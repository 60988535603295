import HttpBase from './http.base';
import AppConstants from '../constants.js';
import { IGenericDetailedReportRequestObject } from '../interfaces';

class HttpSummaryStatLines extends HttpBase {
  calculate = (data:IGenericDetailedReportRequestObject): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/summarystatlines/calculate`, data);
  };
}

const httpSummaryStatLines = new HttpSummaryStatLines();
export { httpSummaryStatLines };
