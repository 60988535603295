/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const withProps = (WrappedComponent: any, additionalProps: any) => {
  return (props: any) => (
    <WrappedComponent {...props} {...additionalProps} />
  );
};

export default withProps;
