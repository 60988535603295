//
import React, { Component } from 'react';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import CheckBox from 'devextreme-react/check-box';
import { Container, Row, Col } from 'react-bootstrap';
import PageHeader from '../../template-component/page-header/PageHeader';
import { dataSource, dataSource1, } from './data';

const Plot = createPlotlyComponent(Plotly);
// let n = 100000;
export class AxisBreaks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yAxisBreak: false,
      xAxisBreak: false,
      tickValsY: [],
      tickTextY: [],
      tickValsX: [],
      tickTextX: [],
      y: dataSource,
      x: dataSource1,
      axisBreakLine: [],
      breakPoint: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    // let data = generateData(n);
    // this.setState({
    //   x: data,
    //   y: data,
    // });
  }

  changeBreaksEnabledState = (e, axis) => {
    if (axis == 'y') {
      if (e.value) {
        this.setState({
          yAxisBreak: e.value,
          isLoading: true,
        });
        this.applyAxisBreak(this.state.y, axis);
      } else {
        const remainingAxisBreak = this.state.axisBreakLine.filter(
          (axisBreak) => axisBreak.key == 'x',
        );
        this.setState({
          y: dataSource,
          // y: generateData(n),
          axisBreakLine: remainingAxisBreak,
          breakPoint: [],
          yAxisBreak: false,
          isLoading: false,
        });
      }
    } else if (axis == 'x') {
      if (e.value) {
        this.setState({
          xAxisBreak: e.value,
          isLoading: true,
        });
        this.applyAxisBreak(this.state.x, axis);
      } else {
        const remainingAxisBreak = this.state.axisBreakLine.filter(
          (axisBreak) => axisBreak.key == 'y',
        );
        this.setState({
          x: dataSource1,
          // x: generateData(n),
          axisBreakLine: remainingAxisBreak,
          breakPoint: [],
          xAxisBreak: false,
          isLoading: false,
        });
      }
    }
  };

  returnActualTickText = (tickValue, breakPoint) => {
    const n = breakPoint.length - 1;
    if (tickValue >= breakPoint[n].breakAt) {
      return tickValue + breakPoint[n].subtractor;
    }
    let i;
    for (i = n - 1; i >= 0; i--) {
      if (tickValue >= breakPoint[i].breakAt) {
        return tickValue + breakPoint[i].subtractor;
      }
    }
    return tickValue;
  };

  roundTest = (v, tickDelta) => Math.round(v / tickDelta) * tickDelta;

  roundBelow1 = (v) => Number(v.toFixed(2));

  roundS = (v) => {
    if (v < 1) {
      const y = 10 ** Math.ceil(Math.log10(v));
      return Math.ceil(v / y) * y;
    }
    const y = 10 ** Math.floor(Math.log10(v));
    return Math.ceil(v / y) * y;
  };

  kFormatter = (num) => (Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num));

  /**
   * @description Function sorts points in asscending order.
   * @param {*List of points} points
   * @returns List of Sorted Points
   */
  sortPointsAscending = ([...points]) => {
    points.sort((x, y) => (x.val > y.val ? 1 : x.val == y.val ? 0 : -1));
    // console.log('\n SORTED DATA SOURCE (ASC. ORDER): ', points);
    return points;
  };

  /**
   * @description Function sets the value of tickVals and tickTexts to default.
   * @param {List of Sorted data points} sortedDataSoure
   * @param {*String of axis flag (Y or X axis)} axis
   * @returns nothing
   */
  noBreakPointFound = (axis) => {
    if (axis == 'y') {
      this.setState({
        tickValsY: null,
        tickTextY: null,
        axisBreakLine: [],
      });
    } else if (axis == 'x') {
      this.setState({
        tickValsX: null,
        tickTextX: null,
        axisBreakLine: [],
      });
    }
  };

  /**
   * @description Function reduces the sorted points in to small points.
   * @param {*List of sorted data points} sortedDataSource
   * @returns Average distance till first Break Point, List of reduced points,
   * boolean of axis Break found and list of Axis Break.
   */
  reduceSortedPoints = (sortedDataSource) => {
    let sum = 0;
    let subtractor = 0;
    let avgDistanceTillFirstbreakPoint = 0;
    let axisBreakFound = false;
    const breakPoint = [];
    for (let i = 0; i < sortedDataSource.length - 1; i++) {
      let delta = 0;

      // --------------
      if (axisBreakFound == false) {
        sum += sortedDataSource[i];
        delta = (sortedDataSource[i + 1] - sortedDataSource[i]) / sortedDataSource[i]; // increase in percentage...
        avgDistanceTillFirstbreakPoint = sum / (i + 1);
      } else {
        const newValue = sortedDataSource[i + 1] - subtractor;
        delta = (newValue - sortedDataSource[i]) / sortedDataSource[i];
      }
      //----------------
      if (delta >= 2.5 && axisBreakFound == false) {
        axisBreakFound = true;
        const newValue = avgDistanceTillFirstbreakPoint + sortedDataSource[i]; // calculate
        subtractor = sortedDataSource[i + 1] - newValue;
        sortedDataSource[i + 1] = newValue; // update the new value
        breakPoint.push({
          index: i,
          breakAt: sortedDataSource[i],
          subtractor,
        });
      } else if (delta >= 1.4 && axisBreakFound == true) {
        axisBreakFound = true;
        const newValue = avgDistanceTillFirstbreakPoint + sortedDataSource[i];
        subtractor = sortedDataSource[i + 1] - newValue;
        sortedDataSource[i + 1] = newValue;
        breakPoint.push({
          index: i,
          breakAt: sortedDataSource[i],
          subtractor,
        });
      } else {
        sortedDataSource[i + 1] = sortedDataSource[i + 1] - subtractor;
      }
    }

    /* console.log(
      '\n',
      '-------- UPDATING SORTED DATA SOURCE AND FINDING BREAKPOINTS ----------------',
    );
    console.log('\n', 'UPDATED DATA SOURCE: ', sortedDataSource);
    console.log('\n', 'BREAKPOINTS: ', breakPoint); */
    return [
      avgDistanceTillFirstbreakPoint,
      sortedDataSource,
      axisBreakFound,
      breakPoint,
    ];
  };

  /**
   * @description Function calculates Tick Delta. Tick Delta is a difference between two ticks.
   * @param {*Float value of average distance till the first Break Point} avgDistanceTillFirstbreakPoint
   * @returns Tick Delta
   */
  calculateTickDelta = (avgDistanceTillFirstbreakPoint) => {
    let tickDelta = avgDistanceTillFirstbreakPoint;
    tickDelta = this.roundS(tickDelta);
    /* console.log(
      "\n",
      "--------------- CALCULATING TICK DELTA ---------------------------"
    );
    console.log("\n", "TICK DELTA: ", tickDelta); */
    return tickDelta;
  };

  /**
   * @description Function adds gap for axis break in data points so graph shows a full tick for axis break
   * @param {*List of data points} sortedDataSoure
   * @param {*Float Value of Tick Delta} tickDelta
   * @param {*List of Break Points in graph} breakPoint
   * @returns list of data points with gap in it for axis break
   */
  addingGapForAxisBreakInData = (sortedDataSoure, tickDelta, breakPoint) => {
    let i;
    let dMultiplier = 1;
    for (i = 0; i < breakPoint.length; i++) {
      let startIndex;
      let endIndex;
      if (i == breakPoint.length - 1) {
        startIndex = breakPoint[i].index;
        endIndex = sortedDataSoure.length - 1;
      } else {
        startIndex = breakPoint[i].index;
        endIndex = breakPoint[i + 1].index;
      }
      for (let j = startIndex + 1; j <= endIndex; j++) {
        sortedDataSoure[j] += dMultiplier * tickDelta;
      }
      dMultiplier += 1;
    }
    /* console.log(
      "\n",
      "--------------- ADDING GAP FOR AXIS BREAK IN UPDATED DATA SOURCE ----------------"
    );
    console.log("\n", "UPDATED SOURCE (D ADJUSTMENTS): ", sortedDataSoure); */
    return sortedDataSoure;
  };

  /**
   * @description Function adds gap for axis break in Break Point list as well.
   * @param {*Float value of tick Delta} tickDelta
   * @param {*List of breakPoints} breakPoint
   * @returns list of updated break Point
   */
  addingGapForAxisBreakInBreakPoint = (tickDelta, breakPoint) => {
    for (let i = 0; i < breakPoint.length; i++) {
      breakPoint[i].breakAt += (i + 1) * tickDelta;
      breakPoint[i].subtractor -= (i + 1) * tickDelta;
    }
    /* console.log(
      "\n",
      "--------------- ADDING GAP FOR AXIS BREAK IN BREAKPOINT ----------------"
    );
    console.log("\n", "BREAKPOINT (D ADJUSTMENTS): ", breakPoint); */
    return breakPoint;
  };

  /**
   * @description Function calculates Tick Positions
   * @param {*List of data points} sortedDataSoure
   * @param {*Integer value of tick Delta} tickDelta
   * @returns list of Tick Positions
   */
  calculateTickPositions = (sortedDataSoure, tickDelta) => {
    let i = 0;
    let tickValue = 0;
    const tickPositions = [];
    const newMax = sortedDataSoure[sortedDataSoure.length - 1];
    while (tickValue < newMax) {
      tickValue = tickDelta * i;
      tickPositions.push(Number(tickValue.toFixed(1)));
      i += 1;
    }
    /* console.log(
      "\n",
      "---------- CALCULATING TIC POSITIONS ------------------------"
    );
    console.log("\n", "TICK POSITIONS: ", tickPositions); */
    return tickPositions;
  };

  /**
   * @description Function calculates Tick Texts for both axis
   * @param {*List of Tick Positions} tickPositions
   * @param {*List of break Point} breakPoint
   * @returns the list of Tick Texts.
   */
  calculateTickTexts = (tickPositions, breakPoint) => {
    // console.log("\n", " ------------- CALCULATING TICK TEXTS ------------");
    // console.log("\n", "TICK TEXTS: ", tickTexts);
    return tickPositions.map((tickValue) => this.returnActualTickText(tickValue, breakPoint));
  };

  /**
   * @description Function calculates Axis Break Lines
   * @param {*List of Tick Texts} tickTexts
   * @param {*List of Tick Positions} tickPositions
   * @param {*Integer value of Tick Delta} tickDelta
   * @param {*String axis flag x or y} axis
   * @returns List of axis break Lines containing objects representing axis break lines on graph.
   */
  calculateAxisBreakLines = (tickTexts, tickPositions, tickDelta, axis) => {
    const axisBreakLine = [];
    for (let i = 0; i < tickTexts.length - 1; i++) {
      const diff = tickTexts[i + 1] - tickTexts[i];
      if (diff.toFixed(1) > tickDelta) {
        const diff1 = tickPositions[i + 1] - tickPositions[i];
        axisBreakLine.push(tickPositions[i] + diff1 / 2);
      }
    }
    const renderAxisBreakLine = [];
    if (axis == 'y') {
      for (let i = 0; i < axisBreakLine.length; i++) {
        renderAxisBreakLine.push({
          key: 'y',
          type: 'line',
          xref: 'paper',
          x0: 0,
          x1: 1,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'Crimson',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'y',
          type: 'line',
          xref: 'paper',
          x0: 0,
          x1: 1,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    } else if (axis == 'x') {
      for (let i = 0; i < axisBreakLine.length; i++) {
        renderAxisBreakLine.push({
          key: 'x',
          type: 'line',
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'Crimson',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'x',
          type: 'line',
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    }

    /* console.log(
      "\n",
      "----------- CALCULATING AXISBREAKS LINES -----------------"
    );
    console.log("\n", "AXIS BREAK: ", renderAxisBreakLine); */

    return renderAxisBreakLine;
  };

  formatTickTexts = (tickTexts) => {
    /* console.log(
      "\n",
      "-------------- FORMATTING TICTEXTS ON AXIS ------------"
    );
    console.log("\n", "FORMATTED TICTEXTS: ", formattedTickTexts); */
    return tickTexts.map((ele) => this.kFormatter(ele).toString());
  };

  /**
   * @description Function updates Tick Positions and Tick Texts for cosmetic improvements by rounding off the values.
   * @param {*List of Tick Texts} tickTexts
   * @param {*List of Tick Positions} tickPositions
   * @param {*Integer Value of Tick Delta} tickDelta
   * @returns list of updated Tick Texts and Tick Positions
   */
  cosmeticAdjustments = (tickTexts, tickPositions, tickDelta) => {
    tickTexts.forEach((ele, index) => {
      let updatedTickText;
      if (tickDelta < 1) {
        updatedTickText = this.roundBelow1(ele);
      } else {
        updatedTickText = this.roundTest(ele, tickDelta);
      }
      const diff = updatedTickText - tickTexts[index];
      tickPositions[index] = Number((tickPositions[index] + diff).toFixed(3));
      tickTexts[index] = updatedTickText;
    });
    // console.log("\n", "----------- COSMETIC ADJUSTMENTS -----------------");
    // console.log("\n", "ADJUSTED TICK POSITIONS (COSMETIC): ", tickPositions);
    // console.log("\n", "ADJUSTED TICK TEXTS (COSMETIC): ", tickTexts);
    return [tickTexts, tickPositions];
  };

  /**
   * @description Function sets the state of the compponent and renders the aix break.
   * @param {*List of data points} sortedDataSoure
   * @param {*List of tick Positions} tickPositions
   * @param {*List of tick Texts} tickTexts
   * @param {*List of Axis Break Line} axisBreakLine
   * @param {*String flag of axis (Either x or y)} axis
   * @returns nothing
   */
  renderAxisBreaks = (
    orderedDataPoints,
    tickPositions,
    tickTexts,
    axisBreakLine,
    axis,
  ) => {
    if (axis == 'y') {
      this.setState({
        y: orderedDataPoints,
        tickValsY: tickPositions,
        tickTextY: tickTexts,
        axisBreakLine: [...axisBreakLine, ...this.state.axisBreakLine],
        isLoading: false,
      });
    } else if (axis == 'x') {
      this.setState({
        x: orderedDataPoints,
        tickValsX: tickPositions,
        tickTextX: tickTexts,
        axisBreakLine: [...axisBreakLine, ...this.state.axisBreakLine],
        isLoading: false,
      });
    }
  };

  /**
   * @description Function reorderes the sorted reduced data points to the original indices
   * @param {*Map of indices of sorted redueced data} sortedDataMap
   * @param {*List of sorted data elements} sortedDataSource
   * @returns list of data points move to their orginal indices.
   */
  reorderSortedDataPoints = (sortedDataMap, sortedDataSource) => {
    const unsortedArray = Array.from({
      length: sortedDataSource.length,
    });
    const originalOrder = sortedDataMap.map((ele) => ele.ind);
    originalOrder.forEach((ele, index) => {
      unsortedArray[ele] = sortedDataSource[index];
    });

    return unsortedArray;
  };

  /**
   * @description Function creates a map of original data points indices for future reordering.
   * @param {*List od orignal data points} points
   * @returns a list of object with original index and value
   */
  createOriginalIndicesMap = (points) => {
    return points.map((e, i) => ({ ind: i, val: e }));
  };

  /**
   * @description Function fetches only the elements which are going to be plotted from  the list of objects
   * @param {List of sorted data points object} sortedDataMap
   * @returns a list of to be plotted elements.
   */
  fetchElementsFromSortedMap = (sortedDataMap) => sortedDataMap.map((ele) => ele.val);

  /**
   * @description Function calculates axis breaks on x and y axis depending on the provided axis flag.
   * @param {*Array of points (X or Y Axis)} data
   * @param {*String Axis flag either "x" or "y"} axis
   * @returns { list of reduced Data points, Tick Positions, Tick Texts and Axis Break Lines.}
   */
  applyAxisBreak = async (points, axis) => {
    // Variable Intialization
    let tickPositions = [];
    let tickTexts = [];
    let sortedDataSoure = [];
    let avgDistanceTillFirstbreakPoint = 0;
    let tickDelta = 0;
    let axisBreakLine = [];
    let axisBreakFound;
    let breakPoint = [];
    let originalDataMap = [];
    let sortedDataMap = [];
    let orderedDataPoints = [];

    // Make Map of Original Data
    originalDataMap = this.createOriginalIndicesMap(points);

    // Sorting data in Assc. Order
    sortedDataMap = this.sortPointsAscending(originalDataMap);

    // Fetching elements from SortedDataMap
    sortedDataSoure = this.fetchElementsFromSortedMap(sortedDataMap);

    // Updating sorted data and finding breakpoints
    [
      avgDistanceTillFirstbreakPoint,
      sortedDataSoure,
      axisBreakFound,
      breakPoint,
    ] = this.reduceSortedPoints(sortedDataSoure);

    // If there is no breakpoint return the function
    if (axisBreakFound == false) {
      this.noBreakPointFound(axis);
      return;
    }

    // calculate Tick Detla
    tickDelta = this.calculateTickDelta(avgDistanceTillFirstbreakPoint);

    // Adding gap for axis break in Data
    sortedDataSoure = this.addingGapForAxisBreakInData(
      sortedDataSoure,
      tickDelta,
      breakPoint,
    );

    // Adding gap for axis break in BreakPoint
    breakPoint = this.addingGapForAxisBreakInBreakPoint(tickDelta, breakPoint);

    // Calculating Tick Positions
    tickPositions = this.calculateTickPositions(sortedDataSoure, tickDelta);

    // Calculating Tick Texts
    tickTexts = this.calculateTickTexts(tickPositions, breakPoint);

    // Cosmetic Adjusment in Tick Texts and Tick Positions
    [tickTexts, tickPositions] = this.cosmeticAdjustments(
      tickTexts,
      tickPositions,
      tickDelta,
    );

    // Calculating Axis Break Line
    axisBreakLine = this.calculateAxisBreakLines(
      tickTexts,
      tickPositions,
      tickDelta,
      axis,
    );

    // Formating Tick Texts (Optional)
    // tickTexts = this.formatTickTexts(tickTexts);

    // Reorder Elements to their original indices
    orderedDataPoints = this.reorderSortedDataPoints(
      sortedDataMap,
      sortedDataSoure,
    );

    // Render Axis Break on Screen
    this.renderAxisBreaks(
      orderedDataPoints,
      tickPositions,
      tickTexts,
      axisBreakLine,
      axis,
    );
  };

  render() {
    return (
      <>
        <Container fluid className="pl20 pt10">
          <Row>
            <Col className="h200">
              <PageHeader
                title="Plolty Axis Break"
                secondaryActions={(
                  <div className="options">
                    <div className="caption pb10">Options</div>
                    <div className="option pb10">
                      <CheckBox
                        className="checkbox"
                        text="Enable y-Axis Breaks"
                        onValueChanged={(event) => this.changeBreaksEnabledState(event, 'y')}
                        value={this.state.yAxisBreak}
                      />
                    </div>
                    <div className="option pb10">
                      <CheckBox
                        className="checkbox"
                        text="Enable x-Axis Breaks"
                        onValueChanged={(event) => this.changeBreaksEnabledState(event, 'x')}
                        value={this.state.xAxisBreak}
                      />
                    </div>
                  </div>
                )}
              />
            </Col>
          </Row>
        </Container>
        <Container fluid className="pl20 pt10">
          <Row>
            <Col lg={12}>
              <Plot
                className="w-100"
                layout={{
                  autosize: true,
                  yaxis: {
                    tickvals: this.state.yAxisBreak
                      ? this.state.tickValsY
                      : null,
                    ticktext: this.state.yAxisBreak
                      ? this.state.tickTextY
                      : null,
                  },
                  xaxis: {
                    tickvals: this.state.xAxisBreak
                      ? this.state.tickValsX
                      : null,
                    ticktext: this.state.xAxisBreak
                      ? this.state.tickTextX
                      : null,
                  },
                  shapes: this.state.axisBreakLine,
                }}
                data={[
                  {
                    type: 'bar',
                    // mode: "markers",
                    // marker: {
                    //   size: 10,
                    //   color: "rgb(152, 0, 0)",
                    //   line: {
                    //     width: 1,
                    //     color: "rgb(0,0,0)",
                    //   },
                    // },
                    y: this.state.y,
                    x: this.state.x,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AxisBreaks;
