/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from 'react';
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import toast from 'CustomToast';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { InteractionInputDTO, ICombinedGraphDataWithReportHeaders, } from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
// import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import { IFieldValue } from '../../../../../components/utility-component/report-headers/ReportHeaderBuilder';
import { ILineDict } from '../action-items-sheet/ActionItemsSheet';
import '../../customized-reports.scss';

// import TestParameterBadges from '../../customized-report-utils/TestParameterBadges';

export class SPCTrendGraphHelper implements IGraphDataHelper {
  preferences = {
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    graphType: ('scatter' as IGraphType),
    graphMode: ('lines+markers' as IGraphMode),
    markerOpacity: 0.9,
    markerBorderOpacity: 0,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: false,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: true,
    showXAxisBreaks: false,
    allowDefaultGrouping: true,
    extractSelectedValuesFromToolTips: true,
    showAddedMarkers: true,
    textOnHover: [['placeholder']],
    hoverTemplate: {
      grouping:
      '<b><i>Calculated Value</i>: %{y}</b><br>'
            + '%{text}<br>'
            + '<extra></extra>',
      noGrouping:
      '<b><i>Calculated Value</i>: %{y}</b><br>'
            + '%{text}<br>'
            + '<extra></extra>',
    },
    showAddedLines: {
      grouping: {
        show: false,
        name: 'Median Connector',
      },
      noGrouping: {
        show: false,
        name: 'Median Connector',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'NONE',
      yAxisLabel: 'Test Parameter Values',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'wafer_id',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'Time',
        yAxisName: 'Monitored Points',
      },
      noGrouping: {
        xAxisName: 'Time',
        yAxisName: 'Monitored Points',
      },
    },
    showTickText: {
      grouping: {
        xAxis: true,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: false,
    hasMultiYAxis: false,
  };

  getPreferences = () => {
    return this.preferences;
  };

  generateGraphComponent = (props:ICustomizedReportGraphProps, state:ICustomizedReportGraphState, classMembers:any, setStateCallback: any, summaryStatLines: IFieldValue[], lineDict:ILineDict) => {
    const graphingUtil = new GraphingUtils();
    return graphingUtil.plotlyGraphGenerationHelper(props, state, classMembers, setStateCallback, this.preferences, summaryStatLines, lineDict);
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject:IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      this.preferences.textOnHover = [];
      graphDataAndReportHeader.flatGraphDataDto.graphs.forEach((graph: any) => {
        this.preferences.textOnHover.push(graph.yText);
      });
      callback(graphDataAndReportHeader);
    }).catch((error:any) => {
      toast.error('Failed to retrive policy output', error);
    });
  };
}
