/* eslint-disable no-unused-vars */
import { IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';
import { IFilterRawDataDTO, IFavoriteSelectionCriteria, IGenericDetailedReportRequestObject } from 'interfaces';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpSCW extends HttpBase {
  getSCWDefaultSelectionCounts = (ribbonViewCounts:IDefaultSelectionCriteria[]): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/master-meta/default-selection-counts`, ribbonViewCounts);
  };

  saveInMemory = (data:IFilterRawDataDTO): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/save-in-memory`, data);
  };

  addFavoriteSelectonCriteria = (data: IFavoriteSelectionCriteria): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria`, data);
  }

  updateFavoriteSelectionCriteria = (data: IFavoriteSelectionCriteria): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/favorite-selection-criteria`, data);
  }

  getFavoriteSelectionCriteria = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/favorite-selection-criteria`)
  }

  deleteFavoriteSelection = (ids: string[]): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria/delete`, ids)
  }

  getFavoriteFolders = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/favorite-selection-criteria-folder`)
  }

  getFoldersAndFavorites = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/favorite-selection-criteria/folders-and-favorites`)
  }

  addFavoriteFolder = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria-folder`, data);
  }

  deleteFavoriteFolder = (id: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/favorite-selection-criteria-folder/${id}`);
  }

  updateFavoriteFolder = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/favorite-selection-criteria-folder`, data);
  }

  moveFavorite = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria/move`, data);
  }

  copyFavorite = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria/copy`, data);
  }

  mergeFavorite = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/favorite-selection-criteria/merge`, data);
  }
  getBinWaferMapComparisonSelectionData = (requestObject: IGenericDetailedReportRequestObject): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/get-bin-wafer-map-comparison-selection-data`, requestObject);
  };

  isValidWaferCombination = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/is-valid-wafer-combination`, data);
  }
}

const httpSCW = new HttpSCW();
export { httpSCW };
