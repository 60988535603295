import { ScrollView } from 'devextreme-react';
import _ from 'lodash';
import React, { Component } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import PubSubBinder, { ActionType } from '../PublishSubscribeBinder';

export interface IPowerViewProgressDriver {
  action: 'RESET' | 'APPEND';
  description: string;
  timestamp: Date;
}
interface IPowerViewProgressBarState {
  log: { description: string; timestamp: Date }[];
  showDetails: boolean;
}
interface IPowerViewProgressBarProps {
  reportsCount: number;
}

class PowerViewProgressBar extends Component<IPowerViewProgressBarProps, IPowerViewProgressBarState> {
  private reportPublisherId : string;

  private pubSubBinder = PubSubBinder();

  constructor(props:any) {
    super(props);
    this.state = {
      showDetails: false,
      log: [{
        description: 'Reports Generation Started',
        timestamp: new Date(),
      }],
    };
    this.reportPublisherId = this.pubSubBinder.RegisterActor(
      'POWER_VIEW_PROGRESS_BAR',
      this.listenUpdates,
      false,
    );
  }

  componentDidUpdate(prevProps: IPowerViewProgressBarProps) {
    if (!_.isEqual(this.props, prevProps)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        log: [{
          description: 'Reports Generation Started',
          timestamp: new Date(),
        }],
      });
    }
  }

  componentWillUnmount() {
    this.pubSubBinder.DisposeActorsByType('POWER_VIEW_PROGRESS_BAR');
  }

  listenUpdates = (action: ActionType, params?: IPowerViewProgressDriver) => {
    const { log } = this.state;
    if (action === 'LOG_POWER_VIEW_PROGRESS' && params) {
      if (params.action === 'RESET') {
        this.setState({
          log: [{
            description: 'Reports Generation Started',
            timestamp: new Date(),
          }],
        });
      } else {
        const newLog = [...log, {
          description: params.description,
          timestamp: params.timestamp,
        }];
        this.setState({ log: newLog });
      }
    }
  };

  getTimeDifferenceInSeconds = (startDate:Date, endDate: Date) => {
    return `${(endDate.getTime() - startDate.getTime()) / 1000} sec`;
  };

  render() {
    const { reportsCount } = this.props;
    const { log, showDetails } = this.state;
    return (
      <div className="w-15 pb6 position-relative">
        <div className="d-flex align-items-center justify-content-between w-100">
          { log.length > 0
          && (
            <div className="flex-80 pr20">
              <ProgressBar
                striped
                variant="success"
                className="h20"
                label={(log.length - 1) === reportsCount ? 'Complete' : `(${log.length - 1} / ${reportsCount})`}
                now={log.length - 1}
                max={reportsCount}
              />
            </div>
          )}
          <div className="flex-20">
            <Button
              type="button"
              size="sm"
              variant={showDetails ? 'info' : 'outline-info'}
              className="p0 h20 w-100"
              onClick={() => {
                this.setState({ showDetails: !showDetails });
              }}
            >
              <div className="d-flex align-items-center justify-content-center pt1 pb2 pl6 pr6">
                <span className="d-inline-block">Logs</span>
              </div>
            </Button>
          </div>
        </div>
        {
          showDetails && (
            <div
              style={{
                top: '36px',
              }}
              className="position-absolute shadow z-index-top w-100 border-radius-4 background-color-light border-all p10"
            >
              <h4>
                Details (
                {(log.length - 1)}
                {' / '}
                {reportsCount}
                )
              </h4>
              <ScrollView
                showScrollbar="always"
                scrollByThumb
                reachBottomText=""
                className="h300"
              >
                <div>
                  {log.map((l) => (
                    <div className="d-flex align-items-start justify-content-between pb5 mb5 break-bottom">
                      <p className="mb0">
                        {l.description}
                      </p>
                      <p className="text-right mb0 color-title-color">
                        {this.getTimeDifferenceInSeconds(log[0].timestamp, l.timestamp)}
                      </p>
                    </div>
                  ))}
                </div>
              </ScrollView>
            </div>
          )
        }
      </div>
    );
  }
}

export default PowerViewProgressBar;
