import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import * as _ from 'lodash';
import { DataGrid, TreeList } from 'devextreme-react';
import {
  Button as DxButton, Column, Editing, RowDragging, SearchPanel, StateStoring,
} from 'devextreme-react/data-grid';
import Button from '../../wrapped-component/hint-controls/Button';

class CreateSort extends Component<any, any> {
  private editSortName = '';

  constructor(props: any) {
    super(props);
    const { selectedSortDialog } = this.props;
    const copySelectedSortDialog = JSON.parse(JSON.stringify(selectedSortDialog));
    this.state = {
      currentSort: copySelectedSortDialog !== undefined ? copySelectedSortDialog[0] : {},
      selectedSortsDialog: copySelectedSortDialog,
      selectedField: [],
      editSortitem: '',
    };
    this.createSort = this.createSort.bind(this);
    this.saveSorts = this.saveSorts.bind(this);
    this.toggleCurrentSort = this.toggleCurrentSort.bind(this);
    this.removeSort = this.removeSort.bind(this);
    this.getSelectedField = this.getSelectedField.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.removeField = this.removeField.bind(this);
    this.editSort = this.editSort.bind(this);
    this.SortNameChanged = this.SortNameChanged.bind(this);
    this.changeSortName = this.changeSortName.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.changeSort = this.changeSort.bind(this);
  }

  createSort(e: any) {
    const item:any = e.row.data;
    const { selectedSortsDialog } = this.state;
    selectedSortsDialog.splice(0);
    const { currentSort } = this.state;
    const createNew: boolean = currentSort.groupObject === undefined;
    if (currentSort.groupObject !== undefined) {
      currentSort.groupObject.push({
        TableName: item.TableName,
        Columns: [{
          ColumnName: item.ColumnName,
          Sort: '',
        }],
      });
      let caption = '';
      currentSort.groupObject.forEach((group: any) => {
        group.Columns.forEach((cols: any) => {
          caption = `${caption + group.TableName} ${cols.ColumnName} / `;
        });
      });

      currentSort.caption = caption.substring(0, caption.length - 2);
    }
    let index = -1;
    if (!createNew) {
      index = _.findIndex(selectedSortsDialog, (item: any) => {
        return item.caption === currentSort.caption;
      });
    }
    if (index === -1) {
      selectedSortsDialog.push(currentSort);
    } else {
      selectedSortsDialog[index] = currentSort;
    }

    this.setState({
      currentSort,
      selectedSortsDialog,
    }, () => {
      this.getSelectedField();
    });
  }

  componentDidMount() {
    this.getSelectedField();
  }

  getSortName(selectedSortsDialog: any) {
    let SortIndex: number = selectedSortsDialog.length;
    let SortName: string;
    while (true) {
      SortName = `Sort ${SortIndex}`;
      const index = _.findIndex(selectedSortsDialog, (item: any) => {
        return item.caption === SortName;
      });
      if (index === -1) {
        return SortName;
      }
      SortIndex += 1;
    }
  }

  saveSorts() {
    const { sortUpdated, closeDialog } = this.props;
    const { selectedSortsDialog } = this.state;
    if (sortUpdated !== undefined) {
      sortUpdated(selectedSortsDialog);
    }
    if (closeDialog !== undefined) {
      closeDialog();
    }
  }

  toggleCurrentSort(item: any) {
    const { currentSort } = this.state;
    if (item.caption === currentSort.caption) {
      this.setState({ currentSort: {} }, () => {
        this.getSelectedField();
      });
    } else {
      this.setState({ currentSort: item }, () => {
        this.getSelectedField();
      });
    }
  }

  removeSort(item: any) {
    const { selectedSortsDialog } = this.state;
    const index = _.findIndex(selectedSortsDialog, (element: any) => {
      return element.caption === item.caption;
    });
    selectedSortsDialog.splice(index, 1);
    this.setState({ selectedSortsDialog });
  }

  onReorder(e: any) {
    const visibleRows = e.component.getVisibleRows();
    const { selectedField, currentSort } = this.state;
    const newTasks = selectedField;
    const toIndex = newTasks.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newTasks.indexOf(e.itemData);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);
    const spliceObject = currentSort.groupObject[fromIndex];
    currentSort.groupObject.splice(fromIndex, 1);
    currentSort.groupObject.splice(toIndex, 0, spliceObject);
    let caption = '';
    currentSort.groupObject.forEach((group: any) => {
      group.Columns.forEach((cols: any) => {
        caption = `${caption + group.TableName} ${cols.ColumnName} / `;
      });
    });

    currentSort.caption = caption.substring(0, caption.length - 2);
    this.setState({
      selectedField: newTasks,
      currentSort,
    });
  }

  getSelectedField() {
    const { currentSort } = this.state;
    const selectedField: any = [];
    if (currentSort.groupObject !== undefined) {
      currentSort.groupObject.forEach((item: any) => {
        item.Columns.forEach((column: any) => {
          selectedField.push({
            caption: `${item.TableName} ${column.ColumnName}`,
            sort: column.Sort,
          });
        });
      });
    }
    this.setState({ selectedField });
  }

  removeField(e: any) {
    const { currentSort } = this.state;
    const objectInfo: any = e.row.data.caption.split(' ');
    const object = _.find(currentSort.groupObject, (item) => {
      return item.TableName === objectInfo[0];
    });
    let deleteObject: any;

    object.Columns.forEach((subItem: any) => {
      if (subItem.ColumnName === objectInfo[1]) {
        deleteObject = object;
      }
    });

    const objectIndex: number = _.findIndex(currentSort.groupObject, deleteObject);
    currentSort.groupObject.splice(objectIndex, 1);
    this.setState({
      currentSort,
    }, () => {
      this.getSelectedField();
    });
  }

  changeSort(e: any) {
    const { currentSort } = this.state;
    const objectInfo: any = e.data.caption.split(' ');
    const object = _.find(currentSort.groupObject, (item) => {
      return item.TableName === objectInfo[0];
    });
    let deleteObject: any;

    object.Columns.forEach((subItem: any) => {
      if (subItem.ColumnName === objectInfo[1]) {
        deleteObject = object;
      }
    });

    const objectIndex: number = _.findIndex(currentSort.groupObject, deleteObject);
    currentSort.groupObject[objectIndex].Columns[0].Sort = currentSort.groupObject[objectIndex].Columns[0].Sort === 'desc' ? 'asc' : 'desc';
    this.setState({
      currentSort,
    }, () => {
      this.getSelectedField();
    });
  }

  editSort(item: any, e: any) {
    this.setState({ editSortitem: item });
    e.stopPropagation();
  }

  SortNameChanged(e: any) {
    this.editSortName = e.target.value;
  }

  changeSortName(SortName: string) {
    const { selectedSortsDialog } = this.state;
    const Sort = _.find(selectedSortsDialog, (item: any) => {
      return item.caption === SortName;
    });
    Sort.caption = this.editSortName;
    this.setState({
      selectedSortsDialog,
    });
  }

  closeDialog() {
    const { closeDialog } = this.props;
    if (closeDialog !== undefined) {
      closeDialog();
    }
  }

  cellRender(e:any) {
    if (e.rowType === 'data' && e.columnIndex == 1) {
      if (e.data.ParentId === null) {
        e.cellElement.className = 'd-none';
      } else {
        e.cellElement.className = 'dx-command-edit dx-command-edit-with-icons';
      }
    }
  }

  render() {
    const columnsList:any = [{
      Id: '1',
      TableName: 'facility',
      Caption: 'Facility',
      ParentId: null,
    }, {
      Id: '1-1',
      TableName: 'facility',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '1',
    }, {
      Id: '1-2',
      TableName: 'facility',
      ColumnName: 'type',
      Caption: 'Type',
      ParentId: '1',
    }, {
      Id: '1-3',
      TableName: 'facility',
      ColumnName: 'test_facility',
      Caption: 'Test Facility',
      ParentId: '1',
    }, {
      Id: '2',
      TableName: 'Work_Center',
      Caption: 'Work Center',
      ParentId: null,
    }, {
      Id: '2-1',
      TableName: 'Work_Center',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '2',
    }, {
      Id: '2-2',
      TableName: 'Work_Center',
      ColumnName: 'test_temperature',
      Caption: 'Test Temperature',
      ParentId: '2',
    }, {
      Id: '3-1',
      TableName: 'Device_Family',
      ColumnName: 'name',
      Caption: 'Device Family',
      ParentId: '4',
    }, {
      Id: '4',
      TableName: 'Device',
      Caption: 'Device',
      ParentId: null,
    }, {
      Id: '4-1',
      TableName: 'Device',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '4',
    }, {
      Id: '5',
      TableName: 'test_program',
      Caption: 'Test Program',
      ParentId: null,
    }, {
      Id: '5-1',
      TableName: 'test_program',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '5',
    }, {
      Id: '5-2',
      TableName: 'test_program_revision',
      ColumnName: 'revision_number',
      Caption: 'Revision Number',
      ParentId: '5',
    }, {
      Id: '6',
      TableName: 'lot',
      Caption: 'Lot',
      ParentId: null,
    }, {
      Id: '6-1',
      TableName: 'lot',
      ColumnName: 'key',
      Caption: 'LOT Id',
      ParentId: '6',
    }, {
      Id: '6-2',
      TableName: 'lot',
      ColumnName: 'start_time',
      Caption: 'Lot Start Time',
      ParentId: '6',
    }, {
      Id: '6-3',
      TableName: 'lot',
      ColumnName: 'end_time',
      Caption: 'Lot Finish Time',
      ParentId: '6',
    }, {
      Id: '6-4',
      TableName: 'lot',
      ColumnName: 'test_temperature',
      Caption: 'Lot Test Temperature',
      ParentId: '6',
    }, {
      Id: '6-5',
      TableName: 'lot',
      ColumnName: 'lot_complete_status',
      Caption: 'Lot Complete Status',
      ParentId: '6',
    }, {
      Id: '7',
      Caption: 'Wafer',
      ParentId: null,
    }, {
      Id: '7-1',
      TableName: 'wafer',
      ColumnName: 'key',
      Caption: 'Wafer Id',
      ParentId: '7',
    }, {
      Id: '7-2',
      TableName: 'wafer',
      ColumnName: 'wafer_start_time',
      Caption: 'Wafer Start Time',
      ParentId: '7',
    }, {
      Id: '7-3',
      TableName: 'wafer',
      ColumnName: 'wafer_finish_time',
      Caption: 'Wafer Finish Time',
      ParentId: '7',
    }, {
      Id: '7-4',
      TableName: 'wafer',
      ColumnName: 'die_count',
      Caption: 'Total Dies',
      ParentId: '7',
    }, {
      Id: '7-5',
      TableName: 'wafer',
      ColumnName: 'good_count',
      Caption: 'Pass Dies',
      ParentId: '7',
    }, {
      Id: '7-6',
      TableName: 'wafer',
      ColumnName: 'probe_count',
      Caption: 'Probe Count',
      ParentId: '7',
    }, {
      Id: '7-7',
      TableName: 'wafer',
      ColumnName: 'test_temperature',
      Caption: 'Wafer Test Temperature',
      ParentId: '7',
    }, {
      Id: '7-8',
      TableName: 'wafer',
      ColumnName: 'test_program_revision_id',
      Caption: 'Test Program Revision',
      ParentId: '7',
    }];
    const { currentSort, selectedField } = this.state;
    const availableFields: any = [];

    columnsList.map((item: any) => {
      const foundObject: any = _.find(currentSort.groupObject, (cobject) => {
        return cobject.TableName === item.TableName && _.findIndex(cobject.Columns, ((columns: any) => {
          return columns.ColumnName === item.ColumnName;
        })) !== -1;
      });
      if (foundObject === undefined) {
        availableFields.push(item);
      }
    });
    // const selectedField: any = this.getSelectedField();
    // if (currentSort.SortObject !== undefined) {
    //   currentSort.SortObject.forEach((item: any) => {
    //     item.Columns.forEach((column: any) => {
    //       selectedField.push({
    //         caption: item.TableName + ' ' + column.ColumnName
    //       });
    //     });
    //   });
    // }
    return (
      <div className="bg-white create-groups create-sorts">
        <div className="p10">
          <h5>
            Sort Management
            <Button
              variant="clear float-right p0 h16 pl5"
              onClick={() => {
                this.closeDialog();
              }}
            >
              <i className="icon dx-icon-remove float-right" />
            </Button>
          </h5>
        </div>

        <div className=" p10 d-flex custom-form">
          <Col>
            <h6>Available Fields</h6>
            <div className="border-all section-fields">
              <TreeList
                dataSource={availableFields}
                keyExpr="Id"
                parentIdExpr="ParentId"
                showColumnHeaders={false}
                showColumnLines={false}
                onCellPrepared={this.cellRender}
                height={337}
                autoExpandAll={false}
              >
                <SearchPanel visible width="100%" />
                <Column
                  dataField="Caption"
                />
                <Column
                  type="buttons"
                  width={36}
                >
                  <DxButton hint="Add to Selected List" icon="add" cssClass="text-success" onClick={this.createSort} />
                </Column>

              </TreeList>
            </div>

          </Col>
          <Col>
            <h6>Selected Fields</h6>
            <div className="border-all section-fields">
              {/* <ListGroup>
                {
                  selectedField.map((item: any) => {
                    return (<ListGroup.Item>{item.caption}</ListGroup.Item>)
                  })
                }
              </ListGroup> */}
              <DataGrid
                height={340}
                dataSource={selectedField}
                showBorders
                showColumnHeaders={false}
                showColumnLines={false}
                showRowLines
                repaintChangesOnly
              >
                <Editing
                  mode="row"
                  useIcons
                  allowDeleting
                />
                <RowDragging
                  allowReordering
                  onReorder={this.onReorder}
                  showDragIcons={this.state.showDragIcons}
                />
                <StateStoring enabled={false} />
                <Column
                  width={26}
                  cellRender={(e) => {
                    return <div><Button variant="outline p0" onClick={() => this.changeSort(e)}><i className={e.data.sort === 'desc' ? 'dx-icon-sortdowntext' : 'dx-icon-sortuptext'} /></Button></div>; // <DxButton icon={e.data.sort==='desc'?'sortdowntext':'sortuptext'} onClick={this.removeField} />
                  }}
                />
                <Column dataField="caption" />
                <Column type="buttons" width={40}>
                  <DxButton hint="Delete" icon="close" cssClass="text-danger" onClick={this.removeField} />
                </Column>
              </DataGrid>
            </div>
          </Col>
        </div>
        <div>
          <Button variant="primary float-right mr25" onClick={this.saveSorts}>Apply & Close</Button>
        </div>
      </div>

    );
  }
}

export default CreateSort;
