/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-use-before-define
import React from "react";
import { DataGrid, ScrollView, Toolbar } from "devextreme-react";
import {
  ColumnChooser,
  Paging,
  Scrolling,
  Selection,
  Column,
  Sorting,
} from "devextreme-react/data-grid";
import { IColumnMeta, IVisibleColumnsDetails } from "interfaces";
import {
  faTimes,
  faArrowsAltH,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spinner from "../../assets/icons/spinner.gif";
import scwGridIcon3 from "../../assets/icons/scw-grid-3.svg";
import scwGridIcon6 from "../../assets/icons/scw-grid-6.svg";
import scwGridIcon9 from "../../assets/icons/scw-grid-9.svg";
import scwGridIcon12 from "../../assets/icons/scw-grid-12.svg";
import "./selection-criteria.scss";
import { listOfEntityTypes } from "./SelectionCriteriaTypes";

interface ISelectionCriteriaGridProps {
  setFullScreenControlType: (controlType?: string | undefined) => void;
  fullScreenControlType?: string | undefined;
  controlType: string;
  customDataSource: any;
  gridColumns: IColumnMeta[];
  getColumnCaption: any;
  returnForceUpdateCallback: any;
  toggleVisibility: any;
  changeWeight: any;
  onGridSelectionChanged: any;
  highlightingKeys: { [key: string]: IVisibleColumnsDetails };
  onFilterExpressionChanged: any;
  selectedRowKeys: string[];
  columnWeight: number;
  pageSize: number;
  errorMessage: string;
  isCleared?: boolean;
  updateDefaultWidgetItem: (
    controlType: string,
    key: string,
    value: any
  ) => void;
  syncWithLocalStorage: any;
  maxSelectedCount?: number;
  filteringKeys?: { [key: string]: IVisibleColumnsDetails };
  minVisibilityCriteria?: string[];
  selectionCounts: any[];
  hideTestParamforPolicy: boolean;
  widgetIndexInDefaultWidgetsList: number;
  onChangeInVisibleColumns: (
    index: number,
    controlTypes: string[],
    visibleColumns: any[]
  ) => void;
  updateLocalVisibleColumnsListForPolicy?: (
    entityKey: string,
    visibleColumnsWithSequence: any
  ) => void;
  getColumnNamesFromCallback: (entityKey: string) => string;
  refreshGridWithControlType: (controlType: string) => void;
}

interface IColumnVisibilityList {
  columnName: string;
  isVisible: boolean;
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISelectionCriteriaGridState {
  gridColumnsState: IColumnMeta[];
}

class SelectionCriteriaGrid extends React.Component<
  ISelectionCriteriaGridProps,
  ISelectionCriteriaGridState
> {
  static defaultProps = {
    selectedRowKeys: [],
  };

  private isColumnChooserVisible = false;

  private dataGridRef: any;

  private testParameterConditions: { [key: string]: any };

  private checkMinVisibilityForSavedData: boolean;

  private ColumnMetaHeaderType = {
    DEFAULT: "DEFAULT",
    UDA: "UDA",
    TEST_CONDITION: "TEST_CONDITION",
    TEST_PARAMETER_VALUE: "TEST_PARAMETER_VALUE",
  };

  private columnsToRoundOff: [columns: string] = ["wafer_yield"];

  constructor(props: ISelectionCriteriaGridProps) {
    super(props);
    this.testParameterConditions = {};
    this.forceUpdate = this.forceUpdate.bind(this);
    this.dataGridRef = React.createRef();
    this.checkMinVisibilityForSavedData = props.selectionCounts!.length > 0;
  }

  componentDidMount() {
    const { controlType, returnForceUpdateCallback } = this.props;
    returnForceUpdateCallback(controlType, this.forceUpdate);
  }

  // appendDefaultTestParamConditions = (
  //   key: string,
  //   conditionName: string,
  //   selectedValues: string[]
  // ) => {
  //   if (this.testParameterConditions[key] === undefined) {
  //     this.testParameterConditions[key] = {};
  //   }
  //   this.testParameterConditions[key][conditionName] = selectedValues;
  // };

  sendRefreshCall = (
    controlType: any,
    selectedRowsData: any,
    selectedRowKeys: any,
    testParameterConditions: any
  ) => {
    const { onGridSelectionChanged } = this.props;
    onGridSelectionChanged(
      controlType,
      selectedRowsData,
      selectedRowKeys,
      testParameterConditions
    );
  };

  setCounterSpan = (
    entityType: string,
    selectionCount: number,
    maxSelectedCount?: number
  ) => {
    const counterSpan = document.getElementById(
      `selection-criteria-grid-counter-${entityType}`
    );
    if (counterSpan) {
      counterSpan.innerText = `Selected ${selectionCount}`;
      if (maxSelectedCount) {
        counterSpan.innerText = `${counterSpan.innerHTML}/${maxSelectedCount}`;
      }
      if (maxSelectedCount && selectionCount > maxSelectedCount) {
        counterSpan.style.color = "red";
      } else {
        counterSpan.style.color = "black";
      }
    }
  };

  setTotalRecordSpan = (entityType: string, totalRecordsCount: string) => {
    const counterSpan = document.getElementById(
      `selection-criteria-grid-total-${entityType}`
    );
    if (counterSpan) {
      counterSpan.innerText = `Total ${totalRecordsCount}`;
    }
  };

  render() {
    const {
      fullScreenControlType,
      setFullScreenControlType,
      controlType,
      customDataSource,
      gridColumns,
      getColumnCaption,
      highlightingKeys,
      filteringKeys,
      onFilterExpressionChanged,
      selectedRowKeys,
      toggleVisibility,
      changeWeight,
      columnWeight,
      pageSize,
      isCleared,
      updateDefaultWidgetItem,
      syncWithLocalStorage,
      maxSelectedCount,
      minVisibilityCriteria,
      selectionCounts,
      hideTestParamforPolicy,
      widgetIndexInDefaultWidgetsList,
      onChangeInVisibleColumns,
      updateLocalVisibleColumnsListForPolicy,
      getColumnNamesFromCallback,
      refreshGridWithControlType,
    } = this.props;
    const gridIcons = [
      {
        columnWeight: 3,
        icon: scwGridIcon3,
        text: "1/4 of Screen",
      },
      {
        columnWeight: 6,
        icon: scwGridIcon6,
        text: "2/4 of Screen",
      },
      {
        columnWeight: 9,
        icon: scwGridIcon9,
        text: "3/4 of Screen",
      },
      {
        columnWeight: 12,
        icon: scwGridIcon12,
        text: "4/4 of Screen",
      },
    ];

    let columnVisibilityList: IColumnVisibilityList[] = [];

    let columns = gridColumns;
    const localStorageColumnsKey = `${controlType
      .replaceAll(" ", "_")
      .toUpperCase()}_SCW_COLUMNS_DATA`;
    if (columns.length === 0) {
      return <div />;
    }
    if (isCleared) {
      columns = syncWithLocalStorage(columns, localStorageColumnsKey);
      updateDefaultWidgetItem(controlType, "isCleared", false);
    }

    const entityType = `${controlType.replaceAll(" ", "_").toUpperCase()}`;
    // const { highlightingKeys } = SCWidgetsList[widgetIndexInDefaultWidgetsList];
    const store = customDataSource(
      controlType,
      entityType,
      selectedRowKeys,
      highlightingKeys,
      widgetIndexInDefaultWidgetsList
    );
    const renderInfoIcon = (selectedRowKeys.length > 0 ||
      (highlightingKeys[controlType.toUpperCase()] &&
        Object.values(highlightingKeys[controlType.toUpperCase()]).length >
          0)) && (
      <OverlayTrigger
        trigger="hover"
        key="left"
        placement="bottom"
        overlay={
          <Popover id="scw-options" className="p0">
            <Popover.Content className="p0">
              <div className="pt2 pb2 pl4 pr4 rounded">
                Selected and Highlighted Row(s) will been stacked on the top as
                the grid reloads
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant="clear" className="mt4" size="sm">
          <i
            style={{
              fontSize: "18px",
            }}
            className="dx-icon-info color-info"
          />
        </Button>
      </OverlayTrigger>
    );
    const renderFullScreenIcon = (
      <Button
        variant="clear"
        className="scw-grid-more-options"
        size="sm"
        onClick={() => {
          setFullScreenControlType(controlType);
        }}
      >
        <FontAwesomeIcon size="sm" icon={faExpand} />
      </Button>
    );
    const renderResizeIcon = (
      <OverlayTrigger
        rootClose
        trigger="click"
        key="left"
        placement="left"
        overlay={
          <Popover id="scw-options" className="p0">
            <Popover.Title className="scw-grid-more-options-title">
              Resize
            </Popover.Title>
            <Popover.Content className="p0">
              <ListGroup>
                {gridIcons.map((item, index: number) => {
                  return (
                    <ListGroup.Item
                      key={`reposition-scw=grid-${index}`}
                      className={`p2 border-none ${
                        columnWeight === item.columnWeight
                          ? "scw-grid-more-options-selected"
                          : ""
                      }`}
                    >
                      <Button
                        key={item.icon.toString()}
                        variant="clear"
                        className="scw-grid-more-options"
                        size="sm"
                        onClick={() => {
                          changeWeight(controlType, item.columnWeight);
                        }}
                      >
                        <img
                          className="grid-width-icon w25 mr10"
                          src={item.icon}
                          alt={`Add Grid Of Witdh ${item.columnWeight}`}
                        />
                        {item.text}
                      </Button>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant="clear" size="sm">
          <FontAwesomeIcon size="sm" icon={faArrowsAltH} />
        </Button>
      </OverlayTrigger>
    );

    const renderColumnChooserButton = (
      <Button
        variant="clear"
        size="sm"
        onClick={() => {
          if (!this.isColumnChooserVisible) {
            this.dataGridRef.current.instance.showColumnChooser();
          } else {
            this.dataGridRef.current.instance.hideColumnChooser();
          }
          this.isColumnChooserVisible = !this.isColumnChooserVisible;
        }}
      >
        <i
          style={{
            fontSize: "18px",
          }}
          className="dx-icon-column-chooser color-primary"
        />
      </Button>
    );

    let minVisibilityCriteriaMet = true;
    if (minVisibilityCriteria && minVisibilityCriteria.length > 0) {
      if (this.checkMinVisibilityForSavedData) {
        minVisibilityCriteriaMet = false;
      }
      if (!this.checkMinVisibilityForSavedData) {
        for (let i = 0; i < minVisibilityCriteria.length; i += 1) {
          const minSC = minVisibilityCriteria[i];
          const minCriteriaSelectionCount = filteringKeys
            ? filteringKeys[minSC]
            : undefined;
          if (
            minCriteriaSelectionCount &&
            minCriteriaSelectionCount.values.length
          ) {
            minVisibilityCriteriaMet = true;
            break;
          } else if (i === minVisibilityCriteria.length - 1) {
            minVisibilityCriteriaMet = false;
          }
        }
      } else if (hideTestParamforPolicy) {
        minVisibilityCriteriaMet = false;
      } else {
        this.checkMinVisibilityForSavedData = false;
        for (let i = 0; i < selectionCounts!.length; i += 1) {
          if (
            minVisibilityCriteria.includes(selectionCounts[i].entityType) &&
            selectionCounts[i].count > 0
          ) {
            minVisibilityCriteriaMet = true;
            break;
          }
        }
      }
    }

    const isFullScreen = fullScreenControlType === controlType;

    const gridControl = (
      <>
        <div className="h6- break-left break-right break-top w-100 d-flex align-items-center justify-content-between background-color-light">
          {!isFullScreen && (
            <Button
              variant="clear"
              size="sm"
              className="flex-10"
              disabled={controlType === ""}
              onClick={() => {
                toggleVisibility(controlType);
              }}
            >
              <FontAwesomeIcon size="sm" icon={faTimes} />
            </Button>
          )}

          <div
            className={`d-flex align-items-center justify-content-between ${
              isFullScreen ? "flex-100" : "flex-70"
            }`}
          >
            <div className="d-flex break-left align-items-center p14 flex-70">
              <h6 className="m0">{controlType}</h6>
              {renderInfoIcon}
            </div>
            <div className="text-right pr10 flex-30">
              <p
                className="ml6 mb0 mr2"
                id={`selection-criteria-grid-counter-${entityType}`}
              >
                {`Selected ${selectedRowKeys.length}`}
                {maxSelectedCount ? `/${maxSelectedCount}` : ""}
              </p>
              <p
                className="ml6 mb0 mr2"
                id={`selection-criteria-grid-total-${entityType}`}
              />
            </div>
          </div>

          {!isFullScreen && (
            <>
              <div className="flex-10 d-flex justify-content-center break-left p6">
                {renderFullScreenIcon}
              </div>
              <div className="flex-10 d-flex justify-content-center break-left p6">
                {renderResizeIcon}
              </div>
            </>
          )}
          {minVisibilityCriteriaMet && (
            <div className="flex-10 d-flex justify-content-center break-left p6">
              {renderColumnChooserButton}
            </div>
          )}
        </div>
        {minVisibilityCriteriaMet ? (
          <DataGrid
            key="key_column"
            ref={this.dataGridRef}
            repaintChangesOnly
            wordWrapEnabled={false}
            dataSource={{
              store,
            }}
            remoteOperations
            loadPanel={{
              enabled: true,
              shading: true,
              showPane: false,
              indicatorSrc: spinner,
              shadingColor: "#f6f7ff",
              text: "Loading...",
            }}
            onRowPrepared={(e: any) => {
              if (e.rowType === "data") {
                if (columnVisibilityList.length === 0) {
                  columnVisibilityList = e.columns.map((item: any) => ({
                    columnName: item.dataField,
                    isVisible: false,
                    index: item.index,
                  }));
                }

                const currentRowData = e.data;

                Object.keys(currentRowData).forEach((key: string) => {
                  const indexOfColumnInVisibilityList =
                    columnVisibilityList.findIndex(
                      (columnVisibilityItem: IColumnVisibilityList) =>
                        columnVisibilityItem.columnName === key &&
                        columnVisibilityItem.isVisible === false
                    );
                  if (
                    e.data[key] !== null &&
                    indexOfColumnInVisibilityList !== -1
                  ) {
                    columnVisibilityList[
                      indexOfColumnInVisibilityList
                    ].isVisible = true;
                  }
                });

                const isHighlightable = currentRowData.isHighlightable === "1";

                if (isHighlightable) {
                  e.rowElement.className = `highlight ${e.rowElement.className}`;
                }

                if (
                  currentRowData.total_record_count !== "" &&
                  currentRowData.total_record_count !== null &&
                  currentRowData.total_record_count !== undefined
                ) {
                  this.setTotalRecordSpan(
                    entityType,
                    currentRowData.total_record_count.toString()
                  );
                }
              }
            }}
            columnAutoWidth
            defaultSelectedRowKeys={selectedRowKeys}
            className="border-all custom-scw-data-grid-container"
            id={`${controlType
              .replaceAll(" ", "-")
              .toLowerCase()}-scw-data-grid`}
            showRowLines={false}
            showBorders={false}
            hoverStateEnabled
            height={
              isFullScreen
                ? window.innerHeight - 300
                : (window.innerHeight - 300) / 2
            }
            allowColumnReordering
            rowAlternationEnabled
            showColumnLines
            onSelectionChanged={(e: any) => {
              this.checkMinVisibilityForSavedData = false;
              this.setCounterSpan(
                entityType,
                e.selectedRowKeys.length,
                maxSelectedCount
              );
              this.sendRefreshCall(
                controlType,
                e.selectedRowsData,
                e.selectedRowKeys,
                this.testParameterConditions
              );
            }}
            columnResizingMode="widget"
            filterRow={{
              visible: true,
            }}
            onContentReady={(e: any) => {
              let visibleColumns = e.component.getVisibleColumns();
              if (visibleColumns.length >= 2 && !isCleared) {
                visibleColumns = visibleColumns.slice(1);
                const visibleColumnsForComparison = visibleColumns.filter(
                  (column: any) => column.caption !== "#"
                );
                let prevColsDataFields = updateLocalVisibleColumnsListForPolicy
                  ? getColumnNamesFromCallback(localStorageColumnsKey)
                  : JSON.parse(
                      localStorage.getItem(localStorageColumnsKey) || ""
                    )?.map((x: any) => x.columnName);
                prevColsDataFields = prevColsDataFields.sort();
                const currColsDataFields = visibleColumnsForComparison
                  .map((x: any) => x.dataField)
                  .sort();

                // check column chooser action has been performed by comparing prev. and curr columns
                if (
                  JSON.stringify(prevColsDataFields) !==
                  JSON.stringify(currColsDataFields)
                ) {
                  const columnsWithSequence = JSON.stringify(
                    visibleColumns
                      .filter((x: any) => x.dataField !== undefined)
                      .map((item: any, index: number) => ({
                        columnName: item.dataField,
                        sequence: index,
                      }))
                  );

                  gridColumns.forEach((element: any) => {
                    element.visibleByDefault = false;
                  });

                  const visibleColumnsList = visibleColumns.map((item: any) => {
                    return item.dataField;
                  });

                  for (let i = 0; i < visibleColumnsList.length; i += 1) {
                    if (i > 0) {
                      gridColumns.forEach((element: any) => {
                        if (visibleColumnsList[i] === element.dataField) {
                          element.visibleByDefault = true;
                        }
                      });
                    }
                  }
                  if (updateLocalVisibleColumnsListForPolicy) {
                    updateLocalVisibleColumnsListForPolicy(
                      `${entityType}_SCW_COLUMNS_DATA`,
                      columnsWithSequence
                    );
                  } else {
                    localStorage.setItem(
                      localStorageColumnsKey,
                      columnsWithSequence
                    );
                  }
                  // update the default widgets list
                  onChangeInVisibleColumns(
                    widgetIndexInDefaultWidgetsList,
                    listOfEntityTypes,
                    visibleColumns
                  );
                  refreshGridWithControlType(controlType);
                }
              }
              onFilterExpressionChanged(
                controlType,
                e.component.getCombinedFilter()
              );
            }}
          >
            <Toolbar visible={false} />
            <Scrolling
              mode="infinite"
              useNative
              scrollByContent
              showScrollbar="onHover"
            />
            <Paging enabled pageSize={pageSize} />
            <ColumnChooser
              enabled
              mode="select"
              width="200"
              allowSearch
              title="Column Chooser"
            />
            <Selection
              mode="multiple"
              allowSelectAll
              selectAllMode="allPages"
              showCheckBoxesMode="onClick"
            />
            <Sorting mode="multiple" />

            <Column
              visible
              allowResizing
              visibleIndex={0}
              width={40}
              showInColumnChooser={false}
              caption="#"
              cellRender={(e) => {
                return <div>{`${e.row.loadIndex + 1}. `}</div>;
              }}
            />

            {controlType === "Test Parameter" && (
              <Column
                allowResizing
                allowSorting
                key={`${controlType}_failed_count`}
                dataField="failed_count"
                caption="Failed Count"
                visible
                allowFiltering
                visibleIndex={3}
              />
            )}

            {/* {columns.filter((column) => column.columnCategory === this.ColumnMetaHeaderType.TEST_CONDITION)
                .map((column: IColumnMeta, colIndex: number) => {
                  // const isParentColumn = column.entityType !== controlType.toUpperCase().replace(' ', '_');
                  const dataField = `${column.entityType.replaceAll(' ', '_')
                    .toLowerCase()}_${column.columnName}`;

                  return (
                    <Column
                      key={controlType + colIndex}
                      dataField={dataField}
                      width={200}
                      allowFiltering={false}
                      allowSorting={false}
                      caption={getColumnCaption(controlType, column)}
                      cssClass="p0"
                      headerCellRender={() => {
                        return (
                          <div
                            className="test-parameter-condition-header-background"
                          >
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                              <span>
                                {toTitleCase(column.columnName)}
                              </span>
                              <Hint
                                className="mt2"
                                hint={{
                                  message: 'Test Parameter Condition',
                                }}
                                variant="light"
                              />
                            </div>
                          </div>
                        );
                      }}
                      cellRender={(e: any) => {
                        if (e.value !== '' && e.value !== null && e.value !== undefined) {
                          return (
                            <CustomizedMultipleDropdown
                              displayMaxSelection={2}
                              list={e.value.split(',')
                                .map((value: string) => ({
                                  key: value,
                                  value
                                }))}
                              placeholder="None"
                              onInit={() => {
                                this.appendDefaultTestParamConditions(
                                  e.key,
                                  e.column.caption,
                                  e.data[e.column.dataField].split(','),
                                );
                              }}
                              selectedRowKeys={e.value.split(',')
                                .map((value: string) => ({
                                  key: value,
                                  value
                                }))}
                              selectionHandler={(selectionEvent: any) => {
                                e.value = selectionEvent.map((selectedValues: any) => (selectedValues.value))
                                  .join(',');
                                this.appendDefaultTestParamConditions(
                                  e.key,
                                  e.column.caption,
                                  selectionEvent.map((selectedValues: any) => (selectedValues.value)),
                                );
                                onGridSelectionChanged(controlType, this.selectedRowsData, this.selectedRowKeys, this.testParameterConditions);
                              }}
                            />
                          );
                        }
                        return '';
                      }}
                    />
                  );
                })} */}
            {columns
              .filter(
                (column) =>
                  column.columnCategory !==
                  this.ColumnMetaHeaderType.TEST_CONDITION
              )
              .map((column: IColumnMeta, colIndex: number) => {
                const isParentColumn =
                  column.entityType !==
                  controlType.toUpperCase().replaceAll(" ", "_");
                let dataField = "";
                dataField = column.dataField;

                return (
                  <Column
                    // allowEditing={false}
                    allowResizing
                    showInColumnChooser={!column.dataField.endsWith("_id")}
                    allowSorting
                    cssClass={isParentColumn ? "parent-column-background" : ""}
                    key={controlType + colIndex}
                    dataField={dataField}
                    caption={getColumnCaption(controlType, column)}
                    visible={column.visibleByDefault}
                    allowFiltering
                    visibleIndex={column.sequence}
                    cellRender={(e) => {
                      return (
                        <pre className="default-font-family default-font-size mb0">
                          {this.columnsToRoundOff.includes(dataField) &&
                          e.data[dataField]
                            ? e.data[dataField].toFixed(2)
                            : e.data[dataField]}
                        </pre>
                      );
                    }}
                  />
                );
              })}
          </DataGrid>
        ) : (
          <div
            style={{
              height: `${(window.innerHeight - 300) / 2}px`,
            }}
            className="border-all pl30 pr30 background-color-secondary-background d-flex align-items-center justify-content-center"
          >
            <div>
              <p className="grid-font-size">
                Please select data from at least one of the preceding grids to
                see associated{" "}
                <b>
                  {controlType}
                  (s)
                </b>
              </p>
              {minVisibilityCriteria?.map((msc, i) => (
                <p className="mb0 grid-font-size">
                  {i + 1}
                  {". "}
                  {msc}
                </p>
              ))}
            </div>
          </div>
        )}
      </>
    );

    return !isFullScreen ? (
      gridControl
    ) : (
      <Modal
        show
        enforceFocus={false}
        dialogClassName="modal-90w-width-only"
        onHide={() => {
          setFullScreenControlType(undefined);
        }}
      >
        <Modal.Header closeButton className="border-none">
          <Modal.Title className="text-center w-100">
            <p className="text-center">{controlType}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignItems: "start" }}>
          <ScrollView
            showScrollbar="always"
            scrollByThumb
            className="height-80vh"
            reachBottomText=""
          >
            <div>{gridControl}</div>
          </ScrollView>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SelectionCriteriaGrid;
