import React from 'react';
import { Form } from 'react-bootstrap';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface ICheckboxProps {
  disabled?: boolean;
  children: React.ReactNode;
  hint?: IHint;
  name?: string | undefined;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: any;
  className?: any;
  type?: 'checkbox' | 'radio';
}

const Checkbox = (props: ICheckboxProps) => {
  const {
    children,
    hint,
    name,
    defaultChecked,
    checked,
    onChange,
    className,
    type,
    disabled,
  } = props;

  return (
    <Form.Label
      className="lh24"
      style={disabled ? {
        pointerEvents: 'none',
        opacity: '0.4'
      } : {}}
    >
      <Form.Check
        disabled={disabled}
        type={type}
        className={className}
        name={name}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={(e: any) => {
          if (onChange !== undefined) {
            onChange(e);
          }
        }}
      />
      {children}
      <Hint
        hint={hint}
        variant="icon"
      />
    </Form.Label>

  );
};

Checkbox.defaultProps = {
  defaultChecked: false,
  name: undefined,
  className: undefined,
  onChange: () => undefined,
  type: 'checkbox',
};

export default Checkbox;
