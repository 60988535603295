/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnChooser from "components/reports/raw-grid/column-chooser";
import PublishSubscribe, {
  EventTypes,
} from "components/utility-component/wafer-map-widget/PublishSubscribe";
import DataGrid, {
  Column,
  Editing,
  FilterRow,
  Grouping,
  GroupPanel,
  Paging,
  RowDragging,
  Scrolling,
  Sorting,
  Summary,
  GroupItem,
  RequiredRule,
  ColumnFixing,
} from "devextreme-react/data-grid";
import Query from "devextreme/data/query";
import _ from "lodash";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import spinner from "../../../assets/icons/spinner.gif";
import colors from "../../../assets/colors.json";
import "../../../styles/dx-theme-override/dx-override.scss";
// eslint-disable-next-line import/no-named-default
import { default as CustomizedButton } from "../hint-controls/Button";
import CreateDynamicColumn from "./CreateDynamicColumn";
import "./customized-data-grid.scss";
import AdvanceFilter from "./advance-filter";
import ModalPopup from "../modal-popup/modal-popup";
import { toast } from "react-toastify";

// eslint-disable-next-line import/order

interface IMoreOptions {
  title: string;
  clickAction?: (e: any) => void;
}

export interface ICustomizedDataGridColumnFields {
  caption?: string;
  dataField?: string;
  dataType?: string;
  filterColumn?: string;
  showInfo?: boolean;
  wordWrap?: boolean;
  allowEditing?: boolean;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  colorField?: boolean;
  minWidth?: number | string;
  width?: number;
  groupIndex?: number;
  autoExpand?: boolean;
  viewMode?: "tag" | "anchor" | "arrayLength";
  onClick?: any;
  cellRender?: any;
  editCellRender?: any;
  editCellComponent?: any;
  groupCellRender?: any;
  selected?: any;
  calculateSortValue?: any;
  sortOrder?: string;
  calculateDisplayValue?: (rowData: Object) => any;
  fields?: ICustomizedDataGridColumnFields[] | [];
  fixed?: boolean;
  calculateFilterExpression?: (
    filterValue: any,
    filterType: any
  ) => (data: any) => boolean;
}

export interface ICustomizedDataGridProps {
  id?: string;
  type?: string;
  onMount?: any;
  setRef?: (ref: DataGrid) => void;
  setCustomGridInstanceHandler?: (
    customizedDataGrid: CustomizedDataGrid,
    keyIndex: string
  ) => void;
  keyExpr?: any;
  publisherId?: string;
  showFilterRow?: boolean;
  showAdvancedFilters?: boolean;
  enableColumnChooser?: boolean;
  tableData: any;
  selectionMode?: "single" | "multiple";
  fields: ICustomizedDataGridColumnFields[];
  moreOptions?: IMoreOptions[];
  onRowSelection?: (e: any) => void;
  getSelectedData?: (e: any) => void;
  onContextMenuItemSelected?: (e: any) => void;
  selectedRowKeys?: any;
  allowRowReorder?: boolean;
  onRowReorder?: (e: any) => void;
  wordWrapEnabled?: boolean;
  similarDeletionField?: string;
  width?: string | number;
  columnAutoWidth?: boolean;
  height?: string | number;
  showGroupPanel?: boolean;
  filterValue?: any;
  advanceFilterExpression?: any;
  isAggregated?: boolean;
  totalRowsToDisplay?: number;
  groupSummaryFields?: any;
  customColumnChooser?: boolean;
  showBorders?: boolean;
  showColumnLines?: boolean;
  showRowLines?: boolean;
  filteredCount?: any;
  getSummaryCaption?: any;
  showDynamicColumn?: boolean;
  rawGrid?: boolean;
  advancedFilterInput?: any;
  onContentReady?: any;
  rowAlternationEnabled?: boolean;
  scrollMode?: "standard" | "virtual";
  allowUpdating?: boolean;
  editingMode?: "row" | "cell" | "popup" | "batch";
  editingComponent?: any;
  allowAdding?: boolean;
  allowDeleting?: boolean;
  showContextMenu?: boolean;
  onInitNewRow?: (event: any) => void;
  onEditingStart?: (event: any) => void;
  onRowInserting?: (event: any) => void;
  onRowRemoved?: (event: any) => void;
  onRowInserted?: (event: any) => void;
  onSaved?: (event: any) => void;
  onEditorPreparing?: (event: any) => void;
  onRowUpdating?: (event: any) => void;
  onRowUpdated?: (event: any) => void;
  onToolbarPreparing?: (event: any) => void;
  autoExpandAll?: boolean;
  columnFixing?: boolean;
  columnHidingEnabled?: boolean;
  key?: string;
  newRowPosition?:
    | "first"
    | "last"
    | "pageTop"
    | "pageBottom"
    | "viewportTop"
    | "viewportBottom";
}

interface ICustomizedDataGridState {
  advancedFilterFlag: boolean;
  tableData: any;
  fullTableData: any;
  dataGrid: DataGrid | null;
  selectedData: any[];
  advancedFilter: string;
  cachedData?: any;
}

class CustomizedDataGrid extends React.Component<
  ICustomizedDataGridProps,
  ICustomizedDataGridState
> {
  static defaultProps: Partial<ICustomizedDataGridProps> = {
    selectionMode: "single",
    showContextMenu: true,
    allowUpdating: false,
    allowAdding: false,
    allowDeleting: false,
    showAdvancedFilters: true,
    enableColumnChooser: true,
    wordWrapEnabled: false,
    width: "100%",
    height: 500,
    showGroupPanel: true,
    isAggregated: false,
    groupSummaryFields: [],
    customColumnChooser: false,
    showBorders: true,
    showColumnLines: false,
    showRowLines: false,
    totalRowsToDisplay: 10,
    filteredCount: undefined,
    getSummaryCaption: undefined,
    showDynamicColumn: false,
    rawGrid: false,
    advancedFilterInput: "",
    onContentReady: undefined,
    scrollMode: "standard",
    rowAlternationEnabled: false,
    tableData: [],
    showFilterRow: true,
    autoExpandAll: true,
    columnFixing: false,
    // columnHidingEnabled: false,
    newRowPosition: "last",
  };

  private fromGrid = false;

  private lastFilter: any = null;

  private advanceFilterupdatedInput: any = "";

  // advanceFilterInput: HTMLInputElement | null = null;

  constructor(props: ICustomizedDataGridProps) {
    super(props);
    this.onClearAdvancedFilter = this.onClearAdvancedFilter.bind(this);
    this.advancedFilter = this.advancedFilter.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.applyColumnChanges = this.applyColumnChanges.bind(this);
    this.getFilteredData = this.getFilteredData.bind(this);
    this.state = {
      advancedFilterFlag: false,
      tableData: [],
      fullTableData: [],
      cachedData: [],
      dataGrid: null,
      selectedData: [],
      // eslint-disable-next-line react/no-unused-state
      advancedFilter: "",
    };
  }

  static getDerivedStateFromProps(
    props: ICustomizedDataGridProps,
    state: ICustomizedDataGridState
  ) {
    if (!_.isEqual(state.cachedData, props.tableData)) {
      const newTableData = [];
      const newFullTableData = [];
      const newCachedTableData = [];
      for (let i = 0; i < props.tableData.length; i += 1) {
        const hiddenFlag = false;
        newTableData.push(_.cloneDeep(props.tableData[i]));
        newTableData[i].hidden = hiddenFlag;
        newFullTableData.push(_.cloneDeep(props.tableData[i]));
        newFullTableData[i].hidden = hiddenFlag;
        newCachedTableData.push(_.cloneDeep(props.tableData[i]));
      }
      return {
        tableData: newTableData,
        fullTableData: newFullTableData,
        cachedData: newCachedTableData,
      };
    }
    return null;
  }

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
    const { setCustomGridInstanceHandler, publisherId } = this.props;
    if (setCustomGridInstanceHandler && publisherId) {
      setCustomGridInstanceHandler(this, publisherId!);
    }
  }

  onContentReady = () => {
    const { advancedFilterFlag, dataGrid, fullTableData } = this.state;
    const { publisherId, filteredCount, onContentReady } = this.props;

    if (filteredCount !== undefined && dataGrid !== null) {
      filteredCount.children.filteredRecords.innerText =
        dataGrid.instance.totalCount();
      filteredCount.children.selectedLots.innerText = _.uniqBy(
        dataGrid.instance.getDataSource().items(),
        "lot÷Key"
      ).length;
      filteredCount.children.selectedWafers.innerText = _.uniqBy(
        dataGrid.instance.getDataSource().items(),
        "wafer÷id"
      ).length;
    }
    if (advancedFilterFlag === true) return;
    if (dataGrid == null) return;
    const filterExp = dataGrid.instance.getCombinedFilter();
    if (filterExp) {
      const sortColumn = dataGrid.instance.columnOption("sortIndex:0") || {
        dataField: "",
      };
      const filteredData = Query(fullTableData)
        .filter(filterExp)
        .sortBy(sortColumn.dataField, sortColumn.sortOrder === "desc")
        .toArray();
      if (publisherId) {
        const ps = PublishSubscribe();
        const filteredDataList: any[] = filteredData.map((data: any) => {
          return data;
        });
        ps.publishWithCustomGridID(
          EventTypes.FILTER_EXPRESSION,
          { filterExp },
          publisherId
        );
        ps.publishWithCustomGridID(
          EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
          { list: filteredDataList },
          publisherId
        );
      }
    } else if (publisherId && this.lastFilter) {
      const ps = PublishSubscribe();
      ps.publishWithCustomGridID(
        EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID,
        {},
        publisherId
      );
    }
    this.lastFilter = filterExp;
    if (onContentReady !== undefined) {
      onContentReady();
    }
  };

  setFilterDataFromCustomGrid = (data: {
    list: any[];
    subscriberKey: string;
    publisherKey: string;
  }) => {
    const { fullTableData } = this.state;
    const selectedRowsData: any[] = [];
    data.list.forEach((dataEntry: any) => {
      fullTableData.forEach((tableDataEntry: any) => {
        if (
          data.subscriberKey in tableDataEntry &&
          data.publisherKey in dataEntry &&
          tableDataEntry[data.subscriberKey] === dataEntry[data.publisherKey]
        ) {
          selectedRowsData.push(tableDataEntry);
        }
      });
    });
    this.setState({ tableData: selectedRowsData });
  };

  // eslint-disable-next-line react/sort-comp
  clearFilterData = () => {
    this.setState((prevState) => ({ tableData: prevState.fullTableData }));
  };

  onClearAdvancedFilter = () => {
    const { publisherId, advanceFilterExpression } = this.props;
    if (advanceFilterExpression !== undefined) {
      advanceFilterExpression("");
    }
    if (publisherId) {
      const ps = PublishSubscribe();
      ps.publishWithCustomGridID(
        EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID,
        {},
        publisherId
      );
    }
    this.advanceFilterupdatedInput = undefined;
    this.setState((prevState) => ({
      tableData: prevState.fullTableData,
      advancedFilterFlag: !prevState.advancedFilterFlag,
    }));
  };

  // eslint-disable-next-line class-methods-use-this
  parse(str: any) {
    // eslint-disable-next-line no-new-func
    return Function(`'use strict'; return (${str})`)();
  }

  getColumnInfo = (columnInfo: any) => {
    const { fields, rawGrid } = this.props;
    let expression: any = columnInfo.columnExpression.toString();
    if (!rawGrid) {
      fields.forEach((field: any) => {
        expression = expression.replaceAll(
          field.caption.toString(),
          `item.${field.dataField}`
        );
      });
    } else {
      // Raw Data Grid Logic for Filter Expression.
      fields.forEach((field: any) => {
        if (expression.indexOf(field.filterColumn) !== -1) {
          expression = expression.replaceAll(
            field.filterColumn,
            `item['${field.dataField}']`
          );
        } else if (field.fields !== undefined) {
          field.fields.forEach((subfield: any) => {
            if (expression.indexOf(subfield.filterColumn) !== -1) {
              expression = expression.replaceAll(
                subfield.filterColumn,
                `item['${subfield.dataField}']`
              );
            }
          });
        }
      });
    }

    const { fullTableData } = this.state;
    fullTableData.forEach((item: any) => {
      // eslint-disable-next-line no-eval
      item[columnInfo.columnName] = eval(expression);
    });
    const { dataGrid } = this.state;
    if (dataGrid !== null) {
      // dataGrid.instance.addColumn({
      //   caption: columnInfo.columnName, dataField: columnInfo.columnName, dataType: columnInfo.columnType[0], minWidth: 70,
      // });
    }
    fields.push({
      caption: columnInfo.columnName,
      dataField: columnInfo.columnName,
      dataType: columnInfo.columnType,
      minWidth: 70,
      filterColumn: columnInfo.columnName,
    });
    this.setState({
      tableData: fullTableData,
    });
  };

  getFilteredData(advanceFilterInput: string) {
    if (advanceFilterInput.length === 0) return;
    const { fields, advanceFilterExpression, rawGrid } = this.props;
    const { fullTableData } = this.state;
    let exp = advanceFilterInput.toString();
    let expKeys = ` ${exp}`.slice(1);
    if (!rawGrid) {
      fields.forEach((field: any) => {
        if (exp.indexOf(field.caption) !== -1) {
          expKeys = expKeys.replaceAll(
            field.caption,
            `${field.dataField.replace("÷", ".")}`
          );
          exp = exp.replaceAll(field.caption, `data['${field.dataField}']`);
        }
      });
      if (advanceFilterExpression !== undefined) {
        advanceFilterExpression(
          expKeys
            .toLowerCase()
            .replaceAll("==", "=")
            .replaceAll("contains", "in")
            .replaceAll("&&", " and ")
            .replaceAll("||", " or ")
        );
      }
    } else {
      // Raw Data Grid Logic for Filter Expression.
      fields.forEach((field: any) => {
        if (exp.indexOf(field.filterColumn) !== -1) {
          expKeys = expKeys.replaceAll(
            field.filterColumn,
            `${field.dataField.replaceAll("÷", ".")}`
          );
          exp = exp.replaceAll(
            field.filterColumn,
            `data['${field.dataField}']`
          );
        } else if (field.fields !== undefined) {
          field.fields.forEach((subfield: any) => {
            if (exp.indexOf(subfield.filterColumn) !== -1) {
              const splitedValue = subfield.dataField.split("÷");
              expKeys = expKeys.replaceAll(
                subfield.filterColumn,
                `${splitedValue[0]}.${splitedValue[1]}`
              );
              exp = exp.replaceAll(
                subfield.filterColumn,
                `data['${subfield.dataField}']`
              );
            }
          });
        }
      });
      if (advanceFilterExpression !== undefined) {
        advanceFilterExpression(
          expKeys
            .toLowerCase()
            .replaceAll("==", "=")
            .replaceAll("contains", "in")
            .replaceAll("&&", " and ")
            .replaceAll("||", " or "),
          advanceFilterInput.toString()
        );
      }
    }
    const filteredData = Query(fullTableData)
      .toArray()
      // eslint-disable-next-line no-unused-vars
      .filter((data: any) => {
        try {
          // eslint-disable-next-line no-eval
          return eval(exp);
        } catch (e) {
          return false;
        }
      });
    const returnObject: any = {
      Expression: exp,
      FilteredData: filteredData,
    };
    // eslint-disable-next-line consistent-return
    return returnObject;
  }

  advancedFilter = (advanceFilterInput: string) => {
    const filteredData = this.getFilteredData(advanceFilterInput);
    const { publisherId } = this.props;
    this.advanceFilterupdatedInput = advanceFilterInput;
    if (publisherId) {
      const ps = PublishSubscribe();
      const filteredDataList: any[] = filteredData.FilteredData.map(
        (data: any) => {
          return data;
        }
      );
      ps.publishWithCustomGridID(
        EventTypes.FILTER_EXPRESSION,
        { filterExp: filteredData.Expression },
        publisherId
      );
      ps.publishWithCustomGridID(
        EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
        { list: filteredDataList },
        publisherId
      );
    }
    // let { advancedFilterInput } = this.props;
    // advancedFilterInput = advancedFilterInput;
    this.setState({
      tableData: filteredData.FilteredData,
      advancedFilterFlag: true,
    });
  };

  onReorder = (e: any) => {
    const { onRowReorder } = this.props;
    const { tableData } = this.state;
    const element = tableData[e.fromIndex];
    tableData.splice(e.fromIndex, 1);
    tableData.splice(e.toIndex, 0, element);
    this.setState({ tableData });
    e.component.refresh();
    if (onRowReorder) {
      onRowReorder(tableData);
    }
  };

  onSelectionChanged = (e: any) => {
    if (this.fromGrid === true) {
      this.fromGrid = false;
      return;
    }
    const { onRowSelection, publisherId, isAggregated } = this.props;
    if (onRowSelection !== undefined) onRowSelection(e);
    if (!publisherId) return;
    const ps = PublishSubscribe();
    const selectedData: any[] = [];
    for (let i = 0; i < e.selectedRowsData.length; i += 1) {
      if (!e.selectedRowsData[i].hidden) {
        selectedData.push(e.selectedRowsData[i]);
      }
    }
    const deselectedData: any[] = e.currentDeselectedRowKeys.map(
      (data: any) => {
        return data;
      }
    );
    ps.publishWithCustomGridID(
      EventTypes.SELECTION_ON_CUSTOMIZED_GRID,
      {
        selectedData,
        currentDeselectedRowKeys: deselectedData,
        isAggregated,
      },
      publisherId
    );
    if (onRowSelection === undefined) {
      this.setState({ selectedData: e.selectedRowsData }, () => {
        const { getSelectedData } = this.props;
        if (getSelectedData !== undefined) {
          getSelectedData(e.selectedRowsData);
        }
      });
    }
  };

  onDeleteSimilarItems = (data: any) => {
    const { tableData, dataGrid, fullTableData } = this.state;
    const { publisherId, similarDeletionField } = this.props;
    if (!dataGrid || !publisherId || !similarDeletionField) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    const newSelectedRows = _.cloneDeep(
      dataGrid.instance.getSelectedRowsData()
    );
    const deletedData = [];
    for (let i = 0; i < newTableData.length; i += 1) {
      if (
        similarDeletionField in newTableData[i] &&
        similarDeletionField in data &&
        newTableData[i][similarDeletionField] === data[similarDeletionField]
      ) {
        deletedData.push(...newTableData.splice(i, 1));
        i -= 1;
      }
    }
    for (let i = 0; i < newFullTableData.length; i += 1) {
      if (
        similarDeletionField in newFullTableData[i] &&
        similarDeletionField in data &&
        newFullTableData[i][similarDeletionField] === data[similarDeletionField]
      ) {
        newFullTableData.splice(i, 1);
        i -= 1;
      }
    }
    for (let i = 0; i < newSelectedRows.length; i += 1) {
      if (
        similarDeletionField in newSelectedRows[i] &&
        similarDeletionField in data &&
        newSelectedRows[i][similarDeletionField] === data[similarDeletionField]
      ) {
        newSelectedRows.splice(i, 1);
        i -= 1;
      }
    }
    this.fromGrid = true;
    const ps = PublishSubscribe();
    ps.publishWithCustomGridID(
      EventTypes.DELETION_ON_CUSTOMIZED_GRID,
      {
        deletedData,
      },
      publisherId
    );
    this.setState({
      selectedData: newSelectedRows,
      tableData: newTableData,
      fullTableData: newFullTableData,
    });
  };

  onDeleteCurrentItem = (data: any) => {
    const { tableData, dataGrid, fullTableData } = this.state;
    const { publisherId } = this.props;
    if (!dataGrid || !publisherId) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    const newSelectedRows = _.cloneDeep(
      dataGrid.instance.getSelectedRowsData()
    );
    const deletedData = [];
    for (let i = 0; i < newTableData.length; i += 1) {
      if (_.isEqual(data, newTableData[i])) {
        deletedData.push(...newTableData.splice(i, 1));
        i -= 1;
      }
    }
    for (let i = 0; i < newFullTableData.length; i += 1) {
      if (_.isEqual(data, newFullTableData[i])) {
        newFullTableData.splice(i, 1);
        i -= 1;
      }
    }
    for (let i = 0; i < newSelectedRows.length; i += 1) {
      if (_.isEqual(data, newSelectedRows[i])) {
        newSelectedRows.splice(i, 1);
        i -= 1;
      }
    }
    this.fromGrid = true;
    const ps = PublishSubscribe();
    ps.publishWithCustomGridID(
      EventTypes.DELETION_ON_CUSTOMIZED_GRID,
      {
        deletedData,
      },
      publisherId
    );
    this.setState({
      selectedData: newSelectedRows,
      tableData: newTableData,
      fullTableData: newFullTableData,
    });
  };

  onToggleShowItems = (
    data: any,
    showSimilar: boolean,
    showSelected: boolean,
    showAll: boolean,
    show: boolean
  ) => {
    const { tableData, dataGrid, fullTableData } = this.state;
    const { publisherId, similarDeletionField } = this.props;
    if (!dataGrid || !publisherId) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    const newSelectedRows = dataGrid.instance.getSelectedRowsData();
    for (let h = 0; h < (showSelected ? newSelectedRows.length : 1); h += 1) {
      for (let i = 0; i < newTableData.length; i += 1) {
        if (
          (showAll && newTableData[i].hidden === show) ||
          (!showAll &&
            !showSelected &&
            !showSimilar &&
            _.isEqual(data, newTableData[i]) &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            !showSelected &&
            showSimilar &&
            similarDeletionField &&
            similarDeletionField in newTableData[i] &&
            similarDeletionField in data &&
            newTableData[i][similarDeletionField] ===
              data[similarDeletionField] &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            showSelected &&
            !showSimilar &&
            _.isEqual(newSelectedRows[h], newTableData[i]) &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            showSelected &&
            showSimilar &&
            similarDeletionField &&
            similarDeletionField in newTableData[i] &&
            similarDeletionField in newSelectedRows[h] &&
            newTableData[i][similarDeletionField] ===
              newSelectedRows[h][similarDeletionField] &&
            newTableData[i].hidden === show)
        ) {
          newTableData[i].hidden = !show;
        }
      }
      for (let i = 0; i < newFullTableData.length; i += 1) {
        if (
          (showAll && newFullTableData[i].hidden === show) ||
          (!showAll &&
            !showSelected &&
            !showSimilar &&
            _.isEqual(data, newFullTableData[i]) &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            !showSelected &&
            showSimilar &&
            similarDeletionField &&
            similarDeletionField in newFullTableData[i] &&
            similarDeletionField in data &&
            newFullTableData[i][similarDeletionField] ===
              data[similarDeletionField] &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            showSelected &&
            !showSimilar &&
            _.isEqual(newSelectedRows[h], newFullTableData[i]) &&
            newTableData[i].hidden === show) ||
          (!showAll &&
            showSelected &&
            showSimilar &&
            similarDeletionField &&
            similarDeletionField in newFullTableData[i] &&
            similarDeletionField in newSelectedRows[h] &&
            newFullTableData[i][similarDeletionField] ===
              newSelectedRows[h][similarDeletionField] &&
            newTableData[i].hidden === show)
        ) {
          newFullTableData[i].hidden = !show;
        }
      }
    }
    let hiddenData: any = [];
    if (newTableData.length > 0) {
      hiddenData = newTableData.filter((item: any) => item.hidden === true);
    }
    const ps = PublishSubscribe();
    ps.publishWithCustomGridID(
      show
        ? EventTypes.UNHIDE_ON_CUSTOMIZED_GRID
        : EventTypes.HIDE_ON_CUSTOMIZED_GRID,
      {
        hiddenData,
      },
      publisherId
    );

    this.setState(
      {
        tableData: newTableData,
        fullTableData: newFullTableData,
      },
      () => {
        const { onContextMenuItemSelected } = this.props;
        if (onContextMenuItemSelected) {
          onContextMenuItemSelected(hiddenData);
        }
      }
    );
  };

  getKeysArray = (key: string | string[]) => {
    let keys: string[] = [];
    if (!Array.isArray(key)) {
      keys = [key];
    } else {
      keys = [...key];
    }
    return keys;
  };

  dataSelected = (data: {
    selectedData: any[];
    subscriberKey: string | string[];
    publisherKey: string | string[];
  }) => {
    const subKeys: string[] = this.getKeysArray(data.subscriberKey);
    const pubKeys: string[] = this.getKeysArray(data.publisherKey);
    const { tableData } = this.state;
    const selectedRowsData: any[] = [];
    data.selectedData.forEach((dataEntry: any) => {
      tableData.forEach((tableDataEntry: any) => {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in tableDataEntry,
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in dataEntry,
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, index) =>
              accuVal &&
              tableDataEntry[subKeys[index]] === dataEntry[pubKeys[index]],
            true
          ) &&
          !tableDataEntry.hidden
        ) {
          selectedRowsData.push(tableDataEntry);
        }
      });
    });
    this.fromGrid = true;
    if ("currentDeselectedRowKeys" in data) {
      this.setState({ selectedData: selectedRowsData });
    } else {
      this.setState((prevState) => {
        return {
          selectedData: [...prevState.selectedData, ...selectedRowsData],
        };
      });
    }
  };

  dataDeleted = (data: {
    deletedData: any[];
    subscriberKey: string | string[];
    publisherKey: string | string[];
  }) => {
    const subKeys: string[] = this.getKeysArray(data.subscriberKey);
    const pubKeys: string[] = this.getKeysArray(data.publisherKey);

    const { tableData, dataGrid, fullTableData } = this.state;
    if (!dataGrid) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    const newSelectedRows = _.cloneDeep(
      dataGrid.instance.getSelectedRowsData()
    );
    for (let h = 0; h < data.deletedData.length; h += 1) {
      for (let i = 0; i < newTableData.length; i += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newTableData[i],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.deletedData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newTableData[i][subKeys[keyInd]] ===
                data.deletedData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newTableData.splice(i, 1);
          i -= 1;
        }
      }

      for (let j = 0; j < newFullTableData.length; j += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newFullTableData[j],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.deletedData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newFullTableData[j][subKeys[keyInd]] ===
                data.deletedData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newFullTableData.splice(j, 1);
          j -= 1;
        }
      }

      for (let k = 0; k < newSelectedRows.length; k += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newSelectedRows[k],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.deletedData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newSelectedRows[k][subKeys[keyInd]] ===
                data.deletedData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newSelectedRows.splice(k, 1);
          k -= 1;
        }
      }
    }
    this.fromGrid = true;
    this.setState({
      selectedData: newSelectedRows,
      tableData: newTableData,
      fullTableData: newFullTableData,
    });
  };

  dataHidden = (data: {
    hiddenData: any[];
    subscriberKey: string | string[];
    publisherKey: string | string[];
  }) => {
    const subKeys: string[] = this.getKeysArray(data.subscriberKey);
    const pubKeys: string[] = this.getKeysArray(data.publisherKey);

    const { tableData, dataGrid, fullTableData } = this.state;
    if (!dataGrid) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    const newSelectedRows = _.cloneDeep(
      dataGrid.instance.getSelectedRowsData()
    );
    for (let h = 0; h < data.hiddenData.length; h += 1) {
      for (let i = 0; i < newTableData.length; i += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newTableData[i],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.hiddenData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newTableData[i][subKeys[keyInd]] ===
                data.hiddenData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newTableData[i].hidden = true;
        }
      }

      for (let j = 0; j < newFullTableData.length; j += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newFullTableData[j],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.hiddenData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newFullTableData[j][subKeys[keyInd]] ===
                data.hiddenData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newFullTableData[j].hidden = true;
        }
      }
      // finish selection of hidden data
      let isRemoved = false;
      for (let i = 0; !isRemoved && i < newSelectedRows.length; i += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newSelectedRows[i],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.hiddenData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, index) =>
              accuVal &&
              newSelectedRows[i][subKeys[index]] ===
                data.hiddenData[h][pubKeys[index]],
            true
          )
        ) {
          newSelectedRows.splice(i, 1);
          isRemoved = true;
        }
      }
    }
    this.fromGrid = true;
    this.setState({
      selectedData: newSelectedRows,
      tableData: newTableData,
      fullTableData: newFullTableData,
    });
  };

  dataUnHidden = (data: {
    hiddenData: any[];
    subscriberKey: string | string[];
    publisherKey: string | string[];
  }) => {
    const subKeys: string[] = this.getKeysArray(data.subscriberKey);
    const pubKeys: string[] = this.getKeysArray(data.publisherKey);

    const { tableData, dataGrid, fullTableData } = this.state;
    if (!dataGrid) return;
    const newTableData = _.cloneDeep(tableData);
    const newFullTableData = _.cloneDeep(fullTableData);
    for (let h = 0; h < data.hiddenData.length; h += 1) {
      for (let i = 0; i < newTableData.length; i += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newTableData[i],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.hiddenData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newTableData[i][subKeys[keyInd]] ===
                data.hiddenData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newTableData[i].hidden = false;
        }
      }

      for (let j = 0; j < newFullTableData.length; j += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in newFullTableData[j],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.hiddenData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, keyInd) =>
              accuVal &&
              newFullTableData[j][subKeys[keyInd]] ===
                data.hiddenData[h][pubKeys[keyInd]],
            true
          )
        ) {
          newFullTableData[j].hidden = false;
        }
      }
    }
    this.fromGrid = true;
    this.setState({
      tableData: newTableData,
      fullTableData: newFullTableData,
    });
  };

  dataUnSelected = (data: {
    unSelectedData: any[];
    subscriberKey: string | string[];
    publisherKey: string | string[];
  }) => {
    const subKeys: string[] = this.getKeysArray(data.subscriberKey);
    const pubKeys: string[] = this.getKeysArray(data.publisherKey);
    const { selectedData } = this.state;
    const selectedRowsData: any[] = [...selectedData];
    for (let h = 0; h < data.unSelectedData.length; h += 1) {
      let isRemoved = false;
      for (let i = 0; !isRemoved && i < selectedRowsData.length; i += 1) {
        if (
          subKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in selectedRowsData[i],
            true
          ) &&
          pubKeys.reduce(
            (accuVal, currVal) => accuVal && currVal in data.unSelectedData[h],
            true
          ) &&
          subKeys.reduce(
            (accuVal, currentvalue, index) =>
              accuVal &&
              selectedRowsData[i][subKeys[index]] ===
                data.unSelectedData[h][pubKeys[index]],
            true
          )
        ) {
          selectedRowsData.splice(i, 1);
          isRemoved = true;
        }
      }
    }
    this.fromGrid = true;
    this.setState({ selectedData: [...selectedRowsData] });
  };

  getGridColumns(fields: any) {
    const gridColumns: any = [];
    fields.forEach((item: any, index: any) => {
      gridColumns.push(
        <Column
          autoExpandGroup={item.autoExpand}
          calculateFilterExpression={item.calculateFilterExpression}
          allowEditing={item.allowEditing}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}_${item.dataField}`}
          dataField={item.dataField}
          groupIndex={"groupIndex" in item ? item.groupIndex : null}
          caption={item.caption}
          dataType={item.dataType}
          minWidth={item.minWidth}
          width={item.width}
          visible={item.selected}
          fixed={item.fixed}
          headerCellRender={item.headerCellRender}
          // Changes made for EditableDataGrid
          setCellValue={
            item.isSetCellValueRequired === false
              ? undefined
              : (rowData: any, value: any) => {
                  rowData[item.dataField] = value;
                }
          }
          cellRender={
            item.cellRender !== undefined
              ? item.cellRender
              : (cell: any, e: any) => {
                  return this.renderCell(item, cell);
                }
          }
          groupCellRender={
            "groupCellRender" in item ? item.groupCellRender : null
          }
          lookup={item.lookup}
          editCellRender={item.editCellRender}
          editCellComponent={item.editCellComponent}
          allowFiltering={item.allowFiltering}
          allowSorting={item.allowSorting}
          calculateDisplayValue={item.calculateDisplayValue}
          sortOrder={item.sortOrder}
          calculateSortValue={item.calculateSortValue}
        >
          {item.fields !== undefined ? this.getGridColumns(item.fields) : ""}
          {this.isColumnRequired(item.isRequired)}
          {item.validationRules}
        </Column>
      );
    });
    return gridColumns;
  }

  getSummaryCaption = (data: any, item: any) => {
    if (item.dataType === undefined) {
      return item.customizeText;
    }
    const { getSummaryCaption } = this.props;
    const { tableData } = this.state;
    if (getSummaryCaption !== undefined) {
      getSummaryCaption(data, item, tableData);
    }
    return `${item.customizeText}${data.value}`;
  };

  calculateSelectedRow = (options: any) => {
    if (options.name === "SelectedRowsSummary") {
      if (options.summaryProcess === "start") {
        options.totalValue = 0;
      } else if (options.summaryProcess === "calculate") {
        if (options.component.isRowSelected(options.value.ID)) {
          options.totalValue += options.value.SaleAmount;
        }
      }
    }
  };

  renderCell = (item: any, e: any) => {
    if (item.viewMode === "tag") {
      let badgeClass = "";
      if (e.data[item.dataField] !== undefined) {
        badgeClass = `control-tower-tag ${e.data[item.dataField]
          .toString()
          .toLowerCase()}`;
      }
      return <span className={`${badgeClass}`}>{e.data[item.dataField]}</span>;
    }
    if (item.viewMode === "anchor") {
      return (
        <CustomizedButton
          variant="link"
          onClick={() => {
            item.onClick(e.key);
          }}
        >
          {e.text}
        </CustomizedButton>
      );
    }
    if (item.viewMode === "arrayLength") {
      if (
        e.key[item.dataField] !== undefined &&
        e.key[item.dataField].length > 0
      ) {
        return e.key[item.dataField].length;
      }
      return 0;
    }
    return e.text;
  };

  applyColumnChanges(fieldInfo: any) {
    const { fields } = this.props;
    for (let count = 0; count < fieldInfo.length; count++) {
      if (fieldInfo[count].selected !== undefined) {
        fields[count].selected = fieldInfo[count].selected;
      } // else { fields[count].selected = true; }
      if (fieldInfo[count].fields !== undefined) {
        for (
          let subcount = 0;
          subcount < fieldInfo[count].fields.length;
          subcount++
        ) {
          if (fieldInfo[count].fields[subcount].selected !== undefined) {
            fields[count].fields![subcount].selected =
              fieldInfo[count].fields[subcount].selected;
          }
        }
      }
    }

    const { tableData } = this.state;
    this.setState({
      tableData,
    });
  }

  render() {
    const {
      setRef,
      onInitNewRow,
      onEditingStart,
      selectionMode,
      keyExpr,
      moreOptions,
      showFilterRow,
      selectedRowKeys,
      allowRowReorder,
      showAdvancedFilters,
      enableColumnChooser,
      wordWrapEnabled,
      similarDeletionField,
      width,
      height,
      showGroupPanel,
      filterValue,
      totalRowsToDisplay,
      groupSummaryFields,
      customColumnChooser,
      showBorders,
      showColumnLines,
      showRowLines,
      showDynamicColumn,
      fields,
      scrollMode,
      allowUpdating,
      allowAdding,
      allowDeleting,
      showContextMenu,
      onRowRemoved,
      onRowInserted,
      onRowInserting,
      rowAlternationEnabled,
      editingMode,
      onEditorPreparing,
      editingComponent,
      onSaved,
      onRowUpdating,
      onRowUpdated,
      onToolbarPreparing,
      autoExpandAll,
      columnAutoWidth,
      columnFixing,
      columnHidingEnabled,
      key,
      newRowPosition,
    } = this.props;

    const { dataGrid } = this.state;
    let filterRow = <></>;
    let rowOptions = <></>;

    if (moreOptions !== undefined) {
      rowOptions = (
        <Column
          dataField="Options"
          caption="Actions"
          dataType="icon"
          width={100}
          cellRender={(e: any) => {
            const options = (
              <Popover id="popover-basic">
                <Popover.Content>
                  <ul className="p0 m0 popover-options-list">
                    {moreOptions.map((item: any) => (
                      <button
                        key={item.title}
                        className="d-block border-none background-color-light p0"
                        type="button"
                        onClick={() => {
                          if (item.clickAction) {
                            item.clickAction(e.key);
                          }
                        }}
                      >
                        <li>{item.title}</li>
                      </button>
                    ))}
                  </ul>
                </Popover.Content>
              </Popover>
            );
            return (
              <div className="cursor-pointer">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={options}
                  rootClose
                >
                  <FontAwesomeIcon
                    size="xs"
                    icon={faEllipsisH}
                    className="ml20 icon"
                  />
                </OverlayTrigger>
              </div>
            );
          }}
        />
      );
    }

    if (showFilterRow !== undefined) {
      if (showFilterRow) {
        filterRow = <FilterRow visible />;
      }
    }

    const selectedRowsKeyList = [];
    if (selectedRowKeys !== undefined && selectedRowKeys !== "") {
      selectedRowsKeyList.push(selectedRowKeys);
    }

    const { advancedFilterFlag, selectedData } = this.state;
    let { tableData } = this.state;
    const { advancedFilterInput, type } = this.props;
    if (
      advancedFilterInput.length > 0 &&
      this.advanceFilterupdatedInput === ""
    ) {
      tableData = this.getFilteredData(advancedFilterInput).FilteredData;
    }
    let selectedRowKeysProp = [];
    if (selectedRowKeys !== undefined && selectedRowKeys !== null) {
      selectedRowKeysProp = selectedRowKeys;
    } else {
      selectedRowKeysProp = selectedData;
    }

    return (
      <>
        {showAdvancedFilters === true ? (
          <AdvanceFilter
            advancedFilter={this.advancedFilter}
            onClearAdvancedFilter={this.onClearAdvancedFilter}
            advancedFilterInput={advancedFilterInput}
          />
        ) : (
          ""
        )}
        {showDynamicColumn ? (
          <CreateDynamicColumn getColumnInfo={this.getColumnInfo} />
        ) : (
          ""
        )}
        {customColumnChooser ? (
          <ColumnChooser
            applyColumnChanges={this.applyColumnChanges}
            fields={fields}
          />
        ) : (
          ""
        )}

        <DataGrid
          // eslint-disable-next-line no-return-assign
          key={key}
          ref={(ref) => {
            // eslint-disable-next-line react/destructuring-assignment
            if (ref && setRef) {
              setRef(ref!);
            }
            if (!this.state.dataGrid) {
              this.setState({ dataGrid: ref });
            }
          }}
          onEditingStart={(event: any) => {
            if (onEditingStart) onEditingStart(event);
          }}
          onEditorPreparing={(e: any) => {
            if (e.type === "selection" && e.parentType === "dataRow") {
              if (
                e.row.data !== undefined &&
                Object.keys(e.row.data).indexOf("id") >= 0
              ) {
                e.editorElement.id = `checkbox_${e.row.data.id.replaceAll(
                  "-",
                  "_"
                )}`;
              }
            }
            if (onEditorPreparing) {
              onEditorPreparing(e);
            }
          }}
          onContextMenuPreparing={(e: any) => {
            if (!showContextMenu) return;
            if (type && type === "non-reporting") return;
            if (e.row && e.row.rowType === "data") {
              e.items = [
                {
                  text: "Show",
                  visible: tableData.some((data: any) => data.hidden),
                  items: [
                    {
                      text: "Show Current Item",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          false,
                          false,
                          true
                        );
                      },
                    },
                    {
                      text: "Show Similar Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          true,
                          false,
                          false,
                          true
                        );
                      },
                      visible: !!similarDeletionField,
                    },
                    {
                      text: "Show Selected Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          true,
                          false,
                          true
                        );
                      },
                      visible:
                        dataGrid &&
                        dataGrid.instance.getSelectedRowsData().length > 0,
                    },
                    {
                      text: "Show Similar Selected Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          true,
                          true,
                          false,
                          true
                        );
                      },
                      visible:
                        !!similarDeletionField &&
                        dataGrid &&
                        dataGrid.instance.getSelectedRowsData().length > 0,
                    },
                    {
                      text: "Show All Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          false,
                          true,
                          true
                        );
                      },
                    },
                  ],
                },
                {
                  text: "Hide",
                  visible: !tableData.every((data: any) => data.hidden),
                  items: [
                    {
                      text: "Hide Current Item",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          false,
                          false,
                          false
                        );
                      },
                    },
                    {
                      text: "Hide Similar Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          true,
                          false,
                          false,
                          false
                        );
                      },
                      visible: !!similarDeletionField,
                    },
                    {
                      text: "Hide Selected Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          true,
                          false,
                          false
                        );
                      },
                      visible:
                        dataGrid &&
                        dataGrid.instance.getSelectedRowsData().length > 0,
                    },
                    {
                      text: "Hide Similar Selected Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          true,
                          true,
                          false,
                          false
                        );
                      },
                      visible:
                        !!similarDeletionField &&
                        dataGrid &&
                        dataGrid.instance.getSelectedRowsData().length > 0,
                    },
                    {
                      text: "Hide All Items",
                      onItemClick: () => {
                        this.onToggleShowItems(
                          e.row.data,
                          false,
                          false,
                          true,
                          false
                        );
                      },
                    },
                  ],
                },
              ];
            }
          }}
          columnChooser={{
            enabled: enableColumnChooser,
            mode: "select",
          }}
          wordWrapEnabled={wordWrapEnabled}
          allowColumnReordering
          allowColumnResizing
          onInitNewRow={(event: any) => {
            if (onInitNewRow) onInitNewRow(event);
          }}
          onRowInserting={(event: any) => {
            if (onRowInserting) onRowInserting(event);
          }}
          onRowRemoving={async (event: any) => {
            // Prevent immediate removal from UI
            event.cancel = true;

            // Show confirmation popup
            const confirmed = await ModalPopup.confirm({
              header: "Remove Confirmation",
              body: "Are you sure you want to remove this item? Do you want to proceed?",
            });

            if (confirmed) {
              try {
                if (this.props.onRowRemoved) {
                  this.props.onRowRemoved(event);

                  // Update the local state to remove the row after deletion
                  const updatedTableData = this.state.tableData.filter(
                    (item: any) => item.id !== event.data.id
                  );

                  // Update the state to re-render the table with the row removed
                  this.setState({ tableData: updatedTableData });
                } else {
                  // Handle the case when onRowRemoved is not passed
                  console.error(
                    "onRowRemoved function is not defined in props"
                  );
                }
              } catch (error) {
                console.error("Error:", error);
              }
            }
          }}
          onRowInserted={(event: any) => {
            if (onRowInserted) onRowInserted(event);
          }}
          onRowUpdated={(event: any) => {
            if (onRowUpdated) onRowUpdated(event);
          }}
          onRowUpdating={(event: any) => {
            if (onRowUpdating) onRowUpdating(event);
          }}
          onToolbarPreparing={(event: any) => {
            if (onToolbarPreparing) onToolbarPreparing(event);
          }}
          onSaved={(event: any) => {
            if (onSaved) onSaved(event);
          }}
          columnResizingMode="widget"
          dataSource={tableData}
          keyExpr={keyExpr}
          showColumnLines={showColumnLines}
          showRowLines={showRowLines}
          width={width}
          columnAutoWidth={columnAutoWidth}
          columnHidingEnabled={columnHidingEnabled}
          height={height}
          filterValue={filterValue}
          showBorders={showBorders}
          rowAlternationEnabled={rowAlternationEnabled}
          selection={{ mode: selectionMode }}
          onRowPrepared={(e: any) => {
            if (e.rowType === "data" && e.data.hidden) {
              e.rowElement.classList.add("row-disable");
            }
          }}
          onSelectionChanged={this.onSelectionChanged}
          onContentReady={this.onContentReady}
          defaultSelectedRowKeys={selectedRowKeys}
          selectedRowKeys={selectedRowKeysProp}
          loadPanel={{
            enabled: true,
            shading: true,
            showPane: false,
            indicatorSrc: spinner,
            shadingColor: "#f6f7ff",
            text: "Loading...",
          }}
        >
          <Paging defaultPageSize={totalRowsToDisplay} />
          <Scrolling mode={scrollMode} />
          <GroupPanel visible={showGroupPanel} />
          <Grouping autoExpandAll={autoExpandAll} />
          <Sorting mode="multiple" />
          <ColumnFixing enabled={columnFixing} />

          {editingComponent ||
            ((allowAdding || allowUpdating || allowDeleting) && (
              <Editing
                useIcons
                confirmDelete={false}
                allowUpdating={allowUpdating}
                allowAdding={allowAdding}
                allowDeleting={true}
                mode={editingMode}
                newRowPosition={newRowPosition}
              />
            ))}
          {allowRowReorder && (
            <RowDragging allowReordering onReorder={this.onReorder} />
          )}
          {filterRow}
          {fields.map((item: any, index: number) => {
            if ("colorField" in item) {
              return (
                <Column
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}_${item.dataField}{'colorField'}`}
                  caption={item.colorFieldCaption ? item.colorFieldCaption : ""}
                  width={60}
                  minWidth={60}
                  visible={item.visible}
                  cellRender={
                    item.cellRender
                      ? item.cellRender
                      : (e) => {
                          if (e.data) {
                            return (
                              <div
                                className="w20 h20 mr5 mt0 ml0 mb0 "
                                style={{
                                  backgroundColor: this.getColor(
                                    e.data[item.dataField]
                                  ),
                                }}
                              />
                            );
                          }
                          return null;
                        }
                  }
                />
              );
            }
          })}
          {this.getGridColumns(fields)}
          {/* <Summary calculateCustomSummary={this.calculateSelectedRow}> */}
          {/*  {groupSummaryFields.map((item:any) => ( */}
          {/*    item.customizeText === undefined */}
          {/*      ? ( */}
          {/*        <TotalItem */}
          {/*          column={item.dataType} */}
          {/*          summaryType={item.summaryType} */}
          {/*          alignment={item.alignment} */}
          {/*          showInColumn={item.showInColumn} */}
          {/*          name={item.name} */}
          {/*        /> */}
          {/*      ) */}
          {/*      : ( */}
          {/*        <TotalItem */}
          {/*          column={item.dataType} */}
          {/*          alignment={item.alignment} */}
          {/*          summaryType={item.summaryType} */}
          {/*          showInColumn={item.showInColumn} */}
          {/*          customizeText={(data:any) => this.getSummaryCaption(data, item)} */}
          {/*        /> */}
          {/*      ) */}
          {/*  ))} */}
          {/* </Summary> */}
          <Summary>
            {groupSummaryFields.map((groupSummaryField: any) => (
              <GroupItem
                column={groupSummaryField.column}
                summaryType={groupSummaryField.summaryType}
                displayFormat={groupSummaryField.displayFormat}
                showInGroupFooter={groupSummaryField.showInGroupFooter}
              />
            ))}
          </Summary>
        </DataGrid>
      </>
    );
  }

  private getColor = (xVal: string) => {
    let hash = 0;
    for (let i = 0; i < xVal.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hash = xVal.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[hash % colors.length];
  };

  // Sets the RequiredRule component in the coloumn
  private isColumnRequired = (condition: boolean) => {
    if (condition) {
      return <RequiredRule />;
    }
    return null;
  };
}

export default CustomizedDataGrid;
