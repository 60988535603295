import React from 'react';
import TagBox from 'devextreme-react/tag-box';
import getDropDownOptions from 'components/getDropDownOptions';

export default class BinPlusTypeTagBox extends React.Component<any, any> {
  onValueChanged = (e: any) => {
    const { data } = this.props;
    data.setValue(e.value);
  };

  onSelectionChanged = () => {
    const { data } = this.props;
    data.component.updateDimensions();
  };

  render() {
    const { data } = this.props;
    let dataSource = null;
    if (data.data.binPlusGroupType) {
      dataSource = data.column.lookup.dataSource.filter((binPlusType: any) => {
        return binPlusType.binPlusGroupTypeName === data.data.binPlusGroupType;
      });
    }
    return (
      <TagBox
        dataSource={dataSource}
        defaultValue={data.value}
        valueExpr="name"
        displayExpr="name"
        showSelectionControls
        maxDisplayedTags={3}
        showMultiTagOnly={false}
        applyValueMode="instantly"
        searchEnabled
        onValueChanged={this.onValueChanged}
        onSelectionChanged={this.onSelectionChanged}
        dropDownOptions={getDropDownOptions('cell', data)}
      />
    );
  }
}
