import React from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { faPlus, faMinus, faCheck, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../wrapped-component/hint-controls/Button';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import Label from '../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import Checkbox from '../../../wrapped-component/hint-controls/Checkbox';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { addErrorClasses } from '../../../template-component/form-error-label/ErrorLabel';
import { DefaultPolicyStepSettings, ILoaderCopyPolicyStepSettings, IPolicyStep, } from '../../../../interfaces';

interface ILoaderPolicyCopyStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  stepSettings?: any;
  updatedCreated: any;
  policySteps: IPolicyStep[];
}

class LoaderCopyPolicyStepSettings extends React.Component<ILoaderPolicyCopyStepSettingsProps, any> {
  private policyStepSettingsReference: any;

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  constructor(props: ILoaderPolicyCopyStepSettingsProps) {
    super(props);
    const { stepSettings } = this.props;

    let loaderPolicyStepSettings: ILoaderCopyPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.Copy));
    if (stepSettings) {
      loaderPolicyStepSettings = stepSettings;
    }
    this.state = {
      loaderPolicyStepSettings,
    };
    this.policyStepSettingsReference = React.createRef();
    this.renderPrePostConditions = this.renderPrePostConditions.bind(this);
  }

  conditionUpdateHandler = (value: string, flag: string, index: any, key: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions[index][key] = value;
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions[index][key] = value;
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  deleteCondition = (index: any, flag: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions.splice(index, 1);
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions.splice(index, 1);
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  renderPrePostConditions = (conditions: any, flag: any) => {
    return conditions?.map((condition: any, index: any) => (
      <Label
        labelTitle={`${index + 1}.`}
        labelPosition="left-middle"
        labelSize="10"
        fieldSize="90"
        errorSize="0"
        childrenItemsInline
        className={`d-flex ${index > 0 ? 'border-top pt10' : ''}`}
      >
        <div className="w-100 flex-100 d-flex align-items-center">
          <div className="w-100 flex-80 flex-column">
            <div
              className="mr10 custom-form-inline label-left"
            >
              <span className="label mr10 w30">If</span>
              <Textbox
                containerClassName="w-80"
                defaultValue={condition.condition}
                value={condition.condition}
                className="w-100"
                autoComplete="off"
                name="if"
                placeholder="Condition"
                onBlur={(event:any) => {
                  this.conditionUpdateHandler(event.target.value, flag, index, 'condition');
                }}
              />
            </div>

            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Then</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.thenClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'thenClause');
                }}
              />
            </div>
            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Else</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.elseClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'elseClause');
                }}
              />
            </div>
          </div>

          <div className="flex-20 mt10">
            <Button
              variant="outline-primary"
              className="w40"
              onClick={() => this.deleteCondition(index, flag)}

            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
          </div>
        </div>
      </Label>
    ));
  };

  render() {
    const {
      loaderPolicyStepSettings,
    } = this.state;
    const {
      updatedCreated,
      updatePolicyStepSettings,
      policySteps,
    } = this.props;
    const formValidationSchema = Yup.object({
      stepOwner: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
      additionalEmail: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
    });
    return (
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          stepOwner: '',
          additionalEmail: '',
          loaderPolicyStepSettings,
          updatedCreated,
        }}
        validationSchema={formValidationSchema}
        onSubmit={() => undefined}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          touched,
        }) => (
          <form
            onChange={handleChange}
            onSubmit={handleSubmit}
            ref={this.policyStepSettingsReference}
            className="custom-form h-100"
          >
            <div className="step-settings-block indent p20">
              <Container fluid className="h-100 overflow-visible">
                <Row>
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Source</Heading>
                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Location Type</span>
                      <div className="d-flex flex-70">
                        <div className="flex-90 mr5">
                          <CustomizedDropdown
                            full
                            variant="clear"
                            selectedValue={loaderPolicyStepSettings.sourceLocation.type}
                            list={[['S3', 'Cloud Storage'], ['ftp', 'ftp'], ['Local', 'Local'], ['PolicyStep', 'Policy Step Output']]}
                            onChange={(value) => {
                              loaderPolicyStepSettings.sourceLocation.type = value;
                              this.setState({ loaderPolicyStepSettings });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }}
                          />
                        </div>
                        <Button
                          variant="outline-warning"
                          className="w40"
                          title="Check if location is accessible"
                        >
                          <FontAwesomeIcon className="mt-10" size="lg" icon={faCheck} />
                        </Button>
                      </div>
                    </div>
                    {loaderPolicyStepSettings.sourceLocation.type === 'S3' && (
                      <div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Source Container</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="yw-test-files"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.container}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.container = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Directory Path</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="folder/Subfolder/"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.folderPath}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(event:any) => {
                                loaderPolicyStepSettings.sourceLocation.folderPath = event.target.value;
                                this.setState(loaderPolicyStepSettings);
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {loaderPolicyStepSettings.sourceLocation.type === 'ftp' && (
                      <div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Address</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="ftp://yieldwerx.com"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.ftpAddress}
                              value={loaderPolicyStepSettings.sourceLocation.ftpAddress}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.ftpAddress = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Username</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="yw-user"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.ftpUsername}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.ftpUsername = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Password</span>
                          <div className="flex-70">
                            <Textbox
                              type="password"
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="yw-password"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.ftpPassword}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.ftpPassword = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Folder to transfer files from</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="/files"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.folderPath}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.folderPath = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {loaderPolicyStepSettings.sourceLocation.type === 'Local' && (
                      <div className="custom-form-inline label-left">
                        <span className="label flex-30">Directory Path</span>
                        <div className="flex-70 d-flex">
                          <div className="flex-90 mr10">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="/files"
                              defaultValue={loaderPolicyStepSettings.sourceLocation.folderPath}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.sourceLocation.folderPath = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                          <Button
                            type="submit"
                            variant="primary"
                            className="height-100percent"
                            size="lg"
                          >
                            Browse
                          </Button>
                        </div>
                      </div>
                    )}
                    {loaderPolicyStepSettings.sourceLocation.type === 'PolicyStep' && (
                      <div className="custom-form-inline label-left">
                        <span className="label flex-30">Source</span>
                        <div className="flex-70">
                          <CustomizedDropdown
                            full
                            variant="clear"
                            list={[['Default', 'Previous Step Output'], ...policySteps.map((x: any) => [x.OutputReference, x.OutputReference])]}
                            selectedValue={loaderPolicyStepSettings.sourceLocation.policyStep}
                            onChange={(value) => {
                              loaderPolicyStepSettings.sourceLocation.policyStep = value;
                              this.setState({ loaderPolicyStepSettings });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <Checkbox>
                      Delete from Source Location
                    </Checkbox>
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>Target</Heading>
                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Location Type</span>
                      <div className="flex-70 d-flex">
                        <div className="flex-90 mr5">
                          <CustomizedDropdown
                            full
                            variant="clear"
                            selectedValue={loaderPolicyStepSettings.targetLocation.type}
                            list={[['S3', 'Cloud Storage'], ['ftp', 'ftp'], ['Local', 'Local']]}
                            onChange={(value) => {
                              loaderPolicyStepSettings.targetLocation.type = value;
                              this.setState({ loaderPolicyStepSettings });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }}
                          />
                        </div>
                        <Button
                          variant="outline-warning"
                          className="w40"
                          title="Check if location is accessible"
                        >
                          <FontAwesomeIcon className="mt-10" size="lg" icon={faCheck} />
                        </Button>
                      </div>
                    </div>
                    {loaderPolicyStepSettings.targetLocation.type === 'S3' && (
                      <div className="custom-form-inline label-left">
                        <span className="label flex-30">Target Container</span>
                        <div className="flex-70">
                          <Textbox
                            autoComplete="off"
                            name="additionalEmail"
                            placeholder="yw-test-files"
                            defaultValue={loaderPolicyStepSettings.targetLocation.container}
                            className={`${addErrorClasses(
                              errors.additionalEmail,
                              touched.additionalEmail,
                            )}`}
                            onBlur={(value: any) => {
                              loaderPolicyStepSettings.targetLocation.container = value.target.value;
                              this.setState({ loaderPolicyStepSettings });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {loaderPolicyStepSettings.targetLocation.type === 'ftp' && (
                      <div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Address</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="ftp://yieldwerx.com"
                              defaultValue={loaderPolicyStepSettings.targetLocation.ftpAddress}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.targetLocation.ftpAddress = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Username</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="yw-user"
                              defaultValue={loaderPolicyStepSettings.targetLocation.ftpUsername}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.targetLocation.ftpUsername = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Ftp Password</span>
                          <div className="flex-70">
                            <Textbox
                              type="password"
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="yw-password"
                              defaultValue={loaderPolicyStepSettings.targetLocation.ftpPassword}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.targetLocation.ftpPassword = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Folder to transfer files from</span>
                          <div className="flex-70">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="/files"
                              defaultValue={loaderPolicyStepSettings.targetLocation.folderPath}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.targetLocation.folderPath = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {loaderPolicyStepSettings.targetLocation.type === 'Local' && (
                      <div className="custom-form-inline label-left">
                        <span className="label flex-30">Directory Path</span>
                        <div className="flex-70 d-flex">
                          <div className="flex-90 mr10">
                            <Textbox
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="/files"
                              defaultValue={loaderPolicyStepSettings.targetLocation.folderPath}
                              className={`${addErrorClasses(
                                errors.additionalEmail,
                                touched.additionalEmail,
                              )}`}
                              onBlur={(value: any) => {
                                loaderPolicyStepSettings.targetLocation.folderPath = value.target.value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                          <Button
                            type="submit"
                            variant="primary"
                            className="height-100percent"
                            size="lg"
                          >
                            Browse
                          </Button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Owner</Heading>
                    <Label
                      labelTitle="Step Owner"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.stepOwner}
                      isFieldTouched={touched.stepOwner}
                    >
                      <Textbox
                        autoComplete="off"
                        name="stepOwner"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.stepOwner,
                          touched.stepOwner,
                        )}`}
                      />
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>Notifications</Heading>
                    <Label
                      labelTitle="Additional Emails"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.additionalEmail}
                      isFieldTouched={touched.additionalEmail}
                    >
                      <Textbox
                        autoComplete="off"
                        name="additionalEmail"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.additionalEmail,
                          touched.additionalEmail,
                        )}`}
                      />
                    </Label>
                    <Checkbox>
                      Notify Owners
                    </Checkbox>
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right">
                    <div className="custom-form-inline label-left">
                      <div className="flex-20 mt20"><Heading size={6}><Heading size={6}>Pre Conditions</Heading></Heading></div>
                      <div className="flex-80 mt10 ">
                        <Button
                          variant="outline-primary"
                          className="w40"
                          onClick={
                            () => {
                              loaderPolicyStepSettings.preConditions.push({
                                condition: '', thenClause: '', elseClause: '',
                              });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }
                          }
                        >
                          <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                        </Button>
                      </div>
                    </div>
                    {this.renderPrePostConditions(loaderPolicyStepSettings.preConditions, 'PRE')}
                  </Col>
                  <Col lg={6}>
                    <div className="custom-form-inline label-left">
                      <div className="flex-20 mt20"><Heading size={6}><Heading size={6}>Post Conditions</Heading></Heading></div>
                      <div className="flex-80 mt10 ">
                        <Button
                          variant="outline-primary"
                          className="w40"
                          onClick={
                            () => {
                              loaderPolicyStepSettings.postConditions.push({
                                condition: '', thenClause: '', elseClause: '',
                              });
                              updatePolicyStepSettings(loaderPolicyStepSettings);
                            }
                          }
                        >
                          <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                        </Button>
                      </div>
                    </div>
                    {this.renderPrePostConditions(loaderPolicyStepSettings.postConditions, 'POST')}
                  </Col>
                </Row>
                {updatedCreated}
              </Container>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default LoaderCopyPolicyStepSettings;
