/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable no-return-await */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DataGrid } from 'devextreme-react';
import { Promise } from 'bluebird';
import {
  Button, OverlayTrigger, Container, Row, Col, Tooltip, Modal, Spinner,
} from 'react-bootstrap';
import { Column, } from 'devextreme-react/data-grid';
import React from 'react';
import {
  IExpressionsList,
  IHint,
  TTypeAheadValuesType,
  IGroupingSortingList,
  IGenericDetailedReportRequestObject,
  EntityType,
  ISelectionCriteriaReturnValue,
  ISelectionsObject,
  GroupingSortingDTO,
  IExportDataOptionsDto,
  IExportDataInitialOptions,
} from 'interfaces';
import { httpService } from 'services/http.service';

import ColumnChooser from 'components/reports/raw-grid/column-chooser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch, faTimes
} from '@fortawesome/free-solid-svg-icons';
import Hint from 'components/wrapped-component/hint-controls/Hint/Hint';
import _ from 'lodash';
import toast from 'CustomToast';
import AdvancedFilterPopover from 'components/utility-component/advanced-filter-popover/AdvancedFilterPopover';
import GeneralUtils, { ReportTypeConstants as ReportType } from 'GeneralUtils';
import { InteractionMode } from 'views/individual-reports/customized-report/CustomizedReportGraph';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import CodeEditor from '../../../utility-component/code-editor/CodeEditor';
import spinner from '../../../../assets/icons/spinner.gif';
import { httpRawData } from '../../../../services/http.raw-data';
import AppConstants from '../../../../constants';

import PubSubBinder, { ActionType, InteractionEventType } from '../../../../views/individual-reports/PublishSubscribeBinder';
import {
  AggregateFunctionNames, IError, InteractionInputDTO, IViewMode,
} from '../../../../views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';
import InteractionIndicators from '../../../../views/individual-reports/customized-report/customized-report-utils/InteractionIndicators';
import DataExportModal from './DataExportModal';

export type GRID_RENDERING_OPTIONS =
  'SHOW_NAME_COLUMNS_BY_DEFAULT'
  | 'SHOW_GROUPING_COLUMNS'
  | 'SHOW_SORTING_COLUMNS'
  | 'SHOW_BIN_CALCULATED_COLUMNS'
  | 'SHOW_PARAMETRIC_CALCULATED_COLUMNS'
  | 'SHOW_STATISTICS_COLUMNS'
  | 'SHOW_ALL_STATS'
  | 'SHOW_PASSING_STATS_ONLY'
  | 'SHOW_FAILING_STATS_ONLY'
  | 'APPLY_LIMIT_LINES'
  | 'INDIVIDUAL_PARAMETER_REPORT'
  | 'SHOW_FILTERED_STATS_COLUMNS';
interface ICaptionColor {
  value: string;
  color: string;
  alias: string;
  columnName: string;
  type: string;
  isPrimaryColumn: boolean;
}

interface IColumnStructure {
  caption: ICaptionColor[];
  dataField: string;
  columnName: string;
  alias: string;
  type: string;
  columnCategoryEnum: string;
  isPrimaryColumn: boolean;
  fields: IColumnStructure[];
  selected: boolean;
  visibleByDefault: boolean;
  allowEditing?: boolean;
  isEdited?: boolean;
  entityType: string;
  tableName: string;
  visibleCaption: string;
  sequence?: number;
}

interface IGridHeaderLegend {
  gridColumnStructureDTO: IColumnStructure[];
  legend: ICaptionColor[];
}

interface ICustomizedRawDataGridProps {
  viewAs: string;
  powerviewSessionId: string;
  gridRenderingOptions: GRID_RENDERING_OPTIONS[];
  id: string;
  reportSessionId: string;
  setRawDataGridInstanceHandler: (rawDataGrid: any, keyIndex: string) => void;
  hideReportRow?: (reportType: string) => void | undefined;
  reportType?: string;
  className?: string | undefined;
  testParameterConditions?: { [key: string]: any };
  groupingSortingListStore: IGroupingSortingList;
  defaultGrouping?: GroupingSortingDTO;
  selectionStore: ISelectionCriteriaReturnValue;
  parseFilter: any;
  aggredateFunction?: any;
  title: string;
  config: { [key: string]: any };
  reportPublisherId?: string;
  limitSettingsObj?: any;
  testParameterIndex?: number[],
  testParameterIndexForWhichLimitsApplied?: number,
  filterData: boolean,
  productType?: string | undefined,
  selectedBinPlusDefId?: string,
  isSoftBin?: boolean,
  setErrorsData?: (errors: IError[]) => void | undefined;
  setNewLimitSettings?: (updatedLimitSettingsObj: any) => void | undefined;
  height?: string,
  invokeDataExportOperation?: (options: IExportDataOptionsDto) => void;
}

interface ICustomizedRawDataGridState {
  gridHeaderLegend: IGridHeaderLegend;
  columnsLoaded: boolean;
  advancedFilter: string;
  gridType: string;
  columns: JSX.Element[];
  interactionIndicator: {
    selection: boolean;
    hiding: boolean;
    highlightedCount: number;
    hiddenCount: number;
  };
  toggleShowConfirmClearInteractions: boolean;
  updatedStats: any[];
  totalItemCount: number;
  isDisableExportButton: boolean;
  isDataExporting: boolean;
}

class CustomizedRawDataGrid extends React.Component<ICustomizedRawDataGridProps, ICustomizedRawDataGridState> {
  private publisherId = '';

  private dataGridRef = React.createRef<DataGrid>();

  private dataGridParams: any = {};

  private actualDataGridProps: any;

  private pubSubBinder = PubSubBinder();

  private retainPriorSort = false;

  private priorSort = '';

  private allRowsSelected = false;

  private gridType = {
    UNPIVOTED: 'UNPIVOTED',
    PIVOTED_SPLIT: 'PIVOTED_SPLIT',
    PIVOTED_MERGE: 'PIVOTED_MERGE',
  };

  private selectedRowKeys: string[] = [];

  private selectedRowsData: any[] = [];

  private currentInteractionEvent: InteractionEventType = 'CLEAR_INTERACTIONS';

  private advancedValue = '';

  private ColumnMetaHeaderType = {
    DEFAULT: 'DEFAULT',
    UDA: 'UDA',
    TEST_CONDITION: 'TEST_CONDITION',
    TEST_PARAMETER_VALUE: 'TEST_PARAMETER_VALUE',
  };

  private advancedFilterInstructions = <AdvancedFilterPopover />;

  constructor(props: ICustomizedRawDataGridProps) {
    super(props);
    this.state = {
      gridHeaderLegend: {
        gridColumnStructureDTO: [],
        legend: [],
      },
      columnsLoaded: false,
      columns: [],
      advancedFilter: '',
      gridType: this.gridType.UNPIVOTED,
      interactionIndicator: {
        selection: false,
        hiding: false,
        highlightedCount: 0,
        hiddenCount: 0,
      },
      toggleShowConfirmClearInteractions: false,
      updatedStats: [],
      totalItemCount: 0,
      isDisableExportButton: GeneralUtils.Constants.IS_DATA_EXPORTING,
      isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING,
    };
    this.allRowsSelected = false;
  }

  componentDidMount() {
    this.allRowsSelected = false;
    this.publisherId = this.pubSubBinder.RegisterActor(ReportType.RAW_DATA_GRID, this.rawDataGridSubscriptionListener);
    this.actualDataGridProps = this.getActualDataGridProps();
    if (GeneralUtils.Constants.IS_DATA_EXPORTING === true){
      this.setState({ isDisableExportButton: GeneralUtils.Constants.IS_DATA_EXPORTING, isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING });
    }
    this.getRawDataGrid();
  }

  componentDidUpdate(prevProps: ICustomizedRawDataGridProps, prevState: ICustomizedRawDataGridState) {
    const { updatedStats } = this.state;
    const { limitSettingsObj, filterData, isSoftBin } = this.props;
    if (JSON.stringify(prevProps.limitSettingsObj) !== JSON.stringify(limitSettingsObj)
    || (prevProps.filterData !== filterData) || (prevProps.isSoftBin !== isSoftBin) || prevState.updatedStats !== updatedStats) {
      this.actualDataGridProps = this.getActualDataGridProps();
      this.getRawDataGrid();
    }
  }

  componentWillUnmount() {
    this.pubSubBinder.DisposeActor(this.publisherId);
  }

  toggleConfirmClearInteractions = (flag:boolean) => {
    this.setState({ toggleShowConfirmClearInteractions: flag });
  };

  compareFields = (sourceColumns: IColumnStructure[], userPreferenceColumns: IColumnStructure[]) => {
    const sourceKeys = Object.keys(sourceColumns).sort();
    const userPreferenceColumnKeys = Object.keys(userPreferenceColumns).sort();

    // Compare both have same key (properties)
    return userPreferenceColumns.length !== 0 && JSON.stringify(sourceKeys) === JSON.stringify(userPreferenceColumnKeys);
  };

  extractTestParameterFromSCWData = () => {
    const { selectionStore } = this.props;
    let testParameterIds: string[] = [];
    const isTestParam = selectionStore.selections.filter((x) => x.entityType === 'Testparameter');
    if (isTestParam.length > 0) {
      testParameterIds = isTestParam[0].values.map((v: ISelectionsObject) => (v.id));
    }
    return testParameterIds;
  };

  getRawDataGrid = (getThroughAPICall = true) => {
    const {
      gridType, updatedStats, gridHeaderLegend
    } = this.state;
    const {
      gridRenderingOptions, groupingSortingListStore, selectionStore, reportSessionId, limitSettingsObj, productType, reportType, defaultGrouping, isSoftBin,
    } = this.props;
    const rawDataGridHeaderInputDTO = {
      productType,
      reportType,
      reportSessionId,
      gridTypeEnum: gridType,
      testParameterIds: this.extractTestParameterFromSCWData(),
      gridRenderingOptions,
      grouping: groupingSortingListStore.grouping,
      defaultGrouping,
      sorting: groupingSortingListStore.sorting,
      selections: selectionStore.selections.map((s) => ({
        entityType: s.controlType,
        values: s.values.map((v) => (v.id)),
      })),
      limitSettingsObj,
      updatedStats,
      isSoftBin,
    };

    Promise.allSettled(
      [
        httpRawData.getRawDataGridHeaders(rawDataGridHeaderInputDTO),
      ],
    ).then((values: any) => {
      let headers: any = gridHeaderLegend;
      if (getThroughAPICall === true) {
        headers = values[0].value();
        const userPreferencedColumns = JSON.parse(localStorage.getItem('preferedColumns') ?? '[]');
        if (userPreferencedColumns.length > 0 && this.compareFields(headers.gridColumnStructureDTO, userPreferencedColumns)) {
          headers.gridColumnStructureDTO = userPreferencedColumns;
        }
      }

      Promise.resolve(this.plotColumns(headers.gridColumnStructureDTO)).then((columns) => {
        this.setState({
          gridHeaderLegend: headers,
          columnsLoaded: true,
          advancedFilter: '',
          columns,
        });
      });
    });
  };

  applyColumnChanges = (fieldInfo: any) => {
    const { gridHeaderLegend } = this.state;
    const { viewAs } = this.props;
    const newGridHeaderLegend: IGridHeaderLegend = _.cloneDeep(gridHeaderLegend);
    if (fieldInfo.length > 0) {
      newGridHeaderLegend.gridColumnStructureDTO = fieldInfo;
    }
    this.setState({ gridHeaderLegend: newGridHeaderLegend }, () => {
      this.getRawDataGrid(false);
      this.forceUpdate();
    });
  };

  checkSubcolumnsSelection = (columnsList: IColumnStructure[]) => {
    let selected = false;
    columnsList.forEach((column: any) => {
      if (column.selected === true) {
        selected = true;
      }
    });
    return selected;
  };

  plotColumns = async (columnsList: IColumnStructure[]) => {
    return Promise.map(columnsList, async (item: IColumnStructure) => {
      const visibility = this.checkSubcolumnsSelection(item.fields);
      const keys = [
        'Test Parameter Number',
        'Test Parameter Name',
        'Test Parameter Unit',
        'Low Ctrl Limit',
        'High Ctrl Limit',
        'Low Spec Limit',
        'High Spec Limit',
      ];
      return (
        <Column
          key={`raw-data-grid-column-${item.caption.map((captionItem) => (captionItem?.value)).join('-')}-${item.dataField}`}
          width="150"
          visible={visibility}
          caption={item.visibleCaption}
          dataField={item.fields.length === 0 ? item.dataField : undefined}
        >
          {
            item.fields.length > 0 && this.plotSubColumns(item.caption[0].value, item.fields)
          }
        </Column>
      );
    }, { concurrency: columnsList.length });
  };

  plotSubColumns = (detailCaption: any, columnsList: IColumnStructure[]) => {
    return columnsList.map((column, index) => {
      const highlightTestConditionColumn = column.columnCategoryEnum === 'TEST_CONDITION'
        || column.columnCategoryEnum === 'TEST_CONDITION_TABLE'
        || column.columnCategoryEnum === 'TEST_CONDITION_COLUMN'
        || column.columnCategoryEnum === 'TEST_PARAMETER_VALUE';
      const keys = [
        'Test Parameter Number',
        'Test Parameter Name',
        'Test Parameter Unit',
        'Low Ctrl Limit',
        'High Ctrl Limit',
        'Low Spec Limit',
        'High Spec Limit',
      ];

      let visibility = this.getDefaultColumnVisibility(column);
      visibility = column.selected === undefined ? visibility : column.selected;
      return (
        <Column
          key={`raw-data-grid-column-${column.caption.map((captionItem, i) => (captionItem?.value ?? i)).join('-')}-${column.dataField ?? index}-${column.visibleCaption}`}
          width="150"
          visible={visibility}
          visibleIndex={column.sequence}
          caption={column.visibleCaption}
          dataField={column.fields.length === 0 ? column.dataField : undefined}
          allowEditing={column.allowEditing !== null ? column.allowEditing : false}
          allowSorting={!highlightTestConditionColumn}
          cssClass={highlightTestConditionColumn ? 'test-parameter-condition-header-background' : ''}
          cellRender={(e:any) => {
            if (column.allowEditing && column.isEdited) {
              return <div className="center-align background-color-light-green-important"><pre className="default-font-family default-font-size mb0">{e.value}</pre></div>;
            }
            return <div className="center-align"><pre className="default-font-family default-font-size mb0 center-align">{e.value}</pre></div>;
          }}
        />
      );
    });
  };

  private getDefaultColumnVisibility = (column: IColumnStructure) => {
    const { viewAs } = this.props;

    if (viewAs === 'DATA_TAB') {
      return column.visibleByDefault;
    }
    return column.isPrimaryColumn && (column.selected === undefined ? true : column.selected);
  };

  private customDataSource = () => {
    const {
      testParameterConditions,
      reportSessionId,
      gridRenderingOptions,
      groupingSortingListStore,
      viewAs,
      limitSettingsObj,
      testParameterIndex,
      testParameterIndexForWhichLimitsApplied,
      productType,
      selectedBinPlusDefId,
      reportType,
      defaultGrouping,
      setErrorsData,
      setNewLimitSettings,
      isSoftBin,
    } = this.props;
    const { advancedFilter, gridType, updatedStats } = this.state;

    return createStore({
      key: 'die_id',
      loadUrl: `${AppConstants.baseURL}/api/rawdata/${viewAs === 'DATA_TAB' ? 'get-data-or-stats-tab' : 'get-raw-data-grid-data'}/`,
      loadMethod: 'POST',
      loadParams: {
        productType,
        reportType,
        gridRenderingOptions,
        advancedFilter,
        testParameterIds: this.extractTestParameterFromSCWData(),
        gridTypeEnum: gridType,
        testParameterConditions,
        reportSessionId,
        grouping: groupingSortingListStore.grouping,
        sorting: groupingSortingListStore.sorting,
        defaultGrouping,
        limitSettingsObj,
        testParameterIndex,
        testParameterIndexForWhichLimitsApplied,
        updatedStats,
        selectedBinPlusDefId,
        isSoftBin,
      },
      errorHandler: async (error: any) => {
        let message = '';
        if (error.message === 'ERR_CONTEXT_EXPIRED') {
          GeneralUtils.clearGlobalVars();
          message = 'The context for your data selection criteria has expired, please select data again.';
          const resolvePopover = await ModalPopup.confirm({
            header: 'Alert',
            body: message,
            showYesButton: false,
            noButtonText: 'Ok',
          });
          if (!resolvePopover) {
            // eslint-disable-next-line no-alert
            window.location.reload();
          }
        }
      },
      onBeforeSend: (method: any, ajaxOptions: any) => {
        const token = GeneralUtils.getCookieValue('AUTH_TOKEN');
        const database = GeneralUtils.getCookieValue('DATABASE');
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          SelectedDatabase: database,
        };
        ajaxOptions.xhrFields = {
          withCredentials: true,
        };

        if (ajaxOptions.data.sort){
          this.priorSort = ajaxOptions.data.sort;
        }
        if (this.retainPriorSort && this.priorSort !== '' && !ajaxOptions.data.sort){
          ajaxOptions.data.sort = this.priorSort;
          this.priorSort = '';
          this.retainPriorSort = false;
        }

        ajaxOptions.data.normalFilter = this.onFilterExpressionChanged(ajaxOptions.data.filter);
        ajaxOptions.data.filter = JSON.stringify(this.parseFilter(ajaxOptions.data.filter));
        const data = JSON.parse(JSON.stringify(ajaxOptions.data));
        this.dataGridParams = ajaxOptions.data;
        ajaxOptions.data = {
          param: JSON.stringify(data),
        };
      },
      onLoaded: (response: any) => {
        const { viewAs } = this.props;
        this.selectedRowKeys = [];
        const errors: IError[] = response.errorMessages;
        if (response.data.length > 0) {
          response.data.forEach((row: any) => {
            if (row?.is_selected || this.allRowsSelected) {
              this.selectedRowKeys.push(row.die_id);
            }
          });
        }

        if (viewAs === 'DATA_TAB' && setErrorsData) {
          setErrorsData(errors);
        }
        if (response.updatedLimitSettings != null && setNewLimitSettings) {
          setNewLimitSettings(response.updatedLimitSettings);
        }
        if (viewAs === ReportType.RAW_DATA_GRID){
          if (response.highlightedCount > 0){
            this.currentInteractionEvent = 'SELECTION';
          } else if (response.hiddenCount > 0){
            this.currentInteractionEvent = 'HIDE';
          }
          this.updateInteractionIndicatorAndRowCount(response.highlightedCount, response.hiddenCount, response.totalCount);
        }
      },
    });
  };

  exportRawDataGridData = (options: IExportDataInitialOptions) => {
    const { gridHeaderLegend } = this.state;
    const { invokeDataExportOperation, height } = this.props;
    const visibleColumns: string[] = [];
    const visibleColumnCaptions: string[] = [];
    gridHeaderLegend.gridColumnStructureDTO.forEach((mainColumn: IColumnStructure) => {
      visibleColumns.push(...mainColumn.fields.filter((x: any) => (options.columnsToExport === 'VISIBLE' ? x.selected : x)).map((x: any) => x.dataField));
      visibleColumnCaptions.push(...mainColumn.fields.filter((x: any) => (options.columnsToExport === 'VISIBLE' ? x.selected : x)).map((x: any) => ((x.columnCategoryEnum === 'UDA')
        ? `${GeneralUtils.toTitleCase(x.entityType.toString().toLowerCase())} ${x.columnLabel}`
        : `${GeneralUtils.toTitleCase(x.entityType.toString().toLowerCase())}  ${GeneralUtils.toTitleCase(x.caption[0].value.toString())}`)));
    })
    const exportDataOptionsDto: IExportDataOptionsDto = {
      filterRawDataDto: {
        productType: this.dataGridParams.productType,
        reportType: this.dataGridParams.reportType,
        gridRenderingOptions: this.dataGridParams.gridRenderingOptions,
        advancedFilter: this.dataGridParams.advancedFilter,
        testParameterIds: this.dataGridParams.testParameterIds,
        gridTypeEnum: this.dataGridParams.gridTypeEnum,
        testParameterConditions: this.dataGridParams.testParameterConditions,
        reportSessionId: this.dataGridParams.reportSessionId,
        grouping: this.dataGridParams.grouping,
        sorting: this.dataGridParams.sorting,
        defaultGrouping: this.dataGridParams.defaultGrouping,
        limitSettingsObj: this.dataGridParams.limitSettingsObj,
        testParameterIndex: this.dataGridParams.testParameterIndex,
        testParameterIndexForWhichLimitsApplied: this.dataGridParams.testParameterIndexForWhichLimitsApplied,
        updatedStats: this.dataGridParams.updatedStats,
        selectedBinPlusDefId: this.dataGridParams.selectedBinPlusDefId,
        isSoftBin: this.dataGridParams.isSoftBin,
        filter: this.dataGridParams.filter,
        normalFilter: this.dataGridParams.normalFilter,
        sort: this.dataGridParams.sort
      },
      exportFormat: options.exportFormat,
      selectedColumns: visibleColumns,
      selectedColumnsCaptions: visibleColumnCaptions,
      fileSaveName: options.fileSaveName,
    }
    if (invokeDataExportOperation){
      if (height !== undefined && height !== '400'){
        this.setState({ isDataExporting: true, isDisableExportButton: true });
      } else {
        this.setState({ isDisableExportButton: true })
      }
      invokeDataExportOperation(exportDataOptionsDto)
    }
  }

  getControlTypeForColumnName = (columnName: string, entitiesList: string[]) => {
    let controlType = 'BIN_SUMMARY';
    entitiesList.forEach((entity) => {
      if (columnName.toLowerCase().includes(entity.toLowerCase())) {
        controlType = entity.toUpperCase();
      }
    });
    return controlType;
  };

  parseFilter = (filter: any) => {
    const { gridHeaderLegend } = this.state;
    const entities = gridHeaderLegend.gridColumnStructureDTO.map((item) => (item.caption[0].value));
    const newFiltersList: IExpressionsList[] = [];
    if (filter !== undefined) {
      const filterRowData = JSON.parse(filter);
      const isSingleFilter = filterRowData.filter((x: any) => x === 'and').length === 0 && filterRowData.length === 3;
      // filter on single column3
      if (isSingleFilter) {
        if (filterRowData[0].length !== 3) {
          const columnName = filterRowData[0];
          const operationName = filterRowData[1];
          const queryText = filterRowData[2];
          const dataType = typeof (queryText);
          const controlType = this.getControlTypeForColumnName(columnName, entities);
          const filterIndexAlreadyExists = newFiltersList.findIndex((x) => x.columnName === columnName);
          // FILTER ALREADY EXISTS
          if (filterIndexAlreadyExists >= 0) {
            newFiltersList[filterIndexAlreadyExists].operationName = operationName;
            newFiltersList[filterIndexAlreadyExists].queryText = queryText;
            newFiltersList[filterIndexAlreadyExists].dataType = dataType;
          } else {
            // FILTER DOES NOT EXIST
            newFiltersList.push({
              columnName,
              operationName,
              queryText,
              controlType,
              dataType,
              groupConditionOn: 'AND',
            });
          }
        }
      } else {
        // filter on multiple column
        const filtersList = filterRowData.filter((x: any) => x !== 'and' && x !== 'or');

        if (filtersList.length > 0) {
          for (let i = 0; i < filtersList.length; i += 1) {
            const currentFilter = filtersList[i];
            const columnName = currentFilter[0];
            const operationName = currentFilter[1];
            const queryText = currentFilter[2];
            const dataType = typeof (queryText);
            const controlType = this.getControlTypeForColumnName(columnName, entities);
            const filterIndexAlreadyExists = newFiltersList.findIndex((x) => x.columnName === columnName);
            // FILTER ALREADY EXISTS
            if (filterIndexAlreadyExists >= 0) {
              newFiltersList[filterIndexAlreadyExists].operationName = operationName;
              newFiltersList[filterIndexAlreadyExists].queryText = queryText;
              newFiltersList[filterIndexAlreadyExists].dataType = dataType;
            } else {
              // FILTER DOES NOT EXIST
              newFiltersList.push({
                columnName,
                operationName,
                queryText,
                controlType,
                dataType,
                groupConditionOn: 'AND',
              });
            }
          }
        }
      }
    }

    const {
      selectionStore,
    } = this.props;

    // adding filters from SCW including Test Parameters
    selectionStore.selections.forEach((selection) => {
      selection.values.forEach((value: ISelectionsObject) => {
        newFiltersList.push({
          columnName: selection.columnName!,
          operationName: '=',
          queryText: value.id,
          controlType: selection.controlType,
          dataType: typeof (value.id),
          groupConditionOn: 'OR',
        });
      });
    });

    return newFiltersList;
  };

  renderUtilityItems = () => {
    const {
      hideReportRow, reportType, viewAs, title, height
    } = this.props;
    const {
      gridHeaderLegend, interactionIndicator, isDisableExportButton, isDataExporting, totalItemCount
    } = this.state;
    const hint: IHint = {
      message: this.advancedFilterInstructions,
      href: '#',
      position: 'bottom',
    };

    // TEMP FIX: Change/find out why hidden and highlighted count not being updated correctly
    if (viewAs === 'DATA_TAB') {
      const interactionIndicatorOld = _.cloneDeep(interactionIndicator);
      interactionIndicator.hiddenCount = interactionIndicatorOld.highlightedCount;
      interactionIndicator.hiding = interactionIndicatorOld.selection;
      interactionIndicator.highlightedCount = interactionIndicator.hiddenCount;
      interactionIndicator.selection = interactionIndicatorOld.hiding;
    }

    return (
      <div className="custom-form d-flex align-items-center justify-content-between flex-wrap">
        <h5>{title}</h5>
        {viewAs === ReportType.RAW_DATA_GRID && (
          <div className="d-flex align-items-center mb10 flex-wrap">
            <span className="ml6 mr10">
              Advanced Filter
            </span>
            <Hint
              className="d-inline-block ml-14 mr10 pt2"
              hint={hint}
              variant="icon"
              mode="popover"
            />
            <CodeEditor
              editorClassName="w300 p6 mt-3"
              rows={1}
              placeHolder="For Example: f.name = {XYZ}"
              matchingCriterion={(this.codeEditorExpressions())}
              updateOnBlur={(value: any) => {
                this.advancedValue = value;
              }}
            />
            <Button
              className="ml4 ml10 btn-config"
              variant="clear"
              onClick={() => {
                localStorage.setItem('SelectedAdvancedFilter', this.advancedValue);
                this.setState({ advancedFilter: this.advancedValue }, () => {
                  this.actualDataGridProps = this.getActualDataGridProps();
                });
              }}
            >
              <FontAwesomeIcon
                size="sm"
                icon={faSearch}
              />
            </Button>

          </div>
        )}
        <div className="d-flex align-items-center mb10">
          <div>
            <InteractionIndicators
              hiddenCount={interactionIndicator.hiddenCount}
              highlightedCount={interactionIndicator.highlightedCount}
              interactionIndicator={interactionIndicator}
              toggleConfirmClearInteractions={this.toggleConfirmClearInteractions}
            />
          </div>
          {
            !AppConstants.isTempHidden && (
              <div>
                {
                  this.renderViewModeButtons()
                }
              </div>
            )
          }
          {height !== '400' && title === 'Raw Data Grid' && isDataExporting && (
            <div className="mr10 d-flex align-items-center pr10 pl10 background-color-warning color-light border-radius-4">
              <Spinner className="mr10" size="sm" animation="border" variant="clear" />
              <p className="pt13">Exporting Data...</p>
            </div>
          )}
          <DataExportModal
            invokeDataExport={this.exportRawDataGridData}
            viewAs={viewAs}
            gridHeaderLegend={gridHeaderLegend}
            disabled={isDisableExportButton}
            totalRowCount={totalItemCount}
          />
          <div className="pl20">
            <ColumnChooser
              columnChooserTitle="Grid Column Chooser"
              mode="row"
              applyColumnChanges={this.applyColumnChanges}
              fields={gridHeaderLegend.gridColumnStructureDTO}
              title="Grid Column Chooser"
            />
          </div>
          {
            (hideReportRow && reportType) && (
              <Button
                className="ml4 ml20 btn-config"
                variant="clear"
                onClick={() => {
                  hideReportRow(reportType);
                }}
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faTimes}
                />
              </Button>
            )
          }
        </div>
      </div>
    );
  };

  parseDtosInAdvancedFilter = () => {
    const { gridHeaderLegend, advancedFilter } = this.state;
    let advancedFilterUpdated = _.cloneDeep(advancedFilter);
    const matchingInstances = advancedFilterUpdated.match(/(f|l|w|d|tp|tpr|wc|bs|di|tepa|ts)(?:\.)(\w+)/g);
    let matchingInstancesPartial;
    let column;
    let replacementText = '';
    if (matchingInstances) {
      matchingInstancesPartial = matchingInstances.map((x) => x.substring(x.indexOf('.') + 1));
      matchingInstancesPartial.forEach((matchingInstancePartial: string, index: number) => {
        column = gridHeaderLegend.gridColumnStructureDTO.flatMap((gridColumn: any) => gridColumn.fields.find((field: any) => field.columnName === matchingInstancePartial && field.columnCategoryEnum === 'UDA'));
        if (column && column[0]) {
          replacementText = column[0].dataField;
          advancedFilterUpdated = replacementText ? advancedFilterUpdated.replace(matchingInstances[index], replacementText) : advancedFilterUpdated;
        }
      });
      if (advancedFilter !== advancedFilterUpdated) {
        this.setState({ advancedFilter: advancedFilterUpdated });
      }
    }
  };

  codeEditorExpressions = () => {
    const { gridHeaderLegend, gridType } = this.state;
    const distinctAlias: string[] = [];
    gridHeaderLegend.gridColumnStructureDTO.filter((x) => x.entityType !== null).forEach(
      (item) => {
        if (distinctAlias.indexOf(item.entityType) < 0) {
          distinctAlias.push(item.alias);
        }
      },
    );

    const regexExpressions = [
      {
        regex: /^$/,
        values: {
          type: ('list' as TTypeAheadValuesType),
          values: distinctAlias.map(
            (item) => (item),
          ),
        },
      },
    ];

    regexExpressions.push({
      regex: /} $/,
      values: {
        type: ('list' as TTypeAheadValuesType),
        values: ['AND', 'OR'],
      },
    });

    gridHeaderLegend.gridColumnStructureDTO.filter((x) => x.entityType !== null).forEach(
      (item, index) => {
        if (index > 7 && gridType === this.gridType.PIVOTED_MERGE) {
          regexExpressions.push({
            regex: new RegExp(`${item.alias}\\.$`, 'i'),
            values: { type: ('list' as TTypeAheadValuesType), values: item.caption.filter((x) => x.isPrimaryColumn).map((fieldItem) => (fieldItem.columnName === 'key' ? 'id' : fieldItem.columnName)) },
          });
        } else {
          const columns: string[] = [];
          const testParameterColumns: string[] = [];
          item.fields.filter((x) => x.isPrimaryColumn).forEach((fieldItem) => {
            if (fieldItem.columnName != null) {
              columns.push(fieldItem.columnName === 'key' ? 'id' : fieldItem.columnName);
            }
          });
          item.fields.filter((x) => x.isPrimaryColumn && x.columnCategoryEnum === this.ColumnMetaHeaderType.TEST_PARAMETER_VALUE).forEach((element) => {
            element.caption.forEach((x) => {
              if (testParameterColumns.indexOf(x.columnName) < 0) {
                testParameterColumns.push(x.columnName);
              }
            });
          });
          const values: string[] = [...columns, ...testParameterColumns];

          regexExpressions.push({
            regex: new RegExp(`${item.alias}\\.$`, 'i'),
            values: { type: ('list' as TTypeAheadValuesType), values },
          });
        }
      },
    );

    gridHeaderLegend.gridColumnStructureDTO.forEach((item, index) => {
      if (index > 7 && gridType === this.gridType.PIVOTED_MERGE) {
        item.caption.forEach((fieldItem) => {
          regexExpressions.push({
            regex: new RegExp(`${item.alias}.${fieldItem.columnName === 'key' ? 'id' : fieldItem.columnName} $`, 'i'),
            values: {
              type: ('list' as TTypeAheadValuesType),
              values: fieldItem.type?.indexOf('String') !== -1 || fieldItem.type === 'VARCHAR'
                ? ['=', 'LIKE', 'NOT LIKE', 'IS', 'IS NOT', 'AND', 'OR'] : ['=', '>', '<', '>=', '<=', '<>', 'IN', 'BETWEEN', 'AND', 'OR'],
            },
          });
        });
      } else {
        const columns = item.fields.filter((x) => x.isPrimaryColumn && x.columnName !== null).map((fieldItem) => ({
          alias: fieldItem.alias,
          columnName: fieldItem.columnName,
          type: fieldItem.type,
          entityType: fieldItem.entityType,
        }));

        const testParameterColumns = item.fields.filter(
          (x) => x.isPrimaryColumn
            && x.columnCategoryEnum === this.ColumnMetaHeaderType.TEST_PARAMETER_VALUE,
        )
          .map((element) => (element.caption.map((captionItem) => (
            {
              alias: captionItem.alias,
              columnName: captionItem.columnName,
              type: captionItem.type,
            }))
          )).flat();
        const values = [...columns, ...testParameterColumns];
        values.forEach((fieldItem) => {
          regexExpressions.push({
            regex: new RegExp(`${item.alias}.${fieldItem.columnName === 'key' ? 'id' : fieldItem.columnName} $`, 'i'),
            values: {
              type: ('list' as TTypeAheadValuesType),
              values: fieldItem.type?.indexOf('String') > 0
                || fieldItem.type === 'VARCHAR' ? ['=', 'LIKE', 'NOT LIKE', 'IS', 'IS NOT', 'AND', 'OR']
                : ['=', '>', '<', '>=', '<=', '<>', 'IN', 'BETWEEN', 'AND', 'OR'],
            },
          });
        });
      }
    });

    this.parseDtosInAdvancedFilter();

    return regexExpressions;
  };

  setGridType = (gridType: string) => {
    this.setState({ gridType }, this.getRawDataGrid);
  };

  renderViewModeButtons = () => {
    const { gridType } = this.state;

    const viewModes = [
      {
        title: 'Merge Mode',
        gridType: this.gridType.PIVOTED_MERGE,
        iconClass: 'dx-icon-mergecells',
      },
      {
        title: 'Split Mode',
        gridType: this.gridType.PIVOTED_SPLIT,
        iconClass: 'dx-icon-columnfield',
      },
      {
        title: 'Unpivoted Mode',
        gridType: this.gridType.UNPIVOTED,
        iconClass: 'dx-icon-detailslayout',
      },
    ];

    return (
      <div>
        {
          viewModes.map((item, index: number) => (
            <OverlayTrigger
              key={`tooltip_raw_data_grid_mode_${index}`}
              placement="bottom"
              overlay={(
                <Tooltip id={`tooltip_text_raw_data_grid_mode_-${index}`}>
                  {item.title}
                </Tooltip>
              )}
            >
              <Button
                key={`raw-data-grid-view-mode-button-${item.gridType}`}
                variant={gridType === item.gridType ? 'primary' : 'clear'}
                className={`${item.iconClass} outline-grey border-all ml4 pl5 pr5 pt0 pb0 raw-data-grid-row-buttons h30 w30
              ${gridType === item.gridType ? '' : 'btn-clear'}`}
                type="button"
                size="lg"
                onClick={() => {
                  this.setGridType(item.gridType);
                }}
              >
                <i />
              </Button>
            </OverlayTrigger>
          ))
        }
      </div>
    );
  };

  updateInteractionIndicatorAndRowCount = (highlightedCount: number, hiddenCount: number, totalItemCount: number) => {
    const interactionIndicator: any = {
      selection: this.currentInteractionEvent === 'SELECTION',
      hiding: this.currentInteractionEvent === 'HIDE',
      highlightedCount,
      hiddenCount,
    };
    this.setState({ interactionIndicator, totalItemCount });
  }

  applyInteraction = (eventType: InteractionEventType, interactionMode: InteractionMode, action: ActionType) => {
    if (this.selectedRowsData.length === 0) {
      toast.warn('Select some data before applying interaction');
      return;
    }

    this.currentInteractionEvent = eventType;

    const interactionDetails: { [key:string]: string }[] = [];

    this.selectedRowsData.forEach((row: any) => {
      const interactionDetail: { [key: string]: any } = {};
      interactionDetail.die_id = row.die_id;
      interactionDetail.die_x = row.die_x;
      interactionDetail.die_y = row.die_y;
      interactionDetails.push(interactionDetail);
    });

    const interactionInputDto: InteractionInputDTO = {
      eventType,
      interactionMode,
      requestObject: this.getRawDataGridRequestObject('COMBINED'),
      interactionDetails,
    };

    const reportIndex = -1;

    httpRawData.setInteractionData(interactionInputDto).then(() => {
      this.pubSubBinder.BroadcastEvent(this.publisherId, 'ALL_REPORTS_AND_LEGENDS', action, { reportIndex });
    });
    this.retainPriorSort = true;
    this.actualDataGridProps = this.getActualDataGridProps();
    this.getRawDataGrid();
  }

  clearInteractions = () => {
    const selectionEventInput: InteractionInputDTO = {
      eventType: 'CLEAR_INTERACTIONS',
      interactionMode: 'ALL',
      requestObject: this.getRawDataGridRequestObject('COMBINED'),
      interactionDetails: [],
    };

    this.currentInteractionEvent = 'CLEAR_INTERACTIONS';

    httpService.clearRawDataGridInteractions(selectionEventInput).then(() => {
      this.setState({
        toggleShowConfirmClearInteractions: false,
        interactionIndicator: {
          selection: false,
          hiding: false,
          highlightedCount: 0,
          hiddenCount: 0,
        },
      }, () => {
        this.pubSubBinder.BroadcastEvent(this.publisherId, 'ALL_REPORTS_AND_LEGENDS', 'SELECT_GRAPH_AND_LEGEND_ITEMS', { clearIndicators: true });
        this.actualDataGridProps = this.getActualDataGridProps();
        this.getRawDataGrid();
      });
    });
  };

  getRawDataGridRequestObject = (viewMode: IViewMode) => {
    const {
      parseFilter, selectionStore, groupingSortingListStore, reportSessionId, aggredateFunction, config,
    } = this.props;

    const data: IGenericDetailedReportRequestObject = {
      scwData: selectionStore.selections.map((item) => ({
        entityType: (item.controlType as EntityType),
        values: item.values.map((val) => (val.id)),
      })),
      filters: JSON.stringify(parseFilter()),
      // binPlusDefId: "selectedBinPlusDefinition.id",
      binPlusDefId: '',
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: aggredateFunction as AggregateFunctionNames, // TO DO: Remove hard code and make prop specific
      seriesType: null,
      config,
    };
    return data;
  };

  rawDataGridSubscriptionListener = (action: ActionType, params?: any) => {
    if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS'){
      this.currentInteractionEvent = 'SELECTION';
      this.actualDataGridProps = this.getActualDataGridProps();
      this.getRawDataGrid();
    } else if (action === 'HIDE_GRAPH_AND_LEGEND_ITEMS'){
      this.currentInteractionEvent = 'HIDE';
      this.actualDataGridProps = this.getActualDataGridProps();
      this.getRawDataGrid();
    }
    if (params && params.clearIndicators){
      this.currentInteractionEvent = 'CLEAR_INTERACTIONS';
      this.actualDataGridProps = this.getActualDataGridProps();
      this.getRawDataGrid();
    }
    if (action === 'HIDE_DATA_EXPORT_SPINNER'){
      this.setState({ isDataExporting: false, isDisableExportButton: false })
    }
  };

  filterExpressionsRecursive = (filterExpressions: any, finalFilterExpression: string) => {
    if (Array.isArray(filterExpressions)){
      filterExpressions.forEach((filterExpression: any, index: number) => {
        if (Array.isArray(filterExpressions[index][0])){
          finalFilterExpression = this.filterExpressionsRecursive(filterExpressions[index], finalFilterExpression);
        }
        if (index % 2 !== 0){
          finalFilterExpression += ' and ';
        } else {
          finalFilterExpression += GeneralUtils.getDataTableFilterFromDataGridFilter(filterExpression);
        }
      })
    } else {
      finalFilterExpression += GeneralUtils.getDataTableFilterFromDataGridFilter(filterExpressions);
    }
    return finalFilterExpression;
  }

  onFilterExpressionChanged = (filterExpressions: any) => {
    let finalFilterExpression = '';
    if (!filterExpressions){
      return '';
    }
    filterExpressions = JSON.parse(filterExpressions);
    if (Array.isArray(filterExpressions[0])){
      finalFilterExpression = this.filterExpressionsRecursive(filterExpressions, finalFilterExpression)
    } else {
      finalFilterExpression += GeneralUtils.getDataTableFilterFromDataGridFilter(filterExpressions);
    }
    return finalFilterExpression;
  }

  getActualDataGridProps = () => {
    const { viewAs, height } = this.props;
    const store = this.customDataSource();
    return ({
      height: height || '400',
      dataSource: {
        store,
      },
      loadPanel: {
        enabled: true,
        shading: true,
        showPane: false,
        indicatorSrc: spinner,
        shadingColor: '#f6f7ff',
        text: 'Loading...',
      },
      filterRow: {
        visible: true,
      },
      onContentReady: (e: any) => {
        if (this.selectedRowKeys.length > 0) {
          e.component.selectRows(this.selectedRowKeys, true);
        }
      },
      onRowPrepared: (e: any) => {
        if (e.rowType === 'data' && (e.data.hidden || e.data.hidden === true)) {
          e.rowElement.classList.add('row-disable');
        }
      },
      onSelectionChanged: (selectedRowsArgs: any) => {
        const { selectedRowKeys, selectedRowsData, component } = selectedRowsArgs;
        if (selectedRowsData.length === component.totalCount()) {
          this.allRowsSelected = true;
        } else {
          this.allRowsSelected = false;
        }
        this.selectedRowKeys = selectedRowKeys;
        this.selectedRowsData = selectedRowsData;
      },
      onCellClick: (e: any) => {
        if (e.column && e.column.type && e.column.type === 'selection' && !e.data){
          this.allRowsSelected = !this.allRowsSelected;
        }
      },
      onSaving: (e: any) => {
        const changedStats: any[] = [];
        let columnIndex = Object.keys(e.changes[0].data)[0].substring(Object.keys(e.changes[0].data)[0].length - 4);
        if (columnIndex !== '_c_o') {
          columnIndex = Object.keys(e.changes[0].data)[0].substring(Object.keys(e.changes[0].data)[0].length - 6);
        }
        const columnAliases = Object.keys(e.changes[0].data);
        const columnValues = Object.values(e.changes[0].data);
        // TODO: Add row index instead of just 0, when multiple parameters/multiple rows in stats tab can exist
        let lowLimitColVal = e.component.cellValue(0, `limit_value_low${columnIndex.toString()}`);
        let highLimitColVal = e.component.cellValue(0, `limit_value_high${columnIndex.toString()}`);
        if (lowLimitColVal === null) {
          lowLimitColVal = Number.MIN_SAFE_INTEGER;
        }
        if (highLimitColVal === null) {
          highLimitColVal = Number.MAX_SAFE_INTEGER;
        }
        columnAliases.forEach((change: any, index: number) => {
          if (isNaN(Number(columnValues[index]))) {
            toast.error('Only a numeric value can be applied here');
          } else {
            changedStats.push({
              alias: columnAliases[index],
              value: Number(columnValues[index]),
              lowLimitVal: Number(lowLimitColVal),
              highLimitVal: Number(highLimitColVal),
            });
          }
        });
        this.setState({ updatedStats: changedStats });
      },
      editing: {
        allowUpdating: true,
        mode: 'cell',
      },
      className: 'w-100',
      key: (new Date()).toString(),
      selection: {
        mode: 'multiple',
        showCheckBoxesMode: 'onClick',
        allowSelectAll: true,
        selectAllMode: 'page',
      },
      scrolling: {
        mode: 'infinite',
        showScrollbar: 'onHover',
        columnRenderingMode: 'virtual',
        rowRenderingMode: 'virtual',
        useNative: true,
        scrollByContent: true,
      },
      paging: {
        enabled: true,
        pageSize: 200,
      },
      sorting: {
        mode: 'multiple',
      },
      rowAlternationEnabled: true,
      hoverStateEnabled: true,
      remoteOperations: true,
      columnAutoWidth: true,
      allowColumnReordering: true,
      showBorders: true,
      allowColumnResizing: true,
      onContextMenuPreparing: viewAs === 'DATA_TAB' ? undefined : (e: any) => {
        if (e.row && e.row.rowType === 'data') {
          e.items = [
            {
              text: 'Hide',
              visible: true,
              items: [
                {
                  text: 'Hide Selected',
                  onItemClick: () => {
                    this.applyInteraction('HIDE', this.allRowsSelected ? 'ALL' : 'SELECTED', 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Hide Similar',
                  onItemClick: () => {
                    this.applyInteraction('HIDE', this.allRowsSelected ? 'ALL' : 'SELECTED', 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Hide All',
                  onItemClick: () => {
                    this.applyInteraction('HIDE', 'ALL', 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
              ],
            },
            {
              text: 'Highlight',
              visible: true,
              items: [
                {
                  text: 'Highlight Selected',
                  onItemClick: () => {
                    this.applyInteraction('SELECTION', this.allRowsSelected ? 'ALL' : 'SELECTED', 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Highlight Similar',
                  onItemClick: () => {
                    this.applyInteraction('SELECTION', this.allRowsSelected ? 'ALL' : 'SIMILAR', 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Highlight All',
                  onItemClick: () => {
                    this.applyInteraction('SELECTION', 'ALL', 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
              ],
            },
          ];
        }
      },
    });
  };

  renderRawDataGrid = () => {
    const {
      columns, toggleShowConfirmClearInteractions,
    } = this.state;

    const dataGridProps: any = this.actualDataGridProps;
    // this.dataGrid = React.createElement(DataGrid, dataGridProps, [
    //   columns,
    // ]);

    return (
      <>
        <DataGrid ref={this.dataGridRef} {...dataGridProps}>{[...columns]}</DataGrid>
        <Modal
          show={toggleShowConfirmClearInteractions}
          onClick={() => { this.toggleConfirmClearInteractions(false); }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to reset all hidden and highlighted points from all of the reports?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={() => { this.toggleConfirmClearInteractions(false); }}>
              No
            </Button>
            <Button variant="danger" onClick={() => { this.clearInteractions(); }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  render() {
    const {
      className, viewAs,
    } = this.props;
    const { columnsLoaded, totalItemCount } = this.state;
    if (!columnsLoaded) {
      return null;
    }
    return (
      <div className={`${viewAs === ReportType.RAW_DATA_GRID ? 'border-all mb10 background-color-light border-radius-4 ' : ''}
        ${className ?? ''}`}
      >
        <div className="w-100 mb10">
          <div>
            {
              this.renderUtilityItems()
            }
          </div>
        </div>
        <Container fluid>

          <Row>
            <Col lg={12} className="background-color-light">
              {this.renderRawDataGrid()}
            </Col>
          </Row>
          {viewAs === ReportType.RAW_DATA_GRID
          && (
            <Row className="mt10 ml5">
              <h6>{`Total Entries: ${totalItemCount}`}</h6>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

export default CustomizedRawDataGrid;
