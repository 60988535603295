/* eslint-disable react/prefer-stateless-function */
import { CheckBox, DataGrid, DropDownBox } from 'devextreme-react';
import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { WaferDraggableControlTab } from './WaferDraggableControlTab';

type WaferOverlayControlsProps = {
  softBins: { [sbn: string]: any },
  wafers: { waferId: string, waferKey: string }[],
  onApplyOverlay: (selectedSoftbinsForOverlay: string[], selectedWaferIdsForOverlay: string[]) => void,
  onChangeSoftbinSelection: (value: boolean, softbinNumber: string) => void,
  onSelectAllSoftbins: (value: boolean, softbinNumbers: string[]) => void,
  onChangeOverlayWafersSelection: (selectedWaferIdsForOverlay: string[]) => void,
  selectedSoftbinsForOverlay: string[],
  selectedWaferIdsForOverlay: string[],
};

type WaferOverlayControlsState = {
  overlayControlsEventKey: string,
};

export default class WaferOverlayControls extends React.Component<WaferOverlayControlsProps, WaferOverlayControlsState> {
  constructor(props: WaferOverlayControlsProps) {
    super(props);
    this.state = {
      overlayControlsEventKey: '0',
    };
  }

  onChangeWidgetAccordian = (eventKey: string) => {
    this.setState({ overlayControlsEventKey: eventKey });
  };

  getSoftbinCheckboxes = () => {
    const {
      softBins, onChangeSoftbinSelection, selectedSoftbinsForOverlay, onSelectAllSoftbins,
    } = this.props;
    if (!softBins) return null;
    const checkBoxes: any[] = [];

    checkBoxes.push((
      <div>
        <CheckBox
          className="mt10 mr20"
          value={Object.keys(softBins).every((sbn: string) => { return selectedSoftbinsForOverlay.includes(sbn); })}
          onValueChange={(value: boolean) => { onSelectAllSoftbins(value, Object.keys(softBins)); }}
        />
        <strong>Select All</strong>
      </div>
    ));

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const softbinNumber in softBins) {
      checkBoxes.push((
        <div>
          <CheckBox
            className="mt10 mr20"
            value={selectedSoftbinsForOverlay.includes(softbinNumber)}
            onValueChange={(value: boolean) => { onChangeSoftbinSelection(value, softbinNumber); }}
          />
          { softBins[softbinNumber] ? (
            <>
              {' '}
              {softBins[softbinNumber].number}
              {' '}
              -
              {' '}
              {softBins[softbinNumber].name}
            </>
          ) : ` ${softbinNumber}`}
        </div>
      ));
    }
    return checkBoxes;
  };

  render() {
    const { overlayControlsEventKey } = this.state;
    const {
      wafers, selectedWaferIdsForOverlay, onChangeOverlayWafersSelection, onApplyOverlay, selectedSoftbinsForOverlay,
    } = this.props;
    return (
      <>
        <div className="text-center">
          <strong style={{ fontWeight: 'bolder' }} className="text">Select Wafers For Stacking: </strong>
          <DropDownBox
            value={selectedWaferIdsForOverlay}
            valueExpr="waferId"
            deferRendering={false}
            displayExpr="waferKey"
            dropDownOptions={{ width: 'auto' }}
            placeholder="Select wafers..."
            dataSource={wafers}
            onValueChanged={(e: any) => { if (e.value) onChangeOverlayWafersSelection(e.value); }}
            contentRender={() => {
              return (
                <DataGrid
                  dataSource={wafers}
                  columns={['waferKey']}
                  keyExpr="waferId"
                  width={200}
                  hoverStateEnabled
                  selectedRowKeys={selectedWaferIdsForOverlay}
                  onSelectionChanged={(e: any) => { onChangeOverlayWafersSelection(e.selectedRowKeys); }}
                  selection={{ mode: 'multiple' }}
                  filterRow={{ visible: true }}
                />
              );
            }}
          />
        </div>
        <Accordion
          activeKey={overlayControlsEventKey}
        >
          <WaferDraggableControlTab
            onChangeWidgetAccordian={this.onChangeWidgetAccordian}
            controlProps={{
              type: 'OVERLAY_CONTROLS',
              eventKey: '0',
              title: 'Bins',
              body: (
                <>
                  {this.getSoftbinCheckboxes()}
                </>
              ),
            }}
          />
        </Accordion>
        <div className="text-center">
          <Button
            variant="success"
            className="mt10 mb10"
            onClick={() => { onApplyOverlay(selectedSoftbinsForOverlay, selectedWaferIdsForOverlay); }}
          >
            Apply
          </Button>
        </div>
      </>
    );
  }
}
