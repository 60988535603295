import { faEye, faObjectGroup, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Col, OverlayTrigger, Popover, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IInteractionIndicatorsProps {
  interactionIndicator : any,
  hiddenCount: any,
  highlightedCount:any,
  toggleConfirmClearInteractions:any
}

const renderInteractionIndicators = (show: boolean, title: string, icon: any, label:string, message: any) => {
  if (!show) {
    return <></>;
  }

  const popover = (
    <Popover id={title} className="w150">
      <Popover.Content>
        <Row>
          <Col>
            <span>
              <b>{label}</b>
            </span>
          </Col>
          <Col>
            <span>
              {message}
            </span>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
      <div className="d-flex align-items-center ml4 background-color-info">
        <div className="pt3 pb3 pl5 pr5 color-light" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
          <FontAwesomeIcon size="sm" icon={icon} />
        </div>
        <div className="pt3 pb3 pl5 pr5 color-light">
          {title}
        </div>
        <div className="pt3 pb3 pl5 pr5 color-light" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
          {message}
        </div>
      </div>
    </OverlayTrigger>
  );
};

const InteractionIndicators = (props:IInteractionIndicatorsProps) => {
  const {
    interactionIndicator, toggleConfirmClearInteractions, highlightedCount, hiddenCount,
  } = props;
  return (
    <div className="d-flex align-items-center">
      {renderInteractionIndicators(
        interactionIndicator.selection,
        'Highlighted',
        faObjectGroup,
        'Count',
        highlightedCount,
      )}
      {renderInteractionIndicators(
        interactionIndicator.hiding,
        'Hidden',
        faEye,
        'Count',
        hiddenCount,
      )}
      {
        (interactionIndicator.selection || interactionIndicator.hiding) && (
          <button
            type="button"
            className="ml4 pt3 pb3 pl5 pr5 border-none background-color-danger color-light background-color-hover-danger"
            onClick={() => { toggleConfirmClearInteractions(true); }}
          >
            <FontAwesomeIcon
              className="mr4 pt1"
              icon={faTimes}
            />
            Clear Interactions
          </button>
        )
      }
    </div>
  );
};

export default InteractionIndicators;
