/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

const setBinNumberCanvas = (id: string, waferMapInstance: WebGLUtils): void => {
  const binNumberCanvas: HTMLCanvasElement = document.querySelector(`#${id}`) as HTMLCanvasElement;
  if (!binNumberCanvas) return;
  waferMapInstance.waferMapVariables.ctx = binNumberCanvas.getContext('2d');
  const { binCanvasWidth, binCanvasHeight, angleInDegrees } = waferMapInstance.waferMapVariables;
  binNumberCanvas.width = binCanvasWidth;
  binNumberCanvas.height = binCanvasHeight;
  waferMapInstance.waferMapVariables.ctx.translate(binCanvasWidth / 2, binCanvasHeight / 2);
  waferMapInstance.waferMapVariables.ctx.rotate(angleInDegrees * (Math.PI / 180));
  waferMapInstance.waferMapVariables.ctx.translate(-binCanvasWidth / 2, -binCanvasHeight / 2);
};

export default setBinNumberCanvas;
