import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpPreference extends HttpBase {
  getPreferenceByName = (preference: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/preference/${preference}`);
  };
}

const httpPreference = new HttpPreference();
export { httpPreference };
