/* eslint-disable quotes */
const outlierLimitsDropdownData = [
  {
    id: '1',
    text: 'Outlier Screening',
    expanded: true,
    selected: false,
    items: [
      {
        id: '1_1',
        text: 'Raw Data Spec',
        expanded: true,
        selected: false,
      },
      {
        id: '1_2',
        text: 'Raw Data Control',
        expanded: true,
        selected: false,
      },
      {
        id: '1_3',
        text: 'Tukey',
        expanded: true,
        selected: false,
      },
      {
        id: '1_4',
        text: 'User Defined Custom',
        expanded: true,
        selected: false,
      },
      {
        id: '1_5',
        text: 'Manual Custom',
        expanded: true,
        selected: false,
      },
      {
        id: '1_6',
        text: 'PAT Limit',
        expanded: true,
        selected: false,
      },
      {
        id: '1_7',
        text: 'Sigma',
        expanded: true,
        selected: false,
      },
    ],
  },
];

const distributionLimitsDropdownData = [
  {
    id: '1',
    text: 'Distribution Limits',
    expanded: true,
    selected: false,
    items: [
      {
        id: '1_1',
        text: 'Raw Data Spec Limits',
        expanded: true,
        selected: false,
      },
      {
        id: '1_2',
        text: 'Raw Data Control Limits',
        expanded: true,
        selected: false,
      },
      {
        id: '1_3',
        text: 'Tukey Limits',
        expanded: true,
        selected: false,
      },
      {
        id: '1_4',
        text: 'User Defined Custom Limits',
        expanded: true,
        selected: false,
      },
      {
        id: '1_5',
        text: 'Manual Custom Limits',
        expanded: true,
        selected: false,
      },
      {
        id: '1_6',
        text: 'PAT Limit',
        expanded: true,
        selected: false,
      },
      {
        id: '1_7',
        text: 'Sigma',
        expanded: true,
        selected: false,
      },
    ],
  },
];

const limitSettingsOptions = {
  outlier: [
    {
      index: 0,
      type: 'Raw Data Spec',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#03fce8',
      customNameLow: 'Raw Data Spec (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#03a5fc',
      customNameHigh: 'Raw Data Spec (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 1,
      type: 'Raw Data Control',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#be03fc',
      customNameLow: 'Raw Data Control (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#fc03df',
      customNameHigh: 'Raw Data Control (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 2,
      type: 'Tukey',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#3dfc03',
      customNameLow: 'Tukey (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#027506',
      customNameHigh: 'Tukey (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 3,
      type: 'User Defined Custom',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 1,
      highVal: 2,
      valUnit: '',
      colorLow: '#d9ce04',
      customNameLow: 'User Defined Custom',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#d9ce04',
      customNameHigh: 'User Defined Custom',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 4,
      type: 'Manual Custom',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 10,
      valUnit: '',
      colorLow: '#d91904',
      customNameLow: 'Manual Custom (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#e06558',
      customNameHigh: 'Manual Custom (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 5,
      type: 'PAT Limit',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#be03fc',
      customNameLow: 'PAT Limit (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#fc03df',
      customNameHigh: 'PAT Limit (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 6,
      type: 'Sigma',
      selected: false,
      displayLow: false,
      displayHigh: false,
      lowCheck: true,
      highCheck: true,
      lowVal: -1,
      lowValDisplayed: -1,
      highVal: 1,
      highValDisplayed: 1,
      valUnit: '',
      colorLow: '#3a5cc2',
      customNameLow: 'Sigma 1 (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#4a3cc2',
      customNameHigh: 'Sigma 1 (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
  ],
  distribution: [
    {
      index: 0,
      type: 'Raw Data Spec',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#03fce8',
      customNameLow: 'Raw Data Spec (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#03a5fc',
      customNameHigh: 'Raw Data Spec (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 1,
      type: 'Raw Data Control',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#be03fc',
      customNameLow: 'Raw Data Control (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#fc03df',
      customNameHigh: 'Raw Data Control (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 2,
      type: 'Tukey',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#3dfc03',
      customNameLow: 'Tukey (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#027506',
      customNameHigh: 'Tukey (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 3,
      type: 'User Defined Custom',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 1,
      highVal: 2,
      valUnit: '',
      colorLow: '#d9ce04',
      customNameLow: 'User Defined',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#d9ce04',
      customNameHigh: 'User Defined',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 4,
      type: 'Manual Custom',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 10,
      highVal: 50,
      valUnit: '',
      colorLow: '#d91904',
      customNameLow: 'Manual Custom (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#e06558',
      customNameHigh: 'Manual Custom (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 5,
      type: 'PAT Limit',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: true,
      lowVal: 0,
      highVal: 0,
      valUnit: '',
      colorLow: '#be03fc',
      customNameLow: 'PAT Limit (Low)',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#fc03df',
      customNameHigh: 'PAT Limit (High)',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
    {
      index: 6,
      type: 'Sigma',
      selected: false,
      displayLow: true,
      displayHigh: true,
      lowCheck: true,
      highCheck: false,
      lowVal: -1,
      lowValDisplayed: -1,
      highVal: 1,
      highValDisplayed: 1,
      valUnit: '',
      colorLow: '#3a5cc2',
      customNameLow: 'Sigma 1',
      appearanceLow: 'solid',
      thicknessLow: '3',
      colorHigh: '#3a5cc2',
      customNameHigh: 'Sigma 1',
      appearanceHigh: 'solid',
      thicknessHigh: '3',
    },
  ],
};

const legendSummaryOptionsForLimits = [
  {
    name: 'Outlier Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'Outlier Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'Outlier Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'Outlier Fail Count',
    showInGroupFooter: true,
  },
  {
    name: 'Raw Data Spec Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'Raw Data Spec Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'Raw Data Spec Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'Raw Data Spec Fail Count',
    showInGroupFooter: true,
  },
  {
    name: 'Raw Data Control Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'Raw Data Control Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'Raw Data Control Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'Raw Data Control Fail Count',
    showInGroupFooter: true,
  },
  {
    name: 'Tukey Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'Tukey Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'Tukey Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'Tukey Fail Count',
    showInGroupFooter: true,
  },
  {
    name: 'User Defined Custom Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'User Defined Custom Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'User Defined Custom Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'User Defined Custom Fail Count',
    showInGroupFooter: true,
  },
  {
    name: 'Manual Custom Parametric Yield',
    summaryType: 'custom',
    displayFormat: 'Parametric Yield: {0}%',
    showInColumn: 'Manual Custom Pass Count',
    showInGroupFooter: true,
  },
  {
    name: 'Manual Custom Yield Loss',
    summaryType: 'custom',
    displayFormat: 'Yield Loss: {0}%',
    showInColumn: 'Manual Custom Fail Count',
    showInGroupFooter: true,
  },
];

export default {
  getOutlierLimitsDropdownData() {
    return outlierLimitsDropdownData;
  },
  getDistributionLimitsDropdownData() {
    return distributionLimitsDropdownData;
  },
  getLimitSettingsOptions() {
    return limitSettingsOptions;
  },

  removePATLimitOptionFromOutlierList() {
    return outlierLimitsDropdownData[0].items.filter((obj) => obj.text !== 'PAT Limit')
  },

  removePATLimitsOptionFromDistributionList() {
    return distributionLimitsDropdownData[0].items.filter((obj) => obj.text !== 'PAT Limit');
  },

  getLegendSummaryOptionsForLimits() {
    if (
      !legendSummaryOptionsForLimits.find(
        (x) => x.name === 'Sigma (0) Parametric Yield',
      )
    ) {
      for (let i = 0; i < 12; i += 1) {
        legendSummaryOptionsForLimits.push(
          {
            name: `Sigma (${i}) Parametric Yield`,
            summaryType: 'custom',
            displayFormat: 'Parametric Yield: {0}%',
            showInColumn: `Sigma (${i}) Pass Count`,
            showInGroupFooter: true,
          },
          {
            name: `Sigma (${i}) Yield Loss`,
            summaryType: 'custom',
            displayFormat: 'Yield Loss: {0}%',
            showInColumn: `Sigma (${i}) Fail Count`,
            showInGroupFooter: true,
          },
        );
      }
    }
    return legendSummaryOptionsForLimits;
  },
};
