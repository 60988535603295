import React, { Component } from 'react';
import {
  Row, Col, Spinner, Button, ProgressBar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import Heading from '../../wrapped-component/hint-controls/Heading';

class SimulatePolicyComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      allowReportsSelection,
      revokeReportGeneration,
      simulateHandler,
      isSimulated,
      viewPolicyOutput,
      simulatedPolicyProgress,
      simulatedPolicyProgressBarVariant,
    } = this.props;
    return (
      <div className="d-flex justify-content-center h249">
        <Row>
          <Col lg={12}>
            {/* <Heading */}
            {/*   size={6} */}
            {/*   className="d-flex align-items-center rounded mb0 h30 p4 background-color-secondary color-light" */}
            {/* > */}
            {/*   Simulate Policy */}
            {/* </Heading> */}
            <Heading size={6}> Simulate Policy </Heading>
          </Col>
          <Col lg={12} className="d-flex flex-column align-items-end justify-content-end ">
            <div className="w-100 mb10">
              {simulatedPolicyProgress >= 0 && (
                <ProgressBar
                  variant={isSimulated ? simulatedPolicyProgressBarVariant : undefined}
                  animated
                  now={simulatedPolicyProgress}
                  label={`${simulatedPolicyProgress}%`}
                />
              )}
            </div>
            <div className="w-100">
              <Button
                className={`d-block h30 w-100 ${allowReportsSelection ? '' : 'ml20'}`}
                size="sm"
                variant={isSimulated ? 'primary' : 'success'}
                disabled={revokeReportGeneration}
                onClick={isSimulated ? () => viewPolicyOutput() : simulateHandler}
              >
                {
                  revokeReportGeneration
                    ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr10"
                      />
                    ) : null
                }
                <FontAwesomeIcon
                  size="lg"
                  className="mr10"
                  icon={faChartBar}
                />
                {/* eslint-disable-next-line no-nested-ternary */}
                {isSimulated ? 'View Output'
                  : revokeReportGeneration ? 'Simulating...' : 'Generate'}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

// display: flex;
// align-items: flex-end;
// justify-content: center;

export default SimulatePolicyComponent;
