import React, { Component } from 'react';
import SelectBox from 'devextreme-react/select-box';
import getDropDownOptions from 'components/getDropDownOptions';

class ConditionalDataLimitTypeForSPCParameterSettings extends Component<any, any> {
  renderSelectBox = (listItem: any) => {
    const { data } = this.props;
    return (
      <SelectBox
        items={listItem}
        defaultValue={data.value}
        displayExpr="caption"
        valueExpr="caption"
        dropDownButtonTemplate="dropDownButton"
        onValueChanged={this.onValueChanged}
        dropDownOptions={getDropDownOptions('cell', data)}
      />
    );
  };

  onValueChanged = (e: any) => {
    const { data, customData } = this.props;
    if (customData.callBackOnUpdate) {
      customData.callBackOnUpdate(e.value);
    }
    data.setValue(e.value);
  };

  render() {
    const { data, customData } = this.props;
    const listForParameter = [
      // { caption: 'CLM' },
      { caption: 'CTL - Static' },
      { caption: 'CTL - Dynamic' },
      // { caption: 'Raw Data Control' },
    ];
    const listForBin = [
      { caption: 'Custom' },
    ];
    if (data.data.parameterType === customData.conditions[0]) {
      return this.renderSelectBox(listForParameter);
    }
    return this.renderSelectBox(listForBin);
  }
}
export default ConditionalDataLimitTypeForSPCParameterSettings;
