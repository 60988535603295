import React from 'react';
import { Row, Col, Container, } from 'react-bootstrap';
import './rules.scss';
import ScrollView from 'devextreme-react/scroll-view';
import Button from '../../components/wrapped-component/hint-controls/Button';
import Heading from '../../components/wrapped-component/hint-controls/Heading';
import TopbarNav from '../../components/navigational-component/topbar-nav/TopbarNav';
import PageHeader from '../../components/template-component/page-header/PageHeader';
import CustomizedDataGrid from '../../components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import EditPanel from '../../components/template-component/edit-panel/EditPanel';
import SelectRuleType from '../../components/rule/select-rule-type/SelectRuleType';
import CreatePATRule from '../../components/rule/create-pat-rule/CreatePATRule';
import { httpService } from '../../services/http.service';
import { ServiceType } from '../../interfaces';
import CreateSWMRule from '../../components/rule/create-swm-rule/CreateSWMRule';

interface IRulesProps {
  className?: string;
}
interface IRulesStates {
  showCreateRule: boolean;
  editPanelRight: any;
  rulesList: Object[];
}

export class Rules extends React.Component<IRulesProps, IRulesStates> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    className: '',
  };

  constructor(props: any) {
    super(props);
    this.toggleSelectRuleType = this.toggleSelectRuleType.bind(this);
    this.clearEditPanel = this.clearEditPanel.bind(this);
    this.selectRuleType = this.selectRuleType.bind(this);
    this.getRules = this.getRules.bind(this);
    this.state = {
      showCreateRule: false,
      editPanelRight: <></>,
      rulesList: [],
    };
    this.getRules();
  }

  getRules() {
    httpService.getRules().then((rulesList: any) => this.setState({ rulesList }));
  }

  toggleSelectRuleType = () => {
    const { showCreateRule } = this.state;
    this.setState({ showCreateRule: !showCreateRule });
  };

  clearEditPanel = () => {
    this.setState({ editPanelRight: null });
    this.getRules();
  };

  getRowData = (e:any) => {
    let content = null;
    if (e.type === ServiceType.PAT) {
      content = <CreatePATRule closeRuleComponent={this.clearEditPanel} id={e.id} />;
    } else if (e.type === ServiceType.SWM) {
      content = <CreateSWMRule closeRuleComponent={this.clearEditPanel} id={e.id} />;
    }
    this.setState({
      editPanelRight: <EditPanel
        show
        position="right"
        handleClose={this.clearEditPanel}
        content={content}
      />,
    });
  };

  selectRuleType(component: any) {
    this.toggleSelectRuleType();
    this.setState({
      editPanelRight: <EditPanel
        show
        position="right"
        handleClose={this.clearEditPanel}
        content={component}
      />,
    });
  }

  render() {
    const { className } = this.props;
    const {
      editPanelRight,
      showCreateRule,
      rulesList,
    } = this.state;
    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
      >
        <Container fluid className={className}>
          <TopbarNav
            items={[
              { name: 'Active Rules', selected: true },
              { name: 'Archived Rules', selected: false },
              { name: 'Deleted Rules', selected: false },
            ]}
          />

          <PageHeader
            breadcrumbs=""
            title="Rule Engine"
            className="pb20 pl20"
            secondaryActions={(
              <Button
                variant="primary"
                className="mr20 mb20"
                onClick={this.toggleSelectRuleType}
              >
                New Rule
              </Button>
            )}
          />

          <Heading size={5} className="pb20 pl20">List of Rules</Heading>
          <Row className="pl20 pr20">
            <Col>
              <CustomizedDataGrid
                showFilterRow
                selectionMode="multiple"
                fields={[
                  {
                    caption: 'Name',
                    dataField: 'name',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Type',
                    dataField: 'type',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Description',
                    dataField: 'description',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Short Description',
                    dataField: 'shortDescription',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Version',
                    dataField: 'version',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Created By',
                    dataField: 'createdBy.firstName',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Created On',
                    dataField: 'createdOn',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Updated By',
                    dataField: 'updatedBy.firstName',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Updated On',
                    dataField: 'updatedOn',
                    dataType: 'string',
                    showInfo: false,
                  },
                ]}
                moreOptions={[
                  {
                    title: 'Edit',
                    clickAction: this.getRowData,
                  },
                ]}
                // key="id"
                tableData={rulesList}
              />
            </Col>
          </Row>

          {editPanelRight}

          <SelectRuleType
            show={showCreateRule}
            hideCreateRule={this.toggleSelectRuleType}
            selectRule={this.selectRuleType}
            closeRuleComponent={this.clearEditPanel}
          />

        </Container>
      </ScrollView>
    );
  }
}
