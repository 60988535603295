import React from 'react';
import './OverlayGraphLegend.css';

export default (props: { graphCaption: string }) => {
  const { graphCaption } = props;
  return (
    <>
      <strong style={{ fontWeight: 'bolder' }}>
        {graphCaption}
        {' '}
        Percentage Yield
      </strong>
      <br />
      <table style={{ width: '75%', fontFamily: 'monospace' }}>
        <tr>
          <td>
            0
          </td>
          <td>
            10
          </td>
          <td>
            20
          </td>
          <td>
            30
          </td>
          <td>
            40
          </td>
          <td>
            50
          </td>
          <td>
            60
          </td>
          <td>
            70
          </td>
          <td>
            80
          </td>
          <td>
            90
          </td>
          <td width="1px">
            100
          </td>
        </tr>
      </table>
      <div className="percentage-color-gradient" />
    </>
  );
};
