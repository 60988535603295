import React from 'react';

import './edit-panel.scss';
import '../../../styles/main.scss';
import ScrollView from 'devextreme-react/scroll-view';

interface IEditPanelProps {
  show?: boolean;
  disableScrollView?: boolean;
  handleClose?: () => void;
  position?: 'right';
  content?: any;
  size?: '40' | '60' | '70' | '80' | '90';
  className?: string;
}

const EditPanel = (props: IEditPanelProps) => {
  const {
    show, handleClose, position, content, size, className, disableScrollView,
  } = props;

  let sizeStyles;
  if (position === 'right') {
    sizeStyles = { width: `${size}%` };
  } else {
    sizeStyles = { height: `${size}%` };
  }
  let childContent = content;
  if (!disableScrollView) {
    childContent = (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
      >
        {content}
      </ScrollView>
    );
  }
  return (
    <div className={`${show === false ? 'd-none' : ''}`}>
      <button type="button" className="edit-panel" onClick={handleClose} />
      <div
        style={sizeStyles}
        className={`${className} edit-panel-body-${position}`}
      >
        {childContent}
      </div>
    </div>
  );
};

EditPanel.defaultProps = {
  show: false,
  handleClose: () => undefined,
  position: 'right',
  content: <></>,
  size: '70',
  className: '',
  disableScrollView: false,
};

export default EditPanel;
