/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpLegend } from 'services/http.legend';
import { InteractionInputDTO, ICustomizedLegendData } from '../CustomizedReportInterfaces';
import { ILegendDataHelper } from '../CustomizedReportHelper';

export class ParametricFailureLegendHelper implements ILegendDataHelper {
  private legendData : any = undefined;

  preferences = {
    summary: {
      calculateCustomSummary: (options:any) => {},
      totalItems: [],
    },
    legendKeyExpression: 'test_parameter_name',
    selectionEventDefaults: {
      xAxisLabel: 'BIN_NUMBER',
      yAxisLabel: 'DIE_COUNT',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      groupKey: 'wafer_key',
      entityType: ('WAFER' as EntityType),
      columnName: 'key',
    },
    postSelectionInYAxis: false,
    equalNumberOfYsForX: false,
  };

  setLegendData = (data: ICustomizedLegendData) => {
    this.legendData = data;
  };

  getPreferences = () => (this.preferences);

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpLegend.setInteractionData(selectionEventInput);
  };

  getDetailedGraphLegendData = async (requestObject: IGenericDetailedReportRequestObject, callback: (legendData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    data.legend.forEach((legendData:any) => {
      legendData.forEach((legendRow:any) => {
        legendRow.hidden = false;
      });
    });
    callback(data);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };
}
