/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import { IFieldValue } from 'components/utility-component/report-headers/ReportHeaderBuilder';
import {
  Button, Col, Container, Modal, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import toast from 'CustomToast';
import _ from 'lodash';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import {
  ICombinedGraphDataWithReportHeaders, InteractionInputDTO, ParametricFailureReportOptions, PFRTrend, PFRType,
} from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import { ILineDict } from '../action-items-sheet/ActionItemsSheet';
import ReportHorizontalScrollView from '../../customized-report-utils/ReportHorizontalScrollView';

export class ParametricFailureGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: true,
    reportType: 'BIN_BASED' as REPORT_TYPE,
    graphType: ('bar' as IGraphType),
    graphMode: ('markers' as IGraphMode),
    markerOpacity: 1,
    markerBorderOpacity: 1,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: true,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: false,
    showXAxisBreaks: false,
    allowDefaultGrouping: false,
    extractSelectedValuesFromToolTips: true,
    showAddedMarkers: false,
    textOnHover: [['placeholder']],
    hoverTemplate: {
      grouping: '%{y} %',
      noGrouping: '%{y} %',
    },
    showAddedLines: {
      grouping: {
        show: true,
        name: 'Cumulative %',
      },
      noGrouping: {
        show: true,
        name: 'Cumulative %',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'CLASS_NAME',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'wafer_id',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'Test Parameter Names',
        yAxisName: 'Failure Contribution (%)',
        secondaryYAxisName: 'Failure Percentage (%)',
      },
      noGrouping: {
        xAxisName: 'Test Parameter Names',
        yAxisName: 'Failure Contribution (%)',
        secondaryYAxisName: 'Failure Percentage (%)',
      },
    },
    showTickText: {
      grouping: {
        xAxis: true,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: true,
  };

  getPreferences = () => {
    return this.preferences;
  };

  onChangePFROption = (oldParametricFailureReportOptions: ParametricFailureReportOptions, optionName: string, value: any, setStateCallback: any, _classMembers: any, reportSessionId: string) => {
    httpReport.clearParametricFailureReportCache(reportSessionId).then(() => {
      const newParametricFailureReportOptions = _.cloneDeep(oldParametricFailureReportOptions);
      newParametricFailureReportOptions[optionName] = value;
      _classMembers.onChangeReportConfigValues([{ key: 'parametricFailureReportOptions', value: newParametricFailureReportOptions }]);
      toast.success('Report options updated');
      setStateCallback({ parametricFailureReportOptions: newParametricFailureReportOptions });
    });
  };

  getPrimaryComponent = (props : ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    return (
      <>
        <div className="mr10 rounded">
          <Button variant="success" onClick={() => setStateCallback({ showModal: true })}>
            <FontAwesomeIcon
              className="color-dark mr6"
              icon={faEllipsisH}
              size="1x"
            />
            Report Options
          </Button>
        </div>
        <Modal dialogClassName="modal-90w" show={state.showModal} size="xl" scrollable>
          <Modal.Header>
            <Modal.Title as="h3">Report Options Chooser</Modal.Title>
            <span className="float-right">
              <Button variant="secondary" onClick={() => setStateCallback({ showModal: false })}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faTimes}
                />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="w-100 h-100">
            <Container fluid className="border-all pl10 pr10 pt10 pb10 background-color-light border-radius-4" style={{ overflow: 'visible' }}>
              <Row className="d-flex mb60">
                <Col><h6 className="ml5 mt7 mr10">Contribution Option</h6></Col>
                <Col>
                  <div className="w100">
                    <CustomizedDropdown
                      variant="light"
                      full
                      list={[
                        ['FAILURE', 'Failure Count'],
                        ['ALARM', 'Alarm Count'],
                      ]}
                      dropdownButtonClass="border-all"
                      selectedValue={state.parametricFailureReportOptions.type}
                      onChange={(value: string) => {
                        this.onChangePFROption(state.parametricFailureReportOptions, 'type', value.toString() as PFRType, setStateCallback, _classMembers, props.reportSessionId);
                      }}
                    />
                  </div>
                </Col>
                <Col />
                <Col />
              </Row>
              <Row className="d-flex mb60">
                <Col><h6 className="ml5 mt7 mr10">Display Test Properties</h6></Col>
                <Col>
                  <div className="w100">
                    <CustomizedDropdown
                      variant="light"
                      full
                      list={[
                        ['TOP', 'Top Most'],
                        ['LEAST', 'Least'],
                      ]}
                      dropdownButtonClass="border-all"
                      selectedValue={state.parametricFailureReportOptions.trend}
                      onChange={(value: string) => {
                        this.onChangePFROption(state.parametricFailureReportOptions, 'trend', value.toString() as PFRTrend, setStateCallback, _classMembers, props.reportSessionId);
                      }}
                    />
                  </div>
                </Col>
                <Col />
                <Col />
              </Row>
              <Row className="d-flex mb60">
                <Col><h6 className="ml5 mt7 mr10">Count</h6></Col>
                <Col>
                  <div className="w100 custom-form">
                    <Textbox
                      name="pageNumber"
                      placeholder="Page Number"
                      type="number"
                      defaultValue={state.parametricFailureReportOptions.count.toString()}
                      setRef={(ref: any) => { _classMembers.pfrTPCountInput = ref; }}
                      onChange={() => {
                        if (_classMembers.pfrTPCountInputTimeout !== null) clearTimeout(_classMembers.pfrTPCountInputTimeout);
                        _classMembers.pfrTPCountInputTimeout = setTimeout(() => {
                          if (+_classMembers.pfrTPCountInput.value >= 0) {
                            this.onChangePFROption(state.parametricFailureReportOptions, 'count', +_classMembers.pfrTPCountInput.value, setStateCallback, _classMembers, props.reportSessionId);
                          }
                          if (_classMembers.pfrTPCountInputTimeout !== null) clearTimeout(_classMembers.pfrTPCountInputTimeout);
                        }, 1000);
                      }}
                    />
                  </div>
                </Col>
                <Col />
                <Col />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props:ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    return [];
  };

  generateGraphComponent = (props: any, state: ICustomizedReportGraphState, classMembers: any, setStateCallback: any, summaryStatLines: IFieldValue[], lineDict:ILineDict) => {
    return (
      <ReportHorizontalScrollView
        reportGraphProps={props}
        reportGraphState={state}
        classMembers={classMembers}
        setStateCallback={setStateCallback}
        preferences={this.preferences}
      />
    );
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    }).catch((err: any) => {
      const errorDetails: any = err.message;
      const graphingUtil = new GraphingUtils();
      callback(graphingUtil.generateDataObjectFromError(errorDetails));
    });
  };
}
