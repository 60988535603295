/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { PATRule } from 'components/policy/policy-step/PAT/PATInterfaces';
import Label from '../../../../../../../wrapped-component/hint-controls/Label';
import CustomizedDropdown from '../../../../../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import RecalculateLimits from '../../../../pat-components/RecalculateLimits';
import { RULE_TYPES, SITE_OPTIONS } from '../../PATCreateRuleGrid';

interface IPATRuleSettingsProps {
  currentEditRule: PATRule;
  onPATRuleSettingsChangeHandler: (newValues: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPATRuleSettingsState {
}

class PATRuleSettings extends Component<IPATRuleSettingsProps, IPATRuleSettingsState> {
  render() {
    const {
      currentEditRule,
      onPATRuleSettingsChangeHandler,
    } = this.props;
    return (
      // <div className="custom-form background-color-light rounded p10 border-all d-flex align-items-center mt20 mb20">
      <div className=" custom-form">
        <div className="mr20">
          <Label
            className="mb20"
            labelTitle="Rule Type"
            labelPosition="left-middle"
            labelSize="30"
            fieldSize="70"
            errorSize="0"
          >
            <CustomizedDropdown
              full
              selectedValue={currentEditRule.patRuleType}
              variant="clear"
              list={RULE_TYPES}
              onChange={(value: any) => onPATRuleSettingsChangeHandler({ patRuleType: value })}
            />
          </Label>
        </div>
        <div className="mr20">
          <Label
            className="mb20"
            labelTitle="Rule Site Option"
            labelPosition="left-middle"
            labelSize="30"
            fieldSize="70"
            errorSize="0"
          >
            <CustomizedDropdown
              full
              selectedValue={currentEditRule.siteOption}
              variant="clear"
              list={SITE_OPTIONS}
              onChange={(value: any) => onPATRuleSettingsChangeHandler({ siteOption: value })}
            />
          </Label>
        </div>
        <div className="mr20">
          {
            currentEditRule.patRuleType === 'STATIC_PAT'
              ? (
                <RecalculateLimits
                  title="Recalculate PAT Limits"
                  seedLimitObj={currentEditRule.seedLimit}
                  showHeading={false}
                  getSeedLimitData={(value: any) => onPATRuleSettingsChangeHandler({ seedLimit: value })}
                />
              )
              : null
          }
        </div>
      </div>
    );
  }
}

export default PATRuleSettings;
