/* eslint-disable no-plusplus */
// import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Popup, ScrollView } from 'devextreme-react';
import Form, { Item } from 'devextreme-react/form';
import {
  Row, Col, Button, Container,
} from 'react-bootstrap';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import React from 'react';
import { httpService } from 'services/http.service';
import { toast } from 'react-toastify';

interface ScreenTypesMappingPopupFormProps {
  visible: boolean;
  setIsShowpopupSettings: (e: any) => void;
}

interface ScreenTypesPopupFormState {
  screenTypes: IScreenType[];
  visibleState?: boolean;
  popupVisible: boolean;
  positionOf: any;
}

interface IScreenType {
  [key: string]: any;
  name: string;
  retestCodes: string[];
  sequence: number;
  id? : string;
  createdOn?: Date;
  updatedOn?: Date;
}

class ScreenTypesMappingPopup extends React.Component<
ScreenTypesMappingPopupFormProps,
ScreenTypesPopupFormState
> {
  private screenTypesList: IScreenType[] = [];

  constructor(props: any) {
    super(props);
  }

  static getDerivedStateFromProps(props: any) {
    return {
      visibleState: props.visible,
    };
  }

  componentDidMount() {
    httpService.getScreenTypes().then((screens: IScreenType[]) => {
      this.screenTypesList = screens;
      this.setState({ screenTypes: screens });
    });
  }

  onSavePopupFormHandler = () => {
    let dontSave = false;
    const sequenceMap = new Map();
    const nameMap = new Map();
    const retestCodesMap = new Map();

    for (let i = 0; i < this.screenTypesList.length; i += 1) {
      if (this.screenTypesList[i].sequence.toString() === '') {
        dontSave = true;
        toast.warning('Empty sequence number isn\'t allowed. Please specify a screen sequence against screen type.');
        break;
      }
      if (this.screenTypesList[i].name === '') {
        dontSave = true;
        toast.warning('Empty screen name isn\'t allowed. Please specify a screen name against screen type.');
        break;
      }
      if (this.screenTypesList[i].retestCodes.join(',').toString() === '') {
        dontSave = true;
        toast.warning('Empty retest code isn\'t allowed. Please specify some retest code against screen type');
        break;
      }

      for (let j = 0; j < this.screenTypesList[i].retestCodes.length; j += 1) {
        const rc = this.screenTypesList[i].retestCodes[j];

        if (retestCodesMap.has(rc.trim().toLowerCase())) {
          dontSave = true;
          const warnMessage = `Duplicate retest code '${
            rc
          }' is found. Retest codes should be unique for screen types.`;
          toast.warning(warnMessage);
          break;
        }
        retestCodesMap.set(rc.trim().toLowerCase(), true);
      }
      if (dontSave) {
        break;
      }

      if (sequenceMap.has(Number(this.screenTypesList[i].sequence))) {
        dontSave = true;
        const warnMessage = `Duplicate sequence number '${
          this.screenTypesList[i].sequence
        }' is found. Sequence numbers should be unique for screen types.`;
        toast.warning(warnMessage);
        break;
      } else if (
        nameMap.has(this.screenTypesList[i].name.trim().toLowerCase())
      ) {
        dontSave = true;
        const warnMessage = `Duplicate name '${
          this.screenTypesList[i].name
        }' is found. Screen names should be unique for screen types.`;
        toast.warning(warnMessage);
        break;
      }
      sequenceMap.set(Number(this.screenTypesList[i].sequence), true);
      nameMap.set(this.screenTypesList[i].name.trim().toLowerCase(), true);
    }

    if (!dontSave) {
      httpService.updateScreenTypes(this.screenTypesList).then(() => {
        toast.success('Screen types updated!');
        this.setState({ screenTypes: this.screenTypesList });
      }).finally(() => {
        this.componentDidMount();
      });
    }
  };

  onCancelPopupFormHandler = () => {
    this.setState({ visibleState: false });
    this.props.setIsShowpopupSettings(false);
  };

  deleteScreenType(screens: IScreenType[], index: any) {
    screens.splice(index, 1);
    this.setState({ screenTypes: screens });
    return screens;
  }

  renderScreenTypeRows = (screens: IScreenType[]) => {
    return screens.map((ascreen: IScreenType, index: number) => (
      <>
        <Col lg={3} className="mb20">
          <Textbox
            autoComplete="off"
            name="screenname"
            placeholder="Screen Name"
            value={ascreen.name}
            onChange={(event: any) => {
              screens[index].name = event.target.value;
              this.setState({ screenTypes: screens });
            }}
          />
        </Col>
        <Col lg={5} className="mb20">
          <Textbox
            autoComplete="off"
            name="retestcode"
            placeholder="Retest Codes"
            value={ascreen.retestCodes.join(',')}
            onChange={(event: any) => {
              screens[index].retestCodes = event.target.value.split(',');
              this.setState({ screenTypes: screens });
            }}
          />
        </Col>
        <Col lg={3} className="mb20">
          <Textbox
            autoComplete="off"
            name="sequence"
            placeholder="Sequence"
            type="number"
            value={ascreen.sequence}
            onChange={(event: any) => {
              screens[index].sequence = event.target.value;
              this.setState({ screenTypes: screens });
            }}
          />
        </Col>
        <Col lg={1} className="mb20">
          <Button
            className="w40 btn-outline-danger"
            variant="outline-primary"
            title="Delete parameter"
            onClick={() => this.deleteScreenType(screens, index)}
          >
            <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
          </Button>
        </Col>
      </>
    ));
  };

  render() {
    const { visibleState } = this.state;

    return (
      <Popup
        className="d-flex"
        showTitle={false}
        closeOnOutsideClick={false}
        onHiding={this.onCancelPopupFormHandler}
        visible={visibleState}
        width="90%"
        height="80%"
        dragEnabled={false}
        toolbarItems={[
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: {
              text: 'Save',
              onClick: this.onSavePopupFormHandler,
            },
          },
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              onClick: this.onCancelPopupFormHandler,
            },
          },
        ]}
      >
        <ScrollView
          showScrollbar="always"
          direction="vertical"
          reachBottomText=""
          scrollByThumb
        >
          <Form>
            <Item
              cssClass="mt20 mb20"
              itemType="group"
              caption="Screen Types Mapping"
              colCount={2}
              colSpan={2}
            >
              <Button
                variant="outline-primary"
                className="align-items-right"
                onClick={() => {
                  this.screenTypesList.unshift({
                    name: 'Screen Name ',
                    retestCodes: ['code1', 'code2', 'code3'],
                    sequence: 0,
                  });
                  this.setState(
                    { screenTypes: this.screenTypesList },
                    this.forceUpdate,
                  );
                }}
              >
                <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
              </Button>
              <Container fluid style={{ overflow: 'visible' }}>
                <Row>
                  <Col className="custom-form mt20" lg={12}>
                    <Row className="background-color-secondary-background rounded border-all m2 pt20 pb20 row">
                      {/* Adding heading row */}
                      <>
                        <Col lg={3}>
                          <Heading className="mb20" size={5}>
                            Screen
                          </Heading>
                        </Col>
                        <Col lg={5}>
                          <Heading className="mb20" size={5}>
                            Retest Codes
                          </Heading>
                        </Col>
                        <Col lg={3}>
                          <Heading className="mb20" size={5}>
                            Sequence
                          </Heading>
                        </Col>
                        <Col lg={1} />
                      </>
                      {this.renderScreenTypeRows(this.screenTypesList)}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Item>
          </Form>
        </ScrollView>
      </Popup>
    );
  }
}

export default ScreenTypesMappingPopup;
