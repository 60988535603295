import React from 'react';
import Textbox from '../hint-controls/Textbox';

class CustomizedTextBox extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.data.value,
    };
  }

  onUpdateHandler = (event: any) => {
    const {
      data,
      customData
    } = this.props;
    // if (event.target.value === '') {
    //   return;
    // }
    const val = customData.type === 'number' ? parseFloat(event.target.value) : event.target.value;
    data.setValue(val);
    this.setState({ value: val });
    if (customData.callBackOnUpdate) {
      customData.callBackOnUpdate(val);
    }
  };

  render() {
    const { value } = this.state;
    const {
      data,
      customData
    } = this.props;
    return (
    // <div className="dx-field">
      <Textbox
        // title={data.column.caption}
        className={customData.className}
        name="Input"
        type={customData.type || 'string'}
        placeholder=""
        defaultValue={value}
        onBlur={this.onUpdateHandler}
        disabled={customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false}
        autoComplete="off"

        // required={customData.requiredWhen ? customData.requiredWhen.values.includes(data.data[customData.requiredWhen.inColumn]) : false}
      />
    // </div>
    );
  }
}

export default CustomizedTextBox;
