/* eslint-disable react/prefer-stateless-function */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'devextreme-react';
import React from 'react';
import { Button, Modal, } from 'react-bootstrap';
import { IReportsTypesWithPositions } from '../CustomizedReportsCollection';

export interface IReportFinderModalProps {
  showReportsToFindModal: boolean,
  currentReportTypesToShow: IReportsTypesWithPositions[],
  forceScrollToReportIndex: (reportIndex: number) => void;
  updateParentState: (updatedState: any) => void;
}

class ReportFinderModal extends React.Component<IReportFinderModalProps> {
  render() {
    const {
      showReportsToFindModal, currentReportTypesToShow, forceScrollToReportIndex, updateParentState,
    } = this.props;

    return (
      <Modal
        show={showReportsToFindModal}
        size="xl"
        scrollable
      >
        <Modal.Header>
          <Modal.Title as="h3">Scroll to Specified Report</Modal.Title>
          <span className="float-right">
            <Button variant="secondary" onClick={() => updateParentState({ showReportsToFindModal: false })}>
              <FontAwesomeIcon
                size="lg"
                icon={faTimes}
              />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="w-100 h-100">
          {currentReportTypesToShow.length > 0 ? (
            <List
              dataSource={currentReportTypesToShow}
              onItemClick={(e: any) => {
                forceScrollToReportIndex(e.itemData.reportPosition);
              }}
              selectionMode="single"
              itemRender={(e: IReportsTypesWithPositions) => (
                <div>
                  {e.reportType.replaceAll('_', ' ')}
                </div>
              )}
              searchMode="contains"
              searchEnabled
              searchExpr="name"
            />
          ) : <div>No reports generated yet</div>}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ReportFinderModal;
