// eslint-disable-next-line no-unused-vars
import { WaferMapTestData } from './Types';

export class RandomNumberUtility {
  colorCodes: { [index: number]: string } = {
    0: '#C004D9',
    1: '#AB05F2',
    2: '#5A13F2',
    3: '#2745F2',
    4: '#138AF2',
    5: '#220B5E',
    6: '#7654B4',
    7: '#3B1D98',
    8: '#967ECB',
    9: '#513779',
  };

  checkPoint = (h: number, k: number, a: number, b: number, x: number, y: number) => {
    return (x - h) ** 2 / a ** 2 + (y - k) ** 2 / b ** 2;
  };

  // eslint-disable-next-line no-unused-vars
  randomWaferDataWithBinNum = (h: number, k: number, a: number, b: number, bins: any, noColor = false) => {
    const data: WaferMapTestData = [];
    const xinit = h - a + 1;
    for (let x = h - a + 1; x < h + a; x += 1) {
      data.push([]);
      for (let y = k - b + 1; y < k + b; y += 1) {
        const xIndex = x - xinit;
        if (this.checkPoint(h, k, a, b, x, y) < 1.0) {
          // Lets Generate a 100 x 4 params. Yaaay!
          data[xIndex].push({
            softBin: Math.floor(Math.random() * 999),
          });
          const yIndex = data[xIndex].length - 1;
          data[xIndex][yIndex]!.T0Data = Math.random() * 3 - 1;
          data[xIndex][yIndex]!.T0MinLimit = Math.random() - 1;
          data[xIndex][yIndex]!.T0MaxLimit = Math.random() + 1;
          data[xIndex][yIndex]!.T1Data = Math.random() * 3 - 1;
          data[xIndex][yIndex]!.T1MinLimit = Math.random() - 1;
          data[xIndex][yIndex]!.T1MaxLimit = Math.random() + 1;
          data[xIndex][yIndex]!.dieType = '';
          if (noColor) {
            data[xIndex][yIndex]!.binColor = '#666666';
          } else {
            data[xIndex][yIndex]!.binColor = this.colorCodes[
              data[xIndex][yIndex]!.softBin! % Object.keys(this.colorCodes).length
            ];
          }
          data[xIndex][yIndex]!.image = `https://picsum.photos/id/${
            data[xIndex][yIndex]!.softBin
          }/200/300`;
        } else {
          data[xIndex].push(null);
        }
      }
    }
    return {
      data,
      dieSubView: [
        [
          [
            {
              x1: 0.1, y1: 0.1, x2: 0.3, y2: 0.4,
            },
            {
              x1: 0.4, y1: 0.5, x2: 0.6, y2: 0.65,
            },
            {
              x1: 0.7, y1: 0.7, x2: 0.9, y2: 0.9,
            },
          ],
          [
            {
              x1: 0.1, y1: 0.1, x2: 0.9, y2: 0.2,
            },
            {
              x1: 0.1, y1: 0.3, x2: 0.9, y2: 0.5,
            },
            {
              x1: 0.1, y1: 0.6, x2: 0.9, y2: 0.9,
            },
          ],
          [
            {
              x1: 0.1, y1: 0.1, x2: 0.9, y2: 0.4,
            },
            {
              x1: 0.1, y1: 0.5, x2: 0.4, y2: 0.9,
            },
            {
              x1: 0.6, y1: 0.5, x2: 0.9, y2: 0.9,
            },
          ],
        ],
        [
          [
            {
              y1: 0.1, x1: 0.1, y2: 0.3, x2: 0.4,
            },
            {
              y1: 0.4, x1: 0.5, y2: 0.6, x2: 0.65,
            },
            {
              y1: 0.7, x1: 0.7, y2: 0.9, x2: 0.9,
            },
          ],
          [
            {
              y1: 0.1, x1: 0.1, y2: 0.9, x2: 0.2,
            },
            {
              y1: 0.1, x1: 0.3, y2: 0.9, x2: 0.5,
            },
            {
              y1: 0.1, x1: 0.6, y2: 0.9, x2: 0.9,
            },
          ],
          [
            {
              y1: 0.1, x1: 0.1, y2: 0.9, x2: 0.4,
            },
            {
              y1: 0.1, x1: 0.5, y2: 0.4, x2: 0.9,
            },
            {
              y1: 0.6, x1: 0.5, y2: 0.9, x2: 0.9,
            },
          ],
        ],
      ],
    };
  };
}
