import Dexie, { Table } from 'dexie';

export class RedoUndoDB extends Dexie {
  waferStates!: Table<any>;

  constructor() {
    super('redo-undo-db');
    this.version(1).stores({
      waferStates: 'id' // Primary key and indexed props
    });
  }
}

export const db = new RedoUndoDB();
