/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from 'react';
import { EntityType, OperationType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpReport } from 'services/http.report';
import toast from 'CustomToast';
import { ILimitSettingsObj } from 'components/wrapped-component/limit-settings-modal/limitSettingsModal';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { InteractionInputDTO, ICombinedGraphData, ICombinedGraphDataWithReportHeaders, } from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import { IFieldValue } from '../../../../../components/utility-component/report-headers/ReportHeaderBuilder';
import { ILineDict } from '../action-items-sheet/ActionItemsSheet';
import '../../customized-reports.scss';
import TestParameterBadges from '../../customized-report-utils/TestParameterBadges';
import ReportOptionsModal from '../../customized-report-utils/ReportOptionsModal';
import UtilityOptions from '../../customized-report-utils/UtilityOptions';
import ReportHorizontalScrollView from '../../customized-report-utils/ReportHorizontalScrollView';

export class ParametricBoxPlotGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: false,
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    graphType: ('box' as IGraphType),
    graphMode: ('lines+markers' as IGraphMode),
    markerOpacity: 0.9,
    markerBorderOpacity: 0,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: false,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: true,
    showXAxisBreaks: false,
    allowDefaultGrouping: true,
    extractSelectedValuesFromToolTips: false,
    showAddedMarkers: true,
    textOnHover: [['placeholder']],
    hoverTemplate: {
      grouping: '%{text}',
      noGrouping: '%{text}',
    },
    showAddedLines: {
      grouping: {
        show: true,
        name: 'Median Connector',
      },
      noGrouping: {
        show: false,
        name: '',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'NONE',
      yAxisLabel: 'Test Parameter Values',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'lot_id',
      entityType: ('LOT' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'NONE',
        yAxisName: 'Test Parameter Values',
      },
      noGrouping: {
        xAxisName: 'Dies and Parametric Box Plot',
        yAxisName: 'Test Parameter Values',
      },
    },
    showTickText: {
      grouping: {
        xAxis: false,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    showDies: true,
    showAnnotations: true,
    useCaptionAsGroupValueWhenGroupingPresent: false,
  };

  getPreferences = () => {
    return this.preferences;
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props:ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });
    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied, undefined, updateParentState),
    });

    return tabs;
  };

  applyLimitSettings = (limitSettingsObject: any, testParameterSelectedIndexUpdated: number, testParameterIndexForWhichLimitsApplied: number, setStateCallback: any, errors: any) => {
    setStateCallback({ limitSettingsObj: limitSettingsObject, testParameterSelectedIndex: testParameterSelectedIndexUpdated, testParameterIndexForWhichLimitsApplied}, errors);
    toast.success('Limit settings applied');
  };

  getPrimaryComponent = (props : ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    const {
      groupingSortingListStore, selectionStore, report, testParameterIndex,
    } = props;
    const {
      limitSettingsObj, showDies, showAnnotations, showDiesShowingOption,
    } = state;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    const testParameterValues: any[] = [];
    testParameters.forEach((testParameter: any) => {
      testParameterValues.push(testParameter.values);
    });
    if (!showDiesShowingOption && showDies) {
      setStateCallback({ showDies: false });
    }
    return (
      <>
        <UtilityOptions
          limitSettingsObj={limitSettingsObj}
          showDies={showDies}
          showAnnotations={showAnnotations}
          showLimitLinesOptions
          showDiesShowingOption={showDiesShowingOption}
          showAnnotationsShowingOption
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
        />
        <ReportOptionsModal
          reportActor={report.actor}
          groupingSortingListStore={groupingSortingListStore}
          selectionStore={selectionStore}
          testParameterValues={testParameterValues}
          testParameterIndex={testParameterIndex}
          parentState={state}
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
          applyLimitSettings={(limitSettingsObject: ILimitSettingsObj, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => {
            this.applyLimitSettings(limitSettingsObject, testParameterSelectedIndex, testParameterIndexForWhichLimitsApplied, setStateCallback, errors);
          }}
          requestObject={requestObject}
        />
      </>
    );
  };

  getSecondaryComponent = (props:any) => {
    const testParameterNames: any[] = [];
    const testParameters = props.selectionStore.selections.filter((x:any) => x.entityType === 'Testparameter');
    if (testParameters.length > 0 && testParameters[0].values.length > 0) {
      testParameterNames.push(testParameters[0].values[0].name);
      return (
        <TestParameterBadges testParameterNames={testParameterNames} />
      );
    }
    return <></>;
  };

  generateGraphComponent = (props:ICustomizedReportGraphProps, state:ICustomizedReportGraphState, classMembers:any, setStateCallback: any, summaryStatLines: IFieldValue[], lineDict:ILineDict) => {
    const { graphData } = state;
    this.updatePreferences(props, (graphData as ICombinedGraphData));
    return (
      <ReportHorizontalScrollView
        reportGraphProps={props}
        reportGraphState={state}
        classMembers={classMembers}
        setStateCallback={setStateCallback}
        preferences={this.preferences}
        summaryStatLines={summaryStatLines}
        lineDict={lineDict}
      />
    );
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject:IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    }).catch((err:any) => {
      const errorDetails : any = err.message;
      const graphingUtil = new GraphingUtils();
      callback(graphingUtil.generateDataObjectFromError(errorDetails));
    });
  };

  updatePreferences = (props: ICustomizedReportGraphProps, data: ICombinedGraphData) => {
    const { showDies, showAnnotations } = props;
    if (data.graphs.length > 0 && data.graphs[0].reportSpecificAttributes.HideDies[0] === true) {
      this.preferences.showDies = false;
    } else {
      this.preferences.showDies = showDies;
    }
    this.preferences.showAnnotations = showAnnotations;
    this.preferences.axisNames.noGrouping.xAxisName = showDies ? 'Dies and Parametric Box Plot' : 'Parametric Box Plot';
    this.preferences.textOnHover = [];
    data.graphs.forEach((graph: any) => {
      this.preferences.textOnHover.push(graph.y);
    });
  };
}
