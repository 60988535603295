/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { ContextMenu } from 'devextreme-react';
import Heading from '../../wrapped-component/hint-controls/Heading';
import Button from '../../wrapped-component/hint-controls/Button';
import WidgetPopup from '../../policy/policy-output/WidgetPopup';
import './widget-card.scss';
import { Spinner } from 'react-bootstrap';

interface WidgetCardProps {
  show?: boolean;
  showSpinner?: boolean;
  id: string;
  instanceName?: string;
  closeToggle?: boolean;
  pinToggle?: boolean;
  pinToggleCallback?: (isPinned: boolean) => void;
  pinWidgetByDefault?: boolean;
  expandToggle?: boolean;
  linkToggle?: boolean;
  widgetName?: string;
  closeHandler?: (id: string) => void;
  linkHandler?: (id: string) => void;
  unlinkHandler?: (id: string) => void;
  primaryWidget?: JSX.Element;
  secondaryWidget?: JSX.Element;
  tertiaryWidget?: JSX.Element;
  primaryWidgetWeight?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  secondaryWidgetWeight?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  fullWidth?: boolean;
  alternateFullWidthClass?: string;
  changeExpandedStateComponentOnUpdate?: boolean;
  hidePrimaryWidgetOnExpand?: boolean;
  hideSecondaryWidgetOnExpand?: boolean;
  contextMenuItems?: any | undefined;
  highlightColor?: string | undefined;
  widgetClassName?: string;
  pinClassName?: string
  cardPaddingClass?: string;
  showBorder?: boolean;
  updatePrimaryWidgetHeightAndWidth?: (newHeight: string, newWidth?: number) => void;
  changeModalClass?: boolean;
}

class WidgetCard extends Component<WidgetCardProps, any> {
  static defaultProps = {
    show: undefined,
    showSpinner: false,
    closeToggle: false,
    pinToggle: false,
    pinWidgetByDefault: false,
    expandToggle: false,
    linkToggle: false,
    widgetName: '',
    primaryWidget: <></>,
    tertiaryWidget: undefined,
    secondaryWidget: undefined,
    primaryWidgetWeight: 6,
    secondaryWidgetWeight: 6,
    fullWidth: false,
    alternateFullWidthClass: '',
    changeExpandedStateComponentOnUpdate: true,
    cardPaddingClass: 'p10',
    showBorder: true,
  };

  private containerDiv: any = undefined;

  private RAW_DATA_GRID_WIDGET = 'Raw Data Grid';

  private DEFAULT_POSITION_RDG_WIDGET = {
    x: -100,
    y: 450
  };

  private DEFAULT_POSITION_OTHER_WIDGETS = {
    x: 0,
    y: 0
  };

  constructor(props: WidgetCardProps) {
    super(props);
    const {
      widgetName,
      pinWidgetByDefault,
    } = this.props;
    const {
      RAW_DATA_GRID_WIDGET,
      DEFAULT_POSITION_OTHER_WIDGETS,
      DEFAULT_POSITION_RDG_WIDGET
    } = this;
    this.containerDiv = React.createRef();
    this.state = {
      showPrimaryWidget: true,
      expandWidget: false,
      widgetName: widgetName || '',
      pinWidget: pinWidgetByDefault,
      linkWidget: false,
      wasPinned: false,
      defaultPosition: widgetName === RAW_DATA_GRID_WIDGET ? DEFAULT_POSITION_RDG_WIDGET : DEFAULT_POSITION_OTHER_WIDGETS,
    };
  }

  componentDidUpdate(prevProps: Readonly<WidgetCardProps>) {
    const {
      linkWidget,
      expandWidget
    } = this.state;
    if (this.props !== prevProps) {
      const {
        widgetName,
        changeExpandedStateComponentOnUpdate,
      } = this.props;
      this.setState({
        showPrimaryWidget: true,
        expandWidget: changeExpandedStateComponentOnUpdate ? false : expandWidget,
        widgetName: widgetName || '',
        linkWidget,
      });
    }
  }

  showSecondaryWidget = () => {
    const { showPrimaryWidget } = this.state;
    this.setState({ showPrimaryWidget: !showPrimaryWidget });
  };

  callPinToggleBack = () => {
    const { pinToggleCallback } = this.props;
    const { pinWidget } = this.state;
    if (pinToggleCallback) {
      pinToggleCallback(!pinWidget);
    }
  };

  pinWidgetHandler = () => {
    const { widgetName } = this.props;
    const { pinWidget } = this.state;
    const {
      RAW_DATA_GRID_WIDGET,
      DEFAULT_POSITION_OTHER_WIDGETS,
      DEFAULT_POSITION_RDG_WIDGET,
      callPinToggleBack,
    } = this;
    const newDefaultPosition = (!pinWidget && widgetName === RAW_DATA_GRID_WIDGET) ? DEFAULT_POSITION_RDG_WIDGET : DEFAULT_POSITION_OTHER_WIDGETS;
    this.setState({
      pinWidget: !pinWidget,
      defaultPosition: newDefaultPosition
    }, () => {
      callPinToggleBack();
    });
  };

  updatePrimaryWidgetHeightAndWidth = () => {
    const {
      widgetName,
      expandWidget
    } = this.state;
    const {
      updatePrimaryWidgetHeightAndWidth,
      primaryWidget
    } = this.props;
    const newWidgetExpandState = !expandWidget;
    let popupHeight = window.innerHeight;
    const popupWidth = window.innerWidth * 0.8;
    if (newWidgetExpandState && updatePrimaryWidgetHeightAndWidth) {
      if (widgetName === 'Raw Data Grid') {
        popupHeight -= 210;
        updatePrimaryWidgetHeightAndWidth(popupHeight.toString())
      } else if (widgetName.includes('Legend')) {
        popupHeight -= 100;
        let newWidth = popupWidth / (primaryWidget!.props.children.props.children.length - 3);
        newWidth = newWidth > 20 ? newWidth : 20;
        updatePrimaryWidgetHeightAndWidth(popupHeight.toString(), newWidth);
      }
    } else if (!newWidgetExpandState && updatePrimaryWidgetHeightAndWidth) {
      if (widgetName === 'Raw Data Grid') {
        updatePrimaryWidgetHeightAndWidth('400')
      } else if (widgetName.includes('Legend')) {
        updatePrimaryWidgetHeightAndWidth('616', 20);
      }
    }
  }

  expandWidgetHandler = () => {
    const {
      expandWidget,
      pinWidget,
      wasPinned,
      widgetName,
    } = this.state;
    const {
      RAW_DATA_GRID_WIDGET,
      DEFAULT_POSITION_OTHER_WIDGETS,
      DEFAULT_POSITION_RDG_WIDGET,
      callPinToggleBack,
    } = this;
    if (pinWidget) {
      this.pinWidgetHandler();
      this.updatePrimaryWidgetHeightAndWidth();
      this.setState({
        expandWidget: !expandWidget,
        wasPinned: true
      });
    } else if (wasPinned && expandWidget) {
      const newDefaultPosition = (!pinWidget && widgetName === RAW_DATA_GRID_WIDGET) ? DEFAULT_POSITION_RDG_WIDGET : DEFAULT_POSITION_OTHER_WIDGETS;
      this.updatePrimaryWidgetHeightAndWidth();
      this.setState({
        expandWidget: false,
        pinWidget: !pinWidget,
        defaultPosition: newDefaultPosition,
        wasPinned: false,
      }, () => {
        callPinToggleBack();
      });
    } else {
      this.updatePrimaryWidgetHeightAndWidth();
      this.setState({ expandWidget: !expandWidget });
    }
  };

  linkWidgetHandler = () => {
    const { linkWidget } = this.state;
    const {
      linkHandler,
      unlinkHandler,
      id,
      highlightColor,
    } = this.props;
    if (highlightColor === undefined) { // not linked
      this.setState({ linkWidget: !linkWidget }, () => {
        if (linkHandler) {
          linkHandler(id);
        }
      });
    } else {
      this.setState({ linkWidget: !linkWidget }, () => {
        if (unlinkHandler) {
          unlinkHandler(id);
        }
      });
    }
  };

  closeWidgetHandler = () => {
    const {
      closeHandler,
      id
    } = this.props;
    if (closeHandler) {
      closeHandler(id);
    }
  };

  render() {
    const {
      showPrimaryWidget,
      expandWidget,
      widgetName,
      pinWidget,
      linkWidget,
      defaultPosition,
    } = this.state;
    const {
      primaryWidget,
      secondaryWidget,
      tertiaryWidget,
      primaryWidgetWeight,
      secondaryWidgetWeight,
      closeToggle,
      pinToggle,
      linkToggle,
      expandToggle,
      fullWidth,
      alternateFullWidthClass,
      hidePrimaryWidgetOnExpand,
      hideSecondaryWidgetOnExpand,
      contextMenuItems,
      instanceName,
      highlightColor,
      showBorder,
      widgetClassName,
      pinClassName,
      cardPaddingClass,
      show,
      id,
      showSpinner,
      changeModalClass,
    } = this.props;
    if (show !== undefined && show === false) {
      return <></>;
    }

    return (
      <Draggable
        axis={pinWidget ? 'both' : 'none'}
        defaultPosition={defaultPosition}
        // onDrag={(e: any) => {
        //   const widgetCardHeader = document.getElementById(`${id}_widget_card_header`);
        //   if (widgetCardHeader && (e.target.id === widgetCardHeader.id)) {
        //     return;
        //   }
        //   return false;
        // }}
      >
        <div
          ref={this.containerDiv}
          className={`background-color-light 
          ${widgetClassName}
          ${showBorder ? 'border-all ' : ''}
           ${pinWidget ? pinClassName || 'floating-graph-right shadow' : fullWidth ? alternateFullWidthClass : 'h-100 w-100'}`}
        >
          {
            contextMenuItems
                        && (
                          <ContextMenu
                            items={(contextMenuItems as any)}
                            target={this.containerDiv.current}
                            onItemClick={(e: any) => {
                              if (e.itemData.action !== undefined) {
                                e.itemData.action(instanceName);
                              }
                              e.component.hide();
                            }}
                          />
                        )
          }
          <div
            id={`${id}_widget_card_header`}
            className={`${showSpinner ? 'd-none' : 'd-flex'} ${widgetName !== '' ? 'justify-content-between' : 'justify-content-center'} align-items-center break-bottom mb10 p10 `}
          >
            {widgetName !== '' ? (
              <Heading size={6}>{widgetName}</Heading>
            ) : null}
            <div>
              {closeToggle ? (
                <Button className="float-right pr-0" onClick={this.closeWidgetHandler}>
                  <i className="icon dx-icon-close" />
                </Button>
              ) : null}
              {
                expandToggle ? (
                  <Button className="float-right pr-0" onClick={this.expandWidgetHandler}>
                    <i className="icon dx-icon-fullscreen" />
                  </Button>
                ) : null
              }
              {
                pinToggle
                  ? (
                    <Button className="float-right pr-0" onClick={this.pinWidgetHandler}>
                      <i className={pinWidget ? 'icon dx-icon-pin' : 'icon dx-icon-unpin'} />
                    </Button>
                  )
                  : null
              }
              {
                secondaryWidget ? (
                  (
                    <Button className="float-right pr-0" onClick={this.showSecondaryWidget}>
                      <i className="icon dx-icon-contentlayout" />
                    </Button>
                  )
                ) : null
              }
              {
                linkToggle ? (
                  <Button className="float-right pr-0" onClick={this.linkWidgetHandler}>
                    {highlightColor === undefined
                      ? (
                        <i
                          className={linkWidget ? 'icon dx-icon-isnotblank' : 'icon dx-icon-isblank'}
                        />
                      )
                      : (
                        <i
                          style={{ color: highlightColor }}
                          className={linkWidget ? 'dx-icon-isnotblank' : 'dx-icon-isblank'}
                        />
                      )}
                  </Button>
                )
                  : null
              }
            </div>
          </div>
          {showSpinner && (
            <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: '700px' }}>
              <Spinner animation="border" />
            </div>
          )}
          <div className={`${cardPaddingClass} ${showSpinner ? 'd-none' : 'd-flex'} col-12 justify-content-center align-items-center`}>
            {primaryWidget}
            {!showPrimaryWidget ? secondaryWidget : <></>}
          </div>
          {expandWidget ? (
            <WidgetPopup
              showSpinner={showSpinner}
              show={expandWidget}
              widgetName={widgetName}
              hideWidgetPopup={this.expandWidgetHandler}
              primaryWidget={primaryWidget}
              secondaryWidget={secondaryWidget}
              tertiaryWidget={tertiaryWidget}
              primaryWidgetWeight={primaryWidgetWeight}
              secondaryWidgetWeight={secondaryWidgetWeight}
              hidePrimaryWidgetOnExpand={hidePrimaryWidgetOnExpand}
              hideSecondaryWidgetOnExpand={hideSecondaryWidgetOnExpand}
              changeModalClass={changeModalClass}
            />
          ) : null}
        </div>
      </Draggable>
    );
  }
}

export default WidgetCard;
