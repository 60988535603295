/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
// eslint-disable-next-line no-unused-vars
import { MiddleViewport } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
// eslint-disable-next-line no-unused-vars
import { DieCountSummaryData, DieType } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import React from 'react';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';

type DieCountSummaryProps = {
  dieTypes: DieType[],
  onDieTypeDoubleClick: () => void,
  onDieTypeClick: (dieType: DieType | null) => void,
  currentDieTypeId: string | null,
  countSummary: DieCountSummaryData,
  onDefineDieTypesButtonClicked: () => void,
  middleViewport: MiddleViewport,
  isLoading: boolean,
};

type DieCountSummaryState = {

};

export default class DieCountSummary extends React.Component<DieCountSummaryProps, DieCountSummaryState> {
  constructor(props: DieCountSummaryProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      dieTypes, onDieTypeDoubleClick, onDieTypeClick, currentDieTypeId, countSummary, onDefineDieTypesButtonClicked, middleViewport, isLoading,
    } = this.props;
    return (
      <Col lg={2} style={{ border: '1px solid #ddd' }}>
        <h5 className="mt20 text-center no-selection-color">Die Count Summary</h5>
        <hr />
        <Row className="pl5 pt5 mb10">
          <Col lg={6} className="p0"><strong>Name</strong></Col>
          <Col lg={3} className="p0"><strong>Count</strong></Col>
          <Col lg={3} className="p0"><strong>Coverage</strong></Col>
        </Row>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '430px' }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <div>
            <div>
              {dieTypes.map((dieType: DieType, index: number) => {
                return (
                  <Row
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}${dieType.name}`}
                    className="pl5 pt5 mb10"
                    role="button"
                    onDoubleClick={onDieTypeDoubleClick}
                    onClick={() => { onDieTypeClick(dieType); }}
                    style={{ fontSize: '12px', backgroundColor: dieType.id === currentDieTypeId ? '#ddd' : '#fff' }}
                  >
                    <Col lg={6} className="p0">
                      <span className="h10 mr5 w20" style={{ backgroundColor: (dieType.color), display: 'inline-block' }} />
                      <span style={{ display: 'inline-block' }}>{dieType.name}</span>
                    </Col>
                    <Col lg={3} className="p0">{countSummary.dieTypeCountInfo[dieType.id]}</Col>
                    <Col lg={3} className="p0">
                      {countSummary.totalDieCount !== 0 ? Math.round((countSummary.dieTypeCountInfo[dieType.id] / countSummary.totalDieCount) * 100 * 100) / 100 : 0}
                      {' '}
                      %
                    </Col>
                  </Row>
                );
              })}
            </div>
            <hr />
            <Row
              className="pl5 pt5"
              style={{ fontSize: '12px', backgroundColor: currentDieTypeId === null ? '#ddd' : '#fff' }}
            >
              <Col lg={6} className="p0"><strong>Total</strong></Col>
              <Col lg={3} className="p0">{countSummary.totalDieCount}</Col>
              <Col lg={3} className="p0" />
            </Row>
            <hr />
            <Button
              onClick={onDefineDieTypesButtonClicked}
              size="sm"
              variant="primary"
              className="mt10 mb30"
            >
              {middleViewport === MiddleViewport.WAFER ? 'Define Die Types' : 'Device Map' }
            </Button>
          </div>
        )}
      </Col>

    );
  }
}
