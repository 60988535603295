import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';

interface IPATLimitsTableProps {
  patLimitsData: any;
  show: boolean;
  updatePATLimits: any;
}

class PATLimitsTable extends Component<IPATLimitsTableProps, any> {
  getPATLimitType = (lowLimit: number, highLimit: number) => {
    if (lowLimit !== 0 && highLimit !== 0){
      return 'Standard 2 sided PAT'
    }
    return 'Single Sided PAT'
  }

  generateDataSource = (patLimitsData: any) => {
    const limitsData: any[] = [];
    patLimitsData.forEach((patLimit: any) => {
      limitsData.push({
        ruleId: patLimit.ruleId,
        ruleName: patLimit.ruleName,
        lowKValue: patLimit.lowKValue,
        highKValue: patLimit.highKValue,
        ruleType: patLimit.ruleType,
        testNumber: patLimit.testParameterNumber,
        testName: patLimit.testParameterName,
        patMinLimit: patLimit.lowLimit.toFixed(8),
        patMaxLimit: patLimit.highLimit.toFixed(8),
        patLimitType: this.getPATLimitType(patLimit.lowLimit, patLimit.highLimit),
      });
    });
    return limitsData;
  };

  render() {
    const { patLimitsData, show, updatePATLimits } = this.props
    return (
      <div>
        <Row>
          <Col lg={12}>
            <div className="mt10">
              <DataGrid
                visible={show}
                height="auto"
                showBorders
                dataSource={patLimitsData.length > 0 ? this.generateDataSource(patLimitsData) : []}
                selection={{ mode: 'multiple' }}
                onSelectionChanged={(e: any) => updatePATLimits(e.selectedRowKeys)}
              >
                <Column alignment="left" caption="Rule Name" dataField="ruleName" />
                <Column caption="K Value">
                  <Column alignment="left" caption=" Low" dataField="lowKValue" />
                  <Column alignment="left" caption="High" dataField="highKValue" />
                </Column>
                <Column alignment="left" caption="Rule Type" dataField="ruleType" />
                <Column alignment="left" caption="Test Parameter Number" dataField="testNumber" />
                <Column alignment="left" caption="Test Parameter Name" dataField="testName" />
                <Column alignment="left" caption="PAT Min Limit" dataField="patMinLimit" />
                <Column alignment="left" caption="PAT Max Limit" dataField="patMaxLimit" />
                <Column alignment="left" caption="PAT Limit Type" dataField="patLimitType" />
              </DataGrid>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PATLimitsTable;
