import React from 'react';
import ColorBox from 'devextreme-react/color-box';
import { Button } from 'react-bootstrap';
import toast from 'CustomToast';
import CustomizedDropdown from '../../../../../components/wrapped-component/customized-dropdown/CustomizedDropdown';
import TextBox from '../../../../../components/wrapped-component/hint-controls/Textbox';
import { defaultLine, IActionData, ILineType } from './ActionItemsSheet';
import Label from '../../../../../components/wrapped-component/hint-controls/Label';
import Heading from '../../../../../components/wrapped-component/hint-controls/Heading';

interface IActionItemsPopoverProps {
  actionItemData : IActionData
  onSaveHandler: (action:string, line: ILineType) => void
}

const line : ILineType = {
  color: '',
  width: 0,
  dash: '',
  opacity: 0,
};

const lineAppearanceList = [
  ['solid', 'Solid'],
  ['dot', 'Dot'],
  ['dash', 'Dash'],
  ['longdash', 'Long Dash'],
  ['dashdot', 'Dash Dot'],
  ['longdashdot', 'Long Dash Dot'],
];

const ActionItemsPopover = (props:IActionItemsPopoverProps) => {
  const onColorBoxSelectionHandler = (e:any) => {
    line.color = e.value;
  };

  const onLineAppearanceHandler = (value:string) => {
    line.dash = value;
  };

  const onThicknessHandler = (e:any) => {
    if (e.currentTarget.value === '') {
      line.width = 1;
      return;
    }

    const value : number = parseInt(e.currentTarget.value, 10);
    if (value > 0 && value <= 15) {
      line.width = value;
    } else if (value > 15) {
      toast.warn('Line Thickness cannot be above 15px');
      line.width = 15;
      e.currentTarget.value = 15;
    } else {
      line.width = 1;
      e.currentTarget.value = 1;
    }
  };

  const onOpacityHandler = (e:any) => {
    if (e.currentTarget.value === '') {
      line.opacity = 1;
      return;
    }

    const value : number = parseFloat(e.currentTarget.value);
    if (value > 0 && value <= 1.0) {
      line.opacity = value;
    } else if (value > 1.0) {
      toast.warn('Line Opacity cannot be above 1px');
      line.opacity = 1.0;
      e.currentTarget.value = 1.0;
    } else {
      line.opacity = 0.1;
      e.currentTarget.value = 0.1;
    }
  };

  const onSaveClick = () => {
    const { actionItemData, onSaveHandler } = props;

    if (line.dash === '') line.dash = defaultLine.dash;
    if (line.color === '') line.color = defaultLine.color;
    if (line.width === 0) line.width = defaultLine.width;
    if (line.opacity === 0) line.opacity = defaultLine.opacity;

    if (onSaveHandler) onSaveHandler(actionItemData.action, line);
  };

  const { actionItemData } = props;

  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="mt5 break-bottom">
        <Heading size={5}>Line Settings</Heading>
      </div>
      <div className="d-flex flex-column mt25 h-100 align-items-center custom-form">
        <Label
          labelSize="40"
          labelPosition="left-middle"
          errorSize="0"
          labelTitle="Color"
          fieldSize="60"
          className="mb15"
        >
          <ColorBox
            acceptCustomValue
            defaultValue={actionItemData.line.color}
            onValueChanged={onColorBoxSelectionHandler}
          />
        </Label>
        <Label
          labelSize="40"
          labelPosition="left-middle"
          errorSize="0"
          labelTitle="Appearance"
          fieldSize="60"
          className="mb15"
        >
          <CustomizedDropdown
            variant="light"
            full
            list={lineAppearanceList}
            selectedValue={actionItemData.line.dash}
            onChange={onLineAppearanceHandler}
          />
        </Label>
        <Label
          labelSize="40"
          labelPosition="left-middle"
          errorSize="0"
          labelTitle="Thickness"
          fieldSize="60"
          className="mb15"
        >
          <TextBox
            step="1"
            autoComplete="off"
            type="number"
            name="thickness"
            placeholder="Thickness"
            defaultValue={actionItemData.line.width}
            onChange={onThicknessHandler}
          />
        </Label>
        <Label
          labelSize="40"
          labelPosition="left-middle"
          errorSize="0"
          labelTitle="Opacity"
          fieldSize="60"
          className="mb15"
        >
          <TextBox
            step="0.1"
            min="0"
            autoComplete="off"
            type="number"
            name="opacity"
            placeholder="Opacity"
            defaultValue={actionItemData.line.opacity}
            onChange={onOpacityHandler}
          />
        </Label>
      </div>
      <div className="d-flex flex-row-reverse">
        <Button
          onClick={onSaveClick}
          type="success"
          style={{ marginLeft: 'auto', backgroundColor: 'green', borderColor: 'green' }}
          variant="primary"
          size="lg"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ActionItemsPopover;
