import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import ScrollView from 'devextreme-react/scroll-view';
import {
  TreeList, Paging, Column, FilterRow, ColumnChooser,
} from 'devextreme-react/tree-list';
import { fixColumnChooserPosition } from 'dataGridConstants';
import Button, { default as CustomizedButton } from '../../components/wrapped-component/hint-controls/Button';
import TopbarNav from '../../components/navigational-component/topbar-nav/TopbarNav';
import { httpService } from '../../services/http.service';
import ContextSavingSpinner from '../individual-reports/customized-report/customized-report-utils/ContextSavingSpinner';

interface IPoliciesProps {
  className?: string;
  hideNavbar? :boolean;
}

const Policies = (props:IPoliciesProps) => {
  const history = useHistory();
  const { className, hideNavbar } = props;
  const [policiesList, updatePoliciesList] = useState([] as any[]);
  // const [policyResults, updatePolicyResults] = useState([] as any[]);
  const [policiesListLoaded, setPoliciesListLoaded] = useState(false);

  useEffect(() => {
    if (policiesList.length === 0) {
      httpService.getPolicies('type=Service').then((data: any) => {
        updatePoliciesList(data);
        setPoliciesListLoaded(true);
      });
    }
  }, []);

  const CreatePolicy = () => {
    history.push({
      pathname: '/create-policy',
    });
  };

  const GetRowData = (e:any) => {
    history.push(`/create-policy/${e.id}`, { Id: e.id });
  };

  const simulatePolicy = (e:any) => {
    history.push(`/simulate-policy/${e.data.id}`, { id: e.data.id });
  };

  const viewPolicyOutput = (e:any) => {
    history.push(`/view-policy-output/${e.data.id}`, { id: e.data.id });
  };

  // const deletePolicyOutput = (e: any) => {
  //   // history.replace(`/policy/${e.data.id}`, { state: { deleted: false, id: e.data.id } });
  //   updatePoliciesList((prevState) => policiesList.filter((obj) => obj.id !== e.data.id));
  // };

  return (
    <ScrollView
      showScrollbar="always"
      scrollByThumb
      reachBottomText=""
    >
      <Container fluid className={className !== '' ? className : ''}>
        { !hideNavbar && (
          <TopbarNav
            title="Policy Engine"
            items={[]}
            showAvatar
            showNotifications
            secondaryActions={(
              <Button
                onClick={CreatePolicy}
                size="sm"
                variant="primary"
                className="mr20 mt-5"
              >
                Create Policy
              </Button>
            )}
          />
        )}
        <Row className="p20">
          {
            policiesListLoaded ? (
              <Col>
                <TreeList
                  dataSource={policiesList}
                  showRowLines
                  showBorders
                  showColumnLines={false}
                  columnAutoWidth
                  height={800}
                  keyExpr="id"
                  parentIdExpr="sourcePolicyId"
                  onContentReady={(e: any) => {
                    const headerPanel = e.element.querySelector('.dx-treelist-header-panel');
                    if (headerPanel) {
                      // set 15px to the bottom badding of the header panel to avoid overlapping with tree list border
                      headerPanel.style.paddingBottom = '15px';
                    }
                    fixColumnChooserPosition(e);
                  }}
                >
                  <Paging
                    enabled
                    defaultPageSize={10}
                  />
                  <FilterRow visible />
                  <Column
                    caption="Name"
                    dataField="name"
                    cellRender={(e: any) => {
                      if (e.data.simulationFlag) {
                        return (
                          <span>{e.text}</span>
                        );
                      }
                      return (
                        <CustomizedButton
                          variant="link"
                          onClick={() => GetRowData(e.data)}
                        >
                          {e.text}
                        </CustomizedButton>
                      );
                    }}
                  />
                  <Column caption="Created On" dataField="createdOn" dataType="datetime" />
                  <Column caption="Version" dataField="version" />
                  <Column caption="Purpose" dataField="purpose" />
                  <Column caption="State" dataField="state" />
                  <Column caption="Stages Count" dataField="count" dataType="string" />
                  <Column caption="Owner" dataField="owner" />
                  {/* <Column caption="LOT" dataField="lotName" /> */}
                  {/* <Column caption="Wafer" dataField="waferName" /> */}
                  {/* <Column caption="Facility/WC/D/TP/TPR" dataField="waferDetails" /> */}
                  <Column caption="Stages" dataField="stages" />
                  <Column caption="Execution Type" dataField="executionType" />
                  <ColumnChooser
                    mode="column"
                    enabled
                    title="Grid Column Chooser"

                  />
                  <Column
                    caption="Action"
                    dataField="simulate"
                    alignment="center"
                    width={200}
                    cellRender={(e:any) => {
                      if (e.data.simulationFlag) {
                        return (
                          <Button
                            className="ml72"
                            size="sm"
                            variant="primary"
                            onClick={() => viewPolicyOutput(e)}
                          >
                            View Output
                          </Button>
                        );
                      }
                      return (
                        <div>
                          <Button
                            className="mr10"
                            size="sm"
                            variant="success"
                            onClick={() => simulatePolicy(e)}
                          >
                            Simulate
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={() => viewPolicyOutput(e)}
                          >
                            View Output
                          </Button>
                        </div>
                      );
                    }}
                  />
                </TreeList>
                {/* <CustomizedDataGrid */}
                {/*  type="non-reporting" */}
                {/*  showFilterRow */}
                {/*  fields={[ */}
                {/*    { */}
                {/*      caption: 'Name', */}
                {/*      dataField: 'name', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*      minWidth: 200, */}
                {/*      viewMode: 'anchor', */}
                {/*      onClick: GetPolicyResult || GetRowData, */}
                {/*      wordWrap: true, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Version', */}
                {/*      dataField: 'version', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*      width: 100, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Description', */}
                {/*      dataField: 'purpose', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*      minWidth: 200, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Facility', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Work Center', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Device', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Test Program', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'State', */}
                {/*      dataField: 'state', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*      width: 100, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Owner', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Email', */}
                {/*      dataField: '', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Stages Count', */}
                {/*      dataField: 'count', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: 'Stages', */}
                {/*      dataField: 'stages', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*    }, */}
                {/*    { */}
                {/*      caption: '', */}
                {/*      dataField: 'simulate', */}
                {/*      dataType: 'string', */}
                {/*      showInfo: false, */}
                {/*      cellRender: (e:any) => { */}
                {/*        return ( */}
                {/*          <Button */}
                {/*            size="sm" */}
                {/*            variant="outline-primary" */}
                {/*            onClick={() => simulatePolicy(e)} */}
                {/*          > */}
                {/*            Simulate */}
                {/*          </Button> */}
                {/*        ); */}
                {/*      }, */}
                {/*    }, */}
                {/*  ]} */}
                {/*  tableData={policiesList} */}
                {/* /> */}
              </Col>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '430px' }}>
                <div className="w300">
                  <ContextSavingSpinner
                    mode="POWER_VIEW"
                    title="Loading Policies"
                  />
                </div>
              </div>
            )
          }
        </Row>
      </Container>
    </ScrollView>
  );
};

Policies.defaultProps = {
  hideNavbar: false,
  className: '',
};

export default Policies;
