/* eslint-disable no-param-reassign */
import React from 'react';

import withProps from 'components/bin-plus-tables/withProps';
import CustomizedDataGrid, { ICustomizedDataGridProps, ICustomizedDataGridColumnFields, } from '../customized-data-grid/CustomizedDataGrid';
import CustomizedTagBox from './CustomizedTagBox';
import CustomizedSelectBox from './CustomizedSelectBox';
import CustomizedTwoInputBox from './CustomizedTwoInputBox';
import CustomizedTextBox from './CustomizedTextBox';

export interface IEditableDataGridColumnFields extends Omit<ICustomizedDataGridColumnFields, 'fields'> {
  cellType: 'textBox' | 'tagBox' | 'twoInputBox' | 'selectBox' | 'conditional' | 'columns' | 'datetime' | 'textBoxWithCallback';
  cellData?: any;
  isSetCellValueRequired?: boolean;
  calculateDisplayValue?: any;
  cellComponent?: any;
  isRequired?: boolean;
  fields?: IEditableDataGridColumnFields[];
  validationRules?: any[];
  // disabledWhen?:{
  //   values: string[],
  //   inColumn: string,
  // }
}

interface IEditableDataGridProps extends Omit<ICustomizedDataGridProps, 'fields'> {
  fields: IEditableDataGridColumnFields[];
}

interface IEditableDataGridState {
  forwardProps: ICustomizedDataGridProps;
}

class EditableDataGrid extends React.Component<IEditableDataGridProps, IEditableDataGridState> {
  constructor(props: IEditableDataGridProps) {
    super(props);
    this.state = {
      forwardProps: this.reconfigureFieldsDriver(),
    };
  }

  componentDidUpdate(prevProps: IEditableDataGridProps) {
    if (prevProps !== this.props) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => {
        return {
          forwardProps: this.reconfigureFieldsDriver(),
        };
      });
    }
  }

  reconfigureFieldsDriver = () => {
    const localProps = { ...this.props };
    const { fields } = localProps;

    const newFields = this.reconfigureFields(fields);

    localProps.fields = newFields;

    return localProps;
  };

  reconfigureFields = (fields: any) => {
    const newFields: any[] = [];
    fields.forEach((field: any) => {
      if (field.cellType === 'textBoxWithCallback') {
        field.editCellComponent = withProps(CustomizedTextBox, { customData: field.cellData });
      } else if (field.cellType === 'selectBox') {
        field.editCellComponent = withProps(CustomizedSelectBox, { customData: field.cellData });
      } else if (field.cellType === 'tagBox') {
        field.editCellComponent = withProps(CustomizedTagBox, { customData: field.cellData });
      } else if (field.cellType === 'twoInputBox') {
        field.editCellComponent = withProps(CustomizedTwoInputBox, { customData: field.cellData });
      } else if (field.cellType === 'conditional') {
        field.editCellComponent = withProps(field.cellComponent, { customData: field.cellData });
      } else if (field.cellType === 'columns') {
        const subFields = this.reconfigureFields(field.fields);
        field.fields = subFields;
      } else {
        // do nothing for datatime and textbox
      }
      delete field.cellComponent;
      delete field.cellType;
      delete field.cellData;
      newFields.push(field);
    });
    return newFields;
  };

  render() {
    const { forwardProps } = this.state;

    if (forwardProps) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <CustomizedDataGrid {...forwardProps} />;
    }
    return <div />;
  }
}

export default EditableDataGrid;
