import React from 'react';
import { ScrollView } from 'devextreme-react';
import DropDownBox from 'devextreme-react/drop-down-box';
import GroupSortDialog from '../../../../utility-component/group-sort-widget/GroupSortDialog';
import { GroupingSortingDTO } from '../../../../../interfaces';

const dropDownOptions = { width: '500%' };
class SPCGroupingDropdown extends React.Component<any, any> {
  dropDownBoxRef: any;

  constructor(props:any) {
    super(props);
    this.state = {
      selectedSequence: [],
      columnsList: props.customData.columnsList,
      // showSortDialog: false,
      // showGroupDialog: false,
    };
    this.dropDownBoxRef = React.createRef();
  }

  selectFieldHandler = (e:any, dialogType:string) => {
    const { selectedSequence, columnsList } = this.state;
    const item : any = e.row.data;

    const columnObj = columnsList.find((obj:any) => obj.ColumnName === item.ColumnName);
    columnObj.isVisible = false;

    if (dialogType === 'SORT') {
      const field : any = {
        columnName: item.ColumnName,
        caption: item.Caption,
        selector: item.ColumnName,
        desc: false,
        entityType: item.ParentId,
        fieldName: item.FieldName,
        disabled: item.Disabled,
        parentRank: item.ParentRank,
      };
      selectedSequence.push(field);
      this.setState({ selectedSequence, columnsList });
    } else {
      const field : any = {
        columnName: item.ColumnName,
        caption: item.Caption,
        selector: item.ColumnName,
        entityType: item.ParentId,
        fieldName: item.FieldName,
        disabled: item.Disabled,
        parentRank: item.ParentRank,
      };
      selectedSequence.push(field);
      this.setState({ selectedSequence, columnsList });
    }
  };

  setReorder = (selectedSequence:any, dialogType: string) => {
    if (dialogType === 'SORT') {
      this.setState({ selectedSequence });
    }
  };

  changeSort = (e:any) => {
    const { selectedSequence } = this.state;
    const item : any = e.data;

    const sortField = selectedSequence.find((obj:any) => obj.columnName === item.columnName);
    sortField.desc = !sortField.desc;

    this.setState({ selectedSequence });
  };

  deleteFieldHandler = (e:any, dialogType:string) => {
    const { selectedSequence, columnsList } = this.state;
    const item : any = e.row.data;
    const columnObj = columnsList.find((obj:any) => obj.Caption === item.caption);
    columnObj.isVisible = true;

    const index = selectedSequence.indexOf(item);
    if (index > -1) {
      selectedSequence.splice(index, 1);
    }
    this.setState({ selectedSequence, columnsList });
  };

  returnGroupingSortingData = (dialogType:string) => {
    const { selectedSequence } = this.state;
    let data : GroupingSortingDTO[] = [];
    if (dialogType === 'GROUP') {
      data = selectedSequence.map((item:any, index:number) => ({
        entityType: item.entityType,
        // name: item.fieldName,
        // sequence: index,
        // operationType: 'GROUPING',
        columnName: item.columnName,
      }));
    } else {
      data = selectedSequence.map((item:any, index:number) => ({
        entityType: item.entityType,
        selector: item.columnName,
        // sequence: index,
        // operationType: 'SORTING',
        isDesc: item.desc,
      }));
    }
    this.sendToParent(data);
  };

  applySorting = () => {
    this.returnGroupingSortingData('SORT');
  };

  applyGrouping = () => {
    this.returnGroupingSortingData('GROUP');
  };

  sendToParent = (groupingSortingData: GroupingSortingDTO[]) => {
    const { data } = this.props;

    data.setValue(groupingSortingData);
    this.dropDownBoxRef.current.instance.close();
  };

  renderGrid = (columnsList:any) => {
    const { selectedSequence } = this.state;
    const { customData } = this.props;

    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        direction="both"
      >
        <GroupSortDialog
          isDropdown
          id={customData.DialogType}
          columnsList={columnsList}
          selectedField={selectedSequence.map((ele:any) => ele)}
          selectFieldHandler={this.selectFieldHandler}
          deleteFieldHandler={this.deleteFieldHandler}
          applyClause={customData.DialogType === 'GROUP' ? this.applyGrouping : this.applySorting}
          setReorder={customData.DialogType !== 'GROUP' ? this.setReorder : undefined}
          changeSort={customData.DialogType !== 'GROUP' ? this.changeSort : undefined}
        />
      </ScrollView>
    );
  };

  render() {
    const { selectedSequence, columnsList } = this.state;
    return (
      <DropDownBox
        ref={this.dropDownBoxRef}
        dropDownOptions={dropDownOptions}
        value={selectedSequence}
        valueExpr="ID"
        deferRendering={false}
        displayExpr="ID"
        placeholder="Select..."
        showClearButton={false}
        dataSource={selectedSequence}
        contentRender={(e:any) => { return this.renderGrid(columnsList); }}
      />
    );
  }
}
export default SPCGroupingDropdown;
