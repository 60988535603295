import React, { Component } from 'react';
import {
  Row, Col, Container, Spinner,
} from 'react-bootstrap';
import Button from '../../../../wrapped-component/hint-controls/Button';
import { ISelectionCriteriaReturnValue } from '../../../../../interfaces';
import SelectionCriteria from '../../../../selection-criteria/SelectionCriteria';
import { httpService } from '../../../../../services/http.service';
import { RuleTestParameter } from '../PATInterfaces';
import toast from '../../../../../CustomToast';

interface ISeedLimitCalculationProps {
  ruleTestParameters: RuleTestParameter[];
  searchCriteria: any;
  getSeedLimit: (e:any) => void;
}
interface ISeedLimitCalculationState {
  // lotsIncluded: any,
  // selectedLotsIncluded: any,
  // lotsExcluded: any,
  // selectedLotsExcluded: any,
  calculateSeedLimit: boolean,
}

class SeedLimitCalculation extends Component<ISeedLimitCalculationProps, ISeedLimitCalculationState > {
  private filterCriteria: any = [];

  private selectedLots: any = [];

  constructor(props: any) {
    super(props);
    this.state = {
      calculateSeedLimit: false,
    };
  }

  componentDidMount() {
    const { searchCriteria } = this.props;
    // eslint-disable-next-line no-unused-expressions
    searchCriteria?.forEach((criteria:any) => {
      if (criteria.id !== 'None') {
        this.filterCriteria.push({
          columnName: criteria.columnName,
          operationName: '=',
          queryText: criteria.id,
          controlType: 'LOT',
          dataType: 'string',
          groupConditionOn: 'OR',
        });
      }
    });
  }

  getSelectionCriteriaComponentData = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    this.selectedLots = selectionCriteriaData.selections[0].values.map((lot:any) => lot.id);
  };

  calculateSeedLimitHandler = () => {
    if (this.selectedLots.length === 0) return;
    const { calculateSeedLimit } = this.state;
    const { ruleTestParameters, getSeedLimit } = this.props;
    const SeedLimitInputDTO = {
      SeedLimitInput: ruleTestParameters.map((tp: RuleTestParameter) => ({
        TestParameterId: tp.tpId,
        SiteNumber: tp.siteNumber,
        LowKLimit: tp.lowKLimit,
        HighKLimit: tp.highKLimit,
        seedLowLimit: tp.seedLowLimit,
        seedHighLimit: tp.seedHighLimit,
      })),
      LotId: this.selectedLots,
    };

    this.setState({ calculateSeedLimit: true });

    if (SeedLimitInputDTO.SeedLimitInput.some((limit) => limit.LowKLimit === 0 && limit.HighKLimit === 0)) {
      toast.error('Value of K cannot be 0');
    }

    httpService.calculateSeedLimit(SeedLimitInputDTO)
      .then((data) => {
        getSeedLimit(data);
      });
  };

  render() {
    const { calculateSeedLimit } = this.state;
    return (
      <Container>
        <Row className="mt40">
          <Col lg={12}>
            <SelectionCriteria
              useDefaultHeight={false}
              filterCriteria={this.filterCriteria}
              advancedFilter=""
              title="Lots for Seed Calculation"
              getSelectionCriteriaData={this.getSelectionCriteriaComponentData}
              defaultSelection={[]}
              hideFavoritesButton
              widgetsList={
                [
                  {
                    controlType: 'Lot',
                    columnWeight: 12,
                    isVisible: true,
                  },
                ]
              }
              isForSeedCalculation
            />
          </Col>
        </Row>
        <Row className="mt20">
          <Col lg={12}>
            <div className="d-flex align-items-end justify-content-end mt20 mb20">
              <Button
                variant="primary"
                size="sm"
                onClick={this.calculateSeedLimitHandler}
              >
                {
                  calculateSeedLimit
                    ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr10"
                      />
                    ) : null
                }
                {calculateSeedLimit ? 'Calculating...' : 'Calculate'}
              </Button>
            </div>

          </Col>
        </Row>
      </Container>
    );
  }
}

export default SeedLimitCalculation;
