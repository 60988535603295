/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpLegend } from 'services/http.legend';
import { InteractionInputDTO, ICustomizedLegendData } from '../CustomizedReportInterfaces';
import { ILegendDataHelper } from '../CustomizedReportHelper';

export class ParametricBoxPlotLegendDataHelper implements ILegendDataHelper {
  private legendData : any = undefined;

  preferences = {
    summary: {
      calculateCustomSummary: (options:any) => {
        if (this.legendData.legendSummary.length > 0 && this.legendData.legendSummary[0][0][options.name]) {
          options.totalValue = this.legendData.legendSummary[0][0][options.name];
        }
      },
      totalItems: [
        {
          name: 'Combined Total Dies',
          summaryType: 'custom',
          displayFormat: 'Combined Total Dies: {0}',
          showInColumn: 'Total Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Average Total Dies',
          summaryType: 'custom',
          displayFormat: 'Average Total Dies: {0}',
          showInColumn: 'Total Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Combined Passed Dies',
          summaryType: 'custom',
          displayFormat: 'Combined Passed Dies: {0}',
          showInColumn: 'Passed Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Average Passed Dies',
          summaryType: 'custom',
          displayFormat: 'Average Passed Dies: {0}',
          showInColumn: 'Passed Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Combined Failed Dies',
          summaryType: 'custom',
          displayFormat: 'Combined Failed Dies: {0}',
          showInColumn: 'Failed Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Average Failed Dies',
          summaryType: 'custom',
          displayFormat: 'Average Failed Dies: {0}',
          showInColumn: 'Failed Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Average Parametric Yield %',
          summaryType: 'custom',
          displayFormat: 'Average Parametric Yield %: {0}',
          showInColumn: 'Parametric Yield %',
          showInGroupFooter: true,
        },
        {
          name: 'Max',
          summaryType: 'custom',
          displayFormat: 'Average Max: {0}',
          showInColumn: 'Max',
          showInGroupFooter: true,
        },
        {
          name: '75th Percentile',
          summaryType: 'custom',
          displayFormat: 'Average Q3: {0}',
          showInColumn: '75th Percentile',
          showInGroupFooter: true,
        },
        {
          name: 'Median',
          summaryType: 'custom',
          displayFormat: 'Average Median: {0}',
          showInColumn: 'Median',
          showInGroupFooter: true,
        },
        {
          name: '25th Percentile',
          summaryType: 'custom',
          displayFormat: 'Average Q1: {0}',
          showInColumn: '25th Percentile',
          showInGroupFooter: true,
        },
        {
          name: 'Min',
          summaryType: 'custom',
          displayFormat: 'Average Min: {0}',
          showInColumn: 'Min',
          showInGroupFooter: true,
        },
        {
          name: 'Total Dies in Min-Q1',
          summaryType: 'custom',
          displayFormat: 'Total Dies in Min-Q1: {0}',
          showInColumn: 'Dies in Min-Q1',
          showInGroupFooter: true,
        },
        {
          name: 'Average Dies in Min-Q1',
          summaryType: 'custom',
          displayFormat: 'Average Dies in Min-Q1: {0}',
          showInColumn: 'Dies in Min-Q1',
          showInGroupFooter: true,
        },
        {
          name: 'Total Dies in Q1-Median',
          summaryType: 'custom',
          displayFormat: 'Total Dies in Q1-Median: {0}',
          showInColumn: 'Dies in Q1-Median',
          showInGroupFooter: true,
        },
        {
          name: 'Average Dies in Q1-Median',
          summaryType: 'custom',
          displayFormat: 'Average Dies in Q1-Median: {0}',
          showInColumn: 'Dies in Q1-Median',
          showInGroupFooter: true,
        },
        {
          name: 'Total Dies in Median-Q3',
          summaryType: 'custom',
          displayFormat: 'Total Dies in Median-Q3: {0}',
          showInColumn: 'Dies in Median-Q3',
          showInGroupFooter: true,
        },
        {
          name: 'Average Dies in Median-Q3',
          summaryType: 'custom',
          displayFormat: 'Average Dies in Median-Q3: {0}',
          showInColumn: 'Dies in Median-Q3',
          showInGroupFooter: true,
        },
        {
          name: 'Total Dies in Q3-Max',
          summaryType: 'custom',
          displayFormat: 'Total Dies in Q3-Max: {0}',
          showInColumn: 'Dies in Q3-Max',
          showInGroupFooter: true,
        },
        {
          name: 'Average Dies in Q3-Max',
          summaryType: 'custom',
          displayFormat: 'Average Dies in Q3-Max: {0}',
          showInColumn: 'Dies in Q3-Max',
          showInGroupFooter: true,
        },
      ],
    },
    legendKeyExpression: 'wafer_key',
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'DIE_ID',
      avoidXWhenGroupingPresent: true,
    },
    defaultGrouping: {
      groupKey: 'lot_key',
      entityType: ('LOT' as EntityType),
      columnName: 'lot',
    },
    postSelectionInYAxis: false,
    equalNumberOfYsForX: false,
  };

  setLegendData = (data: ICustomizedLegendData) => {
    this.legendData = data;
  };

  getPreferences = () => (this.preferences);

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpLegend.setInteractionData(selectionEventInput);
  };

  getDetailedGraphLegendData = async (requestObject: IGenericDetailedReportRequestObject, callback: (legendData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };
}
