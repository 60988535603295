import { ErrorLabel } from 'components/template-component/form-error-label/ErrorLabel';
import { MetaColumnType } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
// eslint-disable-next-line no-unused-vars
import { ReticleSiteAttributeMeta, StandardReticle } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import CreateDynamicColumn, { AttributeApplicationType } from 'components/wrapped-component/customized-data-grid/CreateDynamicColumn';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import { CheckBox, ColorBox, SelectBox } from 'devextreme-react';
import DataGrid, {
  Column, Editing, FilterRow, RangeRule, Scrolling, StringLengthRule, Selection,
} from 'devextreme-react/data-grid';
import _ from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';
import { UtilityFunctions } from './utility';
import { STANDARD_RETICLE_WATPCM_SITE_LIST } from './wafer-control-map';

type StandardReticleEditorProps = {
  onSaveStandardReticle: (data: any) => void,
  standardReticle: StandardReticle,
  reticleSizeX: number,
  reticleSizeY: number,
};

type StandardReticleEditorState = {
  standardReticle: StandardReticle | null,
  watPCMSiteError: string,
};

export default class StandardReticleEditor extends React.Component<StandardReticleEditorProps, StandardReticleEditorState> {
  datagrid: DataGrid | null = null;

  constructor(props: StandardReticleEditorProps) {
    super(props);
    this.state = {
      standardReticle: null,
      watPCMSiteError: '',
    };
  }

  componentDidMount() {
    const { standardReticle, reticleSizeX, reticleSizeY } = this.props;
    this.setState({
      standardReticle: this.getStandardReticle(standardReticle, reticleSizeX, reticleSizeY),
    });
  }

  componentDidUpdate(prevProps: StandardReticleEditorProps) {
    const { standardReticle, reticleSizeX, reticleSizeY } = this.props;
    if (!_.isEqual(prevProps.standardReticle, standardReticle) || prevProps.reticleSizeX !== reticleSizeX || prevProps.reticleSizeY !== reticleSizeY) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ standardReticle: this.getStandardReticle(standardReticle, reticleSizeX, reticleSizeY) });
    }
  }

  getStandardReticle = (standardReticleFromProps: StandardReticle, reticleSizeX: number, reticleSizeY: number): StandardReticle => {
    const shouldInitializeNew = standardReticleFromProps.reticle.length <= 0
    || standardReticleFromProps.reticle.length !== reticleSizeY
    || standardReticleFromProps.reticle[0].length !== reticleSizeX;
    const reticleSiteAttributesMeta: ReticleSiteAttributeMeta[] = shouldInitializeNew ? [{
      applicableForSites: [],
      isApplicableForAllSites: true,
      columnMeta: {
        columnLabel: 'Site Number',
        columnName: 'siteNumber',
        type: MetaColumnType.DECIMAL,
        dropdownItems: [],
        minValue: 1,
        maxValue: 100000000,
        textLength: 0,
      },
    }] : _.cloneDeep(standardReticleFromProps.reticleSiteAttributesMeta);
    return {
      WATPCMSite: shouldInitializeNew ? STANDARD_RETICLE_WATPCM_SITE_LIST[0][0] : standardReticleFromProps.WATPCMSite,
      reticle: shouldInitializeNew ? this.initializeNewReticle(reticleSizeX, reticleSizeY) : _.cloneDeep(standardReticleFromProps.reticle),
      hasSiteAttributes: shouldInitializeNew ? false : standardReticleFromProps.hasSiteAttributes,
      reticleSiteAttributesMeta,
      siteAttributesData: shouldInitializeNew ? this.initializeNewSiteData(reticleSizeX, reticleSizeY, reticleSiteAttributesMeta) : _.cloneDeep(standardReticleFromProps.siteAttributesData),
    };
  };

  initializeNewReticle = (reticleSizeX: number, reticleSizeY: number) => {
    const reticle: any[] = [];
    for (let retVal = 1, i = 0; i < reticleSizeY; i += 1) {
      reticle.push([]);
      for (let j = 0; j < reticleSizeX; j += 1, retVal += 1) {
        reticle[i].push(retVal);
      }
    }
    return reticle;
  };

  initializeNewSiteData = (reticleSizeX: number, reticleSizeY: number, reticleSiteAttributesMeta: ReticleSiteAttributeMeta[]) => {
    const siteAttributesData: any[] = [];
    for (let i = 1; i <= reticleSizeX * reticleSizeY; i += 1) {
      const siteAttribute: any = {};
      for (let j = 0; j < reticleSiteAttributesMeta.length; j += 1) {
        let value: any = '';
        if (reticleSiteAttributesMeta[j].columnMeta.columnName === 'siteNumber') {
          value = i;
        }
        siteAttribute[reticleSiteAttributesMeta[j].columnMeta.columnName] = value;
      }
      siteAttributesData.push(siteAttribute);
    }
    return siteAttributesData;
  };

  onChangeReticleNumbers = (value: number, flatIndex: number) => {
    const { reticleSizeX, reticleSizeY } = this.props;
    if (value <= (reticleSizeX || 0) * (reticleSizeY || 0) && value > 0) {
      this.setState((prevState: StandardReticleEditorState) => {
        const newStandardReticle = _.cloneDeep(prevState.standardReticle);
        if (!newStandardReticle) return { standardReticle: prevState.standardReticle };
        newStandardReticle.reticle[Math.floor(flatIndex / reticleSizeX)][flatIndex % reticleSizeX] = value;
        return {
          standardReticle: newStandardReticle,
        };
      });
    }
  };

  isSiteNumberRepeated = (reticle: number[][]) => {
    const isFound = new Array(reticle.length * reticle[0].length).fill(false);
    for (let i = 0; i < reticle.length; i += 1) {
      for (let j = 0; j < reticle[i].length; j += 1) {
        if (!isFound[reticle[i][j] - 1]) {
          isFound[reticle[i][j] - 1] = true;
        } else return true;
      }
    }
    return false;
  };

  areUnsavedChangesNotifiedToUser = async () => {
    let canProceed = false;
    if (this.datagrid) {
      if (!UtilityFunctions.isDataGridInEditMode(this.datagrid)) {
        canProceed = true;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (await ModalPopup.confirm({
          header: 'Changes Unsaved',
          body: 'You have unsaved changes in the reticle site attributes. Do you want to proceed?',
        })) {
          canProceed = true;
        }
      }
    } else {
      canProceed = true;
    }
    return canProceed;
  };

  onSaveStandardReticle = async () => {
    const { standardReticle } = this.state;
    const { onSaveStandardReticle } = this.props;

    if (standardReticle && !this.isSiteNumberRepeated(standardReticle.reticle)) {
      if (await this.areUnsavedChangesNotifiedToUser()) {
        this.setState({ watPCMSiteError: '' }, () => {
          onSaveStandardReticle(standardReticle);
        });
      }
    } else {
      this.setState({ watPCMSiteError: 'Site numbers can not be repeated' });
    }
  };

  onChangeStandardReticleData = (key: string, value: any) => {
    this.setState((prevState: StandardReticleEditorState) => {
      if (!prevState.standardReticle) return { standardReticle: prevState.standardReticle };
      if (key in prevState.standardReticle) {
        const newStandardReticle = _.cloneDeep(prevState.standardReticle);
        newStandardReticle[key] = value;
        return { standardReticle: newStandardReticle };
      }
      return { standardReticle: prevState.standardReticle };
    });
  };

  getSiteAttributesDatagridColumns = () => {
    const { standardReticle } = this.state;
    return standardReticle?.reticleSiteAttributesMeta.map((field: ReticleSiteAttributeMeta, index: number) => {
      let dataType = 'string';
      if (field.columnMeta.type === MetaColumnType.DATETIME) dataType = 'date';
      else if (field.columnMeta.type === MetaColumnType.BOOL) dataType = 'boolean';
      else if (field.columnMeta.type === MetaColumnType.DECIMAL || field.columnMeta.type === MetaColumnType.LONG) dataType = 'number';
      // eslint-disable-next-line no-undef-init
      let editCellRender = undefined;
      // eslint-disable-next-line no-undef-init
      let cellRender = undefined;
      if (field.columnMeta.type === MetaColumnType.DROPDOWN) {
        editCellRender = (cell: any) => {
          return (
            <SelectBox
              defaultValue={cell.value}
              items={field.columnMeta.dropdownItems}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cell.column.lookup}
              onValueChanged={(event) => { cell.setValue(event.value); }}
            />
          );
        };
      } else if (field.columnMeta.type === MetaColumnType.COLOR) {
        editCellRender = (cell: any) => {
          return (
            <ColorBox
              defaultValue={cell.value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cell.column.lookup}
              onValueChanged={(event) => { cell.setValue(event.value); }}
            />
          );
        };
        cellRender = (e: any) => {
          if (e.data) {
            return <div className="h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: (e.data[field.columnMeta.columnName]) }} />;
          }
          return null;
        };
      }
      return (
        <Column
          setCellValue={(newData: any, value: any) => {
          // eslint-disable-next-line no-param-reassign
            newData[field.columnMeta.columnName] = value;
          }}
          key={`${field.columnMeta.columnName}${index}`}
          dataField={field.columnMeta.columnName}
          dataType={dataType}
          caption={UtilityFunctions.capitalizeFirstLetter(field.columnMeta.columnLabel)}
          editCellRender={editCellRender}
          cellRender={cellRender}
          alignment="left"
          allowEditing={field.columnMeta.columnName !== 'siteNumber'}
        >
          { dataType === 'number' && field.columnMeta.columnName !== 'siteNumber'
            ? (
              <RangeRule
                message={`Value must be between ${field.columnMeta.minValue} and ${field.columnMeta.maxValue}`}
                min={+field.columnMeta.minValue}
                max={+field.columnMeta.maxValue}
              />
            )
            : null}
          { dataType === 'string'
            ? (
              <StringLengthRule
                max={+field.columnMeta.textLength}
                message={`Value Should Not Exceed ${field.columnMeta.textLength} Characters`}
              />
            )
            : null}
        </Column>
      );
    });
  };

  render() {
    const { standardReticle, watPCMSiteError } = this.state;
    const { reticleSizeX, reticleSizeY } = this.props;
    if (!standardReticle || standardReticle.reticle.length !== reticleSizeY || standardReticle.reticle[0].length !== reticleSizeX) {
      return (
        <div />
      );
    }
    const reticleGridItems: any = [];
    let WATPCMSiteList = [['N/A', 'N/A']];
    if (standardReticle.reticle.length > 0) {
      for (let i = 0; i < (reticleSizeX || 0) * (reticleSizeY || 0); i += 1) {
        WATPCMSiteList.push([(i + 1).toString(), (i + 1).toString()]);
        const currVal = standardReticle.reticle[Math.floor(i / reticleSizeX)][i % reticleSizeX];
        reticleGridItems.push(
          <div key={i} className="reticle-grid-item" style={{ minWidth: '60px' }}>
            <Textbox
              autoComplete="off"
              type="number"
              name="1"
              style={{ border: currVal.toString() === standardReticle.WATPCMSite ? '3px solid red' : '' }}
              value={currVal}
              placeholder=""
              onChange={(e: any) => { this.onChangeReticleNumbers(+e.target.value, i); }}
            />
          </div>,
        );
      }
    }
    WATPCMSiteList = WATPCMSiteList.concat(STANDARD_RETICLE_WATPCM_SITE_LIST);
    return (
      <div>
        <div className="custom-form">
          <Label
            labelTitle="WAT/PCM Site"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              full
              variant="clear"
              list={WATPCMSiteList}
              selectedValue={standardReticle.WATPCMSite}
              onChange={(value: any) => { this.onChangeStandardReticleData('WATPCMSite', value); }}
            />
          </Label>
          <div
            className="reticle-grid-container"
            style={{
              borderTop: standardReticle.WATPCMSite === 'Street Top' ? '3px solid red' : '',
              borderBottom: standardReticle.WATPCMSite === 'Street Bottom' ? '3px solid red' : '',
              borderLeft: standardReticle.WATPCMSite === 'Street Left' ? '3px solid red' : '',
              borderRight: standardReticle.WATPCMSite === 'Street Right' ? '3px solid red' : '',
              gridTemplateColumns: Array((reticleSizeX || 0) + 1).join('auto '),
            }}
          >
            {reticleGridItems}
          </div>
        </div>
        <ErrorLabel
          error={watPCMSiteError}
          isTouched
        />
        <div className="mt15 mb15">
          <CheckBox
            className="mr15"
            value={standardReticle.hasSiteAttributes}
            onValueChange={(value: boolean) => { this.onChangeStandardReticleData('hasSiteAttributes', value); }}
          />
          {' '}
          Reticle Site Attributes
        </div>
        <div>
          { standardReticle.hasSiteAttributes && (
            <>
              <div className="float-right">
                <CreateDynamicColumn
                  needExpression={false}
                  isReticleSiteAttribute
                  getColumnInfo={(columnInfo: any, attributeApplicationType?: AttributeApplicationType) => {
                    this.setState((prevState: StandardReticleEditorState) => {
                      if (!prevState.standardReticle) return { standardReticle: prevState.standardReticle };
                      const newStandardReticle = _.cloneDeep(prevState.standardReticle);
                      let type = MetaColumnType.VARCHAR;
                      if (columnInfo.columnType === 'number') {
                        type = MetaColumnType.DECIMAL;
                      } else if (columnInfo.columnType === 'date') {
                        type = MetaColumnType.DATETIME;
                      } else if (columnInfo.columnType === 'boolean') {
                        type = MetaColumnType.BOOL;
                      }
                      let applicableForSites: any[] = [];
                      if (this.datagrid) {
                        applicableForSites = attributeApplicationType === AttributeApplicationType.APPLY_TO_ALL ? [] : _.cloneDeep(this.datagrid!.instance.getSelectedRowKeys());
                      }

                      const newSiteAttributeMeta: ReticleSiteAttributeMeta = {
                        columnMeta: {
                          columnLabel: columnInfo.columnLabel,
                          columnName: columnInfo.columnName,
                          type,
                          textLength: columnInfo.textLength,
                          minValue: columnInfo.minValue,
                          maxValue: columnInfo.maxValue,
                          dropdownItems: [],
                        },
                        isApplicableForAllSites: attributeApplicationType === AttributeApplicationType.APPLY_TO_ALL,
                        applicableForSites,
                      };
                      newStandardReticle.reticleSiteAttributesMeta.push(newSiteAttributeMeta);
                      return {
                        standardReticle: newStandardReticle,
                      };
                    });
                  }}
                  buttonContent="Add Atrribute"
                />
              </div>
              <DataGrid
                showBorders
                errorRowEnabled={false}
                columnMinWidth={100}
                ref={(datagrid: any) => { this.datagrid = datagrid; }}
                style={{ marginTop: '0px', clear: 'both' }}
                dataSource={standardReticle.siteAttributesData}
                hoverStateEnabled
                height="400px"
                width="1000px"
                allowColumnReordering
                rowAlternationEnabled
                keyExpr="siteNumber"
                showColumnLines={false}
                onCellPrepared={(cellElement: any) => {
                  if (cellElement.rowType === 'data') {
                    const column: any = standardReticle.reticleSiteAttributesMeta.filter((c: ReticleSiteAttributeMeta) => { return c.columnMeta.columnName === cellElement.column.dataField; });
                    if (column.length > 0 && !column[0].isApplicableForAllSites && !column[0].applicableForSites.includes(cellElement.key)) {
                      // eslint-disable-next-line no-param-reassign
                      cellElement.cellElement.innerHTML = '<div style="padding-top: 10px; padding-bottom: 10px; background-color: #ccc; text-align: center;"></div>';
                    }
                  }
                }}
              >
                <FilterRow visible />
                <Scrolling mode="standard" />
                <Selection
                  mode="multiple"
                  selectAllMode
                />
                {this.getSiteAttributesDatagridColumns()}
                <Editing
                  useIcons
                  allowUpdating
                  confirmDelete={false}
                  mode="row"
                />
              </DataGrid>
            </>
          )}
        </div>
        <Button
          onClick={() => { this.onSaveStandardReticle(); }}
          size="sm"
          variant="primary"
          className="float-right mt10 mb30"
        >
          Apply
        </Button>
      </div>
    );
  }
}
