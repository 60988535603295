import { IExportDataOptionsDto, IdefaultWidget } from "interfaces";
import { toast } from "react-toastify";
import { httpRawData } from "services/http.raw-data";
import { v4 as uuidv4 } from "uuid";
import { SeriesTypeNames } from "views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces";
import { ActionType } from "views/individual-reports/PublishSubscribeBinder";
import { IEnrichmentParameter } from "views/policies/EnrichmentParameterBlock";

type CustomCookieTypes =
  | "AUTH_TOKEN"
  | "USER_DETAILS"
  | "INIT_USER_STATS"
  | "SCW_DATA_POINT_LIMIT"
  | "DATABASE";
const customCookieKeys: { [key in CustomCookieTypes]: string } = {
  AUTH_TOKEN: "authToken",
  USER_DETAILS: "userDetails",
  INIT_USER_STATS: "initUserStats",
  SCW_DATA_POINT_LIMIT: "scwDataPointLimit",
  DATABASE: "database",
};
let contextSessionId: any = uuidv4();
let contextSaveDto: any = {
  workCenterTypes: [],
};
let scwContext: any = {
  advancedFilter: "",
  expressions: [],
  selections: [],
  testParameterConditions: {},
};
let scwSelections: any[] = [];

export const SCWidgetsList: IdefaultWidget[] = [];
export const ReportDataTypeConstants = {
  GRAPH: "graphData",
  LEGEND: "legendData",
  HEADER: "headerData",
  MODIFIERS: "reportModifiersData",
  REPORT_CLOSING: "isClosedSomeReport",
};

export const ReportTypeConstants: { [key in any]: string } = {
  POWER_VIEW_PROGRESS_BAR: "POWER_VIEW_PROGRESS_BAR",
  BIN_PARETO: "BIN_PARETO",
  BIN_PARETO_LEGEND: "BIN_PARETO_LEGEND",
  BIN_WAFER_MAP: "BIN_WAFER_MAP",
  BIN_WAFER_MAP_LEGEND: "BIN_WAFER_MAP_LEGEND",
  BIN_WAFER_MAP_COMPARISON: "BIN_WAFER_MAP_COMPARISON",
  BIN_WAFER_MAP_COMPARISON_LEGEND: "BIN_WAFER_MAP_COMPARISON_LEGEND",
  PARAMETRIC_FAILURE: "PARAMETRIC_FAILURE",
  PARAMETRIC_FAILURE_LEGEND: "PARAMETRIC_FAILURE_LEGEND",
  PARAMETRIC_TREND: "PARAMETRIC_TREND",
  PARAMETRIC_TREND_LEGEND: "PARAMETRIC_TREND_LEGEND",
  PARAMETRIC_HISTOGRAM: "PARAMETRIC_HISTOGRAM",
  PARAMETRIC_HISTOGRAM_LEGEND: "PARAMETRIC_HISTOGRAM_LEGEND",
  PARAMETRIC_WAFER_MAP: "PARAMETRIC_WAFER_MAP",
  PARAMETRIC_WAFER_MAP_LEGEND: "PARAMETRIC_WAFER_MAP_LEGEND",
  PARAMETRIC_BOX_PLOT: "PARAMETRIC_BOX_PLOT",
  PARAMETRIC_BOX_PLOT_LEGEND: "PARAMETRIC_BOX_PLOT_LEGEND",
  PARAMETRIC_XY_SCATTER_PLOT: "PARAMETRIC_XY_SCATTER_PLOT",
  PARAMETRIC_XY_SCATTER_PLOT_LEGEND: "PARAMETRIC_XY_SCATTER_PLOT_LEGEND",
  RAW_DATA_GRID: "RAW_DATA_GRID",
  REPORT_HEADER_CHOOSER: "REPORT_HEADER_CHOOSER",
  SPC_TREND: "SPC_TREND",
  SPC_TREND_LEGEND: "SPC_TREND_LEGEND",
  GENERAL_ACTION: "GENERAL_ACTION",
  DATA_SUMMARY: "DATA_SUMMARY",
};

const Constants = {
  SCW_CONTEXT: "scwContext",
  SCW_SELECTIONS: "scwSelections",
  CONTEXT_SESSION_ID: "contextSessionId",
  CONTEXT_SAVE_DTO: "contextSaveDto",
  ALL_GROUPINGS: "All Groupings",
  IS_DATA_EXPORTING: false,
};

const roundNumberAppropriately = (value: number, precision: number) => {
  // if decimal and has whole number element too, round to X decimal places
  if ((value >= 1 || value <= -1) && value % 1 !== 0) {
    return value.toFixed(Math.ceil(precision));
  }
  // if decimal without any whole number, round to X significant figures
  if (value <= 1 && value >= -1 && value % 1 !== 0) {
    return value.toPrecision(Math.ceil(precision));
  }
  // if whole number, don't round
  return value;
};

export const toTitleCase = (value: string, removeUnderscores = true) => {
  let val = value;
  if (removeUnderscores) {
    val = value.replaceAll("_", " ");
  }
  const str = val.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i += 1) {
    if (str[i].length === 2) {
      str[i] = str[i].toUpperCase();
    } else {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
  }
  return str.join(" ");
};

const getCookieValue = (key: CustomCookieTypes) => {
  let cookieValue = "";
  if (document.cookie && document.cookie.length > 0) {
    const cookies = document.cookie.split("; ");
    if (cookies.length > 0) {
      cookies.forEach((c) => {
        const cookieKeyValuePair: any[] = [];
        cookieKeyValuePair.push(c.substr(0, c.indexOf("=")));
        cookieKeyValuePair.push(c.slice(c.indexOf("=") + 1));
        if (
          cookieKeyValuePair[0] === customCookieKeys[key] &&
          cookieKeyValuePair[1] !== undefined &&
          cookieKeyValuePair[1] !== "undefined"
        ) {
          // eslint-disable-next-line prefer-destructuring
          cookieValue = cookieKeyValuePair[1];
        }
      });
    }
  }
  return cookieValue;
};

const appendCookie = (key: CustomCookieTypes, value: string) => {
  document.cookie = `${customCookieKeys[key]}=${value};`;
};
const changeUserDataCookie = (key: CustomCookieTypes, value: string) => {
  appendCookie(key, value);
};

const clearSessionCookies = () => {
  appendCookie("AUTH_TOKEN", "");
  appendCookie("USER_DETAILS", "");
  appendCookie("INIT_USER_STATS", "");
};

const setInLocalStorage = (key: CustomCookieTypes, value: string) => {
  localStorage.setItem(key, value);
};

const setGlobalVars = (varName: string, varVal: any) => {
  switch (varName) {
    case Constants.SCW_CONTEXT:
      scwContext = varVal;
      break;
    case Constants.SCW_SELECTIONS:
      scwSelections = varVal;
      break;
    case Constants.CONTEXT_SAVE_DTO:
      contextSaveDto = varVal;
      break;
    default:
      break;
  }
};

const getGlobalVars = () => {
  return [scwContext, scwSelections, contextSessionId, contextSaveDto];
};

const setInLocalStorageAndRemovePrior = (
  oldKey: string,
  newKey: string,
  value: any
) => {
  const localStorageKey = Object.keys(localStorage);
  localStorageKey.forEach((key: string) => {
    if (key.includes(oldKey)) {
      localStorage.removeItem(key);
    }
  });
  localStorage.setItem(newKey, JSON.stringify(value));
};

const getFromLocalStorage = (key: CustomCookieTypes) => {
  return localStorage.getItem(key) || "";
};

const clearScwVars = () => {
  scwContext = {
    advancedFilter: "",
    expressions: [],
    selections: [],
    testParameterConditions: {},
  };
  scwSelections = [];
};

const clearGlobalVars = () => {
  contextSessionId = uuidv4();
  contextSaveDto = {
    workCenterTypes: [],
  };
  scwContext = {
    advancedFilter: "",
    expressions: [],
    selections: [],
    testParameterConditions: {},
  };
  scwSelections = [];
};

const clearSessionCookiesAndGlobalVars = () => {
  clearGlobalVars();
  clearSessionCookies();
};

const isDuplicateParameterFound = (parameters: IEnrichmentParameter[]) => {
  let isFound = false;
  const parameterNameMap = new Map();
  for (let i = 0; i < parameters.length; i += 1) {
    if (
      parameters[i].expression === undefined ||
      parameters[i].expression?.toString() === ""
    ) {
      isFound = true;
      toast.warning(
        `Empty expression value isn't allowed. Please specify an expression value against '${parameters[i].name}' parameter.`
      );
      break;
    }
    if (parameterNameMap.has(parameters[i].name)) {
      isFound = true;
      toast.warning(
        `Duplicate '${parameters[i].name}' parameter is found. Parameter names should be unique for enrichment.`
      );
      break;
    }
    parameterNameMap.set(parameters[i].name, true);
  }
  return isFound;
};

const isSingleGraph = (reportActor: string, seriesType: SeriesTypeNames) => {
  if (reportActor === ReportTypeConstants.SPC_TREND) {
    return true;
  }
  return !!(
    seriesType === "SINGLE" &&
    (reportActor === ReportTypeConstants.PARAMETRIC_TREND ||
      reportActor === ReportTypeConstants.PARAMETRIC_XY_SCATTER_PLOT)
  );
};

const getAxisReferenceIndex = (
  graphIndex: number,
  isSingleGraphVar: boolean,
  reportActor?: string
) => {
  if (isSingleGraphVar || graphIndex === 0) {
    return "";
  }
  if (reportActor && reportActor === ReportTypeConstants.PARAMETRIC_FAILURE) {
    return graphIndex === 1 ? "" : Math.ceil((graphIndex + 1) / 2);
  }
  return graphIndex + 1;
};

const removeAllCookies = () => {
  const allCookies = document.cookie.split(";");
  for (let i = 0; i < allCookies.length; i += 1) {
    document.cookie = `${allCookies[i]}=;expires=${new Date(0).toUTCString()}`;
  }
};

const getMinVisibilityCriteria = () => {
  return ["DEVICE", "TEST PROGRAM", "TEST PROGRAM REVISION", "LOT", "WAFER"];
};

const getControlTypeUndersoreSeparated = (entityName: string): string => {
  const name = entityName.toLocaleLowerCase();
  switch (name) {
    case "facility":
      return "facility";
    case "workcenter":
      return "work_center";
    case "device":
      return "device";
    case "testprogram":
      return "test_program";
    case "testprogramrevision":
      return "test_program_revision";
    case "lot":
      return "lot";
    case "wafer":
      return "wafer";
    case "testparameter":
      return "test_parameter";
    default:
      return "";
  }
};

const getDataTableFilterFromDataGridFilter = (filterExpression: any[]) => {
  const colName = filterExpression[0];
  const operator = filterExpression[1];
  const value = filterExpression[2];
  const dataType = typeof filterExpression[2];
  let convertedVal = colName;
  switch (true) {
    case operator === "contains" && dataType === "string":
      convertedVal += ` LIKE '%${value}%'`;
      break;
    case operator === "notcontains" && dataType === "string":
      convertedVal += ` NOT LIKE '%${value}%'`;
      break;
    case operator === "startswith" && dataType === "string":
      convertedVal += ` LIKE '${value}%'`;
      break;
    case operator === "endswith" && dataType === "string":
      convertedVal += ` LIKE '%${value}'`;
      break;
    case operator === "=" && dataType === "string":
      convertedVal += ` = '${value}'`;
      break;
    case operator === "<>" && dataType === "string":
      convertedVal += ` <> '${value}'`;
      break;
    case dataType === "number":
      convertedVal += ` ${operator}${value}`;
      break;
    default:
      convertedVal = "";
      break;
  }
  return convertedVal;
};

const getClosestNumberDivisibleBy = (value: number, divisibleBy: number) => {
  const quotient = Math.floor(value / divisibleBy);
  const closestFirst = divisibleBy * quotient;
  const closestSecond =
    value * divisibleBy > 0
      ? divisibleBy * (quotient + 1)
      : divisibleBy * (quotient - 1);
  if (Math.abs(value - closestFirst) < Math.abs(value - closestSecond))
    return closestFirst;
  return closestSecond;
};

const ToSnakeCase = (value: any) => {
  let retVal = value;
  if (value === "Wafer Id" || value === "Lot Id") {
    retVal = value.replaceAll("Id", "Key");
  }
  return (
    retVal &&
    retVal
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x: any) => x.toLowerCase())
      .join("_")
  );
};

const exportDataAsFile = (
  exportDataOptionsDto: IExportDataOptionsDto,
  publisherId: string,
  broadcastEvent: (
    publisherId: string,
    eventNames: string[],
    action: ActionType
  ) => void
) => {
  return httpRawData
    .exportRawDataGridData(exportDataOptionsDto, { responseType: "blob" })
    .then((response: any) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", exportDataOptionsDto.fileSaveName);
        document.body.appendChild(link);
        link.click();
        toast.success("Data successfully exported!");
      } else {
        toast.error("Error while exporting data! Please try again");
      }
      Constants.IS_DATA_EXPORTING = false;
      broadcastEvent(
        publisherId,
        ["GENERAL_ACTION"],
        "HIDE_DATA_EXPORT_SPINNER"
      );
    })
    .catch(() => {
      Constants.IS_DATA_EXPORTING = false;
      broadcastEvent(
        publisherId,
        ["GENERAL_ACTION"],
        "HIDE_DATA_EXPORT_SPINNER"
      );
      toast.error("Error while exporting data! Please try again");
    });
};

export default {
  ReportTypeConstants,
  ReportDataTypeConstants,
  Constants,
  getCookieValue: (key: CustomCookieTypes) => {
    return getCookieValue(key);
  },
  appendCookie: (key: CustomCookieTypes, value: string) => {
    return appendCookie(key, value);
  },
  changeUserDataCookie: (key: CustomCookieTypes, value: string) => {
    return changeUserDataCookie(key, value);
  },
  isDuplicateParameterFound,
  setInLocalStorage,
  setInLocalStorageAndRemovePrior,
  getFromLocalStorage,
  roundNumberAppropriately,
  toTitleCase,
  clearScwVars,
  clearSessionCookiesAndGlobalVars,
  clearSessionCookies,
  clearGlobalVars,
  removeAllCookies,
  isSingleGraph,
  getAxisReferenceIndex,
  setGlobalVars,
  getGlobalVars,
  getMinVisibilityCriteria,
  getDataTableFilterFromDataGridFilter,
  getClosestNumberDivisibleBy,
  getControlTypeUndersoreSeparated,
  SCWidgetsList,
  ToSnakeCase,
  exportDataAsFile,
};
