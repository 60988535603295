import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface IErrorLabelProps {
  error?: string;
  isTouched?: boolean;
  size?: string;
  className?: string | undefined;
}

export const ErrorLabel = (props: IErrorLabelProps) => {
  const {
    error,
    isTouched,
    size,
    className,
  } = props;
  return (
    <span className={`
    error-label 
    flex-${size}
    ${className}
    `}
    >
      {error && isTouched && (
        <>
          <FontAwesomeIcon icon={faExclamationTriangle} className="cursor-pointer mt8 mr10" />
          {error}
        </>
      )}
    </span>
  );
};
ErrorLabel.defaultProps = {
  error: '',
  isTouched: false,
  size: '30',
  className: '',
};

export const addErrorClasses = (error?: string, isTouched?: boolean) => {
  if (error !== undefined && isTouched !== undefined) {
    if (error && isTouched) {
      return 'error-field-outline';
    }
  }
  return '';
};

export const isValid = (error?: string) => {
  if (error !== undefined) {
    if (error) {
      return 'error-field-outline';
    }
  }
  return '';
};
