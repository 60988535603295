/* eslint-disable no-underscore-dangle */
const dataGridConstants = {
  defaultOptions: {
    height: 500,
    showRowLines: true,
    showColumnLines: true,
    showBorders: true,
    columnAutoWidth: true,
  },

  editingOptions: {
    mode: 'row',
    newRowPosition: 'last',
    confirmDelete: false,
    useIcons: true,
  },
  scrollingOptions: {
    mode: 'standard',
  },
  pagerOptions: {
    showPageSizeSelector: true,
    allowedPageSizes: [5, 10, 20, 50],
  },
  pagingOptions: {
    defaultPageSize: 10,
  },
  sortingOptions: {
    mode: 'multiple',
  },
}

// fixes column chooser position in dxDatagrid or dxTreeView closer to the button, call it inside the onContentReady event
const fixColumnChooserPosition = (e: any) => {
  const columnChooserView = e.component.getView('columnChooserView');
  // initialize the popup container if it is not initialized yet
  if (!columnChooserView._popupContainer) {
    columnChooserView._initializePopupContainer();
    columnChooserView.render();
  }
  columnChooserView._popupContainer.option('position', {
    of: e.element, my: 'right top', at: 'right top', offset: '0 50'
  });
}

export default dataGridConstants;
export { fixColumnChooserPosition };
