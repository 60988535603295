import React from 'react';
import DrilledBinHistogramReport from 'views/individual-reports/customized-report/DrilledBinHistogramReport';
import { ScrollView } from 'devextreme-react';

import { IDrilledGraphData, IExpressionsList, } from 'interfaces';

interface IGalleryProps {
  galleryData?: IDrilledGraphData[];
  generateReport: (filters:IExpressionsList[]) => void;
}

class Gallery extends React.Component<IGalleryProps, any> {
  private parseFilter : IExpressionsList[] = [];

  updateComponent = () => {
    this.forceUpdate();
    this.parseFilter = [];
    const { galleryData, generateReport } = this.props;
    if (galleryData) {
      galleryData.forEach((gallery) => {
        this.populateParsedFilter(gallery);
      });
    }
    generateReport(this.parseFilter);
  };

  populateParsedFilter = (group:IDrilledGraphData) => {
    const { parseFilter } = this;
    if (group.entityType && group.isSelected) {
      parseFilter.push({
        columnName: group.groupKey,
        controlType: group.entityType,
        dataType: typeof (group.name),
        groupConditionOn: 'OR',
        operationName: '=',
        queryText: group.name,
      });
    }
    if (group.groups.length > 0) {
      group.groups.forEach((g) => {
        this.populateParsedFilter(g);
      });
    }
  };

  render() {
    const { galleryData } = this.props;
    return (
      <ScrollView
        width="100%"
        useNative
        direction="horizontal"
        showScrollbar="onScroll"
        scrollByThumb
        reachBottomText=""
        scrollByContent
      >
        <DrilledBinHistogramReport
          updateComponent={this.updateComponent}
          data={(galleryData as IDrilledGraphData[])}
          isGalleryView
          className="pt20 pb20"
          showConnections
        />
      </ScrollView>
    );
  }
}

export default Gallery;
