import { Viewport } from '../../wafer-map/web-gl-utils/Types';

export class GLUtility {
  public static getShader = (gl: WebGL2RenderingContext, shaderSource: string, shaderType: number) => {
    const shader: WebGLShader = gl.createShader(shaderType) as WebGLShader;
    if (!shader) return null;
    gl.shaderSource(shader, shaderSource);
    gl.compileShader(shader);
    return shader;
  };

  public static getProgram = (gl: WebGL2RenderingContext, vertexShaderSource: string, fragmentShaderSource: string): WebGLProgram | null => {
    const vs: WebGLShader = GLUtility.getShader(gl, vertexShaderSource, gl.VERTEX_SHADER) as WebGLShader;
    const fs: WebGLShader = GLUtility.getShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER) as WebGLShader;
    if (!vs || !fs) return null;
    const program: WebGLProgram = gl.createProgram() as WebGLProgram;
    gl.attachShader(program, vs);
    gl.attachShader(program, fs);
    gl.linkProgram(program);
    return program;
  };

  public static createAndBindBuffer = (gl: WebGL2RenderingContext, bufferType: number, typeOfDrawing: number, data: ArrayBuffer): WebGLBuffer => {
    const buffer: WebGLBuffer = gl.createBuffer() as WebGLBuffer; // allocates some memory in gpu
    gl.bindBuffer(bufferType, buffer); // bind allocated memory with the channel
    gl.bufferData(bufferType, data, typeOfDrawing); // use this channel and send data to GPU
    gl.bindBuffer(bufferType, null); // Dislocating memory
    return buffer;
  };

  public static linkGPUAndCPU = (obj: {
    program: WebGLProgram,
    gpuVariable: string,
    channel?: number,
    buffer: WebGLBuffer,
    dims: number,
    dataType?: number,
    normalize?: boolean,
    stride?: 0,
    offset?: 0
  }, gl: WebGL2RenderingContext) => {
    const position: GLint = gl.getAttribLocation(obj.program, obj.gpuVariable);
    gl.enableVertexAttribArray(position);
    gl.bindBuffer(obj.channel || gl.ARRAY_BUFFER, obj.buffer);
    gl.vertexAttribPointer(
      position,
      obj.dims,
      obj.dataType || gl.FLOAT,
      obj.normalize || false,
      obj.stride || 0,
      obj.offset || 0,
    );
    return position;
  };

  public static m4 = {
    projection(width: number, height: number, depth: number) {
      // Note: This matrix flips the Y axis so 0 is at the top.
      return [
        2 / width, 0, 0, 0,
        0, -2 / height, 0, 0,
        0, 0, 2 / depth, 0,
        -1, 1, 0, 1,
      ];
    },
    multiply(a: number[], b: number[]) {
      const a00 = a[0 * 4 + 0];
      const a01 = a[0 * 4 + 1];
      const a02 = a[0 * 4 + 2];
      const a03 = a[0 * 4 + 3];
      const a10 = a[1 * 4 + 0];
      const a11 = a[1 * 4 + 1];
      const a12 = a[1 * 4 + 2];
      const a13 = a[1 * 4 + 3];
      const a20 = a[2 * 4 + 0];
      const a21 = a[2 * 4 + 1];
      const a22 = a[2 * 4 + 2];
      const a23 = a[2 * 4 + 3];
      const a30 = a[3 * 4 + 0];
      const a31 = a[3 * 4 + 1];
      const a32 = a[3 * 4 + 2];
      const a33 = a[3 * 4 + 3];
      const b00 = b[0 * 4 + 0];
      const b01 = b[0 * 4 + 1];
      const b02 = b[0 * 4 + 2];
      const b03 = b[0 * 4 + 3];
      const b10 = b[1 * 4 + 0];
      const b11 = b[1 * 4 + 1];
      const b12 = b[1 * 4 + 2];
      const b13 = b[1 * 4 + 3];
      const b20 = b[2 * 4 + 0];
      const b21 = b[2 * 4 + 1];
      const b22 = b[2 * 4 + 2];
      const b23 = b[2 * 4 + 3];
      const b30 = b[3 * 4 + 0];
      const b31 = b[3 * 4 + 1];
      const b32 = b[3 * 4 + 2];
      const b33 = b[3 * 4 + 3];
      return [
        b00 * a00 + b01 * a10 + b02 * a20 + b03 * a30,
        b00 * a01 + b01 * a11 + b02 * a21 + b03 * a31,
        b00 * a02 + b01 * a12 + b02 * a22 + b03 * a32,
        b00 * a03 + b01 * a13 + b02 * a23 + b03 * a33,
        b10 * a00 + b11 * a10 + b12 * a20 + b13 * a30,
        b10 * a01 + b11 * a11 + b12 * a21 + b13 * a31,
        b10 * a02 + b11 * a12 + b12 * a22 + b13 * a32,
        b10 * a03 + b11 * a13 + b12 * a23 + b13 * a33,
        b20 * a00 + b21 * a10 + b22 * a20 + b23 * a30,
        b20 * a01 + b21 * a11 + b22 * a21 + b23 * a31,
        b20 * a02 + b21 * a12 + b22 * a22 + b23 * a32,
        b20 * a03 + b21 * a13 + b22 * a23 + b23 * a33,
        b30 * a00 + b31 * a10 + b32 * a20 + b33 * a30,
        b30 * a01 + b31 * a11 + b32 * a21 + b33 * a31,
        b30 * a02 + b31 * a12 + b32 * a22 + b33 * a32,
        b30 * a03 + b31 * a13 + b32 * a23 + b33 * a33,
      ];
    },

    translation(tx: number, ty: number, tz: number) {
      return [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        tx, ty, tz, 1,
      ];
    },

    xRotation(rotation: number[]) {
      const c = rotation[1];
      const s = rotation[0];
      return [
        1, 0, 0, 0,
        0, c, s, 0,
        0, -s, c, 0,
        0, 0, 0, 1,
      ];
    },

    yRotation(rotation: number[]) {
      const c = rotation[1];
      const s = rotation[0];
      return [
        c, 0, -s, 0,
        0, 1, 0, 0,
        s, 0, c, 0,
        0, 0, 0, 1,
      ];
    },

    zRotation(rotation: number[]) {
      const c = rotation[1];
      const s = rotation[0];
      return [
        c, -s, 0, 0,
        s, c, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1,
      ];
    },
  };

  public static renderShape = (
    data: {
      coords: number[],
      shapeType: GLenum,
      gl: WebGL2RenderingContext,
      program: WebGLProgram,
      viewport: { x: number, y: number, width: number, height: number },
      resolution: { width: number, height: number },
      rotation: number[],
      color?: number[],
      coordsBasedColors?: number[],
    },
  ) => {
    const projectionMatrix = GLUtility.m4.projection(data.resolution.width, data.resolution.height, 1500);
    let matrix = GLUtility.m4.multiply(projectionMatrix, GLUtility.m4.translation(data.resolution.width / 2, data.resolution.height / 2, 0));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.zRotation(data.rotation));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.translation(-data.resolution.width / 2, -data.resolution.height / 2, 0));

    data.gl.useProgram(data.program);
    const buffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(data.coords));
    GLUtility.linkGPUAndCPU(
      {
        program: data.program,
        gpuVariable: 'position',
        channel: data.gl.ARRAY_BUFFER,
        buffer,
        dims: 3,
        dataType: data.gl.FLOAT,
        normalize: false,
        stride: 0,
        offset: 0,
      },
      data.gl,
    );
    if (data.coordsBasedColors) {
      const colorBuffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(data.coordsBasedColors));
      GLUtility.linkGPUAndCPU(
        {
          program: data.program,
          gpuVariable: 'a_coords_based_colors',
          channel: data.gl.ARRAY_BUFFER,
          buffer: colorBuffer,
          dims: 4,
          dataType: data.gl.FLOAT,
          normalize: false,
          stride: 0,
          offset: 0,
        },
        data.gl,
      );
    }
    if (data.color) {
      data.gl.uniform4fv(data.gl.getUniformLocation(data.program, 'u_color'), data.color);
    }
    data.gl.uniformMatrix4fv(data.gl.getUniformLocation(data.program, 'u_matrix'), false, matrix);
    data.gl.viewport(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.enable(data.gl.SCISSOR_TEST);
    data.gl.scissor(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.drawArrays(data.shapeType, 0, data.coords.length / 3);

    data.gl.viewport(0, 0, data.gl.canvas.width, data.gl.canvas.height);
    data.gl.disable(data.gl.SCISSOR_TEST);
  };

  public static createAndBindTexture = (gl: WebGL2RenderingContext, image: HTMLImageElement): WebGLTexture => {
    const texture: WebGLTexture = gl.createTexture() as WebGLTexture;
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  };

  public static renderImage = (
    data: {
      coords: number[],
      gl: WebGL2RenderingContext,
      program: WebGLProgram,
      viewport: { x: number, y: number, width: number, height: number },
      resolution: { width: number, height: number },
      rotation: number[],
      textureImage: HTMLImageElement,
    },
  ) => {
    const projectionMatrix = GLUtility.m4.projection(data.resolution.width, data.resolution.height, 1500);
    let matrix = GLUtility.m4.multiply(projectionMatrix, GLUtility.m4.translation(data.resolution.width / 2, data.resolution.height / 2, 0));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.zRotation(data.rotation));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.translation(-data.resolution.width / 2, -data.resolution.height / 2, 0));

    data.gl.useProgram(data.program);
    const buffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(data.coords));
    const uImageTexture = GLUtility.createAndBindTexture(data.gl, data.textureImage);
    const textureCoords = [
      0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 0,
    ];
    const textureBuffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(textureCoords));
    GLUtility.linkGPUAndCPU({
      program: data.program,
      gpuVariable: 'textCoords',
      channel: data.gl.ARRAY_BUFFER,
      buffer: textureBuffer,
      dims: 2,
      dataType: data.gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, data.gl);
    GLUtility.linkGPUAndCPU({
      program: data.program,
      gpuVariable: 'position',
      channel: data.gl.ARRAY_BUFFER,
      buffer,
      dims: 3,
      dataType: data.gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, data.gl);
    const uImageLocation = data.gl.getUniformLocation(data.program, 'uImage');
    data.gl.uniformMatrix4fv(data.gl.getUniformLocation(data.program, 'u_matrix'), false, matrix);
    data.gl.uniform1i(uImageLocation, 0);
    data.gl.activeTexture(data.gl.TEXTURE0 + 0);
    data.gl.bindTexture(data.gl.TEXTURE_2D, uImageTexture);
    data.gl.viewport(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.enable(data.gl.SCISSOR_TEST);
    data.gl.scissor(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.drawArrays(data.gl.TRIANGLES, 0, 6);
    data.gl.viewport(0, 0, data.gl.canvas.width, data.gl.canvas.height);
    data.gl.disable(data.gl.SCISSOR_TEST);
  };

  public static renderCircle = (
    data: {
      coords: number[],
      gl: WebGL2RenderingContext,
      program: WebGLProgram,
      viewport: { x: number, y: number, width: number, height: number },
      resolution: { width: number, height: number },
      rotation: number[],
      coordsBasedColors: number[],
    },
  ) => {
    const projectionMatrix = GLUtility.m4.projection(data.resolution.width, data.resolution.height, 1500);
    let matrix = GLUtility.m4.multiply(projectionMatrix, GLUtility.m4.translation(data.resolution.width / 2, data.resolution.height / 2, 0));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.zRotation(data.rotation));
    matrix = GLUtility.m4.multiply(matrix, GLUtility.m4.translation(-data.resolution.width / 2, -data.resolution.height / 2, 0));

    data.gl.useProgram(data.program);
    const buffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(data.coords));
    const textureCoords = [];
    for (let i = 0; i < data.coords.length / 3; i += 1) {
      textureCoords.push(0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 0);
    }
    const textureBuffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(textureCoords));
    GLUtility.linkGPUAndCPU({
      program: data.program,
      gpuVariable: 'textCoords',
      channel: data.gl.ARRAY_BUFFER,
      buffer: textureBuffer,
      dims: 2,
      dataType: data.gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, data.gl);
    GLUtility.linkGPUAndCPU({
      program: data.program,
      gpuVariable: 'position',
      channel: data.gl.ARRAY_BUFFER,
      buffer,
      dims: 3,
      dataType: data.gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, data.gl);
    const colorBuffer = GLUtility.createAndBindBuffer(data.gl, data.gl.ARRAY_BUFFER, data.gl.STATIC_DRAW, new Float32Array(data.coordsBasedColors));
    GLUtility.linkGPUAndCPU(
      {
        program: data.program,
        gpuVariable: 'a_coords_based_colors',
        channel: data.gl.ARRAY_BUFFER,
        buffer: colorBuffer,
        dims: 4,
        dataType: data.gl.FLOAT,
        normalize: false,
        stride: 0,
        offset: 0,
      },
      data.gl,
    );
    data.gl.uniformMatrix4fv(data.gl.getUniformLocation(data.program, 'u_matrix'), false, matrix);
    data.gl.viewport(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.enable(data.gl.SCISSOR_TEST);
    data.gl.scissor(data.viewport.x, data.viewport.y, data.viewport.width, data.viewport.height);
    data.gl.drawArrays(data.gl.TRIANGLES, 0, data.coords.length / 3);
    data.gl.viewport(0, 0, data.gl.canvas.width, data.gl.canvas.height);
    data.gl.disable(data.gl.SCISSOR_TEST);
  };

  public static clearViewport = (gl: WebGL2RenderingContext, viewport: Viewport) => {
    gl.viewport(viewport.x, viewport.y, viewport.width, viewport.height);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(viewport.x, viewport.y, viewport.width, viewport.height);
    gl.clear(
      // eslint-disable-next-line no-bitwise
      gl!.DEPTH_BUFFER_BIT
      | gl!.COLOR_BUFFER_BIT,
    );
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.disable(gl.SCISSOR_TEST);
  };

  public static clearAllCanvases = (gl: WebGL2RenderingContext, textCtx: CanvasRenderingContext2D) => {
    // eslint-disable-next-line no-bitwise
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    textCtx.clearRect(0, 0, textCtx.canvas.width, textCtx.canvas.width);
  };

  public static clearTextViewport = (textCtx: CanvasRenderingContext2D, viewport: Viewport): void => {
    // this rect is : [0, text canvas width] +, [0, text canvas height] +
    textCtx.clearRect(
      viewport.x,
      viewport.y,
      viewport.width,
      viewport.height,
    );
  };

  public static prepareRectVec = (startX: number, startY: number, endX: number, endY: number): number[] => {
    return [
      startX, startY, 0,
      endX, startY, 0,
      startX, endY, 0,
      startX, endY, 0,
      endX, startY, 0,
      endX, endY, 0,
    ];
  };

  public static prepareRectVecForTexture = (startX: number, startY: number, endX: number, endY: number): number[] => {
    return [
      startX, startY, 0,
      endX, startY, 0,
      startX, endY, 0,
      startX, endY, 0,
      endX, endY, 0,
      endX, startY, 0,
    ];
  };
}
