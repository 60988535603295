import React from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import GeneralUtils from 'GeneralUtils'
import { emptyDefaultSelectionCriteria, IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';
import Button from '../../../wrapped-component/hint-controls/Button';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import {
  DefaultPolicyStepSettings, IdefaultWidget, IPolicyStep, ISWMPolicyStepSettings, ISWMSource,
} from '../../../../interfaces';
import { httpService } from '../../../../services/http.service';
import SelectionCriteriaModal from '../../../selection-criteria/selection-criteria-modal';
import Label from '../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import Checkbox from '../../../wrapped-component/hint-controls/Checkbox';
import Hint from '../../../wrapped-component/hint-controls/Hint/Hint';

interface ISWMPolicyStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  updatePolicyStepExpressions: (step: any) => void;
  stepSettings?: any;
  updatedCreated: any;
  toggleShowRulesPanel: () => void;
  showRulesPanel: boolean;
  rulesCount: number;
  policySteps: IPolicyStep[];

}

class SWMPolicyStepSettings extends React.Component<ISWMPolicyStepSettingsProps, any> {
  private readonly policyStepSettingsReference: any;

  // private currentPolicyItem = ['CURRENT', 'Current'];

  // private CURRENT = 'CURRENT';

  private widgetsList : IdefaultWidget[] = [
    {
      controlType: 'Facility',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'facility') || {} as IDefaultSelectionCriteria,
    },
    {
      controlType: 'Work Center',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'work_center') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Device',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'device') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Test Program',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'test_program') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Test Program Revision',
      columnWeight: 3,
      isVisible: true,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'test_program_revision') || {} as IDefaultSelectionCriteria,
    },
    {
      controlType: 'Lot',
      columnWeight: 4,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'lot') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Wafer',
      columnWeight: 8,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'wafer') || {} as IDefaultSelectionCriteria,

    },
  ];

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  constructor(props: ISWMPolicyStepSettingsProps) {
    super(props);
    const { stepSettings, policySteps } = this.props;
    // const existingPolicy = policySteps.some((x) => x.Id);
    let swmPolicyStepSettings: ISWMPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.SWM));
    if (stepSettings) {
      swmPolicyStepSettings = stepSettings;
    }
    this.state = {
      swmPolicyStepSettings,
      // policies: [['NONE', 'None'], this.currentPolicyItem],
      // source1PolicySteps: existingPolicy ? policySteps.map((x) => [x.OutputReference, x.OutputReference]) : [],
      // source2PolicySteps: existingPolicy ? policySteps.map((x) => [x.OutputReference, x.OutputReference]) : [],
      policySteps: [['NONE', 'None'], ...policySteps.map((x) => [x.OutputReference, x.OutputReference])],
      showModal: [],
    };
    // this.getPolicies();
    this.policyStepSettingsReference = React.createRef();
    this.renderPrePostConditions = this.renderPrePostConditions.bind(this);
  }

  getPolicies = async () => {
    const { policies } = this.state;
    const returnedPolicies = await httpService.getPolicies();
    returnedPolicies.forEach((x: any) => policies.push([x.id, x.name]));
    this.setState({ policies });
  };

  conditionUpdateHandler = (value: string, flag: string, index: any, key: string) => {
    const { swmPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      swmPolicyStepSettings.preConditions[index][key] = value;
    } else if (flag === 'POST') {
      swmPolicyStepSettings.postConditions[index][key] = value;
    }
    this.setState({
      swmPolicyStepSettings,
    });
    updatePolicyStepSettings(swmPolicyStepSettings);
  };

  deleteCondition = (index: any, flag: string) => {
    const { swmPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      swmPolicyStepSettings.preConditions.splice(index, 1);
    } else if (flag === 'POST') {
      swmPolicyStepSettings.postConditions.splice(index, 1);
    }
    this.setState({
      swmPolicyStepSettings,
    });
    updatePolicyStepSettings(swmPolicyStepSettings);
  };

  renderPrePostConditions = (conditions: any, flag: any) => {
    return conditions.map((condition: any, index: any) => (
      <Label
        labelTitle={`${index + 1}.`}
        labelPosition="left-middle"
        labelSize="10"
        fieldSize="90"
        errorSize="0"
        childrenItemsInline
        className={`d-flex ${index > 0 ? 'border-top pt10' : ''}`}
      >
        <div className="w-100 flex-100 d-flex align-items-center">
          <div className="w-100 flex-80 flex-column">
            <div
              className="mr10 custom-form-inline label-left"
            >
              <span className="label mr10 w30">If</span>
              <Textbox
                containerClassName="w-80"
                defaultValue={condition.condition}
                value={condition.condition}
                className="w-100"
                autoComplete="off"
                name="if"
                placeholder="Condition"
                onBlur={(event:any) => {
                  this.conditionUpdateHandler(event.target.value, flag, index, 'condition');
                }}
              />
            </div>

            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Then</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.thenClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'thenClause');
                }}
              />
            </div>
            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Else</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.elseClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'elseClause');
                }}
              />
            </div>
          </div>

          <div className="flex-20 mt10">
            <Button
              variant="outline-primary"
              className="w43"
              onClick={() => this.deleteCondition(index, flag)}

            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
          </div>
        </div>
      </Label>
    ));
  };

  render() {
    const formValidationSchema = Yup.object({
      testProgramMatchingStrategy: Yup.string(),
      mergeFacility: Yup.string(),
      mergeWorkCenter: Yup.string(),
      mergeDevice: Yup.string(),
    });
    const {
      swmPolicyStepSettings,
      // policies,
      showModal,
      showModal2,
      policySteps,
    } = this.state;
    const {
      updatedCreated,
      updatePolicyStepSettings,
      toggleShowRulesPanel,
      showRulesPanel,
      rulesCount,
      // policySteps,
    } = this.props;
    return (
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          owner: '',
          notificationEmail: '',
          swmPolicyStepSettings,
          updatedCreated,
          policySteps,
        }}
        validationSchema={formValidationSchema}
        onSubmit={() => undefined}
      >
        {({
          handleSubmit, handleChange, values,
        }) => (
          <form
            onChange={(e:any) => {
              handleChange(e);
            }}
            onSubmit={handleSubmit}
            ref={this.policyStepSettingsReference}
            className="custom-form h-100 background-color-primary-background"
          >
            <div className="step-settings-block indent p20 background-color-secondary-background">
              <Container fluid className="h-100 overflow-visible">
                <Row>
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Wafer Metadata</Heading>
                    {/* TODO need to do something about test program matching strategy */}
                    {/* <div className="custom-form-inline label-left"> */}
                    {/*  <span className="label flex-40">Execution Strategy</span> */}
                    {/*  <div className="flex-50"> */}
                    {/*    <CustomizedDropdown */}
                    {/*      full */}
                    {/*      variant="clear" */}
                    {/*      list={TestProgramMatchingStrategies} */}
                    {/*      selectedValue={values.swmPolicyStepSettings.testProgramMatchingStrategy} */}
                    {/*      onChange={ */}
                    {/*        (value: string) => { */}
                    {/*          swmPolicyStepSettings.testProgramMatchingStrategy = value; */}
                    {/*          this.setState({ swmPolicyStepSettings }); */}
                    {/*          updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*        } */}
                    {/*      } */}
                    {/*    /> */}
                    {/*  </div> */}
                    {/* </div> */}
                    <label className="label-left" htmlFor="mergeFacility">
                      <span className="label flex-40">Target Facility</span>
                      <input
                        autoComplete="off"
                        name="mergeFacility"
                        type="text"
                        className="flex-50"
                        placeholder="Facility Name"
                        defaultValue={values.swmPolicyStepSettings.mergeFacility}
                        onBlur={
                          (event: any) => {
                            swmPolicyStepSettings.mergeFacility = event.target.value;
                            this.setState({ swmPolicyStepSettings });
                            updatePolicyStepSettings(swmPolicyStepSettings);
                          }
                        }
                      />
                    </label>
                    <label className="label-left" htmlFor="mergeWorkCenter">
                      <span className="label flex-40">Target Work Center</span>
                      <input
                        autoComplete="off"
                        name="mergeWorkCenter"
                        type="text"
                        className="flex-50"
                        placeholder="Work Center Name"
                        defaultValue={values.swmPolicyStepSettings.mergeWorkCenter}
                        onBlur={
                          (event: any) => {
                            swmPolicyStepSettings.mergeWorkCenter = event.target.value;
                            this.setState({ swmPolicyStepSettings });
                            updatePolicyStepSettings(swmPolicyStepSettings);
                          }
                        }
                      />
                    </label>
                    <label className="label-left" htmlFor="mergeDevice">
                      <span className="label flex-40">Target Device</span>
                      <input
                        autoComplete="off"
                        name="mergeDevice"
                        type="text"
                        className="flex-50"
                        placeholder="Device Name"
                        defaultValue={values.swmPolicyStepSettings.mergeDevice}
                        onBlur={
                          (event: any) => {
                            swmPolicyStepSettings.mergeDevice = event.target.value;
                            this.setState({ swmPolicyStepSettings });
                            updatePolicyStepSettings(swmPolicyStepSettings);
                          }
                        }
                      />
                    </label>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex">
                      <Heading size={6}>Selection Criteria</Heading>
                      <Button
                        variant="outline-primary"
                        className="w43 ml-auto"
                        size="lg"
                        onClick={() => {
                          swmPolicyStepSettings.sources.push({
                            copyMetadata: false,
                            copyParametricData: false,
                            criteria: [],
                            waferPolicyStep: 'NONE',
                            waferPolicy: 'NONE',
                            alias: `Source${swmPolicyStepSettings.sources.length + 1}`,
                          });
                          this.setState({ swmPolicyStepSettings });
                          updatePolicyStepSettings(swmPolicyStepSettings);
                        }}
                      >
                        <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                      </Button>
                    </div>
                    {swmPolicyStepSettings.sources.map((sourceValue: any, index: number) => (
                      <div className="border-radius-5">
                        <label className="label-left" htmlFor="sourceAlias">
                          <span className="label flex-40">
                            {`Source${index + 1} Alias`}
                            <Hint className="d-inline-block" hint={{ message: 'This would be appended as prefix to target parameter definition', position: 'top' }} variant="icon" />
                          </span>
                          <input
                            autoComplete="off"
                            name="sourceAlias"
                            type="text"
                            className="flex-50"
                            placeholder="Source data alias"
                            defaultValue={sourceValue.alias}
                            onBlur={
                              (event: any) => {
                                swmPolicyStepSettings.sources[index].alias = event.target.value;
                                this.setState({ swmPolicyStepSettings });
                                updatePolicyStepSettings(swmPolicyStepSettings);
                              }
                            }
                          />
                        </label>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-40">Source Selection Criteria</span>
                          <div className="custom-form d-block flex-50">
                            {sourceValue.criteria.length > 0 ? sourceValue.criteria.map((item: any) => {
                              const keyNames = Object.keys(item);
                              return (
                                <div><span className="color-icon-color">{`${GeneralUtils.toTitleCase(keyNames[0], false)} selected: ${item[keyNames[0]].map((x: any) => x.name).join(', ')}`}</span></div>
                              );
                            }) : <span className="color-icon-color">No source selected</span>}
                          </div>
                          <Button
                            variant="outline-primary"
                            className="w43 ml-auto mr16"
                            size="lg"
                            onClick={
                              () => {
                                swmPolicyStepSettings.sources[index].showModal = true;
                                this.setState({ swmPolicyStepSettings });
                              }
                            }
                          >
                            <FontAwesomeIcon className="mt-10" size="sm" icon={faEdit} />
                          </Button>
                        </div>
                        <div className="custom-form-inline label-left">
                          <span className="label flex-40">{`Source${index + 1} Policy Step`}</span>
                          <div className="flex-50">
                            <CustomizedDropdown
                              full
                              variant="clear"
                              list={values.policySteps}
                              selectedValue={sourceValue.waferPolicyStep}
                              onChange={
                                (value: string) => {
                                  swmPolicyStepSettings.sources[index].waferPolicyStep = value;
                                  this.setState({ swmPolicyStepSettings });
                                  updatePolicyStepSettings(swmPolicyStepSettings);
                                }
                              }
                            />
                          </div>
                        </div>
                        <SelectionCriteriaModal
                          widgetsList={this.widgetsList}
                          showModal={sourceValue.showModal}
                          onModalClose={() => {
                            swmPolicyStepSettings.sources[index].showModal = false;
                            this.setState({ swmPolicyStepSettings });
                          }}
                          applyCriteria={(obj: any) => {
                            const criteria = [];
                            if (obj.deviceSelected.length > 0) criteria.push({ devices: _.uniqBy(obj.deviceSelected, (x: any) => x.id) });
                            if (obj.facilitySelected.length > 0) criteria.push({ facilities: _.uniqBy(obj.facilitySelected, (x: any) => x.id) });
                            if (obj.testProgramSelected.length > 0) criteria.push({ testPrograms: _.uniqBy(obj.testProgramSelected, (x: any) => x.id) });
                            if (obj.workCenterSelected.length > 0) criteria.push({ workCenters: _.uniqBy(obj.workCenterSelected, (x: any) => x.id) });
                            if (obj.lotSelected.length > 0) criteria.push({ lots: _.uniqBy(obj.lotSelected, (x: any) => x.id) });
                            if (obj.waferSelected.length > 0) criteria.push({ wafers: _.uniqBy(obj.waferSelected, (x: any) => x.id) });
                            swmPolicyStepSettings.sources[index].criteria = criteria;
                            swmPolicyStepSettings.sources[index].showModal = false;
                            this.setState({
                              swmPolicyStepSettings,
                            });
                            updatePolicyStepSettings(swmPolicyStepSettings);
                          }}
                        />
                        <div className="d-flex">
                          <Checkbox
                            name="swmCopyWaferMetadata"
                            type="radio"
                            defaultChecked={sourceValue.copyMetadata}
                            onChange={(e:any) => {
                              // eslint-disable-next-line no-param-reassign
                              swmPolicyStepSettings.sources.forEach((x: ISWMSource) => { x.copyMetadata = false; });
                              swmPolicyStepSettings.sources[index].copyMetadata = e.target.checked;
                              this.setState({ swmPolicyStepSettings });
                            }}
                          >
                            Copy Wafer Metadata
                          </Checkbox>
                          <Checkbox
                            defaultChecked={sourceValue.copyParametricData}
                            onChange={(e:any) => {
                              swmPolicyStepSettings.sources[index].copyParametricData = e.target.checked;
                              this.setState({ swmPolicyStepSettings });
                            }}
                          >
                            Copy Wafer Parametric Definition
                          </Checkbox>
                        </div>
                      </div>
                    ))}
                    {/* TODO will add this later when cross policy wafers can be used */}
                    {/* <div className="custom-form-inline label-left"> */}
                    {/*  <span className="label flex-40">Source1 Policy</span> */}
                    {/*  <div className="flex-50"> */}
                    {/*    <CustomizedDropdown */}
                    {/*      full */}
                    {/*      variant="clear" */}
                    {/*      list={policies} */}
                    {/*      selectedValue={values.swmPolicyStepSettings.source1WaferPolicy} */}
                    {/*      onChange={ */}
                    {/*        (value) => { */}
                    {/*          swmPolicyStepSettings.source1WaferPolicy = value; */}
                    {/*          if (value !== this.CURRENT) { */}
                    {/*            httpService.getPolicySteps(value).then((data) => { */}
                    {/*              source1PolicySteps = []; */}
                    {/*              data.forEach((x: any) => source1PolicySteps.push([x.outputReference, x.outputReference])); */}
                    {/*              swmPolicyStepSettings.source1WaferPolicyStep = source1PolicySteps.length > 0 ? source1PolicySteps[0][0] : ''; */}
                    {/*              this.setState({ swmPolicyStepSettings, source1PolicySteps }); */}
                    {/*              updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*            }); */}
                    {/*          } else { */}
                    {/*            source1PolicySteps = policySteps.length > 0 ? policySteps.map((x) => [x.OutputReference, x.OutputReference]) : []; */}
                    {/*            swmPolicyStepSettings.source1WaferPolicyStep = source1PolicySteps.length > 0 ? source1PolicySteps[0][0] : ''; */}
                    {/*            this.setState({ swmPolicyStepSettings, source1PolicySteps }); */}
                    {/*            updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*          } */}
                    {/*        } */}
                    {/*      } */}
                    {/*    /> */}
                    {/*  </div> */}
                    {/* </div> */}
                    {/* <div className="custom-form-inline label-left"> */}
                    {/*  <span className="label flex-40">Source2 Selection Criteria</span> */}
                    {/*  <div className="custom-form d-block flex-50"> */}
                    {/*    {swmPolicyStepSettings.source2Criteria.length > 0 ? swmPolicyStepSettings.source2Criteria.map((item: any) => { */}
                    {/*      const keyNames = Object.keys(item); */}
                    {/*      return ( */}
                    {/*        <div><span className="color-icon-color">{`${keyNames[0]} selected: ${item[keyNames[0]].length}`}</span></div> */}
                    {/*      ); */}
                    {/*    }) : <span className="color-icon-color">No source selected</span>} */}
                    {/*  </div> */}
                    {/*  <Button */}
                    {/*    variant="outline-primary" */}
                    {/*    className="w40 ml-auto" */}
                    {/*    size="lg" */}
                    {/*    onClick={ */}
                    {/*      () => { */}
                    {/*        this.setState({ showModal2: true }); */}
                    {/*      } */}
                    {/*    } */}
                    {/*  > */}
                    {/*    <FontAwesomeIcon className="mt-10" size="sm" icon={faEdit} /> */}
                    {/*  </Button> */}
                    {/* </div> */}
                    {/* <div className="custom-form-inline label-left"> */}
                    {/*  <span className="label flex-40">Source2 Policy</span> */}
                    {/*  <div className="flex-50"> */}
                    {/*    <CustomizedDropdown */}
                    {/*      full */}
                    {/*      variant="clear" */}
                    {/*      list={policies} */}
                    {/*      selectedValue={values.swmPolicyStepSettings.source2WaferPolicy} */}
                    {/*      onChange={ */}
                    {/*        (value) => { */}
                    {/*          swmPolicyStepSettings.source2WaferPolicy = value; */}
                    {/*          if (value !== this.CURRENT) { */}
                    {/*            httpService.getPolicySteps(value).then((data) => { */}
                    {/*              source2PolicySteps = []; */}
                    {/*              data.forEach((x: any) => source2PolicySteps.push([x.outputReference, x.outputReference])); */}
                    {/*              swmPolicyStepSettings.source2WaferPolicyStep = source2PolicySteps.length > 0 ? source2PolicySteps[0][0] : ''; */}
                    {/*              this.setState({ swmPolicyStepSettings, source2PolicySteps }); */}
                    {/*              updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*            }); */}
                    {/*          } else { */}
                    {/*            source2PolicySteps = policySteps.length > 0 ? policySteps.map((x) => [x.OutputReference, x.OutputReference]) : []; */}
                    {/*            swmPolicyStepSettings.source2WaferPolicyStep = source2PolicySteps.length > 0 ? source2PolicySteps[0][0] : ''; */}
                    {/*            this.setState({ swmPolicyStepSettings, source2PolicySteps }); */}
                    {/*            updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*          } */}
                    {/*        } */}
                    {/*      } */}
                    {/*    /> */}
                    {/*  </div> */}
                    {/* </div> */}
                    {/* <div className="custom-form-inline label-left"> */}
                    {/*  <span className="label flex-40">Source2 Policy Step</span> */}
                    {/*  <div className="flex-50"> */}
                    {/*    <CustomizedDropdown */}
                    {/*      full */}
                    {/*      variant="clear" */}
                    {/*      // disabled={values.swmPolicyStepSettings.source2WaferPolicy === 'NONE'} */}
                    {/*      list={values.source2PolicySteps} */}
                    {/*      selectedValue={values.swmPolicyStepSettings.source2WaferPolicyStep} */}
                    {/*      onChange={ */}
                    {/*        (value: string) => { */}
                    {/*          swmPolicyStepSettings.source2WaferPolicyStep = value; */}
                    {/*          this.setState({ swmPolicyStepSettings }); */}
                    {/*          updatePolicyStepSettings(swmPolicyStepSettings); */}
                    {/*        } */}
                    {/*      } */}
                    {/*    /> */}
                    {/*  </div> */}
                    {/* </div> */}
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Owner</Heading>
                    <Label
                      labelTitle="Step Owner"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                    >
                      <Textbox
                        autoComplete="off"
                        name="stepOwner"
                        placeholder="mark.smith@example.com"
                      />
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>Notifications</Heading>
                    <Label
                      labelTitle="Additional Emails"
                      labelPosition="left-middle"
                      labelSize="40"
                      fieldSize="60"
                      errorSize="0"
                    >
                      <Textbox
                        autoComplete="off"
                        name="additionalEmail"
                        placeholder="mark.smith@example.com"
                      />
                    </Label>
                    <Checkbox>Notify Owners</Checkbox>
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right">
                    <div className="custom-form-inline label-left">
                      <div className="flex-20 mt20"><Heading size={6}>Pre Conditions</Heading></div>
                      <div className="flex-80 mt10 ">
                        <Button
                          variant="outline-primary"
                          className="w43"
                          onClick={
                            () => {
                              swmPolicyStepSettings.preConditions.push({
                                condition: '', thenClause: '', elseClause: '',
                              });
                              updatePolicyStepSettings(swmPolicyStepSettings);
                            }
                          }
                        >
                          <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                        </Button>
                      </div>
                    </div>
                    {this.renderPrePostConditions(values.swmPolicyStepSettings.preConditions, 'PRE')}
                  </Col>
                  <Col lg={6}>
                    <div className="custom-form-inline label-left">
                      <div className="flex-20 mt20"><Heading size={6}>Post Conditions</Heading></div>
                      <div className="flex-80 mt10 ">
                        <Button
                          variant="outline-primary"
                          className="w43"
                          onClick={
                            () => {
                              swmPolicyStepSettings.postConditions.push({
                                condition: '', thenClause: '', elseClause: '',
                              });
                              updatePolicyStepSettings(swmPolicyStepSettings);
                            }
                          }
                        >
                          <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                        </Button>
                      </div>
                    </div>
                    {this.renderPrePostConditions(values.swmPolicyStepSettings.postConditions, 'POST')}
                  </Col>
                </Row>
                {updatedCreated}
              </Container>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-between p10">
              <Button variant="clear" className="info-label">
                {rulesCount}
                {' '}
                {rulesCount > 1 ? 's' : ''}
              </Button>
              <Button variant="clear" className="info-label color-icon-color" onClick={toggleShowRulesPanel}>
                {showRulesPanel ? 'Collapse Step ↑' : 'Expand Step ↓'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default SWMPolicyStepSettings;
