/* eslint-disable react/prefer-stateless-function */
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

export interface IUtilityOptionsProps {
  limitSettingsObj?: any;
  showDies?: boolean;
  showAnnotations?: boolean;
  showLimitLinesOptions?: boolean;
  showDiesShowingOption?: boolean;
  showAnnotationsShowingOption?: boolean;
  setParentStateCallback?: (obj: any) => void;
}

class UtilityOptions extends React.Component<IUtilityOptionsProps> {
  render() {
    const {
      limitSettingsObj, showLimitLinesOptions, showDiesShowingOption, showAnnotationsShowingOption, showDies, showAnnotations, setParentStateCallback,
    } = this.props;
    return (
      <>

        {limitSettingsObj && Object.keys(limitSettingsObj).length > 0 && showLimitLinesOptions && setParentStateCallback && (
          <div className="vert-align-content d-flex">
            <Button
              variant="danger"
              className="mt20 mr10 mb20"
              onClick={() => { setParentStateCallback({ limitSettingsObj: {} }); }}
            >
              <FontAwesomeIcon
                className="mr10"
                size="sm"
                icon={faTimes}
              />
              Remove Limit Lines
            </Button>
          </div>
        )}
        {showDiesShowingOption && setParentStateCallback && (
          <div className="vert-align-content d-flex">
            <Button
              variant={showDies ? 'danger' : 'success'}
              className="mt20 mr10 mb20"
              onClick={() => { setParentStateCallback({ showDies: !showDies }); }}
            >
              <FontAwesomeIcon
                className="mr10"
                size="sm"
                icon={showDies ? faTimes : faCheck}
              />
              {showDies ? 'Remove Jittered Points' : 'Show Jittered Points'}
            </Button>
          </div>
        )}
        {showAnnotationsShowingOption && setParentStateCallback && (
          <div className="vert-align-content d-flex">
            <Button
              variant={showAnnotations ? 'danger' : 'success'}
              className="mt20 mr10 mb20"
              onClick={() => { setParentStateCallback({ showAnnotations: !showAnnotations }); }}
            >
              <FontAwesomeIcon
                className="mr10"
                size="sm"
                icon={showAnnotations ? faTimes : faCheck}
              />
              {showAnnotations ? 'Remove Annotations' : 'Show Annotations'}
            </Button>
          </div>
        )}
      </>
    );
  }
}

export default UtilityOptions;
