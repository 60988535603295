import React from 'react';
import DataGrid, {
  Column, Paging, Scrolling, Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import getDropDownOptions from 'components/getDropDownOptions';

interface IBinConfigDropDownBoxComponentProps {
  data: any;
  parentSelectionHandler: (selectedBinPlusDefinition:any, rowData:any) => void;
}

interface IBinConfigDropDownBoxComponentState {
  currentValue: any
}

class BinConfigDropDownBoxComponent extends React.Component<IBinConfigDropDownBoxComponentProps, IBinConfigDropDownBoxComponentState> {
  dropDownBoxRef: React.RefObject<DropDownBox>;

  constructor(props:any) {
    super(props);
    const { data } = this.props;
    this.state = {
      currentValue: data.value,
    };
    this.dropDownBoxRef = React.createRef();
  }

  renderColorInCell = (e:any) => {
    return <div className="h20 mr5 mt0 ml0 mb0" style={{ backgroundColor: e.data.color }} />;
  };

  onSelectionChanged = (selectionChangedArgs:any) => {
    const { data } = this.props;
    const selectedVal = selectionChangedArgs.selectedRowKeys[0];
    if (selectedVal === undefined) return;
    data.setValue(selectedVal);
    const binData = this.getDataSource(data).filter((x: any) => { return x.number === selectedVal; });
    if (binData.length > 0) {
      data.data.binPlusDefinition = { color: binData[0].color, name: binData[0].name, number: binData[0].number };
    }
    this.setState({ currentValue: selectedVal });
    if (selectionChangedArgs.selectedRowKeys.length > 0) {
      // @ts-ignore
      this.dropDownBoxRef.current.instance.close();
    }
  };

  getDataSource = (data: any): any => {
    let dataSource = [];
    if (data.data.binPlusGroupType) {
      dataSource = data.column.lookup.dataSource.filter((bpd: any) => {
        return bpd.binPlusGroupType === data.data.binPlusGroupType;
      });
    }
    return dataSource;
  };

  contentRender = () => {
    const { data } = this.props;
    const { currentValue } = this.state;
    return (
      <DataGrid
        dataSource={this.getDataSource(data)}
        remoteOperations
        keyExpr="id"
        height={250}
        selectedRowKeys={[currentValue]}
        hoverStateEnabled
        onSelectionChanged={this.onSelectionChanged}
        focusedRowEnabled
        showColumnLines={false}
        showRowLines
        defaultFocusedRowKey={currentValue}
      >
        <Column caption="Bin Name" dataField="name" />
        <Column caption="Bin #" dataField="number" />
        <Column caption="Bin Flag" dataField="pass_fail" />
        <Column
          caption="Bin Color"
          dataField="color"
          cellRender={this.renderColorInCell}
        />
        <Paging enabled defaultPageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="single" />
      </DataGrid>
    );
  };

  render() {
    const { currentValue } = this.state;
    const { data, parentSelectionHandler } = this.props;
    return (
      <DropDownBox
        ref={this.dropDownBoxRef}
        dataSource={this.getDataSource(data)}
        value={currentValue}
        displayExpr="name"
        valueExpr="id"
        contentRender={this.contentRender}
        onClosed={() => {
          if (parentSelectionHandler) {
            const selectedBinPlusDefinition = this.getDataSource(data).find((obj:any) => obj.id === currentValue);
            if (selectedBinPlusDefinition) {
              parentSelectionHandler(selectedBinPlusDefinition, data.key);
            }
          }
        }}
        dropDownOptions={getDropDownOptions('cell', data, [{ width: '30%' }])}
      />
    );
  }
}

export default BinConfigDropDownBoxComponent;
