import React from 'react';
import './device-setup.scss';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import { Col, Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { httpDeviceSetup } from 'services/http.device-setup';
import toast from 'CustomToast';
import Button from '../wrapped-component/hint-controls/Button';

type DeviceSetupsProps = {
  className?: string
};

type DeviceSetupsState = {
  deviceSetups: any[]
};

class DeviceSetups extends React.Component<any, DeviceSetupsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      deviceSetups: [],
    };
  }

  componentDidMount() {
    this.getDeviceSetupData();
  }

  getDeviceSetupData = async () => {
    const deviceSetupsData = await httpDeviceSetup.getDeviceSetups();
    this.setState({ deviceSetups: deviceSetupsData });
  };

  onRowRemoved = async (row: any) => {
    await httpDeviceSetup.removeDeviceSetup(row.data.id);
    toast.success('Device Setup Successfully Deleted');
  };

  render() {
    const { className, history } = this.props;
    const { deviceSetups } = this.state;
    return (
      <Container fluid className={className !== '' ? className : ''}>
        <TopbarNav
          title="Device Setup Management Module"
          items={[]}
          showAvatar
          showNotifications
          secondaryActions={(
            <Button
              onClick={() => {
                history.push('/create-device-setup');
              }}
              size="sm"
              variant="primary"
              className="mr20 mt-5"
            >
              Create Device Setup
            </Button>
          )}
        />
        <Row className="p20">
          <Col>
            <CustomizedDataGrid
              showAdvancedFilters={false}
              showFilterRow
              allowDeleting
              showContextMenu={false}
              allowUpdating
              onRowRemoved={this.onRowRemoved}
              onEditingStart={(event: any) => {
                // eslint-disable-next-line no-param-reassign
                event.cancel = true;
                history.push(`/create-device-setup/${event.data.id}`, { id: event.data.id });
              }}
              fields={[
                {
                  caption: 'Name',
                  dataField: 'name',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                  wordWrap: true,
                },
                {
                  caption: 'Owner',
                  dataField: 'owner',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                  wordWrap: true,
                },
                {
                  caption: 'Version',
                  dataField: 'version',
                  dataType: 'number',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'State',
                  dataField: 'state',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Access',
                  dataField: 'access',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Description',
                  dataField: 'description',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                },
                {
                  caption: 'Created On',
                  dataField: 'createdOn',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Updated On',
                  dataField: 'updatedOn',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
              ]}
              tableData={deviceSetups}
            />
          </Col>
        </Row>
      </Container>

    );
  }
}

export default withRouter(DeviceSetups);
