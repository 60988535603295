/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import initializeEvents from '../web-gl-utils/Events';
// eslint-disable-next-line no-unused-vars
import { WaferMapData } from '../web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

const setTooltipContentHandlers: { [keyIndex: string]: (dieData: WaferMapData) => void } = {};
let timeOut: any;
let clearTimeOut: any;

const getArrayCoords = (_startX: number, _startY: number, _endX: number, _endY: number, waferMapInstance: WebGLUtils) => {
  const {
    ratioWidth, ratioHeight, viewport, tickViewPort, viewPortHeight, angleInDegrees, viewPortWidth,
  } = waferMapInstance.waferMapVariables;
  let startX = (_startX - viewport.x) * ratioWidth;
  let endX = (_endX - viewport.x) * ratioWidth;
  let startY = (_startY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  let endY = (_endY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  // rotation
  const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
  startX -= viewPortWidth / 2;
  startY -= viewPortHeight / 2;
  let rstartX = startX * rotation[1] + startY * rotation[0];
  let rstartY = startY * rotation[1] - startX * rotation[0];
  rstartX += viewPortWidth / 2;
  rstartY += viewPortHeight / 2;
  endX -= viewPortWidth / 2;
  endY -= viewPortHeight / 2;
  let rendX = endX * rotation[1] + endY * rotation[0];
  let rendY = endY * rotation[1] - endX * rotation[0];
  rendX += viewPortWidth / 2;
  rendY += viewPortHeight / 2;
  // rotation

  return waferMapInstance.returnBoxArrayIndices(
    rstartX,
    rstartY,
    rendX,
    rendY,
  );
};

const updateTooltipContentOnMouseMove = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  waferMapInstance: WebGLUtils,
  isMouseDown: boolean,
) => {
  const { tooltipEL, tooltipHoverTime } = waferMapInstance.waferMapVariables;
  if (isMouseDown) {
    if (tooltipEL) tooltipEL.instance.hide();
    return;
  }
  const {
    viewPortHeight, viewport, isContextMenuOpen, isMouseMoving,
  } = waferMapInstance.waferMapVariables;
  if (
    isContextMenuOpen
    || isMouseMoving
    || endX < viewport.x
    || endX > viewport.x + viewport.width
    || endY < viewPortHeight - (viewport.y + viewport.height)
    || endY > viewPortHeight - (viewport.y)
  ) {
    clearTimeout(timeOut);
    if (tooltipEL) tooltipEL.instance.hide();
    return;
  }
  const arrayCoords = getArrayCoords(endX, endY, endX, endY, waferMapInstance);
  if (tooltipEL) {
    if (arrayCoords.startArrayY === arrayCoords.endArrayY && arrayCoords.startArrayX === arrayCoords.endArrayX) {
      const {
        startCol, startRow, rowDirection, colDirection, keyIndex,
      } = waferMapInstance.waferMapVariables;
      const rIndex = startRow + rowDirection * arrayCoords.endArrayY;
      const cIndex = startCol + colDirection * arrayCoords.endArrayX;
      if (rIndex < waferMapInstance.waferMapVariables.waferMapTestData.length
        && cIndex < waferMapInstance.waferMapVariables.waferMapTestData[0].length
        && rIndex >= 0 && cIndex >= 0
        && waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]) {
        tooltipEL.instance.option('position.offset', {
          x: endX,
          y: endY - viewPortHeight + 120, // incase of a big tooltip increase this value
        });
        clearTimeout(timeOut);
        tooltipEL.instance.hide();
        // eslint-disable-next-line no-shadow
        timeOut = setTimeout((rIndex: number, cIndex: number, keyIndex: string, waferMapInstance: WebGLUtils) => {
          tooltipEL.instance.show();
          if (setTooltipContentHandlers[keyIndex]) {
            setTooltipContentHandlers[keyIndex](waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!);
          }
        }, tooltipHoverTime * 1000, rIndex, cIndex, keyIndex, waferMapInstance);
        clearTimeOut = setTimeout((timeOutId: any, clearTimeOutId: any) => { clearTimeout(timeOutId); tooltipEL.instance.hide(); clearTimeout(clearTimeOutId); }, 3000, timeOut, clearTimeOut);
      } else {
        clearTimeout(timeOut);
        tooltipEL.instance.hide();
      }
    } else {
      clearTimeout(timeOut);
      tooltipEL.instance.hide();
    }
  }
};

const setTooltip = (setTooltipContentCallback: (dieData: WaferMapData) => void, waferMapInstance: WebGLUtils): void => {
  setTooltipContentHandlers[waferMapInstance.waferMapVariables.keyIndex] = setTooltipContentCallback;
  if (waferMapInstance.waferMapVariables.gl === null) return;
  initializeEvents(
    waferMapInstance,
    waferMapInstance.waferMapVariables.gl,
    updateTooltipContentOnMouseMove,
    null,
    null,
  );
};

export default setTooltip;
