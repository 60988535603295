import React from 'react';
import Plotly from 'plotly.js';
// eslint-disable-next-line import/no-unresolved
import { Container, Row, Col, } from 'react-bootstrap';
// eslint-disable-next-line import/no-unresolved
import createPlotlyComponent from 'react-plotly.js/factory';
import './real-time-stats.scss';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';

interface IRealTimeStatsProps {
  id?: string;
  shadowOnHover?: boolean;
  selectStatForDetailedPreview?: any;
  data: { x: number[], y: number[] };
  title: string;
  stat: string;
  isExpanded?: boolean;
  onCardClick?: () => void
  showActions?: boolean;
  selectedTimespan?: string;
  onTimespanUpdate?: (value: string) => void;
}

const Plot = createPlotlyComponent(Plotly);
const getStatsGraph = (isExpanded: boolean, data: { x: number[], y: number[] }) => {
  let graphHeight = '';
  if (isExpanded) {
    graphHeight = 'h50';
  } else {
    graphHeight = 'h80';
  }
  return (
    <Plot
      className={`w-100 ${graphHeight}`}
      useResizeHandler
      data={[
        {
          x: data.x !== undefined && data.x.length > 0 ? [...data.x] : [],
          y: data.y !== undefined && data.y.length > 0 ? [...data.y] : [],
          // fill: 'tozeroy',
          fillcolor: '#F6F3FD',
          stackgroup: 'PAT',
          name: 'PAT',
          marker: {
            color: '#886CE6',
            line: {
              color: '#ffffff',
              width: 1,
            },
          },
          mode: 'lines+markers',
          showlegend: false,
        },
      ]}
      layout={{
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
          pad: 0,
        },
        // hovermode: false,
        autosize: true,
        xaxis: {
          showgrid: false,
          fixedrange: true,
          showticklabels: false,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          fixedrange: true,
          zeroline: false,
          showticklabels: false,
        },
      }}
      config={{
        displayModeBar: false,
      }}
    />
  );
};

const RealTimeStatsGraphCard = (props: IRealTimeStatsProps) => {
  const {
    id,
    data,
    title,
    stat,
    isExpanded,
    selectStatForDetailedPreview,
    onTimespanUpdate,
    selectedTimespan,
    onCardClick,
    shadowOnHover,
    showActions,
  } = props;

  return (
  // <Button
  //   variant="clear text-left"
  //   onClick={onCardClick}
  //   className="w-100 p0"
  // >
    <Container
      fluid
      className={`stats stats-graph-card overflow-visible
        ${shadowOnHover ? ' shadow-on-hover' : ''}
        ${isExpanded ? 'stats-graph-card-expanded' : 'stats-graph-card-collapsed'}`}
    >
      <Row>
        <Col sm={isExpanded ? 12 : 4}>
          <div className="d-flex justify-content-between">
            <p className="card-text m0">
              {title}
              {isExpanded
                                && (
                                  <div
                                    className="card-sub-text ml-12"
                                  >
                                    <CustomizedDropdown
                                      variant="clear"
                                      selectedValue={selectedTimespan}
                                      onChange={(value: string) => {
                                        // if (onTimespanUpdate !== undefined) {
                                        //   onTimespanUpdate(value);
                                        // }
                                      }}
                                      list={[
                                        ['today', 'Today'],
                                        ['yesterday', 'Yesterday'],
                                        ['lastWeek', 'Last Week'],
                                        ['lastMonth', 'Last Month'],
                                        ['lastYear', 'Last Year'],
                                      ]}
                                    />
                                  </div>
                                )}
            </p>
            {
              showActions
                            && (
                              <div hidden={!isExpanded} className="mt10 text-right">
                                {/* <Button
                  variant="outline-dark"
                  className="mt6"
                  size="sm"
                  onClick={onCardClick}
                >
                  <FontAwesomeIcon
                    className="icon mr10"
                    size="sm"
                    icon={faEllipsisH}
                  />
                  Infrastructure
                </Button>
                <Button
                  variant="outline-dark"
                  className="ml10 mt6"
                  size="sm"
                >
                  <FontAwesomeIcon
                    className="icon mr10"
                    size="sm"
                    icon={faExpand}
                    onClick={() => {
                      selectStatForDetailedPreview(id);
                    }}
                  />
                  Details
                </Button> */}
                              </div>
                            )
            }
          </div>
          <p className="stat m0">{stat}</p>
        </Col>
        <Col sm={isExpanded ? 12 : 8}>
          {getStatsGraph(isExpanded!, data)}
        </Col>
      </Row>
    </Container>
  // </Button>
  );
};

RealTimeStatsGraphCard.defaultProps = {
  id: '-1',
  shadowOnHover: false,
  isExpanded: false,
  onCardClick: () => undefined,
  showActions: true,
  selectStatForDetailedPreview: () => undefined,
  selectedTimespan: 'today',
  onTimespanUpdate: undefined,
};

export default RealTimeStatsGraphCard;
