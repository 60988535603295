/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { IHint } from '../../../../interfaces.js';
import './hint.scss';

interface IHintProps {
  hint?: IHint;
  variant: 'light' | 'icon' | 'outline-button';
  className?: string | undefined;
  mode?: 'tooltip' | 'popover';
}

const Hint = (props:IHintProps) => {
  const {
    hint,
    variant,
    className,
    mode,
  } = props;

  const {
    message, href, position,
  } = hint!;

  if (message === '') {
    return <></>;
  }

  return (
    <span className={`hint-${variant} ${className}`}>
      <OverlayTrigger
        rootClose
        trigger="click"
        placement={position}
        overlay={
          mode === 'tooltip'
            ? (
              <Tooltip id="">
                {href !== ''
                  ? (
                    <a href={href} className="color-light-hover">
                      <span className="color-light color-light-hover">
                        {hint!.message}
                      </span>
                    </a>
                  )
                  : (
                    <span className="color-light color-light-hover">
                      {hint!.message}
                    </span>
                  )}
              </Tooltip>
            ) : (
              <Popover id="hint-component-popover" placement={position}>
                <Popover.Content>
                  {hint!.message}
                </Popover.Content>
              </Popover>
            )
        }
      >
        <button type="button" className="d-inline hint-button">
          <i className="dx-icon-info" />
        </button>
      </OverlayTrigger>
    </span>
  );
};

Hint.defaultProps = {
  hint: {
    message: '',
    href: undefined,
    position: 'right',
  },
  className: undefined,
  mode: 'tooltip',
};

export default Hint;
