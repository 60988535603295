/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { ReactNode } from 'react';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, } from '@fortawesome/free-solid-svg-icons';
import GeneralUtils, { toTitleCase } from 'GeneralUtils';
import { GraphType, IFieldValue, IReportHeaderSetting, } from './ReportHeaderBuilder';
import { httpReportHeader } from '../../../services/http.report-header';
import CustomizedAccordion from '../../wrapped-component/customized-accordion/CustomizedAccordion';
import Heading from '../../wrapped-component/hint-controls/Heading';

type textAlign = 'center' | 'right' | 'left';

interface IReportHeaderLandingAreaProps {
  reportHeaderSettings?: IReportHeaderSetting;
  returnRequestObj?: (reportHeaderId: string) => any;
  additionalInformation?: ReactNode[];
  testParameterIndex?: number[],
  reportIndex: number,
  updatedHeaderId?: string,
  headerData?: IReportHeaderSetting,
  saveData?: (data: any, reportIndex: number, dataType: string) => void;
  isApplyAllReportHeaders?: boolean,
}

interface IReportHeaderLandingAreaState {
  headerSettings?: IReportHeaderSetting;
  isComponentMountForTheFirstTime: boolean;
}

class ReportHeaderLandingArea extends React.Component<IReportHeaderLandingAreaProps, IReportHeaderLandingAreaState> {
  constructor(props: IReportHeaderLandingAreaProps) {
    super(props);
    this.state = {
      headerSettings: undefined,
      isComponentMountForTheFirstTime: true,
    };
  }

  componentDidMount() {
    const {
      reportHeaderSettings,
      returnRequestObj,
      reportIndex,
      updatedHeaderId,
      headerData,
      saveData,
      isApplyAllReportHeaders,
    } = this.props;

    if (reportHeaderSettings !== undefined) {
      this.setState({ headerSettings: reportHeaderSettings });
    } else if (returnRequestObj) {
      const requestObject = returnRequestObj(updatedHeaderId || undefined as unknown as string);
      const { isComponentMountForTheFirstTime } = this.state;
      if (!headerData && isApplyAllReportHeaders && !isComponentMountForTheFirstTime) {
        httpReportHeader.apply(requestObject)
          .then((reportHeader: IReportHeaderSetting) => {
            if (reportIndex !== -1 && saveData) {
              saveData(reportHeader, reportIndex, GeneralUtils.ReportDataTypeConstants.HEADER);
            }
            this.setState({ headerSettings: reportHeader });
          });
      } else {
        this.setState({
          headerSettings: headerData,
          isComponentMountForTheFirstTime: false,
        });
      }
    }
  }

  componentDidUpdate(prevProps: IReportHeaderLandingAreaProps) {
    const {
      reportHeaderSettings, returnRequestObj, testParameterIndex, reportIndex, headerData, saveData, updatedHeaderId,
    } = this.props;
    if (JSON.stringify(prevProps.testParameterIndex) !== JSON.stringify(testParameterIndex)) {
      if (reportHeaderSettings !== undefined) {
        this.setState({ headerSettings: reportHeaderSettings });
      } else if (returnRequestObj) {
        const requestObject = returnRequestObj(updatedHeaderId || undefined as unknown as string);
        if (reportIndex !== -1 && headerData) {
          this.setState({ headerSettings: headerData });
        } else {
          httpReportHeader.apply(requestObject)
            .then((reportHeader: IReportHeaderSetting) => {
              if (reportIndex !== -1 && saveData) {
                saveData(reportHeader, reportIndex, GeneralUtils.ReportDataTypeConstants.HEADER);
              }
              this.setState({ headerSettings: reportHeader });
            });
        }
      }
    }
  }

  renderGraph = (graphType: GraphType, fieldValues: IFieldValue[]) => {
    switch (graphType) {
      case 'BAR_CHART':
        return this.getBarChart(fieldValues);
      case 'PIE_CHART':
        return this.getPieChart(fieldValues);
      default:
        return <></>;
    }
  };

  getBarChart = (fieldValues: IFieldValue[]) => {
    const Plot = createPlotlyComponent(Plotly);
    const [graphValues, colors] = this.getGraphAndColorValues(fieldValues);
    return graphValues.length > 0
      ? (
        <Plot
          className="w-100"
          useResizeHandler
          data={[
            {
              x: graphValues.map((v: any, i: number) => `Resident_${i.toString()}`),
              y: graphValues,
              marker: {
                color: colors,
              },
              opacity: 1,
              stackgroup: 'PAT',
              name: 'PAT',
              type: 'bar',
              showlegend: false,
              hoverinfo: 'none',
              textinfo: 'none',
            },
          ]}
          layout={{
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 0,
            },
            width: 60,
            height: 60,
            autosize: true,
            xaxis: {
              showgrid: false,
              fixedrange: true,
              showticklabels: false,
              zeroline: false,
            },
            yaxis: {
              showgrid: false,
              fixedrange: true,
              zeroline: false,
              showticklabels: false,
            },
          }}
          config={{
            displayModeBar: false,
          }}
        />
      ) : this.getGraphNotApplicableJSX();
  };

  getPieChart = (fieldValues: IFieldValue[]) => {
    const Plot = createPlotlyComponent(Plotly);
    const [graphValues, colors] = this.getGraphAndColorValues(fieldValues);
    return graphValues.length > 0
      ? (
        <Plot
          className="w-100"
          useResizeHandler
          data={[
            {
              values: graphValues,
              labels: ['Residential', 'Non-Residential'],
              stackgroup: 'PAT',
              name: 'PAT',
              line: { shape: 'spline' },
              marker: {
                colors,
              },
              hole: 0.5,
              type: 'pie',
              showlegend: false,
              hoverinfo: 'none',
              textinfo: 'none',
            },
          ]}
          layout={{
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 0,
            },
            width: 60,
            height: 60,
            autosize: true,
            xaxis: {
              showgrid: false,
              fixedrange: true,
              showticklabels: false,
              zeroline: false,
            },
            yaxis: {
              showgrid: false,
              fixedrange: true,
              zeroline: false,
              showticklabels: false,
            },
          }}
          config={{
            displayModeBar: false,
          }}
        />
      ) : this.getGraphNotApplicableJSX();
  };

  getGraphNotApplicableJSX = () => (
    <div className="d-flex justify-content-center mr10">
      <h2
        className="color-secondary mb0"
      >
        N/A
      </h2>
    </div>
  );

  getGraphAndColorValues = (fieldValues: IFieldValue[]) => {
    const graphValues = fieldValues.filter((fieldValueObj) => fieldValueObj.value)
      .map((fieldValueObj) => fieldValueObj.value);
    const colors = fieldValues.filter((fieldValueObj) => fieldValueObj.color)
      .map((fieldValueObj) => fieldValueObj.color);
    return [graphValues, colors];
  };

  getReportHeaderFields = (fieldLabel: string, fieldValues: IFieldValue[]) => {
    const fieldValueObjList = fieldValues.filter((fieldValueObj) => fieldValueObj.formatValue !== null);
    return fieldValueObjList.map((fieldValueObj, index: number) => (
      <div className={index !== fieldValueObjList.length - 1 ? 'mb8' : ''}>
        <h4
          className={`${fieldValueObj.color === null || fieldValueObj.color === '' ? 'color-secondary' : ''} mb0`}
          style={{ color: fieldValueObj.color === null || fieldValueObj.color === '' ? undefined : fieldValueObj.color }}
        >
          {fieldValueObj.formatValue}
        </h4>
        <p className="mb0 h16">
          {fieldValueObj.label === null || fieldValueObj.label === '' ? fieldLabel : fieldValueObj.label}
        </p>
      </div>
    ));
  };

  getGridItems = (isComputedField: boolean) => {
    const { headerSettings } = this.state;

    if (headerSettings === undefined) return <></>;

    const fields = headerSettings.fields.filter((x) => x.isComputedField === isComputedField)
      .map((field) => (
        <div className={`${isComputedField ? '' : 'w250 mr10'} p10 border-all d-flex align-items-center justify-content-between background-color-body-background rounded`}>
          {field.isComputedField && field.graphType ? (
            <div className="flex-40">
              {this.renderGraph(field.graphType, field.fieldValues)}
            </div>
          ) : null}
          <div className={field.isComputedField ? 'flex-60' : ''}>
            <div>
              <p
                className="mb0 color-disabled-color"
              >
                {toTitleCase(field.entityType.toUpperCase())}
              </p>
            </div>
            <div>
              {this.getReportHeaderFields(field.fieldLabel, field.fieldValues)}
            </div>
          </div>
        </div>
      ));

    // if (isComputedField) {
    return (
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(230px,1fr))',
        gap: '10px',
      }}
      >
        {fields}
      </div>
    );
    // }
    // return (
    //   <ScrollView
    //     // useNative
    //     showScrollbar="always"
    //     scrollByThumb
    //     height="100px"
    //     direction="horizontal"
    //   >
    //     <div className="d-flex align-items-center">
    //       {fields}
    //     </div>
    //   </ScrollView>
    // );
  };

  render() {
    const { headerSettings } = this.state;
    const { additionalInformation } = this.props;
    if (headerSettings === undefined) {
      return <></>;
    }

    const summaryStatsCount = headerSettings.fields.filter((x) => x.isComputedField).length;
    const fieldsCount = headerSettings.fields.filter((x) => !x.isComputedField).length;

    return (
      <>
        {/* {
          additionalInformation && (
            <div className="d-flex flex-column align-items-center justify-content-center mt20">
              {additionalInformation}
            </div>
          )
        } */}
        {
          headerSettings && (
            <CustomizedAccordion
              collapseOnLoad
              cardClassNames="mt20 mb20"
              bodyClassNames="p20"
              content={[
                {
                  header: (
                    <div
                      className="d-flex align-items-top justify-content-between "
                    >
                      <Heading className="mb0" size={5}>{headerSettings.title}</Heading>
                      <div className="flex-10 text-right">
                        <FontAwesomeIcon
                          size="lg"
                          icon={faChevronDown}
                        />
                      </div>
                    </div>
                  ),
                  body: (
                    <>

                      {summaryStatsCount > 0 && (
                        <div className="mb20">
                          <div className="d-flex align-items-center pb10">
                            <h6 className="mr10 mb0">Summary Statistics</h6>
                            <p className="text-right mb0">
                              (
                              {summaryStatsCount}
                              )
                            </p>
                          </div>
                          {this.getGridItems(true)}
                        </div>
                      )}
                      {
                        fieldsCount > 0 && (
                          <div>
                            <div className="d-flex align-items-center pb10">
                              <h6 className="mr10 mb0">Fields</h6>
                              <p className="text-right mb0">
                                (
                                {fieldsCount}
                                )
                              </p>
                            </div>
                            {this.getGridItems(false)}
                          </div>
                        )
                      }
                    </>
                  ),
                },
              ]}
            />
          )
        }
      </>
    );
  }
}

export default ReportHeaderLandingArea;
