import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ColorBox from "devextreme-react/color-box";
import { DataGrid, SelectBox } from "devextreme-react";
import {
  Column,
  CustomRule,
  Editing,
  FilterRow,
  Lookup,
  PatternRule,
  RequiredRule,
  Scrolling,
  Selection,
  StringLengthRule,
} from "devextreme-react/data-grid";
import _ from "lodash";
import CreateDynamicColumn from "components/wrapped-component/customized-data-grid/CreateDynamicColumn";
import "./bin-plus-table.scss";
import { ErrorLabel } from "components/template-component/form-error-label/ErrorLabel";
import toast from "CustomToast";
import { withRouter } from "react-router-dom";
import TopbarNav from "components/navigational-component/topbar-nav/TopbarNav";
import { httpBinPlusTable } from "services/http.bin-plus-table";
import CustomStore from "devextreme/data/custom_store";
import withProps from "components/bin-plus-tables/withProps";
import ComponentHeader from "components/wrapped-component/component-header/component-header";
// eslint-disable-next-line no-unused-vars
import {
  BinPlusTableData,
  ComponentHeaderData,
} from "components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types";
import ModalPopup from "components/wrapped-component/modal-popup/modal-popup";
import { UtilityFunctions } from "components/wafer-control-map/utility";
import getDropDownOptions from "components/getDropDownOptions";
import AppConstants from "../../constants.js";
import BinPlusTypeTagBox from "./bin-plus-type-tagbox";
import BinPlusGroupingsEditComponent from "./bin-plus-groupings-edit-component";

type BinPlusTableState = {
  binPlusGroupTypesBinTypes: any[];
  version: number;
  state: string;
  description: string;
  id: string | null;
  name: string;
  owner: string;
  access: string;
  columns: any[];
  binPlusDefinitions: any[];
  formErrors: { [key: string]: any };
  isDefault: boolean;
};

class BinPlusTable extends React.Component<any, BinPlusTableState> {
  datagrid: DataGrid | null = null;

  private binPlusDefinitions: any[] = [];

  private states = [
    ["DRAFT", "DRAFT"],
    ["ACTIVE", "ACTIVE"],
    ["INACTIVE", "INACTIVE"],
    ["OBSOLETE", "OBSOLETE"],
  ];

  constructor(props: any) {
    super(props);
    const { history } = this.props;
    this.state = {
      columns: [],
      binPlusGroupTypesBinTypes: [],
      id:
        history.location.state && "id" in history.location.state
          ? history.location.state.id
          : null,
      version: 1.0,
      state: this.states[0][0],
      description: "",
      formErrors: {},
      access: "PUBLIC",
      name: "",
      owner: "",
      isDefault: false,
      binPlusDefinitions: [],
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(props: any, state: BinPlusTableState) {
    return {
      id:
        props.history.location.state && "id" in props.history.location.state
          ? props.history.location.state.id
          : null,
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    let binPlusTableData = {
      id,
      version: 1.0,
      state: this.states[0][0],
      description: "",
      name: "",
      owner: "",
      access: "PUBLIC",
      binPlusDefinitions: [],
      isDefault: false,
    };
    let binPlusGroupTypesBinTypes: any = [];
    let columns: any = [];
    if (id !== null) {
      binPlusTableData = await this.getBinPlusTableData(id).catch(() => {
        toast.error("Could not get bin plus table data.");
      });
    }
    binPlusGroupTypesBinTypes = await this.getBinPlusGroupTypesBinTypes().catch(
      () => {
        toast.error("Could not get bin group types data.");
      }
    );
    columns = await this.getColumns().catch(() => {
      toast.error("Could not get columns list.");
    });
    const updatedStateObj: any = {
      binPlusGroupTypesBinTypes,
      columns,
    };
    if (binPlusTableData) {
      updatedStateObj.id = binPlusTableData.id;
      updatedStateObj.name = binPlusTableData.name;
      updatedStateObj.owner = binPlusTableData.owner;
      updatedStateObj.version = binPlusTableData.version;
      updatedStateObj.description = binPlusTableData.description;
      updatedStateObj.state = binPlusTableData.state;
      updatedStateObj.access = binPlusTableData.access;
      updatedStateObj.isDefault = binPlusTableData.isDefault;
    }
    this.setState(updatedStateObj);
  }

  getBinPlusGroupTypesBinTypes = async () => {
    const { history } = this.props;
    const workCenterId = history.location.state.workCenterType
      ? history.location.state.workCenterType.id
      : null;
    const data: any = await httpBinPlusTable.getBinPlusGroupTypesBinTypes(
      workCenterId
    );
    if (data) return data;
    return [];
  };

  getColumns = async () => {
    const { history } = this.props;
    const { id } = this.state;
    const workCenterId = history.location.state.workCenterType
      ? history.location.state.workCenterType.id
      : null;
    const data: any = await httpBinPlusTable.getBinPlusColumns(
      workCenterId,
      id
    );
    if (data) return data;
    return [];
  };

  getBinPlusTableData = async (id: string) => {
    const data: any = await httpBinPlusTable.getBinPlusTable(id);
    let stateData: any = {
      id,
      name: "",
      owner: "",
      version: 0,
      description: "",
      access: "PUBLIC",
      state: this.states[0][0],
      isDefault: false,
    };
    stateData = {
      id: data.id,
      name: data.name,
      owner: data.owner,
      version: data.version,
      description: data.description,
      state: data.state,
      access: data.access,
      isDefault: data.isDefault,
    };
    return stateData;
  };

  postBinPlusTableData = async (formData: BinPlusTableData): Promise<any> => {
    const { history } = this.props;
    const data: any = await httpBinPlusTable.postBinPlusTable({
      workCenterType: history.location.state.workCenterType,
      name: formData.name,
      owner: formData.owner,
      access: formData.access,
      description: formData.description,
      state: formData.state,
      createdBy: { Id: AppConstants.user.Id },
      updatedBy: { Id: AppConstants.user.Id },
    });
    if (data) {
      return Promise.resolve(data);
    }
    return Promise.resolve(null);
  };

  putBinPlusTable = async (
    id: string,
    formData: BinPlusTableData
  ): Promise<any> => {
    const data: any = await httpBinPlusTable.putBinPlusTable({
      id,
      name: formData.name,
      owner: formData.owner,
      access: formData.access,
      description: formData.description,
      state: formData.state,
      updatedBy: { Id: AppConstants.user.Id },
    });
    if (data) {
      return Promise.resolve(data);
    }
    return Promise.resolve(null);
  };

  validate = (formData: BinPlusTableData) => {
    const errors: { [key: string]: any } = {};
    if (!formData.name) {
      errors.name = "Bin Map Name Is Required";
    } else if (!new RegExp("^.{1,255}$").test(formData.name)) {
      errors.name = "Bin Map Name Should Not Exceed 255 Characters";
    }
    if (!formData.owner) {
      errors.owner = "Bin Map Owner Is Required";
    } else if (!new RegExp("^.{1,255}$").test(formData.owner)) {
      errors.owner = "Bin Map Owner Should Not Exceed 255 Characters";
    }
    if (!formData.state) {
      errors.state = "State Is Required";
    } else if (!new RegExp("^.{1,255}$").test(formData.state)) {
      errors.state = "State Should Not Exceed 255 Characters";
    }
    if (!formData.description) {
      errors.description = "Description Is Required";
    } else if (!new RegExp("^.{1,1023}$").test(formData.description)) {
      errors.description = "Description Should Not Exceed 1023 Characters";
    }
    return errors;
  };

  onSaveBinPlusTable = async (componentHeaderData: ComponentHeaderData) => {
    const { id } = this.state;
    const { history } = this.props;
    const { name, version, state, description, access, owner } =
      componentHeaderData;
    if (id === null) {
      const data = await this.postBinPlusTableData({
        name,
        version,
        state,
        description,
        access,
        owner,
      });
      if (data) {
        toast.success("Saved!");
        history.replace(`/create-bin-plus-table/${data.id}`, {
          id: data.id,
          workCenterType: history.location.state.workCenterType,
        });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      const data = await this.putBinPlusTable(id, {
        name,
        version,
        state,
        description,
        access,
        owner,
      });
      if (data) {
        toast.success("Updated!");
        this.setState({
          version: data.version,
          name,
          owner,
          access,
          state,
          description,
        });
      }
    }
  };

  onValueChanged = (cell: any, e: any) => {
    cell.setValue(e.value);
  };

  onAddNewColumn = async (columnInfo: any) => {
    const { id } = this.state;
    if (id !== null) {
      // string, number, date, boolean
      const columns = await httpBinPlusTable.postAttribute(
        {
          columnName: columnInfo.columnName.replace(/ /g, ""),
          columnLabel: columnInfo.columnName,
          type: columnInfo.columnType,
          isPublic: columnInfo.isPublic,
          minValue: columnInfo.minValue,
          maxValue: columnInfo.maxValue,
          textLength: +columnInfo.textLength,
        },
        id
      );
      this.setState({ columns });
    }
  };

  getDatagridColumns = (columns: any, prefix: string) => {
    let numberColumnId: string | null = null;
    const filteredColumns = columns.filter((x: any) => {
      return x.columnName === "number";
    });
    if (filteredColumns.length > 0) {
      numberColumnId = filteredColumns[0].id;
    }

    return columns.map((field: any, index: number) => {
      let dataType = "string";
      if (field.type === "DATETIME") dataType = "date";
      else if (field.type === "BOOL") dataType = "boolean";
      else if (field.type === "DECIMAL" || field.type === "LONG")
        dataType = "number";
      // eslint-disable-next-line no-undef-init
      let editCellRender = undefined;
      // eslint-disable-next-line no-undef-init
      let cellRender = undefined;
      if (field.type === "DROPDOWN") {
        editCellRender = (cell: any) => {
          return (
            <SelectBox
              defaultValue={cell.value}
              items={field.dropdownItems}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cell.column.lookup}
              onValueChanged={(event) => this.onValueChanged(cell, event)}
              dropDownOptions={getDropDownOptions("cell", cell)}
            />
          );
        };
      } else if (field.type === "COLOR") {
        editCellRender = (cell: any) => {
          return (
            <ColorBox
              defaultValue={cell.value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...cell.column.lookup}
              onValueChanged={(event) => this.onValueChanged(cell, event)}
              dropDownOptions={getDropDownOptions("cell", cell)}
            />
          );
        };
        cellRender = (e: any) => {
          if (e.data) {
            return (
              <div
                className="h20 mr5 mt0 ml0 mb0 "
                style={{ backgroundColor: e.data[field.id] }}
              />
            );
          }
          return null;
        };
      }
      return (
        <Column
          setCellValue={(newData: any, value: any) => {
            // eslint-disable-next-line no-param-reassign
            newData[field.id] = value;
          }}
          visibleIndex={field.sequence}
          // eslint-disable-next-line react/no-array-index-key
          key={`${field.columnName}${index}`}
          dataField={field.id}
          dataType={dataType}
          sortOrder={field.columnName === "number" ? "asc" : undefined}
          calculateSortValue={
            field.columnName === "number"
              ? (e: any) => {
                  if (numberColumnId !== null) {
                    // eslint-disable-next-line no-restricted-globals
                    return isNaN(+e[numberColumnId]) ? -1 : +e[numberColumnId];
                  }
                  return null;
                }
              : undefined
          }
          caption={`${prefix}${" "}${UtilityFunctions.capitalizeFirstLetter(
            field.columnLabel
          )}`}
          editCellRender={editCellRender}
          cellRender={cellRender}
        >
          {field.columnName === "number" ? (
            <PatternRule
              message="Bin Number Should Not Exceed 255 Characters and Cannot Be String"
              pattern={/^(([0-9]{1,255})|(.{1}))$/i}
            />
          ) : null}

          {field.columnName === "number" || field.columnName === "color" ? (
            <RequiredRule />
          ) : null}

          {dataType === "number" ? (
            <CustomRule
              message={`${prefix}${" "}${UtilityFunctions.capitalizeFirstLetter(
                field.columnLabel
              )} value must be between ${field.minValue} and ${field.maxValue}`}
              validationCallback={(e: any) => {
                return (
                  e.value === undefined ||
                  e.value === null ||
                  (+e.value >= +field.minValue && +e.value <= +field.maxValue)
                );
              }}
            />
          ) : null}
          {dataType === "string" ? (
            <StringLengthRule
              max={+field.textLength}
              message={`Value Should Not Exceed ${field.textLength} Characters`}
            />
          ) : null}
        </Column>
      );
    });
  };

  removeBinPlusDefinition = async (key: any) => {
    const { binPlusDefinitions } = this.state;
    const headerPrefix = this.getHeaderPrefix();
    let isPresentInAGrouping = false;
    let hasGroupings = false;
    for (let i = 0; i < binPlusDefinitions.length; i += 1) {
      if (
        binPlusDefinitions[i].id === key &&
        binPlusDefinitions[i].binPlusGroupings.length > 0
      ) {
        hasGroupings = true;
        break;
      }
      if (binPlusDefinitions[i].binPlusGroupings.includes(key)) {
        isPresentInAGrouping = true;
        break;
      }
    }
    if (hasGroupings) {
      if (
        await ModalPopup.confirm({
          header: "Remove Confirmation",
          body: `This ${headerPrefix} has grouped ${headerPrefix}s. Are you sure you want to remove it.`,
        })
      ) {
        await httpBinPlusTable.removeBinPlusDefinition(key);
      }
    } else if (isPresentInAGrouping) {
      if (
        await ModalPopup.confirm({
          header: "Remove Confirmation",
          body: `This ${headerPrefix} is part of a grouping. Are you sure you want to remove it.`,
        })
      ) {
        await httpBinPlusTable.removeBinPlusDefinition(key);
      }
    } else {
      await httpBinPlusTable.removeBinPlusDefinition(key);
    }
  };

  updateBinPlusDefinition = async (key: any, values: any) => {
    const { id, columns, binPlusDefinitions } = this.state;
    const headerPrefix = this.getHeaderPrefix();
    if (id !== null && key !== null) {
      const keyValuePairs: any = {};
      columns.forEach((columnMeta: any) => {
        if (!(columnMeta.id in values)) {
          // eslint-disable-next-line no-param-reassign
          values[columnMeta.id] = null;
          // eslint-disable-next-line no-param-reassign
          columnMeta.isUpdated = false;
        } else {
          // eslint-disable-next-line no-param-reassign
          columnMeta.isUpdated = true;
        }
        // eslint-disable-next-line no-param-reassign
        columnMeta.value = values[columnMeta.id];
        keyValuePairs[columnMeta.id] = values[columnMeta.id];
      });

      let shouldUpdate = false;
      if (
        values.binPlusTypes &&
        values.binPlusTypes.filter((x: string) => x.startsWith("Grouped"))
          .length > 0
      ) {
        const filteredBpds = binPlusDefinitions.filter(
          (x: any) => x.id === key
        );
        if (
          filteredBpds.length > 0 &&
          filteredBpds[0].binPlusGroupings.length > 0
        ) {
          if (
            await ModalPopup.confirm({
              header: "Update Confirmation",
              body: `This ${headerPrefix} has grouped ${headerPrefix}s. Updating ${headerPrefix} type will remove the current groupings.`,
            })
          ) {
            shouldUpdate = true;
          }
        } else {
          shouldUpdate = true;
        }
      } else {
        shouldUpdate = true;
      }
      if (shouldUpdate) {
        const response = await httpBinPlusTable.putBinPlusDefinition({
          id: key,
          keyValuePairs,
          columns,
          binPlusGroupings: values.binPlusGroupings,
          binPlusTypeNames: values.binPlusTypes,
          binPlusGroupTypeName: values.binPlusGroupType,
        });
        if (response) {
          if (response.success) {
            toast.success("Bin Plus Definition updated");
          } else {
            toast.error(response.message);
          }
        }
      }
    }
    return Promise.resolve(null);
  };

  // eslint-disable-next-line no-unused-vars
  loadBinPlusDefinition = async (loadOptions: any) => {
    const { id, columns } = this.state;
    if (id !== null) {
      const data = await httpBinPlusTable.getBinPlusDefinitions(id);
      if (
        data === null ||
        !Array.isArray(data) ||
        (Array.isArray(data) && data.length <= 0)
      ) {
        this.binPlusDefinitions = [];
        return [];
      }
      const ds: any = [];
      data.forEach((dataEntry: any) => {
        const d: any = {};
        d.id = dataEntry.id;
        d.binPlusGroupings = dataEntry.binPlusGroupings;
        d.binPlusGroupType = dataEntry.binPlusGroupTypeName;
        d.binPlusTypes = dataEntry.binPlusTypeNames;
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const key in dataEntry.keyValuePairs) {
          const column = columns.filter((col: any) => {
            return col.id === key;
          })[0];
          if (column && column.type === "BOOL") {
            let boolValue = dataEntry.keyValuePairs[key];
            if (boolValue === "True") boolValue = true;
            else if (boolValue === "False") boolValue = false;
            else boolValue = null;
            d[key] = boolValue;
          } else d[key] = dataEntry.keyValuePairs[key];
        }
        ds.push(d);
      });
      this.binPlusDefinitions = ds;
      return ds;
    }
    this.binPlusDefinitions = [];
    return [];
  };

  insertBinPlusDefinition = async (values: any) => {
    const { id, columns } = this.state;
    if (id !== null) {
      const keyValuePairs: any = {};
      columns.forEach((columnMeta: any) => {
        if (!(columnMeta.id in values)) {
          // eslint-disable-next-line no-param-reassign
          values[columnMeta.id] = null;
        }
        // eslint-disable-next-line no-param-reassign
        columnMeta.value = values[columnMeta.id];
        keyValuePairs[columnMeta.id] = values[columnMeta.id];
      });
      const response = await httpBinPlusTable.postBinPlusDefinition(
        {
          keyValuePairs,
          columns,
          binPlusGroupings: values.binPlusGroupings,
          binPlusTypeNames: values.binPlusTypes,
          binPlusGroupTypeName: values.binPlusGroupType,
        },
        id
      );
      if (response) {
        if (response.success) {
          toast.success("Bin Plus Definition added");
        } else {
          toast.error(response.message);
        }
      }
    }
    return Promise.resolve(null);
  };

  getHeaderPrefix = () => {
    const { history } = this.props;
    let headerPrefix = history.location.state.workCenterType
      ? history.location.state.workCenterType.binPlusDefinitionType.toLowerCase()
      : "bin";
    headerPrefix = UtilityFunctions.capitalizeFirstLetter(headerPrefix);
    return headerPrefix;
  };

  render() {
    const {
      name,
      formErrors,
      version,
      columns,
      binPlusDefinitions,
      isDefault,
      state,
      description,
      id,
      binPlusGroupTypesBinTypes,
      access,
      owner,
    } = this.state;
    let numberColumnId = "";
    const numberColumn = columns.filter((x) => x.columnName === "number");
    if (numberColumn.length > 0) {
      numberColumnId = numberColumn[0].id;
    }

    const { history } = this.props;
    const headerPrefix = this.getHeaderPrefix();
    let definitionContent = null;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: this.loadBinPlusDefinition,
        insert: this.insertBinPlusDefinition,
        // eslint-disable-next-line no-unused-vars
        onInserted: (values: any, key) => {
          const loadedData = dataSource.store.load();
          if (Array.isArray(loadedData)) {
            this.setState({ binPlusDefinitions: loadedData });
          } else {
            loadedData.then((data: any) => {
              this.binPlusDefinitions = data;
              this.setState({ binPlusDefinitions: data });
            });
          }
        },
        onLoaded: (data: any) => {
          this.setState((prevState: BinPlusTableState) => {
            if (!_.isEqual(prevState.binPlusDefinitions, data)) {
              this.setState({ binPlusDefinitions: data });
            }
          });
        },
        // eslint-disable-next-line no-unused-vars
        onUpdated: (key: any, values: any) => {
          const loadedData = dataSource.store.load();
          if (Array.isArray(loadedData)) {
            this.setState({ binPlusDefinitions: loadedData });
          } else {
            loadedData.then((data: any) => {
              this.binPlusDefinitions = data;
              this.setState({ binPlusDefinitions: data });
            });
          }
        },
        update: this.updateBinPlusDefinition,
        remove: this.removeBinPlusDefinition,
      }),
    };
    if (
      columns &&
      history.location.state &&
      "workCenterType" in history.location.state
    ) {
      definitionContent = (
        <div className="mt20">
          <h5>
            Add {headerPrefix}
            (s)
          </h5>
          <ErrorLabel error={formErrors.binPlusDefinitions} isTouched />
          <div className="float-right">
            <CreateDynamicColumn
              needExpression={false}
              getColumnInfo={(columnInfo: any) => {
                this.onAddNewColumn(columnInfo);
              }}
              buttonContent="Add Atrribute"
              onClick={(showCreateColumn: () => void) => {
                if (this.datagrid) {
                  if (!UtilityFunctions.isDataGridInEditMode(this.datagrid)) {
                    showCreateColumn();
                  } else {
                    toast.warn(
                      "Can not add attribute. Data grid is in edit mode."
                    );
                  }
                }
              }}
            />
          </div>
          <br />
          <DataGrid
            onCellPrepared={(cellElement: any) => {
              if (columns) {
                const retestColumn: any = columns.filter((column: any) => {
                  return column.columnName === "retest_enabled";
                });
                if (retestColumn.length > 0) {
                  if (
                    cellElement.column.dataField === retestColumn[0].id &&
                    cellElement.rowType === "data" &&
                    cellElement.row.isEditing
                  ) {
                    if (
                      cellElement.data.binPlusGroupType &&
                      cellElement.data.binPlusGroupType.startsWith("Grouped")
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      cellElement.cellElement.innerHTML =
                        '<div style="padding-top: 10px; padding-bottom: 10px; background-color: #ccc; text-align: center;"></div>';
                    }
                  }
                }
              }
            }}
            showBorders
            errorRowEnabled={false}
            columnMinWidth={100}
            ref={(datagrid) => {
              this.datagrid = datagrid;
            }}
            style={{ marginTop: "0px", clear: "both" }}
            dataSource={dataSource}
            hoverStateEnabled
            height="600px"
            allowColumnReordering
            rowAlternationEnabled
            showColumnLines={false}
            columnChooser={{ enabled: true, mode: "select" }}
          >
            <FilterRow visible />
            <Scrolling mode="standard" />
            <Selection mode="single" />
            {this.getDatagridColumns(columns, headerPrefix)}
            <Column
              dataField="binPlusGroupType"
              showWhenGrouped
              groupIndex={0}
              caption={`${headerPrefix} Group Type`}
              editCellRender={(cell: any) => {
                return (
                  <SelectBox
                    defaultValue={cell.value}
                    displayExpr="name"
                    items={binPlusGroupTypesBinTypes}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...cell.column.lookup}
                    onValueChanged={(event) => this.onValueChanged(cell, event)}
                    valueExpr="name"
                    dropDownOptions={getDropDownOptions("cell", cell)}
                  />
                );
              }}
              setCellValue={async (newData: any, value: any, currData: any) => {
                let shouldSetValue = true;
                if (
                  currData.binPlusGroupings &&
                  currData.binPlusGroupings.length > 0
                ) {
                  if (
                    !(await ModalPopup.confirm({
                      header: "Update Confirmation",
                      body: `This ${headerPrefix} has grouped ${headerPrefix}s. Are you sure you want to remove it.`,
                    }))
                  ) {
                    shouldSetValue = false;
                  }
                }
                if (shouldSetValue) {
                  // eslint-disable-next-line no-param-reassign
                  newData.binPlusGroupType = value;
                  // eslint-disable-next-line no-param-reassign
                  newData.binPlusTypes = "";
                  if (columns && value.startsWith("Grouped")) {
                    const retestColumn: any = columns.filter((column: any) => {
                      return column.columnName === "retest_enabled";
                    });
                    if (retestColumn.length > 0) {
                      // eslint-disable-next-line no-param-reassign
                      newData[retestColumn[0].id] = null;
                    }
                  }
                  // eslint-disable-next-line no-param-reassign
                  newData.binPlusGroupings = [];
                }
              }}
              allowFiltering
              width={200}
              allowSorting={false}
            >
              <RequiredRule />
            </Column>
            <Column
              setCellValue={(newData: any, value: any) => {
                // eslint-disable-next-line no-param-reassign
                newData.binPlusTypes = value;
              }}
              dataField="binPlusTypes"
              caption={`${headerPrefix} Types`}
              width={200}
              allowSorting={false}
              editCellComponent={BinPlusTypeTagBox}
              cellTemplate={(container: any, options: any) => {
                const noBreakSpace = "\u00A0";
                const text = (options.value || [])
                  .map((element: any) => {
                    return options.column.lookup.calculateCellValue(element);
                  })
                  .join(", ");
                // eslint-disable-next-line no-param-reassign
                container.textContent = text || noBreakSpace;
                // eslint-disable-next-line no-param-reassign
                container.title = text;
              }}
            >
              <Lookup
                dataSource={binPlusGroupTypesBinTypes.reduce(
                  (
                    prevVal: any,
                    currVal: any,
                    currIndex: number,
                    data: any
                  ) => {
                    const mappedBinPlusTypes = data[currIndex].binPlusTypes.map(
                      (binPlusType: any) => {
                        return {
                          ...binPlusType,
                          binPlusGroupTypeName: data[currIndex].name,
                        };
                      }
                    );
                    return prevVal.concat(mappedBinPlusTypes);
                  },
                  []
                )}
                valueExpr="name"
                displayExpr="name"
              />
              <RequiredRule />
            </Column>
            <Column
              minWidth={200}
              dataField="binPlusGroupings"
              caption={`${headerPrefix} Groupings`}
              allowSorting={false}
              editCellComponent={withProps(BinPlusGroupingsEditComponent, {
                columns,
                dataGridColumns: this.getDatagridColumns(columns, headerPrefix),
              })}
              cellTemplate={(container: any, options: any) => {
                const text = (options.value || [])
                  .map((element: any) => {
                    return options.column.lookup.calculateCellValue(element);
                  })
                  .sort()
                  .join(", ");
                // eslint-disable-next-line no-param-reassign
                container.textContent = text || "\u00A0";
                // eslint-disable-next-line no-param-reassign
                container.title = text;
              }}
            >
              <Lookup
                dataSource={binPlusDefinitions}
                valueExpr="id"
                displayExpr={numberColumnId}
              />
            </Column>
            <Editing
              useIcons
              allowUpdating={!isDefault}
              allowDeleting={!isDefault}
              allowAdding={!isDefault}
              confirmDelete={false}
              mode="row"
              newRowPosition="last"
            />
          </DataGrid>
        </div>
      );
    }

    return (
      <Container fluid className="body-content-scroll">
        <Row>
          <Col>
            <TopbarNav
              title={`${
                history.location.state.workCenterType
                  ? history.location.state.workCenterType.name
                  : "Generic"
              } Bin + Table`}
              items={[]}
              showAvatar={false}
              showNotifications={false}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Container fluid>
              <Row className="ml20 mr20">
                <Col className="custom-form stage-zero-border-top pt30 border-color-primary step-zero-block mt20">
                  <ComponentHeader
                    hideSaveButton={isDefault}
                    componentHeaderData={{
                      name,
                      version,
                      access,
                      description,
                      state,
                      owner,
                    }}
                    onParentSave={(data: ComponentHeaderData) => {
                      this.onSaveBinPlusTable(data);
                    }}
                    onBack={async () => {
                      if (
                        await ModalPopup.confirm({
                          header: "Override Alert",
                          body: "Are you sure that you have saved all your changes. Do you want to proceed?",
                        })
                      ) {
                        history.goBack();
                      }
                    }}
                  />
                  {id && definitionContent}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BinPlusTable);
