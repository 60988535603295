import React, { ReactElement } from 'react';
import { Accordion, Card } from 'react-bootstrap';

interface ICustomizedAccordionProps {
  content: {
    header: ReactElement,
    body: ReactElement,
  }[];
  headerClassNames?: string;
  bodyClassNames?: string;
  collapseOnLoad?: boolean;
  defaultActiveKey?: string;
  cardClassNames?: string;
}

const CustomizedAccordion = (props: ICustomizedAccordionProps) => {
  const {
    content,
    defaultActiveKey,
    cardClassNames,
    collapseOnLoad,
    headerClassNames,
    bodyClassNames,
  } = props;
  return (
    <Accordion defaultActiveKey={collapseOnLoad ? undefined : defaultActiveKey}>
      {content.map((item: any, index: number) => {
        return (
          <Card className={cardClassNames}>
            <Accordion.Toggle as={Card.Header} className={headerClassNames} eventKey={index.toString()}>
              {item.header}
            </Accordion.Toggle>
            <Accordion.Collapse className={bodyClassNames} eventKey={index.toString()}>
              {item.body}
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
};

CustomizedAccordion.defaultProps = {
  collapseOnLoad: false,
  defaultActiveKey: '0',
  cardClassNames: '',
  headerClassNames: '',
  bodyClassNames: '',
};

export default CustomizedAccordion;
