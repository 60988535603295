/* eslint-disable prefer-destructuring */
import React from 'react';
import { useLocation } from 'react-router-dom';
import WaferImageGenerator from '../../components/utility-component/wafer-image-generator/WaferImageGenerator';

const ViewWaferImageGenerator = () => {
  const location = useLocation();
  let data : any;
  let userId : any;
  if (location.pathname !== null && location.pathname !== undefined) {
    const arr = location.pathname.split('/');
    data = arr[2]; // FIXME!!
    userId = arr[3]; // FIXME!!
  }
  return (
    <WaferImageGenerator
      waferId={data !== undefined ? data : undefined}
      userId={userId !== undefined ? userId : undefined}
    />
  );
};

export default ViewWaferImageGenerator;
