import DatabaseDropdownGrid from 'components/database-component/DatabaseDropdownGrid';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import GeneralUtils from 'GeneralUtils';
import { IDatabase } from 'interfaces';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const DatabaseSelection = () => {
  const history = useHistory();
  const databases : any = JSON.parse(GeneralUtils.getCookieValue('USER_DETAILS')).databases.map((x: IDatabase) => [x.id, x.name, x.type]);

  const convertToIDatabaseObject = (arr: any): { id: string, name: string, type: string }[] => {
    return arr.map((item: any) => {
      return { id: item[0], name: item[1], type: item[2] };
    });
  }
  const [database, setDatabase] = useState<any>(databases.find((x: any) => x[0] === GeneralUtils.getCookieValue('DATABASE')));
  const [databaseIndex, setDatabaseIndex] = useState(databases.findIndex((x: any) => x[0] === database[0]));

  const updateDatabaseSelection = (value: string) => {
    setDatabaseIndex(databases.findIndex((x: any) => x[0] === value));
  }

  const updateDatabase = () => {
    setDatabase(databases[databaseIndex]);
    GeneralUtils.appendCookie('DATABASE', databases[databaseIndex][0]);
    history.replace('/change-database');
    toast.success("Database updated.");
  }

  const cancelDatabase = () => {
    setDatabaseIndex(databases.findIndex((x: any) => x[0] === database[0]));
  }

  return (
    <>
      <TopbarNav
        title={`Current Database -> (${database[1]} : ${database[2]})`}
        items={[]}
        showAvatar
        showNotifications
      />
      <div className="mx-5 my-5">
        <div className="row my-4">
          <h3 className="col-8">Change Database</h3>
          <div className="d-flex justify-content-end col-4">
            <Button className="mx-2 btn-success" onClick={updateDatabase}>Save</Button>
            <Button className="btn-danger" onClick={cancelDatabase}>Cancel</Button>
          </div>
        </div>
        <DatabaseDropdownGrid
          list={convertToIDatabaseObject(databases)}
          placeholder="Change Database"
          onChange={updateDatabaseSelection}
          defaultDatabaseId={databases[databaseIndex][0]}
          remainExpanded
        />
      </div>
    </>
  )
}

export default DatabaseSelection;
