import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';

interface IBinWaferMapComparisonSummaryTableProps {
  selectedCombinations: any;
}

class BinWaferMapComparisonSummaryTable extends Component<IBinWaferMapComparisonSummaryTableProps, any> {
  generateDataSource = (waferCombinations: any) => {
    const waferCombinationsDetails: any[] = [];
    waferCombinations.forEach((waferCombination: any) => {
      waferCombinationsDetails.push({
        baseWaferId: waferCombination.baseWafer.key,
        baseWaferProbeCount: waferCombination.baseWafer.probeCount,
        nonBaseWaferId: waferCombination.nonBaseWafer.key,
        nonBaseWaferProbeCount: waferCombination.nonBaseWafer.probeCount,
        baseWaferYield: waferCombination.baseWafer.yield,
        nonBaseWaferYield: waferCombination.nonBaseWafer.yield,
        yieldDifference: `${((waferCombination.nonBaseWafer.yield) - (waferCombination.baseWafer.yield)).toFixed(2)}%`
      });
    });
    return waferCombinationsDetails;
  };

  renderColoredText = (e: any) => {
    if (e.row.data.yieldDifference > 0){
      return <p className="text-success">{e.row.data.yieldDifference}</p>;
    }
    return <p className="text-danger">{e.row.data.yieldDifference}</p>;
  }

  render() {
    const { selectedCombinations } = this.props;
    return (
      <div>
        <Row>
          <Col lg={12}>
            <div className="mt10">
              <p className="font-weight-bold font-size-12">Summary Table</p>
              <DataGrid
                height="auto"
                showBorders
                dataSource={Object.keys(selectedCombinations).length > 0 ? this.generateDataSource(selectedCombinations) : []}
              >
                <Column caption="Base Wafer">
                  <Column alignment="left" caption="ID" dataField="baseWaferId" />
                  <Column alignment="left" caption="Probe Count" dataField="baseWaferProbeCount" />
                  <Column alignment="left" caption="Yield" dataField="baseWaferYield" />
                </Column>
                <Column caption=" Non Base Wafer">
                  <Column alignment="left" caption="ID" dataField="nonBaseWaferId" />
                  <Column alignment="left" caption="Probe Count" dataField="nonBaseWaferProbeCount" />
                  <Column alignment="left" caption="Yield" dataField="nonBaseWaferYield" />
                </Column>
                <Column
                  alignment="left"
                  caption="%"
                  dataField="yieldDifference"
                  cellRender={(e:any) => this.renderColoredText(e)}
                />
              </DataGrid>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BinWaferMapComparisonSummaryTable;
