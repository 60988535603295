import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { ScrollView } from 'devextreme-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Dies } from './Utils/WaferMapVariablesClassV2';
import WaferPlotter from './WaferPlotter/WaferPlotter';
import { httpService } from '../../../../services/http.service';

type TestingProps = {

};

type TestingState = {
  vpX: number,
  vpY: number,
};

export default class Testing extends React.Component<TestingProps, TestingState> {
  glCanvas: HTMLCanvasElement | null = null;

  textCanvas: HTMLCanvasElement | null = null;

  plotter: WaferPlotter | null = null;

  colors = ['#B94A60', '#93A6FA', '#01241D', '#E76B7E', '#C12BE2', '#D51508', '#CBDCCF', '#DDE43B', '#220BA5',
    '#DEABCE', '#621462', '#9C4B6E', '#ED118C', '#C7F968', '#F2C980', '#94DE71', '#F44AF6', '#C4D6E2', '#63E97D', '#7D7BB8',
    '#48A7C4', '#D5D112', '#DD344F', '#8B365F', '#9A9C9A', '#91CD81', '#DA193D', '#C0C17F', '#4F68C7', '#A7616F', '#AEEA6B',
    '#D6C6EF', '#7BA463', '#8CD150', '#025C76', '#D4A781', '#4D0D40', '#653715', '#3865F7', '#DD6919', '#A24481', '#CB6091',
    '#CC40B4', '#3114DB', '#DFA20D', '#A8DBF8', '#B6DDA7', '#4E3A48', '#F59CE7', '#55A9AA', '#048BAD', '#576729', '#EA0CAC',
    '#6BEECA', '#E1925D', '#166BFF', '#5A02F7', '#842EC1', '#460101', '#D2878A', '#17ECF9', '#8751BF', '#8784B5', '#DDECB8',
    '#2F5F66', '#12E1ED', '#CDE714', '#44E8A9', '#A0F1EA', '#74CDC3', '#02BA75', '#868DDE', '#827E22', '#03A18A', '#075135',
    '#E49962', '#757F50', '#2F51A1', '#008C0A', '#53791F', '#2E7E77', '#F56266', '#2167D7', '#1F7946', '#B26F55', '#B09C15',
    '#17D3B5', '#7217B1', '#A111AC', '#43CDC0', '#16E013', '#4486F6', '#03791C', '#F7580C', '#EB5974', '#5A98DA', '#570B5D',
    '#B7B4C3', '#69969B', '#2DC05C'];

  // eslint-disable-next-line no-useless-constructor
  constructor(props: TestingProps) {
    super(props);
    this.state = {
      vpX: 400,
      vpY: 400,
    };
  }

  componentDidMount() {
  }

  checkPoint = (h: number, k: number, a: number, b: number, x: number, y: number) => {
    return (x - h) ** 2 / a ** 2 + (y - k) ** 2 / b ** 2;
  };

  randomWaferDataWithBinNum = (h: number, k: number, a: number, b: number) => {
    const data: Dies = [];
    const xinit = h - a + 1;
    let f = true;
    // let count = 0;
    for (let x = h - a + 1; x < h + a; x += 1) {
      data.push([]);
      for (let y = k - b + 1; y < k + b; y += 1) {
        const xIndex = x - xinit;
        if (this.checkPoint(h, k, a, b, x, y) < 1.0) {
          // count += 1;
          data[xIndex].push({
            softBin: Math.floor(Math.random() * 999),
          });
          const yIndex = data[xIndex].length - 1;
          if (f) {
            data[xIndex][yIndex]!.binColor = '#FF0000';
            f = false;
          } else {
            data[xIndex][yIndex]!.binColor = this.colors[Math.floor(Math.random() * this.colors.length)];
          }
        } else {
          data[xIndex].push(null);
        }
      }
    }

    return data;
  };

  x = 0;

  y = 0;

  id = 0;

  vps = [
    [20, 4650], [470, 4650], [920, 4650],
    [20, 4250], [470, 4250], [920, 4250],
    [20, 3850], [470, 3850], [920, 3850],
    [20, 3450], [470, 3450], [920, 3450],
    [20, 3050], [470, 3050], [920, 3050],
  ];

  aAddWafer = () => {
    const { vpX, vpY } = this.state;
    // const width = Math.floor(Math.random() * 20) + 5;
    const width = 400;
    if (this.plotter) {
      this.plotter.addWafer({
        outerViewport: {
          x: this.vps[this.id][0],
          y: this.vps[this.id][1],
          width: 300,
          height: 300,
          // width: +vpX,
          // height: +vpY,
        },
        dies: this.randomWaferDataWithBinNum(
          150,
          250,
          width,
          width / 2,
        ),
        keyIndex: `wafer${this.id.toString()}`,
        hasMarkingFeature: false,
        hasRotationControls: true,
        dieSize: { dieWidth: 2, dieHeight: 1 },
      });
      this.x += 600;
      this.id += 1;
    }
  };

  getOuterViewportBasedOnRadar = (showRadar: boolean) => {
    if (showRadar) {
      const outervpWidth = (3 * 1100) / 4;
      return {
        x: 0,
        y: 1100 - outervpWidth,
        width: outervpWidth,
        height: outervpWidth,
      };
    }
    return {
      x: 0,
      y: 1300 - 1100,
      width: 1100,
      height: 1100,
    };
  };

  onAddWafer = async () => {
    // const response = await httpService.getBinWaferMapData(['225acf75-20ca-4cc5-9078-050831ff6328']);
    console.log(new Date());
    const response = await httpService.getBinWaferMapData(['3d40c138-e14b-4af5-a222-7e6bba18ffca']);
    console.log(new Date());
    if (this.plotter) {
      this.plotter.addOrReplaceWafer({
        outerViewport: this.getOuterViewportBasedOnRadar(true),
        shouldUseOnlyBinColor: false,
        dies: response[0].dies,
        keyIndex: 'deviceWafer',
        hasMarkingFeature: false,
        hasRotationControls: false,
        dieSize: { dieWidth: 32, dieHeight: 32 },
        showRadar: false,
        showWaferInfo: false,
        dieTypeField: 'dieType',
        rowsTakenByWaferBG: 0,
        colsTakenByWaferBG: 0,
        // currentDieType: dieTypes.length > 0 ? UtilityFunctions.getDieTypeFromId(currentDieTypeId, dieTypes)!.id : undefined,
      }, () => {
        this.forceUpdate();
      });
    }
  };

  render() {
    const { vpX, vpY } = this.state;
    return (
      <ScrollView direction="both">
        <div style={{ margin: '20px 20px' }}>
          <Button className="mb10" onClick={this.onAddWafer}>Add Wafer</Button>
          <WaferPlotter
            plotterKey="testing"
            width={1350}
            height={5000}
            ref={(r) => { this.plotter = r; }}
            pinControlsToWaferTop={false}
            config={{
              wafer0: {
                contextMenuDataSource: [
                  {
                    action: 'insert',
                    dimension: 'row',
                    direction: 'below',
                    text: 'Row Below',
                    closeMenuOnClick: true,
                  },
                ],
                onContextMenuItemClick: (event: any) => {

                },
              },
              wafer1: {
                contextMenuDataSource: [
                  {
                    action: 'insert',
                    dimension: 'row',
                    direction: 'below',
                    text: 'Col Below',
                    closeMenuOnClick: true,
                  },
                ],
                onContextMenuItemClick: (event: any) => {

                },
              },
            }}
          />
          {/* <WaferPlotter width={1350} height={5000} ref={(r) => { this.plotter = r; }} pinControlsToWaferTop /> */}
          <Textbox
            autoComplete="off"
            type="number"
            name="vpX"
            placeholder=""
            value={vpX}
            onChange={(e: any) => {
              this.setState({ vpX: e.target.value });
            }}
          />
          <Textbox
            autoComplete="off"
            type="number"
            name="vpY"
            placeholder=""
            value={vpY}
            onChange={(e: any) => {
              this.setState({ vpY: e.target.value });
            }}
          />
        </div>
      </ScrollView>
    );
  }
}
