import React from 'react';
import DataGrid, { Column, RowDragging, Button as DxButton, } from 'devextreme-react/data-grid';
import { Col } from 'react-bootstrap';
import Heading from '../../../../wrapped-component/hint-controls/Heading';

class ApplyingAndSortingGrid extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
    this.state = {
      availableRows: props.availableRows,
      appliedRows: props.appliedRows,
      columnNames: props.columnNames,
    };
  }

  generateColumns = (isSelectedGrid: boolean) => {
    const { columnNames } = this.state;
    const columns:any[] = [];
    columnNames.forEach((element:any) => {
      columns.push(
        <Column
          dataField={element}
          caption={element}
        />,
      );
    });
    let button: any;
    if (!isSelectedGrid) {
      button = (
        <Column
          type="buttons"
          width={36}
        >
          <DxButton
            hint="Add to selected list"
            icon="add"
            cssClass="text-success"
            onClick={this.onSelectionHandler}
          />
        </Column>
      );
    } else {
      button = (
        <Column type="buttons" width={40}>
          <DxButton
            hint="Delete from selected list"
            icon="close"
            cssClass="text-danger"
            onClick={this.onDeletionHandler}
          />
        </Column>
      );
    }
    columns.push(button);
    return columns;
  };

  onSelectionHandler = (e:any) => {
    const { availableRows, appliedRows } = this.state;
    const { onUpdate } = this.props;
    const item : any = e.row.data;

    const index = availableRows.indexOf(item);
    if (index > -1) {
      availableRows.splice(index, 1);
    }

    appliedRows.push(item);
    this.setState({ appliedRows, availableRows });

    onUpdate(appliedRows);
  };

  onDeletionHandler = (e:any) => {
    const { availableRows, appliedRows } = this.state;
    const { onUpdate } = this.props;
    const item : any = e.row.data;
    const index = appliedRows.indexOf(item);
    if (index > -1) {
      appliedRows.splice(index, 1);
    }
    availableRows.push(item);

    this.setState({ appliedRows, availableRows });
    onUpdate(appliedRows);
  };

  onReorder = (e:any) => {
    const { appliedRows } = this.state;
    const { onUpdate } = this.props;
    const { toIndex, fromIndex } = e;

    const element = appliedRows[fromIndex];
    appliedRows.splice(fromIndex, 1);
    appliedRows.splice(toIndex, 0, element);
    this.setState(appliedRows);
    onUpdate(appliedRows);
  };

  render() {
    const { availableRows, appliedRows } = this.state;
    return (
      <div className="widget-container side-by-side border-all pl10 pr10 pb20 border-radius-4">
        <Col lg={6}>
          <Heading size={6}>Available Rules</Heading>
          <DataGrid
            columnAutoWidth
            dataSource={availableRows.filter((el:any) => { return el.isVisible === true; })}
            keyExpr="ID"
            showBorders
          >

            {this.generateColumns(false)}
          </DataGrid>
        </Col>
        <Col lg={6}>
          <Heading size={6}>Selected Rules</Heading>
          <DataGrid
            columnAutoWidth
            dataSource={appliedRows.filter((el:any) => { return el.isVisible === true; })}
            keyExpr="ID"
            showBorders
          >
            <RowDragging
              allowReordering
              onReorder={this.onReorder}
            />
            {this.generateColumns(true)}
          </DataGrid>
        </Col>
      </div>
    );
  }
}

export default ApplyingAndSortingGrid;
