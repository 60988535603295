import Heading from 'components/wrapped-component/hint-controls/Heading';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  Alert, Col, Container, Row, Spinner, Tab, Tabs,
} from 'react-bootstrap';
import './device-setup.scss';
import _ from 'lodash';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import { withRouter } from 'react-router-dom';
import { httpBinPlusTable } from 'services/http.bin-plus-table';
import { httpDeviceSetup } from 'services/http.device-setup';
import toast from 'CustomToast';
import ComponentHeader, { componentHeaderStates } from 'components/wrapped-component/component-header/component-header';
// eslint-disable-next-line no-unused-vars
import { ComponentHeaderData } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { ErrorLabel } from 'components/template-component/form-error-label/ErrorLabel';
import { httpWaferControlMap } from 'services/http.wafer-control-map';
import { httpMasterMeta } from 'services/http.master-meta';
import DeviceSetupWorkCenter from './device-setup-work-center';
import AttributesGrid from './attributes-grid';
import AppConstants from '../../constants.js';

type DeviceSetupState = {
  id: string | null,
  allDeviceAttributes: any[],
  workCenterAttributes: { [key: string]: any[] },
  workCenterAttributeDefinitions: { [key: string]: any[] },
  generalAttributeDefinitions: any[],
  workCenterTypes: any[],
  binPlusTables: any[],
  waferControlMaps: any[],
  name: string,
  owner: string,
  access: string,
  version: number,
  state: string,
  description: string,
  activeWorkCenterTabKey: string,
  dsWorkCenters: any[],
  newWCNameError: string,
  masterMetaColumns: { [entityType: string]: any[] }
  isMasterMetaColumnsRetrieved: boolean,
};

export const levels = [
  {
    name: 'Facility',
  },
  {
    name: 'Work Center',
  },
  {
    name: 'Device',
  },
  {
    name: 'Test Program',
  },
  {
    name: 'Test Program Revision',
  },
  {
    name: 'Lot',
  },
  {
    name: 'Wafer',
  },
  {
    name: 'Die',
  },
];

export const DataTypes = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  BIN_PLUS_TABLE: 'bin plus table',
  WAFER_CONTROL_MAP: 'wafer control map',
};

class DeviceSetup extends React.Component<any, DeviceSetupState> {
  refreshTimeout: any = null;

  newWCNameRef: any = null;

  dsWCRef: any = {};

  constructor(props: any) {
    super(props);
    const { history } = this.props;
    this.state = {
      dsWorkCenters: [],
      masterMetaColumns: {},
      id: history.location.state && 'id' in history.location.state ? history.location.state.id : null,
      allDeviceAttributes: [],
      generalAttributeDefinitions: [],
      workCenterAttributes: {},
      workCenterAttributeDefinitions: {},
      workCenterTypes: [],
      binPlusTables: [],
      waferControlMaps: [],
      name: '',
      owner: '',
      access: 'PUBLIC',
      version: 1,
      state: componentHeaderStates[0][0],
      description: '',
      activeWorkCenterTabKey: '',
      newWCNameError: '',
      isMasterMetaColumnsRetrieved: false,
    };
  }

  static getDerivedStateFromProps(props: any) {
    return {
      id: props.history.location.state && 'id' in props.history.location.state ? props.history.location.state.id : null,
    };
  }

  async componentDidMount() {
    const { id } = this.state;
    const dSWorkCenters = await httpDeviceSetup.getDSWorkCenter();
    const updatedStateObj: any = {
      dsWorkCenters: dSWorkCenters,
      activeWorkCenterTabKey: dSWorkCenters.length <= 0 ? '' : dSWorkCenters[dSWorkCenters.length - 1].id,
    };
    if (id !== null) {
      const data = await this.getDeviceSetupData(id).catch(() => { toast.error('Could not get device setup data.'); });
      if (data) {
        updatedStateObj.id = data.id;
        updatedStateObj.name = data.name;
        updatedStateObj.version = data.version;
        updatedStateObj.description = data.description;
        updatedStateObj.access = data.access;
        updatedStateObj.owner = data.owner;
        updatedStateObj.state = data.state;
      }
    }
    updatedStateObj.workCenterTypes = await httpBinPlusTable.getWorkCenterTypes();
    updatedStateObj.binPlusTables = await httpBinPlusTable.getBinPlusTables();
    const wcmDataResponse = await httpWaferControlMap.getWaferControlMaps();
    if (wcmDataResponse && wcmDataResponse.success && wcmDataResponse.statusCode === 200) {
      updatedStateObj.waferControlMaps = wcmDataResponse.data;
    }
    updatedStateObj.allDeviceAttributes = this.mapAllDeviceAttributes(await httpDeviceSetup.getGeneralAttributes());
    updatedStateObj.workCenterAttributes = this.mapDSWCAttributes(await httpDeviceSetup.getDSWCAttributes());
    if (id !== null) {
      updatedStateObj.generalAttributeDefinitions = this.mapAttributeDefinitions(await httpDeviceSetup.getGeneralAttributeDefinitions(id));
      updatedStateObj.workCenterAttributeDefinitions = this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id));
    }

    const columnMetaSpecObject = {
      isCascading: false,
      isPascalCase: true,
    };
    updatedStateObj.masterMetaColumns = await httpMasterMeta.getColumnsList(columnMetaSpecObject);
    updatedStateObj.isMasterMetaColumnsRetrieved = true;
    this.setState(updatedStateObj);
  }

  mapAttributeDefinitions = (attributeDefinitions: any[]) => {
    const attributeDefinitionsMapped: any[] = [];
    attributeDefinitions.forEach((def: any) => {
      let dataType = DataTypes.STRING;
      if (def.column.type === 'DECIMAL') dataType = DataTypes.NUMBER;
      else if (def.column.type === 'DATETIME') dataType = DataTypes.DATE;
      else if (def.column.type === 'BOOL') dataType = DataTypes.BOOLEAN;
      else if (def.column.type === 'BIN_PLUS_TABLE') dataType = DataTypes.BIN_PLUS_TABLE;
      else if (def.column.type === 'WAFER_CONTROL_MAP') dataType = DataTypes.WAFER_CONTROL_MAP;
      attributeDefinitionsMapped.push({
        id: def.id,
        name: def.column.id,
        label: def.column.columnLabel,
        dataType,
        value: def.expressionValue,
        access: def.access,
        level: Object.assign({}, ...def.definitionLevels.map((x: any) => ({ [x.levelType]: x.levelExpression }))),
      });
    });
    return attributeDefinitionsMapped;
  };

  getDeviceSetupData = async (id: string) => {
    const data: any = await httpDeviceSetup.getDeviceSetup(id);
    return {
      id: data.id,
      name: data.name,
      owner: data.owner,
      version: data.version,
      description: data.description,
      state: data.state,
      access: data.access,
    };
  };

  mapAllDeviceAttributes = (allDeviceAttributesData: any[]) => {
    const allAttributes: any[] = [];
    allDeviceAttributesData.forEach((atr: any) => {
      let dataType = DataTypes.STRING;
      if (atr.type === 'DECIMAL') dataType = DataTypes.NUMBER;
      else if (atr.type === 'DATETIME') dataType = DataTypes.DATE;
      else if (atr.type === 'BOOL') dataType = DataTypes.BOOLEAN;
      else if (atr.type === 'BIN_PLUS_TABLE') dataType = DataTypes.BIN_PLUS_TABLE;
      else if (atr.type === 'WAFER_CONTROL_MAP') dataType = DataTypes.WAFER_CONTROL_MAP;

      allAttributes.push({
        id: atr.id,
        columnName: atr.columnName,
        columnLabel: atr.columnLabel,
        columnType: dataType,
        isPublic: atr.isPublic,
        minValue: atr.minValue,
        maxValue: atr.maxValue,
        textLength: atr.textLength,
      });
    });
    return allAttributes;
  };

  mapDSWCAttributes = (dSWCAttributes: any) => {
    const dSWCAttributesMapped: any = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(dSWCAttributes)) {
      dSWCAttributesMapped[key] = this.mapAllDeviceAttributes(value as any[]);
    }
    return dSWCAttributesMapped;
  };

  mapDSWCAttributeDefinitions = (dsWCAttributeDefinitions: any) => {
    const dSWCAttributeDefinitionsMapped: any = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(dsWCAttributeDefinitions)) {
      dSWCAttributeDefinitionsMapped[key] = this.mapAttributeDefinitions(value as any[]);
    }
    return dSWCAttributeDefinitionsMapped;
  };

  onValueChanged = (cell: any, e: any) => {
    cell.setValue(e.value);
  };

  onSaveDeviceSetup = async (componentHeaderData: ComponentHeaderData) => {
    const { id } = this.state;
    const { history } = this.props;
    const {
      name, access, state, description, owner,
    } = componentHeaderData;
    if (id === null) {
      const data = await this.postDeviceSetupData({
        name, access, state, description, owner,
      });
      if (data) {
        toast.success('Saved!');
        history.push(`/create-device-setup/${data.id}`, { id: data.id });
        if (data.id !== null) {
          this.setState({
            owner,
            name,
            access,
            state,
            description,
            generalAttributeDefinitions: this.mapAttributeDefinitions(await httpDeviceSetup.getGeneralAttributeDefinitions(data.id)),
            workCenterAttributeDefinitions: this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(data.id)),
          });
        }
      }
    } else {
      const data = await this.putDeviceSetup(id, {
        name, access, state, description, owner,
      });
      if (data) {
        toast.success('Updated!');
        this.setState({
          version: data.version,
          name,
          owner,
          access,
          state,
          description,
        });
      }
    }
  };

  postDeviceSetupData = async (formData: any): Promise<any> => {
    const data: any = await httpDeviceSetup.postDeviceSetup({
      name: formData.name,
      owner: formData.owner,
      description: formData.description,
      state: formData.state,
      access: formData.access,
      createdBy: { Id: AppConstants.user.Id },
      updatedBy: { Id: AppConstants.user.Id },
    });
    if (data) {
      return Promise.resolve(data);
    }
    return Promise.resolve(null);
  };

  putDeviceSetup = async (id: string, formData: any): Promise<any> => {
    const data: any = await httpDeviceSetup.putDeviceSetup({
      id,
      name: formData.name,
      owner: formData.owner,
      description: formData.description,
      state: formData.state,
      access: formData.access,
      updatedBy: { Id: AppConstants.user.Id },
    });
    if (data) {
      return Promise.resolve(data);
    }
    return Promise.resolve(null);
  };

  onAddAttributeDefinition = async (data: any, dsWCId: string | null = null) => {
    const { id } = this.state;
    if (id !== null) {
      const definitionData = {
        column: { id: data.name },
        deviceSetup: { id },
        deviceSetupWorkCenter: dsWCId !== null ? { id: dsWCId } : null,
        expressionValue: data.value.toString(),
        access: data.access,
        isDefaultDefinition: false,
        definitionLevels: this.getDefinitionLevelsFromData(data.level),
      };
      this.setState({
        generalAttributeDefinitions: this.mapAttributeDefinitions(await httpDeviceSetup.postGeneralAttributeDefinition(definitionData)),
        workCenterAttributeDefinitions: this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id)),
      });
    }
  };

  onDeleteAttributeDefinition = async (defId: string) => {
    const { id } = this.state;
    if (id !== null) {
      this.setState({
        generalAttributeDefinitions: this.mapAttributeDefinitions(await httpDeviceSetup.deleteGeneralAttributeDefinition(defId)),
        workCenterAttributeDefinitions: this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id)),
      });
    }
  };

  getDefinitionLevelsFromData = (level: any) => {
    return level ? Object.entries(level).map(([key, value]: any) => {
      return {
        levelType: key,
        levelExpression: value,
      };
    }) : [];
  };

  onUpdateAttributeDefinition = async (data: any, dsWCId: string | null = null) => {
    const { id } = this.state;
    if (id !== null) {
      const definitionData = {
        id: data.id,
        column: { id: data.name },
        deviceSetup: { id },
        deviceSetupWorkCenter: dsWCId !== null ? { id: dsWCId } : null,
        expressionValue: data.value.toString(),
        access: data.access,
        isDefaultDefinition: false,
        definitionLevels: this.getDefinitionLevelsFromData(data.level),
      };
      this.setState({
        generalAttributeDefinitions: this.mapAttributeDefinitions(await httpDeviceSetup.putGeneralAttributeDefinition(definitionData)),
        workCenterAttributeDefinitions: this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id)),
      });
    }
  };

  onSaveWorkCenter = async (workCenterData: any) => {
    const newDSWorkCenters = await httpDeviceSetup.putDSWorkCenter(workCenterData);
    toast.success('Device Setup Work Center Updated');
    this.setState({
      dsWorkCenters: newDSWorkCenters,
      activeWorkCenterTabKey: newDSWorkCenters.length <= 0 ? '' : newDSWorkCenters[newDSWorkCenters.length - 1].id,
    });
  };

  onDeleteWorkCenter = async (dsWCId: string) => {
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: 'This action will delete the work center. Attribute defintions will be lost. Do you want to proceed?',
    })) {
      const { id } = this.state;
      const newDSWorkCenters = await httpDeviceSetup.deleteDSWorkCenter(dsWCId);
      toast.success('Device Setup Work Center Removed');
      const updatedState: any = {
        dsWorkCenters: newDSWorkCenters,
        activeWorkCenterTabKey: newDSWorkCenters.length <= 0 ? '' : newDSWorkCenters[newDSWorkCenters.length - 1].id,
      };
      if (id !== null) updatedState.workCenterAttributeDefinitions = this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id));
      this.setState(updatedState);
    }
  };

  onCopyWorkCenter = async (dsWCId: string) => {
    if (this.newWCNameRef === null) return;
    const newName = this.newWCNameRef.value as string;
    if (newName === '') {
      this.setState({ newWCNameError: 'Work center name is required' });
    } else if (newName.length > 255) {
      this.setState({ newWCNameError: 'Name cannot exceed 255 characters' });
    } else {
      this.setState({ newWCNameError: '' }, async () => {
        const { id } = this.state;
        const newDSWorkCenters = await httpDeviceSetup.copyDSWorkCenter(dsWCId, { name: newName });
        toast.success('Device Setup Work Center Copied');
        const updatedState: any = {
          dsWorkCenters: newDSWorkCenters,
          activeWorkCenterTabKey: newDSWorkCenters.length <= 0 ? '' : newDSWorkCenters[newDSWorkCenters.length - 1].id,
        };
        if (id !== null) updatedState.workCenterAttributeDefinitions = this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id));
        this.setState(updatedState);
      });
    }
  };

  onDeleteGeneralAttribute = async (atrId: string, callback: () => void) => {
    const { id } = this.state;
    let response = await httpDeviceSetup.deleteGeneralAttribute(atrId, true);
    let confirmation = false;
    let fetchData = false;
    let generalAttributeDefinitions: any[] = [];
    let workCenterAttributeDefinitions = {};
    let workCenterAttributes = {};
    let allDeviceAttributes: any[] = [];

    if (response) {
      if (response.success) {
        fetchData = true;
        confirmation = true;
      } else {
        confirmation = await ModalPopup.confirm({
          header: 'Confirmation',
          body: 'This attribute is defined in some device setup. Do you want to proceed deleting it?',
        }) as boolean;
        if (confirmation) {
          response = await httpDeviceSetup.deleteGeneralAttribute(atrId, false);
          fetchData = true;
        }
      }
    }

    if (fetchData) {
      if (id !== null) {
        generalAttributeDefinitions = this.mapAttributeDefinitions(await httpDeviceSetup.getGeneralAttributeDefinitions(id));
        workCenterAttributeDefinitions = this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id));
      }
      workCenterAttributes = this.mapDSWCAttributes(await httpDeviceSetup.getDSWCAttributes());
      allDeviceAttributes = this.mapAllDeviceAttributes(response.data);
    }

    this.setState((prevState: DeviceSetupState) => {
      const newState = {
        generalAttributeDefinitions: prevState.generalAttributeDefinitions,
        workCenterAttributeDefinitions: prevState.workCenterAttributeDefinitions,
        workCenterAttributes: prevState.workCenterAttributes,
        allDeviceAttributes: prevState.allDeviceAttributes,
      };
      if (!response || !confirmation) return newState;

      if (response.success || confirmation) {
        toast.success('Attribute Removed');
        if (id !== null) {
          newState.generalAttributeDefinitions = generalAttributeDefinitions;
          newState.workCenterAttributeDefinitions = workCenterAttributeDefinitions;
        }
        newState.workCenterAttributes = workCenterAttributes;
        newState.allDeviceAttributes = allDeviceAttributes;
      }
      return newState;
    }, () => {
      if (response && confirmation) {
        callback();
      }
    });
  };

  onAddAttribute = async (columnInfo: any, callback: any) => {
    // used to add both DS and DSWC UDAs
    const data = await httpDeviceSetup.postGeneralAttribute({
      columnName: columnInfo.columnName,
      columnLabel: columnInfo.columnLabel,
      type: columnInfo.columnType,
      entityType: columnInfo.entityType,
      overrideInDSWC: columnInfo.overrideInWorkCenters,
      isPublic: columnInfo.isPublic,
      minValue: columnInfo.minValue,
      maxValue: columnInfo.maxValue,
      textLength: +columnInfo.textLength,
    });
    toast.success('New Attribute Created');
    this.setState({
      workCenterAttributes: this.mapDSWCAttributes(await httpDeviceSetup.getDSWCAttributes()),
      allDeviceAttributes: this.mapAllDeviceAttributes(data.allAttributes),
    }, () => {
      callback(data.currentAttributeId);
    });
  };

  onAddWorkCenterAttribute = async (columnInfo: any, workCenterTypeId: string, callback: any) => {
    // eslint-disable-next-line no-param-reassign
    columnInfo.overrideInWorkCenters.push(workCenterTypeId);
    this.onAddAttribute(columnInfo, callback);
  };

  onDeleteWorkCenterAttribute = async (attributeId: any, workCenterTypeId: string, callback: () => void) => {
    const { id } = this.state;
    let response = await httpDeviceSetup.deleteWorkCenterAttribute(attributeId, workCenterTypeId, true);
    let confirmation = false;
    let fetchData = false;
    let workCenterAttributeDefinitions = {};
    let workCenterAttributes = {};

    if (response) {
      if (response.success) {
        fetchData = true;
        confirmation = true;
      } else {
        confirmation = await ModalPopup.confirm({
          header: 'Confirmation',
          body: 'This attribute is defined in some device setup. Do you want to proceed deleting it?',
        }) as boolean;
        if (confirmation) {
          response = await httpDeviceSetup.deleteWorkCenterAttribute(attributeId, workCenterTypeId, false);
          fetchData = true;
        }
      }
    }

    if (fetchData) {
      if (id !== null) {
        workCenterAttributeDefinitions = this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id));
      }
      workCenterAttributes = this.mapDSWCAttributes(response.data);
    }

    this.setState((prevState: DeviceSetupState) => {
      const newState = {
        workCenterAttributeDefinitions: prevState.workCenterAttributeDefinitions,
        workCenterAttributes: prevState.workCenterAttributes,
      };
      if (!response || !confirmation) return newState;

      if (response.success || confirmation) {
        toast.success('Attribute Removed From Work Center');
        if (id !== null) {
          newState.workCenterAttributeDefinitions = workCenterAttributeDefinitions;
        }
        newState.workCenterAttributes = workCenterAttributes;
      }
      return newState;
    }, () => {
      if (response && confirmation) {
        callback();
      }
    });
  };

  render() {
    const { className, history } = this.props;
    const {
      workCenterAttributeDefinitions, generalAttributeDefinitions, allDeviceAttributes, workCenterAttributes, workCenterTypes, binPlusTables, access,
      activeWorkCenterTabKey, name, description, state, dsWorkCenters, id, version, newWCNameError, waferControlMaps, masterMetaColumns, owner, isMasterMetaColumnsRetrieved,
    } = this.state;

    const createBinPlusTableDropdownList = workCenterTypes.map((workCenterType: any) => { return [workCenterType.id!, workCenterType.name!]; });
    const addWorkCenterDropdownList = _.cloneDeep(createBinPlusTableDropdownList);
    createBinPlusTableDropdownList.unshift(['generic', 'Generic']);
    createBinPlusTableDropdownList.unshift(['', 'Create Bin+ Table']);

    return (
      <Container fluid className={`${className} body-content-scroll`}>
        <Row>
          <Col>
            <TopbarNav
              title="Device Setup"
              items={[]}
              showAvatar={false}
              showNotifications={false}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12}>
            <Container fluid>
              <Row className="ml20 mr20">
                <Col style={{ minHeight: '500px' }} className="stage-zero-border-top pt30 border-color-primary step-zero-block mt20">
                  <div className="custom-form mb0">
                    <ComponentHeader
                      componentHeaderData={{
                        name, version, access, description, state, owner,
                      }}
                      onParentSave={(data: ComponentHeaderData) => {
                        this.onSaveDeviceSetup(data);
                      }}
                      onBack={async () => {
                        if (await ModalPopup.confirm({
                          header: 'Override Alert',
                          body: 'Are you sure that you have saved all your changes. Do you want to proceed?',
                        })) {
                          history.goBack();
                        }
                      }}
                    />
                  </div>
                  <Row className="mb10 mt15 ml1">
                    <CustomizedDropdown
                      containerClassName="mr10"
                      variant="outline-dark"
                      // eslint-disable-next-line no-unused-vars
                      onChange={async (value: any) => {
                        if (value === 'create') {
                          history.push('/wafer-control-map');
                        }
                      }}
                      list={[
                        ['', 'Wafer Control Map'],
                        ['create', 'Create New'],
                      ]}
                    />
                    <CustomizedDropdown
                      containerClassName="mr10"
                      variant="outline-dark"
                      onChange={(value: any) => {
                        let workCenterType: any = null;
                        if (value === 'generic') {
                          workCenterType = {
                            id: null,
                            name: 'Generic',
                            binPlusDefinitionType: 'BIN',
                          };
                        } else {
                          workCenterType = _.cloneDeep(workCenterTypes.filter((wct: any) => { return wct.id === value; })[0]);
                        }
                        history.push('/create-bin-plus-table/', { workCenterType });
                      }}
                      list={createBinPlusTableDropdownList}
                    />
                  </Row>

                  { id
                    ? (
                      <Row>
                        <Col lg={12}>
                          <Heading size={5}>General Device Settings</Heading>
                          {isMasterMetaColumnsRetrieved ? (
                            <AttributesGrid
                              parentId={id}
                              onDeleteAttributeDefinition={this.onDeleteAttributeDefinition}
                              onAddAttributeDefinition={this.onAddAttributeDefinition}
                              onUpdateAttributeDefinition={this.onUpdateAttributeDefinition}
                              workCenterTypes={workCenterTypes}
                              overrideAttributeInWC
                              binPlusTables={binPlusTables}
                              waferControlMaps={waferControlMaps}
                              attributes={generalAttributeDefinitions}
                              allAttributes={allDeviceAttributes}
                              onDeleteAttribute={this.onDeleteGeneralAttribute}
                              onAddAttribute={this.onAddAttribute}
                              masterMetaColumns={masterMetaColumns}
                            />
                          ) : (
                            <div className="d-flex align-items-center justify-content-center p20">
                              <Spinner animation="border" />
                            </div>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Alert show variant="warning">
                        Save Device Setup To Add Device Settings
                      </Alert>
                    )}
                </Col>
              </Row>
              {id ? (
                <Row className="ml20 mr20 mt10" style={{ minHeight: '600px' }}>
                  <Col className="work-center-tabs top pt30 step-zero-block mt20">
                    <h5>Work Centers</h5>
                    <div style={{ display: 'flex', width: '500px' }} className="p0 mt0 mb10">
                      <div className="custom-form">
                        <Textbox
                          type="text"
                          setRef={(ref: any) => { this.newWCNameRef = ref; }}
                          name="workCenterName"
                          autoComplete="off"
                          placeholder="New work center name..."
                        />
                        <ErrorLabel
                          error={newWCNameError}
                          isTouched
                        />
                      </div>
                      <span style={{ color: 'red' }} className="mr20 ml5">*</span>
                      <div>
                        <CustomizedDropdown
                          containerClassName="mt5"
                          avoidUpdateOnSelect
                          displayElement={<i className="dx-icon-plus" />}
                          variant="outline-dark"
                          onChange={async (event: any) => {
                            if (this.newWCNameRef === null) return;
                            const newName = this.newWCNameRef.value as string;
                            if (newName === '') {
                              this.setState({ newWCNameError: 'Work center name is required' });
                            } else if (newName.length > 255) {
                              this.setState({ newWCNameError: 'Name cannot exceed 255 characters' });
                            } else {
                              this.setState({ newWCNameError: '' }, async () => {
                                const newDSWorkCenters = await httpDeviceSetup.postDSWorkCenter({
                                  name: newName,
                                  workCenterType: { id: event },
                                });
                                toast.success('New Device Setup Work Center Created');
                                this.newWCNameRef.value = '';
                                this.setState({
                                  dsWorkCenters: newDSWorkCenters,
                                  activeWorkCenterTabKey: newDSWorkCenters.length <= 0 ? '' : newDSWorkCenters[newDSWorkCenters.length - 1].id,
                                  workCenterAttributeDefinitions: this.mapDSWCAttributeDefinitions(await httpDeviceSetup.getDSWCAttributeDefinitions(id)),
                                });
                              });
                            }
                          }}
                          list={addWorkCenterDropdownList}
                        />
                      </div>
                    </div>

                    <Tabs
                      activeKey={activeWorkCenterTabKey}
                      onSelect={async (key) => {
                        if (this.dsWCRef[activeWorkCenterTabKey]?.isInEditingMode()) {
                          if (await ModalPopup.confirm({
                            header: 'Changes Unsaved',
                            body: 'You have unsaved changes in the attributes grid. Do you want to proceed?',
                          })) {
                            this.dsWCRef[activeWorkCenterTabKey].setState({ nameEditMode: false }, () => {
                              this.setState({ activeWorkCenterTabKey: key! });
                            });
                          }
                        } else {
                          this.setState({ activeWorkCenterTabKey: key! });
                        }
                      }}
                    >
                      {dsWorkCenters.map((workCenterInfo: any) => {
                        return (
                          <Tab
                            key={workCenterInfo.id}
                            eventKey={workCenterInfo.id}
                            title={(
                              <div style={{ textAlign: 'center' }}>
                                <p className="pl5 pr5 mb0">
                                  {workCenterInfo.name === '' ? '-' : workCenterInfo.name}
                                </p>
                                <small>
                                  <strong>
                                    (
                                    {workCenterInfo.workCenterType.name}
                                    )
                                  </strong>
                                </small>
                              </div>
                            )}
                          >
                            <DeviceSetupWorkCenter
                              ref={(dsWCRef: any) => { this.dsWCRef[workCenterInfo.id] = dsWCRef; }}
                              deviceSetupId={id}
                              onAddAttributeDefinition={this.onAddAttributeDefinition}
                              onUpdateAttributeDefinition={this.onUpdateAttributeDefinition}
                              onDeleteAttributeDefinition={this.onDeleteAttributeDefinition}
                              workCenterTypes={workCenterTypes}
                              onAddWorkCenterAttribute={this.onAddWorkCenterAttribute}
                              onDeleteWorkCenterAttribute={this.onDeleteWorkCenterAttribute}
                              onSaveWorkCenter={this.onSaveWorkCenter}
                              onDeleteWorkCenter={this.onDeleteWorkCenter}
                              onCopyWorkCenter={this.onCopyWorkCenter}
                              binPlusTables={binPlusTables}
                              waferControlMaps={waferControlMaps}
                              workCenterData={workCenterInfo}
                              workCenterAttributeDefinitions={workCenterAttributeDefinitions[workCenterInfo.id]}
                              workCenterAttributes={workCenterAttributes[workCenterInfo.workCenterType.id]}
                              masterMetaColumns={masterMetaColumns}
                            />
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(DeviceSetup);
