/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import { hopscotch } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';

class AdvancedFilterPopover extends React.Component {
  render() {
    return (
      <>
        <strong>1. Syntax</strong>
        <p>
          Following
          {' '}
          <em>Alias</em>
          {' '}
          can be used to identify each entity in the Raw Data Grid
        </p>
        <ul>
          <li>Facility (F)</li>
          <li>Work Center (WC)</li>
          <li>Device (D)</li>
          <li>Test Program (TP)</li>
          <li>Test Program Revision (TPR)</li>
          <li>Lot (L)</li>
          <li>Wafer (W)</li>
          <li>Die (DI)</li>
          <li>Test Parameter (TEPA)</li>
        </ul>
        <strong>For Example</strong>
        <SyntaxHighlighter language="sql" style={hopscotch}>
          f.name = &#123;ABC XYZ&#125;
        </SyntaxHighlighter>
        <strong>2. Column Identification</strong>
        <p>
          Column names are seperated by
          {' '}
          <em>underscore characters(_)</em>
          {' '}
          and the entire advanced filter in case-insensitive
        </p>
        <strong>For Example</strong>
        <SyntaxHighlighter language="sql" style={hopscotch}>
          di.soft_bin_number
        </SyntaxHighlighter>
        <SyntaxHighlighter language="sql" style={hopscotch}>
          bs.number_of_parts
        </SyntaxHighlighter>
        <strong>3. Conditions</strong>
        <p>
          You can use
          {' '}
          <em>OR</em>
          {' '}
          and
          {' '}
          <em>AND</em>
          {' '}
          keywords to write conditions, and round brackets can be used for nested and more complex conditions.
        </p>
        <strong>For Example</strong>
        <SyntaxHighlighter language="sql" style={hopscotch}>
          f.name = &#123;ABC&#125; AND wc.name = &#123;XYZ&#125; OR di.test_count &gt; &#123;10&#125;
        </SyntaxHighlighter>
        <SyntaxHighlighter language="sql" style={hopscotch}>
          f.name = &#123;ABC&#125; AND ( wc.name = &#123;XYZ&#125; OR l.station_number = &#123;1&#125; )
        </SyntaxHighlighter>
      </>
    );
  }
}
export default AdvancedFilterPopover;
