import WaferUtils from '../Utils/WaferUtils';
import { InteractionType } from './Events';

export const contextMenuMouseDown = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, data?: { [key: string]: any }) => {
  if ((data && data.interactionType !== InteractionType.ContextMenu)) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  const { contextMenuRef } = waferUtil.waferMapVariables;
  if (!contextMenuRef) return;
  if (data && data.show) {
    contextMenuRef.instance.hide();
    contextMenuRef.instance.option('position', {
      offset: {
        x: startX + 10,
        y: startY + 10,
      },
    });
    contextMenuRef.instance.show();
  } else {
    contextMenuRef.instance.hide();
  }
};
