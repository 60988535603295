/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
// eslint-disable-next-line no-unused-vars
import HomeDieCoordsForm, { HomeDieCoordinateSystemFormData } from 'components/wafer-control-map/home-die-coords-form';
import { UtilityFunctions, } from 'components/wafer-control-map/utility';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import toast from 'CustomToast';
import { COORDINATE_SYSTEMS } from '../WaferMap';
import {
  ColAxisDirection, ColFlip, MarkingMode, RowAxisDirection, RowFlip, SelectionMode,
} from '../web-gl-utils/Enums';
import initializeEvents from '../web-gl-utils/Events';
import {
  RectCoord, ReticleGridRectCoords, WaferMapData, WaferMapTestData, ZoneData,
} from '../web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

let startArrayX: number;
let startArrayY: number;
let endArrayX: number;
let endArrayY: number;
const boxSelectionGridHandlers: {
  [keyIndex: string]: (
    selectedData: { row: number, col: number }[],
    unSelectedData: { row: number, col: number }[],
    markedData: { row: number, col: number }[],
  ) => void
} = {};

const setArrayCoords = (_startX: number, _startY: number, _endX: number, _endY: number, waferMapInstance: WebGLUtils) => {
  const {
    ratioWidth, ratioHeight, viewport, tickViewPort, viewPortHeight, angleInDegrees, viewPortWidth,
  } = waferMapInstance.waferMapVariables;
  let startX = (_startX - viewport.x) * ratioWidth;
  let endX = (_endX - viewport.x) * ratioWidth;
  let startY = (_startY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  let endY = (_endY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  const v = waferMapInstance.getGPUCoords({
    startX, startY, endX, endY,
  }, {
    width: viewPortWidth,
    height: viewPortHeight,
  });
  waferMapInstance.waferMapVariables.selectionCoord = {
    startX: v.startX, startY: -v.startY, endX: v.endX, endY: -v.endY,
  };
  // rotation
  const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
  startX -= viewPortWidth / 2;
  startY -= viewPortHeight / 2;
  let rstartX = startX * rotation[1] + startY * rotation[0];
  let rstartY = startY * rotation[1] - startX * rotation[0];
  rstartX += viewPortWidth / 2;
  rstartY += viewPortHeight / 2;
  endX -= viewPortWidth / 2;
  endY -= viewPortHeight / 2;
  let rendX = endX * rotation[1] + endY * rotation[0];
  let rendY = endY * rotation[1] - endX * rotation[0];
  rendX += viewPortWidth / 2;
  rendY += viewPortHeight / 2;
  // rotation

  const indicesObj = waferMapInstance.returnBoxArrayIndices(
    rstartX,
    rstartY,
    rendX,
    rendY,
  );
  startArrayX = indicesObj.startArrayX;
  startArrayY = indicesObj.startArrayY;
  endArrayX = indicesObj.endArrayX;
  endArrayY = indicesObj.endArrayY;
};

const handleReticleUnderSelection = (reticleGridRectCoords: ReticleGridRectCoords, colOffset: number, rowOffset: number, value: boolean) => {
  const reticles = Object.keys(reticleGridRectCoords);
  for (let i = 0; i < reticles.length; i += 1) {
    const reticleCoords: RectCoord = JSON.parse(reticles[i]);
    if (
      endArrayX + colOffset >= reticleCoords.startX
      && startArrayX + colOffset <= reticleCoords.endX
      && endArrayY + rowOffset >= reticleCoords.startY
      && startArrayY + rowOffset <= reticleCoords.endY
    ) {
      reticleGridRectCoords[reticles[i]].isUnderSelection = value;
    }
  }
};

const isWATPCMNumberAlreadyMarked = (reticleGridRectCoords: ReticleGridRectCoords, watPCMSiteNumber: number | null) => {
  const reticles = Object.keys(reticleGridRectCoords);
  for (let i = 0; i < reticles.length; i += 1) {
    if (watPCMSiteNumber !== null && reticleGridRectCoords[reticles[i]].watPCMSiteNumber === watPCMSiteNumber) {
      return true;
    }
  }
  return false;
};

const isHomeDieAlreadyMarked = (waferMapTestData: WaferMapTestData, homeDieId: string | null) => {
  if (!homeDieId) return null;
  for (let i = 0; i < waferMapTestData.length; i += 1) {
    for (let j = 0; waferMapTestData[i] && j < waferMapTestData[i].length; j += 1) {
      if (waferMapTestData[i][j] && waferMapTestData[i][j]!.dieType === homeDieId) {
        return waferMapTestData[i][j];
      }
    }
  }
  return null;
};

const highlightSelectedReticlesOnMouseUp = async (waferMapInstance: WebGLUtils) => {
  const {
    reticleGridRectCoords, colOffset, rowOffset, markZones, currentWatPCMSiteNumber,
    showReticleText, reticleTextField, currentWatTestMethod, markingMode, waferMapTestData,
    currentZoneId, freeFormZonePCMSiteNumber, zones,
  } = waferMapInstance.waferMapVariables;

  const orignalReticleGridRectCoords = _.cloneDeep(reticleGridRectCoords);
  const reticles = Object.keys(reticleGridRectCoords);
  let overrideAllowed = false;

  const canSampleWatPCMSites = currentWatTestMethod === 'sampling' && markingMode === MarkingMode.WAT_PCM_SITE_NUMBER && showReticleText && reticleTextField === 'watPCMSiteNumber';

  if (markZones && canSampleWatPCMSites && isWATPCMNumberAlreadyMarked(reticleGridRectCoords, currentWatPCMSiteNumber)) {
    toast.warn(`PCM site number ${currentWatPCMSiteNumber} is already marked`);
    return;
  }

  for (let i = 0; i < reticles.length; i += 1) {
    const reticleCoords: RectCoord = JSON.parse(reticles[i]);
    if (
      endArrayX + colOffset >= reticleCoords.startX
      && startArrayX + colOffset <= reticleCoords.endX
      && endArrayY + rowOffset >= reticleCoords.startY
      && startArrayY + rowOffset <= reticleCoords.endY
    ) {
      if (!markZones) {
        reticleGridRectCoords[reticles[i]].isSelected = !reticleGridRectCoords[reticles[i]].isSelected;
      } else {
        if (canSampleWatPCMSites) {
          if (reticleGridRectCoords[reticles[i]].watPCMSiteNumber === null || reticleGridRectCoords[reticles[i]].watPCMSiteNumber === currentWatPCMSiteNumber) {
            reticleGridRectCoords[reticles[i]].watPCMSiteNumber = currentWatPCMSiteNumber;
            if (currentWatPCMSiteNumber !== null) {
              return; // return because can only mark 1 pcm site number at a time
            }
          } else {
            if (!overrideAllowed) {
              // eslint-disable-next-line no-await-in-loop
              if (await ModalPopup.confirm({
                header: 'Override Alert',
                body: 'This action will override WAT / PCM sites in some reticles. Do you want to proceed?',
              })) {
                overrideAllowed = true;
                reticleGridRectCoords[reticles[i]].watPCMSiteNumber = currentWatPCMSiteNumber;
                if (currentWatPCMSiteNumber !== null) {
                  return; // return because can only mark 1 pcm site number at a time
                }
              } else {
                waferMapInstance.waferMapVariables.reticleGridRectCoords = orignalReticleGridRectCoords;
                break;
              }
            } else {
              reticleGridRectCoords[reticles[i]].watPCMSiteNumber = currentWatPCMSiteNumber;
              if (currentWatPCMSiteNumber !== null) {
                return; // return because can only mark 1 pcm site number at a time
              }
            }
          }
        }
        const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
        if (markingMode === MarkingMode.ZONE_SITE && filteredZones.length !== 0 && filteredZones[0].zoneType === 'FREE_FORM') {
          for (let l = reticleCoords.startY - rowOffset; l <= reticleCoords.endY - rowOffset; l += 1) {
            for (let m = reticleCoords.startX - colOffset; m <= reticleCoords.endX - colOffset; m += 1) {
              if (waferMapTestData[l] && waferMapTestData[l][m]) {
                if (!waferMapTestData[l][m]!.zoneInfo) {
                  waferMapTestData[l][m]!.zoneInfo = {};
                }
                if (freeFormZonePCMSiteNumber !== null) {
                  waferMapTestData[l][m]!.zoneInfo![currentZoneId] = [freeFormZonePCMSiteNumber];
                } else {
                  delete waferMapTestData[l][m]!.zoneInfo![currentZoneId];
                }
              }
            }
          }
        }
      }
    }
  }
};

const createSelectionBoxOnMouseMove = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  waferMapInstance: WebGLUtils,
  isMouseDown: boolean,
) => {
  if (!isMouseDown || !waferMapInstance.waferMapVariables.select) return;
  // return box array indices
  setArrayCoords(startX, startY, endX, endY, waferMapInstance);

  const underSelectionData: WaferMapData[] = [];
  const {
    startRow, rowDirection, startCol, colDirection, reticleGridRectCoords, selectionMode, colOffset, rowOffset,
  } = waferMapInstance.waferMapVariables;

  if (selectionMode === SelectionMode.RETICLE) {
    handleReticleUnderSelection(reticleGridRectCoords, colOffset, rowOffset, true);
  } else if (selectionMode === SelectionMode.DIE) {
    for (let i = startArrayY; i <= endArrayY; i += 1) {
      for (let j = startArrayX; j <= endArrayX; j += 1) {
        const rIndex = startRow + rowDirection * i;
        const cIndex = startCol + colDirection * j;
        if (waferMapInstance.waferMapVariables.waferMapTestData[rIndex] && waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]) {
          const dieData = waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!;
          underSelectionData.push(dieData);
          dieData.underSelection = true;
          underSelectionData[underSelectionData.length - 1].row = rIndex;
          underSelectionData[underSelectionData.length - 1].col = cIndex;
        }
      }
    }
  }
  // When selection is Done change the opacity of dies to 50%
  // waferMapInstance.waferMapVariables.binColorOpacity = 0.5;

  waferMapInstance.reRender();

  if (selectionMode === SelectionMode.RETICLE) {
    handleReticleUnderSelection(reticleGridRectCoords, colOffset, rowOffset, false);
  } else if (selectionMode === SelectionMode.DIE) {
    for (let i = startArrayY; i <= endArrayY; i += 1) {
      for (let j = startArrayX; j <= endArrayX; j += 1) {
        const rIndex = startRow + rowDirection * i;
        const cIndex = startCol + colDirection * j;
        if (waferMapInstance.waferMapVariables.waferMapTestData[rIndex] && waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]) {
          underSelectionData.push(waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!);
          waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!.underSelection = false;
          underSelectionData[underSelectionData.length - 1].row = rIndex;
          underSelectionData[underSelectionData.length - 1].col = cIndex;
        }
      }
    }
  }
};

const applyOffsetsToDieData = (waferMapInstance: WebGLUtils, coordinateSystems: string[], subRowOffset: number, subColOffset: number) => {
  const {
    rowFlip, rowAxisStart, rowAxisIncrement, rowAxisDirection, rowOffset, waferMapTestData,
    colFlip, colAxisStart, colAxisIncrement, colAxisDirection, colOffset,
  } = waferMapInstance.waferMapVariables;
  for (let i = 0; i < waferMapTestData.length; i += 1) {
    for (let j = 0; j < waferMapTestData[i].length; j += 1) {
      const dieData = waferMapTestData[i][j];
      if (dieData) {
        if (coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
          dieData.y = UtilityFunctions.getDieXOrY(
            rowFlip === RowFlip.Upright,
            rowAxisStart,
            rowAxisIncrement,
            rowAxisDirection === RowAxisDirection.TopToBottom,
            i,
            rowOffset,
            waferMapTestData.length,
          );
          dieData.x = UtilityFunctions.getDieXOrY(
            colFlip === ColFlip.Upright,
            colAxisStart,
            colAxisIncrement,
            colAxisDirection === ColAxisDirection.LeftToRight,
            j,
            colOffset,
            waferMapTestData[0].length,
          );
        }
        if (coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE) && dieData.reticleX !== undefined && dieData.reticleY !== undefined) {
          dieData.reticleX += subColOffset;
          dieData.reticleY += subRowOffset;
        }
      }
    }
  }
};

const applyOffsetsToReticleData = (waferMapInstance: WebGLUtils, coordinateSystems: string[], deltaRowOffset: number, deltaColOffset: number, reticleRow: number, reticleCol: number) => {
  const { reticleGridRectCoords, referenceReticleGridRectCoords } = waferMapInstance.waferMapVariables;
  const reticles = Object.keys(reticleGridRectCoords);
  const newReticleGridRectCoords: ReticleGridRectCoords = {};
  for (let i = 0; i < reticles.length; i += 1) {
    const reticleCoords: RectCoord = JSON.parse(reticles[i]);
    const newReticleCoords: RectCoord = {
      startX: reticleCoords.startX + deltaColOffset,
      startY: reticleCoords.startY + deltaRowOffset,
      endX: reticleCoords.endX + deltaColOffset,
      endY: reticleCoords.endY + deltaRowOffset,
    };
    newReticleGridRectCoords[JSON.stringify(newReticleCoords)] = _.cloneDeep(reticleGridRectCoords[reticles[i]]);
  }
  waferMapInstance.waferMapVariables.reticleGridRectCoords = newReticleGridRectCoords;

  if (referenceReticleGridRectCoords) {
    referenceReticleGridRectCoords.startX += deltaColOffset;
    referenceReticleGridRectCoords.startY += deltaRowOffset;
    referenceReticleGridRectCoords.endX += deltaColOffset;
    referenceReticleGridRectCoords.endY += deltaRowOffset;
  }
  // eslint-disable-next-line no-param-reassign
  waferMapInstance.waferMapVariables.reticleXAxisReference = waferMapInstance.getReticleAxisReference(waferMapInstance.waferMapVariables.reticleGridRectCoords, 'x', false, reticleCol);
  // eslint-disable-next-line no-param-reassign
  waferMapInstance.waferMapVariables.reticleYAxisReference = waferMapInstance.getReticleAxisReference(waferMapInstance.waferMapVariables.reticleGridRectCoords, 'y', false, reticleRow);
};

const applyOffsetsToData = (waferMapInstance: WebGLUtils, data: HomeDieCoordinateSystemFormData, homeDie: WaferMapData) => {
  let subRowOffset = 0;
  let subColOffset = 0;

  let reticleRowOffset = 0;
  let reticleColOffset = 0;
  if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
    if (homeDie.x === undefined || homeDie.y === undefined) return;
    const deltaColOffset = data.standardX - homeDie.x;
    const deltaRowOffset = data.standardY - homeDie.y;
    reticleRowOffset += deltaRowOffset;
    reticleColOffset += deltaColOffset;

    waferMapInstance.waferMapVariables.colOffset += deltaColOffset;
    waferMapInstance.waferMapVariables.rowOffset += deltaRowOffset;
  }

  if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)) {
    if (homeDie.reticleX === undefined || homeDie.reticleY === undefined) return;
    subRowOffset = data.subRow - homeDie.reticleY;
    subColOffset = data.subCol - homeDie.reticleX;
    reticleRowOffset -= subRowOffset;
    reticleColOffset -= subColOffset;
  }

  applyOffsetsToDieData(waferMapInstance, data.coordinateSystems, subRowOffset, subColOffset);
  applyOffsetsToReticleData(waferMapInstance, data.coordinateSystems, reticleRowOffset, reticleColOffset, data.row, data.col);
};

const onHomeDieMarked = async (waferMapInstance: WebGLUtils, dieData: WaferMapData) => {
  const {
    isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled, reticleXAxisReference, reticleSize, reticleYAxisReference,
    colAxisDirection, rowAxisDirection,
  } = waferMapInstance.waferMapVariables;
  const coordinateSystems = [];
  if (isDieCoordinatesSystemEnabled) coordinateSystems.push(COORDINATE_SYSTEMS.STANDARD);
  if (isReticleCoordinatesSystemEnabled) coordinateSystems.push(COORDINATE_SYSTEMS.RETICLE);

  let reticleRow = Math.floor(Math.abs(dieData.y! - reticleYAxisReference) / reticleSize.y);
  if (dieData.y! < reticleYAxisReference) {
    reticleRow = -1 * (reticleRow + 1);
  }
  let reticleCol = Math.floor(Math.abs(dieData.x! - reticleXAxisReference) / reticleSize.x);
  if (dieData.x! < reticleXAxisReference) {
    reticleCol = -1 * (reticleCol + 1);
  }

  // eslint-disable-next-line no-await-in-loop
  const result = await HomeDieCoordsForm.openForm({
    data: {
      coordinateSystems,
      standardX: dieData.x!,
      standardY: dieData.y!,
      row: reticleRow,
      col: reticleCol,
      subRow: dieData.reticleY!,
      subCol: dieData.reticleX!,
      waferAxisDirection: UtilityFunctions.getWaferAxisDirection(colAxisDirection, rowAxisDirection),
    },
    reticleSize,
  });
  const data = (result as (null | HomeDieCoordinateSystemFormData));
  if (data) {
    applyOffsetsToData(waferMapInstance, data, dieData);
    return true;
  } else {
    return false;
  }
};

const highlightSelectedDiesOnMouseUp = async (waferMapInstance: WebGLUtils) => {
  const {
    startRow, startCol, rowDirection, colDirection, waferMapTestData,
    selectedDieField, dieTypeField, markZones, dieTypes, currentDieType, keyIndex,
  } = waferMapInstance.waferMapVariables;

  let overrideAllowed = false;
  let isFirstDieMarked = false;

  const selectedData: { row: number, col: number }[] = [];
  const unSelectedData: { row: number, col: number }[] = [];
  const markedData: { row: number, col: number }[] = [];
  const orignalWaferMapTestData = _.cloneDeep(waferMapTestData);

  let homeDie: WaferMapData | null = null;
  let homeDieId = null;
  if (dieTypes && dieTypes.dieType) homeDieId = UtilityFunctions.getDieTypeIdFromName('Home Die', dieTypes.dieType);
  if (dieTypes && dieTypes.dieType && markZones && homeDieId && currentDieType === homeDieId) {
    const unAssignedDieId = UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType);
    homeDie = isHomeDieAlreadyMarked(waferMapTestData, homeDieId);
    if (homeDie && await ModalPopup.confirm({
      header: 'Override Alert',
      body: 'This action will override the home die. Do you want to proceed?',
    })) {
      homeDie.dieType = unAssignedDieId || '';
      homeDie = null;
    }
  }

  for (let i = startArrayY; i <= endArrayY; i += 1) {
    for (let j = startArrayX; j <= endArrayX; j += 1) {
      const rIndex = startRow + rowDirection * i;
      const cIndex = startCol + colDirection * j;
      const dieData = waferMapTestData[rIndex] === undefined ? undefined : waferMapTestData[rIndex][cIndex];

      if (dieData && !dieData.cropped && !dieData.deleted) {
        if (markZones) {
          if (homeDie) {
            toast.warn('Home die is already marked');
            return;
          }

          if (isFirstDieMarked && homeDieId && currentDieType === homeDieId) {
            return;
          }

          const fieldName = selectedDieField ?? dieTypeField;
          const dieTypeId = fieldName ? dieData[fieldName] : dieData.dieType!;
          const differentFieldSelected = (fieldName !== 'dieType'
          || (fieldName === 'dieType' && UtilityFunctions.getDieTypeFromId(String(dieTypeId), dieTypes.dieType)?.name !== 'Unassigned'))
          && dieTypeId !== currentDieType;

          if (differentFieldSelected) {
            if (!overrideAllowed) {
            // eslint-disable-next-line no-await-in-loop
              if (!await ModalPopup.confirm({
                header: 'Override Alert',
                body: 'This action will override die types in some dies. Do you want to proceed?',
              })) {
                waferMapInstance.waferMapVariables.waferMapTestData = orignalWaferMapTestData;
                return;
              } else {
                overrideAllowed = true;
              }
            }
          }
          dieData[fieldName] = currentDieType;
          isFirstDieMarked = true;
          // eslint-disable-next-line no-await-in-loop
          if (homeDieId && currentDieType === homeDieId && !await onHomeDieMarked(waferMapInstance, dieData)) {
            waferMapInstance.waferMapVariables.waferMapTestData = orignalWaferMapTestData;
            return;
          }
          markedData.push({ row: rIndex, col: cIndex });
        } else {
        // eslint-disable-next-line no-lonely-if
          if (!dieData.selected) {
            dieData.selected = true;
            selectedData.push({ row: rIndex, col: cIndex });
          } else {
            dieData.selected = false;
            unSelectedData.push({ row: rIndex, col: cIndex });
          }
        }
      }
    }
  }
  boxSelectionGridHandlers[keyIndex](selectedData, unSelectedData, markedData);
};

const highlightSelectedDiesOrReticlesOnMouseUp = async (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  waferMapInstance: WebGLUtils,
) => {
  const {
    viewPortHeight, viewport, select, gl, selectionMode,
  } = waferMapInstance.waferMapVariables;

  if (!select || gl === null) return;
  if (startX < viewport.x || startX > viewport.x + viewport.width) return;
  if (startY < viewPortHeight - (viewport.y + viewport.height) || startY > viewPortHeight - (viewport.y)) return;

  if (startX === endX && startY === endY) {
    setArrayCoords(startX, startY, endX, endY, waferMapInstance);
  }

  if (selectionMode === SelectionMode.RETICLE) {
    await highlightSelectedReticlesOnMouseUp(waferMapInstance);
  } else if (selectionMode === SelectionMode.DIE) {
    await highlightSelectedDiesOnMouseUp(waferMapInstance);
  }
  waferMapInstance.waferMapVariables.selectionCoord = null;
  waferMapInstance.reRender();
};

const selection = (callback: (
  selectedData: { row: number, col: number }[],
  unSelectedData: { row: number, col: number }[],
  markedData: { row: number, col: number }[],
) => void, waferMapInstance: WebGLUtils): void => {
  boxSelectionGridHandlers[waferMapInstance.waferMapVariables.keyIndex] = callback;
  if (waferMapInstance.waferMapVariables.gl === null) return;
  initializeEvents(
    waferMapInstance,
    waferMapInstance.waferMapVariables.gl,
    createSelectionBoxOnMouseMove,
    highlightSelectedDiesOrReticlesOnMouseUp,
    null,
  );
};

export default selection;
