/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollView } from 'devextreme-react';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { WaferState } from '../../types';
import { ActionHandler, ActionType } from '../../WaferPlotter/WaferPlotter';
import './redo-undo-control.scss'

type RedoUndoControlProps = {
  showPopover: boolean,
  onTogglePopover: (shouldOpen: boolean) => void,
  listOfItems: WaferState[],
  actionType: ActionType,
  keyIndex: string,
  actionHandler: ActionHandler,
  disabled: boolean,
  icon: IconDefinition,
};

const RedoUndoControl = (props: RedoUndoControlProps) => {
  const {
    showPopover, onTogglePopover, listOfItems, actionType, keyIndex, actionHandler, disabled, icon,
  } = props;
  return (
    <OverlayTrigger
      trigger="focus"
      show={showPopover}
      placement="auto"
      overlay={(
        <Popover
          id={`${actionType === ActionType.UNDO ? 'undo' : 'redo'}-popover`}
          show={showPopover}
          tabIndex={-1}
          style={{ maxWidth: '1200px' }}
        >
          <Popover.Title as="h3" className="pr0">
            {actionType === ActionType.UNDO ? 'Undo' : 'Redo'}
            {' '}
            Actions
            <Button
              variant="outline float-right p0 pl5 pr10 text-danger dx-icon-close"
              onClick={() => { onTogglePopover(false); }}
            >
              <i />
            </Button>
          </Popover.Title>
          <Popover.Content>
            <ScrollView
              height="20vh"
              showScrollbar="onHover"
              scrollByThumb
              reachBottomText=""
            >
              <div>
                {listOfItems.map((waferState: WaferState, index: number) => {
                  return (
                    <div>
                      <div style={{ display: 'inline-flex' }}>
                        <div
                          className={`redo-undo-item-index redo-undo-item ${index % 2 === 0 ? 'redo-undo-item-light' : 'redo-undo-item-dark'} mr10`}
                        >
                          <span style={{ margin: '10px' }}>{index + 1}</span>
                        </div>
                        <div
                          className={`redo-undo-item-action redo-undo-item ${index % 2 === 0 ? 'redo-undo-item-light' : 'redo-undo-item-dark'}`}
                          onClick={async () => { await actionHandler(actionType, keyIndex, undefined, index + 1); }}
                        >
                          <span style={{ margin: '10px' }}>{actionType === ActionType.UNDO ? waferState.actionUndoMessage : waferState.actionDoMessage }</span>
                        </div>
                      </div>
                      <br />
                    </div>
                  );
                })}
              </div>
            </ScrollView>
          </Popover.Content>
        </Popover>
      )}
    >
      <Button
        variant="light"
        className="mb10 mt10"
        size="sm"
        disabled={disabled}
        onClick={() => { onTogglePopover(true); }}
      >
        <FontAwesomeIcon
          icon={icon}
          className="mr5"
        />
      </Button>
    </OverlayTrigger>
  );
};

export default RedoUndoControl;
