/* eslint-disable no-param-reassign */
import { rulesAppliedDisplayValue } from 'components/policy/policy-step/SPC/SPCMapperUtils';
import { ServiceType } from '../../../interfaces';

const generateExecutionCommands = (selectedData: any) => {
  const executeCommands: any = [];
  selectedData.forEach((selectedDataObj: any) => {
    const {
      level,
      id,
      stepType,
      stepId,
      ruleSetId,
    } = selectedDataObj;
    const command = {
      stepId,
      stepType,
      ruleSetId,
      level,
      id,
    };
    executeCommands.push(command);
  });
  return executeCommands;
};

const markExistingPolicy = (executeCommands: any, policyData: any) => {
  const newPolicy = JSON.parse(JSON.stringify(policyData));
  executeCommands.forEach((command: any) => {
    const {
      id,
      level,
      stepId,
      stepType,
      ruleSetId,
    } = command;
    if (stepType === ServiceType.PAT) {
      if (level === 'STEP') {
        const step = newPolicy.policySteps.find((pStep: any) => pStep.id === id);
        step.fullIncluded = true;
      } else if (level === 'RULE_SET') {
        const step = newPolicy.policySteps.find((pStep: any) => pStep.id === stepId);
        step.partialIncluded = true;
        const { stepSettings } = step;
        const ruleSet = stepSettings.patRuleSet.find((patRuleSetObj: any) => patRuleSetObj.id === id);
        ruleSet.fullIncluded = true;
      } else if (level === 'RULE') {
        const step = newPolicy.policySteps.find((pStep: any) => pStep.id === stepId);
        step.partialIncluded = true;
        if (stepType === ServiceType.PAT) {
          const { stepSettings } = step;
          const ruleSet = stepSettings.patRuleSet.find((patRuleSetObj: any) => patRuleSetObj.id === ruleSetId);
          ruleSet.partialIncluded = true;
          const patRule = ruleSet.patRules.find((pRule: any) => pRule.id === id);
          patRule.fullIncluded = true;
        }
      }
    } else if (stepType === ServiceType.SPC) {
      if (level === 'STEP') {
        const step = newPolicy.policySteps.find((step: any) => step.id === id);
        step.fullIncluded = true;
      } else if (level === 'RULE_SET') {
        const step = newPolicy.policySteps.find((step: any) => step.id === stepId);
        step.partialIncluded = true;
        const { stepSettings } = step;
        const reportParameterIds = id.split(',');
        const spcReport = stepSettings.spcReports.find((sReport: any) => sReport.id === reportParameterIds[0]);
        spcReport.partialIncluded = true;
        const spcParameter = stepSettings.spcParameters.find((sParameter: any) => sParameter.id === reportParameterIds[1]);
        spcParameter.fullIncluded = true;
        spcParameter.rulesApplied.forEach((ruleApplied: any) => {
          stepSettings.spcRules.find((sRule: any) => sRule.id === ruleApplied.id).fullIncluded = true;
        });
      } else if (level === 'RULE') {
        const step = newPolicy.policySteps.find((step: any) => step.id === stepId);
        step.partialIncluded = true;
        const { stepSettings } = step;
        const reportParameterIds = ruleSetId.split(',');
        const spcReport = stepSettings.spcReports.find((sReport: any) => sReport.id === reportParameterIds[0]);
        spcReport.partialIncluded = true;
        const spcParameter = stepSettings.spcParameters.find((sParameter: any) => sParameter.id === reportParameterIds[1]);
        spcParameter.partialIncluded = true;
        const spcRuleId = id.split(',')[1];
        const spcRule = stepSettings.spcRules.find((sRule: any) => sRule.id === spcRuleId);
        spcRule.fullIncluded = true;
      }
    } else if (stepType === ServiceType.AMG) {
      if (level === 'STEP') {
        const step = newPolicy.policySteps.find((step: any) => step.id === id);
        step.fullIncluded = true;
      }
    }
  });

  return newPolicy;
};

const filterMarkedPolicyStepsSetsRules = (newPolicy: any) => {
  const newPolicySteps: any = [];

  newPolicy.policySteps.forEach((policyStep: any) => {
    // eslint-disable-next-line no-param-reassign
    policyStep.id = null;
    if (policyStep.fullIncluded) {
      // eslint-disable-next-line no-param-reassign
      newPolicySteps.push(policyStep);
    } else if (policyStep.partialIncluded) {
      const { type } = policyStep;
      if (type === ServiceType.PAT) {
        const { stepSettings } = policyStep;
        const newPatRuleSet: any = [];
        stepSettings.patRuleSet.forEach((pRuleSet: any) => {
          // eslint-disable-next-line no-param-reassign
          pRuleSet.id = null;
          if (pRuleSet.fullIncluded) {
            newPatRuleSet.push(pRuleSet);
          } else if (pRuleSet.partialIncluded) {
            const newPatRules: any = [];
            pRuleSet.patRules.forEach((patRule: any) => {
              if (patRule.fullIncluded) {
                // eslint-disable-next-line no-param-reassign
                patRule.id = null;
                newPatRules.push(patRule);
              }
            });
            // eslint-disable-next-line no-param-reassign
            pRuleSet.patRules = newPatRules;
            // eslint-disable-next-line no-param-reassign
            pRuleSet.rules = newPatRules.map((pR: any) => pR.rule.id);
            newPatRuleSet.push(pRuleSet);
          }
        });
        stepSettings.patRuleSet = newPatRuleSet;
      } else if (type === ServiceType.SPC) {
        // Do something here
        const { stepSettings } = policyStep;
        const newSPCReports: any[] = [];
        const newSPCParameters: any[] = [];
        const newSPCRules: any[] = [];
        stepSettings.spcRules.forEach((spcRule: any) => {
          if (spcRule.fullIncluded) {
            newSPCRules.push(spcRule);
          }
        });
        stepSettings.spcParameters.forEach((spcParameter: any) => {
          if (spcParameter.fullIncluded) {
            newSPCParameters.push(spcParameter);
          } else if (spcParameter.partialIncluded) {
            const newSPCRuleIds = newSPCRules.map((spcRule: any) => spcRule.id);
            spcParameter.rulesApplied = spcParameter.rulesApplied.filter((rule: any) => newSPCRuleIds.indexOf(rule.id) !== -1);
            spcParameter.rulesAppliedSequence = rulesAppliedDisplayValue(spcParameter);
            newSPCParameters.push(spcParameter);
          }
        });
        stepSettings.spcReports.forEach((spcReport: any) => {
          if (spcReport.partialIncluded) {
            const newSPCParameterIds = newSPCParameters.map((spcParameter: any) => spcParameter.id);
            spcReport.spcParameters = spcReport.spcParameters.filter((parameter: any) => newSPCParameterIds.indexOf(parameter.id) !== -1);
            newSPCReports.push(spcReport);
          }
        });
        stepSettings.spcReports = newSPCReports;
        stepSettings.spcParameters = newSPCParameters;
        stepSettings.spcRules = newSPCRules;
      }
      newPolicySteps.push(policyStep);
    }
  });
  return newPolicySteps;
};

export const simulateHandlerUtility = (selectedData: any, policy: any, waferIds: string[]) => {
  const { policyData } = policy;

  const executeCommands: any = generateExecutionCommands(selectedData.selectedRowsData);

  const newPolicy: any = markExistingPolicy(executeCommands, policyData);

  const newPolicySteps: any = filterMarkedPolicyStepsSetsRules(newPolicy);

  const timestamp = new Date().toISOString();

  newPolicy.policySteps = newPolicySteps;
  newPolicy.name += `_${timestamp}`;
  newPolicy.sourcePolicyId = policyData.sourcePolicyId;
  newPolicy.id = null;
  newPolicy.simulationFlag = true;

  return {
    Policy: newPolicy,
    WaferIds: waferIds,
  };
};
