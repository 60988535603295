import React, { ReactNode } from 'react';
import WaferUtils from './WaferUtils';

type WaferPrimaryComponentProps = {
  waferUtil: WaferUtils,
  children: ReactNode;
  pinControlsToWaferTop: boolean,
};

export const WaferPrimaryComponent = (props: WaferPrimaryComponentProps) => {
  const {
    children, waferUtil, pinControlsToWaferTop,
  } = props;
  return (
    <div
      style={{
        position: 'absolute',
        width: `${waferUtil.waferMapVariables.radarViewPort.width + waferUtil.waferMapVariables.outerViewPort.width}px`,
        bottom: `${waferUtil.waferMapVariables.outerViewPort.y + waferUtil.waferMapVariables.outerViewPort.height + (pinControlsToWaferTop ? 40 : 0)}px`,
        left: `${waferUtil.waferMapVariables.outerViewPort.x}px`,
        zIndex: 1,
        overflow: 'clip',
      }}
      className="wafer-map-primary-components"
    >
      {children}
    </div>
  );
};
