// export const dataSource = [
//   -153,
//   10000
// ];
// export const dataSource1 = [
//   153,
//   10000
// ];
// histogram => original example then show the performance of algorithm by increasing the points (Limit: 100000)
// Line chart =>
const gaussianRand = () => {
  let rand = 0;
  for (let i = 0; i < 6; i += 1) {
    rand += Math.random();
  }
  return rand / 6 - 0.5;
};
export const generateData1 = (n) => {
  const X = [];
  const Y = [];
  let i;
  for (i = 0; i < n; i += 1) {
    X.push(gaussianRand());
    Y.push(gaussianRand());
  }
  return [X, Y];
};
export const generateData = (n) => {
  const data = [];
  const breakP = Math.floor(n / 3);
  let endRange = breakP;
  let multiplier = 1;
  for (let i = 0; i < 3; i++) {
    for (let j = multiplier; j <= endRange; j++) {
      data.push(j);
    }
    multiplier = endRange * 4;
    endRange = multiplier + (breakP - 1);
  }
  return data;
};
/// PRACTICE RUN
// export const dataSource = [318, 0.06, 1.2,14.6, 0.11, 0.82, 1, 1.4, 17.2, 95];
export const dataSource = [
  35,
  89,
  30,
  122,
  1540,
  110000,
  1800,
  1900,
  153,
  10000,
];
export const dataSource1 = [
  30,
  76,
  89,
  122,
  153,
  1540,
  1800,
  1900,
  10000,
  110000,
];
