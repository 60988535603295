/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import CustomizedDataGrid from '../../../wrapped-component/customized-data-grid/CustomizedDataGrid';

class SPCPExclusionSettings extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      spcExclusionSettings: [],
    };
  // this.updateRulesToParent = this.updateRulesToParent.bind(this);
  }

  updateRulesToParent = async () => {
    const { onUpdate } = this.props;
    const { parameterSettings } = this.state;
    onUpdate(parameterSettings);
  };

  render() {
    const { spcExclusionSettings } = this.state;
    return (
      <div className="w530 border-all pl10 pr10 pb20 background-color-light">
        <Col lg={12} className="mt20">
          <Heading size={4}>SPC Exclusion Settings</Heading>
        </Col>
        <div className="w500">
          <CustomizedDataGrid
            height={200}
            totalRowsToDisplay={10}
            showFilterRow={false}
            showAdvancedFilters={false}
            enableColumnChooser={false}
            showGroupPanel={false}
            showRowLines
            showColumnLines
            allowAdding
            allowUpdating
            allowDeleting
            onRowInserted={(event: any) => {
              const data = JSON.parse(JSON.stringify(event.data));
              const setting = {
                __id__: data.__KEY__,
                parameter: data.parameter,
                condition: data.condition,
              };
              spcExclusionSettings.push(setting);
              this.setState({ spcExclusionSettings });
            }}
            onRowRemoved={(event: any) => {
              const index = spcExclusionSettings.findIndex(
                (x: any) => x.__id__ === event.data.__KEY__ && x.parameter === event.data.parameter && x.condition === event.data.condition,
              );
              this.setState({ spcExclusionSettings: spcExclusionSettings.splice(index, 1) });
            }}
            tableData={spcExclusionSettings.map((exclusionSetting: any) => ({
              ...exclusionSetting,
            }))}
            fields={[
              {
                caption: 'Parameter',
                dataField: 'parameter',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
              },
              {
                caption: 'Condition',
                dataField: 'condition',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default SPCPExclusionSettings;
