import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Heading from '../../../../wrapped-component/hint-controls/Heading';
import Label from '../../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../../wrapped-component/hint-controls/Textbox';
import CustomizedDropdown from '../../../../wrapped-component/customized-dropdown/CustomizedDropdown';

interface IRecalculateLimitsProps {
  seedLimitObj: any;
  title: string;
  showHeading: boolean;
  getSeedLimitData: (e:any) => void;
}

interface IRecalculateLimitsState {
  seedLimit: {
    recalculateCondition: string,
    timeValue: number,
    logicalOp: string,
    waferLotCriteria: string,
    relationalOp: string,
    waferLotCount: number,
    timeCriteria: string
  }
}

class RecalculateLimits extends Component<IRecalculateLimitsProps, IRecalculateLimitsState> {
  private timePeriod = [
    ['DAY', 'Days'],
    ['WEEK', 'Weeks'],
    ['MONTH', 'Months'],
  ];

  private logicalOp = [
    ['&&', 'and'],
    ['||', 'or'],
  ];

  private lotWaferList = [
    ['WAFER', 'Wafers'],
    // ['LOT', 'Lots'],
  ];

  private mathOp = [
    ['>', '>'],
    ['<', '<'],
    ['>=', '>='],
    ['<=', '<='],
    ['==', '=='],
  ];

  private DefaultSeedLimitSettings = {
    recalculateCondition: '',
    timeValue: 0,
    logicalOp: this.logicalOp[0][0],
    waferLotCriteria: this.lotWaferList[0][0],
    relationalOp: this.mathOp[0][0],
    waferLotCount: 0,
    timeCriteria: this.timePeriod[0][0],
  };

  constructor(props:any) {
    super(props);

    this.state = {
      seedLimit: JSON.parse(JSON.stringify(this.DefaultSeedLimitSettings)),
    };
  }

  componentDidUpdate(prevProps: Readonly<IRecalculateLimitsProps>) {
    if (prevProps === this.props) return;
    const { seedLimitObj } = this.props;
    let seedLimit = JSON.parse(JSON.stringify(this.DefaultSeedLimitSettings));

    if (Object.entries(seedLimitObj).length > 0) {
      seedLimit = seedLimitObj;
    }

    this.setState({ seedLimit });
  }

  onChangeHandler = (key:string, value:string) => {
    const { seedLimit } = this.state;
    const { getSeedLimitData } = this.props;
    // @ts-ignore
    seedLimit[key] = value;
    this.setState({
      seedLimit,
    });

    if (getSeedLimitData) getSeedLimitData(seedLimit);
  };

  render() {
    const {
      showHeading,
      title,
    } = this.props;
    const {
      seedLimit,
    } = this.state;

    return (
      <Row>
        {showHeading ? (
          <Col lg={12}>
            <Heading size={4}>{title}</Heading>
          </Col>
        ) : null }
        <Col lg={12}>
          <Label
            className="mb20"
            labelTitle="Recalculate Limits after every"
            labelPosition="left-middle"
            labelSize="30"
            fieldSize="70"
            errorSize="0"
          >
            <div className="d-flex">
              {/* FIXME! Need to add time condition too at bckend */}
              {/* <Textbox */}
              {/*  className="w-100 mr10" */}
              {/*  placeholder="0" */}
              {/*  name="time" */}
              {/*  value={seedLimit.timeValue} */}
              {/*  onChange={(e:any) => this.onChangeHandler('timeValue', e.target.value)} */}
              {/* /> */}
              {/* <CustomizedDropdown */}
              {/*  containerClassName="w-100 mr10" */}
              {/*  full */}
              {/*  variant="clear" */}
              {/*  list={this.timePeriod} */}
              {/*  selectedValue={seedLimit.timeCriteria} */}
              {/*  onChange={(value:any) => this.onChangeHandler('timeCriteria', value)} */}
              {/* /> */}
              {/* <CustomizedDropdown */}
              {/*  containerClassName="mr10" */}
              {/*  full */}
              {/*  variant="clear" */}
              {/*  list={this.logicalOp} */}
              {/*  selectedValue={seedLimit.logicalOp} */}
              {/*  onChange={(value:any) => this.onChangeHandler('logicalOp', value)} */}
              {/* /> */}
              <CustomizedDropdown
                containerClassName="w-100 mr10"
                full
                variant="clear"
                list={this.lotWaferList}
                selectedValue={seedLimit.waferLotCriteria}
                onChange={(value:any) => this.onChangeHandler('waferLotCriteria', value)}
              />
              <CustomizedDropdown
                containerClassName="mr10"
                full
                variant="clear"
                list={this.mathOp}
                selectedValue={seedLimit.relationalOp}
                onChange={(value:any) => this.onChangeHandler('relationalOp', value)}
              />
              <Textbox
                className="w-100"
                placeholder="0"
                name="value"
                value={seedLimit.waferLotCount}
                onChange={(e:any) => this.onChangeHandler('waferLotCount', e.target.value)}
              />
            </div>
          </Label>
        </Col>
      </Row>
    );
  }
}

export default RecalculateLimits;
