import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpLimitSettings extends HttpBase {
  getLimitsData = (testParameter: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/limits-data`, testParameter);
  };

  getCalculatedLimitsData = (limitSettingsObject: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/calculated-limits-data`, limitSettingsObject);
  };

  getLimitSettingsByName = (limitSettingsName: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/limit-settings-by-name`, limitSettingsName);
  };

  getAllLimitSettingsByUserId = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/limitsettings/all-limit-settings-by-user-id`);
  };

  saveLimitSettings = (limitSettings:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/save-limit-settings`, limitSettings);
  };

  updateLimitSettings = (limitSettings:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/update-limit-settings`, limitSettings);
  };

  deleteLimitSettings = (limitSettings:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/limitsettings/delete-limit-settings`, limitSettings);
  };
}

const httpLimitSettings = new HttpLimitSettings();
export { httpLimitSettings };
