/* eslint-disable no-else-return */
import { Dies } from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferMapVariablesClassV2';
import {
  ColAxisDirection, RowAxisDirection, WaferAxisDirection, NotchPosition, DefaultNotchPosition,
} from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
import {
  DieType, RectCoord, ReticleReference, Viewport, WaferMapData, XYPoint,
} from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import { DataGrid } from 'devextreme-react';
import { NOTCH_FLAT_TOGGLE_DIAMETER_VALUE } from './wafer-control-map';

const IMPERIAL_TO_METRIC_CONVERSION_MAPPINGS = [
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MILLIMETERS',
    imperialVal: 3,
    metricVal: 76,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MILLIMETERS',
    imperialVal: 4,
    metricVal: 100,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MILLIMETERS',
    imperialVal: 6,
    metricVal: 150,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MILLIMETERS',
    imperialVal: 8,
    metricVal: 200,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'CENTIMETERS',
    imperialVal: 3,
    metricVal: 7.6,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'CENTIMETERS',
    imperialVal: 4,
    metricVal: 10,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'CENTIMETERS',
    imperialVal: 6,
    metricVal: 15,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'CENTIMETERS',
    imperialVal: 8,
    metricVal: 20,
  },

  {
    imperialUnit: 'INCHES',
    metricUnit: 'MICRONS',
    imperialVal: 3,
    metricVal: 76000,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MICRONS',
    imperialVal: 4,
    metricVal: 100000,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MICRONS',
    imperialVal: 6,
    metricVal: 150000,
  },
  {
    imperialUnit: 'INCHES',
    metricUnit: 'MICRONS',
    imperialVal: 8,
    metricVal: 200000,
  },
];

// base is mm
const BASE_UNIT_TO_MICRON = 1000;
const BASE_UNIT_TO_CM = 0.1;
const BASE_UNIT_TO_INCH = (1 / 25.4);

const MM_TO_INCH = (1 / 25.4);
const CM_TO_INCH = (1 / 2.54);
const MICRON_TO_INCH = (1 / 25400);

export class UtilityFunctions {
  public static getDieTypeIdFromName = (name: string, dieTypes: DieType[]) => {
    const filteredDieTypes = dieTypes.filter((dt: DieType) => dt.name === name);
    if (filteredDieTypes.length > 0) {
      return filteredDieTypes[0].id;
    }
    return null;
  };

  public static getDieTypeFromId = (id: string, dieTypes: DieType[]) => {
    const filteredDieTypes = dieTypes.filter((dt: DieType) => dt.id === id);
    if (filteredDieTypes.length > 0) {
      return filteredDieTypes[0];
    }
    return null;
  };

  public static checkFilter = (value: string, filterValue: string, filterType: string) => {
    switch (filterType) {
      case 'contains': return value.includes(filterValue);
      case 'notcontains': return !value.includes(filterValue);
      case 'startswith': return value.startsWith(filterValue);
      case 'endswith': return value.endsWith(filterValue);
      case '=': return value === filterValue;
      case '<>': return value !== filterValue;
      default: return value.includes(filterValue);
    }
  };

  public static capitalizeFirstLetter = (str: string) => {
    if (!str) return '';
    const separateWord = str.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i += 1) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase()
      + separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  };

  public static isDataGridInEditMode = (dataGridRef: DataGrid | null) => {
    if (dataGridRef) {
      const rows = dataGridRef.instance.getVisibleRows();
      const notInEditMode = rows.reduce((prevVal: boolean, row: any) => {
        return prevVal && !row.isEditing;
      }, true);
      return !notInEditMode;
    }
    return false;
  };

  public static getReticleGridRectCoordOfDie = (dieData: WaferMapData, reticleSize: { x: number, y: number }) => {
    // returns the grid coords of reticle in which the curr die falls in
    return {
      startX: (dieData.x as number) - (dieData.reticleX as number),
      startY: (dieData.y as number) - (dieData.reticleY as number),
      endX: (dieData.x as number) - (dieData.reticleX as number) + reticleSize.x - 1,
      endY: (dieData.y as number) - (dieData.reticleY as number) + reticleSize.y - 1,
    };
  };

  public static deleteReticleInfo = (dieData: WaferMapData, dieTypes: DieType[]) => {
    // eslint-disable-next-line no-param-reassign
    delete dieData.reticleX;
    // eslint-disable-next-line no-param-reassign
    delete dieData.reticleY;
    // eslint-disable-next-line no-param-reassign
    delete dieData.referenceReticle;
    // eslint-disable-next-line no-param-reassign
    delete dieData.reticleSite;

    if (dieData.dieType === UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', dieTypes)) {
      // eslint-disable-next-line no-param-reassign
      dieData.dieType = UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes) || '';
    }
  };

  public static getYCoordsOfIntersectionOfVerticleLineWithCircle = (u: number, h: number, k: number, r: number) => {
    // eqn of line: x = u
    // eqn of circle (x - h) ^ 2 + (y - k) ^ 2 = r ^ 2
    const determinant = 4 * k * k - 4 * (k * k + (u - h) * (u - h) - r * r);
    if (determinant < 0) {
      return null; // no point of intersection
    }
    const p1 = (2 * k + Math.sqrt(determinant)) / 2;
    const p2 = (2 * k - Math.sqrt(determinant)) / 2;
    if (p1 === p2) return null; // consider tangency as no intersection
    return { p1, p2 };
  };

  public static getXCoordsOfIntersectionOfHorizontalLineWithCircle = (u: number, h: number, k: number, r: number) => {
    // eqn of line: y = u
    // eqn of circle (x - h) ^ 2 + (y - k) ^ 2 = r ^ 2
    const determinant = 4 * h * h - 4 * (h * h + (u - k) * (u - k) - r * r);
    if (determinant < 0) {
      return null; // no point of intersection
    }
    const p1 = (2 * h + Math.sqrt(determinant)) / 2;
    const p2 = (2 * h - Math.sqrt(determinant)) / 2;
    if (p1 === p2) return null; // consider tangency as no intersection
    return { p1, p2 };
  };

  public static isWaferFlat = (waferDiameter: number) => {
    return waferDiameter < NOTCH_FLAT_TOGGLE_DIAMETER_VALUE;
  };

  public static getDieXOrY = (
    isAxisUpright: boolean,
    axisStart: number,
    axisIncrement: number,
    isAxisDefaultDirection: boolean,
    rowOrColNumber: number,
    offset: number,
    numOfRowsOrCol: number,
  ) => {
    // isAxisDefaultDirection => true if top to bottom or left to right
    if (isAxisUpright) {
      return (axisStart + axisIncrement * rowOrColNumber) + offset;
    // eslint-disable-next-line no-else-return
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isAxisDefaultDirection) {
        return ((numOfRowsOrCol - 1) - rowOrColNumber) + offset;
      // eslint-disable-next-line no-else-return
      } else {
        return rowOrColNumber + offset;
      }
    }
  };

  public static getColAndRowAxisDirection = (direction: WaferAxisDirection) => {
    const downRightCase = {
      colAxisDirection: ColAxisDirection.LeftToRight,
      rowAxisDirection: RowAxisDirection.TopToBottom,
    };
    switch (direction) {
      case WaferAxisDirection.DOWN_LEFT:
        return {
          colAxisDirection: ColAxisDirection.RightToLeft,
          rowAxisDirection: RowAxisDirection.TopToBottom,
        };
      case WaferAxisDirection.DOWN_RIGHT:
        return downRightCase;
      case WaferAxisDirection.UP_LEFT:
        return {
          colAxisDirection: ColAxisDirection.RightToLeft,
          rowAxisDirection: RowAxisDirection.BottomToTop,
        };
      case WaferAxisDirection.UP_RIGHT:
        return {
          colAxisDirection: ColAxisDirection.LeftToRight,
          rowAxisDirection: RowAxisDirection.BottomToTop,
        };
      default:
        return downRightCase;
    }
  };

  public static getWaferAxisDirection = (colAxisDirection: ColAxisDirection, rowAxisDirection: RowAxisDirection) => {
    if (colAxisDirection === ColAxisDirection.LeftToRight && rowAxisDirection === RowAxisDirection.TopToBottom) {
      return WaferAxisDirection.DOWN_RIGHT;
    } else if (colAxisDirection === ColAxisDirection.LeftToRight && rowAxisDirection === RowAxisDirection.BottomToTop) {
      return WaferAxisDirection.UP_RIGHT;
    } else if (colAxisDirection === ColAxisDirection.RightToLeft && rowAxisDirection === RowAxisDirection.TopToBottom) {
      return WaferAxisDirection.DOWN_LEFT;
    }
    return WaferAxisDirection.UP_LEFT;
  };

  public static getDieTrueXOrY = (xOrYCalc: number, isAxisDefaultDirection: boolean, waferMaxRowsOrCols: number, rowOrColOffset: number) => {
    // isAxisDefaultDirection => true if top to bottom or left to right
    if (isAxisDefaultDirection) {
      return xOrYCalc;
    } else {
      return waferMaxRowsOrCols - 1 - xOrYCalc + 2 * rowOrColOffset;
    }
  };

  private static micronToBaseUnit = (value: number) => {
    return value / BASE_UNIT_TO_MICRON;
  };

  private static cmToBaseUnit = (value: number) => {
    return value / BASE_UNIT_TO_CM;
  };

  private static dieToBaseUnit = (value: number, dieSide?: 'x' | 'y', dieDimensions?: { dieSizeX: number, dieSizeY: number, dieUOM: string }) => {
    if (dieDimensions && dieSide) {
      if (dieSide === 'x') {
        return UtilityFunctions.toBaseUnit({ value: dieDimensions.dieSizeX, UOM: dieDimensions.dieUOM }) * value;
      } else if (dieSide === 'y') {
        return UtilityFunctions.toBaseUnit({ value: dieDimensions.dieSizeY, UOM: dieDimensions.dieUOM }) * value;
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  public static inchToOtherUnit = (value: number, UOM: string, isDiameter?: boolean) => {
    let conversionFactor = 1;
    if (UOM === 'CENTIMETERS') {
      conversionFactor = CM_TO_INCH;
    } else if (UOM === 'MICRONS') {
      conversionFactor = MICRON_TO_INCH;
    } else if (UOM === 'MILLIMETERS') {
      conversionFactor = MM_TO_INCH;
    }
    if (isDiameter) {
      const mapping = IMPERIAL_TO_METRIC_CONVERSION_MAPPINGS.find((m) => {
        return (m.imperialUnit === 'INCHES' && m.imperialVal === value && m.metricUnit === UOM);
      });
      if (mapping) {
        return mapping.metricVal;
      } else {
        return value / conversionFactor;
      }
    } else {
      return value / conversionFactor;
    }
  };

  private static inchToBaseUnit = (value: number, isDiameter?: boolean) => {
    if (isDiameter) {
      const mapping = IMPERIAL_TO_METRIC_CONVERSION_MAPPINGS.find((m) => {
        return (m.imperialUnit === 'INCHES' && m.imperialVal === value && m.metricUnit === 'MILLIMETERS');
      });
      if (mapping) {
        return mapping.metricVal;
      } else {
        return value / BASE_UNIT_TO_INCH;
      }
    } else {
      return value / BASE_UNIT_TO_INCH;
    }
  };

  public static toBaseUnit = (
    data: {
      value: number,
      UOM: string,
      dieSide?: 'x' | 'y',
      dieDimensions?: { dieSizeX: number, dieSizeY: number, dieUOM: string },
      isDiameter?: boolean,
    },
  ): number => {
    switch (data.UOM) {
      case 'MICRONS':
        return UtilityFunctions.micronToBaseUnit(data.value);
      case 'MILLIMETERS':
        return data.value;
      case 'CENTIMETERS':
        return UtilityFunctions.cmToBaseUnit(data.value);
      case 'die':
        return UtilityFunctions.dieToBaseUnit(data.value, data.dieSide, data.dieDimensions);
      case 'INCHES':
        return UtilityFunctions.inchToBaseUnit(data.value, data.isDiameter);
      default:
        return data.value;
    }
  };

  public static euclideanDistance(p1: XYPoint, p2: XYPoint) {
    const a = p1.x - p2.x;
    const b = p1.y - p2.y;
    return Math.sqrt(a * a + b * b);
  }

  public static coordInRange(coord: XYPoint, xLimit: { max: number, min: number }, yLimit: { max: number, min: number }) {
    return coord.x >= xLimit.min && coord.x <= xLimit.max && coord.y >= yLimit.min && coord.y <= yLimit.max;
  }

  public static getReticleReference(x: number, y: number, retWidth: number, retHeight: number, reference: ReticleReference) {
    switch (reference) {
      case 'CENTER': return { x: (x + 0.5) * retWidth, y: (y + 0.5) * retHeight };
      case 'TOP_LEFT': return { x: x * retWidth, y: y * retHeight };
      case 'TOP_RIGHT': return { x: (x + 1) * retWidth, y: y * retHeight };
      case 'BOTTOM_LEFT': return { x: x * retWidth, y: (y + 1) * retHeight };
      case 'BOTTOM_RIGHT': return { x: (x + 1) * retWidth, y: (y + 1) * retHeight };
      default: return { x: (x + 0.5) * retWidth, y: (y + 0.5) * retHeight };
    }
  }

  public static areFloatsEqual(a: number, b: number) {
    return Math.abs(a - b) < 0.0000001;
  }

  public static getMaxRowsAndCols = (data: Dies) => {
    let maxCols = 0;
    for (let i = 0; i < data.length; i += 1) {
      if (maxCols < data[i].length) {
        maxCols = data[i].length;
      }
    }
    return {
      maxRows: data.length,
      maxCols,
    };
  };

  public static markDieUnderSelection = (
    arrayIndices: RectCoord,
    value: boolean,
    startRow: number,
    startCol: number,
    rowDirection: number,
    colDirection: number,
    waferData: any,
  ) => {
    for (let i = arrayIndices.startY; i <= arrayIndices.endY; i += 1) {
      for (let j = arrayIndices.startX; j <= arrayIndices.endX; j += 1) {
        const rIndex = startRow + rowDirection * i;
        const cIndex = startCol + colDirection * j;
        if (waferData.dies[rIndex] && waferData.dies[rIndex][cIndex]) {
          // eslint-disable-next-line no-param-reassign
          waferData.dies[rIndex][cIndex]!.isUnderSelection = value;
        }
      }
    }
  };

  public static getTranslatedCoords = (rect: RectCoord, viewport: Viewport, canvasHeight: number) => {
    // From
    // startX, endX => [0 - canvas.width]+
    // startY, endY => [0 - canvas.height]+

    // To
    // startX, endX => [0 - viewport.width]+
    // startY, endY => [0 - viewport.height]+

    return {
      startX: rect.startX - viewport.x,
      endX: rect.endX - viewport.x,
      startY: viewport.height + (viewport.y - canvasHeight + rect.startY),
      endY: viewport.height + (viewport.y - canvasHeight + rect.endY),
    };
  };

  public static rotate = (point: XYPoint, angle: number, isCLockwise: boolean) => {
    return isCLockwise ? {
      x: point.x * Math.cos(angle) + point.y * Math.sin(angle),
      y: point.y * Math.cos(angle) - point.x * Math.sin(angle),
    } : {
      x: point.x * Math.cos(angle) - point.y * Math.sin(angle),
      y: point.y * Math.cos(angle) + point.x * Math.sin(angle),
    };
  };

  public static solveSimultaneous = (a: number, b: number, c: number, p: number, q: number, r: number) => {
    if (((a * q - p * b) !== 0) && ((b * p - q * a) !== 0)) {
    // In this case we have a unique solution and display x and y
      return {
        x: (c * q - r * b) / (a * q - p * b),
        y: (c * p - r * a) / (b * p - q * a),
      };
    }
    return null;
  };

  private static componentToHex = (c: number) => {
    const a = c;
    const hex = a.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  public static rgbToHex = (r: number, g: number, b: number) => {
    return `#${UtilityFunctions.componentToHex(r)}${UtilityFunctions.componentToHex(g)}${UtilityFunctions.componentToHex(b)}`;
  };

  public static ignoreDecimalsIfVerySmall = (x: number) => {
    return Math.abs((x) - Math.round(x)) < 0.000000001 ? Math.round(x) : x;
  };

  public static toNotchPositionEnum = (notchPosition: string) => {
    switch (notchPosition) {
      case 'U':
        return NotchPosition.UP;
      case 'D':
        return NotchPosition.DOWN;
      case 'L':
        return NotchPosition.LEFT;
      case 'R':
        return NotchPosition.RIGHT;
      default:
        return DefaultNotchPosition;
    }
  };

  public static toRotationAngle = (notchPosition: string) => {
    switch (notchPosition) {
      case 'U':
        return 180;
      case 'D':
        return 0;
      case 'L':
        return 90;
      case 'R':
        return 270;
      default:
        return 0;
    }
  };

  public static fromCamelToNormalCase = (str: string) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str_: string) => { return str_.toUpperCase(); });
  }

  public static upperToLowerCase(str: string) {
    return str.toLowerCase().replace(/_/g, ' ');
  }
}
