/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';
import { IGraphType } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportHelper';
import {
  AggregateFunctionNames, ICombinedGraphData, SeriesTypeNames,
} from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';

export interface IFailedBinNumbers {
  Id: string;
  Name: string;
  Number: string;
  Color: string;
}

export interface IUser {
  Id: string;
  FirstName?: string;
  LastName?: string;
}

export interface IDatabase {
  id: string;
  name: string; 
  type: string;
}
export interface IUserDetails {
  tenantId: string;
  userId: string;
  firstName: string;
  lastName: string;
  username: string;
  authToken: string;
  databases: IDatabase[];
}

export const ServiceType = {
  PAT: 'PAT',
  SWM: 'SWM',
  AMG: 'AMG',
  SPC: 'SPC',
  STATIC_PAT: 'STATIC_PAT',
  STATIC_PAT_PER_SITE: 'STATIC_PAT_PER_SITE',
  DYNAMIC_PAT: 'DYNAMIC_PAT',
  DYNAMIC_PAT_PER_SITE: 'DYNAMIC_PAT_PER_SITE',
};

export const LoaderStepType = {
  Converter: 'Converter',
  Copy: 'Copy',
  Enrichment: 'Enrichment',
  Load: 'Load',
};

const ServiceTypes = [
  ['', 'Add Stage'],
  [ServiceType.PAT, ServiceType.PAT],
  ['', 'MVPAT'],
  ['', 'GDBN'],
  ['', 'GDBNZ'],
  ['', ServiceType.SWM],
  [ServiceType.SPC, ServiceType.SPC],
  ['', 'SBL'],
  ['', 'SYL'],
  [ServiceType.AMG, ServiceType.AMG],
];

export const LoaderSteps = [
  ['', 'Add Stage'],
  [LoaderStepType.Converter, LoaderStepType.Converter],
  // [LoaderStepType.Copy, LoaderStepType.Copy],
  [LoaderStepType.Enrichment, LoaderStepType.Enrichment],
  [LoaderStepType.Load, LoaderStepType.Load],
];

export interface IRule {
  Id: string;
}

export interface IPATPolicyStepSettings {
  preConditions: object[];
  postConditions: object[];
}

export interface ISPCPolicyStepSettings {
  spcRules: object[];
  spcParameters: object[];
  spcReports: object[];
}

export interface ISWMSource {
  copyMetadata: boolean;
  copyParametricData: boolean;
  criteria: object[];
  waferPolicyStep: string;
  waferPolicy: string;
  alias: string;
}

export interface ISWMPolicyStepSettings {
  testProgramMatchingStrategy: string;
  mergeFacility: string;
  mergeWorkCenter: string;
  mergeDevice: string;
  sources: ISWMSource[];
  preConditions: object[];
  postConditions: object[];
}

export interface IAMGPolicyStepSettings {
  fileOutputs: IOutputFileLocation[];
  preConditions: object[];
  postConditions: object[];
}

export interface ILoaderLoadPolicyStepSettings {
  sourceLocation: IFileLocation;
  deleteFromSource: boolean;
  targetDatabase: string;
  readHbr: boolean;
  readSbr: boolean;
  readTsr: boolean;
  generateHbr: boolean;
  generateSbr: boolean;
  generateTsr: boolean;
  readPir: boolean;
  ignorePirExpression: string;
  readPtr: boolean;
  ignorePtrExpression: string;
  ptrNameExpression: string;
  readFtr: boolean;
  ignoreFtrExpression: string;
  ftrNameExpression: string;
  readMpr: boolean;
  ignoreMprExpression: string;
  serialTesting: boolean;
  disableRevision: boolean;
  applyDataScaling: boolean;
  errorScaleMatch: boolean;
  testParamUniqueCriteria: any[];
  preConditions: object[];
  postConditions: object[];
  applyDeviceSetup: boolean;
  binPlusCriteria: any[];
  binPlusResult: string;
  applyWcmEnrichment: boolean;
  generateMergeProbeSummariesManually: boolean;
}

export type ScheduleType = 'Manual' | 'Poller' | 'Webhook';

export interface ILoaderSchedulerPolicyStepSettings {
  type: ScheduleType;
  startsAt: Date;
  interval: number;
}

export interface ILoaderEnrichmentPolicyStepSettings {
  parameters: {
    name: string;
    expression: string;
  }[];
  sourceLocation: IFileLocation;
  preConditions: object[];
  postConditions: object[];
}

export interface IFileLocation {
  type: string;
  container: string;
  folderPath: string;
  ftpAddress: string;
  ftpUsername: string;
  ftpPassword: string;
  scanSubDirectories: boolean;
  detectDuplicates: IDetectDuplicateFiles;
  policyStep: string;
  fileFilterOptionsCheck: boolean;
  fileFilterCaseSensitive: boolean;
  fileFilterSelectAll: boolean;
  fileFilterOptions: IFileFilter[];
}

export interface IFileFilter {
  isSelected: boolean;
  filterName: string;
  filterCondition: string;
  firstValue: string;
  secondValue: string;
  booleanExpression: string;
}

export interface IDetectDuplicateFiles {
  detectDuplicateFiles: boolean;
  detectionMethod: string;
  fileContentAttributes: string[];
  duplicateOutcome: string;
  rejectDuplicateOutcome: string;
  moveFolderPath: string;
  moveAndRenameFolderPath: string;
  renameFileExpression: string;
  moveAndRenameFileExpression: string;
}

export interface IOutputFileLocation extends IFileLocation {
  binMapType: string;
  outputFormatDictList: object[];
}

export interface ILoaderConverterPolicyStepSettings {
  sourceFormat: string;
  targetFormat: string;
  sourceLocation: IFileLocation;
  preConditions: object[];
  postConditions: object[];
}

export interface ILoaderCopyPolicyStepSettings {
  sourceLocation: IFileLocation;
  targetLocation: IFileLocation;
  deleteFromSource: boolean;
  preConditions: object[];
  postConditions: object[];
}

export interface IPATRuleSet {
  rules: string[]
  patRuleSetName: string;
  patRuleSetType: string;
  patRuleSetSequence: number;
}

export interface IPolicyStep {
  Id?: string;
  PolicyId?: string;
  OutputReference: string;
  Type: string;
  IsNotificationEnabled: boolean;
  CreatedBy: IUser;
  UpdatedBy: IUser;
  CreatedOn: Date;
  UpdatedOn: Date;
  Sequence: number;
  Rules: string[];
  isOpen: boolean;
  StepSettings?: any;
  hocKey: string;
  stepOwner?: string;
  additionalEmail?: string;
  expressions?: object[];
}

export interface PolicySearchCriteria {
  id: string;
  type: string;
  name: string;
}

export interface Policy {
  Id: string;
  Name: string;
  Version: string;
  Purpose: string;
  Privacy: string;
  State: string;
  Owner: string;
  CreatedBy: IUser;
  UpdatedBy: IUser;
  CreatedOn: Date;
  UpdatedOn: Date;
  PolicySteps: IPolicyStep[];
  SearchCriteria: PolicySearchCriteria[];
  SearchCriteriaExpressions: string;
  AdvancedSearchExpressions: string;
  Type: string;
  Schedule: ILoaderSchedulerPolicyStepSettings;
  defaultSelections?: IDefaultSelectionCriteria[];
  visibleColumnsJson?: { [entityName: string]: string };
}

export interface SearchCriteriaSelectedValue {
  name: string;
  id: string;
}

export const fileFilterOptionsValues = {
  filterNames: ['Creation Date', 'Modified Date', 'Wafer End Time', 'Lot End Time', 'Name/Extension', '(', ')'],
  dateFilterConditions: ['greater than', 'greater than or equal to', 'less than', 'less than or equal to', 'between', 'equal to', 'does not equal'],
  nameExtensionFilterConditions: ['equal', 'not equal', 'begins with', 'not begins with', 'ends with', 'not ends with', 'contains', 'not contains', 'custom'],
  booleanExpressionOptions: ['', 'and', 'or', 'end'],
  fileInclusionOption: ['Include', 'Exclude'],
}

const TestProgramMatchingStrategies = [
  ['MATCH_BOTH', 'Match Test Name & Test Number'],
  ['MATCH_NAME_ONLY', 'Match Test Name Only'],
  ['MATCH_NUMBER_ONLY', 'Match Test Number Only'],
  ['COMPOSITE_KEY', 'Composite Key'],
  ['EXPRESSION', 'Add Expression'],
];

const ParametricReferences = [
  ['NONE', 'None'],
  ['SOURCE1', 'Source 1'],
  ['SOURCE2', 'Source 2'],
  ['BOTH', 'Both'],
];

const WaferReferences = [
  ['SOURCE1', 'Source 1'],
  ['SOURCE2', 'Source 2'],
];

export const DieTypesMap = new Map();
DieTypesMap.set('WAT_PCM', 'WAT/PCM');
DieTypesMap.set('PARTIAL_DIE', 'Partial Die');
DieTypesMap.set('NOT_PROBED', 'Not Probed');
DieTypesMap.set('PROBED_BUT_EXCLUDED', 'Probed but excluded');
DieTypesMap.set('PROBED_DIE', 'Probe Die');
DieTypesMap.set('EXCLUDED_FROM_ASSEMBLY', 'Excluded from Assembly');

export type TTypeAheadValuesType = 'list' | 'groupedList' | 'grid';

export interface IHint {
  message?: string | JSX.Element;
  href?: string | undefined;
  position?:
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
  | undefined;
}

export interface DockerContainerStats {
  id: string;
  name: string;
  state: string;
  freeMemory: string;
  cpuUsed: string;
}

export interface RollingDataCoordinates {
  x: number[];
  y: number[];
}

export interface RollingData {
  data: RollingDataCoordinates;
  minValue: number;
  maxValue: number;
}

export interface DockerMachineStats {
  id: string;
  name: string;
  type: string;
  freeMemory: string;
  cpuUsed: string;
  tasks: DockerContainerStats[];
  rollingCPUUsed: RollingData;
  rollingMemoryUsed: RollingData;
}

export interface ISourceLocation {
  type: string,
  container: undefined,
  folderPath: undefined,
  ftpAddress: undefined,
  ftpUsername: undefined,
  ftpPassword: undefined,
  scanSubDirectories: boolean,
  detectDuplicates: IDetectDuplicateFiles,
  policyStep: 'Default',
  fileFilterOptionsCheck: boolean,
  fileFilterCaseSensitive: boolean,
  fileFilterSelectAll: boolean,
  fileFilterOptions: IFileFilter[],
}

export const DefaultPolicyStepSettings = {
  SPC: {
    spcRules: [],
    spcParameters: [],
    spcReports: [],
  },
  PAT: {
    preConditions: [],
    postConditions: [],
    patRuleSet: [],
    generalSettings: [],
  },
  SWM: {
    testProgramMatchingStrategy: TestProgramMatchingStrategies[0][0],
    mergeFacility: '',
    mergeWorkCenter: '',
    mergeDevice: '',
    sources: [
      {
        copyMetadata: false,
        copyParametricData: false,
        criteria: [],
        waferPolicyStep: 'NONE',
        waferPolicy: 'NONE',
        alias: 'Source1',
      },
    ],
    preConditions: [],
    postConditions: [],
  },
  AMG: {
    outputFiles: [],
    expressions: [],
    preConditions: [],
    postConditions: [],
  },
  Converter: {
    sourceFormat: 'STDF',
    targetFormat: 'ATDF',
    sourceLocation: {
      type: 'PolicyStep',
      container: undefined,
      folderPath: undefined,
      ftpAddress: undefined,
      ftpUsername: undefined,
      ftpPassword: undefined,
      scanSubDirectories: false,
      detectDuplicates: {
        detectDuplicateFiles: false,
        fileContentAttributes: [],
        detectionMethod: 'filename',
        duplicateOutcome: 'process',
        rejectDuplicateOutcome: 'leave',
        moveFolderPath: '',
        moveAndRenameFolderPath: '',
        renameFileExpression: '',
        moveAndRenameFileExpression: '',
      },
      fileFilterOptionsCheck: false,
      fileFilterCaseSensitive: false,
      fileFilterSelectAll: false,
      fileFilterOptions: [],
    },
    preConditions: [],
    postConditions: [],
  },
  Copy: {
    preConditions: [],
    postConditions: [],
    sourceLocation: {
      type: 'PolicyStep',
      container: undefined,
      folderPath: undefined,
      ftpAddress: undefined,
      ftpUsername: undefined,
      ftpPassword: undefined,
    },
    deleteFromSource: false,
    targetLocation: {
      type: 'S3',
      container: undefined,
      folderPath: undefined,
      ftpAddress: undefined,
      ftpUsername: undefined,
      ftpPassword: undefined,
    },
  },
  Enrichment: {
    sourceLocation: {
      type: 'PolicyStep',
      container: undefined,
      folderPath: undefined,
      ftpAddress: undefined,
      ftpUsername: undefined,
      ftpPassword: undefined,
      scanSubDirectories: false,
      detectDuplicates: {
        detectDuplicateFiles: false,
        fileContentAttributes: [],
        detectionMethod: 'filename',
        duplicateOutcome: 'process',
        rejectDuplicateOutcome: 'leave',
        moveFolderPath: '',
        moveAndRenameFolderPath: '',
        renameFileExpression: '',
        moveAndRenameFileExpression: '',
      },
      PolicyStep: 'Default',
      fileFilterOptionsCheck: false,
      fileFilterCaseSensitive: false,
      fileFilterSelectAll: false,
      fileFilterOptions: []
    },
    parameters: [],
    preConditions: [],
    postConditions: [],
  },
  Load: {
    sourceLocation: {
      type: 'PolicyStep',
      container: undefined,
      folderPath: undefined,
      ftpAddress: undefined,
      ftpUsername: undefined,
      ftpPassword: undefined,
      scanSubDirectories: false,
      detectDuplicates: {
        detectDuplicateFiles: false,
        fileContentAttributes: [],
        detectionMethod: 'filename',
        duplicateOutcome: 'process',
        rejectDuplicateOutcome: 'leave',
        moveFolderPath: '',
        moveAndRenameFolderPath: '',
        renameFileExpression: '',
        moveAndRenameFileExpression: '',
      },
      policyStep: 'Default',
      fileFilterOptionsCheck: false,
      fileFilterCaseSensitive: false,
      fileFilterSelectAll: false,
      fileFilterOptions: []
    },
    deleteFromSource: false,
    targetDatabase: undefined,
    readHbr: false,
    readSbr: false,
    readTsr: false,
    generateHbr: false,
    generateSbr: false,
    generateTsr: false,
    readPir: true,
    ignorePirExpression: undefined,
    readPtr: false,
    ignorePtrExpression: undefined,
    ptrNameExpression: undefined,
    readFtr: false,
    ignoreFtrExpression: undefined,
    ftrNameExpression: undefined,
    readMpr: false,
    ignoreMprExpression: undefined,
    failLoadOnNoScreenType: false,
    serialTesting: false,
    disableRevision: true,
    applyDataScaling: false,
    applyDeviceSetup: false,
    binPlusCriteria: ['Number'],
    binPlusResult: 'LOAD_AND_SHOW_WARNING',
    errorScaleMatch: false,
    testParamUniqueCriteria: ['Number'],
    preConditions: [],
    postConditions: [],
    applyWcmEnrichment: false,
    generateMergeProbeSummariesManually: false,
  },
};

export {
  ServiceTypes,
  TestProgramMatchingStrategies,
  ParametricReferences,
  WaferReferences,
};

export interface IExpressionsList {
  operationName: string;
  columnName: string;
  queryText: string;
  controlType: string;
  dataType: string;
  groupConditionOn: 'AND' | 'OR' | 'CUSTOM';
  parentGroupId?: number;
  parentGroupConditionOn?: 'AND' | 'OR' | 'CUSTOM';
}

export interface ICaptionColor {
  value: string;
  color: string;
  columnName: string;
  type: string;
  isPrimaryColumn: boolean;
}

export interface IColumnStructure {
  alias: string;
  caption: ICaptionColor[];
  dataField: string | null;
  fields: IColumnStructure[];
  columnName: string;
  selected: boolean;
  hideInColumnChooser: boolean;
}

export interface IExpressionsObject {
  facility: IExpressionsList[];
  workCenter: IExpressionsList[];
  device: IExpressionsList[];
  testProgram: IExpressionsList[];
  testProgramRevision: IExpressionsList[];
  lot: IExpressionsList[];
  wafer: IExpressionsList[];
}

export const DialogType = {
  GROUP: 'GROUP',
  SORT: 'SORT',
};

export interface ISelectionCriteria {
  useGridExpressions?: boolean;
  widgetsList?: {
    controlType: string;
    isVisible: boolean;
    group: string /* 'U' for Upper 'L' for Lower */;
    isExpanded: boolean;
  }[];
  facilitySelected?: string[];
  workCenterSelected?: string[];
  deviceSelected?: string[];
  testProgramSelected?: string[];
  testProgramRevisionSelected?: string[];
  lotSelected?: string[];
  waferSelected?: string[];
  parameterSelected?: string[];
  getFilterObject?: any;
  filterExpression?: any;
  errorMessage?: string;
  filterRowData?: IExpressionsObject;
  showHeading?: boolean;
}

export interface IFieldList {
  caption?: string;
  dataField?: string;
  filterOperations?: Array<string>;
  dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime';
  minWidth?: string | number;
  headerCellRender?: any;
  fields?: any;
  selected?: boolean;
}

// ----- POWER VIEW INTERFACES -----
export interface Report {
  reportName: string;
  reportKey: string;
  reportType: string;
  isExpanded: boolean;
  isVisible: boolean;
  isPinned: boolean;
  showInList: boolean;
  primaryWidget: any;
  secondaryWidget: any;
  legend: any;
  cache: any;
}

export const ReportType = {
  wafer: 'wafer',
  graph: 'graph',
  stack: 'stack',
};
export const ReportKeys = {
  BIN_WAFER_MAP: 'BIN_WAFER_MAP',
  PARAMETRIC_WAFER_MAP: 'PARAMETRIC_WAFER_MAP',
  STACK_BIN_WAFER_MAP: 'STACK_BIN_WAFER_MAP',
  PAT_LIMIT_TREND_CHART: 'PAT_LIMIT_TREND_CHART',
  RAW_DATA_GRID: 'RAW_DATA_GRID',
  PARAMETRIC_HISTOGRAM: 'PARAMETRIC_HISTOGRAM',
  TREND_REPORT: 'TREND_REPORT',
  YIELD_REPORT: 'YIELD_REPORT',
  BIN_FLIP_MAP: 'BIN_FLIP_MAP',
  GLOBAL_RADAR: 'GLOBAL_RADAR',
  MASTER_DATA_GRID: 'MASTER_DATA_GRID',
};
// REPORTS
export const DEFAULT_BIN_WAFER_MAP: Report = {
  reportName: 'Bin Wafer Map',
  reportKey: ReportKeys.BIN_WAFER_MAP,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.wafer,
};
export const DEFAULT_STACK_BIN_WAFER_MAP: Report = {
  reportName: 'Stack Bin Wafer Map',
  reportKey: ReportKeys.STACK_BIN_WAFER_MAP,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.wafer,
};
export const DEFAULT_PARAMETRIC_WAFER_MAP: Report = {
  reportName: 'Parametric Wafer Map',
  reportKey: ReportKeys.PARAMETRIC_WAFER_MAP,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.wafer,
};
export const DEFAULT_PAT_LIMIT_TREND_CHART: Report = {
  reportName: 'PAT Limits Trend Chart',
  reportKey: ReportKeys.PAT_LIMIT_TREND_CHART,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};
export const DEFAULT_RAW_DATA_GRID: Report = {
  reportName: 'Raw Data Grid',
  reportKey: ReportKeys.RAW_DATA_GRID,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: true,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};
export const DEFAULT_MASTER_DATA_GRID: Report = {
  reportName: 'Master Data Grid',
  reportKey: ReportKeys.MASTER_DATA_GRID,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: true,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};
export const DEFAULT_PARAMETRIC_HISTOGRAM: Report = {
  reportName: 'Parametric Pareto Report',
  reportKey: ReportKeys.PARAMETRIC_HISTOGRAM,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};

export const DEFAULT_YIELD_REPORT: Report = {
  reportName: 'Yield Report',
  reportKey: ReportKeys.YIELD_REPORT,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};
export const DEFAULT_TREND_REPORT: Report = {
  reportName: 'Trend Report',
  reportKey: ReportKeys.TREND_REPORT,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.graph,
};
export const DEFAULT_BIN_FLIP_MAP: Report = {
  reportName: 'Bin Flip Map',
  reportKey: ReportKeys.BIN_FLIP_MAP,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: true,
  reportType: ReportType.wafer,
};
export const DEFAULT_GLOBAL_RADAR: Report = {
  reportName: 'Global Radar',
  reportKey: ReportKeys.GLOBAL_RADAR,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  legend: undefined,
  cache: undefined,
  isExpanded: false,
  isVisible: false,
  isPinned: false,
  showInList: false,
  reportType: ReportType.wafer,
};

export interface IColumnMeta {
  columnName: string;
  visibleByDefault: boolean;
  entityType: string;
  tableName: string;
  type: string;
  sequence: number;
  dataField: string;
  columnCategory: string;
}

export interface IColumnMetaDictionary {
  FACILITY: IColumnMeta[];
  WORK_CENTER: IColumnMeta[];
  DEVICE: IColumnMeta[];
  TEST_PROGRAM: IColumnMeta[];
  TEST_PROGRAM_REVISION: IColumnMeta[];
  LOT: IColumnMeta[];
  WAFER: IColumnMeta[];
}

export interface IRowKeys {
  columnName: string;
  value: string[];
}
export interface IVisibleColumnsDetails {
  columnNames: string[];
  values: string[][];
  entityType: string;
}

export interface ISelectionsObject {
  id: string;
  name: string;
}

export type GRID_TYPE_ENUM =
    'UNPIVOTED' |
    'PIVOTED_SPLIT' |
    'PIVOTED_MERGE';

export type GRID_RENDERING_OPTION_ENUM =
    'SHOW_NAME_COLUMNS_BY_DEFAULT' |
    'SHOW_GROUPING_COLUMNS' |
    'SHOW_SORTING_COLUMNS' |
    'SHOW_BIN_CALCULATED_COLUMNS' |
    'SHOW_PARAMETRIC_CALCULATED_COLUMNS';

export interface IFilterRawDataDTO {
  Skip: number;
  Take: number;
  RequireTotalCount: boolean;
  ReportSessionId: string;
  Filter: string; // IExpressionsList[]
  Sort: string;
  AdvancedFilter: string;
  TestParameterIds: string[];
  GridTypeEnum: GRID_TYPE_ENUM;
  GridRenderingOptions: GRID_RENDERING_OPTION_ENUM[];
  Grouping: GroupingSortingDTO[];
  Sorting: GroupingSortingDTO[];
  TestParameterConditions: { [key: string]: { [key: string]: string[] } };
}

export interface ISelectionCriteriaReturnValue {
  selections: { entityType: string, controlType: string, columnName?: string, values: ISelectionsObject[], isVisibiltyDefault?: boolean,
    visibleColumns?: string[] | undefined; visibleColumnsValues?: { [column: string]: string; }[] | undefined }[];
  expressions: { entityType: string, values: IExpressionsList[] }[];
  advancedFilter: string;
  testParameterConditions: { [key: string]: any };
  defaultSelectionCriteria?: IDefaultSelectionCriteria[]
}

export interface IdefaultWidget {
  controlType: string;
  columnWeight: number;
  identityColumnName?: string;
  forceUpdateCallback?: any;
  isVisible?: boolean;
  highlightingKeys?: { [key: string]: IVisibleColumnsDetails };
  filteringKeys?: { [key: string]: IVisibleColumnsDetails };
  selectedRowKeys?: string[];
  selectedRowIDs?: ISelectionsObject[];
  columnFilters?: IExpressionsList[];
  gridColumns?: IColumnMeta[];
  highlightOtherGrids?: boolean;
  isCleared?: boolean;
  errorMessage?: string;
  maxSelectedCount?: number;
  minVisibilityCriteria?: string[];
  defaultSelectionCriteria: IDefaultSelectionCriteria;
}

export const DefaultSelectionCriteriaMappings = {
  wafer_key: 'Wafer Id',
  lot_key: 'Lot Id',
};

export interface ISelectionCriteriaData {
  entityType: string;
  values: string[];
}

export interface WaferGalleryWaferObject {
  waferKey: string,
  waferId?: string,
  dataUrl: string
}

export interface IGroupingSortingList {
  grouping: GroupingSortingDTO[],
  sorting: GroupingSortingDTO[]
}

// ----------------------------------------------------
// DETAILED Bin Pareto REPORT STARTS

export interface IGraphCoordinates {
  x: number[] | string[];
  y: number[] | string[] | any[];
  z: number[] | string[] | any[];
  xCoordinateCustom: number[] | string | any[];
  yCoordinateCustom: number[] | string | any[];
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  xValue: number[] | string[];
  xText: string[];
  yText: string[];
  colors: string[];
  selectedIndexes: number[];
  selectedIndexesCustom: any[];
  toolTips: { [key: string]: any }[];
  graphCaption: string;
  uniqueId?: string;
  graphType?: string,
  groupingKey: number[] | string[];
  similarInteractionDetail: { [key: string]: any }[];
  selectedInteractionDetail: { [key: string]: any }[];
  grouping: GroupingSortingDTO[];
  isSPCLimitLine?: boolean;
  dieHeight?: number;
  dieWidth?: number;
  reportSpecificAttributes: { [key:string]: any };
  comparisonGraphResponseData?: IComparisonGraphResponseData | undefined;
}

export interface IComparisonGraphResponseData {
  inputWafers: IWaferCombinationsRequestObject;
  passToFailCount: number;
  failToPassCount: number;
  unchangedDiePercentage: number;
  dieComparisonStatuses : string[];
  binNumberChangeList: { [key: string] : string }[];
}

export interface IDrilledTrendReportData {
  axisData: IAxisData,
  testParameterId: string,
  dataTable: any,
  fields: any,
  colors: string[],
}

export interface IAxisData {
  xAxisDatas: IXAxisData[];
  yAxis: string[];
}

export interface IXAxisData {
  xAxis: string[];
  XAxisLabel: string;
}

export interface IThumbNailData {
  title: string;
  imageUrl: string;
  entityType: string;
}

export interface IIconDefinition {
  icon: IconDefinition;
  image?: string;
  entityType: string;
}

export interface IDrilledGraphData {
  thumbNail?: IThumbNailData,
  isSelected: boolean;
  sequence: number;
  name: string,
  graph?: IGraphCoordinates;
  groups: IDrilledGraphData[];
  groupKey: string;
  entityType?: string;
}

export interface ITrendReportData {
  testProgramName: string,
  deviceName: string,
  lotId: string,
  testParameterId: string;
  testParameterName: string;
  waferTrendDataItems: ITrendReportWaferData[];
  trendReportHeader: any;
}

export interface ITrendReportWaferData {
  xAxis: number[];
  yAxis: number[];
  waferId: string;
  waferKey: string;
  dies: string[];
  color: string;
}

export type EntityType = 'FACILITY' | 'WORK_CENTER' | 'DEVICE' | 'TEST_PROGRAM' | 'TEST_PARAMETER' | 'TEST_PROGRAM_REVISION' | 'LOT' | 'WAFER';
type SortingOrder = 'ASC' | 'DESC';
export type OperationType = 'SORTING' | 'GROUPING';

export interface GroupingSortingDTO {
  entityType: EntityType;
  name: string;
  sequence: number;
  operationType: OperationType;
  order?: SortingOrder;
  columnName: string;
  values?: any[];
  columnCategory?: string;
  isLastGrouping?: boolean;
}

interface SelectionCriteriaDTO {
  entityType: EntityType;
  values: string[];
}

export interface IGenericDetailedReportRequestObject {
  productType?: string | undefined;
  selectionStoreDataSetCount?: number;
  scwData: SelectionCriteriaDTO[];
  filters: string;
  grouping: GroupingSortingDTO[];
  sorting: GroupingSortingDTO[];
  binPlusDefId?: string;
  isFlatGraph?: boolean;
  reportSessionId: string;
  function: AggregateFunctionNames | null;
  seriesType: SeriesTypeNames | null;
  reportHeaderId?: string;
  summaryStat?: string[];
  reportType?: string;
  config: { [key: string]: any };
  rulePayLoad?: string;
  testParameterIndex?: number[];
  selectedTestParameterIndex?: number;
  limits?: any;
  hasNonSequentialXValues?: boolean;
  headerSettingsRequestObj?: IHeaderSettingsRequestObj;
  isSoftBin?: boolean | undefined;
}

export interface IDetailedTrendRequestObject {
  scwData: SelectionCriteriaDTO[];
  grouping: GroupingSortingDTO[];
  sorting: GroupingSortingDTO[];
  binPlusDefId: string;
  function: string;
}

export interface IPatLimits {
  highVal: number;
  lowVal: number;
  colorLow: string;
  customNameLow: string;
  appearanceLow: string;
  thicknessLow: string;
  colorHigh: string;
  customNameHigh: string;
  appearanceHigh: string;
  thicknessHigh: string;
}

export interface ILimitLinesVisualizationFuncObj {
  graphData: ICombinedGraphData;
  graphIndex: number;
  limitSettingsObj: any;
  plotVerticalLines: boolean;
  reportType: string;
  graphType: IGraphType;
  seriesType: SeriesTypeNames | null;
  xMax: number;
  xMin: number;
}

export interface IHeaderSettingsRequestObj {
  xValuesForComputation: any[] | undefined;
  xValuesOnActualGraph: number[] | undefined;
  yValues: number[] | undefined;
}

export interface IFavoriteSelectionCriteria{
  id: string;
  name: string;
  privacy: string;
  purpose: string;
  owner: string;
  advanceFilter: string;
  folder: string;
}
export interface IWaferCombinationDataDTO {
  id: string;
  probeCount: string;
  key: string;
  yield: number;
  dieCount: number;
}

export interface IWaferCombinationsRequestObject {
  baseWafer : IWaferCombinationDataDTO;
  nonBaseWafer: IWaferCombinationDataDTO;
}

export type ExportFormatType = 'PDF' | 'CSV' | 'XLS' | 'HTML';
export type ColumnsToExportType = 'VISIBLE' | 'ALL';

export interface IExportDataOptionsDto {
  filterRawDataDto: any;
  exportFormat: ExportFormatType;
  selectedColumns: string[];
  selectedColumnsCaptions: string[];
  fileSaveName: string;
}

export interface IExportDataInitialOptions {
  exportFormat: ExportFormatType,
  columnsToExport: ColumnsToExportType,
  fileSaveName: string,
}
