// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

const radarView = (waferMapInstance: WebGLUtils) => {
  if (waferMapInstance.waferMapVariables.radarViewPort.width === 0
    && waferMapInstance.waferMapVariables.radarViewPort.height === 0) return;
  const radarVertexShader = `#version 300 es
    precision mediump float;
    in vec2 position;
    uniform float flipY;
    void main() {
        gl_Position = vec4(position.x,position.y*-1.0, 0.0, 1.0);
        gl_PointSize = 5.0;
    }`;
  const radarFragmentShader = `#version 300 es
    precision mediump float;
    out vec4 color;
    uniform vec4 reticleColor;
    void main() {
        color = reticleColor; // r,g,b,a
    }
    `;

  const radarProgram = waferMapInstance.getProgram(
    waferMapInstance.waferMapVariables.gl!,
    radarVertexShader,
    radarFragmentShader,
  );

  if (radarProgram === null || waferMapInstance.waferMapVariables.gl === null || waferMapInstance.waferMapVariables.radarDiesCoords === null) return;
  waferMapInstance.drawShape(
    waferMapInstance.waferMapVariables.gl,
    radarProgram!,
    waferMapInstance.waferMapVariables.radarDiesCoords,
    waferMapInstance.waferMapVariables.radarWaferMapColor,
    waferMapInstance.waferMapVariables.gl.TRIANGLES,
    waferMapInstance.waferMapVariables.radarViewPort,
    true,
  );
};

export default radarView;
