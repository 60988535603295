// eslint-disable-next-line no-unused-vars
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addErrorClasses, ErrorLabel } from 'components/template-component/form-error-label/ErrorLabel';
import {
  CalculationMethod, DieSpecType, ExclusionType, WaferAxisDirection, WaferOriginLocation,
} from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
// eslint-disable-next-line no-unused-vars
import { StandardReticle, WaferDefinitionData } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import { ScrollView } from 'devextreme-react';
import _ from 'lodash';
import React from 'react';
import {
  Button, Col, OverlayTrigger, Popover, Row, ToggleButton,
} from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import StandardReticleEditor from './standard-reticle-editor';
import { UtilityFunctions } from './utility';
import {
  WAFER_AXIS_DIRECTION, DIAMETERS, NOTCH_FLAT_TOGGLE_DIAMETER_VALUE, NOTCH_POSITIONS, UOMs, WAFER_ORIGIN_LOCATION,
} from './wafer-control-map';

type WaferDefinitionProps = {
  onSaveWaferDefinitionData: (data: WaferDefinitionData) => void,
  onSaveReticleData: (data: any) => void,
  waferDefinitionData: WaferDefinitionData,
};

type WaferDefinitionState = {
  waferDefinitionData: WaferDefinitionData,
  formErrors: { [key: string] : any },
  showReticleSitePopover: boolean,
};

const reticleReferences = [
  ['BOTTOM_LEFT', 'Bottom Left'],
  ['BOTTOM_RIGHT', 'Bottom Right'],
  ['CENTER', 'Center'],
  ['TOP_LEFT', 'Top Left'],
  ['TOP_RIGHT', 'Top Right'],
];

export default class WaferDefinition extends React.Component<WaferDefinitionProps, WaferDefinitionState> {
  constructor(props: WaferDefinitionProps) {
    super(props);
    const { waferDefinitionData } = this.props;
    this.state = {
      waferDefinitionData: { ...waferDefinitionData },
      formErrors: {},
      showReticleSitePopover: false,
    };
  }

  componentDidUpdate(prevProps: WaferDefinitionProps) {
    const { waferDefinitionData } = this.props;
    if (!_.isEqual(prevProps.waferDefinitionData.version, waferDefinitionData.version)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ waferDefinitionData: { ...waferDefinitionData } });
    }
  }

  validateReticleSize = (reticleSizeX: number | string, reticleSizeY: number | string) => {
    const errors: { [key: string] : any } = {};
    if (reticleSizeX.toString() === '') {
      errors.reticleSizeX = 'Required';
    } else if (+reticleSizeX <= 0) {
      errors.reticleSizeX = 'Must be greater than 0';
    }

    if (reticleSizeY.toString() === '') {
      errors.reticleSizeY = 'Required';
    } else if (+reticleSizeY <= 0) {
      errors.reticleSizeY = 'Must be greater than 0';
    }
    return errors;
  };

  validate = (data: WaferDefinitionData, onlyReticleData = false) => {
    let errors: { [key: string] : any } = {};

    const waferDiameter = UtilityFunctions.toBaseUnit({ value: +data.waferDiameter, UOM: data.waferDiameterUOM, isDiameter: true });
    const waferRadius = waferDiameter / 2;

    if (!onlyReticleData) {
      if (data.waferEdgeExclusion.toString() === '') {
        errors.waferEdgeExclusion = 'Required';
      }
      if (data.exclusionType === ExclusionType.NOTCH_KEEP_OUT && data.waferNotchKeepOut.toString() === '') {
        errors.waferNotchKeepOut = 'Required';
      }
      if (data.exclusionType === ExclusionType.FLAT_KEEP_OUT && data.waferFlatKeepOut.toString() === '') {
        errors.waferFlatKeepOut = 'Required';
      }
      if (data.exclusionType === ExclusionType.SCRIBE_LINE_AND_BASE_FLAT && data.waferBaseFlat.toString() === '') {
        errors.waferBaseFlat = 'Required';
      }
      if (data.exclusionType === ExclusionType.SCRIBE_LINE_AND_BASE_FLAT && data.waferScribeLine.toString() === '') {
        errors.waferScribeLine = 'Required';
      }
      if (data.waferRowOffset.toString() === '') {
        errors.waferRowOffset = 'Required';
      }
      if (data.waferColOffset.toString() === '') {
        errors.waferColOffset = 'Required';
      }
      if (data.dieSpecType === DieSpecType.DIE || data.dieSpecType === DieSpecType.BOTH) {
        if (data.dieSizeX.toString() === '') {
          errors.dieSizeX = 'Required';
        } else if (+data.dieSizeX <= 0) {
          errors.dieSizeX = 'Must be greater than 0';
        }
      }
      if (data.dieSpecType === DieSpecType.DIE || data.dieSpecType === DieSpecType.BOTH) {
        if (data.dieSizeY.toString() === '') {
          errors.dieSizeY = 'Required';
        } else if (+data.dieSizeY <= 0) {
          errors.dieSizeY = 'Must be greater than 0';
        }
      }
      if (data.dieSpecType === DieSpecType.STEP || data.dieSpecType === DieSpecType.BOTH) {
        if (data.dieStepSizeX.toString() === '') {
          errors.dieStepSizeX = 'Required';
        } else if (+data.dieStepSizeX <= 0) {
          errors.dieStepSizeX = 'Must be greater than 0';
        }
      }
      if (data.dieSpecType === DieSpecType.STEP || data.dieSpecType === DieSpecType.BOTH) {
        if (data.dieStepSizeY.toString() === '') {
          errors.dieStepSizeY = 'Required';
        } else if (+data.dieStepSizeY <= 0) {
          errors.dieStepSizeY = 'Must be greater than 0';
        }
      }
      if (data.dieSpecType === DieSpecType.BOTH && +data.dieStepSizeX <= +data.dieSizeX) {
        errors.dieStepSizeX = 'Must be greater than die width';
      }
      if (data.dieSpecType === DieSpecType.BOTH && +data.dieStepSizeY <= +data.dieSizeY) {
        errors.dieStepSizeY = 'Must be greater than die height';
      }
      if (data.calculationMethod === CalculationMethod.ROWS_COLS) {
        if (data.waferMaxRows.toString() === '') {
          errors.waferMaxRows = 'Required';
        } else if (+data.waferMaxRows <= 0) {
          errors.waferMaxRows = 'Must be greater than 0';
        }
        if (data.waferMaxCols.toString() === '') {
          errors.waferMaxCols = 'Required';
        } else if (+data.waferMaxCols <= 0) {
          errors.waferMaxCols = 'Must be greater than 0';
        }
      }
      if (UtilityFunctions.toBaseUnit({ value: +data.dieStepSizeX, UOM: data.dieUOM }) > waferDiameter) {
        errors.dieStepSizeX = 'Die step size X cannot be greater than wafer diameter';
      }
      if (UtilityFunctions.toBaseUnit({ value: +data.dieStepSizeY, UOM: data.dieUOM }) > waferDiameter) {
        errors.dieStepSizeY = 'Die step size Y cannot be greater than wafer diameter';
      }
      if (UtilityFunctions.toBaseUnit({ value: +data.waferEdgeExclusion, UOM: data.waferEdgeExclusionUOM }) > waferDiameter) {
        errors.waferEdgeExclusion = 'Edge exclusion cannot be greater than wafer diameter';
      }
    }
    if (data.calculationMethod === CalculationMethod.RETICLE || onlyReticleData) {
      errors = { ...errors, ...this.validateReticleSize(data.reticleSizeX, data.reticleSizeY) };

      if (UtilityFunctions.toBaseUnit({ value: +data.reticleOffsetX, UOM: data.reticleUOM })
        > waferRadius) {
        errors.reticleOffsetX = 'Reticle offset X cannot be greater than wafer radius';
      }
      if (UtilityFunctions.toBaseUnit({ value: +data.reticleOffsetY, UOM: data.reticleUOM })
        > waferRadius) {
        errors.reticleOffsetX = 'Reticle offset Y} cannot be greater than wafer radius';
      }

      if (data.reticleOffsetX.toString() === '') {
        errors.reticleOffsetX = 'Required';
      }

      if (data.reticleOffsetY.toString() === '') {
        errors.reticleOffsetY = 'Required';
      }
      if (!(data.standardReticle.reticle.length > 0
        && data.standardReticle.reticle.length === +data.reticleSizeY
        && data.standardReticle.reticle[0].length === +data.reticleSizeX)) {
        errors.standardReticle = 'Generate Reticle With New Dimensions';
      }
    }
    return errors;
  };

  onSaveWaferDefinitionData = async () => {
    const { waferDefinitionData } = this.state;
    const { onSaveWaferDefinitionData } = this.props;
    const errors = this.validate(waferDefinitionData);
    if (Object.keys(errors).length !== 0) {
      this.setState({ formErrors: errors });
    } else {
      if (waferDefinitionData.version !== 1 && !await ModalPopup.confirm({
        header: 'Override Alert',
        body: 'This action will override any changes made to the wafer control map. Do you want to proceed?',
      })) {
        this.setState({ formErrors: errors });
        return;
      }
      this.setState({ formErrors: errors }, () => {
        let dieSizeX = +waferDefinitionData.dieSizeX;
        let dieSizeY = +waferDefinitionData.dieSizeY;
        if (waferDefinitionData.dieSpecType === DieSpecType.STEP) {
          dieSizeX = +waferDefinitionData.dieStepSizeX;
          dieSizeY = +waferDefinitionData.dieStepSizeY;
        }
        let dieStepSizeX = +waferDefinitionData.dieStepSizeX;
        let dieStepSizeY = +waferDefinitionData.dieStepSizeY;
        if (waferDefinitionData.dieSpecType === DieSpecType.DIE) {
          dieStepSizeX = +waferDefinitionData.dieSizeX;
          dieStepSizeY = +waferDefinitionData.dieSizeY;
        }
        onSaveWaferDefinitionData({
          version: +waferDefinitionData.version,
          waferDiameterUOM: waferDefinitionData.waferDiameterUOM,
          waferDiameter: +waferDefinitionData.waferDiameter,
          waferEdgeExclusionUOM: waferDefinitionData.waferEdgeExclusionUOM,
          waferEdgeExclusion: +waferDefinitionData.waferEdgeExclusion,
          waferFlatKeepOutUOM: waferDefinitionData.waferFlatKeepOutUOM,
          waferFlatKeepOut: +waferDefinitionData.waferFlatKeepOut,
          waferNotchKeepOutUOM: waferDefinitionData.waferNotchKeepOutUOM,
          waferNotchKeepOut: +waferDefinitionData.waferNotchKeepOut,
          waferBaseFlatUOM: waferDefinitionData.waferBaseFlatUOM,
          waferBaseFlat: +waferDefinitionData.waferBaseFlat,
          waferScribeLineUOM: waferDefinitionData.waferScribeLineUOM,
          waferScribeLine: +waferDefinitionData.waferScribeLine,
          waferInputNotchPosition: waferDefinitionData.waferInputNotchPosition,
          waferOutputNotchPosition: waferDefinitionData.waferOutputNotchPosition,
          waferOriginLocation: waferDefinitionData.waferOriginLocation,
          waferAxisDirection: waferDefinitionData.waferAxisDirection,
          waferPGDW: +waferDefinitionData.waferPGDW,
          dieUOM: waferDefinitionData.dieUOM,
          dieSizeX,
          dieSizeY,
          dieStepSizeX,
          dieStepSizeY,
          reticleUOM: waferDefinitionData.reticleUOM,
          reticleSizeX: +waferDefinitionData.reticleSizeX,
          reticleSizeY: +waferDefinitionData.reticleSizeY,
          reticleOffsetX: +waferDefinitionData.reticleOffsetX,
          reticleOffsetY: +waferDefinitionData.reticleOffsetY,
          waferMaxCols: +waferDefinitionData.waferMaxCols,
          waferMaxRows: +waferDefinitionData.waferMaxRows,
          calculationMethod: waferDefinitionData.calculationMethod,
          standardReticle: waferDefinitionData.standardReticle,
          reticleReference: waferDefinitionData.reticleReference,
          waferRowOffset: +waferDefinitionData.waferRowOffset,
          waferColOffset: +waferDefinitionData.waferColOffset,
          exclusionType: waferDefinitionData.exclusionType,
          dieSpecType: waferDefinitionData.dieSpecType,
        });
      });
    }
  };

  onSaveReticleData = () => {
    const { waferDefinitionData } = this.state;
    const { onSaveReticleData } = this.props;
    const errors = this.validate(waferDefinitionData, true);
    if (Object.keys(errors).length !== 0) {
      this.setState({ formErrors: errors });
    } else {
      this.setState({ formErrors: errors }, () => {
        onSaveReticleData({
          version: +waferDefinitionData.version,
          reticleUOM: waferDefinitionData.reticleUOM,
          reticleSizeX: +waferDefinitionData.reticleSizeX,
          reticleSizeY: +waferDefinitionData.reticleSizeY,
          reticleOffsetX: +waferDefinitionData.reticleOffsetX,
          reticleOffsetY: +waferDefinitionData.reticleOffsetY,
          standardReticle: waferDefinitionData.standardReticle,
          reticleReference: waferDefinitionData.reticleReference,
        });
      });
    }
  };

  onChangeWaferDefintionData = (key: string, value: any) => {
    this.setState((prevState: WaferDefinitionState) => {
      const newState = {
        waferDefinitionData: prevState.waferDefinitionData,
        showReticleSitePopover: prevState.showReticleSitePopover,
      };
      if (key in prevState.waferDefinitionData) {
        const newWaferDefinitionData = _.cloneDeep(prevState.waferDefinitionData);
        if (
          key === 'waferDiameter'
          && ((prevState.waferDefinitionData.waferDiameter >= NOTCH_FLAT_TOGGLE_DIAMETER_VALUE && value < NOTCH_FLAT_TOGGLE_DIAMETER_VALUE)
          || (prevState.waferDefinitionData.waferDiameter < NOTCH_FLAT_TOGGLE_DIAMETER_VALUE && value >= NOTCH_FLAT_TOGGLE_DIAMETER_VALUE))) {
          newWaferDefinitionData.exclusionType = ExclusionType.NONE;
        }
        newWaferDefinitionData[key] = value;
        newState.waferDefinitionData = newWaferDefinitionData;
        if (key === 'standardReticle' || (key === 'calculationMethod' && value !== CalculationMethod.RETICLE)) {
          newState.showReticleSitePopover = false;
        }
        return newState;
      }
      return newState;
    });
  };

  getReticleButtonVariant = (standardReticle: StandardReticle, reticleSizeX: number, reticleSizeY: number) => {
    return (standardReticle.reticle.length > 0
      && standardReticle.reticle.length === reticleSizeY
      && standardReticle.reticle[0].length === reticleSizeX) ? 'success' : 'warning';
  };

  isReticleChanged = (waferDefinitionData: WaferDefinitionData, waferDefinitionDataFromProps: WaferDefinitionData) => {
    return (
      waferDefinitionData.reticleSizeX !== waferDefinitionDataFromProps.reticleSizeX
      || waferDefinitionData.reticleSizeY !== waferDefinitionDataFromProps.reticleSizeY
      || waferDefinitionData.reticleUOM !== waferDefinitionDataFromProps.reticleUOM
      || waferDefinitionData.reticleOffsetX !== waferDefinitionDataFromProps.reticleOffsetX
      || waferDefinitionData.reticleOffsetY !== waferDefinitionDataFromProps.reticleOffsetY
      || waferDefinitionData.reticleReference !== waferDefinitionDataFromProps.reticleReference
      || !_.isEqual(waferDefinitionData.standardReticle, waferDefinitionDataFromProps.standardReticle)
    );
  };

  onChangeExclusionType = (value: any) => {
    this.setState((prevState: WaferDefinitionState) => {
      const newWaferDefinitionData = _.cloneDeep(prevState.waferDefinitionData);
      const newExclusionType = ExclusionType[value as unknown as ExclusionType] as unknown as ExclusionType;
      switch (newExclusionType) {
        case ExclusionType.FLAT_KEEP_OUT:
          newWaferDefinitionData.waferNotchKeepOut = 0;
          newWaferDefinitionData.waferScribeLine = 0;
          newWaferDefinitionData.waferBaseFlat = 0;
          break;
        case ExclusionType.NOTCH_KEEP_OUT:
          newWaferDefinitionData.waferFlatKeepOut = 0;
          newWaferDefinitionData.waferScribeLine = 0;
          newWaferDefinitionData.waferBaseFlat = 0;
          break;
        case ExclusionType.SCRIBE_LINE_AND_BASE_FLAT:
          newWaferDefinitionData.waferFlatKeepOut = 0;
          newWaferDefinitionData.waferNotchKeepOut = 0;
          break;
        case ExclusionType.NONE:
          newWaferDefinitionData.waferFlatKeepOut = 0;
          newWaferDefinitionData.waferNotchKeepOut = 0;
          newWaferDefinitionData.waferScribeLine = 0;
          newWaferDefinitionData.waferBaseFlat = 0;
          break;
        default:
          break;
      }
      newWaferDefinitionData.exclusionType = newExclusionType;
      return {
        waferDefinitionData: newWaferDefinitionData,
      };
    });
  };

  render() {
    const {
      waferDefinitionData, formErrors, showReticleSitePopover,
    } = this.state;
    const { waferDefinitionData: waferDefinitionDataFromProps } = this.props;

    const exclusionOptionsList: string[][] = [[ExclusionType[ExclusionType.NONE], 'None'], [ExclusionType[ExclusionType.SCRIBE_LINE_AND_BASE_FLAT], 'Scribe Line and Base Flat']];
    let exclusionDefault = ExclusionType[ExclusionType.NOTCH_KEEP_OUT];
    if (!UtilityFunctions.isWaferFlat(UtilityFunctions.toBaseUnit({ value: +waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true }))) {
      exclusionOptionsList.push([ExclusionType[ExclusionType.NOTCH_KEEP_OUT], 'Notch Keep Out']);
    } else {
      exclusionDefault = ExclusionType[ExclusionType.FLAT_KEEP_OUT];
      exclusionOptionsList.push([ExclusionType[ExclusionType.FLAT_KEEP_OUT], 'Flat Keep Out']);
    }

    let keepOutFields = null;
    switch (waferDefinitionData.exclusionType) {
      case ExclusionType.FLAT_KEEP_OUT:
        keepOutFields = (
          <Row>
            <Col>
              <Label
                labelTitle="Flat Keep Out"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                errorSize="100"
                required
                error={formErrors.waferFlatKeepOut}
                isFieldTouched
                className="mb5"
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.waferFlatKeepOut,
                    true,
                  )}`}
                  type="number"
                  name="waferFlatKeepOut"
                  value={waferDefinitionData.waferFlatKeepOut}
                  placeholder="Wafer Flat Keepout"
                  onChange={(e: any) => {
                    if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferFlatKeepOut', e.target.value);
                  }}
                />
              </Label>
            </Col>
            <Col>
              <Label
                labelTitle="UOM"
                labelPosition="left-middle"
                labelSize="50"
                required
                fieldSize="50"
                errorSize="100"
                error={formErrors.waferFlatKeepOutUOM}
                isFieldTouched
                className="mb5"
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={UOMs}
                  selectedValue={waferDefinitionData.waferFlatKeepOutUOM || UOMs[0][0]}
                  onChange={(value: any) => { this.onChangeWaferDefintionData('waferFlatKeepOutUOM', value); }}
                />
              </Label>
            </Col>
          </Row>
        );
        break;
      case ExclusionType.NOTCH_KEEP_OUT:
        keepOutFields = (
          <Row>
            <Col>
              <Label
                labelTitle="Notch Keep Out"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                errorSize="100"
                required
                error={formErrors.waferNotchKeepOut}
                isFieldTouched
                className="mb5"
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.waferNotchKeepOut,
                    true,
                  )}`}
                  type="number"
                  name="waferNotchKeepOut"
                  value={waferDefinitionData.waferNotchKeepOut}
                  placeholder="Wafer Notch Keepout"
                  onChange={(e: any) => {
                    if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferNotchKeepOut', e.target.value);
                  }}
                />
              </Label>
            </Col>
            <Col>
              <Label
                labelTitle="UOM"
                labelPosition="left-middle"
                labelSize="50"
                required
                fieldSize="50"
                errorSize="100"
                error={formErrors.waferNotchKeepOutUOM}
                isFieldTouched
                className="mb5"
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={UOMs}
                  selectedValue={waferDefinitionData.waferNotchKeepOutUOM || UOMs[0][0]}
                  onChange={(value: any) => { this.onChangeWaferDefintionData('waferNotchKeepOutUOM', value); }}
                />
              </Label>
            </Col>
          </Row>
        );
        break;
      case ExclusionType.SCRIBE_LINE_AND_BASE_FLAT:
        keepOutFields = (
          <>
            <Row>
              <Col>
                <Label
                  labelTitle="Scribe Line"
                  labelPosition="left-middle"
                  labelSize="50"
                  fieldSize="50"
                  errorSize="100"
                  required
                  error={formErrors.waferScribeLine}
                  isFieldTouched
                  className="mb5"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.waferScribeLine,
                      true,
                    )}`}
                    type="number"
                    name="waferScribeLine"
                    value={waferDefinitionData.waferScribeLine}
                    placeholder="Wafer Scribe Line"
                    onChange={(e: any) => {
                      if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferScribeLine', e.target.value);
                    }}
                  />
                </Label>
              </Col>
              <Col>
                <Label
                  labelTitle="UOM"
                  labelPosition="left-middle"
                  labelSize="50"
                  required
                  fieldSize="50"
                  errorSize="100"
                  error={formErrors.waferScribeLineUOM}
                  isFieldTouched
                  className="mb5"
                >
                  <CustomizedDropdown
                    variant="clear"
                    full
                    list={UOMs}
                    selectedValue={waferDefinitionData.waferScribeLineUOM || UOMs[0][0]}
                    onChange={(value: any) => { this.onChangeWaferDefintionData('waferScribeLineUOM', value); }}
                  />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label
                  labelTitle="Base Flat"
                  labelPosition="left-middle"
                  labelSize="50"
                  fieldSize="50"
                  errorSize="100"
                  required
                  error={formErrors.waferBaseFlat}
                  isFieldTouched
                  className="mb5"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.waferBaseFlat,
                      true,
                    )}`}
                    type="number"
                    name="waferBaseFlat"
                    value={waferDefinitionData.waferBaseFlat}
                    placeholder="Wafer Base Flat"
                    onChange={(e: any) => {
                      if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferBaseFlat', e.target.value);
                    }}
                  />
                </Label>
              </Col>
              <Col>
                <Label
                  labelTitle="UOM"
                  labelPosition="left-middle"
                  labelSize="50"
                  required
                  fieldSize="50"
                  errorSize="100"
                  error={formErrors.waferBaseFlatUOM}
                  isFieldTouched
                  className="mb5"
                >
                  <CustomizedDropdown
                    variant="clear"
                    full
                    list={UOMs}
                    selectedValue={waferDefinitionData.waferBaseFlatUOM || UOMs[0][0]}
                    onChange={(value: any) => { this.onChangeWaferDefintionData('waferBaseFlatUOM', value); }}
                  />
                </Label>
              </Col>
            </Row>
          </>
        );
        break;
      default: keepOutFields = null;
        break;
    }

    return (
      <div className="custom-form mt10" style={{ fontSize: '10px' }}>
        <ScrollView
          height="85vh"
          showScrollbar="onHover"
          scrollByThumb
          reachBottomText=""
        >
          <Heading size={5}>Wafer</Heading>
          <Row>
            <Col>
              <Label
                labelTitle="Diameter"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                required
                errorSize="100"
                error={formErrors.waferDiameter}
                isFieldTouched
                className="mb5"
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={DIAMETERS(waferDefinitionData.waferDiameterUOM)}
                  selectedValue={waferDefinitionData.waferDiameter.toString() || DIAMETERS(waferDefinitionData.waferDiameterUOM)[0][0]}
                  onChange={(value: any) => { this.onChangeWaferDefintionData('waferDiameter', +value); }}
                />
              </Label>
            </Col>
            <Col>
              <Label
                labelTitle="UOM"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                required
                errorSize="100"
                error={formErrors.waferDiameterUOM}
                isFieldTouched
                className="mb5"
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={UOMs}
                  selectedValue={waferDefinitionData.waferDiameterUOM || UOMs[0][0]}
                  onChange={(value: any) => { this.onChangeWaferDefintionData('waferDiameterUOM', value); }}
                />
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                labelTitle="Edge Exclusion"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                errorSize="100"
                required
                error={formErrors.waferEdgeExclusion}
                isFieldTouched
                className="mb5"
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.waferEdgeExclusion,
                    true,
                  )}`}
                  type="number"
                  name="waferEdgeExclusion"
                  value={waferDefinitionData.waferEdgeExclusion}
                  placeholder="Edge Exclusion"
                  onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferEdgeExclusion', e.target.value); }}
                />
              </Label>
            </Col>
            <Col>
              <Label
                labelTitle="UOM"
                labelPosition="left-middle"
                labelSize="50"
                fieldSize="50"
                errorSize="100"
                error={formErrors.waferEdgeExclusionUOM}
                isFieldTouched
                className="mb5"
                required
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={UOMs}
                  selectedValue={waferDefinitionData.waferEdgeExclusionUOM || UOMs[0][0]}
                  onChange={(value: any) => { this.onChangeWaferDefintionData('waferEdgeExclusionUOM', value); }}
                />
              </Label>
            </Col>
          </Row>
          <div>
            <Label
              labelTitle="Exclusion Options"
              labelPosition="left-middle"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                list={exclusionOptionsList}
                selectedValue={ExclusionType[waferDefinitionData.exclusionType] || exclusionDefault}
                onChange={this.onChangeExclusionType}
              />
            </Label>
            {keepOutFields}
          </div>
          <Label
            labelTitle="Input Notch Position"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required
            errorSize="100"
            error={formErrors.waferInputNotchPosition}
            isFieldTouched
            className="mb5 mt10"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={NOTCH_POSITIONS}
              selectedValue={waferDefinitionData.waferInputNotchPosition || NOTCH_POSITIONS[0][0]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('waferInputNotchPosition', value); }}
            />
          </Label>
          <Label
            labelTitle="Output Notch Position"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required
            errorSize="100"
            error={formErrors.waferOutputNotchPosition}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={NOTCH_POSITIONS}
              selectedValue={waferDefinitionData.waferOutputNotchPosition || NOTCH_POSITIONS[0][0]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('waferOutputNotchPosition', value); }}
            />
          </Label>
          <Label
            labelTitle="Origin Location"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required
            errorSize="100"
            error={formErrors.waferOriginLocation}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={WAFER_ORIGIN_LOCATION.map((x) => { return [WaferOriginLocation[x.originLocation], x.label]; })}
              selectedValue={WaferOriginLocation[waferDefinitionData.waferOriginLocation] || WaferOriginLocation[WaferOriginLocation.UPPER_LEFT]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('waferOriginLocation', WaferOriginLocation[value as unknown as WaferOriginLocation] as unknown as WaferOriginLocation); }}
            />
          </Label>
          <Label
            labelTitle="Axis Direction"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required
            errorSize="100"
            error={formErrors.waferAxisDirection}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={WAFER_AXIS_DIRECTION.map((x) => { return [WaferAxisDirection[x.axisDirection], x.label]; })}
              selectedValue={WaferAxisDirection[waferDefinitionData.waferAxisDirection] || WaferAxisDirection[WaferAxisDirection.DOWN_RIGHT]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('waferAxisDirection', WaferAxisDirection[value as unknown as WaferAxisDirection] as unknown as WaferAxisDirection); }}
            />
          </Label>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            labelTitle="Offset"
          >
            <Label
              labelTitle="X"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              error={formErrors.waferColOffset}
              isFieldTouched
              required
              className="mr10"
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.waferColOffset,
                  true,
                )}`}
                type="number"
                name="waferColOffset"
                value={waferDefinitionData.waferColOffset}
                placeholder="X"
                onChange={(e: any) => { this.onChangeWaferDefintionData('waferColOffset', e.target.value); }}
              />
            </Label>
            <Label
              labelTitle="Y"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              error={formErrors.waferRowOffset}
              isFieldTouched
              required
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.waferRowOffset,
                  true,
                )}`}
                type="number"
                name="waferRowOffset"
                value={waferDefinitionData.waferRowOffset}
                placeholder="Y"
                onChange={(e: any) => { this.onChangeWaferDefintionData('waferRowOffset', e.target.value); }}
              />
            </Label>
          </Label>
          <Label
            labelTitle="PGDW"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            error={formErrors.waferPGDW}
            isFieldTouched
            className="mb5"
          >
            <Textbox
              autoComplete="off"
              className={`${addErrorClasses(
                formErrors.waferPGDW,
                true,
              )}`}
              type="number"
              name="waferPGDW"
              value={waferDefinitionData.waferPGDW}
              placeholder="Wafer PGDW"
              onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferPGDW', e.target.value); }}
            />
          </Label>
          <hr className="mt10 mb10" />
          <Heading size={5}>Die</Heading>
          <Label
            labelTitle="UOM"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required
            errorSize="100"
            error={formErrors.dieUOM}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={UOMs}
              selectedValue={waferDefinitionData.dieUOM || UOMs[0][0]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('dieUOM', value); }}
            />
          </Label>
          <Label
            labelTitle=""
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={[[DieSpecType[DieSpecType.DIE], 'Use die size only'], [DieSpecType[DieSpecType.STEP], 'Use step size only'], [DieSpecType[DieSpecType.BOTH], 'Use both die and step size']]}
              selectedValue={DieSpecType[waferDefinitionData.dieSpecType] || DieSpecType[DieSpecType.DIE]}
              onChange={(value: string) => { this.onChangeWaferDefintionData('dieSpecType', DieSpecType[value as unknown as DieSpecType] as unknown as DieSpecType); }}
            />
          </Label>
          <div>
            { waferDefinitionData.dieSpecType === DieSpecType.BOTH || waferDefinitionData.dieSpecType === DieSpecType.DIE ? (
              <Label
                childrenItemsInline
                labelPosition="left-middle"
                labelSize="40"
                fieldSize="60"
                errorSize="100"
                labelTitle="Die Size"
              >
                <Label
                  labelTitle="Width"
                  labelPosition="top"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  error={formErrors.dieSizeX}
                  isFieldTouched
                  required
                  className="mr10"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.dieSizeX,
                      true,
                    )}`}
                    type="number"
                    name="dieSizeX"
                    value={waferDefinitionData.dieSizeX}
                    placeholder="Width"
                    onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('dieSizeX', e.target.value); }}
                  />
                </Label>
                <Label
                  labelTitle="Height"
                  labelPosition="top"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  error={formErrors.dieSizeY}
                  isFieldTouched
                  required
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.dieSizeY,
                      true,
                    )}`}
                    type="number"
                    name="dieSizeY"
                    value={waferDefinitionData.dieSizeY}
                    placeholder="Height"
                    onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('dieSizeY', e.target.value); }}
                  />
                </Label>
              </Label>
            ) : null }
          </div>
          <div>
            { waferDefinitionData.dieSpecType === DieSpecType.BOTH || waferDefinitionData.dieSpecType === DieSpecType.STEP ? (
              <Label
                childrenItemsInline
                labelPosition="left-middle"
                labelSize="40"
                fieldSize="60"
                errorSize="100"
                labelTitle="Step Size"
              >
                <Label
                  labelTitle="Width"
                  labelPosition="top"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  error={formErrors.dieStepSizeX}
                  isFieldTouched
                  className="mr10"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.dieStepSizeX,
                      true,
                    )}`}
                    type="number"
                    name="dieStepSizeX"
                    value={waferDefinitionData.dieStepSizeX}
                    placeholder="Width"
                    onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('dieStepSizeX', e.target.value); }}
                  />
                </Label>
                <Label
                  labelTitle="Height"
                  labelPosition="top"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  error={formErrors.dieStepSizeY}
                  isFieldTouched
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.dieStepSizeY,
                      true,
                    )}`}
                    type="number"
                    name="dieStepSizeY"
                    value={waferDefinitionData.dieStepSizeY}
                    placeholder="Height"
                    onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('dieStepSizeY', e.target.value); }}
                  />
                </Label>
              </Label>
            ) : null }
          </div>
          <hr className="mt10 mb10" />
          <Heading size={5}>Reticle</Heading>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            labelTitle="Reticle Dimensions"
          >
            <Label
              labelTitle="Cols"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              error={formErrors.reticleSizeX}
              isFieldTouched
              required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
              className="mr10"
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.reticleSizeX,
                  true,
                )}`}
                type="number"
                name="reticleSizeX"
                value={waferDefinitionData.reticleSizeX}
                placeholder="X"
                onChange={(e: any) => {
                  if (e.target.value === '' || +e.target.value > 0) this.onChangeWaferDefintionData('reticleSizeX', e.target.value);
                }}
              />
            </Label>
            <Label
              labelTitle="Rows"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              error={formErrors.reticleSizeY}
              required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.reticleSizeY,
                  true,
                )}`}
                type="number"
                name="reticleSizeY"
                value={waferDefinitionData.reticleSizeY}
                placeholder="Y"
                onChange={(e: any) => {
                  if (e.target.value === '' || +e.target.value > 0) this.onChangeWaferDefintionData('reticleSizeY', e.target.value);
                }}
              />
            </Label>
          </Label>
          <Label
            labelTitle="UOM"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            error={formErrors.reticleUOM}
            isFieldTouched
            required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={UOMs}
              selectedValue={waferDefinitionData.reticleUOM || UOMs[0][0]}
              onChange={(value: any) => { this.onChangeWaferDefintionData('reticleUOM', value); }}
            />
          </Label>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            labelTitle="Reticle Offset"
          >
            <Label
              labelTitle="X"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              error={formErrors.reticleOffsetX}
              required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
              isFieldTouched
              className="mr10"
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.reticleOffsetX,
                  true,
                )}`}
                type="number"
                name="reticleOffsetX"
                value={waferDefinitionData.reticleOffsetX}
                placeholder="X"
                onChange={(e: any) => { this.onChangeWaferDefintionData('reticleOffsetX', e.target.value); }}
              />
            </Label>
            <Label
              labelTitle="Y"
              labelPosition="top"
              labelSize="20"
              fieldSize="80"
              errorSize="100"
              required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
              error={formErrors.reticleOffsetY}
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.reticleOffsetY,
                  true,
                )}`}
                type="number"
                name="reticleOffsetY"
                value={waferDefinitionData.reticleOffsetY}
                placeholder="Y"
                onChange={(e: any) => { this.onChangeWaferDefintionData('reticleOffsetY', e.target.value); }}
              />
            </Label>
          </Label>
          <Label
            labelTitle="Reticle Reference"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            required={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
            errorSize="100"
            error={formErrors.reticleReference}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={reticleReferences}
              selectedValue={waferDefinitionData.reticleReference.toString() || reticleReferences[0][0]}
              onChange={(value: string) => { this.onChangeWaferDefintionData('reticleReference', value); }}
            />
          </Label>
          <OverlayTrigger
            trigger="focus"
            show={showReticleSitePopover}
            placement="auto"
            overlay={(
              <Popover
                id="wcm-reticle-site-popover"
                show={showReticleSitePopover}
                tabIndex={-1}
                style={{ maxWidth: '1200px' }}
              >
                <Popover.Title as="h3" className="pr0">
                  Reticle Editor (Standard)
                  <Button
                    variant="outline float-right p0 pl5 pr10 text-danger dx-icon-close"
                    onClick={() => { this.setState({ showReticleSitePopover: false }); }}
                  >
                    <i />
                  </Button>
                </Popover.Title>
                <Popover.Content>
                  <StandardReticleEditor
                    onSaveStandardReticle={(standardReticle: StandardReticle) => {
                      this.onChangeWaferDefintionData('standardReticle', _.cloneDeep(standardReticle));
                    }}
                    standardReticle={waferDefinitionData.standardReticle}
                    reticleSizeX={+waferDefinitionData.reticleSizeX}
                    reticleSizeY={+waferDefinitionData.reticleSizeY}
                  />
                </Popover.Content>
              </Popover>
            )}
          >
            <Button
              variant={this.getReticleButtonVariant(waferDefinitionData.standardReticle, +waferDefinitionData.reticleSizeX, +waferDefinitionData.reticleSizeY)}
              className="mb10 mt10"
              size="sm"
              onClick={() => {
                const errors = this.validateReticleSize(waferDefinitionData.reticleSizeX, waferDefinitionData.reticleSizeY);
                if (Object.keys(errors).length !== 0) {
                  this.setState({ formErrors: errors });
                } else {
                  this.setState({ formErrors: errors }, () => {
                    this.setState({ showReticleSitePopover: true });
                  });
                }
              }}
            >
              <FontAwesomeIcon
                icon={faCrosshairs}
                className="mr5"
              />
              Generate and Edit Reticle Site
            </Button>
          </OverlayTrigger>
          <span>
            { this.isReticleChanged(waferDefinitionData, waferDefinitionDataFromProps) && waferDefinitionData.version !== 1 ? (
              <Button
                onClick={this.onSaveReticleData}
                size="sm"
                variant="primary"
                className="ml10"
              >
                Apply Changes
              </Button>
            ) : null}
          </span>
          <br />
          <ErrorLabel
            error={formErrors.standardReticle}
            isTouched
          />
          <hr className="mt10 mb10" />
          <Heading size={5}>Draw Method</Heading>
          <div className="mt15">
            <ToggleButton
              style={{ boxShadow: '0 0', color: 'black' }}
              variant="clear"
              type="radio"
              className="mr15"
              checked={waferDefinitionData.calculationMethod === CalculationMethod.AUTO}
              value={waferDefinitionData.calculationMethod}
              onChange={() => { this.onChangeWaferDefintionData('calculationMethod', CalculationMethod.AUTO); }}
            >
              <span className="ml10">Auto Calculate Wafer Rows and Cols</span>
            </ToggleButton>
          </div>
          <p style={{ textAlign: 'center' }} className="mt10">--- Or ---</p>
          <div>
            <ToggleButton
              style={{ boxShadow: '0 0', color: 'black' }}
              variant="clear"
              type="radio"
              className="mr15"
              checked={waferDefinitionData.calculationMethod === CalculationMethod.ROWS_COLS}
              value={waferDefinitionData.calculationMethod}
              onChange={() => { this.onChangeWaferDefintionData('calculationMethod', CalculationMethod.ROWS_COLS); }}
            >
              <span className="ml10"> Define Wafer With Fixed Rows / Cols</span>
            </ToggleButton>
            <Label
              childrenItemsInline
              labelPosition="left-middle"
              labelSize="0"
              fieldSize="100"
              errorSize="100"
              labelTitle=""
              className="mt10"
            >
              <Label
                labelTitle="Rows"
                labelPosition="top"
                labelSize="20"
                fieldSize="80"
                errorSize="100"
                error={formErrors.waferMaxRows}
                isFieldTouched
                className="mr10"
                required={waferDefinitionData.calculationMethod === CalculationMethod.ROWS_COLS}
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.waferMaxRows,
                    true,
                  )}`}
                  type="number"
                  name="waferMaxRows"
                  value={waferDefinitionData.waferMaxRows}
                  placeholder=""
                  onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferMaxRows', e.target.value); }}
                />
              </Label>
              <Label
                labelTitle="Cols"
                labelPosition="top"
                labelSize="20"
                fieldSize="80"
                errorSize="100"
                error={formErrors.waferMaxCols}
                isFieldTouched
                required={waferDefinitionData.calculationMethod === CalculationMethod.ROWS_COLS}
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.waferMaxCols,
                    true,
                  )}`}
                  type="number"
                  name="waferMaxCols"
                  value={waferDefinitionData.waferMaxCols}
                  placeholder=""
                  onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) this.onChangeWaferDefintionData('waferMaxCols', e.target.value); }}
                />
              </Label>
            </Label>
          </div>
          <p style={{ textAlign: 'center' }} className="mt10">--- Or ---</p>
          <div className="mt10">
            <ToggleButton
              style={{ boxShadow: '0 0', color: 'black' }}
              variant="clear"
              type="radio"
              className="mr15"
              checked={waferDefinitionData.calculationMethod === CalculationMethod.RETICLE}
              value={waferDefinitionData.calculationMethod}
              onChange={() => { this.onChangeWaferDefintionData('calculationMethod', CalculationMethod.RETICLE); }}
            >
              <span className="ml10">Define With Reticle</span>
            </ToggleButton>
          </div>
          <Button
            onClick={this.onSaveWaferDefinitionData}
            size="sm"
            variant="primary"
            className="mt10 mb30"
          >
            Define
          </Button>
        </ScrollView>
      </div>
    );
  }
}
