/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpLegend } from 'services/http.legend';
import { InteractionInputDTO, ICustomizedLegendData } from '../CustomizedReportInterfaces';
import { ILegendDataHelper } from '../CustomizedReportHelper';

export class BinWaferMapComparisonLegendDataHelper implements ILegendDataHelper {
  private legendData : any = undefined;

  preferences = {
    summary: {
      calculateCustomSummary: (options:any) => {
        if (this.legendData.legendSummary.length > 0 && this.legendData.legendSummary[0][0][options.name]) {
          options.totalValue = this.legendData.legendSummary[0][0][options.name];
        }
      },
      totalItems: [
        {
          column: 'Total Die Count',
          name: 'Total Die Count',
          summaryType: 'custom',
          displayFormat: 'Total Die Count: {0}',
          showInColumn: 'Die Count',
          showInGroupFooter: true,
        },
      ],
    },
    legendKeyExpression: 'die_soft_bin_number',
    selectionEventDefaults: {
      xAxisLabel: 'BIN_NUMBER',
      yAxisLabel: 'DIE_COUNT',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      groupKey: 'wafer_key',
      entityType: ('WAFER' as EntityType),
      columnName: 'key',
    },
    postSelectionInYAxis: false,
    equalNumberOfYsForX: false,
  };

  setLegendData = (data: ICustomizedLegendData) => {
    this.legendData = data;
  };

  getPreferences = () => (this.preferences);

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpLegend.setInteractionData(selectionEventInput);
  };

  getDetailedGraphLegendData = async (requestObject: IGenericDetailedReportRequestObject, callback: (legendData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    if (requestObject.isSoftBin) {
      this.preferences.legendKeyExpression = 'die_soft_bin_number';
    } else {
      this.preferences.legendKeyExpression = 'die_hard_bin_number';
    }
    data.legend.forEach((legendData:any) => {
      legendData.forEach((legendRow:any) => {
        legendRow.hidden = false;
      });
    });
    this.setLegendData(data);
    callback(data);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };
}
