import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, {
  Column, Grouping, GroupPanel, Summary, GroupItem,
} from 'devextreme-react/data-grid';
import { ColumnChooser } from 'devextreme-react/tree-list';
import Heading from '../../wrapped-component/hint-controls/Heading';

interface IPolicySummaryStatsOverViewProps {
  height?: number
  policyResult?: any;
  selectedStageId?: string;
  showGrid: any [];
}

interface IPolicySummaryStatsOverViewStats {
  showSummaryStats: boolean
}

class PolicySummaryStatsOverView extends Component<IPolicySummaryStatsOverViewProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showSummaryStats: false,
    };
  }

  componentDidMount() {
    const { policyResult } = this.props;
    if (policyResult.type === 'PAT') {
      this.checkShowGrid();
    }
  }

  generateDataSource = (policyResult: any) => {
    const { selectedStageId } = this.props;
    const policyResultSummary: any[] = [];
    if (policyResult.policyStepId === selectedStageId) {
      policyResult.ruleSetResult.forEach((ruleSetResultObj: any) => {
        ruleSetResultObj.ruleResult.forEach((ruleResultObj: any) => {
          ruleResultObj.testParameterResult.forEach((testParameterResultObj: any) => {
            Object.keys(testParameterResultObj.siteWiseLimits)
              .forEach((site) => {
                policyResultSummary.push({
                  policyStageType: policyResult.type,
                  ruleName: ruleResultObj.rule.name,
                  testParameterNumber: testParameterResultObj.testParameter.number,
                  testParameterName: testParameterResultObj.testParameter.name,
                  siteNumber: site,
                  highLimit: testParameterResultObj.siteWiseLimits[site][testParameterResultObj.inputWaferId].highLimit,
                  lowLimit: testParameterResultObj.siteWiseLimits[site][testParameterResultObj.inputWaferId].lowLimit,
                  totalDies: testParameterResultObj.totalDieCount,
                  inputDies: testParameterResultObj.inputDieCount,
                  dieChangeCount: testParameterResultObj.dieChangeCount,
                  outputDies: testParameterResultObj.outputDieCount,
                  yieldLoss: `${testParameterResultObj.yieldLoss}%`,
                  waferKey: testParameterResultObj.waferKey,
                });
              });
          });
        });
      });
    }
    return policyResultSummary;
  };

  checkShowGrid = () => {
    const {
      showGrid,
      policyResult
    } = this.props;
    if (showGrid.includes('PATGrids') && policyResult.type === 'PAT') {
      this.setState({ showSummaryStats: true });
    }
  }

  render() {
    const {
      height,
      policyResult
    } = this.props;
    const { showSummaryStats } = this.state;
    return (
      showSummaryStats && (
        <div>
          <Row>
            <Col lg={12}>
              <Heading size={6}> Policy Summary Stats Overview</Heading>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="mt10">
                <DataGrid
                  height={height}
                  showBorders
                  dataSource={Object.keys(policyResult).length > 0 ? this.generateDataSource(policyResult) : []}
                  allowColumnReordering
                >
                  <GroupPanel visible />
                  <Grouping autoExpandAll />

                  <Column alignment="left" caption="Policy Stage" dataField="policyStageType" groupIndex={0} />
                  <Column alignment="left" caption="Rule Name" dataField="ruleName" />
                  <Column alignment="left" caption="Test Parameter Number" dataField="testParameterNumber" />
                  <Column alignment="left" caption="Test Parameter Name" dataField="testParameterName" />
                  <Column alignment="left" caption="Wafer ID" dataField="waferKey" />
                  <Column alignment="left" caption="Site Number" dataField="siteNumber" />
                  <Column alignment="left" caption="High Limit" dataField="highLimit" />
                  <Column alignment="left" caption="Low Limit" dataField="lowLimit" />
                  <Column alignment="left" caption="Total Dies" dataField="totalDies" />
                  <Column alignment="left" caption="Input Dies" dataField="inputDies" />
                  <Column alignment="left" caption="Die Change Count" dataField="dieChangeCount" />
                  <Column alignment="left" caption="Output Dies" dataField="outputDies" />
                  <Column alignment="left" caption="Yield Loss (%)" dataField="yieldLoss" />
                  <ColumnChooser
                    mode="column"
                    enabled
                    title="Grid Column Chooser"
                  />
                  <Summary>
                    <GroupItem
                      column="dieChangeCount"
                      summaryType="sum"
                      displayFormat="Total: {0}"
                      showInGroupFooter
                    />

                  </Summary>
                </DataGrid>
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export default PolicySummaryStatsOverView;
