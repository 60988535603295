import { toast as originalToast } from 'react-toastify';

type ToastType = 'SUCCESS' | 'INFO' | 'WARN' | 'WARNING' | 'ERROR';

let toastCount = 0;

class Toast {
  incrementCount = () => {
    toastCount += 1;
    this.handleCloseAllButtonVisibility();
  };

  decrementCount = () => {
    toastCount -= 1;
    this.handleCloseAllButtonVisibility();
  };

  getToastOptions = (typeOfToast: ToastType) => {
    let toastOptions = {};
    if (typeOfToast === 'SUCCESS' || typeOfToast === 'INFO') {
      toastOptions = {
        autoClose: 3500,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: true,
        onOpen: this.incrementCount,
        onClose: this.decrementCount,
      };
    } else if (typeOfToast === 'WARN' || typeOfToast === 'ERROR' || typeOfToast === 'WARNING') {
      toastOptions = {
        autoClose: false,
        closeOnClick: true,
        closeButton: true,
        onOpen: this.incrementCount,
        onClose: this.decrementCount,
      };
    }
    return toastOptions;
  };

  success = (message: any) => {
    originalToast.success(message, this.getToastOptions('SUCCESS'));
  };

  info = (message: any) => {
    originalToast.info(message, this.getToastOptions('INFO'));
  };

  warning = (message: any) => {
    originalToast.warning(message, this.getToastOptions('WARNING'));
  };

  warn = (message: any) => {
    originalToast.warn(message, this.getToastOptions('WARN'));
  };

  error = (message: any, error?: any) => {
    if (error) {
      originalToast.error(message, error);
    } else {
      originalToast.error(message, this.getToastOptions('ERROR'));
    }
  };

  handleCloseAllButtonVisibility = () => {
    setTimeout(() => {
      const closeAllToastButton = document.getElementById('close-all-toast-container');
      if (closeAllToastButton !== null) {
        if (toastCount > 0) {
          closeAllToastButton.style.display = 'block';
        } else {
          closeAllToastButton.style.display = 'none';
        }
      }
    }, 2000);
  };
}

const toast = new Toast();
export default toast;
