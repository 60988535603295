import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faCopy, faPencilAlt, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import AttributesGrid from './attributes-grid';

type DeviceSetupWorkCenterProps = {
  workCenterData: any,
  binPlusTables: any[],
  waferControlMaps: any[],
  onAddWorkCenterAttribute: (columnInfo: string, workCenterTypeId: string, callback: any) => void,
  onDeleteWorkCenterAttribute: (attributeId: string, workCenterTypeId: string, callback: () => void) => void,
  onDeleteWorkCenter: (id: string) => void,
  onCopyWorkCenter: (id: string) => void,
  onSaveWorkCenter: (workCenterData: any) => void,
  workCenterTypes: any[],
  workCenterAttributes: any[],
  workCenterAttributeDefinitions: any[],
  onAddAttributeDefinition: (data: any, dsWCId: string) => void,
  onUpdateAttributeDefinition: (data: any, dsWCId: string) => void,
  onDeleteAttributeDefinition: (id: string) => void,
  deviceSetupId: string | null,
  masterMetaColumns: { [entityType: string]: any[] }
};

type DeviceSetupWorkCenterState = {
  workCenterData: any,
  nameEditMode: boolean,
};

export default class DeviceSetupWorkCenter extends React.Component<DeviceSetupWorkCenterProps, DeviceSetupWorkCenterState> {
  WCNameRef: any = null;

  atrGrid: any = null;

  constructor(props: DeviceSetupWorkCenterProps) {
    super(props);
    const { workCenterData } = this.props;
    this.state = {
      workCenterData: _.cloneDeep(workCenterData),
      nameEditMode: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps: DeviceSetupWorkCenterProps) {
    const { workCenterData } = this.props;
    if (!_.isEqual(prevProps.workCenterData, workCenterData)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ workCenterData: _.cloneDeep(workCenterData) });
    }
  }

  onValueChanged = (cell: any, e: any) => {
    cell.setValue(e.value);
  };

  isInEditingMode = () => {
    const { nameEditMode } = this.state;
    if (nameEditMode) return true;
    return UtilityFunctions.isDataGridInEditMode(this.atrGrid);
  };

  render() {
    const { workCenterData, nameEditMode } = this.state;
    const {
      onDeleteAttributeDefinition, onUpdateAttributeDefinition, binPlusTables,
      onDeleteWorkCenter, workCenterAttributeDefinitions, onAddAttributeDefinition,
      onAddWorkCenterAttribute, workCenterTypes, onSaveWorkCenter, workCenterAttributes,
      onDeleteWorkCenterAttribute, deviceSetupId, onCopyWorkCenter, waferControlMaps, masterMetaColumns,
    } = this.props;
    return (
      <div>
        <div className="custom-form p10">
          <Row>
            <Col lg={3} style={{ display: 'flex' }}>
              { nameEditMode ? (
                <>
                  <Label
                    labelTitle=""
                    labelPosition="top"
                    labelSize="80"
                    fieldSize="20"
                    errorSize="20"
                    isFieldTouched
                  >
                    <Textbox
                      type="text"
                      setRef={(ref: any) => { this.WCNameRef = ref; }}
                      name="workCenterName"
                      autoComplete="off"
                      placeholder="Work Center Name"
                    />
                  </Label>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faCheck}
                    className="mt20 ml15 wc-name-control"
                    cursor="pointer"
                    onClick={() => {
                      let isUpdated = false;
                      this.setState((prevState: DeviceSetupWorkCenterState) => {
                        if (prevState.workCenterData.name !== this.WCNameRef.value) {
                          isUpdated = true;
                          return {
                            workCenterData: { ..._.cloneDeep(prevState.workCenterData), name: this.WCNameRef.value },
                            nameEditMode: false,
                          };
                        }
                        isUpdated = false;
                        return {
                          workCenterData: prevState.workCenterData,
                          nameEditMode: false,
                        };
                      }, () => {
                        const { workCenterData: updatedWorkCenterData } = this.state;
                        if (isUpdated) onSaveWorkCenter(updatedWorkCenterData);
                      });
                    }}
                  />
                </>
              )
                : (
                  <div className="mt20" style={{ display: 'flex' }}>
                    <h6>Work Center Name: </h6>
                    <span className="ml15">{workCenterData.name}</span>
                    <FontAwesomeIcon
                      size="sm"
                      icon={faPencilAlt}
                      className="mt3 ml15 wc-name-control"
                      cursor="pointer"
                      onClick={() => {
                        this.setState({
                          nameEditMode: true,
                        }, () => {
                          this.WCNameRef.value = workCenterData.name;
                        });
                      }}
                    />
                  </div>
                )}
            </Col>
            <Col lg={9}>
              <Button
                size="sm"
                variant="danger"
                className="mt10 float-right"
                onClick={() => { onDeleteWorkCenter(workCenterData.id); }}
              >
                <FontAwesomeIcon size="sm" icon={faTrash} className="mr6" />
                Delete
              </Button>
              <Button
                size="sm"
                variant="primary"
                className="mt10 float-right mr5"
                onClick={() => { onCopyWorkCenter(workCenterData.id); }}
              >
                <FontAwesomeIcon size="sm" icon={faCopy} className="mr6" />
                Copy
              </Button>
            </Col>
          </Row>
          <AttributesGrid
            parentId={deviceSetupId}
            onAddAttributeDefinition={(data: any) => { onAddAttributeDefinition(data, workCenterData.id); }}
            onUpdateAttributeDefinition={(data: any) => { onUpdateAttributeDefinition(data, workCenterData.id); }}
            onDeleteAttributeDefinition={(id: string) => { onDeleteAttributeDefinition(id); }}
            onDeleteAttribute={(id: string, callback: () => void) => { onDeleteWorkCenterAttribute(id, workCenterData.workCenterType.id, callback); }}
            workCenterTypes={workCenterTypes}
            overrideAttributeInWC
            binPlusTables={binPlusTables}
            waferControlMaps={waferControlMaps}
            attributes={workCenterAttributeDefinitions}
            allAttributes={workCenterAttributes}
            onAddAttribute={(columnInfo: any, callback: any) => { onAddWorkCenterAttribute(columnInfo, workCenterData.workCenterType.id, callback); }}
            passAtrGridRefToParent={(atrGrid: any) => { this.atrGrid = atrGrid; }}
            masterMetaColumns={masterMetaColumns}
          />
        </div>
      </div>
    );
  }
}
