/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

const setTickText = (id: string, waferMapInstance: WebGLUtils) => {
  const tickTextCanvas: HTMLCanvasElement = document.querySelector(`#${id}`) as HTMLCanvasElement;
  if (!tickTextCanvas) return;
  waferMapInstance.waferMapVariables.tickTextCtx = tickTextCanvas.getContext('2d');
  const { tickTextCanvasWidth, tickTextCanvasHeight, angleInDegrees } = waferMapInstance.waferMapVariables;
  tickTextCanvas.width = tickTextCanvasWidth;
  tickTextCanvas.height = tickTextCanvasHeight;
  waferMapInstance.waferMapVariables.tickTextCtx.translate(tickTextCanvasWidth / 2, tickTextCanvasHeight / 2);
  waferMapInstance.waferMapVariables.tickTextCtx.rotate(angleInDegrees * (Math.PI / 180));
  waferMapInstance.waferMapVariables.tickTextCtx.translate(-tickTextCanvasWidth / 2, -tickTextCanvasHeight / 2);
};

export default setTickText;
