import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TreeList, Selection, Column } from 'devextreme-react/tree-list';
import { CheckBox } from 'devextreme-react/check-box';
import Heading from '../../wrapped-component/hint-controls/Heading';
import { httpService } from '../../../services/http.service';
import { ServiceType } from '../../../interfaces';

interface IPolicyOverViewGridProps {
  policyId: string;
  onRowSelection?: (e: any, p: any) => void;
  height?: number;
  enableSelection?: boolean;
  showParentNodesOnly?: boolean;
  singleSelection?: boolean;
}

interface IPolicyOverViewGridState {
  policy: any;
  selectedData: any;
}

class PolicyOverViewGrid extends Component<
IPolicyOverViewGridProps,
IPolicyOverViewGridState
> {
  constructor(props: IPolicyOverViewGridProps) {
    super(props);
    this.state = {
      policy: {},
      selectedData: {},
    };
  }

  componentDidMount() {
    const { policyId } = this.props;
    if (policyId && policyId === '') return;
    httpService.getPolicy(policyId)
      .then((fetchedPolicy: any) => {
        const policy = this.policyDataGenerator(fetchedPolicy);
        this.setState({ policy });
      });
  }

  returnTreeNode = (
    id: any,
    parentId: any,
    stage: any,
    ruleSet: any,
    rule: any,
    stepType: string,
    stepId: any,
    ruleSetId: any,
    level: string,
  ) => {
    return {
      id,
      parentId,
      stage,
      ruleSet,
      rule,
      level,
      stepId,
      stepType,
      ruleSetId,
    };
  };

  policyDataGenerator = (policyObj: any) => {
    const { showParentNodesOnly } = this.props;
    const policyStepList: object[] = [];
    const { policySteps } = policyObj;

    // Adding Policy Stage in the tree
    policySteps.forEach((policyStep: any) => {
      // Adding PAT Rule Set in the tree
      policyStepList.push(
        this.returnTreeNode(
          policyStep.id,
          0,
          policyStep.type,
          null,
          null,
          policyStep.type,
          policyStep.id,
          null,
          'STEP',
        ),
      );

      // If Stage is "PAT"
      if (policyStep.type === ServiceType.PAT) {
        // Adding PAT Rule Set in the tree
        const { patRuleSet } = policyStep.stepSettings;
        patRuleSet.forEach((pRuleSet: any) => {
          policyStepList.push(this.returnTreeNode(pRuleSet.id, policyStep.id, null, `${pRuleSet.patRuleSetType}_${pRuleSet.patRuleSetName}`, null, policyStep.type, policyStep.id, null, 'RULE_SET'));
          // Adding PAT Rules in the tree
          const { patRules } = pRuleSet;
          patRules.forEach((patRule: any) => {
            policyStepList.push(
              this.returnTreeNode(
                patRule.id,
                pRuleSet.id,
                null,
                null,
                patRule.name,
                policyStep.type,
                policyStep.id,
                pRuleSet.id,
                'RULE',
              ),
            );
          });
        });
      }
      // if stage is SPC
      if (policyStep.type === ServiceType.SPC) {
        const { spcReports } = policyStep.stepSettings;
        spcReports.forEach((spcReport: any) => {
          spcReport.spcParameters.forEach((spcParameter: any) => {
            policyStepList.push(
              this.returnTreeNode(
                `${spcReport.id},${spcParameter.id}`,
                policyStep.id,
                null,
                `${spcReport.name} - Parameter ${spcParameter.serialNumber}`,
                null,
                policyStep.type,
                policyStep.id,
                null,
                'RULE_SET',
              ),
            );
            spcParameter.rulesApplied.forEach((spcRule: any) => {
              policyStepList.push(
                this.returnTreeNode(
                  `${spcParameter.id},${spcRule.id}`,
                  `${spcReport.id},${spcParameter.id}`,
                  null,
                  null,
                  `${spcRule.rule.name}`,
                  policyStep.type,
                  policyStep.id,
                  `${spcReport.id},${spcParameter.id}`,
                  'RULE',
                ),
              );
            });
          });
        });
      }
    });

    return {
      id: policyObj.id,
      name: policyObj.name,
      policyData: policyObj,
      policyStepList,
    };
  };

  onRowSelection = (selectedData: any) => {
    const { policy } = this.state;
    const {
      onRowSelection,
      showParentNodesOnly
    } = this.props;
    if (showParentNodesOnly) {
      const findStep = policy.policyStepList.find((x: any) => x.id === selectedData.selectedRowsData[0].stepId);
      selectedData.selectedRowsData[0] = findStep;
      selectedData.selectedRowKeys[0] = findStep.id;
      this.setState({ selectedData });
      if (onRowSelection) onRowSelection(selectedData, policy);
    } else {
      this.setState({ selectedData });
      if (onRowSelection) onRowSelection(selectedData, policy);
    }
  };

  renderCheckBox = (e: any) => {
    return (
      <div className="d-flex justify-content-center">
        <CheckBox defaultValue={false} />
      </div>
    );
  };

  render() {
    const {
      policy,
      selectedData
    } = this.state;
    const {
      height,
      enableSelection,
      singleSelection,
      showParentNodesOnly,
    } = this.props;
    return (
      <div>
        <Row>
          <Col lg={12} className="mb10">
            <Heading
              size={6}
            >
              Policy Name:
              <span className="ml6">{policy.name}</span>
            </Heading>
            Select the Stage to view the Policy Results
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <TreeList
              id="employees"
              autoExpandAll={false}
              height={height}
              dataSource={policy.policyStepList}
              showRowLines
              showBorders
              columnAutoWidth
              selectedRowKeys={selectedData.selectedRowKeys}
              keyExpr="id"
              parentIdExpr="parentId"
              onSelectionChanged={this.onRowSelection}
            >
              {enableSelection && <Selection allowSelectAll={!singleSelection} recursive={!singleSelection} mode={singleSelection ? 'single' : 'multiple'} />}
              <Column caption="Stages" dataField="stage" dataType="string" />
              <Column
                caption="Rule Set"
                dataField="ruleSet"
                dataType="string"
              />
              <Column
                caption="Rule"
                dataField="rule"
                dataType="string"
              />
              <Column
                visible={false}
                width={120}
                caption="Show Report"
                dataField="visible"
                cellRender={this.renderCheckBox}
              />
            </TreeList>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PolicyOverViewGrid;
