import React, { Component } from 'react';
import { Popover, Button } from 'devextreme-react';
import './action-items-sheet.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import ActionItemsPopover from './ActionItemsPopover';
import meanIcon from '../../../../../assets/icons/mean-icon.svg';
import medianIcon from '../../../../../assets/icons/median-icon.svg';
import stdDevIcon from '../../../../../assets/icons/std-dev-icon.svg';
import bestFitLineIcon from '../../../../../assets/icons/best-fit-line-icon.png';
import WidgetCard from '../../../../../components/template-component/widget-card/WidgetCard';

export interface ILineType {
  color: string;
  dash: string;
  width : number;
  opacity: number;
}

export interface IActionData {
  action: string;
  text: string | null;
  hint:string;
  icon: any;
  line: ILineType
}

export interface ILineDict {
  [action:string] : ILineType
}

interface IActionItemsSheetProps {
  reportActor?: string;
  diesShownOnBoxPlot?: boolean;
  onActionHandler : (summaryStat:string[], lineDict: ILineDict) => void;
}

interface IActionItemsSheetState {
  actionSheetData : IActionData[];
  visible: boolean;
  selectAll: boolean;
  activeAction: IActionData | null;
  actionMap: any;
}

export const defaultLine : ILineType = {
  color: '#ff0000',
  dash: 'solid',
  width: 2,
  opacity: 0.8,
};

class ActionItemsSheet extends Component<IActionItemsSheetProps, IActionItemsSheetState> {
  private actionSheetDataList : IActionData[] = [
    {
      hint: 'Best Fit Line',
      text: null,
      action: 'BEST_FIT_LINE',
      icon: bestFitLineIcon,
      line: JSON.parse(JSON.stringify(defaultLine)),
    },
    {
      hint: 'Mean',
      text: null,
      action: 'MEAN',
      icon: meanIcon,
      line: JSON.parse(JSON.stringify(defaultLine)),
    },
    {
      hint: 'Median',
      text: null,
      action: 'MEDIAN',
      icon: medianIcon,
      line: JSON.parse(JSON.stringify(defaultLine)),
    },
    {
      hint: 'Std Dev',
      text: null,
      action: 'STD_DEV',
      icon: stdDevIcon,
      line: JSON.parse(JSON.stringify(defaultLine)),
    },
    // {
    //   hint: 'CP',
    //   text: 'CP',
    //   action: 'CP',
    //   icon: null,
    //   line: JSON.parse(JSON.stringify(defaultLine)),
    // },
    // {
    //   hint: 'CPK',
    //   text: 'CPK',
    //   action: 'CPK',
    //   icon: null,
    //   line: JSON.parse(JSON.stringify(defaultLine)),
    // },
    // {
    //   hint: 'CPK1',
    //   text: 'CPK1',
    //   action: 'CPK1',
    //   icon: null,
    //   line: JSON.parse(JSON.stringify(defaultLine)),
    // },
    // {
    //   hint: 'CPK2',
    //   text: 'CPK2',
    //   action: 'CPK2',
    //   icon: null,
    //   line: JSON.parse(JSON.stringify(defaultLine)),
    // },
  ];

  constructor(props:IActionItemsSheetProps) {
    super(props);
    this.state = {
      actionSheetData: this.actionSheetDataList,
      visible: false,
      selectAll: false,
      activeAction: null,
      actionMap: new Set([]),
    };
  }

  visibleHandler = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  applyAction = (itemDataList:IActionData[]) => {
    const { onActionHandler } = this.props;
    const { actionMap, actionSheetData } = this.state;

    itemDataList.forEach((itemData) => {
      if (!actionMap.has(itemData.action)) {
        actionMap.add(itemData.action);
      } else {
        actionMap.delete(itemData.action);
      }
    });

    const lineDict : ILineDict = {};
    const actionMapList = [...actionMap];

    actionMapList.forEach((action:string) => {
      const actionItemData = actionSheetData.find((actionItemObj) => actionItemObj.action === action);
      if (actionItemData) {
        lineDict[action] = actionItemData.line;
      }
    });

    if (onActionHandler) onActionHandler([...actionMap], lineDict);
  };

  popoverHandler = (actionItemData:IActionData | null) => {
    const lineDict : ILineDict = {};
    const { onActionHandler } = this.props;
    const { activeAction, actionMap, actionSheetData } = this.state;

    if (actionItemData == null) {
      this.setState({ visible: false, activeAction: null });
    } else if (actionItemData.action === activeAction?.action) {
      this.setState({ visible: false, activeAction: null });
    } else {
      this.setState({ visible: true, activeAction: actionItemData });
    }

    const actionMapList = [...actionMap];
    actionMapList.forEach((action:string) => {
      const actionItemDataNew = actionSheetData.find((actionItemObj) => actionItemObj.action === action);
      if (actionItemDataNew) {
        lineDict[action] = actionItemDataNew.line;
      }
    });
    if (onActionHandler) onActionHandler([...actionMap], lineDict);
  };

  onActionItemPopoverSaveHandler = (action:string, line: ILineType) => {
    const { actionSheetData } = this.state;
    const actionItem = actionSheetData.find((actionItemData) => actionItemData.action === action);

    if (actionItem) {
      actionItem.line = JSON.parse(JSON.stringify(line));
    }

    this.popoverHandler(null);
  };

  applyAllAction = () => {
    const { actionSheetData, selectAll } = this.state;
    let { actionMap } = this.state;

    if ([...actionMap].length !== actionSheetData.length) {
      actionMap = new Set([]);
    }

    this.setState({ actionMap }, () => this.applyAction(actionSheetData));
  };

  componentDidMount() {
    const { actionSheetData } = this.state;
    const { reportActor, diesShownOnBoxPlot } = this.props;
    let newActionSheetData = _.cloneDeep(actionSheetData);
    if (reportActor && reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) {
      newActionSheetData = newActionSheetData.filter((item) => item.action === 'BEST_FIT_LINE');
      this.setState({ actionSheetData: newActionSheetData });
    }
    if (reportActor && reportActor === ReportType.PARAMETRIC_BOX_PLOT && diesShownOnBoxPlot === false) {
      newActionSheetData = newActionSheetData.filter((item) => item.action !== 'BEST_FIT_LINE');
      this.setState({ actionSheetData: newActionSheetData });
    }
  }

  render() {
    const {
      visible, activeAction, actionMap, actionSheetData,
    } = this.state;
    return (
      <div>
        {actionSheetData.length > 0
      && (
        <WidgetCard
          id="action-items-sheet"
          fullWidth
          alternateFullWidthClass="h-65 w65"
          widgetClassName="border-radius-5"
          pinClassName="floating-action-items-sheet"
          pinToggle
          expandToggle={false}
          linkToggle={false}
          pinWidgetByDefault={false}
          changeExpandedStateComponentOnUpdate={false}
          primaryWidgetWeight={12}
          primaryWidget={(
            <div className="w60 d-flex flex-column align-items-center mr150">
              <div className="w50 h-10 pb5 d-flex justify-content-center align-items-center break-bottom">
                <Button
                  focusStateEnabled={false}
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  stylingMode="text"
                  onClick={this.applyAllAction}
                >
                  <FontAwesomeIcon
                    className={`icon cursor-pointer show-all-icon ${[...actionMap].length === actionSheetData.length ? 'icon-selected' : ''}`}
                    size="lg"
                    icon={faEye}
                  />
                </Button>
              </div>
              <div className="w60 h-90 d-flex flex-column align-items-center mt10">
                {actionSheetData.map((actionItemData:IActionData) => (
                  <div className="mb10 d-flex flex-row">
                    <Button
                      id={actionItemData.action}
                      className="d-flex align-items-center justify-content-center"
                      height={40}
                      width={15}
                      focusStateEnabled={false}
                      icon="overflow"
                      stylingMode="text"
                      onClick={() => this.popoverHandler(actionItemData)}
                    />
                    <Button
                      className="d-flex align-items-center justify-content-center"
                      height={40}
                      width={35}
                      icon={actionItemData.icon !== null ? actionItemData.icon : undefined}
                      text={actionItemData.text !== null ? actionItemData.text : undefined}
                      focusStateEnabled={false}
                      stylingMode="text"
                      onClick={() => this.applyAction([actionItemData])}
                      hint={actionItemData.hint}
                    />
                    {actionMap.has(actionItemData.action) ? <div className="action-item-selected" /> : null }
                  </div>
                ))}
              </div>
              {activeAction && (
                <Popover
                  target={`#${activeAction?.action}`}
                  position="left"
                  width={350}
                  height={400}
                  showTitle
                  title={activeAction?.hint}
                  visible={visible}
                  closeOnOutsideClick
                  onHiding={() => this.popoverHandler(null)}
                >
                  <ActionItemsPopover
                    actionItemData={activeAction}
                    onSaveHandler={this.onActionItemPopoverSaveHandler}
                  />
                </Popover>
              )}
            </div>
          )}
        />
      )}
      </div>
    );
  }
}

export default ActionItemsSheet;
