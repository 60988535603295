/* eslint-disable no-param-reassign */
import initializeEvents from '../web-gl-utils/Events';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';

const dragMouseMove = (startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils, isMouseDown: boolean): void => {
  if (!isMouseDown || !waferMapInstance.waferMapVariables.drag || waferMapInstance.waferMapVariables.showRadarOnly) return;
  const ps = PublishSubscribe();
  ps.publishWithWaferID(EventTypes.WAFER_MAP_DRAGGED, {
    startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
    startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
    endX: endX / waferMapInstance.waferMapVariables.viewPortWidth,
    endY: endY / waferMapInstance.waferMapVariables.viewPortHeight,
  }, waferMapInstance.waferMapVariables.keyIndex.toString());
};

const renderWaferMap = (waferMapInstance: WebGLUtils): void => {
  if (waferMapInstance.waferMapVariables.gl === null) return;
  const vertexShader = `#version 300 es
  precision mediump float;
  in vec2 position;  
  uniform vec2 a_position;
  void main() {
      gl_Position = vec4(position.x,position.y*-1.0, 0.0, 1.0);
      gl_PointSize = 2.0;
  }`;
  const fragmentShader = `#version 300 es
  precision mediump float;
  out vec4 colorVar;
  uniform vec4 reticleColor;
  void main() {
      colorVar = reticleColor; // r,g,b,a 
  }
  `;

  const program = waferMapInstance.getProgram(
    waferMapInstance.waferMapVariables.gl!,
    vertexShader,
    fragmentShader,
  );
  if (!program) return;
  waferMapInstance.waferMapVariables.program = program!;

  initializeEvents(
    waferMapInstance,
    waferMapInstance.waferMapVariables.gl!,
    dragMouseMove,
    null,
    null,
  );
};

export default renderWaferMap;
