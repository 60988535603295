import React from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import AppConstants from '../../../../constants.js';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import { DefaultPolicyStepSettings, ISPCPolicyStepSettings } from '../../../../interfaces';
import ExpressionBuilder from '../../../utility-component/expression-builder/ExpressionBuilder';
import SPCRuleSettingsV2 from '../SPC/SPCRuleSettingsV2';
import SPCExclusionSettings from '../SPC/SPCExclusionSettings';
import SPCParameterSettings from '../SPC/SPCParameterSettings';
import SPCReportingSettings from '../SPC/SPCReportingSettings';
import { loadSPCReports, loadSPCParameters, loadSPCRules } from '../SPC/SPCMapperUtils';

interface ISPCPolicyStepSettingsProps {
  searchCriteria?: any;
  updatePolicyStepSettings: (stepSettings: any) => void;
  updatePolicyStepExpressions: (step: any) => void;
  stepSettings?: any;
  expressions?: any;
}

class SPCPolicyStepSettings extends React.Component<ISPCPolicyStepSettingsProps, any> {
  private policyStepSettingsReference: any;

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  constructor(props: ISPCPolicyStepSettingsProps) {
    super(props);
    const { stepSettings } = this.props;
    let policyStepSettings: ISPCPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.SPC));
    if (stepSettings) {
      policyStepSettings = stepSettings;
    }
    this.state = {
      createdSPCRules: loadSPCRules(policyStepSettings.spcRules),
      createdSPCParameterSettings: loadSPCParameters(policyStepSettings.spcParameters),
      createdSPCReportSettings: loadSPCReports(policyStepSettings.spcReports),
      expressions: props.expressions ? props.expressions : [],
    };
    this.policyStepSettingsReference = React.createRef();
  }

  updateSPCPolicyStepSettings = () => {
    const { updatePolicyStepSettings } = this.props;
    const {
      createdSPCRules, createdSPCParameterSettings, createdSPCReportSettings,
    } = this.state;
    const spcRules:any = [];
    const spcParameters: any = [];
    const spcReports: any = [];
    createdSPCRules.forEach((element:any) => {
      spcRules.push({ id: element.Id });
    });
    createdSPCParameterSettings.forEach((element:any) => {
      spcParameters.push({ id: element.id });
    });
    createdSPCReportSettings.forEach((element:any) => {
      spcReports.push({ id: element.id });
    });
    updatePolicyStepSettings({
      spcRules, spcParameters, spcReports,
    });
  };

  handleSPCRuleSettingsCallback = (receivedRules: any) => {
    this.setState(() => {
      return { createdSPCRules: receivedRules };
    }, this.updateSPCPolicyStepSettings);
  };

  handleSPCParameterSettingsCallback = (receivedParameterSettings: any) => {
    this.setState(() => {
      return { createdSPCParameterSettings: receivedParameterSettings };
    }, this.updateSPCPolicyStepSettings);
  };

  handleSPCReportSettingsCallback = (receivedReportSettings: any) => {
    this.setState(() => {
      return { createdSPCReportSettings: receivedReportSettings };
    }, this.updateSPCPolicyStepSettings);
  };

  render() {
    const {
      createdSPCRules,
      createdSPCParameterSettings,
      createdSPCReportSettings,
      expressions,
    } = this.state;
    const {
      searchCriteria,
    } = this.props;
    return (
      <div className="step-settings-block indent p20 background-color-secondary-background">
        <Container fluid className="h-100 overflow-visible">
          <div>
            <Row>
              <Col lg={12} className="mt20">
                <Heading size={4}>General Settings</Heading>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mt20">
                <ExpressionBuilder
                  height={350}
                  defaultStatements={expressions}
                  onUpdate={(e: any) => {
                    const { updatePolicyStepExpressions } = this.props;
                    this.setState({ expressions: e }, () => updatePolicyStepExpressions(e));
                  }}
                  rules={{
                    objects: [

                      {
                        caption: 'AbortCount',
                        dataField: 'wafer.AbortCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'AtdfVersion',
                        dataField: 'wafer.AtdfVersion',
                        dataType: 'number',
                      },
                      {
                        caption: 'CableType',
                        dataField: 'wafer.CableType',
                        dataType: 'string',
                      }, {
                        caption: 'CardType',
                        dataField: 'wafer.CardType',
                        dataType: 'string',
                      }, {
                        caption: 'CenterX',
                        dataField: 'wafer.CenterX',
                        dataType: 'number',
                      }, {
                        caption: 'CenterY',
                        dataField: 'wafer.CenterY',
                        dataType: 'number',
                      }, {
                        caption: 'ContactorType',
                        dataField: 'wafer.ContactorType',
                        dataType: 'string',
                      },
                      {
                        caption: 'CpuType',
                        dataField: 'wafer.CpuType',
                        dataType: 'string',
                      },
                      {
                        caption: 'DeviceName',
                        dataField: 'device.Name',
                        dataType: 'string',
                      },
                      {
                        caption: 'DibType',
                        dataField: 'wafer.DibType',
                        dataType: 'string',
                      },
                      {
                        caption: 'DieHeight',
                        dataField: 'wafer.DieHeight',
                        dataType: 'number',
                      },
                      {
                        caption: 'DieWidth',
                        dataField: 'wafer.DieWidth',
                        dataType: 'number',
                      },
                      {
                        caption: 'ExecType',
                        dataField: 'wafer.ExecType',
                        dataType: 'string',
                      }, {
                        caption: 'ExecVersion',
                        dataField: 'wafer.ExecVersion',
                        dataType: 'string',
                      },
                      {
                        caption: 'ExtraType',
                        dataField: 'wafer.ExtraType',
                        dataType: 'number',
                      },
                      {
                        caption: 'FacilityName',
                        dataField: 'facility.Name',
                        dataType: 'string',
                      },
                      {
                        caption: 'Flat',
                        dataField: 'wafer.Flat',
                        dataType: 'string',
                      },

                      {
                        caption: 'FunctionalCount',
                        dataField: 'wafer.FunctionalCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'GoodCount',
                        dataField: 'wafer.GoodCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'HandlerType',
                        dataField: 'wafer.HandlerType',
                        dataType: 'string',
                      },
                      {
                        caption: 'IncludeInYield',
                        dataField: 'wafer.IncludeInYield',
                        dataType: 'boolean',
                      },
                      {
                        caption: 'IsActive',
                        dataField: 'wafer.IsActive',
                        dataType: 'boolean',
                      },
                      {
                        caption: 'IsExcluded',
                        dataField: 'lot.IsExcluded',
                        dataType: 'string',
                      },
                      {
                        caption: 'IsScaled',
                        dataField: 'wafer.IsScaled',
                        dataType: 'boolean',
                      },
                      {
                        caption: 'LaserType',
                        dataField: 'wafer.LaserType',
                        dataType: 'string',
                      },
                      {
                        caption: 'LoadBoardType',
                        dataField: 'wafer.LoadBoardType',
                        dataType: 'string',
                      },
                      {
                        caption: 'LotKey',
                        dataField: 'lot.Key',
                        dataType: 'string',
                      },
                      {
                        caption: 'LotTestTemperature',
                        dataField: 'lot.TestTemperature',
                        dataType: 'string',
                      },
                      {
                        caption: 'MarkedForWaferHunter',
                        dataField: 'wafer.MarkedForWaferHunter',
                        dataType: 'boolean',
                      },
                      {
                        caption: 'NodeName',
                        dataField: 'wafer.NodeName',
                        dataType: 'string',
                      },
                      {
                        caption: 'OperatorName',
                        dataField: 'wafer.OperatorName',
                        dataType: 'string',
                      },
                      {
                        caption: 'PcrAbortCount',
                        dataField: 'wafer.PcrAbortCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'PcrFunctionalCount',
                        dataField: 'wafer.PcrFunctionalCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'PositiveX',
                        dataField: 'wafer.PositiveX',
                        dataType: 'string',
                      },
                      {
                        caption: 'PositiveY',
                        dataField: 'wafer.PositiveY',
                        dataType: 'string',
                      },
                      {
                        caption: 'ProbeCount',
                        dataField: 'wafer.ProbeCount',
                        dataType: 'string',
                      },
                      {
                        caption: 'RetestCount',
                        dataField: 'wafer.RetestCount',
                        dataType: 'number',
                      },
                      {
                        caption: 'Run SPC Step',
                        dataField: 'ProcessStep()',
                        dataType: 'string',
                      },
                      {
                        caption: 'SiteGroup',
                        dataField: 'wafer.SiteGroup',
                        dataType: 'number',
                      },
                      {
                        caption: 'SiteNumber',
                        dataField: 'wafer.SiteNumber',
                        dataType: 'number',
                      },
                      {
                        caption: 'StdfVersion',
                        dataField: 'wafer.StdfVersion',
                        dataType: 'number',
                      },
                      {
                        caption: 'TestProgramName',
                        dataField: 'testProgram.Name',
                        dataType: 'string',
                      },
                      {
                        caption: 'TestProgramRevisionNumber',
                        dataField: 'testProgram.ProgramRevisionNumber',
                        dataType: 'string',
                      },
                      {
                        caption: 'TesterType',
                        dataField: 'wafer.TesterType',
                        dataType: 'string',
                      },
                      {
                        caption: 'TestTemperature',
                        dataField: 'wafer.TestTemperature',
                        dataType: 'string',
                      },
                      {
                        caption: 'TotalDiesInFile',
                        dataField: 'wafer.TotalDiesInFile',
                        dataType: 'number',
                      },
                      {
                        caption: 'WaferSize',
                        dataField: 'wafer.WaferSize',
                        dataType: 'number',
                      },
                      {
                        caption: 'WorkCenterName',
                        dataField: 'workCenter.Name',
                        dataType: 'string',
                      },
                      {
                        caption: 'Yield',
                        dataField: 'wafer.Yield',
                        dataType: 'number',
                      },
                      { 
                        caption: 'MismatchedDieCount', 
                        dataField: 'wafer.MismatchedDieCount', 
                        dataType: 'number' 
                      },
                    ],
                    operators: [
                      {
                        caption: '=',
                        dataField: '==',
                      },
                      {
                        caption: '<',
                        dataField: '<',
                      },
                      {
                        caption: '>',
                        dataField: '>',
                      },
                      {
                        caption: '!=',
                        dataField: '!=',
                      },
                    ],
                    typeOperations: [
                      {
                        dataType: 'string',
                        operators: ['==', '!='],
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg={12} className="mt20">
              <SPCRuleSettingsV2 spcRules={createdSPCRules} onUpdate={this.handleSPCRuleSettingsCallback} />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt20">
              <SPCParameterSettings
                spcParameters={createdSPCParameterSettings}
                onUpdate={this.handleSPCParameterSettingsCallback}
                searchCriteria={searchCriteria}
                receivedRules={createdSPCRules}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt20">
              <SPCReportingSettings spcReports={createdSPCReportSettings} onUpdate={this.handleSPCReportSettingsCallback} receivedParameterSettings={createdSPCParameterSettings} />
            </Col>
          </Row>
          { !AppConstants.isTempHidden
            && (
              <Row>
                <Col lg={12} className="mt20">
                  <SPCExclusionSettings onUpdate={() => undefined} />
                </Col>
              </Row>
            )}
        </Container>
      </div>
    );
  }
}

export default SPCPolicyStepSettings;
