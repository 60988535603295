import React, { Component } from 'react';
import { PATRule, RuleTestParameter } from 'components/policy/policy-step/PAT/PATInterfaces';
import WidgetPopup from 'components/policy/policy-output/WidgetPopup';
import SeedLimitCalculation from 'components/policy/policy-step/PAT/pat-components/SeedLimitCalculation';
import { httpService } from 'services/http.service';
import { PaginatedDatagridMeta } from 'views/control-tower/PaginatedDatagrid';
import Button from '../../../../../../../wrapped-component/hint-controls/Button';
import ParameterGrid from '../../../../pat-components/ParameterGrid';
import PATSelectedParameterGrid from '../../../../pat-components/PATSelectedParameterGrid';
import Heading from '../../../../../../../wrapped-component/hint-controls/Heading';

interface IPATParameterSettingsProps {
  searchCriteria: any;
  currentEditRule: PATRule;
  patRuleSetType: any;
  onSelectTestParameterHandler: (currentSelectedTPIds: string[], currentDeselectedTPIds: string[], currentTp?: any) => void;
  binConfigSelectionHandler : (selectedBinPlusDefinition: any, rowData: any) => void;
  getSeedLimit: (e: any) => void;
  updateCurrentTestParameters: (e: any) => void;
}

interface IPATParameterSettingsState {
  showSeedLimitModal: boolean;
  selectedTestParameterIds: any;
  allTestParameters: any[]; // array of objects of all tps in snake case and flat hierarchy => test_parameter_id
}

class PATParameterSettings extends Component<IPATParameterSettingsProps, IPATParameterSettingsState> {
  constructor(props: any) {
    super(props);
    const { currentEditRule } = this.props;
    this.state = {
      allTestParameters: [],
      showSeedLimitModal: false,
      selectedTestParameterIds: currentEditRule.testParameters && currentEditRule.testParameters.length > 0
        ? currentEditRule.testParameters.map((tp: RuleTestParameter) => { return tp.tpId; }) : [],
    };
  }

  hideSeedLimitModal = () => {
    const { showSeedLimitModal } = this.state;
    this.setState({ showSeedLimitModal: !showSeedLimitModal });
  };

  onTestParameterSelected = (e: any) => {
    const { onSelectTestParameterHandler } = this.props;
    const { allTestParameters } = this.state;
    this.setState({
      selectedTestParameterIds: [...e.selectedRowKeys],
    }, () => {
      onSelectTestParameterHandler(
        e.currentSelectedRowKeys,
        e.currentDeselectedRowKeys,
        e.currentSelectedRowKeys.length > 0 ? allTestParameters.filter((x: any) => {
          return e.currentSelectedRowKeys.includes(x.test_parameter_id);
        }) : undefined,
      );
    });
  };

  onTestParameterRemoved = (e: any) => {
    const { selectedTestParameterIds } = this.state;
    const { currentEditRule, updateCurrentTestParameters } = this.props;
    updateCurrentTestParameters(e.row.data.tpId);
    this.setState({
      selectedTestParameterIds: selectedTestParameterIds.filter((tp: any, index:number) => tp !== e.row.data.tpId),
    });
  };

  getSeedLimit = (e: any) => {
    const { getSeedLimit } = this.props;
    this.setState((prevState: IPATParameterSettingsState) => {
      return {
        showSeedLimitModal: !prevState.showSeedLimitModal,
      };
    }, () => { getSeedLimit(e); });
  };

  onTestParametersLoaded = (data: any[]) => {
    this.setState({ allTestParameters: data });
  };

  render() {
    const {
      selectedTestParameterIds, showSeedLimitModal, allTestParameters,
    } = this.state;
    const {
      patRuleSetType, currentEditRule, binConfigSelectionHandler, searchCriteria,
    } = this.props;

    return (
      <div className="border-all p10 border-radius-4 background-color-secondary-background">
        <Heading size={5}>Test Parameters</Heading>
        <ParameterGrid
          pageSize={15}
          height={860}
          showScrollBar={false}
          allTestParameters={allTestParameters}
          patRuleSetType={patRuleSetType}
          selectedTestParameterIds={selectedTestParameterIds}
          onRowSelectionHandler={this.onTestParameterSelected}
          removeUnwantedSelectedTestParameters={() => {}}
          onTestParametersLoaded={this.onTestParametersLoaded}
          httpLoadTestParametersCallback={(datagridMeta: PaginatedDatagridMeta) => { return httpService.getPaginatedTestParameters(datagridMeta, searchCriteria, selectedTestParameterIds); }}
        />
        <PATSelectedParameterGrid
          allTestParameters={allTestParameters}
          ruleTestParameters={currentEditRule.testParameters}
          selectedTestParameterIds={selectedTestParameterIds}
          binConfigSelectionHandler={binConfigSelectionHandler}
          removeField={this.onTestParameterRemoved}
          patRuleType={currentEditRule.patRuleType}
        />
        <div className="mt20 d-flex align-items-end justify-content-end">
          <Button
            variant="primary"
            size="sm"
            disabled={currentEditRule.patRuleType === 'DYNAMIC_PAT'}
            onClick={this.hideSeedLimitModal}
          >
            Calculate Seed Limit
          </Button>
        </div>
        <WidgetPopup
          show={showSeedLimitModal}
          hideWidgetPopup={this.hideSeedLimitModal}
          widgetName="Seed Calculation"
          primaryWidget={(
            <SeedLimitCalculation
              searchCriteria={searchCriteria}
              ruleTestParameters={currentEditRule.testParameters}
              getSeedLimit={this.getSeedLimit}
            />
          )}
          primaryWidgetWeight={12}
          hideSecondaryWidgetOnExpand
        />
      </div>
    );
  }
}

export default PATParameterSettings;
