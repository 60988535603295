/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import FileInput from 'components/wrapped-component/hint-controls/FileInput';
import { Popup, ScrollView } from 'devextreme-react';
import Form, { Item } from 'devextreme-react/form';
import { Row, Col, Container } from 'react-bootstrap';
import React from 'react';
import { httpService } from 'services/http.service';
import { toast } from 'react-toastify';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import GeneralUtils from 'GeneralUtils';
import EnrichmentParameterBlock, { IEnrichmentParameter } from './EnrichmentParameterBlock';

interface ILoaderPolicyEnrichmentStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  stepSettings?: any;
  updatedCreated?: any;
  visible: boolean;
  setIsShowpopupSettings: (e: any) => void;
}

interface ILoaderPolicyEnrichmentStepSettingsState {
  visibleState?: boolean;
  isFileUploadInProcess: boolean;
}
class ManualFileUploadPopup extends
  React.Component<ILoaderPolicyEnrichmentStepSettingsProps,
  ILoaderPolicyEnrichmentStepSettingsState> {
  private fileNames = '';

  private parameters: IEnrichmentParameter[];

  private formData: FormData;

  constructor(props: ILoaderPolicyEnrichmentStepSettingsProps) {
    super(props);
    this.parameters = [];
    this.formData = new FormData();
    this.state = { isFileUploadInProcess: false };
  }

  static getDerivedStateFromProps(props: any) {
    return {
      visibleState: props.visible,
    };
  }

  uploadFile = async (e: any) => {
    const { files } = e.target;
    let totalFileSize = 0;
    this.fileNames = '';
    for (let i = 0; i < files.length; i += 1) {
      totalFileSize += files[i].size;
      if (i !== 0) { this.fileNames += ', '; }
      this.fileNames += files[i].name;
    }
    if (totalFileSize > 5368709120) {
      toast.error('Max file size of 5GB allowed. Please use alternate storage mechanism for data uploading');
      return;
    }
    this.setState({}, this.forceUpdate);
    this.formData = new FormData();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      this.formData.append('formFiles', files[i]);
    }
  };

  toggleIsFileUploadInProcess = (isInUploadState: boolean) => {
    this.setState({ isFileUploadInProcess: isInUploadState });
  };

  onCancelPopupFormHandler = () => {
    this.setState({ visibleState: false });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setIsShowpopupSettings(false);
  };

  onUploadPopupFormHandler = async () => {
    try {
      if (GeneralUtils.isDuplicateParameterFound(this.parameters)) { return; }
      this.toggleIsFileUploadInProcess(true);
      toast.warning('Please don\'t refresh or close the page while file is being uploaded.');
      this.formData.delete('parameters');
      this.formData.append('parameters', JSON.stringify(this.parameters));
      const response = await httpService.postUploadFile(this.formData);
      this.toggleIsFileUploadInProcess(false);
      if (response.isSuccessStatusCode) {
        toast.success('Files uploaded to Server, please check Control Tower for Load status!');
        this.onCancelPopupFormHandler();
      } else toast.error('An error occurred. Please contact administrator.');
    } catch (ex) {
      this.toggleIsFileUploadInProcess(false);
    }
  };

  render() {
    const { visibleState, isFileUploadInProcess } = this.state;
    return (
      <Popup
        className="d-flex"
        showTitle={false}
        closeOnOutsideClick={false}
        onHiding={this.onCancelPopupFormHandler}
        visible={visibleState}
        width="90%"
        height="80%"
        dragEnabled={false}
        toolbarItems={[
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            disabled: isFileUploadInProcess,
            options: {
              text: 'Upload',
              onClick: this.onUploadPopupFormHandler,
            },
          },
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              onClick: this.onCancelPopupFormHandler,
            },
          },
        ]}
      >
        <ScrollView
          showScrollbar="always"
          direction="vertical"
          reachBottomText=""
          scrollByThumb
        >
          <Form>
            <Item
              cssClass="mt20 mb20"
              itemType="group"
              caption="Manual File Upload"
              colCount={2}
              colSpan={2}
            >

              <EnrichmentParameterBlock
                parameterList={this.parameters}
                isManualFilePopup
                componentHeading="Parameter Info for Enrichment"
              />

              <Row>
                <Col className="custom-form mt20" lg={12}>
                  <span className="d-flex align-items-center justify-content-between">
                    <Heading size={4}>Files info for manual upload</Heading>
                  </span>
                </Col>
              </Row>

              <Container fluid style={{ overflow: 'visible' }}>
                <Row>
                  <Col className="custom-form mt10" lg={12}>
                    <Row className="background-color-secondary-background rounded border-all m2 pt20 pb20 row">
                      <>
                        <Col className="mt10" lg={2}>
                          <FileInput
                            id="file-upload"
                            onChange={this.uploadFile}
                            name="Manual File Upload"
                            className="upload-file-label"
                            isFileUploadInProcess={isFileUploadInProcess}
                          />
                        </Col>
                        <Col className="mt10" lg={10}>
                          <label className="upload-file-names">
                            {this.fileNames}
                          </label>
                        </Col>
                      </>
                    </Row>
                  </Col>
                </Row>
              </Container>

            </Item>
          </Form>
        </ScrollView>
      </Popup>
    );
  }
}

export default ManualFileUploadPopup;
