/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DataGrid, SelectBox } from 'devextreme-react';
import {
  Column, Editing, FilterRow, RequiredRule, Scrolling, Sorting,
} from 'devextreme-react/data-grid';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './device-setup.scss';
import withProps from 'components/bin-plus-tables/withProps';
import CreateDynamicColumn from 'components/wrapped-component/customized-data-grid/CreateDynamicColumn';
import { Alert, Row } from 'react-bootstrap';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import toast from 'CustomToast';
import getDropDownOptions from 'components/getDropDownOptions';
import ExpressionValueComponent from './expression-value-component';
import EditLevelComponent from './edit-level-component';

type AttributesGridState = {
};

type AttributesGridProps = {
  attributes: any,
  allAttributes: any,
  binPlusTables?: any,
  waferControlMaps?: any,
  onAddAttribute: (columnInfo: any, callback: any) => void,
  onAddAttributeDefinition: (data: any) => void,
  onUpdateAttributeDefinition: (data: any) => void,
  onDeleteAttributeDefinition: (id: string) => void,
  overrideAttributeInWC?: boolean,
  workCenterTypes?: any[],
  onDeleteAttribute: (id: string, callback: () => void) => void,
  parentId: string | null,
  needLevel?: boolean,
  needAccess?: boolean,
  needDatatype?: boolean,
  needUDAConstraints?: boolean,
  needUDAAccess?: boolean,
  needUDAType?: boolean,
  passAtrGridRefToParent?: (ref: any) => void,
  masterMetaColumns?: { [entityType: string]: any[] },
};

const DataTypes = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  BIN_PLUS_TABLE: 'bin plus table',
  WAFER_CONTROL_MAP: 'wafer control map',
};

const ACCESS_ITEMS = [
  {
    name: 'Public',
    value: 'PUBLIC',
  },
  {
    name: 'Private',
    value: 'PRIVATE',
  },
];

class AttributesGrid extends React.Component<AttributesGridProps, AttributesGridState> {
  static defaultProps = {
    needLevel: true,
    needAccess: true,
    needDatatype: true,
    needUDAConstraints: true,
    needUDAAccess: true,
    needUDAType: true,
  };

  datagrid: DataGrid | null = null;

  onValueChanged = (cell: any, e: any) => {
    cell.setValue(e.value);
  };

  clearValue = (colName: string) => {
    const rowIndex = this.datagrid?.instance.getVisibleRows().filter((row: any) => { return row.isEditing; })[0]?.rowIndex;
    const colIndex = this.datagrid?.instance.getVisibleColumns().filter((col: any) => { return col.dataField === colName; })[0]?.visibleIndex;
    // eslint-disable-next-line no-unused-expressions
    if (rowIndex !== undefined && colIndex !== undefined) this.datagrid?.instance.cellValue(rowIndex!, colIndex!, '');
  };

  deleteAttributeAndClearValues = (id: string) => {
    const { onDeleteAttribute } = this.props;
    onDeleteAttribute(id, () => {
      this.clearValue('name');
      this.clearValue('label');
      this.clearValue('dataType');
    });
  };

  setLabelDataTypeOnNameValue = (newData: any, value: any) => {
    const { allAttributes } = this.props;
    // eslint-disable-next-line no-param-reassign
    newData.name = value;
    const atr = allAttributes.filter((_atr: any) => _atr.id === value)[0];
    // eslint-disable-next-line no-param-reassign
    newData.label = atr?.columnLabel;
    // eslint-disable-next-line no-param-reassign
    newData.dataType = atr?.columnType;
  };

  onAddAttribute = (columnInfo: any) => {
    const { onAddAttribute } = this.props;
    onAddAttribute(columnInfo, (atrId: string) => {
      if (this.datagrid === null) return;
      const rows = this.datagrid.instance.getVisibleRows();
      const editRows = rows.filter((row: any) => { return row.isEditing; });
      if (editRows.length > 0 && editRows[0].rowIndex !== undefined) {
        this.datagrid.instance.cellValue(editRows[0].rowIndex, 'name', atrId);
      }
    });
  };

  wrapInDisabledDiv = (value: any) => {
    return (
      <div style={{
        paddingTop: '10px', height: '35px', paddingBottom: '10px', backgroundColor: '#eee', paddingLeft: '5px',
      }}
      >
        {value}
      </div>
    );
  };

  editNameCellRender = (cell: any) => {
    const {
      overrideAttributeInWC, workCenterTypes, allAttributes, needUDAType, needUDAConstraints, needUDAAccess,
    } = this.props;
    if (!cell.row.isNewRow) {
      return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.value)[0]?.columnName);
    }
    return (
      <Row>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: '20px' }}>
            <CreateDynamicColumn
              columnTypes={[
                [DataTypes.STRING, 'Text'],
                [DataTypes.NUMBER, 'Number'],
                [DataTypes.DATE, 'Date'],
                [DataTypes.BOOLEAN, 'Boolean'],
                [DataTypes.BIN_PLUS_TABLE, 'Bin+ Table'],
                [DataTypes.WAFER_CONTROL_MAP, 'Wafer Control Map'],
              ]}
              showEntityTypes
              needUDAType={needUDAType}
              needUDAConstraints={needUDAConstraints}
              needUDAAccess={needUDAAccess}
              needExpression={false}
              overideInWorkCenters={overrideAttributeInWC}
              workCentersList={workCenterTypes}
              getColumnInfo={this.onAddAttribute}
              buttonContent={<small>+ UDA</small>}
            />
          </div>
          <SelectBox
            width="60%"
            defaultValue={cell.value}
            displayExpr="columnName"
            items={allAttributes}
            itemRender={(item) => {
              return (
                <div>
                  <span>{item.columnName}</span>
                  <i
                    className="float-right dx-icon-trash text-danger"
                    onClick={() => { this.deleteAttributeAndClearValues(item.id); }}
                  />
                </div>
              );
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cell.column.lookup}
            dropDownOptions={getDropDownOptions('cell', cell, [{ minWidth: 200 }])}
            onValueChanged={(event: any) => { this.onValueChanged(cell, event); }}
            valueExpr="id"
          />
        </div>
      </Row>

    );
  };

  editAccessCellRender = (cell: any) => {
    return (
      <SelectBox
        defaultValue={cell.value}
        displayExpr="name"
        items={ACCESS_ITEMS}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...cell.column.lookup}
        onValueChanged={(event) => this.onValueChanged(cell, event)}
        valueExpr="value"
        dropDownOptions={getDropDownOptions('cell', cell)}
      />
    );
  };

  calculateValueFilterExpression = (filterValue: any) => {
    const { binPlusTables, waferControlMaps } = this.props;
    return (data: any) => {
      if (data.value === undefined || data.value === null) return true;
      if (data.dataType === DataTypes.NUMBER) {
        return +filterValue === data.value;
      }
      if (data.dataType === DataTypes.DATE || data.dataType === DataTypes.STRING) {
        return data.value.toString().toLowerCase().includes(filterValue.toLowerCase());
      }
      if (data.dataType === DataTypes.BOOLEAN) {
        return data.value.toString().toLowerCase() === filterValue.toLowerCase();
      }
      if (binPlusTables && data.dataType === DataTypes.BIN_PLUS_TABLE) {
        return binPlusTables.filter((bpt: any) => { return data.value.includes(bpt.id); }).map((bpt: any) => bpt.name).sort().join(' ')
          .includes(filterValue.toLowerCase());
      }
      if (waferControlMaps && data.dataType === DataTypes.WAFER_CONTROL_MAP) {
        return waferControlMaps.filter((wcm: any) => { return data.value.includes(wcm.id); }).map((wcm: any) => wcm.name).sort().join(' ')
          .includes(filterValue.toLowerCase());
      }
      return false;
    };
  };

  calculateNameFilterExpression = (filterValue: any, filterType: any) => {
    const { allAttributes } = this.props;
    return (data: any) => {
      const filteredAtrs = allAttributes.filter((atr: any) => { return atr.id === data.name; });
      if (filteredAtrs.length > 0) {
        return UtilityFunctions.checkFilter(filteredAtrs[0].columnName, filterValue, filterType);
      }
      return false;
    };
  };

  calculateLevelFilterExpression = (filterValue: any) => {
    return (data: any) => {
      return data.level && Object.keys(data.level).join('').toLowerCase().includes(filterValue.toLowerCase());
    };
  };

  // eslint-disable-next-line no-unused-vars
  valueCellRender = (cell: any) => {
    const { binPlusTables, waferControlMaps } = this.props;
    let text = '';
    if (binPlusTables && cell.data.dataType === DataTypes.BIN_PLUS_TABLE) {
      text = binPlusTables.filter((bpt: any) => { return cell.value.includes(bpt.id); }).map((bpt: any) => bpt.name).sort().join(', ');
      return text;
    }
    if (waferControlMaps && cell.data.dataType === DataTypes.WAFER_CONTROL_MAP) {
      text = waferControlMaps.filter((wcm: any) => { return cell.value.includes(wcm.id); }).map((wcm: any) => wcm.name).sort().join(', ');
      return text;
    }
    return cell.value;
  };

  levelCellRender = (cell: any) => {
    // eslint-disable-next-line no-unused-vars
    const text = (Object.entries(cell.value || {}) || []).map(([levelName]: any) => {
      return (<span key={levelName}>{UtilityFunctions.capitalizeFirstLetter(levelName.replace(/_/g, ' ').toLowerCase())}</span>);
    });
    return (
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto' }}>
        {text}
      </div>
    );
  };

  setAccessCellValue = (newData: any, value: any) => {
    // eslint-disable-next-line no-param-reassign
    newData.access = value;
  };

  render() {
    const {
      attributes, binPlusTables, onAddAttributeDefinition, needAccess, needLevel, needDatatype, allAttributes, masterMetaColumns,
      onUpdateAttributeDefinition, onDeleteAttributeDefinition, parentId, passAtrGridRefToParent, waferControlMaps,
    } = this.props;
    return (

      <>
        <Alert show={parentId === null} variant="warning">
          Save Device Setup To Add Device Settings
        </Alert>
        { parentId !== null && (
          <DataGrid
            showBorders
            ref={(datagrid) => {
              this.datagrid = datagrid;
              if (passAtrGridRefToParent) passAtrGridRefToParent(datagrid);
            }}
            style={{ marginTop: '0px', clear: 'both', maxHeight: '400px' }}
            dataSource={attributes}
            hoverStateEnabled
            onToolbarPreparing={(e: any) => {
              if (parentId !== null) {
                e.toolbarOptions.items.unshift({
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'add',
                    onClick: () => {
                      if (this.datagrid) {
                        if (!UtilityFunctions.isDataGridInEditMode(this.datagrid)) {
                          e.component.addRow();
                        } else {
                          toast.warn('Can not add attribute definition. Data grid is in edit mode.');
                        }
                      }
                    },
                    hint: 'Add attribute definition',
                  },
                });
              }
            }}
            height="auto"
            showColumnLines
            allowColumnReordering
            rowAlternationEnabled
            columnResizingMode="widget"
            keyExpr="id"
            onRowRemoved={(event: any) => { onDeleteAttributeDefinition(event.key); }}
            onRowUpdated={(event: any) => { onUpdateAttributeDefinition(event.data); }}
            onRowInserted={(event: any) => { onAddAttributeDefinition(event.data); }}
          >
            <FilterRow visible />
            <Sorting mode="multiple" />
            <Column
              dataField="name"
              minWidth={200}
              setCellValue={this.setLabelDataTypeOnNameValue}
              cellRender={(cell: any) => { return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.value)[0]?.columnName); }}
              editCellRender={this.editNameCellRender}
              calculateFilterExpression={this.calculateNameFilterExpression}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField="label"
              width={200}
              allowEditing={false}
              cellRender={(cell: any) => { return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.data.name)[0]?.columnLabel || ''); }}
              editCellRender={(cell: any) => { return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.data.name)[0]?.columnLabel || ''); }}
            >
              <RequiredRule />
            </Column>
            {needDatatype && (
              <Column
                minWidth={200}
                allowEditing={false}
                dataField="dataType"
                cellRender={(cell: any) => { return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.data.name)[0]?.columnType || ''); }}
                editCellRender={(cell: any) => { return this.wrapInDisabledDiv(allAttributes.filter((atr: any) => atr.id === cell.data.name)[0]?.columnType || ''); }}
              >
                <RequiredRule />
              </Column>
            )}
            <Column
              alignment="center"
              minWidth={200}
              dataType="object"
              dataField="value"
              calculateFilterExpression={this.calculateValueFilterExpression}
              cellRender={this.valueCellRender}
              editCellComponent={withProps(ExpressionValueComponent, { binPlusTables, waferControlMaps })}
            >
              <RequiredRule />
            </Column>

            {
              needAccess && (
                <Column
                  width={150}
                  dataField="access"
                  setCellValue={this.setAccessCellValue}
                  editCellRender={this.editAccessCellRender}
                />
              )
            }
            { needLevel && (
              <Column
                width={400}
                minWidth={400}
                dataType="object"
                dataField="level"
                calculateFilterExpression={this.calculateLevelFilterExpression}
                cellRender={this.levelCellRender}
                editCellComponent={withProps(EditLevelComponent, { masterMetaColumns })}
              />
            )}

            <Scrolling mode="virtual" />
            <Editing
              allowUpdating
              confirmDelete={false}
              allowDeleting
              mode="row"
            />
          </DataGrid>
        )}
      </>

    );
  }
}

export default AttributesGrid;
