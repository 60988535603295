import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import React, { ReactNode } from 'react';
import {
  CheckBox, ColorBox, DataGrid, SelectBox,
} from 'devextreme-react';
import {
  Column, Editing, PatternRule, RequiredRule, Scrolling,
} from 'devextreme-react/data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import toast from 'CustomToast';
import { dxContextMenuItem } from 'devextreme/ui/context_menu';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import _, { cloneDeep } from 'lodash';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import OutputLocation from './OutputLocation';
import TopbarNav from '../../navigational-component/topbar-nav/TopbarNav';
import SelectionCriteriaButton from '../../selection-criteria/SelectionCriteriaButton';
import './amg-pick-map.scss';
import Button from '../../wrapped-component/hint-controls/Button';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import Heading from '../../wrapped-component/hint-controls/Heading';
import Label from '../../wrapped-component/hint-controls/Label';
import { httpService } from '../../../services/http.service';
import WaferData from '../../utility-component/wafer-map-widget/WaferData';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import ExpressionBuilder from '../../utility-component/expression-builder/ExpressionBuilder';
import { ISelectionCriteriaData, ISelectionCriteriaReturnValue } from '../../../interfaces';
import PublishSubscribe, { EventTypes } from '../../utility-component/wafer-map-widget/PublishSubscribe';
import CustomizedMultipleDropdown from '../../wrapped-component/customized-multiple-dropdown/CustomizedMultipleDropdown';
import Textbox from '../../wrapped-component/hint-controls/Textbox';
import Checkbox from '../../wrapped-component/hint-controls/Checkbox';
import { httpBinPlusTable } from '../../../services/http.bin-plus-table';
import WaferPlotter, { WaferAdditionalControl } from '../../utility-component/wafer-map-widget/wafer-map-v2/WaferPlotter/WaferPlotter';
import WaferUtils from '../../utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferUtils';
import { NotchPosition, RotateDirection } from '../../utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
import GeneralUtils from 'GeneralUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AMGPickMapProps {

}

interface WaferMapOffset {
  x: number;
  y: number;
}
enum MiddleViewport {
  // eslint-disable-next-line no-unused-vars
  WAFER, OUTPUT_SETTINGS, EXPRESSIONS, DIE_TYPES, BIN_PLUS, PARAMETER_SELECTION, WAFER_SELECTION,
}

const keyIndexIdentifier = 'amg-pick-map-wafer';
const inlineBlockStyle = 'inline-block';
const outlinePrimaryStyle = 'outline-primary';

class AMGPickMap extends React.Component<AMGPickMapProps, any> {
  plotter: WaferPlotter | null = null;

  waferPlotterDiv: any = null;

  WAFER_PLOTTER_CANVAS_WIDTH = 1000;

  WAFER_PLOTTER_CANVAS_HEIGHT = 1000;

  WAFER_PLOTTER_DRAGGABLE_CONTROLS_WIDTH = 200;

  waferMapToPlotRatio = 3 / 4; // 3:1 is the size ration between wafer map and its radar

  smallerToBiggerWaferRatio = 1 / 3; // smaller wafer are 3 times lesser in size than selected wafer

  numberOfWafersPerRow = 2;

  offset = 10;

  totalNumberOfRows = 1;

  verticalMargin = 0;

  dieSelectionOptions = [['passFailFlag', 'Pass/Fail flag'], ['softBin', 'Soft Bin'], ['hardBin', 'Hard Bin']];

  possibleNotchPositions = ['D', 'L', 'U', 'R']; // in clockwise order - matters when updating - do not change order

  styles = {
    inlineBlock: { display: inlineBlockStyle },
    dieSummaries: { fontSize: '12px', backgroundColor: '#fff' },
  };

  constructor(props: AMGPickMapProps) {
    super(props);
    const dieTypes = [
      {
        id: 'dt0',
        name: 'Unassigned',
        color: '#b80932',
        probed: 'N',
        yielded: '-',
        touchDownAllowed: 'N',
      },
      {
        id: 'dt1',
        name: 'WAT/PCM',
        color: '#d9681e',
        probed: '-',
        yielded: 'N',
        touchDownAllowed: 'Y',
      },
    ];
    const softBins = [{ id: '1', name: 'gray', color: '#eeeeee' }, { id: '4', name: 'green 4', color: '#70f0b0' },
      { id: '5', name: 'blue 5', color: '#70f0f0' }, { id: '6', name: 'red 6', color: '#f07070' },
      { id: '7', name: 'red 7', color: '#f00070' }, { id: '10', name: 'green 10', color: '#00f000' },
      { id: '11', name: 'green 11', color: '#86B404' }, { id: '12', name: 'orange 12', color: '#F0AC70FF' }];
    const hardBins = [{ id: '2', name: 'green 2', color: '#70f000' }, { id: '3', name: 'green 3', color: '#70f070' },
      { id: '4', name: 'green 4', color: '#70f0b0' }, { id: '5', name: 'blue 5', color: '#70f0f0' },
      { id: '6', name: 'red 6', color: '#f07070' }, { id: '8', name: 'green 8', color: '#70f020' },
      { id: '9', name: 'purple', color: '#A901DB' }];
    this.state = {
      dieTypes,
      middleViewport: MiddleViewport.WAFER,
      waferMaps: [],
      originalWaferMaps: [],
      unchangedWaferMaps: [],
      waferMetaDatas: [],
      currentWaferIndex: 0,
      waferMapInstances: {},
      selectionCriteriaData: [],
      collapseTabsColumn: false,
      collapseDieSummaryColumn: false,
      softBins,
      hardBins,
      selectedLegend: {
        field: undefined,
        id: undefined,
      },
      filters: [],
      keys: [],
      dieColorType: 'hardBin',
      expressions: undefined,
      selectedTestParameters: [],
      dataSelectionTestParameters: [],
      classDefinitions: [],
      classOption: 'SingleParameter',
      numberOfClasses: 0,
      dieFieldValues: {
        dieType: dieTypes,
        softBin: softBins,
        hardBin: hardBins,
        assembled: [{ id: 'true', name: 'true', color: '#e6ee72' }],
        passFailFlag: [{ id: 'PASS', name: 'Pass', color: '#55db47' }, { id: 'FAIL', name: 'Fail', color: '#fa0303' }],
      },
      binPlusDefinitions: [],
      binPlusTableMap: {},
      selectedBinPlusTable: undefined,
      selectedWafers: [],
      fileOutputs: [
      // { type: 'FTP', ftpAddress: 'ftp://yieldwerx.com', ftpUsername: 'yieldwerx', ftpPassword: '*******', folderPath: '/AMG/', outputFormat: 'Laurier' },
      // { type: 'Cloud Container', container: 'yw-amg-files', outputFormat: 'ASCII_TXT' },
      // { type: 'Local', folderPath: 'E:\\Yieldwerx\\AMG', outputFormat: 'ASCII_TXT' }
      ],
      waferMapIndexMap: {},
      connectedWaferMapKeys: [],
      controlWaferMapKey: undefined,
      unchangedCoordinateOffsets: [],
      customCoordinateOffsets: [],
      coordinateOffsets: [],
      isProcessing: false,
      userDefinedWaferMapOffset: { x: 0, y: 0 } as WaferMapOffset,
      defaultAxes: [],
      unchangedNotchPositions: [],
      notchPositions: [],
      unchangedAxesFlips: [],
      axesFlips: [],
    };
    this.getSelectionCriteriaComponentData = this.getSelectionCriteriaComponentData.bind(this);
    this.getBinPlusDefinitions();
  }

  getBinPlusDefinitions = async () => {
    const { binPlusTableMap } = this.state;
    const response = await httpBinPlusTable.getSoftHardBinPlusDefinitions('binTypes=Softbin,HardBin');
    response.forEach((x: any) => { binPlusTableMap[x.binPlusTable.id] = x.binPlusTable.name; });
    this.setState({ binPlusDefinitions: response, binPlusTableMap });
  };

  updateDieFieldValues = () => {
    const {
      dieTypes,
      softBins,
      hardBins,
      classDefinitions,
    } = this.state;
    const dieFieldValues: any = {
      dieType: dieTypes,
      softBin: softBins,
      hardBin: hardBins,
      assembled: [{ id: 'true', name: 'true', color: '#e6ee72' }],
      passFailFlag: [{ id: 'PASS', name: 'Pass', color: '#55db47' }, { id: 'FAIL', name: 'Fail', color: '#fa0303' }],
    };
    classDefinitions.forEach((definition: any) => {
      dieFieldValues[definition.name] = definition.rows.map((row: any) => {
        return { id: row.classSequence, name: row.classSequence, color: row.color };
      });
    });
    this.setState({ dieFieldValues });
  };

  getWaferMap = async (ids: string[]) => {
    this.setState({ isProcessing: true });
    const originalWaferMaps = [];
    const waferMaps = [];
    let unchangedWaferMaps = [];
    const keys = [];
    const coordinateOffsets = [];
    const customCoordinateOffsets = [];
    const notchPositions = [];
    const axesFlips = [];
    const defaultAxes = [];
    const response = await httpService.getBinWaferMapData(ids);
    const softBinExecutionStatuses: any = {};
    const hardBinExecutionStatuses: any = {};
    let softBins = [];
    let hardBins = [];
    for (let i = 0; i < ids.length; i += 1) {
      const resultWaferMap = this.createWaferMap(response[i].dies);
      originalWaferMaps.push(JSON.parse(JSON.stringify(resultWaferMap)));
      unchangedWaferMaps = _.cloneDeep(originalWaferMaps);
      waferMaps.push(resultWaferMap);
      keys.push(i);
      response[i].binInformationDTOs.filter((x: any) => x.binType === 'Softbin').forEach((x: any) => { softBinExecutionStatuses[x.binNumber] = x.binExecutionStatus; });
      response[i].binInformationDTOs.filter((x: any) => x.binType === 'Hardbin').forEach((x: any) => { hardBinExecutionStatuses[x.binNumber] = x.binExecutionStatus; });
      coordinateOffsets.push(
        {
          colOffset: response[i].colOffset,
          rowOffset: response[i].rowOffset,
        },
      );
      customCoordinateOffsets.push({
        x: 0,
        y: 0,
      });
      defaultAxes.push({
        x: response[i].defaultFlipX,
        y: response[i].defaultFlipY,
      })
      notchPositions.push(
        this.possibleNotchPositions.includes(response[i].notchPosition)
          ? response[i].notchPosition
          : NotchPosition[NotchPosition.DOWN].charAt(0),
      ); // default notch position is D (NotchPosition.DOWN)
      axesFlips.push({
        x: response[i].flipX,
        y: response[i].flipY,
      });
    }
    softBins = Object.keys(softBinExecutionStatuses);
    hardBins = Object.keys(hardBinExecutionStatuses);
    const softBinPlusTableId = response[0].binInformationDTOs.filter((x: any) => x.binType === 'Softbin').find((x: any) => x.binPlusTableId)?.binPlusTableId;
    const hardBinPlusTableId = response[0].binInformationDTOs.filter((x: any) => x.binType === 'Hardbin').find((x: any) => x.binPlusTableId)?.binPlusTableId;
    const softLegendBins = (await httpBinPlusTable.getSoftHardBinPlusDefinitions(`binTypes=Softbin&binPlusTableId=${softBinPlusTableId}&binNumbers=${softBins.join(',')}`))
      .map((x: any) => {
        return {
          id: x.number,
          name: x.name,
          color: x.color,
          passFail: softBinExecutionStatuses[x.number],
        };
      });
    const hardLegendBins = (await httpBinPlusTable.getSoftHardBinPlusDefinitions(`binTypes=Hardbin&binPlusTableId=${hardBinPlusTableId}&binNumbers=${hardBins.join(',')}`))
      .map((x: any) => {
        return {
          id: x.number,
          name: x.name,
          color: x.color,
          passFail: hardBinExecutionStatuses[x.number],
        };
      });
    const { dieFieldValues } = this.state;
    dieFieldValues.softBin = softLegendBins;
    dieFieldValues.hardBin = hardLegendBins;
    this.setState({
      originalWaferMaps,
      waferMaps,
      keys,
      unchangedWaferMaps,
      softBins: softLegendBins,
      hardBins: hardLegendBins,
      dieFieldValues,
      coordinateOffsets,
      unchangedCoordinateOffsets: _.cloneDeep(coordinateOffsets),
      customCoordinateOffsets,
      defaultAxes,
      notchPositions,
      unchangedNotchPositions: cloneDeep(notchPositions),
      axesFlips,
      unchangedAxesFlips: _.cloneDeep(axesFlips),
    });

    this.totalNumberOfRows = Math.ceil((waferMaps.length - 1) / this.numberOfWafersPerRow);
    this.WAFER_PLOTTER_CANVAS_WIDTH = this.waferPlotterDiv.offsetWidth;
    this.verticalMargin = (this.WAFER_PLOTTER_CANVAS_WIDTH * this.waferMapToPlotRatio) / (6 * this.numberOfWafersPerRow);
    this.WAFER_PLOTTER_CANVAS_HEIGHT = this.getCanvasHeight(this.waferPlotterDiv.offsetWidth);

    await this.addWafer();
    this.setState({ isProcessing: false });
  };

  waferMapKeysToApplyChanges = () => {
    const { connectedWaferMapKeys, controlWaferMapKey } = this.state;
    if (connectedWaferMapKeys.length > 0 && controlWaferMapKey) {
      return connectedWaferMapKeys.includes(controlWaferMapKey) ? connectedWaferMapKeys : [controlWaferMapKey];
    }
    return controlWaferMapKey ? [controlWaferMapKey] : [];
  };

  createWaferMap = (dies: any) => {
    return new WaferData(dies, []);
  };

  setWaferInstanceHandler = (utils: WaferUtils, keyIndex: string) => {
    const { waferMapInstances } = this.state;
    waferMapInstances[keyIndex] = utils;
    const ps = PublishSubscribe();
    ps.subscribeToOthersID(
      EventTypes.DIES_MARKED_ON_WAFER,
      () => {
        this.forceUpdate();
      },
      keyIndex,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.WAFER_MAP_ROTATED,
      (directionObj: any) => {
        this.onChangeNotchPosition(directionObj.direction);
      },
      keyIndex,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.WAFER_RESET,
      () => {
        this.resetWaferMapNotchPosition();
      },
      keyIndex,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.WAFER_MAP_FLIPPED,
      (flipObj: any) => {
        this.onChangeAxesFlip(flipObj.isXAxisFlipped, flipObj.isYAxisFlipped);
      },
      keyIndex,
      'myid',
    );
  };

  getTestParameters = async (ids: string[]) => {
    const tps = await httpService.getMultipleTestParameters(ids);
    this.setState({ dataSelectionTestParameters: tps });
  };

  getSelectionCriteriaComponentData = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    const defaultSelectionCriteriaData : ISelectionCriteriaData[] = [];
    selectionCriteriaData.selections.forEach((selection) => {
      const currentSelection = {
        entityType: selection.entityType,
        values: selection.values.map((item) => (item.id)),
        isVisibiltyDefault: selection.isVisibiltyDefault,
        visibleColumns: selection.visibleColumns,
        visibleColumnsValues: selection.visibleColumnsValues,
      };
      defaultSelectionCriteriaData.push(currentSelection);
    });
    this.setState({ selectionCriteriaData: defaultSelectionCriteriaData });
    const waferSelection = selectionCriteriaData.selections.find((x) => x.entityType === 'Wafer');
    if (waferSelection && waferSelection.values.length > 0) {
      this.setState({ waferMetaDatas: waferSelection.values });
      this.getWaferMap(waferSelection.values.map((x) => x.id));
    }
    const tpSelection = selectionCriteriaData.selections.find((x) => x.entityType === 'Testparameter');
    if (tpSelection && tpSelection.values.length > 0) {
      this.getTestParameters(tpSelection.values.map((x) => x.id));
    }
  };

  updateWaferSize = () => {
    if (this.waferPlotterDiv) {
      this.WAFER_PLOTTER_CANVAS_WIDTH = this.waferPlotterDiv.offsetWidth;
      this.WAFER_PLOTTER_CANVAS_HEIGHT = this.getCanvasHeight(this.waferPlotterDiv.offsetWidth);
    }
  };

  getCanvasHeight = (canvasWidth: number) => {
    // canvas height = (selected wafer height) + (((smaller wafer height + margin) * number of rows) + offset
    return (canvasWidth * this.waferMapToPlotRatio) + ((((canvasWidth * this.waferMapToPlotRatio) * this.smallerToBiggerWaferRatio) + this.verticalMargin) * this.totalNumberOfRows) + this.offset;
  };

  getAvailableFieldValues = (fieldName: string) => {
    const { originalWaferMaps, currentWaferIndex } = this.state;
    const values: any = {};
    originalWaferMaps[currentWaferIndex].waferMapTestData.forEach((x: any[]) => x.forEach((y: any) => {
      if (y) {
        values[y[fieldName]] = true;
      }
    }));
    const result: any[] = [];
    Object.keys(values).forEach((x) => result.push({ key: x, value: x }));
    return result;
  };

  generateAssemblyMaps = async (waferIds: string[]) => {
    const {
      fileOutputs, waferMetaDatas, waferMaps, customCoordinateOffsets, notchPositions, axesFlips,
    } = this.state;
    const requestFiles = JSON.parse(JSON.stringify(fileOutputs));
    const waferIndices: number[] = [];
    waferMetaDatas.forEach((x: any, index: number) => {
      if (waferIds.includes(x.id)) waferIndices.push(index);
    });
    const requestWaferMaps: any[] = [];
    waferIndices.forEach((x: number) => requestWaferMaps.push({
      id: waferMetaDatas[x].id,
      dies: waferMaps[x].waferMapTestData,
    }));
    const customLimits = {
      axesOffsets: customCoordinateOffsets,
      notchPositions,
      axesFlips,
    };
    const responseLink = await httpService.generateAssemblyMaps({ fileOutputs: requestFiles, waferMaps: requestWaferMaps, customLimits });
    toast.success('Assembly Maps generated');
    return responseLink;
  };

  resetWaferMapsToDefaultState = async (keyIndex = '') => {
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: keyIndex === ''
        ? 'This action will remove all the changes applied to wafer maps. Do you want to proceed?'
        : 'This action will remove all the chnages applied to this wafer map. Do you want to proceed?',
    })) {
      const {
        waferMaps, unchangedWaferMaps, controlWaferMapKey, unchangedCoordinateOffsets, customCoordinateOffsets, coordinateOffsets, unchangedNotchPositions,
        notchPositions, unchangedAxesFlips, axesFlips,
      } = this.state;
      const defaultWaferMaps: any[] = _.cloneDeep(unchangedWaferMaps);
      if (keyIndex !== '') {
        const ind = parseInt(keyIndex.replace(keyIndexIdentifier, ''), 10);
        waferMaps[ind].dieSubView = defaultWaferMaps[ind].dieSubView;
        waferMaps[ind].waferMapTestData = defaultWaferMaps[ind].waferMapTestData;
        customCoordinateOffsets[ind] = {
          x: 0,
          y: 0,
        };
        coordinateOffsets[ind] = _.cloneDeep(unchangedCoordinateOffsets[ind]);
        notchPositions[ind] = _.cloneDeep(unchangedNotchPositions[ind]);
        axesFlips[ind] = _.cloneDeep(unchangedAxesFlips[ind]);
      } else {
        waferMaps.forEach((x: any, index: number) => {
          x.dieSubView = defaultWaferMaps[index].dieSubView;
          x.waferMapTestData = defaultWaferMaps[index].waferMapTestData;
          customCoordinateOffsets[index] = {
            x: 0,
            y: 0,
          };
          coordinateOffsets[index] = _.cloneDeep(unchangedCoordinateOffsets[index]);
          notchPositions[index] = _.cloneDeep(unchangedNotchPositions[index]);
          axesFlips[index] = _.cloneDeep(unchangedAxesFlips[index]);
        });
      }

      this.setState(
        {
          waferMaps,
          coordinateOffsets,
          customCoordinateOffsets,
          notchPositions,
          axesFlips,
        },
        () => {
          this.addWaferByKeys(waferMaps.map((x:any, index: any) => index), controlWaferMapKey);
        },
      );
    }
  };

  applyToWafer = async (waferIds: string[]) => {
    this.updateDieFieldValues();
    const {
      waferMaps,
      originalWaferMaps,
      keys,
      expressions,
      waferMetaDatas,
      selectedTestParameters,
      classOption,
      numberOfClasses,
      classDefinitions,
      filters,
      controlWaferMapKey,
      userDefinedWaferMapOffset,
      coordinateOffsets,
      customCoordinateOffsets,
      notchPositions,
      axesFlips,
    } = this.state;

    if (filters.length > this.dieSelectionOptions.length) {
      toast.error('Duplicate die selection options are not allowed.');
      return;
    }
    const uniqueFilters = new Set(filters.map((filter: any) => filter.field));
    if (uniqueFilters.size < filters.length) {
      toast.error('Duplicate die selection options are not allowed.');
      return;
    }

    const waferIndices: number[] = [];
    waferMetaDatas.forEach((x: any, index: number) => {
      if (waferIds.includes(x.id)) waferIndices.push(index);
    });

    const coordinateOffsetsForCurrentWaferMaps: WaferMapOffset[] = [];
    waferIndices.forEach((x: number) => {
      customCoordinateOffsets[x] = _.cloneDeep(userDefinedWaferMapOffset);
      coordinateOffsetsForCurrentWaferMaps.push(_.cloneDeep(customCoordinateOffsets[x]));
    });

    const classDefinitionObject: any[] = [];
    if (classOption === 'SingleParameter') {
      classDefinitions.forEach((x: any) => {
        classDefinitionObject.push({
          classes: x.rows.map((y: any) => {
            return {
              name: y.classSequence,
              testParameterLimits: [{
                lowerLimit: y.min,
                upperLimit: y.max,
              }],
            };
          }),
        });
      });
    } else {
      classDefinitions.forEach((x: any) => {
        classDefinitionObject.push({
          classes: x.rows.map((y: any) => {
            const row: any = {
              name: y.classSequence,
              testParameterLimits: [],
              operator: [],
            };
            selectedTestParameters.forEach((z: any, index: number) => {
              row.testParameterLimits.push({
                lowerLimit: y[`min_${index}`],
                upperLimit: y[`max_${index}`],
              });
              if (index > 0) {
                row.operator.push(y[`operator_${index}`]);
              }
            });
            return row;
          }),
        });
      });
    }
    const customLimits = {
      testParameters: selectedTestParameters.map((x: any) => x.id),
      classOption,
      numberOfClasses,
      outlierScreeningLimit: 'Custom',
      customOutlierLowerScreeningLimit: -26,
      customOutlierUpperScreeningLimit: 0,
      classType: 'UserDefined',
      classDefinitions: [{
        classes: [{ name: 'class 1', testParameterLimits: [{ upperLimit: 0, lowerLimit: -23.3 }] },
          { name: 'class 2', testParameterLimits: [{ upperLimit: -23.31, lowerLimit: -23.5 }] },
          { name: 'class 3', testParameterLimits: [{ upperLimit: -23.51, lowerLimit: -25.5 }] }],
      }],
      axesOffsets: coordinateOffsetsForCurrentWaferMaps,
    };
    customLimits.classDefinitions = classDefinitionObject;
    const requestWaferMaps: any[] = [];
    waferIndices.forEach((x: number) => requestWaferMaps.push({
      id: waferMetaDatas[x].id,
      dies: waferMaps[x].waferMapTestData,
    }));
    // raise warning toast if any of the wafer maps have all dies where die type is null or the die type is proped, when axes offset is applied
    const waferMapsWithAllDiesNullOrProbed = requestWaferMaps.filter(
      (waferMap: any, index:number) => (coordinateOffsetsForCurrentWaferMaps[index].x !== 0 || coordinateOffsetsForCurrentWaferMaps[index].y !== 0)
        && _.flatten(waferMap.dies).every((x:any) => x === null || x.dieType === null || x.dieType.isProbed)
    );
    if (waferMapsWithAllDiesNullOrProbed.length > 0) {
      toast.warn('Wafer map(s) contain all dies with a null or probed die type. These wafer maps will be ignored when applying an offset.');
    }
    const response = await httpService.applyPickMapAmgSettings({ expressions, waferMaps: requestWaferMaps, customLimits })
    if (response) {
      waferIndices.forEach((x: number, index: number) => {
        waferMaps[x] = this.createWaferMap(response[index].dies);
        originalWaferMaps[x] = JSON.parse(JSON.stringify(waferMaps[x]));
        for (let i = 0; i < originalWaferMaps[x].waferMapTestData.length; i += 1) {
          for (let j = 0; j < originalWaferMaps[x].waferMapTestData[i].length; j += 1) {
            if (originalWaferMaps[x].waferMapTestData[i][j] !== undefined && originalWaferMaps[x].waferMapTestData[i][j] !== null) {
              waferMaps[x].waferMapTestData[i][j] = originalWaferMaps[x].waferMapTestData[i][j];
              for (let k = 0; k < filters.length; k += 1) {
                if (!filters[k].ids.map((y: any) => y.key).includes(String(originalWaferMaps[x].waferMapTestData[i][j][filters[k].field]))) {
                  waferMaps[x].waferMapTestData[i][j] = null;
                  break;
                }
              }
            }
          }
        }
        keys[x] += waferMaps.length;
        coordinateOffsets[x].colOffset = response[index].colOffset;
        coordinateOffsets[x].rowOffset = response[index].rowOffset;
        notchPositions[index] = response[index].notchPosition;
        axesFlips[index].x = response[index].flipX;
        axesFlips[index].y = response[index].flipY;
      });

      this.setState(
        {
          waferMaps, keys, coordinateOffsets, customCoordinateOffsets, notchPositions, axesFlips,
        },
        () => {
          this.addWaferByKeys(waferIndices, controlWaferMapKey);
          toast.success('Pick map applied successfully.');
        },
      );
    }
  };

  onChangeWaferMapOffset = (offsetAxis: string, offsetValue: any) => {
    const { userDefinedWaferMapOffset } = this.state;
    const offsetValueInInteger = offsetValue === '' ? 0 : parseInt(offsetValue.toString(), 10);
    userDefinedWaferMapOffset[offsetAxis] = offsetValueInInteger;
    this.setState({ userDefinedWaferMapOffset });
  };

  onChangeNotchPosition = (direction: RotateDirection) => {
    const { notchPositions } = this.state;
    this.waferMapKeysToApplyChanges().forEach((keyIndex: string) => {
      const notchPositionIndexToUpdate = parseInt(keyIndex.replace(keyIndexIdentifier, ''), 10);
      const maximumPossibleNotchPostions = this.possibleNotchPositions.length;
      const currentNotchPossitionIndex = this.possibleNotchPositions.findIndex((x: string) => x === notchPositions[notchPositionIndexToUpdate]);
      if (direction === RotateDirection.ClockWise) {
        notchPositions[notchPositionIndexToUpdate] = this.possibleNotchPositions[(currentNotchPossitionIndex + 1) % maximumPossibleNotchPostions];
      } else { // anticlockwise rotation
        notchPositions[notchPositionIndexToUpdate] = this.possibleNotchPositions[(currentNotchPossitionIndex - 1 + maximumPossibleNotchPostions) % maximumPossibleNotchPostions];
      }
    });

    this.setState({ notchPositions });
  };

  resetWaferMapNotchPosition = () => {
    const { notchPositions, unchangedNotchPositions } = this.state;
    this.waferMapKeysToApplyChanges().forEach((keyIndex: string) => {
      const notchPositionIndexToUpdate = parseInt(keyIndex.replace(keyIndexIdentifier, ''), 10);
      notchPositions[notchPositionIndexToUpdate] = _.cloneDeep(unchangedNotchPositions[notchPositionIndexToUpdate]);
    });
    this.setState({ notchPositions });
  };

  onChangeAxesFlip = (isXAxisFlipped: boolean, isYAxisFlipped: boolean) => {
    const { axesFlips } = this.state;
    this.waferMapKeysToApplyChanges().forEach((keyIndex: string) => {
      const axesFlipIndexToUpdate = parseInt(keyIndex.replace(keyIndexIdentifier, ''), 10);
      axesFlips[axesFlipIndexToUpdate] = {
        x: isXAxisFlipped,
        y: isYAxisFlipped,
      };
    });
    this.setState({ axesFlips });
  };

  getTabContent = () => {
    const {
      middleViewport, filters, waferMapInstances, dieColorType, classDefinitions, waferMetaDatas, waferMapIndexMap, userDefinedWaferMapOffset,
    } = this.state;
    return (
      <div className="custom-form mt10 font-size-10">
        <Row>
          <Col>
            <Label
              labelTitle="Wafer Plotting Options"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                disabled
                list={[['actual', 'Actual Wafer/Die size'], ['calculated', 'Calculated Wafer/Die size']]}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Bin Map Type"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                selectedValue={dieColorType}
                list={[...this.dieSelectionOptions,
                  ...classDefinitions.map((x: any) => [x.name, `TP ${x.name} class`]),
                ]}
                onChange={(selectedValue) => {
                  this.setState({ dieColorType: selectedValue });
                  Object.entries(waferMapInstances).forEach((x: any) => {
                    // eslint-disable-next-line no-param-reassign
                    x[1].waferMapVariables.dieTypeField = selectedValue;
                  });
                  this.changeWaferMapVariable({ dieTypeField: selectedValue }, false);
                }}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Die Selection"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <Button
                variant="outline-primary"
                className="w43 flex-5 float-right"
                size="lg"
                onClick={
                  () => {
                    filters.push({ field: 'passFailFlag', ids: [] });
                    this.setState({ filters });
                  }
                }
              >
                <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
              </Button>
            </Label>
          </Col>
        </Row>
        <div>
          {filters.map((filter: any, index: number) => {
            return (
              <div className="d-flex">
                <Col className="padding-0">
                  <CustomizedDropdown
                    containerClassName="filter-item"
                    full
                    list={[...this.dieSelectionOptions, ...classDefinitions.map((x: any) => [x.name, x.name])]}
                    selectedValue={filter.field}
                    onChange={(selectedValue:string) => {
                      filters[index].field = selectedValue;
                      this.setState({ filters });
                    }}
                  />
                </Col>
                <Col className="padding-0">
                  <CustomizedMultipleDropdown
                    list={this.getAvailableFieldValues(filter.field)}
                    placeholder="Select values"
                    selectedRowKeys={filter.ids.map((x: any) => x.key)}
                    selectionHandler={(args: any) => {
                      filters[index].ids = args;
                      this.setState({ filters });
                    }}
                  />
                </Col>
                <Col style={{ padding: '0px', paddingTop: '5px', paddingLeft: '5px' }} lg={1}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={
                      () => {
                        filters.splice(index, 1);
                        this.setState({ filters });
                      }
                    }
                  >
                    <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
                  </Button>
                </Col>
              </div>
            );
          })}
        </div>
        <Row className="pb10">
          <Col>
            <Label
              labelTitle="Point Labels"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedMultipleDropdown
                list={[
                  { key: 'softBin', value: 'Soft Bin' },
                  { key: 'hardBin', value: 'Hard Bin' },
                  { key: 'die', value: 'Die Value' },
                  { key: 'probecard', value: 'Probe Card Site Number' },
                  { key: 'reticle', value: 'Reticle Site Number' },
                ]}
                placeholder="Point Labels"
                disabled
                selectedRowKeys={[]}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Offset"
              childrenItemsInline
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              errorSize="100"
              isFieldTouched
              className="mb5"
              hint={{ message: 'The Offset is applicable to dies that have a non-null Die Type and the Die Type is not Probed.', position: 'bottom' }}
            >
              <Textbox
                hint={{ message: 'Enter X value of offset', position: 'bottom' }}
                autoComplete="off"
                className="mr5"
                type="number"
                name="waferMapOffsetX"
                value={userDefinedWaferMapOffset.x}
                defaultValue={0}
                placeholder="X"
                onChange={(e: any) => { this.onChangeWaferMapOffset('x', e.target.value); }}
              />
              <Textbox
                hint={{ message: 'Enter Y value of offset', position: 'bottom' }}
                autoComplete="off"
                className="ml5"
                type="number"
                name="waferMapOffsetY"
                value={userDefinedWaferMapOffset.y}
                defaultValue={0}
                placeholder="Y"
                onChange={(e: any) => { this.onChangeWaferMapOffset('y', e.target.value); }}
              />
            </Label>
          </Col>
        </Row>
        <Heading size={5}>Limit/Class</Heading>
        <Row>
          <Col>
            <Label
              labelTitle="Apply Custom Limit"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                disabled
                list={[['none', 'None'], ['1', 'Limit 1'], ['2', 'Limit 2']]}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Apply Custom Class"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                disabled
                list={[['none', 'None'], ['1', 'Class 1'], ['2', 'Class 2']]}
              />
            </Label>
          </Col>
        </Row>
        <Row className="d-flex side-by-side">
          <Col className="ml-auto mr-auto mt10 mb30">
            <Button
              size="sm"
              variant="primary"
              full
              disabled
            >
              View CLM/CCM
            </Button>
          </Col>
          <Col className="ml-auto mr-auto mt10 mb30">
            <Button
              size="sm"
              variant="primary"
              full
              disabled
              onClick={() => this.setState({ middleViewport: middleViewport === MiddleViewport.PARAMETER_SELECTION ? MiddleViewport.WAFER : MiddleViewport.PARAMETER_SELECTION }, this.addWafer)}
            >
              Create Custom Limit/Class
            </Button>
          </Col>
        </Row>
        <Heading size={5}>AMG Settings</Heading>
        <Row className="d-flex side-by-side mt10 mb30">
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant={middleViewport === MiddleViewport.WAFER ? 'primary' : outlinePrimaryStyle}
              onClick={() => (middleViewport !== MiddleViewport.WAFER ? this.setState({ middleViewport: MiddleViewport.WAFER }, this.addWafer) : null)}
            >
              Wafer Maps
            </Button>
          </Col>
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant={middleViewport === MiddleViewport.EXPRESSIONS ? 'primary' : outlinePrimaryStyle}
              onClick={() => (middleViewport !== MiddleViewport.EXPRESSIONS ? this.setState({ middleViewport: MiddleViewport.EXPRESSIONS }, this.addWafer) : null)}
            >
              Expressions
            </Button>
          </Col>
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant={middleViewport === MiddleViewport.OUTPUT_SETTINGS ? 'primary' : outlinePrimaryStyle}
              onClick={() => (middleViewport !== MiddleViewport.OUTPUT_SETTINGS ? this.setState({ middleViewport: MiddleViewport.OUTPUT_SETTINGS }, this.addWafer) : null)}
            >
              Output Settings
            </Button>
          </Col>
        </Row>
        <Heading size={5}>Overlay</Heading>
        <Row>
          <Col>
            <Checkbox
              disabled
            >
              Reticle Site
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Probe Card Site"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                disabled
                list={[['none', 'None'], ['1', '2x2'], ['2', 'IDDQ_2']]}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label
              labelTitle="Defect Map"
              labelPosition="left-middle"
              labelSize="50"
              fieldSize="50"
              required
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                disabled
                list={[['none', 'None'], ['1', 'Defect Map']]}
              />
            </Label>
          </Col>
        </Row>
        <Row className="d-flex side-by-side mt10 mb10 ">
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant="warning"
              onClick={() => this.resetWaferMapsToDefaultState()}
            >
              Reset All
            </Button>
          </Col>
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant="primary"
              onClick={() => this.applyToWafer(waferMetaDatas.map((x: any) => x.id))}
            >
              Apply to All
            </Button>
          </Col>
          <Col className="ml-auto mr-auto">
            <Button
              size="sm"
              full
              variant="primary"
              onClick={() => this.applyToWafer(
                this.waferMapKeysToApplyChanges().map((x: any) => {
                  return waferMetaDatas[waferMapIndexMap[x]].id;
                }),
              )}
            >
              Apply to Wafers
            </Button>
          </Col>
        </Row>
        <Row className="d-flex mt10 mb10">
          <Col className="ml-auto mr-auto">
            <Button
              size="lg"
              variant="primary"
              full
              onClick={async () => {
                const zippedFileLink = await this.generateAssemblyMaps(waferMetaDatas.map((x: any) => x.id))
                if (zippedFileLink == null || zippedFileLink.length !== 0) {
                  window.location.href = zippedFileLink;
                  toast.success('Assembly maps downloaded.');
                }
              }}
            >
              Generate Assembly Maps
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  changeWaferMapVariable = (waferData: { [key:string] : any }, shouldReRender = true) => {
    PublishSubscribe().publishWithOthersID(EventTypes.CHANGE_WAFER_MAP_VARIABLES, { waferData, shouldReRender }, 'myid');
  };

  getDieCount = (field: string, id: string, mode = 'Count') => {
    const { waferMaps, waferMapIndexMap } = this.state;
    let count = 0;
    let totalCount = 0;
    const indexesToTraverse = this.getIndexesToTraverse(this.waferMapKeysToApplyChanges(), waferMapIndexMap, waferMaps);
    for (let k = 0; k < indexesToTraverse.length; k += 1) {
      for (let i = 0; i < waferMaps[indexesToTraverse[k]].waferMapTestData.length; i += 1) {
        for (let j = 0; j < waferMaps[indexesToTraverse[k]].waferMapTestData[i].length; j += 1) {
          if (waferMaps[indexesToTraverse[k]].waferMapTestData[i][j]) {
            totalCount += 1;
            if (String(waferMaps[indexesToTraverse[k]].waferMapTestData[i][j][field]) === id) {
              count += 1;
            }
          }
        }
      }
    }
    return mode === 'Count' ? count : ((count / totalCount) * 100).toFixed(2);
  };

  getIndexesToTraverse = (waferMapKeysToApplyChanges: any[], waferMapIndexMap: any, waferMaps: any[]) => {
    let indexesToTraverse = [];
    if (waferMapKeysToApplyChanges === undefined || waferMapKeysToApplyChanges!.length === 0) {
      for (let i = 0; i < waferMaps.length; i += 1) {
        indexesToTraverse.push(i);
      }
    } else {
      indexesToTraverse = waferMapKeysToApplyChanges.map((x: string) => waferMapIndexMap[x]);
    }
    return indexesToTraverse;
  };

  getSummaryCount = (summaryType: string, mode = 'Count') => {
    const {
      waferMaps, unchangedWaferMaps, waferMapIndexMap,
    } = this.state;
    const indexesToTraverse = this.getIndexesToTraverse(this.waferMapKeysToApplyChanges(), waferMapIndexMap, waferMaps);
    let count = 0;
    let totalCount = 0;
    for (let k = 0; k < indexesToTraverse.length; k += 1) {
      let flatCurrentWaferMap: any = _.flatten(waferMaps[indexesToTraverse[k]].waferMapTestData);
      let flatUnchangedWaferMap: any = _.flatten(unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData);
      if (flatCurrentWaferMap.length !== flatUnchangedWaferMap.length) {
        flatCurrentWaferMap = flatCurrentWaferMap.filter((x:any) => x !== null && x.dieType !== null && x.dieType.isProbed);
        flatUnchangedWaferMap = flatUnchangedWaferMap.filter((x:any) => x !== null && x.dieType !== null && x.dieType.isProbed);
        if (flatCurrentWaferMap.length !== flatUnchangedWaferMap.length) {
          toast.error(`Wafer map data is not consistent. Failed to calculate ${mode}.`);
        } else {
          for (let i = 0; i < flatCurrentWaferMap.length; i += 1){
            totalCount += 1;
            if ((flatCurrentWaferMap[i].softBin && flatUnchangedWaferMap.softBin
              && (((summaryType === 'Downgraded' && flatCurrentWaferMap[i].softBin > flatUnchangedWaferMap[i].softBin)
                || (summaryType === 'Upgraded' && flatCurrentWaferMap[i].softBin < flatUnchangedWaferMap[i].softBin)
                || (summaryType === 'Reclassified' && flatCurrentWaferMap[i].softBin !== flatUnchangedWaferMap[i].softBin))))
              || (flatCurrentWaferMap[i].hardBin && flatUnchangedWaferMap.hardBin
                && (((summaryType === 'Downgraded' && flatCurrentWaferMap[i].hardBin > flatUnchangedWaferMap[i].hardBin)
                  || (summaryType === 'Upgraded' && flatCurrentWaferMap[i].hardBin < flatUnchangedWaferMap[i].hardBin)
                  || (summaryType === 'Reclassified' && flatCurrentWaferMap[i].hardBin !== flatUnchangedWaferMap[i].hardBin))))) {
              count += 1;
            }
          }
        }
      } else {
        for (let i = 0; i < waferMaps[indexesToTraverse[k]].waferMapTestData.length; i += 1) {
          for (let j = 0; j < waferMaps[indexesToTraverse[k]].waferMapTestData[i].length; j += 1) {
            if (waferMaps[indexesToTraverse[k]].waferMapTestData[i][j] && unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j]) {
              totalCount += 1;
              if ((waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin && unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin
              && (((summaryType === 'Downgraded' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin > unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin)
                || (summaryType === 'Upgraded' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin < unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin)
                || (summaryType === 'Reclassified' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin !== unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].softBin))))
              || (waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin && unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin
                && (((summaryType === 'Downgraded' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin > unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin)
                  || (summaryType === 'Upgraded' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin < unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin)
                  || (summaryType === 'Reclassified' && waferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin !== unchangedWaferMaps[indexesToTraverse[k]].waferMapTestData[i][j].hardBin))))) {
                count += 1;
              }
            }
          }
        }
      }
    }
    return mode === 'Count' ? count : ((count / totalCount) * 100).toFixed(2);
  };

  getOuterViewport = (i: number, size: number, activeControlWaferMapKeyIndex?: string) => {
    const relativeWaferWidth = this.WAFER_PLOTTER_CANVAS_WIDTH * this.waferMapToPlotRatio;
    const totalWaferHeightAndMarginForSelected = this.WAFER_PLOTTER_CANVAS_WIDTH * this.waferMapToPlotRatio;
    const totalWaferHeightAndMarginForGeneral = ((this.WAFER_PLOTTER_CANVAS_WIDTH * this.waferMapToPlotRatio) * this.smallerToBiggerWaferRatio);
    if ((activeControlWaferMapKeyIndex && parseInt(activeControlWaferMapKeyIndex.replace(keyIndexIdentifier, ''), 10) === i) || (activeControlWaferMapKeyIndex === undefined && i === 0)) {
      return {
        x: 0,
        y: this.WAFER_PLOTTER_CANVAS_HEIGHT - totalWaferHeightAndMarginForSelected - this.offset,
        width: relativeWaferWidth,
        height: totalWaferHeightAndMarginForSelected,
      };
    }
    let relativeWaferPosition = 0;
    if (activeControlWaferMapKeyIndex && i < parseInt(activeControlWaferMapKeyIndex.replace(keyIndexIdentifier, ''), 10)) {
      relativeWaferPosition = i + this.numberOfWafersPerRow;
    } else {
      relativeWaferPosition = i + this.numberOfWafersPerRow - 1;
    }

    return {
      x: (relativeWaferPosition % this.numberOfWafersPerRow) * ((this.WAFER_PLOTTER_CANVAS_WIDTH) / this.numberOfWafersPerRow),
      y: this.WAFER_PLOTTER_CANVAS_HEIGHT - (((Math.floor((relativeWaferPosition) / this.numberOfWafersPerRow)))
        * (totalWaferHeightAndMarginForGeneral + this.verticalMargin)) - totalWaferHeightAndMarginForSelected - this.offset,
      width: relativeWaferWidth / this.numberOfWafersPerRow,
      height: totalWaferHeightAndMarginForGeneral,
    };
  }

  getWaferData = (dies: any, colOffset: number, rowOffset: number) => {
    const rows = dies.length;
    const columns = dies[0].length;
    const dieHeightToWidthRatio = columns / rows;
    const centerX = (colOffset + dies[0].length - 1 + colOffset) / 2;
    const centerY = (rowOffset + dies.length - 1 + rowOffset) / 2;
    let maxRadius = Number.MIN_SAFE_INTEGER;

    for (let i = 0; i < dies.length; i += 1) {
      for (let j = 0; j < dies[i].length; j += 1) {
        if (dies[i][j] != null) {
          const p1 = (colOffset + j) - centerX; // horizontal dist of die from center
          const p2 = ((rowOffset + i) - centerY) * dieHeightToWidthRatio; // vertical dist of die from center
          const distanceFromCenter = Math.sqrt(p1 * p1 + p2 * p2);
          if (distanceFromCenter > maxRadius) maxRadius = distanceFromCenter;
        }
      }
    }
    return { dieHeightToWidthRatio, maxRadius };
  };

  addWaferByKeys = async (waferIndices: number[], activeControlWaferMapKeyIndex?: string) => {
    const {
      waferMaps, dieFieldValues, dieColorType, waferMapIndexMap, coordinateOffsets, waferMetaDatas, defaultAxes, notchPositions, axesFlips,
    } = this.state;
    if (this.plotter) {
      PublishSubscribe().clearAll();
      this.setState({ waferMapInstances: {} }, () => {
        waferIndices.forEach((waferIndex) => {
          waferMapIndexMap[`${keyIndexIdentifier}${waferIndex}`] = waferIndex;
          const waferData = this.getWaferData(waferMaps[waferIndex].waferMapTestData, coordinateOffsets[waferIndex].colOffset, coordinateOffsets[waferIndex].rowOffset);
          this.plotter!.addOrReplaceWafer({
            outerViewport: this.getOuterViewport(waferIndex, waferMaps.length, activeControlWaferMapKeyIndex),
            shouldUseOnlyBinColor: false,
            dies: waferMaps[waferIndex].waferMapTestData,
            keyIndex: `${keyIndexIdentifier}${waferIndex}`,
            hasMarkingFeature: true,
            hasRotationControls: true,
            hasFlippingControls: true,
            dieSize: { dieWidth: 1, dieHeight: waferData.dieHeightToWidthRatio },
            showRadar: true,
            showWaferInfo: true,
            setWaferInstanceHandler: this.setWaferInstanceHandler,
            dieTypeField: dieColorType,
            dieTextField: dieColorType,
            dieTypes: dieFieldValues,
            colOffset: coordinateOffsets[waferIndex].colOffset,
            rowOffset: coordinateOffsets[waferIndex].rowOffset,
            notchPosition: NotchPosition.DOWN,
            rotationAngle: UtilityFunctions.toRotationAngle(notchPositions[waferIndex]),
            isXAxisFlipped: axesFlips[waferIndex].x,
            isYAxisFlipped: axesFlips[waferIndex].y,
            isDefaultXAxisFlipped: defaultAxes[waferIndex].x,
            isDefaultYAxisFlipped: defaultAxes[waferIndex].y,
            waferName: waferMetaDatas[waferIndex].name,
            waferHeightToRowsRatio: ((waferData.maxRadius + (Math.sqrt((0.5 * waferData.dieHeightToWidthRatio) * (0.5 * waferData.dieHeightToWidthRatio) + (0.5 * 0.5)))) * 2)
              / Math.min(waferMaps[waferIndex].waferMapTestData.length * waferData.dieHeightToWidthRatio, waferMaps[waferIndex].waferMapTestData[0].length),
          }, undefined, activeControlWaferMapKeyIndex);
        });
      });
    }
    if (activeControlWaferMapKeyIndex) this.setState({ waferMapIndexMap });
    else this.setState({ waferMapIndexMap, controlWaferMapKey: `${keyIndexIdentifier}0` });
  };

  addWafer = async () => {
    const { waferMaps } = this.state;
    const waferIndices = [];
    if (this.plotter) {
      for (let i = 0; i < waferMaps.length; i += 1) {
        waferIndices.push(i);
      }
    }
    await this.addWaferByKeys(waferIndices);
  };

  setWaferMapConfigs = () => {
    const {
      waferMetaDatas, waferMapIndexMap, collapseTabsColumn, collapseDieSummaryColumn, controlWaferMapKey, waferMaps,
    } = this.state;

    const config : {
      [keyIndex: string]: {
        primaryComponents?: ReactNode | undefined,
        onContextMenuItemClick?: (event: any) => void,
        contextMenuDataSource?: string | Array<dxContextMenuItem> | DataSource | DataSourceOptions,
        additionalControls?: WaferAdditionalControl[],
        dieTextField?: string,
      };
    } = {};
    const waferIndices = (waferMaps.map((x:any, index:number) => index));
    Object.entries(waferMapIndexMap).forEach((waferKey: any, waferIndex: number) => {
      config[`${waferKey[0]}`] = {
        primaryComponents: waferMetaDatas[waferIndex].name,
        onContextMenuItemClick: (event: any) => {
          switch (event.itemData.action) {
            case 'expand':
              this.setState({ collapseTabsColumn: true, collapseDieSummaryColumn: true }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            case 'collapse':
              this.setState({ collapseTabsColumn: false, collapseDieSummaryColumn: false }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            case 'collapse_die_summary':
              this.setState({ collapseDieSummaryColumn: true }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            case 'collapse_definition_tabs':
              this.setState({ collapseTabsColumn: true }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            case 'expand_die_summary':
              this.setState({ collapseDieSummaryColumn: false }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            case 'expand_definition_tabs':
              this.setState({ collapseTabsColumn: false }, () => {
                this.updateWaferSize();
                this.addWaferByKeys(waferIndices, controlWaferMapKey);
              });
              break;
            default:
              break;
          }
        },
        contextMenuDataSource: [
          {
            text: 'Layout',
            items: [
              {
                text: collapseTabsColumn && collapseDieSummaryColumn ? 'Collapse Wafer' : 'Expand Wafer',
                action: collapseTabsColumn && collapseDieSummaryColumn ? 'collapse' : 'expand',
              },
              {
                text: collapseTabsColumn ? 'Expand Definition Tabs' : 'Collapse Definition Tabs',
                action: collapseTabsColumn ? 'expand_definition_tabs' : 'collapse_definition_tabs',
              },
              {
                text: collapseDieSummaryColumn ? 'Expand Die Summary' : 'Collapse Die Summary',
                action: collapseDieSummaryColumn ? 'expand_die_summary' : 'collapse_die_summary',
              },
            ],
          },
        ],
      };
    });
    return config;
  };

  handleOutputLocationCallback = (receivedOutputFiles: any) => {
    this.setState(() => {
      return { fileOutputs: receivedOutputFiles };
    });
  };

  render() {
    const {
      dieTypes, waferMaps, middleViewport, selectionCriteriaData,
      collapseTabsColumn, collapseDieSummaryColumn, softBins, waferMapInstances,
      selectedLegend, hardBins, waferMetaDatas, expressions, selectedTestParameters,
      dataSelectionTestParameters, classDefinitions, classOption, numberOfClasses, dieFieldValues, binPlusDefinitions,
      binPlusTableMap, selectedBinPlusTable, selectedWafers, fileOutputs, isProcessing, currentWaferIndex,
    } = this.state;
    let centerArea = 7;
    if (collapseTabsColumn && collapseDieSummaryColumn) centerArea = 12;
    if (!collapseTabsColumn && collapseDieSummaryColumn) centerArea = 9;
    if (collapseTabsColumn && !collapseDieSummaryColumn) centerArea = 10;

    const PickMapAMGParametersInExpressionBuilder = [
      { caption: 'Soft Bin', dataField: 'die.SoftBinNumber', dataType: 'number' },
      { caption: 'Hard Bin', dataField: 'die.HardBinNumber', dataType: 'number' },
      { caption: 'Die Passing', dataField: 'die.IsPassing', dataType: 'boolean' },
      { caption: 'X Coordinate', dataField: 'die.X', dataType: 'number' },
      { caption: 'Y Coordinate', dataField: 'die.Y', dataType: 'number' },
      { caption: 'Head Number', dataField: 'die.HeadNumber', dataType: 'number' },
      { caption: 'Site Number', dataField: 'die.SiteNumber', dataType: 'number' },
      { caption: 'Die Count', dataField: 'wafer.DieCount', dataType: 'number' },
      { caption: 'Good Count', dataField: 'wafer.GoodCount', dataType: 'number' },
      { caption: 'Retest Count', dataField: 'wafer.RetestCount', dataType: 'number' },
      { caption: 'Abort Count', dataField: 'wafer.AbortCount', dataType: 'number' },
      { caption: 'Functional Count', dataField: 'wafer.FunctionalCount', dataType: 'number' },
      { caption: 'Probe Count', dataField: 'wafer.ProbeCount', dataType: 'string' },
      { caption: 'Yield', dataField: 'wafer.Yield', dataType: 'number' },
      { caption: 'Test Temperature', dataField: 'wafer.TestTemperature', dataType: 'string' },
      { caption: 'Flip X', dataField: 'wafer.FlipX', dataType: 'boolean' },
      { caption: 'Flip Y', dataField: 'wafer.FlipY', dataType: 'boolean' },
      { caption: 'Offset X/Y', dataField: 'ApplyAxesOffset()', dataType: 'string' },
      { caption: 'Notch Position', dataField: 'wafer.NotchPosition', dataType: 'string' },
      { caption: 'MismatchedDieCount', dataField: 'wafer.MismatchedDieCount', dataType: 'number' },
      ...classDefinitions.map((x: any) => { return { caption: `TP ${x.name} class`, dataField: `die.ParameterClasses["${x.name}"]`, dataType: 'string' }; }),
    ];

    const PickMapAMGOperatorsInExpressionBuilder = [
      { caption: '=', dataField: '==' },
      { caption: '<', dataField: '<' },
      { caption: '>', dataField: '>' },
      { caption: '!=', dataField: '!=' },
    ];
    return (
      <Container fluid className="body-content-scroll amg-pick-map">
        <TopbarNav
          title="Manual AMG"
          items={[]}
          showAvatar={false}
          showNotifications={false}
        />
        <Row className="pt0 pl30 pr30 mt10">
          <Col lg={12} className="mb20 pl0 pr0">
            <SelectionCriteriaButton
              getSelectionCriteriaData={this.getSelectionCriteriaComponentData}
              widgetsList={
                [
                  {
                    controlType: 'Facility',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Work Center',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Device',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Test Program',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Test Program Revision',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Lot',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Wafer',
                    columnWeight: 3,
                    isVisible: true,
                  },
                  {
                    controlType: 'Test Parameter',
                    columnWeight: 3,
                    isVisible: true,
                    highlightOtherGrids: false,
                    minVisibilityCriteria: GeneralUtils.getMinVisibilityCriteria(),
                  },
                ]
              }
              defaultSelection={selectionCriteriaData}
            />
          </Col>
          {currentWaferIndex < waferMaps.length ? (
            <Col lg={12} className="mb20 pl0 pr0">
              <Row className="ml0 mr0 pt10 pl10 pr10 pb30 border-all background-color-light border-radius-4">
                {!collapseTabsColumn && (
                  <Col lg={3} className="pr0 pl0" style={{ border: '1px solid #ddd', height: '100vh' }}>
                    <Container style={{ borderBottom: '1px solid #ddd' }}>
                      <Row>
                        <Col className="pr1 pl1">
                          <div
                            className="custom-tab"
                            style={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Plotting Options and Parameter Settings
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <Container>
                      {this.getTabContent()}
                    </Container>
                  </Col>
                )}
                <Col lg={centerArea}>
                  { currentWaferIndex < waferMaps.length && middleViewport === MiddleViewport.WAFER
                    ? (
                      <div ref={(ref: any) => { this.waferPlotterDiv = ref; }}>
                        <WaferPlotter
                          width={this.WAFER_PLOTTER_CANVAS_WIDTH}
                          height={this.WAFER_PLOTTER_CANVAS_HEIGHT}
                          draggableControlsWidth={this.WAFER_PLOTTER_DRAGGABLE_CONTROLS_WIDTH}
                          ref={(r) => { this.plotter = r; }}
                          plotterKey={`${keyIndexIdentifier}2138`}
                          onControlSelectionChanged={(keyIndexes: string[]) => {
                            this.setState(
                              { connectedWaferMapKeys: keyIndexes },
                              () => {
                                this.waferMapKeysToApplyChanges().forEach((x: string) => {
                                  waferMapInstances[x].waferMapVariables.selectedDieField = selectedLegend.field;
                                  waferMapInstances[x].waferMapVariables.currentDieType = selectedLegend.id;
                                });
                              }
                            );
                          }}
                          onControlChanged={(keyIndex: string) => {
                            this.setState({ controlWaferMapKey: keyIndex, selectedLegend: { field: undefined, id: undefined }, connectedWaferMapKeys: [] }, () => {
                              this.addWaferByKeys((waferMaps.map((x:any, index:number) => index)), keyIndex);
                              toast.info('Wafer map control changed');
                            });
                          }}
                          config={this.setWaferMapConfigs()}
                        />
                      </div>
                    ) : null }
                  { middleViewport === MiddleViewport.DIE_TYPES
                    ? (
                      <DataGrid
                        keyExpr="id"
                        onSaved={() => { this.forceUpdate(); }}
                        columnMinWidth={100}
                        style={{ marginTop: '20px', clear: 'both', fontSize: '8px' }}
                        dataSource={dieTypes}
                        hoverStateEnabled
                        onToolbarPreparing={(e: any) => {
                          if (e.toolbarOptions.items[1]) {
                            e.toolbarOptions.items[1].locateInMenu = 'never';
                          }
                        }}
                        height="400px"
                        allowColumnReordering
                        rowAlternationEnabled
                        showColumnLines={false}
                        showRowLines={false}
                      >
                        <Scrolling mode="standard" />
                        <Column dataField="name" width={100}>
                          <RequiredRule />
                          <PatternRule
                            message="Die Name Should Not Exceed 255 Characters"
                            pattern={/^.{1,255}$/i}
                          />
                        </Column>
                        <Column
                          editCellRender={(cell: any) => {
                            return (
                              <ColorBox
                                defaultValue={cell.value}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...cell.column.lookup}
                                onValueChanged={(event: any) => { cell.setValue(event.value); }}
                              />
                            );
                          }}
                          dataField="color"
                          cellRender={(e) => {
                            if (e.data) {
                              return <div className="h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: (e.data.color) }} />;
                            }
                            return null;
                          }}
                        >
                          <RequiredRule />
                        </Column>
                        <Column
                          editCellRender={(cell: any) => {
                            return (
                              <SelectBox
                                defaultValue={cell.value}
                                items={['Y', 'N', '-']}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...cell.column.lookup}
                                onValueChanged={(event: any) => { cell.setValue(event.value); }}
                              />
                            );
                          }}
                          caption="Probed"
                          dataField="probed"
                        >
                          <RequiredRule />
                        </Column>
                        <Column
                          editCellRender={(cell: any) => {
                            return (
                              <SelectBox
                                defaultValue={cell.value}
                                items={['Y', 'N', '-']}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...cell.column.lookup}
                                onValueChanged={(event: any) => { cell.setValue(event.value); }}
                              />
                            );
                          }}
                          caption="Yielded"
                          dataField="yielded"
                        >
                          <RequiredRule />
                        </Column>
                        <Column
                          editCellRender={(cell: any) => {
                            return (
                              <SelectBox
                                defaultValue={cell.value}
                                items={['Y', 'N', '-']}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...cell.column.lookup}
                                onValueChanged={(event: any) => { cell.setValue(event.value); }}
                              />
                            );
                          }}
                          caption="Touch Down Allowed"
                          dataField="touchDownAllowed"
                        >
                          <RequiredRule />
                        </Column>
                        <Editing
                          useIcons
                          allowUpdating
                          allowDeleting
                          allowAdding
                          confirmDelete={false}
                          mode="row"
                          newRowPosition="last"
                        />
                      </DataGrid>
                    ) : null }
                  { middleViewport === MiddleViewport.BIN_PLUS
                    ? (
                      <div className="custom-form">
                        <CustomizedDataGrid
                          tableData={binPlusDefinitions}
                          height={750}
                          totalRowsToDisplay={25}
                          showFilterRow
                          showAdvancedFilters={false}
                          showGroupPanel={false}
                          enableColumnChooser={false}
                          fields={[
                            {
                              caption: 'Bin Number',
                              dataField: 'number',
                              dataType: 'number',
                            },
                            {
                              caption: 'Bin Name',
                              dataField: 'name',
                              dataType: 'string',
                            },
                            {
                              caption: 'Bin Type',
                              dataField: 'groupType',
                              dataType: 'string',
                              groupIndex: 1,
                            },
                            {
                              caption: 'Bin Plus Table Name',
                              dataField: 'binPlusTable.id',
                              dataType: 'string',
                              groupIndex: 0,
                              groupCellRender: (e: any) => {
                                return (
                                  <div>
                                    <CheckBox
                                      value={selectedBinPlusTable === e.data.key}
                                      onValueChanged={(x: any) => {
                                        this.setState({ selectedBinPlusTable: x.value ? e.data.key : undefined });
                                      }}
                                    />
                                    <span style={{ marginLeft: '5px' }}>
                                      {`Bin Plus Table Name: ${binPlusTableMap[e.data.key]}`}
                                    </span>
                                  </div>
                                );
                              },
                            },
                            {
                              caption: 'Color',
                              dataField: 'color',
                              colorField: true,
                              cellRender: (e:any) => {
                                return <div className="w20 h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: e.data.color }} />;
                              },
                            },
                            {
                              caption: 'Pass/Fail',
                              dataField: 'passFail',
                              dataType: 'string',
                            },
                            {
                              caption: 'Retest Enabled',
                              dataField: 'retestEnabled',
                              dataType: 'string',
                            },
                            {
                              caption: 'Bin Plus Table Name',
                              dataField: 'binPlusTable.name',
                              dataType: 'string',
                            },
                          ]}
                          autoExpandAll={false}
                        />
                        <Button
                          size="sm"
                          variant="primary"
                          className="mt10 mb30"
                          onClick={async () => {
                            if (!selectedBinPlusTable) return;
                            const waferSoftBins: any[] = [];
                            binPlusDefinitions.filter((x: any) => x.binPlusTable.id === selectedBinPlusTable && x.groupType === 'Softbin' && !waferSoftBins.some((y: any) => x.number === y.id))
                              .forEach((x: any) => waferSoftBins.push({
                                id: x.number,
                                name: x.name,
                                color: x.color,
                                passFail: x.passFail,
                              }));
                            const waferHardBins: any[] = [];
                            binPlusDefinitions.filter((x: any) => x.binPlusTable.id === selectedBinPlusTable && x.groupType === 'Hardbin' && !waferHardBins.some((y: any) => x.number === y.id))
                              .forEach((x: any) => waferHardBins.push({
                                id: x.number,
                                name: x.name,
                                color: x.color,
                                passFail: x.passFail,
                              }));

                            if (waferHardBins.find((x: any) => x.passFail == null) == null || waferSoftBins.find((x: any) => x.passFail == null) == null) {
                              dieFieldValues.softBin = waferSoftBins;
                              dieFieldValues.hardBin = waferHardBins;
                              this.setState({ softBins: waferSoftBins, hardBins: waferHardBins, dieFieldValues });
                              return;
                            }
                            if ((await ModalPopup.confirm({
                              header: 'Confirmation',
                              body: 'Some of the hard/soft bins from the selected bin plus table do not contain pass/fail values. Do you want to proceed?',
                            }))) {
                              dieFieldValues.softBin = waferSoftBins;
                              dieFieldValues.hardBin = waferHardBins;
                              this.setState({ softBins: waferSoftBins, hardBins: waferHardBins, dieFieldValues });
                            }
                          }}
                        >
                          Add Bin+ Definitions
                        </Button>
                      </div>
                    ) : null }
                  { middleViewport === MiddleViewport.OUTPUT_SETTINGS
                    ? (
                      <OutputLocation
                        onUpdate={this.handleOutputLocationCallback}
                        fileOutputs={fileOutputs}
                        locationTypes={['Cloud Container', 'FTP', 'Local']}
                      />
                    ) : null }
                  { middleViewport === MiddleViewport.EXPRESSIONS
                    ? (
                      <ExpressionBuilder
                        defaultStatements={expressions}
                        isInputUOMDisabled
                        isOutputUOMDisabled
                        onUpdate={(e) => {
                          this.setState({ expressions: e });
                        }}
                        rules={{
                          objects: PickMapAMGParametersInExpressionBuilder,
                          operators: PickMapAMGOperatorsInExpressionBuilder,
                          typeOperations: [
                            { dataType: 'string', operators: ['==', '!='] },
                            { dataType: 'string', operators: ['', ''] },
                          ],
                        }}
                      />
                    ) : null }
                  { middleViewport === MiddleViewport.PARAMETER_SELECTION ? (
                    <div className="custom-form">
                      <CustomizedDataGrid
                        tableData={dataSelectionTestParameters}
                        height={250}
                        showFilterRow={false}
                        showAdvancedFilters={false}
                        showGroupPanel={false}
                        enableColumnChooser={false}
                        selectedRowKeys={selectedTestParameters}
                        onRowReorder={(e: any) => {
                          const reorderedSelectedParams = e.filter((x: any) => selectedTestParameters.some((y: any) => y.id === x.id));
                          this.setState({ dataSelectionTestParameters: e, selectedTestParameters: reorderedSelectedParams });
                        }}
                        onRowSelection={(e: any) => {
                          this.setState({ selectedTestParameters: e.selectedRowKeys });
                        }}
                        fields={[
                          {
                            caption: 'Test Number',
                            dataField: 'number',
                            dataType: 'number',
                          },
                          {
                            caption: 'Test Name',
                            dataField: 'name',
                            dataType: 'string',
                          },
                          {
                            caption: 'UOM',
                            dataField: 'testUnit',
                            dataType: 'string',
                          },
                          {
                            caption: 'Low Limit',
                            dataField: 'lowSpecLimit',
                            dataType: 'number',
                          },
                          {
                            caption: 'High Limit',
                            dataField: 'lowHighLimit',
                            dataType: 'number',
                          },
                        ]}
                        selectionMode="multiple"
                        allowRowReorder
                      />
                      <br />
                      <Row>
                        <Col>
                          <Label
                            labelTitle="Class Option"
                            labelPosition="left-middle"
                            labelSize="50"
                            fieldSize="50"
                            errorSize="100"
                            isFieldTouched
                            className="mb5"
                          >
                            <CustomizedDropdown
                              variant="clear"
                              full
                              selectedValue={classOption}
                              list={[['SingleParameter', 'Single-Parameter'], ['MultiParameters', 'Multi-Parameter']]}
                              onChange={(e: any) => this.setState({ classOption: e })}
                            />
                          </Label>
                        </Col>
                        <Col>
                          <Label
                            labelTitle="# of Classes"
                            labelPosition="left-middle"
                            labelSize="50"
                            fieldSize="50"
                            errorSize="100"
                            isFieldTouched
                            className="mb5"
                          >
                            <Textbox
                              placeholder="10"
                              name="classesTexBox"
                              type="number"
                              value={numberOfClasses}
                              onChange={(e: any) => this.setState({ numberOfClasses: +e.target.value })}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label
                            labelTitle="Outlier Screening Limit"
                            labelPosition="left-middle"
                            labelSize="50"
                            fieldSize="50"
                            errorSize="100"
                            isFieldTouched
                            className="mb5"
                          >
                            <CustomizedDropdown
                              variant="clear"
                              full
                              list={[['none', 'None']]}
                            />
                          </Label>
                        </Col>
                        <Col>
                          <Label
                            labelTitle="Class Type"
                            labelPosition="left-middle"
                            labelSize="50"
                            fieldSize="50"
                            errorSize="100"
                            isFieldTouched
                            className="mb5"
                          >
                            <CustomizedDropdown
                              variant="clear"
                              full
                              list={[['none', 'User Defined']]}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Button
                        size="sm"
                        variant="primary"
                        className="mt10 mb30"
                        onClick={() => {
                          const localClassDefinitions : any[] = [];
                          if (classOption === 'SingleParameter') {
                            selectedTestParameters.forEach((x: any) => {
                              const classDefinition = { columns: [] as any[], rows: [] as any[], name: x.name };
                              classDefinition.columns.push({
                                caption: 'Class Sequence',
                                dataField: 'classSequence',
                                dataType: 'string',
                              });
                              classDefinition.columns.push({
                                caption: x.name,
                                fields: [
                                  {
                                    caption: 'Min',
                                    dataField: 'min',
                                    dataType: 'number',
                                  },
                                  {
                                    caption: 'Max',
                                    dataField: 'max',
                                    dataType: 'number',
                                  },
                                ],
                              });
                              classDefinition.columns.push({
                                caption: 'Class Color',
                                dataField: 'color',
                                cellRender: (e:any) => {
                                  return <div className="w20 h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: e.data.color }} />;
                                },
                                editCellRender: (cell:any) => {
                                  return (
                                    <ColorBox
                                      defaultValue={cell.value}
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...cell.column.lookup}
                                      onValueChanged={(event: any) => { cell.setValue(event.value); }}
                                    />
                                  );
                                },
                              });
                              for (let i = 0; i < numberOfClasses; i += 1) {
                                classDefinition.rows.push({ classSequence: `Class ${i + 1}`, id: i });
                              }
                              localClassDefinitions.push(classDefinition);
                            });
                          } else {
                            const classDefinition = { columns: [] as any[], rows: [] as any[], name: 'Multi-Parameter Class' };
                            classDefinition.columns.push({
                              caption: 'Class Sequence',
                              dataField: 'classSequence',
                              dataType: 'string',
                            });
                            selectedTestParameters.forEach((x: any, index: number) => {
                              if (index > 0) {
                                classDefinition.columns.push({
                                  caption: 'Operator',
                                  dataField: `operator_${index}`,
                                  editCellRender: (cell: any) => {
                                    return (
                                      <SelectBox
                                        defaultValue={cell.value}
                                        displayExpr="dataField"
                                        valueExpr="dataField"
                                        items={[{ dataField: 'and' }, { dataField: 'or' }]}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...cell.column.lookup}
                                        /* eslint-disable no-param-reassign */
                                        onValueChanged={(e: any) => {
                                          cell.setValue(e.value);
                                        }}
                                      />
                                    );
                                  },
                                });
                              }
                              classDefinition.columns.push({
                                caption: x.name,
                                fields: [
                                  {
                                    caption: 'Min',
                                    dataField: `min_${index}`,
                                    dataType: 'number',
                                  },
                                  {
                                    caption: 'Max',
                                    dataField: `max_${index}`,
                                    dataType: 'number',
                                  },
                                ],
                              });
                            });
                            classDefinition.columns.push({
                              caption: 'Class Color',
                              dataField: 'color',
                              cellRender: (e:any) => {
                                return <div className="w20 h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: e.data.color }} />;
                              },
                              editCellRender: (cell:any) => {
                                return (
                                  <ColorBox
                                    defaultValue={cell.value}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...cell.column.lookup}
                                    onValueChanged={(event: any) => { cell.setValue(event.value); }}
                                  />
                                );
                              },
                            });
                            for (let i = 0; i < numberOfClasses; i += 1) {
                              classDefinition.rows.push({ classSequence: `Class ${i + 1}`, id: i });
                            }
                            localClassDefinitions.push(classDefinition);
                          }
                          this.setState({ classDefinitions: localClassDefinitions });
                        }}
                      >
                        Generate Classes
                      </Button>
                      {classDefinitions.map((definition: any, index: number) => {
                        return (
                          <div className="mb10">
                            <CustomizedDataGrid
                              showAdvancedFilters={false}
                              showFilterRow={false}
                              showGroupPanel={false}
                              showColumnLines
                              showRowLines
                              height={200}
                              enableColumnChooser={false}
                              fields={definition.columns}
                              tableData={definition.rows}
                              allowUpdating
                              onRowUpdated={(e: any) => {
                                classDefinitions[index].rows[e.data.id] = e.data;
                                this.setState({ classDefinitions });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  { middleViewport === MiddleViewport.WAFER_SELECTION ? (
                    <div className="custom-form">
                      <CustomizedDataGrid
                        tableData={waferMetaDatas}
                        height={250}
                        showFilterRow={false}
                        showAdvancedFilters={false}
                        showGroupPanel={false}
                        enableColumnChooser={false}
                        selectedRowKeys={selectedWafers}
                        onRowSelection={(e: any) => {
                          this.setState({ selectedWafers: e.selectedRowKeys });
                        }}
                        fields={[
                          {
                            caption: 'Wafer Id',
                            dataField: 'id',
                            dataType: 'string',
                          },
                          {
                            caption: 'Wafer Name',
                            dataField: 'name',
                            dataType: 'string',
                          },
                        ]}
                        selectionMode="multiple"
                      />
                      <br />
                      <Button
                        size="sm"
                        variant="primary"
                        className="mt10 mb30"
                        onClick={() => this.applyToWafer(selectedWafers.map((x: any) => x.id))}
                      >
                        Apply
                      </Button>
                      {classDefinitions.map((definition: any, index: number) => {
                        return (
                          <div className="mb10">
                            <CustomizedDataGrid
                              showAdvancedFilters={false}
                              showFilterRow={false}
                              showGroupPanel={false}
                              showColumnLines
                              showRowLines
                              height={200}
                              enableColumnChooser={false}
                              fields={definition.columns}
                              tableData={definition.rows}
                              allowUpdating
                              onRowUpdated={(e: any) => {
                                classDefinitions[index].rows[e.data.id] = e.data;
                                this.setState({ classDefinitions });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </Col>
                {!collapseDieSummaryColumn && (
                  <Col lg={2} style={{ border: '1px solid #ddd' }}>
                    <Heading size={5} className="mt20 text-center">Legend</Heading>
                    <hr />
                    {classDefinitions.map((definition: any) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key="bin1"
                          className="pl5 pt5"
                          role="button"
                          style={{ fontSize: '12px', backgroundColor: '#fff' }}
                        >
                          <Heading size={5}>{definition.name}</Heading>
                          <div>
                            <p className="mb0 mr30" style={this.styles.inlineBlock}><strong>Name</strong></p>
                            <p className="mb0 mr10" style={this.styles.inlineBlock}><strong>Min</strong></p>
                            <p className="mb0 mr10" style={this.styles.inlineBlock}><strong>Max</strong></p>
                            <p className="mb0 mr10" style={this.styles.inlineBlock}><strong>Count</strong></p>
                            <p className="mb0" style={this.styles.inlineBlock}><strong>Percentage</strong></p>
                          </div>
                          {definition.rows.map((row: any) => {
                            return (
                              <div>
                                <div className="h10 mr10 mb0 w20" style={{ backgroundColor: row.color, display: inlineBlockStyle }} />
                                <p className="mb0 w25pc mr10" style={this.styles.inlineBlock}>{row.classSequence}</p>
                                <p className="mb0 mr10" style={this.styles.inlineBlock}>{row.min}</p>
                                <p className="mb0 mr10" style={this.styles.inlineBlock}>{row.max}</p>
                                <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount(definition.name, row.classSequence)}</p>
                                <p className="mb0" style={this.styles.inlineBlock}>
                                  {this.getDieCount(definition.name, row.classSequence, 'Percentage')}
                                  {' '}
                                  %
                                </p>
                              </div>
                            );
                          })}
                          <hr />
                        </div>
                      );
                    })}
                    <Heading size={5}>Pass/Fail</Heading>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key="bin1"
                      className="pl5 pt5"
                      role="button"
                      onClick={() => {
                        this.setState({ selectedLegend: { field: 'passFailFlag', id: 'PASS' } });
                        this.waferMapKeysToApplyChanges().forEach((x: string) => {
                          waferMapInstances[x].waferMapVariables.selectedDieField = 'passFailFlag';
                          waferMapInstances[x].waferMapVariables.currentDieType = 'PASS';
                        });
                      }}
                      style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'passFailFlag' && selectedLegend.id === 'PASS' ? '#ddd' : '#fff' }}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#55db47', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Pass</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('passFailFlag', 'PASS')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getDieCount('passFailFlag', 'PASS', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key="bin1"
                      className="pl5 pt5"
                      role="button"
                      onClick={() => {
                        this.setState({ selectedLegend: { field: 'passFailFlag', id: 'FAIL' } });
                        this.waferMapKeysToApplyChanges().forEach((x: string) => {
                          waferMapInstances[x].waferMapVariables.selectedDieField = 'passFailFlag';
                          waferMapInstances[x].waferMapVariables.currentDieType = 'FAIL';
                        });
                      }}
                      style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'passFailFlag' && selectedLegend.id === 'FAIL' ? '#ddd' : '#fff' }}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#fa0303', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Fail</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('passFailFlag', 'FAIL')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getDieCount('passFailFlag', 'FAIL', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    <hr />
                    <Heading size={5}>Soft Bins</Heading>
                    {softBins.map((bin: any) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key="bin1"
                          className="pl5 pt5"
                          role="button"
                          onClick={() => {
                            this.setState({ selectedLegend: { field: 'softBin', id: bin.id } });
                            this.waferMapKeysToApplyChanges().forEach((x: string) => {
                              waferMapInstances[x].waferMapVariables.selectedDieField = 'softBin';
                              waferMapInstances[x].waferMapVariables.currentDieType = bin.id;
                            });
                          }}
                          style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'softBin' && selectedLegend.id === bin.id ? '#ddd' : '#fff' }}
                        >
                          <div className="h10 mr10 mb0 w20" style={{ backgroundColor: bin.color, display: inlineBlockStyle }} />
                          <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>{`${bin.id} ${bin.name}`}</p>
                          <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('softBin', bin.id)}</p>
                          <p className="mb0" style={this.styles.inlineBlock}>
                            {this.getDieCount('softBin', bin.id, 'Percentage')}
                            {' '}
                            %
                          </p>
                        </div>
                      );
                    })}
                    <hr />
                    <Heading size={5}>Hard Bins</Heading>
                    {hardBins.map((bin: any) => {
                      return (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key="bin1"
                          className="pl5 pt5"
                          role="button"
                          onClick={() => {
                            this.setState({ selectedLegend: { field: 'hardBin', id: bin.id } });
                            this.waferMapKeysToApplyChanges().forEach((x: string) => {
                              waferMapInstances[x].waferMapVariables.selectedDieField = 'hardBin';
                              waferMapInstances[x].waferMapVariables.currentDieType = bin.id;
                            });
                          }}
                          style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'hardBin' && selectedLegend.id === bin.id ? '#ddd' : '#fff' }}
                        >
                          <div className="h10 mr10 mb0 w20" style={{ backgroundColor: bin.color, display: inlineBlockStyle }} />
                          <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>{`${bin.id} ${bin.name}`}</p>
                          <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('hardBin', bin.id)}</p>
                          <p className="mb0" style={this.styles.inlineBlock}>
                            {this.getDieCount('hardBin', bin.id, 'Percentage')}
                            {' '}
                            %
                          </p>
                        </div>
                      );
                    })}
                    <hr />
                    {/* <Heading size={5}>Die Types</Heading> */}
                    {/* {dieTypes.map((dieType: any) => { */}
                    {/*  return ( */}
                    {/*    <div */}
                    {/*      // eslint-disable-next-line react/no-array-index-key */}
                    {/*      key="bin1" */}
                    {/*      className="pl5 pt5" */}
                    {/*      role="button" */}
                    {/*      onClick={() => { */}
                    {/*        this.setState({ selectedLegend: { field: 'dieType', id: dieType.id } }); */}
                    {/*        controlIndexes.forEach((x: string) => { */}
                    {/*          waferMapInstances[x].waferMapVariables.selectedDieField = 'dieType'; */}
                    {/*          waferMapInstances[x].waferMapVariables.currentDieType = dieType.id; */}
                    {/*        }); */}
                    {/*      }} */}
                    {/*      style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'dieType' && selectedLegend.id === dieType.id ? '#ddd' : '#fff' }} */}
                    {/*    > */}
                    {/*      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: dieType.color, display: inlineBlockStyle }} /> */}
                    {/*      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>{dieType.name}</p> */}
                    {/*      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('dieType', dieType.id)}</p> */}
                    {/*      <p className="mb0" style={this.styles.inlineBlock}> */}
                    {/*        {this.getDieCount('dieType', dieType.id, 'Percentage')} */}
                    {/*        {' '} */}
                    {/*        % */}
                    {/*      </p> */}
                    {/*    </div> */}
                    {/*  ); */}
                    {/* })} */}
                    {/* <hr /> */}
                    <Heading size={5}>Die Summary</Heading>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key="Downgraded"
                      className="pl5 pt5"
                      role="button"
                      style={this.styles.dieSummaries}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#c740c9', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Downgraded</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getSummaryCount('Downgraded')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getSummaryCount('Downgraded', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key="Upgraded"
                      className="pl5 pt5"
                      role="button"
                      style={this.styles.dieSummaries}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#6bdb3c', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Upgraded</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getSummaryCount('Upgraded')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getSummaryCount('Upgraded', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      key="Reclassified"
                      className="pl5 pt5"
                      role="button"
                      style={this.styles.dieSummaries}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#1f52aa', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Reclassified</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getSummaryCount('Reclassified')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getSummaryCount('Reclassified', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key="assembled"
                      className="pl5 pt5"
                      role="button"
                      style={{ fontSize: '12px', backgroundColor: selectedLegend.field === 'assembled' ? '#ddd' : '#fff' }}
                    >
                      <div className="h10 mr10 mb0 w20" style={{ backgroundColor: '#f5d36c', display: inlineBlockStyle }} />
                      <p className="mb0 w100 mr10" style={this.styles.inlineBlock}>Assembled</p>
                      <p className="mb0 mr20" style={this.styles.inlineBlock}>{this.getDieCount('assembled', 'true')}</p>
                      <p className="mb0" style={this.styles.inlineBlock}>
                        {this.getDieCount('assembled', 'true', 'Percentage')}
                        {' '}
                        %
                      </p>
                    </div>
                    <hr />
                    {/* <div className="pl5 pt5"> */}
                    {/*  <p className="mb0 w150" style={this.styles.inlineBlock}>Total Die Count</p> */}
                    {/*  <p className="mb0" style={this.styles.inlineBlock}>10</p> */}
                    {/* </div> */}
                    {/* <div className="pl5 pt5"> */}
                    {/*  <p className="mb0 w150" style={this.styles.inlineBlock}>Selected Die Count</p> */}
                    {/*  <p className="mb0" style={this.styles.inlineBlock}>1</p> */}
                    {/* </div> */}
                    {/* <div className="pl5 pt5"> */}
                    {/*  <p className="mb0 w150" style={this.styles.inlineBlock}>Total Good Die</p> */}
                    {/*  <p className="mb0" style={this.styles.inlineBlock}>15</p> */}
                    {/* </div> */}
                    {/* <div className="pl5 pt5"> */}
                    {/*  <p className="mb0 w150" style={this.styles.inlineBlock}>Delta(Total Good Die -  Total Revised)</p> */}
                    {/*  <p className="mb0" style={this.styles.inlineBlock}>2</p> */}
                    {/* </div> */}
                    <Row className="d-flex mt10 mb10 ml-auto mr-auto">
                      <Button
                        size="sm"
                        variant="primary"
                        full
                        disabled
                        onClick={() => this.setState({ middleViewport: middleViewport === MiddleViewport.DIE_TYPES ? MiddleViewport.WAFER : MiddleViewport.DIE_TYPES }, this.addWafer)}
                      >
                        Define Die Types
                      </Button>
                    </Row>
                    <Row className="d-flex mt10 mb10 ml-auto mr-auto">
                      <Button
                        size="sm"
                        variant="primary"
                        full
                        onClick={() => this.setState({ middleViewport: middleViewport === MiddleViewport.BIN_PLUS ? MiddleViewport.WAFER : MiddleViewport.BIN_PLUS }, this.addWafer)}
                      >
                        Select Bin Plus Tables
                      </Button>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          ) : isProcessing
            ? (
              <div className="h180 w-100 d-flex align-items-center justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div className="h180 w-100 d-flex align-items-center justify-content-center">
                Select some wafers to get started.
              </div>
            )}
        </Row>
      </Container>
    );
  }
}
export default AMGPickMap;
