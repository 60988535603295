import React from 'react';
import DropDownBox from 'devextreme-react/ui/drop-down-box';
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Scrolling,
} from 'devextreme-react/data-grid';
import { toast } from 'react-toastify';
import dataGridConstants from 'dataGridConstants';
import getDropDownOptions from 'components/getDropDownOptions';
import Button from '../../../../wrapped-component/hint-controls/Button';

class SPCParameterSettingsDropdown extends React.Component<any, any> {
  dropDownBoxRef: any;

  gridDataSource: any;

  constructor(props: any) {
    super(props);
    this.gridDataSource = props.data.column.lookup.dataSource;
    this.state = {
      gridBoxValue: props.data.value || [],
      filter: this.getFilter(props.data.value),
    };
    this.dropDownBoxRef = React.createRef();
  }

  getFilter = (selectedSPCParameters: any) => {
    const localFilter = {
      measurePointBy: '',
      groupBy: '',
      sortBy: '',
    };
    if (selectedSPCParameters && selectedSPCParameters.length > 0) {
      localFilter.measurePointBy = selectedSPCParameters[0].measurePointBy;
      localFilter.groupBy = selectedSPCParameters[0].groupBy;
      localFilter.sortBy = selectedSPCParameters[0].sortBy;
    }
    return localFilter;
  };

  dataGridRender = () => {
    const { filter, gridBoxValue } = this.state;
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div flex-container>
        <DataGrid
          {...dataGridConstants.defaultOptions}
          height="auto"
          dataSource={
            filter.measurePointBy
              ? this.gridDataSource.filter(
                (el: any) => el.measurePointBy === filter.measurePointBy
                    && el.sortBy === filter.sortBy
                    && el.groupBy === filter.groupBy
              )
              : this.gridDataSource
          }
          selectedRowKeys={gridBoxValue}
          onSelectionChanged={this.dataGridOnSelectionChanged}
        >
          <Selection mode="multiple" />
          <Scrolling {...dataGridConstants.scrollingOptions} />
          <FilterRow visible={false} />
          <Column caption="ID" dataField="serialNumber" />
          <Column caption="Parameter Type" dataField="parameterType" />
          <Column caption="Parameter/Bin" dataField="parameterOrBin" />
          <Column caption="Data Limit Type" dataField="dataLimitType" />
          <Column caption="UOM" dataField="uom" />
          <Column caption="Low Limit" dataField="lowLimit" />
          <Column caption="High Limit" dataField="highLimit" />
          <Column caption="Measured Value" dataField="measuredValue" />
          <Column caption="Measure Point By" dataField="measurePointBy" />
          <Column caption="Group By" dataField="groupBy" />
          <Column caption="Sort By" dataField="sortBy" />
          <Column
            caption="Trigger Limit Violation"
            dataField="triggerLimitViolation"
          />
          <Column
            caption="Rules Applied"
            dataField="rulesApplied"
            calculateDisplayValue={(rowData: any) => rowData.rulesApplied
              .map((x: any) => `${x.spcRuleType}_${x.spcRuleName}`)
              .join('-> ')}
          />
          <Column caption="Actions" dataField="actions" />
          <Column
            caption="Min. Points for Seed Limit"
            dataField="pointsForSeedLimit"
          />
          <Column caption="Limit Recalculation Cycle">
            <Column caption="(#)" dataField="LRCDurationValue" />
            <Column caption="(Duration)" dataField="LRCDurationType" />
            <Column caption="(Expression)" dataField="LRCExpression" />
            <Column caption="(UOM)" dataField="LRCuom" />
            <Column caption="(Operator)" dataField="LRCOperator" />
            <Column caption="(Value)" dataField="LRCValue" />
          </Column>
        </DataGrid>
        <div>
          <Button
            variant="primary float-right mt10"
            onClick={this.sendToParent}
          >
            Apply & Close
          </Button>
        </div>
      </div>
    );
  };

  syncDataGridSelection = (e: any) => {
    this.setState({
      gridBoxValue: e.value || [],
    });
  };

  dataGridOnSelectionChanged = (e: any) => {
    this.setState({
      gridBoxValue: (e.selectedRowKeys.length && e.selectedRowKeys) || [],
      filter: this.getFilter(e.selectedRowsData),
    });
  };

  sendToParent = () => {
    const { gridBoxValue } = this.state;
    const { data } = this.props;
    if (gridBoxValue.length === 0) {
      toast.warning('Please select at least one SPC parameter');
      return;
    }
    data.setValue(gridBoxValue);
    this.dropDownBoxRef.current.instance.close();
  };

  render() {
    const { gridBoxValue } = this.state;
    const { data } = this.props;

    return (
      <DropDownBox
        className="flex-container"
        ref={this.dropDownBoxRef}
        dropDownOptions={getDropDownOptions('cell', data, [{ width: '80%' }])}
        showDropDownButton
        value={gridBoxValue}
        deferRendering={false}
        displayExpr="serialNumber"
        valueExpr="id"
        placeholder="Select..."
        showClearButton={false}
        dataSource={this.gridDataSource}
        onValueChanged={this.syncDataGridSelection}
        contentRender={this.dataGridRender}
        displayValueFormatter={(e: any) => (gridBoxValue.length === 0
          ? e.value
          : gridBoxValue.map((x: any) => x.serialNumber).join(', '))}
      />
    );
  }
}
export default SPCParameterSettingsDropdown;
