import React, { useEffect, useRef, useState } from 'react';
import './CodeEditor.scss';
import { List } from 'devextreme-react/list';
import * as CaretCoordinates from 'textarea-caret';
import { TTypeAheadValuesType } from 'interfaces';
import Textarea from '../../wrapped-component/hint-controls/Textarea';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import { ErrorLabel } from '../../template-component/form-error-label/ErrorLabel';

interface ITypeAheadGroupValues {
  key: string;
  items: string[];
}
interface ITypeAheadGrid {
  fields: any;
  datasource: any;
  filterValue: string;
}

interface ITypeAheadValues {
  type: TTypeAheadValuesType;
  values: string[] | ITypeAheadGroupValues[] | ITypeAheadGrid;
}

interface IMatchingCriteria {
  regex: RegExp;
  values: ITypeAheadValues;
}

interface ICodeEditorProps {
  placeHolder: string;
  defaultValue?: string;
  updateOnBlur?: (value: string) => void;
  updateOnChange?: (value:string) => void;
  matchingCriterion: IMatchingCriteria[];
  rows?: number;
  value?: string;
  errorSize?: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  error?: string;
  editorClassName?: string | undefined;
  disabled?: boolean;
}

const CodeEditor = (props: ICodeEditorProps) => {
  const {
    placeHolder,
    defaultValue,
    matchingCriterion,
    rows,
    value,
    updateOnBlur,
    updateOnChange,
    error,
    errorSize,
    editorClassName,
    disabled,
  } = props;
  const textareaRef = useRef(null);
  const divRef = useRef(null);
  const alphaNumericregex = /[A-Za-z0-9]/;
  const [textValue, setTextValue] = useState(defaultValue);
  const [passingCriteria, setPassingCriteria] = useState(null as unknown as IMatchingCriteria);
  const [initialValue, setInitialValue] = useState('');
  const [index, setIndex] = useState(0 as number);
  const [isFocused, setIsFocused] = useState(false as boolean);
  const emptyTypeAheadGroup: ITypeAheadValues = { type: 'list', values: [] };
  const subFilterList = (list: string[], subString: string) => list.filter((x: any) => x?.toUpperCase()?.includes(((subString === undefined) ? '' : subString).toUpperCase()));
  const [list, setList] = useState({ type: 'list', values: [] } as ITypeAheadValues);

  const onValueSelect = (text: string) => {
    setPassingCriteria(null as unknown as IMatchingCriteria);
    setList(emptyTypeAheadGroup);
    setIsFocused(false);
    setTextValue(initialValue?.slice(0, index) + text + initialValue?.slice(index, initialValue.length));
    if (updateOnChange !== undefined) {
      updateOnChange(initialValue?.slice(0, index) + text + initialValue?.slice(index, initialValue.length));
    }
  };
  const filteredGroupList: ITypeAheadGroupValues[] = [];

  useEffect(() => {
    setTextValue(defaultValue);
  }, [defaultValue]);

  function updateTypeAheadGroupedValues(subString: string) {
    (passingCriteria.values.values as ITypeAheadGroupValues[]).forEach((x: ITypeAheadGroupValues) => {
      const filterValues = subFilterList(x.items as string[], subString);
      if (filterValues && filterValues.length > 0) {
        filteredGroupList.push({
          key: x.key,
          items: filterValues,
        });
      }
    });
    setList({ type: 'groupedList', values: filteredGroupList });
  }

  function updateTypeAheadGridValues(subString: string) {
    const typeAheadGrid = (passingCriteria.values.values as ITypeAheadGrid);
    setList({
      type: 'grid',
      values: {
        fields: typeAheadGrid.fields,
        filterValue: typeAheadGrid.filterValue,
        datasource: typeAheadGrid.datasource.filter((x: any) => x[typeAheadGrid.filterValue].toLowerCase().includes(subString.toLowerCase())),
      },
    });
  }

  const updateList = (value: string, selectionStart: number, matchingCriteria: IMatchingCriteria | undefined) => {
    if (matchingCriteria) {
      setPassingCriteria(matchingCriteria);
      setList(matchingCriteria.values);
    } else if (value !== '' && passingCriteria) {
      const subString = value.slice(index, selectionStart);
      if (passingCriteria.values.type === 'list' || passingCriteria.values.type === 'groupedList' || passingCriteria.values.type === 'grid') {
        if (passingCriteria.values.type === 'list') {
          setList({ type: 'list', values: subFilterList(passingCriteria.values.values as string[], subString) });
        } else if (passingCriteria.values.type === 'groupedList') {
          updateTypeAheadGroupedValues(subString);
        } else if (passingCriteria.values.type === 'grid') {
          updateTypeAheadGridValues(subString);
        }
        if (index > selectionStart) {
          if ((subString && subString !== '' && !alphaNumericregex.test(subString)) || ((!subString || subString === '')
              && !(matchingCriterion.find((criteria) => criteria.regex.test(value.slice(0, index - 1)))))) {
            setPassingCriteria(null as unknown as IMatchingCriteria);
            setList(emptyTypeAheadGroup);
          }
        }
      }
    } else {
      setPassingCriteria(null as unknown as IMatchingCriteria);
      setList({ type: 'list', values: [] });
    }
  };
  const updatePosition = () => {
    const coordinates = CaretCoordinates(textareaRef.current, (textareaRef.current as any).selectionEnd);
    (divRef.current! as any).style.left = `${(coordinates.left + (textareaRef.current! as HTMLElement).offsetLeft).toString()}px`;
    if (window.innerHeight
        < (textareaRef.current as any).getBoundingClientRect().top + (divRef.current as any).getBoundingClientRect().height) {
      (divRef.current! as any).style.top = `${((textareaRef.current as any).offsetTop
          - (divRef.current as any).getBoundingClientRect().height + coordinates.height)}px`;
    } else {
      (divRef.current! as any).style.top = `${(coordinates.top + (textareaRef.current! as HTMLElement).offsetTop + coordinates.height).toString()}px`;
    }
  };
  const onInputEvent = (event: any) => {
    updatePosition();
    const { value, selectionStart } = event.target;
    const matchingCriteria = matchingCriterion.find((criteria) => criteria.regex.test(value.slice(0, selectionStart)));
    if ((matchingCriteria && matchingCriteria.regex)) {
      setInitialValue(value);
      const object = matchingCriteria.regex.exec(value.slice(0, selectionStart));
      const object1 = value.slice(0, selectionStart).match(matchingCriteria.regex)![0];
      if (object && object1) {
        const indexx = object?.index + object1.length;
        setIndex(indexx);
      }
    }
    updateList(value, selectionStart, matchingCriteria);
    setTextValue(value);
  };

  const [userStillTyping, updateUserStillTyping] = useState<any>(null);

  return (
    <div>
      <div>
        <Textarea
          disabled={disabled === undefined ? false : disabled}
          ref={textareaRef}
          rows={rows!}
          className={`code ${editorClassName || ''}`}
          onFocusOut={(event: any) => {
            if (updateOnBlur !== undefined) {
              updateOnBlur(event.target.value);
            }
            if (!isFocused) {
              setList(emptyTypeAheadGroup);
              setPassingCriteria(null as unknown as IMatchingCriteria);
            }
          }}
          onChange={() => {
            if (userStillTyping !== null) {
              clearTimeout(userStillTyping!);
            }
            updateUserStillTyping(setTimeout(() => {
              if (updateOnChange !== undefined && textareaRef.current !== null) {
                updateOnChange((textareaRef.current as any).value);
              }
            }, 1000));
          }}
          name="code-editor"
          placeholder={placeHolder}
          // defaultValue={textValue}
          defaultValue={textValue}
          onInput={onInputEvent}
          onClick={onInputEvent}
          onSelect={onInputEvent}
        />
      </div>
      { error !== '' && (
        <ErrorLabel
          className=" pl10 mt-4 "
          size={errorSize}
          error={error}
          isTouched
        />
      )}
      <div className={`type-ahead-results ${list.type === 'grid' ? 'type-ahead-results-grid' : list.type === 'list' ? 'type-ahead-results-list' : 'type-ahead-results-multi-list'}`} ref={divRef}>
        {list.type === 'list' && (list.values as string[]).length > 0 && (
          <div className="table table-bordered m0" onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
            <List dataSource={list.values as string[]} onItemClick={(event: any) => onValueSelect(event.itemData)} />
          </div>
        )}
        {list.type === 'groupedList' && (list.values as ITypeAheadGroupValues[]).length > 0 && (
          <div className="table table-bordered m0" onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
            <List dataSource={list.values as any[]} grouped collapsibleGroups onItemClick={(event: any) => onValueSelect(event.itemData.text)} />
          </div>
        )}
        {list.type === 'grid' && (list.values as ITypeAheadGrid).datasource.length > 0 && (
          <div className="table table-bordered m0" onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
            <CustomizedDataGrid
              fields={(list.values as ITypeAheadGrid).fields}
              tableData={(list.values as ITypeAheadGrid).datasource}
              onRowSelection={(event: any) => onValueSelect(event.selectedRowKeys)}
              keyExpr={(list.values as ITypeAheadGrid).filterValue}
              showAdvancedFilters={false}
              enableColumnChooser={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
CodeEditor.defaultProps = {
  defaultValue: '',
  rows: 3,
  error: '',
  errorSize: '20',
};
export default CodeEditor;
