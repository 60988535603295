/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import toast from 'CustomToast';
import { ILimitSettingsObj } from 'components/wrapped-component/limit-settings-modal/limitSettingsModal';
import _ from 'lodash';
import {
  InteractionInputDTO, ICombinedGraphData, AggregateFunctionNames, SeriesTypeNames, ICombinedGraphDataWithReportHeaders,
} from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import '../../customized-reports.scss';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { IFieldValue } from '../../../../../components/utility-component/report-headers/ReportHeaderBuilder';
import { ILineDict } from '../action-items-sheet/ActionItemsSheet';
import TestParameterBadges from '../../customized-report-utils/TestParameterBadges';
import ReportOptionsModal from '../../customized-report-utils/ReportOptionsModal';
import UtilityOptions from '../../customized-report-utils/UtilityOptions';
import ReportHorizontalScrollView from '../../customized-report-utils/ReportHorizontalScrollView';

export class ParametricXYScatterPlotGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: false,
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    graphType: 'scattergl' as IGraphType,
    graphMode: 'markers' as IGraphMode,
    markerOpacity: 0.9,
    markerBorderOpacity: 0,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: false,
    autoLoad: true,
    repeatMarkerColor: true,
    showYAxisBreaks: true,
    showXAxisBreaks: true,
    allowDefaultGrouping: true,
    extractSelectedValuesFromToolTips: false,
    showAddedMarkers: false,
    textOnHover: [['']],
    hoverTemplate: {
      grouping: '<b>Entity: %{text}</b>'
     + '<br>(%{x}, %{y})',
      noGrouping: '<b>Entity: %{text}</b>'
      + '<br>(%{x}, %{y})',
    },
    showAddedLines: {
      grouping: {
        show: false,
        name: '',
      },
      noGrouping: {
        show: false,
        name: '',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'DIE_ID',
      avoidXWhenGroupingPresent: true,
    },
    defaultGrouping: {
      columnName: 'wafer_id',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'Test Parameter 1 Values',
        yAxisName: 'Test Parameter 2 Values',
      },
      noGrouping: {
        xAxisName: 'Test Parameter 1 Values',
        yAxisName: 'Test Parameter 2 Values',
      },
    },
    showTickText: {
      grouping: {
        xAxis: true,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: false,
  };

  getPreferences = () => {
    return this.preferences;
  };

  generateTabs = (_requestObject: IGenericDetailedReportRequestObject, props:ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });

    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied, undefined, updateParentState),
    });
    return tabs;
  };

  processInput = (
    state: ICustomizedReportState,
    setStateCallback: any,
  ) => {
    setStateCallback({
      aggredateFunction: state.aggredateFunction as AggregateFunctionNames | 'None',
      seriesType: state.seriesType as SeriesTypeNames,
      showReportRow: true,
      showModal: false,
    });
  };

  applyLimitSettings = (limitSettingsObject: any, testParameterSelectedIndexUpdated: number, testParameterIndexForWhichLimitsAppliedUpdated: number, setStateCallback: any, errors: any) => {
    setStateCallback({ limitSettingsObj: limitSettingsObject, testParameterSelectedIndex: testParameterSelectedIndexUpdated, testParameterIndexForWhichLimitsApplied: testParameterIndexForWhichLimitsAppliedUpdated }, errors);
    toast.success('Limit settings applied');
  };

  getPrimaryComponent = (props : ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    const {
      groupingSortingListStore, selectionStore, testParameterIndex, report,
    } = props;
    const { limitSettingsObj } = state;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    const testParameterValues: any[] = [];
    testParameters.forEach((testParameter: any) => {
      testParameterValues.push(testParameter.values);
    });
    return (
      <>
        <UtilityOptions
          limitSettingsObj={limitSettingsObj}
          showLimitLinesOptions
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
        />
        <ReportOptionsModal
          reportActor={report.actor}
          groupingSortingListStore={groupingSortingListStore}
          selectionStore={selectionStore}
          testParameterValues={testParameterValues}
          testParameterIndex={testParameterIndex}
          parentState={state}
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
          applyLimitSettings={(limitSettingsObject: ILimitSettingsObj, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => {
            this.applyLimitSettings(limitSettingsObject, testParameterSelectedIndex, testParameterIndexForWhichLimitsApplied, setStateCallback, errors);
          }}
          requestObject={requestObject}
        />
      </>
    );
  };

  getSecondaryComponent = (props: ICustomizedReportProps, _state: ICustomizedReportState) => {
    const testParameterNames: any[] = [];
    const { testParameterIndex } = props;
    const testParameters = props.selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    if (testParameters.length > 0 && testParameters[0].values.length > 0) {
      testParameterNames.push(testParameters[0].values[testParameterIndex[0]].name);
      this.preferences.axisNames.noGrouping.xAxisName = testParameterNames[0];
      this.preferences.axisNames.grouping.xAxisName = `${testParameterNames[0]}AGGREGATE_FUNCTION`;
      testParameterNames.push(testParameters[0].values[testParameterIndex[1]].name);
      this.preferences.axisNames.noGrouping.yAxisName = testParameterNames[1];
      this.preferences.axisNames.grouping.yAxisName = `${testParameterNames[1]}AGGREGATE_FUNCTION`;
      return (
        <TestParameterBadges testParameterNames={testParameterNames} />
      );
    }
    return <></>;
  };

  generateGraphComponent = (props: ICustomizedReportGraphProps, state: ICustomizedReportGraphState, classMembers: any, setStateCallback: any, summaryStatLines: IFieldValue[], lineDict:ILineDict) => {
    const graphingUtil = new GraphingUtils();
    const { seriesType } = props;
    const { graphData } = state;
    this.updatePreferences(props, (graphData as ICombinedGraphData));
    if (seriesType === 'SINGLE') {
      return graphingUtil.plotlyGraphGenerationHelper(props, state, classMembers, setStateCallback, this.preferences, summaryStatLines, lineDict);
    }
    return (
      <ReportHorizontalScrollView
        reportGraphProps={props}
        reportGraphState={state}
        classMembers={classMembers}
        setStateCallback={setStateCallback}
        preferences={this.preferences}
        summaryStatLines={summaryStatLines}
        lineDict={lineDict}
      />
    );
  };

  postCombinedEvent = (
    selectionEventInput: InteractionInputDTO,
  ) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      const newData = _.cloneDeep(graphDataAndReportHeader);
      callback(newData);
    }).catch((err: any) => {
      const errorDetails: any = err.message;
      const graphingUtil = new GraphingUtils();
      callback(graphingUtil.generateDataObjectFromError(errorDetails));
    });
  };

  updatePreferences = (props: ICustomizedReportGraphProps, data: ICombinedGraphData) => {
    const { seriesType } = props;
    this.preferences.textOnHover = [];
    const textForHoverTemplate = '%{text}, %{y})';
    const groupingsCount = data.labelRows.length / data.graphs.length;
    data.graphs.forEach((graph:any, index: number) => {
      this.preferences.hoverTemplate.grouping = textForHoverTemplate;
      this.preferences.hoverTemplate.noGrouping = textForHoverTemplate;
      let text = '';
      for (let i = index * groupingsCount; i < (index + 1) * groupingsCount; i += 1) {
        text += `<b>${data.labelRows[i].xAxisTextPrefix}: </b>${data.labelRows[i].xAxisText}<br>`;
      }
      let xAxisTextList = Array(graph.y.length).fill('');
      xAxisTextList = xAxisTextList.map((x, i) => `${text}(${graph.xText[i]}`);
      this.preferences.textOnHover.push(xAxisTextList);
    });
    if (seriesType === 'SINGLE') {
      this.preferences.showCustomLabelsWhenGrouped = false;
      this.preferences.showCustomLabelsWhenNotGrouped = false;
    } else {
      this.preferences.showCustomLabelsWhenGrouped = true;
      this.preferences.showCustomLabelsWhenNotGrouped = true;
    }
  };
}
