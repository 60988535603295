import React from 'react';
import DropDownBox from 'devextreme-react/ui/drop-down-box';
import {
  Col, Row, Tabs, Tab,
} from 'react-bootstrap';
import DataGrid, { Column, } from 'devextreme-react/data-grid';
import CreateDynamicColumn from 'components/wrapped-component/customized-data-grid/CreateDynamicColumn';
import GroupSortDialog from 'components/utility-component/group-sort-widget/GroupSortDialog';
import { ScrollView } from 'devextreme-react';
import { toast } from 'react-toastify';
import { PaginatedDatagridMeta } from 'views/control-tower/PaginatedDatagrid';
import getDropDownOptions from 'components/getDropDownOptions';
import Button from '../../../../wrapped-component/hint-controls/Button';
import ParameterGrid from '../../PAT/pat-components/ParameterGrid';
import { parameterOrBinDisplayValue } from '../SPCMapperUtils';
import { httpService } from '../../../../../services/http.service';

const DataTypes = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  BIN_PLUS_TABLE: 'bin plus table',
  WAFER_CONTROL_MAP: 'wafer control map',
};

// interface IConditionalParameterOrBinBoxProps {
//   data: any
//   customData:any
//   searchCriteria: any;
// }
//
// interface IConditionalParameterOrBinBoxState {
//   testParametersPaginationMeta: PaginatedDatagridMeta;
//   allTestParametersResponse: { data: any, totalCount: number }; // array of objects of all tps in snake case and flat hierarchy => test_parameter_id
//   currentValue: any
//   searchCriteria:any
//   selectedTestParameter: any
//   selectedTestParameterKey: string
//   binDefinitions: any
//   selectedRowKeys: any
//   selectedRowsData: any
//   filterBinsBy: any
//   columnsList: any
//   selectedSequence: any
//   spcUDAType: any
// }

class ConditionalParameterOrBinBox extends React.Component<any, any> {
  dropDownBoxRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      allTestParameters: [],
      currentValue: props.data.value,
      // allTestParameters: props.customData.listItem,
      searchCriteria: props.customData.searchCriteria,
      selectedTestParameter: this.getSelectedTestParameter(props.data, props.customData.listItem, false),
      selectedTestParameterKey: this.getSelectedTestParameter(props.data, props.customData.listItem, true),

      binDefinitions: props.customData.binDefinitions,
      selectedRowKeys: props.data.value ? props.data.value : [],
      selectedRowsData: [],
      filterBinsBy: '',

      columnsList: props.customData.columnsList,
      selectedSequence: [],

      spcUDAType: 'Standard',

    };
    this.dropDownBoxRef = React.createRef();
  }

  componentDidUpdate(prevProps: { customData: any; }) {
    const { customData } = this.props;
    if (prevProps.customData.searchCriteria !== customData.searchCriteria) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => {
        return ({
          searchCriteria: customData.searchCriteria,
          selectedTestParameter: undefined,
        });
      });
    }
  }

  getSelectedTestParameter = (propsData: any, allTestParameters:any, returnKey: boolean) => {
    if (propsData.data.parameterType === 'Parametric' && propsData.data.parameterOrBin) {
      const testParameterNumberAndName = propsData.data.parameterOrBin.split(', ');
      const currentTestParameter = allTestParameters.find((testParameter:any) => testParameter.test_parameter_number === parseInt(testParameterNumberAndName[0].replace('(', '')) && testParameter.test_parameter_name === testParameterNumberAndName[1].replace(')', ''));
      if (currentTestParameter) {
        return returnKey ? currentTestParameter.test_parameter_id : currentTestParameter;
      }
    }
    return returnKey ? '' : undefined;
  };

  parameterSelectionHandler = (selectedData: any) => {
    this.setState({ selectedTestParameter: selectedData.selectedRowsData[0], selectedTestParameterKey: selectedData.selectedRowKeys[0] });
  };

  sendParametersToParent = () => {
    const { data, customData } = this.props;
    const { selectedTestParameter } = this.state;
    if (selectedTestParameter === undefined) {
      toast.warning('Please select a parameter');
      return;
    }
    const input = {
      uom: selectedTestParameter.test_parameter_test_unit,
      lowLimit: selectedTestParameter.test_parameter_low_ctrl_limit,
      highLimit: selectedTestParameter.test_parameter_high_ctrl_limit,
      testParam: selectedTestParameter.test_parameter_id,
    };
    customData.setAutofillValues(input);
    data.setValue(parameterOrBinDisplayValue(selectedTestParameter));
    this.dropDownBoxRef.current.instance.close();
  };

  onTestParametersLoaded = (data: any[]) => {
    this.setState({ allTestParameters: data });
  };

  renderParameterGrid = () => {
    const {
      allTestParameters, selectedTestParameterKey, searchCriteria,
    } = this.state;
    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        direction="vertical"
      >
        <ParameterGrid
          height={300}
          pageSize={10}
          columnsWidth={100}
          allTestParameters={allTestParameters}
          patRuleSetType="SHOW_ALL"
          isSelectionSingle
          selectedTestParameterIds={selectedTestParameterKey}
          onRowSelectionHandler={this.parameterSelectionHandler}
          onTestParametersLoaded={this.onTestParametersLoaded}
          httpLoadTestParametersCallback={(datagridMeta: PaginatedDatagridMeta) => {
            return httpService.getPaginatedTestParameters(datagridMeta, searchCriteria, selectedTestParameterKey ? [selectedTestParameterKey] : []);
          }}
        />
        <div>
          <Button variant="primary float-right mt10" onClick={this.sendParametersToParent}>Apply & Close</Button>
        </div>
      </ScrollView>
    );
  };

  binSelectionChangedHandler = ({ selectedRowsData, selectedRowKeys }: any) => {
    let filter = '';
    if (selectedRowsData.length > 0) {
      filter = selectedRowsData[0].passFail;
    }
    this.setState({ selectedRowsData, filterBinsBy: filter, selectedRowKeys });
  };

  renderColorInCell = (e:any) => {
    return <div className="center-align h20 w20" style={{ backgroundColor: e.data.color }} />;
  };

  sendBinsToParent = () => {
    const { selectedRowsData } = this.state;
    const { data, customData } = this.props;
    const input = {
      uom: '%',
      lowLimit: undefined,
      highLimit: undefined,
    };
    customData.setAutofillValues(input);
    data.setValue(selectedRowsData);
    this.dropDownBoxRef.current.instance.close();
  };

  renderBinGrid = () => {
    const { data, customData } = this.props;
    const { selectedRowKeys, filterBinsBy, binDefinitions } = this.state;

    let listItem: any;
    if (data.data.parameterType === customData.conditions[1]) {
      listItem = binDefinitions.filter((el:any) => el.groupType === 'Hardbin');
    } else {
      listItem = binDefinitions.filter((el:any) => el.groupType === 'Softbin');
    }
    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        direction="both"
      >
        <DataGrid
          columnAutoWidth
          selection={{ mode: 'multiple' }}
          onSelectionChanged={this.binSelectionChangedHandler}
          dataSource={filterBinsBy ? listItem.filter((el:any) => el.passFail === filterBinsBy) : listItem}
          selectedRowKeys={selectedRowKeys}
          keyExpr="name"
          showBorders
        >
          <Column
            caption="name"
            dataField="name"
          />
          <Column
            caption="Work Center"
            dataField="workCenter"
          />
          <Column
            caption="Device"
            dataField="device"
          />
          <Column
            caption="Bin+ Table Name"
            dataField="binPlusTable.name"
          />
          <Column
            caption="Bin Color"
            dataField="color"
            cellRender={this.renderColorInCell}
          />
          <Column
            caption="Bin Status"
            dataField="passFail"
          />
        </DataGrid>
        <div>
          <Button variant="primary float-right mt10" onClick={this.sendBinsToParent}>Apply & Close</Button>
        </div>
      </ScrollView>
    );
  };

  // for uda
  selectFieldHandler = (e:any, dialogType:string) => {
    const { selectedSequence, columnsList } = this.state;
    const item : any = e.row.data;

    const columnObj = columnsList.find((obj:any) => obj.ColumnName === item.ColumnName);
    columnObj.isVisible = false;

    if (dialogType === 'SORT') {
      const field : any = {
        columnName: item.ColumnName,
        caption: item.Caption,
        selector: item.ColumnName,
        desc: false,
        entityType: item.ParentId,
        fieldName: item.FieldName,
        disabled: item.Disabled,
        parentRank: item.ParentRank,
      };
      selectedSequence.push(field);
      this.setState({ selectedSequence, columnsList });
    } else {
      const field : any = {
        columnName: item.ColumnName,
        caption: item.Caption,
        selector: item.ColumnName,
        entityType: item.ParentId,
        fieldName: item.FieldName,
        disabled: item.Disabled,
        parentRank: item.ParentRank,
      };
      selectedSequence.push(field);
      this.setState({ selectedSequence, columnsList });
    }
  };

  deleteFieldHandler = (e:any) => {
    const { selectedSequence, columnsList } = this.state;
    const item : any = e.row.data;
    const columnObj = columnsList.find((obj:any) => obj.Caption === item.caption);
    columnObj.isVisible = true;

    const index = selectedSequence.indexOf(item);
    if (index > -1) {
      selectedSequence.splice(index, 1);
    }
    this.setState({ selectedSequence, columnsList });
  };

  spcUDATypeSelectionHandler = (spcUDAType:any) => {
    this.setState({ spcUDAType });
  };

  onAddNewAttribute = async (isAdvance: boolean, columnInfo: any) => {
    const { data } = this.props;
    const { selectedSequence } = this.state;
    const column = {
      type:
        {
          isAdvance,
        },
      selectedSequence,
      data:
        {
          columnName: columnInfo.columnName.replace(/ /g, ''),
          columnLabel: columnInfo.columnName,
          type: columnInfo.columnType,
          isPublic: columnInfo.isPublic,
          minValue: columnInfo.minValue,
          maxValue: columnInfo.maxValue,
          textLength: +columnInfo.textLength,
          expression: columnInfo.expression,
        },
    };
    data.setValue(column);
    this.dropDownBoxRef.current.instance.close();
  };

  onSetPredefinedAttribute = () => {
    const { data } = this.props;
    const { selectedSequence } = this.state;
    data.setValue({ type: { isAdvance: false }, selectedSequence });
    this.dropDownBoxRef.current.instance.close();
  };

  renderInline = (columnsList:any, selectedSequence:any) => {
    return (

      <ScrollView
        showScrollbar="always"
        scrollByThumb
        direction="both"
      >
        <Tabs
          id="spc-inline-tab"
          onSelect={(e: any) => this.spcUDATypeSelectionHandler(e)}
        >
          <Tab
            className="p10"
            eventKey="Standard"
            title="Standard"
          >
            <GroupSortDialog
              isDropdown
              id="GROUP"
              columnsList={columnsList}
              selectedField={selectedSequence.map((ele:any) => ele)}
              selectFieldHandler={this.selectFieldHandler}
              deleteFieldHandler={this.deleteFieldHandler}
              applyClause={this.onSetPredefinedAttribute}

            />
          </Tab>
          <Tab
            className="p10"
            eventKey="Advance"
            title="Advance"
          >
            <Row>
              <Col lg={6}>
                <GroupSortDialog
                  isDropdown
                  id="GROUP"
                  columnsList={columnsList}
                  selectedField={selectedSequence.map((ele:any) => ele)}
                  selectFieldHandler={this.selectFieldHandler}
                  deleteFieldHandler={this.deleteFieldHandler}
                  applyClause={() => {}}
                  hideApplyCloseButton
                />
              </Col>

              <Col lg={6}>
                <CreateDynamicColumn
                  columnTypes={[
                    [DataTypes.STRING, 'Text'],
                    [DataTypes.NUMBER, 'Number'],
                    [DataTypes.DATE, 'Date'],
                    [DataTypes.BOOLEAN, 'Boolean'],
                  ]}
                  showEntityTypes
                  needExpression
                  isPopover={false}
                  getColumnInfo={(columnInfo: any) => { this.onAddNewAttribute(true, columnInfo); }}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </ScrollView>

    );
  };

  renderHelper = (parameterType: string, conditions: string[]) => {
    const { columnsList, selectedSequence } = this.state;
    if (conditions[2] === parameterType) {
      return this.renderParameterGrid;
    }
    if (conditions[3] === parameterType) {
      return (e:any) => { return this.renderInline(columnsList, selectedSequence); };
    }
    return this.renderBinGrid;
  };

  dropdownOptionSelector = (data:any, parameterType: string, conditions: string[]) => {
    const { spcUDAType } = this.state;
    if (conditions[2] === parameterType) {
      return getDropDownOptions('cell', data, [{ width: '80%' }, { height: '500' }]);
    }
    if (conditions[3] === parameterType && spcUDAType === 'Advance') {
      return getDropDownOptions('cell', data, [{ width: '70%' }]);
    }
    return getDropDownOptions('cell', data, [{ width: '50%' }]);
  };

  render() {
    const { currentValue } = this.state;
    const { data, customData } = this.props;
    return (
      <DropDownBox
        ref={this.dropDownBoxRef}
        dropDownOptions={this.dropdownOptionSelector(data, data.data.parameterType, customData.conditions)}
        value={currentValue}
        deferRendering={false}
        placeholder={currentValue || 'Select...'}
        showClearButton={false}
        applyValueMode="instantly"
        onValueChanged={() => {}}
        contentRender={this.renderHelper(data.data.parameterType, customData.conditions)}
        disabled={!customData.conditions.includes(data.data.parameterType)}
        // todo: find a fix of the blinking issue when dropdown is opened while displayValueFormatter is set
        // displayValueFormatter={() => currentValue}
      />
    );
  }
}
export default ConditionalParameterOrBinBox;
