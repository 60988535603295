// eslint-disable-next-line no-unused-vars
import WaferUtils from '../Utils/WaferUtils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import { InteractionType } from './Events';

const performDragFromRadar = (waferUtil: WaferUtils, x: number, y: number) => {
  const { innerViewPort, keyIndex } = waferUtil.waferMapVariables;
  waferUtil.dragWaferMapFromRadar({ x, y });
  PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_DRAGGED_FROM_RADAR, {
    x: x / innerViewPort.width,
    y: y / innerViewPort.height,
    isFromPubSub: true,
  }, keyIndex);
};

export const radarMouseMove = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }): void => {
  if (data && data.interactionType !== InteractionType.Radar) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  if (!isMouseDown) return;
  performDragFromRadar(waferUtil, endX, endY);
};

export const radarMouseUp = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils): void => {
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  performDragFromRadar(waferUtil, endX, endY);
};
