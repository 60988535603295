/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import {
  faFolderPlus,
  faTable,
  faCircleNotch,
  faTimes,
  faMapSigns,
  faChevronDown,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Accordion,
  Spinner,
} from "react-bootstrap";
import Gallery from "components/gallery/GalleryView";
import CustomizedRawDataGrid from "components/reports/v2.0/raw-data-grid/CustomizedRawDataGrid";
import SelectionCriteriaButton from "components/selection-criteria/SelectionCriteriaButton";
import GroupSortWidget from "components/utility-component/group-sort-widget/GroupSortWidget";
import CustomizedDataGrid from "components/wrapped-component/customized-data-grid/CustomizedDataGrid";
import {
  IGroupingSortingList,
  IDrilledGraphData,
  IExpressionsList,
  ISelectionCriteriaData,
  ISelectionCriteriaReturnValue,
  IGenericDetailedReportRequestObject,
  EntityType,
  IFilterRawDataDTO,
  IPatLimits,
  IWaferCombinationsRequestObject,
  ReportType,
  IExportDataOptionsDto,
  GroupingSortingDTO,
} from "interfaces";
import React from "react";
import { ScrollView, SpeedDialAction } from "devextreme-react";
import toast from "CustomToast";
import { httpService } from "services/http.service";
import TopbarNav from "components/navigational-component/topbar-nav/TopbarNav";
import { httpBinPlusTable } from "services/http.bin-plus-table";
import _ from "lodash";
import { ISelectionCritereaWidget } from "components/selection-criteria/SelectionCriteriaTypes";
import WidgetCard from "components/template-component/widget-card/WidgetCard";
import { httpSCW } from "services/http.scw";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ReportHeaderChooser from "components/utility-component/report-headers/ReportHeaderChooser";
import GeneralUtils, {
  ReportTypeConstants,
  ReportTypeConstants as ReportTypeNames,
} from "GeneralUtils";
import ContextSavingSpinner from "./customized-report-utils/ContextSavingSpinner";
import CustomizedReport, {
  ICustomizedReportProps,
  parametricFailureReportOptions,
} from "./CustomizedReport";
import PubSubBinder, { ActionType } from "../PublishSubscribeBinder";
import {
  IInteractionType,
  ICustomizedReportCollectionTypes,
} from "./customized-report-helpers/CustomizedReportInterfaces";
import CustomizedReportHeaderControls from "../../../components/utility-component/chart-control-component/CustomizedReportHeaderControls";
import PolicyOverViewGrid from "../../../components/power-view-component/policy-overview-grid/PolicyOverViewGrid";
import { simulateHandlerUtility } from "../../../components/power-view-component/policy-overview-grid/SimulatePolicyUtility";
import {
  ReportCollectionMapping,
  ReportCollectionPreferences,
  GetGraphAndLegendPreferences,
} from "./customized-report-helpers/CustomizedReportMapping";
import { CustomizedReportsCollectionExtensions } from "./CustomizedReportsCollectionExtensions";
import PolicySummaryStatsOverView from "../../../components/power-view-component/policy-summary-stats-overview/PolicySummaryStatsOverView";
import CustomizedDataSummary from "./CustomizedDataSummary";
import PolicySummaryStatsGraph from "../../../components/power-view-component/policy-summary-stats-graph/PolicySummaryStatsGraph";
import AppConstants from "../../../constants.js";
import PolicyWaferSelectionGrid from "../../../components/power-view-component/policy-wafer-selection-grid/PolicyWaferSelectionGrid";
import SimulatePolicyComponent from "../../../components/power-view-component/simulate-policy-component/SimulatePolicyComponent";
import { IGraphDataHelperPreferences } from "./customized-report-helpers/CustomizedReportHelper";
import PowerViewProgressBar from "./PowerViewProgressBar";
import AMGSummaryStatsOverView from "../../../components/power-view-component/policy-summary-stats-overview/AMGSummaryStatsOverView";
import ReportFinderModal from "./customized-report-utils/ReportFinderModal";
import ScrollableReports from "./ScrollableReports";
import BinWaferMapComparisonPopup from "./customized-report-utils/BinWaferMapComparisonPopup";
import selectionCriteria from "../../../components/selection-criteria/SelectionCriteria";

type ContextState = "UNSAVED" | "SAVING" | "SAVED";

export interface ICustomizedReportsCollectionBaseState {
  contextState: ContextState;
  selectionStoreDataSetCount?: number | undefined;
  defaultSelectionCriteriaData: ISelectionCriteriaData[];
  galleryData: IDrilledGraphData[];
  selectedBinPlusDefinition: {
    name: string;
    id: string;
  };
  binPlusDefinitions: [];
  waferGalleryFilters: IExpressionsList[];
  showRawDataGrid: boolean;
  showReportHeaderChooser: boolean;
  showWaferGallery: boolean;
  showBinPlusTables: boolean;
  revokeReportGeneration: boolean;
  reports: ICustomizedReportProps[];
  policyResults: any;
  selectedPolicy: any;
  selectedStepResult: any;
  selectedStageId: string;
  selectedStageType: string;
  showModal?: any;
  // showSelectReportsWidget: boolean;
  policySimulationNeeded: boolean;
  isSimulated: boolean;
  simulatedPolicyId: string;
  simulatedPolicyProgress: number;
  simulatedPolicyProgressBarVariant: string;
  contextSaveDTO: {
    workCenterTypes: string[];
  };
  showReportsToFindModal: boolean;
  currentReportTypesToShow: IReportsTypesWithPositions[];
  renderReportsList: JSX.Element[];
  selectedWaferData?: any;
  isReportSelectionAccordianCollapsed: boolean;
  isClosedSomeReport: boolean;
  allLegendsExpanded: boolean;
  primaryWidgetHeight?: string;
  showStageGrids: string[];
  isSaveAndCloseClickedCRCState: boolean;
  showBinWaferMapComparisonPopup: boolean;
  waferCombinations: any[];
  reportSelected: boolean;
  showSelectionCriteriaByDefault: boolean;
  isDataReselected: boolean;
  isDataExporting: boolean;
}

export interface IReportsTypesWithPositions {
  reportType: string;
  reportPosition: number;
}

export interface IActorDetails {
  actor: string;
  selectionEventPublisher: IInteractionType;
  hidingEventPublisher: IInteractionType;
}

export interface ICustomizedReportCollectionProps extends RouteComponentProps {
  reportList: string[];
  type: ICustomizedReportCollectionTypes;
  policyId?: string;
  title?: string;
  isBinBased?: boolean;
}

export interface ISelectionStoreValue {
  name: string;
  id: string;
  ruleId?: string;
}

interface IPolicyOverViewGrid {
  selectedData: any;
  policy: any;
}

export const SELECTION_CRITERIA_DIV_ID = "selectionCriteriaDiv";
export const REPORT_HEADER_CONTROLS_DIV_ID = "reportHeaderControlsDiv";

export const getHeaderControlButtonStructure = (icon: any, content: any) => {
  return (
    <div className="d-flex align-items-start justify-content-between">
      <div className="flex-10">
        <FontAwesomeIcon size="1x" className="mr10" icon={icon} />
      </div>
      <div className="flex-90 text-left">
        <span className="mt6">{content}</span>
      </div>
    </div>
  );
};

class CustomizedReportsCollection extends React.Component<
  ICustomizedReportCollectionProps,
  ICustomizedReportsCollectionBaseState
> {
  private isReportRequested = false;

  private isRawDataGridRequested = false;

  private groupingSortingListStore: IGroupingSortingList;

  private prevMPEGridData: any[] = [];

  private policyOverViewGridData: IPolicyOverViewGrid;

  private currentReports: any[];

  private dontDisable: boolean;

  private isBinWaferMapComparisonReportSelected = false;

  // private reportSessionId: string = uuidv4();

  private reportSessionId = "";

  private pollingTime = 1000;

  private selectionStore: ISelectionCriteriaReturnValue;

  private pubSubBinder = PubSubBinder();

  private publisherId: string;

  private customizedReportRef: any;

  private allLimitLines: any;

  private patParamCtrlLimitsLines: any;

  private globalReportIndex = 0;

  constructor(props: ICustomizedReportCollectionProps, context: any) {
    super(props, context);
    this.dontDisable = false;
    this.customizedReportRef = React.createRef();
    this.publisherId = this.pubSubBinder.RegisterActor(
      "GENERAL_ACTION",
      this.hideDataExportSpinner
    );

    this.selectionStore = {
      advancedFilter: "",
      expressions: [],
      selections: [],
      testParameterConditions: {},
    };

    this.state = {
      contextSaveDTO: {
        workCenterTypes: [],
      },
      selectionStoreDataSetCount: undefined,
      contextState: "UNSAVED",
      policySimulationNeeded: true,
      policyResults: [],
      selectedPolicy: {},
      defaultSelectionCriteriaData: [],
      galleryData: [],
      selectedBinPlusDefinition: {
        name: "",
        id: "",
      },
      binPlusDefinitions: [],
      showRawDataGrid: false,
      selectedStepResult: {},
      selectedStageId: "",
      selectedStageType: "",
      showReportHeaderChooser: false,
      showWaferGallery: false,
      reports: [],
      showBinPlusTables: false,
      revokeReportGeneration: false,
      waferGalleryFilters: [],
      isSimulated: false,
      simulatedPolicyProgress: -1,
      simulatedPolicyProgressBarVariant: "success",
      simulatedPolicyId: "",
      showReportsToFindModal: false,
      currentReportTypesToShow: [],
      renderReportsList: [],
      isReportSelectionAccordianCollapsed: false,
      isClosedSomeReport: false,
      allLegendsExpanded: false,
      primaryWidgetHeight: undefined,
      showStageGrids: ["PATGrids", "AMGGrids"],
      isSaveAndCloseClickedCRCState: false,
      isDataReselected: false,
      showBinWaferMapComparisonPopup: false,
      waferCombinations: [],
      reportSelected: false,
      showSelectionCriteriaByDefault: false,
      isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING,
    };
    this.groupingSortingListStore = {
      grouping: [],
      sorting: [],
    };
    this.policyOverViewGridData = {
      selectedData: [],
      policy: {},
    };
    this.currentReports = [];
  }

  componentDidMount() {
    const [
      contextData,
      defaultSelectionCriteriaData,
      contextSessionId,
      contextSaveDto,
    ] = GeneralUtils.getGlobalVars();
    this.reportSessionId = contextSessionId;
    this.selectionStore = contextData || null;
    const { selectedStageType } = this.state;

    this.setState(
      {
        isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING,
        contextState: !contextData ? "UNSAVED" : "SAVED",
        contextSaveDTO: contextSaveDto || null,
        defaultSelectionCriteriaData,
      },
      () => {
        const { policyId, type } = this.props;
        if (policyId && type === "VIEW_POLICY_OUTPUT") {
          httpService
            .getPolicyResult(policyId, -1)
            .then((policyResultDTO: any) => {
              if (policyResultDTO.errorMessage !== "") {
                toast.error(policyResultDTO.errorMessage);
              }
              const { simulatedPolicyResults } = policyResultDTO;
              this.setState({
                policyResults: simulatedPolicyResults,
                revokeReportGeneration: false,
              });
            });
        }
      }
    );
  }

  getPATLimits = (policyResult: any) => {
    const result: any[] = [];
    const patLimitPoints: any = {};
    if (policyResult === undefined) {
      return patLimitPoints;
    }
    policyResult.forEach((policyStep: any) => {
      policyStep.policyStepResult.forEach((ruleSet: any) => {
        ruleSet.ruleSetResult.forEach((rule: any) => {
          rule.ruleResult.forEach((testParameter: any) => {
            testParameter.testParameterResult.forEach(
              (testParameterResult: any, testParameterIndex: number) => {
                patLimitPoints[testParameterResult.testParameter.id] = result;
                Object.keys(testParameterResult.siteWiseLimits).forEach(
                  (site) => {
                    let siteString = "";
                    if (site !== "-1") siteString = ` (Site: ${site})`;
                    patLimitPoints[testParameterResult.testParameter.id].push({
                      highVal:
                        testParameterResult.siteWiseLimits[site][
                          testParameterResult.inputWaferId
                        ].highLimit,
                      lowVal:
                        testParameterResult.siteWiseLimits[site][
                          testParameterResult.inputWaferId
                        ].lowLimit,
                      colorLow: "#5634eb",
                      customNameLow: `PAT Low Limit${siteString}`,
                      appearanceLow: "solid",
                      thicknessLow: "2",
                      colorHigh: "#34eb37",
                      customNameHigh: `PAT High Limit${siteString}`,
                      appearanceHigh: "solid",
                      thicknessHigh: "2",
                      waferId: testParameterResult.outputWaferId,
                    });
                  }
                );
              }
            );
          });
        });
      });
    });
    return patLimitPoints;
  };

  getPATParamCtrlLimits = (policyResult: any) => {
    const result: any[] = [];
    const patParamCtrlLimitPoints: any = {};
    if (policyResult === undefined) {
      return patParamCtrlLimitPoints;
    }
    policyResult.forEach((policyStep: any) => {
      policyStep.policyStepResult.forEach((ruleSet: any) => {
        ruleSet.ruleSetResult.forEach((rule: any) => {
          rule.ruleResult.forEach((testParameter: any) => {
            testParameter.testParameterResult.forEach(
              (testParameterResult: any, testParameterIndex: number) => {
                patParamCtrlLimitPoints[testParameterResult.testParameter.id] =
                  result;
                Object.keys(testParameterResult.siteWiseLimits).forEach(
                  (site) => {
                    patParamCtrlLimitPoints[
                      testParameterResult.testParameter.id
                    ].push({
                      highVal: testParameterResult.testParameter.highCtrlLimit,
                      lowVal: testParameterResult.testParameter.lowCtrlLimit,
                      colorLow: "#A020F0",
                      customNameLow: "Raw Data Control (Low)",
                      appearanceLow: "solid",
                      thicknessLow: "2",
                      colorHigh: "#FFC0CB",
                      customNameHigh: "Raw Data Control (High)",
                      appearanceHigh: "solid",
                      thicknessHigh: "2",
                      waferId: testParameterResult.outputWaferId,
                    });
                  }
                );
              }
            );
          });
        });
      });
    });
    return patParamCtrlLimitPoints;
  };

  updateWaferSelection = (selectedWafer: any) => {
    const { selectedWaferData } = this.state;
    this.setState({ selectedWaferData: selectedWafer });
  };

  getReportsListForSPC = (
    reportList: string[],
    policyResults: any,
    selectedStageId: string
  ): string[] => {
    const spcPolicyStepResultIndex =
      policyResults[0].policyStepResult.findIndex(
        (x: any) => x.policyStepId === selectedStageId
      );
    const numberOfSPCReports =
      policyResults[0].policyStepResult[spcPolicyStepResultIndex].spcReports
        .length;
    if (numberOfSPCReports > 1) {
      for (let index = 0; index < numberOfSPCReports - 1; index += 1) {
        reportList.push(ReportTypeNames.SPC_TREND as string);
      }
    }
    return reportList;
  };

  viewPolicyOutput = () => {
    const { simulatedPolicyId } = this.state;
    const { history } = this.props;
    history.push(`/view-policy-output/${simulatedPolicyId}`, {
      id: simulatedPolicyId,
    });
  };

  renderPolicyOutputComponent = () => {
    const { type, policyId, reportList } = this.props;
    const {
      policyResults,
      revokeReportGeneration,
      selectedPolicy,
      isSimulated,
      selectedStageId,
      simulatedPolicyProgress,
      selectedStageType,
      simulatedPolicyProgressBarVariant,
      selectedStepResult,
      showStageGrids,
    } = this.state;
    let policyOutputComponent: JSX.Element = <></>;
    if (type && type === "VIEW_POLICY_OUTPUT") {
      policyOutputComponent = (
        <Row>
          <Col lg={6}>
            <div className="ml10 mt10 p10 pt16 background-color-light border-all border-radius-4">
              <PolicyOverViewGrid
                height={209}
                enableSelection
                showParentNodesOnly
                singleSelection
                policyId={policyId && policyId !== "" ? policyId : ""}
                onRowSelection={this.onRowSelection}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt10 mr10 p10 pt16 background-color-light border-all border-radius-4">
              <PolicyWaferSelectionGrid
                height={209}
                policyResults={policyResults}
                showSpecificPolicyOutput={this.showSpecificPolicyOutput}
                selectedStageType={selectedStageType}
                selectedStageId={selectedStageId}
                updateWaferSelection={
                  selectedStageType === "PAT"
                    ? this.updateWaferSelection
                    : () => {}
                }
                getRequestObjectForBinWaferMapComparison={
                  selectedStageType === "PAT"
                    ? this.getRequestObjectForBinWaferMapComparison
                    : () => {}
                }
              />
            </div>
          </Col>
          {!revokeReportGeneration &&
            selectedStageId.length > 0 &&
            Object.keys(selectedStepResult).length > 0 &&
            selectedStageType === "PAT" && (
              <>
                <Col lg={6}>
                  <div className="mt10 ml10 p10 pt16 background-color-light border-all border-radius-4">
                    <PolicySummaryStatsOverView
                      height={847}
                      selectedStageId={selectedStageId}
                      policyResult={selectedStepResult}
                      showGrid={showStageGrids}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mr10 mt10 p10 pt16 background-color-light border-all border-radius-4">
                    <PolicySummaryStatsGraph
                      selectedStageId={selectedStageId}
                      policyResult={selectedStepResult}
                      showGrid={showStageGrids}
                    />
                  </div>
                </Col>
              </>
            )}
          {!revokeReportGeneration &&
            selectedStageId.length > 0 &&
            Object.keys(selectedStepResult).length > 0 &&
            selectedStageType === "AMG" && (
              <Col lg={6}>
                <div className="mt10 ml10 p10 pt16 background-color-light border-all border-radius-4">
                  <AMGSummaryStatsOverView
                    height={200}
                    selectedStageId={selectedStageId}
                    policyResult={selectedStepResult}
                    showGrid={showStageGrids}
                  />
                </div>
              </Col>
            )}
          {revokeReportGeneration && (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "430px" }}
            >
              <div className="w300">
                <ContextSavingSpinner
                  mode="REPORT"
                  title="Generating Summary Stats"
                />
              </div>
            </div>
          )}
        </Row>
      );
    } else if (type === "SIMULATE_POLICY") {
      policyOutputComponent = (
        <Row>
          <Col lg={10}>
            <div className="ml10 mt10 p10 pt16 background-color-light border-all border-radius-4">
              <PolicyOverViewGrid
                height={209}
                enableSelection
                showParentNodesOnly={false}
                singleSelection={false}
                policyId={policyId && policyId !== "" ? policyId : ""}
                onRowSelection={this.onRowSelection}
              />
            </div>
          </Col>
          <Col lg={2}>
            <div className="mr10 mt10 p10 pt16 background-color-light border-all border-radius-4">
              <SimulatePolicyComponent
                allowReportsSelection={reportList.length === 0}
                revokeReportGeneration={revokeReportGeneration}
                isSimulated={isSimulated}
                simulatedPolicyProgress={simulatedPolicyProgress}
                simulatedPolicyProgressBarVariant={
                  simulatedPolicyProgressBarVariant
                }
                viewPolicyOutput={this.viewPolicyOutput}
                simulateHandler={this.simulateHandler}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return policyOutputComponent;
  };

  SelectionCriteriaOpenHandler = () => {
    this.setState({ showSelectionCriteriaByDefault: true });
  };

  SelectionCriteriaCloseHandler = () => {
    this.setState({ showSelectionCriteriaByDefault: false });
  };

  checkPreReportGenerationOptions = () => {
    const { reportList, type } = this.props;
    const { contextState } = this.state;
    this.isBinWaferMapComparisonReportSelected = !!(
      this.currentReports.find((x) =>
        x.key.includes(ReportTypeNames.BIN_WAFER_MAP_COMPARISON)
      ) ||
      (reportList.length === 1 &&
        reportList[0].includes(ReportTypeNames.BIN_WAFER_MAP_COMPARISON))
    );

    if (
      this.isBinWaferMapComparisonReportSelected &&
      type !== "VIEW_POLICY_OUTPUT" &&
      contextState !== "SAVING"
    ) {
      this.setState({ showBinWaferMapComparisonPopup: true });
    } else if (contextState === "SAVING") {
      this.setState({ revokeReportGeneration: true });
      this.isReportRequested = true;
    } else {
      this.currentReports.forEach(() => {
        this.addReportInstance();
      });
      reportList.forEach(() => {
        this.addReportInstance();
      });
    }
  };

  addReportInstance = () => {
    const { selectionStore } = this;
    // reset expand all legends button and remove all current reports;
    this.setState({ allLegendsExpanded: false });
    this.removeAllReports();
    if (selectionStore?.selections.length === 0) {
      toast.warning("No data selected");
      return;
    }
    const selectionsCount = selectionStore?.selections
      .map((s) => s.values.length)
      .reduce((a, b) => a + b);
    if (selectionsCount === 0) {
      toast.warning("No data selected");
      return;
    }

    const { contextState } = this.state;
    if (contextState === "SAVING") {
      this.setState({ revokeReportGeneration: true });
      this.isReportRequested = true;
      return;
    }

    this.setState(
      {
        reports: [],
        showRawDataGrid: false,
        showWaferGallery: false,
        isReportSelectionAccordianCollapsed: true,
        showBinWaferMapComparisonPopup: false,
      },
      () => {
        this.createReportInstancesList().then(
          (data: ICustomizedReportProps[]) => {
            const newCurrentReportTypesToShow: IReportsTypesWithPositions[] =
              this.updateReportsTypesAndPositionsList(data);
            let newRenderReportsList: JSX.Element[] = [];
            newRenderReportsList = this.getCustomizedReportComponentsList(data);
            this.setState({
              reports: data,
              currentReportTypesToShow: newCurrentReportTypesToShow,
              renderReportsList: newRenderReportsList,
              isClosedSomeReport: false,
            });
          }
        );
      }
    );
  };

  updateReportsTypesAndPositionsList = (reports: ICustomizedReportProps[]) => {
    const newCurrentReportTypesToShow: IReportsTypesWithPositions[] = [];
    let reportsStartIndex = 0;
    const testParametersCount = this.selectionStore?.selections.find(
      (x) => x.entityType === "Testparameter"
    )?.values.length;
    reports.forEach((reportItem: ICustomizedReportProps, index: number) => {
      newCurrentReportTypesToShow.push({
        reportType: reportItem.report.actor,
        reportPosition: reportsStartIndex,
      });
      if (
        ReportCollectionPreferences[reportItem.report.actor]
          ?.showReportForIndividualTestParameter
      ) {
        reportsStartIndex += testParametersCount || 1;
      } else {
        reportsStartIndex += 1;
      }
    });
    return newCurrentReportTypesToShow;
  };

  getCustomizedReportComponentsList = (
    reports: ICustomizedReportProps[],
    updatedReportHeaderId = ""
  ) => {
    this.globalReportIndex = 0;
    let testParametersObj = this.selectionStore?.selections.find(
      (x) => x.entityType === "Testparameter"
    )?.values;
    testParametersObj = testParametersObj || [];
    const reportsList = reports.map(
      (reportItem: ICustomizedReportProps, index: number) =>
        this.renderCustomizedReport(reportItem, index, testParametersObj)
    );
    return _.flatten(reportsList);
  };

  getDefaultGrouping = (reportActor: string) => {
    return (
      GetGraphAndLegendPreferences[reportActor] as IGraphDataHelperPreferences
    )?.defaultGrouping;
  };

  createReportInstancesList = async (
    overrideDefaultSelectionCriteria: ISelectionCriteriaData[] = []
  ) => {
    const {
      defaultSelectionCriteriaData,
      galleryData,
      selectedBinPlusDefinition,
      binPlusDefinitions,
      waferGalleryFilters,
      policyResults,
      selectionStoreDataSetCount,
      selectedStageType,
      contextSaveDTO,
      selectedStageId,
      selectedWaferData,
      waferCombinations,
    } = this.state;
    const { type } = this.props;
    let reportList: string[] = [];

    if (this.currentReports.length > 0) {
      reportList = this.currentReports.map((x) => x.key as string);
    } else {
      const { reportList: propReportList } = this.props;
      reportList = propReportList;
    }
    const renderReportsList: ICustomizedReportProps[] = [];
    let patLimits: IPatLimits[];
    let patCtrlLimits: IPatLimits[];

    if (selectedStageType === "PAT" && policyResults.length > 0) {
      patLimits = this.getPATLimits(policyResults);
      this.allLimitLines = patLimits;
    }

    if (selectedStageType === "PAT" && policyResults.length > 0) {
      patCtrlLimits = this.getPATParamCtrlLimits(policyResults);
      this.patParamCtrlLimitsLines = patCtrlLimits;
    }

    if (selectedStageType === "SPC" && policyResults.length > 0) {
      reportList = this.getReportsListForSPC(
        reportList,
        policyResults,
        selectedStageId
      );
    }

    const testParameters = this.selectionStore?.selections.filter(
      (x: any) => x.entityType === "Testparameter"
    );
    let SPCReportIndex = -1;
    reportList.forEach((reportInstance, index: number) => {
      const reportMapping = ReportCollectionMapping[reportInstance];
      if (reportMapping) {
        if (this.selectionStore?.selections.length === 0) {
          toast.error(
            "Choose some selection criteria before generating report"
          );
        } else if (
          ((ReportCollectionPreferences[reportMapping.report.actor]
            ?.testParameterSelectionMandatory &&
            testParameters[0].values.length === 0) ||
            (reportMapping.report.actor ===
              ReportTypeNames.PARAMETRIC_XY_SCATTER_PLOT &&
              testParameters[0].values.length < 2)) &&
          reportMapping.report.actor !== ReportTypeNames.SPC_TREND
        ) {
          toast.error(
            `An additional test parameter needs to be selected for ${reportMapping.report.actor
              .toString()
              .toLowerCase()
              .replaceAll("_", " ")} report!`
          );
        } else {
          const reportOptions: ICustomizedReportProps = {
            reportIndex: 0,
            productType: "",
            selectionStoreDataSetCount,
            reportSessionId: this.reportSessionId,
            report: reportMapping.report,
            legend: reportMapping.legend,
            loadingPriority:
              ReportCollectionPreferences[reportMapping.report.actor]
                ?.loadingPriority || 10,
            loadingWeight:
              ReportCollectionPreferences[reportMapping.report.actor]
                ?.loadingWeight || 10,
            defaultSelectionCriteriaData:
              overrideDefaultSelectionCriteria.length > 0
                ? overrideDefaultSelectionCriteria
                : defaultSelectionCriteriaData,
            galleryData,
            type: "INDIVIDUAL_REPORT",
            selectedBinPlusDefinition,
            binPlusDefinitions,
            waferGalleryFilters,
            parseFilter: this.parseFilter,
            isSoftBin: this.getAppropriateBinTypeValue(
              JSON.parse(JSON.stringify(this.groupingSortingListStore))
            ),
            groupingSortingListStore: this.getFilteredGroupingSortingListStore(
              reportMapping.report.actor,
              JSON.parse(JSON.stringify(this.groupingSortingListStore))
            ),
            defaultGrouping: this.getDefaultGrouping(
              reportMapping.report.actor
            ),
            selectionStore: this.selectionStore,
            config:
              reportMapping.report.actor === ReportTypeNames.SPC_TREND
                ? { policyResults, spcReportIndex: ++SPCReportIndex }
                : (() => {
                    const filteredReports = this.currentReports.filter((r) => {
                      return r.key === reportInstance;
                    });
                    if (filteredReports.length > 0) {
                      if (
                        filteredReports[0].key ===
                        ReportTypeNames.BIN_WAFER_MAP_COMPARISON
                      ) {
                        filteredReports[0].config = { waferCombinations };
                      }
                      return filteredReports[0].config;
                    }
                    return {};
                  })(),
            testParameterIndex: [0],
            patLimitLines: patLimits,
            patParamCtrlLimitLines: patCtrlLimits,
            showLegend: false,
            waferSelection: selectedWaferData,
          };

          if (contextSaveDTO.workCenterTypes.length > 0) {
            contextSaveDTO.workCenterTypes.forEach((productType) => {
              if (
                reportMapping.baseType === "PLOTLY" ||
                (reportMapping.baseType === "WAFER" &&
                  productType !== "FINAL_TEST")
              ) {
                const currentReportOptions = _.cloneDeep(reportOptions);
                currentReportOptions.productType = productType;
                renderReportsList.push(currentReportOptions);
              } else if (
                reportMapping.baseType === "WAFER" &&
                productType === "FINAL_TEST"
              ) {
                toast.error(
                  `${GeneralUtils.toTitleCase(
                    reportOptions.report.actor
                  )} can not be created for Final Test data`
                );
              }
            });
          } else {
            // add report instances when no context is saved
            renderReportsList.push(reportOptions);
          }
        }
      }
    });
    return renderReportsList.sort(
      (a, b) => a.loadingPriority - b.loadingPriority
    );
  };

  getAppropriateBinTypeValue = (
    groupingSortingListStore: IGroupingSortingList
  ) => {
    if (
      groupingSortingListStore.grouping.find(
        (x: GroupingSortingDTO) => x.columnName === "die_soft_bin_number"
      )
    ) {
      return true;
    }
    return !groupingSortingListStore.grouping.find(
      (x: GroupingSortingDTO) => x.columnName === "die_hard_bin_number"
    );
  };

  getFilteredGroupingSortingListStore = (
    reportActor: string,
    groupingSortingListStore: IGroupingSortingList
  ) => {
    if (
      ReportCollectionPreferences[reportActor]?.isBinGroupingEnforced !==
        undefined &&
      ReportCollectionPreferences[reportActor]?.isBinGroupingEnforced
    ) {
      groupingSortingListStore.grouping =
        groupingSortingListStore.grouping.filter(
          (x: GroupingSortingDTO) =>
            x.columnName !== "die_hard_bin_number" &&
            x.columnName !== "die_soft_bin_number"
        );
    }
    return groupingSortingListStore;
  };

  toggleShowBinPlusTables = () => {
    const { showBinPlusTables, binPlusDefinitions } = this.state;
    if (binPlusDefinitions.length === 0) {
      httpBinPlusTable
        .getSoftHardBinPlusDefinitions("binTypes=Softbin,HardBin")
        .then((data: any) => {
          this.setState({
            showBinPlusTables: !showBinPlusTables,
            binPlusDefinitions: data,
          });
        });
    } else {
      this.setState({ showBinPlusTables: !showBinPlusTables });
    }
  };

  prepareParamsForSaveInMemory = (): IFilterRawDataDTO => {
    const filters: IExpressionsList[] = [];
    if (this.selectionStore) {
      this.selectionStore.selections.forEach((selection) => {
        selection.values.forEach((value) => {
          filters.push({
            columnName: selection.columnName!,
            controlType: selection.controlType,
            dataType: typeof value.id,
            groupConditionOn: "OR",
            operationName: "=",
            queryText: value.id,
          });
        });
      });
    }

    const TestParameterIds = filters
      .filter((x) => x.columnName === "test_parameter_id")
      .map((x) => x.queryText);

    const params: IFilterRawDataDTO = {
      Skip: 0,
      Take: 0,
      RequireTotalCount: false,
      ReportSessionId: this.reportSessionId,
      Filter: JSON.stringify(filters),
      Sort: "",
      AdvancedFilter: "",
      TestParameterIds,
      GridRenderingOptions: [],
      Grouping: [],
      Sorting: [],
      TestParameterConditions: {},
      GridTypeEnum: "UNPIVOTED",
    };

    return params;
  };

  getSelectionCriteriaData = (
    selectionCriteriaContext: ISelectionCriteriaReturnValue,
    selectionStoreDataSetCount: number
  ) => {
    const { type } = this.props;
    let anyValueSelected = false;
    let defaultSelectionCriteriaData: ISelectionCriteriaData[] = [];
    selectionCriteriaContext.selections.forEach((selection) => {
      const currentSelection = {
        entityType: selection.entityType,
        isVisibiltyDefault: selection.isVisibiltyDefault,
        visibleColumns: selection.visibleColumns,
        visibleColumnsValues: selection.visibleColumnsValues,
        values: selection.values.map((item) => item.id),
      };
      if (currentSelection.values.length > 0) {
        anyValueSelected = true;
      }
      defaultSelectionCriteriaData.push(currentSelection);
    });

    // IS SELECTION JUST CLEARED
    if (!anyValueSelected) {
      defaultSelectionCriteriaData = [];
    }

    const policySimulationNeeded = this.compareTwoList(
      this.selectionStore?.selections,
      selectionCriteriaContext.selections
    );
    this.selectionStore = selectionCriteriaContext;

    GeneralUtils.setGlobalVars(
      GeneralUtils.Constants.SCW_CONTEXT,
      selectionCriteriaContext
    );
    GeneralUtils.setGlobalVars(
      GeneralUtils.Constants.SCW_SELECTIONS,
      defaultSelectionCriteriaData
    );

    if (type === "SIMULATE_POLICY" && anyValueSelected) {
      this.setState({
        policySimulationNeeded,
        defaultSelectionCriteriaData,
        waferGalleryFilters: [],
        selectionStoreDataSetCount,
      });
      return;
    }

    this.setState(
      {
        defaultSelectionCriteriaData,
        waferGalleryFilters: [],
        selectionStoreDataSetCount,
      },
      () => {
        const params: IFilterRawDataDTO = this.prepareParamsForSaveInMemory();
        if (anyValueSelected) {
          this.setState(
            {
              isSaveAndCloseClickedCRCState: true,
              contextState: "SAVING",
            },
            () => {
              httpSCW
                .saveInMemory(params)
                .then((contextSaveDTO) => {
                  GeneralUtils.setGlobalVars(
                    GeneralUtils.Constants.CONTEXT_SAVE_DTO,
                    contextSaveDTO
                  );
                  this.setState(
                    {
                      revokeReportGeneration: false,
                      contextState: "SAVED",
                      contextSaveDTO,
                      isDataReselected: true,
                    },
                    () => {
                      if (this.isReportRequested) {
                        this.isReportRequested = false;
                        if (this.isBinWaferMapComparisonReportSelected) {
                          this.setState({
                            showBinWaferMapComparisonPopup: true,
                          });
                        } else {
                          this.addReportInstance();
                        }
                      }
                      if (this.isRawDataGridRequested) {
                        this.isRawDataGridRequested = false;
                        this.toggleShowRawDataGrid();
                      }
                    }
                  );
                })
                .catch((err) => {
                  console.log(err);
                  this.isReportRequested = false;
                  this.isRawDataGridRequested = false;

                  this.setState({
                    revokeReportGeneration: false,
                    contextState: "UNSAVED",
                  });

                  toast.error(
                    "Context was not saved properly. Please select some different data."
                  );
                });
            }
          );
        } else {
          this.setState({
            revokeReportGeneration: false,
            contextState: "UNSAVED",
          });
        }
      }
    );
  };

  compareTwoList = (prevID: any, newID: any) => {
    return JSON.stringify(prevID) !== JSON.stringify(newID);
  };

  toggleShowRawDataGrid = () => {
    const { showRawDataGrid, contextState } = this.state;
    if (contextState === "SAVING") {
      this.isRawDataGridRequested = true;
      return;
    }
    this.setState({ showRawDataGrid: !showRawDataGrid });
  };

  toggleShowReportHeaderChooser = () => {
    const { showReportHeaderChooser } = this.state;
    this.setState({ showReportHeaderChooser: !showReportHeaderChooser });
  };

  parseFilter = (selectionStore?: ISelectionCriteriaReturnValue) => {
    const { waferGalleryFilters } = this.state;
    if (waferGalleryFilters.length > 0) {
      return waferGalleryFilters;
    }
    if (selectionStore === undefined) {
      selectionStore = this.selectionStore;
    }
    const filtersList: IExpressionsList[] = [];
    if (selectionStore) {
      selectionStore.selections.forEach((selection) => {
        selection.values.forEach((value) => {
          filtersList.push({
            columnName:
              selection.columnName !== undefined ? selection.columnName : "",
            operationName: "=",
            queryText: value.id,
            controlType: selection.controlType,
            dataType: typeof value.id,
            groupConditionOn: "OR",
          });
        });
      });
    }
    return filtersList;
  };

  generateWaferGallery = () => {
    const { selectionStoreDataSetCount } = this.state;
    if (this.selectionStore?.selections.length === 0) {
      toast.error("Please select some selection criteria");
      return;
    }
    if (this.groupingSortingListStore.grouping.length === 0) {
      toast.error("Please select some grouping criteria");
      return;
    }

    const data: IGenericDetailedReportRequestObject = {
      selectionStoreDataSetCount,
      scwData: this.selectionStore?.selections.map((item) => ({
        entityType: item.controlType as EntityType,
        values: item.values.map((val) => val.id),
      })),
      filters: JSON.stringify(this.parseFilter(this.selectionStore)),
      grouping: this.groupingSortingListStore.grouping,
      sorting: this.groupingSortingListStore.sorting,
      reportSessionId: this.reportSessionId,
      function: null,
      seriesType: null,
      config: {},
    };
    httpService
      .getGroupedGalleryData(data)
      .then((galleryDataResponse: IDrilledGraphData[]) => {
        this.setState({
          galleryData: galleryDataResponse,
          showWaferGallery: true,
        });
      });
  };

  removeReport = (index: number) => {
    const { reports } = this.state;
    const newReports = _.cloneDeep(reports);
    if (index < newReports.length) {
      newReports.splice(index, 1);
      let newRenderReportsList: JSX.Element[] = [];
      newRenderReportsList = this.getCustomizedReportComponentsList(newReports);
      this.setState({
        reports: newReports,
        renderReportsList: newRenderReportsList,
        isClosedSomeReport: true,
      });
    }
  };

  getReportInstancesForSpecificTestParameter = (
    reportItem: ICustomizedReportProps,
    testParameterObj: ISelectionStoreValue,
    testParameterIndex: number
  ) => {
    const { selectedWaferData } = this.state;
    const defaultSelectionCriteriaData =
      reportItem.defaultSelectionCriteriaData.filter(
        (x: ISelectionCriteriaData) => x.entityType !== "Testparameter"
      );
    defaultSelectionCriteriaData.push({
      entityType: "Testparameter",
      values: [testParameterObj.id],
    });

    const selectionStore = JSON.parse(
      JSON.stringify(reportItem.selectionStore)
    );

    const allSelectionsExceptTestParameters = selectionStore?.selections.filter(
      (x: any) => x.entityType !== "Testparameter"
    );

    const testParameterSelections = selectionStore?.selections.filter(
      (x: any) => x.entityType === "Testparameter"
    )[0];
    testParameterSelections.values = testParameterSelections.values.filter(
      (x: any) => x.id === testParameterObj.id
    );

    selectionStore.selections = [
      ...allSelectionsExceptTestParameters,
      testParameterSelections,
    ];
    reportItem.reportIndex = this.globalReportIndex;
    this.globalReportIndex += 1;

    reportItem.rulePayLoad = testParameterObj.ruleId;
    reportItem.defaultSelectionCriteriaData = defaultSelectionCriteriaData;
    reportItem.selectionStore = selectionStore;
    reportItem.testParameterIndex = [];
    reportItem.testParameterIndex.push(testParameterIndex);
    reportItem.patLimitLines = this.allLimitLines
      ? this.allLimitLines[testParameterObj.id]
      : undefined;
    reportItem.patParamCtrlLimitLines = this.patParamCtrlLimitsLines
      ? this.patParamCtrlLimitsLines[testParameterObj.id]
      : undefined;
    reportItem.waferSelection = selectedWaferData;
    return reportItem;
  };

  renderCustomizedReport = (
    reportItem: ICustomizedReportProps,
    index: number,
    testParametersObj: ISelectionStoreValue[] | undefined
  ) => {
    if (
      ReportCollectionPreferences[reportItem.report.actor]
        ?.showReportForIndividualTestParameter
    ) {
      return testParametersObj
        ? testParametersObj.map((testParameterObj, testParameterIndex) => {
            const newReportItem =
              this.getReportInstancesForSpecificTestParameter(
                _.cloneDeep(reportItem),
                testParameterObj,
                testParameterIndex
              );
            return this.getCustomizedReportComponent(newReportItem, index);
          })
        : [];
    }
    reportItem.reportIndex = this.globalReportIndex;
    this.globalReportIndex += 1;

    if (
      reportItem.report.actor === ReportTypeNames.PARAMETRIC_XY_SCATTER_PLOT
    ) {
      reportItem.testParameterIndex = [];
      reportItem.testParameterIndex = [0, 1];
    } else {
      reportItem.testParameterIndex = [];
      if (testParametersObj) {
        testParametersObj.forEach(
          (testParameterObj: any, testParameterIndex: number) => {
            reportItem.testParameterIndex.push(testParameterIndex);
          }
        );
      }
    }

    return this.getCustomizedReportComponent(reportItem, index);
  };

  getCustomizedReportComponent = (
    reportItem: ICustomizedReportProps,
    index: number
  ) => {
    const { waferCombinations } = this.state;
    if (reportItem.report.actor === "DATA_SUMMARY") {
      return (
        <CustomizedDataSummary
          ref={this.customizedReportRef}
          productType={reportItem.productType}
          hideReportRow={() => {
            this.removeReport(index);
          }}
          type={reportItem.type}
          reportSessionId={reportItem.reportSessionId}
          report={reportItem.report}
          legend={reportItem.legend}
          defaultSelectionCriteriaData={reportItem.defaultSelectionCriteriaData}
          galleryData={reportItem.galleryData}
          selectedBinPlusDefinition={reportItem.selectedBinPlusDefinition}
          binPlusDefinitions={reportItem.binPlusDefinitions}
          parseFilter={reportItem.parseFilter}
          waferGalleryFilters={reportItem.waferGalleryFilters}
          groupingSortingListStore={reportItem.groupingSortingListStore}
          selectionStore={reportItem.selectionStore}
          config={reportItem.config}
          key={`${reportItem.report.actor}${reportItem.reportSessionId}`}
          rulePayLoad={reportItem.rulePayLoad}
          testParameterIndex={reportItem.testParameterIndex}
        />
      );
    }

    return (
      <CustomizedReport
        reportIndex={reportItem.reportIndex}
        ref={this.customizedReportRef}
        productType={reportItem.productType}
        hideReportRow={() => {
          this.removeReport(index);
        }}
        type={reportItem.type}
        reportSessionId={reportItem.reportSessionId}
        report={reportItem.report}
        legend={reportItem.legend}
        defaultSelectionCriteriaData={reportItem.defaultSelectionCriteriaData}
        galleryData={reportItem.galleryData}
        selectedBinPlusDefinition={reportItem.selectedBinPlusDefinition}
        binPlusDefinitions={reportItem.binPlusDefinitions}
        parseFilter={reportItem.parseFilter}
        waferGalleryFilters={reportItem.waferGalleryFilters}
        groupingSortingListStore={reportItem.groupingSortingListStore}
        defaultGrouping={reportItem.defaultGrouping}
        selectionStore={reportItem.selectionStore}
        config={
          reportItem.report.actor === "BIN_WAFER_MAP_COMPARISON"
            ? { waferCombinations }
            : reportItem.config
        }
        key={`${reportItem.report.actor}-${reportItem.reportIndex}-${reportItem.reportSessionId}`}
        rulePayLoad={reportItem.rulePayLoad}
        testParameterIndex={reportItem.testParameterIndex}
        selectionStoreDataSetCount={reportItem.selectionStoreDataSetCount}
        loadingPriority={reportItem.loadingPriority}
        loadingWeight={reportItem.loadingWeight}
        patLimitLines={
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_HISTOGRAM ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_TREND ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_BOX_PLOT ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_XY_SCATTER_PLOT
            ? reportItem.patLimitLines
            : []
        }
        patParamCtrlLimitLines={
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_HISTOGRAM ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_TREND ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_BOX_PLOT ||
          reportItem.report.actor === ReportTypeNames.PARAMETRIC_XY_SCATTER_PLOT
            ? reportItem.patParamCtrlLimitLines
            : []
        }
        showLegend={reportItem.showLegend}
        waferSelection={reportItem.waferSelection}
        isApplyAllReportHeaders={false}
        waferCombinations={waferCombinations}
        isSoftBin={reportItem.isSoftBin}
      />
    );
  };

  getBinPlusTableButton = () => {
    const { selectedBinPlusDefinition } = this.state;

    const button = (
      <Button
        className={`d-block w-100 h40 ${
          selectedBinPlusDefinition.id === "" ? "btn-config" : "border-all"
        }`}
        variant={selectedBinPlusDefinition.id !== "" ? "success" : "clear"}
        onClick={this.toggleShowBinPlusTables}
      >
        {getHeaderControlButtonStructure(faFolderPlus, "Bin Plus Tables")}
      </Button>
    );
    if (selectedBinPlusDefinition.id === "") {
      return button;
    }
    return selectedBinPlusDefinition.id !== "" ? (
      <OverlayTrigger
        trigger="hover"
        key="bottom"
        placement="bottom"
        rootClose
        overlay={
          <Popover id={`popover-positioned-${"bottom"}`}>
            <Popover.Content style={{ fontSize: "11px" }}>
              {selectedBinPlusDefinition.name}
            </Popover.Content>
          </Popover>
        }
      >
        {button}
      </OverlayTrigger>
    ) : (
      <></>
    );
  };

  showSpecificPolicyOutput = (selectedPolicyResult: any) => {
    this.removeAllReports();
    const { defaultSelectionCriteriaData, policyResults } = this.state;
    const { selectedData } = this.policyOverViewGridData;
    const { policyStepResult } = selectedPolicyResult;
    const selectedStageId = selectedData.selectedRowKeys[0];

    const selectedStageResult = policyStepResult.find(
      (pSResult: any) => pSResult.policyStepId === selectedStageId
    );

    this.setState({ selectedStepResult: selectedStageResult });
    const additionalFeatures = new CustomizedReportsCollectionExtensions();
    // eslint-disable-next-line max-len
    const { selectionStoreSelectionsData } =
      additionalFeatures.overridePolicySimulationDataOverSCW(
        defaultSelectionCriteriaData,
        this.selectionStore,
        selectedStageResult,
        policyResults
      );

    this.selectionStore.selections = selectionStoreSelectionsData;

    if (this.policyOverViewGridData.selectedData.length === 0) {
      this.setState({ selectedPolicy: selectedPolicyResult });
      toast.warning(
        "Please select some stage from the Policy Overview Grid on the left"
      );
    } else {
      this.removeAllReports();
      const newSelectedStageId = selectedData.selectedRowKeys[0];
      const newSelectedStageType = selectedData.selectedRowsData[0].stepType;

      this.saveContextForPolicyOutput(
        newSelectedStageId,
        selectedPolicyResult,
        newSelectedStageType
      );
    }
  };

  saveAndSimulateDuplicatePolicy = (ManualPolicyExecutionInputDTO: any) => {
    const { revokeReportGeneration } = this.state;

    toast.info("Simulating Policy");
    let tempProgress = -1;
    httpService
      .simulatePolicy(ManualPolicyExecutionInputDTO)
      .then((simulatePolicyMetaDataDTO: any) => {
        const { policyId, waferCount } = simulatePolicyMetaDataDTO;
        this.setState({ revokeReportGeneration: !revokeReportGeneration });
        const pollingFuncId = setInterval(
          () =>
            httpService
              .getPolicyResult(policyId, waferCount)
              .then((policyResultDTO: any) => {
                const progress = parseInt(policyResultDTO.progress, 10);
                if (policyResultDTO.isSimulated) {
                  const { simulatedPolicyResults } = policyResultDTO;
                  clearInterval(pollingFuncId);
                  let progressBarVariant = "success";
                  if (policyResultDTO.errorMessage !== "") {
                    toast.error(policyResultDTO.errorMessage);
                    progressBarVariant = "warning";
                  } else {
                    toast.success("Policy Simulated Successfully");
                  }
                  this.setState({
                    simulatedPolicyId: policyId,
                    revokeReportGeneration: false,
                    isSimulated: true,
                    simulatedPolicyProgress: progress,
                    simulatedPolicyProgressBarVariant: progressBarVariant,
                  });
                } else if (tempProgress !== progress) {
                  this.setState({
                    simulatedPolicyProgress: progress,
                  });
                  tempProgress = progress;
                }
              }),
          this.pollingTime
        );
      });
  };

  simulateHandler = () => {
    const { defaultSelectionCriteriaData, policyResults } = this.state;
    const { selectedData, policy } = this.policyOverViewGridData;

    const policySimulationNeeded = this.compareTwoList(
      this.prevMPEGridData,
      selectedData.selectedRowKeys
    );
    this.prevMPEGridData = selectedData.selectedRowKeys;

    if (
      Object.keys(selectedData).length === 0 ||
      selectedData.selectedRowsData.length === 0
    ) {
      toast.error("Please select some rule, set or stage");
      return;
    }

    if (defaultSelectionCriteriaData.length === 0) {
      toast.error("Please select some wafers from Selection Criteria");
      return;
    }

    const waferIds = defaultSelectionCriteriaData.find(
      (obj: any) => obj.entityType === "Wafer"
    )?.values;
    if (!waferIds || waferIds.length === 0) {
      toast.error("Please select some wafers from Selection Criteria");
      return;
    }

    // Check if SPC step has all the necessary data for processing
    if (
      policy.policyData.policySteps
        .map((step: any) => step.type)
        .includes("SPC")
    ) {
      for (
        let stepIndex = 0;
        stepIndex < policy.policyData.policySteps.length;
        stepIndex += 1
      ) {
        const step = policy.policyData.policySteps[stepIndex];
        if (step.type === "SPC") {
          if (step.stepSettings.spcReports.length === 0) {
            toast.error(
              "Please provide SPC Report settings for the SPC step in the policy"
            );
            return;
          }
          for (
            let reportIndex = 0;
            reportIndex < step.stepSettings.spcReports.length;
            reportIndex += 1
          ) {
            const report = step.stepSettings.spcReports[reportIndex];
            for (
              let paramIndex = 0;
              paramIndex < report.spcParameters.length;
              paramIndex += 1
            ) {
              const parameter = report.spcParameters[paramIndex];
              if (
                step.stepSettings.spcParameters.findIndex(
                  (param: any) => param.id === parameter.id
                ) === -1
              ) {
                toast.error(
                  "Please provide SPC Parameter settings for the SPC step in the policy"
                );
                return;
              }
              for (
                let ruleIndex = 0;
                ruleIndex < parameter.rulesApplied.length;
                ruleIndex += 1
              ) {
                const rule = parameter.rulesApplied[ruleIndex];
                if (
                  step.stepSettings.spcRules.findIndex(
                    (ruleApplied: any) => ruleApplied.id === rule.id
                  ) === -1
                ) {
                  toast.error(
                    "Please provide SPC Rule settings for the SPC step in the policy"
                  );
                  return;
                }
              }
            }
          }
        }
      }
    }

    if (!policySimulationNeeded) {
      const additionalFeatures = new CustomizedReportsCollectionExtensions();

      const { selectionStoreSelectionsData, overrideDefaultSelectionCriteria } =
        additionalFeatures.overridePolicySimulationDataOverSCW(
          defaultSelectionCriteriaData,
          this.selectionStore,
          policyResults
        );

      this.selectionStore.selections = selectionStoreSelectionsData;

      this.createReportInstancesList(overrideDefaultSelectionCriteria).then(
        (data: ICustomizedReportProps[]) => {
          this.setState(
            {
              revokeReportGeneration: false,
              reports: data,
              policySimulationNeeded,
            },
            () => toast.success("Policy Simulated")
          );
        }
      );
    } else {
      const ManualPolicyExecutionInputDTO = simulateHandlerUtility(
        selectedData,
        policy,
        waferIds
      );
      this.saveAndSimulateDuplicatePolicy(ManualPolicyExecutionInputDTO);
    }
  };

  onRowSelection = (selectedData: any, policy: any) => {
    const { selectedStageId, selectedPolicy } = this.state;
    const { type } = this.props;
    this.policyOverViewGridData = {
      selectedData,
      policy,
    };
    this.setState({
      selectedStageType:
        selectedData.selectedRowsData.length > 0
          ? selectedData.selectedRowsData[0].stepType
          : null,
      selectedStageId:
        selectedData.selectedRowsData.length > 0
          ? selectedData.selectedRowsData[0].stepId
          : null,
    });
    if (
      type &&
      type === "VIEW_POLICY_OUTPUT" &&
      Object.keys(selectedPolicy).length > 0
    ) {
      const newSelectedStageId = selectedData.selectedRowKeys[0];
      if (newSelectedStageId !== selectedStageId) {
        this.removeAllReports();
      }
    }
  };

  saveContextForPolicyOutput = (
    selectedStageId: string,
    selectedPolicyResult: any,
    selectedStageType: any
  ) => {
    const { defaultSelectionCriteriaData, policyResults } = this.state;

    const { policyStepResult } = selectedPolicyResult;

    const selectedStageResult = policyStepResult.find(
      (pSResult: any) => pSResult.policyStepId === selectedStageId
    );

    const additionalFeatures = new CustomizedReportsCollectionExtensions();
    // eslint-disable-next-line max-len
    const { selectionStoreSelectionsData } =
      additionalFeatures.overridePolicySimulationDataOverSCW(
        defaultSelectionCriteriaData,
        this.selectionStore,
        selectedStageResult,
        policyResults
      );

    this.selectionStore.selections = selectionStoreSelectionsData;

    const params: IFilterRawDataDTO = this.prepareParamsForSaveInMemory();

    this.setState(
      {
        selectedStageId,
        selectedStageType,
        // selectedPolicy: selectedPolicyResult,
        revokeReportGeneration: true,
      },
      () => {
        httpSCW
          .saveInMemory(params)
          .then(() => {
            this.setState({ revokeReportGeneration: false });
          })
          .catch(() => {
            this.setState({ revokeReportGeneration: false });
            toast.error("Unable to Save Context");
          });
      }
    );
  };

  onReportSelection = (selectedReports: any) => {
    this.currentReports = selectedReports;
  };

  onUpdateReportsSelection = (selectedReports: any) => {
    this.onReportSelection(selectedReports);
  };

  removeAllReports = () => {
    this.setState({
      reports: [],
      renderReportsList: [],
      showRawDataGrid: false,
      showWaferGallery: false,
      isReportSelectionAccordianCollapsed: false,
    });
  };

  expandAllLegends = () => {
    const { publisherId } = this;
    const { allLegendsExpanded } = this.state;
    if (allLegendsExpanded) {
      this.pubSubBinder.BroadcastEvent(
        publisherId,
        "ALL_REPORTS_AND_LEGENDS",
        "COLLAPSE_LEGEND_EVENT"
      );
    } else {
      this.pubSubBinder.BroadcastEvent(
        publisherId,
        "ALL_REPORTS_AND_LEGENDS",
        "EXPAND_LEGEND_EVENT"
      );
    }
    this.setState({ allLegendsExpanded: !allLegendsExpanded });
  };

  forceScrollToTop = () => {
    // const { virtuosoRef } = this;
    // if (virtuosoRef.current) {
    //   virtuosoRef.current.scrollToIndex(0);
    // }
  };

  forceScrollToReportIndex = (reportIndex: number) => {
    // const { virtuosoRef } = this;
    // if (virtuosoRef.current) {
    //   virtuosoRef.current.scrollToIndex(reportIndex);
    // }
    // this.setState({ showReportsToFindModal: false });
  };

  toggleShowReportsToFindModal = () => {
    this.setState({ showReportsToFindModal: true });
  };

  stateCallback = (updatedState: ICustomizedReportsCollectionBaseState) => {
    this.setState(updatedState);
  };

  reportsToFindModal = () => {
    const { currentReportTypesToShow, showReportsToFindModal } = this.state;
    return (
      <ReportFinderModal
        showReportsToFindModal={showReportsToFindModal}
        currentReportTypesToShow={currentReportTypesToShow}
        forceScrollToReportIndex={this.forceScrollToReportIndex}
        updateParentState={this.stateCallback}
      />
    );
  };

  renderPage = (Parent: any, Child: any, additionalProps: any) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Parent {...additionalProps}>{Child}</Parent>
    );
  };

  getRequestObjectForBinWaferMapComparison = (data: any) => {
    this.setState({ waferCombinations: data });
  };

  hideDataExportSpinner = (action: ActionType) => {
    if (action === "HIDE_DATA_EXPORT_SPINNER") {
      this.setState({ isDataExporting: false });
    }
  };

  invokeDataExportOperation = async (options: IExportDataOptionsDto) => {
    GeneralUtils.Constants.IS_DATA_EXPORTING = true;
    this.setState({ isDataExporting: true });
    await GeneralUtils.exportDataAsFile(
      options,
      this.publisherId,
      this.pubSubBinder.BroadcastEvent
    );
    this.setState({ isDataExporting: false });
  };

  render() {
    const {
      defaultSelectionCriteriaData,
      galleryData,
      selectedBinPlusDefinition,
      showRawDataGrid,
      showReportHeaderChooser,
      showWaferGallery,
      reports,
      binPlusDefinitions,
      showBinPlusTables,
      revokeReportGeneration,
      selectedStageType,
      contextState,
      renderReportsList,
      isReportSelectionAccordianCollapsed,
      isClosedSomeReport,
      allLegendsExpanded,
      primaryWidgetHeight,
      isSaveAndCloseClickedCRCState,
      showBinWaferMapComparisonPopup,
      showSelectionCriteriaByDefault,
      isDataReselected,
      isDataExporting,
    } = this.state;

    const { reportList, type, title, isBinBased } = this.props;

    let waferIds: string[] = [];
    let testParametersObj: ISelectionStoreValue[] = [];

    const wafers = this.selectionStore?.selections.filter(
      (x) => x.entityType === "Wafer"
    );
    if (wafers?.length > 0) {
      waferIds = wafers[0].values.map((v) => v.id);
    }
    const testParameters = this.selectionStore?.selections.find(
      (x) => x.entityType === "Testparameter"
    );
    if (testParameters) {
      testParametersObj = testParameters.values;
    }

    const generalColumnWeight:
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12 = 3;
    let adjustedColumnWeight: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 =
      generalColumnWeight;
    const visibility = true;
    let adjustedVisibility = true;
    if (isBinBased) {
      adjustedColumnWeight = 4;
      adjustedVisibility = false;
    }

    const selectionCriteriaControls: ISelectionCritereaWidget[] = [
      {
        controlType: "Facility",
        columnWeight: generalColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Work Center",
        columnWeight: generalColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Device",
        columnWeight: generalColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Test Program",
        columnWeight: generalColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Test Program Revision",
        columnWeight: adjustedColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Lot",
        columnWeight: adjustedColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Wafer",
        columnWeight: adjustedColumnWeight,
        isVisible: visibility,
      },
      {
        controlType: "Test Parameter",
        columnWeight: adjustedColumnWeight,
        isVisible: adjustedVisibility,
        highlightOtherGrids: false,
        minVisibilityCriteria: GeneralUtils.getMinVisibilityCriteria(),
      },
    ];

    if (showRawDataGrid) {
      reports.forEach((report: any) => {
        if (Object.keys(report.config).length === 0) {
          report.config = { parametricFailureReportOptions };
        }
      });
    }

    return (
      <>
        <TopbarNav
          title={title}
          items={[]}
          showAvatar={false}
          showNotifications={false}
          className=" w-100"
          secondaryActions={
            renderReportsList.length > 0 && this.dontDisable ? (
              <PowerViewProgressBar reportsCount={renderReportsList.length} />
            ) : undefined
          }
        />
        {this.renderPage(
          type !== "INDIVIDUAL_REPORT" ? ScrollView : React.Fragment,
          <Container fluid className="overflow-visible">
            {type && type !== "VIEW_POLICY_OUTPUT" && (
              <Row>
                <Col lg={12}>
                  <div
                    className="ml10 mt10 mr10 border-radius-4"
                    id={SELECTION_CRITERIA_DIV_ID}
                  >
                    <SelectionCriteriaButton
                      showByDefault={showSelectionCriteriaByDefault}
                      closeModalAfterReselection={
                        this.SelectionCriteriaCloseHandler
                      }
                      advancedFilter=""
                      getSelectionCriteriaData={this.getSelectionCriteriaData}
                      defaultSelection={defaultSelectionCriteriaData}
                      widgetsList={selectionCriteriaControls}
                      checkUsageLimits
                      isBinBased={isBinBased}
                      removeAllReports={this.removeAllReports}
                      isSaveAndCloseClickedSCProp={
                        isSaveAndCloseClickedCRCState
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {type && type !== "SIMULATE_POLICY" && (
              <div
                id={REPORT_HEADER_CONTROLS_DIV_ID}
                className="mt10 ml10 mr10"
              >
                <div className="border-all p10 background-color-light border-radius-4">
                  <Accordion
                    activeKey={isReportSelectionAccordianCollapsed ? "-1" : "0"}
                  >
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <div className="d-flex align-items-center flex-wrap mt6">
                        <h5 className="mr10">Report Selection Controls</h5>
                      </div>
                      <div style={{ display: "inline-flex" }}>
                        {isReportSelectionAccordianCollapsed && (
                          <>
                            <Button
                              className="mr10 btn"
                              variant="success"
                              onClick={this.checkPreReportGenerationOptions}
                              disabled={
                                revokeReportGeneration ||
                                (this.currentReports.length === 0 &&
                                  (!this.props.reportList ||
                                    (this.props.reportList &&
                                      this.props.reportList.length === 0)))
                              }
                            >
                              <FontAwesomeIcon
                                size="lg"
                                className="mr10"
                                icon={faChartBar}
                              />
                              Generate
                            </Button>
                            <Button
                              disabled={
                                this.selectionStore?.selections.find(
                                  (s: any) => s.values.length > 0
                                ) === undefined || contextState === "SAVING"
                              }
                              title={
                                this.selectionStore?.selections.length === 0
                                  ? "Please select some Selection Criteria to see Raw Data Grid"
                                  : undefined
                              }
                              className={`mr10 btn btn-clear ${
                                showRawDataGrid ? "border-all" : "btn-config"
                              }`}
                              variant={showRawDataGrid ? "success" : "clear"}
                              onClick={this.toggleShowRawDataGrid}
                            >
                              {getHeaderControlButtonStructure(
                                faTable,
                                "Raw Data Grid"
                              )}
                            </Button>
                            <Button
                              title="Report Header"
                              className={`mr10 btn btn-clear ${
                                showReportHeaderChooser
                                  ? "border-all"
                                  : "btn-config"
                              }`}
                              variant={
                                showReportHeaderChooser ? "success" : "clear"
                              }
                              onClick={this.toggleShowReportHeaderChooser}
                            >
                              {getHeaderControlButtonStructure(
                                faTable,
                                "Report Header"
                              )}
                            </Button>
                            <Button
                              className={`mr10 btn btn-clear btn-config ${
                                reports.length > 0 &&
                                !(
                                  reports.length === 1 &&
                                  reports[0].report.actor === "DATA_SUMMARY"
                                )
                                  ? ""
                                  : "d-none"
                              }`}
                              variant={allLegendsExpanded ? "success" : "clear"}
                              onClick={this.expandAllLegends}
                            >
                              {getHeaderControlButtonStructure(
                                faMapSigns,
                                "Toggle Legends"
                              )}
                            </Button>
                          </>
                        )}
                        {isDataExporting && (
                          <div className="mr10 d-flex align-items-center pr10 pl10 background-color-warning color-light border-radius-4">
                            <Spinner
                              className="mr10"
                              size="sm"
                              animation="border"
                              variant="clear"
                            />
                            <p className="pt13">Exporting Data...</p>
                          </div>
                        )}
                        <Accordion.Toggle
                          as={Button}
                          eventKey="0"
                          variant="clear"
                          style={{ width: "50px", border: "1px solid #dddddd" }}
                          className="transition-smooth p4 h32"
                          onClick={() => {
                            this.setState(
                              (
                                prevState: ICustomizedReportsCollectionBaseState
                              ) => {
                                return {
                                  isReportSelectionAccordianCollapsed:
                                    !prevState.isReportSelectionAccordianCollapsed,
                                };
                              }
                            );
                          }}
                        >
                          <FontAwesomeIcon size="sm" icon={faChevronDown} />
                        </Accordion.Toggle>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey="0">
                      <Row>
                        <Col lg={6} className="pr0">
                          <CustomizedReportHeaderControls
                            windowType={type}
                            onUpdateReportsSelection={
                              this.onUpdateReportsSelection
                            }
                            generateReportHandler={
                              this.checkPreReportGenerationOptions
                            }
                            revokeReportGeneration={revokeReportGeneration}
                            allowReportsSelection={reportList.length === 0}
                            selectedStageType={selectedStageType}
                            secondaryControls={[
                              this.getBinPlusTableButton(),
                              <Button
                                disabled={
                                  this.selectionStore?.selections.find(
                                    (s: any) => s.values.length > 0
                                  ) === undefined || contextState === "SAVING"
                                }
                                title={
                                  this.selectionStore?.selections.length === 0
                                    ? "Please select some Selection Criteria to see Raw Data Grid"
                                    : undefined
                                }
                                className={`d-block w-100 h40 ${
                                  showRawDataGrid ? "border-all" : "btn-config"
                                }`}
                                variant={showRawDataGrid ? "success" : "clear"}
                                onClick={this.toggleShowRawDataGrid}
                              >
                                {getHeaderControlButtonStructure(
                                  faTable,
                                  "Raw Data Grid"
                                )}
                              </Button>,
                              <Button
                                title="Report Header"
                                className={`d-block w-100 h40 ${
                                  showReportHeaderChooser
                                    ? "border-all"
                                    : "btn-config"
                                }`}
                                variant={
                                  showReportHeaderChooser ? "success" : "clear"
                                }
                                onClick={this.toggleShowReportHeaderChooser}
                              >
                                {getHeaderControlButtonStructure(
                                  faTable,
                                  "Report Header"
                                )}
                              </Button>,
                              <Button
                                className={`${
                                  AppConstants.isTempHidden
                                    ? "d-none"
                                    : "d-block"
                                } w-100 h40 ${
                                  showWaferGallery ? "border-all" : "btn-config"
                                }`}
                                variant={showWaferGallery ? "success" : "clear"}
                                onClick={() => {
                                  this.generateWaferGallery();
                                }}
                              >
                                {getHeaderControlButtonStructure(
                                  faCircleNotch,
                                  "Gallery View"
                                )}
                              </Button>,
                              reportList.length === 0 ? (
                                <Button
                                  className={`w-100 h40 btn-config ${
                                    reports.length > 0 &&
                                    !(
                                      reports.length === 1 &&
                                      reports[0].report.actor === "DATA_SUMMARY"
                                    )
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                  variant={
                                    allLegendsExpanded ? "success" : "clear"
                                  }
                                  onClick={this.expandAllLegends}
                                >
                                  {getHeaderControlButtonStructure(
                                    faMapSigns,
                                    "Toggle Legends"
                                  )}
                                </Button>
                              ) : (
                                <></>
                              ),
                              this.dontDisable ? (
                                <Button
                                  className={`w-100 h40 ${
                                    reports.length > 0 ? "d-block" : "d-none"
                                  }`}
                                  variant="danger"
                                  onClick={this.removeAllReports}
                                >
                                  {getHeaderControlButtonStructure(
                                    faTimes,
                                    "Remove All"
                                  )}
                                </Button>
                              ) : (
                                <></>
                              ),
                            ]}
                          />
                        </Col>
                        <Col lg={6} className="pl0">
                          <div className="ml10 mt10 mr10 p10 pt16 background-color-light border-all border-radius-4">
                            <GroupSortWidget
                              heightClass={
                                reportList.length === 0 ? "h290" : "h120"
                              }
                              isDefaultGroupingRequired={selectedStageType}
                              filterCriteria={[]}
                              showAccordion={false}
                              getGroupingSortingData={(
                                data: IGroupingSortingList
                              ) => {
                                this.groupingSortingListStore = data;
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              </div>
            )}

            <BinWaferMapComparisonPopup
              show={
                type === "VIEW_POLICY_OUTPUT"
                  ? false
                  : showBinWaferMapComparisonPopup
              }
              isDataReselected={isDataReselected}
              requestObjectForBinWaferMapComparisonSelectionData={{
                productType: "WAFER_SORT",
                scwData: this.selectionStore.selections.map((item) => ({
                  entityType: item.controlType as EntityType,
                  values: item.values.map((val: any) => val.id),
                })),
                reportSessionId: this.reportSessionId,
                filters: "",
                grouping: [],
                sorting: [],
                config: {},
                function: null,
                seriesType: null,
              }}
              updateParentState={(newState: any) => {
                this.setState(newState);
              }}
              generateReports={this.addReportInstance}
              getRequestObject={this.getRequestObjectForBinWaferMapComparison}
              showSelectionCriteriaByDefault={this.SelectionCriteriaOpenHandler}
            />

            {this.renderPolicyOutputComponent()}

            <Modal
              show={
                contextState === "SAVING" && revokeReportGeneration === true
              }
              centered
              enforceFocus={false}
            >
              <Modal.Body style={{ alignItems: "start" }}>
                <ContextSavingSpinner mode="POWER_VIEW" title="Processing" />
              </Modal.Body>
            </Modal>

            {showWaferGallery && galleryData !== undefined && (
              <Row>
                <Col lg={12}>
                  <div className="m10 border-all background-color-light p10">
                    <div className="d-flex align-item-center justify-content-between mb10 pt10">
                      <h5>Gallery View</h5>
                      <div className="d-flex mt-10">
                        <Button
                          className="btn-config"
                          variant="clear"
                          onClick={() => {
                            this.setState({ showWaferGallery: false });
                          }}
                        >
                          <FontAwesomeIcon size="sm" icon={faTimes} />
                        </Button>
                      </div>
                    </div>
                    <Gallery
                      galleryData={galleryData as IDrilledGraphData[]}
                      generateReport={(filters: IExpressionsList[]) => {
                        this.setState({ waferGalleryFilters: filters }, () => {
                          this.addReportInstance();
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col lg={12}>
                <div className="p10 ">
                  <WidgetCard
                    show={
                      showRawDataGrid &&
                      this.selectionStore?.selections.length > 0
                    }
                    pinToggleCallback={() => {}}
                    fullWidth
                    linkToggle={false}
                    expandToggle
                    changeExpandedStateComponentOnUpdate={false}
                    pinToggle={false}
                    pinWidgetByDefault
                    id="raw_data_grid"
                    widgetName="Raw Data Grid"
                    primaryWidgetWeight={12}
                    updatePrimaryWidgetHeightAndWidth={(newHeight: string) => {
                      this.setState({ primaryWidgetHeight: newHeight });
                    }}
                    primaryWidget={
                      <div className="w-100">
                        <CustomizedRawDataGrid
                          title={GeneralUtils.toTitleCase(
                            ReportTypeNames.RAW_DATA_GRID.replaceAll("_", " ")
                          )}
                          viewAs={ReportTypeNames.RAW_DATA_GRID}
                          height={primaryWidgetHeight}
                          gridRenderingOptions={[]}
                          className="p20"
                          reportSessionId={this.reportSessionId}
                          setRawDataGridInstanceHandler={() => undefined}
                          powerviewSessionId="individual_bin_histogram_report"
                          id="individual_bin_histogram_report"
                          testParameterConditions={
                            this.selectionStore?.testParameterConditions
                          }
                          groupingSortingListStore={
                            this.groupingSortingListStore
                          }
                          parseFilter={this.parseFilter}
                          selectionStore={this.selectionStore}
                          aggredateFunction={null}
                          config={{}}
                          filterData={false}
                          selectedBinPlusDefId={selectedBinPlusDefinition.id}
                          isSoftBin
                          invokeDataExportOperation={
                            this.invokeDataExportOperation
                          }
                        />
                      </div>
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <ScrollableReports
                  isClosedSomeReport={isClosedSomeReport}
                  renderReportsList={renderReportsList}
                />
              </Col>
            </Row>

            {this.reportsToFindModal()}
          </Container>,
          {
            height: "100%",
            useNative: true,
            showScrollbar: "always",
            scrollByThumb: true,
            reachBottomText: "",
          }
        )}

        {this.dontDisable && (
          <SpeedDialAction
            icon="chevronup"
            label="Scroll to top"
            onClick={this.forceScrollToTop}
          />
        )}
        {this.dontDisable && (
          <SpeedDialAction
            icon="showpanel"
            label="Scroll to specified report"
            onClick={this.toggleShowReportsToFindModal}
          />
        )}

        <Modal show={showReportHeaderChooser} size="xl">
          <Modal.Header>
            <Modal.Title as="h5">Report Headers</Modal.Title>
            <span className="float-right">
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({ showReportHeaderChooser: false })
                }
              >
                <FontAwesomeIcon size="lg" icon={faTimes} />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="background-color-body-background">
            <div className="h-100">
              <ReportHeaderChooser
                applyActions={{
                  APPLY_ALL: true,
                  APPLY_SELECTED: false,
                  APPLY_SIMILAR: false,
                }}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showBinPlusTables}
          onHide={this.toggleShowBinPlusTables}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">Bin Plus Table</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="h-100">
              <CustomizedDataGrid
                tableData={binPlusDefinitions}
                height={600}
                scrollMode="virtual"
                showFilterRow
                showAdvancedFilters={false}
                showGroupPanel={false}
                enableColumnChooser={false}
                keyExpr="binPlusTable.id"
                fields={[
                  {
                    caption: "Bin Number",
                    dataField: "number",
                    dataType: "number",
                    sortOrder: "asc",
                    calculateSortValue: (e: any) => {
                      // eslint-disable-next-line no-restricted-globals
                      return isNaN(+e.number) ? -1 : +e.number;
                    },
                  },
                  {
                    caption: "Bin Name",
                    dataField: "name",
                    dataType: "string",
                  },
                  {
                    caption: "Bin Type",
                    dataField: "groupType",
                    dataType: "string",
                    groupIndex: 0,
                  },
                  {
                    caption: "Bin Plus Table Name",
                    dataField: "binPlusTable.id",
                    dataType: "string",
                    groupIndex: 1,
                    autoExpand: false,
                    groupCellRender: (e: any) => {
                      return (
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="checkbox"
                            className="mr10 color-primary color-background-primary"
                            checked={
                              selectedBinPlusDefinition.id === e.data.key
                            }
                            onClick={() => {
                              if (
                                e.data.items !== null &&
                                e.data.items.length > 0
                              ) {
                                this.setState({
                                  selectedBinPlusDefinition: {
                                    id: e.data.key,
                                    name: e.data.items[0].binPlusTable.name,
                                  },
                                  showBinPlusTables: false,
                                });
                              } else if (
                                e.data.collapsedItems &&
                                e.data.collapsedItems !== null &&
                                e.data.collapsedItems.length > 0
                              ) {
                                this.setState({
                                  selectedBinPlusDefinition: {
                                    id: e.data.key,
                                    name: e.data.collapsedItems[0].binPlusTable
                                      .name,
                                  },
                                  showBinPlusTables: false,
                                });
                              }
                            }}
                          />
                          {e.data.items !== null && e.data.items.length > 0 && (
                            <div className="d-flex align-items-center">
                              <h6 className="m0 p0 mr20">
                                <b>Name</b>
                              </h6>
                              <h6 className="m0 p0 mr20">
                                {e.data.items[0].binPlusTable.name}
                              </h6>
                            </div>
                          )}
                          {e.data.collapsedItems &&
                            e.data.collapsedItems !== null &&
                            e.data.collapsedItems.length > 0 && (
                              <div className="d-flex align-items-center">
                                <h6 className="m0 p0 mr20">
                                  <b>Name</b>
                                </h6>
                                <h6 className="m0 p0 mr20">
                                  {e.data.collapsedItems[0].binPlusTable.name}
                                </h6>
                              </div>
                            )}
                        </div>
                      );
                    },
                  },
                  {
                    caption: "Color",
                    dataField: "color",
                    colorField: true,
                    cellRender: (e: any) => {
                      return (
                        <div
                          className="w20 h20 mr5 mt0 ml0 mb0 "
                          style={{ backgroundColor: e.data.color }}
                        />
                      );
                    },
                  },
                  {
                    caption: "Pass/Fail",
                    dataField: "passFail",
                    dataType: "string",
                  },
                  {
                    caption: "Retest Enabled",
                    dataField: "retestEnabled",
                    dataType: "string",
                  },
                ]}
                selectionMode="single"
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(CustomizedReportsCollection);
