import { DataTypes } from 'components/device-setup/device-setup';
import { addErrorClasses } from 'components/template-component/form-error-label/ErrorLabel';
import { TagBox } from 'devextreme-react';
import _ from 'lodash';
import React from 'react';
import {
  Form, OverlayTrigger, Popover, Row,
} from 'react-bootstrap';
import CustomizedDropdown from '../customized-dropdown/CustomizedDropdown';
import Button from '../hint-controls/Button';
import Heading from '../hint-controls/Heading';
import Label from '../hint-controls/Label';
import Textbox from '../hint-controls/Textbox';
import Toggle from '../hint-controls/Toggle';

type CreateDynamicColumnProps = {
  getColumnInfo: (columnInfo: any, attributeApplicationType?: AttributeApplicationType) => void,
  buttonContent?: any,
  needExpression?: boolean,
  columnTypes?: any[],
  overideInWorkCenters?: boolean,
  workCentersList?: any,
  showEntityTypes?: boolean,
  onClick?: (showCreateColumn: () => void) => void,
  isReticleSiteAttribute?: boolean,
  isPopover?: boolean,
  needUDAConstraints?: boolean,
  needUDAAccess?: boolean,
  needUDAType?: boolean,
};

type CreateDynamicColumnState = {
  showPopover: boolean,
  formErrors: { [key: string]: any },
  formData: { [key: string]: any },
};

// eslint-disable-next-line no-unused-vars
export enum AttributeApplicationType { APPLY_TO_ALL, APPLY_TO_SELECTED }

const ENTITY_TYPES = [
  ['FACILITY', 'Facility'],
  ['WORK_CENTER', 'Work Center'],
  ['DEVICE', 'Device'],
  ['TEST_PROGRAM', 'Test Program'],
  ['TEST_PROGRAM_REVISION', 'Test Program Revision'],
  ['LOT', 'Lot'],
  ['WAFER', 'Wafer'],
  ['DIE', 'Die'],
];

const COLUMN_TYPES = [
  ['string', 'Text'],
  ['number', 'Number'],
  ['date', 'Date'],
  ['boolean', 'Boolean'],
];

const defaultFormData = {
  overrideInWorkCenters: [],
  columnName: '',
  columnLabel: '',
  expression: '',
  isPublic: true,
  entityType: ENTITY_TYPES[0][0],
  canOverride: false,
  textLength: 255,
  minValue: -10000,
  maxValue: 10000,
  columnType: COLUMN_TYPES[0][0],
};

class CreateDynamicColumn extends React.Component<CreateDynamicColumnProps, CreateDynamicColumnState> {
  static defaultProps = {
    needExpression: true,
    overideInWorkCenters: false,
    showEntityTypes: false,
    isReticleSiteAttribute: false,
    needUDAConstraints: true,
    needUDAAccess: true,
    needUDAType: true,
  };

  private columnName: any;

  private columnType: any = '';

  private columnExpression: any;

  constructor(props: CreateDynamicColumnProps) {
    super(props);
    this.state = {
      showPopover: false,
      formData: defaultFormData,
      formErrors: {},
    };
    this.columnName = React.createRef();
    this.columnExpression = React.createRef();
  }

  showCreateColumn = () => {
    let { showPopover } = this.state;
    showPopover = !showPopover;
    this.setState(
      {
        showPopover,
        formData: defaultFormData,
        formErrors: {},
      },
    );
  };

  // eslint-disable-next-line no-unused-vars
  validate = (formData: any) => {
    const errors: { [key: string]: any } = {};
    const {
      columnName,
      columnLabel,
      columnType,
      textLength,
      minValue,
      maxValue,
    } = formData;
    if (!columnName || columnName === '') {
      errors.columnName = 'Name is required';
    } else if (columnName.length > 255) {
      errors.columnName = 'Name should not exceed 255 characters';
    } else if (!(new RegExp('^[a-zA-Z0-9 _]+$')).test(columnName)) {
      errors.columnName = 'Name should only contain alpha-numeric values';
    }
    if (!columnLabel || columnLabel === '') {
      errors.columnLabel = 'Label is required';
    } else if (columnLabel.length > 255) {
      errors.columnLabel = 'Label should not exceed 255 characters';
    } else if (!(new RegExp('^[a-zA-Z0-9 _]+$')).test(columnLabel)) {
      errors.columnLabel = 'Label should only contain alpha-numeric values';
    }
    if (columnType === DataTypes.STRING) {
      if (!textLength || textLength === '') {
        errors.textLength = 'Text length is required';
      }
      if (textLength > 511) {
        errors.textLength = 'Text length can not exceed 511.';
      }
      if (textLength < 0) {
        errors.textLength = 'Text length should be greater than 0.';
      }
    }
    if (columnType === DataTypes.NUMBER) {
      if (!minValue || minValue === '') {
        errors.minValue = 'Min value is required';
      }
      if (!maxValue || maxValue === '') {
        errors.maxValue = 'Max value is required';
      }
    }
    return errors;
  };

  CreateColumn = (attributeApplicationType?: AttributeApplicationType) => {
    const { getColumnInfo } = this.props;
    const { formData } = this.state;
    const errors = this.validate(formData);
    if (Object.keys(errors).length !== 0) {
      this.setState({ formErrors: errors });
    } else {
      const columnInfo: any = {
        columnName: formData.columnName,
        columnLabel: formData.columnLabel,
        isPublic: formData.isPublic,
        entityType: formData.entityType,
        textLength: +formData.textLength,
        minValue: +formData.minValue,
        maxValue: +formData.maxValue,
        columnType: formData.columnType === '' ? 'string' : formData.columnType,
        columnExpression: formData.expression,
        overrideInWorkCenters: formData.overrideInWorkCenters,
      };
      if (getColumnInfo !== undefined) {
        getColumnInfo(columnInfo, attributeApplicationType);
      }
      this.showCreateColumn();
    }
  };

  onValueChanged = (field: string, value: any) => {
    this.setState((prevState) => {
      const newFormData = _.cloneDeep(prevState.formData);
      newFormData[field] = value;
      return {
        formData: newFormData,
      };
    });
  };

  render() {
    const {
      showPopover,
      formData,
      formErrors,
    } = this.state;
    const {
      buttonContent,
      needExpression,
      columnTypes,
      overideInWorkCenters,
      workCentersList,
      showEntityTypes,
      onClick,
      isReticleSiteAttribute,
      isPopover,
      needUDAAccess,
      needUDAConstraints,
      needUDAType,
    } = this.props;
    const createColumn: any = (
      <div className="h300">
        <Heading size={5}>General Settings</Heading>
        <div className="custom-form p20">
          <Form.Group as={Row}>
            <Label
              labelTitle="Attribute Name"
              labelPosition="left-middle"
              labelSize="20"
              fieldSize="60"
              errorSize="100"
              required
              error={formErrors.columnName}
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.columnName,
                  true,
                )}`}
                type="text"
                name="columnName"
                defaultValue={formData.columnName}
                placeholder="Attribute Name"
                onChange={(e: any) => {
                  const { value } = e.target;
                  this.onValueChanged('columnName', value);
                }}
              />
            </Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Label
              labelTitle="Attribute Label"
              labelPosition="left-middle"
              labelSize="20"
              fieldSize="60"
              errorSize="100"
              required
              error={formErrors.columnLabel}
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                className={`${addErrorClasses(
                  formErrors.columnLabel,
                  true,
                )}`}
                type="text"
                name="columnLabel"
                defaultValue={formData.columnLabel}
                placeholder="Attribute Label"
                onChange={(e: any) => {
                  const { value } = e.target;
                  this.onValueChanged('columnLabel', value);
                }}
              />
            </Label>
          </Form.Group>
          {needUDAType && (
            <Form.Group as={Row}>
              <Label
                labelTitle="Attribute Type"
                labelPosition="left-middle"
                labelSize="20"
                fieldSize="60"
                required
                errorSize="20"
              >
                <CustomizedDropdown
                  onChange={(e: any) => {
                    this.onValueChanged('columnType', e);
                  }}
                  full
                  selectedValue={formData.columnType || (columnTypes ? columnTypes[0][0] : COLUMN_TYPES[0][0])}
                  variant="outline-primary"
                  list={(columnTypes || COLUMN_TYPES)}
                />
              </Label>
            </Form.Group>
          )}
          {showEntityTypes && (
            <Form.Group as={Row}>
              <Label
                labelTitle="Entity Type"
                labelPosition="left-middle"
                labelSize="20"
                fieldSize="60"
                required
                errorSize="20"
              >
                <CustomizedDropdown
                  list={ENTITY_TYPES}
                  full
                  selectedValue={formData.entityType || ENTITY_TYPES[0][0]}
                  onChange={(e: any) => {
                    this.onValueChanged('entityType', e);
                  }}
                />
              </Label>
            </Form.Group>
          )}
          <div style={{ display: needExpression ? 'block' : 'none' }}>
            <Form.Group as={Row}>
              <Label
                labelTitle="Expression"
                labelPosition="left-middle"
                labelSize="20"
                fieldSize="80"
                errorSize="100"
                error={formErrors.expression}
                isFieldTouched
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.expression,
                    true,
                  )}`}
                  type="text"
                  name="expression"
                  defaultValue={formData.expression}
                  placeholder="Expression"
                  onChange={(e: any) => {
                    const { value } = e.target;
                    this.onValueChanged('expression', value);
                  }}
                />
              </Label>
            </Form.Group>
          </div>
          <Row className="mt10 mb10">
            {needUDAAccess && (
              <Toggle
                title="Is Public?"
                id="is_public_dynamic_column"
                name="isPublicDynamicColumn"
                checked={formData.isPublic}
                className="mr30 custom-switch"
                onChange={(e: any) => {
                  const { checked } = e.target;
                  this.onValueChanged('isPublic', checked);
                }}
              />
            )}
            {overideInWorkCenters && (
              <Toggle
                title="Can Override?"
                id="canOverride_dynamic_column"
                name="canOverrideDynamicColumn"
                checked={formData.canOverride}
                className="mr10 custom-switch"
                onChange={(e: any) => {
                  const { checked } = e.target;
                  this.onValueChanged('canOverride', checked);
                }}
              />
            )}
          </Row>
          <Form.Group as={Row} style={{ display: formData.canOverride && overideInWorkCenters ? 'block' : 'none' }}>
            <Label
              labelTitle="Enable At"
              labelPosition="left-middle"
              labelSize="20"
              fieldSize="60"
              errorSize="20"
            >
              <TagBox
                showSelectionControls
                placeholder="Select workcenters to enable the attribute at..."
                items={workCentersList}
                onValueChange={(value: any) => {
                  this.onValueChanged('overrideInWorkCenters', [...value]);
                }}
                maxDisplayedTags={10}
                displayExpr="name"
                valueExpr="id"
              />
            </Label>
          </Form.Group>
        </div>

        {needUDAConstraints && (
          <div className="custom-form p20">
            <div style={{ display: formData.columnType === 'string' ? 'block' : 'none' }}>
              <Heading size={5}>Constraints</Heading>
              <Form.Group as={Row}>
                <Label
                  labelTitle="Text Length"
                  labelPosition="left-middle"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  required
                  error={formErrors.textLength}
                  isFieldTouched
                  className="mb15"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.textLength,
                      true,
                    )}`}
                    type="number"
                    name="textLength"
                    defaultValue={formData.textLength}
                    placeholder="Text Length"
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.onValueChanged('textLength', value);
                    }}
                  />
                </Label>
              </Form.Group>
            </div>
            <div style={{ display: formData.columnType === 'number' ? 'block' : 'none' }}>
              <Heading size={5}>Constraints</Heading>
              <Form.Group as={Row}>
                <Label
                  labelTitle="Min Value"
                  labelPosition="left-middle"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  required
                  error={formErrors.minValue}
                  isFieldTouched
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.minValue,
                      true,
                    )}`}
                    type="number"
                    name="minValue"
                    defaultValue={formData.minValue}
                    placeholder="Min Value"
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.onValueChanged('minValue', value);
                    }}
                  />
                </Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Label
                  labelTitle="Max Value"
                  labelPosition="left-middle"
                  labelSize="20"
                  fieldSize="80"
                  errorSize="100"
                  required
                  error={formErrors.maxValue}
                  isFieldTouched
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      formErrors.maxValue,
                      true,
                    )}`}
                    type="number"
                    name="maxValue"
                    defaultValue={formData.maxValue}
                    placeholder="Max Value"
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.onValueChanged('maxValue', value);
                    }}
                  />
                </Label>
              </Form.Group>
            </div>
          </div>
        )}

        {!isReticleSiteAttribute ? (<Button variant="primary float-right mr10 mb20" onClick={this.CreateColumn}>Create</Button>)
          : (
            <>
              <Button
                variant="primary float-right mr10 mb20"
                onClick={() => {
                  this.CreateColumn(AttributeApplicationType.APPLY_TO_ALL);
                }}
              >
                Apply To All
              </Button>
              <Button
                variant="primary float-right mr10 mb20"
                onClick={() => {
                  this.CreateColumn(AttributeApplicationType.APPLY_TO_SELECTED);
                }}
              >
                Apply To Selected
              </Button>
            </>
          )}
      </div>
    );

    const createColumnInPopover: any = (
      <Popover id="popover-basic" className="w700p create-columns" show={showPopover} tabIndex={-1}>
        <Popover.Title as="h3" className="pr0">
          Create UDA
          <Button variant="outline float-right p0 pl5 pr10 text-danger dx-icon-close" onClick={this.showCreateColumn}><i /></Button>
        </Popover.Title>
        <Popover.Content>
          {createColumn}
        </Popover.Content>
      </Popover>
    );

    return (
      isPopover === false
        ? (
          <div>
            {createColumn}
          </div>
        )
        : (
          <OverlayTrigger rootClose trigger="focus" show={showPopover} placement="auto" overlay={createColumnInPopover}>
            <Button
              type="button"
              className="border-all"
              variant="outline-gray p7 "
              title="Create Dynamic Column"
              onClick={onClick ? () => {
                onClick(this.showCreateColumn);
              } : this.showCreateColumn}
            >
              {buttonContent || <i className="dx-icon-plus" />}
            </Button>
          </OverlayTrigger>
        )

    );
  }
}

export default CreateDynamicColumn;
