import React from 'react';
import './avatar.scss';

interface IAvatarProps {
  title?: string;
  subtitle?: string;
  img?: any;
  mode?: string;
  size?: string;
  className?: string;
  heading?: string;
}

const Avatar = (props: IAvatarProps) => {
  const {
    title, subtitle, img, size, className, mode, heading,
  } = props;
  const avatarTitle = <span className="title ml10">{title}</span>;
  const avatarHeading = (
    <span className="subtitle ml10">{heading}</span>
  );
  const avatarSubtitle = (
    <span className="subtitle ml10">{subtitle}</span>
  );

  let image = null;
  if (img !== null && img !== undefined) {
    image = (
      <div
        className={`avatar-img ${size}`}
        style={{ backgroundImage: `url(${img})` }}
      />
    );
  } else if (title) {
    let namePlaceholder = '';
    title.split(' ').forEach((item, index) => {
      if (index < 2) {
        namePlaceholder += item.substring(0, 1);
      }
    });
    image = (
      <div className={`avatar-img ${size}`}>
        {namePlaceholder}
      </div>
    );
  }

  return (
    <div className={`avatar ${className}`}>
      {image}
      <div className={`text-left avatar-text avatar-mode-${mode}`}>
        {heading !== '' && avatarHeading}
        {title !== '' && avatarTitle}
        {subtitle !== '' && avatarSubtitle}
      </div>
    </div>
  );
};

Avatar.defaultProps = {
  title: '',
  subtitle: '',
  img: null,
  mode: 'dark',
  size: 'lg',
  className: '',
  heading: '',
};

export default Avatar;
