/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-update-set-state */
import TreeView from 'devextreme-react/tree-view';
import React, { Component } from 'react';
import { OverlayTrigger, Popover, } from 'react-bootstrap';
import Button from 'components/wrapped-component/hint-controls/Button';
import { IColumnStructure } from 'interfaces';

interface IColumnChooserProps {
  fields: any;
  applyColumnChanges: (fieldInfo: any) => void;
  title?: string | undefined;
  mode?: 'standard' | 'row' | undefined;
  columnChooserTitle?: string | undefined;
}

interface IColumnChooserState {
  hideColumnChooser: boolean;
}

class ColumnChooser extends Component<IColumnChooserProps, IColumnChooserState> {
  private data: IColumnStructure[];

  private userPreferenceColumns: IColumnStructure[];

  private fields: IColumnStructure[];

  constructor(props: IColumnChooserProps) {
    super(props);
    this.fields = [];
    const str = localStorage.getItem('preferedColumns');
    this.userPreferenceColumns = JSON.parse(str ?? '[]');
    this.compareField(props.fields, this.userPreferenceColumns);
    this.data = [];
    this.state = {
      hideColumnChooser: false,
    };
  }

  compareField = (sourceColumns: IColumnStructure[], userPreferenceColumns: IColumnStructure[]) => {
    const sourceKeys = Object.keys(sourceColumns)
      .sort();
    const userPreferenceColumnKeys = Object.keys(userPreferenceColumns)
      .sort();

    // Compare both have same key (properties)
    if (userPreferenceColumns.length !== 0 && JSON.stringify(sourceKeys) === JSON.stringify(userPreferenceColumnKeys)) {
      this.fields = userPreferenceColumns;
    } else {
      localStorage.removeItem('preferedColumns');
      this.fields = sourceColumns;
    }
    if (this.fields.length > 0) {
      this.fields.forEach((item: any, index: number) => {
        item.fields = item.fields.filter((subItem: any) => subItem.hideInColumnChooser === false);
      });
    }
  };

  applyColumnChanges = () => {
    const { applyColumnChanges } = this.props;
    if (applyColumnChanges !== undefined) {
      applyColumnChanges(this.data);
      const data = JSON.stringify(this.data);
      localStorage.setItem('preferedColumns', data);
      this.setState({ hideColumnChooser: true });
      return this.data;
    }
  };

  // serializeTreeViewData = (data: any) => {
  //   this.data = [];
  //   data.forEach((item:any) => {
  //     const entityType = item.itemData;
  //     if (item.selected === undefined || item.selected === true) {
  //       entityType.selected = true;
  //       const modifiedDataFields:{[key:string]: boolean} = {};
  //       item.items.forEach((i:any) => {
  //         (modifiedDataFields as any)[i.key] = i.selected;
  //       });
  //       entityType.fields.forEach((field:any) => {
  //         if ((modifiedDataFields as any)[field.dataField] !== undefined) {
  //           field.selected = (modifiedDataFields as any)[field.dataField];
  //         }
  //       });
  //     } else {
  //       entityType.selected = false;
  //       entityType.fields.forEach((field:any) => {
  //         field.selected = false;
  //       });
  //     }
  //     this.data.push(entityType);
  //   });
  // }

  serializeTreeViewData = (data: any) => {
    this.data = data.map((item: any) => (item.itemData));
  };

  renderTreeViewItem = (item: any) => {
    // let returnValue = '';
    // if (Array.isArray(item.caption)) {
    //   returnValue = item.caption.map((captionItem:any) => (captionItem.value)).join(' ');
    // } else if (item.caption.indexOf('][') !== -1) {
    //   const captionValue = item.caption.split('][');
    //   returnValue = `${captionValue[0]} (${captionValue[1]})`;
    // } else {
    //   returnValue = item.caption;
    // }
    // return returnValue;
    if (item.visibleCaption !== null && item.visibleCaption !== undefined) {
      return item.visibleCaption;
    }
    if (Array.isArray(item.caption)) {
      return item.caption.map((captionItem: any) => (captionItem.value))
        .join(' ');
    }
    return 'Not Applicable';
  };

  render() {
    const {
      title,
      mode,
      columnChooserTitle,
    } = this.props;

    const { hideColumnChooser } = this.state;

    const popover = (
      <Popover id="popover-basic" className={`column-chooser ${hideColumnChooser ? 'd-none' : ''}`}>
        <Popover.Title as="h3">{title || 'Column Chooser'}</Popover.Title>
        <Popover.Content>
          <div className="h450 w400">
            <TreeView
              id={new Date().toString()}
              items={this.fields.length > 0 ? this.fields.filter((item: any) => item.hideInColumnChooser === false) : []}
              displayExpr="columnName"
              itemsExpr="fields"
              keyExpr="dataField"
              width="100%"
              height={400}
              searchExpr="visibleCaption"
              searchEnabled
              selectNodesRecursive
              showCheckBoxesMode="selectAll"
              selectionMode="multiple"
              className="border-bottom"
              itemRender={this.renderTreeViewItem}
              onSelectionChanged={(e: any) => {
                this.serializeTreeViewData(e.component.getNodes());
              }}
            />
            <div className="w100p p5 mb5 ">
              <Button variant="primary float-right mt10 pt2 pb4" onClick={this.applyColumnChanges}>Apply</Button>
            </div>
          </div>
        </Popover.Content>
      </Popover>

    );
    return (
      <div title={title || 'Column Chooser'} className={mode === undefined || mode === 'standard' ? 'btn-column-chooser' : ''}>
        <OverlayTrigger onEntering={() => this.setState({ hideColumnChooser: false })} trigger="click" placement="left" overlay={popover} rootClose>
          <Button
            className="btn-config"
            variant="clear"
          >
            <i className={`dx-icon dx-icon-column-chooser ${columnChooserTitle ? 'mr8' : ''}`} />
            {columnChooserTitle ?? ''}
          </Button>
        </OverlayTrigger>
      </div>
    );
  }
}

export default ColumnChooser;
