import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../wrapped-component/hint-controls/Button';

interface ILocationTypeValidationButtonProps {
  isValidationInProcess: boolean;
  isSourceLocationPolicyStep: boolean;
  validateLocation: () => void;
}

const LocationTypeValidationButton = (props:ILocationTypeValidationButtonProps) => {
  const {
    isValidationInProcess,
    validateLocation,
    isSourceLocationPolicyStep,
  } = props;

  return (
    <div className="d-flex align-items-top justify-content-between">

      <Button
        variant="outline-warning"
        className="w40"
        title="Check if location is accessible"
        disabled={isValidationInProcess || isSourceLocationPolicyStep}
        onClick={async () => {
          validateLocation();
        }}
      >
        { isValidationInProcess
          ? (
            <>
              {' '}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr10"
              />
            </>
          )
          : <FontAwesomeIcon className="mt-10" size="lg" icon={faInfoCircle} /> }
      </Button>
    </div>
  );
};

export default LocationTypeValidationButton;
