import React from 'react';
import { SelectBox } from 'devextreme-react';
import getDropDownOptions from 'components/getDropDownOptions';
import Textbox from '../../wrapped-component/hint-controls/Textbox';

class ConditionalComponentForExpressionBuilder extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstValue: props.data.value !== null ? typeof (props.data.value) === 'object' ? props.data.value.firstValue : props.data.value : props.data.value,
      secondValue: props.data.value !== null ? typeof (props.data.value) === 'object' ? props.data.value.secondValue : props.data.value : props.data.value,
    };
  }

  renderSelectBox = (isDisabled:boolean, dropdownOptions: any, returnFormat: string) => {
    const { firstValue } = this.state;
    const { data } = this.props;
    return (
      <SelectBox
        className="w-100 h40 border-none outline-none"
        items={dropdownOptions}
        defaultValue={firstValue}
        value={firstValue}
        displayExpr="caption"
        valueExpr="caption"
        searchEnabled={false}
        dropDownButtonTemplate="dropDownButton"
        onValueChanged={(e : any) => this.onSelectBoxValueChanged(e, returnFormat)}
        disabled={isDisabled}
        dropDownOptions={getDropDownOptions('cell', data)}
      />
    );
  };

  onSelectBoxValueChanged = (e: any, returnFormat: string) => {
    const { data, customData } = this.props;
    if (returnFormat && returnFormat === 'singleValue') {
      data.setValue(e.value);
      if (customData.callBackOnUpdate) {
        customData.callBackOnUpdate(e.value);
      }
    } else {
      data.setValue(
        {
          firstValue: e.value,
          secondValue: undefined,
        },
      );
      if (customData.callBackOnUpdate) {
        customData.callBackOnUpdate({
          firstValue: e.value,
          secondValue: undefined,
        });
      }
    }
    this.setState({ firstValue: e.value });
  };

  renderTwoInputBox = (isDisabled:boolean, twoInputTypes: any) => {
    const { firstValue, secondValue } = this.state;

    return (
      <div className="d-flex">
        <Textbox
          className="w-100 h40 border-none outline-none"
          name="First"
          type={twoInputTypes.first}
          placeholder="Enter Value..."
          defaultValue={firstValue}
          onChange={this.onFirstUpdateHandler}
          disabled={isDisabled}
        />
        <div className="mt10">
          {twoInputTypes.separator || null}
        </div>
        <Textbox
          className="w-100 h40 border-none outline-none"
          name="Second"
          type={twoInputTypes.second}
          placeholder="Enter Value..."
          defaultValue={secondValue}
          onChange={this.onSecondUpdateHandler}
          disabled={isDisabled}
        />

      </div>
    );
  };

  onFirstUpdateHandler = (event: any, returnFormat: string) => {
    const { data, customData } = this.props;
    const { secondValue } = this.state;
    if (returnFormat && returnFormat === 'singleNumber') {
      data.setValue(event.target.value);
      if (customData.callBackOnUpdate) {
        customData.callBackOnUpdate(event.target.value);
      }
    } else {
      data.setValue({
        firstValue: event.target.value,
        secondValue,
      });
      if (customData.callBackOnUpdate) {
        customData.callBackOnUpdate({
          firstValue: event.target.value,
          secondValue,
        });
      }
    }
    this.setState({ firstValue: event.target.value });
  };

  onSecondUpdateHandler = (event: any) => {
    const { data, customData } = this.props;
    const { firstValue } = this.state;
    data.setValue({
      secondValue: event.target.value,
      firstValue,
    });
    if (customData.callBackOnUpdate) {
      customData.callBackOnUpdate({
        secondValue: event.target.value,
        firstValue,
      });
    }
    this.setState({ secondValue: event.target.value });
  };

  renderTextBox = (isDisabled: boolean, type:any, returnFormat:string) => {
    const { firstValue } = this.state;
    return (
      <Textbox
        className="w-100 h40 border-none outline-none"
        name="First"
        type={type}
        placeholder="Enter Value..."
        defaultValue={firstValue}
        onChange={(e:any) => this.onFirstUpdateHandler(e, returnFormat)}
        disabled={isDisabled}
      />
    );
  };

  // initialForm: column.dataType,
  // secondaryForm: column.changeMe.toType || undefined,
  // isDisabled: column.changeMe.disabled || undefined,
  // onValue: column.changeMe.onValues,
  // becauseOfColumn: column.changeMe.becauseOfColumn,
  // dropdownData: column.changeMe.dropdownData,
  // twoInputTypes: column.changeMe.twoInputTypes,
  renderHelper = (isDisabled: boolean, currentForm: string, dropdownOptions: any, twoInputTypes: any, returnFormat: string) => {
    return (
      <div className="side-by-side dx-field">
        {
          (currentForm === 'string')
    && this.renderTextBox(isDisabled, 'text', returnFormat)
        }
        {
          (currentForm === 'number')
    && this.renderTextBox(isDisabled, 'number', returnFormat)
        }
        {
          (currentForm === 'twoInputBox')
    && this.renderTwoInputBox(isDisabled, twoInputTypes)
        }
        {
          (currentForm === 'dropdown')
    && this.renderSelectBox(isDisabled, dropdownOptions, returnFormat)
        }

      </div>
    );
  };

  render() {
    const { data, customData } = this.props;
    const isDisbled = customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false;

    if (customData.secondaryForm !== undefined && customData.secondaryForm.onValues.includes(data.data[customData.secondaryForm.becauseOfColumn])) {
      return (this.renderHelper(isDisbled, customData.secondaryForm.toType, customData.secondaryForm.dropdownOptions, customData.secondaryForm.twoInputTypes, customData.secondaryForm.returnFormat));
    }
    if (customData.tertiaryForm !== undefined && customData.tertiaryForm.onValues.includes(data.data[customData.tertiaryForm.becauseOfColumn])) {
      return (this.renderHelper(isDisbled, customData.tertiaryForm.toType, customData.tertiaryForm.dropdownOptions, customData.secondaryForm.twoInputTypes, customData.secondaryForm.returnFormat));
    }
    return (this.renderHelper(isDisbled, customData.initialForm, customData.dropdownOptions, customData.secondaryForm.twoInputType, customData.secondaryForm.returnFormat));
  }
}

export default ConditionalComponentForExpressionBuilder;
