/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { ICombinedGraphDataWithReportHeaders, InteractionInputDTO } from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import { WaferPlottingUtil } from '../../customized-report-utils/WaferPlottingUtil';
import ReportOptionsModal from '../../customized-report-utils/ReportOptionsModal';

export class BinWaferMapComparisonGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: false,
    reportType: 'BIN_BASED' as REPORT_TYPE,
    graphType: ('wafermap' as IGraphType),
    graphMode: ('classColor' as IGraphMode),
    markerOpacity: 1,
    markerBorderOpacity: 1,
    showCustomLabelsWhenGrouped: false,
    showCustomLabelsWhenNotGrouped: false,
    showCustomTickText: false,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: false,
    showXAxisBreaks: false,
    allowDefaultGrouping: false,
    extractSelectedValuesFromToolTips: true,
    showAddedMarkers: false,
    textOnHover: [['placeholder']],
    showAddedLines: {
      grouping: {
        show: false,
        name: '',
      },
      noGrouping: {
        show: false,
        name: '',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'CLASS_NAME',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'wafer_id',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'NONE',
        yAxisName: 'NONE',
      },
      noGrouping: {
        xAxisName: 'NONE',
        yAxisName: 'NONE',
      },
    },
    showTickText: {
      grouping: {
        xAxis: false,
        yAxis: false,
      },
      noGrouping: {
        xAxis: false,
        yAxis: false,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: true,
  };

  getPreferences = () => {
    return this.preferences;
  };

  getPrimaryComponent = (props: ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    const {
      groupingSortingListStore, selectionStore, report, testParameterIndex,
    } = props;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    const testParameterValues: any[] = [];
    testParameters.forEach((testParameter: any) => {
      testParameterValues.push(testParameter.values);
    });
    return (
      <ReportOptionsModal
        reportActor={report.actor}
        groupingSortingListStore={groupingSortingListStore}
        selectionStore={selectionStore}
        testParameterValues={testParameterValues}
        testParameterIndex={testParameterIndex}
        parentState={state}
        setParentStateCallback={(callbackObj: any) => {
          setStateCallback(callbackObj);
        }}
        applyLimitSettings={() => {}}
        requestObject={requestObject}
        hideLimitSettings
      />
    );
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props:ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();
    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });
    return tabs;
  };

  generateGraphComponent = (props:ICustomizedReportGraphProps, state:ICustomizedReportGraphState, classMembers:any, setStateCallback: any) => {
    const waferPlottingUtil = new WaferPlottingUtil();
    return waferPlottingUtil.binComparisonWaferGenerationHelper(props, state, classMembers, setStateCallback, this.preferences);
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject:IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    await httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    })
      .catch((err: any) => {
        const errorDetails: any = err.message;
        const graphingUtil = new GraphingUtils();
        callback(graphingUtil.generateDataObjectFromError(errorDetails));
      });
  };
}
