import React, { Component } from 'react';
import { Col, Button as BsButton } from 'react-bootstrap';
import TreeList, { Column, FilterRow } from 'devextreme-react/tree-list';
import {
  Button as DxButton, Editing, RowDragging, StateStoring,
} from 'devextreme-react/data-grid';
import { DataGrid } from 'devextreme-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../wrapped-component/hint-controls/Button';
import Heading from '../../wrapped-component/hint-controls/Heading';
import { DialogType } from '../../../interfaces';
import './group-sort-widget-style.scss';

interface GroupSortDialogProps {
  id: string,
  columnsList: any[],
  dialogTitle?: string,
  selectedField?: [],
  closeDialog?: () => void,
  selectFieldHandler?: (e:any, dialogType:string) => void,
  deleteFieldHandler?: (e:any, dialogType:string) => void,
  setReorder?: (e:any, dialogType:string) => void,
  applyClause?: () => void,
  changeSort?: (e:any) => void
  // for spc dropdown
  isDropdown?: boolean,
  hideApplyCloseButton?: boolean,
  removeAllSelectedFields?: (dialogType: string) => void;
}

class GroupSortDialog extends Component<GroupSortDialogProps, any> {
  constructor(props:any) {
    super(props);
    this.state = {
    };
  }

  cellRender = (e:any) => {
    if (e.rowType === 'data' && e.columnIndex === 1) {
      if (e.data.ParentId === null) {
        // e.cellElement.className = 'invisible';
      } else {
        e.cellElement.className = 'dx-command-edit dx-command-edit-with-icons';
      }
    }
  };

  renderSortIcon = (e:any) => {
    const { changeSort } = this.props;
    return (
      <div>
        <Button variant="outline p0" onClick={() => (changeSort ? changeSort(e) : null)}>
          <i className={e.data.desc ? 'dx-icon-sortdowntext' : 'dx-icon-sortuptext'} />
        </Button>
      </div>
    );
  };

  onReorder = (e:any) => {
    const { setReorder, id, selectedField } = this.props;
    if (setReorder !== undefined && selectedField !== undefined) {
      const { toIndex, fromIndex } = e;

      const element = selectedField[fromIndex];
      selectedField.splice(fromIndex, 1);
      selectedField.splice(toIndex, 0, element);

      setReorder(selectedField, id);
    }
  };

  render() {
    const {
      id,
      dialogTitle,
      columnsList,
      closeDialog,
      selectFieldHandler,
      selectedField,
      deleteFieldHandler,
      applyClause,
      isDropdown,
      hideApplyCloseButton,
      removeAllSelectedFields,
    } = this.props;

    return (
      <>
        <div className={`w865 ${isDropdown ? 'bg-white' : 'bg-white create-groups'}`}>
          {isDropdown !== true && (
            <div className="p15">
              <Heading size={5}>
                {dialogTitle}
                <Button
                  variant="clear float-right p0 h16 pl5"
                  onClick={closeDialog || undefined}
                >
                  <i className="icon dx-icon-remove float-right" />
                </Button>
              </Heading>
            </div>
          )}
          <div className=" p10 d-flex">
            <Col lg={6}>
              <Heading size={6}>Available Fields</Heading>
              <TreeList
                dataSource={selectedField !== undefined ? columnsList.filter((el) => {
                  return selectedField.findIndex((x:any) => x.columnName === el.ColumnName) < 0;
                }) : columnsList}
                showBorders
                keyExpr="Id"
                parentIdExpr="ParentId"
                showColumnLines={false}
                // showColumnHeaders={false}
                autoExpandAll={false}
                height={350}
                onCellPrepared={this.cellRender}
              // repaintChangesOnly
              // searchPanel={{ width: 200, visible: true }}
              >
                <FilterRow visible />
                <Column
                  width="82%"
                  dataField="Caption"
                  cellRender={(e:any) => (
                    <div className="mt2">
                      {
                        e.data.ParentId !== null
                          ? e.data.Caption
                          : <b>{e.data.Caption}</b>
                      }
                    </div>
                  )}
                />

                <Column
                  width="18%"
                  cellRender={(e:any) => {
                    return (
                      <BsButton
                        disabled={selectedField && selectedField.find((sf: any) => sf.caption === e.data.Id)}
                        size="sm"
                        className="float-right p2 mr10"
                        variant={e.data.ParentId === null ? 'secondary' : 'success'}
                        onClick={
                          selectFieldHandler !== undefined ? () => {
                            selectFieldHandler(e, id);
                          } : () => undefined
                        }
                      >
                        Add
                        <FontAwesomeIcon className="ml10" size="sm" icon={faChevronRight} />
                      </BsButton>
                    );
                  }}
                />

              </TreeList>
            </Col>
            <Col lg={6}>
              {selectedField && selectedField?.length > 0
                ? (
                  <div className="d-flex align-items-center justify-content-between">
                    <Heading size={6}>Selected Fields</Heading>
                    <Button
                      variant="danger"
                      className="p5 mb10"
                      onClick={removeAllSelectedFields !== undefined ? () => {
                        removeAllSelectedFields(id);
                      } : () => undefined}
                    >
                      Remove All
                    </Button>
                  </div>
                ) : <Heading size={6}>Selected Fields</Heading>}
              <div className="border-all section-fields">
                <DataGrid
                  height={340}
                  dataSource={selectedField}
                  showBorders
                  showColumnHeaders={false}
                  showColumnLines={false}
                  showRowLines
                  repaintChangesOnly
                  scrolling={
                    {
                      mode: 'infinite',
                      rowRenderingMode: 'virtual',
                      columnRenderingMode: 'virtual',
                      useNative: true
                    }
                  }
                >
                  <Editing
                    mode="row"
                    useIcons
                    allowDeleting
                  />
                  {id === DialogType.SORT ? (
                    <RowDragging
                      allowReordering
                      onReorder={this.onReorder}
                    // showDragIcons={this.state.showDragIcons}
                    />
                  ) : <></>}
                  <StateStoring enabled={false} />
                  {id === DialogType.SORT ? (
                    <Column
                      width={26}
                      cellRender={this.renderSortIcon}
                    />
                  ) : null}
                  <Column dataField="caption" />
                  <Column type="buttons" width={40}>
                    <DxButton
                      hint="Delete"
                      icon="close"
                      cssClass="text-danger"
                      onClick={deleteFieldHandler !== undefined ? (e:any) => {
                        deleteFieldHandler(e, id);
                      } : () => undefined}
                    />
                  </Column>
                </DataGrid>
              </div>
            </Col>
          </div>

          {isDropdown !== true && hideApplyCloseButton !== true
            && (
              <div>
                <Button variant="primary float-right mr25" onClick={applyClause}>Apply & Close</Button>
              </div>
            )}
        </div>
        {isDropdown && hideApplyCloseButton !== true
          && (
            <div>
              <Button variant="primary float-right mr48" onClick={applyClause}>Apply & Close</Button>
            </div>
          )}
      </>
    );
  }
}

export default GroupSortDialog;
