import React from 'react';
import DataGrid, {
  Column, FilterRow, Paging, Scrolling, Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

export default class BinPlusGroupingsEditComponent extends React.Component<any, any> {
  dropDownBoxRef: React.RefObject<DropDownBox>;

  constructor(props: any) {
    super(props);
    this.state = {
      currentValue: props.data.value,
    };
    this.dropDownBoxRef = React.createRef();
  }

  onSelectionChanged = (selectionChangedArgs: any) => {
    const { data } = this.props;
    data.setValue(selectionChangedArgs.selectedRowKeys);
    this.setState({ currentValue: selectionChangedArgs.selectedRowKeys });
  };

  contentRender = () => {
    const { data, dataGridColumns } = this.props;
    const { currentValue } = this.state;
    let dataSource = null;
    if ((data.data.binPlusGroupType && data.data.binPlusGroupType === 'Softbin')
    || (data.data.binPlusTypes && data.data.binPlusTypes.includes('Grouped HB'))) {
      dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusGroupType === 'Hardbin' && binData.id !== data.data.id; });
    } else if ((data.data.binPlusGroupType && data.data.binPlusGroupType === 'Hardbin')
  || (data.data.binPlusTypes && data.data.binPlusTypes.includes('Grouped SB'))) {
      dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusGroupType === 'Softbin' && binData.id !== data.data.id; });
    } else if (data.data.binPlusGroupType && data.data.binPlusGroupType === 'Grouped Defects') {
      if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Electrical Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Electrical Defect'); });
      } else if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Memory Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Memory Defect'); });
      } else if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Physical Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Physical Defect'); });
      }
    }
    return (
      <DataGrid
        dataSource={dataSource}
        remoteOperations
        height={350}
        columnWidth={100}
        keyExpr="id"
        selectedRowKeys={currentValue}
        hoverStateEnabled
        onSelectionChanged={this.onSelectionChanged}
      >
        {dataGridColumns}
        <Column dataField="binPlusGroupType" />
        <Column dataField="binPlusTypes" />
        <Paging enabled defaultPageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="multiple" allowSelectAll />
        <FilterRow visible />
      </DataGrid>
    );
  };

  render() {
    const { data, columns } = this.props;
    const { currentValue } = this.state;
    let dataSource = null;
    if ((data.data.binPlusGroupType && data.data.binPlusGroupType === 'Softbin')
    || (data.data.binPlusTypes && data.data.binPlusTypes.includes('Grouped HB'))) {
      dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusGroupType === 'Hardbin' && binData.id !== data.data.id; });
    } else if ((data.data.binPlusGroupType && data.data.binPlusGroupType === 'Hardbin')
  || (data.data.binPlusTypes && data.data.binPlusTypes.includes('Grouped SB'))) {
      dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusGroupType === 'Softbin' && binData.id !== data.data.id; });
    } else if (data.data.binPlusGroupType && data.data.binPlusGroupType === 'Grouped Defects') {
      if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Electrical Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Electrical Defect'); });
      } else if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Memory Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Memory Defect'); });
      } else if (data.data.binPlusTypes && data.data.binPlusTypes.includes('Physical Defect')) {
        dataSource = data.column.lookup.dataSource.filter((binData: any) => { return binData.binPlusTypes.includes('Physical Defect'); });
      }
    }
    if (data.data.binPlusGroupType
        && (
          data.data.binPlusGroupType === 'Softbin'
          || data.data.binPlusGroupType === 'Grouped Bins'
          || data.data.binPlusGroupType === 'Grouped Defects'
          || data.data.binPlusGroupType === 'Hardbin')) {
      return (
        <DropDownBox
          ref={this.dropDownBoxRef}
          dropDownOptions={{ width: 500 }}
          dataSource={dataSource}
          value={currentValue}
          displayValueFormatter={(value: any) => {
            return value.map((val: any) => {
              if (data.column.lookup.dataSource === null || data.column.lookup.dataSource === undefined) return '';
              return data.column.lookup.dataSource.filter((d: any) => d.id === val)[0][columns.filter((column: any) => { return column.columnName === 'number'; })[0].id];
            }).join(', ');
          }}
          contentRender={this.contentRender}
        />
      );
    } return null;
  }
}
