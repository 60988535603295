/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import {
  Accordion, Card, Col, OverlayTrigger, Row, Modal,
} from 'react-bootstrap';
import {
  faPlus, faPen, faChevronDown, faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { toTitleCase } from 'GeneralUtils';
import Button from '../../wrapped-component/hint-controls/Button';
import Heading from '../../wrapped-component/hint-controls/Heading';
import GroupSortDialog from './GroupSortDialog';
import { DialogType, IGroupingSortingList, IExpressionsList } from '../../../interfaces';
import { httpService } from '../../../services/http.service';
import { httpMasterMeta } from '../../../services/http.master-meta';
import { ICustomizedReportGraphProps } from '../../../views/individual-reports/customized-report/CustomizedReportGraph';

interface GroupSortWidgetProps {
  applyGroupSortHandler?: (e:any) => void,
  filterCriteria: IExpressionsList[] // Criteria to show which data u want to display and want to apply sorting/grouping
  showAccordion?: boolean | undefined;
  secondaryActions?: JSX.Element | undefined;
  getGroupingSortingData?: (data:IGroupingSortingList) => void;
  heightClass?:string | undefined;
  isDefaultGroupingRequired: string;
}

class GroupSortWidget extends Component<GroupSortWidgetProps, any> {
  constructor(props:any) {
    super(props);
    this.state = {
      showGroupDialog: false,
      showSortDialog: false,
      selectedSortFields: [],
      selectedGroupFields: [],
      selectedAndSavedSortFields: [],
      selectedAndSavedGroupFields: [],
      columnsList: [],
    };
  }

  componentDidMount() {
    httpMasterMeta.getIndividualColumnsList()
      .then((columnDict) => {
        // console.log('CHECK', columnDict);
        if (columnDict) {
          const columnsList: any = [];
          Object.keys(columnDict)
            .filter((x) => x !== 'BIN_SUMMARY' && x !== 'TEST_PARAMETER')
            .forEach((entityName: string, parentRank: number) => {
              const parentObj = {
                Id: entityName,
                Caption: toTitleCase(entityName),
                ParentId: null,
                isVisible: true,
              };
              columnsList.push(parentObj);
              columnDict[entityName].forEach((columnObj: any, index: any) => {
                const {
                  tableName,
                  columnName,
                  columnCategory,
                } = columnObj;
                const child = {
                  Id: `${entityName}_${index}`,
                  TableName: tableName,
                  ColumnName: `${tableName}_${columnName}`,
                  Caption: (entityName === 'WAFER' || entityName === 'LOT') && (columnName === 'key')
                    ? toTitleCase(`${tableName} Id`)
                    : toTitleCase(`${tableName} ${columnName}`),
                  ParentId: entityName,
                  isVisible: true,
                  EntityType: entityName,
                  FieldName: columnName,
                  Disabled: true,
                  ParentRank: parentRank,
                  ColumnCategory: columnCategory,
                };
                columnsList.push(child);
              });
            });

          const newColumnsList: any = [];
          columnsList.forEach((x: any) => {
            if (x.ColumnName !== undefined) {
              if (!x.ColumnName.includes('_id')) {
                newColumnsList.push(x);
              }
            } else {
              newColumnsList.push(x);
            }
          });

          // console.log({ columnsList });
          this.setState({ columnsList: newColumnsList });
        }
      });
  }

  componentDidUpdate(prevProps: GroupSortWidgetProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      const { isDefaultGroupingRequired } = this.props;
      if (isDefaultGroupingRequired === 'PAT') {
        this.defaultGroupingForPolicy();
        this.applyGrouping();
      } else {
        this.removeAllSelectedFields(DialogType.GROUP);
      }
    }
  }

  getPrimaryFieldName = (entityType:string) => {
    if (entityType === 'lot' || entityType === 'wafer') {
      return 'key';
    }
    if (entityType === 'test_program_revision') {
      return 'revision_number';
    }
    if (entityType === 'die') {
      return 'hard_bin_number';
    }
    return 'name';
  };

  getPrimaryColumnName = (entityType:string) => {
    if (entityType === 'lot' || entityType === 'wafer') {
      return `${entityType}_key`;
    }
    if (entityType === 'test_program_revision') {
      return `${entityType}_revision_number`;
    }
    if (entityType === 'die') {
      return `${entityType}_hard_bin_number`;
    }
    return `${entityType}_name`;
  };

  selectFieldHandler = (e:any, dialogType:string) => {
    const { selectedSortFields, selectedGroupFields, columnsList } = this.state;
    const item: any = e.row.data;
    const columnObj = columnsList.find((obj:any) => obj.ColumnName === item.ColumnName);
    columnObj.isVisible = false;
    if (dialogType === DialogType.SORT) {
      const field : any = {
        columnName: item.ParentId !== null ? item.ColumnName : this.getPrimaryColumnName(item.Id.toLowerCase()),
        selector: item.ParentId !== null ? item.ColumnName : this.getPrimaryColumnName(item.Id.toLowerCase()),
        fieldName: item.ParentId != null ? item.FieldName : this.getPrimaryFieldName(item.Id.toLowerCase()),
        caption: item.ParentId !== null ? item.Caption : item.Id,
        disabled: item.ParentId !== null ? item.Disabled : false,
        parentRank: item.ParentId !== null ? item.ParentRank : null,
        entityType: item.ParentId !== null ? item.ParentId : item.Id.toUpperCase(),
        desc: false,
        columnCategory: item.ColumnCategory,
      };
      selectedSortFields.push(field);
      this.setState({ selectedSortFields, columnsList });
    } else {
      const field : any = {
        columnName: item.ParentId !== null ? item.ColumnName : this.getPrimaryColumnName(item.Id.toLowerCase()),
        selector: item.ParentId !== null ? item.ColumnName : this.getPrimaryColumnName(item.Id.toLowerCase()),
        caption: item.ParentId !== null ? item.Caption : item.Id,
        fieldName: item.ParentId != null ? item.FieldName : this.getPrimaryFieldName(item.Id.toLowerCase()),
        parentRank: item.ParentId !== null ? item.ParentRank : null,
        entityType: item.ParentId !== null ? item.ParentId : item.Id.toUpperCase(),
        disabled: item.Disabled,
        columnCategory: item.ColumnCategory,
      };
      selectedGroupFields.push(field);
      this.setState({ selectedGroupFields, columnsList });
    }
  };

  defaultGroupingForPolicy = () => {
    const {
      selectedGroupFields,
      columnsList,
      selectedAndSavedGroupFields,
    } = this.state;
    const probe: any = {
      columnName: 'wafer_probe_count',
      selector: 'wafer_probe_count',
      caption: 'Wafer Probe Count',
      fieldName: 'probe_count',
      parentRank: 6,
      entityType: 'WAFER',
      disabled: true,
      columnCategory: null,
    };
    selectedGroupFields.push(probe);
    selectedAndSavedGroupFields.push(probe);

    this.setState({
      selectedGroupFields,
      selectedAndSavedGroupFields,
      columnsList,
    });
  };

  setReorder = (selectedSortFields:any, dialogType: string) => {
    if (dialogType === DialogType.SORT) {
      this.setState({ selectedSortFields });
    }
  };

  changeSort = (e:any) => {
    const { selectedSortFields } = this.state;
    const item : any = e.data;

    const sortField = selectedSortFields.find((obj:any) => obj.columnName === item.columnName);
    sortField.desc = !sortField.desc;

    this.setState({ selectedSortFields });
  };

  deleteFieldHandler = (e:any, dialogType:string) => {
    const { selectedSortFields, selectedGroupFields, columnsList } = this.state;
    const item : any = e.row.data;

    let columnObj;
    if (item.entityType.toLowerCase() === item.caption.toLowerCase()) {
      columnObj = columnsList.find((obj:any) => (obj.ColumnName === item.columnName));
    } else {
      columnObj = columnsList.find((obj:any) => (obj.Caption === item.caption));
    }

    if (columnObj) {
      columnObj.isVisible = true;

      if (dialogType === DialogType.SORT) {
        const index = selectedSortFields.indexOf(item);
        if (index > -1) {
          selectedSortFields.splice(index, 1);
        }
        this.setState({ selectedSortFields, columnsList });
      } else {
        const index = selectedGroupFields.indexOf(item);
        if (index > -1) {
          selectedGroupFields.splice(index, 1);
        }
        this.setState({ selectedGroupFields, columnsList });
      }
    }
  };

  returnGroupingSortingData = () => {
    const { getGroupingSortingData } = this.props;
    const { selectedAndSavedSortFields, selectedAndSavedGroupFields } = this.state;
    const data: IGroupingSortingList = {
      grouping: selectedAndSavedGroupFields.map((item: any, index: number) => ({
        entityType: item.entityType,
        name: item.fieldName,
        sequence: index,
        operationType: 'GROUPING',
        columnName: item.columnName,
        columnCategory: item.columnCategory,
      })),
      sorting: selectedAndSavedSortFields.map((item: any, index: number) => ({
        entityType: item.entityType,
        name: item.fieldName,
        sequence: index,
        operationType: 'SORTING',
        order: item.desc ? 'DESC' : 'ASC',
        columnName: item.columnName,
        columnCategory: item.columnCategory,
      })),
    };
    if (getGroupingSortingData) {
      getGroupingSortingData(data);
      this.closeAllDialogBoxes();
    }
  };

  applySorting = () => {
    const { selectedSortFields } = this.state;
    this.setState({ selectedAndSavedSortFields: _.cloneDeep(selectedSortFields) }, this.applySortingCallback);
  };

  applySortingCallback = () => {
    const { applyGroupSortHandler, filterCriteria, getGroupingSortingData } = this.props;
    const { selectedAndSavedSortFields } = this.state;
    const SortCriteria = selectedAndSavedSortFields.map((sortObj: any) => ({
      selector: sortObj.selector,
      desc: sortObj.desc,
    }));

    const GroupSortData = {
      EntityType: 'WAFER_IMAGE',
      SortCriteria,
      FilterCriteria: filterCriteria,
      AdvancedFilter: '',
    };

    if (getGroupingSortingData) {
      this.returnGroupingSortingData();
    } else {
      httpService.getGroupSortData(GroupSortData)
        .then((data) => {
          if (applyGroupSortHandler) {
            applyGroupSortHandler(data);
          }
        });
    }
  };

  applyGrouping = () => {
    const { selectedGroupFields } = this.state;
    this.setState({
      selectedAndSavedGroupFields: _.cloneDeep(selectedGroupFields),
    }, this.returnGroupingSortingDataCallback);
  };

  returnGroupingSortingDataCallback = () => {
    const { getGroupingSortingData } = this.props;
    if (getGroupingSortingData) {
      this.returnGroupingSortingData();
    }
  };

  closeAllDialogBoxes = () => {
    this.setState({ showGroupDialog: false, showSortDialog: false });
  };

  toggleGroupDialog = () => {
    const { showGroupDialog } = this.state;
    this.setState({ showGroupDialog: !showGroupDialog, showSortDialog: false });
  };

  toggleActionDialog = (dialogType:string) => {
    let { showSortDialog, showGroupDialog } = this.state;
    const { selectedAndSavedGroupFields, selectedAndSavedSortFields } = this.state;
    if (dialogType === DialogType.GROUP) {
      showSortDialog = false;
      showGroupDialog = !showGroupDialog;
      if (!showGroupDialog) {
        this.setState({ selectedGroupFields: _.cloneDeep(selectedAndSavedGroupFields) });
      }
    } else {
      showSortDialog = !showSortDialog;
      showGroupDialog = false;
      if (!showSortDialog) {
        this.setState({ selectedSortFields: _.cloneDeep(selectedAndSavedSortFields) });
      }
    }
    this.setState({ showSortDialog, showGroupDialog });
  };

  generateExpString = (list: any) => {
    return (
      <div className="pt10 d-flex flex-wrap">
        {
          list.map((obj: any) => {
            const showSortingIcon = obj.desc !== undefined;
            return (
              <div className="pl10 pr10 pt4 pb4 mr4 mb4 background-color-info color-light border-radius-4 d-flex align-items-center">
                <span>
                  {obj.caption}
                </span>
                <span>
                  {showSortingIcon && (
                    <FontAwesomeIcon
                      className="ml10 mt4 mr10"
                      icon={obj.desc === true ? faChevronDown : faChevronUp}
                    />
                  )}
                </span>
              </div>
            );
          })
        }
      </div>
    );
  };

  removeAllSelectedFields = (dialogType: string) => {
    const { isDefaultGroupingRequired } = this.props;
    if (dialogType === DialogType.GROUP && (isDefaultGroupingRequired === 'SPC')) {
      this.setState({ selectedGroupFields: [], selectedAndSavedGroupFields: [] }, this.returnGroupingSortingDataCallback);
    } else if (dialogType === DialogType.GROUP) {
      this.setState({ selectedGroupFields: [] });
    } else {
      this.setState({ selectedSortFields: [] });
    }
  };

  render() {
    const {
      showGroupDialog, showSortDialog, selectedSortFields, selectedAndSavedSortFields, selectedGroupFields, selectedAndSavedGroupFields, columnsList,
    } = this.state;

    const { showAccordion, secondaryActions, heightClass } = this.props;
    const GroupDialog: any = (
      <Col>
        <Modal show size="lg">
          <GroupSortDialog
            id={DialogType.GROUP}
            columnsList={columnsList}
            dialogTitle="Group Management"
            closeDialog={() => { this.toggleActionDialog(DialogType.GROUP); }}
            selectFieldHandler={this.selectFieldHandler}
            selectedField={selectedGroupFields.map((ele:any) => ele)}
            deleteFieldHandler={this.deleteFieldHandler}
            applyClause={this.applyGrouping}
            removeAllSelectedFields={this.removeAllSelectedFields}
          />
        </Modal>
      </Col>
    );

    const SortDialog: any = (
      <Col>
        <Modal show size="lg">
          <GroupSortDialog
            id={DialogType.SORT}
            columnsList={columnsList}
            dialogTitle="Sort Management"
            closeDialog={() => { this.toggleActionDialog(DialogType.SORT); }}

            selectFieldHandler={this.selectFieldHandler}
            selectedField={selectedSortFields.map((ele:any) => ele)}

            deleteFieldHandler={this.deleteFieldHandler}
            applyClause={this.applySorting}
            changeSort={this.changeSort}

            setReorder={this.setReorder}
            removeAllSelectedFields={this.removeAllSelectedFields}
          />
        </Modal>
      </Col>
    );

    const accordionContent = (
      <div className="d-flex align-items-top justify-content-between">

        <div className={`${secondaryActions ? 'w-60' : 'w-100'}`}>
          <Row className="mb6">
            <Col>
              <Heading size={6}>Grouping and Sorting Details</Heading>
            </Col>
          </Row>

          <Row>
            <Col className="mr20 break-right pr20">
              <div className="d-flex align-items-center justify-content-between">
                <Heading className="mr20" size={5}>
                  Group By
                  {selectedAndSavedGroupFields.length > 0 ? `(${selectedAndSavedGroupFields.length})` : ''}
                </Heading>
                <Button
                  onClick={() => { this.toggleActionDialog(DialogType.GROUP); }}
                  variant="secondary"
                  className="mt-10"
                >
                  {selectedAndSavedGroupFields.length === 0
                    ? (
                      <>
                        <FontAwesomeIcon size="sm" className="mr10" icon={faPlus} />
                        Add
                      </>
                    )
                    : (
                      <>
                        <FontAwesomeIcon size="sm" className="mr10" icon={faPen} />
                        Edit
                      </>
                    )}
                </Button>
              </div>
              <ScrollView
                showScrollbar="always"
                scrollByThumb
                reachBottomText=""
                className={heightClass || 'h290'}
              >
                <div>
                  {selectedAndSavedGroupFields.length === 0 ? (
                    <p className="mt30 text-center">
                      Click the Add button to select some grouping criteria
                    </p>
                  )
                    : (
                      <div className={heightClass || 'h290'}>
                        {this.generateExpString(selectedAndSavedGroupFields)}
                      </div>
                    )}
                </div>
              </ScrollView>
            </Col>
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                <Heading className="mr20" size={5}>
                  Sort By
                  {selectedAndSavedSortFields.length > 0 ? `(${selectedAndSavedSortFields.length})` : ''}
                </Heading>
                <Button
                  onClick={() => { this.toggleActionDialog(DialogType.SORT); }}
                  variant="secondary"
                  className="mt-10"
                >
                  {selectedAndSavedSortFields.length === 0
                    ? (
                      <>
                        <FontAwesomeIcon size="sm" className="mr10" icon={faPlus} />
                        Add
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon size="sm" className="mr10" icon={faPen} />
                        Edit
                      </>
                    )}
                </Button>
              </div>
              <ScrollView
                showScrollbar="always"
                scrollByThumb
                reachBottomText=""
                className={heightClass || 'h290'}
              >
                <div>
                  {selectedAndSavedSortFields.length === 0 ? (
                    <p className="mt30 text-center">
                      Click the Add button to select some sorting criteria
                    </p>
                  ) : (
                    <div className={heightClass || 'h290'}>
                      {this.generateExpString(selectedAndSavedSortFields)}
                    </div>
                  )}
                </div>
              </ScrollView>
            </Col>
          </Row>
        </div>
        {
          secondaryActions && (
            <div className="w-40">
              {secondaryActions || null}
            </div>
          )
        }
      </div>
    );

    return (
      <div>
        <OverlayTrigger
          trigger="click"
          placement="auto"
          show={showGroupDialog}
          overlay={GroupDialog}
        >
          <h1 className="d-none">123</h1>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="auto"
          show={showSortDialog}
          overlay={SortDialog}
        >
          <h1 className="d-none">123</h1>
        </OverlayTrigger>
        {
          showAccordion === false ? accordionContent
            : (
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Grouping & Sorting
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="pl20">
                      {accordionContent}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )
        }
      </div>
    );
  }
}

export default GroupSortWidget;
