import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedMultipleDropdown from 'components/wrapped-component/customized-multiple-dropdown/CustomizedMultipleDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface IAddVirtualMachineForm {
  modalMode: 'ADD_MACHINE' | 'ADD_CONTAINER' | '';
}

interface IVMContainer {
  name: string;
  type: 'Stay Agnostic' | 'PAT' | 'GDBN' | 'SWM';
}

const AddVirtualMachineForm = (props:IAddVirtualMachineForm) => {
  const [machine, updateMachine] = useState<{
    name: string;
    joiningToken: string;
  }>({
    name: '',
    joiningToken: '',
  });
  const [containers, updateContainers] = useState<IVMContainer[]>([]);
  const { modalMode } = props;
  const containerTypes = [
    {
      key: 'Stay Agnostic',
      value: 'Stay Agnostic',
    },
    {
      key: 'PAT',
      value: 'PAT',
    },
    {
      key: 'GDBN',
      value: 'GDBN',
    },
    {
      key: 'SWM',
      value: 'SWM',
    },
  ];
  return (
    <>
      <h4>{modalMode === 'ADD_MACHINE' ? 'Add Virtual Machine' : 'Add Containers'}</h4>
      <Label
        labelTitle="Name"
        labelPosition="top"
        labelSize="100"
        fieldSize="100"
        errorSize="100"
        isFieldTouched
      >
        <Textbox
          autoComplete="off"
          name="version"
          placeholder=""
          defaultValue={machine.name}
          onChange={(e:any) => {
            machine.name = e.target.value;
            updateMachine(machine);
          }}
        />
      </Label>
      <div className="d-flex align-items-center justify-content-between mt20">
        <h4>Containers</h4>
        <Button
          variant="secondary"
          className="mt-6"
          size="sm"
          onClick={() => {
            const newContainers = JSON.parse(JSON.stringify(containers));
            newContainers.push({
              name: '',
              type: 'Stay Agnostic',
            });
            updateContainers(newContainers);
          }}
        >
          <FontAwesomeIcon
            className="icon mr10 color-light"
            size="sm"
            icon={faPlus}
          />
          Add Container
        </Button>
      </div>
      <div>
        {containers.map((item:IVMContainer, index:number) => (
          <div className="d-flex align-items-center">
            <Label
              className="mr10"
              labelTitle="Name"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="version"
                // readonly
                placeholder=""
                defaultValue={item.name}
                onChange={(e:any) => {
                  containers[index] = e.target.value;
                  updateContainers(containers);
                }}
              />
            </Label>
            <Label
              labelTitle="Type"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              isFieldTouched
            >
              <CustomizedMultipleDropdown
                list={containerTypes}
                placeholder="Select Report Type"
                selectedRowKeys={[]}
                selectionHandler={() => undefined}
              />
            </Label>
          </div>
        ))}
      </div>
      <Button
        variant="secondary"
        className="w-100 mt20"
        size="sm"
        onClick={() => undefined}
      >
        <FontAwesomeIcon
          className="icon mr10 color-light"
          size="sm"
          icon={faPlus}
        />
        Add
      </Button>
    </>
  );
};

export default AddVirtualMachineForm;
