import React from 'react';
import { Col, Container, } from 'react-bootstrap';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import { DefaultPolicyStepSettings, IAMGPolicyStepSettings, } from '../../../../interfaces';
import ExpressionBuilder from '../../../utility-component/expression-builder/ExpressionBuilder';
import OutputLocation from '../../../manual/amg-pick-map/OutputLocation';

const AMGParametersInExpressionBuilders = [
  { caption: 'AbortCount', dataField: 'wafer.AbortCount', dataType: 'number' },
  { caption: 'AtdfVersion', dataField: 'wafer.AtdfVersion', dataType: 'number' },
  { caption: 'CableType', dataField: 'wafer.CableType', dataType: 'string' },
  { caption: 'CardType', dataField: 'wafer.CardType', dataType: 'string' },
  { caption: 'CenterX', dataField: 'wafer.CenterX', dataType: 'number' },
  { caption: 'CenterY', dataField: 'wafer.CenterY', dataType: 'number' },
  { caption: 'ContactorType', dataField: 'wafer.ContactorType', dataType: 'string' },
  { caption: 'CpuType', dataField: 'wafer.CpuType', dataType: 'string' },
  { caption: 'DeviceName', dataField: 'device.Name', dataType: 'string' },
  { caption: 'DibType', dataField: 'wafer.DibType', dataType: 'string' },
  { caption: 'DieCount', dataField: 'wafer.DieCount', dataType: 'number' },
  { caption: 'DieHeight', dataField: 'wafer.DieHeight', dataType: 'number' },
  { caption: 'DieWidth', dataField: 'wafer.DieWidth', dataType: 'number' },
  { caption: 'ExecType', dataField: 'wafer.ExecType', dataType: 'string' },
  { caption: 'ExecVersion', dataField: 'wafer.ExecVersion', dataType: 'string' },
  { caption: 'ExtraType', dataField: 'wafer.ExtraType', dataType: 'number' },
  { caption: 'FacilityName', dataField: 'facility.Name', dataType: 'string' },
  { caption: 'Flat', dataField: 'wafer.Flat', dataType: 'string' },
  { caption: 'Flip X', dataField: 'wafer.FlipX', dataType: 'boolean' },
  { caption: 'Flip Y', dataField: 'wafer.FlipY', dataType: 'boolean' },
  { caption: 'FunctionalCount', dataField: 'wafer.FunctionalCount', dataType: 'number' },
  { caption: 'GoodCount', dataField: 'wafer.GoodCount', dataType: 'number' },
  { caption: 'HandlerType', dataField: 'wafer.HandlerType', dataType: 'string' },
  { caption: 'IncludeInYield', dataField: 'wafer.IncludeInYield', dataType: 'boolean' },
  { caption: 'IsActive', dataField: 'wafer.IsActive', dataType: 'boolean' },
  { caption: 'IsExcluded', dataField: 'lot.IsExcluded', dataType: 'string' },
  { caption: 'IsScaled', dataField: 'wafer.IsScaled', dataType: 'boolean' },
  { caption: 'LotKey', dataField: 'lot.Key', dataType: 'string' },
  { caption: 'LaserType', dataField: 'wafer.LaserType', dataType: 'string' },
  { caption: 'LoadBoardType', dataField: 'wafer.LoadBoardType', dataType: 'string' },
  { caption: 'LotTestTemperature', dataField: 'lot.TestTemperature', dataType: 'string' },
  { caption: 'MarkedForWaferHunter', dataField: 'wafer.MarkedForWaferHunter', dataType: 'boolean' },
  { caption: 'NodeName', dataField: 'wafer.NodeName', dataType: 'string' },
  { caption: 'Notch Position', dataField: 'wafer.NotchPosition', dataType: 'string' },
  { caption: 'Offset X/Y', dataField: 'ApplyAxesOffset()', dataType: 'string' },
  { caption: 'OperatorName', dataField: 'wafer.OperatorName', dataType: 'string' },
  { caption: 'PcrAbortCount', dataField: 'wafer.PcrAbortCount', dataType: 'number' },
  { caption: 'PcrFunctionalCount', dataField: 'wafer.PcrFunctionalCount', dataType: 'number' },
  { caption: 'PositiveX', dataField: 'wafer.PositiveX', dataType: 'string' },
  { caption: 'PositiveY', dataField: 'wafer.PositiveY', dataType: 'string' },
  { caption: 'ProbeCount', dataField: 'wafer.ProbeCount', dataType: 'string' },
  { caption: 'RetestCount', dataField: 'wafer.RetestCount', dataType: 'number' },
  { caption: 'Run AMG Step', dataField: 'GenerateAssemblyMaps()', dataType: 'string' },
  { caption: 'SiteGroup', dataField: 'wafer.SiteGroup', dataType: 'number' },
  { caption: 'SiteNumber', dataField: 'wafer.SiteNumber', dataType: 'number' },
  { caption: 'StdfVersion', dataField: 'wafer.StdfVersion', dataType: 'number' },
  { caption: 'TesterType', dataField: 'wafer.TesterType', dataType: 'string' },
  { caption: 'TestProgramName', dataField: 'testProgram.Name', dataType: 'string' },
  { caption: 'TestProgramRevisionNumber', dataField: 'testProgram.ProgramRevisionNumber', dataType: 'string' },
  { caption: 'TotalDiesInFile', dataField: 'wafer.TotalDiesInFile', dataType: 'number' },
  { caption: 'WaferSize', dataField: 'wafer.WaferSize', dataType: 'number' },
  { caption: 'WaferTestTemperature', dataField: 'wafer.TestTemperature', dataType: 'string' },
  { caption: 'WorkCenterName', dataField: 'workCenter.Name', dataType: 'string' },
  { caption: 'Yield', dataField: 'wafer.Yield', dataType: 'number' },
  { caption: 'MismatchedDieCount', dataField: 'wafer.MismatchedDieCount', dataType: 'number' },
];
const AMGOperatorsInExpressionBuilder = [
  { caption: '=', dataField: '==' },
  { caption: '<', dataField: '<' },
  { caption: '>', dataField: '>' },
  { caption: '!=', dataField: '!=' },
];
interface IAMGPolicyStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  updatePolicyStepExpressions: (step: any) => void;
  stepSettings?: any;
  expressions?: any;
  updatedCreated: any;
}

class AMGPolicyStepSettings extends React.Component<IAMGPolicyStepSettingsProps, any> {
  constructor(props: IAMGPolicyStepSettingsProps) {
    super(props);
    const { stepSettings } = this.props;
    let policyStepSettings: IAMGPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.AMG));
    if (stepSettings) {
      policyStepSettings = stepSettings;
    }
    this.state = {
      policyStepSettings,
      expressions: props.expressions ? props.expressions : [],
    };
  }

  handleOutputLocationCallback = (receivedOutputLocations: any) => {
    const {
      policyStepSettings,
    } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;

    policyStepSettings.fileOutputs = receivedOutputLocations;
    this.setState({ policyStepSettings });
    updatePolicyStepSettings(policyStepSettings);
  };

  render() {
    const {
      policyStepSettings, expressions,
    } = this.state;
    const {
      updatePolicyStepExpressions,
    } = this.props;
    return (
      <div className="step-settings-block indent p20 background-color-secondary-background">
        <Container fluid className="h-100 overflow-visible">
          <div className="border-all pl10 pr10 pb20 background-color-light">
            <Col lg={12} className="mt20">
              <Heading size={4}>Expressions</Heading>
              <ExpressionBuilder
                onUpdate={(e) => {
                  this.setState({ expressions: e }, () => updatePolicyStepExpressions(e));
                }}
                defaultStatements={expressions}
                isInputUOMDisabled
                isOutputUOMDisabled
                rules={{
                  objects: AMGParametersInExpressionBuilders,
                  operators: AMGOperatorsInExpressionBuilder,
                  typeOperations: [
                    { dataType: 'string', operators: ['==', '!='] },
                  ],
                }}
              />
            </Col>
          </div>
          <div className="border-all pl10 pr10 pb20 background-color-light mt20">
            <Col lg={12} className="mt20">
              <Heading size={4}>Output</Heading>
              <OutputLocation
                onUpdate={this.handleOutputLocationCallback}
                fileOutputs={policyStepSettings.fileOutputs}
                locationTypes={['Cloud Container', 'FTP']}
              />
            </Col>
          </div>
        </Container>
      </div>
    );
  }
}

export default AMGPolicyStepSettings;
