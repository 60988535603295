/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable import/extensions */
/* eslint-disable import/order */

// eslint-disable-next-line no-use-before-define
import { Component } from 'react';
import {
  Row, Col, Container, Button, Spinner,
} from 'react-bootstrap';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { httpMasterMeta } from 'services/http.master-meta';
import { Popup, ScrollView } from 'devextreme-react';
import { DropDownButton } from 'devextreme-react/drop-down-button';
import { httpPreference } from 'services/http.preference';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ReactGA from 'react-ga';
import {
  faCheck, faTimes, faBroom, faHeart,
} from '@fortawesome/free-solid-svg-icons';
// import ReactDOM from 'react-dom';
import Hint from 'components/wrapped-component/hint-controls/Hint/Hint';
import userDataUtil from 'services/user-data-util';
import GeneralUtils, { toTitleCase } from 'GeneralUtils';
import toast from 'CustomToast';
import {
  IExpressionsList, IColumnMetaDictionary, IColumnMeta, IHint, ISelectionCriteriaReturnValue, IdefaultWidget, ISelectionsObject, IVisibleColumnsDetails,
} from '../../interfaces';
import './selection-criteria.scss';
import SelectionCriteriaGrid from './SelectionCriteriaGrid';
import AppConstants from '../../constants';
import AddSCWGrid from './AddSCWGrid';
import {
  IDefaultSelectionCriteria, ISelectionCriteriaProps, ISelectionCriteriaStates, emptyDefaultSelectionCriteria
} from './SelectionCriteriaTypes';
import AdvancedFilterPopover from 'components/utility-component/advanced-filter-popover/AdvancedFilterPopover';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import _ from 'lodash';
import AddOrEditFavoriteSC from './favorite-selection-criteria/AddOrEditFavoriteSC';
import ManageFavoriteSC from './favorite-selection-criteria/ManageFavoriteSC';
import { httpSCW } from 'services/http.scw';
import getDropDownOptions from 'components/getDropDownOptions';

class SelectionCriteria extends Component<ISelectionCriteriaProps, ISelectionCriteriaStates> {
  static defaultProps = {
    getSelectionCriteriaData: undefined,
    widgetsList: [],
    defaultSelection: [],
    returnDataOnSelectButtonClick: false,
    title: 'Selection Criteria',
    advancedFilter: '',
    filterCriteria: [],
    defaultHighlightingKeys: undefined,
    testParameterIdsAndNames: undefined,
    isForSeedCalculation: false,
    selectionCounts: [],
    hideFavoritesButton: false,
  };

  private customExpressions: string;

  private delayOnSelectFn: any;

  private advancedFilterInstructions = <AdvancedFilterPopover />;

  private controlTypeOfSelectedGrid = '';

  private testParameterConditions: { [key: string]: any };

  private favoritesDropDownActions = [
    { id: 1, text: 'Add to Favorites', icon: 'add' },
    { id: 2, text: 'Manage Favorites', icon: 'menu' },
    { id: 3, text: 'Load Favorites', icon: 'download' },
  ];

  private SCWListForDiscardAndClose: IdefaultWidget[] = [];

  private localSCWidgetList: IdefaultWidget[] = [];

  private localVisibleColumnsListForPolicy : { [entityName: string]: any } = {};

  constructor(props: any) {
    super(props);
    const {
      widgetsList,
      advancedFilter,
      defaultSelection,
      isSaveAndCloseClickedSCProp,
      visibleColumnsForPolicy,
    } = this.props;
    this.state = {
      fullScreenControlType: undefined,
      isLoading: true,
      pageSize: 100,
      selectedPageSize: 20,
      pageSizeStep: 10,
      hideTestParamforPolicy: false,
      isSaveAndCloseClickedSCState: isSaveAndCloseClickedSCProp || false,
      // selectedControl: widgetsList[0].controlType,
      showFavoritesAccordian: false,
      selectedFavoriteAction: undefined,
      isFavoritePopupVisible: false,
      favoriteToEdit: undefined,
      markGridsForUpdate: false,
    };

    this.localVisibleColumnsListForPolicy = visibleColumnsForPolicy || {};
    this.testParameterConditions = {};

    this.customExpressions = advancedFilter === undefined ? '' : advancedFilter;

    if (GeneralUtils.SCWidgetsList.length === 0){
      for (let i = 0; i < widgetsList.length; i += 1) {
        const defaultWidgetsListKey = widgetsList[i].controlType.split(' ');
        let snakeName = defaultWidgetsListKey[0].toLowerCase();
        if (defaultWidgetsListKey.length > 1) {
          for (let j = 1; j < defaultWidgetsListKey.length; j += 1) {
            snakeName = `${snakeName}_${toTitleCase(defaultWidgetsListKey[j], false)}`;
          }
        }

        const currentDefaultSelection = defaultSelection[i];
        if (currentDefaultSelection.visibleColumns?.length === 0) {
          const storedItem = localStorage.getItem(`${snakeName.toUpperCase()}_SCW_COLUMNS_DATA`);
          if (storedItem !== null) {
            currentDefaultSelection.isVisibiltyDefault = true;
            currentDefaultSelection.visibleColumns = JSON.parse(storedItem).map((x:any) => x.columnName)
          } else {
            currentDefaultSelection.isVisibiltyDefault = false;
            currentDefaultSelection.visibleColumns = [];
          }
        }
        const widget: IdefaultWidget = {
          controlType: widgetsList[i].controlType,
          columnWeight: widgetsList[i].columnWeight,
          identityColumnName: `${snakeName.toLowerCase()}_id`,
          highlightOtherGrids: (widgetsList[i].highlightOtherGrids !== undefined ? widgetsList[i].highlightOtherGrids : true),
          forceUpdateCallback: undefined,
          isVisible: widgetsList[i].isVisible,
          highlightingKeys: {},
          filteringKeys: {},
          selectedRowKeys: [],
          columnFilters: [],
          gridColumns: [],
          errorMessage: widgetsList[i].isSelectionMandatory ? 'Required' : '',
          isCleared: false,
          maxSelectedCount: widgetsList[i].maxSelectedCount,
          minVisibilityCriteria: widgetsList[i].minVisibilityCriteria,
          defaultSelectionCriteria: currentDefaultSelection,
        };
        GeneralUtils.SCWidgetsList.push(widget)
      }
    } else if (widgetsList.length === 1) {
      const currentDefaultSelection = GeneralUtils.SCWidgetsList[5].defaultSelectionCriteria;
      if (currentDefaultSelection.visibleColumns?.length === 0) {
        const storedItem = localStorage.getItem('LOT_SCW_COLUMNS_DATA');
        if (storedItem !== null) {
          currentDefaultSelection.isVisibiltyDefault = true;
          currentDefaultSelection.visibleColumns = JSON.parse(storedItem).map((x:any) => x.columnName)
        } else {
          currentDefaultSelection.isVisibiltyDefault = false;
          currentDefaultSelection.visibleColumns = [];
        }
      }
      const widget: IdefaultWidget = {
        controlType: widgetsList[0].controlType,
        columnWeight: widgetsList[0].columnWeight,
        identityColumnName: 'lot_id',
        highlightOtherGrids: false,
        forceUpdateCallback: undefined,
        isVisible: widgetsList[0].isVisible,
        highlightingKeys: {},
        filteringKeys: {},
        selectedRowKeys: [],
        columnFilters: [],
        gridColumns: [],
        errorMessage: '',
        isCleared: false,
        maxSelectedCount: 0,
        minVisibilityCriteria: [],
        defaultSelectionCriteria: currentDefaultSelection,
      };
      this.localSCWidgetList = [widget];
    }
    if (!this.localSCWidgetList.length) {
      this.localSCWidgetList = JSON.parse(JSON.stringify(GeneralUtils.SCWidgetsList));
    // for (let i = 0; i < GeneralUtils.SCWidgetsList.length; i += 1) {
    //   this.localSCWidgetList.push({ ...GeneralUtils.SCWidgetsList[i] })
    // }
    }
    // this.localSCWidgetList = [...GeneralUtils.SCWidgetsList];
    // create backup to be used in case of discard changes
    this.SCWListForDiscardAndClose = JSON.parse(JSON.stringify(this.localSCWidgetList));
    if (!(this.localSCWidgetList.some(({ highlightingKeys }) => highlightingKeys && Object.values(highlightingKeys).length > 0)
          || this.localSCWidgetList.some(({ filteringKeys }) => filteringKeys && Object.values(filteringKeys).length > 0))
        && defaultSelection.some(({ values }) => values.length > 0)) {
      this.populateDefaultSelection();
      this.populateHighlightingAndFilteringKeys(defaultSelection);
    }
  }

  updateLocalVisibleColumnsListForPolicy = (entityKey: string, visibleColumnsWithSequence: any) => {
    if (this.localVisibleColumnsListForPolicy) {
      this.localVisibleColumnsListForPolicy[entityKey] = JSON.parse(visibleColumnsWithSequence);
    }
  }

  getColumnNamesFromCallback = (entityKey: string) => {
    return this.localVisibleColumnsListForPolicy[entityKey].map((x: any) => x.columnName);
  }

  populateHighlightingAndFilteringKeys = (selectionCriteriaList: IDefaultSelectionCriteria[]) => {
    selectionCriteriaList.forEach((selection: IDefaultSelectionCriteria, controlIndex:number) => {
      if (selection.visibleColumnsValues && selection.visibleColumnsValues.length !== 0 && controlIndex < 7){
        let colList = Object.keys(selection.visibleColumnsValues[0]);
        const colValues = selection.visibleColumnsValues;
        if (colList && colList.length === 0) {
          colList = ['id']
        }
        const entry: IVisibleColumnsDetails = {
          columnNames: colList,
          entityType: GeneralUtils.getControlTypeUndersoreSeparated(selection.entityType.toLowerCase()).replaceAll('_', ' ').toUpperCase(),
          values: []
        };
        colValues.forEach((vcValue) => {
          const dataRow: any[] = [];
          colList.forEach((col:string) => {
            dataRow.push(vcValue[col]);
          });
          entry.values.push(dataRow);
        });

        this.localSCWidgetList.forEach((widget: any, index: number) => {
          if (index !== controlIndex){
            if (index < controlIndex){ // highlightingKeys'
              widget.highlightingKeys[entry.entityType] = entry;
            } else {
              widget.filteringKeys[entry.entityType] = entry;
            }
          }
        });
      }
    });
  }

  setFullScreenControlType = (controlType?: string | undefined) => {
    this.setState({ fullScreenControlType: controlType });
  };

  clearSelections = () => {
    this.setState({ hideTestParamforPolicy: true });
    this.clearDefaultWidgetList();
    // this.returnSelectionCriteriaToParent();
    this.setState({ isLoading: true, markGridsForUpdate: false }, () => {
      this.setState({ isLoading: false }, () => {
        const { closeSelectionCriteriaModal } = this.props;

        if (closeSelectionCriteriaModal) {
          const highlightingKeys = this.getHighlightedKeys();
          const updatedVisibleColumnsJsonForPolicy = this.localVisibleColumnsListForPolicy || {};
          closeSelectionCriteriaModal(true, highlightingKeys, true, true, updatedVisibleColumnsJsonForPolicy);
        }
      });

      // this.getSelectionCriteriaLayout();
    });
  };

  getHighlightedKeys = () => {
    const highlightingKeys = {};
    this.localSCWidgetList.forEach((item) => {
      Object.values(item.highlightingKeys!).forEach((h) => {
        h.values = [];
        h.columnNames = [];
        h.entityType = ''
      });
      (highlightingKeys as any)[item.controlType.toUpperCase()] = item.highlightingKeys;
    });
    return highlightingKeys;
  };

  clearDefaultWidgetList = () => {
    this.localSCWidgetList.forEach((w: IdefaultWidget, index:number) => {
      w.isCleared = true;
      w.defaultSelectionCriteria = { ...emptyDefaultSelectionCriteria[index], visibleColumns: w.defaultSelectionCriteria.visibleColumns };
      w.selectedRowKeys = [];
      w.selectedRowIDs = [];
      w.filteringKeys = {};
      w.highlightingKeys = {};
    });
    // const oldWidgetsList = GeneralUtils.SCWidgetsList;
    // GeneralUtils.SCWidgetsList = this.localSCWidgetList;
  };

  getTestParameterName = (testParameterId: string) => {
    const { testParameterIdsAndNames } = this.props;
    let tpName;
    if (testParameterIdsAndNames && testParameterIdsAndNames.find((tp) => tp.id === testParameterId)) {
      tpName = testParameterIdsAndNames.find((tp) => tp.id === testParameterId)?.name;
    }
    if (tpName === undefined) {
      tpName = '';
    }
    return tpName;
  };

  populateDefaultSelection = (selectionCriteria: IDefaultSelectionCriteria[] | undefined = undefined) => {
    const { defaultSelection } = this.props;
    let selectionCriteriaToPopulate: IDefaultSelectionCriteria[];
    if (selectionCriteria){
      selectionCriteriaToPopulate = (selectionCriteria);
    } else {
      selectionCriteriaToPopulate = _.cloneDeep(defaultSelection);
    }
    this.localSCWidgetList.forEach((widget:any, index:number) => {
      const snakeName = selectionCriteriaToPopulate[index].entityType;
      const currentDefaultSelection = selectionCriteriaToPopulate[index];
      if (currentDefaultSelection.visibleColumns?.length === 0) {
        const storedItem = localStorage.getItem(`${snakeName.toUpperCase()}_SCW_COLUMNS_DATA`);
        if (storedItem !== null) {
          currentDefaultSelection.isVisibiltyDefault = true;
          currentDefaultSelection.visibleColumns = JSON.parse(storedItem).map((x:any) => x.columnName)
        } else {
          currentDefaultSelection.isVisibiltyDefault = false;
          currentDefaultSelection.visibleColumns = [];
        }
      }
      widget.defaultSelectionCriteria = currentDefaultSelection;
      // populate selectedRowIds in SCWidgetsList
      if (currentDefaultSelection.visibleColumnsValues && currentDefaultSelection.visibleColumnsValues.length > 0){
        const isIdColumnAvailable = currentDefaultSelection.visibleColumnsValues[0].hasOwnProperty('id');
        widget.selectedRowIDs = currentDefaultSelection.values.map((x:any) => ({ id: x, name: '' }));
        if (isIdColumnAvailable){
          widget.isVisibiltyDefault = true;
          widget.visibleColumns = [];
          widget.selectedRowKeys = currentDefaultSelection.visibleColumnsValues.map((x:any) => x.id);
        } else {
          const visibleColVals = this.sortVisibleColumnsValuesInAlphabeticalOrder(currentDefaultSelection);
          widget.selectedRowKeys = visibleColVals.map((x:any) => {
            let key = '';
            Object.entries(x).forEach((entry) => {
              if (entry[0] !== 'failed_count' && entry[1] !== null && entry[0] !== 'isHighlightable') {
                key += entry[1];
              }
            })
            return key;
          });
        }
      }
    });
  };

  sortVisibleColumnsValuesInAlphabeticalOrder = (currentDefaultSelection: IDefaultSelectionCriteria) => {
    const newVisibleColumnValues: any[] = [];
    if (currentDefaultSelection && currentDefaultSelection.visibleColumnsValues){
      currentDefaultSelection.visibleColumnsValues.forEach((x: any) => {
        const sortedDict = Object.fromEntries(
          Object.entries(x).sort((a, b) => a[0].localeCompare(b[0]))
        );
        newVisibleColumnValues.push(sortedDict);
      });
    }
    return newVisibleColumnValues;
  }

  getSelectionCriteriaToReturn = () => {
    const selections: { entityType: string, controlType: string, columnName?: string, values: ISelectionsObject[], isVisibiltyDefault?: boolean,
      visibleColumns?: string[] | undefined; visibleColumnsValues?: { [column: string]: string; }[] | undefined }[] = [];
    const expressions: { entityType: string, values: IExpressionsList[] }[] = [];
    this.localSCWidgetList.forEach((item) => {
      const defaultWidgetsListKey = item.controlType.split(' ');
      let controlType = defaultWidgetsListKey[0];
      let selectionsKey = defaultWidgetsListKey[0];
      let expressionsKey = defaultWidgetsListKey[0].toLowerCase();
      if (defaultWidgetsListKey.length > 1) {
        for (let j = 1; j < defaultWidgetsListKey.length; j += 1) {
          controlType = `${controlType}_${defaultWidgetsListKey[j]}`;
          selectionsKey = `${selectionsKey}${defaultWidgetsListKey[j].toLowerCase()}`;
          expressionsKey = `${expressionsKey}${toTitleCase(defaultWidgetsListKey[j], false)}`;
        }
      }
      // selections.push({...item.defaultSelectionCriteria});
      selections.push({
        entityType: selectionsKey,
        controlType: controlType.toUpperCase(),
        columnName: item.identityColumnName?.toLowerCase(),
        isVisibiltyDefault: item.defaultSelectionCriteria.isVisibiltyDefault,
        visibleColumns: item.defaultSelectionCriteria.visibleColumns,
        visibleColumnsValues: item.defaultSelectionCriteria.visibleColumnsValues,
        values: item.selectedRowIDs!,
      });

      expressions.push({
        entityType: expressionsKey,
        values: item.columnFilters!
      });
    });

    const selectedTestParameters = selections.filter((x) => x.entityType === 'Testparameter');
    const testParameterConditions: { [key: string]: any } = {};
    if (selectedTestParameters.length > 0) {
      selectedTestParameters[0].values.map((item) => (item.id))
        .forEach((item) => {
          if (this.testParameterConditions[item] !== undefined) {
            testParameterConditions[item] = this.testParameterConditions[item];
          }
        });
    }

    const selectionCriteriaReturnValue: ISelectionCriteriaReturnValue = {
      selections,
      expressions,
      advancedFilter: this.customExpressions,
      testParameterConditions,
      defaultSelectionCriteria: this.localSCWidgetList.map((item: IdefaultWidget) => item.defaultSelectionCriteria)
    };

    return selectionCriteriaReturnValue;
  };

  isSelectionAllowed = () => {
    const errorsList: string[] = [];
    this.localSCWidgetList.forEach((item) => {
      if (item.maxSelectedCount && item.selectedRowKeys && item.selectedRowKeys?.length > item.maxSelectedCount) {
        errorsList.push(`You can not select more than ${item.maxSelectedCount} ${item.controlType}(s)`);
      }
    });

    if (errorsList.length > 0) {
      const errorBody = (
        <div>
          <p>Please revise your selections</p>
          <ul>
            {errorsList.map((e) => (<li>{e}</li>))}
          </ul>
        </div>
      );
      ModalPopup.confirm({
        header: 'Invalid Selections Alert',
        body: errorBody,
        showYesButton: false,
        noButtonText: 'Ok',
      });

      return false;
    }
    return true;
  };

  returnSelectionCriteriaToParent = async () => {
    const selectionCriteriaReturnValue = this.getSelectionCriteriaToReturn();
    const { getSelectionCriteriaData } = this.props;
    if (getSelectionCriteriaData !== undefined && this.isSelectionAllowed()) {
      getSelectionCriteriaData(selectionCriteriaReturnValue);
    }
  };

  parseFilter = (controlType: string, entityType: string, filter: any, includeAdvancedFilter = true) => {
    const controlTypeForFilter = this.controlTypeOfSelectedGrid === '' ? entityType.toUpperCase() : this.controlTypeOfSelectedGrid;
    const { filterCriteria } = this.props;
    const newFiltersList: IExpressionsList[] = [];
    if (filter !== undefined) {
      const filterRowData = JSON.parse(filter);
      const isSingleFilter = filterRowData.filter((x: any) => x === 'and').length === 0 && filterRowData.length === 3;
      // filter on single column3
      if (isSingleFilter) {
        if (filterRowData[0].length !== 3 && filterRowData[0] !== 'key_column') {
          const columnName = filterRowData[0];
          const operationName = filterRowData[1];
          const queryText = filterRowData[2];
          const dataType = typeof (queryText);

          const filterIndexAlreadyExists = newFiltersList.findIndex((x) => x.columnName === columnName);
          // FILTER ALREADY EXISTS
          if (filterIndexAlreadyExists >= 0) {
            newFiltersList[filterIndexAlreadyExists].operationName = operationName;
            newFiltersList[filterIndexAlreadyExists].queryText = queryText;
            newFiltersList[filterIndexAlreadyExists].dataType = dataType;
          } else {
            // FILTER DOES NOT EXIST
            newFiltersList.push({
              columnName,
              operationName,
              queryText,
              controlType: controlTypeForFilter,
              dataType,
              groupConditionOn: 'AND',
            });
          }
        }
      } else {
        // filter on multiple column
        const filtersList = filterRowData.filter((x: any) => x !== 'and' && x !== 'or');

        if (filtersList.length > 0) {
          for (let i = 0; i < filtersList.length; i += 1) {
            const currentFilter = filtersList[i];
            const columnName = currentFilter[0];
            const operationName = currentFilter[1];
            const queryText = currentFilter[2];
            const dataType = typeof (queryText);
            if (columnName !== 'key_column'){
              const filterIndexAlreadyExists = newFiltersList.findIndex((x) => x.columnName === columnName);
              // FILTER ALREADY EXISTS
              if (filterIndexAlreadyExists >= 0) {
                newFiltersList[filterIndexAlreadyExists].operationName = operationName;
                newFiltersList[filterIndexAlreadyExists].queryText = queryText;
                newFiltersList[filterIndexAlreadyExists].dataType = dataType;
              } else {
                // FILTER DOES NOT EXIST
                newFiltersList.push({
                  columnName,
                  operationName,
                  queryText,
                  controlType: controlTypeForFilter,
                  dataType,
                  groupConditionOn: 'AND',
                });
              }
            }
          }
        }
      }
    }

    if (includeAdvancedFilter) {
      const { filteringKeys } = this.localSCWidgetList.filter((x) => x.controlType === controlType)[0];
      if (filteringKeys !== undefined) {
        const filterValues = Object.values(filteringKeys);
        for (let i = 0; i < filterValues.length; i += 1) {
          const currentFilteringKey = filterValues[i];
          for (let j = 0; j < currentFilteringKey.values.length; j += 1) {
            currentFilteringKey.columnNames.forEach((colName: string, index : number) => {
              newFiltersList.push({
                columnName: colName,
                operationName: '=',
                queryText: currentFilteringKey.values[j][index],
                controlType: currentFilteringKey.entityType.replaceAll(' ', '_'),
                dataType: typeof (currentFilteringKey.values[j][index]),
                groupConditionOn: 'AND',
                parentGroupId: j,
                parentGroupConditionOn: 'OR'
              });
            });
          }
        }
      }
    }

    if (filterCriteria && filterCriteria?.length > 0) {
      newFiltersList.push(...filterCriteria);
    }

    return newFiltersList;
  };

  componentDidUpdate(prevProps: ISelectionCriteriaProps) {
    const { defaultSelection, visibleColumnsForPolicy } = this.props;
    if (JSON.stringify(prevProps.defaultSelection) !== JSON.stringify(defaultSelection)) {
      if (!(this.localSCWidgetList.some(({ highlightingKeys }) => highlightingKeys && Object.values(highlightingKeys).length > 0)
            || this.localSCWidgetList.some(({ filteringKeys }) => filteringKeys && Object.values(filteringKeys).length > 0))
          && defaultSelection.some(({ values }) => values.length > 0)){
        this.populateDefaultSelection();
        this.populateHighlightingAndFilteringKeys(defaultSelection)
      }
    } else if (JSON.stringify(prevProps.visibleColumnsForPolicy) !== JSON.stringify(visibleColumnsForPolicy)){
      this.localVisibleColumnsListForPolicy = visibleColumnsForPolicy || {};
    }
  }

  componentDidMount() {
    userDataUtil.registerInteractionEvent('Rendering Selection Criteria', 'SCW');
    const { visibleColumnsForPolicy } = this.props;
    if (visibleColumnsForPolicy){
      this.getSelectionCriteriaLayoutForPolicy();
    } else {
      this.getSelectionCriteriaLayout();
    }
  }

  getSelectionCriteriaLayoutForPolicy = () => {
    const { isForSeedCalculation } = this.props;
    const columnMetaSpecObject = {
      isCascading: true,
      isPascalCase: false,
      isFromSelectionCriteria: !isForSeedCalculation,
    };
    httpMasterMeta.getColumnsList(columnMetaSpecObject)
      .then((data: IColumnMetaDictionary) => {
        this.localSCWidgetList.forEach((item: IdefaultWidget) => {
          const currentWidgetKey = item.controlType.replaceAll(' ', '_')
            .toUpperCase();
          const currentWidgetColumns = (data as any)[currentWidgetKey];
          if (currentWidgetColumns !== undefined) {
            item.gridColumns = this.syncGridColumnsWithVisibleColumnsForPolicy(
              item,
              currentWidgetColumns,
              `${currentWidgetKey}_SCW_COLUMNS_DATA`,
            );
          }
        });
        this.setState({ isLoading: false });
      });
  }

  getSelectionCriteriaLayout = () => {
    const { isForSeedCalculation } = this.props;
    // {/* // TO DO: SELECTION CRITERIA FIX */} :: FIX LOCAL STORAGE ISSUE
    httpPreference.getPreferenceByName('SCW_DEFAULT_LAYOUT')
      .then((data) => {
        const defaultVisibleColumnsList = JSON.parse(data.value);
        this.localSCWidgetList.forEach((widget: IdefaultWidget) => {
          if (widget.defaultSelectionCriteria.visibleColumns?.length === 0){
            const visibleColumns = defaultVisibleColumnsList[`${widget.controlType.replaceAll(' ', '_').toUpperCase()}_SCW_COLUMNS_DATA`];
            visibleColumns?.forEach((col: any) => {
              if (widget.defaultSelectionCriteria.visibleColumns){
                widget.defaultSelectionCriteria.visibleColumns.push(col.columnName);
              }
            })
          }
        })
        const defaultGridKeys = Object.keys(defaultVisibleColumnsList);
        defaultGridKeys.forEach((localStorageKey: string) => {
          if (localStorage.getItem(localStorageKey) === null) {
            localStorage.setItem(localStorageKey, JSON.stringify(defaultVisibleColumnsList[localStorageKey]));
          }
        });
      })
      .finally(() => {
        const columnMetaSpecObject = {
          isCascading: true,
          isPascalCase: false,
          isFromSelectionCriteria: !isForSeedCalculation,
        };
        httpMasterMeta.getColumnsList(columnMetaSpecObject)
          .then((data: IColumnMetaDictionary) => {
            this.localSCWidgetList.forEach((item: IdefaultWidget) => {
              const currentWidgetKey = item.controlType.replaceAll(' ', '_')
                .toUpperCase();
              const currentWidgetColumns = (data as any)[currentWidgetKey];
              if (currentWidgetColumns !== undefined) {
                item.gridColumns = this.syncWithLocalStorage(
                  currentWidgetColumns,
                  `${currentWidgetKey}_SCW_COLUMNS_DATA`,
                );
              }
            });
            this.setState({ isLoading: false });
          });
      });
  };

  syncGridColumnsWithVisibleColumnsForPolicy = (widget: IdefaultWidget, columns:IColumnMeta[], localStorageKey: string) => {
    const { visibleColumnsForPolicy } = this.props;
    let storageColumnsList = (visibleColumnsForPolicy || {})[localStorageKey];
    if (storageColumnsList) {
      if (typeof storageColumnsList === 'string'){
        storageColumnsList = JSON.parse(storageColumnsList);
      }
      const visibleStorageColumns: { columnName: string, sequence: number }[] = storageColumnsList!;
      if (visibleStorageColumns.length > 0) {
        columns.forEach((item: IColumnMeta) => {
          item.visibleByDefault = false;
        });
        visibleStorageColumns.forEach((visibleColumnDataField) => {
          if (widget.defaultSelectionCriteria.visibleColumns) {
            widget.defaultSelectionCriteria.visibleColumns.push(visibleColumnDataField.columnName);
          }
          const columnIndex = columns.findIndex((x) => x.dataField === visibleColumnDataField.columnName);
          if (columnIndex >= 0) {
            columns[columnIndex].visibleByDefault = true;
            columns[columnIndex].sequence = visibleColumnDataField.sequence;
          }
        });
      }
    }
    return columns;
  }

  syncWithLocalStorage = (columns: IColumnMeta[], localStorageKey: string) => {
    const storageColumnsList = localStorage.getItem(localStorageKey);
    if (storageColumnsList !== null) {
      const visibleStorageColumns: { columnName: string, sequence: number }[] = JSON.parse(storageColumnsList!);
      if (visibleStorageColumns.length > 0) {
        columns.forEach((item: IColumnMeta) => {
          item.visibleByDefault = false;
        });
        visibleStorageColumns.forEach((visibleColumnDataField) => {
          const columnIndex = columns.findIndex((x) => x.dataField === visibleColumnDataField.columnName);
          if (columnIndex >= 0) {
            columns[columnIndex].visibleByDefault = true;
            columns[columnIndex].sequence = visibleColumnDataField.sequence;
          }
        });
      }
    }

    return columns;
  };

  getKeyFromControlType = (controlType: string, objectToIterate: any, split = ' ', delimeter = '') => {
    try {
      const keyIndex = Object.keys(objectToIterate)
        .map((item) => (item.toLowerCase()))
        .indexOf(controlType.split(split)
          .join(delimeter)
          .toLowerCase());
      if (keyIndex >= 0) {
        return Object.keys(objectToIterate)[keyIndex];
      }
    } catch (ex) {
      // throw new Error('Invalid map key.');
    }
    return '';
  };

  getColumnCaption = (controlType: string, column: IColumnMeta) => {
    if (column.dataField.endsWith('_key')) {
      return `${toTitleCase(column.entityType.replaceAll('_', ' '))} ${toTitleCase(column.columnName.replaceAll('key', 'id').replaceAll('_', ' '))}`;
    }
    return `${toTitleCase(column.entityType)} ${toTitleCase(column.columnName)}`;
  };

  onGridSelectionChanged = (controlType: string, selectedRowsData: any, selectedRowKeys: any, testParameterConditions: { [key: string]: any }) => {
    const { isForSeedCalculation } = this.props;
    const controlIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    const currentWidget = this.localSCWidgetList[controlIndex];
    this.updateDefaultSelectionCriteriaOnSelection(currentWidget, selectedRowsData);

    this.testParameterConditions = testParameterConditions;
    if (this.localSCWidgetList[controlIndex].highlightOtherGrids) {
      const colList: string[] = [];
      if (selectedRowsData.length > 0){
        Object.keys(selectedRowsData[0]).forEach((columnName : string) => {
          if (columnName !== 'key_column' && columnName !== 'total_record_count' && columnName !== 'isHighlightable'){
            colList.push(columnName);
          }
        });
      }

      const dataEntry: IVisibleColumnsDetails = { columnNames: colList, entityType: controlType.toUpperCase(), values: [] }

      selectedRowsData.forEach((selectedRowData: any) => {
        const dataRow: string[] = [];
        colList.forEach((col) => {
          dataRow.push(selectedRowData[col]);
        });
        dataEntry.values.push(dataRow);
      });

      // const startIndex = indexOfNearestSelectedParent >= 0 ? indexOfNearestSelectedParent + 1 : 0;
      if (this.localSCWidgetList[controlIndex].highlightOtherGrids) {
        for (let i = 0; i < controlIndex; i += 1) {
          if (dataEntry.values.length) {
            this.localSCWidgetList[i].highlightingKeys![controlType.toUpperCase()] = dataEntry;
          } else {
            delete this.localSCWidgetList[i].highlightingKeys![controlType.toUpperCase()];
          }
        }
        for (let i = controlIndex + 1; i < this.localSCWidgetList.length; i += 1) {
          if (dataEntry.values.length) {
            this.localSCWidgetList[i].filteringKeys![controlType.toUpperCase()] = dataEntry;
          } else {
            delete this.localSCWidgetList[i].filteringKeys![controlType.toUpperCase()];
          }
        }
      }
    }

    this.localSCWidgetList[controlIndex].selectedRowKeys = selectedRowKeys;

    // check if selection is not from highlighted row
    if (selectedRowsData.length > 0 && selectedRowsData.some((x:any) => x.isHighlightable === '0')){
      // if not, remove selection from below grids
      for (let i = controlIndex + 1; i < this.localSCWidgetList.length; i += 1) {
        this.localSCWidgetList[i].defaultSelectionCriteria.values = [];
        // remove filters from below and highliting from above grids if any, using entity type
        for (let j = 0; j < this.localSCWidgetList.length; j += 1){
          if (j < i){
            delete this.localSCWidgetList[j].highlightingKeys![this.localSCWidgetList[i].controlType.toUpperCase()];
          } else if (j > i){
            delete this.localSCWidgetList[j].filteringKeys![this.localSCWidgetList[i].controlType.toUpperCase()];
          }
        }
      }
    }

    if (this.delayOnSelectFn) {
      clearTimeout(this.delayOnSelectFn);
    }
    if (selectedRowsData.length > 0) {
      if (controlType !== 'Test Parameter') {
        this.refreshGridsForAllExceptControlType(true, controlType);
      }
    } else {
      this.refreshGridsForAllExceptControlType(false, controlType);
    }

    if (isForSeedCalculation) {
      // get data ids
      httpMasterMeta.getDataIdsFromDefaultSelections([currentWidget.defaultSelectionCriteria])
        .then((data: { values: ISelectionsObject[] }[]) => {
          this.localSCWidgetList.forEach((widget: IdefaultWidget, index: number) => {
            widget.selectedRowIDs = data[index].values;
          });
          this.returnSelectionCriteriaToParent();
        });
    }
  };

  onFilterExpressionChanged = (controlType: string, filterExpression: any) => {
    const controlIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    const columnFilters = this.parseFilter(controlType, controlType, JSON.stringify(filterExpression), false);

    if (this.localSCWidgetList[controlIndex].columnFilters === undefined
            || !_.isEqual(this.localSCWidgetList[controlIndex].columnFilters, columnFilters)) {
      this.localSCWidgetList[controlIndex].columnFilters = columnFilters;
    }
  };

  // PASS A CONTROL TYPE TO SKIP AND UPDATE THE REST OF THE GRIDS
  refreshGridsForAllExceptControlType = (isKeepControlType: boolean, controlType = '') => {
    const gridsToUpdate = isKeepControlType ? this.localSCWidgetList.filter((x) => x.controlType !== controlType) : this.localSCWidgetList;
    gridsToUpdate.forEach((item) => {
      setTimeout(() => {
        if (item.forceUpdateCallback) {
          item.forceUpdateCallback();
        }
      }, 0);
    });
  };

  refreshGridWithControlType = (controlType: string) => {
    const gridItemIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    const currentGrid = this.localSCWidgetList[gridItemIndex];
    setTimeout(() => {
      if (currentGrid.forceUpdateCallback) {
        currentGrid.forceUpdateCallback();
      }
    }, 0);
  };

  returnForceUpdateCallback = (
    controlType: string,
    callback: () => void,
  ) => {
    const gridItemIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    this.localSCWidgetList[gridItemIndex].forceUpdateCallback = callback;
  };

  // PASS EMPTY TO THIS FUNCTION FOR ALL GRIDS

  applyCustomExpression = (e: any) => {
    this.customExpressions = e.target.value;
  };

  toggleVisibility = (controlType: string, columnWeight?: number) => {
    const controlIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    this.localSCWidgetList[controlIndex].isVisible = !this.localSCWidgetList[controlIndex].isVisible;
    if (columnWeight !== undefined) {
      this.localSCWidgetList[controlIndex].columnWeight = columnWeight;
    }
    this.forceUpdate();
  };

  changeWeight = (controlType: string, newWeight: number) => {
    const controlIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    this.localSCWidgetList[controlIndex].columnWeight = newWeight;
    this.forceUpdate();
  };

  checkifSelectionPerformed = () => {
    let selectionPerformed = false;
    this.localSCWidgetList.forEach((item) => {
      if (item.selectedRowKeys && item.selectedRowKeys.length > 0) {
        selectionPerformed = true;
      }
    });
    return selectionPerformed;
  };

  handleSelect = (preserveSelection: boolean) => {
    const { removeAllReports, defaultSelection } = this.props;
    const defaultSelectionList : IDefaultSelectionCriteria[] = this.localSCWidgetList.map((item: IdefaultWidget) => item.defaultSelectionCriteria);
    if (preserveSelection){
      httpMasterMeta.getDataIdsFromDefaultSelections(defaultSelectionList)
        .then((data: { values: ISelectionsObject[] }[]) => {
          this.localSCWidgetList.forEach((widget: IdefaultWidget, index: number) => {
            widget.selectedRowIDs = data[index].values;
          // widget.defaultSelectionCriteria.values = data[index].values.map((selectionObj: any) => selectionObj.id);
          });
          const currentSelection = this.getSelectionCriteriaToReturn()
            ?.selections
            .map((x: any) => {
              return {
                entityType: x.entityType,
                values: x.values.map((val: any) => {
                  return val.id;
                }),
              };
            });

          if (removeAllReports && (JSON.stringify(defaultSelection) !== JSON.stringify(currentSelection) || !this.checkifSelectionPerformed())) {
            removeAllReports();
          }
          this.returnSelectionCriteriaToParent();
          this.callCloseSelectionCriteriaModal(preserveSelection);
        });
    } else {
      this.callCloseSelectionCriteriaModal(preserveSelection);
    }
  };

  callCloseSelectionCriteriaModal = (preserveSelection: boolean) => {
    let selectionPerformed = false;
    const {
      returnDataOnSelectButtonClick,
      closeSelectionCriteriaModal
    } = this.props;
    if (returnDataOnSelectButtonClick && closeSelectionCriteriaModal !== undefined) {
      const highlightingKeys = {};
      selectionPerformed = this.checkifSelectionPerformed();
      this.localSCWidgetList.forEach((item) => {
        (highlightingKeys as any)[item.controlType.toUpperCase()] = item.highlightingKeys;
      });
      if (selectionPerformed === false && preserveSelection === true) {
        toast.warning('No data selected in Selection Criteria');
      }
      const updatedVisibleColumnsJsonForPolicy = this.localVisibleColumnsListForPolicy || {};
      closeSelectionCriteriaModal(preserveSelection, highlightingKeys, false, false, updatedVisibleColumnsJsonForPolicy);
    }
  }

  updateDefaultWidgetItem = (controlType: string, key: string, value: any) => {
    const controlIndex = this.localSCWidgetList.findIndex((x) => x.controlType === controlType);
    if (controlIndex >= 0 && (this.localSCWidgetList[controlIndex] as any)[key]) {
      (this.localSCWidgetList[controlIndex] as any)[key] = value;
    }
  };

  handlePageSizeChange = (action: any) => {
    let valueChanged = false;
    const {
      pageSize,
      pageSizeStep
    } = this.state;
    let { selectedPageSize } = this.state;
    if (action === 'increase') {
      if (selectedPageSize + pageSizeStep <= pageSize) {
        selectedPageSize += pageSizeStep;
        valueChanged = true;
      }
    } else if (selectedPageSize - pageSizeStep > 0) {
      selectedPageSize -= pageSizeStep;
      valueChanged = true;
    }

    if (valueChanged) {
      this.setState({ selectedPageSize });
    }
  };

  updateVisibleColumns = (updatedVisibleColumns: (string[] | undefined)[]) => {
    updatedVisibleColumns.forEach((visibleColumns, index) => {
      this.localSCWidgetList[index].defaultSelectionCriteria.isVisibiltyDefault = false;
      this.localSCWidgetList[index].defaultSelectionCriteria.visibleColumns = visibleColumns;
      this.localSCWidgetList[index].gridColumns = this.localSCWidgetList[index].gridColumns?.map((x) => {
        x.visibleByDefault = visibleColumns?.includes(x.dataField) || false;
        return x;
      })
    });
  }

  onChangeInVisibleColumns = (widgetIndexInDefaultWidgetsList: number, listOfEntityTypes: string[], visibleColumns: any) => {
    this.localSCWidgetList[widgetIndexInDefaultWidgetsList].defaultSelectionCriteria.isVisibiltyDefault = false;
    const temp = visibleColumns.filter(
      (x:any) => x.dataField !== undefined
    ).map((item: any) => {
      return item.dataField;
    });
    GeneralUtils.SCWidgetsList[widgetIndexInDefaultWidgetsList].defaultSelectionCriteria.visibleColumns = temp;
    this.localSCWidgetList[widgetIndexInDefaultWidgetsList].defaultSelectionCriteria.visibleColumns = temp;
    this.localSCWidgetList[widgetIndexInDefaultWidgetsList].defaultSelectionCriteria.visibleColumnsValues = [];
    this.localSCWidgetList[widgetIndexInDefaultWidgetsList].defaultSelectionCriteria.values = []
  }

  updateDefaultSelectionCriteriaOnSelection = (widget: IdefaultWidget, selectedRowKeys: string[]) => {
    const visibleColumnValues: { [columnName: string]: string }[] = [];
    if (widget.defaultSelectionCriteria.visibleColumns?.includes('id')) {
      // save only id of selected rows
      selectedRowKeys.forEach((key:any) => {
        const vcValue :{ [columnName: string]: string } = {};
        vcValue.id = key;
        visibleColumnValues.push(vcValue);
      });
    } else {
      selectedRowKeys.forEach((key:any) => {
        const vcValue :{ [columnName: string]: string } = {};
        widget.defaultSelectionCriteria.visibleColumns?.forEach((columnName: string) => {
          const columnValue = key[columnName];
          vcValue[columnName] = columnValue;
        });
        visibleColumnValues.push(vcValue);
      });
    }
    widget.defaultSelectionCriteria.visibleColumnsValues = visibleColumnValues;
  }

  renderFavorite = (selectedItem: any): JSX.Element => {
    if (selectedItem.id === 2){
    // Manage Favorite
      return (
        <ManageFavoriteSC
          title={selectedItem.text}
          callback={this.manageFavoriteCallback}
          callbackOnEditFavorite={this.callbackOnEditFavorite}
        />
      )
    }
    return (
      <ManageFavoriteSC
        title={selectedItem.text}
        callback={this.manageFavoriteCallback}
        isManageFavoritePopup={false}
        callbackOnLoadFavorite={this.callbackOnLoadFavorite}
      />
    )
  }

  manageFavoriteCallback = (data: string) => {
    this.setState({ isFavoritePopupVisible: false });
  }

  favoritesItemClickHandler = (item: any) => {
    if (item.itemData.id === 1) { // Add to favorites
      this.setState({ showFavoritesAccordian: true, selectedFavoriteAction: item.itemData, markGridsForUpdate: false });
      return;
    }
    this.setState({ selectedFavoriteAction: item.itemData, isFavoritePopupVisible: true, markGridsForUpdate: false });
  }

  onFavoritesPopupClose = () => {
    this.setState({ isFavoritePopupVisible: false });
  }

  saveFavorite = (data: any) => {
    const { favoriteToEdit } = this.state;
    const currentSelection: IDefaultSelectionCriteria[] = this.localSCWidgetList.map((widget: IdefaultWidget) => widget.defaultSelectionCriteria)
    if (currentSelection.some(({ visibleColumnsValues }) => visibleColumnsValues && visibleColumnsValues.length > 0)) {
      const newData = { ...data, selectionCriteria: currentSelection };
      if (favoriteToEdit) {
        // update favorite
        newData.id = favoriteToEdit.id;
        httpSCW.updateFavoriteSelectionCriteria(newData).then((response: any) => {
          if (response){
            toast.success('Favorite updated successfully');
            this.setState({
              showFavoritesAccordian: false, selectedFavoriteAction: this.favoritesDropDownActions[1], favoriteToEdit: null, isFavoritePopupVisible: true
            });
          }
        })
        return;
      }
      httpSCW.addFavoriteSelectonCriteria(newData).then((response: any) => {
        if (response){
          toast.success('Favorite saved successfully');
          this.setState({ showFavoritesAccordian: false, selectedFavoriteAction: null });
        }
      })
    } else {
      toast.error('Kindly select some data to proceed');
    }
  }

  callbackOnLoadFavorite = (data:any) => {
    let selectionsToLoad: IDefaultSelectionCriteria[] = [];
    if (data && data.length > 1){
      const favoriteIds = data.map((item: any) => item.id);
      httpSCW.mergeFavorite({ favorites: favoriteIds }).then((response: any) => {
        selectionsToLoad = response;
        if (selectionsToLoad && selectionsToLoad.length > 0){
          this.populateDefaultSelection(selectionsToLoad);
          this.populateHighlightingAndFilteringKeys(selectionsToLoad);
          this.updateVisibleColumns(selectionsToLoad.map((x) => x.visibleColumns));
          this.setState({ isFavoritePopupVisible: false, markGridsForUpdate: true });
        }
      }).catch(() => {
        toast.error('Error while merging favorites');
      });
    } else {
      selectionsToLoad = data[0].selectionCriteria;
      if (selectionsToLoad && selectionsToLoad.length > 0){
        this.populateDefaultSelection(selectionsToLoad);
        this.populateHighlightingAndFilteringKeys(selectionsToLoad);
        this.updateVisibleColumns(selectionsToLoad.map((x) => x.visibleColumns));
        this.setState({ isFavoritePopupVisible: false, markGridsForUpdate: true });
      }
    }
  };

  callbackOnEditFavorite = (data:any) => {
    this.populateDefaultSelection(data.selectionCriteria);
    this.populateHighlightingAndFilteringKeys(data.selectionCriteria);
    this.setState({
      favoriteToEdit: data,
      isFavoritePopupVisible: false,
      showFavoritesAccordian: true,
      selectedFavoriteAction:
      this.favoritesDropDownActions[0],
      markGridsForUpdate: true,
    });
  }

  render() {
    const {
      isLoading,
      selectedPageSize,
      fullScreenControlType,
      hideTestParamforPolicy,
      showFavoritesAccordian,
      selectedFavoriteAction,
      isFavoritePopupVisible,
      favoriteToEdit,
      markGridsForUpdate,
      isSaveAndCloseClickedSCState
    } = this.state;
    const {
      widgetsList,
      returnDataOnSelectButtonClick,
      advancedFilter,
      useDefaultHeight,
      selectionCounts,
      hideFavoritesButton,
      visibleColumnsForPolicy,
    } = this.props;
    if (widgetsList.length === 0) {
      return (
        <div className="w-100 h-100 d-flex align-items-center justify-content-between">
          <span>No Default Widgets Selected</span>
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className={`w-100 d-flex align-items-center justify-content-center ${useDefaultHeight ? 'scw-grid-row-height' : ''}`}>
          <Spinner animation="border" />
        </div>
      );
    }

    const hint: IHint = {
      message: this.advancedFilterInstructions,
      href: '#',
      position: 'bottom',
    };

    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        height="100%"
        className="h-100"
        reachBottomText=""
      >
        <Container fluid className="h-100">
          {showFavoritesAccordian && (
            <Row className="align-items-center pb10 mb10 border-bottom">
              <Col lg={12} className="custom-form d-flex align-items-center pl20">
                <AddOrEditFavoriteSC
                  title={favoriteToEdit ? 'Edit Favorite' : 'Add Favorite'}
                  callbackOnSave={this.saveFavorite}
                  callbackOnDiscard={() => {
                    if (favoriteToEdit){
                      this.setState({
                        showFavoritesAccordian: false, selectedFavoriteAction: this.favoritesDropDownActions[1], favoriteToEdit: null, isFavoritePopupVisible: true
                      });
                    } else {
                      this.setState({ showFavoritesAccordian: false, selectedFavoriteAction: null, favoriteToEdit: null })
                    }
                  }}
                  favoriteToEdit={favoriteToEdit}
                />
              </Col>
            </Row>
          )}
          <Row className="align-items-center pb10 mb10 border-bottom">
            <Col lg={6} className="custom-form d-flex align-items-center pl20">
              <Hint
                className="d-inline-block ml-14 mr10 pt2"
                hint={hint}
                variant="icon"
                mode="popover"
              />
              <input
                placeholder="For Example: f.name = {XYZ}"
                type="text"
                defaultValue={advancedFilter}
                onChange={this.applyCustomExpression}
                className="mr10"
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    this.refreshGridsForAllExceptControlType(true);
                  }
                }}
              />
            </Col>
            <Col lg={6}>
              <div className="float-right">
                { !showFavoritesAccordian && (
                  <>
                    { !hideFavoritesButton
                && (
                  <DropDownButton
                    disabled={showFavoritesAccordian}
                    text="Favorites"
                    icon="like"
                    className="h40 mr6"
                    items={this.favoritesDropDownActions}
                    dropDownOptions={getDropDownOptions('div', undefined, [{ width: '140%' }])}
                    displayExpr="text"
                    onItemClick={this.favoritesItemClickHandler}
                  />
                )}
                    {
                      returnDataOnSelectButtonClick
                                    && (
                                      <Button
                                        variant="success"
                                        onClick={() => {
                                          this.setState(
                                            { isSaveAndCloseClickedSCState: true },
                                            () => this.handleSelect(true)
                                          );
                                        }}
                                        className="h40 mr6"
                                      >
                                        <FontAwesomeIcon icon={faCheck} size="lg" className="mr10" />
                                        Save & Close
                                      </Button>
                                    )
                    }
                    <Button
                      variant="warning"
                      className="h40 mr6"
                      onClick={this.clearSelections}
                    >
                      <FontAwesomeIcon icon={faBroom} size="lg" className="mr10" />
                      Clear Selection
                    </Button>
                    {
                      returnDataOnSelectButtonClick
                                    && (
                                      <Button
                                        variant="danger"
                                        onClick={() => {
                                          // reset the SCWWidgetsList to backup
                                          this.localSCWidgetList = this.SCWListForDiscardAndClose;
                                          this.handleSelect(false);
                                        }}
                                        className="h40 mr6"
                                      >
                                        <FontAwesomeIcon icon={faTimes} size="lg" className="mr10" />
                                        Discard & Close
                                      </Button>
                                    )
                    }
                  </>
                )}
                <AddSCWGrid defaultWidgetsList={GeneralUtils.SCWidgetsList} onGridItemAdd={this.toggleVisibility} />
              </div>
            </Col>
          </Row>
          <ScrollView
            direction="vertical"
            showScrollbar="always"
            width="100%"
            useNative
          >
            <div className={`${useDefaultHeight ? 'scw-grid-row-height' : ''} pl20 pr20`}>
              <Row>
                {
                  !isSaveAndCloseClickedSCState && this.localSCWidgetList.filter((item: IdefaultWidget) => item.isVisible === true)
                    .map((item: IdefaultWidget, index: number) => {
                      return (
                        <Col key={`selection-criteria-${item.controlType}${markGridsForUpdate ? '-edit' : ''}`} className="selection-criteria" lg={item.columnWeight}>
                          <SelectionCriteriaGrid
                            setFullScreenControlType={this.setFullScreenControlType}
                            fullScreenControlType={fullScreenControlType}
                            controlType={item.controlType}
                            customDataSource={this.customDataSource}
                            gridColumns={item.gridColumns!}
                            getColumnCaption={this.getColumnCaption}
                            returnForceUpdateCallback={this.returnForceUpdateCallback}
                            onGridSelectionChanged={this.onGridSelectionChanged}
                            highlightingKeys={item.highlightingKeys!}
                            onFilterExpressionChanged={this.onFilterExpressionChanged}
                            selectedRowKeys={(item.selectedRowKeys! || []).map((selectionKey: string) => (selectionKey))!}
                            toggleVisibility={this.toggleVisibility}
                            columnWeight={item.columnWeight}
                            changeWeight={this.changeWeight}
                            pageSize={selectedPageSize}
                            errorMessage={item.errorMessage!}
                            isCleared={item.isCleared}
                            updateDefaultWidgetItem={this.updateDefaultWidgetItem}
                            syncWithLocalStorage={this.syncWithLocalStorage}
                            maxSelectedCount={item.maxSelectedCount}
                            filteringKeys={item.filteringKeys}
                            minVisibilityCriteria={item.minVisibilityCriteria}
                            selectionCounts={item.isCleared ? [] : selectionCounts!}
                            hideTestParamforPolicy={hideTestParamforPolicy}
                            widgetIndexInDefaultWidgetsList={index}
                            onChangeInVisibleColumns={this.onChangeInVisibleColumns}
                            updateLocalVisibleColumnsListForPolicy={visibleColumnsForPolicy ? this.updateLocalVisibleColumnsListForPolicy : undefined}
                            getColumnNamesFromCallback={this.getColumnNamesFromCallback}
                            refreshGridWithControlType={this.refreshGridWithControlType}
                          />
                        </Col>
                      );
                    })
                }
              </Row>
            </div>
          </ScrollView>
        </Container>
        { isFavoritePopupVisible
        && (
          <Popup
            visible={isFavoritePopupVisible}
            onHiding={this.onFavoritesPopupClose}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton
            showTitle={false}
            title={selectedFavoriteAction.text}
          >
            {this.renderFavorite(selectedFavoriteAction)}
          </Popup>
        )}
      </ScrollView>

    );
  }

  private customDataSource = (
    controlType: string,
    entityType: string,
    selectedRowKeys: string[],
    highlightingKeys: { [key: string]: IVisibleColumnsDetails },
    index: number
  ) => {
    if (entityType !== 'TEST_PARAMETER'){
      delete highlightingKeys.TESTPARAMETER;
    }
    const { isForSeedCalculation } = this.props;
    const advancedFilter: string = this.customExpressions;
    const widget = this.localSCWidgetList[index]
    return createStore({
      key: 'key_column',
      loadMethod: 'POST',
      loadUrl: entityType === 'TEST_PARAMETER' ? `${AppConstants.baseURL}/api/test-parameter-and-column-details`
        : `${AppConstants.baseURL}/api/master-meta-and-column-details`,
      loadParams: {
        entityType,
        controlType,
        advancedFilter,
        highlightKeys: JSON.stringify(Object.values(highlightingKeys)),
        selectedRowKeys: JSON.stringify(selectedRowKeys),
        requireTotalCount: true,
        isForSeedCalculation,
        defaultSelectionCriteria: JSON.stringify(widget.defaultSelectionCriteria),
      },
      onBeforeSend: (method: any, ajaxOptions: any) => {
        const database = GeneralUtils.getCookieValue('DATABASE');
        const token = GeneralUtils.getCookieValue('AUTH_TOKEN');
        userDataUtil.registerInteractionEvent(`Rendering [${controlType}] in Selection Criteria`, 'SCW');
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          SelectedDatabase: database,
        };
        ajaxOptions.xhrFields = {
          withCredentials: true,
        };
        ajaxOptions.data.filter = JSON.stringify(this.parseFilter(ajaxOptions.data.controlType, ajaxOptions.data.entityType, ajaxOptions.data.filter));

        const data = JSON.parse(JSON.stringify(ajaxOptions.data));
        ajaxOptions.data = {
          param: JSON.stringify(data),
        };
      },
    });
  };
}

export default SelectionCriteria;
