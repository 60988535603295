import React from 'react';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'CustomToast';
import { httpWaferControlMap } from 'services/http.wafer-control-map';
import Button from '../wrapped-component/hint-controls/Button';

interface WaferControlMapsProps extends RouteComponentProps {
  className?: string
}

interface WaferControlMapsState {
  waferControlMaps: any[]
}

class WaferControlMaps extends React.Component<WaferControlMapsProps, WaferControlMapsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      waferControlMaps: [],
    };
  }

  componentDidMount() {
    this.getWaferControlMaps();
  }

  getWaferControlMaps = async () => {
    const wcmDataResponse = await httpWaferControlMap.getWaferControlMaps();
    if (wcmDataResponse) {
      if (wcmDataResponse.success) {
        this.setState({ waferControlMaps: wcmDataResponse.data });
      } else if (wcmDataResponse.message) {
        toast.error(wcmDataResponse.message);
      }
    }
  };

  onRowRemoved = async (event: any) => {
    const response = await httpWaferControlMap.deleteWaferControlMap(event.data.id);
    if (response) {
      if (response.success) {
        toast.success('Wafer control map deleted successfully');
      } else if (response.message) {
        toast.error(response.message);
      }
      this.setState({ waferControlMaps: response.data });
    }
  };

  render() {
    const { className, history } = this.props;
    const { waferControlMaps } = this.state;
    return (
      <Container fluid className={className !== '' ? className : ''}>
        <TopbarNav
          title="Wafer Control Maps"
          items={[]}
          showAvatar
          showNotifications
          secondaryActions={(
            <Button
              onClick={() => {
                history.push('/wafer-control-map');
              }}
              size="sm"
              variant="primary"
              className="mr20 mt-5"
            >
              Create Wafer Control Map
            </Button>
          )}
        />
        <Row className="p20">
          <Col>
            <CustomizedDataGrid
              showAdvancedFilters={false}
              showFilterRow
              allowDeleting
              showContextMenu={false}
              allowUpdating
              onRowRemoved={this.onRowRemoved}
              onEditingStart={(event: any) => {
                // eslint-disable-next-line no-param-reassign
                event.cancel = true;
                history.push(`/wafer-control-map/${event.data.id}`);
              }}
              fields={[
                {
                  caption: 'Name',
                  dataField: 'name',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                  wordWrap: true,
                },
                {
                  caption: 'Owner',
                  dataField: 'owner',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                  wordWrap: true,
                },
                {
                  caption: 'Version',
                  dataField: 'version',
                  dataType: 'number',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'State',
                  dataField: 'state',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Access',
                  dataField: 'access',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Description',
                  dataField: 'description',
                  dataType: 'string',
                  showInfo: false,
                  minWidth: 200,
                },
                {
                  caption: 'Created On',
                  dataField: 'createdOn',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
                {
                  caption: 'Updated On',
                  dataField: 'updatedOn',
                  dataType: 'string',
                  showInfo: false,
                  width: 100,
                },
              ]}
              tableData={waferControlMaps}
            />
          </Col>
        </Row>
      </Container>

    );
  }
}

export default withRouter(WaferControlMaps);
