/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import toast from 'CustomToast';
import { ILimitSettingsObj } from 'components/wrapped-component/limit-settings-modal/limitSettingsModal';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import {
  InteractionInputDTO, ICombinedGraphData, AggregateFunctionNames, SeriesTypeNames, ICombinedGraphDataWithReportHeaders,
} from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import TestParameterBadges from '../../customized-report-utils/TestParameterBadges';
import ReportOptionsModal from '../../customized-report-utils/ReportOptionsModal';
import UtilityOptions from '../../customized-report-utils/UtilityOptions';
import ReportHorizontalScrollView from '../../customized-report-utils/ReportHorizontalScrollView';

export class ParametricHistogramGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: false,
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    graphType: ('bar' as IGraphType),
    graphMode: ('markers' as IGraphMode),
    markerOpacity: 0.9,
    markerBorderOpacity: 0,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: true,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: true,
    showXAxisBreaks: true,
    allowDefaultGrouping: false,
    extractSelectedValuesFromToolTips: false,
    showAddedMarkers: false,
    showAddedLines: {
      grouping: {
        show: false,
        name: '',
      },
      noGrouping: {
        show: false,
        name: '',
      },
    },
    textOnHover: [['placeholder']],
    hoverTemplate: {
      grouping: '%{text}',
      noGrouping: '%{text}',
    },
    selectionEventDefaults: {
      xAxisLabel: 'BIN_NUMBER',
      yAxisLabel: 'DIE_COUNT',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'lot_id',
      entityType: ('LOT' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'Class Range',
        yAxisName: 'Die Count',
      },
      noGrouping: {
        xAxisName: 'Class Range',
        yAxisName: 'Die Count',
      },
    },
    showTickText: {
      grouping: {
        xAxis: true,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    defineCustomAxisRange: {
      xAxis: false, // TODO: Change when limit lines changes implemented in backend
      yAxis: false,
    },
    useCaptionAsGroupValueWhenGroupingPresent: false,
    barGap: 0,
  };

  getPreferences = () => {
    return this.preferences;
  };

  getSecondaryComponent = (props: ICustomizedReportProps) => {
    const testParameterNames: any[] = [];
    const testParameters = props.selectionStore.selections.filter((x: any) => x.entityType === 'Testparameter');
    if (testParameters.length > 0 && testParameters[0].values.length > 0) {
      testParameterNames.push(testParameters[0].values[0].name);
      return (
        <TestParameterBadges testParameterNames={testParameterNames} />
      );
    }
    return <></>;
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props: ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });
    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied, undefined, updateParentState),
    });

    return tabs;
  };

  processInput = (
    state: ICustomizedReportState,
    setStateCallback: any,
  ) => {
    setStateCallback({
      aggredateFunction: state.aggredateFunction as AggregateFunctionNames | 'None',
      seriesType: state.seriesType as SeriesTypeNames,
      showReportRow: true,
      showModal: false,
      limitSettingsObj: {},
    });
  };

  applyLimitSettings = (limitSettingsObject: any, testParameterSelectedIndexUpdated: number, testParameterIndexForWhichLimitsApplied: number, setStateCallback: any, errors: any) => {
    setStateCallback({ limitSettingsObj: limitSettingsObject, testParameterSelectedIndex: testParameterSelectedIndexUpdated, testParameterIndexForWhichLimitsApplied }, errors);
    toast.success('Limit settings applied');
  };

  getPrimaryComponent = (props: ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    const {
      groupingSortingListStore, selectionStore, report, testParameterIndex,
    } = props;
    const { limitSettingsObj } = state;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    const testParameterValues: any[] = [];
    testParameters.forEach((testParameter: any) => {
      testParameterValues.push(testParameter.values);
    });
    return (
      <>
        <UtilityOptions
          limitSettingsObj={limitSettingsObj}
          showLimitLinesOptions
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
        />
        <ReportOptionsModal
          reportActor={report.actor}
          groupingSortingListStore={groupingSortingListStore}
          selectionStore={selectionStore}
          testParameterValues={testParameterValues}
          testParameterIndex={testParameterIndex}
          parentState={state}
          setParentStateCallback={(callbackObj: any) => {
            setStateCallback(callbackObj);
          }}
          applyLimitSettings={(limitSettingsObject: ILimitSettingsObj, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => {
            this.applyLimitSettings(limitSettingsObject, testParameterSelectedIndex, testParameterIndexForWhichLimitsApplied, setStateCallback, errors);
          }}
          requestObject={requestObject}
        />
      </>
    );
  };

  generateGraphComponent = (props: ICustomizedReportGraphProps, state: ICustomizedReportGraphState, classMembers: any, setStateCallback: any) => {
    const { graphData } = state;
    this.updatePreferences(props, (graphData as ICombinedGraphData));
    return (
      <ReportHorizontalScrollView
        reportGraphProps={props}
        reportGraphState={state}
        classMembers={classMembers}
        setStateCallback={setStateCallback}
        preferences={this.preferences}
      />
    );
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    }).catch((err: any) => {
      const errorDetails: any = err.message;
      const graphingUtil = new GraphingUtils();
      callback(graphingUtil.generateDataObjectFromError(errorDetails));
    });
  };

  updatePreferences = (props: ICustomizedReportGraphProps, data: ICombinedGraphData) => {
    this.preferences.textOnHover = [];
    data.graphs.forEach((graph: any) => {
      this.preferences.textOnHover.push(graph.y);
    });
  };
}
