import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpWaferControlMap extends HttpBase {
  postWaferControlMap = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/wafer-control-map/`, data);
  };

  getWaferControlMap = (id: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/wafer-control-map/${id}`);
  };

  getWaferControlMaps = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/wafer-control-map/`);
  };

  putWaferControlMap = (data: any, id: string): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/wafer-control-map/${id}`, data);
  };

  postWaferControlMapAttributeMeta = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/wafer-control-map/attribute-meta`, data);
  };

  getWaferControlMapAttributeMetas = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/wafer-control-map/attribute-meta`);
  };

  deleteWaferControlMapAttributeMeta = (id: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/wafer-control-map/attribute-meta/${id}`);
  };

  deleteWaferControlMap = (id: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/wafer-control-map/${id}`);
  };

  getWaferControlMapAttributeDefinitions = (wcmId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/wafer-control-map/${wcmId}/attribute-definition/`);
  };
}

const httpWaferControlMap = new HttpWaferControlMap();
export { httpWaferControlMap };
