/* eslint-disable react/no-array-index-key */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import { faChevronLeft, faSave, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import _ from 'lodash';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import arrayMove from 'array-move';

import { httpMasterMeta } from 'services/http.master-meta';
import toast from 'CustomToast';
import { httpReportHeader } from 'services/http.report-header';
import { toTitleCase } from 'GeneralUtils'
import ReportHeaderFieldsCardView from './ReportHeaderFieldsCardView';
import ReportHeaderFieldsTreeView from './ReportHeaderFieldsTreeView';
import Heading from '../../wrapped-component/hint-controls/Heading';

type fieldType = 'FREE_TEXT' | 'COLUMN';
interface IColumnMeta {
  isComputedField?: boolean;
  entityType:string;
  columnName:string;
  dataField: string;
  tableName:string;
  graphType?:any;
  type:string;
  isPolicyStepField: boolean;
}
interface ITitleExpression {
  entityType: string;
  tableName: string;
  columnName: string;
  sequence: number;
  placeHolder: string;
}
export interface IFieldValue {
  formatValue: string;
  value?: any;
  valuesX?: number[];
  valuesY?: number[];
  name: string;
  label: string;
  color: string;
}
export interface IFieldExpression {
  entityType: string;
  tableName: string;
  columnName: string;
  sequence: number;
  graphType?: any;
  isPolicyStepField: boolean;
  isComputedField: boolean;
  computedFieldName: string;
  fieldLabel : string;
  fieldValues: IFieldValue[];
}
export interface IReportHeaderSettingWithHousekeeping {
  id: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy:string;
  user: any;
  defaultHeader?: boolean;
  headerSettings: IReportHeaderSetting;
}
export interface IReportHeaderSetting {
  id?: string;
  name: string;
  titleFontSize: number;
  fieldFontSize: number;
  displayFieldsCount: number;
  rowCount: number;
  columnCount: number;
  itemsAlignment: string;
  defaultHeader?: boolean;
  collapseHeader? :boolean;

  titleTemplate: string;
  title: string;
  titleExpression: ITitleExpression[];
  fields: IFieldExpression[] | [];
  userId?: string;
}
interface ReportHeaderBuilderState {
  columnMeta: { [key:string]: IColumnMeta[] };
  reportHeaderSettings: IReportHeaderSetting;
  selectedFields: IFieldExpression[];
  isEdit: boolean;
}

interface IReportHeaderBuilderProps {
  onHide?: () => void;
  onSave?: () => void;
  reportHeaderSettingsProps?: IReportHeaderSetting;
}

export type GraphType =
    'BAR_CHART' |
    'PIE_CHART';

interface ISummaryStat {
  fieldName: string;
  graphType?: any;
  isPolicyStepField : boolean;
}
class ReportHeaderBuilder extends React.Component<IReportHeaderBuilderProps, ReportHeaderBuilderState> {
  private selectedTitleFields : ITitleExpression[] = [];

  // DISABLED SINCE INDIVIDUAL STATUS PER DIE PER TEST PARAMETER IS UNAVAILABLE ATM IN THE APP
  private summaryStats : ISummaryStat[] = [
    // { fieldName: 'PASSING_AND_FAILING_DIES', graphType: 'PIE_CHART', isPolicyStepField: false },
    { fieldName: 'BIN_YIELD', graphType: 'PIE_CHART', isPolicyStepField: false },
    // { fieldName: 'PARAMETRIC_YIELD', graphType: 'PIE_CHART', isPolicyStepField: false },
    { fieldName: 'DIE_MIN_AND_MAX', graphType: 'BAR_CHART', isPolicyStepField: false },
    { fieldName: 'PAT_HIGH_LOW_LIMIT', graphType: 'BAR_CHART', isPolicyStepField: true },
    { fieldName: 'MEAN', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'MEDIAN', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'STD_DEV', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'CP', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'CPK1', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'CPK2', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'CPK', graphType: undefined, isPolicyStepField: false },
    { fieldName: 'DIE_COUNT', graphType: undefined, isPolicyStepField: false },
    // { fieldName: 'BEST_FIT_LINE', graphType: undefined, isPolicyStepField: false },
  ];

  constructor(props:any) {
    super(props);
    const { reportHeaderSettingsProps } = this.props;
    this.state = {
      // titleExpression: '',
      columnMeta: {},
      reportHeaderSettings: reportHeaderSettingsProps ? this.extractReportHeaderSettingsFromProps(reportHeaderSettingsProps) : {
        name: '',
        columnCount: 3,
        rowCount: 3,
        title: '',
        fields: [],
        titleExpression: [],
        displayFieldsCount: 9,
        fieldFontSize: 12,
        titleFontSize: 18,
        itemsAlignment: 'LEFT',
        titleTemplate: '',
        id: '',
      },
      selectedFields: reportHeaderSettingsProps ? reportHeaderSettingsProps.fields : [],
      isEdit: !!reportHeaderSettingsProps,
    };
  }

  extractReportHeaderSettingsFromProps = (reportHeaderSettingsProps: IReportHeaderSetting) => {
    return {
      name: reportHeaderSettingsProps.name,
      columnCount: reportHeaderSettingsProps.columnCount,
      rowCount: reportHeaderSettingsProps.rowCount,
      title: reportHeaderSettingsProps.title,
      fields: reportHeaderSettingsProps.fields,
      titleExpression: reportHeaderSettingsProps.titleExpression,
      displayFieldsCount: reportHeaderSettingsProps.displayFieldsCount,
      fieldFontSize: reportHeaderSettingsProps.fieldFontSize,
      titleFontSize: reportHeaderSettingsProps.titleFontSize,
      itemsAlignment: reportHeaderSettingsProps.itemsAlignment,
      titleTemplate: reportHeaderSettingsProps.titleTemplate,
      id: reportHeaderSettingsProps.id,
    };
  };

  replaceAttributesForWaferOrLot = (columnMeta : { [key:string]: IColumnMeta[] }, waferOrLot: string) => {
    const value = columnMeta[waferOrLot].find((x: IColumnMeta) => x.columnName === 'key');
    value!.columnName = 'id';
    value!.dataField = `${waferOrLot.toLowerCase()}_id`;
  }

  componentDidMount() {
    Promise.allSettled(
      [httpMasterMeta.getIndividualColumnsList()],
    ).then((values: any) => {
      const columnMeta : { [key:string]: IColumnMeta[] } = values[0].value;
      Object.keys(columnMeta).forEach((key) => {
        columnMeta[key] = columnMeta[key].filter((x: IColumnMeta) => !x.columnName.includes('id'));
      });
      this.replaceAttributesForWaferOrLot(columnMeta, 'WAFER');
      this.replaceAttributesForWaferOrLot(columnMeta, 'LOT');
      this.summaryStats.forEach((summaryStat) => {
        if (columnMeta.SUMMARY_STATISTICS === undefined) {
          columnMeta.SUMMARY_STATISTICS = [];
        }
        columnMeta.SUMMARY_STATISTICS.push({
          entityType: 'SUMMARY_STATISTICS',
          columnName: summaryStat.fieldName,
          dataField: summaryStat.fieldName,
          tableName: '',
          type: 'string',
          graphType: summaryStat.graphType,
          isComputedField: true,
          isPolicyStepField: summaryStat.isPolicyStepField,
        });
      });

      this.setState({ columnMeta });
    });
  }

  updateReportForm = (key:string, value:any, dataType:'string' | 'number') => {
    const { reportHeaderSettings } = this.state;
    const updatedReportHeaderSettings = _.cloneDeep(reportHeaderSettings);
    if ((updatedReportHeaderSettings as any)[key] !== undefined) {
      if (dataType === 'number') {
        (updatedReportHeaderSettings as any)[key] = _.toNumber(value);
      } else {
        (updatedReportHeaderSettings as any)[key] = value;
      }
    }
    this.setState({ reportHeaderSettings: updatedReportHeaderSettings });
  };

  onFieldSelected = (e:any) => {
    const { selectedFields } = this.state;

    const row: any = e.row.data;

    const field : IFieldExpression = {
      isComputedField: row.isComputedField !== undefined ? row.isComputedField : false,
      entityType: row.entityType,
      columnName: row.columnName,
      computedFieldName: row.columnName,
      sequence: selectedFields.length,
      tableName: row.tableName,
      fieldLabel: toTitleCase(row.columnName.toUpperCase()),
      fieldValues: [],
      graphType: row.graphType,
      isPolicyStepField: row.isPolicyStepField,
    };

    selectedFields.push(field);

    this.setState({ selectedFields });
  };

  appendSelectedFields = (entityType:string, value:string, runningIndex:number, fieldType:fieldType) => {
    const { selectedTitleFields } = this;
    const { columnMeta } = this.state;
    if (fieldType === 'COLUMN' && entityType.trim() !== '' && columnMeta[entityType.toUpperCase()]) {
      const fieldItems = columnMeta[entityType.toUpperCase()];
      const currentFieldIndex = fieldItems.findIndex((x) => x.columnName === value);
      if (currentFieldIndex >= 0) {
        selectedTitleFields.push({
          columnName: fieldItems[currentFieldIndex].columnName,
          sequence: runningIndex,
          tableName: fieldItems[currentFieldIndex].tableName,
          entityType,
          placeHolder: `<${runningIndex}>`,
        });
        return true;
      }
    }
    return false;
  };

  replaceBetween = (testString:string, replaceChar:string, startIndex:number, endIndex:number) => {
    const firstPart = testString.substr(0, startIndex - 1);
    let lastPart = '';
    if (endIndex + 1 < testString.length) {
      lastPart = testString.substr(endIndex + 1);
    }
    return firstPart + replaceChar + lastPart;
  };

  parseSquaredBrackets = (testString:string, startChar:string, endChar:string, runningIndex:number, fieldType:fieldType) => {
    const expressionsCount = testString.length;
    for (let i = 0; i < expressionsCount; i += 1) {
      const startIndex = testString.indexOf(startChar) + 1;
      const endIndex = testString.indexOf(endChar);

      if (startIndex < 0 || endIndex < 0) {
        continue;
      }
      if (testString.substring(startIndex, endIndex) === '') {
        break;
      } else {
        const currentFieldValues = testString.substring(startIndex, endIndex).split('.');
        if (fieldType === 'COLUMN' && currentFieldValues.length === 2) {
          const isFieldAppendable = this.appendSelectedFields(currentFieldValues[0], currentFieldValues[1], runningIndex, fieldType);
          if (isFieldAppendable) {
            testString = this.replaceBetween(testString, `<${runningIndex}>`, startIndex, endIndex);
            runningIndex += 1;
          }
        }
      }
    }
    return [testString, runningIndex];
  };

  createExpression = (testString:any) => {
    this.selectedTitleFields = [];
    let placeholderString = testString;
    let runningIndex: any = 0;
    [placeholderString, runningIndex] = this.parseSquaredBrackets(placeholderString, '[', ']', runningIndex, 'COLUMN');
    const { reportHeaderSettings } = this.state;
    const updatedReportHeaderSettings = _.cloneDeep(reportHeaderSettings);
    updatedReportHeaderSettings.titleTemplate = placeholderString;
    updatedReportHeaderSettings.titleExpression = this.selectedTitleFields;
    updatedReportHeaderSettings.title = testString;
    this.setState({ reportHeaderSettings: updatedReportHeaderSettings });
  };

  removeField = (index:number) => {
    const { selectedFields } = this.state;

    selectedFields.splice(index, 1);
    for (let i = 0; i < selectedFields.length; i += 1) {
      selectedFields[i].sequence = i;
    }
    this.setState({ selectedFields });
  };

  clearAll = () => {
    this.setState({ selectedFields: [] });
  };

  prepareTreeViewData = () => {
    const { columnMeta } = this.state;
    const dataSource : { [key:string]: any }[] = [];
    const keys = Object.keys(columnMeta);
    keys.forEach((key) => {
      dataSource.push({
        id: key,
        parentId: null,
        caption: key,
        entityType: key,
        tableName: null,
        columnName: null,
        isComputedField: null,
        graphType: null,
        isPolicyStepField: false,
      });
      columnMeta[key].forEach((field, index) => {
        dataSource.push({
          id: `${key}_${index}`,
          parentId: key,
          caption: field.columnName,
          entityType: key,
          tableName: field.tableName,
          columnName: field.columnName,
          isComputedField: field.isComputedField !== undefined,
          graphType: field.graphType === undefined ? null : field.graphType,
          isPolicyStepField: field.isPolicyStepField,
        });
      });
    });
    return dataSource;
  };

  onFieldDragActionEnd = ({ oldIndex, newIndex } :any) => {
    let { selectedFields } = this.state;

    selectedFields = arrayMove(selectedFields, oldIndex, newIndex);
    for (let i = 0; i < selectedFields.length; i += 1) {
      selectedFields[i].sequence = i;
    }
    this.setState({ selectedFields });
  };

  updateField = (value:string, fieldNameToDisplay:string, columnName:string, entityType:string) => {
    const { selectedFields } = this.state;

    const field = selectedFields.find((f:IFieldExpression) => f.columnName === columnName && f.entityType === entityType);

    if (field) {
      if (value.trim() !== '') {
        field.fieldLabel = value;
      } else {
        toast.error(`Field Name for ${fieldNameToDisplay} can not be empty.`);
      }
    }

    this.setState({ selectedFields });
  };

  saveHeader = () => {
    const { reportHeaderSettings, selectedFields, isEdit } = this.state;

    if (reportHeaderSettings.title.trim() === '') {
      toast.error('Title can not be empty');
      return;
    }
    if (reportHeaderSettings.name.trim() === '') {
      toast.error('Name can not be empty');
      return;
    }
    if (reportHeaderSettings.titleFontSize === 0) {
      toast.error('Title font size can not be empty');
      return;
    }
    if (reportHeaderSettings.fieldFontSize === 0) {
      toast.error('Field font size can not be empty');
      return;
    }
    if (reportHeaderSettings.title.length > 100 || reportHeaderSettings.name.length > 100) {
      toast.error('Title and/or Name cannot exceed 150 characters');
      return;
    }
    if (reportHeaderSettings.titleFontSize > 200 || reportHeaderSettings.fieldFontSize > 200) {
      toast.error('Title font size and/or field font size cannot exceed 200');
      return;
    }
    if (selectedFields.length === 0) {
      toast.error('Select at least one field to display');
      return;
    }

    reportHeaderSettings.fields = selectedFields;
    if (isEdit) {
      this.updateReportHeader(reportHeaderSettings);
    } else {
      this.saveReportHeader(reportHeaderSettings);
    }
  };

  updateReportHeader = (reportHeaderSettings: IReportHeaderSetting) => {
    httpReportHeader.update(reportHeaderSettings).then(() => {
      toast.success('Header updated successfully!');
      this.saveAndHide();
    });
  };

  saveReportHeader = (reportHeaderSettings: IReportHeaderSetting) => {
    httpReportHeader.save(reportHeaderSettings).then(() => {
      toast.success('Header created successfully!');
      this.saveAndHide();
    });
  };

  saveAndHide = () => {
    const { onSave, onHide } = this.props;
    if (onSave) {
      onSave();
    }
    if (onHide) {
      onHide();
    }
  };

  render() {
    const { reportHeaderSettings, selectedFields, isEdit } = this.state;
    const { onHide } = this.props;

    const dataSource = this.prepareTreeViewData();
    return (
      <div className="custom-form p10">
        <h5>Header Settings</h5>
        <Row>
          <Col lg={3}>
            <Label
              labelTitle="Name"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="headerName"
                type="text"
                placeholder="Header Name"
                className=""
                defaultValue={reportHeaderSettings.name}
                onChange={(e:any) => {
                  this.updateReportForm('name', e.target.value, 'string');
                }}
              />
            </Label>
          </Col>
          <Col lg={2}>
            <Label
              labelTitle="Title Font Size"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="titleFontSize"
                type="number"
                placeholder="Title Font Size"
                defaultValue={reportHeaderSettings.titleFontSize}
                className=""
                onChange={(e:any) => {
                  this.updateReportForm('titleFontSize', e.target.value, 'number');
                }}
              />
            </Label>
          </Col>
          <Col lg={2}>
            <Label
              labelTitle="Field Font Size"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="fieldFontSize"
                type="number"
                placeholder="Field Font Size"
                defaultValue={reportHeaderSettings.fieldFontSize !== 0 ? reportHeaderSettings.fieldFontSize : undefined}
                className=""
                onChange={(e:any) => {
                  this.updateReportForm('fieldFontSize', e.target.value, 'number');
                }}
              />
            </Label>
          </Col>
        </Row>
        <Row className="mb10">
          <Col lg={3}>
            <Label
              labelTitle="Total Fields to Display"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="displayFieldsCount"
                type="number"
                placeholder="Number of Fields to Display"
                defaultValue={reportHeaderSettings.displayFieldsCount !== 0 ? reportHeaderSettings.displayFieldsCount : undefined}
                className=""
                onChange={(e:any) => {
                  this.updateReportForm('displayFieldsCount', e.target.value, 'number');
                }}
              />
            </Label>
          </Col>
          <Col lg={3}>
            <Label
              labelTitle="Number of Rows"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="rowCount"
                type="number"
                placeholder="Number of Rows"
                defaultValue={reportHeaderSettings.rowCount !== 0 ? reportHeaderSettings.rowCount : undefined}
                className=""
                onChange={(e:any) => {
                  this.updateReportForm('rowCount', e.target.value, 'number');
                }}
              />
            </Label>
          </Col>
          <Col lg={3}>
            <Label
              labelTitle="Number of Columns"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
              error=""
              isFieldTouched
            >
              <Textbox
                autoComplete="off"
                name="columnCount"
                type="number"
                placeholder="Number of Columns"
                defaultValue={reportHeaderSettings.columnCount !== 0 ? reportHeaderSettings.columnCount : undefined}
                className=""
                onChange={(e:any) => {
                  this.updateReportForm('columnCount', e.target.value, 'number');
                }}
              />
            </Label>
          </Col>
          <Col lg={3}>
            <Label
              labelTitle="Field Test Alignment"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
            >
              <CustomizedDropdown
                variant="clear"
                full
                list={[
                  ['LEFT', 'Left'],
                  ['RIGHT', 'Right'],
                  ['CENTER', 'Center'],
                ]}
                selectedValue={reportHeaderSettings.itemsAlignment}
                onChange={(value:string) => {
                  this.updateReportForm('itemsAlignment', value, 'string');
                }}
              />
            </Label>
          </Col>
        </Row>
        {/* {this.plotFields()} */}
        <Row className="mb10">
          <Col lg={12}>
            {
              onHide && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={onHide}
                  className="mr10"
                >
                  <FontAwesomeIcon className="mr10" size="sm" icon={faChevronLeft} />
                  Cancel
                </Button>
              )
            }
            <Button
              variant="success"
              size="sm"
              onClick={this.saveHeader}
            >
              <FontAwesomeIcon className="mr10" size="sm" icon={faSave} />
              {isEdit ? 'Update' : 'Save'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ReportHeaderFieldsTreeView
              dataSource={dataSource}
              selectionHandler={this.onFieldSelected}
              selectedFields={selectedFields}
            />
          </Col>
          <Col lg={8} className="background-color-light p10 border-all h500">
            <Label
              labelTitle="Title"
              labelPosition="left-top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              required
            >
              <Textbox
                placeholder="Title"
                autoComplete="off"
                type="text"
                name="titleExpression"
                className="w-100 color-default-color mt5"
                value={reportHeaderSettings.title}
                onChange={(e:any) => {
                  this.createExpression(e.target.value === undefined ? '' : e.target.value);
                }}
              />
            </Label>
            <div className="d-flex mt10 align-items-center justify-content-between">
              <Heading size={5}>Fields</Heading>
              {
                selectedFields.length > 0 && (
                  <Button
                    variant="link"
                    size="sm"
                    onClick={this.clearAll}
                    className="color-danger"
                  >
                    Clear All Fields
                  </Button>
                )
              }
            </div>
            <ReportHeaderFieldsCardView
              isReadOnly={false}
              reportHeaderSettingsFields={selectedFields}
              reportHeaderSettings={reportHeaderSettings}
              onFieldDragActionEnd={this.onFieldDragActionEnd}
              removeField={this.removeField}
              updateFieldLabel={this.updateField}
            />
          </Col>
        </Row>

      </div>
    );
  }
}

export default ReportHeaderBuilder;
