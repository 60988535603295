// eslint-disable-next-line import/no-cycle
import EntityHeader from 'components/wrapped-component/entity-header/EntityHeader';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IAddOrEditFavoriteSCProps {
  callbackOnSave: (data: any) => void;
  callbackOnDiscard: () => void;
  title: string;
  favoriteToEdit?: any;
}
interface IAddOrEditFavoriteSCState {
  name: string;
  owner: string;
  privacy: string;
  purpose: string;
}

class AddOrEditFavoriteSC extends Component<IAddOrEditFavoriteSCProps, IAddOrEditFavoriteSCState> {
  static defaultProps: Partial<IAddOrEditFavoriteSCProps> = {
    favoriteToEdit: null,
  }

  constructor(props: IAddOrEditFavoriteSCProps) {
    super(props);
    const { favoriteToEdit } = this.props;
    if (favoriteToEdit) {
      this.state = {
        name: favoriteToEdit.name,
        owner: favoriteToEdit.owner,
        privacy: favoriteToEdit.privacy,
        purpose: favoriteToEdit.purpose,
      }
      return;
    }
    this.state = {
      name: '',
      owner: '',
      privacy: 'PUBLIC',
      purpose: '',
    }
  }

  saveOrUpdate = (data:any) => {
    const { callbackOnSave } = this.props;
    const {
      name, owner, privacy, purpose
    } = data;
    // stop if the data is empty
    if (!name || !owner || !privacy || !purpose) {
      return
    }
    callbackOnSave({
      name, owner, privacy, purpose
    })
  }

  render() {
    const {
      name, owner, privacy, purpose,
    } = this.state;
    const { title, callbackOnDiscard } = this.props;
    return (
      <Container fluid className="mt10 position-relative">
        <Row>
          <Col lg={3}>
            <h2>{title}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <EntityHeader
              hideTitle
              hideVersion
              hideState
              name={name}
              owner={owner}
              version=""
              privacy={privacy}
              purpose={purpose}
              state="DRAFT"
              onParentSave={this.saveOrUpdate}
              onParentDiscard={callbackOnDiscard}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default AddOrEditFavoriteSC;
