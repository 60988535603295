import { useState, useEffect } from 'react';
import DataGrid, { Column, Selection} from 'devextreme-react/data-grid';
import { IDatabase } from 'interfaces';
import DropdownButton from 'react-bootstrap/esm/DropdownButton';
import { Button } from 'react-bootstrap';

interface IDBProps {
  list: IDatabase[];
  onChange?: (value: string) => void;
  placeholder: string;
  remainExpanded: boolean;
  defaultDatabaseId: string;
}

function DatabaseDropdownGrid(props: IDBProps) {
  const {
    list, onChange, placeholder, defaultDatabaseId, remainExpanded
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleDropdownClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleRowClick = (item: any) => {
    const index = list.findIndex((element: IDatabase) => element.id === item.data.id);
    setSelectedIndex(index);
    if (!remainExpanded) setIsExpanded(false)
    if (onChange){
      onChange(item.data.id);
    }
  }

  useEffect(() => {
    const index = list.findIndex((element: IDatabase) => element.id === defaultDatabaseId);
    if (index !== selectedIndex) { // check if index has changed
      setSelectedIndex(index);
      if (onChange && defaultDatabaseId !== null) {
        onChange(defaultDatabaseId);
      }
    }
  }, [defaultDatabaseId, list, onChange, selectedIndex]);

  return (<div className={`d-flex align-items-center`}>
      <div className="position-relative w-100">
        <DropdownButton
          className={'dropdown-full'}
          disabled={false}
          drop="down"
          title={selectedIndex === -1 ? placeholder : `${list[selectedIndex].name} : ${list[selectedIndex].type}`}
          key={Math.random().toString()}
          onClick={handleDropdownClick}
        />
        {isExpanded &&
        <div 
        className="database-dropdown-grid"> 
            <DataGrid
              dataSource={list}
              showBorders={true}
              hoverStateEnabled
              allowColumnResizing={true}
              filterRow={{
                visible: true
              }}
              highlightChanges
            >
              <Selection mode="single" />
              <Column
                alignment={'center'}
                caption={"Select"}
                allowFiltering={false}
                cellRender={(item) => (
                  <input
                    type="radio"
                    checked={item.data === list[selectedIndex]}
                    onChange={()=>handleRowClick(item)}
                    onSelect={()=>handleRowClick(item)}
                  />
                )}
              />
              <Column 
              alignment={'center'}
              dataField="name" 
              caption="Name" 
              />
              <Column 
              alignment={'center'}
              dataField="type" 
              caption="Type" 
              />
            </DataGrid>
        </div>
        }
      </div>
  </div>);
}
export default DatabaseDropdownGrid;