import {
  faEdit, faPlus, faTimes, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import toast from 'CustomToast';
import { Popup } from 'devextreme-react';
import React from 'react';
import {
  Button, Col, Container, Row
} from 'react-bootstrap';
import { httpSCW } from 'services/http.scw';

interface CreateOrEditFavoriteFolderProps {
  isVisible: boolean;
  isCreate: boolean;
  folder?: any;
  onClose: () => void;
  onAddFavoriteFolder?: (favorite: any) => void;
  onEditFavoriteFolder?: (favorite: any) => void;
  onDeleteFavoriteFolder?: (favorite: any) => void;
}
interface CreateOrEditFavoriteFolderState {
}
class CreateOrEditFavoriteFolder extends React.Component<CreateOrEditFavoriteFolderProps, CreateOrEditFavoriteFolderState> {
  static defaultProps: Partial<CreateOrEditFavoriteFolderProps> = {
    folder: null,
    onAddFavoriteFolder: undefined,
    onEditFavoriteFolder: undefined,
    onDeleteFavoriteFolder: undefined,
  }

  textFieldRef: any = null;

  constructor(props: CreateOrEditFavoriteFolderProps) {
    super(props);
    this.state = {
    };
  }

  createFavorite = () => {
    if (!this.textFieldRef.value){
      toast.error('Please enter name')
      return;
    }
    if (this.textFieldRef.value === 'Favorites' || this.textFieldRef.value === 'Uncatorized'){
      toast.error('Restricted name, please choose another name');
      return;
    }
    const { onAddFavoriteFolder: onAddFavorite } = this.props;
    httpSCW.addFavoriteFolder({ name: this.textFieldRef.value }).then((res) => {
      toast.success('Folder added successfully');
      if (onAddFavorite){
        onAddFavorite(res);
      }
    }).catch((y) => {
      toast.error('Failed to add folder');
    });
  }

  deleteFavorite = () => {
    const { folder, onDeleteFavoriteFolder: onDeleteFavorite } = this.props;
    httpSCW.deleteFavoriteFolder(folder.id).then((res:any) => {
      toast.success('Folder deleted successfully');
      if (onDeleteFavorite){
        onDeleteFavorite(folder);
      }
    }).catch(() => {
      toast.error('Failed to delete folder');
    });
  }

  editFavorite = () => {
    if (!this.textFieldRef.value){
      toast.error('Please enter name')
      return;
    }
    if (this.textFieldRef.value === 'Favorites' || this.textFieldRef.value === 'Uncatorized'){
      toast.error('Restricted name, please choose another name');
      return;
    }
    const { folder, onEditFavoriteFolder: onEditFavorite } = this.props;
    folder.name = this.textFieldRef.value;
    httpSCW.updateFavoriteFolder(folder).then((res:any) => {
      toast.success('Folder edited successfull');
      if (onEditFavorite){
        onEditFavorite(res);
      }
    }).catch((err:any) => {
      toast.error(`Failed to edit folder: ${err.data}`);
    });
  }

  render() {
    const {
      isVisible, isCreate, folder, onClose
    } = this.props;
    return (
      <Popup
        visible={isVisible}
        onHiding={onClose}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={false}
        title={isCreate ? 'Create Favorites' : 'Edit Favorites'}
        height="25%"
        width="20%"
        onContentReady={(e: any) => {
          const toolbar = e.element.querySelector('.dx-toolbar');
          if (toolbar) {
            toolbar.style.paddingBottom = '60px';
          }
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2>
                {isCreate ? 'Create Folder' : 'Folder Settings'}
              </h2>
            </Col>
            <Col>
              <div className="float-right">
                <Button
                  variant="outline"
                  onClick={onClose}
                  className="h40"
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" className="mr10" />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label
                labelTitle="Folder Name"
                labelPosition="top"
                labelSize="80"
                fieldSize="20"
                errorSize="20"
                isFieldTouched
              >
                <Textbox
                  type="text"
                  name="folderName"
                  autoComplete="off"
                  placeholder="Name of folder"
                  setRef={(ref: any) => { this.textFieldRef = ref; }}
                  defaultValue={folder ? folder.name : ''}
                />
              </Label>
            </Col>
          </Row>
          <Row className="mt20 align-bottom">
            <Col>
              <Button
                variant="outline-danger"
                onClick={this.deleteFavorite}
                className="h40"
                hidden={isCreate}
              >
                <FontAwesomeIcon icon={faTrash} size="lg" className="mr10" />
                Delete
              </Button>
            </Col>
            <Col>
              <div className="float-right">
                <Button
                  variant="outline-dark"
                  onClick={this.editFavorite}
                  className="h40"
                  hidden={isCreate}
                >
                  <FontAwesomeIcon icon={faEdit} size="lg" className="mr10" />
                  Update
                </Button>
                <Button
                  variant="outline-dark"
                  onClick={this.createFavorite}
                  className="h40"
                  hidden={!isCreate}
                >
                  <FontAwesomeIcon icon={faPlus} size="lg" className="mr10" />
                  Create
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Popup>
    );
  }
}
export default CreateOrEditFavoriteFolder;
