/* eslint-disable react/prefer-stateless-function */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import React from 'react';
import {
  Button, Modal, Row, Col, Container,
} from 'react-bootstrap';
import {
  faCheck,
  faFileExport, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { RadioGroup } from 'devextreme-react';
import TextBox from 'components/wrapped-component/hint-controls/Textbox';
import {
  ColumnsToExportType, ExportFormatType, IExportDataInitialOptions
} from 'interfaces';
import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';

export interface IDataExportModalState {
  showModal: boolean,
  currentExportFormat: ExportFormatType,
  currentColumnsToExport: ColumnsToExportType,
  currentFileName: string,
  disabled: boolean,
}

export interface IDataExportModalProps {
  viewAs: string;
  gridHeaderLegend: any;
  invokeDataExport: (options: IExportDataInitialOptions)=>void;
  disabled: boolean;
  totalRowCount: number;
}

const PDF_COLUMN_THRESHOLD = 30;

const HTML_ROW_COLUMN_THRESHOLD = 1000000;

class DataExportModal extends React.Component<IDataExportModalProps, IDataExportModalState> {
  private numberOfTotalColumns = 0;

  private numberOfVisibleColumns = 0;

  constructor(props:IDataExportModalProps) {
    super(props);
    this.state = {
      showModal: false,
      currentExportFormat: 'CSV',
      currentColumnsToExport: 'VISIBLE',
      currentFileName: 'exported_data',
      disabled: props.disabled,
    };
  }

  componentDidMount() {
    this.updateColumnsCounts();
  }

  componentDidUpdate(prevProps: IDataExportModalProps) {
    const { gridHeaderLegend, disabled } = this.props;
    if (gridHeaderLegend !== prevProps.gridHeaderLegend){
      this.updateColumnsCounts();
    } else if (disabled !== prevProps.disabled){
      this.setState({ disabled })
    }
  }

  updateColumnsCounts = () => {
    const { gridHeaderLegend } = this.props;
    this.numberOfTotalColumns = gridHeaderLegend.gridColumnStructureDTO.reduce((acc: any, mainColumn: any) => acc + mainColumn.fields.length, 0)
    this.numberOfVisibleColumns = gridHeaderLegend.gridColumnStructureDTO.reduce((acc: any, mainColumn: any) => acc + mainColumn.fields.filter((x: any) => x.selected).length, 0)
  }

  returnOptionsToGrid = async () => {
    const {
      currentExportFormat, currentColumnsToExport, currentFileName
    } = this.state;
    const { invokeDataExport, totalRowCount } = this.props;
    const options: IExportDataInitialOptions = {
      exportFormat: currentExportFormat,
      columnsToExport: currentColumnsToExport,
      fileSaveName: `${currentFileName}.${currentExportFormat.toString().toLowerCase()}`
    }
    const relevantColumnCount = currentColumnsToExport === 'ALL' ? this.numberOfTotalColumns : this.numberOfVisibleColumns;
    const htmlThresholdCheckCount = totalRowCount * relevantColumnCount;
    if (currentExportFormat === 'PDF' && relevantColumnCount > PDF_COLUMN_THRESHOLD) {
      if (await ModalPopup.confirm({
        header: 'Warning',
        body: `The number of selected columns (${relevantColumnCount}) are too high and the generated PDF might not have a readable output and/or might have a horizontally clipped output. 
      Are you sure you want to proceed?`,
      })) {
        this.setState({ showModal: false });
        invokeDataExport(options);
      }
    } else if (currentExportFormat === 'HTML' && htmlThresholdCheckCount > HTML_ROW_COLUMN_THRESHOLD) {
      if (await ModalPopup.confirm({
        header: 'Warning',
        body: `The amount of data selected may lead to the viewed HTML file to load slowly on your browser, or cause the browser to crash
      Are you sure you want to proceed?`,
      })) {
        this.setState({ showModal: false });
        invokeDataExport(options);
      }
    } else {
      this.setState({ showModal: false });
      invokeDataExport(options);
    }
  }

  render() {
    const {
      showModal, currentExportFormat, currentColumnsToExport, currentFileName, disabled
    } = this.state;
    const { viewAs } = this.props;
    return (viewAs === ReportType.RAW_DATA_GRID) ? (
      <>
        <Button
          className={`btn btn-clear ${showModal ? 'border-all' : 'btn-config'}`}
          variant={showModal ? 'success' : 'clear'}
          onClick={() => { this.setState({ showModal: !showModal }) }}
          disabled={disabled}
        >
          <FontAwesomeIcon
            className="mr10"
            icon={faFileExport}
          />
          Export Data
        </Button>
        <Modal dialogClassName="data-export-modal" show={showModal} size="lg" scrollable>
          <Modal.Header>
            <Modal.Title as="h3">Data Export Options</Modal.Title>
            <span className="float-right">
              <Button
                variant="secondary"
                onClick={() => this.setState({ showModal: false })}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faTimes}
                />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="w-100 h-100">
            <Container fluid className="border-all pl10 pr10 pt10 pb10 background-color-light border-radius-4" style={{ overflow: 'visible', minHeight: '200px' }}>
              <Row className="d-flex mb20">
                <Col><h6 className="ml5 mt7 mr10">Export Format</h6></Col>
                <Col>
                  <CustomizedDropdown
                    variant="light"
                    full
                    list={[['CSV', 'CSV'],
                      ['XLSX', 'XLSX'],
                      ['PDF', 'PDF'],
                      ['HTML', 'HTML']]}
                    dropdownButtonClass="border-all"
                    selectedValue={currentExportFormat}
                    onChange={(value: string) => {
                      this.setState({ currentExportFormat: value as ExportFormatType });
                    }}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
              <Row className="d-flex mb20">
                <Col><h6 className="ml5 mt7 mr10">Columns to Export</h6></Col>
                <Col>
                  <RadioGroup
                    items={['VISIBLE', 'ALL']}
                    defaultValue={currentColumnsToExport}
                    onValueChanged={(e: any) => {
                      this.setState({ currentColumnsToExport: e.value as ColumnsToExportType })
                    }}
                    layout="horizontal"
                  />
                </Col>
                <Col />
                <Col />
              </Row>
              <Row className="d-flex mb20">
                <Col><h6 className="ml5 mt7 mr10">File Name</h6></Col>
                <Col>
                  <TextBox
                    hint={{ message: 'Enter the name with which your file will be saved in your Downloads folder' }}
                    name="exported file name"
                    key="exported file name"
                    value={currentFileName}
                    onChange={(e: any) => {
                      this.setState({ currentFileName: e.target.value });
                    }}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
              <Row>
                <Col />
                <Col />
                <Col>
                  <Button
                    className="float-right mr20"
                    variant="success"
                    size="lg"
                    onClick={this.returnOptionsToGrid}
                  >
                    <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
                    Export
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    ) : null;
  }
}

export default DataExportModal;
