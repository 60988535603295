import React, { Component } from 'react';
// eslint-disable-next-line no-unused-vars
import Plotly, { PlotType } from 'plotly.js';
// eslint-disable-next-line import/no-unresolved
import createPlotlyComponent from 'react-plotly.js/factory';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import ContextMenu from 'devextreme-react/context-menu';
// eslint-disable-next-line no-unused-vars
import { dxContextMenuItem } from 'devextreme/ui/context_menu';
// eslint-disable-next-line no-unused-vars
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
// eslint-disable-next-line no-unused-vars

import './CustomPlotly.css';

type CustomPlotlyProps = {
  dataSource: { x: any[], y: number[] }[],
  singlePointGraphSelection: (event: any) => void,
  onContextMenuItemClick?: (event: any) => void,
  onContextMenuShowing?: (event: any) => void,
  onContextMenuHidden?: (event: any) => void,
  graphSelection: (event: any) => void,
  selectionModeInfo?: {
    onToggleSelectionModeHandler: () => void,
    singleSelection: boolean
  },
  title?: string,
  colorList: string[],
  enableYAxisBreaks?: boolean,
  hideOptions?: boolean,
  isMultiCategory?: boolean,
  hideLegend?: boolean,
  disableXAxisBreaks?: boolean,
  type: PlotType,
  contextMenuDataSource?: string | Array<dxContextMenuItem> | DataSource | DataSourceOptions,
  id: string,
  mode:
  | 'lines'
  | 'markers'
  | 'text'
  | 'lines+markers'
  | 'text+markers'
  | 'text+lines'
  | 'text+lines+markers'
  | 'none'
  | 'gauge'
  | 'number'
  | 'delta'
  | 'number+delta'
  | 'gauge+number'
  | 'gauge+number+delta'
  | 'gauge+delta',
  layout?:any,
  toggleEditor?:any,
};

type CustomPlotlyState = {
  yAxisBreak: boolean,
  xAxisBreak: boolean,
  tickValsY: null | [],
  tickTextY: null | [],
  tickValsX: null | [],
  tickTextX: null | [],
  y: number[][],
  x: any[][],
  axisBreakLine: any[],
  breakPoint: [],
  isLoading: boolean,
};

export class CustomPlotly extends Component<CustomPlotlyProps, CustomPlotlyState> {
  private updateCount = 0;

  static defaultProps = {
    enableYAxisBreaks: false,
    hideOptions: false,
    hideLegend: false,
    disableXAxisBreaks: false,
    isMultiCategory: false,
  };

  contextMenu: ContextMenu | null = null;

  constructor(props: CustomPlotlyProps) {
    super(props);
    const { dataSource } = this.props;
    this.state = {
      yAxisBreak: false,
      xAxisBreak: false,
      tickValsY: [],
      tickTextY: [],
      tickValsX: [],
      tickTextX: [],
      y: dataSource.map((data) => data.y),
      x: dataSource.map((data) => data.x),
      axisBreakLine: [],
      // eslint-disable-next-line react/no-unused-state
      breakPoint: [],
      // eslint-disable-next-line react/no-unused-state
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: CustomPlotlyProps) {
    const { enableYAxisBreaks, dataSource } = this.props;
    if (prevProps.dataSource !== dataSource && this.updateCount !== -1) {
      this.updateCount = -1;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        x: dataSource.map((data) => data.x),
        y: dataSource.map((data) => data.y),
        axisBreakLine: [],
      });
    }
    if (enableYAxisBreaks && this.updateCount === 0 && dataSource.length !== 0) {
      const { y } = this.state;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        yAxisBreak: true,
        // eslint-disable-next-line react/no-unused-state
        isLoading: true,
      });
      this.applyAxisBreakWithMulticategoryAxis(y);
    }
    this.updateCount += 1;
  }

  changeBreaksEnabledState = (e: any, axis: string) => {
    const { x, y, axisBreakLine } = this.state;
    const { dataSource } = this.props;
    if (dataSource.length === 0) return;
    if (axis === 'y') {
      if (e.value) {
        this.setState({
          yAxisBreak: e.value,
          // eslint-disable-next-line react/no-unused-state
          isLoading: true,
        });
        this.applyAxisBreakWithMulticategoryAxis(y);
      } else {
        const remainingAxisBreak = axisBreakLine.filter(
          (axisBreak) => axisBreak.key === 'x',
        );
        this.setState({
          y: dataSource.map((data) => data.y),
          axisBreakLine: remainingAxisBreak,
          // eslint-disable-next-line react/no-unused-state
          breakPoint: [],
          yAxisBreak: false,
          // eslint-disable-next-line react/no-unused-state
          isLoading: false,
        });
      }
    } else if (axis === 'x') {
      if (e.value) {
        this.setState({
          xAxisBreak: e.value,
          // eslint-disable-next-line react/no-unused-state
          isLoading: true,
        });
        this.applyAxisBreak(x[0], axis);
      } else {
        const remainingAxisBreak = axisBreakLine.filter(
          (axisBreak) => axisBreak.key === 'y',
        );
        this.setState({
          x: dataSource.map((data) => data.x),
          axisBreakLine: remainingAxisBreak,
          // eslint-disable-next-line react/no-unused-state
          breakPoint: [],
          xAxisBreak: false,
          // eslint-disable-next-line react/no-unused-state
          isLoading: false,
        });
      }
    }
  };

  returnActualTickText = (tickValue: number, breakPoint: any) => {
    const n = breakPoint.length - 1;
    if (tickValue >= breakPoint[n].breakAt) {
      return tickValue + breakPoint[n].subtractor;
    }
    let i;
    for (i = n - 1; i >= 0; i -= 1) {
      if (tickValue >= breakPoint[i].breakAt) {
        return tickValue + breakPoint[i].subtractor;
      }
    }
    return tickValue;
  };

  roundTest = (v: number, tickDelta: number) => Math.round(v / tickDelta) * tickDelta;

  roundBelow1 = (v: number) => Number(v.toFixed(2));

  roundS = (v: number) => {
    if (v < 1) {
      const y = 10 ** Math.ceil(Math.log10(v));
      return Math.ceil(v / y) * y;
    }
    const y = 10 ** Math.floor(Math.log10(v));
    return Math.ceil(v / y) * y;
  };

  kFormatter = (num: number) => (Math.abs(num) > 999
    ? `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}k`
    : Math.sign(num) * Math.abs(num));

  /**
   * @description Function sorts points in asscending order.
   * @param {*List of points} points
   * @returns List of Sorted Points
   */
  sortPointsAscending = ([...points]) => {
    // eslint-disable-next-line no-nested-ternary
    points.sort((x, y) => (x.val > y.val ? 1 : x.val === y.val ? 0 : -1));
    return points;
  };

  /**
   * @description Function sets the value of tickVals and tickTexts to default.
   * @param {List of Sorted data points} sortedDataSoure
   * @param {*String of axis flag (Y or X axis)} axis
   * @returns nothing
   */
  noBreakPointFound = (axis: string) => {
    if (axis === 'y') {
      this.setState({
        tickValsY: null,
        tickTextY: null,
        axisBreakLine: [],
      });
    } else if (axis === 'x') {
      this.setState({
        tickValsX: null,
        tickTextX: null,
        axisBreakLine: [],
      });
    }
  };

  /**
   * @description Function reduces the sorted points in to small points.
   * @param {*List of sorted data points} sortedDataSource
   * @returns Average distance till first Break Point, List of reduced points,
   * boolean of axis Break found and list of Axis Break.
   */
  reduceSortedPoints = (sortedDataSource: any) => {
    let sum = 0;
    let subtractor = 0;
    let avgDistanceTillFirstbreakPoint = 0;
    let axisBreakFound = false;
    const breakPoint = [];
    for (let i = 0; i < sortedDataSource.length - 1; i += 1) {
      let delta = 0;

      // --------------
      if (axisBreakFound === false) {
        sum += sortedDataSource[i];
        delta = (sortedDataSource[i + 1] - sortedDataSource[i]) / sortedDataSource[i]; // increase in percentage...
        avgDistanceTillFirstbreakPoint = sum / (i + 1);
      } else {
        const newValue = sortedDataSource[i + 1] - subtractor;
        delta = (newValue - sortedDataSource[i]) / sortedDataSource[i];
      }
      //----------------
      if (delta >= 2.5 && axisBreakFound === false) {
        axisBreakFound = true;
        const newValue = avgDistanceTillFirstbreakPoint + sortedDataSource[i]; // calculate
        subtractor = sortedDataSource[i + 1] - newValue;
        // eslint-disable-next-line no-param-reassign
        sortedDataSource[i + 1] = newValue; // update the new value
        breakPoint.push({
          index: i,
          breakAt: sortedDataSource[i],
          subtractor,
        });
      } else if (delta >= 1.4 && axisBreakFound === true) {
        axisBreakFound = true;
        const newValue = avgDistanceTillFirstbreakPoint + sortedDataSource[i];
        subtractor = sortedDataSource[i + 1] - newValue;
        // eslint-disable-next-line no-param-reassign
        sortedDataSource[i + 1] = newValue;
        breakPoint.push({
          index: i,
          breakAt: sortedDataSource[i],
          subtractor,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        sortedDataSource[i + 1] = sortedDataSource[i + 1] - subtractor;
      }
    }

    return [
      avgDistanceTillFirstbreakPoint,
      sortedDataSource,
      axisBreakFound,
      breakPoint,
    ];
  };

  /**
   * @description Function calculates Tick Delta. Tick Delta is a difference between two ticks.
   * @param {*Float value of average distance till the first Break Point} avgDistanceTillFirstbreakPoint
   * @returns Tick Delta
   */
  calculateTickDelta = (avgDistanceTillFirstbreakPoint: number) => {
    let tickDelta = avgDistanceTillFirstbreakPoint;
    tickDelta = this.roundS(tickDelta);
    return tickDelta;
  };

  /**
   * @description Function adds gap for axis break in data points so graph shows a full tick for axis break
   * @param {*List of data points} sortedDataSoure
   * @param {*Float Value of Tick Delta} tickDelta
   * @param {*List of Break Points in graph} breakPoint
   * @returns list of data points with gap in it for axis break
   */
  addingGapForAxisBreakInData = (sortedDataSoure: any, tickDelta: number, breakPoint: any) => {
    let i;
    let dMultiplier = 1;
    for (i = 0; i < breakPoint.length; i += 1) {
      let startIndex;
      let endIndex;
      if (i === breakPoint.length - 1) {
        startIndex = breakPoint[i].index;
        endIndex = sortedDataSoure.length - 1;
      } else {
        startIndex = breakPoint[i].index;
        endIndex = breakPoint[i + 1].index;
      }
      for (let j = startIndex + 1; j <= endIndex; j += 1) {
        // eslint-disable-next-line no-param-reassign
        sortedDataSoure[j] += dMultiplier * tickDelta;
      }
      dMultiplier += 1;
    }
    return sortedDataSoure;
  };

  /**
   * @description Function adds gap for axis break in Break Point list as well.
   * @param {*Float value of tick Delta} tickDelta
   * @param {*List of breakPoints} breakPoint
   * @returns list of updated break Point
   */
  addingGapForAxisBreakInBreakPoint = (tickDelta: number, breakPoint: any) => {
    for (let i = 0; i < breakPoint.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      breakPoint[i].breakAt += (i + 1) * tickDelta;
      // eslint-disable-next-line no-param-reassign
      breakPoint[i].subtractor -= (i + 1) * tickDelta;
    }
    return breakPoint;
  };

  /**
   * @description Function calculates Tick Positions
   * @param {*List of data points} sortedDataSoure
   * @param {*Integer value of tick Delta} tickDelta
   * @returns list of Tick Positions
   */
  calculateTickPositions = (sortedDataSoure: any, tickDelta: number) => {
    let i = 0;
    let tickValue = 0;
    const tickPositions = [];
    const newMax = sortedDataSoure[sortedDataSoure.length - 1];
    while (tickValue < newMax) {
      tickValue = tickDelta * i;
      tickPositions.push(Number(tickValue.toFixed(1)));
      i += 1;
    }
    return tickPositions;
  };

  /**
   * @description Function calculates Tick Texts for both axis
   * @param {*List of Tick Positions} tickPositions
   * @param {*List of break Point} breakPoint
   * @returns the list of Tick Texts.
   */
  calculateTickTexts = (tickPositions: any, breakPoint: any) => {
    return tickPositions.map((tickValue: any) => this.returnActualTickText(tickValue, breakPoint));
  };

  /**
   * @description Function calculates Axis Break Lines
   * @param {*List of Tick Texts} tickTexts
   * @param {*List of Tick Positions} tickPositions
   * @param {*Integer value of Tick Delta} tickDelta
   * @param {*String axis flag x or y} axis
   * @returns List of axis break Lines containing objects representing axis break lines on graph.
   */
  calculateAxisBreakLines = (tickTexts: any, tickPositions: any, tickDelta: number, axis: any) => {
    const axisBreakLine = [];
    for (let i = 0; i < tickTexts.length - 1; i += 1) {
      const diff = tickTexts[i + 1] - tickTexts[i];
      if (Number(diff.toFixed(1)) > tickDelta) {
        const diff1 = tickPositions[i + 1] - tickPositions[i];
        axisBreakLine.push(tickPositions[i] + diff1 / 2);
      }
    }
    const renderAxisBreakLine = [];
    if (axis === 'y') {
      for (let i = 0; i < axisBreakLine.length; i += 1) {
        renderAxisBreakLine.push({
          key: 'y',
          type: 'line',
          xref: 'paper',
          x0: 0,
          x1: 1,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'grey',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'y',
          type: 'line',
          xref: 'paper',
          x0: 0,
          x1: 1,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    } else if (axis === 'x') {
      for (let i = 0; i < axisBreakLine.length; i += 1) {
        renderAxisBreakLine.push({
          key: 'x',
          type: 'line',
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'grey',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'x',
          type: 'line',
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    }
    return renderAxisBreakLine;
  };

  formatTickTexts = (tickTexts: any) => {
    return tickTexts.map((ele: any) => this.kFormatter(ele).toString());
  };

  /**
   * @description Function updates Tick Positions and Tick Texts for cosmetic improvements by rounding off the values.
   * @param {*List of Tick Texts} tickTexts
   * @param {*List of Tick Positions} tickPositions
   * @param {*Integer Value of Tick Delta} tickDelta
   * @returns list of updated Tick Texts and Tick Positions
   */
  cosmeticAdjustments = (tickTexts: any, tickPositions: any, tickDelta: any) => {
    tickTexts.forEach((ele: any, index: number) => {
      let updatedTickText;
      if (tickDelta < 1) {
        updatedTickText = this.roundBelow1(ele);
      } else {
        updatedTickText = this.roundTest(ele, tickDelta);
      }
      const diff = updatedTickText - tickTexts[index];
      // eslint-disable-next-line no-param-reassign
      tickPositions[index] = Number((tickPositions[index] + diff).toFixed(3));
      // eslint-disable-next-line no-param-reassign
      tickTexts[index] = updatedTickText;
    });
    return [tickTexts, tickPositions];
  };

  /**
   * @description Function sets the state of the compponent and renders the aix break.
   * @param {*List of data points} sortedDataSoure
   * @param {*List of tick Positions} tickPositions
   * @param {*List of tick Texts} tickTexts
   * @param {*List of Axis Break Line} axisBreakLine
   * @param {*String flag of axis (Either x or y)} axis
   * @returns nothing
   */
  renderAxisBreaks = (
    orderedDataPoints: any,
    tickPositions: any,
    tickTexts: any,
    axisBreakLine: any,
  ) => {
    const { dataSource } = this.props;
    this.setState((prevState) => {
      return {
        x: dataSource.map(() => orderedDataPoints),
        tickValsX: tickPositions,
        tickTextX: tickTexts,
        axisBreakLine: [...axisBreakLine, ...prevState.axisBreakLine],
        isLoading: false,
      };
    });
  };

  /**
   * @description Function reorderes the sorted reduced data points to the original indices
   * @param {*Map of indices of sorted redueced data} sortedDataMap
   * @param {*List of sorted data elements} sortedDataSource
   * @returns list of data points move to their orginal indices.
   */
  reorderSortedDataPoints = (sortedDataMap: any, sortedDataSource: any) => {
    const unsortedArray = Array.from({
      length: sortedDataSource.length,
    });
    const originalOrder = sortedDataMap.map((ele: any) => ele.ind);
    originalOrder.forEach((ele: any, index: number) => {
      unsortedArray[ele] = sortedDataSource[index];
    });

    return unsortedArray;
  };

  /**
   * @description Function creates a map of original data points indices for future reordering.
   * @param {*List od orignal data points} points
   * @returns a list of object with original index and value
   */
  createOriginalIndicesMap = (points: any) => {
    return points.map((e: any, i: number) => ({ ind: i, val: e }));
  };

  /**
   * @description Function fetches only the elements which are going to be plotted from  the list of objects
   * @param {List of sorted data points object} sortedDataMap
   * @returns a list of to be plotted elements.
   */
  fetchElementsFromSortedMap = (sortedDataMap: any) => sortedDataMap.map((ele: any) => ele.val);

  applyAxisBreakWithMulticategoryAxis = (points2D: any) => {
    const flattenedPoints = [].concat(...points2D);
    const retval: any = this.applyAxisBreak(flattenedPoints, 'y');
    if (!retval) return;
    const newOrderedPoints: any = [];
    for (let i = 0; i < points2D.length; i += 1) {
      newOrderedPoints.push(retval.orderedDataPoints.splice(0, points2D[i].length));
    }
    const { dataSource } = this.props;
    this.setState((prevState) => {
      return {
        y: dataSource.map((el, i) => newOrderedPoints[i]),
        tickValsY: retval.tickPositions,
        tickTextY: retval.tickTexts,
        axisBreakLine: [...retval.axisBreakLine, ...prevState.axisBreakLine],
        isLoading: false,
      };
    });
  };

  /**
   * @description Function calculates axis breaks on x and y axis depending on the provided axis flag.
   * @param {*Array of points (X or Y Axis)} data
   * @param {*String Axis flag either "x" or "y"} axis
   * @returns { list of reduced Data points, Tick Positions, Tick Texts and Axis Break Lines.}
   */
  applyAxisBreak = (points: any, axis: string) => {
    // Variable Intialization
    let tickPositions = [];
    let tickTexts = [];
    let sortedDataSoure = [];
    let avgDistanceTillFirstbreakPoint = 0;
    let tickDelta = 0;
    let axisBreakLine = [];
    let axisBreakFound;
    let breakPoint = [];
    let originalDataMap = [];
    let sortedDataMap = [];
    let orderedDataPoints = [];

    // Make Map of Original Data
    originalDataMap = this.createOriginalIndicesMap(points);
    // Sorting data in Assc. Order
    sortedDataMap = this.sortPointsAscending(originalDataMap);

    // Fetching elements from SortedDataMap
    sortedDataSoure = this.fetchElementsFromSortedMap(sortedDataMap);

    // Updating sorted data and finding breakpoints
    [
      avgDistanceTillFirstbreakPoint,
      sortedDataSoure,
      // eslint-disable-next-line prefer-const
      axisBreakFound,
      breakPoint,
    ] = this.reduceSortedPoints(sortedDataSoure);
    // If there is no breakpoint return the function
    if (axisBreakFound === false) {
      this.noBreakPointFound(axis);
      return null;
    }

    // calculate Tick Detla
    tickDelta = this.calculateTickDelta(avgDistanceTillFirstbreakPoint);
    // Adding gap for axis break in Data
    sortedDataSoure = this.addingGapForAxisBreakInData(
      sortedDataSoure,
      tickDelta,
      breakPoint,
    );
    // Adding gap for axis break in BreakPoint
    breakPoint = this.addingGapForAxisBreakInBreakPoint(tickDelta, breakPoint);
    // Calculating Tick Positions
    tickPositions = this.calculateTickPositions(sortedDataSoure, tickDelta);

    // Calculating Tick Texts
    tickTexts = this.calculateTickTexts(tickPositions, breakPoint);

    // Cosmetic Adjusment in Tick Texts and Tick Positions
    [tickTexts, tickPositions] = this.cosmeticAdjustments(
      tickTexts,
      tickPositions,
      tickDelta,
    );
    // Calculating Axis Break Line
    axisBreakLine = this.calculateAxisBreakLines(
      tickTexts,
      tickPositions,
      tickDelta,
      axis,
    );
    // Formating Tick Texts (Optional)
    // tickTexts = this.formatTickTexts(tickTexts);

    // Reorder Elements to their original indices
    orderedDataPoints = this.reorderSortedDataPoints(
      sortedDataMap,
      sortedDataSoure,
    );

    if (axis === 'x') {
      // Render Axis Break on Screen
      this.renderAxisBreaks(
        orderedDataPoints,
        tickPositions,
        tickTexts,
        axisBreakLine,
      );
      return null;
    }

    return {
      orderedDataPoints,
      tickPositions,
      tickTexts,
      axisBreakLine,
      axis,
    };
  };

  render() {
    const Plot = createPlotlyComponent(Plotly);
    const {
      type, mode, colorList, id, isMultiCategory, onContextMenuHidden, onContextMenuItemClick, onContextMenuShowing, selectionModeInfo,
      singlePointGraphSelection, graphSelection, dataSource, hideOptions, hideLegend, disableXAxisBreaks, contextMenuDataSource,
    } = this.props;
    const {
      yAxisBreak, xAxisBreak, tickValsY, tickValsX, tickTextY, tickTextX, axisBreakLine, x, y,
    } = this.state;
    const data = [];
    const yOrignal = dataSource.map((dat) => dat.y);
    const localColorList = [...colorList];
    for (let i = 0; i < dataSource.length && y[i]; i += 1) {
      data.push({
        type: eval(`dataSource[${i}].type`) === undefined ? type : eval(`dataSource[${i}].type`),
        mode: eval(`dataSource[${i}].mode`) === undefined ? type : eval(`dataSource[${i}].mode`),
        y: y[i],
        x: x[i],
        name: isMultiCategory ? x[i][0][0] : '',
        hovertemplate: '<b>%{text}</b>',
        text: yOrignal[i].map((yVal) => yVal.toString()),
        width: 0.8,
        marker: {
          size: 5,
          color: colorList && !isMultiCategory ? localColorList : localColorList.splice(0, y[i].length),
        },
      });
    }
    const modeBarButtonsToAdd = [];
    if (selectionModeInfo) {
      modeBarButtonsToAdd.push({
        title: 'Single Selection Mode',
        name: 'Single Selection Mode',
        toggle: selectionModeInfo.singleSelection,
        icon: {
          width: 500,
          height: 600,
          // eslint-disable-next-line max-len
          path: 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z',
        },
        // eslint-disable-next-line no-unused-vars
        click: (gd: any) => {
          selectionModeInfo.onToggleSelectionModeHandler();
        },
      });
    }
    modeBarButtonsToAdd.push({
      title: 'Show/Hide Designer',
      name: 'Chart Designer',
      toggle: xAxisBreak,
      icon: {
        width: 500,
        height: 600,
        // eslint-disable-next-line max-len
        path: 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z',
      },
      // eslint-disable-next-line no-unused-vars
      click: (gd: any) => {
        if (this.props.toggleEditor !== undefined) { this.props.toggleEditor(); }
      },
    });
    if (!hideOptions) {
      modeBarButtonsToAdd.push({
        title: 'YAxis Break',
        name: 'YAxis Break',
        toggle: yAxisBreak,
        icon: {
          width: 500,
          height: 600,
          // eslint-disable-next-line max-len
          path: 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z',
        },
        // eslint-disable-next-line no-unused-vars
        click: (gd: any) => {
          this.changeBreaksEnabledState({ value: !yAxisBreak }, 'y');
        },
      });
      if (!disableXAxisBreaks) {
        modeBarButtonsToAdd.push({
          title: 'XAxis Break',
          name: 'XAxis Break',
          toggle: xAxisBreak,
          icon: {
            width: 500,
            height: 600,
            // eslint-disable-next-line max-len
            path: 'M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z',
          },
          // eslint-disable-next-line no-unused-vars
          click: (gd: any) => {
            this.changeBreaksEnabledState({ value: !xAxisBreak }, 'x');
          },
        });
      }
    }
    const { layout } = this.props;
    if (layout !== undefined && layout.yaxis !== undefined) {
      layout.yaxis.tickvals = yAxisBreak
        ? tickValsY!
        : undefined;
      layout.yaxis.ticktext = yAxisBreak
        ? tickTextY!
        : undefined;

      layout.xaxis.tickvals = xAxisBreak
        ? tickValsX!
        : undefined;
      layout.xaxis.ticktext = xAxisBreak
        ? tickTextX!
        : undefined;
      layout.shapes = axisBreakLine;
    }
    return (
      <Container fluid>
        <Row>
          <Col lg={12} className="p0">
            <Plot
              divId={id}
              onClick={singlePointGraphSelection}
              onSelected={graphSelection}
              useResizeHandler
              style={{ width: '80%', height: '100%' }}
              config={{
                responsive: true,
                displaylogo: false,
                modeBarButtonsToAdd,
                displayModeBar: true,
                modeBarButtonsToRemove: ['hoverClosestCartesian', 'hoverCompareCartesian', 'toggleSpikelines'],
              }}
              layout={this.props.layout}

              // !== undefined ? this.props.layout : {
              //   dragmode: 'select',
              //   legend: {
              //     x: 1,
              //     y: 1,
              //   },
              //   margin: {
              //     t: 30,
              //     r: 10,
              //   },
              //   showlegend: !hideLegend,
              //   autosize: true,
              //   yaxis: {
              //     tickvals: yAxisBreak
              //       ? tickValsY!
              //       : undefined,
              //     ticktext: yAxisBreak
              //       ? tickTextY!
              //       : undefined,
              //   },
              //   xaxis: {
              //     tickfont: { size: 10 },
              //     tickvals: xAxisBreak
              //       ? tickValsX!
              //       : undefined,
              //     ticktext: xAxisBreak
              //       ? tickTextX!
              //       : undefined,
              //   },
              //   shapes: axisBreakLine,
              // }}
              data={data}
            />
          </Col>
          <ContextMenu
            activeStateEnabled
            cssClass="black"
            dataSource={contextMenuDataSource}
            target={`#${id}`}
            width={200}
            onItemClick={onContextMenuItemClick || (() => undefined)}
            onShowing={onContextMenuShowing || (() => undefined)}
            onHidden={onContextMenuHidden || (() => undefined)}
          />
        </Row>
      </Container>
    );
  }
}

export default CustomPlotly;
