interface IOption {
  [key: string]: string | number;
}
/**
  * @remarks
  * This function is used to get the options for the dxDropDownBox, dxSelectBox and dxTagBox component.
  * It is used to set the container and hideOnParentScroll options. Other options can be passed in as well.
  * @param containerType (Required): to determine if the dropdown is being used in a dxDataGrid "cell" or independetly in a "div".
  * @param container (Optional, default: undefined): to pass in the dxDataGrid Cell component for if the dropdown is in editCellRender
  * and dxDataGrid data prop for if the dropdown is in editCellComponent. This is only required if the containerType is 'cell'.
  * @param options (Optional, default: undefined): to pass in a list of options that need to be set for the dxDropDownBox component.
  * This is only required if there are other options that need to be set.
  * @example dropDownOptions('div')
  * @example dropDownOptions('cell', data)
  * @example dropDownOptions('cell', cell, [{ width: 200}, {height: 200 }])
  * @example dropDownOptions('div', undefined, [{ width: 200}, {height: 200 }])
  * @returns An object with the options for the dxDropDownBox component.
*/
const getDropDownOptions = (containerType : 'cell' | 'div', container?: any, options?: IOption[]) => {
  const compiledOptions = {
    onShowing: (e: any) => {
      e.component.option({
        container: containerType === 'div' ? e.element : container.component.element().querySelector('.dx-gridbase-container'),
        hideOnParentScroll: false
      });
    }
  }
  if (options) {
    options.forEach((option: IOption) => {
      Object.assign(compiledOptions, option);
    });
  }
  return compiledOptions;
}

export default getDropDownOptions;
