import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ScrollView from 'devextreme-react/scroll-view';
import LoaderPolicyBlock from '../../components/policy/loader-policy-block/LoaderPolicyBlock';

const CreateLoaderPolicy = (props:any) => {
  const { className } = props;
  const location = useLocation();
  let data : any;
  if (location.state !== null && location.state !== undefined) {
    data = location.state;
  }

  return (
    <ScrollView
      showScrollbar="always"
      scrollByThumb
      reachBottomText=""
    >
      <Container fluid className={`${className}`}>
        <Row>
          <Col lg={12} md={12}>
            <LoaderPolicyBlock id={data !== undefined ? data.Id : ''} />
          </Col>
        </Row>
      </Container>
    </ScrollView>
  );
};

CreateLoaderPolicy.defaultProps = {
  className: '',
};

export default CreateLoaderPolicy;
