import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
  Accordion,
} from "react-bootstrap";
import { ScrollView } from "devextreme-react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralUtils from "GeneralUtils";
import logo from "../../../assets/icons/logo.svg";
import avatar from "../../../assets/images/avatar.svg";
import Avatar from "../../template-component/avatar/Avatar";
import SidebarNavItem from "./sidebar-nav-item/SidebarNavItem";
import AppConstants from "../../../constants.js";

import "./sidebar-nav.scss";

import routes from "../../../routes";
import { IDatabase, IUserDetails } from "interfaces";
import { toast } from "react-toastify";
import { httpIAM } from "services/http.IAM";
import userDataUtil from "services/user-data-util";
import { jwtDecode } from "jwt-decode";

const SidebarNav = (props: any) => {
  const [reloading, setReloading] = useState(true);
  useEffect(() => {}, [reloading]);

  const history = useHistory();
  const location = useLocation();

  const databaseData: any = (index: any) => {
    const userDetail = GeneralUtils.getCookieValue("USER_DETAILS");

    if (userDetail) {
      let data = JSON.parse(userDetail)
        ?.databases?.map((x: IDatabase) => [x.id, x.name, x.type])
        .find((x: any) => x[0] === GeneralUtils.getCookieValue("DATABASE"));

      const returnData = data[index];

      return returnData;
    } else {
      return new Promise<void>((resolve, reject) => {
        const authToken = GeneralUtils.getCookieValue("AUTH_TOKEN");

        if (authToken) {
          let token = jwtDecode(authToken);
          const { UserName } = token as { UserName: string };
          if (authToken !== null) {
            GeneralUtils.appendCookie("INIT_USER_STATS", "");
            const d1 = new Date();
            const d2 = new Date(d1);
            d2.setMinutes(d1.getMinutes() + 2);

            GeneralUtils.appendCookie("AUTH_TOKEN", authToken);
            httpIAM
              .getUserDetails(UserName)
              .then((user: IUserDetails) => {
                GeneralUtils.appendCookie("USER_DETAILS", JSON.stringify(user));
                const previousDatabase =
                  GeneralUtils.getCookieValue("DATABASE");
                if (
                  user !== undefined &&
                  user.databases?.length > 0 &&
                  (previousDatabase === "" ||
                    user.databases.find((x) => x.id === previousDatabase) ===
                      undefined)
                ) {
                  GeneralUtils.appendCookie("DATABASE", user.databases[0].id);
                }
                userDataUtil.initializeUserStats();
                resolve();
                setReloading((prev) => !prev);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } else {
          resolve(); // Resolve immediately if no auth token
          setReloading((prev) => !prev);
        }
      });
    }
  };

  const sidebarNavItems = () =>
    routes
      .getRoutes()
      ?.filter((item) => item.visibleInSidebar)
      ?.map((item) => {
        if (item.title?.includes("Database")) {
          // eslint-disable-next-line no-param-reassign
          item.title = `Database\n(${databaseData(1)} : ${databaseData(2)})`;
        }
        return (
          <React.Fragment key={item.title}>
            {item.subRoutes ? (
              <Accordion defaultActiveKey="0">
                <Accordion.Toggle
                  as={Button}
                  variant=""
                  eventKey="0"
                  className="p0 ml0 w-100"
                >
                  <OverlayTrigger
                    key={`sidebar_overlay_${item.title}`}
                    placement="right"
                    overlay={
                      <Tooltip
                        id={`tooltip_${item.title}`}
                        className={
                          (showMenuLabel || "").toString() === "true"
                            ? "d-none"
                            : "d-block"
                        }
                        style={{ marginLeft: "-170px" }}
                      >
                        <span className="color-light-hover">
                          <span className="color-light color-light-hover">
                            {item.title}
                          </span>
                        </span>
                      </Tooltip>
                    }
                  >
                    <SidebarNavItem
                      // className={props.showMenuLabel ? '' : 'd-none'}
                      keys={`navitem_${item.title}`}
                      selected={false}
                      icon={item.icon}
                      title={item.title}
                      showMenuLabel={props.showMenuLabel}
                      showExpandIcon
                    />
                  </OverlayTrigger>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <>
                    {item.subRoutes.map((subItem) => (
                      <OverlayTrigger
                        key={`sidebar_overlay_${subItem.title}`}
                        placement="right"
                        overlay={
                          <Tooltip
                            id={`tooltip_${subItem.title}`}
                            className={
                              (showMenuLabel || "").toString() === "true"
                                ? "d-none"
                                : "d-block"
                            }
                            style={{ marginLeft: "-170px" }}
                          >
                            <span className="color-light-hover">
                              <span className="color-light color-light-hover">
                                {subItem.title}
                              </span>
                            </span>
                          </Tooltip>
                        }
                      >
                        <Link
                          to={subItem.path}
                          key={`sub_link_${subItem.title}`}
                        >
                          <SidebarNavItem
                            className={
                              props.showMenuLabel ? "pl70 ml1 mt-1" : ""
                            }
                            keys={`navitem_${subItem.title}`}
                            selected={subItem.path === location.pathname}
                            icon={subItem.icon}
                            title={subItem.title}
                            showMenuLabel={props.showMenuLabel}
                            isChild
                          />
                        </Link>
                      </OverlayTrigger>
                    ))}
                  </>
                </Accordion.Collapse>
              </Accordion>
            ) : (
              <OverlayTrigger
                key={`sidebar_overlay_${item.title}`}
                placement="right"
                overlay={
                  <Tooltip
                    id={`tooltip_${item.title}`}
                    className={
                      (showMenuLabel || "").toString() === "true"
                        ? "d-none"
                        : "d-block"
                    }
                    style={{ marginLeft: "-170px" }}
                  >
                    <span className="color-light-hover">
                      <span className="color-light color-light-hover">
                        {item.title}
                      </span>
                    </span>
                  </Tooltip>
                }
              >
                <Link to={item.path} key={`link_${item.title}`}>
                  <SidebarNavItem
                    keys={`navitem_${item.title}`}
                    selected={item.path === location.pathname}
                    icon={item.icon}
                    title={item.title}
                    showMenuLabel={props.showMenuLabel}
                  />
                </Link>
              </OverlayTrigger>
            )}
          </React.Fragment>
        );
      });

  const quickLinks = routes.getQuickLinks().map((item) => (
    <div key={item.path} className="sidebar-quicklinks">
      {item.title}
    </div>
  ));

  const { showMenuLabel, collapseSidebar } = props;

  const accountOptions = (
    <Popover id="popover-basic">
      <Popover.Content className="w100 p0">
        <Button
          disabled
          variant="link"
          className="w-100 d-block p3 break-bottom"
        >
          Options
        </Button>
        <Button
          disabled
          variant="link"
          className="w-100 d-block p3 break-bottom"
        >
          Settings
        </Button>
        <Button
          variant="link"
          onClick={() => {
            localStorage.clear();
            history.push("/");
            const selectedDatabase = GeneralUtils.getCookieValue("DATABASE");
            GeneralUtils.clearSessionCookiesAndGlobalVars();
            GeneralUtils.removeAllCookies();
            GeneralUtils.appendCookie("DATABASE", selectedDatabase);
          }}
          className="w-100 d-block p3"
        >
          Logout
        </Button>
      </Popover.Content>
    </Popover>
  );

  let userDetails = {
    tenantId: "",
    userId: "",
    firstName: "",
    lastName: "",
    username: "",
  };
  const userDetailsCookie = GeneralUtils.getCookieValue("USER_DETAILS");
  if (userDetailsCookie !== "") {
    userDetails = JSON.parse(userDetailsCookie);
  }

  return (
    <div className="sidebar">
      <ScrollView showScrollbar="always" scrollByThumb reachBottomText="">
        <div
          style={{
            backgroundColor: "transparent",
            border: "none",
            // paddingLeft: '0px',
            // marginLeft: '-10px',
          }}
          className="logo"
        >
          <img src={logo} alt="Logo" />
          <span
            className={
              showMenuLabel === false ? "fade-to-none" : "fade-to-show"
            }
          >
            yieldWerx
          </span>
        </div>
        <div className="sidebar-menu">
          {sidebarNavItems()}
          {/** ----------------------------------------- */}
          <div
            className={`sidebar-bottom ${
              showMenuLabel === false ? "fade-to-none" : "fade-to-show"
            }`}
          >
            {!AppConstants.isTempHidden && (
              <>
                <div className="sidebar-section-title">Quick Links</div>
                {quickLinks}
              </>
            )}

            <div className="avatar-container ml-20 pl20 pt20 pb20 d-flex align-items-center justify-content-between">
              <Avatar
                size="lg"
                mode="light"
                img={avatar}
                title={GeneralUtils.toTitleCase(
                  `${userDetails.firstName} ${userDetails.lastName}`,
                  false
                )}
                subtitle={`@${userDetails.username}`}
              />

              <OverlayTrigger
                rootClose
                trigger="click"
                placement="top"
                overlay={accountOptions}
              >
                <FontAwesomeIcon
                  className="color-light cursor-pointer"
                  icon={faChevronDown}
                />
              </OverlayTrigger>
            </div>

            <div className="sidebar-copyright-text">
              <span className="company color-light d-block">
                © Copyright {new Date().getFullYear()} yieldWerx.
                <br />
                All Rights Reserved.
              </span>
              <span className="color-sidebar d-block">
                {process.env.REACT_APP_ENV}:
                {process.env.REACT_APP_BUILD_VERSION}
              </span>
            </div>
          </div>
          {/** ----------------------------------------- */}
        </div>
      </ScrollView>
    </div>
  );
};

export default SidebarNav;
