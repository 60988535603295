import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomizedReportsCollection from '../individual-reports/customized-report/CustomizedReportsCollection';

const ViewPolicyOutput = () => {
  const location = useLocation();
  let data : any;
  let path = '';
  let _ = '';
  if (location.pathname !== null && location.pathname !== undefined) {
    [_, path, data] = location.pathname.split('/');
  }

  return (path === 'simulate-policy') ? (
    <CustomizedReportsCollection
      reportList={[]}
      type="SIMULATE_POLICY"
      policyId={data !== undefined ? data : undefined}
      title="Manual Policy Execution"
    />
  ) : (
    <CustomizedReportsCollection
      reportList={[]}
      type="VIEW_POLICY_OUTPUT"
      policyId={data !== undefined ? data : undefined}
      title="Policy Output"
    />
  );
};

export default ViewPolicyOutput;
