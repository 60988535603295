import getDropDownOptions from 'components/getDropDownOptions';
import { SelectBox } from 'devextreme-react';
import React from 'react';
import { Viewport } from '../../wafer-map/web-gl-utils/Types';
import WaferUtils from './WaferUtils';

type WaferInfoRegionProps = {
  outerViewPort: Viewport,
  radarViewPort: Viewport,
  pinControlsToWaferTop: boolean,
  activeWaferKeyIndex: string,
  onControlsRadioChange: (value: boolean, keyIndex: string) => void,
  onBindCheckboxChange: (value: boolean, keyIndex: string) => void,
  isBoundToPubSubNetwork: boolean,
  waferUtil: WaferUtils,
  onChangeDieTextField: (value: string, keyIndex: string) => void,
  dieTextField: string,
  noOfWafers: number,
};

export const WaferInfoRegion = (props: WaferInfoRegionProps) => {
  const {
    outerViewPort, radarViewPort, activeWaferKeyIndex, pinControlsToWaferTop, dieTextField, noOfWafers,
    onControlsRadioChange, isBoundToPubSubNetwork, onBindCheckboxChange, waferUtil, onChangeDieTextField,
  } = props;
  const fontSize = (4 / 148.5) * radarViewPort.width + 8 - 400 / 148.5;
  return (
    <div
      style={{
        position: 'absolute',
        width: `${radarViewPort.width}px`,
        height: `${outerViewPort.height - radarViewPort.height}px`,
        bottom: `${outerViewPort.y}px`,
        left: `${outerViewPort.x + outerViewPort.width}px`,
        zIndex: 1,
        overflow: 'clip',
        fontSize: `${fontSize}px`,
      }}
    >
      { noOfWafers > 1
        ? (
          <div>
            <p style={{ marginLeft: radarViewPort.width / 10 }} className="mt10">
              <input type="checkbox" checked={isBoundToPubSubNetwork} onChange={(event: any) => { onBindCheckboxChange(event.target.checked, waferUtil.waferMapVariables.keyIndex); }} />
              <span className="ml5">Connect</span>
            </p>
            {!pinControlsToWaferTop ? (
              <p style={{ marginLeft: radarViewPort.width / 10 }} className="mt-5">
                <input
                  type="radio"
                  checked={activeWaferKeyIndex === waferUtil.waferMapVariables.keyIndex}
                  onChange={(event: any) => { onControlsRadioChange(event.target.value, waferUtil.waferMapVariables.keyIndex); }}
                />
                <span className="ml5">Control</span>
              </p>
            ) : null}
          </div>
        ) : null }
      {pinControlsToWaferTop ? (
        <div style={{ marginLeft: radarViewPort.width / 10 }}>
          <strong style={{ fontWeight: 'bolder' }} className="text">Die Text Field: </strong>
          <SelectBox
            displayExpr={(x: any) => { return x ? x[1] : ''; }}
            items={waferUtil.waferMapVariables.dieTextFieldOptions}
            value={dieTextField}
            valueExpr={(x: any) => { return x ? x[0] : ''; }}
            onValueChanged={(e: any) => { onChangeDieTextField(e.value, waferUtil.waferMapVariables.keyIndex); }}
            dropDownOptions={getDropDownOptions('div')}
          />
        </div>
      ) : null}
      <p style={{ marginLeft: radarViewPort.width / 10, fontSize: fontSize + 2 }} className="mb1 mt10"><strong>Legend</strong></p>
      { waferUtil.waferMapVariables.externalLegendFields.map((field: { key: string, value: string }) => {
        return (
          <p style={{ marginLeft: radarViewPort.width / 10 }} className="mb0">
            <strong style={{ fontWeight: 'bolder' }} className="text">
              {field.key}
              :
              {' '}
            </strong>
            {field.value}
          </p>
        );
      })}
      { waferUtil.waferMapVariables.legendFields.map((field: string[]) => {
        return (
          <p style={{ marginLeft: radarViewPort.width / 10 }} className="mb0">
            <strong style={{ fontWeight: 'bolder' }} className="text">
              {field[1]}
              :
              {' '}
            </strong>
            {waferUtil.waferMapVariables[field[0]]}
          </p>
        );
      })}
    </div>
  );
};
