import React from 'react';
import {
  Container, Col, Row, Spinner,
} from 'react-bootstrap';
import { faPlus, faSortDown, faMinus, } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeEditor from 'components/utility-component/code-editor/CodeEditor';
import Button from '../../wrapped-component/hint-controls/Button';
import Label from '../../wrapped-component/hint-controls/Label';
import Textbox from '../../wrapped-component/hint-controls/Textbox';
import Textarea from '../../wrapped-component/hint-controls/Textarea';
import PageHeader from '../../template-component/page-header/PageHeader';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import CustomizedAccordion from '../../wrapped-component/customized-accordion/CustomizedAccordion';
import AppConstants from '../../../constants.js';
import Heading from '../../wrapped-component/hint-controls/Heading';
import {
  IFailedBinNumbers, TestProgramMatchingStrategies, ServiceType, DieTypesMap, ISelectionCriteriaReturnValue,
} from '../../../interfaces';
import { httpService } from '../../../services/http.service';
import { addErrorClasses, } from '../../template-component/form-error-label/ErrorLabel';
import SelectionCriteriaButton from '../../selection-criteria/SelectionCriteriaButton';
import CustomizedMultipleDropdown from '../../wrapped-component/customized-multiple-dropdown/CustomizedMultipleDropdown';

interface ICreatePATRule {
  closeRuleComponent: () => void
  searchCriteria?: any[]
  id?: string
  policyStepSequence?: number
  getSavedRule?: (sequence: number, ruleId: string) => void
}

class CreatePATRule extends React.Component<ICreatePATRule, any> {
  private collapseAccordion: any;

  private filterCriteria: any = [];

  private ruleType = [
    ['STATIC_PAT', 'Static Rule'],
    ['DYNAMIC_PAT', 'Dynamic Rule'],
    ['MPVAT', 'MVPAT'],
  ];

  private siteOptions = [
    ['SIMPLE_RULE', 'Simple Rule'],
    ['RULE_PER_SITE', 'Rule Per Site'],
  ];

  private limitsToValidate = [
    ['NONE', 'None'],
    ['SPEC', 'Raw Data Spec Limit'],
    ['CONTROL', 'Raw Data Control Limit'],
    ['CUSTOM', 'Custom Limit'],
  ];

  private binType = [
    ['HARD_BIN', 'Hard Bin'],
    ['SOFT_BIN', 'Soft Bin'],
    ['PASS_FLAG', 'Pass Flag'],
    ['FAIL_FLAG', 'Fail Flag'],
  ];

  private dieType = [
    ['WAT_PCM', 'WAT/PCM'],
    ['PARTIAL_DIE', 'Partial Die'],
    ['NOT_PROBED', 'Not Probed'],
    ['PROBED_BUT_EXCLUDED', 'Probed but excluded'],
    ['PROBED_DIE', 'Probe Die'],
    ['EXCLUDED_FROM_ASSEMBLY', 'Excluded from Assembly'],
  ];

  private dieTypeOptions = [
    { key: 'WAT_PCM', value: 'WAT/PCM' },
    { key: 'PARTIAL_DIE', value: 'Partial Die' },
    { key: 'NOT_PROBED', value: 'Not Probed' },
    { key: 'PROBED_BUT_EXCLUDED', value: 'Probe but Excluded' },
    { key: 'PROBED_DIE', value: 'Probe Die' },
    { key: 'EXCLUDED_FROM_ASSEMBLY', value: 'Excluded from Assembly' },
  ];

  private recalculateLimitType = [
    ['LOT', 'Lots'],
    ['WAFER', 'Wafers'],
    ['DAY', 'Days'],
    ['MONTH', 'Months'],
  ];

  private dieFields = ['SoftBinNumber', 'HardBinNumber', 'X', 'Y'];

  private initialFields = ['die', 'patHighLimit', 'patLowLimit'];

  private testParameterFields = ['HighCtrlLimit', 'LowCtrlLimit', 'HighSpecLimit', 'LowSpecLimit'];

  private conditionalInitialRegex = { regex: /^$/, values: { type: 'list', values: this.initialFields } };

  private conditionalDieRegex = { regex: /die\.$/i, values: { type: 'groupedList', values: [{ key: 'Fields', items: this.dieFields }] } };

  private conditionalSecondStatementRegex = { regex: /\s$/i, values: { type: 'list', values: this.initialFields } };

  constructor(props: ICreatePATRule) {
    super(props);
    this.updateSiteOption = this.updateSiteOption.bind(this);
    this.updatePATRuleType = this.updatePATRuleType.bind(this);
    this.updateLimitsToValidate = this.updateLimitsToValidate.bind(this);
    this.getRule = this.getRule.bind(this);
    this.getTestParameters = this.getTestParameters.bind(this);
    this.selectTestParameter = this.selectTestParameter.bind(this);
    this.updateRecalculateLimitType = this.updateRecalculateLimitType.bind(this);
    this.renderConditionalRules = this.renderConditionalRules.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.addRule = this.addRule.bind(this);
    this.deleteClause = this.deleteClause.bind(this);
    this.addClause = this.addClause.bind(this);
    this.renderClauses = this.renderClauses.bind(this);
    this.renderDeleteClauseButton = this.renderDeleteClauseButton.bind(this);
    this.renderDeleteRuleButton = this.renderDeleteRuleButton.bind(this);
    this.conditionOnChangeHandler = this.conditionOnChangeHandler.bind(this);
    this.clauseOnChangeHandler = this.clauseOnChangeHandler.bind(this);
    this.updateDieType = this.updateDieType.bind(this);
    this.updateSourceDieFilter = this.updateSourceDieFilter.bind(this);
    this.updateTestParameterMatchingStrategy = this.updateTestParameterMatchingStrategy.bind(this);
    const failedBinNumbers: IFailedBinNumbers[] = [];
    this.state = {
      showFormFields: false,
      editRule: {
        Id: '',
        name: '',
        owner: '',
        shortDescription: '',
        description: '',
        recalculateLimit: '',
        version: 'v1.0',
        customHighLimit: '',
        customLowLimit: '',
      },
      preserveTestParamSelectionOnEdit: false,
      isStatic: false,
      failedBinNumbers,
      selectedRuleType: this.ruleType[1][0],
      selectedSiteOption: this.siteOptions[0][0],
      selectedLimitsToValidate: this.limitsToValidate[0][0],
      hideAddExpression: true,
      showCustomLimit: false,
      sourceDieFilter: '',
      testParameters: [],
      selectedDieTypes: [],
      selectedRecalculateLimitType: this.recalculateLimitType[0][0],
      selectedTestParameterMatchingStrategy: TestProgramMatchingStrategies[0][0],
      testParameterMatchingExpression: '',
      isManualSeedLimit: false,
      type: '',
      rules: [{
        condition: '', thenStatements: [''], elseStatements: [''],
      }],
      errorSelectedTestParameters: '',
      selectedTestParameters: [],
      conditionalProperties: [
        this.conditionalInitialRegex,
        this.conditionalDieRegex,
        { regex: /die\.P\d+\.$/i, values: { type: 'groupedList', values: [{ key: 'Fields', items: ['Value'] }] } },
        { regex: /P\d+\.$/i, values: { type: 'groupedList', values: [{ key: 'Fields', items: this.testParameterFields }] } },
        {
          regex: /[A-Za-z0-9]+\s$/i,
          values: {
            type: 'groupedList',
            values: [
              { key: 'Comparison Operators', items: ['<', '>', '==', '!=', '>=', '<='] },
              { key: 'Logical Operators', items: ['&&', '||'] },
              { key: 'Airthmetic Operators', items: ['+', '-', '*', '/'] }],
          },
        },
        this.conditionalSecondStatementRegex,
      ],
    };
    this.collapseAccordion = React.createRef();
  }

  componentDidMount() {
    const { searchCriteria } = this.props;
    // eslint-disable-next-line no-unused-expressions
    searchCriteria?.forEach((criteria:any) => {
      if (criteria.id !== 'None') {
        this.filterCriteria.push({
          columnName: criteria.columnName,
          operationName: '=',
          queryText: criteria.id,
          controlType: 'LOT',
          dataType: 'string',
          groupConditionOn: 'OR',
        });
      }
    });
  }

  isFormFieldsValid = (submitCount:any, errors:any, selectedTestParameters: any) => {
    return (submitCount > 0
      && (
        errors.name
        || errors.owner
        || errors.shortDescription
        || errors.description
        || selectedTestParameters.length === 0
      ));
  };

  getRule = () => {
    const { id } = this.props;
    if (id !== undefined) {
      let {
        editRule,
        selectedRuleType,
        selectedLimitsToValidate,
        isStatic,
        selectedDieTypes,
        selectedTestParameterMatchingStrategy,
        selectedTestParameters,
        selectedRecalculateLimitType,
        selectedSiteOption,
        sourceDieFilter,
        type,
        rules,
        testParameterMatchingExpression,
        isManualSeedLimit,
      } = this.state;
      httpService.getPATRule(id).then((data) => {
        if (data !== '') {
          editRule = {
            Id: data.id,
            name: data.name,
            owner: data.owner,
            shortDescription: data.shortDescription,
            description: data.description,
            lowSeedLimits: data.seedLowLimit === null ? '' : data.seedLowLimit,
            highSeedLimits:
              data.seedHighLimit === null ? '' : data.seedHighLimit,
            valueOfK: data.k,
            recalculateLimit: data.recalculateLimit,
            version: data.version,
            createdBy:
              data.createdBy !== null
                ? `${data.createdBy.firstName} ${data.createdBy.lastName}`
                : '',
            createdOn: data.createdOn,
            updatedBy:
              data.updatedBy !== null
                ? `${data.updatedBy.firstName} ${data.createdBy.lastName}`
                : '',
            updatedOn: data.updatedOn,
            customLowLimit: data.customLowLimit,
            customHighLimit: data.customHighLimit,
          };
          selectedRecalculateLimitType = data.recalculateLimitType;
          selectedRuleType = data.patRuleType;
          isStatic = selectedRuleType === 'STATIC_PAT';
          selectedLimitsToValidate = data.limitTypeToValidate;
          selectedDieTypes = data.dieTypes.map((dieType:string) => { return { key: dieType, value: DieTypesMap.get(dieType) }; });
          selectedTestParameterMatchingStrategy = data.testParameterMatchingStrategy;
          selectedTestParameters = data.testParameters.map((param:any) => param.testParameter);
          selectedSiteOption = data.siteOption;
          rules = data.conditionalStatements;
          sourceDieFilter = data.sourceDieFilter;
          testParameterMatchingExpression = data.testParameterMatchingExpression;
          type = data.type;
          isManualSeedLimit = data.manualSeedLimit;
          this.setState({
            preserveTestParamSelectionOnEdit: true,
            showFormFields: true,
            editRule,
            selectedRuleType,
            selectedLimitsToValidate,
            isStatic,
            selectedTestParameters,
            selectedDieTypes,
            selectedTestParameterMatchingStrategy,
            selectedRecalculateLimitType,
            selectedSiteOption,
            sourceDieFilter,
            type,
            rules,
            showCustomLimit: selectedLimitsToValidate === 'CUSTOM',
            testParameterMatchingExpression,
            isManualSeedLimit,
          });
        }
      });
    }
  };

  getTestParameters = () => {
    const { searchCriteria } = this.props;
    httpService
      .getFilteredTestParameters(searchCriteria)
      .then((metaData) => {
        const { data } = metaData;
        this.setState({ testParameters: data });
      })
      .finally(() => {
        this.getRule();
      });
  };

  selectTestParameter = (selectedRowsData: any) => {
    const { preserveTestParamSelectionOnEdit } = this.state;
    if (preserveTestParamSelectionOnEdit) {
      this.setState({ preserveTestParamSelectionOnEdit: false });
      return;
    }
    let { errorSelectedTestParameters } = this.state;
    const { conditionalProperties } = this.state;
    if (selectedRowsData.selectedRowsData.length > 0) {
      errorSelectedTestParameters = '';
    }

    const newInitialFields = [...selectedRowsData.selectedRowsData.map((_:any, index:any) => `P${index + 1}`), ...this.initialFields];
    const newDieFields = [...selectedRowsData.selectedRowsData.map((_:any, index:any) => `P${index + 1}`), ...this.dieFields];

    this.conditionalInitialRegex.values.values = newInitialFields;
    this.conditionalSecondStatementRegex.values.values = newInitialFields;
    this.conditionalDieRegex.values.values[0].items = newDieFields;

    this.setState({ selectedTestParameters: selectedRowsData.selectedRowsData, errorSelectedTestParameters, conditionalProperties });
  };

  updatePATRuleType = (value: string) => {
    this.setState({ isStatic: value === 'STATIC_PAT', selectedRuleType: value });
  };

  updateSourceDieFilter = (value: string) => {
    this.setState({ sourceDieFilter: value });
  };

  updateLimitsToValidate = (value: string) => {
    this.setState({ showCustomLimit: value === 'CUSTOM', selectedLimitsToValidate: value });
  };

  updateDieType = (values: any) => {
    this.setState({ selectedDieTypes: values, preserveTestParamSelectionOnEdit: true });
  };

  updateRecalculateLimitType = (value:string) => {
    this.setState({ selectedRecalculateLimitType: value });
  };

  updateTestParameterMatchingStrategy = (value:string) => {
    const { selectedTestParameters } = this.state;
    if (value === 'EXPRESSION') {
      this.setState({
        selectedTestParameters, preserveTestParamSelectionOnEdit: true, selectedTestParameterMatchingStrategy: value, hideAddExpression: false,
      });
    } else {
      this.setState({
        selectedTestParameters, preserveTestParamSelectionOnEdit: true, selectedTestParameterMatchingStrategy: value, hideAddExpression: true,
      });
    }
  };

  updateSiteOption = (value:string) => {
    this.setState({ selectedSiteOption: value });
  };

  addClause = (ruleIndex: any, flag: string) => {
    const { rules } = this.state;
    if (flag === 'THEN') {
      rules[ruleIndex].thenStatements.push('');
    } else if (flag === 'ELSE') {
      rules[ruleIndex].elseStatements.push('');
    }
    this.setState({ rules });
  };

  deleteClause = (ruleIndex: any, statementIndex: any, flag: string) => {
    const { rules } = this.state;
    if (flag === 'THEN') {
      rules[ruleIndex].thenStatements.splice(statementIndex, 1);
    } else if (flag === 'ELSE') {
      rules[ruleIndex].elseStatements.splice(statementIndex, 1);
    }
    this.setState({ rules });
  };

  addRule = () => {
    const { rules } = this.state;
    rules.push({ condition: '', thenStatements: [''], elseStatements: [''] });
    this.setState({ rules });
  };

  onRowEditing = (event:any) => {
    const { selectedTestParameters } = this.state;
    const { data } = event;
    const selectedTpIds = selectedTestParameters.map((tp:any) => tp.id);
    const tpIndex = selectedTpIds.indexOf(data.id);
    selectedTestParameters[tpIndex] = data;
  };

  deleteRule = (ruleIndex: any) => {
    const { rules } = this.state;
    rules.splice(ruleIndex, 1);
    this.setState({ rules });
  };

  renderDeleteClauseButton = (clauses: any, ruleIndex: any, statementIndex: any, flag: string) => {
    if (clauses.length > 1) {
      return (
        <Button
          variant="outline-primary"
          className="ml20 w40"
          onClick={() => this.deleteClause(ruleIndex, statementIndex, flag)}
        >
          <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
        </Button>
      );
    }
    return null;
  };

  renderDeleteRuleButton = (ruleIndex: any) => {
    const { rules } = this.state;
    if (rules.length > 1) {
      return (
        <Button
          variant="outline-primary"
          className="w40"
          onClick={() => this.deleteRule(ruleIndex)}
        >
          <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
        </Button>
      );
    }
    return null;
  };

  clauseOnChangeHandler = (value: string, ruleIndex: any, statementIndex: any, flag: string) => {
    const { rules } = this.state;
    if (flag === 'THEN') {
      rules[ruleIndex].thenStatements[statementIndex] = value;
    } else if (flag === 'ELSE') {
      rules[ruleIndex].elseStatements[statementIndex] = value;
    }
    this.setState({ rules });
  };

  conditionOnChangeHandler = (value: string, ruleIndex: any) => {
    const { rules } = this.state;
    rules[ruleIndex].condition = value;
    this.setState({ rules });
  };

  renderClauses = (clauses: any, ruleIndex: any, flag: string) => {
    const { conditionalProperties } = this.state;
    return clauses.map((clause: string, statementIndex: any) => (
      <div className="d-flex mb10">
        <CodeEditor
          rows={1}
          defaultValue={clause}
          value={clause}
          placeHolder="Statement"
          matchingCriterion={conditionalProperties}
          updateOnChange={(value: string) => {
            this.clauseOnChangeHandler(value, ruleIndex, statementIndex, flag);
          }}
        />
        {statementIndex === clauses.length - 1 ? (
          <Button
            variant="outline-primary"
            className="ml20 w40"
            onClick={
              () => this.addClause(ruleIndex, flag)
            }
          >
            <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
          </Button>
        ) : null}
        {this.renderDeleteClauseButton(clauses, ruleIndex, statementIndex, flag)}
      </div>
    ));
  };

  renderConditionalRules = (conditionalRules:any) => {
    const { conditionalProperties } = this.state;
    return conditionalRules.map((conditionalRule:any, ruleIndex: any) => (
      <div className="w-100 flex-100 d-flex align-items-center">
        <div
          style={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #eeeeee',
            marginTop: '5px',
          }}
          className="flex-90 mr10 custom-form-inline label-left"
        >
          <Label
            labelTitle="If"
            labelPosition="left-middle"
            labelSize="20"
            fieldSize="70"
            errorSize="10"
          >
            <div
              className="ml15"
            >
              <CodeEditor
                rows={1}
                defaultValue={conditionalRule.condition}
                value={conditionalRule.condition}
                placeHolder="Condition"
                matchingCriterion={conditionalProperties}
                updateOnChange={(value: string) => {
                  this.conditionOnChangeHandler(value, ruleIndex);
                }}
              />
            </div>
          </Label>
          <Label
            labelTitle="Then"
            labelPosition="left-middle"
            labelSize="20"
            fieldSize="70"
            errorSize="10"
          >
            <div
              className="ml15"
            >
              <div className="flex-column">
                {this.renderClauses(conditionalRule.thenStatements, ruleIndex, 'THEN')}
              </div>
            </div>
          </Label>
          <Label
            className="mt-10"
            labelTitle="Else"
            labelPosition="left-middle"
            labelSize="20"
            fieldSize="70"
            errorSize="10"
          >
            <div
              className="ml15"
            >
              <div className="flex-column">
                {this.renderClauses(conditionalRule.elseStatements, ruleIndex, 'ELSE')}
              </div>
            </div>
          </Label>
        </div>
        <div className="flex-10 mt10">
          {ruleIndex === conditionalRules.length - 1 ? (
            <Button
              variant="outline-primary"
              className="w40 mr10"
              onClick={
                () => this.addRule()
              }
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
            </Button>
          ) : null}
          {this.renderDeleteRuleButton(ruleIndex)}
        </div>
      </div>
    ));
  };

  getSelectionCriteriaComponentData = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    // this.policyStore.SearchCriteria = [];
    // selectionCriteriaData.selections.forEach((item) => {
    //   this.policyStore.SearchCriteria.push(...this.populateSelectedCriteria(item.entityType, item.values.map((selectionsItem:ISelectionsObject) => (selectionsItem.id)), true));
    // });
    // const expressions : any = {};
    // selectionCriteriaData.expressions.forEach((item) => {
    //   expressions[item.entityType] = item.values;
    // });
    // this.policyStore.SearchCriteriaExpressions = JSON.stringify(expressions);
    // this.policyStore.AdvancedSearchExpressions = selectionCriteriaData.advancedFilter;
  };

  render() {
    const {
      editRule,
      isStatic,
      selectedRuleType,
      selectedLimitsToValidate,
      testParameters,
      selectedTestParameters,
      hideAddExpression,
      rules,
      selectedDieTypes,
      selectedTestParameterMatchingStrategy,
      selectedRecalculateLimitType,
      selectedSiteOption,
      conditionalProperties,
      sourceDieFilter,
      showCustomLimit,
      type,
      showFormFields,
      testParameterMatchingExpression,
      isManualSeedLimit,
    } = this.state;
    console.trace(sourceDieFilter);
    const { errorSelectedTestParameters } = this.state;
    const { closeRuleComponent, getSavedRule, policyStepSequence } = this.props;

    const formValidationSchema = Yup.object({
      name: Yup.string().required('Required').max(20),
      shortDescription: Yup.string().required('Required').max(1000),
      description: Yup.string().max(1000),
      recalculateLimit: Yup.number().when([], () => {
        if (isStatic) {
          return Yup.number().required('Required').typeError('Must be a number');
        }
        return Yup.string();
      }),
      version: Yup.string().required(),
      testParameterMatchingExpression: Yup.string().when([], () => {
        if (selectedTestParameterMatchingStrategy.toUpperCase() === 'EXPRESSION') {
          return Yup.string().required('Required');
        }
        return Yup.string();
      }),
    });

    const targetDieConfiguration = this.renderConditionalRules(rules);

    return showFormFields ? (
      <div className="pl40 pr20 container-primary-border-top">
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            name: `${editRule.name}`,
            owner: `${editRule.owner}`,
            shortDescription: `${editRule.shortDescription}`,
            description: `${editRule.description}`,
            recalculateLimit: `${editRule.recalculateLimit}`,
            customLowLimit: `${editRule.customLowLimit}`,
            customHighLimit: `${editRule.customHighLimit}`,
            version: 'v1.0',
            testParameterMatchingExpression: `${testParameterMatchingExpression}`,
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values: any) => {
            if (selectedTestParameters.length === 0) {
              this.setState({ errorSelectedTestParameters: 'Please Select a Test Parameter' });
              return;
            }
            const { id } = this.props;
            if (id === undefined) {
              const rule = {
                Name: values.name,
                Owner: values.owner,
                ShortDescription: values.shortDescription,
                Description: values.description,
                Version: values.version,
                CreatedBy: { Id: AppConstants.user.Id },
                SeedLowLimit:
                  selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : values.lowSeedLimits,
                SeedHighLimit:
                  selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : values.highSeedLimits,
                K: values.valueOfK,
                CustomLowLimit: values.customLowLimit === undefined || !showCustomLimit || values.customLowLimit === null ? 0.0 : values.customLowLimit,
                CustomHighLimit: values.customHighLimit === undefined || !showCustomLimit || values.customHighLimit === null ? 0.0 : values.customHighLimit,
                LimitTypeToValidate: selectedLimitsToValidate,
                PATRuleType: selectedRuleType,
                DieTypes: selectedDieTypes.map((dieType:any) => dieType.key),
                TestParameters: selectedTestParameters.map((param:any, index:any) => {
                  return {
                    TestParameter: { ...param },
                    // Alias: `P${index + 1}`,
                    Sequence: index,
                  };
                }),
                SourceDieFilter: sourceDieFilter,
                SiteOption: selectedSiteOption,
                ConditionalStatements: rules,
                TestParameterMatchingStrategy: selectedTestParameterMatchingStrategy,
                RecalculateLimit: selectedRuleType === ServiceType.DYNAMIC_PAT ? '0' : values.recalculateLimit,
                RecalculateLimitType: selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : selectedRecalculateLimitType,
                testParameterMatchingExpression: selectedTestParameterMatchingStrategy.toUpperCase() === 'EXPRESSION' ? values.testParameterMatchingExpression : '',
                ManualSeedLimit: isManualSeedLimit,
              };
              httpService
                .savePATRule({ RuleJSON: JSON.stringify(rule).toString() })
                .then((data) => {
                  if (data !== '') {
                    if (
                      getSavedRule !== undefined
                      && policyStepSequence !== undefined
                    ) {
                      getSavedRule(policyStepSequence, data);
                    }
                    closeRuleComponent();
                  }
                });
            } else {
              const rule = {
                Id: id,
                Type: type,
                Name: values.name,
                Owner: values.owner,
                ShortDescription: values.shortDescription,
                Description: values.description,
                Version: values.version,
                CreatedBy: { Id: AppConstants.user.Id },
                SeedLowLimit:
                    selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : values.lowSeedLimits,
                SeedHighLimit:
                    selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : values.highSeedLimits,
                K: values.valueOfK,
                CustomLowLimit: values.customLowLimit,
                CustomHighLimit: values.customHighLimit,
                LimitTypeToValidate: selectedLimitsToValidate,
                PATRuleType: selectedRuleType,
                DieTypes: selectedDieTypes.map((dieType:any) => dieType.key),
                TestParameters: selectedTestParameters.map((param:any, index:any) => {
                  return {
                    TestParameter: { ...param },
                    // Alias: `P${index + 1}`,
                    Sequence: index,
                  };
                }),
                SourceDieFilter: sourceDieFilter,
                SiteOption: selectedSiteOption,
                ConditionalStatements: rules,
                TestParameterMatchingStrategy: selectedTestParameterMatchingStrategy,
                RecalculateLimit: selectedRuleType === ServiceType.DYNAMIC_PAT ? '0' : values.recalculateLimit,
                RecalculateLimitType: selectedRuleType === ServiceType.DYNAMIC_PAT ? '' : selectedRecalculateLimitType,
                testParameterMatchingExpression: selectedTestParameterMatchingStrategy.toUpperCase() === 'EXPRESSION' ? values.testParameterMatchingExpression : '',
                ManualSeedLimit: isManualSeedLimit,
              };
              httpService
                .updatePATRule({ RuleJSON: JSON.stringify(rule).toString() })
                .then(() => {
                  closeRuleComponent();
                });
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, touched, submitCount,
          }) => (
            <form
              onSubmit={handleSubmit}
              onBlur={handleChange}
              className="custom-form"
            >
              <PageHeader
                title={values.name === '' ? 'Create PAT Rule' : values.name}
                breadcrumbs={`Policy \\ PAT Stages \\ ${
                  values.name === '' ? 'Create PAT Rule' : values.name
                }`}
                titleActions={(
                  <div className="pt10 d-flex align-items-end">
                    <span className="pb7 mr10" style={{ color: 'grey' }}>
                      {values.version}
                    </span>
                    <div className="dropdown-badge pb7">
                      <CustomizedDropdown
                        variant="clear"
                        disabled
                        list={[
                          ['1', 'Draft'],
                          ['2', 'Active'],
                        ]}
                      />
                    </div>
                  </div>
                )}
                primaryActions={(
                  <div className="d-flex align-items-center justify-content-between pr10 ml-20 pb20">
                    <div hidden={editRule.Id === ''}>
                      <span className="color-icon-color mt-10">Updated By</span>
                      {' '}
                      <span className="color-default-color mr20">
                        {editRule.updatedBy}
                      </span>
                      <span className="color-icon-color mt-10">Updated On</span>
                      {' '}
                      <span className="color-default-color">
                        {new Date(editRule.updatedOn).toDateString()}
                      </span>
                    </div>
                    <div hidden={editRule.Id === ''}>
                      <span className="color-icon-color mt-10">Created By</span>
                      {' '}
                      <span className="color-default-color mr20">
                        {editRule.createdBy}
                      </span>
                      <span className="color-icon-color mt-10">Created On</span>
                      {' '}
                      <span className="color-default-color">
                        {new Date(editRule.createdOn).toDateString()}
                      </span>
                    </div>
                  </div>
                )}
                secondaryActions={(
                  <div>
                    <span className="color-icon-color mt-10">PAT Rule Sequence:</span>
                    {' '}
                    <span className="color-default-color mr20">
                      {policyStepSequence}
                    </span>
                  </div>
                )}
              />
              <div>
                <Container fluid>
                  <Row>
                    <Col lg={12}>
                      <Container className="p0">
                        <Row>
                          <Col>
                            <div className="custom-form-inline label-left">
                              <div className="flex-20 mt20"><Heading size={6}>General Configuration</Heading></div>
                            </div>
                            <Label
                              labelTitle="Name"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              error={errors.name}
                              isFieldTouched={touched.name}
                              required
                            >
                              <Textbox
                                defaultValue={values.name}
                                autoComplete="off"
                                name="name"
                                placeholder="Rule Name"
                                className={`${addErrorClasses(
                                  errors.name,
                                  touched.name,
                                )}`}
                              />
                            </Label>
                            <Label
                              labelTitle="Short Description"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              error={errors.shortDescription}
                              isFieldTouched={touched.shortDescription}
                              required
                            >
                              <Textbox
                                defaultValue={values.shortDescription}
                                autoComplete="off"
                                name="shortDescription"
                                placeholder="Short Description"
                                className={`${addErrorClasses(
                                  errors.shortDescription,
                                  touched.shortDescription,
                                )}`}
                              />
                            </Label>
                            <Label
                              labelTitle="Description"
                              labelPosition="left-top"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              error={errors.description}
                              isFieldTouched={touched.description}
                            >
                              <Textarea
                                defaultValue={values.description}
                                autoComplete="off"
                                name="description"
                                placeholder="Description"
                                rows={3}
                                className={`${addErrorClasses(
                                  errors.description,
                                  touched.description,
                                )}`}
                              />
                            </Label>
                            <div className="custom-form-inline label-left">
                              <div className="flex-20 mt20"><Heading size={6}>Rule Configuration</Heading></div>
                            </div>
                            <Label
                              labelTitle="PAT Type"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              hint={{ message: 'Some Message' }}
                            >
                              <CustomizedDropdown
                                onChange={this.updatePATRuleType}
                                full
                                variant="clear"
                                list={this.ruleType}
                                selectedValue={selectedRuleType}
                                defaultValue={selectedRuleType}
                              />
                            </Label>
                            <Label
                              labelTitle="Site Option"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              hint={{ message: 'Some Message' }}
                            >
                              <CustomizedDropdown
                                onChange={this.updateSiteOption}
                                full
                                variant="clear"
                                list={this.siteOptions}
                                selectedValue={selectedSiteOption}
                                defaultValue={selectedSiteOption}
                              />
                            </Label>

                            <div className="custom-form-inline label-left">
                              <div className="flex-20 mt40"><Heading size={6}>Parameter Configuration</Heading></div>
                            </div>
                            <Label
                              labelTitle="Parameter Matching Selection"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              hint={{ message: 'Some Message' }}
                            >
                              <CustomizedDropdown
                                full
                                variant="clear"
                                list={TestProgramMatchingStrategies}
                                selectedValue={selectedTestParameterMatchingStrategy}
                                onChange={this.updateTestParameterMatchingStrategy}
                                defaultValue={selectedTestParameterMatchingStrategy}
                              />
                            </Label>
                            {selectedTestParameterMatchingStrategy.toUpperCase() === 'EXPRESSION' && (
                              <Label
                                labelTitle="Custom Regular Expression"
                                labelPosition="left-middle"
                                labelSize="20"
                                fieldSize="60"
                                errorSize="20"
                                hint={{ message: 'Some Message' }}
                              >
                                <Textbox
                                  defaultValue={values.testParameterMatchingExpression}
                                  autoComplete="off"
                                  name="testParameterMatchingExpression"
                                  placeholder="Parameter Matching Expression"
                                  className={`${addErrorClasses(
                                    errors.testParameterMatchingExpression,
                                    touched.testParameterMatchingExpression,
                                  )}`}
                                />
                              </Label>
                            ) }
                            <br />
                            <CustomizedAccordion
                              collapseOnLoad
                              cardClassNames="background-color-secondary-background border-all"
                              content={[
                                {
                                  header: (
                                    <>
                                      <div
                                        className="pt6 d-flex align-items-top justify-content-between flex-wrap"
                                        ref={this.collapseAccordion}
                                      >
                                        <Heading size={6} className="flex-90">
                                          Test Parameters Selection
                                        </Heading>
                                        <div className="flex-10 text-right">
                                          <FontAwesomeIcon
                                            size="sm"
                                            icon={faSortDown}
                                          />
                                        </div>
                                      </div>
                                      <span className="color-danger">
                                        {errorSelectedTestParameters}
                                      </span>
                                    </>
                                  ),
                                  body: (
                                    <CustomizedDataGrid
                                      showFilterRow
                                      totalRowsToDisplay={15}
                                      selectionMode="multiple"
                                      onRowSelection={this.selectTestParameter}
                                      selectedRowKeys={selectedTestParameters}
                                      fields={[
                                        {
                                          caption: 'Test #',
                                          dataField: 'test_parameter_number',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Test Name',
                                          dataField: 'test_parameter_name',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Ctrl Low',
                                          dataField: 'test_parameter_low_ctrl_limit',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Ctrl Hi',
                                          dataField: 'test_parameter_high_ctrl_limit',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Spec Low',
                                          dataField: 'test_parameter_low_spec_limit',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Spec Hi',
                                          dataField: 'test_parameter_high_spec_limit',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Test Unit',
                                          dataField: 'test_parameter_test_unit',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                        {
                                          caption: 'Res Scale',
                                          dataField: 'test_parameter_res_scale',
                                          dataType: 'string',
                                          showInfo: false,
                                        },
                                      ]}
                                      tableData={testParameters}
                                    />
                                  ),
                                },
                              ]}
                            />
                            <br />
                            {selectedTestParameters.length > 0 ? (
                              <div className="">
                                <div className="custom-form-inline label-left">
                                  <div className="flex-20 mt20"><Heading size={6}>Selected Parameters</Heading></div>
                                </div>
                                <CustomizedDataGrid
                                  showAdvancedFilters={false}
                                  allowUpdating
                                  height={300}
                                  onEditingStart={this.onRowEditing}
                                  editingMode="cell"
                                  tableData={selectedTestParameters.map((parameter:any, index:number) => ({
                                    alias: `P${index + 1}`, highKLimit: '', lowKLimit: '', ...parameter,
                                  }))}
                                  fields={[
                                    // {
                                    //   caption: 'Alias',
                                    //   dataField: 'alias',
                                    //   dataType: 'string',
                                    //   allowEditing: false,
                                    //   showInfo: false,
                                    // },
                                    {
                                      caption: 'Test #',
                                      dataField: 'test_parameter_number',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Test Name',
                                      dataField: 'test_parameter_name',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Ctrl Low',
                                      dataField: 'test_parameter_low_ctrl_limit',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Ctrl Hi',
                                      dataField: 'test_parameter_high_ctrl_limit',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Spec Low',
                                      dataField: 'test_parameter_low_spec_limit',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Spec Hi',
                                      dataField: 'test_parameter_high_spec_limit',
                                      dataType: 'string',
                                      allowEditing: false,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'K High',
                                      dataField: 'highKLimit',
                                      dataType: 'string',
                                      allowEditing: true,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'K Low',
                                      dataField: 'lowKLimit',
                                      dataType: 'string',
                                      allowEditing: true,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Seed Low',
                                      dataField: 'seedLowLimit',
                                      dataType: 'string',
                                      allowEditing: true,
                                      showInfo: false,
                                    },
                                    {
                                      caption: 'Seed High',
                                      dataField: 'seedHighLimit',
                                      dataType: 'string',
                                      allowEditing: true,
                                      showInfo: false,
                                    },
                                  ]}
                                />
                              </div>
                            ) : null}
                            {isStatic ? (
                              <>
                                <div className="custom-form-inline label-left">
                                  <div className="flex-20 mt20"><Heading size={6}>Limits</Heading></div>
                                </div>
                                <Label
                                  labelTitle="Select Golden Lots"
                                  labelPosition="left-middle"
                                  labelSize="20"
                                  fieldSize="60"
                                  errorSize="20"
                                  required
                                >
                                  <div className="mb10 w700">
                                    <SelectionCriteriaButton
                                      filterCriteria={this.filterCriteria}
                                      advancedFilter=""
                                      getSelectionCriteriaData={this.getSelectionCriteriaComponentData}
                                      defaultSelection={[]}
                                      widgetsList={
                                        [
                                          {
                                            controlType: 'Lot',
                                            columnWeight: 12,
                                            isVisible: true,
                                          },
                                        ]
                                      }
                                    />
                                  </div>
                                </Label>

                                <Label
                                  labelTitle="Recalculate After Every"
                                  labelPosition="left-middle"
                                  labelSize="20"
                                  fieldSize="60"
                                  errorSize="20"
                                  error={errors.recalculateLimit}
                                  isFieldTouched={touched.recalculateLimit}
                                  required
                                  childrenItemsInline
                                  hint={{ message: 'Some Message' }}
                                >
                                  <Textbox
                                    defaultValue={values.recalculateLimit}
                                    autoComplete="off"
                                    name="recalculateLimit"
                                    placeholder="Recalculate After Every"
                                    className={`${addErrorClasses(
                                      errors.recalculateLimit,
                                      touched.recalculateLimit,
                                    )}`}
                                    block
                                  />
                                  <CustomizedDropdown
                                    variant="clear"
                                    list={this.recalculateLimitType}
                                    onChange={this.updateRecalculateLimitType}
                                    selectedValue={selectedRecalculateLimitType}
                                  />
                                </Label>
                              </>
                            ) : null}
                            <div className="custom-form-inline label-left">
                              <div className="flex-20 mt30"><Heading size={6}>Source Dies Configuration</Heading></div>
                            </div>
                            <Label
                              labelTitle="Die Types"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              hint={{ message: 'Some Message' }}
                            >
                              <CustomizedMultipleDropdown
                                list={this.dieTypeOptions}
                                placeholder="Select Die Types"
                                selectedRowKeys={selectedDieTypes}
                                selectionHandler={this.updateDieType}
                              />
                            </Label>
                            <Label
                              labelTitle="Validate PAT Limits"
                              labelPosition="left-middle"
                              labelSize="20"
                              fieldSize="60"
                              errorSize="20"
                              hint={{ message: 'Some Message' }}
                            >
                              <CustomizedDropdown
                                full
                                variant="clear"
                                list={this.limitsToValidate}
                                onChange={this.updateLimitsToValidate}
                                selectedValue={selectedLimitsToValidate}
                                defaultValue={selectedLimitsToValidate}
                              />
                            </Label>
                            {showCustomLimit ? (
                              <div className="custom-form-inline">
                                <div className="w-50">
                                  <Label
                                    labelTitle="Custom Low Limit"
                                    labelPosition="left-middle"
                                    labelSize="40"
                                    fieldSize="40"
                                    errorSize="20"
                                    error={errors.customLowLimit}
                                    isFieldTouched={touched.customLowLimit}
                                    required
                                  >
                                    <Textbox
                                      defaultValue={values.customLowLimit === 'null' ? '' : values.customLowLimit}
                                      autoComplete="off"
                                      name="customLowLimit"
                                      placeholder="Custom Low Limit"
                                      className={`${addErrorClasses(
                                        errors.customLowLimit,
                                        touched.customLowLimit,
                                      )}`}
                                    />
                                  </Label>
                                </div>
                                <div className="w-50">
                                  <Label
                                    labelTitle="Custom High Limit"
                                    labelPosition="left-middle"
                                    labelSize="40"
                                    fieldSize="40"
                                    errorSize="20"
                                    error={errors.customHighLimit}
                                    isFieldTouched={touched.customHighLimit}
                                    required
                                  >
                                    <Textbox
                                      defaultValue={values.customHighLimit === 'null' ? '' : values.customHighLimit}
                                      autoComplete="off"
                                      name="customHighLimit"
                                      placeholder="Custom High Limit"
                                      className={`${addErrorClasses(
                                        errors.customHighLimit,
                                        touched.customHighLimit,
                                      )}`}
                                    />
                                  </Label>
                                </div>
                              </div>
                            ) : null}
                            <div className="custom-form-inline label-left justify-content-center">
                              <div className="flex-20 mt30"><Heading size={6}>OR</Heading></div>
                            </div>
                            <div className="w-100 flex-100 d-flex align-items-center">
                              <div
                                style={{
                                  padding: '10px',
                                  borderRadius: '5px',
                                  border: '1px solid #eeeeee',
                                  marginTop: '5px',
                                }}
                                className="flex-90 mr10 custom-form-inline label-left"
                              >
                                <Label
                                  labelTitle="Source Dies Filter"
                                  labelPosition="left-middle"
                                  labelSize="20"
                                  fieldSize="70"
                                  errorSize="10"
                                >
                                  <div
                                    className="ml15"
                                  >
                                    <CodeEditor
                                      rows={1}
                                      defaultValue={sourceDieFilter}
                                      value={sourceDieFilter}
                                      placeHolder="Filter Dies"
                                      matchingCriterion={conditionalProperties}
                                      updateOnChange={(value: string) => {
                                        this.updateSourceDieFilter(value);
                                      }}
                                    />
                                  </div>
                                </Label>
                              </div>
                            </div>
                            <div className="custom-form-inline label-left">
                              <div className="flex-20 mt30"><Heading size={6}>Target Die Configuration</Heading></div>
                            </div>
                            {targetDieConfiguration}
                            {/* <ExpressionBuilder */}
                            {/*  onUpdate={(e) => {}} */}
                            {/*  rules={{ */}
                            {/*    objects: [ */}
                            {/*      { */}
                            {/*        caption: 'Bin Map Type', dataField: 'binMapType', dataType: 'dropdown', values: ['soft', 'hard'], */}
                            {/*      }, */}
                            {/*      { */}
                            {/*        caption: 'Die Execution Status', dataField: 'executionStatus', dataType: 'dropdown', values: ['pass', 'fail'], */}
                            {/*      }, */}
                            {/*      { */}
                            {/*        caption: 'Soft Bin Number', dataField: 'softBinNumber', dataType: 'number', */}
                            {/*      }, */}
                            {/*      { */}
                            {/*        caption: 'Hard Bin Number', dataField: 'hardBinNumber', dataType: 'number', */}
                            {/*      }, */}
                            {/*      { caption: 'X', dataField: 'x', dataType: 'number' }, */}
                            {/*      { caption: 'Y', dataField: 'y', dataType: 'number' }, */}
                            {/*    ], */}
                            {/*    operators: [ */}
                            {/*      { caption: '=', dataField: '==' }, */}
                            {/*      { caption: '<', dataField: '<' }, */}
                            {/*      { caption: '>', dataField: '>' }, */}
                            {/*      { caption: '!=', dataField: '!=' }, */}
                            {/*    ], */}
                            {/*    typeOperations: [ */}
                            {/*      { dataType: 'string', operators: ['==', '!='] }, */}
                            {/*    ], */}
                            {/*  }} */}
                            {/* /> */}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid>
                <Row className="mt30 p0 pb60">
                  <Col lg={9}>
                    <Button
                      type="submit"
                      variant={this.isFormFieldsValid(submitCount, errors, selectedTestParameters) ? 'danger' : 'primary'}
                      size="lg"
                      className="mr10"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={closeRuleComponent}
                      variant="clear"
                      size="lg"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Formik>
      </div>
    ) : (
      <div className="w-100 height-100vh d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-between">
          <Spinner animation="border" />
          <span className="p20">
            Fetching Rule Data
          </span>
        </div>
      </div>
    );
  }
}

export default CreatePATRule;
