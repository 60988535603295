/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import _ from 'lodash';
import toast from 'CustomToast';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
// eslint-disable-next-line no-unused-vars
import HomeDieCoordsForm, { HomeDieCoordinateSystemFormData } from 'components/wafer-control-map/home-die-coords-form';
import WaferUtils from '../Utils/WaferUtils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import {
  MarkingMode, SelectionMode,
} from '../../wafer-map/web-gl-utils/Enums';
import {
  RectCoord, ReticleGridRectCoords, ZoneData, ReticleData,
} from '../../wafer-map/web-gl-utils/Types';
import { DieData, Dies } from '../Utils/WaferMapVariablesClassV2';
import { InteractionType } from './Events';
import { COORDINATE_SYSTEMS } from '../../wafer-map/WaferMap';
import { ActionType } from '../WaferPlotter/WaferPlotter';
import { MarkedDieTypeData } from '../types';

const handleReticleUnderSelection = (arrayIndices: RectCoord, reticleGridRectCoords: ReticleGridRectCoords, colOffset: number, rowOffset: number, value: boolean) => {
  const reticles = Object.keys(reticleGridRectCoords);
  for (let i = 0; i < reticles.length; i += 1) {
    const reticleCoords: RectCoord = JSON.parse(reticles[i]);
    if (
      arrayIndices.endX + colOffset >= reticleCoords.startX
      && arrayIndices.startX + colOffset <= reticleCoords.endX
      && arrayIndices.endY + rowOffset >= reticleCoords.startY
      && arrayIndices.startY + rowOffset <= reticleCoords.endY
    ) {
      reticleGridRectCoords[reticles[i]].isUnderSelection = value;
    }
  }
};

export const selectionMouseMove = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
  if (data && data.interactionType !== InteractionType.Select) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  if (!isMouseDown || !waferUtil.waferMapVariables.isSelectionControlActive) return;

  const {
    startRow, startCol, rowDirection, colDirection, waferData, selectionMode, reticleGridRectCoords, colOffset, rowOffset,
  } = waferUtil.waferMapVariables;

  const arrayIndices = waferUtil.getBoxArrayIndicesFromCanvasCoords({
    startX, startY, endX, endY,
  });

  if (selectionMode === SelectionMode.RETICLE) {
    handleReticleUnderSelection(arrayIndices, reticleGridRectCoords, colOffset, rowOffset, true);
  } else if (selectionMode === SelectionMode.DIE) {
    UtilityFunctions.markDieUnderSelection(arrayIndices, true, startRow, startCol, rowDirection, colDirection, waferData);
  }

  waferUtil.renderWafer();

  if (selectionMode === SelectionMode.RETICLE) {
    handleReticleUnderSelection(arrayIndices, reticleGridRectCoords, colOffset, rowOffset, false);
  } else if (selectionMode === SelectionMode.DIE) {
    UtilityFunctions.markDieUnderSelection(arrayIndices, false, startRow, startCol, rowDirection, colDirection, waferData);
  }
};

const isWATPCMNumberAlreadyMarked = (reticleGridRectCoords: ReticleGridRectCoords, watPCMSiteNumber: number | null) => {
  const reticles = Object.keys(reticleGridRectCoords);
  for (let i = 0; i < reticles.length; i += 1) {
    if (watPCMSiteNumber !== null && reticleGridRectCoords[reticles[i]].watPCMSiteNumber === watPCMSiteNumber) {
      return true;
    }
  }
  return false;
};

const markWATPCMSiteNumber = (reticle: ReticleData, currentWatPCMSiteNumber: number | null, waferUtil: WaferUtils) => {
  reticle.watPCMSiteNumber = currentWatPCMSiteNumber;
  if (waferUtil.waferMapVariables.onWATPCMNumberMarked) waferUtil.waferMapVariables.onWATPCMNumberMarked(waferUtil.waferMapVariables.keyIndex);
  return currentWatPCMSiteNumber !== null;
};

const highlightSelectedReticlesOnMouseUp = async (arrayIndices: RectCoord, waferUtil: WaferUtils) => {
  const {
    reticleGridRectCoords, colOffset, rowOffset, isMarkingControlActive, currentWatPCMSiteNumber,
    showReticleText, reticleTextField, currentWatTestMethod, markingMode, waferData,
    currentZoneId, freeFormZonePCMSiteNumber, zones,
  } = waferUtil.waferMapVariables;

  const orignalReticleGridRectCoords = _.cloneDeep(reticleGridRectCoords);
  const reticles = Object.keys(reticleGridRectCoords);
  let overrideAllowed = false;

  const canSampleWatPCMSites = currentWatTestMethod === 'sampling' && markingMode === MarkingMode.WAT_PCM_SITE_NUMBER && showReticleText && reticleTextField === 'watPCMSiteNumber';

  if (isMarkingControlActive && canSampleWatPCMSites && isWATPCMNumberAlreadyMarked(reticleGridRectCoords, currentWatPCMSiteNumber)) {
    toast.warn(`PCM site number ${currentWatPCMSiteNumber} is already marked`);
    return;
  }

  for (let i = 0; i < reticles.length; i += 1) {
    const reticleCoords: RectCoord = JSON.parse(reticles[i]);
    if (
      arrayIndices.endX + colOffset >= reticleCoords.startX
      && arrayIndices.startX + colOffset <= reticleCoords.endX
      && arrayIndices.endY + rowOffset >= reticleCoords.startY
      && arrayIndices.startY + rowOffset <= reticleCoords.endY
    ) {
      if (!isMarkingControlActive) {
        reticleGridRectCoords[reticles[i]].isSelected = !reticleGridRectCoords[reticles[i]].isSelected;
      } else {
        if (canSampleWatPCMSites) {
          if (reticleGridRectCoords[reticles[i]].watPCMSiteNumber === null || reticleGridRectCoords[reticles[i]].watPCMSiteNumber === currentWatPCMSiteNumber) {
            if (markWATPCMSiteNumber(reticleGridRectCoords[reticles[i]], currentWatPCMSiteNumber, waferUtil)) return;
          } else {
            if (!overrideAllowed) {
              // eslint-disable-next-line no-await-in-loop
              if (await ModalPopup.confirm({
                header: 'Override Alert',
                body: 'This action will override WAT / PCM sites in some reticles. Do you want to proceed?',
              })) {
                overrideAllowed = true;
                if (markWATPCMSiteNumber(reticleGridRectCoords[reticles[i]], currentWatPCMSiteNumber, waferUtil)) return;
              } else {
                waferUtil.waferMapVariables.reticleGridRectCoords = orignalReticleGridRectCoords;
                break;
              }
            } else {
              if (markWATPCMSiteNumber(reticleGridRectCoords[reticles[i]], currentWatPCMSiteNumber, waferUtil)) return;
            }
          }
        }
        const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
        if (markingMode === MarkingMode.ZONE_SITE && filteredZones.length !== 0 && filteredZones[0].zoneType === 'FREE_FORM') {
          for (let l = reticleCoords.startY - rowOffset; l <= reticleCoords.endY - rowOffset; l += 1) {
            for (let m = reticleCoords.startX - colOffset; m <= reticleCoords.endX - colOffset; m += 1) {
              if (waferData.dies[l] && waferData.dies[l][m]) {
                if (!waferData.dies[l][m]!.zoneInfo) {
                  waferData.dies[l][m]!.zoneInfo = {};
                }
                if (freeFormZonePCMSiteNumber !== null) {
                  waferData.dies[l][m]!.zoneInfo![currentZoneId] = [freeFormZonePCMSiteNumber];
                } else {
                  delete waferData.dies[l][m]!.zoneInfo![currentZoneId];
                }
              }
            }
          }
        }
      }
    }
  }
};

const isHomeDieAlreadyMarked = (dies: Dies, homeDieId: string | null) => {
  if (!homeDieId) return { homeDie: null };
  for (let i = 0; i < dies.length; i += 1) {
    for (let j = 0; dies[i] && j < dies[i].length; j += 1) {
      if (dies[i][j] && dies[i][j]!.dieType === homeDieId) {
        return { homeDie: dies[i][j], i, j };
      }
    }
  }
  return { homeDie: null };
};

const onHomeDieMarked = async (waferUtil: WaferUtils, dieData: DieData) => {
  const {
    isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled, reticleXAxisReference, reticleSize, reticleYAxisReference,
    colAxisDirection, rowAxisDirection,
  } = waferUtil.waferMapVariables;
  const coordinateSystems = [];
  if (isDieCoordinatesSystemEnabled) coordinateSystems.push(COORDINATE_SYSTEMS.STANDARD);
  if (isReticleCoordinatesSystemEnabled) coordinateSystems.push(COORDINATE_SYSTEMS.RETICLE);

  let reticleRow = Math.floor(Math.abs(dieData.y! - reticleYAxisReference) / reticleSize.y);
  if (dieData.y! < reticleYAxisReference) {
    reticleRow = -1 * (reticleRow + 1);
  }
  let reticleCol = Math.floor(Math.abs(dieData.x! - reticleXAxisReference) / reticleSize.x);
  if (dieData.x! < reticleXAxisReference) {
    reticleCol = -1 * (reticleCol + 1);
  }

  const oldHomeDieFormData = {
    coordinateSystems,
    standardX: dieData.xTrue!,
    standardY: dieData.yTrue!,
    row: reticleRow,
    col: reticleCol,
    subRow: dieData.reticleY!,
    subCol: dieData.reticleX!,
    waferAxisDirection: UtilityFunctions.getWaferAxisDirection(colAxisDirection, rowAxisDirection),
  };
  // eslint-disable-next-line no-await-in-loop
  const result = await HomeDieCoordsForm.openForm({
    data: _.cloneDeep(oldHomeDieFormData),
    reticleSize,
  });
  const data = (result as (null | HomeDieCoordinateSystemFormData));
  if (data) {
    waferUtil.applyOffsetsToData(data, dieData);
    return { newHomeDieFormData: _.cloneDeep(data), oldHomeDieFormData }
  // eslint-disable-next-line no-else-return
  } else {
    return null;
  }
};

const revertMarkedDieTypeChanges = (markedDieTypeData: MarkedDieTypeData, waferUtil: WaferUtils) => {
  const {
    startRow, startCol, rowDirection, colDirection, waferData,
  } = waferUtil.waferMapVariables;
  for (let i = 0; i < markedDieTypeData.markedDies.length; i += 1) {
    const rIndex = startRow + rowDirection * markedDieTypeData.markedDies[i].r;
    const cIndex = startCol + colDirection * markedDieTypeData.markedDies[i].c;
    const dieData = waferData.dies[rIndex] === undefined ? undefined : waferData.dies[rIndex][cIndex];
    if (dieData && !dieData.isCropped && !dieData.isDeleted) {
      const changeKeys = Object.keys(markedDieTypeData.markedDies[i].changes);
      for (let j = 0; j < changeKeys.length; j += 1) {
        dieData[changeKeys[j]] = markedDieTypeData.markedDies[i].changes[changeKeys[j]].prevVal;
      }
    }
  }
}

const markDiesOnMouseUp = async (arrayIndices: RectCoord, waferUtil: WaferUtils) => {
  const {
    startRow, startCol, rowDirection, colDirection, waferData, actionHandler,
    selectedDieField, dieTypeField, dieTypes, currentDieType, keyIndex,
  } = waferUtil.waferMapVariables;

  const fieldName = selectedDieField ?? dieTypeField;
  let overrideAllowed = false;
  let isFirstDieMarked = false;
  const markedDieData: DieData[] = [];

  let nameOfCurrentDieType = currentDieType;
  if (fieldName === 'dieType') {
    const dt = UtilityFunctions.getDieTypeFromId(String(currentDieType), dieTypes.dieType);
    nameOfCurrentDieType = dt === null ? '' : dt.name;
  }

  let homeDie: DieData | null = null;
  let homeDieId = null;
  let orignalHomeDie = null; // assigned when home die was already marked and now user wants to mark (override) home die some where else
  let orignalHomeDieMatrixIndex = null; // assigned when home die was already marked and now user wants to mark (override) home die some where else
  const markedDieTypeData: MarkedDieTypeData = { markedDies: [] };

  if (dieTypes && dieTypes.dieType) homeDieId = UtilityFunctions.getDieTypeIdFromName('Home Die', dieTypes.dieType);
  if (dieTypes && dieTypes.dieType && homeDieId && currentDieType === homeDieId) {
    const unAssignedDieId = UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType);
    const homeDieData = isHomeDieAlreadyMarked(waferData.dies, homeDieId);
    homeDie = homeDieData.homeDie;
    if (homeDie && await ModalPopup.confirm({
      header: 'Override Alert',
      body: 'This action will override the home die. Do you want to proceed?',
    })) {
      homeDie.dieType = unAssignedDieId || '';
      orignalHomeDie = homeDie;
      orignalHomeDieMatrixIndex = { i: homeDieData.i!, j: homeDieData.j! };
      homeDie = null;
    }
  }

  for (let r = arrayIndices.startY; r <= arrayIndices.endY; r += 1) {
    for (let c = arrayIndices.startX; c <= arrayIndices.endX; c += 1) {
      const rIndex = startRow + rowDirection * r;
      const cIndex = startCol + colDirection * c;
      const dieData = waferData.dies[rIndex] === undefined ? undefined : waferData.dies[rIndex][cIndex];

      if (dieData && !dieData.isCropped && !dieData.isDeleted) {
        if (homeDie) {
          toast.warn('Home die is already marked');
          return;
        }

        if (isFirstDieMarked && homeDieId && currentDieType === homeDieId) {
          if (actionHandler) {
            // eslint-disable-next-line no-await-in-loop
            await actionHandler(ActionType.PERFORM, keyIndex, {
              actionDoMessage: `Unmark ${UtilityFunctions.fromCamelToNormalCase(fieldName)} as ${nameOfCurrentDieType}`,
              actionUndoMessage: `Mark "${nameOfCurrentDieType}" ${UtilityFunctions.fromCamelToNormalCase(fieldName)}`,
            });
          }
          return;
        }
        if (currentDieType == null) {
          toast.warning('No die type selected.');
          return;
        }
        const dieTypeId = dieData[fieldName];
        const prevPassFailFlag = dieData.passFailFlag;
        const isDieTypeNotUnassigned = (fieldName !== 'dieType' || (fieldName === 'dieType' && UtilityFunctions.getDieTypeFromId(String(dieTypeId), dieTypes.dieType)?.name !== 'Unassigned'));
        const differentFieldSelected = dieTypeId !== currentDieType;

        if (!overrideAllowed && differentFieldSelected && isDieTypeNotUnassigned) {
          // eslint-disable-next-line no-await-in-loop
          if (!await ModalPopup.confirm({
            header: 'Override Alert',
            body: 'This action will override die types in some dies. Do you want to proceed?',
          })) {
            revertMarkedDieTypeChanges(markedDieTypeData, waferUtil);
            if (orignalHomeDie) orignalHomeDie.dieType = homeDieId;
            return;
          }
          overrideAllowed = true;
        }

        const diePrevData = _.cloneDeep(dieData);
        dieData[fieldName] = currentDieType;
        if (fieldName === 'hardBin' || fieldName === 'softBin') dieData.passFailFlag = dieTypes[fieldName].find((x: any) => x.id === currentDieType)?.passFail;

        markedDieData.push(_.cloneDeep(dieData));
        const markedDieTypeDataDie = {
          r,
          c,
          changes: {
            [fieldName]: { prevVal: dieTypeId, newVal: currentDieType },
            passFailFlag: { prevVal: prevPassFailFlag, newVal: dieData.passFailFlag },
          }
        };

        isFirstDieMarked = true;
        if (homeDieId && currentDieType === homeDieId) {
          // eslint-disable-next-line no-await-in-loop
          const homeDieFormData = await onHomeDieMarked(waferUtil, dieData);
          if (homeDieFormData === null) {
            dieData[fieldName] = dieTypeId;
            if (orignalHomeDie) orignalHomeDie.dieType = homeDieId;
            return;
          // eslint-disable-next-line no-else-return
          } else {
            markedDieTypeData.newHomeDieFormData = homeDieFormData.newHomeDieFormData;
            markedDieTypeData.oldHomeDieFormData = homeDieFormData.oldHomeDieFormData;
            markedDieTypeData.dieNewData = _.cloneDeep(dieData);
            markedDieTypeData.diePrevData = diePrevData;
            if (orignalHomeDie && orignalHomeDieMatrixIndex) markedDieTypeData.oldHomeDieMatrixIndices = orignalHomeDieMatrixIndex;
          }
        }
        markedDieTypeData.markedDies.push(markedDieTypeDataDie);
      }
    }
  }
  PublishSubscribe().publishWithOthersID(EventTypes.DIES_MARKED_ON_WAFER, {
    markedDieData,
    fieldName,
    currentDieType,
    colOffset: waferUtil.waferMapVariables.colOffset,
    rowOffset: waferUtil.waferMapVariables.rowOffset,
  }, keyIndex);

  if (actionHandler) {
    await actionHandler(ActionType.PERFORM, keyIndex, {
      actionDoMessage: `Unmark ${UtilityFunctions.fromCamelToNormalCase(fieldName)} as ${nameOfCurrentDieType}`,
      actionUndoMessage: `Mark "${nameOfCurrentDieType}" ${UtilityFunctions.fromCamelToNormalCase(fieldName)}`,
    });
  }
};

const getDieSelectionMessage = (startRow: number, startCol: number, rowDirection: number, colDirection: number, arrayIndices: RectCoord, selectedDataLength: number, unSelectedDataLength: number) => {
  const msg = `from (${startCol + colDirection * arrayIndices.startX}, ${startRow + rowDirection * arrayIndices.startY})
      to (${startCol + colDirection * arrayIndices.endX}, ${startRow + rowDirection * arrayIndices.endY})`;
  let actionDoMessage = `Toggle selection ${msg}`;
  let actionUndoMessage = actionDoMessage;
  if (selectedDataLength !== 0 && unSelectedDataLength === 0) {
    actionDoMessage = `Unselect data ${msg}`;
    actionUndoMessage = `Select data ${msg}`;
  } else if (selectedDataLength === 0 && unSelectedDataLength !== 0) {
    actionDoMessage = `Select data ${msg}`;
    actionUndoMessage = `Unselect data ${msg}`;
  }
  return { actionDoMessage, actionUndoMessage };
}

const highlightSelectedDiesOnMouseUp = async (arrayIndices: RectCoord, waferUtil: WaferUtils) => {
  const {
    onSelectionChanged, keyIndex, actionHandler, startRow, startCol, rowDirection, colDirection,
  } = waferUtil.waferMapVariables;
  const { selectedData, unSelectedData } = waferUtil.toggleSelectionOnWaferFromArrayIndices(arrayIndices, true);
  PublishSubscribe().publishWithOthersID(EventTypes.SELECTION_ON_WAFER, {
    selectedData,
    unSelectedData,
  }, keyIndex);
  if (actionHandler !== undefined) {
    const { actionDoMessage, actionUndoMessage } = getDieSelectionMessage(startRow, startCol, rowDirection, colDirection, arrayIndices, selectedData.length, unSelectedData.length);
    await actionHandler(ActionType.PERFORM, keyIndex, {
      actionDoMessage,
      actionUndoMessage,
    });
  }
  if (onSelectionChanged) onSelectionChanged(keyIndex, selectedData, unSelectedData);
};

export const selectionMouseUp = async (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils) => {
  if (!waferUtil.waferMapVariables.isSelectionControlActive) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  const { selectionMode } = waferUtil.waferMapVariables;

  const arrayIndices = waferUtil.getBoxArrayIndicesFromCanvasCoords({
    startX, startY, endX, endY,
  });

  if (selectionMode === SelectionMode.RETICLE) {
    await highlightSelectedReticlesOnMouseUp(arrayIndices, waferUtil);
  } else if (selectionMode === SelectionMode.DIE) {
    if (waferUtil.waferMapVariables.isMarkingControlActive) {
      await markDiesOnMouseUp(arrayIndices, waferUtil);
    } else {
      highlightSelectedDiesOnMouseUp(arrayIndices, waferUtil);
    }
  }
  waferUtil.renderWafer();
};
