import React from 'react';

// import Graph from "./graph";

interface IReportCard {
  title: string;
  className: string;
  children: any;
}

const ReportCard = (props: IReportCard) => {
  const { title, className, children } = props;

  return (
    <div className={className}>
      <label>
        <h1>{title}</h1>
      </label>
      {children}
    </div>
  );
};

export default ReportCard;
