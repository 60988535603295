/* eslint-disable no-param-reassign */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import { InteractionType } from './Events';
// eslint-disable-next-line no-unused-vars
import WaferUtils from '../Utils/WaferUtils';
import { DieData } from '../Utils/WaferMapVariablesClassV2';

let hoverDelayTimeout: number;

export const hideTooltip = (tooltipRef: HTMLDivElement) => {
  clearTimeout(hoverDelayTimeout);
  tooltipRef.style.display = 'none';
};

export const tooltipMouseMove = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
  if ((data && data.interactionType !== InteractionType.Tooltip) || !waferUtil.waferMapVariables.gl) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  const {
    startRow, rowDirection, startCol, colDirection, waferData, tooltipHoverTime,
  } = waferUtil.waferMapVariables;

  const translatedCoords = UtilityFunctions.getTranslatedCoords({
    startX, startY, endX, endY,
  }, waferUtil.waferMapVariables.innerViewPort, waferUtil.waferMapVariables.gl.canvas.height);

  const arrayIndices = waferUtil.getBoxArrayIndicesFromCanvasCoords({
    startX: translatedCoords.startX, startY: translatedCoords.startY, endX: translatedCoords.endX, endY: translatedCoords.endY,
  });
  if (data && data.tooltipRef) {
    const rIndex = startRow + rowDirection * arrayIndices.endY;
    const cIndex = startCol + colDirection * arrayIndices.endX;
    if (waferData.dies[rIndex] && waferData.dies[rIndex][cIndex]) {
      hideTooltip(data.tooltipRef);
      hoverDelayTimeout = setTimeout((tooltipRef: HTMLDivElement, startX_: number, startY_: number, waferUtil_: WaferUtils, die: DieData) => {
        tooltipRef.style.left = `${startX_ - 100}px`; // half of the width of the tooltip div in wafer plotter
        tooltipRef.style.top = `${startY_ + 30}px`;
        tooltipRef.innerHTML = waferUtil_.getTooltipContent(die);
        tooltipRef.style.display = 'block';
      }, tooltipHoverTime * 1000, data.tooltipRef, startX, startY, waferUtil, waferData.dies[rIndex][cIndex]);
    } else {
      hideTooltip(data.tooltipRef);
    }
  }
};
