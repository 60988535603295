import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpDeviceSetup extends HttpBase {
  getDeviceSetup = (id: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/device-setups/${id}`);
  };

  getDeviceSetups = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/device-setups/`);
  };

  postDeviceSetup = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/device-setups/`, data);
  };

  postDSWorkCenter = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/device-setup-work-centers/`, data);
  };

  copyDSWorkCenter = (id: string, data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/device-setup-work-centers/${id}`, data);
  };

  putDSWorkCenter = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/device-setup/device-setup-work-centers/`, data);
  };

  getDSWorkCenter = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/device-setup-work-centers/`);
  };

  deleteDSWorkCenter = (id: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/device-setup/device-setup-work-centers/${id}`);
  };

  putDeviceSetup = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/device-setup/device-setups/`, data);
  };

  removeDeviceSetup = (id: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/device-setup/device-setups/${id}`);
  };

  postGeneralAttribute = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/general-attribute/`, data);
  };

  postGeneralAttributeDefinition = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/general-attribute-definition/`, data);
  };

  postWCAttributeDefinition = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/device-setup/work-center-attribute-definition/`, data);
  };

  putGeneralAttributeDefinition = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/device-setup/general-attribute-definition/`, data);
  };

  deleteGeneralAttributeDefinition = (id: any): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/device-setup/general-attribute-definition/${id}`);
  };

  getGeneralAttributeDefinitions = (deviceSetupId: any): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/general-attribute-definition/${deviceSetupId}`);
  };

  getDSWCAttributeDefinitions = (deviceSetupId: any): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/work-center-attribute-definition/${deviceSetupId}`);
  };

  deleteGeneralAttribute = (id: string, checkUsage: boolean): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/device-setup/general-attribute/${id}?checkUsage=${checkUsage}`);
  };

  deleteWorkCenterAttribute = (attributeId: string, workCenterId: string, checkUsage: boolean): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/device-setup/work-center-attribute/${attributeId}/${workCenterId}?checkUsage=${checkUsage}`);
  };

  getGeneralAttributes = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/general-attributes/`);
  };

  getDSWCAttributes = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/device-setup/work-center-attributes/`);
  };
}

const httpDeviceSetup = new HttpDeviceSetup();
export { httpDeviceSetup };
