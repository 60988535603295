import React from 'react';
import './real-time-stats.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, } from '@fortawesome/free-solid-svg-icons';
import ScrollView from 'devextreme-react/scroll-view';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';

interface IRealTimeStatsProps {
  title: string;
  subTitle?: string;
  showTimePeriodFilter?: boolean;
  statsList?: {
    name: string;
    value: number;
  }[];
  stat?: string;
  icon: any;
  selectedTimespan?: string;
  onTimespanUpdate?: (value: string) => void;
}

const RealTimeStatsGraphCard = (props: IRealTimeStatsProps) => {
  const {
    title,
    subTitle,
    showTimePeriodFilter,
    statsList,
    stat,
    icon,
    selectedTimespan,
    onTimespanUpdate,
  } = props;
  return (
    <div className="mb20 stats stats-card border-all pt10 pb10 pl20 pr20 rounded background-color-light">
      <div className="d-flex justify-content-between">
        <div className="flex-90">
          <p className="card-text mb0">
            {title}
          </p>
          {subTitle !== '' && <p className="color-disabled-color mt5">{subTitle}</p>}
          {showTimePeriodFilter && (
            <div
              className="card-sub-text ml-12"
            >
              <CustomizedDropdown
                variant="clear"
                selectedValue={selectedTimespan}
                onChange={(value: string) => {
                  if (onTimespanUpdate !== undefined) {
                    onTimespanUpdate(value);
                  }
                }}
                list={[
                  ['today', 'Today'],
                  ['yesterday', 'Yesterday'],
                  ['lastWeek', 'Last Week'],
                  ['lastMonth', 'Last Month'],
                  ['lastYear', 'Last Year'],
                ]}
              />
            </div>
          )}
        </div>
        <div className="mt10 flex-10 text-right">
          <FontAwesomeIcon
            className="icon"
            size="sm"
            icon={faEllipsisH}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mb20">
        {(statsList!.length === 0 && stat !== '')
          ? (
            <p className="stat m0">{stat}</p>
          )
          : (
            <p className="stat m0">
              {' '}
              {statsList!.length === 0 ? 0 : statsList!.map((x: any) => parseInt(x.value, 0))
                .reduce((accumulator: number, currentValue: number) => accumulator + currentValue)}
            </p>
          )}
        <div className="mt10">
          <FontAwesomeIcon
            className="stat-icon"
            size="lg"
            icon={icon}
          />
        </div>
      </div>
      {statsList!.length > 0
                && (
                  <div className="h200 pr10">
                    <ScrollView
                      showScrollbar="always"
                      scrollByThumb
                      reachBottomText="Updating..."
                    >
                      {statsList!.map((item) => (
                        <div className="d-flex align-items-center justify-content-between pt10 pb10 break-top">
                          <p className="m0 stat-list-name">{item.name}</p>
                          <p className="m0 stat-list-value">{item.value}</p>
                        </div>
                      ))}
                    </ScrollView>
                  </div>
                )}
    </div>
  );
};
RealTimeStatsGraphCard.defaultProps = {
  stat: '',
  subTitle: '',
  showTimePeriodFilter: false,
  statsList: [],
  selectedTimespan: 'today',
  onTimespanUpdate: undefined,
};
export default RealTimeStatsGraphCard;
