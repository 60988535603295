/* eslint-disable */
import React, {Component} from 'react';
import Plot from 'react-plotly.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import plotly from 'plotly.js';
import GeneralUtils from 'GeneralUtils';
import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import _ from 'lodash';
import {DEFAULT_FONTS} from './lib/constants';
import EditorControls from './EditorControls';

class PlotlyEditor extends Component {
  AXIS_BREAK_SCALING_FACTOR_DENOMINATOR = 0.09;
  modeBarButtonsToUnhighlight = ['Show/Hide Designer', 'X-Axis Break', 'Y-Axis Break'];

  constructor(props) {
    super();
    this.state = {
      graphDiv: {},
      hideControls: true,
      xAxisBreak: false,
      yAxisBreak: false,
      originalData: _.cloneDeep(props.data),
      originalLayout: _.cloneDeep(props.layout),
      originalDataAfterAlternateAxisBreak: undefined,
      originalLayoutAfterAlternateAxisBreak: undefined,
    };
    this.handleRender = this.handleRender.bind(this);
    this.toggleEditor = this.toggleEditor.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { data, layout } = this.props;
    if (prevProps !== this.props && layout && layout.shapes && layout.shapes.filter((x) => x.key && x.key.includes('AxisBreak')).length === 0) {
      const { yAxisBreak, xAxisBreak } = this.state;
      const originalData = _.cloneDeep(data);
      const originalLayout = _.cloneDeep(layout);
      this.setState({ originalLayout, originalData }, () => {
        if (yAxisBreak) {
          this.changeBreaksEnabledState({ value: true }, 'y');
        }
        if (xAxisBreak) {
          this.changeBreaksEnabledState({ value: true }, 'x');
        }
      });
    }
  };

  handleRender(_fig, graphDiv) {
    this.setState({ graphDiv });
    if (this.props.onRender) {
      this.props.onRender(
        graphDiv.data,
        graphDiv.layout,
        graphDiv._transitionData._frames,
      );
    }
  }

  applyAxisBreakOuter = (points2D, minAndMaxForOtherAxis, axis, graphIndex) => {
    const { data } = this.props;
    const graphType = data.length > 0 ? data[0].type : '';
    if (graphType === 'box') {
      points2D = points2D.filter((elem) => Array.isArray(elem));
    }
    const flattenedPoints = [].concat(
      ...(points2D === undefined ? [] : points2D),
    );
    const retval = this.applyAxisBreak(
      flattenedPoints.map((item) => item),
      minAndMaxForOtherAxis,
      axis,
      graphIndex,
    );
    if (!retval) return;
    const newOrderedPoints = [];
    for (let i = 0; i < points2D.length; i += 1) {
      newOrderedPoints.push(
        retval.orderedDataPoints.splice(
          0,
          points2D[i] !== null ? points2D[i].length : undefined,
        ),
      );
    }
  };

  createOriginalIndicesMap = (points) => {
    return points.map((e, i) => ({ ind: i, val: e }));
  };

  sortPointsAscending = ([...points]) => {
    points.sort((x, y) => (x.val > y.val ? 1 : x.val === y.val ? 0 : -1));
    return points;
  };

  roundS = (v) => {
    if (v < 1) {
      const y = 10 ** Math.ceil(Math.log10(v));
      return Math.ceil(v / y) * y;
    }
    const y = 10 ** Math.floor(Math.log10(v));
    return Math.ceil(v / y) * y;
  };

  fetchElementsFromSortedMap = (sortedDataMap) => {
    const sortedData = sortedDataMap.map((ele) => ele.val);
    const sortedDataWithoutNulls = sortedData.filter((ele) => ele !== null);
    return [
      sortedDataWithoutNulls,
      sortedData.length - sortedDataWithoutNulls.length,
    ];
  };

  calculateDeltaThreshold = (sortedDataSource, sortedDataMap, limitPointsIndexes) => {
    const { axisBreakThresholdFactor } = this.props;
    const excludedIndexesForSortedDataSource = [];
    if (limitPointsIndexes.length > 0) {
      limitPointsIndexes.forEach((lpIndex) => {
        excludedIndexesForSortedDataSource.push(sortedDataMap.findIndex((x) => x.ind === lpIndex));
      });
      sortedDataSource = sortedDataSource.filter((x, index) => !excludedIndexesForSortedDataSource.includes(index));
    }
    const max = Math.max(...sortedDataSource);
    const min = Math.min(...sortedDataSource);
    if (sortedDataSource.length === 0 || max === min) {
      return 1;
    }
    return axisBreakThresholdFactor / 100 * (max - min);
  };

  findAxisBreaks = (sortedDataSource, sortedDataMap, limitPointsIndexes) => {
    const breakPointIndexes = [];
    let axisBreakFound = true;
    let axisBreakFoundAtLeastOnce = false;
    let numOfPasses = 1;

    // This while loop can be used for recursive kind of axis breaks application -- if needed
    while (axisBreakFound && numOfPasses <= 2) {
      numOfPasses += 1;
      const originalSortedDataSource = [...sortedDataSource];
      axisBreakFound = false;
      const deltaThreshold = this.calculateDeltaThreshold(originalSortedDataSource, sortedDataMap, limitPointsIndexes);

      for (let i = 0; i < sortedDataSource.length - 1; i += 1) {
        const delta = sortedDataSource[i + 1] - originalSortedDataSource[i];

        if (delta >= deltaThreshold) {
          axisBreakFound = true;
          axisBreakFoundAtLeastOnce = true;
          let space = Math.abs(this.AXIS_BREAK_SCALING_FACTOR_DENOMINATOR * (sortedDataSource[i] - sortedDataSource[0]));
          if (space === 0) {
            space = Math.abs(this.AXIS_BREAK_SCALING_FACTOR_DENOMINATOR * (sortedDataSource[i + 1] - sortedDataSource[0]));
          }
          if (!breakPointIndexes.includes(i)) {
            breakPointIndexes.push(i);
          }
          sortedDataSource[i + 1] = Number(GeneralUtils.roundNumberAppropriately(Number(sortedDataSource[i] + space), 3));
        } else {
          sortedDataSource[i + 1] = Number(GeneralUtils.roundNumberAppropriately(Number(sortedDataSource[i] + delta), 3));
        }
      }
    }
    return [sortedDataSource, axisBreakFoundAtLeastOnce, breakPointIndexes];
  };

  getMaxAndMinXAxisValue = (sortedDataSource, minAndMaxForOtherAxis) => {
    const { reportActor } = this.props;
    let xMin = 0;
    let xMax = 0;
    if (reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) {
      xMin = minAndMaxForOtherAxis[0];
      xMax = minAndMaxForOtherAxis[1];
    } else if (reportActor === ReportType.PARAMETRIC_BOX_PLOT) {
      xMin = -0.25;
      xMax = -0.55;
    } else {
      xMin = 0;
      xMax = sortedDataSource.length-1;
    }
    xMin = xMin === undefined ? 0 : xMin;
    xMax = xMax === undefined ? 0 : xMax;
    return [xMin, xMax];
  }

  calculateAxisBreakLines = (sortedDataSource, breakPointIndexes, minAndMaxForOtherAxis, axis, graphIndex) => {
    const { isOneGraph } = this.props;
    const axisBreakLine = [];
    const neededTickIndexes = [];
    const [xMin, xMax] = this.getMaxAndMinXAxisValue(sortedDataSource, minAndMaxForOtherAxis);
    // add axis break lines logic
    for (let i = 0; i < sortedDataSource.length; i += 1) {
      if (breakPointIndexes.includes(i)) {
        neededTickIndexes.push(i);
        neededTickIndexes.push(i + 1);
        axisBreakLine.push((sortedDataSource[i] + sortedDataSource[i + 1]) / 2);
      }
    }
    const renderAxisBreakLine = [];
    if (axis === 'y') {
      for (let i = 0; i < axisBreakLine.length; i += 1) {
        renderAxisBreakLine.push({
          key: 'yAxisBreak',
          type: 'line',
          xref: `x${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          yref: `y${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          x0: xMin,
          x1: xMax,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'grey',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'yAxisBreak',
          type: 'line',
          xref: `x${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          yref: `y${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          x0: xMin,
          x1: xMax,
          y0: axisBreakLine[i],
          y1: axisBreakLine[i],
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    } else if (axis === 'x') {
      for (let i = 0; i < axisBreakLine.length; i += 1) {
        renderAxisBreakLine.push({
          key: 'xAxisBreak',
          type: 'line',
          xref: `x${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'grey',
            width: 7,
            dash: 'dot',
          },
        });
        renderAxisBreakLine.push({
          key: 'xAxisBreak',
          type: 'line',
          xref: `x${GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph)}`,
          yref: 'paper',
          x0: axisBreakLine[i],
          x1: axisBreakLine[i],
          y0: 0,
          y1: 1,
          line: {
            color: 'white',
            width: 6,
          },
        });
      }
    }
    return [renderAxisBreakLine, neededTickIndexes];
  };

  reorderSortedDataPoints = (sortedDataMap, sortedDataSource, limitPointsIndexes) => {
    const unsortedArray = new Array(sortedDataMap.length - limitPointsIndexes.length).fill(null);
    const originalOrder = sortedDataMap.filter((e) => e.val !== null).map((ele) => ele.ind);
    originalOrder.forEach((ele, index) => {
      if (limitPointsIndexes.length === 0 || (limitPointsIndexes.length > 0 && !limitPointsIndexes.includes(ele))) {
        unsortedArray[ele] = sortedDataSource[index];
      }
    });
    return [sortedDataMap, unsortedArray];
  };

  makeNumberChar = (arr) => arr.map((val) => (val !== null ? val.toString() : ''));

  getExtremeValExcludingOutliers = (graphType, sortedDataSource, outlierIndexes) => {
    if (sortedDataSource.length === 0 || graphType === 'bar') {
      return 1;
    }
    const tempArray = [];
    sortedDataSource.forEach((element, index) => {
      if (!outlierIndexes.includes(index)) {
        tempArray.push(element);
      }
    });
    return Math.max(...tempArray);
  };

  insertAnnotationsToData = (points, layoutAnnotations, axis, annotationsOrientation, graphIndex) => {
    const { isOneGraph } = this.props;
    const limitPointsIndexes = [];
    const layoutIndexes = [];
    let requiredLines = [];
    requiredLines = layoutAnnotations.filter(
      (line) => line.key && line.key.includes(annotationsOrientation) && (isOneGraph || (!isOneGraph && (Number)(line.key.toString().split(' ')[1]) === graphIndex)),
    );
    layoutAnnotations.forEach((line, index) => {
      if (line.key && line.key.includes(annotationsOrientation) && (isOneGraph || (!isOneGraph && (Number)(line.key.toString().split(' ')[1]) === graphIndex))) {
        layoutIndexes.push(index);
      }
    });
    if (requiredLines) {
      requiredLines.forEach((line) => {
        points.push(line[axis]);
        limitPointsIndexes.push(points.length - 1);
      });
    }
    return [points, limitPointsIndexes, layoutIndexes];
  };

  updateGroupingLabelsShapes = (layoutShapes, sortedDataMap, sortedDataSource, limitPointsIndexes, index) => {
    let groupingLabelStart = 0;
    let groupingLabelEnd = 0;
    for (let i = 0; i < sortedDataMap.length; i++) {
      if (!limitPointsIndexes.includes(sortedDataMap[i].ind)) {
        groupingLabelStart = sortedDataSource[i];
        break;
      }
    }
    for (let i = sortedDataMap.length - 1; i >= 0; i--) {
      if (!limitPointsIndexes.includes(sortedDataMap[i].ind)) {
        groupingLabelEnd = sortedDataSource[i];
        break;
      }
    }
    const indexStart = layoutShapes.findIndex((x) => x.key === `xAxisGroupingLabelStart${index}`);
    if (indexStart !== -1) {
      layoutShapes[indexStart].x0 = groupingLabelStart;
      layoutShapes[indexStart].x1 = groupingLabelStart;
    }
    const indexEnd = layoutShapes.findIndex((x) => x.key === `xAxisGroupingLabelEnd${index}`);
    if (indexEnd !== -1) {
      layoutShapes[indexEnd].x0 = groupingLabelEnd;
      layoutShapes[indexEnd].x1 = groupingLabelEnd;
    }
    const indexStartToEnd = layoutShapes.findIndex((x) => x.key === `xAxisGroupingLabelStartToEnd${index}`);
    if (indexStartToEnd !== -1) {
      layoutShapes[indexStartToEnd].x0 = groupingLabelStart;
      layoutShapes[indexStartToEnd].x1 = groupingLabelEnd;
    }
    return layoutShapes;
  };

  updateAnnotationsAndShapes = (layoutAnnotations, layoutShapes, sortedDataMap, sortedDataSource,
    limitPointsIndexes, layoutIndexes, nullValueCountInDataSource, axis) => {
    let lpIndex = 0;
    let layoutIndex = 0;
    let shapesIndex = -1;
    let tempVal = 0;
    if (layoutIndexes && layoutIndexes.length > 0) {
      layoutAnnotations.forEach((_line, index) => {
        if (index === layoutIndexes[layoutIndex]) {
          tempVal = sortedDataSource[sortedDataMap.findIndex((point) => point.ind === limitPointsIndexes[lpIndex]) - nullValueCountInDataSource];
          layoutAnnotations[index][axis] = tempVal;
          shapesIndex = layoutShapes.findIndex((shape) => layoutAnnotations[index].key && shape.key && layoutAnnotations[index].key === shape.key);
          if (layoutAnnotations[index].key === 'xAxisGroupingLabelMiddleVertical' && axis === 'x') {
            layoutShapes = this.updateGroupingLabelsShapes(layoutShapes, sortedDataMap, sortedDataSource, limitPointsIndexes, index);
          } else if (shapesIndex !== -1) {
            layoutShapes[shapesIndex][`${axis}0`] = tempVal;
            layoutShapes[shapesIndex][`${axis}1`] = tempVal;
          }
          lpIndex += 1;
          layoutIndex += 1;
        }
      });
    }
    return [layoutAnnotations, layoutShapes];
  };

  insertBoxPlotPointsToData = (points, graphIndex) => {
    const { data, isOneGraph } = this.props;
    const boxPlotPointsIndexes = [];
    const tempList = [];
    graphIndex = isOneGraph ? 0 : graphIndex;
    if (data.length > 0) {
      for (let j = 0; j < 6; j++) {
        tempList.push(points.length + j);
      }
      points.push(data[graphIndex].lowerfence[0], data[graphIndex].q1[0], data[graphIndex].mean[0], data[graphIndex].median[0], data[graphIndex].q3[0], data[graphIndex].upperfence[0]);
      boxPlotPointsIndexes.push(tempList);
    }
    return [points, boxPlotPointsIndexes];
  };

  calculateRelativePoints = (points, relativeAdder) => {
    let minVal = 0;
    if (points.length > 0) {
      minVal = Math.min(...points.filter((point) => point !== null));
      if (minVal < 0) {
        relativeAdder = 0 - minVal + 1;
      }
      points = points.map((point) => (point !== null ? point + relativeAdder : point));
    }
    return [points, relativeAdder];
  };

  calculateAbsoluteValues = (relativeAdder, points, sortedDataMap, sortedDataSource, axisBreakLine, axis) => {
    points = points.map((point) => (point !== null ? point - relativeAdder : point));
    sortedDataMap.forEach((point, index) => {
      sortedDataMap[index].val = point.val !== null ? point.val - relativeAdder : point.val;
    });
    sortedDataSource = sortedDataSource.map((point) => point - relativeAdder);
    axisBreakLine.forEach((element, index) => {
      element[`${axis}0`] -= relativeAdder;
      element[`${axis}1`] -= relativeAdder;
      axisBreakLine[index] = element;
    });
    return [points, sortedDataMap, sortedDataSource, axisBreakLine];
  };

  mapTickTextsAndPositionsToLayout = (layout, graphType, axis, orderedDataPoints, sortedDataMap, neededTickIndexes, graphIndex) => {
    const { isOneGraph } = this.props;
    const axisReferenceIndex = GeneralUtils.getAxisReferenceIndex(graphIndex, isOneGraph);
    // if x axis and bar chart, map tick vals to new bar values
    if (layout[`${axis}axis${axisReferenceIndex}`] && axis === 'x' && graphType === 'bar' && layout[`${axis}axis${axisReferenceIndex}`].tickvals) {
      for (let i = 0; i < layout[`${axis}axis${axisReferenceIndex}`].tickvals.length; i++) {
        layout[`${axis}axis${axisReferenceIndex}`].tickvals[i] = orderedDataPoints[i];
      }
    }
    // map tick texts, tick positions, and axis breaks to layout
    else if (layout[`${axis}axis${axisReferenceIndex}`]) {
      const ticksIndexGap = Math.floor(sortedDataMap.length / 5 === 0 ? 1 : sortedDataMap.length / 5);
      const newTickTexts = [];
      const newTickPositions = [];
      for (let i = 0; i < sortedDataMap.length; i++) {
        if (i % ticksIndexGap === 0 || i === sortedDataMap.length - 1 || neededTickIndexes.includes(i)) {
          newTickTexts.push(GeneralUtils.roundNumberAppropriately(sortedDataMap[i].val, 3));
          newTickPositions.push(orderedDataPoints[sortedDataMap[i].ind]);
          layout[`${axis}axis${axisReferenceIndex}`].autorange = true;
          layout[`${axis}axis${axisReferenceIndex}`].ticktext = newTickTexts;
          layout[`${axis}axis${axisReferenceIndex}`].tickvals = newTickPositions;
        }
      }
    }
    return layout;
  }

  applyAxisBreak = (points, minAndMaxForOtherAxis, axis, graphIndex) => {
    let { data, layout, isOneGraph } = this.props;
    const { graphDiv } = this.state;
    // Variable Initialization
    const graphType = data.length > 0 ? data[0].type : '';
    const tickPositions = [];
    const tickTexts = [];
    let sortedDataSource = [];
    let axisBreakLine = [];
    let axisBreakFound;
    let breakPointIndexes = [];
    let originalDataMap = [];
    let sortedDataMap = [];
    let orderedDataPoints = [];
    let limitPointsIndexes = [];
    let layoutIndexes = [];
    let boxPlotPointsIndexes = [];
    let relativeAdder = 0;
    let nullValueCountInDataSource = 0;
    let neededTickIndexes = [];

    // Insert box plot lines to data for y-axis breaks
    if (graphType === 'box') {
      [points, boxPlotPointsIndexes] = this.insertBoxPlotPointsToData(points, graphIndex);
    }
    // Insert limit lines to data for axis breaks
    if (layout.annotations && layout.annotations.length > 0) {
      [points, limitPointsIndexes, layoutIndexes] = this.insertAnnotationsToData(points, layout.annotations, axis, axis === 'y' ? 'Horizontal' : 'Vertical', graphIndex);
    }
    // Calculate relative points
    [points, relativeAdder] = this.calculateRelativePoints(points, relativeAdder);
    // Make Map of Original Data
    originalDataMap = this.createOriginalIndicesMap(points);
    // Sorting data in Assc. Order
    sortedDataMap = this.sortPointsAscending(originalDataMap);
    // Fetching elements from SortedDataMap
    [sortedDataSource, nullValueCountInDataSource] = this.fetchElementsFromSortedMap(sortedDataMap);
    // Updating sorted data and finding breakpoint indexes
    [sortedDataSource, axisBreakFound, breakPointIndexes] = this.findAxisBreaks(sortedDataSource, sortedDataMap, limitPointsIndexes);
    // If there is no breakpoint index return the function
    if (axisBreakFound === false) {
      return null;
    }
    // Calculating actual Axis Break Line positions
    [axisBreakLine, neededTickIndexes] = this.calculateAxisBreakLines(sortedDataSource, breakPointIndexes, minAndMaxForOtherAxis, axis, graphIndex);
    // calculate actual values if needed
    [points, sortedDataMap, sortedDataSource, axisBreakLine] = this.calculateAbsoluteValues(relativeAdder, points, sortedDataMap, sortedDataSource, axisBreakLine, axis);
    // update limit values in layout
    [layout.annotations, layout.shapes] = this.updateAnnotationsAndShapes(layout.annotations, layout.shapes, sortedDataMap, sortedDataSource,
      limitPointsIndexes, layoutIndexes, nullValueCountInDataSource, axis);
    // remove limit points from sorted data map and sorted data source
    if (limitPointsIndexes.length > 0) {
      for (const [index, element] of sortedDataMap.entries()) {
        if (limitPointsIndexes.includes(element.ind)) {
          sortedDataSource.splice(index, 1);
          sortedDataMap.splice(index, 1);
        }
      }
    }
    // Reorder Elements to their original indices
    [sortedDataMap, orderedDataPoints] = this.reorderSortedDataPoints(sortedDataMap, sortedDataSource, limitPointsIndexes, boxPlotPointsIndexes);

    const preBreakValues = this.makeNumberChar(
      points.filter((_point, index) => !limitPointsIndexes.includes(index)),
    );
    // Remap updated points onto graph data
    let dataIndexForBoxPlotRangeMarkers = 0;
    let dataIndexForBoxPlotMedianConnector = -1;
    let index = isOneGraph ? 0 : graphIndex;
    let iterator = 0;
    let tempPoints = [];
    let texts = [];

    for (let i = 0; i <= orderedDataPoints.length; i += 1) {
      if (graphType !== 'box' && data[index][axis].length === iterator && data[index][`${axis}axis`] !== undefined) {
        data[index][axis] = tempPoints;
        tempPoints = [];
        texts = [];
        iterator = 0;
        index++;
        if (i === orderedDataPoints.length || !isOneGraph) {
          break;
        }
      } else if (graphType === 'box' && (data[index][axis] && data[index][axis].length > 0
        ? data[index][axis][0].length === iterator
        : data[index][axis] === undefined)) {
        // add updated box plot graph and points
        if (data[index][axis] && data[index][axis].length > 0) {
          data[index][axis][0] = tempPoints;
        }
        data[index].lowerfence[0] = orderedDataPoints[boxPlotPointsIndexes[0][0]];
        data[index].q1[0] = orderedDataPoints[boxPlotPointsIndexes[0][1]];
        data[index].mean[0] = orderedDataPoints[boxPlotPointsIndexes[0][2]];
        data[index].median[0] = orderedDataPoints[boxPlotPointsIndexes[0][3]];
        data[index].q3[0] = orderedDataPoints[boxPlotPointsIndexes[0][4]];
        data[index].upperfence[0] = orderedDataPoints[boxPlotPointsIndexes[0][5]];
        // update and add box plot range markers and median connector values
        dataIndexForBoxPlotRangeMarkers = data.length === 2 ? 1 : data.length - 2;
        dataIndexForBoxPlotMedianConnector = data.length === 2 ? -1 : data.length - 1;
        data[dataIndexForBoxPlotRangeMarkers].y[index * 4] = (data[index].lowerfence[0] + data[index].q1[0]) / 2;
        data[dataIndexForBoxPlotRangeMarkers].y[index * 4 + 1] = (data[index].q1[0] + data[index].median[0]) / 2;
        data[dataIndexForBoxPlotRangeMarkers].y[index * 4 + 2] = (data[index].median[0] + data[index].q3[0]) / 2;
        data[dataIndexForBoxPlotRangeMarkers].y[index * 4 + 3] = (data[index].q3[0] + data[index].upperfence[0]) / 2;
        if (dataIndexForBoxPlotMedianConnector !== -1) {
          data[dataIndexForBoxPlotMedianConnector].y[index] = data[index].median[0];
        }
        tempPoints = [];
        texts = [];
        iterator = 0;
        index++;
        if (i === orderedDataPoints.length || !isOneGraph) {
          break;
        }
      }
      tempPoints.push(orderedDataPoints[i]);
      if (axis === 'y') {
        texts.push(`${axis}: ${preBreakValues[i]}`);
      }
      iterator++;
    }
    // map tick texts, positions to layout
    layout = this.mapTickTextsAndPositionsToLayout(layout, graphType, axis, orderedDataPoints, sortedDataMap, neededTickIndexes, graphIndex);
    layout.shapes.push(...axisBreakLine);
    graphDiv.data = data;
    graphDiv.layout = layout;
    plotly.redraw(graphDiv);
    return {
      orderedDataPoints, tickPositions, tickTexts, axisBreakLine, axis,
    };
  };

  resetAxisBreakWithRequiredDataPassed = (originalData, originalLayout, axis) => {
    const { graphDiv } = this.state;
    const { layout, data } = this.props;
    if (data.length > 0) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i][axis]) {
          data[i][axis] = [...originalData[i][axis]];
        }
        data[i].hovertemplate = _.cloneDeep(originalData[i].hovertemplate);
        data[i].text = [...originalData[i].text];
        if (originalData[i].type === 'box') {
          data[i].lowerfence[0] = originalData[i].lowerfence[0];
          data[i].q1[0] = originalData[i].q1[0];
          data[i].mean[0] = originalData[i].mean[0];
          data[i].median[0] = originalData[i].median[0];
          data[i].q3[0] = originalData[i].q3[0];
          data[i].upperfence[0] = originalData[i].upperfence[0];
        }
      }
      let index = '';
      while (originalLayout[`xaxis${index}`] !== undefined) {
        layout[`xaxis${index}`] = _.cloneDeep(originalLayout[`xaxis${index}`]);
        if (index === '') {
          index = 2;
        } else {
          index += 1;
        }
      }
      index = '';
      while (originalLayout[`yaxis${index}`] !== undefined) {
        layout[`yaxis${index}`] = _.cloneDeep(originalLayout[`yaxis${index}`]);
        if (index === '') {
          index = 2;
        } else {
          index += 1;
        }
      }
      layout.annotations = originalLayout.annotations
        ? _.cloneDeep(originalLayout.annotations)
        : undefined;
      layout.shapes = originalLayout.shapes
        ? _.cloneDeep(originalLayout.shapes)
        : undefined;
      layout.height = originalLayout.height ? originalLayout.height : undefined;
    }
    graphDiv.layout = layout;
    graphDiv.data = data;
    return graphDiv;
  }

  resetAxisBreak = (axis) => {
    const { originalData, originalLayout, originalDataAfterAlternateAxisBreak, originalLayoutAfterAlternateAxisBreak } = this.state;
    let graphDiv;
    if (originalDataAfterAlternateAxisBreak){
      graphDiv = this.resetAxisBreakWithRequiredDataPassed(originalDataAfterAlternateAxisBreak, originalLayoutAfterAlternateAxisBreak, axis);
      this.setState({originalDataAfterAlternateAxisBreak: undefined, originalLayoutAfterAlternateAxisBreak: undefined});
    }
    else{
      graphDiv = this.resetAxisBreakWithRequiredDataPassed(originalData, originalLayout, axis);
    }
    plotly.redraw(graphDiv);
  };

  changeBreaksEnabledState = (e, axis) => {
    const { data, isOneGraph } = this.props;
    for (let graphIndex = 0; graphIndex < data.length; graphIndex += 1) {
      // Remove pareto and PFR cumulative line when y-axis breaks are applied.
      // Also remove other graphs' data if axis break need to be handled separately for each graph
      const { data } = this.props;
      const copiedData = data.filter((x) => x.name !== 'Cumulative Bin %' && x.name !== 'Cumulative %')
        .filter((x, index) => (index === graphIndex && !isOneGraph) || isOneGraph);
      if ((!isOneGraph && (data[graphIndex].name === '' || data[graphIndex].name === undefined)) || (isOneGraph && graphIndex === 0)) {
        const axisData = {
          x: copiedData.map((d) => d.x).flat(),
          y: copiedData.map((d) => d.y).flat(),
        };
        if (axis !== '') {
          if (e.value) {
            const otherAxisData = axis === 'x' ? axisData.y : axisData.x;
            const minAndMaxForOtherAxis = [Math.min(...otherAxisData), Math.max(...otherAxisData)];
            this.applyAxisBreakOuter(axisData[axis], minAndMaxForOtherAxis, axis, graphIndex);
          }
        }
      }
    }
  };

  removeActiveClassFromModeBarButtonsToUnhighlight = (buttonsToCheck) => {
    const { id } = this.props;
    let currentGraph = document.getElementById(id);
    if (currentGraph !== null) {
      let buttons = currentGraph.getElementsByClassName('modebar-btn');
      for (let i = 0; i < buttons.length && buttonsToCheck.length > 0; i++) {
        if (buttonsToCheck.includes(buttons[i].getAttribute('data-title'))) {
          buttons[i].classList.remove('active');
        }
      }
    }
  }

  toggleEditor() {
    const { hideControls } = this.state;
    this.setState({ hideControls: !hideControls });
  }

  handleAxisBreakClick = (typeOfAxis, axisBreakStatus, otherAxisBreakStatus, axisBreakVar) => {
    if (axisBreakStatus) {
      this.resetAxisBreak(typeOfAxis);
      this.setState({ [axisBreakVar]: false });
    } else if (!axisBreakStatus) {
      const { data, layout } = this.props;
      if (otherAxisBreakStatus){
        const originalDataAfterAlternateAxisBreak = _.cloneDeep(data);
        const originalLayoutAfterAlternateAxisBreak = _.cloneDeep(layout);
        this.setState({ originalLayoutAfterAlternateAxisBreak, originalDataAfterAlternateAxisBreak }, ()=>{
          this.changeBreaksEnabledState({ value: true }, typeOfAxis);
          this.setState({ [axisBreakVar]: true });
        })
      }
      else{
        this.changeBreaksEnabledState({ value: true }, typeOfAxis);
        this.setState({ [axisBreakVar]: true });
      } 
    }
  }

  render() {
    const {
      showYAxisBreaks,
      showXAxisBreaks,
      onDeselect,
      onSelected,
      id,
    } = this.props;
    const modeBarButtonsToAdd = [];
    modeBarButtonsToAdd.push({
      title: 'Show/Hide Designer',
      name: 'Chart Designer',
      toggle: true,
      attr: 'chartDesigner',
      icon: {
        width: 500,
        height: 600,
        path: 'M373.5 27.1C388.5 9.9 410.2 0 433 0c43.6 0 79 35.4 79 79c0 22.8-9.9 44.6-27.1 59.6L277.7 319l-10.3-10.3-64-64L193 234.3 373.5 27.1zM170.3 256.9l10.4 10.4 64 64 10.4 10.4-19.2 83.4c-3.9 17.1-16.9 30.7-33.8 35.4L24.4 510.3l95.4-95.4c2.6 .7 5.4 1.1 8.3 1.1c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32c0 2.9 .4 5.6 1.1 8.3L1.7 487.6 51.5 310c4.7-16.9 18.3-29.9 35.4-33.8l83.4-19.2z'
      },

      click: () => {
        const { hideControls } = this.state;
        this.modeBarButtonsToUnhighlight = this.modeBarButtonsToUnhighlight.filter((x)=>x!=='Show/Hide Designer');
        this.setState({ hideControls: !hideControls });
      },
    });
    if (showYAxisBreaks) {
      modeBarButtonsToAdd.push({
        title: 'Y-Axis Break',
        name: 'YAxis Break',
        toggle: true,
        attr: 'yAxisBreak',
        icon: {
          width: 500,
          height: 600,
          path: 'M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z',
        },

        click: () => {
          const { yAxisBreak, xAxisBreak } = this.state;
          this.modeBarButtonsToUnhighlight = this.modeBarButtonsToUnhighlight.filter((x)=>x!=='Y-Axis Break');
          this.handleAxisBreakClick('y', yAxisBreak, xAxisBreak, "yAxisBreak");
        },
      });
    }
    if (showXAxisBreaks) {
      modeBarButtonsToAdd.push({
        title: 'X-Axis Break',
        name: 'XAxis Break',
        toggle: true,
        attr: 'xAxisBreak',
        icon: {
          width: 500,
          height: 600,
          path: 'M24 32C10.7 32 0 42.7 0 56V456c0 13.3 10.7 24 24 24H40c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H24zm88 0c-8.8 0-16 7.2-16 16V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16zm72 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H184zm96 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H280zM448 56V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H472c-13.3 0-24 10.7-24 24zm-64-8V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16s-16 7.2-16 16z',
        },

        click: () => {
          this.modeBarButtonsToUnhighlight = this.modeBarButtonsToUnhighlight.filter((x)=>x!=='X-Axis Break');
          const { xAxisBreak, yAxisBreak } = this.state;
          this.handleAxisBreakClick('x', xAxisBreak, yAxisBreak, "xAxisBreak");
        },
      });
    }

    this.removeActiveClassFromModeBarButtonsToUnhighlight(this.modeBarButtonsToUnhighlight);

    let showBorder = false;
    if (this.props.showBorder !== undefined) {
      showBorder = this.props.showBorder;
    }
    this.props.config.displaylogo = false;
    this.props.config.modeBarButtonsToAdd = modeBarButtonsToAdd;
    this.props.layout.title = {
      font: {
        color: '#FFFFFF',
      },
      text: '',
    };
    return (
      <div className={`plotly_editor ${showBorder ? 'border-all' : ''}`}>
        {!this.state.hideControls ? (
          <>
            <div className="setting-header">
              <button
                onClick={this.toggleEditor}
                type="button"
                className="setting-header"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            <div className="plotly-designer">
              <EditorControls
                graphDiv={this.state.graphDiv}
                dataSources={this.props.dataSources}
                dataSourceOptions={this.props.dataSourceOptions}
                plotly={this.props.plotly}
                onUpdate={this.props.onUpdate}
                advancedTraceTypeSelector={this.props.advancedTraceTypeSelector}
                locale={this.props.locale}
                traceTypesConfig={this.props.traceTypesConfig}
                dictionaries={this.props.dictionaries}
                showFieldTooltips={this.props.showFieldTooltips}
                srcConverters={this.props.srcConverters}
                makeDefaultTrace={this.props.makeDefaultTrace}
                glByDefault={this.props.glByDefault}
                mapBoxAccess={Boolean(
                  this.props.config && this.props.config.mapboxAccessToken,
                )}
                fontOptions={this.props.fontOptions}
                chartHelp={this.props.chartHelp}
                customConfig={this.props.customConfig}
              >
                {this.props.children}
              </EditorControls>
            </div>
          </>
        ) : (
          ''
        )}
        <div
          id="plot"
          className="plotly_editor_plot"
          style={{ width: '100%', height: '100%' }}
        >
          <Plot
            id={id}
            data={this.props.data}
            layout={this.props.layout}
            frames={this.props.frames}
            config={this.props.config}
            useResizeHandler={this.props.useResizeHandler}
            debug={this.props.debug}
            onInitialized={this.handleRender}
            onUpdate={this.handleRender}
            divId={this.state.xAxisBreak.toString()}
            onSelected={onSelected}
            onDeselect={onDeselect}
          />
        </div>
      </div>
    );
  }
}

PlotlyEditor.propTypes = {
  children: PropTypes.any,
  layout: PropTypes.object,
  data: PropTypes.array,
  config: PropTypes.object,
  dataSourceOptions: PropTypes.array,
  dataSources: PropTypes.object,
  frames: PropTypes.array,
  onUpdate: PropTypes.func,
  onRender: PropTypes.func,
  plotly: PropTypes.object,
  useResizeHandler: PropTypes.bool,
  debug: PropTypes.bool,
  advancedTraceTypeSelector: PropTypes.bool,
  locale: PropTypes.string,
  traceTypesConfig: PropTypes.object,
  dictionaries: PropTypes.object,
  divId: PropTypes.string,
  hideControls: PropTypes.bool,
  showFieldTooltips: PropTypes.bool,
  srcConverters: PropTypes.shape({
    toSrc: PropTypes.func.isRequired,
    fromSrc: PropTypes.func.isRequired,
  }),
  makeDefaultTrace: PropTypes.func,
  glByDefault: PropTypes.bool,
  fontOptions: PropTypes.array,
  chartHelp: PropTypes.object,
  customConfig: PropTypes.object,
  onSelected: PropTypes.func,
};

PlotlyEditor.defaultProps = {
  hideControls: true,
  showFieldTooltips: false,
  fontOptions: DEFAULT_FONTS,
  onSelected: () => {},
};

export default PlotlyEditor;
