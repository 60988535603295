/* eslint-disable react/prefer-stateless-function */
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import React from 'react';
import { Row, Col, } from 'react-bootstrap';

export interface IAxisDropdownProps {
  color?: any,
  selectionStore: any,
  testParameterIndex: any,
  applyNewAxis: (testParameterIndex: any) => void;
}

class AxisDropdowns extends React.Component<IAxisDropdownProps> {
  render() {
    const {
      selectionStore, testParameterIndex, color, applyNewAxis,
    } = this.props;
    const testParameterNames: any[] = [];
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    testParameters[0].values.forEach((testParameter: any) => {
      const formattedTestParameterName: any[] = [];
      formattedTestParameterName.push(testParameter.name);
      formattedTestParameterName.push(testParameter.name);
      testParameterNames.push(formattedTestParameterName);
    });

    return (
      <>
        <Row className="d-flex mb10">
          <Col><h6 className="ml5 mt7 mr10">X-Axis</h6></Col>
          <Col>
            <CustomizedDropdown
              variant="light"
              full
              list={testParameterNames}
              dropdownButtonClass="border-all"
              selectedValue={testParameters[0].values[testParameterIndex[0]].name}
              onChange={(value: any) => {
                testParameterIndex[0] = testParameters[0].values.findIndex((x: { name: any; }) => x.name === value);
                applyNewAxis(testParameterIndex);
              }}
            />
          </Col>
          <Col />
          <Col />
        </Row>
        <Row className="d-flex">
          <Col><h6 className="ml5 mt7 mr10">Y-Axis</h6></Col>
          <Col>
            <CustomizedDropdown
              variant="light"
              full
              list={testParameterNames}
              dropdownButtonClass="border-all"
              selectedValue={testParameters[0].values[testParameterIndex[1]].name}
              onChange={(value: any) => {
                testParameterIndex[1] = testParameters[0].values.findIndex((x: { name: any; }) => x.name === value);
                applyNewAxis(testParameterIndex);
              }}
            />
          </Col>
          <Col />
          <Col />
        </Row>
      </>
    );
  }
}

export default AxisDropdowns;
