/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  Row, Col, Button, Spinner,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  faCopy, faCross, faFileExport, faFileImport, faPlus, faSave, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { DateBox } from 'devextreme-react';
import { getHeaderControlButtonStructure } from 'views/individual-reports/customized-report/CustomizedReportsCollection';
import { toast } from 'react-toastify';
import Heading from '../hint-controls/Heading';
import Label from '../hint-controls/Label';
import Textbox from '../hint-controls/Textbox';
import Toggle from '../hint-controls/Toggle';
import Textarea from '../hint-controls/Textarea';
import CustomizedDropdown from '../customized-dropdown/CustomizedDropdown';
import { isValid, } from '../../template-component/form-error-label/ErrorLabel';

export interface IEntityHeaderState {
  name: string;
  version: string;
  state: string;
  privacy: string;
  purpose: string;
  owner: string;
  policyType?: string;
  startsAt?: Date;
  interval?: number;
  onClickSave?: boolean;
}

interface IEntityHeaderProps {
  name: string;
  version: string;
  state: string;
  privacy: string;
  purpose: string;
  owner: string;
  policyType?: string;
  startsAt?: Date;
  interval?: number;
  onClickSave?: boolean;
  //------------------------
  hideUtilityActions?: boolean;
  hideTitle? : boolean;
  onChange?: any;
  onParentSave?: any;
  onNew?: () => void;
  showBorderAll?: boolean;
  hideSaveButton? : boolean;
  isInSavingState?: boolean;
  hideVersion?: boolean;
  hideState?: boolean;
  onParentDiscard?: () => void;
}
export const entityHeaderStates = [['DRAFT', 'DRAFT'], ['ACTIVE', 'ACTIVE'], ['INACTIVE', 'INACTIVE'], ['OBSOLETE', 'OBSOLETE']];

let formValidationSchema = Yup.object();

class EntityHeader extends React.Component<IEntityHeaderProps, IEntityHeaderState> {
  static defaultProps = {
    name: '',
    version: '',
    state: '',
    privacy: '',
    purpose: '',
    owner: '',
    //------------------------
    hideUtilityActions: false,
    hideTitle: false,
    showBorderAll: true,
    hideSaveButton: false,
    hideVersion: false,
    hideState: false,
  };

  constructor(props:IEntityHeaderProps) {
    super(props);
    const {
      name, version, state, privacy, purpose, owner, policyType, startsAt, interval,
    } = this.props;
    this.state = {
      name,
      version,
      privacy: privacy === '' ? 'PUBLIC' : privacy,
      purpose,
      owner,
      policyType,
      startsAt,
      interval,
      onClickSave: false,
      state: state === '' ? entityHeaderStates[0][0] : state,
    };
  }

  componentDidUpdate(prevProps: IEntityHeaderProps) {
    if (this.props !== prevProps) {
      this.getEntityHeader();
    }
  }

  returnEntityHeader = () => {
    const { onChange } = this.props;
    const {
      name, version, privacy, purpose, owner, state, policyType, startsAt, interval,
    } = this.state;

    const entityHeaderData : IEntityHeaderState = {
      name,
      version,
      state,
      privacy,
      purpose,
      owner,
      policyType,
      startsAt,
      interval,
    };
    if (onChange) onChange(entityHeaderData);
  };

  onParentSaveHandler = () => {
    const { onParentSave, hideVersion } = this.props;
    const {
      name, version, privacy, purpose, owner, state, policyType, startsAt, interval,
    } = this.state;

    const entityHeaderData : IEntityHeaderState = {
      name,
      version,
      state,
      privacy,
      purpose,
      owner,
      policyType,
      startsAt,
      interval,
    };

    this.setState({ onClickSave: true });

    formValidationSchema = Yup.object({
      name: Yup.string().required('Policy name is a required field'),
      version: Yup.string().required('Version is a required field'),
      purpose: Yup.string().required('Description is a required field').max(1000),
      owner: Yup.string().required('Owner is a required field').max(1000),
    });
    if (onParentSave) onParentSave(entityHeaderData);
  };

  getEntityHeader = () => {
    const {
      name: propsName,
      version: propsVersion,
      state: propsState,
      privacy: propsPrivacy,
      purpose: propsPurpose,
      owner: propsOwner,
      policyType: propsType,
      startsAt: propsStartsAt,
      interval: propsInterval,
    } = this.props;

    let {
      name, version, privacy, purpose, owner, state, policyType, startsAt, interval,
    } = this.state;

    name = propsName!;
    version = propsVersion!;
    privacy = propsPrivacy!;
    purpose = propsPurpose!;
    owner = propsOwner!;
    state = propsState!;
    policyType = propsType!;
    startsAt = propsStartsAt!;
    interval = propsInterval!;

    this.setState({
      name, version, privacy, purpose, owner, state, policyType, startsAt, interval,
    }, this.returnEntityHeader);
  };

  render() {
    const {
      name, version, privacy, purpose, owner, state, policyType, startsAt, interval,
    } = this.state;

    const {
      onNew, hideUtilityActions, hideTitle, showBorderAll,
      hideSaveButton, isInSavingState, hideVersion, hideState, onParentDiscard,
    } = this.props;

    const utilityActions = [
      {
        icon: faPlus,
        tooltip: 'New',
        onClick: () => { if (onNew) onNew(); },
      },
      {
        icon: faCopy,
        tooltip: 'Copy',
        onClick: () => undefined,
      },
      {
        icon: faFileImport,
        tooltip: 'Import',
        onClick: () => undefined,
      },
      {
        icon: faFileExport,
        tooltip: 'Export',
        onClick: () => undefined,
      },
    ];

    const noFormValidationSchema = Yup.object({
      name: Yup.string(),
      version: Yup.string(),
      purpose: Yup.string(),
      owner: Yup.string(),
    });

    const validateSchema = () => {
      const { onClickSave } = this.state;
      return onClickSave ? formValidationSchema : noFormValidationSchema;
    }

    const showSchedulingInfo = policyType && startsAt;

    return (
      <div className={`${showBorderAll ? 'border-all pl10 pr10' : 'border-left pl50 pr50'} pt10  pb30 background-color-light border-radius-4`}>
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            name: `${name}`,
            version: `${version}`,
            purpose: `${purpose}`,
            owner: `${owner}`,
          }}
          validationSchema={validateSchema}
          onSubmit={() => undefined}
        >
          {({
            handleSubmit, handleChange, values, errors,
          }) => (
            <form
              onSubmit={handleSubmit}
              onChange={handleChange}
              className="custom-form"
            >
              {hideTitle ? <div className="mt20" /> : (
                <Row className="pb10">
                  <Col>
                    <div className="d-flex mt3">
                      <Heading size={3}>Settings</Heading>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={showSchedulingInfo ? 8 : 12} className="">
                  <Heading size={4}>General Settings</Heading>
                  <Row className="mb10">
                    <Col lg={6}>
                      <Row>
                        <Col>
                          <Label
                            labelTitle="Name"
                            labelPosition="top"
                            labelSize="100"
                            fieldSize="100"
                            errorSize="100"
                            required
                            error={errors.name}
                            isFieldTouched
                          >
                            <Textbox
                              autoComplete="off"
                              name="name"
                              placeholder="Name"
                              value={values.name}
                              className={`${isValid(errors.name!)}`}
                              onChange={(e:any) => {
                                this.setState({ name: e.target.value }, this.returnEntityHeader);
                              }}
                            />
                          </Label>
                          <Label
                            labelTitle="Owner"
                            labelPosition="top"
                            labelSize="100"
                            fieldSize="100"
                            errorSize="100"
                            error={errors.owner}
                            required
                            isFieldTouched
                          >
                            <Textbox
                              autoComplete="off"
                              name="owner"
                              placeholder="mark.smith@someemail.com"
                              value={values.owner}
                              className={`${isValid(errors.owner!)}`}
                              onChange={(e:any) => {
                                this.setState({ owner: e.target.value }, this.returnEntityHeader);
                              }}
                            />
                          </Label>
                        </Col>
                        <Col>
                          <Row>
                            { !hideVersion
                              && (
                                <Col>
                                  <Label
                                    labelTitle="Version"
                                    labelPosition="top"
                                    labelSize="100"
                                    fieldSize="100"
                                    errorSize="100"
                                    error={errors.version}
                                    required={!hideVersion}
                                    isFieldTouched
                                  >
                                    <Textbox
                                      autoComplete="off"
                                      name="version"
                                      // readonly
                                      placeholder="1.0"
                                      value={values.version}
                                      className={`${isValid(errors.version!)}`}
                                      onChange={(e:any) => {
                                        this.setState({ version: e.target.value }, this.returnEntityHeader);
                                      }}
                                    />
                                  </Label>
                                </Col>
                              )}
                            <Col>
                              <Label
                                labelTitle="Privacy"
                                labelPosition="top"
                                labelSize="100"
                                fieldSize="100"
                                errorSize="100"
                                isFieldTouched
                              >
                                <Toggle
                                  title="Is Public?"
                                  id="is_public"
                                  name="is_public"
                                  className="custom-switch"
                                  checked={privacy !== 'PRIVATE'}
                                  onChange={(e:any) => {
                                    this.setState({ privacy: e.target.checked ? 'PUBLIC' : 'PRIVATE' }, this.returnEntityHeader);
                                  }}
                                />
                              </Label>

                            </Col>
                          </Row>
                          {
                            !hideState
                          && (
                            <Label
                              labelTitle="State"
                              labelPosition="top"
                              labelSize="100"
                              fieldSize="100"
                              errorSize="100"
                            >
                              <CustomizedDropdown
                                variant="clear"
                                full
                                list={entityHeaderStates}
                                selectedValue={state}
                                onChange={(value:string) => {
                                  this.setState({ state: value }, this.returnEntityHeader);
                                }}
                              />
                            </Label>
                          )
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Label
                        labelTitle="Description"
                        labelPosition="left-top"
                        labelSize="100"
                        fieldSize="100"
                        errorSize="100"
                        className="mt6"
                        required
                        error={errors.purpose}
                        isFieldTouched
                      >
                        <Textarea
                          autoComplete="off"
                          name="purpose"
                          placeholder="Add detailed notes to this rule..."
                          rows={5}
                          defaultValue={values.purpose}
                          className={`h109 ${isValid(errors.purpose!)}`}
                          onChange={(e:any) => {
                            this.setState({ purpose: e.target.value }, this.returnEntityHeader)
                          }}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col lg={6}> */}
                    {/*   <div className="d-flex justify-content-between"> */}
                    {/*     { */}
                    {/*       hideUtilityActions ? null : ( */}
                    {/*         utilityActions.map((item) => ( */}
                    {/*           <Button */}
                    {/*             className="d-block w-100 h36 btn-config mr10" */}
                    {/*             variant="clear" */}
                    {/*             onClick={item.onClick} */}
                    {/*           > */}
                    {/*             {getHeaderControlButtonStructure(item.icon, item.tooltip)} */}
                    {/*           </Button> */}
                    {/*         )) */}
                    {/*       ) */}
                    {/*     } */}
                    {/*     <OverlayTrigger */}
                    {/*       rootClose */}
                    {/*       trigger="click" */}
                    {/*       placement="right" */}
                    {/*       overlay={( */}
                    {/*         <Popover id="editor-info" className="w250"> */}
                    {/*           <Popover.Content> */}
                    {/*             <div className="d-flex align-items-center justify-content-between break-bottom pb10 mb10"> */}
                    {/*               <Heading size={6} className="mt10">Audit Trail</Heading> */}
                    {/*               <Button variant="link" className="p0 default-font-size"> */}
                    {/*                 Change Log */}
                    {/*               </Button> */}
                    {/*             </div> */}
                    {/*             <div className="d-flex align-items-center justify-content-between default-font-size"> */}
                    {/*               <div className="mr20"> */}
                    {/*                 <span className="color-icon-color mr7 mt-10">Updated By</span> */}
                    {/*                 <br /> */}
                    {/*                 <span className="color-dark-three mt-10 mr7">Ken Edwards</span> */}
                    {/*                 <br /> */}
                    {/*                 <span className="color-dark-three mt-10"><em>26th Jul 2020</em></span> */}
                    {/*               </div> */}
                    {/*               <div> */}
                    {/*                 <span className="color-icon-color mt-10">Created By</span> */}
                    {/*                 <br /> */}
                    {/*                 <span className="color-dark-three mt-10">Matt Anderson</span> */}
                    {/*                 <br /> */}
                    {/*                 <span className="color-dark-three mt-10"><em>26th Jul 2020</em></span> */}
                    {/*               </div> */}
                    {/*             </div> */}
                    {/*           </Popover.Content> */}
                    {/*         </Popover> */}
                    {/*       )} */}
                    {/*     > */}
                    {/*       <Button */}
                    {/*         className="d-block w-100 h36 btn-config" */}
                    {/*         variant="clear" */}
                    {/*       > */}
                    {/*         {getHeaderControlButtonStructure(faUserEdit, 'Editors')} */}
                    {/*       </Button> */}
                    {/*     </OverlayTrigger> */}
                    {/*   </div> */}
                    {/* </Col> */}
                    <Col lg={6}>
                      <Row>
                        <Col className="side-by-side">
                          {
                            hideSaveButton
                              ? null : (
                                <Button
                                  className="d-block w130 h36"
                                  variant="success"
                                  type="submit"
                                  onClick={this.onParentSaveHandler}
                                  disabled={isInSavingState}
                                >
                                  { isInSavingState ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr10"
                                      />
                                      Saving
                                    </>
                                  ) : getHeaderControlButtonStructure(faSave, 'Save') }
                                </Button>
                              )

                          }
                          {
                            onParentDiscard && (
                              <Button
                                className="d-block w150 h36 ml10"
                                variant="danger"
                                // type="submit"
                                onClick={onParentDiscard}
                                disabled={isInSavingState}
                              >
                                {getHeaderControlButtonStructure(faTimes, 'Discard and Close') }
                              </Button>
                            )
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {
                  showSchedulingInfo && (
                    <Col lg={4} className="border-left">
                      <Heading size={4}>Scheduling Settings</Heading>
                      <Row>
                        <Col>
                          {
                            policyType && (
                              <Label
                                labelTitle="Type"
                                labelPosition="top"
                                labelSize="100"
                                fieldSize="100"
                                errorSize="100"
                              >
                                <CustomizedDropdown
                                  variant="clear"
                                  full
                                  list={[['Manual', 'Manual'], ['Poller', 'Poller'], ['', 'Microservice Webhook']]}
                                  selectedValue={policyType}
                                  onChange={(value: string) => {
                                    this.setState({ policyType: value }, this.returnEntityHeader);
                                  }}
                                />
                              </Label>
                            )
                          }
                          {
                            startsAt && (
                              <Label
                                labelTitle="Starts At"
                                labelPosition="top"
                                labelSize="100"
                                fieldSize="100"
                                errorSize="100"
                                required
                                className={`${policyType === 'Poller' ? 'visible' : 'invisible'}`}
                              >
                                <div className="border-all rounded">
                                  <DateBox
                                    value={startsAt}
                                    onValueChanged={(e:any) => {
                                      if (e.value !== '' && e.value !== null) {
                                        this.setState({ startsAt: e.value }, this.returnEntityHeader);
                                      }
                                    }}
                                    type="datetime"
                                  />
                                </div>
                              </Label>
                            )
                          }
                          <Label
                            labelTitle="Polling Interval (in days)"
                            labelPosition="top"
                            labelSize="100"
                            fieldSize="100"
                            errorSize="100"
                            required
                            className={`${policyType === 'Poller' ? 'visible' : 'invisible'}`}
                          >
                            <Textbox
                              type="number"
                              defaultValue={interval}
                              autoComplete="off"
                              name="additionalEmail"
                              placeholder="7 (days)"
                              onBlur={(e: any) => {
                                if (Number(e.target.value) > 35791394) {
                                  toast.warning('Polling interval exceeds the maximum limit. Setting it to maximum available limit of 35791394 days');
                                  this.setState({ interval: 35791394 }, this.returnEntityHeader);
                                  e.target.value = 35791394;
                                  return;
                                }
                                if (Number(e.target.value) < 1) {
                                  toast.warning('Polling Interval should be greater than 0. Setting it to minimum available limit of 1 day');
                                  this.setState({ interval: 1 }, this.returnEntityHeader);
                                  e.target.value = 1;
                                  return;
                                }
                                this.setState({ interval: e.target.value }, this.returnEntityHeader);
                              }}
                            />
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                  )
                }
              </Row>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default EntityHeader;
