/* eslint-disable no-else-return */
/* eslint-disable lines-between-class-members */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import { ContextMenu } from 'devextreme-react';
import _ from 'lodash';
import { defaultToolTipFields } from '../../wafer-map/variables/WaferMapVariablesClass';
import {
  ColAxisDirection,
  ColFlip,
  DefaultNotchPosition,
  DieColorType,
  DieLocation,
  ExclusionType,
  FeatureSpecificWaferTag,
  FlipAxis,
  MarkingMode,
  NotchPosition,
  RowAxisDirection,
  RowFlip,
  SelectionMode,
  WaferOriginLocation,
} from '../../wafer-map/web-gl-utils/Enums';
import {
  DieCircleTextureDetails,
  DieLineTextureDetails,
  DieRectangleTextureDetails,
  DieTriangleTextureDetails,
  RectCoord,
  ReticleGridRectCoords,
  ReticleReference,
  StandardReticle,
  Viewport,
  XYPoint,
  ZoneData,
} from '../../wafer-map/web-gl-utils/Types';
import { ActionHandler } from '../WaferPlotter/WaferPlotter';

export type DieData = {
  [key: string] : any,
  x?: number, // used for calculation
  y?: number, // used for calculation
  xTrue?: number, // actual visible coordinates
  yTrue?: number, // actual visible coordinates
  binColor?: string,
  isUnderSelection?: boolean,
  isSelected?: boolean,
  isCropped?: boolean,
  isDeleted?: boolean,
  location?: DieLocation,
  lineTexture?: { clockwise?: DieLineTextureDetails, antiClockwise?: DieLineTextureDetails },
  circleTextures?: DieCircleTextureDetails[],
  triangleTextures?: DieTriangleTextureDetails[],
  rectangleTextures?: DieRectangleTextureDetails[],
};

export type Dies = (null | DieData)[][];

export type InitData = {
  waferName: string,
  actionHandler?: ActionHandler,
  isDieSelectedOnAnyWafer: boolean,
  keyIndex: string,
  dies: Dies,
  outerViewPort: Viewport,
  dieSize: { dieWidth: number, dieHeight: number },
  programNormal: WebGLProgram,
  programColorBased: WebGLProgram,
  programTexture: WebGLProgram,
  programCircle: WebGLProgram,
  gl: WebGL2RenderingContext,
  textCtx: CanvasRenderingContext2D,
  hasRotationControls: boolean,
  hasFlippingControls: boolean,
  hasMarkingFeature: boolean,
  onSelectionChanged?: (keyIndex: string, selectedData: DieData[], unSelectedData: DieData[]) => void,
  onWATPCMNumberMarked?: (keyIndex: string) => void,
  notchPosition: NotchPosition,
  waferHeightToRowsRatio: number,
  waferWidthToColsRatio: number,
  waferBGOffsetXDies: number;
  waferBGOffsetYDies: number;
  waferTopOffset: number;
  showRing: boolean;
  dieWidthToStreetWidthRatio: number,
  dieHeightToStreetHeightRatio: number,
  rowOffset: number,
  colOffset: number,
  rotationAngle: number,
  wcmWaferDiameter: number,
  showRadar: boolean,
  dieTypeField: string,
  ringDiameterToWaferDiameterRatio: number,
  overlayReticle: boolean,
  markWaferCenter: boolean,
  dieTextField: string,
  showDieText: boolean,
  reticleSize: { x: number, y: number },
  reticleReference: ReticleReference,
  currentDieType: string | null,
  dieTypes: any,
  showReferenceReticle: boolean,
  zones: ZoneData[],
  wcmWaferEdgeExclusion: number,
  wcmWaferNotchKeepOut: number,
  wcmWaferFlatKeepOut: number,
  wcmWaferBaseFlat: number,
  wcmWaferScribeLine: number,
  wcmExclusionType: ExclusionType,
  waferBgImageTop: (null | HTMLImageElement),
  waferBgImageBottom: (null | HTMLImageElement),
  waferBgImageLeft: (null | HTMLImageElement),
  waferBgImageRight: (null | HTMLImageElement),
  waferBgImageTopFlat: (null | HTMLImageElement),
  waferBgImageBottomFlat: (null | HTMLImageElement),
  waferBgImageLeftFlat: (null | HTMLImageElement),
  waferBgImageRightFlat: (null | HTMLImageElement),
  shouldUseOnlyBinColor: boolean,
  standardReticle: StandardReticle | null,
  rowsTakenByWaferBG: number;
  colsTakenByWaferBG: number;
  colAxisDirection: ColAxisDirection;
  rowAxisDirection: RowAxisDirection;
  waferOriginLocation: WaferOriginLocation;
  tooltipFields?: string[][];
  externalLegendFields: { key: string, value: string }[],
  skipProcessData: boolean,
  reticleGridRectCoords: ReticleGridRectCoords,
  referenceReticleGridRectCoords: RectCoord | null,
  featureSpecificWaferTags: FeatureSpecificWaferTag[],
  pageNumber?: number,
  isXAxisFlipped: boolean,
  isYAxisFlipped: boolean,
  isDefaultXAxisFlipped: boolean,
  isDefaultYAxisFlipped: boolean,
};

class WaferMapVariablesV2 {
  [key: string]: any;

  waferData: {
    dies: Dies;
    waferMaxCols: number; // must change if the dies are updated anywhere
    waferMaxRows: number; // must change if the dies are updated anywhere
  } = {
    dies: [],
    waferMaxRows: 0,
    waferMaxCols: 0,
  };

  featureSpecificWaferTags: FeatureSpecificWaferTag[] = [];

  waferName = '';

  isDieSelectedOnAnyWafer = false;

  keyIndex = '';

  isBoxZoomControlActive = false;

  isDragControlActive = true;

  isSelectionControlActive = false;

  isMarkingControlActive = false; // new version of mark zones

  hasRotationControls = true;

  hasFlippingControls = true;

  hasMarkingFeature = false;

  markingMode: MarkingMode = MarkingMode.DIE_TYPE;

  isMouseMoving = false;

  angleInDegrees = 0;

  rotation: number[] = [Math.sin((360 - this.angleInDegrees) * (Math.PI / 180)), Math.cos((360 - this.angleInDegrees) * (Math.PI / 180))];

  isRadarEventAnchorCenter = true;

  selectionMode: SelectionMode = SelectionMode.DIE;

  reticleGridRectCoords: ReticleGridRectCoords = {}; // this includes the col and row offsets

  referenceReticleGridRectCoords: RectCoord | null = null; // stores grid rect coords of reference reticle

  rowOffset = 0; // row axis numbering start

  colOffset = 0; // column axis numbering start

  currentWatPCMSiteNumber: number | null = null;

  showReticleText = true;

  reticleTextField = 'watPCMSiteNumber';

  currentWatTestMethod = 'sampling';

  currentZoneId = '';

  freeFormZonePCMSiteNumber: number | null = null;

  zones: ZoneData[] = [];

  selectedDieField: string | undefined;

  dieTypeField: string = undefined as unknown as string;

  dieTypes: any; // DONOT Mutate this in wafermap because it has orignal data!!

  currentDieType: string | null = null;

  selectedDieDarknessFactor = 2.5;

  selectedOpacity = 1.0; // Selected Dies Opacity

  onSelectionChanged?: (keyIndex: string, selectedData: DieData[], unSelectedData: DieData[]) => void;

  onWATPCMNumberMarked?: (keyIndex: string) => void;

  defaultBorderGLCoords = [
    -1, -1, -1, 1, // left border
    -1, 1, 1, 1, // top border
    1, 1, 1, -1, // right border
    1, -1, -1, -1, // bottom border
  ];

  outerViewPort: Viewport = {
    // x: reference from lower left, y: reference from lower left, width: 0 - main canvas width, height: 0 - main canvas height
    x: 0, y: 0, width: 0, height: 0,
  };

  outerViewPortWithRadar: Viewport | null = null;

  outerViewPortWithoutRadar: Viewport | null = null;

  innerViewPort: Viewport = {
    // x: reference from lower left, y: reference from lower left, width: 0 - main canvas width, height: 0 - main canvas height
    x: 0, y: 0, width: 0, height: 0,
  };

  radarViewPort: Viewport = {
    // x: reference from lower left, y: reference from lower left, width: 0 - main canvas width, height: 0 - main canvas height
    x: 0, y: 0, width: 0, height: 0,
  };

  waferInternalPaddingX = 0.05; // 0-1

  waferInternalPaddingY = 0.05; // 0-1

  viewPortPaddingX = 0.08; // 0-1

  viewPortPaddingY = 0.08; // 0-1

  dieWidthToHeightRatio = 2;

  scaledDieWidth = 0;

  scaledDieHeight = 0;

  scaledDieWidthInitial = 0;

  scaledDieHeightInitial = 0;

  xAnchor = 0;

  yAnchor = 0;

  xAnchorInitial = 0;

  yAnchorInitial = 0;

  xAnchorPreDrag = 0; // Pre X Anchor position before dragging

  yAnchorPreDrag = 0; // Pre Y Anchor position before dragging

  rowDirection = 1;

  startRow = 0;

  colDirection = 1;

  startCol = 0;

  rowAxisIncrement = 1;

  rowAxisStart = 0;

  colAxisIncrement = 1;

  colAxisStart = 0;

  rowAxisDirection: RowAxisDirection = RowAxisDirection.TopToBottom; // flips only the axis (visually)

  colAxisDirection: ColAxisDirection = ColAxisDirection.LeftToRight; // flips only the axis (visually)

  rowFlip: RowFlip = RowFlip.Upright;

  colFlip: ColFlip = ColFlip.Upright;

  reticleSize: { x: number, y: number } = { x: 0, y: 0 };

  dieCount = 0;

  selectedDieCount = 0;

  rowCount = 0; // visible row count (excludes rows with all nulls / all deleted / all cropped dies)

  colCount = 0; // visible col count (excludes col with all nulls / all deleted / all cropped dies)

  dieWidthToStreetWidthRatio = -1; // if this is -1, it means there is no street width

  dieHeightToStreetHeightRatio = -1; // if this is -1, it means there is no street width

  allDiesOuterGLCoords: number[] = [];

  radarDiesGLCoords: number[] | null = null;

  wcmCroppingRingGLCoords: number[] = [];

  radarPointerGLCoords: number[] = [];

  referenceReticleBorderGLCoords: number[] = [];

  reticleReferenceGLCoords: number[] = [];

  outerViewportBorderGLCoords: number[] = this.defaultBorderGLCoords;

  innerViewportBorderGLCoords: number[] = this.defaultBorderGLCoords;

  radarViewportBorderGLCoords: number[] = this.defaultBorderGLCoords;

  glCoordsBasedColors: number[] = [];

  programNormal: WebGLProgram | null = null;

  programColorBased: WebGLProgram | null = null;

  programTexture: WebGLProgram | null = null;

  programCircle: WebGLProgram | null = null;

  gl: WebGL2RenderingContext | null = null;

  textCtx: CanvasRenderingContext2D | null = null;

  textFont = 9; // Tick Text font size in px

  isDieCoordinatesSystemEnabled = true;

  isReticleCoordinatesSystemEnabled = false;

  overlayReticle = false; // if this is true, only then consider using reticle info else consider as if there is no reticle applied

  binColorOpacity = 1;

  tickLineSize = 8;

  dieTextFieldOptions = [['-', 'none'], ['bin', 'bin'], ['x', 'x'], ['y', 'y'], ['reticleSite', 'Reticle Site'], ['zoneInfo', 'Zone Info']];

  legendFields = [['dieCount', 'Die Count']];

  externalLegendFields: { key: string, value: string }[] = [];

  dieTextField = ''; // specify the property to be displayed inside the dies just like bin numbers

  showDieText = false;

  tooltipHoverTime = 0.5; // tooltip hover time in seconds

  tooltipFields: string[][] = _.cloneDeep(defaultToolTipFields);

  notchPosition: NotchPosition = DefaultNotchPosition;

  waferBgImageTop: (null | HTMLImageElement) = null;

  waferBgImageBottom: (null | HTMLImageElement) = null;

  waferBgImageLeft: (null | HTMLImageElement) = null;

  waferBgImageRight: (null | HTMLImageElement) = null;

  waferBgImageTopFlat: (null | HTMLImageElement) = null;

  waferBgImageBottomFlat: (null | HTMLImageElement) = null;

  waferBgImageLeftFlat: (null | HTMLImageElement) = null;

  waferBgImageRightFlat: (null | HTMLImageElement) = null;

  showBgImage = true;

  waferHeightToRowsRatio = 1;

  waferWidthToColsRatio = 1;

  panOffsetXToDieStepSizeXRatio = 0;

  panOffsetYToDieStepSizeYRatio = 0;

  waferBGOffsetXDies = 0;

  waferBGOffsetYDies = 0;

  waferTopOffset = 0;

  showRing = false;

  waferBgImageGLCoords: number[] = [];

  radarWaferBgImageGLCoords: number[] | null = null;

  wcmWaferDiameter = 0;

  showRadar = true;

  ringDiameterToWaferDiameterRatio = 1;

  markWaferCenter = false;

  reticleReference: ReticleReference = 'CENTER';

  showReferenceReticle = false;

  wcmWaferEdgeExclusion = 0;

  wcmWaferNotchKeepOut = 0;

  wcmWaferFlatKeepOut = 0;

  wcmWaferBaseFlat = 0;

  wcmWaferScribeLine = 0;

  wcmExclusionType = 0;

  dieColorType: DieColorType = DieColorType.DIE_TYPE;

  shouldUseOnlyBinColor = true; // determines whether colors of dies are only bin colors or can be derived from zones or dietypes

  referenceReticleBorderColor: number[] = [0.0, 0.0, 1.0, 1.0];

  reticleReferenceColor: number[] = [0.0, 0.0, 1.0, 1.0];

  allReticleBorderGLCoords: number[] = [];

  allReticleBorderColor: number[] = [1.0, 0.0, 0.0, 1.0];

  allReticleBgGLCoords: number[] = []; // reticle bg coords are only for those reticles that have text on them

  allReticleBgColor: number[] = [0.0, 0.0, 1.0, 0.2];

  underSelectionReticleBorderGLCoords: number[] = [];

  underSelectionReticleBorderColor: number[] = [0.0, 0.6, 0.1, 1.0];

  selectedReticleBorderGLCoords: number[] = [];

  selectedReticleBorderColor: number[] = [0.0, 1.0, 0.0, 1.0];

  reticleOffsetGLCoords: number[] = [];

  reticleOffsetLineColor: number[] = [1.0, 0.0, 1.0, 1.0];

  waferBgCenterPoint: XYPoint = { x: 0, y: 0 }; // coords of only the single point wafer bg center

  waferBgCenterGLCoords: number[] = []; // coords of the cross mark at wafer bg center

  waferBgCenterColor: number[] = [1.0, 0.0, 0.0, 1.0];

  standardReticle: StandardReticle | null = null; // same type as in reticle inside standard reticle editor type (passed by reference: don't mutate)

  contextMenuRef: ContextMenu | null = null;

  reticleXAxisReference = 0;

  reticleYAxisReference = 0;

  dieTypeCountInfo: { [key: string]: number } = {};

  radialZoneRingGLCoords: number[] = [];

  verticalZoneGLCoords: number[] = [];

  horizontalZoneGLCoords: number[] = [];

  groupedZoneGLCoords: number[] = [];

  zoneBoundaryColor: number[] = [1.0, 1.0, 0.0, 1.0];

  rowsTakenByWaferBG = 0;

  colsTakenByWaferBG = 0;

  waferOriginLocation: WaferOriginLocation = WaferOriginLocation.UPPER_LEFT;

  textureLinesGLCoords: number[] = [];

  textureLineColors: number[] = [];

  textureCirclesGLCoords: number[] = [];

  textureCircleColors: number[] = [];

  textureTrianglesGLCoords: number[] = [];

  textureTriangleColors: number[] = [];

  textureRectanglesGLCoords: number[] = [];

  textureRectangleColors: number[] = [];

  pageNumber?: number = undefined;

  actionHandler?: ActionHandler = undefined;

  plotterPageNumber?: number = undefined;

  constructor(data: InitData) {
    this.initWaferVariables(data);
    this.adjustOffsetsBasedOnOriginLocation();
    if (!data.skipProcessData) {
      this.processData();
    }
    if (this.overlayReticle) {
      // eslint-disable-next-line no-param-reassign
      this.reticleXAxisReference = this.getReticleAxisReference('x');
      // eslint-disable-next-line no-param-reassign
      this.reticleYAxisReference = this.getReticleAxisReference('y');
    }
    this.computeDefaultAxis(data.isDefaultXAxisFlipped, data.isDefaultYAxisFlipped);
    if (data.isXAxisFlipped) {
      this.changeVariablesForWaferFlip(FlipAxis.ColWise, true, false, false);
    }
    if (data.isYAxisFlipped) {
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, false);
    }
  }

  computeDefaultAxis = (isDefaultXAxisFlipped: boolean, isDefaultYAxisFlipped: boolean) => {
    if (this.angleInDegrees === 270 && isDefaultXAxisFlipped && isDefaultYAxisFlipped) {
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, true);
    } else if (this.angleInDegrees === 90) {
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, true);
    } else if (this.angleInDegrees === 180 && isDefaultXAxisFlipped) {
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, true);

    } else if (this.angleInDegrees === 0 && isDefaultYAxisFlipped) {
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, true);
    }
    else if (isDefaultXAxisFlipped){
      this.changeVariablesForWaferFlip(FlipAxis.ColWise, true, false, true);
    }
    else if (isDefaultYAxisFlipped){
      this.changeVariablesForWaferFlip(FlipAxis.RowWise, false, true, true);
    }
  }

  changeVariablesForFlipColWise = (isDefaultAxisChangeOnly: boolean) => {
    if (this.colDirection === 1) {
      this.colDirection = -1;
      this.colAxisStart = this.waferData.dies[0].length - 1;
      this.startCol = this.waferData.dies[0].length - 1;
    } else {
      this.colDirection = 1;
      this.colAxisStart = 0;
      this.startCol = 0;
    }
    this.colAxisDirection = this.colAxisDirection === ColAxisDirection.RightToLeft ? ColAxisDirection.LeftToRight : ColAxisDirection.RightToLeft;
    if (isDefaultAxisChangeOnly) return;
    if (this.notchPosition === NotchPosition.LEFT) {
      this.notchPosition = NotchPosition.RIGHT;
    } else if (this.notchPosition === NotchPosition.RIGHT) {
      this.notchPosition = NotchPosition.LEFT;
    }
  };

  changeVariablesForFlipRowWise = (isDefaultAxisChangeOnly: boolean) => {
    if (this.rowDirection === 1) {
      this.rowDirection = -1;
      this.rowAxisStart = this.waferData.dies.length - 1;
      this.startRow = this.waferData.dies.length - 1;
    } else {
      this.rowDirection = 1;
      this.rowAxisStart = 0;
      this.startRow = 0;
    }
    this.rowAxisDirection = this.rowAxisDirection === RowAxisDirection.TopToBottom ? RowAxisDirection.BottomToTop : RowAxisDirection.TopToBottom;
    if (isDefaultAxisChangeOnly) return;
    if (this.notchPosition === NotchPosition.UP) {
      this.notchPosition = NotchPosition.DOWN;
    } else if (this.notchPosition === NotchPosition.DOWN) {
      this.notchPosition = NotchPosition.UP;
    }
  };

  changeVariablesForWaferFlip = (axis: FlipAxis, isXAxisGoingToBeFlipped: boolean, isYAxisGoingToBeFlipped: boolean, isDefaultAxisChangeOnly: boolean) => {
    if (axis === FlipAxis.ColWise) {
      this.changeVariablesForFlipColWise(isDefaultAxisChangeOnly);
    } else {
      this.changeVariablesForFlipRowWise(isDefaultAxisChangeOnly);
    }
    this.radarDiesGLCoords = null;
    this.radarWaferBgImageGLCoords = null;
  };

  adjustOffsetsBasedOnOriginLocation = () => {
    if (
      ((this.waferOriginLocation === WaferOriginLocation.UPPER_RIGHT || this.waferOriginLocation === WaferOriginLocation.LOWER_RIGHT) && this.colAxisDirection === ColAxisDirection.LeftToRight)
      || ((this.waferOriginLocation === WaferOriginLocation.UPPER_LEFT || this.waferOriginLocation === WaferOriginLocation.LOWER_LEFT) && this.colAxisDirection === ColAxisDirection.RightToLeft)) {
      this.colOffset = this.colOffset - this.waferData.waferMaxCols + 1;
    } else if (this.waferOriginLocation === WaferOriginLocation.CENTER) {
      this.colOffset -= Math.floor((this.waferData.waferMaxCols) / 2);
    }
    if (
      ((this.waferOriginLocation === WaferOriginLocation.LOWER_LEFT || this.waferOriginLocation === WaferOriginLocation.LOWER_RIGHT) && this.rowAxisDirection === RowAxisDirection.TopToBottom)
      || ((this.waferOriginLocation === WaferOriginLocation.UPPER_LEFT || this.waferOriginLocation === WaferOriginLocation.UPPER_RIGHT) && this.rowAxisDirection === RowAxisDirection.BottomToTop)) {
      this.rowOffset = this.rowOffset - this.waferData.waferMaxRows + 1;
    } else if (this.waferOriginLocation === WaferOriginLocation.CENTER) {
      this.rowOffset -= Math.floor((this.waferData.waferMaxRows) / 2);
    }
  };

  setRotationParams = (rotationAngle: number) => {
    this.angleInDegrees = rotationAngle;
    this.rotation = [Math.sin((360 - this.angleInDegrees) * (Math.PI / 180)), Math.cos((360 - this.angleInDegrees) * (Math.PI / 180))];
  };

  setScaledDimensionsAndAnchors = (
    cols: number,
    rows: number,
    dieWidthToHeightRatio: number,
    innerViewPort: { x: number, y: number, width: number, height: number },
    viewportDimensionChange: boolean,
  ) => {
    const colUnits = cols;
    const rowUnits = rows / dieWidthToHeightRatio;

    const widthWeHave = innerViewPort.width - (this.waferInternalPaddingX * innerViewPort.width * 2) - (this.viewPortPaddingX * innerViewPort.width * 2);
    const heightWeHave = innerViewPort.height - (this.waferInternalPaddingY * innerViewPort.height * 2) - (this.viewPortPaddingY * innerViewPort.height * 2);
    const spaceWeHave = Math.min(widthWeHave, heightWeHave);

    const a = colUnits * (spaceWeHave / (Math.max(colUnits, rowUnits)));
    const b = rowUnits * (spaceWeHave / (Math.max(colUnits, rowUnits)));

    const zoomFactor = this.scaledDieWidth / this.scaledDieWidthInitial;

    const oldCenterX = this.xAnchor + (this.scaledDieWidth * this.waferData.waferMaxCols) / 2;
    const oldCenterY = this.yAnchor + (this.scaledDieHeight * this.waferData.waferMaxRows) / 2;

    const oldScaledDieWidth = this.scaledDieWidth;
    const oldScaledDieHeight = this.scaledDieHeight;

    this.scaledDieWidth = a / cols;
    this.scaledDieHeight = b / rows;
    this.scaledDieWidthInitial = this.scaledDieWidth;
    this.scaledDieHeightInitial = this.scaledDieHeight;

    this.xAnchor = (innerViewPort.width - this.scaledDieWidth * cols) / 2;
    this.yAnchor = (innerViewPort.height - this.scaledDieHeight * rows) / 2;
    this.xAnchorInitial = this.xAnchor;
    this.yAnchorInitial = this.yAnchor;

    if (viewportDimensionChange) {
      this.scaledDieWidth = this.scaledDieWidthInitial * zoomFactor;
      this.scaledDieHeight = this.scaledDieHeightInitial * zoomFactor;

      const newCenterX = oldCenterX * (this.scaledDieWidth / oldScaledDieWidth);
      const newCenterY = oldCenterY * (this.scaledDieHeight / oldScaledDieHeight);
      this.xAnchor = newCenterX - (this.scaledDieWidth * this.waferData.waferMaxCols) / 2;
      this.yAnchor = newCenterY - (this.scaledDieHeight * this.waferData.waferMaxRows) / 2;
    }
  };

  setRadarViewport = (outerViewPort: Viewport) => {
    if (this.showRadar) {
      const radarViewportWidth = outerViewPort.width / 3;
      const radarViewportHeight = outerViewPort.height / 3;
      this.radarViewPort = {
        x: outerViewPort.x + outerViewPort.width,
        y: outerViewPort.y + outerViewPort.height - radarViewportHeight,
        width: radarViewportWidth,
        height: radarViewportHeight,
      };
    } else {
      this.radarViewPort = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }
  };

  initWaferVariables = (data: InitData) => {
    this.waferName = data.waferName;
    this.isDieSelectedOnAnyWafer = data.isDieSelectedOnAnyWafer;
    this.featureSpecificWaferTags = data.featureSpecificWaferTags;
    const maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(data.dies);
    this.waferData = {
      dies: data.dies,
      waferMaxCols: maxRowsAndCols.maxCols,
      waferMaxRows: maxRowsAndCols.maxRows,
    };
    this.actionHandler = data.actionHandler;
    this.pageNumber = data.pageNumber;
    this.referenceReticleGridRectCoords = data.referenceReticleGridRectCoords;
    this.reticleGridRectCoords = data.reticleGridRectCoords;
    this.waferOriginLocation = data.waferOriginLocation;
    this.colAxisDirection = data.colAxisDirection;
    this.rowAxisDirection = data.rowAxisDirection;
    this.keyIndex = data.keyIndex;
    this.programNormal = data.programNormal;
    this.programColorBased = data.programColorBased;
    this.programTexture = data.programTexture;
    this.programCircle = data.programCircle;
    this.gl = data.gl;
    this.textCtx = data.textCtx;
    this.hasMarkingFeature = data.hasMarkingFeature;
    this.hasRotationControls = data.hasRotationControls;
    this.hasFlippingControls = data.hasFlippingControls;
    this.notchPosition = data.notchPosition;
    this.waferWidthToColsRatio = data.waferWidthToColsRatio;
    this.waferHeightToRowsRatio = data.waferHeightToRowsRatio;
    this.waferBGOffsetXDies = data.waferBGOffsetXDies;
    this.waferBGOffsetYDies = data.waferBGOffsetYDies;
    this.waferTopOffset = data.waferTopOffset;
    this.showRing = data.showRing;
    this.dieWidthToStreetWidthRatio = data.dieWidthToStreetWidthRatio;
    this.dieHeightToStreetHeightRatio = data.dieHeightToStreetHeightRatio;
    this.rowOffset = data.rowOffset;
    this.colOffset = data.colOffset;
    this.wcmWaferDiameter = data.wcmWaferDiameter;
    this.showRadar = data.showRadar;
    this.dieTypeField = data.dieTypeField;
    this.ringDiameterToWaferDiameterRatio = data.ringDiameterToWaferDiameterRatio;
    this.overlayReticle = data.overlayReticle;
    this.markWaferCenter = data.markWaferCenter;
    this.dieTextField = data.dieTextField;
    this.showDieText = data.showDieText;
    this.reticleSize = data.reticleSize;
    this.reticleReference = data.reticleReference;
    this.currentDieType = data.currentDieType;
    this.dieTypes = data.dieTypes;
    this.showReferenceReticle = data.showReferenceReticle;
    this.zones = data.zones;
    this.wcmWaferEdgeExclusion = data.wcmWaferEdgeExclusion;
    this.wcmWaferNotchKeepOut = data.wcmWaferNotchKeepOut;
    this.wcmWaferFlatKeepOut = data.wcmWaferFlatKeepOut;
    this.wcmWaferBaseFlat = data.wcmWaferBaseFlat;
    this.wcmWaferScribeLine = data.wcmWaferScribeLine;
    this.wcmExclusionType = data.wcmExclusionType;
    this.waferBgImageTop = data.waferBgImageTop;
    this.waferBgImageBottom = data.waferBgImageBottom;
    this.waferBgImageLeft = data.waferBgImageLeft;
    this.waferBgImageRight = data.waferBgImageRight;
    this.waferBgImageTopFlat = data.waferBgImageTopFlat;
    this.waferBgImageBottomFlat = data.waferBgImageBottomFlat;
    this.waferBgImageLeftFlat = data.waferBgImageLeftFlat;
    this.waferBgImageRightFlat = data.waferBgImageRightFlat;
    this.shouldUseOnlyBinColor = data.shouldUseOnlyBinColor;
    this.standardReticle = data.standardReticle;
    this.setRotationParams(data.rotationAngle);
    this.dieWidthToHeightRatio = data.dieSize.dieWidth / data.dieSize.dieHeight;
    this.onSetOuterViewport(data.outerViewPort, false);
    this.onSelectionChanged = data.onSelectionChanged;
    this.onWATPCMNumberMarked = data.onWATPCMNumberMarked;
    this.rowsTakenByWaferBG = data.rowsTakenByWaferBG;
    this.colsTakenByWaferBG = data.colsTakenByWaferBG;
    this.externalLegendFields = data.externalLegendFields;
    if (data.tooltipFields) this.tooltipFields = _.cloneDeep(data.tooltipFields);
  };

  processData = () => {
    // eslint-disable-next-line no-param-reassign
    this.reticleGridRectCoords = {};
    for (let i = 0; i < this.waferData.dies.length; i += 1) {
      for (let j = 0; j < this.waferData.dies[i].length; j += 1) {
        const dieData = this.waferData.dies[i][j];
        if (dieData) {
          dieData.y = UtilityFunctions.getDieXOrY(
            this.rowFlip === RowFlip.Upright,
            this.rowAxisStart,
            this.rowAxisIncrement,
            this.rowAxisDirection === RowAxisDirection.TopToBottom,
            i,
            this.rowOffset,
            this.waferData.waferMaxRows,
          );
          dieData.x = UtilityFunctions.getDieXOrY(
            this.colFlip === ColFlip.Upright,
            this.colAxisStart,
            this.colAxisIncrement,
            this.colAxisDirection === ColAxisDirection.LeftToRight,
            j,
            this.colOffset,
            this.waferData.waferMaxCols,
          );
          dieData.xTrue = UtilityFunctions.getDieTrueXOrY(dieData.x, this.colAxisDirection === ColAxisDirection.LeftToRight, this.waferData.waferMaxCols, this.colOffset);
          dieData.yTrue = UtilityFunctions.getDieTrueXOrY(dieData.y, this.rowAxisDirection === RowAxisDirection.TopToBottom, this.waferData.waferMaxRows, this.rowOffset);
          if ('reticleX' in dieData && 'reticleY' in dieData) {
            const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, this.reticleSize);
            // eslint-disable-next-line no-param-reassign
            this.reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)] = {
              isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
            };
            if (dieData.referenceReticle) {
              // eslint-disable-next-line no-param-reassign
              this.referenceReticleGridRectCoords = _.cloneDeep(reticleRectCoordOfCurrDie);
            }
          }
        }
      }
    }
  };

  getReticleAxisReferenceUsingMin = (axis: 'x' | 'y'): number => {
    const reticles = Object.keys(this.reticleGridRectCoords);
    let minStart = Number.MAX_VALUE;
    for (let i = 0; i < reticles.length; i += 1) {
      const reticleRect: RectCoord = JSON.parse(reticles[i]);
      const reticleStartVal = axis === 'x' ? reticleRect.startX : reticleRect.startY;
      if (reticleStartVal < minStart) {
        minStart = reticleStartVal;
      }
    }
    return minStart;
  };

  getReticleAxisReferenceUsingHomeDie = (axis: 'x' | 'y', homeDieReticleNumber: number): number => {
    let homeDieId = null;
    if (this.dieTypes && this.dieTypes.dieType) homeDieId = UtilityFunctions.getDieTypeIdFromName('Home Die', this.dieTypes.dieType);
    if (!homeDieId) {
      return this.getReticleAxisReference(axis);
    }
    for (let i = 0, p = this.startRow; i < this.waferData.dies.length; i += 1, p += this.rowDirection) {
      for (let j = 0, q = this.startCol; j < this.waferData.dies[i].length; j += 1, q += this.colDirection) {
        const dieData = this.waferData.dies[p] === undefined ? undefined : this.waferData.dies[p][q];
        if (dieData && dieData.dieType === homeDieId) {
          const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, this.reticleSize);
          if (axis === 'x') {
            return reticleRectCoordOfCurrDie.startX - homeDieReticleNumber * this.reticleSize.x;
          } else {
            return reticleRectCoordOfCurrDie.startY - homeDieReticleNumber * this.reticleSize.y;
          }
        }
      }
    }
    return this.getReticleAxisReference(axis);
  };

  getReticleAxisReference = (axis: 'x' | 'y', useMin = true, homeDieReticleNumber = 0): number => {
    if (useMin) {
      return this.getReticleAxisReferenceUsingMin(axis);
    } else {
      return this.getReticleAxisReferenceUsingHomeDie(axis, homeDieReticleNumber);
    }
  };

  setMaxRowsCols = () => {
    const maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(this.waferData.dies);
    this.waferData.waferMaxCols = maxRowsAndCols.maxCols;
    this.waferData.waferMaxRows = maxRowsAndCols.maxRows;
  };

  onSetOuterViewport = (outerViewPort: Viewport, viewportDimensionChange = true) => {
    this.setMaxRowsCols();
    this.outerViewPort = outerViewPort;
    this.innerViewPort = {
      x: outerViewPort.x + this.viewPortPaddingX * outerViewPort.width,
      y: outerViewPort.y + this.viewPortPaddingY * outerViewPort.height,
      width: outerViewPort.width - 2 * this.viewPortPaddingX * outerViewPort.width,
      height: outerViewPort.height - 2 * this.viewPortPaddingY * outerViewPort.height,
    };
    this.radarDiesGLCoords = null;
    this.radarWaferBgImageGLCoords = null;
    this.setRadarViewport(outerViewPort);
    this.setScaledDimensionsAndAnchors(this.waferData.waferMaxCols, this.waferData.waferMaxRows, this.dieWidthToHeightRatio, this.innerViewPort, viewportDimensionChange);
    if (this.outerViewPortWithRadar === null) {
      if (this.showRadar) {
        this.outerViewPortWithRadar = { ...this.outerViewPort };
      } else {
        this.outerViewPortWithRadar = {
          x: this.outerViewPort.x,
          y: this.outerViewPort.y + this.outerViewPort.height * (1 / 4),
          width: this.outerViewPort.width * (3 / 4),
          height: this.outerViewPort.height * (3 / 4),
        };
      }
    }
    if (this.outerViewPortWithoutRadar === null) {
      if (this.showRadar) {
        this.outerViewPortWithoutRadar = {
          x: this.outerViewPort.x,
          y: this.outerViewPort.y - this.outerViewPort.height * (1 / 3),
          width: this.outerViewPort.width * (4 / 3),
          height: this.outerViewPort.height * (4 / 3),
        };
      } else {
        this.outerViewPortWithoutRadar = { ...this.outerViewPort };
      }
    }
  };
}

export default WaferMapVariablesV2;
