import React from 'react';
import { Viewport } from '../../wafer-map/web-gl-utils/Types';
import WaferMainControls, { WaferMainControlsProps } from './WaferMainControls';

type WaferPinnedControlsProps = {
  outerViewPort: Viewport,
  waferMainControlsProps: WaferMainControlsProps,
};

export const WaferPinnedControls = (props: WaferPinnedControlsProps) => {
  const { outerViewPort, waferMainControlsProps } = props;

  return (
    <div
      style={{
        position: 'absolute',
        width: `${outerViewPort.width}px`,
        height: '30px',
        bottom: `${outerViewPort.height + outerViewPort.y}px`,
        left: `${outerViewPort.x}px`,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <WaferMainControls
        featureSpecificWaferTags={waferMainControlsProps.featureSpecificWaferTags}
        waferStateStacks={waferMainControlsProps.waferStateStacks}
        onToggleRedoPopover={waferMainControlsProps.onToggleRedoPopover}
        showRedoPopover={waferMainControlsProps.showRedoPopover}
        showUndoPopover={waferMainControlsProps.showUndoPopover}
        actionHandler={waferMainControlsProps.actionHandler}
        onToggleUndoPopover={waferMainControlsProps.onToggleUndoPopover}
        dragChangeHandler={waferMainControlsProps.dragChangeHandler}
        zoomChangeHandler={waferMainControlsProps.zoomChangeHandler}
        hasMarkingFeature={waferMainControlsProps.hasMarkingFeature}
        hasRotationControls={waferMainControlsProps.hasRotationControls}
        isBoxZoomControlActive={waferMainControlsProps.isBoxZoomControlActive}
        isDragControlActive={waferMainControlsProps.isDragControlActive}
        isSelectionControlActive={waferMainControlsProps.isSelectionControlActive}
        isMarkingControlActive={waferMainControlsProps.isMarkingControlActive}
        markingMode={waferMainControlsProps.markingMode}
        keyIndex={waferMainControlsProps.keyIndex}
        selectionChangeHandler={waferMainControlsProps.selectionChangeHandler}
        rotateWaferMap={waferMainControlsProps.rotateWaferMap}
        resetWaferZoomPositionRotation={waferMainControlsProps.resetWaferZoomPositionRotation}
        zoomWaferMapUsingButtons={waferMainControlsProps.zoomWaferMapUsingButtons}
        clearSelectionHandler={waferMainControlsProps.clearSelectionHandler}
        showRadar={waferMainControlsProps.showRadar}
        showRadarHandler={waferMainControlsProps.showRadarHandler}
      />
    </div>
  );
};
