import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle } from '../Utils/CustomAccordianToggle';
import { WaferAdditionalControl } from '../WaferPlotter/WaferPlotter';

type WaferDraggableControlTabProps = {
  onChangeWidgetAccordian: (eventKey: string) => void,
  controlProps: WaferAdditionalControl,
};

export const WaferDraggableControlTab = (props: WaferDraggableControlTabProps) => {
  const { onChangeWidgetAccordian, controlProps } = props;

  return (
    <Card style={{ borderRadius: '0px' }}>
      <CustomToggle eventKey={controlProps.eventKey} setCallback={onChangeWidgetAccordian}>
        <h6>
          {controlProps.title}
        </h6>
      </CustomToggle>
      <Accordion.Collapse eventKey={controlProps.eventKey}>
        <Card.Body>
          <div style={controlProps.type && controlProps.type === 'OVERLAY_CONTROLS' ? { maxHeight: 250, overflow: 'scroll' } : undefined}>
            { controlProps.body }
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
