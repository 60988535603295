/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-unused-vars
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import PublishSubscribe, { EventTypes } from 'components/utility-component/wafer-map-widget/PublishSubscribe';
import {
  DieType,
  ReticleReference,
  WaferDefinitionData,
  WaferMapConfig,
  ZoneData,
  StandardReticle,
  ComponentHeaderData,
  ReticleSiteAttributeMeta,
  ReticleGridRectCoords,
  RectCoord,
} from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import { CheckBox, ColorBox, DataGrid, } from 'devextreme-react';
import {
  Column, Editing, PatternRule, Scrolling, Selection,
} from 'devextreme-react/data-grid';
import _ from 'lodash';
import React from 'react';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import toast from 'CustomToast';
import './wafer-control-map.scss';
// eslint-disable-next-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown, faArrowLeft, faArrowRight, faArrowsAlt, faArrowUp, faCrosshairs, faMinus, faPlus, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import AttributesGrid from 'components/device-setup/attributes-grid';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
// eslint-disable-next-line no-unused-vars
import ComponentHeader, { componentHeaderStates } from 'components/wrapped-component/component-header/component-header';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import {
  ActiveTab,
  CalculationMethod,
  DieColorType,
  ExclusionType,
  MarkingMode,
  MiddleViewport,
  NotchPosition,
  SelectionMode,
  ActionOnWaferData,
  WaferAxisDirection,
  WaferOriginLocation,
  DieSpecType,
  MetaColumnType,
  FeatureSpecificWaferTag,
} from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
import WaferPlotter, { ActionType, WaferAdditionalControl } from 'components/utility-component/wafer-map-widget/wafer-map-v2/WaferPlotter/WaferPlotter';
import WaferUtils from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferUtils';
import WaferMapVariablesV2, { DieData, Dies } from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferMapVariablesClassV2';
import { httpWaferControlMap } from 'services/http.wafer-control-map';
import { httpDieType } from 'services/http.die-types';
import EditableDataGrid from 'components/wrapped-component/editable-data-grid/EditableDataGrid';
import WaferDefinition from './wafer-definition';
// eslint-disable-next-line no-unused-vars
import { DrawLayoutData, DrawLayoutPanResizeMode } from './DrawLayout';
import { UtilityFunctions } from './utility';
import PCMWATSiteMapTab, {
  PCMWATSiteMapTabData, ReticleCalculationMethod, ReticleInsertDirection, ReticlePanDirection, ReticlePanMode,
} from './pcm-wat-site-map-tab';
import DieCountSummary from './die-count-summary';
import PCMWATSiteMapSideBar from './pcm-wat-site-map-side-bar';
// eslint-disable-next-line no-unused-vars
import DefineZonesTab from './define-zones';
import HomeDieCoordsForm from './home-die-coords-form';

type WaferControlMapState = {
  name: string,
  owner: string,
  access: string,
  version: number,
  state: string,
  description: string,
  waferDefinitionData: WaferDefinitionData,
  ringPanOffsetXMultiplier: number,
  ringPanOffsetYMultiplier: number,
  drawLayout: DrawLayoutData,
  pcmWatSiteMapTabData: PCMWATSiteMapTabData,
  dieTypes: DieType[],
  currentDieTypeId: string | null,
  waferMapConfig: WaferMapConfig | null,
  orignalWaferDimensions: { height: number, width: number },
  middleViewport: MiddleViewport,
  activeTab: ActiveTab,
  ringDiameterToWaferDiameterRatio: number,
  collapseTabsColumn: boolean,
  collapseDieSummaryColumn: boolean,
  allWCMAttributeMetas: any[],
  WCMAttributeDefinitions: any[],
  pcmWatTestMethod: string,
  watProbingSequence: string,
  pcmWatSiteNumber: number | null,
  maxPcmWatSiteNumber: number,
  showWATPCMSites: boolean,
  zones: ZoneData[],
  selectedZoneId: string | null,
  isInSavingState: boolean,
  isLoading: boolean,
};

export const UOMs = [['MILLIMETERS', 'Millimeter'], ['INCHES', 'Inches'], ['MICRONS', 'Microns'], ['CENTIMETERS', 'Centimeter'], ['MILS', 'Mils']];

export const reticleIncrementUOMs = [['die', 'Die'], ['reticle', 'Reticle']];

export function DIAMETERS(UOM: string): string[][] {
  return [['3', '3'], ['4', '4'], ['6', '6'], ['8', '8'], ['12', '12']].map((d: string[]) => {
    return [
      UtilityFunctions.inchToOtherUnit(+d[0], UOM, true).toString(),
      UtilityFunctions.inchToOtherUnit(+d[1], UOM, true).toString(),
    ];
  });
}

export const NOTCH_POSITIONS = [['DOWN', 'Down'], ['UP', 'Up'], ['LEFT', 'Left'], ['RIGHT', 'Right']];

export const WAFER_AXIS_DIRECTION: {
  axisDirection: WaferAxisDirection,
  label: string,
}[] = [
  {
    axisDirection: WaferAxisDirection.UP_RIGHT,
    label: 'Up Right',
  },
  {
    axisDirection: WaferAxisDirection.DOWN_RIGHT,
    label: 'Down Right',
  },
  {
    axisDirection: WaferAxisDirection.UP_LEFT,
    label: 'Up Left',
  },
  {
    axisDirection: WaferAxisDirection.DOWN_LEFT,
    label: 'Down Left',
  },
];

export const WAFER_ORIGIN_LOCATION: {
  originLocation: WaferOriginLocation,
  label: string,
}[] = [
  {
    originLocation: WaferOriginLocation.UPPER_RIGHT,
    label: 'Upper Right',
  },
  {
    originLocation: WaferOriginLocation.UPPER_LEFT,
    label: 'Upper Left',
  },
  {
    originLocation: WaferOriginLocation.LOWER_RIGHT,
    label: 'Lower Right',
  },
  {
    originLocation: WaferOriginLocation.LOWER_LEFT,
    label: 'Lower Left',
  },
  {
    originLocation: WaferOriginLocation.CENTER,
    label: 'Center',
  },
];

export const STANDARD_RETICLE_WATPCM_SITE_LIST = [
  ['Street Top', 'Street Top'],
  ['Street Bottom', 'Street Bottom'],
  ['Street Left', 'Street Left'],
  ['Street Right', 'Street Right'],
];

export const DEFAULT_DIE_COLOR = '#666666';

export const NOTCH_FLAT_TOGGLE_DIAMETER_VALUE = 200;

export const WAFER_DIAMETER_EXEMPTED_FROM_RETICLE_COLLISION_CROPPING = 100;

const DEVICE_WAFER_KEY = 'deviceWafer';

const WAFER_PLOTTER_CANVAS_HEIGHT = 2500;

enum WCMContextMenuAction { COLLAPSE_WAFER, EXPAND_WAFER, COLLAPSE_DEFINITION_TABS, EXPAND_DEFINITION_TABS, COLLAPSE_DIE_SUMMARY, EXPAND_DIE_SUMMARY }

class WaferControlMap extends React.Component<any, WaferControlMapState> {
  dieTypesDatagrid: DataGrid | null = null;

  zonesDatagrid: DataGrid | null = null;

  waferDefinitionRef: any = null;

  waferDiv: any = null;

  plotter: WaferPlotter | null = null;

  canvasDimension = 800;

  deviceWafer: WaferUtils | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      isInSavingState: false,
      name: '',
      owner: '',
      access: 'PUBLIC',
      version: 1,
      state: componentHeaderStates[0][0],
      description: '',
      allWCMAttributeMetas: [],
      WCMAttributeDefinitions: [],
      collapseTabsColumn: false,
      collapseDieSummaryColumn: false,
      pcmWatTestMethod: 'sampling',
      watProbingSequence: 'top_left_z_pattern',
      pcmWatSiteNumber: null,
      maxPcmWatSiteNumber: 10,
      showWATPCMSites: true,
      selectedZoneId: null,
      waferDefinitionData: {
        version: 1, // only for frontend
        waferMaxCols: 0,
        waferMaxRows: 0,
        waferRowOffset: 0,
        waferColOffset: 0,
        calculationMethod: CalculationMethod.AUTO,
        waferDiameterUOM: UOMs[1][0],
        waferEdgeExclusionUOM: UOMs[0][0],
        waferFlatKeepOutUOM: UOMs[0][0],
        waferNotchKeepOutUOM: UOMs[0][0],
        waferBaseFlatUOM: UOMs[0][0],
        waferScribeLineUOM: UOMs[0][0],
        waferDiameter: +DIAMETERS(UOMs[1][0])[4][0],
        waferEdgeExclusion: 0,
        waferFlatKeepOut: 0,
        waferNotchKeepOut: 0,
        waferBaseFlat: 0,
        waferScribeLine: 0,
        waferInputNotchPosition: NOTCH_POSITIONS[0][0],
        waferOutputNotchPosition: NOTCH_POSITIONS[0][0],
        waferOriginLocation: WaferOriginLocation.UPPER_LEFT,
        waferAxisDirection: WaferAxisDirection.DOWN_RIGHT,
        waferPGDW: 0,
        dieUOM: UOMs[3][0],
        dieSpecType: DieSpecType.BOTH,
        dieSizeX: 2,
        dieSizeY: 2,
        dieStepSizeX: 3,
        dieStepSizeY: 3,
        reticleUOM: UOMs[0][0],
        reticleSizeX: 2,
        reticleSizeY: 2,
        reticleOffsetX: 0,
        reticleOffsetY: 0,
        reticleReference: 'CENTER',
        standardReticle: {
          WATPCMSite: STANDARD_RETICLE_WATPCM_SITE_LIST[0][0],
          reticle: [],
          siteAttributesData: [],
          hasSiteAttributes: false,
          reticleSiteAttributesMeta: [],
        },
        exclusionType: ExclusionType.NONE,
      },
      zones: [],
      orignalWaferDimensions: { height: 0, width: 0 },
      ringPanOffsetXMultiplier: 0,
      ringPanOffsetYMultiplier: 0,
      ringDiameterToWaferDiameterRatio: 1,
      drawLayout: {
        ringSize: 0,
        ringUOM: UOMs[0][0],
        increment: 1,
        incrementUOM: UOMs[0][0],
        overlayReticle: false,
        markWaferCenter: true,
        drawLayoutPanResizeMode: DrawLayoutPanResizeMode.RING,
        showReferenceReticle: false,
        showRing: false,
      },
      pcmWatSiteMapTabData: {
        reticleCalculationMethod: ReticleCalculationMethod.ROWS_COLS,
        maxReticleCols: 0,
        maxReticleRows: 0,
        panIncrement: 1,
        panIncrementUOM: reticleIncrementUOMs[0][0],
        panMode: ReticlePanMode.SELECTED,
      },
      activeTab: ActiveTab.WAFER_DEFINTION,
      middleViewport: MiddleViewport.WAFER,
      waferMapConfig: null,
      currentDieTypeId: null,
      dieTypes: [],
    };
  }

  setWaferInstanceHandler = (utils: WaferUtils) => {
    this.deviceWafer = utils;
    this.setSubsribers();
  };

  hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : {
      r: 0,
      g: 0,
      b: 0,
    };
  };

  loadAttributeMetaAndDefinitions = async (loadWhat: 'meta' | 'def' | 'both' = 'both') => {
    const { match } = this.props;
    let wcmAttributeDefinitionResponse: any = null;
    let wcmAttributeMetaResponse: any = null;
    if (loadWhat === 'both' || loadWhat === 'meta') {
      wcmAttributeMetaResponse = await httpWaferControlMap.getWaferControlMapAttributeMetas();
    }
    if (match.params.id !== undefined && (loadWhat === 'both' || loadWhat === 'def')) {
      wcmAttributeDefinitionResponse = await httpWaferControlMap.getWaferControlMapAttributeDefinitions(match.params.id);
    }
    const newState: any = {};
    if (loadWhat === 'both' || loadWhat === 'def') {
      newState.WCMAttributeDefinitions = [];
    }
    if (loadWhat === 'both' || loadWhat === 'meta') {
      newState.allWCMAttributeMetas = [];
    }

    if (wcmAttributeMetaResponse && wcmAttributeMetaResponse.success && wcmAttributeMetaResponse.statusCode === 200) {
      newState.allWCMAttributeMetas = wcmAttributeMetaResponse.data;
    }
    if (wcmAttributeDefinitionResponse && wcmAttributeDefinitionResponse.success && wcmAttributeDefinitionResponse.statusCode === 200) {
      newState.WCMAttributeDefinitions = this.mapAttributeDefinitions(wcmAttributeDefinitionResponse.data);
    }
    return newState;
  };

  loadDataFromBackend = async () => {
    this.setState({ isLoading: true }, async () => {
      const { match, history } = this.props;
      let wcmDataResponse: any = null;
      if (match.params.id !== undefined) {
        wcmDataResponse = await httpWaferControlMap.getWaferControlMap(match.params.id);
      }
      const dieTypesResponse = await httpDieType.getDieTypes();
      const attrMetaAndDefs = await this.loadAttributeMetaAndDefinitions();
      let isWaferDataLoadedSuccessfully = false;
      this.setState((prevState: WaferControlMapState) => {
        const newState = {
          isLoading: false,
          allWCMAttributeMetas: prevState.allWCMAttributeMetas,
          WCMAttributeDefinitions: prevState.WCMAttributeDefinitions,
          zones: prevState.zones,
          dieTypes: prevState.dieTypes,
          currentDieTypeId: prevState.currentDieTypeId,
          ringDiameterToWaferDiameterRatio: prevState.ringDiameterToWaferDiameterRatio,
          middleViewport: prevState.middleViewport,
          orignalWaferDimensions: prevState.orignalWaferDimensions,
          waferMapConfig: prevState.waferMapConfig,
          waferDefinitionData: prevState.waferDefinitionData,
          drawLayout: prevState.drawLayout,
          name: prevState.name,
          owner: prevState.owner,
          version: prevState.version,
          access: prevState.access,
          state: prevState.state,
          description: prevState.description,
        };

        if (dieTypesResponse && dieTypesResponse.success && dieTypesResponse.statusCode === 200) {
          newState.dieTypes = dieTypesResponse.data;
          newState.currentDieTypeId = newState.dieTypes.length > 0 ? newState.dieTypes[0].id : null;
        }
        newState.allWCMAttributeMetas = attrMetaAndDefs.allWCMAttributeMetas;
        newState.WCMAttributeDefinitions = attrMetaAndDefs.WCMAttributeDefinitions;
        if (wcmDataResponse && wcmDataResponse.success && wcmDataResponse.statusCode === 200) {
          isWaferDataLoadedSuccessfully = true;
          const waferDefinitionData: WaferDefinitionData = {
            calculationMethod: CalculationMethod[wcmDataResponse.data.drawMethod] as unknown as CalculationMethod,
            dieSizeX: wcmDataResponse.data.dieWidth,
            dieSizeY: wcmDataResponse.data.dieHeight,
            dieSpecType: DieSpecType[wcmDataResponse.data.dieDimensionType] as unknown as DieSpecType,
            waferEdgeExclusionUOM: wcmDataResponse.data.edgeExclusionUOM,
            dieStepSizeX: wcmDataResponse.data.dieStepWidth,
            dieStepSizeY: wcmDataResponse.data.dieStepHeight,
            reticleOffsetX: wcmDataResponse.data.reticleOffsetX,
            reticleOffsetY: wcmDataResponse.data.reticleOffsetY,
            dieUOM: wcmDataResponse.data.dieUOM,
            exclusionType: ExclusionType[wcmDataResponse.data.exclusionOption] as unknown as ExclusionType,
            reticleReference: wcmDataResponse.data.reticleReference,
            reticleSizeX: wcmDataResponse.data.reticleCols,
            reticleSizeY: wcmDataResponse.data.reticleRows,
            reticleUOM: wcmDataResponse.data.reticleUOM,
            waferAxisDirection: WaferAxisDirection[wcmDataResponse.data.axisDirection] as unknown as WaferAxisDirection,
            waferBaseFlat: wcmDataResponse.data.baseFlat,
            waferBaseFlatUOM: wcmDataResponse.data.baseFlatUOM,
            waferColOffset: wcmDataResponse.data.axisOffsetX,
            waferDiameter: wcmDataResponse.data.diameter,
            waferDiameterUOM: wcmDataResponse.data.diameterUOM,
            waferEdgeExclusion: wcmDataResponse.data.edgeExclusion,
            waferFlatKeepOut: wcmDataResponse.data.flatKeepOut,
            waferFlatKeepOutUOM: wcmDataResponse.data.flatKeepOutUOM,
            waferInputNotchPosition: wcmDataResponse.data.inputNotchPosition,
            waferOutputNotchPosition: wcmDataResponse.data.outputNotchPosition,
            waferMaxCols: wcmDataResponse.data.fixedCols,
            waferMaxRows: wcmDataResponse.data.fixedRows,
            waferNotchKeepOut: wcmDataResponse.data.notchKeepOut,
            waferNotchKeepOutUOM: wcmDataResponse.data.notchKeepOutUOM,
            waferOriginLocation: WaferOriginLocation[wcmDataResponse.data.originLocation] as unknown as WaferOriginLocation,
            waferPGDW: wcmDataResponse.data.pgdw,
            waferRowOffset: wcmDataResponse.data.axisOffsetY,
            waferScribeLine: wcmDataResponse.data.scribeLine,
            waferScribeLineUOM: wcmDataResponse.data.scribeLineUOM,
            version: 1,
            standardReticle: {
              WATPCMSite: wcmDataResponse.data.reticleWATPCMSite,
              reticle: this.getStandardReticleEditorReticle(wcmDataResponse.data.reticleSites),
              siteAttributesData: this.getSiteAttributesData(wcmDataResponse.data.reticleSiteAttributeMappings, wcmDataResponse.data.reticleSiteAttributes),
              hasSiteAttributes: wcmDataResponse.data.hasSiteAttributes,
              reticleSiteAttributesMeta: wcmDataResponse.data.reticleSiteAttributes.map((rsa: any) => {
                return { ...rsa, columnMeta: { ...rsa.columnMeta, type: MetaColumnType[rsa.columnMeta.type] as unknown as MetaColumnType } };
              }),
            },
          };
          const waferDiameter = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true });
          const waferEdgeExclusion = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferEdgeExclusion, UOM: waferDefinitionData.waferEdgeExclusionUOM });
          const dieStepSizeX = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeX, UOM: waferDefinitionData.dieUOM });
          const dieStepSizeY = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeY, UOM: waferDefinitionData.dieUOM });

          const newDrawLayout = _.cloneDeep(prevState.drawLayout);
          newDrawLayout.overlayReticle = wcmDataResponse.data.overlayReticle;
          newDrawLayout.showReferenceReticle = wcmDataResponse.data.overlayReticle && waferDefinitionData.calculationMethod === CalculationMethod.RETICLE;
          newDrawLayout.ringSize = waferEdgeExclusion;
          newDrawLayout.showRing = false;
          const dies = this.getDies2D(wcmDataResponse.data.dies, waferDefinitionData.standardReticle);
          const maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(dies);
          const reticleData = this.getReticleGridRectCoords(wcmDataResponse.data.reticles);
          waferDefinitionData.version = prevState.waferDefinitionData.version + 1;

          newState.zones = wcmDataResponse.data.zones.map((z: any) : ZoneData => {
            return {
              id: z.id,
              zoneName: z.name,
              zoneType: z.type,
              markedPCMSiteNumbers: z.markedPCMSiteNumbers,
              numberOfZones: z.numberOfZones,
              zoneApplication: z.zoneApplication,
              createdBy: z.createdBy,
              createdOn: z.createdOn,
              updatedBy: z.updatedBy,
              updatedOn: z.updatedOn,
              horizontalZoneRowNumbers: z.horizontalZoneRowNumbers,
              verticalZoneColNumbers: z.verticalZoneColNumbers,
              xcoords: z.xCoords,
              ycoords: z.yCoords,
            };
          });
          newState.ringDiameterToWaferDiameterRatio = (waferDiameter - 2 * waferEdgeExclusion) / waferDiameter;
          newState.middleViewport = MiddleViewport.WAFER;
          newState.orignalWaferDimensions = { height: wcmDataResponse.data.waferHeightOrignal, width: wcmDataResponse.data.waferWidthOrignal };
          newState.waferMapConfig = {
            data: { dies, waferMaxRows: maxRowsAndCols.maxRows, waferMaxCols: maxRowsAndCols.maxCols },
            reticleGridRectCoords: reticleData.reticleGridRectCoords,
            referenceReticleGridRectCoords: reticleData.referenceReticleGridRectCoords,
            dieSize: {
              dieWidth: dieStepSizeX > dieStepSizeY ? 32 * (dieStepSizeX / dieStepSizeY) : 32,
              dieHeight: dieStepSizeY > dieStepSizeX ? 32 * (dieStepSizeY / dieStepSizeX) : 32,
            },
            showDieText: waferDefinitionData.calculationMethod === CalculationMethod.RETICLE,
            reticleSize: {
              x: waferDefinitionData.standardReticle.reticle.length > 0 ? waferDefinitionData.standardReticle.reticle[0].length : 0,
              y: waferDefinitionData.standardReticle.reticle.length,
            },
            dieTextField: waferDefinitionData.calculationMethod === CalculationMethod.RETICLE ? 'reticleSite' : '',
            dieWidthToStreetWidthRatio:
              wcmDataResponse.data.dieWidth === wcmDataResponse.data.dieStepWidth
                ? Infinity : wcmDataResponse.data.dieWidthToStreetWidthRatio,
            dieHeightToStreetHeightRatio:
              wcmDataResponse.data.dieHeight === wcmDataResponse.data.dieStepHeight
                ? Infinity : wcmDataResponse.data.dieHeightToStreetHeightRatio,
            waferWidthToColsRatio: wcmDataResponse.data.waferWidthToColsRatio,
            waferHeightToRowsRatio: wcmDataResponse.data.waferHeightToRowsRatio,
            waferBGOffsetXDies: wcmDataResponse.data.waferBGOffsetXDies,
            waferBGOffsetYDies: wcmDataResponse.data.waferBGOffsetYDies,
          };
          newState.waferDefinitionData = waferDefinitionData;
          newState.drawLayout = newDrawLayout;
          newState.name = wcmDataResponse.data.name;
          newState.owner = wcmDataResponse.data.owner;
          newState.version = wcmDataResponse.data.version;
          newState.access = wcmDataResponse.data.access;
          newState.state = wcmDataResponse.data.state;
          newState.description = wcmDataResponse.data.description;
        }
        return newState;
      }, () => {
        if (isWaferDataLoadedSuccessfully) {
          this.addWafer(true);
        } else {
          if (wcmDataResponse) toast.error(wcmDataResponse.message);
          history.push('/wafer-control-map');
        }
      });
    });
  };

  async componentDidMount() {
    if (this.waferDiv) {
      this.canvasDimension = this.waferDiv.offsetWidth;
    }
    await this.loadDataFromBackend();
  }

  getSiteAttributesData = (reticleSiteAttributeMappings: { [siteNumber: number]: { [columnName: string]: string } }, reticleSiteAttributes: ReticleSiteAttributeMeta[]) => {
    const dataTypeMappings: { [columnName: string]: MetaColumnType } = {};
    for (let i = 0; i < reticleSiteAttributes.length; i += 1) {
      dataTypeMappings[reticleSiteAttributes[i].columnMeta.columnName] = MetaColumnType[reticleSiteAttributes[i].columnMeta.type] as unknown as MetaColumnType;
    }
    const siteAttributesData: any[] = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const siteNumber in reticleSiteAttributeMappings) {
      const row: { [columnName: string]: any } = { siteNumber: +siteNumber };
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const columnName in reticleSiteAttributeMappings[siteNumber]) {
        const value = reticleSiteAttributeMappings[siteNumber][columnName];
        if (columnName !== 'siteNumber') {
          if (dataTypeMappings[columnName] === MetaColumnType.BOOL) {
            row[columnName] = value === 'true';
          } else if (dataTypeMappings[columnName] === MetaColumnType.DECIMAL) {
            row[columnName] = +value;
          } else if (dataTypeMappings[columnName] === MetaColumnType.VARCHAR) {
            row[columnName] = value;
          } else if (dataTypeMappings[columnName] === MetaColumnType.DATETIME) {
            row[columnName] = Date.parse(value);
          }
        }
      }
      siteAttributesData.push(row);
    }
    return siteAttributesData;
  };

  getDies2D = (dies: any[], standardReticle?: StandardReticle) => {
    let maxRow = -1;
    let maxCol = -1;

    for (let i = 0; i < dies.length; i += 1) {
      if (dies[i].dieRow > maxRow) {
        maxRow = dies[i].dieRow;
      }
      if (dies[i].dieCol > maxCol) {
        maxCol = dies[i].dieCol;
      }
    }

    const dies2D: Dies = [];

    for (let i = 0; i < maxRow + 1; i += 1) {
      dies2D.push([]);
      for (let j = 0; j < maxCol + 1; j += 1) {
        dies2D[i].push(null);
      }
    }
    for (let i = 0; i < dies.length; i += 1) {
      if (!dies[i].isDieNull) {
        const dieData: DieData = {
          x: dies[i].dieXCalculation,
          y: dies[i].dieYCalculation,
          xTrue: dies[i].dieX,
          yTrue: dies[i].dieY,
          binColor: DEFAULT_DIE_COLOR,
          isSelected: false,
          isCropped: dies[i].isCropped,
          isDeleted: dies[i].isDeleted,
          dieType: dies[i].dieType ? dies[i].dieType.id : null,
        };

        if (dies[i].reticleSubRow !== null) {
          dieData.reticleY = dies[i].reticleSubRow;
        }
        if (dies[i].reticleSubCol !== null) {
          dieData.reticleX = dies[i].reticleSubCol;
        }
        if (dies[i].zoneInfo !== null) {
          dieData.zoneInfo = dies[i].zoneInfo;
        }
        if (standardReticle && dies[i].reticleSubRow !== null && dies[i].reticleSubCol !== null) {
          dieData.reticleSite = standardReticle.reticle[dieData.reticleY][dieData.reticleX];
        }
        dies2D[dies[i].dieRow][dies[i].dieCol] = dieData;
      }
    }
    return dies2D;
  };

  getReticleGridRectCoords = (reticles: any[]) => {
    const reticleGridRectCoords: ReticleGridRectCoords = {};
    let referenceReticleGridRectCoords: RectCoord | null = null;
    for (let i = 0; i < reticles.length; i += 1) {
      const rectCoord: RectCoord = {
        startX: reticles[i].startX,
        startY: reticles[i].startY,
        endX: reticles[i].endX,
        endY: reticles[i].endY,
      };
      if (!reticles[i].isReferenceReticle) {
        reticleGridRectCoords[JSON.stringify(rectCoord)] = {
          isSelected: false, isUnderSelection: false, watPCMSiteNumber: reticles[i].watpcmSiteNumber,
        };
      } else {
        referenceReticleGridRectCoords = rectCoord;
      }
    }
    return { reticleGridRectCoords, referenceReticleGridRectCoords };
  };

  getStandardReticleEditorReticle = (reticleSites: { siteRow: number, siteCol: number, siteNumber: number } []) => {
    let maxRow = -1;
    let maxCol = -1;

    for (let i = 0; i < reticleSites.length; i += 1) {
      if (reticleSites[i].siteRow > maxRow) {
        maxRow = reticleSites[i].siteRow;
      }
      if (reticleSites[i].siteCol > maxCol) {
        maxCol = reticleSites[i].siteCol;
      }
    }
    const standardReticleEditorReticle: number[][] = [];

    for (let i = 0; i < maxRow + 1; i += 1) {
      standardReticleEditorReticle.push([]);
      for (let j = 0; j < maxCol + 1; j += 1) {
        standardReticleEditorReticle[i].push(-1);
      }
    }

    for (let i = 0; i < reticleSites.length; i += 1) {
      standardReticleEditorReticle[reticleSites[i].siteRow][reticleSites[i].siteCol] = reticleSites[i].siteNumber;
    }
    return standardReticleEditorReticle;
  };

  setSubsribers = () => {
    if (!this.deviceWafer) return;
    const ps = PublishSubscribe();
    ps.subscribeToOthersID(
      EventTypes.SELECTION_ON_WAFER,
      () => { PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DEFAULT, config: { } }, 'myid'); },
      DEVICE_WAFER_KEY,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.DIES_MARKED_ON_WAFER,
      (data: any) => {
        this.setState((prevState: WaferControlMapState) => {
          const newWaferDefinitionData = _.cloneDeep(prevState.waferDefinitionData);
          newWaferDefinitionData.waferColOffset = data.colOffset;
          newWaferDefinitionData.waferRowOffset = data.rowOffset;
          newWaferDefinitionData.version += 1;
          return {
            waferDefinitionData: newWaferDefinitionData,
          };
        });
      },
      DEVICE_WAFER_KEY,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.WAFER_DATA_RESPONSE,
      (data: any) => {
        this.setState((prevState: WaferControlMapState) => {
          const newState = {
            waferMapConfig: prevState.waferMapConfig,
            waferDefinitionData: prevState.waferDefinitionData,
          };
          if (prevState.waferMapConfig) {
            const newWaferMapConfig = _.cloneDeep(prevState.waferMapConfig);
            newWaferMapConfig.data = _.cloneDeep(data.data);
            newState.waferMapConfig = newWaferMapConfig;
          }
          if (data.action === ActionOnWaferData.DELETE_ROW_COL || data.action === ActionOnWaferData.SHIFT_INSERT_DIES || data.action === ActionOnWaferData.INSERT_ROW_COL) {
            newState.waferDefinitionData = _.cloneDeep(prevState.waferDefinitionData);
            newState.waferDefinitionData.waferColOffset = data.colOffset;
            newState.waferDefinitionData.waferRowOffset = data.rowOffset;
            newState.waferDefinitionData.version += 1;
          }
          return newState;
        }, async () => {
          await this.callActionHanler(data);
        });
      },
      DEVICE_WAFER_KEY,
      'myid',
    );
    ps.subscribeToOthersID(
      EventTypes.WAFER_DATA_REQUEST,
      this.deviceWafer.performActionAndSendResponse,
      'myid',
      DEVICE_WAFER_KEY,
    );
    ps.subscribeToOthersID(
      EventTypes.CHANGE_WAFER_MAP_VARIABLES,
      this.deviceWafer.changeWaferMapVariables,
      'myid',
      DEVICE_WAFER_KEY,
    );
  };

  callActionHanler = async (data: any) => {
    if (this.plotter) {
      if (data.action === ActionOnWaferData.CROP) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `${UtilityFunctions.upperToLowerCase(ActionOnWaferData[data.action])}`,
          actionUndoMessage: `${UtilityFunctions.upperToLowerCase(ActionOnWaferData[data.action])}`,
        });
      } else if (data.action === ActionOnWaferData.INSERT_ROW_COL) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `Insert ${data.config.dimension} ${data.config.direction}`,
          actionUndoMessage: `Insert ${data.config.dimension} ${data.config.direction}`,
        });
      } else if (data.action === ActionOnWaferData.REDRAW) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Redraw dies',
          actionUndoMessage: 'Redraw dies',
        });
      } else if (data.action === ActionOnWaferData.SHIFT_INSERT_DIES) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `Shift insert ${data.config.direction}`,
          actionUndoMessage: `Shift insert ${data.config.direction}`,
        });
      } else if (data.action === ActionOnWaferData.DELETE_ROW_COL) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `Delete ${data.config.dimension} ${data.config.direction}`,
          actionUndoMessage: `Delete ${data.config.dimension} ${data.config.direction}`,
        });
      } else if (data.action === ActionOnWaferData.SHIFT_DELETE_DIES) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `Shift delete ${data.config.dimension} ${data.config.direction}`,
          actionUndoMessage: `Shift delete ${data.config.dimension} ${data.config.direction}`,
        });
      } else if (data.action === ActionOnWaferData.DELETE_SELECTED_DIES) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Delete selected dies',
          actionUndoMessage: 'Delete selected dies',
        });
      } else if (data.action === ActionOnWaferData.CROP_SELECTED_RETICLES_ON_WAFER){
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Crop reticles',
          actionUndoMessage: 'Crop reticles',
        });
      } else if (data.action === ActionOnWaferData.PAN_RETICLE){
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Pan reticles',
          actionUndoMessage: 'Pan reticles',
        });
      } else if (data.action === ActionOnWaferData.SHIFT_INSERT_RETICLE){
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Insert reticles',
          actionUndoMessage: 'Insert reticles',
        });
      }
    }
  }

  getWaferData = (rows: number, cols: number) => {
    const { dieTypes } = this.state;
    const data: Dies = [];
    for (let y = 0; y < rows; y += 1) {
      data.push([]);
      for (let x = 0; x < cols; x += 1) {
        data[y].push({
          binColor: DEFAULT_DIE_COLOR,
          isSelected: false,
          isCropped: false,
          isDeleted: false,
          softBin: Math.floor(Math.random() * 1000),
          dieType: UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes),
        });
      }
    }
    return data;
  };

  getWaferDataUsingReticle = (
    dieStepSizeX: number,
    dieStepSizeY: number,
    reticleSizeX: number,
    reticleSizeY: number,
    reticleOffsetX: number,
    reticleOffsetY: number,
    waferDiameter: number,
    standardReticle: StandardReticle,
    reticleReference: ReticleReference,
  ) => {
    const { dieTypes } = this.state;
    if (reticleSizeY !== standardReticle.reticle.length || reticleSizeX !== standardReticle.reticle[0].length) {
      toast.warning('Reticle Size and Dimensions Mismatch. Generate Reticle With New Dimensions');
      return null;
    }
    const reticleWidth = dieStepSizeX * reticleSizeX;
    const reticleHeight = dieStepSizeY * reticleSizeY;
    const waferRadius = waferDiameter / 2;

    const reticleRows = Math.ceil(waferDiameter / reticleHeight) + 1;
    const reticleCols = Math.ceil(waferDiameter / reticleWidth) + 1;

    const dataRows = reticleRows * reticleSizeY;
    const dataCols = reticleCols * reticleSizeX;

    if (UtilityFunctions.euclideanDistance({ x: 0, y: 0 }, { x: reticleOffsetX, y: reticleOffsetY }) > waferRadius) {
      toast.warning('Reticle offset can not be greater than wafer radius.');
      return null;
    }

    let data: Dies = [];
    for (let i = 0; i < dataRows; i += 1) {
      data.push([]);
      for (let j = 0; j < dataCols; j += 1) {
        data[i].push(null);
      }
    }

    const maxXLimit = reticleCols * reticleWidth;
    const maxYLimit = reticleRows * reticleHeight;

    const waferCenter = { x: -1, y: -1 };
    for (let i = 0; i < reticleRows && waferCenter.x === -1 && waferCenter.y === -1; i += 1) {
      for (let j = 0; j < reticleCols && waferCenter.x === -1 && waferCenter.y === -1; j += 1) {
        const reticleReferencePoint = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, reticleReference); // in terms of base units
        // eslint-disable-next-line no-underscore-dangle
        const _waferCenter = { x: reticleReferencePoint.x - reticleOffsetX, y: reticleReferencePoint.y + reticleOffsetY };
        const waferLeft = { x: _waferCenter.x - waferRadius, y: _waferCenter.y };
        const waferRight = { x: _waferCenter.x + waferRadius, y: _waferCenter.y };
        const waferTop = { x: _waferCenter.x, y: _waferCenter.y - waferRadius };
        const waferBottom = { x: _waferCenter.x, y: _waferCenter.y + waferRadius };
        if (
          UtilityFunctions.coordInRange(waferLeft, { min: 0, max: maxXLimit }, { min: 0, max: maxYLimit })
          && UtilityFunctions.coordInRange(waferRight, { min: 0, max: maxXLimit }, { min: 0, max: maxYLimit })
          && UtilityFunctions.coordInRange(waferTop, { min: 0, max: maxXLimit }, { min: 0, max: maxYLimit })
          && UtilityFunctions.coordInRange(waferBottom, { min: 0, max: maxXLimit }, { min: 0, max: maxYLimit })
        ) {
          waferCenter.x = _waferCenter.x;
          waferCenter.y = _waferCenter.y;
        }
      }
    }
    const newWaferCenter = { x: -1, y: -1 };
    if (waferCenter.x !== -1 && waferCenter.y !== -1) {
      for (let i = 0; i < reticleRows; i += 1) {
        for (let j = 0; j < reticleCols; j += 1) {
          const reticleReferencePoint = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, reticleReference); // in terms of base units
          const topLeft = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, 'TOP_LEFT'); // in terms of base units
          const topRight = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, 'TOP_RIGHT'); // in terms of base units
          const bottomLeft = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, 'BOTTOM_LEFT'); // in terms of base units
          const bottomRight = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, 'BOTTOM_RIGHT'); // in terms of base units

          const h = waferCenter.x;
          const k = (maxYLimit - waferCenter.y);

          const reticleLeftSideIntersectionPoints = UtilityFunctions.getYCoordsOfIntersectionOfVerticleLineWithCircle(topLeft.x, h, k, waferRadius);
          const reticleRightSideIntersectionPoints = UtilityFunctions.getYCoordsOfIntersectionOfVerticleLineWithCircle(topRight.x, h, k, waferRadius);
          const reticleTopSideIntersectionPoints = UtilityFunctions.getXCoordsOfIntersectionOfHorizontalLineWithCircle(maxYLimit - topLeft.y, h, k, waferRadius);
          const reticleBottomSideIntersectionPoints = UtilityFunctions.getXCoordsOfIntersectionOfHorizontalLineWithCircle(maxYLimit - bottomLeft.y, h, k, waferRadius);

          if (
            (
              reticleLeftSideIntersectionPoints
              && (
                !((maxYLimit - reticleLeftSideIntersectionPoints.p1) >= bottomLeft.y && (maxYLimit - reticleLeftSideIntersectionPoints.p2) >= bottomLeft.y)
                    && !((maxYLimit - reticleLeftSideIntersectionPoints.p1) <= topLeft.y && (maxYLimit - reticleLeftSideIntersectionPoints.p2) <= topLeft.y)
              )
            )
            || (
              reticleRightSideIntersectionPoints
              && (
                !((maxYLimit - reticleRightSideIntersectionPoints.p1) >= bottomRight.y && (maxYLimit - reticleRightSideIntersectionPoints.p2) >= bottomRight.y)
                    && !((maxYLimit - reticleRightSideIntersectionPoints.p1) <= topRight.y && (maxYLimit - reticleRightSideIntersectionPoints.p2) <= topRight.y)
              )
            )
            || (
              reticleTopSideIntersectionPoints
              && (
                !(reticleTopSideIntersectionPoints.p1 >= topRight.x && reticleTopSideIntersectionPoints.p2 >= topRight.x)
                    && !(reticleTopSideIntersectionPoints.p1 <= topLeft.x && reticleTopSideIntersectionPoints.p2 <= topLeft.x)
              )
            )
            || (
              reticleBottomSideIntersectionPoints
              && (
                !(reticleBottomSideIntersectionPoints.p1 >= bottomRight.x && reticleBottomSideIntersectionPoints.p2 >= bottomRight.x)
                    && !(reticleBottomSideIntersectionPoints.p1 <= bottomLeft.x && reticleBottomSideIntersectionPoints.p2 <= bottomLeft.x)
              )
            )
          ) {
            const referenceReticle = UtilityFunctions.areFloatsEqual(reticleReferencePoint.x - waferCenter.x, reticleOffsetX)
              && UtilityFunctions.areFloatsEqual(waferCenter.y - reticleReferencePoint.y, reticleOffsetY);
            // this reticle is included
            for (let a = i * reticleSizeY, c = 0; a < i * reticleSizeY + reticleSizeY; a += 1, c += 1) {
              for (let b = j * reticleSizeX, d = 0; b < j * reticleSizeX + reticleSizeX; b += 1, d += 1) {
                data[a][b] = {
                  binColor: DEFAULT_DIE_COLOR,
                  reticleSite: standardReticle.reticle[c][d],
                  referenceReticle,
                  reticleX: d,
                  reticleY: c,
                  isSelected: false,
                  dieType: +standardReticle.WATPCMSite === standardReticle.reticle[c][d]
                    ? UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', dieTypes) : UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes),
                  isCropped: false,
                  isDeleted: false,
                  softBin: Math.floor(Math.random() * 1000),
                };
              }
            }
          }
        }
      }
      // remove all null rows
      data = data.filter((dataRow: any[]) => { return !dataRow.reduce((result, currData) => result && currData === null, true); });
      // remove all null cols
      data = data.map(
        (x: any) => x.filter((dat: any, idx: number) => data.slice(1).some((arr: any[]) => arr[idx] !== null)),
      );
      if (data.length === 0) {
        toast.warning('Calculation Error. Please contact administrator.');
        return null;
      }
      const newReticleRows = data.length / reticleSizeY;
      const newReticleCols = data[0].length / reticleSizeX;

      for (let i = 0; i < newReticleRows && newWaferCenter.x === -1 && newWaferCenter.y === -1; i += 1) {
        for (let j = 0; j < newReticleCols && newWaferCenter.x === -1 && newWaferCenter.y === -1; j += 1) {
          const dataY = i * reticleSizeY;
          const dataX = j * reticleSizeX;
          if (data[dataY][dataX] && data[dataY][dataX]!.referenceReticle) {
            const reticleReferencePoint = UtilityFunctions.getReticleReference(j, i, reticleWidth, reticleHeight, reticleReference);
            newWaferCenter.x = reticleReferencePoint.x - reticleOffsetX;
            newWaferCenter.y = reticleReferencePoint.y + reticleOffsetY;
          }
        }
      }
    } else {
      toast.warning('Calculation Error. Please contact administrator.');
      return null;
    }
    if (newWaferCenter.x === -1 || newWaferCenter.y === -1) {
      toast.warning('Calculation Error. Please contact administrator.');
      return null;
    }
    return {
      // to get center just remove the below 2 lines to default
      waferHeightToRowsRatio: (2 * (waferRadius / dieStepSizeY)) / data.length,
      waferWidthToColsRatio: (2 * (waferRadius / dieStepSizeX)) / data[0].length,
      waferBGOffsetXDies: (newWaferCenter.x - (data[0].length / 2) * dieStepSizeX) / dieStepSizeX,
      waferBGOffsetYDies: (newWaferCenter.y - (data.length / 2) * dieStepSizeY) / dieStepSizeY,
      data,
    };
  };

  onSaveWaferDefinitionData = (data: WaferDefinitionData) => {
    let streetWidth = 0;
    let streetHeight = 0;
    let rows = 0;
    let cols = 0;
    let waferBGOffsetXDies = 0; // X offset of wafer from the center of the wafermap canvas in terms of cols
    let waferBGOffsetYDies = 0; // Y offset of wafer from the center of the wafermap canvas in terms of rows
    let dieWidthToStreetWidthRatio = 1;
    let dieHeightToStreetHeightRatio = 1;

    const dieSizeX = UtilityFunctions.toBaseUnit({ value: data.dieSizeX, UOM: data.dieUOM });
    const dieSizeY = UtilityFunctions.toBaseUnit({ value: data.dieSizeY, UOM: data.dieUOM });
    const dieStepSizeX = UtilityFunctions.toBaseUnit({ value: data.dieStepSizeX, UOM: data.dieUOM });
    const dieStepSizeY = UtilityFunctions.toBaseUnit({ value: data.dieStepSizeY, UOM: data.dieUOM });
    const waferDiameter = UtilityFunctions.toBaseUnit({ value: data.waferDiameter, UOM: data.waferDiameterUOM, isDiameter: true });
    const reticleOffsetX = UtilityFunctions.toBaseUnit({ value: data.reticleOffsetX, UOM: data.reticleUOM });
    const reticleOffsetY = UtilityFunctions.toBaseUnit({ value: data.reticleOffsetY, UOM: data.reticleUOM });
    const waferEdgeExclusion = UtilityFunctions.toBaseUnit({ value: data.waferEdgeExclusion, UOM: data.waferEdgeExclusionUOM });

    streetWidth = dieStepSizeX - dieSizeX;
    streetHeight = dieStepSizeY - dieSizeY;
    let waferHeightToRowsRatio = 1;
    let waferWidthToColsRatio = 1;
    const rowsRequired = Math.floor(waferDiameter / dieStepSizeY);
    const colsRequired = Math.floor(waferDiameter / dieStepSizeX);
    let waferHeight = (waferDiameter / dieStepSizeY); // height in terms of number of rows taken
    let waferWidth = (waferDiameter / dieStepSizeX); // wafer width in terms of number of cols taken
    let showDieText = false;
    let reticleSize = { x: 0, y: 0 };
    let dieTextField = '';
    dieWidthToStreetWidthRatio = dieStepSizeX / streetWidth;
    dieHeightToStreetHeightRatio = dieStepSizeY / streetHeight;
    if (data.calculationMethod === CalculationMethod.AUTO) {
      rows = rowsRequired;
      cols = colsRequired;
      waferHeightToRowsRatio = (waferDiameter / dieStepSizeY) / rows;
      waferWidthToColsRatio = (waferDiameter / dieStepSizeX) / cols;
    } else if (data.calculationMethod === CalculationMethod.ROWS_COLS) {
      rows = data.waferMaxRows;
      cols = data.waferMaxCols;
      waferHeight = rowsRequired;
      waferWidth = colsRequired;
      waferHeightToRowsRatio = rowsRequired / rows;
      waferWidthToColsRatio = colsRequired / cols;
    }
    let dies = this.getWaferData(rows, cols);
    let maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(dies);

    if (data.calculationMethod === CalculationMethod.RETICLE) {
      const waferData = this.getWaferDataUsingReticle(
        dieStepSizeX,
        dieStepSizeY,
        data.reticleSizeX,
        data.reticleSizeY,
        reticleOffsetX,
        reticleOffsetY,
        waferDiameter,
        data.standardReticle,
        data.reticleReference,
      );
      if (!waferData) return;
      dies = waferData.data;
      maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(dies);
      rows = maxRowsAndCols.maxRows;
      cols = maxRowsAndCols.maxCols;
      waferBGOffsetXDies = waferData.waferBGOffsetXDies;
      waferBGOffsetYDies = waferData.waferBGOffsetYDies;
      waferHeightToRowsRatio = waferData.waferHeightToRowsRatio!;
      waferWidthToColsRatio = waferData.waferWidthToColsRatio!;
      waferHeight = waferHeightToRowsRatio * rows;
      waferWidth = waferWidthToColsRatio * cols;
      showDieText = true;
      reticleSize = {
        x: data.standardReticle.reticle.length > 0 ? data.standardReticle.reticle[0].length : 0,
        y: data.standardReticle.reticle.length,
      };
      dieTextField = 'reticleSite';
    }
    const ringSize = waferEdgeExclusion;
    const waferMapConfig: WaferMapConfig = {
      data: { dies, waferMaxRows: maxRowsAndCols.maxRows, waferMaxCols: maxRowsAndCols.maxCols },
      dieSize: {
        dieWidth: dieStepSizeX > dieStepSizeY ? 32 * (dieStepSizeX / dieStepSizeY) : 32,
        dieHeight: dieStepSizeY > dieStepSizeX ? 32 * (dieStepSizeY / dieStepSizeX) : 32,
      },
      reticleGridRectCoords: {},
      referenceReticleGridRectCoords: null,
      showDieText,
      reticleSize,
      dieTextField,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
    };
    this.setState((prevState: WaferControlMapState) => {
      const newWaferDefinitionData = _.cloneDeep(data);
      newWaferDefinitionData.version += 1;
      const newDrawLayout = _.cloneDeep(prevState.drawLayout);
      newDrawLayout.overlayReticle = data.calculationMethod === CalculationMethod.RETICLE;
      newDrawLayout.showReferenceReticle = data.calculationMethod === CalculationMethod.RETICLE;
      newDrawLayout.ringSize = ringSize;
      newDrawLayout.showRing = false;
      return {
        currentDieTypeId: prevState.dieTypes.length > 0 ? prevState.dieTypes[0].id : null,
        ringDiameterToWaferDiameterRatio: (waferDiameter - 2 * waferEdgeExclusion) / waferDiameter,
        middleViewport: MiddleViewport.WAFER,
        orignalWaferDimensions: { height: waferHeight, width: waferWidth },
        waferMapConfig,
        waferDefinitionData: newWaferDefinitionData,
        drawLayout: newDrawLayout,
        zones: [],
        selectedZoneId: null,
      };
    }, () => {
      this.addWafer();
    });
  };

  addWafer = (skipProcessData = false) => {
    const {
      waferMapConfig, waferDefinitionData, ringDiameterToWaferDiameterRatio, drawLayout, dieTypes, currentDieTypeId, zones,
      orignalWaferDimensions,
    } = this.state;
    if (this.plotter && waferMapConfig) {
      this.plotter.addOrReplaceWafer({
        skipProcessData,
        reticleGridRectCoords: waferMapConfig.reticleGridRectCoords,
        referenceReticleGridRectCoords: waferMapConfig.referenceReticleGridRectCoords,
        featureSpecificWaferTags: [FeatureSpecificWaferTag.WAFER_CONTROL_MAP],
        outerViewport: this.getOuterViewportBasedOnRadar(true),
        shouldUseOnlyBinColor: false,
        dies: waferMapConfig!.data.dies,
        keyIndex: DEVICE_WAFER_KEY,
        hasMarkingFeature: true,
        hasRotationControls: true,
        dieSize: waferMapConfig.dieSize,
        dieHeightToStreetHeightRatio: waferMapConfig.dieHeightToStreetHeightRatio,
        dieWidthToStreetWidthRatio: waferMapConfig.dieWidthToStreetWidthRatio,
        notchPosition: NotchPosition[waferDefinitionData.waferOutputNotchPosition as unknown as NotchPosition] as unknown as NotchPosition,
        rowOffset: waferDefinitionData.waferRowOffset,
        colOffset: waferDefinitionData.waferColOffset,
        rotationAngle: 0,
        showRadar: true,
        showWaferInfo: true,
        setWaferInstanceHandler: this.setWaferInstanceHandler,
        dieTypeField: 'dieType',
        ringDiameterToWaferDiameterRatio,
        waferHeightToRowsRatio: waferMapConfig.waferHeightToRowsRatio,
        waferWidthToColsRatio: waferMapConfig.waferWidthToColsRatio,
        waferBGOffsetXDies: waferMapConfig.waferBGOffsetXDies,
        waferBGOffsetYDies: waferMapConfig.waferBGOffsetYDies,
        overlayReticle: drawLayout.overlayReticle,
        markWaferCenter: drawLayout.markWaferCenter,
        dieTextField: waferMapConfig.dieTextField,
        showDieText: waferMapConfig.showDieText,
        reticleSize: waferMapConfig.reticleSize,
        reticleReference: waferDefinitionData.reticleReference,
        dieTypes: { dieType: dieTypes },
        showReferenceReticle: drawLayout.showReferenceReticle,
        zones,
        currentDieType: currentDieTypeId,
        wcmWaferDiameter: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true }),
        wcmWaferEdgeExclusion: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferEdgeExclusion, UOM: waferDefinitionData.waferEdgeExclusionUOM }),
        wcmWaferNotchKeepOut: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferNotchKeepOut, UOM: waferDefinitionData.waferNotchKeepOutUOM }),
        wcmWaferFlatKeepOut: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferFlatKeepOut, UOM: waferDefinitionData.waferFlatKeepOutUOM }),
        wcmWaferBaseFlat: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferBaseFlat, UOM: waferDefinitionData.waferBaseFlatUOM }),
        wcmWaferScribeLine: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferScribeLine, UOM: waferDefinitionData.waferScribeLineUOM }),
        wcmExclusionType: waferDefinitionData.exclusionType,
        standardReticle: waferDefinitionData.standardReticle,
        rowsTakenByWaferBG: orignalWaferDimensions.height,
        colsTakenByWaferBG: orignalWaferDimensions.width,
        colAxisDirection: UtilityFunctions.getColAndRowAxisDirection(waferDefinitionData.waferAxisDirection).colAxisDirection,
        rowAxisDirection: UtilityFunctions.getColAndRowAxisDirection(waferDefinitionData.waferAxisDirection).rowAxisDirection,
        waferOriginLocation: waferDefinitionData.waferOriginLocation,
        onWATPCMNumberMarked: this.onWATPCMNumberMarked,
      }, () => {
        this.forceUpdate();
      });
    }
  };

  onWATPCMNumberMarked = () => {
    this.setState((prevState: WaferControlMapState) => {
      const newPcmWatSiteNumber = prevState.pcmWatSiteNumber === null || (prevState.pcmWatSiteNumber !== null && prevState.pcmWatSiteNumber >= prevState.maxPcmWatSiteNumber)
        ? prevState.pcmWatSiteNumber : prevState.pcmWatSiteNumber + 1;
      this.changeWaferMapVariable({ currentWatPCMSiteNumber: newPcmWatSiteNumber }, false);
      return {
        pcmWatSiteNumber: newPcmWatSiteNumber,
      };
    });
  };

  validateReticleData = (data: WaferDefinitionData) => {
    const errors: { [key: string] : any } = {};
    const waferRadius = (UtilityFunctions.toBaseUnit({ value: data.waferDiameter, UOM: data.waferDiameterUOM, isDiameter: true }) / 2);
    if (data.reticleSizeX.toString() === '') {
      errors.reticleSizeX = 'Required';
    } else if (+data.reticleSizeX <= 0) {
      errors.reticleSizeX = 'Reticle cols must be greater than 0';
    }

    if (data.reticleSizeY.toString() === '') {
      errors.reticleSizeY = 'Required';
    } else if (+data.reticleSizeY <= 0) {
      errors.reticleSizeY = 'Reticle rows must be greater than 0';
    }

    if (UtilityFunctions.toBaseUnit({ value: data.reticleOffsetX, UOM: data.reticleUOM })
      > waferRadius) {
      errors.reticleOffsetX = 'Reticle offset X cannot be greater than wafer radius';
    }
    if (UtilityFunctions.toBaseUnit({ value: data.reticleOffsetY, UOM: data.reticleUOM })
      > waferRadius) {
      errors.reticleOffsetX = 'Reticle offset Y cannot be greater than wafer radius';
    }

    if (data.reticleOffsetX.toString() === '') {
      errors.reticleOffsetX = 'Required';
    }

    if (data.reticleOffsetY.toString() === '') {
      errors.reticleOffsetY = 'Required';
    }
    if (!(data.standardReticle.reticle.length > 0
        && data.standardReticle.reticle.length === +data.reticleSizeY
        && data.standardReticle.reticle[0].length === +data.reticleSizeX)) {
      errors.standardReticle = 'Generate Reticle With New Dimensions';
    }
    return errors;
  };

  onSaveReticleData = (data: any) => {
    const { waferMapConfig, waferDefinitionData } = this.state;
    if (!waferMapConfig) return;
    const reticleSize = {
      x: data.standardReticle.reticle.length > 0 ? data.standardReticle.reticle[0].length : 0,
      y: data.standardReticle.reticle.length,
    };
    const reticleOffset = {
      x: UtilityFunctions.toBaseUnit({ value: data.reticleOffsetX, UOM: data.reticleUOM })
      / UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeX, UOM: waferDefinitionData.dieUOM }),
      y: UtilityFunctions.toBaseUnit({ value: data.reticleOffsetY, UOM: data.reticleUOM })
      / UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeY, UOM: waferDefinitionData.dieUOM }),
    }; // in terms of die step size units
    const reticleTopLeft = this.getReticleTopLeft(
      waferMapConfig.data.dies.length,
      waferMapConfig.data.dies[0].length,
      data.reticleReference,
      reticleOffset,
      reticleSize,
      waferMapConfig.waferBGOffsetXDies,
      waferMapConfig.waferBGOffsetYDies,
    );
    if (reticleTopLeft.x % 1 === 0 && reticleTopLeft.y % 1 === 0) {
      this.setState((prevState: WaferControlMapState) => {
        const newWaferDefinitionData = _.cloneDeep(prevState.waferDefinitionData);
        newWaferDefinitionData.version = data.version + 1;
        newWaferDefinitionData.reticleUOM = data.reticleUOM;
        newWaferDefinitionData.reticleSizeX = data.reticleSizeX;
        newWaferDefinitionData.reticleSizeY = data.reticleSizeY;
        newWaferDefinitionData.reticleOffsetX = data.reticleOffsetX;
        newWaferDefinitionData.reticleOffsetY = data.reticleOffsetY;
        newWaferDefinitionData.standardReticle = _.cloneDeep(data.standardReticle);
        newWaferDefinitionData.reticleReference = data.reticleReference;
        return {
          waferDefinitionData: newWaferDefinitionData,
        };
      }, this.overlayReticle);
    } else {
      toast.warning('Error! Reticle does not aligns with the dies. Change reticle dimensions, UOM, offset or reference.');
    }
  };

  overlayReticle = () => {
    // only used for methods other than draw using reticle
    const { waferDefinitionData, waferMapConfig } = this.state;
    const reticleSize = {
      x: waferDefinitionData.standardReticle.reticle.length > 0 ? waferDefinitionData.standardReticle.reticle[0].length : 0,
      y: waferDefinitionData.standardReticle.reticle.length,
    };
    const reticleOffset = {
      x: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.reticleOffsetX, UOM: waferDefinitionData.reticleUOM })
      / UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeX, UOM: waferDefinitionData.dieUOM }),
      y: UtilityFunctions.toBaseUnit({ value: waferDefinitionData.reticleOffsetY, UOM: waferDefinitionData.reticleUOM })
      / UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeY, UOM: waferDefinitionData.dieUOM }),
    }; // in terms of die step size units
    if (!waferMapConfig) return;
    const reticleTopLeft = this.getReticleTopLeft(
      waferMapConfig.data.dies.length,
      waferMapConfig.data.dies[0].length,
      waferDefinitionData.reticleReference,
      reticleOffset,
      reticleSize,
      waferMapConfig.waferBGOffsetXDies,
      waferMapConfig.waferBGOffsetYDies,
    );
      // check if reticle overlay is possible by checking if top left coords are whole numbers
    if (reticleTopLeft.x % 1 === 0 && reticleTopLeft.y % 1 === 0) {
      const newBinTextField = 'reticleSite';
      this.setState((prevState: WaferControlMapState) => {
        let newWaferMapConfig = prevState.waferMapConfig;
        if (prevState.waferMapConfig) {
          newWaferMapConfig = { ...prevState.waferMapConfig, dieTextField: newBinTextField };
        }
        return {
          drawLayout: { ...prevState.drawLayout, overlayReticle: true },
          waferMapConfig: newWaferMapConfig,
          zones: [],
          selectedZoneId: null,
        };
      }, () => {
        this.changeWaferMapVariable({
          showDieText: true,
          reticleSize,
          overlayReticle: true,
          dieTextField: 'reticleSite',
          reticleReference: waferDefinitionData.reticleReference,
          standardReticle: waferDefinitionData.standardReticle,
        });
        PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.APPLY_RETICLE, config: { reticleTopLeft } }, 'myid');
      });
    } else {
      toast.warning('Error! Reticle does not aligns with the dies. Change reticle dimensions, UOM, offset or reference.');
    }
  };

  getReticleTopLeft = (
    waferRows: number,
    waferCols: number,
    reticleReference: ReticleReference,
    reticleOffset: { x: number, y: number },
    reticleSize: { x: number, y: number },
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
  ) => {
    // helper method
    // reticle offset in terms of die step size units
    const waferCenter = { x: waferCols / 2, y: waferRows / 2 };
    const waferBGCenter = { x: waferCenter.x + waferBGOffsetXDies, y: waferCenter.y + waferBGOffsetYDies };
    const reticleReferencePoint = { x: waferBGCenter.x + reticleOffset.x, y: waferBGCenter.y - reticleOffset.y };
    let reticleTopLeft;
    switch (reticleReference) {
      case 'BOTTOM_LEFT':
        reticleTopLeft = { x: reticleReferencePoint.x, y: reticleReferencePoint.y - reticleSize.y };
        break;
      case 'BOTTOM_RIGHT':
        reticleTopLeft = { x: reticleReferencePoint.x - reticleSize.x, y: reticleReferencePoint.y - reticleSize.y };
        break;
      case 'TOP_LEFT':
        reticleTopLeft = { x: reticleReferencePoint.x, y: reticleReferencePoint.y };
        break;
      case 'TOP_RIGHT':
        reticleTopLeft = { x: reticleReferencePoint.x - reticleSize.x, y: reticleReferencePoint.y };
        break;
      case 'CENTER':
        reticleTopLeft = { x: reticleReferencePoint.x - reticleSize.x / 2, y: reticleReferencePoint.y - reticleSize.y / 2 };
        break;
      default:
        reticleTopLeft = { x: reticleReferencePoint.x - reticleSize.x / 2, y: reticleReferencePoint.y - reticleSize.y / 2 };
        break;
    }
    reticleTopLeft.x = UtilityFunctions.ignoreDecimalsIfVerySmall(reticleTopLeft.x);
    reticleTopLeft.y = UtilityFunctions.ignoreDecimalsIfVerySmall(reticleTopLeft.y);
    return reticleTopLeft;
  };

  setDrawLayoutFromDbState = (drawLayout: DrawLayoutData) => {
    this.setState({ drawLayout });
  }

  getDrawLayoutData = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.drawLayout;
  }

  getOrignalWaferDimensions = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.orignalWaferDimensions;
  }

  getWaferDefinitionData = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.waferDefinitionData;
  }

  getWCMAttributeDefinitions = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.WCMAttributeDefinitions;
  }

  setWCMAttributeDefinitions = async (WCMAttributeDefinitions: any[]) => {
    this.setState({ WCMAttributeDefinitions });
  }

  getPCMWatSiteMapTabData = () => {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.pcmWatSiteMapTabData;
  }

  setPCMWatSiteMapTabData = async (pcmWatSiteMapTabData: PCMWATSiteMapTabData) => {
    this.setState({ pcmWatSiteMapTabData });
  }

  onWCMDefintionActionUndoRedo = (waferDefinitionData: WaferDefinitionData, orignalWaferDimensions: { height: number, width: number }, waferMapVariables: WaferMapVariablesV2) => {
    this.setState((prevState: WaferControlMapState) => {
      const waferDiameter = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true });
      const waferEdgeExclusion = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferEdgeExclusion, UOM: waferDefinitionData.waferEdgeExclusionUOM });
      const dieStepSizeX = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeX, UOM: waferDefinitionData.dieUOM });
      const dieStepSizeY = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeY, UOM: waferDefinitionData.dieUOM });

      const waferMapConfig: WaferMapConfig = {
        data: { dies: waferMapVariables.waferData.dies, waferMaxRows: waferMapVariables.waferData.waferMaxRows, waferMaxCols: waferMapVariables.waferData.waferMaxCols },
        dieSize: {
          dieWidth: dieStepSizeX > dieStepSizeY ? 32 * (dieStepSizeX / dieStepSizeY) : 32,
          dieHeight: dieStepSizeY > dieStepSizeX ? 32 * (dieStepSizeY / dieStepSizeX) : 32,
        },
        reticleGridRectCoords: {},
        referenceReticleGridRectCoords: null,
        showDieText: waferMapVariables.showDieText,
        reticleSize: waferMapVariables.reticleSize,
        dieTextField: waferMapVariables.dieTextField,
        dieWidthToStreetWidthRatio: waferMapVariables.dieWidthToStreetWidthRatio,
        dieHeightToStreetHeightRatio: waferMapVariables.dieHeightToStreetHeightRatio,
        waferWidthToColsRatio: waferMapVariables.waferWidthToColsRatio,
        waferHeightToRowsRatio: waferMapVariables.waferHeightToRowsRatio,
        waferBGOffsetXDies: waferMapVariables.waferBGOffsetXDies,
        waferBGOffsetYDies: waferMapVariables.waferBGOffsetYDies,
      };

      return {
        currentDieTypeId: prevState.dieTypes.length > 0 ? prevState.dieTypes[0].id : null,
        ringDiameterToWaferDiameterRatio: (waferDiameter - 2 * waferEdgeExclusion) / waferDiameter,
        middleViewport: MiddleViewport.WAFER,
        orignalWaferDimensions,
        waferMapConfig,
        waferDefinitionData,
        zones: [],
        selectedZoneId: null,
      };
    });
  }

  onChangeDrawLayout = (data: DrawLayoutData, callback?: any) => {
    this.setState((prevState: WaferControlMapState) => {
      if (+prevState.drawLayout.ringSize !== +data.ringSize || prevState.drawLayout.ringUOM !== data.ringUOM) {
        const dataRingSize = 2 * UtilityFunctions.toBaseUnit({ value: +data.ringSize, UOM: data.ringUOM });
        const waferDiameter = UtilityFunctions.toBaseUnit({ value: prevState.waferDefinitionData.waferDiameter, UOM: prevState.waferDefinitionData.waferDiameterUOM, isDiameter: true });
        if (dataRingSize > waferDiameter) {
          toast.warning('Ring size can not be greater than the diameter');
          return { drawLayout: prevState.drawLayout };
        }
        this.setRingSize(waferDiameter - dataRingSize);
      }
      return { drawLayout: _.cloneDeep(data) };
    }, async () => {
      if (callback) await callback();
    });
  };

  setRingSize = (ringSize: number, setShowRing = false) => {
    const { waferDefinitionData } = this.state;
    const newVars: any = {
      ringDiameterToWaferDiameterRatio: ringSize / UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true }),
    };
    if (setShowRing) {
      newVars.showRing = true;
    }
    this.changeWaferMapVariable(newVars);
  };

  changeWaferMapVariable = (waferData: { [key:string] : any }, shouldReRender = true) => {
    PublishSubscribe().publishWithOthersID(EventTypes.CHANGE_WAFER_MAP_VARIABLES, { waferData, shouldReRender }, 'myid');
  };

  getOuterViewportBasedOnRadar = (getWithRadar = false) => {
    if (getWithRadar || this.deviceWafer!.waferMapVariables.showRadar) {
      const outervpWidth = (3 * this.canvasDimension) / 4;
      return {
        x: 0,
        y: WAFER_PLOTTER_CANVAS_HEIGHT - outervpWidth,
        width: outervpWidth,
        height: outervpWidth,
      };
    }
    return {
      x: 0,
      y: WAFER_PLOTTER_CANVAS_HEIGHT - this.canvasDimension,
      width: this.canvasDimension,
      height: this.canvasDimension,
    };
  };

  updateCanvasDimensions = () => {
    if (!this.deviceWafer) return;
    // update dimension after state update to ensure teh dimension changes on dom before its value gets updated
    this.canvasDimension = this.waferDiv.offsetWidth;
    // force update to ensure the new canvas dimensions are passed to the child component
    this.forceUpdate(() => {
      this.deviceWafer!.clearAll();
      this.deviceWafer!.waferMapVariables.outerViewPortWithRadar = null;
      this.deviceWafer!.waferMapVariables.outerViewPortWithoutRadar = null;
      this.deviceWafer!.waferMapVariables.onSetOuterViewport(
        this.getOuterViewportBasedOnRadar(),
      );
      this.deviceWafer!.renderWafer();
    });
  };

  onChangeLayout = (collapseTabsColumn: boolean | null, collapseDieSummaryColumn: boolean | null) => {
    this.setState((prevState: WaferControlMapState) => {
      return {
        collapseTabsColumn: collapseTabsColumn === null ? prevState.collapseTabsColumn : collapseTabsColumn,
        collapseDieSummaryColumn: collapseDieSummaryColumn === null ? prevState.collapseDieSummaryColumn : collapseDieSummaryColumn,
      };
    }, this.updateCanvasDimensions);
  };

  onResetRing = () => {
    const { waferDefinitionData } = this.state;
    const waferDiameter = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferDiameter, UOM: waferDefinitionData.waferDiameterUOM, isDiameter: true });
    const waferEdgeExclusion = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.waferEdgeExclusion, UOM: waferDefinitionData.waferEdgeExclusionUOM });
    this.setState((prevState: WaferControlMapState) => {
      return {
        ringPanOffsetXMultiplier: 0,
        ringPanOffsetYMultiplier: 0,
        ringDiameterToWaferDiameterRatio: (waferDiameter - 2 * waferEdgeExclusion) / waferDiameter,
        drawLayout: { ...prevState.drawLayout, ringSize: waferEdgeExclusion, ringUOM: UOMs[0][0] },
      };
    }, async () => {
      this.changeWaferMapVariable({
        ringDiameterToWaferDiameterRatio: (waferDiameter - 2 * waferEdgeExclusion) / waferDiameter,
        panOffsetXToDieStepSizeXRatio: 0,
        panOffsetYToDieStepSizeYRatio: 0,
      });
      if (this.plotter) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Reset ring',
          actionUndoMessage: 'Reset ring',
        });
      }
    });
  };

  panRingOrWafer = (ringOrWafer: DrawLayoutPanResizeMode, xChange: number, yChange: number) => {
    const {
      drawLayout, waferDefinitionData, ringPanOffsetXMultiplier, ringPanOffsetYMultiplier, waferMapConfig,
    } = this.state;
    if (!waferMapConfig) return;
    const dieStepSizeX = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeX, UOM: waferDefinitionData.dieUOM });
    const dieStepSizeY = UtilityFunctions.toBaseUnit({ value: waferDefinitionData.dieStepSizeY, UOM: waferDefinitionData.dieUOM });
    const incrementX = UtilityFunctions.toBaseUnit({
      value: drawLayout.increment,
      UOM: drawLayout.incrementUOM,
      dieSide: 'x',
      dieDimensions: { dieSizeX: waferDefinitionData.dieSizeX, dieSizeY: waferDefinitionData.dieSizeY, dieUOM: waferDefinitionData.dieUOM },
    });
    const incrementY = UtilityFunctions.toBaseUnit({
      value: drawLayout.increment,
      UOM: drawLayout.incrementUOM,
      dieSide: 'y',
      dieDimensions: { dieSizeX: waferDefinitionData.dieSizeX, dieSizeY: waferDefinitionData.dieSizeY, dieUOM: waferDefinitionData.dieUOM },
    });
    if (ringOrWafer === DrawLayoutPanResizeMode.RING) {
      this.changeWaferMapVariable({
        panOffsetXToDieStepSizeXRatio: (incrementX / dieStepSizeX) * (ringPanOffsetXMultiplier + xChange),
        panOffsetYToDieStepSizeYRatio: (incrementY / dieStepSizeY) * (ringPanOffsetYMultiplier + yChange),
      });
      this.setState((prevState: WaferControlMapState) => {
        return {
          ringPanOffsetXMultiplier: prevState.ringPanOffsetXMultiplier + xChange,
          ringPanOffsetYMultiplier: prevState.ringPanOffsetYMultiplier + yChange,
        };
      });
    } else if (ringOrWafer === DrawLayoutPanResizeMode.WAFER) {
      this.changeWaferMapVariable({
        waferBGOffsetXDies: waferMapConfig.waferBGOffsetXDies + xChange * (incrementX / dieStepSizeX),
        waferBGOffsetYDies: waferMapConfig.waferBGOffsetYDies + yChange * (incrementY / dieStepSizeY),
        radarWaferBgImageGLCoords: null,
      });
      this.setState((prevState: WaferControlMapState) => {
        if (!prevState.waferMapConfig) return { waferMapConfig: prevState.waferMapConfig };
        const newWaferMapConfig = _.cloneDeep(prevState.waferMapConfig);
        newWaferMapConfig.waferBGOffsetXDies = prevState.waferMapConfig.waferBGOffsetXDies + xChange * (incrementX / dieStepSizeX);
        newWaferMapConfig.waferBGOffsetYDies = prevState.waferMapConfig.waferBGOffsetYDies + yChange * (incrementY / dieStepSizeY);
        return {
          waferMapConfig: newWaferMapConfig,
        };
      });
    }
  };

  mapAttributeDefinitions = (data: any) => {
    return data.map((x: any) => {
      return {
        id: x.id,
        label: x.attributeMeta.columnLabel,
        name: x.attributeMeta.id,
        value: x.expressionValue,
      };
    });
  };

  onAddAttributeMeta = async (columnInfo: any, callback: any) => {
    const response = await httpWaferControlMap.postWaferControlMapAttributeMeta({
      columnName: columnInfo.columnName,
      columnLabel: columnInfo.columnLabel,
      entityType: columnInfo.entityType,
    });
    if (response) {
      if (response.success && response.statusCode === 200) {
        if (response.message) toast.success(response.message);
        this.setState({ allWCMAttributeMetas: response.data.attributeMetaDTOs }, () => { callback(response.data.currentAttributeMetaId); });
      } else if (response.message) toast.error(response.message);
    } else {
      this.setState({ WCMAttributeDefinitions: [], allWCMAttributeMetas: [] });
    }
  };

  onDeleteAttributeMeta = async (atrId: string, callback: () => void) => {
    const response = await httpWaferControlMap.deleteWaferControlMapAttributeMeta(atrId);
    if (response && response.message) {
      if (response.success) toast.success(response.message);
      else toast.error(response.message);
    }
    this.setState(await this.loadAttributeMetaAndDefinitions(), () => {
      callback();
    });
  };

  getColorObjectFromHex = (hexColor: string) => {
    const color = this.hexToRgb(hexColor);
    return {
      r: color?.r, g: color?.g, b: color?.b, key: hexColor,
    };
  };

  showErrorToasts = (errors: { [key: string] : any }) => {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [key, value] of Object.entries(errors)) {
      toast.warning(value);
    }
  };

  validateAndOverlayReticle = () => {
    const { waferDefinitionData } = this.state;
    if (this.waferDefinitionRef) {
      this.waferDefinitionRef.onSaveReticleData();
    } else {
      const errors = this.validateReticleData(waferDefinitionData);
      if (Object.keys(errors).length !== 0) {
        this.showErrorToasts(errors);
      } else {
        this.overlayReticle();
      }
    }
  };

  onCropSelectedReticles = () => {
    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.CROP_SELECTED_RETICLES_ON_WAFER }, 'myid');
  };

  onOverlayReticleCheckBoxValueChanged = (value: boolean) => {
    const { drawLayout } = this.state;
    if (value) this.validateAndOverlayReticle();
    else this.onChangeDrawLayout({ ...drawLayout, overlayReticle: value }, () => { this.onOverlayReticleChange(value); });
  };

  onShowReferenceReticleCheckBoxValueChanged = (value: boolean) => {
    const { drawLayout } = this.state;
    this.onChangeDrawLayout({ ...drawLayout, showReferenceReticle: value }, () => {
      this.onShowReferenceReticleChange(value);
    });
  };

  onOverlayReticleChange = (value: boolean) => {
    const { waferDefinitionData } = this.state;
    const newDieTextField = 'reticleSite';
    const changedVariables: any = {
      showDieText: value,
      reticleSize: {
        x: waferDefinitionData.standardReticle.reticle.length > 0 ? waferDefinitionData.standardReticle.reticle[0].length : 0,
        y: waferDefinitionData.standardReticle.reticle.length,
      },
      overlayReticle: value,
      dieTextField: newDieTextField,
    };
    if (!value) {
      changedVariables.isReticleCoordinatesSystemEnabled = false;
    }
    this.setState((prevState: WaferControlMapState) => {
      if (prevState.waferMapConfig) {
        return {
          waferMapConfig: { ...prevState.waferMapConfig, dieTextField: newDieTextField },
        };
      }
      return {
        waferMapConfig: prevState.waferMapConfig,
      };
    }, () => {
      this.changeWaferMapVariable(changedVariables);
    });
  };

  onShowReferenceReticleChange = (value: boolean) => {
    const { waferDefinitionData } = this.state;
    this.changeWaferMapVariable({
      reticleSize: {
        x: waferDefinitionData.standardReticle.reticle.length > 0 ? waferDefinitionData.standardReticle.reticle[0].length : 0,
        y: waferDefinitionData.standardReticle.reticle.length,
      },
      showReferenceReticle: value,
    });
  };

  onMarkWaferCenterChange = (value: boolean) => {
    this.changeWaferMapVariable({ markWaferCenter: value });
  };

  uncheckReticleAndInsertOrDelete = (operationCallback: any) => {
    const { drawLayout } = this.state;
    // if reticle is already overlayed, uncheck it to allow recalculation
    if (drawLayout.overlayReticle) {
      this.onChangeDrawLayout({ ...drawLayout, overlayReticle: false }, () => {
        this.onOverlayReticleChange(false);
        operationCallback();
      });
    } else {
      operationCallback();
    }
  };

  onClickShowHideRing = () => {
    this.setState((prevState: WaferControlMapState) => {
      const newDrawLayout = _.cloneDeep(prevState.drawLayout);
      if (prevState.drawLayout.showRing) {
        this.changeWaferMapVariable({ showRing: false });
        newDrawLayout.showRing = false;
      } else {
        this.setRingSize(UtilityFunctions.toBaseUnit({ value: prevState.waferDefinitionData.waferDiameter, UOM: prevState.waferDefinitionData.waferDiameterUOM, isDiameter: true })
        - 2 * UtilityFunctions.toBaseUnit({ value: +prevState.drawLayout.ringSize, UOM: prevState.drawLayout.ringUOM }), true);
        newDrawLayout.showRing = true;
      }
      return {
        drawLayout: newDrawLayout,
      };
    }, async () => {
      const { drawLayout } = this.state;
      if (this.plotter) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: `${drawLayout.showRing ? 'show' : 'hide'} ring`,
          actionUndoMessage: `${drawLayout.showRing ? 'show' : 'hide'} ring`,
        });
      }
    });
  };

  getAdditionalControls = (): WaferAdditionalControl[] => {
    const {
      drawLayout, showWATPCMSites,
    } = this.state;
    return [
      {
        eventKey: '1',
        title: (
          <>
            <FontAwesomeIcon
              icon={faSpinner}
              className="mr5"
            />
            Ring Controls
          </>
        ),
        body: (
          <div style={{ height: '200px' }}>
            <div className="custom-form mt0 pt0" style={{ fontSize: '10px' }}>
              <Label
                childrenItemsInline
                labelPosition="left-middle"
                labelSize="0"
                fieldSize="100"
                errorSize="100"
                labelTitle=""
              >
                <Label
                  labelTitle="Ring Size"
                  labelPosition="top"
                  labelSize="40"
                  fieldSize="60"
                  errorSize="100"
                  isFieldTouched
                  className="mb5"
                  fontSize="12px"
                >
                  <Textbox
                    containerClassName="small"
                    autoComplete="off"
                    type="number"
                    name="ringSize"
                    value={drawLayout.ringSize}
                    placeholder="0"
                    onChange={(e: any) => {
                      this.onChangeDrawLayout({ ...drawLayout, ringSize: e.target.value }, async () => {
                        if (this.plotter) {
                          await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
                            actionDoMessage: 'Change ring size',
                            actionUndoMessage: 'Change ring size',
                          });
                        }
                      });
                    }}
                  />
                </Label>
                <Label
                  labelTitle="UOM"
                  labelPosition="top"
                  labelSize="40"
                  fieldSize="60"
                  errorSize="100"
                  isFieldTouched
                  className="mb5 ml5"
                  fontSize="12px"
                >
                  <CustomizedDropdown
                    containerClassName="small"
                    variant="clear"
                    full
                    list={UOMs}
                    selectedValue={drawLayout.ringUOM || UOMs[0][0]}
                    onChange={(value: any) => {
                      this.onChangeDrawLayout({ ...drawLayout, ringUOM: value }, async () => {
                        if (this.plotter) {
                          await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
                            actionDoMessage: 'Change ring UOM',
                            actionUndoMessage: 'Change ring UOM',
                          });
                        }
                      });
                    }}
                  />
                </Label>
              </Label>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button
                  onClick={this.onClickShowHideRing}
                  variant="clear"
                  size="sm"
                  className="w60 btn-config h40"
                >
                  { drawLayout.showRing ? 'Hide' : 'Draw'}
                  {' '}
                  Ring
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button
                  onClick={() => { PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.CROP }, 'myid'); }}
                  variant="clear"
                  size="sm"
                  className="w60 btn-config h40"
                >
                  Crop Dies
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button onClick={this.onResetRing} variant="clear" size="sm" className="w60 btn-config h40">
                  Reset Ring
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button
                  onClick={() => { PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.REDRAW }, 'myid'); }}
                  variant="clear"
                  size="sm"
                  className="w60 btn-config h40"
                >
                  Redraw Dies
                </Button>
              </div>
            </div>
          </div>
        ),
      },
      {
        eventKey: '2',
        title: (
          <>
            <FontAwesomeIcon
              icon={faArrowsAlt}
              className="mr5"
            />
            Pan Controls
          </>
        ),
        body: (
          <div style={{ height: '200px' }}>
            <div className="custom-form mt0 pt0" style={{ fontSize: '10px' }}>
              <Label
                childrenItemsInline
                labelPosition="left-middle"
                labelSize="0"
                fieldSize="100"
                errorSize="100"
                labelTitle=""
                fontSize="12px"
              >
                <Label
                  labelTitle="Increment"
                  labelPosition="top"
                  labelSize="40"
                  fieldSize="60"
                  errorSize="100"
                  isFieldTouched
                  className="mb5"
                  fontSize="12px"
                >
                  <Textbox
                    containerClassName="small"
                    autoComplete="off"
                    type="number"
                    name="increment"
                    value={drawLayout.increment}
                    placeholder="0"
                    onChange={(e: any) => { this.onChangeDrawLayout({ ...drawLayout, increment: +e.target.value }); }}
                  />
                </Label>
                <Label
                  labelTitle="UOM"
                  labelPosition="top"
                  labelSize="40"
                  fieldSize="60"
                  errorSize="100"
                  isFieldTouched
                  className="mb5 ml5"
                  fontSize="10px"
                >
                  <CustomizedDropdown
                    containerClassName="small"
                    variant="clear"
                    full
                    list={UOMs.concat([['die', 'Die']])}
                    selectedValue={drawLayout.incrementUOM || UOMs[0][0]}
                    onChange={(value: any) => { this.onChangeDrawLayout({ ...drawLayout, incrementUOM: value }); }}
                  />
                </Label>
              </Label>
              <Row className="mb10">
                <Col style={{ textAlign: 'center' }}>
                  <CheckBox
                    value={drawLayout.drawLayoutPanResizeMode === DrawLayoutPanResizeMode.RING}
                    onValueChange={() => { this.onChangeDrawLayout({ ...drawLayout, drawLayoutPanResizeMode: DrawLayoutPanResizeMode.RING }); }}
                  />
                  {' '}
                  Ring
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  <CheckBox
                    value={drawLayout.drawLayoutPanResizeMode === DrawLayoutPanResizeMode.WAFER}
                    onValueChange={() => { this.onChangeDrawLayout({ ...drawLayout, drawLayoutPanResizeMode: DrawLayoutPanResizeMode.WAFER }); }}
                  />
                  {' '}
                  Wafer
                </Col>
              </Row>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button onClick={() => { this.panRingOrWafer(drawLayout.drawLayoutPanResizeMode, 0, -1); }} variant="clear" size="sm" className="w40 btn-config">
                  <small>
                    <FontAwesomeIcon
                      icon={faArrowUp}
                    />
                  </small>
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button onClick={() => { this.panRingOrWafer(drawLayout.drawLayoutPanResizeMode, 0, 1); }} variant="clear" size="sm" className="w40 btn-config">
                  <small>
                    <FontAwesomeIcon
                      icon={faArrowDown}
                    />
                  </small>
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button onClick={() => { this.panRingOrWafer(drawLayout.drawLayoutPanResizeMode, -1, 0); }} variant="clear" size="sm" className="w40 btn-config">
                  <small>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                    />
                  </small>
                </Button>
              </div>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button onClick={() => { this.panRingOrWafer(drawLayout.drawLayoutPanResizeMode, 1, 0); }} variant="clear" size="sm" className="w40 btn-config">
                  <small>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                    />
                  </small>
                </Button>
              </div>
            </div>
          </div>
        ),
      },
      {
        eventKey: '3',
        title: (
          <>
            <FontAwesomeIcon
              icon={faPlus}
              className="mr5"
            />
            Insert Controls
          </>
        ),
        body: (
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.INSERT_ROW_COL, config: { dimension: 'row', direction: 'below' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Row Below
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.INSERT_ROW_COL, config: { dimension: 'row', direction: 'above' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Row Above
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.INSERT_ROW_COL, config: { dimension: 'col', direction: 'left' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Col Left
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.INSERT_ROW_COL, config: { dimension: 'col', direction: 'right' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Col Right
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_INSERT_DIES, config: { direction: 'below' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Down
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_INSERT_DIES, config: { direction: 'above' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Up
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_INSERT_DIES, config: { direction: 'left' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Left
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_INSERT_DIES, config: { direction: 'right' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Right
              </Button>
            </div>
          </div>
        ),
      },
      {
        eventKey: '4',
        title: (
          <>
            <FontAwesomeIcon
              icon={faMinus}
              className="mr5"
            />
            Delete Controls
          </>
        ),
        body: (
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DELETE_ROW_COL, config: { dimension: 'row', direction: 'below' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Row Below
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DELETE_ROW_COL, config: { dimension: 'row', direction: 'above' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Row Above
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DELETE_ROW_COL, config: { dimension: 'col', direction: 'left' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Col Left
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DELETE_ROW_COL, config: { dimension: 'col', direction: 'right' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Col Right
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_DELETE_DIES, config: { direction: 'below' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Down
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_DELETE_DIES, config: { direction: 'above' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Up
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_DELETE_DIES, config: { direction: 'left' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Left
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  this.uncheckReticleAndInsertOrDelete(() => {
                    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.SHIFT_DELETE_DIES, config: { direction: 'right' } }, 'myid');
                  });
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Shift Right
              </Button>
            </div>
            <div style={{ textAlign: 'center', padding: '5px 0' }}>
              <Button
                onClick={() => {
                  PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.DELETE_SELECTED_DIES }, 'myid');
                }}
                variant="clear"
                size="sm"
                className="w70 btn-config"
              >
                Delete
              </Button>
            </div>
          </div>
        ),
      },
      {
        eventKey: '5',
        title: (
          <>
            <FontAwesomeIcon
              icon={faCrosshairs}
              className="mr5"
            />
            Other Controls
          </>
        ),
        body: (
          <div style={{ fontSize: '12px' }}>
            <div className="mt15">
              <CheckBox
                className="mr15"
                value={drawLayout.overlayReticle}
                onValueChange={this.onOverlayReticleCheckBoxValueChanged}
              />
              {' '}
              Overlay Reticle
            </div>
            <div className="mt15">
              <CheckBox
                disabled={!drawLayout.overlayReticle}
                className="mr15"
                value={drawLayout.showReferenceReticle}
                onValueChange={this.onShowReferenceReticleCheckBoxValueChanged}
              />
              {' '}
              Show Reference Reticle
            </div>
            <div className="mt15">
              <CheckBox
                className="mr15"
                value={drawLayout.markWaferCenter}
                onValueChange={(value: boolean) => {
                  this.onChangeDrawLayout({ ...drawLayout, markWaferCenter: value }, () => {
                    this.onMarkWaferCenterChange(value);
                  });
                }}
              />
              {' '}
              Wafer Center
            </div>
            { drawLayout.overlayReticle
              && (
                <div className="mt15">
                  <CheckBox
                    className="mr15"
                    value={showWATPCMSites}
                    onValueChange={(value: boolean) => {
                      this.setState({ showWATPCMSites: value }, () => {
                        this.changeWaferMapVariable({
                          showReticleText: value,
                          reticleTextField: value ? 'watPCMSiteNumber' : '',
                        });
                      });
                    }}
                  />
                  {' '}
                  Show WAT/PCM Sites
                </div>
              )}

          </div>
        ),
      },
    ];
  };

  onApplyReticleLayout = async () => {
    const { pcmWatSiteMapTabData, waferMapConfig, waferDefinitionData } = this.state;
    if (pcmWatSiteMapTabData.reticleCalculationMethod === ReticleCalculationMethod.ROWS_COLS) {
      if (!waferMapConfig) return;
      let leastX = null;
      let leastXDie = null;
      let maxX = Number.MIN_VALUE;
      let maxXDie = null;
      let leastY = null;
      let leastYDie = null;
      let maxY = Number.MIN_VALUE;
      let maxYDie = null;
      for (let i = 0; i < waferMapConfig.data.dies.length; i += 1) {
        for (let j = 0; j < waferMapConfig.data.dies[i].length; j += 1) {
          const dieData = waferMapConfig.data.dies[i][j];
          if (dieData && !dieData.isCropped && !dieData.isDeleted) {
            if (leastX === null) {
              leastX = j;
              leastXDie = dieData;
            }
            if (j > maxX) {
              maxX = j;
              maxXDie = dieData;
            }
            if (leastY === null) {
              leastY = i;
              leastYDie = dieData;
            }
            if (i > maxY) {
              maxY = i;
              maxYDie = dieData;
            }
          }
        }
      }

      if (!leastXDie || !leastYDie || !maxXDie || !maxYDie) return;
      const reticleSize = { x: +waferDefinitionData.reticleSizeX, y: +waferDefinitionData.reticleSizeY };
      const leastSx = UtilityFunctions.getReticleGridRectCoordOfDie(leastXDie, reticleSize).startX;
      const leastSy = UtilityFunctions.getReticleGridRectCoordOfDie(leastYDie, reticleSize).startY;
      const maxEx = UtilityFunctions.getReticleGridRectCoordOfDie(maxXDie, reticleSize).endX;
      const maxEy = UtilityFunctions.getReticleGridRectCoordOfDie(maxYDie, reticleSize).endY;

      const reticleRows = (maxEy - leastSy + 1) / waferDefinitionData.reticleSizeY;
      const reticleCols = (maxEx - leastSx + 1) / waferDefinitionData.reticleSizeX;

      const colsBefore = Math.ceil((+pcmWatSiteMapTabData.maxReticleCols - reticleCols) / 2);
      const startX = leastSx - colsBefore * waferDefinitionData.reticleSizeX;
      const endX = startX + waferDefinitionData.reticleSizeX - 1;
      const rowsBefore = Math.ceil((+pcmWatSiteMapTabData.maxReticleRows - reticleRows) / 2);
      const startY = leastSy - rowsBefore * waferDefinitionData.reticleSizeY;
      const endY = startY + waferDefinitionData.reticleSizeY - 1;

      const data: { [key: string]: any } = {};
      for (let i = 0; i < +pcmWatSiteMapTabData.maxReticleRows; i += 1) {
        for (let j = 0; j < +pcmWatSiteMapTabData.maxReticleCols; j += 1) {
          data[JSON.stringify({
            startX: startX + j * waferDefinitionData.reticleSizeX,
            startY: startY + i * waferDefinitionData.reticleSizeY,
            endX: endX + j * waferDefinitionData.reticleSizeX,
            endY: endY + i * waferDefinitionData.reticleSizeY,
          })] = {
            isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
          };
        }
      }
      this.changeWaferMapVariable({ reticleGridRectCoords: data });
      if (this.plotter) {
        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
          actionDoMessage: 'Apply reticle layout',
          actionUndoMessage: 'Apply reticle layout',
        });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  onChangePCMWATMapTabData = (key: string, value: any, callback?: any) => {
    this.setState((prevState: WaferControlMapState) => {
      if (key in prevState.pcmWatSiteMapTabData) {
        const newPcmWatSiteMapTabData = _.cloneDeep(prevState.pcmWatSiteMapTabData);
        newPcmWatSiteMapTabData[key] = value;
        return { pcmWatSiteMapTabData: newPcmWatSiteMapTabData };
      }
      return { pcmWatSiteMapTabData: prevState.pcmWatSiteMapTabData };
    });
  };

  onSaveZone = async (zoneData: ZoneData) => {
    if (!this.deviceWafer) return;
    let proceed = false;
    if (this.deviceWafer.getMarkedPCMSiteNumbers().length > 0) {
      if (zoneData.zoneType === 'RADIAL') {
        const numberOfRadialZonesWithNoPCMSites = this.deviceWafer.getNumberOfRadialZonesWithNoPCMSites(zoneData.numberOfZones!);
        if (numberOfRadialZonesWithNoPCMSites > 0) {
          if (await ModalPopup.confirm({
            header: 'Confirmation',
            body: `${numberOfRadialZonesWithNoPCMSites} ${numberOfRadialZonesWithNoPCMSites > 1 ? 'radial zones have' : 'radial zone has'} no PCM Site. Do you want to proceed?`,
          })) {
            proceed = true;
          }
        } else {
          proceed = true;
        }
      } else if (zoneData.zoneType === 'VERTICAL') {
        const numberOfVerticalZonesWithNoPCMSites = this.deviceWafer.getNumberOfVerticalZonesWithNoPCMSites(zoneData.numberOfZones!);
        if (numberOfVerticalZonesWithNoPCMSites !== null) {
          if (numberOfVerticalZonesWithNoPCMSites > 0) {
            if (await ModalPopup.confirm({
              header: 'Confirmation',
              body: `${numberOfVerticalZonesWithNoPCMSites} ${numberOfVerticalZonesWithNoPCMSites > 1 ? 'vertical zones have' : 'vertical zone has'} no PCM Site. Do you want to proceed?`,
            })) {
              proceed = true;
            }
          } else {
            proceed = true;
          }
        }
      } else if (zoneData.zoneType === 'HORIZONTAL') {
        const numberOfHoriontalZonesWithNoPCMSites = this.deviceWafer.getNumberOfHorizontalZonesWithNoPCMSites(zoneData.numberOfZones!);
        if (numberOfHoriontalZonesWithNoPCMSites !== null) {
          if (numberOfHoriontalZonesWithNoPCMSites > 0) {
            if (await ModalPopup.confirm({
              header: 'Confirmation',
              // eslint-disable-next-line max-len
              body: `${numberOfHoriontalZonesWithNoPCMSites} ${numberOfHoriontalZonesWithNoPCMSites > 1 ? 'horizontal zones have' : 'horizontal zone has'} no PCM Site. Do you want to proceed?`,
            })) {
              proceed = true;
            }
          } else {
            proceed = true;
          }
        }
      } else {
        proceed = true;
      }
    } else {
      toast.error('Can not add / update zone. No PCM site is marked');
    }

    this.setState((prevState: WaferControlMapState) => {
      if (proceed) {
        const newZones = _.cloneDeep(prevState.zones);
        const filteredZones = newZones.filter((z: ZoneData) => { return z.id === zoneData.id; });
        if (filteredZones.length === 0) {
          newZones.push(zoneData);
          toast.success('New zone added.');
          return {
            zones: newZones,
            selectedZoneId: zoneData.id,
          };
        // eslint-disable-next-line no-else-return
        } else {
          toast.success('Zone updated.');
          newZones.forEach((z: ZoneData, index: number) => {
            if (z.id === zoneData.id) {
              newZones[index] = zoneData;
            }
          });
          return {
            zones: newZones,
            selectedZoneId: prevState.selectedZoneId,
          };
        }
      // eslint-disable-next-line no-else-return
      } else {
        return {
          zones: prevState.zones,
          selectedZoneId: prevState.selectedZoneId,
        };
      }
    }, async () => {
      const { selectedZoneId, zones } = this.state;
      this.changeWaferMapVariable({ currentZoneId: selectedZoneId, zones }, false);
      if (zoneData.zoneType === 'RADIAL' && zoneData.numberOfZones !== undefined && proceed) {
        PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
          action: ActionOnWaferData.APPLY_RADIAL_ZONE,
          config: {
            selectedZoneId,
          },
        }, 'myid');
      } else if (zoneData.zoneType === 'VERTICAL' && proceed) {
        PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
          action: ActionOnWaferData.APPLY_VERTICAL_ZONE,
          config: {
            selectedZoneId,
          },
        }, 'myid');
      } else if (zoneData.zoneType === 'HORIZONTAL' && proceed) {
        PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
          action: ActionOnWaferData.APPLY_HORIZONTAL_ZONE,
          config: {
            selectedZoneId,
          },
        }, 'myid');
      } else if (zoneData.zoneType === 'GROUPED_PER_SITE' && proceed) {
        PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
          action: ActionOnWaferData.APPLY_GROUPED_PER_SITE_ZONE,
          config: {
            selectedZoneId,
          },
        }, 'myid');
      }
    });
  };

  getSelectedZoneData = () => {
    const { zones, selectedZoneId } = this.state;
    let selectedZoneData: ZoneData = {
      id: null,
      zoneName: '',
      zoneType: 'FREE_FORM',
      zoneApplication: [],
      createdBy: null,
      createdOn: null,
      updatedBy: null,
      updatedOn: null,
      numberOfZones: undefined,
      markedPCMSiteNumbers: [],
    };
    if (selectedZoneId) {
      const filteredZones = zones.filter((zone: ZoneData) => { return zone.id === selectedZoneId; });
      if (filteredZones.length > 0) {
        selectedZoneData = _.cloneDeep(filteredZones[0]);
      }
    }
    return selectedZoneData;
  };

  getTabContent = () => {
    const {
      waferDefinitionData, activeTab, pcmWatSiteMapTabData, middleViewport,
    } = this.state;
    let tabContent = null;

    switch (activeTab) {
      case ActiveTab.WAFER_DEFINTION:
        tabContent = (
          <div className="wafer-definition">
            <WaferDefinition
              ref={(ref: any) => { this.waferDefinitionRef = ref; }}
              waferDefinitionData={waferDefinitionData}
              onSaveWaferDefinitionData={this.onSaveWaferDefinitionData}
              onSaveReticleData={this.onSaveReticleData}
            />
          </div>
        );
        break;
      case ActiveTab.ADDITIONAL_ATTRIBUTES:
        tabContent = null;
        break;
      case ActiveTab.SITE_MAP:
        tabContent = (
          <PCMWATSiteMapTab
            pcmWatSiteMapTabData={pcmWatSiteMapTabData}
            formErrors={{}}
            onChangePCMWATMapTabData={this.onChangePCMWATMapTabData}
            onApplyReticleLayout={this.onApplyReticleLayout}
            onResetReticle={this.validateAndOverlayReticle}
            onCropSelectedReticles={this.onCropSelectedReticles}
            onPanReticle={(direction: ReticlePanDirection) => {
              PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
                action: ActionOnWaferData.PAN_RETICLE,
                config: {
                  direction, increment: +pcmWatSiteMapTabData.panIncrement, UOM: pcmWatSiteMapTabData.panIncrementUOM, mode: pcmWatSiteMapTabData.panMode,
                },
              }, 'myid');
            }}
            onInsert={(direction: ReticleInsertDirection) => {
              PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, {
                action: ActionOnWaferData.SHIFT_INSERT_RETICLE,
                config: {
                  direction,
                },
              }, 'myid');
            }}
          />
        );
        break;
      case ActiveTab.DEFINE_ZONES:
        tabContent = this.deviceWafer ? (
          <DefineZonesTab
            viewDefineZonesBtnText={middleViewport === MiddleViewport.WAFER ? 'View Defined Zones List' : 'Device Map'}
            onClickDefineZones={() => {
              this.setState((prevState: WaferControlMapState) => {
                return {
                  middleViewport: prevState.middleViewport === MiddleViewport.WAFER ? MiddleViewport.ZONES : MiddleViewport.WAFER,
                };
              });
            }}
            onChangeFreeFormPCMSiteNumber={(freeFormZonePCMSiteNumber: number | null) => { this.changeWaferMapVariable({ freeFormZonePCMSiteNumber }, false); }}
            zoneData={this.getSelectedZoneData()}
            onClear={() => {
              this.changeWaferMapVariable({ currentZoneId: '' }, true);
              this.setState({ selectedZoneId: null });
            }}
            onSaveZone={this.onSaveZone}
            deviceWafer={this.deviceWafer}
          />
        ) : null;
        break;
      default:
        tabContent = null;
        break;
    }
    return tabContent;
  };

  getWaferControlMapDataForRequest = () => {
    const {
      name, access, state, description, version, zones, owner,
      waferDefinitionData, orignalWaferDimensions, WCMAttributeDefinitions,
    } = this.state;

    let dieWidthToStreetWidthRatio = 0;
    if (this.plotter && waferDefinitionData.dieSizeX !== waferDefinitionData.dieStepSizeX) {
      dieWidthToStreetWidthRatio = this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.dieWidthToStreetWidthRatio;
    }
    let dieHeightToStreetHeightRatio = 0;
    if (this.plotter && waferDefinitionData.dieSizeY !== waferDefinitionData.dieStepSizeY) {
      dieHeightToStreetHeightRatio = this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.dieHeightToStreetHeightRatio;
    }

    return {
      name,
      owner,
      state,
      version,
      description,
      access,
      diameter: waferDefinitionData.waferDiameter,
      diameterUOM: waferDefinitionData.waferDiameterUOM,
      edgeExclusion: waferDefinitionData.waferEdgeExclusion,
      edgeExclusionUOM: waferDefinitionData.waferEdgeExclusionUOM,
      exclusionOption: ExclusionType[waferDefinitionData.exclusionType],
      scribeLine: waferDefinitionData.waferScribeLine,
      scribeLineUOM: waferDefinitionData.waferScribeLineUOM,
      baseFlat: waferDefinitionData.waferBaseFlat,
      baseFlatUOM: waferDefinitionData.waferBaseFlatUOM,
      notchKeepOut: waferDefinitionData.waferNotchKeepOut,
      notchKeepOutUOM: waferDefinitionData.waferNotchKeepOutUOM,
      flatKeepOut: waferDefinitionData.waferFlatKeepOut,
      flatKeepOutUOM: waferDefinitionData.waferFlatKeepOutUOM,
      inputNotchPosition: waferDefinitionData.waferInputNotchPosition,
      outputNotchPosition: waferDefinitionData.waferOutputNotchPosition,
      originLocation: WaferOriginLocation[waferDefinitionData.waferOriginLocation],
      axisDirection: WaferAxisDirection[waferDefinitionData.waferAxisDirection],
      axisOffsetX: waferDefinitionData.waferColOffset,
      axisOffsetY: waferDefinitionData.waferRowOffset,
      pGDW: waferDefinitionData.waferPGDW,
      dieUOM: waferDefinitionData.dieUOM,
      dieDimensionType: DieSpecType[waferDefinitionData.dieSpecType],
      dieWidth: waferDefinitionData.dieSizeX,
      dieHeight: waferDefinitionData.dieSizeY,
      dieStepWidth: waferDefinitionData.dieStepSizeX,
      dieStepHeight: waferDefinitionData.dieStepSizeY,
      reticleCols: waferDefinitionData.reticleSizeX,
      reticleRows: waferDefinitionData.reticleSizeY,
      reticleUOM: waferDefinitionData.reticleUOM,
      reticleOffsetX: waferDefinitionData.reticleOffsetX,
      reticleOffsetY: waferDefinitionData.reticleOffsetY,
      reticleReference: waferDefinitionData.reticleReference,
      reticleWATPCMSite: waferDefinitionData.standardReticle.WATPCMSite,
      drawMethod: CalculationMethod[waferDefinitionData.calculationMethod],
      fixedCols: waferDefinitionData.waferMaxCols,
      fixedRows: waferDefinitionData.waferMaxRows,
      areStandardCoordinatesEnabled: this.plotter ? this.plotter.state.waferStates[DEVICE_WAFER_KEY].isDieCoordinatesSystemEnabled : false,
      areReticleCoordinatesEnabled: this.plotter ? this.plotter.state.waferStates[DEVICE_WAFER_KEY].isReticleCoordinatesSystemEnabled : false,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      waferWidthToColsRatio: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.waferWidthToColsRatio : 0,
      waferHeightToRowsRatio: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.waferHeightToRowsRatio : 0,
      waferBGOffsetXDies: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.waferBGOffsetXDies : 0,
      waferBGOffsetYDies: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.waferBGOffsetYDies : 0,
      overlayReticle: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].waferMapVariables.overlayReticle : false,
      waferWidthOrignal: orignalWaferDimensions.width,
      waferHeightOrignal: orignalWaferDimensions.height,
      hasSiteAttributes: waferDefinitionData.standardReticle.hasSiteAttributes,
      dies: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].getDiesList() : null,
      reticles: this.plotter ? this.plotter.waferUtils[DEVICE_WAFER_KEY].getReticlesList() : null,
      reticleSites: this.getReticleSites(waferDefinitionData.standardReticle),
      reticleSiteAttributes: waferDefinitionData.standardReticle.reticleSiteAttributesMeta.map((rsam: ReticleSiteAttributeMeta) => {
        return { ...rsam, columnMeta: { ...rsam.columnMeta, type: MetaColumnType[rsam.columnMeta.type] } };
      }),
      reticleSiteAttributeMappings: this.getReticleSiteAttributeMappings(waferDefinitionData.standardReticle),
      zones: zones.map((zoneData: ZoneData) => {
        return {
          id: zoneData.id,
          name: zoneData.zoneName,
          type: zoneData.zoneType,
          zoneApplication: zoneData.zoneApplication,
          markedPCMSiteNumbers: zoneData.markedPCMSiteNumbers,
          numberOfZones: (zoneData.numberOfZones !== undefined && zoneData.zoneType === 'RADIAL') ? zoneData.numberOfZones : 0,
          verticalZoneColNumbers: zoneData.verticalZoneColNumbers === undefined ? null : zoneData.verticalZoneColNumbers,
          horizontalZoneRowNumbers: zoneData.horizontalZoneRowNumbers === undefined ? null : zoneData.horizontalZoneRowNumbers,
          ycoords: zoneData.ycoords === undefined ? null : zoneData.ycoords,
          xcoords: zoneData.xcoords === undefined ? null : zoneData.xcoords,
        };
      }),
      WCMAttributeDefinitions: WCMAttributeDefinitions.map((def: any) => {
        return {
          attributeMeta: { id: def.name },
          expressionValue: def.value,
        };
      }),
    };
  };

  postWaferControlMap = async () => {
    this.setState({ isInSavingState: true }, async () => {
      const { history } = this.props;
      const response = await httpWaferControlMap.postWaferControlMap(this.getWaferControlMapDataForRequest());
      if (response) {
        if (response.success && response.statusCode === 200) {
          toast.success(response.message);
          history.push(`/wafer-control-map/${response.data.id}`);
          this.setState({ isInSavingState: false, WCMAttributeDefinitions: this.mapAttributeDefinitions(response.data.wcmAttributeDefinitions) });
        } else {
          toast.error(response.message);
          this.setState({ isInSavingState: false });
        }
      } else {
        this.setState({ isInSavingState: false });
      }
    });
  };

  putWaferControlMap = async () => {
    this.setState({ isInSavingState: true }, async () => {
      const { match, history } = this.props;
      const response = await httpWaferControlMap.putWaferControlMap(this.getWaferControlMapDataForRequest(), match.params.id);
      if (response) {
        if (response.success && response.statusCode === 200) {
          toast.success(response.message);
          this.setState({ isInSavingState: false, WCMAttributeDefinitions: this.mapAttributeDefinitions(response.data.wcmAttributeDefinitions) });
        } else {
          toast.error(response.message);
          history.push('/wafer-control-map');
          this.setState({ isInSavingState: false });
        }
      } else {
        history.push('/wafer-control-map');
        this.setState({ isInSavingState: false });
      }
    });
  };

  getReticleSiteAttributeMappings = (standardReticle: StandardReticle) => {
    const reticleSiteAttributeMappings: { [siteNumber: number]: { [columnName: string]: string } } = {};
    for (let i = 0; i < standardReticle.siteAttributesData.length; i += 1) {
      reticleSiteAttributeMappings[standardReticle.siteAttributesData[i].siteNumber] = { ...standardReticle.siteAttributesData[i] };
      delete reticleSiteAttributeMappings[standardReticle.siteAttributesData[i].siteNumber].siteNumber;
    }
    return reticleSiteAttributeMappings;
  };

  getReticleSites = (standardReticle: StandardReticle) => {
    const reticleSites: { siteRow: number, siteCol: number, siteNumber: number }[] = [];
    for (let i = 0; i < standardReticle.reticle.length; i += 1) {
      for (let j = 0; j < standardReticle.reticle[i].length; j += 1) {
        reticleSites.push({ siteRow: i, siteCol: j, siteNumber: standardReticle.reticle[i][j] });
      }
    }
    return reticleSites;
  };

  onSaveWCM = (data: ComponentHeaderData) => {
    const { waferDefinitionData } = this.state;
    let shouldSendRequest = false;
    if (this.waferDefinitionRef) {
      const waferDefinitionDataFromRef: WaferDefinitionData = this.waferDefinitionRef.state.waferDefinitionData;
      if (waferDefinitionDataFromRef.version === 1) {
        toast.warn('Please define wafer from the \'Wafer Definition\' tab.');
      } else if (_.isEqual(waferDefinitionData, waferDefinitionDataFromRef)) {
        shouldSendRequest = true;
      } else {
        toast.warn('Please define wafer from the \'Wafer Definition\' tab using the new changes.');
      }
    } else {
      shouldSendRequest = true;
    }
    if (shouldSendRequest) {
      this.setState({
        name: data.name,
        owner: data.owner,
        access: data.access,
        version: data.version,
        description: data.description,
        state: data.state,
      }, async () => {
        const { match } = this.props;
        if (match.params.id === undefined) {
          await this.postWaferControlMap();
        } else {
          await this.putWaferControlMap();
        }
      });
    }
  };

  onUpdateDieType = async (event: any) => {
    const response = await httpDieType.putDieType({
      name: event.data.name,
      color: event.data.color,
      isProbed: 'isProbed' in event.data ? event.data.isProbed : false,
      isYielded: 'isYielded' in event.data ? event.data.isYielded : false,
      isTouchDownAllowed: 'isTouchDownAllowed' in event.data ? event.data.isTouchDownAllowed : false,
      character: 'character' in event.data ? event.data.character : '',
    }, event.key);
    if (response) {
      toast.success(response.message);
      this.setState({ dieTypes: response.data }, () => {
        const { dieTypes } = this.state;
        this.changeWaferMapVariable({ dieTypes: { dieType: dieTypes } });
      });
    }
  };

  onAddDieType = async (event: any) => {
    const response = await httpDieType.postDieType({
      name: event.data.name,
      color: event.data.color,
      isProbed: 'isProbed' in event.data ? event.data.isProbed : false,
      isYielded: 'isYielded' in event.data ? event.data.isYielded : false,
      isTouchDownAllowed: 'isTouchDownAllowed' in event.data ? event.data.isTouchDownAllowed : false,
      character: 'character' in event.data ? event.data.character : '',
    });
    if (response && response.success && response.statusCode === 200) {
      toast.success(response.message);
      this.setState({ dieTypes: response.data }, () => {
        const { dieTypes } = this.state;
        this.changeWaferMapVariable({ dieTypes: { dieType: dieTypes } });
      });
    }
  };

  sendDietypeDeletionRequest = async (checkUsage: boolean, dieTypeId: string) => {
    const response = await httpDieType.deleteDieType(dieTypeId, checkUsage);
    if (response) {
      if (checkUsage && response.statusCode === 409) {
        if (await ModalPopup.confirm({
          header: 'Confirmation',
          body: 'This dietype is marked in some wafer control map. Do you want to proceed deleting it?',
        })) {
          this.sendDietypeDeletionRequest(false, dieTypeId);
        } else {
          this.setState((prevState: WaferControlMapState) => {
            return {
              dieTypes: prevState.dieTypes,
            };
          });
        }
      } else if (!response.success) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        if (this.deviceWafer) this.deviceWafer.removeDieTypeFromDies(dieTypeId);
        this.changeWaferMapVariable({ dieTypes: { dieType: response.data } });
        this.setState({ dieTypes: response.data });
      }
    }
  };

  onDeleteDieType = async (event: any) => {
    let shouldConfirmDeletion = false;
    let checkUsage = true;
    let sendRequest = false;
    if (this.deviceWafer && event.key in this.deviceWafer!.waferMapVariables.dieTypeCountInfo && this.deviceWafer!.waferMapVariables.dieTypeCountInfo[event.key] > 0) {
      shouldConfirmDeletion = true;
    } else {
      sendRequest = true;
    }
    if (shouldConfirmDeletion && await ModalPopup.confirm({
      header: 'Confirmation',
      body: 'This dietype is marked in some wafer control map. Do you want to proceed deleting it?',
    })) {
      checkUsage = false;
      sendRequest = true;
    }

    if (sendRequest) {
      this.sendDietypeDeletionRequest(checkUsage, event.key);
    } else {
      this.setState((prevState: WaferControlMapState) => {
        return {
          dieTypes: prevState.dieTypes,
        };
      });
    }
  };

  render() {
    const {
      waferMapConfig, dieTypes, allWCMAttributeMetas, WCMAttributeDefinitions, drawLayout, isInSavingState, isLoading,
      middleViewport, currentDieTypeId, activeTab, collapseTabsColumn, collapseDieSummaryColumn, waferDefinitionData,
      name, access, version, state, description, pcmWatTestMethod, pcmWatSiteNumber, maxPcmWatSiteNumber, watProbingSequence, zones, selectedZoneId, owner,
    } = this.state;
    const { history } = this.props;
    let centerArea = 7;
    if (collapseTabsColumn && collapseDieSummaryColumn) centerArea = 12;
    if (!collapseTabsColumn && collapseDieSummaryColumn) centerArea = 9;
    if (collapseTabsColumn && !collapseDieSummaryColumn) centerArea = 10;
    let rightSideBar: any = (
      <DieCountSummary
        isLoading={isLoading}
        dieTypes={dieTypes}
        middleViewport={middleViewport}
        currentDieTypeId={currentDieTypeId}
        onDieTypeDoubleClick={() => {
          this.setState({ middleViewport: MiddleViewport.DIE_TYPES });
        }}
        onDieTypeClick={(dieType: DieType | null) => {
          const newDietypeId = dieType === null ? null : dieType.id;
          this.changeWaferMapVariable({ currentDieType: newDietypeId }, false);
          this.setState({ currentDieTypeId: newDietypeId });
        }}
        countSummary={{
          dieTypeCountInfo: this.deviceWafer ? this.deviceWafer.waferMapVariables.dieTypeCountInfo : {},
          rowCount: this.deviceWafer ? this.deviceWafer.waferMapVariables.rowCount : 0,
          colCount: this.deviceWafer ? this.deviceWafer.waferMapVariables.colCount : 0,
          totalDieCount: this.deviceWafer ? this.deviceWafer.waferMapVariables.dieCount : 0,
          selectedDieCount: this.deviceWafer ? this.deviceWafer.waferMapVariables.selectedDieCount : 0,
        }}
        onDefineDieTypesButtonClicked={async () => {
          if (this.dieTypesDatagrid) {
            if (!UtilityFunctions.isDataGridInEditMode(this.dieTypesDatagrid)) {
              this.setState((prevState) => {
                return {
                  middleViewport: prevState.middleViewport === MiddleViewport.WAFER ? MiddleViewport.DIE_TYPES : MiddleViewport.WAFER,
                };
              });
            } else {
              // eslint-disable-next-line no-lonely-if
              if (middleViewport === MiddleViewport.DIE_TYPES && await ModalPopup.confirm({
                header: 'Changes Unsaved',
                body: 'You have unsaved changes in the die types grid. Do you want to proceed?',
              })) {
                this.setState((prevState) => {
                  return {
                    middleViewport: prevState.middleViewport === MiddleViewport.WAFER ? MiddleViewport.DIE_TYPES : MiddleViewport.WAFER,
                  };
                });
              }
            }
          } else {
            this.setState((prevState) => {
              return {
                middleViewport: prevState.middleViewport === MiddleViewport.WAFER ? MiddleViewport.DIE_TYPES : MiddleViewport.WAFER,
              };
            });
          }
        }}
      />
    );

    if (activeTab === ActiveTab.SITE_MAP) {
      rightSideBar = (
        <PCMWATSiteMapSideBar
          pcmWatTestMethod={pcmWatTestMethod}
          watProbingSequence={watProbingSequence}
          maxPcmWatSiteNumber={maxPcmWatSiteNumber}
          onChangePcmWatTestMethod={(newPcmWatTestMethod: string) => {
            this.changeWaferMapVariable({ currentWatTestMethod: newPcmWatTestMethod }, false);
            this.setState({ pcmWatTestMethod: newPcmWatTestMethod });
          }}
          onChangeWatProbingSequence={(newWatProbingSequence: string) => { this.setState({ watProbingSequence: newWatProbingSequence }); }}
          pcmWatSiteNumber={pcmWatSiteNumber}
          onChangePcmWatSiteNumber={(value: string) => {
            const newPcmWatSiteNumber: number | null = value === 'null' ? null : +value;
            this.changeWaferMapVariable({ currentWatPCMSiteNumber: newPcmWatSiteNumber }, false);
            this.setState({ pcmWatSiteNumber: newPcmWatSiteNumber });
          }}
          onAddPcmWatSite={() => {
            this.setState((prevState: WaferControlMapState) => {
              return { maxPcmWatSiteNumber: prevState.maxPcmWatSiteNumber + 1 };
            }, () => {
              toast.success('PCM WAT Site number added to dropdown');
            });
          }}
          onApplyFullWatMap={() => {
            PublishSubscribe().publishWithOthersID(EventTypes.WAFER_DATA_REQUEST, { action: ActionOnWaferData.APPLY_FULL_WAT_MAP, config: { } }, 'myid');
          }}
        />
      );
    } else if (activeTab === ActiveTab.DEFINE_ZONES) {
      rightSideBar = null;
    }

    return (
      <Container fluid className="body-content-scroll wafer-control-map">
        <HomeDieCoordsForm />
        <TopbarNav
          title="Wafer Control Map"
          items={[]}
          showAvatar={false}
          showNotifications={false}
        />
        <Row className="pt0 pl30 pr30 mt10">
          <Col lg={12} className="mb20 pl0 pr0">
            <div className="custom-form mb0">
              <ComponentHeader
                isInSavingState={isInSavingState}
                componentHeaderData={{
                  name, version, access, description, state, owner,
                }}
                saveButtonText="Save wafer control map"
                onParentSave={this.onSaveWCM}
                onBack={async () => {
                  if (await ModalPopup.confirm({
                    header: 'Override Alert',
                    body: 'Are you sure that you have saved all your changes. Do you want to proceed?',
                  })) {
                    history.goBack();
                  }
                }}
              />
            </div>
          </Col>
          <Col lg={12} className="mb20 pl0 pr0">
            <Row className="ml0 mr0 pt10 pl10 pr10 pb30 border-all background-color-light border-radius-4">
              {!collapseTabsColumn && (
                <Col lg={3} className="pr0 pl0" style={{ border: '1px solid #ddd', height: '100vh' }}>
                  <Container style={{ borderBottom: '1px solid #ddd' }}>
                    <Row>
                      <Col className="pr1 pl1">
                        <div
                          onClick={() => {
                            this.changeWaferMapVariable({ selectionMode: SelectionMode.DIE, markingMode: MarkingMode.DIE_TYPE, dieColorType: DieColorType.DIE_TYPE });
                            this.setState({ activeTab: ActiveTab.WAFER_DEFINTION, middleViewport: MiddleViewport.WAFER });
                          }}
                          className={`custom-tab ${activeTab === ActiveTab.WAFER_DEFINTION ? 'active-custom-tab' : ''}`}
                        >
                          Wafer Definition
                        </div>
                      </Col>
                      <Col className="pr1 pl1">
                        <div
                          onClick={() => {
                            // TODO: Change this version after backend done
                            if (waferDefinitionData.version === 1) {
                              toast.warning('Please define wafer first');
                            } else {
                              this.setState({ activeTab: ActiveTab.ADDITIONAL_ATTRIBUTES, middleViewport: MiddleViewport.UDA });
                            }
                          }}
                          className={`custom-tab ${activeTab === ActiveTab.ADDITIONAL_ATTRIBUTES ? 'active-custom-tab' : ''}`}
                        >
                          Additional Attributes
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr1 pl1">
                        <div
                          onClick={() => {
                            // TODO: Change this version after backend done
                            if (waferDefinitionData.version === 1) {
                              toast.warning('Please define wafer first');
                            } else {
                              const errors = this.validateReticleData(waferDefinitionData);
                              if (Object.keys(errors).length !== 0) {
                                this.showErrorToasts(errors);
                              } else if (drawLayout.overlayReticle) {
                                this.changeWaferMapVariable({
                                  selectionMode: SelectionMode.RETICLE, markingMode: MarkingMode.WAT_PCM_SITE_NUMBER, dieTextField: 'reticleSite', dieColorType: DieColorType.DIE_TYPE,
                                });
                                this.setState((prevState: WaferControlMapState) => {
                                  let newWaferMapConfig = prevState.waferMapConfig;
                                  if (prevState.waferMapConfig) {
                                    newWaferMapConfig = { ...prevState.waferMapConfig, dieTextField: 'reticleSite' };
                                  }
                                  return {
                                    waferMapConfig: newWaferMapConfig,
                                    activeTab: ActiveTab.SITE_MAP,
                                    middleViewport: MiddleViewport.WAFER,
                                  };
                                });
                              } else {
                                toast.warning('Please use other controls to overlay reticle first');
                              }
                            }
                          }}
                          className={`custom-tab ${activeTab === ActiveTab.SITE_MAP ? 'active-custom-tab' : ''}`}
                        >
                          PCM/WAT Site Map
                        </div>
                      </Col>
                      <Col className="pr1 pl1">
                        <div
                          onClick={() => {
                            // TODO: Change this version after backend done
                            if (waferDefinitionData.version === 1) {
                              toast.warning('Please define wafer first');
                            } else {
                              const errors = this.validateReticleData(waferDefinitionData);
                              if (Object.keys(errors).length !== 0) {
                                this.showErrorToasts(errors);
                              } else if (drawLayout.overlayReticle) {
                                this.changeWaferMapVariable({
                                  selectionMode: SelectionMode.RETICLE, markingMode: MarkingMode.ZONE_SITE, dieTextField: 'zoneInfo', dieColorType: DieColorType.ZONE,
                                });
                                this.setState((prevState: WaferControlMapState) => {
                                  let newWaferMapConfig = prevState.waferMapConfig;
                                  if (prevState.waferMapConfig) {
                                    newWaferMapConfig = { ...prevState.waferMapConfig, dieTextField: 'zoneInfo' };
                                  }
                                  return {
                                    waferMapConfig: newWaferMapConfig,
                                    activeTab: ActiveTab.DEFINE_ZONES,
                                    middleViewport: MiddleViewport.WAFER,
                                  };
                                });
                              } else {
                                toast.warning('Please use other controls to overlay reticle first');
                              }
                            }
                          }}
                          className={`custom-tab ${activeTab === ActiveTab.DEFINE_ZONES ? 'active-custom-tab' : ''}`}
                        >
                          Define Zones
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    {this.getTabContent()}
                  </Container>
                </Col>
              )}
              <Col lg={centerArea}>
                <div style={{ display: middleViewport === MiddleViewport.WAFER ? 'block' : 'none' }} ref={(ref: any) => { this.waferDiv = ref; }}>
                  {isLoading && (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '430px' }}>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!isLoading && waferMapConfig !== null && (
                    <WaferPlotter
                      WCMAttributeDefinitionsGetterSetter={{ get: this.getWCMAttributeDefinitions, set: this.setWCMAttributeDefinitions }}
                      PCMWatSiteMapTabDataGetterSetter={{ get: this.getPCMWatSiteMapTabData, set: this.setPCMWatSiteMapTabData }}
                      onWCMDefintionActionUndoRedo={this.onWCMDefintionActionUndoRedo}
                      getWaferDefinitionData={this.getWaferDefinitionData}
                      getOrignalWaferDimensions={this.getOrignalWaferDimensions}
                      drawLayoutGetterSetter={{ get: this.getDrawLayoutData, set: this.setDrawLayoutFromDbState }}
                      draggableControlsWidth={250}
                      width={this.canvasDimension}
                      height={WAFER_PLOTTER_CANVAS_HEIGHT}
                      ref={(r) => { this.plotter = r; }}
                      plotterKey={DEVICE_WAFER_KEY}
                      config={{
                        [DEVICE_WAFER_KEY]: {
                          additionalControls: this.getAdditionalControls(),
                          dieTextField: waferMapConfig.dieTextField,
                          onContextMenuItemClick: (event: any) => {
                            switch (event.itemData.action) {
                              case WCMContextMenuAction.EXPAND_WAFER:
                                this.onChangeLayout(true, true);
                                break;
                              case WCMContextMenuAction.COLLAPSE_WAFER:
                                this.onChangeLayout(false, false);
                                break;
                              case WCMContextMenuAction.COLLAPSE_DIE_SUMMARY:
                                this.onChangeLayout(null, true);
                                break;
                              case WCMContextMenuAction.EXPAND_DIE_SUMMARY:
                                this.onChangeLayout(null, false);
                                break;
                              case WCMContextMenuAction.COLLAPSE_DEFINITION_TABS:
                                this.onChangeLayout(true, null);
                                break;
                              case WCMContextMenuAction.EXPAND_DEFINITION_TABS:
                                this.onChangeLayout(false, null);
                                break;
                              default:
                                break;
                            }
                          },
                          contextMenuDataSource: [
                            {
                              text: 'Layout',
                              items: [
                                {
                                  text: collapseTabsColumn && collapseDieSummaryColumn ? 'Collapse Wafer' : 'Expand Wafer',
                                  action: collapseTabsColumn && collapseDieSummaryColumn ? WCMContextMenuAction.COLLAPSE_WAFER : WCMContextMenuAction.EXPAND_WAFER,
                                },
                                {
                                  text: collapseTabsColumn ? 'Expand Definition Tabs' : 'Collapse Definition Tabs',
                                  action: collapseTabsColumn ? WCMContextMenuAction.EXPAND_DEFINITION_TABS : WCMContextMenuAction.COLLAPSE_DEFINITION_TABS,
                                },
                                {
                                  text: collapseDieSummaryColumn ? 'Expand Die Summary' : 'Collapse Die Summary',
                                  action: collapseDieSummaryColumn ? WCMContextMenuAction.EXPAND_DIE_SUMMARY : WCMContextMenuAction.COLLAPSE_DIE_SUMMARY,
                                },
                              ],
                            },
                          ],
                        },
                      }}
                    />
                  )}
                </div>

                <div style={{ display: middleViewport === MiddleViewport.DIE_TYPES ? 'block' : 'none' }}>
                  <EditableDataGrid
                    key={new Date().toString()}
                    height={400}
                    showFilterRow
                    showAdvancedFilters={false}
                    enableColumnChooser={false}
                    showGroupPanel={false}
                    showRowLines
                    showColumnLines
                    allowAdding
                    allowUpdating
                    allowDeleting
                    columnAutoWidth
                    columnFixing
                    columnHidingEnabled
                    scrollMode="virtual"
                    editingMode="row"
                    keyExpr="id"
                    onRowUpdated={this.onUpdateDieType}
                    onRowInserted={this.onAddDieType}
                    onRowRemoved={this.onDeleteDieType}
                    tableData={dieTypes}
                    fields={[
                      {
                        caption: 'Name',
                        dataField: 'name',
                        dataType: 'string',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: true,
                        allowSorting: true,
                        cellType: 'textBox',
                        isRequired: true,
                        validationRules: [
                          (
                            <PatternRule
                              message="Die Name Should Not Exceed 255 Characters"
                              pattern={/^.{1,255}$/i}
                            />
                          ),
                        ],
                      },
                      {
                        caption: 'Color',
                        dataField: 'color',
                        dataType: 'string',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: false,
                        allowSorting: false,
                        cellType: 'textBox',
                        isRequired: true,
                        editCellRender: (cell: any) => {
                          return (
                            <ColorBox
                              defaultValue={cell.value}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...cell.column.lookup}
                              onValueChanged={(event: any) => { cell.setValue(event.value); }}
                            />
                          );
                        },
                        cellRender: (e: any) => {
                          if (e.data) {
                            return <div className="h20 mr5 mt0 ml0 mb0 " style={{ backgroundColor: (e.data.color) }} />;
                          }
                          return null;
                        },
                      },
                      {
                        caption: 'Probed',
                        dataField: 'isProbed',
                        dataType: 'boolean',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: true,
                        allowSorting: true,
                        isRequired: false,
                        cellType: 'textBox',
                      },
                      {
                        caption: 'Yielded',
                        dataField: 'isYielded',
                        dataType: 'boolean',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: true,
                        allowSorting: true,
                        isRequired: false,
                        cellType: 'textBox',
                      },
                      {
                        caption: 'Touch Down Allowed',
                        dataField: 'isTouchDownAllowed',
                        dataType: 'boolean',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: true,
                        allowSorting: true,
                        isRequired: false,
                        cellType: 'textBox',
                      },
                      {
                        caption: 'Character',
                        dataField: 'character',
                        dataType: 'string',
                        allowEditing: true,
                        showInfo: false,
                        allowFiltering: true,
                        allowSorting: true,
                        cellType: 'textBox',
                        isRequired: false,
                        validationRules: [
                          (
                            <PatternRule
                              message="Character Should Not Exceed 255 Characters"
                              pattern={/^.{1,255}$/i}
                            />
                          ),
                        ],
                      },
                    ]}
                  />
                </div>

                <div style={{ display: middleViewport === MiddleViewport.ZONES ? 'block' : 'none' }}>
                  <h5>Defined Zones</h5>
                  <DataGrid
                    keyExpr="id"
                    ref={(datagrid: any) => { this.zonesDatagrid = datagrid; }}
                    style={{ marginTop: '20px', clear: 'both', fontSize: '8px' }}
                    dataSource={zones}
                    hoverStateEnabled
                    height="400px"
                    allowColumnReordering
                    rowAlternationEnabled
                    showColumnLines={false}
                    showRowLines={false}
                    onRowRemoved={() => {
                      this.changeWaferMapVariable({ currentZoneId: null });
                      this.setState({ selectedZoneId: null });
                    }}
                    columnMinWidth={200}
                    selectedRowKeys={[selectedZoneId]}
                    onSelectedRowKeysChange={(e: any) => {
                      if (e.length > 0) {
                        this.changeWaferMapVariable({ currentZoneId: e[0] });
                        this.setState({ selectedZoneId: e[0] });
                      }
                    }}
                  >
                    <Selection mode="single" />
                    <Scrolling mode="standard" />
                    <Column dataField="zoneName" />
                    <Column dataField="zoneType" />
                    <Column dataField="zoneApplication" width={450} />
                    <Column dataField="createdBy" />
                    <Column dataField="createdOn" />
                    <Column dataField="updatedBy" />
                    <Column dataField="updatedOn" />

                    <Editing
                      useIcons
                      allowDeleting
                      confirmDelete={false}
                      mode="row"
                    />
                  </DataGrid>
                </div>

                <div style={{ display: middleViewport === MiddleViewport.UDA ? 'block' : 'none' }}>
                  <AttributesGrid
                    needAccess={false}
                    needLevel={false}
                    needDatatype={false}
                    needUDAAccess={false}
                    needUDAConstraints={false}
                    needUDAType={false}
                    parentId="myid"
                    onDeleteAttributeDefinition={async () => {
                      if (this.plotter) {
                        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
                          actionDoMessage: 'Delete attribute',
                          actionUndoMessage: 'Delete attribute',
                        });
                      }
                    }}
                    onAddAttributeDefinition={async () => {
                      if (this.plotter) {
                        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
                          actionDoMessage: 'Add attribute',
                          actionUndoMessage: 'Add attribute',
                        });
                      }
                    }}
                    onUpdateAttributeDefinition={async () => {
                      if (this.plotter) {
                        await this.plotter.actionHandler!(ActionType.PERFORM, DEVICE_WAFER_KEY, {
                          actionDoMessage: 'Update attribute',
                          actionUndoMessage: 'Update attribute',
                        });
                      }
                    }}
                    attributes={WCMAttributeDefinitions}
                    allAttributes={allWCMAttributeMetas}
                    onDeleteAttribute={this.onDeleteAttributeMeta}
                    onAddAttribute={this.onAddAttributeMeta}
                  />
                </div>

              </Col>
              {!collapseDieSummaryColumn && rightSideBar}
            </Row>
          </Col>
        </Row>
      </Container>

    );
  }
}
export default withRouter(WaferControlMap);
