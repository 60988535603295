/* eslint-disable quotes */
const AppConstants = {
  baseURL: process.env.REACT_APP_API_URL,
  user: {
    Id: "1",
    firstName: "Sample",
    lastName: "Sample",
    designation: "_",
  },
  visualizeInfrastructure: "/visualize-infrastructure",
  isTempHidden: true,
  scwDataPointLimit: Number.MAX_SAFE_INTEGER,
  parametricBoxPlotDiesShowingLimit: 20000,
  STEP_INTAKE_RETRY_TIME_IN_MINUTES: 45,
  DATA_INTAKE_RETRY_TIME_IN_MINUTES: 45,
  numberOfGraphsPerRow: 5,
  ALLOW_REPORTS_RETRIEVAL_BATCHING: true,
};

export default AppConstants;
