import GeneralUtils from 'GeneralUtils';
import { SeriesTypeNames, } from './customized-report/customized-report-helpers/CustomizedReportInterfaces';

export interface INestedLabels {
  xAxisEnd: number | string;
  xAxisStart: number;
  xAxisText: any;
  xAxisValue: number;
  yAxisValue: number;
  xAxisTextPrefix: string;
}

class NestedGraphLabelUtility {
  private shapes: any;

  private annotations: any;

  constructor(labels: INestedLabels[], actualGraphsCount: number) {
    this.shapes = [];
    this.annotations = [];
    let indexForLabels = 0;
    const labelsPerGraph = Math.ceil(labels.length / actualGraphsCount);
    labels.forEach((label, index) => {
      indexForLabels = Math.floor(index / labelsPerGraph);
      this.shapes.push({
        key: `xAxisGroupingLabelStart${indexForLabels}`,
        type: 'line',
        x0: label.xAxisStart,
        x1: label.xAxisStart,
        xref: `x${indexForLabels === 0 ? '' : indexForLabels + 1}`,
        yref: 'paper',
        y0: label.yAxisValue,
        y1: label.yAxisValue + 0.01,
        line: {
          width: 1,
        },
      });
      if (label.xAxisStart !== label.xAxisEnd) {
        this.shapes.push({
          key: `xAxisGroupingLabelEnd${indexForLabels}`,
          type: 'line',
          x0: label.xAxisEnd,
          x1: label.xAxisEnd,
          xref: `x${indexForLabels === 0 ? '' : indexForLabels + 1}`,
          yref: 'paper',
          y0: label.yAxisValue,
          y1: label.yAxisValue + 0.01,
          line: {
            width: 1,
          },
        });
      }
      this.annotations.push(
        {
          key: 'xAxisGroupingLabelMiddleVertical',
          xref: `x${indexForLabels === 0 ? '' : indexForLabels + 1}`,
          yref: 'paper',
          xanchor: 'left',
          x: label.xAxisValue,
          y: label.yAxisValue,
          yanchor: 'top',
          text: label.xAxisTextPrefix ? `<b>${label.xAxisTextPrefix}: </b>${label.xAxisText}` : label.xAxisText,
          showarrow: false,
          // textangle: 20,
        },
      );
      if ((label.xAxisEnd as number) - label.xAxisStart > 0) {
        this.shapes.push({
          key: `xAxisGroupingLabelStartToEnd${indexForLabels}`,
          type: 'line',
          x0: label.xAxisStart,
          x1: label.xAxisEnd,
          xref: `x${indexForLabels === 0 ? '' : indexForLabels + 1}`,
          yref: 'paper',
          y0: label.yAxisValue,
          y1: label.yAxisValue,
          line: {
            // color: rangeColor,
            width: 1,
          },
        });
      }
      indexForLabels += 1;
    });
  }

  addXAxisLabels = (xAxisLabel: string, xAxisPosition: number, axisLabelCounter: number) => {
    const xValue = xAxisPosition;
    const yValue = -0.15;
    this.annotations.push({
      key: 'xAxisActualLabelMiddleVertical',
      xref: `x${axisLabelCounter === 0 ? '' : axisLabelCounter + 1}`,
      yref: 'paper',
      xanchor: 'left',
      x: xValue,
      y: yValue,
      yanchor: 'top',
      text: `<b>${xAxisLabel}</b>`,
      showarrow: false,
    });
  }

  getData = (axisLabelCounter: number, xMinOfData: number[], xMaxOfData: number[], xAxisLabel: string, seriesType: SeriesTypeNames | null, reportType: string) => {
    if (!GeneralUtils.isSingleGraph(reportType, seriesType!)) {
      for (let i = 0; i < axisLabelCounter; i += 1) {
        const mid = (xMinOfData[i] + xMaxOfData[i]) / 2;
        const div = 9 - (xAxisLabel.length / 7);
        const xAxisPosition = mid - ((xMaxOfData[i] - xMinOfData[i]) / div);
        this.addXAxisLabels(xAxisLabel, xAxisPosition, i);
      }
    }
    return {
      shapes: this.shapes,
      annotations: this.annotations,
    };
  };
}

export default NestedGraphLabelUtility;
