import React from 'react';
import DataGrid, { Column, Scrolling, Selection } from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { faInfoCircle, faSortDown, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface IMDropdownListProps {
  key: string,
  value: string
}

interface IMDropdownProps {
  displayMaxSelection?: number | undefined;
  list: IMDropdownListProps[],
  placeholder: string,
  selectedRowKeys: IMDropdownProps[],
  selectionHandler?: (e: any) => void,
  className?: string;
  onInit?: () => void;
  disabled?: boolean;
  dropDownOptions?: any;
}

class CustomizedMultipleDropdown extends React.Component<IMDropdownProps, any> {
  static defaultProps = {
    // eslint-disable-next-line no-unused-vars
    selectionHandler: (e: any) => undefined,
    className: '',
    disabled: false,
  };

  constructor(props: IMDropdownProps) {
    super(props);
    const {
      selectedRowKeys,
      onInit
    } = this.props;
    this.state = {
      selectedOptions: selectedRowKeys,
    };
    if (onInit) {
      onInit();
    }
  }

  onSelectionHandler = (e: any) => {
    const { selectionHandler } = this.props;
    if (selectionHandler) {
      this.setState({ selectedOptions: (e.selectedRowKeys.length && e.selectedRowKeys) || [] });
    }
  };

  render() {
    const { selectedOptions } = this.state;
    const dataGridRenderer = () => {
      const {
        list,
      } = this.props;
      return (
        <DataGrid
          dataSource={list}
          hoverStateEnabled
          // columns={['value']}
          showColumnLines={false}
          showRowLines={false}
          showBorders={false}
          selectedRowKeys={selectedOptions}
          onSelectionChanged={this.onSelectionHandler}
        >
          <Column caption="Options" dataField="value" />
          <Selection mode="multiple" />
          <Scrolling mode="standard" />
        </DataGrid>
      );
    };

    const {
      placeholder,
      list,
      className,
      displayMaxSelection,
      disabled,
      dropDownOptions,
    } = this.props;

    return (
      <DropDownBox
        displayValueFormatter={displayMaxSelection === undefined
          ? (values: any) => (values) : (values: any) => {
            if (list.length === values.length) {
              return ['All Selected'];
            }
            if (values.length > displayMaxSelection) {
              let outputString = '';
              for (let i = 0; i < displayMaxSelection; i += 1) {
                if (i > 0) {
                  outputString += ', ';
                }
                outputString += values[i];
              }
              return ([`${outputString} & ${values.length - displayMaxSelection} more...`] as any);
            }
            return (values as any);
          }}
        dropDownButtonRender={displayMaxSelection === undefined
          ? displayMaxSelection : () => {
            if (selectedOptions.length === 0) {
              return (
                <FontAwesomeIcon
                  className="mt-6"
                  icon={faSortDown}
                />
              );
            }
            const selectionListItems = selectedOptions.map((item: any) => (<li key={`customized-multiple-dropdown-list-item-${item.value}`}>{item.value}</li>));
            return (
              <div
                key={`customized-multiple-dropdown-${selectionListItems}`}
                className="w-100 h-100 d-flex align-items-center justify-content-center"
              >
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom"
                  overlay={(
                    <Popover id="selected-multi-select-dropdown-items-popover">
                      <Popover.Content>
                        <ul className="ribbon-view-lists">
                          {selectionListItems}
                        </ul>
                      </Popover.Content>
                    </Popover>
                  )}
                >
                  <FontAwesomeIcon
                    className="mr4 color-primary"
                    icon={faInfoCircle}
                  />
                </OverlayTrigger>
                <FontAwesomeIcon
                  className="mt-6"
                  icon={faSortDown}
                />
              </div>
            );
          }}
        className={className}
        value={selectedOptions.map((el: any) => el.key)}
        valueExpr="key"
        displayExpr="value"
        placeholder={placeholder}
        dataSource={list}
        contentRender={dataGridRenderer}
        onClosed={() => {
          const { selectionHandler } = this.props;
          if (selectionHandler) {
            selectionHandler(selectedOptions);
          }
        }}
        disabled={disabled}
        dropDownOptions={dropDownOptions}
      />
    );
  }
}

export default CustomizedMultipleDropdown;
