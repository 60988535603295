/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import {
  Button, Col, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { CheckBox, ScrollView } from 'devextreme-react';
// eslint-disable-next-line import/no-named-default
import './device-setup.scss';
import Label from 'components/wrapped-component/hint-controls/Label';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import CodeEditor from 'components/utility-component/code-editor/CodeEditor';
import { TTypeAheadValuesType } from 'interfaces';

export const LEVEL_TYPES: { [entityType: string]: string } = {
  FACILITY: 'facility',
  WORK_CENTER: 'workCenter',
  DEVICE: 'device',
  TEST_PROGRAM: 'testProgram',
  TEST_PROGRAM_REVISION: 'testProgramRevision',
  LOT: 'lot',
  WAFER: 'wafer',
  DIE: 'die',
};

type LevelPopoverProps = {
  title: string,
  getColumnInfo?: (columnInfo: any) => void,
  onSave: (levelsData: any) => void,
  levelsData: { [key: string] : any },
  masterMetaColumns: { [entityType: string]: any[] },
};

type LevelPopoverState = {
  showPopover: boolean,
  levelsData: { [key: string] : string },
};

class LevelPopover extends React.Component<LevelPopoverProps, LevelPopoverState> {
  constructor(props: LevelPopoverProps) {
    super(props);
    const { levelsData } = this.props;
    this.state = {
      levelsData: _.cloneDeep(levelsData),
      showPopover: false,
    };
  }

  showCreateColumn = () => {
    this.setState((prevState: LevelPopoverState) => ({
      showPopover: !prevState.showPopover,
    }));
  };

  onLevelCheckBoxChanged = (value: boolean, levelName: string) => {
    if (value) {
      this.setState((prevState: LevelPopoverState) => {
        const newLevelsData = _.cloneDeep(prevState.levelsData);
        newLevelsData[levelName] = '';
        return {
          levelsData: newLevelsData,
        };
      });
    } else {
      this.setState((prevState: LevelPopoverState) => {
        const newLevelsData = _.cloneDeep(prevState.levelsData);
        delete newLevelsData[levelName];
        return {
          levelsData: newLevelsData,
        };
      });
    }
  };

  onLevelValueChanged = (value: any, levelName: string) => {
    this.setState((prevState: LevelPopoverState) => {
      const newLevelsData = _.cloneDeep(prevState.levelsData);
      newLevelsData[levelName] = value;
      return {
        levelsData: newLevelsData,
      };
    });
  };

  getEntityRegExps = (entityName: string, entityNameInUpperCase: string) => {
    const { masterMetaColumns } = this.props;
    const regexExpressions: any = [
      {
        regex: /^$/,
        values: {
          type: ('list' as TTypeAheadValuesType),
          values: [entityName],
        },
      },
    ];
    regexExpressions.push({
      regex: new RegExp(`${entityName}$`),
      values: {
        type: ('list' as TTypeAheadValuesType),
        values: ['.'],
      },
    });

    const entityColumnsOrRegex = masterMetaColumns[entityNameInUpperCase].map((x) => { return `${_.camelCase(x.columnName)}$`; }).join('|');
    regexExpressions.push({
      regex: new RegExp(`${entityName}\\.${entityColumnsOrRegex}$`),
      values: {
        type: ('list' as TTypeAheadValuesType),
        values: ['.'],
      },
    });
    regexExpressions.push({
      regex: new RegExp(`${entityName}\\.[A-Za-z0-9]+\\.$`),
      values: {
        type: ('list' as TTypeAheadValuesType),
        values: ['StartsWith("str")', 'Contains("str")', 'EndsWith("str")'],
      },
    });
    regexExpressions.push({
      regex: new RegExp(`${entityName}\\.$`),
      values: {
        type: ('list' as TTypeAheadValuesType),
        values: masterMetaColumns[entityNameInUpperCase].map((x) => { return x.columnName; }),
      },
    });

    regexExpressions.push({
      regex: /&& $|\|\| $/i,
      values: {
        type: 'list',
        values: [entityName],
      },
    });
    regexExpressions.push({
      regex: /[A-Za-z0-9'")]+\s$/i,
      values: {
        type: 'groupedList',
        values: [
          { key: 'Comparison Operators', items: ['!=', '==', '>', '<', '>=', '<='] },
          { key: 'Conditional Operations', items: ['&&', '||'] },
        ],
      },
    });

    return regexExpressions;
  };

  render() {
    const { showPopover, levelsData } = this.state;
    const { title, onSave } = this.props;
    const createColumn:any = (
      <Popover id="popover-basic" className="w300p create-columns" show={showPopover} tabIndex={-1}>
        <Popover.Title as="h3" className="pr0">
          {title}
          <Button variant="outline float-right p0 pl5 pr10 text-danger dx-icon-close" onClick={this.showCreateColumn}><i /></Button>
        </Popover.Title>
        <Popover.Content>
          <ScrollView
            height="55vh"
            showScrollbar="onHover"
            scrollByThumb
            reachBottomText=""
          >
            <div className="h600 noSelectionBG custom-form">

              {Object.keys(LEVEL_TYPES).map((levelType: string) => {
                return (

                  <Col style={{ display: 'inline-flex' }} className="mb10">
                    <CheckBox
                      className="mt10 mr20"
                      value={levelType in levelsData}
                      onValueChange={(value: boolean) => this.onLevelCheckBoxChanged(value, levelType)}
                    />
                    <Label
                      labelTitle={levelType}
                      labelPosition="left-middle"
                      labelSize="100"
                      fieldSize="100"
                      errorSize="100"
                      isFieldTouched
                    >
                      <CodeEditor
                        value={levelsData[levelType]}
                        defaultValue={levelsData[levelType]}
                        disabled={!(levelType in levelsData)}
                        editorClassName="w400 p6 mt-3"
                        rows={1}
                        placeHolder={`${LEVEL_TYPES[levelType]}.AttributeName == "abc"`}
                        matchingCriterion={(this.getEntityRegExps(LEVEL_TYPES[levelType], levelType))}
                        updateOnChange={(value: any) => {
                          this.onLevelValueChanged(value, levelType);
                        }}
                      />
                    </Label>
                  </Col>
                );
              })}
              <Button
                variant="success"
                size="sm"
                className="mt5"
                onClick={() => {
                  onSave(levelsData);
                  this.setState({
                    showPopover: false,
                  });
                }}
              >
                <FontAwesomeIcon size="sm" icon={faSave} className="mr6" />
                Save
              </Button>
            </div>

          </ScrollView>
        </Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger rootClose trigger="focus" show={showPopover} placement="auto" overlay={createColumn}>
        <div
          role="button"
          className="p5"
          onClick={this.showCreateColumn}
          style={{
            cursor: 'pointer',
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            color: 'blue',
          }}
        >
          {Object.keys(levelsData).length === 0 ? 'Select Level Types...' : Object.entries(levelsData).map(([key]: any) => {
            return <span key={key}>{key.replace(/_/g, ' ').toLowerCase()}</span>;
          })}
        </div>
      </OverlayTrigger>
    );
  }
}
export default LevelPopover;
