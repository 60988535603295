// eslint-disable-next-line no-unused-vars
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import React from 'react';
import { Button, Col, Row, } from 'react-bootstrap';

type PCMWATSiteMapSideBarProps = {
  pcmWatTestMethod: string,
  watProbingSequence: string,
  pcmWatSiteNumber: number | null,
  maxPcmWatSiteNumber: number,
  onChangePcmWatSiteNumber: (value: string) => void,
  onChangePcmWatTestMethod: (pcmWatTestMethod: string) => void,
  onChangeWatProbingSequence: (watProbingSeqeuence: string) => void,
  onAddPcmWatSite: () => void,
  onApplyFullWatMap: () => void,
};

type PCMWATSiteMapSideBarState = {

};

export const WAT_TEST_METHODS = [['full', 'Full WAT'], ['sampling', 'Sampling']];

export const WAT_PROBING_SEQUENCE = [['top_left_z_pattern', 'Top Left, Z-Pattern']];

export default class PCMWATSiteMapSideBar extends React.Component<PCMWATSiteMapSideBarProps, PCMWATSiteMapSideBarState> {
  constructor(props: PCMWATSiteMapSideBarProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      pcmWatTestMethod, onChangePcmWatTestMethod, pcmWatSiteNumber, onChangePcmWatSiteNumber, maxPcmWatSiteNumber,
      onAddPcmWatSite, watProbingSequence, onChangeWatProbingSequence, onApplyFullWatMap,
    } = this.props;

    const pcmWatTestSiteNumbersList: string[][] = [['null', 'Un-assign']];
    for (let i = 1; i <= maxPcmWatSiteNumber; i += 1) {
      pcmWatTestSiteNumbersList.push([i.toString(), i.toString()]);
    }

    return (
      <Col lg={2} style={{ border: '1px solid #ddd' }}>
        <h5 className="mt20 text-center no-selection-color">Define PCM / WAT Site</h5>
        <hr />
        <Row className="mb10">
          <Col className="custom-form" lg={12}>
            <Label
              labelTitle="WAT Test Method"
              labelPosition="top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <CustomizedDropdown
                full
                variant="clear"
                list={WAT_TEST_METHODS}
                selectedValue={pcmWatTestMethod}
                onChange={(value: string) => { onChangePcmWatTestMethod(value); }}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          { pcmWatTestMethod === 'sampling'
          && (
            <>
              <Col className="custom-form" lg={9}>
                <Label
                  labelTitle="WAT/PCM Site #"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  isFieldTouched
                  className="mb5"
                >
                  <CustomizedDropdown
                    full
                    variant="clear"
                    list={pcmWatTestSiteNumbersList}
                    selectedValue={pcmWatSiteNumber === null ? 'null' : pcmWatSiteNumber.toString()}
                    onChange={onChangePcmWatSiteNumber}
                  />
                </Label>
              </Col>
              <Col lg={3}>
                <Button
                  onClick={() => { onAddPcmWatSite(); }}
                  variant="clear"
                  size="sm"
                  className="btn-config mt40"
                >
                  <i className="dx-icon-plus" />
                </Button>
              </Col>
            </>
          )}
          { pcmWatTestMethod === 'full'
          && (
            <Col className="custom-form" lg={12}>
              <Label
                labelTitle="WAT Probing Sequence"
                labelPosition="top"
                labelSize="100"
                fieldSize="100"
                errorSize="100"
                isFieldTouched
                className="mb5"
              >
                <CustomizedDropdown
                  full
                  variant="clear"
                  list={WAT_PROBING_SEQUENCE}
                  selectedValue={watProbingSequence}
                  onChange={onChangeWatProbingSequence}
                />
                <Button
                  onClick={() => { onApplyFullWatMap(); }}
                  variant="primary"
                  size="sm"
                  className="mt30"
                >
                  Apply
                </Button>
              </Label>
            </Col>
          )}
        </Row>

        <hr />
      </Col>

    );
  }
}
