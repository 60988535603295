/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { Button } from "react-bootstrap";
import { addErrorClasses } from "components/template-component/form-error-label/ErrorLabel";
import { Formik } from "formik";
import Textbox from "components/wrapped-component/hint-controls/Textbox";
import Label from "components/wrapped-component/hint-controls/Label";
import * as Yup from "yup";
// eslint-disable-next-line import/no-cycle
import Dashboard from "layouts/dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import ReactDOM from "react-dom";
import { IUserDetails } from "interfaces";
import GeneralUtils from "GeneralUtils";
import userDataUtil from "services/user-data-util";
import toast from "CustomToast";
import { httpIAM } from "../../services/http.IAM";
import login from "../../assets/images/login.jpg";

const formValidationSchema = Yup.object({
  UserName: Yup.string().required("Required").max(40),
  Password: Yup.string().required("Required").max(100),
});

const Login = (props: any) => {
  const { setloading } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        UserName: "",
        Password: "",
      }}
      validationSchema={formValidationSchema}
      onSubmit={(values: any) => {
        setloading(true);
        httpIAM
          .login(values.UserName, values.Password)
          .then((authToken: any) => {
            if (authToken !== null) {
              GeneralUtils.appendCookie("INIT_USER_STATS", "");
              const d1: any = new Date();
              const d2: any = new Date(d1);
              d2.setMinutes(d1.getMinutes() + 2);

              GeneralUtils.appendCookie("AUTH_TOKEN", authToken);
              httpIAM
                .getUserDetails(values.UserName)
                .then((user: IUserDetails) => {
                  GeneralUtils.appendCookie(
                    "USER_DETAILS",
                    JSON.stringify(user)
                  );
                  const previousDatabase =
                    GeneralUtils.getCookieValue("DATABASE");
                  if (
                    user !== undefined &&
                    user.databases?.length > 0 &&
                    (previousDatabase === "" ||
                      user.databases.find((x) => x.id === previousDatabase) ===
                        undefined)
                  ) {
                    GeneralUtils.appendCookie("DATABASE", user.databases[0].id);
                  }
                  userDataUtil.initializeUserStats();
                  ReactDOM.render(
                    <Router>
                      <Switch>
                        <Route exact path="" component={Dashboard} />
                      </Switch>
                    </Router>,
                    document.getElementById("root")
                  );
                });
              GeneralUtils.clearGlobalVars();
            }
          })
          .finally(() => {
            setloading(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <form
          onSubmit={handleSubmit}
          onChange={handleChange}
          className="custom-form login-align-center"
        >
          <div
            className="w-100 height-100vh"
            style={{
              backgroundImage: `url(${login})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="position-absolute w-100">
              <div className="m-auto mt50 w500 background-color-light p30 rounded shadow-lg">
                <h3 className="mb30 text-center">Login to your account</h3>
                <Label
                  labelTitle="User Name"
                  labelPosition="top"
                  labelSize="10"
                  fieldSize="30"
                  errorSize="20"
                  error={errors.UserName}
                  isFieldTouched={touched.Password}
                  required
                  className="mb10"
                >
                  <Textbox
                    value={values.UserName}
                    autoComplete="off"
                    name="UserName"
                    placeholder="User Name"
                    className={`${addErrorClasses(
                      errors.UserName,
                      touched.UserName
                    )} border`}
                  />
                </Label>
                <Label
                  labelTitle="Password"
                  labelPosition="top"
                  labelSize="10"
                  fieldSize="30"
                  errorSize="20"
                  error={errors.Password}
                  isFieldTouched={touched.Password}
                  required
                >
                  <Textbox
                    type="password"
                    value={values.Password}
                    autoComplete="off"
                    name="Password"
                    placeholder="Password"
                    className={`${addErrorClasses(
                      errors.Password,
                      touched.Password
                    )} border`}
                  />
                </Label>
                <Button
                  block
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="mr10 mb20 mt20"
                >
                  Login
                </Button>
                <div className="text-center">
                  Unable to Login?
                  <a
                    href="https://www.google.com"
                    target="blank"
                    className="ml4"
                  >
                    Contact Us.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Login;
