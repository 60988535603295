import React, { Component } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Scrolling, Selection } from 'devextreme-react/data-grid';
import { Button } from 'react-bootstrap';
import getDropDownOptions from 'components/getDropDownOptions';

interface IGridDropdownProps {
  list: any[],
  placeholder: string,
  selectedRowKeys: any[],
  selectionHandler?: (e: any) => void,
  className?: string;
  columns: string[];
  selectionMode: 'single' | 'multiple';
  displayExpr: string;
  valueExpr: string;
  disabled?: boolean;
  showApplyButton?: boolean;
}

interface IGridDropdownState {
  selectedOptions: any
}

class CustomizedGridDropDown extends Component<IGridDropdownProps, IGridDropdownState> {
  dropDownRef: any;

  constructor(props: IGridDropdownProps) {
    super(props);
    const { selectedRowKeys } = this.props;
    this.state = {
      selectedOptions: selectedRowKeys,
    };
    this.dropDownRef = React.createRef();
  }

  onSelectionHandler = (e: any) => {
    const { selectionHandler } = this.props;
    if (selectionHandler) {
      this.setState({ selectedOptions: (e.selectedRowKeys.length && e.selectedRowKeys) || [] });
    }
  };

  sendToParent = () => {
    const { selectionHandler } = this.props;
    const { selectedOptions } = this.state;
    if (selectionHandler) {
      selectionHandler(selectedOptions);
      this.dropDownRef.current.instance.close();
    }
  };

  dataGridRenderer = () => {
    const { selectedOptions } = this.state;
    const {
      list,
      columns,
      selectionMode,
      showApplyButton,
    } = this.props;
    if (showApplyButton) {
      return (
        <>
          <div flex-container>
            <DataGrid
              dataSource={list}
              hoverStateEnabled
              columns={columns}
              showColumnLines={false}
              showRowLines={false}
              showBorders={false}
              onSelectionChanged={this.onSelectionHandler}
              selectedRowKeys={selectedOptions}
            >
              <Selection mode={selectionMode} />
              <Scrolling mode="standard" />
            </DataGrid>
          </div>
          <div>
            <div>
              <Button variant="primary float-right mt10" onClick={this.sendToParent}>Apply</Button>
            </div>
          </div>
        </>
      );
    }
    return (
      <DataGrid
        dataSource={list}
        hoverStateEnabled
        columns={columns}
        showColumnLines={false}
        showRowLines={false}
        showBorders={false}
        onSelectionChanged={this.onSelectionHandler}
        selectedRowKeys={selectedOptions}
      >
        <Selection mode={selectionMode} />
        <Scrolling mode="standard" />
      </DataGrid>
    );
  };

  render() {
    const {
      placeholder,
      list,
      className,
      valueExpr,
      displayExpr,
      selectionHandler,
      disabled,
    } = this.props;
    const { selectedOptions } = this.state;
    return (
      <DropDownBox
        ref={this.dropDownRef}
        displayValueFormatter={(data: any) => {
          return data.map((x: any) => {
            return x[displayExpr];
          })
            .join(',');
        }}
        className={className}
        value={selectedOptions[0]}
        valueExpr={valueExpr}
        deferRendering={false}
        displayExpr={displayExpr}
        placeholder={placeholder}
        disabled={disabled === undefined ? false : disabled}
        dataSource={list}
        contentRender={this.dataGridRenderer}
        onClosed={() => {
          if (selectionHandler) {
            selectionHandler(selectedOptions);
          }
        }}
        dropDownOptions={getDropDownOptions('div')}
      />
    );
  }
}

export default CustomizedGridDropDown;
