import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface IContextSavingSpinnerProps {
  mode: 'REPORT' | 'POWER_VIEW';
  title: string;
}

interface IContextSavingSpinnerState {
  stepIndex: number;
}
class ContextSavingSpinner extends React.Component<IContextSavingSpinnerProps, IContextSavingSpinnerState> {
  private contextSavingSteps : string[] = [];

  private intervalSize : number;

  private timeout: any;

  constructor(props:any) {
    super(props);
    const { mode } = this.props;
    if (mode === 'POWER_VIEW') {
      this.contextSavingSteps = [
        'Resolving Endpoints',
        'Praparing For Data Retrieval',
        'Sorting Out Dependencies',
        'Preparing Data Query',
        'Finding Relevant Data',
        'Querying Database',
        'Fetching Bulk Data',
        'Copying Data for Retrieval',
        'Saving Data In Context',
        'Finishing up...',
      ];
      this.intervalSize = 1000;
    } else {
      this.contextSavingSteps = [
        'Praparing For Data Retrieval',
        'Fetching Bulk Data',
        'Applying Default Groupings',
        'Applying Default Sortings',
        'Applying Aggregations',
        'Building Visualizations',
        'Filling Axes',
        'Plotting Data Points',
        'Finishing up...',
      ];
      this.intervalSize = 1000;
    }
    this.state = {
      stepIndex: 0,
    };
  }

  componentDidMount() {
    this.updateStep();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  updateStep = () => {
    const { stepIndex } = this.state;
    this.setState({ stepIndex: (stepIndex + 1) % this.contextSavingSteps.length }, () => {
      if (stepIndex !== this.contextSavingSteps.length - 2) {
        this.timeout = setTimeout(() => {
          this.updateStep();
        }, this.intervalSize);
      }
    });
  };

  render() {
    const { stepIndex } = this.state;
    const { title, mode } = this.props;
    return (
      <div>
        <h3>{title}</h3>
        <div className="d-flex align-items-center justify-content-between w-100">
          <h6 className="mb0 w-50">
            {this.contextSavingSteps[stepIndex]}
          </h6>
          <div className="w-50">
            <ProgressBar
              animated
              variant={mode === 'POWER_VIEW' ? 'secondary' : 'success'}
              now={(stepIndex / this.contextSavingSteps.length) * 100}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ContextSavingSpinner;
