import GeneralUtils from "./GeneralUtils";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface interfacesForThisComponent {
  hideDashboardBasedOnParams: (e: string) => boolean;
  // rotatingToken : (e:string) =>
}

// eslint-disable-next-line operator-linebreak
const hideDashboardBasedOnParams: interfacesForThisComponent["hideDashboardBasedOnParams"] =
  (search) => {
    const searchParams = new URLSearchParams(search);
    const isDashboardParam = searchParams.get("isDashboard")?.toLowerCase();

    // It will hide the Top_bar and Dashboard URL Example is below
    //  http://localhost:3000/bin-plus-tables?isDashboard=false

    return !(isDashboardParam === "false");
  };

const rotatingToken = (token: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  token !== null && GeneralUtils.changeUserDataCookie("AUTH_TOKEN", token);
};
const commonFunctions = {
  hideDashboardBasedOnParams,
  rotatingToken,
};

export default commonFunctions;
