import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import { IActorDetails } from '../CustomizedReportsCollection';
import { IGraphDataHelperPreferences, ILegendDataHelperPreferences, } from './CustomizedReportHelper';
import { BinParetoDataHelper } from './graph-helpers/BinParetoGraphHelper';
import { ParametricTrendGraphHelper } from './graph-helpers/ParametricTrendGraphHelper';
import { ParametricWaferMapGraphHelper } from './graph-helpers/ParametricWaferMapGraphHelper';
import { BinParetoLegendDataHelper } from './legend-helpers/BinParetoLegendHelper';
import { ParametricTrendLegendDataHelper } from './legend-helpers/ParametricTrendLegendHelper';
import { ParametricWaferMapLegendDataHelper } from './legend-helpers/ParametricWaferMapLegendHelper';
import { BinWaferMapDataHelper } from './graph-helpers/BinWaferMapGraphHelper';
import { BinWaferMapLegendDataHelper } from './legend-helpers/BinWaferMapLegendHelper';
import { SPCTrendLegendHelper } from './legend-helpers/SPCTrendLegendHelper';
import { ParametricBoxPlotGraphHelper } from './graph-helpers/ParametricBoxPlotGraphHelper';
import { ParametricBoxPlotLegendDataHelper } from './legend-helpers/ParametricBoxPlotLegendHelper';
import { ParametricXYScatterPlotGraphHelper } from './graph-helpers/ParametricXYScatterPlotGraphHelper';
import { ParametricXYScatterPlotLegendHelper } from './legend-helpers/ParametricXYScatterPlotLegendHelper';
import { ParametricHistogramGraphHelper } from './graph-helpers/ParametricHistogramGraphHelper';
import { SPCTrendGraphHelper } from './graph-helpers/SPCTrendGraphHelper';
import { ParametricFailureGraphHelper } from './graph-helpers/ParametricFailureGraphHelper';
import { ParametricFailureLegendHelper } from './legend-helpers/ParametricFailureLegendHelper';
import { BinWaferMapComparisonGraphHelper } from './graph-helpers/BinWaferMapComparisonGraphHelper';
import { BinWaferMapComparisonLegendDataHelper } from './legend-helpers/BinWaferMapComparisonLegendHelper';

export type REPORT_TYPE = 'PARAMETER_BASED' | 'BIN_BASED';

export const GraphAndLegendDataHelpersFactory = (type: string) => {
  switch (type) {
    case ReportType.PARAMETRIC_HISTOGRAM:
      return new ParametricHistogramGraphHelper();
    case ReportType.PARAMETRIC_FAILURE:
      return new ParametricFailureGraphHelper();
    case ReportType.PARAMETRIC_FAILURE_LEGEND:
      return new ParametricFailureLegendHelper();
    case ReportType.BIN_PARETO:
      return new BinParetoDataHelper();
    case ReportType.PARAMETRIC_TREND:
      return new ParametricTrendGraphHelper();
    case ReportType.PARAMETRIC_WAFER_MAP:
      return new ParametricWaferMapGraphHelper();
    case ReportType.BIN_PARETO_LEGEND:
      return new BinParetoLegendDataHelper();
    case ReportType.PARAMETRIC_TREND_LEGEND:
      return new ParametricTrendLegendDataHelper();
    case ReportType.PARAMETRIC_WAFER_MAP_LEGEND:
      return new ParametricWaferMapLegendDataHelper();
    case ReportType.PARAMETRIC_BOX_PLOT:
      return new ParametricBoxPlotGraphHelper();
    case ReportType.PARAMETRIC_BOX_PLOT_LEGEND:
      return new ParametricBoxPlotLegendDataHelper();
    case ReportType.BIN_WAFER_MAP:
      return new BinWaferMapDataHelper();
    case ReportType.BIN_WAFER_MAP_LEGEND:
      return new BinWaferMapLegendDataHelper();
    case ReportType.BIN_WAFER_MAP_COMPARISON:
      return new BinWaferMapComparisonGraphHelper();
    case ReportType.BIN_WAFER_MAP_COMPARISON_LEGEND:
      return new BinWaferMapComparisonLegendDataHelper();
    case ReportType.PARAMETRIC_XY_SCATTER_PLOT:
      return new ParametricXYScatterPlotGraphHelper();
    case ReportType.PARAMETRIC_XY_SCATTER_PLOT_LEGEND:
      return new ParametricXYScatterPlotLegendHelper();
    case ReportType.PARAMETRIC_HISTOGRAM_LEGEND:
      return new ParametricWaferMapLegendDataHelper(); // this is intentional as the legends for parametric wafer map and parametric histogram are sam;
    case ReportType.SPC_TREND:
      return new SPCTrendGraphHelper();
    case ReportType.SPC_TREND_LEGEND:
      return new SPCTrendLegendHelper();
    case 'DATA_SUMMARY':
      return undefined;
    case 'RAW_DATA_GRID':
      return undefined;
    case 'REPORT_HEADER_CHOOSER':
      return undefined;
    case 'GENERAL_ACTION':
      return undefined;
    default:
      return undefined;
      break;
  }
};

export const GetGraphAndLegendPreferences: { [key in any]: IGraphDataHelperPreferences | ILegendDataHelperPreferences | undefined } = {
  PARAMETRIC_HISTOGRAM: (new ParametricHistogramGraphHelper()).preferences,
  PARAMETRIC_FAILURE: (new ParametricFailureGraphHelper()).preferences,
  PARAMETRIC_FAILURE_LEGEND: (new ParametricFailureLegendHelper()).preferences,
  BIN_PARETO: (new BinParetoDataHelper()).preferences,
  PARAMETRIC_TREND: (new ParametricTrendGraphHelper()).preferences,
  PARAMETRIC_WAFER_MAP: (new ParametricWaferMapGraphHelper()).preferences,
  BIN_PARETO_LEGEND: (new BinParetoLegendDataHelper()).preferences,
  PARAMETRIC_TREND_LEGEND: (new ParametricTrendLegendDataHelper()).preferences,
  PARAMETRIC_WAFER_MAP_LEGEND: (new ParametricWaferMapLegendDataHelper()).preferences,
  PARAMETRIC_BOX_PLOT: (new ParametricBoxPlotGraphHelper()).preferences,
  PARAMETRIC_BOX_PLOT_LEGEND: (new ParametricBoxPlotLegendDataHelper()).preferences,
  BIN_WAFER_MAP: (new BinWaferMapDataHelper()).preferences,
  BIN_WAFER_MAP_LEGEND: (new BinWaferMapLegendDataHelper()).preferences,
  BIN_WAFER_MAP_COMPARISON: (new BinWaferMapComparisonGraphHelper()).preferences,
  BIN_WAFER_MAP_COMPARISON_LEGEND: (new BinWaferMapComparisonLegendDataHelper()).preferences,
  PARAMETRIC_XY_SCATTER_PLOT: (new ParametricXYScatterPlotGraphHelper()).preferences,
  PARAMETRIC_XY_SCATTER_PLOT_LEGEND: (new ParametricXYScatterPlotLegendHelper()).preferences,
  PARAMETRIC_HISTOGRAM_LEGEND: (new ParametricWaferMapLegendDataHelper()).preferences, // this is intentional as the legends for parametric wafer map and parametric histogram are same
  SPC_TREND: (new SPCTrendGraphHelper()).preferences,
  SPC_TREND_LEGEND: (new SPCTrendLegendHelper()).preferences,
  DATA_SUMMARY: undefined,
  RAW_DATA_GRID: undefined,
  REPORT_HEADER_CHOOSER: undefined,
  GENERAL_ACTION: undefined,
  POWER_VIEW_PROGRESS_BAR: undefined,
};

interface ReportCollectionPreferences {
  showReportForIndividualTestParameter: boolean;
  useAggregateFunctions: boolean;
  testParameterSelectionMandatory: boolean;
  loadingPriority: number;
  loadingWeight: number;
  isBinGroupingEnforced?: boolean;
}

export const getPerformanceIntensiveness = (loadingWeight: number) => {
  let category = 'Small';
  let badgeColor = 'background-color-success';
  if (loadingWeight > 3 && loadingWeight <= 6) {
    badgeColor = 'background-color-warning';
    category = 'Medium';
  } else if (loadingWeight > 6) {
    badgeColor = 'background-color-danger';
    category = 'High';
  }

  return { category, badgeColor };
};

export const ReportCollectionPreferences: { [key in any]: ReportCollectionPreferences | undefined } = {
  PARAMETRIC_HISTOGRAM: {
    loadingPriority: 0.9,
    loadingWeight: 1,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  BIN_PARETO: {
    loadingPriority: 1,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  PARAMETRIC_TREND: {
    loadingPriority: 3,
    loadingWeight: 0.5,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  PARAMETRIC_WAFER_MAP: {
    loadingPriority: 2,
    loadingWeight: 0.5,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  BIN_WAFER_MAP: {
    loadingPriority: 4,
    loadingWeight: 0.75,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  BIN_WAFER_MAP_COMPARISON: {
    loadingPriority: 0.8,
    loadingWeight: 0.75,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  PARAMETRIC_FAILURE: {
    loadingPriority: 3,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
  },
  PARAMETRIC_BOX_PLOT: {
    loadingPriority: 5,
    loadingWeight: 0.5,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: true,
  },
  SPC_TREND: {
    loadingPriority: 3,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: true,
  },
  PARAMETRIC_XY_SCATTER_PLOT: {
    loadingPriority: 5,
    loadingWeight: 0.75,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },

  PARAMETRIC_HISTOGRAM_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  BIN_PARETO_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  PARAMETRIC_BOX_PLOT_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: true,
  },
  PARAMETRIC_TREND_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  PARAMETRIC_WAFER_MAP_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: true,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: true,
  },
  BIN_WAFER_MAP_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  BIN_WAFER_MAP_COMPARISON_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
    isBinGroupingEnforced: true,
  },
  PARAMETRIC_FAILURE_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: false,
  },
  PARAMETRIC_XY_SCATTER_PLOT_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: false,
  },
  DATA_SUMMARY: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: true,
    testParameterSelectionMandatory: false,
  },
  SPC_TREND_LEGEND: {
    loadingPriority: 10,
    loadingWeight: 1,
    showReportForIndividualTestParameter: false,
    useAggregateFunctions: false,
    testParameterSelectionMandatory: true,
  },
  RAW_DATA_GRID: undefined,
  REPORT_HEADER_CHOOSER: undefined,
  GENERAL_ACTION: undefined,
  POWER_VIEW_PROGRESS_BAR: undefined,
};

export type ReportMappingType = 'PARAMETRIC' | 'BIN' | 'MISC' | 'BIN+WAFER' | 'PARAMETRIC+WAFER';

export const ReportCollectionMapping: { [key in any]: {
  type?: ReportMappingType;
  baseType: 'PLOTLY' | 'WAFER';
  report: IActorDetails;
  legend: IActorDetails;
} | undefined } = {
  PARAMETRIC_HISTOGRAM: {
    type: 'PARAMETRIC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.PARAMETRIC_HISTOGRAM,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_HISTOGRAM_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_HISTOGRAM_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_HISTOGRAM_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_HISTOGRAM_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_HISTOGRAM_LEGEND_SELECTION',
    },
  },
  BIN_PARETO: {
    type: 'BIN',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.BIN_PARETO,
      hidingEventPublisher: 'INDIVIDUAL_BIN_HISTOGRAM_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_HISTOGRAM_SELECTION',
    },
    legend: {
      actor: ReportType.BIN_PARETO_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_BIN_HISTOGRAM_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_HISTOGRAM_LEGEND_SELECTION',
    },
  },
  BIN_WAFER_MAP: {
    type: 'BIN',
    baseType: 'WAFER',
    report: {
      actor: ReportType.BIN_WAFER_MAP,
      hidingEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_SELECTION',
    },
    legend: {
      actor: ReportType.BIN_WAFER_MAP_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_LEGEND_SELECTION',
    },
  },
  BIN_WAFER_MAP_COMPARISON: {
    type: 'BIN',
    baseType: 'WAFER',
    report: {
      actor: ReportType.BIN_WAFER_MAP_COMPARISON,
      hidingEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_COMPARISON_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_COMPARISON_SELECTION',
    },
    legend: {
      actor: ReportType.BIN_WAFER_MAP_COMPARISON_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_COMPARISON_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_BIN_WAFER_MAP_COMPARISON_LEGEND_SELECTION',
    },
  },
  PARAMETRIC_FAILURE: {
    type: 'PARAMETRIC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.PARAMETRIC_FAILURE,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_FAILURE_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_FAILURE_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_FAILURE_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_FAILURE_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_FAILURE_LEGEND_SELECTION',
    },
  },
  PARAMETRIC_TREND: {
    type: 'PARAMETRIC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.PARAMETRIC_TREND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_TREND_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_LEGEND_SELECTION',
    },
  },
  PARAMETRIC_WAFER_MAP: {
    type: 'PARAMETRIC',
    baseType: 'WAFER',
    report: {
      actor: ReportType.PARAMETRIC_WAFER_MAP,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_WAFER_MAP_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_WAFER_MAP_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_WAFER_MAP_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_WAFER_MAP_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_WAFER_MAP_LEGEND_SELECTION',
    },
  },
  PARAMETRIC_BOX_PLOT: {
    type: 'PARAMETRIC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.PARAMETRIC_BOX_PLOT,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_BOX_PLOT_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_BOX_PLOT_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_BOX_PLOT_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_BOX_PLOT_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_BOX_PLOT_LEGEND_SELECTION',
    },
  },
  SPC_TREND: {
    type: 'MISC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.SPC_TREND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_SELECTION',
    },
    legend: {
      actor: ReportType.SPC_TREND_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_TREND_LEGEND_SELECTION',
    },
  },
  PARAMETRIC_XY_SCATTER_PLOT: {
    type: 'PARAMETRIC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.PARAMETRIC_XY_SCATTER_PLOT,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_XY_SCATTER_PLOT_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_LEGEND_SELECTION',
    },
  },
  DATA_SUMMARY: {
    type: 'MISC',
    baseType: 'PLOTLY',
    report: {
      actor: ReportType.DATA_SUMMARY,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_SELECTION',
    },
    legend: {
      actor: ReportType.PARAMETRIC_XY_SCATTER_PLOT_LEGEND,
      hidingEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_LEGEND_HIDING',
      selectionEventPublisher: 'INDIVIDUAL_PARAMETRIC_XY_SCATTER_PLOT_LEGEND_SELECTION',
    },
  },
  RAW_DATA_GRID: undefined,
  BIN_PARETO_LEGEND: undefined,
  PARAMETRIC_TREND_LEGEND: undefined,
  BIN_WAFER_MAP_LEGEND: undefined,
  BIN_WAFER_MAP_COMPARISON_LEGEND: undefined,
  PARAMETRIC_WAFER_MAP_LEGEND: undefined,
  PARAMETRIC_BOX_PLOT_LEGEND: undefined,
  PARAMETRIC_XY_SCATTER_PLOT_LEGEND: undefined,
  REPORT_HEADER_CHOOSER: undefined,
  GENERAL_ACTION: undefined,
  PARAMETRIC_HISTOGRAM_LEGEND: undefined,
  PARAMETRIC_FAILURE_LEGEND: undefined,
  POWER_VIEW_PROGRESS_BAR: undefined,
  SPC_TREND_LEGEND: undefined,
};
