import { DataGrid, DropDownBox } from 'devextreme-react';
import React from 'react';

type ConnectWafersDropdownProps = {
  syncConnectWafersDropDownDataGridSelection: (e: any) => void,
  connectWafersDataGridOnSelectionChanged: (e: any) => void,
  connectedWaferKeys: string[],
  waferList: { waferKey: string, name: string }[],
};

export const ConnectWafersDropdown = (props: ConnectWafersDropdownProps) => {
  const {
    syncConnectWafersDropDownDataGridSelection, connectWafersDataGridOnSelectionChanged, connectedWaferKeys, waferList,
  } = props;
  return (
    <div className="text-center">
      <strong style={{ fontWeight: 'bolder' }} className="text">Connect Wafers: </strong>
      <DropDownBox
        value={connectedWaferKeys}
        valueExpr="waferKey"
        deferRendering={false}
        displayExpr="name"
        dropDownOptions={{ width: 'auto' }}
        placeholder="Select a value..."
        dataSource={waferList}
        onValueChanged={(e: any) => syncConnectWafersDropDownDataGridSelection(e.value)}
        contentRender={() => {
          return (
            <DataGrid
              dataSource={waferList}
              columns={['name']}
              keyExpr="waferKey"
              width={200}
              hoverStateEnabled
              selectedRowKeys={connectedWaferKeys}
              onSelectionChanged={connectWafersDataGridOnSelectionChanged}
              selection={{ mode: 'multiple' }}
              filterRow={{ visible: true }}
            />
          );
        }}
      />
    </div>
  );
};
