import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './styles/main.scss';
import Dashboard from './layouts/dashboard/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import GeneralUtils from 'GeneralUtils';

GeneralUtils.clearGlobalVars();

ReactDOM.render(
  <Router>
    <Dashboard />
  </Router>,
  document.getElementById('root'),
);
