/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prefer-stateless-function */
import React, { createRef } from 'react';
import {
  Row, Col, Button, Container, Spinner,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { CheckBox, DropDownBox, List, } from 'devextreme-react';
import {
  faPlus, faMinus, faTrash, faSync, faCheck,
} from '@fortawesome/free-solid-svg-icons';
import ColorBox from 'devextreme-react/color-box';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import TooltipWrapper from 'components/wrapped-component/hint-controls/tooltip-wrapper';
import TextBox from 'components/wrapped-component/hint-controls/Textbox';
import { TreeView } from 'devextreme-react/tree-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'CustomToast';
import _ from 'lodash';
import { httpLimitSettings } from 'services/http.limit-settings';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import { GroupingSortingDTO, IGenericDetailedReportRequestObject } from 'interfaces.js';
import getDropDownOptions from 'components/getDropDownOptions';
import limitSettingsData from './LimitSettingsData.js';
import PATLimitsTable from '../../policy/policy-step/PAT/pat-limits-report-options/PATLimitsTable';

export interface ILimitsValues {
  rawDataSpecLow: number;
  rawDataSpecHigh: number;
  rawDataControlLow: number;
  rawDataControlHigh: number;
  tukeyLow: number;
  tukeyHigh: number;
  outlierSigmas: number[];
  distributionSigmas: number[];
  unitOfMeasurement: string;
  patLimits: number[];
}

export interface ILimitSettingsObj {
  id: string;
  outlier: any[];
  distribution: any[];
  limitSettingsName: string;
  private: boolean;
}

interface ILimitSettingsModalState {
  id: any;
  name: any;
  limitOptions: any;
  differentUOM: any;
  outlierMultiselectInfo: any;
  distributionMultiselectInfo: any;
  currentOutlierMultiselectLow: any;
  currentOutlierMultiselectHigh: any;
  savePrivate: any;
  savedLimitSettingsNamesList: any;
  savedLimitSettingsSelectedIndex: any;
  prevSavedLimitSettingsSelectedIndex: any;
  testParameterNamesList: any;
  testParameterSelectedIndex: number;
  testParameterIndexState: number[],
  isLoading: boolean,
  patLimitsData: any;
  displayPATLimitsTable: boolean;
  isPatDataLoading: boolean;
}

interface ILimitSettingsModalProps {
  name: any;
  differentUOM: any;
  testParameters: any;
  updatedTabCount: number;
  updateTabUpdatedCount: () => void;
  applyLimitSettings: (limitSettingsObj: any, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => void;
  requestObject?: IGenericDetailedReportRequestObject;
  genericLimitSettingsState?: any,
  retainGenericLimitSettingsState?: (genericLimitSettingsState: any) => void;
  testParameterIndexProps: number[];
}

class limitSettingsModal extends React.Component<ILimitSettingsModalProps, ILimitSettingsModalState> {
  static defaultProps = {
    name: '',
    differentUOM: false,
    testParameters: [],
    updatedTabCount: 0,
  }

  private GENERIC_LIMIT_SETTINGS_NAME = 'Generic Limit Settings';

  private SAVED_LIMIT_SETTINGS_NAME = 'Saved Limit Settings';

  private largeVal = 79228162514264337593543950335;

  private outlierLowDropdownRef = createRef<DropDownBox>();

  private outlierHighDropdownRef = createRef<DropDownBox>();

  private outlierLowTreeViewRef = createRef<TreeView>();

  private outlierHighTreeViewRef = createRef<TreeView>();

  private distributionTreeViewRef = createRef<TreeView>();

  private originalLimitSettingsData = JSON.parse(JSON.stringify(limitSettingsData.getLimitSettingsOptions()));

  private originalOutlierMultiselectInfo = JSON.parse(JSON.stringify(limitSettingsData.getOutlierLimitsDropdownData()));

  private originalDistributionMultiselectInfo = JSON.parse(JSON.stringify(limitSettingsData.getDistributionLimitsDropdownData()));

  private PATLimits: any[] = []

  private patLotErrors : any[] = []

  private isPatLimitsWarningShown = false;

  constructor(props: ILimitSettingsModalProps) {
    super(props);
    const {
      name,
      differentUOM,
      testParameterIndexProps,
    } = this.props;
    this.state = {
      id: '',
      name,
      limitOptions: limitSettingsData.getLimitSettingsOptions(),
      differentUOM,
      outlierMultiselectInfo: limitSettingsData.getOutlierLimitsDropdownData(),
      distributionMultiselectInfo: limitSettingsData.getDistributionLimitsDropdownData(),
      currentOutlierMultiselectLow: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
      currentOutlierMultiselectHigh: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
      savePrivate: true,
      savedLimitSettingsNamesList: [],
      savedLimitSettingsSelectedIndex: 0,
      prevSavedLimitSettingsSelectedIndex: 0,
      testParameterNamesList: [],
      testParameterSelectedIndex: 0,
      testParameterIndexState: testParameterIndexProps,
      isLoading: name === 'Saved Limit Settings',
      patLimitsData: undefined,
      displayPATLimitsTable: false,
      isPatDataLoading: true,
    };
  }

  populateLimitValues = (typeOfLimits: any, limitsValues: ILimitsValues, limitSettingsObj: any, typeOfLimitsSpecific?: string, limitIndexSpecific?: number) => {
    const { limitOptions } = this.state;
    limitSettingsObj[typeOfLimits][0].lowVal = limitsValues.rawDataSpecLow;
    limitSettingsObj[typeOfLimits][0].highVal = limitsValues.rawDataSpecHigh;
    limitSettingsObj[typeOfLimits][0].valUnit = limitsValues.unitOfMeasurement;
    limitSettingsObj[typeOfLimits][1].lowVal = limitsValues.rawDataControlLow;
    limitSettingsObj[typeOfLimits][1].highVal = limitsValues.rawDataControlHigh;
    limitSettingsObj[typeOfLimits][1].valUnit = limitsValues.unitOfMeasurement;
    limitSettingsObj[typeOfLimits][2].lowVal = limitsValues.tukeyLow;
    limitSettingsObj[typeOfLimits][2].highVal = limitsValues.tukeyHigh;
    const sigmaLimits: any[] = [];
    limitOptions.distribution.forEach((limit: any, sigmaIndex: number) => {
      if (limit.type === 'Sigma'){
        sigmaLimits.push(sigmaIndex);
      }
    })
    if ((!typeOfLimitsSpecific && !limitIndexSpecific) || (typeOfLimitsSpecific === typeOfLimits && limitIndexSpecific === 4)) {
      // limitSettingsObj[typeOfLimits][4].lowVal = limitsValues.tukeyLow;
      // limitSettingsObj[typeOfLimits][4].highVal = limitsValues.tukeyHigh;
      limitSettingsObj[typeOfLimits][4].valUnit = '';
    }
    if (limitsValues.outlierSigmas.length > 0) {
      limitSettingsObj.outlier[6].lowVal = limitsValues.outlierSigmas[0];
      limitSettingsObj.outlier[6].highVal = limitsValues.outlierSigmas[1];
    }
    if (limitsValues.distributionSigmas.length > 0) {
      limitsValues.distributionSigmas.forEach((sigmaLimit: any, index: number) => {
        if (index % 2 === 0) {
          const limitIndex = sigmaLimits[index / 2];
          if (limitsValues.distributionSigmas[index] === -this.largeVal) {
            limitSettingsObj.distribution[limitIndex].displayLow = false;
            limitSettingsObj.distribution[limitIndex].highCheck = true;
            limitSettingsObj.distribution[limitIndex].displayHigh = true;
          } else {
            limitSettingsObj.distribution[limitIndex].lowVal = limitsValues.distributionSigmas[index];
          }
          if (limitsValues.distributionSigmas[index + 1] === this.largeVal) {
            limitSettingsObj.distribution[limitIndex].displayLow = true;
            limitSettingsObj.distribution[limitIndex].displayHigh = false;
            limitSettingsObj.distribution[limitIndex].highCheck = false;
          } else {
            limitSettingsObj.distribution[limitIndex].highVal = limitsValues.distributionSigmas[index + 1];
          }
        }
      });
    }
    if (!limitsValues.patLimits || limitsValues.patLimits.length === 0){
      limitSettingsObj[typeOfLimits].forEach((limit: any) => {
        if (limit.type === 'PAT Limit'){
          limit.lowVal = null;
          limit.highVal = null;
          limit.selected = false;
          this.setState({ patLimitsData: [] })
          this.setState({ displayPATLimitsTable: false })
        }
      });
    }

    if (limitsValues.patLimits && limitsValues.patLimits.length > 0 && this.PATLimits && this.PATLimits.length === 0) {
      limitsValues.patLimits.forEach((patLimit: any, index: number) => {
        if (index === 0) {
          limitSettingsObj[typeOfLimits][5].lowVal = patLimit.lowLimit;
          limitSettingsObj[typeOfLimits][5].highVal = patLimit.highLimit;
          limitSettingsObj[typeOfLimits][5].customNameLow = `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Min')}`;
          limitSettingsObj[typeOfLimits][5].customNameHigh = `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Max')}`;
        } else if (this.findCountOfPATLimits() !== limitsValues.patLimits.length) {
          const lowColor = `#${((Math.random() * 0xfffff * 1000000).toString(16)).slice(0, 6)}`;
          const highColor = `#${((Math.random() * 0xfffff * 2000000).toString(16)).slice(0, 6)}`;
          const appearance = 'solid';
          const thickness = '3';
          limitOptions[typeOfLimits].push({
            index: limitOptions[typeOfLimits].length,
            type: 'PAT Limit',
            selected: false,
            displayLow: false,
            displayHigh: false,
            lowCheck: true,
            highCheck: true,
            lowValDisplayed: patLimit.lowLimit,
            lowVal: patLimit.lowLimit,
            highValDisplayed: patLimit.highLimit,
            highVal: patLimit.highLimit,
            valUnit: '',
            colorLow: lowColor,
            customNameLow: `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Min')}`,
            appearanceLow: appearance,
            thicknessLow: thickness,
            colorHigh: highColor,
            customNameHigh: `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Max')}`,
            appearanceHigh: appearance,
            thicknessHigh: thickness,
          });
        }
      });
    }
  };

  populatePATLimits = (typeOfLimits: any, limitsValues: any, limitSettingsObj: any, typeOfLimitsSpecific?: string, limitIndexSpecific?: number) => {
    limitSettingsObj[typeOfLimits] = limitSettingsObj[typeOfLimits].filter((obj: any) => obj.index < 7);
    if (limitsValues && limitsValues.length > 0) {
      limitsValues.forEach((patLimit: any, index: number) => {
        if (index === 0) {
          limitSettingsObj[typeOfLimits][5].lowVal = patLimit.patMinLimit;
          limitSettingsObj[typeOfLimits][5].highVal = patLimit.patMaxLimit;
          limitSettingsObj[typeOfLimits][5].customNameLow = `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Min')}`;
          limitSettingsObj[typeOfLimits][5].customNameHigh = `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Max')}`;
        } else if (this.findCountOfPATLimits() !== limitsValues.length) {
          const lowColor = `#${((Math.random() * 0xfffff * 1000000).toString(16)).slice(0, 6)}`;
          const highColor = `#${((Math.random() * 0xfffff * 2000000).toString(16)).slice(0, 6)}`;
          const appearance = 'solid';
          const thickness = '3';
          limitSettingsObj[typeOfLimits].push({
            index: limitSettingsObj[typeOfLimits].length,
            type: 'PAT Limit',
            selected: false,
            displayLow: false,
            displayHigh: false,
            lowCheck: true,
            highCheck: true,
            lowValDisplayed: patLimit.patMinLimit,
            lowVal: patLimit.patMinLimit,
            highValDisplayed: patLimit.patMaxLimit,
            highVal: patLimit.patMaxLimit,
            valUnit: '',
            colorLow: lowColor,
            customNameLow: `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Min')}`,
            appearanceLow: appearance,
            thicknessLow: thickness,
            colorHigh: highColor,
            customNameHigh: `${'PAT_'.concat(patLimit.ruleName).concat('_PAT_Max')}`,
            appearanceHigh: appearance,
            thicknessHigh: thickness,
          });
        }
      });
    }
  };

  findCountOfPATLimits = () => {
    const { limitOptions } = this.state;
    let count = 0
    limitOptions.distribution.forEach((obj: any) => {
      if (obj.type === 'PAT Limit'){
        count += 1;
      }
    })
    return count
  }

  getCorrectLimitValues = (limitSettingsObj: any, typeOfLimitsSpecific?: string, limitIndexSpecific?: number) => {
    const {
      testParameters,
      requestObject,
      name
    } = this.props;
    const {
      testParameterIndexState,
      testParameterSelectedIndex
    } = this.state;
    if (requestObject !== undefined) {
      requestObject.limits = limitSettingsObj;
      requestObject.selectedTestParameterIndex = testParameterIndexState[testParameterSelectedIndex];
    }
    if (testParameters.length !== 0) {
      limitSettingsObj.outlier.forEach((limit: any) => {
        limit.lowVal = limit.lowVal === '-' ? -1 : limit.lowVal;
        limit.highVal = limit.highVal === '-' ? -1 : limit.highVal;
        limit.lowVal = Number(limit.lowVal);
        limit.highVal = Number(limit.highVal);
        limit.lowValDisplayed = Number(limit.lowValDisplayed);
        limit.highValDisplayed = Number(limit.highValDisplayed);
      });
      limitSettingsObj.distribution.forEach((limit: any) => {
        limit.lowVal = limit.lowVal === '-' ? -1 : limit.lowVal;
        limit.highVal = limit.highVal === '-' ? -1 : limit.highVal;
        limit.lowVal = Number(limit.lowVal);
        limit.highVal = Number(limit.highVal);
        limit.lowValDisplayed = Number(limit.lowValDisplayed);
        limit.highValDisplayed = Number(limit.highValDisplayed);
      });
      httpLimitSettings.getLimitsData(requestObject)
        .then((limitsValues: any) => {
          if ((!limitsValues.patLimits || limitsValues.patLimits.length === 0) && name === 'Generic Limit Settings' && !this.isPatLimitsWarningShown){
            toast.error('PAT Limits do not exist for the selected data!');
            this.isPatLimitsWarningShown = true;
          }
          this.populateLimitValues('outlier', limitsValues, limitSettingsObj, typeOfLimitsSpecific, limitIndexSpecific);
          this.populateLimitValues('distribution', limitsValues, limitSettingsObj, typeOfLimitsSpecific, limitIndexSpecific);
          if (limitsValues.error && limitsValues.error.length > 0){
            this.patLotErrors = limitsValues.error;
          }
          if (limitsValues.patLimits && limitsValues.patLimits.length > 0 && limitsValues.isPATProbeWafer){
            this.setState({ patLimitsData: limitsValues.patLimits, displayPATLimitsTable: true })
          }

          if (!limitsValues.isPATProbeWafer && limitsValues.patLimits && limitsValues.patLimits.length > 0){
            this.setState({
              outlierMultiselectInfo: limitSettingsData.removePATLimitOptionFromOutlierList(),
              distributionMultiselectInfo: limitSettingsData.removePATLimitsOptionFromDistributionList(),
              displayPATLimitsTable: false
            })
          }
          const newestLimitOptions = { ...limitSettingsObj };
          this.setState({ limitOptions: newestLimitOptions, isPatDataLoading: false });
        });
    }
  };

  getAndSetLimitSettings = () => {
    const { savedLimitSettingsSelectedIndex } = this.state;
    const {
      differentUOM,
      testParameters,
      testParameterIndexProps
    } = this.props;
    const paramsList: any = [];
    for (let i = 0; i < testParameters[0].length; i += 1) {
      if (testParameterIndexProps.includes(i) || testParameterIndexProps.length < 2) {
        paramsList.push({
          id: i,
          name: testParameters[0][i].name.toString()
        });
      }
    }
    httpLimitSettings.getAllLimitSettingsByUserId()
      .then((limitSettingsResponse: any) => {
        let savedLimitSettingsNamesListRetrieved: any = [];
        if (limitSettingsResponse !== undefined && limitSettingsResponse !== null) {
          savedLimitSettingsNamesListRetrieved = limitSettingsResponse.map((limitSetting: any) => ({
            id: limitSetting.id,
            name: `${limitSetting.limitSettingsName}${limitSetting.private ? ' (private)' : ' (public)'}`.toString(),
          }));
        }
        const limitSettingsSelected = limitSettingsResponse[savedLimitSettingsSelectedIndex];
        if (savedLimitSettingsNamesListRetrieved.length === 0 || savedLimitSettingsSelectedIndex >= savedLimitSettingsNamesListRetrieved.length) {
          this.setState({
            savedLimitSettingsNamesList: savedLimitSettingsNamesListRetrieved,
            savedLimitSettingsSelectedIndex: savedLimitSettingsNamesListRetrieved.length,
            prevSavedLimitSettingsSelectedIndex: savedLimitSettingsNamesListRetrieved.length,
            testParameterNamesList: paramsList,
            testParameterIndexState: testParameterIndexProps,
            isLoading: false,
          });
        } else if (limitSettingsSelected !== undefined && limitSettingsSelected !== null) {
          const limitOptionsObj = {
            outlier: [],
            distribution: []
          };
          limitOptionsObj.outlier = limitSettingsSelected.limits.outlier;
          limitOptionsObj.distribution = limitSettingsSelected.limits.distribution;
          const limitSettingsDistributionDropdown = limitSettingsData.getDistributionLimitsDropdownData();
          const limitSettingsOutlierDropdown = limitSettingsData.getOutlierLimitsDropdownData();
          let outlierLowDropdownIndex = -1;
          let outlierHighDropdownIndex = -1;
          let outlierSelectLow: any = limitSettingsData.getOutlierLimitsDropdownData()[0];
          let outlierSelectHigh: any = limitSettingsData.getOutlierLimitsDropdownData()[0];
          for (let i = 0; i < limitSettingsDistributionDropdown[0].items.length; i += 1) {
            if (limitSettingsSelected.limits.outlier[i].selected) {
              if (limitSettingsSelected.limits.outlier[i].displayLow) {
                outlierLowDropdownIndex = i;
              }
              if (limitSettingsSelected.limits.outlier[i].displayHigh) {
                outlierHighDropdownIndex = i;
              }
              limitSettingsOutlierDropdown[0].items[i].selected = true;
            } else {
              limitSettingsOutlierDropdown[0].items[i].selected = false;
            }
            if (limitSettingsSelected.limits.distribution[i].selected) {
              limitSettingsDistributionDropdown[0].items[i].selected = true;
            } else {
              limitSettingsDistributionDropdown[0].items[i].selected = false;
            }
          }
          if (outlierLowDropdownIndex !== -1) {
            outlierSelectLow = outlierSelectLow.items[outlierLowDropdownIndex];
          }
          if (outlierHighDropdownIndex !== -1) {
            outlierSelectHigh = outlierSelectHigh.items[outlierHighDropdownIndex];
          }
          this.getCorrectLimitValues(limitOptionsObj);
          this.setState({
            id: limitSettingsSelected.id,
            name: limitSettingsSelected.limitSettingsName,
            differentUOM,
            outlierMultiselectInfo: limitSettingsOutlierDropdown,
            distributionMultiselectInfo: limitSettingsDistributionDropdown,
            currentOutlierMultiselectLow: outlierSelectLow,
            currentOutlierMultiselectHigh: outlierSelectHigh,
            savePrivate: limitSettingsSelected.private,
            savedLimitSettingsNamesList: savedLimitSettingsNamesListRetrieved,
            savedLimitSettingsSelectedIndex,
            prevSavedLimitSettingsSelectedIndex: savedLimitSettingsSelectedIndex,
            testParameterNamesList: paramsList,
            testParameterIndexState: testParameterIndexProps,
            isLoading: false,
          });
        }
      });
  };

  componentDidMount() {
    const {
      name,
      differentUOM,
      testParameters,
      genericLimitSettingsState,
      testParameterIndexProps,
    } = this.props;
    const paramsList: any = [];
    for (let i = 0; i < testParameters[0].length; i += 1) {
      if (testParameterIndexProps.includes(i) || testParameterIndexProps.length < 2) {
        paramsList.push({
          id: i,
          name: testParameters[0][i].name.toString()
        });
      }
    }

    // Get appropriate Limit Settings for the tab
    if (name === this.GENERIC_LIMIT_SETTINGS_NAME) {
      let newTestParameterIndex = [];
      newTestParameterIndex = [...testParameterIndexProps];
      // retain or not retain previous selections
      if (genericLimitSettingsState !== undefined) {
        genericLimitSettingsState.testParameterNamesList = paramsList;
        genericLimitSettingsState.testParameterIndexState = newTestParameterIndex;
        this.setState(genericLimitSettingsState);
      } else {
        this.setState({
          id: '',
          name: 'Limit Settings 1',
          limitOptions: this.originalLimitSettingsData,
          differentUOM,
          outlierMultiselectInfo: this.originalOutlierMultiselectInfo,
          distributionMultiselectInfo: this.originalDistributionMultiselectInfo,
          currentOutlierMultiselectLow: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
          currentOutlierMultiselectHigh: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
          savePrivate: true,
          savedLimitSettingsNamesList: [],
          savedLimitSettingsSelectedIndex: 0,
          prevSavedLimitSettingsSelectedIndex: 0,
          testParameterNamesList: paramsList,
          testParameterIndexState: newTestParameterIndex,
        });
        this.getCorrectLimitValues(this.originalLimitSettingsData);
      }
    } else {
      let newTestParameterIndex = [];
      newTestParameterIndex = [...testParameterIndexProps];
      this.setState({
        testParameterNamesList: paramsList,
        testParameterIndexState: newTestParameterIndex
      });
      this.getAndSetLimitSettings();
    }
  }

  componentDidUpdate(prevProps: ILimitSettingsModalProps, prevState: ILimitSettingsModalState) {
    const {
      limitOptions,
      savedLimitSettingsSelectedIndex,
      prevSavedLimitSettingsSelectedIndex,
      testParameterSelectedIndex,
    } = this.state;
    const { updatedTabCount } = this.props;
    if (testParameterSelectedIndex !== prevState.testParameterSelectedIndex) {
      this.getCorrectLimitValues(limitOptions);
    }
    if ((savedLimitSettingsSelectedIndex !== prevSavedLimitSettingsSelectedIndex)
            || (updatedTabCount !== prevProps.updatedTabCount)) {
      this.getAndSetLimitSettings();
    }
  }

  getTooltipText = (limit: any, check: any) => {
    const MANUAL_CUSTOM = 'Manual Custom';
    let tooltipText = '';
    if (check === 'lowCheck') {
      if (!limit.lowCheck) {
        if (limit.type === 'Sigma' || limit.type === MANUAL_CUSTOM) {
          tooltipText = 'Check \'Enable Low\' to enable and edit';
        } else {
          tooltipText = 'Check \'Enable Low\' to enable (uneditable field)';
        }
      } else if (limit.type === 'Sigma' || limit.type === MANUAL_CUSTOM) {
        tooltipText = 'Edit field';
      } else {
        tooltipText = 'Uneditable field';
      }
    } else if (check === 'highCheck') {
      if (!limit.highCheck) {
        if (limit.type === 'Sigma'
                    || limit.type === MANUAL_CUSTOM) {
          tooltipText = 'Check \'Enable High\' to enable and edit';
        } else {
          tooltipText = 'Check \'Enable High\' to enable (uneditable field)';
        }
      } else if (limit.type === 'Sigma'
                || limit.type === MANUAL_CUSTOM) {
        tooltipText = 'Edit field';
      } else {
        tooltipText = 'Uneditable field';
      }
    }
    return tooltipText;
  };

  checkReadOnly = (limit: any, check: any) => {
    const MANUAL_CUSTOM = 'Manual Custom';
    let readOnly = true;
    if (check === 'lowCheck') {
      if (limit.lowCheck
                && (limit.type === 'Sigma'
                    || limit.type === MANUAL_CUSTOM)) {
        readOnly = false;
      }
    } else if (check === 'highCheck') {
      if ((limit.highCheck)
                && (limit.type === 'Sigma'
                    || limit.type === MANUAL_CUSTOM)) {
        readOnly = false;
      }
    }
    return readOnly;
  };

  mapLineAppearanceToCSS = (appearance: string) => {
    let newAppearance = _.cloneDeep(appearance);
    if (appearance === 'dot') {
      newAppearance = 'Dotted';
    } else if (appearance === 'dash' || appearance === 'longdash' || appearance === 'dashdot' || appearance === 'longdashdot') {
      newAppearance = 'Dashed';
    }
    return newAppearance;
  };

  getLimitLinePropertiesDisplay = (newLimitOptions: any, bothRows: any, onlySecondRow: any, typeOfLimits: any, limitIndex: number) => {
    const { name } = this.props;
    const { limitOptions } = this.state;
    return (
      <Row>
        <Col>
          {((limitOptions[typeOfLimits][limitIndex].displayLow && !onlySecondRow) || (typeOfLimits === 'distribution' && !onlySecondRow)) && (
            <Row className="d-flex text-center pr15">
              <TooltipWrapper text="Change Color" id="">
                <div className="mt3 vert-align-content">
                  <ColorBox
                    acceptCustomValue
                    key={`${limitOptions[typeOfLimits][limitIndex].index} color low ${name}`}
                    defaultValue={limitOptions[typeOfLimits][limitIndex].colorLow}
                    value={limitOptions[typeOfLimits][limitIndex].colorLow}
                    onValueChanged={(e) => {
                      limitOptions[typeOfLimits][limitIndex].colorLow = e.value;
                      const newestLimitOptions = { ...limitOptions };
                      this.setState({ limitOptions: newestLimitOptions });
                    }}
                  />
                </div>
              </TooltipWrapper>
            </Row>
          )}
          {(bothRows && (limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution'))
                        && (
                          <Row className="d-flex text-center mt10 pr15">
                            <TooltipWrapper text="Change Color" id="">
                              <div className="mt3 vert-align-content">
                                <ColorBox
                                  acceptCustomValue
                                  key={`${limitOptions[typeOfLimits][limitIndex].index} color high ${name}`}
                                  defaultValue={limitOptions[typeOfLimits][limitIndex].colorHigh}
                                  onValueChanged={(e) => {
                                    limitOptions[typeOfLimits][limitIndex].colorHigh = e.value;
                                    const newestLimitOptions = { ...limitOptions };
                                    this.setState({ limitOptions: newestLimitOptions });
                                  }}
                                />
                              </div>
                            </TooltipWrapper>
                          </Row>
                        )}
        </Col>
        <Col>
          {((limitOptions[typeOfLimits][limitIndex].displayLow && !onlySecondRow) || (typeOfLimits === 'distribution' && !onlySecondRow)) && (
            <Row className="d-flex text-center">
              <TooltipWrapper text="Edit custom name for limit" id="">
                <div style={{ width: '90%' }}>
                  <TextBox
                    name={`${limitOptions[typeOfLimits][limitIndex].type} custom name low`}
                    key={`${limitOptions[typeOfLimits][limitIndex].index} custom name low ${name}`}
                    placeholder={limitOptions[typeOfLimits][limitIndex].customNameLow}
                    defaultValue={limitOptions[typeOfLimits][limitIndex].customNameLow}
                    value={limitOptions[typeOfLimits][limitIndex].customNameLow}
                    onChange={(e: any) => {
                      limitOptions[typeOfLimits][limitIndex].customNameLow = e.currentTarget.value;
                      const newestLimitOptions = { ...limitOptions };
                      this.setState({ limitOptions: newestLimitOptions });
                    }}
                  />
                </div>
              </TooltipWrapper>
            </Row>
          )}
          {(bothRows && (limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution'))
                        && (
                          <Row className="d-flex text-center mt10">
                            <TooltipWrapper text="Edit custom name for limit" id="">
                              <div style={{ width: '90%' }}>
                                <TextBox
                                  name={`${limitOptions[typeOfLimits][limitIndex].type} custom name high`}
                                  key={`${limitOptions[typeOfLimits][limitIndex].index} custom name high ${name}`}
                                  placeholder={limitOptions[typeOfLimits][limitIndex].customNameHigh}
                                  defaultValue={limitOptions[typeOfLimits][limitIndex].customNameHigh}
                                  value={limitOptions[typeOfLimits][limitIndex].customNameHigh}
                                  onChange={(e: any) => {
                                    limitOptions[typeOfLimits][limitIndex].customNameHigh = e.currentTarget.value;
                                    const newestLimitOptions = { ...limitOptions };
                                    this.setState({ limitOptions: newestLimitOptions });
                                  }}
                                />
                              </div>
                            </TooltipWrapper>
                          </Row>
                        )}
        </Col>
        <Col className="">
          {((limitOptions[typeOfLimits][limitIndex].displayLow && !onlySecondRow) || (typeOfLimits === 'distribution' && !onlySecondRow)) && (
            <Row className="d-flex text-center">
              <div style={{ width: '90%' }}>
                <CustomizedDropdown
                  key={`${limitOptions[typeOfLimits][limitIndex].index} appearance low ${name}`}
                  variant="light"
                  full
                  list={[
                    ['solid', 'Solid'],
                    ['dot', 'Dot'],
                    ['dash', 'Dash'],
                    ['longdash', 'Longdash'],
                    ['dashdot', 'Dashdot'],
                    ['longdashdot', 'Longdashdot'],
                  ]}
                  selectedValue={limitOptions[typeOfLimits][limitIndex].appearanceLow.toString()}
                  onChange={(value: string) => {
                    limitOptions[typeOfLimits][limitIndex].appearanceLow = value.toString();
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
            </Row>
          )}
          {(bothRows && (limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution'))
                        && (
                          <Row className="d-flex text-center mt10">
                            <div style={{ width: '90%' }}>
                              <CustomizedDropdown
                                key={`${limitOptions[typeOfLimits][limitIndex].index} appearance high ${name}`}
                                variant="light"
                                full
                                list={[
                                  ['solid', 'Solid'],
                                  ['dot', 'Dot'],
                                  ['dash', 'Dash'],
                                  ['longdash', 'Longdash'],
                                  ['dashdot', 'Dashdot'],
                                  ['longdashdot', 'Longdashdot'],
                                ]}
                                selectedValue={limitOptions[typeOfLimits][limitIndex].appearanceHigh.toString()}
                                onChange={(value: string) => {
                                  limitOptions[typeOfLimits][limitIndex].appearanceHigh = value.toString();
                                  const newestLimitOptions = { ...limitOptions };
                                  this.setState({ limitOptions: newestLimitOptions });
                                }}
                              />
                            </div>
                          </Row>
                        )}
        </Col>
        <Col className="">
          {((limitOptions[typeOfLimits][limitIndex].displayLow && !onlySecondRow) || (typeOfLimits === 'distribution' && !onlySecondRow)) && (
            <Row className="d-flex text-center">
              <TooltipWrapper text="Edit limit line thickness (in pixels)" id="">
                <div className="d-flex">
                  <TextBox
                    autoComplete="off"
                    type="number"
                    name={`${limitOptions[typeOfLimits][limitIndex].type} thickness low`}
                    key={`${limitOptions[typeOfLimits][limitIndex].index} thickness low ${name}`}
                    placeholder={limitOptions[typeOfLimits][limitIndex].thicknessLow}
                    defaultValue={limitOptions[typeOfLimits][limitIndex].thicknessLow}
                    value={limitOptions[typeOfLimits][limitIndex].thicknessLow}
                    onChange={(e: any) => {
                      if (e.currentTarget.value > 0 && e.currentTarget.value <= 15) {
                        limitOptions[typeOfLimits][limitIndex].thicknessLow = Number(e.currentTarget.value);
                      } else if (e.currentTarget.value > 15) {
                        toast.warn('Line thickness cannot be above 15px');
                        limitOptions[typeOfLimits][limitIndex].thicknessLow = 15;
                        e.currentTarget.value = 15;
                      } else if (e.currentTarget.value === '') {
                        limitOptions[typeOfLimits][limitIndex].thicknessLow = 1;
                      } else {
                        limitOptions[typeOfLimits][limitIndex].thicknessLow = 1;
                        e.currentTarget.value = 1;
                      }
                      const newestLimitOptions = { ...limitOptions };
                      this.setState({ limitOptions: newestLimitOptions });
                    }}
                  />
                  <div
                    key={`${limitOptions[typeOfLimits][limitIndex].index} preview high ${name}`}
                    className="ml10"
                    style={{
                      borderLeft: `${limitOptions[typeOfLimits][limitIndex].thicknessLow.toString()}px
                    ${this.mapLineAppearanceToCSS(limitOptions[typeOfLimits][limitIndex].appearanceLow)} ${limitOptions[typeOfLimits][limitIndex].colorLow}`
                    }}
                  />
                </div>
              </TooltipWrapper>
            </Row>
          )}
          {(bothRows && (limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution'))
                        && (
                          <Row className="d-flex text-center mt10">
                            <TooltipWrapper text="Edit limit line thickness (in pixels)" id="">
                              <div className="d-flex">
                                <TextBox
                                  autoComplete="off"
                                  type="number"
                                  name={`${limitOptions[typeOfLimits][limitIndex].type} thickness high`}
                                  key={`${limitOptions[typeOfLimits][limitIndex].index} thickness high ${name}`}
                                  placeholder={limitOptions[typeOfLimits][limitIndex].thicknessHigh}
                                  defaultValue={limitOptions[typeOfLimits][limitIndex].thicknessHigh}
                                  value={limitOptions[typeOfLimits][limitIndex].thicknessHigh}
                                  onChange={(e: any) => {
                                    if (e.currentTarget.value > 0 && e.currentTarget.value <= 15) {
                                      limitOptions[typeOfLimits][limitIndex].thicknessHigh = Number(e.currentTarget.value);
                                    } else if (e.currentTarget.value > 15) {
                                      toast.warn('Thickness cannot be above 15px');
                                      limitOptions[typeOfLimits][limitIndex].thicknessHigh = 15;
                                      e.currentTarget.value = 15;
                                    } else if (e.currentTarget.value === '') {
                                      limitOptions[typeOfLimits][limitIndex].thicknessHigh = 1;
                                    } else {
                                      limitOptions[typeOfLimits][limitIndex].thicknessHigh = 1;
                                      e.currentTarget.value = 1;
                                    }
                                    const newestLimitOptions = { ...limitOptions };
                                    this.setState({ limitOptions: newestLimitOptions });
                                  }}
                                />
                                <div
                                  key={`${limitOptions[typeOfLimits][limitIndex].index} preview high ${name}`}
                                  className="ml10"
                                  style={{
                                    borderLeft: `${limitOptions[typeOfLimits][limitIndex].thicknessHigh.toString()}px
                                  ${this.mapLineAppearanceToCSS(limitOptions[typeOfLimits][limitIndex].appearanceHigh)} ${limitOptions[typeOfLimits][limitIndex].colorHigh}`
                                  }}
                                />
                              </div>
                            </TooltipWrapper>
                          </Row>
                        )}
        </Col>
      </Row>
    );
  };

  displayLowOrHighForOutlier = (limit: any, typeOfLimits: string) => {
    let displayValue = '';
    if (typeOfLimits === 'outlier' && limit.displayLow && !limit.displayHigh) {
      displayValue = ' [Low]';
    } else if (typeOfLimits === 'outlier' && limit.displayHigh && !limit.displayLow) {
      displayValue = ' [High]';
    }
    return <span className="font-weight-bold">{displayValue}</span>;
  };

  getMostLimitsDisplay = (typeOfLimits: string, limitIndex: number) => {
    const {
      differentUOM,
      limitOptions
    } = this.state;
    const { name } = this.props;
    return (
      <Row className="d-flex mt10 vert-align-content ">
        <Col className="col-md-2" key={`${limitOptions[typeOfLimits][limitIndex].displayHigh} ${limitOptions[typeOfLimits][limitIndex].type} ${typeOfLimits} ${name}`}>
          {limitOptions[typeOfLimits][limitIndex].type}
          {this.displayLowOrHighForOutlier(limitOptions[typeOfLimits][limitIndex], typeOfLimits)}
        </Col>
        <Col className="col-md-3">
          {(limitOptions[typeOfLimits][limitIndex].displayLow || typeOfLimits === 'distribution') && (
            <Row className="d-flex  " style={{ flexWrap: 'nowrap' }}>
              <div className="vert-align-content">
                <span className="font-weight-bold">Enable Low</span>
                <CheckBox
                  className="ml10"
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable low`}
                  key={`${limitOptions[typeOfLimits][limitIndex].index} enable low ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].lowCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].lowCheck = !limitOptions[typeOfLimits][limitIndex].lowCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              {(!differentUOM || differentUOM) && (
                <TooltipWrapper text={this.getTooltipText(limitOptions[typeOfLimits][limitIndex], 'lowCheck')} id="">
                  <div style={{ width: '65%' }}>
                    <TextBox
                      className={`ml5 ${this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'lowCheck') ? 'darken-background' : ''}`}
                      disabled={this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'lowCheck')}
                      name={`${limitOptions[typeOfLimits][limitIndex].type} value low`}
                      key={`${limitOptions[typeOfLimits][limitIndex].type} low val ${name}`}
                      placeholder={limitOptions[typeOfLimits][limitIndex].lowVal}
                      value={limitOptions[typeOfLimits][limitIndex].lowVal}
                      onChange={(e: any) => {
                        if (e.currentTarget.value === '-') {
                          limitOptions[typeOfLimits][limitIndex].lowVal = '-';
                        } else if (isNaN(Number(e.currentTarget.value))) {
                          toast.error('Only numeric data can be entered for limit values');
                          e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].lowVal;
                        } else if (e.currentTarget.value.length > 10 && !e.currentTarget.value.includes('.')) {
                          toast.error('Value limit exceeded');
                          e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].lowVal;
                        } else if (e.currentTarget.value === '') {
                          limitOptions[typeOfLimits][limitIndex].lowVal = '';
                        } else {
                          limitOptions[typeOfLimits][limitIndex].lowVal = e.currentTarget.value;
                          const newestLimitOptions = { ...limitOptions };
                          this.setState({ limitOptions: newestLimitOptions });
                        }
                      }}
                    />
                  </div>
                </TooltipWrapper>
              )}
              {(!differentUOM || differentUOM)
                                && (
                                  <div className="vert-align-content ml5">{limitOptions[typeOfLimits][limitIndex].valUnit}</div>
                                )}
            </Row>
          )}
          {(limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution') && (
            <Row className="d-flex mt10 " style={{ flexWrap: 'nowrap' }}>
              <div className="vert-align-content">
                <span className="font-weight-bold">Enable High</span>
                <CheckBox
                  className="ml8"
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable high`}
                  key={`${limitOptions[typeOfLimits][limitIndex].index} enable high ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].highCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].highCheck = !limitOptions[typeOfLimits][limitIndex].highCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              {(!differentUOM || differentUOM) && (
                <TooltipWrapper text={this.getTooltipText(limitOptions[typeOfLimits][limitIndex], 'highCheck')} id="">
                  <div style={{ width: '65%' }}>
                    <TextBox
                      className={`ml5 ${this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'highCheck') ? 'darken-background' : ''}`}
                      disabled={this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'highCheck')}
                      name={`${limitOptions[typeOfLimits][limitIndex].type} value high`}
                      key={`${limitOptions[typeOfLimits][limitIndex].type} high val ${name}`}
                      placeholder={limitOptions[typeOfLimits][limitIndex].highVal}
                      value={limitOptions[typeOfLimits][limitIndex].highVal}
                      onChange={(e: any) => {
                        if (e.currentTarget.value === '-') {
                          limitOptions[typeOfLimits][limitIndex].highVal = '-';
                        } else if (isNaN(Number(e.currentTarget.value))) {
                          toast.error('Only numeric data can be entered for limit values');
                          e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].highVal;
                        } else if (e.currentTarget.value.length > 10 && !e.currentTarget.value.includes('.')) {
                          toast.error('Value limit exceeded');
                          e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].highVal;
                        } else if (e.currentTarget.value === '') {
                          limitOptions[typeOfLimits][limitIndex].highVal = '';
                        } else {
                          limitOptions[typeOfLimits][limitIndex].highVal = e.currentTarget.value;
                          const newestLimitOptions = { ...limitOptions };
                          this.setState({ limitOptions: newestLimitOptions });
                        }
                      }}
                    />
                  </div>
                </TooltipWrapper>
              )}
              {(!differentUOM || differentUOM)
                                && (
                                  <div className="vert-align-content ml5">{limitOptions[typeOfLimits][limitIndex].valUnit}</div>
                                )}
            </Row>
          )}
        </Col>

        <Col className="col-md-6">
          {this.getLimitLinePropertiesDisplay(limitOptions, true, false, typeOfLimits, limitIndex)}
        </Col>
        <Col className="col-md-1" />
      </Row>
    );
  };

  getUserDefinedLimitsDisplay = (typeOfLimits: string, limitIndex: number) => {
    const { limitOptions } = this.state;
    const { name } = this.props;
    // const limit = newLimitOptions[typeOfLimits][limitIndex];
    return (
      <Row className="d-flex mt10 vert-align-content ">
        <Col className="col-md-2" key={`${limitOptions[typeOfLimits][limitIndex].displayHigh} ${limitOptions[typeOfLimits][limitIndex].type} ${typeOfLimits} ${name}`}>
          {limitOptions[typeOfLimits][limitIndex].type}
          {this.displayLowOrHighForOutlier(limitOptions[typeOfLimits][limitIndex], typeOfLimits)}
        </Col>
        <Col className="col-md-3">
          {(limitOptions[typeOfLimits][limitIndex].displayLow || typeOfLimits === 'distribution') && (
            <Row className="d-flex ">
              <div className="vert-align-content">
                <span className="font-weight-bold">{`Enable ${typeOfLimits === 'outlier' ? 'Low' : ''}`}</span>
                <CheckBox
                  className={`${typeOfLimits === 'outlier' ? 'x10' : 'ml35'}`}
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable`}
                  key={`${limitOptions[typeOfLimits][limitIndex].index} enable low ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].lowCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].check = !limitOptions[typeOfLimits][limitIndex].lowCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              <div style={{
                width: '64%',
                marginLeft: 5
              }}
              >
                <CustomizedDropdown
                  variant="light"
                  full
                  list={[
                    ['Custom Limit 1', 'Custom Limit 1'],
                    ['Custom Limit 2', 'Custom Limit 2'],
                    ['Custom Limit 3', 'Custom Limit 3'],
                  ]}
                  dropdownButtonClass="border-all"
                  selectedValue={limitOptions[typeOfLimits][limitIndex].lowVal.toString()}
                  onChange={(value: string) => {
                    limitOptions[typeOfLimits][limitIndex].lowVal = value.toString();
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                >
                  Open
                </CustomizedDropdown>
              </div>
            </Row>
          )}
          {(limitOptions[typeOfLimits][limitIndex].displayHigh || typeOfLimits === 'distribution') && (
            <Row className="d-flex mt10 ">
              <div className="vert-align-content">
                <span className="font-weight-bold">{`Enable ${typeOfLimits === 'outlier' ? 'High' : ''}`}</span>
                <CheckBox
                  className={`${typeOfLimits === 'outlier' ? 'ml10' : 'ml35'}`}
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable`}
                  key={`${limitOptions[typeOfLimits][limitIndex].index} enable high ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].highCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].check = !limitOptions[typeOfLimits][limitIndex].highCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              <div style={{
                width: '64%',
                marginLeft: 5
              }}
              >
                <CustomizedDropdown
                  variant="light"
                  full
                  list={[
                    ['Custom limit 1', 'Custom limit 1'],
                    ['Custom limit 2', 'Custom limit 2'],
                    ['Custom limit 3', 'Custom limit 3'],
                  ]}
                  dropdownButtonClass="border-all"
                  selectedValue={limitOptions[typeOfLimits][limitIndex].highVal.toString()}
                  onChange={(value: string) => {
                    limitOptions[typeOfLimits][limitIndex].highVal = value.toString();
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                >
                  Open
                </CustomizedDropdown>
              </div>
            </Row>
          )}
        </Col>

        <Col className="col-md-6">
          {this.getLimitLinePropertiesDisplay(limitOptions, true, false, typeOfLimits, limitIndex)}
        </Col>
        <Col className="col-md-1" />
      </Row>
    );
  };

  getSigmaLimitsDisplay = (typeOfLimits: string, index: number, lastIndex: number) => {
    const {
      differentUOM,
      limitOptions
    } = this.state;
    const { name } = this.props;
    const sigmaLimits: any[] = [];

    limitOptions[typeOfLimits].forEach((limit: any, sigmaIndex: number) => {
      if (limit.type === 'Sigma'){
        sigmaLimits.push(sigmaIndex);
      }
    })

    const limitIndex = sigmaLimits[index];

    return (
      <Row className="d-flex mt10 vert-align-content ">
        {index === 0
                    && (
                      <Col className="col-md-2" key={`${limitOptions[typeOfLimits][limitIndex].displayHigh} ${limitOptions[typeOfLimits][limitIndex].type} ${typeOfLimits} ${name}`}>
                        {limitOptions[typeOfLimits][limitIndex].type}
                        {this.displayLowOrHighForOutlier(limitOptions[typeOfLimits][limitIndex], typeOfLimits)}
                      </Col>
                    )}
        {index !== 0 && (<Col className="col-md-2" />)}
        <Col className="col-md-3">
          {(limitOptions[typeOfLimits][limitIndex].displayLow || typeOfLimits === 'distribution') && (
            <Row className="d-flex " style={{ flexWrap: 'nowrap' }}>
              <div className="vert-align-content">
                <span className="font-weight-bold">{`Enable ${typeOfLimits === 'outlier' ? 'Low' : ''}`}</span>
                <CheckBox
                  className={`${typeOfLimits === 'outlier' ? 'ml10' : 'ml35'}`}
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable low`}
                  key={`${limitOptions[typeOfLimits][limitIndex].index} enable low ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].lowCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].lowCheck = !limitOptions[typeOfLimits][limitIndex].lowCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              {(!differentUOM || differentUOM) && (
                <>
                  <div className="w150">
                    <TooltipWrapper text={this.getTooltipText(limitOptions[typeOfLimits][limitIndex], 'lowCheck')} id="">
                      <TextBox
                        className={`ml5 ${this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'lowCheck') ? 'darken-background' : ''}`}
                        disabled={this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'lowCheck')}
                        name={`${limitOptions[typeOfLimits][limitIndex].type} low val sigma`}
                        key={`${limitOptions[typeOfLimits][limitIndex].index} low val sigma ${name}`}
                        placeholder={limitOptions[typeOfLimits][limitIndex].lowValDisplayed}
                        defaultValue={limitOptions[typeOfLimits][limitIndex].lowValDisplayed}
                        value={limitOptions[typeOfLimits][limitIndex].lowValDisplayed}
                        onChange={(e: any) => {
                          if (e.currentTarget.value === '-') {
                            limitOptions[typeOfLimits][limitIndex].lowValDisplayed = -1;
                          } else if (isNaN(Number(e.currentTarget.value))) {
                            toast.error('Only numeric data can be entered for limit values');
                            e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].lowValDisplayed;
                          } else if (e.currentTarget.value.length > 10 && !e.currentTarget.value.includes('.')) {
                            toast.error('Value limit exceeded');
                            e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].lowValDisplayed;
                          } else if (Number(e.currentTarget.value) > 0 && typeOfLimits === 'outlier') {
                            toast.error('Sigma low limit should be a negative value');
                            e.currentTarget.value = -Number(e.currentTarget.value);
                            limitOptions[typeOfLimits][limitIndex].lowValDisplayed = e.currentTarget.value;
                          } else {
                            limitOptions[typeOfLimits][limitIndex].lowValDisplayed = Number(e.currentTarget.value);
                          }
                          const newestLimitOptions = { ...limitOptions };
                          this.setState({ limitOptions: newestLimitOptions });
                        }}
                      />
                    </TooltipWrapper>
                  </div>
                  <div className="w150">
                    <TextBox
                      className="darken-background ml10"
                      disabled
                      name={`${limitOptions[typeOfLimits][limitIndex].type} low val actual sigma`}
                      key={`${limitOptions[typeOfLimits][limitIndex].index} low val actual sigma ${name}`}
                      placeholder={limitOptions[typeOfLimits][limitIndex].lowValDisplayed < 0 ? limitOptions[typeOfLimits][limitIndex].lowVal : limitOptions[typeOfLimits][limitIndex].highVal}
                      value={limitOptions[typeOfLimits][limitIndex].lowValDisplayed < 0 ? limitOptions[typeOfLimits][limitIndex].lowVal : limitOptions[typeOfLimits][limitIndex].highVal}
                    />
                  </div>
                  <Button
                    className="ml5"
                    key="sigma update button low"
                    variant="color-secondary color-primary-hover"
                    onClick={(e: any) => {
                      this.getCorrectLimitValues(limitOptions, typeOfLimits, limitIndex);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: 'blue' }}
                      className="fal fa-sync"
                      size="lg"
                      icon={faSync}
                    />
                  </Button>
                </>
              )}
              {(!differentUOM || differentUOM)
                                && (
                                  <div className="vert-align-content ml5">{limitOptions[typeOfLimits][limitIndex].valUnit}</div>
                                )}
            </Row>
          )}

          {(limitOptions[typeOfLimits][limitIndex].displayHigh && typeOfLimits === 'outlier') && (
            <Row className="d-flex mt10 " style={{ flexWrap: 'nowrap' }}>
              <div className="vert-align-content">
                <span className="font-weight-bold">{`Enable ${typeOfLimits === 'outlier' ? 'High' : ''}`}</span>
                <CheckBox
                  className={`${typeOfLimits === 'outlier' ? 'ml10' : 'ml35'}`}
                  name={`${limitOptions[typeOfLimits][limitIndex].type} enable high`}
                  key={`${limitOptions[typeOfLimits][limitIndex].high} enable high ${name}`}
                  value={limitOptions[typeOfLimits][limitIndex].highCheck}
                  onValueChange={() => {
                    limitOptions[typeOfLimits][limitIndex].highCheck = !limitOptions[typeOfLimits][limitIndex].highCheck;
                    const newestLimitOptions = { ...limitOptions };
                    this.setState({ limitOptions: newestLimitOptions });
                  }}
                />
              </div>
              {(!differentUOM || differentUOM) && (
                <>
                  <div className="w150">
                    <TooltipWrapper text={this.getTooltipText(limitOptions[typeOfLimits][limitIndex], 'highCheck')} id="">
                      <TextBox
                        className={`ml5 ${this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'highCheck') ? 'darken-background' : ''}`}
                        disabled={this.checkReadOnly(limitOptions[typeOfLimits][limitIndex], 'highCheck')}
                        name={`${limitOptions[typeOfLimits][limitIndex].type} high val sigma`}
                        key={`${limitOptions[typeOfLimits][limitIndex].index} high val sigma ${name}`}
                        placeholder={limitOptions[typeOfLimits][limitIndex].highValDisplayed}
                        defaultValue={limitOptions[typeOfLimits][limitIndex].highValDisplayed}
                        value={limitOptions[typeOfLimits][limitIndex].highValDisplayed}
                        onChange={(e: any) => {
                          if (e.currentTarget.value === '-') {
                            limitOptions[typeOfLimits][limitIndex].highValDisplayed = 1;
                          } else if (isNaN(Number(e.currentTarget.value))) {
                            toast.error('Only numeric data can be entered for limit values');
                            e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].highValDisplayed;
                          } else if (e.currentTarget.value.length > 10 && !e.currentTarget.value.includes('.')) {
                            toast.error('Value limit exceeded');
                            e.currentTarget.value = limitOptions[typeOfLimits][limitIndex].highValDisplayed;
                          } else if (Number(e.currentTarget.value) < 0 && typeOfLimits === 'outlier') {
                            toast.error('Sigma high limit should be a positive value');
                            e.currentTarget.value = -Number(e.currentTarget.value);
                            limitOptions[typeOfLimits][limitIndex].highValDisplayed = e.currentTarget.value;
                          } else {
                            limitOptions[typeOfLimits][limitIndex].highValDisplayed = Number(e.currentTarget.value);
                          }
                          const newestLimitOptions = { ...limitOptions };
                          this.setState({ limitOptions: newestLimitOptions });
                        }}
                      />
                    </TooltipWrapper>
                  </div>
                  <div className="w150">
                    <TextBox
                      className="darken-background ml10"
                      disabled
                      name={`${limitOptions[typeOfLimits][limitIndex].type} high val actual sigma`}
                      key={`${limitOptions[typeOfLimits][limitIndex].index} high val actual sigma ${name}`}
                      placeholder={limitOptions[typeOfLimits][limitIndex].lowValDisplayed < 0 ? limitOptions[typeOfLimits][limitIndex].lowVal : limitOptions[typeOfLimits][limitIndex].highVal}
                      value={typeOfLimits === 'outlier'
                        ? limitOptions[typeOfLimits][limitIndex].highVal
                        : limitOptions[typeOfLimits][limitIndex].lowValDisplayed < 0 ? limitOptions[typeOfLimits][limitIndex].lowVal : limitOptions[typeOfLimits][limitIndex].highVal}
                    />
                  </div>
                  <Button
                    className="ml5"
                    // style={{ backgroundColor: 'white', borderColor: 'red' }}
                    variant="color-secondary color-primary-hover"
                    key="sigma update button high"
                    onClick={(e: any) => {
                      this.getCorrectLimitValues(limitOptions, typeOfLimits, limitIndex);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: 'blue' }}
                      className="fal fa-sync"
                      size="lg"
                      icon={faSync}
                    />
                  </Button>
                </>
              )}
              {(!differentUOM || differentUOM)
                                && (
                                  <div className="vert-align-content ml5">{limitOptions[typeOfLimits][limitIndex].valUnit}</div>
                                )}
            </Row>
          )}
        </Col>

        <Col className="col-md-6">
          {this.getLimitLinePropertiesDisplay(limitOptions, limitOptions[typeOfLimits][limitIndex].lowValDisplayed < 0
            ? typeOfLimits === 'outlier' : true, limitOptions[typeOfLimits][limitIndex].lowValDisplayed >= 0, typeOfLimits, limitIndex)}
        </Col>
        <Col className="col-md-1">
          <Row className="d-flex ml-30 ">
            {(index !== 0 && typeOfLimits === 'distribution')
                            && (
                              <Button
                                className="mr5 mt10 ml10"
                                style={{
                                  backgroundColor: 'white',
                                  borderColor: 'red'
                                }}
                                variant="color-secondary color-primary-hover"
                                value={index.toString()}
                                onClick={(e: any) => {
                                  limitOptions[typeOfLimits].splice(sigmaLimits[e.currentTarget.value], 1);
                                  const newestLimitOptions = { ...limitOptions };
                                  this.setState({ limitOptions: newestLimitOptions });
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{ color: 'red' }}
                                  className="cursor-pointer"
                                  size="lg"
                                  icon={faMinus}
                                />
                              </Button>
                            )}
            {(index === lastIndex && typeOfLimits === 'distribution')
                            && (
                              <Button
                                className="mt10"
                                style={{
                                  backgroundColor: 'white',
                                  borderColor: '#248BF2'
                                }}
                                variant="color-secondary color-primary-hover"
                                onClick={() => {
                                  const color = `#${((Math.random() * 0xfffff * 1000000).toString(16)).slice(0, 6)}`;
                                  const appearance = 'solid';
                                  const customName = `${'Sigma '.concat((limitOptions[typeOfLimits][limitOptions[typeOfLimits].length - 1].lowValDisplayed + 3).toString())}`;
                                  const thickness = '3';
                                  const value = limitOptions[typeOfLimits][limitOptions[typeOfLimits].length - 1].lowValDisplayed + 1 === 0
                                    ? limitOptions[typeOfLimits][limitOptions[typeOfLimits].length - 1].lowValDisplayed + 2
                                    : limitOptions[typeOfLimits][limitOptions[typeOfLimits].length - 1].lowValDisplayed + 1;
                                  limitOptions[typeOfLimits].push({
                                    index: limitOptions[typeOfLimits].length,
                                    type: 'Sigma',
                                    selected: true,
                                    displayLow: true,
                                    displayHigh: false,
                                    lowCheck: true,
                                    highCheck: false,
                                    lowValDisplayed: value,
                                    lowVal: value,
                                    highValDisplayed: value,
                                    highVal: value,
                                    valUnit: '',
                                    colorLow: color,
                                    customNameLow: customName,
                                    appearanceLow: appearance,
                                    thicknessLow: thickness,
                                    colorHigh: color,
                                    customNameHigh: customName,
                                    appearanceHigh: appearance,
                                    thicknessHigh: thickness,
                                  });
                                  this.getCorrectLimitValues(limitOptions, typeOfLimits, limitOptions[typeOfLimits].length - 1);
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{ color: '#248BF2' }}
                                  className="cursor-pointer"
                                  size="lg"
                                  icon={faPlus}
                                />
                              </Button>
                            )}
          </Row>
        </Col>
      </Row>
    );
  };

  getSelectedLimitsDisplay = (typeOfLimits: string) => {
    const { limitOptions } = this.state;
    const selectedLimitsRows: JSX.Element[] = [];
    const rawDataSpecLimits = limitOptions[typeOfLimits][0];
    const rawDataControlLimits = limitOptions[typeOfLimits][1];
    const tukeyLimits = limitOptions[typeOfLimits][2];
    const userDefinedLimits = limitOptions[typeOfLimits][3];
    const manualCustomLimits = limitOptions[typeOfLimits][4];
    const patLimits = [];
    const sigmaLimits = [];

    for (let i = 5; i < limitOptions[typeOfLimits].length; i += 1) {
      if (limitOptions[typeOfLimits][i].type === 'PAT Limit'){
        patLimits.push(limitOptions[typeOfLimits][i]);
      }
    }

    for (let i = 6; i < limitOptions[typeOfLimits].length; i += 1) {
      if (limitOptions[typeOfLimits][i].type === 'Sigma') {
        sigmaLimits.push(limitOptions[typeOfLimits][i]);
      }
    }

    if (rawDataSpecLimits.selected) {
      const limitDisplay = this.getMostLimitsDisplay(typeOfLimits, 0);
      selectedLimitsRows.push(
        limitDisplay,
      );
      selectedLimitsRows.push(<div className="break-bottom mt10" />);
    }
    if (rawDataControlLimits.selected) {
      const limitDisplay = this.getMostLimitsDisplay(typeOfLimits, 1);
      selectedLimitsRows.push(
        limitDisplay,
      );
      selectedLimitsRows.push(<div className="break-bottom mt10" />);
    }
    if (tukeyLimits.selected) {
      const limitDisplay = this.getMostLimitsDisplay(typeOfLimits, 2);
      selectedLimitsRows.push(
        limitDisplay,
      );
      selectedLimitsRows.push(<div className="break-bottom mt10" />);
    }
    if (userDefinedLimits.selected) {
      const limitDisplay = this.getUserDefinedLimitsDisplay(typeOfLimits, 3);
      selectedLimitsRows.push(
        limitDisplay,
      );
      selectedLimitsRows.push(<div className="break-bottom mt10" />);
    }
    if (manualCustomLimits.selected) {
      const limitDisplay = this.getMostLimitsDisplay(typeOfLimits, 4);
      selectedLimitsRows.push(
        limitDisplay,
      );
      selectedLimitsRows.push(<div className="break-bottom mt10" />);
    }

    if (patLimits[0].selected && patLimits.length > 0) {
      for (let i = 0; i < patLimits.length; i += 1) {
        const limitDisplay = this.getMostLimitsDisplay(typeOfLimits, patLimits[i].index);
        if (typeOfLimits === 'distribution'){
          patLimits[i].displayLow = true;
          patLimits[i].displayHigh = true;
        }
        selectedLimitsRows.push(
          limitDisplay,
        );
        selectedLimitsRows.push(<div className="break-bottom mt10" />);
      }
    }

    if (sigmaLimits[0].selected) {
      for (let i = 0; i < sigmaLimits.length; i += 1) {
        const limitDisplay = this.getSigmaLimitsDisplay(typeOfLimits, i, sigmaLimits.length - 1);
        selectedLimitsRows.push(
          limitDisplay,
        );
        selectedLimitsRows.push(<div className="break-bottom mt10" />);
      }
    }

    // swap displaying rows if outliers are rendered in the wrong order
    if (selectedLimitsRows.length === 4 && typeOfLimits === 'outlier' && selectedLimitsRows[0].props.children[0].key[0] === 't') {
      [selectedLimitsRows[0], selectedLimitsRows[2]] = [selectedLimitsRows[2], selectedLimitsRows[0]];
    }

    return (
      <Container
        fluid
        className={`${selectedLimitsRows.length > 0 ? 'background-color-body-background border-all mt5' : ''} border-radius-4 pl20`}
        style={{
          maxHeight: '500px',
          overflow: 'scroll'
        }}
      >
        {selectedLimitsRows}
      </Container>
    );
  };

  changeOutlierLimitsSelection = (e: any, displayLowOrHigh: string) => {
    const { limitOptions } = this.state;
    let idx;

    // ----deselection logic
    limitOptions.outlier.forEach((limit: any) => {
      if (limit.displayLow === true && displayLowOrHigh === 'high') {
        // don't deselect
      } else {
        if (limit.displayHigh !== true) {
          limit.selected = false;
        }
        limit.displayLow = false;
      }

      if (limit.displayHigh === true && displayLowOrHigh === 'low') {
        // don't deselect
      } else {
        if (limit.displayLow !== true) {
          limit.selected = false;
        }
        limit.displayHigh = false;
      }
    });

    // ----selection logic
    if (e.itemData.id !== '1') {
      if (e.itemData.text === 'PAT Limit'){
        limitOptions.outlier.forEach((patLimit: any) => {
          if (patLimit.type === 'PAT Limit'){
            patLimit.selected = true;
            if (displayLowOrHigh === 'low') {
              patLimit.displayLow = true;
              const newestLimitOptions = { ...limitOptions };
              this.setState({
                limitOptions: newestLimitOptions,
                currentOutlierMultiselectLow: { ...e.itemData },
              });
            } else if (displayLowOrHigh === 'high') {
              patLimit.displayHigh = true;
              const newestLimitOptions = { ...limitOptions };
              this.setState({
                limitOptions: newestLimitOptions,
                currentOutlierMultiselectHigh: { ...e.itemData },
              });
            }
          }
        })
      } else {
        idx = e.itemData.id[2] - 1;
        limitOptions.outlier[idx].selected = true;
        if (displayLowOrHigh === 'low') {
          limitOptions.outlier[idx].displayLow = true;
          const newestLimitOptions = { ...limitOptions };
          this.setState({
            limitOptions: newestLimitOptions,
            currentOutlierMultiselectLow: { ...e.itemData },
          });
        } else if (displayLowOrHigh === 'high') {
          limitOptions.outlier[idx].displayHigh = true;
          const newestLimitOptions = { ...limitOptions };
          this.setState({
            limitOptions: newestLimitOptions,
            currentOutlierMultiselectHigh: { ...e.itemData },
          });
        }
      }
    } else if (e.itemData.id === '1' && displayLowOrHigh === 'low') {
      this.outlierLowTreeViewRef!.current!.instance.unselectItem('1');
      const newestLimitOptions = { ...limitOptions };
      this.setState({
        limitOptions: newestLimitOptions,
        currentOutlierMultiselectLow: { ...e.itemData },
      });
    } else if (e.itemData.id === '1' && displayLowOrHigh === 'high') {
      this.outlierHighTreeViewRef!.current!.instance.unselectItem('1');
      const newestLimitOptions = { ...limitOptions };
      this.setState({
        limitOptions: newestLimitOptions,
        currentOutlierMultiselectHigh: { ...e.itemData },
      });
    }
    if (displayLowOrHigh === 'low') {
      this.outlierLowDropdownRef!.current!.instance.close();
    } else {
      this.outlierHighDropdownRef!.current!.instance.close();
    }
  };

  changeDistributionLimitsSelection = (e: any) => {
    const { limitOptions } = this.state;
    const items = e.component.getSelectedNodes();
    limitOptions.distribution.forEach((limit: any) => {
      limit.selected = false;
    });
    let idx;
    if (items.length !== 0) {
      items.forEach((item: any) => {
        if (item.key === '1') {
          limitOptions.distribution.forEach((limit: any) => {
            limit.selected = true;
          });
        } else if (item.text === 'PAT Limit'){
          limitOptions.distribution.forEach((patLimit: any) => {
            if (patLimit.type === 'PAT Limit'){
              patLimit.selected = true;
            }
          })
        } else if (item.text === 'Sigma'){
          limitOptions.distribution.forEach((sigmaLimit: any) => {
            if (sigmaLimit.type === 'Sigma'){
              sigmaLimit.selected = true;
            }
          })
        } else {
          idx = item.key[2] - 1;
          if (limitOptions.distribution[idx].text !== 'PAT Limit' || limitOptions.distribution[idx].text !== 'Sigma'){
            limitOptions.distribution[idx].selected = true;
          }
        }
      });
    }
    const newestLimitOptions = { ...limitOptions };
    this.setState({ limitOptions: newestLimitOptions });
  };

  resetAllSelection = () => {
    const { differentUOM } = this.props;
    const {
      outlierMultiselectInfo,
      limitOptions
    } = this.state;
    let allOutlierLimitsCurrentlyUnselected = true;
    let allDistributionLimitsCurrentlyUnselected = true;
    limitOptions.outlier.forEach((limit: any) => {
      if (limit.selected) {
        limit.selected = false;
        allOutlierLimitsCurrentlyUnselected = false;
      }
    });
    limitOptions.distribution.forEach((limit: any) => {
      if (limit.selected) {
        limit.selected = false;
        allDistributionLimitsCurrentlyUnselected = false;
      }
    });
    limitOptions.outlier = JSON.parse(JSON.stringify(this.originalLimitSettingsData.outlier));
    limitOptions.distribution = JSON.parse(JSON.stringify(this.originalLimitSettingsData.distribution));
    const newestLimitOptions = { ...limitOptions };

    if (!allOutlierLimitsCurrentlyUnselected) {
      this.setState({
        currentOutlierMultiselectLow: { ...outlierMultiselectInfo[0] },
        currentOutlierMultiselectHigh: { ...outlierMultiselectInfo[0] },
        limitOptions: newestLimitOptions,
      });
    }

    if (!allDistributionLimitsCurrentlyUnselected && this.distributionTreeViewRef!.current !== undefined && this.distributionTreeViewRef!.current !== null) {
      this.distributionTreeViewRef!.current!.instance.unselectAll();
      this.setState({ limitOptions: newestLimitOptions });
    }

    this.setState({
      id: '',
      limitOptions: newestLimitOptions,
      differentUOM,
      outlierMultiselectInfo: this.originalOutlierMultiselectInfo,
      distributionMultiselectInfo: this.originalDistributionMultiselectInfo,
      currentOutlierMultiselectLow: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
      currentOutlierMultiselectHigh: { ...limitSettingsData.getOutlierLimitsDropdownData()[0] },
    });
  };

  getDropdownValue = (typeOfLimits: any, lowOrHigh: any) => {
    const {
      currentOutlierMultiselectLow,
      currentOutlierMultiselectHigh,
      distributionMultiselectInfo
    } = this.state;
    let value: any;
    let count: any;
    if (typeOfLimits === 'outlier') {
      value = 'Select an Outlier Limit to display...';
      if (currentOutlierMultiselectLow.id !== '1' && lowOrHigh === 'low') {
        value = currentOutlierMultiselectLow.text;
      } else if (currentOutlierMultiselectHigh.id !== '1' && lowOrHigh === 'high') {
        value = currentOutlierMultiselectHigh.text;
      }
    } else {
      value = 'Select Distribution Limits to display...';
      count = 0;
      distributionMultiselectInfo[0].items?.forEach((limit: any) => {
        if (limit.selected === true) {
          value = limit.text;
          count += 1;
        }
      });
      if (count > 1) {
        value = value.concat(` (+${(count - 1).toString()})`);
      }
    }
    return value;
  };

  getOutlierDropdowns = () => {
    const {
      limitOptions,
      outlierMultiselectInfo
    } = this.state;
    const { name } = this.props;
    // const newLimitOptions = { ...limitOptions };
    return (
      <Row>
        <Col className="form d-flex" style={{ padding: '0px' }}>
          <span className="vert-align-content mr5">Low</span>
          <div style={{ backgroundColor: '#f2f0f0' }}>
            <DropDownBox
              valueExpr="ID"
              ref={this.outlierLowDropdownRef}
              displayExpr="name"
              dataSource={outlierMultiselectInfo}
              key={`outlier dropdown ${name}`}
              label={this.getDropdownValue('outlier', 'low')}
              labelMode="floating"
              contentRender={() => {
                return (
                  <TreeView
                    expandAllEnabled
                    ref={this.outlierLowTreeViewRef}
                    id="simple-treeview-1"
                    items={outlierMultiselectInfo}
                    onItemClick={(e: any) => this.changeOutlierLimitsSelection(e, 'low')}
                  />
                );
              }}
              dropDownOptions={getDropDownOptions('div')}
            />
          </div>
        </Col>
        <Col className="form d-flex " style={{ padding: '0px' }}>
          <span className="vert-align-content ml5 mr5">High</span>
          <div style={{ backgroundColor: '#f2f0f0' }}>
            <DropDownBox
              valueExpr="ID"
              ref={this.outlierHighDropdownRef}
              displayExpr="name"
              placeholder="Select an Outlier Limit to display..."
              dataSource={outlierMultiselectInfo}
              label={this.getDropdownValue('outlier', 'high')}
              labelMode="floating"
              key={`distribution dropdown ${name}`}
              contentRender={() => {
                return (
                  <TreeView
                    expandAllEnabled
                    ref={this.outlierHighTreeViewRef}
                    id="simple-treeview-2"
                    items={outlierMultiselectInfo}
                    onItemClick={(e: any) => this.changeOutlierLimitsSelection(e, 'high')}
                  />
                );
              }}
              dropDownOptions={getDropDownOptions('div')}
            />
          </div>
        </Col>
      </Row>
    );
  };

  getDistributionDropdown = () => {
    const {
      distributionMultiselectInfo,
      limitOptions
    } = this.state;
    return (
      <Row>
        <Col className="form" style={{ padding: '0px' }}>
          <div style={{ backgroundColor: '#f2f0f0' }}>
            <DropDownBox
              valueExpr="ID"
              displayExpr="name"
              dataSource={distributionMultiselectInfo}
              label={this.getDropdownValue('distribution', 'both')}
              labelMode="floating"
              contentRender={() => {
                return (
                  <TreeView
                    expandAllEnabled
                    ref={this.distributionTreeViewRef}
                    selectionMode="multiple"
                    showCheckBoxesMode="normal"
                    id="simple-treeview"
                    items={distributionMultiselectInfo}
                    onSelectionChanged={(e: any) => this.changeDistributionLimitsSelection(e)}
                  />
                );
              }}
              dropDownOptions={getDropDownOptions('div')}
            />
          </div>
        </Col>
      </Row>
    );
  };

  saveOrUpdateLimitSettings = (type: any) => {
    const { updateTabUpdatedCount } = this.props;
    const {
      name,
      id,
      savePrivate,
      limitOptions,
    } = this.state;
    let anySelected = false;
    limitOptions.outlier.forEach((element: any) => {
      if (element.selected) {
        anySelected = true;
      }
    });
    limitOptions.distribution.forEach((element: any) => {
      if (element.selected) {
        anySelected = true;
      }
    });
    const newestLimitOptions = { ...limitOptions };
    this.setState({ limitOptions: newestLimitOptions });

    if (!anySelected) {
      toast.error('No Limit Settings Options selected');
    } else if (name === '') {
      toast.error('Insert a valid name');
    } else {
      const limitSettingsObj: any = {
        id,
        outlier: newestLimitOptions.outlier,
        distribution: newestLimitOptions.distribution,
        limitSettingsName: name,
        private: savePrivate,
      };
      if (type === 'update') {
        httpLimitSettings.updateLimitSettings(limitSettingsObj)
          .then((resMessage: any) => {
            if (resMessage.toString() === 'Limit Setting updated') {
              updateTabUpdatedCount();
              toast.success(resMessage);
              this.getAndSetLimitSettings();
            } else {
              toast.error(resMessage);
            }
          });
      } else if (type === 'save') {
        httpLimitSettings.saveLimitSettings(limitSettingsObj)
          .then((exists: any) => {
            if (!exists) {
              updateTabUpdatedCount();
              toast.success('Limit Setting saved');
            } else {
              toast.error('A Limit Setting with this name already exists!');
            }
          });
      }
    }
  };

  getSavedLimitSettingsList = () => {
    const savedLimitSettingsObj: any = [];
    httpLimitSettings.getAllLimitSettingsByUserId()
      .then((limitSettings: any) => {
        let idCounter = 0;
        if (limitSettings !== undefined || limitSettings !== null) {
          limitSettings.forEach((limitSetting: any) => {
            savedLimitSettingsObj.push({
              id: idCounter,
              name: limitSetting.limitSettingsName,
              privacy: limitSetting.private
            });
            idCounter += 1;
          });
        }
        return savedLimitSettingsObj;
      });
  };

  deleteLimitSettings = async () => {
    const { updateTabUpdatedCount } = this.props;
    const {
      id,
      name,
      limitOptions,
      savePrivate,
      savedLimitSettingsSelectedIndex,
    } = this.state;
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: `This action will delete '${name}' permanently. Are you sure you want to proceed?`,
    })) {
      const limitSettingsObj: any = {
        id,
        outlier: limitOptions.outlier,
        distribution: limitOptions.distribution,
        limitSettingsName: name,
        private: savePrivate,
      };
      httpLimitSettings.deleteLimitSettings(limitSettingsObj)
        .then((deletedCheck: any) => {
          if (deletedCheck) {
            updateTabUpdatedCount();
            toast.success('Selected limit settings deleted');
            if (savedLimitSettingsSelectedIndex !== 0) {
              this.setState({ savedLimitSettingsSelectedIndex: savedLimitSettingsSelectedIndex - 1 });
            } else {
              this.setState({ savedLimitSettingsSelectedIndex: savedLimitSettingsSelectedIndex + 1 });
            }
          } else {
            toast.error('You do not have the necessary permissions to delete this limit setting!');
          }
        });
    }
  };

  verifyLimitSelections = (typeOfLimits: any) => {
    const { limitOptions } = this.state;
    let anySelected = false;
    limitOptions[typeOfLimits].forEach((element: any, index: number) => {
      if (element.selected) {
        if (element.lowVal === null) {
          element.lowCheck = false;
        }
        if (element.highVal === null) {
          element.highCheck = false;
        }
        if ((element.lowVal === null && element.highVal === null) || (element.lowCheck === false && element.highCheck === false)) {
          element.selected = false;
        } else {
          anySelected = true;
        }
      }
    });
    const newestLimitOptions = { ...limitOptions };
    this.setState({ limitOptions: newestLimitOptions });
    return anySelected;
  };

  getAppropriateListComponent = (title: string, dataSourceName: string[], selectedIndex: number, selectedIndexStateName: string) => {
    const { isLoading } = this.state;
    return (
      <>
        <h5>
          <b>
            {' '}
            {title}
            {' '}
          </b>
        </h5>
        {isLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )
          : (
            <List
              width="500px"
              dataSource={dataSourceName}
              selectedIndex={selectedIndex}
              onItemClick={(e: any) => {
                if (selectedIndexStateName === 'savedLimitSettingsSelectedIndex') {
                  this.setState({ savedLimitSettingsSelectedIndex: e.itemIndex });
                } else if (selectedIndexStateName === 'testParameterSelectedIndex') {
                  this.setState({ testParameterSelectedIndex: e.itemIndex });
                }
              }}
              selectionMode="single"
              itemRender={(e: any) => (
                <div>
                  {e.name}
                </div>
              )}
              searchMode="contains"
              searchEnabled
              searchExpr="name"
            />
          )}
      </>
    );
  };

  updatePATLimits = (patLimits: any) => {
    this.PATLimits = patLimits;
    const { limitOptions } = this.state;
    if (this.PATLimits && this.PATLimits.length > 0){
      this.populatePATLimits('outlier', this.PATLimits, limitOptions);
      this.populatePATLimits('distribution', this.PATLimits, limitOptions);
      this.setState({ limitOptions })
    }
  }

  render() {
    const {
      savedLimitSettingsNamesList,
      savedLimitSettingsSelectedIndex,
      savePrivate,
      limitOptions,
      testParameterNamesList,
      testParameterIndexState,
      testParameterSelectedIndex,
      isLoading,
      patLimitsData,
      displayPATLimitsTable,
      isPatDataLoading
    } = this.state;
    const { state } = this;
    const { name } = this.props;
    const {
      testParameters,
      applyLimitSettings,
      retainGenericLimitSettingsState
    } = this.props;
    return (
      <div className="d-flex flex-row justify-content-start">
        <div className="d-flex flex-column justify-content-start">
          <div
            style={{
              maxHeight: 250,
              maxWidth: 250,
              overflow: 'scroll'
            }}
            className={`mr10 border-all border-radius-4 pl10 pr10 pt10 pb10 ${name === this.GENERIC_LIMIT_SETTINGS_NAME ? 'd-none' : ''}`}
          >
            {this.getAppropriateListComponent('Saved Limits', savedLimitSettingsNamesList, savedLimitSettingsSelectedIndex, 'savedLimitSettingsSelectedIndex')}
          </div>
          <div
            style={{
              maxHeight: 250,
              maxWidth: 250,
              overflow: 'scroll'
            }}
            className={`mr10 mt20  border-all border-radius-4 pl10 pr10 pt10 pb10 ${testParameters[0].length <= 1 ? 'd-none' : ''}`}
          >
            {this.getAppropriateListComponent('Test Parameters', testParameterNamesList, testParameterSelectedIndex, 'testParameterSelectedIndex')}
          </div>
        </div>
        <div className={`border-all pl20 pr20 pt10 pb10 background-color-light border-radius-4 ${name === this.GENERIC_LIMIT_SETTINGS_NAME ? 'w-100' : 'w-80'}`}>
          {isLoading ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" />
            </div>
          )
            : (
              <div style={{ fontSize: 16 }} className={`${name !== this.GENERIC_LIMIT_SETTINGS_NAME && savedLimitSettingsNamesList.length === 0 ? '' : 'd-none'}`}>
                No Saved Limit Setting exists! Save a limit setting by switching to the `Generic Limit Settings` tab
              </div>
            )}
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={{
              // name: `${name}`,
            }}
            onSubmit={() => undefined}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
            }) => (
              <form
                onSubmit={handleSubmit}
                onChange={handleChange}
                className={`custom-form ${name !== this.GENERIC_LIMIT_SETTINGS_NAME && savedLimitSettingsNamesList.length === 0 ? 'd-none' : ''}`}
              >
                <Container fluid style={{ overflow: 'visible' }}>
                  <Row className="font-weight-bold d-flex mt10 mb5 vert-align-content">
                    <Col className="col-md-2" style={{ fontSize: '13px' }}>Outlier Screening Limits</Col>
                    <Col className="col-md-3">{this.getOutlierDropdowns()}</Col>
                    <Col className="col-md-6">
                      <Row>
                        <Col className="font-weight-bold ">Color</Col>
                        <Col className="font-weight-bold ">Name</Col>
                        <Col className="font-weight-bold ">Appearance</Col>
                        <Col className="font-weight-bold ">Thickness</Col>
                      </Row>
                    </Col>
                    {name !== this.GENERIC_LIMIT_SETTINGS_NAME
                      ? (
                        <Col className="col-md-1 vert-align-content">
                          <Button
                            style={{
                              backgroundColor: 'white',
                              borderColor: 'red'
                            }}
                            variant="color-secondary color-primary-hover"
                            onClick={() => {
                              this.deleteLimitSettings();
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ color: 'red' }}
                              className="cursor-pointer"
                              size="lg"
                              icon={faTrash}
                            />
                          </Button>
                        </Col>
                      )
                      : <Col className="col-md-1" />}
                  </Row>
                  <Row>
                    {this.getSelectedLimitsDisplay('outlier')}
                  </Row>

                  <Row className="font-weight-bold d-flex vert-align-content mb5 mt20">
                    <Col className="col-md-2" style={{ fontSize: '13px' }}>Distribution Limits</Col>
                    <Col className="col-md-3">{this.getDistributionDropdown()}</Col>
                    <Col className="col-md-6">
                      <Row>
                        <Col className="font-weight-bold ">Color</Col>
                        <Col className="font-weight-bold ">Name</Col>
                        <Col className="font-weight-bold ">Appearance</Col>
                        <Col className="font-weight-bold ">Thickness</Col>
                      </Row>
                    </Col>
                    <Col className="col-md-1" />
                  </Row>
                  <Row>
                    {this.getSelectedLimitsDisplay('distribution')}
                  </Row>

                  <div />
                  <div />

                  {isPatDataLoading ? (
                    <div className="w-100 d-flex align-items-center justify-content-center">
                      <Spinner className="mr10" animation="border" variant="clear" />
                      <p className="pt13">Fetching PAT Limits Data...</p>
                    </div>
                  ) : (
                    <Row>
                      <Col>
                        <PATLimitsTable
                          patLimitsData={patLimitsData && patLimitsData.length > 0 ? patLimitsData : []}
                          show={displayPATLimitsTable}
                          updatePATLimits={this.updatePATLimits}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="font-weight-bold mt20" style={{ fontSize: '13px' }}>Applying and Saving Options</Col>
                  </Row>
                  <Row>
                    <div className="vert-align-content">
                      <span className="font-weight-bold pl15">Private</span>
                      <CheckBox
                        className="ml5"
                        name={`${savePrivate} save private`}
                        key={`enable private ${name}`}
                        value={savePrivate}
                        onValueChange={() => {
                          this.setState({ savePrivate: !savePrivate });
                        }}
                      />
                    </div>
                    <div className="font-weight-bold d-flex">
                      <div className="vert-align-content d-flex mr10">
                        <TooltipWrapper text="Save chosen settings by this name" id="">
                          <div>
                            <TextBox
                              className="ml10"
                              name="limitSettingsName"
                              key="Limit Settings name"
                              placeholder="Limit Settings 1"
                              value={state.name}
                              onChange={(e: any) => {
                                this.setState({ name: e.target.value });
                              }}
                            />
                          </div>
                        </TooltipWrapper>
                      </div>
                      <div className="vert-align-content d-flex">
                        <Button
                          variant="primary"
                          size="lg"
                          className="mt20 mr20 mb20"
                          onClick={() => {
                            this.saveOrUpdateLimitSettings(name === this.GENERIC_LIMIT_SETTINGS_NAME ? 'save' : 'update');
                          }}
                        >
                          {name === this.GENERIC_LIMIT_SETTINGS_NAME
                            ? 'Save'
                            : 'Update'}
                        </Button>
                      </div>
                      <div className="vert-align-content d-flex mr20 ">
                        <div>OR</div>
                      </div>
                      <div className="vert-align-content d-flex">
                        <Button
                          variant="warning"
                          size="lg"
                          className="mt20 mr10 mb20"
                          onClick={() => {
                            this.resetAllSelection();
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                      <div className="vert-align-content d-flex">
                        <Button
                          type="submit"
                          variant="success"
                          size="lg"
                          className="mt20 mr10 mb20"
                          onClick={() => {
                            let anySelected = false;
                            if (this.verifyLimitSelections('outlier')) {
                              anySelected = true;
                            }
                            if (this.verifyLimitSelections('distribution')) {
                              anySelected = true;
                            }
                            if (anySelected) {
                              // correct limit values called just to update all sigma values, regardless of whether user has refreshed or not
                              this.getCorrectLimitValues(limitOptions, 'outlier', -1);
                              if (retainGenericLimitSettingsState) {
                                retainGenericLimitSettingsState(this.state);
                              }
                              applyLimitSettings(limitOptions, testParameterSelectedIndex, testParameterIndexState[testParameterSelectedIndex], this.patLotErrors);
                            } else {
                              toast.error('No Limit Settings Options selected');
                            }
                          }}
                        >
                          <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Row>
                </Container>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default limitSettingsModal;
