import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';

interface IBinWaferMapComparisonSelectionTable {
  selectedCombinations: any;
}

class BinWaferMapComparisonSelectionTable extends Component<IBinWaferMapComparisonSelectionTable, any> {
  generateDataSource = (waferCombinations: any) => {
    const waferCombinationsDetails: any[] = [];
    waferCombinations.forEach((waferCombination: any, index: number) => {
      waferCombinationsDetails.push({
        combination: index + 1,
        baseWaferId: waferCombination.baseWafer.key,
        baseWaferProbeCount: waferCombination.baseWafer.probeCount,
        nonBaseWaferId: waferCombination.nonBaseWafer.key,
        nonBaseWaferProbeCount: waferCombination.nonBaseWafer.probeCount,
      });
    });
    return waferCombinationsDetails;
  };

  render() {
    const { selectedCombinations } = this.props;
    return (
      <div className="d-flex justify-content-start ml10 mr10 mb10">
        <Row>
          <Col lg={12}>
            <div className="mt10">
              <DataGrid
                height="auto"
                showBorders
                dataSource={Object.keys(selectedCombinations).length > 0 ? this.generateDataSource(selectedCombinations) : []}
              >
                <Column alignment="left" caption="Combination" dataField="combination" />
                <Column caption="Base Wafer">
                  <Column alignment="left" caption="ID" dataField="baseWaferId" />
                  <Column alignment="left" caption="Probe Count" dataField="baseWaferProbeCount" />
                </Column>
                <Column caption=" Non Base Wafer">
                  <Column alignment="left" caption="ID" dataField="nonBaseWaferId" />
                  <Column alignment="left" caption="Probe Count" dataField="nonBaseWaferProbeCount" />
                </Column>
              </DataGrid>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BinWaferMapComparisonSelectionTable;
