/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import plotly from 'plotly.js';
import '../../chart-editor/styles/main.scss';
import _ from 'lodash';
import PlotlyEditor from '../../chart-editor';

const config = { mapboxAccessToken: '', editable: true };

class PlotlyChartDesigner extends Component<any, any> {
  constructor(props:any) {
    super(props);
    const { layout } = this.props;
    this.state = {
      data: props.data,
      layout,
      frames: [],
      currentMockIndex: -1,
      // mocks: [],
    };

    // this.loadMock = this.loadMock.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  // eslint-disable-next-line camelcase
  // UNSAFE_componentWillMount() {
  //   fetch('/mocks.json')
  //     .then((response) => response.json())
  //     .then((mocks) => this.setState({ mocks }));
  // }

  componentDidUpdate(prevProps: any, props: any) {
    let updateData = false;
    let updateLayout = false;

    const { data, layout } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(props.data)) {
      updateData = true;
    }
    if (JSON.stringify(prevProps.layout) !== JSON.stringify(props.layout)) {
      updateLayout = true;
    }
    if (updateData && updateLayout) {
      this.setState({ data, layout });
    } else if (updateData) {
      this.setState({ data });
    } else if (updateLayout) {
      this.setState({ layout });
    }
  }

  updateState(data:any, layout:any, frames:any, currentMockIndex:any) {
    this.setState({
      data,
      layout,
      frames,
      currentMockIndex,
      full: 'hit refresh',
      jsonError: false,
      jsonString: JSON.stringify({ data, layout, frames }, null, 2),
    });
  }

  render() {
    const {
      id,
      setPlotInstanceHandler,
      dataSources,
      dataSourceOptions,
      type,
      mode,
      showBorder,
      layout: propsLayout,
      showYAxisBreaks,
      showXAxisBreaks,
      graphIndex,
      axisBreakThresholdFactor,
      onDeselect,
      onSelected,
      isOneGraph,
      reportActor,
    } = this.props;
    const { layout } = this.state;
    const {
      data,
      frames,
      // mocks,
      currentMockIndex,
      full,
      jsonError,
      jsonString,
    } = this.state;
    data.forEach((element: any) => {
      element.text = [];
      element.hovertemplate = '%{y}';
    });
    return (
      <div className="app mt-10">
        <pre className="d-none">
          {/* {mocks} */}
          {currentMockIndex}
          {full}
          {jsonError}
          {jsonString}
        </pre>
        <PlotlyEditor
          reportActor={reportActor}
          id={id}
          isOneGraph={isOneGraph}
          showYAxisBreaks={showYAxisBreaks}
          showXAxisBreaks={showXAxisBreaks}
          showBorder={showBorder}
          setPlotInstanceHandler={setPlotInstanceHandler}
          dataSources={dataSources}
          dataSourceOptions={dataSourceOptions}
          makeDefaultTrace={() => ({ type, mode })}
          data={data}
          layout={layout}
          frames={frames}
          config={_.cloneDeep(config)}
          plotly={plotly}
          onUpdate={this.updateState}
          divId="gd"
          useResizeHandler
          debug
          advancedTraceTypeSelector
          showFieldTooltips
          onSelected={onSelected}
          onDeselect={onDeselect}
          axisBreakThresholdFactor={axisBreakThresholdFactor}
        />

      </div>
    );
  }
}

export default PlotlyChartDesigner;
