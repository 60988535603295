import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { ScrollView } from 'devextreme-react';
import { XYPoint } from '../../wafer-map/web-gl-utils/Types';
import { WaferDraggableControlTab } from './WaferDraggableControlTab';
import WaferMainControls, { WaferMainControlsProps } from './WaferMainControls';
import { WaferAdditionalControl } from '../WaferPlotter/WaferPlotter';
import { UnpinnableWaferMainControls } from './UnpinnableWaferMainControls';
import { FeatureSpecificWaferTag, FlipAxis } from '../../wafer-map/web-gl-utils/Enums';

type WaferDraggableControlsProps = {
  controlsPosition: XYPoint,
  onDrag: (event: any, data: any) => void,
  controlsWidgetEventKey: string,
  onChangeWidgetAccordian: (eventKey: string) => void,
  activeWaferKeyIndex: string,
  onChangeWaferControl: (e: any) => void,
  waferMainControlsProps: WaferMainControlsProps,
  additionalControls?: WaferAdditionalControl[],
  onChangeDieTextField: (value: string, keyIndex: string) => void,
  dieTextField: string,
  isDieCoordinatesSystemEnabled: boolean;
  onChangeDieCoordinateSystemValue: (value: boolean, keyIndex: string) => void,
  isReticleCoordinatesSystemEnabled: boolean;
  onChangeReticleCoordinateSystemValue: (value: boolean, keyIndex: string) => void,
  dieTextFieldOptions: string[][],
  width: number,
  connectedWaferKeys: string[],
  waferList: { waferKey: string, name: string }[],
  syncConnectWafersDropDownDataGridSelection: (e: any) => void,
  connectWafersDataGridOnSelectionChanged: (e: any) => void,
  noOfWafers: number,
  featureSpecificWaferTags: FeatureSpecificWaferTag[],
  hasFlippingControls: boolean,
  isXAxisFlipped: boolean,
  isYAxisFlipped: boolean,
  flipWaferMap: (value: boolean, axis: FlipAxis, keyIndex: string) => void;
};

export const WaferDraggableControls = (props: WaferDraggableControlsProps) => {
  const {
    controlsPosition, onDrag, controlsWidgetEventKey, onChangeWidgetAccordian, dieTextField, onChangeDieTextField, onChangeDieCoordinateSystemValue,
    activeWaferKeyIndex, waferMainControlsProps, additionalControls, isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled, noOfWafers,
    onChangeReticleCoordinateSystemValue, dieTextFieldOptions, width, syncConnectWafersDropDownDataGridSelection, connectWafersDataGridOnSelectionChanged, connectedWaferKeys,
    waferList, featureSpecificWaferTags, isYAxisFlipped, isXAxisFlipped, hasFlippingControls, flipWaferMap,
  } = props;

  return (
    <Draggable
      handle=".waferControlDragHandle"
      position={controlsPosition}
      axis="both"
      onDrag={onDrag}
    >
      <div style={{
        zIndex: 2,
        position: 'relative',
        height: '150px',
        width: `${width}px`,
      }}
      >
        <div
          style={{
            height: '20px', backgroundColor: '#ccc', cursor: 'move', textAlign: 'center',
          }}
          className="waferControlDragHandle"
        />
        <Accordion
          activeKey={controlsWidgetEventKey}
        >
          <WaferDraggableControlTab
            onChangeWidgetAccordian={onChangeWidgetAccordian}
            controlProps={{
              eventKey: '0',
              title: (
                <h6>
                  <FontAwesomeIcon
                    icon={faTools}
                    className="mr5"
                  />
                  Main Controls
                </h6>
              ),
              body: (
                <ScrollView>
                  <div
                    className="wafer-actions d-flex align-items-center justify-content-between grid-container"
                    style={{
                      width: '150px',
                      zIndex: 100,
                      position: 'relative',
                      height: '150px',
                    }}
                  >
                    <WaferMainControls
                      featureSpecificWaferTags={waferMainControlsProps.featureSpecificWaferTags}
                      waferStateStacks={waferMainControlsProps.waferStateStacks}
                      onToggleRedoPopover={waferMainControlsProps.onToggleRedoPopover}
                      showRedoPopover={waferMainControlsProps.showRedoPopover}
                      actionHandler={waferMainControlsProps.actionHandler}
                      onToggleUndoPopover={waferMainControlsProps.onToggleUndoPopover}
                      showUndoPopover={waferMainControlsProps.showUndoPopover}
                      dragChangeHandler={waferMainControlsProps.dragChangeHandler}
                      zoomChangeHandler={waferMainControlsProps.zoomChangeHandler}
                      hasMarkingFeature={waferMainControlsProps.hasMarkingFeature}
                      hasRotationControls={waferMainControlsProps.hasRotationControls}
                      isBoxZoomControlActive={waferMainControlsProps.isBoxZoomControlActive}
                      isDragControlActive={waferMainControlsProps.isDragControlActive}
                      isSelectionControlActive={waferMainControlsProps.isSelectionControlActive}
                      isMarkingControlActive={waferMainControlsProps.isMarkingControlActive}
                      markingMode={waferMainControlsProps.markingMode}
                      keyIndex={waferMainControlsProps.keyIndex}
                      selectionChangeHandler={waferMainControlsProps.selectionChangeHandler}
                      rotateWaferMap={waferMainControlsProps.rotateWaferMap}
                      resetWaferZoomPositionRotation={waferMainControlsProps.resetWaferZoomPositionRotation}
                      zoomWaferMapUsingButtons={waferMainControlsProps.zoomWaferMapUsingButtons}
                      clearSelectionHandler={waferMainControlsProps.clearSelectionHandler}
                      showRadar={waferMainControlsProps.showRadar}
                      showRadarHandler={waferMainControlsProps.showRadarHandler}
                    />
                  </div>
                  <UnpinnableWaferMainControls
                    flipWaferMap={flipWaferMap}
                    hasFlippingControls={hasFlippingControls}
                    isXAxisFlipped={isXAxisFlipped}
                    isYAxisFlipped={isYAxisFlipped}
                    onChangeDieTextField={onChangeDieTextField}
                    dieTextField={dieTextField}
                    isDieCoordinatesSystemEnabled={isDieCoordinatesSystemEnabled}
                    onChangeDieCoordinateSystemValue={onChangeDieCoordinateSystemValue}
                    isReticleCoordinatesSystemEnabled={isReticleCoordinatesSystemEnabled}
                    onChangeReticleCoordinateSystemValue={onChangeReticleCoordinateSystemValue}
                    dieTextFieldOptions={dieTextFieldOptions}
                    keyIndex={waferMainControlsProps.keyIndex}
                    waferList={waferList}
                    connectedWaferKeys={connectedWaferKeys}
                    syncConnectWafersDropDownDataGridSelection={syncConnectWafersDropDownDataGridSelection}
                    connectWafersDataGridOnSelectionChanged={connectWafersDataGridOnSelectionChanged}
                    noOfWafers={noOfWafers}
                    featureSpecificWaferTags={featureSpecificWaferTags}
                  />
                </ScrollView>
              ),
            }}
          />
          { additionalControls?.map((control: WaferAdditionalControl) => {
            return (
              <WaferDraggableControlTab
                controlProps={control}
                onChangeWidgetAccordian={onChangeWidgetAccordian}
                key={`${activeWaferKeyIndex}ac${control.eventKey}`}
              />
            );
          })}
        </Accordion>
      </div>
    </Draggable>
  );
};
