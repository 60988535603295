import React from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { faMinus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import getDropDownOptions from 'components/getDropDownOptions';
import Button from '../../../wrapped-component/hint-controls/Button';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import Label from '../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import Checkbox from '../../../wrapped-component/hint-controls/Checkbox';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { addErrorClasses } from '../../../template-component/form-error-label/ErrorLabel';
import {
  DefaultPolicyStepSettings, IDatabase, ILoaderLoadPolicyStepSettings, IPolicyStep, ISourceLocation,
} from '../../../../interfaces';
import Hint from '../../../wrapped-component/hint-controls/Hint/Hint';
import CodeEditor from '../../../utility-component/code-editor/CodeEditor';
import CustomizedMultipleDropdown from '../../../wrapped-component/customized-multiple-dropdown/CustomizedMultipleDropdown';
import LocationType from './LocationType';
import GeneralUtils from '../../../../GeneralUtils';
import DatabaseDropdownGrid from 'components/database-component/DatabaseDropdownGrid';

interface ILoaderPolicyLoadStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  stepSettings?: any;
  updatedCreated: any;
  policySteps: IPolicyStep[];
}

class LoaderLoadPolicyStepSettings extends React.Component<ILoaderPolicyLoadStepSettingsProps, any> {
  private policyStepSettingsReference: any;

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  constructor(props: ILoaderPolicyLoadStepSettingsProps) {
    super(props);
    const { stepSettings } = this.props;

    const databases : IDatabase[] = JSON.parse(GeneralUtils.getCookieValue('USER_DETAILS')).databases.map((x: IDatabase) => [x.id, x.name, x.type]);
    let loaderPolicyStepSettings: ILoaderLoadPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.Load));
    if (stepSettings) {
      loaderPolicyStepSettings = stepSettings;
    }
    this.state = {
      loaderPolicyStepSettings,
      databases,
    };
    this.policyStepSettingsReference = React.createRef();
    this.renderPrePostConditions = this.renderPrePostConditions.bind(this);
  }

  updateSourceLocation = (sourceLocation: ISourceLocation) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    loaderPolicyStepSettings.sourceLocation = sourceLocation;
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  conditionUpdateHandler = (value: string, flag: string, index: any, key: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions[index][key] = value;
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions[index][key] = value;
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  deleteCondition = (index: any, flag: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions.splice(index, 1);
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions.splice(index, 1);
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  renderPrePostConditions = (conditions: any, flag: any) => {
    return conditions?.map((condition: any, index: any) => (
      <Label
        labelTitle={`${index + 1}.`}
        labelPosition="left-middle"
        labelSize="10"
        fieldSize="90"
        errorSize="0"
        childrenItemsInline
        className={`d-flex ${index > 0 ? 'border-top pt10' : ''}`}
      >
        <div className="w-100 flex-100 d-flex align-items-center">
          <div className="w-100 flex-80 flex-column">
            <div
              className="mr10 custom-form-inline label-left"
            >
              <span className="label mr10 w30">If</span>
              <Textbox
                containerClassName="w-80"
                defaultValue={condition.condition}
                value={condition.condition}
                className="w-100"
                autoComplete="off"
                name="if"
                placeholder="Condition"
                onBlur={(event:any) => {
                  this.conditionUpdateHandler(event.target.value, flag, index, 'condition');
                }}
              />
            </div>

            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Then</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.thenClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'thenClause');
                }}
              />
            </div>
            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Else</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.elseClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'elseClause');
                }}
              />
            </div>
          </div>

          <div className="flex-20 mt10">
            <Button
              variant="outline-primary"
              className="w40"
              onClick={() => this.deleteCondition(index, flag)}
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
          </div>
        </div>
      </Label>
    ));
  };

  convertToIDatabaseObject(arr: string[][]): {id: string, name: string, type: string}[] {
    return arr.map((item) => {
        return {id: item[0], name: item[1], type: item[2]};
    });
}

  render() {
    const {
      loaderPolicyStepSettings,
      databases,
    } = this.state;
    const {
      updatedCreated,
      updatePolicyStepSettings,
      policySteps,
    } = this.props;
    const formValidationSchema = Yup.object({
      stepOwner: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
      additionalEmail: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
    });
    return (
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          stepOwner: '',
          additionalEmail: '',
          loaderPolicyStepSettings,
          updatedCreated,
        }}
        validationSchema={formValidationSchema}
        onSubmit={() => undefined}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          touched,
        }) => (
          <form
            onChange={handleChange}
            onSubmit={handleSubmit}
            ref={this.policyStepSettingsReference}
            className="custom-form h-100"
          >
            <div className="step-settings-block indent p20">
              <Container fluid className="h-100 overflow-visible">
                <Row>
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Location</Heading>
                    <LocationType
                      updateSourceLocation={this.updateSourceLocation}
                      policySteps={policySteps}
                      sourceLocation={loaderPolicyStepSettings.sourceLocation}
                      errors={errors}
                      touched={touched}
                    />
                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Target Database</span>
                      <div className="flex-70">
                        <DatabaseDropdownGrid
                        list={this.convertToIDatabaseObject(databases)}
                        placeholder="Choose Database"
                        defaultDatabaseId = {loaderPolicyStepSettings.targetDatabase}
                        onChange={(value:string) => {
                          loaderPolicyStepSettings.targetDatabase = value;
                          this.setState({ loaderPolicyStepSettings });
                          updatePolicyStepSettings(loaderPolicyStepSettings);
                        }}
                        remainExpanded={false}
                      />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>Summary Records</Heading>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.readHbr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.readHbr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Read Hard Bin Record
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.readSbr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.readSbr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Read Soft Bin Record
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.readTsr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.readTsr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Read Test Synopsis Record
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.generateHbr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.generateHbr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Generate Hard Bin Record (if not read/found)
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.generateSbr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.generateSbr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Generate Soft Bin Record (if not read/found)
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.generateTsr}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.generateTsr = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Generate Test Synopsis Record (if not read/found)
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.generateMergeProbeSummariesManually}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.generateMergeProbeSummariesManually = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Generate Merge Wafer Test Summary Manually
                    </Checkbox>
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right">
                    <Heading size={6}>Die Records</Heading>
                    <div className="d-flex">
                      <div className="flex-30 mt10">
                        <Checkbox
                          defaultChecked={loaderPolicyStepSettings.readPir}
                          onChange={(value: any) => {
                            loaderPolicyStepSettings.readPir = value.target.checked;
                            this.setState({ loaderPolicyStepSettings });
                            updatePolicyStepSettings(loaderPolicyStepSettings);
                          }}
                        >
                          Read PIR/PRR
                        </Checkbox>
                      </div>
                      {loaderPolicyStepSettings.readPir && (
                        <div className="d-flex flex-70">
                          <div className="flex-90">
                            <CodeEditor
                              rows={1}
                              placeHolder="Ignore Dies expression"
                              defaultValue={loaderPolicyStepSettings.ignorePirExpression}
                              value={loaderPolicyStepSettings.ignorePirExpression}
                              matchingCriterion={[
                                { regex: /^$/, values: { type: 'list', values: ['pir', 'prr'] } },
                                { regex: /pir\.$/i, values: { type: 'list', values: ['HeadNumber', 'SiteNumber'] } },
                                { regex: /pir\.$/i, values: { type: 'list', values: ['XCoordinate', 'YCoordinate', 'HardBin', 'SoftBin'] } },
                              ]}
                              updateOnBlur={(value: any) => {
                                loaderPolicyStepSettings.ignorePirExpression = value;
                                this.setState(loaderPolicyStepSettings);
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                          <Hint
                            className="mt10"
                            hint={{
                              message: 'Specify custom expression like prr.X == 1',
                            }}
                            variant="icon"
                          />
                        </div>
                      )}
                    </div>
                    {loaderPolicyStepSettings.readPir && (
                      <div>
                        <div className="d-flex">
                          <div className="flex-30 mt10">
                            <Checkbox
                              defaultChecked={loaderPolicyStepSettings.readPtr}
                              onChange={(e: any) => {
                                loaderPolicyStepSettings.readPtr = e.target.checked;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            >
                              Read PTR
                            </Checkbox>
                          </div>
                          {loaderPolicyStepSettings.readPtr && (
                            <div className="d-flex flex-70">
                              <div className="flex-90">
                                <CodeEditor
                                  rows={1}
                                  placeHolder="Ignore PTR expression"
                                  defaultValue={loaderPolicyStepSettings.ignorePtrExpression}
                                  value={loaderPolicyStepSettings.ignorePtrExpression}
                                  matchingCriterion={[
                                    { regex: /^$/, values: { type: 'list', values: ['ptr'] } },
                                    { regex: /ptr\.$/i, values: { type: 'list', values: ['HeadNumber', 'SiteNumber', 'TestNumber', 'TestText', 'Result'] } },
                                  ]}
                                  updateOnBlur={(value: any) => {
                                    loaderPolicyStepSettings.ignorePtrExpression = value;
                                    this.setState(loaderPolicyStepSettings);
                                    updatePolicyStepSettings(loaderPolicyStepSettings);
                                  }}
                                />
                              </div>
                              <Hint
                                className="mt10"
                                hint={{
                                  message: 'Specify custom expression like ptr.TestNumber == 1',
                                }}
                                variant="icon"
                              />
                            </div>
                          )}
                        </div>
                        {loaderPolicyStepSettings.readPtr && (
                          <div className="d-flex">
                            <span className="label flex-30 mt10">
                              PTR/TSR record name
                            </span>
                            <div className="d-flex flex-70">
                              <div className="flex-90">
                                <CodeEditor
                                  rows={1}
                                  placeHolder="Expression"
                                  defaultValue={loaderPolicyStepSettings.ptrNameExpression}
                                  value={loaderPolicyStepSettings.ptrNameExpression}
                                  matchingCriterion={[
                                    { regex: /^$/, values: { type: 'list', values: ['testName'] } },
                                    { regex: /testName\.$/i, values: { type: 'list', values: ['Split("_")', 'Substring(0, 4)'] } },
                                  ]}
                                  updateOnBlur={(value: any) => {
                                    loaderPolicyStepSettings.ptrNameExpression = value;
                                    this.setState(loaderPolicyStepSettings);
                                    updatePolicyStepSettings(loaderPolicyStepSettings);
                                  }}
                                />
                              </div>
                              <Hint
                                className="mt10"
                                hint={{
                                  message: 'Specify custom expression like ptr.TestText.Split(" ")[0]',
                                }}
                                variant="icon"
                              />
                            </div>
                          </div>
                        )}
                        <div className="d-flex">
                          <div className="flex-30 mt10">
                            <Checkbox
                              defaultChecked={loaderPolicyStepSettings.readFtr}
                              onChange={(e: any) => {
                                loaderPolicyStepSettings.readFtr = e.target.checked;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            >
                              Read FTR
                            </Checkbox>
                          </div>
                          {loaderPolicyStepSettings.readFtr && (
                            <div className="d-flex flex-70">
                              <div className="flex-90">
                                <CodeEditor
                                  rows={1}
                                  placeHolder="Ignore FTR expression"
                                  defaultValue={loaderPolicyStepSettings.ignoreFtrExpression}
                                  value={loaderPolicyStepSettings.ignoreFtrExpression}
                                  matchingCriterion={[
                                    { regex: /^$/, values: { type: 'list', values: ['ftr'] } },
                                    { regex: /ftr\.$/i, values: { type: 'list', values: ['HeadNumber', 'SiteNumber', 'TestNumber', 'TestText', 'ResultText'] } },
                                  ]}
                                  updateOnBlur={(value: any) => {
                                    loaderPolicyStepSettings.ignoreFtrExpression = value;
                                    this.setState(loaderPolicyStepSettings);
                                    updatePolicyStepSettings(loaderPolicyStepSettings);
                                  }}
                                />
                              </div>
                              <Hint
                                className="mt10"
                                hint={{
                                  message: 'Specify custom expression like ftr.TestNumber == 1',
                                }}
                                variant="icon"
                              />
                            </div>
                          )}
                        </div>
                        {loaderPolicyStepSettings.readFtr && (
                          <div className="d-flex">
                            <span className="label flex-30 mt10">
                              FTR record name
                            </span>
                            <div className="d-flex flex-70">
                              <div className="flex-90">
                                <CodeEditor
                                  rows={1}
                                  placeHolder="Expression"
                                  defaultValue={loaderPolicyStepSettings.ftrNameExpression}
                                  value={loaderPolicyStepSettings.ftrNameExpression}
                                  matchingCriterion={[
                                    { regex: /^$/, values: { type: 'list', values: ['ftr'] } },
                                    { regex: /ftr\.$/i, values: { type: 'list', values: ['HeadNumber', 'SiteNumber', 'TestNumber', 'TestText', 'ResultText'] } },
                                  ]}
                                  updateOnBlur={(value: any) => {
                                    loaderPolicyStepSettings.ftrNameExpression = value;
                                    this.setState(loaderPolicyStepSettings);
                                    updatePolicyStepSettings(loaderPolicyStepSettings);
                                  }}
                                />
                              </div>
                              <Hint
                                className="mt10"
                                hint={{
                                  message: 'Specify custom expression like $"{ftr.TestText}_{ftr.VectorName}"',
                                }}
                                variant="icon"
                              />
                            </div>
                          </div>
                        )}
                        <div className="d-flex">
                          <div className="flex-30 mt10">
                            <Checkbox
                              defaultChecked={loaderPolicyStepSettings.readMpr}
                              onChange={(e: any) => {
                                loaderPolicyStepSettings.readMpr = e.target.checked;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            >
                              Read MPR
                            </Checkbox>
                          </div>
                          {loaderPolicyStepSettings.readMpr && (
                            <div className="d-flex flex-70">
                              <div className="flex-90">
                                <CodeEditor
                                  rows={1}
                                  placeHolder="Ignore MPR expression"
                                  defaultValue={loaderPolicyStepSettings.ignoreMprExpression}
                                  value={loaderPolicyStepSettings.ignoreMprExpression}
                                  matchingCriterion={[
                                    { regex: /^$/, values: { type: 'list', values: ['ftr'] } },
                                    { regex: /ftr\.$/i, values: { type: 'list', values: ['HeadNumber', 'SiteNumber', 'TestNumber', 'TestText', 'Results'] } },
                                  ]}
                                  updateOnBlur={(value: any) => {
                                    loaderPolicyStepSettings.ignoreMprExpression = value;
                                    this.setState(loaderPolicyStepSettings);
                                    updatePolicyStepSettings(loaderPolicyStepSettings);
                                  }}
                                />
                              </div>
                              <Hint
                                className="mt10"
                                hint={{
                                  message: 'Specify custom expression like mpr.TestNumber == 1',
                                }}
                                variant="icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>Miscellaneous</Heading>

                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.failLoadOnNoScreenType}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.failLoadOnNoScreenType = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Fail load step if no screen type mapping exists
                    </Checkbox>

                    <Checkbox
                      disabled
                      defaultChecked={loaderPolicyStepSettings.serialTesting}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.serialTesting = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Serial Testing
                    </Checkbox>
                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.deleteFromSource}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.deleteFromSource = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Delete Once Successfully Loaded
                    </Checkbox>
                    <div className="d-flex">
                      <div className="flex-50">
                        <Checkbox
                          defaultChecked={loaderPolicyStepSettings.applyDataScaling}
                          onChange={(e: any) => {
                            loaderPolicyStepSettings.applyDataScaling = e.target.checked;
                            this.setState({ loaderPolicyStepSettings });
                            updatePolicyStepSettings(loaderPolicyStepSettings);
                          }}
                        >
                          Apply Data Scaling
                        </Checkbox>
                      </div>
                      {loaderPolicyStepSettings.applyDataScaling && (
                        <Checkbox
                          defaultChecked={loaderPolicyStepSettings.errorScaleMatch}
                          onChange={(e: any) => {
                            loaderPolicyStepSettings.errorScaleMatch = e.target.checked;
                            this.setState({ loaderPolicyStepSettings });
                            updatePolicyStepSettings(loaderPolicyStepSettings);
                          }}
                        >
                          Error out if scale doesn&#39;t match existing test programs scale
                        </Checkbox>
                      )}
                    </div>
                    <Checkbox
                      defaultChecked={!loaderPolicyStepSettings.disableRevision}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.disableRevision = !e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Enable Yieldwerx Internal Test Program revisioning
                    </Checkbox>

                    <Checkbox
                      defaultChecked={loaderPolicyStepSettings.applyDeviceSetup}
                      onChange={(e: any) => {
                        loaderPolicyStepSettings.applyDeviceSetup = e.target.checked;
                        this.setState({ loaderPolicyStepSettings });
                        updatePolicyStepSettings(loaderPolicyStepSettings);
                      }}
                    >
                      Apply device setups
                    </Checkbox>

                    {loaderPolicyStepSettings.applyDeviceSetup ? (
                      <Checkbox
                        defaultChecked={loaderPolicyStepSettings.applyWcmEnrichment}
                        onChange={(e: any) => {
                          loaderPolicyStepSettings.applyWcmEnrichment = e.target.checked;
                          this.setState({ loaderPolicyStepSettings });
                          updatePolicyStepSettings(loaderPolicyStepSettings);
                        }}
                      >
                        Apply WCM Enrichment
                      </Checkbox>
                    ) : null}

                    {(loaderPolicyStepSettings.applyDeviceSetup)
                      ? (
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Bin Plus Matching Criteria </span>
                          <div className="flex-70">
                            <CustomizedMultipleDropdown
                              list={[
                                { key: 'Number', value: 'Number' },
                                { key: 'Name', value: 'Name' },
                              ]}
                              placeholder="Select matching criteria"
                              selectedRowKeys={loaderPolicyStepSettings.binPlusCriteria.map(
                                (value:string) => ({ key: value, value }),
                              )}
                              selectionHandler={(e: any) => {
                                loaderPolicyStepSettings.binPlusCriteria = e.map(
                                  (x: any) => x.key,
                                );
                                if (
                                  loaderPolicyStepSettings.binPlusCriteria
                                    .length === 1 && loaderPolicyStepSettings.binPlusCriteria.includes(
                                    'Name',
                                  )
                                ) {
                                  toast.warning(
                                    'Bin Plus cannot have only "Name" matching criteria. It should be either "Number" or "Name and Number" both',
                                  );
                                  return;
                                }
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                    {(loaderPolicyStepSettings.applyDeviceSetup)
                      ? (
                        <div className="custom-form-inline label-left">
                          <span className="label flex-30">Bin Plus Result (If raw data bin information is not found in any bin plus table)</span>
                          <div className="flex-70">
                            <CustomizedDropdown
                              full
                              variant="clear"
                              containerClassName="bin-plus-result"
                              defaultValue={loaderPolicyStepSettings.binPlusResult}
                              selectedValue={loaderPolicyStepSettings.binPlusResult}
                              list={[['LOAD_AND_SHOW_WARNING', 'Load the file successfully and show a warning message in data intake queue'],
                                ['DONT_LOAD_AND_FAIL', 'Fail the load stage and show a failure message in data intake queue']]}
                              onChange={(value) => {
                                loaderPolicyStepSettings.binPlusResult = value;
                                this.setState({ loaderPolicyStepSettings });
                                updatePolicyStepSettings(loaderPolicyStepSettings);
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Test Parameter Uniqueness Criteria</span>

                      <div className="flex-65">
                        <CustomizedMultipleDropdown
                          list={[
                            { key: 'Number', value: 'Number' },
                            { key: 'Name', value: 'Name' },
                            { key: 'LowCtrlLimit', value: 'Low Ctrl Limit' },
                            { key: 'HighCtrlLimit', value: 'High Ctrl Limit' },
                            { key: 'LowSpecLimit', value: 'Low Spec Limit' },
                            { key: 'HighSpecLimit', value: 'High Spec Limit' },
                            { key: 'TestUnit', value: 'Test Unit' },
                          ]}
                          placeholder="Select matching criteria"
                          selectedRowKeys={loaderPolicyStepSettings.testParamUniqueCriteria.map(
                            (value:string) => ({ key: value, value }),
                          )}
                          selectionHandler={(e:any) => {
                            loaderPolicyStepSettings.testParamUniqueCriteria = e.map((x: any) => x.key);
                            this.setState({ loaderPolicyStepSettings });
                            updatePolicyStepSettings(loaderPolicyStepSettings);
                            if (!loaderPolicyStepSettings.testParamUniqueCriteria.includes('Name')
                            && !loaderPolicyStepSettings.testParamUniqueCriteria.includes('Number')) {
                              toast.warning('Test Parameter uniqueness criteria must include at least "Name" or "Number" option');
                            }
                          }}
                          dropDownOptions={getDropDownOptions('div')}
                        />
                      </div>
                      <div className="flex-5">
                        <Hint
                          className="align-items-center pt5"
                          hint={{ message: 'If no option is selected from "Test Parameter Uniqueness Criteria" dropdown, then all the options would be considered for applying uniqueness criteria.' }}
                          variant="icon"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="mt20"> */}
                {/*  <Col lg={6} className="border-right"> */}
                {/*    <Heading size={6}>Failure Location</Heading> */}
                {/*    <Checkbox defaultChecked={copyOnFail} onChange={(e: any) => this.setState({ copyOnFail: e.target.checked })}> */}
                {/*      Copy ATDF File to Specific location if file processing fails */}
                {/*    </Checkbox> */}
                {/*    {copyOnFail && ( */}
                {/*      <div> */}
                {/*        <div className="custom-form-inline label-left"> */}
                {/*          <span className="label flex-30">Location Type</span> */}
                {/*          <div className="flex-70 d-flex"> */}
                {/*            <div className="flex-90 mr5"> */}
                {/*              <CustomizedDropdown */}
                {/*                full */}
                {/*                variant="clear" */}
                {/*                selectedValue={targetLocationType} */}
                {/*                list={[['S3', 'Cloud Storage'], ['ftp', 'ftp'], ['Local', 'Local']]} */}
                {/*                onChange={((value) => this.setState({ targetLocationType: value }))} */}
                {/*              /> */}
                {/*            </div> */}
                {/*            <Button */}
                {/*              variant="outline-warning" */}
                {/*              className="w40" */}
                {/*              title="Check if location is accessible" */}
                {/*            > */}
                {/*              <FontAwesomeIcon className="mt-10" size="lg" icon={faCheck} /> */}
                {/*            </Button> */}
                {/*          </div> */}
                {/*        </div> */}
                {/*        {targetLocationType === 'S3' && ( */}
                {/*          <div className="custom-form-inline label-left"> */}
                {/*            <span className="label flex-30">Source Container</span> */}
                {/*            <div className="flex-70"> */}
                {/*              <Textbox */}
                {/*                autoComplete="off" */}
                {/*                name="additionalEmail" */}
                {/*                placeholder="yw-test-files" */}
                {/*                className={`${addErrorClasses( */}
                {/*                  errors.additionalEmail, */}
                {/*                  touched.additionalEmail, */}
                {/*                )}`} */}
                {/*              /> */}
                {/*            </div> */}
                {/*          </div> */}
                {/*        )} */}
                {/*        {targetLocationType === 'ftp' && ( */}
                {/*          <div> */}
                {/*            <div className="custom-form-inline label-left"> */}
                {/*              <span className="label flex-30">Ftp Address</span> */}
                {/*              <div className="flex-70"> */}
                {/*                <Textbox */}
                {/*                  autoComplete="off" */}
                {/*                  name="additionalEmail" */}
                {/*                  placeholder="ftp://yieldwerx.com" */}
                {/*                  className={`${addErrorClasses( */}
                {/*                    errors.additionalEmail, */}
                {/*                    touched.additionalEmail, */}
                {/*                  )}`} */}
                {/*                /> */}
                {/*              </div> */}
                {/*            </div> */}
                {/*            <div className="custom-form-inline label-left"> */}
                {/*              <span className="label flex-30">Ftp Username</span> */}
                {/*              <div className="flex-70"> */}
                {/*                <Textbox */}
                {/*                  autoComplete="off" */}
                {/*                  name="additionalEmail" */}
                {/*                  placeholder="yw-user" */}
                {/*                  className={`${addErrorClasses( */}
                {/*                    errors.additionalEmail, */}
                {/*                    touched.additionalEmail, */}
                {/*                  )}`} */}
                {/*                /> */}
                {/*              </div> */}
                {/*            </div> */}
                {/*            <div className="custom-form-inline label-left"> */}
                {/*              <span className="label flex-30">Ftp Password</span> */}
                {/*              <div className="flex-70"> */}
                {/*                <Textbox */}
                {/*                  type="password" */}
                {/*                  autoComplete="off" */}
                {/*                  name="additionalEmail" */}
                {/*                  placeholder="yw-password" */}
                {/*                  className={`${addErrorClasses( */}
                {/*                    errors.additionalEmail, */}
                {/*                    touched.additionalEmail, */}
                {/*                  )}`} */}
                {/*                /> */}
                {/*              </div> */}
                {/*            </div> */}
                {/*            <div className="custom-form-inline label-left"> */}
                {/*              <span className="label flex-30">Folder to transfer files from</span> */}
                {/*              <div className="flex-70"> */}
                {/*                <Textbox */}
                {/*                  autoComplete="off" */}
                {/*                  name="additionalEmail" */}
                {/*                  placeholder="/files" */}
                {/*                  className={`${addErrorClasses( */}
                {/*                    errors.additionalEmail, */}
                {/*                    touched.additionalEmail, */}
                {/*                  )}`} */}
                {/*                /> */}
                {/*              </div> */}
                {/*            </div> */}
                {/*          </div> */}
                {/*        )} */}
                {/*        {targetLocationType === 'Local' && ( */}
                {/*          <div className="custom-form-inline label-left"> */}
                {/*            <span className="label flex-30">Directory Path</span> */}
                {/*            <div className="flex-70 d-flex"> */}
                {/*              <div className="flex-90 mr10"> */}
                {/*                <Textbox */}
                {/*                  autoComplete="off" */}
                {/*                  name="additionalEmail" */}
                {/*                  placeholder="/files" */}
                {/*                  className={`${addErrorClasses( */}
                {/*                    errors.additionalEmail, */}
                {/*                    touched.additionalEmail, */}
                {/*                  )}`} */}
                {/*                /> */}
                {/*              </div> */}
                {/*              <Button */}
                {/*                type="submit" */}
                {/*                variant="primary" */}
                {/*                className="height-100percent" */}
                {/*                size="lg" */}
                {/*              > */}
                {/*                Browse */}
                {/*              </Button> */}
                {/*            </div> */}
                {/*          </div> */}
                {/*        )} */}
                {/*      </div> */}
                {/*    )} */}
                {/*  </Col> */}
                {/* </Row> */}
                <Row className="mt20">
                  <Col lg={6} className="border-right d-none">
                    <Heading size={6}>Owner</Heading>
                    <Label
                      labelTitle="Step Owner"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.stepOwner}
                      isFieldTouched={touched.stepOwner}
                    >
                      <Textbox
                        disabled
                        autoComplete="off"
                        name="stepOwner"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.stepOwner,
                          touched.stepOwner,
                        )}`}
                      />
                    </Label>
                  </Col>
                  <Col lg={6} className="d-none">
                    <Heading size={6}>Notifications</Heading>
                    <Label
                      labelTitle="Additional Emails"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.additionalEmail}
                      isFieldTouched={touched.additionalEmail}
                    >
                      <Textbox
                        disabled
                        autoComplete="off"
                        name="additionalEmail"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.additionalEmail,
                          touched.additionalEmail,
                        )}`}
                      />
                    </Label>
                    <Checkbox
                      disabled
                    >
                      Notify Owners
                    </Checkbox>
                  </Col>
                </Row>
              </Container>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default LoaderLoadPolicyStepSettings;
