/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import DataGrid, { Column, FilterRow, Selection } from 'devextreme-react/data-grid';
import WidgetCard from '../../../../components/template-component/widget-card/WidgetCard';
import { toTitleCase } from '../../../../GeneralUtils';

interface IBinWaferMapComparisonLegendProps {
  showSpinner: boolean;
  pinToggleCallback: any;
  reportIndex: any;
  legend: any;
  isSoftBin: boolean;
  waferCombinations: any;
  legendData:any;
  binChangeData: any;
  publishLegendEventCallback: (mode: any, reportType: any, action: any, isSecondaryLegend?: boolean) => void;
  setSelectedLegendRowsData: (data: any) => void;
}

class BinChangeLegend extends Component<IBinWaferMapComparisonLegendProps, any> {
  private legendDataForBinWaferMapComparison: any[] = [];

  private selectedLegendKeys: any[] = [];

  private hiddenRowIndexes: number[] = [];

  constructor(props: any) {
    super(props);
  }

  getLegendDataForBinWaferMapComparison = () => {
    const { isSoftBin } = this.props;
    this.getInteractionsDataFromLegendData();
    const waferCombinationsDetails: any[] = [];
    this.legendDataForBinWaferMapComparison.forEach((waferCombination: any, index: number) => {
      waferCombinationsDetails.push({
        baseId: waferCombination.baseWafer.id,
        baseWaferKey: waferCombination.baseWafer.key,
        baseWaferProbeCount: waferCombination.baseWafer.probeCount,
        nonBaseId: waferCombination.nonBaseWafer.id,
        nonBaseWaferKey: waferCombination.nonBaseWafer.key,
        nonBaseWaferProbeCount: waferCombination.nonBaseWafer.probeCount,
        binClassification: isSoftBin ? 'SOFT_BIN' : 'HARD_BIN',
        count: waferCombination.baseWafer.count,
        changePercent: `${waferCombination.baseWafer.percent}%`,
        binChange: `${waferCombination.baseWafer.binChange}`,
        PFStatus: waferCombination.baseWafer.flag,
        color: waferCombination.baseWafer.color,
        rowId: index,
        hidden: this.hiddenRowIndexes.includes(index) ? true : undefined,
      });
    });
    return waferCombinationsDetails;
  }

  renderColorInCell = (e: any) => {
    return <div className="center-align h20 w20" style={{ backgroundColor: e.data.color }} />;
  };

  getInteractionsDataFromLegendData = () => {
    const { legendData } = this.props;
    legendData.legend.forEach((data: any) => {
      this.getBinSummaryLegend(data);
    })
  }

  getBinSummaryLegend = (data: any) => {
    const {
      waferCombinations,
      binChangeData
    } = this.props;
    const binSummaryLegendDetails: any[] = [];
    let baseWaferData;
    let nonBaseWaferData;
    for (let index = 0; index < waferCombinations.length; index += 1) {
      for (let innerIndex = 0; innerIndex < binChangeData[index].length; innerIndex += 1) {
        const binChangeInfo: any = Object.values(binChangeData[index][innerIndex])[0];
        baseWaferData = {
          id: waferCombinations[index].baseWafer.id,
          key: waferCombinations[index].baseWafer.key,
          probeCount: waferCombinations[index].baseWafer.probeCount,
          binChange: Object.keys(binChangeData[index][innerIndex])[0],
          count: binChangeInfo[0],
          flag: binChangeInfo[1],
          percent: ((binChangeInfo[0] / waferCombinations[index].baseWafer.dieCount) * 100).toFixed(3),
          color: binChangeInfo[2],
          isSelected: binChangeInfo[3],
          hidden: binChangeInfo[4],
        }
        nonBaseWaferData = {
          id: waferCombinations[index].nonBaseWafer.id,
          key: waferCombinations[index].nonBaseWafer.key,
          probeCount: waferCombinations[index].nonBaseWafer.probeCount,
          binChange: Object.keys(binChangeData[index][innerIndex])[0],
          count: binChangeInfo[0],
          flag: binChangeInfo[1],
          percent: ((binChangeInfo[0] / waferCombinations[index].nonBaseWafer.dieCount) * 100).toFixed(3),
          color: binChangeInfo[2],
        }
        const waferDetails = {
          baseWafer: baseWaferData,
          nonBaseWafer: nonBaseWaferData,
        }
        binSummaryLegendDetails.push(waferDetails);
      }
    }
    this.legendDataForBinWaferMapComparison = binSummaryLegendDetails;
    this.selectedLegendKeys = binSummaryLegendDetails.map((x: any, index: number) => (x.baseWafer.isSelected === true ? index : -1)).filter((index: any) => index !== -1)
      .map((index: number) => index.toString());
    this.hiddenRowIndexes = binSummaryLegendDetails.map((x: any, index: number) => (x.baseWafer.hidden === true ? index : -1)).filter((index: any) => index !== -1);
  };

  renderBinWaferMapComparisonLegend = () => {
    const { legend, publishLegendEventCallback, setSelectedLegendRowsData } = this.props;
    const dataGridProps: any = {
      height: 'auto',
      showBorders: true,
      dataSource: this.getLegendDataForBinWaferMapComparison(),
      keyExpr: 'rowId',
      filterRow: {
        visible: true,
      },
      selection: {
        mode: 'multiple',
      },
      onRowPrepared: (e: any) => {
        if (e.rowType === 'data' && (e.data.hidden || e.data.hidden === 'true')) {
          e.rowElement.classList.add('row-disable');
        }
      },
      onContextMenuPreparing: (e: any) => {
        if (e.row && e.row.rowType === 'data') {
          e.items = [
            {
              text: 'Hide',
              visible: true,
              items: [
                {
                  text: 'Hide Selected',
                  onItemClick: () => {
                    publishLegendEventCallback('SELECTED', legend.hidingEventPublisher, 'HIDE_GRAPH_AND_LEGEND_ITEMS', true);
                  },
                },
                {
                  text: 'Hide All',
                  onItemClick: () => {
                    publishLegendEventCallback('ALL', legend.hidingEventPublisher, 'HIDE_GRAPH_AND_LEGEND_ITEMS', true);
                  },
                },
              ],
            },
            {
              text: 'Highlight',
              visible: true,
              items: [
                {
                  text: 'Highlight Selected',
                  onItemClick: () => {
                    publishLegendEventCallback('SELECTED', legend.selectionEventPublisher, 'SELECT_GRAPH_AND_LEGEND_ITEMS', true);
                  },
                },
                {
                  text: 'Highlight All',
                  onItemClick: () => {
                    publishLegendEventCallback('ALL', legend.selectionEventPublisher, 'SELECT_GRAPH_AND_LEGEND_ITEMS', true);
                  },
                },
              ],
            },
          ];
        }
      },
      onSelectionChanged: (e: any) => {
        setSelectedLegendRowsData(e.selectedRowsData);
      }
    };
    dataGridProps.defaultSelectedRowKeys = this.selectedLegendKeys;
    const columns: JSX.Element[] = [];
    columns.push(<Column
      alignment="left"
      caption="Color Band"
      dataField="Color Band"
      width={50}
      cellRender={(e: any) => { return this.renderColorInCell(e) }}
    />);
    columns.push(<Column alignment="left" caption="P/F Status" dataField="PFStatus" />);
    columns.push(<Column caption="Base Wafer">
      <Column alignment="left" caption="ID" dataField="baseWaferKey" />
      <Column alignment="left" caption="Probe Count" dataField="baseWaferProbeCount" />
                 </Column>);
    columns.push(<Column caption=" Non Base Wafer">
      <Column alignment="left" caption="ID" dataField="nonBaseWaferKey" />
      <Column alignment="left" caption="Probe Count" dataField="nonBaseWaferProbeCount" />
                 </Column>);
    columns.push(<Column alignment="left" caption="Bin Classification" dataField="binClassification" />);
    columns.push(<Column alignment="left" caption="Bin Change" dataField="binChange" />);
    columns.push(<Column alignment="left" caption="Count" dataField="count" />);
    columns.push(<Column alignment="left" caption="%" dataField="changePercent" />);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DataGrid {...dataGridProps}>{[...columns]}</DataGrid>
  }

  render() {
    const { showSpinner, pinToggleCallback, reportIndex } = this.props;
    return (
      <WidgetCard
        showSpinner={showSpinner}
        pinToggleCallback={pinToggleCallback}
        fullWidth
        linkToggle={false}
        expandToggle
        changeExpandedStateComponentOnUpdate={false}
        pinToggle
        pinWidgetByDefault={false}
        id={`legend_${reportIndex}`}
        widgetName={toTitleCase('Bin Change Legend Table')}
        primaryWidgetWeight={12}
        cardPaddingClass="p0 pl0"
        showBorder={false}
        primaryWidget={(
          <div className="w-100">
            {this.renderBinWaferMapComparisonLegend()}
          </div>
        )}
      />
    );
  }
}

export default BinChangeLegend;
