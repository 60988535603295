import React from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CodeEditor from 'components/utility-component/code-editor/CodeEditor';
import Button from '../../wrapped-component/hint-controls/Button';
import PageHeader from '../../template-component/page-header/PageHeader';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import AppConstants from '../../../constants.js';
import { addErrorClasses, ErrorLabel } from '../../template-component/form-error-label/ErrorLabel';
import { WaferReferences } from '../../../interfaces';
import { httpService } from '../../../services/http.service';
import Heading from '../../wrapped-component/hint-controls/Heading';
import Checkbox from '../../wrapped-component/hint-controls/Checkbox';
import ExpressionBuilder from '../../utility-component/expression-builder/ExpressionBuilder';
import './create-amg-rule.scss';

interface ICreateAMGRule {
  closeRuleComponent: () => void;
  id?: string;
  policyStepSequence?:number;
  getSavedRule?: (sequence:number, ruleId:string) => void;
}

class CreateAMGRule extends React.Component<ICreateAMGRule, any> {
  private defaultStatement = {
    condition: '',
    conditionError: undefined,
    thenStatements: [''],
    thenStatementErrors: [undefined],
    elseStatements: [''],
    elseStatementErrors: [undefined],
    thenFieldsData: WaferReferences[0][0],
    thenParametricData: [{ key: 'NONE', value: 'NONE' }], // ParametricReferences[0][0],
    elseFieldsData: WaferReferences[0][0],
    elseParametricData: [{ key: 'NONE', value: 'NONE' }], // ParametricReferences[0][0],
    shouldExitAfterThen: false,
    shouldExitAfterElse: false,
  };

  constructor(props:ICreateAMGRule) {
    super(props);
    this.renderConditionStatements = this.renderConditionStatements.bind(this);

    this.state = {
      editRule: {
        Id: '',
        name: '',
        shortDescription: '',
        description: '',
        version: 'v1.0',
      },
      statements: [],
      sourceProperties: this.getCodeEditorOptions(),
      thenSourceProperties: this.getCodeEditorOptions(),
    };
    this.getRule();
    // this.getCodeEditorOptions();
  }

  getCodeEditorOptions = () => {
    const dieFields = ['SoftBinNumber', 'IsPassing', 'HardBinNumber', 'Parameters', 'Facility', 'WorkCenter', 'Device', 'TestProgram', 'Assemble'];
    const options: any[] = [];
    options.push({ regex: /die\.$/i, values: { type: 'list', values: dieFields } });
    options.push(
      { regex: /[A-Za-z0-9]+\s$/i, values: { type: 'groupedList', values: [{ key: 'Operations', items: ['*', '==', '+'] }] } },
      { regex: /Math\.$/i, values: { type: 'list', values: ['Pow(value, power)', 'Sqrt(value)'] } },
      { regex: /Facility\.$/i, values: { type: 'list', values: ['StartsWith("str")'] } },
    );
    return options;
  };

  getRule = async () => {
    const { id } = this.props;
    const { editRule, statements } = this.state;
    if (id !== undefined) {
      const data = await httpService.getSWMRule(id);
      editRule.Id = data.id;
      editRule.name = data.name;
      editRule.shortDescription = data.shortDescription;
      editRule.description = data.description;
      editRule.version = data.version;
      editRule.createdBy = data.createdBy !== null ? `${data.createdBy.firstName} ${data.createdBy.lastName}` : '';
      editRule.createdOn = data.createdOn;
      editRule.updatedBy = data.updatedBy !== null ? `${data.updatedBy.firstName} ${data.createdBy.lastName}` : '';
      editRule.updatedOn = data.updatedOn;
      data.statements.sort((a: any, b: any) => a.sequence - b.sequence).forEach((x: any) => {
        const statement = JSON.parse(JSON.stringify(this.defaultStatement));
        statement.condition = x.condition;
        // //     conditionError: undefined,
        statement.thenStatements = x.thenStatements;
        statement.thenStatementErrors = x.thenStatements.map(() => undefined);
        statement.elseStatements = x.elseStatements;
        statement.elseStatementErrors = x.elseStatements.map(() => undefined);
        statement.shouldExitAfterThen = x.shouldExitAfterThen;
        statement.shouldExitAfterElse = x.shouldExitAfterElse;
        statements.push(statement);
      });
    } else {
      statements.push(JSON.parse(JSON.stringify(this.defaultStatement)));
    }
    this.setState({ editRule, statements });
  };

  renderConditionStatements = (conditionalStatements: any) => {
    const { sourceProperties, thenSourceProperties } = this.state;
    return conditionalStatements.map((conditionalStatement: any, index: any) => (
      <div className="border-radius-5">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className="label-left"
          htmlFor="if"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            if
          </span>
          <div className="flex-60 d-flex">
            <div className="flex-90 ml-7">
              <CodeEditor
                defaultValue={conditionalStatement.condition}
                error={conditionalStatement.conditionError}
                placeHolder="Rule Definition"
                matchingCriterion={sourceProperties}
                updateOnBlur={(value: string) => {
                  const { statements } = this.state;
                  statements[index].condition = value;
                  this.setState({ statements });
                }}
              />
            </div>
            <div className="flex-10">
              <Button
                variant="outline-warning"
                className="w43 flex-5 float-right"
                size="lg"
                onClick={async () => {
                  const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.condition });
                  if (!response.validSyntax) {
                    const { statements } = this.state;
                    statements[index].conditionError = response.message;
                    this.setState({ statements });
                  }
                }}
              >
                <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
              </Button>
            </div>
          </div>
        </label>
        <label
          className="label-left"
          htmlFor="then"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            then
          </span>
          <div className="flex-60">
            {
              conditionalStatement.thenStatements.map((thenStatement: string, statementIndex: any) => (
                <div className="d-flex mb5">
                  <div className="flex-90 ml-7">
                    <CodeEditor
                      rows={1}
                      error={conditionalStatement.thenStatementErrors[statementIndex]}
                      defaultValue={thenStatement}
                      placeHolder="Die then statement"
                      matchingCriterion={thenSourceProperties}
                      updateOnBlur={(value: string) => {
                        const { statements } = this.state;
                        statements[index].thenStatements[statementIndex] = value;
                        this.setState({ statements });
                      }}
                    />
                  </div>
                  <div className="flex-10">
                    <Button
                      variant="outline-warning"
                      className="w43 flex-5 float-right"
                      size="lg"
                      onClick={async () => {
                        const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.thenStatements[statementIndex] });
                        if (!response.validSyntax) {
                          const { statements } = this.state;
                          statements[index].thenStatementErrors[statementIndex] = response.message;
                          this.setState({ statements });
                        }
                      }}
                    >
                      <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
                    </Button>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex-20">
            <Button
              variant="outline-primary"
              className="w43 flex-5 float-right"
              size="lg"
              onClick={
                () => {
                  const { statements } = this.state;
                  statements[index].thenStatements.push('');
                  statements[index].thenStatementErrors.push(undefined);
                  this.setState({ statements });
                }
              }
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
            </Button>
          </div>
        </label>
        <div className="flex-100 d-flex">
          <div className="custom-form-inline label-left flex-80 ml-auto pl10">
            <div className="flex-10 mt5">
              <Checkbox
                defaultChecked={conditionalStatement.shouldExitAfterThen}
                onChange={(e:any) => {
                  const { statements } = this.state;
                  statements[index].shouldExitAfterThen = e.target.checked;
                  this.setState({ statements });
                }}
              >
                Exit
              </Checkbox>
            </div>
          </div>
        </div>
        <label
          className="label-left"
          htmlFor="else"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            else
          </span>
          <div className="flex-60">
            {
              conditionalStatement.elseStatements.map((elseStatement: string, statementIndex: any) => (
                <div className="d-flex mb5">
                  <div className="flex-90 ml-7">
                    <CodeEditor
                      rows={1}
                      defaultValue={elseStatement}
                      error={conditionalStatement.elseStatementErrors[statementIndex]}
                      placeHolder="Die else statement"
                      matchingCriterion={thenSourceProperties}
                      updateOnBlur={(value: string) => {
                        const { statements } = this.state;
                        statements[index].elseStatements[statementIndex] = value;
                        this.setState({ statements });
                      }}
                    />
                  </div>
                  <div className="flex-10">
                    <Button
                      variant="outline-warning"
                      className="w43 flex-5 float-right"
                      size="lg"
                      onClick={async () => {
                        const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.elseStatements[statementIndex] });
                        if (!response.validSyntax) {
                          const { statements } = this.state;
                          statements[index].elseStatementErrors[statementIndex] = response.message;
                          this.setState({ statements });
                        }
                      }}
                    >
                      <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
                    </Button>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex-20">
            <Button
              variant="outline-primary"
              className="w43 flex-5 float-right"
              size="lg"
              onClick={
                () => {
                  const { statements } = this.state;
                  statements[index].elseStatements.push('');
                  statements[index].elseStatementErrors.push(undefined);
                  this.setState({ statements });
                }
              }
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
            </Button>
          </div>
        </label>
        <div className="flex-100 d-flex">
          <div className="custom-form-inline label-left flex-80 ml-auto pl10">
            <div className="flex-10 mt5">
              <Checkbox
                defaultChecked={conditionalStatement.shouldExitAfterElse}
                onChange={(e:any) => {
                  const { statements } = this.state;
                  statements[index].shouldExitAfterElse = e.target.checked;
                  this.setState({ statements });
                }}
              >
                Exit
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const formValidationSchema = Yup.object({
      name: Yup.string().required('Required').max(20),
      shortDescription: Yup.string().required('Required').max(1000),
      description: Yup.string().max(1000),
      version: Yup.string().required(),
    });
    const {
      editRule,
      statements,
    } = this.state;
    const { closeRuleComponent, getSavedRule, policyStepSequence } = this.props;

    return (
      <div
        className="pl40 pr20 container-primary-border-top"
      >
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            name: `${editRule.name}`,
            shortDescription: `${editRule.shortDescription}`,
            description: `${editRule.description}`,
            version: 'v1.0',
          }}
          validationSchema={formValidationSchema}
          onSubmit={async (values: any) => {
            const { id } = this.props;
            const data = {
              id,
              name: values.name,
              shortDescription: values.shortDescription,
              description: values.description,
              version: values.version,
              createdBy: { Id: AppConstants.user.Id },
              updatedBy: { Id: AppConstants.user.Id },
              statements,
            };
            if (id === undefined) {
              const saveSWMResponse = await httpService.saveSWMRule({ RuleJSON: JSON.stringify(data).toString() });
              if (saveSWMResponse && saveSWMResponse !== '') {
                if (getSavedRule !== undefined && policyStepSequence !== undefined) {
                  getSavedRule(policyStepSequence, saveSWMResponse);
                }
                closeRuleComponent();
              }
            } else {
              await httpService.updateSWMRule({ RuleJSON: JSON.stringify(data).toString() });
              closeRuleComponent();
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, touched,
          }) => (
            <form
              onSubmit={handleSubmit}
              onBlur={handleChange}
              className="custom-form"
            >
              <PageHeader
                title={values.name === '' ? 'Create AMG Rule' : values.name}
                breadcrumbs={`Policy \\ AMG Stages \\ ${
                  values.name === '' ? 'Create AMG Rule' : values.name
                }`}
                titleActions={(
                  <div className="pt10 d-flex align-items-end">
                    <span className="pb7 mr10" style={{ color: 'grey' }}>
                      {values.version}
                    </span>
                    <div className="dropdown-badge pb7">
                      <CustomizedDropdown
                        variant="clear"
                        disabled
                        list={[['1', 'Draft'],
                          ['2', 'Active']]}
                      />
                    </div>
                  </div>
                )}
                primaryActions={(
                  <div className="d-flex align-items-center justify-content-between pr10 ml-20 pb20">
                    {/* <div className="text-right">
                      <span className="color-dark-three mt-10">Created By</span>
                      <br />
                      <span className="color-dark-three mt-10 mr7">
                        <u>{`${AppConstants.user.firstName} ${AppConstants.user.lastName}`}</u>
                      </span>
                      <span className="color-dark-three mr7 mt-10">on</span>
                      <span className="color-dark-three mt-10">
                        <u>26th Jul 2020</u>
                      </span>
                    </div> */}
                  </div>
                )}
                secondaryActions={editRule.Id !== undefined && editRule.Id !== '' ? (
                  <span className="color-primary mt-10">
                    Rule ID:
                    {' '}
                    {editRule.Id}
                  </span>
                ) : undefined}
              />
              <div className="">

                <Container fluid className="overflow-visible">
                  <Row>
                    <Col>
                      <Container className="p0">
                        <Row>
                          <Col>
                            <Heading size={6}>General</Heading>
                            <label className="label-left" htmlFor="name">
                              <span className="label flex-20">
                                Name
                                <span className="required">*</span>
                              </span>
                              <input
                                defaultValue={values.name}
                                autoComplete="off"
                                type="text"
                                className={`flex-50 ${addErrorClasses(
                                  errors.name,
                                  touched.name,
                                )}`}
                                name="name"
                                placeholder="Name"
                              />
                              <ErrorLabel
                                error={errors.name}
                                isTouched={touched.name}
                              />
                            </label>
                            {/** ---------------------------------- */}
                            <label
                              className="label-left"
                              htmlFor="shortDescription"
                            >
                              <span className="label flex-20">
                                Short Description
                                <span className="required">*</span>
                              </span>
                              <input
                                defaultValue={editRule.shortDescription}
                                autoComplete="off"
                                type="text"
                                className={`flex-50 ${addErrorClasses(
                                  errors.shortDescription,
                                  touched.shortDescription,
                                )}`}
                                name="shortDescription"
                                placeholder="Short Description"
                              />
                              <ErrorLabel
                                error={errors.shortDescription}
                                isTouched={touched.shortDescription}
                              />
                            </label>
                            {/** ---------------------------------- */}
                            <label className="label-textarea" htmlFor="description">
                              <span className="label flex-20">Description</span>
                              <textarea
                                defaultValue={editRule.description}
                                rows={3}
                                className={`flex-50 ${addErrorClasses(
                                  errors.description,
                                  touched.description,
                                )}`}
                                name="description"
                                placeholder="Add detailed notes to this rule..."
                              />
                              <ErrorLabel
                                error={errors.description}
                                isTouched={touched.description}
                              />
                            </label>
                            <div className="custom-form-inline label-left">
                              <div className="label flex-20"><Heading size={6}>Conditions</Heading></div>
                              <div className="flex-80">
                                <Button
                                  variant="outline-primary"
                                  className="w43 float-right"
                                  size="lg"
                                  onClick={
                                    () => {
                                      statements.push(JSON.parse(JSON.stringify(this.defaultStatement)));
                                      this.setState({ statements });
                                    }
                                  }
                                >
                                  <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                                </Button>
                              </div>
                            </div>
                            <ExpressionBuilder
                              onUpdate={(e) => undefined}
                              rules={{
                                objects: [
                                  { caption: 'Soft Bin Number', dataField: 'SoftBinNumber', dataType: 'string' },
                                  { caption: 'Hard Bin Number', dataField: 'HardBinNumber', dataType: 'string' },
                                  { caption: 'Die Passing', dataField: 'IsPassing', dataType: 'string' },
                                  { caption: 'Die Assemble', dataField: 'Assembled', dataType: 'string' },
                                ],
                                operators: [
                                  { caption: '=', dataField: '==' },
                                  { caption: '<', dataField: '<' },
                                  { caption: '>', dataField: '>' },
                                  { caption: '!=', dataField: '!=' },
                                ],
                                typeOperations: [
                                  { dataType: 'string', operators: ['==', '!='] },
                                ],
                              }}
                            />
                            {/** ---------------------------------- */}
                            {/* { */}
                            {/*  this.renderConditionStatements(statements) */}
                            {/* } */}
                            {/* <br /> */}
                            {/* <br /> */}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid>
                <Row className="mt30 p0 pb60">
                  <Col lg={9}>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      className="mr10"
                    >
                      Save
                    </Button>
                    <Button onClick={closeRuleComponent} variant="clear" size="lg">
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CreateAMGRule;
