import { InteractionInputDTO } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';
import { PaginatedDatagridMeta } from 'views/control-tower/PaginatedDatagrid';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpService extends HttpBase {
  sendWebSocketCall = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/message`, data);
  };

  saveSWMRule = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rules/swm`, data);
  };

  updateSWMRule = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/rules/swm`, data);
  };

  getSWMRule = (ruleId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/rules/swm/${ruleId}`);
  };

  getTestProgramRevisions = (testProgramId: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/test-programs/${testProgramId}/revisions`,
    );
  };

  getTestPrograms = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/test-programs`);
  };

  getTestProgramRevisionParameters = (
    testProgramRevisionId: string,
  ): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/test-program-revisions/${testProgramRevisionId}/parameters`,
    );
  };

  getAllTestParameters = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/test-parameters`);
  };

  getMultipleTestParameters = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/test-parameters/multiple`, data);
  };

  getFilteredTestParameters = (searchCriteria:any, mustHaveTpIds: string[] | null = null): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/filtered-test-parameters`, { searchCriteria, mustHaveTpIds });
  };

  getPaginatedTestParameters = (datagridMeta: PaginatedDatagridMeta, searchCriteria:any, mustHaveTpIds: string[] | null = null): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/paginated-test-parameters`, { datagridMeta, searchCriteria, mustHaveTpIds });
  };

  getPATRule = (ruleId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/rules/pat/${ruleId}`);
  };

  savePATRule = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rules/pat`, data);
  };

  deletePATRule = (ruleId: any): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/rules/pat/${ruleId}`);
  };

  runPATSimulation = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/run-pat-simulation`, data);
  };

  updatePATRule = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/rules/pat`, data);
  };

  getUniqueSiteNumber = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/find-site-number`, data);
  };

  getPolicies = (query = ''): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/policies?${query}`);
  };

  getScreenTypes = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/screentypes`);
  };

  updateScreenTypes = (data:any) : Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/screentypes`, data);
  };

  getPolicySteps = (policyId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/policies/${policyId}/steps`);
  };

  getPolicy = (policyId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/policies/${policyId}`);
  };

  getRules = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/rules`);
  };

  getMultipleRules = (rulesId: string[], ruleType: string): Promise<any> => {
    return this.POST(
      `${AppConstants.baseURL}/api/rules/${ruleType}/multiple`,
      rulesId,
    );
  };

  getPolicyResultTrendChart = (trendId: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/policy-result/trend/${trendId}`,
    );
  };

  getDevices = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/devices`);
  };

  getWorkflows = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/workflows`);
  };

  getWafers = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/wafers`);
  };

  getControlTowerVisualizationStats = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/infrastructure/stats`);
  };

  // SPC calls
  saveSPCRule = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rules/spc`, data);
  };

  updateSPCRule = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/rules/spc`, data);
  };

  getSPCRule = (ruleId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/rules/spc/${ruleId}`);
  };

  saveSPCParameterSettings = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/spc-parameter`, data);
  };

  updateSPCParameterSettings = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/spc-parameter`, data);
  };

  saveSPCReportSettings = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/spc-report`, data);
  };

  updateSPCReportSettings = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/spc-report`, data);
  };

  getSPCOutput = (ruleId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/spc-output/${ruleId}`);
  };

  getBinPlusDefinitions = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-definitions`);
  };

  /// //////////////////////////////////////

  getContainerMemoryStats = (
    containerId: string,
    timespan: string,
  ): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/container-memory-stats/${containerId}/${timespan}`,
    );
  };

  getContainerCPUStats = (
    containerId: string,
    timespan: string,
  ): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/container-cpu-stats/${containerId}/${timespan}`,
    );
  };

  getContainerErrorsCount = (
    containerId: string,
    timespan: string,
  ): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/worker/errors-count/${containerId}/${timespan}`,
    );
  };

  getContainerWarningsCount = (
    containerId: string,
    timespan: string,
  ): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/worker/warnings-count/${containerId}/${timespan}`,
    );
  };

  /// //////////////////////////////////////

  getGlobalCPUStats = (timespan: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/cpu-stats/${timespan}`,
    );
  };

  getGlobalMemoryStats = (timespan: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/memory-stats/${timespan}`,
    );
  };

  getGlobalWorkerStats = (timespan: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/worker-stats/${timespan}`,
    );
  };

  getDataIntakeQueue = (data: PaginatedDatagridMeta): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/infrastructure/loader-files`, data);
  };

  getStepIntakeQueue = (data: PaginatedDatagridMeta): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/infrastructure/step-queue`, data);
  };

  /// //////////////////////////////////////

  getContainerStats = (id: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/infrastructure/container/${id}`,
    );
  };

  getWorkerQueueData = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/worker/queue`);
  };

  getErrorsCount = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/worker/errors-count`);
  };

  getWarningsCount = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/worker/warnings-count`);
  };

  validateSWMRuleCondition = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rules/swm/validate`, data);
  };

  swmRuleCoverage = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rules/swm/coverage`, data);
  };

  // POWER VIEW HTTP  CALLS
  getWorkflowById = (workflowInstanceId: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/workflow/${workflowInstanceId}`,
    );
  };

  getWorkflowsByPolicyId = (policyId: any): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/policy/workflows/${policyId}`);
  };

  postSelectionEvent = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/report/selection`, data);
  };

  postCombinedEvent = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/report/combined-selection`, data);
  };

  clearRawDataGridInteractions = (data:InteractionInputDTO) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/report/clear-raw-data-grid-interactions`, data);
  };

  getBinWaferMapData = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binwafermap/get-bin-wafer-map-detail`, data);
  };

  getPublishedTrendReportSelection = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/trend/get-selection-data`, data);
  };

  getRawDataGrid = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/get-raw-data`, data);
  };

  getPublishedBinHistogramLegendtSelection = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/legend/bin-histogram/get-legend-selection`, data);
  };

  getPublishedTrendLegendtSelection = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/legend/trend/get-legend-selection`, data);
  };

  calculateSeedLimit = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/calculate-seed-limit`, data);
  };

  // POWER VIEW REPORTS HTTP CALLS
  getFilteredReports = (rawDataObj: any): Promise<any> => {
    return this.POST(
      `${AppConstants.baseURL}/api/filter-trend-report`,
      rawDataObj,
    );
  };

  getPowerViewReport = (reportInput: any): Promise<any> => {
    return this.POST(
      `${AppConstants.baseURL}/api/get-power-view-report`,
      reportInput,
    );
  };

  getPATRuleTestParameters = (ruleId: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/pat-test-parameters/${ruleId}`);
  };

  getTrendChartsData = (data:any) => {
    return this.POST(`${AppConstants.baseURL}/api/trend`, data);
  };

  getBinHistogramData = (waferIds: string[]) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/chart-data`, waferIds);
  };

  getBinHistogramSelectionData = (data: any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/BinHistogram/get-selection-data`, data);
  };

  getBinWaferMapSelectionData = (data: any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/BinWaferMap/get-selection-data`, data);
  };

  getRawDataGridIndicators = (data: any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/get-raw-data-indicators`, data);
  };

  // WAFER GALLERY HTTP CALLS

  getWaferGalleryImages = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/wafer-gallery-images`, data);
  };

  getGroupSortData = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/group-sort-data`, data);
  };

  getTrendReportDataGrid = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/trend/get-grid-data`, data);
  };

  getBinWaferMapReportDataGrid = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binwafermap/get-grid-data`, data);
  };

  getBinHistogramReportDataGrid = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/get-grid-data`, data);
  };

  getTrendReportDiesDetailGrid = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/trend/get-passed-failed-dies-data`, data);
  };

  getDetailedTrendReport = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/trend/detailed-trend-report`, data);
  };

  postUploadFile = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/loader-worker/file`, data);
  };

  createPolicy = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/policies`, data);
  };

  updatePolicy = (data:any) : Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/policies`, data);
  };

  getPolicyResult = (policyId:string, waferCount: number): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/get-policy-result/${policyId}/${waferCount}`);
  };

  getSummaryStatsReportHeader = (waferId:string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/summary-stats-report-header/${waferId}`);
  };

  applyPickMapAmgSettings = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/pick-map-amg`, data);
  };

  generateAssemblyMaps = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/pick-map-amg/generate`, data);
  };

  downloadZippedAssemblyMapsFile = (zippedFileName: string) : Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/policies/download-zipped-amg-file/${zippedFileName}`);
  }

  validateLocationCredentials = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/file-system/validate-location-credentials`, data);
  };

  simulatePolicy = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/simulate-policy`, data);
  };

  getGroupedGalleryData = (data: any): Promise<any> => {
    return this.POST(
      `${AppConstants.baseURL}/api/grouped-gallery-data`,
      data,
    );
  };

  retryWorkflowLoadFile = (id: string) : Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/policies/retry-file/${id}`, { });
  };

  retryWorkflowStep = (id: string) : Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/policies/retry-step/${id}`, { });
  };

  private successHandler = (res: any) => {
    if (res.status === 200) {
      return Promise.resolve(res.data);
    }
    return Promise.reject(res);
  };

  private errorHandler = (res: any) => {
    if (res.success === false || res.code !== 200) {
      return Promise.reject(res.message);
    }
    if (res.error && res.status !== 200) {
      return Promise.reject(res.error.message);
    }
    return Promise.reject(res.message);
  };
}
const httpService = new HttpService();
export { httpService };
