import React, { useState } from 'react';
import Checkbox from 'components/wrapped-component/hint-controls/Checkbox';
import { IDetectDuplicateFiles, IHint } from 'interfaces';
import Multiselect from 'multiselect-react-dropdown';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';

interface IDetectDuplicateFileProps {
  updateDetectDuplicateFiles: (detectDuplicateFiles: any) => void;
  detectDuplicateFiles: IDetectDuplicateFiles;
}

const FILE_NAME = 'filename';
const FILE_CONTENT = 'filecontent';
const PROCESS_FILES = 'process';
const REJECT_FILES = 'reject';
const MOVE_DUPLICATE_FILE = 'move';
const MOVE_RENAME_DUPLICATE_FILE = 'move/rename';
const LEAVE_DUPLICATE_FILE = 'leave';
const LEAVE_RENAME_DUPLICATE_FILE = 'leave/rename';
const DELETE_DUPLICATE_FILE = 'delete';
const FILE_CONTENT_OPTIONS = ['Facility', 'Work Center', 'Device', 'Test Program', 'Test Program Version', 'Lot', 'Wafer', 'Wafer Start Time', 'Wafer End Time', 'Lot Start Time', 'Lot End Time']
const hint: IHint = {
  message: 'Use [filename] to access the file name and [timestamp] to access the current timestamp',
  position: 'right'
}

const DetectDuplicateFiles = (props: IDetectDuplicateFileProps) => {
  const { updateDetectDuplicateFiles, detectDuplicateFiles } = props;

  const onSelectFileContentOption = (selectedList: string[]) => {
    detectDuplicateFiles.fileContentAttributes = selectedList;
    updateDetectDuplicateFiles(detectDuplicateFiles);
  }

  const onRemoveFileContentOption = (selectedList: string[]) => {
    detectDuplicateFiles.fileContentAttributes = selectedList;
    updateDetectDuplicateFiles(detectDuplicateFiles);
  }

  const RejectDuplicateOutcomeHandler = (e: any) => {
    detectDuplicateFiles.rejectDuplicateOutcome = e.target.value;
    updateDetectDuplicateFiles(detectDuplicateFiles);
  }

  return (
    <div className="d-flex flex-column justify-content-start my-3">
      <Checkbox
        defaultChecked={detectDuplicateFiles.detectDuplicateFiles}
        onChange={(e: any) => {
          detectDuplicateFiles.detectDuplicateFiles = e.target.checked;
          updateDetectDuplicateFiles(detectDuplicateFiles);
        }}
      >
        Detect Duplicate Files
      </Checkbox>
      {detectDuplicateFiles.detectDuplicateFiles ? (
        <>
          <div className="d-flex flex-row mx-2">
            <label htmlFor={FILE_CONTENT}>
              <input
                className="mx-1"
                type="radio"
                value={FILE_CONTENT}
                name={FILE_CONTENT}
                checked={detectDuplicateFiles.detectionMethod === FILE_CONTENT}
                onChange={(e: any) => {
                  detectDuplicateFiles.detectionMethod = e.target.value;
                  updateDetectDuplicateFiles(detectDuplicateFiles);
                }}
              />
              Via File Content
            </label>
            <label htmlFor={FILE_NAME}>
              <input
                className="mx-1"
                type="radio"
                value={FILE_NAME}
                name={FILE_NAME}
                checked={detectDuplicateFiles.detectionMethod === FILE_NAME}
                onChange={(e: any) => {
                  detectDuplicateFiles.detectionMethod = e.target.value;
                  updateDetectDuplicateFiles(detectDuplicateFiles);
                }}
              />
              Via File Name
            </label>
          </div>
          {detectDuplicateFiles.detectionMethod === FILE_CONTENT ? (
            <div className="d-flex flex-row my-1 mx-4">
              <Multiselect
                options={FILE_CONTENT_OPTIONS}
                selectedValues={detectDuplicateFiles.fileContentAttributes}
                onSelect={onSelectFileContentOption}
                onRemove={onRemoveFileContentOption}
                placeholder="Select Attribute(s)"
                isObject={false}
                avoidHighlightFirstOption
              />
            </div>
          ) : null}
          <div className="d-flex flex-row mx-2 my-2">
            <label htmlFor={REJECT_FILES}>
              <input
                className="mx-1"
                type="radio"
                value={REJECT_FILES}
                name={REJECT_FILES}
                checked={detectDuplicateFiles.duplicateOutcome === REJECT_FILES}
                onChange={(e) => {
                  detectDuplicateFiles.duplicateOutcome = e.target.value;
                  updateDetectDuplicateFiles(detectDuplicateFiles);
                }}
              />
              Reject File If Duplicate
            </label>
            <label htmlFor={PROCESS_FILES}>
              <input
                className="mx-1"
                type="radio"
                value={PROCESS_FILES}
                name={PROCESS_FILES}
                checked={detectDuplicateFiles.duplicateOutcome === PROCESS_FILES}
                onChange={(e: any) => {
                  detectDuplicateFiles.duplicateOutcome = e.target.value;
                  updateDetectDuplicateFiles(detectDuplicateFiles);
                }}
              />
              Process File If Duplicate
            </label>
          </div>
          {detectDuplicateFiles.duplicateOutcome === REJECT_FILES ? (
            <div className="mx-3">
              <div className="d-inline-flex">
                <label htmlFor={MOVE_DUPLICATE_FILE}>
                  <input
                    className="mx-1"
                    type="radio"
                    value={MOVE_DUPLICATE_FILE}
                    name={MOVE_DUPLICATE_FILE}
                    checked={detectDuplicateFiles.rejectDuplicateOutcome === MOVE_DUPLICATE_FILE}
                    onChange={(e: any) => {
                      RejectDuplicateOutcomeHandler(e);
                    }}
                  />
                  <span className="my-2">Move Duplicate File To</span>
                </label>
                <Textbox
                  disabled={detectDuplicateFiles.rejectDuplicateOutcome !== MOVE_DUPLICATE_FILE}
                  autoComplete="off"
                  name="folderPath"
                  placeholder="/folder/subFolder/"
                  defaultValue={detectDuplicateFiles.moveFolderPath}
                  onChange={(e: any) => {
                    detectDuplicateFiles.moveFolderPath = e.target.value;
                    updateDetectDuplicateFiles(detectDuplicateFiles);
                  }}
                  className="h-1"
                />
              </div>
              <div className="d-inline-flex">
                <label htmlFor={MOVE_RENAME_DUPLICATE_FILE}>
                  <input
                    className="mx-1"
                    type="radio"
                    value={MOVE_RENAME_DUPLICATE_FILE}
                    name={MOVE_RENAME_DUPLICATE_FILE}
                    checked={detectDuplicateFiles.rejectDuplicateOutcome === MOVE_RENAME_DUPLICATE_FILE}
                    onChange={(e: any) => {
                      RejectDuplicateOutcomeHandler(e);
                    }}
                  />
                  <span className="my-2">Move Duplicate File & Rename To</span>
                </label>
                <Textbox
                  disabled={detectDuplicateFiles.rejectDuplicateOutcome !== MOVE_RENAME_DUPLICATE_FILE}
                  autoComplete="off"
                  name="folderPath"
                  placeholder="/folder/subFolder/"
                  defaultValue={detectDuplicateFiles.moveAndRenameFolderPath}
                  onChange={(e: any) => {
                    detectDuplicateFiles.moveAndRenameFolderPath = e.target.value;
                    updateDetectDuplicateFiles(detectDuplicateFiles);
                  }}
                  className="h-1 mx-1"
                />
                <Textbox
                  disabled={detectDuplicateFiles.rejectDuplicateOutcome !== MOVE_RENAME_DUPLICATE_FILE}
                  autoComplete="off"
                  name="regularExpression"
                  hint={hint}
                  placeholder="rename"
                  defaultValue={detectDuplicateFiles.moveAndRenameFileExpression}
                  onChange={(e: any) => {
                    detectDuplicateFiles.moveAndRenameFileExpression = e.target.value;
                    updateDetectDuplicateFiles(detectDuplicateFiles);
                  }}
                  className="h-1"
                />
              </div>
              <div>
                <label htmlFor={LEAVE_DUPLICATE_FILE}>
                  <input
                    className="mx-1"
                    type="radio"
                    value={LEAVE_DUPLICATE_FILE}
                    name={LEAVE_DUPLICATE_FILE}
                    checked={detectDuplicateFiles.rejectDuplicateOutcome === LEAVE_DUPLICATE_FILE}
                    onChange={(e: any) => {
                      RejectDuplicateOutcomeHandler(e);
                    }}
                  />
                  Leave File Intact
                </label>
              </div>
              <div className="d-inline-flex">
                <label htmlFor={LEAVE_RENAME_DUPLICATE_FILE}>
                  <input
                    className="mx-1"
                    type="radio"
                    value={LEAVE_RENAME_DUPLICATE_FILE}
                    name={LEAVE_RENAME_DUPLICATE_FILE}
                    checked={detectDuplicateFiles.rejectDuplicateOutcome === LEAVE_RENAME_DUPLICATE_FILE}
                    onChange={(e: any) => {
                      RejectDuplicateOutcomeHandler(e);
                    }}
                  />
                  <span className="my-2">Leave File Intact & Rename To</span>
                </label>
                <Textbox
                  disabled={detectDuplicateFiles.rejectDuplicateOutcome !== LEAVE_RENAME_DUPLICATE_FILE}
                  autoComplete="off"
                  name="regularExpression"
                  placeholder="rename"
                  hint={hint}
                  defaultValue={detectDuplicateFiles.renameFileExpression}
                  className="h-1"
                  onChange={(e: any) => {
                    detectDuplicateFiles.renameFileExpression = e.target.value;
                    updateDetectDuplicateFiles(detectDuplicateFiles);
                  }}
                />
              </div>
              <div>
                <label htmlFor={DELETE_DUPLICATE_FILE}>
                  <input
                    className="mx-1"
                    type="radio"
                    value={DELETE_DUPLICATE_FILE}
                    name={DELETE_DUPLICATE_FILE}
                    checked={detectDuplicateFiles.rejectDuplicateOutcome === DELETE_DUPLICATE_FILE}
                    onChange={(e: any) => {
                      RejectDuplicateOutcomeHandler(e);
                    }}
                  />
                  Delete Duplicate File
                </label>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default DetectDuplicateFiles;
