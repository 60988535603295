import { faFolder, faFolderPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'CustomToast';
import {
  DataGrid,
  Popup
} from 'devextreme-react';
import {
  Column, FilterRow, Scrolling, Selection
} from 'devextreme-react/data-grid';
import _ from 'lodash';
import React from 'react';
import {
  Button, Col, Container, FormCheck, Row
} from 'react-bootstrap';
import { httpSCW } from 'services/http.scw';
import CreateOrEditFavoriteFolder from './CreateOrEditFavoriteFolder';

interface ICopyOrMoveFavoriteSelectionCriteriaProps {
  isVisible: boolean;
  folders: any;
  selectedFolder: any;
  selectedFavorite: any;
  allFavorites: any;
  onClose: ({
    isCanceled, isMoved, folders, favorites
  }: any) => void;
}
interface ICopyOrMoveFavoriteSelectionCriteriaState {
  selectedFolder: any;
  isCreateOrEditFavoriteFolderPopupVisible: boolean;
  currentFoldersList:any;
  currentFavoritesList: any;
  allFavoritesList: any;
}
class CopyOrMoveFavoriteSelectionCriteria extends React.Component<ICopyOrMoveFavoriteSelectionCriteriaProps, ICopyOrMoveFavoriteSelectionCriteriaState> {
  toggleFieldRef:any = null;

  constructor(props: ICopyOrMoveFavoriteSelectionCriteriaProps) {
    super(props);
    this.state = {
      selectedFolder: props.selectedFolder.name === 'Favorites' || props.selectedFolder.name == 'Uncatogrized' ? props.folders[0] : props.selectedFolder,
      isCreateOrEditFavoriteFolderPopupVisible: false,
      currentFoldersList: props.folders,
      currentFavoritesList: props.selectedFavorite,
      allFavoritesList: props.allFavorites,
    };
  }

  moveFavorite = (_e:any) => {
    const { selectedFolder, currentFavoritesList, allFavoritesList } = this.state;
    const { onClose } = this.props;
    if (!selectedFolder) {
      toast.error('Please select a folder to move the selection criteria to');
      return;
    }
    const folderId = selectedFolder.id;
    const favoriteIds = currentFavoritesList.map((favorite:any) => favorite.id);
    if (this.toggleFieldRef.checked){
      // copy
      // check if the favorite with the same name and privacy PRIVATE already exists in the folder
      const favoritesInTheFolder = allFavoritesList.filter((favorite:any) => favorite.folder && favorite.folder.id === folderId && favorite.privacy === 1);
      const favoritesToBeCopied = currentFavoritesList.filter((favorite:any) => favoritesInTheFolder.some((f:any) => f.name === favorite.name && favorite.privacy === 1));
      if (favoritesToBeCopied.length > 0) {
        toast.error(`The following selection criteria already exist in the folder ${selectedFolder.name}: ${favoritesToBeCopied.map((f:any) => f.name).join(', ')}`);
        return;
      }
      httpSCW.copyFavorite({ folder: folderId, favorites: favoriteIds }).then((res:any) => {
        onClose({
          isCanceled: false, isMoved: false, favorites: res
        });
        toast.success(`Successfully copied selection criteria to folder ${selectedFolder.name}`);
      }).catch((err:any) => {
        toast.error(`Failed to copy selection criteria to folder ${selectedFolder.name}`);
      });
      return;
    }
    // move
    httpSCW.moveFavorite({ folder: folderId, favorites: favoriteIds }).then((res:any) => {
      onClose({
        isCanceled: false, isMoved: true, favorites: res
      });
      toast.success(`Successfully moved selection criteria to folder ${selectedFolder.name}`);
    }).catch((err:any) => {
      toast.error(`Failed to move selection criteria to folder ${selectedFolder.name}`);
    });
  }

  render() {
    const {
      isVisible, onClose
    } = this.props;
    const { selectedFolder, isCreateOrEditFavoriteFolderPopupVisible, currentFoldersList } = this.state;
    return (
      <>
        <Popup
          visible={isVisible}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={false}
          title="Move Favorites"
          height="35%"
          width="25%"
        >
          <Container>
            <Row>
              <Col>
                <h2>
                  Move to
                </h2>
              </Col>
              <Col>
                <div className="float-right">
                  <Button
                    variant="outline"
                    onClick={() => onClose({
                      isCanceled: true, isMoved: false, folders: [], favorites: []
                    })}
                    className="h40"
                  >
                    <FontAwesomeIcon icon={faTimes} size="lg" className="mr10" />
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    this.setState({ isCreateOrEditFavoriteFolderPopupVisible: true });
                  }}
                  className="h40 mb10"
                  size="sm"
                  block
                >
                  <FontAwesomeIcon icon={faFolderPlus} size="lg" className="mr10" />
                  New Folder
                </Button>
                <DataGrid
                  className="border-radius-5"
                  height={150}
                  showBorders
                  columnAutoWidth
                  showRowLines
                  showColumnLines
                  showColumnHeaders={false}
                  dataSource={_.sortBy(currentFoldersList, (f) => f.name)}
                  keyExpr="id"
                  onSelectionChanged={(e:any) => {
                    this.setState({ selectedFolder: e.selectedRowsData[0] });
                  }}
                  selectedRowKeys={selectedFolder ? [selectedFolder.id] : []}
                  onEditorPreparing={(e:any) => {
                    if (e.parentType === 'filterRow') {
                      e.editorOptions.placeholder = 'Search for folder';
                    }
                  }}
                  focusedRowEnabled
                  focusedRowKey={selectedFolder ? selectedFolder.id : null}
                  autoNavigateToFocusedRow
                >
                  <Selection mode="single" showCheckBoxesMode="normal" />
                  <Scrolling mode="Standard" showScrollbar="always" />
                  <FilterRow visible />
                  <Column
                    dataField="name"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    cellRender={(e:any) => {
                      return (
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon icon={faFolder} size="lg" className="mr10" />
                          {`${e.value} (${e.row.data.associatedFavoriteSelectionCriteriaCount})`}
                        </div>
                      );
                    }}
                  />
                </DataGrid>
              </Col>
            </Row>
            <Row className="mt20 align-bottom">
              <Col sm={8}>
                <FormCheck
                  ref={(ref:any) => { this.toggleFieldRef = ref; }}
                  type="checkbox"
                  label="Keep a copy in current folder"
                />
              </Col>
              <Col>
                <div className="float-right">
                  <Button
                    variant="outline-dark"
                    onClick={this.moveFavorite}
                    className="h40 dx-icon-move ToFolder"
                  >
                    Move
                  </Button>
                </div>
              </Col>
            </Row>

          </Container>
        </Popup>
        { isCreateOrEditFavoriteFolderPopupVisible
        && (
          <CreateOrEditFavoriteFolder
            isVisible={isCreateOrEditFavoriteFolderPopupVisible}
            isCreate
            onClose={() => this.setState({
              isCreateOrEditFavoriteFolderPopupVisible: false,
            })}
            onAddFavoriteFolder={(newFolder: any) => {
              this.setState({
                isCreateOrEditFavoriteFolderPopupVisible: false,
                selectedFolder: newFolder,
                currentFoldersList: [newFolder, ...currentFoldersList],
              });
            }}
          />
        )}
      </>
    );
  }
}
export default CopyOrMoveFavoriteSelectionCriteria;
