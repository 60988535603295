import React, { Component } from 'react';
import { Accordion, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ExpressionBuilder from '../../../../../../../utility-component/expression-builder/ExpressionBuilder';
import Button from '../../../../../../../wrapped-component/hint-controls/Button';
import Heading from '../../../../../../../wrapped-component/hint-controls/Heading';

interface IDieSettingsProps {
  getDieSettings: (e: any) => void;
  rules: any;
  title?: string;
  hideOutputSettings?: boolean;
  collapseView?: boolean | undefined;
  dieSettings?: any;
}

class DieSettings extends Component<IDieSettingsProps, any> {
  static defaultProps: Partial<IDieSettingsProps> = {
    title: 'Die Settings',
    dieSettings: [],
    hideOutputSettings: false,
    collapseView: false,
  };

  constructor(props: any) {
    super(props);
    // const { dieSettings } = this.props;

    // this.state = {
    //   open: !!(dieSettings && dieSettings.length > 0),
    // };
  }

  // collapseHandler = () => {
  //   const { open } = this.state;
  //   this.setState({ open: !open });
  // };

  render() {
    // const { open } = this.state;
    const {
      getDieSettings,
      title,
      rules,
      dieSettings,
      collapseView,
      hideOutputSettings,
    } = this.props;

    const expressionGrid = (
      <ExpressionBuilder
        defaultStatements={dieSettings}
        hideOutputSettings={hideOutputSettings}
        onUpdate={(e: any) => {
          getDieSettings(e);
        }}
        rules={rules}
      />
    );
    return (
      <div className="background-color-light border-radius-4">
        {collapseView
          ? (
            <div className="mb20">
              <Accordion>
                <Accordion.Toggle eventKey="0" as={Button} variant="clear" className="p0 text-left w-100">
                  <div className="background-color-secondary-background border-all p10 mt20 d-flex align-items-center">
                    <div className="w20 h20 d-flex align-items-center justify-content-center border-all rounded mr10 background-color-light">
                      <FontAwesomeIcon size="sm" icon={faChevronDown} />
                    </div>
                    <Heading className="mb0" size={5}>{title}</Heading>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="border-all mt-1 p10">
                    {expressionGrid}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          )
          : (
            <>
              {expressionGrid}
            </>
          )}

      </div>
    );
  }
}

export default DieSettings;
