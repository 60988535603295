import React from 'react';
import { Col } from 'react-bootstrap';
import toast from 'CustomToast';
import { PatternRule } from 'devextreme-react/data-grid';
import AppConstants from '../../../../constants.js';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import { httpService } from '../../../../services/http.service';
import { httpMasterMeta } from '../../../../services/http.master-meta';
import EditableDataGrid from '../../../wrapped-component/editable-data-grid/EditableDataGrid';
import ConditionalParameterOrBinBox from './components-for-spc-parameter-settings/ConditionalParameterOrBinBox';
import ConditionalDataLimitTypeForSPCParameterSettings from './components-for-spc-parameter-settings/ConditionalDataLimitTypeForSPCParameterSettings';
import SPCAppliedRulesBox from './components-for-spc-parameter-settings/SPCAppliedRulesBox';
import GroupingSortingDropdown from './grouping-sorting-dropdown/GroupingSortingDropdown';
import { groupByView, parameterSettingMapper, sortByView, } from './SPCMapperUtils';

interface ISPCParameterSetting {
  SerialNumber: number;
  SPCParameterType: string;
  Parameters:any;
  Bins:any;
  PreDefinedAttribute: string;
  UserDefinedAttribute:any;
  DataLimitType: string;
  UOM : string;
  LowLimit : number;
  HighLimit : number;
  MeasuredValue : string;
  MeasurePointBy : string;
  GroupBy : string;
  SortBy : string;
  TriggerLimitViolation: string;
  RulesApplied : any;
  RulesAppliedSequence : string;
  Actions : string;
  PointsForSeedLimit: number;
  LRCDurationValue : number;
  LRCDurationType : string;
  LRCuom : string;
  LRCOperator : string;
  LRCValue : number;
}
class SPCParameterSettings extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      createdSPCParameterSettings: props.spcParameters,
      receivedRules: [],
      reusableNames: [],
      testParameters: [],
      binDefinitions: [],
      columnsList: [],
      autoRowUOM: '',
      autoRowLowLimit: 0,
      autoRowHighLimit: 0,
      testParam: {},
      rulesApplied: [],
      // based onData Limit Type
      isLimitEditable: true,
      isLimitRecalculationCycleEditable: true,
      // based on selected data in Hard/Soft Bin
      isLowLimitEditable: true,
      isHighLimitEditable: true,
      isTriggerLimitViolationEditable: false,

      triggerLimitViolationOptions: [
        { caption: 'Lower SPC Limit', dataField: 'Lower SPC Limit' },
        { caption: 'Upper SPC Limit', dataField: 'Upper SPC Limit' },
        { caption: 'Both SPC Limits', dataField: 'Both SPC Limits' },
      ],
      measuredValueOptions: [
        { caption: 'Cp', dataField: 'Cp' },
        { caption: 'Cpk', dataField: 'Cpk' },
        { caption: 'Stdev', dataField: 'Stdev' },
        { caption: 'Unit', dataField: 'Unit' },
        { caption: 'Mean', dataField: 'Mean' },
        { caption: 'Median', dataField: 'Median' },
      ],
    };
  }

  componentDidMount() {
    this.getTestParameters();
    // this.getBinDefinitions();  disbaled for Alpha release
    this.getColumnsList();
    this.getSPCRules();
  }

  componentDidUpdate(prevProps: any) {
    const { receivedRules, searchCriteria } = this.props;
    if (prevProps.receivedRules !== receivedRules) {
      this.getSPCRules();
    }
    if (prevProps.searchCriteria !== searchCriteria) {
      this.getTestParameters();
    }
  }

  getTestParameters = async () => {
    const { searchCriteria } = this.props;
    httpService
      .getFilteredTestParameters(searchCriteria)
      .then((metaData) => {
        if (metaData !== undefined && metaData !== null) {
          const { data } = metaData;
          this.setState({ testParameters: data });
        } else {
          toast.error('No Test Parameters found against the selected Selection Criteria');
        }
      });
  };

  getBinDefinitions = async () => {
    await httpService.getBinPlusDefinitions().then((bins) => {
      this.setState({ binDefinitions: bins });
    });
  };

  getColumnsList = async () => {
    httpMasterMeta.getIndividualColumnsList().then((columnDict) => {
      if (columnDict) {
        const columnsList : any = [];
        Object.keys(columnDict).forEach((entityName:string, parentRank: number) => {
          const parentObj = {
            Id: entityName,
            Caption: entityName,
            ParentId: null,
            isVisible: true,
          };
          columnsList.push(parentObj);
          columnDict[entityName].forEach((columnObj:any, index:any) => {
            const { tableName, columnName } = columnObj;
            const child = {
              Id: `${entityName}_${index}`,
              TableName: tableName,
              ColumnName: `${tableName}_${columnName}`,
              Caption: `${tableName}_${columnName}`,
              ParentId: entityName,
              isVisible: true,
              EntityType: entityName,
              FieldName: columnName,
              Disabled: true,
              ParentRank: parentRank,
            };
            columnsList.push(child);
          });
        });

        const newColumnsList : any = [];
        columnsList.forEach((x:any) => {
          if (x.ColumnName !== undefined) {
            if (!x.ColumnName.includes('_id')) {
              newColumnsList.push(x);
            }
          } else {
            newColumnsList.push(x);
          }
        });

        this.setState({ columnsList: newColumnsList });
      }
    });
  };

  getSPCRules = () => {
    const { receivedRules } = this.props;
    const temparoryRules = receivedRules;
    if (temparoryRules !== undefined) {
      if (temparoryRules.spcRuleExpression !== undefined) temparoryRules.spcRuleExpression = JSON.parse(temparoryRules.spcRuleExpression);
    }
    this.setState({ receivedRules: temparoryRules });
  };

  setName = () => {
    const { createdSPCParameterSettings, reusableNames } = this.state;
    if (reusableNames.length !== 0) {
      const newName = reusableNames[0];
      reusableNames.splice(0, 1);
      this.setState(reusableNames);
      return newName;
    }
    return `${createdSPCParameterSettings.length + 1}`;
  };

  // set UOM, low limit, and high limit based on selected parameter/bin
  setAutofillValues = (receivedValues: any) => {
    this.setState(() => {
      return ({
        autoRowUOM: receivedValues.uom,
        autoRowLowLimit: receivedValues.lowLimit,
        autoRowHighLimit: receivedValues.highLimit,
        testParam: receivedValues.testParam,
      });
    });
  };

  handleDataLimitType = (dataLimitType: string) => {
    let rclEditable = false;
    let limitEditable = false;

    rclEditable = dataLimitType !== 'CTL - Static';
    limitEditable = dataLimitType === 'Custom';
    this.setState(() => {
      return { isLimitRecalculationCycleEditable: rclEditable, isLimitEditable: limitEditable };
    });
  };

  handleUpdateAppliedRules = (rulesApplied: any) => {
    const conditionsThatNeedTriggerLimitViolations:string[] = [
      'point(s) in a row on same side of centerline',
      'point(s) more than',
      'point(s) greater than',
      'point(s) in a row greater than',
    ];
    let currentTriggerLimitViolationOptions:any[] = [];
    rulesApplied.forEach((element:any) => {
      element.spcRuleExpressions.forEach((rule:any) => {
        if (currentTriggerLimitViolationOptions.length === 0 && conditionsThatNeedTriggerLimitViolations.includes(rule.condition)) {
          currentTriggerLimitViolationOptions = [
            { caption: 'Lower SPC Limit', dataField: 'Lower SPC Limit' },
            { caption: 'Upper SPC Limit', dataField: 'Upper SPC Limit' },
            { caption: 'Both SPC Limits', dataField: 'Both SPC Limits' },
          ];
        }
      });
    });

    this.setState(
      {
        rulesApplied,
        triggerLimitViolationOptions: currentTriggerLimitViolationOptions,
        isTriggerLimitViolationEditable: currentTriggerLimitViolationOptions.length !== 0,
      },
    );
  };

  handleUpdateMeasureValueBy = (measureValueBy: string) => {
    let defaultMeasuredValueOptions = [
      { caption: 'Cp', dataField: 'Cp' },
      { caption: 'Cpk', dataField: 'Cpk' },
      { caption: 'Stdev', dataField: 'Stdev' },
      { caption: 'Unit', dataField: 'Unit' },
      { caption: 'Mean', dataField: 'Mean' },
      { caption: 'Median', dataField: 'Median' },
    ];
    if (measureValueBy === 'Wafer' || measureValueBy === 'Lot') {
      defaultMeasuredValueOptions = defaultMeasuredValueOptions.filter((option:any) => option.caption !== 'Unit');
    } else if (measureValueBy === 'Die' || measureValueBy === 'Site') {
      defaultMeasuredValueOptions = defaultMeasuredValueOptions.filter((option:any) => option.caption === 'Unit');
    }
    this.setState({ measuredValueOptions: defaultMeasuredValueOptions });
  };

  updateToParent = (data: any) => {
    const { onUpdate } = this.props;
    const { createdSPCParameterSettings } = this.state;
    const index = createdSPCParameterSettings.findIndex((x: any) => x === data);
    const newSetting: any = parameterSettingMapper(data);
    if (newSetting.Id !== undefined) {
      httpService
        .updateSPCParameterSettings({ RuleJSON: JSON.stringify(newSetting).toString() })
        .then((response: string) => {
          toast.success('SPC Parameter Setting updated successfully');
        }, (error: any) => {
          toast.error('SPC Parameter Setting update failed');
        });
    } else {
      httpService
        .saveSPCParameterSettings({ RuleJSON: JSON.stringify(newSetting).toString() })
        .then((response: string) => {
          createdSPCParameterSettings[index].id = response;
          this.setState({ createdSPCParameterSettings }, onUpdate(createdSPCParameterSettings));
          toast.success('SPC Parameter Setting added successfully');
        }, (error: any) => {
          toast.error('SPC Parameter Setting add failed');
        });
    }
    this.setState({ createdSPCParameterSettings, isTriggerLimitViolationEditable: false }, onUpdate(createdSPCParameterSettings));
  };

  render() {
    const {
      createdSPCParameterSettings, receivedRules, testParameters, isLimitRecalculationCycleEditable,
      isLowLimitEditable, isHighLimitEditable, isLimitEditable, binDefinitions, columnsList, triggerLimitViolationOptions, measuredValueOptions, isTriggerLimitViolationEditable,
    } = this.state;
    const { onUpdate, searchCriteria } = this.props;

    return (
      <div className="border-all pl10 pr10 pb20 background-color-light">
        <Col lg={12} className="mt20">
          <Heading size={4}>SPC Parameter Settings</Heading>
        </Col>
        <div className="w-100">
          <EditableDataGrid
            height={350}
            showFilterRow={false}
            showAdvancedFilters={false}
            enableColumnChooser={false}
            showGroupPanel={false}
            showRowLines
            showColumnLines
            allowAdding
            allowUpdating
            allowDeleting
            columnAutoWidth
            columnFixing
            columnHidingEnabled
            scrollMode="virtual"
            editingMode="row"
            onRowUpdated={(event: any) => {
              const {
                autoRowUOM, autoRowLowLimit, autoRowHighLimit, testParam, rulesApplied,
              } = this.state;
              let currentIndex = 0;
              createdSPCParameterSettings.forEach((x:any, index:number) => {
                if (x.id === event.data.id) {
                  createdSPCParameterSettings[index].serialNumber = event.data.serialNumber;
                  createdSPCParameterSettings[index].parameterType = event.data.parameterType;
                  createdSPCParameterSettings[index].parameterOrBin = event.data.parameterOrBin;
                  createdSPCParameterSettings[index].testParam = testParam;
                  createdSPCParameterSettings[index].dataLimitType = event.data.dataLimitType;
                  createdSPCParameterSettings[index].uom = autoRowUOM;
                  createdSPCParameterSettings[index].lowLimit = (event.data.dataLimitType !== 'Custom') ? event.data.lowLimit : autoRowLowLimit;
                  createdSPCParameterSettings[index].highLimit = (event.data.dataLimitType !== 'Custom') ? event.data.highLimit : autoRowHighLimit;
                  createdSPCParameterSettings[index].measuredValue = event.data.measuredValue;
                  createdSPCParameterSettings[index].measurePointBy = event.data.measurePointBy;
                  createdSPCParameterSettings[index].groupBy = event.data.groupBy;
                  createdSPCParameterSettings[index].sortBy = event.data.sortBy;
                  createdSPCParameterSettings[index].triggerLimitViolation = event.data.triggerLimitViolation;
                  createdSPCParameterSettings[index].rulesApplied = rulesApplied;
                  createdSPCParameterSettings[index].rulesAppliedName = event.data.rulesAppliedName;
                  createdSPCParameterSettings[index].actions = event.data.actions;
                  createdSPCParameterSettings[index].pointsForSeedLimit = event.data.pointsForSeedLimit;
                  // Limit Recalculation Cycle
                  createdSPCParameterSettings[index].LRCDurationValue = event.data.LRCDurationValue;
                  createdSPCParameterSettings[index].LRCDurationType = event.data.LRCDurationType;
                  createdSPCParameterSettings[index].LRCExpression = event.data.LRCExpression;
                  createdSPCParameterSettings[index].LRCuom = event.data.LRCuom;
                  createdSPCParameterSettings[index].LRCOperator = event.data.LRCOperator;
                  createdSPCParameterSettings[index].LRCValue = event.data.LRCValue;
                  currentIndex = index;
                }
              });
              this.updateToParent(createdSPCParameterSettings[currentIndex]);
            }}
            onRowInserted={(event: any) => {
              const {
                autoRowUOM, autoRowLowLimit, autoRowHighLimit, testParam, rulesApplied,
              } = this.state;
              const setting = {
                id: '',
                serialNumber: this.setName(),
                parameterType: event.data.parameterType,
                parameterOrBin: event.data.parameterOrBin,
                testParam,
                dataLimitType: event.data.dataLimitType,
                uom: autoRowUOM,
                lowLimit: event.data.dataLimitType === 'Custom' ? event.data.lowLimit : autoRowLowLimit,
                highLimit: event.data.dataLimitType === 'Custom' ? event.data.highLimit : autoRowHighLimit,
                measuredValue: event.data.measuredValue,
                measurePointBy: event.data.measurePointBy,
                groupBy: event.data.groupBy,
                sortBy: event.data.sortBy,
                triggerLimitViolation: event.data.triggerLimitViolation,
                rulesApplied,
                rulesAppliedName: event.data.rulesAppliedName,
                actions: event.data.actions,
                pointsForSeedLimit: event.data.pointsForSeedLimit,
                // Limit Recalculation Cycle
                LRCDurationValue: event.data.LRCDurationValue,
                LRCDurationType: event.data.LRCDurationType,
                LRCExpression: event.data.LRCExpression,
                LRCuom: event.data.LRCuom,
                LRCOperator: event.data.LRCOperator,
                LRCValue: event.data.LRCValue,

              };
              createdSPCParameterSettings.push(setting);
              this.updateToParent(setting);
            }}
            onEditingStart={(event: any) => {
              this.setState({
                testParam: typeof (event.data.testParam) === 'string' ? event.data.testParam : event.data.testParam.id,
                rulesApplied: event.data.rulesApplied,
                autoRowUOM: event.data.uom,
                autoRowLowLimit: event.data.lowLimit,
                autoRowHighLimit: event.data.highLimit,
              });
            }}
            onRowRemoved={(event: any) => {
              createdSPCParameterSettings.forEach((x: any, index: number) => {
                if (x.id === event.data.id) {
                  createdSPCParameterSettings.splice(index, 1);
                  this.setState(createdSPCParameterSettings);
                }
              });
              onUpdate(createdSPCParameterSettings);
            }}
            tableData={createdSPCParameterSettings}
            fields={[
              {
                caption: 'SPC #',
                dataField: 'serialNumber',
                dataType: 'string',
                allowEditing: false,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'textBox',
                fixed: true,
                cellData: {
                  type: 'string',
                },
              },
              {
                caption: 'Parameter Type',
                dataField: 'parameterType',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'selectBox',
                cellData: {
                  listItem: [
                    // { caption: 'Hard Bin', dataField: 'Hard Bin' },
                    // { caption: 'Soft Bin', dataField: 'Soft Bin' },
                    { caption: 'Parametric', dataField: 'Parametric' },
                    // { caption: 'Inline', dataField: 'Inline' },
                  ],
                },
                isRequired: true,
                width: 150,
                fixed: true,
              },
              {
                caption: 'Parameter / Bin',
                dataField: 'parameterOrBin',
                dataType: 'object',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                isRequired: true,
                width: 150,
                cellType: 'conditional',
                cellComponent: ConditionalParameterOrBinBox,
                cellData: {
                  listItem: testParameters,
                  conditions: ['Hard Bin', 'Soft Bin', 'Parametric', 'Inline'],
                  setAutofillValues: this.setAutofillValues,
                  binDefinitions,
                  columnsList,
                  searchCriteria,
                },
                fixed: true,
              },
              {
                caption: 'Data Limit Type',
                dataField: 'dataLimitType',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                isRequired: true,
                width: 150,
                cellType: 'conditional',
                cellComponent: ConditionalDataLimitTypeForSPCParameterSettings,
                cellData: { callBackOnUpdate: this.handleDataLimitType, conditions: ['Parametric', 'Hard Bin', 'Soft Bin'] },
                fixed: true,

              },
              {
                // this value is set in ConditionalParameterOrBinBox automatically
                caption: 'UOM',
                dataField: 'uom',
                dataType: 'string',
                allowEditing: false,
                showInfo: true,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'textBox',
                width: 100,
                cellData: {
                  type: 'string',
                },

              },
              {
                caption: 'Low Limit',
                dataField: 'lowLimit',
                dataType: 'number',
                allowEditing: isLowLimitEditable && isLimitEditable && !AppConstants.isTempHidden,
                isRequired: isLowLimitEditable && isLimitEditable && !AppConstants.isTempHidden,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'textBox',
                width: 100,
                cellData: {
                  type: 'string',
                },
              },
              {
                caption: 'High Limit',
                dataField: 'highLimit',
                dataType: 'number',
                allowEditing: isHighLimitEditable && isLimitEditable && !AppConstants.isTempHidden,
                isRequired: isHighLimitEditable && isLimitEditable && !AppConstants.isTempHidden,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'textBox',
                width: 100,
                cellData: {
                  type: 'number',
                },
              },
              {
                caption: 'Measure Point By',
                dataField: 'measurePointBy',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'selectBox',
                cellData: {
                  listItem: [
                    // { caption: 'Site', dataField: 'Site' },
                    { caption: 'Die', dataField: 'Die' },
                    { caption: 'Wafer', dataField: 'Wafer' },
                    // { caption: 'Lot' },
                  ],
                  callBackOnUpdate: this.handleUpdateMeasureValueBy,
                },
                isRequired: true,
                width: 150,
              },
              {
                caption: 'Meaured Value',
                dataField: 'measuredValue',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'selectBox',
                cellData: {
                  listItem: measuredValueOptions,
                },
                isRequired: true,
                width: 150,
              },
              {
                caption: 'Group By',
                dataField: 'groupBy',
                dataType: 'string',
                allowEditing: !AppConstants.isTempHidden,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'conditional',
                cellComponent: GroupingSortingDropdown,
                cellData: {
                  DialogType: 'GROUP',
                  filterCriteria: [],
                  columnsList,
                },
                isSetCellValueRequired: false,
                calculateDisplayValue: (data: any) => groupByView(data),
                width: 150,
              },
              {
                caption: 'Sort By',
                dataField: 'sortBy',
                dataType: 'string',
                allowEditing: !AppConstants.isTempHidden,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'conditional',
                cellComponent: GroupingSortingDropdown,
                cellData: {
                  DialogType: 'SORT',
                  filterCriteria: [],
                  columnsList,
                },
                isSetCellValueRequired: false,
                calculateDisplayValue: (data: any) => sortByView(data),
                width: 150,
              },
              {
                caption: 'Rules Applied',
                dataField: 'rulesAppliedName',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'conditional',
                cellComponent: SPCAppliedRulesBox,
                cellData: { receivedRules, onUpdateAppliedRules: this.handleUpdateAppliedRules },
                // isSetCellValueRequired: false,
                // calculateDisplayValue: (data: any) => rulesAppliedDisplayValue(data),
                isRequired: true,
                width: 150,
              },
              {
                caption: 'Trigger Limit Violation',
                dataField: 'triggerLimitViolation',
                dataType: 'string',
                allowEditing: isTriggerLimitViolationEditable,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'selectBox',
                cellData: {
                  listItem: triggerLimitViolationOptions,
                },
                isRequired: isTriggerLimitViolationEditable,
                width: 150,
              },
              {
                caption: 'Actions',
                dataField: 'actions',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'tagBox',
                cellData: {
                  listItem: [
                    // { caption: 'Raise Alarm', dataField: 'Raise Alarm' },
                    { caption: 'Send Email', dataField: 'Send Email' },
                  ],
                },
                isRequired: true,
                width: 150,
              },
              {
                // 'Minimum points for seed limit & monitoring',
                caption: 'Min. points for seed limit',
                dataField: 'pointsForSeedLimit',
                dataType: 'number',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'textBox',
                isRequired: true,
                width: 150,
                cellData: {
                  type: 'number',
                },
                validationRules: [
                  (
                    <PatternRule
                      message="Please enter a number between 1 and 999999999"
                      pattern={/^[1-9]\d{0,8}$/}
                    />
                  ),
                ],
              },
              {
                caption: 'Limit Recalculation Cycle',
                dataField: 'limitRecalculationCycle',
                dataType: 'string',
                allowEditing: true,
                showInfo: false,
                allowFiltering: false,
                allowSorting: false,
                cellType: 'columns',
                fields: [
                  {
                    caption: '(#)',
                    dataField: 'LRCDurationValue',
                    dataType: 'number',
                    allowEditing: isLimitRecalculationCycleEditable,
                    showInfo: false,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'textBox',
                    width: 150,
                    cellData: {
                      type: 'number',
                    },
                    validationRules: [
                      (
                        <PatternRule
                          message="Please enter a number between 1 and 999999999"
                          pattern={/^[1-9]\d{0,8}$/}
                        />
                      ),
                    ],

                  },
                  {
                    caption: '(Duration)',
                    dataField: 'LRCDurationType',
                    dataType: 'string',
                    allowEditing: isLimitRecalculationCycleEditable,
                    showInfo: false,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'selectBox',
                    cellData: {
                      listItem: [
                        { caption: 'Day', dataField: 'Day' },
                        { caption: 'Week', dataField: 'Week' },
                        { caption: 'Month', dataField: 'Month' },
                      ],
                    },

                    width: 150,
                  },
                  {
                    caption: '(Expression)',
                    dataField: 'LRCExpression',
                    dataType: 'string',
                    allowEditing: isLimitRecalculationCycleEditable,
                    showInfo: false,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'selectBox',
                    cellData: {
                      listItem: [
                        { caption: 'OR', dataField: 'OR' },
                        { caption: 'AND', dataField: 'AND' },
                      ],
                    },

                    width: 150,
                  },
                  {
                    caption: '(UOM)',
                    dataField: 'LRCuom',
                    dataType: 'string',
                    allowEditing: isLimitRecalculationCycleEditable,
                    showInfo: false,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'selectBox',
                    cellData: {
                      listItem: [
                        // { caption: 'Lot', dataField: 'Lot' },
                        // { caption: 'Wafer', dataField: 'Wafer },
                        { caption: 'Point', dataField: 'Point' },
                      ],
                    },

                    width: 150,
                  },
                  {
                    caption: '(Operator)',
                    dataField: 'LRCOperator',
                    dataType: 'string',
                    allowEditing: isLimitRecalculationCycleEditable,
                    showInfo: false,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'selectBox',
                    cellData: {
                      listItem: [
                        { caption: '==', dataField: '==' },
                        { caption: '>', dataField: '>' },
                      ],
                    },

                    width: 150,
                  },
                  {
                    caption: '(Value)',
                    dataField: 'LRCValue',
                    dataType: 'number',
                    allowEditing: isLimitRecalculationCycleEditable,
                    allowFiltering: false,
                    allowSorting: false,
                    cellType: 'textBox',
                    width: 150,
                    cellData: {
                      type: 'number',
                    },
                    validationRules: [
                      (
                        <PatternRule
                          message="Please enter a number between 1 and 999999999"
                          pattern={/^[1-9]\d{0,8}$/}
                        />
                      ),
                    ],
                  },

                ],

              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default SPCParameterSettings;
