/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
import { ISelectionCriteriaReturnValue, ISelectionsObject } from 'interfaces';
import React from 'react';
import { httpSCW } from 'services/http.scw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import './selection-criteria.scss';
// eslint-disable-next-line import/no-unresolved
import { hopscotch } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  Button, Accordion, OverlayTrigger, Popover, Modal, Spinner,
} from 'react-bootstrap';
import { faFilter, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import toast from 'CustomToast';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import GeneralUtils, { toTitleCase } from 'GeneralUtils';
import SelectionCriteria from './SelectionCriteria';
import {
  emptyDefaultSelectionCriteria, IDefaultSelectionCriteria, ISelectionCritereaWidget, ISelectionCriteriaProps
} from './SelectionCriteriaTypes';
import AppConstants from '../../constants';

interface ISelectionCriteriaButtonState {
  isLoading: boolean;
  show: boolean;
  defaultSelectionInState: IDefaultSelectionCriteria[];
  advancedFilter: string;
  highlightingKeys: { [key: string]: any } | undefined;
  selectionCriteriaDetails: ISelectionDetails;
  selectionCloseState: {
    showDataSetCount: boolean;
    dataSetCount: number;
    showPopover: boolean;
  }
  testParameterIdsAndNames?: ISelectionsObject[];
  isSaveAndCloseClickedSCBState: boolean;
}

interface ISelectionCriteriaButtonProps extends ISelectionCriteriaProps {
  showByDefault?: boolean;
  checkUsageLimits?: boolean | undefined;
  isBinBased?: boolean;
  isForSeedCalculation?: boolean;
  removeAllReports?: () => void,
  populateDefaultSelection?: boolean,
  visibleColumnsForPolicy?: { [entityName: string]: any };
  callbackToUpdateVisibleColumnsForPolicy?: (updatedVisibleColumnsForPolicy : { [entityName: string]: string }) => void,
  closeModalAfterReselection?: () => void;
}

interface ISelectionDetails {
  data: any;
  columnNames: string[];
  columnValues: { [key: string]: any };
  ribbonViewCounts: {
    entityType: string;
    count: number;
  }[],
}

class SelectionCriteriaButton extends React.Component<ISelectionCriteriaButtonProps, ISelectionCriteriaButtonState> {
  static defaultProps = {
    showByDefault: false,
  };

  private selectionStore: ISelectionCriteriaReturnValue;

  private edited: boolean;

  constructor(props: ISelectionCriteriaProps) {
    super(props);
    const {
      defaultSelection,
      advancedFilter,
      showByDefault,
      isSaveAndCloseClickedSCProp,
    } = this.props;

    this.edited = false;
    this.state = {
      isLoading: false,
      show: showByDefault!,
      defaultSelectionInState: !defaultSelection.some(({ values }) => values.length > 0) ? _.cloneDeep(emptyDefaultSelectionCriteria) : defaultSelection,
      advancedFilter: advancedFilter === undefined ? '' : advancedFilter,
      highlightingKeys: undefined,
      selectionCloseState: {
        showDataSetCount: true,
        dataSetCount: 0,
        showPopover: false,
      },
      selectionCriteriaDetails: {
        columnValues: {},
        data: [],
        columnNames: [],
        ribbonViewCounts: [],
      },
      testParameterIdsAndNames: [],
      isSaveAndCloseClickedSCBState: isSaveAndCloseClickedSCProp || false,
    };
    this.selectionStore = {
      selections: [],
      expressions: [],
      advancedFilter: '',
      testParameterConditions: {},
      defaultSelectionCriteria: _.cloneDeep(emptyDefaultSelectionCriteria),
    };
  }

  componentDidMount() {
    const {
      defaultSelection,
      advancedFilter
    } = this.props;
    if (defaultSelection && defaultSelection.some(({ values }) => values.length > 0) && defaultSelection.map((x) => (x.values.length))
      .reduce((a, b) => (a + b)) > 0) {
      this.setSelectionCriteriaDetails(defaultSelection, advancedFilter);
    }
  }

  componentDidUpdate(prevProps: ISelectionCriteriaProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      const {
        defaultSelection,
        advancedFilter,
        showByDefault
      } = this.props;
      if (showByDefault) this.setState({ show: showByDefault });
      if (!this.edited || JSON.stringify(prevProps.defaultSelection) !== JSON.stringify(defaultSelection)) {
        this.setSelectionCriteriaDetails(defaultSelection, advancedFilter);
      } else {
        this.setState({
          defaultSelectionInState: defaultSelection.some(({ values }) => values.length > 0) ? _.cloneDeep(emptyDefaultSelectionCriteria) : defaultSelection,
          advancedFilter: advancedFilter === undefined ? '' : advancedFilter
        });
      }
    }
  }

  setSelectionCriteriaDetails = (defaultSelection: any, advancedFilter: any) => {
    let currentSCWSelection: IDefaultSelectionCriteria[] = [];
    if (defaultSelection.length === 0) {
      currentSCWSelection = _.cloneDeep(emptyDefaultSelectionCriteria);
    } else {
      currentSCWSelection = defaultSelection;
    }
    this.setState({ isLoading: true }, () => {
      httpSCW.getSCWDefaultSelectionCounts(currentSCWSelection)
        .then((data: any) => {
          this.setState({
            isLoading: false,
            selectionCriteriaDetails: data,
            defaultSelectionInState: currentSCWSelection,
            advancedFilter: advancedFilter === undefined ? '' : advancedFilter,
          });
        });
    });
  };

  getSelectionsList = (selectionObject: any) => {
    const { selectionCriteriaDetails } = this.state;
    const { isBinBased } = this.props;
    let description = <></>;
    if (selectionObject.entityType === 'TEST PARAMETER' && isBinBased && isBinBased === true) {
      return <></>;
    }
    if ((selectionCriteriaDetails.columnValues as any)[selectionObject.entityType].length > 0) {
      description = (
        <Button variant="clear" type="button" className="border-none p0 ml6 color-light color-light-hover">
          <i className="icon dx-icon-info" />
        </Button>
      );
    }
    const selectionButton = (
      <div className="p0 cursor-default">
        <div className="d-inline pl8 pr8 pt6 pb6 background-color-secondary color-light">
          {selectionObject.count === 1 ? selectionObject.entityType : `${selectionObject.entityType}(s)`}
          {description}
        </div>
        <div className="d-inline pl6 pr6 pt6 pb6 background-color-primary color-light">
          {selectionObject.count}
        </div>
      </div>
    );
    if ((selectionCriteriaDetails.columnValues as any)[selectionObject.entityType].length > 0) {
      const selectionListItems = (selectionCriteriaDetails.columnValues as any)[selectionObject.entityType].map((item: string) => (<li>{item}</li>));
      return (
        <OverlayTrigger
          trigger="click"
          key={`${selectionObject.entityType}_selection_list`}
          placement="bottom"
          rootClose
          overlay={(
            <Popover
              id={`${selectionObject.entityType}_selection_list_popover`}
              style={{
                maxHeight: '350px',
                overflowY: 'auto',
                whiteSpace: 'pre-wrap',
              }}
            >
              <Popover.Content>
                <ul className="ribbon-view-lists">
                  {selectionListItems}
                </ul>
              </Popover.Content>
            </Popover>
          )}
        >
          {selectionButton}
        </OverlayTrigger>
      );
    }
    return selectionButton;
  };

  getSelectionDetailsFields() {
    const { isBinBased } = this.props;
    let selectionDetailsFields = [
      {
        caption: 'Facility Name',
        dataField: 'facility_name',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Work Center Name',
        dataField: 'work_center_name',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Device Name',
        dataField: 'device_name',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Test Program Name',
        dataField: 'test_program_name',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Test Program Revision Number',
        dataField: 'test_program_revision_revision_number',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Lot Id',
        dataField: 'lot_key',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Wafer Id',
        dataField: 'wafer_key',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
      {
        caption: 'Test Parameter Name',
        dataField: 'test_parameter_name',
        dataType: 'text',
        showInfo: false,
        cellRender: (e: any) => this.highlightSelectedValue(e),
      },
    ];
    if (isBinBased && isBinBased === true) {
      selectionDetailsFields = selectionDetailsFields.filter((x: any) => x.dataField !== 'test_parameter_name');
    }
    return selectionDetailsFields;
  }

  highlightSelectedValue = (e: any) => {
    if (e.rowType === 'data') {
      const rowValues: any[] = e.values;
      const currentValue = e.value;
      if (currentValue === null || currentValue === undefined) {
        return '';
      }

      if (rowValues.length > 0) {
        let lastNonNullIndex = 0;
        for (let i = rowValues.length - 1; i >= 0; i -= 1) {
          if (rowValues[i] !== null && rowValues[i] !== undefined) {
            lastNonNullIndex = i;
            break;
          }
        }
        if (rowValues[lastNonNullIndex] === currentValue) {
          return <div className="p6 rounded background-color-info color-light">{currentValue}</div>;
        }
      }
    }
    return e.value;
  };

  handleRowSelection = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    this.selectionStore = selectionCriteriaData;
    this.edited = true;
  };

  checkAllMandatorySelections = (selectionPerEntityType: IDefaultSelectionCriteria[]) => {
    let isSelectionForMandatoryWidgets = true;
    const { widgetsList } = this.props;
    widgetsList.forEach((widget) => {
      const entityTypeNameArray = widget.controlType.split(' ');
      let entitySnakeName = toTitleCase(entityTypeNameArray[0], false);
      if (entityTypeNameArray.length > 1) {
        for (let i = 1; i < entityTypeNameArray.length; i += 1) {
          entitySnakeName += entityTypeNameArray[i].toLowerCase();
        }
      }
      if (selectionPerEntityType.filter((x) => x.entityType === entitySnakeName)[0].values.length === 0 && widget.isSelectionMandatory === true) {
        toast.warning(`Selection for ${widget.controlType} is mandatory`);
        isSelectionForMandatoryWidgets = false;
      }
    });

    return isSelectionForMandatoryWidgets;
  };

  handleModalClose = (
    preserveSelection: boolean,
    highlightingKeys: { [key: string]: any },
    keepModalOpen: boolean,
    isCleared: boolean,
    updatedVisibleColumnsForPolicy: { [entityName: string]: string },
  ) => {
    if (this.edited && preserveSelection) {
      const {
        selections,
        advancedFilter,
        defaultSelectionCriteria,
      } = this.selectionStore;
      const {
        widgetsList,
        checkUsageLimits,
        callbackToUpdateVisibleColumnsForPolicy,
        closeModalAfterReselection,
      } = this.props;
      closeModalAfterReselection?.();
      const widgetNames = widgetsList.map((widget: ISelectionCritereaWidget) => {
        const entityTypeNameArray = widget.controlType.split(' ');
        // FOR SAVING
        let entitySnakeName = toTitleCase(entityTypeNameArray[0], false);

        if (entityTypeNameArray.length > 1) {
          for (let i = 1; i < entityTypeNameArray.length; i += 1) {
            entitySnakeName += entityTypeNameArray[i].toLowerCase();
          }
        }

        return entitySnakeName;
      });

      selections.forEach((selectionItem) => {
        const widgetIndex = widgetNames.findIndex(
          (widget) => widget.toLowerCase() === selectionItem.entityType.toLowerCase(),
        );
        if (widgetIndex >= 0) {
          selectionItem.entityType = widgetNames[widgetIndex];
        }
      });

      const selectionPerEntityType: IDefaultSelectionCriteria[] = selections.map((item, index: number) => ({
        entityType: item.entityType,
        values: item.values.map((value: ISelectionsObject) => (value.id)),
        visibleColumnsValues: item.values.map((value: ISelectionsObject) => ({ id: value.id })),
        visibleColumns: item.values.length > 0 ? ['id'] : [],
      }));

      const testParameterIdsAndNames = selections.find((selection) => selection.entityType === 'Testparameter')?.values;

      const dataPointLimit = AppConstants.scwDataPointLimit;

      let selectionCloseState: {
        showDataSetCount: boolean;
        dataSetCount: number;
        showPopover: boolean;
      } = {
        showDataSetCount: true,
        dataSetCount: 0,
        showPopover: false,
      };
      let selectionCriteriaDetails = {
        columnValues: {},
        data: [],
        columnNames: [],
        ribbonViewCounts: [],
      };
      let show = keepModalOpen;
      const defaultSelection = selectionPerEntityType;
      let callback = () => {
        const { getSelectionCriteriaData } = this.props;
        getSelectionCriteriaData(this.selectionStore, 0);
        GeneralUtils.clearScwVars();
      };

      if (!isCleared) {
        if (this.checkAllMandatorySelections(selectionPerEntityType)) {
          this.setState({ isSaveAndCloseClickedSCBState: true });
          if (callbackToUpdateVisibleColumnsForPolicy) {
            callbackToUpdateVisibleColumnsForPolicy(updatedVisibleColumnsForPolicy);
          }
          httpSCW.getSCWDefaultSelectionCounts(selectionPerEntityType)
            .then((data: any) => {
              const isUsageLimitReached = (checkUsageLimits === true) && (data.dataSetCount > dataPointLimit);

              selectionCloseState = {
                showDataSetCount: true,
                dataSetCount: data.dataSetCount,
                showPopover: isUsageLimitReached,
              };
              show = isUsageLimitReached ? true : keepModalOpen;
              selectionCriteriaDetails = isUsageLimitReached ? {
                columnValues: {},
                data: [],
                columnNames: [],
                ribbonViewCounts: [],
              } : data;
              callback = () => {
                if (!isUsageLimitReached) {
                  const { getSelectionCriteriaData } = this.props;
                  getSelectionCriteriaData(this.selectionStore, data.dataSetCount);
                }
              };

              this.setState({
                selectionCloseState,
                show,
                selectionCriteriaDetails,
                defaultSelectionInState: defaultSelectionCriteria ? defaultSelectionCriteria : _.cloneDeep(emptyDefaultSelectionCriteria),
                advancedFilter,
                highlightingKeys,
                testParameterIdsAndNames,
                isLoading: false,
              }, callback);
            });
        }
      } else {
        defaultSelection.forEach((entity: IDefaultSelectionCriteria) => {
          entity.values = [];
          entity.isVisibiltyDefault = true;
          entity.visibleColumnsValues = [];
        });
        this.setState({
          selectionCloseState,
          show,
          selectionCriteriaDetails,
          defaultSelectionInState: defaultSelection,
          advancedFilter,
          highlightingKeys,
          testParameterIdsAndNames,
        }, callback);
      }
    } else {
      if (!preserveSelection) {
        const {
          defaultSelection,
          advancedFilter
        } = this.props;
        this.setSelectionCriteriaDetails(defaultSelection, advancedFilter);
      }
      this.setState({ show: keepModalOpen });
    }
  };

  render() {
    const {
      show,
      defaultSelectionInState,
      advancedFilter,
      highlightingKeys,
      selectionCriteriaDetails,
      selectionCloseState,
      testParameterIdsAndNames,
      isLoading,
      isSaveAndCloseClickedSCBState,
    } = this.state;

    const {
      widgetsList,
      filterCriteria,
      isBinBased,
      isForSeedCalculation,
      removeAllReports,
      populateDefaultSelection,
      visibleColumnsForPolicy,
      callbackToUpdateVisibleColumnsForPolicy
    } = this.props;

    return (
      <div className="border-all p10 background-color-light border-radius-4">
        <div>
          <Accordion>
            <div>
              <div
                className="d-flex align-items-center justify-content-between flex-wrap"
              >
                <div className="d-flex align-items-center flex-wrap mt6">
                  <h5 className="mr10">Selection Criteria</h5>
                  {
                    selectionCriteriaDetails.ribbonViewCounts.map((item) => (
                      <div key={`${item.entityType}-ribbon-view-item`} className="border-all text-center mr6 mt-4">
                        {this.getSelectionsList(item)}
                      </div>
                    ))
                  }
                </div>
                <div>
                  <Button
                    variant="primary"
                    className="mr10"
                    onClick={() => {
                      this.setState({ show: true, isSaveAndCloseClickedSCBState: false });
                    }}
                  >
                    <FontAwesomeIcon className="mr10" size="sm" icon={faFilter} />
                    Select Selection Criteria
                  </Button>
                  <Accordion.Toggle
                    as={Button}
                    eventKey="0"
                    variant={isLoading ? 'warning' : 'clear'}
                    className={`transition-smooth ${isLoading ? '' : 'btn-config w50'} p4 h32`}
                  >
                    {
                      isLoading
                        ? (
                          <div className="d-flex align-items-center pl10 pr10">
                            <Spinner size="sm" animation="border" />
                            <p className="mb0 pl10">Retrieving Selection Counts</p>
                          </div>
                        )
                        : <FontAwesomeIcon size="sm" icon={faChevronDown} />
                    }
                  </Accordion.Toggle>
                </div>

              </div>

              <Accordion.Collapse eventKey="0">
                <div className="pt10">
                  {
                    advancedFilter !== '' && (
                      <>
                        <h6 className="d-inline-block">Custom Expression</h6>
                        <SyntaxHighlighter language="sql" style={hopscotch}>
                          {advancedFilter}
                        </SyntaxHighlighter>
                      </>
                    )
                  }
                  {
                    defaultSelectionInState.filter((item) => item.values.length > 0).length === 0
                      ? (
                        <div className="h180 w-100 d-flex align-items-center justify-content-center">
                          <div className="text-center">
                            No Selection Criteria Selected
                          </div>
                        </div>
                      ) : (
                        <>
                          <h5>Selection Details</h5>

                          <CustomizedDataGrid
                            fields={this.getSelectionDetailsFields()}
                            tableData={selectionCriteriaDetails.data}
                          />
                        </>
                      )
                  }
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>

        <Modal
          show={show}
          enforceFocus={false}
          dialogClassName="selection-criteria-modal-width"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ alignItems: 'start' }}>
            <div className="w-100 position-relative h-100">
              {
                selectionCloseState.showPopover && selectionCloseState.showDataSetCount && (
                  <div
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.4)',
                    }}
                    className="top-0 z-index-top position-absolute w-100 h-100  d-flex align-items-center justify-content-center"
                  >
                    <div className="w350 p10 rounded background-color-light shadow-sm">
                      <div className="p6 border-bottom">
                        <h6>Caution</h6>
                      </div>
                      <div className="p6">
                        {'You have selected '}
                        <b>
                          {selectionCloseState.dataSetCount}
                        </b>
                        {' data points.'}
                        <br />
                        {'You can select a maximum of '}
                        <b>{AppConstants.scwDataPointLimit}</b>
                        {' data points.'}
                        <br />
                        Please reselect the data.
                      </div>
                      <div className="w-100 d-flex align-items-center flex-row-reverse">
                        <Button
                          onClick={() => {
                            const tempSelectionCloseState = _.cloneDeep(selectionCloseState);
                            tempSelectionCloseState.showPopover = false;
                            this.setState({ selectionCloseState: tempSelectionCloseState });
                          }}
                          variant="warning"
                        >
                          Ok
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }
              <SelectionCriteria
                // defaultHighlightingKeys={highlightingKeys}
                filterCriteria={filterCriteria}
                returnDataOnSelectButtonClick
                closeSelectionCriteriaModal={this.handleModalClose}
                getSelectionCriteriaData={this.handleRowSelection}
                widgetsList={widgetsList}
                defaultSelection={defaultSelectionInState}
                advancedFilter={advancedFilter}
                testParameterIdsAndNames={testParameterIdsAndNames}
                removeAllReports={removeAllReports}
                isForSeedCalculation={isForSeedCalculation}
                selectionCounts={selectionCriteriaDetails.ribbonViewCounts}
                overrideSelectionCriteria={this.setSelectionCriteriaDetails}
                populateDefaultSelectionFlag={populateDefaultSelection}
                isSaveAndCloseClickedSCProp={isSaveAndCloseClickedSCBState}
                visibleColumnsForPolicy={visibleColumnsForPolicy}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default SelectionCriteriaButton;
