import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface IButtonProps {
  children: React.ReactNode;
  hint?: IHint;
  full?:boolean;
  type?: string,
  className?: string;
  variant?:string;
  size?: 'sm' | 'lg' | undefined;
  onClick?: any;
  disabled?:boolean;
  title?:string;
  visible?:boolean;
}

const Button = (props:IButtonProps) => {
  const {
    children, hint, full, type, className, variant, size, onClick, disabled, title, visible,
  } = props;

  return (
    <BootstrapButton
      disabled={disabled}
      block={full}
      type={type}
      title={title}
      className={visible ? className : `d-none ${className}`}
      variant={variant}
      size={size}
      onClick={(e:any) => {
        if (onClick !== undefined) {
          onClick(e);
        }
      }}
    >
      {children}
      <Hint hint={hint} variant={variant?.includes('outline') ? 'outline-button' : 'light'} />
    </BootstrapButton>
  );
};

Button.defaultProps = {
  full: false,
  type: undefined,
  className: '',
  variant: 'clear',
  size: 'sm',
  onClick: () => undefined,
  disabled: false,
  visible: true,
};

export default Button;
