import React from "react";
import {
  faBroadcastTower,
  faProjectDiagram,
  faLayerGroup,
  faMapMarker,
  faFlask,
  faPalette,
  faCircleNotch,
  faChartBar,
  faFolderPlus,
  faTruckLoading,
  faHammer,
  faFilter,
  faTachometerAlt,
  faChartPie,
  faChartLine,
  faFileAlt,
  faBraille,
  faTable,
  faBoxes,
  faTrashRestore,
  faCodeBranch,
  faCoins,
  faVial,
  faDatabase,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import SelectionCriteria from "components/selection-criteria/SelectionCriteria";
import DeviceSetups from "components/device-setup/device-setups";
import DeviceSetup from "components/device-setup/device-setup";
import BinPlusTables from "components/bin-plus-tables/bin-plus-tables";
import BinPlusTable from "components/bin-plus-tables/bin-plus-table";
import WaferControlMap from "components/wafer-control-map/wafer-control-map";
import CustomizedReportsCollection from "views/individual-reports/customized-report/CustomizedReportsCollection";
import Testing from "components/utility-component/wafer-map-widget/wafer-map-v2/testing";
import IndividualRawDataGrid from "views/individual-reports/individual-raw-data-grid/IndividualRawDataGrid";
import WaferControlMaps from "components/wafer-control-map/wafer-control-maps";
import GeneralUtils, {
  ReportTypeConstants as ReportType,
  toTitleCase,
} from "GeneralUtils";
import ControlTower from "./views/control-tower/ControlTower";
import { Rules } from "./views/rules/Rules";
import Utilities from "./views/utilities/Utilities";
import Policies from "./views/policies/Policies";
import VisualizeInfrastructure from "./views/visualize-infrastructure/VisualizeInfrastructure";
import CreatePolicy from "./views/create-policy/CreatePolicy";
import VersionHistory from "./views/version-history/VersionHistory";
import PolicyResults from "./views/policy-results/PolicyResults";
import StyleGuide from "./views/StyleGuide";
import PolicyExecution from "./views/policy-execution/PolicyExecution";
import Histogram from "./components/reports/histogram/bin-histogram";
import CardReport from "./components/reports/report-card/CardReport";
import ViewPolicyOutput from "./views/view-policy-output/ViewPolicyOutput";
import ViewWaferImageGenerator from "./views/view-wafer-image-generator/ViewWaferImageGenerator";
import CreateLoaderPolicy from "./views/create-loader-policy/CreateLoaderPolicy";
import LoaderPolicies from "./views/policies/LoaderPolicies";
import Login from "./components/iam/login";
import AMGPickMap from "./components/manual/amg-pick-map/AMGPickMap";
import AppConstants from "./constants";
import DatabaseSelection from "views/database-selection/DatabaseSelection";

const routes = [
  {
    path: "/login",
    content: <Login />,
    visibleInSidebar: false,
  },
  {
    path: "/control-tower",
    icon: faBroadcastTower,
    title: "Control Tower",
    visibleTo: [],
    content: <ControlTower />,
    visibleInSidebar: true,
  },
  {
    path: "/policy",
    icon: faLayerGroup,
    title: "Policies",
    visibleTo: [],
    content: <Policies />,
    visibleInSidebar: true,
  },
  {
    path: "/loader-policy",
    icon: faTruckLoading,
    title: "Loader Policies",
    visibleTo: [],
    content: <LoaderPolicies />,
    visibleInSidebar: true,
  },
  {
    path: "/utilities",
    icon: faCircleNotch,
    title: "Wafer Map",
    visibleTo: [],
    content: <Utilities className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/selection",
    icon: faFilter,
    title: "Selection Criteria",
    visibleTo: [],
    content: (
      <div className="pl20 pt20 body-content-scroll">
        <SelectionCriteria
          getSelectionCriteriaData={() => undefined}
          widgetsList={[
            {
              controlType: "Facility",
              columnWeight: 3,
              isVisible: true,
            },
            {
              controlType: "Work Center",
              columnWeight: 3,
              isVisible: true,
            },
            {
              controlType: "Device",
              columnWeight: 3,
              isVisible: true,
            },
            {
              controlType: "Test Program",
              columnWeight: 3,
              isVisible: true,
            },
            {
              controlType: "Lot",
              columnWeight: 3,
              isVisible: true,
            },
            {
              controlType: "Wafer",
              columnWeight: 9,
              isVisible: true,
            },
            {
              controlType: "Test Parameter",
              columnWeight: 12,
              isVisible: true,
              highlightOtherGrids: false,
            },
          ]}
        />
      </div>
    ),
    visibleInSidebar: false,
  },
  {
    path: "/histogram",
    icon: faChartBar,
    title: "Pareto Report",
    visibleTo: [],
    content: <Histogram />,
    visibleInSidebar: false,
  },
  {
    path: "/card",
    icon: faChartBar,
    title: "Card",
    visibleTo: [],
    content: (
      <CardReport title="Bin Histogram" className="border-all p20">
        <h1>Children</h1>
        <h1>Children</h1>
        <h1>Children</h1>
      </CardReport>
    ),
    visibleInSidebar: false,
  },
  {
    path: "/policy-results",
    icon: faFlask,
    title: "Policy Results",
    visibleTo: [],
    content: <PolicyResults className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/rules",
    icon: faProjectDiagram,
    title: "Rules",
    visibleTo: [],
    content: <Rules className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/utilities",
    icon: faFlask,
    title: "Utilities",
    visibleTo: [],
    content: <Utilities className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/device-setups",
    icon: faHammer,
    title: "Device Setups",
    visibleTo: [],
    content: <DeviceSetups className="body-content-scroll" />,
    visibleInSidebar: true,
  },
  {
    path: "/testing",
    icon: faHammer,
    title: "Wafer testing",
    visibleTo: [],
    content: <Testing />,
    visibleInSidebar: false,
  },
  {
    path: "/bin-plus-tables",
    icon: faFolderPlus,
    title: "Bin Plus Tables",
    visibleTo: [],
    content: <BinPlusTables className="body-content-scroll" />,
    visibleInSidebar: true,
  },
  {
    path: "/create-device-setup",
    title: "create-device-setup",
    visibleTo: [],
    content: <DeviceSetup className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/create-device-setup/:id",
    title: "create-device-setup",
    visibleTo: [],
    content: <DeviceSetup className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/create-bin-plus-table/",
    title: "Create Bin Plus Table",
    visibleTo: [],
    content: <BinPlusTable />,
    visibleInSidebar: false,
  },
  {
    path: "/create-bin-plus-table/:id",
    title: "Create Bin Plus Table",
    visibleTo: [],
    content: <BinPlusTable />,
    visibleInSidebar: false,
  },
  {
    path: "/style-guide",
    icon: faPalette,
    title: "Style Guide",
    visibleTo: [],
    content: <StyleGuide className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/versionHistory",
    icon: faFlask,
    title: "Version History",
    visibleTo: [],
    content: (
      <VersionHistory className="y-scroll-with-hidden-scrollbar height-100vh p20" />
    ),
    visibleInSidebar: false,
  },
  {
    path: "/create-policy",
    title: "Create Policy",
    visibleTo: [],
    content: <CreatePolicy className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/wafer-control-map/:id?",
    title: "Wafer Control Map",
    visibleTo: [],
    content: <WaferControlMap />,
    visibleInSidebar: false,
  },
  {
    path: "/wafer-control-maps",
    title: "Wafer Control Maps",
    visibleTo: [],
    content: <WaferControlMaps />,
    visibleInSidebar: true,
    icon: faCircleNotch,
  },
  {
    path: "/create-policy/:id",
    title: "Create Policy",
    visibleTo: [],
    content: <CreatePolicy className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/create-loader-policy",
    title: "Create Loader Policy",
    visibleTo: [],
    content: <CreateLoaderPolicy className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/create-loader-policy/:id",
    title: "Create Loader Policy",
    visibleTo: [],
    content: <CreateLoaderPolicy className="" />,
    visibleInSidebar: false,
  },
  {
    path: "/visualize-infrastructure",
    icon: faFlask,
    title: "Visualize Infrastructure",
    visibleTo: [],
    content: <VisualizeInfrastructure className="body-content-scroll" />,
    visibleInSidebar: false,
  },
  {
    path: "/policy-execution",
    icon: faFlask,
    title: "Policy Execution",
    visibleTo: [],
    content: <PolicyExecution />,
    visibleInSidebar: false,
  },
  {
    path: "/simulate-policy/:id",
    icon: faMapMarker,
    title: "Simulate Policy",
    content: <ViewPolicyOutput />,
    visibleInSidebar: false,
  },
  {
    path: "/view-policy-output/:id",
    icon: faMapMarker,
    title: "View Policy Output",
    content: <ViewPolicyOutput />,
    visibleInSidebar: false,
  },
  {
    path: "/wafer-image-generator/:id",
    icon: faMapMarker,
    title: "Wafer Image Generator",
    content: <ViewWaferImageGenerator />,
    visibleInSidebar: false,
  },
  {
    path: "/correlation",
    icon: faTachometerAlt,
    title: "Correlation Report",
    content: (
      <CustomizedReportsCollection
        type="INDIVIDUAL_REPORT"
        title="Power View"
        reportList={[]}
      />
    ),
    visibleInSidebar: false,
  },
  {
    path: "/manual-amg",
    icon: faChartPie,
    title: "Manual AMG",
    content: <AMGPickMap />,
    visibleInSidebar: true,
  },
  {
    path: "/raw-data-grid",
    icon: faChartPie,
    title: toTitleCase(ReportType.RAW_DATA_GRID),
    content: <></>,
    visibleInSidebar: false,
  },
  {
    path: "/power-view",
    icon: faTachometerAlt,
    title: "Power View",
    content: (
      <CustomizedReportsCollection
        type="INDIVIDUAL_REPORT"
        title="Power View"
        reportList={[]}
      />
    ),
    visibleInSidebar: true,
  },
  {
    path: "",
    icon: faVial,
    title: "Parametric Reports",
    content: <></>,
    visibleInSidebar: true,
    subRoutes: [
      {
        path: "/parametric-histogram-report",
        icon: faChartBar,
        title: "Histogram",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.PARAMETRIC_HISTOGRAM)}
            reportList={[ReportType.PARAMETRIC_HISTOGRAM]}
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/parametric-trend-report",
        icon: faChartLine,
        title: "Trend",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.PARAMETRIC_TREND)}
            reportList={[ReportType.PARAMETRIC_TREND]}
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/parametric-wafer-map",
        icon: faCircleNotch,
        title: "Wafer Map",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.PARAMETRIC_WAFER_MAP)}
            reportList={["PARAMETRIC_WAFER_MAP"]}
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/parametric-box-plot-report",
        icon: faBoxes,
        title: "Box Plot",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.PARAMETRIC_BOX_PLOT)}
            reportList={[ReportType.PARAMETRIC_BOX_PLOT]}
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/parametric-xy-scatter-plot-report",
        icon: faBraille,
        title: "XY Scatter Plot",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(
              ReportType.PARAMETRIC_XY_SCATTER_PLOT
            )}
            reportList={[ReportType.PARAMETRIC_XY_SCATTER_PLOT]}
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/parametric-failure-report",
        icon: faChartBar,
        title: "Failure Report",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.PARAMETRIC_FAILURE)}
            reportList={[ReportType.PARAMETRIC_FAILURE]}
          />
        ),
        visibleInSidebar: true,
      },
    ],
  },
  {
    path: "",
    icon: faTrashRestore,
    title: "Bin Report",
    content: <></>,
    visibleInSidebar: true,
    subRoutes: [
      {
        path: "/bin-pareto-report",
        icon: faChartBar,
        title: "Pareto",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.BIN_PARETO)}
            reportList={[ReportType.BIN_PARETO]}
            isBinBased
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/bin-wafer-map-report",
        icon: faCircleNotch,
        title: "Wafer Map",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.BIN_WAFER_MAP)}
            reportList={[ReportType.BIN_WAFER_MAP]}
            isBinBased
          />
        ),
        visibleInSidebar: true,
      },
      {
        path: "/bin-wafer-map-report-comparison",
        icon: faCircleNotch,
        title: "Wafer Map Comparison",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(
              ReportType.BIN_WAFER_MAP_COMPARISON
            )}
            reportList={[ReportType.BIN_WAFER_MAP_COMPARISON]}
            isBinBased
          />
        ),
        visibleInSidebar: true,
      },
    ],
  },
  {
    path: "",
    icon: faFileAlt,
    title: "Misc",
    content: <></>,
    visibleInSidebar: true,
    subRoutes: [
      {
        path: "/data-extraction",
        icon: faTable,
        title: "Data Extraction",
        content: <IndividualRawDataGrid />,
        visibleInSidebar: true,
      },
      {
        path: "/data-summary-report",
        icon: faTable,
        title: "Data Summary",
        content: (
          <CustomizedReportsCollection
            type="INDIVIDUAL_REPORT"
            title={GeneralUtils.toTitleCase(ReportType.DATA_SUMMARY)}
            reportList={[ReportType.DATA_SUMMARY]}
          />
        ),
        visibleInSidebar: true,
      },
    ],
  },
  {
    path: "",
    icon: faCodeBranch,
    title: "Correlation Reports",
    content: <></>,
    visibleInSidebar: !AppConstants.isTempHidden,
    subRoutes: [],
  },
  {
    path: "",
    icon: faCoins,
    title: "Yield Reports",
    content: <></>,
    visibleInSidebar: !AppConstants.isTempHidden,
    subRoutes: [],
  },
  {
    path: "/",
    icon: faChartBar,
    title: "Dashboard",
    visibleTo: [],
    content: (
      <div className="h-100 w-100">
        <iframe
          title="BI Dashboard - Cost of Yield"
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiMzBlMjI2MWQtYjk5ZS00YWZlLWFlYjItMmRlNWZhMzM4ZjQ4IiwidCI6IjBhNzBmNTZjLTkxZTctNDk0ZS1iZjgwLTY2MzEzNzQ3Y2QxMiIsImMiOjl9&pageName=ReportSection"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    ),
    visibleInSidebar: true,
  },
  {
    path: "",
    icon: faDatabase,
    title: "Database ( : )",
    visibleTo: [],
    content: null,
    visibleInSidebar: true,
    subRoutes: [
      {
        path: "/change-database",
        icon: faEdit,
        title: "Change Database",
        content: <DatabaseSelection />,
        visibleInSidebar: true,
      },
    ],
  },
];

const quickLinks = [
  {
    path: "help",
    title: "Help",
  },
  {
    path: "tutorials",
    title: "Tutorials",
  },
  {
    path: "issueReport",
    title: "Report an Issue",
  },
];

export default {
  getRoutes() {
    return routes;
  },
  getQuickLinks() {
    return quickLinks;
  },
};
