/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  Row, Col, Button, OverlayTrigger, Popover, Spinner,
} from 'react-bootstrap';
import {
  faArrowLeft, faExclamationTriangle, faSave, faTimes, faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line no-unused-vars
import { ComponentHeaderData } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import _ from 'lodash';
import { Sparkline } from 'devextreme-react';
import Heading from '../hint-controls/Heading';
import Label from '../hint-controls/Label';
import Textbox from '../hint-controls/Textbox';
import Textarea from '../hint-controls/Textarea';
import CustomizedDropdown from '../customized-dropdown/CustomizedDropdown';
import { isValid, } from '../../template-component/form-error-label/ErrorLabel';

interface ComponentHeaderState {
  componentHeaderData: ComponentHeaderData,
  formErrors: { [key: string]: string },
  highlightBorder: any;
}

interface ComponentHeaderProps {
  hideEditorsField?: boolean;
  hideBackButton?: boolean;
  onChange?: (newValues: any) => void;
  componentHeaderData: ComponentHeaderData;
  onParentSave: (data: ComponentHeaderData) => void;
  onBack: () => void;
  isInSavingState?: boolean;
  saveButtonText?: string;
  hideSaveButton?: boolean;
  className?: string | undefined;
  highlightTextBox?: any;
}

export const componentHeaderStates = [['DRAFT', 'Draft'], ['ACTIVE', 'Active'], ['INACTIVE', 'Inactive'], ['OBSOLETE', 'Obsolete']];
export const componentHeaderAccess = [['PUBLIC', 'Public'], ['PRIVATE', 'Private']];

class ComponentHeader extends React.Component<ComponentHeaderProps, ComponentHeaderState> {
  constructor(props: ComponentHeaderProps) {
    super(props);
    const { componentHeaderData } = this.props;
    this.state = {
      componentHeaderData: { ...componentHeaderData },
      formErrors: {},
      highlightBorder: false,
    };
  }

  componentDidUpdate(prevProps: ComponentHeaderProps) {
    const { componentHeaderData } = this.props;
    if (!_.isEqual(prevProps.componentHeaderData, componentHeaderData)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ componentHeaderData: { ...componentHeaderData } });
    }
  }

  validate = (formData: ComponentHeaderData) => {
    const {
      name,
      access,
      description,
      state,
      owner,
    } = formData;
    const { highlightBorder } = this.state;
    const errors: { [key: string]: any } = {};
    if (!name || name === '') {
      errors.name = 'Name Is Required';
    } else if (name.length > 255) {
      errors.name = 'Name Should Not Exceed 255 Characters';
    } else if (!(new RegExp('^[a-zA-Z0-9_ ]+$')).test(name)) {
      errors.name = 'Name Should Only Contain AlphaNumeric Values';
    }
    if (!owner || owner === '') {
      errors.owner = 'Owner Is Required';
    } else if (owner.length > 255) {
      errors.owner = 'Owner Should Not Exceed 255 Characters';
    }
    if (!state || state === '') {
      errors.state = 'State Is Required';
    } else if (state.length > 255) {
      errors.state = 'State Should Not Exceed 255 Characters';
    }
    if (!access || access === '') {
      errors.access = 'Access Is Required';
    } else if (state.length > 255) {
      errors.access = 'Access Should Not Exceed 255 Characters';
    }
    if (!description || description === '') {
      errors.description = 'Description Is Required';
    } else if (description.length > 1023) {
      errors.description = 'Description Should Not Exceed 1023 Characters';
    }
    return errors;
  };

  onSave = () => {
    const { componentHeaderData } = this.state;
    const { onParentSave } = this.props;
    const errors = this.validate(componentHeaderData);
    if (Object.keys(errors).length !== 0) {
      this.setState({ formErrors: errors });
    } else {
      this.setState({ formErrors: errors }, () => {
        onParentSave({ ...componentHeaderData });
      });
    }
    return { ...componentHeaderData };
  };

  render() {
    const {
      componentHeaderData,
      formErrors,
      highlightBorder,
    } = this.state;
    const {
      onBack,
      isInSavingState,
      saveButtonText,
      hideSaveButton,
      onChange,
      hideBackButton,
      hideEditorsField,
      className,
      highlightTextBox,
    } = this.props;

    const utilityActions: { icon: any, tooltip: string, onClick: any }[] = [
      // {
      //   icon: faCopy,
      //   tooltip: 'Copy',
      //   onClick: () => {},
      // },
      // {
      //   icon: faFileImport,
      //   tooltip: 'Import',
      //   onClick: () => {},
      // },
      // {
      //   icon: faFileExport,
      //   tooltip: 'Export',
      //   onClick: () => {},
      // },
    ];

    return (
      <div className={`border-all pt10 pl10 pr10 pb30 border-radius-4 ${className || ''}`}>
        <Row className="pb10">
          <Col>
            <div className="d-flex mt3">
              <Heading size={5}>General Settings</Heading>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="pr0">
            <Row>
              <Col>
                <Label
                  labelTitle="Name"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  required
                  error={formErrors.name}
                  isFieldTouched
                >
                  <Textbox
                    className={highlightTextBox?.Name ? 'error-field-outline' : ''}
                    autoComplete="off"
                    name="name"
                    placeholder="Name"
                    value={componentHeaderData.name}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.setState((prevState: ComponentHeaderState) => (
                        {
                          componentHeaderData: {
                            ...prevState.componentHeaderData,
                            name: value
                          }
                        }
                      ), () => {
                        if (onChange) {
                          onChange({ name: value });
                        }
                      });
                    }}
                  />
                  {highlightTextBox?.Name ? (
                    <div className="text-danger">
                      <FontAwesomeIcon
                        className="cursor-pointer mt8 mr10"
                        size="sm"
                        icon={faExclamationTriangle}
                      />
                      Name is a required field
                    </div>
                  ) : null}
                </Label>
                <Label
                  labelTitle="Owner"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={formErrors.owner}
                  required
                  isFieldTouched
                >
                  <Textbox
                    className={highlightTextBox?.Owner ? 'error-field-outline' : ''}
                    autoComplete="off"
                    name="owner"
                    placeholder="mark.smith@someemail.com"
                    value={componentHeaderData.owner}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.setState((prevState: ComponentHeaderState) => (
                        {
                          componentHeaderData: {
                            ...prevState.componentHeaderData,
                            owner: value
                          }
                        }
                      ), () => {
                        if (onChange) {
                          onChange({ owner: value });
                        }
                      });
                    }}
                  />
                  {highlightTextBox?.Owner ? (
                    <div className="text-danger">
                      <FontAwesomeIcon
                        className="cursor-pointer mt8 mr10"
                        size="sm"
                        icon={faExclamationTriangle}
                      />
                      Owner is a required field
                    </div>
                  ) : null}
                </Label>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Label
                      labelTitle="Access"
                      labelPosition="top"
                      labelSize="100"
                      fieldSize="100"
                      errorSize="100"
                      error={formErrors.access}
                    >
                      <CustomizedDropdown
                        variant="clear"
                        full
                        list={componentHeaderAccess}
                        selectedValue={componentHeaderData.access}
                        onChange={(value: string) => {
                          this.setState((prevState: ComponentHeaderState) => (
                            {
                              componentHeaderData: {
                                ...prevState.componentHeaderData,
                                access: value
                              }
                            }
                          ), () => {
                            if (onChange) {
                              onChange({ access: value });
                            }
                          });
                        }}
                      />
                    </Label>
                  </Col>
                  <Col>
                    <Label
                      labelTitle="Version"
                      labelPosition="top"
                      labelSize="100"
                      fieldSize="100"
                      errorSize="100"
                    >
                      <Textbox
                        autoComplete="off"
                        name="version"
                        readonly
                        style={{ backgroundColor: '#eee' }}
                        placeholder="version"
                        value={componentHeaderData.version}
                      />
                    </Label>
                  </Col>
                </Row>
                <Label
                  labelTitle="State"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={formErrors.state}
                >
                  <CustomizedDropdown
                    variant="clear"
                    full
                    list={componentHeaderStates}
                    selectedValue={componentHeaderData.state}
                    onChange={(value: string) => {
                      this.setState((prevState: ComponentHeaderState) => (
                        {
                          componentHeaderData: {
                            ...prevState.componentHeaderData,
                            state: value
                          }
                        }
                      ), () => {
                        if (onChange) {
                          onChange({ state: value });
                        }
                      });
                    }}
                  />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-between">
                  {
                    utilityActions.map((item, index) => (
                      <Button
                        onClick={item.onClick}
                        variant="clear"
                        size="sm"
                        block
                        className={`${index !== utilityActions.length - 1 ? 'mr10' : ''} mt8 btn-config`}
                      >
                        <FontAwesomeIcon size="sm" icon={item.icon} className="mr10" />
                        {item.tooltip}
                      </Button>
                    ))
                  }
                  {!hideEditorsField
                                        && (
                                          <OverlayTrigger
                                            rootClose
                                            trigger="click"
                                            placement="right"
                                            overlay={(
                                              <Popover id="editor-info" className="w250">
                                                <Popover.Content>
                                                  <div className="d-flex align-items-center justify-content-between break-bottom pb10 mb10">
                                                    <Heading size={6} className="mt10">Audit Trail</Heading>
                                                    <Button variant="link" className="p0 default-font-size">
                                                      Change Log
                                                    </Button>
                                                  </div>
                                                  <div className="d-flex align-items-center justify-content-between default-font-size">
                                                    <div className="mr20">
                                                      <span className="color-icon-color mr7 mt-10">Updated By</span>
                                                      <br />
                                                      <span className="color-dark-three mt-10 mr7">Ken Edwards</span>
                                                      <br />
                                                      <span className="color-dark-three mt-10"><em>26th Jul 2020</em></span>
                                                    </div>
                                                    <div>
                                                      <span className="color-icon-color mt-10">Created By</span>
                                                      <br />
                                                      <span className="color-dark-three mt-10">Matt Anderson</span>
                                                      <br />
                                                      <span className="color-dark-three mt-10"><em>26th Jul 2020</em></span>
                                                    </div>
                                                  </div>
                                                </Popover.Content>
                                              </Popover>
                                            )}
                                          >

                                            <Button variant="clear" size="sm" block className="ml10 mt8 btn-config">
                                              <FontAwesomeIcon size="sm" icon={faUserEdit} className="mr10" />
                                              Editors
                                            </Button>

                                          </OverlayTrigger>
                                        )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Label
              labelTitle="Description"
              labelPosition="left-top"
              labelSize="100"
              fieldSize="100"
              errorSize="100"
              className="mt6"
              required
              error={formErrors.description}
              isFieldTouched
            >
              <Textarea
                className={highlightTextBox?.Description ? 'error-field-outline' : ''}
                autoComplete="off"
                name="description"
                placeholder="Add detailed notes..."
                rows={5}
                defaultValue={componentHeaderData.description}
                onChange={(e: any) => {
                  const { value } = e.target;
                  this.setState((prevState: ComponentHeaderState) => (
                    {
                      componentHeaderData: {
                        ...prevState.componentHeaderData,
                        description: value
                      }
                    }
                  ), () => {
                    if (onChange) {
                      onChange({ purpose: value });
                    }
                  });
                }}
              />
              {highlightTextBox?.Description ? (
                <div className="text-danger">
                  <FontAwesomeIcon
                    className="cursor-pointer mt8 mr10"
                    size="sm"
                    icon={faExclamationTriangle}
                  />
                  Description is a required field
                </div>
              ) : null}
            </Label>
            {!hideSaveButton
                            && (
                              <Button
                                variant="success"
                                size="sm"
                                className="mt5"
                                onClick={() => {
                                  this.onSave();
                                }}
                                disabled={isInSavingState}
                              >
                                {isInSavingState ? (
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr10"
                                  />
                                ) : null}
                                <FontAwesomeIcon size="sm" icon={faSave} className="mr6" />
                                {isInSavingState ? 'Saving' : saveButtonText || 'Save General Settings'}
                              </Button>
                            )}
            {!hideBackButton
                            && (
                              <Button variant="primary" size="sm" className="mt5 ml5" onClick={onBack}>
                                <FontAwesomeIcon size="sm" icon={faArrowLeft} className="mr6" />
                                Back
                              </Button>
                            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ComponentHeader;
