import TreeView from 'devextreme-react/tree-view';
import React, { Component } from 'react';
import { OverlayTrigger, Popover, } from 'react-bootstrap';
import Button from 'components/wrapped-component/hint-controls/Button';

class FieldChooser extends Component<any, any> {
  private fieldsInfo:any;

  constructor(props: any) {
    super(props);
    const { fields } = this.props;
    this.fieldsInfo = JSON.parse(JSON.stringify(fields));
    this.applyColumnChanges = this.applyColumnChanges.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
  }

  componentDidUpdate() {
    //  do nothing
  }

  selectionChanged() {
    // do nothing
  }

  applyColumnChanges() {
    const { applyColumnChanges } = this.props;
    if (applyColumnChanges !== undefined) {
      applyColumnChanges(this.fieldsInfo);
    }
  }

  renderTreeViewItem(item:any) {
    let returnValue = '';
    if (item.caption.indexOf('][') !== -1) {
      const captionValue = item.caption.split('][');
      returnValue = `${captionValue[0]} (${captionValue[1]})`;
    } else {
      returnValue = item.caption;
    }
    return returnValue;
  }

  render() {
    const { fields } = this.props;

    // this.fieldsInfo = filterFields;
    const popover = (

      <Popover id="popover-basic" className="field-chooser">
        <Popover.Title as="h3">Parameter Field Chooser</Popover.Title>
        <Popover.Content>
          <div className="h450 w400">
            <TreeView
              id={new Date().toString()}
              items={fields}
              displayExpr="caption"
              keyExpr="dataField"
              width="100%"
              height={400}
              searchEnabled
              selectNodesRecursive
              showCheckBoxesMode="normal"
              selectionMode="multiple"
              className="border-bottom"
              onSelectionChanged={this.selectionChanged}
            />
            <div className="w100p p5 mb5">
              <Button variant="primary float-right mt10 pt2 pb4" onClick={this.applyColumnChanges}>Apply</Button>
            </div>
          </div>
        </Popover.Content>
      </Popover>

    );
    return (
      <div className="btn-field-chooser">
        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
          <div className="dx-button-content" title="Parameter Field Chooser">
            <i className="dx-icon dx-icon-fieldchooser" />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

export default FieldChooser;
