import React from 'react';
import Textbox from '../hint-controls/Textbox';

class CustomizedTwoInputBox extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstValue: props.data.value ? props.data.value.firstValue : props.data.value,
      secondValue: props.data.value ? props.data.value.secondValue : props.data.value,
    };
  }

  onFirstUpdateHandler = (event: any) => {
    const { data } = this.props;
    const { secondValue } = this.state;
    data.setValue({
      firstValue: event.target.value,
      secondValue,
    });
    this.setState({ firstValue: event.target.value });
  };

  onSecondUpdateHandler = (event: any) => {
    const { data } = this.props;
    const { firstValue } = this.state;
    data.setValue({
      secondValue: event.target.value,
      firstValue,
    });
    this.setState({ secondValue: event.target.value });
  };

  render() {
    const { firstValue, secondValue } = this.state;
    const { data, customData } = this.props;
    return (
      <div className="side-by-side">
        <Textbox
          className="w-100 h40 border-none outline-none"
          name="First"
          type={customData.firstValueType}
          placeholder="Enter Value..."
          value={firstValue}
          onChange={this.onFirstUpdateHandler}
          disabled={customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false}
        />
        <div className="mt10">
          {customData.seprator || null}
        </div>
        <Textbox
          className="w-100 h40 border-none outline-none"
          name="Second"
          type={customData.secondValueType}
          placeholder="Enter Value..."
          value={secondValue}
          onChange={this.onSecondUpdateHandler}
          disabled={customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false}
        />

      </div>
    );
  }
}

export default CustomizedTwoInputBox;
