import React, { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../template-component/avatar/Avatar";
import Heading from "../../wrapped-component/hint-controls/Heading";

import "./topbar-nav.scss";
import Button from "../../wrapped-component/hint-controls/Button";
import commonFunctions from "../../../customFunctions";

interface ITopbarProps {
  items: { name: string; selected: boolean }[];
  title?: string;
  actions?: ReactElement;
  secondaryActions?: ReactElement;
  showAvatar?: boolean;
  showNotifications?: boolean;
  className?: string;
  showShadow?: boolean;
  titleIcon?: any;
  titleIconAction?: any;
}

const TopbarNav = (props: ITopbarProps) => {
  const [showNavBar, setShowNavBar] = useState(true);
  const { search } = useLocation();

  useEffect(() => {
    setShowNavBar(commonFunctions.hideDashboardBasedOnParams(search));
  }, [search]);
  const {
    className,
    items,
    title,
    actions,
    secondaryActions,
    showAvatar,
    showNotifications,
    showShadow,
    titleIcon,
    titleIconAction,
  } = props;

  const navItems = items.map((item: any) => {
    const itemClass = item.selected === true ? "nav-selected" : "";
    return (
      <Nav.Link key={`key_${item.name}`} href="#home" className={itemClass}>
        {item.name}
      </Nav.Link>
    );
  });
  return showNavBar ? (
    <Navbar
      bg="light"
      expand="lg"
      className={`topbarnav ml-15 ${
        showShadow ? "nav-shadow" : ""
      } ${className}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        {titleIcon !== undefined && titleIconAction !== undefined && (
          <Button
            type="button"
            className="mt-6 mr10 p10"
            onClick={titleIconAction}
          >
            <FontAwesomeIcon icon={titleIcon} />
          </Button>
        )}
        <Heading size={4}>{title}</Heading>
        {actions !== undefined && actions}
      </div>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="mr-auto">{navItems}</Nav>
      </Navbar.Collapse>
      {secondaryActions !== undefined && secondaryActions}
      {showNotifications && (
        <>
          <FontAwesomeIcon className="mt-10" size="lg" icon={faBell} />
          <span className="h20 mr20 mt-10 ml20 break-right" />
        </>
      )}
      {showAvatar && (
        <Avatar size="lg" className="mb10 pr20" mode="dark" title="YieldWerx" />
      )}
    </Navbar>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

TopbarNav.defaultProps = {
  title: "",
  actions: undefined,
  secondaryActions: undefined,
  showAvatar: true,
  showNotifications: true,
  className: "",
  showShadow: true,
  titleIcon: undefined,
  titleIconAction: undefined,
};

export default TopbarNav;
