import HttpBase from './http.base';
import AppConstants from '../constants.js';

const CONTROLLER_ROUTE = 'api/die-type';

class HttpDieType extends HttpBase {
  getDieTypes = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/${CONTROLLER_ROUTE}`);
  };

  postDieType = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/${CONTROLLER_ROUTE}`, data);
  };

  deleteDieType = (id: string, checkUsage: boolean): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/${CONTROLLER_ROUTE}/${id}?checkUsage=${checkUsage}`);
  };

  putDieType = (data: any, id: string): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/${CONTROLLER_ROUTE}/${id}`, data);
  };
}

const httpDieType = new HttpDieType();
export { httpDieType };
