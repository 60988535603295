import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faFileExport, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/wrapped-component/hint-controls/Button';
import PageHeader from '../../components/template-component/page-header/PageHeader';
import CustomizedDropdown from '../../components/wrapped-component/customized-dropdown/CustomizedDropdown';

interface IVersionHistory {
  className?: string;
}

const VersionHistory = (props: IVersionHistory) => {
  const { className } = props;
  return (
    <Container
      fluid
      className={`${className}`}
    >
      <PageHeader
        className="pl20 pb10"
        title="Version History"
        breadcrumbs="Create Rule / PAT / Version History"
        secondaryActions={(
          <Row>
            <Col>
              <Button variant="light" className="mr10">
                <FontAwesomeIcon size="lg" icon={faShareAlt} className="cursor-pointer mr10" />
                Share
              </Button>
              <Button variant="light" className="mr10">
                <FontAwesomeIcon size="lg" icon={faFileExport} className="cursor-pointer mr10" />
                Export
              </Button>
              <Button variant="light" className="mr10">
                <FontAwesomeIcon size="lg" icon={faTimes} className="cursor-pointer" />
              </Button>
            </Col>
          </Row>
        )}
        primaryActions={(
          <Row className="pt20 ">
            <Col className="d-flex">
              <div className="pr20">
                <CustomizedDropdown
                  variant="clear"
                  full
                  list={[
                    ['1', 'Changes Made In'],
                    ['1', 'Name'],
                    ['1', 'Owner Name'],
                    ['1', 'Owner Email'],
                    ['1', 'PAT Rule to Device Level'],
                    ['1', 'Validate PAT Limits Against'],
                    ['1', 'Bin Type'],
                    ['1', 'Test Program'],
                    ['1', 'Low Seed Limit'],
                    ['1', 'High Seed Limit'],
                    ['1', 'Static Limits after every'],
                    ['1', 'Value of K'],
                  ]}
                />
              </div>
              <div>
                <CustomizedDropdown variant="clear" full list={[['1', 'By User']]} />
              </div>
            </Col>
          </Row>
        )}
      />
    </Container>
  );
};

VersionHistory.defaultProps = {
  className: '',
};

export default VersionHistory;
