import getDropDownOptions from 'components/getDropDownOptions';
import { CheckBox, SelectBox } from 'devextreme-react';
import React from 'react';
import { FeatureSpecificWaferTag, FlipAxis } from '../../wafer-map/web-gl-utils/Enums';
import { ConnectWafersDropdown } from './ConnectWafersDropdown';

type UnpinnableWaferMainControlsProps = {
  onChangeDieTextField: (value: string, keyIndex: string) => void,
  dieTextField: string,
  isDieCoordinatesSystemEnabled: boolean,
  onChangeDieCoordinateSystemValue: (value: boolean, keyIndex: string) => void,
  isReticleCoordinatesSystemEnabled: boolean;
  onChangeReticleCoordinateSystemValue: (value: boolean, keyIndex: string) => void,
  dieTextFieldOptions: string[][],
  keyIndex: string,
  connectedWaferKeys: string[],
  waferList: { waferKey: string, name: string }[],
  syncConnectWafersDropDownDataGridSelection: (e: any) => void,
  connectWafersDataGridOnSelectionChanged: (e: any) => void,
  noOfWafers: number,
  featureSpecificWaferTags: FeatureSpecificWaferTag[],
  hasFlippingControls: boolean,
  isXAxisFlipped: boolean,
  isYAxisFlipped: boolean,
  flipWaferMap: (value: boolean, axis: FlipAxis, keyIndex: string) => void;
};

export const UnpinnableWaferMainControls = (props: UnpinnableWaferMainControlsProps) => {
  const {
    dieTextField, onChangeDieTextField, onChangeDieCoordinateSystemValue, connectedWaferKeys,
    isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled, waferList,
    onChangeReticleCoordinateSystemValue, dieTextFieldOptions, keyIndex, syncConnectWafersDropDownDataGridSelection,
    connectWafersDataGridOnSelectionChanged, noOfWafers, featureSpecificWaferTags, isXAxisFlipped, isYAxisFlipped, hasFlippingControls, flipWaferMap,
  } = props;

  return (
    <>
      {
        hasFlippingControls
          ? (
            <div>
              <CheckBox
                className="mt10 mr20"
                value={isXAxisFlipped}
                onValueChange={(value: boolean) => { flipWaferMap(value, FlipAxis.ColWise, keyIndex); }}
              />
              {' '}
              <span style={{ fontSize: '12px' }}>Flip X</span>
              {/* <br /> */}
              <CheckBox
                className="mt10 mr20 ml20"
                value={isYAxisFlipped}
                onValueChange={(value: boolean) => { flipWaferMap(value, FlipAxis.RowWise, keyIndex); }}
              />
              {' '}
              <span style={{ fontSize: '12px' }}>Flip Y</span>
            </div>
          ) : null
      }
      <div className="text-center mt10">
        <h6 style={{ fontWeight: 'bolder' }} className="text">Die Text Field: </h6>
        <SelectBox
          displayExpr={(x: any) => { return x ? x[1] : ''; }}
          items={dieTextFieldOptions}
          value={dieTextField}
          valueExpr={(x: any) => { return x ? x[0] : ''; }}
          onValueChanged={(e: any) => { onChangeDieTextField(e.value, keyIndex); }}
          dropDownOptions={getDropDownOptions('div')}
        />
      </div>
      { noOfWafers > 1
        ? (
          <ConnectWafersDropdown
            waferList={waferList}
            connectedWaferKeys={connectedWaferKeys}
            syncConnectWafersDropDownDataGridSelection={syncConnectWafersDropDownDataGridSelection}
            connectWafersDataGridOnSelectionChanged={connectWafersDataGridOnSelectionChanged}
          />
        ) : null}
      {
        featureSpecificWaferTags.includes(FeatureSpecificWaferTag.WAFER_CONTROL_MAP)
          ? (
            <div>
              <h6 className="text-center font-weight-bold mt10">Coordinate System</h6>
              <CheckBox
                className="mt10 mr20"
                value={isDieCoordinatesSystemEnabled}
                onValueChange={(value: boolean) => { onChangeDieCoordinateSystemValue(value, keyIndex); }}
              />
              {' '}
              <span style={{ fontSize: '12px' }}>Standard</span>
              <br />
              <CheckBox
                className="mt10 mr20"
                value={isReticleCoordinatesSystemEnabled}
                onValueChange={(value: boolean) => { onChangeReticleCoordinateSystemValue(value, keyIndex); }}
              />
              {' '}
              <span style={{ fontSize: '12px' }}>Reticle Defined</span>
            </div>
          ) : null
      }
    </>
  );
};
