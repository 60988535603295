import React from 'react';
import { Col, Button, Spinner } from 'react-bootstrap';
import toast from 'CustomToast';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import SelectBox from 'devextreme-react/select-box';
import userDataUtil from 'services/user-data-util';
import { PatternRule } from 'devextreme-react/data-grid';
import getDropDownOptions from 'components/getDropDownOptions';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import ExpressionBuilderV2 from '../../../utility-component/expression-builder/ExpressionBuilderV2';
import { httpService } from '../../../../services/http.service';
import AppConstants from '../../../../constants.js';

class SPCRuleSettingsV2 extends React.Component<any, any> {
  private conditions = [
    { caption: 'point(s) more than', dataField: 'point(s) more than' },
    { caption: 'point(s) in a row on same side of centerline', dataField: 'point(s) in a row on same side of centerline' },
    { caption: 'point(s) in a row all increasing or decreasing', dataField: 'point(s) in a row all increasing or decreasing' },
    { caption: 'point(s) in a row alternating up and down', dataField: 'point(s) in a row alternating up and down' },
    { caption: 'point(s) greater than', dataField: 'point(s) greater than' },
    { caption: 'point(s) in a row within', dataField: 'point(s) in a row within' },
    { caption: 'point(s) in a row greater than', dataField: 'point(s) in a row greater than' },
    { caption: 'point(s) out of the CTL(either side)', dataField: 'point(s) out of the CTL(either side)' },
    { caption: 'point(s) greater than upper CTL value', dataField: 'point(s) greater than upper CTL value' },
    { caption: 'point(s) less than lower CTL value', dataField: 'point(s) less than lower CTL value' },
  ];

  private disablingConditions = [
    'point(s) in a row on same side of centerline',
    'point(s) in a row all increasing or decreasing',
    'point(s) in a row alternating up and down',
    'point(s) out of the CTL(either side)',
    'point(s) greater than upper CTL value',
    'point(s) less than lower CTL value',
  ];

  private enablingConditions = [
    'point(s) more than',
    'point(s) greater than',
    'point(s) in a row within',
    'point(s) in a row greater than',
  ];

  private conditionsThatNeedTriggerLimitViolations:string[] = [
    'point(s) in a row on same side of centerline',
    'point(s) more than',
    'point(s) greater than',
    'point(s) in a row greater than',
  ];

  private conditionsThatDoNotNeedTriggerLimitViolations:string[] = [
    'point(s) in a row all increasing or decreasing',
    'point(s) in a row alternating up and down',
    'point(s) out of the CTL(either side)',
    'point(s) greater than upper CTL value',
    'point(s) less than lower CTL value',
    'point(s) in a row within',
  ];

  private SPCRules = {
    objects: [
      { caption: 'point(s) more than', dataField: 'pointsMoreThan', dataType: 'string' },
      { caption: 'point(s) in a row on same side of centerline', dataField: 'sameSideOfCenterline', dataType: 'string' },
      { caption: 'point(s) in a row all increasing or decreasing', dataField: 'increasingOrDescreasing', dataType: 'string' },
      { caption: 'point(s) in a row alternating up and down', dataField: 'alternatingUpAndDown', dataType: 'string' },
      { caption: 'point(s) greater than', dataField: 'greaterThan', dataType: 'string' },
      { caption: 'point(s) in a row within', dataField: 'rowWithin', dataType: 'string' },
      { caption: 'point(s) in a row greater than', dataField: 'rowGreaterThan', dataType: 'string' },
      { caption: 'point(s) out of the CTL(either side)', dataField: 'outOfCTL', dataType: 'string' },
      { caption: 'point(s) greater than upper CTL value', dataField: 'greaterThanUpeerCTL', dataType: 'string' },
      { caption: 'point(s) less than the lower CTL value', dataField: 'lessThanLowerCTL', dataType: 'string' },

    ],
    operators: [
      { caption: '=', dataField: '==' },
      { caption: '<', dataField: '<' },
      { caption: '>', dataField: '>' },
      { caption: '!=', dataField: '!=' },
    ],
    typeOperations: [{ dataType: 'string', operators: ['==', '!='] }],
  };

  constructor(props: any) {
    super(props);

    this.state = {
      createdSPCRules: props.spcRules,
      reusableNames: [],
      viewMode: 'All',
      standardSimulationInProgress: false,
      advanceSimulationInProgress: false,
      isConditionRequired: false,
      isMeasuredValueAndStdevRequired: false,

    };
  }

  setName = () => {
    const { createdSPCRules, reusableNames } = this.state;
    if (reusableNames.length !== 0) {
      const newName = reusableNames[0];
      reusableNames.splice(0, 1);
      this.setState(reusableNames);
      return newName;
    }
    return `${createdSPCRules.length + 1}`;
  };

  updateToParent = async (rule: any, statements:string) => {
    const { onUpdate } = this.props;
    const { createdSPCRules } = this.state;
    const index = createdSPCRules.findIndex((x: any) => x === rule);
    if (createdSPCRules[index].Id !== undefined) {
      createdSPCRules[index].UpdatedBy = { Id: AppConstants.user.Id };
      await httpService
        .updateSPCRule({ RuleJSON: JSON.stringify(createdSPCRules[index]).toString() })
        .then((response) => {
          if (response) {
            toast.success(`${createdSPCRules[index].spcRuleType} Rule ${createdSPCRules[index].spcRuleName} updated!`);
            userDataUtil.registerInteractionEvent('Updated SPC Rule in db ', 'SPC');
          }
        }).catch((error:any) => {
          toast.error(`Failed to add ${createdSPCRules[index].spcRuleType} Rule ${createdSPCRules[index].spcRuleName} `);
        });
    } else {
      await httpService
        .saveSPCRule({ RuleJSON: JSON.stringify(createdSPCRules[index]).toString() })
        .then((savedSPCRuleId: string) => {
          createdSPCRules[index].Id = savedSPCRuleId;
          this.setState(createdSPCRules);
          toast.success(`${createdSPCRules[index].spcRuleType} Rule ${createdSPCRules[index].spcRuleName} Added!`);
          userDataUtil.registerInteractionEvent('Added SPC Rule to db ', 'SPC');
        }).catch((error:any) => {
          toast.error(`Failed to update ${createdSPCRules[index].spcRuleType} Rule ${createdSPCRules[index].spcRuleName} `);
        });
    }

    this.setState({ createdSPCRules, isConditionRequired: false, isMeasuredValueAndStdevRequired: false }, onUpdate(createdSPCRules));
  };

  addRule = (ruleType: string) => {
    const { createdSPCRules } = this.state;
    if (ruleType === 'Standard') {
      this.setState({ standardSimulationInProgress: true });
    } else {
      this.setState({ advanceSimulationInProgress: true });
    }
    const newRule = {
      spcRuleType: ruleType,
      spcRuleName: `${this.setName()}`,
      spcRuleExpressions: [],

      Name: `SPC ${ruleType} Rule ${this.setName()}`,
      ShortDescription: '',
      Description: '',
      Version: '0',
      CreatedBy: { Id: AppConstants.user.Id },

    };
    createdSPCRules.push(newRule);
    if (ruleType === 'Standard') {
      this.setState({ standardSimulationInProgress: false });
    } else {
      this.setState({ advanceSimulationInProgress: false });
    }
  };

  deleteRule = async (rule: any) => {
    const { createdSPCRules } = this.state;
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: `This action will delete ${rule.Name}. Do you want to proceed?`,
    })) {
      createdSPCRules.forEach((x: any, index: number) => {
        if (x.spcRuleName === rule.spcRuleName) {
          createdSPCRules.splice(index, 1);
          this.setState({ createdSPCRules });
        }
      });
      toast.success(`${rule.spcRuleName} Deleted (Frontend Only)`);
    }
  };

  renderStandardRules = (rule: any) => {
    const { isConditionRequired, isMeasuredValueAndStdevRequired } = this.state;
    return (
      <div className="mt10">
        <ExpressionBuilderV2
          height={300}
          defaultStatements={rule.spcRuleExpressions}
          hideOutputSettings
          onUpdate={(statements:string) => this.updateToParent(rule, statements)}
          rules={this.SPCRules}
          editingMode="row"
          behavior="customizedInput"
          onRowUpdating={(event:any) => {
            let isCanceled = Promise.resolve(false);
            if (event.newData.condition) {
              if ((this.conditionsThatDoNotNeedTriggerLimitViolations.includes(event.newData.condition) && this.conditionsThatNeedTriggerLimitViolations.includes(event.oldData.condition))
              || (this.conditionsThatDoNotNeedTriggerLimitViolations.includes(event.oldData.condition) && this.conditionsThatNeedTriggerLimitViolations.includes(event.newData.condition))) {
                isCanceled = new Promise(async (resolve, reject) => {
                  if (await (
                    ModalPopup.confirm({
                      header: 'Confirmation',
                      body: 'This condition change affects Trigger Limit Violations in SPC Parameter Settings. Do you want to continue?',
                    })
                  )) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                });
              }
            }
            event.cancel = isCanceled;
          }}
          onToolbarPreparing={(e:any) => {
            e.toolbarOptions.items.unshift({
              location: 'after',
              widget: 'dxButton',
              options: {
                icon: 'trash',
                text: 'Delete Rule',
                onClick: () => { this.deleteRule(rule); },
              },
            });
          }}
          customizedInput={
            {
              caption: `${rule.spcRuleType} Rule - ${rule.spcRuleName}`,
              fields: [
                {
                  caption: 'Points',
                  dataField: 'points',
                  dataType: 'dropdown',
                  width: 200,
                  isRequired: true,
                  dropdownOptions: [
                    { caption: '(', dataField: '(' },
                    { caption: ')', dataField: ')' },
                    // { caption: 'Number Input', dataField: 'numberInput' },
                  ],
                  changeMyType: {
                    primary: {
                      toType: 'twoInputBox',
                      onValues: ['point(s) greater than'],
                      becauseOfColumn: 'condition',
                      twoInputTypes: {
                        first: 'number',
                        second: 'number',
                        separator: '/',
                      },
                    },
                    secondary: {
                      toType: 'number',
                      onValues: [
                        'point(s) more than',
                        'point(s) in a row on same side of centerline',
                        'point(s) in a row all increasing or decreasing',
                        'point(s) in a row alternating up and down',
                        'point(s) in a row within',
                        'point(s) in a row greater than',
                        'point(s) out of the CTL(either side)',
                        'point(s) greater than upper CTL value',
                        'point(s) less than lower CTL value',
                      ],
                      becauseOfColumn: 'condition',
                    },
                  },
                  callBackOnUpdate: this.callBackOnPointUpdate,

                },
                {
                  caption: 'Condition',
                  dataField: 'condition',
                  dataType: 'dropdown',
                  dropdownOptions: this.conditions,
                  width: 300,
                  isRequired: isConditionRequired,
                  callBackOnUpdate: this.callBackOnConditionUpdate,
                },
                {
                  caption: 'Measured Value',
                  dataField: 'measuredValue',
                  dataType: 'textBoxWithCallback',
                  className: 'w-100 h40 border-none outline-none',
                  typeOfCustomizedTextBox: 'number',
                  disabledWhen: {
                    values: this.disablingConditions,
                    inColumn: 'condition',
                  },
                  requiredWhenForCustomizedTextBox: {
                    values: this.enablingConditions,
                    inColumn: 'condition',
                  },
                  validationRules: [
                    (
                      <PatternRule
                        message="Please enter a non-negative non-zero number less than 1000000000"
                        pattern={/^((([1-9]\d{0,8})(\.\d{1,9})?)|(0(\.\d{0,9})))$/}
                      />
                    ),
                  ],
                  isRequired: isMeasuredValueAndStdevRequired,
                },
                {
                  caption: 'Standard Deviation',
                  dataField: 'stdev',
                  dataType: 'dropdown',
                  dropdownOptions: [
                    { caption: 'Standard deviation (same side)', dataField: 'Standard deviation (same side)' },
                    { caption: 'Standard deviation (either side)', dataField: 'Standard deviation (either side)' },
                  ],
                  disabledWhen: {
                    values: this.disablingConditions,
                    inColumn: 'condition',
                  },
                  isRequired: isMeasuredValueAndStdevRequired,
                },
                {
                  caption: 'Logical Operators',
                  dataField: 'logicalOperator',
                  dataType: 'dropdown',
                  dropdownOptions: [
                    { caption: 'and', dataField: 'and' },
                    { caption: 'or', dataField: 'or' },
                  ],

                },

              ],

            }
          }
        />
      </div>
    );
  };

  renderAdvanceRule = (rule: any) => {
    return (
      <div className="mt10">
        <ExpressionBuilderV2
          viewMode="text"
          onUpdate={(e:any) => {}}
          rules={this.SPCRules}
          defaultStatements={rule.spcRuleExpressions}
        />
      </div>
    );
  };

  callBackOnConditionUpdate = (data:any) => {
    this.setState({ isMeasuredValueAndStdevRequired: this.enablingConditions.includes(data) });
  };

  callBackOnPointUpdate = (data:any) => {
    if (data.firstValue === '(' || data.firstValue === ')') {
      this.setState({ isConditionRequired: false, isMeasuredValueAndStdevRequired: false });
    } else {
      this.setState({ isConditionRequired: true });
    }
  };

  render() {
    const {
      createdSPCRules, viewMode, standardSimulationInProgress, advanceSimulationInProgress,
    } = this.state;

    return (
      <div className="border-all pl10 pr10 pb20 background-color-light">
        <Col lg={12} className="mt20">
          <Heading size={4}>SPC Rule Settings</Heading>
        </Col>
        <Col lg={12} className="d-flex">
          <Button
            type="Button"
            variant="primary"
            size="lg"
            className="mr10"
            onClick={() => this.addRule('Standard')}
            disabled={standardSimulationInProgress}
          >
            {
              standardSimulationInProgress
                ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null
            }
            {standardSimulationInProgress ? 'Adding Standard Rule...' : 'Add Standard Rule'}
          </Button>
          <Button
            type="Button"
            variant="primary"
            size="lg"
            className="mr10"
            onClick={() => this.addRule('Advance')}
            disabled={advanceSimulationInProgress || AppConstants.isTempHidden}
          >
            {
              advanceSimulationInProgress
                ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null
            }

            {advanceSimulationInProgress ? 'Adding Advance Rule...' : 'Add Advance Rule'}
          </Button>

          <div className="ml10 dx-field">
            <div className="dx-field-label"> View Rules </div>
            <div className="dx-field-value">
              <SelectBox
                placeholder="View Mode"
                items={[{ caption: 'All' }, { caption: 'Standard' }, { caption: 'Advance' }]}
                defaultValue={viewMode}
                displayExpr="caption"
                valueExpr="caption"
                searchEnabled={false}
                dropDownButtonTemplate="dropDownButton"
                onValueChanged={(e: any) => { this.setState({ viewMode: e.value }); }}
                dropDownOptions={getDropDownOptions('div')}
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          {createdSPCRules.map((rule: any) => {
            if (viewMode === 'Standard') {
              if (rule.spcRuleType === 'Standard') {
                return this.renderStandardRules(rule);
              }
            } else if (viewMode === 'Advance') {
              if (rule.spcRuleType === 'Advance') {
                return this.renderAdvanceRule(rule);
              }
            } else {
              if (rule.spcRuleType === 'Standard') {
                return this.renderStandardRules(rule);
              }
              return this.renderAdvanceRule(rule);
            }
          })}
        </Col>

      </div>
    );
  }
}

export default SPCRuleSettingsV2;
