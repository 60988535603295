// import React, { Component } from 'react';
// import './legend.scss';
// import { Col } from 'react-bootstrap';
// import { Switch } from 'devextreme-react';
// import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
//
// interface LegendProps {
//   legendData: object[];
//   fieldList: object[];
//   switchDefaultValue?: boolean;
//   switchWidth?: number;
//   selectionMode? : 'single' | 'multiple';
//   similarDeletionField? : string;
//   sortBy?: any;
//   groupCaption?: string;
//   publisherId? : string;
//   setCustomGridInstanceHandler?: (customizedDataGrid: CustomizedDataGrid, keyIndex: string) => void;
//   height?: string;
//   showGroupPanel?: boolean;
//   summaryFields?: object[];
//   showAdvancedFilters?: boolean;
//   scrollMode?: 'standard'|'virtual';
//   showColumnLines?: boolean,
//   showRowLines?: boolean,
//   getSummaryCaption?: (data:any, item:any, tableData:any) => void;
// }
//
// class Legend extends Component<LegendProps, any> {
//   static defaultProps = {
//     legendData: [],
//     fieldList: [],
//     switchDefaultValue: false,
//     switchWidth: 120,
//     selectionMode: 'multiple',
//     similarDeletionField: 'bin',
//     height: '500px',
//     showGroupPanel: true,
//     summaryFields: [],
//     showAdvancedFilters: true,
//     scrollMode: 'virtual',
//     groupCaption: 'wafer',
//     showColumnLines: false,
//     showRowLines: true,
//     sortBy: {
//       caption: 'Wafer',
//       groupObject: [{
//         TableName: 'wafer',
//         Columns: [{
//           ColumnName: 'key',
//           Sort: 'asc',
//         }],
//       }],
//     },
//   }
//
//   constructor(props:any) {
//     super(props);
//     this.state = {
//       aggregated: false,
//     };
//   }
//
//   getAggregateFieldList = () => {
//     const { fieldList, groupCaption, sortBy } = this.props;
//     const aggregatedFieldList = JSON.parse(JSON.stringify(fieldList));
//     aggregatedFieldList.unshift({
//       caption: groupCaption,
//       dataField: 'groupName',
//       dataType: 'string',
//       showInfo: false,
//       groupIndex: 0,
//       sortOrder: sortBy!.groupObject[0].Columns[0].Sort,
//     });
//     aggregatedFieldList.push({
//       caption: 'Bin Count',
//       dataField: 'binCount',
//       dataType: 'number',
//       showInfo: false,
//       minWidth: 100,
//     }, {
//       caption: 'Bin %',
//       dataField: 'binYield',
//       dataType: 'string',
//       showInfo: false,
//       selected: false,
//       minWidth: 100,
//     });
//     return aggregatedFieldList;
//   }
//
//   toggleAggregation = (e: any) => {
//     this.setState({
//       aggregated: e.value,
//     });
//   }
//
//   getAggregatedData = () => {
//     const { legendData } = this.props;
//     const result: any = [...legendData.reduce((r: any, o: any) => {
//       const key: any = `${o.bin}-${o.passFailFlag}`;
//       const item: any = r.get(key) || ({ ...o, binCount: 0 });
//
//       item.binCount += o.binCount;
//       return r.set(key, item);
//     }, new Map())
//       .values()];
//
//     return result;
//   }
//
//   render() {
//     const {
//       legendData,
//       fieldList,
//       switchDefaultValue,
//       switchWidth,
//       showColumnLines,
//       showRowLines,
//       selectionMode,
//       publisherId,
//       height,
//       showGroupPanel,
//       showAdvancedFilters,
//       similarDeletionField,
//       setCustomGridInstanceHandler,
//       summaryFields,
//       scrollMode,
//       getSummaryCaption,
//     } = this.props;
//
//     const { aggregated } = this.state;
//
//     return (
//       <Col className="w-100 h-100 pl0 pr0 mt10 mb10">
//         {/* <Switch */}
//         {/*  defaultValue={switchDefaultValue} */}
//         {/*  switchedOnText="Hide Aggregated" */}
//         {/*  switchedOffText="Show Aggregated" */}
//         {/*  width={switchWidth} */}
//         {/*  className="btn-agregated" */}
//         {/*  onValueChanged={this.toggleAggregation} */}
//         {/* /> */}
//         <CustomizedDataGrid
//           key={aggregated ? 'agregated' : 'non-agregated'}
//           selectionMode={selectionMode}
//           similarDeletionField={similarDeletionField}
//           publisherId={publisherId}
//           setCustomGridInstanceHandler={setCustomGridInstanceHandler}
//           height={height}
//           showGroupPanel={showGroupPanel}
//           fields={!aggregated ? this.getAggregateFieldList() : fieldList}
//           summaryFields={!aggregated ? summaryFields : undefined}
//           tableData={aggregated ? this.getAggregatedData() : legendData}
//           showAdvancedFilters={showAdvancedFilters}
//           scrollMode={scrollMode}
//           totalRowsToDisplay={10}
//           isAggregated={aggregated}
//           getSummaryCaption={getSummaryCaption}
//           showColumnLines={showColumnLines}
//           showRowLines={showRowLines}
//         />
//       </Col>
//     );
//   }
// }
//
// export default Legend;

import React, { Component } from 'react';
import './legend.scss';
import { Col } from 'react-bootstrap';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';

interface LegendProps {
  legendData: object[];
  fieldList: object[];
  onMount: any;
  switchDefaultValue?: boolean;
  switchWidth?: number;
  selectionMode? : 'single' | 'multiple';
  similarDeletionField? : string;
  sortBy?: any;
  publisherId? : string;
  setCustomGridInstanceHandler?: (customizedDataGrid: CustomizedDataGrid, keyIndex: string) => void;
  height?: string;
  showGroupPanel?: boolean;
  groupSummaryFields?: any;
  showAdvancedFilters?: boolean;
  scrollMode?: 'standard' | 'virtual';
  showColumnLines?: boolean,
  showRowLines?: boolean,
  getSummaryCaption?: (data:any, item:any, tableData:any) => void;
  getSelectedData?: (data: any) => void,
  getRowSelectionData?:(data: any) => void,
  onContextMenuItemSelected?: (data:any) => void,
  selectedRowKeys?: any;
  keyExpr: any;
}

class Legend extends Component<LegendProps, any> {
  static defaultProps = {
    legendData: [],
    getSelectedData: undefined,
    getRowSelectionData: undefined,
    onContextMenuItemSelected: undefined,
    onMount: undefined,
    fieldList: [],
    switchDefaultValue: false,
    switchWidth: 120,
    selectionMode: 'multiple',
    similarDeletionField: 'bin',
    height: '500px',
    showGroupPanel: true,
    groupSummaryFields: [],
    showAdvancedFilters: true,
    scrollMode: 'virtual',
    showColumnLines: false,
    showRowLines: true,
    selectedRowKeys: [],
    keyExpr: undefined,
    sortBy: {
      caption: 'Wafer',
      groupObject: [{
        TableName: 'wafer',
        Columns: [{
          ColumnName: 'key',
          Sort: 'asc',
        }],
      }],
    },
  };

  constructor(props:any) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      legendData,
      fieldList,
      showColumnLines,
      showRowLines,
      selectionMode,
      publisherId,
      height,
      showGroupPanel,
      showAdvancedFilters,
      similarDeletionField,
      setCustomGridInstanceHandler,
      groupSummaryFields,
      scrollMode,
      getSelectedData,
      getRowSelectionData,
      onContextMenuItemSelected,
      onMount,
      selectedRowKeys,
      keyExpr,
    } = this.props;

    return (
      <Col className="w-100 h-100 pl0 pr0 mb10">
        <CustomizedDataGrid
          onMount={onMount}
          selectedRowKeys={selectedRowKeys}
          getSelectedData={getSelectedData}
          onRowSelection={getRowSelectionData}
          onContextMenuItemSelected={onContextMenuItemSelected}
          selectionMode={selectionMode}
          similarDeletionField={similarDeletionField}
          publisherId={publisherId}
          setCustomGridInstanceHandler={setCustomGridInstanceHandler}
          height={height}
          showGroupPanel={showGroupPanel}
          fields={fieldList}
          groupSummaryFields={groupSummaryFields}
          tableData={legendData}
          showAdvancedFilters={showAdvancedFilters}
          scrollMode={scrollMode}
          totalRowsToDisplay={10}
          showColumnLines={showColumnLines}
          showRowLines={showRowLines}
          keyExpr={keyExpr}
        />
      </Col>
    );
  }
}

export default Legend;
