import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpBinHistogram extends HttpBase {
  getHistogramData = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-histogramdata/`, data);
  };

  calcutateHistogramData = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-calculate-histogramdata/`, data);
  };

  getHistogramHeaderData = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-histogramheaderinfo/`, data);
  };

  getWafersData = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-histogram/wafers-data`, data);
  };

  getRawData = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-histogram/raw-data`, data);
  };

  getRawDataUnpivoted = (data:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-histogram/raw-data-unpivoted`, data);
  };

  getDetailedBinHistogramReport = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/detailed-bin-histogram`, data);
  };

  subscribeDetailedBinHistogramReport = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/get-selected-combined-bin-histogram`, data);
  };

  subscribeHiddenDetailedBinHistogramReport = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/get-hidden-combined-bin-histogram`, data);
  };

  getDetailedBinHistogramLegend = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/detailed-bin-histogram-legend`, data);
  };

  subscribeDetailedBinHistogramLegend = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/subscribe-detailed-bin-histogram-legend`, data);
  };

  subscribeHiddenDetailedBinHistogramLegend = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/subscribe-hidden-detailed-bin-histogram-legend`, data);
  };

  getInteractionDataForGraph = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/get-interaction-data-for-graph`, data);
  };

  getInteractionDataForLegend = (data:any) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/binhistogram/get-interaction-data-for-legend`, data);
  };
}

const httpBinHistogram = new HttpBinHistogram();
export { httpBinHistogram };
