/* eslint-disable no-param-reassign */
import { ISelectionCriteriaData, ISelectionCriteriaReturnValue } from 'interfaces';

export class CustomizedReportsCollectionExtensions {
  overridePolicySimulationDataOverSCW = (defaultSelectionCriteriaData:ISelectionCriteriaData[], selectionStore:ISelectionCriteriaReturnValue, policyStepResult: any, policyResult:any = undefined) : any => {
    const outputWaferIds : string[] = [];
    const outputTestParameter : any[] = [];
    const inputWaferIds : string[] = [];

    // policyResult.policyStepResult.forEach((policyStepResultObj:any) => {
    //   inputWaferIds.push(policyStepResultObj.inputWaferId);
    if (policyStepResult.type === 'SPC') {
      policyResult.forEach((policyResultObj:any) => {
        policyResultObj.policyStepResult.forEach((policyStepResultObj:any) => {
          if (policyStepResultObj.type === 'SPC') {
            inputWaferIds.push(policyStepResultObj.inputWaferId);
          }
        });
      });
      policyStepResult.stepSettings.spcReports.forEach((spcReport:any) => {
        spcReport.spcParameters.forEach((spcParameter:any) => {
          if (outputTestParameter.indexOf((tp:any) => tp.id === spcParameter.testParam.id) === -1) { // to not add duplicate test parameters
            outputTestParameter.push(spcParameter.testParam);
          }
        });
      });
    }
    if (policyStepResult.type === 'AMG') {
      inputWaferIds.push(policyStepResult.inputWaferId);
      outputWaferIds.push(policyStepResult.outputWaferId);
    }
    if (policyStepResult.ruleSetResult) {
      policyStepResult.ruleSetResult.forEach((ruleSetResultObj: any) => {
        inputWaferIds.push(ruleSetResultObj.inputWaferId);
        ruleSetResultObj.ruleResult.forEach((ruleResultObj: any) => {
          ruleResultObj.testParameterResult.forEach((testParameterResultObj: any) => {
            outputWaferIds.push(testParameterResultObj.outputWaferId);
            outputTestParameter.push({ ruleId: ruleResultObj.rule.id, ...testParameterResultObj.testParameter });
          });
        });
      });
    }

    const overrideDefaultSelectionCriteria = defaultSelectionCriteriaData.filter((x) => x.entityType !== 'Wafer' && x.entityType !== 'Testparameter');
    const selectionStoreSelectionsData = selectionStore.selections.filter((x) => x.controlType !== 'WAFER' && x.controlType !== 'TEST_PARAMETER');

    overrideDefaultSelectionCriteria.push({
      entityType: 'Wafer',
      values: [...inputWaferIds, ...outputWaferIds],
    });

    overrideDefaultSelectionCriteria.push({
      entityType: 'Testparameter',
      values: outputTestParameter.map((tpObj:any) => tpObj.id),
    });

    selectionStoreSelectionsData.push({
      entityType: 'Wafer',
      controlType: 'WAFER',
      columnName: 'wafer_id',
      values: [...inputWaferIds.map((waferId:string) => ({
        id: waferId,
        name: waferId,
      })), ...outputWaferIds.map((waferId:string) => ({
        id: waferId,
        name: waferId,
      }))],
    });

    selectionStoreSelectionsData.push({
      entityType: 'Testparameter',
      controlType: 'TEST_PARAMETER',
      columnName: 'test_parameter_id',
      values: outputTestParameter.map((tpObj: any) => ({
        id: tpObj.id,
        name: tpObj.name,
        ruleId: tpObj.ruleId,
      })),
    });

    return { selectionStoreSelectionsData, overrideDefaultSelectionCriteria };
  };
}
