import { InteractionInputDTO } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';
import { IExportDataOptionsDto } from 'interfaces';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpRawData extends HttpBase {
  setInteractionData = (interactionInputDto: InteractionInputDTO): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/set-interaction-data`, interactionInputDto);
  }

  getRawDataGridHeaders = (rawDataGridHeaderInputDTO:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/get-raw-data-grid-headers`, rawDataGridHeaderInputDTO);
  };

  evictCache = (key:string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/rawdata/evict-cache/${key}`);
  };

  exportRawDataGridData = (exportDataOptionsDto: IExportDataOptionsDto, options?: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/rawdata/export-raw-data-grid-data`, exportDataOptionsDto, options)
  }
}

const httpRawData = new HttpRawData();
export { httpRawData };
