/* eslint-disable no-param-reassign */
import {
  Row, Col, Container, Button,
} from 'react-bootstrap';
import React from 'react';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeEditor from 'components/utility-component/code-editor/CodeEditor';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Hint from 'components/wrapped-component/hint-controls/Hint/Hint';
import Heading from 'components/wrapped-component/hint-controls/Heading';

interface IEnrichmentParameterBlockProps {
  parameterList: IEnrichmentParameter[];
  isManualFilePopup?: boolean;
  componentHeading: string;
}

export interface IEnrichmentParameter {
  name: string;
  expression?: string;
}

class EnrichmentParameterBlock extends
  React.Component<IEnrichmentParameterBlockProps,
  any> {
  private parameterNames = [
    ['Facility', 'Facility Name'],
    ['WorkCenter', 'Work Center Name'],
    ['Device', 'Device Name'],
    ['TestProgram', 'Test Program Name'],
    ['TestProgramRevision', 'Test Program Revision'],
    ['Lot', 'Lot Id'],
    ['Wafer', 'Wafer Id'],
    ['RetestCode', 'Retest Code'],
  ];

  private strFuncList = ['Replace("abc", "xyz")', 'Substring(startingIndex, lengthOfSubStr)', 'Split("_")'];

  private wirAttributes = [
    'HeadNumber',
    'SiteGroup',
    'WaferId',
  ];

  private wrrAttributes = [
    'HeadNumber',
    'SiteGroup',
    'PartCount',
    'RetestCount',
    'AbortCount',
    'GoodCount',
    'FunctionalCount',
    'WaferId',
    'FabWaferId',
    'FrameId',
    'MaskId',
    'UserDescription',
    'ExecDescription',
  ];

  private wcrAttributes = [
    'WaferSize',
    'DieHeight',
    'DieWidth',
    'Units',
    'Flat',
    'CenterX',
    'CenterY',
    'PositiveX',
    'PositiveY',
  ];

  private mirAttributes = [
    'LotId',
    'PartType',
    'NodeName',
    'JobName',
    'TestCode',
    'TestTemperature',
    'UserText',
  ];

  private wirOptions = ['fileName', 'filePath', 'wir', 'wrr', 'wcr'];

  private mirOptions = ['fileName', 'filePath', 'mir'];

  private wirHintMessage = 'Specify custom expression like fileName.Substring(5,2) or filePath.Replace("a", "b") '
  + 'or you can also specify a hard coded expression like "name". Available variables are fileName, filePath, wir, wcr and wrr.';

  private mirHintMessage = 'Specify custom expression like fileName.Substring(5,2) or filePath.Replace("a", "b") '
  + 'or you can also specify a hard coded expression like "name". Available variables are fileName, filePath and mir.';

  constructor(props: IEnrichmentParameterBlockProps) {
    super(props);
    this.renderPickParameterSection = this.renderPickParameterSection.bind(this);
  }

  deleteParameter = (parameterList: any, index: any) => {
    parameterList.splice(index, 1);
    this.setState({}, this.forceUpdate);
    return parameterList;
  };

  renderPickParameterSection = (parameterList: any) => {
    return parameterList.map((parameter: any, index: number) => (
      <div key={`parameter.name_${index}`} className="custom-form-inline label-left d-flex w-100 align-items-center" style={{ padding: '8px' }}>
        <div className="label flex-30">
          <CustomizedDropdown
            full
            variant="clear"
            selectedValue={parameter.name}
            list={this.parameterNames}
            onChange={(value: string) => {
              // eslint-disable-next-line no-param-reassign
              parameterList[index].name = value;
              this.setState({}, this.forceUpdate);
            }}
          />
        </div>

        <div className="flex-70 w-100 d-flex align-items-center justify-content-between">
          <div className="w-100">
            <CodeEditor
              rows={1}
              placeHolder={'"GenericName"'}
              defaultValue={parameter.expression}
              matchingCriterion={
                parameter.name === 'Wafer'
                  ? [
                    {
                      regex: /^$/,
                      values: {
                        type: 'list',
                        values: this.wirOptions,
                      },
                    },
                    {
                      regex: /fileName\.$/i,
                      values: {
                        type: 'list',
                        values: this.strFuncList,
                      },
                    },
                    {
                      regex: /filePath\.$/i,
                      values: {
                        type: 'list',
                        values: this.strFuncList,
                      },
                    },
                    {
                      regex: /wir\.$/i,
                      values: {
                        type: 'list',
                        values: this.wirAttributes,
                      },
                    },
                    {
                      regex: /wrr\.$/i,
                      values: {
                        type: 'list',
                        values: this.wrrAttributes,
                      },
                    },
                    {
                      regex: /wcr\.$/i,
                      values: {
                        type: 'list',
                        values: this.wcrAttributes,
                      },
                    },
                  ]
                  : [
                    {
                      regex: /^$/,
                      values: {
                        type: 'list',
                        values: this.mirOptions,
                      },
                    },
                    {
                      regex: /fileName\.$/i,
                      values: {
                        type: 'list',
                        values: this.strFuncList,
                      },
                    },
                    {
                      regex: /filePath\.$/i,
                      values: {
                        type: 'list',
                        values: this.strFuncList,
                      },
                    },
                    {
                      regex: /mir\.$/i,
                      values: {
                        type: 'list',
                        values: this.mirAttributes,
                      },
                    },
                  ]
              }
              updateOnChange={(value: string) => {
                parameterList[index].expression = value;
                this.setState({}, this.forceUpdate);
              }}
            />
          </div>

          <div className="align-items-right side-by-side ml5">
            <Button
              className="w40 btn-outline-danger"
              variant="outline-primary"
              title="Delete parameter"
              onClick={() => this.deleteParameter(parameterList, index)}
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
            <Hint
              className="align-items-center pt5"
              hint={{
                message: parameter.name === 'Wafer' ? this.wirHintMessage : this.mirHintMessage,
              }}
              variant="icon"
            />
          </div>

        </div>
      </div>
    ));
  };

  render() {
    const { parameterList, isManualFilePopup, componentHeading } = this.props;
    return (
      <>
        <Row>
          <Col className="custom-form mt10" lg={12}>
            <span className="d-flex align-items-center justify-content-between">
              <Heading size={4}>{componentHeading}</Heading>
              <div className="mt7">
                <Button
                  variant="outline-primary"
                  className="w40"
                  onClick={() => {
                    parameterList.push({
                      name: 'Facility',
                      expression: undefined,
                    });
                    this.setState({}, this.forceUpdate);
                  }}
                >
                  <FontAwesomeIcon
                    className="mt-10"
                    size="sm"
                    icon={faPlus}
                  />
                </Button>
              </div>
            </span>
          </Col>
        </Row>

        <Container fluid style={{ overflow: 'visible' }}>
          <Row>
            <Col className="custom-form mt10" lg={12}>
              <Row className={`${isManualFilePopup === true ? 'background-color-secondary-background rounded border-all m2 pt20 pb20 row' : ''}`}>
                {isManualFilePopup
                  ? (
                    <>
                      <Col lg={4}>
                        <Heading className="mb10" size={5}>
                          Parameter
                        </Heading>
                      </Col>
                      <Col lg={7}>
                        <Heading className="mb10" size={5}>
                          Expression
                        </Heading>
                      </Col>
                      <Col lg={1} />
                    </>
                  ) : <></>}
                {this.renderPickParameterSection(parameterList)}
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EnrichmentParameterBlock;
