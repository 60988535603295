type EventType = {
  [key: string] : {
    [key: string]: {
      [key: string]:
      {
        callback: any,
        subscriberKey: string | string[],
        publisherKey: string | string[],
      }
    }
  }
};

const subscribersToWafers: EventType = {};
const subscribersToPlots: EventType = {};
const subscribersToCustomGrids: EventType = {};
const subscribersToOthers: EventType = {};

const PublishSubscribe = () => {
  function isFunction(functionName: any) {
    return functionName && {}.toString.call(functionName) === '[object Function]';
  }

  function clearAll() {
    Object.keys(subscribersToOthers).forEach((key:string) => {
      subscribersToOthers[key] = {};
    });
  }

  function publishWithWaferID(eventName: string, data: { [key: string]: any }, publisherID: string) {
    if (!(eventName in subscribersToWafers) || !(publisherID in subscribersToWafers[eventName])) {
      return;
    }
    Object.values(subscribersToWafers[eventName][publisherID]).forEach((obj: { callback: any, subscriberKey: string | string[], publisherKey: string | string[] }) => {
      if (obj.subscriberKey !== '' || obj.publisherKey !== '') {
        // eslint-disable-next-line no-param-reassign
        data.subscriberKey = obj.subscriberKey;
        // eslint-disable-next-line no-param-reassign
        data.publisherKey = obj.publisherKey;
      }
      if (isFunction(obj.callback)) {
        obj.callback(data);
      }
    });
  }

  function publishWithOthersID(eventName: string, data: { [key: string]: any }, publisherID: string) {
    if (!(eventName in subscribersToOthers) || !(publisherID in subscribersToOthers[eventName])) {
      return;
    }
    Object.values(subscribersToOthers[eventName][publisherID]).forEach((obj: { callback: any, subscriberKey: string | string[], publisherKey: string | string[] }) => {
      if (obj.subscriberKey !== '' || obj.publisherKey !== '') {
        // eslint-disable-next-line no-param-reassign
        data.subscriberKey = obj.subscriberKey;
        // eslint-disable-next-line no-param-reassign
        data.publisherKey = obj.publisherKey;
      }
      if (isFunction(obj.callback)) {
        obj.callback(data);
      }
    });
  }

  function publishWithPlotID(eventName: string, data: { [key: string]: any }, publisherID: string) {
    if (!(eventName in subscribersToPlots) || !(publisherID in subscribersToPlots[eventName])) {
      return;
    }
    Object.values(subscribersToPlots[eventName][publisherID]).forEach((obj: { callback: any, subscriberKey: string | string[], publisherKey: string | string[] }) => {
      if (obj.subscriberKey !== '' || obj.publisherKey !== '') {
        // eslint-disable-next-line no-param-reassign
        data.subscriberKey = obj.subscriberKey;
        // eslint-disable-next-line no-param-reassign
        data.publisherKey = obj.publisherKey;
      }
      if (isFunction(obj.callback)) {
        obj.callback(data);
      }
    });
  }

  function publishWithCustomGridID(eventName: string, data: { [key: string]: any }, publisherID: string) {
    if (!(eventName in subscribersToCustomGrids) || !(publisherID in subscribersToCustomGrids[eventName])) {
      return;
    }
    Object.values(subscribersToCustomGrids[eventName][publisherID]).forEach((obj: { callback: any, subscriberKey: string | string[], publisherKey: string | string[] }) => {
      // eslint-disable-next-line no-param-reassign
      data.subscriberKey = obj.subscriberKey;
      // eslint-disable-next-line no-param-reassign
      data.publisherKey = obj.publisherKey;
      if (isFunction(obj.callback)) {
        obj.callback(data);
      }
    });
  }

  function subscribeToWaferID(eventName: string, callback: any, publisherID: string, subscriberID: string, subscriberKey = '', publisherKey = '') {
    if (callback === null || callback === undefined) return;
    if (!(eventName in subscribersToWafers)) {
      subscribersToWafers[eventName] = {};
    }
    if (!(publisherID in subscribersToWafers[eventName])) {
      subscribersToWafers[eventName][publisherID] = {};
    }
    subscribersToWafers[eventName][publisherID][subscriberID] = { callback, subscriberKey, publisherKey };
  }

  function subscribeToPlotID(eventName: string, callback: any, publisherID: string, subscriberID: string, subscriberKey: string | string [] = '', publisherKey: string | string [] = '') {
    if (callback === null || callback === undefined) return;
    if (!(eventName in subscribersToPlots)) {
      subscribersToPlots[eventName] = {};
    }
    if (!(publisherID in subscribersToPlots[eventName])) {
      subscribersToPlots[eventName][publisherID] = {};
    }
    subscribersToPlots[eventName][publisherID][subscriberID] = { callback, subscriberKey, publisherKey };
  }

  function subscribeToCustomGridID(eventName: string, callback: any, publisherID: string, subscriberID: string, subscriberKey: string | string [] = '', publisherKey: string | string [] = '') {
    if (callback === null || callback === undefined) return;
    if (!(eventName in subscribersToCustomGrids)) {
      subscribersToCustomGrids[eventName] = {};
    }
    if (!(publisherID in subscribersToCustomGrids[eventName])) {
      subscribersToCustomGrids[eventName][publisherID] = {};
    }
    subscribersToCustomGrids[eventName][publisherID][subscriberID] = { callback, subscriberKey, publisherKey };
  }

  function subscribeToOthersID(eventName: string, callback: any, publisherID: string, subscriberID: string, subscriberKey: string | string [] = '', publisherKey: string | string [] = '') {
    if (callback === null || callback === undefined) return;
    if (!(eventName in subscribersToOthers)) {
      subscribersToOthers[eventName] = {};
    }
    if (!(publisherID in subscribersToOthers[eventName])) {
      subscribersToOthers[eventName][publisherID] = {};
    }
    subscribersToOthers[eventName][publisherID][subscriberID] = { callback, subscriberKey, publisherKey };
  }
  function unSubscribeToOthersID(eventName: string, publisherID: string, subscriberID: string) {
    if (subscribersToOthers[eventName] && subscribersToOthers[eventName][publisherID]) {
      delete subscribersToOthers[eventName][publisherID][subscriberID];
    }
  }

  return {
    subscribersToOthers,
    publishWithWaferID,
    subscribeToWaferID,
    publishWithPlotID,
    subscribeToPlotID,
    publishWithCustomGridID,
    subscribeToCustomGridID,
    subscribersToCustomGrids,
    subscribersToPlots,
    subscribersToWafers,
    subscribeToOthersID,
    publishWithOthersID,
    unSubscribeToOthersID,
    clearAll,
  };
};

export const EventTypes = {
  CLEAR_SELECTION_ON_WAFER: 'CLEAR_SELECTION_ON_WAFER',
  CHANGE_WAFER_MAP_VARIABLES: 'CHANGE_WAFER_MAP_VARIABLES',
  WAFER_DATA_REQUEST: 'WAFER_DATA_REQUEST',
  WAFER_DATA_RESPONSE: 'WAFER_DATA_RESPONSE',
  FILTER_EXPRESSION: 'FILTER_EXPRESSION',
  DATA_SELECTED_ON_WAFER: 'DATA_SELECTED_ON_WAFER',
  DATA_SELECTED_ON_PLOT: 'DATA_SELECTED_ON_PLOT',
  DATA_HIDDEN_ON_PLOT: 'DATA_HIDDEN_ON_PLOT',
  DATA_UNHIDDEN_ON_PLOT: 'DATA_UNHIDDEN_ON_PLOT',
  DATA_DELETED_ON_PLOT: 'DATA_DELETED_ON_PLOT',
  DATA_UNSELECTED_ON_PLOT: 'DATA_UNSELECTED_ON_PLOT',
  SELECTION_ON_CUSTOMIZED_GRID: 'SELECTION_ON_CUSTOMIZED_GRID',
  HIDE_ON_CUSTOMIZED_GRID: 'HIDE_ON_CUSTOMIZED_GRID',
  UNHIDE_ON_CUSTOMIZED_GRID: 'UNHIDE_ON_CUSTOMIZED_GRID',
  DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID: 'DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID',
  DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID: 'DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID',
  DELETION_ON_CUSTOMIZED_GRID: 'DELETION_ON_CUSTOMIZED_GRID',
  WAFER_RESET: 'WAFER_RESET',
  DIE_SELECTED_ON_WAFER: 'DIE_SELECTED_ON_WAFER',
  DIE_SELECTED_ON_PLOT: 'DIE_SELECTED_ON_PLOT',
  DIE_UNSELECTED_ON_PLOT: 'DIE_UNSELECTED_ON_PLOT',
  DIE_SELECTED_ON_GRID: 'DIE_SELECTED_ON_GRID',
  SUBDIE_SELECTED_ON_GRID: 'SUBDIE_SELECTED_ON_GRID',
  SUBDIES_HIDDEN_ON_GRID: 'SUBDIES_HIDDEN_ON_GRID',
  DATA_FILTER_APPLIED_ON_GRID: 'DATA_FILTER_APPLIED_ON_GRID',
  DATA_FILTER_CLEARED_ON_GRID: 'DATA_FILTER_CLEARED_ON_GRID',
  WAFER_MAP_DRAGGED: 'WAFER_MAP_DRAGGED',
  WAFER_MAP_DRAGGED_FROM_RADAR: 'WAFER_MAP_DRAGGED_FROM_RADAR',
  WAFER_MAP_ZOOMED: 'WAFER_MAP_ZOOMED',
  WAFER_MAP_ZOOMED_USING_BUTTONS: 'WAFER_MAP_ZOOMED_USING_BUTTONS',
  WAFER_MAP_DRAG_STARTED: 'WAFER_MAP_DRAG_STARTED',
  WAFER_MAP_ROTATED: 'WAFER_MAP_ROTATED',
  WAFER_MAP_FLIPPED: 'WAFER_MAP_FLIPPED',
  UPDATE_WAFER_ON_RADARPOINTER_UPDATE: 'UPDATE_WAFER_ON_RADARPOINTER_UPDATE',
  UPDATE_RADARPOINTER_ON_RADARPOINTER_UPDATE: 'UPDATE_RADARPOINTER_ON_RADARPOINTER_UPDATE',
  REPORT_SELECTION_UPDATE: 'REPORT_SELECTION_UPDATE',
  LEGEND_SELECTION_UPDATE: 'REPORT_SELECTION_UPDATE',
  SELECTION_ON_WAFER: 'SELECTION_ON_WAFER', // used in v2
  DIES_MARKED_ON_WAFER: 'DIES_MARKED_ON_WAFER', // used in v2
  TOGGLE_RADAR: 'TOGGLE_RADAR',
};

export default PublishSubscribe;
