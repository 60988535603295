import React from 'react';
import { toast } from 'react-toastify';
import { EmailRule } from 'devextreme-react/data-grid';
import PropTypes, { Requireable } from 'prop-types';
import EditableDataGrid from '../../wrapped-component/editable-data-grid/EditableDataGrid';
import Textbox from '../../wrapped-component/hint-controls/Textbox';
import { httpService } from '../../../services/http.service';
import spinner from '../../../assets/icons/spinner.gif';
import './location-validation-button.scss';
import { includes } from 'lodash';

interface ILocationTypeValues {
  'Cloud Container': string,
  FTP: string,
  Local: string,
}

export interface IOutputLocationProps {
  fileOutputs: any;
  onUpdate: (receivedOutputLocations: any) => void;
  locationTypes: string[];
}

class OutputLocation extends React.Component<IOutputLocationProps, any> {
  locationTypeValues: ILocationTypeValues = {
    'Cloud Container': 'S3',
    FTP: 'ftp',
    Local: 'Local',
  };

  static propTypes: {
    locationTypes: Requireable<any>;
  };

  constructor(props:IOutputLocationProps) {
    super(props);
    this.state = {
      fileOutputs: props.fileOutputs ? props.fileOutputs : [],
      isValidationInProcess: false,
    };
  }

  validateLocations = async () => {
    const { fileOutputs } = this.state;
    const locations: any = [];
    let containsLocalLocation = false;

    fileOutputs.forEach((fileOutput: any) => {
      locations.push({
        locationType: fileOutput.type,
        host: fileOutput.ftpAddress,
        username: fileOutput.ftpUsername,
        password: fileOutput.ftpPassword,
        container: fileOutput.ftpAddress,
        mainDirectory: fileOutput.mainDirectory,
      });
      if (fileOutput.type === 'Local') containsLocalLocation = true;
    });
    this.setState({ isValidationInProcess: true });
    const responseList = await httpService.validateLocationCredentials(locations);
    this.setState({ isValidationInProcess: false });

    const invalidatedLocations: any[] = [];
    responseList.forEach((response: any) => {
      if (!response.isValidated) invalidatedLocations.push(response.hostOrContainer);
    });

    if (responseList.length === 0) toast.warning('Add an output location to validate.');
    else if (invalidatedLocations.length === 0) toast.success('Validation successful.');
    else if (containsLocalLocation) toast.error(`Main directory for 'Local' location is empty. Validation failed for: ${invalidatedLocations.toString()}`);
    else toast.error(`Validation failed for: ${invalidatedLocations.toString()}`);
  };

  render() {
    const { fileOutputs, isValidationInProcess } = this.state;
    const { onUpdate, locationTypes } = this.props;
    return (
      <div className="w-100">
        <EditableDataGrid
          height={250}
          totalRowsToDisplay={10}
          showFilterRow={false}
          showAdvancedFilters={false}
          enableColumnChooser={false}
          showGroupPanel={false}
          showRowLines
          showColumnLines
          allowAdding
          allowUpdating
          allowDeleting
          columnFixing
          editingMode="row"
          keyExpr="id"
          onToolbarPreparing={(e:any) => {
            e.toolbarOptions.items.unshift({
              location: 'after',
              widget: 'dxButton',
              options: {
                text: 'Validate Locations',
                icon: isValidationInProcess ? spinner : 'info',
                hint: isValidationInProcess ? 'Validation is in process' : 'Check if location is accessible',
                disabled: false,
                elementAttr: { class: 'location-validation' },
                onClick: () => {
                  this.validateLocations();
                },
              },

            });
          }}
          onRowUpdated={(event: any) => {
            const index = fileOutputs.findIndex((x: any) => x.id === event.data.id);
            fileOutputs[index].type = event.data.type;
            fileOutputs[index].folderPath = event.data.folderPath;
            fileOutputs[index].outputFormatList = event.data.outputFormatList;
            fileOutputs[index].ftpAddress = event.data.ftpAddress;
            fileOutputs[index].ftpUsername = event.data.ftpUsername;
            fileOutputs[index].ftpPassword = event.data.ftpPassword;
            fileOutputs[index].generationFailedEmail = event.data.generationFailedEmail;
            fileOutputs[index].errorOccurredEmail = event.data.errorOccurredEmail;
            fileOutputs[index].successEmail = event.data.successEmail;
            fileOutputs[index].passDirectory = event.data.passDirectory;
            fileOutputs[index].holdDirectory = event.data.holdDirectory;
            fileOutputs[index].archiveDirectory = event.data.archiveDirectory;
            fileOutputs[index].historicDirectory = event.data.historicDirectory;
            this.setState(fileOutputs);
            onUpdate(fileOutputs);
          }}
          onRowInserted={(event: any) => {
            const rowData = {
              id: event.data.id,
              type: event.data.type,
              folderPath: event.data.folderPath,
              outputFormatList: event.data.outputFormatList,
              ftpAddress: event.data.ftpAddress,
              ftpUsername: event.data.ftpUsername,
              ftpPassword: event.data.ftpPassword,
              generationFailedEmail: event.data.generationFailedEmail,
              errorOccurredEmail: event.data.errorOccurredEmail,
              successEmail: event.data.successEmail,
              passDirectory: event.data.passDirectory,
              holdDirectory: event.data.holdDirectory,
              archiveDirectory: event.data.archiveDirectory,
              historicDirectory: event.data.historicDirectory,
            };
            fileOutputs.push(rowData);

            this.setState({ fileOutputs });
            onUpdate(fileOutputs);
          }}
          onRowRemoved={(event: any) => {
            fileOutputs.forEach((x: any, index: number) => {
              if (x.id === event.data.id) {
                fileOutputs.splice(index, 1);
                this.setState(fileOutputs);
              }
            });
            onUpdate(fileOutputs);
          }}
          onEditorPreparing={(event: any) => {
            if (event.dataField === 'folderPath') {
              event.editorOptions.disabled = event.row.data.type === 'Local'
            }
          }}
          tableData={fileOutputs}
          fields={[
            {
              caption: 'Location Type',
              dataField: 'type',
              cellData: {
                listItem:
                locationTypes.map((locationType) => ({
                  caption: locationType,
                  dataField: this.locationTypeValues[locationType as keyof ILocationTypeValues]
                }))
              },
              showInfo: false,
              cellType: 'selectBox',
              minWidth: 150,
              isRequired: true,
              cellRender: (cell: any) => {
                const locKey = (cell.value as keyof ILocationTypeValues);
                return (cell.value ? Object.keys(this.locationTypeValues).find((key: string) => this.locationTypeValues[key as keyof ILocationTypeValues] === locKey) : null);
              },
            },
            {
              caption: 'Host/Container',
              dataField: 'ftpAddress',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              minWidth: 150,
            },
            {
              caption: 'Username',
              dataField: 'ftpUsername',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              minWidth: 150,
            },
            {
              caption: 'Password',
              dataField: 'ftpPassword',
              dataType: 'password',
              showInfo: false,
              cellType: 'textBox',
              calculateDisplayValue: (rowData: any) => {
                if (rowData.ftpPassword) {
                  return '******';
                }
              },
              editCellRender: (cell: any) => {
                return (
                  <Textbox
                    className="dx-texteditor-input"
                    type="password"
                    defaultValue={cell.value}
                    autoComplete="off"
                    name="Password"
                    placeholder=""
                    onBlur={(e: any) => {
                      cell.setValue(e.target.value);
                    }}
                  />
                );
              },
              minWidth: 150,
            },
            {
              caption: 'Main Directory',
              dataField: 'folderPath',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              minWidth: 150,
            },
            {
              caption: 'Pass Directory',
              dataField: 'passDirectory',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              allowEditing: true,
              minWidth: 150,
            },
            {
              caption: 'Hold Directory',
              dataField: 'holdDirectory',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              allowEditing: false,
              minWidth: 150,
            },
            {
              caption: 'Archive Directory',
              dataField: 'archiveDirectory',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              allowEditing: false,
              minWidth: 150,
            },
            {
              caption: 'Historic Directory',
              dataField: 'historicDirectory',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              allowEditing: true,
              minWidth: 150,
            },
            {
              caption: 'File Name Format (Workcenter_Device_Lot_Wafer_Timestamp)',
              dataField: 'fileNameFormat',
              dataType: 'string',
              showInfo: false,
              cellType: 'textBox',
              allowEditing: false,
              minWidth: 350,
            },
            {
              caption: 'File Format',
              dataField: 'outputFormatList',
              allowEditing: true,
              showInfo: false,
              allowFiltering: false,
              allowSorting: false,
              cellType: 'tagBox',
              cellData: {
                listItem: [
                  { caption: 'Laurier', dataField: 'Laurier' },
                  { caption: 'ASCII_TMA', dataField: 'ASCII_TMA' },
                  { caption: 'ASCII_TXT', dataField: 'ASCII_TXT' },
                ],
              },
              isRequired: true,
              width: 200,
            },
            {
              caption: 'If File Already Exist',
              dataField: 'fileDuplicateOption',
              cellData: {
                listItem: [
                  { caption: 'Overwrite', dataField: 'Overwrite' },
                  { caption: 'Rename Old and Create New', dataField: 'Rename Old and Create New' },
                  { caption: 'Error Out', dataField: 'Error Out' },
                ],
              },
              showInfo: false,
              cellType: 'selectBox',
              allowEditing: false,
              minWidth: 150,
            },
            {
              caption: 'Alert Email If',
              cellType: 'columns',
              fields: [
                {
                  caption: 'Generation Failed',
                  dataField: 'generationFailedEmail',
                  dataType: 'string',
                  showInfo: false,
                  cellType: 'textBox',
                  allowEditing: true,
                  validationRules: [
                    (
                      <EmailRule
                        ignoreEmptyValue
                        message="Please enter a valid email address"
                        type="email"
                      />
                    ),
                  ],
                  minWidth: 150,
                },
                {
                  caption: 'Error Occurs',
                  dataField: 'errorOccurredEmail',
                  dataType: 'string',
                  showInfo: false,
                  cellType: 'textBox',
                  allowEditing: true,
                  validationRules: [
                    (
                      <EmailRule
                        ignoreEmptyValue
                        message="Please enter a valid email address"
                        type="email"
                      />
                    ),
                  ],
                  minWidth: 150,
                },
                {
                  caption: 'Maps Successful',
                  dataField: 'successEmail',
                  dataType: 'string',
                  showInfo: false,
                  cellType: 'textBox',
                  allowEditing: true,
                  validationRules: [
                    (
                      <EmailRule
                        ignoreEmptyValue
                        message="Please enter a valid email address"
                        type="email"
                      />
                    ),
                  ],
                  minWidth: 150,
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

OutputLocation.propTypes = {
  // eslint-disable-next-line react/require-default-props
  locationTypes: PropTypes.arrayOf(PropTypes.oneOf(['Cloud Container', 'FTP', 'Local'])),
};

export default OutputLocation;
