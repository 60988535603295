import React from 'react';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface ITextareaProps {
  hint?: IHint;
  name: string;
  rows: number;
  placeholder: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: 'off' | undefined;
  className?: string;
  onInput?: (event: any) => void;
  onFocusOut?: (event: any) => void;
  onChange?: (event: any) => void;
  onSelect?: (event: any) => void;
  onClick?: (event: any) => void;
  disabled?: boolean;
}

const Textarea = React.forwardRef((props: ITextareaProps, ref: any) => {
  const {
    value,
    hint,
    name,
    rows,
    placeholder,
    defaultValue,
    autoComplete,
    className,
    onInput,
    onFocusOut,
    onChange,
    onClick,
    onSelect,
    disabled,
  } = props;
  return (
    <div className="d-flex align-items-top justify-content-between pt4">
      <textarea
        disabled={disabled}
        ref={ref}
        name={name}
        rows={rows}
        value={defaultValue}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`flex-98 ${className}`}
        autoComplete={autoComplete}
        onInput={onInput}
        onBlur={onFocusOut}
        onChange={onChange}
        onClick={onClick}
        onSelect={onSelect}
      />
      <Hint
        hint={hint}
        variant="icon"
        className="flex-2 mt10"
      />
    </div>
  );
});

Textarea.defaultProps = {
  defaultValue: '',
  autoComplete: 'off',
  className: '',
  onInput: () => undefined,
  onFocusOut: () => undefined,
  onSelect: () => undefined,
  onClick: () => undefined,
  onChange: () => undefined,
  hint: undefined,
};

export default Textarea;
