import React from 'react';
import { Dropdown, DropdownButton, } from 'react-bootstrap';

interface IDropdownProps {
  list: string[][];
  variant?: string;
  disabled?: boolean;
  full?: boolean;
  selectedValue?: string;
  avoidUpdateOnSelect?: boolean;
  containerClassName?: string;
  defaultValue?: string
  onChange?: (value: string) => void;
  displayElement?: any,
  dropdownButtonClass?: string,
  updateTitle?: boolean,
}

interface IDropdownState {
  selectedIndex: number;
}

class CustomizedDropdown extends React.Component <IDropdownProps, IDropdownState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    variant: 'clear',
    disabled: false,
    full: false,
    selectedValue: '',
    avoidUpdateOnSelect: false,
    onChange: () => undefined,
    displayElement: undefined,
    dropdownButtonClass: '',
  };

  constructor(props: IDropdownProps) {
    super(props);
    this.updateSelectedIndex = this.updateSelectedIndex.bind(this);
    this.findSelectedIndex = this.findSelectedIndex.bind(this);
    const {
      selectedValue,
      list
    } = this.props;
    this.state = {
      selectedIndex: this.findSelectedIndex(selectedValue, list),
    };
  }

  findSelectedIndex = (value: string | undefined, list: string[][]) => {
    if (value !== '' && value !== undefined) {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i][0] === value) {
          return i;
        }
      }
    }
    return 0;
  };

  componentDidUpdate(prevProps: IDropdownProps) {
    if (this.props !== prevProps) {
      const {
        selectedValue,
        list
      } = this.props;
      this.setState({ selectedIndex: this.findSelectedIndex(selectedValue, list) });
    }
  }

  updateSelectedIndex(selectedIndex: number) {
    this.setState({ selectedIndex });
  }

  render() {
    const {
      list,
      variant,
      disabled,
      full,
      avoidUpdateOnSelect,
      containerClassName,
      defaultValue,
      onChange,
      displayElement,
      dropdownButtonClass,
      updateTitle,
    } = this.props;
    const { selectedIndex } = this.state;
    const title = list.length > 0 ? list[selectedIndex >= list.length ? 0 : selectedIndex][1] : '';
    return (
      <div className={`d-flex align-items-center align-items-center ${containerClassName !== undefined ? containerClassName : ''}`}>
        <DropdownButton
          className={`${dropdownButtonClass} ${full === true ? 'dropdown-full' : ''}`}
          disabled={disabled}
          drop="down"
          title={(displayElement !== undefined && avoidUpdateOnSelect) ? displayElement
            : (updateTitle !== undefined && !updateTitle) ? '---' : title}
          variant={variant}
          key={Math.random()
            .toString()}
          defaultValue={defaultValue !== undefined ? defaultValue : undefined}
        >
          {list.map((item: string[], index: number) => {
            const isActive = (selectedIndex === index && selectedIndex > 0);
            return (
              <Dropdown.Item
                className="w-100"
                key={`${item[0]}_${item[1]}`}
                active={isActive}
                disabled={item[0] === ''}
                onClick={() => {
                  if (onChange !== undefined) {
                    onChange(item[0]);
                  }
                  if (!avoidUpdateOnSelect) {
                    this.updateSelectedIndex(index);
                  }
                }}
                eventKey="1"
              >
                {item[1]}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        {/* <Hint
          hint={{
            message: 'Some Message',
            href: '',
            position: 'right',
          }}
          variant="icon"
        /> */}
      </div>
    );
  }
}

export default CustomizedDropdown;

// return (
//   <DropdownButton
//     className={`${full === true ? 'dropdown-full' : ''}`}
//     disabled={disabled}
//     drop="down"
//     title={dropdownTitle}
//     variant={variant}
//   >
//     <TreeView
//       dataSource={list.map((item: string, index: number) => ({ id: index, name: item }))}
//       dataStructure="plain"
//       keyExpr="ID"
//       parentIdExpr="categoryId"
//       selectionMode="multiple"
//       showCheckBoxesMode="normal"
//       selectNodesRecursive={false}
//       displayExpr="name"
//       selectByClick
//       onSelectionChanged={this.handleMultipleSelect}
//     />
//   </DropdownButton>
// );

// handleMultipleSelect(e:any) {
//   const selectedIndex = e.component.getSelectedNodes();
//   // setTimeout(
//   //   this.setState(() => ({ dropdownTitle: '' })), 150,
//   // );
// }
