/* eslint-disable no-param-reassign */
import WaferUtils from '../Utils/WaferUtils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import { InteractionType } from './Events';

export const dragMouseMove = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }): void => {
  if (data && data.interactionType !== InteractionType.Drag) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  const {
    isDragControlActive, innerViewPort, keyIndex, showRadarOnly,
  } = waferUtil.waferMapVariables;
  if (!isMouseDown || !isDragControlActive || showRadarOnly) return;
  waferUtil.dragWaferMap({
    startX, startY, endX, endY,
  });
  PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_DRAGGED, {
    startX: startX / innerViewPort.width,
    startY: startY / innerViewPort.height,
    endX: endX / innerViewPort.width,
    endY: endY / innerViewPort.height,
    isFromPubSub: true,
  }, keyIndex);
};
