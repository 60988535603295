import { ISelectionsObject } from 'interfaces';
import SelectionCriteria from './SelectionCriteria';

export interface IDefaultSelectionCriteria {
  entityType: string;
  values: string[];
  isVisibiltyDefault?: boolean;
  visibleColumns?: string[],
  visibleColumnsValues?: { [column: string]: string }[],
}

export interface ISelectionCriteriaDefaultSelectionDetails {
  entityType: string;
  selection: {
    id: string
    name: string;
  }[];
}

export interface ISelectionCriteriaStates {
  // gridColumns: IColumnMetaDictionary;
  fullScreenControlType?: string | undefined;
  isLoading: boolean;
  pageSize: number;
  selectedPageSize: number;
  pageSizeStep: number;
  hideTestParamforPolicy: boolean;
  // selectedControl: string;
  selectedFavoriteAction: any;
  showFavoritesAccordian: boolean;
  isFavoritePopupVisible: boolean;
  favoriteToEdit: any;
  markGridsForUpdate: boolean;
  isSaveAndCloseClickedSCState: boolean;
}

export interface ISelectionCritereaWidget {
  controlType: string,
  columnWeight: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  isVisible: boolean,
  highlightOtherGrids?: boolean
  isSelectionMandatory?: boolean;
  maxSelectedCount?: number;
  minVisibilityCriteria?: string[];
}

export interface ISelectionCriteriaProps {
  useDefaultHeight?: undefined | boolean;
  title?: string;
  getSelectionCriteriaData?: any;
  widgetsList: ISelectionCritereaWidget[];
  // TO DO: ADD DEFAULT VISIBILITY , isVisible: boolean
  defaultSelection: IDefaultSelectionCriteria[];
  closeSelectionCriteriaModal?: (
    preserveSelection: boolean,
    highlightingKeys: { [key: string]: any },
    keepModalOpen: boolean,
    isCleared: boolean,
    updatedVisibleColumnsJsonForPolicy: { [entityName: string]: string },
  ) => void;
  returnDataOnSelectButtonClick?: boolean;
  advancedFilter?: string;
  filterCriteria?: any[];
  defaultHighlightingKeys?: { [key: string]: any };
  testParameterIdsAndNames?: ISelectionsObject[];
  isForSeedCalculation?: boolean,
  removeAllReports?: () => void;
  selectionCounts?: any[];
  hideFavoritesButton?: boolean;
  overrideSelectionCriteria?: (selectionCriteria: any, advancedFilter:any) => void;
  populateDefaultSelectionFlag?: boolean,
  isSaveAndCloseClickedSCProp?: boolean;
  visibleColumnsForPolicy?: { [entityName: string]: any };
}

export const emptyDefaultSelectionCriteria: IDefaultSelectionCriteria[] = [
  {
    entityType: 'facility',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'workCenter',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'device',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'testProgram',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'testProgramRevision',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'lot',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'wafer',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
    visibleColumnsValues: []
  },
  {
    entityType: 'testParameter',
    isVisibiltyDefault: true,
    visibleColumns: [],
    values: [],
  },
];

export const listOfEntityTypes: string[] = [
  'facility',
  'work_center',
  'device',
  'test_program_revision',
  'test_program',
  'lot',
  'wafer',
  'test_parameter'
]
