import React from 'react';
import DataGrid, { FilterRow, HeaderFilter, Column } from 'devextreme-react/data-grid';
import Query from 'devextreme/data/query';
import { Button } from 'devextreme-react/button';
import { Multiselect } from 'multiselect-react-dropdown';
import Heading from '../../../wrapped-component/hint-controls/Heading';
// eslint-disable-next-line no-unused-vars
import { WaferMapData, Option, WaferMapTestData } from '../wafer-map/web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import WaferData from '../WaferData';

const fields = [
  'Soft Bin',
  'T0Data',
  'T0MinLimit',
  'T0MaxLimit',
  'T1Data',
  'T1MinLimit',
  'T1MaxLimit',
  'Bin Color',
  'Image',
  'X',
  'Y',
  'Row',
  'Col',
  'rIndex',
  'cIndex',
];

const keys = [
  'softBin',
  'T0Data',
  'T0MinLimit',
  'T0MaxLimit',
  'T1Data',
  'T1MinLimit',
  'T1MaxLimit',
  'binColor',
  'image',
  'x',
  'y',
  'row',
  'col',
  'rIndex',
  'cIndex',
];

type DataGridComponentProps = {
  waferData: WaferData,
  keyIndex: string,
  setGridInstanceHandler: (utils: DataGridComponent, keyIndex: string) => void,
};

type DataGridComponentState = {
  waferMapdata: WaferMapTestData,
  selectedData: WaferMapData[],
  dataSource: WaferMapData[],
  advancedFilter: string
  dataGrid: DataGrid | null,
  advancedFilterFlag: boolean,
  gridFilteredData: WaferMapData[],
  options: Option[],
};

class DataGridComponent extends React.Component<DataGridComponentProps, DataGridComponentState> {
  private fromPlot = false;

  constructor(props: DataGridComponentProps) {
    super(props);
    const { dataSource, data } = this.dataGenerator();
    this.state = {
      waferMapdata: data,
      selectedData: [],
      dataSource,
      advancedFilter: '',
      dataGrid: null,
      advancedFilterFlag: false,
      gridFilteredData: dataSource,
      options: [],
    };
  }

  componentDidMount() {
    this.setDieSubViewOptions();
    const { setGridInstanceHandler, keyIndex } = this.props;
    if (setGridInstanceHandler) setGridInstanceHandler(this, keyIndex);
  }

  dataGenerator = () => {
    const dataSource: WaferMapData[] = [];
    const data: WaferMapTestData = [];
    const { waferData } = this.props;
    for (let i = 0; i < waferData.waferMapTestData.length; i += 1) {
      data.push([]);
      for (let j = 0; j < waferData.waferMapTestData[i].length; j += 1) {
        if (waferData.waferMapTestData[i][j]) {
          const dieData = { ...waferData.waferMapTestData[i][j] };
          data[i].push(dieData);
          dataSource.push(dieData!);
          dataSource[dataSource.length - 1]!.row = i; // set x val of currently pushed object
          dataSource[dataSource.length - 1]!.col = j; // set y val of currently pushed object
        } else {
          data[i].push(null);
        }
      }
    }
    return { dataSource, data };
  };

  setDieSubViewOptions = () => {
    const { waferData } = this.props;
    if (waferData.dieSubView == null) return;
    const options: Option[] = [];
    const reticleCols = waferData.dieSubView![0].length;
    for (let i = 0; i < waferData.dieSubView.length; i += 1) {
      for (let j = 0; j < waferData.dieSubView[i].length; j += 1) {
        waferData.dieSubView[i][j].forEach(
          (_ele, index) => {
            options.push({
              name: `DieSub${(i * reticleCols + j) + 1}_${index + 1}`,
              x: i,
              y: j,
              key: index,
            });
          },
        );
      }
    }
    this.setState({
      options,
    });
  };

  filterClearHelper = () => {
    const { dataGrid } = this.state;
    if (dataGrid == null) return;
    dataGrid.instance.clearFilter();
  };

  applyFilterHelper = (filteredData: WaferMapData[]) => {
    // eslint-disable-next-line no-unused-vars
    const filteredDataList: { row: number | null, col: number | null }[] = filteredData.map((data: WaferMapData) => {
      return { row: data.row!, col: data.col! };
    });
  };

  onContentReady = () => {
    const { advancedFilterFlag, dataGrid } = this.state;
    const { dataSource } = this.state;
    if (advancedFilterFlag === true) return;
    if (dataGrid == null) return;
    const filterExp = dataGrid.instance.getCombinedFilter();
    if (filterExp) {
      const sortColumn = dataGrid.instance.columnOption('sortIndex:0') || { dataField: '' };
      const filteredData = Query(dataSource).filter(filterExp).sortBy(sortColumn.dataField, sortColumn.sortOrder === 'desc').toArray();
      this.applyFilterHelper(filteredData);
    } else {
      this.filterClearHelper();
    }
  };

  onClearAdvancedFilter = () => {
    this.filterClearHelper();
    this.setState((prevState) => ({
      gridFilteredData: prevState.dataSource,
      advancedFilterFlag: !prevState.advancedFilterFlag,
    }));
  };

  advancedFilter = () => {
    const { advancedFilter, dataSource } = this.state;
    let exp: string = advancedFilter;
    fields.forEach((field: string, index: number) => {
      if (exp.indexOf(field) !== -1) {
        exp = exp.replace(field, `data.${keys[index]}`);
      }
    });
    // eslint-disable-next-line no-unused-vars, no-eval
    const filteredData = Query(dataSource).toArray().filter((data) => eval(exp));
    this.applyFilterHelper(filteredData);
    this.setState({
      gridFilteredData: filteredData,
      advancedFilterFlag: true,
    });
  };

  hideAllSubDies = () => undefined;

  // eslint-disable-next-line no-unused-vars
  onSelect = (e: any) => undefined;

  onRemove = (e: any) => {
    this.hideAllSubDies();
    this.onSelect(e);
  };

  onSelectionChanged = (e: any) => {
    if (this.fromPlot === true) {
      this.fromPlot = false;
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const selectedData: { row: number, col: number }[] = e.selectedRowsData.map((data: WaferMapData) => {
      return { row: data.row, col: data.col };
    });
    // eslint-disable-next-line no-unused-vars
    const deselectedData: { row: number, col: number }[] = e.currentDeselectedRowKeys.map((data: WaferMapData) => {
      return { row: data.row, col: data.col };
    });
    this.setState({ selectedData: e.selectedRowsData });
  };

  dieSelectedOnWafer = (data: { selectedData: { row: number, col: number }[] }) => {
    const { waferMapdata } = this.state;
    const selectedRowsData: WaferMapData[] = [];
    for (let i = 0; i < data.selectedData.length; i += 1) {
      if (waferMapdata[data.selectedData[i].row][data.selectedData[i].col]) {
        selectedRowsData.push(waferMapdata[data.selectedData[i].row][data.selectedData[i].col]!);
      }
    }
    this.setState((prevState) => {
      return {
        selectedData: [...prevState.selectedData, ...selectedRowsData],
      };
    });
  };

  dieSelectedOnPlot = (data: { selectedData: { row: number, col: number }[] }) => {
    const { waferMapdata } = this.state;
    const selectedRowsData: WaferMapData[] = [];
    for (let i = 0; i < data.selectedData.length; i += 1) {
      if (waferMapdata[data.selectedData[i].row][data.selectedData[i].col]) {
        selectedRowsData.push(waferMapdata[data.selectedData[i].row][data.selectedData[i].col]!);
      }
    }
    this.fromPlot = true;
    this.setState((prevState) => {
      return {
        selectedData: [...prevState.selectedData, ...selectedRowsData],
      };
    });
  };

  dieUnSelectedOnPlot = (data: { unselectedRowsData: { row: number, col: number }[] }) => {
    const { selectedData } = this.state;
    const selectedRowsData: WaferMapData[] = [...selectedData];
    for (let i = 0; i < selectedRowsData.length; i += 1) {
      if (selectedRowsData[i].row === data.unselectedRowsData[0].row && selectedRowsData[i].col === data.unselectedRowsData[0].col) {
        selectedRowsData.splice(i, 1);
        break;
      }
    }
    this.fromPlot = true;
    this.setState({ selectedData: [...selectedRowsData] });
  };

  render() {
    const {
      options, advancedFilterFlag, gridFilteredData, advancedFilter, selectedData,
    } = this.state;
    return (
      <div>
        <Heading size={6}>Select Sub Die(s)</Heading>
        <Multiselect
          options={options} // Options to display in the dropdown
          displayValue="name" // Property name to display in the dropdown options
          onSelect={this.onSelect} // Function will trigger on select event
          onRemove={this.onRemove}
        />
        <div className="w-100 d-flex align-items-center justify-content-between pt10 pb10 background-color-light">
          {advancedFilterFlag ? (
            <>
              <span className="label mr10 text-nowrap">Advanced filter</span>
              <input
                className="form-control"
                type="text"
                name="advancedFilter"
                value={advancedFilter}
                // eslint-disable-next-line no-return-assign
                onChange={(e) => { this.setState({ advancedFilter: e.target.value }); }}
              />
            </>
          ) : null}
        </div>
        <div className="w-100 d-flex align-items-center pb10 background-color-light">
          <Button
            text="Advanced Filter"
            visible={!advancedFilterFlag}
            onClick={this.onClearAdvancedFilter}
          />
          <Button
            text="Apply"
            visible={advancedFilterFlag}
            onClick={this.advancedFilter}
          />
          <Button
            text="Cancel Advanced Filter"
            className="ml10"
            visible={advancedFilterFlag}
            onClick={this.onClearAdvancedFilter}
          />
        </div>
        <DataGrid
          dataSource={gridFilteredData}
          showBorders
          // eslint-disable-next-line no-return-assign
          ref={(ref) => {
            // eslint-disable-next-line react/destructuring-assignment
            if (this.state.dataGrid == null) {
              this.setState({ dataGrid: ref });
            }
          }}
          selection={{ mode: 'multiple' }}
          onSelectionChanged={this.onSelectionChanged}
          onContentReady={this.onContentReady}
          selectedRowKeys={selectedData}
          height="470px"
        >
          <FilterRow visible />
          <HeaderFilter visible />
          <Column
            width={26}
            cellRender={(e) => {
              return <div className="w10 h10 mt5 " style={{ backgroundColor: e.data.binColor.key }} />;
            }}
          />
          <Column
            dataField="row"
          />
          <Column
            dataField="col"
          />
          {/* <Column */}
          {/*  dataField="T0Data" */}
          {/* /> */}
          {/* <Column */}
          {/*  dataField="T1Data" */}
          {/* /> */}
          <Column
            dataField="softBin"
          />
        </DataGrid>
      </div>
    );
  }
}

export default DataGridComponent;
