/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import ScrollView from "devextreme-react/scroll-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faTimes,
  faExclamation,
  faStream,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

import TopbarNav from "components/navigational-component/topbar-nav/TopbarNav";
import CustomizedTabs from "components/wrapped-component/customized-tabs/CustomizedTabs";
import { httpService } from "services/http.service";
import { DataGrid } from "devextreme-react";
import RealTimeStatsGraphCard from "../../components/template-component/real-time-stats-card/RealTimeStatsGraphCard";
import RealTimeStatsCard from "../../components/template-component/real-time-stats-card/RealTimeStatsCard";
import CTStatsMockData from "./tempCTStatsMockData";

import "./control-tower.scss";
import AppConstants from "../../constants";
import PaginatedDatagrid from "./PaginatedDatagrid";

let stepDatagrid: PaginatedDatagrid | null = null;
let dataIntakeDatagrid: PaginatedDatagrid | null = null;

const ControlTower = () => {
  const history = useHistory();

  const statusMap: { [key: string]: string } = {
    UNPROCESSED: "NEW",
    PROCESSING: "PROCESSING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILED",
    NEW: "NEW",
    RUNNING: "PROCESSING",
    DONE: "SUCCESS",
    SUSPENDED: "FAILED",
    STOPPED: "FAILED",
    FAILED: "FAILED",
    FINISHED: "SUCCESS",
    ABORTED: "FAILED",
  };

  const [globalCPUStats, updateGlobalCPUStats]: any = useState({
    timespan: "",
    data: CTStatsMockData.getCPUUsed(),
  });

  const [globalMemoryStats, updateGlobalMemoryStats]: any = useState({
    timespan: "",
    data: CTStatsMockData.getMemory(),
  });

  const [globalWorkerStats, updateGlobalWorkerStats]: any = useState({
    timespan: "",
    data: CTStatsMockData.getWorkersCount(),
  });

  const [wafersData, updateWafersData]: any = useState([]);
  const [workflowsData, updateWorkflowsData]: any = useState([]);
  const [dataIntakeQueue, updateDataIntakeQueue]: any = useState([]);
  const [stepIntakeQueue, updateStepIntakeQueue]: any = useState([]);
  const [dataGrid, setDataGrid] = useState<DataGrid | null>(null);

  const [workerQueueData, updateworkerQueueData]: any = useState(
    CTStatsMockData.getCTStats()
  );
  const [errorsData, updateErrorsData]: any = useState(
    CTStatsMockData.getCTStats()
  );
  const [warningsData, updateWarningsData]: any = useState(
    CTStatsMockData.getCTStats()
  );

  const getGlobalCPUStats = (timespan: string) => {
    if (
      globalCPUStats.data === undefined ||
      globalCPUStats.timespan !== timespan
    ) {
      httpService.getGlobalCPUStats(timespan).then((data: any) => {
        updateGlobalCPUStats({
          timespan,
          data,
        });
      });
    }
  };
  const getGlobalMemoryStats = (timespan: string) => {
    if (
      globalMemoryStats.data === undefined ||
      globalMemoryStats.timespan !== timespan
    ) {
      httpService.getGlobalMemoryStats(timespan).then((data: any) => {
        updateGlobalMemoryStats({
          timespan,
          data,
        });
      });
    }
  };
  const getGlobalWorkerStats = (timespan: string) => {
    if (
      globalWorkerStats.data === undefined ||
      globalWorkerStats.timespan !== timespan
    ) {
      httpService.getGlobalWorkerStats(timespan).then((data: any) => {
        updateGlobalWorkerStats({
          timespan,
          data,
        });
      });
    }
  };

  const addRetryButton = (dataRow: any) => {
    dataRow.retryButton =
      (dataRow.state !== undefined &&
        (dataRow.state === "ABORTED" ||
          dataRow.state === "FAILED" ||
          dataRow.state === "SUSPENDED" ||
          dataRow.state === "STOPPED")) ||
      (dataRow.status !== undefined && dataRow.status === "FAILURE");
  };

  const retryWorkflowButtonCellRender = (e: any, httpCaller: any) => {
    return e.data.retryButton ? (
      <Button
        onClick={async () => {
          const data = await httpCaller(e.data.id);
          if (data.statusCode === 200) {
            dataGrid?.instance.beginUpdate();
            dataGrid?.instance.cellValue(e.rowIndex, e.column.dataField, false);
            dataGrid?.instance.endUpdate();
          }
        }}
      >
        Retry
      </Button>
    ) : (
      ""
    );
  };

  const GetPolicyResult = (e: any) => {
    history.push(`/policy-output/${e.id}`, { Id: e.id });
  };

  const [graphStatCardExpanded, toggleGraphStatCardExpanded] = useState(false);
  const [previewStatIndex, updatePreviewStatIndex] = useState("-1");
  const GetRowData = (e: any) => {
    history.push(`/policy-output/${e.id}`, { Id: e.id });
  };

  const updateDataIntakeDataAfterLoad = (data: any[]) => {
    updateDataIntakeQueue(data);
  };

  const processDataAndStepIntakeDataResponse = (data: any[]) => {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].startDate)
        data[i].startDate = new Date(`${data[i].startDate}+00:00`);
      if (data[i].endDate)
        data[i].endDate = new Date(`${data[i].endDate}+00:00`);
      if (data[i].createdOn)
        data[i].createdOn = new Date(`${data[i].createdOn}+00:00`);
      if (data[i].status) data[i].status = statusMap[data[i].status];
      if (data[i].state) data[i].state = statusMap[data[i].state];
      addRetryButton(data[i]);
    }
  };

  const updateStepIntakeDataAfterLoad = (data: any[]) => {
    updateStepIntakeQueue(data);
  };
  const { isTempHidden, visualizeInfrastructure } = AppConstants;
  return (
    <ScrollView showScrollbar="always" scrollByThumb reachBottomText="">
      <TopbarNav
        title="Control Tower"
        items={[]}
        showAvatar={false}
        showNotifications={false}
        secondaryActions={
          !isTempHidden ? (
            <Button
              variant="primary"
              className="mt-6 mr10"
              size="sm"
              onClick={() => {
                history.push({
                  pathname: visualizeInfrastructure,
                });
              }}
            >
              <FontAwesomeIcon
                className="icon mr10"
                size="sm"
                icon={faEllipsisH}
              />
              Visualize Infrastructure
            </Button>
          ) : undefined
        }
      />
      <Container fluid className="pl20 pr20">
        {!isTempHidden && (
          <Row className="break-bottom background-color-light">
            <Col md={12} lg={4}>
              <RealTimeStatsGraphCard
                id="10"
                selectStatForDetailedPreview={updatePreviewStatIndex}
                onTimespanUpdate={(value: string) => {
                  getGlobalCPUStats(value);
                }}
                selectedTimespan={globalCPUStats.timespan}
                key={globalCPUStats.data.title}
                isExpanded={graphStatCardExpanded}
                title={globalCPUStats.data.name}
                stat={globalCPUStats.data.value}
                data={globalCPUStats.data.data}
                onCardClick={() => {
                  history.push({
                    pathname: visualizeInfrastructure,
                  });
                }}
              />
            </Col>
            <Col md={12} lg={4}>
              <RealTimeStatsGraphCard
                id="10"
                selectStatForDetailedPreview={updatePreviewStatIndex}
                onTimespanUpdate={(value: string) => {
                  getGlobalMemoryStats(value);
                }}
                selectedTimespan={globalMemoryStats.timespan}
                key={globalMemoryStats.data.title}
                isExpanded={graphStatCardExpanded}
                title={globalMemoryStats.data.name}
                stat={globalMemoryStats.data.value}
                data={globalMemoryStats.data.data}
                onCardClick={() => {
                  history.push({
                    pathname: visualizeInfrastructure,
                  });
                }}
              />
            </Col>
            <Col md={12} lg={4}>
              <RealTimeStatsGraphCard
                id="10"
                selectStatForDetailedPreview={updatePreviewStatIndex}
                onTimespanUpdate={(value: string) => {
                  getGlobalWorkerStats(value);
                }}
                selectedTimespan={globalWorkerStats.timespan}
                key={globalWorkerStats.data.title}
                isExpanded={graphStatCardExpanded}
                title={globalWorkerStats.data.name}
                stat={globalWorkerStats.data.value}
                data={globalWorkerStats.data.data}
                onCardClick={() => {
                  history.push({
                    pathname: visualizeInfrastructure,
                  });
                }}
              />
            </Col>
          </Row>
        )}
        {!isTempHidden &&
          globalCPUStats !== undefined &&
          globalMemoryStats !== undefined &&
          globalWorkerStats !== undefined && (
            <div className="w-100 mt-1">
              <button
                onClick={() => {
                  toggleGraphStatCardExpanded(!graphStatCardExpanded);
                }}
                type="button"
                className="cursor-pointer border-all background-color-light mx-auto d-block w50"
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={!graphStatCardExpanded ? faAngleDown : faAngleUp}
                />
              </button>
            </div>
          )}
        {!isTempHidden && (
          <Row className="mt20 mb10">
            <Col md={4} lg={4}>
              <RealTimeStatsCard
                statsList={errorsData}
                title="Errors"
                icon={faTimes}
              />
            </Col>
            <Col md={4} lg={4}>
              <RealTimeStatsCard
                statsList={warningsData}
                title="Warnings"
                icon={faExclamation}
              />
            </Col>
            <Col md={4} lg={4}>
              <RealTimeStatsCard
                statsList={workerQueueData}
                title="Queue"
                icon={faStream}
              />
            </Col>
          </Row>
        )}

        <Row className="mt10 background-color-light">
          <Col className="pb40 border-all">
            <CustomizedTabs
              shadow={false}
              tabItems={[
                {
                  title: "Data Intake Queue",
                  eventKey: "dataIntakeQueue",
                  content: (
                    <PaginatedDatagrid
                      setRef={(ref: DataGrid) => {
                        setDataGrid(ref);
                      }}
                      pageSize={10}
                      httpLoadDataCallback={httpService.getDataIntakeQueue}
                      updateParentDataAfterLoad={updateDataIntakeDataAfterLoad}
                      processDataAfterLoad={
                        processDataAndStepIntakeDataResponse
                      }
                      ref={(ref: any) => {
                        dataIntakeDatagrid = ref;
                      }}
                      columns={[
                        {
                          caption: "File Name",
                          dataField: "fileName",
                          dataType: "string",
                          width: 200,
                        },
                        {
                          caption: "Source File Location",
                          dataField: "sourceFileLocation",
                          dataType: "string",
                          width: 200,
                        },
                        {
                          caption: "Intermediate File Location",
                          dataField: "extractedFileLocation",
                          dataType: "string",
                          width: 200,
                        },
                        {
                          caption: "Target File Location",
                          dataField: "targetFileLocation",
                          dataType: "string",
                        },
                        {
                          caption: "Status",
                          dataField: "status",
                          dataType: "string",
                          viewMode: "tag",
                        },
                        {
                          caption: "Load Step",
                          dataField: "type",
                          dataType: "string",
                        },
                        {
                          caption: "Message",
                          dataField: "message",
                          dataType: "string",
                        },
                        {
                          caption: "Database",
                          dataField: "database",
                          dataType: "string",
                          width: 200,
                        },
                        {
                          caption: "Created on",
                          dataField: "createdOn",
                          dataType: "datetime",
                        },
                        {
                          caption: "Start time",
                          dataField: "startDate",
                          dataType: "datetime",
                        },
                        {
                          caption: "End time",
                          dataField: "endDate",
                          dataType: "datetime",
                        },
                        {
                          caption: "Uploading Time",
                          dataField: "uploadingTime",
                          dataType: "string",
                          allowFiltering: false,
                        },
                        {
                          caption: "Retry",
                          dataField: "retryButton",
                          dataType: "string",
                          allowFiltering: false,
                          cellRender: (e: any) => {
                            return retryWorkflowButtonCellRender(
                              e,
                              httpService.retryWorkflowLoadFile
                            );
                          },
                        },
                        {
                          caption: "Worker ID",
                          dataField: "workerId",
                          dataType: "string",
                          width: 250,
                        },
                      ]}
                    />
                  ),
                },
                {
                  title: "Step Intake Queue",
                  eventKey: "stepIntakeQueue",
                  content: (
                    <PaginatedDatagrid
                      pageSize={10}
                      httpLoadDataCallback={httpService.getStepIntakeQueue}
                      updateParentDataAfterLoad={updateStepIntakeDataAfterLoad}
                      processDataAfterLoad={
                        processDataAndStepIntakeDataResponse
                      }
                      ref={(ref: any) => {
                        stepDatagrid = ref;
                      }}
                      columns={[
                        {
                          caption: "Name",
                          dataField: "name",
                          dataType: "string",
                        },
                        {
                          caption: "Policy Name",
                          dataField: "policyName",
                          dataType: "string",
                        },
                        {
                          caption: "Status",
                          dataField: "state",
                          dataType: "string",
                          viewMode: "tag",
                        },
                        {
                          caption: "Type",
                          dataField: "type",
                          dataType: "string",
                        },
                        {
                          caption: "Message",
                          dataField: "failureReason",
                          dataType: "string",
                        },
                        {
                          caption: "Policy Database",
                          dataField: "policyDatabase",
                          dataType: "string",
                        },
                        {
                          caption: "Load Database",
                          dataField: "loadDatabase",
                          dataType: "string",
                        },
                        {
                          caption: "Created on",
                          dataField: "createdOn",
                          dataType: "datetime",
                        },
                        {
                          caption: "Start time",
                          dataField: "startDate",
                          dataType: "datetime",
                        },
                        {
                          caption: "End time",
                          dataField: "endDate",
                          dataType: "datetime",
                        },
                        {
                          caption: "Execution Time",
                          dataField: "uploadingTime",
                          dataType: "string",
                          allowFiltering: false,
                        },
                        {
                          caption: "Retry",
                          dataField: "retryButton",
                          dataType: "string",
                          allowFiltering: false,
                          cellRender: (e: any) => {
                            return retryWorkflowButtonCellRender(
                              e,
                              httpService.retryWorkflowStep
                            );
                          },
                        },
                        {
                          caption: "Worker ID",
                          dataField: "workerId",
                          dataType: "string",
                          width: 250,
                        },
                      ]}
                    />
                  ),
                },
              ]}
              defaultActivityKey="active"
            />
          </Col>
        </Row>
      </Container>
    </ScrollView>
  );
};

export default ControlTower;