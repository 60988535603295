import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from 'devextreme/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '../../wrapped-component/hint-controls/Button';
import toast from '../../../CustomToast';
import { httpService } from '../../../services/http.service';

interface AMGPolicySummaryStatsOverViewProps {
  height: number;
  selectedStageId: string;
  policyResult: any;
  showGrid: any [];
}

interface AMGPolicySummaryStatsOverViewState {
  currentData: AMGSummaryStats[];
  zippedFileName: string;
  isZippedFileDownloading: boolean;
  showAMGSummaryStats: boolean;
}

interface AMGSummaryStats {
  waferKey: string;
  totalDies: number;
  originalGoodDies: number;
  revisedGoodDies: number;
  changeInGoodDies: number;
  downgradedDies: number;
  upgradedDies: number;
  reclassifiedDies: number;
}

class AMGPolicySummaryStatsOverView extends React.Component<AMGPolicySummaryStatsOverViewProps, AMGPolicySummaryStatsOverViewState> {
  constructor(props: AMGPolicySummaryStatsOverViewProps) {
    super(props);
    const [currentData, zippedFileName] = this.fetchData(props.policyResult, props.selectedStageId);
    this.state = {
      currentData,
      zippedFileName,
      isZippedFileDownloading: false,
      showAMGSummaryStats: false,
    };
  }

  componentDidMount() {
    const { policyResult } = this.props;
    if (policyResult.type === 'AMG') {
      this.checkShowGrid();
    }
  }

  componentDidUpdate(prevProps: Readonly<AMGPolicySummaryStatsOverViewProps>): void {
    if (prevProps.selectedStageId !== this.props.selectedStageId) {
      const [currentData, zippedFileName] = this.fetchData(this.props.policyResult, this.props.selectedStageId);
      this.setState({
        currentData,
        zippedFileName
      });
    }
  }

  fetchData = (policyResult: any, selectedStageId: string): [AMGSummaryStats[], string] => {
    return [policyResult.amgSummaryStats, policyResult.zippedAssemblyMapsFileName];
  };

  checkShowGrid = () => {
    const {
      showGrid,
      policyResult
    } = this.props;
    if (showGrid.includes('AMGGrids') && policyResult.type === 'AMG') {
      this.setState({ showAMGSummaryStats: true });
    }
  }

  cellRender = (data: any) => {
    let percentColor = 'black';
    if (data.column.dataField === 'changeInGoodDies') {
      percentColor = data.value !== 0 ? (data.value > 0 ? 'green' : 'red') : 'black';
    }
    return (
      <div>
        <div>{data.value}</div>
        <div style={{ color: percentColor }}>
          {formatNumber(data.value / data.data.totalDies, {
            type: 'percent',
            precision: 2
          })}
        </div>
      </div>
    );
  };

  render() {
    const { height } = this.props;
    const {
      currentData,
      isZippedFileDownloading,
      zippedFileName,
      showAMGSummaryStats
    } = this.state;

    return (
      showAMGSummaryStats && (
        <div>
          <Row>
            <Col lg={6}>
              <Heading size={6}> AMG Summary Statistics</Heading>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
                className="mb15"
              >

                <Button
                  className="d-block h30 ml20"
                  size="sm"
                  variant="success"
                  onClick={async () => {
                    this.setState({ isZippedFileDownloading: true });
                    const zippedFileLink = await httpService.downloadZippedAssemblyMapsFile(zippedFileName);
                    if (zippedFileLink !== null && zippedFileLink.length !== 0) {
                      window.location.href = zippedFileLink;
                      toast.success('Assembly maps downloaded.');
                    }
                    this.setState({ isZippedFileDownloading: false });
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    className={`mr10 ${isZippedFileDownloading ? 'icon-pulse' : ''}`}
                    icon={isZippedFileDownloading ? faSpinner : faFileDownload}
                  />
                  Download Assembly Maps
                </Button>

              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <CustomizedDataGrid
                height={height}
                scrollMode="virtual"
                showAdvancedFilters={false}
                showGroupPanel={false}
                showFilterRow={false}
                enableColumnChooser={false}
                showRowLines
                showColumnLines
                columnAutoWidth
                tableData={currentData}
                fields={[
                  {
                    caption: 'Wafer #',
                    dataField: 'waferKey',
                    dataType: 'text',
                  },
                  {
                    caption: 'Good Dies',
                    fields: [
                      {
                        caption: 'Original',
                        dataField: 'originalGoodDies',
                        cellRender: this.cellRender,
                      },
                      {
                        caption: 'Revised',
                        dataField: 'revisedGoodDies',
                        cellRender: this.cellRender,

                      },
                      {
                        caption: 'Delta',
                        dataField: 'changeInGoodDies',
                        cellRender: this.cellRender,
                      },
                    ],
                  },
                  {
                    caption: 'Classification',
                    fields: [
                      {
                        caption: 'Downgraded',
                        dataField: 'downgradedDies',
                        cellRender: this.cellRender,
                      },
                      {
                        caption: 'Upgraded',
                        dataField: 'upgradedDies',
                        cellRender: this.cellRender,

                      },
                      {
                        caption: 'Reclassified',
                        dataField: 'reclassifiedDies',
                        cellRender: this.cellRender,
                      },
                    ],
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export default AMGPolicySummaryStatsOverView;
