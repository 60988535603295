/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { fileFilterOptionsValues, IFileFilter } from 'interfaces';
import {
  DataGrid, DateBox, SelectBox, TextBox
} from 'devextreme-react';
import {
  Column, Editing, Lookup, RequiredRule
} from 'devextreme-react/data-grid';
import dataGridConstants from 'dataGridConstants';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import { render } from 'react-dom';

interface IFileFilterOptionsProps {
  updateFileFilterOptions: (fileFilterOptions: any) => void;
  fileFilterOptions: IFileFilter[];
}

const newFilter: IFileFilter = {
  isSelected: false,
  filterName: '',
  filterCondition: '',
  firstValue: '',
  secondValue: '',
  booleanExpression: ''
}

const isValidDate = (dateString: any) => {
  const date = Date.parse(dateString);
  return !Number.isNaN(date);
}

const fileFilterWithTwoValuesTable = ['between', 'custom'];
const brackets = ['(', ')'];
const test = ['Creation Date', 'Modified Date', 'Wafer End Time', 'Lot End Time']

const CustomRendererHelper = (x: any, y: any, options: any, selectedRow: IFileFilter, handleUpdate: any, determineDataType: any) => {
  const { row, columnIndex } = x;
  let value;
  if (columnIndex === 1) {
    value = selectedRow.filterName;
  } else if (columnIndex === 2) {
    value = selectedRow.filterCondition;
  } else if (columnIndex === 3) {
    value = selectedRow.firstValue;
  } else if (columnIndex === 4) {
    value = selectedRow.secondValue;
  } else if (columnIndex === 5) {
    value = selectedRow.booleanExpression;
  }
  const dataType = determineDataType(row);
  const {
    filterNames, dateFilterConditions, nameExtensionFilterConditions, fileInclusionOption, booleanExpressionOptions
  } = options;

  switch (dataType) {
    case 'date':
      if (columnIndex === 1){
        row.data.filterName = value;
        return <SelectBox dataSource={filterNames} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 2){
        value = dateFilterConditions.includes(value) ? value : dateFilterConditions[0];
        row.data.filterCondition = value;
        return <SelectBox dataSource={dateFilterConditions} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 3){
        row.data.firstValue = isValidDate(value) ? value : new Date().toLocaleDateString();
        return <DateBox value={isValidDate(value) ? value : new Date().toDateString()} onValueChange={(e) => handleUpdate(e, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 4){
        if (!fileFilterWithTwoValuesTable.includes(row.data.filterCondition)) {
          row.data.secondValue = '';
          return null;
        }
        row.data.secondValue = isValidDate(value) ? value : new Date().toLocaleDateString();
        return <DateBox value={isValidDate(value) ? value : new Date().toDateString()} onValueChange={(e) => handleUpdate(e, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 5){
        row.data.booleanExpression = value;
        return <SelectBox dataSource={booleanExpressionOptions} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      break;
    case 'string':
      if (columnIndex === 1){
        row.data.filterName = value;
        return <SelectBox dataSource={filterNames} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 2){
        value = nameExtensionFilterConditions.includes(value) ? value : nameExtensionFilterConditions[0];
        row.data.filterCondition = value;
        return <SelectBox dataSource={nameExtensionFilterConditions} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 3){
        row.data.firstValue = value
        return <TextBox value={value} onValueChange={(e) => handleUpdate(e, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 4){
        if (!fileFilterWithTwoValuesTable.includes(row.data.filterCondition)) {
          row.data.secondValue = '';
          return null;
        }
        row.data.secondValue = fileInclusionOption.includes(value) ? value : fileInclusionOption[0];
        return <SelectBox dataSource={fileInclusionOption} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 5){
        row.data.booleanExpression = value;
        return <SelectBox dataSource={booleanExpressionOptions} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      break;
    case 'bracket':
      if (columnIndex === 1){
        row.data.filterName = value;
        return <SelectBox dataSource={filterNames} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      if (columnIndex === 2){
        row.data.filterCondition = '';
        return null;
      }
      if (columnIndex === 3){
        row.data.firstValue = '';
        return null;
      }
      if (columnIndex === 4){
        row.data.secondValue = '';
        return null;
      }
      if (columnIndex === 5){
        row.data.booleanExpression = value;
        return <SelectBox dataSource={booleanExpressionOptions} value={value} onValueChanged={(e) => handleUpdate(e.value, row.data.__KEY__, columnIndex)} />;
      }
      break;
    default:
      return value;
  }
  return null;
}

const FileFilterOptions = (props: IFileFilterOptionsProps) => {
  const { updateFileFilterOptions, fileFilterOptions } = props;
  const [gridData, setGridData] = useState(fileFilterOptions);
  const [selectedRow, setSelectedRow] = useState<any>(newFilter);
  const [key, setKey] = useState(Math.random());
  const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(false);
  const {
    mode, newRowPosition, confirmDelete, useIcons
  } = dataGridConstants.editingOptions;

  const updateFilterSelection = (e: any) => {
    gridData.forEach((_filter: any, index) => {
      gridData[index].isSelected = e.selectedRowKeys.includes(_filter.__KEY__);
    });
    setDeleteButtonEnabled(e.selectedRowKeys.length > 0);
    setGridData(gridData);
    updateFileFilterOptions(gridData);
  }

  const handleDeleteClick = async () => {
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: 'Are you sure you want to delete the selected filter/s?',
    })) {
      const updatedDataSource = gridData.filter(
        (item) => !item.isSelected
      );
      setGridData(updatedDataSource);
      updateFileFilterOptions(updatedDataSource)
      setDeleteButtonEnabled(false);
    }
  };

  const handleUpdate = (updatedRow: any, id: any, columnIndex: any) => {
    const index = gridData.findIndex((row: any) => row.__KEY__ === id);
    if (columnIndex === 1) {
      gridData[index].filterName = updatedRow;
    } else if (columnIndex === 2) {
      gridData[index].filterCondition = updatedRow;
    } else if (columnIndex === 3) {
      gridData[index].firstValue = updatedRow;
    } else if (columnIndex === 4) {
      gridData[index].secondValue = updatedRow;
    } else if (columnIndex === 5) {
      gridData[index].booleanExpression = updatedRow;
    }
    setSelectedRow(gridData[index]);
    setGridData(gridData);
    updateFileFilterOptions(gridData)
  }

  const determineDataType = (row: any) => {
    if (brackets.includes(row.data.filterName)) return 'bracket';
    return test.includes(row.data.filterName) ? 'date' : 'string';
  }

  const handleOptionChanged = (e: any) => {
    if (e.value != null && e.value[0] !== undefined && e.name === 'editing'){
      const newValue = { ...selectedRow, ...(e.value[0]).data }
      gridData[-1] = newValue;
      setSelectedRow(newValue);
      setGridData(gridData);
    }
  };

  const CustomRenderer = (x: any, y: any) => {
    return CustomRendererHelper(x, y, fileFilterOptionsValues, selectedRow, handleUpdate, determineDataType);
  }

  const setDefaultValues = (e: any) => {
    e.data.isSelected = false;
    e.data.filterName = null;
    e.data.filterCondition = null;
    e.data.firstValue = '';
    e.data.secondValue = '';
    e.data.booleanExpression = null;
  }

  const handleRowClick = (e: any) => {
    setSelectedRow(e.data);
  };

  const handleRowUpdateCancel = () => {
    const currRowIndex = gridData.findIndex((x: any) => x.__KEY__ === selectedRow.__KEY__);
    gridData[currRowIndex] = gridData[-1];
    setSelectedRow(gridData[currRowIndex])
    setGridData(gridData)
    updateFileFilterOptions(gridData);
    setKey(Math.random());
  }

  useEffect(() => {
    setDeleteButtonEnabled(gridData.filter((x) => x.isSelected).length > 0)
  }, [])

  return (
    <div className="">
      <DataGrid
        dataSource={gridData}
        key={key}
        selection={{ mode: 'multiple' }}
        keyExpr="__KEY__"
        onOptionChanged={handleOptionChanged}
        onEditingStart={handleRowClick}
        onEditCanceling={handleRowUpdateCancel}
        onInitNewRow={setDefaultValues}
        onRowInserted={() => { updateFileFilterOptions(gridData) }}
        selectedRowKeys={gridData.filter((x) => x.isSelected).map((x: any) => x.__KEY__)}
        onSelectionChanged={updateFilterSelection}
        columnWidth={150}
        sorting={{ mode: 'none' }}
        onToolbarPreparing={(e:any) => {
          e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            disabled: !deleteButtonEnabled,
            options: {
              icon: 'trash',
              onClick: async () => { handleDeleteClick() },
            },
          });
        }}
      >
        <Editing
          mode={mode}
          newRowPosition={newRowPosition}
          confirmDelete={confirmDelete}
          useIcons={useIcons}
          allowAdding
          allowUpdating
        />
        <Column
          dataField="filterName"
          caption="Parameter"
          editCellRender={CustomRenderer}
          visible
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="filterCondition"
          caption="Operator"
          editCellRender={CustomRenderer}
          visible
        >
          {brackets.includes(selectedRow.filterName) ? (
            null
          ) : (
            <RequiredRule />
          )}
        </Column>
        <Column
          dataField="firstValue"
          caption="Value/Date"
          editCellRender={CustomRenderer}
          visible
        >
          {brackets.includes(selectedRow.filterName) ? (
            null
          ) : (
            <RequiredRule />
          )}
        </Column>
        <Column
          dataField="secondValue"
          caption="Date/Inclusion"
          editCellRender={CustomRenderer}
          visible
          // allowEditing={fileFilterWithTwoValuesTable.includes(selectedRow.filterCondition)}
        >
          {fileFilterWithTwoValuesTable.includes(selectedRow.filterCondition) ? (
            <RequiredRule />
          ) : (
            null
          )}
        </Column>
        <Column
          dataField="booleanExpression"
          caption="Boolean Expression"
          editCellRender={CustomRenderer}
          visible
        />
      </DataGrid>
    </div>
  )
}

export default FileFilterOptions;
