import React, { useState } from 'react';
import Plot from 'react-plotly.js';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/wrapped-component/hint-controls/Button';
import Checkbox from '../components/wrapped-component/hint-controls/Checkbox';
import Heading from '../components/wrapped-component/hint-controls/Heading';
import Label from '../components/wrapped-component/hint-controls/Label';
import Textbox from '../components/wrapped-component/hint-controls/Textbox';
import Textarea from '../components/wrapped-component/hint-controls/Textarea';
import Toggle from '../components/wrapped-component/hint-controls/Toggle';

interface IStyleGuideProps {
  className?: string;
}

const StyleGuide = (props:IStyleGuideProps) => {
  const [enableNotifications, toggleEnableNotifications] = useState(false);
  const { className } = props;
  const hint = {
    message: 'This is the hint message. It redirects to google.com',
    href: 'http://google.com',
  };
  return (
    <Container fluid className={`p20 ${className}`} style={{ backgroundColor: '#f0f0f7' }}>
      <Row>
        <Col lg={12}>
          <Plot
            layout={{}}
            data={[
              {
                x: [new Date(2019, 12, 19), new Date(2020, 1, 4)],
                y: [1, 4],
                type: 'scattergl',
                mode: 'lines+markers',
                marker: {
                  line: {
                    width: 1,
                    color: '#404040',
                  },
                },
              },
            ]}
          />
        </Col>
        <Col>
          <Heading size={3}>Typography</Heading>

          <Heading hint={hint} size={1}>Heading Text</Heading>
          <Heading hint={hint} size={2}>Heading Text</Heading>
          <Heading hint={hint} size={3}>Heading Text</Heading>
          <Heading hint={hint} size={4}>Heading Text</Heading>
          <Heading hint={hint} size={5}>Heading Text</Heading>
          <Heading hint={hint} size={6}>Heading Text</Heading>
        </Col>
        <Col>
          <Heading size={3}>Buttons</Heading>
          <Row>
            <Col>
              <Heading size={5}>Solid Small</Heading>

              <Button hint={hint} variant="primary" className="mb10">Click Me</Button>
              <Button hint={hint} variant="warning" className="mb10">Click Me</Button>
              <Button hint={hint} variant="danger" className="mb10">Click Me</Button>
              <Button hint={hint} variant="info" className="mb10">Click Me</Button>
            </Col>
            <Col>
              <Heading size={5}>Outlined Small</Heading>

              <Button hint={hint} variant="outline-primary" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-warning" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-danger" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-info" className="mb10">Click Me</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Heading size={5}>Solid Large</Heading>

              <Button hint={hint} variant="primary" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="warning" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="danger" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="info" size="lg" className="mb10">Click Me</Button>
            </Col>
            <Col>
              <Heading size={5}>Outlined Large</Heading>

              <Button hint={hint} variant="outline-primary" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-warning" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-danger" size="lg" className="mb10">Click Me</Button>
              <Button hint={hint} variant="outline-info" size="lg" className="mb10">Click Me</Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Heading size={5}>Solid Button Full</Heading>

              <Button full hint={hint} variant="outline-primary" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-warning" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-danger" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-info" className="mb10">Click Me</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Heading size={5}>Outlined Button Full</Heading>

              <Button full hint={hint} variant="primary" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="warning" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="danger" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="info" className="mb10">Click Me</Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Heading size={5}>Solid Button Full Large</Heading>

              <Button full hint={hint} variant="outline-primary" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-warning" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-danger" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="outline-info" size="lg" className="mb10">Click Me</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Heading size={5}>Outlined Button Full Large</Heading>

              <Button full hint={hint} variant="primary" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="warning" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="danger" size="lg" className="mb10">Click Me</Button>
              <Button full hint={hint} variant="info" size="lg" className="mb10">Click Me</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <form className="custom-form">
            <Heading size={3}>Forms</Heading>
            <Heading size={6}>Label top</Heading>

            <Label
              labelTitle="Name"
              labelPosition="top"
              labelSize="10"
              fieldSize="10"
              errorSize="10"
              error="This is an error"
              isFieldTouched
            >
              <Textbox
                hint={hint}
                autoComplete="off"
                name="name"
                placeholder="Name"
              />
            </Label>
            <Label
              labelTitle="Description"
              labelPosition="top"
              labelSize="10"
              fieldSize="10"
              errorSize="10"
              error="This is an error"
              isFieldTouched
            >
              <Textarea
                hint={hint}
                autoComplete="off"
                name="description"
                placeholder="Description"
                rows={3}
              />
            </Label>
            <Checkbox hint={hint}>Checkbox Label</Checkbox>
            <Toggle
              hint={hint}
              id="enableNotifications"
              name="enableNotifications"
              title="Enable Notifications"
              checked={enableNotifications}
              onChange={() => {
                toggleEnableNotifications(!enableNotifications);
              }}
            />
          </form>
        </Col>
        <Col>
          <form className="custom-form">
            <Heading size={3}>Forms</Heading>
            <Heading size={6}>Label Left</Heading>

            <Label
              labelTitle="Name"
              labelPosition="left-middle"
              labelSize="20"
              fieldSize="50"
              errorSize="30"
              error="This is an error"
              isFieldTouched
            >
              <Textbox
                hint={hint}
                autoComplete="off"
                name="name"
                placeholder="Name"
              />
            </Label>
            <Label
              labelTitle="Description"
              labelPosition="left-top"
              labelSize="20"
              fieldSize="50"
              errorSize="30"
              error="This is an error"
              isFieldTouched
            >
              <Textarea
                hint={hint}
                autoComplete="off"
                name="description"
                placeholder="Description"
                rows={3}
              />
            </Label>
            <Label
              labelTitle="Notifications"
              labelPosition="left-middle"
              labelSize="20"
              fieldSize="30"
              errorSize="20"
              error="This is an error"
              isFieldTouched
            >
              <Toggle
                hint={hint}
                id="enableNotifications"
                name="enableNotifications"
                title="Enable Notifications"
                checked={enableNotifications}
                onChange={() => {
                  toggleEnableNotifications(!enableNotifications);
                }}
              />
            </Label>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

StyleGuide.defaultProps = {
  className: '',
};

export default StyleGuide;
