import React from 'react';
import {
  Container, Row, Col, Modal, Spinner,
} from 'react-bootstrap';

interface WidgetPopupProps {
  show?: boolean;
  showSpinner?: boolean;
  primaryWidget?: JSX.Element;
  hidePrimaryWidgetOnExpand? :boolean;
  hideSecondaryWidgetOnExpand? :boolean;
  secondaryWidget?: JSX.Element;
  tertiaryWidget?: JSX.Element;
  primaryWidgetWeight?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  secondaryWidgetWeight?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  hideWidgetPopup?: () => void;
  widgetName?: string;
  changeModalClass?: boolean;
}

const WidgetPopup = (props:WidgetPopupProps) => {
  const {
    show,
    hideWidgetPopup,
    widgetName,
    showSpinner,
    changeModalClass,
  } = props;

  const primaryWidgetRenderer = () => {
    const {
      primaryWidget,
      primaryWidgetWeight,
      hidePrimaryWidgetOnExpand,
      changeModalClass,
    } = props;

    if (hidePrimaryWidgetOnExpand) return null;
    if (primaryWidget && !showSpinner) {
      return (
        <Col className="h-100 w-100" lg={primaryWidgetWeight}>
          {primaryWidget}
        </Col>
      );
    }
    if (primaryWidget && showSpinner){
      return (
        <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: '700px' }}>
          <Spinner animation="border" />
        </div>
      )
    }
    return null;
  };

  const secondaryWidgetRenderer = () => {
    const {
      secondaryWidget,
      secondaryWidgetWeight,
      hideSecondaryWidgetOnExpand,
    } = props;

    if (hideSecondaryWidgetOnExpand) return null;
    return secondaryWidget ? (
      <Col className="h-100 w-100" lg={secondaryWidgetWeight}>
        {secondaryWidget}
      </Col>
    ) : null;
  };

  const tertiaryWidgetRenderer = () => {
    const {
      tertiaryWidget,
    } = props;

    return tertiaryWidget ? (
      <Row>
        <Col lg={12} className="h-100 w-100">
          {tertiaryWidget}
        </Col>
      </Row>
    ) : null;
  };
  return (
    <Row>
      <Col>
        <Modal
          show={show}
          enforceFocus={false}
          onHide={hideWidgetPopup}
          dialogClassName={changeModalClass ? 'modal-90w' : 'modal-90w-of-screen'}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton className="border-none">
            <Modal.Title className="text-center w-100">
              <p className="text-center">{widgetName}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ alignItems: 'start' }}>
            <Container fluid>
              <Row>
                {primaryWidgetRenderer()}
                {secondaryWidgetRenderer()}
              </Row>
              {tertiaryWidgetRenderer()}
            </Container>
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
};

WidgetPopup.defaultProps = {
  show: false,
  primaryWidget: undefined,
  secondaryWidget: undefined,
  tertiaryWidget: undefined,
  primaryWidgetWeight: 6,
  secondaryWidgetWeight: 6,
  hidePrimaryWidgetOnExpand: false,
  hideSecondaryWidgetOnExpand: false,
  hideWidgetPopup: () => undefined,
  widgetName: '',
};

export default WidgetPopup;
