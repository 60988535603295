import React from 'react';
import DataGrid, {
  FilterRow, Paging, Scrolling, Selection,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { TextBox } from 'devextreme-react';
import getDropDownOptions from 'components/getDropDownOptions';
import { DataTypes } from './device-setup';

export default class ExpressionValueComponent extends React.Component<any, any> {
  dropDownBoxRef: React.RefObject<DropDownBox>;

  constructor(props: any) {
    super(props);
    this.state = {
      currentValue: props.data.value,
    };
    this.dropDownBoxRef = React.createRef();
  }

  onSelectionChanged = (selectionChangedArgs: any) => {
    const { data } = this.props;
    data.setValue(selectionChangedArgs.selectedRowKeys);
    this.setState({ currentValue: selectionChangedArgs.selectedRowKeys });
  };

  IsObject = (data: any) => {
    return typeof data === 'object' && data !== null;
  };

  contentRender = (dataSource: any) => {
    const { currentValue } = this.state;
    return (
      <DataGrid
        dataSource={dataSource}
        remoteOperations
        height={350}
        columnWidth={100}
        keyExpr="id"
        selectedRowKeys={currentValue}
        hoverStateEnabled
        onSelectionChanged={this.onSelectionChanged}
      >
        <Paging enabled defaultPageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="multiple" />
        <FilterRow visible />
      </DataGrid>
    );
  };

  render() {
    const { data, binPlusTables, waferControlMaps } = this.props;
    const { currentValue } = this.state;
    let dataSource: any = [];
    if (data.data.dataType === DataTypes.BIN_PLUS_TABLE) dataSource = binPlusTables;
    else if (data.data.dataType === DataTypes.WAFER_CONTROL_MAP) dataSource = waferControlMaps;
    if ((binPlusTables && data.data.dataType === DataTypes.BIN_PLUS_TABLE) || (waferControlMaps && data.data.dataType === DataTypes.WAFER_CONTROL_MAP)) {
      return (
        <DropDownBox
          placeholder="Select..."
          ref={this.dropDownBoxRef}
          dropDownOptions={getDropDownOptions('cell', data, [{ width: '30%' }])}
          dataSource={dataSource}
          value={currentValue}
          displayValueFormatter={(value: any) => {
            return value.map((val: any) => {
              if (dataSource === null || dataSource === undefined) return '';
              return dataSource.filter((d: any) => d.id === val)[0]?.name;
            }).join(', ');
          }}
          contentRender={() => { return this.contentRender(dataSource); }}
        />
      );
    }
    return (
      <TextBox
        placeholder="Enter Value..."
        value={currentValue}
        onValueChanged={(event: any) => {
          data.setValue(event.value);
          this.setState({ currentValue: event.value });
        }}
      />
    );
  }
}
