/* eslint-disable no-unused-vars */
import { IReportHeaderSetting } from 'components/utility-component/report-headers/ReportHeaderBuilder';
import { IGenericDetailedReportRequestObject } from 'interfaces';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpReportHeader extends HttpBase {
  getAll = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/headersettings/all`);
  };

  getDefaultReportHeader = () : Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/headersettings/get-default-report-header`);
  };

  save = (reportHeaderSettings:IReportHeaderSetting): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/headersettings`, reportHeaderSettings);
  };

  update = (reportHeaderSettings:IReportHeaderSetting): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/headersettings`, reportHeaderSettings);
  };

  apply = (requestObject:IGenericDetailedReportRequestObject): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/headersettings/apply`, requestObject);
  };
}

const httpReportHeader = new HttpReportHeader();
export { httpReportHeader };
