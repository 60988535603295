// eslint-disable-next-line no-unused-vars
import { PlotType } from 'plotly.js';
import React from 'react';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import PublishSubscribe, { EventTypes } from '../wafer-map-widget/PublishSubscribe';
import CustomPlotly from './CustomPlotly';
import colors from '../../../assets/colors.json';

type PlotlyGraphProps = {
  dataSource: { x: any, y: any }[],
  xAxisDataFields: string[],
  setPlotInstanceHandler?: (plot: PlotlyGraph, keyIndex: string) => void,
  isMultiCategory?: boolean,
  title?: string,
  enableYAxisBreaks?: boolean,
  hideOptions?: boolean,
  hideLegend?: boolean,
  mode:
  | 'lines'
  | 'markers'
  | 'text'
  | 'lines+markers'
  | 'text+markers'
  | 'text+lines'
  | 'text+lines+markers'
  | 'none'
  | 'gauge'
  | 'number'
  | 'delta'
  | 'number+delta'
  | 'gauge+number'
  | 'gauge+number+delta'
  | 'gauge+delta',
  type: PlotType,
  id: string,
  layout?:any,
  toggleEditor?:any,
};

type PlotlyGraphState = {
  cachedDataSource: { x: any, y: any }[],
  selectionColor: string,
  unselectionColor: string,
  contextPoint: any,
  mData: any[],
  singleSelection: boolean,
};

export default class PlotlyGraph extends React.Component<PlotlyGraphProps, PlotlyGraphState> {
  static defaultProps = {
    enableYAxisBreaks: false,
    hideOptions: false,
    hideLegend: false,
    disableXAxisBreaks: false,
    isMultiCategory: false,
  };

  static getColor = (xVal: string) => {
    let hash = 0;
    for (let i = 0; i < xVal.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hash = xVal.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[hash % colors.length];
  };

  constructor(props: PlotlyGraphProps) {
    super(props);
    this.state = {
      mData: [],
      selectionColor: '#00FF00',
      unselectionColor: '#FF0000',
      cachedDataSource: [],
      contextPoint: null,
      singleSelection: false,
    };
  }

  componentDidMount() {
    const { setPlotInstanceHandler, id } = this.props;
    if (setPlotInstanceHandler) {
      setPlotInstanceHandler!(this, id);
    }
  }

  static getFlatData(dataSource: { x: any, y: any }[], xAxisDataFields: string[], isMultiCategory: boolean, unSelectionColor: string) {
    const mData: any = [];
    dataSource.forEach((curveData: any, curveIndex: number) => {
      for (let i = 0; i < curveData.y.length; i += 1) {
        const dataObj: any = {};
        dataObj.filtered = true;
        dataObj.hidden = false;
        dataObj.selected = false;
        dataObj.curveNumber = curveIndex;
        dataObj.y = curveData.y[i];
        dataObj.x = {};
        for (let j = 0; j < xAxisDataFields.length; j += 1) {
          dataObj.x[xAxisDataFields[j]] = isMultiCategory ? curveData.x[j][i] : curveData.x[i];
        }
        dataObj.color = isMultiCategory ? PlotlyGraph.getColor(dataObj.x[xAxisDataFields[xAxisDataFields.length - 1]].toString()) : unSelectionColor;
        dataObj.orignalColor = dataObj.color;
        mData.push(dataObj);
      }
    });
    return mData;
  }

  static getDerivedStateFromProps(props: PlotlyGraphProps, state: PlotlyGraphState) {
    if (!_.isEqual(state.cachedDataSource, props.dataSource)) {
      const mData = PlotlyGraph.getFlatData(props.dataSource, props.xAxisDataFields, props.isMultiCategory || false, state.unselectionColor);
      return {
        mData,
        cachedDataSource: _.cloneDeep(props.dataSource),
      };
    }
    return null;
  }

  onToggleSelectionModeHandler = () => {
    this.setState((prevState) => ({ singleSelection: !prevState.singleSelection }));
  };

  getIndex = (xDataEntry: any[]) => {
    const { mData } = this.state;
    const { xAxisDataFields } = this.props;
    for (let i = 0; i < mData.length; i += 1) {
      let flag = true;
      for (let j = 0; j < xAxisDataFields.length; j += 1) {
        if (mData[i].x[xAxisDataFields[j]] !== xDataEntry[j]) {
          flag = false;
        }
      }
      if (flag) return i;
    }
    return -1;
  };

  singlePointGraphSelection = (event: any) => {
    if (!event) return;
    if (event.event.which === 3) {
      // right mouse clicked
      this.setState({ contextPoint: event.points[0] });
      return;
    }
    const {
      mData, selectionColor, singleSelection,
    } = this.state;
    const {
      id, isMultiCategory, xAxisDataFields,
    } = this.props;
    const point = event.points[0];
    const newMDataSource = _.cloneDeep(mData);
    const ps = PublishSubscribe();
    if (!singleSelection) {
      const index = isMultiCategory ? this.getIndex(point.x) : this.getIndex([point.x]);
      if (index === -1) return;
      const selectionData: any[] = [];
      const selectionDataObj: any = {};
      for (let i = 0; i < xAxisDataFields.length; i += 1) {
        if (xAxisDataFields[i]) {
          selectionDataObj[xAxisDataFields[i]] = Array.isArray(point.x) ? point.x[i] : point.x;
        }
      }
      selectionData.push(selectionDataObj);
      if (point['marker.color'] === mData[index].orignalColor) {
        newMDataSource[index].selected = true;
        newMDataSource[index].color = selectionColor;
        ps.publishWithPlotID(EventTypes.DATA_SELECTED_ON_PLOT, { selectedData: selectionData }, id);
      } else if (point['marker.color'] === selectionColor) {
        newMDataSource[index].selected = false;
        newMDataSource[index].color = newMDataSource[index].orignalColor;
        ps.publishWithPlotID(EventTypes.DATA_UNSELECTED_ON_PLOT, { unSelectedData: selectionData }, id);
      }
    } else {
      const xDataEntry = isMultiCategory ? point.x : [point.x];
      const selectionData: any[] = [];
      const unSelectionData: any[] = [];
      for (let i = 0; i < mData.length; i += 1) {
        let flag = true;
        const selectionDataObj: any = {};
        for (let j = 0; j < xAxisDataFields.length; j += 1) {
          if (mData[i].x[xAxisDataFields[j]] !== xDataEntry[j]) {
            flag = false;
          }
          selectionDataObj[xAxisDataFields[j]] = mData[i].x[xAxisDataFields[j]];
        }
        if (flag && !newMDataSource[i].selected) {
          selectionData.push(selectionDataObj);
          newMDataSource[i].selected = true;
          newMDataSource[i].color = selectionColor;
        } else {
          unSelectionData.push(selectionDataObj);
          newMDataSource[i].selected = false;
          newMDataSource[i].color = newMDataSource[i].orignalColor;
        }
      }
      ps.publishWithPlotID(EventTypes.DATA_SELECTED_ON_PLOT, { selectedData: selectionData, currentDeselectedRowKeys: unSelectionData }, id);
    }
    this.setState({
      mData: newMDataSource,
      contextPoint: event.points[0],
    });
  };

  graphSelection = (event: any) => {
    // console.log('POINT SELECTTTIONN', event);
    if (!event) return;
    const { selectionColor, mData } = this.state;
    const { id, isMultiCategory, xAxisDataFields } = this.props;
    if (!xAxisDataFields) return;
    const selectedData: any[] = [];
    const newMDataSource = _.cloneDeep(mData);
    event.points.forEach((point: any) => {
      const index = isMultiCategory ? this.getIndex(point.x) : this.getIndex([point.x]);
      if (point['marker.color'] === mData[index].orignalColor) {
        const selectedDataObj: any = {};
        for (let i = 0; i < xAxisDataFields.length; i += 1) {
          if (xAxisDataFields[i]) {
            selectedDataObj[xAxisDataFields[i]] = Array.isArray(point.x) ? point.x[i] : point.x;
          }
        }
        selectedData.push(selectedDataObj);
        newMDataSource[index].selected = true;
        newMDataSource[index].color = selectionColor;
      }
    });
    const ps = PublishSubscribe();
    ps.publishWithPlotID(EventTypes.DATA_SELECTED_ON_PLOT, { selectedData }, id);
    this.setState({
      mData: newMDataSource,
    });
  };

  deleteData = (mData: any[], xDataEntry: any[], checkAxes: boolean[]) => {
    const { xAxisDataFields } = this.props;
    const newMDataSource = [];
    const deletedData = [];
    for (let i = 0; i < mData.length; i += 1) {
      let isMatched = true;
      for (let j = 0; j < xAxisDataFields.length; j += 1) {
        if ((mData[i].x[xAxisDataFields[j]] !== xDataEntry[j] && checkAxes[j])) {
          isMatched = false;
        }
      }
      if (!isMatched) newMDataSource.push({ ...mData[i] });
      else {
        deletedData.push({ ...mData[i].x });
      }
    }
    return {
      newMDataSource,
      deletedData,
    };
  };

  onToggleShowItems = (mData: any[], contextPoint: any, toggleSimilar: boolean, toggleSelected: boolean) => {
    const { xAxisDataFields, isMultiCategory } = this.props;
    if (!isMultiCategory && toggleSimilar) return null;
    const newMDataSource = [];
    const hiddenData: any[] = [];
    const xAxisDataFieldsNumber = xAxisDataFields.length;
    const similarIndices = [];
    const selectedSimilar: any = [];
    if (!toggleSelected && !contextPoint) return null;
    let xDataEntry = [];
    if (contextPoint !== null && contextPoint.x !== undefined && contextPoint.x !== null) {
      xDataEntry = isMultiCategory ? contextPoint.x : [contextPoint.x];
    }
    for (let i = 0; i < mData.length; i += 1) {
      newMDataSource[i] = _.cloneDeep(mData[i]);
      if ((toggleSelected && toggleSimilar) && mData[i].selected) {
        selectedSimilar.push(mData[i].x[xAxisDataFields[xAxisDataFieldsNumber - 1]]);
      }
      if (!toggleSelected) {
        let flag = true;
        for (let j = 0; j < xAxisDataFieldsNumber; j += 1) {
          if ((!toggleSimilar || (j === xAxisDataFieldsNumber - 1))
          && mData[i].x[xAxisDataFields[j]] !== xDataEntry[j]) {
            flag = false;
          }
        }
        if (flag) similarIndices.push(i);
      }
    }
    for (let i = 0; toggleSelected && toggleSimilar && i < mData.length; i += 1) {
      if (selectedSimilar.indexOf(mData[i].x[xAxisDataFields[xAxisDataFieldsNumber - 1]]) !== -1) {
        similarIndices.push(i);
      }
    }
    for (let i = 0; i < newMDataSource.length; i += 1) {
      if ((toggleSelected && !toggleSimilar && newMDataSource[i].selected) || (!(toggleSelected && !toggleSimilar) && similarIndices.indexOf(i) !== -1)) {
        newMDataSource[i].hidden = true;
        newMDataSource[i].selected = false;
        newMDataSource[i].color = newMDataSource[i].orignalColor;
        hiddenData.push({ ...newMDataSource[i].x });
      }
    }
    return {
      hiddenData,
      newMDataSource,
    };
  };

  onContextMenuItemClick = (event: any) => {
    event.component.hide();
    const { mData, contextPoint } = this.state;
    if (event.itemData.text === 'Delete Current Item' || event.itemData.text === 'Delete Similar Items') {
      const { isMultiCategory } = this.props;
      if (!contextPoint) return;
      if (contextPoint.x === undefined || contextPoint.x === null) return;
      let newData = null;
      if (event.itemData.text === 'Delete Current Item') {
        newData = isMultiCategory ? this.deleteData(mData, contextPoint.x, [true, true]) : this.deleteData(mData, [contextPoint.x], [true, true]);
      } else if (event.itemData.text === 'Delete Similar Items') {
        newData = isMultiCategory ? this.deleteData(mData, contextPoint.x, [false, true]) : this.deleteData(mData, [contextPoint.x], [false, true]);
      }
      if (newData) {
        const { id } = this.props;
        const ps = PublishSubscribe();
        ps.publishWithPlotID(EventTypes.DATA_DELETED_ON_PLOT, { deletedData: newData.deletedData }, id);
        this.setState({
          contextPoint: null,
          mData: newData.newMDataSource,
        });
      }
    } else if (event.itemData.text === 'Hide Current Item') {
      const newData = this.onToggleShowItems(mData, contextPoint, false, false);
      if (newData) {
        const { id } = this.props;
        const ps = PublishSubscribe();
        ps.publishWithPlotID(EventTypes.DATA_HIDDEN_ON_PLOT, { hiddenData: newData.hiddenData }, id);
        this.setState({
          contextPoint: null,
          mData: newData.newMDataSource,
        });
      }
    } else if (event.itemData.text === 'Hide Selected Items') {
      const newData = this.onToggleShowItems(mData, contextPoint, false, true);
      if (newData) {
        const { id } = this.props;
        const ps = PublishSubscribe();
        ps.publishWithPlotID(EventTypes.DATA_HIDDEN_ON_PLOT, { hiddenData: newData.hiddenData }, id);
        this.setState({
          contextPoint: null,
          mData: newData.newMDataSource,
        });
      }
    } else if (event.itemData.text === 'Hide Similar Selected Items') {
      const newData = this.onToggleShowItems(mData, contextPoint, true, true);
      if (newData) {
        const { id } = this.props;
        const ps = PublishSubscribe();
        ps.publishWithPlotID(EventTypes.DATA_HIDDEN_ON_PLOT, { hiddenData: newData.hiddenData }, id);
        this.setState({
          contextPoint: null,
          mData: newData.newMDataSource,
        });
      }
    } else if (event.itemData.text === 'Hide Similar Items') {
      const newData = this.onToggleShowItems(mData, contextPoint, true, false);
      if (newData) {
        const { id } = this.props;
        const ps = PublishSubscribe();
        ps.publishWithPlotID(EventTypes.DATA_HIDDEN_ON_PLOT, { hiddenData: newData.hiddenData }, id);
        this.setState({
          contextPoint: null,
          mData: newData.newMDataSource,
        });
      }
    } else if (event.itemData.text === 'Show All Items') {
      const newMDataSource = _.cloneDeep(mData);
      const hiddenData: any[] = [];
      for (let i = 0; i < newMDataSource.length; i += 1) {
        if (newMDataSource[i].hidden) {
          newMDataSource[i].hidden = false;
          hiddenData.push({ ...newMDataSource[i].x });
        }
      }
      const { id } = this.props;
      const ps = PublishSubscribe();
      ps.publishWithPlotID(EventTypes.DATA_UNHIDDEN_ON_PLOT, { hiddenData }, id);
      this.setState({
        contextPoint: null,
        mData: newMDataSource,
      });
    }
  };

  dataDeletedOnCustomGrid = (data: { deletedData: any[], subscriberKey: string, publisherKey: string }) => {
    if (data.deletedData.length <= 0) return;
    const pubKeys: string [] = this.getKeysArray(data.publisherKey);
    let newData = null;
    const { mData } = this.state;
    if (data.deletedData.length === 1) {
      newData = this.deleteData(mData, pubKeys.map((pubKey) => data.deletedData[0][pubKey]), [true, true]);
    } else {
      newData = this.deleteData(mData, pubKeys.map((pubKey) => data.deletedData[0][pubKey]), [false, true]);
    }
    if (newData) {
      this.setState({
        contextPoint: null,
        mData: newData.newMDataSource,
      });
    }
  };

  dataHiddenOnCustomGrid = (data: { hiddenData: any[], subscriberKey: string, publisherKey: string }) => {
    if (data.hiddenData.length <= 0) return;
    const pubKeys: string [] = this.getKeysArray(data.publisherKey);
    const subKeys = this.getKeysArray(data.subscriberKey);
    const { mData } = this.state;
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.hiddenData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.hiddenData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.hiddenData[j][pubKeys[index]], true)) {
          newMDataSource[i].hidden = true;
          newMDataSource[i].selected = false;
          newMDataSource[i].color = newMDataSource[i].orignalColor;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  dataUnHiddenOnCustomGrid = (data: { hiddenData: any[], subscriberKey: string, publisherKey: string }) => {
    if (data.hiddenData.length <= 0) return;
    const pubKeys: string [] = this.getKeysArray(data.publisherKey);
    const subKeys = this.getKeysArray(data.subscriberKey);
    const { mData } = this.state;
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.hiddenData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.hiddenData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.hiddenData[j][pubKeys[index]], true)) {
          newMDataSource[i].hidden = false;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  // eslint-disable-next-line no-unused-vars
  onContextMenuHidden = () => undefined; //  previously, this was: (event: any) => undefined

  // eslint-disable-next-line no-unused-vars
  onContextMenuShowing = () => undefined; //  previously, this was: (event: any) => undefined

  getFlatIndex = (curveNumber: number, pointNumber: number, dataSource: { x: any, y: any }[]) => {
    let index = 0;
    for (let i = 0; i < curveNumber; i += 1) {
      index += dataSource[i].y.length;
    }
    index += pointNumber;
    return index;
  };

  dataSelectedOnCustomGrid = (data: { selectedData: any[],
    currentDeselectedRowKeys: any[], subscriberKey: string, publisherKey: string, isAggregated?: boolean }) => {
    const {
      mData, selectionColor,
    } = this.state;
    const subKeys = this.getKeysArray(data.subscriberKey);
    const pubKeys = this.getKeysArray(data.publisherKey);
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.selectedData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.selectedData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => {
            if (data.isAggregated !== undefined && data.isAggregated && index < subKeys.length - 1) {
              return true;
            }
            return accuVal && newMDataSource[i].x[subKeys[index]] === data.selectedData[j][pubKeys[index]];
          }, true)) {
          newMDataSource[i].color = selectionColor;
          newMDataSource[i].selected = true;
        }
      }
      for (let k = 0; k < data.currentDeselectedRowKeys.length; k += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.currentDeselectedRowKeys[k], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => {
            if (data.isAggregated !== undefined && data.isAggregated && index < subKeys.length - 1) {
              return true;
            }
            return accuVal && newMDataSource[i].x[subKeys[index]] === data.currentDeselectedRowKeys[k][pubKeys[index]];
          }, true)) {
          newMDataSource[i].color = newMDataSource[i].orignalColor;
          newMDataSource[i].selected = false;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  getKeysArray = (key: string | string []) => {
    let keys: string [] = [];
    if (!Array.isArray(key)) {
      keys = [key];
    } else {
      keys = [...key];
    }
    return keys;
  };

  dataSelectedOnWafer = (data: { selectedData: any[],
    currentDeselectedRowKeys: any[], subscriberKey: string, publisherKey: string }) => {
    const {
      mData, selectionColor,
    } = this.state;
    const subKeys = this.getKeysArray(data.subscriberKey);
    const pubKeys = this.getKeysArray(data.publisherKey);
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.selectedData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.selectedData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.selectedData[j][pubKeys[index]], true)) {
          newMDataSource[i].color = selectionColor;
          newMDataSource[i].selected = true;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  dataSelectedOnPlot = (data: { selectedData: any[],
    currentDeselectedRowKeys: any[], subscriberKey: string, publisherKey: string }) => {
    const { mData, selectionColor } = this.state;
    const subKeys = this.getKeysArray(data.subscriberKey);
    const pubKeys = this.getKeysArray(data.publisherKey);
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.selectedData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.selectedData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.selectedData[j][pubKeys[index]], true)) {
          newMDataSource[i].color = selectionColor;
          newMDataSource[i].selected = true;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  dataUnSelectedOnPlot = (data: { unSelectedData: any[], subscriberKey: string, publisherKey: string }) => {
    const {
      mData,
    } = this.state;
    const subKeys = this.getKeysArray(data.subscriberKey);
    const pubKeys = this.getKeysArray(data.publisherKey);
    const newMDataSource = _.cloneDeep(mData);
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.unSelectedData.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.unSelectedData[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)
          && subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.unSelectedData[j][pubKeys[index]], true)) {
          newMDataSource[i].color = newMDataSource[i].orignalColor;
          newMDataSource[i].selected = false;
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  setFilterDataFromCustomGrid = (data: { list: any[], subscriberKey: string, publisherKey: string }) => {
    const { mData } = this.state;
    const subKeys = this.getKeysArray(data.subscriberKey);
    const pubKeys = this.getKeysArray(data.publisherKey);
    const newMDataSource = mData.map((mDataEntry: any) => ({ ...mDataEntry, filtered: false }));
    for (let i = 0; i < newMDataSource.length; i += 1) {
      for (let j = 0; j < data.list.length; j += 1) {
        if (pubKeys.reduce((accuVal, currVal) => accuVal && currVal in data.list[j], true)
          && subKeys.reduce((accuVal, currVal) => accuVal && currVal in newMDataSource[i].x, true)) {
          if (subKeys.reduce((accuVal, currentvalue, index) => accuVal && newMDataSource[i].x[subKeys[index]] === data.list[j][pubKeys[index]], true)) {
            newMDataSource[i].filtered = true;
          }
        }
      }
    }
    this.setState({
      mData: newMDataSource,
    });
  };

  clearFilterData = () => {
    this.setState((prevState) => {
      return {
        mData: prevState.mData.map((mDataEntry: any) => ({ ...mDataEntry, filtered: true })),
      };
    });
  };

  getDataForPlolty = () => {
    const { mData } = this.state;
    const { xAxisDataFields, isMultiCategory } = this.props;
    const dataToPlot: any = [];
    const colorList: string[] = [];
    for (let i = 0; i < mData.length; i += 1) {
      if (mData[i].filtered && !mData[i].hidden) {
        const { curveNumber } = mData[i];
        colorList.push(mData[i].color);
        if (dataToPlot[curveNumber] === null || dataToPlot[curveNumber] === undefined) dataToPlot.push({ x: [], y: [] });
        dataToPlot[curveNumber].y.push(mData[i].y);
        for (let k = 0; k < xAxisDataFields.length; k += 1) {
          if (isMultiCategory) {
            if (dataToPlot[curveNumber].x[k] === undefined || dataToPlot[curveNumber].x[k] === null) {
              dataToPlot[curveNumber].x.push([]);
            }
            dataToPlot[curveNumber].x[k].push(mData[i].x[xAxisDataFields[k]]);
          } else dataToPlot[curveNumber].x.push(mData[i].x[xAxisDataFields[k]]);
        }
      }
    }
    return {
      dataToPlot,
      colorList,
    };
  };

  render() {
    const {
      enableYAxisBreaks, hideLegend, hideOptions, title, isMultiCategory, id, type, mode,
    } = this.props;
    const { singleSelection, mData } = this.state;
    const dataForPlotly = this.getDataForPlolty();
    return (
      <CustomPlotly
        id={id}
        layout={this.props.layout}
        selectionModeInfo={{
          onToggleSelectionModeHandler: this.onToggleSelectionModeHandler,
          singleSelection,
        }}
        toggleEditor={this.props.toggleEditor}
        contextMenuDataSource={[
          {
            text: 'Show',
            items: [
              {
                text: 'Show All Items',
                closeMenuOnClick: true,
              },
            ],
          },
          {
            text: 'Hide',
            items: [
              {
                text: 'Hide Current Item',
                closeMenuOnClick: true,
              },
              {
                text: 'Hide Selected Items',
                closeMenuOnClick: true,
                visible: mData.some((data: any) => data.selected),
              },
              {
                text: 'Hide Similar Items',
                closeMenuOnClick: true,
                visible: isMultiCategory,
              },
              {
                text: 'Hide Similar Selected Items',
                closeMenuOnClick: true,
                visible: isMultiCategory && mData.some((data: any) => data.selected),
              },
            ],
          },
        ]}
        onContextMenuHidden={this.onContextMenuHidden}
        onContextMenuShowing={this.onContextMenuShowing}
        onContextMenuItemClick={this.onContextMenuItemClick}
        graphSelection={this.graphSelection}
        singlePointGraphSelection={this.singlePointGraphSelection}
        dataSource={this.props.dataSource}
        // {dataForPlotly.dataToPlot}
        isMultiCategory={isMultiCategory}
        enableYAxisBreaks={enableYAxisBreaks}
        hideLegend={hideLegend}
        hideOptions={hideOptions}
        colorList={dataForPlotly.colorList}
        title={title}
        type={type}
        mode={mode}

      />
    );
  }
}
