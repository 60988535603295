import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type ModalPopupProps = {
  header?: JSX.Element | string,
  body?: JSX.Element | string,
  size?: 'sm' | 'lg' | 'xl' | undefined,
  showYesButton?: boolean | undefined,
  yesButtonText?: string | undefined,
  showNoButton?: boolean | undefined,
  noButtonText?: string | undefined,
};

type ModalPopupState = {
  show: boolean,
  header: JSX.Element | undefined | string,
  body: JSX.Element | undefined | string,
  size: 'sm' | 'lg' | 'xl' | undefined,
  showYesButton?: boolean | undefined,
  yesButtonText?: string | undefined,
  showNoButton?: boolean | undefined,
  noButtonText?: string | undefined,
};

let instance: ModalPopup;

export default class ModalPopup extends React.Component<ModalPopupProps, ModalPopupState> {
  public static async confirm(props: ModalPopupProps) {
    if (!instance) return true;
    return instance.showModal(props);
  }

  yesButton: any = null;

  noButton: any = null;

  constructor(props: ModalPopupProps) {
    super(props);
    this.state = {
      show: false,
      header: undefined,
      body: undefined,
      size: undefined,
      showYesButton: true,
      showNoButton: true,
      yesButtonText: 'Yes',
      noButtonText: 'No',
    };
    instance = this;
  }

  showModal = (props: ModalPopupProps) => {
    return new Promise((resolveOuter: any) => {
      this.setState((prevState:ModalPopupState) => {
        return {
          show: true,
          size: props.size,
          body: props.body,
          header: props.header,
          showYesButton: props.showYesButton !== undefined ? props.showYesButton : prevState.showYesButton,
          yesButtonText: props.yesButtonText !== undefined ? props.yesButtonText : prevState.yesButtonText,
          showNoButton: props.showNoButton !== undefined ? props.showNoButton : prevState.showNoButton,
          noButtonText: props.noButtonText !== undefined ? props.noButtonText : prevState.noButtonText,
        };
      }, () => {
        const p2 = new Promise((resolveInner: any) => {
          if (this.yesButton) {
            this.yesButton.addEventListener(
              'click',
              () => {
                this.setState({
                  show: false,
                }, () => {
                  resolveInner(true);
                });
              },
              { once: true },
            );
          } else {
            resolveInner(true);
          }
          if (this.noButton) {
            this.noButton.addEventListener(
              'click',
              () => {
                this.setState({
                  show: false,
                }, () => {
                  resolveInner(false);
                });
              },
              { once: true },
            );
          } else {
            resolveInner(true);
          }
        });
        resolveOuter(p2);
      });
    });
  };

  render() {
    const {
      show,
      size,
      body,
      header,
      showYesButton,
      showNoButton,
      yesButtonText,
      noButtonText,
    } = this.state;
    return (
      <Modal
        show={show}
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="global-alert-modal"
        backdropClassName="global-alert-modal"
      >
        <Modal.Header style={{ borderBottomStyle: 'none', padding: '0.5rem' }}>
          {header !== undefined && (
            <Modal.Title id="contained-modal-title-vcenter" className="ml5">
              {header}
            </Modal.Title>
          )}
        </Modal.Header>

        {body !== undefined && (
          <Modal.Body className="mt10">
            {body}
          </Modal.Body>
        )}
        <Modal.Footer style={{ borderTopStyle: 'none', padding: '0.5rem' }}>
          <Button hidden={!showYesButton} variant="success" ref={(btn: any) => { this.yesButton = btn; }}>
            {yesButtonText}
          </Button>
          <Button hidden={!showNoButton} variant="danger" ref={(btn: any) => { this.noButton = btn; }}>
            {noButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
