/* eslint-disable no-param-reassign */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
// eslint-disable-next-line no-unused-vars
import WaferUtils from '../Utils/WaferUtils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import { InteractionType } from './Events';

export const zoomMouseMove = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }): void => {
  if (data && data.interactionType !== InteractionType.Zoom) return;
  if (!isMouseDown || !waferUtil.waferMapVariables.isBoxZoomControlActive) return;
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;

  const {
    startRow, startCol, rowDirection, colDirection, waferData,
  } = waferUtil.waferMapVariables;

  const arrayIndices = waferUtil.getBoxArrayIndicesFromCanvasCoords({
    startX, startY, endX, endY,
  });

  UtilityFunctions.markDieUnderSelection(arrayIndices, true, startRow, startCol, rowDirection, colDirection, waferData);
  waferUtil.renderWafer();
  UtilityFunctions.markDieUnderSelection(arrayIndices, false, startRow, startCol, rowDirection, colDirection, waferData);
};

export const zoomOnMouseUp = (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils): void => {
  if (waferUtil.waferMapVariables.pageNumber !== undefined
    && waferUtil.waferMapVariables.plotterPageNumber !== undefined
    && waferUtil.waferMapVariables.pageNumber !== waferUtil.waferMapVariables.plotterPageNumber) return;
  const { isBoxZoomControlActive, innerViewPort, keyIndex } = waferUtil.waferMapVariables;
  if (isBoxZoomControlActive && startX !== endX && startY !== endY) {
    waferUtil.zoomWaferMapUsingBoxSelection({
      startX, startY, endX, endY,
    });
    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_ZOOMED, {
      startX: startX / innerViewPort.width,
      startY: startY / innerViewPort.height,
      endX: endX / innerViewPort.width,
      endY: endY / innerViewPort.height,
      isFromPubSub: true,
    }, keyIndex);
  }
};
