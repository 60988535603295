import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import _ from 'lodash';
import { IUserDetails } from '../interfaces';
import GeneralUtils from '../GeneralUtils';

type REACT_GA_EVENT_LABEL = 'SCW' | 'REPORTING' | 'SPC';

const initializeUserStats = () => {
  const gaTrackingId = process.env.REACT_APP_GA;
  const hotjarId = _.toNumber(process.env.REACT_APP_HOTJAR_ID);
  const hotjarVersion = _.toNumber(process.env.REACT_APP_HOTJAR_VERSION);

  if (process.env.REACT_APP_ENV === 'app') {
    const userDetailsFromCookie = GeneralUtils.getCookieValue('USER_DETAILS');
    if (userDetailsFromCookie) {
      const userDetails:IUserDetails = JSON.parse(userDetailsFromCookie);
      ReactGA.initialize(`${gaTrackingId}`, {
        useExistingGa: false,
        gaOptions: {
          clientId: userDetails.tenantId,
          userId: userDetails.userId,
        },
      });
      hotjar.initialize(hotjarId, hotjarVersion);
      hotjar.identify('USER_ID', { userProperty: userDetails.userId });
      hotjar.identify('CLIENT_ID', { userProperty: userDetails.tenantId });
      GeneralUtils.appendCookie('INIT_USER_STATS', 'true');
    }
  }
};

const registerPageView = (history:any) => {
  if (process.env.REACT_APP_ENV === 'app') {
    initializeUserStats();
    const userDetailsFromCookie = GeneralUtils.getCookieValue('USER_DETAILS');
    // const isUserStatsInit = GeneralUtils.getCookieValue('INIT_USER_STATS');
    if (userDetailsFromCookie) {
    // const userDetails:IUserDetails = JSON.parse(userDetailsFromCookie);
      ReactGA.set({ page: history.location.pathname });
      ReactGA.pageview(history.location.pathname);
      hotjar.stateChange(history.location.pathname);
    }
  }
};

const registerInteractionEvent = (interactionName:string, label: REACT_GA_EVENT_LABEL) => {
  if (process.env.REACT_APP_ENV === 'app') {
    initializeUserStats();
    const userDetailsFromCookie = GeneralUtils.getCookieValue('USER_DETAILS');
    // const isUserStatsInit = GeneralUtils.getCookieValue('INIT_USER_STATS');
    if (userDetailsFromCookie) {
      ReactGA.event({
        category: 'Component Render',
        action: interactionName,
        label,
      });
      hotjar.event(`Component Render | ${interactionName} | ${label}`);
    }
  }
};

export default {
  registerPageView,
  initializeUserStats,
  registerInteractionEvent,
};
