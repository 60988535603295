/* eslint-disable no-param-reassign */
/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { ScrollView } from 'devextreme-react';
import WaferPlotter from 'components/utility-component/wafer-map-widget/wafer-map-v2/WaferPlotter/WaferPlotter';
import { Dies } from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferMapVariablesClassV2';
import _ from 'lodash';
import WaferOverlayControls from 'components/utility-component/wafer-map-widget/wafer-map-v2/Controls/WaferOverlayControls';
import {
  GroupingSortingDTO, IComparisonGraphResponseData, IDrilledGraphData, IGenericDetailedReportRequestObject, IGraphCoordinates,
} from 'interfaces';
import { toast } from 'react-toastify';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import GeneralUtils, { ReportTypeConstants } from 'GeneralUtils';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../CustomizedReportGraph';
import { IGraphDataHelper, IGraphDataHelperPreferences } from '../customized-report-helpers/CustomizedReportHelper';
import { ICombinedGraphData, ICombinedGraphDataWithReportHeaders } from '../customized-report-helpers/CustomizedReportInterfaces';
import OverlayGraphLegend from '../customized-report-helpers/OverlayGraphLegend/OverlayGraphLegend';
import { NotchPosition } from '../../../../components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
// eslint-disable-next-line import/extensions,import/no-unresolved
import AppConstants from '../../../../constants';
import ComparisonWaferHeader from '../../../../components/reports/v2.0/bin-wafer-map-comparison/ComparisonWaferHeader';
import { IActorDetails } from '../CustomizedReportsCollection';

let WAFER_HEIGHT_WIDTH = 435;
let RADAR_HEIGHT_WIDTH = WAFER_HEIGHT_WIDTH / 3;
const MARGIN_X = 50;
let TOTAL_WIDTH_OCCUPIED_BY_WAFER = WAFER_HEIGHT_WIDTH + RADAR_HEIGHT_WIDTH;
const WAFERS_PER_PAGE = AppConstants.numberOfGraphsPerRow;
const WAFERS_PER_COMBINATION = 3;

export class WaferPlottingUtil {
  getPrimaryComponent = (graphCaption: string, graphType: string | undefined, hasNoData: boolean, appendClass: boolean) => {
    if (graphType === '' && appendClass) {
      if (graphCaption === '0') {
        graphCaption = 'Dies with no value for selected test parameter';
      } else if (graphCaption === '-1') {
        graphCaption = '';
        hasNoData = true;
      } else {
        graphCaption = `Class ${graphCaption}`;
      }
    } else if (graphType === '' && !appendClass && graphCaption === '') {
      hasNoData = true;
    }
    return (
      <div className="">
        { graphType === 'overlay' ? (
          <OverlayGraphLegend graphCaption={graphCaption} />
        ) : <h4 className="mr10" style={{ display: 'inline' }}>{graphCaption}</h4> }
        <span className="color-danger">{hasNoData ? 'No data found' : ''}</span>
      </div>
    );
  };

  getWaferHeaderForComparisonReport = (comparisonGraphResponseData: IComparisonGraphResponseData | undefined) => {
    if (comparisonGraphResponseData === undefined || comparisonGraphResponseData === null) return null;
    return (
      <ComparisonWaferHeader comparisonGraphResponseData={comparisonGraphResponseData} />
    );
  }

  getAdditionalControls = (classMembers: any, state: ICustomizedReportGraphState, props: ICustomizedReportGraphProps, configs: { [key:string]: any }) => {
    return props.config && props.config.overlay ? [{
      eventKey: '1',
      title: 'Overlay Controls',
      body: <WaferOverlayControls
        onSelectAllSoftbins={classMembers.onSelectAllSoftbins}
        onChangeOverlayWafersSelection={classMembers.onChangeOverlayWafersSelection}
        onChangeSoftbinSelection={classMembers.onChangeSoftbinSelection}
        selectedSoftbinsForOverlay={state.selectedSoftbinsForOverlay}
        selectedWaferIdsForOverlay={state.selectedWaferIdsForOverlay}
        wafers={classMembers.wafers}
        softBins={classMembers.softBins}
        onApplyOverlay={(selectedSoftbins: string[], selectedWaferIds: string[]) => {
          this.onChangeReportConfigValues(selectedSoftbins, selectedWaferIds, props, classMembers, configs, state);
        }}
      />,
    }] : undefined;
  };

  getContextMenuDataSource = (classMembers: any, report: IActorDetails) => {
    //! (report.actor === ReportTypeConstants.BIN_WAFER_MAP_COMPARISON)?
    const contextMenuItems = [
      {
        text: 'Hide',
        items: [
          {
            text: 'Hide Selected',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'SELECTED',
                'HIDE_GRAPH_AND_LEGEND_ITEMS',
                report.hidingEventPublisher,
              );
            },
          },
          {
            text: 'Hide Similar',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'SIMILAR',
                'HIDE_GRAPH_AND_LEGEND_ITEMS',
                report.hidingEventPublisher,
              );
            },
          },
          {
            text: 'Hide All',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'ALL',
                'HIDE_GRAPH_AND_LEGEND_ITEMS',
                report.hidingEventPublisher,
              );
            },
          },
        ],
      },
      {
        text: 'Highlight',
        items: [
          {
            text: 'Highlight Selected',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'SELECTED',
                'SELECT_GRAPH_AND_LEGEND_ITEMS',
                report.selectionEventPublisher,
              );
            },
          },
          {
            text: 'Highlight Similar',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'SIMILAR',
                'SELECT_GRAPH_AND_LEGEND_ITEMS',
                report.selectionEventPublisher,
              );
            },
          },
          {
            text: 'Highlight All',
            onClick: () => {
              classMembers.reportPublisherEvent(
                'ALL',
                'SELECT_GRAPH_AND_LEGEND_ITEMS',
                report.selectionEventPublisher,
              );
            },
          },
        ],
      },
    ];
    if (report.actor === ReportTypeConstants.BIN_WAFER_MAP_COMPARISON){
      contextMenuItems.forEach((menuItem: any) => {
        menuItem.items = menuItem.items.filter((item: any) => !item.text.toLowerCase().includes('similar'));
      });
    }
    return contextMenuItems;
  }

  getDieHeightToWidthRatio = (graph: any, waferData: any) => {
    let dieHeightToWidthRatio = 1;
    if (graph.dieWidth !== null && graph.dieHeight !== null && graph.dieHeight !== 0 && graph.dieWidth !== 0) {
      dieHeightToWidthRatio = graph.dieHeight! / graph.dieWidth!;
    } else {
      dieHeightToWidthRatio = waferData.obj[0].length / waferData.obj.length;
    }
    return dieHeightToWidthRatio;
  }

  getWaferData = (graph: IGraphCoordinates) => {
    const xData = graph.x as number[];
    const yData = graph.y as number[];

    const {
      xMin, xMax, yMin, yMax,
    } = graph;
    let isAnyDieSelectedOnWafer = false;
    const dieHeightToWidthRatio = (xMax - xMin + 1) / (yMax - yMin + 1);
    const centerX = (xMax + xMin) / 2;
    const centerY = (yMax + yMin) / 2;
    let maxRadius = Number.MIN_SAFE_INTEGER;
    const obj: Dies = [];
    for (let y = 0; y < yMax - yMin + 1; y += 1) {
      obj.push([]);
      for (let x = 0; x < xMax - xMin + 1; x += 1) {
        obj[y].push(null);
      }
    }

    let toolTipLabels: string[] = [];
    if (graph.toolTips.length > 0) {
      toolTipLabels = Object.keys(graph.toolTips[0]).filter((x) => x !== 'Die Id');
    }
    let selectedIndexIndex = 0;
    const checkIfSelected = graph.selectedIndexes.length > 0;
    xData.forEach((xPosition: number, index: number) => {
      let isSelected = false;
      if (checkIfSelected && graph.selectedIndexes[selectedIndexIndex] === index) {
        isSelected = true;
        selectedIndexIndex += 1;
      }
      if (!isAnyDieSelectedOnWafer) isAnyDieSelectedOnWafer = isSelected;
      const columnIndex = xPosition;
      const rowIndex = yData[index];
      const currentObject = {
        binColor: graph.colors[index],
        isSelected,
        isCropped: false,
        isDeleted: false,
        bin: graph.toolTips[index]?.bin,
        index,
      };
      if (toolTipLabels.length > 0) {
        toolTipLabels.forEach((tooltipName) => {
          (currentObject as any)[tooltipName] = graph.toolTips[index][tooltipName];
          if (!isAnyDieSelectedOnWafer && tooltipName === 'isSelected') isAnyDieSelectedOnWafer = graph.toolTips[index][tooltipName];
        });
      }
      const p1 = columnIndex - centerX;
      const p2 = (rowIndex - centerY) * dieHeightToWidthRatio;
      const distanceFromCenter = Math.sqrt(p1 * p1 + p2 * p2);
      if (distanceFromCenter > maxRadius) maxRadius = distanceFromCenter;
      obj[rowIndex - yMin][columnIndex - xMin] = currentObject;
    });
    return {
      yMin, xMin, obj, toolTipLabels, maxRadius, isAnyDieSelectedOnWafer,
    };
  };

  onSelectionChanged = (keyIndex:string, selectedData:any, unSelectedData:any, classMembers: any, graphIndex: number) => {
    classMembers.setSelectionData({
      points: selectedData.map((d:any) => ({
        pointIndex: d.index,
        curveNumber: graphIndex,
      })),
    });
  };

  getExternalLegenFields = (grouping: GroupingSortingDTO[]) => {
    let numOfWafers = 0;
    const fields = grouping.map((g: GroupingSortingDTO) => {
      if (g.columnName === 'wafer_id') {
        numOfWafers = g.values ? g.values.length : 0;
      }
      return {
        key: g.columnName,
        value: g.values ? g.values.join(', ') : '',
      };
    });
    if (numOfWafers !== 0) {
      fields.push({
        key: 'Number Of Wafers',
        value: numOfWafers.toString(),
      });
    }
    return fields;
  };

  getReportSpecificAttributes = (graph: any, obj: any) => {
    if (obj in graph.reportSpecificAttributes) {
      return graph.reportSpecificAttributes[obj][0];
    }
    return null;
  };

  getKeyIndex = (uniqueId: string | undefined, graphCaption: string, reportActor: string, reportSessionId: string, selections: any) => {
    let tpName = '';
    const testParameters = selections.filter((x:any) => x.entityType === 'Testparameter');
    if (testParameters.length > 0 && testParameters[0].values.length > 0) {
      tpName = testParameters[0].values[0].name;
    }
    return `${uniqueId || graphCaption}${reportActor}${reportSessionId}${tpName}`;
  };

  onChangeReportConfigValues = (
    selectedSoftbinsForOverlay: string[],
    selectedWaferIdsForOverlay: string[],
    props: ICustomizedReportGraphProps,
    classMembers: any,
    configs: { [key:string]: any },
    state: ICustomizedReportGraphState,
  ) => {
    props.onChangeReportConfigValues([
      { key: 'bins', value: selectedSoftbinsForOverlay },
      { key: 'waferIdsForOverlay', value: selectedWaferIdsForOverlay },
    ]);

    const requestObject: IGenericDetailedReportRequestObject = classMembers.getRequestObject(props.viewMode);
    requestObject.config.bins = selectedSoftbinsForOverlay;
    requestObject.config.waferIdsForOverlay = selectedWaferIdsForOverlay;
    requestObject.config = _.cloneDeep(requestObject.config);
    requestObject.config.onlyOverlay = true;
    (props.dataHelper as IGraphDataHelper).getDetailedGraphData(requestObject, (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      const gd : ICombinedGraphData = graphDataAndReportHeader.flatGraphDataDto;
      for (let i = 0; i < gd.errors.length; i += 1) {
        if (gd.errors[i].type === 'DATA_ERROR') {
          toast.error(gd.errors[i].message);
        }
      }
      if (classMembers.waferPlotter) {
        const previousOverlayGraphKeys = Object.keys(classMembers.overlayGraphs);
        for (let i = 0; i < previousOverlayGraphKeys.length; i += 1) {
          classMembers.waferPlotter.removeWafer(previousOverlayGraphKeys[i]);
          if (previousOverlayGraphKeys[i] in configs) {
            // eslint-disable-next-line no-param-reassign
            delete configs[previousOverlayGraphKeys[i]];
          }
        }

        // eslint-disable-next-line no-param-reassign
        classMembers.overlayGraphs = [];
        if ((gd as ICombinedGraphData).graphs !== undefined && (gd as ICombinedGraphData).graphs.length > 0) {
          (gd as ICombinedGraphData).graphs.forEach(
            (graph: IGraphCoordinates, graphIndex: number) => {
              const newKeyIndex = this.getKeyIndex(graph.uniqueId, graph.graphCaption, props.report.actor.toString(), props.reportSessionId.toString(), props.selectionStore.selections);
              // eslint-disable-next-line no-param-reassign
              configs[newKeyIndex] = {
                primaryComponents: this.getPrimaryComponent(graph.graphCaption, graph.graphType, graph.x.length === 0, false),
                additionalControls: this.getAdditionalControls(classMembers, state, props, configs),
              };
              if (graph.graphType && graph.graphType === 'overlay') {
                // eslint-disable-next-line no-param-reassign
                classMembers.overlayGraphs[newKeyIndex] = graph;
              }
              const waferData = this.getWaferData(graph);
              const dieHeightToWidthRatio = this.getDieHeightToWidthRatio(graph, waferData);
              const tooltips = waferData.toolTipLabels.map((tooltipName: string) => {
                return [tooltipName, tooltipName];
              });
              classMembers.waferPlotter.addOrReplaceWafer({
                waferName: graph.graphCaption,
                externalLegendFields: this.getExternalLegenFields(graph.grouping),
                outerViewport: {
                  x: ((graphIndex % WAFERS_PER_PAGE) * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X)),
                  y: 0,
                  width: WAFER_HEIGHT_WIDTH,
                  height: WAFER_HEIGHT_WIDTH,
                },
                tooltipFields: [...tooltips.filter((x: any) => { return x[0] !== 'isSelected' && x[0] !== 'isDeleted'; }), ...[['xTrue', 'x'], ['yTrue', 'y']]],
                colOffset: waferData.xMin,
                rowOffset: waferData.yMin,
                dies: waferData.obj,
                keyIndex: newKeyIndex,
                hasMarkingFeature: false,
                hasRotationControls: true,
                showWaferInfo: true,
                isXAxisFlipped: false,
                isYAxisFlipped: false,
                notchPosition: NotchPosition.DOWN,
                rotationAngle: 0,
                dieSize: {
                  dieWidth: 1,
                  dieHeight: dieHeightToWidthRatio,
                },
                pageNumber: Math.floor(graphIndex / WAFERS_PER_PAGE),
                waferHeightToRowsRatio: ((waferData.maxRadius + (Math.sqrt((0.5 * dieHeightToWidthRatio) * (0.5 * dieHeightToWidthRatio) + (0.5 * 0.5)))) * 2)
                  / Math.min(waferData.obj.length * dieHeightToWidthRatio, waferData.obj[0].length),
              });
            },
          );
        }
      }
    });
  };

  getWidthPerWafer = (graphData: IDrilledGraphData[] | ICombinedGraphData, widthPerGraph: number) => {
    if ((graphData as ICombinedGraphData).graphs.length > 0 && (graphData as ICombinedGraphData).graphs.length < 3) {
      WAFER_HEIGHT_WIDTH = widthPerGraph / 2.88;
      if (WAFER_HEIGHT_WIDTH % 1 !== 0){
        WAFER_HEIGHT_WIDTH = GeneralUtils.getClosestNumberDivisibleBy(WAFER_HEIGHT_WIDTH, 3);
      }
    } else {
      WAFER_HEIGHT_WIDTH = widthPerGraph / 4.3;
      if (WAFER_HEIGHT_WIDTH % 1 !== 0){
        WAFER_HEIGHT_WIDTH = GeneralUtils.getClosestNumberDivisibleBy(WAFER_HEIGHT_WIDTH, 3);
      }
    }
  };

  customWaferGenerationHelper = (
    props: ICustomizedReportGraphProps,
    state: ICustomizedReportGraphState,
    classMembers: any,
    _setStateCallback: any,
    preferences: IGraphDataHelperPreferences,
  ) => {
    const { graphData, widthPerGraph } = state;
    const waferMaps: {
      graphCaption:string;
      isEmpty: boolean;
      obj: Dies;
      keyIndex:string;
      graphIndex:number;
      rowOffset: number;
      colOffset: number;
      layoutRowNum: number;
      layoutColNum: number;
      tooltips: string[][];
      graphType: string;
      pageNumber: number,
      grouping: { key: string, value: string }[];
      dieHeightToWidthRatio: number;
      maxRadius: number,
      isXAxisFlipped: boolean,
      isYAxisFlipped: boolean,
      isDefaultXAxisFlipped: boolean,
      isDefaultYAxisFlipped: boolean,
      notchPosition: NotchPosition,
      rotationAngle: number,
      isDieSelectedOnAnyWafer: boolean,
    }[] = [];

    const groupingKeyToRowMapping: { [key: string] : number } = {};
    const colsInRowNum: { [row: number]: number } = {};
    let rowNumber = 0;
    colsInRowNum[rowNumber] = 0;
    let isOverlayGraphPresent = false;
    const overlayGraphs: { [keyIndex: string]: IGraphCoordinates } = {};

    if ((graphData as ICombinedGraphData).graphs !== undefined && (graphData as ICombinedGraphData).graphs.length > 0) {
      // eslint-disable-next-line no-param-reassign
      classMembers.softBins = (graphData as ICombinedGraphData).extras ? (graphData as ICombinedGraphData).extras!.bins : undefined;
      // eslint-disable-next-line no-param-reassign
      classMembers.wafers = (graphData as ICombinedGraphData).extras && (graphData as ICombinedGraphData).extras!.wafers
        ? Object.keys((graphData as ICombinedGraphData).extras!.wafers).map((waferId: string) => {
          return { waferId, waferKey: (graphData as ICombinedGraphData).extras!.wafers[waferId] };
        })
        : [];
      let isDieSelectedOnAnyWafer = false;
      (graphData as ICombinedGraphData).graphs.forEach(
        (graph, graphIndex) => {
          const keyIndex = this.getKeyIndex(graph.uniqueId, graph.graphCaption, props.report.actor.toString(), props.reportSessionId.toString(), props.selectionStore.selections);
          if (graph.graphType) {
            if (!(graph.graphType in groupingKeyToRowMapping)) {
              groupingKeyToRowMapping[graph.graphType] = rowNumber;
              rowNumber += 1;
              colsInRowNum[rowNumber] = 0;
            }
            if (graph.graphType === 'overlay') {
              isOverlayGraphPresent = true;
              overlayGraphs[keyIndex] = graph;
            }
          }
          const waferData = this.getWaferData(graph);
          if (!isDieSelectedOnAnyWafer) isDieSelectedOnAnyWafer = waferData.isAnyDieSelectedOnWafer;
          const layoutColNum = colsInRowNum[graph.graphType ? groupingKeyToRowMapping[graph.graphType] : 0];

          waferMaps.push({
            dieHeightToWidthRatio: this.getDieHeightToWidthRatio(graph, waferData),
            rowOffset: waferData.yMin,
            colOffset: waferData.xMin,
            graphCaption: graph.graphCaption,
            obj: waferData.obj,
            isEmpty: graph.x.length === 0,
            graphIndex,
            keyIndex,
            layoutRowNum: graph.graphType ? groupingKeyToRowMapping[graph.graphType] : 0,
            layoutColNum: (layoutColNum) % WAFERS_PER_PAGE,
            tooltips: waferData.toolTipLabels.map((tooltipName: string) => { return [tooltipName, tooltipName]; }),
            graphType: graph.graphType || '',
            grouping: this.getExternalLegenFields(graph.grouping),
            pageNumber: Math.floor(layoutColNum / WAFERS_PER_PAGE),
            maxRadius: waferData.maxRadius,
            isXAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferFlipX'),
            isYAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferFlipY'),
            isDefaultXAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'UP_LEFT' || this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'DOWN_LEFT',
            isDefaultYAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'UP_LEFT' || this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'UP_RIGHT',
            notchPosition: NotchPosition.DOWN,
            rotationAngle: UtilityFunctions.toRotationAngle(this.getReportSpecificAttributes(graph, 'WaferFlat')),
            isDieSelectedOnAnyWafer: false,
          });
          colsInRowNum[graph.graphType ? groupingKeyToRowMapping[graph.graphType] : 0] += 1;
        },
      );

      if (isDieSelectedOnAnyWafer) {
        waferMaps.forEach((waferMap) => { waferMap.isDieSelectedOnAnyWafer = true; });
      }

      const { report } = props;
      const configs : { [key:string]: any } = {};
      waferMaps.forEach((waferMap, index:number) => {
        configs[waferMap.keyIndex] = {
          primaryComponents: this.getPrimaryComponent(waferMap.graphCaption, waferMap.graphType, waferMap.isEmpty, props.groupingSortingListStore.grouping.length === 0),
          contextMenuDataSource: this.getContextMenuDataSource(classMembers, report),
          additionalControls: this.getAdditionalControls(classMembers, state, props, configs),
        };
      });

      let totalNumberOfRows = Object.values(colsInRowNum).filter((value) => { return value !== 0; }).length;
      if (!isOverlayGraphPresent && props.report.actor === 'BIN_WAFER_MAP' && props.config.overlay) {
        totalNumberOfRows += 1;
      }
      const actualMaxNumOfWafersInARow = Math.max(...Object.values(colsInRowNum));
      const numOfWafersToUse = Math.min(WAFERS_PER_PAGE, actualMaxNumOfWafersInARow);
      let canvasWidth = numOfWafersToUse * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X);
      this.getWidthPerWafer(graphData, widthPerGraph);
      RADAR_HEIGHT_WIDTH = WAFER_HEIGHT_WIDTH / 3;
      TOTAL_WIDTH_OCCUPIED_BY_WAFER = WAFER_HEIGHT_WIDTH + RADAR_HEIGHT_WIDTH;
      canvasWidth = numOfWafersToUse * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X);
      while (canvasWidth > 15000) {
        WAFER_HEIGHT_WIDTH -= 3;
        RADAR_HEIGHT_WIDTH = WAFER_HEIGHT_WIDTH / 3;
        TOTAL_WIDTH_OCCUPIED_BY_WAFER = WAFER_HEIGHT_WIDTH + RADAR_HEIGHT_WIDTH;
        canvasWidth = numOfWafersToUse * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X);
      }
      const canvasHeight = (WAFER_HEIGHT_WIDTH + 70) * totalNumberOfRows;
      if (classMembers.waferPlotter === null) {
        // eslint-disable-next-line no-param-reassign
        classMembers.overlayGraphs = overlayGraphs;
      } else {
        const previousOverlayGraphKeys = Object.keys(classMembers.overlayGraphs);
        for (let i = 0; i < previousOverlayGraphKeys.length; i += 1) {
          configs[previousOverlayGraphKeys[i]] = {
            primaryComponents: this.getPrimaryComponent(
              classMembers.overlayGraphs[previousOverlayGraphKeys[i]].graphCaption,
              classMembers.overlayGraphs[previousOverlayGraphKeys[i]].graphType,
              classMembers.overlayGraphs[previousOverlayGraphKeys[i]].x.length === 0,
              false,
            ),
            additionalControls: this.getAdditionalControls(classMembers, state, props, configs),
          };
        }
      }

      return (
        (
          <ScrollView
            useNative
            showScrollbar="always"
            scrollByThumb
            className="ml15"
            height={`${canvasHeight + 50}px`}
            direction="horizontal"
            ref={(r) => { if (r && classMembers.waferPlotterScrollView === null) classMembers.waferPlotterScrollView = r; }}
          >
            <WaferPlotter
              draggableControlsWidth={200}
              plotterKey={report.actor}
              width={canvasWidth}
              height={canvasHeight}
              config={configs}
              plotterPageNumber={state.plotterPageNumber}
              // onSelectionChanged={(data:any) => {
              //   classMembers.setSelectionData({
              //     points: data.map((d:any) => ({
              //       pointIndex: d.index,
              //       curveNumber: graphIndex,
              //     })),
              //   });
              // }}
              ref={(r) => {
                if (r && classMembers.waferPlotter === null) {
                  // eslint-disable-next-line no-param-reassign
                  classMembers.waferPlotter = r;
                  waferMaps.forEach((waferMap, index:number) => {
                    r.addOrReplaceWafer({
                      isDieSelectedOnAnyWafer: waferMap.isDieSelectedOnAnyWafer,
                      waferName: waferMap.graphCaption,
                      externalLegendFields: waferMap.grouping,
                      pageNumber: waferMap.pageNumber,
                      outerViewport: {
                        x: (waferMap.layoutColNum * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X)),
                        y: (canvasHeight) - ((WAFER_HEIGHT_WIDTH + 70) * waferMap.layoutRowNum) - (WAFER_HEIGHT_WIDTH + 70),
                        width: WAFER_HEIGHT_WIDTH,
                        height: WAFER_HEIGHT_WIDTH,
                      },
                      tooltipFields: [...waferMap.tooltips.filter((x: any) => { return x[0] !== 'isSelected' && x[0] !== 'isDeleted'; }), ...[['xTrue', 'x'], ['yTrue', 'y']]],
                      colOffset: waferMap.colOffset,
                      rowOffset: waferMap.rowOffset,
                      dies: waferMap.obj,
                      keyIndex: waferMap.keyIndex,
                      hasMarkingFeature: false,
                      hasRotationControls: true,
                      showWaferInfo: true,
                      waferHeightToRowsRatio: ((waferMap.maxRadius + (Math.sqrt((0.5 * waferMap.dieHeightToWidthRatio) * (0.5 * waferMap.dieHeightToWidthRatio) + (0.5 * 0.5)))) * 2)
                        / Math.min(waferMap.obj.length * waferMap.dieHeightToWidthRatio, waferMap.obj[0].length),
                      dieSize: {
                        dieWidth: 1,
                        dieHeight: waferMap.dieHeightToWidthRatio,
                      },
                      isXAxisFlipped: waferMap.isXAxisFlipped,
                      isYAxisFlipped: waferMap.isYAxisFlipped,
                      isDefaultXAxisFlipped: waferMap.isDefaultXAxisFlipped,
                      isDefaultYAxisFlipped: waferMap.isDefaultYAxisFlipped,
                      notchPosition: waferMap.notchPosition,
                      rotationAngle: waferMap.rotationAngle,
                      onSelectionChanged: (keyIndex: string, selectedData: any, unSelectedData: any) => {
                        this.onSelectionChanged(keyIndex, selectedData, unSelectedData, classMembers, waferMap.graphIndex);
                      },
                    });
                  });
                }
              }}
              pinControlsToWaferTop={false}
            />

          </ScrollView>
        )
      );
    }
    return (<></>);
  };

  binComparisonWaferGenerationHelper = (
    props: ICustomizedReportGraphProps,
    state: ICustomizedReportGraphState,
    classMembers: any,
    _setStateCallback: any,
    preferences: IGraphDataHelperPreferences,
  ) => {
    const { graphData, widthPerGraph } = state;
    const waferMaps: {
      graphCaption:string;
      isEmpty: boolean;
      obj: Dies;
      keyIndex:string;
      graphIndex:number;
      rowOffset: number;
      colOffset: number;
      layoutRowNum: number;
      layoutColNum: number;
      tooltips: string[][];
      graphType: string;
      pageNumber: number,
      grouping: { key: string, value: string }[];
      dieHeightToWidthRatio: number;
      maxRadius: number,
      isXAxisFlipped: boolean,
      isYAxisFlipped: boolean,
      isDefaultXAxisFlipped: boolean,
      isDefaultYAxisFlipped: boolean,
      notchPosition: NotchPosition,
      rotationAngle: number,
      isDieSelectedOnAnyWafer: boolean,
      comparisonGraphResponseData?: IComparisonGraphResponseData,
    }[] = [];

    let rowNumber = 0;

    if ((graphData as ICombinedGraphData).graphs !== undefined && (graphData as ICombinedGraphData).graphs.length > 0) {
      classMembers.softBins = (graphData as ICombinedGraphData).extras ? (graphData as ICombinedGraphData).extras!.bins : undefined;
      let isDieSelectedOnAnyWafer = false;
      (graphData as ICombinedGraphData).graphs.forEach(
        (graph, graphIndex) => {
          const keyIndex = this.getKeyIndex(graph.uniqueId, graph.graphCaption, props.report.actor.toString(), props.reportSessionId.toString(), props.selectionStore.selections);
          rowNumber = Math.floor(graphIndex / WAFERS_PER_COMBINATION);
          const layoutColNum = graphIndex % WAFERS_PER_COMBINATION;
          const waferData = this.getWaferData(graph);
          if (!isDieSelectedOnAnyWafer) isDieSelectedOnAnyWafer = waferData.isAnyDieSelectedOnWafer;

          waferMaps.push({
            dieHeightToWidthRatio: this.getDieHeightToWidthRatio(graph, waferData),
            rowOffset: waferData.yMin,
            colOffset: waferData.xMin,
            graphCaption: graph.graphCaption,
            obj: waferData.obj,
            isEmpty: graph.x.length === 0,
            graphIndex,
            keyIndex,
            layoutRowNum: rowNumber,
            layoutColNum,
            tooltips: waferData.toolTipLabels.map((tooltipName: string) => { return [tooltipName, tooltipName]; }),
            graphType: graph.graphType || '',
            grouping: this.getExternalLegenFields(graph.grouping),
            pageNumber: 0,
            maxRadius: waferData.maxRadius,
            isXAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferFlipX'),
            isYAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferFlipY'),
            isDefaultXAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'UP_LEFT' || this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'DOWN_LEFT',
            isDefaultYAxisFlipped: this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'DOWN_LEFT' || this.getReportSpecificAttributes(graph, 'WaferAxisDirection') === 'DOWN_RIGHT',
            notchPosition: NotchPosition.DOWN,
            rotationAngle: UtilityFunctions.toRotationAngle(this.getReportSpecificAttributes(graph, 'WaferFlat')),
            isDieSelectedOnAnyWafer: false,
            comparisonGraphResponseData: graph.comparisonGraphResponseData,
          });
        },
      );

      if (isDieSelectedOnAnyWafer) {
        waferMaps.forEach((waferMap) => { waferMap.isDieSelectedOnAnyWafer = true; });
      }

      const { report } = props;
      const configs : { [key:string]: any } = {};
      waferMaps.forEach((waferMap, index:number) => {
        configs[waferMap.keyIndex] = {
          primaryComponents: waferMap.graphType === 'comparison-graph' ? this.getWaferHeaderForComparisonReport(waferMap.comparisonGraphResponseData)
            : this.getPrimaryComponent(waferMap.graphCaption, waferMap.graphType, waferMap.isEmpty, props.groupingSortingListStore.grouping.length === 0),
          contextMenuDataSource: this.getContextMenuDataSource(classMembers, report),
          additionalControls: this.getAdditionalControls(classMembers, state, props, configs),
        };
      });

      const totalNumberOfRows = (graphData as ICombinedGraphData).graphs.length / WAFERS_PER_COMBINATION;

      this.getWidthPerWafer(graphData, widthPerGraph);
      RADAR_HEIGHT_WIDTH = WAFER_HEIGHT_WIDTH / 3;
      TOTAL_WIDTH_OCCUPIED_BY_WAFER = WAFER_HEIGHT_WIDTH + RADAR_HEIGHT_WIDTH;
      let canvasWidth = WAFERS_PER_COMBINATION * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X);
      while (canvasWidth > 15000) {
        WAFER_HEIGHT_WIDTH -= 3;
        RADAR_HEIGHT_WIDTH = WAFER_HEIGHT_WIDTH / 3;
        TOTAL_WIDTH_OCCUPIED_BY_WAFER = WAFER_HEIGHT_WIDTH + RADAR_HEIGHT_WIDTH;
        canvasWidth = WAFERS_PER_COMBINATION * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X);
      }
      const waferHeaderMargin = 133;
      const canvasHeight = (WAFER_HEIGHT_WIDTH + waferHeaderMargin) * totalNumberOfRows;

      return (
        (
          <ScrollView
            useNative
            showScrollbar="always"
            scrollByThumb
            className="ml15"
            height={`${canvasHeight + 50}px`}
            direction="horizontal"
            ref={(r) => { if (r && classMembers.waferPlotterScrollView === null) classMembers.waferPlotterScrollView = r; }}
          >

            <WaferPlotter
              draggableControlsWidth={200}
              plotterKey={report.actor}
              width={canvasWidth}
              height={canvasHeight}
              config={configs}
              plotterPageNumber={state.plotterPageNumber}
              ref={(r) => {
                if (r && classMembers.waferPlotter === null) {
                  classMembers.waferPlotter = r;
                  waferMaps.forEach((waferMap, index:number) => {
                    r.addOrReplaceWafer({
                      isDieSelectedOnAnyWafer: waferMap.isDieSelectedOnAnyWafer,
                      waferName: waferMap.graphCaption,
                      externalLegendFields: waferMap.grouping,
                      pageNumber: waferMap.pageNumber,
                      outerViewport: {
                        x: (waferMap.layoutColNum * (TOTAL_WIDTH_OCCUPIED_BY_WAFER + MARGIN_X)),
                        y: (canvasHeight) - ((WAFER_HEIGHT_WIDTH + waferHeaderMargin) * waferMap.layoutRowNum) - (WAFER_HEIGHT_WIDTH + waferHeaderMargin),
                        width: WAFER_HEIGHT_WIDTH,
                        height: WAFER_HEIGHT_WIDTH,
                      },
                      tooltipFields: [...waferMap.tooltips.filter((x: any) => { return x[0] !== 'isSelected' && x[0] !== 'isDeleted'; }), ...[['xTrue', 'x'], ['yTrue', 'y']]],
                      colOffset: waferMap.colOffset,
                      rowOffset: waferMap.rowOffset,
                      dies: waferMap.obj,
                      keyIndex: waferMap.keyIndex,
                      hasMarkingFeature: false,
                      hasRotationControls: true,
                      showWaferInfo: true,
                      waferHeightToRowsRatio: ((waferMap.maxRadius + (Math.sqrt((0.5 * waferMap.dieHeightToWidthRatio) * (0.5 * waferMap.dieHeightToWidthRatio) + (0.5 * 0.5)))) * 2)
                        / Math.min(waferMap.obj.length * waferMap.dieHeightToWidthRatio, waferMap.obj[0].length),
                      dieSize: {
                        dieWidth: 1,
                        dieHeight: waferMap.dieHeightToWidthRatio,
                      },
                      isXAxisFlipped: waferMap.isXAxisFlipped,
                      isYAxisFlipped: waferMap.isYAxisFlipped,
                      isDefaultXAxisFlipped: waferMap.isDefaultXAxisFlipped,
                      isDefaultYAxisFlipped: waferMap.isDefaultYAxisFlipped,
                      notchPosition: waferMap.notchPosition,
                      rotationAngle: waferMap.rotationAngle,
                      onSelectionChanged: (keyIndex: string, selectedData: any, unSelectedData: any) => {
                        this.onSelectionChanged(keyIndex, selectedData, unSelectedData, classMembers, waferMap.graphIndex);
                      },
                    });
                  });
                }
              }}
              pinControlsToWaferTop={false}
            />
          </ScrollView>
        )
      );
    }
    return (<></>);
  };
}
