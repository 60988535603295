import { ReportTypeConstants } from 'GeneralUtils';
import { ReportType } from 'interfaces';
import { v4 as uuidv4 } from 'uuid';

type GroupedActorType = 'ALL_REPORTS_AND_LEGENDS' | 'ALL_REPORTS' | 'ALL_LEGENDS' | 'CUSTOMIZED_REPORTS_COLLECTION';

export type ActionType =
'LOG_POWER_VIEW_PROGRESS' |
'SELECT_GRAPH_ITEMS' |
'SELECT_LEGEND_ITEMS' |
'HIDE_GRAPH_ITEMS' |
'HIDE_LEGEND_ITEMS' |
'SELECT_GRAPH_AND_LEGEND_ITEMS' |
'HIDE_GRAPH_AND_LEGEND_ITEMS' |
'APPLY_REPORT_HEADER' |
'APPLY_REPORT_HEADER_TO_SELECTED' |
'EXPAND_LEGEND_EVENT' |
'COLLAPSE_LEGEND_EVENT' |
'HIDE_DATA_EXPORT_SPINNER';

export type InteractionEventType = 'SELECTION' | 'HIDE' | 'CLEAR_INTERACTIONS';

export interface IActor {
  publisherId: string;
  actorType: string;
  callback: (action: ActionType, params?:any) => void;
  isStaticActor?: boolean; // static actor only interacts with its own report / legend
}

let actors : IActor[] = [];

const PubSubBinder = () => {
  function RegisterActor(
    actorType: string,
    callback: (action: ActionType, params?:any) => void,
    isStaticActor?: boolean,
  ) {
    const publisherId = uuidv4();

    // UPDATE CALLBACK FUNCTION IF ALREADY EXISTS
    // const actorIndex = actors.findIndex((x) => x.actorType === actorType);
    // if (actorIndex > -1) {
    //   actors[actorIndex].callback = callback;
    //   return actors[actorIndex].publisherId;
    // }
    actors.push({
      publisherId,
      actorType,
      callback,
      isStaticActor,
    });
    // console.log('Actors in PubSub', actors);

    return publisherId;
  }

  function broadcastEventToCounterpart(currActor: IActor, subscribers: IActor[]) {
    if (currActor.actorType.includes('_LEGEND')) {
      actors.filter((x) => x.actorType === currActor.actorType.replace('_LEGEND', '') && x.publisherId).forEach((x) => {
        subscribers.push(x);
      });
    } else {
      actors.filter((x) => x.actorType === `${currActor.actorType}_LEGEND` && x.publisherId).forEach((x) => {
        subscribers.push(x);
      });
    }
  }

  function BroadcastEvent(publisherId: string, publishedTo: string[] | GroupedActorType, action: ActionType, params?:any) {
    const subscribers : IActor[] = [];
    const currentActorFilter = actors.filter((x) => x.publisherId === publisherId);
    if (currentActorFilter.length === 0) return;
    const currActor = currentActorFilter[0];

    if ((publishedTo as GroupedActorType) === 'ALL_REPORTS_AND_LEGENDS') {
      if (currActor.isStaticActor) {
        broadcastEventToCounterpart(currActor, subscribers);
      } else {
        actors.filter((x) => x.publisherId !== publisherId && !x.isStaticActor).forEach((x) => {
          subscribers.push(x);
        });
      }
    } else if ((publishedTo as GroupedActorType) === 'ALL_LEGENDS') {
      actors.filter((x) => x.actorType.includes('_LEGEND') && x.publisherId).forEach((x) => {
        subscribers.push(x);
      });
    } else if ((publishedTo as GroupedActorType) === 'ALL_REPORTS') {
      actors.filter((x) => !x.actorType.includes('_LEGEND') && x.publisherId).forEach((x) => {
        subscribers.push(x);
      });
    } else {
      actors.filter((x) => x.actorType.includes('GENERAL_ACTION') && x.publisherId).forEach((x) => {
        subscribers.push(x);
      });
      if (action === 'HIDE_DATA_EXPORT_SPINNER'){
        actors.filter((x) => x.actorType.includes(ReportTypeConstants.RAW_DATA_GRID)).forEach((x) => {
          subscribers.push(x);
        })
      }
    }
    if (subscribers.length > 0) {
      subscribers.forEach((subscriber) => {
        subscriber.callback(action, params);
      });
    }
  }

  function GetActor(publisherId: string) {
    const publisher = actors.filter((x) => x.publisherId === publisherId);
    if (publisher.length > 0) {
      return publisher[0];
    }
  }

  function GetAll() {
    return actors;
  }

  function DisposeActor(publisherId: string) {
    const publisherIndex = actors.findIndex((x) => x.publisherId === publisherId);
    if (publisherIndex >= 0) {
      actors.splice(publisherIndex, 1);
    }
  }

  function DisposeActorsByType(actorType: string) {
    actors = actors.filter((x) => x.actorType !== actorType);
  }

  function DisposeAll() {
    actors = [];
  }

  return {
    RegisterActor,
    BroadcastEvent,
    GetActor,
    GetAll,
    DisposeActor,
    DisposeActorsByType,
    DisposeAll,
  };
};

export default PubSubBinder;
