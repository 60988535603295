/* eslint-disable no-param-reassign */
import { IFieldValue } from 'components/utility-component/report-headers/ReportHeaderBuilder';
import { ScrollView } from 'devextreme-react';
import { GroupingSortingDTO, IGraphCoordinates } from 'interfaces';
import _ from 'lodash';
import React from 'react';
import { ReportTypeConstants as ReportType, ReportTypeConstants } from 'GeneralUtils';
import { ILineDict } from '../customized-report-helpers/action-items-sheet/ActionItemsSheet';
import { IGraphDataHelperPreferences } from '../customized-report-helpers/CustomizedReportHelper';
import { ICombinedGraphData } from '../customized-report-helpers/CustomizedReportInterfaces';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../CustomizedReportGraph';
import { GraphingUtils } from './GraphingUtils';

export interface IReportHorizontalScrollViewProps {
  reportGraphProps: ICustomizedReportGraphProps,
  reportGraphState: ICustomizedReportGraphState,
  classMembers: any,
  setStateCallback: any,
  preferences: IGraphDataHelperPreferences,
  summaryStatLines?: IFieldValue[],
  lineDict?: ILineDict
}

const mapParametricFailureReportData = (tempState: ICustomizedReportGraphState, g: IGraphCoordinates) => {
  // (tempState.graphData as ICombinedGraphData).graphs = [];
  const failureContribution = _.cloneDeep(g);
  const failurePercentage = _.cloneDeep(g);
  failureContribution.x = [];
  failureContribution.xValue = [];
  failureContribution.colors = [];
  failureContribution.xText = [];
  failureContribution.y = [];
  failureContribution.yCoordinateCustom = [];
  failureContribution.selectedIndexes = [];
  failureContribution.selectedInteractionDetail = [];

  failurePercentage.x = [];
  failurePercentage.xValue = [];
  failurePercentage.colors = [];
  failurePercentage.xText = [];
  failurePercentage.y = [];
  failurePercentage.yCoordinateCustom = [];
  failurePercentage.selectedIndexes = [];
  failurePercentage.selectedInteractionDetail = [];
  for (let i = 0; i < g.x.length; i += 1) {
    if (i < g.x.length / 2) {
      (failureContribution.yCoordinateCustom as Array<any>).push(g.yCoordinateCustom[i]);
    }

    if (i % 2 === 0) {
      // failure percentage
      (failurePercentage.x as Array<any>).push(g.x[i]);
      (failurePercentage.xValue as Array<any>).push(g.xValue[i]);
      (failurePercentage.colors as Array<any>).push(g.colors[i]);
      (failurePercentage.xText as Array<any>).push(g.xText[i]);
      (failurePercentage.y as Array<any>).push(g.y[i]);
      (failurePercentage.selectedIndexes as Array<any>).push(g.selectedIndexes[i]);
      (failurePercentage.selectedInteractionDetail as Array<any>).push(g.selectedInteractionDetail[i]);
    } else {
      // failure contribution
      (failureContribution.x as Array<any>).push(g.x[i]);
      (failureContribution.xValue as Array<any>).push(g.xValue[i]);
      (failureContribution.colors as Array<any>).push(g.colors[i]);
      (failureContribution.xText as Array<any>).push(g.xText[i]);
      (failureContribution.y as Array<any>).push(g.y[i]);
      (failureContribution.selectedIndexes as Array<any>).push(g.selectedIndexes[i]);
      (failureContribution.selectedInteractionDetail as Array<any>).push(g.selectedInteractionDetail[i]);
    }
  }
  (tempState.graphData as ICombinedGraphData).graphs.push(failureContribution, failurePercentage);
};

const moveModeBarWithScroll = (e: any, props: IReportHorizontalScrollViewProps) => {
  const { reportGraphState, reportGraphProps } = props;
  const { reportPublisherId, numOfGraphsPerPage } = reportGraphState
  const { report, reportIndex } = reportGraphProps;
  const currentGraph = document.getElementById(`${report.actor}-${reportIndex}-${reportPublisherId}`)
  const modeBarElement = (currentGraph!.getElementsByClassName('modebar') as HTMLCollectionOf<HTMLElement>)[0];
  const currentModeBarLeftPositionPercentage = Number(modeBarElement.style.getPropertyValue('left').replace('%', ''));
  const currentGraphInScroll = Math.trunc(((e.component.scrollLeft()) / e.component.clientWidth()) * numOfGraphsPerPage);
  let updatedModeBarLeftPositionPercentage = (currentGraphInScroll / numOfGraphsPerPage) * 100;
  if (updatedModeBarLeftPositionPercentage > currentModeBarLeftPositionPercentage){
    updatedModeBarLeftPositionPercentage += 1;
  } else if ((updatedModeBarLeftPositionPercentage + 15) < currentModeBarLeftPositionPercentage){
    updatedModeBarLeftPositionPercentage = e.component.scrollLeft() === 0 ? 0 : updatedModeBarLeftPositionPercentage + 20;
  } else {
    updatedModeBarLeftPositionPercentage = currentModeBarLeftPositionPercentage;
  }
  modeBarElement.style.setProperty('left', `${updatedModeBarLeftPositionPercentage.toString()}%`);
}

export const ReportHorizontalScrollView = (props: IReportHorizontalScrollViewProps) => {
  const {
    reportGraphState, reportGraphProps, classMembers, setStateCallback, preferences, summaryStatLines, lineDict
  } = props;
  const { plotterPageNumber, numOfGraphsPerPage } = reportGraphState;
  const { groupingSortingListStore } = reportGraphProps;
  const graphingUtil = new GraphingUtils();
  let groupsCount = groupingSortingListStore.grouping.length === 0 ? 1 : groupingSortingListStore.grouping.length;
  const higherEntityTypes: string[] = ['FACILITY', 'WORK_CENTER', 'DEVICE', 'TEST_PROGRAM', 'TEST_PROGRAM_REVISION'];
  groupsCount = (groupingSortingListStore.grouping.length > 0 && groupingSortingListStore.grouping.every((g: GroupingSortingDTO) => higherEntityTypes.includes(g.entityType))
    && (reportGraphProps.report.actor === ReportTypeConstants.BIN_PARETO))
    ? groupsCount + 1 : groupsCount;
  if (groupsCount + 1 === (reportGraphState.graphData as ICombinedGraphData).graphs.length * (reportGraphState.graphData as ICombinedGraphData).labelRows.length) {
    groupsCount += 1;
  }
  const startIndex = plotterPageNumber * numOfGraphsPerPage;
  const endIndex = startIndex + numOfGraphsPerPage > (reportGraphState.graphData as ICombinedGraphData).graphs.length
    ? (reportGraphState.graphData as ICombinedGraphData).graphs.length : startIndex + numOfGraphsPerPage;
  const newReportGraphState = _.cloneDeep(reportGraphState);
  (newReportGraphState.graphData as ICombinedGraphData).graphs = (reportGraphState.graphData as ICombinedGraphData).graphs.slice(startIndex, endIndex);
  if (reportGraphProps.report.actor === ReportType.PARAMETRIC_FAILURE) {
    (newReportGraphState.graphData as ICombinedGraphData).graphs = [];
    (reportGraphState.graphData as ICombinedGraphData).graphs.slice(startIndex, endIndex).forEach((g) => {
      mapParametricFailureReportData(newReportGraphState, g);
    });
  }
  (newReportGraphState.graphData as ICombinedGraphData).labelRows = (reportGraphState.graphData as ICombinedGraphData).labelRows.slice(startIndex * groupsCount, endIndex * groupsCount);
  return (
    <ScrollView
      useNative
      showScrollbar="always"
      scrollByThumb
      direction="horizontal"
      className="mr40"
      onScroll={(e: any) => {
        moveModeBarWithScroll(e, props);
      }}
    >
      <div className="d-flex align-items-center mt10">
        {graphingUtil.plotlyGraphGenerationHelper(reportGraphProps, newReportGraphState, classMembers, setStateCallback, preferences, summaryStatLines, lineDict)}
      </div>
    </ScrollView>
  );
};

export default ReportHorizontalScrollView;
