/* eslint-disable no-unused-vars */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import initializeEvents from '../web-gl-utils/Events';
// eslint-disable-next-line no-unused-vars
import { WaferMapData } from '../web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

let startArrayX: number;
let startArrayY: number;
let endArrayX: number;
let endArrayY: number;
const createZoomBoxOnMouseMove = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  waferMapInstance: WebGLUtils,
  isMouseDown: boolean,
): void => {
  if (!isMouseDown || !waferMapInstance.waferMapVariables.zoom) return;
  const {
    ratioWidth, ratioHeight, viewport, tickViewPort, viewPortHeight, angleInDegrees, viewPortWidth,
  } = waferMapInstance.waferMapVariables;
  if (startX < viewport.x || startX > viewport.x + viewport.width) return;
  if (startY < viewPortHeight - (viewport.y + viewport.height) || startY > viewPortHeight - (viewport.y)) return;
  startX = (startX - viewport.x) * ratioWidth;
  endX = (endX - viewport.x) * ratioWidth;
  startY = (startY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  endY = (endY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
  const v = waferMapInstance.getGPUCoords({
    startX, startY, endX, endY,
  }, {
    width: waferMapInstance.waferMapVariables.viewPortWidth,
    height: waferMapInstance.waferMapVariables.viewPortHeight,
  });
  waferMapInstance.waferMapVariables.selectionCoord = {
    startX: v.startX, startY: -v.startY, endX: v.endX, endY: -v.endY,
  };
  // rotation
  const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
  startX -= viewPortWidth / 2;
  startY -= viewPortHeight / 2;
  let rstartX = startX * rotation[1] + startY * rotation[0];
  let rstartY = startY * rotation[1] - startX * rotation[0];
  rstartX += viewPortWidth / 2;
  rstartY += viewPortHeight / 2;
  endX -= viewPortWidth / 2;
  endY -= viewPortHeight / 2;
  let rendX = endX * rotation[1] + endY * rotation[0];
  let rendY = endY * rotation[1] - endX * rotation[0];
  rendX += viewPortWidth / 2;
  rendY += viewPortHeight / 2;
  // rotation
  const indicesObj = waferMapInstance.returnBoxArrayIndices(
    rstartX,
    rstartY,
    rendX,
    rendY,
  );
  startArrayX = indicesObj.startArrayX;
  startArrayY = indicesObj.startArrayY;
  endArrayX = indicesObj.endArrayX;
  endArrayY = indicesObj.endArrayY;
  const underSelectionData: WaferMapData[] = [];
  const {
    startRow, rowDirection, startCol, colDirection,
  } = waferMapInstance.waferMapVariables;
  for (let i = startArrayY; i <= endArrayY; i += 1) {
    for (let j = startArrayX; j <= endArrayX; j += 1) {
      const rIndex = startRow + rowDirection * i;
      const cIndex = startCol + colDirection * j;
      if (waferMapInstance.waferMapVariables.waferMapTestData[rIndex] && waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]) {
        underSelectionData.push(waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!);
        waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!.underSelection = true;
        underSelectionData[underSelectionData.length - 1].row = rIndex;
        underSelectionData[underSelectionData.length - 1].col = cIndex;
      }
    }
  }
  waferMapInstance.waferMapDataToGPU(
    waferMapInstance.waferMapVariables.waferMapTestData,
    waferMapInstance.waferMapVariables.scaledDieWidth,
    waferMapInstance.waferMapVariables.scaledDieHeight,
    {
      width: waferMapInstance.waferMapVariables.gl!.canvas.width,
      height: waferMapInstance.waferMapVariables.gl!.canvas.height,
    },
    waferMapInstance.waferMapVariables.XAnchor,
    waferMapInstance.waferMapVariables.YAnchor,
  );
  waferMapInstance.renderRadarView();
  waferMapInstance.prepareAndRenderRadarPointer();
  waferMapInstance.intiateRendering(
    waferMapInstance.waferMapVariables.gl!,
    waferMapInstance.waferMapVariables.XAnchor,
    waferMapInstance.waferMapVariables.YAnchor,
  );
  for (let i = startArrayY; i <= endArrayY; i += 1) {
    for (let j = startArrayX; j <= endArrayX; j += 1) {
      const rIndex = startRow + rowDirection * i;
      const cIndex = startCol + colDirection * j;
      if (waferMapInstance.waferMapVariables.waferMapTestData[rIndex] && waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]) {
        underSelectionData.push(waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!);
        waferMapInstance.waferMapVariables.waferMapTestData[rIndex][cIndex]!.underSelection = false;
        underSelectionData[underSelectionData.length - 1].row = rIndex;
        underSelectionData[underSelectionData.length - 1].col = cIndex;
      }
    }
  }
};

const zoomOnMouseUp = (startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils): void => {
  if (!waferMapInstance.waferMapVariables.zoom) return;
  if (startX !== endX && startY !== endY) {
    PublishSubscribe().publishWithWaferID(EventTypes.WAFER_MAP_ZOOMED, {
      startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
      startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
      endX: endX / waferMapInstance.waferMapVariables.viewPortWidth,
      endY: endY / waferMapInstance.waferMapVariables.viewPortHeight,
    }, waferMapInstance.waferMapVariables.keyIndex.toString());
  }
};

const zoom = (waferMapInstance: WebGLUtils): void => {
  if (waferMapInstance.waferMapVariables.gl === null) return;
  initializeEvents(
    waferMapInstance,
    waferMapInstance.waferMapVariables.gl!,
    createZoomBoxOnMouseMove,
    zoomOnMouseUp,
    null,
  );
};

export default zoom;
