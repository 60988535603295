/* eslint-disable max-len */
import { DockerContainerStats, DockerMachineStats, RollingData } from 'interfaces';

const tasks1: DockerContainerStats[] = [
  {
    id: 'container_1',
    name: 'container 1',
    state: 'STARTING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_2',
    name: 'container 2',
    state: 'RUNNING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_3',
    name: 'container 3',
    state: 'FAILED',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
];
const tasks2: DockerContainerStats[] = [
  {
    id: 'container_4',
    name: 'container 4',
    state: 'STARTING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_5',
    name: 'container 5',
    state: 'RUNNING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_6',
    name: 'container 6',
    state: 'FAILED',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
];
const tasks3: DockerContainerStats[] = [
  {
    id: 'container_7',
    name: 'container 7',
    state: 'STARTING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_8',
    name: 'container 8',
    state: 'RUNNING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_9',
    name: 'container 9',
    state: 'FAILED',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
];
const tasks4: DockerContainerStats[] = [
  {
    id: 'container_10',
    name: 'container 10',
    state: 'STARTING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_11',
    name: 'container 11',
    state: 'RUNNING',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
  {
    id: 'container_12',
    name: 'container 12',
    state: 'FAILED',
    freeMemory: '12GB',
    cpuUsed: '10%',
  },
];

const rollingCPUUsed: RollingData = {
  data: {
    x: [1270, 9145, 7568, 7927, 9812, 9550, 108, 6963, 8926, 8002, 380, 9884, 5226, 7529, 3860, 6339, 4770, 4981, 3497, 8730, 6974, 9819, 9206, 1339, 3523, 9244, 6680, 2648, 9555, 4337, 8899, 8244, 5359, 7890, 5566, 4521, 8291, 6188, 4629, 1041, 8438, 8174, 7692, 5111, 7041, 1844, 2781, 9881, 3088, 1476, 508, 115, 1714, 4311, 1846, 6426, 5643, 8182, 659, 3601, 6196, 1759, 2925, 9741, 9944, 9117, 5441, 4722, 762, 627, 4253, 9238, 9679, 7620, 8911, 3602, 2535, 7958, 5523, 2044, 9671, 1453, 3948, 5643, 8242, 9670, 1890, 9192, 2121, 2925, 5685, 9906, 5085, 1702, 9809, 8055, 4191, 9777, 4339, 1105, 3400, 1460, 7501, 3289, 1115, 6131, 2305, 9773, 9160, 7767, 3392, 7220, 1297, 3587],
    y: [176, 485, 119, 394, 91, 455, 184, 116, 195, 47, 150, 380, 443, 258, 136, 15, 240, 403, 383, 488, 373, 35, 154, 117, 192, 282, 168, 41, 272, 148, 68, 180, 451, 266, 318, 277, 195, 110, 210, 462, 105, 267, 415, 266, 75, 236, 262, 173, 44, 171, 461, 433, 35, 408, 140, 297, 307, 388, 299, 78, 23, 313, 84, 19, 334, 304, 404, 112, 396, 107, 247, 381, 121, 168, 221, 368, 179, 355, 239, 143, 209, 254, 259, 312, 220, 191, 186, 61, 457, 459, 53, 75, 297, 184, 179, 451, 334, 467, 193, 179, 216, 496, 165, 107, 403, 84, 435, 69, 468, 315, 276, 138, 152, 152],
  },
  minValue: 10,
  maxValue: 100,
};
const rollingMemoryUsed: RollingData = {
  data: {
    x: [1270, 9145, 7568, 7927, 9812, 9550, 108, 6963, 8926, 8002, 380, 9884, 5226, 7529, 3860, 6339, 4770, 4981, 3497, 8730, 6974, 9819, 9206, 1339, 3523, 9244, 6680, 2648, 9555, 4337, 8899, 8244, 5359, 7890, 5566, 4521, 8291, 6188, 4629, 1041, 8438, 8174, 7692, 5111, 7041, 1844, 2781, 9881, 3088, 1476, 508, 115, 1714, 4311, 1846, 6426, 5643, 8182, 659, 3601, 6196, 1759, 2925, 9741, 9944, 9117, 5441, 4722, 762, 627, 4253, 9238, 9679, 7620, 8911, 3602, 2535, 7958, 5523, 2044, 9671, 1453, 3948, 5643, 8242, 9670, 1890, 9192, 2121, 2925, 5685, 9906, 5085, 1702, 9809, 8055, 4191, 9777, 4339, 1105, 3400, 1460, 7501, 3289, 1115, 6131, 2305, 9773, 9160, 7767, 3392, 7220, 1297, 3587],
    y: [176, 485, 119, 394, 91, 455, 184, 116, 195, 47, 150, 380, 443, 258, 136, 15, 240, 403, 383, 488, 373, 35, 154, 117, 192, 282, 168, 41, 272, 148, 68, 180, 451, 266, 318, 277, 195, 110, 210, 462, 105, 267, 415, 266, 75, 236, 262, 173, 44, 171, 461, 433, 35, 408, 140, 297, 307, 388, 299, 78, 23, 313, 84, 19, 334, 304, 404, 112, 396, 107, 247, 381, 121, 168, 221, 368, 179, 355, 239, 143, 209, 254, 259, 312, 220, 191, 186, 61, 457, 459, 53, 75, 297, 184, 179, 451, 334, 467, 193, 179, 216, 496, 165, 107, 403, 84, 435, 69, 468, 315, 276, 138, 152, 152],
  },
  minValue: 10,
  maxValue: 100,
};

const infrastructure : DockerMachineStats[] = [
  {
    id: 'machine 1',
    name: 'some name',
    type: 'SWARM MASTER',
    freeMemory: '10GB',
    cpuUsed: '10%',
    tasks: tasks1,
    rollingCPUUsed,
    rollingMemoryUsed,
  },
  {
    id: 'machine 2',
    name: 'some name',
    type: 'WORKER',
    freeMemory: '10GB',
    cpuUsed: '10%',
    tasks: tasks2,
    rollingCPUUsed,
    rollingMemoryUsed,
  },
  {
    id: 'machine 3',
    name: 'some name',
    type: 'WORKER',
    freeMemory: '10GB',
    cpuUsed: '10%',
    tasks: tasks3,
    rollingCPUUsed,
    rollingMemoryUsed,
  },
  {
    id: 'machine 4',
    name: 'some name',
    type: 'WORKER',
    freeMemory: '10GB',
    cpuUsed: '10%',
    tasks: tasks4,
    rollingCPUUsed,
    rollingMemoryUsed,
  },
];

export default {
  getInfrastructure: () => {
    return infrastructure;
  },
};
