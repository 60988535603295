/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
// eslint-disable-next-line no-unused-vars
import React from "react";
import TopbarNav from "components/navigational-component/topbar-nav/TopbarNav";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  Grouping,
  GroupPanel,
  Scrolling,
  Sorting,
  Selection,
} from "devextreme-react/data-grid";
import { httpBinPlusTable } from "services/http.bin-plus-table";
import toast from "CustomToast";
import CustomizedDropdown from "components/wrapped-component/customized-dropdown/CustomizedDropdown";
import _ from "lodash";
import commonFunctions from "customFunctions";
import ModalPopup from "components/wrapped-component/modal-popup/modal-popup";

type Base = {
  id?: string | null;
  createdBy?: any;
  updatedBy?: any;
  createdOn?: string | null;
  updatedOn?: any;
  [key: string]: any;
};

type WorkCenterType = Base & {
  name?: string | null;
  binPlusDefinitionType?: string | null;
};

type BinPlusTablesState = {
  binPlusTables: any[];
  workCenterTypes: WorkCenterType[];
  forIframe: boolean;
};
const binPlusTableFields = [
  {
    dataField: "id",
    caption: "id",
  },
  {
    dataField: "name",
    caption: "Name",
  },
  {
    dataField: "owner",
    caption: "Owner",
  },
  {
    dataField: "version",
    caption: "Version",
  },
  {
    dataField: "description",
    caption: "Description",
  },
  {
    dataField: "state",
    caption: "State",
  },
  {
    dataField: "workCenterType",
    caption: "Work Center Type",
  },
  {
    dataField: "updatedOn",
    caption: "Updated On",
  },
  {
    dataField: "createdOn",
    caption: "Created On",
  },
];

class BinPlusTables extends React.Component<any, BinPlusTablesState> {
  bindefinitionMapsDatagrid: DataGrid | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      workCenterTypes: [],
      binPlusTables: [],
      forIframe: true,
    };
  }

  componentDidMount() {
    const { search } = this.props.location;

    this.getBinPlusTablesData();
    this.setState({
      forIframe: commonFunctions.hideDashboardBasedOnParams(search),
    });
  }

  getBinPlusTablesData = async () => {
    const workCenterTypesData: any[] =
      await httpBinPlusTable.getWorkCenterTypes();
    const binPlusTablesData = await httpBinPlusTable.getBinPlusTables();
    this.setState({
      workCenterTypes: workCenterTypesData,
      binPlusTables: binPlusTablesData,
    });
  };

  onRowRemoved = async (row: any) => {
    row.cancel = true;
    try {
      const confirmed = await ModalPopup.confirm({
        header: "Remove Confirmation",
        body: "Are you sure that you want to remove this Bin plus table? Do you want to proceed?",
      });

      if (confirmed) {
        const response = await httpBinPlusTable.removeBinPlusTable(row.data.id);
        if (response?.success) {
          toast.success(response.message);
          this.setState({ binPlusTables: response.data });
        }
      }
    } catch (error) {
      // Handle network errors or any unexpected issues
      // toast.error("An error occurred while deleting the Bin plus table.");
      console.error("Error deleting Bin plus table:", error);
    }
  };

  IsObject = (data: any) => {
    return typeof data === "object" && data !== null;
  };

  render() {
    const { className, getBinPlusTableSelection } = this.props;
    let { hideNavBar } = this.props;
    hideNavBar = hideNavBar === undefined ? false : hideNavBar;

    const { binPlusTables, workCenterTypes } = this.state;
    const createBinPlusTableDropdownList = workCenterTypes.map(
      (workCenterType: WorkCenterType) => {
        return [workCenterType.id!, workCenterType.name!];
      }
    );
    createBinPlusTableDropdownList.unshift(["generic", "Generic"]);
    createBinPlusTableDropdownList.unshift(["", "Create Bin+ Table"]);
    const { forIframe } = this.state;
    return (
      <Container fluid className={className !== "" ? className : ""}>
        {!hideNavBar && forIframe && (
          <TopbarNav
            title="Bin+ Tables"
            items={[]}
            showAvatar
            showNotifications
            secondaryActions={
              <CustomizedDropdown
                containerClassName="mr10 mb5"
                variant="outline-dark"
                onChange={(value: any) => {
                  const history = this.props;
                  let workCenterType: WorkCenterType | null = null;
                  if (value === "generic") {
                    workCenterType = {
                      id: null,
                      name: "Generic",
                      binPlusDefinitionType: "BIN",
                    };
                  } else {
                    workCenterType = _.cloneDeep(
                      workCenterTypes.filter((wct: WorkCenterType) => {
                        return wct.id === value;
                      })[0]
                    );
                  }
                  history.history.push("/create-bin-plus-table/", {
                    workCenterType,
                  });
                }}
                list={createBinPlusTableDropdownList}
              />
            }
          />
        )}
        <Row className="p20">
          <Col>
            <h5 className={hideNavBar === true ? "d-none" : ""}>Bin+</h5>
            <DataGrid
              ref={(datagrid) => {
                this.bindefinitionMapsDatagrid = datagrid;
              }}
              style={{ marginTop: "20px", clear: "both" }}
              dataSource={binPlusTables}
              onEditingStart={(event: any) => {
                // eslint-disable-next-line no-param-reassign
                event.cancel = true;
                const history = this.props;
                history.history.push(
                  `/create-bin-plus-table/${event.data.id}`,
                  {
                    id: event.data.id,
                    workCenterType: event.data.workCenterType,
                  }
                );
              }}
              onRowRemoving={(row: any) => {
                this.onRowRemoved(row);
              }}
              hoverStateEnabled
              height="auto"
              columnChooser={{ mode: "select", enabled: true }}
              showBorders
              allowColumnReordering
              rowAlternationEnabled
              showColumnLines={false}
              columnResizingMode="widget"
              onSelectionChanged={getBinPlusTableSelection}
            >
              <GroupPanel visible />
              <Grouping autoExpandAll />
              <FilterRow visible />
              <Sorting mode="multiple" />
              {!hideNavBar ? (
                <Editing
                  useIcons
                  confirmDelete={false}
                  allowUpdating
                  allowDeleting
                  mode="row"
                />
              ) : (
                <Selection
                  mode="single"
                  selectAllMode="allPages"
                  showCheckBoxesMode="onClick"
                />
              )}
              <Scrolling mode="virtual" />
              {binPlusTableFields.map(
                (
                  item: { dataField: string; caption: string },
                  index: number
                ) => {
                  return (
                    <Column
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}`}
                      dataField={item.dataField}
                      visible={item.dataField !== "id"}
                      caption={item.caption}
                      cellRender={(e) => {
                        if (this.IsObject(e.data[item.dataField])) {
                          return e.data[item.dataField].name || "";
                        }
                        if (Array.isArray(e.data[item.dataField])) {
                          return e.data[item.dataField].length;
                        }
                        return e.text;
                      }}
                    />
                  );
                }
              )}
            </DataGrid>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BinPlusTables);
