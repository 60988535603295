import React from 'react';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface ITextboxProps {
  hint?: IHint;
  name: string;
  placeholder?: string;
  autoComplete?: 'off' | undefined;
  defaultValue?: string | undefined | number;
  onChange?: any;
  onBlur?: any;
  className?: string;
  block?: boolean;
  value?: string | number;
  readonly?: boolean;
  hidden?: boolean;
  containerClassName?: string;
  type?: 'text' | 'password' | 'number' | 'date' | 'datetime-local';
  // eslint-disable-next-line react/require-default-props
  style?: React.CSSProperties;
  setRef?: any;
  disabled?: boolean;
  step?: string;
  min?: string;
  onKeyDown?: (e: any) => void | undefined;
}

const Textbox = (props: ITextboxProps) => {
  const {
    hint,
    name,
    placeholder,
    autoComplete,
    defaultValue,
    onChange,
    onBlur,
    className,
    block,
    value,
    containerClassName,
    type,
    readonly,
    hidden,
    style,
    setRef,
    disabled,
    step,
    min,
    onKeyDown,
  } = props;
  return (
    <div className={`${block ? 'w-100' : ''} d-flex align-items-center justify-content-between ${containerClassName !== undefined ? containerClassName : ''}`}>
      <input
        // eslint-disable-next-line no-unneeded-ternary
        step={step}
        min={min}
        ref={setRef}
        disabled={disabled}
        style={style || undefined}
        readOnly={readonly}
        className={`${className}`}
        type={hidden ? 'hidden' : type}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={(e: any) => {
          if (onBlur !== undefined) {
            onBlur(e);
          }
        }}
      />
      <Hint
        hint={hint}
        variant="icon"
        className="flex-2"
      />
    </div>
  );
};

Textbox.defaultProps = {
  onKeyDown: undefined,
  autoComplete: undefined,
  defaultValue: undefined,
  onChange: undefined,
  onBlur: undefined,
  className: '',
  block: false,
  containerClassName: '',
  type: 'text',
  readonly: false,
  hidden: false,
  disabled: false,
};

export default Textbox;
