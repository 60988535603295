/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Label from '../../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../../wrapped-component/hint-controls/Textbox';
import { PATRule } from '../PATInterfaces';
import PATCreateRuleGrid from './pat-create-rule/PATCreateRuleGrid';

interface IPATRuleSetSettingsProps {
  searchCriteria: any,
  patRuleSetConfig : any,
  saveNewPatRule : (e: PATRule) =>void,
  patRuleSetNameHandler: (name: string) => void,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPATRuleSetSettingsState {
  patRuleSetName: string,
}

class PATRuleSetSettings extends Component<IPATRuleSetSettingsProps, IPATRuleSetSettingsState> {
  private textAreaRef: any = null;

  constructor(props:any) {
    super(props);

    this.state = {
      patRuleSetName: '',
    };
  }

  render() {
    const {
      patRuleSetConfig,
      saveNewPatRule,
      patRuleSetNameHandler,
      searchCriteria,
    } = this.props;
    const {
      patRuleSetName,
    } = this.state;

    return (
      <>
        <Row className="mt20">
          <Col lg={6}>
            <Label
              labelTitle="Pat Rule Set Name"
              labelPosition="left-middle"
              labelSize="30"
              fieldSize="70"
              errorSize="0"
            >
              <Textbox
                setRef={(ref: any) => {
                  this.textAreaRef = ref;
                }}
                defaultValue={patRuleSetConfig.patRuleSetName}
                autoComplete="off"
                name="name"
                placeholder="Rule Set Name"
                onBlur={() => patRuleSetNameHandler(this.textAreaRef.value)}
              />
            </Label>
          </Col>
        </Row>
        <PATCreateRuleGrid
          searchCriteria={searchCriteria}
          saveNewPatRule={saveNewPatRule}
          patRuleSetConfig={patRuleSetConfig}
        />
        <div className="mt10">
          <span className="color-warning mt-10 pt-400 font-italic">
            *Please save the policy after you have changed anything in the rules
          </span>
        </div>
      </>
    );
  }
}

export default PATRuleSetSettings;
