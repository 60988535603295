import React from 'react';
import { Spinner } from 'react-bootstrap';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface IFileInputProps {
  id?: string;
  hint?: IHint;
  name: string;
  className?: string;
  isFileUploadInProcess: boolean;
  onChange?: (event: any) => void;
}

const FileInput = (props: IFileInputProps) => {
  const {
    id,
    hint,
    name,
    className,
    onChange,
    isFileUploadInProcess,
  } = props;

  return (
    <div className="d-flex align-items-top justify-content-between">
      <input
        id={id}
        name={name}
        type="file"
        hidden
        onChange={onChange}
        multiple
        disabled={isFileUploadInProcess}
      />
      <label
        htmlFor={id}
        className={className}
      >
        {isFileUploadInProcess
          ? (
            <>
              {' '}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr10"
              />
              Uploading file(s)
            </>
          )
          : <>Browse files</>}
      </label>
      <Hint
        hint={hint}
        variant="icon"
        className="flex-2"
      />
    </div>
  );
};

FileInput.defaultProps = {
  id: undefined,
  hint: undefined,
  className: '',
  onChange: undefined,
};

export default FileInput;
