import React from 'react';
import {
  Modal, Container, Row, Col, Card,
} from 'react-bootstrap';
import './select-rule-type.scss';

import CreateGDBNRule from '../create-gdbn-rule/CreateGDBNRule';
import CreatePATRule from '../create-pat-rule/CreatePATRule';
import Heading from '../../wrapped-component/hint-controls/Heading';
import CreateSWMRule from '../create-swm-rule/CreateSWMRule';

const SelectRuleType = (props: any) => {
  const {
    show, hideCreateRule, closeRuleComponent, selectRule,
  } = props;

  return (
    <Modal
      show={show}
      onHide={hideCreateRule}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-none">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100 "
        >
          <p className="text-center">Create Rule</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="PAT"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="MVPAT"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="GDBN"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreateGDBNRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="GDBN-Z"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="SWM"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreateSWMRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="SPC"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="SBL"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
            <Col lg={3} className="mb20">
              <RuleTypeCard
                title="SYL"
                description="Some rule description comes here. It will be about two sentences."
                handleClick={() => {
                  selectRule(<CreatePATRule closeRuleComponent={closeRuleComponent} />);
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SelectRuleType;

const RuleTypeCard = (props: any) => {
  const { title, description, handleClick } = props;
  return (
    <Card onClick={handleClick} className="create-rule-card">
      <div className="create-rule-img-container margin-auto mt20" />
      <Card.Body>
        <Heading size={6} className="text-center">{title}</Heading>
        <p className="text-center">{description}</p>
      </Card.Body>
    </Card>
  );
};
