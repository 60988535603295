import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { CheckBox, ScrollView } from 'devextreme-react';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { UOMs } from './wafer-control-map';

export type DrawLayoutData = {
  ringSize: number,
  ringUOM: string,
  increment: number,
  incrementUOM: string,
  overlayReticle: boolean,
  markWaferCenter: boolean,
  drawLayoutPanResizeMode: DrawLayoutPanResizeMode,
  showReferenceReticle: boolean,
  showRing: boolean,
};

type DrawLayoutProps = {
  onDrawRing: () => void,
  onResetRing: () => void,
  onPanLeft: (ringOrWafer: DrawLayoutPanResizeMode) => void,
  onPanRight: (ringOrWafer: DrawLayoutPanResizeMode) => void,
  onPanUp: (ringOrWafer: DrawLayoutPanResizeMode) => void,
  onPanDown: (ringOrWafer: DrawLayoutPanResizeMode) => void,
  onInsertRowAbove: () => void,
  onInsertRowBelow: () => void,
  onInsertColumnLeft: () => void,
  onInsertColumnRight: () => void,
  onDeleteRowAbove: () => void,
  onDeleteRowBelow: () => void,
  onDeleteColumnLeft: () => void,
  onDeleteColumnRight: () => void,
  onCropDies: () => void,
  onRedrawDies: () => void,
  onChangeDrawLayout: (data: DrawLayoutData, callback?: any) => void,
  openReticleEditor?: (reticleSizeX: number, reticleSizeY: number) => void,
  onMarkWaferCenterChange: (value: boolean) => void,
  reticleGenerated?: boolean,
  drawLayout: DrawLayoutData,
  onOverlayReticleCheckBoxValueChanged: (value: boolean) => void,
  onShowReferenceReticleCheckBoxValueChanged: (value: boolean) => void,
};

// eslint-disable-next-line no-unused-vars
export enum DrawLayoutPanResizeMode { RING, WAFER }

type DrawLayoutState = {
  // formErrors: {[key: string] : any},
};

export default class DrawLayout extends React.Component<DrawLayoutProps, DrawLayoutState> {
  constructor(props: DrawLayoutProps) {
    super(props);
    this.state = {
      // formErrors: {},
    };
  }

  // eslint-disable-next-line no-unused-vars
  validate = (data: any) => {
    const errors: { [key: string] : any } = {};
    return errors;
  };

  render() {
    const {
      onChangeDrawLayout, drawLayout, onDrawRing, onResetRing, onPanLeft, onPanDown, onPanRight, onDeleteColumnLeft, onDeleteColumnRight, onRedrawDies, onMarkWaferCenterChange,
      onPanUp, onInsertRowAbove, onInsertRowBelow, onInsertColumnLeft, onInsertColumnRight, onDeleteRowAbove, onDeleteRowBelow, onCropDies,
      // eslint-disable-next-line no-unused-vars
      onOverlayReticleCheckBoxValueChanged, onShowReferenceReticleCheckBoxValueChanged,
    } = this.props;
    return (

      <div className="custom-form mt10" style={{ fontSize: '10px' }}>
        <ScrollView
          height="85vh"
          showScrollbar="onHover"
          scrollByThumb
          reachBottomText=""
        >
          <Heading size={5}>Ring</Heading>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="0"
            fieldSize="100"
            errorSize="100"
            labelTitle=""
            className="mt10"
          >
            <Label
              labelTitle="Ring Size"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <Textbox
                autoComplete="off"
                type="number"
                name="ringSize"
                value={drawLayout.ringSize}
                placeholder="0"
                onChange={(e: any) => { onChangeDrawLayout({ ...drawLayout, ringSize: +e.target.value }); }}
              />
            </Label>
            <Label
              labelTitle="UOM"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5 ml5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                list={UOMs}
                selectedValue={drawLayout.ringUOM || UOMs[0][0]}
                onChange={(value: any) => { onChangeDrawLayout({ ...drawLayout, ringUOM: value }); }}
              />
            </Label>
          </Label>
          <Row>
            <Col className="text-center">
              <Button onClick={() => { onDrawRing(); }} variant="clear" size="sm" className="w100 btn-config">
                Draw Ring
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onCropDies(); }} variant="clear" size="sm" className="w100 btn-config">
                Crop Dies
              </Button>
            </Col>
          </Row>
          <Row className="mt5 mb10">
            <Col className="text-center">
              <Button onClick={() => { onResetRing(); }} variant="clear" size="sm" className="w100 btn-config">
                Reset Ring
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onRedrawDies(); }} variant="clear" size="sm" className="w100 btn-config">
                Redraw Dies
              </Button>
            </Col>
          </Row>
          <Heading size={5}>Pan</Heading>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="0"
            fieldSize="100"
            errorSize="100"
            labelTitle=""
            className="mt10"
          >
            <Label
              labelTitle="Increment"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <Textbox
                autoComplete="off"
                type="number"
                name="increment"
                value={drawLayout.increment}
                placeholder="0"
                onChange={(e: any) => { onChangeDrawLayout({ ...drawLayout, increment: +e.target.value }); }}
              />
            </Label>
            <Label
              labelTitle="UOM"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5 ml5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                list={UOMs.concat([['die', 'die']])}
                selectedValue={drawLayout.incrementUOM || UOMs[0][0]}
                onChange={(value: any) => { onChangeDrawLayout({ ...drawLayout, incrementUOM: value }); }}
              />
            </Label>
          </Label>
          <Row>
            <Col>

              <div className="mt15">
                <CheckBox
                  className="mr15"
                  value={drawLayout.drawLayoutPanResizeMode === DrawLayoutPanResizeMode.RING}
                  onValueChange={() => { onChangeDrawLayout({ ...drawLayout, drawLayoutPanResizeMode: DrawLayoutPanResizeMode.RING }); }}
                />
                {' '}
                Ring
              </div>
              <div className="mt15">
                <CheckBox
                  className="mr15"
                  value={drawLayout.drawLayoutPanResizeMode === DrawLayoutPanResizeMode.WAFER}
                  onValueChange={() => { onChangeDrawLayout({ ...drawLayout, drawLayoutPanResizeMode: DrawLayoutPanResizeMode.WAFER }); }}
                />
                {' '}
                Wafer
              </div>
            </Col>
            <Col>
              <Row>
                <Col />
                <Col>
                  <Button onClick={() => { onPanUp(drawLayout.drawLayoutPanResizeMode); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowup" />
                  </Button>
                </Col>
                <Col />
              </Row>
              <Row>
                <Col className="mr0 pl40">
                  <Button onClick={() => { onPanLeft(drawLayout.drawLayoutPanResizeMode); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowleft" />
                  </Button>
                </Col>
                <Col className="ml0">
                  <Button onClick={() => { onPanRight(drawLayout.drawLayoutPanResizeMode); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowright" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col>
                  <Button onClick={() => { onPanDown(drawLayout.drawLayoutPanResizeMode); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowdown" />
                  </Button>
                </Col>
                <Col />
              </Row>
              {/* <Row className="mt10">
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-plus" />
                  </Button>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-minus" />
                  </Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Heading size={5}>Columns/Rows</Heading>
          <Row>
            <Col className="text-center">
              <Button onClick={() => { onInsertRowAbove(); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Row Above
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onInsertColumnLeft(); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Column Left
              </Button>
            </Col>
          </Row>
          <Row className="mt5 mb10">
            <Col className="text-center">
              <Button onClick={() => { onInsertRowBelow(); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Row Below
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onInsertColumnRight(); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Column Right
              </Button>
            </Col>
          </Row>
          <Row className="mb10">
            <Col className="text-center">
              <Button onClick={() => { onDeleteRowAbove(); }} variant="clear" size="sm" className="w150 btn-config">
                Delete Row Above
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onDeleteColumnLeft(); }} variant="clear" size="sm" className="w150 btn-config">
                Delete Column Left
              </Button>
            </Col>
          </Row>
          <Row className="mb10">
            <Col className="text-center">
              <Button onClick={() => { onDeleteRowBelow(); }} variant="clear" size="sm" className="w150 btn-config">
                Delete Row Below
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onDeleteColumnRight(); }} variant="clear" size="sm" className="w150 btn-config">
                Delete Column Right
              </Button>
            </Col>
          </Row>
          {/* <div className="mt15">
            <CheckBox
              className="mr15"
              value={drawLayout.overlayReticle}
              onValueChange={onOverlayReticleCheckBoxValueChanged}
            />
            {' '}
            Overlay Reticle
          </div>
          <div className="mt15">
            <CheckBox
              className="mr15"
              disabled={!drawLayout.overlayReticle}
              value={drawLayout.showReticleOffset}
              onValueChange={onShowReferenceReticleCheckBoxValueChanged}
            />
            {' '}
            Show Reticle Offset
          </div> */}
          <div className="mt15">
            <CheckBox
              className="mr15"
              value={drawLayout.markWaferCenter}
              onValueChange={(value: boolean) => {
                onChangeDrawLayout({ ...drawLayout, markWaferCenter: value }, () => {
                  onMarkWaferCenterChange(value);
                });
              }}
            />
            {' '}
            Wafer Center
          </div>
        </ScrollView>
      </div>
    );
  }
}
