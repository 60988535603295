import AppConstants from '../../../../constants.js';

const shortenRulesAppliedForParameterSettings = (data: any) => {
  const newData: any = [];
  if (data) {
    data.forEach((element: any) => {
      newData.push({
        Id: element.Id,
        Rule: element.Rule,
      });
    });
  }
  return newData;
};
const rulesAppliedDisplayValue = (data: any) => {
  let displayValue = '';
  if (data !== undefined && data.length > 0) {
    for (let i = 0; i < (data.length - 1); i += 1) {
      displayValue = `${displayValue + data[i].spcRuleType}_${data[i].spcRuleName}->`;
    }
    displayValue += `${data[(data.length - 1)].spcRuleType}_${data[(data.length - 1)].spcRuleName}`;
  }
  return displayValue;
};

const parameterOrBinDisplayValue = (data: any) => {
  return data.name
    ? `(${data.number}, ${data.name})`
    : `(${data.test_parameter_number}, ${data.test_parameter_name})`;
};

const calculateSPCParameterDisplayValue = (data: any) => {
  let displayValue = '';
  if (data !== undefined && data.length > 0) {
    for (let i = 0; i < (data.length - 1); i += 1) {
      displayValue = `${displayValue + data[i].serialNumber}, `;
    }
    displayValue = `${displayValue + data[data.length - 1].serialNumber}`;
  }
  return displayValue;
};

const parameterSettingMapper = (data: any) => {
  if (data.id === '') {
    return {
      SerialNumber: data.serialNumber,
      SPCParameterType: data.parameterType,
      TestParam: { Id: data.testParam },
      Bins: data.parameterType === 'Hard Bin' || data.parameterType === 'Soft Bin' ? data.parameterOrBin : null,
      PreDefinedAttribute: data.parameterType === 'Inline' ? data.parameterOrBin : null,
      UserDefinedAttribute: data.parameterType === 'Inline' ? data.parameterOrBin : null,
      DataLimitType: data.dataLimitType,
      UOM: data.uom || null,
      LowLimit: data.lowLimit || null,
      HighLimit: data.highLimit || null,
      MeasuredValue: data.measuredValue,
      MeasurePointBy: data.measurePointBy,
      GroupBy: data.groupBy,
      SortBy: data.sortBy,
      TriggerLimitViolation: data.triggerLimitViolation,
      RulesApplied: shortenRulesAppliedForParameterSettings(data.rulesApplied),
      RulesAppliedSequence: data.rulesAppliedName,
      Actions: data.actions,
      PointsForSeedLimit: data.pointsForSeedLimit,
      LRCDurationValue: data.LRCDurationValue || null,
      LRCDurationType: data.LRCDurationType || null,
      LRCExpression: data.LRCExpression || null,
      LRCuom: data.LRCuom || null,
      LRCOperator: data.LRCOperator || null,
      LRCValue: data.LRCValue || null,
      CreatedBy: { Id: AppConstants.user.Id },
    };
  }
  return {
    Id: data.id,
    SerialNumber: data.serialNumber,
    SPCParameterType: data.parameterType,
    TestParam: { Id: data.testParam },
    Bins: data.parameterType === 'Hard Bin' || data.parameterType === 'Soft Bin' ? data.parameterOrBin : null,
    PreDefinedAttribute: data.parameterType === 'Inline' ? data.parameterOrBin : null,
    UserDefinedAttribute: data.parameterType === 'Inline' ? data.parameterOrBin : null,
    DataLimitType: data.dataLimitType,
    UOM: data.uom || null,
    LowLimit: data.lowLimit || null,
    HighLimit: data.highLimit || null,
    MeasuredValue: data.measuredValue,
    MeasurePointBy: data.measurePointBy,
    GroupBy: data.groupBy,
    SortBy: data.sortBy,
    TriggerLimitViolation: data.triggerLimitViolation,
    RulesApplied: shortenRulesAppliedForParameterSettings(data.rulesApplied),
    RulesAppliedSequence: data.rulesAppliedName,
    Actions: data.actions,
    PointsForSeedLimit: data.pointsForSeedLimit,
    LRCDurationValue: data.LRCDurationValue || null,
    LRCDurationType: data.LRCDurationType || null,
    LRCExpression: data.LRCExpression || null,
    LRCuom: data.LRCuom || null,
    LRCOperator: data.LRCOperator || null,
    LRCValue: data.LRCValue || null,
    UpdatedBy: { Id: AppConstants.user.Id },
  };
};
const getSPCParameterIds = (data: any) => {
  const ids: any = [];
  if (data) {
    data.forEach((element: any) => {
      ids.push({ id: element.id });
    });
  }
  return ids;
};
const reportSettingMapper = (data: any) => {
  const mappedData = {
    Id: data.id,
    Name: data.name,
    ReportType: data.reportType,
    NumberOfYAxis: data.numberOfYAxis,
    ChartTitle: data.chartTitle,
    SPCParameters: getSPCParameterIds(data.SPCParameters),
    Header: data.header,
    EmailAlertType: data.emailAlertType,
    Email: data.email,
    StartTime: data.startTime,
    Interval: data.interval,
    Period: data.period,
  };
  if (!data.id) {
    return {
      ...mappedData,
      CreatedBy: { Id: AppConstants.user.Id },
    };
  }
  return {
    ...mappedData,
    UpdatedBy: { Id: AppConstants.user.Id },
  };
};
const groupByView = (data: any) => {
  let displayValue = '';
  if (data.groupBy !== undefined && data.groupBy !== null) {
    for (let i = 0; i < (data.groupBy.length - 1); i += 1) {
      displayValue = `${data.groupBy[i].columnName}/`;
    }
    displayValue += `${data.groupBy[(data.groupBy.length - 1)].columnName}`;
  }

  return displayValue;
};

const sortByView = (data: any) => {
  let displayValue = '';
  if (data.sortBy !== undefined && data.sortBy !== null) {
    for (let i = 0; i < (data.sortBy.length - 1); i += 1) {
      if (!data.sortBy[i].isDesc) {
        displayValue = `${displayValue}↑`;
      } else {
        displayValue = `${displayValue}↓`;
      }

      displayValue = `${displayValue + data.sortBy[i].selector}, `;
    }
    if (!data.sortBy[data.sortBy.length - 1].isDesc) {
      displayValue = `${displayValue}↑`;
    } else {
      displayValue = `${displayValue}↓`;
    }
    displayValue = `${displayValue + data.sortBy[data.sortBy.length - 1].selector}`;
  }
  return displayValue;
};

const loadSPCReports = (spcReports:any) => {
  const loadedReports:any = [];
  if (spcReports !== undefined) {
    spcReports.forEach((report:any) => {
      loadedReports.push({
        id: report.id,
        name: report.name,
        reportType: report.reportType,
        numberOfYAxis: report.numberOfYAxis,
        chartTitle: report.chartTitle,
        SPCParameters: loadSPCParameters(report.spcParameters),
        header: report.header ? report.header : 'Default Header', // 652bd93b-2ad1-4981-896b-d88848ffdb75',
        // todo: remove condition after 'Real Time' is transitioned to 'REAL_TIME'
        emailAlertType: report.emailAlertType === 'Real Time' ? 'REAL_TIME' : report.emailAlertType,
        email: report.email,
        startTime: report.startTime,
        interval: report.interval,
        period: report.period,
      });
    });
  }
  return loadedReports;
};

const loadSPCParameters = (spcParameters:any) => { // Todo: Update it for Bin and Inline data
  const loadedParameters:any = [];
  if (spcParameters !== undefined) {
    spcParameters.forEach((parameter:any) => {
      loadedParameters.push({
        id: parameter.id,
        serialNumber: parameter.serialNumber,
        parameterType: parameter.spcParameterType,
        parameterOrBin: parameterOrBinDisplayValue(parameter.testParam),
        testParam: parameter.testParam,
        dataLimitType: parameter.dataLimitType,
        uom: parameter.uom,
        lowLimit: parameter.lowLimit,
        highLimit: parameter.highLimit,
        measuredValue: parameter.measuredValue,
        measurePointBy: parameter.measurePointBy,
        groupBy: parameter.groupBy,
        sortBy: parameter.sortBy,
        triggerLimitViolation: parameter.triggerLimitViolation,
        rulesApplied: loadSPCRules(parameter.rulesApplied),
        rulesAppliedName: rulesAppliedDisplayValue(parameter.rulesApplied),
        actions: parameter.actions,
        pointsForSeedLimit: parameter.pointsForSeedLimit,
        // Limit Recalculation Cycle
        LRCDurationValue: parameter.lrcDurationValue,
        LRCDurationType: parameter.lrcDurationType,
        LRCExpression: parameter.lrcExpression,
        LRCuom: parameter.lrcUom,
        LRCOperator: parameter.lrcOperator,
        LRCValue: parameter.lrcValue,
      });
    });
  }
  return loadedParameters;
};

const loadSPCRules = (spcRules:any) => {
  const loadedRules:any = [];
  if (spcRules !== undefined) {
    spcRules.forEach((rule:any) => {
      loadedRules.push({
        Id: rule.id,
        spcRuleType: rule.spcRuleType,
        spcRuleName: rule.spcRuleName,
        spcRuleExpressions: rule.spcRuleExpressions,
        Name: rule.name,
        ShortDescription: '',
        Description: '',
        Version: rule.version,
        Rule: rule.rule,
      });
    });
  }
  return loadedRules;
};
export {
  parameterSettingMapper, rulesAppliedDisplayValue, reportSettingMapper, groupByView, sortByView,
  loadSPCReports, loadSPCParameters, loadSPCRules, parameterOrBinDisplayValue, calculateSPCParameterDisplayValue,
};
