/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Col } from 'react-bootstrap';
import DataGrid, {
  Column,
  CustomRule,
  Editing,
  EmailRule,
  Lookup,
  PatternRule,
  RequiredRule,
  Scrolling,
} from 'devextreme-react/data-grid';
import toast from 'CustomToast';
import dxPopup from 'devextreme/ui/popup';
import dataGridConstants from 'dataGridConstants';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import SPCParameterSettingsDropdown from './components-for-spc-reporting-settings/SPCParameterSettingsDropdown';
import { reportSettingMapper } from './SPCMapperUtils';
import { httpService } from '../../../../services/http.service';

class SPCReportingSettings extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      receivedParameterSettings: props.receivedParameterSettings,
      createdSPCReportSettings: props.spcReports,
    };
  }

  componentDidUpdate(prevProps: { receivedParameterSettings: any }) {
    const { receivedParameterSettings } = this.props;
    if (prevProps.receivedParameterSettings !== receivedParameterSettings) {
      this.setState({
        receivedParameterSettings,
      });
    }
  }

  updateToParent = (data: any) => {
    const { onUpdate } = this.props;
    const { createdSPCReportSettings } = this.state;
    const mappedData: any = reportSettingMapper(data);
    if (mappedData.Id) {
      httpService
        .updateSPCReportSettings({
          RuleJSON: JSON.stringify(mappedData).toString(),
        })
        .then((response: string) => {
          const index = createdSPCReportSettings.findIndex(
            (x: any) => x.id === data.id
          );
          createdSPCReportSettings[index] = data;
          this.setState(
            { createdSPCReportSettings },
            onUpdate(createdSPCReportSettings)
          );
          toast.success('SPC Report Setting updated successfully');
        })
        .catch((error: any) => {
          toast.error('SPC Report Setting add failed', error);
        });
    } else {
      httpService
        .saveSPCReportSettings({
          RuleJSON: JSON.stringify(mappedData).toString(),
        })
        .then((response: string) => {
          data.id = response;
          createdSPCReportSettings.push(data);
          this.setState(
            { createdSPCReportSettings },
            onUpdate(createdSPCReportSettings)
          );
          toast.success('SPC Report Setting added successfully');
        })
        .catch((error: any) => {
          toast.error('SPC Report Setting add failed', error);
        });
    }
  };

  customValidationCallback = (event: any) => {
    if (event.data.emailAlertType === 'SCHEDULED_COLLECTIVE_DASHBOARD') {
      return (
        event.value === undefined || event.value === null || event.value === ''
      );
    }
    return true;
  };

  render() {
    const { onUpdate } = this.props;
    const { createdSPCReportSettings, receivedParameterSettings } = this.state;
    return (
      <div className="border-all pl10 pr10 pb20 background-color-light">
        <Col lg={12} className="mt20">
          <Heading size={4}>SPC Reporting Settings</Heading>
        </Col>
        <div className="w-100">
          <DataGrid
            {...dataGridConstants.defaultOptions}
            height={350}
            dataSource={createdSPCReportSettings}
            onContentReady={(e: any) => {
              if (!e.component.__headerFilterPopupAttached) {
                e.component.__headerFilterPopupAttached = true;

                const headerFilter = e.element.querySelector('.dx-header-filter-menu');
                new dxPopup(headerFilter, { container: headerFilter });
              }
            }}
            onEditorPreparing={(event: any) => {
              if (event.parentType === 'dataRow'
                && (event.dataField === 'reportType' || event.dataField === 'numberOfYAxis' || event.dataField === 'emailAlertType' || event.dataField === 'period')) {
                event.editorOptions = {
                  ...event.editorOptions,
                  dropDownOptions: {
                    onShowing(args:any) {
                      args.component.option({
                        container: event.component.element().querySelector('.dx-gridbase-container'),
                        hideOnParentScroll: false
                      });
                    }
                  }
                };
              }
              if (
                event.dataField === 'startTime'
                || event.dataField === 'interval'
                || event.dataField === 'period'
              ) {
                // todo: remove 'Real Time' condition once the transition to 'REAL_TIME' is complete
                event.editorOptions.disabled = event.row.data.emailAlertType === 'REAL_TIME'
                  || event.row.data.emailAlertType === 'Real Time';
              }
            }}
            onRowInserted={(event: any) => {
              // remove auto added entry containing __KEY__ from the array
              const index = createdSPCReportSettings.findIndex(
                (x: any) => x.__KEY__ !== undefined
              );
              if (index > -1) {
                createdSPCReportSettings.splice(index, 1);
                this.setState({ createdSPCReportSettings });
              }
              const data = {
                id: undefined,
                name: event.data.name,
                reportType: event.data.reportType,
                numberOfYAxis: event.data.numberOfYAxis,
                chartTitle: event.data.chartTitle,
                SPCParameters: event.data.SPCParameters,
                header: event.data.header
                  ? event.data.header
                  : 'Default Header', // 652bd93b-2ad1-4981-896b-d88848ffdb75',
                emailAlertType: event.data.emailAlertType,
                email: event.data.email,
                startTime: event.data.startTime,
                interval: event.data.interval,
                period: event.data.period,
              };
              this.updateToParent(data);
            }}
            onRowRemoved={(event: any) => {
              createdSPCReportSettings.forEach((x: any, index: number) => {
                if (x.id === event.data.id) {
                  createdSPCReportSettings.splice(index, 1);
                  this.setState(createdSPCReportSettings);
                }
              });
              onUpdate(createdSPCReportSettings);
              toast.info('SPC Report Setting deleted successfully');
            }}
            onRowUpdated={(event: any) => {
              const index = createdSPCReportSettings.findIndex((x: any) => x.id === event.data.id)
              if (index !== -1){
                createdSPCReportSettings[index].name = event.data.name;
                createdSPCReportSettings[index].reportType = event.data.reportType;
                createdSPCReportSettings[index].numberOfYAxis = event.data.numberOfYAxis;
                createdSPCReportSettings[index].chartTitle = event.data.chartTitle;
                createdSPCReportSettings[index].SPCParameters = event.data.SPCParameters;
                createdSPCReportSettings[index].header = event.data.header;
                createdSPCReportSettings[index].emailAlertType = event.data.emailAlertType;
                createdSPCReportSettings[index].email = event.data.email;
                createdSPCReportSettings[index].startTime = event.data.startTime;
                createdSPCReportSettings[index].interval = event.data.interval;
                createdSPCReportSettings[index].period = event.data.period;
                this.updateToParent(createdSPCReportSettings[index]);
              }
            }}
          >
            <Editing
              {...dataGridConstants.editingOptions}
              allowAdding
              allowUpdating
              allowDeleting
            />
            <Scrolling {...dataGridConstants.scrollingOptions} />
            <Column
              dataField="name"
              caption="Report Group Name"
              dataType="string"
              visible
            >
              <RequiredRule />
            </Column>
            <Column
              dataField="reportType"
              caption="Report Type"
              dataType="string"
              visible
            >
              <Lookup
                dataSource={[
                  //   { caption: 'Pareto Report', dataField: 'PARETO_REPORT' },
                  //   { caption: 'Line Report', dataField: 'LINE_REPORT' },
                  { caption: 'Trend Report', dataField: 'TREND_REPORT' },
                ]}
                displayExpr="caption"
                valueExpr="dataField"
              />
              <RequiredRule />
            </Column>
            <Column
              dataField="numberOfYAxis"
              caption="No. of Y-Axis"
              dataType="string"
              visible
            >
              <Lookup
                dataSource={[{ caption: '1', dataField: '1' }]}
                displayExpr="caption"
                valueExpr="dataField"
              />
              <RequiredRule />
            </Column>
            <Column
              dataField="chartTitle"
              caption="Chart Title"
              dataType="string"
              visible
            >
              <RequiredRule />
            </Column>
            <Column
              dataField="SPCParameters"
              caption="SPC Parameter Settings Included"
              allowSorting={false}
              editCellComponent={SPCParameterSettingsDropdown}
              calculateDisplayValue={(rowData: any) => (rowData.SPCParameters
                ? rowData.SPCParameters.map((x: any) => x.serialNumber).join(', ') : '')}
            >
              <Lookup
                dataSource={receivedParameterSettings}
                displayExpr="serialNumber"
                valueExpr="id"
              />
              <RequiredRule />
            </Column>
            <Column
              dataField="header"
              caption="Header Details"
              dataType="string"
              visible
              allowEditing={false}
            >
              {/* <RequiredRule /> */}
            </Column>
            <Column
              dataField="emailAlertType"
              caption="Email Alert Type"
              dataType="string"
              visible
              setCellValue={(newData: any, value: any) => {
                newData.emailAlertType = value;
              }}
            >
              <Lookup
                dataSource={[
                  { caption: 'Real Time', dataField: 'REAL_TIME' },
                  // { caption: 'Scheduled Collective Dashboard', dataField: 'SCHEDULED_COLLECTIVE_DASHBOARD' },
                ]}
                displayExpr="caption"
                valueExpr="dataField"
              />
              <RequiredRule />
            </Column>
            <Column dataField="email" caption="Email" dataType="string">
              <RequiredRule />
              <EmailRule
                ignoreEmptyValue={false}
                message="Please enter a valid email address"
                type="email"
              />
            </Column>
            <Column
              dataField="startTime"
              caption="Start Time"
              dataType="datetime"
            >
              <CustomRule
                validationCallback={this.customValidationCallback}
                message="Start Time is required for Dashboard Email Alert Type"
              />
            </Column>
            <Column dataField="interval" caption="Interval" dataType="string">
              <CustomRule
                validationCallback={this.customValidationCallback}
                message="Interval is required for Dashboard Email Alert Type"
              />
              <PatternRule
                message="Please enter a number between 1 and 999999999"
                pattern={/^[1-9]\d{0,8}$/}
              />
            </Column>
            <Column dataField="period" caption="Period" dataType="string">
              <Lookup
                dataSource={[
                  { caption: 'Day', dataField: 'Day' },
                  { caption: 'Week', dataField: 'Week' },
                  { caption: 'Month', dataField: 'Month' },
                ]}
                displayExpr="caption"
                valueExpr="dataField"
              />
              <CustomRule
                validationCallback={this.customValidationCallback}
                message="Period is required for Dashboard Email Alert Type"
              />
            </Column>
          </DataGrid>
        </div>
      </div>
    );
  }
}
export default SPCReportingSettings;
