/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import WebGLUtils from './Utils';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';

const initializeEvents = (
  waferMapInstance: WebGLUtils,
  gl: WebGL2RenderingContext,
  mouseMove: (startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils, isMouseDown: boolean) => void,
  mouseUp: ((startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils) => void) | null,
  zoom: any,
) => {
  const { canvas } = gl;
  const { tooltipEL } = waferMapInstance.waferMapVariables;
  let isDown = false;
  let startX: number;
  let startY: number;
  let endX: number;
  let endY: number;
  canvas.addEventListener('mouseup', (e: any) => {
    if (e.which !== 1) return;
    isDown = false;
    endX = e.offsetX;
    endY = e.offsetY;
    if (!mouseUp) return;
    mouseUp(startX, startY, endX, endY, waferMapInstance);
  });
  canvas.addEventListener('mouseleave', () => {
    isDown = false;
    if (tooltipEL) tooltipEL.instance.hide();
  });
  canvas.addEventListener('mousedown', (e: any) => {
    if (e.which !== 1) return;
    startX = e.offsetX;
    startY = e.offsetY;
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.WAFER_MAP_DRAG_STARTED, {}, waferMapInstance.waferMapVariables.keyIndex.toString());
    isDown = true;
  });
  canvas.addEventListener('mousemove', (e: any) => {
    if (isDown) waferMapInstance.waferMapVariables.isMouseMoving = true;
    endX = e.offsetX;
    endY = e.offsetY;
    mouseMove(startX, startY, endX, endY, waferMapInstance, isDown);
    waferMapInstance.waferMapVariables.isMouseMoving = false;
  });
  canvas.addEventListener('mousewheel', () => {
    if (zoom) {
      // zoom(e.deltaY);
    }
  });
};

export default initializeEvents;
