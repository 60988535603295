import React from 'react';
import Button from '../hint-controls/Button';

class AdvanceFilter extends React.Component<any, any> {
  advanceFilterInput: HTMLInputElement | null = null;

  private FilterApplied = false;

  constructor(props: any) {
    super(props);
    this.onClearAdvancedFilter = this.onClearAdvancedFilter.bind(this);
    this.advancedFilter = this.advancedFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.state = {
      advancedFilterFlag: false,
    };
  }

  onClearAdvancedFilter() {
    if (this.advanceFilterInput !== null) {
      this.advanceFilterInput.value = '';
    }
    const { onClearAdvancedFilter } = this.props;
    if (onClearAdvancedFilter !== undefined) {
      onClearAdvancedFilter();
    }
    const { advancedFilterFlag } = this.state;
    this.setState({
      advancedFilterFlag: !advancedFilterFlag,
    });
  }

  toggleFilter() {
    const { advancedFilterFlag } = this.state;
    this.setState({
      advancedFilterFlag: !advancedFilterFlag,
    });
  }

  advancedFilter() {
    const { advancedFilter } = this.props;
    if (advancedFilter !== undefined) {
      if (this.advanceFilterInput !== null) {
        advancedFilter(this.advanceFilterInput.value);
      }
    }
    this.FilterApplied = true;
    // const { advancedFilterFlag } = this.state;
    // this.setState({
    //   advancedFilterFlag: false,
    // });
  }

  render() {
    let { advancedFilterFlag } = this.state;
    const { advancedFilterInput } = this.props;
    if (!advancedFilterFlag) {
      advancedFilterFlag = advancedFilterInput.length > 0;
    }
    return (
      <div className="pl0 d-flex align-items-center justify-content-between pt5 pb5 col-12 mb10">
        {
          advancedFilterFlag ? (
            <>
              <span className="label mr10 text-nowrap">Advanced filter</span>
              <input
                ref={(inputEL) => {
                  this.advanceFilterInput = inputEL;
                }}
                type="text"
                className="form-control mr10"
                name="advancedFilter"
                defaultValue={advancedFilterInput}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    this.advancedFilter();
                  }
                }}
              />
            </>
          ) : null
        }
        <Button
          visible={!advancedFilterFlag}
          onClick={this.toggleFilter}
          variant="outline-gray "
          className="border-all background-color-light"
          size="lg"
        >
          Advanced Filter

        </Button>
        <Button
          className="mr10 border-all"
          variant="primary "
          visible={advancedFilterFlag}
          onClick={this.advancedFilter}
          size="lg"
        >
          Apply

        </Button>
        <Button
          variant="outline-gray"
          visible={advancedFilterFlag}
          onClick={this.onClearAdvancedFilter}
          className="border-all background-color-light "
          size="lg"
        >
          Cancel

        </Button>
      </div>
    );
  }
}

export default AdvanceFilter;
