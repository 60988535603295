// eslint-disable-next-line no-unused-vars
import { DieSubView, WaferMapData, WaferMapTestData } from './wafer-map/web-gl-utils/Types';

class WaferData {
  waferMapTestData: WaferMapTestData;

  dieSubView: DieSubView;

  constructor(waferData: WaferMapTestData, waferSubViewData: DieSubView) {
    this.waferMapTestData = waferData;
    this.dieSubView = waferSubViewData;
  }

  dataGenerator = () => {
    const dataSource: WaferMapData[] = [];
    const data: WaferMapTestData = [];
    let num = 0;
    for (let i = 0; i < this.waferMapTestData.length; i += 1) {
      data.push([]);
      for (let j = 0; j < this.waferMapTestData[i].length; j += 1) {
        if (this.waferMapTestData[i][j]) {
          const dieData = { ...this.waferMapTestData[i][j] };
          data[i].push(dieData);
          dataSource.push(dieData!);
          dataSource[dataSource.length - 1]!.row = i;
          dataSource[dataSource.length - 1]!.col = j;
          dataSource[dataSource.length - 1]!.dieNumber = num;
          num += 1;
        } else {
          data[i].push(null);
        }
      }
    }
    return { dataSource, data };
  };
}

export default WaferData;
