import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Checkbox from 'components/wrapped-component/hint-controls/Checkbox';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { httpService } from '../../../../services/http.service';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import { addErrorClasses } from '../../../template-component/form-error-label/ErrorLabel';
import { IFileFilter, IPolicyStep, ISourceLocation } from '../../../../interfaces';
import LocationTypeValidationButton from './LocationTypeValidationButton';
import DetectDuplicateFiles from './DetectDuplicateFiles';
import FileFilterOptions from './FileFilterOptions';

interface ILocationTypeProps {
  updateSourceLocation: (sourceLocation: any) => void;
  sourceLocation: ISourceLocation;
  policySteps: IPolicyStep[];
  errors: any;
  touched: any;
}

const LocationType = (props: ILocationTypeProps) => {
  const { sourceLocation } = props;

  const [isValidationInProcess, setIsValidationInProcess] = useState(false);
  const [isSourceLocationPolicyStep, setIsSourceLocationPolicyStep] = useState(false);

  const validateLocation = async () => {
    setIsValidationInProcess(true);
    const response = await httpService.validateLocationCredentials([{
      locationType: sourceLocation.type,
      host: sourceLocation.ftpAddress,
      username: sourceLocation.ftpUsername,
      password: sourceLocation.ftpPassword,
      container: sourceLocation.container,
    }]);
    setIsValidationInProcess(false);
    if (response[0].isValidated) toast.success('Validation successful.');
    else toast.error('Credentials are incorrect');
  };

  const {
    updateSourceLocation, policySteps, errors, touched,
  } = props;

  const updateDetectDuplicateFiles = (detectDuplicateFiles: any) => {
    sourceLocation.detectDuplicates = detectDuplicateFiles;
    updateSourceLocation(sourceLocation);
  }
  const updateFileFilterOptions = (fileFilterOptions: IFileFilter[]) => {
    sourceLocation.fileFilterOptions = fileFilterOptions;
    updateSourceLocation(sourceLocation);
  };

  return (
    <div>
      <div className="custom-form-inline label-left">
        <span className="label flex-30">Location Type</span>
        <div className="flex-70 d-flex">
          <div className="flex-90 mr5">
            <CustomizedDropdown
              full
              variant="clear"
              selectedValue={sourceLocation.type}
              list={[['S3', 'Cloud Storage'], ['ftp', 'ftp'], // ['Local', 'Local'],
                ['PolicyStep', 'Policy Step Output']]}
              onChange={(value) => {
                sourceLocation.type = value;
                if (value === 'PolicyStep') setIsSourceLocationPolicyStep(true);
                else setIsSourceLocationPolicyStep(false);
                updateSourceLocation(sourceLocation);
              }}
            />
          </div>

          <LocationTypeValidationButton
            isValidationInProcess={isValidationInProcess}
            isSourceLocationPolicyStep={isSourceLocationPolicyStep}
            validateLocation={validateLocation}
          />

        </div>
      </div>

      {
        sourceLocation.type === 'S3' && (
          <div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Source Container</span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="yw-test-files"
                  defaultValue={sourceLocation.container}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event:any) => {
                    sourceLocation.container = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Directory Path</span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="folder/Subfolder/"
                  defaultValue={sourceLocation.folderPath}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event:any) => {
                    sourceLocation.folderPath = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="ml-30">
              <Checkbox
                defaultChecked={sourceLocation.scanSubDirectories}
                onChange={(e: any) => {
                  sourceLocation.scanSubDirectories = e.target.checked;
                  updateSourceLocation(sourceLocation);
                }}
              >
                Scan Sub-Directories
              </Checkbox>
            </div>
            <DetectDuplicateFiles updateDetectDuplicateFiles={updateDetectDuplicateFiles} detectDuplicateFiles={sourceLocation.detectDuplicates} />
            <div className="my-2 border p-2">
              <div className="d-flex flex-row">
                <div className="mr-auto">
                  <Checkbox
                    defaultChecked={sourceLocation.fileFilterOptionsCheck}
                    onChange={(e: any) => {
                      sourceLocation.fileFilterOptionsCheck = e.target.checked;
                      updateSourceLocation(sourceLocation);
                    }}
                  >
                    <b className="text-dark">File Filter Options</b>
                  </Checkbox>
                </div>
                {
                  sourceLocation.fileFilterOptionsCheck && (
                    <div>
                      <Checkbox
                        defaultChecked={sourceLocation.fileFilterCaseSensitive}
                        onChange={(e: any) => {
                          sourceLocation.fileFilterCaseSensitive = e.target.checked;
                          updateSourceLocation(sourceLocation);
                        }}
                      >
                        Case Sensitive
                      </Checkbox>
                    </div>
                  )
                }
              </div>
              {
                sourceLocation.fileFilterOptionsCheck && (
                  <FileFilterOptions
                    updateFileFilterOptions={updateFileFilterOptions}
                    fileFilterOptions={sourceLocation.fileFilterOptions}
                  />
                )
              }
            </div>
          </div>
        )
      }
      {
        sourceLocation.type === 'ftp' && (
          <div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Ftp Address</span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="ftp://yieldwerx.com"
                  defaultValue={sourceLocation.ftpAddress}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event: any) => {
                    sourceLocation.ftpAddress = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Ftp Username</span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="yw-user"
                  defaultValue={sourceLocation.ftpUsername}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event: any) => {
                    sourceLocation.ftpUsername = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Ftp Password</span>
              <div className="flex-70">
                <Textbox
                  type="password"
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="yw-password"
                  defaultValue={sourceLocation.ftpPassword}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event: any) => {
                    sourceLocation.ftpPassword = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Folder to transfer files from</span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="/files"
                  defaultValue={sourceLocation.folderPath}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event: any) => {
                    sourceLocation.folderPath = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="ml-30">
              <Checkbox
                defaultChecked={sourceLocation.scanSubDirectories}
                onChange={(e: any) => {
                  sourceLocation.scanSubDirectories = e.target.checked;
                  updateSourceLocation(sourceLocation);
                }}
              >
                Scan Sub-Directories
              </Checkbox>
            </div>
            <DetectDuplicateFiles updateDetectDuplicateFiles={updateDetectDuplicateFiles} detectDuplicateFiles={sourceLocation.detectDuplicates} />
            <div className="my-3 border p-2">
              <div className="row">
                <div className="col-7 col-md-5">
                  <Checkbox
                    defaultChecked={sourceLocation.fileFilterOptionsCheck}
                    onChange={(e: any) => {
                      sourceLocation.fileFilterOptionsCheck = e.target.checked;
                      updateSourceLocation(sourceLocation);
                    }}
                  >
                    <b className="text-dark">File Filter Options</b>
                  </Checkbox>
                </div>
                {
                  sourceLocation.fileFilterOptionsCheck && (
                    <div className="col-5">
                      <Checkbox
                        defaultChecked={sourceLocation.fileFilterCaseSensitive}
                        onChange={(e: any) => {
                          sourceLocation.fileFilterCaseSensitive = e.target.checked;
                          updateSourceLocation(sourceLocation);
                        }}
                      >
                        Case Sensitive
                      </Checkbox>
                    </div>
                  )
                }
              </div>
              {
                sourceLocation.fileFilterOptionsCheck && (
                  <FileFilterOptions
                    updateFileFilterOptions={updateFileFilterOptions}
                    fileFilterOptions={sourceLocation.fileFilterOptions}
                  />
                )
              }
            </div>
          </div>
        )
      }
      {
        sourceLocation.type === 'Local' && (
          <div>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Directory Path </span>
              <div className="flex-70">
                <Textbox
                  autoComplete="off"
                  name="additionalEmail"
                  placeholder="/files"
                  defaultValue={sourceLocation.folderPath}
                  className={`${addErrorClasses(
                    errors.additionalEmail,
                    touched.additionalEmail,
                  )}`}
                  onBlur={(event: any) => {
                    sourceLocation.folderPath = event.target.value;
                    updateSourceLocation(sourceLocation);
                  }}
                />
              </div>
            </div>
            <div className="ml-30">
              <Checkbox
                defaultChecked={sourceLocation.scanSubDirectories}
                onChange={(e: any) => {
                  sourceLocation.scanSubDirectories = e.target.checked;
                  updateSourceLocation(sourceLocation);
                }}
              >
                Scan Sub-Directories
              </Checkbox>
            </div>
            <DetectDuplicateFiles updateDetectDuplicateFiles={updateDetectDuplicateFiles} detectDuplicateFiles={sourceLocation.detectDuplicates} />
          </div>
        )
      }
      {
        sourceLocation.type === 'PolicyStep' && (
          <div className="custom-form-inline label-left">
            <span className="label flex-30">Source</span>
            <div className="flex-70">
              <CustomizedDropdown
                full
                variant="clear"
                selectedValue={sourceLocation.policyStep}
                list={[['Default', 'Previous Step Output'], ...policySteps.map((x: any) => [x.OutputReference, x.OutputReference])]}
                onChange={(value: any) => {
                  sourceLocation.policyStep = value;
                  updateSourceLocation(sourceLocation);
                }}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LocationType;
