import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { IComparisonGraphResponseData } from '../../../../interfaces';

const WAFER_WIDTH_WITHOUT_RADAR = 438;

interface IComparisonWaferHeaderProps {
  comparisonGraphResponseData: IComparisonGraphResponseData,
}

const ComparisonWaferHeader = (props: IComparisonWaferHeaderProps) => {
  const {
    comparisonGraphResponseData,
  } = props;
  const readOnlyInformation = [
    { field: '', label: 'Wafer ID / Probe Count' },
    { field: comparisonGraphResponseData.unchangedDiePercentage, label: 'Unchanged Die %' },
    { field: `${comparisonGraphResponseData.inputWafers.baseWafer.key}/${comparisonGraphResponseData.inputWafers.baseWafer.probeCount}`, label: 'Base' },
    { field: comparisonGraphResponseData.passToFailCount, label: 'Pass to Fail Count' },
    { field: `${comparisonGraphResponseData.inputWafers.nonBaseWafer.key}/${comparisonGraphResponseData.inputWafers.nonBaseWafer.probeCount}`, label: 'Non Base' },
    { field: comparisonGraphResponseData.failToPassCount, label: 'Fail to Pass Count' },
  ];

  return (
    <>
      <div
        className="pr10 mb5 pb5 break-bottom"
        style={{ width: `${WAFER_WIDTH_WITHOUT_RADAR}px` }}
      >
        <h4 className="mb10 text-center">Bin Comparison Report</h4>
        <Row>
          {readOnlyInformation.map((fieldObject) => ([fieldObject.field]
            ? (
              <Col lg={6} className="mb2 break-right">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="color-disabled-color">
                    {fieldObject.label}
                    :
                  </span>
                  <span>
                    <b>
                      {[fieldObject.field]}
                    </b>
                  </span>
                </div>
              </Col>
            ) : <></>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ComparisonWaferHeader;
