/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import {
  IDrilledGraphData, ISelectionCriteriaData, IExpressionsList, IGenericDetailedReportRequestObject, EntityType,
} from 'interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Row, Col, Tab, Tabs,
} from 'react-bootstrap';
import _ from 'lodash';

import '../individual-reports.scss';
import { toTitleCase, ReportTypeConstants as ReportType } from 'GeneralUtils';
import {
  IViewMode,
  ICustomizedReportRowBaseProps,
  AggregateFunctionNames,
  SeriesTypeNames,
  ICustomizedReportCollectionTypes,
  ICustomizedReportGraphTab,
  IError,
} from './customized-report-helpers/CustomizedReportInterfaces';
import { IGraphDataHelper, ILegendDataHelper } from './customized-report-helpers/CustomizedReportHelper';
import { IActorDetails } from './CustomizedReportsCollection';
import PubSubBinder, { ActionType } from '../PublishSubscribeBinder';
import { GraphingUtils } from './customized-report-utils/GraphingUtils';
import { ReportCollectionPreferences, REPORT_TYPE } from './customized-report-helpers/CustomizedReportMapping';
import { IPowerViewProgressDriver } from './PowerViewProgressBar';

export interface ICustomizedDataSummaryState {
  viewMode: IViewMode;
  showLegend: boolean;
  isLegendPinned: boolean;
  showReportRow: boolean;
  aggredateFunction: AggregateFunctionNames;
  seriesType: SeriesTypeNames;
  showModal?:boolean;
  testParameterIndex?: number[];
  reportPublisherId: string;
  limitSettingsObj?: any;
  errors: IError[];
  isGeneratedOnce: boolean;
}
export interface ICustomizedDataSummaryProps extends ICustomizedReportRowBaseProps {
  report : IActorDetails;
  legend : IActorDetails;
  defaultSelectionCriteriaData : ISelectionCriteriaData[];
  galleryData: IDrilledGraphData[];
  type: ICustomizedReportCollectionTypes;
  binPlusDefinitions: [];
  waferGalleryFilters: IExpressionsList[];
  hideReportRow?: () => void;
  config: { [key: string]: any };
  rulePayLoad?: string;
  testParameterIndex: number[];
}

class CustomizedDataSummary extends React.Component<ICustomizedDataSummaryProps, ICustomizedDataSummaryState> {
  private graphHelper : IGraphDataHelper | undefined = undefined;

  private legendHelper : ILegendDataHelper | undefined = undefined;

  private pubSubBinder = PubSubBinder();

  private customizedDataSummaryGraphRef: any;

  preferences = {
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    autoLoad: true,
    allowDefaultGrouping: true,
  };

  constructor(props:ICustomizedDataSummaryProps) {
    super(props);
    // this.customizedDataSummaryRef = React.createRef();
    const {
      report, legend, type, groupingSortingListStore,
    } = this.props;

    const reportPreferences = this.preferences;

    this.state = {
      showLegend: false,
      isLegendPinned: false,
      showReportRow: reportPreferences.autoLoad,
      viewMode: type && type === 'SIMULATE_POLICY' ? 'DETAILED' : 'COMBINED',
      aggredateFunction: reportPreferences.allowDefaultGrouping && groupingSortingListStore.grouping.length > 0 ? 'AVG' : null,
      seriesType: 'SEPARATE',
      showModal: false,
      testParameterIndex: [0, 1],
      reportPublisherId: '',
      limitSettingsObj: {},
      errors: [],
      isGeneratedOnce: false,
    };
  }

  componentDidMount() {
    const { report } = this.props;
    let { reportPublisherId } = this.state;
    reportPublisherId = this.pubSubBinder.RegisterActor(
      report.actor,
      this.reportSubscriptionListener,
      report.actor.includes(ReportType.PARAMETRIC_FAILURE),
    );
    this.setState({ reportPublisherId });
  }

  clearInteractionsFromCustomizedReportGraph = () => {
    return null;
  };

  updateState = (stateData:any) => {
    this.setState(stateData);
  };

  reportSubscriptionListener = (action: ActionType, params?:any) => {
    const { viewMode } = this.state;

    if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS' || action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
      this.setState(() => {
        let handler = null;
        if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS') {
          handler = 'x';
        } else if (action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
          handler = 'y';
        }
        if (handler !== null) {
          this.forceUpdate();
        }
      });
    }
  };

  getRequestObject = (viewMode: IViewMode) => {
    const {
      selectedBinPlusDefinition,
      parseFilter,
      selectionStore,
      groupingSortingListStore,
      reportSessionId,
      report,
      config,
      testParameterIndex,
    } = this.props;
    const {
      aggredateFunction,
      seriesType,
    } = this.state;
    const data: IGenericDetailedReportRequestObject = {
      scwData: selectionStore.selections.map((item) => ({
        entityType: item.controlType as EntityType,
        values: item.values.map((val) => val.id),
      })),
      filters: JSON.stringify(parseFilter(selectionStore)),
      binPlusDefId: selectedBinPlusDefinition.id,
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: ReportCollectionPreferences[report.actor]?.useAggregateFunctions ? aggredateFunction : null,
      seriesType,
      config,
      reportType: report.actor,
      testParameterIndex,
      isSoftBin: true,
    };
    return data;
  };

  getReportColumnWidth = () => {
    const { showLegend, isLegendPinned } = this.state;

    if (isLegendPinned) {
      return 12;
    }
    if (showLegend) {
      return 8;
    }

    return 12;
  };

  onChangeReportConfigValues = (keyValuePairs: { key: string, value: any }[]) => {
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.config[keyValuePairs[i].key] = keyValuePairs[i].value;
    }
  };

  getSupportingComponents = (callback:any) => {
    if (callback) {
      return callback(this.props, this.state, this, this.updateState);
    }
    return <></>;
  };

  getTabsComponent = (callback:any) => {
    const viewMode = 'COMBINED';
    const { errors } = this.state;
    const tabs : any[] = [];
    if (callback) {
      tabs.push(callback(this.getRequestObject(viewMode), this.props).map((tabItem:ICustomizedReportGraphTab) => (
        <Tab
          className="pt20"
          eventKey={tabItem.key}
          title={tabItem.title}
          mountOnEnter
        >
          {tabItem.component}
        </Tab>
      )));
    }
    const graphingUtil = new GraphingUtils();
    if (errors.length > 0) {
      tabs.push(
        <Tab
          tabClassName="w200"
          className="pt20"
          eventKey="errors"
          title={(() => {
            const errorsCount = errors.filter((x) => x.type === 'DATA_ERROR' || x.type === 'SYSTEM_ERROR').length;
            const warningsCount = errors.filter((x) => x.type === 'WARNING').length;

            return (
              <div className="d-flex align-items-center justify-content-between">
                <div className="flex-50 d-flex align-items-center justify-content-between pr10 mr10 border-right">
                  <div className="flex-20">
                    <FontAwesomeIcon
                      className="color-danger"
                      size="sm"
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-40 pl4">Errors</div>
                  <div className="flex-40 text-right pl4">{errorsCount}</div>
                </div>
                <div className="flex-50 d-flex align-items-center justify-content-between">
                  <div className="flex-20">
                    <FontAwesomeIcon
                      className="color-warning"
                      size="sm"
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-40 pl4">Warnings</div>
                  <div className="flex-40 text-right pl4">{warningsCount}</div>
                </div>
              </div>
            );
          })()}
          mountOnEnter
        >
          {graphingUtil.generateErrorsTab(errors)}
        </Tab>,
      );
    }
    return tabs;
  };

  populateErrors = (errorsReceived: IError[]) => {
    const { errors } = this.state;
    let updatedErrors = _.cloneDeep(errors);
    if (errors.length === 0 && errorsReceived.length !== 0) {
      updatedErrors = errorsReceived;
      this.setState({ errors: updatedErrors });
    } else {
      errorsReceived.forEach((errorReceived: IError) => {
        if (!(errors.some((error) => JSON.stringify(error) === JSON.stringify(errorReceived)))) {
          updatedErrors = [...updatedErrors, ...errorsReceived];
          this.setState({ errors: updatedErrors });
        }
      });
    }
  };

  generateTabs = (requestObject: any, props: any, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();
    const { selectionStore } = props;
    const { reportPublisherId, isGeneratedOnce } = this.state;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    )[0];
    if (testParameters.values.length === 0) {
      this.preferences.reportType = 'BIN_BASED' as REPORT_TYPE;
    } else {
      this.preferences.reportType = 'PARAMETER_BASED' as REPORT_TYPE;
    }
    props.isSoftBin = requestObject.isSoftBin;

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences, this.populateErrors),
    });
    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied, undefined, this.populateErrors),
    });
    if (reportPublisherId !== '' && !isGeneratedOnce) {
      const powerViewProgressDriver : IPowerViewProgressDriver = {
        action: 'APPEND',
        description: 'Customized Data Summary Report Generated Successfully',
        timestamp: new Date(),
      };
      this.logProgress(powerViewProgressDriver);
    }
    return tabs;
  };

  logProgress = (powerViewProgressDriver: IPowerViewProgressDriver) => {
    const { reportPublisherId } = this.state;
    this.setState({ isGeneratedOnce: true }, () => {
      this.pubSubBinder.BroadcastEvent(
        reportPublisherId,
        ['POWER_VIEW_PROGRESS_BAR'],
        'LOG_POWER_VIEW_PROGRESS',
        powerViewProgressDriver,
      );
    });
  };

  render() {
    const {
      showLegend,
      viewMode,
      aggredateFunction,
      seriesType,
      showReportRow,
      showModal,
      testParameterIndex,
    } = this.state;

    const {
      groupingSortingListStore,
      parseFilter,
      legend,
      reportSessionId,
      selectedBinPlusDefinition,
      selectionStore,
      config,
      rulePayLoad,
    } = this.props;

    const { report, hideReportRow } = this.props;

    return (
      <Container fluid className="overflow-visible">
        <div className="m10 border-all background-color-light p20">
          <Row className="mb20">
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb0 mr10">{toTitleCase(report.actor)}</h5>
                </div>
                <div className="d-flex align-items-center justify-content-between" />
              </div>
            </Col>
          </Row>
          {
            showReportRow && (
              <Row>
                {/* --------------------- GRAPH 👇🏼 --------------------- */}
                <Col lg={this.getReportColumnWidth()}>
                  <Tabs key="customized report tabs" id="customized-report-tabs">
                    {/* ---------------------  DYNAMIC TABS RENDERING 👇🏼 --------------------- */}
                    {
                      this.getTabsComponent(this.generateTabs)
                    }
                    {/* ---------------------  DYNAMIC TABS RENDERING 👆🏼 --------------------- */}
                  </Tabs>
                </Col>
              </Row>
            )
          }

        </div>
      </Container>
    );
  }
}

export default CustomizedDataSummary;
