/* eslint-disable no-unused-expressions */
import React from 'react';
import EditableDataGrid, { IEditableDataGridColumnFields } from '../../wrapped-component/editable-data-grid/EditableDataGrid';
import ConditionalComponentForExpressionBuilder from './ConditionalComponentForExpressionBuilder';

interface IExpressionBuilderV2DropdownOption {
  caption: string;
  dataField: string;
  dataType?: string;
}
interface IExpressionBuilderV2ChangeMyTypeOptions {
  toType?: 'number' | 'string' | 'twoInputBox' | 'dropdown',
  disabled?: boolean,
  onValues: string[],
  becauseOfColumn: string,
  dropdownOptions?:IExpressionBuilderV2DropdownOption[],
  twoInputTypes?: {
    first: 'string' | 'number',
    second: 'string' | 'number',
    separator: '_' | '-' | ',' | ':' | '/',
  }
  returnFormat?: 'singleValue' | 'object',
}

interface IExpressionBuilderV2Rules {
  objects: {
    caption: string;
    dataField: string;
    dataType: string;
    values?: string[];
  }[];
  operators: {
    caption: string;
    dataField: string;
  }[];
  typeOperations: {
    dataType: string;
    operators: string[];
  }[];
}
interface IExpressionBuilderCustomInputOutputProps {
  caption: string,
  fields: {
    caption: string,
    dataField: string,
    dataType: 'number' | 'string' | 'twoInputBox' | 'dropdown' | 'textBoxWithCallback',
    className?: string | undefined;
    typeOfCustomizedTextBox?: string | undefined;
    requiredWhenForCustomizedTextBox?:any;
    callBackOnUpdate?:(data:any)=>void;
    isRequired?: boolean,
    dropdownOptions?:IExpressionBuilderV2DropdownOption[],
    twoInputTypes?:{
      first: 'string' | 'number',
      second: 'string' | 'number',
      separator: '_' | '-' | ',' | ':' | '/',
    }
    changeMyType?:{
      primary: IExpressionBuilderV2ChangeMyTypeOptions,
      secondary?: IExpressionBuilderV2ChangeMyTypeOptions,
    }
    disabledWhen?:{
      values: string[],
      inColumn: string,
    }
    width?: number,
    allowEditing?: boolean,
    validationRules?: any,
  }[],
}
interface IExpressionBuilderV2Props {
  rules: IExpressionBuilderV2Rules;
  onUpdate: (event: any) => void;
  defaultStatements?: string
  hideOutputSettings?: boolean;
  height?: number;
  viewMode?: 'tabular' | 'text';
  behavior?: 'default' | 'customizedInput' | 'customizedOutput' | 'allCustomized',
  onToolbarPreparing?: (event: any) => void;

  customizedInput?: IExpressionBuilderCustomInputOutputProps;
  customizedOutput?: IExpressionBuilderCustomInputOutputProps;
  editingMode?: 'batch' | 'cell' | 'row';
  onRowUpdating? : (event: any) => void;
  isInputUOMDisabled?: boolean;
  isOutputUOMDisabled?: boolean;
}

class ExpressionBuilderV2 extends React.Component<IExpressionBuilderV2Props, any> {
  static defaultProps: Partial<IExpressionBuilderV2Props> = {
    viewMode: 'tabular',
    hideOutputSettings: false,
    behavior: 'default',
  };

  constructor(props: IExpressionBuilderV2Props) {
    super(props);
    this.state = {
      statements: this.getDefaultStatements(this.props.defaultStatements),
    };
  }

  getDefaultStatements = (defaultStatements: any) => {
    if (defaultStatements) {
      return typeof (defaultStatements) === 'string' ? JSON.parse(defaultStatements) : defaultStatements;
    }
    return [];
  };

  dropdownPropsExtractor = (x:any) => {
    const y: any = [];
    x.forEach((element: any) => {
      y.push({ caption: element.caption, dataField: element.dataField });
    });
    return y;
  };

  columnConfigurator = (columns?: IExpressionBuilderCustomInputOutputProps) => {
    const configuredColumns: IEditableDataGridColumnFields = {
      caption: columns?.caption,
      cellType: 'columns',
      fields: [],
    };

    if (columns) {
      columns.fields?.forEach((column:any) => {
        if (column.changeMyType) {
          configuredColumns.fields?.push({
            caption: column.caption,
            dataField: column.dataField,
            dataType: column.dataType,
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
            allowEditing: column.allowEditing === undefined ? true : column.allowEditing,
            cellType: 'conditional',
            cellComponent: ConditionalComponentForExpressionBuilder,
            cellData: {
              initialForm: column.dataType,
              dropdownOptions: column.dropdownOptions,
              twoInputTypes: column.twoInputTypes,
              secondaryForm: {
                toType: column.changeMyType.primary.toType || undefined,
                isDisabled: column.changeMyType.primary.disabled || undefined,
                onValues: column.changeMyType.primary.onValues,
                becauseOfColumn: column.changeMyType.primary.becauseOfColumn,
                dropdownOptions: column.changeMyType.primary.dropdownOptions,
                twoInputTypes: column.changeMyType.primary.twoInputTypes,
                returnFormat: column.changeMyType.primary.returnFormat || undefined,
              },
              tertiaryForm: column.changeMyType.secondary ? {
                toType: column.changeMyType.secondary.toType || undefined,
                isDisabled: column.changeMyType.secondary.disabled || undefined,
                onValues: column.changeMyType.secondary.onValues,
                becauseOfColumn: column.changeMyType.secondary.becauseOfColumn,
                dropdownOptions: column.changeMyType.secondary.dropdownOptions,
                twoInputTypes: column.changeMyType.secondary.twoInputTypes,
                returnFormat: column.changeMyType.secondary.returnFormat || undefined,
              } : undefined,
              disabledWhen: column.disabledWhen,
              width: column.width,
              callBackOnUpdate: column.callBackOnUpdate,
            },
            isSetCellValueRequired: false,
            calculateDisplayValue: (data: any) => {
              if (data[column.dataField] !== null && typeof data[column.dataField] === 'object') {
                if (column.changeMyType.primary !== undefined && column.changeMyType.primary.onValues.includes(data[column.changeMyType.primary.becauseOfColumn])) {
                  return column.changeMyType.primary.toType === 'twoInputBox'
                    ? `${data[column.dataField].firstValue}${column.changeMyType.primary.twoInputTypes.separator}${data[column.dataField].secondValue}`
                    : `${data[column.dataField].firstValue}`;
                }
                if (column.changeMyType.secondary !== undefined && column.changeMyType.secondary.onValues.includes(data[column.changeMyType.secondary.becauseOfColumn])) {
                  return column.changeMyType.secondary.toType === 'twoInputBox'
                    ? `${data[column.dataField].firstValue}${column.changeMyType.secondary.twoInputTypes.separator}${data[column.dataField].secondValue}`
                    : `${data[column.dataField].firstValue}`;
                }
                return `${data[column.dataField] === null ? '' : typeof (data[column.dataField]) === 'object' ? data[column.dataField].firstValue : data[column.dataField]}`;
              }
              return data[column.dataField];
            },
            isRequired: column.isRequired,
            validationRules: column.validationRules,
          });
        } else if (column.dataType === 'number' || column.dataType === 'string') {
          configuredColumns.fields?.push({
            caption: column.caption,
            dataField: column.dataField,
            dataType: column.dataType,
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
            allowEditing: column.allowEditing === undefined ? true : column.allowEditing,
            cellType: 'textBox',
            width: column.width,
            cellData: {
              type: column.dataType,
              disabledWhen: column.disabledWhen,
            },
            isRequired: column.isRequired,
            validationRules: column.validationRules,
          });
        } else if (column.dataType === 'textBoxWithCallback') {
          configuredColumns.fields?.push({
            caption: column.caption,
            dataField: column.dataField,
            dataType: column.dataType,
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
            allowEditing: column.allowEditing === undefined ? true : column.allowEditing,
            cellType: 'textBoxWithCallback',
            width: column.width,
            cellData: {
              type: column.typeOfCustomizedTextBox,
              disabledWhen: column.disabledWhen,
              className: column.className,
              requiredWhen: column.requiredWhenForCustomizedTextBox,
              callBackOnUpdate: column.callBackOnUpdate,
            },
            isRequired: column.isRequired,
            validationRules: column.validationRules,
          });
        } else if (column.dataType === 'twoInputBox') {
          configuredColumns.fields?.push({
            caption: column.caption,
            dataField: column.dataField,
            dataType: column.dataType,
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
            allowEditing: column.allowEditing === undefined ? true : column.allowEditing,
            cellType: 'twoInputBox',
            cellData: {
              firstValueType: column.twoInputTypes.first,
              secondValueType: column.twoInputTypes.second,
              separator: column.twoInputTypes.separator,
              disabledWhen: column.disabledWhen,
            },
            width: column.width,
            isRequired: column.isRequired,
            validationRules: column.validationRules,
          });
        } else if (column.dataType === 'dropdown') {
          configuredColumns.fields?.push({
            caption: column.caption,
            dataField: column.dataField,
            dataType: column.dataType,
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
            allowEditing: column.allowEditing === undefined ? true : column.allowEditing,
            cellType: 'selectBox',
            cellData: {
              isSearchEnabled: false,
              listItem: this.dropdownPropsExtractor(column.dropdownOptions),
              disabledWhen: column.disabledWhen,
              callBackOnUpdate: column.callBackOnUpdate,
            },
            width: column.width,
            isRequired: column.isRequired,
            validationRules: column.validationRules,
          });
        }
      });
    }
    return (configuredColumns);
  };

  columnConfigration = (defaultInputColumns: IEditableDataGridColumnFields, defaultOutputColumns: IEditableDataGridColumnFields) => {
    const {
      behavior, hideOutputSettings, customizedInput, customizedOutput,
    } = this.props;
    // behavior: 'default' | 'onlyInput' | 'customizedInput' | 'customizedOutput' | 'allCustomized' ,
    if (hideOutputSettings) {
      switch (behavior) {
        case 'default': {
          return [defaultInputColumns];
        }
        case 'customizedInput': {
          return [this.columnConfigurator(customizedInput)];
        }
        default: {
          return [defaultInputColumns];
        }
      }
    } else {
      switch (behavior) {
        case 'default': {
          return [defaultInputColumns, defaultOutputColumns];
        }
        case 'customizedInput': {
          return [this.columnConfigurator(customizedInput), defaultOutputColumns];
        }
        case 'customizedOutput': {
          return [defaultInputColumns, this.columnConfigurator(customizedOutput)];
        }
        case 'allCustomized': {
          return [this.columnConfigurator(customizedInput), this.columnConfigurator(customizedOutput)];
        }
        default: {
          return [defaultInputColumns, defaultOutputColumns];
        }
      }
    }
  };

  updateStatementsToParent = async () => {
    const { onUpdate } = this.props;
    const { statements } = this.state;
    onUpdate(JSON.stringify(statements));
  };

  render() {
    const {
      statements,
    } = this.state;
    const {
      viewMode, rules, height, onToolbarPreparing, editingMode, onRowUpdating, isInputUOMDisabled, isOutputUOMDisabled,
    } = this.props;
    const defaultInputColumns: IEditableDataGridColumnFields = {
      caption: 'Input',
      cellType: 'columns',
      fields: [
        {
          caption: 'Parameter',
          dataField: 'inputParameter',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: true,
            listItem: [...rules.objects, { caption: '(', dataField: '(' }, { caption: ')', dataField: ')' }, { caption: '', dataField: '', dataType: 'string' }],
          },
        },
        {
          caption: 'Operator',
          dataField: 'inputOperator',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: false,
            listItem: [...rules.operators, { caption: '', dataField: '', dataType: 'string' }],
          },
        },
        {
          caption: 'Value',
          dataField: 'inputValue',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'textBox',
        },
        {
          caption: 'UOM',
          dataField: 'inputUom',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          allowEditing: !isInputUOMDisabled,
          cellData: {
            isSearchEnabled: false,
            listItem: [
              { caption: 'nano', dataField: 'nano' },
              { caption: 'micro', dataField: 'micro' },
              { caption: 'milli', dataField: 'milli' },
              { caption: 'kilo', dataField: 'kilo' },
              { caption: 'mega', dataField: 'mega' },
              { caption: 'giga', dataField: 'giga' },
              { caption: '', dataField: '' },
            ],
          },
        },
        {
          caption: 'Boolean Expression',
          dataField: 'inputBooleanExpression',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: false,
            listItem: [
              { caption: 'and', dataField: 'and' },
              { caption: 'or', dataField: 'or' },
              { caption: 'then', dataField: 'then' },
              { caption: '', dataField: '' },
            ],
          },
        },
      ],
    };
    const defaultOutputColumns: IEditableDataGridColumnFields = {
      caption: 'Output',
      cellType: 'columns',
      fields: [
        {
          caption: 'Parameter',
          dataField: 'outputParameter',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: true,
            listItem: [...rules.objects, { caption: '', dataField: '', dataType: 'string' }],
          },
        },
        {
          caption: 'Operator',
          dataField: 'outputOperator',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: false,
            listItem: [
              { dataField: '=', caption: '=' },
              { dataField: '', caption: '' },
            ],
          },
        },
        {
          caption: 'Value',
          dataField: 'outputValue',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'textBox',
        },
        {
          caption: 'UOM',
          dataField: 'outputUom',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          allowEditing: !isOutputUOMDisabled,
          cellData: {
            isSearchEnabled: false,
            listItem: [
              { caption: 'nano', dataField: 'nano' },
              { caption: 'micro', dataField: 'micro' },
              { caption: 'milli', dataField: 'milli' },
              { caption: 'kilo', dataField: 'kilo' },
              { caption: 'mega', dataField: 'mega' },
              { caption: 'giga', dataField: 'giga' },
              { caption: '', dataField: '' },
            ],
          },
        },
        {
          caption: 'Boolean Expression',
          dataField: 'outputBooleanExpression',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
          cellType: 'selectBox',
          cellData: {
            isSearchEnabled: false,
            listItem: [
              { dataField: 'or', caption: 'or' },
              { dataField: 'end', caption: 'end' },
              { dataField: '', caption: '' },
            ],
          },
        },
      ],
    };
    return (
      <div className="flex-width-auto">
        { viewMode === 'tabular'
      && (
        <EditableDataGrid
          height={height || 500}
          showFilterRow={false}
          showAdvancedFilters={false}
          enableColumnChooser={false}
          showGroupPanel={false}
          showRowLines
          showColumnLines
          allowAdding
          allowUpdating
          allowDeleting
          columnAutoWidth
          onToolbarPreparing={onToolbarPreparing}
          onRowInserted={(event: any) => {
            statements.push(event.data);
            this.setState({ statements });
            this.updateStatementsToParent();
          }}
          onRowRemoved={(event: any) => {
            // eslint-disable-next-line no-underscore-dangle
            this.setState({ statements: statements.filter((x: any) => x.__KEY__ !== event.data.__KEY__) }, () => {
              this.updateStatementsToParent();
            });
          }}
          onRowUpdated={(event:any) => {
            // eslint-disable-next-line no-underscore-dangle
            const index = statements.findIndex((x: any) => x.__KEY__ === event.data.__KEY__);
            statements[index] = event.data;
            this.setState({ statements }, () => { this.updateStatementsToParent(); });
          }}
          onRowUpdating={onRowUpdating}
          editingMode={editingMode || 'batch'}
          tableData={statements}
          fields={this.columnConfigration(defaultInputColumns, defaultOutputColumns)}
        />
      )}
        {viewMode === 'text'
  && (
    <h1>
      Advance Mode - to be done...
    </h1>
  )}
      </div>
    );
  }
}

export default ExpressionBuilderV2;
