/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Spinner, } from 'react-bootstrap';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { IDrilledGraphData, IIconDefinition } from 'interfaces';
import {
  faBezierCurve, faBoxes, faCheckCircle, faClone, faCropAlt, faFileAlt, faLayerGroup, faVectorSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './drilled-bin-histogram.scss';
import { GraphingUtils } from './customized-report-utils/GraphingUtils';

interface IDrilledBinHistogramReportProps {
  data: IDrilledGraphData[];
  className?: string | undefined;
  parentColor?: string | undefined;
  depth?: number | undefined;
  isGalleryView?: boolean;
  updateComponent?: () => void;
  showConnections?: boolean;
}

class DrilledBinHistogramReport extends React.Component<IDrilledBinHistogramReportProps, any> {
  private GraphingUtil = new GraphingUtils();

  private barsColorPallet: string[] = [
    '#668C4A',
    '#A6BF4B',
    '#F2F0D5',
    '#F2C53D',
    '#B1B1AC',
  ];

  private groupsColorPallet: string[] = [
    '#889630',
    '#FEA443',
    '#705E78',
    '#A5AAA3',
    '#812F33',
  ];

  private iconsDictionary : IIconDefinition[] = [
    {
      entityType: 'FACILITY',
      image: '',
      icon: faLayerGroup,
    },
    {
      entityType: 'WORK_CENTER',
      image: '',
      icon: faBezierCurve,
    },
    {
      entityType: 'DEVICE',
      image: '',
      icon: faClone,
    },
    {
      entityType: 'TEST_PROGRAM',
      image: '',
      icon: faCropAlt,
    },
    {
      entityType: 'TEST_PARAMETER',
      image: '',
      icon: faVectorSquare,
    },
    {
      entityType: 'TEST_PROGRAM_REVISION',
      image: '',
      icon: faFileAlt,
    },
    {
      entityType: 'LOT',
      image: '',
      icon: faBoxes,
    },
  ];

  renderCheckbox = (isSelected:boolean) => {
    return isSelected
      ? (
        <div className="position-absolute mt-20 mr4 background-color-light border-radius-all h25 w25 overflow-hidden d-flex align-items-center justify-content-center card-checkbox">
          <FontAwesomeIcon
            className="color-info"
            icon={faCheckCircle}
            size="2x"
          />
        </div>
      ) : <></>;
  };

  renderImage = (imageUrl: string | undefined) => {
    return imageUrl !== undefined && imageUrl !== null
      ? (
        <img
          className="h130"
          alt="hello world"
          // src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Wafermap_showing_fully_and_partially_patterned_dies.svg/220px-Wafermap_showing_fully_and_partially_patterned_dies.svg.png"
          src={imageUrl}
        />
      ) : (
        <div className="h130">
          <div className="d-flex align-items-center h-100 justify-content-center">
            <Spinner animation="border" />
          </div>
        </div>
      );
  };

  renderGuideLines = (depthIndex: number, horizontalIndex: number, maxHorizontalIndex: number) => {
    return (
      <>
        { depthIndex > 0 && (
          <div className="d-flex align-items-center justify-content-between">
            <div className={`ml1 h15 w80 ${horizontalIndex === 0 ? 'break-right' : ''}`} />
          </div>
        )}
        { depthIndex > 0 && (
          <div className="d-flex align-items-center justify-content-between">
            {
              maxHorizontalIndex === 1
                ? <div className="ml1 h15 w80 break-right" />
                : (
                  <>
                    <div className={`h15 w80 ${horizontalIndex > 0 ? 'break-right break-top' : ''}`} />
                    <div className={`h15 w80 ${horizontalIndex !== (maxHorizontalIndex - 1) ? 'break-left break-top' : ''} ml-1`} />
                  </>
                )
            }
          </div>
        )}
      </>
    );
  };

  renderImageCard = (depthIndex: number, horizontalIndex: number, maxHorizontalIndex: number, group: IDrilledGraphData, iconImage:any, name: string, imageUrl: string | undefined) => {
    const cardHeight = 'h170';
    const { isSelected } = group;
    return (
      <div className="w160">
        {this.renderGuideLines(depthIndex, horizontalIndex, maxHorizontalIndex)}
        <div
          onClick={() => { this.selectCard(group); }}
          className={`cursor-pointer position-relative ${cardHeight} ${isSelected ? 'selected-gallery-card' : ''} border-all rounded ml5 mr5 background-color-light gallery-card-hover`}
        >
          {this.renderCheckbox(isSelected)}
          <div className="overflow-hidden d-flex justify-content-center background-color-body-background">
            {this.renderImage(imageUrl)}
          </div>
          <div className="h40">
            <h5 className="mb0 w-100 text-center">
              {name}
            </h5>
            {/* <p>
              depth :
              {depthIndex}
              <br />
              : horizontal:
              {horizontalIndex}
              <br />
              : maxHorizontalIndex :
              {maxHorizontalIndex}
            </p> */}
          </div>

        </div>
      </div>
    );
  };

  renderInfoCard = (depthIndex: number, horizontalIndex: number, maxHorizontalIndex: number, group: IDrilledGraphData, iconImage:any, name: string) => {
    const cardHeight = 'h80';
    const { isSelected, entityType } = group;
    return (
      <div className="w160">
        {this.renderGuideLines(depthIndex, horizontalIndex, maxHorizontalIndex)}
        <div
          onClick={() => { this.selectCard(group); }}
          className={`cursor-pointer position-relative ${cardHeight} ${isSelected ? 'selected-gallery-card' : ''} border-all d-flex flex-column justify-content-between rounded ml5 mr5 p10 background-color-light gallery-card-hover`}
        >
          {this.renderCheckbox(isSelected)}
          <h5 className="mb0">
            {name}
          </h5>
          {/* <p>
            depth :
            {depthIndex}
            <br />
            : horizontal:
            {horizontalIndex}
            <br />
            : maxHorizontalIndex :
            {maxHorizontalIndex}
          </p> */}
          <div className="d-flex align-items-center justify-content-between">
            {/* <div className="w25 h25 border-radius-all d-flex align-items-center justify-content-center mr20 background-color-primary"> */}
            <div className="mr20">
              <FontAwesomeIcon size="lg" icon={iconImage} className="color-success" />
            </div>
            <p className="mb0 color-disabled-color">
              {entityType}
            </p>
          </div>
        </div>
      </div>
    );
  };

  selectCard = (group: IDrilledGraphData) => {
    const { updateComponent } = this.props;
    if (updateComponent) {
      const newSelectionValue = !group.isSelected;
      this.selectBellow(group, newSelectionValue);
      updateComponent();
    }
  };

  selectBellow = (group: IDrilledGraphData, newSelectionValue: boolean) => {
    const { updateComponent } = this.props;
    if (updateComponent !== undefined && group.thumbNail !== undefined) {
      group.isSelected = newSelectionValue;
      if (group.groups.length > 0) {
        group.groups.forEach((g) => {
          this.selectBellow(g, newSelectionValue);
        });
      }
    }
  };

  renderGraphAndGroups = (group:IDrilledGraphData, horizontalIndex: number, maxHorizontalIndex: number) => {
    const {
      parentColor, depth, isGalleryView, updateComponent,
    } = this.props;
    const color = parentColor || this.groupsColorPallet[Math.floor(Math.random() * (this.groupsColorPallet.length - 0) + 0)];
    const depthIndex = depth !== undefined ? depth : 0;

    let lastInRow = false;
    if (group.groups === null) {
      lastInRow = true;
    } else if (group.groups.length === 0) {
      lastInRow = true;
    }

    const componentToRender : JSX.Element[] = [];
    if (isGalleryView === true) {
      const imageUrl = group.thumbNail !== null ? group.thumbNail?.imageUrl : '';
      const entityType = group.entityType !== undefined ? group.entityType : '';
      const iconDef = this.iconsDictionary.find((x) => x.entityType === entityType);
      const iconImage = iconDef !== undefined ? iconDef.icon : faBoxes;

      const isWaferCard = group.entityType === 'WAFER';
      componentToRender.push(
        <>
          {
            isWaferCard
              ? this.renderImageCard(depthIndex, horizontalIndex, maxHorizontalIndex, group, iconImage, group.name, imageUrl)
              : this.renderInfoCard(depthIndex, horizontalIndex, maxHorizontalIndex, group, iconImage, group.name)
          }
        </>,
      );
    } else if (group.graph !== undefined) {
      const Plot = createPlotlyComponent(Plotly);

      // -------------------------------------------------
      const plotlyChartDesignerGroups = [];
      for (let i = 0; i < group.graph.x.length; i += 1) {
        plotlyChartDesignerGroups.push((group.name));
      }
      const designerSource = {
        Bins: group.graph.x,
        Group: plotlyChartDesignerGroups,
        Values: group.graph.y,
      };
      const dataSourceOptions = Object.keys(designerSource).map((name) => ({
        value: name,
        label: name,
      }));
      // -------------------------------------------------

      const barColors : string[] = [];
      group.graph.x.forEach(() => {
        barColors.push(this.barsColorPallet[Math.floor(Math.random() * (this.barsColorPallet.length - 0) + 0)]);
      });

      componentToRender.push(
        <div
          className="w650"
        >
          <div
            style={{
              backgroundColor: color,
            }}
            className="text-center w-100 p4 mb20 color-light"
          >
            {group.name}
          </div>
          {this.GraphingUtil.getPlotDrilledGraphData(group.graph)}
        </div>,
      );
    }

    if (group.groups !== null) {
      if (group.groups.length !== 0) {
        componentToRender.push(
          <div className="d-flex align-items-center">
            <DrilledBinHistogramReport
              updateComponent={updateComponent}
              parentColor={color}
              data={group.groups}
              depth={depthIndex + 1}
              isGalleryView={isGalleryView}
            />
          </div>,
        );
      }
    }

    return componentToRender;
  };

  render() {
    // const styles : CSSProperties = {
    //   borderRightStyle: 'dashed',
    //   borderWidth: '1px',
    // };
    const {
      data, className, depth, showConnections,
    } = this.props;
    const depthIndex = depth !== undefined ? depth : 0;
    return (
      <div
        className={`w-100 position-relative d-flex align-content-end ${className ?? ''}`} // d-flex align-content-end
      >
        {
          data.length > 1
          && (
            <div
              className={`groupGuide position-absolute mt15 ml80 ${showConnections ? 'border-top' : ''}`}
            />
          )
        }
        {
          data.map((group, horizontalIndex:number) => {
            return (
              <div
                className={depthIndex === 0 ? '  mr20 pr20' : ''}
              >
                {
                  this.renderGraphAndGroups(group, horizontalIndex, data.length)
                }
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default DrilledBinHistogramReport;

//  `#${Math.floor(Math.random() * 16777215).toString(16)}`
