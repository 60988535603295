/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject, OperationType } from 'interfaces';
import { httpReport } from 'services/http.report';
import { IGraphDataHelper, IGraphMode, IGraphType } from '../CustomizedReportHelper';
import { ICombinedGraphDataWithReportHeaders, InteractionInputDTO, } from '../CustomizedReportInterfaces';
import { WaferPlottingUtil } from '../../customized-report-utils/WaferPlottingUtil';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { ICustomizedReportState } from '../../CustomizedReport';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import TestParameterBadges from '../../customized-report-utils/TestParameterBadges';

export class ParametricWaferMapGraphHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: false,
    reportType: 'PARAMETER_BASED' as REPORT_TYPE,
    graphType: ('wafermap' as IGraphType),
    graphMode: ('classColor' as IGraphMode),
    markerOpacity: 1,
    markerBorderOpacity: 1,
    showCustomLabelsWhenGrouped: false,
    showCustomLabelsWhenNotGrouped: false,
    showCustomTickText: false,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: false,
    showXAxisBreaks: false,
    allowDefaultGrouping: true,
    extractSelectedValuesFromToolTips: true,
    showAddedMarkers: false,
    textOnHover: [['placeholder']],
    showAddedLines: {
      grouping: {
        show: false,
        name: '',
      },
      noGrouping: {
        show: false,
        name: '',
      },
    },
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'CLASS_NAME',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'wafer_key',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'key',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'NONE',
        yAxisName: 'NONE',
      },
      noGrouping: {
        xAxisName: 'NONE',
        yAxisName: 'NONE',
      },
    },
    showTickText: {
      grouping: {
        xAxis: false,
        yAxis: false,
      },
      noGrouping: {
        xAxis: false,
        yAxis: false,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: true,
  };

  getPreferences = () => {
    return this.preferences;
  };

  getAggregateFunctionDropdownList = (props: any) => {
    const { groupingSortingListStore } = props;
    let aggregateFunctionDropdownList = [];
    if (groupingSortingListStore.grouping.length > 0) {
      aggregateFunctionDropdownList = [
        ['AVG', 'AVG'],
        ['MEDIAN', 'MEDIAN'],
        ['STDDEV', 'STDDEV'],
        ['CP', 'CP'],
        ['CPK', 'CPK'],
      ];
    } else {
      aggregateFunctionDropdownList = [['None', 'None']];
    }
    return aggregateFunctionDropdownList;
  };

  getPrimaryComponent = (props:any, state:ICustomizedReportState, classMembers:any, setStateCallback: any) => {
    return (
      <></>
    );
  };

  getSecondaryComponent = (props:any) => {
    const testParameterNames: any[] = [];
    const testParameters = props.selectionStore.selections.filter((x:any) => x.entityType === 'Testparameter');
    if (testParameters.length > 0 && testParameters[0].values.length > 0) {
      testParameterNames.push(testParameters[0].values[0].name);
      return (
        <TestParameterBadges testParameterNames={testParameterNames} />
      );
    }
    return <></>;
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props:ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });

    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied, undefined, updateParentState),
    });
    return tabs;
  };

  generateGraphComponent = (props:ICustomizedReportGraphProps, state:ICustomizedReportGraphState, classMembers:any, setStateCallback: any) => {
    const waferPlottingUtil = new WaferPlottingUtil();
    return waferPlottingUtil.customWaferGenerationHelper(props, state, classMembers, setStateCallback, this.preferences);
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject:IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    await httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    })
      .catch((err: any) => {
        const errorDetails: any = err.message;
        const graphingUtil = new GraphingUtils();
        callback(graphingUtil.generateDataObjectFromError(errorDetails));
      });
  };
}
