/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import WaferUtils from '../Utils/WaferUtils';
import { hideTooltip } from './Tooltip';

export enum InteractionType { Select = 'Select', Drag = 'Drag', Zoom = 'Zoom', Tooltip = 'Tooltip', Radar = 'Radar', ContextMenu = 'ContextMenu' }

const getUtilBasedOnViewport = (startX: number, startY: number, endX: number, endY: number, waferUtils: { [keyIndex: string]: WaferUtils }, canvasHeight: number, viewportType: 'inner' | 'radar') => {
  const waferUtilKeys = Object.keys(waferUtils);
  for (let i = 0; i < waferUtilKeys.length; i += 1) {
    const waferUtil: WaferUtils = waferUtils[waferUtilKeys[i]];
    let viewport;
    if (viewportType === 'radar') {
      viewport = waferUtil.waferMapVariables.radarViewPort;
    } else {
      viewport = waferUtil.waferMapVariables.innerViewPort;
    }
    if (
      startX > viewport.x
      && startX < viewport.x + viewport.width
      && endX > viewport.x
      && endX < viewport.x + viewport.width
      && (canvasHeight - startY) > viewport.y
      && (canvasHeight - startY) < viewport.y + viewport.height
      && (canvasHeight - endY) > viewport.y
      && (canvasHeight - endY) < viewport.y + viewport.height
      && waferUtils[waferUtilKeys[i]].waferMapVariables.pageNumber === waferUtils[waferUtilKeys[i]].waferMapVariables.plotterPageNumber
    ) {
      return waferUtils[waferUtilKeys[i]];
    }
  }
  return null;
};

let isDown = false;
let startX: number;
let startY: number;
let endX: number;
let endY: number;

const initializeEvents = (
  waferUtils: { [keyIndex: string]: WaferUtils },
  canvas: HTMLCanvasElement,
  mouseDownCallbacks: {
    [key in InteractionType]?: {
      callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, data?: { [key: string]: any }) => void | null,
      data?: { [key: string]: any }
    }
  },
  mouseMoveCallbacks: {
    [key in InteractionType]?: {
      callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => void | null,
      data?: { [key: string]: any }
    }
  },
  mouseUpCallbacks: {
    [key in InteractionType]?: ((startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils) => void) | null
  },
) => {
  canvas.addEventListener('mousedown', (e: any) => {
    isDown = true;
    if (e.which === 1) {
      startX = e.offsetX;
      startY = e.offsetY;
      const waferUtil: WaferUtils | null = getUtilBasedOnViewport(startX, startY, startX, startY, waferUtils, canvas.height, 'inner');
      if (waferUtil && mouseDownCallbacks[InteractionType.Drag]) {
        mouseDownCallbacks[InteractionType.Drag]!.callback(startX, startY, startX, startY, waferUtil, mouseDownCallbacks[InteractionType.Drag]!.data);
      }
    }
    const waferUtilForContextMenu: WaferUtils | null = getUtilBasedOnViewport(e.offsetX, e.offsetY, e.offsetX, e.offsetY, waferUtils, canvas.height, 'inner');
    if (waferUtilForContextMenu && mouseDownCallbacks[InteractionType.ContextMenu]) {
      mouseDownCallbacks[InteractionType.ContextMenu]!.callback(e.offsetX, e.offsetY, e.offsetX, e.offsetY, waferUtilForContextMenu, { ...mouseDownCallbacks[InteractionType.ContextMenu]!.data, show: e.which === 3 });
    }
  });
  canvas.addEventListener('mousemove', (e: any) => {
    endX = e.offsetX;
    endY = e.offsetY;

    const waferUtil: WaferUtils | null = getUtilBasedOnViewport(startX, startY, endX, endY, waferUtils, canvas.height, 'inner');
    if (waferUtil) {
      if (isDown) waferUtil.waferMapVariables.isMouseMoving = true;
      const translatedCoords = UtilityFunctions.getTranslatedCoords({
        startX, startY, endX, endY,
      }, waferUtil.waferMapVariables.innerViewPort, canvas.height);
      if (mouseMoveCallbacks[InteractionType.Drag]) {
        mouseMoveCallbacks[InteractionType.Drag]!.callback(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtil, isDown, mouseMoveCallbacks[InteractionType.Drag]!.data);
      }
      if (mouseMoveCallbacks[InteractionType.Zoom]) {
        mouseMoveCallbacks[InteractionType.Zoom]!.callback(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtil, isDown, mouseMoveCallbacks[InteractionType.Zoom]!.data);
      }
      if (mouseMoveCallbacks[InteractionType.Select]) {
        mouseMoveCallbacks[InteractionType.Select]!.callback(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtil, isDown, mouseMoveCallbacks[InteractionType.Select]!.data);
      }
      waferUtil.waferMapVariables.isMouseMoving = false;
    }

    if (mouseMoveCallbacks[InteractionType.Tooltip] && mouseMoveCallbacks[InteractionType.Tooltip]!.data) hideTooltip(mouseMoveCallbacks[InteractionType.Tooltip]!.data!.tooltipRef);
    const waferUtilSinglePoint: WaferUtils | null = getUtilBasedOnViewport(endX, endY, endX, endY, waferUtils, canvas.height, 'inner');
    if (waferUtilSinglePoint) {
      if (isDown) waferUtilSinglePoint.waferMapVariables.isMouseMoving = true;
      if (mouseMoveCallbacks[InteractionType.Tooltip]) {
        mouseMoveCallbacks[InteractionType.Tooltip]!.callback(endX, endY, endX, endY, waferUtilSinglePoint, isDown, mouseMoveCallbacks[InteractionType.Tooltip]!.data);
      }
      waferUtilSinglePoint.waferMapVariables.isMouseMoving = false;
    }

    const waferUtilBasedOnRadarVp: WaferUtils | null = getUtilBasedOnViewport(startX, startY, endX, endY, waferUtils, canvas.height, 'radar');
    if (waferUtilBasedOnRadarVp) {
      if (isDown) waferUtilBasedOnRadarVp.waferMapVariables.isMouseMoving = true;
      const translatedCoords = UtilityFunctions.getTranslatedCoords({
        startX, startY, endX, endY,
      }, waferUtilBasedOnRadarVp.waferMapVariables.radarViewPort, canvas.height);
      if (mouseMoveCallbacks[InteractionType.Radar]) {
        mouseMoveCallbacks[InteractionType.Radar]!.callback(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtilBasedOnRadarVp, isDown, mouseMoveCallbacks[InteractionType.Radar]!.data);
      }
      waferUtilBasedOnRadarVp.waferMapVariables.isMouseMoving = false;
    }
  });
  canvas.addEventListener('mouseup', (e: any) => {
    isDown = false;
    if (e.which !== 1) return;
    endX = e.offsetX;
    endY = e.offsetY;
    const waferUtil: WaferUtils | null = getUtilBasedOnViewport(startX, startY, endX, endY, waferUtils, canvas.height, 'inner');
    if (waferUtil) {
      const translatedCoords = UtilityFunctions.getTranslatedCoords({
        startX, startY, endX, endY,
      }, waferUtil.waferMapVariables.innerViewPort, canvas.height);
      if (mouseUpCallbacks[InteractionType.Zoom]) {
        mouseUpCallbacks[InteractionType.Zoom]!(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtil);
      }
      if (mouseUpCallbacks[InteractionType.Select]) {
        mouseUpCallbacks[InteractionType.Select]!(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtil);
      }
    }
    const waferUtilBasedOnRadarVp: WaferUtils | null = getUtilBasedOnViewport(startX, startY, endX, endY, waferUtils, canvas.height, 'radar');
    if (waferUtilBasedOnRadarVp) {
      const translatedCoords = UtilityFunctions.getTranslatedCoords({
        startX, startY, endX, endY,
      }, waferUtilBasedOnRadarVp.waferMapVariables.radarViewPort, canvas.height);
      if (mouseUpCallbacks[InteractionType.Radar]) {
        mouseUpCallbacks[InteractionType.Radar]!(translatedCoords.startX, translatedCoords.startY, translatedCoords.endX, translatedCoords.endY, waferUtilBasedOnRadarVp);
      }
    }
  });
};

export default initializeEvents;
