import { IGenericDetailedReportRequestObject } from 'interfaces';
import { InteractionInputDTO } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpReport extends HttpBase {
  getReport = (data:IGenericDetailedReportRequestObject) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/report/get-report`, data);
  };

  setInteractionData = (data:InteractionInputDTO) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/report/set-interaction-data`, data);
  };

  clearParametricFailureReportCache = (reportSessionId: string) : Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/report/clear-parametric-failure-report-cache/${reportSessionId}`);
  };
}

const httpReport = new HttpReport();
export { httpReport };
