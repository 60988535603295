import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './sidebar-nav-item.scss';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SidebarNavItem = (props: any) => {
  const {
    selected,
    icon,
    showMenuLabel,
    title,
    className,
    showExpandIcon,
    isChild,
  } = props;
  return (
    <div
      className={`${className} sidebar-menu-item 
      ${selected === true ? 'selected' : ''}
      ${showExpandIcon === true || isChild === true ? 'dropdown' : ''}
      ${showMenuLabel === false && isChild ? 'ml1 mt-1' : ''}
      `}
    >
      <div className="sidebar-menu-item-icon">
        <FontAwesomeIcon
          className="sidebar-icon-size-custom"
          icon={icon}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between w-80">
        <span
          className={
            showMenuLabel === false ? 'fade-to-none' : 'fade-to-show'
          }
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {title}
        </span>
        {
          showExpandIcon
                    && (
                      <FontAwesomeIcon
                        size="sm"
                        icon={faChevronDown}
                      />
                    )
        }
      </div>
    </div>
  );
};
export default SidebarNavItem;
