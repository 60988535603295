// eslint-disable-next-line no-unused-vars
import React, { Component, ReactElement } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Heading from 'components/wrapped-component/hint-controls/Heading';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import EntityHeader, { IEntityHeaderState } from 'components/wrapped-component/entity-header/EntityHeader';
import toast from 'CustomToast';
import GeneralUtils from 'GeneralUtils';
import PolicyStep from '../policy-step/PolicyStep';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import '../policy-block.scss';
import AppConstants from '../../../constants.js';

import {
  DefaultPolicyStepSettings, IDetectDuplicateFiles, IPolicyStep, LoaderSteps, Policy, ScheduleType,
} from '../../../interfaces';

import { httpService } from '../../../services/http.service';

const SortableItem = SortableElement(({ value }: any) => value);

const SortableList = SortableContainer(({ items, policyInfo, addFirst }: any) => {
  return (
    <div className="policy-step-container background-color-light rounded">
      <Heading size={4} className="mt10 mb26 pl20 pt20">Policy Stages</Heading>
      {policyInfo !== undefined
      && (
        <Row className="ml10 mr10">
          <Col lg={12}>
            {policyInfo.PolicySteps.length === 0
            && (
              <div
                className="w-100 h30 d-flex align-items-center mt20 justify-content-between pb300"
              >
                <div className="break-top flex-30 d-inline-block" />
                <div className="flex-30 text-center">
                  <CustomizedDropdown
                    avoidUpdateOnSelect
                    full
                    variant="outline-dark"
                    onChange={addFirst}
                    list={LoaderSteps}
                  />
                </div>
                <div className="break-top flex-30 d-inline-block" />
              </div>
            )}
          </Col>
        </Row>
      )}
      {items.map((value: any, index: number) => {
        return <SortableItem key={value.props.step.hocKey} index={index} value={value} />;
      })}
    </div>
  );
});

interface IPolicyBlockStates {
  editPanel: ReactElement;
  policyInfo: Policy;
  isInSavingState: boolean;
}

interface IPolicyBlockProps {
  id?: string;
}

class LoaderPolicyBlock extends Component<IPolicyBlockProps, IPolicyBlockStates> {
  private policyStore : Policy;

  private policyBlockContainer: any;

  private policyStates = [['DRAFT', 'DRAFT'], ['ACTIVE', 'ACTIVE'], ['INACTIVE', 'INACTIVE'], ['OBSOLETE', 'OBSOLETE']];

  constructor(props: IPolicyBlockProps) {
    super(props);
    this.policyBlockContainer = React.createRef();
    this.onStepSortEnd = this.onStepSortEnd.bind(this);
    this.addStep = this.addStep.bind(this);
    this.deleteStep = this.deleteStep.bind(this);
    this.updatePolicyState = this.updatePolicyState.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
    this.attachSavedRuleToStep = this.attachSavedRuleToStep.bind(this);
    this.updateStepSettings = this.updateStepSettings.bind(this);

    this.policyStore = {
      Id: '',
      Name: '',
      Version: '',
      Owner: '',
      Purpose: '',
      Privacy: 'PRIVATE',
      Type: 'Loader',
      State: this.policyStates[0][0],
      CreatedBy: {
        Id: '',
        FirstName: '',
        LastName: '',
      },
      UpdatedBy: {
        Id: '',
        FirstName: '',
        LastName: '',
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      PolicySteps: [],
      SearchCriteria: [],
      SearchCriteriaExpressions: '',
      AdvancedSearchExpressions: '',
      Schedule: {
        type: 'Manual',
        startsAt: new Date(),
        interval: 1000,
      },
    };

    this.state = {
      editPanel: <></>,
      policyInfo: this.policyStore,
      isInSavingState: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id !== undefined && id !== '') {
      this.getPolicy();
    }
  }

  getPolicy = () => {
    const { id } = this.props;
    const { policyInfo } = this.state;
    httpService.getPolicy(id!).then((data:any) => {
      if (Object.keys(data).length !== 0) {
        policyInfo.Id = data.id;
        policyInfo.Name = data.name;
        policyInfo.Owner = data.owner;
        policyInfo.Version = data.version;
        policyInfo.Purpose = data.purpose;
        policyInfo.State = data.state;
        policyInfo.CreatedBy = data.createdBy;
        policyInfo.Privacy = data.privacy;
        policyInfo.Type = data.type;

        if (data.schedule && data.schedule !== null) {
          data.schedule.startsAt = new Date(`${data.schedule.startsAt}+00:00`);
          policyInfo.Schedule = data.schedule;
        }

        if (data.policySteps !== undefined) {
          if (data.policySteps.length > 0) {
            data.policySteps.forEach((item: any) => {
              const step: IPolicyStep = {
                Id: item.id,
                OutputReference: item.outputReference,
                IsNotificationEnabled: item.isNotificationEnabled === true,
                CreatedBy: {
                  Id: item.createdBy?.id, // FIX: REMOVE QUESTION MARKS
                  FirstName: item.createdBy?.firstName, // FIX: REMOVE QUESTION MARKS
                  LastName: item.createdBy?.lastName, // FIX: REMOVE QUESTION MARKS
                },
                UpdatedBy: {
                  Id: item.updatedBy?.id, // FIX: REMOVE QUESTION MARKS
                  FirstName: item.updatedBy?.firstName, // FIX: REMOVE QUESTION MARKS
                  LastName: item.updatedBy?.lastName, // FIX: REMOVE QUESTION MARKS
                },
                CreatedOn: new Date(item.createdOn),
                UpdatedOn: new Date(item.updatedOn),
                Type: item.type.toString(),
                Sequence: item.sequence,
                hocKey: item.id,
                Rules: item.rules,
                isOpen: false,
                StepSettings: item.stepSettings,
                stepOwner: item.stepOwner,
                additionalEmail: item.additionalEmail,
              };
              policyInfo.PolicySteps.push(step);
            });
          }
        }
      }
      this.setState({ policyInfo }, this.updatePolicyStore);
    });
  };

  updatePolicyStore = () => {
    const { policyInfo } = this.state;
    this.policyStore = policyInfo;
  };

  updateStepSettings = (step: IPolicyStep) => {
    const { policyInfo } = this.state;
    policyInfo.PolicySteps[step.Sequence].StepSettings = step.StepSettings;
    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  addFirst = (type: string) => {
    const { policyInfo } = this.state;
    policyInfo.PolicySteps.push({
      hocKey: (Math.max(...policyInfo.PolicySteps.map((x) => x.Sequence)) + 1).toString(),
      OutputReference: `${type}0`,
      IsNotificationEnabled: false,
      CreatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      UpdatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      Type: type,
      Sequence: 0,
      Rules: [],
      isOpen: true,
      stepOwner: '',
      additionalEmail: '',
      StepSettings: JSON.parse(JSON.stringify(DefaultPolicyStepSettings[type as keyof typeof DefaultPolicyStepSettings])),
    });

    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  deleteStep = (index: number) => {
    const { policyInfo } = this.state;
    policyInfo.PolicySteps.splice(index, 1);
    for (let i = 0; i < policyInfo.PolicySteps.length; i += 1) {
      policyInfo.PolicySteps[i].Sequence = i;
    }
    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  addStep = (type: string, addIndex: number) => {
    const { policyInfo } = this.state;
    policyInfo.PolicySteps.splice(addIndex + 1, 0, {
      hocKey: (Math.max(...policyInfo.PolicySteps.map((x) => x.Sequence)) + 1).toString(),
      OutputReference: `${type}${addIndex + 1}`,
      IsNotificationEnabled: false,
      CreatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      UpdatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      Type: type,
      Sequence: 0,
      Rules: [],
      isOpen: true,
      stepOwner: '',
      additionalEmail: '',
      StepSettings: JSON.parse(JSON.stringify(DefaultPolicyStepSettings[type as keyof typeof DefaultPolicyStepSettings])),
    });
    for (let i = 0; i < policyInfo.PolicySteps.length; i += 1) {
      policyInfo.PolicySteps[i].Sequence = i;
    }
    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  onStepSortEnd = ({ oldIndex, newIndex }: any) => {
    const { policyInfo } = this.state;
    let steps = policyInfo.PolicySteps;
    steps = arrayMove(steps, oldIndex, newIndex);
    policyInfo.PolicySteps = steps;
    for (let i = 0; i < policyInfo.PolicySteps.length; i += 1) {
      policyInfo.PolicySteps[i].Sequence = i;
    }

    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  closeEditPanel = () => {
    this.setState({ editPanel: <></> }, this.updatePolicyStore);
  };

  attachSavedRuleToStep = (sequence: number, ruleId: string) => {
    const { policyInfo } = this.state;
    policyInfo.PolicySteps[sequence].Rules.push(ruleId);
    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  isDuplicateFileFieldsValid = (detectDuplicates: IDetectDuplicateFiles) => {
    const {
      detectDuplicateFiles, duplicateOutcome, rejectDuplicateOutcome, moveFolderPath, moveAndRenameFolderPath, moveAndRenameFileExpression, renameFileExpression 
    } = detectDuplicates;
    return !(detectDuplicateFiles && duplicateOutcome === 'reject'
            && ((rejectDuplicateOutcome === 'move' && !moveFolderPath)
            || (rejectDuplicateOutcome === 'move/rename' && (!moveAndRenameFolderPath || !moveAndRenameFileExpression))
            || (rejectDuplicateOutcome === 'leave/rename' && !renameFileExpression)));
  }

  isFormFieldsValid = (submitCount:any, errors:any) => {
    return (submitCount > 0
      && (errors.name
        || errors.version
        || errors.purpose
        || errors.owner));
  };

  updatePolicyState = (value: string) => {
    const { policyInfo } = this.state;
    policyInfo.State = value;
    this.setState({ policyInfo }, this.updatePolicyStore);
  };

  getPolicyInfoComponentData = (policyInfoData:IEntityHeaderState) => {
    this.policyStore.Name = policyInfoData.name;
    this.policyStore.Version = policyInfoData.version;
    this.policyStore.Privacy = policyInfoData.privacy;
    this.policyStore.Purpose = policyInfoData.purpose;
    this.policyStore.State = policyInfoData.state;
    this.policyStore.Owner = policyInfoData.owner;
    if (policyInfoData.policyType) {
      this.policyStore.Schedule.type = (policyInfoData.policyType as ScheduleType);
    }
    if (policyInfoData.startsAt) {
      this.policyStore.Schedule.startsAt = policyInfoData.startsAt;
    }
    if (policyInfoData.interval) {
      this.policyStore.Schedule.interval = policyInfoData.interval;
    }
  };

  saveOrUpdatePolicy = (values: any) => {
    const { policyInfo } = this.state;
    const {
      Name, Owner, Version, Purpose, Schedule, State, Privacy,
    } = this.policyStore;

    this.setState({ isInSavingState: true }, () => {
      policyInfo.Name = Name;
      policyInfo.Version = Version;
      policyInfo.Privacy = Privacy;
      policyInfo.Purpose = Purpose;
      policyInfo.State = State;
      policyInfo.Owner = Owner;
      policyInfo.Schedule.type = Schedule.type;

      if (policyInfo.Schedule.type === 'Poller') {
        if (Schedule?.startsAt) {
          // @ts-ignore
          policyInfo.Schedule.startsAt = new Date(policyInfo.Schedule.startsAt).toISOString();
        } else {
          toast.warning('Start Time is mandatory for poller scheduling');
          this.setState({ isInSavingState: false });
          return;
        }
        if (Schedule?.interval) {
          policyInfo.Schedule.interval = Schedule.interval;
        } else {
          toast.warning('Time Interval is mandatory for poller scheduling');
          this.setState({ isInSavingState: false });
          return;
        }
      }

      if (!Name || !Owner || !Version || !Purpose) {
        this.setState({ isInSavingState: false });
        return;
      }

      if (policyInfo.PolicySteps.length === 0) {
        toast.warning('Policy must contain at least one stage');
        this.setState({ isInSavingState: false });
        return;
      }

      let isDetectDuplicatesValid = true;
      let isDatabaseSelected = true;
      policyInfo.PolicySteps.forEach((policyStep) => {
        if (isDetectDuplicatesValid && policyStep.StepSettings.sourceLocation.type !== 'PolicyStep' && !this.isDuplicateFileFieldsValid(policyStep.StepSettings.sourceLocation.detectDuplicates)) {
          isDetectDuplicatesValid = false;
        }
        if (isDatabaseSelected && policyStep.Type === 'Load'
            && (policyStep.StepSettings.targetDatabase === null || policyStep.StepSettings.targetDatabase === undefined)) {
          isDatabaseSelected = false;
        }
      })
      if (!isDetectDuplicatesValid){
        toast.warning('Rename file or Folder path field is empty in detect duplicate files. Please fill in the value(s)');
        this.setState({ isInSavingState: false });
        return;
      }
      if (!isDatabaseSelected){
        toast.warning('Please select Target Database in load stage(s)');
        this.setState({ isInSavingState: false });
        return;
      }

      if (policyInfo.PolicySteps.length > 0) {
        const enrichStepSettings = policyInfo.PolicySteps.find((x) => x.Type === 'Enrichment')?.StepSettings;
        if (enrichStepSettings) {
          const enrichmentParameterList = enrichStepSettings?.parameters;
          if (enrichmentParameterList.length === 0) {
            toast.warning('Parameter info is empty. Please specify parameters for enrichment stage.');
            this.setState({ isInSavingState: false });
            return;
          }
          if (GeneralUtils.isDuplicateParameterFound(enrichmentParameterList)) {
            this.setState({ isInSavingState: false });
            return;
          }
        }
      }

      if (!policyInfo.Id || policyInfo.Id === '') {
        policyInfo.CreatedBy = { Id: AppConstants.user.Id.toString() };
        httpService.createPolicy(policyInfo).then((response) => {
          policyInfo.Id = response;
          toast.success('Saved!');
        }).finally(() => {
          this.setState({ policyInfo, isInSavingState: false });
        });
      } else {
        policyInfo.UpdatedBy = { Id: AppConstants.user.Id.toString() };
        httpService.updatePolicy(policyInfo).then(() => {
          toast.success('Updated!');
        }).finally(() => {
          this.setState({ policyInfo, isInSavingState: false });
        });
      }
    });
  };

  render() {
    const { editPanel, isInSavingState } = this.state;
    const { policyInfo } = this.state;

    // const formValidationSchema = Yup.object({
    //   name: Yup.string().required('Policy name is a required field'),
    //   version: Yup.string().required('Version is a required field'),
    //   purpose: Yup.string().required('Description is a required field').max(1000),
    //   owner: Yup.string().required().max(1000),
    // });

    return (
      <>
        {/* <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            name: `${policyInfo.Name}`,
            version: `${policyInfo.Version}`,
            purpose: `${policyInfo.Purpose}`,
            owner: `${policyInfo.Owner}`,
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values: any) => {
            policyInfo.Name = values.name;
            policyInfo.Owner = values.owner;
            policyInfo.Version = values.version;
            policyInfo.Purpose = values.purpose;
            if (policyInfo.Schedule?.startsAt) {
              // @ts-ignore
              policyInfo.Schedule.startsAt = new Date(policyInfo.Schedule.startsAt).toISOString();
            }

            if (policyInfo.PolicySteps.length === 0) {
              toast.warning('Policy must contain at least one stage');
              return;
            }

            if (!policyInfo.Id || policyInfo.Id === '') {
              policyInfo.CreatedBy = { Id: AppConstants.user.Id.toString() };
              httpService.createPolicy(policyInfo).then((response) => {
                policyInfo.Id = response;
                toast.success('Saved!');
                this.setState({ policyInfo });
              });
            } else {
              policyInfo.UpdatedBy = { Id: AppConstants.user.Id.toString() };
              httpService.updatePolicy(policyInfo).then(() => {
                toast.success('Updated!');
                this.setState({ policyInfo });
              });
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, touched, submitCount,
          }) => (
            <> */}
        <TopbarNav
          title="Create Loader Policy"
          items={[]}
          showAvatar={false}
          showNotifications={false}
        />
        <Container fluid className="mt10 position-relative">
          <Row className="pt0 pl30 pr30">
            <Col lg={12} className="p0">
              <EntityHeader
                isInSavingState={isInSavingState}
                name={policyInfo.Name}
                version={policyInfo.Version}
                privacy={policyInfo.Privacy}
                owner={policyInfo.Owner}
                purpose={policyInfo.Purpose}
                state={policyInfo.State}
                policyType={policyInfo.Schedule.type}
                interval={policyInfo.Schedule.interval as unknown as number}
                startsAt={policyInfo.Schedule.startsAt}
                onChange={this.getPolicyInfoComponentData}
                onParentSave={this.saveOrUpdatePolicy}
              />
              {/*
             <form
              onSubmit={handleSubmit}
              onBlur={handleChange}
              className="background-color-light p20 custom-form stage-zero-borders stage-zero-border-top border-color-primary"
            >
              <Heading size={4} className="mt6 mb37">General Settings</Heading>
              <div className="d-flex">
                <Label
                  labelTitle="Policy Name"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={errors.name}
                  isFieldTouched={touched.name}
                  required
                  className="mb15"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      errors.name,
                      touched.name,
                    )}`}
                    name="name"
                    defaultValue={values.name}
                    placeholder="Policy Name"
                  />
                </Label>

                <Label
                  labelTitle="Version"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={errors.version}
                  isFieldTouched={touched.version}
                  required
                  className="mb15 ml10"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      errors.version,
                      touched.version,
                    )}`}
                    name="version"
                    placeholder="1.0"
                    defaultValue={values.version}
                  />
                </Label>
                <Label
                  labelTitle="State"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  className="mb15 ml10"
                >
                  <CustomizedDropdown
                    variant="clear"
                    full
                    list={this.policyStates}
                    onChange={this.updatePolicyState}
                    selectedValue={policyInfo.State ? policyInfo.State : this.policyStates[0][0]}
                  />
                </Label>
                <Label
                  labelTitle="Description"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={errors.purpose}
                  isFieldTouched={touched.purpose}
                  required
                  className="mb15 ml10"
                >
                  <Textbox
                    autoComplete="off"
                    className={`${addErrorClasses(
                      errors.purpose,
                      touched.purpose,
                    )}`}
                    name="purpose"
                    placeholder="Add detailed notes to this rule..."
                    defaultValue={values.purpose}
                  />
                </Label>
                <Label
                  labelTitle="Owner"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  error={errors.owner}
                  isFieldTouched={touched.owner}
                  className="mb25 ml10"
                >
                  <Textbox
                    autoComplete="off"
                    name="owner"
                    placeholder="mark.smith@someemail.com"
                    className={`${addErrorClasses(
                      errors.owner,
                      touched.owner,
                    )}`}
                    defaultValue={values.owner}
                  />
                </Label>
                <div className="custom-form ml10 mt20">
                  <Toggle
                    title="Is Public?"
                    id="is_public"
                    name="is_public"
                    checked={policyInfo.Privacy !== 'PRIVATE'}
                    className="custom-switch"
                    onChange={(e:any) => {
                      policyInfo.Privacy = e.target.checked ? 'PUBLIC' : 'PRIVATE';
                      this.setState({ policyInfo });
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col><Heading size={4} className="mb30">Schedule</Heading></Col>
              </Row>
              <div className="d-flex">
                <Label
                  labelTitle="Type"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  className="mb15"
                >
                  <CustomizedDropdown
                    full
                    variant="clear"
                    selectedValue={policyInfo.Schedule.type}
                    onChange={(value: string) => {
                      policyInfo.Schedule.type = value as ScheduleType;
                      this.setState({ policyInfo });
                    }}
                    list={[['Manual', 'Manual'], ['Poller', 'Poller'], ['Webhook', 'Microservice Webhook']]}
                  />
                </Label>
                <Label
                  labelTitle="Starts At"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  required
                  className={`mb15 ml10 ${policyInfo.Schedule.type === 'Poller' ? 'visible' : 'invisible'}`}
                >
                  <div className="border-all rounded">
                    <DateBox
                      value={policyInfo.Schedule.startsAt}
                      onValueChanged={(e:any) => {
                        policyInfo.Schedule.startsAt = e.value;
                        this.setState({ policyInfo });
                      }}
                      type="datetime"
                    />
                  </div>
                </Label>
                <Label
                  labelTitle="Polling Interval"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  required
                  className={`mb15 ml10 ${policyInfo.Schedule.type === 'Poller' ? 'visible' : 'invisible'}`}
                >
                  <Textbox
                    type="number"
                    defaultValue={policyInfo.Schedule.interval}
                    autoComplete="off"
                    name="additionalEmail"
                    placeholder="5 (minutes)"
                    onBlur={(e: any) => {
                      policyInfo.Schedule.interval = e.target.value;
                      this.setState({ policyInfo });
                    }}
                  />
                </Label>
              </div>
              <Button
                type="submit"
                variant="primary"
                className="mt20 mr20 mb10 height-100percent"
                size="lg"
              >
                Save
              </Button>
              <Row className="pb20">
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <span className="color-dark-three mr7 mt-10">
                        Updated By
                      </span>
                      <span className="color-dark-three mt-10 mr7">
                        <u>Ken Edwards</u>
                      </span>
                      <span className="color-dark-three mr7 mt-10">on</span>
                      <span className="color-dark-three mt-10">
                        <u>26th Jul 2020</u>
                      </span>
                    </div>
                    <div>
                      <span className="color-dark-three mr7 mt-10">
                        Created By
                      </span>
                      <span className="color-dark-three mt-10 mr7">
                        <u>Matt Anderson</u>
                      </span>
                      <span className="color-dark-three mr7 mt-10">on</span>
                      <span className="color-dark-three mt-10">
                        <u>26th Jul 2020</u>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
                  </form>
                   */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="p15">
                <SortableList
                  addFirst={this.addFirst}
                  policyInfo={policyInfo}
                  items={policyInfo.PolicySteps.map((item: IPolicyStep) => (
                    <PolicyStep
                      step={item}
                      title={item.Type}
                      addStep={this.addStep}
                      deleteStep={this.deleteStep}
                      updateStepSettings={this.updateStepSettings}
                      updateStepExpressions={() => {}} // todo: confirm with Salman Raza if loader needs expressions.
                      policySteps={policyInfo.PolicySteps}
                      updateRules={(step, rules) => {
                        policyInfo.PolicySteps[step.Sequence].Rules = rules;
                        this.setState({ policyInfo }, this.updatePolicyStore);
                      }}
                    />
                  ))}
                  onSortEnd={this.onStepSortEnd}
                  useDragHandle
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* </>
          )}
        </Formik> */}

        {editPanel}

      </>
    );
  }
}

export default LoaderPolicyBlock;
