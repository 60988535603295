/* eslint-disable no-else-return */
import { faRedo, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addErrorClasses, ErrorLabel } from 'components/template-component/form-error-label/ErrorLabel';
import WaferUtils from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferUtils';
// eslint-disable-next-line no-unused-vars
import { ZoneData } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Utils';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { ScrollView, TagBox } from 'devextreme-react';
import _ from 'lodash';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

type DefineZonesTabProps = {
  onClickDefineZones: () => void,
  viewDefineZonesBtnText: string,
  onSaveZone: (zoneData: ZoneData) => void,
  onClear: () => void,
  zoneData: ZoneData,
  onChangeFreeFormPCMSiteNumber: (freeFormZonePCMSiteNumber: number | null) => void,
  deviceWafer: WebGLUtils | WaferUtils,
};

type DefineZonesTabState = {
  zoneData: ZoneData,
  formErrors: { [key: string] : any },
  freeFormZonePCMSiteNumber: number | null,
};

export const ZONE_TYPES = [['FREE_FORM', 'Free Form'], ['RADIAL', 'Radial'], ['GROUPED_PER_SITE', 'Grouped Per Site'], ['VERTICAL', 'Vertical'], ['HORIZONTAL', 'Horizontal']];

export const ZONE_APPLICATION = {
  YIELD_PARAMETER_VS_WAT_CORRELATION: 'Yield / Parameter vs WAT Correlation',
  YIELD_MAP: 'Yield Map',
  PARAMETRIC_MAP: 'Parametric Map',
  DEFECT_MAP: 'Defect Map',
};

export default class DefineZonesTab extends React.Component<DefineZonesTabProps, DefineZonesTabState> {
  constructor(props: DefineZonesTabProps) {
    super(props);
    const markedWatPCMSiteNumbers = props.deviceWafer.getMarkedPCMSiteNumbers();
    const freeFormZonePCMSiteNumber = markedWatPCMSiteNumbers.length > 0 ? +markedWatPCMSiteNumbers.map((x: number) => { return [x.toString(), x.toString()]; })[0][0] : null;
    props.onChangeFreeFormPCMSiteNumber(freeFormZonePCMSiteNumber);
    this.state = {
      zoneData: _.cloneDeep(props.zoneData),
      formErrors: {},
      freeFormZonePCMSiteNumber,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(props: DefineZonesTabProps, state: DefineZonesTabState) {
    if (props.zoneData.id !== state.zoneData.id) {
      return {
        zoneData: _.cloneDeep(props.zoneData),
      };
    }
    return null;
  }

  validateZoneKey = (key: string, value: any, zoneData: ZoneData) => {
    if (zoneData.zoneType === 'RADIAL' && key === 'numberOfZones' && (!/^\d+$/.test(value) || +value <= 1 || value === '' || value === null || value === undefined)) {
      return 'Value must be a whole number greater than 1';
    }
    if (key === 'zoneName') {
      if (value === '') {
        return 'Zone name is required';
      } else if (value.length > 255) {
        return 'Zone name should be less than 255 characters';
      }
    }
    return null;
  };

  validateZoneData = (zoneData: ZoneData) => {
    const formErrors: { [key: string] : any } = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in zoneData) {
      const error = this.validateZoneKey(key, zoneData[key], zoneData);
      if (error) {
        formErrors[key] = error;
      }
    }
    return formErrors;
  };

  onSaveZone = () => {
    const { zoneData } = this.state;
    const { onSaveZone, deviceWafer } = this.props;
    const formErrors = this.validateZoneData(zoneData);
    if (Object.keys(formErrors).length === 0) {
      this.setState({ formErrors }, () => {
        onSaveZone({
          id: zoneData.id || uuidv4(),
          zoneName: zoneData.zoneName,
          zoneType: zoneData.zoneType,
          zoneApplication: [...zoneData.zoneApplication],
          createdBy: zoneData.createdBy,
          updatedBy: zoneData.updatedBy,
          createdOn: zoneData.createdOn,
          updatedOn: zoneData.updatedOn,
          numberOfZones: zoneData.zoneType === 'VERTICAL' || zoneData.zoneType === 'HORIZONTAL' ? 3 : zoneData.numberOfZones,
          markedPCMSiteNumbers: [...deviceWafer.getMarkedPCMSiteNumbers()].sort((a, b) => (a - b)),
        });
      });
    } else {
      this.setState({ formErrors });
    }
  };

  onChangeZoneData = (key: string, value: any) => {
    const { zoneData } = this.state;
    if (!(key in zoneData) || _.isEqual(zoneData[key], value)) return;
    this.setState((prevState: DefineZonesTabState) => {
      if (key in prevState.zoneData) {
        const formErrors = { ...prevState.formErrors };
        formErrors[key] = this.validateZoneKey(key, value, prevState.zoneData);
        const newZoneData = _.cloneDeep(prevState.zoneData);
        newZoneData[key] = value;
        return { zoneData: newZoneData, formErrors };
      }
      return { zoneData: prevState.zoneData, formErrors: prevState.formErrors };
    });
  };

  render() {
    const {
      zoneData, formErrors, freeFormZonePCMSiteNumber,
    } = this.state;
    const {
      onClickDefineZones, viewDefineZonesBtnText, deviceWafer, onChangeFreeFormPCMSiteNumber, onClear,
    } = this.props;

    let markedWatPCMSiteNumbersList: string[][] = [];
    if (zoneData.zoneType === 'FREE_FORM') {
      const markedWatPCMSiteNumbers = deviceWafer.getMarkedPCMSiteNumbers();
      markedWatPCMSiteNumbersList = markedWatPCMSiteNumbers.map((x: number) => { return [x.toString(), x.toString()]; });
    }

    return (
      <div className="custom-form mt10" style={{ fontSize: '10px' }}>
        <ScrollView
          height="85vh"
          showScrollbar="onHover"
          scrollByThumb
          reachBottomText=""
        >
          <Label
            labelTitle="Zone Name"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            required
            error={formErrors.zoneName}
            isFieldTouched
            className="mb5"
          >
            <Textbox
              autoComplete="off"
              className={`${addErrorClasses(
                formErrors.zoneName,
                true,
              )}`}
              type="text"
              name="zoneName"
              value={zoneData.zoneName}
              placeholder="Zone Name"
              onChange={(e: any) => { this.onChangeZoneData('zoneName', e.target.value); }}
            />
          </Label>
          <Label
            labelTitle="Zone Type"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            required
            error={formErrors.zoneType}
            isFieldTouched
            className="mb5"
          >
            <CustomizedDropdown
              variant="clear"
              full
              list={ZONE_TYPES}
              selectedValue={zoneData.zoneType || ZONE_TYPES[0][0]}
              onChange={(value: any) => { this.onChangeZoneData('zoneType', value); }}
            />
          </Label>
          <Label
            labelTitle="Apply Zones To"
            labelPosition="left-middle"
            labelSize="40"
            fieldSize="60"
            errorSize="100"
            error={formErrors.zoneApplication}
            isFieldTouched
            className="mb5"
          >
            <TagBox
              items={Object.values(ZONE_APPLICATION)}
              value={zoneData.zoneApplication}
              onValueChanged={(e: any) => { this.onChangeZoneData('zoneApplication', [...e.value]); }}
              showSelectionControls
            />
          </Label>
          <div className="mt10">
            { zoneData.zoneType === 'RADIAL' && (
              <Label
                labelTitle="Define # of Zones"
                labelPosition="left-middle"
                labelSize="40"
                fieldSize="60"
                errorSize="100"
                required
                error={formErrors.numberOfZones}
                isFieldTouched
                className="mb5"
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.numberOfZones,
                    true,
                  )}`}
                  type="number"
                  name="numberOfZones"
                  value={zoneData.numberOfZones}
                  placeholder="Number of Zones"
                  onChange={(e: any) => { this.onChangeZoneData('numberOfZones', e.target.value); }}
                />
              </Label>
            )}
          </div>
          <Button
            onClick={() => { this.onSaveZone(); }}
            size="sm"
            variant="success"
            className="mt10 mb30 mr10"
          >
            <FontAwesomeIcon size="sm" icon={faSave} className="mr6" />
            { zoneData.id ? 'Update' : 'Add' }
          </Button>
          <Button
            onClick={() => { onClear(); }}
            size="sm"
            variant="primary"
            className="mt10 mb30 mr10"
          >
            <FontAwesomeIcon size="sm" icon={faRedo} className="mr6" />
            Clear
          </Button>
          <Button
            onClick={onClickDefineZones}
            size="sm"
            variant="primary"
            className="mt10 mb30"
          >
            {viewDefineZonesBtnText}
          </Button>
          <div>
            { !zoneData.id && zoneData.zoneType === 'FREE_FORM'
          && (
            <Alert show variant="warning">
              Save zone to mark zone sites.
            </Alert>
          )}
          </div>
          <div>
            {
              zoneData.zoneType === 'FREE_FORM' && markedWatPCMSiteNumbersList.length <= 0 && (

                <ErrorLabel
                  error="No PCM Site marked on Wafer"
                  isTouched
                />

              )
            }
          </div>
          <div className="mt10">
            { zoneData.zoneType === 'FREE_FORM' && (
              <Label
                labelTitle="Define PCM/WAT Zone"
                labelPosition="left-middle"
                labelSize="40"
                fieldSize="60"
                errorSize="100"
                isFieldTouched
                style={{
                  color: markedWatPCMSiteNumbersList.length <= 0 || !zoneData.id ? 'grey' : 'black',
                }}
              >
                <CustomizedDropdown
                  variant="clear"
                  full
                  disabled={markedWatPCMSiteNumbersList.length <= 0 || !zoneData.id}
                  list={[['null', 'Un-assign']].concat(markedWatPCMSiteNumbersList)}
                  selectedValue={freeFormZonePCMSiteNumber === null ? 'null' : freeFormZonePCMSiteNumber.toString()}
                  onChange={(value: any) => {
                    const newValue = value === 'null' ? null : +value;
                    onChangeFreeFormPCMSiteNumber(newValue);
                    this.setState({ freeFormZonePCMSiteNumber: newValue });
                  }}
                />
              </Label>
            )}
          </div>
        </ScrollView>
      </div>
    );
  }
}
