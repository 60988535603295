import React from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { faMinus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../wrapped-component/hint-controls/Button';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import Label from '../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import Checkbox from '../../../wrapped-component/hint-controls/Checkbox';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { addErrorClasses } from '../../../template-component/form-error-label/ErrorLabel';
import {
  DefaultPolicyStepSettings, ILoaderConverterPolicyStepSettings, IPolicyStep, ISourceLocation,
} from '../../../../interfaces';
import LocationType from './LocationType';

interface ILoaderPolicyConverterStepSettingsProps {
  updatePolicyStepSettings: (stepSettings: any) => void;
  stepSettings?: any;
  updatedCreated: any;
  policySteps: IPolicyStep[];
}

class LoaderConverterPolicyStepSettings extends React.Component<ILoaderPolicyConverterStepSettingsProps, any> {
  private policyStepSettingsReference: any;

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  constructor(props: ILoaderPolicyConverterStepSettingsProps) {
    super(props);
    const { stepSettings } = this.props;

    let loaderPolicyStepSettings: ILoaderConverterPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.Converter));
    if (stepSettings) {
      loaderPolicyStepSettings = stepSettings;
    }
    this.state = {
      loaderPolicyStepSettings,
    };
    this.policyStepSettingsReference = React.createRef();
    this.renderPrePostConditions = this.renderPrePostConditions.bind(this);
  }

  updateSourceLocation = (sourceLocation: ISourceLocation) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    loaderPolicyStepSettings.sourceLocation = sourceLocation;
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  conditionUpdateHandler = (value: string, flag: string, index: any, key: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions[index][key] = value;
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions[index][key] = value;
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  deleteCondition = (index: any, flag: string) => {
    const { loaderPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      loaderPolicyStepSettings.preConditions.splice(index, 1);
    } else if (flag === 'POST') {
      loaderPolicyStepSettings.postConditions.splice(index, 1);
    }
    this.setState({
      loaderPolicyStepSettings,
    });
    updatePolicyStepSettings(loaderPolicyStepSettings);
  };

  renderPrePostConditions = (conditions: any, flag: any) => {
    return conditions?.map((condition: any, index: any) => (
      <Label
        labelTitle={`${index + 1}.`}
        labelPosition="left-middle"
        labelSize="10"
        fieldSize="90"
        errorSize="0"
        childrenItemsInline
        className={`d-flex ${index > 0 ? 'border-top pt10' : ''}`}
      >
        <div className="w-100 flex-100 d-flex align-items-center">
          <div className="w-100 flex-80 flex-column">
            <div
              className="mr10 custom-form-inline label-left"
            >
              <span className="label mr10 w30">If</span>
              <Textbox
                containerClassName="w-80"
                defaultValue={condition.condition}
                value={condition.condition}
                className="w-100"
                autoComplete="off"
                name="if"
                placeholder="Condition"
                onBlur={(event:any) => {
                  this.conditionUpdateHandler(event.target.value, flag, index, 'condition');
                }}
              />
            </div>

            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Then</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.thenClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'thenClause');
                }}
              />
            </div>
            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Else</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.elseClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'elseClause');
                }}
              />
            </div>
          </div>

          <div className="flex-20 mt10">
            <Button
              variant="outline-primary"
              className="w40"
              onClick={() => this.deleteCondition(index, flag)}

            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
          </div>
        </div>
      </Label>
    ));
  };

  render() {
    const {
      loaderPolicyStepSettings,
    } = this.state;
    const {
      updatedCreated,
      updatePolicyStepSettings,
      policySteps,
    } = this.props;
    const formValidationSchema = Yup.object({
      stepOwner: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
      additionalEmail: Yup.string().email().when([], () => {
        return Yup.string().email('Must be a valid email');
      }),
    });
    return (
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          stepOwner: '',
          additionalEmail: '',
          loaderPolicyStepSettings,
          updatedCreated,
        }}
        validationSchema={formValidationSchema}
        onSubmit={() => undefined}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          touched,
        }) => (
          <form
            onChange={handleChange}
            onSubmit={handleSubmit}
            ref={this.policyStepSettingsReference}
            className="custom-form h-100"
          >
            <div className="step-settings-block indent p20 converter-step-settings-block">
              <Container fluid className="h-100 overflow-visible">
                <Row>
                  <Col lg={6} className="border-right">
                    <Heading size={6}>
                      Source
                    </Heading>
                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Source Format</span>
                      <div className="flex-70">
                        <CustomizedDropdown
                          full
                          variant="clear"
                          selectedValue={loaderPolicyStepSettings.sourceFormat}
                          onChange={(value) => {
                            loaderPolicyStepSettings.sourceFormat = value;
                            this.setState({ loaderPolicyStepSettings });
                          }}
                          list={[['STDF', 'STDF'], ['IMS', 'IMS'], ['IMS_LA', 'IMS LA'], // ['SiGe', 'SiGe'], ['ATDF', 'ATDF'],
                            ['AOI', 'AOI'], ['TSMC_WAT', 'TSMC WAT'], ['UMC_WAT', 'UMC WAT'], ['SMIC_WAT', 'SMIC WAT'],
                            ['GSMC_WAT', 'GSMC WAT'], ['FUJITSU_WAT', 'FUJITSU WAT']]}
                        />
                      </div>
                    </div>

                    <LocationType
                      updateSourceLocation={this.updateSourceLocation}
                      policySteps={policySteps}
                      sourceLocation={loaderPolicyStepSettings.sourceLocation}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={6}>
                    <Heading size={6}>
                      Target
                    </Heading>
                    <div className="custom-form-inline label-left">
                      <span className="label flex-30">Target Format</span>
                      <div className="flex-70">
                        <CustomizedDropdown
                          full
                          variant="clear"
                          selectedValue={loaderPolicyStepSettings.targetFormat}
                          onChange={(value) => {
                            loaderPolicyStepSettings.targetFormat = value;
                            this.setState({ loaderPolicyStepSettings });
                            updatePolicyStepSettings(loaderPolicyStepSettings);
                          }}
                          list={[['ATDF', 'ATDF'], // ['STDF', 'STDF'], ['IMS', 'IMS'], ['IMS_LA', 'IMS LA'], ['SiGe', 'SiGe'],
                            // ['AOI', 'AOI'], ['TSMC_WAT', 'TSMC WAT'], ['UMC_WAT', 'UMC WAT'], ['SMIC_WAT', 'SMIC WAT'],
                            // ['GSMC_WAT', 'GSMC WAT'], ['FUJITSU_WAT', 'FUJITSU WAT']
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt20">
                  <Col lg={6} className="border-right d-none">
                    <Heading size={6}>Owner</Heading>
                    <Label
                      labelTitle="Step Owner"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.stepOwner}
                      isFieldTouched={touched.stepOwner}
                    >
                      <Textbox
                        disabled
                        autoComplete="off"
                        name="stepOwner"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.stepOwner,
                          touched.stepOwner,
                        )}`}
                      />
                    </Label>
                  </Col>
                  <Col lg={6} className="d-none">
                    <Heading size={6}>Notifications</Heading>
                    <Label
                      labelTitle="Additional Emails"
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                      error={errors.additionalEmail}
                      isFieldTouched={touched.additionalEmail}
                    >
                      <Textbox
                        disabled
                        autoComplete="off"
                        name="additionalEmail"
                        placeholder="mark.smith@example.com"
                        className={`${addErrorClasses(
                          errors.additionalEmail,
                          touched.additionalEmail,
                        )}`}
                      />
                    </Label>
                    <Checkbox
                      disabled
                    >
                      Notify Owners
                    </Checkbox>
                  </Col>
                </Row>
              </Container>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default LoaderConverterPolicyStepSettings;
