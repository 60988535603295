import React from 'react';
import { Button, useAccordionToggle } from 'react-bootstrap';

export const CustomToggle = (props: any) => {
  const { eventKey, children, setCallback } = props;
  const decoratedOnClick = useAccordionToggle(eventKey, () => setCallback && setCallback(eventKey));

  return (
    <Button
      variant="link"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
};
