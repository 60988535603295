import { faGripVertical, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { ScrollView, CheckBox } from 'devextreme-react';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SortableHandle, SortableElement, SortableContainer } from 'react-sortable-hoc';
import { toTitleCase } from 'GeneralUtils'
import { IFieldExpression, IReportHeaderSetting } from './ReportHeaderBuilder';

interface IReportHeaderFieldsCardViewProps {
  reportHeaderSettings?: IReportHeaderSetting,
  isReadOnly: boolean,
  onFieldDragActionEnd?: ({ oldIndex, newIndex }: any) => void;
  removeField?: (index:number) => void;
  updateFieldLabel?: (value:string, fieldNameToDisplay:string, columnName:string, entityType:string) => void;
  reportHeaderSettingsFields? : IFieldExpression[]
}

const ReportHeaderFieldsCardView = (props:IReportHeaderFieldsCardViewProps) => {
  const {
    reportHeaderSettings,
    onFieldDragActionEnd,
    removeField,
    updateFieldLabel,
    isReadOnly,
    reportHeaderSettingsFields,
  } = props;

  const DragHandle = SortableHandle(() => (
    <div
      className="h-100 pt10 pb10 pl12 pr12 cursor-pointer border-none"
    >
      <FontAwesomeIcon
        size="1x"
        className="icon"
        icon={faGripVertical}
      />
    </div>
  ));

  const SortableItem = SortableElement(({ value: field }: any) => {
    const entityTypeToDisplay = toTitleCase(field.entityType.toUpperCase());
    const fieldNameToDisplay = toTitleCase(field.columnName.toUpperCase());
    let fieldCardConditionalClassNames = 'h150';
    if (isReadOnly) {
      fieldCardConditionalClassNames = 'h60 p10';
    }

    return (
      <div
        style={{
          zIndex: 10000,
        }}
        className="flex-33 pr10 pb10"
      >
        <div className={`border-all d-flex flex-column justify-content-between background-color-body-background ${fieldCardConditionalClassNames}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {!isReadOnly && <DragHandle />}
              <div>
                <p className="mb0 color-disabled-color">
                  {entityTypeToDisplay}
                </p>
                <h6 className="mb0">
                  {isReadOnly ? field.fieldLabel : fieldNameToDisplay}
                </h6>
              </div>
            </div>
            {!isReadOnly
              && (
                <button
                  type="button"
                  onClick={() => {
                    if (removeField) {
                      removeField(field.sequence);
                    }
                  }}
                  className="color-danger p10 cursor-pointer border-none background-color-none"
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faTrash}
                  />
                </button>
              )}
          </div>
          {
            !isReadOnly && (
              <div className="p12">
                {field.entityType === 'SUMMARY_STATISTICS' && (
                  <Label labelSize="90" labelPosition="left-middle" errorSize="0" labelTitle="Show Graph" fieldSize="10">
                    <CheckBox disabled={!field?.graphType} defaultValue />
                  </Label>
                )}
                <Label
                  labelTitle="Field Label"
                  labelPosition="top"
                  labelSize="100"
                  fieldSize="100"
                  errorSize="100"
                  required
                  error=""
                  isFieldTouched
                >
                  <Textbox
                    autoComplete="off"
                    name="fieldLabel"
                    type="text"
                    placeholder={fieldNameToDisplay}
                    defaultValue={field.fieldLabel}
                    className=""
                    onBlur={(e:any) => {
                      if (updateFieldLabel) {
                        updateFieldLabel(e.target.value, fieldNameToDisplay, field.columnName, field.entityType);
                      }
                    }}
                  />
                </Label>
              </div>
            )
          }
        </div>
      </div>
    );
  });
  const SortableList = SortableContainer(({ items: fieldsList, axis }: any) => {
    return (
      <div className="d-flex align-items-center flex-wrap">
        {fieldsList.map((field: IFieldExpression) => {
          return <SortableItem axis={axis} key={field.sequence} index={field.sequence} value={field} />;
        })}
      </div>
    );
  });

  const readOnlyInformation = [
    { field: 'name', label: 'Name' },
    { field: 'titleFontSize', label: 'Title Font Size' },
    { field: 'fieldFontSize', label: 'Field Font Size' },
    { field: 'displayFieldsCount', label: 'Display Fields Count' },
    { field: 'rowCount', label: 'Row Count' },
    { field: 'columnCount', label: 'Column Count' },
    { field: 'itemsAlignment', label: 'Items Alignment' },
  ];

  return (
    <>
      {
        isReadOnly
          && (
            <div className="pr10 mb10 pb15 break-bottom">
              <h4 className="mb10">{reportHeaderSettings?.title}</h4>
              <Row>
                {readOnlyInformation.map((infoObject) => ((reportHeaderSettings as any)[infoObject.field]
                  ? (
                    <Col lg={6} className="mb2 break-right">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="color-disabled-color">
                          {infoObject.label}
                          :
                        </span>
                        <span>
                          <b>
                            {(reportHeaderSettings as any)[infoObject.field]}
                          </b>
                        </span>
                      </div>
                    </Col>
                  ) : <></>
                ))}
              </Row>
            </div>
          )
      }
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
        className="h400"
      >
        <div>
          {reportHeaderSettingsFields && reportHeaderSettingsFields?.length > 0 && (
            <SortableList
              axis="xy"
              items={reportHeaderSettingsFields}
              onSortEnd={onFieldDragActionEnd}
              useDragHandle
            />
          )}
        </div>
      </ScrollView>
    </>
  );
};

export default ReportHeaderFieldsCardView;
