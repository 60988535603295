import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RandomNumberUtility } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/RandomNumberUtility';
import AxisBreaks from '../../components/utility-component/axis-breaks/AxisBreaks';
// import WaferMap from '../../components/utility-component/wafer-map-widget/wafer-map/WaferMap';

// Testing code only for data generation (demo reasons)
const test = new RandomNumberUtility();
const centerOfEclipse1 = 150; // Center of Wafer X Axis
const centerOfEclipse2 = 250; // Center of Wafer Y Axis
const widthOfEclipse = 20; // Number of Rows in Wafer Map Data
const heightOfEclipse = widthOfEclipse / 2; // Number of Columns in Wafer Map Data
const dieSize = { dieWidth: 64, dieHeight: 32 }; // database
const obj = test.randomWaferDataWithBinNum(
  centerOfEclipse1,
  centerOfEclipse2,
  widthOfEclipse,
  heightOfEclipse,
  [1, 2, 3, 4, 5, 6, 7, 8, 9],
);

type UtilitiesProps = {
  className?: string
};

const Utilities = ({ className }: UtilitiesProps) => {
  // console.log(obj);

  return (
    <Container fluid className={className}>
      <Row>
        <Col>
          <div className="pl10 pt10 pr10 mt-6">
            {/* <WaferMapGridView */}
            {/*  keyIndex="wafergridviewpoc" */}
            {/*  waferData={obj.data} */}
            {/*  dieSubView={obj.dieSubView} */}
            {/*  dieSize={dieSize} */}
            {/* /> */}
            <AxisBreaks />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Utilities.defaultProps = {
  className: '',
};

export default Utilities;
