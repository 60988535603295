import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import ScrollView from 'devextreme-react/scroll-view';
import PolicyBlock from '../../components/policy/policy-block/PolicyBlock';
import { httpPreference } from 'services/http.preference';

const CreatePolicy = (props:any) => {
  const { className } = props;
  const location = useLocation();
  let data : any;
  if (location.state !== null && location.state !== undefined) {
    data = location.state;
  }

  const [visibleColumnsJsonObj, setVisibleColumnsJson] = React.useState({});
  useEffect(() => {
    if (location.pathname === '/create-policy') {
      httpPreference.getPreferenceByName('SCW_DEFAULT_LAYOUT')
        .then((responseData) => {
          const response = JSON.parse(responseData.value);
          setVisibleColumnsJson(response);
        });
    }
  }, []);

  return (
    <ScrollView
      showScrollbar="always"
      scrollByThumb
      reachBottomText=""
    >
      <Container fluid className={`${className}`}>
        <Row>
          <Col lg={12} md={12}>
            <PolicyBlock
              id={data !== undefined ? data.Id : ''}
              visibleColumnsJsonObj={visibleColumnsJsonObj}
            />
          </Col>
        </Row>
      </Container>
    </ScrollView>
  );
};

CreatePolicy.defaultProps = {
  className: '',
};

export default CreatePolicy;
