import React from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import Plotly from 'plotly.js';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Heading from '../../wrapped-component/hint-controls/Heading';
import Button from '../../wrapped-component/hint-controls/Button';
import PageHeader from '../../template-component/page-header/PageHeader';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { ErrorLabel, addErrorClasses } from '../../template-component/form-error-label/ErrorLabel';

class CreateGDBNRule extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
    this.generateGrid = this.generateGrid.bind(this);
    this.state = { gridSize: 3 };
  }

  componentDidMount() {
    this.generateGrid();
  }

  generateGrid = () => {
    const { gridSize } = this.state;
    const size = gridSize;
    const gridContainer = document.getElementById('grid-container');
    if (gridContainer !== null) {
      Plotly.react(gridContainer, [
        {
          type: 'scatter',
          fill: 'tozeroy',
          mode: 'markers',
          marker: {
            color: 'rgba(255,255,255,0)',
            line: {
              color: 'rgba(255,255,255,0)',
            },
          },
          showlegend: false,
        },
      ], {
        hovermode: false,
        xaxis: {
          side: 'top',
          linecolor: '#D8DCE6',
          range: [0, size],
          tick0: 1,
          dtick: 1,
          ticklen: size,
          tickcolor: '#ffffff',
          mirror: true,
          ticks: 'outside',
          showline: true,
          fixedrange: true,
        },
        yaxis: {
          linecolor: '#D8DCE6',
          range: [size, 0],
          autorange: 'reversed',
          tick0: 1,
          dtick: 1,
          ticklen: size,
          tickcolor: '#ffffff',
          mirror: true,
          ticks: 'outside',
          showline: true,
          fixedrange: true,
        },
      }, { displayModeBar: false });
    }
  };

  render() {
    const { gridSize } = this.state;
    const formValidationSchema = Yup.object({
      name: Yup.string().required('Required').max(20),
      shortDescription: Yup.string().required('Required').max(1000),
      description: Yup.string().max(1000),
      grid: Yup.number().required('Required').max(20),
    });
    return (
      <>
        <PageHeader
          title="HMT_test901 Rule"
          breadcrumbs="Rules \ GDBN \ HMT_test901"
          className="pl30 pb10"
          titleActions={(
            <div className="pt10 d-flex align-items-end">
              <span className="pb7 mr10" style={{ color: 'grey' }}>v1.0</span>
              <div className="dropdown-badge pb7">
                <CustomizedDropdown disabled variant="clear" list={[['1', 'Draft'], ['1', 'Active']]} />
              </div>
            </div>
          )}
          primaryActions={(
            <div className="d-flex align-items-center justify-content-between pl10 pr10">
              <div>
                <span className="color-dark-three mr7 mt-10">Updated By</span>
                <br />
                <span className="color-dark-three mt-10 mr7"><u>Ken Edwards</u></span>
                <span className="color-dark-three mr7 mt-10">on</span>
                <span className="color-dark-three mt-10"><u>26th Jul 2020</u></span>
              </div>
              <div className="text-right">
                <span className="color-dark-three mt-10">Created By</span>
                <br />
                <span className="color-dark-three mt-10 mr7"><u>Matt Anderson</u></span>
                <span className="color-dark-three mr7 mt-10">on</span>
                <span className="color-dark-three mt-10"><u>26th Jul 2020</u></span>
              </div>
            </div>
          )}
          secondaryActions={(
            <span className="color-primary mt-10">Rule ID: 246</span>
          )}
        />
        <Container fluid className="pl30">
          <Row>
            <Col>

              <Formik
                validateOnBlur
                initialValues={{
                  name: '',
                  shortDescription: '',
                  description: '',
                  grid: gridSize,
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values:any) => {
                  console.log(values);
                }}
              >
                {({
                  handleSubmit, handleChange, values, errors, touched,
                }) => (
                  <form onSubmit={handleSubmit} onChange={handleChange} className="custom-form">
                    <Container className="mt60 p0">
                      <Row>
                        <Col>
                          <Heading size={6}>General</Heading>
                          <label className="label-left" htmlFor="name">
                            <span className="label flex-20">
                              Name
                              <span className="required">*</span>
                            </span>
                            <input value={values.name} autoComplete="off" type="text" className={`flex-50 ${addErrorClasses(errors.name, touched.name)}`} name="name" placeholder="Name" />
                            <ErrorLabel
                              error={errors.name}
                              isTouched={touched.name}
                            />
                          </label>
                          {/** ---------------------------------- */}
                          <label className="label-left" htmlFor="shortDescription">
                            <span className="label flex-20">
                              Short Description
                              <span className="required">*</span>
                            </span>
                            <input value={values.shortDescription} autoComplete="off" type="text" className={`flex-50 ${addErrorClasses(errors.shortDescription, touched.shortDescription)}`} name="shortDescription" placeholder="Short Description" />
                            <ErrorLabel
                              error={errors.shortDescription}
                              isTouched={touched.shortDescription}
                            />
                          </label>
                          {/** ---------------------------------- */}
                          <label className="label-textarea" htmlFor="description">
                            <span className="label flex-20">
                              Description
                            </span>
                            <textarea value={values.description} rows={3} className={`flex-50 ${addErrorClasses(errors.description, touched.description)}`} name="description" placeholder="Add detailed notes to this rule..." />
                            <ErrorLabel
                              error={errors.description}
                              isTouched={touched.description}
                            />
                          </label>
                          {/** ---------------------------------- */}
                          <br />
                          <Heading size={6}>GDBN</Heading>
                          <div className="custom-form-inline label-left">
                            <span className="label flex-20">
                              Filter Type
                            </span>
                            <div className="flex-50">
                              <CustomizedDropdown
                                full
                                variant="clear"
                                list={[
                                  ['1', 'Repeater'],
                                  ['1', 'Gross'],
                                ]}
                              />
                            </div>
                            <span className="error-label flex-30" />
                          </div>
                          {/** ---------------------------------- */}
                          <label className="label-left" htmlFor="name">
                            <span className="label flex-20">
                              Pass Bins
                              <span className="required">*</span>
                            </span>
                            <input value={values.name} autoComplete="off" type="text" className={`flex-50 ${addErrorClasses(errors.name, touched.name)}`} name="name" placeholder="Name" />
                            <ErrorLabel
                              error={errors.name}
                              isTouched={touched.name}
                            />
                          </label>
                          {/** ---------------------------------- */}
                          <label className="label-left" htmlFor="name">
                            <span className="label flex-20">
                              Failed Bins
                              <span className="required">*</span>
                            </span>
                            <input value={values.name} autoComplete="off" type="text" className={`flex-50 ${addErrorClasses(errors.name, touched.name)}`} name="name" placeholder="Name" />
                            <ErrorLabel
                              error={errors.name}
                              isTouched={touched.name}
                            />
                          </label>
                          {/** ---------------------------------- */}
                          <div className="custom-form-inline label-left">
                            <span className="label flex-20">
                              Failed Bin Number
                            </span>
                            <div className="flex-50">
                              <CustomizedDropdown
                                full
                                variant="clear"
                                list={[
                                  ['1', '1 Bin Name'],
                                  ['1', '12 Bin Name'],
                                  ['1', '17 Bin Name'],
                                  ['1', '21 Bin Name']]}
                              />
                            </div>
                            <span className="error-label flex-30" />
                          </div>
                          {/** ---------------------------------- */}
                          <br />
                          <Heading size={6}>Filter</Heading>
                          <label className="label-left" htmlFor="name">
                            <span className="label flex-20">
                              Filter Matrix
                            </span>
                            <div className="flex-50 d-flex align-items-center justify-content-between">
                              <button
                                type="button"
                                className="btn btn-outline-primary mr10"
                                onClick={() => {
                                  if (gridSize > 1) {
                                    this.setState({ gridSize: gridSize - 1 });
                                    setTimeout(this.generateGrid, 10);
                                  }
                                }}
                              >
                                -
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-primary mr10"
                                onClick={() => {
                                  this.setState({ gridSize: gridSize + 1 });
                                  setTimeout(this.generateGrid, 10);
                                }}
                              >
                                +
                              </button>
                              <input
                                value={gridSize}
                                autoComplete="off"
                                type="text"
                                disabled
                                name="grid"
                                placeholder="Grid"
                              />
                            </div>
                            <span className="flex-30" />
                          </label>
                          <div className="d-flex align-items-center justify-content-center">
                            <div id="grid-container" style={{ width: '800px', height: '800px' }} />
                          </div>
                          {/** ---------------------------------- */}
                        </Col>
                      </Row>
                      <Row className="pt20 pb50">
                        <Col lg={9}>
                          <Button type="submit" variant="primary" size="lg" className="mr10">
                            Save
                          </Button>
                          <Button variant="clear" size="lg">
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CreateGDBNRule;
