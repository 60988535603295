/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ComponentHeader from 'components/wrapped-component/component-header/component-header';
import { httpService } from 'services/http.service';
import { v4 as uuidv4 } from 'uuid';
import PATRuleSettings from './pat-rule-settings/PATRuleSettings';
import PATParameterSettings from './pat-parameter-settings/PATParameterSettings';
import DieSettings from './pat-die-settings/DieSettings';
import { httpMasterMeta } from '../../../../../../../services/http.master-meta';
import { IDieRules, PATRule, RuleTestParameter } from '../../../PATInterfaces';
import Heading from '../../../../../../wrapped-component/hint-controls/Heading';
import EntityHeader, { IEntityHeaderState } from '../../../../../../wrapped-component/entity-header/EntityHeader';

interface PATCreateRulePopupFormProps {
  searchCriteria: any;
  currentEditRule: PATRule;
  patRuleSetType: string;
  highlightTextBox: boolean;
}

interface PATCreateRulePopupFormState {
  currentEditRule: PATRule;
  dieInputSettingRules: IDieRules;
  dieOutputSettingRules: IDieRules;
  siteNumbers: number[];
  componentHeaderRef: any;
}

class PATCreateRulePopupForm extends Component<PATCreateRulePopupFormProps, PATCreateRulePopupFormState> {
  private ruleSettings: any = {};

  componentHeaderRef: any;

  private defaultDieSettingRules: IDieRules = {
    objects: [],
    operators: [
      {
        caption: '=',
        dataField: '==',
      },
      {
        caption: '<',
        dataField: '<',
      },
      {
        caption: '>',
        dataField: '>',
      },
      {
        caption: '!=',
        dataField: '!=',
      },
    ],
    typeOperations: [
      {
        dataType: 'string',
        operators: ['==', '!='],
      },
    ],
  };

  constructor(props: any) {
    super(props);
    const { currentEditRule } = this.props;
    this.ruleSettings = currentEditRule;
    this.state = {
      currentEditRule: _.cloneDeep(currentEditRule),
      dieInputSettingRules: JSON.parse(JSON.stringify(this.defaultDieSettingRules)),
      dieOutputSettingRules: JSON.parse(JSON.stringify(this.defaultDieSettingRules)),
      siteNumbers: [],
      componentHeaderRef: React.createRef(),
    };
  }

  getSiteNumbers = (SiteOption: string) => {
    const { searchCriteria } = this.props;
    const waferObjList = searchCriteria.filter((criteria: any) => criteria.controlType === 'WAFER');
    const waferIds = waferObjList.map((waferObj: any) => (waferObj.id !== 'None' ? waferObj.id : ''));
    return httpService.getUniqueSiteNumber({
      Ids: waferIds,
      SiteOption,
    });
  };

  async componentDidMount() {
    const { currentEditRule } = this.state;
    const individualColumnsList = await httpMasterMeta.getIndividualColumnsList();
    const siteNumbers = await this.getSiteNumbers(currentEditRule.siteOption);
    const objects = individualColumnsList.DIE.map((columnObj: any) => ({
      caption: columnObj.columnName,
      dataField: `die.DieDataRow["die_${columnObj.columnName}"]`,
      dataType: columnObj.type,
    }));
    this.defaultDieSettingRules.objects = objects;
    this.setState({
      dieInputSettingRules: JSON.parse(JSON.stringify(this.defaultDieSettingRules)),
      dieOutputSettingRules: JSON.parse(JSON.stringify(this.defaultDieSettingRules)),
      siteNumbers,
    });
  }

  getCurrentEditRule = (): PATRule => {
    let { currentEditRule } = this.state;
    const componentHeaderData = this.componentHeaderRef.onSave();
    currentEditRule = { ...currentEditRule, ...componentHeaderData };
    return currentEditRule;
  };

  onSaveRuleSettings = async (ruleSettings: { [key: string]: any }) => {
    let siteNumbers: number[] | null = null;
    if ('siteOption' in ruleSettings) {
      siteNumbers = await this.getSiteNumbers(ruleSettings.siteOption);
    }
    if ('purpose' in ruleSettings) {
      // eslint-disable-next-line no-param-reassign
      ruleSettings.description = ruleSettings.purpose;
    }
    this.setState((prevState: PATCreateRulePopupFormState) => {
      let newCurrentEditRule = _.cloneDeep(prevState.currentEditRule);
      newCurrentEditRule = { ...newCurrentEditRule, ...ruleSettings };
      return {
        currentEditRule: newCurrentEditRule,
        siteNumbers: siteNumbers === null ? prevState.siteNumbers : siteNumbers,
      };
    });
  };

  binConfigSelectionHandler = (selectedBinPlusDefinition: any, id: any) => {
    this.setState((prevState: PATCreateRulePopupFormState) => {
      const newCurrentEditRule = _.cloneDeep(prevState.currentEditRule);
      const ruleTestParameter = newCurrentEditRule.testParameters.find((x: RuleTestParameter) => {
        return x.id === id;
      });
      if (ruleTestParameter) {
        ruleTestParameter.binPlusDefinitionColor = selectedBinPlusDefinition.color;
        ruleTestParameter.binPlusDefinitionFlag = selectedBinPlusDefinition.pass_fail;
        ruleTestParameter.binPlusDefinitionName = selectedBinPlusDefinition.name;
        ruleTestParameter.binPlusDefinitionNumber = selectedBinPlusDefinition.number;
        ruleTestParameter.binPlusTableId = selectedBinPlusDefinition.binPlusTableId;
        ruleTestParameter.binPlusDefinitionId = selectedBinPlusDefinition.id;
      }
      return {
        currentEditRule: newCurrentEditRule,
      };
    });
  };

  getNewRuleTestParameter = (testParameter: any, siteNumber?: number): RuleTestParameter => {
    return {
      id: uuidv4(),
      sequence: 0,
      tpId: testParameter.test_parameter_id,
      // alias: '', // will be re-assigned
      lowKLimit: 0,
      highKLimit: 0,
      seedLowLimit: 0,
      seedHighLimit: 0,
      binPlusGroupType: '',
      binPlusDefinitionId: null,
      binPlusTableId: '',
      testParameterHighCtrlLimit: testParameter.test_parameter_high_ctrl_limit,
      testParameterLowCtrlLimit: testParameter.test_parameter_low_ctrl_limit,
      testParameterHighSpecLimit: testParameter.test_parameter_high_spec_limit,
      testParameterLowSpecLimit: testParameter.test_parameter_low_spec_limit,
      testParameterName: testParameter.test_parameter_name,
      testParameterNumber: testParameter.test_parameter_number,
      siteNumber,
    };
  };

  getNewRuleTestParameters = (siteNumberList: any[], currentTp: any, ruleTestParameters: RuleTestParameter[]): RuleTestParameter[] => {
    return siteNumberList.length > 0
      ? siteNumberList.map((siteNumber: number) => this.getNewRuleTestParameter(currentTp, siteNumber))
      : [this.getNewRuleTestParameter(currentTp)];
  };

  onSelectTestParameterHandler = (currentSelectedTPIds: string[], currentDeselectedTPIds: string[], currentTps?: any[]) => {
    this.setState((prevState: PATCreateRulePopupFormState) => {
      const newCurrentEditRule = _.cloneDeep(prevState.currentEditRule);
      if (currentDeselectedTPIds.length > 0) {
        newCurrentEditRule.testParameters = newCurrentEditRule.testParameters.filter((x: RuleTestParameter, index) => {
          return !currentDeselectedTPIds.includes(x.tpId);
        });
      }
      if (currentSelectedTPIds.length > 0 && currentTps) {
        for (let i = 0; i < currentTps.length; i += 1) {
          newCurrentEditRule.testParameters.push(...this.getNewRuleTestParameters(prevState.siteNumbers, currentTps[i], prevState.currentEditRule.testParameters));
        }
      }
      // eslint-disable-next-line no-param-reassign
      // newCurrentEditRule.testParameters.forEach((x: RuleTestParameter, index: number) => { x.alias = `P${index + 1}`; });
      return {
        currentEditRule: newCurrentEditRule,
      };
    });
  };

  onDieSettings = (dieSettings: any, key: string) => {
    if (this.validateDieAdvanceSettings(dieSettings)) {
      this.onSaveRuleSettings({ [key]: dieSettings });
    } else {
      toast.error('Fill the fields in Die Advanced Output Settings');
    }
  };

  validateDieAdvanceSettings = (arr: any) => {
    for (let obj = 0; obj < arr.length; obj += 1) {
      if (Object.keys(arr[obj]).length === 0) {
        return false;
      }
    }
    return true;
  };

  generateDieOutputSettingRules = () => {
    const {
      currentEditRule,
      dieOutputSettingRules,
    } = this.state;

    if (currentEditRule.testParameters && currentEditRule.testParameters.length > 0) {
      const newObjects = this.defaultDieSettingRules.objects;
      dieOutputSettingRules.objects = [
        ...currentEditRule.testParameters.map((tp: any) => ({
          caption: tp.testParameterName,
          dataField: `die.Parameters["${tp.test_parameter_id}"]`,
          dataType: 'number',
        })),
        ...newObjects,
      ];
    }
    return dieOutputSettingRules;
  };

  getSeedLimit = (seedLimitOutput: any) => {
    if (seedLimitOutput.length === 0) return;
    this.setState((prevState: PATCreateRulePopupFormState) => {
      const newCurrentEditRule = _.cloneDeep(prevState.currentEditRule);
      seedLimitOutput.forEach((seedLimitObj: any) => {
        // eslint-disable-next-line max-len
        const tp = newCurrentEditRule.testParameters.find((parameter: RuleTestParameter) => parameter.tpId === seedLimitObj.testParameterId && (seedLimitObj.siteNumber !== null ? seedLimitObj.siteNumber === parameter.siteNumber : true));
        if (tp) {
          tp.seedHighLimit = seedLimitObj.seedHighLimit;
          tp.seedLowLimit = seedLimitObj.seedLowLimit;
        }
      });
      return {
        currentEditRule: newCurrentEditRule,
      };
    });
  };

  updateCurrentTestParameters = (value: any) => {
    const { currentEditRule } = this.state;
    for (let index = 0; index < currentEditRule.testParameters.length; index += 1) {
      if (currentEditRule.testParameters[index].tpId === value) {
        currentEditRule.testParameters.splice(index, 1);
        this.setState({ currentEditRule });
      }
    }
  };

  render() {
    const {
      searchCriteria,
      patRuleSetType,
      highlightTextBox,
    } = this.props;
    const {
      currentEditRule,
      dieInputSettingRules,
    } = this.state;

    return (
      <Row>
        <Col className="custom-form mt20" lg={12}>
          <Row className="background-color-secondary-background rounded border-all m2 pt20 pb20 row">
            <Col lg={8}>
              <ComponentHeader
                className="background-color-light"
                componentHeaderData={{
                  name: currentEditRule.name,
                  version: currentEditRule.version,
                  owner: currentEditRule.owner,
                  access: '',
                  description: currentEditRule.description,
                  state: currentEditRule.state,
                }}
                onParentSave={() => {
                }}
                onBack={() => {
                }}
                hideSaveButton
                hideBackButton
                hideEditorsField
                ref={(ref: any) => { this.componentHeaderRef = ref; }}
                highlightTextBox={highlightTextBox}
              />
            </Col>
            <Col lg={4}>
              <Heading className="mb20" size={5}>Rule Configurations</Heading>
              <PATRuleSettings
                currentEditRule={currentEditRule}
                onPATRuleSettingsChangeHandler={this.onSaveRuleSettings}
              />
            </Col>
          </Row>
          <DieSettings
            rules={dieInputSettingRules}
            getDieSettings={(e: any) => this.onDieSettings(e, 'dieInputSettings')}
            collapseView
            title="Input Die Filter"
            dieSettings={currentEditRule.dieInputSettings}
            hideOutputSettings
          />
          <PATParameterSettings
            searchCriteria={searchCriteria}
            currentEditRule={currentEditRule}
            patRuleSetType={patRuleSetType}
            onSelectTestParameterHandler={this.onSelectTestParameterHandler}
            binConfigSelectionHandler={this.binConfigSelectionHandler}
            getSeedLimit={this.getSeedLimit}
            updateCurrentTestParameters={this.updateCurrentTestParameters}
          />
          <DieSettings
            rules={this.generateDieOutputSettingRules()}
            getDieSettings={(e: any) => this.onDieSettings(e, 'dieOutputSettings')}
            collapseView
            title="Die Advanced Output Settings"
            dieSettings={currentEditRule.dieOutputSettings}
            hideOutputSettings={false}
          />
        </Col>
      </Row>
    );
  }
}

export default PATCreateRulePopupForm;
