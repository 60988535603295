import React from 'react';
import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { faMinus, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'CustomToast';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../wrapped-component/hint-controls/Button';
import Heading from '../../../wrapped-component/hint-controls/Heading';
import Label from '../../../wrapped-component/hint-controls/Label';
import Textbox from '../../../wrapped-component/hint-controls/Textbox';
import CustomizedDropdown from '../../../wrapped-component/customized-dropdown/CustomizedDropdown';
import { DefaultPolicyStepSettings, IPATPolicyStepSettings, } from '../../../../interfaces';
import { httpService } from '../../../../services/http.service';
import ExpressionBuilder from '../../../utility-component/expression-builder/ExpressionBuilder';
import PATRuleSetSettings from '../PAT/pat-rule-set-settings/PATRuleSetSettings';
import RecalculateLimits from '../PAT/pat-components/RecalculateLimits';
import { PATRule, RuleTestParameter } from '../PAT/PATInterfaces';

interface IPATPolicyStepSettingsProps {
  searchCriteria?: any;
  updatePolicyStepSettings: (stepSettings: any) => void;
  updatePolicyStepExpressions: (step: any) => void;
  updateStepOwner: (stepOwner: string) => void;
  updateAdditionalEmail: (additionalEmail: string) => void;
  toggleShowRulesPanel: () => void;
  updateNotifyOwner: (notifyOwner: boolean) => void;
  updatePATRuleSetSettings: (patRuleSets: any) => void;
  updateRuleSetName: (value: string) => void;
  stepSettings?: any;
  patRuleSetSettings?: any;
  updatedCreated: any;
  showRulesPanel: boolean;
  rulesCount: number;
  notifyOwner: boolean;
  stepOwner: string;
  additionalEmail: string;
  expressions?: any;
}
interface IPATPolicyStepSettingsState {
  patPolicyStepSettings: any;
  patRuleSets: any[],
  selectedPatRuleSetObjId: null | string,
  expressions?: any,
}

class PATPolicyStepSettings extends React.Component<IPATPolicyStepSettingsProps, IPATPolicyStepSettingsState> {
  private policyStepSettingsReference: any;

  private alternatives = [
    ['- Select -', '- Select -'],
    ['CONTINUE', 'Continue'],
    ['EXIT', 'Exit with Error'],
    ['HOLD', 'Hold Policy'],
    ['ACTION', 'Perform Action'],
    ['SKIP', 'Skip Stage'],
  ];

  private patRuleSetIds = [
    ['AUTOMOTIVE', 'Automotive'],
    ['INDUSTRIAL', 'Industrial'],
  ];

  constructor(props: IPATPolicyStepSettingsProps) {
    super(props);
    const {
      stepSettings,
      patRuleSetSettings,
    } = this.props;

    let patPolicyStepSettings: IPATPolicyStepSettings = JSON.parse(JSON.stringify(DefaultPolicyStepSettings.PAT));

    if (stepSettings) {
      patPolicyStepSettings = stepSettings;
    }
    this.state = {
      patPolicyStepSettings,
      patRuleSets: patRuleSetSettings || [],
      selectedPatRuleSetObjId: patRuleSetSettings && patRuleSetSettings.length > 0 ? patRuleSetSettings[0].id : null,
      expressions: props.expressions ? props.expressions : [],
    };
    this.policyStepSettingsReference = React.createRef();
    this.renderPrePostConditions = this.renderPrePostConditions.bind(this);
  }

  conditionUpdateHandler = (value: string, flag: string, index: any, key: string) => {
    const { patPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      patPolicyStepSettings.preConditions[index][key] = value;
    } else if (flag === 'POST') {
      patPolicyStepSettings.postConditions[index][key] = value;
    }
    this.setState({
      patPolicyStepSettings,
    });
    updatePolicyStepSettings(patPolicyStepSettings);
  };

  deleteCondition = (index: any, flag: string) => {
    const { patPolicyStepSettings } = this.state;
    const {
      updatePolicyStepSettings,
    } = this.props;
    if (flag === 'PRE') {
      patPolicyStepSettings.preConditions.splice(index, 1);
    } else if (flag === 'POST') {
      patPolicyStepSettings.postConditions.splice(index, 1);
    }
    this.setState({
      patPolicyStepSettings,
    });
    updatePolicyStepSettings(patPolicyStepSettings);
  };

  renderPrePostConditions = (conditions: any, flag: any) => {
    return conditions.map((condition: any, index: any) => (
      <Label
        labelTitle={`${index + 1}.`}
        labelPosition="left-middle"
        labelSize="10"
        fieldSize="90"
        errorSize="0"
        childrenItemsInline
        className={`d-flex ${index > 0 ? 'border-top pt10' : ''}`}
      >
        <div className="w-100 flex-100 d-flex align-items-center">
          <div className="w-100 flex-80 flex-column">
            <div
              className="mr10 custom-form-inline label-left"
            >
              <span className="label mr10 w30">If</span>
              <Textbox
                containerClassName="w-80"
                defaultValue={condition.condition}
                value={condition.condition}
                className="w-100"
                autoComplete="off"
                name="if"
                placeholder="Condition"
                onBlur={(event: any) => {
                  this.conditionUpdateHandler(event.target.value, flag, index, 'condition');
                }}
              />
            </div>

            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Then</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.thenClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'thenClause');
                }}
              />
            </div>
            <div className="mr10 custom-form-inline label-left">
              <span className="label mr10 w30">Else</span>
              <CustomizedDropdown
                containerClassName="mr20 w-80"
                full
                variant="clear"
                list={this.alternatives}
                selectedValue={condition.elseClause}
                onChange={(value) => {
                  this.conditionUpdateHandler(value, flag, index, 'elseClause');
                }}
              />
            </div>
          </div>

          <div className="flex-20 mt10">
            <Button
              variant="outline-primary"
              className="w40"
              onClick={() => this.deleteCondition(index, flag)}

            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faMinus} />
            </Button>
          </div>
        </div>
      </Label>
    ));
  };

  addRuleSet = (patRuleSetType: string) => {
    this.setState((prevState: IPATPolicyStepSettingsState) => {
      const newPatRuleSets = _.cloneDeep(prevState.patRuleSets);
      const tempId = uuidv4();
      newPatRuleSets.push({
        patRuleSetType,
        patRuleSetSequence: prevState.patRuleSets.length,
        patRuleSetName: '',
        patRules: [],
        rules: [],
        id: tempId,
        isNew: true,
      });
      return {
        patRuleSets: newPatRuleSets,
        selectedPatRuleSetObjId: prevState.selectedPatRuleSetObjId || tempId,
      };
    });
  };

  patRuleSetNameHandler = (value: string) => {
    const { patRuleSets } = this.state;
    const { updateRuleSetName } = this.props;
    this.setState((prevState: IPATPolicyStepSettingsState) => {
      const newPatRuleSets = _.cloneDeep(prevState.patRuleSets);
      const selectedPatRuleSetObj = this.getPatRuleSetFromId(prevState.selectedPatRuleSetObjId, newPatRuleSets);
      if (selectedPatRuleSetObj) {
        selectedPatRuleSetObj.patRuleSetName = value;
        updateRuleSetName(selectedPatRuleSetObj.patRuleSetName);
      }
      return {
        patRuleSets: newPatRuleSets,
      };
    });
  };

  patGeneralSettingsHandler = (generalSettings: any) => {
    const { updatePolicyStepExpressions } = this.props;
    if (this.validateGeneralSettings(generalSettings)) {
      this.setState({ expressions: generalSettings }, () => updatePolicyStepExpressions(generalSettings));
    } else {
      toast.error('Fill the fields in general settings');
    }
  };

  validateGeneralSettings = (arr: any) => {
    for (let obj = 0; obj < arr.length; obj += 1) {
      if (Object.keys(arr[obj]).length === 0) {
        return false;
      }
    }
    return true;
  };

  createRuleObj = (createdRule: PATRule) => {
    return {
      Id: createdRule.id,
      Name: createdRule.name,
      Owner: createdRule.owner,
      ShortDescription: '',
      Description: createdRule.description,
      SiteOption: createdRule.siteOption,
      State: createdRule.state,
      SequenceId: createdRule.sequenceId,
      PATRuleType: createdRule.patRuleType,
      Version: createdRule.version,
      DieOutputSettings: createdRule.dieOutputSettings && createdRule.dieOutputSettings.length > 0 ? createdRule.dieOutputSettings : null,
      DieInputSettings: createdRule.dieInputSettings && createdRule.dieInputSettings.length > 0 ? createdRule.dieInputSettings : null,
      SeedLimit: createdRule.patRuleType === 'STATIC_PAT' ? createdRule.seedLimit : null,
      TestParameters: createdRule.testParameters.map((param: RuleTestParameter, paramIndex: number) => {
        return {
          TestParameter: { Id: param.tpId },
          // Alias: param.alias,
          Sequence: paramIndex,
          SiteNumber: param.siteNumber,
          // eslint-disable-next-line no-restricted-globals
          LowKLimit: +param.lowKLimit && !isNaN(+param.lowKLimit) ? +param.lowKLimit : null,
          // eslint-disable-next-line no-restricted-globals
          HighKLimit: +param.highKLimit && !isNaN(+param.highKLimit) ? +param.highKLimit : null,
          SeedHighLimit: (param.seedHighLimit),
          SeedLowLimit: (param.seedLowLimit),
          BinPlusDefinition: {
            Id: param.binPlusDefinitionId,
            Color: param.binPlusDefinitionColor,
            Number: param.binPlusDefinitionNumber,
            Name: param.binPlusDefinitionName,
            PassFail: param.binPlusDefinitionFlag,
          },
          BinGroupType: param.binPlusGroupType,
        };
      }),
    };
  };

  saveRuleHandler = (rule: any) => {
    const { selectedPatRuleSetObjId, patRuleSets } = this.state;
    const { updatePATRuleSetSettings } = this.props;

    const selectedPatRuleSetObj = this.getPatRuleSetFromId(selectedPatRuleSetObjId, patRuleSets);
    if (!selectedPatRuleSetObj) return;
    httpService
      .savePATRule({
        RuleJSON: JSON.stringify(rule)
          .toString(),
      })
      .then((savedPATRuleId: string) => {
        toast.success('Rule Saved!');
        const patRuleSetObj = {
          ruleId: savedPATRuleId,
          patRuleSetName: selectedPatRuleSetObj.patRuleSetName,
          patRuleSetType: selectedPatRuleSetObj.patRuleSetType,
          patRuleSetSequence: selectedPatRuleSetObj.patRuleSetSequence,
          seedLimitObj: selectedPatRuleSetObj.seedLimitObj,
        };
        selectedPatRuleSetObj.patRules[selectedPatRuleSetObj.patRules.length - 1].id = savedPATRuleId;
        if (updatePATRuleSetSettings) updatePATRuleSetSettings(patRuleSetObj);
      });
  };

  updateRuleHandler = (rule: any) => {
    httpService
      .updatePATRule({
        RuleJSON: JSON.stringify(rule)
          .toString(),
      })
      .then((updatedRuleId: string) => toast.success('Rule Updated!'));
  };

  saveNewPatRule = (createdRule: PATRule) => {
    let isRuleCreated = false;
    this.setState((prevState: IPATPolicyStepSettingsState) => {
      const newPatRuleSets = _.cloneDeep(prevState.patRuleSets);
      const selectedPatRuleSetObj = this.getPatRuleSetFromId(prevState.selectedPatRuleSetObjId, newPatRuleSets);
      if (!selectedPatRuleSetObj) return { patRuleSets: prevState.patRuleSets };

      let ruleIndex = -1;
      const ruleAlreadyExist = selectedPatRuleSetObj.patRules.find((ruleObj: PATRule, index: number) => {
        if (ruleObj.id === createdRule.id) ruleIndex = index;
        return ruleObj.id === createdRule.id;
      });
      if (ruleAlreadyExist) {
        createdRule.sequenceId = selectedPatRuleSetObj.patRules.length;
        selectedPatRuleSetObj.patRules[ruleIndex] = createdRule;
      } else {
        createdRule.sequenceId = selectedPatRuleSetObj.patRules.length;
        selectedPatRuleSetObj.patRules.push(createdRule);
        isRuleCreated = true;
      }
      return {
        patRuleSets: newPatRuleSets,
      };
    }, () => {
      const rule = this.createRuleObj(createdRule);
      if (isRuleCreated) {
        this.saveRuleHandler(rule);
      } else {
        this.updateRuleHandler(rule);
      }
    });
  };

  getSeedLimitData = (seedLimit: any) => undefined;

  getPatRuleSetFromId = (id: string | null, patRuleSets: any[]) => {
    const filteredRuleSets = patRuleSets.filter((x) => { return x.id === id; });
    return filteredRuleSets.length === 0 ? null : filteredRuleSets[0];
  };

  isSelectedRuleSequence = (patRuleSetObj: any) => {
    const { selectedPatRuleSetObjId, patRuleSets } = this.state;
    const selectedPatRuleSetObj = this.getPatRuleSetFromId(selectedPatRuleSetObjId, patRuleSets);
    return selectedPatRuleSetObj === null ? false : selectedPatRuleSetObj.patRuleSetSequence === patRuleSetObj.patRuleSetSequence;
  };

  render() {
    const {
      patPolicyStepSettings,
      patRuleSets,
      selectedPatRuleSetObjId,
      expressions,
    } = this.state;

    const {
      notifyOwner,
      updatedCreated,
      searchCriteria,
      stepSettings,
    } = this.props;

    const validEmailMsg = 'Must be a valid email';
    const formValidationSchema = Yup.object({
      stepOwner: Yup.string()
        .email()
        .when([], () => {
          if (notifyOwner) {
            return Yup.string()
              .email(validEmailMsg)
              .required('Required');
          }
          return Yup.string()
            .email(validEmailMsg);
        }),
      additionalEmail: Yup.string()
        .email()
        .when([], () => {
          if (notifyOwner) {
            return Yup.string()
              .email(validEmailMsg)
              .required('Required');
          }
          return Yup.string()
            .email(validEmailMsg);
        }),
    });
    return (
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={{
          stepOwner: '',
          additionalEmail: '',
          patPolicyStepSettings,
          updatedCreated,
        }}
        validationSchema={formValidationSchema}
        onSubmit={() => undefined}
      >
        {({
          handleSubmit,
        }) => (
          <form
            // onChange={handleChange}
            onSubmit={handleSubmit}
            ref={this.policyStepSettingsReference}
            className="custom-form h-100 background-color-primary-background"
          >
            <div className="step-settings-block indent p20 background-color-secondary-background">
              <Container fluid className="h-100 overflow-visible">
                <Row>
                  <Col lg={12} className="mt20">
                    <Heading size={4}>General Settings</Heading>
                  </Col>
                  <Col lg={12} className="mt20">
                    <ExpressionBuilder
                      defaultStatements={expressions}
                      onUpdate={this.patGeneralSettingsHandler}
                      rules={{
                        objects: [
                          {
                            caption: 'AbortCount',
                            dataField: 'wafer.AbortCount',
                            dataType: 'number',
                          },
                          {
                            caption: 'AtdfVersion',
                            dataField: 'wafer.AtdfVersion',
                            dataType: 'number',
                          },
                          {
                            caption: 'CableType',
                            dataField: 'wafer.CableType',
                            dataType: 'string',
                          },
                          {
                            caption: 'CardType',
                            dataField: 'wafer.CardType',
                            dataType: 'string',
                          }, {
                            caption: 'CenterX',
                            dataField: 'wafer.CenterX',
                            dataType: 'number',
                          }, {
                            caption: 'CenterY',
                            dataField: 'wafer.CenterY',
                            dataType: 'number',
                          }, {
                            caption: 'ContactorType',
                            dataField: 'wafer.ContactorType',
                            dataType: 'string',
                          },
                          {
                            caption: 'CpuType',
                            dataField: 'wafer.CpuType',
                            dataType: 'string',
                          },
                          {
                            caption: 'DeviceName',
                            dataField: 'device.Name',
                            dataType: 'string',
                          },
                          {
                            caption: 'DibType',
                            dataField: 'wafer.DibType',
                            dataType: 'string',
                          },
                          {
                            caption: 'DieHeight',
                            dataField: 'wafer.DieHeight',
                            dataType: 'number',
                          },
                          {
                            caption: 'DieWidth',
                            dataField: 'wafer.DieWidth',
                            dataType: 'number',
                          },
                          {
                            caption: 'ExecType',
                            dataField: 'wafer.ExecType',
                            dataType: 'string',
                          }, {
                            caption: 'ExecVersion',
                            dataField: 'wafer.ExecVersion',
                            dataType: 'string',
                          },
                          {
                            caption: 'ExtraType',
                            dataField: 'wafer.ExtraType',
                            dataType: 'number',
                          },
                          {
                            caption: 'FacilityName',
                            dataField: 'facility.Name',
                            dataType: 'string',
                          },
                          {
                            caption: 'Flat',
                            dataField: 'wafer.Flat',
                            dataType: 'string',
                          },

                          {
                            caption: 'FunctionalCount',
                            dataField: 'wafer.FunctionalCount',
                            dataType: 'number',
                          },
                          {
                            caption: 'GoodCount',
                            dataField: 'wafer.GoodCount',
                            dataType: 'number',
                          },
                          {
                            caption: 'HandlerType',
                            dataField: 'wafer.HandlerType',
                            dataType: 'string',
                          },
                          {
                            caption: 'IncludeInYield',
                            dataField: 'wafer.IncludeInYield',
                            dataType: 'boolean',
                          },
                          {
                            caption: 'IsActive',
                            dataField: 'wafer.IsActive',
                            dataType: 'boolean',
                          },
                          {
                            caption: 'IsExcluded',
                            dataField: 'lot.IsExcluded',
                            dataType: 'string',
                          },
                          {
                            caption: 'IsScaled',
                            dataField: 'wafer.IsScaled',
                            dataType: 'boolean',
                          },
                          {
                            caption: 'LaserType',
                            dataField: 'wafer.LaserType',
                            dataType: 'string',
                          },
                          {
                            caption: 'LoadBoardType',
                            dataField: 'wafer.LoadBoardType',
                            dataType: 'string',
                          },
                          {
                            caption: 'LotKey',
                            dataField: 'lot.Key',
                            dataType: 'string',
                          },
                          {
                            caption: 'LotTestTemperature',
                            dataField: 'lot.TestTemperature',
                            dataType: 'string',
                          },
                          {
                            caption: 'MarkedForWaferHunter',
                            dataField: 'wafer.MarkedForWaferHunter',
                            dataType: 'boolean',
                          },
                          {
                            caption: 'NodeName',
                            dataField: 'wafer.NodeName',
                            dataType: 'string',
                          },
                          {
                            caption: 'OperatorName',
                            dataField: 'wafer.OperatorName',
                            dataType: 'string',
                          },
                          {
                            caption: 'PcrAbortCount',
                            dataField: 'wafer.PcrAbortCount',
                            dataType: 'number',
                          },
                          {
                            caption: 'PcrFunctionalCount',
                            dataField: 'wafer.PcrFunctionalCount',
                            dataType: 'number',
                          },
                          {
                            caption: 'PositiveX',
                            dataField: 'wafer.PositiveX',
                            dataType: 'string',
                          },
                          {
                            caption: 'PositiveY',
                            dataField: 'wafer.PositiveY',
                            dataType: 'string',
                          },
                          {
                            caption: 'ProbeCount',
                            dataField: 'wafer.ProbeCount',
                            dataType: 'string',
                          },
                          {
                            caption: 'RetestCount',
                            dataField: 'wafer.RetestCount',
                            dataType: 'number',
                          },
                          ...(patRuleSets && patRuleSets.map((x: any) => ({
                            caption: `RuleSet("${x.patRuleSetType}","${x.patRuleSetName}")`,
                            dataField: `ExecutePATRuleSet(${x.patRuleSetType},${x.patRuleSetName})`,
                            dataType: 'string',
                          }))),
                          {
                            caption: 'SiteGroup',
                            dataField: 'wafer.SiteGroup',
                            dataType: 'number',
                          },
                          {
                            caption: 'SiteNumber',
                            dataField: 'wafer.SiteNumber',
                            dataType: 'number',
                          },
                          {
                            caption: 'StdfVersion',
                            dataField: 'wafer.StdfVersion',
                            dataType: 'number',
                          },
                          {
                            caption: 'TestProgramName',
                            dataField: 'testProgram.Name',
                            dataType: 'string',
                          },
                          {
                            caption: 'TestProgramRevisionNumber',
                            dataField: 'testProgramRevision.ProgramRevisionNumber',
                            dataType: 'string',
                          },
                          {
                            caption: 'TestTemperature',
                            dataField: 'wafer.TestTemperature',
                            dataType: 'string',
                          },
                          {
                            caption: 'TesterType',
                            dataField: 'wafer.TesterType',
                            dataType: 'string',
                          },
                          {
                            caption: 'TotalDiesInFile',
                            dataField: 'wafer.TotalDiesInFile',
                            dataType: 'number',
                          },
                          {
                            caption: 'WaferSize',
                            dataField: 'wafer.WaferSize',
                            dataType: 'number',
                          },
                          {
                            caption: 'WorkCenterName',
                            dataField: 'workCenter.Name',
                            dataType: 'string',
                          },
                          {
                            caption: 'Yield',
                            dataField: 'wafer.Yield',
                            dataType: 'number',
                          },
                          {
                            caption: 'MismatchedDieCount',
                            dataField: 'wafer.MismatchedDieCount',
                            dataType: 'number',
                          }
                        ],
                        operators: [
                          {
                            caption: '=',
                            dataField: '==',
                          },
                          {
                            caption: '<',
                            dataField: '<',
                          },
                          {
                            caption: '>',
                            dataField: '>',
                          },
                          {
                            caption: '!=',
                            dataField: '!=',
                          },
                        ],
                        typeOperations: [
                          {
                            dataType: 'string',
                            operators: ['==', '!='],
                          },
                        ],
                      }}
                    />
                  </Col>
                </Row>
                <Row className="d-none mt40">
                  <Col lg={12}>
                    <Heading size={4}>Exclude Lots/Wafers</Heading>
                  </Col>
                  <Col lg={6}>
                    <Label
                      className="mb20"
                      labelTitle="Exclude Lots/Wafers for Calculation: "
                      labelPosition="left-middle"
                      labelSize="30"
                      fieldSize="70"
                      errorSize="0"
                    >
                      <Textbox
                        autoComplete="off"
                        name="excludeExpression"
                        placeholder="Exclude Expression"
                        block
                      />
                    </Label>
                  </Col>
                </Row>
                <div className="d-none mt40">
                  <RecalculateLimits
                    title="Recalculate PAT Limits"
                    seedLimitObj={{}}
                    getSeedLimitData={this.getSeedLimitData}
                    showHeading
                  />
                </div>
                <Row>
                  <Col lg={12} className="d-flex mt20 align-items-center justify-content-between">
                    <Heading size={4}>PAT Rule Set Settings</Heading>
                    <CustomizedDropdown
                      avoidUpdateOnSelect
                      list={this.patRuleSetIds}
                      variant="outline-dark"
                      onChange={this.addRuleSet}
                      displayElement={<i className="dx-icon-plus" />}
                    />
                  </Col>
                  <Col lg={12} className="mt20">
                    {selectedPatRuleSetObjId !== null && patRuleSets.length > 0 ? (
                      <div className="background-color-light border-all p10">
                        <Tabs
                          id="controlled-tab-example"
                          onSelect={(key: any) => { this.setState({ selectedPatRuleSetObjId: key }); }}
                          activeKey={selectedPatRuleSetObjId}
                        >
                          {patRuleSets.map((patRuleSetObj: any) => {
                            return (
                              <Tab
                                className="p10"
                                eventKey={patRuleSetObj.id}
                                title={patRuleSetObj.patRuleSetType}
                                key={patRuleSetObj.id}
                              >
                                {selectedPatRuleSetObjId && this.isSelectedRuleSequence(patRuleSetObj) && patRuleSetObj !== null ? (
                                  <PATRuleSetSettings
                                    searchCriteria={searchCriteria}
                                    saveNewPatRule={this.saveNewPatRule}
                                    patRuleSetConfig={patRuleSetObj}
                                    patRuleSetNameHandler={this.patRuleSetNameHandler}
                                  />
                                ) : null}

                              </Tab>
                            );
                          })}
                        </Tabs>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default PATPolicyStepSettings;
