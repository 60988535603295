/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpLegend } from 'services/http.legend';
import { InteractionInputDTO, ICustomizedLegendData } from '../CustomizedReportInterfaces';
import { ILegendDataHelper } from '../CustomizedReportHelper';

export class ParametricXYScatterPlotLegendHelper implements ILegendDataHelper {
  private legendData : any = undefined;

  preferences = {
    summary: {
      calculateCustomSummary: (options:any) => {
        if (this.legendData.legendSummary.length > 0 && this.legendData.legendSummary[0][0][options.name]) {
          options.totalValue = this.legendData.legendSummary[0][0][options.name];
        }
      },
      totalItems: [
        {
          name: 'Combined AVG Parameter 1',
          summaryType: 'custom',
          displayFormat: 'Combined AVG Parameter 1: {0}',
          showInColumn: 'AVG Parameter 1',
          showInGroupFooter: true,
        },
        {
          name: 'Combined AVG Parameter 2',
          summaryType: 'custom',
          displayFormat: 'Combined AVG Parameter 2: {0}',
          showInColumn: 'AVG Parameter 2',
          showInGroupFooter: true,
        },
        {
          name: 'Combined MEDIAN Parameter 1',
          summaryType: 'custom',
          displayFormat: 'Combined MEDIAN Parameter 1: {0}',
          showInColumn: 'MEDIAN Parameter 1',
          showInGroupFooter: true,
        },
        {
          name: 'Combined MEDIAN Parameter 2',
          summaryType: 'custom',
          displayFormat: 'Combined MEDIAN Parameter 2: {0}',
          showInColumn: 'MEDIAN Parameter 2',
          showInGroupFooter: true,
        },
        {
          name: 'Combined STDDEV Parameter 1',
          summaryType: 'custom',
          displayFormat: 'Combined STDDEV Parameter 1: {0}',
          showInColumn: 'STDDEV Parameter 1',
          showInGroupFooter: true,
        },
        {
          name: 'Combined STDDEV Parameter 2',
          summaryType: 'custom',
          displayFormat: 'Combined STDDEV Parameter 2: {0}',
          showInColumn: 'STDDEV Parameter 2',
          showInGroupFooter: true,
        },
        {
          name: 'Combined CP Parameter 1',
          summaryType: 'custom',
          displayFormat: 'Combined CP Parameter 1: {0}',
          showInColumn: 'CP Parameter 1',
          showInGroupFooter: true,
        },
        {
          name: 'Combined CP Parameter 2',
          summaryType: 'custom',
          displayFormat: 'Combined CP Parameter 2: {0}',
          showInColumn: 'CP Parameter 2',
          showInGroupFooter: true,
        },
        {
          name: 'Combined CPK Parameter 1',
          summaryType: 'custom',
          displayFormat: 'Combined CPK Parameter 1: {0}',
          showInColumn: 'CPK Parameter 1',
          showInGroupFooter: true,
        },
        {
          name: 'Combined CPK Parameter 2',
          summaryType: 'custom',
          displayFormat: 'Combined CPK Parameter 2: {0}',
          showInColumn: 'CPK Parameter 2',
          showInGroupFooter: true,
        },

        {
          name: 'Combined Total Dies',
          summaryType: 'custom',
          displayFormat: 'Combined Total Dies: {0}',
          showInColumn: 'Total Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Average Total Dies',
          summaryType: 'custom',
          displayFormat: 'Average Total Dies: {0}',
          showInColumn: 'Total Dies',
          showInGroupFooter: true,
        },
        {
          name: 'Combined Correlation Coefficient',
          summaryType: 'custom',
          displayFormat: 'Combined Correlation Coefficient: {0}',
          showInColumn: 'Correlation Coefficient',
          showInGroupFooter: true,
        },
      ],
    },
    legendKeyExpression: 'wafer_key',
    selectionEventDefaults: {
      xAxisLabel: 'DIE_ID',
      yAxisLabel: 'DIE_ID',
      avoidXWhenGroupingPresent: true,
    },
    defaultGrouping: {
      groupKey: 'wafer_key',
      entityType: ('WAFER' as EntityType),
      columnName: 'key',
    },
    postSelectionInYAxis: false,
    equalNumberOfYsForX: false,
  };

  setLegendData = (data: ICustomizedLegendData) => {
    this.legendData = data;
  };

  getPreferences = () => (this.preferences);

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpLegend.setInteractionData(selectionEventInput);
  };

  getDetailedGraphLegendData = async (requestObject: IGenericDetailedReportRequestObject, callback: (legendData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICustomizedLegendData) => void) => {
    const data = await httpLegend.getLegend(requestObject);
    this.setLegendData(data);
    callback(data);
  };
}
