/* eslint-disable no-unused-vars */
import HttpBase from './http.base';
import AppConstants from '../constants.js';
import { IColumnMeta, } from '../interfaces';
import { IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';

class HttpMasterMeta extends HttpBase {
  getMasterMeta = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/master-meta`);
  };

  getMasterMetaForColumns = (columnsList: IColumnMeta[]): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/master-meta-for-columns`, columnsList);
  };

  getMasterMetaData = (data:string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/master-meta-data/${data}`);
  };

  getDataIdsFromDefaultSelections = (data: IDefaultSelectionCriteria[]): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/get-data-ids`, data);
  };
  // getMasterMetaAndColumnDetails = (): Promise<any> => {
  //   return this.GET(`${AppConstants.baseURL}/api/master-meta-and-column-details`);
  // }
  getColumnsList = (pascalCaseResponse:any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/master-meta-columns`, pascalCaseResponse);
  };

  getIndividualColumnsList = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/individual-master-meta-columns`);
  };
}

const httpMasterMeta = new HttpMasterMeta();
export { httpMasterMeta };
