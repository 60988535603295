import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container, Row, Col, Button, Modal,
} from 'react-bootstrap';
import RealTimeStatsGraphCard from 'components/template-component/real-time-stats-card/RealTimeStatsGraphCard';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import ScrollView from 'devextreme-react/scroll-view';
import ExecutionWorkerDetails from '../../components/template-component/execution-worker-details/ExecutionWorkerDetails';
import { httpService } from '../../services/http.service';
// eslint-disable-next-line no-unused-vars
import { DockerMachineStats } from '../../interfaces';
import './visualize-infrastructure.scss';
import InfraStatsMockData from './tempInfraStatsMockData';
import AddVirtualMachineForm from './AddVirtualMachineForm';

interface IVisualizeInfrastructureProps {
  className?:string;
}

const VisualizeInfrastructure = (props:IVisualizeInfrastructureProps) => {
  const history = useHistory();
  const recordsPerPage = 4;

  const [statsData, updateStatsData] = useState({
    currentPage: 0,
    totalPages: 0,
    visualizationStats: InfraStatsMockData.getInfrastructure(),
  });

  const [selectedStats, updateSelectedStats] = useState({
    containerId: '',
    machineId: '',
  });

  const [toggleShowAddMachine, updateToggleShowAddMachine] = useState(false);
  const [modalMode, updateModalMode] = useState<'ADD_MACHINE' | 'ADD_CONTAINER' | ''>('');
  useEffect(() => {
    if (statsData.visualizationStats.length === 0) {
      httpService.getControlTowerVisualizationStats().then((stats) => {
        if (stats.length > 0) {
          updateStatsData({
            currentPage: 0,
            totalPages: (Math.ceil((stats.length / recordsPerPage))),
            visualizationStats: stats,
          });
        }
      });
    }
  });

  const getWorkerCard = () => {
    const executionStats = [];
    const startingIndex = (statsData.currentPage * recordsPerPage);
    const endingIndex = ((recordsPerPage * statsData.currentPage) + recordsPerPage);

    for (
      let i = startingIndex;
      i < endingIndex && i < statsData.visualizationStats.length;
      i += 1
    ) {
      const machine : DockerMachineStats = statsData.visualizationStats[i];
      if (selectedStats.machineId !== '' && selectedStats.machineId !== machine.id) {
        // eslint-disable-next-line no-continue
        continue;
      }
      executionStats.push(
        <Col key={machine.name} lg={selectedStats.containerId === '' ? 3 : 12} className="pl20 pr0">
          <div className="pr20 pl16 pb50 execution-line-left machine-card">
            <div className="d-flex align-items-center justify-content-between">
              <h6>
                {machine.name}
              </h6>
              <div>
                <Button variant="clear" className="mb5 pr0" title="Gracefully Shutdown">
                  <FontAwesomeIcon
                    color="orange"
                    icon={faPowerOff}
                  />
                </Button>
                <Button variant="clear" className="mb5 pr0" title="Abrupt Shutdown">
                  <FontAwesomeIcon
                    color="red"
                    icon={faPowerOff}
                  />
                </Button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="d-inline-block p4 border-all rounded machine-type">{machine.type}</span>
              <div className="float-right color-disabled-color">
                {machine.tasks.filter((x) => x.state === 'STARTING').length > 0 && (
                  <span className="border-all p4 mr4 rounded cursor-pointer" title="Starting">
                    <div className="d-inline-block mr3 execution-stat-task-badge-icon execution-stat-task-badge-icon-starting" />
                    {machine.tasks.filter((x) => x.state === 'STARTING').length}
                  </span>
                )}
                {machine.tasks.filter((x) => x.state === 'RUNNING').length > 0 && (
                  <span className="border-all p4 mr4 rounded cursor-pointer" title="Running">
                    <div className="d-inline-block mr3 execution-stat-task-badge-icon execution-stat-task-badge-icon-running" />
                    {machine.tasks.filter((x) => x.state === 'RUNNING').length}
                  </span>
                )}
                {machine.tasks.filter((x) => x.state === 'FAILED').length > 0 && (
                  <span className="border-all p4 rounded cursor-pointer" title="Failed">
                    <div className="d-inline-block mr3 execution-stat-task-badge-icon execution-stat-task-badge-icon-failed" />
                    {machine.tasks.filter((x) => x.state === 'FAILED').length}
                  </span>
                )}
                {machine.tasks.length === 0 && <span className="color-disabled-color">No Workers</span> }
              </div>
            </div>
            <div className="clearfix" />
          </div>
          {machine.tasks.length > 0
          && (
            <>
              <div className="execution-line-left mt-20 pb30">
                <RealTimeStatsGraphCard
                  id=""
                  selectStatForDetailedPreview={() => undefined}
                  isExpanded={false}
                  title="CPU Used"
                  stat={machine.cpuUsed}
                  data={{
                    x: machine.rollingCPUUsed.data.x,
                    y: machine.rollingCPUUsed.data.y,
                  }}
                />
              </div>
              <div className="execution-line-left mt-20 pb30">
                <RealTimeStatsGraphCard
                  id=""
                  selectStatForDetailedPreview={() => undefined}
                  isExpanded={false}
                  title="Free Memory"
                  stat={`${machine.freeMemory}`}
                  data={{
                    x: machine.rollingMemoryUsed.data.x,
                    y: machine.rollingMemoryUsed.data.y,
                  }}
                />
              </div>
            </>
          )}
          {machine.tasks.length > 0
          && (
            <div
              className="execution-line-left pr20"
            >
              {machine.tasks.map((item:any) => (
                <div className="d-flex align-items-center">
                  <div className="execution-instance-ring mt-8 ml-5" />
                  <div className="w20 h2 execution-line-top" />
                  <button
                    type="button"
                    onClick={() => {
                      updateSelectedStats({
                        containerId: item.id,
                        machineId: machine.id,
                      });
                    }}
                    className={`rounded 
                    execution-card 
                    
                    w-100 
                    pl6 
                    pr6 
                    pt10 
                    pb10 
                    mb16 
                    ${selectedStats.containerId === item.id ? 'execution-card-selected' : 'execution-card-unselected '}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb0 execution-stat-task-name">
                        {item.name}
                      </p>
                      <p className="mb0 mt2 execution-stat-task-state">
                        <span className={`d-inline-block mb0 mr10 execution-stat-task-badge-icon execution-stat-task-badge-icon-${item.state.toLowerCase()}`} />
                        {item.state}
                      </p>
                    </div>
                  </button>
                </div>
              ))}
              <Button
                variant="secondary"
                size="sm"
                onClick={() => undefined}
              >
                <FontAwesomeIcon
                  className="icon mr10 color-light"
                  size="sm"
                  icon={faPlus}
                />
                Add Container
              </Button>
            </div>
          )}
        </Col>,
      );
    }
    return executionStats;
  };

  const { className } = props;

  return (
    <>
      <TopbarNav
        items={[]}
        title="Infrastructure Visualization"
        showNotifications={false}
        showAvatar={false}
      />
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
      >
        <div className={className}>
          <Container fluid>
            <div className="background-color-light h100 d-flex align-items-center justify-content-between">
              <div>
                <p className="p20 mt5">
                  <Button
                    variant="link"
                    onClick={() => {
                      history.push({
                        pathname: '/control-tower',
                      });
                    }}
                    className="pl0 breadcrumbs"
                  >
                    Control Tower

                  </Button>
                  {' '}
                  /
                  {' '}
                  <span className="breadcrumbs p12">Infrastructure Visualization</span>
                </p>
              </div>
              <div>
                <Button
                  variant="secondary"
                  className="mt-6 mr10"
                  size="sm"
                  onClick={() => {
                    updateModalMode('ADD_MACHINE');
                    updateToggleShowAddMachine(!toggleShowAddMachine);
                  }}
                >
                  <FontAwesomeIcon
                    className="icon mr10"
                    size="sm"
                    icon={faPlus}
                  />
                  Add Virtual Machine
                </Button>
              </div>
            </div>
            <Row className="break-top background-color-light p20">
              <Col lg={selectedStats.containerId === '' ? 12 : 3}>
                <Row>
                  {getWorkerCard()}
                </Row>
              </Col>
              {selectedStats.containerId !== ''
            && (
              <Col lg={9} className="mt-6 break-left">
                <ExecutionWorkerDetails
                  id={selectedStats.containerId}
                  handleClose={() => {
                    updateSelectedStats({
                      containerId: '',
                      machineId: '',
                    });
                  }}
                />
              </Col>
            )}
            </Row>
          </Container>
        </div>
      </ScrollView>
      <Modal
        scrollable
        show={toggleShowAddMachine}
        onHide={() => {
          updateModalMode('');
          updateToggleShowAddMachine(!toggleShowAddMachine);
        }}
        size="lg"
      >
        <Modal.Body style={{ alignItems: 'start' }}>
          <Container fluid>
            <Row>
              <Col>
                <AddVirtualMachineForm
                  modalMode={modalMode}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

VisualizeInfrastructure.defaultProps = {
  className: '',
};

export default VisualizeInfrastructure;
