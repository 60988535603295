import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import getDropDownOptions from 'components/getDropDownOptions';

// Requires prop named listItem with captions, an object containing the names of items to show in dropdown
class CustomizedSelectBox extends React.Component<any, any> {
  onValueChanged = (e: any) => {
    const {
      data,
      customData
    } = this.props;
    data.setValue(e.value);
    if (customData.callBackOnUpdate) {
      customData.callBackOnUpdate(e.value);
    }
  };

  render() {
    const {
      data,
      customData
    } = this.props;
    return (
      <SelectBox
        items={customData.listItem}
        defaultValue={data.value}
        displayExpr="caption"
        valueExpr="dataField"
        searchEnabled={customData.isSearchEnabled || true}
        dropDownButtonTemplate="dropDownButton"
        onValueChanged={this.onValueChanged}
        disabled={customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false}
        dropDownOptions={getDropDownOptions('cell', data,)}
      />
    );
  }
}

export default CustomizedSelectBox;
