/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import bgImageTop from 'assets/images/wafer-bg-top.png';
import bgImageLeft from 'assets/images/wafer-bg-left.png';
import bgImageRight from 'assets/images/wafer-bg-right.png';
import bgImageBottom from 'assets/images/wafer-bg-bottom.png';
import bgImageTopFlat from 'assets/images/wafer-bg-top-flat.png';
import bgImageLeftFlat from 'assets/images/wafer-bg-left-flat.png';
import bgImageRightFlat from 'assets/images/wafer-bg-right-flat.png';
import bgImageBottomFlat from 'assets/images/wafer-bg-bottom-flat.png';
import React, { ReactNode } from 'react';
import { ContextMenu } from 'devextreme-react';
import { dxContextMenuItem } from 'devextreme/ui/context_menu';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import toast from 'CustomToast';
import { v4 as uuidv4 } from 'uuid';
import { DrawLayoutData } from 'components/wafer-control-map/DrawLayout';
import { PCMWATSiteMapTabData } from 'components/wafer-control-map/pcm-wat-site-map-tab';
import {
  RectCoord, ReticleGridRectCoords, ReticleReference, StandardReticle, Viewport, WaferDefinitionData, XYPoint, ZoneData,
} from '../../wafer-map/web-gl-utils/Types';
import { GLUtility } from '../Utils/WebGLUtility';
import WaferUtils from '../Utils/WaferUtils';
import WaferMapVariablesV2, { DieData, Dies } from '../Utils/WaferMapVariablesClassV2';
import '../../../../../styles/custom-form-styles.scss';
import { WaferDraggableControls } from '../Controls/WaferDraggableControls';
import { WaferPinnedControls } from '../Controls/WaferPinnedControls';
import { WaferInfoRegion } from '../Utils/WaferInfoRegion';
import { dragMouseMove } from '../Interactions/Drag';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import {
  ColAxisDirection,
  DefaultNotchPosition,
  ExclusionType,
  FeatureSpecificWaferTag,
  FlipAxis,
  NotchPosition,
  RotateDirection,
  RowAxisDirection,
  WaferOriginLocation,
} from '../../wafer-map/web-gl-utils/Enums';
import { zoomMouseMove, zoomOnMouseUp } from '../Interactions/Zoom';
import { radarMouseMove, radarMouseUp } from '../Interactions/Radar';
import { selectionMouseMove, selectionMouseUp } from '../Interactions/Selection';
import './WaferPlotter.scss';
import { tooltipMouseMove } from '../Interactions/Tooltip';
import initializeEvents, { InteractionType } from '../Interactions/Events';
import { contextMenuMouseDown } from '../Interactions/ContextMenu';
import { WaferPrimaryComponent } from '../Utils/WaferPrimaryComponent';
import { WaferState, WaferStateStacks } from '../types';
import { db } from '../db';

export type WaferAdditionalControl = {
  eventKey: string,
  type?: WaferAdditionalControlType,
  title: JSX.Element | string | number,
  body: JSX.Element | string | number,
};

export type WaferAdditionalControlType = 'MAIN_CONTROLS' | 'OVERLAY_CONTROLS';

export type WaferStates = { [keyIndex: string]: {
  [key: string]: any,
  isSelectionControlActive: boolean,
  isDragControlActive: boolean,
  isXAxisFlipped: boolean,
  isYAxisFlipped: boolean,
  isBoxZoomControlActive: boolean,
  isMarkingControlActive: boolean,
  isBoundToPubSubNetwork: boolean,
  dieTextField: string,
  showWaferInfo: boolean,
  showRadar: boolean,
  isDieCoordinatesSystemEnabled: boolean;
  isReticleCoordinatesSystemEnabled: boolean;
} };

export type WaferProps = {
  outerViewport: Viewport,
  dies: Dies,
  keyIndex: string,
  dieSize: { dieWidth: number, dieHeight: number },
  hasRotationControls?: boolean, // needs default
  hasFlippingControls?: boolean, // needs default
  isXAxisFlipped?: boolean, // needs default
  isYAxisFlipped?: boolean, // needs default
  isDefaultXAxisFlipped?: boolean, // needs default
  isDefaultYAxisFlipped?: boolean, // needs default
  hasMarkingFeature?: boolean, // needs default
  notchPosition?: NotchPosition, // needs default
  waferWidthToColsRatio?: number; // needs default
  waferHeightToRowsRatio?: number; // needs default
  waferBGOffsetXDies?: number, // needs default
  waferBGOffsetYDies?: number, // needs default
  waferTopOffset?: number, // needs default
  showRing?: boolean, // needs default
  dieWidthToStreetWidthRatio?: number, // needs default
  dieHeightToStreetHeightRatio?: number, // needs default
  rowOffset?: number, // needs default
  colOffset?: number, // needs default
  rotationAngle?: number, // needs default
  wcmWaferDiameter?: number, // needs default
  showRadar?: boolean, // needs default
  showWaferInfo?: boolean, // needs default
  ringDiameterToWaferDiameterRatio?: number, // needs default
  overlayReticle?: boolean, // needs default
  markWaferCenter?: boolean, // needs default
  dieTextField?: string, // needs default
  reticleSize?: { x: number, y: number }, // needs default
  reticleReference?: ReticleReference, // needs default
  currentDieType?: string | null, // nneds default
  dieTypes?: any, // nneds default
  showReferenceReticle?: boolean, // nneds default
  zones?: ZoneData[], // nneds default
  wcmWaferEdgeExclusion?: number, // needs default
  wcmWaferNotchKeepOut?: number, // needs default
  wcmWaferFlatKeepOut?: number, // needs default
  wcmWaferBaseFlat?: number, // needs default
  wcmWaferScribeLine?: number, // needs default
  wcmExclusionType?: ExclusionType, // needs default
  shouldUseOnlyBinColor?: boolean, // needs default
  showDieText?: boolean, // needs default
  standardReticle?: StandardReticle | null, // needs default
  rowsTakenByWaferBG?: number; // needs default
  colsTakenByWaferBG?: number; // needs default
  colAxisDirection?: ColAxisDirection; // needs default
  rowAxisDirection?: RowAxisDirection; // needs default
  waferOriginLocation?: WaferOriginLocation; // needs default
  externalLegendFields?: { key: string, value: string }[], // needs default
  skipProcessData?: boolean, // needs default
  reticleGridRectCoords?: ReticleGridRectCoords, // needs default
  referenceReticleGridRectCoords?: RectCoord | null, // needs default
  waferName?: string, // needs default
  isDieSelectedOnAnyWafer?: boolean, // needs default
  featureSpecificWaferTags?: FeatureSpecificWaferTag[], // needs default
  dieTypeField?: string, // optional
  tooltipFields?: string[][], // optional
  additionalControls?: WaferAdditionalControl[], // optional
  onSelectionChanged?: (keyIndex: string, selectedData: DieData[], unSelectedData: DieData[]) => void, // optional
  onWATPCMNumberMarked?: (keyIndex: string) => void, // optional
  setWaferInstanceHandler?: (utils: WaferUtils, keyIndex: string) => void, // optional
  pageNumber?: number, // optional
};

export enum ActionType { UNDO, REDO, PERFORM }
export type ActionHandler = (actionType: ActionType, keyIndex: string, actionDetail?: ActionDetail, noOfItemsToPop?: number) => void;

export type ActionDetail = {
  actionUndoMessage: string,
  actionDoMessage: string,
};

type WaferPlotterProps = {
  height: number,
  width: number,
  plotterPageNumber?: number,
  pinControlsToWaferTop: boolean,
  plotterKey: string,
  draggableControlsWidth: number,
  config?: {
    [keyIndex: string]: {
      primaryComponents?: ReactNode | undefined,
      onContextMenuItemClick?: (event: any) => void,
      contextMenuDataSource?: string | Array<dxContextMenuItem> | DataSource | DataSourceOptions,
      additionalControls?: WaferAdditionalControl[],
      dieTextField?: string,
    }
  },
  onControlSelectionChanged?: (keyIndex: string[]) => void,
  onControlChanged?: (keyIndex: string) => void,
  drawLayoutGetterSetter?: {
    get: () => DrawLayoutData,
    set: (drawLayout: DrawLayoutData) => void,
  },
  WCMAttributeDefinitionsGetterSetter?: {
    get: () => any[],
    set: (WCMAttributeDefinitions: any[]) => void,
  },
  PCMWatSiteMapTabDataGetterSetter?: {
    get: () => PCMWATSiteMapTabData,
    set: (pcmWatSiteMapTabData: PCMWATSiteMapTabData) => void,
  },
  getOrignalWaferDimensions?: () => { height: number, width: number },
  getWaferDefinitionData?: () => WaferDefinitionData,
  onWCMDefintionActionUndoRedo?: (waferDefinitionData: WaferDefinitionData, orignalWaferDimensions: { height: number, width: number }, waferMapVariables: WaferMapVariablesV2) => void,
};

type WaferPlotterState = {
  waferStates: WaferStates,
  controlsPosition: XYPoint,
  activeWaferKeyIndex: string;
  controlsWidgetEventKey: string;
  showUndoPopover: boolean;
  showRedoPopover: boolean;
};

const VERTEX_SHADER_NORMAL = `#version 300 es
precision mediump float;
in vec3 position;
uniform mat4 u_matrix;
void main () {
  vec4 pos = vec4(u_matrix * vec4(position, 1));
  gl_Position = vec4(pos.x, pos.y, pos.z, 1);
  gl_PointSize = 5.0;
}`;

const VERTEX_SHADER_COLOR_BASED = `#version 300 es
precision mediump float;
in vec3 position;
uniform mat4 u_matrix;
in vec4 a_coords_based_colors;
out vec4 v_coords_based_colors;
void main () {
  vec4 pos = vec4(u_matrix * vec4(position, 1));
  gl_Position = vec4(pos.x, pos.y, pos.z, 1);
  v_coords_based_colors = a_coords_based_colors;
  gl_PointSize = 5.0;
}`;

const VERTEX_SHADER_TEXTURE = `#version 300 es
precision mediump float;
in vec3 position;
uniform mat4 u_matrix;
in vec2 textCoords; // texture Coords
out vec2 textCoordsToFragmentShader;
void main () {
  vec4 pos = vec4(u_matrix * vec4(position, 1));
  gl_Position = vec4(pos.x, pos.y, pos.z, 1);
  gl_PointSize = 5.0;
  textCoordsToFragmentShader = textCoords;
}`;

const VERTEX_SHADER_CIRCLE = `#version 300 es
precision mediump float;
in vec3 position;
uniform mat4 u_matrix;
in vec2 textCoords; // texture Coords
out vec2 textCoordsToFragmentShader;
in vec4 a_coords_based_colors;
out vec4 v_coords_based_colors;
void main () {
  vec4 pos = vec4(u_matrix * vec4(position, 1));
  gl_Position = vec4(pos.x, pos.y, pos.z, 1);
  gl_PointSize = 5.0;
  textCoordsToFragmentShader = textCoords;
  v_coords_based_colors = a_coords_based_colors;
}`;

export const FRAGMENT_SHADER_NORMAL = `#version 300 es
precision mediump float;
out vec4 color;
uniform vec4 u_color;
void main () {
  color = u_color;  
}`;

export const FRAGMENT_SHADER_COLOR_BASED = `#version 300 es
precision mediump float;
in vec4 v_coords_based_colors;
out vec4 color;
void main () {
  color = v_coords_based_colors; 
}`;

export const FRAGMENT_SHADER_TEXTURE = `#version 300 es
precision mediump float;
out vec4 color;
in vec2 textCoordsToFragmentShader;
uniform sampler2D uImage;
void main () {
  color = texture(uImage, textCoordsToFragmentShader);  
}`;

export const FRAGMENT_SHADER_CIRCLE = `#version 300 es
precision mediump float;
out vec4 color;
in vec4 v_coords_based_colors;
in vec2 textCoordsToFragmentShader;
float circle(in vec2 st, in float radius) {
  vec2 dist = st - vec2(0.5);
  return 1.0 - smoothstep(
     radius - (radius * 0.01),
     radius +(radius * 0.01),
     dot(dist, dist) * 4.0);
}
void main () {
  if (circle(textCoordsToFragmentShader, 1.0) < 0.5) {
    discard;
  }
  color = v_coords_based_colors;
}`;

const WAFER_EVENTS = [
  {
    event: EventTypes.WAFER_MAP_DRAGGED,
    callback: 'dragWaferMap',
  },
  {
    event: EventTypes.WAFER_MAP_DRAG_STARTED,
    callback: 'setPreDragAnchors',
  },
  {
    event: EventTypes.WAFER_MAP_ROTATED,
    callback: 'rotateWaferMap',
  },
  {
    event: EventTypes.WAFER_RESET,
    callback: 'resetWaferZoomPositionRotation',
  },
  {
    event: EventTypes.WAFER_MAP_ZOOMED_USING_BUTTONS,
    callback: 'zoomWaferMapUsingButtons',
  },
  {
    event: EventTypes.WAFER_MAP_ZOOMED,
    callback: 'zoomWaferMapUsingBoxSelection',
  },
  {
    event: EventTypes.CLEAR_SELECTION_ON_WAFER,
    callback: 'clearSelection',
  },
  {
    event: EventTypes.WAFER_MAP_FLIPPED,
    callback: 'onFlipWafer',
  },
  {
    event: EventTypes.WAFER_MAP_DRAGGED_FROM_RADAR,
    callback: 'dragWaferMapFromRadar',
  },
  {
    event: EventTypes.SELECTION_ON_WAFER,
    callback: 'onDataSelectedOnWafer',
  },
  {
    event: EventTypes.DIES_MARKED_ON_WAFER,
    callback: 'onDiesMarkedOnWafer',
  },
  {
    event: EventTypes.TOGGLE_RADAR,
    callback: 'onToggleRadar',
  },
];

export default class WaferPlotter extends React.Component<WaferPlotterProps, WaferPlotterState> {
  static defaultProps = {
    pinControlsToWaferTop: false,
    draggableControlsWidth: 150,
  };

  glCanvas: HTMLCanvasElement | null = null;

  textCanvas: HTMLCanvasElement | null = null;

  tooltipRef: HTMLDivElement | null = null;

  waferUtils: { [keyIndex: string]: WaferUtils } = { };

  waferStateStacks: WaferStateStacks = {};

  programNormal: WebGLProgram | null = null;

  programColorBased: WebGLProgram | null = null;

  programTexture: WebGLProgram | null = null;

  programCircle: WebGLProgram | null = null;

  gl: WebGL2RenderingContext | null = null;

  textCtx: CanvasRenderingContext2D | null = null;

  imgTop: HTMLImageElement | null = null;

  imgBottom: HTMLImageElement | null = null;

  imgLeft: HTMLImageElement | null = null;

  imgRight: HTMLImageElement | null = null;

  imgTopFlat: HTMLImageElement | null = null;

  imgBottomFlat: HTMLImageElement | null = null;

  imgLeftFlat: HTMLImageElement | null = null;

  imgRightFlat: HTMLImageElement | null = null;

  isImageLoadingFinished = false;

  pendingWafersToBeAdded: { wafer: WaferProps, callback?: () => void }[] = [];

  constructor(props: WaferPlotterProps) {
    super(props);
    this.state = {
      waferStates: {},
      controlsPosition: { x: 1200, y: 0 },
      activeWaferKeyIndex: '',
      controlsWidgetEventKey: '0',
      showUndoPopover: false,
      showRedoPopover: false,
    };
  }

  componentDidMount() {
    this.loadImages();
    this.initGlAndProgram();
    this.initTextContext();
    this.setupEvents();
  }

  componentDidUpdate(prevProps: WaferPlotterProps) {
    this.checkAndUpdateCanvasDimensions(this.props, prevProps);
    this.checkAndUpdateDieTextField(this.state, this.props, prevProps);
    this.checkAndUpdateWaferPages(prevProps);
  }

  componentWillUnmount(): void {
    this.glCanvas = null;
  }

  initGlAndProgram = () => {
    const { width, height } = this.props;
    if (this.glCanvas) {
      this.glCanvas.height = height;
      this.glCanvas.width = width;
      this.gl = this.glCanvas.getContext('webgl2', { preserveDrawingBuffer: true, powerPreference: 'high-performance' }) as WebGL2RenderingContext;
      this.programNormal = GLUtility.getProgram(this.gl, VERTEX_SHADER_NORMAL, FRAGMENT_SHADER_NORMAL);
      this.programColorBased = GLUtility.getProgram(this.gl, VERTEX_SHADER_COLOR_BASED, FRAGMENT_SHADER_COLOR_BASED);
      this.programTexture = GLUtility.getProgram(this.gl, VERTEX_SHADER_TEXTURE, FRAGMENT_SHADER_TEXTURE);
      this.programCircle = GLUtility.getProgram(this.gl, VERTEX_SHADER_CIRCLE, FRAGMENT_SHADER_CIRCLE);
    }
  };

  initTextContext = () => {
    const { width, height } = this.props;
    if (this.textCanvas) {
      this.textCanvas.height = height;
      this.textCanvas.width = width;
      this.textCtx = this.textCanvas.getContext('2d');
    }
  };

  onChangeWidgetAccordian = (eventKey: string) => {
    this.setState({ controlsWidgetEventKey: eventKey });
  };

  checkAndUpdateDieTextField = (currState: WaferPlotterState, currProps: WaferPlotterProps, prevProps: WaferPlotterProps) => {
    const { activeWaferKeyIndex } = currState;
    const { config } = currProps;
    if (
      prevProps.config && config && prevProps.config[activeWaferKeyIndex] && config[activeWaferKeyIndex]
      && prevProps.config[activeWaferKeyIndex].dieTextField !== undefined && config[activeWaferKeyIndex].dieTextField !== undefined
      && prevProps.config[activeWaferKeyIndex].dieTextField !== config[activeWaferKeyIndex].dieTextField) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[activeWaferKeyIndex].dieTextField = config[activeWaferKeyIndex].dieTextField!;
        return { waferStates: newWaferStates };
      });
    }
  };

  checkAndUpdateCanvasDimensions = (currProps: WaferPlotterProps, prevProps: WaferPlotterProps) => {
    if ((currProps.width !== prevProps.width || currProps.height !== prevProps.height) && this.gl && this.textCtx) {
      GLUtility.clearAllCanvases(this.gl, this.textCtx);
      this.gl.canvas.height = currProps.height;
      this.gl.canvas.width = currProps.width;
      this.textCtx.canvas.height = currProps.height;
      this.textCtx.canvas.width = currProps.width;
      Object.keys(this.waferUtils).forEach((key: string) => {
        this.waferUtils[key].renderWafer();
      });
      this.forceUpdate();
    }
  };

  checkAndUpdateWaferPages = (prevProps: WaferPlotterProps) => {
    const { plotterPageNumber } = this.props;
    if (plotterPageNumber !== undefined && prevProps.plotterPageNumber !== undefined && prevProps.plotterPageNumber !== plotterPageNumber) {
      Object.keys(this.waferUtils).forEach((key: string) => {
        this.waferUtils[key].waferMapVariables.plotterPageNumber = plotterPageNumber;
        this.waferUtils[key].clearAll();
      });
      Object.keys(this.waferUtils).forEach((key: string) => {
        this.waferUtils[key].renderWafer();
      });
    }
  };

  setupEvents = () => {
    if (!this.glCanvas) return;
    initializeEvents(this.waferUtils, this.glCanvas, {
      [InteractionType.Drag]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, data?: { [key: string]: any }) => {
          if (data && data.interactionType !== InteractionType.Drag) return;
          waferUtil.setPreDragAnchors();
          PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_DRAG_STARTED, {}, waferUtil.waferMapVariables.keyIndex);
        },
        data: { interactionType: InteractionType.Drag },
      },
      [InteractionType.ContextMenu]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, data?: { [key: string]: any }) => {
          contextMenuMouseDown(startX, startY, endX, endY, waferUtil, data);
        },
        data: { interactionType: InteractionType.ContextMenu },
      },
    }, {
      [InteractionType.Drag]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
          dragMouseMove(startX, startY, endX, endY, waferUtil, isMouseDown, data);
        },
        data: { interactionType: InteractionType.Drag },
      },
      [InteractionType.Radar]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
          radarMouseMove(startX, startY, endX, endY, waferUtil, isMouseDown, data);
        },
        data: { interactionType: InteractionType.Radar },
      },
      [InteractionType.Select]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
          selectionMouseMove(startX, startY, endX, endY, waferUtil, isMouseDown, data);
        },
        data: { interactionType: InteractionType.Select },
      },
      [InteractionType.Zoom]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
          zoomMouseMove(startX, startY, endX, endY, waferUtil, isMouseDown, data);
        },
        data: { interactionType: InteractionType.Zoom },
      },
      [InteractionType.Tooltip]: {
        callback: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils, isMouseDown: boolean, data?: { [key: string]: any }) => {
          tooltipMouseMove(startX, startY, endX, endY, waferUtil, isMouseDown, data);
        },
        data: { tooltipRef: this.tooltipRef, interactionType: InteractionType.Tooltip },
      },
    }, {
      [InteractionType.Radar]: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils) => {
        radarMouseUp(startX, startY, endX, endY, waferUtil);
      },
      [InteractionType.Select]: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils) => {
        selectionMouseUp(startX, startY, endX, endY, waferUtil);
      },
      [InteractionType.Zoom]: (startX: number, startY: number, endX: number, endY: number, waferUtil: WaferUtils) => {
        zoomOnMouseUp(startX, startY, endX, endY, waferUtil);
      },
    });
  };

  setDefaults = (wafer: WaferProps) => {
    wafer.hasRotationControls = wafer.hasRotationControls === undefined ? true : wafer.hasRotationControls;
    wafer.hasFlippingControls = wafer.hasFlippingControls === undefined ? true : wafer.hasFlippingControls;
    wafer.isXAxisFlipped = wafer.isXAxisFlipped === undefined ? false : wafer.isXAxisFlipped;
    wafer.isYAxisFlipped = wafer.isYAxisFlipped === undefined ? false : wafer.isYAxisFlipped;
    wafer.hasMarkingFeature = wafer.hasMarkingFeature === undefined ? false : wafer.hasMarkingFeature;
    wafer.notchPosition = wafer.notchPosition === undefined ? DefaultNotchPosition : wafer.notchPosition;
    wafer.waferWidthToColsRatio = wafer.waferWidthToColsRatio === undefined ? 1 : wafer.waferWidthToColsRatio;
    wafer.waferHeightToRowsRatio = wafer.waferHeightToRowsRatio === undefined ? 1 : wafer.waferHeightToRowsRatio;
    wafer.waferBGOffsetXDies = wafer.waferBGOffsetXDies === undefined ? 0 : wafer.waferBGOffsetXDies;
    wafer.waferBGOffsetYDies = wafer.waferBGOffsetYDies === undefined ? 0 : wafer.waferBGOffsetYDies;
    wafer.waferTopOffset = wafer.waferTopOffset === undefined ? 0 : wafer.waferTopOffset;
    wafer.showRing = wafer.showRing === undefined ? false : wafer.showRing;
    wafer.dieWidthToStreetWidthRatio = wafer.dieWidthToStreetWidthRatio === undefined ? -1 : wafer.dieWidthToStreetWidthRatio;
    wafer.dieHeightToStreetHeightRatio = wafer.dieHeightToStreetHeightRatio === undefined ? -1 : wafer.dieHeightToStreetHeightRatio;
    wafer.rowOffset = wafer.rowOffset === undefined ? 0 : wafer.rowOffset;
    wafer.colOffset = wafer.colOffset === undefined ? 0 : wafer.colOffset;
    wafer.rotationAngle = wafer.rotationAngle === undefined ? 0 : wafer.rotationAngle;
    wafer.wcmWaferDiameter = wafer.wcmWaferDiameter === undefined ? 0 : wafer.wcmWaferDiameter;
    wafer.showRadar = wafer.showRadar === undefined ? true : wafer.showRadar;
    wafer.showWaferInfo = wafer.showWaferInfo === undefined ? true : wafer.showWaferInfo;
    wafer.ringDiameterToWaferDiameterRatio = wafer.ringDiameterToWaferDiameterRatio === undefined ? 1 : wafer.ringDiameterToWaferDiameterRatio;
    wafer.overlayReticle = wafer.overlayReticle === undefined ? false : wafer.overlayReticle;
    wafer.markWaferCenter = wafer.markWaferCenter === undefined ? false : wafer.markWaferCenter;
    wafer.dieTextField = wafer.dieTextField === undefined ? 'bin' : wafer.dieTextField;
    wafer.showDieText = wafer.showDieText === undefined ? true : wafer.showDieText;
    wafer.reticleSize = wafer.reticleSize === undefined ? { x: 0, y: 0 } : wafer.reticleSize;
    wafer.reticleReference = wafer.reticleReference === undefined ? 'CENTER' : wafer.reticleReference;
    wafer.currentDieType = wafer.currentDieType === undefined ? null : wafer.currentDieType;
    wafer.dieTypes = wafer.dieTypes === undefined ? [] : wafer.dieTypes;
    wafer.showReferenceReticle = wafer.showReferenceReticle === undefined ? false : wafer.showReferenceReticle;
    wafer.zones = wafer.zones === undefined ? [] : wafer.zones;
    wafer.wcmWaferEdgeExclusion = wafer.wcmWaferEdgeExclusion === undefined ? 0 : wafer.wcmWaferEdgeExclusion;
    wafer.wcmWaferNotchKeepOut = wafer.wcmWaferNotchKeepOut === undefined ? 0 : wafer.wcmWaferNotchKeepOut;
    wafer.wcmWaferFlatKeepOut = wafer.wcmWaferFlatKeepOut === undefined ? 0 : wafer.wcmWaferFlatKeepOut;
    wafer.wcmWaferBaseFlat = wafer.wcmWaferBaseFlat === undefined ? 0 : wafer.wcmWaferBaseFlat;
    wafer.wcmWaferScribeLine = wafer.wcmWaferScribeLine === undefined ? 0 : wafer.wcmWaferScribeLine;
    wafer.wcmExclusionType = wafer.wcmExclusionType === undefined ? ExclusionType.NONE : wafer.wcmExclusionType;
    wafer.shouldUseOnlyBinColor = wafer.shouldUseOnlyBinColor === undefined ? true : wafer.shouldUseOnlyBinColor;
    wafer.standardReticle = wafer.standardReticle === undefined ? null : wafer.standardReticle;
    wafer.rowsTakenByWaferBG = wafer.rowsTakenByWaferBG === undefined ? wafer.dies.length : wafer.rowsTakenByWaferBG;
    wafer.colsTakenByWaferBG = wafer.colsTakenByWaferBG === undefined ? wafer.dies[0].length : wafer.colsTakenByWaferBG;
    wafer.rowAxisDirection = wafer.rowAxisDirection === undefined ? RowAxisDirection.TopToBottom : wafer.rowAxisDirection;
    wafer.colAxisDirection = wafer.colAxisDirection === undefined ? ColAxisDirection.LeftToRight : wafer.colAxisDirection;
    wafer.waferOriginLocation = wafer.waferOriginLocation === undefined ? WaferOriginLocation.UPPER_LEFT : wafer.waferOriginLocation;
    wafer.externalLegendFields = wafer.externalLegendFields === undefined ? [] : wafer.externalLegendFields;
    wafer.skipProcessData = wafer.skipProcessData === undefined ? false : wafer.skipProcessData;
    wafer.reticleGridRectCoords = wafer.reticleGridRectCoords === undefined ? {} : wafer.reticleGridRectCoords;
    wafer.referenceReticleGridRectCoords = wafer.referenceReticleGridRectCoords === undefined ? null : wafer.referenceReticleGridRectCoords;
    wafer.waferName = wafer.waferName === undefined ? '' : wafer.waferName;
    wafer.isDieSelectedOnAnyWafer = wafer.isDieSelectedOnAnyWafer === undefined ? false : wafer.isDieSelectedOnAnyWafer;
    wafer.featureSpecificWaferTags = wafer.featureSpecificWaferTags === undefined ? [] : wafer.featureSpecificWaferTags;
  };

  loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = src;
      img.onload = () => { resolve(img); };
      img.onerror = () => { reject(new Error('Image failed to load')); };
    });
  };

  loadImages = () => {
    Promise.all([
      this.loadImage(bgImageTop),
      this.loadImage(bgImageBottom),
      this.loadImage(bgImageLeft),
      this.loadImage(bgImageRight),
      this.loadImage(bgImageTopFlat),
      this.loadImage(bgImageBottomFlat),
      this.loadImage(bgImageLeftFlat),
      this.loadImage(bgImageRightFlat),
    ]).then((obj) => {
      [
        this.imgTop, this.imgBottom, this.imgLeft, this.imgRight,
        this.imgTopFlat, this.imgBottomFlat, this.imgLeftFlat, this.imgRightFlat,
      ] = obj;
      this.isImageLoadingFinished = true;
      this.addPendingWafers();
    }).catch(() => {
      this.isImageLoadingFinished = true;
      this.addPendingWafers();
    });
  };

  addPendingWafers = () => {
    for (let i = 0; i < this.pendingWafersToBeAdded.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      this.addWafer(this.pendingWafersToBeAdded[i].wafer, this.pendingWafersToBeAdded[i].callback);
    }
    this.pendingWafersToBeAdded = [];
  };

  addWafer = (wafer: WaferProps, callback?: () => void, activeControlWaferMapKeyIndex?:string) => {
    const { plotterPageNumber } = this.props;
    if (!this.isImageLoadingFinished) {
      this.pendingWafersToBeAdded.push({ wafer, callback });
      return;
    }
    this.setDefaults(wafer);
    if (this.programNormal && this.programTexture && this.programColorBased && this.programCircle && this.gl && this.textCtx) {
      this.waferUtils[wafer.keyIndex] = new WaferUtils(new WaferMapVariablesV2({
        keyIndex: wafer.keyIndex,
        dies: wafer.dies,
        actionHandler: wafer.featureSpecificWaferTags && wafer.featureSpecificWaferTags.includes(FeatureSpecificWaferTag.WAFER_CONTROL_MAP) ? this.actionHandler : undefined,
        pageNumber: wafer.pageNumber,
        outerViewPort: wafer.outerViewport,
        dieSize: wafer.dieSize,
        programNormal: this.programNormal,
        programColorBased: this.programColorBased,
        programTexture: this.programTexture,
        programCircle: this.programCircle,
        gl: this.gl,
        textCtx: this.textCtx,
        hasRotationControls: wafer.hasRotationControls!,
        hasFlippingControls: wafer.hasFlippingControls!,
        hasMarkingFeature: wafer.hasMarkingFeature!,
        tooltipFields: wafer.tooltipFields,
        onSelectionChanged: wafer.onSelectionChanged,
        onWATPCMNumberMarked: wafer.onWATPCMNumberMarked,
        notchPosition: wafer.notchPosition!,
        waferHeightToRowsRatio: wafer.waferHeightToRowsRatio!,
        waferWidthToColsRatio: wafer.waferWidthToColsRatio!,
        waferBGOffsetXDies: wafer.waferBGOffsetXDies!,
        waferBGOffsetYDies: wafer.waferBGOffsetYDies!,
        waferTopOffset: wafer.waferTopOffset!,
        showRing: wafer.showRing!,
        dieWidthToStreetWidthRatio: wafer.dieWidthToStreetWidthRatio!,
        dieHeightToStreetHeightRatio: wafer.dieHeightToStreetHeightRatio!,
        rowOffset: wafer.rowOffset!,
        colOffset: wafer.colOffset!,
        rotationAngle: wafer.rotationAngle!,
        wcmWaferDiameter: wafer.wcmWaferDiameter!,
        showRadar: wafer.showRadar!,
        dieTypeField: wafer.dieTypeField ? wafer.dieTypeField : wafer.dieTypeField as unknown as string,
        ringDiameterToWaferDiameterRatio: wafer.ringDiameterToWaferDiameterRatio!,
        overlayReticle: wafer.overlayReticle!,
        markWaferCenter: wafer.markWaferCenter!,
        dieTextField: wafer.dieTextField!,
        showDieText: wafer.showDieText!,
        reticleSize: wafer.reticleSize!,
        reticleReference: wafer.reticleReference!,
        currentDieType: wafer.currentDieType!,
        dieTypes: wafer.dieTypes!,
        showReferenceReticle: wafer.showReferenceReticle!,
        zones: wafer.zones!,
        wcmWaferEdgeExclusion: wafer.wcmWaferEdgeExclusion!,
        wcmWaferNotchKeepOut: wafer.wcmWaferNotchKeepOut!,
        wcmWaferFlatKeepOut: wafer.wcmWaferFlatKeepOut!,
        wcmWaferBaseFlat: wafer.wcmWaferBaseFlat!,
        wcmWaferScribeLine: wafer.wcmWaferScribeLine!,
        wcmExclusionType: wafer.wcmExclusionType!,
        waferBgImageTop: this.imgTop,
        waferBgImageBottom: this.imgBottom,
        waferBgImageLeft: this.imgLeft,
        waferBgImageRight: this.imgRight,
        waferBgImageTopFlat: this.imgTopFlat,
        waferBgImageBottomFlat: this.imgBottomFlat,
        waferBgImageLeftFlat: this.imgLeftFlat,
        waferBgImageRightFlat: this.imgRightFlat,
        shouldUseOnlyBinColor: wafer.shouldUseOnlyBinColor!,
        standardReticle: wafer.standardReticle!,
        rowsTakenByWaferBG: wafer.rowsTakenByWaferBG!,
        colsTakenByWaferBG: wafer.colsTakenByWaferBG!,
        colAxisDirection: wafer.colAxisDirection!,
        rowAxisDirection: wafer.rowAxisDirection!,
        waferOriginLocation: wafer.waferOriginLocation!,
        externalLegendFields: wafer.externalLegendFields!,
        skipProcessData: wafer.skipProcessData!,
        reticleGridRectCoords: wafer.reticleGridRectCoords!,
        referenceReticleGridRectCoords: wafer.referenceReticleGridRectCoords!,
        waferName: wafer.waferName!,
        isDieSelectedOnAnyWafer: wafer.isDieSelectedOnAnyWafer!,
        featureSpecificWaferTags: wafer.featureSpecificWaferTags!,
        isXAxisFlipped: wafer.isXAxisFlipped!,
        isYAxisFlipped: wafer.isYAxisFlipped!,
        isDefaultXAxisFlipped: wafer.isDefaultXAxisFlipped!,
        isDefaultYAxisFlipped: wafer.isDefaultYAxisFlipped!,
      }));
      this.waferUtils[wafer.keyIndex].waferMapVariables.plotterPageNumber = plotterPageNumber;
      this.waferUtils[wafer.keyIndex].renderWafer();
      if (wafer.setWaferInstanceHandler) wafer.setWaferInstanceHandler(this.waferUtils[wafer.keyIndex], wafer.keyIndex);
      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[wafer.keyIndex] = {
          isSelectionControlActive: false,
          isDragControlActive: true,
          isXAxisFlipped: wafer.isXAxisFlipped!,
          isYAxisFlipped: wafer.isYAxisFlipped!,
          isBoxZoomControlActive: false,
          isMarkingControlActive: false,
          isBoundToPubSubNetwork: false,
          dieTextField: wafer.dieTextField!,
          showWaferInfo: wafer.showWaferInfo!,
          isDieCoordinatesSystemEnabled: true,
          isReticleCoordinatesSystemEnabled: false,
          showRadar: wafer.showRadar!,
        };
        return {
          waferStates: newWaferStates,
          activeWaferKeyIndex: this.determineActiveWaferKeyIndex(prevState.activeWaferKeyIndex, wafer.keyIndex, activeControlWaferMapKeyIndex),
          controlsPosition: this.determineControlsPosition(wafer, prevState.controlsPosition, prevState.activeWaferKeyIndex, activeControlWaferMapKeyIndex),
        };
      }, async () => {
        await this.pushState(wafer.keyIndex, 'Wafer Definition', 'Wafer Definition');
        if (callback) callback();
      });
    }
  };

  pushState = async (waferKeyIndex: string, actionDoMessage: string, actionUndoMessage: string) => {
    const id = uuidv4();
    const { waferMapVariables } = this.waferUtils[waferKeyIndex];
    const { waferStates } = this.state;
    const {
      drawLayoutGetterSetter, getWaferDefinitionData, getOrignalWaferDimensions, WCMAttributeDefinitionsGetterSetter, PCMWatSiteMapTabDataGetterSetter,
    } = this.props;
    await db.waferStates.add({
      id,
      waferMapVariables: JSON.stringify({
        waferData: waferMapVariables.waferData,
        isBoxZoomControlActive: waferMapVariables.isBoxZoomControlActive,
        isDragControlActive: waferMapVariables.isDragControlActive,
        isSelectionControlActive: waferMapVariables.isSelectionControlActive,
        isMarkingControlActive: waferMapVariables.isMarkingControlActive,
        markingMode: waferMapVariables.markingMode,
        angleInDegrees: waferMapVariables.angleInDegrees,
        rotation: waferMapVariables.rotation,
        isRadarEventAnchorCenter: waferMapVariables.isRadarEventAnchorCenter,
        reticleGridRectCoords: waferMapVariables.reticleGridRectCoords,
        referenceReticleGridRectCoords: waferMapVariables.referenceReticleGridRectCoords,
        rowOffset: waferMapVariables.rowOffset,
        colOffset: waferMapVariables.colOffset,
        zones: waferMapVariables.zones,
        outerViewPort: waferMapVariables.outerViewPort,
        outerViewPortWithRadar: waferMapVariables.outerViewPortWithRadar,
        outerViewPortWithoutRadar: waferMapVariables.outerViewPortWithoutRadar,
        innerViewPort: waferMapVariables.innerViewPort,
        radarViewPort: waferMapVariables.radarViewPort,
        dieWidthToHeightRatio: waferMapVariables.dieWidthToHeightRatio,
        scaledDieWidth: waferMapVariables.scaledDieWidth,
        scaledDieHeight: waferMapVariables.scaledDieHeight,
        scaledDieWidthInitial: waferMapVariables.scaledDieWidthInitial,
        scaledDieHeightInitial: waferMapVariables.scaledDieHeightInitial,
        xAnchor: waferMapVariables.xAnchor,
        yAnchor: waferMapVariables.yAnchor,
        xAnchorInitial: waferMapVariables.xAnchorInitial,
        yAnchorInitial: waferMapVariables.yAnchorInitial,
        rowDirection: waferMapVariables.rowDirection,
        startRow: waferMapVariables.startRow,
        colDirection: waferMapVariables.colDirection,
        startCol: waferMapVariables.startCol,
        rowAxisIncrement: waferMapVariables.rowAxisIncrement,
        rowAxisStart: waferMapVariables.rowAxisStart,
        colAxisIncrement: waferMapVariables.colAxisIncrement,
        colAxisStart: waferMapVariables.colAxisStart,
        rowAxisDirection: waferMapVariables.rowAxisDirection,
        colAxisDirection: waferMapVariables.colAxisDirection,
        rowFlip: waferMapVariables.rowFlip,
        colFlip: waferMapVariables.colFlip,
        reticleSize: waferMapVariables.reticleSize,
        dieCount: waferMapVariables.dieCount,
        selectedDieCount: waferMapVariables.selectedDieCount,
        rowCount: waferMapVariables.rowCount,
        colCount: waferMapVariables.colCount,
        dieWidthToStreetWidthRatio: waferMapVariables.dieWidthToStreetWidthRatio,
        dieHeightToStreetHeightRatio: waferMapVariables.dieHeightToStreetHeightRatio,
        isDieCoordinatesSystemEnabled: waferMapVariables.isDieCoordinatesSystemEnabled,
        isReticleCoordinatesSystemEnabled: waferMapVariables.isReticleCoordinatesSystemEnabled,
        overlayReticle: waferMapVariables.overlayReticle,
        dieTextField: waferMapVariables.dieTextField,
        notchPosition: waferMapVariables.notchPosition,
        waferHeightToRowsRatio: waferMapVariables.waferHeightToRowsRatio,
        waferWidthToColsRatio: waferMapVariables.waferWidthToColsRatio,
        panOffsetXToDieStepSizeXRatio: waferMapVariables.panOffsetXToDieStepSizeXRatio,
        panOffsetYToDieStepSizeYRatio: waferMapVariables.panOffsetYToDieStepSizeYRatio,
        waferBGOffsetXDies: waferMapVariables.waferBGOffsetXDies,
        waferBGOffsetYDies: waferMapVariables.waferBGOffsetYDies,
        waferTopOffset: waferMapVariables.waferTopOffset,
        showRing: waferMapVariables.showRing,
        wcmWaferDiameter: waferMapVariables.wcmWaferDiameter,
        showRadar: waferMapVariables.showRadar,
        ringDiameterToWaferDiameterRatio: waferMapVariables.ringDiameterToWaferDiameterRatio,
        reticleReference: waferMapVariables.reticleReference,
        wcmWaferEdgeExclusion: waferMapVariables.wcmWaferEdgeExclusion,
        wcmWaferNotchKeepOut: waferMapVariables.wcmWaferNotchKeepOut,
        wcmWaferFlatKeepOut: waferMapVariables.wcmWaferFlatKeepOut,
        wcmWaferBaseFlat: waferMapVariables.wcmWaferBaseFlat,
        wcmWaferScribeLine: waferMapVariables.wcmWaferScribeLine,
        wcmExclusionType: waferMapVariables.wcmExclusionType,
        waferBgCenterPoint: waferMapVariables.waferBgCenterPoint,
        reticleXAxisReference: waferMapVariables.reticleXAxisReference,
        reticleYAxisReference: waferMapVariables.reticleYAxisReference,
        dieTypeCountInfo: waferMapVariables.dieTypeCountInfo,
        rowsTakenByWaferBG: waferMapVariables.rowsTakenByWaferBG,
        colsTakenByWaferBG: waferMapVariables.colsTakenByWaferBG,
        waferOriginLocation: waferMapVariables.waferOriginLocation,
      }),
      waferState: JSON.stringify(waferStates[waferKeyIndex]),
      drawLayout: drawLayoutGetterSetter ? JSON.stringify(drawLayoutGetterSetter.get()) : undefined,
      WCMAttributeDefinitions: WCMAttributeDefinitionsGetterSetter ? JSON.stringify(WCMAttributeDefinitionsGetterSetter.get()) : undefined,
      pcmWatSiteMapTabData: PCMWatSiteMapTabDataGetterSetter ? JSON.stringify(PCMWatSiteMapTabDataGetterSetter.get()) : undefined,
      orignalWaferDimensions: getOrignalWaferDimensions ? JSON.stringify(getOrignalWaferDimensions()) : undefined,
      waferDefinitionData: getWaferDefinitionData ? JSON.stringify(getWaferDefinitionData()) : undefined,
    });
    const state: WaferState = {
      id,
      actionDoMessage,
      actionUndoMessage,
    };
    if (this.waferStateStacks[waferKeyIndex]) {
      this.waferStateStacks[waferKeyIndex].stack.push(state);
      this.waferStateStacks[waferKeyIndex].ptr += 1;
    } else {
      this.waferStateStacks[waferKeyIndex] = { stack: [state], ptr: 0 };
    }
  }

  clearRedo = (waferKeyIndex: string) => {
    if (this.waferStateStacks[waferKeyIndex]) {
      const { stack, ptr } = this.waferStateStacks[waferKeyIndex];
      stack.splice(ptr + 1);
    }
  }

  performHandler = async (waferKeyIndex: string, actionDetail?: ActionDetail) => {
    if (actionDetail !== undefined) {
      this.clearRedo(waferKeyIndex);
      await this.pushState(waferKeyIndex, actionDetail.actionDoMessage, actionDetail.actionUndoMessage);
      this.waferUtils[waferKeyIndex].renderWafer();
    }
  }

  copyVariablesFromIndexDBStateAndRender = async (stateId: string, waferKeyIndex: string) => {
    const {
      drawLayoutGetterSetter, onWCMDefintionActionUndoRedo, WCMAttributeDefinitionsGetterSetter, PCMWatSiteMapTabDataGetterSetter,
    } = this.props;
    const dbState = await db.waferStates.where('id').equals(stateId).first();
    const waferMapVariablesFromDB = JSON.parse(dbState.waferMapVariables);
    const keys = Object.keys(waferMapVariablesFromDB);
    const { waferMapVariables } = this.waferUtils[waferKeyIndex];
    for (let i = 0; i < keys.length; i += 1) {
      waferMapVariables[keys[i]] = waferMapVariablesFromDB[keys[i]];
    }
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[waferKeyIndex] = JSON.parse(dbState.waferState);
      return {
        waferStates: newWaferStates,
      }
    }, () => {
      waferMapVariables.radarDiesGLCoords = null;
      waferMapVariables.radarWaferBgImageGLCoords = null;
      this.waferUtils[waferKeyIndex].renderWafer();
      if (drawLayoutGetterSetter) {
        drawLayoutGetterSetter.set(JSON.parse(dbState.drawLayout));
      }
      if (WCMAttributeDefinitionsGetterSetter) {
        WCMAttributeDefinitionsGetterSetter.set(JSON.parse(dbState.WCMAttributeDefinitions));
      }
      if (PCMWatSiteMapTabDataGetterSetter) {
        PCMWatSiteMapTabDataGetterSetter.set(JSON.parse(dbState.pcmWatSiteMapTabData));
      }
      if (onWCMDefintionActionUndoRedo) {
        onWCMDefintionActionUndoRedo(JSON.parse(dbState.waferDefinitionData), JSON.parse(dbState.orignalWaferDimensions), waferMapVariables);
      }
    });
  }

  undoHandler = async (waferKeyIndex: string, noOfItemsToPop?: number) => {
    if (this.waferStateStacks[waferKeyIndex] && this.waferUtils[waferKeyIndex] && noOfItemsToPop !== undefined && this.waferStateStacks[waferKeyIndex].ptr >= noOfItemsToPop) {
      this.waferStateStacks[waferKeyIndex].ptr -= noOfItemsToPop;
      const stateId = this.waferStateStacks[waferKeyIndex].stack[this.waferStateStacks[waferKeyIndex].ptr].id;
      await this.copyVariablesFromIndexDBStateAndRender(stateId, waferKeyIndex);
    }
  }

  redoHandler = async (waferKeyIndex: string, noOfItemsToPop?: number) => {
    // eslint-disable-next-line max-len
    if (this.waferStateStacks[waferKeyIndex] && this.waferUtils[waferKeyIndex] && noOfItemsToPop !== undefined && this.waferStateStacks[waferKeyIndex].stack.length - 1 - this.waferStateStacks[waferKeyIndex].ptr >= noOfItemsToPop) {
      this.waferStateStacks[waferKeyIndex].ptr += noOfItemsToPop;
      const stateId = this.waferStateStacks[waferKeyIndex].stack[this.waferStateStacks[waferKeyIndex].ptr].id;
      await this.copyVariablesFromIndexDBStateAndRender(stateId, waferKeyIndex);
    }
  }

  actionHandler = async (actionType: ActionType, keyIndex: string, actionDetail?: ActionDetail, noOfItemsToPop?: number) => {
    if (!this.waferUtils[keyIndex].waferMapVariables.featureSpecificWaferTags
      || !this.waferUtils[keyIndex].waferMapVariables.featureSpecificWaferTags.includes(FeatureSpecificWaferTag.WAFER_CONTROL_MAP)) { return; }

    switch (actionType) {
      case ActionType.PERFORM:
        await this.performHandler(keyIndex, actionDetail);
        break;
      case ActionType.UNDO:
        await this.undoHandler(keyIndex, noOfItemsToPop);
        break;
      case ActionType.REDO:
        await this.redoHandler(keyIndex, noOfItemsToPop);
        break;
      default:
        await this.performHandler(keyIndex, actionDetail);
        break;
    }
    this.setState({ showRedoPopover: false, showUndoPopover: false });
  }

  addOrReplaceWafer = (wafer: WaferProps, callback?: () => void, activeControlWaferMapKeyIndex?: string) => {
    this.removeWafer(wafer.keyIndex, wafer, callback, activeControlWaferMapKeyIndex);
  };

  removeWafer = (keyIndexToRemove: string, wafer?: WaferProps, callback?: () => void, activeControlWaferMapKeyIndex?:string) => {
    if (keyIndexToRemove in this.waferUtils) {
      this.waferUtils[keyIndexToRemove].clearAll();
      delete this.waferUtils[keyIndexToRemove];
    }
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      const waferUtilKeys = Object.keys(this.waferUtils);
      const ps = PublishSubscribe();
      for (let i = 0; i < WAFER_EVENTS.length; i += 1) {
        for (let j = 0; j < waferUtilKeys.length; j += 1) {
          if (waferUtilKeys[j] !== keyIndexToRemove && newWaferStates[waferUtilKeys[j]].isBoundToPubSubNetwork) {
            ps.unSubscribeToOthersID(WAFER_EVENTS[i].event, waferUtilKeys[j], keyIndexToRemove);
            ps.unSubscribeToOthersID(WAFER_EVENTS[i].event, keyIndexToRemove, waferUtilKeys[j]);
          }
        }
      }
      if (keyIndexToRemove in newWaferStates) {
        delete newWaferStates[keyIndexToRemove];
      }
      const newWaferUtilKeys = Object.keys(this.waferUtils);
      return {
        waferStates: newWaferStates,
        activeWaferKeyIndex: newWaferUtilKeys.length > 0 ? newWaferUtilKeys[0] : '',
      };
    }, () => {
      if (wafer) this.addWafer(wafer, callback, activeControlWaferMapKeyIndex);
    });
  };

  selectionChangeHandler = (checked: boolean, isFromMarkControls: boolean, keyIndex: string) => {
    if (checked) {
      this.waferUtils[keyIndex].waferMapVariables.isDragControlActive = false;
      this.waferUtils[keyIndex].waferMapVariables.isSelectionControlActive = true;
      this.waferUtils[keyIndex].waferMapVariables.isBoxZoomControlActive = false;
      this.waferUtils[keyIndex].waferMapVariables.isMarkingControlActive = isFromMarkControls;

      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[keyIndex].isBoxZoomControlActive = false;
        newWaferStates[keyIndex].isDragControlActive = false;
        newWaferStates[keyIndex].isSelectionControlActive = !isFromMarkControls;
        newWaferStates[keyIndex].isMarkingControlActive = isFromMarkControls;
        return { waferStates: newWaferStates };
      });
    }
  };

  showRadarHandler = (checked: boolean, keyIndex: string) => {
    this.waferUtils[keyIndex].waferMapVariables.showRadar = checked;
    this.waferUtils[keyIndex].clearAll();
    this.waferUtils[keyIndex].waferMapVariables.onSetOuterViewport(
      checked ? this.waferUtils[keyIndex].waferMapVariables.outerViewPortWithRadar!
        : this.waferUtils[keyIndex].waferMapVariables.outerViewPortWithoutRadar!,
    );
    this.waferUtils[keyIndex].renderWafer();
    PublishSubscribe().publishWithOthersID(EventTypes.TOGGLE_RADAR, { checked }, keyIndex);
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[keyIndex].showRadar = checked;
      return { waferStates: newWaferStates };
    });
  };

  dragChangeHandler = (checked: boolean, keyIndex: string) => {
    if (checked) {
      this.waferUtils[keyIndex].waferMapVariables.isDragControlActive = true;
      this.waferUtils[keyIndex].waferMapVariables.isSelectionControlActive = false;
      this.waferUtils[keyIndex].waferMapVariables.isBoxZoomControlActive = false;

      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[keyIndex].isBoxZoomControlActive = false;
        newWaferStates[keyIndex].isDragControlActive = true;
        newWaferStates[keyIndex].isSelectionControlActive = false;
        newWaferStates[keyIndex].isMarkingControlActive = false;
        return { waferStates: newWaferStates };
      });
    }
  };

  zoomChangeHandler = (checked: boolean, keyIndex: string) => {
    if (checked) {
      this.waferUtils[keyIndex].waferMapVariables.isDragControlActive = false;
      this.waferUtils[keyIndex].waferMapVariables.isSelectionControlActive = false;
      this.waferUtils[keyIndex].waferMapVariables.isBoxZoomControlActive = true;

      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[keyIndex].isBoxZoomControlActive = true;
        newWaferStates[keyIndex].isDragControlActive = false;
        newWaferStates[keyIndex].isSelectionControlActive = false;
        newWaferStates[keyIndex].isMarkingControlActive = false;
        return { waferStates: newWaferStates };
      });
    }
  };

  onResetWaferZoomPositionRotation = (keyIndex: string) => {
    this.waferUtils[keyIndex].resetWaferZoomPositionRotation();
    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_RESET, {}, keyIndex);
  };

  manageBindingOfEvents = (waferStates: WaferStates, waferKey: string, isBoundToPubSubNetworkNewValue: boolean) => {
    const ps = PublishSubscribe();
    const waferUtilKeys = Object.keys(this.waferUtils);
    for (let i = 0; i < WAFER_EVENTS.length; i += 1) {
      for (let j = 0; j < waferUtilKeys.length; j += 1) {
        if (waferUtilKeys[j] !== waferKey && waferStates[waferUtilKeys[j]].isBoundToPubSubNetwork) {
          if (isBoundToPubSubNetworkNewValue) {
            ps.subscribeToOthersID(WAFER_EVENTS[i].event, this.waferUtils[waferKey][WAFER_EVENTS[i].callback], waferUtilKeys[j], waferKey);
            ps.subscribeToOthersID(WAFER_EVENTS[i].event, this.waferUtils[waferUtilKeys[j]][WAFER_EVENTS[i].callback], waferKey, waferUtilKeys[j]);
          } else {
            ps.unSubscribeToOthersID(WAFER_EVENTS[i].event, waferUtilKeys[j], waferKey);
            ps.unSubscribeToOthersID(WAFER_EVENTS[i].event, waferKey, waferUtilKeys[j]);
          }
        }
      }
    }
  };

  onBindCheckboxChange = (value: boolean, key: string) => {
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[key].isBoundToPubSubNetwork = value;
      this.manageBindingOfEvents(newWaferStates, key, value);
      return { waferStates: newWaferStates };
    }, () => {
      const { onControlSelectionChanged } = this.props;
      const { waferStates } = this.state;
      if (onControlSelectionChanged) {
        onControlSelectionChanged(Object.entries(waferStates).filter((x) => x[1].isBoundToPubSubNetwork === true).map((x) => x[0]));
      }
    });
  };

  onRotateWafer = async (direction: RotateDirection, keyIndex: string) => {
    this.waferUtils[keyIndex].rotateWaferMap({ direction });
    if (this.waferUtils[keyIndex].waferMapVariables.actionHandler) {
      await this.waferUtils[keyIndex].waferMapVariables.actionHandler!(ActionType.PERFORM, keyIndex, {
        actionDoMessage: `Rotate ${direction === RotateDirection.ClockWise ? 'clockwise' : 'anti-clockwise'}`,
        actionUndoMessage: `Rotate ${direction === RotateDirection.ClockWise ? 'clockwise' : 'anti-clockwise'}`,
      });
    }
    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_ROTATED, { direction }, keyIndex);
  };

  onFlipWafer = (value: boolean, axis: FlipAxis, keyIndex: string) => {
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[keyIndex].isXAxisFlipped = axis === FlipAxis.ColWise ? value : newWaferStates[keyIndex].isXAxisFlipped;
      newWaferStates[keyIndex].isYAxisFlipped = axis === FlipAxis.RowWise ? value : newWaferStates[keyIndex].isYAxisFlipped;
      return { waferStates: newWaferStates };
    }, async () => {
      const { waferStates } = this.state;
      this.waferUtils[keyIndex].onFlipWafer({ axis, isXAxisFlipped: waferStates[keyIndex].isXAxisFlipped, isYAxisFlipped: waferStates[keyIndex].isYAxisFlipped });
      if (this.waferUtils[keyIndex].waferMapVariables.actionHandler) {
        await this.waferUtils[keyIndex].waferMapVariables.actionHandler!(ActionType.PERFORM, keyIndex, {
          actionDoMessage: `Flip ${axis === FlipAxis.ColWise ? 'x' : 'y'} action`,
          actionUndoMessage: `Flip ${axis === FlipAxis.ColWise ? 'x' : 'y'} action`,
        });
      }
      PublishSubscribe().publishWithOthersID(
        EventTypes.WAFER_MAP_FLIPPED,
        {
          axis,
          isXAxisFlipped: waferStates[keyIndex].isXAxisFlipped,
          isYAxisFlipped: waferStates[keyIndex].isYAxisFlipped,
          keyIndex,
        },
        keyIndex,
      );
    });
  };

  onZoomWaferMapUsingButtons = (zoomFactor: number, keyIndex: string) => {
    this.waferUtils[keyIndex].zoomWaferMapUsingButtons({ zoomFactor });
    PublishSubscribe().publishWithOthersID(EventTypes.WAFER_MAP_ZOOMED_USING_BUTTONS, { zoomFactor }, keyIndex);
  };

  onChangeDieTextField = (value: string, keyIndex: string) => {
    this.waferUtils[keyIndex].waferMapVariables.showDieText = true;
    this.waferUtils[keyIndex].waferMapVariables.dieTextField = value;
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[keyIndex].dieTextField = value;
      return { waferStates: newWaferStates };
    }, () => {
      this.waferUtils[keyIndex].renderWafer();
    });
  };

  clearSelectionHandler = async (keyIndex: string) => {
    this.waferUtils[keyIndex].clearSelection();
    if (this.waferUtils[keyIndex].waferMapVariables.actionHandler) {
      await this.waferUtils[keyIndex].waferMapVariables.actionHandler!(ActionType.PERFORM, keyIndex, {
        actionDoMessage: 'Clear selection action',
        actionUndoMessage: 'Clear selection action',
      });
    }
    PublishSubscribe().publishWithOthersID(EventTypes.CLEAR_SELECTION_ON_WAFER, {}, keyIndex);
  };

  onChangeDieCoordinateSystemValue = (value: boolean, keyIndex: string) => {
    this.waferUtils[keyIndex].waferMapVariables.isDieCoordinatesSystemEnabled = value;
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      newWaferStates[keyIndex].isDieCoordinatesSystemEnabled = value;
      return { waferStates: newWaferStates };
    }, () => {
      this.waferUtils[keyIndex].renderWafer();
    });
  };

  onChangeReticleCoordinateSystemValue = (value: boolean, keyIndex: string) => {
    if (!this.waferUtils[keyIndex].waferMapVariables.overlayReticle && value) {
      toast.warn('Reticle coordinates cannot be enabled without overlaying the reticle');
    } else {
      this.waferUtils[keyIndex].waferMapVariables.isReticleCoordinatesSystemEnabled = value;
      this.setState((prevState: WaferPlotterState) => {
        const newWaferStates = _.cloneDeep(prevState.waferStates);
        newWaferStates[keyIndex].isReticleCoordinatesSystemEnabled = value;
        return { waferStates: newWaferStates };
      }, () => {
        this.waferUtils[keyIndex].renderWafer();
      });
    }
  };

  onControlsRadioChange = (value: boolean, key: string) => {
    if (value) {
      this.setState({
        activeWaferKeyIndex: key,
        controlsPosition: {
          x: this.waferUtils[key].waferMapVariables.outerViewPort.x + this.waferUtils[key].waferMapVariables.outerViewPort.width,
          y: this.gl!.canvas!.height - this.waferUtils[key].waferMapVariables.outerViewPort.y - (this.waferUtils[key].waferMapVariables.outerViewPort.height / 2),
        },
      }, () => {
        const { onControlChanged } = this.props;
        if (onControlChanged) {
          onControlChanged(key);
        }
      });
    }
  };

  handleConnectWafersSelection = (selectedWaferKeyList: string[]) => {
    this.setState((prevState: WaferPlotterState) => {
      const newWaferStates = _.cloneDeep(prevState.waferStates);
      const waferKeys = Object.keys(newWaferStates);
      for (let i = 0; i < waferKeys.length; i += 1) {
        const isBoundToPubSubNetwork = selectedWaferKeyList.includes(waferKeys[i]);
        newWaferStates[waferKeys[i]].isBoundToPubSubNetwork = isBoundToPubSubNetwork;
        this.manageBindingOfEvents(newWaferStates, waferKeys[i], isBoundToPubSubNetwork);
      }
      return { waferStates: newWaferStates };
    }, () => {
      const { onControlSelectionChanged } = this.props;
      const { waferStates } = this.state;
      if (onControlSelectionChanged) {
        onControlSelectionChanged(Object.entries(waferStates).filter((x) => x[1].isBoundToPubSubNetwork === true).map((x) => x[0]));
      }
    });
  };

  determineActiveWaferKeyIndex = (prevActiveWaferKeyIndex: string, keyIndex: string, currentActiveWaferKeyIndex?: string): string => {
    if (currentActiveWaferKeyIndex) {
      return currentActiveWaferKeyIndex;
    }
    return prevActiveWaferKeyIndex === '' ? keyIndex : prevActiveWaferKeyIndex;
  };

  determineControlsPosition(wafer: WaferProps, prevControlsPosition: XYPoint, prevActiveWaferKeyIndex: string, currentActiveWaferKeyIndex?: string): XYPoint {
    if (currentActiveWaferKeyIndex) {
      return {
        x: this.waferUtils[currentActiveWaferKeyIndex].waferMapVariables.outerViewPort.x + this.waferUtils[currentActiveWaferKeyIndex].waferMapVariables.outerViewPort.width,
        y: this.gl!.canvas!.height
          - this.waferUtils[currentActiveWaferKeyIndex].waferMapVariables.outerViewPort.y - (this.waferUtils[currentActiveWaferKeyIndex].waferMapVariables.outerViewPort.height / 2),
      };
    }
    return prevActiveWaferKeyIndex === '' ? {
      x: wafer.outerViewport.x + wafer.outerViewport.width,
      y: this.gl!.canvas!.height - wafer.outerViewport.y - (wafer.outerViewport.height / 2),
    } : prevControlsPosition;
  }

  onToggleUndoPopover = (shouldOpen: boolean) => {
    this.setState({ showUndoPopover: shouldOpen });
  }

  onToggleRedoPopover = (shouldOpen: boolean) => {
    this.setState({ showRedoPopover: shouldOpen });
  }

  render() {
    const {
      width, height, pinControlsToWaferTop, config, plotterKey, draggableControlsWidth, plotterPageNumber,
    } = this.props;
    const {
      waferStates, controlsPosition, activeWaferKeyIndex, controlsWidgetEventKey, showUndoPopover, showRedoPopover,
    } = this.state;
    const noOfWafers = Object.keys(waferStates).length;
    return (
      <div
        className="wafer-plotter"
        style={{
          position: 'relative', height: `${height}px`, width: `${width}px`,
        }}
      >
        {!pinControlsToWaferTop && Object.keys(this.waferUtils).length > 0 ? (
          <WaferDraggableControls
            width={draggableControlsWidth}
            dieTextField={waferStates[activeWaferKeyIndex].dieTextField}
            onChangeDieTextField={this.onChangeDieTextField}
            activeWaferKeyIndex={activeWaferKeyIndex}
            controlsPosition={controlsPosition}
            controlsWidgetEventKey={controlsWidgetEventKey}
            onChangeWaferControl={(e) => { this.setState({ activeWaferKeyIndex: e.value }); }}
            onChangeWidgetAccordian={this.onChangeWidgetAccordian}
            onDrag={(event: any, data: any) => {
              if (data.y + 100 < height && data.x + draggableControlsWidth < width && data.x > 0 && data.y > 0) this.setState({ controlsPosition: { x: data.x, y: data.y } });
            }}
            additionalControls={config && config[activeWaferKeyIndex] ? config[activeWaferKeyIndex].additionalControls : undefined}
            dieTextFieldOptions={this.waferUtils[activeWaferKeyIndex].waferMapVariables.dieTextFieldOptions}
            isReticleCoordinatesSystemEnabled={waferStates[activeWaferKeyIndex].isReticleCoordinatesSystemEnabled}
            isDieCoordinatesSystemEnabled={waferStates[activeWaferKeyIndex].isDieCoordinatesSystemEnabled}
            onChangeDieCoordinateSystemValue={this.onChangeDieCoordinateSystemValue}
            onChangeReticleCoordinateSystemValue={this.onChangeReticleCoordinateSystemValue}
            waferList={Object.keys(waferStates).map((waferKey: string) => { return { waferKey, name: this.waferUtils[waferKey].waferMapVariables.waferName }; })}
            connectedWaferKeys={Object.keys(waferStates).filter((waferKey: string) => { return waferStates[waferKey].isBoundToPubSubNetwork; })}
            syncConnectWafersDropDownDataGridSelection={(e: any) => { if (e.value) this.handleConnectWafersSelection(e.value); }}
            connectWafersDataGridOnSelectionChanged={(e: any) => { this.handleConnectWafersSelection(e.selectedRowKeys); }}
            noOfWafers={noOfWafers}
            featureSpecificWaferTags={this.waferUtils[activeWaferKeyIndex].waferMapVariables.featureSpecificWaferTags}
            flipWaferMap={this.onFlipWafer}
            hasFlippingControls={this.waferUtils[activeWaferKeyIndex].waferMapVariables.hasFlippingControls}
            isXAxisFlipped={waferStates[activeWaferKeyIndex].isXAxisFlipped}
            isYAxisFlipped={waferStates[activeWaferKeyIndex].isYAxisFlipped}
            waferMainControlsProps={{
              waferStateStacks: this.waferStateStacks,
              actionHandler: this.actionHandler,
              onToggleUndoPopover: this.onToggleUndoPopover,
              onToggleRedoPopover: this.onToggleRedoPopover,
              showUndoPopover,
              showRedoPopover,
              featureSpecificWaferTags: this.waferUtils[activeWaferKeyIndex].waferMapVariables.featureSpecificWaferTags,
              dragChangeHandler: this.dragChangeHandler,
              zoomChangeHandler: this.zoomChangeHandler,
              hasMarkingFeature: this.waferUtils[activeWaferKeyIndex].waferMapVariables.hasMarkingFeature,
              hasRotationControls: this.waferUtils[activeWaferKeyIndex].waferMapVariables.hasRotationControls,
              isBoxZoomControlActive: waferStates[activeWaferKeyIndex].isBoxZoomControlActive,
              isDragControlActive: waferStates[activeWaferKeyIndex].isDragControlActive,
              isSelectionControlActive: waferStates[activeWaferKeyIndex].isSelectionControlActive,
              isMarkingControlActive: waferStates[activeWaferKeyIndex].isMarkingControlActive,
              markingMode: this.waferUtils[activeWaferKeyIndex].waferMapVariables.markingMode,
              keyIndex: activeWaferKeyIndex,
              selectionChangeHandler: this.selectionChangeHandler,
              rotateWaferMap: this.onRotateWafer,
              resetWaferZoomPositionRotation: this.onResetWaferZoomPositionRotation,
              zoomWaferMapUsingButtons: this.onZoomWaferMapUsingButtons,
              clearSelectionHandler: this.clearSelectionHandler,
              showRadarHandler: this.showRadarHandler,
              showRadar: waferStates[activeWaferKeyIndex].showRadar,
            }}
          />
        ) : null}
        { Object.keys(this.waferUtils).map((keyIndex: string) => {
          if (this.waferUtils[keyIndex].waferMapVariables.pageNumber !== undefined
            && plotterPageNumber !== undefined && this.waferUtils[keyIndex].waferMapVariables.pageNumber !== plotterPageNumber) return <></>;
          return (
            <div key={`pinnedRegion${keyIndex}`}>
              { pinControlsToWaferTop ? (
                <WaferPinnedControls
                  outerViewPort={this.waferUtils[keyIndex].waferMapVariables.outerViewPort}
                  waferMainControlsProps={{
                    waferStateStacks: this.waferStateStacks,
                    actionHandler: this.actionHandler,
                    onToggleUndoPopover: this.onToggleUndoPopover,
                    onToggleRedoPopover: this.onToggleRedoPopover,
                    showRedoPopover,
                    showUndoPopover,
                    featureSpecificWaferTags: this.waferUtils[activeWaferKeyIndex].waferMapVariables.featureSpecificWaferTags,
                    dragChangeHandler: this.dragChangeHandler,
                    zoomChangeHandler: this.zoomChangeHandler,
                    hasMarkingFeature: this.waferUtils[keyIndex].waferMapVariables.hasMarkingFeature,
                    hasRotationControls: this.waferUtils[keyIndex].waferMapVariables.hasRotationControls,
                    isBoxZoomControlActive: waferStates[keyIndex].isBoxZoomControlActive,
                    isDragControlActive: waferStates[keyIndex].isDragControlActive,
                    isSelectionControlActive: waferStates[keyIndex].isSelectionControlActive,
                    isMarkingControlActive: waferStates[keyIndex].isMarkingControlActive,
                    markingMode: this.waferUtils[keyIndex].waferMapVariables.markingMode,
                    keyIndex,
                    selectionChangeHandler: this.selectionChangeHandler,
                    rotateWaferMap: this.onRotateWafer,
                    resetWaferZoomPositionRotation: this.onResetWaferZoomPositionRotation,
                    zoomWaferMapUsingButtons: this.onZoomWaferMapUsingButtons,
                    clearSelectionHandler: this.clearSelectionHandler,
                    showRadarHandler: this.showRadarHandler,
                    showRadar: waferStates[keyIndex].showRadar,
                  }}
                />
              ) : null}
              { config && config[keyIndex] && this.waferUtils[keyIndex] !== undefined && keyIndex in this.waferUtils && this.waferUtils[keyIndex].waferMapVariables !== undefined
                ? (
                  <ContextMenu
                    showEvent="none"
                    ref={(ref: any) => { if (this.waferUtils[keyIndex]) this.waferUtils[keyIndex].waferMapVariables.contextMenuRef = ref; }}
                    target={`#glCanvas${plotterKey}`}
                    width={200}
                    onItemClick={(event: any) => {
                      event.component.hide();
                      if (config[keyIndex].onContextMenuItemClick) config[keyIndex].onContextMenuItemClick!(event);
                    }}
                    dataSource={config[keyIndex].contextMenuDataSource}
                  />
                )
                : null}
              { config && config[keyIndex]
                ? (
                  <WaferPrimaryComponent waferUtil={this.waferUtils[keyIndex]} pinControlsToWaferTop={pinControlsToWaferTop}>
                    {config[keyIndex].primaryComponents}
                  </WaferPrimaryComponent>
                )
                : null}
              { waferStates[keyIndex].showWaferInfo
                ? (
                  <WaferInfoRegion
                    outerViewPort={this.waferUtils[keyIndex].waferMapVariables.outerViewPort}
                    radarViewPort={this.waferUtils[keyIndex].waferMapVariables.radarViewPort}
                    activeWaferKeyIndex={activeWaferKeyIndex}
                    pinControlsToWaferTop={pinControlsToWaferTop}
                    isBoundToPubSubNetwork={waferStates[keyIndex].isBoundToPubSubNetwork}
                    onControlsRadioChange={this.onControlsRadioChange}
                    onBindCheckboxChange={this.onBindCheckboxChange}
                    waferUtil={this.waferUtils[keyIndex]}
                    onChangeDieTextField={this.onChangeDieTextField}
                    dieTextField={waferStates[keyIndex].dieTextField}
                    noOfWafers={noOfWafers}
                  />
                ) : null}
            </div>
          );
        })}
        <canvas
          id={`glCanvas${plotterKey}`}
          ref={(canvasEl) => { this.glCanvas = canvasEl; }}
          onContextMenu={(e: any) => { e.preventDefault(); return false; }}
          style={{
            position: 'absolute', left: '0px', top: '0px', height: `${height}px`, width: `${width}px`,
          }}
        />
        <canvas
          ref={(canvasEl) => { this.textCanvas = canvasEl; }}
          style={{
            position: 'absolute', left: '0px', top: '0px', height: `${height}px`, width: `${width}px`, pointerEvents: 'none',
          }}
        />
        <div
          ref={(tooltipRef) => { this.tooltipRef = tooltipRef; }}
          className="wafer-tooltip"
        />
      </div>
    );
  }
}
