import React from 'react';
import TreeList, { Column, FilterRow } from 'devextreme-react/tree-list';
import { Button as DxButton } from 'devextreme-react/data-grid';
import { IFieldExpression } from './ReportHeaderBuilder';

interface IReportHeaderFieldsTreeViewProps {
  dataSource : any[];
  selectedFields: IFieldExpression[];
  selectionHandler: (e:any) => void
}
const ReportHeaderFieldsTreeView = (props:IReportHeaderFieldsTreeViewProps) => {
  const { dataSource, selectionHandler, selectedFields } = props;

  const cellRender = (e:any) => {
    if (e.rowType === 'data') {
      if (e.columnIndex === 0 && e.data.parentId == null) {
        e.cellElement.className = 'font-weight-bold';
      }
      if (e.columnIndex === 1) {
        if (e.data.parentId === null) {
          e.cellElement.className = 'invisible';
        } else {
          let isDisabled = false;
          if (selectedFields !== undefined) {
            const minParentRank = selectedFields.filter((x:any) => x.parentRank !== undefined).map((item:any) => (item.parentRank));
            if (minParentRank.length > 0) {
              const minRank = minParentRank.reduce((min:any, val:any) => (min < val ? min : val));

              const currentParentRank = e.data.ParentRank;
              if (currentParentRank < minRank) {
                isDisabled = true;
              }
            }
          }
          e.cellElement.className = `dx-command-edit dx-command-edit-with-icons ${isDisabled ? 'd-none' : ''}`;
        }
      }
    }
  };
  return (
    <TreeList
      dataSource={selectedFields !== undefined ? dataSource.filter((el:any) => {
        return selectedFields.findIndex((x:any) => x.columnName === el.columnName && x.entityType === el.entityType) < 0;
      }) : dataSource}
      showBorders
      parentIdExpr="parentId"
      showColumnLines={false}
      showColumnHeaders={false}
      autoExpandAll={false}
      height={500}
      onCellPrepared={cellRender}
    >
      <FilterRow visible />
      <Column
        dataField="caption"
      />
      <Column
        type="buttons"
        width={36}
      >
        <DxButton
          hint="Add to Selected List"
          icon="add"
          cssClass="text-success"
          onClick={selectionHandler}
        />
      </Column>
    </TreeList>
  );
};

export default ReportHeaderFieldsTreeView;
