import React from 'react';
import { Form } from 'react-bootstrap';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface IToggleProps {
  hint?: IHint
  id: string
  name: string
  title: string
  checked?: boolean
  className?: string | undefined
  onChange: any
}

const Toggle = (props: IToggleProps) => {
  const {
    hint, id, name, title, checked, className, onChange,
  } = props;

  return (
    <div className="d-flex align-items-center">
      <Form.Check
        type="switch"
        id={id}
        name={name}
        checked={checked}
        className={className}
        onChange={(e: any) => onChange(e)}
        label={title}
      />
      <Hint hint={hint} variant="icon" className="mt6" />
    </div>
  );
};

Toggle.defaultProps = {
  checked: false,
  className: undefined,
};

export default Toggle;
