import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, { Column, Selection, } from 'devextreme-react/data-grid';
import toast from 'CustomToast';
import Heading from '../../wrapped-component/hint-controls/Heading';
import { IWaferCombinationsRequestObject } from '../../../interfaces';

interface IPolicyWaferSelectionGridProps {
  policyResults: any;
  height: number;
  showSpecificPolicyOutput: (e: any) => void
  selectedStageType: string;
  selectedStageId: string;
  updateWaferSelection: (selectedWafer: any) => void
  getRequestObjectForBinWaferMapComparison: (selectedData: any) => void
}

class PolicyWaferSelectionGrid extends Component<IPolicyWaferSelectionGridProps, any> {
  dataGridRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      selectedStageType: props.selectedStageType,
    };
    this.dataGridRef = React.createRef();
  }

  get dataGrid() {
    return this.dataGridRef.current.instance;
  }

  componentDidUpdate(prevProps: any) {
    const {
      selectedStageType,
      selectedStageId
    } = this.props;
    if (prevProps.selectedStageId !== selectedStageId) {
      if (selectedStageType === 'SPC') {
        this.dataGrid.selectAll();
      } else {
        this.dataGrid.deselectAll();
      }
    }
  }

  onRowSelection = (selectedArgs: any) => {
    const {
      showSpecificPolicyOutput,
      selectedStageType,
      updateWaferSelection,
      policyResults,
      getRequestObjectForBinWaferMapComparison,
    } = this.props;

    const { selectedRowsData } = selectedArgs;

    if (selectedRowsData.length === 0) {
      return;
    }
    if (selectedStageType === 'PAT' && selectedRowsData[0] !== undefined) {
      const selectedWaferID: any[] = [];
      const selectedWaferCombination: any[] = [];
      policyResults.forEach((policyResult: any) => {
        if (JSON.stringify(policyResult) === JSON.stringify(selectedRowsData[0])) {
          selectedRowsData[0].policyStepResult.forEach((ruleSet: any) => {
            const waferCombinationRequestObject: IWaferCombinationsRequestObject = {
              baseWafer: {
                id: ruleSet.inputWaferId,
                probeCount: ruleSet.inputWaferProbeCount,
                key: ruleSet.waferKey,
                yield: ruleSet.inputWaferYield,
                dieCount: ruleSet.totalDieCount,
              },
              nonBaseWafer: {
                id: ruleSet.outputWaferId,
                probeCount: ruleSet.outputWaferProbeCount,
                key: ruleSet.waferKey,
                yield: ruleSet.outputWaferYield,
                dieCount: ruleSet.totalDieCount,
              }
            }
            selectedWaferCombination.push(waferCombinationRequestObject);
            ruleSet.ruleSetResult.forEach((rule: any) => {
              rule.ruleResult.forEach((testParameter: any) => {
                testParameter.testParameterResult.forEach((testParameterResult: any) => {
                  Object.keys(testParameterResult.siteWiseLimits)
                    .forEach((site) => {
                      let siteString = '';
                      if (site !== '-1') siteString = ` (Site: ${site})`;
                      if (!selectedWaferID.includes(testParameterResult.outputWaferId)) {
                        selectedWaferID.push(testParameterResult.outputWaferId);
                      }
                    });
                });
              });
            });
          });
        }
      });
      updateWaferSelection(selectedWaferID);
      getRequestObjectForBinWaferMapComparison(selectedWaferCombination);
    }

    if (!selectedStageType) {
      toast.warning('Please select a policy stage first.');
      this.dataGrid.deselectAll();
      return;
    }
    if (showSpecificPolicyOutput) {
      showSpecificPolicyOutput(selectedRowsData[0]);
    }
  };

  render() {
    const {
      policyResults,
      height
    } = this.props;
    const { selectedStageType } = this.state;
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Heading size={6}>Policy Wafer Selection Grid</Heading>
            Select a wafer to view the Policy Output
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="mt10">
              <DataGrid
                height={height}
                showRowLines
                columnAutoWidth
                scrolling={{ mode: 'virtual' }}
                showBorders
                dataSource={policyResults != null && policyResults.length > 0 ? policyResults : []}
                allowColumnReordering
                onSelectionChanged={this.onRowSelection}
                ref={this.dataGridRef}
              >
                <Selection mode={selectedStageType === 'SPC' ? 'none' : 'single'} />
                <Column alignment="left" caption="Simulated Policy Name" dataField="policy.name" />
                <Column alignment="left" caption="Created On" dataType="datetime" dataField="policy.createdOn" />
                <Column alignment="left" caption="Version" dataField="policy.version" />
                <Column visible={false} alignment="left" caption="Wafer ID" dataField="inputWaferId" />
                <Column alignment="left" caption="Wafer ID" dataField="wafer.key" />
                <Column alignment="left" caption="Wafer TesterType" dataField="wafer.testerType" />
              </DataGrid>
            </div>
          </Col>
        </Row>

      </div>
    );
  }
}

export default PolicyWaferSelectionGrid;
