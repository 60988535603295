import _ from 'lodash';
import React from 'react';
import LevelPopover from './level-popover';

// eslint-disable-next-line react/prefer-stateless-function
export default class EditLevelComponent extends React.Component<any, any> {
  render() {
    const { data, masterMetaColumns } = this.props;
    return (
      <LevelPopover
        onSave={(lvlData: any) => {
          data.setValue(_.cloneDeep(lvlData));
          data.data.level = _.cloneDeep(lvlData);
        }}
        levelsData={data.value || {}}
        title="Select Level Types"
        masterMetaColumns={masterMetaColumns || {}}
      />
    );
  }
}
