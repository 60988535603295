import React from 'react';
import {
  Container, Row, Col, Nav, Navbar,
} from 'react-bootstrap';
import './policy-execution.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, } from '@fortawesome/free-solid-svg-icons';

const PolicyExecution = () => {
  const policySteps = [
    {
      type: 'PAT',
      state: 'complete',
    },
    {
      type: 'DPAT',
      state: 'complete',
    },
    {
      type: 'SWM',
      state: 'error',
    },
    {
      type: 'PAT',
      state: 'scheduled',
    },
    {
      type: 'SWM',
      state: 'scheduled',
    },
  ];

  return (
    <>
      <Navbar bg="light" expand="lg" className="nav-shadow">
        <Nav className="mr-auto">
          <div className="d-flex align-items-center h-100 p10">
            <h5 className="mr20">
              Policy Name
            </h5>
            <span className="mt-5 mr20">
              v
              {' '}
              4.1
            </span>
            <span className="mt-5 mr20 border-all p4 rounded">
              DRAFT
            </span>
            <span className="mt-5 border-all p4 rounded">
              PUBLIC
            </span>
          </div>
        </Nav>
      </Navbar>
      <Container fluid>
        <Row className="pt30 pl30 pr30">
          <Col>
            {policySteps.map((item, index:number) => (
              <>
                {index > 0 && (
                  <div className="policy-execution-arrow">
                    <FontAwesomeIcon
                      icon={faArrowDown}
                    />
                  </div>
                )}
                <div className={`border-all execution-step-border-${item.state} w500 h50 d-flex align-items-center text-center justify-content-between`}>
                  <div className="h-100 w60 d-flex align-items-center text-center justify-content-center step-type">{item.type}</div>
                  <p className={`border-all p2 rounded mt12 mr10 execution-state-${item.state}`}>{item.state.toUpperCase()}</p>
                </div>
              </>
            ))}
          </Col>
        </Row>

      </Container>
    </>
  );
};

export default PolicyExecution;
