import React from 'react';
import { SelectBox } from 'devextreme-react';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import CodeEditor from '../code-editor/CodeEditor';
import './expression-builder.scss';
import getDropDownOptions from 'components/getDropDownOptions';
import toast from '../../../CustomToast';

interface IExpressionBuilderRules {
  objects: {
    caption: string;
    dataField: string;
    dataType: string;
    values?: string[];
  }[];
  operators: {
    caption: string;
    dataField: string;
  }[];
  typeOperations: {
    dataType: string;
    operators: string[];
  }[];
}
interface ICustomizedInputForSPCAttributes {
  caption: string;
  columnFirst: any,
  columnSecond: {
    caption: string,
    values: any,
  }
  columnFourth: {
    caption: string,
    values: any,
  }
  columnFifth: {
    caption: string,
    values: any,
  }
  onToolbarPreparing: (event: any) => void;
}

interface IExpressionBuilderProps {
  height?: number;
  viewMode?: 'tabular' | 'text';
  rules: IExpressionBuilderRules;
  defaultStatements?: any[]
  onUpdate: (event: any) => void;
  hideOutputSettings?: boolean;
  isInputCustomized?: boolean;
  customizedInputForSPCAttributes?:ICustomizedInputForSPCAttributes;
  isInputUOMDisabled?: boolean;
  isOutputUOMDisabled?: boolean;
}

class ExpressionBuilder extends React.Component<IExpressionBuilderProps, any> {
  static defaultProps: Partial<IExpressionBuilderProps> = {
    viewMode: 'tabular',
    hideOutputSettings: false,
  };

  constructor(props: IExpressionBuilderProps) {
    super(props);
    const { viewMode, rules, defaultStatements } = this.props;
    const options: any[] = [];
    const booleanOperators = ['{if}', '{or}', '{and}', '{then}', '{end}'];
    options.push({ regex: /^$/, values: { type: 'list', values: booleanOperators } });
    // eslint-disable-next-line max-len
    options.push({ regex: /]\s$/, values: { type: 'groupedList', values: [{ key: 'Operators', items: rules.operators.map((x) => x.caption) }, { key: 'Condition Operators', items: booleanOperators }] } });
    options.push({ regex: /\s$/, values: { type: 'list', values: [...rules.objects.map((x) => `[${x.caption}]`), '[(]', '[)]'] } });
    options.push({ regex: /\[$/, values: { type: 'list', values: [...rules.objects.map((x) => `${x.caption}]`), '(]', ')]'] } });
    const statements = this.parseDefaultStatements(rules, defaultStatements);
    this.state = {
      viewMode,
      statements,
      statementsTextView: '',
      codeEditorOptions: options,
    };
    this.convertStatementsToTextView = this.convertStatementsToTextView.bind(this);
    this.convertTextIntoStatements = this.convertTextIntoStatements.bind(this);
    this.updateStatementsToParent = this.updateStatementsToParent.bind(this);
  }

  parseDefaultStatements = (rules: IExpressionBuilderRules, defaultStatements?: any[]) => {
    const formattedStatements: any[] = [];
    if (!defaultStatements) {
      return formattedStatements;
    }
    let i = 0;
    defaultStatements.forEach((x: any) => {
      const copyStatement = JSON.parse(JSON.stringify(x));
      if (x.inputParameter) {
        const mappedObject = rules.objects.find((y) => y.dataField === x.inputParameter);
        copyStatement.inputParameter = mappedObject === undefined ? x.inputParameter : mappedObject.caption;
      }
      if (x.inputOperator) {
        const mappedObject = rules.objects.find((y) => y.dataField === x.inputOperator);
        copyStatement.inputOperator = mappedObject === undefined ? x.inputOperator : mappedObject.caption;
      }
      if (x.inputValue && copyStatement.inputParameter) {
        const mappedObject = rules.objects.find((y) => y.dataField === x.inputParameter);
        copyStatement.inputValue = mappedObject !== undefined && mappedObject.dataType === 'string' ? x.inputValue.replace(/^"(.*)"$/, '$1') : x.inputValue;
      }
      if (x.outputParameter) {
        const mappedOutputObject = rules.objects.find((y) => y.dataField === x.outputParameter);
        copyStatement.outputParameter = mappedOutputObject === undefined ? x.outputParameter : mappedOutputObject.caption;
      }
      if (x.outputOperator) {
        const mappedOutputObject = rules.objects.find((y) => y.dataField === x.outputOperator);
        copyStatement.outputOperator = mappedOutputObject === undefined ? x.outputOperator : mappedOutputObject.caption;
      }
      if (x.outputValue && copyStatement.outputParameter) {
        const mappedObject = rules.objects.find((y) => y.dataField === x.outputParameter);
        copyStatement.outputValue = mappedObject !== undefined && mappedObject.dataType === 'string' ? x.outputValue.replace(/^"(.*)"$/, '$1') : x.outputValue;
      }
      // eslint-disable-next-line no-underscore-dangle
      copyStatement.__KEY__ = i;
      i += 1;
      formattedStatements.push(copyStatement);
    });
    return formattedStatements;
  };

  convertStatementsToTextView = () => {
    const { statements } = this.state;
    let statementsTextView = '';
    statements.forEach((x: any) => {
      if (x.inputParameter) {
        statementsTextView += `{if} [${x.inputParameter}] `;
        if (x.inputOperator) {
          statementsTextView += `${x.inputOperator} [${x.inputValue}] `;
          if (x.inputUom) {
            statementsTextView += `[${x.inputUom}] `;
          }
          statementsTextView += `{${x.inputBooleanExpression}} `;
        }
      }
      if (x.outputParameter) {
        statementsTextView += `[${x.outputParameter}] ${x.outputOperator} [${x.outputValue}] `;
        if (x.outputUom) {
          statementsTextView += `[${x.outputUom}] `;
        }
      }
      if (x.outputBooleanExpression) {
        statementsTextView += `{${x.outputBooleanExpression}}`;
      }
      if (x.outputParameter || x.outputBooleanExpression) {
        statementsTextView += ';';
      }
      statementsTextView += '\n';
    });
    this.setState({ statementsTextView });
    this.updateStatementsToParent();
  };

  getNthOccurencePosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  /* eslint-disable prefer-destructuring */
  convertTextIntoStatements = () => {
    try {
      const { statementsTextView } = this.state;
      const statements: any[] = [];
      statementsTextView.split('{if}').map((i: string) => i.trim()).filter((i: any) => i).forEach((x: any) => {
        const ifStatement = {
          inputParameter: undefined,
          inputOperator: undefined,
          inputValue: undefined,
          inputUom: undefined,
          inputBooleanExpression: undefined,
          outputParameter: undefined,
          outputOperator: undefined,
          outputValue: undefined,
          outputUom: undefined,
          outputBooleanExpression: undefined,
        };
        statements.push(ifStatement);
        const ifEndPosition = this.getNthOccurencePosition(x, '{', 1) - 1;
        const ifConditionText = x.slice(0, ifEndPosition);
        ifStatement.inputParameter = ifConditionText.split(']')[0].split('[').pop();
        if (ifStatement.inputParameter === '(') return;
        let outputConditionsText: any = '';
        if (ifStatement.inputParameter === ')') {
          outputConditionsText = x.slice(this.getNthOccurencePosition(x, ']', 1) + 1);
          ifStatement.outputBooleanExpression = outputConditionsText.split('}')[0].split('{').pop();
        } else {
          ifStatement.inputOperator = ifConditionText.split(']')[1].split('[')[0].trim();
          ifStatement.inputValue = ifConditionText.split(']')[1].split('[')[1];
          if (ifConditionText.split(']').map((y: string) => y.trim()).filter((y: any) => y).length > 2) {
            ifStatement.inputUom = ifConditionText.split(']').map((y: string) => y.trim()).filter((y: any) => y)[2].split('[').pop();
          }
          outputConditionsText = x.slice(ifEndPosition);
          ifStatement.inputBooleanExpression = outputConditionsText.split('}')[0].split('{').pop();
        }
        // const thenConditionsText = outputConditionsText.split('}')[1].split('{')[0];
        if (outputConditionsText.trim() === '') return;
        const thenConditionSplitList = outputConditionsText.split('{').map((i: string) => i.trim()).filter((i: any) => i)[0]
          .split('}').pop().split(';')
          .map((i: any) => i.trim())
          .filter((i: any) => i);
        const thenStatements = [];
        for (let i = 0; i < thenConditionSplitList.length; i += 1) {
          const thenStatement = {
            outputParameter: undefined,
            outputOperator: undefined,
            outputValue: undefined,
            outputUom: undefined,
            outputBooleanExpression: undefined,
          };
          const thenConditionStatement = thenConditionSplitList[i].split('[').map((j: string) => j.trim()).filter((j: any) => j);
          thenStatement.outputParameter = thenConditionStatement[0].split(']')[0];
          thenStatement.outputOperator = thenConditionStatement[0].split(']').pop().trim();
          thenStatement.outputValue = thenConditionStatement[1].split(']')[0];
          if (thenConditionStatement.length > 2) {
            thenStatement.outputUom = thenConditionStatement[2].split(']')[0];
          }
          thenStatements.push(thenStatement);
        }
        if (thenStatements.length > 0) {
          if (outputConditionsText.split('}')[1].split('{').length > 1) {
            thenStatements[thenStatements.length - 1].outputBooleanExpression = outputConditionsText.split('}')[1].split('{').pop();
          }
          if (ifStatement.inputParameter === ')') {
            statements.push(...thenStatements);
          } else {
            ifStatement.outputParameter = thenStatements[0].outputParameter;
            ifStatement.outputOperator = thenStatements[0].outputOperator;
            ifStatement.outputValue = thenStatements[0].outputValue;
            ifStatement.outputUom = thenStatements[0].outputUom;
            ifStatement.outputBooleanExpression = thenStatements[0].outputBooleanExpression;
            if (thenStatements.length > 1) {
              statements.push(...thenStatements.slice(1));
            }
          }
        }
      });
      this.setState({ statements });
      this.updateStatementsToParent();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  updateStatementsToParent = async () => {
    const { onUpdate, rules } = this.props;
    const { statements } = this.state;
    const formattedStatements: any[] = [];
    statements.forEach((x: any) => {
      const copyStatement = JSON.parse(JSON.stringify(x));
      if (x.inputParameter) {
        const mappedObject = rules.objects.find((y) => y.caption === x.inputParameter);
        copyStatement.inputParameter = mappedObject === undefined ? x.inputParameter : mappedObject.dataField;
      }
      if (x.inputOperator) {
        const mappedObject = rules.objects.find((y) => y.caption === x.inputOperator);
        copyStatement.inputOperator = mappedObject === undefined ? x.inputOperator : mappedObject.dataField;
      }
      if (x.inputValue && copyStatement.inputParameter) {
        const mappedObject = rules.objects.find((y) => y.dataField === copyStatement.inputParameter);
        copyStatement.inputValue = mappedObject !== undefined && mappedObject.dataType === 'string' ? `"${x.inputValue}"` : x.inputValue;
      }
      if (x.outputParameter) {
        const mappedOutputObject = rules.objects.find((y) => y.caption === x.outputParameter);
        copyStatement.outputParameter = mappedOutputObject === undefined ? x.outputParameter : mappedOutputObject.dataField;
      }
      if (x.outputOperator) {
        const mappedOutputObject = rules.objects.find((y) => y.caption === x.outputOperator);
        copyStatement.outputOperator = mappedOutputObject === undefined ? x.outputOperator : mappedOutputObject.dataField;
      }
      if (x.outputValue && copyStatement.outputParameter) {
        const mappedObject = rules.objects.find((y) => y.dataField === copyStatement.outputParameter);
        copyStatement.outputValue = mappedObject !== undefined && mappedObject.dataType === 'string' ? `"${x.outputValue}"` : x.outputValue;
      }
      // eslint-disable-next-line no-underscore-dangle
      delete copyStatement.__KEY__;
      formattedStatements.push(copyStatement);
    });
    onUpdate(formattedStatements);
  };

  render() {
    const {
      statements,
      statementsTextView,
      codeEditorOptions,
      viewMode,
    } = this.state;
    const {
      rules, hideOutputSettings, customizedInputForSPCAttributes, height, isInputUOMDisabled, isOutputUOMDisabled,
    } = this.props;

    const inputColumnsConfig = {
      caption: customizedInputForSPCAttributes ? customizedInputForSPCAttributes.caption : 'Input',
      // caption: 'Input',
      fields: [
        customizedInputForSPCAttributes
          ? customizedInputForSPCAttributes.columnFirst
          : {
            caption: 'Parameter',
            dataField: 'inputParameter',
            editCellRender: (cell: any) => {
              return (
                <SelectBox
                  searchEnabled
                  defaultValue={cell.value}
                  displayExpr="caption"
                  valueExpr="caption"
                  items={[...rules.objects, { caption: '(', dataField: '(' }, { caption: ')', dataField: ')' }, { caption: '', dataField: '' }]}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cell.column.lookup}
                  /* eslint-disable no-param-reassign */
                  onValueChanged={(e: any) => {
                    cell.setValue(e.value);
                  }}
                  dropDownOptions={getDropDownOptions('cell', cell,)}
                />
              );
            },
            showInfo: false,
            allowFiltering: false,
            allowSorting: false,
          },
        {
          caption: customizedInputForSPCAttributes ? customizedInputForSPCAttributes.columnSecond.caption : 'Operator',
          dataField: customizedInputForSPCAttributes ? 'condition' : 'inputOperator',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                displayExpr="caption"
                valueExpr="caption"
                items={customizedInputForSPCAttributes ? customizedInputForSPCAttributes.columnSecond.values : [...rules.operators, { caption: '', dataField: '' }]}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'Value',
          dataField: 'inputValue',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: customizedInputForSPCAttributes ? customizedInputForSPCAttributes.columnFourth.caption : 'UOM',
          dataField: 'inputUom',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                items={customizedInputForSPCAttributes ? customizedInputForSPCAttributes.columnFourth.values : ['nano', 'micro', 'milli', 'kilo', 'mega', 'giga', '']}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                disabled={isInputUOMDisabled}
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'Boolean Expression',
          dataField: 'inputBooleanExpression',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                displayExpr="caption"
                valueExpr="caption"
                items={[
                  { dataField: 'and', caption: 'and' },
                  { dataField: 'or', caption: 'or' },
                  { dataField: 'then', caption: 'then' },
                  { dataField: '', caption: '' },
                ]}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
      ],
    };
    const outputColumnsConfig = {
      caption: 'Output',
      fields: [
        {
          caption: 'Parameter',
          dataField: 'outputParameter',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                searchEnabled
                defaultValue={cell.value}
                displayExpr="caption"
                valueExpr="caption"
                items={[...rules.objects, { caption: '', dataField: '' }]}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'Operator',
          dataField: 'outputOperator',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                displayExpr="caption"
                valueExpr="caption"
                items={[
                  { dataField: '=', caption: '=' },
                  { dataField: '', caption: '' },
                ]}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'Value',
          dataField: 'outputValue',
          dataType: 'string',
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'UOM',
          dataField: 'outputUom',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                items={['nano', 'micro', 'milli', 'kilo', 'mega', 'giga', '']}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                disabled={isOutputUOMDisabled}
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
        {
          caption: 'Boolean Expression',
          dataField: 'outputBooleanExpression',
          editCellRender: (cell: any) => {
            return (
              <SelectBox
                defaultValue={cell.value}
                displayExpr="caption"
                valueExpr="caption"
                items={[
                  { dataField: 'and', caption: 'and' },
                  { dataField: 'end', caption: 'end' },
                  { dataField: '', caption: '' },
                ]}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cell.column.lookup}
                /* eslint-disable no-param-reassign */
                onValueChanged={(e: any) => {
                  cell.setValue(e.value);
                }}
                dropDownOptions={getDropDownOptions('cell', cell,)}
              />
            );
          },
          showInfo: false,
          allowFiltering: false,
          allowSorting: false,
        },
      ],
    };

    const expressionGridFields = hideOutputSettings ? [inputColumnsConfig] : [inputColumnsConfig, outputColumnsConfig];

    return (
      <div className="border-all pl10 pr10 pb20 background-color-light">
        {/* <div className="d-flex"> */}
        {/*  <Button */}
        {/*    variant="outline-primary" */}
        {/*    onClick={() => { */}
        {/*      if (viewMode === 'tabular') { */}
        {/*        viewMode = 'text'; */}
        {/*        this.convertStatementsToTextView(); */}
        {/*      } else { */}
        {/*        viewMode = 'tabular'; */}
        {/*        this.convertTextIntoStatements(); */}
        {/*      } */}
        {/*      this.setState({ viewMode }); */}
        {/*    }} */}
        {/*  > */}
        {/*    Switch to */}
        {/*    {' '} */}
        {/*    {viewMode === 'tabular' ? 'advanced' : 'tabular'} */}
        {/*    {' '} */}
        {/*    view */}
        {/*  </Button> */}
        {/* </div> */}
        {viewMode === 'tabular' && (
          <CustomizedDataGrid
            totalRowsToDisplay={100}
            allowAdding
            allowUpdating
            allowDeleting
            enableColumnChooser={false}
            showAdvancedFilters={false}
            height={height || 500}
            showGroupPanel={false}
            showFilterRow={false}
            showColumnLines
            showRowLines
            onToolbarPreparing={customizedInputForSPCAttributes ? customizedInputForSPCAttributes.onToolbarPreparing : undefined}
            onRowInserting={(event:any) => {
              if (Object.keys(event.data).length <= 1){
                event.cancel = true;
                toast.warning('Cannot save empty rows in the expressions grid.');
              } else event.cancel = false;
            }}
            onRowUpdated={(event:any) => {
              // eslint-disable-next-line no-underscore-dangle
              const index = statements.findIndex((x: any) => x.__KEY__ === event.data.__KEY__);
              statements[index] = event.data;
              // this.setState({ statements }, () => { this.updateStatementsToParent(); });
              this.setState({ statements }, this.updateStatementsToParent);
            }}
            onRowInserted={(event: any) => {
              if (Object.keys(event.data).length <= 1) return;
              statements.push(event.data);
              this.setState({ statements }, this.updateStatementsToParent);
              // this.updateStatementsToParent();
            }}
            onRowRemoved={(event: any) => {
              const index = statements.findIndex((x: any) => x.inputParameter === event.data.inputParameter
                && x.inputOperator === event.data.inputOperator
                && x.inputValue === event.data.inputValue
                && x.inputUom === event.data.inputUom
                && x.inputBooleanExpression === event.data.inputBooleanExpression
                && x.outputParameter === event.data.outputParameter
                && x.outputOperator === event.data.outputOperator
                && x.outputValue === event.data.outputValue
                && x.outputUom === event.data.outputUom
                && x.outputBooleanExpression === event.data.outputBooleanExpression);
              this.setState({ statements: statements.filter((statement:any, ind: number) => ind !== index) }, this.updateStatementsToParent);
              // this.setState({ statements: statements.splice(index, 1) });
            }}
            fields={expressionGridFields}
            tableData={statements}
            editingMode="batch"
          />
        )}
        {viewMode === 'text' && (
          <>
            {/* {customizedInputForSPCAttributes !== undefined && (
              <div className="d-flex border-all align-items-center justify-content-center">
                <text>
                  customi
                </text>
              </div>
            )} */}
            <CodeEditor
              matchingCriterion={codeEditorOptions}
              placeHolder="Conditional Statements"
              defaultValue={statementsTextView}
              rows={15}
              updateOnBlur={(value: string) => {
                this.setState({ statementsTextView: value });
                this.convertTextIntoStatements();
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default ExpressionBuilder;
