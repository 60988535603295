import React from 'react';
import '../../../../assets/yw-icons/yw-icons.css';
import { Accordion, Card, } from 'react-bootstrap';
import { Tooltip } from 'devextreme-react/tooltip';
import bgImageTop from 'assets/images/wafer-bg-top.png';
import bgImageLeft from 'assets/images/wafer-bg-left.png';
import bgImageRight from 'assets/images/wafer-bg-right.png';
import bgImageBottom from 'assets/images/wafer-bg-bottom.png';
import bgImageTopFlat from 'assets/images/wafer-bg-top-flat.png';
import bgImageLeftFlat from 'assets/images/wafer-bg-left-flat.png';
import bgImageRightFlat from 'assets/images/wafer-bg-right-flat.png';
import bgImageBottomFlat from 'assets/images/wafer-bg-bottom-flat.png';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import ContextMenu from 'devextreme-react/context-menu';
// eslint-disable-next-line no-unused-vars
import { dxContextMenuItem } from 'devextreme/ui/context_menu';
import { CheckBox } from 'devextreme-react';
import toast from 'CustomToast';
// eslint-disable-next-line no-unused-vars
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMarker, faTools, faUndo } from '@fortawesome/free-solid-svg-icons';
import TooltipWrapper from 'components/wrapped-component/hint-controls/tooltip-wrapper';
import _ from 'lodash';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import renderWaferMap from './wafer-map-scripts/RenderWaferMap';
import selection from './wafer-map-scripts/Selection';
import zoom from './wafer-map-scripts/Zoom';
import setBinNumberCanvas from './wafer-map-scripts/BinNumber';
import './wafer.css';
import setTickText from './wafer-map-scripts/TickTexts';
import setTooltip from './wafer-map-scripts/Tooltip';
import radarPointer from './wafer-map-scripts/RadarPointer';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from './web-gl-utils/Utils';
import {
  DieSubView, ReticleReference, WaferMapData, WaferMapTestData, ZoneData,
} from './web-gl-utils/Types';
import WaferMapVariablesClass, { defaultToolTipFields } from './variables/WaferMapVariablesClass';
// eslint-disable-next-line no-unused-vars
import WaferData from '../WaferData';
import PublishSubscribe, { EventTypes } from '../PublishSubscribe';
import {
  ColAxisDirection, ColFlip, ExclusionType, FlipAxis, MainControl, MarkingMode, NotchPosition, RotateDirection, RowAxisDirection, RowFlip,
} from './web-gl-utils/Enums';
import { CustomToggle } from '../wafer-map-v2/Utils/CustomAccordianToggle';

type WaferMapProps = {
  onSelectionChanged?: any,
  rotationAngle?: number,
  draggable?: boolean,
  showRadar?: boolean,
  showGridBordersAndTicks?: boolean,
  showToolbar?: boolean,
  showRadarOnly?: boolean,
  enableDieImages?: boolean,
  showRotationNotch?: boolean,
  enableTooltip?: boolean,
  keyIndex: string,
  waferData: WaferData,
  waferSize: number,
  waferTopOffset?: number,
  padding?: number,
  dieSize: { dieWidth: number, dieHeight: number },
  colOffset?: number,
  rowOffset?: number,
  setWaferInstanceHandler?: (utils: WebGLUtils, keyIndex: string) => void,
  takeScreenshot?: (url:string) => void,
  ringRadius?: number,
  markZones?: boolean,
  dieWidthToStreetWidthRatio?: number,
  dieHeightToStreetHeightRatio?: number,
  ringDiameterToWaferDiameterRatio?: number,
  waferHeightToRowsRatio?: number,
  waferWidthToColsRatio?: number,
  waferBGOffsetXDies?: number,
  waferBGOffsetYDies?: number,
  isWaferControlMap?: boolean,
  isManualServiceWafer?: boolean,
  showRing?: boolean,
  contextMenuDataSource?: string | Array<dxContextMenuItem> | DataSource | DataSourceOptions,
  onContextMenuItemClick?: (event: any) => void,
  selectionCoords?: { x: number, y: number }[]; // keeps track of all dies x and y values (not axes coordinates) that were selected
  overlayReticle?: boolean,
  binTextFieldFlag?: boolean,
  reticleSize?: { x: number, y: number },
  binTextField?: string,
  zoomScaleFactor?: number,
  additionalControls?: any[],
  getParentDivRef?: (ref: any) => void,
  currentDieType?: string,
  onToggleWaferControlsPosition?: (pinControlsToWaferTop: boolean) => void,
  pinControlsToWaferTop?: boolean,
  mainControl?: MainControl,
  onChangeMainControl?: (newControl: MainControl) => void,
  onChangeWidgetAccordian?: (eventKey: string) => void,
  controlsWidgetEventKey?: string,
  dieTypes?: any,
  hasFlippingControls?: boolean,
  hasRotationControls?: boolean,
  markWaferCenter?: boolean,
  notchPosition?: NotchPosition,
  reticleReference?: ReticleReference,
  showReferenceReticle?: boolean,
  dieTypeField?: string,
  selectedDieField?: string,
  zones?: ZoneData[],
  wcmWaferDiameter?: number,
  wcmWaferEdgeExclusion?: number,
  wcmWaferNotchKeepOut?: number,
  wcmWaferFlatKeepOut?: number,
  wcmWaferBaseFlat?: number,
  wcmWaferScribeLine?: number,
  wcmExclusionType?: ExclusionType,
  tooltipFields?: string[][],
};

type WaferMapState = {
  waferMapInstance: WebGLUtils,
  selection: boolean,
  drag: boolean,
  zoom: boolean,
  binNumber: boolean,
  dieImage: boolean,
  rowWiseFlip: boolean,
  colWiseFlip: boolean,
  tooltipText: any,
  controlsPosition: { x: number, y: number },
  markDieTypes: boolean,
};

export const COORDINATE_SYSTEMS = {
  STANDARD: 'Standard',
  RETICLE: 'Reticle Defined',
};

export default class WaferMap extends React.Component<WaferMapProps, WaferMapState> {
  static defaultProps = {
    onSelectionChanged: undefined,
    rotationAngle: 0,
    markZones: false,
    draggable: true,
    showRadar: true,
    showGridBordersAndTicks: true,
    showToolbar: true,
    showRadarOnly: false,
    showRotationNotch: false,
    enableDieImages: true,
    enableTooltip: false,
    waferTopOffset: 0,
    padding: 20,
    rowOffset: 0,
    colOffset: 0,
    ringRadius: 170,
    dieWidthToStreetWidthRatio: 1,
    dieHeightToStreetHeightRatio: 1,
    ringDiameterToWaferDiameterRatio: 1,
    waferHeightToRowsRatio: 1,
    waferWidthToColsRatio: 1,
    waferBGOffsetXDies: 0,
    waferBGOffsetYDies: 0,
    isWaferControlMap: false,
    isManualServiceWafer: false,
    showRing: false,
    overlayReticle: false,
    binTextFieldFlag: false,
    reticleSize: { x: 0, y: 0 },
    binTextField: 'softBin',
    zoomScaleFactor: 1,
    currentDieType: '',
    pinControlsToWaferTop: true,
    mainControl: MainControl.Drag,
    controlsWidgetEventKey: '0',
    dieTypes: [],
    zones: [],
    hasFlippingControls: false,
    hasRotationControls: false,
    markWaferCenter: true,
    notchPosition: NotchPosition.UP,
    reticleReference: 'CENTER',
    showReferenceReticle: false,
    dieTypeField: undefined,
    selectedDieField: undefined,
    wcmWaferDiameter: 0,
    wcmWaferEdgeExclusion: 0,
    wcmWaferNotchKeepOut: 0,
    wcmWaferFlatKeepOut: 0,
    wcmWaferBaseFlat: 0,
    wcmWaferScribeLine: 0,
    wcmExclusionType: ExclusionType.NONE,
    tooltipFields: defaultToolTipFields,
  };

  tooltipEL: Tooltip | null = null;

  canvasEl: HTMLCanvasElement | null = null;

  parentDiv: any = null;

  constructor(props: WaferMapProps) {
    super(props);
    const {
      waferHeightToRowsRatio, waferWidthToColsRatio, keyIndex, waferData, dieSize, waferSize, showRadar, waferTopOffset, padding, ringRadius, ringDiameterToWaferDiameterRatio, dieTypes,
      showGridBordersAndTicks, rotationAngle, showRadarOnly, showRotationNotch, rowOffset, colOffset, markZones, dieHeightToStreetHeightRatio, dieWidthToStreetWidthRatio, waferBGOffsetXDies,
      waferBGOffsetYDies, isWaferControlMap, showRing, overlayReticle, binTextFieldFlag, reticleSize, binTextField, zoomScaleFactor, currentDieType, pinControlsToWaferTop, markWaferCenter,
      reticleReference, showReferenceReticle, dieTypeField, isManualServiceWafer, selectedDieField, zones, wcmWaferDiameter, wcmWaferEdgeExclusion, wcmWaferNotchKeepOut, wcmWaferScribeLine,
      wcmWaferFlatKeepOut, wcmWaferBaseFlat, wcmExclusionType, tooltipFields,
    } = this.props;
    const data: WaferMapTestData = _.cloneDeep(waferData.waferMapTestData);
    const dieSubViewData: DieSubView = _.cloneDeep(waferData.dieSubView);

    const waferMapInstance = new WebGLUtils(new WaferMapVariablesClass(
      keyIndex,
      data,
      dieSize,
      dieSubViewData,
      showRadar,
      waferSize,
      showGridBordersAndTicks,
      rotationAngle,
      showRadarOnly,
      showRotationNotch,
      waferTopOffset,
      padding,
      rowOffset,
      colOffset,
      ringRadius,
      markZones,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      ringDiameterToWaferDiameterRatio,
      waferHeightToRowsRatio,
      waferWidthToColsRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      isWaferControlMap,
      showRing,
      overlayReticle,
      binTextFieldFlag,
      reticleSize,
      binTextField,
      zoomScaleFactor,
      currentDieType,
      dieTypes,
      markWaferCenter,
      reticleReference,
      showReferenceReticle,
      dieTypeField,
      isManualServiceWafer,
      selectedDieField,
      zones,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      wcmWaferNotchKeepOut,
      wcmWaferFlatKeepOut,
      wcmWaferBaseFlat,
      wcmWaferScribeLine,
      wcmExclusionType,
      tooltipFields,
    ));

    this.state = {
      waferMapInstance,
      selection: false,
      markDieTypes: false,
      drag: false,
      zoom: false,
      binNumber: false,
      dieImage: false,
      rowWiseFlip: false,
      colWiseFlip: false,
      tooltipText: '',
      controlsPosition: !pinControlsToWaferTop ? { x: waferMapInstance.waferMapVariables.tickViewPort.width, y: waferMapInstance.waferMapVariables.radarViewPort.height } : { x: 0, y: 0 },
    };
  }

  componentDidMount() {
    const {
      keyIndex, setWaferInstanceHandler, showToolbar, showRadar, enableTooltip,
      showGridBordersAndTicks, draggable, showRadarOnly, enableDieImages, getParentDivRef,
      takeScreenshot,
    } = this.props;
    const { waferMapInstance } = this.state;
    this.dataGenerator(waferMapInstance);
    if (showToolbar && !showRadarOnly && enableDieImages) this.loadDieImages(waferMapInstance);
    if (waferMapInstance.waferMapVariables.gl === null) { this.initializeContext(); }
    if (showRadar) radarPointer(waferMapInstance);
    if (showGridBordersAndTicks) setTickText(`tickText${keyIndex}`, waferMapInstance);
    if (enableTooltip) waferMapInstance.waferMapVariables.tooltipEL = this.tooltipEL;
    renderWaferMap(waferMapInstance);
    if (showToolbar && !showRadarOnly) setBinNumberCanvas(`binNumber${keyIndex}`, waferMapInstance);
    if (setWaferInstanceHandler) {
      setWaferInstanceHandler(waferMapInstance, keyIndex.toString());
    }
    if (enableTooltip) setTooltip(this.setTooltipContent, waferMapInstance);
    const ps = PublishSubscribe();
    if (draggable) {
      ps.subscribeToWaferID(EventTypes.WAFER_MAP_DRAGGED, waferMapInstance.dragWaferMap, keyIndex.toString(), keyIndex.toString());
      ps.subscribeToWaferID(EventTypes.WAFER_MAP_DRAG_STARTED, waferMapInstance.setPreDragAnchor, keyIndex.toString(), keyIndex.toString());
    }
    ps.subscribeToWaferID(EventTypes.WAFER_MAP_ZOOMED, waferMapInstance.zoomWaferMap, keyIndex.toString(), keyIndex.toString());
    ps.subscribeToWaferID(EventTypes.WAFER_MAP_ZOOMED_USING_BUTTONS, waferMapInstance.zoomWaferMapUsingButtons, keyIndex.toString(), keyIndex.toString());
    this.loadBgImage(waferMapInstance);
    if (takeScreenshot) this.generateScreenshot();
    ps.publishWithWaferID(EventTypes.WAFER_DATA_RESPONSE, { data: waferMapInstance.waferMapVariables.waferMapTestData, action: '' }, keyIndex.toString());
    if (getParentDivRef) getParentDivRef(this.parentDiv);
    selection(this.boxSelectionGridHandler, waferMapInstance);
    zoom(waferMapInstance);
    this.setInitialControl(); // state is updated in this
  }

  setInitialControl = () => {
    const { mainControl } = this.props;
    switch (mainControl) {
      case MainControl.Drag:
        this.dragChangeHandler(true);
        break;
      case MainControl.Zoom:
        this.zoomChangeHandler(true);
        break;
      case MainControl.Select:
        this.selectionChangeHandler(true, false);
        break;
      case MainControl.MarkDieType:
        this.selectionChangeHandler(true, true);
        break;
      default:
        this.dragChangeHandler(true);
        break;
    }
  };

  generateScreenshot = () => {
    const { takeScreenshot, keyIndex } = this.props;
    const { waferMapInstance } = this.state;
    const canvas : any = document.getElementById(`canvas${keyIndex}`);
    if (canvas) {
      waferMapInstance.resetWaferZoom();
      const dataURL = canvas.toDataURL('image/png', 0.0);
      if (takeScreenshot) takeScreenshot(dataURL);
    }
  };

  boxSelectionGridHandler = (
    selectedData: { row: number, col: number }[],
    unSelectedData: { row: number, col: number }[],
    markedData: { row: number, col: number }[],
  ) => {
    const { keyIndex, onSelectionChanged } = this.props;
    const { waferMapInstance } = this.state;
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.DIE_SELECTED_ON_WAFER, { selectedData }, keyIndex.toString());

    const data: (WaferMapData | null)[] = selectedData.map((dataEntry: { row: number, col: number }) => {
      if (waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]) {
        return waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col];
      }
      return null;
    });
    // eslint-disable-next-line no-underscore-dangle
    const unSelectedData_: (WaferMapData | null)[] = unSelectedData.map((dataEntry: { row: number, col: number }) => {
      if (waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]) {
        return waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col];
      }
      return null;
    });
    // eslint-disable-next-line no-underscore-dangle
    const markedData_: (WaferMapData | null)[] = markedData.map((dataEntry: { row: number, col: number }) => {
      if (waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]) {
        return waferMapInstance.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col];
      }
      return null;
    });

    if (onSelectionChanged !== undefined) {
      onSelectionChanged!(data, unSelectedData_, markedData_);
    }

    ps.publishWithWaferID(EventTypes.DATA_SELECTED_ON_WAFER, {
      selectedData: data,
      unSelectedData: unSelectedData_,
      markZones: waferMapInstance.waferMapVariables.markZones,
      markedData: markedData_,
    }, keyIndex.toString());
  };

  loadImage = (src: string, iInd: number, jInd: number) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = src;
      img.onload = () => {
        resolve({ img, iInd, jInd });
      };
      img.onerror = () => {
        reject(new Error('Image failed to load'));
      };
    });
  };

  loadBgImage = (waferMapInstance: WebGLUtils) => {
    const { notchPosition } = this.props;
    // eslint-disable-next-line no-param-reassign
    waferMapInstance.waferMapVariables.notchPosition = notchPosition!;
    Promise.all([
      this.loadImage(bgImageTop, 0, 0),
      this.loadImage(bgImageBottom, 0, 0),
      this.loadImage(bgImageLeft, 0, 0),
      this.loadImage(bgImageRight, 0, 0),
      this.loadImage(bgImageTopFlat, 0, 0),
      this.loadImage(bgImageBottomFlat, 0, 0),
      this.loadImage(bgImageLeftFlat, 0, 0),
      this.loadImage(bgImageRightFlat, 0, 0),
    ]).then((obj) => {
      const imgTop = obj[0] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgBottom = obj[1] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgLeft = obj[2] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgRight = obj[3] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgTopFlat = obj[4] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgBottomFlat = obj[5] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgLeftFlat = obj[6] as { img: HTMLImageElement, iInd: number, jInd: number };
      const imgRightFlat = obj[7] as { img: HTMLImageElement, iInd: number, jInd: number };
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageTop = imgTop.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageBottom = imgBottom.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageLeft = imgLeft.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageRight = imgRight.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageTopFlat = imgTopFlat.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageBottomFlat = imgBottomFlat.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageLeftFlat = imgLeftFlat.img;
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.waferBgImageRightFlat = imgRightFlat.img;
      waferMapInstance.resetWaferZoom();
    }).catch(() => {
      waferMapInstance.resetWaferZoom();
    });
  };

  loadDieImages = (waferMapInstance: WebGLUtils) => {
    const promises = [];
    for (let i = 0; i < waferMapInstance.waferMapVariables.waferMapTestData.length; i += 1) {
      waferMapInstance.waferMapVariables.dieImages.push([]);
      for (let j = 0; j < waferMapInstance.waferMapVariables.waferMapTestData[i].length; j += 1) {
        waferMapInstance.waferMapVariables.dieImages[i].push(null);
      }
    }
    for (let i = 0; i < waferMapInstance.waferMapVariables.waferMapTestData.length; i += 1) {
      for (let j = 0; j < waferMapInstance.waferMapVariables.waferMapTestData[i].length; j += 1) {
        if (waferMapInstance.waferMapVariables.waferMapTestData[i][j] && waferMapInstance.waferMapVariables.waferMapTestData[i][j]!.image) {
          promises.push(this.loadImage(waferMapInstance.waferMapVariables.waferMapTestData[i][j]!.image!, i, j).then((obj) => {
            const objj = obj as { img: HTMLImageElement, iInd: number, jInd: number };
            // eslint-disable-next-line no-param-reassign
            waferMapInstance.waferMapVariables.dieImages[objj.iInd][objj.jInd] = objj.img as HTMLImageElement;
          // eslint-disable-next-line no-unused-vars
          }));
        }
      }
    }
    Promise.allSettled(promises).then(() => {
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.isImagePromiseResolved = true;
      if (waferMapInstance.waferMapVariables.dieImageFlag) {
        // eslint-disable-next-line no-bitwise
        waferMapInstance.waferMapVariables.gl!.clear(waferMapInstance.waferMapVariables.gl!.DEPTH_BUFFER_BIT | waferMapInstance.waferMapVariables.gl!.COLOR_BUFFER_BIT);
        waferMapInstance.waferMapDataToGPU(
          waferMapInstance.waferMapVariables.waferMapTestData,
          waferMapInstance.waferMapVariables.scaledDieWidth,
          waferMapInstance.waferMapVariables.scaledDieHeight,
          {
            width: waferMapInstance.waferMapVariables.gl!.canvas.width,
            height: waferMapInstance.waferMapVariables.gl!.canvas.height,
          },
          waferMapInstance.waferMapVariables.XAnchor,
          waferMapInstance.waferMapVariables.YAnchor,
        );
        waferMapInstance.renderRadarView();
        waferMapInstance.prepareAndRenderRadarPointer();
        waferMapInstance.intiateRendering(
          waferMapInstance.waferMapVariables.gl!,
          waferMapInstance.waferMapVariables.XAnchor,
          waferMapInstance.waferMapVariables.YAnchor,
        );
      }
    });
  };

  dataGenerator = (waferMapInstance: WebGLUtils) => {
    const {
      rowFlip, rowAxisStart, rowAxisIncrement, rowAxisDirection, rowOffset, waferMapTestData,
      colFlip, colAxisStart, colAxisIncrement, colAxisDirection, colOffset, reticleSize,
    } = waferMapInstance.waferMapVariables;
    const { selectionCoords } = this.props;
    let num = 0;
    let isReticleInfoPresentInDies = false;
    // eslint-disable-next-line no-param-reassign
    waferMapInstance.waferMapVariables.reticleGridRectCoords = {};
    for (let i = 0; i < waferMapTestData.length; i += 1) {
      for (let j = 0; j < waferMapTestData[i].length; j += 1) {
        const dieData = waferMapTestData[i][j];
        if (dieData) {
          // to be deleted (don't use in any calculation): only to check the real index of data in 2d array
          dieData.rIndex = i;
          dieData.cIndex = j;
          // to be deleted (don't use in any calculation): only to check the real index of data in 2d array

          dieData.y = UtilityFunctions.getDieXOrY(
            rowFlip === RowFlip.Upright,
            rowAxisStart,
            rowAxisIncrement,
            rowAxisDirection === RowAxisDirection.TopToBottom,
            i,
            rowOffset,
            waferMapTestData.length,
          );
          dieData.x = UtilityFunctions.getDieXOrY(
            colFlip === ColFlip.Upright,
            colAxisStart,
            colAxisIncrement,
            colAxisDirection === ColAxisDirection.LeftToRight,
            j,
            colOffset,
            waferMapTestData[0].length,
          );

          // used for visual illusion displayed on the datagrid only. (Don't use in any calculation)

          // dataSource[dataSource.length - 1]!.row = dataSource[dataSource.length - 1]!.x! - waferMapInstance.waferMapVariables.rowOffset; // set x val of currently pushed object
          // dataSource[dataSource.length - 1]!.col = dataSource[dataSource.length - 1]!.y! - waferMapInstance.waferMapVariables.colOffset; // set y val of currently pushed object
          dieData.row = i;
          dieData.col = j;
          dieData.dieNumber = num;
          num += 1;
          if (selectionCoords && selectionCoords.find((coords) => coords.x === dieData.x && coords.y === dieData.y)) {
            dieData.selected = true;
          }

          if ('reticleX' in dieData && 'reticleY' in dieData) {
            isReticleInfoPresentInDies = true;
            const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, reticleSize);
            // eslint-disable-next-line no-param-reassign
            waferMapInstance.waferMapVariables.reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)] = {
              isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
            };
            if (dieData.referenceReticle) {
              // eslint-disable-next-line no-param-reassign
              waferMapInstance.waferMapVariables.referenceReticleGridRectCoords = _.cloneDeep(reticleRectCoordOfCurrDie);
            }
          }
        }
      }
    }
    if (isReticleInfoPresentInDies) {
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.reticleXAxisReference = waferMapInstance.getReticleAxisReference(waferMapInstance.waferMapVariables.reticleGridRectCoords, 'x');
      // eslint-disable-next-line no-param-reassign
      waferMapInstance.waferMapVariables.reticleYAxisReference = waferMapInstance.getReticleAxisReference(waferMapInstance.waferMapVariables.reticleGridRectCoords, 'y');
    }
  };

  initializeContext = () => {
    const { keyIndex } = this.props;
    const { waferMapInstance } = this.state;
    const canvas: HTMLCanvasElement = document.querySelector(`#canvas${keyIndex}`) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    canvas.height = waferMapInstance.waferMapVariables.viewPortHeight;
    canvas.width = waferMapInstance.waferMapVariables.viewPortWidth;
    waferMapInstance.waferMapVariables.gl = waferMapInstance.getGlContext(canvas);
  };

  selectionChangeHandler = (checked: boolean, shouldMarkDieTypes: boolean) => {
    const { waferMapInstance } = this.state;
    const { onChangeMainControl } = this.props;
    if (checked) {
      waferMapInstance.waferMapVariables.drag = false;
      waferMapInstance.waferMapVariables.select = true;
      waferMapInstance.waferMapVariables.zoom = false;
      // to ensure one radio button state is true at one time
      const newState: any = {
        drag: false,
        zoom: false,
      };
      let newMainControl = MainControl.Select;
      if (shouldMarkDieTypes) {
        waferMapInstance.waferMapVariables.markZones = true;
        newState.markDieTypes = true;
        newState.selection = false;
        newMainControl = MainControl.MarkDieType;
      } else {
        waferMapInstance.waferMapVariables.markZones = false;
        newState.selection = true;
        newState.markDieTypes = false;
        newMainControl = MainControl.Select;
      }
      this.setState(newState, () => {
        if (onChangeMainControl) onChangeMainControl(newMainControl);
      });
    }
  };

  dragChangeHandler = (checked: boolean) => {
    const { keyIndex, onChangeMainControl } = this.props;
    const { waferMapInstance } = this.state;
    if (checked) {
      document.getElementById(`canvas${keyIndex}`)!.style.pointerEvents = 'auto';
      waferMapInstance.waferMapVariables.drag = true;
      waferMapInstance.waferMapVariables.select = false;
      waferMapInstance.waferMapVariables.zoom = false;
      this.setState({
        drag: true,
        selection: false,
        markDieTypes: false,
        zoom: false,
      }, () => {
        if (onChangeMainControl) onChangeMainControl(MainControl.Drag);
      });
    }
  };

  zoomChangeHandler = (checked: boolean) => {
    const { keyIndex, onChangeMainControl } = this.props;
    const { waferMapInstance } = this.state;
    if (checked) {
      document.getElementById(`canvas${keyIndex}`)!.style.pointerEvents = 'auto';
      waferMapInstance.waferMapVariables.drag = false;
      waferMapInstance.waferMapVariables.select = false;
      waferMapInstance.waferMapVariables.zoom = true;
      this.setState({
        zoom: true,
        selection: false,
        markDieTypes: false,
        drag: false,
      }, () => {
        if (onChangeMainControl) onChangeMainControl(MainControl.Zoom);
      });
    }
  };

  binToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { waferMapInstance } = this.state;
    waferMapInstance.waferMapVariables.binTextFieldFlag = e.target.checked;
    if (e.target.checked) {
      waferMapInstance.waferMapVariables.binTextField = 'softBin';
      waferMapInstance.renderReticleAndBinText(waferMapInstance.waferMapVariables.binNumX, waferMapInstance.waferMapVariables.binNumY);
    } else {
      waferMapInstance.clearbinNumber();
    }
    this.setState({ binNumber: e.target.checked });
  };

  flipToggleHandler = (e: React.ChangeEvent<HTMLInputElement>, axis: FlipAxis) => {
    const { waferMapInstance } = this.state;
    if (axis === FlipAxis.RowWise) {
      if (e.target.checked) {
        waferMapInstance.waferMapVariables.rowFlip = RowFlip.Inverted;
      } else {
        waferMapInstance.waferMapVariables.rowFlip = RowFlip.Upright;
      }
      waferMapInstance.flipWaferMap(axis);
      // TODO: Will be used later when icons will be available
      // eslint-disable-next-line react/no-unused-state
      this.setState({ rowWiseFlip: e.target.checked });
    } else {
      if (e.target.checked) {
        waferMapInstance.waferMapVariables.colFlip = ColFlip.Inverted;
      } else {
        waferMapInstance.waferMapVariables.colFlip = ColFlip.Upright;
      }
      waferMapInstance.flipWaferMap(axis);
      // TODO: Will be used later when icons will be available
      // eslint-disable-next-line react/no-unused-state
      this.setState({ colWiseFlip: e.target.checked });
    }
  };

  resetWaferHandler = () => {
    const { waferMapInstance } = this.state;
    const { keyIndex } = this.props;
    waferMapInstance.resetWaferZoom();
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.WAFER_RESET, { }, keyIndex.toString());
  };

  clearSelectionHandler = () => {
    const { waferMapInstance } = this.state;
    // When selection is cleared change the opacity back to 100%
    // waferMapInstance.waferMapVariables.binColorOpacity = 1;
    waferMapInstance.clearSelection();
  };

  renderDieImageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { waferMapInstance } = this.state;
    waferMapInstance.waferMapVariables.dieImageFlag = e.target.checked;
    if (waferMapInstance.waferMapVariables.isImagePromiseResolved) {
      // eslint-disable-next-line no-bitwise
      waferMapInstance.waferMapVariables.gl!.clear(waferMapInstance.waferMapVariables.gl!.DEPTH_BUFFER_BIT | waferMapInstance.waferMapVariables.gl!.COLOR_BUFFER_BIT);
      waferMapInstance.waferMapDataToGPU(
        waferMapInstance.waferMapVariables.waferMapTestData,
        waferMapInstance.waferMapVariables.scaledDieWidth,
        waferMapInstance.waferMapVariables.scaledDieHeight,
        {
          width: waferMapInstance.waferMapVariables.gl!.canvas.width,
          height: waferMapInstance.waferMapVariables.gl!.canvas.height,
        },
        waferMapInstance.waferMapVariables.XAnchor,
        waferMapInstance.waferMapVariables.YAnchor,
      );
      waferMapInstance.renderRadarView();
      waferMapInstance.prepareAndRenderRadarPointer();
      waferMapInstance.intiateRendering(
        waferMapInstance.waferMapVariables.gl!,
        waferMapInstance.waferMapVariables.XAnchor,
        waferMapInstance.waferMapVariables.YAnchor,
      );
    }
    this.setState({ dieImage: e.target.checked });
  };

  zoomWaferMapUsingButtons = (zoomFactor: number) => {
    const { keyIndex } = this.props;
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.WAFER_MAP_ZOOMED_USING_BUTTONS, { zoomFactor }, keyIndex.toString());
  };

  setTooltipContent = (die: WaferMapData) => {
    if (die !== null) {
      const { waferMapInstance } = this.state;
      const content = (
        <div>
          {
            waferMapInstance.waferMapVariables.tooltipFields.map((item: string[]) => {
              return (
                <div key={item[0]}>
                  <strong>
                    {item}
                    :
                  </strong>
                  {' '}
                  {waferMapInstance.getTextFromDieField(die, item[0])}
                </div>
              );
            })
          }
        </div>
      );
      this.setState({ tooltipText: content });
    }
  };

  render() {
    const {
      keyIndex, showToolbar, showRadarOnly, enableDieImages, enableTooltip, contextMenuDataSource, onChangeWidgetAccordian, controlsWidgetEventKey,
      onContextMenuItemClick, additionalControls, markZones, onToggleWaferControlsPosition, pinControlsToWaferTop, hasFlippingControls, hasRotationControls,
    } = this.props;
    const {
      waferMapInstance,
      selection: selectionState,
      drag,
      binNumber,
      dieImage,
      zoom: zoomState,
      rowWiseFlip,
      colWiseFlip,
      tooltipText,
      controlsPosition,
      markDieTypes,
    } = this.state;

    let cursor = 'default';
    if (selectionState || zoomState || markDieTypes) {
      cursor = 'crosshair';
    } else if (drag) {
      cursor = 'default';
    }

    let markingText = 'Mark Die Types';
    if (waferMapInstance.waferMapVariables.markingMode === MarkingMode.WAT_PCM_SITE_NUMBER) {
      markingText = 'Mark WAT / PCM Sites';
    } else if (waferMapInstance.waferMapVariables.markingMode === MarkingMode.ZONE_SITE) {
      markingText = 'Mark Zone';
    }

    const localContextMenuDataSource = [{ text: pinControlsToWaferTop ? 'Float Wafer Controls' : 'Pin Wafer Controls', action: 'pinWaferControls' }];
    const controlsContent = showToolbar && !showRadarOnly
      ? (
        <div>
          <div
            className={`wafer-actions d-flex align-items-center justify-content-between ${!pinControlsToWaferTop ? 'grid-container' : ''}`}
            style={{
              width: waferMapInstance.waferMapVariables.tickViewPort.width,
              paddingBottom: '0px',
              marginLeft: pinControlsToWaferTop ? waferMapInstance.waferMapVariables.tickViewPort.x : '0px',
              zIndex: 100,
              position: 'relative',
              height: pinControlsToWaferTop ? '100%' : '150px',
            }}
          >
            <TooltipWrapper text="Selection" id="wafer-action-selection">
              <label className="wafer-action" htmlFor={`selectionRadioButton${keyIndex}`}>
                <input
                  className="mr10 "
                  type="radio"
                  id={`selectionRadioButton${keyIndex}`}
                  onChange={(e: any) => { this.selectionChangeHandler(e.target.checked, false); }}
                  checked={selectionState}
                />
                <svg
                  viewBox="0 0 1000 1000"
                  className="icon"
                  height="1em"
                  width="1em"
                >
                  <path
                    d="m0 850l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0
                143-143 0z m286 0l0-143 143 0 0 143-143 0z m-857-286l0-143 143 0 0 143-143 0z m857 0l0-143
                143 0 0 143-143 0z m-857-285l0-143 143 0 0 143-143 0z m857 0l0-143 143 0 0 143-143 0z m-857-286l0-143
                143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z"
                    transform="matrix(1 0 0 -1 0 850)"
                  />
                </svg>
              </label>
            </TooltipWrapper>
            <TooltipWrapper text="Clear Selection" id="wafer-action-clear-selection">
              <div className="wafer-action ">
                <FontAwesomeIcon
                  role="button"
                  icon={faUndo}
                  cursor="pointer"
                  onClick={this.clearSelectionHandler}
                />
              </div>
            </TooltipWrapper>
            {markZones && (
              <TooltipWrapper text={markingText} id="wafer-action-mark-die-types">
                <label className="wafer-action" htmlFor={`markDieTypes${keyIndex}`}>
                  <input
                    className="mr10 "
                    type="radio"
                    id={`markDieTypes${keyIndex}`}
                    onChange={(e: any) => { this.selectionChangeHandler(e.target.checked, true); }}
                    checked={markDieTypes}
                  />
                  <FontAwesomeIcon
                    icon={faMarker}
                    cursor="pointer"
                  />
                </label>
              </TooltipWrapper>
            )}
            <TooltipWrapper text="Drag" id="wafer-action-drag">
              <label className="wafer-action" htmlFor={`draggingRadioButton${keyIndex}`}>
                <input
                  className="mr10 "
                  type="radio"
                  id={`draggingRadioButton${keyIndex}`}
                  checked={drag}
                  onChange={(e: any) => { this.dragChangeHandler(e.target.checked); }}
                />
                <i className="yw-icons-pan" />
              </label>
            </TooltipWrapper>
            <TooltipWrapper text="View Bin Text" id="wafer-action-bin-text">
              <label className="wafer-action" htmlFor={`binNumberToggle${keyIndex}`}>
                <input
                  className="mr10 wafer-checkbox"
                  type="checkbox"
                  id={`binNumberToggle${keyIndex}`}
                  checked={binNumber}
                  onChange={this.binToggleHandler}
                />
                <i className="yw-icons-dice" />
              </label>
            </TooltipWrapper>
            { hasFlippingControls && (
              <>
                <TooltipWrapper text="Row-wise flip" id="wafer-action-row-wise-flip">
                  <label className="wafer-action " htmlFor={`rowWiseFlipToggle${keyIndex}`}>
                    <input
                      className="mr10 wafer-checkbox"
                      type="checkbox"
                      id={`rowWiseFlipToggle${keyIndex}`}
                      checked={rowWiseFlip}
                      onChange={(event) => this.flipToggleHandler(event, FlipAxis.RowWise)}
                    />
                    <i className="yw-icons-chevron-up" />
                  </label>
                </TooltipWrapper>
                <TooltipWrapper text="Col-wise flip" id="wafer-action-col-wise-flip">
                  <label className="wafer-action " htmlFor={`colWiseFlipToggle${keyIndex}`}>
                    <input
                      className="mr10 wafer-checkbox"
                      type="checkbox"
                      id={`colWiseFlipToggle${keyIndex}`}
                      checked={colWiseFlip}
                      onChange={(event) => this.flipToggleHandler(event, FlipAxis.ColWise)}
                    />
                    <i className="yw-icons-chevron-left" />
                  </label>
                </TooltipWrapper>
              </>
            )}
            { enableDieImages ? (
              <TooltipWrapper text="View Bin Images" id="wafer-action-bin-images">
                <label className="wafer-action " htmlFor={`dieImageToggle${keyIndex}`}>
                  <input
                    className="mr10 wafer-checkbox"
                    type="checkbox"
                    id={`dieImageToggle${keyIndex}`}
                    checked={dieImage}
                    onChange={this.renderDieImageHandler}
                  />
                  <i className="yw-icons-plot-heatmap" />
                </label>
              </TooltipWrapper>
            ) : null }

            <TooltipWrapper text="Box Zoom" id="wafer-action-box-zoom">
              <label className="wafer-action" htmlFor={`zoomingRadioButton${keyIndex}`}>
                <input
                  className="mr10 "
                  type="radio"
                  id={`zoomingRadioButton${keyIndex}`}
                  checked={zoomState}
                  onChange={(e: any) => { this.zoomChangeHandler(e.target.checked); }}
                />
                <i className="yw-icons-zoombox" />
              </label>
            </TooltipWrapper>
            <TooltipWrapper text="Zoom In" id="wafer-action-zoom-in">
              <div className="wafer-action ">
                <i
                  role="button"
                  className="yw-icons-zoom-plus mt-0"
                  style={{ cursor: 'pointer' }}
                  id={`zoomPlus${keyIndex}`}
                  aria-label="zoom-in"
                  aria-hidden
                  onClick={() => this.zoomWaferMapUsingButtons(1.1)}
                />
              </div>
            </TooltipWrapper>
            <TooltipWrapper text="Zoom Out" id="wafer-action-zoom-out">
              <div className="wafer-action ">
                <i
                  role="button"
                  aria-label="zoom-out"
                  aria-hidden
                  className="yw-icons-zoom-minus"
                  style={{ cursor: 'pointer' }}
                  id={`zoomMinus${keyIndex}`}
                  onClick={() => this.zoomWaferMapUsingButtons(0.9)}
                />
              </div>
            </TooltipWrapper>
            <TooltipWrapper text="Reset" id="wafer-action-reset">
              <div className="wafer-action ">
                <i
                  role="button"
                  aria-label="reset"
                  aria-hidden
                  className="yw-icons-home"
                  style={{ cursor: 'pointer' }}
                  id={`zoomReset${keyIndex}`}
                  onClick={this.resetWaferHandler}
                />
              </div>
            </TooltipWrapper>
            { hasRotationControls && (
              <>
                <TooltipWrapper text="Rotate anti-clockwise" id="wafer-action-anti-clockwise">
                  <div className="wafer-action ">
                    <i
                      role="button"
                      className="yw-icons-rotate-ccw mt-0"
                      style={{ cursor: 'pointer' }}
                      id={`rotateAntiClockwise${keyIndex}`}
                      aria-label="rotateAntiClockwise"
                      aria-hidden
                      onClick={() => waferMapInstance.rotateWaferMap(RotateDirection.AntiClockWise)}
                    />
                  </div>
                </TooltipWrapper>
                <TooltipWrapper text="Rotate clockwise" id="wafer-action-clockwise">
                  <div className="wafer-action ">
                    <i
                      role="button"
                      aria-label="rotateClockwise"
                      aria-hidden
                      className="yw-icons-rotate-cw"
                      style={{ cursor: 'pointer' }}
                      id={`rotateClockwise${keyIndex}`}
                      onClick={() => waferMapInstance.rotateWaferMap(RotateDirection.ClockWise)}
                    />
                  </div>
                </TooltipWrapper>
              </>
            ) }
          </div>
          { !pinControlsToWaferTop && (
            <>
              <div>
                <div className="text-center font-weight-bold mt10">Bin Text Field</div>
                <CustomizedDropdown
                  containerClassName="small"
                  variant="clear"
                  full
                  list={waferMapInstance.waferMapVariables.binTextFieldOptions}
                  selectedValue={waferMapInstance.waferMapVariables.binTextField}
                  onChange={(value: any) => { waferMapInstance.WCMOnChangeWaferMapVariables({ waferData: { binTextField: value, binTextFieldFlag: true }, shouldReRender: true }); }}
                />
              </div>
              <div>
                <div className="text-center font-weight-bold mt10">Coordinate System</div>
                <CheckBox
                  className="mt10 mr20"
                  value={waferMapInstance.waferMapVariables.isDieCoordinatesSystemEnabled}
                  onValueChange={(value: boolean) => {
                    waferMapInstance.WCMOnChangeWaferMapVariables({ waferData: { isDieCoordinatesSystemEnabled: value }, shouldReRender: true });
                    this.forceUpdate();
                  }}
                />
                {' '}
                Standard
                <br />
                <CheckBox
                  className="mt10 mr20"
                  value={waferMapInstance.waferMapVariables.isReticleCoordinatesSystemEnabled}
                  onValueChange={(value: boolean) => {
                    if (!waferMapInstance.waferMapVariables.overlayReticle && value) {
                      toast.warn('Reticle coordinates cannot be enabled without overlaying the reticle');
                    } else {
                      waferMapInstance.WCMOnChangeWaferMapVariables({ waferData: { isReticleCoordinatesSystemEnabled: value }, shouldReRender: true });
                      this.forceUpdate();
                    }
                  }}
                />
                {' '}
                Reticle Defined
              </div>
            </>
          )}

        </div>
      )
      : null;

    let draggableContent = controlsContent;

    if (!pinControlsToWaferTop) {
      draggableContent = (
        <div style={{
          zIndex: 2,
          position: 'relative',
          height: pinControlsToWaferTop ? '100%' : '150px',
          width: '160px',
        }}
        >
          <div
            style={{
              height: '20px', backgroundColor: '#ccc', cursor: 'move', textAlign: 'center',
            }}
            className="waferControlDragHandle"
          />
          <Accordion
            activeKey={controlsWidgetEventKey}
          >
            <Card>
              <CustomToggle eventKey="0" setCallback={(eventKey: string) => { if (onChangeWidgetAccordian) onChangeWidgetAccordian(eventKey); }}>
                <FontAwesomeIcon
                  icon={faTools}
                  className="mr5"
                />
                Main Controls
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {controlsContent}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            { additionalControls }
          </Accordion>
        </div>
      );
    }

    const controls = controlsContent !== null ? (
      <Draggable
        handle=".waferControlDragHandle"
        position={controlsPosition}
        axis={pinControlsToWaferTop ? 'none' : 'both'}
        onDrag={(event: any, data: any) => {
          if (!pinControlsToWaferTop) {
            this.setState({
              controlsPosition: {
                x: data.x,
                y: data.y,
              },
            });
          }
        }}
      >
        {draggableContent}
      </Draggable>
    ) : null;

    return (
      <div ref={(el: any) => { this.parentDiv = el; }}>
        {!pinControlsToWaferTop ? controls : null}
        <div
          className="wafer-widget"
          style={{
            margin: '0 auto', width: `${waferMapInstance.waferMapVariables.viewPortWidth}px`, position: 'relative', top: `${pinControlsToWaferTop ? '0' : '-150px'}`,
          }}
        >
          {pinControlsToWaferTop ? controls : null}
          <div style={{ display: 'flex', alignItems: 'top', position: 'relative' }}>
            <div className="wafer-container wafer-container">
              <canvas
                ref={(canvasEl) => { this.canvasEl = canvasEl; }}
                className="canvas"
                id={`canvas${keyIndex}`}
                style={{
                  cursor,
                }}
              />
              {
                enableTooltip
                  ? (
                    <Tooltip
                      ref={(tooltipEL) => { this.tooltipEL = tooltipEL; }}
                      target={`#canvas${keyIndex}`}
                      closeOnOutsideClick={false}
                      position={{
                        of: `#canvas${keyIndex}`,
                        at: 'left bottom',
                      }}
                    >
                      <div>{tooltipText}</div>
                    </Tooltip>
                  ) : null
              }
              <canvas
                className="binNumber"
                id={`binNumber${keyIndex}`}
                style={{
                  top: waferMapInstance.waferMapVariables.viewPortYDiff,
                  left: waferMapInstance.waferMapVariables.tickViewPort.x + waferMapInstance.waferMapVariables.viewPortXDiff,
                }}
              />
              <canvas
                className="tickText"
                id={`tickText${keyIndex}`}
                style={{
                  left: waferMapInstance.waferMapVariables.tickViewPort.x,
                }}
              />
              {contextMenuDataSource
                ? (
                  <ContextMenu
                    target={`#canvas${keyIndex}`}
                    onShowing={() => { waferMapInstance.waferMapVariables.isContextMenuOpen = true; }}
                    onHiding={() => { waferMapInstance.waferMapVariables.isContextMenuOpen = false; }}
                    width={200}
                    onItemClick={(event: any) => {
                      event.component.hide();
                      if (event.itemData.action === 'pinWaferControls') {
                        if (pinControlsToWaferTop) {
                          this.setState({
                            controlsPosition: { x: waferMapInstance.waferMapVariables.tickViewPort.width, y: waferMapInstance.waferMapVariables.radarViewPort.height },
                          });
                        } else {
                          this.setState({
                            controlsPosition: {
                              x: 0,
                              y: 0,
                            },
                          });
                        }
                        if (onToggleWaferControlsPosition) {
                          // eslint-disable-next-line react/destructuring-assignment
                          onToggleWaferControlsPosition(!pinControlsToWaferTop);
                        }
                      } else if (onContextMenuItemClick) onContextMenuItemClick(event);
                    }}
                    dataSource={(contextMenuDataSource as Array<dxContextMenuItem>).concat(localContextMenuDataSource)}
                  />
                ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
