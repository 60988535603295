import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, } from 'react-bootstrap';
import { faGripVertical, faPlusCircle, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { SortableHandle } from 'react-sortable-hoc';

import Hint from 'components/wrapped-component/hint-controls/Hint/Hint';
import arrowImg from '../../../assets/images/arrow-size-1.png';

import Heading from '../../wrapped-component/hint-controls/Heading';
import Button from '../../wrapped-component/hint-controls/Button';
import Textbox from '../../wrapped-component/hint-controls/Textbox';

import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import CustomizedDataGrid from '../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import {
  ServiceTypes, IPolicyStep, ServiceType, LoaderSteps, LoaderStepType, IPATRuleSet,
} from '../../../interfaces';
import SWMPolicyStepSettings from './policy-step-settings/SWMPolicyStepSettings';
import PATPolicyStepSettings from './policy-step-settings/PATPolicyStepSettings';
import { httpService } from '../../../services/http.service';
import LoaderLoadPolicyStepSettings from './policy-step-settings/LoaderLoadPolicyStepSettings';
import LoaderConverterPolicyStepSettings from './policy-step-settings/LoaderConverterPolicyStepSettings';
import LoaderCopyPolicyStepSettings from './policy-step-settings/LoaderCopyPolicyStepSettings';
import LoaderEnrichmentPolicyStepSettings from './policy-step-settings/LoaderEnrichmentPolicyStepSettings';
import AMGPolicyStepSettings from './policy-step-settings/AMGPolicyStepSettings';
import SPCPolicyStepSettings from './policy-step-settings/SPCPolicyStepSettings';

interface IPolicyStepProps {
  searchCriteria?: any;
  step: IPolicyStep;
  title: string;
  addStep: (type: string, addIndex: number) => void;
  deleteStep: (index: number) => void;
  addRule?: (sequence: number, ruleType: string, updateFocus: any, focus: boolean, id: string) => void;
  attachSavedPATRuleSetToStep?: (step: IPolicyStep) => void;
  updateStepSettings: (step: IPolicyStep) => void;
  updateStepExpressions: (step: IPolicyStep) => void;
  updateRules: (step: IPolicyStep, rules: []) => void;
  policySteps: IPolicyStep[];
}

class PolicyStep extends React.Component<IPolicyStepProps, any> {
  private stepSettings: any;

  constructor(props: IPolicyStepProps) {
    super(props);
    this.getRules = this.getRules.bind(this);
    this.toggleShowSettings = this.toggleShowSettings.bind(this);
    this.toggleShowRulesPanel = this.toggleShowRulesPanel.bind(this);
    this.updatePolicyStepSettings = this.updatePolicyStepSettings.bind(this);
    this.updateNotifyOwner = this.updateNotifyOwner.bind(this);
    this.updateFocus = this.updateFocus.bind(this);
    this.getRowData = this.getRowData.bind(this);
    this.clearEditPanel = this.clearEditPanel.bind(this);
    const { step } = this.props;

    this.state = {
      newlyAdded: true,
      showSettings: step.isOpen,
      showRulesPanel: step.isOpen && step.Type === 'PAT',
      stepOwner: step.stepOwner,
      additionalEmail: step.additionalEmail,

      notifyOwner: step.IsNotificationEnabled,
      rules: [],
      editPanelRight: <></>,
      focus: false,
    };

    this.stepSettings = React.createRef();
  }

  componentDidMount() {
    const { newlyAdded } = this.state;
    if (newlyAdded) {
      setTimeout(() => {
        this.setState({ newlyAdded: false });
      }, 1200);
    }
  }

  getRules = async () => {
    const { step } = this.props;
    const { showRulesPanel } = this.state;
    if (showRulesPanel === false) {
      if (step.Rules.length > 0) {
        httpService.getMultipleRules(step.Rules, step.Type)
          .then((rules: any) => {
            this.setState({ rules });
          });
      }
    } else {
      const rules: any = [];
      this.setState({ rules });
    }
  };

  getRowData = (e: any) => {
    const { focus } = this.state;
    const {
      step,
      addRule,
    } = this.props;
    this.updateFocus(!focus);
    addRule!(step.Sequence, step.Type, this.updateFocus, !focus, e.id);
  };

  clearEditPanel = () => {
    this.setState({ editPanelRight: null });
    this.getRules();
  };

  updateFocus = (focus: boolean) => {
    this.setState({ focus });
  };

  toggleShowRulesPanel = () => {
    const { showRulesPanel } = this.state;
    const { step } = this.props;
    this.getRules();

    this.setState({ showRulesPanel: !showRulesPanel });
    step.isOpen = !step.isOpen;
  };

  toggleShowSettings = () => {
    const {
      showRulesPanel,
      showSettings,
    } = this.state;
    const { step } = this.props;
    this.getRules();
    if (showRulesPanel === true) {
      this.toggleShowRulesPanel();
    }

    this.setState({ showSettings: !showSettings });
    step.isOpen = !step.isOpen;
  };

  updatePolicyStepSettings = (stepSettings: any) => {
    const {
      step,
      updateStepSettings,
    } = this.props;
    step.StepSettings = stepSettings;
    updateStepSettings(step);
  };

  updatePolicyStepExpressions = (stepExpressions: any) => {
    const {
      step,
      updateStepExpressions,
    } = this.props;
    step.expressions = stepExpressions;
    updateStepExpressions(step);
  };

  updatePATRuleSetSettings = (patRuleSetObj: any) => {
    const {
      step,
      attachSavedPATRuleSetToStep,
    } = this.props;
    const {
      ruleId,
      patRuleSetSequence,
      patRuleSetName,
      patRuleSetType,
    } = patRuleSetObj;

    if (step.StepSettings.patRuleSet) {
      if (step.StepSettings.patRuleSet[patRuleSetSequence]) {
        step.StepSettings.patRuleSet[patRuleSetSequence].rules.push(ruleId);
        step.StepSettings.patRuleSet[patRuleSetSequence].patRuleSetType = patRuleSetType;
        step.StepSettings.patRuleSet[patRuleSetSequence].patRuleSetName = patRuleSetName;
        step.StepSettings.patRuleSet[patRuleSetSequence].patRuleSetSequence = patRuleSetSequence;
      } else {
        const newPATRuleSetObj: IPATRuleSet = {
          rules: [ruleId],
          patRuleSetName,
          patRuleSetType,
          patRuleSetSequence,
        };
        step.StepSettings.patRuleSet.push(newPATRuleSetObj);
      }
    }

    if (attachSavedPATRuleSetToStep) attachSavedPATRuleSetToStep(step);
  };

  updateStepOwner = (stepOwner: string) => {
    const {
      step,
      updateStepSettings,
    } = this.props;
    step.stepOwner = stepOwner;
    this.setState({ stepOwner });
    updateStepSettings(step);
  };

  updateAdditionalEmail = (additionalEmail: string) => {
    const {
      step,
      updateStepSettings,
    } = this.props;
    step.additionalEmail = additionalEmail;
    this.setState({ additionalEmail });
    updateStepSettings(step);
  };

  updateNotifyOwner = (notifyOwner: boolean) => {
    this.setState({ notifyOwner });
  };

  updateRuleSetName = (value: string) => {
    const {
      step,
      updateStepSettings,
    } = this.props;
    if (step.StepSettings.patRuleSet) {
      if (step.StepSettings.patRuleSet[0]) {
        step.StepSettings.patRuleSet[0].patRuleSetName = value;
        updateStepSettings(step);
      }
    }
  };

  render() {
    const {
      newlyAdded,
      rules,
      editPanelRight,
      notifyOwner,
      showSettings,
      showRulesPanel,
      focus,
      stepOwner,
      additionalEmail,
    } = this.state;

    const {
      step,
      title,
      policySteps,
      addStep,
      deleteStep,
      addRule,
      updateStepSettings,
      updateRules,
      searchCriteria,
    } = this.props;

    const DragHandle = SortableHandle(() => (
      <div className="w30">
        <FontAwesomeIcon
          className="icon dragable-icon cursor-row-grab mt6"
          size="sm"
          icon={faGripVertical}
        />
      </div>
    ));
    const updatedCreated = (
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between mt30">
            <div>
              <span className="color-dark-three mr7 mt-10">
                Updated By
              </span>
              <span className="color-dark-three mt-10 mr7">
                <u>Ken Edwards</u>
              </span>
              <span className="color-dark-three mr7 mt-10">on</span>
              <span className="color-dark-three mt-10">
                <u>26th Jul 2020</u>
              </span>
            </div>
            <div>
              <span className="color-dark-three mr7 mt-10">
                Created By
              </span>
              <span className="color-dark-three mt-10 mr7">
                <u>Matt Anderson</u>
              </span>
              <span className="color-dark-three mr7 mt-10">on</span>
              <span className="color-dark-three mt-10">
                <u>26th Jul 2020</u>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    );

    const swmSettings = (
      <SWMPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatePolicyStepExpressions={this.updatePolicyStepExpressions}
        updatedCreated={updatedCreated}
        rulesCount={step.Rules.length}
        showRulesPanel={showRulesPanel}
        toggleShowRulesPanel={this.toggleShowRulesPanel}
        stepSettings={step.StepSettings}
        policySteps={policySteps.slice(0, policySteps.indexOf(step))}
      />
    );
    const amgSettings = (
      <AMGPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatePolicyStepExpressions={this.updatePolicyStepExpressions}
        updatedCreated={updatedCreated}
        stepSettings={step.StepSettings}
        expressions={step.expressions}
      />
    );

    const spcSettings = (
      <SPCPolicyStepSettings
        searchCriteria={searchCriteria}
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatePolicyStepExpressions={this.updatePolicyStepExpressions}
        stepSettings={step.StepSettings}
        expressions={step.expressions}
      />
    );
    const patSettings = (
      <PATPolicyStepSettings
        searchCriteria={searchCriteria}
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatePolicyStepExpressions={this.updatePolicyStepExpressions}
        updatePATRuleSetSettings={this.updatePATRuleSetSettings}
        updateNotifyOwner={this.updateNotifyOwner}
        updateStepOwner={this.updateStepOwner}
        updateAdditionalEmail={this.updateAdditionalEmail}
        toggleShowRulesPanel={this.toggleShowRulesPanel}
        updateRuleSetName={this.updateRuleSetName}
        updatedCreated={updatedCreated}
        showRulesPanel={showRulesPanel}
        notifyOwner={notifyOwner}
        stepOwner={stepOwner}
        additionalEmail={additionalEmail}
        rulesCount={step.Rules.length}
        stepSettings={step.StepSettings}
        expressions={step.expressions}
        patRuleSetSettings={step.StepSettings.patRuleSet}
      />
    );
    const loaderCopySettings = (
      <LoaderCopyPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatedCreated={updatedCreated}
        stepSettings={step.StepSettings}
        policySteps={policySteps.slice(0, policySteps.indexOf(step))}
      />
    );
    const loaderConverterSettings = (
      <LoaderConverterPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatedCreated={updatedCreated}
        stepSettings={step.StepSettings}
        policySteps={policySteps.slice(0, policySteps.indexOf(step))}
      />
    );
    const loaderEnrichmentSettings = (
      <LoaderEnrichmentPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatedCreated={updatedCreated}
        stepSettings={step.StepSettings}
        policySteps={policySteps.slice(0, policySteps.indexOf(step))}
      />
    );
    const loaderSaveSettings = (
      <LoaderLoadPolicyStepSettings
        updatePolicyStepSettings={this.updatePolicyStepSettings}
        updatedCreated={updatedCreated}
        stepSettings={step.StepSettings}
        policySteps={policySteps.slice(0, policySteps.indexOf(step))}
      />
    );
    const swmRulesGrid = (
      <CustomizedDataGrid
        showFilterRow
        selectionMode="multiple"
        allowRowReorder
        fields={[
          {
            caption: 'Name',
            dataField: 'name',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Description',
            dataField: 'description',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Short Description',
            dataField: 'shortDescription',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Version',
            dataField: 'version',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Created By',
            dataField: 'createdBy.firstName',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Created On',
            dataField: 'createdOn',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Updated By',
            dataField: 'updatedBy.firstName',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Updated On',
            dataField: 'updatedOn',
            dataType: 'string',
            showInfo: false,
          },
        ]}
        tableData={rules}
        onRowReorder={(e: any) => updateRules(step, e.map((x: any) => x.id))}
      />
    );

    const patRulesGrid = (
      <CustomizedDataGrid
        showFilterRow
        selectionMode="multiple"
        allowRowReorder
        fields={[
          {
            caption: 'Name',
            dataField: 'name',
            dataType: 'string',
            showInfo: false,
            viewMode: 'anchor',
            onClick: this.getRowData,
          },
          {
            caption: 'Type',
            dataField: 'type',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Description',
            dataField: 'description',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Short Description',
            dataField: 'shortDescription',
            dataType: 'string',
            showInfo: false,
          },

          {
            caption: 'Test Param Count',
            dataField: 'testParameters.length',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Source Bin Number',
            dataField: 'sourceBin.binNumber',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Source Bin Type',
            dataField: 'sourceBin.binType',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Target Bin Number',
            dataField: 'targetBin.binNumber',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Target Bin Type',
            dataField: 'targetBin.binType',
            dataType: 'string',
            showInfo: false,
          },
          {
            caption: 'Version',
            dataField: 'version',
            dataType: 'string',
            showInfo: false,
          },
        ]}
        // moreOptions={[
        //   { title: 'Edit Rule', clickAction: this.getRowData },
        // ]}
        tableData={rules}
        onRowReorder={(e: any) => updateRules(step, e.map((x: any) => x.id))}
      />
    );

    const rulesPanel = (
      <div className="rules-panel indent background-color-secondary-background">
        <div className="pt20 pl5 d-flex justify-content-between">
          <Heading size={5}>
            {title}
            {' '}
            Rules
          </Heading>
        </div>
        {step.Type === ServiceType.PAT && patRulesGrid}
        {step.Type === ServiceType.SWM && swmRulesGrid}
      </div>
    );
    return (
      <Row>
        <Col lg={12}>
          <div
            className="mb20"
          >
            <div className="d-flex align-items-center mx-2 mx-md-4">
              <div>
                <DragHandle />
              </div>
              <span className="w20 overflow-hidden">
                {step.Sequence + 1}
                .
              </span>
              <div className={`p0 block step-block step-block-width ${newlyAdded ? 'new' : ''} ${focus ? 'selected-rule' : ''}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="step-name p0 border-none text-center">
                      {title}
                    </div>
                    {step.Rules.length > 0 && (
                      <div className="step-rules-count p10 border-none text-center mr12">
                        {step.Rules.length}
                        {' '}
                        Rule
                        {step.Rules.length > 1 ? 's' : ''}
                        <Hint
                          className="mt4"
                          hint={{
                            message: 'Rules will be attached with Policy once the Policy is saved',
                          }}
                          variant="icon"
                        />
                      </div>
                    )}
                    <Textbox
                      autoComplete="off"
                      className="policy-block-editable mt2 version w130 mr10 h30 "
                      name="version"
                      placeholder="Output Reference"
                      defaultValue={step.OutputReference}
                      onChange={
                        (e: any) => {
                          step.OutputReference = e.target.value;
                          updateStepSettings(step);
                        }
                      }
                    />
                  </div>
                  <div className={`${focus ? 'hide-rule-view' : ''}`}>
                    {step.Type !== LoaderStepType.Load
                      && step.Type !== LoaderStepType.Copy && step.Type !== LoaderStepType.Converter
                      && step.Type !== LoaderStepType.Enrichment
                      && step.Type !== ServiceType.SPC
                      && step.Type !== ServiceType.PAT
                      && step.Type !== ServiceType.AMG
                      && (
                        <Button
                          variant="clear color-secondary color-primary-hover break-right"
                          visible={false}
                          onClick={() => {
                            this.updateFocus(!focus);
                            addRule!(step.Sequence, step.Type, this.updateFocus, !focus, '');
                          }}
                        >
                          <FontAwesomeIcon
                            className="mr10 mt2 cursor-pointer"
                            size="sm"
                            icon={faPlusCircle}
                          />
                          Create Rule
                        </Button>
                      )}
                    <Button
                      variant="clear color-secondary color-primary-hover"
                      onClick={this.toggleShowSettings}
                    >
                      {showSettings ? '↑ Collapse' : '↓ View'}
                    </Button>
                    <Button
                      type="button"
                      variant="clear"
                      onClick={() => {
                        deleteStep(step.Sequence);
                      }}
                    >

                      <FontAwesomeIcon
                        className="icon mr10 mt2 cursor-pointer delete-icon"
                        size="lg"
                        icon={faTrash}
                      />
                    </Button>
                  </div>
                </div>
                {showSettings && step.Type === ServiceType.PAT && patSettings}
                {showSettings && step.Type === ServiceType.SWM && swmSettings}
                {showSettings && step.Type === ServiceType.AMG && amgSettings}
                {showSettings && step.Type === LoaderStepType.Load && loaderSaveSettings}
                {showSettings && step.Type === LoaderStepType.Converter && loaderConverterSettings}
                {showSettings && step.Type === LoaderStepType.Copy && loaderCopySettings}
                {showSettings && step.Type === LoaderStepType.Enrichment && loaderEnrichmentSettings}
                {/* {showRulesPanel && rulesPanel} */}
                {editPanelRight}
                {showSettings && step.Type === ServiceType.SPC && spcSettings}
              </div>
            </div>
            <div
              className="w-100 h30 d-flex align-items-center mt20 justify-content-between"
            >
              <div className="flex-10 text-center">
                {
                  policySteps.length !== (step.Sequence + 1) && (
                    <img src={arrowImg} alt="Arrow" className="h30" />
                  )
                }
              </div>
              <div className="flex-90 d-flex align-items-center justify-content-center add-step-button">
                <div className="break-top flex-45 d-inline-block add-step-content" />
                <div className="flex-10 text-center add-step-content">
                  <CustomizedDropdown
                    full
                    avoidUpdateOnSelect
                    variant="outline-dark"
                    onChange={(value: string) => {
                      addStep(value, step.Sequence);
                    }}
                    list={step.Type === LoaderStepType.Load || step.Type === LoaderStepType.Enrichment
                    || step.Type === LoaderStepType.Converter || step.Type === LoaderStepType.Copy
                      ? LoaderSteps : ServiceTypes}
                  />
                </div>
                <div className="break-top flex-45 d-inline-block add-step-content" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PolicyStep;
