/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { ReticleInsertDirection, ReticlePanDirection, ReticlePanMode } from 'components/wafer-control-map/pcm-wat-site-map-tab';
import { UtilityFunctions } from 'components/wafer-control-map/utility';
// eslint-disable-next-line no-unused-vars
import { DEFAULT_DIE_COLOR, WAFER_DIAMETER_EXEMPTED_FROM_RETICLE_COLLISION_CROPPING } from 'components/wafer-control-map/wafer-control-map';
import _ from 'lodash';
import toast from 'CustomToast';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
// eslint-disable-next-line no-unused-vars
import WaferMapVariables from '../variables/WaferMapVariablesClass';
import radarView from '../wafer-map-scripts/RadarView';
import {
  ColAxisDirection, ColFlip, DieColorType, DieLocation, ExclusionType, FlipAxis, NotchPosition, RotateDirection, RowAxisDirection, RowFlip, ActionOnWaferData,
} from './Enums';
import {
  WaferMapData, RectCoord, DieSubView, Canvas, Viewport, Option, ReticleReference, ZoneData, XYPoint, WaferMapTestData, ReticleGridRectCoords, StandardReticle,
} from './Types';
/* eslint-enable object-curly-newline */
// eslint-disable-next-line no-unused-vars
class WebGLUtils {
  waferMapVariables: WaferMapVariables;

  gl: WebGL2RenderingContext | null = null;

  constructor(variablesObject: WaferMapVariables) {
    this.waferMapVariables = variablesObject;
  }

  getGlContext = (canvas: HTMLCanvasElement) => {
    const gl: WebGL2RenderingContext = canvas.getContext('webgl2') as WebGL2RenderingContext;
    this.gl = gl;
    return gl;
  };

  clear = (r: number, g: number, b: number, a: number) => {
    this.gl!.clearColor(r, g, b, a);
    this.gl!.clear(this.gl!.COLOR_BUFFER_BIT);
  };

  getShader = (gl: WebGL2RenderingContext, shaderSource: string, shaderType: number) => {
    const shader: WebGLShader = gl.createShader(shaderType) as WebGLShader;
    if (!shader) return null;
    gl.shaderSource(shader, shaderSource);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      // eslint-disable-next-line no-console
      console.error(gl.getShaderInfoLog(shader));
    }
    return shader;
  };

  getProgram = (gl: WebGL2RenderingContext, vertexShaderSource: string, fragmentShaderSource: string): WebGLProgram | null => {
    const vs: WebGLShader = this.getShader(gl, vertexShaderSource, gl.VERTEX_SHADER) as WebGLShader;
    const fs: WebGLShader = this.getShader(gl, fragmentShaderSource, gl.FRAGMENT_SHADER) as WebGLShader;
    if (!vs || !fs) return null;
    const program: WebGLProgram = gl.createProgram() as WebGLProgram;
    gl.attachShader(program, vs);
    gl.attachShader(program, fs);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      // eslint-disable-next-line no-console
      console.error(gl.getProgramInfoLog(program));
    }
    return program;
  };

  createAndBindBuffer = (gl: WebGL2RenderingContext, bufferType: number, typeOfDrawing: number, data: ArrayBuffer): WebGLBuffer => {
    const buffer: WebGLBuffer = gl.createBuffer() as WebGLBuffer; // allocates some memory in gpu
    gl.bindBuffer(bufferType, buffer); // bind allocated memory with the channel
    gl.bufferData(bufferType, data, typeOfDrawing); // use this channel and send data to GPU
    gl.bindBuffer(bufferType, null); // Dislocating memory
    return buffer;
  };

  createAndBindTexture = (gl: WebGL2RenderingContext, image: HTMLImageElement): WebGLTexture => {
    const texture: WebGLTexture = gl.createTexture() as WebGLTexture;
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  };

  linkGPUAndCPU = (obj: {
    program: WebGLProgram,
    gpuVariable: string,
    channel?: number,
    buffer: WebGLBuffer,
    dims: number,
    dataType?: number,
    normalize?: boolean,
    stride?: 0,
    offset?: 0
  }, gl: WebGL2RenderingContext) => {
    const position: GLint = gl.getAttribLocation(obj.program, obj.gpuVariable);
    gl.enableVertexAttribArray(position);
    gl.bindBuffer(obj.channel || gl.ARRAY_BUFFER, obj.buffer);
    gl.vertexAttribPointer(
      position,
      obj.dims,
      obj.dataType || gl.FLOAT,
      obj.normalize || false,
      obj.stride || 0,
      obj.offset || 0,
    );
    return position;
  };

  getHTMLCoords = (startX: number, startY: number, canvas: HTMLCanvasElement) => {
    return {
      x: ((startX + 1.0) / 2) * canvas.width,
      y: ((startY + 1.0) / 2) * canvas.height,
    };
  };

  getGPUCoords0To2 = (obj: RectCoord) => {
    return {
      startX: 1.0 + obj.startX,
      startY: 1.0 + obj.startY,
      endX: 1.0 + obj.endX,
      endY: 1.0 + obj.endY,
    };
  };

  updateReticlesOnPanning = (reticle: any [], diff: { x: any, y: any }) => {
    for (let i = 0; i < reticle.length; i += 2) {
      // eslint-disable-next-line no-param-reassign
      reticle[i] += diff.x;
      // eslint-disable-next-line no-param-reassign
      reticle[i + 1] += diff.y;
    }
    return reticle;
  };

  getDiff = (startX: number, startY: number, endX: number, endY: number, canvas: HTMLCanvasElement) => {
    const obj = {
      startX,
      startY,
      endX,
      endY,
    };
    let v = this.getGPUCoords(obj, {
      width: canvas.width,
      height: canvas.height,
    }); // -1 to +1
    v = this.getGPUCoords0To2(v); // 0 to 2
    const diffX = v.endX - v.startX;
    const diffY = v.endY - v.startY;
    return {
      x: diffX,
      y: diffY,
      diffXHTML: endX - startX,
      diffYHTML: endY - startY,
    };
  };

  scalingWaferMap = (dieWidth: number, dieHeight: number, waferMaptTestData: WaferMapTestData, canvas: Canvas) => {
    let scaledDieWidth: number;
    let scaledDieHeight: number;
    const padding = this.waferMapVariables.waferMapPadding;
    const originalWaferWidth = dieWidth * waferMaptTestData[0].length; // die width * number of dies in a row
    const originalWaferHeight = dieHeight * waferMaptTestData.length; // die height * number of dies in a column
    const widthWeHave = canvas.width - padding * 2;
    const heightWeHave = canvas.height - padding * 2;
    if (originalWaferWidth > originalWaferHeight) {
      scaledDieWidth = (dieWidth * widthWeHave) / originalWaferWidth;
      scaledDieHeight = (dieHeight * widthWeHave) / originalWaferWidth;
    } else {
      scaledDieWidth = (dieWidth * heightWeHave) / originalWaferHeight;
      scaledDieHeight = (dieHeight * heightWeHave) / originalWaferHeight;
    }
    this.waferMapVariables.thresholdDieWidth = scaledDieWidth;
    this.waferMapVariables.thresholdDieHeight = scaledDieHeight;
    const scaledWaferWidth = scaledDieWidth * waferMaptTestData[0].length;
    const scaledWaferHeight = scaledDieHeight * waferMaptTestData.length;
    return {
      scaledDieWidth,
      scaledDieHeight,
      scaledWaferWidth,
      scaledWaferHeight,
    };
  };

  getGPUCoords = (obj: RectCoord, canvas: Canvas): RectCoord => {
    return {
      startX: -1.0 + (obj.startX / canvas.width) * 2,
      startY: -1.0 + (obj.startY / canvas.height) * 2,
      endX: -1.0 + (obj.endX / canvas.width) * 2,
      endY: -1.0 + (obj.endY / canvas.height) * 2,
    };
  };

  prepareRectVec2 = (startX: number, startY: number, endX: number, endY: number): number[] => {
    return [
      startX,
      startY,
      endX,
      startY,
      startX,
      endY,
      startX,
      endY,
      endX,
      endY,
      endX,
      startY,
    ];
  };

  prepareRectVec = (startX: number, startY: number, endX: number, endY: number): number[] => {
    return [
      startX, startY,
      endX, startY,
      startX, endY,
      startX, endY,
      endX, startY,
      endX, endY,
    ];
  };

  // eslint-disable-next-line no-unused-vars
  returnVerticalLine = (startX: number, canvas: Canvas, lineThickness = 1): number[] => {
    // eslint-disable-next-line no-param-reassign
    startX = Math.floor(startX);
    const { rotation } = this.waferMapVariables;
    const rotatedLineCoords = this.rotatedCoords(
      [
        startX - lineThickness / 2,
        0,
        startX + lineThickness / 2,
        0,
        startX - lineThickness / 2,
        canvas.height,
        startX - lineThickness / 2,
        canvas.height,
        startX + lineThickness / 2,
        0,
        startX + lineThickness / 2,
        canvas.height],
      canvas,
      rotation,
    );
    return [
      rotatedLineCoords[0].x, rotatedLineCoords[0].y,
      rotatedLineCoords[1].x, rotatedLineCoords[1].y,
      rotatedLineCoords[2].x, rotatedLineCoords[2].y,
      rotatedLineCoords[3].x, rotatedLineCoords[3].y,
      rotatedLineCoords[4].x, rotatedLineCoords[4].y,
      rotatedLineCoords[5].x, rotatedLineCoords[5].y,
    ];
  };

  // eslint-disable-next-line no-unused-vars
  returnVerticalLineFromCoords = (canvas: Canvas, lineThickness: number, coord: { x: number, y: number }, dieWidth: number, dieHeight: number, offsetX: number, offsetY: number): number[] => {
    // TODO: add comments
    const { rotation } = this.waferMapVariables;

    const sy = offsetY + coord.y * dieHeight;
    const ey = offsetY + (coord.y + 1) * dieHeight;
    const x = coord.x * dieWidth + offsetX;
    const rotatedLineCoords = this.rotatedCoords(
      [
        x - lineThickness / 2,
        sy, // sy
        x + lineThickness / 2,
        sy, // sy
        x - lineThickness / 2,
        ey, // ey
        x - lineThickness / 2,
        ey, // ey
        x + lineThickness / 2,
        sy, // sy
        x + lineThickness / 2,
        ey, // ey
      ],
      canvas,
      rotation,
    );
    return [
      rotatedLineCoords[0].x, rotatedLineCoords[0].y,
      rotatedLineCoords[1].x, rotatedLineCoords[1].y,
      rotatedLineCoords[2].x, rotatedLineCoords[2].y,
      rotatedLineCoords[3].x, rotatedLineCoords[3].y,
      rotatedLineCoords[4].x, rotatedLineCoords[4].y,
      rotatedLineCoords[5].x, rotatedLineCoords[5].y,
    ];
  };

  getKeyByValue = (object: any, value: any) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  // eslint-disable-next-line no-unused-vars
  returnHorizentalLine = (startY: number, canvas: Canvas, lineThickness = 1): number[] => {
    // eslint-disable-next-line no-param-reassign
    startY = Math.floor(startY);
    const { rotation } = this.waferMapVariables;
    const rotatedLineCoords = this.rotatedCoords(
      [
        0,
        startY - lineThickness / 2,
        canvas.width,
        startY - lineThickness / 2,
        0,
        startY + lineThickness / 2,
        0,
        startY + lineThickness / 2,
        canvas.width,
        startY - lineThickness / 2,
        canvas.width,
        startY + lineThickness / 2],
      canvas,
      rotation,
    );
    return [
      rotatedLineCoords[0].x, rotatedLineCoords[0].y,
      rotatedLineCoords[1].x, rotatedLineCoords[1].y,
      rotatedLineCoords[2].x, rotatedLineCoords[2].y,
      rotatedLineCoords[3].x, rotatedLineCoords[3].y,
      rotatedLineCoords[4].x, rotatedLineCoords[4].y,
      rotatedLineCoords[5].x, rotatedLineCoords[5].y,
    ];
  };

  // eslint-disable-next-line no-unused-vars
  returnHorizentalLineFromCoords = (canvas: Canvas, lineThickness: number, coord: { x: number, y: number }, dieWidth: number, dieHeight: number, offsetX: number, offsetY: number): number[] => {
    // TODO: Add comments
    const { rotation } = this.waferMapVariables;
    const sx = offsetX + coord.x * dieWidth;
    const ex = offsetX + (coord.x + 1) * dieWidth;
    const y = coord.y * dieHeight + offsetY;
    const rotatedLineCoords = this.rotatedCoords(
      [
        sx, // sx
        y - lineThickness / 2,
        ex, // ex
        y - lineThickness / 2,
        sx, // sx
        y + lineThickness / 2,
        sx, // sx
        y + lineThickness / 2,
        ex, // ex
        y - lineThickness / 2,
        ex, // ex
        y + lineThickness / 2],
      canvas,
      rotation,
    );
    return [
      rotatedLineCoords[0].x, rotatedLineCoords[0].y,
      rotatedLineCoords[1].x, rotatedLineCoords[1].y,
      rotatedLineCoords[2].x, rotatedLineCoords[2].y,
      rotatedLineCoords[3].x, rotatedLineCoords[3].y,
      rotatedLineCoords[4].x, rotatedLineCoords[4].y,
      rotatedLineCoords[5].x, rotatedLineCoords[5].y,
    ];
  };

  binColorMapping = (
    color: string,
    dieColorMap: { [key: string]: number[] },
    dieBorderMap: { [key: string]: number[] },
    reticleCoords: number[],
    dieBorderCoords: number[],
  ): void => {
    if (dieColorMap[color]) { dieColorMap[color].push(...reticleCoords); } else { dieColorMap[color] = [...reticleCoords]; }

    if (dieBorderMap[color]) { dieBorderMap[color].push(...dieBorderCoords); } else { dieBorderMap[color] = [...dieBorderCoords]; }
  };

  selectionBinColorMapping = (
    color: string,
    dieColorMapSelection: { [key: string]: number[] },
    dieBorderMapSelection: { [key: string]: number[] },
    reticleCoords: number[],
    dieBorderCoords: number[],
  ): void => {
    if (dieColorMapSelection[color]) { dieColorMapSelection[color].push(...reticleCoords); } else { dieColorMapSelection[color] = [...reticleCoords]; }

    if (dieBorderMapSelection[color]) { dieBorderMapSelection[color].push(...dieBorderCoords); } else { dieBorderMapSelection[color] = [...dieBorderCoords]; }
  };

  selectedBinColorMapping = (
    color: string,
    dieColorMapSelected: { [key: string]: number[] },
    dieBorderMapSelected: { [key: string]: number[] },
    reticleCoords: number[],
    dieBorderCoords: number[],
  ): void => {
    if (dieColorMapSelected[color]) { dieColorMapSelected[color].push(...reticleCoords); } else { dieColorMapSelected[color] = [...reticleCoords]; }

    if (dieBorderMapSelected[color]) { dieBorderMapSelected[color].push(...dieBorderCoords); } else { dieBorderMapSelected[color] = [...dieBorderCoords]; }
  };

  normaliseDieSubView = (): DieSubView | null => {
    if (this.waferMapVariables.dieSubView == null) return null;
    const dieSubView: DieSubView = [];
    for (let i = 0; i < this.waferMapVariables.dieSubView.length; i += 1) {
      dieSubView.push([]);
      for (let j = 0; j < this.waferMapVariables.dieSubView[i].length; j += 1) {
        dieSubView[i].push(
          this.waferMapVariables.dieSubView[i][j].map((dieSub: { x1: number, x2: number, y1: number, y2: number }) => {
            return {
              x1: dieSub.x1 * this.waferMapVariables.scaledDieWidth,
              y1: dieSub.y1 * this.waferMapVariables.scaledDieHeight,
              x2: dieSub.x2 * this.waferMapVariables.scaledDieWidth,
              y2: dieSub.y2 * this.waferMapVariables.scaledDieHeight,
            };
          }),
        );
      }
    }
    return dieSubView;
  };

  // ask: Property 'visible' does not exist on type '{ x1: number; x2: number; y1: number; y2: number; }'.ts(2339)
  prepareDieSubViewCoords = (
    dieSubView: DieSubView,
    dieSubViewMap: number[],
    canvas: Canvas,
    newIndexI: number,
    newIndexJ: number,
    startX: number,
    startY: number,
  ): void => {
    if (this.waferMapVariables.dieSubView == null) return;
    dieSubView[newIndexI][newIndexJ].forEach((dieSub, index) => {
      if (
        this.waferMapVariables.dieSubView![newIndexI][newIndexJ][index].visible
      ) {
        const { rotation } = this.waferMapVariables;
        const rotatedDieCoords = this.rotatedCoords([startX + dieSub.x1,
          startY + dieSub.y1,
          startX + dieSub.x2,
          startY + dieSub.y1,
          startX + dieSub.x1,
          startY + dieSub.y2,
          startX + dieSub.x1,
          startY + dieSub.y2,
          startX + dieSub.x2,
          startY + dieSub.y1,
          startX + dieSub.x2,
          startY + dieSub.y2], canvas, rotation);
        const v3: number[] = [
          rotatedDieCoords[0].x, rotatedDieCoords[0].y,
          rotatedDieCoords[1].x, rotatedDieCoords[1].y,
          rotatedDieCoords[2].x, rotatedDieCoords[2].y,
          rotatedDieCoords[3].x, rotatedDieCoords[3].y,
          rotatedDieCoords[5].x, rotatedDieCoords[5].y,
          rotatedDieCoords[4].x, rotatedDieCoords[4].y,
        ];
        dieSubViewMap.push(
          ...v3,
        );
      }
    });
  };

  getGPUCoordsGLSingle = (x: number, y: number, canvas: Canvas) => {
    return {
      x: -1.0 + (x / canvas.width) * 2,
      y: -1.0 + (y / canvas.height) * 2,
    };
  };

  rotatedCoords = (coords: number[], canvas: Canvas, rotation: number[]) => {
    const originShiftedCoords: number[] = [];
    for (let i = 0; i < coords.length - 1; i += 2) {
      originShiftedCoords.push(coords[i] - canvas.width / 2);
      originShiftedCoords.push(coords[i + 1] - canvas.height / 2);
    }
    const rotatedCoords: number[] = [];
    for (let i = 0; i < coords.length - 1; i += 2) {
      rotatedCoords.push(originShiftedCoords[i] * rotation[1] + originShiftedCoords[i + 1] * rotation[0]);
      rotatedCoords.push(originShiftedCoords[i + 1] * rotation[1] - originShiftedCoords[i] * rotation[0]);
    }
    const rotatedShiftedCoords: number[] = [];
    for (let i = 0; i < coords.length - 1; i += 2) {
      rotatedShiftedCoords.push(rotatedCoords[i] + canvas.width / 2);
      rotatedShiftedCoords.push(rotatedCoords[i + 1] + canvas.height / 2);
    }
    const gpuCoords: { x: number, y: number }[] = [];
    for (let i = 0; i < coords.length - 1; i += 2) {
      gpuCoords.push(this.getGPUCoordsGLSingle(rotatedShiftedCoords[i], rotatedShiftedCoords[i + 1], canvas));
    }
    return gpuCoords;
  };

  hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  };

  euclideanDistance = (p1: { x: number, y: number }, p2: { x: number, y: number }) => {
    const a = p1.x - p2.x;
    const b = p1.y - p2.y;
    return Math.sqrt(a * a + b * b);
  };

  getColor = (isWaferControlMap: boolean, die: WaferMapData, dieTypes: any, binColor: string, dieTypeField: string, dieColorType: DieColorType, currZone: ZoneData | null, zoneColorSettingColors: any[] | null) : string => {
    if (!isWaferControlMap) return binColor;

    if (dieColorType === DieColorType.DIE_TYPE) {
      if ((dieTypeField && !die[dieTypeField]) || (!dieTypeField && (!die.dieType || die.dieType === ''))) return binColor;
      let dieType;
      if (dieTypeField) {
        dieType = UtilityFunctions.getDieTypeFromId(die[dieTypeField], dieTypes[dieTypeField]);
      } else {
        dieType = UtilityFunctions.getDieTypeFromId(die.dieType!, dieTypes);
      }
      if (dieType === null) return binColor;
      return dieType.color;
    } else if (dieColorType === DieColorType.ZONE) {
      if (currZone === null || zoneColorSettingColors === null || die.zoneInfo === undefined) {
        return DEFAULT_DIE_COLOR;
      } else {
        const currZoneId = currZone.id?.toString();
        if (currZone.zoneType === 'FREE_FORM' || currZone.zoneType === 'GROUPED_PER_SITE') {
          // given that free form and grouped zones have 1 pcm site per zone
          if (currZoneId! in die.zoneInfo) {
            if (die.zoneInfo![currZoneId!] && die.zoneInfo![currZoneId!].length === 0) return DEFAULT_DIE_COLOR;
            const colorIndex = currZone.markedPCMSiteNumbers.findIndex((p: number) => { return p === die.zoneInfo![currZoneId!][0]; });
            if (colorIndex === -1) return DEFAULT_DIE_COLOR;
            const colorSetting = zoneColorSettingColors[colorIndex];
            if (colorSetting) {
              return colorSetting.color as string;
            } else {
              return DEFAULT_DIE_COLOR;
            }
          } else {
            return DEFAULT_DIE_COLOR;
          }
        }
      }
    }
    return binColor;
  };

  getReticleReferencePointCoords = (
    canvas: Canvas,
    rotation: number[],
    reticleReference: ReticleReference,
    reticleRect: RectCoord,
  ) => {
    const reticleReferenceCoords: number[] = [];
    let reticleReferencePoint: { x: number, y: number } | null = null;
    let referenceX;
    let referenceY;

    switch (reticleReference) {
      case 'BOTTOM_LEFT':
        referenceX = reticleRect.startX;
        referenceY = reticleRect.endY;
        break;
      case 'BOTTOM_RIGHT':
        referenceX = reticleRect.endX;
        referenceY = reticleRect.endY;
        break;
      case 'TOP_LEFT':
        referenceX = reticleRect.startX;
        referenceY = reticleRect.startY;
        break;
      case 'TOP_RIGHT':
        referenceX = reticleRect.endX;
        referenceY = reticleRect.startY;
        break;
      default:
        referenceX = reticleRect.startX + (reticleRect.endX - reticleRect.startX) / 2;
        referenceY = reticleRect.startY + (reticleRect.endY - reticleRect.startY) / 2;
        break;
    }

    const rotatedReticleReferenceCoords = this.rotatedCoords([
      referenceX - 5, referenceY - 5,
      referenceX + 5, referenceY + 5,
      referenceX + 5, referenceY - 5,
      referenceX - 5, referenceY + 5,
    ], canvas, rotation);
    rotatedReticleReferenceCoords.forEach((dat) => { reticleReferenceCoords.push(dat.x, dat.y); });
    reticleReferencePoint = { x: referenceX, y: referenceY };
    return { reticleReferencePoint, reticleReferenceCoords };
  };

  getReticleOffsetLineCoords = (
    canvas: Canvas,
    rotation: number[],
    p1: { x: number, y: number },
    p2: { x: number, y: number },
  ) => {
    const slope = (p2.y - p1.y) / (p2.x - p1.x);
    const intercept = p1.y - slope * p1.x;

    const points = [];
    const noOfPoints = 50; // number of points in between p1 and p2
    const xIncrement = (p2.x - p1.x) / (noOfPoints + 1);
    for (let i = 1; i <= noOfPoints; i += 1) {
      const x = xIncrement * i + p1.x;
      const y = slope * x + intercept;
      points.push(x, y);
    }
    const reticleOffsetLineCoords: number[] = [];
    const rotatedReticleOffsetLineCoords = this.rotatedCoords(points, canvas, rotation);
    rotatedReticleOffsetLineCoords.forEach((dat) => { reticleOffsetLineCoords.push(dat.x, dat.y); });
    return reticleOffsetLineCoords;
  };

  getReticleCoordsData = (
    reticleGridRectCoords: ReticleGridRectCoords,
    offsetX: number,
    offsetY: number,
    dieWidth: number,
    dieHeight: number,
    canvas: Canvas,
    rotation: number[],
    rowOffset: number,
    colOffset: number,
    thicknessFactor: number,
    paddingFactor: 0 | 1,
    colFlip: ColFlip,
    colAxisIncrement: number,
    colAxisStart: number,
    rowFlip: RowFlip,
    rowAxisIncrement: number,
    rowAxisStart: number,
    reticleType: 'all' | 'selected' | 'under_selection',
  ) => {
    const reticles = Object.keys(reticleGridRectCoords);
    const reticleBorderCoords: number[] = [];
    const reticleBGCoords: number[] = [];

    for (let i = 0; i < reticles.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (reticleType === 'under_selection' && !reticleGridRectCoords[reticles[i]].isUnderSelection) continue;
      // eslint-disable-next-line no-continue
      if (reticleType === 'selected' && !reticleGridRectCoords[reticles[i]].isSelected) continue;
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const xAnchorOffset = colFlip === ColFlip.Inverted ? (colAxisStart + 1) * dieWidth : 0;
      const yAnchorOffset = rowFlip === RowFlip.Inverted ? (rowAxisStart + 1) * dieHeight : 0;
      const reticleRect: RectCoord = {
        startX: offsetX + xAnchorOffset + colAxisIncrement * (currReticle.startX - colOffset) * dieWidth,
        startY: offsetY + yAnchorOffset + rowAxisIncrement * (currReticle.startY - rowOffset) * dieHeight,
        endX: offsetX + xAnchorOffset + colAxisIncrement * (currReticle.endX - colOffset + 1) * dieWidth,
        endY: offsetY + yAnchorOffset + rowAxisIncrement * (currReticle.endY - rowOffset + 1) * dieHeight,
      };

      if (reticleType === 'all') {
        const { ratioWidth, ratioHeight } = this.waferMapVariables;
        reticleGridRectCoords[reticles[i]].reticleTextData = {
          x: (reticleRect.startX + reticleRect.endX) / (2 * ratioWidth),
          y: (reticleRect.startY + reticleRect.endY) / (2 * ratioHeight),
          text:
            reticleGridRectCoords[reticles[i]][this.waferMapVariables.reticleTextField] !== undefined
            && reticleGridRectCoords[reticles[i]][this.waferMapVariables.reticleTextField] !== null
              ? `${reticleGridRectCoords[reticles[i]][this.waferMapVariables.reticleTextField]}` : null,
        };
      }
      const thickness = (0.033333 * Math.min(dieWidth, dieHeight) + 1.66667) * thicknessFactor; // y = mx + c, where x is min of die width and die height
      const padding = thickness * paddingFactor;
      const unrotatedReticleBorderCoords = [
        ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.startY + thickness + padding), // top
        ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.endY - padding - thickness, reticleRect.endX - padding, reticleRect.endY - padding), // bottom
        ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.startX + thickness + padding, reticleRect.endY - padding), // left
        ...this.prepareRectVec(reticleRect.endX - padding - thickness, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.endY - padding), // right
      ];
      const rotatedReticleBorderCoords = this.rotatedCoords(unrotatedReticleBorderCoords, canvas, rotation);
      rotatedReticleBorderCoords.forEach((dat) => { reticleBorderCoords.push(dat.x, dat.y); });

      if (reticleGridRectCoords[reticles[i]].reticleTextData?.text) {
        const unrotatedReticleBGCoords = this.prepareRectVec(reticleRect.startX, reticleRect.startY, reticleRect.endX, reticleRect.endY);
        const rotatedReticleBGCoords = this.rotatedCoords(unrotatedReticleBGCoords, canvas, rotation);
        rotatedReticleBGCoords.forEach((dat) => { reticleBGCoords.push(dat.x, dat.y); });
      }
    }
    return { reticleBorderCoords, reticleBGCoords };
  };

  getReferenceReticleCoordsData = (
    reticleGridRectCoords: RectCoord,
    offsetX: number,
    offsetY: number,
    dieWidth: number,
    dieHeight: number,
    canvas: Canvas,
    rotation: number[],
    rowOffset: number,
    colOffset: number,
    thicknessFactor: number,
    paddingFactor: 0 | 1,
    reticleReference: ReticleReference,
  ) => {
    const reticleBorderCoords: number[] = [];
    let reticleReferenceCoords: number[] = [];
    let reticleReferencePoint: { x: number, y: number } | null = null;
    const currReticle: RectCoord = reticleGridRectCoords;
    const reticleRect: RectCoord = {
      startX: offsetX + (currReticle.startX - colOffset) * dieWidth,
      startY: offsetY + (currReticle.startY - rowOffset) * dieHeight,
      endX: offsetX + (currReticle.endX - colOffset) * dieWidth + dieWidth,
      endY: offsetY + (currReticle.endY - rowOffset) * dieHeight + dieHeight,
    };

    const reticleReferenceData = this.getReticleReferencePointCoords(canvas, rotation, reticleReference, reticleRect);
    reticleReferenceCoords = reticleReferenceData.reticleReferenceCoords;
    reticleReferencePoint = reticleReferenceData.reticleReferencePoint;

    const thickness = (0.033333 * Math.min(dieWidth, dieHeight) + 1.66667) * thicknessFactor; // y = mx + c, where x is min of die width and die height
    const padding = thickness * paddingFactor;
    const unrotatedReticleBorderCoords = [
      ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.startY + thickness + padding), // top
      ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.endY - padding - thickness, reticleRect.endX - padding, reticleRect.endY - padding), // bottom
      ...this.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.startX + thickness + padding, reticleRect.endY - padding), // left
      ...this.prepareRectVec(reticleRect.endX - padding - thickness, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.endY - padding), // right
    ];
    const rotatedReticleBorderCoords = this.rotatedCoords(unrotatedReticleBorderCoords, canvas, rotation);
    rotatedReticleBorderCoords.forEach((dat) => { reticleBorderCoords.push(dat.x, dat.y); });

    return { reticleBorderCoords, reticleReferenceCoords, reticleReferencePoint };
  };

  getRingCoords = (currRadius: number, noOfPoints: number, centerX: number, centerY: number, canvas: Canvas, rotation: number[]) => {
    let ringCoords: number[] = [];
    const radiusY = currRadius;
    const radiusX = (radiusY / canvas.width) * canvas.height;
    for (let i = 0; i < noOfPoints; i += 1) {
      const theta = 2 * Math.PI * (i / noOfPoints);
      const x = centerX + radiusX * Math.cos(theta);
      const y = centerY + radiusY * Math.sin(theta);
      ringCoords.push(x, y);
    }
    const data = this.rotatedCoords(ringCoords, canvas, rotation);
    ringCoords = [];
    data.forEach((dat) => {
      ringCoords.push(dat.x, dat.y);
    });
    return ringCoords;
  };

  getRadialZoneCoords = (zones: ZoneData[], currentZoneId: string, waferDiameter: number, waferEdgeExclusion: number, maxRingRadius: number, centerX: number, centerY: number, canvas: Canvas, rotation: number[]) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'RADIAL' || (filteredZones[0].zoneType === 'RADIAL' && filteredZones[0].numberOfZones === undefined)) return [];
    const numberOfZones = filteredZones[0].numberOfZones!;
    const ringRadiusExcludingEdgeExclusion = ((waferDiameter - 2 * waferEdgeExclusion) / waferDiameter) * maxRingRadius;
    const incrementRadiusSqred = (ringRadiusExcludingEdgeExclusion ** 2) / numberOfZones;
    let currRadiusSqred = incrementRadiusSqred;
    const radialZoneRingCoords: number[] = [];
    for (let r = 0; r < numberOfZones; r += 1, currRadiusSqred += incrementRadiusSqred) {
      radialZoneRingCoords.push(...this.getRingCoords(Math.sqrt(currRadiusSqred), 1000, centerX, centerY, canvas, rotation));
    }
    return radialZoneRingCoords;
  };

  getVerticalZoneCoords = (zones: ZoneData[], currentZoneId: string, canvas: Canvas, colOffset: number, scaledDieWidth: number, XAnchor: number) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'VERTICAL' || (filteredZones[0].zoneType === 'VERTICAL' && filteredZones[0].numberOfZones === undefined)) return [];

    const verticalZoneCoords: number[] = [];
    for (let i = 0; filteredZones[0].verticalZoneColNumbers && i < filteredZones[0].verticalZoneColNumbers.length; i += 1) {
      const startX = (filteredZones[0].verticalZoneColNumbers[i] + 1 - colOffset) * scaledDieWidth + XAnchor;
      verticalZoneCoords.push(
        ...this.returnVerticalLine(
          startX,
          canvas,
          5,
        ),
      );
    }
    return verticalZoneCoords;
  };

  getHorizontalZoneCoords = (zones: ZoneData[], currentZoneId: string, canvas: Canvas, rowOffset: number, scaledDieHeight: number, YAnchor: number) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'HORIZONTAL' || (filteredZones[0].zoneType === 'HORIZONTAL' && filteredZones[0].numberOfZones === undefined)) return [];

    const horizontalZoneCoords: number[] = [];
    for (let i = 0; filteredZones[0].horizontalZoneRowNumbers && i < filteredZones[0].horizontalZoneRowNumbers.length; i += 1) {
      const startY = (filteredZones[0].horizontalZoneRowNumbers[i] + 1 - rowOffset) * scaledDieHeight + YAnchor;
      horizontalZoneCoords.push(
        ...this.returnHorizentalLine(
          startY,
          canvas,
          5,
        ),
      );
    }
    return horizontalZoneCoords;
  };

  getGroupedZoneCoords = (zones: ZoneData[], currentZoneId: string, canvas: Canvas, rowOffset: number, colOffset: number, dieWidth: number, dieHeight: number, offsetX: number, offsetY: number) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];

    const horizontalZoneCoords: number[] = [];
    for (let i = 0; filteredZones[0].ycoords && i < filteredZones[0].ycoords.length; i += 1) {
      horizontalZoneCoords.push(
        ...this.returnVerticalLineFromCoords(
          canvas,
          5,
          { x: filteredZones[0].ycoords[i].x - colOffset, y: filteredZones[0].ycoords[i].y - rowOffset },
          dieWidth,
          dieHeight,
          offsetX,
          offsetY,
        ),
      );
    }
    for (let i = 0; filteredZones[0].xcoords && i < filteredZones[0].xcoords.length; i += 1) {
      horizontalZoneCoords.push(
        ...this.returnHorizentalLineFromCoords(
          canvas,
          5,
          { x: filteredZones[0].xcoords[i].x - colOffset, y: filteredZones[0].xcoords[i].y - rowOffset },
          dieWidth,
          dieHeight,
          offsetX,
          offsetY,
        ),
      );
    }
    return horizontalZoneCoords;
  };

  waferMapDataToGPU = (
    waferMaptTestData: WaferMapTestData,
    dieWidth: number,
    dieHeight: number,
    canvas: Canvas,
    offsetX: number,
    offsetY: number,
  ) => {
    const horizontalGridLines: number[] = [];
    const verticleGridLines: number[] = [];
    const allDiesCoords: number[] = [];
    const selectedDies: number[] = [];
    const filteredDies: number[] = [];
    const dieImageCoords: number[][][] = [];
    let startX: number;
    let startY: number;
    let endX = 0;
    let endY = 0;
    let dieBorderSX: number;
    let dieBorderSY: number;
    let dieBorderEX: number;
    let dieBorderEY: number;
    let XAnchor = 0;
    let YAnchor = 0;
    const dieColorMap: { [key: string]: number[] } = {};
    const dieBorderMap: { [key: string]: number[] } = {};
    const dieColorMapSelection: { [key: string]: number[] } = {};
    const dieBorderMapSelection: { [key: string]: number[] } = {};
    const dieColorMapSelected: { [key: string]: number[] } = {};
    const dieBorderMapSelected: { [key: string]: number[] } = {};
    const dieSubViewMap: number[] = [];
    let referenceReticleBorderCoords: number[] = [];
    let allReticleBorderCoords: number[] = [];
    let allReticleBGCoords: number[] = [];
    let underSelectionReticleBorderCoords: number[] = [];
    let selectedReticleBorderCoords: number[] = [];
    const reticleReferenceCoords: number[] = [];
    let ringCoords: number[] = [];
    let radialZoneRingCoords: number[] = [];
    let verticalZoneCoords: number[] = [];
    let horizontalZoneCoords: number[] = [];
    let groupedZoneCoords: number[] = [];
    const waferCenterCoords: number[] = [];
    let waferBgImageCoords: number[] = [];
    let dieSubView: DieSubView | null = [];
    let dieCount = 0;

    let ringRadius = 0;
    let ringCenterX = 0;
    let ringCenterY = 0;

    dieSubView = this.normaliseDieSubView();
    const {
      waferHeightToRowsRatio, waferWidthToColsRatio, panOffsetYToDieStepSizeYRatio, panOffsetXToDieStepSizeXRatio, startRow, rowDirection, dieTypes, colOffset, rowOffset, colFlip, rowFlip,
      startCol, colDirection, subdieColStart, showRing, ringDiameterToWaferDiameterRatio, wcmWaferDiameter, wcmWaferEdgeExclusion, zones, currentZoneId, reticleReference, showReferenceReticle,
      rotation, showRotationNotch, waferTopOffset, dieHeightToStreetHeightRatio, dieWidthToStreetWidthRatio, waferBGOffsetXDies, waferBGOffsetYDies, isWaferControlMap, dieTypeField, dieColorType,
      colAxisIncrement, rowAxisIncrement, colAxisStart, rowAxisStart, reticleGridRectCoords, referenceReticleGridRectCoords, overlayReticle,
    } = this.waferMapVariables;
    if (dieSubView === null || this.waferMapVariables.dieSubView === null) return;
    if (this.waferMapVariables.dieImageFlag) {
      for (let i = 0, p = startRow; i < waferMaptTestData.length; i += 1, p += rowDirection) {
        dieImageCoords.push([]);
        for (let j = 0, q = startCol; j < waferMaptTestData[i].length; j += 1, q += colDirection) {
          dieImageCoords[i].push([]);
        }
      }
    }

    let currZone: ZoneData | null = null;
    const zoneColors: any[] | null = null;
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      currZone = filteredZones[0];
    }

    const rows = this.waferMapVariables.waferMapTestData.length;
    const cols = this.waferMapVariables.waferMapTestData[0].length;
    const mWidth = dieWidth * cols;
    const mHeight = dieHeight * rows;
    const widthOfBg = mWidth * waferWidthToColsRatio;
    const heightOfBg = mHeight * waferHeightToRowsRatio;
    const centerX = offsetX + mWidth / 2 + (dieWidth * panOffsetXToDieStepSizeXRatio) + waferBGOffsetXDies * dieWidth;
    const centerY = offsetY + mHeight / 2 + (dieHeight * panOffsetYToDieStepSizeYRatio) + waferBGOffsetYDies * dieHeight;
    const waferCenterX = offsetX + mWidth / 2 + waferBGOffsetXDies * dieWidth;
    const waferCenterY = offsetY + mHeight / 2 + waferBGOffsetYDies * dieHeight;

    const maxRingRadiusY = Math.max(widthOfBg, heightOfBg) / 2;
    ringRadius = (ringDiameterToWaferDiameterRatio * maxRingRadiusY);
    ringCenterX = centerX;
    ringCenterY = centerY;
    if (showRotationNotch) {
      const initialXdiff = (widthOfBg - mWidth) / 2;
      const initialYdiff = (heightOfBg - mHeight) / 2;
      const diff = Math.abs(widthOfBg - heightOfBg);
      let rotatedWaferBgImageCoords: { x: number, y: number }[] = [];
      const waferCenter: { x: number, y: number } = { x: 0, y: 0 };
      if (widthOfBg < heightOfBg) {
        // dot at center of wafer
        waferCenter.x = (
          offsetX - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth // x1
          + offsetX + mWidth + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth // x2
        ) / 2;
        waferCenter.y = (
          offsetY - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight // y1
          + offsetY + mHeight + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight // y2
        ) / 2;
        rotatedWaferBgImageCoords = this.rotatedCoords([
          offsetX - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX + mWidth + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX + mWidth + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX + mWidth + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth,
          offsetY - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight,
        ], canvas, rotation);
      } else {
        // dot at center of wafer
        waferCenter.x = (
          offsetX - initialXdiff + waferBGOffsetXDies * dieWidth // x1
          + offsetX + mWidth + initialXdiff + waferBGOffsetXDies * dieWidth // x2
        ) / 2;
        waferCenter.y = (
          offsetY - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight // y1
          + offsetY + mHeight + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight // y2
        ) / 2;
        rotatedWaferBgImageCoords = this.rotatedCoords([
          offsetX - initialXdiff + waferBGOffsetXDies * dieWidth, // x1
          offsetY - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight, // y1
          offsetX + mWidth + initialXdiff + waferBGOffsetXDies * dieWidth,
          offsetY - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX - initialXdiff + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX - initialXdiff + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX + mWidth + initialXdiff + waferBGOffsetXDies * dieWidth,
          offsetY + mHeight + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight,
          offsetX + mWidth + initialXdiff + waferBGOffsetXDies * dieWidth,
          offsetY - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight,
        ], canvas, rotation);
      }
      this.waferMapVariables.waferCenterPoint = { ...waferCenter };
      const rotatedWaferCenterCoords = this.rotatedCoords([
        waferCenter.x + 5, waferCenter.y + 5,
        waferCenter.x - 5, waferCenter.y - 5,
        waferCenter.x + 5, waferCenter.y - 5,
        waferCenter.x - 5, waferCenter.y + 5,
      ], canvas, rotation);
      rotatedWaferCenterCoords.forEach((dat) => { waferCenterCoords.push(dat.x, dat.y); });
      waferBgImageCoords = [
        rotatedWaferBgImageCoords[0].x, rotatedWaferBgImageCoords[0].y,
        rotatedWaferBgImageCoords[1].x, rotatedWaferBgImageCoords[1].y,
        rotatedWaferBgImageCoords[2].x, rotatedWaferBgImageCoords[2].y,
        rotatedWaferBgImageCoords[3].x, rotatedWaferBgImageCoords[3].y,
        rotatedWaferBgImageCoords[4].x, rotatedWaferBgImageCoords[4].y,
        rotatedWaferBgImageCoords[5].x, rotatedWaferBgImageCoords[5].y,
      ];
      if (showRing) {
        ringCoords = this.getRingCoords(ringRadius, 1000, centerX, centerY, canvas, rotation);
      }
    }

    radialZoneRingCoords = this.getRadialZoneCoords(zones, currentZoneId, wcmWaferDiameter, wcmWaferEdgeExclusion, maxRingRadiusY, waferCenterX, waferCenterY, canvas, rotation);
    verticalZoneCoords = this.getVerticalZoneCoords(zones, currentZoneId, canvas, colOffset, dieWidth, offsetX);
    horizontalZoneCoords = this.getHorizontalZoneCoords(zones, currentZoneId, canvas, rowOffset, dieHeight, offsetY);
    groupedZoneCoords = this.getGroupedZoneCoords(zones, currentZoneId, canvas, rowOffset, colOffset, dieWidth, dieHeight, offsetX, offsetY);

    if (overlayReticle) {
      const allReticleCoordsData = this.getReticleCoordsData(
        reticleGridRectCoords,
        offsetX,
        offsetY,
        dieWidth,
        dieHeight,
        canvas,
        rotation,
        rowOffset,
        colOffset,
        0.5,
        0,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'all',
      );
      if (referenceReticleGridRectCoords && showReferenceReticle) {
        const referenceReticleCoordsData = this.getReferenceReticleCoordsData(
          referenceReticleGridRectCoords,
          offsetX,
          offsetY,
          dieWidth,
          dieHeight,
          canvas,
          rotation,
          rowOffset,
          colOffset,
          0.5,
          0,
          reticleReference,
        );
        referenceReticleBorderCoords = referenceReticleCoordsData.reticleBorderCoords;
        reticleReferenceCoords.push(...referenceReticleCoordsData.reticleReferenceCoords);
        this.waferMapVariables.reticleReferencePoint = referenceReticleCoordsData.reticleReferencePoint;
      }
      const underSelectionReticleCoordsData = this.getReticleCoordsData(
        reticleGridRectCoords,
        offsetX,
        offsetY,
        dieWidth,
        dieHeight,
        canvas,
        rotation,
        rowOffset,
        colOffset,
        0.2,
        0,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'under_selection',
      );
      const selectedReticleCoordsData = this.getReticleCoordsData(
        reticleGridRectCoords,
        offsetX,
        offsetY,
        dieWidth,
        dieHeight,
        canvas,
        rotation,
        rowOffset,
        colOffset,
        1,
        1,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'selected',
      );
      allReticleBorderCoords = allReticleCoordsData.reticleBorderCoords;
      allReticleBGCoords = allReticleCoordsData.reticleBGCoords;
      underSelectionReticleBorderCoords = underSelectionReticleCoordsData.reticleBorderCoords;
      selectedReticleBorderCoords = selectedReticleCoordsData.reticleBorderCoords;
    }
    for (let i = 0, p = startRow; i < waferMaptTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMaptTestData[i].length; j += 1, q += colDirection) {
        startX = j * dieWidth + offsetX;
        startY = i * dieHeight + offsetY;
        endX = startX + dieWidth;
        endY = startY + dieHeight;
        // fix the border of dies

        const sw = dieWidth / dieWidthToStreetWidthRatio;
        const sh = dieHeight / dieHeightToStreetHeightRatio;

        dieBorderSX = startX + sw / 2;
        dieBorderSY = startY + sh / 2;
        dieBorderEX = endX - sw / 2;
        dieBorderEY = endY - sh / 2;
        // fix dieSubView

        if (waferMaptTestData[p][q]) {
          const topLeft = { x: dieBorderSX, y: dieBorderSY };
          const topRight = { x: dieBorderEX, y: dieBorderSY };
          const bottomLeft = { x: dieBorderSX, y: dieBorderEY };
          const bottomRight = { x: dieBorderEX, y: dieBorderEY };
          const ringCenter = { x: ringCenterX, y: ringCenterY };

          if (
            this.euclideanDistance(topLeft, ringCenter) <= ringRadius
            && this.euclideanDistance(topRight, ringCenter) <= ringRadius
            && this.euclideanDistance(bottomLeft, ringCenter) <= ringRadius
            && this.euclideanDistance(bottomRight, ringCenter) <= ringRadius
          ) {
            // inside the ring
            waferMaptTestData[p][q]!.location = DieLocation.INSIDE_RING;
          } else {
            if (
              this.euclideanDistance(topLeft, ringCenter) > ringRadius
            || this.euclideanDistance(topRight, ringCenter) > ringRadius
            || this.euclideanDistance(bottomLeft, ringCenter) > ringRadius
            || this.euclideanDistance(bottomRight, ringCenter) > ringRadius
            ) {
              // on the ring
              waferMaptTestData[p][q]!.location = DieLocation.ON_RING;
            }
            if (
              this.euclideanDistance(topLeft, ringCenter) > ringRadius
            && this.euclideanDistance(topRight, ringCenter) > ringRadius
            && this.euclideanDistance(bottomLeft, ringCenter) > ringRadius
            && this.euclideanDistance(bottomRight, ringCenter) > ringRadius
            ) {
              // out of the ring
              waferMaptTestData[p][q]!.location = DieLocation.OUTSIDE_RING;
            }
          }
          if (!waferMaptTestData[p][q]!.cropped && !waferMaptTestData[p][q]!.deleted) {
            dieCount += 1;
            const rotatedDieCoords = this.rotatedCoords([startX, startY, endX, startY, startX, endY, startX, endY, endX, startY, endX, endY], canvas, rotation);
            const rotatedDieBorderCoords = this.rotatedCoords([dieBorderSX, dieBorderSY, dieBorderEX, dieBorderSY, dieBorderSX, dieBorderEY, dieBorderSX, dieBorderEY, dieBorderEX, dieBorderSY, dieBorderEX, dieBorderEY], canvas, rotation);
            const reticleCoords: number[] = [
              rotatedDieCoords[0].x, rotatedDieCoords[0].y,
              rotatedDieCoords[1].x, rotatedDieCoords[1].y,
              rotatedDieCoords[2].x, rotatedDieCoords[2].y,
              rotatedDieCoords[3].x, rotatedDieCoords[3].y,
              rotatedDieCoords[5].x, rotatedDieCoords[5].y,
              rotatedDieCoords[4].x, rotatedDieCoords[4].y,
            ];

            const dieBorderCoords: number[] = [
              rotatedDieBorderCoords[0].x, rotatedDieBorderCoords[0].y,
              rotatedDieBorderCoords[1].x, rotatedDieBorderCoords[1].y,
              rotatedDieBorderCoords[2].x, rotatedDieBorderCoords[2].y,
              rotatedDieBorderCoords[3].x, rotatedDieBorderCoords[3].y,
              rotatedDieBorderCoords[4].x, rotatedDieBorderCoords[4].y,
              rotatedDieBorderCoords[5].x, rotatedDieBorderCoords[5].y,
            ];

            allDiesCoords.push(...reticleCoords);
            if (waferMaptTestData[p][q]!.underSelection) {
              this.selectionBinColorMapping(
                this.getColor(isWaferControlMap, waferMaptTestData[p][q]!, dieTypes, waferMaptTestData[p][q]!.binColor!, dieTypeField, dieColorType, currZone, zoneColors),
                dieColorMapSelection,
                dieBorderMapSelection,
                reticleCoords,
                dieBorderCoords,
              );
            } else if (!waferMaptTestData[p][q]!.underSelection) {
              if (waferMaptTestData[p][q]!.selected) {
                selectedDies.push(...reticleCoords);
                this.selectedBinColorMapping(
                  this.getColor(isWaferControlMap, waferMaptTestData[p][q]!, dieTypes, waferMaptTestData[p][q]!.binColor!, dieTypeField, dieColorType, currZone, zoneColors),
                  dieColorMapSelected,
                  dieBorderMapSelected,
                  reticleCoords,
                  dieBorderCoords,
                );
              } else {
                const newIndexI: number = i % this.waferMapVariables.dieSubView!.length;
                let newIndexJ: number = j % this.waferMapVariables.dieSubView![0].length;
                newIndexJ = subdieColStart + colDirection * newIndexJ;
                this.prepareDieSubViewCoords(
                  dieSubView,
                  dieSubViewMap,
                  canvas,
                  newIndexI,
                  newIndexJ,
                  startX,
                  startY,
                );
                this.binColorMapping(
                  this.getColor(
                    isWaferControlMap,
                    waferMaptTestData[p][q]!,
                    dieTypes,
                    waferMaptTestData[p][q]!.binColor!,
                    dieTypeField,
                    dieColorType,
                    currZone,
                    zoneColors,
                  ),
                  dieColorMap,
                  dieBorderMap,
                  reticleCoords,
                  dieBorderCoords,
                );
              }
            }

            if (waferMaptTestData[p][q]!.filtered) {
              selectedDies.push(...reticleCoords);
              this.selectedBinColorMapping(
                this.getColor(isWaferControlMap, waferMaptTestData[p][q]!, dieTypes, waferMaptTestData[p][q]!.binColor!, dieTypeField, dieColorType, currZone, zoneColors),
                dieColorMapSelected,
                dieBorderMapSelected,
                reticleCoords,
                dieBorderCoords,
              );
            }
            if (this.waferMapVariables.dieImageFlag) {
              dieImageCoords[i][j] = [...reticleCoords];
            }
          }
        }
        // XAnchor and YAnchor
        if (i === 0 && j === 0) {
          XAnchor = startX;
          YAnchor = startY;
        }
        // vertical line
        if (i === 0) {
          verticleGridLines.push(
            ...this.returnVerticalLine(
              startX,
              canvas,
            ),
          );
        }
        // Horizental Line
        if (j === 0) {
          horizontalGridLines.push(
            ...this.returnHorizentalLine(
              startY,
              canvas,
            ),
          );
        }
      }
      // Last vertical Line
      if (i === 0) {
        verticleGridLines.push(
          ...this.returnVerticalLine(
            endX,
            canvas,
          ),
        );
      }
    }
    // Last Horizental Line
    horizontalGridLines.push(
      ...this.returnHorizentalLine(
        endY,
        canvas,
      ),
    );
    this.waferMapVariables.allDiesCoords = allDiesCoords;
    if (this.waferMapVariables.radarDiesCoords === null) {
      this.waferMapVariables.radarDiesCoords = [...allDiesCoords];
    }
    this.waferMapVariables.horizontalGridLines = horizontalGridLines;
    this.waferMapVariables.verticleGridLines = verticleGridLines;
    this.waferMapVariables.selectedDies = selectedDies;
    this.waferMapVariables.filteredDies = filteredDies;
    this.waferMapVariables.dieColorMap = dieColorMap;
    this.waferMapVariables.dieBorderMap = dieBorderMap;
    this.waferMapVariables.dieColorMapSelection = dieColorMapSelection;
    this.waferMapVariables.dieBorderMapSelection = dieBorderMapSelection;
    this.waferMapVariables.dieColorMapSelected = dieColorMapSelected;
    this.waferMapVariables.dieBorderMapSelected = dieBorderMapSelected;
    this.waferMapVariables.XAnchor = XAnchor;
    this.waferMapVariables.YAnchor = YAnchor;
    this.waferMapVariables.dieCount = dieCount;
    this.waferMapVariables.dieSubViewMap = dieSubViewMap;
    this.waferMapVariables.scaledWaferWidth = this.waferMapVariables.scaledDieWidth
      * this.waferMapVariables.waferMapTestData[0].length;
    this.waferMapVariables.scaledWaferHeight = this.waferMapVariables.scaledDieHeight
      * this.waferMapVariables.waferMapTestData.length;
    this.waferMapVariables.dieImageCoords = dieImageCoords;
    this.waferMapVariables.waferBgImageCoords = waferBgImageCoords;
    this.waferMapVariables.ringCoords = ringCoords;
    this.waferMapVariables.radialZoneRingCoords = radialZoneRingCoords;
    this.waferMapVariables.verticalZoneCoords = verticalZoneCoords;
    this.waferMapVariables.horizontalZoneCoords = horizontalZoneCoords;
    this.waferMapVariables.groupedZoneCoords = groupedZoneCoords;
    this.waferMapVariables.waferCenterCoords = waferCenterCoords;
    this.waferMapVariables.referenceReticleBorderCoords = referenceReticleBorderCoords;
    this.waferMapVariables.allReticleBorderCoords = allReticleBorderCoords;
    this.waferMapVariables.allReticleBGCoords = allReticleBGCoords;
    this.waferMapVariables.underSelectionReticleBorderCoords = underSelectionReticleBorderCoords;
    this.waferMapVariables.selectedReticleBorderCoords = selectedReticleBorderCoords;
    this.waferMapVariables.reticleReferenceCoords = reticleReferenceCoords;
    if (this.waferMapVariables.reticleReferencePoint) {
      this.waferMapVariables.reticleOffsetCoords = this.getReticleOffsetLineCoords(canvas, rotation, this.waferMapVariables.waferCenterPoint, this.waferMapVariables.reticleReferencePoint);
    }
  };

  getFirstTickInViewPortXYPos = (anchor: number, dieLength: number, tickOffset: number, axisStart: number, axisIncrement: number, isFlipped: boolean) => {
    const anchorOffset = isFlipped ? (axisStart + 1) * dieLength : 0;
    const tickPosAtYAnchor = (anchor + anchorOffset + axisIncrement * (dieLength / 2));
    const tickDistance = dieLength * tickOffset;
    const noOfTicksInViewPortBeforeAnchorTick = Math.floor(tickPosAtYAnchor / tickDistance);
    return tickPosAtYAnchor - noOfTicksInViewPortBeforeAnchorTick * tickDistance;
  };

  getReticleAxisReferenceUsingMin = (reticleGridRectCoords: ReticleGridRectCoords, axis: 'x' | 'y'): number => {
    const reticles = Object.keys(reticleGridRectCoords);
    let minStart = Number.MAX_VALUE;
    for (let i = 0; i < reticles.length; i += 1) {
      const reticleRect: RectCoord = JSON.parse(reticles[i]);
      const reticleStartVal = axis === 'x' ? reticleRect.startX : reticleRect.startY;
      if (reticleStartVal < minStart) {
        minStart = reticleStartVal;
      }
    }
    return minStart;
  };

  getReticleAxisReferenceUsingHomeDie = (reticleGridRectCoords: ReticleGridRectCoords, axis: 'x' | 'y', homeDieReticleNumber: number): number => {
    const {
      startRow, startCol, waferMapTestData, rowDirection, colDirection, dieTypes, reticleSize,
    } = this.waferMapVariables;
    const homeDieId = UtilityFunctions.getDieTypeIdFromName('Home Die', dieTypes.dieType);
    if (!homeDieId) {
      return this.getReticleAxisReference(reticleGridRectCoords, axis);
    }
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        const dieData = waferMapTestData[p] === undefined ? undefined : waferMapTestData[p][q];
        if (dieData && dieData.dieType === homeDieId) {
          const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, reticleSize);
          if (axis === 'x') {
            return reticleRectCoordOfCurrDie.startX - homeDieReticleNumber * reticleSize.x;
          } else {
            return reticleRectCoordOfCurrDie.startY - homeDieReticleNumber * reticleSize.y;
          }
        }
      }
    }
    return this.getReticleAxisReference(reticleGridRectCoords, axis);
  };

  getReticleAxisReference = (reticleGridRectCoords: ReticleGridRectCoords, axis: 'x' | 'y', useMin = true, homeDieReticleNumber = 0): number => {
    if (useMin) {
      return this.getReticleAxisReferenceUsingMin(reticleGridRectCoords, axis);
    } else {
      return this.getReticleAxisReferenceUsingHomeDie(reticleGridRectCoords, axis, homeDieReticleNumber);
    }
  };

  getN9s = (n: number) => {
    return +('9'.repeat(n));
  };

  getFirstReticleTickInViewPortXYPos = (
    reticleSize: number,
    leastStartReticleOnOverlayingReticle: { start: number, end: number },
    offset: number, // row or col offset
    anchor: number,
    dieLength: number,
    tickPosition: 'center' | 'start',
    axisStart: number,
    axisIncrement: number,
    isFlipped: boolean,
    tickOffset: number,
  ) => {
    let tickPosAtAnchor = -1;
    let anhorReticleMidGridWithoutOffset = -1;

    const anchorOffset = isFlipped ? (axisStart + 1) * dieLength : 0;

    if (tickPosition === 'start') {
      tickPosAtAnchor = anchor + anchorOffset;
      anhorReticleMidGridWithoutOffset = leastStartReticleOnOverlayingReticle.start - offset;
    } else if (tickPosition === 'center') {
      tickPosAtAnchor = (anchor + anchorOffset + axisIncrement * (dieLength / 2));
      anhorReticleMidGridWithoutOffset = ((leastStartReticleOnOverlayingReticle.start + leastStartReticleOnOverlayingReticle.end) / 2) - offset;
    }
    const anhorReticleMidPos = tickPosAtAnchor + axisIncrement * anhorReticleMidGridWithoutOffset * dieLength;
    const reticleLength = reticleSize * dieLength;

    const tickDistance = tickOffset === 1 ? reticleLength : reticleLength * Math.ceil(tickOffset / reticleSize);
    const noOfReticleTicksInViewPortBeforeAnchorReticleTick = Math.floor(anhorReticleMidPos / tickDistance);
    return {
      anhorReticleMidPos,
      tickDistance,
      reticleLength,
      firstReticleTickInViewPort: anhorReticleMidPos - noOfReticleTicksInViewPortBeforeAnchorReticleTick * tickDistance,
    };
  };

  renderTickLinesAndMarks = (
    waferMaptTestData: WaferMapTestData,
    dieWidth: number,
    dieHeight: number,
    offsetX: number,
    offsetY: number,
  ) => {
    const tickLines: number[] = [];
    const binNumX: number[] = [];
    const binNumY: number[] = [];
    const {
      rowAxisStart, rowAxisIncrement, colAxisStart, colAxisIncrement, angleInDegrees, referenceReticleGridRectCoords, tickTextCtx, overlayReticle,
      ratioWidth, tickTextFont, rowOffset, ratioHeight, outerRatioWidth, outerRatioHeight, viewPortWidth, viewPortHeight, tickLineSize, rowFlip,
      viewPortXDiff, viewPortYDiff, rotation, reticleXAxisReference, colOffset, reticleSize, tickOffsetX, tickOffsetY, colFlip,
      reticleYAxisReference, isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled,
    } = this.waferMapVariables;

    this.clearTickText();
    tickTextCtx.font = `${tickTextFont}px Arial`;
    let xyVal = { xVal: 0, yVal: 0 };

    for (let j = 0, q = colAxisStart; j < waferMaptTestData[0].length; j += 1, q += colAxisIncrement) {
      const sx = j * dieWidth + offsetX;
      const vtx = sx + (this.waferMapVariables.scaledDieWidth / 2);
      binNumX.push((vtx / ratioWidth));
    }
    for (let i = 0, p = rowAxisStart; i < waferMaptTestData.length; i += 1, p += rowAxisIncrement) {
      const sy = i * dieHeight + offsetY;
      const hty = sy + (this.waferMapVariables.scaledDieHeight / 2);
      binNumY.push((hty / ratioHeight));
    }

    for (
      let i = this.getFirstTickInViewPortXYPos(offsetX, dieWidth, tickOffsetX, colAxisStart, colAxisIncrement, colFlip === ColFlip.Inverted);
      (
        (!isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetX === 1 && tickOffsetY === 1)))
        || (isDieCoordinatesSystemEnabled && !isReticleCoordinatesSystemEnabled)
        || (isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetX === 1 && tickOffsetY === 1)))
      )
      && i <= viewPortWidth;
      i += (dieWidth * tickOffsetX)) {
      // ****************** Die tick marks ******************
      // ****************** Die tick marks - Top ******************
      const topTickCoords = this.rotatedCoords(
        [
          (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
          (0 / ratioHeight + viewPortYDiff) * outerRatioHeight - tickLineSize,
          (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
          (0 / ratioHeight + viewPortYDiff) * outerRatioHeight + tickLineSize + 1],
        {
          width: viewPortWidth,
          height: viewPortHeight,
        },
        rotation,
      );
      tickLines.push(...[topTickCoords[0].x, topTickCoords[0].y, topTickCoords[1].x, topTickCoords[1].y]);

      // ****************** Die tick marks - Bottom ******************
      const bottomTickCoords = this.rotatedCoords(
        [
          (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
          (viewPortHeight / ratioHeight + viewPortYDiff) * outerRatioHeight - tickLineSize - 1,
          (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
          (viewPortHeight / ratioHeight + viewPortYDiff) * outerRatioHeight + tickLineSize],
        {
          width: viewPortWidth,
          height: viewPortHeight,
        },
        rotation,
      );
      tickLines.push(...[bottomTickCoords[0].x, bottomTickCoords[0].y, bottomTickCoords[1].x, bottomTickCoords[1].y]);

      // ****************** Die tick text ******************
      const positionBasedOnTextCanvas = (i / ratioWidth + viewPortXDiff);
      const xAnchorOffset = colFlip === ColFlip.Inverted ? (colAxisStart + 1) * dieWidth : 0;
      const tickGridValueWithOffset = Math.round(((offsetX + xAnchorOffset + colAxisIncrement * (dieWidth / 2)) - i) / dieWidth);
      const tickValue = colOffset - colAxisIncrement * tickGridValueWithOffset;
      const fontWidth: number = tickTextCtx.measureText(`${tickValue}`).width;
      const fontWidthBasedOnDigits: number = tickTextCtx.measureText(`${this.getN9s(4 - tickValue.toString().length)}`).width;
      const fontHeight: number = tickTextFont;
      let reticleTickValue = -1;
      let reticleFontWidth = -1;
      let reticleFontWidthBasedOnDigits = -1;
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        reticleTickValue = (reticleSize.x - ((referenceReticleGridRectCoords.startX - tickValue) % reticleSize.x)) % reticleSize.x;
        reticleFontWidth = tickTextCtx.measureText(`${reticleTickValue}`).width;
        reticleFontWidthBasedOnDigits = tickTextCtx.measureText(`${this.getN9s(4 - reticleTickValue.toString().length)}`).width;
      }
      // ****************** Die tick text - Top ******************
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
        tickTextCtx.save();
        tickTextCtx.translate(positionBasedOnTextCanvas, (0 / ratioHeight + viewPortYDiff));
        tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
        tickTextCtx.translate(-(positionBasedOnTextCanvas), -((0 / ratioHeight + viewPortYDiff)));
      }

      if (isDieCoordinatesSystemEnabled) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'top',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
      }
      // ****************** Reticle Sub Col tick text - Top ******************
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'top',
          positionBasedOnTextCanvas,
          reticleFontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          reticleFontWidthBasedOnDigits,
          '2',
        );
        tickTextCtx.fillStyle = '#0000FF';
        tickTextCtx.fillText(
          `${reticleTickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        tickTextCtx.fillStyle = '#000000';
      }

      // ****************** Die tick text - Bottom ******************
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
        tickTextCtx.restore();
        tickTextCtx.save();
        tickTextCtx.translate(positionBasedOnTextCanvas, (viewPortHeight / ratioHeight + viewPortYDiff));
        tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
        tickTextCtx.translate(-(positionBasedOnTextCanvas), -((viewPortHeight / ratioHeight + viewPortYDiff)));
      }
      if (isDieCoordinatesSystemEnabled) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'bottom',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
      }
      // ****************** Reticle Sub Col tick text - Bottom ******************
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'bottom',
          positionBasedOnTextCanvas,
          reticleFontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          reticleFontWidthBasedOnDigits,
          '2',
        );
        tickTextCtx.fillStyle = '#0000FF';
        tickTextCtx.fillText(
          `${reticleTickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        tickTextCtx.fillStyle = '#000000';
      }
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) tickTextCtx.restore();
    }

    for (
      let i = this.getFirstTickInViewPortXYPos(offsetY, dieHeight, tickOffsetY, rowAxisStart, rowAxisIncrement, rowFlip === RowFlip.Inverted);
      (
        (!isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetY === 1 && tickOffsetX === 1)))
        || (isDieCoordinatesSystemEnabled && !isReticleCoordinatesSystemEnabled)
        || (isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetY === 1 && tickOffsetX === 1)))
      )
      && i <= viewPortHeight;
      i += (dieHeight * tickOffsetY)) {
      // ****************** Die tick marks ******************
      // ****************** Die tick marks - Left  ******************
      const leftTickCoords = this.rotatedCoords(
        [
          (0 / ratioWidth + viewPortXDiff) * outerRatioWidth - tickLineSize,
          (i / ratioHeight + viewPortYDiff) * outerRatioHeight,
          (0 / ratioWidth + viewPortXDiff) * outerRatioWidth + tickLineSize,
          (i / ratioHeight + viewPortYDiff) * outerRatioHeight],
        {
          width: viewPortWidth,
          height: viewPortHeight,
        },
        rotation,
      );
      tickLines.push(...[leftTickCoords[0].x, leftTickCoords[0].y, leftTickCoords[1].x, leftTickCoords[1].y]);

      // ****************** Die tick marks - Right  ******************
      const rightTickCoords = this.rotatedCoords(
        [
          (viewPortWidth / ratioWidth + viewPortXDiff) * outerRatioWidth - tickLineSize,
          (i / ratioHeight + viewPortYDiff) * outerRatioHeight,
          (viewPortWidth / ratioWidth + viewPortXDiff) * outerRatioWidth + tickLineSize,
          (i / ratioHeight + viewPortYDiff) * outerRatioHeight],
        {
          width: viewPortWidth,
          height: viewPortHeight,
        },
        rotation,
      );
      tickLines.push(...[rightTickCoords[0].x, rightTickCoords[0].y, rightTickCoords[1].x, rightTickCoords[1].y]);

      // ****************** Die tick text ******************
      const positionBasedOnTextCanvas = (i / ratioHeight + viewPortYDiff);
      const yAnchorOffset = rowFlip === RowFlip.Inverted ? (rowAxisStart + 1) * dieHeight : 0;
      const tickGridValueWithOffset = Math.round(((offsetY + yAnchorOffset + rowAxisIncrement * (dieHeight / 2)) - i) / dieHeight);
      const tickValue = rowOffset - rowAxisIncrement * tickGridValueWithOffset;
      const fontWidth: number = tickTextCtx.measureText(`${tickValue}`).width;
      const fontWidthBasedOnDigits: number = tickTextCtx.measureText(`${this.getN9s(4 - tickValue.toString().length)}`).width;
      const fontHeight: number = tickTextFont;
      let reticleTickValue = -1;
      let reticleFontWidth = -1;
      let reticleFontWidthBasedOnDigits = -1;
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        reticleTickValue = (reticleSize.y - ((referenceReticleGridRectCoords.startY - tickValue) % reticleSize.y)) % reticleSize.y;
        reticleFontWidth = tickTextCtx.measureText(`${reticleTickValue}`).width;
        reticleFontWidthBasedOnDigits = tickTextCtx.measureText(`${this.getN9s(4 - reticleTickValue.toString().length)}`).width;
      }

      // ****************** Die tick text - Left ******************
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
        tickTextCtx.save();
        tickTextCtx.translate((0 / ratioWidth + viewPortXDiff), (positionBasedOnTextCanvas));
        tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
        tickTextCtx.translate(-((0 / ratioWidth + viewPortXDiff)), -(positionBasedOnTextCanvas));
      }
      if (isDieCoordinatesSystemEnabled) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'left',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
      }
      // ****************** Reticle Sub Row tick text - Left ******************
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'left',
          positionBasedOnTextCanvas,
          reticleFontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          reticleFontWidthBasedOnDigits,
          '2',
        );
        tickTextCtx.fillStyle = '#0000FF';
        tickTextCtx.fillText(
          `${reticleTickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        tickTextCtx.fillStyle = '#000000';
      }
      // ****************** Die tick text - Right ******************
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
        tickTextCtx.restore();
        tickTextCtx.save();
        tickTextCtx.translate((viewPortWidth / ratioWidth + viewPortXDiff), (positionBasedOnTextCanvas));
        tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
        tickTextCtx.translate(-((viewPortWidth / ratioWidth + viewPortXDiff)), -(positionBasedOnTextCanvas));
      }
      if (isDieCoordinatesSystemEnabled) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'right',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
      }
      // ****************** Reticle Sub Row tick text - Right ******************
      if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'right',
          positionBasedOnTextCanvas,
          reticleFontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          reticleFontWidthBasedOnDigits,
          '2',
        );
        tickTextCtx.fillStyle = '#0000FF';
        tickTextCtx.fillText(
          `${reticleTickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        tickTextCtx.fillStyle = '#000000';
      }
      if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) tickTextCtx.restore();
    }

    // ****************** Reticle tick marks and text - Top and Bottom ******************
    if (isReticleCoordinatesSystemEnabled && overlayReticle) {
      const reticleTickMarksData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.x,
        { start: reticleXAxisReference, end: reticleXAxisReference + reticleSize.x - 1 },
        colOffset,
        offsetX,
        dieWidth,
        tickOffsetX === 1 ? 'start' : 'center',
        colAxisStart,
        colAxisIncrement,
        colFlip === ColFlip.Inverted,
        tickOffsetX,
      );
      for (let i = reticleTickMarksData.firstReticleTickInViewPort; i <= viewPortWidth; i += reticleTickMarksData.tickDistance) {
        // ****************** Reticle tick marks - Top ******************
        const topTickCoords = this.rotatedCoords(
          [
            (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
            (0 / ratioHeight + viewPortYDiff) * outerRatioHeight - (tickLineSize * (tickOffsetX === 1 && tickOffsetY === 1 ? 12 : 4)),
            (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
            (0 / ratioHeight + viewPortYDiff) * outerRatioHeight + (tickLineSize * 4) + 1],
          {
            width: viewPortWidth,
            height: viewPortHeight,
          },
          rotation,
        );
        tickLines.push(...[topTickCoords[0].x, topTickCoords[0].y, topTickCoords[1].x, topTickCoords[1].y]);

        // ****************** Reticle tick marks - Bottom ******************
        const bottomTickCoords = this.rotatedCoords(
          [
            (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
            (viewPortHeight / ratioHeight + viewPortYDiff) * outerRatioHeight - (tickLineSize * 4) - 1,
            (i / ratioWidth + viewPortXDiff) * outerRatioWidth,
            (viewPortHeight / ratioHeight + viewPortYDiff) * outerRatioHeight + (tickLineSize * (tickOffsetX === 1 && tickOffsetY === 1 ? 12 : 4))],
          {
            width: viewPortWidth,
            height: viewPortHeight,
          },
          rotation,
        );
        tickLines.push(...[bottomTickCoords[0].x, bottomTickCoords[0].y, bottomTickCoords[1].x, bottomTickCoords[1].y]);
      }

      const reticleTickTextData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.x,
        { start: reticleXAxisReference, end: reticleXAxisReference + reticleSize.x - 1 },
        colOffset,
        offsetX,
        dieWidth,
        'center',
        colAxisStart,
        colAxisIncrement,
        colFlip === ColFlip.Inverted,
        tickOffsetX,
      );
      tickTextCtx.fillStyle = '#FF0000';
      for (let i = reticleTickTextData.firstReticleTickInViewPort; i <= viewPortWidth; i += reticleTickTextData.tickDistance) {
        const tickValue = colAxisIncrement * Math.round((i - reticleTickTextData.anhorReticleMidPos) / reticleTickTextData.reticleLength);
        const positionBasedOnTextCanvas = (i / ratioWidth + viewPortXDiff);
        // ****************** Reticle tick text - Top ******************
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
          tickTextCtx.save();
          tickTextCtx.translate(positionBasedOnTextCanvas, (0 / ratioHeight + viewPortYDiff));
          tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
          tickTextCtx.translate(-(positionBasedOnTextCanvas), -((0 / ratioHeight + viewPortYDiff)));
        }
        const fontWidth: number = tickTextCtx.measureText(`${tickValue}`).width;
        const fontWidthBasedOnDigits: number = tickTextCtx.measureText(`${this.getN9s(4 - tickValue.toString().length)}`).width;
        const fontHeight: number = tickTextFont;
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'top',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
          '3',
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );

        // ****************** Reticle tick text - Bottom ******************
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
          tickTextCtx.restore();
          tickTextCtx.save();
          tickTextCtx.translate(positionBasedOnTextCanvas, (viewPortHeight / ratioHeight + viewPortYDiff));
          tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
          tickTextCtx.translate(-(positionBasedOnTextCanvas), -((viewPortHeight / ratioHeight + viewPortYDiff)));
        }
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'bottom',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
          '3',
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) tickTextCtx.restore();
      }
      tickTextCtx.fillStyle = '#000000';
    }

    // ****************** Reticle tick marks and text - Left and Right ******************
    if (isReticleCoordinatesSystemEnabled && overlayReticle) {
      const reticleTickMarksData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.y,
        { start: reticleYAxisReference, end: reticleYAxisReference + reticleSize.y - 1 },
        rowOffset,
        offsetY,
        dieHeight,
        tickOffsetY === 1 ? 'start' : 'center',
        rowAxisStart,
        rowAxisIncrement,
        rowFlip === RowFlip.Inverted,
        tickOffsetY,
      );
      for (let i = reticleTickMarksData.firstReticleTickInViewPort; i <= viewPortHeight; i += reticleTickMarksData.tickDistance) {
        // ****************** Reticle tick marks - Left ******************
        const leftTickCoords = this.rotatedCoords(
          [
            (0 / ratioWidth + viewPortXDiff) * outerRatioWidth - (tickLineSize * (tickOffsetY === 1 && tickOffsetX === 1 ? 12 : 4)),
            (i / ratioHeight + viewPortYDiff) * outerRatioHeight,
            (0 / ratioWidth + viewPortXDiff) * outerRatioWidth + (tickLineSize * 4),
            (i / ratioHeight + viewPortYDiff) * outerRatioHeight],
          {
            width: viewPortWidth,
            height: viewPortHeight,
          },
          rotation,
        );
        tickLines.push(...[leftTickCoords[0].x, leftTickCoords[0].y, leftTickCoords[1].x, leftTickCoords[1].y]);

        // ****************** Reticle tick marks - Right  ******************
        const rightTickCoords = this.rotatedCoords(
          [
            (viewPortWidth / ratioWidth + viewPortXDiff) * outerRatioWidth - (tickLineSize * 4),
            (i / ratioHeight + viewPortYDiff) * outerRatioHeight,
            (viewPortWidth / ratioWidth + viewPortXDiff) * outerRatioWidth + (tickLineSize * (tickOffsetY === 1 && tickOffsetX === 1 ? 12 : 4)),
            (i / ratioHeight + viewPortYDiff) * outerRatioHeight],
          {
            width: viewPortWidth,
            height: viewPortHeight,
          },
          rotation,
        );
        tickLines.push(...[rightTickCoords[0].x, rightTickCoords[0].y, rightTickCoords[1].x, rightTickCoords[1].y]);
      }

      const reticleTickTextData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.y,
        { start: reticleYAxisReference, end: reticleYAxisReference + reticleSize.y - 1 },
        rowOffset,
        offsetY,
        dieHeight,
        'center',
        rowAxisStart,
        rowAxisIncrement,
        rowFlip === RowFlip.Inverted,
        tickOffsetY,
      );
      tickTextCtx.fillStyle = '#FF0000';
      for (let i = reticleTickTextData.firstReticleTickInViewPort; i <= viewPortHeight; i += reticleTickTextData.tickDistance) {
        const tickValue = rowAxisIncrement * Math.round((i - reticleTickTextData.anhorReticleMidPos) / reticleTickTextData.reticleLength);
        const positionBasedOnTextCanvas = (i / ratioHeight + viewPortYDiff);
        // ****************** Reticle tick text - Left ******************
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
          tickTextCtx.save();
          tickTextCtx.translate((0 / ratioWidth + viewPortXDiff), (positionBasedOnTextCanvas));
          tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
          tickTextCtx.translate(-((0 / ratioWidth + viewPortXDiff)), -(positionBasedOnTextCanvas));
        }
        const fontWidth: number = tickTextCtx.measureText(`${tickValue}`).width;
        const fontWidthBasedOnDigits: number = tickTextCtx.measureText(`${this.getN9s(4 - tickValue.toString().length)}`).width;
        const fontHeight: number = tickTextFont;
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'left',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
          '3',
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );

        // ****************** Reticle tick text - Right ******************
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) {
          tickTextCtx.restore();
          tickTextCtx.save();
          tickTextCtx.translate((viewPortWidth / ratioWidth + viewPortXDiff), (positionBasedOnTextCanvas));
          tickTextCtx.rotate((360 - angleInDegrees) * (Math.PI / 180));
          tickTextCtx.translate(-((viewPortWidth / ratioWidth + viewPortXDiff)), -(positionBasedOnTextCanvas));
        }
        xyVal = this.getTickTextXYPos(
          angleInDegrees,
          'right',
          positionBasedOnTextCanvas,
          fontWidth,
          fontHeight,
          ratioWidth,
          ratioHeight,
          viewPortXDiff,
          viewPortYDiff,
          viewPortWidth,
          viewPortHeight,
          fontWidthBasedOnDigits,
          '3',
        );
        tickTextCtx.fillText(
          `${tickValue}`,
          xyVal.xVal,
          xyVal.yVal,
        );
        if (angleInDegrees === 90 || angleInDegrees === 270 || angleInDegrees === 180) tickTextCtx.restore();
      }
      tickTextCtx.fillStyle = '#000000';
    }

    this.renderTickLines(tickLines);
    return {
      tickLines, binNumX, binNumY,
    };
  };

  drawShape = (
    gl: WebGL2RenderingContext,
    program: WebGLProgram,
    coords: number[],
    color: number[],
    drawingMode: number,
    viewport: Viewport | null = this.waferMapVariables.viewport,
    considerViewport = true,
  ) => {
    // Step 3
    const data = new Float32Array(coords);
    const buffer: WebGLBuffer = this.createAndBindBuffer(
      gl,
      gl.ARRAY_BUFFER,
      gl.STATIC_DRAW,
      data,
    );
    // Step 4
    gl.useProgram(program);
    this.linkGPUAndCPU(
      {
        program,
        buffer,
        dims: 2,
        gpuVariable: 'position',
      },
      gl,
    );

    const reticleColor: WebGLUniformLocation = gl.getUniformLocation(program, 'reticleColor') as WebGLUniformLocation;
    gl.uniform4fv(reticleColor, color);

    // Step 5
    if (considerViewport && viewport !== null) {
      gl.viewport(viewport.x, viewport.y, viewport.width, viewport.height);
    }
    gl.drawArrays(drawingMode, 0, coords.length / 2);
  };

  renderRadarPointer = (boxCoord: { startArrayX: number, startArrayY: number, endArrayX: number, endArrayY: number }): void => {
    const { rotation, gl } = this.waferMapVariables;
    const startX = boxCoord.startArrayX * this.waferMapVariables.intialScaledDieWidth
    + this.waferMapVariables.XAnchorIntialPosition;
    const startY = boxCoord.startArrayY * this.waferMapVariables.intialScaledDieHeight
    + this.waferMapVariables.YAnchorIntialPosition;
    const endX = (boxCoord.endArrayX + 1)
      * this.waferMapVariables.intialScaledDieWidth
    + this.waferMapVariables.XAnchorIntialPosition;
    const endY = (boxCoord.endArrayY + 1)
      * this.waferMapVariables.intialScaledDieHeight
    + this.waferMapVariables.YAnchorIntialPosition;
    const v = this.rotatedCoords([startX, startY, endX, startY, startX, endY, startX, endY, endX, startY, endX, endY], {
      width: this.waferMapVariables.gl!.canvas.width,
      height: this.waferMapVariables.gl!.canvas.height,
    }, rotation);
    this.waferMapVariables.radarPointer = [
      v[0].x, v[0].y,
      v[1].x, v[1].y,
      v[2].x, v[2].y,
      v[3].x, v[3].y,
      v[4].x, v[4].y,
      v[5].x, v[5].y,
    ];
    const { radarViewPort: rvp } = this.waferMapVariables;
    this.waferMapVariables.gl!.viewport(rvp.x, rvp.y, rvp.width, rvp.height);
    this.drawRectBorder(this.waferMapVariables.gl!, {
      startX: v[0].x,
      startY: -v[0].y,
      endX: v[5].x,
      endY: -v[5].y,
    });
    if (gl) {
      gl.viewport(rvp.x, rvp.y, rvp.width, rvp.height);
      if (this.waferMapVariables.showGridBordersAndTicks) this.drawGridBorder(this.waferMapVariables.gl!);
    }
  };

  clearWaferMap = (): void => {
    const { gl, tickViewPort } = this.waferMapVariables;
    if (gl === null) return;
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(tickViewPort.x, tickViewPort.y, tickViewPort.width, tickViewPort.height);
    gl.clear(
      // eslint-disable-next-line no-bitwise
      gl!.DEPTH_BUFFER_BIT
      | gl!.COLOR_BUFFER_BIT,
    );
    gl.disable(gl.SCISSOR_TEST);
  };

  hashColorToRGB = (hash: string): number[] => {
    return [parseInt(hash.substring(1, 3), 16) / 255.0,
      parseInt(hash.substring(3, 5), 16) / 255.0,
      parseInt(hash.substring(5, 7), 16) / 255.0];
  };

  renderRadarView = () => {
    radarView(this);
  };

  prepareAndRenderRadarPointer = () => {
    this.renderRadarPointer(this.returnBoxArrayIndicesForRadar(
      0,
      0,
      this.waferMapVariables.viewPortWidth,
      this.waferMapVariables.viewPortHeight,
    ));
  };

  getCurrentWaferBgImage = (notchPosition: NotchPosition, wcmWaferDiameter: number) => {
    if (wcmWaferDiameter > 0 && UtilityFunctions.isWaferFlat(wcmWaferDiameter)) {
      if (notchPosition === NotchPosition.DOWN) {
        return this.waferMapVariables.waferBgImageBottomFlat;
      } else if (notchPosition === NotchPosition.LEFT) {
        return this.waferMapVariables.waferBgImageLeftFlat;
      } else if (notchPosition === NotchPosition.RIGHT) {
        return this.waferMapVariables.waferBgImageRightFlat;
      } else {
        return this.waferMapVariables.waferBgImageTopFlat;
      }
    } else {
      if (notchPosition === NotchPosition.DOWN) {
        return this.waferMapVariables.waferBgImageBottom;
      } else if (notchPosition === NotchPosition.LEFT) {
        return this.waferMapVariables.waferBgImageLeft;
      } else if (notchPosition === NotchPosition.RIGHT) {
        return this.waferMapVariables.waferBgImageRight;
      } else {
        return this.waferMapVariables.waferBgImageTop;
      }
    }
  };

  intiateRendering = (gl: WebGL2RenderingContext, XAnchor: number, YAnchor: number): void => {
    if (this.waferMapVariables.showRadarOnly) return;
    this.clearWaferMap();
    const {
      startRow, rowDirection, startCol, colDirection, isWaferControlMap, notchPosition, isManualServiceWafer, wcmWaferDiameter,
    } = this.waferMapVariables;
    const waferBgImage = this.getCurrentWaferBgImage(notchPosition, wcmWaferDiameter);

    if (this.waferMapVariables.showRotationNotch && waferBgImage !== null) {
      this.makeProgramForTextures();
      this.renderDieImageTextures(this.waferMapVariables.waferBgImageCoords, waferBgImage);
    }
    if (this.waferMapVariables.dieImageFlag) {
      if (this.waferMapVariables.isImagePromiseResolved) {
        let retIndex = 0;
        this.makeProgramForTextures();
        for (let i = 0, p = startRow; i < this.waferMapVariables.waferMapTestData.length; i += 1, p += rowDirection) {
          for (let j = 0, q = startCol; j < this.waferMapVariables.waferMapTestData[i].length; j += 1, q += colDirection, retIndex += 1) {
            if (this.waferMapVariables.waferMapTestData[p][q]) {
              if (this.waferMapVariables.dieImages[p][q] !== null) {
                this.renderDieImageTextures(this.waferMapVariables.dieImageCoords[i][j], this.waferMapVariables.dieImages[p][q]!);
              }
            }
          }
        }
      }
    } else {
      if (!isWaferControlMap || isManualServiceWafer) {
        Object.keys(this.waferMapVariables.dieColorMap).forEach((key) => {
          const [r, g, b] = this.hashColorToRGB(key);
          this.drawShape(
            gl,
            this.waferMapVariables.program!,
            this.waferMapVariables.dieColorMap[key],
            [r, g, b, this.waferMapVariables.binColorOpacity],
            gl.TRIANGLES,
          );
        });
        Object.keys(this.waferMapVariables.dieColorMapSelection).forEach((key) => {
          const [r, g, b] = this.hashColorToRGB(key);
          this.drawShape(
            gl,
            this.waferMapVariables.program!,
            this.waferMapVariables.dieColorMapSelection[key],
            [r, g, b, this.waferMapVariables.selectionBoxOpacity],
            gl.TRIANGLES,
          );
        });
        Object.keys(this.waferMapVariables.dieColorMapSelected).forEach((key) => {
          const [r, g, b] = this.hashColorToRGB(key);
          this.drawShape(
            gl,
            this.waferMapVariables.program!,
            this.waferMapVariables.dieColorMapSelected[key],
            [r / this.waferMapVariables.selectedDieDarknessFactor,
              g / this.waferMapVariables.selectedDieDarknessFactor,
              b / this.waferMapVariables.selectedDieDarknessFactor,
              this.waferMapVariables.selectedOpacity],
            gl.TRIANGLES,
          );
        });
      }
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.filteredDies,
        this.waferMapVariables.filteredDieColor,
        gl.TRIANGLES,
      );
      Object.keys(this.waferMapVariables.dieBorderMap).forEach((key) => {
        const [r, g, b] = this.hashColorToRGB(key);
        this.drawShape(
          gl,
          this.waferMapVariables.program!,
          this.waferMapVariables.dieBorderMap[key],
          [r, g, b, this.waferMapVariables.binColorOpacity],
          gl.TRIANGLES,
        );
      });
      Object.keys(this.waferMapVariables.dieBorderMapSelection).forEach((key) => {
        const [r, g, b] = this.hashColorToRGB(key);
        this.drawShape(
          gl,
          this.waferMapVariables.program!,
          this.waferMapVariables.dieBorderMapSelection[key],
          [r, g, b, this.waferMapVariables.selectionBoxOpacity],
          gl.TRIANGLES,
        );
      });
      Object.keys(this.waferMapVariables.dieBorderMapSelected).forEach((key) => {
        const [r, g, b] = this.hashColorToRGB(key);
        this.drawShape(
          gl,
          this.waferMapVariables.program!,
          this.waferMapVariables.dieBorderMapSelected[key],
          [r / this.waferMapVariables.selectedDieDarknessFactor,
            g / this.waferMapVariables.selectedDieDarknessFactor,
            b / this.waferMapVariables.selectedDieDarknessFactor,
            this.waferMapVariables.selectedOpacity],
          gl.TRIANGLES,
        );
      });
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.dieSubViewMap,
        [0.0, 0.0, 0.0, 0.3],
        gl.TRIANGLES,
      );
    }
    if (this.waferMapVariables.selectionCoord !== null) {
      const { viewport: vp } = this.waferMapVariables;
      this.waferMapVariables.gl!.viewport(vp.x, vp.y, vp.width, vp.height);
      this.drawRectBorder(this.waferMapVariables.gl!, this.waferMapVariables.selectionCoord);
    }
    if (this.waferMapVariables.radarPointerCoord !== null) {
      const { radarViewPort: rvp } = this.waferMapVariables;
      this.waferMapVariables.gl!.viewport(rvp.x, rvp.y, rvp.width, rvp.height);
      this.drawRectBorder(this.waferMapVariables.gl!, this.waferMapVariables.radarPointerCoord);
    }
    if (this.waferMapVariables.scaledDieWidth > 10 && this.waferMapVariables.showGridBordersAndTicks) {
      // FIXME: Use appropriate method to show hide grid lines. rectangular dies should be handled
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.verticleGridLines,
        this.waferMapVariables.gridLinesColor,
        gl.TRIANGLES,
      );
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.horizontalGridLines,
        this.waferMapVariables.gridLinesColor,
        gl.TRIANGLES,
      );
    }
    this.drawShape(
      gl,
      this.waferMapVariables.program!,
      this.waferMapVariables.ringCoords,
      [1.0, 0.0, 0.0, 1.0],
      gl.LINES,
    );
    this.drawShape(
      gl,
      this.waferMapVariables.program!,
      this.waferMapVariables.radialZoneRingCoords,
      this.waferMapVariables.zoneBoundaryColor,
      gl.POINTS,
    );
    if (this.waferMapVariables.overlayReticle) {
      if (this.waferMapVariables.showReticleText) {
        this.drawShape(
          gl,
          this.waferMapVariables.program!,
          this.waferMapVariables.allReticleBGCoords,
          this.waferMapVariables.allReticleBGColor,
          gl.TRIANGLES,
        );
      }
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.allReticleBorderCoords,
        this.waferMapVariables.allReticleBorderColor,
        gl.TRIANGLES,
      );
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.referenceReticleBorderCoords,
        this.waferMapVariables.referenceReticleBorderColor,
        gl.LINES,
      );
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.selectedReticleBorderCoords,
        this.waferMapVariables.selectedReticleBorderColor,
        gl.TRIANGLES,
      );
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.underSelectionReticleBorderCoords,
        this.waferMapVariables.underSelectionReticleBorderColor,
        gl.TRIANGLES,
      );
    }
    this.drawShape(
      gl,
      this.waferMapVariables.program!,
      this.waferMapVariables.verticalZoneCoords,
      this.waferMapVariables.zoneBoundaryColor,
      gl.TRIANGLES,
    );
    this.drawShape(
      gl,
      this.waferMapVariables.program!,
      this.waferMapVariables.horizontalZoneCoords,
      this.waferMapVariables.zoneBoundaryColor,
      gl.TRIANGLES,
    );
    this.drawShape(
      gl,
      this.waferMapVariables.program!,
      this.waferMapVariables.groupedZoneCoords,
      this.waferMapVariables.zoneBoundaryColor,
      gl.TRIANGLES,
    );
    if (this.waferMapVariables.markWaferCenter) {
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.waferCenterCoords,
        this.waferMapVariables.waferCenterColor,
        gl.LINES,
      );
    }
    if (this.waferMapVariables.showReferenceReticle && this.waferMapVariables.overlayReticle) {
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.reticleReferenceCoords,
        this.waferMapVariables.reticleReferenceColor,
        gl.LINES,
      );
      this.drawShape(
        gl,
        this.waferMapVariables.program!,
        this.waferMapVariables.reticleOffsetCoords,
        this.waferMapVariables.reticleOffsetLineColor,
        gl.LINES,
      );
    }
    this.setTickOffsets();
    const tickData = this.renderTickLinesAndMarks(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      XAnchor,
      YAnchor,
    );
    this.waferMapVariables.binNumX = tickData.binNumX;
    this.waferMapVariables.binNumY = tickData.binNumY;
    this.renderReticleAndBinText(tickData.binNumX, tickData.binNumY);
    const { viewport } = this.waferMapVariables;
    gl.viewport(viewport.x, viewport.y, viewport.width, viewport.height);
    if (this.waferMapVariables.showGridBordersAndTicks) this.drawGridBorder(this.waferMapVariables.gl!);
  };

  returnBoxArrayIndices = (startX: number, startY: number, endX: number, endY: number) => {
    if (startX > endX) {
      [startX, endX] = [endX, startX];
    }
    if (startY > endY) {
      [startY, endY] = [endY, startY];
    }

    const boxStartOnArrayX: number = startX - this.waferMapVariables.XAnchor;
    const boxStartOnArrayY: number = startY - this.waferMapVariables.YAnchor;

    const startArrayX: number = Math.floor(
      boxStartOnArrayX / this.waferMapVariables.scaledDieWidth,
    );
    const startArrayY: number = Math.floor(
      boxStartOnArrayY / this.waferMapVariables.scaledDieHeight,
    );

    const endArrayX: number = Math.floor((endX - startX) / this.waferMapVariables.scaledDieWidth)
      + startArrayX;
    const endArrayY: number = Math.floor((endY - startY) / this.waferMapVariables.scaledDieHeight)
      + startArrayY;

    return {
      startArrayX, startArrayY, endArrayX, endArrayY,
    };
  };

  returnBoxArrayIndicesForRadar = (startX: number, startY: number, endX: number, endY: number) => {
    if (startX > endX) {
      [startX, endX] = [endX, startX];
    }
    if (startY > endY) {
      [startY, endY] = [endY, startY];
    }

    const boxStartOnArrayX: number = startX - this.waferMapVariables.XAnchor;
    const boxStartOnArrayY: number = startY - this.waferMapVariables.YAnchor;

    if (boxStartOnArrayX < 0) {
      startX = 0;
    }
    if (boxStartOnArrayY < 0) {
      startY = 0;
    }

    const startArrayX: number = boxStartOnArrayX / this.waferMapVariables.scaledDieWidth;
    const startArrayY: number = boxStartOnArrayY / this.waferMapVariables.scaledDieHeight;

    const endArrayX: number = (endX - startX) / this.waferMapVariables.scaledDieWidth
    + startArrayX - 1;
    const endArrayY: number = (endY - startY) / this.waferMapVariables.scaledDieHeight
    + startArrayY - 1;
    return {
      startArrayX, startArrayY, endArrayX, endArrayY,
    };
  };

  resetWaferZoom = (): void => {
    if (this.waferMapVariables.gl == null) {
      console.error('WaferMapVariables GL is null');
      return;
    }
    const scaledWaferMap = this.scalingWaferMap(
      this.waferMapVariables.dieWidth,
      this.waferMapVariables.dieHeight,
      this.waferMapVariables.waferMapTestData,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
    );
    this.waferMapVariables.scaledDieWidth = scaledWaferMap.scaledDieWidth;
    this.waferMapVariables.scaledDieHeight = scaledWaferMap.scaledDieHeight;
    this.waferMapVariables.intialScaledDieWidth = scaledWaferMap.scaledDieWidth;
    this.waferMapVariables.intialScaledDieHeight = scaledWaferMap.scaledDieHeight;
    this.waferMapVariables.scaledWaferWidth = scaledWaferMap.scaledWaferWidth;
    this.waferMapVariables.scaledWaferHeight = scaledWaferMap.scaledWaferHeight;
    this.waferMapVariables.zoomScaleFactor = this.waferMapVariables.zoomScaleFactorFromProps;

    this.waferMapVariables.XAnchorIntialPosition = (this.waferMapVariables.gl.canvas.width
        - this.waferMapVariables.scaledWaferWidth)
      / 2;
    this.waferMapVariables.YAnchorIntialPosition = (this.waferMapVariables.gl.canvas.height
        - this.waferMapVariables.scaledWaferHeight)
      / 2;
    this.waferMapVariables.YAnchorIntialPosition += this.waferMapVariables.waferTopOffset;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
      this.waferMapVariables.XAnchorIntialPosition,
      this.waferMapVariables.YAnchorIntialPosition,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchorIntialPosition,
      this.waferMapVariables.YAnchorIntialPosition,
    );
    if (this.waferMapVariables.isWaferControlMap) {
      this.zoomPlusMinus((1 / Math.min(this.waferMapVariables.waferWidthToColsRatio, this.waferMapVariables.waferHeightToRowsRatio)) * this.waferMapVariables.zoomScaleFactorFromProps, true);
    }
  };

  zoomPlusMinus = (scaleRatio: number, isInitialAdjusting = false): void => {
    const originalWaferWidth: number = this.waferMapVariables.scaledDieWidth
      * this.waferMapVariables.waferMapTestData[0].length;
    const originalWaferHeight: number = this.waferMapVariables.scaledDieHeight
      * this.waferMapVariables.waferMapTestData.length;

    // Assuming that the wafer will remain where it is.
    //  We want to adjust the Xanchor and YAnchor in such a way that the
    //  delta change is such that the center of the map remains where it was before.
    if (this.waferMapVariables.dieWidth < this.waferMapVariables.dieHeight) {
      this.waferMapVariables.scaledDieWidth *= scaleRatio;
      this.waferMapVariables.scaledDieHeight = this.waferMapVariables.scaledDieWidth
        * (this.waferMapVariables.dieHeight / this.waferMapVariables.dieWidth);
    } else {
      this.waferMapVariables.scaledDieHeight *= scaleRatio;
      this.waferMapVariables.scaledDieWidth = this.waferMapVariables.scaledDieHeight
        * (this.waferMapVariables.dieWidth / this.waferMapVariables.dieHeight);
    }
    const newWaferWidth: number = this.waferMapVariables.scaledDieWidth
      * this.waferMapVariables.waferMapTestData[0].length;
    const newWaferHeight: number = this.waferMapVariables.scaledDieHeight
      * this.waferMapVariables.waferMapTestData.length;
    if (!isInitialAdjusting) {
      this.waferMapVariables.zoomScaleFactor = newWaferWidth
      / (this.waferMapVariables.intialScaledDieWidth * (1 / Math.min(this.waferMapVariables.waferWidthToColsRatio, this.waferMapVariables.waferHeightToRowsRatio))
        * this.waferMapVariables.waferMapTestData[0].length);
    }
    if (this.waferMapVariables.isWaferControlMap && isInitialAdjusting) {
      this.waferMapVariables.XAnchor -= (newWaferWidth - originalWaferWidth) / 2 + this.waferMapVariables.waferBGOffsetXDies * this.waferMapVariables.scaledDieWidth;
      this.waferMapVariables.YAnchor -= (newWaferHeight - originalWaferHeight) / 2 + this.waferMapVariables.waferBGOffsetYDies * this.waferMapVariables.scaledDieHeight;
    } else {
      this.waferMapVariables.XAnchor -= (newWaferWidth - originalWaferWidth) / 2;
      this.waferMapVariables.YAnchor -= (newWaferHeight - originalWaferHeight) / 2;
    }
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  updateAnchorOnRadarDragOrClick = (data: { startX: number, startY: number }): void => {
    let { startX, startY } = data;
    startX *= this.waferMapVariables.viewPortWidth;
    startY *= this.waferMapVariables.viewPortHeight;
    // rotation
    const { angleInDegrees, viewPortWidth, viewPortHeight } = this.waferMapVariables;
    const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
    startX -= viewPortWidth / 2;
    startY -= viewPortHeight / 2;
    let rstartX = startX * rotation[1] + startY * rotation[0];
    let rstartY = startY * rotation[1] - startX * rotation[0];
    rstartX += viewPortWidth / 2;
    rstartY += viewPortHeight / 2;
    [startX, startY] = [rstartX, rstartY];
    // rotation
    startX -= this.waferMapVariables.XAnchorIntialPosition;
    startY -= this.waferMapVariables.YAnchorIntialPosition;

    const radarWaferWidth: number = this.waferMapVariables.viewPortWidth
      - 2 * this.waferMapVariables.XAnchorIntialPosition;
    const radarWaferHeight: number = this.waferMapVariables.viewPortHeight
      - 2 * this.waferMapVariables.YAnchorIntialPosition;
    this.waferMapVariables.XAnchor = -startX * (this.waferMapVariables.scaledWaferWidth / radarWaferWidth);
    this.waferMapVariables.YAnchor = -startY * (this.waferMapVariables.scaledWaferHeight / radarWaferHeight);
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  updateRadarPointer = (data: { startX: number, startY: number }) => {
    let { startX, startY } = data;
    startX *= this.waferMapVariables.viewPortWidth;
    startY *= this.waferMapVariables.viewPortHeight;
    // rotation
    const { angleInDegrees, viewPortWidth, viewPortHeight } = this.waferMapVariables;
    const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
    startX -= viewPortWidth / 2;
    startY -= viewPortHeight / 2;
    let rstartX = startX * rotation[1] + startY * rotation[0];
    let rstartY = startY * rotation[1] - startX * rotation[0];
    rstartX += viewPortWidth / 2;
    rstartY += viewPortHeight / 2;
    [startX, startY] = [rstartX, rstartY];
    // rotation
    startX -= this.waferMapVariables.XAnchorIntialPosition;
    startY -= this.waferMapVariables.YAnchorIntialPosition;

    const radarWaferWidth: number = this.waferMapVariables.viewPortWidth
      - 2 * this.waferMapVariables.XAnchorIntialPosition;
    const radarWaferHeight: number = this.waferMapVariables.viewPortHeight
      - 2 * this.waferMapVariables.YAnchorIntialPosition;
    this.waferMapVariables.XAnchor = -startX * (this.waferMapVariables.scaledWaferWidth / radarWaferWidth);
    this.waferMapVariables.YAnchor = -startY * (this.waferMapVariables.scaledWaferHeight / radarWaferHeight);
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    // Rendering Dyes and Gridlines
    radarView(this);
    this.renderRadarPointer(this.returnBoxArrayIndicesForRadar(
      0,
      0,
      this.waferMapVariables.viewPortWidth,
      this.waferMapVariables.viewPortHeight,
    ));
  };

  renderBinText = (binNumX: number[], binNumY: number[], ctxFontSize: number) => {
    const {
      ctx, startRow, startCol, rowDirection, colDirection, waferMapTestData,
      angleInDegrees, binTextField, reticleGridRectCoords,
    } = this.waferMapVariables;
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        if (waferMapTestData[p][j] && !waferMapTestData[p][j]?.cropped && !waferMapTestData[p][j]?.deleted) {
          const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(waferMapTestData[p][j]!, this.waferMapVariables.reticleSize);
          if (!reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)]
            || (reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)]
            && !reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)].reticleTextData?.text)) {
            const text = this.getTextFromDieField(waferMapTestData[p][q]!, binTextField);
            const fontWidth: number = ctx.measureText(`${text}`).width;
            const fontHeight: number = ctxFontSize;
            ctx.save();
            ctx.translate(binNumX[j], binNumY[i]);
            ctx.rotate((360 - angleInDegrees) * (Math.PI / 180));
            ctx.translate(-(binNumX[j]), -(binNumY[i]));
            ctx.fillText(
              `${text !== null && text !== undefined ? text : ''}`,
              binNumX[j] - fontWidth / 2,
              binNumY[i] + fontHeight / 3,
            );
            ctx.restore();
          }
        }
      }
    }
  };

  renderReticleText = (ctxFontSize: number) => {
    const { ctx, angleInDegrees, reticleGridRectCoords } = this.waferMapVariables;
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      const { reticleTextData } = reticleGridRectCoords[key];
      if (reticleTextData && reticleTextData.text) {
        const fontWidth: number = ctx.measureText(`${reticleTextData.text}`).width;
        const fontHeight: number = ctxFontSize;
        ctx.save();
        ctx.translate(reticleTextData.x, reticleTextData.y);
        ctx.rotate((360 - angleInDegrees) * (Math.PI / 180));
        ctx.translate(-(reticleTextData.x), -(reticleTextData.y));
        ctx.fillText(
          `${reticleTextData.text}`,
          reticleTextData.x - fontWidth / 2,
          reticleTextData.y + fontHeight / 3,
        );
        ctx.restore();
      }
    }
  };

  renderReticleAndBinText = (binNumX: number[], binNumY: number[]): void => {
    this.clearbinNumber();
    const {
      ctx, angleInDegrees, showReticleText, binTextFieldFlag,
    } = this.waferMapVariables;

    if (!ctx) return;
    let ctxFontSize = this.waferMapVariables.scaledDieWidth * 0.15;
    if (angleInDegrees === 90 || angleInDegrees === 270) {
      ctxFontSize = this.waferMapVariables.scaledDieHeight * 0.15;
    }
    ctx.font = `${ctxFontSize}px Arial`;

    if (binTextFieldFlag) {
      this.renderBinText(binNumX, binNumY, ctxFontSize);
    } else {
      this.clearbinNumber();
    }

    ctxFontSize *= 2;
    ctx.font = `${ctxFontSize}px Arial`;

    if (showReticleText) {
      this.renderReticleText(ctxFontSize);
    }
  };

  renderTickLines = (coords: number[]) => {
    const tickLinesVertexShader = `#version 300 es
      precision mediump float;
      in vec2 position;
      void main() {
          gl_Position = vec4(position.x,position.y*-1.0, 0.0, 1.0);
      }`;
    const tickLinesFragmentShader = `#version 300 es
      precision mediump float;
      out vec4 color;
      void main() {
          color = vec4(0.0, 0.0, 0.0, 0.5); // r,g,b,a 
      }
      `;

    const program = this.getProgram(
      this.waferMapVariables.gl!,
      tickLinesVertexShader,
      tickLinesFragmentShader,
    );
    const { gl } = this.waferMapVariables;
    if (gl === null || program === null) return;
    const buffer = this.createAndBindBuffer(gl, gl.ARRAY_BUFFER, gl.STATIC_DRAW, new Float32Array(coords));
    gl.useProgram(program);
    this.linkGPUAndCPU({
      program,
      gpuVariable: 'position',
      channel: gl.ARRAY_BUFFER,
      buffer,
      dims: 2,
      dataType: gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, gl);
    const { tickViewPort } = this.waferMapVariables;
    gl.viewport(tickViewPort.x, tickViewPort.y, tickViewPort.width, tickViewPort.height);
    gl.drawArrays(gl.LINES, 0, coords.length / 2);
    if (this.waferMapVariables.showGridBordersAndTicks) this.drawGridBorder(gl);
  };

  renderPoints = (coords: number[], vertexCount: number, pointSize: number) => {
    for (let i = 0; i < coords.length; i += 1) {
      coords[i] = -1.0 + (coords[i] / 700) * 2;
    }
    const canvas: HTMLCanvasElement = document.querySelector('#canvas1') as HTMLCanvasElement;
    const gl: WebGL2RenderingContext = canvas.getContext('webgl2')!;
    const vertexShader = `#version 300 es
    precision mediump float;
    in vec2 position;
    uniform float pointSize;
    void main () {
        gl_Position = vec4(position.x, position.y * -1.0, 0.0, 1.0);
        gl_PointSize = pointSize;
    }`;

    const fragmentShader = `#version 300 es
    precision mediump float;
    out vec4 color;
    void main () {
        color = vec4(1.0, 0.0, 0.0, 1.0);
    }`;

    const program = this.getProgram(gl, vertexShader, fragmentShader);
    if (program === null) return;
    const buffer = this.createAndBindBuffer(gl, gl.ARRAY_BUFFER, gl.STATIC_DRAW, new Float32Array(coords));
    gl.useProgram(program);
    this.linkGPUAndCPU({
      program,
      gpuVariable: 'position',
      channel: gl.ARRAY_BUFFER,
      buffer,
      dims: 2,
      dataType: gl.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, gl);
    gl.uniform1f(gl.getUniformLocation(program, 'pointSize'), pointSize);
    const { viewport } = this.waferMapVariables;
    gl.viewport(viewport.x, viewport.y, viewport.width, viewport.height);
    gl.drawArrays(this.gl!.POINTS, 0, vertexCount);
  };

  setTickOffsets = (): void => {
    if (!this.waferMapVariables.tickTextCtx) return;
    this.clearTickText();
    const { angleInDegrees } = this.waferMapVariables;
    let offsetX = 1;
    let offsetY = 1;
    let tickTextYBuffer = 1;
    this.waferMapVariables.tickTextCtx.font = `${this.waferMapVariables.tickTextFont}px Arial`;
    const maxXTickText: number = this.waferMapVariables.waferMapTestData[0].length - 1
      + this.waferMapVariables.colOffset;
    const minXTickText: number = this.waferMapVariables.colOffset;
    const maxTickTextWidth: number = Math.max(
      this.waferMapVariables.tickTextCtx.measureText(`${maxXTickText}`).width,
      this.waferMapVariables.tickTextCtx.measureText(`${minXTickText}`).width,
    );
    const tickTextXBuffer: number = maxTickTextWidth * 1.5;
    if (angleInDegrees === 0 || angleInDegrees === 180) {
      tickTextYBuffer = this.waferMapVariables.tickTextFont * 1.5;
    } else if (angleInDegrees === 90 || angleInDegrees === 270) {
      const maxYTickText = this.waferMapVariables.waferMapTestData.length - 1
      + this.waferMapVariables.rowOffset;
      const minYTickText: number = this.waferMapVariables.rowOffset;
      const maxTickTextWidthY: number = Math.max(
        this.waferMapVariables.tickTextCtx.measureText(`${maxYTickText}`).width,
        this.waferMapVariables.tickTextCtx.measureText(`${minYTickText}`).width,
      );
      tickTextYBuffer = maxTickTextWidthY * 1.5;
    }
    if (this.waferMapVariables.scaledDieWidth <= tickTextXBuffer) {
      offsetX += Math.floor(
        tickTextXBuffer / (this.waferMapVariables.scaledDieWidth / 1.5),
      );
    } else {
      offsetX = 1;
    }
    if (this.waferMapVariables.scaledDieHeight <= tickTextYBuffer) {
      offsetY += Math.floor(
        tickTextYBuffer / (this.waferMapVariables.scaledDieHeight / 1.5),
      );
    } else {
      offsetY = 1;
    }
    this.waferMapVariables.tickOffsetX = offsetX;
    this.waferMapVariables.tickOffsetY = offsetY;
  };

  getTickTextXYPos = (
    angleInDegrees: number,
    location: 'top' | 'bottom' | 'left' | 'right',
    positionBasedOnTextCanvas: number,
    fontWidth: number,
    fontHeight: number,
    ratioWidth: number,
    ratioHeight: number,
    viewPortXDiff: number,
    viewPortYDiff: number,
    viewPortWidth: number,
    viewPortHeight: number,
    fontWidthBasedOnDigits = 0,
    level: '1' | '2' | '3' = '1',
  ) => {
    const { viewport, tickViewPort } = this.waferMapVariables;
    let xVal = 0;
    let yVal = 0;
    const initialSpaceBeforeViewport = Math.abs(tickViewPort.x - viewport.x);
    const increment = initialSpaceBeforeViewport / 5;
    const levelSpace = (+level - 1) * increment;

    const topAdjustment = -10 - levelSpace;
    const bottomAdjustment = 2 * fontHeight + levelSpace;
    const leftAdjustment = -20 - fontWidth / 2 - levelSpace;
    const rightAdjustment = 10 + fontWidthBasedOnDigits / 2 + levelSpace;

    switch (location) {
      case 'top':
        if (angleInDegrees === 0) {
          xVal = positionBasedOnTextCanvas - fontWidth / 2;
          yVal = (0 / ratioHeight + viewPortYDiff) + topAdjustment;
        } else if (angleInDegrees === 180) {
          xVal = positionBasedOnTextCanvas - fontWidth / 2;
          yVal = (0 / ratioHeight + viewPortYDiff) + bottomAdjustment;
        } else if (angleInDegrees === 270) {
          xVal = positionBasedOnTextCanvas + leftAdjustment;
          yVal = (0 / ratioHeight + viewPortYDiff) + fontHeight / 2;
        } else if (angleInDegrees === 90) {
          xVal = positionBasedOnTextCanvas + rightAdjustment;
          yVal = (0 / ratioHeight + viewPortYDiff) + fontHeight / 2;
        }
        break;
      case 'bottom':
        if (angleInDegrees === 0) {
          xVal = positionBasedOnTextCanvas - fontWidth / 2;
          yVal = (viewPortHeight / ratioHeight + viewPortYDiff) + bottomAdjustment;
        } else if (angleInDegrees === 180) {
          xVal = positionBasedOnTextCanvas - fontWidth / 2;
          yVal = (viewPortHeight / ratioHeight + viewPortYDiff) + topAdjustment;
        } else if (angleInDegrees === 90) {
          xVal = positionBasedOnTextCanvas + leftAdjustment;
          yVal = (viewPortHeight / ratioHeight + viewPortYDiff) + fontHeight / 2;
        } else if (angleInDegrees === 270) {
          xVal = positionBasedOnTextCanvas + rightAdjustment;
          yVal = (viewPortHeight / ratioHeight + viewPortYDiff) + fontHeight / 2;
        }
        break;
      case 'left':
        if (angleInDegrees === 0) {
          xVal = (0 / ratioWidth + viewPortXDiff) + leftAdjustment;
          yVal = positionBasedOnTextCanvas + fontHeight / 2;
        } else if (angleInDegrees === 180) {
          xVal = (0 / ratioWidth + viewPortXDiff) + rightAdjustment;
          yVal = positionBasedOnTextCanvas + fontHeight / 2;
        } else if (angleInDegrees === 90) {
          xVal = (0 / ratioWidth + viewPortXDiff) - fontWidth / 2;
          yVal = positionBasedOnTextCanvas + topAdjustment;
        } else if (angleInDegrees === 270) {
          xVal = (0 / ratioWidth + viewPortXDiff) - fontWidth / 2;
          yVal = positionBasedOnTextCanvas + bottomAdjustment;
        }
        break;
      case 'right':
        if (angleInDegrees === 0) {
          xVal = (viewPortWidth / ratioWidth + viewPortXDiff) + rightAdjustment;
          yVal = positionBasedOnTextCanvas + fontHeight / 2;
        } else if (angleInDegrees === 180) {
          xVal = (viewPortWidth / ratioWidth + viewPortXDiff) + leftAdjustment;
          yVal = positionBasedOnTextCanvas + fontHeight / 2;
        } else if (angleInDegrees === 90) {
          xVal = (viewPortWidth / ratioWidth + viewPortXDiff) - fontWidth / 2;
          yVal = positionBasedOnTextCanvas + bottomAdjustment;
        } else if (angleInDegrees === 270) {
          xVal = (viewPortWidth / ratioWidth + viewPortXDiff) - fontWidth / 2;
          yVal = positionBasedOnTextCanvas + topAdjustment;
        }
        break;
      default:
        break;
    }
    return { xVal, yVal };
  };

  clearbinNumber = (): void => {
    if (this.waferMapVariables.ctx === null) return;
    this.waferMapVariables.ctx.clearRect(
      0,
      0,
      this.waferMapVariables.ctx.canvas.width,
      this.waferMapVariables.ctx.canvas.height,
    );
  };

  clearTickText = (): void => {
    if (this.waferMapVariables.tickTextCtx === null) return;
    this.waferMapVariables.tickTextCtx.clearRect(
      0,
      0,
      this.waferMapVariables.tickTextCtx.canvas.width,
      this.waferMapVariables.tickTextCtx.canvas.width,
    );
  };

  makeProgramForTextures = () => {
    const vertexShader = `#version 300 es
    precision mediump float;
    in vec2 position; // WebGL Coords
    in vec2 textCoords; // texture Coords
    out vec2 textCoordsToFragmentShader;
    void main () {
        gl_Position = vec4(position.x, position.y * -1.0, 0.0, 1.0);
        textCoordsToFragmentShader = textCoords;
    }`;

    const fragmentShader = `#version 300 es
    precision mediump float;
    in vec2 textCoordsToFragmentShader;
    uniform sampler2D uImage;
    out vec4 color;
    void main () {
        color = texture(uImage, textCoordsToFragmentShader);
    }`;
    if (this.waferMapVariables.gl === null) return;
    const { gl } = this.waferMapVariables;
    const dieImageProgram = this.getProgram(gl, vertexShader, fragmentShader);
    if (dieImageProgram === null) return;
    this.waferMapVariables.dieImageProgram = dieImageProgram;
    gl.useProgram(this.waferMapVariables.dieImageProgram);
  };

  renderDieImageTextures = (coords: number[], textureImage: HTMLImageElement) => {
    return new Promise(() => {
      if (this.waferMapVariables.gl === null || this.waferMapVariables.dieImageProgram === null) return;
      const { gl, dieImageProgram } = this.waferMapVariables;
      const buffer = this.createAndBindBuffer(gl, gl.ARRAY_BUFFER, gl.STATIC_DRAW, new Float32Array(coords));
      if (this.waferMapVariables.dieImageTextureCoords === null) {
        this.waferMapVariables.dieImageTextureCoords = this.prepareRectVec2(0, 0, 1, 1);
        this.waferMapVariables.dieImageTextureBuffer = this.createAndBindBuffer(gl, gl.ARRAY_BUFFER, gl.STATIC_DRAW, new Float32Array(this.waferMapVariables.dieImageTextureCoords));
        this.linkGPUAndCPU({
          program: dieImageProgram,
          gpuVariable: 'textCoords',
          channel: gl.ARRAY_BUFFER,
          buffer: this.waferMapVariables.dieImageTextureBuffer!,
          dims: 2,
          dataType: gl.FLOAT,
          normalize: false,
          stride: 0,
          offset: 0,
        }, gl);
      }
      const uImageTexture = this.createAndBindTexture(gl, textureImage);
      this.linkGPUAndCPU({
        program: dieImageProgram,
        gpuVariable: 'position',
        channel: gl.ARRAY_BUFFER,
        buffer,
        dims: 2,
        dataType: gl.FLOAT,
        normalize: false,
        stride: 0,
        offset: 0,
      }, gl);
      const uImageLocation = gl.getUniformLocation(dieImageProgram, 'uImage');
      gl.uniform1i(uImageLocation, 0);
      gl.activeTexture(gl.TEXTURE0 + 0);
      gl.bindTexture(gl.TEXTURE_2D, uImageTexture);
      const { viewport } = this.waferMapVariables;
      gl.viewport(viewport.x, viewport.y, viewport.width, viewport.height);
      gl.drawArrays(gl.TRIANGLES, 0, 6);
    // end
    });
  };

  flipWaferMap = (axis: FlipAxis) => {
    if (axis === FlipAxis.RowWise) {
      if (this.waferMapVariables.rowFlip === RowFlip.Upright) {
        this.waferMapVariables.rowDirection = 1;
        this.waferMapVariables.startRow = 0;
        this.waferMapVariables.subdieRowStart = 0;
      } else {
        this.waferMapVariables.rowDirection = -1;
        this.waferMapVariables.startRow = this.waferMapVariables.waferMapTestData.length - 1;
        this.waferMapVariables.subdieRowStart = this.waferMapVariables.dieSubView!.length - 1;
      }
      this.waferMapVariables.rowAxisDirection = this.waferMapVariables.rowAxisDirection === RowAxisDirection.BottomToTop ? RowAxisDirection.TopToBottom : RowAxisDirection.BottomToTop;
    } else if (axis === FlipAxis.ColWise) {
      if (this.waferMapVariables.colFlip === ColFlip.Upright) {
        this.waferMapVariables.colDirection = 1;
        this.waferMapVariables.startCol = 0;
        this.waferMapVariables.subdieColStart = 0;
      } else {
        this.waferMapVariables.colDirection = -1;
        this.waferMapVariables.startCol = this.waferMapVariables.waferMapTestData[0].length - 1;
        this.waferMapVariables.subdieColStart = this.waferMapVariables.dieSubView![0].length - 1;
      }
      this.waferMapVariables.colAxisDirection = this.waferMapVariables.colAxisDirection === ColAxisDirection.RightToLeft ? ColAxisDirection.LeftToRight : ColAxisDirection.RightToLeft;
    }
    if (this.waferMapVariables.colAxisDirection === ColAxisDirection.RightToLeft) {
      this.waferMapVariables.colAxisIncrement = -1;
      this.waferMapVariables.colAxisStart = this.waferMapVariables.waferMapTestData[0].length - 1;
    } else {
      this.waferMapVariables.colAxisIncrement = 1;
      this.waferMapVariables.colAxisStart = 0;
    }
    if (this.waferMapVariables.rowAxisDirection === RowAxisDirection.BottomToTop) {
      this.waferMapVariables.rowAxisIncrement = -1;
      this.waferMapVariables.rowAxisStart = this.waferMapVariables.waferMapTestData.length - 1;
    } else {
      this.waferMapVariables.rowAxisIncrement = 1;
      this.waferMapVariables.rowAxisStart = 0;
    }
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  rotateWaferMap = (direction: RotateDirection) => {
    const {
      setRotationParams, tickTextCtx, angleInDegrees, tickTextCanvasWidth, tickTextCanvasHeight, ctx, binCanvasHeight, binCanvasWidth,
    } = this.waferMapVariables;
    let angle;
    let directionMultiplier;
    if (direction === RotateDirection.AntiClockWise) {
      angle = angleInDegrees - 90;
      if (angleInDegrees === 0) {
        angle = 270;
      }
      directionMultiplier = -1;
    } else {
      angle = (angleInDegrees + 90) % 360;
      directionMultiplier = 1;
    }
    setRotationParams(angle);
    if (tickTextCtx !== null) {
      tickTextCtx.translate(tickTextCanvasWidth / 2, tickTextCanvasHeight / 2);
      tickTextCtx.rotate(directionMultiplier * 90 * (Math.PI / 180));
      tickTextCtx.translate(-tickTextCanvasWidth / 2, -tickTextCanvasHeight / 2);
    }
    if (ctx !== null) {
      ctx.translate(binCanvasWidth / 2, binCanvasHeight / 2);
      ctx.rotate(directionMultiplier * 90 * (Math.PI / 180));
      ctx.translate(-binCanvasWidth / 2, -binCanvasHeight / 2);
    }
    this.reRender();
  };

  renderShape = (coords: number[], vertexCount: number, shapeType: number, glContext = this.waferMapVariables.gl, color = [0.0, 0.0, 0.0, 1.0]) => {
    if (glContext === null) return;
    const vertexShader = `#version 300 es
    precision mediump float;
    in vec2 position;
    void main () {
        gl_Position = vec4(position, 0.0, 1.0);
    }`;

    const fragmentShader = `#version 300 es
    precision mediump float;
    out vec4 color;
    uniform vec4 u_color;
    void main () {
        color = u_color;
    }`;

    const program = this.getProgram(glContext, vertexShader, fragmentShader);
    if (program === null) return;
    const buffer = this.createAndBindBuffer(glContext, glContext.ARRAY_BUFFER, glContext.STATIC_DRAW, new Float32Array(coords));
    glContext.useProgram(program);
    this.linkGPUAndCPU({
      program,
      gpuVariable: 'position',
      channel: glContext.ARRAY_BUFFER,
      buffer,
      dims: 2,
      dataType: glContext.FLOAT,
      normalize: false,
      stride: 0,
      offset: 0,
    }, glContext);
    glContext.uniform4fv(glContext.getUniformLocation(program, 'u_color'), color);
    glContext.drawArrays(shapeType, 0, vertexCount);
  };

  drawGridBorder = (gl: WebGL2RenderingContext) => {
    const left = {
      startX: -1.0, startY: 1.0, endX: -1.0, endY: -1.0,
    };
    const up = {
      startX: -1.0, startY: 1.0, endX: 1.0, endY: 1.0,
    };
    const right = {
      startX: 1.0, startY: 1.0, endX: 1.0, endY: -1.0,
    };
    const bottom = {
      startX: -1.0, startY: -1.0, endX: 1.0, endY: -1.0,
    };
    this.renderShape([left.startX, left.startY, left.endX, left.endY], 2, gl.LINES, gl); // line
    this.renderShape([up.startX, up.startY, up.endX, up.endY], 2, gl.LINES, gl); // line
    this.renderShape([right.startX, right.startY, right.endX, right.endY], 2, gl.LINES, gl); // line
    this.renderShape([bottom.startX, bottom.startY, bottom.endX, bottom.endY], 2, gl.LINES, gl); // line
  };

  drawRectBorder = (gl: WebGL2RenderingContext, rect: RectCoord) => {
    const left = {
      startX: rect.startX, startY: rect.startY, endX: rect.startX, endY: rect.endY,
    };
    const up = {
      startX: rect.startX, startY: rect.startY, endX: rect.endX, endY: rect.startY,
    };
    const right = {
      startX: rect.endX, startY: rect.startY, endX: rect.endX, endY: rect.endY,
    };
    const bottom = {
      startX: rect.startX, startY: rect.endY, endX: rect.endX, endY: rect.endY,
    };
    this.renderShape([left.startX, left.startY, left.endX, left.endY], 2, gl.LINES, gl); // line
    this.renderShape([up.startX, up.startY, up.endX, up.endY], 2, gl.LINES, gl); // line
    this.renderShape([right.startX, right.startY, right.endX, right.endY], 2, gl.LINES, gl); // line
    this.renderShape([bottom.startX, bottom.startY, bottom.endX, bottom.endY], 2, gl.LINES, gl); // line
  };

  setFilterData = (data: { list: { row: number | null, col: number | null }[] }) => {
    this.waferMapVariables.filteredData.forEach((dataEntry: { row: number | null, col: number | null }) => {
      if (dataEntry.row != null && dataEntry.col != null) {
        if (this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col] != null) {
          this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]!.filtered = false;
        }
      }
    });
    this.waferMapVariables.filteredData = data.list;
    data.list.forEach((dataEntry: { row: number | null, col: number | null }) => {
      if (dataEntry.row != null && dataEntry.col != null) {
        if (this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col] != null) {
          this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]!.filtered = true;
        }
      }
    });
    if (this.waferMapVariables.gl === null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  setFilterDataFromCustomGrid = (data: { list: any[], subscriberKey: string, publisherKey: string }) => {
    this.waferMapVariables.filteredData.forEach((dataEntry: { row: number | null, col: number | null }) => {
      if (dataEntry.row != null && dataEntry.col != null) {
        if (this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col] != null) {
          this.waferMapVariables.waferMapTestData[dataEntry.row][dataEntry.col]!.filtered = false;
        }
      }
    });
    this.waferMapVariables.filteredData = [];
    data.list.forEach((dataEntry: any) => {
      if (dataEntry !== null) {
        for (let i = 0; i < this.waferMapVariables.waferMapTestData.length; i += 1) {
          for (let j = 0; j < this.waferMapVariables.waferMapTestData[i].length; j += 1) {
            if (this.waferMapVariables.waferMapTestData[i][j]
              && data.subscriberKey in this.waferMapVariables.waferMapTestData[i][j]!
              && data.publisherKey in dataEntry
              && this.waferMapVariables.waferMapTestData[i][j]![data.subscriberKey] === dataEntry[data.publisherKey]) {
              this.waferMapVariables.waferMapTestData[i][j]!.filtered = true;
              this.waferMapVariables.filteredData.push({
                row: this.waferMapVariables.waferMapTestData[i][j]!.row, col: this.waferMapVariables.waferMapTestData[i][j]!.col,
              });
            }
          }
        }
      }
    });
    if (this.waferMapVariables.gl === null) { return; }
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  clearFilterData = () => {
    for (let i = 0; i < this.waferMapVariables.waferMapTestData.length; i += 1) {
      for (let j = 0; j < this.waferMapVariables.waferMapTestData[i].length; j += 1) {
        if (this.waferMapVariables.waferMapTestData[i][j]) {
          this.waferMapVariables.waferMapTestData[i][j]!.filtered = false;
        }
      }
    }
    if (this.waferMapVariables.gl == null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  dieSelectionFromGrid =
  (data: { selectedRowsData: { row: number, col: number }[], currentDeselectedRowKeys: { row: number, col: number }[] }) => {
    data.selectedRowsData.forEach((dataEntry: { row: number, col: number }) => {
      const i = dataEntry.row;
      const j = dataEntry.col;
      if (i === undefined || j === undefined) return;
      if (this.waferMapVariables.waferMapTestData[i][j] == null) {
        console.error(`Data entry at x = ${i} and y = ${j} is null`);
        return;
      }
      this.waferMapVariables.waferMapTestData[i][j]!.selected = true;
    });
    data.currentDeselectedRowKeys.forEach((dataEntry: { row: number, col: number }) => {
      const i = dataEntry.row;
      const j = dataEntry.col;
      if (i === undefined || j === undefined) return;
      if (this.waferMapVariables.waferMapTestData[i][j] == null) {
        console.error(`Data entry at x = ${i} and y = ${j} is null`);
        return;
      }
      this.waferMapVariables.waferMapTestData[i][j]!.selected = false;
    });
    if (this.waferMapVariables.gl == null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  private dataSelectionFromEntry = (dataEntry: any, subscriberKey: string, publisherKey: string, selectionFlag: boolean) => {
    if (dataEntry !== null) {
      for (let i = 0; i < this.waferMapVariables.waferMapTestData.length; i += 1) {
        for (let j = 0; j < this.waferMapVariables.waferMapTestData[i].length; j += 1) {
          if (this.waferMapVariables.waferMapTestData[i][j]
            && subscriberKey in this.waferMapVariables.waferMapTestData[i][j]!
            && publisherKey in dataEntry
            && this.waferMapVariables.waferMapTestData[i][j]![subscriberKey] === dataEntry[publisherKey]) {
            this.waferMapVariables.waferMapTestData[i][j]!.selected = selectionFlag;
          }
        }
      }
    }
  };

  dataSelectedOnCustomGrid = (data: { selectedData: any[],
    currentDeselectedRowKeys: any[], subscriberKey: string, publisherKey: string }) => {
    data.selectedData.forEach((dataEntry: any) => {
      this.dataSelectionFromEntry(dataEntry, data.subscriberKey, data.publisherKey, true);
    });
    data.currentDeselectedRowKeys.forEach((dataEntry: any) => {
      this.dataSelectionFromEntry(dataEntry, data.subscriberKey, data.publisherKey, false);
    });
    if (this.waferMapVariables.gl == null) { return; }
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  dataSelectedOnPlot =
  (data: { selectedData: any[], subscriberKey: string, publisherKey: string }) => {
    data.selectedData.forEach((dataEntry: any) => {
      this.dataSelectionFromEntry(dataEntry, data.subscriberKey, data.publisherKey, true);
    });
    if (this.waferMapVariables.gl == null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  dataUnSelectedOnPlot =
  (data: { unSelectedData: any[], subscriberKey: string, publisherKey: string }) => {
    data.unSelectedData.forEach((dataEntry: any) => {
      this.dataSelectionFromEntry(dataEntry, data.subscriberKey, data.publisherKey, false);
    });
    if (this.waferMapVariables.gl == null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  selectSubDiesFromGrid = (data: { e: any }) => {
    if (this.waferMapVariables.dieSubView == null) return;
    data.e.forEach((selectedOption: Option) => {
      this.waferMapVariables.dieSubView![selectedOption.x][selectedOption.y][selectedOption.key].visible = true;
    });
    if (this.waferMapVariables.gl == null) return;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  hideAllSubDies = () => {
    if (this.waferMapVariables.dieSubView == null) return;
    for (let i = 0; i < this.waferMapVariables.dieSubView.length; i += 1) {
      for (let j = 0; j < this.waferMapVariables.dieSubView[i].length; j += 1) {
        this.waferMapVariables.dieSubView[i][j].forEach(
          (ele) => {
            // eslint-disable-next-line no-param-reassign
            ele.visible = false;
          },
        );
      }
    }
  };

  dieSelectedOnWafer = (data: { selectedData: { row: number, col: number }[] }) => {
    for (let i = 0; i < data.selectedData.length; i += 1) {
      if (this.waferMapVariables.waferMapTestData[data.selectedData[i].row][data.selectedData[i].col]) {
        this.waferMapVariables.waferMapTestData[data.selectedData[i].row][data.selectedData[i].col]!.selected = true;
      }
    }
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  setPreDragAnchor = () => {
    this.waferMapVariables.XAnchorPreDrag = this.waferMapVariables.XAnchor;
    this.waferMapVariables.YAnchorPreDrag = this.waferMapVariables.YAnchor;
  };

  dragWaferMap = (data: { startX: number, startY: number, endX: number, endY: number }) => {
    const d = { ...data };
    d.startX *= this.waferMapVariables.viewPortWidth;
    d.startY *= this.waferMapVariables.viewPortHeight;
    d.endX *= this.waferMapVariables.viewPortWidth;
    d.endY *= this.waferMapVariables.viewPortHeight;
    const { viewport, viewPortHeight } = this.waferMapVariables;
    if (d.startX < viewport.x || d.startX > viewport.x + viewport.width) return;
    if (d.startY < viewPortHeight - (viewport.y + viewport.height) || d.startY > viewPortHeight - (viewport.y)) return;
    // rotation
    const { angleInDegrees, viewPortWidth } = this.waferMapVariables;
    const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
    d.startX -= viewPortWidth / 2;
    d.startY -= viewPortHeight / 2;
    let rstartX = d.startX * rotation[1] + d.startY * rotation[0];
    let rstartY = d.startY * rotation[1] - d.startX * rotation[0];
    rstartX += viewPortWidth / 2;
    rstartY += viewPortHeight / 2;
    d.endX -= viewPortWidth / 2;
    d.endY -= viewPortHeight / 2;
    let rendX = d.endX * rotation[1] + d.endY * rotation[0];
    let rendY = d.endY * rotation[1] - d.endX * rotation[0];
    rendX += viewPortWidth / 2;
    rendY += viewPortHeight / 2;
    [d.startX, d.startY, d.endX, d.endY] = [rstartX, rstartY, rendX, rendY];
    // rotation
    this.waferMapVariables.XAnchor = this.waferMapVariables.XAnchorPreDrag + d.endX - d.startX;
    this.waferMapVariables.YAnchor = this.waferMapVariables.YAnchorPreDrag + d.endY - d.startY;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    if (this.waferMapVariables.gl === null) return;
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  zoomWaferMapUsingButtons = (data: { zoomFactor: number }) => {
    this.zoomPlusMinus(data.zoomFactor);
  };

  zoomWaferMap = (data: { startX: number, startY: number, endX: number, endY: number }) => {
    let {
      startX, startY, endX, endY,
    } = data;
    startX *= this.waferMapVariables.viewPortWidth;
    startY *= this.waferMapVariables.viewPortHeight;
    endX *= this.waferMapVariables.viewPortWidth;
    endY *= this.waferMapVariables.viewPortHeight;
    const {
      ratioWidth, ratioHeight, viewport, tickViewPort, viewPortHeight, angleInDegrees, viewPortWidth,
    } = this.waferMapVariables;
    if (startX < viewport.x || startX > viewport.x + viewport.width) return;
    if (startY < viewPortHeight - (viewport.y + viewport.height) || startY > viewPortHeight - (viewport.y)) return;
    if (this.waferMapVariables.gl == null) return;

    // eslint-disable-next-line no-bitwise
    this.waferMapVariables.gl.clear(this.waferMapVariables.gl.DEPTH_BUFFER_BIT
      | this.waferMapVariables.gl.COLOR_BUFFER_BIT);

    if (startX === endX || startY === endY) return;
    startX = (startX - viewport.x) * ratioWidth;
    endX = (endX - viewport.x) * ratioWidth;
    startY = (startY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
    endY = (endY - (((tickViewPort.height - viewport.height) / 2) + (viewPortHeight - (tickViewPort.y + tickViewPort.height)))) * ratioHeight;
    // rotation
    const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];
    startX -= viewPortWidth / 2;
    startY -= viewPortHeight / 2;
    let rstartX = startX * rotation[1] + startY * rotation[0];
    let rstartY = startY * rotation[1] - startX * rotation[0];
    rstartX += viewPortWidth / 2;
    rstartY += viewPortHeight / 2;
    endX -= viewPortWidth / 2;
    endY -= viewPortHeight / 2;
    let rendX = endX * rotation[1] + endY * rotation[0];
    let rendY = endY * rotation[1] - endX * rotation[0];
    rendX += viewPortWidth / 2;
    rendY += viewPortHeight / 2;
    [startX, startY, endX, endY] = [rstartX, rstartY, rendX, rendY];
    // rotation
    const indicesObj = this.returnBoxArrayIndices(startX, startY, endX, endY);
    const inDieDeltaX = ((startX - this.waferMapVariables.XAnchor)
    % this.waferMapVariables.scaledDieWidth)
    / this.waferMapVariables.scaledDieWidth;

    const inDieDeltaY = ((startY - this.waferMapVariables.YAnchor)
    % this.waferMapVariables.scaledDieHeight)
    / this.waferMapVariables.scaledDieHeight;

    // calculate reigon (zoom rect)
    const reigonWidth = Math.abs(endX - startX);
    const reigonHeight = Math.abs(endY - startY);

    // First calculate the scale ratio
    let scaleRatio = 1;
    if (reigonWidth > reigonHeight) {
      scaleRatio = this.waferMapVariables.viewPortWidth / reigonWidth;
    } else {
      scaleRatio = this.waferMapVariables.viewPortHeight / reigonHeight;
    }
    // Now based on which die side is smaller we would adjust the die size
    if (this.waferMapVariables.dieWidth < this.waferMapVariables.dieHeight) {
      this.waferMapVariables.scaledDieWidth = Math.round(this.waferMapVariables.scaledDieWidth * scaleRatio);
      this.waferMapVariables.scaledDieHeight = this.waferMapVariables.scaledDieWidth
      * (this.waferMapVariables.dieHeight / this.waferMapVariables.dieWidth);
    } else {
      this.waferMapVariables.scaledDieHeight = Math.round(this.waferMapVariables.scaledDieHeight * scaleRatio);
      this.waferMapVariables.scaledDieWidth = this.waferMapVariables.scaledDieHeight
      * (this.waferMapVariables.dieWidth / this.waferMapVariables.dieHeight);
    }

    // wafer map will draw in center!!!...
    // We need to make micro adjustments to find the delta within a die. Ie where the selection started.
    //  this needs to be done on dies prior to zoom

    // push the anchor to full dies after we have the new dieWidths/Heights
    this.waferMapVariables.XAnchor = -this.waferMapVariables.scaledDieWidth * indicesObj.startArrayX;
    this.waferMapVariables.YAnchor = -this.waferMapVariables.scaledDieHeight * indicesObj.startArrayY;
    this.waferMapVariables.XAnchor -= this.waferMapVariables.scaledDieWidth * inDieDeltaX;
    this.waferMapVariables.YAnchor -= this.waferMapVariables.scaledDieHeight * inDieDeltaY;
    this.waferMapVariables.zoomScaleFactor = (this.waferMapVariables.scaledDieWidth
      * this.waferMapVariables.waferMapTestData[0].length) / (this.waferMapVariables.intialScaledDieWidth
      * this.waferMapVariables.waferMapTestData[0].length);
    if (this.waferMapVariables.gl == null) return;
    this.waferMapVariables.selectionCoord = null;
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl.canvas.width,
        height: this.waferMapVariables.gl.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  WCMOnChangeWaferMapVariables = (data: { waferData: { [key:string] : any }, shouldReRender: boolean }) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data.waferData)) {
      if (key in this.waferMapVariables) {
        this.waferMapVariables[key] = value;
      }
    }
    if (data.shouldReRender) this.reRender();
  };

  addReticleInfoToDie = (i: number, j: number, dieData: WaferMapData, reticleSize: { x: number, y: number }, reticleTopLeft: { x: number, y: number }, standardReticle: StandardReticle) => {
    // helper method
    const retX = (reticleSize.x - ((reticleTopLeft.x - j) % reticleSize.x)) % reticleSize.x;
    const retY = (reticleSize.y - ((reticleTopLeft.y - i) % reticleSize.y)) % reticleSize.y;
    dieData.reticleX = retX;
    dieData.reticleY = retY;
    dieData.reticleSite = standardReticle.reticle[retY][retX];
    if (standardReticle.reticle[retY][retX] === +standardReticle.WATPCMSite) {
      dieData.dieType = UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', this.waferMapVariables.dieTypes.dieType) || '';
    }
    return ((j - reticleTopLeft.x) < reticleSize.x)
    && ((j - reticleTopLeft.x) >= 0) && ((i - reticleTopLeft.y) < reticleSize.y) && ((i - reticleTopLeft.y) >= 0);
  };

  isDieOutsideFlatOrNotch =
  (multiplier: number, center: number, topLeft: XYPoint, topRight: XYPoint, bottomLeft: XYPoint, bottomRight: XYPoint, axis: 'x' | 'y', distanceFromCenterToKeepOutLine: number) => {
    return (
      multiplier * (center - topLeft[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - topRight[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - bottomLeft[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - bottomRight[axis]) > distanceFromCenterToKeepOutLine
    );
  };

  getDieCoordsFromAnchorAndDieDimension = (i: number, j: number, width: number, height: number, xAnchor: number, yAnchor: number, canvas: Canvas, rotation: number[]) => {
    const startXBasedOnOrignalWaferState = j * width + xAnchor;
    const startYBasedOnOrignalWaferState = i * height + yAnchor;
    const endXBasedOnOrignalWaferState = startXBasedOnOrignalWaferState + width;
    const endYBasedOnOrignalWaferState = startYBasedOnOrignalWaferState + height;
    const rotatedDieCoords = this.rotatedCoords([
      startXBasedOnOrignalWaferState, startYBasedOnOrignalWaferState,
      endXBasedOnOrignalWaferState, startYBasedOnOrignalWaferState,
      startXBasedOnOrignalWaferState, endYBasedOnOrignalWaferState,
      startXBasedOnOrignalWaferState, endYBasedOnOrignalWaferState,
      endXBasedOnOrignalWaferState, startYBasedOnOrignalWaferState,
      endXBasedOnOrignalWaferState, endYBasedOnOrignalWaferState,
    ], canvas, rotation, // rotation corresponding to 0 degrees
    );
    return [
      rotatedDieCoords[0].x, rotatedDieCoords[0].y,
      rotatedDieCoords[1].x, rotatedDieCoords[1].y,
      rotatedDieCoords[2].x, rotatedDieCoords[2].y,
      rotatedDieCoords[3].x, rotatedDieCoords[3].y,
      rotatedDieCoords[5].x, rotatedDieCoords[5].y,
      rotatedDieCoords[4].x, rotatedDieCoords[4].y,
    ];
  };

  isReticleCollidingWithKeepOut = (
    data: {
      dieWidth: number,
      dieHeight: number,
      reticleSize: XYPoint,
      dieData: WaferMapData,
      notchPosition: NotchPosition,
      yAnchor: number,
      xAnchor: number,
      center: number,
      multiplier: number,
      distanceFromCenterToKeepOutLine: number,
    },
  ) => {
    let reticleBorder;
    const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(data.dieData, data.reticleSize);
    switch (data.notchPosition) {
      case NotchPosition.DOWN:
        reticleBorder = reticleRectCoordOfCurrDie.endY * data.dieHeight + data.yAnchor + data.dieHeight;
        break;
      case NotchPosition.UP:
        reticleBorder = reticleRectCoordOfCurrDie.startY * data.dieHeight + data.yAnchor;
        break;
      case NotchPosition.RIGHT:
        reticleBorder = reticleRectCoordOfCurrDie.endX * data.dieWidth + data.xAnchor + data.dieWidth;
        break;
      case NotchPosition.LEFT:
        reticleBorder = reticleRectCoordOfCurrDie.startX * data.dieWidth + data.xAnchor;
        break;
      default:
        reticleBorder = 0;
        break;
    }
    return data.multiplier * (data.center - reticleBorder) > data.distanceFromCenterToKeepOutLine;
  };

  performCropDueToKeepOut = (
    data: {
      i: number, j: number, dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number,
      wcmExclusionType: ExclusionType, dieWidthToStreetWidthRatio: number, dieHeightToStreetHeightRatio: number,
      waferCenterX: number, waferCenterY: number, wcmWaferDiameter: number, wcmWaferNotchKeepOut: number,
      wcmWaferFlatKeepOut: number, wcmWaferBaseFlat: number, wcmWaferScribeLine: number, widthOfBg: number,
      heightOfBg: number, notchPosition: NotchPosition, dieData: WaferMapData, reticleSize: XYPoint,
    },
  ): void => {
    // startX startY endX endY are coords of the entire step => die and street both
    const startX = data.j * data.dieWidth + data.xAnchor;
    const startY = data.i * data.dieHeight + data.yAnchor;
    const endX = startX + data.dieWidth;
    const endY = startY + data.dieHeight;

    const sw = data.dieWidth / data.dieWidthToStreetWidthRatio;
    const sh = data.dieHeight / data.dieHeightToStreetHeightRatio;

    // dieBorderSX dieBorderSY dieBorderEX dieBorderEY are coords of the inner die
    const dieBorderSX = startX + sw / 2;
    const dieBorderSY = startY + sh / 2;
    const dieBorderEX = endX - sw / 2;
    const dieBorderEY = endY - sh / 2;

    const topLeft = { x: dieBorderSX, y: dieBorderSY };
    const topRight = { x: dieBorderEX, y: dieBorderSY };
    const bottomLeft = { x: dieBorderSX, y: dieBorderEY };
    const bottomRight = { x: dieBorderEX, y: dieBorderEY };

    let multiplier = 1;
    let center = data.waferCenterY;
    let axis: 'x' | 'y' = 'y';
    const radiusInInches = (data.wcmWaferDiameter / 2);
    let distanceFromCenterInInches = radiusInInches;
    switch (data.wcmExclusionType) {
      case ExclusionType.NOTCH_KEEP_OUT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferNotchKeepOut;
        break;
      case ExclusionType.FLAT_KEEP_OUT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferFlatKeepOut;
        break;
      case ExclusionType.SCRIBE_LINE_AND_BASE_FLAT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferBaseFlat - data.wcmWaferScribeLine;
        break;
      default:
        break;
    }
    let distanceFromCenterToKeepOutLine = (distanceFromCenterInInches * data.heightOfBg) / data.wcmWaferDiameter;
    if (data.notchPosition === NotchPosition.LEFT || data.notchPosition === NotchPosition.RIGHT) {
      center = data.waferCenterX;
      axis = 'x';
      distanceFromCenterToKeepOutLine = (distanceFromCenterInInches * data.widthOfBg) / data.wcmWaferDiameter;
    }
    if (data.notchPosition === NotchPosition.DOWN || data.notchPosition === NotchPosition.RIGHT) {
      multiplier = -1;
    }
    if (this.isDieOutsideFlatOrNotch(multiplier, center, topLeft, topRight, bottomLeft, bottomRight, axis, distanceFromCenterToKeepOutLine)) {
      data.dieData.cropped = true;
    }
    if (data.wcmWaferDiameter !== WAFER_DIAMETER_EXEMPTED_FROM_RETICLE_COLLISION_CROPPING && this.isReticleCollidingWithKeepOut({
      dieWidth: data.dieWidth,
      dieHeight: data.dieHeight,
      reticleSize: data.reticleSize,
      dieData: data.dieData,
      notchPosition: data.notchPosition,
      yAnchor: data.yAnchor,
      xAnchor: data.xAnchor,
      center,
      multiplier,
      distanceFromCenterToKeepOutLine,
    })) {
      data.dieData.cropped = true;
    }
  };

  performWCMActionCrop = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, showRing, ringDiameterToWaferDiameterRatio, scaledDieWidth, scaledDieHeight, XAnchor, YAnchor, reticleSize,
      waferWidthToColsRatio, waferHeightToRowsRatio, waferBGOffsetXDies, waferBGOffsetYDies, dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio, wcmWaferNotchKeepOut, wcmWaferDiameter, notchPosition, wcmExclusionType, wcmWaferFlatKeepOut, gl,
      wcmWaferBaseFlat, wcmWaferScribeLine, startRow, startCol, rowDirection, colDirection, intialScaledDieHeight, intialScaledDieWidth, XAnchorIntialPosition, YAnchorIntialPosition,
    } = this.waferMapVariables;

    const rows = this.waferMapVariables.waferMapTestData.length;
    const cols = this.waferMapVariables.waferMapTestData[0].length;
    const mWidth = scaledDieWidth * cols;
    const mHeight = scaledDieHeight * rows;
    const widthOfBg = mWidth * waferWidthToColsRatio;
    const heightOfBg = mHeight * waferHeightToRowsRatio;
    const waferCenterX = XAnchor + mWidth / 2 + waferBGOffsetXDies * scaledDieWidth;
    const waferCenterY = YAnchor + mHeight / 2 + waferBGOffsetYDies * scaledDieHeight;

    if (!showRing || !gl) return null;
    const allDiesCoords: number[] = [];
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        const dieData = waferMapTestData[p][q];

        if (dieData) {
          if (dieData.location !== DieLocation.INSIDE_RING) {
            dieData.cropped = true;
          }

          this.performCropDueToKeepOut({
            i,
            j,
            dieWidth: scaledDieWidth,
            dieHeight: scaledDieHeight,
            xAnchor: XAnchor,
            yAnchor: YAnchor,
            wcmExclusionType,
            dieWidthToStreetWidthRatio,
            dieHeightToStreetHeightRatio,
            waferCenterX,
            waferCenterY,
            wcmWaferDiameter,
            wcmWaferNotchKeepOut,
            wcmWaferFlatKeepOut,
            wcmWaferBaseFlat,
            wcmWaferScribeLine,
            widthOfBg,
            heightOfBg,
            notchPosition,
            dieData,
            reticleSize,
          });

          if (!dieData.cropped && !dieData.deleted) {
            allDiesCoords.push(...this.getDieCoordsFromAnchorAndDieDimension(
              i,
              j,
              intialScaledDieWidth,
              intialScaledDieHeight,
              XAnchorIntialPosition,
              YAnchorIntialPosition,
              {
                width: gl.canvas.width,
                height: gl.canvas.height,
              },
              [0, 1],
            ));// rotation corresponding to 0 degrees
          }
        }
      }
    }
    this.waferMapVariables.radarDiesCoords = [...allDiesCoords];
    return { data: waferMapTestData, action: data.action, ringDiameterToWaferDiameterRatio };
  };

  performWCMActionDelete = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, startCol, startRow, rowDirection, colDirection, gl, intialScaledDieWidth,
      intialScaledDieHeight, XAnchorIntialPosition, YAnchorIntialPosition,
    } = this.waferMapVariables;
    if (!gl) return null;
    const allDiesCoords: number[] = [];
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        const dieData = waferMapTestData[p][q];
        if (dieData) {
          if (dieData.selected) {
            dieData.deleted = true;
            dieData.selected = false;
          }
          if (!dieData.cropped && !dieData.deleted) {
            allDiesCoords.push(...this.getDieCoordsFromAnchorAndDieDimension(
              i,
              j,
              intialScaledDieWidth,
              intialScaledDieHeight,
              XAnchorIntialPosition,
              YAnchorIntialPosition,
              {
                width: gl.canvas.width,
                height: gl.canvas.height,
              },
              [0, 1],
            ));// rotation corresponding to 0 degrees
          }
        }
      }
    }
    this.waferMapVariables.radarDiesCoords = [...allDiesCoords];
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionRedraw = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, showRing, ringDiameterToWaferDiameterRatio, startRow, startCol,
      rowDirection, colDirection, intialScaledDieWidth, intialScaledDieHeight, XAnchorIntialPosition,
      YAnchorIntialPosition, gl,
    } = this.waferMapVariables;
    if (!showRing || !gl) return null;
    const allDiesCoords: number[] = [];
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        const dieData = waferMapTestData[i][j];
        if (dieData) {
          if (dieData.location === DieLocation.INSIDE_RING) {
            dieData.cropped = false;
          }
          if (!dieData.cropped && !dieData.deleted) {
            allDiesCoords.push(...this.getDieCoordsFromAnchorAndDieDimension(
              i,
              j,
              intialScaledDieWidth,
              intialScaledDieHeight,
              XAnchorIntialPosition,
              YAnchorIntialPosition,
              {
                width: gl.canvas.width,
                height: gl.canvas.height,
              },
              [0, 1],
            ));// rotation corresponding to 0 degrees
          }
        }
      }
    }
    this.waferMapVariables.radarDiesCoords = [...allDiesCoords];
    return { data: waferMapTestData, action: data.action, ringDiameterToWaferDiameterRatio };
  };

  performWCMActionApplyReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const { waferMapTestData, reticleSize, standardReticle } = this.waferMapVariables;
    if (standardReticle !== null) {
      this.waferMapVariables.reticleGridRectCoords = {}; // empty older reticle coords
      this.waferMapVariables.referenceReticleGridRectCoords = null;
      for (let i = 0; i < waferMapTestData.length; i += 1) {
        for (let j = 0; j < waferMapTestData[i].length; j += 1) {
          const dieData = waferMapTestData[i][j];
          if (dieData !== null) {
            const isDieInReferenceReticle = this.addReticleInfoToDie(i, j, dieData, reticleSize, data.config.reticleTopLeft, standardReticle);
            // reticle stored as dictionary
            const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, reticleSize);
            this.waferMapVariables.reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)] = {
              isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
            };
            if (isDieInReferenceReticle) {
              this.waferMapVariables.referenceReticleGridRectCoords = _.cloneDeep(reticleRectCoordOfCurrDie);
            }
          }
        }
      }
      this.waferMapVariables.reticleXAxisReference = this.getReticleAxisReference(this.waferMapVariables.reticleGridRectCoords, 'x');
      this.waferMapVariables.reticleYAxisReference = this.getReticleAxisReference(this.waferMapVariables.reticleGridRectCoords, 'y');
    }
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionApplyFullWATMap = (data: { action: ActionOnWaferData, config: any }) => {
    const { waferMapTestData } = this.waferMapVariables;

    const arr = Object.keys(this.waferMapVariables.reticleGridRectCoords).map((x: any) => { return JSON.parse(x); });
    const sortedArr: RectCoord[] = _.orderBy(arr, ['startY', 'startX'], ['asc']);
    for (let i = 0; i < sortedArr.length; i += 1) {
      this.waferMapVariables.reticleGridRectCoords[JSON.stringify(sortedArr[i])].watPCMSiteNumber = i + 1;
    }
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionDefault = (data: { action: ActionOnWaferData, config: any }) => {
    const { waferMapTestData } = this.waferMapVariables;
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionCropSelectedReticlesOnWafer = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, colOffset, rowOffset, waferMapTestData,
    } = this.waferMapVariables;
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [key, value] of Object.entries(reticleGridRectCoords)) {
      if (reticleGridRectCoords[key].isSelected) {
        delete reticleGridRectCoords[key];
        const reticleRectCoord: RectCoord = JSON.parse(key);
        for (let i = reticleRectCoord.startY - rowOffset; i <= reticleRectCoord.endY - rowOffset; i += 1) {
          for (let j = reticleRectCoord.startX - colOffset; j <= reticleRectCoord.endX - colOffset; j += 1) {
            if (waferMapTestData[i] && waferMapTestData[i][j]) {
              UtilityFunctions.deleteReticleInfo(waferMapTestData[i][j]!, this.waferMapVariables.dieTypes.dieType);
            }
          }
        }
      }
    }
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionPanReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, colOffset, rowOffset, waferMapTestData, reticleSize,
    } = this.waferMapVariables;

    const newReticleGridRectCoords = _.cloneDeep(reticleGridRectCoords);

    // adjust the increment depending on whether UOM is die or reticle
    let incrementX = data.config.increment;
    let incrementY = data.config.increment;
    if (data.config.UOM === 'reticle') {
      incrementY = data.config.increment * reticleSize.y;
      incrementX = data.config.increment * reticleSize.x;
    }

    const oldReticles: ReticleGridRectCoords = {};
    // delete old reticles
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      if (key in reticleGridRectCoords) {
        oldReticles[key] = _.cloneDeep(reticleGridRectCoords[key]);
        delete newReticleGridRectCoords[key];
      }
    }

    // add new reticles
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      if (data.config.direction === ReticlePanDirection.UP) {
        reticleRectCoord.startY -= incrementY;
        reticleRectCoord.endY -= incrementY;
      } else if (data.config.direction === ReticlePanDirection.DOWN) {
        reticleRectCoord.startY += incrementY;
        reticleRectCoord.endY += incrementY;
      } else if (data.config.direction === ReticlePanDirection.LEFT) {
        reticleRectCoord.startX -= incrementX;
        reticleRectCoord.endX -= incrementX;
      } else if (data.config.direction === ReticlePanDirection.RIGHT) {
        reticleRectCoord.startX += incrementX;
        reticleRectCoord.endX += incrementX;
      }

      if (key in reticleGridRectCoords) {
        if (data.config.mode === ReticlePanMode.SELECTED && JSON.stringify(reticleRectCoord) in newReticleGridRectCoords) {
          toast.warn('Reticles cannot overlap with each other.');
          return null;
        }
        newReticleGridRectCoords[JSON.stringify(reticleRectCoord)] = oldReticles[key];
      }
    }

    // check for overlap
    if (data.config.mode === ReticlePanMode.SELECTED) {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const key1 in newReticleGridRectCoords) {
        if (newReticleGridRectCoords[key1].isSelected) {
          // eslint-disable-next-line guard-for-in, no-restricted-syntax
          for (const key2 in newReticleGridRectCoords) {
            const selectedReticleRectCoord: RectCoord = JSON.parse(key1);
            const reticleRectCoord: RectCoord = JSON.parse(key2);
            if (
              selectedReticleRectCoord.endX >= reticleRectCoord.startX
              && selectedReticleRectCoord.startX <= reticleRectCoord.endX
              && selectedReticleRectCoord.endY >= reticleRectCoord.startY
              && selectedReticleRectCoord.startY <= reticleRectCoord.endY
              && !_.isEqual(selectedReticleRectCoord, reticleRectCoord)
            ) {
              toast.warn('Reticles cannot overlap with each other.');
              return null;
            }
          }
        }
      }
    }

    // delete reticle info from old dies in selected reticle because it is going to be moved
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      for (let i = reticleRectCoord.startY - rowOffset; i <= reticleRectCoord.endY - rowOffset; i += 1) {
        for (let j = reticleRectCoord.startX - colOffset; j <= reticleRectCoord.endX - colOffset; j += 1) {
          if (waferMapTestData[i] && waferMapTestData[i][j]) {
            UtilityFunctions.deleteReticleInfo(waferMapTestData[i][j]!, this.waferMapVariables.dieTypes.dieType);
          }
        }
      }
    }

    // adding reticle info to dies where reticle is moved
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in newReticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !newReticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      for (let i = reticleRectCoord.startY - rowOffset, k = 0; i <= reticleRectCoord.endY - rowOffset; i += 1, k += 1) {
        for (let j = reticleRectCoord.startX - colOffset, l = 0; j <= reticleRectCoord.endX - colOffset; j += 1, l += 1) {
          if (waferMapTestData[i] && waferMapTestData[i][j]) {
            waferMapTestData[i][j]!.reticleX = l;
            waferMapTestData[i][j]!.reticleY = k;
            waferMapTestData[i][j]!.reticleSite = this.waferMapVariables.standardReticle!.reticle[k][l];
            if (this.waferMapVariables.standardReticle!.reticle[k][l] === +this.waferMapVariables.standardReticle!.WATPCMSite) {
              waferMapTestData[i][j]!.dieType = UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', this.waferMapVariables.dieTypes.dieType) || '';
            }
          }
        }
      }
    }
    this.waferMapVariables.reticleGridRectCoords = newReticleGridRectCoords;
    return { data: waferMapTestData, action: data.action };
  };

  isNewReticleSpaceOccupied = (direction: ReticleInsertDirection, currReticle: RectCoord, affectingReticle: RectCoord, reticleSize: { x: number, y: number }) => {
    // helper method
    return (
      (
        (
          (
            (direction === ReticleInsertDirection.DOWN && currReticle.startY > affectingReticle.endY && currReticle.startY <= affectingReticle.endY + reticleSize.y)
            || (direction === ReticleInsertDirection.UP && currReticle.endY < affectingReticle.startY && currReticle.endY >= affectingReticle.startY - reticleSize.y)
          )
          && currReticle.startX <= affectingReticle.endX
          && currReticle.endX >= affectingReticle.startX
        )
    || (
      (
        (direction === ReticleInsertDirection.RIGHT && currReticle.startX > affectingReticle.endX && currReticle.startX <= affectingReticle.endX + reticleSize.x)
            || (direction === ReticleInsertDirection.LEFT && currReticle.endX < affectingReticle.startX && currReticle.endX >= affectingReticle.startX - reticleSize.x)
      )
        && currReticle.startY <= affectingReticle.endY
        && currReticle.endY >= affectingReticle.startY
    )
      )
   && !_.isEqual(affectingReticle, currReticle)
    );
  };

  performWCMActionInsertReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, waferMapTestData, reticleSize,
    } = this.waferMapVariables;

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const selectedReticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[selectedReticleKey].isSelected) {
        const selectedReticle: RectCoord = JSON.parse(selectedReticleKey);

        let canInsert = true;

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const reticleKey in this.waferMapVariables.reticleGridRectCoords) {
          const currReticle: RectCoord = JSON.parse(reticleKey);
          if (this.isNewReticleSpaceOccupied(data.config.direction, currReticle, selectedReticle, reticleSize)) {
            canInsert = false;
            break;
          }
        }

        if (canInsert) {
          const newReticle = _.cloneDeep(selectedReticle);
          if (data.config.direction === ReticleInsertDirection.LEFT) {
            newReticle.startX -= reticleSize.x;
            newReticle.endX -= reticleSize.x;
          }
          if (data.config.direction === ReticleInsertDirection.RIGHT) {
            newReticle.startX += reticleSize.x;
            newReticle.endX += reticleSize.x;
          }
          if (data.config.direction === ReticleInsertDirection.DOWN) {
            newReticle.startY += reticleSize.y;
            newReticle.endY += reticleSize.y;
          }
          if (data.config.direction === ReticleInsertDirection.UP) {
            newReticle.startY -= reticleSize.y;
            newReticle.endY -= reticleSize.y;
          }
          this.waferMapVariables.reticleGridRectCoords[JSON.stringify(newReticle)] = {
            isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
          };
        } else {
          toast.warn(`Can not insert reticle in ${ReticleInsertDirection[data.config.direction].toLowerCase()}
            direction of the following reticle: startX = ${selectedReticle.startX},  startY = ${selectedReticle.startY}, endX = ${selectedReticle.endX}, endY = ${selectedReticle.endY}`);
        }
      }
    }
    return { data: waferMapTestData, action: data.action };
  };

  getPcmSitesInRadialZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    XAnchor: number,
    YAnchor: number,
    rows: number,
    cols: number,
    panOffsetXToDieStepSizeXRatio: number,
    panOffsetYToDieStepSizeYRatio: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    waferMapTestData: WaferMapTestData,
    markRadialInfo: boolean,
  }) => {
    const dieWidth = data.scaledDieWidth;
    const dieHeight = data.scaledDieHeight;
    const offsetX = data.XAnchor;
    const offsetY = data.YAnchor;
    const mWidth = dieWidth * data.cols;
    const mHeight = dieHeight * data.rows;

    const centerX = offsetX + mWidth / 2 + data.waferBGOffsetXDies * dieWidth;
    const centerY = offsetY + mHeight / 2 + data.waferBGOffsetYDies * dieHeight;
    const ringCenterX = centerX;
    const ringCenterY = centerY;

    const reticles = Object.keys(data.reticleGridRectCoords);
    const ringCenter = { x: ringCenterX, y: ringCenterY };
    const widthOfBg = mWidth * data.waferWidthToColsRatio;
    const heightOfBg = mHeight * data.waferHeightToRowsRatio;
    const maxRingRadiusY = Math.max(widthOfBg, heightOfBg) / 2;

    const ringRadiusExcludingEdgeExclusion = ((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter) * maxRingRadiusY;
    const incrementRadiusSqred = (ringRadiusExcludingEdgeExclusion ** 2) / data.numberOfZones!;

    const pcmSitesInRadialZone: any = [];
    for (let i = 0; i < data.numberOfZones!; i += 1) {
      pcmSitesInRadialZone[i] = [];
    }

    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleRect: RectCoord = {
        startX: offsetX + (currReticle.startX - data.colOffset) * dieWidth,
        startY: offsetY + (currReticle.startY - data.rowOffset) * dieHeight,
        endX: offsetX + (currReticle.endX - data.colOffset) * dieWidth + dieWidth,
        endY: offsetY + (currReticle.endY - data.rowOffset) * dieHeight + dieHeight,
      };
      const reticleCenter = { x: (reticleRect.startX + reticleRect.endX) / 2, y: (reticleRect.startY + reticleRect.endY) / 2 };

      let currRadiusSqred = incrementRadiusSqred;
      for (let r = 0; r < data.numberOfZones!; r += 1, currRadiusSqred += incrementRadiusSqred) {
        const ringRadius = Math.sqrt(currRadiusSqred);
        if (this.euclideanDistance(reticleCenter, ringCenter) < ringRadius) {
          if (data.markRadialInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.waferMapTestData[l] && data.waferMapTestData[l][m]) {
                  data.waferMapTestData[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInRadialZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }
    return pcmSitesInRadialZone;
  };

  getPcmSitesInVericleZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    XAnchor: number,
    YAnchor: number,
    rows: number,
    cols: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    waferMapTestData: WaferMapTestData,
    markInfo: boolean,
    startRow: number,
    startCol: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    rowDirection: number,
    colDirection: number,
    selectedZoneId: string,
    dieTypes: any,
    currZone: ZoneData | null,
  }) => {
    const dieWidth = data.scaledDieWidth;
    const dieHeight = data.scaledDieHeight;
    const offsetX = data.XAnchor;
    const offsetY = data.YAnchor;
    const mWidth = dieWidth * data.waferMapTestData[0].length;
    const mHeight = dieHeight * data.waferMapTestData.length;
    const waferCenterX = offsetX + mWidth / 2 + data.waferBGOffsetXDies * dieWidth;
    const waferCenterY = offsetY + mHeight / 2 + data.waferBGOffsetYDies * dieHeight;
    const widthOfBg = mWidth * data.waferWidthToColsRatio;
    const heightOfBg = mHeight * data.waferHeightToRowsRatio;
    const waferCenter = { x: waferCenterX, y: waferCenterY };
    const waferRadiusExcludingEdgeExclusion = (((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);
    const rows = data.waferMapTestData.length;
    const cols = data.waferMapTestData[0].length;

    const noOfDiesInsideWaferInColumn = [];
    let totalDies = 0;
    for (let j = 0, q = data.startCol; j < cols; j += 1, q += data.colDirection) {
      let count = 0;
      for (let i = 0, p = data.startRow; i < rows; i += 1, p += data.rowDirection) {
        if (data.waferMapTestData[p][q]) {
          if (data.markInfo) this.deleteOldZoneInfoFromDie(data.waferMapTestData[p][q]!, data.selectedZoneId);
          if (
            this.isDieEligibleForMarkingZone(
              waferRadiusExcludingEdgeExclusion,
              data.waferMapTestData[p][q]!,
              data.rowOffset,
              data.colOffset,
              data.scaledDieWidth,
              data.scaledDieHeight,
              data.dieWidthToStreetWidthRatio,
              data.dieHeightToStreetHeightRatio,
              data.XAnchor,
              data.YAnchor,
              waferCenter,
              data.dieTypes,
            )
          ) {
            count += 1;
            totalDies += 1;
            if (data.markInfo) data.waferMapTestData[p][q]!.shouldConsiderForVerticleZone = true;
          } else {
            // eslint-disable-next-line no-lonely-if
            if (data.markInfo) data.waferMapTestData[p][q]!.shouldConsiderForVerticleZone = false;
          }
        }
      }
      noOfDiesInsideWaferInColumn.push(count);
    }

    const verticalZoneColNumbers: number[] = this.getVerticalZoneColNumbers(totalDies, data.numberOfZones, noOfDiesInsideWaferInColumn, rows, data.colOffset);
    if (data.markInfo) data.currZone!.verticalZoneColNumbers = verticalZoneColNumbers;

    const pcmSitesInVerticalZone: any = [];
    for (let i = 0; i < data.numberOfZones; i += 1) {
      pcmSitesInVerticalZone[i] = [];
    }

    const verticalZoneColNumbersIncludingLast = [...verticalZoneColNumbers, this.getMaxColNumFromAllReticles(data.reticleGridRectCoords)];
    if (data.numberOfZones !== verticalZoneColNumbersIncludingLast.length) return null;
    const reticles = Object.keys(data.reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleCenter = { x: (currReticle.startX + currReticle.endX) / 2, y: (currReticle.startY + currReticle.endY) / 2 };
      for (let r = 0; r < data.numberOfZones; r += 1) {
        if (reticleCenter.x < verticalZoneColNumbersIncludingLast[r]) {
          if (data.markInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.waferMapTestData[l] && data.waferMapTestData[l][m] && data.waferMapTestData[l][m]!.shouldConsiderForVerticleZone) {
                  data.waferMapTestData[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInVerticalZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }

    return pcmSitesInVerticalZone;
  };

  getMaxRowNumFromAllReticles = (reticleGridRectCoords: ReticleGridRectCoords) => {
    let max = Number.MIN_SAFE_INTEGER;
    const reticles = Object.keys(reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      if ((JSON.parse(reticles[i]) as RectCoord).endY > max) {
        max = (JSON.parse(reticles[i]) as RectCoord).endY;
      }
    }
    return max;
  };

  getMaxColNumFromAllReticles = (reticleGridRectCoords: ReticleGridRectCoords) => {
    let max = Number.MIN_SAFE_INTEGER;
    const reticles = Object.keys(reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      if ((JSON.parse(reticles[i]) as RectCoord).endX > max) {
        max = (JSON.parse(reticles[i]) as RectCoord).endX;
      }
    }
    return max;
  };

  isDieInsideGivenRadiusFromCenter = (
    radius: number,
    dieData: WaferMapData,
    rowOffset: number,
    colOffset: number,
    dieWidth: number,
    dieHeight: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    offsetX: number,
    offsetY: number,
    waferCenter: { x: number, y: number },
  ) => {
    const startX = (dieData.x! - colOffset) * dieWidth + offsetX;
    const startY = (dieData.y! - rowOffset) * dieHeight + offsetY;
    const endX = startX + dieWidth;
    const endY = startY + dieHeight;

    const sw = dieWidth / dieWidthToStreetWidthRatio;
    const sh = dieHeight / dieHeightToStreetHeightRatio;

    const dieBorderSX = startX + sw / 2;
    const dieBorderSY = startY + sh / 2;
    const dieBorderEX = endX - sw / 2;
    const dieBorderEY = endY - sh / 2;

    const topLeft = { x: dieBorderSX, y: dieBorderSY };
    const topRight = { x: dieBorderEX, y: dieBorderSY };
    const bottomLeft = { x: dieBorderSX, y: dieBorderEY };
    const bottomRight = { x: dieBorderEX, y: dieBorderEY };

    return (
      this.euclideanDistance(topLeft, waferCenter) < radius
      && this.euclideanDistance(topRight, waferCenter) < radius
      && this.euclideanDistance(bottomLeft, waferCenter) < radius
      && this.euclideanDistance(bottomRight, waferCenter) < radius
    );
  };

  isDieEligibleForMarkingZone = (
    radius: number,
    dieData: WaferMapData,
    rowOffset: number,
    colOffset: number,
    dieWidth: number,
    dieHeight: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    offsetX: number,
    offsetY: number,
    waferCenter: { x: number, y: number },
    dieTypes: any,
  ) => {
    return (
      this.isDieInsideGivenRadiusFromCenter(
        radius,
        dieData,
        rowOffset,
        colOffset,
        dieWidth,
        dieHeight,
        dieWidthToStreetWidthRatio,
        dieHeightToStreetHeightRatio,
        offsetX,
        offsetY,
        waferCenter,
      ) && dieData.dieType !== UtilityFunctions.getDieTypeIdFromName('Edge Die', dieTypes.dieType)
      && dieData.dieType !== UtilityFunctions.getDieTypeIdFromName('Ugly/Partial Die', dieTypes.dieType)
    );
  };

  getPcmSitesInHorizontalZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    XAnchor: number,
    YAnchor: number,
    rows: number,
    cols: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    waferMapTestData: WaferMapTestData,
    markInfo: boolean,
    startRow: number,
    startCol: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    rowDirection: number,
    colDirection: number,
    selectedZoneId: string,
    dieTypes: any,
    currZone: ZoneData | null,
  }) => {
    const dieWidth = data.scaledDieWidth;
    const dieHeight = data.scaledDieHeight;
    const offsetX = data.XAnchor;
    const offsetY = data.YAnchor;
    const mWidth = dieWidth * data.waferMapTestData[0].length;
    const mHeight = dieHeight * data.waferMapTestData.length;
    const waferCenterX = offsetX + mWidth / 2 + data.waferBGOffsetXDies * dieWidth;
    const waferCenterY = offsetY + mHeight / 2 + data.waferBGOffsetYDies * dieHeight;
    const widthOfBg = mWidth * data.waferWidthToColsRatio;
    const heightOfBg = mHeight * data.waferHeightToRowsRatio;
    const waferCenter = { x: waferCenterX, y: waferCenterY };
    const waferRadiusExcludingEdgeExclusion = (((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);
    const rows = data.waferMapTestData.length;
    const cols = data.waferMapTestData[0].length;

    const noOfDiesInsideWaferInRow = [];
    let totalDies = 0;
    for (let i = 0, p = data.startRow; i < rows; i += 1, p += data.rowDirection) {
      let count = 0;
      for (let j = 0, q = data.startCol; j < cols; j += 1, q += data.colDirection) {
        if (data.waferMapTestData[p][q]) {
          if (data.markInfo) this.deleteOldZoneInfoFromDie(data.waferMapTestData[p][q]!, data.selectedZoneId);
          if (
            this.isDieEligibleForMarkingZone(
              waferRadiusExcludingEdgeExclusion,
              data.waferMapTestData[p][q]!,
              data.rowOffset,
              data.colOffset,
              data.scaledDieWidth,
              data.scaledDieHeight,
              data.dieWidthToStreetWidthRatio,
              data.dieHeightToStreetHeightRatio,
              data.XAnchor,
              data.YAnchor,
              waferCenter,
              data.dieTypes,
            )
          ) {
            count += 1;
            totalDies += 1;
            if (data.markInfo) data.waferMapTestData[p][q]!.shouldConsiderForHorizontalZone = true;
          } else {
            // eslint-disable-next-line no-lonely-if
            if (data.markInfo) data.waferMapTestData[p][q]!.shouldConsiderForHorizontalZone = false;
          }
        }
      }
      noOfDiesInsideWaferInRow.push(count);
    }
    const horizontalZoneRowNumbers: number[] = this.getHorizontalZoneRowNumbers(totalDies, data.numberOfZones, noOfDiesInsideWaferInRow, cols, data.rowOffset);
    if (data.markInfo) data.currZone!.horizontalZoneRowNumbers = horizontalZoneRowNumbers;
    const pcmSitesInHorizontalZone: any = [];
    for (let i = 0; i < data.numberOfZones; i += 1) {
      pcmSitesInHorizontalZone[i] = [];
    }

    const horizontalZoneNumbersIncludingLast = [...horizontalZoneRowNumbers, this.getMaxRowNumFromAllReticles(data.reticleGridRectCoords)];
    if (data.numberOfZones !== horizontalZoneNumbersIncludingLast.length) return null;
    const reticles = Object.keys(data.reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleCenter = { x: (currReticle.startX + currReticle.endX) / 2, y: (currReticle.startY + currReticle.endY) / 2 };
      for (let r = 0; r < data.numberOfZones; r += 1) {
        if (reticleCenter.y < horizontalZoneNumbersIncludingLast[r]) {
          if (data.markInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.waferMapTestData[l] && data.waferMapTestData[l][m] && data.waferMapTestData[l][m]!.shouldConsiderForHorizontalZone) {
                  data.waferMapTestData[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInHorizontalZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }

    return pcmSitesInHorizontalZone;
  };

  performWCMActionApplyRadialZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, reticleGridRectCoords,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, colOffset, rowOffset, zones,
      panOffsetXToDieStepSizeXRatio, panOffsetYToDieStepSizeYRatio, waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferMapTestData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'RADIAL' && currZone.numberOfZones === undefined) return { data: waferMapTestData, action: data.action };

    // delete old zone info from dies
    this.deleteOldZoneInfoFromDies(startRow, startCol, waferMapTestData, rowDirection, colDirection, data.config.selectedZoneId);

    const pcmSitesInRadialZone = this.getPcmSitesInRadialZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      panOffsetXToDieStepSizeXRatio,
      panOffsetYToDieStepSizeYRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      waferMapTestData,
      markRadialInfo: true,
    });
    this.markZoneInfoFromZoneNumbers(startRow, startCol, waferMapTestData, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInRadialZone);
    return { data: waferMapTestData, action: data.action };
  };

  getVerticalZoneColNumbers = (totalDies: number, numberOfZones: number, noOfDiesInsideWaferInColumn: number[], rows: number, colOffset: number) => {
    const front: number[] = [];
    const back: number[] = [];
    let verticalZoneColNumbers: number[] = [];
    let dieCountInCurrZone = 0;
    const requiredDies = totalDies / numberOfZones;
    for (let i = 0, j = 0; i < noOfDiesInsideWaferInColumn.length && j < Math.floor((numberOfZones - 1) / 2); i += 1) {
      dieCountInCurrZone += noOfDiesInsideWaferInColumn[i];
      if (dieCountInCurrZone <= (requiredDies + rows / 2) && dieCountInCurrZone > (requiredDies - rows / 2)) {
        front.push(i + colOffset);
        back.push(noOfDiesInsideWaferInColumn.length - i - 2 + colOffset);
        dieCountInCurrZone = 0;
        j += 1;
      }
    }

    if (numberOfZones % 2 === 0) {
      verticalZoneColNumbers = [...front, (front[front.length - 1] + back[back.length - 1]) / 2, ...back.reverse()];
    } else {
      verticalZoneColNumbers = [...front, ...back.reverse()];
    }

    return verticalZoneColNumbers;
  };

  getHorizontalZoneRowNumbers = (totalDies: number, numberOfZones: number, noOfDiesInsideWaferInRow: number[], cols: number, rowOffset: number) => {
    const front: number[] = [];
    const back: number[] = [];
    let horizontalZoneRowNumbers: number[] = [];
    let dieCountInCurrZone = 0;
    const requiredDies = totalDies / numberOfZones;
    for (let i = 0, j = 0; i < noOfDiesInsideWaferInRow.length && j < Math.floor((numberOfZones - 1) / 2); i += 1) {
      dieCountInCurrZone += noOfDiesInsideWaferInRow[i];
      if (dieCountInCurrZone <= (requiredDies + cols / 2) && dieCountInCurrZone > (requiredDies - cols / 2)) {
        front.push(i + rowOffset);
        back.push(noOfDiesInsideWaferInRow.length - i - 2 + rowOffset);
        dieCountInCurrZone = 0;
        j += 1;
      }
    }

    if (numberOfZones % 2 === 0) {
      horizontalZoneRowNumbers = [...front, (front[front.length - 1] + back[back.length - 1]) / 2, ...back.reverse()];
    } else {
      horizontalZoneRowNumbers = [...front, ...back.reverse()];
    }

    return horizontalZoneRowNumbers;
  };

  deleteOldZoneInfoFromDie = (dieData: WaferMapData, selectedZoneId: string) => {
    if ('zoneNumber' in dieData) {
      delete dieData.zoneNumber;
    }
    if (dieData.zoneInfo && selectedZoneId in dieData.zoneInfo!) {
      delete dieData.zoneInfo[selectedZoneId];
    }
  };

  deleteOldZoneInfoFromDies = (startRow: number, startCol: number, waferMapTestData: WaferMapTestData, rowDirection: number, colDirection: number, selectedZoneId: string) => {
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        if (waferMapTestData[p][q]) this.deleteOldZoneInfoFromDie(waferMapTestData[p][q]!, selectedZoneId);
      }
    }
  };

  markZoneInfoFromZoneNumbers = (startRow: number, startCol: number, waferMapTestData: WaferMapTestData, rowDirection: number, colDirection: number, selectedZoneId: string, pcmSitesInZone: any) => {
    for (let i = 0, p = startRow; i < waferMapTestData.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferMapTestData[i].length; j += 1, q += colDirection) {
        if (waferMapTestData[p][q] && 'zoneNumber' in waferMapTestData[p][q]!) {
          if (!waferMapTestData[p][q]!.zoneInfo) {
            waferMapTestData[p][q]!.zoneInfo = {};
          }
          waferMapTestData[p][q]!.zoneInfo![selectedZoneId] = [...pcmSitesInZone[waferMapTestData[p][q]!.zoneNumber]];
        }
      }
    }
  };

  performWCMActionApplyVerticalZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, zones, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferMapTestData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'VERTICAL' && currZone.numberOfZones === undefined) return { data: waferMapTestData, action: data.action };

    // also handles deletion of old zones
    const pcmSitesInVerticalZone = this.getPcmSitesInVericleZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      waferMapTestData,
      markInfo: true,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: data.config.selectedZoneId,
      dieTypes,
      currZone,
    });
    if (pcmSitesInVerticalZone) {
      this.markZoneInfoFromZoneNumbers(startRow, startCol, waferMapTestData, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInVerticalZone);
    } else {
      toast.error('Error in defining vertical zone.');
    }
    return { data: waferMapTestData, action: data.action };
  };

  performWCMActionApplyHorizontalZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferMapTestData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, zones, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferMapTestData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'HORIZONTAL' && currZone.numberOfZones === undefined) return { data: waferMapTestData, action: data.action };

    // also handles deletion of old zones
    const pcmSitesInHorizontalZone = this.getPcmSitesInHorizontalZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      waferMapTestData,
      markInfo: true,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: data.config.selectedZoneId,
      dieTypes,
      currZone,
    });
    if (pcmSitesInHorizontalZone) {
      this.markZoneInfoFromZoneNumbers(startRow, startCol, waferMapTestData, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInHorizontalZone);
    } else {
      toast.error('Error in defining horizontal zone.');
    }
    return { data: waferMapTestData, action: data.action };
  };

  getZoneInfoOfDiesWithinReticle = (reticle: RectCoord, rowOffset: number, colOffset: number, waferMapTestData: WaferMapTestData, selectedZoneId: string) => {
    let reticleZoneInfo: number | null = null;
    for (let l = reticle.startY - rowOffset; l <= reticle.endY - rowOffset && reticleZoneInfo === null; l += 1) {
      for (let m = reticle.startX - colOffset; m <= reticle.endX - colOffset && reticleZoneInfo === null; m += 1) {
        if (
          waferMapTestData[l]
          && waferMapTestData[l][m]
          && waferMapTestData[l][m]!.zoneInfo
          && waferMapTestData[l][m]!.zoneInfo![selectedZoneId]
          && waferMapTestData[l][m]!.zoneInfo![selectedZoneId].length > 0) {
          // eslint-disable-next-line prefer-destructuring
          reticleZoneInfo = waferMapTestData[l][m]!.zoneInfo![selectedZoneId][0]; // assuming one die has only one zone incase of grouped
        }
      }
    }
    return reticleZoneInfo;
  };

  getIntersectingInterval = (a: { start: number, end: number }, b: { start: number, end: number }) => {
    const instersectingInterval: { start: number | null, end: number | null } = { start: null, end: null }; // means no intersection
    if (!(b.start > a.end || a.start > b.end)) {
      instersectingInterval.start = Math.max(a.start, b.start);
      instersectingInterval.end = Math.max(a.end, b.end);
    }
    return instersectingInterval;
  };

  getZoneBoundaryCoordsForGroupedZone = (
    direction: 'right' | 'left' | 'above' | 'below',
    reticle: RectCoord,
    reticleSize: { x: number, y: number },
    reticleGridRectCoords: ReticleGridRectCoords,
    currReticleZoneInfo: number | null,
    rowOffset: number,
    colOffset: number,
    waferMapTestData: WaferMapTestData,
    selectedZoneId: string,
  ) => {
    const coords: { [key: string]: boolean } = {};
    const possibleReticlesOnSide: string[] = [];

    let start = reticle.startY - reticleSize.y + 1;
    let end = reticle.endY;

    if (direction === 'above' || direction === 'below') {
      start = reticle.startX - reticleSize.x + 1;
      end = reticle.endX;
    }

    for (let i = start; i <= end; i += 1) {
      let startX = reticle.endX + 1;
      let startY = i;
      if (direction === 'left') {
        startX = reticle.startX - 1 - (reticleSize.x - 1);
        startY = i;
      } else if (direction === 'below') {
        startX = i;
        startY = reticle.endY + 1;
      } else if (direction === 'above') {
        startX = i;
        startY = reticle.startY - 1 - (reticleSize.y - 1);
      }
      possibleReticlesOnSide.push(JSON.stringify({
        startX, startY, endX: startX + reticleSize.x - 1, endY: startY + reticleSize.y - 1,
      }));
    }

    let reticlesOnSideCount = 0;
    for (let i = 0; i < possibleReticlesOnSide.length; i += 1) {
      if (possibleReticlesOnSide[i] in reticleGridRectCoords) {
        reticlesOnSideCount += 1;
        const otherReticle: RectCoord = JSON.parse(possibleReticlesOnSide[i]);
        const otherReticleZoneInfo: number | null = this.getZoneInfoOfDiesWithinReticle(otherReticle, rowOffset, colOffset, waferMapTestData, selectedZoneId);
        if (otherReticleZoneInfo !== currReticleZoneInfo) {
          const instersectingInterval: { start: number | null, end: number | null } = this.getIntersectingInterval(
            {
              start: direction === 'right' || direction === 'left' ? reticle.startY : reticle.startX,
              end: direction === 'right' || direction === 'left' ? reticle.endY : reticle.endX,
            },
            {
              start: direction === 'right' || direction === 'left' ? otherReticle.startY : otherReticle.startX,
              end: direction === 'right' || direction === 'left' ? otherReticle.endY : otherReticle.endX,
            },
          );
          if (instersectingInterval.start !== null && instersectingInterval.end !== null) {
            for (let j = instersectingInterval.start; j <= instersectingInterval.end; j += 1) {
              if (direction === 'right') {
                coords[JSON.stringify({ x: otherReticle.startX, y: j })] = true;
              } else if (direction === 'left') {
                coords[JSON.stringify({ x: reticle.startX, y: j })] = true;
              } else if (direction === 'below') {
                coords[JSON.stringify({ x: j, y: otherReticle.startY })] = true;
              } else if (direction === 'above') {
                coords[JSON.stringify({ x: j, y: reticle.startY })] = true;
              }
            }
          }
        }
      }
    }

    if (reticlesOnSideCount === 0) {
      start = reticle.startY;
      end = reticle.endY;
      if (direction === 'above' || direction === 'below') {
        start = reticle.startX;
        end = reticle.endX;
      }
      for (let i = start; i <= end; i += 1) {
        if (direction === 'right' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: reticle.endX + 1, y: i })] = true;
        } else if (direction === 'left' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: reticle.startX, y: i })] = true;
        } else if (direction === 'below' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: i, y: reticle.endY + 1 })] = true;
        } else if (direction === 'above' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: i, y: reticle.startY })] = true;
        }
      }
    }
    return coords;
  };

  performWCMActionApplyGroupedZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      zones, waferMapTestData, reticleGridRectCoords, colOffset, rowOffset, reticleSize, scaledDieHeight, scaledDieWidth, wcmWaferDiameter, wcmWaferEdgeExclusion,
      XAnchor, YAnchor, dieHeightToStreetHeightRatio, dieWidthToStreetWidthRatio, dieTypes, waferBGOffsetXDies, waferBGOffsetYDies, waferWidthToColsRatio, waferHeightToRowsRatio,
    } = this.waferMapVariables;

    const mWidth = scaledDieWidth * waferMapTestData[0].length;
    const mHeight = scaledDieHeight * waferMapTestData.length;
    const waferCenterX = XAnchor + mWidth / 2 + waferBGOffsetXDies * scaledDieWidth;
    const waferCenterY = YAnchor + mHeight / 2 + waferBGOffsetYDies * scaledDieHeight;
    const widthOfBg = mWidth * waferWidthToColsRatio;
    const heightOfBg = mHeight * waferHeightToRowsRatio;
    const waferCenter = { x: waferCenterX, y: waferCenterY };
    const waferRadiusExcludingEdgeExclusion = (((wcmWaferDiameter - 2 * wcmWaferEdgeExclusion) / wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferMapTestData, action: data.action };
    const currZone = filteredZones[0];

    const markedWatPCMSiteReticles: ReticleGridRectCoords = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[reticleKey].watPCMSiteNumber !== null) {
        markedWatPCMSiteReticles[reticleKey] = _.cloneDeep(reticleGridRectCoords[reticleKey]);
      }
    }

    if (Object.keys(markedWatPCMSiteReticles).length === 0) {
      return { data: waferMapTestData, action: data.action };
    }

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      const reticle: RectCoord = JSON.parse(reticleKey);
      const reticleCenter = { x: (reticle.startX + reticle.endX) / 2, y: (reticle.startY + reticle.endY) / 2 };
      let minimumDistance = Number.MAX_SAFE_INTEGER;
      let closestPCMSiteNumber = null;
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const markedReticleKey in markedWatPCMSiteReticles) {
        const markedReticle: RectCoord = JSON.parse(markedReticleKey);
        const markedReticleCenter = { x: (markedReticle.startX + markedReticle.endX) / 2, y: (markedReticle.startY + markedReticle.endY) / 2 };

        const distance = this.euclideanDistance(markedReticleCenter, reticleCenter);
        if (distance < minimumDistance) {
          minimumDistance = distance;
          closestPCMSiteNumber = markedWatPCMSiteReticles[markedReticleKey].watPCMSiteNumber;
        }
      }
      if (closestPCMSiteNumber !== null) {
        for (let l = reticle.startY - rowOffset; l <= reticle.endY - rowOffset; l += 1) {
          for (let m = reticle.startX - colOffset; m <= reticle.endX - colOffset; m += 1) {
            if (waferMapTestData[l] && waferMapTestData[l][m]
              && this.isDieEligibleForMarkingZone(
                waferRadiusExcludingEdgeExclusion,
                waferMapTestData[l][m]!,
                rowOffset,
                colOffset,
                scaledDieWidth,
                scaledDieHeight,
                dieWidthToStreetWidthRatio,
                dieHeightToStreetHeightRatio,
                XAnchor,
                YAnchor,
                waferCenter,
                dieTypes,
              )) {
              if (!waferMapTestData[l][m]!.zoneInfo) {
                waferMapTestData[l][m]!.zoneInfo = {};
              }
              waferMapTestData[l][m]!.zoneInfo![data.config.selectedZoneId] = [closestPCMSiteNumber];
            }
          }
        }
      }
    }

    let ycoords: { [key: string]: boolean } = {};
    let xcoords: { [key: string]: boolean } = {};

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      const reticle: RectCoord = JSON.parse(reticleKey);
      const currReticleZoneInfo: number | null = this.getZoneInfoOfDiesWithinReticle(reticle, rowOffset, colOffset, waferMapTestData, data.config.selectedZoneId);
      ycoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('right', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferMapTestData, data.config.selectedZoneId),
        ...ycoords,
      };
      ycoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('left', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferMapTestData, data.config.selectedZoneId),
        ...ycoords,
      };
      xcoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('below', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferMapTestData, data.config.selectedZoneId),
        ...xcoords,
      };
      xcoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('above', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferMapTestData, data.config.selectedZoneId),
        ...xcoords,
      };
    }
    currZone.ycoords = Object.keys(ycoords).map((coord: string) => { return JSON.parse(coord); });
    currZone.xcoords = Object.keys(xcoords).map((coord: string) => { return JSON.parse(coord); });
    return { data: waferMapTestData, action: data.action };
  };

  getMarkedPCMSiteNumbers = () => {
    const { reticleGridRectCoords } = this.waferMapVariables;

    const markedWatPCMSiteNumbers: number[] = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[reticleKey].watPCMSiteNumber !== null) {
        markedWatPCMSiteNumbers.push(reticleGridRectCoords[reticleKey].watPCMSiteNumber!);
      }
    }
    return Array.from(new Set(markedWatPCMSiteNumbers)); // unique
  };

  getNumberOfRadialZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      scaledDieHeight, scaledDieWidth, reticleGridRectCoords, waferMapTestData,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, colOffset, rowOffset,
      panOffsetXToDieStepSizeXRatio, panOffsetYToDieStepSizeYRatio, waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion,
    } = this.waferMapVariables;

    const pcmSitesInRadialZone: number[][] = this.getPcmSitesInRadialZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      panOffsetXToDieStepSizeXRatio,
      panOffsetYToDieStepSizeYRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      waferMapTestData,
      markRadialInfo: false,
    });

    let numberOfRadialZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInRadialZone[i].length === 0) {
        numberOfRadialZonesWithNoPCMSites += 1;
      }
    }

    return numberOfRadialZonesWithNoPCMSites;
  };

  getNumberOfVerticalZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      waferMapTestData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const pcmSitesInVerticalZone = this.getPcmSitesInVericleZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      waferMapTestData,
      markInfo: false,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: '',
      dieTypes,
      currZone: null,
    });

    if (!pcmSitesInVerticalZone) {
      toast.error('Error in defining vertical zone.');
      return null;
    }

    let numberOfVerticalZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInVerticalZone[i].length === 0) {
        numberOfVerticalZonesWithNoPCMSites += 1;
      }
    }

    return numberOfVerticalZonesWithNoPCMSites;
  };

  getNumberOfHorizontalZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      waferMapTestData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      XAnchor, YAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const pcmSitesInHorizontalZone = this.getPcmSitesInHorizontalZone({
      scaledDieWidth,
      scaledDieHeight,
      XAnchor,
      YAnchor,
      rows: waferMapTestData.length,
      cols: waferMapTestData[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      waferMapTestData,
      markInfo: false,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: '',
      dieTypes,
      currZone: null,
    });

    if (!pcmSitesInHorizontalZone) {
      toast.error('Error in defining horizontal zone.');
      return null;
    }

    let numberOfHorizontalZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInHorizontalZone[i].length === 0) {
        numberOfHorizontalZonesWithNoPCMSites += 1;
      }
    }

    return numberOfHorizontalZonesWithNoPCMSites;
  };

  onRequestWaferData = (data: { action: ActionOnWaferData, config: any }) => {
    const { keyIndex } = this.waferMapVariables;
    let publishData: any = {};
    switch (data.action) {
      case ActionOnWaferData.CROP:
        publishData = this.performWCMActionCrop(data);
        break;
      case ActionOnWaferData.DELETE:
        publishData = this.performWCMActionDelete(data);
        break;
      case ActionOnWaferData.APPLY_RETICLE:
        publishData = this.performWCMActionApplyReticle(data);
        break;
      case ActionOnWaferData.DEFAULT:
        publishData = this.performWCMActionDefault(data);
        break;
      case ActionOnWaferData.REDRAW:
        publishData = this.performWCMActionRedraw(data);
        break;
      case ActionOnWaferData.CROP_SELECTED_RETICLES_ON_WAFER:
        publishData = this.performWCMActionCropSelectedReticlesOnWafer(data);
        break;
      case ActionOnWaferData.PAN_RETICLE:
        publishData = this.performWCMActionPanReticle(data);
        break;
      case ActionOnWaferData.SHIFT_INSERT_RETICLE:
        publishData = this.performWCMActionInsertReticle(data);
        break;
      case ActionOnWaferData.APPLY_FULL_WAT_MAP:
        publishData = this.performWCMActionApplyFullWATMap(data);
        break;
      case ActionOnWaferData.APPLY_RADIAL_ZONE:
        publishData = this.performWCMActionApplyRadialZone(data);
        break;
      case ActionOnWaferData.APPLY_VERTICAL_ZONE:
        publishData = this.performWCMActionApplyVerticalZone(data);
        break;
      case ActionOnWaferData.APPLY_HORIZONTAL_ZONE:
        publishData = this.performWCMActionApplyHorizontalZone(data);
        break;
      case ActionOnWaferData.APPLY_GROUPED_PER_SITE_ZONE:
        publishData = this.performWCMActionApplyGroupedZone(data);
        break;
      default:
        publishData = this.performWCMActionDefault(data);
        break;
    }
    if (publishData) {
      const ps = PublishSubscribe();
      ps.publishWithWaferID(EventTypes.WAFER_DATA_RESPONSE, publishData, keyIndex.toString());
      this.reRender();
    }
  };

  reRender = () => {
    this.waferMapDataToGPU(
      this.waferMapVariables.waferMapTestData,
      this.waferMapVariables.scaledDieWidth,
      this.waferMapVariables.scaledDieHeight,
      {
        width: this.waferMapVariables.gl!.canvas.width,
        height: this.waferMapVariables.gl!.canvas.height,
      },
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
    this.renderRadarView();
    this.prepareAndRenderRadarPointer();
    this.intiateRendering(
      this.waferMapVariables.gl!,
      this.waferMapVariables.XAnchor,
      this.waferMapVariables.YAnchor,
    );
  };

  clearSelection = () => {
    const { waferMapTestData, keyIndex } = this.waferMapVariables;
    for (let i = 0; i < waferMapTestData.length; i += 1) {
      for (let j = 0; j < waferMapTestData[i].length; j += 1) {
        if (waferMapTestData[i][j] !== null) {
          waferMapTestData[i][j]!.selected = false;
        }
      }
    }
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.CLEAR_SELECTION_ON_WAFER, { }, keyIndex.toString());
    this.reRender();
  };

  getTextFromDieField = (die: WaferMapData, field: string) => {
    const { dieTypes, zones, currentZoneId } = this.waferMapVariables;
    if (!(field in die)) return '';
    if (field in dieTypes) {
      const dieType = UtilityFunctions.getDieTypeFromId(die[field].toString()!, dieTypes[field]);
      if (dieType === null) return '';
      return dieType.name;
    // eslint-disable-next-line no-else-return
    } else if (field === 'zoneInfo') {
      const currZone = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
      if (currZone.length > 0) {
        const zoneInfo = die[field];
        if (zoneInfo && zoneInfo[currentZoneId]) {
          return zoneInfo[currentZoneId].toString();
        }
        return '';
      }
      return '';
    } else {
      return die[field];
    }
  };
}

export default WebGLUtils;
