import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import ScrollView from 'devextreme-react/scroll-view';
import Button from '../../components/wrapped-component/hint-controls/Button';
import TopbarNav from '../../components/navigational-component/topbar-nav/TopbarNav';
import CustomizedDataGrid from '../../components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import { httpService } from '../../services/http.service';
import ScreenTypesMappingPopup from './ScreenTypesMappingPopup';
import ManualFileUploadPopup from './ManualFileUploadPopup';

interface ILoaderPoliciesProps {
  className?: string;
  GetPolicyResult? : (e:any) => void;
}

const LoaderPolicies = (props:ILoaderPoliciesProps) => {
  const history = useHistory();

  const { className, GetPolicyResult } = props;

  // eslint-disable-next-line max-len,object-curly-newline
  // const [policiesList, updatePoliciesList] = useState([{ id: '123', name: 'Loader Policy', version: '1.0', purpose: 'to load simple atdf files', state: 'ACTIVE', type: 'ATDF', count: '3', stages: 'Scheduler, Load, Copy', lastExecutionDate: '08-Mar-21 12:05:10', processedFiles: '5', failedFiles: '1', schedule: 'Manual' }]);
  const [policiesList, updatePoliciesList] = useState([]);
  const [isShowpopup, setIsShowpopup] = useState<boolean>(false);
  const [isShowManualLoaderPopup, setIsShowManualLoaderPopup] = useState<boolean>(false);

  useEffect(() => {
    if (policiesList.length === 0) {
      httpService.getPolicies('type=Loader').then((data: any) => {
        for (const val of data) {
          if (val.startingTime) val.startingTime = new Date(`${val.startingTime}+00:00`);
        }
        updatePoliciesList(data);
      });
    }
  }, []);

  const CreatePolicy = () => {
    history.push({
      pathname: '/create-loader-policy',
    });
  };

  const GetRowData = (e:any) => {
    history.push(`/create-loader-policy/${e.id}`, { Id: e.id });
  };

  const toggleIsShowpopup = () => {
    setIsShowpopup(true);
  };

  const toggleIsShowManualLoaderPopup = () => {
    setIsShowManualLoaderPopup(true);
  };

  return (
    <>
      {isShowpopup && (
        <ScreenTypesMappingPopup
          visible={isShowpopup}
          setIsShowpopupSettings={setIsShowpopup}
        />
      )}

      {isShowManualLoaderPopup && (
        <ManualFileUploadPopup
          updatePolicyStepSettings={setIsShowManualLoaderPopup}
          visible={isShowManualLoaderPopup}
          setIsShowpopupSettings={setIsShowManualLoaderPopup}
        />
      )}

      <ScrollView
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
      >
        <Container fluid className={className !== '' ? className : ''}>
          <TopbarNav
            title="Loader Policy Engine"
            items={[]}
            showAvatar
            showNotifications
            secondaryActions={(
              <>
                <Button
                  onClick={toggleIsShowpopup}
                  size="sm"
                  variant="primary"
                  className="mr20 mt-5"
                >
                  Screen Types Mapping
                </Button>

                <Button
                  onClick={toggleIsShowManualLoaderPopup}
                  size="sm"
                  variant="primary"
                  className="mr20 mt-5"
                >
                  Manual File Upload
                </Button>

                <Button
                  onClick={CreatePolicy}
                  size="sm"
                  variant="primary"
                  className="mr20 mt-5"
                >
                  Create Policy
                </Button>
              </>
            )}
          />
          <Row className="p20">
            <Col>
              <CustomizedDataGrid
                type="non-reporting"
                showFilterRow
                // selectionMode="multiple"
                fields={[
                  {
                    caption: 'Name',
                    dataField: 'name',
                    dataType: 'string',
                    showInfo: false,
                    minWidth: 200,
                    viewMode: 'anchor',
                    onClick: GetPolicyResult || GetRowData,
                    wordWrap: true,
                  },
                  {
                    caption: 'Version',
                    dataField: 'version',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Description',
                    dataField: 'purpose',
                    dataType: 'string',
                    showInfo: false,
                    minWidth: 200,
                  },
                  {
                    caption: 'State',
                    dataField: 'state',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Schedule',
                    dataField: 'schedule',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Interval',
                    dataField: 'interval',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Starting Time',
                    dataField: 'startingTime',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Stages',
                    dataField: 'stages',
                    dataType: 'string',
                    showInfo: false,
                    width: 200,
                  },
                  {
                    caption: 'Last execution date',
                    dataField: 'lastExecutionDate',
                    dataType: 'string',
                    showInfo: false,
                    width: 140,
                  },
                  {
                    caption: 'No. of processed files',
                    dataField: 'processedFiles',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'No. of failed files',
                    dataField: 'failedFiles',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Owner',
                    dataField: 'owner',
                    dataType: 'string',
                    showInfo: false,
                  },
                ]}
                // moreOptions={[{
                //   title: 'Edit',
                //   clickAction: GetRowData,
                // }]}
                // key="id"
                tableData={policiesList}
              />

            </Col>
          </Row>
        </Container>
      </ScrollView>
    </>
  );
};

export default LoaderPolicies;
