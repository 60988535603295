/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Label from 'components/wrapped-component/hint-controls/Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faPlus, } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { IdefaultWidget } from 'interfaces';
import scwGridIcon3 from '../../assets/icons/scw-grid-3.svg';
import scwGridIcon6 from '../../assets/icons/scw-grid-6.svg';
import scwGridIcon9 from '../../assets/icons/scw-grid-9.svg';
import scwGridIcon12 from '../../assets/icons/scw-grid-12.svg';

interface ISCWAddGridProps {
  defaultWidgetsList: IdefaultWidget[];
  onGridItemAdd: any;
}

interface ISCWAddGridStates {
  controlType: string;
  columnWeight: number;
}

class AddSCWGrid extends React.Component<ISCWAddGridProps, ISCWAddGridStates> {
  constructor(props: ISCWAddGridProps) {
    super(props);
    this.state = {
      controlType: '',
      columnWeight: 3,
    };
  }

  render() {
    const {
      controlType,
      columnWeight,
    } = this.state;
    const {
      defaultWidgetsList,
      onGridItemAdd
    } = this.props;
    const gridItemsToAdd = [['', 'SELECT'], ...defaultWidgetsList.filter((item: IdefaultWidget) => !item.isVisible)
      .map((item: IdefaultWidget) => ([item.controlType, item.controlType]))];

    const gridIcons = [
      {
        columnWeight: 3,
        icon: scwGridIcon3,
      },
      {
        columnWeight: 6,
        icon: scwGridIcon6,
      },
      {
        columnWeight: 9,
        icon: scwGridIcon9,
      },
      {
        columnWeight: 12,
        icon: scwGridIcon12,
      },
    ];

    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        key="add-scw-grid-popover"
        placement="left"
        overlay={(
          <Popover id="popover-positioned-left" className="shadow-dark border-none">
            <Popover.Title>
              <h6 className="m0">
                Edit Grid
              </h6>
            </Popover.Title>
            <Popover.Content className="custom-form p20">
              <Label
                labelTitle="Type"
                labelPosition="top"
                labelSize="100"
                fieldSize="100"
                errorSize="100"
                className="mb15"
              >
                <CustomizedDropdown
                  full
                  list={gridItemsToAdd}
                  selectedValue={controlType}
                  onChange={(selectedValue: string) => {
                    this.setState({ controlType: selectedValue });
                  }}
                />

              </Label>
              <Label
                labelTitle="Width"
                labelPosition="top"
                labelSize="100"
                fieldSize="100"
                errorSize="100"
                className="mb15"
              >
                <div className="d-flex align-items-center justify-content-between">
                  {gridIcons.map((item) => {
                    return (
                      <img
                        key={item.icon.toString()}
                        onClick={() => {
                          this.setState({ columnWeight: item.columnWeight });
                        }}
                        className={`grid-width-icon w50 ${gridItemsToAdd.length === 1 ? 'icon' : ''} ${gridItemsToAdd.length > 1 && columnWeight === item.columnWeight ? 'selected' : ''}`}
                        src={item.icon}
                        alt={`Add Grid Of Witdh ${item.columnWeight}`}
                      />
                    );
                  })}
                </div>
              </Label>
              <Button
                variant="primary"
                disabled={controlType === ''}
                onClick={() => {
                  if (!(controlType === '')) {
                    onGridItemAdd(controlType, columnWeight);
                    this.setState({
                      controlType: '',
                      columnWeight: 3,
                    });
                  }
                }}
              >
                <FontAwesomeIcon
                  className="mr6"
                  size="sm"
                  icon={faPlus}
                />
                Add
              </Button>
              {gridItemsToAdd.length === 1
                                && <span className="ml10 text-muted">All grids are already added</span>}
            </Popover.Content>
          </Popover>
        )}
      >
        <Button variant="clear" className="h40 d-inline add-scw-button btn-config">
          <FontAwesomeIcon icon={faEllipsisH} size="lg" />
          {
            gridItemsToAdd.length > 1
                        && <span className="add-scw-button-badge ml6">{gridItemsToAdd.length - 1}</span>
          }
        </Button>
      </OverlayTrigger>
    );
  }
}

export default AddSCWGrid;
