import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpBinPlusTable extends HttpBase {
  getBinPlusTable = (binPlusTableID: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-tables/${binPlusTableID}`);
  };

  getBinPlusDefinitions = (binPlusTableID: string): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-definitions/${binPlusTableID}`);
  };

  getSoftHardBinPlusDefinitions = (query = ''): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-definitions?${query}`);
  };

  getWorkCenterTypes = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/work-center-types/`);
  };

  getBinPlusGroupTypesBinTypes = (workCenterId: string | null): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-group-types/${workCenterId}`);
  };

  getBinPlusColumns = (workCenterId: string | null, binPlusTableId: string | null): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-columns/${workCenterId}/${binPlusTableId}`);
  };

  removeBinPlusTable = (binPlusTableID: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-tables/${binPlusTableID}`);
  };

  removeBinPlusDefinition = (binPlusDefinitionID: string): Promise<any> => {
    return this.DELETE(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-def/${binPlusDefinitionID}`);
  };

  getBinPlusTables = (): Promise<any> => {
    return this.GET(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-tables/`);
  };

  postBinPlusTable = (data: any): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-tables/`, data);
  };

  putBinPlusTable = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-tables/`, data);
  };

  postBinPlusDefinition = (data: any, binPlusTableID: string): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-def/${binPlusTableID}`, data);
  };

  putBinPlusDefinition = (data: any): Promise<any> => {
    return this.PUT(`${AppConstants.baseURL}/api/bin-plus-table/bin-plus-def/`, data);
  };

  postAttribute = (data: any, binPlusTableID: string): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/bin-plus-table/attribute/${binPlusTableID}`, data);
  };
}

const httpBinPlusTable = new HttpBinPlusTable();
export { httpBinPlusTable };
