/* eslint-disable no-param-reassign */
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
// eslint-disable-next-line no-unused-vars
import WaferMapVariables from '../variables/WaferMapVariablesClass';
import initializeEvents from '../web-gl-utils/Events';
// eslint-disable-next-line no-unused-vars
import WebGLUtils from '../web-gl-utils/Utils';

const scaleRadarCoordToWaferCoord = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  waferMapVariables: WaferMapVariables,
): number[] => {
  const scaleX: number = waferMapVariables.viewPortWidth / waferMapVariables.radarCanvasWidth;
  const scaleY: number = waferMapVariables.viewPortHeight / waferMapVariables.radarCanvasHeight;
  startX *= scaleX;
  startY *= scaleY;
  endX *= scaleX;
  endY *= scaleY;

  return [startX, startY, endX, endY];
};

const dragMouseMove = (startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils, isMouseDown: boolean): void => {
  if (!isMouseDown) return;
  const {
    radarViewPort, radarCanvasWidth, radarCanvasHeight, viewPortHeight,
  } = waferMapInstance.waferMapVariables;
  if (startX < radarViewPort.x || startX > radarViewPort.x + radarViewPort.width) return;
  if (startY < viewPortHeight - (radarViewPort.y + radarViewPort.height) || startY > viewPortHeight - (radarViewPort.y)) return;
  startX = ((startX - radarViewPort.x) * radarCanvasWidth) / radarViewPort.width;
  endX = ((endX - radarViewPort.x) * radarCanvasWidth) / radarViewPort.width;
  startY = radarCanvasHeight - (((-(startY - viewPortHeight) - radarViewPort.y) * radarCanvasHeight) / radarViewPort.width);
  endY = radarCanvasHeight - (((-(endY - viewPortHeight) - radarViewPort.y) * radarCanvasHeight) / radarViewPort.width);
  [startX, startY, endX, endY] = scaleRadarCoordToWaferCoord(
    startX,
    startY,
    endX,
    endY,
    waferMapInstance.waferMapVariables,
  );
  startX = endX;
  startY = endY;
  const ps = PublishSubscribe();
  ps.publishWithWaferID(EventTypes.UPDATE_WAFER_ON_RADARPOINTER_UPDATE, {
    startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
    startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
  }, waferMapInstance.waferMapVariables.keyIndex.toString());
  ps.publishWithWaferID(EventTypes.UPDATE_RADARPOINTER_ON_RADARPOINTER_UPDATE, {
    startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
    startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
  }, waferMapInstance.waferMapVariables.keyIndex.toString());
};

const mouseUp = (startX: number, startY: number, endX: number, endY: number, waferMapInstance: WebGLUtils): void => {
  const {
    radarViewPort, radarCanvasWidth, radarCanvasHeight, viewPortHeight,
  } = waferMapInstance.waferMapVariables;
  if (startX < radarViewPort.x || startX > radarViewPort.x + radarViewPort.width) return;
  if (startY < viewPortHeight - (radarViewPort.y + radarViewPort.height) || startY > viewPortHeight - (radarViewPort.y)) return;
  startX = ((startX - radarViewPort.x) * radarCanvasWidth) / radarViewPort.width;
  endX = ((endX - radarViewPort.x) * radarCanvasWidth) / radarViewPort.width;
  startY = radarCanvasHeight - (((-(startY - viewPortHeight) - radarViewPort.y) * radarCanvasHeight) / radarViewPort.width);
  endY = radarCanvasHeight - (((-(endY - viewPortHeight) - radarViewPort.y) * radarCanvasHeight) / radarViewPort.width);
  [startX, startY, endX, endY] = scaleRadarCoordToWaferCoord(
    startX,
    startY,
    endX,
    endY,
    waferMapInstance.waferMapVariables,
  );
  if (startX === endX && startY === endY) {
    const ps = PublishSubscribe();
    ps.publishWithWaferID(EventTypes.UPDATE_WAFER_ON_RADARPOINTER_UPDATE, {
      startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
      startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
    }, waferMapInstance.waferMapVariables.keyIndex.toString());
    ps.publishWithWaferID(EventTypes.UPDATE_RADARPOINTER_ON_RADARPOINTER_UPDATE, {
      startX: startX / waferMapInstance.waferMapVariables.viewPortWidth,
      startY: startY / waferMapInstance.waferMapVariables.viewPortHeight,
    }, waferMapInstance.waferMapVariables.keyIndex.toString());
  }
};

const radarPointer = (waferMapInstance: WebGLUtils): void => {
  if (waferMapInstance.waferMapVariables.gl === null) return;
  const radarPointerVertexShader = `#version 300 es
    precision mediump float;
    in vec2 position;
    uniform float flipY;
    void main() {
        gl_Position = vec4(position.x,position.y*-1.0, 0.0, 1.0);
        gl_PointSize = 5.0;
    }`;
  const radarPointerFragmentShader = `#version 300 es
    precision mediump float;
    out vec4 color;
    uniform vec4 reticleColor;
    void main() {
        color = reticleColor; // r,g,b,a 
    }
    `;
  const radarPointerProgram = waferMapInstance.getProgram(
    waferMapInstance.waferMapVariables.gl!,
    radarPointerVertexShader,
    radarPointerFragmentShader,
  );
  if (!radarPointerProgram) return;
  waferMapInstance.waferMapVariables.radarPointerProgram = radarPointerProgram!;

  initializeEvents(
    waferMapInstance,
    waferMapInstance.waferMapVariables.gl!,
    dragMouseMove,
    mouseUp,
    null,
  );
};

export default radarPointer;
