import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../../wrapped-component/hint-controls/Heading';
import './page-header.scss';

interface IPageHeaderProps {
  title: string;
  breadcrumbs?: string;
  secondaryActions?: any;
  primaryActions?: any;
  titleActions?: any;
  className?: string;
}

const PageHeader = (props: IPageHeaderProps) => {
  const {
    title,
    breadcrumbs,
    primaryActions,
    secondaryActions,
    className,
    titleActions,
  } = props;

  return (
    <Container fluid className="overflow-visible">
      <Row className={`pt20 ${className}`}>
        <Col lg={7}>

          <Row>
            <Col lg={12} className="breadcrumbs ">
              <p>
                {breadcrumbs}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <Heading size={3} className={`p0 m0 ${title !== '' ? 'pr20' : ''}`}>{title}</Heading>
                <div className="">
                  {titleActions}
                </div>
              </div>
            </Col>
          </Row>

        </Col>
        <Col lg={5} className="text-right pr30">{secondaryActions}</Col>
      </Row>
      <Row className="pl20">
        <Col>{primaryActions}</Col>
      </Row>
    </Container>
  );
};

PageHeader.defaultProps = {
  breadcrumbs: '',
  secondaryActions: <></>,
  primaryActions: <></>,
  titleActions: <></>,
  className: '',
};

export default PageHeader;
