import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type TooltipWrapperProps = {
  text: string,
  placement?: 'top' | 'right' | 'bottom' | 'left',
  id: string,
  [key: string]: any,
  display?: string,
};

const TooltipWrapper = (props: TooltipWrapperProps) => {
  const {
    text,
    id,
    placement,
    children,
    display,
  } = props;
  return (
    <OverlayTrigger
      placement={placement}
      delay={{
        show: 250,
        hide: 400
      }}
      overlay={(
        <Tooltip
          style={{
            position: 'relative',
            zIndex: 200,
            display
          }}
          id={id}
        >
          {text}
        </Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
};

TooltipWrapper.defaultProps = {
  placement: 'bottom',
};

export default TooltipWrapper;
