/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export interface ITestParameterBadgeProps {
  testParameterNames: any;
}

class TestParameterBadges extends React.Component<ITestParameterBadgeProps> {
  getBadges = (testParameterNames: any) => {
    const returnObj: JSX.Element[] = [];
    testParameterNames.forEach((testParameterName: any, index: any) => {
      returnObj.push(
        <div className="d-flex align-items-center ml4 background-color-secondary mb4 h28" style={{ borderRadius: 5 }}>
          <div className="d-flex align-items-center color-light h-100 pl10 pr10" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
            {`Test Parameter ${testParameterNames.length > 1 ? index + 1 : ''}`}
          </div>
          <div className="d-flex align-items-center color-light h-100 pl10 pr10">
            {testParameterName}
          </div>
        </div>,
      );
    });
    return returnObj;
  };

  render() {
    const { testParameterNames } = this.props;
    return (
      <div className="d-flex mr10">{this.getBadges(testParameterNames)}</div>
    );
  }
}

export default TestParameterBadges;
