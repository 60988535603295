import React from 'react';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';

interface IHeadingProps {
  size: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  className?: string | undefined;
  hint?: IHint;
  hidden?: boolean;
}

const Heading = (props: IHeadingProps) => {
  const {
    size,
    children,
    className,
    hint,
    hidden,
  } = props;

  if (size === 1) {
    return (
      <h1 className={className} hidden={hidden}>
        {children}
        <Hint className="d-inline-block" hint={hint} variant="icon" />
      </h1>
    );
  }
  if (size === 2) {
    return (
      <h2 className={className} hidden={hidden}>
        {children}
        <Hint className="d-inline-block" hint={hint} variant="icon" />
      </h2>
    );
  }
  if (size === 3) {
    return (
      <h3 className={className} hidden={hidden}>
        {children}
        <Hint className="d-inline-block" hint={hint} variant="icon" />
      </h3>
    );
  }
  if (size === 4) {
    return (
      <h4 className={className} hidden={hidden}>
        {children}
        <Hint className="d-inline-block" hint={hint} variant="icon" />
      </h4>
    );
  }
  if (size === 5) {
    return (
      <h5 className={className} hidden={hidden}>
        {children}
        <Hint className="d-inline-block" hint={hint} variant="icon" />
      </h5>
    );
  }
  return (
    <h6 className={className} hidden={hidden}>
      {children}
      <Hint className="d-inline-block" hint={hint} variant="icon" />
    </h6>
  );
};

Heading.defaultProps = {
  className: '',
  hidden: false,
};

export default Heading;
