/* eslint-disable no-unused-vars */

export enum CalculationMethod { AUTO, ROWS_COLS, RETICLE }

export enum DieSpecType { DIE, STEP, BOTH }

export enum ExclusionType { FLAT_KEEP_OUT, NOTCH_KEEP_OUT, SCRIBE_LINE_AND_BASE_FLAT, NONE }

export enum ActionOnWaferData {
  CROP, REDRAW, DELETE, DEFAULT, APPLY_RETICLE, CROP_SELECTED_RETICLES_ON_WAFER, PAN_RETICLE,
  SHIFT_INSERT_RETICLE, APPLY_FULL_WAT_MAP, REMOVE_ZONE_INFO_USING_ID, SHIFT_INSERT_DIES,
  APPLY_RADIAL_ZONE, APPLY_VERTICAL_ZONE, APPLY_HORIZONTAL_ZONE, APPLY_GROUPED_PER_SITE_ZONE,
  INSERT_ROW_COL, SHIFT_DELETE_DIES, DELETE_ROW_COL, DELETE_SELECTED_DIES,
}

export enum MiddleViewport { WAFER, DIE_TYPES, UDA, ZONES }

export enum ActiveTab { WAFER_DEFINTION, ADDITIONAL_ATTRIBUTES, SITE_MAP, DEFINE_ZONES }

export enum FlipAxis { RowWise, ColWise }

export enum RotateDirection { ClockWise, AntiClockWise }

export enum MainControl { Select, MarkDieType, Drag, Zoom }

export enum NotchPosition { UP, DOWN, LEFT, RIGHT }

export enum DieColorType { DIE_TYPE, ZONE }

export enum DieLocation { INSIDE_RING, ON_RING, OUTSIDE_RING }

export enum SelectionMode { DIE, RETICLE }

export enum MarkingMode { DIE_TYPE, WAT_PCM_SITE_NUMBER, ZONE_SITE }

export enum RowAxisDirection { TopToBottom, BottomToTop }

export enum ColAxisDirection { LeftToRight, RightToLeft }

export enum RowFlip { Upright, Inverted }

export enum ColFlip { Upright, Inverted }

export enum WaferAxisDirection { DOWN_RIGHT, DOWN_LEFT, UP_LEFT, UP_RIGHT }

export enum WaferOriginLocation { UPPER_LEFT, UPPER_RIGHT, LOWER_LEFT, LOWER_RIGHT, CENTER }

export enum MetaColumnType {
  TINYTEXT, LONGTEXT, DATETIME, LONG, BOOL,
  DECIMAL, VARCHAR, COLOR, DROPDOWN, BIN_PLUS_TABLE,
}
export enum FeatureSpecificWaferTag { WAFER_CONTROL_MAP }

export const DefaultNotchPosition = NotchPosition.UP;
