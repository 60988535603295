import {
  faFolder,
  faFolderOpen,
  faFolderPlus, faPen, faStar, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'CustomToast';
import {
  DataGrid, SelectBox
} from 'devextreme-react';
import {
  Column, Editing, Selection, HeaderFilter, FilterRow, SearchPanel, RowDragging, Button as DxButton, Scrolling, Lookup
} from 'devextreme-react/data-grid'
import React, { Component } from 'react';
import {
  Button, Col, Container, Row
} from 'react-bootstrap';
import { httpSCW } from 'services/http.scw';
import ModalPopup from 'components/wrapped-component/modal-popup/modal-popup';
import _ from 'lodash';
import CreateOrEditFavoriteFolder from './CreateOrEditFavoriteFolder';
import CopyOrMoveFavoriteSelectionCriteria from './CopyOrMoveFavoriteSelectionCriteria';
import spinner from '../../../assets/icons/spinner.gif';

interface IManageFavoriteProps {
  callback: (data: any) => void;
  title:string;
  isManageFavoritePopup?: boolean;
  callbackOnLoadFavorite?: (data: any) => void;
  callbackOnEditFavorite?: (data: any) => void;
}

interface IManageFavoriteState{
  selectionCriteriaData: any;
  folderData:any;
  selectedFolder: any;
  selectedFavoriteKeys: any;
  isCreateOrEditFavoriteFolderPopupVisible: boolean;
  isEditingFolder: boolean;
  folderToEdit: any;
  isMoveFavoritePopupVisible: boolean;
}

class ManageFavoriteSC extends Component<IManageFavoriteProps, IManageFavoriteState>{
  static defaultProps: Partial<IManageFavoriteProps> = {
    isManageFavoritePopup: true,
    callbackOnLoadFavorite: undefined,
    callbackOnEditFavorite: undefined,
  }

  privacyRef:any = null;

  importRef:any = null;

  exportRef:any = null;

  constructor(props: IManageFavoriteProps) {
    super(props);
    this.state = {
      selectionCriteriaData: [],
      selectedFolder: { id: 0, name: 'Favorites' },
      selectedFavoriteKeys: [],
      folderData: [],
      isMoveFavoritePopupVisible: false,
      isCreateOrEditFavoriteFolderPopupVisible: false,
      isEditingFolder: false,
      folderToEdit: null,
    }
  }

  componentDidMount(): void {
    httpSCW.getFoldersAndFavorites().then((data) => {
      this.setState({
        folderData: data.item1,
        selectionCriteriaData: data.item2,
      })
    });
  }

  onEditingFavorite = (e: any) => {
    const { callbackOnEditFavorite } = this.props;
    if (callbackOnEditFavorite) {
      callbackOnEditFavorite(e.row.data);
    }
  }

  onDeletingFavorite = async (_e:any) => {
    const { selectedFavoriteKeys, selectionCriteriaData } = this.state;
    if (selectedFavoriteKeys.length === 0) {
      toast.warning('Please select a favorite to delete');
      return;
    }
    if (await ModalPopup.confirm({
      header: 'Confirmation',
      body: 'Are you sure you want to delete the selected favorite/s?',
    })) {
      const itemsToDelete = selectedFavoriteKeys.map((key:any) => key.id);
      httpSCW.deleteFavoriteSelection(itemsToDelete).then(() => {
        // decrement the count of the folders that the deleted favorites belong to
        const { folderData } = this.state;
        selectedFavoriteKeys.forEach((favorite:any) => {
          const folder = folderData.find((item:any) => favorite.folder !== null && item.id === favorite.folder.id);
          if (folder) {
            folder.associatedFavoriteSelectionCriteriaCount -= 1;
          }
        });
        this.setState({
          selectedFavoriteKeys: [],
          selectionCriteriaData: selectionCriteriaData.filter((item:any) => !itemsToDelete.includes(item.id)),
          folderData,
        });
        toast.success('Favorites deleted successfully');
      }).catch(() => {
        toast.error('Failed to delete favorites');
      });
    }
  }

  render(){
    const {
      title, callback, isManageFavoritePopup, callbackOnLoadFavorite
    } = this.props;
    const {
      selectedFolder, selectedFavoriteKeys, selectionCriteriaData, folderData, isMoveFavoritePopupVisible, isCreateOrEditFavoriteFolderPopupVisible, isEditingFolder, folderToEdit
    } = this.state;

    const dataGridHeight = isManageFavoritePopup ? window.innerHeight / 1.6 : window.innerHeight / 1.75;
    return (
      // <ScrollView
      //   showScrollbar="onScroll"
      //   scrollByThumb
      //   height="100%"
      //   className="h-100"
      //   reachBottomText=""
      // >
      <>
        <Container fluid className="mt10 mb10 position-relative">

          <Row>
            <Col xs={3}>
              <h2>
                {title}
              </h2>
            </Col>
            <Col>
              <div className="float-right">
                <Button
                  variant="danger"
                  onClick={callback}
                  className="h40"
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" className="mr10" />
                  Close
                </Button>
              </div>
            </Col>
          </Row>
          {!isManageFavoritePopup && (
            <Row className="mt10 mb10">
              <Col>
                <Row className="mr0 ml0 border-radius-5">
                  <Col>
                    <SelectBox
                      items={['ALL', 'PUBLIC', 'PRIVATE']}
                      defaultValue="ALL"
                      label="Privacy"
                      ref={(ref:any) => { this.privacyRef = ref; }}
                      disabled
                    />
                  </Col>
                  <Col>
                    <SelectBox
                      items={['Local', 'Other Business Unit', '']}
                      defaultValue=""
                      label="Import"
                      ref={(ref:any) => { this.importRef = ref; }}
                      disabled
                    />
                  </Col>
                  <Col>
                    <SelectBox
                      items={['Local', 'Other Business Unit', '']}
                      defaultValue=""
                      label="Export"
                      ref={(ref:any) => { this.exportRef = ref; }}
                      disabled
                    />
                  </Col>
                  <Col lg={1} className="d-flex justify-content-center">
                    <Button
                      variant="success"
                      disabled
                      onClick={() => {
                        const privacy = this.privacyRef.instance.option('value');
                        const importValue = this.importRef.instance.option('value');
                        const exportValue = this.exportRef.instance.option('value');
                        // todo: implement import and export, enable UI as well
                      }}
                    >
                      OK
                    </Button>
                  </Col>
                </Row>

              </Col>
            </Row>
          )}
          <Row>
            <Col xs={3}>
              <Button
                variant="outline-dark"
                onClick={() => {
                  this.setState({ isCreateOrEditFavoriteFolderPopupVisible: true });
                }}
                className="h40 mb10"
                size="sm"
                block
                disabled={!isManageFavoritePopup}
              >
                <FontAwesomeIcon icon={faFolderPlus} size="lg" className="mr10" />
                New Folder
              </Button>
              <DataGrid
                className="border-radius-5"
                height={dataGridHeight}
                showBorders
                columnAutoWidth
                showRowLines
                showColumnHeaders={false}
                dataSource={folderData.length === 0
                  ? [{ id: 0, name: 'Favorites' }, { id: 1, name: 'Uncatogrized' }]
                  : (
                    [{ id: 0, name: 'Favorites' }, { id: 1, name: 'Uncatogrized' }]).concat(
                    [..._.sortBy(folderData, (f) => f.name)]
                  )}
                keyExpr="id"
                onSelectionChanged={(e:any) => {
                  this.setState({ selectedFolder: e.selectedRowsData[0] });
                }}
                selectedRowKeys={[selectedFolder.id]}
                onEditorPreparing={(e:any) => {
                  if (e.parentType === 'filterRow') {
                    e.editorOptions.placeholder = 'Search for folder';
                  }
                }}
              >
                <RowDragging
                  data="folder"
                  group="favoritesGroup"
                  allowDropInsideItem
                  showDragIcons={false}
                  onAdd={(e:any) => {
                    if (e.toIndex === 0) {
                      toast.error('Cannot move to Favorites folder');
                      return;
                    }
                    if (e.toIndex === 1) {
                      toast.error('Cannot move to Uncatogrized folder');
                      return;
                    }
                    this.setState({
                      isMoveFavoritePopupVisible: true,
                      selectedFolder: _.sortBy(folderData, (f) => f.name)[e.toIndex - 2],
                      selectedFavoriteKeys: [e.itemData],
                    })
                  }}
                />
                <Selection mode="single" />
                <Scrolling mode="Standard" showScrollbar="always" />
                <Column
                  dataField="name"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cellRender={(e:any) => {
                    let icon = faFolder;
                    let color = '';
                    let count = 0;
                    switch (e.value) {
                      case 'Favorites':
                        icon = faStar;
                        color = 'red';
                        count = selectionCriteriaData.length;
                        break;
                      case 'Uncatogrized':
                        icon = faFolderOpen;
                        color = 'red';
                        count = selectionCriteriaData.filter((item:any) => item.folder === null).length;
                        break
                      default:
                        count = e.row.data.associatedFavoriteSelectionCriteriaCount;
                        break;
                    }
                    return (
                      <div className="d-flex align-items-center" style={{ color }}>
                        <FontAwesomeIcon icon={icon} size="lg" className="mr10" />
                        {`${e.value} (${count})`}
                      </div>
                    );
                  }}
                />
                <FilterRow visible />
                <Column type="buttons">
                  <DxButton
                    hint="Edit"
                    name="editing"
                    icon="edit"
                    onClick={(e:any) => {
                      this.setState({ isCreateOrEditFavoriteFolderPopupVisible: true, isEditingFolder: true, folderToEdit: e.row.data });
                    }}
                    disabled={(e:any) => {
                      return e.row.data.name === 'Favorites' || e.row.data.name === 'Uncatogrized' || !isManageFavoritePopup;
                    }}
                  />
                </Column>
              </DataGrid>

            </Col>
            <Col xs={9}>
              <Row>
                <Col className="d-flex align-items-center side-by-side">
                  <h1>{selectedFolder.name}</h1>
                  <Button
                    variant="outline"
                    onClick={() => {
                      this.setState({ isCreateOrEditFavoriteFolderPopupVisible: true, isEditingFolder: true, folderToEdit: selectedFolder });
                    }}
                    className="h40"
                    size="lg"
                    hidden={selectedFolder.name === 'Favorites' || selectedFolder.name === 'Uncatogrized'}
                    disabled={!isManageFavoritePopup}
                  >
                    <FontAwesomeIcon icon={faPen} size="lg" className="ml10" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataGrid
                    dataSource={
                      // eslint-disable-next-line no-nested-ternary
                      selectedFolder.name === 'Favorites' ? selectionCriteriaData
                        : selectedFolder.name === 'Uncatogrized' ? selectionCriteriaData.filter((item:any) => item.folder === null)
                          : selectionCriteriaData.filter((item:any) => item.folder && item.folder.id === selectedFolder.id)
                    }
                    className="border-radius-5"
                    height={dataGridHeight}
                    showBorders
                    columnAutoWidth
                    showRowLines
                    showColumnLines
                    onToolbarPreparing={(e:any) => {
                      e.toolbarOptions.items.unshift({
                        location: 'before',
                        widget: 'dxButton',
                        disabled: isManageFavoritePopup,
                        options: {
                          icon: 'download',
                          text: 'Load',
                          onClick: () => {
                            if (selectedFavoriteKeys.length === 0) {
                              toast.warning('Please select a favorite to load');
                              return;
                            }
                            if (callbackOnLoadFavorite){
                              callbackOnLoadFavorite(selectedFavoriteKeys);
                            }
                          },
                        },
                      });
                      e.toolbarOptions.items.unshift({
                        location: 'before',
                        widget: 'dxButton',
                        disabled: !isManageFavoritePopup,
                        options: {
                          icon: 'trash',
                          text: 'Delete',
                          onClick: this.onDeletingFavorite,
                        },
                      });
                      e.toolbarOptions.items.unshift({
                        location: 'before',
                        widget: 'dxButton',
                        disabled: !isManageFavoritePopup,
                        options: {
                          icon: 'movetofolder',
                          text: 'Move',
                          onClick: () => {
                            if (selectedFavoriteKeys.length === 0) {
                              toast.warning('Please select a favorite to move');
                              return;
                            }
                            this.setState({ isMoveFavoritePopupVisible: true });
                          },
                        },
                      });
                    }}
                    onContentReady={(e:any) => {
                      const headerPanel = e.element.querySelector('.dx-datagrid-header-panel');
                      if (headerPanel) {
                        headerPanel.style.paddingBottom = '5%';
                        headerPanel.style.paddingTop = '5px';
                      }
                    }}
                    selectedRowKeys={selectedFavoriteKeys}
                    onSelectionChanged={(e:any) => {
                      this.setState({ selectedFavoriteKeys: e.selectedRowKeys });
                    }}
                    onRowRemoved={(e:any) => {
                      httpSCW.deleteFavoriteSelection([e.data.id]).then(() => {
                        // decrement the count of the folder
                        const folderDataCopy = [...folderData];
                        const folderIndex = folderDataCopy.findIndex((item:any) => item.id === e.data.folder.id);
                        folderDataCopy[folderIndex].associatedFavoriteSelectionCriteriaCount -= 1;
                        this.setState({
                          selectedFavoriteKeys: selectedFavoriteKeys.filter((key:any) => key.id !== e.data.id),
                          selectionCriteriaData: selectionCriteriaData.filter((item:any) => item.id !== e.data.id),
                          folderData: folderDataCopy
                        });
                        toast.success('Favorites deleted successfully');
                      }).catch(() => {
                        toast.error('Failed to delete favorites');
                      });
                    }}
                  >
                    <Editing allowDeleting useIcons />
                    { isManageFavoritePopup
                    && (
                      <RowDragging
                        data="favorite"
                        group="favoritesGroup"
                        allowReordering={false}
                      />
                    )}
                    <Selection mode="multiple" />
                    <Scrolling mode="Standard" showScrollbar="always" />
                    <HeaderFilter visible />
                    <FilterRow visible />
                    <SearchPanel
                      visible
                      placeholder="Search..."
                    />
                    <Column caption="Name" dataField="name" defaultSortOrder="asc" />
                    <Column caption="Discription" dataField="purpose" />
                    <Column caption="Owner" dataField="owner" />
                    <Column caption="Privacy" dataField="privacy">
                      <Lookup
                        dataSource={[
                          { dataField: 0, caption: 'PRIVATE' },
                          { dataField: 1, caption: 'PUBLIC' }
                        ]}
                        valueExpr="dataField"
                        displayExpr="caption"
                      />
                    </Column>
                    <Column caption="Date Created" dataField="createdOn" dataType="datetime" />
                    <Column caption="Date Last Modified" dataField="updatedOn" dataType="datetime" />
                    <Column type="buttons">
                      <DxButton hint="Edit" name="editing" icon="edit" onClick={this.onEditingFavorite} disabled={!isManageFavoritePopup} />
                      <DxButton name="delete" disabled={!isManageFavoritePopup} />
                    </Column>
                  </DataGrid>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        { isMoveFavoritePopupVisible
        && (
          <CopyOrMoveFavoriteSelectionCriteria
            isVisible={isMoveFavoritePopupVisible}
            onClose={({
              isCanceled, isMoved, favorites
            }) => {
              if (isCanceled){
                this.setState({ isMoveFavoritePopupVisible: false })
                return;
              }
              let newSelectionCriteriaData: any;
              if (isMoved){
              // change the selectionCriteriaData with the new one
                newSelectionCriteriaData = selectionCriteriaData.map((item:any) => {
                  const favorite = favorites.find((fav:any) => fav.id === item.id);
                  if (favorite) {
                    return favorite;
                  }
                  return item;
                });
              } else {
                // copy assentially adds new items
                newSelectionCriteriaData = [...selectionCriteriaData, ...favorites];
              }
              // get updated folder data, and update the state
              httpSCW.getFavoriteFolders().then((res:any) => {
                this.setState({
                  isMoveFavoritePopupVisible: false,
                  folderData: res,
                  selectionCriteriaData: newSelectionCriteriaData
                })
              });
            }}
            folders={folderData}
            selectedFavorite={selectedFavoriteKeys}
            allFavorites={selectionCriteriaData}
            selectedFolder={selectedFolder}
          />
        )}
        { isCreateOrEditFavoriteFolderPopupVisible
        && (
          <CreateOrEditFavoriteFolder
            isVisible={isCreateOrEditFavoriteFolderPopupVisible}
            isCreate={!isEditingFolder}
            folder={folderToEdit}
            onClose={() => this.setState({
              isEditingFolder: false,
              isCreateOrEditFavoriteFolderPopupVisible: false,
              folderToEdit: null
            })}
            onAddFavoriteFolder={(newFolder: any) => {
              this.setState({
                isEditingFolder: false,
                folderToEdit: null,
                isCreateOrEditFavoriteFolderPopupVisible: false,
                folderData: folderData.concat(newFolder),
                selectedFolder: newFolder
              });
            }}
            onEditFavoriteFolder={(editedFolder: any) => {
              this.setState({
                isEditingFolder: false,
                folderToEdit: null,
                isCreateOrEditFavoriteFolderPopupVisible: false,
                folderData: folderData.map((folder: any) => (folder.id === editedFolder.id ? editedFolder : folder)),
                selectedFolder: editedFolder
              });
            }}
            onDeleteFavoriteFolder={(deletedFolder: any) => {
              this.setState({
                isEditingFolder: false,
                folderToEdit: null,
                isCreateOrEditFavoriteFolderPopupVisible: false,
                folderData: folderData.filter((folder: any) => folder.id !== deletedFolder.id),
                // selectionCriteriaData: selectionCriteriaData.map((item: any) => (item.folder.id === deletedFolder.id ? { ...item, folder: null } : item)),
                selectionCriteriaData: selectionCriteriaData.filter((item: any) => (!item.folder || (item.folder && item.folder.id !== deletedFolder.id))),
                selectedFolder: { id: 0, name: 'Favorites' }
              });
            }}
          />
        )}
      </>
      // </ScrollView>
    )
  }
}

export default ManageFavoriteSC;
