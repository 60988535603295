import React from 'react';
import TagBox from 'devextreme-react/tag-box';
import getDropDownOptions from 'components/getDropDownOptions';

// Requires prop named listItem an object containing the names of items to show in dropdown
class CustomizedTagBox extends React.Component<any, any> {
  onValueChanged = (e: any) => {
    const {
      data,
      customData
    } = this.props;
    if (customData.callBackOnUpdate) {
      customData.callBackOnUpdate(e.value);
    }
    data.setValue(e.value);
  };

  onSelectionChanged = () => {
    const { data } = this.props;
    data.component.updateDimensions();
  };

  render() {
    const {
      data,
      customData
    } = this.props;

    return (
      <TagBox
        dataSource={customData.listItem}
        defaultValue={data.value}
        displayExpr="caption"
        valueExpr="dataField"
        showSelectionControls
        maxDisplayedTags={3}
        showMultiTagOnly={false}
        applyValueMode="instantly"
        searchEnabled={customData.isSearchEnabled !== undefined ? customData.isSearchEnabled : true}
        dropDownButtonTemplate="dropDownButton"
        onValueChanged={this.onValueChanged}
        onSelectionChanged={this.onSelectionChanged}
        disabled={customData.disabledWhen ? customData.disabledWhen.values.includes(data.data[customData.disabledWhen.inColumn]) : false}
        dropDownOptions={getDropDownOptions('cell', data,)}
      />
    );
  }
}

export default CustomizedTagBox;
