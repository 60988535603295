/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import WidgetCard from 'components/template-component/widget-card/WidgetCard';
import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { EntityType, IGenericDetailedReportRequestObject } from 'interfaces';
import _ from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import GeneralUtils, {
  toTitleCase, ReportTypeConstants as ReportType, ReportDataTypeConstants, ReportTypeConstants
} from 'GeneralUtils';
import { report } from 'process';
import PubSubBinder, { ActionType, InteractionEventType } from '../PublishSubscribeBinder';
import { ILegendDataHelper } from './customized-report-helpers/CustomizedReportHelper';
import {
  AggregateFunctionNames,
  ICustomizedLegendData,
  ICustomizedReportBaseProps,
  InteractionInputDTO,
  IInteractionType,
  ISelectedLegendRows,
  IViewMode,
} from './customized-report-helpers/CustomizedReportInterfaces';
import { InteractionMode } from './CustomizedReportGraph';
import { IActorDetails } from './CustomizedReportsCollection';
import './drilled-bin-histogram.scss';
import BinWaferMapComparisonSummaryTable from './customized-report-utils/BinWaferMapComparisonSummaryTable';
import BinChangeLegend from './customized-report-utils/BinChangeLegend';

interface ICustomizedReportLegendProps extends ICustomizedReportBaseProps {
  legend: IActorDetails;
  pinToggleCallback: (isPinned: boolean) => void;
  reportIndex: number;
  legendData?: ICustomizedLegendData,
  saveData?: (data: ICustomizedLegendData, reportIndex: number, dataType: string) => void;
  waferCombinations?: any;
  binChangeData?:any;
}

interface ICustomizedReportLegendState {
  legendData: ICustomizedLegendData;
  legendPublisherId: string;
  showSpinner: boolean;
  gridHeight: string;
  columnWidth: number;
}

class CustomizedReportLegend extends React.Component<ICustomizedReportLegendProps, ICustomizedReportLegendState> {
  private legendKeyExpression: any[] = [];

  private pubSubBinder = PubSubBinder();

  private selectedLegendRows: ISelectedLegendRows[] = [];

  constructor(props: ICustomizedReportLegendProps) {
    super(props);
    this.legendKeyExpression = [];
    this.state = {
      showSpinner: true,
      legendPublisherId: '',
      // selectedLegendKeys: [],
      legendData: {
        legend: [],
        columns: [],
        selectedInteractionSchemaColumnNames: [],
        similarInteractionSchemaColumnNames: [],
        groupingColumnNames: [],
      },
      gridHeight: '616px',
      columnWidth: 20,
    };
  }

  componentDidMount() {
    const {
      legend,
      viewMode,
      legendData,
      dataHelper,
    } = this.props;
    let {
      legendPublisherId,
    } = this.state;
    legendPublisherId = this.pubSubBinder.RegisterActor(legend.actor, this.legendSubscriptionListener, legend.actor.includes(ReportType.PARAMETRIC_FAILURE));
    this.setState({ legendPublisherId }, () => {
      if (!legendData) {
        this.generateReport(viewMode);
      } else {
        (dataHelper as ILegendDataHelper).setLegendData(legendData);
        this.setState({
          legendData,
          showSpinner: false
        });
      }
    });
  }

  componentDidUpdate(prevProps: ICustomizedReportLegendProps) {
    if (this.checkUpdationConditions(prevProps.aggredateFunction, prevProps.config, prevProps.limitSettingsObj, prevProps.testParameterIndex, prevProps.isSoftBin)) {
      const { viewMode } = this.props;
      this.generateReport(viewMode);
    }
  }

  componentWillUnmount() {
    const { legendPublisherId } = this.state;
    this.pubSubBinder.DisposeActor(legendPublisherId);
  }

  checkUpdationConditions = (prevAggregateFunction: AggregateFunctionNames | 'None', prevConfig: { [key: string]: any }, prevLimitSettingsObj: any, prevTestParameterIndex: number[] | undefined, prevIsSoftBin: boolean) => {
    const {
      aggredateFunction,
      config,
      limitSettingsObj,
      testParameterIndex,
      isSoftBin,
    } = this.props;
    if ((Object.keys(config).length !== 0) && (JSON.stringify(prevAggregateFunction) !== JSON.stringify(aggredateFunction)
      || !_.isEqual(prevConfig, config) || JSON.stringify(prevLimitSettingsObj) !== JSON.stringify(limitSettingsObj)
      || !_.isEqual(prevTestParameterIndex, testParameterIndex) || isSoftBin !== prevIsSoftBin)) {
      return true;
    }

    return false;
  };

  setSelectedLegendRowsData = (selectionData: any) => {
    this.selectedLegendRows = selectionData;
  };

  publishLegendEvent = (mode: InteractionMode, interactionReportType: IInteractionType, action: ActionType, isSecondaryLegend?: boolean) => {
    const {
      viewMode,
      dataHelper,
      saveData,
      reportIndex,
    } = this.props;
    const { legendData } = this.state;
    const { legendPublisherId } = this.state;
    let interactionEventType: InteractionEventType = 'SELECTION';
    if (action.includes('HIDE_')) {
      interactionEventType = 'HIDE';
    } else if (action.includes('SELECT_')) {
      interactionEventType = 'SELECTION';
    } else {
      interactionEventType = 'CLEAR_INTERACTIONS';
    }

    const selectionEventInput: InteractionInputDTO = {
      eventType: interactionEventType,
      interactionMode: mode,
      requestObject: this.getLegendRequestObject(viewMode, isSecondaryLegend),
      interactionDetails: [],
    };

    if (this.selectedLegendRows.length > 0) {
      const keys : string[] = Object.keys(this.selectedLegendRows[0]);
      const relevantKeys: string[] = [];

      if (keys.length > 0) {
        if (interactionReportType.includes('WAFER_MAP_COMPARISON_LEGEND') && isSecondaryLegend){
          relevantKeys.push('baseId');
          relevantKeys.push('nonBaseId');
          relevantKeys.push('binChange');
        } else {
          keys.forEach((k) => {
            if (interactionReportType.includes('PARAMETRIC_FAILURE_LEGEND')
          || (mode === 'SELECTED' && legendData.selectedInteractionSchemaColumnNames.includes(k))
          || (mode === 'SIMILAR' && legendData.similarInteractionSchemaColumnNames.includes(k))
          || (mode === 'SELECTED' && legendData.selectedInteractionSchemaColumnNames.includes(GeneralUtils.Constants.ALL_GROUPINGS) && legendData.groupingColumnNames.includes(k))
          || (mode === 'SIMILAR' && legendData.similarInteractionSchemaColumnNames.includes(GeneralUtils.Constants.ALL_GROUPINGS) && legendData.groupingColumnNames.includes(k))){
              relevantKeys.push(k);
            }
          });
        }

        this.selectedLegendRows.forEach((legendRow:any) => {
          const interactionDetail : { [key:string]: any } = {};
          relevantKeys.forEach((key: string) => {
            interactionDetail[key] = legendRow[key];
            if (interactionDetail[key] === 'N/A'){
              interactionDetail[key] = -1;
            }
          })
          if (interactionReportType.includes('WAFER_MAP_COMPARISON_LEGEND') && isSecondaryLegend){
            const interactionDetailForBaseWafer : { [key:string]: any } = {};
            const interactionDetailForNonBaseWafer: { [key:string]: any } = {};
            interactionDetailForBaseWafer.wafer_id = interactionDetail.baseId;
            interactionDetailForBaseWafer[`die_${legendRow.binClassification.toLowerCase()}_number`] = interactionDetail.binChange.substring(0, interactionDetail.binChange.indexOf('-'));
            interactionDetailForNonBaseWafer.wafer_id = interactionDetail.nonBaseId;
            interactionDetailForNonBaseWafer[`die_${legendRow.binClassification.toLowerCase()}_number`] = interactionDetail.binChange.substring(interactionDetail.binChange.indexOf('>') + 1);
            selectionEventInput.interactionDetails.push(interactionDetailForBaseWafer);
            selectionEventInput.interactionDetails.push(interactionDetailForNonBaseWafer);
          } else {
            selectionEventInput.interactionDetails.push(interactionDetail);
          }
        });
      }
    }

    this.setState({ showSpinner: true }, () => {
      (dataHelper as ILegendDataHelper).postCombinedEvent(selectionEventInput).then((legendDataResponse: ICustomizedLegendData) => {
        this.setState({ legendData: legendDataResponse, showSpinner: false }, () => {
          this.pubSubBinder.BroadcastEvent(legendPublisherId, 'ALL_REPORTS_AND_LEGENDS', action);
          if (saveData) {
            (dataHelper as ILegendDataHelper).setLegendData(legendDataResponse);
            saveData(legendDataResponse, reportIndex, ReportDataTypeConstants.LEGEND);
          }
        });
      });
    });
  }

  generateReport = (viewMode: IViewMode) => {
    const {
      dataHelper,
      saveData,
      reportIndex
    } = this.props;
    this.setState({ showSpinner: true }, () => {
      (dataHelper as ILegendDataHelper).getDetailedGraphLegendData(this.getLegendRequestObject(viewMode), (legendDataResponse: ICustomizedLegendData) => {
        this.setState({
          legendData: legendDataResponse,
          showSpinner: false
        }, () => {
          if (saveData) {
            saveData(legendDataResponse, reportIndex, ReportDataTypeConstants.LEGEND);
          }
        });
      });
    });
  };

  legendSubscriptionListener = (action: ActionType) => {
    const {
      viewMode,
      dataHelper
    } = this.props;
    let handler: any;
    if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS' || action === 'SELECT_LEGEND_ITEMS') {
      handler = (dataHelper as ILegendDataHelper).subscribeSelectionEvent;
    } else if (action === 'HIDE_GRAPH_AND_LEGEND_ITEMS' || action === 'HIDE_LEGEND_ITEMS') {
      handler = (dataHelper as ILegendDataHelper).subscribeHidingEvent;
    }

    if (handler) {
      this.setState({ showSpinner: true }, () => {
        handler(this.getLegendRequestObject(viewMode), (legendDataResponse: ICustomizedLegendData) => {
          this.setState(() => ({
            legendData: legendDataResponse,
            showSpinner: false,
          }));
        });
      });
    }
  };

  getLegendRequestObject = (viewMode: IViewMode, isSecondaryLegend?: boolean) => {
    const {
      selectionStoreDataSetCount,
      selectedBinPlusDefinition,
      parseFilter,
      selectionStore,
      groupingSortingListStore,
      reportSessionId,
      aggredateFunction,
      legend,
      config,
      testParameterIndex,
      productType,
      limitSettingsObj,
      isSoftBin,
    } = this.props;
    if (isSecondaryLegend){
      config.isSecondaryLegend = true;
    }
    const data: IGenericDetailedReportRequestObject = {
      productType,
      selectionStoreDataSetCount,
      scwData: selectionStore.selections.map((item) => ({
        entityType: (item.controlType as EntityType),
        values: item.values.map((val) => (val.id)),
      })),
      filters: JSON.stringify(parseFilter(selectionStore)),
      binPlusDefId: selectedBinPlusDefinition.id,
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: aggredateFunction,
      seriesType: null,
      isSoftBin,
      reportType: legend.actor,
      config,
      testParameterIndex,
      limits: limitSettingsObj,
    };
    return data;
  };

  getDefaultGroupingLabel = (data: any) => {
    if (data.items && data.items.length > 0) {
      return data.items[0].default_grouping_label || data.key;
    }
    if (data.collapsedItems && data.collapsedItems.length > 0) {
      return data.collapsedItems[0].default_grouping_label || data.key;
    }
    if (data.items && data.items.length === 0 && data.key) {
      return data.key;
    }
    return '';
  };

  getMismatchDieCount = (data: any) => {
    if (data.items && data.items.length > 0) {
      return data.items[0].grouped_wafer_mismatched_die_count;
    } if (data.collapsedItems && data.collapsedItems.length > 0) {
      return data.collapsedItems[0].grouped_wafer_mismatched_die_count;
    } return '';
  }

  getMismatchDieContribution = (data: any) => {
    if (data.items && data.items.length > 0) {
      return data.items[0].wafer_mismatched_die_count_contribution;
    } if (data.collapsedItems && data.collapsedItems.length > 0) {
      return data.collapsedItems[0].wafer_mismatched_die_count_contribution;
    } return '';
  }

  renderLegendComponent = (data: any, index: number) => {
    const { viewMode, dataHelper, legend } = this.props;
    const {
      legendData, gridHeight, columnWidth,
    } = this.state;
    const {
      legendKeyExpression,
      setSelectedLegendRowsData,
    } = this;

    const columns = (viewMode === 'COMBINED'
      ? legendData.columns[index].filter((x: any) => x.columnName !== 'Z' && x.columnName !== 'XText' && x.columnName !== 'XCoordinate' && x.columnName !== 'YCoordinate' && x.columnName !== 'Wafer Ids').map((item: any) => {
        let dataField = '';
        let columnItem;
        dataField = item.dataField;
        if (item.dataField === 'die_hard_bin_number'
          || item.dataField === 'GroupingKey'
          || item.dataField === 'default_grouping_label' || item.dataField === 'test_summary_id'
          || item.dataField === 'grouped_wafer_mismatched_die_count' || item.dataField === 'wafer_mismatched_die_count_contribution') {
          columnItem = ({
            caption: item.columnName,
            dataField,
            visible: false,
          });
        } else if (item.dataField === 'Color Band') {
          columnItem = {
            caption: item.columnName,
            visible: true,
            dataField,
            cellRender: (e: any) => {
              return (
                <div className="rounded mr5 mt0 ml0 mb0 d-flex align-items-center justify-content-center w20 h20 " style={{ backgroundColor: e.data['Color Band'] }}>
                  {e.data.die_hard_bin_number !== undefined
                    ? <span className="m0 p0 color-light">{e.data.die_hard_bin_number}</span> : <></>}
                </div>
              );
            },
          };
        } else if (item.groupIndex === 0) {
          columnItem = ({
            caption: item.columnName,
            visible: true,
            dataField,
          });
        } else {
          columnItem = ({
            caption: (item.columnName.includes('_id') || item.columnName.includes('_key')) ? item.entityType : item.columnName,
            visible: true,
            dataField,
            groupIndex: item.groupIndex,
            groupCellRender: legendData.columns[index].find((i: any) => i.groupIndex === Math.max(...legendData.columns[index].map((x: any) => +x.groupIndex))).columnName === item.columnName
              ? (e: any) => {
                const groupCell = legendData.columns[index].find((i: any) => i.groupIndex === Math.max(...legendData.columns[index].map((x: any) => +x.groupIndex)));
                const groupCellCaption = groupCell ? `${
                  (groupCell.columnName.includes('_id') || groupCell.columnName.includes('_key')) ? groupCell.entityType : groupCell.columnNamegroup
                }:` : '';
                return (
                  <div>
                    <span className="mr15">{`${groupCellCaption + this.getDefaultGroupingLabel(e.data)}`}</span>
                    {
                      legend.actor === 'BIN_WAFER_MAP_LEGEND' || legend.actor === 'BIN_PARETO_LEGEND' || legend.actor === 'PARAMETRIC_WAFER_MAP_LEGEND'
                      || legend.actor === 'BIN_WAFER_MAP_COMPARISON_LEGEND' || legend.actor === 'PARAMETRIC_HISTOGRAM_LEGEND'
                      || legend.actor === 'PARAMETRIC_TREND_LEGEND' || legend.actor === 'PARAMETRIC_BOX_PLOT_LEGEND' || legend.actor === 'PARAMETRIC_XY_SCATTER_PLOT_LEGEND'
                        ? (
                          <>
                            <span className="mr15">{`Mismatched Die Count: ${this.getMismatchDieCount(e.data)}`}</span>
                            <span className="mr15">{`Mismatched Die Contribution: ${this.getMismatchDieContribution(e.data)} %`}</span>
                          </>
                        ) : undefined
                    }
                  </div>
                );
              } : undefined,
          });
        }

        let headerCellRender;

        if (dataField === 'failed_percentage' || dataField === 'failed_contribution') {
          headerCellRender = (d: any) => {
            return (
              <div>
                <div style={{
                  width: '20px', height: '5px', color: '#FFFFFF', backgroundColor: dataField === 'failed_percentage' ? '#00FF00' : '#f05c42',
                }}
                />
                {' '}
                {d.column.caption}
              </div>
            );
          };
        }

        return (
          <Column
            visible={columnItem.visible}
            dataField={columnItem.dataField}
            caption={columnItem.caption}
            cellRender={columnItem.cellRender}
            groupCellRender={columnItem.groupCellRender}
            sortOrder={item.sortOrder}
            groupIndex={columnItem.groupIndex}
            width={item.dataField === 'Color Band' ? 50 : 70}
            dataType={item.type !== null ? item.type : undefined}
            headerCellRender={headerCellRender}
          />
        );
      }) : <></>);

    const dataGridProps: any = {
      wordWrapEnabled: true,
      showBorders: true,
      columnAutoWidth: false,
      allowColumnResizing: true,
      allowColumnReordering: true,
      key: (new Date()).toString(),
      height: gridHeight,
      columnMinWidth: columnWidth,
      width: '100%',
      dataSource: data,
      filterRow: {
        visible: true,
      },
      keyExpr: legendKeyExpression,
      selection: {
        mode: 'multiple',
      },
      scrolling: {
        mode: 'virtual',
        columnRenderingMode: 'virtual',
        useNative: true,
      },
      groupPanel: {
        visible: true,
      },
      paging: {
        pageSize: '',
      },
      onRowPrepared: (e: any) => {
        if (e.rowType === 'data' && (e.data.hidden || e.data.hidden === 'true')) {
          e.rowElement.classList.add('row-disable');
        }
      },
      onContextMenuPreparing: (e: any) => {
        if (e.row && e.row.rowType === 'data') {
          e.items = [
            {
              text: 'Hide',
              visible: true,
              items: [
                {
                  text: 'Hide Selected',
                  onItemClick: () => {
                    this.publishLegendEvent('SELECTED', legend.hidingEventPublisher, 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Hide Similar',
                  onItemClick: () => {
                    this.publishLegendEvent('SIMILAR', legend.hidingEventPublisher, 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Hide All',
                  onItemClick: () => {
                    this.publishLegendEvent('ALL', legend.hidingEventPublisher, 'HIDE_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
              ],
            },
            {
              text: 'Highlight',
              visible: true,
              items: [
                {
                  text: 'Highlight Selected',
                  onItemClick: () => {
                    this.publishLegendEvent('SELECTED', legend.selectionEventPublisher, 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Highlight Similar',
                  onItemClick: () => {
                    this.publishLegendEvent('SIMILAR', legend.selectionEventPublisher, 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
                {
                  text: 'Highlight All',
                  onItemClick: () => {
                    this.publishLegendEvent('ALL', legend.selectionEventPublisher, 'SELECT_GRAPH_AND_LEGEND_ITEMS');
                  },
                },
              ],
            },
          ];
        }
      },
      onSelectionChanged: (e: any) => {
        setSelectedLegendRowsData(e.selectedRowsData);
      },
    };
    if (legendData.selectedLegendKeys !== undefined && legendData.selectedLegendKeys.length > 0) {
      if (legendData.selectedLegendKeys[0].length === 0) {
        dataGridProps.defaultSelectedRowKeys = undefined;
      } else {
        dataGridProps.defaultSelectedRowKeys = legendData.selectedLegendKeys[0];
      }
    }

    dataGridProps.summary = {};

    // populate summary list in helper using summary dictionary

    const legendPreferences = (dataHelper as ILegendDataHelper).getPreferences();

    if (legendPreferences.summary.totalItems !== undefined) {
      dataGridProps.summary.totalItems = legendPreferences.summary.totalItems;
    }
    if (legendPreferences.summary.groupItems !== undefined) {
      dataGridProps.summary.groupItems = legendPreferences.summary.groupItems;
    }

    if (dataGridProps.summary !== undefined && legendPreferences.summary.calculateCustomSummary !== undefined) {
      dataGridProps.summary.calculateCustomSummary = legendPreferences.summary.calculateCustomSummary;
    }

    return <DataGrid {...dataGridProps}>{[...columns]}</DataGrid>;

    // React.createElement(DataGrid, dataGridProps, [
    //   columns,
    // ]);
  };

  render() {
    const {
      legendData,
      showSpinner,
    } = this.state;
    const {
      legend,
      pinToggleCallback,
      dataHelper,
      reportIndex,
      waferCombinations,
      isSoftBin,
      binChangeData,
    } = this.props;

    if (legendData.columns.length === 1) {
      // CHECK FOR ACTIVE LEGEND, NOT FIRST LEGEND 💀💀💀⚡⚡⚡
      this.legendKeyExpression = legendData.columns[0].filter((c: any) => c.groupIndex !== 0)
        .map((c: any) => (c.dataField));
      if (this.legendKeyExpression.filter((x) => x === (dataHelper as ILegendDataHelper).preferences.legendKeyExpression).length === 0) {
        this.legendKeyExpression.push((dataHelper as ILegendDataHelper).preferences.legendKeyExpression);
      }
    }
    return (
      <div className="mt6 pr20">
        {showSpinner && (
          <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: '700px' }}>
            <Spinner animation="border" />
          </div>
        )}
        {
          legend.actor === ReportTypeConstants.BIN_WAFER_MAP_COMPARISON_LEGEND && legendData.legend.length > 0 && (
            <BinChangeLegend
              showSpinner={showSpinner}
              pinToggleCallback={pinToggleCallback}
              reportIndex={reportIndex}
              legend={legend}
              isSoftBin={isSoftBin}
              waferCombinations={waferCombinations}
              legendData={legendData}
              binChangeData={binChangeData}
              publishLegendEventCallback={this.publishLegendEvent}
              setSelectedLegendRowsData={this.setSelectedLegendRowsData}
            />
          )
        }
        {
          legendData.legend.map((data: any, index: number) => (
            <WidgetCard
              showSpinner={showSpinner}
              pinToggleCallback={pinToggleCallback}
              fullWidth
              linkToggle={false}
              expandToggle
              changeExpandedStateComponentOnUpdate={false}
              pinToggle
              pinWidgetByDefault={false}
              id={`legend_${reportIndex}`}
              widgetName={toTitleCase(legend.actor)}
              primaryWidgetWeight={12}
              cardPaddingClass="p0 pl0"
              showBorder={false}
              updatePrimaryWidgetHeightAndWidth={(newHeight: string, newWidth?: number) => {
                this.setState({
                  gridHeight: `${newHeight}px`,
                  columnWidth: newWidth || 20
                });
              }}
              primaryWidget={(
                <div className="w-100">
                  {this.renderLegendComponent(data, index)}
                </div>
              )}
            />
          ))
        }
        {
          legend.actor === ReportTypeConstants.BIN_WAFER_MAP_COMPARISON_LEGEND && (
            <BinWaferMapComparisonSummaryTable
              selectedCombinations={waferCombinations}
            />
          )
        }
      </div>
    );
  }
}

export default CustomizedReportLegend;
