import React, { useState } from 'react';
import {
  Container, Modal, Row, Col,
} from 'react-bootstrap';
// import { ISelectionCriteriaWidgetList } from 'interfaces';
import Button from '../wrapped-component/hint-controls/Button';

interface ISelectionCriteriaModalProps {
  onModalClose: () => void;
  showModal: boolean;
  applyCriteria: (obj: any) => void;
  widgetsList?: any;
}

const SelectionCriteriaModal = (props: ISelectionCriteriaModalProps) => {
  const {
    showModal,
    onModalClose,
    applyCriteria,
    widgetsList,
  } = props;
  const [selectionCriteria, setSelectionCriteria] = useState(null);
  return (
    <Modal
      show={showModal}
      onHide={onModalClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="border-none"
    >
      <Modal.Header closeButton className="border-none">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100 "
        >
          <p className="text-center">Selection Criteria</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              // TO DO: SELECTION CRITERIA FIX INTEGRATION
              {/* <SelectionCriteria
                widgetsList={widgetsList}
                getSelectionCriteriaData={(obj: any) => {
                  setSelectionCriteria(obj);
                }}
              /> */}
            </Col>
          </Row>
          <Col>
            <Row className="d-flex flex-row-reverse">
              <Button variant="primary" size="sm" className="mr30 mt-10" onClick={() => applyCriteria(selectionCriteria)}>
                Apply
              </Button>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
  );
};
SelectionCriteriaModal.defaultProps = {
  widgetsList: undefined,
};

export default SelectionCriteriaModal;
