import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import TreeList, { Column } from 'devextreme-react/tree-list';
import * as _ from 'lodash';
import { DataGrid } from 'devextreme-react';
import {
  Button as DxButton, Editing, RowDragging, SearchPanel, StateStoring,
} from 'devextreme-react/data-grid';
import Button from '../../wrapped-component/hint-controls/Button';

class CreateGroup extends Component<any, any> {
  private editGroupName = '';

  constructor(props: any) {
    super(props);
    const { selectedGroupDialog } = this.props;
    const copySelectedGroupDialog = JSON.parse(JSON.stringify(selectedGroupDialog));
    this.state = {
      currentGroup: copySelectedGroupDialog !== undefined ? copySelectedGroupDialog[0] : {},
      selectedGroupsDialog: copySelectedGroupDialog,
      selectedField: [],
      editGroupitem: '',
      collapse: false,
    };
    this.createGroup = this.createGroup.bind(this);
    this.saveGroups = this.saveGroups.bind(this);
    this.toggleCurrentGroup = this.toggleCurrentGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
    this.getSelectedField = this.getSelectedField.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.removeField = this.removeField.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.groupNameChanged = this.groupNameChanged.bind(this);
    this.changeGroupName = this.changeGroupName.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    const { collapse } = this.state;

    this.setState({ collapse: !collapse });
  }

  createGroup(e: any) {
    const item:any = e.row.data;
    const { selectedGroupsDialog } = this.state;
    selectedGroupsDialog.splice(0);
    const { currentGroup } = this.state;
    const createNew: boolean = currentGroup.groupObject === undefined;
    if (currentGroup.groupObject !== undefined) {
      currentGroup.groupObject.push({
        TableName: item.TableName,
        Columns: [{
          ColumnName: item.ColumnName,
          Sort: '',
        }],
      });
      let caption = '';
      currentGroup.groupObject.forEach((group: any) => {
        group.Columns.forEach((cols: any) => {
          caption = `${caption + group.TableName} ${cols.ColumnName} / `;
        });
      });

      currentGroup.caption = caption.substring(0, caption.length - 2);
    }
    let index = -1;
    if (!createNew) {
      index = _.findIndex(selectedGroupsDialog, (item: any) => {
        return item.caption === currentGroup.caption;
      });
    }
    if (index === -1) {
      selectedGroupsDialog.push(currentGroup);
    } else {
      selectedGroupsDialog[index] = currentGroup;
    }

    this.setState({
      currentGroup,
      selectedGroupsDialog,
    }, () => {
      this.getSelectedField();
    });
  }

  componentDidMount() {
    this.getSelectedField();
  }

  getGroupName(selectedGroupsDialog: any, columnName: string) {
    let groupIndex: number = selectedGroupsDialog.length;
    let groupName: string;
    while (true) {
      groupName = `group ${groupIndex}`;
      const index = _.findIndex(selectedGroupsDialog, (item: any) => {
        return item.caption === groupName;
      });
      if (index === -1) {
        return groupName;
      }
      groupIndex += 1;
    }
  }

  cellRender(e:any) {
    if (e.rowType === 'data' && e.columnIndex == 1) {
      if (e.data.ParentId === null) {
        e.cellElement.className = 'd-none';
      } else {
        e.cellElement.className = 'dx-command-edit dx-command-edit-with-icons';
      }
    }
  }

  saveGroups() {
    const { groupUpdated, closeDialog, groupChanged } = this.props;
    const { selectedGroupsDialog, currentGroup } = this.state;
    if (groupUpdated !== undefined) {
      groupUpdated(selectedGroupsDialog);
    }
    if (closeDialog !== undefined) {
      closeDialog();
    }
  }

  toggleCurrentGroup(item: any) {
    const { currentGroup } = this.state;
    if (item.caption === currentGroup.caption) {
      this.setState({ currentGroup: {} }, () => {
        this.getSelectedField();
      });
    } else {
      this.setState({ currentGroup: item }, () => {
        this.getSelectedField();
      });
    }
  }

  removeGroup(item: any) {
    const { selectedGroupsDialog } = this.state;
    const index = _.findIndex(selectedGroupsDialog, (element: any) => {
      return element.caption === item.caption;
    });
    selectedGroupsDialog.splice(index, 1);
    this.setState({ selectedGroupsDialog });
  }

  onReorder(e: any) {
    const visibleRows = e.component.getVisibleRows();
    const { selectedField, currentGroup } = this.state;
    const newTasks = selectedField;
    const toIndex = newTasks.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newTasks.indexOf(e.itemData);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);
    const spliceObject = currentGroup.groupObject[fromIndex];
    currentGroup.groupObject.splice(fromIndex, 1);
    currentGroup.groupObject.splice(toIndex, 0, spliceObject);
    let caption = '';
    currentGroup.groupObject.forEach((group: any) => {
      group.Columns.forEach((cols: any) => {
        caption = `${caption + group.TableName} ${cols.ColumnName} / `;
      });
    });

    currentGroup.caption = caption.substring(0, caption.length - 2);
    this.setState({
      selectedField: newTasks,
      currentGroup,
    });
  }

  getSelectedField() {
    const { currentGroup } = this.state;
    const selectedField: any = [];
    if (currentGroup.groupObject !== undefined) {
      currentGroup.groupObject.forEach((item: any) => {
        item.Columns.forEach((column: any) => {
          selectedField.push({
            Caption: `${item.TableName} ${column.ColumnName}`,
            TableName: item.TableName,
            ColumnName: column.ColumnName,
          });
        });
      });
    }
    this.setState({ selectedField });
  }

  removeField(e: any) {
    const { currentGroup } = this.state;

    const object = _.find(currentGroup.groupObject, (item) => {
      return item.TableName === e.row.data.TableName;
    });
    let deleteObject: any;
    currentGroup.caption = currentGroup.caption.toLowerCase()
      .replace(e.row.data.Caption.toLowerCase(), '');
    object.Columns.forEach((subItem: any) => {
      if (subItem.ColumnName === e.row.data.ColumnName) {
        deleteObject = object;
      }
    });

    const objectIndex: number = _.findIndex(currentGroup.groupObject, deleteObject);
    currentGroup.groupObject.splice(objectIndex, 1);
    this.setState({
      currentGroup,
    }, () => {
      this.getSelectedField();
    });
  }

  editGroup(item: any, e: any) {
    this.setState({ editGroupitem: item });
    e.stopPropagation();
  }

  groupNameChanged(e: any) {
    this.editGroupName = e.target.value;
  }

  changeGroupName(groupName: string) {
    const { selectedGroupsDialog } = this.state;
    const group = _.find(selectedGroupsDialog, (item: any) => {
      return item.caption === groupName;
    });
    group.caption = this.editGroupName;
    this.setState({
      selectedGroupsDialog,
    });
  }

  closeDialog() {
    const { closeDialog } = this.props;
    if (closeDialog !== undefined) {
      closeDialog();
    }
  }

  getTemplate(item:any) {
    if (item.items === undefined) {
      return (
        <div className="w100p">
          {item.text}
          <Button className="btn btn-clear float-right p0 h16 btn-sm" onClick={() => this.createGroup(item)}>
            <i className="dx-icon-plus" />
          </Button>
        </div>
      );
    }
    return (
      <div>
        {item.text}
        {' '}
      </div>
    );
  }

  render() {
    const columnsList:any = [{
      Id: '1',
      TableName: 'facility',
      Caption: 'Facility',
      ParentId: null,
    }, {
      Id: '1-1',
      TableName: 'facility',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '1',
    }, {
      Id: '1-2',
      TableName: 'facility',
      ColumnName: 'type',
      Caption: 'Type',
      ParentId: '1',
    }, {
      Id: '1-3',
      TableName: 'facility',
      ColumnName: 'test_facility',
      Caption: 'Test Facility',
      ParentId: '1',
    }, {
      Id: '2',
      TableName: 'Work_Center',
      Caption: 'Work Center',
      ParentId: null,
    }, {
      Id: '2-1',
      TableName: 'Work_Center',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '2',
    }, {
      Id: '2-2',
      TableName: 'Work_Center',
      ColumnName: 'test_temperature',
      Caption: 'Test Temperature',
      ParentId: '2',
    }, {
      Id: '3-1',
      TableName: 'Device_Family',
      ColumnName: 'name',
      Caption: 'Device Family',
      ParentId: '4',
    }, {
      Id: '4',
      TableName: 'Device',
      Caption: 'Device',
      ParentId: null,
    }, {
      Id: '4-1',
      TableName: 'Device',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '4',
    }, {
      Id: '5',
      TableName: 'test_program',
      Caption: 'Test Program',
      ParentId: null,
    }, {
      Id: '5-1',
      TableName: 'test_program',
      ColumnName: 'name',
      Caption: 'Name',
      ParentId: '5',
    }, {
      Id: '5-2',
      TableName: 'test_program_revision',
      ColumnName: 'revision_number',
      Caption: 'Revision Number',
      ParentId: '5',
    }, {
      Id: '6',
      TableName: 'lot',
      Caption: 'Lot',
      ParentId: null,
    }, {
      Id: '6-1',
      TableName: 'lot',
      ColumnName: 'key',
      Caption: 'LOT Id',
      ParentId: '6',
    }, {
      Id: '6-2',
      TableName: 'lot',
      ColumnName: 'start_time',
      Caption: 'Lot Start Time',
      ParentId: '6',
    }, {
      Id: '6-3',
      TableName: 'lot',
      ColumnName: 'end_time',
      Caption: 'Lot Finish Time',
      ParentId: '6',
    }, {
      Id: '6-4',
      TableName: 'lot',
      ColumnName: 'test_temperature',
      Caption: 'Lot Test Temperature',
      ParentId: '6',
    }, {
      Id: '6-5',
      TableName: 'lot',
      ColumnName: 'lot_complete_status',
      Caption: 'Lot Complete Status',
      ParentId: '6',
    }, {
      Id: '7',
      Caption: 'Wafer',
      ParentId: null,
    }, {
      Id: '7-1',
      TableName: 'wafer',
      ColumnName: 'key',
      Caption: 'Wafer Id',
      ParentId: '7',
    }, {
      Id: '7-2',
      TableName: 'wafer',
      ColumnName: 'wafer_start_time',
      Caption: 'Wafer Start Time',
      ParentId: '7',
    }, {
      Id: '7-3',
      TableName: 'wafer',
      ColumnName: 'wafer_finish_time',
      Caption: 'Wafer Finish Time',
      ParentId: '7',
    }, {
      Id: '7-4',
      TableName: 'wafer',
      ColumnName: 'die_count',
      Caption: 'Total Dies',
      ParentId: '7',
    }, {
      Id: '7-5',
      TableName: 'wafer',
      ColumnName: 'good_count',
      Caption: 'Pass Dies',
      ParentId: '7',
    }, {
      Id: '7-6',
      TableName: 'wafer',
      ColumnName: 'probe_count',
      Caption: 'Probe Count',
      ParentId: '7',
    }, {
      Id: '7-7',
      TableName: 'wafer',
      ColumnName: 'test_temperature',
      Caption: 'Wafer Test Temperature',
      ParentId: '7',
    }, {
      Id: '7-8',
      TableName: 'wafer',
      ColumnName: 'test_program_revision_id',
      Caption: 'Test Program Revision',
      ParentId: '7',
    }];

    const {
      selectedGroupsDialog, currentGroup, selectedField, editGroupitem, collapse,
    } = this.state;

    const availableFields: any = [];
    columnsList.map((item: any) => {
      const foundObject: any = _.find(currentGroup.groupObject, (cobject) => {
        return cobject.TableName === item.TableName && _.findIndex(cobject.Columns, ((columns: any) => {
          return columns.ColumnName === item.ColumnName;
        })) !== -1;
      });
      if (foundObject === undefined) {
        availableFields.push(item);
      }
    });

    return (
      <div className="bg-white create-groups">
        <div className="p10">
          <h5>
            Group Management
            <Button
              variant="clear float-right p0 h16 pl5"
              onClick={() => {
                this.closeDialog();
              }}
            >
              <i className="icon dx-icon-remove float-right" />
            </Button>
          </h5>
        </div>

        <div className=" p10 d-flex custom-form">
          <Col>
            <h6>Available Fields</h6>
            <div className="border-all section-fields">
              <TreeList
                dataSource={availableFields}
                keyExpr="Id"
                parentIdExpr="ParentId"
                showColumnHeaders={false}
                showColumnLines={false}
                onCellPrepared={this.cellRender}
                height={337}
                autoExpandAll={false}
                repaintChangesOnly
              >
                <SearchPanel visible width="100%" />
                <Column
                  dataField="Caption"
                />
                <Column
                  type="buttons"
                  width={36}
                >
                  <DxButton hint="Add to Selected List" icon="add" cssClass="text-success" onClick={this.createGroup} />
                </Column>

              </TreeList>
            </div>

          </Col>
          <Col>
            <h6>Selected Fields</h6>
            <div className="border-all section-fields">

              <DataGrid
                height={340}
                dataSource={selectedField}
                showBorders
                showColumnHeaders={false}
                showColumnLines={false}
                showRowLines
                repaintChangesOnly
              >
                <Editing
                  mode="row"
                  useIcons
                  allowDeleting
                />
                <RowDragging
                  allowReordering
                  onReorder={this.onReorder}
                  showDragIcons={this.state.showDragIcons}
                />
                <StateStoring enabled={false} />
                <Column dataField="Caption" />
                <Column type="buttons" width={40}>
                  <DxButton hint="Delete" icon="close" cssClass="text-danger" onClick={this.removeField} />
                </Column>
              </DataGrid>

            </div>
          </Col>
        </div>
        <div>
          <Button variant="primary float-right mr25" onClick={this.saveGroups}>Apply & Close</Button>
        </div>
      </div>

    );
  }
}

export default CreateGroup;
