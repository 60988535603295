/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faMarker, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipWrapper from 'components/wrapped-component/hint-controls/tooltip-wrapper';
import React from 'react';
import { FeatureSpecificWaferTag, MarkingMode, RotateDirection } from '../../wafer-map/web-gl-utils/Enums';
import { WaferStateStacks } from '../types';
import { ActionHandler, ActionType } from '../WaferPlotter/WaferPlotter';
import RedoUndoControl from './RedoUndoControl/RedoUndoControl';

export type WaferMainControlsProps = {
  featureSpecificWaferTags?: FeatureSpecificWaferTag[],
  keyIndex: string,
  showRadar: boolean,
  waferStateStacks: WaferStateStacks,
  showUndoPopover: boolean,
  showRedoPopover: boolean,
  onToggleUndoPopover: (shouldOpen: boolean) => void,
  onToggleRedoPopover: (shouldOpen: boolean) => void,
  actionHandler: ActionHandler,
  isSelectionControlActive: boolean,
  isDragControlActive: boolean,
  isBoxZoomControlActive: boolean,
  hasMarkingFeature: boolean;
  isMarkingControlActive: boolean;
  hasRotationControls: boolean,
  markingMode: MarkingMode;
  selectionChangeHandler: (value: boolean, isFromMarkControls: boolean, keyIndex: string) => void;
  showRadarHandler: (value: boolean, keyIndex: string) => void;
  dragChangeHandler: (value: boolean, keyIndex: string) => void;
  zoomChangeHandler: (value: boolean, keyIndex: string) => void;
  rotateWaferMap: (direction: RotateDirection, keyIndex: string) => void;
  resetWaferZoomPositionRotation: (keyIndex: string) => void;
  zoomWaferMapUsingButtons: (zoomFactor: number, keyIndex: string) => void;
  clearSelectionHandler: (keyIndex: string) => void;
};

type WaferMainControlsState = {

};

export default class WaferMainControls extends React.Component<WaferMainControlsProps, WaferMainControlsState> {
  getMarkingText = () => {
    const { markingMode } = this.props;
    let markingText = 'Mark Die Types';
    if (markingMode === MarkingMode.WAT_PCM_SITE_NUMBER) {
      markingText = 'Mark WAT / PCM Sites';
    } else if (markingMode === MarkingMode.ZONE_SITE) {
      markingText = 'Mark Zone';
    }
    return markingText;
  };

  render() {
    const {
      keyIndex, isSelectionControlActive, selectionChangeHandler, isMarkingControlActive, resetWaferZoomPositionRotation, isBoxZoomControlActive, clearSelectionHandler,
      hasMarkingFeature, isDragControlActive, dragChangeHandler, hasRotationControls, rotateWaferMap, zoomWaferMapUsingButtons, zoomChangeHandler, onToggleUndoPopover,
      showUndoPopover, actionHandler, showRedoPopover, onToggleRedoPopover, waferStateStacks, featureSpecificWaferTags,
    } = this.props;

    return (
      <>
        <TooltipWrapper text="Die / Reticle Selection" id={`wafer-action-selection${keyIndex}`}>
          <label className="wafer-action" htmlFor={`selectionRadioButton${keyIndex}`}>
            <input
              className="mr10 "
              type="radio"
              id={`selectionRadioButton${keyIndex}`}
              onChange={(e: any) => { selectionChangeHandler(e.target.checked, false, keyIndex); }}
              checked={isSelectionControlActive}
            />
            <svg
              viewBox="0 0 1000 1000"
              className="icon"
              height="1em"
              width="1em"
            >
              <path
                d="m0 850l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0
    143-143 0z m286 0l0-143 143 0 0 143-143 0z m-857-286l0-143 143 0 0 143-143 0z m857 0l0-143
    143 0 0 143-143 0z m-857-285l0-143 143 0 0 143-143 0z m857 0l0-143 143 0 0 143-143 0z m-857-286l0-143
    143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z m285 0l0-143 143 0 0 143-143 0z m286 0l0-143 143 0 0 143-143 0z"
                transform="matrix(1 0 0 -1 0 850)"
              />
            </svg>
          </label>
        </TooltipWrapper>
        <TooltipWrapper text="Clear Selection" id="wafer-action-clear-selection">
          <div className="wafer-action ">
            <FontAwesomeIcon
              role="button"
              icon={faUndo}
              cursor="pointer"
              onClick={async () => { await clearSelectionHandler(keyIndex); }}
            />
          </div>
        </TooltipWrapper>
        {hasMarkingFeature && (
          <TooltipWrapper text={this.getMarkingText()} id="wafer-action-mark-die-types">
            <label className="wafer-action" htmlFor={`markDieTypes${keyIndex}`}>
              <input
                className="mr10 "
                type="radio"
                id={`markDieTypes${keyIndex}`}
                onChange={(e: any) => { selectionChangeHandler(e.target.checked, true, keyIndex); }}
                checked={isMarkingControlActive}
              />
              <FontAwesomeIcon
                icon={faMarker}
                cursor="pointer"
              />
            </label>
          </TooltipWrapper>
        )}
        <TooltipWrapper text="Drag" id="wafer-action-drag">
          <label className="wafer-action" htmlFor={`draggingRadioButton${keyIndex}`}>
            <input
              className="mr10 "
              type="radio"
              id={`draggingRadioButton${keyIndex}`}
              checked={isDragControlActive}
              onChange={(e: any) => { dragChangeHandler(e.target.checked, keyIndex); }}
            />
            <i className="yw-icons-pan" />
          </label>
        </TooltipWrapper>
        <TooltipWrapper text="Box Zoom" id="wafer-action-box-zoom">
          <label className="wafer-action" htmlFor={`zoomingRadioButton${keyIndex}`}>
            <input
              className="mr10 "
              type="radio"
              id={`zoomingRadioButton${keyIndex}`}
              checked={isBoxZoomControlActive}
              onChange={(e: any) => { zoomChangeHandler(e.target.checked, keyIndex); }}
            />
            <i className="yw-icons-zoombox" />
          </label>
        </TooltipWrapper>
        <TooltipWrapper text="Zoom In" id="wafer-action-zoom-in">
          <div className="wafer-action ">
            <i
              role="button"
              className="yw-icons-zoom-plus mt-0"
              style={{ cursor: 'pointer' }}
              id={`zoomPlus${keyIndex}`}
              aria-label="zoom-in"
              aria-hidden
              onClick={() => { zoomWaferMapUsingButtons(1.1, keyIndex); }}
            />
          </div>
        </TooltipWrapper>
        <TooltipWrapper text="Zoom Out" id="wafer-action-zoom-out">
          <div className="wafer-action ">
            <i
              role="button"
              aria-label="zoom-out"
              aria-hidden
              className="yw-icons-zoom-minus"
              style={{ cursor: 'pointer' }}
              id={`zoomMinus${keyIndex}`}
              onClick={() => { zoomWaferMapUsingButtons(0.9, keyIndex); }}
            />
          </div>
        </TooltipWrapper>
        { hasRotationControls && (
          <>
            <TooltipWrapper text="Rotate anti-clockwise" id="wafer-action-anti-clockwise">
              <div className="wafer-action ">
                <i
                  role="button"
                  className="yw-icons-rotate-ccw mt-0"
                  style={{ cursor: 'pointer' }}
                  id={`rotateAntiClockwise${keyIndex}`}
                  aria-label="rotateAntiClockwise"
                  aria-hidden
                  onClick={async () => { await rotateWaferMap(RotateDirection.AntiClockWise, keyIndex); }}
                />
              </div>
            </TooltipWrapper>
            <TooltipWrapper text="Rotate clockwise" id="wafer-action-clockwise">
              <div className="wafer-action ">
                <i
                  role="button"
                  aria-label="rotateClockwise"
                  aria-hidden
                  className="yw-icons-rotate-cw"
                  style={{ cursor: 'pointer' }}
                  id={`rotateClockwise${keyIndex}`}
                  onClick={async () => { await rotateWaferMap(RotateDirection.ClockWise, keyIndex); }}
                />
              </div>
            </TooltipWrapper>
          </>
        ) }
        <TooltipWrapper text="Reset" id="wafer-action-reset">
          <div className="wafer-action ">
            <i
              role="button"
              aria-label="reset"
              aria-hidden
              className="yw-icons-home"
              style={{ cursor: 'pointer' }}
              id={`zoomReset${keyIndex}`}
              onClick={() => { resetWaferZoomPositionRotation(keyIndex); }}
            />
          </div>
        </TooltipWrapper>
        { featureSpecificWaferTags && featureSpecificWaferTags.includes(FeatureSpecificWaferTag.WAFER_CONTROL_MAP) && waferStateStacks[keyIndex]
        && (
          <>
            <RedoUndoControl
              icon={faUndo}
              actionHandler={actionHandler}
              actionType={ActionType.UNDO}
              disabled={waferStateStacks[keyIndex].ptr === 0}
              keyIndex={keyIndex}
              onTogglePopover={onToggleUndoPopover}
              showPopover={showUndoPopover}
              listOfItems={waferStateStacks[keyIndex].stack.slice(1, waferStateStacks[keyIndex].ptr + 1).reverse()}
            />
            <RedoUndoControl
              icon={faRedo}
              actionHandler={actionHandler}
              actionType={ActionType.REDO}
              disabled={waferStateStacks[keyIndex].stack.length - 1 === waferStateStacks[keyIndex].ptr}
              keyIndex={keyIndex}
              onTogglePopover={onToggleRedoPopover}
              showPopover={showRedoPopover}
              listOfItems={waferStateStacks[keyIndex].stack.slice(waferStateStacks[keyIndex].ptr + 1)}
            />
          </>
        )}
      </>
    );
  }
}
