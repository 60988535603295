/* eslint-disable no-param-reassign */
import { faCheck, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import React from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { httpReportHeader } from 'services/http.report-header';
import PubSubBinder from 'views/individual-reports/PublishSubscribeBinder';
import toast from 'CustomToast';
import ReportHeaderBuilder, { IReportHeaderSetting, IReportHeaderSettingWithHousekeeping } from './ReportHeaderBuilder';
import ReportHeaderFieldsCardView from './ReportHeaderFieldsCardView';
import { IActorDetails } from '../../../views/individual-reports/customized-report/CustomizedReportsCollection';

type reportHeaderApplyActions = 'APPLY_ALL' | 'APPLY_SIMILAR' | 'APPLY_SELECTED';

interface IReportHeaderChooserState {
  headerPublisherId: string;
  headersList: IReportHeaderSetting[];
  showHeaderBuilder: boolean;
  previewHeader: IReportHeaderSetting | undefined;
  showHeaderBuilderForEdit: boolean;
  showEditButton: boolean;
}

interface IReportHeaderChooserProps {
  applyToSelected? : (reportHeaderId:string) => void;
  reportPublisherId?: string;
  report?: IActorDetails;
  onHide?: () => void;
  applyActions?: { [key in reportHeaderApplyActions]: boolean };
}
class ReportHeaderChooser extends React.Component<IReportHeaderChooserProps, IReportHeaderChooserState> {
  private pubSubBinder = PubSubBinder();

  constructor(props:any) {
    super(props);
    this.state = {
      headersList: [],
      headerPublisherId: '',
      showHeaderBuilder: false,
      previewHeader: undefined,
      showHeaderBuilderForEdit: false,
      showEditButton: false,
    };
  }

  componentDidMount() {
    let { headerPublisherId } = this.state;
    headerPublisherId = this.pubSubBinder.RegisterActor(
      'REPORT_HEADER_CHOOSER',
      this.reportHeaderSubscriptionListener,
    );
    this.setState({ headerPublisherId }, this.populateReportHeadersList);
  }

  reportHeaderSubscriptionListener = () => {

  };

  cellRender = (e:any) => (
    <div className="d-flex flex-row justify-content-between h15 mr5 mt0 ml5 mb0">
      <div>
        <p>
          {e.data.name}
        </p>
      </div>
      <div>
        {e.data.defaultHeader && <Badge className="ml20" variant="primary">Default</Badge>}
      </div>
    </div>
  );

  populateReportHeadersList = () => {
    let { previewHeader } = this.state;

    httpReportHeader.getAll().then((headers: IReportHeaderSettingWithHousekeeping[]) => {
      const headersList = headers.map((header) => {
        const tempHeader = header.headerSettings;
        tempHeader.id = header.id;
        tempHeader.defaultHeader = header.defaultHeader;
        return tempHeader;
      });

      const defaultHeader = headersList.find((header) => header.defaultHeader);
      if (defaultHeader) {
        previewHeader = defaultHeader;
      } else {
        previewHeader = headersList.find((header) => header.id === previewHeader?.id);
      }

      this.setState({ headersList, previewHeader });
    });
  };

  toggleShowHeaderBuilderForEdit = () => {
    const { showHeaderBuilderForEdit } = this.state;
    this.setState({
      showHeaderBuilderForEdit: !showHeaderBuilderForEdit,
    }, () => {
      if (showHeaderBuilderForEdit) {
        this.populateReportHeadersList();
      }
    });
  };

  toggleShowHeaderBuilder = () => {
    const { showHeaderBuilder } = this.state;
    this.setState({ showHeaderBuilder: !showHeaderBuilder });
  };

  getBreadCrumbs = () => {
    const { showHeaderBuilder, showHeaderBuilderForEdit } = this.state;
    if (!showHeaderBuilder && !showHeaderBuilderForEdit) {
      return <></>;
    }
    return (
      <div className="p10 background-color-light mb10">
        <button
          onClick={showHeaderBuilder ? this.toggleShowHeaderBuilder : this.toggleShowHeaderBuilderForEdit}
          type="button"
          className="p0 border-none background-color-light text-underlined"
        >
          Report Header Chooser
        </button>
        <span> \ </span>
        <button
          type="button"
          className="p0 border-none background-color-light color-primary"
        >
          Report Header Builder
        </button>
      </div>
    );
  };

  componentWillUnmount() {
    const { headerPublisherId } = this.state;
    this.pubSubBinder.DisposeActor(headerPublisherId);
  }

  getApplyActions = () => {
    const {
      onHide, applyActions, applyToSelected,
    } = this.props;
    const {
      previewHeader, headerPublisherId, showEditButton,
    } = this.state;

    const actions : any[] = [];

    if (previewHeader && applyActions) {
      if (applyActions.APPLY_ALL) {
        actions.push(
          <Button
            variant="success"
            size="sm"
            onClick={() => {
              toast.success('Applied report header to all reports successfully!');
              this.pubSubBinder.BroadcastEvent(headerPublisherId, 'ALL_REPORTS', 'APPLY_REPORT_HEADER', { reportHeaderId: previewHeader.id! });
              if (onHide) {
                onHide();
              }
            }}
          >
            <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
            Apply to All
          </Button>,
        );
      }
      if (applyActions.APPLY_SELECTED) {
        actions.push(
          <Button
            variant="success"
            className="ml10"
            size="sm"
            onClick={() => {
              if (applyToSelected) {
                toast.success('Applied report header to selected report successfully!');
                applyToSelected(previewHeader.id!);
                if (onHide) {
                  onHide();
                }
              }
              // if (report) {
              //   console.log('Report Details', report, reportPublisherId);
              //   this.pubSubBinder.BroadcastEvent(headerPublisherId, [report?.actor], 'APPLY_REPORT_HEADER_TO_SELECTED', { reportHeaderId: previewHeader.id!, subscriberId: reportPublisherId });
              //   if (onHide) {
              //     onHide();
              //   }
              // }
            }}
          >
            <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
            Apply to Selected
          </Button>,
        );
      }
      if (applyActions.APPLY_SIMILAR) {
        actions.push(
          <Button
            variant="success"
            className="ml10"
            size="sm"
            onClick={() => undefined}
            disabled
          >
            <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
            Apply to Similar
          </Button>,
        );
      }
      if (showEditButton) {
        actions.push(
          <Button
            className="ml10"
            variant="primary"
            size="sm"
            onClick={this.toggleShowHeaderBuilderForEdit}
          >
            <FontAwesomeIcon className="mr10" size="sm" icon={faEdit} />
            Edit
          </Button>,
        );
      }
    }

    return <div>{actions}</div>;
  };

  showEditButton = () => {
    this.setState({ showEditButton: true });
  };

  render() {
    const {
      headersList, showHeaderBuilder, previewHeader, showHeaderBuilderForEdit,
    } = this.state;

    return (
      <>
        {this.getBreadCrumbs()}
        {!showHeaderBuilder && !showHeaderBuilderForEdit && (
          <div className="">
            <Row>
              <Col lg={4}>
                <div className="h-100 background-color-light p10 border-all">
                  <div className="d-flex align-items-center justify-content-between mb10">
                    <h5>Report Header Chooser</h5>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => this.toggleShowHeaderBuilder()}
                    >
                      <FontAwesomeIcon className="mr10" size="sm" icon={faPlus} />
                      Create
                    </Button>
                  </div>
                  <CustomizedDataGrid
                    tableData={headersList}
                    // height="100%"
                    keyExpr="id"
                    showFilterRow
                    showAdvancedFilters={false}
                    selectionMode="single"
                    showGroupPanel={false}
                    enableColumnChooser={false}
                    selectedRowKeys={previewHeader !== undefined ? [previewHeader.id] : []}
                    onRowSelection={(e: any) => {
                      if (e.selectedRowKeys.length > 0) {
                        this.setState({ previewHeader: e.selectedRowsData[0] });
                        this.showEditButton();
                      }
                    }}
                    fields={[
                      {
                        caption: 'Headers',
                        dataField: 'name',
                        dataType: 'string',
                        cellRender: this.cellRender,
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="background-color-light p10 border-all">
                  <div className="d-flex align-items-center justify-content-between mb20">
                    <h5>Details</h5>
                    {this.getApplyActions()}
                  </div>
                  {
                    previewHeader ? (
                      <ReportHeaderFieldsCardView
                        isReadOnly
                        reportHeaderSettings={previewHeader}
                        reportHeaderSettingsFields={previewHeader.fields}
                      />
                    ) : <p className="color-disabled-color">Please select a header to see details</p>
                  }
                </div>
              </Col>
            </Row>
          </div>
        )}
        {(showHeaderBuilder || showHeaderBuilderForEdit)
          && (
            <ReportHeaderBuilder
              onSave={this.populateReportHeadersList}
              onHide={showHeaderBuilder ? this.toggleShowHeaderBuilder : this.toggleShowHeaderBuilderForEdit}
              reportHeaderSettingsProps={showHeaderBuilderForEdit ? previewHeader : undefined}
            />
          )}
      </>
    );
  }
}

export default ReportHeaderChooser;
