import { v4 as uuidv4 } from 'uuid';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import CustomizedRawDataGrid from 'components/reports/v2.0/raw-data-grid/CustomizedRawDataGrid';
import SelectionCriteriaButton from 'components/selection-criteria/SelectionCriteriaButton';
import { ISelectionCritereaWidget } from 'components/selection-criteria/SelectionCriteriaTypes';
import { ScrollView } from 'devextreme-react';
import {
  IExportDataOptionsDto,
  IExpressionsList, IFilterRawDataDTO, IGroupingSortingList, ISelectionCriteriaData, ISelectionCriteriaReturnValue,
} from 'interfaces';
import React from 'react';
import {
  Container, Row, Col, Spinner,
} from 'react-bootstrap';
import toast from 'CustomToast';
import { httpSCW } from 'services/http.scw';
import GeneralUtils, { ReportTypeConstants as ReportType } from 'GeneralUtils';
import PubSubBinder, { ActionType } from '../PublishSubscribeBinder';

class IndividualRawDataGrid extends React.Component<any, any> {
  private selectionStore : ISelectionCriteriaReturnValue;

  private groupingSortingListStore : IGroupingSortingList;

  private reportSessionId: string = uuidv4();

  private pubSubBinder = PubSubBinder();

  private publisherId: string;

  constructor(props:any) {
    super(props);
    this.state = {
      defaultSelectionCriteriaData: [],
      revokeReportGeneration: false,
      isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING,
    };
    this.selectionStore = {
      advancedFilter: '',
      expressions: [],
      selections: [],
      testParameterConditions: {},
    };
    this.groupingSortingListStore = {
      grouping: [],
      sorting: [],
    };
    this.publisherId = this.pubSubBinder.RegisterActor(
      'GENERAL_ACTION',
      this.hideDataExportSpinner,
    );
  }

  componentDidMount() {
    const [contextData, defaultSelectionCriteriaData, contextSessionId] = GeneralUtils.getGlobalVars();
    this.reportSessionId = contextSessionId;
    this.selectionStore = contextData || null;
    this.setState({
      isDataExporting: GeneralUtils.Constants.IS_DATA_EXPORTING,
      defaultSelectionCriteriaData,
    });
  }

  getSelectionCriteriaData = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    const defaultSelectionCriteriaData : ISelectionCriteriaData[] = [];
    selectionCriteriaData.selections.forEach((selection) => {
      const currentSelection = {
        entityType: selection.entityType,
        isVisibiltyDefault: selection.isVisibiltyDefault,
        visibleColumns: selection.visibleColumns,
        visibleColumnsValues: selection.visibleColumnsValues,
        values: selection.values.map((item) => (item.id)),
      };
      defaultSelectionCriteriaData.push(currentSelection);
    });

    this.selectionStore = selectionCriteriaData;
    GeneralUtils.setGlobalVars(GeneralUtils.Constants.SCW_CONTEXT, selectionCriteriaData);
    GeneralUtils.setGlobalVars(GeneralUtils.Constants.SCW_SELECTIONS, defaultSelectionCriteriaData);

    this.setState({ defaultSelectionCriteriaData }, () => {
      const params : IFilterRawDataDTO = this.prepareParamsForSaveInMemory();

      this.setState({ revokeReportGeneration: true }, () => {
        httpSCW.saveInMemory(params).then(() => {
          this.setState({ revokeReportGeneration: false });
        }).catch(() => {
          this.setState({ revokeReportGeneration: false });
          toast.error('Unable to retrieve data');
        });
      });
    });
  };

  prepareParamsForSaveInMemory = () : IFilterRawDataDTO => {
    const filters : IExpressionsList[] = [];
    this.selectionStore.selections.forEach((selection) => {
      selection.values.forEach((value) => {
        filters.push({
          columnName: selection.columnName!,
          controlType: selection.controlType,
          dataType: typeof (value.id),
          groupConditionOn: 'OR',
          operationName: '=',
          queryText: value.id,
        });
      });
    });

    const TestParameterIds = filters.filter((x) => x.columnName === 'test_parameter_id').map((x) => (x.queryText));

    const params :IFilterRawDataDTO = {
      Skip: 0,
      Take: 0,
      RequireTotalCount: false,
      ReportSessionId: this.reportSessionId,
      Filter: JSON.stringify(filters),
      Sort: '',
      AdvancedFilter: '',
      TestParameterIds,
      GridRenderingOptions: [],
      Grouping: [],
      Sorting: [],
      TestParameterConditions: {},
      GridTypeEnum: 'UNPIVOTED',
    };

    return params;
  };

  parseFilter = () => {
    const filtersList : IExpressionsList[] = [];
    this.selectionStore.selections.forEach((selection) => {
      selection.values.forEach((value) => {
        filtersList.push({
          columnName: selection.columnName !== undefined ? selection.columnName : '',
          operationName: '=',
          queryText: value.id,
          controlType: selection.controlType,
          dataType: typeof (value.id),
          groupConditionOn: 'OR',
        });
      });
    });

    return filtersList;
  };

  hideDataExportSpinner = (action: ActionType) => {
    if (action === 'HIDE_DATA_EXPORT_SPINNER'){
      this.setState({ isDataExporting: false })
    }
  }

  invokeDataExportOperation = async (options: IExportDataOptionsDto) => {
    GeneralUtils.Constants.IS_DATA_EXPORTING = true;
    this.setState({ isDataExporting: true });
    await GeneralUtils.exportDataAsFile(options, this.publisherId, this.pubSubBinder.BroadcastEvent);
    this.setState({ isDataExporting: false });
  }

  getDataGrid = () => {
    const {
      revokeReportGeneration,
    } = this.state;
    if (this.selectionStore.selections.length === 0 || this.selectionStore.selections.filter((x: any) => x.values.length > 0).length === 0) {
      return (
        <div className="d-flex align-items-center justify-content-center p20">
          <div>Please select some selection criteria to continue</div>
        </div>
      );
    }
    if (this.selectionStore.selections.length > 0 && !revokeReportGeneration) {
      return (
        <Row className="p10">
          <Col>
            <div className="w-100">
              <CustomizedRawDataGrid
                title="Data Extraction - Raw Data Grid"
                viewAs={ReportType.RAW_DATA_GRID}
                height={(window.innerHeight - 220).toString()}
                gridRenderingOptions={[]}
                className="p20"
                reportSessionId={this.reportSessionId}
                setRawDataGridInstanceHandler={() => undefined}
                powerviewSessionId="individual_bin_histogram_report"
                id="individual_bin_histogram_report"
                testParameterConditions={this.selectionStore.testParameterConditions}
                groupingSortingListStore={this.groupingSortingListStore}
                parseFilter={this.parseFilter}
                selectionStore={this.selectionStore}
                aggredateFunction={null}
                config={{}}
                filterData={false}
                selectedBinPlusDefId=""
                isSoftBin
                invokeDataExportOperation={this.invokeDataExportOperation}
              />
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <div className="d-flex align-items-center justify-content-center p20">
        <Spinner animation="border" />
        <div className="p10">Working on it</div>
      </div>
    );
  };

  render() {
    const {
      defaultSelectionCriteriaData, isDataExporting,
    } = this.state;

    const selectionCriteriaControls : ISelectionCritereaWidget[] = [
      {
        controlType: 'Facility',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Work Center',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Device',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Test Program',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Test Program Revision',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Lot',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Wafer',
        columnWeight: 3,
        isVisible: true,
      },
      {
        controlType: 'Test Parameter',
        columnWeight: 3,
        isVisible: true,
        highlightOtherGrids: false,
      },
    ];
    return (
      <>
        <TopbarNav
          title="Data Extraction"
          items={[]}
          showAvatar={false}
          showNotifications={false}
          className=" w-100"
        />
        <ScrollView
          height="100%"
          useNative
          showScrollbar="always"
          scrollByThumb
          reachBottomText=""
        >
          <Container fluid className="overflow-visible">
            <Row>
              <Col lg={isDataExporting ? 11 : 12}>
                <div className={`ml10 mt10 ${isDataExporting ? '' : 'mr10'} border-radius-4`}>
                  <SelectionCriteriaButton
                    showByDefault={false}
                    advancedFilter=""
                    getSelectionCriteriaData={this.getSelectionCriteriaData}
                    defaultSelection={defaultSelectionCriteriaData}
                    widgetsList={selectionCriteriaControls}
                    checkUsageLimits
                  />
                </div>
              </Col>
              {isDataExporting
              && (
                <Col lg={1}>
                  <div className="mt10 pl5 mr10 d-flex align-items-center background-color-warning color-light border-radius-4">
                    <Spinner className="mr10" size="sm" animation="border" variant="clear" />
                    <p className="mt13">Exporting Data...</p>
                  </div>
                </Col>
              )}
            </Row>
            {
              this.getDataGrid()
            }
          </Container>
        </ScrollView>

      </>
    );
  }
}

export default IndividualRawDataGrid;
