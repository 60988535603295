/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-use-before-define */
import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import {
  IPolicyStep, ISelectionCriteriaReturnValue, ISelectionsObject, Policy, PolicySearchCriteria, ILoaderSchedulerPolicyStepSettings,
} from 'interfaces';
import { httpService } from 'services/http.service';
import toast from 'CustomToast';
import SelectionCriteriaButton from 'components/selection-criteria/SelectionCriteriaButton';
import { Redirect } from 'react-router-dom';
import GeneralUtils from 'GeneralUtils';
import { emptyDefaultSelectionCriteria, IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';
import EntityHeader from '../../wrapped-component/entity-header/EntityHeader';
import PolicyStepList from './PolicyStepList';
import AppConstants from '../../../constants.js';
import { RuleTestParameter } from '../policy-step/PAT/PATInterfaces';
import _ from 'lodash';

interface IPolicyBlockProps {
  id?: string;
  visibleColumnsJsonObj: { [entityName: string]: string },
}

interface IPolicyBlockState {
  policy: Policy;
  redirect: boolean;
  isInSavingState: boolean;
}

class PolicyBlock extends React.Component<IPolicyBlockProps, IPolicyBlockState> {
  private policyStore: Policy;

  private BinGroupType: any = {
    HARD_BIN: 'Hardbin',
    SOFT_BIN: 'Softbin',
    NA: 'NA',
  };

  private controlTypeDict: any = {
    Facility: 'FACILITY',
    Workcenter: 'WORK_CENTER',
    Device: 'DEVICE',
    TestProgram: 'TEST_PROGRAM',
    Lot: 'LOT',
    Wafer: 'WAFER',
    Testparameter: 'TEST_PARAMETER',
    Testprogramrevision: 'TEST_PROGRAM_REVISION',
  };

  private columnNameDict: any = {
    Facility: 'facility_id',
    Workcenter: 'work_center_id',
    Device: 'device_id',
    TestProgram: 'test_program_id',
    Lot: 'lot_id',
    Wafer: 'wafer_id',
    Testparameter: 'test_parameter_id',
    Testprogramrevision: 'test_program_revision_id',
  };

  constructor(props: IPolicyBlockProps) {
    super(props);
    const { visibleColumnsJsonObj } = this.props;
    this.policyStore = {
      Owner: '',
      Id: '',
      Name: '',
      Version: '',
      Purpose: '',
      Privacy: 'PUBLIC',
      State: '',
      SearchCriteriaExpressions: '',
      AdvancedSearchExpressions: '',
      Type: 'Service',
      CreatedBy: {
        Id: '',
        FirstName: '',
        LastName: '',
      },
      UpdatedBy: {
        Id: '',
        FirstName: '',
        LastName: '',
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      PolicySteps: [],
      SearchCriteria: [],
      Schedule: undefined as unknown as ILoaderSchedulerPolicyStepSettings,
      defaultSelections: _.cloneDeep(emptyDefaultSelectionCriteria),
      visibleColumnsJson: Object.keys(visibleColumnsJsonObj).length === 0 ? this.getVisibleColumnsFromLocalStorage() : visibleColumnsJsonObj,
    };
    this.state = {
      policy: this.policyStore,
      redirect: false,
      isInSavingState: false,
    };
  }

  componentDidMount() {
    this.getPolicy();
  }

  componentDidUpdate(prevProps: any) {
    const { visibleColumnsJsonObj } = this.props;
    const { policy } = this.state;
    if (JSON.stringify(prevProps.visibleColumnsJsonObj) !== JSON.stringify(visibleColumnsJsonObj) && policy) {
      const newPolicy = policy;
      newPolicy.visibleColumnsJson = visibleColumnsJsonObj;
      this.setState({ policy: newPolicy });
    }
  }


  getVisibleColumnsFromLocalStorage = () => {
    const response : any = {};
    Object.keys(this.controlTypeDict).forEach((key) => {
      const entityKeyForLocalStorage = `${this.controlTypeDict[key]}_SCW_COLUMNS_DATA`;
      const retrievedColumnsList = localStorage.getItem(entityKeyForLocalStorage);
      if (retrievedColumnsList !== null){
        response[entityKeyForLocalStorage] = JSON.parse(retrievedColumnsList);
      }
    });
    return response;
  }

  mapPatRuleSet = (patRuleSet: any) => {
    patRuleSet.forEach((ptrs: any) => {
      ptrs.patRules.forEach((patRule: any) => {
        // eslint-disable-next-line no-param-reassign
        patRule.testParameters = patRule.testParameters.map((rtp: any): RuleTestParameter => {
          return {
            // alias: rtp.alias,
            id: rtp.id,
            sequence: rtp.sequence,
            tpId: rtp.testParameter.id,
            binPlusDefinitionId: !rtp.binPlusDefinition ? null : rtp.binPlusDefinition.id,
            binPlusTableId: !rtp.binPlusDefinition ? null : rtp.binPlusDefinition.binPlusTable.id,
            highKLimit: rtp.highKLimit,
            lowKLimit: rtp.lowKLimit,
            seedHighLimit: rtp.seedHighLimit,
            seedLowLimit: rtp.seedLowLimit,
            binPlusDefinitionColor: !rtp.binPlusDefinition ? '' : rtp.binPlusDefinition.color,
            binPlusDefinitionFlag: !rtp.binPlusDefinition ? '' : rtp.binPlusDefinition.passFail,
            binPlusDefinitionName: !rtp.binPlusDefinition ? '' : rtp.binPlusDefinition.name,
            binPlusDefinitionNumber: !rtp.binPlusDefinition ? '' : rtp.binPlusDefinition.number,
            siteNumber: rtp.siteNumber,
            testParameterHighCtrlLimit: rtp.testParameter.highCtrlLimit,
            testParameterLowCtrlLimit: rtp.testParameter.lowCtrlLimit,
            testParameterHighSpecLimit: rtp.testParameter.highSpecLimit,
            testParameterLowSpecLimit: rtp.testParameter.lowSpecLimit,
            testParameterName: rtp.testParameter.name,
            testParameterNumber: rtp.testParameter.number,
            inputUom: '',
            binPlusGroupType: this.BinGroupType[rtp.binGroupType],
          };
        });
      });
    });
    return patRuleSet;
  };

  callbackToUpdateVisibleColumnsForPolicy = (updatedVisibleColumnsForPolicy : { [entityName: string]: string }) => {
    // update here
    this.policyStore.visibleColumnsJson = updatedVisibleColumnsForPolicy;
  }

  getPolicy = () => {
    const { id } = this.props;
    if (id !== '') {
      httpService.getPolicy(id!).then((data:any) => {
        if (Object.keys(data).length !== 0) {
          const { policy } = this.state;
          policy.Id = data.id;
          policy.Name = data.name;
          policy.Version = data.version;
          policy.Purpose = data.purpose;
          policy.Owner = data.owner;
          policy.State = data.state;
          policy.CreatedBy = data.createdBy;
          policy.Privacy = data.privacy;
          policy.SearchCriteriaExpressions = data.searchCriteriaExpressions;
          policy.AdvancedSearchExpressions = data.advancedSearchExpressions;
          policy.SearchCriteria = data.searchCriteria.length > 0 ? data.searchCriteria
            .map((item: PolicySearchCriteria) => {
              return {
                columnName: this.columnNameDict[item.type],
                controlType: this.controlTypeDict[item.type],
                id: item.id,
                name: '',
                type: item.type,
              };
            }) : [];
          policy.Type = data.type;
          if (data.policySteps !== undefined) {
            if (data.policySteps.length > 0) {
              data.policySteps.forEach((item: any) => {
                const step: IPolicyStep = {
                  Id: item.id,
                  OutputReference: item.outputReference,
                  IsNotificationEnabled: item.isNotificationEnabled === true,
                  CreatedBy: {
                    Id: item.createdBy?.id, // FIX: REMOVE QUESTION MARKS
                    FirstName: item.createdBy?.firstName, // FIX: REMOVE QUESTION MARKS
                    LastName: item.createdBy?.lastName, // FIX: REMOVE QUESTION MARKS
                  },
                  UpdatedBy: {
                    Id: item.updatedBy?.id, // FIX: REMOVE QUESTION MARKS
                    FirstName: item.updatedBy?.firstName, // FIX: REMOVE QUESTION MARKS
                    LastName: item.updatedBy?.lastName, // FIX: REMOVE QUESTION MARKS
                  },
                  CreatedOn: new Date(item.createdOn),
                  UpdatedOn: new Date(item.updatedOn),
                  Type: item.type.toString(),
                  Sequence: item.sequence,
                  hocKey: item.id,
                  Rules: item.rules,
                  isOpen: false,
                  // eslint-disable-next-line no-nested-ternary
                  StepSettings: item.type === 'PAT' ? {
                    patRuleSet: this.mapPatRuleSet(item.stepSettings.patRuleSet),
                    preConditions: [],
                    postConditions: [],
                    generalSettings: item.stepSettings.generalSettings,
                    patTestParameters: item.stepSettings.patTestParameters,
                  } : item.stepSettings,
                  stepOwner: item.stepOwner,
                  additionalEmail: item.additionalEmail,
                  expressions: item.expressions,
                };
                policy.PolicySteps.push(step);
              });
            }
          }
          policy.defaultSelections = data.defaultSelections;
          // here
          policy.visibleColumnsJson = JSON.parse(data.visibleColumnsJson);
          this.setState({ policy });
        }
      });
    }
  };
  // getPolicy = () => {
  //   const { id } = this.props;
  //   if (id !== '') {
  //     httpService.getPolicy(id!).then((data:any) => {
  //       if (data !== {}) {
  //         const { policy } = this.state;
  //         policy.Id = data.id;
  //         policy.Name = data.name;
  //         policy.Version = data.version;
  //         policy.Purpose = data.purpose;
  //         policy.Owner = data.owner;
  //         policy.State = data.state;
  //         policy.CreatedBy = data.createdBy;
  //         policy.Privacy = data.privacy;
  //         policy.SearchCriteriaExpressions = data.searchCriteriaExpressions;
  //         policy.AdvancedSearchExpressions = data.advancedSearchExpressions;
  //         policy.SearchCriteria = data.searchCriteria.length > 0 ? data.searchCriteria
  //           .map((item: PolicySearchCriteria) => {
  //             return {
  //               columnName: this.columnNameDict[item.type],
  //               controlType: this.controlTypeDict[item.type],
  //               id: item.id,
  //               name: '',
  //               type: item.type,
  //             };
  //           }) : [];
  //         policy.Type = data.type;
  //         if (data.policySteps !== undefined) {
  //           if (data.policySteps.length > 0) {
  //             data.policySteps.forEach((item: any) => {
  //               const step: IPolicyStep = {
  //                 Id: item.id,
  //                 OutputReference: item.outputReference,
  //                 IsNotificationEnabled: item.isNotificationEnabled === true,
  //                 CreatedBy: {
  //                   Id: item.createdBy?.id, // FIX: REMOVE QUESTION MARKS
  //                   FirstName: item.createdBy?.firstName, // FIX: REMOVE QUESTION MARKS
  //                   LastName: item.createdBy?.lastName, // FIX: REMOVE QUESTION MARKS
  //                 },
  //                 UpdatedBy: {
  //                   Id: item.updatedBy?.id, // FIX: REMOVE QUESTION MARKS
  //                   FirstName: item.updatedBy?.firstName, // FIX: REMOVE QUESTION MARKS
  //                   LastName: item.updatedBy?.lastName, // FIX: REMOVE QUESTION MARKS
  //                 },
  //                 CreatedOn: new Date(item.createdOn),
  //                 UpdatedOn: new Date(item.updatedOn),
  //                 Type: item.type.toString(),
  //                 Sequence: item.sequence,
  //                 hocKey: item.id,
  //                 Rules: item.rules,
  //                 isOpen: false,
  //                 // eslint-disable-next-line no-nested-ternary
  //                 StepSettings: item.type === 'SPC' ? {
  //                   spcRules: item.stepSettings.spcRules, spcParameters: item.stepSettings.spcParameters, spcReports: item.stepSettings.spcReports, preConditions: [], postConditions: [],
  //                 } : item.type === 'AMG' ? {
  //                   fileOutputs: item.stepSettings.fileOutputs, preConditions: [], postConditions: [],
  //                 } : {
  //                   patRuleSet: this.mapPatRuleSet(item.stepSettings.patRuleSet),
  //                   preConditions: [],
  //                   postConditions: [],
  //                   generalSettings: item.stepSettings.generalSettings,
  //                   patTestParameters: item.stepSettings.patTestParameters,
  //                 },
  //                 stepOwner: item.stepOwner,
  //                 additionalEmail: item.additionalEmail,
  //                 expressions: item.expressions,
  //               };
  //             });// : [];
  //           policy.Type = data.type;
  //           if (data.policySteps !== undefined) {
  //             if (data.policySteps.length > 0) {
  //               data.policySteps.forEach((item: any) => {
  //                 const step: IPolicyStep = {
  //                   Id: item.id,
  //                   OutputReference: item.outputReference,
  //                   IsNotificationEnabled: item.isNotificationEnabled === true,
  //                   CreatedBy: {
  //                     Id: item.createdBy?.id, // FIX: REMOVE QUESTION MARKS
  //                     FirstName: item.createdBy?.firstName, // FIX: REMOVE QUESTION MARKS
  //                     LastName: item.createdBy?.lastName, // FIX: REMOVE QUESTION MARKS
  //                   },
  //                   UpdatedBy: {
  //                     Id: item.updatedBy?.id, // FIX: REMOVE QUESTION MARKS
  //                     FirstName: item.updatedBy?.firstName, // FIX: REMOVE QUESTION MARKS
  //                     LastName: item.updatedBy?.lastName, // FIX: REMOVE QUESTION MARKS
  //                   },
  //                   CreatedOn: new Date(item.createdOn),
  //                   UpdatedOn: new Date(item.updatedOn),
  //                   Type: item.type.toString(),
  //                   Sequence: item.sequence,
  //                   hocKey: item.id,
  //                   Rules: item.rules,
  //                   isOpen: false,
  //                   // eslint-disable-next-line no-nested-ternary
  //                   StepSettings: item.type === 'SPC' ? {
  //                     spcRules: item.stepSettings.spcRules,
  //                     spcParameters: item.stepSettings.spcParameters,
  //                     spcReports: item.stepSettings.spcReports,
  //                     preConditions: [],
  //                     postConditions: [],
  //                   } : item.type === 'AMG' ? {
  //                     expressions: item.stepSettings.expressions,
  //                     fileOutputs: item.stepSettings.fileOutputs,
  //                     preConditions: [],
  //                     postConditions: [],
  //                   } : {
  //                     patRuleSet: this.mapPatRuleSet(item.stepSettings.patRuleSet),
  //                     preConditions: [],
  //                     postConditions: [],
  //                     generalSettings: item.stepSettings.generalSettings,
  //                     patTestParameters: item.stepSettings.patTestParameters,
  //                   },
  //                   stepOwner: item.stepOwner,
  //                   additionalEmail: item.additionalEmail,
  //                 };
  //                 policy.PolicySteps.push(step);
  //               });
  //             }
  //           }
  //           this.setState({ policy });
  //         }
  //       });
  //   }
  // };

  getPolicyInfoComponentData = (policyInfoData: any) => {
    this.policyStore.Name = policyInfoData.name;
    this.policyStore.Owner = policyInfoData.owner;
    this.policyStore.Version = policyInfoData.version;
    this.policyStore.Privacy = policyInfoData.privacy;
    this.policyStore.Purpose = policyInfoData.purpose;
    this.policyStore.State = policyInfoData.state;
  };

  getSelectionCriteriaComponentData = (selectionCriteriaData: ISelectionCriteriaReturnValue) => {
    this.policyStore.SearchCriteria = [];
    const { defaultSelectionCriteria } = selectionCriteriaData;
    this.policyStore.defaultSelections = [];
    selectionCriteriaData.selections.forEach((item, index) => {
      this.policyStore.SearchCriteria.push(
        ...this.populateSelectedCriteria(
          item.entityType,
          item.controlType,
          item.values.map((selectionsItem: ISelectionsObject) => (selectionsItem.id)),
          true,
          item.columnName,
        ),
      );
      this.policyStore.defaultSelections?.push({
        entityType: item.entityType,
        values: item.values.map((value: any) => value.id),
        visibleColumns: defaultSelectionCriteria ? defaultSelectionCriteria[index].visibleColumns : [],
        visibleColumnsValues: defaultSelectionCriteria ? defaultSelectionCriteria[index].visibleColumnsValues : [],
        isVisibiltyDefault: defaultSelectionCriteria ? defaultSelectionCriteria[index].isVisibiltyDefault : true,
      } as IDefaultSelectionCriteria)
    });
    const expressions: any = {};
    selectionCriteriaData.expressions.forEach((item) => {
      expressions[item.entityType] = item.values;
    });
    this.policyStore.SearchCriteriaExpressions = JSON.stringify(expressions);
    this.policyStore.AdvancedSearchExpressions = selectionCriteriaData.advancedFilter;
    this.setState({ policy: this.policyStore });
  };

  populateSelectedCriteria = (selectionType: string, controlType: string, selectionArray: string[], addNoneValues: boolean, columnName?: string) => {
    let currentSelection = [];
    if (selectionArray.length > 0) {
      currentSelection = selectionArray.map((item: string) => {
        return {
          id: item,
          type: selectionType,
          name: '',
          controlType,
          columnName,
        };
      });
    } else if (addNoneValues !== undefined && addNoneValues) {
      currentSelection.push({
        id: 'None',
        type: selectionType,
        name: 'None Selected',
        controlType,
        columnName,
      });
    }
    return currentSelection;
  };

  getPolicyStepListComponentData = (steps: IPolicyStep[]) => {
    this.policyStore.PolicySteps = steps;
  };

  saveOrUpdatePolicy = () => {
    if (this.policyStore.PolicySteps.length === 0) {
      toast.warning('Policy must contain at least one stage');
      this.setState({ isInSavingState: false });
      return;
    }
    if (this.policyStore.SearchCriteria.every((entity) => entity.id === 'None') || this.policyStore.SearchCriteria.length === 0){
      toast.warning('Please select some search criteria.');
      this.setState({ isInSavingState: false });
      return;
    }
    if (!this.policyStore.Name || !this.policyStore.Owner || !this.policyStore.Version || !this.policyStore.Purpose) {
      this.setState({ isInSavingState: false });
      return;
    }

    const patSteps = this.policyStore.PolicySteps.filter((step:any) => step.Type === 'PAT');
    if (patSteps.length > 0) {
      for (let index = 0; index < patSteps.length; index++) {
        const element = patSteps[index];
        if (patSteps[index].expressions?.length === 0) {
          toast.error(`Please provide General Settings for PAT step with sequence number ${patSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (patSteps[index].expressions?.find((x: any) => x.outputParameter?.startsWith('ExecutePATRuleSet')) == null) {
          toast.error(`Please specify rule set to be executed in PAT step with sequence number ${patSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (patSteps[index].StepSettings.patRuleSet.length === 0) {
          toast.error(`Please provide Rule Set for PAT step with sequence number ${patSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (patSteps[index].StepSettings.patRuleSet[0].patRuleSetName === '') {
          toast.error('Please provide Rule Set Name for PAT step');
          this.setState({ isInSavingState: false });
          return;
        }
      }
    }

    const amgSteps = this.policyStore.PolicySteps.filter((step:any) => step.Type === 'AMG');
    if (amgSteps.length > 0) {
      for (let index = 0; index < amgSteps.length; index++) {
        if (amgSteps[index].expressions?.length === 0) {
          toast.error(`Please provide expressions for AMG step with sequence number ${amgSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (amgSteps[index].expressions?.find((x: any) => x.outputParameter === 'GenerateAssemblyMaps()') == null) {
          toast.error(`Please specify Run AMG Step condition in AMG step with sequence number ${amgSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (!amgSteps[index].StepSettings.fileOutputs || amgSteps[index].StepSettings.fileOutputs.length === 0) {
          toast.error(`Please provide file output settings for AMG step with sequence number ${amgSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
      }
    }

    const spcSteps = this.policyStore.PolicySteps.filter((step:any) => step.Type === 'SPC');
    if (spcSteps.length > 0) {
      for (let index = 0; index < spcSteps.length; index++) {
        if (spcSteps[index].expressions?.length === 0) {
          toast.error(`Please provide input/output settings for SPC step with sequence number ${spcSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (spcSteps[index].expressions?.find((x: any) => x.outputParameter === 'ProcessStep()') == null) {
          toast.error(`Please specify "Run SPC Step" condition in General Settings of SPC step with sequence number ${spcSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (spcSteps[index].StepSettings.spcRules.length === 0) {
          toast.error(`Please provide SPC Rule in SPC step with sequence number ${spcSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (spcSteps[index].StepSettings.spcParameters.length === 0) {
          toast.error(`Please provide SPC Parameter in SPC step with sequence number ${spcSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
        if (spcSteps[index].StepSettings.spcReports.length === 0) {
          toast.error(`Please provide SPC Reports in SPC step with sequence number ${spcSteps[index].Sequence}`);
          this.setState({ isInSavingState: false });
          return;
        }
      }
    }

    this.setState({ isInSavingState: true }, () => {
      let newPolicy : { [key: string]: any } = {}
        for (const [key, value] of Object.entries(this.policyStore)) {
          newPolicy[key] = (key === 'visibleColumnsJson') ? JSON.stringify(value) : value;
        }
      if (this.policyStore.Id === '') {
        this.policyStore.CreatedBy = { Id: AppConstants.user.Id.toString() };
        httpService.createPolicy(newPolicy)
          .then((policyId: string) => {
            this.policyStore.Id = policyId;
            toast.success('Policy Saved!');
            this.policyStore.SearchCriteria = this.policyStore.SearchCriteria.filter((x) => x.name !== 'None Selected');
            this.setState({
              policy: this.policyStore,
              redirect: true,
            });
          })
          .finally(() => {
            this.setState({ isInSavingState: false });
          });
      } else {
        this.policyStore.UpdatedBy = { Id: AppConstants.user.Id.toString() };
        httpService.updatePolicy(newPolicy)
          .then(() => {
            toast.success('Policy Updated!');
            this.policyStore.SearchCriteria = this.policyStore.SearchCriteria.filter((x) => x.name !== 'None Selected');
            this.setState({
              policy: this.policyStore,
              redirect: true,
            });
          })
          .finally(() => {
            this.setState({ isInSavingState: false });
          });
      }
    });
  };

  mapSelectionCriteriaToDefaultSelection = (SelectionCriteria: any[]) => {
    const entityTypes = [
      'facility',
      'workCenter',
      'device',
      'testProgram',
      'testProgramRevision',
      'lot',
      'wafer',
      'testParameter'];
    return entityTypes.map((entityType: string) => {
      const filteredCriteria = SelectionCriteria.filter(
        (item: any) => item.type.toLowerCase() === entityType.toLowerCase() && item.id !== 'None');
      return {
        entityType,
        isVisibiltyDefault: true,
        visibleColumns: [],
        values: filteredCriteria.map((item: any) => item.id),
        visibleColumnsValues: filteredCriteria.map((item: any) => ({ id: item.id }))
      };
    });
  }

  render() {
    const {
      isInSavingState,
      policy,
      redirect,
    } = this.state;
    return (
      <>
        <TopbarNav
          title="Create Policy"
          items={[]}
          showAvatar={false}
          showNotifications={false}
          className=" w-100"
        />
        {redirect ? <Redirect to="/policy" />
          : null}
        <Container fluid className="mt10 position-relative">
          <Row className="pt0 pl30 pr30">
            <Col lg={12} className="mb20 pl0 pr0">
              <EntityHeader
                isInSavingState={isInSavingState}
                name={policy.Name}
                owner={policy.Owner}
                version={policy.Version}
                privacy={policy.Privacy}
                purpose={policy.Purpose}
                state={policy.State}
                onChange={this.getPolicyInfoComponentData}
                onParentSave={this.saveOrUpdatePolicy}
              />
            </Col>
            <Col lg={12} className="p0">
              <SelectionCriteriaButton
                advancedFilter={policy.AdvancedSearchExpressions}
                getSelectionCriteriaData={this.getSelectionCriteriaComponentData}
                populateDefaultSelection
                visibleColumnsForPolicy={policy.visibleColumnsJson}
                callbackToUpdateVisibleColumnsForPolicy={this.callbackToUpdateVisibleColumnsForPolicy}
                // this condition is for policies which are created before the implementation of data clubbing in selection criteria, and can be removed after updating all the policies
                defaultSelection={policy.defaultSelections && policy.defaultSelections.length !== 0
                  ? policy.defaultSelections
                  : this.mapSelectionCriteriaToDefaultSelection(policy.SearchCriteria)}
                widgetsList={
                  [
                    {
                      controlType: 'Facility',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Work Center',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Device',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Test Program',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Test Program Revision',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Lot',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Wafer',
                      columnWeight: 3,
                      isVisible: true,
                    },
                    {
                      controlType: 'Test Parameter',
                      columnWeight: 3,
                      isVisible: true,
                      highlightOtherGrids: false,
                      minVisibilityCriteria: GeneralUtils.getMinVisibilityCriteria(),
                    },
                  ]
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="p15">
                <PolicyStepList
                  searchCriteria={this.policyStore.SearchCriteria}
                  steps={this.policyStore.PolicySteps}
                  getPolicyStepListComponentData={this.getPolicyStepListComponentData}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default PolicyBlock;
