/* eslint-disable no-unused-vars */
import HttpBase from "./http.base";
import AppConstants from "../constants.js";

class HttpIAM extends HttpBase {
  login = (UserName: string, Password: string): Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/authentication/login`, {
      UserName,
      Password,
    });
  };

  getUserDetails = (userName: string): Promise<any> => {
    return this.GET(
      `${AppConstants.baseURL}/api/userdetail/get-user-details/${userName}`
    );
  };
}

const httpIAM = new HttpIAM();
export { httpIAM };
