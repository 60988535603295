/* eslint-disable max-len */
import TopbarNav from 'components/navigational-component/topbar-nav/TopbarNav';
import RealTimeStatsGraphCard from 'components/template-component/real-time-stats-card/RealTimeStatsGraphCard';
import RealTimeStatsCard from 'components/template-component/real-time-stats-card/RealTimeStatsCard';
import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { faTimes, faExclamation, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import CustomizedTabs from 'components/wrapped-component/customized-tabs/CustomizedTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';

interface IExecutionWorkerDetailsProps {
  id: string;
  className?: string;
  handleClose?: () => void;
}

class ExecutionWorkerDetails extends React.Component<IExecutionWorkerDetailsProps, any> {
  static defaultProps: Partial<IExecutionWorkerDetailsProps> = {
    className: '',
    handleClose: () => undefined,
  };

  constructor(props: IExecutionWorkerDetailsProps) {
    super(props);
    this.populateMemoryStats = this.populateMemoryStats.bind(this);
    this.populateCPUStats = this.populateCPUStats.bind(this);

    this.populateContainerErrors = this.populateContainerErrors.bind(this);
    this.populateContainerWarnings = this.populateContainerWarnings.bind(this);

    const getLogs = () => {
      const logs = [];
      const types = ['Error', 'Warning', 'Verbose', 'Information', 'Debug'];
      const totalLogs = Math.floor(Math.random() * (500 - 100 + 1) + 100);
      for (let i = 0; i < totalLogs; i += 1) {
        logs.push({
          timestamp: (new Date()).toString(),
          message: `Error ${i}`,
          type: types[Math.floor(Math.random() * (3 - 0 + 1) + 0)],
        });
      }
      return logs;
    };

    this.state = {
      containerStats: {
        name: 'Container Name',
        state: '',
        cpuUsed: '',
        memoryUsed: '',
        errorsCount: '',
        warningsCount: '',
        logs: getLogs(),
      },
      memoryUsed: {
        timespan: 'Today',
        value: '29',
        data: {
          x: [1270, 9145, 7568, 7927, 9812, 9550, 108, 6963, 8926, 8002, 380, 9884, 5226, 7529, 3860, 6339, 4770, 4981, 3497, 8730, 6974, 9819, 9206, 1339, 3523, 9244, 6680, 2648, 9555, 4337, 8899, 8244, 5359, 7890, 5566, 4521, 8291, 6188, 4629, 1041, 8438, 8174, 7692, 5111, 7041, 1844, 2781, 9881, 3088, 1476, 508, 115, 1714, 4311, 1846, 6426, 5643, 8182, 659, 3601, 6196, 1759, 2925, 9741, 9944, 9117, 5441, 4722, 762, 627, 4253, 9238, 9679, 7620, 8911, 3602, 2535, 7958, 5523, 2044, 9671, 1453, 3948, 5643, 8242, 9670, 1890, 9192, 2121, 2925, 5685, 9906, 5085, 1702, 9809, 8055, 4191, 9777, 4339, 1105, 3400, 1460, 7501, 3289, 1115, 6131, 2305, 9773, 9160, 7767, 3392, 7220, 1297, 3587],
          y: [176, 485, 119, 394, 91, 455, 184, 116, 195, 47, 150, 380, 443, 258, 136, 15, 240, 403, 383, 488, 373, 35, 154, 117, 192, 282, 168, 41, 272, 148, 68, 180, 451, 266, 318, 277, 195, 110, 210, 462, 105, 267, 415, 266, 75, 236, 262, 173, 44, 171, 461, 433, 35, 408, 140, 297, 307, 388, 299, 78, 23, 313, 84, 19, 334, 304, 404, 112, 396, 107, 247, 381, 121, 168, 221, 368, 179, 355, 239, 143, 209, 254, 259, 312, 220, 191, 186, 61, 457, 459, 53, 75, 297, 184, 179, 451, 334, 467, 193, 179, 216, 496, 165, 107, 403, 84, 435, 69, 468, 315, 276, 138, 152, 152],
        },
      },
      CPUUsed: {
        timespan: 'Today',
        value: '42',
        data: {
          x: [1270, 9145, 7568, 7927, 9812, 9550, 108, 6963, 8926, 8002, 380, 9884, 5226, 7529, 3860, 6339, 4770, 4981, 3497, 8730, 6974, 9819, 9206, 1339, 3523, 9244, 6680, 2648, 9555, 4337, 8899, 8244, 5359, 7890, 5566, 4521, 8291, 6188, 4629, 1041, 8438, 8174, 7692, 5111, 7041, 1844, 2781, 9881, 3088, 1476, 508, 115, 1714, 4311, 1846, 6426, 5643, 8182, 659, 3601, 6196, 1759, 2925, 9741, 9944, 9117, 5441, 4722, 762, 627, 4253, 9238, 9679, 7620, 8911, 3602, 2535, 7958, 5523, 2044, 9671, 1453, 3948, 5643, 8242, 9670, 1890, 9192, 2121, 2925, 5685, 9906, 5085, 1702, 9809, 8055, 4191, 9777, 4339, 1105, 3400, 1460, 7501, 3289, 1115, 6131, 2305, 9773, 9160, 7767, 3392, 7220, 1297, 3587],
          y: [176, 485, 119, 394, 91, 455, 184, 116, 195, 47, 150, 380, 443, 258, 136, 15, 240, 403, 383, 488, 373, 35, 154, 117, 192, 282, 168, 41, 272, 148, 68, 180, 451, 266, 318, 277, 195, 110, 210, 462, 105, 267, 415, 266, 75, 236, 262, 173, 44, 171, 461, 433, 35, 408, 140, 297, 307, 388, 299, 78, 23, 313, 84, 19, 334, 304, 404, 112, 396, 107, 247, 381, 121, 168, 221, 368, 179, 355, 239, 143, 209, 254, 259, 312, 220, 191, 186, 61, 457, 459, 53, 75, 297, 184, 179, 451, 334, 467, 193, 179, 216, 496, 165, 107, 403, 84, 435, 69, 468, 315, 276, 138, 152, 152],
        },
      },
      errorsCount: {
        timespan: '',
        value: '447',
      },
      warningsCount: {
        timespan: '',
        value: '291',
      },
    };
  }

  componentDidMount() {
    this.populateContainerStats();
    this.populateMemoryStats('today');
    this.populateCPUStats('today');
    this.populateContainerErrors('today');
    this.populateContainerWarnings('today');
  }

  componentDidUpdate(prevProps: IExecutionWorkerDetailsProps) {
    const prevId = prevProps.id;
    const { id } = this.props;
    if (prevId !== id) {
      this.populateContainerStats();
      this.populateMemoryStats('today');
      this.populateCPUStats('today');
      this.populateContainerErrors('today');
      this.populateContainerWarnings('today');
    }
  }

  populateMemoryStats = (timespan: string) => {
    const { id } = this.props;
    // httpService.getContainerMemoryStats(id, timespan).then((stats:any) => {
    //   this.setState({ memoryUsed: { timespan, value: stats.value, data: stats.data } });
    // });
  };

  populateCPUStats = (timespan: string) => {
    const { id } = this.props;
    // httpService.getContainerCPUStats(id, timespan).then((stats:any) => {
    //   this.setState({ CPUUsed: { timespan, value: stats.value, data: stats.data } });
    // });
  };

  populateContainerErrors = (timespan: string) => {
    const { id } = this.props;
    // httpService.getContainerErrorsCount(id, timespan).then((count:any) => {
    //   this.setState({ errorsCount: { timespan, value: count } });
    // });
  };

  populateContainerWarnings = (timespan: string) => {
    const { id } = this.props;
    // httpService.getContainerWarningsCount(id, timespan).then((count:any) => {
    //   this.setState({ warningsCount: { timespan, value: count } });
    // });
  };

  populateContainerStats = () => {
    const { id } = this.props;
    // httpService.getContainerStats(id).then((stats:any) => {
    //   let { containerStats } = this.state;
    //   containerStats = {
    //     name: stats.name,
    //     state: stats.state,
    //     /// /////////////////////////
    //     cpuUsed: stats.cpuUsed,
    //     memoryUsed: stats.memoryUsed,
    //     errorsCount: stats.errorsCount,
    //     warningsCount: stats.warningsCount,
    //     /// /////////////////////////
    //     errorsLog: stats.errorsLog,
    //     warningsLog: stats.warningsLog,
    //     otherLog: stats.otherLog,
    //   };

    //   this.setState({ containerStats });
    // });
  };

  render() {
    const {
      className,
      handleClose
    } = this.props;
    const {
      containerStats,
      memoryUsed,
      CPUUsed,
      errorsCount,
      warningsCount,
    } = this.state;

    return (
      <div className={className}>
        <TopbarNav
          items={[]}
          title={containerStats.name}
          showNotifications={false}
          showAvatar={false}
          showShadow={false}
          actions={(
            <div>
              <Button variant="clear" className="mb5 pr0" title="Gracefully Shutdown">
                <FontAwesomeIcon
                  color="orange"
                  icon={faPowerOff}
                />
              </Button>
              <Button variant="clear" className="mb5 pr0" title="Abrupt Shutdown">
                <FontAwesomeIcon
                  color="red"
                  icon={faPowerOff}
                />
              </Button>
            </div>
          )}
          secondaryActions={(
            <Button onClick={handleClose} variant="clear" className="mb5">
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
              />
            </Button>
          )}
        />
        <Container fluid className="p20">
          <Row className="break-top break-bottom">
            <Col md={6} lg={6}>
              {CPUUsed.data !== undefined
                                && (
                                  <RealTimeStatsGraphCard
                                    shadowOnHover={false}
                                    showActions={false}
                                    id=""
                                    selectStatForDetailedPreview={() => undefined}
                                    isExpanded
                                    title="CPU Used"
                                    stat={`${CPUUsed.value}%`}
                                    data={CPUUsed.data}
                                    onTimespanUpdate={(value: string) => {
                                      this.populateCPUStats(value);
                                    }}
                                    selectedTimespan={CPUUsed.timespan}
                                  />
                                )}
            </Col>
            <Col md={6} lg={6}>
              {
                memoryUsed.data !== undefined
                                && (
                                  <RealTimeStatsGraphCard
                                    showActions={false}
                                    shadowOnHover={false}
                                    id=""
                                    selectStatForDetailedPreview={() => undefined}
                                    isExpanded
                                    title="Memory Used"
                                    stat={`${memoryUsed.value}%`}
                                    data={memoryUsed.data}
                                    onTimespanUpdate={(value: string) => {
                                      this.populateMemoryStats(value);
                                    }}
                                    selectedTimespan={memoryUsed.timespan}
                                  />
                                )
              }
            </Col>
          </Row>
          <Row className="p16">
            <Col md={6} lg={6}>
              {
                errorsCount.value !== undefined
                                && (
                                  <RealTimeStatsCard
                                    title="Errors"
                                    stat={errorsCount.value}
                                    showTimePeriodFilter
                                    icon={faTimes}
                                    onTimespanUpdate={(value: string) => {
                                      this.populateContainerErrors(value);
                                    }}
                                    selectedTimespan={errorsCount.timespan}
                                  />
                                )
              }
            </Col>
            <Col md={6} lg={6}>
              {
                warningsCount.value !== undefined
                                && (
                                  <RealTimeStatsCard
                                    title="Warning"
                                    stat={warningsCount.value}
                                    showTimePeriodFilter
                                    icon={faExclamation}
                                    onTimespanUpdate={(value: string) => {
                                      this.populateContainerWarnings(value);
                                    }}
                                    selectedTimespan={warningsCount.timespan}
                                  />
                                )
              }
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end mb20">
            <h6 className="mr10 mb0">
              Log Level
            </h6>
            <div className="border-all w200 rounded">
              <CustomizedDropdown
                variant="clear"
                full
                list={[
                  ['Debug', 'Debug'],
                  ['Information', 'Information'],
                  ['Verbose', 'Verbose'],
                ]}
                selectedValue=""
                onChange={() => undefined}
              />
            </div>
          </div>
          <Row className="m0 background-color-light">
            <Col className="pb40 border-all">
              <CustomizedTabs
                shadow={false}
                tabItems={[
                  {
                    title: 'Errors',
                    eventKey: 'errors',
                    content: (
                      <CustomizedDataGrid
                        selectionMode="single"
                        showFilterRow
                        fields={[
                          {
                            caption: 'Timestamp',
                            dataField: 'timestamp',
                            dataType: 'string',
                            showInfo: false,
                            width: 140,
                          },
                          {
                            caption: 'Error Message',
                            dataField: 'message',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                          },
                          {
                            caption: 'Type',
                            dataField: 'type',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                            viewMode: 'tag',
                          },
                        ]}
                        tableData={containerStats.logs.filter((x: any) => x.type === 'Error')}
                      />
                    ),
                  },
                  {
                    title: 'Warnings',
                    eventKey: 'warnings',
                    content: (
                      <CustomizedDataGrid
                        selectionMode="single"
                        showFilterRow
                        fields={[
                          {
                            caption: 'Timestamp',
                            dataField: 'timestamp',
                            dataType: 'string',
                            showInfo: false,
                            width: 140,
                          },
                          {
                            caption: 'Warning Message',
                            dataField: 'message',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                          },
                          {
                            caption: 'Type',
                            dataField: 'type',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                            viewMode: 'tag',
                          },
                        ]}
                        tableData={containerStats.logs.filter((x: any) => x.type === 'Warning')}
                      />
                    ),
                  },
                  {
                    title: 'Logs',
                    eventKey: 'log',
                    content: (
                      <CustomizedDataGrid
                        selectionMode="single"
                        showFilterRow
                        fields={[
                          {
                            caption: 'Timestamp',
                            dataField: 'timestamp',
                            dataType: 'string',
                            showInfo: false,
                            width: 140,
                          },
                          {
                            caption: 'Message',
                            dataField: 'message',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                          },
                          {
                            caption: 'Type',
                            dataField: 'type',
                            dataType: 'string',
                            showInfo: false,
                            minWidth: 550,
                            viewMode: 'tag',
                          },
                        ]}
                        tableData={containerStats.logs.filter((x: any) => (x.type !== 'Error' && x.type !== 'Warning'))}
                      />
                    ),
                  },
                ]}
                defaultActivityKey="active"
              />
            </Col>
          </Row>

        </Container>
      </div>
    );
  }
}

export default ExecutionWorkerDetails;
