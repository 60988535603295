/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ReportCollectionMapping } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportMapping';
import { CheckBox } from 'devextreme-react';
import _ from 'lodash';
import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import CustomizedTreeView from 'components/wrapped-component/customized-treeview/CustomizedTreeView';

const reportCategories = ['BIN', 'PARAMETRIC', 'MISC'];
interface ICustomizedReportHeaderControlsProps {
  generateReportHandler: any;
  secondaryControls: any[];
  revokeReportGeneration: boolean;
  allowReportsSelection: boolean;
  onUpdateReportsSelection: (selectedReports: any) => void;
  windowType: string;
  selectedStageType: string;
}

interface ISelectedReport {
  [key: string]: { value: string, config: { [key: string]: any } }
}
interface ICustomizedReportHeaderControlsState {
  selectedNodesTreeView: any;
  selectedReports: ISelectedReport;
}

class CustomizedReportHeaderControls extends React.Component<ICustomizedReportHeaderControlsProps, ICustomizedReportHeaderControlsState> {
  constructor(props: ICustomizedReportHeaderControlsProps) {
    super(props);
    this.state = {
      selectedReports: {},
      selectedNodesTreeView: {},
    };
  }

  onChangeReportSelection = (value: boolean, reportType: string) => {
    const { onUpdateReportsSelection } = this.props;
    const { selectedReports } = this.state;
    const newSelectedReports = _.cloneDeep(selectedReports);
    if (value && !(reportType in newSelectedReports)) {
      newSelectedReports[reportType] = { value: reportType.replaceAll('_', ' '), config: {} };
    } else if (!value && reportType in newSelectedReports) {
      delete newSelectedReports[reportType];
    }
    this.setState({ selectedReports: newSelectedReports }, () => {
      onUpdateReportsSelection(Object.keys(newSelectedReports).map((x) => { return { key: x, ...newSelectedReports[x] }; }));
    });
  };

  onChangeReportConfig = (value: boolean, reportType: string, configName: string) => {
    const { onUpdateReportsSelection } = this.props;
    const { selectedReports } = this.state;
    const newSelectedReports = _.cloneDeep(selectedReports);
    if (reportType in newSelectedReports) {
      newSelectedReports[reportType].config[configName] = value;
    }
    this.setState({ selectedReports: newSelectedReports }, () => {
      onUpdateReportsSelection(Object.keys(newSelectedReports).map((x) => { return { key: x, ...newSelectedReports[x] }; }));
    });
  };

  onSelectionOrDeselection(e: any) {
    const { onUpdateReportsSelection } = this.props;
    const currentSelectedNodes = e.component.getSelectedNodes().map((node: any) => node.itemData);
    const { selectedReports } = this.state;
    const newSelectedReports: ISelectedReport = {};
    let existingConfig = {};

    Object.keys(currentSelectedNodes).forEach((key: any) => {
      const reportType = currentSelectedNodes[key];
      if (reportType.text in Object.keys(selectedReports)) {
        existingConfig = selectedReports[reportType.text].config;
      }
      if (!reportCategories.includes(reportType.text)) {
        newSelectedReports[reportType.text] = { value: reportType.text.replaceAll('_', ' '), config: existingConfig };
        existingConfig = {};
      }
    });
    this.setState({ selectedNodesTreeView: currentSelectedNodes, selectedReports: newSelectedReports }, () => {
      onUpdateReportsSelection(Object.keys(newSelectedReports).map((x) => { return { key: x, ...newSelectedReports[x] }; }));
    });
  }

  transformDataForTreeView = (reportTypes: any) => {
    const { selectedNodesTreeView } = this.state;

    return [{
      text: 'ALL REPORTS',
      expanded: true,
      items: reportCategories.map((type: any) => ({
        text: type,
        expanded: true,
        items: reportTypes[type].map((item: any) => ({
          text: item.name,
          expanded: true,
          selected: selectedNodesTreeView.length > 0 ? this.checkIfSelected(item.name) : false,
        })),
      })),
    },
    ];
  };

  checkIfSelected(text: string) {
    const { selectedNodesTreeView } = this.state;
    const node = selectedNodesTreeView.find((n: any) => n.text === text);
    if (node) {
      return node.selected;
    }
    return false;
  }

  renderTreeViewItem = (item: any) => {
    const { selectedReports } = this.state;
    const reportName = item.text.replaceAll('_', ' ');
    return (
      <>
        {reportName === 'BIN WAFER MAP' ? (
          <div className="d-flex align-items-center">
            <p className="mb0">
              {`${reportName}`}
            </p>
            <div className="d-flex align-items-center pl10">
              <CheckBox
                className="position-static d-inline-block"
                disabled={!(item.text in selectedReports)}
                value={(item.text in selectedReports && selectedReports[item.text].config && 'perBin' in selectedReports[item.text].config!) ? selectedReports[item.text].config!.perBin : false}
                onValueChange={(value: boolean) => { this.onChangeReportConfig(value, item.text, 'perBin'); }}
              />
              <p className="pl10 mb0" style={{ color: !(item.text in selectedReports) ? '#CCC' : '#000' }}>SEPARATE PER BIN</p>
            </div>
            <div className="d-flex align-items-center pl10">
              <CheckBox
                className="position-static d-inline-block"
                disabled={!(item.text in selectedReports)}
                value={(item.text in selectedReports && selectedReports[item.text].config && 'overlay' in selectedReports[item.text].config!) ? selectedReports[item.text].config!.overlay : false}
                onValueChange={(value: boolean) => { this.onChangeReportConfig(value, item.text, 'overlay'); }}
              />
              <p className="pl10 mb0" style={{ color: !(item.text in selectedReports) ? '#CCC' : '#000' }}>OVERLAY</p>
            </div>
          </div>
        ) : (
          <div className={`${(reportCategories.includes(item.text) || item.text === 'ALL REPORTS') ? 'font-weight-bold' : ''}`}>
            {`${reportName}`}
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      generateReportHandler,
      secondaryControls,
      revokeReportGeneration,
      allowReportsSelection,
      onUpdateReportsSelection,
      windowType,
      selectedStageType,
    } = this.props;

    const {
      selectedReports,
    } = this.state;

    const reportTypes = _.groupBy(Object.keys(ReportCollectionMapping).filter((x) => !x.includes('_LEGEND')
      && x !== ReportType.RAW_DATA_GRID
      && x !== 'REPORT_HEADER_CHOOSER'
      && x !== 'GENERAL_ACTION'
      && x !== 'POWER_VIEW_PROGRESS_BAR')
      .map((x) => ({
        name: x,
        type: (ReportCollectionMapping as any)[x].type,
      })), 'type');
    reportTypes.MISC = windowType === 'VIEW_POLICY_OUTPUT' && selectedStageType === 'SPC' ? reportTypes.MISC : reportTypes.MISC.filter((x: any) => x.name !== ReportType.SPC_TREND);
    const generateButtonText = (Object.keys(selectedReports).length === 0 && allowReportsSelection) ? 'Select report(s) to generate' : 'Generate';
    const dataForTreeView = this.transformDataForTreeView(reportTypes);

    return (
      <div className={`
        mt10
        ml10
        mr10
        p10
        pt16
        background-color-light
        border-all
        border-radius-4
        d-flex
        justify-content-start ${!allowReportsSelection ? 'flex-column flex-column-reverse h200' : 'h370'}`}
      >
        {/* Col 1 */}
        <div className={allowReportsSelection ? 'pr10 mr20 border-right flex-30' : ''}>
          <h5>Controls</h5>
          <div className={!allowReportsSelection ? 'd-flex align-items-center w-100' : ''}>
            {secondaryControls.map((jsxElement: any) => (
              <div className="mr10 mb10 d-inline-block w-100">
                {jsxElement}
              </div>
            ))}
          </div>
        </div>
        {/* Col 1 */}
        <div className="flex-70">
          <div>
            <div className={`d-flex align-items-center mb10 ${allowReportsSelection ? 'justify-content-between' : 'align-items-center'}`}>
              {allowReportsSelection ? <h6>Select Reports</h6> : <h4 className="mb0">Generate Report</h4>}

              <Button
                className={`d-block h30 ${allowReportsSelection ? '' : 'ml20'}`}
                size="sm"
                variant="success"
                onClick={() => {
                  generateReportHandler();
                }}
                disabled={revokeReportGeneration || (Object.keys(selectedReports).length === 0 && allowReportsSelection)}
              >
                <FontAwesomeIcon
                  size="lg"
                  className="mr10"
                  icon={faChartBar}
                />
                {generateButtonText}
              </Button>
            </div>
            <div>
              {
                allowReportsSelection && (
                  <CustomizedTreeView
                    width="100%"
                    height={300}
                    dataForTreeViewInJson={dataForTreeView}
                    showCheckBoxesMode="normal"
                    selectionMode="multiple"
                    onSelectionChanged={(e: any) => this.onSelectionOrDeselection(e)}
                    renderTreeViewItem={this.renderTreeViewItem}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomizedReportHeaderControls;
