import React from 'react';
import DropDownBox from 'devextreme-react/ui/drop-down-box';
import { ScrollView } from 'devextreme-react';
import { toast } from 'react-toastify';
import getDropDownOptions from 'components/getDropDownOptions';
import Button from '../../../../wrapped-component/hint-controls/Button';
import ApplyingAndSortingGrid from '../applying-and-sorting-grid/ApplyingAndSortingGrid';
import { rulesAppliedDisplayValue } from '../SPCMapperUtils';

interface IRuleView {
  ID: number,
  Type: string,
  Rule: string,
  isVisible: boolean,
}
const columnNames = ['ID', 'Rule'];
class SPCAppliedRulesBox extends React.Component<any, any> {
  dropDownBoxRef: any;

  constructor(props:any) {
    super(props);
    this.state = {
      currentRules: props.customData.receivedRules,
      readonlyRules: props.data.data.rulesApplied === undefined
        ? this.generateRuleView(props.customData.receivedRules)
        : this.generateRuleView(props.customData.receivedRules.filter((rule:any) => { return !props.data.data.rulesApplied.find((appliedRule:any) => { return appliedRule.Id === rule.Id; }); })),
      selectedSortRules: this.generateRuleView(props.data.data.rulesApplied),
    };
    this.dropDownBoxRef = React.createRef();
  }

  componentDidUpdate(prevProps: { customData: any; }) {
    const { customData } = this.props;
    if (prevProps.customData.receivedRules !== customData.receivedRules) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => {
        return ({
          currentRules: customData.receivedRules,
        });
      });
    }
  }

  // temporary function
  aggregateData = (data:any) => {
    let result = '';
    if (data) {
      data.forEach((element:any) => {
        if (element.points.secondValue !== null) {
          result += `${element.points.firstValue}/${element.points.secondValue} `;
        } else {
          result += `${element.points.firstValue} `;
        }
        if (element.condition) {
          result += `${element.condition} `;
        }
        if (element.measuredValue) {
          result += `${element.measuredValue} `;
        }
        if (element.stdev) {
          result += `${element.stdev} `;
        }
        if (element.logicalOperator) {
          result += `${element.logicalOperator} `;
        }
      });
    }
    return result;
  };

  generateRuleView = (currentRules:any) => {
    const views : IRuleView[] = [];
    if (currentRules !== undefined) {
      currentRules.forEach((element:any) => {
        const view:IRuleView = {
          ID: element.spcRuleName,
          Type: element.spcRuleType,
          Rule: this.aggregateData(element.spcRuleExpressions),

          isVisible: true,
        };
        views.push(view);
      });
      return views;
    }
    return views;
  };

  private find = (array:any, value:any) => {
    let result: any;
    // eslint-disable-next-line consistent-return
    array.forEach((element:any) => {
      if (element.spcRuleName === value) {
        result = element;
        return null;
      }
    });
    return result;
  };

  applyingAndSortingGridHandler = (appliedRules: any) => {
    this.setState({ selectedSortRules: appliedRules });
  };

  sendToParent = () => {
    const { currentRules, selectedSortRules } = this.state;
    const { data, customData } = this.props;
    if (selectedSortRules.length === 0) {
      toast.warning('Please apply at least one rule');
      return;
    }
    const appliedRulesObjectFormat: any[] = [];
    selectedSortRules.forEach((element: any) => {
      appliedRulesObjectFormat.push(this.find(currentRules, element.ID));
    });
    data.setValue(rulesAppliedDisplayValue(appliedRulesObjectFormat));
    if (customData.onUpdateAppliedRules) {
      customData.onUpdateAppliedRules(appliedRulesObjectFormat);
    }
    this.dropDownBoxRef.current.instance.close();
  };

  renderAppliedRulesGrid = () => {
    const { readonlyRules, selectedSortRules } = this.state;
    return (
      <ScrollView
        showScrollbar="always"
        scrollByThumb
        direction="both"
      >
        <ApplyingAndSortingGrid availableRows={readonlyRules} appliedRows={selectedSortRules} columnNames={columnNames} onUpdate={this.applyingAndSortingGridHandler} />
        <div>
          <Button variant="primary float-right mt10" onClick={this.sendToParent}>Apply & Close</Button>
        </div>
      </ScrollView>
    );
  };

  render() {
    const { currentRules } = this.state;
    const { data } = this.props;
    return (
      <DropDownBox
        ref={this.dropDownBoxRef}
        dropDownOptions={getDropDownOptions('cell', data, [{ width: '80%' }])}
        value={data.value}
        deferRendering={false}
        placeholder="Select..."
        showClearButton={false}
        dataSource={currentRules}
        // onClosed={this.sendToParent}
        contentRender={this.renderAppliedRulesGrid}
        displayValueFormatter={() => data.value}
      />
    );
  }
}
export default SPCAppliedRulesBox;
