import React, { Component } from 'react';
import {
  Button, Col, Container, Modal, Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomizedDropdown from '../../../../components/wrapped-component/customized-dropdown/CustomizedDropdown';
import { httpSCW } from '../../../../services/http.scw';
import { IGenericDetailedReportRequestObject, IWaferCombinationsRequestObject } from '../../../../interfaces';
import toast from '../../../../CustomToast';
import BinWaferComparisonSelectionTable from './BinWaferMapComparisonSelectionTable';
import Checkbox from '../../../../components/wrapped-component/hint-controls/Checkbox';

interface ISelectionCriteriaData {
  key: string;
  probeCount: string;
  waferYield: number;
  dieCount: number;
}

interface IBinWaferMapComparisonPopupProps {
  show: boolean;
  requestObjectForBinWaferMapComparisonSelectionData: IGenericDetailedReportRequestObject;
  isDataReselected: boolean;
  generateReports: () => void;
  getRequestObject: (data: any) => void;
  showSelectionCriteriaByDefault: () => void;
  updateParentState: (state: any) => void;
}

interface IBinWaferMapComparisonPopupState{
  show: boolean;
  waferKeys: any;
  baseWaferProbeCountList: any;
  nonBaseWaferProbeCountList: any;
  selectedBaseWaferID: string;
  selectedNonBaseWaferID: string;
  changeBaseWaferTitle: boolean;
  changeNonBaseWaferTitle: boolean;
  allProbeCount: boolean;
  WaferCombinationsListRequest: IWaferCombinationsRequestObject[];
}

class BinWaferComparisonPopup extends Component<IBinWaferMapComparisonPopupProps, IBinWaferMapComparisonPopupState> {
  private SelectionCriteriaData: {
    [key: string]: ISelectionCriteriaData
  } = {};

  private isRemounted = false;

  constructor(props: any) {
    super(props);
    this.state = {
      show: props.show,
      waferKeys: [],
      baseWaferProbeCountList: [],
      nonBaseWaferProbeCountList: [],
      selectedBaseWaferID: '',
      selectedNonBaseWaferID: '',
      changeBaseWaferTitle: false,
      changeNonBaseWaferTitle: false,
      allProbeCount: false,
      WaferCombinationsListRequest: [],
    };
  }

  componentDidMount() {
    this.isRemounted = true;
  }

  componentDidUpdate(prevProps: IBinWaferMapComparisonPopupProps) {
    const {
      show, requestObjectForBinWaferMapComparisonSelectionData, isDataReselected, updateParentState
    } = this.props;
    if (show && (isDataReselected || this.isRemounted)){
      this.isRemounted = false;
      httpSCW.getBinWaferMapComparisonSelectionData(requestObjectForBinWaferMapComparisonSelectionData).then((data: any) => {
        updateParentState({ isDataReselected: false })
        if (data.length >= 2){
          this.populateDataArray(data);
        } else {
          toast.error('At least 2 wafers must be selected for bin wafer map comparision report!')
        }
      });
    }
    if (show !== this.state.show){
      this.setState({ show });
    }
  }

  onReselectPopupFormHandler = () => {
    const { showSelectionCriteriaByDefault } = this.props;
    this.setState({
      selectedBaseWaferID: '', selectedNonBaseWaferID: '', changeBaseWaferTitle: false, changeNonBaseWaferTitle: false, baseWaferProbeCountList: [], nonBaseWaferProbeCountList: []
    })
    showSelectionCriteriaByDefault();
  }

  onNextCombinationPopupFormHandler = () => {
    this.setState({
      selectedBaseWaferID: '', selectedNonBaseWaferID: '', changeBaseWaferTitle: false, changeNonBaseWaferTitle: false, baseWaferProbeCountList: [], nonBaseWaferProbeCountList: []
    })
    this.populateSelectedCombinations();
  }

  onOkPopupFormHandler = () => {
    const {
      generateReports,
      getRequestObject,
    } = this.props;
    const { allProbeCount, WaferCombinationsListRequest } = this.state;
    if (!allProbeCount) {
      this.populateSelectedCombinations();
      getRequestObject(WaferCombinationsListRequest);
      if (WaferCombinationsListRequest.some((limit) => limit.baseWafer.id !== '' && limit.nonBaseWafer.id !== '')) {
        httpSCW.isValidWaferCombination(WaferCombinationsListRequest)
          .then((data: any) => {
            if (data) {
              generateReports();
            } else {
              toast.error('Wafer dimensions do not match!');
            }
          });
      }
    } else {
      generateReports();
    }
  }

  getBaseWaferProbeCountList = (selectedBaseWaferID: string) => {
    const waferKey = this.SelectionCriteriaData[selectedBaseWaferID].key;
    const baseWaferProbeCountList = Object.entries(this.SelectionCriteriaData).map(([key, value]) => (value.key === waferKey ? [key, value.probeCount] : null))
      .filter((x) => x !== null);
    this.setState({
      selectedBaseWaferID,
      changeBaseWaferTitle: true,
      baseWaferProbeCountList,
    });
  }

  getNonBaseWaferProbeCountList = (selectedNonBaseWaferID: string) => {
    const waferKey = this.SelectionCriteriaData[selectedNonBaseWaferID].key;
    const nonBaseWaferProbeCountList = Object.entries(this.SelectionCriteriaData).map(([key, value]) => (value.key === waferKey ? [key, value.probeCount] : null))
      .filter((x) => x !== null);
    this.setState({
      selectedNonBaseWaferID,
      changeNonBaseWaferTitle: true,
      nonBaseWaferProbeCountList,
    });
  };

  updateSelectedBaseWaferID = (selectedBaseWaferID: string) => {
    this.setState({ selectedBaseWaferID });
  }

  updateSelectedNonBaseWaferID = (selectedNonBaseWaferID: string) => {
    this.setState({ selectedNonBaseWaferID });
  }

  populateDataArray = (data: any) => {
    const waferKeys: any = [];
    const WaferCombinationsListRequest: IWaferCombinationsRequestObject[] = [];
    data.forEach((item: any) => {
      if (waferKeys.filter((x: any) => { return x[1] === item.waferKey }).length === 0) {
        waferKeys.push([item.waferID, item.waferKey]);
      }
      this.SelectionCriteriaData[item.waferID] = {
        key: item.waferKey,
        probeCount: item.probeCount,
        waferYield: item.yield,
        dieCount: item.dieCount
      };
    });
    this.setState({
      waferKeys,
      WaferCombinationsListRequest
    });
  }

  populateSelectedCombinations = () => {
    const {
      selectedBaseWaferID, selectedNonBaseWaferID, WaferCombinationsListRequest
    } = this.state;
    if (selectedBaseWaferID !== '' && selectedNonBaseWaferID !== ''){
      const waferCombinationRequestObject: IWaferCombinationsRequestObject = {
        baseWafer: {
          id: selectedBaseWaferID,
          probeCount: this.SelectionCriteriaData[selectedBaseWaferID].probeCount,
          key: this.SelectionCriteriaData[selectedBaseWaferID].key,
          yield: this.SelectionCriteriaData[selectedBaseWaferID].waferYield,
          dieCount: this.SelectionCriteriaData[selectedBaseWaferID].dieCount,
        },
        nonBaseWafer: {
          id: selectedNonBaseWaferID,
          probeCount: this.SelectionCriteriaData[selectedNonBaseWaferID].probeCount,
          key: this.SelectionCriteriaData[selectedNonBaseWaferID].key,
          yield: this.SelectionCriteriaData[selectedNonBaseWaferID].waferYield,
          dieCount: this.SelectionCriteriaData[selectedNonBaseWaferID].dieCount,
        }
      }

      if (selectedBaseWaferID === selectedNonBaseWaferID){
        toast.error('Base and non-base wafers cannot be the same.');
        return;
      }

      if (!WaferCombinationsListRequest.some((waferCombination) => {
        return (waferCombination.baseWafer.id === waferCombinationRequestObject.baseWafer.id && waferCombination.nonBaseWafer.id === waferCombinationRequestObject.nonBaseWafer.id)
      })) {
        WaferCombinationsListRequest.push(waferCombinationRequestObject);
        if (WaferCombinationsListRequest.length > 1){
          WaferCombinationsListRequest.sort((a: any, b: any) => a.baseWafer.key - b.nonBaseWafer.key);
        }
        this.setState({ WaferCombinationsListRequest });
      } else {
        toast.error('This wafer combination is already added.')
      }
      return;
    }
    toast.warn('Select some wafer combination');
  }

  isWaferDataSame = (waferId:string, key: string, probeCount: string) => {
    return this.SelectionCriteriaData[waferId].key === key && this.SelectionCriteriaData[waferId].probeCount === probeCount;
  }

  implementSameProbeCountSelection = (selectedBaseWaferID: string, selectedNonBaseWaferID: string, isSameProbeCountSelectionChecked: boolean) => {
    if (!isSameProbeCountSelectionChecked) return;

    const { WaferCombinationsListRequest } = this.state;
    const selectedBaseWaferKey = this.SelectionCriteriaData[selectedBaseWaferID].key;
    const selectedNonBaseWaferKey = this.SelectionCriteriaData[selectedNonBaseWaferID].key;
    if (selectedBaseWaferKey !== selectedNonBaseWaferKey) {
      toast.error('Different wafer IDs are not applicable when \'Implement same Probe Count selection for all wafer IDs\' is checked.');
      return;
    }
    const baseWaferProbeCount = this.SelectionCriteriaData[selectedBaseWaferID].probeCount;
    const nonBaseWaferProbeCount = this.SelectionCriteriaData[selectedNonBaseWaferID].probeCount;
    if (baseWaferProbeCount === nonBaseWaferProbeCount) {
      toast.error('Similar probe count is not applicable when \'Implement same Probe Count selection for all wafer IDs\' is checked.');
      return;
    }

    const remainingWaferKeysWithIdsDict: any = {};
    Object.entries(this.SelectionCriteriaData).filter(([, value]) => { return value.key !== selectedBaseWaferKey }).forEach(([key, value]) => {
      if (!remainingWaferKeysWithIdsDict[value.key]) remainingWaferKeysWithIdsDict[value.key] = [];
      return remainingWaferKeysWithIdsDict[value.key].push(key)
    });

    let baseWafer: any = {};
    let nonBaseWafer: any = {};
    Object.keys(remainingWaferKeysWithIdsDict).forEach((waferKey: string) => {
      remainingWaferKeysWithIdsDict[waferKey].forEach((waferId: string) => {
        if (this.isWaferDataSame(waferId, waferKey, baseWaferProbeCount)) {
          baseWafer = {
            id: waferId,
            probeCount: baseWaferProbeCount,
            key: waferKey,
            yield: this.SelectionCriteriaData[waferId].waferYield
          }
        } else if (this.isWaferDataSame(waferId, waferKey, nonBaseWaferProbeCount)) {
          nonBaseWafer = {
            id: waferId,
            probeCount: nonBaseWaferProbeCount,
            key: waferKey,
            yield: this.SelectionCriteriaData[waferId].waferYield
          }
        }
      });
      if (!_.isEmpty(baseWafer) && !_.isEmpty(nonBaseWafer)) {
        const waferCombinationRequestObject: IWaferCombinationsRequestObject = {
          baseWafer,
          nonBaseWafer
        }
        if (!WaferCombinationsListRequest.some((waferCombination) => {
          return (waferCombination.baseWafer.id === waferCombinationRequestObject.baseWafer.id && waferCombination.nonBaseWafer.id === waferCombinationRequestObject.nonBaseWafer.id)
        })) {
          WaferCombinationsListRequest.push(waferCombinationRequestObject);
        }
      }
    });
    if (WaferCombinationsListRequest.length > 1){
      WaferCombinationsListRequest.sort((a: any, b: any) => a.baseWafer.key - b.nonBaseWafer.key);
    }
    this.setState({ WaferCombinationsListRequest });
  }

  render() {
    const {
      show,
      waferKeys,
      baseWaferProbeCountList,
      nonBaseWaferProbeCountList,
      selectedBaseWaferID,
      selectedNonBaseWaferID,
      changeBaseWaferTitle,
      changeNonBaseWaferTitle,
      WaferCombinationsListRequest,
    } = this.state;
    const { updateParentState } = this.props;
    return (
      <Modal dialogClassName="wafer-selection-popup" show={show} size="xl" scrollable>
        <Modal.Header>
          <Modal.Title as="h3">Wafer Selection for Comparison</Modal.Title>
          <span className="float-right">
            <Button variant="secondary" onClick={() => { updateParentState({ showBinWaferMapComparisonPopup: false }) }}>
              <FontAwesomeIcon
                size="lg"
                icon={faTimes}
              />
            </Button>
          </span>
        </Modal.Header>
        <Modal.Body className="w-100 h-100">
          <Container fluid className="border-all pl10 pr10 pt10 pb10 background-color-light border-radius-4" style={{ overflow: 'visible', minHeight: '200px' }}>
            <Row className="d-flex justify-content-start ml10">
              <h4>
                Set the Base and Non-Base Wafer ID/Probe Count from the Selection
              </h4>
            </Row>

            <Row className="d-flex mb10 ml10 mt10">
              <Col lg={6}>Base Wafer</Col>
              <Col lg={6}> Non-Base Wafer</Col>
            </Row>

            <Row className="d-flex mb10 ml10">
              <Col lg={6}>
                Wafer ID
                <CustomizedDropdown
                  variant="light"
                  full
                  updateTitle={changeBaseWaferTitle}
                  avoidUpdateOnSelect
                  list={waferKeys}
                  dropdownButtonClass="border-all"
                  selectedValue={selectedBaseWaferID}
                  defaultValue={selectedBaseWaferID}
                  onChange={this.getBaseWaferProbeCountList}
                />
              </Col>

              <Col lg={6}>
                Wafer ID
                <CustomizedDropdown
                  variant="light"
                  full
                  updateTitle={changeNonBaseWaferTitle}
                  list={waferKeys}
                  dropdownButtonClass="border-all"
                  selectedValue={selectedNonBaseWaferID}
                  defaultValue={selectedNonBaseWaferID}
                  onChange={this.getNonBaseWaferProbeCountList}
                />
              </Col>
            </Row>

            <Row className="d-flex mb10 ml10">
              <Col lg={6}>
                Probe Count
                <CustomizedDropdown
                  variant="light"
                  disabled={selectedBaseWaferID === ''}
                  updateTitle={changeBaseWaferTitle}
                  full
                  list={baseWaferProbeCountList}
                  dropdownButtonClass="border-all"
                  selectedValue={selectedBaseWaferID}
                  defaultValue={selectedBaseWaferID}
                  onChange={this.updateSelectedBaseWaferID}
                />
              </Col>

              <Col lg={6}>
                Probe Count
                <CustomizedDropdown
                  variant="light"
                  disabled={selectedNonBaseWaferID === ''}
                  updateTitle={changeNonBaseWaferTitle}
                  full
                  list={nonBaseWaferProbeCountList}
                  dropdownButtonClass="border-all"
                  selectedValue={selectedNonBaseWaferID}
                  defaultValue={selectedNonBaseWaferID}
                  onChange={this.updateSelectedNonBaseWaferID}
                />
              </Col>
            </Row>

            <Row className="d-flex mb10 ml10">
              <Col>
                <Checkbox
                  onChange={(e: any) => {
                    this.implementSameProbeCountSelection(selectedBaseWaferID, selectedNonBaseWaferID, e.target.checked);
                  }}
                >
                  Implement same Probe Count selection for all wafer IDs
                </Checkbox>
              </Col>
            </Row>

            <Row className="d-flex justify-content-end">
              <Button
                className="float-right mr20"
                variant="outline-dark"
                size="lg"
                onClick={() => {
                  this.onReselectPopupFormHandler();
                }}
              >
                Reselect
              </Button>

              <Button
                className="float-right mr20"
                variant="outline-dark"
                size="lg"
                onClick={() => {
                  this.onNextCombinationPopupFormHandler();
                }}
              >
                Next Combination
              </Button>

              <Button
                className="float-right mr20"
                variant="outline-dark"
                size="lg"
                onClick={() => {
                  this.onOkPopupFormHandler()
                }}
              >
                Ok
              </Button>
            </Row>
          </Container>
        </Modal.Body>

        <Row className="border-all background-color-light border-radius-4">
          <Col lg={12}>
            <BinWaferComparisonSelectionTable
              selectedCombinations={WaferCombinationsListRequest}
            />
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default BinWaferComparisonPopup;
