import React, { Component } from 'react';
import { httpService } from '../../../services/http.service';
import WaferData from '../wafer-map-widget/WaferData';
import { RandomNumberUtility } from '../wafer-map-widget/wafer-map/web-gl-utils/RandomNumberUtility';
import WaferMap from '../wafer-map-widget/wafer-map/WaferMap';

const dieSize = { dieWidth: 64, dieHeight: 45 }; // database
const test = new RandomNumberUtility();
const centerOfEclipse1 = 150; // Center of Wafer X Axis
const centerOfEclipse2 = 250; // Center of Wafer Y Axis
const widthOfEclipse = 8; // Number of Rows in Wafer Map Data
const heightOfEclipse = widthOfEclipse / 2; // Number of Columns in Wafer Map Data
const radomGeneratedWafereData = test.randomWaferDataWithBinNum(
  centerOfEclipse1,
  centerOfEclipse2,
  widthOfEclipse,
  heightOfEclipse,
  [1, 2, 3, 4, 5, 6, 7, 8, 9],
);

interface WaferImageGeneratorProps {
  waferId: string;
  userId: string;
}

class WaferImageGenerator extends Component<WaferImageGeneratorProps, any> {
  private waferSize = 550;

  constructor(props:any) {
    super(props);
    this.state = {
      wafer: undefined,
    };
  }

  componentDidMount() {
    const { waferId, userId } = this.props;
    let { wafer } = this.state;
    httpService.getBinWaferMapData([waferId])
      .then((data) => {
        if (data !== '') {
          const { dies } = data;
          const waferDataObj = new WaferData(dies, radomGeneratedWafereData.dieSubView);
          wafer = (
            <WaferMap
              showRadar={false}
              draggable={false}
              showToolbar={false}
              showGridBordersAndTicks={false}
              keyIndex={waferId}
              waferData={waferDataObj}
              waferSize={this.waferSize}
              dieSize={dieSize}
            />
          );
          this.setState({ wafer });
        }
      });
  }

  render() {
    const { wafer } = this.state;
    return (
      <div className={`wafer h${this.waferSize} w${this.waferSize}`}>
        {wafer}
      </div>
    );
  }
}

export default WaferImageGenerator;
