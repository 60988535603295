import React from 'react';
import {
  Container, Row, Col, Tabs, Tab,
} from 'react-bootstrap';
import axios from 'axios';
import WebGLUtils from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Utils';
import WaferMapVariables from 'components/utility-component/wafer-map-widget/wafer-map/variables/WaferMapVariablesClass';
import { RandomNumberUtility } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/RandomNumberUtility';
import Heading from '../../components/wrapped-component/hint-controls/Heading';
import Button from '../../components/wrapped-component/hint-controls/Button';
import './policy-results.scss';
import TopbarNav from '../../components/navigational-component/topbar-nav/TopbarNav';
import WaferMapGridView from '../../components/utility-component/wafer-map-widget/tab-view/WaferMapGridView';
import CustomizedDropdown from '../../components/wrapped-component/customized-dropdown/CustomizedDropdown';
import AppConstants from '../../constants.js';
import { ServiceType } from '../../interfaces';

interface ICntrolTowerTestingProps {
  className?: string;
}

interface IPolicyResultsStates {
  policies: string[][],
  selectedPolicy: string;
  wafers: string[][],
  selectedWafer: string;
  executionData: any;
}

class PolicyResults
  extends React.Component<ICntrolTowerTestingProps, IPolicyResultsStates> {
  constructor(props: ICntrolTowerTestingProps) {
    super(props);
    this.updateSelectedPolicy = this.updateSelectedPolicy.bind(this);
    this.updateSelectedWafer = this.updateSelectedWafer.bind(this);
    this.getSteps = this.getSteps.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.getStepData = this.getStepData.bind(this);
    this.state = {
      policies: [],
      selectedPolicy: '',
      // selectedPolicy: 'fc669177-79f0-4773-baf2-9e83796cd06b',
      wafers: [],
      selectedWafer: '',
      // selectedWafer: '34ab46fa-716a-44ed-86a7-05093e0a84fe',
      executionData: [],
    };
    // this.getSteps();
  }

  dieSize = { dieWidth: 32, dieHeight: 32 };

  componentDidMount() {
    let policies:string[][] = [];
    axios.get(`${AppConstants.baseURL}/api/policies`)
      .then((response) => {
        if (response.data.length > 0) {
          policies = response.data.map((item: any) => ([item.id, item.name]));
          policies.unshift(['', 'Select Policy']);
          this.setState({
            policies,
          });
        }
      });
  }

  getSteps = (selectedWafer:string) => {
    axios.get(`${AppConstants.baseURL}/api/workflow/${selectedWafer}`)
      .then((response) => {
        if (response.data !== '') {
          const executionData = response.data.workflowSteps.map((item:any) => ({
            type: item.type,
            id: item.id,
            selectedPage: 1,
            totalPages: item.patRules ? item.patRules.length : 0,
            expand: false,
            outputWafer: item.outputWafer,
            source1Wafer: item.source1Wafer,
            source2Wafer: item.source2Wafer,
            Rules: item.patRules ? item.patRules.map((ruleItem:any) => ({
              id: ruleItem.id,
              name: '',
              inputWaferId: ruleItem.inputWafer.id,
              outputWaferId: ruleItem.outputWafer.id,
              titleInputTrend: '',
              titleOutputTrend: '',
              titleInputHistogram: '',
              titleOutputHistogram: '',
              inputTrendX: [],
              inputTrendY: [],
              outputTrendX: [],
              outputTrendY: [],
              inputTrendLimits: [],
              outputTrendLimits: [],
              inputHistogramLimits: [],
              outputHistogramLimits: [],
              inputHistogramX: [],
              inputHistogramY: [],
              outputHistogramX: [],
              outputHistogramY: [],
              inputWaferData: undefined,
              inputWaferSubViewData: undefined,
              outputWaferData: undefined,
              outputWaferSubViewData: undefined,
            })) : [],
          }));
          this.setState({
            executionData,
            selectedWafer,
          });
        }
      });
  };

  updateSelectedPolicy = (value: string) => {
    const selectedPolicy = value;
    let wafers:string[][] = [];
    axios.get(`${AppConstants.baseURL}/api/policy/${value}/workflows`)
      .then((response) => {
        if (response.data.length > 0) {
          wafers = response.data.map((item: any) => ([item.id, item.id]));
          wafers.unshift(['', 'Select Policy Execution']);
        }
        this.setState({
          selectedPolicy,
          wafers,
        });
      });
  };

  updateSelectedWafer = (value: string) => {
    if (value !== '') {
      this.getSteps(value);
    }
  };

  updatePage = (stepIndex:number, selectedPage:number, action:string) => {
    let newPage = selectedPage;
    if (action === 'prev') {
      newPage -= 1;
    } else {
      newPage += 1;
    }
    this.getStepData(stepIndex, newPage, false);
  };

  getPATStepData = (stepIndex:number, selectedPage:number, toggleExpand:boolean) => {
    const { executionData } = this.state;
    if (toggleExpand) { executionData[stepIndex].expand = !executionData[stepIndex].expand; }

    const { Rules } = executionData[stepIndex];
    const { inputWaferId, outputWaferId } = Rules[selectedPage - 1];
    const ruleId = Rules[selectedPage - 1].id;

    // TRENDS
    axios.get(`${AppConstants.baseURL}/api/workflow-step-rule/${ruleId}/trends`).then((trendsExecutionDataResponse:any) => {
      executionData[stepIndex].selectedPage = selectedPage;

      // TREND INPUT
      executionData[stepIndex].Rules[selectedPage - 1].inputTrendX = trendsExecutionDataResponse.data.inputChart !== null ? trendsExecutionDataResponse.data.inputChart.xAxis : [];
      executionData[stepIndex].Rules[selectedPage - 1].inputTrendY = trendsExecutionDataResponse.data.inputChart !== null ? trendsExecutionDataResponse.data.inputChart.yAxis : [];

      if (trendsExecutionDataResponse.data.inputChart.siteWiseLimits !== null) {
        const inputLimits:any = Object.entries(trendsExecutionDataResponse.data.inputChart.siteWiseLimits);
        for (let i = 0; i < inputLimits.length; i += 1) {
          const inputLimitItem = {
            lowLimit: inputLimits[i][1].lowLimit,
            hiLimit: inputLimits[i][1].highLimit,
          };
          executionData[stepIndex].Rules[selectedPage - 1].inputTrendLimits.push(inputLimitItem);
        }
      }

      // TREND OUTPUT
      executionData[stepIndex].Rules[selectedPage - 1].outputTrendX = trendsExecutionDataResponse.data.outputChart !== null ? trendsExecutionDataResponse.data.outputChart.xAxis : [];
      executionData[stepIndex].Rules[selectedPage - 1].outputTrendY = trendsExecutionDataResponse.data.outputChart !== null ? trendsExecutionDataResponse.data.outputChart.yAxis : [];

      if (trendsExecutionDataResponse.data.outputChart.siteWiseLimits !== null) {
        const outputLimits:any = Object.entries(trendsExecutionDataResponse.data.outputChart.siteWiseLimits);
        for (let i = 0; i < outputLimits.length; i += 1) {
          const outputLimitItem = {
            lowLimit: outputLimits[i][1].lowLimit,
            hiLimit: outputLimits[i][1].highLimit,
          };
          executionData[stepIndex].Rules[selectedPage - 1].outputTrendLimits.push(outputLimitItem);
        }
      }
      executionData[stepIndex].Rules[selectedPage - 1].titleInputTrend = trendsExecutionDataResponse.data.inputChart.title;
      executionData[stepIndex].Rules[selectedPage - 1].titleOutputTrend = trendsExecutionDataResponse.data.outputChart.title;

      // HISTOGRAMS
      axios.get(`${AppConstants.baseURL}/api/workflow-step-rule/${ruleId}/parametric-histograms`).then((histogramsExecutionDataResponse:any) => {
        // HISTOGRAM INPUT
        executionData[stepIndex].Rules[selectedPage - 1].inputHistogramX = histogramsExecutionDataResponse.data.inputChart.xAxis;
        // histogramsExecutionDataResponse.data.inputChart !== null ? histogramsExecutionDataResponse.data.inputChart.xAxis : [];
        executionData[stepIndex].Rules[selectedPage - 1].inputHistogramY = histogramsExecutionDataResponse.data.inputChart.yAxis;
        // histogramsExecutionDataResponse.data.inputChart !== null ? histogramsExecutionDataResponse.data.inputChart.yAxis : [];

        if (histogramsExecutionDataResponse.data.inputChart.siteWiseLimits !== null) {
          const inputLimits:any = Object.entries(histogramsExecutionDataResponse.data.inputChart.siteWiseLimits);
          for (let i = 0; i < inputLimits.length; i += 1) {
            const inputLimitItem = {
              lowLimit: inputLimits[i][1].lowLimit,
              hiLimit: inputLimits[i][1].highLimit,
            };
            executionData[stepIndex].Rules[selectedPage - 1].inputHistogramLimits.push(inputLimitItem);
          }
        }

        // HISTOGRAM OUTPUT
        executionData[stepIndex].Rules[selectedPage - 1].outputHistogramX = histogramsExecutionDataResponse.data.outputChart.xAxis;
        // histogramsExecutionDataResponse.data.outputChart !== null ? histogramsExecutionDataResponse.data.outputChart.xAxis : [];
        executionData[stepIndex].Rules[selectedPage - 1].outputHistogramY = histogramsExecutionDataResponse.data.outputChart.yAxis;
        // histogramsExecutionDataResponse.data.outputChart !== null ? histogramsExecutionDataResponse.data.outputChart.yAxis : [];

        if (histogramsExecutionDataResponse.data.outputChart.siteWiseLimits !== null) {
          const outputLimits:any = Object.entries(histogramsExecutionDataResponse.data.outputChart.siteWiseLimits);
          for (let i = 0; i < outputLimits.length; i += 1) {
            const outputLimitItem = {
              lowLimit: outputLimits[i][1].lowLimit,
              hiLimit: outputLimits[i][1].highLimit,
            };
            executionData[stepIndex].Rules[selectedPage - 1].outputHistogramLimits.push(outputLimitItem);
          }
        }

        executionData[stepIndex].Rules[selectedPage - 1].titleInputHistogram = histogramsExecutionDataResponse.data.inputChart.title;
        executionData[stepIndex].Rules[selectedPage - 1].titleOutputHistogram = histogramsExecutionDataResponse.data.outputChart.title;

        // INPUT WAFER
        axios.get(`${AppConstants.baseURL}/api/wafer-map/${inputWaferId}`)
          .then((inputWaferDataResponse) => {
            executionData[stepIndex].Rules[selectedPage - 1].inputWaferData = inputWaferDataResponse.data.dies;

            // OUTPUT WAFER
            axios.get(`${AppConstants.baseURL}/api/wafer-map/${outputWaferId}`)
              .then((outputWaferDataResponse) => {
                executionData[stepIndex].Rules[selectedPage - 1].outputWaferData = outputWaferDataResponse.data.dies;

                // GENERATING RANDOM SUB DIE VIEW
                const util = new WebGLUtils(new WaferMapVariables('0', []));
                const obj = new RandomNumberUtility().randomWaferDataWithBinNum(
                  util.waferMapVariables.centerOfEclipse1,
                  util.waferMapVariables.centerOfEclipse2,
                  util.waferMapVariables.widthOfEclipse,
                  util.waferMapVariables.heightOfEclipse,
                  [1, 2, 3, 4, 5, 6, 7, 8, 9],
                );

                executionData[stepIndex].Rules[selectedPage - 1].inputWaferSubViewData = obj.dieSubView;
                executionData[stepIndex].Rules[selectedPage - 1].outputWaferSubViewData = obj.dieSubView;

                this.setState({ executionData });
              });
          });
      });
    });
  };

  getSWMStepData = async (stepIndex:number, selectedPage:number, toggleExpand:boolean) => {
    const { executionData } = this.state;
    if (toggleExpand) { executionData[stepIndex].expand = !executionData[stepIndex].expand; }
    const source1Response = await axios.get(`${AppConstants.baseURL}/api/wafer-map/${executionData[stepIndex].source1Wafer.id}`);
    executionData[stepIndex].source1WaferMap = source1Response.data.dies;
    const source2Response = await axios.get(`${AppConstants.baseURL}/api/wafer-map/${executionData[stepIndex].source2Wafer.id}`);
    executionData[stepIndex].source2WaferMap = source2Response.data.dies;
    const targetResponse = await axios.get(`${AppConstants.baseURL}/api/wafer-map/${executionData[stepIndex].outputWafer.id}`);
    executionData[stepIndex].targetWaferMap = targetResponse.data.dies;
    // GENERATING RANDOM SUB DIE VIEW
    const util = new WebGLUtils(new WaferMapVariables('0', [], { dieWidth: 32, dieHeight: 32 }));
    const obj = new RandomNumberUtility().randomWaferDataWithBinNum(
      util.waferMapVariables.centerOfEclipse1,
      util.waferMapVariables.centerOfEclipse2,
      util.waferMapVariables.widthOfEclipse,
      util.waferMapVariables.heightOfEclipse,
      [1, 2, 3, 4, 5, 6, 7, 8, 9],
    );

    executionData[stepIndex].waferSubViewData = obj.dieSubView;
    this.setState({ executionData });
  };

  getStepData = (stepIndex:number, selectedPage:number, toggleExpand:boolean) => {
    const { executionData } = this.state;
    if (executionData[stepIndex].type === ServiceType.PAT) {
      this.getPATStepData(stepIndex, selectedPage, toggleExpand);
    } else if (executionData[stepIndex].type === ServiceType.SWM) {
      this.getSWMStepData(stepIndex, selectedPage, toggleExpand);
    }
  };

  render() {
    const {
      policies,
      selectedPolicy,
      wafers,
      selectedWafer,
      executionData,
    } = this.state;
    const { className } = this.props;

    return (

      <Container fluid className={`${className !== undefined ? className : ''}`}>
        <TopbarNav
          items={[]}
          title="Policy Results Analysis"
        />
        <Row className="settings-section p20">
          <Col lg={4} className="custom-form">
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Policy</span>
              <div className="flex-70">
                <CustomizedDropdown
                  full
                  onChange={this.updateSelectedPolicy}
                  variant="clear"
                  list={policies}
                  selectedValue={selectedPolicy}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} className="custom-form" hidden={wafers.length === 0}>
            <div className="custom-form-inline label-left">
              <span className="label flex-30">Policy Executions</span>
              <div className="flex-70">
                <CustomizedDropdown
                  full
                  onChange={this.updateSelectedWafer}
                  variant="clear"
                  list={wafers}
                  selectedValue={selectedWafer}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} hidden={wafers.length > 0 || selectedPolicy === ''}>
            <div className="label p10">No Execution(s) found for this policy</div>
          </Col>
          {/* <Col>
            <Button
              disabled={wafers.length === 0}
              className="mt3"
              variant="primary"
              onClick={this.getSteps}
            >
              Load
            </Button>
          </Col> */}
        </Row>
        <Row className="p20">
          <Col>
            {executionData.map((item: any, stepIndex:number) => (
              <>
                <div

                  className="block w-100 step-block collapsed d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <div className="step-name p0 border-none text-center">
                      {item.type}
                    </div>
                    <span
                      className="info-label ml10"
                    >
                      {item.totalPages}
                      {' '}
                      Rules
                    </span>
                  </div>
                  <Button
                    variant="clear"
                    className="info-label color-icon-color"
                    onClick={() => [
                      this.getStepData(stepIndex, item.selectedPage, true),
                    ]}
                  >
                    <span hidden={item.expand}>
                      Expand Step ↓
                    </span>
                    <span hidden={!item.expand}>
                      Collapse Step ↑
                    </span>
                  </Button>
                </div>
                {item.expand && item.type === ServiceType.PAT
                && (
                // item.Rules.map((ruleItem:any) => (
                  <div className="block step-block p20 ">
                    <div className="d-flex align-items-center float-right">
                      <Button
                        size="sm"
                        variant="outline-dark"
                        disabled={item.selectedPage === 1}
                        onClick={() => {
                          this.updatePage(stepIndex, item.selectedPage, 'prev');
                        }}
                      >
                        ‹
                      </Button>
                      <span className="info-label color-icon-color pl10 pr10">
                        {item.selectedPage}
                        {' '}
                        of
                        {' '}
                        {item.totalPages}
                        {' '}
                        Rules
                      </span>
                      <Button
                        size="sm"
                        variant="outline-dark"
                        disabled={item.selectedPage === item.totalPages}
                        onClick={() => {
                          this.updatePage(stepIndex, item.selectedPage, 'next');
                        }}
                      >
                        ›
                      </Button>
                    </div>
                    <Tabs>
                      <Tab
                        eventKey="wafer"
                        title="Wafer"
                      >
                        <>
                          <div className="mb50">

                            <Heading size={3} className="mt20">Original Wafer</Heading>
                            <p>
                              {item.Rules[item.selectedPage - 1].name}
                            </p>
                            <WaferMapGridView
                              keyIndex={`input-${item.id}-${stepIndex}`}
                              waferData={item.Rules[item.selectedPage - 1].inputWaferData}
                              dieSubView={item.Rules[item.selectedPage - 1].inputWaferSubViewData}
                              dieSize={this.dieSize}
                            />
                          </div>
                          <div>
                            <Heading size={3}>Output Wafer</Heading>
                            <p>
                              {item.Rules[item.selectedPage - 1].name}
                            </p>
                            <WaferMapGridView
                              keyIndex={`output-${item.id}-${stepIndex}`}
                              waferData={item.Rules[item.selectedPage - 1].outputWaferData}
                              dieSubView={item.Rules[item.selectedPage - 1].outputWaferSubViewData}
                              dieSize={this.dieSize}
                            />
                          </div>
                        </>
                      </Tab>
                      <Tab
                        eventKey="histogram"
                        title="Histogram"
                      >
                        <Container fluid>
                          <Row>
                            <Col>
                              {/* <Histogram */}
                              {/*  data={{ */}
                              {/*    id: '', */}
                              {/*    name: item.Rules[item.selectedPage - 1].titleInputHistogram, */}
                              {/*    x: { */}
                              {/*      data: item.Rules[item.selectedPage - 1].inputHistogramX, */}
                              {/*      name: 'X', */}
                              {/*      showLegend: false, */}
                              {/*    }, */}
                              {/*    y: { */}
                              {/*      data: item.Rules[item.selectedPage - 1].inputHistogramY, */}
                              {/*      name: 'Y', */}
                              {/*      showLegend: false, */}
                              {/*    }, */}
                              {/*    limits: item.Rules[item.selectedPage - 1].inputHistogramLimits, */}
                              {/*  }} */}
                              {/* /> */}
                            </Col>
                            <Col>
                              {/* <Histogram */}
                              {/*  data={{ */}
                              {/*    id: '', */}
                              {/*    name: item.Rules[item.selectedPage - 1].titleOutputHistogram, */}
                              {/*    x: { */}
                              {/*      data: item.Rules[item.selectedPage - 1].outputHistogramX, */}
                              {/*      name: 'X', */}
                              {/*      showLegend: false, */}
                              {/*    }, */}
                              {/*    y: { */}
                              {/*      data: item.Rules[item.selectedPage - 1].outputHistogramY, */}
                              {/*      name: 'Y', */}
                              {/*      showLegend: false, */}
                              {/*    }, */}
                              {/*    limits: item.Rules[item.selectedPage - 1].outputHistogramLimits, */}
                              {/*  }} */}
                              {/* /> */}
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                      <Tab
                        eventKey="trends"
                        title="Trends"
                        mountOnEnter
                        unmountOnExit
                      >
                        <Container fluid>
                          <Row>
                            <Col lg={12}>
                              {/* <Trend */}
                              {/*  data={{ */}
                              {/*    name: item.Rules[item.selectedPage - 1].titleInputTrend, */}
                              {/*    x: item.Rules[item.selectedPage - 1].inputTrendX, */}
                              {/*    y: item.Rules[item.selectedPage - 1].inputTrendY, */}
                              {/*    limits: item.Rules[item.selectedPage - 1].inputTrendLimits, */}
                              {/*  }} */}
                              {/* /> */}
                            </Col>
                            <Col lg={12}>
                              {/* <Trend */}
                              {/*  rule={{ */}
                              {/*    name: item.Rules[item.selectedPage - 1].titleOutputTrend, */}
                              {/*    x: item.Rules[item.selectedPage - 1].outputTrendX, */}
                              {/*    y: item.Rules[item.selectedPage - 1].outputTrendY, */}
                              {/*    limits: item.Rules[item.selectedPage - 1].outputTrendLimits, */}
                              {/*  }} */}
                              {/* /> */}
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                    </Tabs>
                  </div>
                )}
                {item.expand && item.type === ServiceType.SWM
                && (
                  <div className="block step-block p20 ">
                    <div className="d-flex align-items-center float-right">
                      <Button
                        size="sm"
                        variant="outline-dark"
                        disabled={item.selectedPage === 1}
                        onClick={() => {
                          this.updatePage(stepIndex, item.selectedPage, 'prev');
                        }}
                      >
                        ‹
                      </Button>
                      <span className="info-label color-icon-color pl10 pr10">
                        {item.selectedPage}
                        {' '}
                        of
                        {' '}
                        {item.totalPages}
                      </span>
                      <Button
                        size="sm"
                        variant="outline-dark"
                        disabled={item.selectedPage === item.totalPages}
                        onClick={() => {
                          this.updatePage(stepIndex, item.selectedPage, 'next');
                        }}
                      >
                        ›
                      </Button>
                    </div>
                    <div className="mb50">

                      <h3 className="mt20">Source1 Wafer</h3>
                      <WaferMapGridView
                        keyIndex={`source1-${item.id}-${stepIndex}`}
                        waferData={item.source1WaferMap}
                        dieSubView={item.waferSubViewData}
                        dieSize={this.dieSize}
                      />
                    </div>
                    <div>
                      <h3>Source2 Wafer</h3>
                      <WaferMapGridView
                        keyIndex={`source2-${item.id}-${stepIndex}`}
                        waferData={item.source2WaferMap}
                        dieSubView={item.waferSubViewData}
                        dieSize={this.dieSize}
                      />
                    </div>
                    <div>
                      <h3>Target Wafer</h3>
                      <WaferMapGridView
                        keyIndex={`target-${item.id}-${stepIndex}`}
                        waferData={item.targetWaferMap}
                        dieSubView={item.waferSubViewData}
                        dieSize={this.dieSize}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}

          </Col>
        </Row>
      </Container>
    );
  }
}

export default PolicyResults;
