import React, { Component } from 'react';
import DataGrid, {
  Button as DxButton, Column, Editing, Lookup, Paging, Scrolling,
} from 'devextreme-react/data-grid';
import { SelectBox } from 'devextreme-react';
import toast from 'CustomToast';
import getDropDownOptions from 'components/getDropDownOptions';
import Heading from '../../../../wrapped-component/hint-controls/Heading';
import CustomizedGridDropDown from '../../../../wrapped-component/customized-grid-dropdown/CustomizedGridDropDown';
import withProps from '../../../../bin-plus-tables/withProps';
import BinConfigDropDownBoxComponent from './BinConfigDropDownBoxComponent';
import { httpBinPlusTable } from '../../../../../services/http.bin-plus-table';
import { RuleTestParameter } from '../PATInterfaces';
import ModalPopup from '../../../../wrapped-component/modal-popup/modal-popup';

interface IPATSelectedParameterGridProps {
  selectedTestParameterIds: any;
  binConfigSelectionHandler: (selectedBinPlusDefinition: any, rowData: any) => void;
  ruleTestParameters: RuleTestParameter[];
  allTestParameters: any;
  removeField: (e: any) => void;
  patRuleType: any;
}

interface IPATSelectedParameterGridState {
  binPlusTables: any,
  selectedBinPlusTableId: string,
  binPlusDefinitionGridData: any,
  binPlusGroupTypesBinTypes: any[],
  changeSelectedBinPlusTableId: boolean,
  previousSelectedBinPlusTableName: string;
}

class PATSelectedParameterGrid extends Component<IPATSelectedParameterGridProps, IPATSelectedParameterGridState> {
  private binPlusTableFields = [
    'id',
    'name',
    'Bin+ Table Name',
    'version',
    'state',
    'description',
    'workCenterType',
    'createdBy',
    'updatedBy',
    'updatedOn',
    'createdOn',
  ];

  private previousSelectedBinPlusTableId: string;

  constructor(props: any) {
    super(props);
    this.state = {
      binPlusTables: [],
      selectedBinPlusTableId: '',
      binPlusDefinitionGridData: [],
      binPlusGroupTypesBinTypes: [],
      changeSelectedBinPlusTableId: false,
      previousSelectedBinPlusTableName: '',
    };
    this.previousSelectedBinPlusTableId = '';
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { selectedBinPlusTableId } = this.state;
    if (prevState.selectedBinPlusTableId !== selectedBinPlusTableId) {
      this.changeBinPlusTable();
    }
  }

  getBinPlusTableIdFromRuleParameters = () => {
    const { ruleTestParameters } = this.props;
    const { selectedBinPlusTableId } = this.state;
    for (let i = 0; i < ruleTestParameters.length; i += 1) {
      if (ruleTestParameters[i].binPlusTableId) {
        return ruleTestParameters[i].binPlusTableId;
      }
    }
    return selectedBinPlusTableId;
  };

  getBinPlusGroupTypesBinTypes = async () => {
    const data: any = await httpBinPlusTable.getBinPlusGroupTypesBinTypes(null);
    data.push({
      id: null,
      binPlusTypes: [],
      name: 'NA',
    });
    if (data) return data;
    return [];
  };

  async componentDidMount() {
    const selectedBinPlusTableId = this.getBinPlusTableIdFromRuleParameters();
    let binPlusDefinitionData = null;
    let binPlusColumnsData = null;
    const newState: any = {
      binPlusTables: await httpBinPlusTable.getBinPlusTables(),
      binPlusGroupTypesBinTypes: await this.getBinPlusGroupTypesBinTypes()
        .catch(() => {
          toast.error('Could not get bin group types data.');
        }),
    };
    if (selectedBinPlusTableId) {
      binPlusDefinitionData = await httpBinPlusTable.getBinPlusDefinitions(selectedBinPlusTableId);
      binPlusColumnsData = await httpBinPlusTable.getBinPlusColumns(null, selectedBinPlusTableId);
      newState.selectedBinPlusTableId = selectedBinPlusTableId;
    }
    if (binPlusDefinitionData !== null && binPlusColumnsData !== null) {
      newState.binPlusDefinitionGridData = this.createBinPlusDefinitionGridData(binPlusDefinitionData, binPlusColumnsData);
    }
    this.setState(newState);

    this.defaultBinPlusTableName();
  }

  createBinPlusDefinitionGridData = (binPlusDefinitionData: any, binPlusColumnsData: any) => {
    if (!binPlusDefinitionData || binPlusDefinitionData.length === 0) return [];

    const columnMap: any = {};
    Object.keys(binPlusDefinitionData[0].keyValuePairs)
      .forEach((id: string) => {
        const column = binPlusColumnsData.find((obj: any) => obj.id === id);
        columnMap[id] = column.columnName;
      });

    const binPlusDefinitionGridData: any[] = binPlusDefinitionData.map((binPlusDefObj: any) => {
      const row: any = {};
      Object.keys(binPlusDefObj.keyValuePairs)
        .forEach((id: string) => {
          row[columnMap[id]] = binPlusDefObj.keyValuePairs[id];
        });
      row.id = binPlusDefObj.id;
      row.binPlusGroupType = binPlusDefObj.binPlusGroupTypeName;
      row.binPlusTableId = binPlusDefObj.binPlusTableId;
      return row;
    });
    return binPlusDefinitionGridData;
  };

  binPlusTableSelectionHandler = async (selectedRowsData: any) => {
    const { selectedBinPlusTableId, changeSelectedBinPlusTableId, previousSelectedBinPlusTableName } = this.state;

    if (changeSelectedBinPlusTableId === true) {
      if (await ModalPopup.confirm({
        header: 'Confirmation',
        body: 'Test Parameter Data will be delete on changing the Bin Plus Table. Do you want to continue? ',
      }) && selectedRowsData && selectedRowsData.length > 0) {
        const { id } = selectedRowsData[0];
        const binPlusDefinitionData = await httpBinPlusTable.getBinPlusDefinitions(id);
        const binPlusColumnsData = await httpBinPlusTable.getBinPlusColumns(null, id);
        this.alertForBinPlusTableChange();
        this.setState({
          selectedBinPlusTableId: id,
          binPlusDefinitionGridData: this.createBinPlusDefinitionGridData(binPlusDefinitionData, binPlusColumnsData),
        });
      }
    } else if (selectedRowsData && selectedRowsData.length > 0 && changeSelectedBinPlusTableId === false) {
      const { id } = selectedRowsData[0];
      const binPlusDefinitionData = await httpBinPlusTable.getBinPlusDefinitions(id);
      const binPlusColumnsData = await httpBinPlusTable.getBinPlusColumns(null, id);
      this.setState({
        selectedBinPlusTableId: id,
        binPlusDefinitionGridData: this.createBinPlusDefinitionGridData(binPlusDefinitionData, binPlusColumnsData),
        previousSelectedBinPlusTableName: 'name',
      });
    }
  };

  renderColorInCell = (e: any) => {
    return <div className="h20 mr5 mt0 ml0 mb0" style={{ backgroundColor: e.data.binPlusDefinitionColor }} />;
  };

  getSelectedBinPlusTableName = () => {
    const {
      selectedBinPlusTableId,
      binPlusTables,
    } = this.state;
    const bpt = binPlusTables.find((x: any) => {
      return x.id === selectedBinPlusTableId;
    });
    return bpt ? bpt.name : '';
  };

  staticPATCheck = () => {
    const { patRuleType } = this.props;
    if (patRuleType === 'STATIC_PAT') {
      return true;
    }
    return false;
  };

  changeBinPlusTable = () => {
    const { selectedBinPlusTableId, changeSelectedBinPlusTableId } = this.state;
    this.setState({ selectedBinPlusTableId, changeSelectedBinPlusTableId: true });
  };

  alertForBinPlusTableChange = () => {
    const { selectedBinPlusTableId, changeSelectedBinPlusTableId } = this.state;
    const { ruleTestParameters } = this.props;
    if (changeSelectedBinPlusTableId === true) {
      for (let index = 0; index < ruleTestParameters.length; index += 1) {
        ruleTestParameters[index].binPlusDefinitionColor = '';
        ruleTestParameters[index].binPlusDefinitionFlag = '';
        ruleTestParameters[index].binPlusDefinitionId = '';
        ruleTestParameters[index].binPlusDefinitionName = '';
        ruleTestParameters[index].binPlusDefinitionNumber = '';
        ruleTestParameters[index].binPlusGroupType = '';
      }
    }
    return ruleTestParameters;
  };

  defaultBinPlusTableName = () => {
    const { ruleTestParameters } = this.props;
    if (ruleTestParameters.length > 0) {
      this.previousSelectedBinPlusTableId = ruleTestParameters[0].binPlusTableId;
      const previousId = this.previousSelectedBinPlusTableId;
      const { binPlusTables, previousSelectedBinPlusTableName, changeSelectedBinPlusTableId } = this.state;
      if (binPlusTables.length > 0) {
        for (let index = 0; index < binPlusTables.length; index += 1) {
          if (binPlusTables[index].id === previousId) {
            // return binPlusTables[index].name;
            this.setState({ previousSelectedBinPlusTableName: binPlusTables[index].name });
          }
        }
      }
    }
  };

  render() {
    const {
      binPlusTables,
      selectedBinPlusTableId,
      binPlusDefinitionGridData,
      binPlusGroupTypesBinTypes,
      changeSelectedBinPlusTableId,
      previousSelectedBinPlusTableName,
    } = this.state;

    const {
      ruleTestParameters,
      binConfigSelectionHandler,
      removeField,
      patRuleType,
    } = this.props;

    return (
      <div className="p10 pb30 mb20">
        <div className="flex-20 mt10">
          <Heading size={5}>Selected Test Parameters</Heading>
        </div>
        <div className="mt20 mb20 dx-field w-70">
          <div className="dx-field-label">
            <pre>
              Robust Mean + K * Robust Sigma
            </pre>
          </div>
          <div className="dx-field-value border-all background-color-light">
            <CustomizedGridDropDown
              list={binPlusTables}
              placeholder={this.getBinPlusTableIdFromRuleParameters() ? this.getSelectedBinPlusTableName() : 'Select Bin Plus Table...'}
              selectedRowKeys={[selectedBinPlusTableId]}
              columns={this.binPlusTableFields}
              selectionMode="single"
              displayExpr={previousSelectedBinPlusTableName}
              valueExpr="id"
              selectionHandler={this.binPlusTableSelectionHandler}
              showApplyButton
            />
          </div>
        </div>
        <DataGrid
          height={300}
          showBorders
          showRowLines
          showColumnLines={false}
          dataSource={ruleTestParameters}
          keyExpr="id"
        >
          <Paging enabled defaultPageSize={15} />
          <Editing allowUpdating mode="cell" />
          <Scrolling mode="standard" />
          {/* <Column allowEditing={false} alignment="center" caption="Alias" dataField="alias" dataType="string" /> */}
          <Column allowEditing={false} alignment="center" caption="Test #" dataField="testParameterNumber" dataType="string" />
          <Column allowEditing={false} alignment="center" caption="Test Name" dataField="testParameterName" dataType="string" />

          <Column
            alignment="center"
            caption="UOM"
            dataField="inputUom"
            dataType="string"
            allowEditing
            editCellRender={(cell: any) => {
              return (
                <SelectBox
                  defaultValue={cell.value}
                  items={['Nano', 'Micro', 'Milli', 'Kilo', 'Mega', 'Giga']}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cell.column.lookup}
                  /* eslint-disable no-param-reassign */
                  onValueChanged={(e: any) => {
                    cell.setValue(e.value);
                  }}
                  dropDownOptions={getDropDownOptions('cell', cell)}
                />
              );
            }}
          />
          <Column allowEditing={false} alignment="center" caption="Site Number" dataField="siteNumber" dataType="number" />
          <Column caption="Control Limit">
            <Column allowEditing={false} alignment="center" caption="Low" dataField="testParameterLowCtrlLimit" />
            <Column allowEditing={false} alignment="center" caption="High" dataField="testParameterHighCtrlLimit" />
          </Column>
          <Column caption="Spec Limit">
            <Column allowEditing={false} alignment="center" caption="Low" dataField="testParameterLowSpecLimit" />
            <Column allowEditing={false} alignment="center" caption="High" dataField="testParameterHighSpecLimit" />
          </Column>
          <Column caption="Value of K">
            <Column allowEditing alignment="center" caption="Low" dataField="lowKLimit" />
            <Column
              allowEditing
              alignment="center"
              caption="High"
              dataField="highKLimit"
            />
          </Column>
          <Column caption="Seed Limit" visible={this.staticPATCheck()}>
            <Column
              allowEditing
              alignment="center"
              caption="Low"
              dataField="seedLowLimit"
              visible={this.staticPATCheck()}
            />
            <Column
              allowEditing
              alignment="center"
              caption="High"
              dataField="seedHighLimit"
              visible={this.staticPATCheck()}
            />
          </Column>
          <Column
            alignment="center"
            caption="Bin Group Type"
            dataField="binPlusGroupType"
            dataType="string"
            allowEditing
            editCellRender={(cell: any) => {
              return (
                <SelectBox
                  valueExpr="name"
                  displayExpr="name"
                  defaultValue={cell.value}
                  items={binPlusGroupTypesBinTypes}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cell.column.lookup}
                  /* eslint-disable no-param-reassign */
                  onValueChanged={(e: any) => {
                    cell.setValue(e.value);
                    cell.data.binPlusDefinitionId = null;
                    // cell.data.binPlusGroupType = value;
                    cell.data.binPlusDefinitionNumber = '';
                    cell.data.binPlusDefinitionName = '';
                    cell.data.binPlusDefinitionColor = '';
                  }}
                  dropDownOptions={getDropDownOptions('cell', cell)}
                />
              );
            }}
          />
          <Column
            caption="Bin Configuration"
            allowEditing
            alignment="center"
            dataField="binPlusDefinitionId"
            dataType="string"
            editCellComponent={withProps(BinConfigDropDownBoxComponent, {
              parentSelectionHandler: binConfigSelectionHandler,
            })}
          >
            <Lookup
              dataSource={binPlusDefinitionGridData}
              displayExpr="name"
              valueExpr="id"
            />
          </Column>
          <Column
            allowEditing={false}
            alignment="center"
            caption="Bin #"
            dataField="binPlusDefinitionNumber"
            dataType="string"
          />
          <Column
            allowEditing={false}
            alignment="center"
            caption="Bin Name"
            dataField="binPlusDefinitionName"
            dataType="string"
          />
          <Column
            allowEditing={false}
            alignment="center"
            caption="Bin Flag"
            dataField="binPlusDefinitionFlag"
            dataType="string"
          />
          <Column
            allowEditing={false}
            alignment="center"
            caption="Bin Color"
            dataField="binPlusDefinitionColor"
            dataType="string"
            cellRender={this.renderColorInCell}
          />
          <Column type="buttons" width={40}>
            <DxButton
              hint="Delete"
              icon="close"
              cssClass="text-danger"
              onClick={removeField}
            />
          </Column>
        </DataGrid>
      </div>
    );
  }
}

export default PATSelectedParameterGrid;
