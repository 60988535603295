/* eslint-disable react/prefer-stateless-function */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import LimitSettingsModal from 'components/wrapped-component/limit-settings-modal/limitSettingsModal';
import React from 'react';
import {
  Button, Modal, Tab, Tabs, Row, Col, Container,
} from 'react-bootstrap';
import toast from 'CustomToast';
import { faCheck, faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { IGenericDetailedReportRequestObject } from 'interfaces';
import { NumberBox, Slider } from 'devextreme-react';
import { ReportTypeConstants as ReportType } from 'GeneralUtils';
import { ICustomizedReportState } from '../CustomizedReport';
import { AggregateFunctionNames, SeriesTypeNames, } from '../customized-report-helpers/CustomizedReportInterfaces';
import AxisDropdowns from './AxisDropdowns';
import { httpLimitSettings } from '../../../../services/http.limit-settings';
import LimitSettingsData from '../../../../components/wrapped-component/limit-settings-modal/LimitSettingsData';

export interface IReportOptionsModalState {
  showModal: boolean,
  showAggregateFunctionsOptions: boolean,
  showAxisDropdownOptions: boolean,
  showSeriesTypeOptions: boolean,
  showBinTypeSwitchingOptions: boolean,
  showAxisBreakThresholdFactorOptions: boolean,
  genericLimitSettingsState: any,
  currentSeriesType: SeriesTypeNames;
  currentAggregateFunction: AggregateFunctionNames | 'None';
  currentAxisDropdown?: number[],
  currentBinType: boolean,
  currentAxisBreakThresholdFactor?: number,
  isSeriesTypeForceSwitched: boolean,
}

export interface IReportOptionsModalProps {
  reportActor: string,
  groupingSortingListStore: any,
  selectionStore: any,
  testParameterIndex: number[],
  testParameterValues: any[],
  parentState: ICustomizedReportState,
  setParentStateCallback: (obj: any)=> void,
  applyLimitSettings: (limitOptions: any, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any)=> void,
  requestObject?: IGenericDetailedReportRequestObject,
  hideGeneralOptions?: boolean,
  hideLimitSettings?: boolean,
}

class ReportOptionsModal extends React.Component<IReportOptionsModalProps, IReportOptionsModalState> {
  constructor(props:IReportOptionsModalProps) {
    super(props);
    this.state = {
      isSeriesTypeForceSwitched: false,
      showAggregateFunctionsOptions: true,
      showSeriesTypeOptions: true,
      showAxisDropdownOptions: false,
      showBinTypeSwitchingOptions: false,
      showAxisBreakThresholdFactorOptions: true,
      genericLimitSettingsState: undefined,
      currentSeriesType: props.parentState.seriesType,
      currentAggregateFunction: props.parentState.aggredateFunction,
      currentBinType: props.parentState.isSoftBin,
      currentAxisBreakThresholdFactor: props.parentState.axisBreakThresholdFactor,
      currentAxisDropdown: _.cloneDeep(props.parentState.testParameterIndex),
      showModal: false,
    };
  }

  componentDidMount() {
    const { reportActor, requestObject } = this.props;
    if (reportActor === ReportType.PARAMETRIC_BOX_PLOT || reportActor === ReportType.PARAMETRIC_HISTOGRAM) {
      this.setState({ showAggregateFunctionsOptions: false, showSeriesTypeOptions: false });
    }
    if (reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) {
      this.setState({ showAxisDropdownOptions: true });
    }
    if (reportActor === ReportType.BIN_PARETO || reportActor === ReportType.BIN_WAFER_MAP || reportActor === ReportType.BIN_WAFER_MAP_COMPARISON) {
      this.setState({ showAggregateFunctionsOptions: false, showSeriesTypeOptions: false, showBinTypeSwitchingOptions: true });
    }
    if (reportActor === ReportType.BIN_WAFER_MAP || reportActor === ReportType.PARAMETRIC_WAFER_MAP || reportActor === ReportType.BIN_WAFER_MAP_COMPARISON) {
      this.setState({ showAxisBreakThresholdFactorOptions: false });
    }
  }

  componentDidUpdate(prevProps: IReportOptionsModalProps) {
    const { parentState } = this.props;
    if (JSON.stringify(prevProps.parentState) !== JSON.stringify(parentState)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentSeriesType: parentState.seriesType,
        currentAggregateFunction: parentState.aggredateFunction,
        currentBinType: parentState.isSoftBin,
        currentAxisBreakThresholdFactor: parentState.axisBreakThresholdFactor,
        currentAxisDropdown: _.cloneDeep(parentState.testParameterIndex),
      });
    }
  }

  updateTabUpdationCount = (parentState: any, setParentStateCallback: any) => {
    setParentStateCallback({ updatedTabCount: parentState.updatedTabCount + 1 });
  }

  getAggregateFunctionDropdownList = () => {
    const { groupingSortingListStore } = this.props;
    let aggregateFunctionDropdownList = [];
    if (groupingSortingListStore.grouping.length > 0) {
      aggregateFunctionDropdownList = [
        ['NONE', 'NONE'],
        ['AVG', 'AVG'],
        ['MEDIAN', 'MEDIAN'],
        ['STDDEV', 'STDDEV'],
        ['CP', 'CP'],
        ['CPK', 'CPK'],
      ];
    } else {
      aggregateFunctionDropdownList = [['None', 'None']];
    }
    return aggregateFunctionDropdownList;
  };

  getSeriesTypeDropdownList = () => {
    const { reportActor } = this.props;
    const { currentAggregateFunction } = this.state;
    let seriesTypeDropdownList = [];
    if (reportActor !== ReportType.PARAMETRIC_XY_SCATTER_PLOT
      || (reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT && (currentAggregateFunction === 'NONE' || currentAggregateFunction === 'None'))) {
      seriesTypeDropdownList = [
        ['SEPARATE', 'SEPARATE'],
        ['SINGLE', 'SINGLE'],
      ];
    } else {
      seriesTypeDropdownList = [
        ['SINGLE', 'SINGLE'],
      ];
    }
    return seriesTypeDropdownList;
  };

  appendToParentState = (showProperty: boolean, propertyName: string, newValue: any, updatedParentState: any) => {
    if (showProperty) {
      const valueObj: any = {};
      valueObj[propertyName] = newValue;
      // eslint-disable-next-line no-param-reassign
      updatedParentState = Object.assign(updatedParentState, valueObj);
    }
    return updatedParentState;
  };

  updateParentState = () => {
    const { hideGeneralOptions, setParentStateCallback } = this.props;
    const {
      currentAggregateFunction, currentSeriesType, currentAxisDropdown, currentBinType, currentAxisBreakThresholdFactor,
      showAggregateFunctionsOptions, showSeriesTypeOptions, showAxisDropdownOptions, showBinTypeSwitchingOptions, showAxisBreakThresholdFactorOptions,
    } = this.state;
    let updatedParentState: any = {};
    const hideGeneralOptionsTab = !!(hideGeneralOptions && hideGeneralOptions === true);
    if (!hideGeneralOptionsTab) {
      updatedParentState = this.appendToParentState(showAggregateFunctionsOptions, 'aggredateFunction', currentAggregateFunction, updatedParentState);
      updatedParentState = this.appendToParentState(showSeriesTypeOptions, 'seriesType', currentSeriesType, updatedParentState);
      updatedParentState = this.appendToParentState(showAxisDropdownOptions, 'testParameterIndex', currentAxisDropdown, updatedParentState);
      updatedParentState = this.appendToParentState(showBinTypeSwitchingOptions, 'isSoftBin', currentBinType, updatedParentState);
      updatedParentState = this.appendToParentState(showAxisBreakThresholdFactorOptions, 'axisBreakThresholdFactor', currentAxisBreakThresholdFactor, updatedParentState);
      setParentStateCallback(updatedParentState);
      this.setState({
        currentAxisDropdown: _.cloneDeep(currentAxisDropdown),
        showModal: false,
      });
    }
  };

  render() {
    const {
      isSeriesTypeForceSwitched, currentAggregateFunction, currentSeriesType, currentAxisDropdown, currentBinType, currentAxisBreakThresholdFactor,
      showAggregateFunctionsOptions, showSeriesTypeOptions, showAxisDropdownOptions, showBinTypeSwitchingOptions, showAxisBreakThresholdFactorOptions,
      genericLimitSettingsState, showModal } = this.state;
    const {
      reportActor, groupingSortingListStore, selectionStore, testParameterValues, applyLimitSettings, setParentStateCallback, parentState, requestObject, hideGeneralOptions, hideLimitSettings,
    } = this.props;
    const hideGeneralOptionsTab = !!(hideGeneralOptions && hideGeneralOptions === true);
    const hideLimitSettingsTabs = !!(hideLimitSettings && hideLimitSettings === true);
    return (
      <>
        <div className="mr10 rounded">
          <Button variant="success" onClick={() => this.setState({ showModal: !showModal })}>
            <FontAwesomeIcon
              className="color-dark mr6"
              icon={faEllipsisH}
              size="1x"
            />
            Report Options
          </Button>
        </div>
        <Modal dialogClassName="report-options-modal" show={showModal} size="xl" scrollable>
          <Modal.Header>
            <Modal.Title as="h3">Report Options Chooser</Modal.Title>
            <span className="float-right">
              <Button variant="secondary" onClick={() => this.setState({ showModal: !showModal })}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faTimes}
                />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="w-100 h-100">
            <Tabs
              defaultActiveKey={`${hideGeneralOptionsTab ? 'genericLimitSettings' : 'generalOptions'}`}
              id="report-options-tabs"
              className="mb3 ml10"
            >
              {!hideGeneralOptionsTab && (
                <Tab eventKey="generalOptions" title="General Options">
                  <Container fluid className="border-all pl10 pr10 pt10 pb10 background-color-light border-radius-4" style={{ overflow: 'visible', minHeight: '200px' }}>
                    {showAggregateFunctionsOptions && (
                      <Row className="d-flex mb10">
                        <Col><h6 className="ml5 mt7 mr10">Aggregate Function</h6></Col>
                        <Col>
                          <CustomizedDropdown
                            variant="light"
                            full
                            disabled={groupingSortingListStore.grouping.length === 0}
                            list={this.getAggregateFunctionDropdownList()}
                            dropdownButtonClass="border-all"
                            selectedValue={currentAggregateFunction?.toString()}
                            onChange={(value: string) => {
                              if ((reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT || reportActor === ReportType.PARAMETRIC_TREND)
                                && currentSeriesType === 'SEPARATE' && value !== 'NONE' && value !== 'None') {
                                this.setState({ currentAggregateFunction: value.toString() as AggregateFunctionNames, currentSeriesType: 'SINGLE', isSeriesTypeForceSwitched: true });
                                toast.warning('Series type switched to SINGLE, as SEPARATE is unavailable with current aggregation option');
                              } else if (isSeriesTypeForceSwitched && value.toString() === 'NONE') {
                                this.setState({ currentAggregateFunction: value.toString() as AggregateFunctionNames, currentSeriesType: 'SEPARATE', isSeriesTypeForceSwitched: false });
                                toast.warning('Series type switched back to SEPARATE');
                              } else {
                                this.setState({ currentAggregateFunction: value.toString() as AggregateFunctionNames });
                              }
                            }}
                          />
                        </Col>
                        <Col />
                        <Col />
                      </Row>
                    )}
                    {showSeriesTypeOptions && (
                      <Row className="d-flex mb10">
                        <Col><h6 className="ml5 mt7 mr20">Series Type</h6></Col>
                        <Col>
                          <CustomizedDropdown
                            variant="light"
                            full
                            list={this.getSeriesTypeDropdownList()}
                            dropdownButtonClass="border-all"
                            selectedValue={currentSeriesType.toString()}
                            onChange={(value: string) => {
                              this.setState({ currentSeriesType: value.toString() as SeriesTypeNames });
                            }}
                          />
                        </Col>
                        <Col />
                        <Col />
                      </Row>
                    )}
                    {showAxisDropdownOptions && (
                      <AxisDropdowns
                        color="#ffffff"
                        selectionStore={selectionStore}
                        testParameterIndex={currentAxisDropdown}
                        applyNewAxis={(updatedTestParameterIndex: any) => {
                          let newTestParameterIndex = [];
                          newTestParameterIndex = _.cloneDeep(updatedTestParameterIndex);
                          this.setState({ currentAxisDropdown: newTestParameterIndex });
                        }}
                      />
                    )}
                    {showBinTypeSwitchingOptions && (
                      <Row className="d-flex mb10">
                        <Col><h6 className="ml5 mt7 mr10">Bin Type</h6></Col>
                        <Col>
                          <CustomizedDropdown
                            variant="light"
                            full
                            list={[
                              ['SOFTBIN', 'SOFTBIN'],
                              ['HARDBIN', 'HARDBIN'],
                            ]}
                            dropdownButtonClass="border-all"
                            selectedValue={currentBinType ? 'SOFTBIN' : 'HARDBIN'}
                            onChange={(value: string) => {
                              this.setState({ currentBinType: value.toString() === 'SOFTBIN' });
                            }}
                          />
                        </Col>
                        <Col />
                        <Col />
                      </Row>
                    )}
                    {showAxisBreakThresholdFactorOptions && (
                      <Row className="d-flex mb10">
                        <Col><h6 className="ml5 mt7 mr10">Axis Break Threshold</h6></Col>
                        <Col>
                          <Slider
                            label={{ position: 'bottom', visible: true }}
                            min={10}
                            max={100}
                            value={currentAxisBreakThresholdFactor}
                            onValueChanged={(e: any) => {
                              this.setState({ currentAxisBreakThresholdFactor: e.value });
                            }}
                          />
                        </Col>
                        <Col>
                          <div className="d-flex vert-align-content">
                            <NumberBox
                              className="vert-align-content"
                              min={10}
                              max={100}
                              showSpinButtons
                              value={currentAxisBreakThresholdFactor}
                              onValueChanged={(e: any) => {
                                this.setState({ currentAxisBreakThresholdFactor: e.value });
                              }}
                            />
                            <div className="ml5 vert-align-content">%</div>
                          </div>
                        </Col>
                        <Col />
                      </Row>
                    )}
                    <Row>
                      <Col />
                      <Col />
                      <Col />
                      <Col>
                        <Button
                          className="float-right mr20"
                          variant="success"
                          size="lg"
                          onClick={() => {
                            this.updateParentState();
                            toast.success('Report Options updated');
                          }}
                        >
                          <FontAwesomeIcon className="mr10" size="sm" icon={faCheck} />
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              )}
              {!hideLimitSettingsTabs && (
                <Tab eventKey="genericLimitSettings" title="Generic Limit Settings" mountOnEnter className="background-color-light">
                  <LimitSettingsModal
                    key="Generic Limit Settings"
                    name="Generic Limit Settings"
                    differentUOM={false}
                    testParameters={testParameterValues}
                    updatedTabCount={parentState.updatedTabCount}
                    updateTabUpdatedCount={() => { this.updateTabUpdationCount(parentState, setParentStateCallback); }}
                    applyLimitSettings={(limitOptions: any, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => {
                      this.setState({ showModal: false });
                      applyLimitSettings(limitOptions, testParameterSelectedIndex, testParameterIndexForWhichLimitsApplied, errors);
                    }}
                    requestObject={requestObject}
                    genericLimitSettingsState={genericLimitSettingsState}
                    retainGenericLimitSettingsState={(genericLimitSettingsStateReceived: any) => {
                      this.setState({ genericLimitSettingsState: genericLimitSettingsStateReceived });
                    }}
                    testParameterIndexProps={parentState.testParameterIndex || []}
                  />
                </Tab>
              )}
              {!hideLimitSettingsTabs && (
                <Tab eventKey="savedLimitSettings" title="Saved Limit Settings" mountOnEnter className="background-color-light">
                  <LimitSettingsModal
                    key="Saved Limit Settings"
                    name="Saved Limit Settings"
                    differentUOM={false}
                    testParameters={testParameterValues}
                    updatedTabCount={parentState.updatedTabCount}
                    updateTabUpdatedCount={() => { this.updateTabUpdationCount(parentState, setParentStateCallback); }}
                    applyLimitSettings={(limitOptions: any, testParameterSelectedIndex: number, testParameterIndexForWhichLimitsApplied: number, errors: any) => {
                      this.setState({ showModal: false });
                      applyLimitSettings(limitOptions, testParameterSelectedIndex, testParameterIndexForWhichLimitsApplied, errors);
                    }}
                    requestObject={requestObject}
                    retainGenericLimitSettingsState={(genericLimitSettingsStateReceived: any) => {
                      this.setState({ genericLimitSettingsState: genericLimitSettingsStateReceived });
                    }}
                    testParameterIndexProps={parentState.testParameterIndex || []}
                  />
                </Tab>
              )}
              {/* TODO: Add tab here when CLM is started */}
              {/* <Tab eventKey="customizedLimitSettings" title="Customized Limit Settings" disabled>
            </Tab> */}
            </Tabs>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ReportOptionsModal;
