import React, { Component } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import PlotlyChartDesigner from 'components/reports/plotly-designer/PlotlyChartDesigner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import WidgetCard from 'components/template-component/widget-card/WidgetCard';
import Heading from '../../wrapped-component/hint-controls/Heading';
import ReportHeaderLandingArea from '../../utility-component/report-headers/ReportHeaderLandingArea';
import { httpService } from '../../../services/http.service';
import { IPolicyStep } from '../../../interfaces';

interface IPolicySummaryStatsGraphProps {
  policyResult: any;
  selectedStageId: string;
  showGrid: any [];
}

interface IPolicySummaryStatsGraphState {
  showLegend: boolean;
  showSummaryGraph: boolean;
  summaryStatsGraphReportHeader: any;
}

class PolicySummaryStatsGraph extends Component<IPolicySummaryStatsGraphProps, IPolicySummaryStatsGraphState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showLegend: false,
      showSummaryGraph: false,
      summaryStatsGraphReportHeader: undefined,
    };
  }

  componentDidMount() {
    const { policyResult } = this.props;
    if (policyResult.type === 'PAT') {
      this.checkShowGrid();
      this.getSummaryStatsGraphReportHeader();
    }
  }

  getSummaryStatsGraphReportHeader = () => {
    const { policyResult } = this.props;
    const { summaryStatsGraphReportHeader } = this.state;
    const reportHeaderData = {
      lotName: '',
      probeCount: '',
      deviceName: ''
    };

    httpService.getSummaryStatsReportHeader(policyResult.outputWaferId).then((data:any) => {
      reportHeaderData.lotName = data.lotName;
      reportHeaderData.probeCount = data.probeCount;
      reportHeaderData.deviceName = data.deviceName;
      this.setState({ summaryStatsGraphReportHeader: reportHeaderData });
    });
  };

  generateDataSource = (policyResult: any) => {
    const { selectedStageId } = this.props;
    const policyResultSummary: any[] = [];
    if (policyResult.policyStepId === selectedStageId) {
      policyResult.ruleSetResult.forEach((ruleSetResultObj: any) => {
        ruleSetResultObj.ruleResult.forEach((ruleResultObj: any) => {
          ruleResultObj.testParameterResult.forEach((testParameterResultObj: any) => {
            Object.keys(testParameterResultObj.siteWiseLimits)
              .forEach((site) => {
                policyResultSummary.push({
                  policyStageType: policyResult.type,
                  ruleName: ruleResultObj.rule.name,
                  testParameterNumber: testParameterResultObj.testParameter.number,
                  testParameterName: testParameterResultObj.testParameter.name,
                  siteNumber: site,
                  highLimit: testParameterResultObj.siteWiseLimits[site][testParameterResultObj.inputWaferId].highLimit,
                  lowLimit: testParameterResultObj.siteWiseLimits[site][testParameterResultObj.inputWaferId].lowLimit,
                  inputDies: testParameterResultObj.inputDieCount,
                  dieChangeCount: testParameterResultObj.dieChangeCount,
                  outputDies: testParameterResultObj.outputDieCount,
                  yieldLoss: `${testParameterResultObj.yieldLoss}%`,
                  waferKey: testParameterResultObj.waferKey,
                });
              });
          });
        });
      });
    }
    return policyResultSummary;
  }

  toggleShowLegend = () => {
    const { showLegend } = this.state;
    this.setState({ showLegend: !showLegend });
  };

  renderColorInCell = (e: any) => {
    return <div className="center-align h20 w20" style={{ backgroundColor: e.data.ruleColor }} />;
  };

  getGraphData = () => {
    const { policyResult } = this.props;
    const responseObj: any[] = [];
    let yaxis = '';
    if (Object.keys(policyResult).length > 0) {
      const policyResultSummary = this.generateDataSource(policyResult);
      const chartType: string [] = ['bar', 'bar', 'bar', 'scatter'];
      const color: string [] = ['#d9b3ff', '#ff80bf', '#00b300', '#cc6600'];
      const yValTypes: string[] = ['inputDies', 'dieChangeCount', 'outputDies', 'yieldLoss'];
      const name: string [] = ['Input Dies', 'Die Change Count', 'Output Dies', 'Yield Loss Percentage'];

      for (let index = 0; index < 4; index += 1) {
        const xVals: string [] = [];
        const yVals: number [] = [];

        // eslint-disable-next-line no-loop-func
        policyResultSummary.forEach((rule: any) => {
          xVals.push(rule.testParameterName);
          if (index === 3) {
            yaxis = 'y2';
            yVals.push(rule[yValTypes[index]]);
          } else if (index === 1) {
            yVals.push(rule.dieChangeCount);
          } else {
            yVals.push(rule[yValTypes[index]]);
          }
        });

        responseObj.push({
          x: xVals,
          y: yVals,
          yaxis,
          type: chartType[index],
          name: name[index],
          mode: chartType[index] === 'bar' ? 'markers' : 'lines+markers',
          marker: {
            opacity: 1,
            color: color[index],
          },
        });
      }
    }
    return responseObj;
  };

  // eslint-disable-next-line consistent-return
  getLegendData = () => {
    const { policyResult } = this.props;

    const responseObj: any[] = [];

    if (Object.keys(policyResult).length > 0) {
      const policyResultSummary = this.generateDataSource(policyResult);
      const color: string [] = ['#d9b3ff', '#ff80bf', '#00b300', '#cc6600'];
      const yValTypes: string[] = ['inputDies', 'dieChangeCount', 'outputDies', 'yieldLoss'];
      const columnHeaderName: string [] = ['Input Dies', 'Die Change Count', 'Output Dies', 'Yield Loss Percentage'];

      policyResultSummary.forEach((rule: any) => {
        for (let columnType = 0; columnType < 4; columnType += 1) {
          let columnVal = 0;
          let ruleColor = '';
          let info = '';

          if (columnType === 1) {
            columnVal = rule.dieChangeCount;
          } else {
            columnVal = rule[yValTypes[columnType]];
          }

          ruleColor = color[columnType];
          info = columnHeaderName[columnType];

          responseObj.push({
            // ruleName: rule.ruleName,
            testParameterName: rule.testParameterName,
            ruleColor,
            columnVal,
            info,
          });
        }
      });
      return responseObj;
    }
  };

  checkShowGrid = () => {
    const {
      showGrid,
      policyResult
    } = this.props;
    if (showGrid.includes('PATGrids') && policyResult.type === 'PAT') {
      this.setState({ showSummaryGraph: true });
    }
  }

  render() {
    const {
      showLegend,
      showSummaryGraph,
      summaryStatsGraphReportHeader,
    } = this.state;
    const {
      policyResult,
    } = this.props;

    return Object.keys(policyResult).length > 0 && summaryStatsGraphReportHeader !== undefined
      ? (
        showSummaryGraph && (
          <div className="h1200">
            <Row>
              <Col lg={12}>
                <Heading size={6}>Policy Summary Stats Graph</Heading>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="mt20">
                  <ReportHeaderLandingArea
                    reportHeaderSettings={{
                      name: 'Something',
                      titleFontSize: 18,
                      fieldFontSize: 12,
                      displayFieldsCount: 9,
                      rowCount: 3,
                      columnCount: 3,
                      itemsAlignment: 'LEFT',
                      titleTemplate: 'Default Report Header',
                      title: 'Summary Stats Visualization Report Header',
                      titleExpression: [],
                      fields: [
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: summaryStatsGraphReportHeader.deviceName,
                              value: 'Demo Device',
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'Device',
                          tableName: 'device',
                          columnName: 'name',
                          sequence: 0,
                          isComputedField: false,
                          isPolicyStepField: false,
                          computedFieldName: 'name',
                        },
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: 'PAT',
                              value: 'STAGE',
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'STAGE',
                          tableName: 'stage',
                          columnName: 'name',
                          sequence: 1,
                          isComputedField: false,
                          isPolicyStepField: false,
                          computedFieldName: 'name',
                        },
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: '1',
                              value: 'Wafer Count',
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'Wafer Count',
                          tableName: 'wafer count',
                          columnName: 'name',
                          sequence: 2,
                          isComputedField: false,
                          isPolicyStepField: false,
                          computedFieldName: 'name',
                        },
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: summaryStatsGraphReportHeader.probeCount,
                              value: 1,
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'Probe_Count',
                          tableName: 'wafer',
                          columnName: 'probe_count',
                          sequence: 3,
                          isComputedField: false,
                          isPolicyStepField: false,
                          computedFieldName: 'probe_count',
                        },
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: summaryStatsGraphReportHeader.lotName,
                              value: 'Lot Name',
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'Lot Name',
                          tableName: 'lot name',
                          columnName: 'name',
                          sequence: 0,
                          isComputedField: false,
                          isPolicyStepField: false,
                          computedFieldName: 'name',
                        },
                        {
                          graphType: 'null',
                          fieldLabel: '',
                          fieldValues: [
                            {
                              formatValue: 'N/A',
                              value: 'null',
                              label: '',
                              name: 'null',
                              color: 'null',
                            },
                          ],
                          entityType: 'SUMMARY_STATISTICS',
                          tableName: '',
                          columnName: 'MEAN',
                          sequence: 4,
                          isComputedField: true,
                          isPolicyStepField: false,
                          computedFieldName: 'MEAN',
                        },
                      ],
                      userId: 'null',
                      collapseHeader: true,
                    }}
                    reportIndex={-1}
                    updatedHeaderId=""
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="d-flex align-items-center justify-content-between mt10 mb20">
                  <div className="d-flex align-items-center justify-content-between" />
                  <div>
                    <Button
                      style={{
                        right: '4px',
                        top: '6px',
                        height: '35px',
                        width: showLegend ? '35px' : '100px',
                        borderRadius: '0px',
                      }}
                      onClick={this.toggleShowLegend}
                      className="position-absolute"
                      variant="dark"
                    >
                      {!showLegend && (
                        <>
                          <FontAwesomeIcon
                            size="lg"
                            icon={faChevronLeft}
                          />
                          <span className="m8">
                            Legend
                          </span>
                        </>
                      )}
                      {showLegend && (
                        <FontAwesomeIcon
                          size="lg"
                          icon={faChevronRight}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={showLegend ? 7 : 12} className="pt50">
                <div className="mt10 h-100 w-100">
                  <PlotlyChartDesigner
                    showBorder={false}
                    onClick={() => {
                    }}
                    dataSource={Object.keys(policyResult).length > 0 ? this.generateDataSource(policyResult) : []}
                    dataSourceOptions={[]}
                    className="combined-bin-histogram-report"
                    useResizeHandler
                    data={
                      this.getGraphData()
                    }
                    config={{
                      responsive: true,
                    }}
                    layout={{
                      barmode: 'group',
                      autosize: true,
                      height: 690,
                      showlegend: false,
                      margin: {
                        b: 120,
                        t: 0,
                        l: 120,
                        pad: 20,
                      },
                      xaxis: {
                        title: {
                          text: 'Test Parameters',
                        },
                      },
                      yaxis: {
                        title: {
                          text: 'Die Count',
                        },
                      },
                      yaxis2: {
                        title: 'Yield Loss Percentage',
                        titlefont: { color: 'rgb(148, 103, 189)' },
                        tickfont: { color: 'rgb(148, 103, 189)' },
                        overlaying: 'y',
                        side: 'right',
                      },
                    }}
                  />
                </div>
              </Col>
              {
                showLegend && (
                  <Col lg={5} className="h100">
                    <WidgetCard
                      fullWidth
                      linkToggle={false}
                      expandToggle
                      changeExpandedStateComponentOnUpdate={false}
                      pinWidgetByDefault={false}
                      id="combined_bin_histogram_legend"
                      widgetName="Policy Summary Stats Legend"
                      primaryWidgetWeight={12}
                      changeModalClass
                      primaryWidget={(
                        <div className="w-100">
                          <CustomizedDataGrid
                            height={400}
                            tableData={
                              this.getLegendData()
                            }
                            fields={[
                              {
                                caption: 'Test Parameter Name',
                                dataField: 'testParameterName',
                                dataType: 'text',
                                groupIndex: 1,
                              },
                              {
                                caption: 'Color',
                                dataField: 'ruleColor',
                                dataType: 'color',
                                cellRender: (e: any) => {
                                  return this.renderColorInCell(e);
                                },
                              },
                              {
                                caption: 'Type',
                                dataField: 'info',
                                dataType: 'text',
                              },
                              {
                                caption: 'Value',
                                dataField: 'columnVal',
                                dataType: 'number',
                              },
                            ]}
                            scrollMode="virtual"
                            showFilterRow
                            showContextMenu={false}
                          />
                        </div>
                      )}
                    />
                  </Col>
                )
              }
            </Row>
          </div>
        )) : (
        showSummaryGraph && (
          <div className="">
            <Row>
              <Col lg={12}>
                <Heading size={6} className="d-flex align-items-center rounded mb0 p4 h30 background-color-secondary color-light">
                  Policy Summary Stats Visualization
                </Heading>
              </Col>
              <Col lg={12} className="mt10 ml2">
                <Heading size={5}>No data found</Heading>
              </Col>
            </Row>
          </div>
        )
      );
  }
}

export default PolicySummaryStatsGraph;
