import React from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import './customized-tabs.scss';

interface ICustomizedTabsProps {
  tabItems: [];
  className?: string;
}

const CustomizedTabs = (props: any) => {
  const {
    tabItems,
    className
  } = props;
  const tabs = tabItems.map((item: any) => (
    <Tab
      key={item.eventKey}
      eventKey={item.eventKey}
      title={item.title}
      mountOnEnter
    >
      {item.content}
    </Tab>
  ));

  return (
    <Tabs className={className}>
      {tabs}
    </Tabs>
  );
};

CustomizedTabs.defaultProps = {
  tabItems: [],
  className: '',
};

export default CustomizedTabs;
