/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import { Tooltip } from 'devextreme-react/tooltip';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { TagBox } from 'devextreme-react';
import { COORDINATE_SYSTEMS } from 'components/utility-component/wafer-map-widget/wafer-map/WaferMap';
import _ from 'lodash';
import './wafer-control-map.scss';
import { addErrorClasses, ErrorLabel } from 'components/template-component/form-error-label/ErrorLabel';
import toast from 'CustomToast';
// eslint-disable-next-line no-unused-vars
import { XYPoint } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Types';
import { WaferAxisDirection } from 'components/utility-component/wafer-map-widget/wafer-map/web-gl-utils/Enums';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import { WAFER_AXIS_DIRECTION } from './wafer-control-map';

export type HomeDieCoordinateSystemFormData = {
  [key: string]: any,
  coordinateSystems: string [],
  standardX: number,
  standardY: number,
  row: number,
  col: number,
  subRow: number,
  subCol: number,
  waferAxisDirection: WaferAxisDirection,
};

type HomeDieCoordsFormProps = {
  data?: HomeDieCoordinateSystemFormData,
  reticleSize?: XYPoint,
};

type HomeDieCoordsFormState = {
  show: boolean,
  data: HomeDieCoordinateSystemFormData,
  formErrors: { [key: string] : any },
  reticleSize: XYPoint | undefined,
};

let instance: HomeDieCoordsForm;

export default class HomeDieCoordsForm extends React.Component<HomeDieCoordsFormProps, HomeDieCoordsFormState> {
  public static async openForm(props: HomeDieCoordsFormProps) {
    if (!instance) return true;
    return instance.showForm(props);
  }

  yesButton: any = null;

  noButton: any = null;

  homeDieTooltipEL: Tooltip | null = null;

  COORDINATE_SYSTEM_VALUES: string[] = [];

  constructor(props: HomeDieCoordsFormProps) {
    super(props);
    this.state = {
      show: false,
      data: {
        coordinateSystems: [],
        standardX: 0,
        standardY: 0,
        row: 0,
        col: 0,
        subRow: 0,
        subCol: 0,
        waferAxisDirection: WaferAxisDirection.DOWN_RIGHT,
      },
      formErrors: {},
      reticleSize: undefined,
    };
    instance = this;
  }

  handleResolvingFromButton = (
    resolveInner: any,
    buttonRef: any,
    buttonClickedCallback: (resolveInner: any) => void,
  ) => {
    if (buttonRef) {
      buttonRef.addEventListener(
        'click',
        () => {
          buttonClickedCallback(resolveInner);
        },
        { once: true },
      );
    } else {
      resolveInner(null);
    }
  };

  checkIntExtreme = (value: number, fieldName: string, fieldLabel: string, errors: { [key: string] : any }) => {
    if (value > Number.MAX_SAFE_INTEGER) {
      // eslint-disable-next-line no-param-reassign
      errors[fieldName] = `${fieldLabel} can not exceed ${Number.MAX_SAFE_INTEGER}`;
    } else if (value < Number.MIN_SAFE_INTEGER) {
      // eslint-disable-next-line no-param-reassign
      errors[fieldName] = `${fieldLabel} can not be less than ${Number.MIN_SAFE_INTEGER}`;
    }
  };

  checkDecimal = (value: string, fieldName: string, fieldLabel: string, errors: { [key: string] : any }) => {
    if (value.includes('.')) {
      // eslint-disable-next-line no-param-reassign
      errors[fieldName] = `${fieldLabel} should be an integer`;
    }
  };

  validateData = (data: HomeDieCoordinateSystemFormData) => {
    const errors: { [key: string] : any } = {};
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
      if (data.standardX.toString() === '') {
        errors.standardX = 'Required';
      }
      if (data.standardY.toString() === '') {
        errors.standardY = 'Required';
      }
    }
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)) {
      const { reticleSize } = this.state;
      if (!reticleSize) {
        errors.error = 'Reticle Size is missing';
        return errors;
      }
      if (data.row.toString() === '') {
        errors.row = 'Required';
      }
      if (data.col.toString() === '') {
        errors.col = 'Required';
      }
      if (data.subRow.toString() === '') {
        errors.subRow = 'Required';
      }
      if (+data.subRow >= reticleSize.y) {
        errors.subRow = 'Sub row can not exceed reticle size';
      }
      if (data.subCol.toString() === '') {
        errors.subCol = 'Required';
      }
      if (+data.subCol >= reticleSize.x) {
        errors.subCol = 'Sub col can not exceed reticle size';
      }
    }
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
      this.checkIntExtreme(+data.standardX, 'standardX', 'X', errors);
      this.checkIntExtreme(+data.standardY, 'standardY', 'Y', errors);
    }
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)) {
      this.checkIntExtreme(+data.col, 'col', 'Col', errors);
      this.checkIntExtreme(+data.row, 'row', 'Row', errors);
      this.checkIntExtreme(+data.subCol, 'subCol', 'Sub col', errors);
      this.checkIntExtreme(+data.subRow, 'subRow', 'Sub row', errors);
    }

    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
      this.checkDecimal(data.standardX ? data.standardX.toString() : '', 'standardX', 'X', errors);
      this.checkDecimal(data.standardY ? data.standardY.toString() : '', 'standardY', 'Y', errors);
    }
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)) {
      this.checkDecimal(data.col ? data.col.toString() : '', 'col', 'Col', errors);
      this.checkDecimal(data.row ? data.row.toString() : '', 'row', 'Row', errors);
      this.checkDecimal(data.subCol ? data.subCol.toString() : '', 'subCol', 'Sub col', errors);
      this.checkDecimal(data.subRow ? data.subRow.toString() : '', 'subRow', 'Sub row', errors);
    }
    return errors;
  };

  close = (callback: () => void) => {
    if (this.homeDieTooltipEL) this.homeDieTooltipEL.instance.hide();
    this.setState({ formErrors: {}, show: false }, callback);
  };

  yesButtonClickedCallback = (resolve: any) => {
    const { data } = this.state;
    const errors = this.validateData(data);
    if (Object.keys(errors).length === 0) {
      this.close(() => {
        resolve({
          coordinateSystems: data.coordinateSystems,
          standardX: +data.standardX,
          standardY: +data.standardY,
          row: +data.row,
          col: +data.col,
          subRow: +data.subRow,
          subCol: +data.subCol,
          waferAxisDirection: data.waferAxisDirection,
        });
      });
    } else {
      this.handleResolvingFromButton(resolve, this.yesButton, this.yesButtonClickedCallback);
      this.setState({ formErrors: errors });
    }
  };

  noButtonClickedCallback = (resolve: any) => {
    this.close(() => {
      resolve(null);
    });
  };

  setInnerPromise = (resolveOuter: any) => {
    resolveOuter(new Promise((resolveInner: any) => {
      this.handleResolvingFromButton(resolveInner, this.yesButton, this.yesButtonClickedCallback);
      this.handleResolvingFromButton(resolveInner, this.noButton, this.noButtonClickedCallback);
    }));
  };

  showForm = (props: HomeDieCoordsFormProps) => {
    return new Promise((resolveOuter: any) => {
      if (props.data) {
        this.COORDINATE_SYSTEM_VALUES = [...props.data.coordinateSystems];
        this.setState(() => {
          return {
            show: true,
            data: props.data!,
            formErrors: {},
            reticleSize: props.reticleSize,
          };
        }, () => {
          this.setInnerPromise(resolveOuter);
        });
      }
    });
  };

  onChangeData = (key: string, value: any) => {
    this.setState((prevState: HomeDieCoordsFormState) => {
      if (key in prevState.data) {
        const newData: HomeDieCoordinateSystemFormData = _.cloneDeep(prevState.data);
        newData[key] = value;
        return { data: newData };
      }
      return { data: prevState.data };
    });
  };

  render() {
    const {
      show, data, formErrors,
    } = this.state;
    return (

      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="home-die-modal"
        backdropClassName="home-die-modal"
      >

        <div className="custom-form p10">
          <p className="mb0" style={{ textAlign: 'left' }}>Define Home Die Coordinates</p>
          <TagBox
            items={this.COORDINATE_SYSTEM_VALUES}
            value={data.coordinateSystems}
            onValueChanged={(e: any) => {
              if (e.value.length > 0) {
                if (!_.isEqual(e.value, data.coordinateSystems)) this.onChangeData('coordinateSystems', [...e.value]);
              } else {
                toast.warn('Define at least one type of coordinates');
              }
            }}
            showSelectionControls
            style={{ border: '1px solid #e2e5ed', borderRadius: '5px' }}
          />
          <div>
            { data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)
              ? (
                <>
                  <p className="mt10 mb0" style={{ textAlign: 'left' }}>Standard Coordinates</p>
                  <Row>
                    <Col>
                      <Label
                        labelTitle="X"
                        labelPosition="left-middle"
                        labelSize="20"
                        fieldSize="80"
                        errorSize="100"
                        error={formErrors.standardX}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.standardX,
                            true,
                          )}`}
                          type="number"
                          name="standardX"
                          value={data.standardX}
                          placeholder="X"
                          onChange={(e: any) => {
                            this.onChangeData('standardX', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                    <Col>
                      <Label
                        labelTitle="Y"
                        labelPosition="left-middle"
                        labelSize="20"
                        fieldSize="80"
                        errorSize="100"
                        error={formErrors.standardY}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.standardY,
                            true,
                          )}`}
                          type="number"
                          name="standardY"
                          value={data.standardY}
                          placeholder="Y"
                          onChange={(e: any) => {
                            this.onChangeData('standardY', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Label
                    labelTitle="Axis Direction"
                    labelPosition="left-middle"
                    labelSize="20"
                    fieldSize="80"
                    required
                    errorSize="100"
                    error={formErrors.waferAxisDirection}
                    isFieldTouched
                    className="mb5"
                  >
                    <CustomizedDropdown
                      variant="clear"
                      full
                      list={WAFER_AXIS_DIRECTION.map((x) => { return [WaferAxisDirection[x.axisDirection], x.label]; })}
                      selectedValue={WaferAxisDirection[data.waferAxisDirection] || WaferAxisDirection[WaferAxisDirection.DOWN_RIGHT]}
                      onChange={(value: any) => { this.onChangeData('waferAxisDirection', WaferAxisDirection[value as unknown as WaferAxisDirection] as unknown as WaferAxisDirection); }}
                    />
                  </Label>
                </>
              )
              : null}

          </div>
          <div>
            { data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)
              ? (
                <>
                  <p className="mt10 mb0" style={{ textAlign: 'left' }}>Reticle Defined Coordinates</p>
                  <Row>
                    <Col>
                      <Label
                        labelTitle="Row"
                        labelPosition="left-middle"
                        labelSize="50"
                        fieldSize="50"
                        errorSize="100"
                        error={formErrors.row}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.row,
                            true,
                          )}`}
                          type="number"
                          name="row"
                          value={data.row}
                          placeholder="row"
                          onChange={(e: any) => {
                            this.onChangeData('row', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                    <Col>
                      <Label
                        labelTitle="Col"
                        labelPosition="left-middle"
                        labelSize="50"
                        fieldSize="50"
                        errorSize="100"
                        error={formErrors.col}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.col,
                            true,
                          )}`}
                          type="number"
                          name="col"
                          value={data.col}
                          placeholder="col"
                          onChange={(e: any) => {
                            this.onChangeData('col', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label
                        labelTitle="Sub-Row"
                        labelPosition="left-middle"
                        labelSize="50"
                        fieldSize="50"
                        errorSize="100"
                        error={formErrors.subRow}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.subRow,
                            true,
                          )}`}
                          type="number"
                          name="subRow"
                          value={data.subRow}
                          placeholder="Sub-Row"
                          onChange={(e: any) => {
                            this.onChangeData('subRow', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                    <Col>
                      <Label
                        labelTitle="Sub-Col"
                        labelPosition="left-middle"
                        labelSize="50"
                        fieldSize="50"
                        errorSize="100"
                        error={formErrors.subCol}
                        isFieldTouched
                        required
                        className="mr10"
                      >
                        <Textbox
                          autoComplete="off"
                          className={`${addErrorClasses(
                            formErrors.subCol,
                            true,
                          )}`}
                          type="number"
                          name="subCol"
                          value={data.subCol}
                          placeholder="Sub-Col"
                          onChange={(e: any) => {
                            this.onChangeData('subCol', e.target.value);
                          }}
                        />
                      </Label>
                    </Col>
                  </Row>
                </>
              )
              : null}
            <ErrorLabel
              size="100"
              error={formErrors.error}
              isTouched
            />
          </div>
          <div className="mt10" style={{ textAlign: 'center' }}>
            <Button variant="success" ref={(btn: any) => { this.yesButton = btn; }}>
              Apply
            </Button>
            <Button variant="danger" className="ml5" ref={(btn: any) => { this.noButton = btn; }}>
              Cancel
            </Button>

          </div>
        </div>
      </Modal>
    );
  }
}
