import React from 'react';
import { Button, DataGrid } from 'devextreme-react';
import {
  Column, FilterRow, Scrolling, Sorting,
} from 'devextreme-react/data-grid';
import _ from 'lodash';
import CustomStore from 'devextreme/data/custom_store';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import './paginated-datagrid.scss';
import { fixColumnChooserPosition } from 'dataGridConstants';

type PaginatedDatagridFilter = { columnName: string, operationName: string, queryText: string };
type PaginatedDatagridSort = { columnName: string, isDesc: boolean };

export type PaginatedDatagridMeta = {
  sortings: PaginatedDatagridSort [],
  filters: PaginatedDatagridFilter [],
  take: number,
  skip: number,
};

type PaginatedDatagridState = {
};

type PaginatedDatagridColumn = {
  caption: string,
  dataType: string,
  dataField: string,
  cellRender?: (e: any) => any,
  allowFiltering?: boolean,
  viewMode?: 'tag',
  width?: number,
  height?: number,
  columnsWidth?: number,
};

type PaginatedDatagridProps = {
  setRef?: (ref: DataGrid) => void,
  pageSize: number,
  httpLoadDataCallback: (datagridMeta: PaginatedDatagridMeta) => Promise<any>,
  processDataAfterLoad?: (data: any[]) => void,
  updateParentDataAfterLoad: (data: any[]) => void,
  columns: PaginatedDatagridColumn[],
  selection?: any,
  selectedRowKeys?: any,
  keyExpr?: string,
  onSelectionChanged?: any,
  height?: number,
  width?: number,
  columnsWidth?: number,
  showScrollBar?: boolean,
};

class PaginatedDatagrid extends React.Component<PaginatedDatagridProps, PaginatedDatagridState> {
  datagrid: DataGrid | null = null;

  prevPayload: PaginatedDatagridMeta | null = null;

  currPayload: PaginatedDatagridMeta = {
    sortings: [],
    filters: [],
    take: 100,
    skip: 0,
  };

  prevData: any[] = [];

  pageNumberInputTimeout: NodeJS.Timeout | null = null;

  pageNumberInput: any = null;

  constructor(props: PaginatedDatagridProps) {
    super(props);
    const { pageSize } = this.props;
    this.currPayload.take = pageSize;
  }

  componentDidUpdate() {
    this.pageNumberInput.value = this.currPayload.skip / this.currPayload.take;
  }

  getNewFilters = (filter: any) => {
    let newFilters: PaginatedDatagridFilter[] = [];
    if (filter) {
      if (Array.isArray(filter[0])) {
        for (let i = 0; i < filter.length; i += 1) {
          if (i % 2 === 0) {
            newFilters.push({
              columnName: filter[i][0],
              operationName: filter[i][1],
              queryText: filter[i][2],
            });
          }
        }
      } else {
        newFilters = [{
          columnName: filter[0],
          operationName: filter[1],
          queryText: filter[2],
        }];
      }
    }
    return newFilters;
  };

  onChangePageNumberInput = () => {
    if (this.pageNumberInputTimeout !== null) clearTimeout(this.pageNumberInputTimeout);
    this.pageNumberInputTimeout = setTimeout(() => {
      if (+this.pageNumberInput.value >= 0) {
        this.currPayload.skip = +this.pageNumberInput.value * this.currPayload.take;
        if (this.datagrid) this.datagrid.instance.refresh();
      }
      if (this.pageNumberInputTimeout !== null) clearTimeout(this.pageNumberInputTimeout);
    }, 1000);
  };

  onNextPageClickHandler = () => {
    this.currPayload.skip += this.currPayload.take;
    if (this.datagrid) this.datagrid.instance.refresh();
  };

  onPreviousPageClickHandler = () => {
    if (this.currPayload.skip >= this.currPayload.take) {
      this.currPayload.skip -= this.currPayload.take;
      if (this.datagrid) this.datagrid.instance.refresh();
    }
  };

  loadDatasource = async (loadOptions: any) => {
    const { httpLoadDataCallback, processDataAfterLoad } = this.props;
    this.currPayload.filters = this.getNewFilters(loadOptions.filter);
    this.currPayload.sortings = loadOptions.sort ? loadOptions.sort.map((s: any) => { return { columnName: s.selector, isDesc: s.desc }; }) : [];
    if ((this.prevPayload && (!_.isEqual(this.currPayload.sortings, this.prevPayload.sortings) || !_.isEqual(this.currPayload.filters, this.prevPayload.filters)))) {
      // load from first page when filter or sorting is changed
      this.currPayload.skip = 0;
    }
    if (!_.isEqual(this.prevPayload, this.currPayload)) {
      this.prevPayload = { ...this.currPayload };
      const data = await httpLoadDataCallback(this.currPayload);
      if (processDataAfterLoad) processDataAfterLoad(data);
      return [...data];
    }
    return [...this.prevData];
  };

  onDatasourceLoaded = (data: any[]) => {
    const { updateParentDataAfterLoad } = this.props;
    if (data && !_.isEqual(this.prevData, data)) {
      this.prevData = data;
      updateParentDataAfterLoad(data);
    }
  };

  render() {
    const {
      columns, selection, selectedRowKeys, keyExpr, onSelectionChanged, width, height, columnsWidth, showScrollBar, setRef,
    } = this.props;
    return (
      <div>
        <DataGrid
          showBorders
          allowColumnResizing
          errorRowEnabled={false}
          columnWidth={columnsWidth || 200}
          ref={(ref) => {
            if (ref && setRef) {
              setRef(ref!);
            }
            this.datagrid = ref;
          }}
          style={{ marginTop: '0px', clear: 'both' }}
          selection={selection}
          remoteOperations={{ filtering: true, sorting: true }}
          selectedRowKeys={selectedRowKeys}
          onSelectionChanged={onSelectionChanged}
          dataSource={{
            store: new CustomStore(
              {
                key: keyExpr || 'id',
                load: this.loadDatasource,
                onLoaded: this.onDatasourceLoaded,
              },
            ),
          }}
          hoverStateEnabled
          width={width || '100%'}
          height={height || '600px'}
          allowColumnReordering
          rowAlternationEnabled
          showColumnLines={false}
          paging={{ enabled: false }}
          onContentReady={(e: any) => { fixColumnChooserPosition(e) }}
          columnChooser={{ enabled: true, mode: 'select' }}
        >
          <Sorting mode="multiple" />
          <FilterRow visible />
          <Scrolling mode="standard" showScrollbar={showScrollBar} />
          {columns.map((c: PaginatedDatagridColumn) => {
            return (
              <Column
                key={`${c.dataField}`}
                width={c.width}
                caption={c.caption}
                cellRender={c.cellRender || ((e: any) => {
                  if (c.viewMode === 'tag') {
                    const badgeClass = `control-tower-tag ${e.text.toLowerCase()}`;
                    return <span className={`${badgeClass}`}>{e.text}</span>;
                  }
                  return <p title={e.text}>{e.text}</p>;
                })}
                dataField={c.dataField}
                dataType={c.dataType}
                filterOperations={c.dataType === 'string' ? ['contains'] : ['=']}
                allowFiltering={c.allowFiltering === false ? false : (c.dataType === 'string' || c.dataType === 'number')}
              />
            );
          })}
        </DataGrid>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }} className="mt20">
          <span className="mt5 mr10" style={{ fontWeight: 'bold' }}>Page Info</span>
          <Button onClick={this.onPreviousPageClickHandler}>
            {'<'}
          </Button>
          <Textbox
            style={{ width: '50px' }}
            name="pageNumber"
            placeholder="Page Number"
            type="number"
            setRef={(ref: any) => { this.pageNumberInput = ref; }}
            onChange={this.onChangePageNumberInput}
          />
          <Button onClick={this.onNextPageClickHandler}>
            {'>'}
          </Button>
        </div>
      </div>
    );
  }
}

export default PaginatedDatagrid;
