import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import {
  Switch,
  Route,
  useHistory,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Login from "components/iam/login";
import ModalPopup from "components/wrapped-component/modal-popup/modal-popup";
import userDataUtil from "services/user-data-util";
import GeneralUtils from "GeneralUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import commonFunctions from "customFunctions";
import { httpIAM } from "services/http.IAM";
import { IUserDetails } from "interfaces";
import { jwtDecode } from "jwt-decode";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FallingLines, FallingLinesProps } from "react-loader-spinner";
import routes from "../../routes";
import SidebarNav from "../../components/navigational-component/sidebar-nav/SidebarNav";
import "./Dashboard.scss";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(() =>
    localStorage.getItem("isSidebarNavExpanded") == "true" ||
    localStorage.getItem("isSidebarNavExpanded") == null
      ? true
      : false
  );
  const [loginOrDashboard, setLoginOrDashboard] = useState(false);

  const [forIframe, setForIframe] = useState(false);
  const [loading, setloading] = React.useState(false);

  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const currentToken = searchParams.get("token");

    if (currentToken) {
      commonFunctions.rotatingToken(currentToken);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      tokenReplacing(currentToken);
    }
    setLoginOrDashboard(!!currentToken);
    setForIframe(commonFunctions.hideDashboardBasedOnParams(search));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, []);

  const collapseSidebar = () => {
    localStorage.setItem("isSidebarNavExpanded", (!sidebarOpen).toString());
    setSidebarOpen(!sidebarOpen);
  };

  const toastContainer = (
    <ToastContainer
      hideProgressBar
      className="mb40"
      position="bottom-right"
      newestOnTop
      containerId="toast-container-id"
    />
  );

  const clearAllToastsButton = (
    <Button
      style={{
        position: "absolute",
        display: "none",
        right: "28px",
        bottom: "20px",
        zIndex: 100,
      }}
      className="h40 w40 border-radius-all"
      variant="danger"
      type="button"
      id="close-all-toast-container"
      onClick={() => {
        toast.dismiss();
      }}
    >
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  );

  const authToken = GeneralUtils.getCookieValue("AUTH_TOKEN");

  const tokenReplacing = async (tokenParam: any) => {
    const T = GeneralUtils.getCookieValue("AUTH_TOKEN");
    const newauthToken = T == "" ? tokenParam : T;

    if (newauthToken) {
      let token = jwtDecode(newauthToken);

      const { UserName } = token as { UserName: string };
      if (newauthToken !== null) {
        GeneralUtils.appendCookie("INIT_USER_STATS", "");
        const d1: any = new Date();
        const d2: any = new Date(d1);
        d2.setMinutes(d1.getMinutes() + 2);

        GeneralUtils.appendCookie("AUTH_TOKEN", newauthToken);
        httpIAM.getUserDetails(UserName).then((user: IUserDetails) => {
          GeneralUtils.appendCookie("USER_DETAILS", JSON.stringify(user));
          const previousDatabase = GeneralUtils.getCookieValue("DATABASE");
          if (
            user !== undefined &&
            user.databases?.length > 0 &&
            (previousDatabase === "" ||
              user.databases.find((x) => x.id === previousDatabase) ===
                undefined)
          ) {
            GeneralUtils.appendCookie("DATABASE", user.databases[0].id);
          }
          userDataUtil.initializeUserStats();
        });
      }
    }
  };

  interface CustomFallingLinesProps extends FallingLinesProps {
    ariaLabel?: string;

    // color?: string;
    // width?: string;
    // visible?: boolean;
  }
  const CustomFallingLines: React.FC<CustomFallingLinesProps> = (props) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <FallingLines {...props} />;
  };

  userDataUtil.registerPageView(history);

  if (
    (authToken && authToken.length > 1) ||
    window.location.href.includes("wafer-image-generator")
  ) {
    return (
      <>
        <ModalPopup />
        {toastContainer}
        <Container fluid>
          <Row>
            {forIframe && (
              <Col
                className={`${
                  sidebarOpen === true
                    ? "sidebar-column "
                    : "sidebar-column-collapse "
                } 
                ${
                  window.location.href.includes("wafer-image-generator")
                    ? " d-none"
                    : ""
                }`}
              >
                <SidebarNav
                  // tokenReplacing={tokenReplacing}
                  showMenuLabel={sidebarOpen}
                />
              </Col>
            )}
            <Col
              className="main-body-content main-body-content-width position-relative"
              id="main-dashboard"
            >
              <Button
                type="button"
                variant="clear"
                className="w30 h30 d-flex align-items-center justify-content-center background-color-light shadow border-radius-all"
                onClick={collapseSidebar}
                style={{
                  position: "absolute",
                  left: "-14px",
                  top: "64px",
                  border: "1px solid black",
                }}
              >
                <FontAwesomeIcon
                  size="sm"
                  className="color-default-color"
                  icon={sidebarOpen ? faChevronLeft : faChevronRight}
                />
              </Button>
              <Switch>
                {routes.getRoutes().map((item: any) => {
                  if (item.subRoutes) {
                    return item.subRoutes.map((subItem: any) => (
                      <Route
                        exact
                        key={`route_${subItem.path}`}
                        path={subItem.path}
                      >
                        {subItem.content}
                      </Route>
                    ));
                  }

                  return (
                    <Route exact key={`route_${item.path}`} path={item.path}>
                      {item.content}
                    </Route>
                  );
                })}
              </Switch>
              {clearAllToastsButton}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      {loginOrDashboard ? (
        <Router>
          <Switch>
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </Router>
      ) : (
        <Login setloading={setloading} />
      )}
      <ModalPopup />
      {toastContainer}
      {loading && (
        <div className="overlay">
          <CustomFallingLines
            color="#6A41FF"
            width="100"
            visible
            ariaLabel="falling-circles-loading"
          />
        </div>
      )}
    </>
  );
};

export default Dashboard;
