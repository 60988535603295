/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import PlotlyGraph from 'components/utility-component/custom-plotly/PlotlyGraph';
import PublishSubscribe from 'components/utility-component/wafer-map-widget/PublishSubscribe';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import { DataGrid } from 'devextreme-react';
import {
  ColumnChooser, FilterRow, Grouping, Paging, Scrolling, SearchPanel, Summary, TotalItem,
} from 'devextreme-react/data-grid';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import React, { Component } from 'react';
import {
  Accordion, Card, Col, Dropdown, Table,
} from 'react-bootstrap';
import { ISelectionCriteriaReturnValue, ISelectionsObject } from 'interfaces';
import { httpBinHistogram } from '../../../services/http.bin-histogram';
import SelectionCriteriaButton from '../../selection-criteria/SelectionCriteriaButton';
import Button from '../../wrapped-component/hint-controls/Button';
import ShowDataPopup from '../show-data-popup';
import CreateGroup from './create-groups';
import CreateSort from './create-sorts';
import './histogram.scss';
// eslint-disable-next-line import/no-unresolved
import PlotlyChartDesigner from '../plotly-designer/PlotlyChartDesigner';
import Legend from '../legend/Legend';

class BinHistogram extends Component<any, any> {
  private selectionCriteriaInfo: any;

  private selectionCriteriaUpdated = true;

  private wafersData = [];

  private rawData = [];

  private globalFilterExpression = '';

  private headerData:any = [];

  constructor(props: any) {
    super(props);
    this.selectionCriteriaInfo = {
      availableComponents: undefined,
      facilitySelected: undefined,
      workCenterSelected: undefined,
      deviceSelected: undefined,
      testProgramSelected: undefined,
      waferSelected: [{
        name: 'WT803988-15B5',
        id: '34971ed1-1f6b-4f71-9c8c-77a6294c4363',
      }],
      lotSelected: undefined,
      filterExpression: undefined,
    };
    this.state = {
      rawFilterValue: '',
      aggregated: false,
      wafersData: this.wafersData,
      showData: false,
      showGrid: false,
      advancedFilterInput: '',
      headerData: [],
      plotlyInstances: {},
      customizedDataGridInstances: {},
      groupCaption: 'wafer',
      showGroupDialog: false,
      showSortDialog: false,
      dataSource: [],
      selectionCriteriaInfo: this.selectionCriteriaInfo,
      selectedGroups: [{
        caption: 'Wafer',
        groupObject: [{
          TableName: 'wafer',
          Columns: [{
            ColumnName: 'key',
            Sort: '',
          }],
        }],
      }, {
        caption: 'Lot',
        groupObject: [{
          TableName: 'lot',
          Columns: [{
            ColumnName: 'key',
            Sort: '',
          }],
        }],
      }, {
        caption: 'Test Program',
        groupObject: [{
          TableName: 'test_program',
          Columns: [{
            ColumnName: 'name',
            Sort: '',
          }],
        }],
      }],
      selectedSorts: [{
        caption: 'Wafer',
        groupObject: [{
          TableName: 'wafer',
          Columns: [{
            ColumnName: 'key',
            Sort: 'asc',
          }],
        }],
      }, {
        caption: 'Lot',
        groupObject: [{
          TableName: 'lot',
          Columns: [{
            ColumnName: 'key',
            Sort: 'desc',
          }],
        }],
      }, {
        caption: 'Test Program',
        groupObject: [{
          TableName: 'test_program',
          Columns: [{
            ColumnName: 'name',
            Sort: 'desc',
          }],
        }],
      }],
      groupBy: [{
        TableName: 'wafer',
        Columns: [{
          ColumnName: 'key',
          Sort: '',
        }],
      }],
      sortBy: {
        caption: 'Wafer',
        groupObject: [{
          TableName: 'wafer',
          Columns: [{
            ColumnName: 'key',
            Sort: 'asc',
          }],
        }],
      },
    };
    this.getFilterObject = this.getFilterObject.bind(this);
    this.getData = this.getData.bind(this);
    this.onHideSelectionCriteria = this.onHideSelectionCriteria.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeSortingOrder = this.changeSortingOrder.bind(this);
    this.groupUpdated = this.groupUpdated.bind(this);
    this.toggleGroupDialog = this.toggleGroupDialog.bind(this);
    this.toggleSortDialog = this.toggleSortDialog.bind(this);
    this.sortUpdated = this.sortUpdated.bind(this);
    this.getWafersData = this.getWafersData.bind(this);
    this.showData = this.showData.bind(this);
    this.setPlotInstanceHandler = this.setPlotInstanceHandler.bind(this);
    this.setCustomGridInstanceHandler = this.setCustomGridInstanceHandler.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.toogleGrid = this.toogleGrid.bind(this);
    this.toggleAggregation = this.toggleAggregation.bind(this);
    this.preserveRawFilters = this.preserveRawFilters.bind(this);
  }

  appendKeyIndex = (key: string | number) => {
    const { keyIndex } = this.props;
    return keyIndex + key.toString();
  };

  toogleGrid() {
    const dataFieldPanel = this.getFilters();
    const { showGrid } = this.state;
    httpBinHistogram.getRawData(dataFieldPanel)
      .then((data: any) => {
        this.rawData = data;
        this.setState({ showGrid: !showGrid });
        // this.setState({
        //   rawData:data
        // });
      });
  }

  componentDidMount() {
    this.getData();
    const { customizedDataGridInstances, plotlyInstances } = this.state;
    const ps = PublishSubscribe();
    // ps.subscribeToPlotID(EventTypes.DATA_SELECTED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataSelected, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToPlotID(EventTypes.DATA_UNSELECTED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataUnSelected, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToPlotID(EventTypes.DATA_DELETED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataDeleted, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex('5')].clearFilterData,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex('5')].setFilterDataFromCustomGrid,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );

    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID, plotlyInstances[this.appendKeyIndex(5)].clearFilterData, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex(5)].setFilterDataFromCustomGridMultiCategory, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.SELECTION_ON_CUSTOMIZED_GRID, plotlyInstances[this.appendKeyIndex(5)].dataSelectedOnCustomGrid, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToPlotID(
    //   EventTypes.DATA_HIDDEN_ON_PLOT,
    //   customizedDataGridInstances[this.appendKeyIndex('5')].dataHidden,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToPlotID(
    //   EventTypes.DATA_UNHIDDEN_ON_PLOT,
    //   customizedDataGridInstances[this.appendKeyIndex('5')].dataUnHidden,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.HIDE_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex('5')].dataHiddenOnCustomGrid,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.UNHIDE_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex('5')].dataUnHiddenOnCustomGrid,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('5'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
  }

  componentDidUpdate() {
    const { customizedDataGridInstances, plotlyInstances } = this.state;
    const ps = PublishSubscribe();
    // ps.subscribeToPlotID(EventTypes.DATA_SELECTED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataSelected, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToPlotID(EventTypes.DATA_UNSELECTED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataUnSelected, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToPlotID(EventTypes.DATA_DELETED_ON_PLOT, customizedDataGridInstances[this.appendKeyIndex(5)].dataDeleted, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin']);
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID, plotlyInstances[this.appendKeyIndex(5)].clearFilterData, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
    //   plotlyInstances[this.appendKeyIndex(5)].setFilterDataFromCustomGridMultiCategory, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.SELECTION_ON_CUSTOMIZED_GRID, plotlyInstances[this.appendKeyIndex(5)].dataSelectedOnCustomGrid, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DELETION_ON_CUSTOMIZED_GRID, plotlyInstances[this.appendKeyIndex(5)].dataDeletedOnCustomGrid, this.appendKeyIndex(5), this.appendKeyIndex(5), ['groupName', 'bin'], ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_CLEARED_ON_CUSTOMIZED_GRID,
    //   customizedDataGridInstances[this.appendKeyIndex('rawGrid')]?.clearFilterData,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('rawGrid'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.DATA_FILTER_APPLIED_ON_CUSTOMIZED_GRID,
    //   customizedDataGridInstances[this.appendKeyIndex('rawGrid')]?.setFilterDataFromCustomGrid,
    //   this.appendKeyIndex('5'),
    //   this.appendKeyIndex('rawGrid'),
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
    // ps.subscribeToCustomGridID(
    //   EventTypes.FILTER_EXPRESSION,
    //   (data: { filterExp: any }) => {
    //     this.globalFilterExpression = data.filterExp;
    //   },
    //   this.appendKeyIndex('5'),
    //   'bin-histogram',
    //   ['groupName', 'bin'],
    //   ['groupName', 'bin'],
    // );
  }

  showData() {
    const { showData } = this.state;
    this.setState({
      showData: !showData,
    });
  }

  onHideSelectionCriteria = () => {
    const { selectionCriteriaInfo } = this.state;
    if (JSON.stringify(selectionCriteriaInfo) !== JSON.stringify(this.selectionCriteriaInfo)) {
      this.getData();
    }
  };

  getSelectionData = (data:{ entityType: string, values: ISelectionsObject[] }[], key:string) => {
    const selectionData = data.filter((x) => x.entityType === key);
    if (selectionData !== undefined) {
      if (selectionData.length > 0) {
        return selectionData[0].values;
      }
    }
    return [];
  };

  getFilterObject(selectionCriteriaInfo: ISelectionCriteriaReturnValue) {
    const newSelectionCriteriaInfo = {
      availableComponents: undefined,
      facilitySelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Facility'),
      workCenterSelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Workcenter'),
      deviceSelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Device'),
      testProgramSelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Testprogram'),
      lotSelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Lot'),
      waferSelected: this.getSelectionData(selectionCriteriaInfo.selections, 'Wafer'),
      filterExpression: undefined,
    };
    if (JSON.stringify(this.selectionCriteriaInfo) !== JSON.stringify(newSelectionCriteriaInfo)) {
      this.selectionCriteriaInfo = newSelectionCriteriaInfo;
      this.selectionCriteriaUpdated = true;
      this.onHideSelectionCriteria();
    }
  }

  getData() {
    this.getWafersData();
    if (!this.selectionCriteriaUpdated) {
      return;
    }
    const { selectionCriteriaInfo } = this;
    const { groupBy, sortBy, rawFilterValue } = this.state;
    // const groups: any = [];
    // groups.push(groupBy);
    const sort: any = [];
    sortBy.groupObject.forEach((item: any) => {
      sort.push(item);
    });

    const dataFieldPanel = {
      Filters: {
        FacilitySelected: _.map(selectionCriteriaInfo.facilitySelected, (item: any) => {
          return item.id;
        }),
        WorkCenterSelected: _.map(selectionCriteriaInfo.workCenterSelected, (item: any) => {
          return item.id;
        }),
        DeviceSelected: _.map(selectionCriteriaInfo.deviceSelected, (item: any) => {
          return item.id;
        }),
        TestProgramSelected: _.map(selectionCriteriaInfo.testProgramSelected, (item: any) => {
          return item.id;
        }),
        LotSelected: _.map(selectionCriteriaInfo.lotSelected, (item: any) => {
          return item.id;
        }),
        WaferSelected: _.map(selectionCriteriaInfo.waferSelected, (item: any) => {
          return item.id;
        }),
        FilterExpression: rawFilterValue,
        ParameterSelected: _.map(selectionCriteriaInfo.parameterSelected, (item: any) => {
          return item.id;
        }),
      },
      GroupColumns: groupBy,
      SortColumns: sort,
    };

    httpBinHistogram.getHistogramHeaderData(dataFieldPanel)
      .then((data: any) => {
        this.setState({ headerData: data });
      });

    if (rawFilterValue !== '') {
      httpBinHistogram.calcutateHistogramData(dataFieldPanel)
        .then((data: any) => {
          this.selectionCriteriaUpdated = false;
          this.setState({
            dataSource: data,
            selectionCriteriaInfo: this.selectionCriteriaInfo,
          });
        });
    } else {
      httpBinHistogram.getHistogramData(dataFieldPanel)
        .then((data: any) => {
          this.selectionCriteriaUpdated = false;
          this.setState({
            dataSource: data,
            selectionCriteriaInfo: this.selectionCriteriaInfo,
            wafersData: this.wafersData,
          });
        });
    }
  }

  getWafersData() {
    httpBinHistogram.getWafersData(this.getFilters())
      .then((data: any) => {
        this.wafersData = data;
      });
  }

  getFilters() {
    const { selectionCriteriaInfo } = this;
    return {
      FacilitySelected: _.map(selectionCriteriaInfo.facilitySelected, (item: any) => {
        return item.id;
      }),
      WorkCenterSelected: _.map(selectionCriteriaInfo.workCenterSelected, (item: any) => {
        return item.id;
      }),
      DeviceSelected: _.map(selectionCriteriaInfo.deviceSelected, (item: any) => {
        return item.id;
      }),
      TestProgramSelected: _.map(selectionCriteriaInfo.testProgramSelected, (item: any) => {
        return item.id;
      }),
      LotSelected: _.map(selectionCriteriaInfo.lotSelected, (item: any) => {
        return item.id;
      }),
      WaferSelected: _.map(selectionCriteriaInfo.waferSelected, (item: any) => {
        return item.id;
      }),
      ParameterSelected: _.map(selectionCriteriaInfo.parameterSelected, (item: any) => {
        return item.id;
      }),
      FilterExpression: '',
    };
  }

  changeGroup(item: any, caption: string) {
    this.selectionCriteriaUpdated = true;
    this.setState({
      groupBy: item,
      dataSource: [],
      groupCaption: caption,
    }, () => {
      this.getData();
    });
  }

  changeSort(item: any) {
    this.selectionCriteriaUpdated = true;
    this.setState({
      sortBy: item,
    }, () => {
      this.getData();
    });
  }

  changeSortingOrder() {
    this.selectionCriteriaUpdated = true;
    const { sortBy } = this.state;
    sortBy.groupObject[0].Columns[0].Sort = sortBy.groupObject[0].Columns[0].Sort === 'asc' ? 'desc' : 'asc';
    this.setState({ sortBy }, () => {
      this.getData();
    });
  }

  groupUpdated(selectedGroup: any) {
    this.setState({ selectedGroups: selectedGroup });
    const selecteditm: any = selectedGroup[0].groupObject;
    this.changeGroup(selecteditm, selectedGroup[0].caption);
  }

  sortUpdated(selectedSort: any) {
    this.setState({
      selectedSorts: selectedSort,
      dataSource: [],
    });
    const selecteditm: any = selectedSort[0];
    this.changeSort(selecteditm);
  }

  toggleGroupDialog() {
    const { showGroupDialog } = this.state;
    this.setState({ showGroupDialog: !showGroupDialog });
  }

  toggleSortDialog() {
    const { showSortDialog } = this.state;
    this.setState({ showSortDialog: !showSortDialog });
  }

  createLegends(waferName: string, binsData: any) {
    const binsRow: any = [];
    binsData.map((item: any) => {
      binsRow.push(<tr>
        <td>
          <div style={{ background: this.getColor(item.bin) }} className="w20 h20 mr5 mt0 ml10 mb0 mr0" />
        </td>
        <td className={item.passFailFlag === 'P' ? 'pass d-flex' : 'fail d-flex'}>
          {item.bin}

        </td>
        <td>{item.passFailFlag}</td>
        <td>{item.binCount}</td>
                   </tr>);
    });
    return (
      <div>
        <h6 className="pr5 pl5">
          {waferName}
          {' '}
          <i className="float-right text-success" />
        </h6>
        <Table bordered>
          <thead>
            <tr>
              <th />
              <th>Number/Name</th>
              <th>Pass/Fail</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {binsRow}
          </tbody>
        </Table>
      </div>
    );
  }

  getColor(xVal: string) {
    let color = '#';
    let firstDigit = 0;
    const digits = [11, 1, 9, 64, 5, 23, 42, 71, 89, 9];
    for (let i = 0; i < xVal.length; i += 1) {
      if (xVal >= '0' && xVal <= '9') {
        firstDigit = +xVal[i];
        break;
      }
    }
    const num = ((digits[firstDigit] * 123456789) % 799999) + 124379;
    color += num.toString();
    return color;
  }

  setCustomGridInstanceHandler(customizedDataGrid: CustomizedDataGrid, keyIndex: string) {
    const { customizedDataGridInstances } = this.state;
    customizedDataGridInstances[keyIndex] = customizedDataGrid;
  }

  setPlotInstanceHandler(plot: PlotlyGraph, keyIndex: string) {
    const { plotlyInstances } = this.state;
    plotlyInstances[keyIndex] = plot;
  }

  getAggregatedData(dataSource: any) {
    const result: any = [...dataSource.reduce((r: any, o: any) => {
      const key: any = `${o.bin}-${o.passFailFlag}`;
      const item: any = r.get(key) || ({ ...o, binCount: 0 });

      item.binCount += o.binCount;
      return r.set(key, item);
    }, new Map())
      .values()];

    return result;
  }

  toggleAggregation(e: any) {
    this.setState({
      aggregated: e.value,
    });
  }

  preserveRawFilters(filtervalue: any, advancedFilterInput:any) {
    this.setState({
      rawFilterValue: filtervalue,
      advancedFilterInput,
      showData: false,
    }, () => {
      this.selectionCriteriaUpdated = true;
      this.getData();
    });
  }

  getSummaryCaption = (data:any, item:any, tableData:any) => {
    let total:any = 0;
    forEach(tableData, (row:any) => {
      if (row.hidden !== undefined && row.hidden !== true) {
        if (row[item.dataType] !== undefined) {
          if (row[item.dataType].toString().indexOf('.') !== -1) {
            total += parseFloat(row[item.dataType]);
          } else { total += row[item.dataType]; }
        }
      }
    });
    data.value = total;
  };

  render() {
    const {
      aggregated, dataSource, selectionCriteriaInfo, selectedGroups, selectedSorts, sortBy, showGroupDialog, showSortDialog, wafersData, showData, groupCaption, showGrid, rawFilterValue, headerData, advancedFilterInput,
    } = this.state;

    const createGroup: any = (
      <Col>
        <CreateGroup groupChanged={() => this.changeGroup} selectedGroupDialog={selectedGroups} groupUpdated={this.groupUpdated} closeDialog={this.toggleGroupDialog} />
      </Col>
    );
    const createSort: any = (
      <Col>
        <CreateSort selectedSortDialog={selectedSorts} sortUpdated={this.sortUpdated} closeDialog={this.toggleSortDialog} />
      </Col>
    );
    const selectionCriteria: any = (
      <Col className="p0">
        <SelectionCriteriaButton
          getSelectionCriteriaData={this.getFilterObject}
          defaultSelection={[
            { entityType: 'facility', values: selectionCriteriaInfo.facilitySelected === undefined ? [] : selectionCriteriaInfo.facilitySelected.map((item:any) => (item.id)) },
            { entityType: 'workCenter', values: selectionCriteriaInfo.workCenterSelected === undefined ? [] : selectionCriteriaInfo.workCenterSelected.map((item:any) => (item.id)) },
            { entityType: 'device', values: selectionCriteriaInfo.deviceSelected === undefined ? [] : selectionCriteriaInfo.deviceSelected.map((item:any) => (item.id)) },
            { entityType: 'testProgram', values: selectionCriteriaInfo.testProgramSelected === undefined ? [] : selectionCriteriaInfo.testProgramSelected.map((item:any) => (item.id)) },
            { entityType: 'lot', values: selectionCriteriaInfo.lotSelected === undefined ? [] : selectionCriteriaInfo.lotSelected.map((item:any) => (item.id)) },
            { entityType: 'wafer', values: selectionCriteriaInfo.waferSelected === undefined ? [] : selectionCriteriaInfo.waferSelected.map((item:any) => (item.id)) },
            { entityType: 'testParameter', values: selectionCriteriaInfo.parameterSelected === undefined ? [] : selectionCriteriaInfo.parameterSelected.map((item:any) => (item.id)) },
          ]}
          widgetsList={
            [
              {
                controlType: 'Facility',
                columnWeight: 3,
                isVisible: true,
              },
              {
                controlType: 'Work Center',
                columnWeight: 3,
                isVisible: true,
              },
              {
                controlType: 'Device',
                columnWeight: 3,
                isVisible: true,
              },
              {
                controlType: 'Test Program',
                columnWeight: 3,
                isVisible: true,
              },
              {
                controlType: 'Lot',
                columnWeight: 3,
                isVisible: true,
              },
              {
                controlType: 'Wafer',
                columnWeight: 6,
                isVisible: true,
              },
              {
                controlType: 'Test Parameter',
                columnWeight: 3,
                isVisible: true,
                highlightOtherGrids: false,
              },
            ]
          }
        />

      </Col>
    );
    const passCount: number = _.filter(dataSource, ((item: any) => {
      if (item.passFailFlag.toString().toLowerCase() === 'pass') return item;
    })).length;
    const failCount: number = _.filter(dataSource, ((item: any) => {
      if (item.passFailFlag.toString().toLowerCase() === 'fail') return item;
    })).length;

    // const summaryFields: any = [
    //   {
    //     dataType: undefined,
    //     summaryType: undefined,
    //     showInColumn: 'bin',
    //     customizeText: 'Total',
    //   },
    //   {
    //     dataType: 'binCount',
    //     summaryType: 'sum',
    //     showInColumn: 'binCount',
    //     customizeText: '',
    //     name: 'binCount',
    //   },
    //   {
    //     dataType: 'binYield',
    //     summaryType: 'sum',
    //     showInColumn: 'binYield',
    //     customizeText: '',
    //   },
    //   // {
    //   //   dataType:undefined,
    //   //   summaryType:undefined,
    //   //   showInColumn:'passFailFlag',
    //   //   customizeText:passCount+ "/"+ failCount
    //   // }
    // ];
    const groupSummaryFields: any = [
      {
        column: 'binNumber',
        summaryType: 'count',
        displayFormat: 'Count: {0}',
        showInGroupFooter: false,
      },
      {
        column: 'binCount',
        summaryType: 'sum',
        displayFormat: 'Total: {0}',
        showInGroupFooter: true,
      },
    ];

    const fieldList : any = [
      {
        caption: 'Bin Number',
        dataField: 'bin',
        dataType: 'number',
        showInfo: false,
        colorField: true,
        colorFieldCaption: 'Color Band',
        visible: true,
        minWidth: 60,
      },
      {
        caption: 'Bin Name',
        dataField: 'binName',
        dataType: 'string',
        showInfo: false,
        minWidth: 100,
      },
      {
        caption: 'Pass / Fail',
        dataField: 'passFailFlag',
        dataType: 'string',
        showInfo: false,
        minWidth: 70,
      },
      {
        caption: 'Bin Count',
        dataField: 'binCount',
        dataType: 'number',
        showInfo: false,
        minWidth: 100,
      },
      {
        caption: 'Bin %',
        dataField: 'binYield',
        dataType: 'string',
        showInfo: false,
        selected: false,
        minWidth: 100,
      },
      {
        caption: groupCaption,
        dataField: 'groupName',
        dataType: 'string',
        showInfo: false,
        groupIndex: 0,
        sortOrder: sortBy!.groupObject[0].Columns[0].Sort,
      },
    ];
    // const fieldList: any = [
    //   {
    //     caption: 'Bin Number',
    //     dataField: 'bin',
    //     dataType: 'number',
    //     showInfo: false,
    //     colorField: true,
    //     colorFieldCaption: 'Color Band',
    //     visible: true,
    //     minWidth: 60,
    //   },
    //   {
    //     caption: 'Bin Name',
    //     dataField: 'binName',
    //     dataType: 'string',
    //     showInfo: false,
    //     minWidth: 100,
    //   },
    //   {
    //     caption: 'Pass / Fail',
    //     dataField: 'passFailFlag',
    //     dataType: 'string',
    //     showInfo: false,
    //     minWidth: 70,
    //   },
      // {
      //   caption: 'wafer',
      //   dataField: 'groupName',
      //   dataType: 'string',
      //   showInfo: false,
      //   groupIndex: 0,
      // },
    // ];
    // if (!aggregated) {
    //   fieldList.unshift({
    //     caption: groupCaption,
    //     dataField: 'groupName',
    //     dataType: 'string',
    //     showInfo: false,
    //     groupIndex: 0,
    //     sortOrder: sortBy.groupObject[0].Columns[0].Sort,
    //   });
    //   fieldList.push({
    //     caption: 'Bin Count',
    //     dataField: 'binCount',
    //     dataType: 'number',
    //     showInfo: false,
    //     minWidth: 100,
    //   }, {
    //     caption: 'Bin %',
    //     dataField: 'binYield',
    //     dataType: 'string',
    //     showInfo: false,
    //     selected: false,
    //     minWidth: 100,
    //   });
    // }
    const gdata: any = [];
    const designerSource:any = {
      Bins: [],
      Group: [],
      Values: [],
    };
    const groupData = _.groupBy(dataSource, 'groupName');
    // const legends:any=[];
    const groupString: any = [];
    const binString: any = [];
    const valueString: any = [];

    for (const item in groupData) {
      const values = groupData[item];
      // legends.push(this.createLegends(item,values));
      values.forEach((bindata: any) => {
        groupString.push(item);
        binString.push(bindata.bin);
        valueString.push(bindata.binCount);
      });
      const x: any = [];
      x.push(groupString);
      x.push(binString);
      gdata.push({
        x,
        y: valueString,
      });
    }
    designerSource.Group = groupString;
    designerSource.Bins = binString;
    designerSource.Values = valueString;

    const layout = { barmode: 'group' };
    const waferIds = wafersData.map((item: any) => {
      return item.id;
    });
    const data = [{
      type: 'bar',
      mode: 'markers',
      x: designerSource.Bins,
      xsrc: 'Bins',
      meta: {
        columnNames: {
          x: 'Bins',
          y: 'Values',
        },
      },
      y: designerSource.Values,
      ysrc: 'Values',
    }];
    const dataSourceOptions = Object.keys(designerSource).map((name) => ({

      value: name,
      label: name,
    }));

    const testPrograms = _.unionBy(dataSource, 'test_program').toString();
    const config = { mapboxAccessToken: '', editable: true };

    return (

      <div className="col overflow-auto bin-histogram">
        {selectionCriteria}
        <div className="wafer-galary border-all mt20 mb20 background-color-light">
          {/* {
            waferIds.length > 0 ? (
              <WaferGallery policyInputWaferIds={waferIds} itemPerPage={4} currentPage={1} selected={1} />
            ) : ''
          } */}
        </div>
        <div
          className="w-100 border-all background-color-light"
        >
          <div className="d-flex align-items-center justify-content-between pb10 mt10">
            <h5 className="ml10 mt5">Bin Distribution test</h5>
            <div className="sort-group d-flex">
              {/* <Button variant={'primary mr10'} onClick={() => this.toogleGrid()}>{showGrid ? 'Show Chart' : 'Show Grid'}</Button> */}
              <div className="p0 ">
                <Dropdown className="float-right mr10">
                  <Dropdown.Toggle variant="clear" id="dropdown-basic" className="btn-sm hide-carot btn-config">
                    <i className="dx-icon dx-icon-overflow" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                      this.toggleGroupDialog();
                    }}
                    >
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      this.showData();
                    }}
                    >
                      Show Data
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      this.showData();
                    }}
                    >
                      Export
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      this.showData();
                    }}
                    >
                      Print
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div>
            {
              !showGrid ? (
                <div>
                  <div className="sort-group p10">
                    <Accordion>
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          Grouping & Sorting
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="pl10">
                            <div className="p0">
                              <label className="pr5 pt6 w70">Group By</label>
                              <label className="pr5 pt6">
                                <Button onClick={() => this.toggleGroupDialog()} variant="outline">
                                  <a
                                    href="#"
                                  >
                                    {selectedGroups[0].caption !== '' ? selectedGroups[0].caption : 'add new'}
                                  </a>
                                </Button>
                              </label>
                              {/* <DropdownButton
                as={ButtonGroup}
                title={groupCaption}
                id="dropdown-menu-align-responsive-1"
                className={'p0'}
                variant={'sm btn-outline-primary'}
              >
            {
              selectedGroups.map((item: any) => {
                // return (<Button variant="primary mr10" size={'sm'} onClick={() => this.changeGroup(item.groupObject)}>{item.caption}</Button>);
                return (<Dropdown.Item eventKey={item} onClick={() => this.changeGroup(item.groupObject,item.caption)}>{item.caption}</Dropdown.Item>);
              })
            }
              </DropdownButton>
              <Button variant="outline-success mr10 ml5" size={'sm'} onClick={()=>{this.toggleGroupDialog()}}><i className='dx-icon-plus'></i></Button>
              */}
                            </div>
                            <div className="p0">
                              <label className="pr5 pt6 w70">Sort By</label>
                              <label className="pr5 pt6">
                                <Button onClick={() => this.toggleSortDialog()} variant="outline">
                                  <a href="#">
                                    {sortBy.caption !== '' ? sortBy.caption : 'add new'}
                                  </a>
                                </Button>
                              </label>
                              {/* <DropdownButton
                as={ButtonGroup}
                title={sortBy !== undefined ? sortBy.caption : ''}
                id="dropdown-menu-align-responsive-1"
                className={'p0'}
                variant={'sm btn-outline-primary'}
              >
                {
                  selectedSorts.map((item: any) => {
                    return (<Dropdown.Item eventKey={item} onClick={() => this.changeSort(item)}>{item.caption}</Dropdown.Item>);
                  })
                }
              </DropdownButton>
              <Button variant={sortBy.groupObject[0].Columns[0].Sort === 'asc' ? "mr10 dx-icon-sortuptext " : "mr10 dx-icon-sortdowntext"} size={'sm'} onClick={() => this.changeSortingOrder()}><i></i></Button>
              <Button variant="outline-success mr10 ml5" size={'sm'} onClick={()=>{this.toggleSortDialog()}}><i className='dx-icon-plus'></i></Button>
              */}
                            </div>

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                  <div className="d-flex ml10 mr10">
                    <Col className="col-8 bordered">
                      <div className="header-info pt20 pl0">
                        <div className="center-align col-12 bg-light">
                          <Table size="sm" className="col-10">
                            <tbody>
                              <tr>
                                <td colSpan={4} className="text-center">
                                  {_.uniqBy(headerData, 'facility').length === 1
                                    ? <b> Facility :</b> : <b> No. of Facilities : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'facility').length === 1 ? _.uniqBy(headerData, 'facility').map((item:any) => {
                                      return item.testProgram;
                                    }) : _.uniqBy(headerData, 'facility').length
                                  }

                                </td>
                              </tr>
                              <tr>

                                <td>
                                  {_.uniqBy(headerData, 'workCenter').length === 1
                                    ? <b> Work Center :</b> : <b> No. of Work Centers : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'workCenter').length === 1 ? _.uniqBy(headerData, 'workCenter').map((item:any) => {
                                      return item.workCenter;
                                    }) : _.uniqBy(headerData, 'workCenter').length
                                  }

                                </td>

                                <td className="text-right">
                                  {_.uniqBy(headerData, 'Device').length === 1
                                    ? <b> Device :</b> : <b> No. of Devices : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'device').length === 1 ? _.uniqBy(headerData, 'device').map((item:any) => {
                                      return item.device;
                                    }) : _.uniqBy(headerData, 'device').length
                                  }

                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {_.uniqBy(headerData, 'testProgram').length === 1
                                    ? <b> Test Program :</b> : <b> No. of Test Programs : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'testProgram').length === 1 ? _.uniqBy(headerData, 'testProgram').map((item:any) => {
                                      return item.testProgram;
                                    }) : _.uniqBy(headerData, 'testProgram').length
                                  }

                                </td>

                                <td className="text-right">
                                  {_.uniqBy(headerData, 'lot').length === 1
                                    ? <b> Lot :</b> : <b> No. of Lots [T/I/E] : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'lot').length === 1 ? _.uniqBy(headerData, 'lot').map((item:any) => {
                                      return item.lot;
                                    }) : `${_.uniqBy(headerData, 'lot').length.toString()}/${_.uniqBy(_.filter(headerData, (item) => { return item.isExcluded === false; }), 'lot').length.toString()}/${_.uniqBy(_.filter(headerData, (item) => { return item.isExcluded === true; }), 'lot').length.toString()}`
                                  }

                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {_.uniqBy(headerData, 'wafer').length === 1
                                    ? <b> Wafer :</b> : <b> No. of Wafers : </b>}
                                  {' '}
                                  {
                                    _.uniqBy(headerData, 'wafer').length === 1 ? _.uniqBy(headerData, 'wafer').map((item:any) => {
                                      return item.wafer;
                                    }) : _.uniqBy(headerData, 'wafer').length
                                  }
                                </td>
                                <td className="text-right">
                                  <b>Total Unique Dies : </b>
                                  {' '}
                                  {_.sumBy(headerData, 'totalUniqueDies')}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Pass Count :</b>
                                  {' '}
                                  {_.sumBy(headerData, 'passCount')}
                                </td>

                                <td className="text-right">
                                  <b>Average Yield : </b>
                                  {' '}
                                  {parseFloat((_.sumBy(headerData, 'yield') / headerData.length).toString()).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="h450">

                        <PlotlyChartDesigner
                          id={this.appendKeyIndex(5)}
                          type="bar"
                          mode="markers"
                          data={data}
                          dataSources={designerSource}
                          setPlotInstanceHandler={this.setPlotInstanceHandler}
                          dataSourceOptions={dataSourceOptions}
                        />

                      </div>

                      {/* <PlotlyGraph
                        hideLegend
                        xAxisDataFields={['groupName', 'bin']}
                        id={this.appendKeyIndex(5)}
                        setPlotInstanceHandler={this.setPlotInstanceHandler}
                        enableYAxisBreaks
                        dataSource={gdata}
                        isMultiCategory
                        type="bar"
                        mode="markers"
                      /> */}
                    </Col>

                    <Legend
                      switchDefaultValue={false}
                      switchWidth={120}
                      selectionMode="multiple"
                      similarDeletionField="bin"
                      publisherId={this.appendKeyIndex(5)}
                      setCustomGridInstanceHandler={this.setCustomGridInstanceHandler}
                      height="500px"
                      showGroupPanel
                      fieldList={fieldList}
                      groupSummaryFields={groupSummaryFields}
                      legendData={dataSource}
                      showAdvancedFilters
                      scrollMode="virtual"
                      getSummaryCaption={this.getSummaryCaption}
                    />
                    {/* <Col className="col-4 pl0 pr0 mt30 mb30"> */}
                    {/*  <Switch */}
                    {/*    defaultValue={false} */}
                    {/*    switchedOnText="Hide Aggregated" */}
                    {/*    switchedOffText="Show Aggregated" */}
                    {/*    width={120} */}
                    {/*    className="btn-agregated" */}
                    {/*    onValueChanged={this.toggleAggregation} */}
                    {/*  /> */}

                    {/*  <CustomizedDataGrid */}
                    {/*    key={aggregated ? 'agregated' : 'non-agregated'} */}
                    {/*    selectionMode="multiple" */}
                    {/*    similarDeletionField="bin" */}
                    {/*    publisherId={this.appendKeyIndex(5)} */}
                    {/*    setCustomGridInstanceHandler={this.setCustomGridInstanceHandler} */}
                    {/*    height="500px" */}
                    {/*    showGroupPanel={false} */}
                    {/*    fields={fieldList} */}
                    {/*    summaryFields={!aggregated ? summaryFields : undefined} */}
                    {/*    tableData={aggregated ? this.getAggregatedData(dataSource) : dataSource} */}
                    {/*    showAdvancedFilters */}
                    {/*    scrollMode="virtual" */}
                    {/*    isAggregated={aggregated} */}
                    {/*    getSummaryCaption={this.getSummaryCaption} */}

                    {/*  /> */}
                    {/* </Col> */}
                  </div>
                </div>
              )
                : (
                  <div>
                    <DataGrid
                      dataSource={this.rawData}
                      showBorders
                      columnMinWidth={120}
                      allowColumnResizing
                      allowColumnReordering
                      width="100%"
                      height={600}
                    >
                      <Summary>
                        <TotalItem
                          column="facility"
                          summaryType="count"
                        />
                      </Summary>
                      <Scrolling mode="virtual" />
                      <FilterRow visible />
                      <Grouping autoExpandAll />
                      <SearchPanel
                        visible
                        width={240}
                        placeholder="Search..."
                      />
                      <Paging enabled pageSize={10} />
                      <ColumnChooser
                        enabled
                        mode="dragAndDrop"
                      />
                    </DataGrid>
                  </div>
                )
            }
          </div>
          {
            showData ? (<ShowDataPopup rawFilterValue={rawFilterValue} preserveFilterValue={this.preserveRawFilters} advancedFilterInput={advancedFilterInput} showData={this.showData} dataFieldPanel={this.getFilters()} />) : ''
          }
        </div>
      </div>
    );
  }
}

export default BinHistogram;
