import { IGenericDetailedReportRequestObject } from 'interfaces';
import { InteractionInputDTO } from 'views/individual-reports/customized-report/customized-report-helpers/CustomizedReportInterfaces';
import HttpBase from './http.base';
import AppConstants from '../constants.js';

class HttpLegend extends HttpBase {
  getLegend = (data:IGenericDetailedReportRequestObject) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/legend/get-legend`, data);
  };

  setInteractionData = (data:InteractionInputDTO) : Promise<any> => {
    return this.POST(`${AppConstants.baseURL}/api/legend/set-interaction-data`, data);
  };
}

const httpLegend = new HttpLegend();
export { httpLegend };
