/* eslint-disable no-use-before-define */
import React, { ReactElement } from 'react';
import arrayMove from 'array-move';
import {
  DefaultPolicyStepSettings, IPolicyStep, ISWMSource, ServiceType, ServiceTypes,
} from 'interfaces';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Row, Col } from 'react-bootstrap';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import Heading from '../../wrapped-component/hint-controls/Heading';
import EditPanel from '../../template-component/edit-panel/EditPanel';
import CreateSWMRule from '../../rule/create-swm-rule/CreateSWMRule';
import CreatePATRule from '../../rule/create-pat-rule/CreatePATRule';
import AppConstants from '../../../constants.js';
import PolicyStep from '../policy-step/PolicyStep';
import CreateAMGRule from '../../rule/create-amg-rule/CreateAMGRule';

interface IPolicyStepListProps {
  searchCriteria?: any[];
  steps: IPolicyStep [];
  getPolicyStepListComponentData: any;
}

interface IPolicyStepListState {
  steps: IPolicyStep[];
  editPanel: ReactElement;
}

const SortableItem = SortableElement(({ value }: any) => value);

const SortableList = SortableContainer(({ items, steps, addFirst }: any) => {
  return (
    <div className="policy-step-container background-color-light border-radius-4 mt6 p10">
      <Heading size={5} className="mt10 mb26">Policy Stages</Heading>
      {steps !== undefined
      && (
        <Row className="ml10 mr10">
          <Col lg={12}>
            {steps.length === 0
                      && (
                        <div
                          className="w-100 h30 d-flex align-items-center mt20 justify-content-between pb300"
                        >
                          <div className="break-top flex-30 d-inline-block" />
                          <div className="flex-30 text-center">
                            <CustomizedDropdown
                              avoidUpdateOnSelect
                              full
                              variant="outline-dark"
                              onChange={addFirst}
                              list={ServiceTypes}
                            />
                          </div>
                          <div className="break-top flex-30 d-inline-block" />
                        </div>
                      )}
          </Col>
        </Row>
      )}
      {items.map((value: any, index: number) => {
        return <SortableItem key={value.props.step.hocKey} index={index} value={value} />;
      })}
    </div>
  );
});

class PolicyStepList extends React.Component<IPolicyStepListProps, IPolicyStepListState> {
  constructor(props:IPolicyStepListProps) {
    super(props);
    this.state = {
      steps: [],
      editPanel: <></>,
    };
  }

  componentDidUpdate(prevProps:IPolicyStepListProps) {
    if (prevProps !== this.props) {
      const { steps } = this.props;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ steps }, this.onChange);
    }
  }

  onChange = () => {
    const { steps } = this.state;
    const { getPolicyStepListComponentData } = this.props;
    getPolicyStepListComponentData(steps);
  };

  addStep = (type: string, addIndex: number) => {
    const { steps } = this.state;
    steps.splice(addIndex + 1, 0, {
      hocKey: (Math.max(...steps.map((x) => x.Sequence)) + 1).toString(),
      OutputReference: '',
      IsNotificationEnabled: false,
      CreatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      UpdatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      Type: type,
      Sequence: 0,
      Rules: [],
      isOpen: true,
      stepOwner: '',
      additionalEmail: '',
      StepSettings: JSON.parse(JSON.stringify(DefaultPolicyStepSettings[type as keyof typeof DefaultPolicyStepSettings])),
    });
    for (let i = 0; i < steps.length; i += 1) {
      steps[i].Sequence = i;
    }

    this.setState({ steps }, this.onChange);
  };

  deleteStep = (index: number) => {
    const { steps } = this.state;
    steps.splice(index, 1);
    for (let i = 0; i < steps.length; i += 1) {
      steps[i].Sequence = i;
    }

    this.setState({ steps }, this.onChange);
  };

  addFirst = (type: string) => {
    const { steps } = this.state;
    steps.push({
      hocKey: (Math.max(...steps.map((x) => x.Sequence)) + 1).toString(),
      OutputReference: '',
      IsNotificationEnabled: false,
      CreatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      UpdatedBy: {
        Id: AppConstants.user.Id.toString(),
      },
      CreatedOn: new Date(),
      UpdatedOn: new Date(),
      Type: type,
      Sequence: 0,
      Rules: [],
      isOpen: true,
      stepOwner: '',
      additionalEmail: '',
      StepSettings: JSON.parse(JSON.stringify(DefaultPolicyStepSettings[type as keyof typeof DefaultPolicyStepSettings])),
    });

    this.setState({ steps }, this.onChange);
  };

  onStepSortEnd = ({ oldIndex, newIndex }: any) => {
    let { steps } = this.state;
    steps = arrayMove(steps, oldIndex, newIndex);
    for (let i = 0; i < steps.length; i += 1) {
      steps[i].Sequence = i;
    }

    this.setState({ steps }, this.onChange);
  };

  updateStepSettings = (step: IPolicyStep) => {
    const { steps } = this.state;
    steps[step.Sequence].StepSettings = step.StepSettings;

    this.setState({ steps }, this.onChange);
  };

  updateStepExpressions = (step: IPolicyStep) => {
    const { steps } = this.state;
    steps[step.Sequence].expressions = step.expressions;

    this.setState({ steps }, this.onChange);
  };

  attachSavedPATRuleSetToStep = (step: IPolicyStep) => {
    const { steps } = this.state;
    steps[step.Sequence].StepSettings.patRuleSet = step.StepSettings.patRuleSet;
    this.setState({ steps }, this.onChange);
  };

  closeEditPanel = () => {
    let { editPanel } = this.state;
    editPanel = <></>;
    this.setState({ editPanel });
  };

  attachSavedRuleToStep = (sequence: number, ruleId: string) => {
    const { steps } = this.state;
    steps[sequence].Rules.push(ruleId);

    this.setState({ steps }, this.onChange);
  };

  addRule = (sequence: number, ruleType: string, updateFocus: any, focus: boolean, id: string) => {
    let { editPanel } = this.state;
    const { searchCriteria } = this.props;
    const { steps } = this.state;
    if (ruleType === ServiceType.SWM) {
      editPanel = (
        <EditPanel
          size="60"
          show
          position="right"
          handleClose={() => {
            this.closeEditPanel();
            updateFocus(!focus);
          }}
          content={(
            <CreateSWMRule
              closeRuleComponent={() => {
                this.closeEditPanel();
                updateFocus(!focus);
              }}
              policyStepSequence={sequence}
              getSavedRule={this.attachSavedRuleToStep}
              sources={steps.find((x) => x.Sequence === sequence)!.StepSettings?.sources.map((x: ISWMSource) => [x.alias, x.alias])}
            />
          )}
        />
      );
    } else if (ruleType === ServiceType.PAT) {
      editPanel = (
        <EditPanel
          size="60"
          show
          position="right"
          handleClose={() => {
            this.closeEditPanel();
            updateFocus(!focus);
          }}
          content={(
            <CreatePATRule
              closeRuleComponent={() => {
                this.closeEditPanel();
                updateFocus(!focus);
              }}
              searchCriteria={searchCriteria}
              policyStepSequence={sequence}
              getSavedRule={this.attachSavedRuleToStep}
              id={id !== '' ? id : undefined}
            />
          )}
        />
      );
    } else if (ruleType === ServiceType.AMG) {
      editPanel = (
        <EditPanel
          size="70"
          show
          position="right"
          handleClose={() => {
            this.closeEditPanel();
            updateFocus(!focus);
          }}
          content={(
            <CreateAMGRule
              closeRuleComponent={() => {
                this.closeEditPanel();
                updateFocus(!focus);
              }}
              policyStepSequence={sequence}
              getSavedRule={this.attachSavedRuleToStep}
              id={id !== '' ? id : undefined}
            />
          )}
        />
      );
    }
    this.setState({ editPanel });
  };

  render() {
    const { searchCriteria } = this.props;
    const { steps, editPanel } = this.state;
    return (
      <>
        <SortableList
          addFirst={this.addFirst}
          steps={steps}
          items={steps.map((item: IPolicyStep) => (
            <PolicyStep
              searchCriteria={searchCriteria}
              step={item}
              title={item.Type}
              addStep={this.addStep}
              deleteStep={this.deleteStep}
              addRule={this.addRule}
              updateStepSettings={this.updateStepSettings}
              updateStepExpressions={this.updateStepExpressions}
              attachSavedPATRuleSetToStep={this.attachSavedPATRuleSetToStep}
              policySteps={steps}
              updateRules={(step, rules) => {
                steps[step.Sequence].Rules = rules;

                this.setState({ steps }, this.onChange);
              }}
            />
          ))}
          onSortEnd={this.onStepSortEnd}
          useDragHandle
        />

        {editPanel}
      </>
    );
  }
}

export default PolicyStepList;
