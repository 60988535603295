import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import PaginatedDatagrid, { PaginatedDatagridMeta } from 'views/control-tower/PaginatedDatagrid';
import Label from '../../../../wrapped-component/hint-controls/Label';
import CustomizedDropdown from '../../../../wrapped-component/customized-dropdown/CustomizedDropdown';

interface IParameterGridProps {
  columnsWidth?: number;
  height?: number;
  width?: number;
  selectedTestParameterIds: string[];
  onRowSelectionHandler: (selectionChangedArgs: any) => void;
  removeUnwantedSelectedTestParameters?: (patRuleSetType: string) => void;
  allTestParameters: any;
  patRuleSetType: any;
  isSelectionSingle?: boolean;
  onTestParametersLoaded: (data: any[]) => void,
  httpLoadTestParametersCallback: (datagridMeta: PaginatedDatagridMeta) => Promise<any>,
  pageSize? : number,
  showScrollBar?: boolean,
}

interface IParameterGridState {
  testParameterDisplayOption: string,
  filteredTestParameters: any,
}

class ParameterGrid extends Component<IParameterGridProps, IParameterGridState> {
  private testParameterDisplayOptions = [
    ['AUTOMOTIVE', 'Automotive'],
    // ['INDUSTRIAL', 'Industrial'],
    ['SHOW_ALL', 'Show All'],
  ];

  constructor(props:any) {
    super(props);
    const { allTestParameters, patRuleSetType } = this.props;
    this.state = {
      testParameterDisplayOption: patRuleSetType,
      // filteredTestParameters: this.getRequiredTestParameters(allTestParameters, patRuleSetType),
      filteredTestParameters: allTestParameters,
    };
  }

  getRequiredTestParameters = (filteredTestParameters: any, patRuleSetType: string) => {
    let requiredTestParameters: any = [];
    switch (patRuleSetType) {
      case 'AUTOMOTIVE':
        requiredTestParameters = filteredTestParameters.slice(0, 10);
        break;
      case 'INDUSTRIAL':
        requiredTestParameters = filteredTestParameters.slice(0, 5);
        break;
      case 'SHOW_ALL':
        requiredTestParameters = filteredTestParameters;
        break;
      default:
        break;
    }
    return requiredTestParameters;
  };

  testParameterDisplayOptionHandler = (value: string) => {
    const { allTestParameters, removeUnwantedSelectedTestParameters } = this.props;
    const { testParameterDisplayOption: oldValue } = this.state;
    this.setState({
      testParameterDisplayOption: value,
      filteredTestParameters: this.getRequiredTestParameters(allTestParameters, value),
    }, () => {
      if (removeUnwantedSelectedTestParameters) removeUnwantedSelectedTestParameters(oldValue);
    });
  };

  render() {
    const {
      height, isSelectionSingle, selectedTestParameterIds, onRowSelectionHandler, onTestParametersLoaded, httpLoadTestParametersCallback, pageSize, width, columnsWidth, showScrollBar,
    } = this.props;
    const { testParameterDisplayOption, filteredTestParameters } = this.state;

    return (
      <>
        <Row className="mb20">
          <Col lg={6} className="custom-form">
            <Label
              labelTitle="Pre-Defined Test Parameters Library"
              labelPosition="left-middle"
              labelSize="30"
              fieldSize="70"
              errorSize="0"
            >
              <CustomizedDropdown
                selectedValue={testParameterDisplayOption}
                onChange={this.testParameterDisplayOptionHandler}
                full
                variant="clear"
                list={this.testParameterDisplayOptions}
              />
            </Label>
          </Col>
        </Row>

        <PaginatedDatagrid
          pageSize={pageSize || 100}
          height={height}
          width={width}
          columnsWidth={columnsWidth}
          showScrollBar={showScrollBar}
          onSelectionChanged={onRowSelectionHandler}
          keyExpr="test_parameter_id"
          selectedRowKeys={selectedTestParameterIds}
          selection={isSelectionSingle === true ? { mode: 'single' } : { mode: 'multiple' }}
          httpLoadDataCallback={httpLoadTestParametersCallback}
          updateParentDataAfterLoad={onTestParametersLoaded}
          columns={[
            {
              caption: 'Test #',
              dataField: 'test_parameter_number',
              dataType: 'number',
            },
            {
              caption: 'Test Name',
              dataField: 'test_parameter_name',
              dataType: 'string',
            },
            {
              caption: 'Test Unit',
              dataField: 'test_parameter_test_unit',
              dataType: 'string',
            },
            {
              caption: 'Res Scale',
              dataField: 'test_parameter_res_scale',
              dataType: 'number',
            },
            {
              caption: 'Low Control Limit',
              dataField: 'test_parameter_low_ctrl_limit',
              dataType: 'number',
            },
            {
              caption: 'High Control Limit',
              dataField: 'test_parameter_high_ctrl_limit',
              dataType: 'number',
            },
            {
              caption: 'Low Spec Limit',
              dataField: 'test_parameter_low_spec_limit',
              dataType: 'number',
            },
            {
              caption: 'High Spec Limit',
              dataField: 'test_parameter_high_spec_limit',
              dataType: 'number',
            },
          ]}
        />
      </>
    );
  }
}

export default ParameterGrid;
