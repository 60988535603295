/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PlotlyChartDesigner from 'components/reports/plotly-designer/PlotlyChartDesigner';
import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import { ContextMenu, ScrollView } from 'devextreme-react';
import { Container, Row, Col } from 'react-bootstrap';
import NestedGraphLabelUtility from 'views/individual-reports/NestedGraphLabelUtility';
import _ from 'lodash';
import CustomizedRawDataGrid, { GRID_RENDERING_OPTIONS } from 'components/reports/v2.0/raw-data-grid/CustomizedRawDataGrid';
import GeneralUtils, { ReportTypeConstants as ReportType } from 'GeneralUtils';
import { dxContextMenuItem } from 'devextreme/ui/context_menu';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState, } from '../CustomizedReportGraph';
import { IGraphDataHelperPreferences, IGraphMode } from '../customized-report-helpers/CustomizedReportHelper';
import {
  ICombinedGraphData, ICombinedGraphDataWithReportHeaders, IError, SeriesTypeNames,
} from '../customized-report-helpers/CustomizedReportInterfaces';
import { IDrilledGraphData, IGraphCoordinates, ILimitLinesVisualizationFuncObj, } from '../../../../interfaces';
import { IFieldValue } from '../../../../components/utility-component/report-headers/ReportHeaderBuilder';
import { ILineDict } from '../customized-report-helpers/action-items-sheet/ActionItemsSheet';
import { ReportCollectionPreferences } from '../customized-report-helpers/CustomizedReportMapping';

export class GraphingUtils {
  private DECIMAL_MAX = 79228162514264337593543950335; // NEEDED for comparison with max value from backend

  getPlotDrilledGraphData = (data: IGraphCoordinates) => {
    const xaxis: { [key: string]: any } = {};
    const yaxis: { [key: string]: any } = {};
    let xValue: number[] = [];
    if (data) {
      xValue = Array.from(Array(data.x.length)
        .keys()); // FIXME!!
      xaxis.tickvals = xValue;
      xaxis.ticktext = data.x;
      xaxis.title = {
        text: 'x Axis',
      };
      yaxis.ticktext = data.y;
      yaxis.title = {
        text: 'y Axis',
      };
    }

    return (
      <div>
        <PlotlyChartDesigner
          showBorder={false}
          onClick={() => {
          }}
          dataSources={{
            Bins: [],
            Group: [],
            Values: [],
            Colors: [],
          }}
          dataSourceOptions={[]}
          className="combined-bin-histogram-report w-100"
          useResizeHandler
          data={[{
            x: xValue,
            y: data.y,
            type: 'bar',
            mode: 'markers',
            name: 'x',
            marker: {
              opacity: 1,
              color: [
                '#90EE90',
                '#E53506',
                '#9C45F4',
                '#796DF2',
                '#475995',
                '#C5C442',
                '#EDADE6',
                '#D2A105',
                '#B2A2A3',
                '#37679F',
                '#B90D21',
              ],
            },
          }]}
          config={{
            responsive: true,
          }}
          layout={{
            autosize: true,
            height: 520,
            margin: {
              t: 30,
            },
            bargap: 0.5,
            showlegend: false,
            xaxis,
            yaxis,
          }}
        />
      </div>
    );
  };

  getCumulativeValues = (y: any[]) => {
    const result: any[] = [];
    for (let i = 0; i < y.length; i += 1) {
      result[i] = (i === 0) ? y[i] : result[i - 1] + y[i];
    }
    return result;
  };

  isOneGraph = (data: any, isGraphSingle: boolean) => {
    if (isGraphSingle) {
      return true;
    }
    return data.length === 1;
  }

  generateGraphData = (props: ICustomizedReportGraphProps, graphData: ICombinedGraphData | IDrilledGraphData[], classMembers: any, preferences: IGraphDataHelperPreferences, interactionIndicator: any) => {
    const {
      report, seriesType, patLimitLines, patParamCtrlLimitLines, waferSelection,
    } = props;
    const colorHEXToRGBA = (hex: string, alpha: number) => {
      if (hex) {
        const hexColor = hex.replaceAll('#', '');
        const r = parseInt(hexColor.substr(0, 2), 16);
        const g = parseInt(hexColor.substr(2, 2), 16);
        const b = parseInt(hexColor.substr(4, 2), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }
      return `rgba(${'FFFFFF'}, ${alpha})`;
    };
    const groupingApplied: boolean = props.groupingSortingListStore.grouping.length > 0;

    const showAddedLines = (groupingApplied ? preferences.showAddedLines.grouping.show : preferences.showAddedLines.noGrouping.show);

    const data: any[] = [];
    let lineX: any[] = [];
    let lineY: any[] = [];
    const markersX: any[] = [];
    const markersY: any[] = [];
    const markersText: any[] = [];
    const graphCount: any = (graphData as ICombinedGraphData).graphs.length;

    let yAxisCount = 1;
    (graphData as ICombinedGraphData).graphs.forEach(
      (graphCoordinate: any, index: number) => {
        const selectedpoints = classMembers.plotSelectedPointsForGroup(
          index,
          graphCoordinate.xValue,
          graphCoordinate.y,
          preferences.graphType,
          report.actor,
        );
        const hexColor = colorHEXToRGBA(graphCoordinate.colors[0], 0.25);
        let calculatedY = graphCoordinate.y;
        let markDataOnGraph = true;
        let calculatedMin;
        let calculatedLowerQuartile;
        let calculatedMean;
        let calculatedMedian;
        let calculatedUpperQuartile;
        let calculatedMax;
        let defaultPointPos;
        let defaultJitter;
        let defaultBoxPoints;
        let defaultHoverOn;
        if (preferences.graphType === 'box') {
          if (graphCoordinate.z[0][0] === this.DECIMAL_MAX) {
            markDataOnGraph = false;
          } else {
            calculatedMin = [graphCoordinate.z[0][0]];
            calculatedLowerQuartile = [graphCoordinate.z[0][1]];
            calculatedMean = [graphCoordinate.z[0][2]];
            calculatedMedian = [graphCoordinate.z[0][3]];
            calculatedUpperQuartile = [graphCoordinate.z[0][5]];
            calculatedMax = [graphCoordinate.z[0][6]];
            defaultHoverOn = 'points';
            if (preferences.showDies) {
              defaultBoxPoints = 'all';
              defaultPointPos = -1.7;
              defaultJitter = 0.5;
            } else {
              // eslint-disable-next-line no-param-reassign
              calculatedY = undefined;
            }
          }
        }

        const requiredData = this.getOnlyGraphData(data);

        if (markDataOnGraph) {
          const plotData: any = {
            x: graphCoordinate.xValue,
            y: calculatedY,
            lowerfence: calculatedMin,
            q1: calculatedLowerQuartile,
            mean: calculatedMean,
            median: calculatedMedian,
            q3: calculatedUpperQuartile,
            upperfence: calculatedMax,
            pointpos: defaultPointPos,
            jitter: defaultJitter,
            boxpoints: defaultBoxPoints,
            type: preferences.graphType,
            mode: graphCoordinate.isSPCLimitLine ? ('lines' as IGraphMode) : preferences.graphMode,
            selectedpoints,
            hoveron: defaultHoverOn,
            name: '',
            text: preferences.textOnHover ? preferences.textOnHover[index] : undefined,
            hovertemplate: graphCoordinate.isSPCLimitLine ? null : groupingApplied === true ? preferences.hoverTemplate?.grouping : preferences.hoverTemplate?.noGrouping,
            hoverinfo: 'y',
            hovermode: 'closest',
            marker: {
              line: {
                color: '#000000',
                width: !interactionIndicator.selection ? 0 : 2,
              },
              opacity: preferences.graphType === 'scattergl' ? [preferences.markerOpacity] : preferences.markerOpacity,
              size: graphCoordinate.sizes && graphCoordinate.sizes.length !== 0
                ? graphCoordinate.sizes
                : 10,
              color: preferences.repeatMarkerColor
                ? graphCoordinate.colors[0]
                : graphCoordinate.colors,
              symbol: graphCoordinate.symbols && graphCoordinate.symbols.length !== 0
                ? graphCoordinate.symbols
                : undefined,
            },
            selected: {
              marker: {
                opacity: preferences.graphType === 'scattergl' ? [1] : 1,
                size: interactionIndicator.selection ? 15 : 10,
              },
            },
            line: {
              width: 2,
              color: preferences.repeatMarkerColor
                ? hexColor
                : preferences.graphType !== 'box' && preferences.graphType !== 'scatter' ? graphCoordinate.colors : graphCoordinate.colors[0],
            },
            xaxis: `x${GeneralUtils.getAxisReferenceIndex(index, GeneralUtils.isSingleGraph(report.actor, seriesType), report.actor)}`,
            yaxis: `y${GeneralUtils.getAxisReferenceIndex(index, GeneralUtils.isSingleGraph(report.actor, seriesType), report.actor)}`,
          };
          data.push(plotData);

          // Add range markers for aggregated data in parametric box plot
          if (preferences.showAddedMarkers && preferences.graphType === 'box') {
            markersX.push(+graphCoordinate.xValue[0]);
            markersX.push(+graphCoordinate.xValue[0]);
            markersX.push(+graphCoordinate.xValue[0]);
            markersX.push(+graphCoordinate.xValue[0]);

            markersY.push(graphCoordinate.z[0][11]);
            markersY.push(graphCoordinate.z[0][12]);
            markersY.push(graphCoordinate.z[0][13]);
            markersY.push(graphCoordinate.z[0][14]);

            markersText.push(`${graphCoordinate.z[0][7]} dies b/w min and q1`);
            markersText.push(`${graphCoordinate.z[0][8]} dies b/w q1 and median`);
            markersText.push(`${graphCoordinate.z[0][9]} dies b/w median and q3`);
            markersText.push(`${graphCoordinate.z[0][10]} dies b/w q3 and max`);
          }

          // Add connecting lines coordinates to variables
          if (showAddedLines && preferences.graphType !== 'box') {
            lineX = [...lineX, ...graphCoordinate.xValue];
            lineY = [...lineY, ...graphCoordinate.y];
          }

          if (showAddedLines && preferences.graphType === 'box') {
            lineX.push(graphCoordinate.xValue[0]);
            lineY.push(graphCoordinate.z[0][3]);
          }
        }
        yAxisCount += 1;
      },
    );

    const lengthOfInitialData = data.length;

    // Show range counts for parametric box plot
    if (preferences.showAddedMarkers && preferences.graphType === 'box') {
      data.push({
        x: markersX,
        y: markersY,
        type: 'scatter',
        mode: 'markers',
        text: markersText,
        name: 'dies in parametric box plot ranges',
        marker: {
          symbol: 'diamond-open',
          size: graphCount <= 5 ? ((1 / graphCount) * 10) : 5,
          color: 'blue',
          line: {
            color: 'rgb(231, 99, 250)',
            width: 3,
          },
        },
        hovermode: 'closest',
        hoverinfo: 'text',
      });
    }

    if (showAddedLines) {
      if (props.report.actor === ReportType.PARAMETRIC_FAILURE) {
        for (let i = 0, j = 0; i < (graphData as ICombinedGraphData).graphs.length; i += 1) {
          if ((graphData as ICombinedGraphData).graphs[i]?.yCoordinateCustom.length > 0) {
            data.push({
              x: this.getXCoordForCumulativeContributionLine(props.report.actor, (graphData as ICombinedGraphData), i),
              y: this.getYCoordForCumulativeContributionLine(props.report.actor, (graphData as ICombinedGraphData), i),
              name: groupingApplied ? preferences.showAddedLines.grouping.name : preferences.showAddedLines.noGrouping.name,
              text: groupingApplied ? preferences.showAddedLines.grouping.name.toString() : preferences.showAddedLines.noGrouping.name.toString(),
              type: 'scatter',
              mode: 'lines',
              hoverTemplate: '<b>%{text}: </b> %{y}%',
              hoverinfo: 'text+y',
              line: {
                dash: 'dot',
                width: 1,
                color: 'black',
              },
              offsetgroup: 0,
              xaxis: `x${i === 0 ? '' : j + 1}`,
              yaxis: `y${(j + 1) + lengthOfInitialData / 2}`,
            });
            j += 1;
          }
        }
      } else if (props.report.actor === ReportType.BIN_PARETO) {
        for (let i = 0; i < (graphData as ICombinedGraphData).graphs.length; i += 1) {
          data.push({
            x: this.getXCoordForCumulativeContributionLine(props.report.actor, (graphData as ICombinedGraphData), i),
            y: this.getYCoordForCumulativeContributionLine(props.report.actor, (graphData as ICombinedGraphData), i),
            name: groupingApplied ? preferences.showAddedLines.grouping.name : preferences.showAddedLines.noGrouping.name,
            text: groupingApplied ? preferences.showAddedLines.grouping.name.toString() : preferences.showAddedLines.noGrouping.name.toString(),
            type: 'scatter',
            mode: 'lines',
            hoverTemplate: '<b>%{text}: </b> %{y}%',
            hoverinfo: 'text+y',
            line: {
              dash: 'dot',
              width: 1,
              color: 'black',
            },
            offsetgroup: 0,
            xaxis: `x${i === 0 ? '' : i + 1}`,
            yaxis: `y${(i + 1) + lengthOfInitialData}`,
          });
        }
      } else {
        data.push({
          x: lineX.filter((n) => n != null),
          y: lineY.filter((n) => n != null),
          name: groupingApplied ? preferences.showAddedLines.grouping.name : preferences.showAddedLines.noGrouping.name,
          text: groupingApplied ? preferences.showAddedLines.grouping.name.toString() : preferences.showAddedLines.noGrouping.name.toString(),
          type: 'scatter',
          mode: 'lines',
          line: {
            dash: 'dot',
            width: 1,
            color: 'black',
          },
          hoverinfo: 'text',
        });
      }
    }
    if (patLimitLines && patLimitLines.length > 0 && patParamCtrlLimitLines && patParamCtrlLimitLines.length > 0) {
      return [data[0]];
    }
    return data;
  };

  getXCoordForCumulativeContributionLine = (reportActor: string, graphData: ICombinedGraphData, labelRowIndex: number) => {
    return ((graphData as ICombinedGraphData).graphs[labelRowIndex].xValue as any[]).filter((n) => n != null);
  };

  getYCoordForCumulativeContributionLine = (reportActor: string, graphData: ICombinedGraphData, labelRowIndex: number) => {
    if (reportActor === ReportType.BIN_PARETO) {
      let sum = 0;
      const yValues = (graphData as ICombinedGraphData).graphs[labelRowIndex].y.filter((n) => n != null);
      yValues.forEach((y : number) => {
        sum += y;
      });
      return this.getCumulativeValues(yValues.map((y: number) => {
        return (y * 100) / sum;
      }));
    }
    return this.getCumulativeValues(((graphData as ICombinedGraphData).graphs[labelRowIndex].yCoordinateCustom as number[]).filter((n) => n != null));
  };

  getFinalPlotlyWidth = (widthPerGraph: number, layoutCount: number) => {
    if (layoutCount > 0 && layoutCount <= 3) {
      return widthPerGraph;
    }
    return (widthPerGraph / 3) * layoutCount;
  }

  getSecondaryYAxisLayout = (yIndexLayout: any, yAxisTitle: any, xAxisOverlayIndex: any, yAxisOverlayIndex: any) => {
    return (() => {
      const yAxisClone = _.cloneDeep(yIndexLayout);
      yAxisClone.side = 'right';
      yAxisClone.anchor = `x${xAxisOverlayIndex}`;
      yAxisClone.overlaying = `y${yAxisOverlayIndex}`;
      yAxisClone.title.text = yAxisTitle;
      yAxisClone.rangemode = 'tozero';
      yAxisClone.range = [0, 110];
      return yAxisClone;
    });
  }

  triggerOnClickForContextMenu = (type: string, classMembers: any, report: any) => {
    switch (type) {
      case 'Hide Selected':
        classMembers.reportPublisherEvent(
          'SELECTED',
          'HIDE_GRAPH_AND_LEGEND_ITEMS',
          report.hidingEventPublisher,
        );
        break;
      case 'Hide Similar':
        classMembers.reportPublisherEvent(
          'SIMILAR',
          'HIDE_GRAPH_AND_LEGEND_ITEMS',
          report.hidingEventPublisher,
        );
        break;
      case 'Hide All':
        classMembers.reportPublisherEvent(
          'ALL',
          'HIDE_GRAPH_AND_LEGEND_ITEMS',
          report.hidingEventPublisher,
        );
        break;
      case 'Highlight Selected':
        classMembers.reportPublisherEvent(
          'SELECTED',
          'SELECT_GRAPH_AND_LEGEND_ITEMS',
          report.selectionEventPublisher,
        );
        break;
      case 'Highlight Similar':
        classMembers.reportPublisherEvent(
          'SIMILAR',
          'SELECT_GRAPH_AND_LEGEND_ITEMS',
          report.selectionEventPublisher,
        );
        break;
      case 'Highlight All':
        classMembers.reportPublisherEvent(
          'ALL',
          'SELECT_GRAPH_AND_LEGEND_ITEMS',
          report.selectionEventPublisher,
        );
        break;
      case 'Report Headers':
        classMembers.toggleShowReportHeaderChooser();
        break;
      default:
        break;
    }
  };

  getPlotlyChartDesigner = (
    preferences: any,
    designerSource: any,
    dataSourceOptions: any,
    data: any,
    labelRowHeight: any,
    groupsPlottingInfo: any,
    classMembers: any,
    props: ICustomizedReportGraphProps,
    state: ICustomizedReportGraphState,
    xaxis: any,
    yaxis: any,
    reportPublisherId: string,
  ) => {
    const {
      report,
      reportSessionId,
      axisBreakThresholdFactor,
      seriesType,
    } = props;

    const { widthPerGraph, graphData } = state;

    const requiredData = this.getOnlyGraphData(data);
    const layoutCount = GeneralUtils.isSingleGraph(report.actor, seriesType) ? 1
      : (report.actor === ReportType.PARAMETRIC_FAILURE ? requiredData.length / 2 : requiredData.length);

    const layout: any = {
      grid: {
        rows: 1, columns: layoutCount, pattern: 'independent', xgap: 0.228,
      },
      hovermode: 'closest',
      autosize: true,
      height: 500 + labelRowHeight,
      width: this.getFinalPlotlyWidth(widthPerGraph, layoutCount),
      margin: {
        b: labelRowHeight,
        t: 30,
        l: 60,
        r: 60,
      },
      dragmode: 'select',
      annotations:
        groupsPlottingInfo === undefined
          ? []
          : groupsPlottingInfo.annotations,
      shapes:
        groupsPlottingInfo === undefined
          ? []
          : groupsPlottingInfo.shapes,
      bargap: preferences.barGap !== undefined ? preferences.barGap : 0.5,
      showlegend: false,
      xaxis,
      yaxis,
    };
    if (layoutCount === 1) {
      if (preferences.showCustomTickText) {
        layout['xaxis' as any].ticktext = (graphData as ICombinedGraphData).graphs[0].xText.flat();
        layout['xaxis' as any].tickvals = (graphData as ICombinedGraphData).graphs[0].xValue.flat();
      }
      if (preferences.hasMultiYAxis) {
        layout.yaxis2 = this.getSecondaryYAxisLayout(layout.yaxis, preferences.axisNames.grouping.secondaryYAxisName, '', '')();
      }
    }
    for (let index = 0; index < layoutCount && layoutCount !== 1; index += 1) {
      if (index === 0 && preferences.hasMultiYAxis) {
        layout[`yaxis${(index + 1) + layoutCount}`] = this.getSecondaryYAxisLayout(layout.yaxis, preferences.axisNames.grouping.secondaryYAxisName, '', '')();
      } else if (index !== 0) {
        layout[`xaxis${index + 1}` as any] = { ...xaxis };
        layout[`yaxis${index + 1}` as any] = { ...yaxis };
        if (preferences.hasMultiYAxis) {
          layout[`yaxis${(index + 1) + layoutCount}`] = this.getSecondaryYAxisLayout(layout[`yaxis${index + 1}` as any], preferences.axisNames.grouping.secondaryYAxisName, index + 1, index + 1)();
        }
      }
      if (preferences.showCustomTickText) {
        const referenceIndex = report.actor === ReportType.PARAMETRIC_FAILURE ? index * 2 + 1 : index;
        layout[`xaxis${index === 0 ? '' : index + 1}` as any].ticktext = (graphData as ICombinedGraphData).graphs[referenceIndex].xText.flat();
        layout[`xaxis${index === 0 ? '' : index + 1}` as any].tickvals = (graphData as ICombinedGraphData).graphs[referenceIndex].xValue.flat();
      }
    }
    return (
      // WARNING: If these ids are changed, ensured that the "target" id for context menu is updated as well
      <div style={{ width: '100%' }} id={`${report.actor}-${props.reportIndex}-${reportPublisherId}`}>
        <PlotlyChartDesigner
          reportActor={report.actor}
          id={`${report.actor}-${props.reportIndex}-${reportPublisherId}`}
          isOneGraph={this.isOneGraph(requiredData, GeneralUtils.isSingleGraph(report.actor, seriesType))}
          showBorder={false}
          onDeselect={classMembers.replotGraph}
          dataSources={designerSource}
          dataSourceOptions={dataSourceOptions}
          className="combined-bin-histogram-report w-100"
          useResizeHandler
          onSelected={classMembers.setSelectionData}
          showXAxisBreaks={preferences.showXAxisBreaks}
          showYAxisBreaks={preferences.showYAxisBreaks}
          data={data}
          config={{
            responsive: true,
          }}
          axisBreakThresholdFactor={axisBreakThresholdFactor}
          layout={layout}
        />
        <ContextMenu
          id={`${report.actor}-${props.reportIndex}-${reportPublisherId}-context-menu`}
          onItemClick={(e) => this.triggerOnClickForContextMenu(e.itemData?.text ? e.itemData.text : '', classMembers, report)}
          dataSource={[
            {
              text: 'Hide',
              items: [
                {
                  text: 'Hide Selected',
                },
                {
                  text: 'Hide Similar',
                },
                {
                  text: 'Hide All',
                },
              ],
            },
            {
              text: 'Highlight',
              items: [
                {
                  text: 'Highlight Selected',
                },
                {
                  text: 'Highlight Similar',
                },
                {
                  text: 'Highlight All',
                },
              ],
            },
            {
              text: 'Report Headers',
            },
          ] as dxContextMenuItem[]}
          width={200}
          target={`#${report.actor}-${props.reportIndex}-${reportPublisherId}`}
        />
      </div>
    );
  };

  getAxisLabels = (value: any, props: ICustomizedReportGraphProps) => {
    if (value === 'NONE') {
      return '';
    }
    if (value === 'AGGREGATE_FUNCTION' && props.aggredateFunction && props.aggredateFunction !== 'NONE') {
      return props.aggredateFunction;
    }
    if (value.includes('AGGREGATE_FUNCTION') && value !== 'AGGREGATE_FUNCTION' && props.aggredateFunction && props.aggredateFunction !== 'NONE') {
      return `${value.replace('AGGREGATE_FUNCTION', '')} ${props.aggredateFunction}`;
    }
    return value.replace('AGGREGATE_FUNCTION', '');
  };

  getMaxAndMinXAxisValue = (data: any, reportActor: string) => {
    let xMin = 0;
    let xMax = 0;
    if (reportActor === ReportType.SPC_TREND) {
      data.forEach((graph:any) => {
        if (graph.x.length > xMax) {
          xMax = graph.x.length;
        }
      });
    } else if (reportActor === ReportType.PARAMETRIC_FAILURE) {
      const lastElementIndex = data[0].x.length - 1;
      xMax = data[0].x[lastElementIndex];
    } else if (reportActor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) {
      xMax = Math.max(...data.map((graph: any) => Math.max(...graph.x)));
      xMin = Math.min(...data.map((graph: any) => Math.min(...graph.x)));
    } else if (reportActor === ReportType.PARAMETRIC_BOX_PLOT) {
      xMax = -0.25;
      xMin = -0.55;
    } else {
      data.forEach((graph: any) => {
        if (graph.name === '' || graph.name === undefined) {
          xMax += graph.x.length;
        }
      });
      xMin = Math.min(...data.map((graph: any) => Math.min(...graph.x)));
    }
    xMax = xMax === undefined ? 0 : xMax;
    xMin = xMin === undefined ? 0 : xMin;
    return [xMin, xMax];
  };

  getOnlyGraphData = (data: any[]) => {
    return data.filter((graph: any) => graph.name === '' || graph.name === undefined);
  }

  plotlyGraphGenerationHelper = (
    props: ICustomizedReportGraphProps,
    state: ICustomizedReportGraphState,
    classMembers: any,
    _setStateCallback: any,
    preferences: IGraphDataHelperPreferences,
    summaryStatLines?: IFieldValue[],
    lineDict?: ILineDict,
  ) => {
    const {
      viewMode,
      limitSettingsObj,
      testParameterIndex,
      testParameterSelectedIndex,
      patLimitLines,
      patParamCtrlLimitLines,
      waferSelection,
      seriesType,
      groupingSortingListStore,
      report,
    } = props;

    const {
      graphData,
      reportPublisherId,
      graphIndex,
      interactionIndicator,
    } = state;
    let groupsPlottingInfo: { annotations: any; shapes: any; } | undefined;
    const dataSourceOptions: any = [];

    let labelRowHeight = 25;

    const designerSource: any = {
      Bins: [],
      Group: [],
      Values: [],
      Colors: [],
    };

    const isGrouped = props.groupingSortingListStore.grouping.length > 0;
    let data = null;
    if (
      viewMode === 'COMBINED'
      && (graphData as ICombinedGraphData).labelRows !== undefined
      && (graphData as ICombinedGraphData).graphs.length > 0
    ) {
      let nestedLabelData: any[] = [];
      let showNestedLabels;
      if (isGrouped) {
        showNestedLabels = preferences.showCustomLabelsWhenGrouped;
      } else {
        showNestedLabels = preferences.showCustomLabelsWhenNotGrouped;
      }

      if (showNestedLabels && patLimitLines && patLimitLines.length > 0 && patParamCtrlLimitLines && patParamCtrlLimitLines.length > 0){
        const patData = (graphData as ICombinedGraphData).labelRows;
        if (patData.length > 1) {
          patData.forEach((labelData: any, gIndex: number) => {
            if (!(testParameterIndex) || testParameterIndex[0] === gIndex) {
              nestedLabelData.push(patData[gIndex + 1]);
            }
          });
        } else {
          patData.forEach((labelData: any, gIndex: number) => {
            nestedLabelData.push(patData[gIndex]);
          });
        }
        const groupedLabelRowsCount = Object.keys(_.groupBy(patData, 'yAxisValue')).length;
        labelRowHeight = (groupedLabelRowsCount * 25) + 4.3 * labelRowHeight;
      } else if (showNestedLabels) {
        nestedLabelData = (graphData as ICombinedGraphData).labelRows;
        const groupedLabelRowsCount = Object.keys(_.groupBy((graphData as ICombinedGraphData).labelRows, 'yAxisValue')).length;
        labelRowHeight = (groupedLabelRowsCount * 25) + 4.3 * labelRowHeight;
      }
      data = this.generateGraphData(props, graphData, classMembers, preferences, interactionIndicator);
      const allGraphData = this.getOnlyGraphData(data);
      const actualGraphsCount = GeneralUtils.isSingleGraph(report.actor, seriesType) ? 1
        : (report.actor === ReportType.PARAMETRIC_FAILURE ? allGraphData.length / 2 : allGraphData.length);
      const nestedGraphLabelUtility = new NestedGraphLabelUtility(nestedLabelData, actualGraphsCount);
      const xlabelName = isGrouped ? this.getAxisLabels(preferences.axisNames.grouping.xAxisName, props) : this.getAxisLabels(preferences.axisNames.noGrouping.xAxisName, props);
      const xMinOfData: number[] = [];
      const xMaxOfData: number[] = [];
      if (!GeneralUtils.isSingleGraph(report.actor, seriesType!)) {
        for (let i = 0; i < actualGraphsCount; i += 1) {
          const [currXMinOfData, currXMaxOfData] = this.getMaxAndMinXAxisValue(allGraphData.filter((x: any, index: number) => index === i), report.actor);
          xMinOfData.push(currXMinOfData);
          xMaxOfData.push(currXMaxOfData);
        }
      }
      groupsPlottingInfo = nestedGraphLabelUtility.getData(actualGraphsCount, xMinOfData, xMaxOfData, xlabelName, seriesType, report.actor);
    }

    const xaxis: { [key: string]: any } = {};
    const yaxis: { [key: string]: any } = {};
    if ((graphData as ICombinedGraphData).graphs && preferences.showCustomTickText) {
      xaxis.ticklen = 5;
      xaxis.ticks = 'outside';
      xaxis.tickson = 'boundaries';
      xaxis.tickmode = 'array';
      xaxis.automargin = false;
      yaxis.automargin = false;
    }
    if (isGrouped) {
      yaxis.title = {
        text: this.getAxisLabels(preferences.axisNames.grouping.yAxisName, props),
      };
      xaxis.showticklabels = preferences.showTickText.grouping.xAxis;
      yaxis.showticklabels = preferences.showTickText.grouping.yAxis;
    } else {
      yaxis.title = {
        text: this.getAxisLabels(preferences.axisNames.noGrouping.yAxisName, props),
      };
      xaxis.showticklabels = preferences.showTickText.noGrouping.xAxis;
      yaxis.showticklabels = preferences.showTickText.noGrouping.yAxis;
    }
    let xAxisText = '';
    if (GeneralUtils.isSingleGraph(report.actor, seriesType)) {
      labelRowHeight *= 2;
      xAxisText = this.getAxisLabels(preferences.axisNames.noGrouping.xAxisName, props);
    }
    xaxis.title = {
      text: xAxisText
    }
    if (xAxisText === ''){
      xaxis.title.font = {
        color: 'white'
      }
    }

    if (groupsPlottingInfo !== undefined) {
      data = this.generateGraphData(props, graphData, classMembers, preferences, interactionIndicator);
      const [xMin, xMax]: any = this.getMaxAndMinXAxisValue(this.getOnlyGraphData(data), props.report.actor);
      const extraLines = { index: 0 };

      if (summaryStatLines && lineDict && summaryStatLines.length > 0) {
        const shapes = summaryStatLines.map((fieldValueObj: IFieldValue, index: number) => ({
          key: `summaryStatLineHorizontal ${index}`,
          type: 'line',
          xref: fieldValueObj.value ? 'paper' : 'x',
          yref: 'y',
          x0: fieldValueObj.value ? 0 : (fieldValueObj.valuesX as number[])[0],
          y0: fieldValueObj.value ? fieldValueObj.value : (fieldValueObj.valuesY as number[])[0],
          x1: fieldValueObj.value ? 1 : (fieldValueObj.valuesX as number[])[1],
          y1: fieldValueObj.value ? fieldValueObj.value : (fieldValueObj.valuesY as number[])[1],
          opacity: lineDict[fieldValueObj.name.toUpperCase()].opacity,
          line: {
            color: lineDict[fieldValueObj.name.toUpperCase()].color,
            width: lineDict[fieldValueObj.name.toUpperCase()].width,
            dash: lineDict[fieldValueObj.name.toUpperCase()].dash,
          },
        }));

        const annotations = summaryStatLines.map((fieldValueObj: IFieldValue, index: number) => ({
          key: `summaryStatLineHorizontal ${index}`,
          x: (report.actor === ReportType.PARAMETRIC_BOX_PLOT && fieldValueObj.name === 'Best_Fit_Line' && groupingSortingListStore.grouping.length === 0) ? -0.55 : xMin + ((xMax - xMin) * (index / 9)),
          y: fieldValueObj.value ? fieldValueObj.value : (fieldValueObj.valuesY as number[])[0],
          xref: 'x',
          yref: 'y',
          text: `${fieldValueObj.name}${fieldValueObj.value ? `: ${GeneralUtils.roundNumberAppropriately(fieldValueObj.value, 3)}` : ''}`,
          showarrow: true,
          ax: -30,
          ay: -40,
          font: {
            size: 14,
            color: '#ffffff',
          },
          align: 'center',
          arrowhead: 3,
          arrowsize: 1,
          arrowwidth: 2,
          arrowcolor: '#636363',
          bordercolor: '#000000',
          borderwidth: 1,
          borderpad: 3,
          bgcolor: lineDict[fieldValueObj.name.toUpperCase()].color,
          opacity: 1,
        }));

        extraLines.index += summaryStatLines.length;
        groupsPlottingInfo.annotations = [...groupsPlottingInfo.annotations, ...annotations];
        groupsPlottingInfo.shapes = [...groupsPlottingInfo.shapes, ...shapes];
      }

      const allGraphData: any[] = this.getOnlyGraphData(data);
      const isOneGraph = this.isOneGraph(allGraphData, GeneralUtils.isSingleGraph(report.actor, seriesType));
      let currentExtraLinesIndex = extraLines.index;
      const groupsPlottingInfoAnnotationsLengthBeforeLimits = groupsPlottingInfo!.annotations.length;

      if (Object.keys(limitSettingsObj).length > 0) {
        let plotVerticalLines = false;
        if ((testParameterIndex && testParameterIndex[0] === testParameterSelectedIndex && testParameterIndex.length >= 2) || (preferences.graphType === 'bar')) {
          plotVerticalLines = true;
        }
        const limitLinesVisObj: ILimitLinesVisualizationFuncObj = {
          graphData: (graphData as ICombinedGraphData), graphIndex: 0, limitSettingsObj, plotVerticalLines, reportType: report.actor, graphType: preferences.graphType, seriesType, xMax, xMin,
        };
        allGraphData.forEach((graph: any, gIndex: number) => {
          [limitLinesVisObj.xMin, limitLinesVisObj.xMax] = !isOneGraph
            ? this.getMaxAndMinXAxisValue(this.getOnlyGraphData(allGraphData).filter((x: any, index: number) => index === gIndex), report.actor)
            : [xMin, xMax];
          limitLinesVisObj.graphIndex = gIndex;
          extraLines.index = currentExtraLinesIndex;

          if ((isOneGraph && gIndex === 0) || !isOneGraph) {
            let shapes = this.getLimitLinesVisualization(limitLinesVisObj, 'shapes', 'outlier', 'low', extraLines);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            shapes = this.getLimitLinesVisualization(limitLinesVisObj, 'shapes', 'outlier', 'high', extraLines);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            shapes = this.getLimitLinesVisualization(limitLinesVisObj, 'shapes', 'distribution', 'low', extraLines);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            shapes = this.getLimitLinesVisualization(limitLinesVisObj, 'shapes', 'distribution', 'high', extraLines);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            let annotations = this.getLimitLinesVisualization(limitLinesVisObj, 'annotations', 'outlier', 'low', extraLines);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
            annotations = this.getLimitLinesVisualization(limitLinesVisObj, 'annotations', 'outlier', 'high', extraLines);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
            annotations = this.getLimitLinesVisualization(limitLinesVisObj, 'annotations', 'distribution', 'low', extraLines);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
            annotations = this.getLimitLinesVisualization(limitLinesVisObj, 'annotations', 'distribution', 'high', extraLines);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
          }
        });
      }

      currentExtraLinesIndex += (groupsPlottingInfo!.annotations.length - groupsPlottingInfoAnnotationsLengthBeforeLimits);
      currentExtraLinesIndex = currentExtraLinesIndex > 0 ? currentExtraLinesIndex + 1 : currentExtraLinesIndex;
      if (patLimitLines && patLimitLines.length > 0) {
        const limitLinesVisObj: ILimitLinesVisualizationFuncObj = {
          graphData: (graphData as ICombinedGraphData), graphIndex: 0, limitSettingsObj: patLimitLines, plotVerticalLines: false, reportType: report.actor, graphType: preferences.graphType, seriesType, xMax, xMin,
        };
        allGraphData.forEach((graph: any, gIndex: number) => {
          let plotVerticalLines = false;
          if (preferences.graphType === 'bar') {
            plotVerticalLines = true;
          }
          limitLinesVisObj.plotVerticalLines = plotVerticalLines;
          [limitLinesVisObj.xMin, limitLinesVisObj.xMax] = !isOneGraph
            ? this.getMaxAndMinXAxisValue(this.getOnlyGraphData(allGraphData).filter((x: any, index: number) => index === gIndex), report.actor)
            : [xMin, xMax];
          limitLinesVisObj.graphIndex = gIndex;
          extraLines.index = currentExtraLinesIndex;
          if ((isOneGraph && gIndex === 0) || !isOneGraph) {
            let shapes = this.getPatLimitLinesVisualization(limitLinesVisObj, 'shapes', 'low', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            let annotations = this.getPatLimitLinesVisualization(limitLinesVisObj, 'annotations', 'low', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
            shapes = this.getPatLimitLinesVisualization(limitLinesVisObj, 'shapes', 'high', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            annotations = this.getPatLimitLinesVisualization(limitLinesVisObj, 'annotations', 'high', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
          }
        });
      }

      currentExtraLinesIndex += (groupsPlottingInfo!.annotations.length - groupsPlottingInfoAnnotationsLengthBeforeLimits) + 1;
      currentExtraLinesIndex = currentExtraLinesIndex > 0 ? currentExtraLinesIndex + 1 : currentExtraLinesIndex;
      if (patParamCtrlLimitLines && patParamCtrlLimitLines.length > 0) {
        const limitLinesVisObj: ILimitLinesVisualizationFuncObj = {
          graphData: (graphData as ICombinedGraphData), graphIndex: 0, limitSettingsObj: patParamCtrlLimitLines, plotVerticalLines: false, reportType: report.actor, graphType: preferences.graphType, seriesType, xMax, xMin,
        };
        allGraphData.forEach((graph: any, gIndex: number) => {
          let plotVerticalLines = false;
          if (preferences.graphType === 'bar') {
            plotVerticalLines = true;
          }
          limitLinesVisObj.plotVerticalLines = plotVerticalLines;
          [limitLinesVisObj.xMin, limitLinesVisObj.xMax] = !isOneGraph
            ? this.getMaxAndMinXAxisValue(this.getOnlyGraphData(allGraphData).filter((x: any, index: number) => index === gIndex), report.actor)
            : [xMin, xMax];
          limitLinesVisObj.graphIndex = gIndex;
          extraLines.index = currentExtraLinesIndex;
          if ((isOneGraph && gIndex === 0) || !isOneGraph) {
            let shapes = this.getPatLimitLinesVisualization(limitLinesVisObj, 'shapes', 'low', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            let annotations = this.getPatLimitLinesVisualization(limitLinesVisObj, 'annotations', 'low', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
            shapes = this.getPatLimitLinesVisualization(limitLinesVisObj, 'shapes', 'high', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.shapes = [...groupsPlottingInfo!.shapes, ...shapes];
            annotations = this.getPatLimitLinesVisualization(limitLinesVisObj, 'annotations', 'high', extraLines, waferSelection, testParameterIndex);
            groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
          }
        });
      }

      if (data && data.length > 0 && data[0].type === 'box' && preferences.showAnnotations) {
        const gIndexesCount = data.length === 2 ? 1 : data.length - 2;
        for (let gIndex = 0; gIndex < gIndexesCount; gIndex += 1) {
          const annotations = this.getBoxPlotAnnotations(data, gIndex, gIndexesCount);
          groupsPlottingInfo!.annotations = [...groupsPlottingInfo!.annotations, ...annotations];
        }
      }

      if (props.report.actor === ReportType.SPC_TREND) {
        const annotations = this.getSPCAnnotations(graphData, extraLines, xMax, xMin);
        groupsPlottingInfo.annotations = [...groupsPlottingInfo.annotations, ...annotations];
      }

      if (props.report.actor === ReportType.PARAMETRIC_FAILURE) {
        yaxis.rangemode = 'tozero';
        yaxis.range = [0, 110];
        const annotations = this.getPFRAnnotations(graphData, props.seriesType);
        groupsPlottingInfo.annotations = [...groupsPlottingInfo.annotations, ...annotations];
      }
      return (
        <>
          {this.getPlotlyChartDesigner(
            preferences,
            designerSource,
            dataSourceOptions,
            data,
            labelRowHeight,
            groupsPlottingInfo,
            classMembers,
            props,
            state,
            xaxis,
            yaxis,
            reportPublisherId,
          )}
        </>
      );
    }

    return <></>;
  };

  getBoxPlotAnnotationsFontSize = (graphIndexesCount: number) => {
    if (graphIndexesCount <= 10) {
      if ((graphIndexesCount % 2) === 0) {
        return 15 - graphIndexesCount / 2;
      }
      return 15 - (graphIndexesCount + 1) / 2;
    }
    return 9;
  };

  getBoxPlotAnnotations = (data: any, graphIndex: number, graphIndexesCount: number) => {
    const groupsPlottingInfo: any[] = [];
    const annotationsInfo = [{
      name: 'min',
      accessor: 'lowerfence',
      x: -0.1,
    }, {
      name: 'q1',
      accessor: 'q1',
      x: -0.1,
    }, {
      name: 'mean',
      accessor: 'mean',
      x: -0.2,
    }, {
      name: 'median',
      accessor: 'median',
      x: -0.05,
    }, {
      name: 'q3',
      accessor: 'q3',
      x: -0.15,
    }, {
      name: 'max',
      accessor: 'upperfence',
      x: -0.1,
    }];
    for (let i = 0; i < 6; i += 1) {
      groupsPlottingInfo.push({
        key: `boxPlotHorizontal ${graphIndex} ${i}`,
        x: annotationsInfo[i].x,
        y: data[graphIndex][annotationsInfo[i].accessor][0],
        xref: `x${graphIndex === 0 ? '' : graphIndex + 1}`,
        yref: `y${graphIndex === 0 ? '' : graphIndex + 1}`,
        text: `${annotationsInfo[i].name}: ${GeneralUtils.roundNumberAppropriately(data[graphIndex][annotationsInfo[i].accessor][0], 3)}`,
        showarrow: true,
        ax: -30,
        ay: -40,
        font: {
          size: this.getBoxPlotAnnotationsFontSize(graphIndexesCount),
          color: '#ffffff',
        },
        align: 'center',
        arrowhead: 3,
        arrowsize: 1,
        arrowwidth: 2,
        arrowcolor: '#636363',
        bordercolor: '#000000',
        borderwidth: 1,
        borderpad: 3,
        bgcolor: data[graphIndex].line.color,
        opacity: 0.9,
      });
    }
    return groupsPlottingInfo;
  };

  getSPCAnnotations = (graphData: any, extraLines: any, xMax: number, xMin: number) => {
    const annotations: any = [];
    let prevValue = 0;
    let annotationY = -100;
    graphData.graphs.forEach((graph: any, index: number) => {
      const lastIndex = graph.y.length - 1;
      if (index !== 0) { annotationY = Math.abs(graph.y[lastIndex] - prevValue) > 30 ? annotationY : annotationY + 30; }
      prevValue = graph.y[lastIndex];
      if (graph.graphCaption) {
        annotations.push(
          {
            key: `spcHorizontal ${index}`,
            x: xMax - 1,
            y: graph.y[lastIndex],
            xref: 'x',
            yref: 'y',
            text: graph.graphCaption,
            showarrow: true,
            ax: 30,
            ay: annotationY,
            font: {
              size: 14,
              color: '#ffffff',
            },
            align: 'center',
            arrowhead: 3,
            arrowsize: 1,
            arrowwidth: 2,
            arrowcolor: '#636363',
            bordercolor: '#000000',
            borderwidth: 1,
            borderpad: 3,
            bgcolor: graph.colors[0],
            opacity: 1,
            xanchor: 'left',
          },
        );
      }
    });
    return annotations;
  };

  getPFRAnnotations = (graphData: any, seriesType: SeriesTypeNames) => {
    const annotations: any = [];
    graphData.graphs.forEach((graph: any, index: number) => {
      let cumVal = 0;
      let pfrGraphIndex = 0;
      for (let i = 0; i < graph.y.length; i += 1) {
        cumVal += graph.yCoordinateCustom[i];
        if (index % 2 === 0) {
          annotations.push(
            {
              key: `pfr ${index}`,
              x: i,
              y: cumVal,
              xref: `x${GeneralUtils.getAxisReferenceIndex(index, GeneralUtils.isSingleGraph(ReportType.PARAMETRIC_FAILURE, seriesType), ReportType.PARAMETRIC_FAILURE)}`,
              yref: `y${GeneralUtils.getAxisReferenceIndex(index, GeneralUtils.isSingleGraph(ReportType.PARAMETRIC_FAILURE, seriesType), ReportType.PARAMETRIC_FAILURE)}`,
              text: `${Math.round(cumVal * 100) / 100} %`,
              showarrow: true,
              ax: 20,
              ay: 20,
              font: {
                size: 9,
                color: '#ffffff',
              },
              align: 'center',
              arrowhead: 3,
              arrowsize: 1,
              arrowwidth: 2,
              arrowcolor: '#636363',
              bordercolor: '#000000',
              borderwidth: 1,
              borderpad: 2,
              bgcolor: graph.colors[0],
              opacity: 1,
            },
          );
          if (graphData.labelRows[pfrGraphIndex].xAxisEnd === i) {
            pfrGraphIndex += 1;
            cumVal = 0;
          }
        }
      }
    });
    return annotations;
  };

  getMappedValueToPlotForLimitLinesInBarGraph = (val: number, graphData: ICombinedGraphData, graphIndex: number) => {
    let mappedVal = 0;
    const xTextList = graphData.graphs[graphIndex].xText.map(Number);
    for (let i = 0; i < xTextList.length; i += 1) {
      if (val === xTextList[i]) {
        mappedVal = i;
        break;
      } else if (val < xTextList[i] && ((xTextList[i + 1] === undefined) || (xTextList[i + 1] > val))) {
        const barGap = (xTextList[xTextList.length - 1] - xTextList[0]) / (xTextList.length);
        mappedVal = i - (Math.abs(val - xTextList[i]) / barGap);
        break;
      } else if (val > xTextList[i] && (xTextList[i + 1] === undefined)) {
        const barGap = (xTextList[xTextList.length - 1] - xTextList[0]) / (xTextList.length);
        mappedVal = i + (Math.abs(val - xTextList[i]) / barGap);
        break;
      }
    }
    return mappedVal;
  };

  getMappedValueToPlotForLimitLinesInScatterPlot = (val: number, graphData: ICombinedGraphData, graphIndex: number) => {
    let mappedVal = 0;
    const xTextList = graphData.graphs[graphIndex].xText.map(Number);
    const xValsList = (graphData.graphs[graphIndex].xValue as number[]).map((x) => _.toNumber(x));
    const maxXVal = Math.max(...xValsList);
    const minXVal = Math.min(...xValsList);
    mappedVal = (xValsList[0] * val) / xTextList[0];
    if (mappedVal > maxXVal) {
      mappedVal = (maxXVal + (maxXVal - minXVal) / 60) === 0 ? maxXVal : maxXVal + (maxXVal - minXVal) / 60;
    } else if (mappedVal < minXVal) {
      mappedVal = (minXVal - (maxXVal - minXVal) / 50) === 0 ? minXVal : minXVal - (maxXVal - minXVal) / 50;
    }
    return mappedVal;
  };

  getPlottingValueForLimitLines = (limitLinesVis: ILimitLinesVisualizationFuncObj, limitSetting: any, coordinateType: string, lowOrHigh: string, plottingType: string, index: number, extraLines: any) => {
    let retVal = 0;
    if (plottingType === 'shapes') {
      if (limitLinesVis.plotVerticalLines) {
        if (coordinateType === 'x0' || coordinateType === 'x1') {
          if (lowOrHigh === 'low') {
            retVal = limitSetting.lowVal;
          } else {
            retVal = limitSetting.highVal;
          }
          if (limitLinesVis.graphType === 'bar') {
            retVal = this.getMappedValueToPlotForLimitLinesInBarGraph(retVal, limitLinesVis.graphData, limitLinesVis.graphIndex);
          }
        } else if (coordinateType === 'y0') {
          retVal = 0;
        } else {
          retVal = 1;
        }
      } else if (coordinateType === 'y0' || coordinateType === 'y1') {
        if (lowOrHigh === 'low') {
          retVal = limitSetting.lowVal;
        } else {
          retVal = limitSetting.highVal;
        }
        if (limitLinesVis.graphType === 'bar') {
          retVal = this.getMappedValueToPlotForLimitLinesInBarGraph(retVal, limitLinesVis.graphData, limitLinesVis.graphIndex);
        }
      } else if (coordinateType === 'x0') {
        retVal = limitLinesVis.xMin;
      } else {
        retVal = limitLinesVis.xMax;
      }
    } else if (plottingType === 'annotations') {
      if (limitLinesVis.plotVerticalLines) {
        if (coordinateType === 'x') {
          if (lowOrHigh === 'low') {
            retVal = limitSetting.lowVal;
          } else {
            retVal = limitSetting.highVal;
          }
          if (limitLinesVis.graphType === 'bar') {
            retVal = this.getMappedValueToPlotForLimitLinesInBarGraph(retVal, limitLinesVis.graphData, limitLinesVis.graphIndex);
          }
        } else {
          retVal = Math.min((0.05 + (extraLines.index + index) / 14), 0.9);
        }
      } else if (limitLinesVis.plotVerticalLines === false) {
        if (coordinateType === 'y') {
          if (lowOrHigh === 'low') {
            retVal = limitSetting.lowVal;
          } else {
            retVal = limitSetting.highVal;
          }
          if (limitLinesVis.graphType === 'bar') {
            retVal = this.getMappedValueToPlotForLimitLinesInBarGraph(retVal, limitLinesVis.graphData, limitLinesVis.graphIndex);
          }
        } else {
          retVal = limitLinesVis.xMin + ((limitLinesVis.xMax - limitLinesVis.xMin) * ((extraLines.index + index) / 9));
        }
      }
    }
    return retVal;
  };

  getLimitLinesVisualization = (limitLinesVis: ILimitLinesVisualizationFuncObj, plottingType: string, limitType: string, lowOrHigh: string, extraLines: any) => {
    let groupsPlottingInfo: any[] = [];
    const { graphIndex, plotVerticalLines, limitSettingsObj } = limitLinesVis;
    if (plottingType === 'shapes') {
      groupsPlottingInfo = limitSettingsObj[limitType].filter((limitSetting: any) => limitSetting.index !== 3)
        .filter((limitSetting: any) => limitSetting.selected === true)
        .filter((limitSetting: any) => (lowOrHigh === 'low' ? limitSetting.lowCheck === true && limitSetting.displayLow === true : limitSetting.highCheck === true && limitSetting.displayHigh === true))
        .map((limitSetting: any, index: number) => ({
          key: `limitLines${plotVerticalLines ? 'Vertical' : 'Horizontal'} ${graphIndex} ${index} ${lowOrHigh}`,
          type: 'line',
          xref: `x${graphIndex === 0 ? '' : graphIndex + 1}`,
          yref: plotVerticalLines ? 'paper' : `y${graphIndex === 0 ? '' : graphIndex + 1}`,
          x0: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'x0', lowOrHigh, plottingType, index, extraLines),
          y0: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'y0', lowOrHigh, plottingType, index, extraLines),
          x1: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'x1', lowOrHigh, plottingType, index, extraLines),
          y1: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'y1', lowOrHigh, plottingType, index, extraLines),
          opacity: 1,
          line: {
            color: lowOrHigh === 'low' ? limitSetting.colorLow : limitSetting.colorHigh,
            width: lowOrHigh === 'low' ? limitSetting.thicknessLow : limitSetting.thicknessHigh,
            dash: lowOrHigh === 'low' ? limitSetting.appearanceLow : limitSetting.appearanceHigh,
          },
        }));
    } else if (plottingType === 'annotations') {
      // eslint-disable-next-line no-param-reassign
      groupsPlottingInfo = limitSettingsObj[limitType].filter((limitSetting: any) => limitSetting.index !== 3)
        .filter((limitSetting: any) => limitSetting.selected === true)
        .filter((limitSetting: any) => (lowOrHigh === 'low' ? limitSetting.lowCheck === true && limitSetting.displayLow === true : limitSetting.highCheck === true && limitSetting.displayHigh === true))
        .map((limitSetting: any, index: number) => ({
          key: `limitLines${plotVerticalLines ? 'Vertical' : 'Horizontal'} ${graphIndex} ${index} ${lowOrHigh}`,
          x: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'x', lowOrHigh, plottingType, index, extraLines),
          y: this.getPlottingValueForLimitLines(limitLinesVis, limitSetting, 'y', lowOrHigh, plottingType, index, extraLines),
          xref: `x${graphIndex === 0 ? '' : graphIndex + 1}`,
          yref: plotVerticalLines ? 'paper' : `y${graphIndex === 0 ? '' : graphIndex + 1}`,
          text: lowOrHigh === 'low' ? `${limitSetting.customNameLow}: ${GeneralUtils.roundNumberAppropriately(limitSetting.lowVal, 3)}` : `${limitSetting.customNameHigh}: ${GeneralUtils.roundNumberAppropriately(limitSetting.highVal, 3)}`,
          showarrow: true,
          ax: plotVerticalLines ? -40 : -30,
          ay: plotVerticalLines ? -30 : -40,
          font: {
            size: 14,
            color: '#ffffff',
          },
          align: 'center',
          arrowhead: 3,
          arrowsize: 1,
          arrowwidth: 2,
          arrowcolor: '#636363',
          bordercolor: '#000000',
          borderwidth: 1,
          borderpad: 3,
          bgcolor: lowOrHigh === 'low' ? limitSetting.colorLow : limitSetting.colorHigh,
          opacity: 1,
        }));
      // eslint-disable-next-line no-param-reassign
      extraLines.index += limitLinesVis.limitSettingsObj[limitType].filter((limitSetting: any) => limitSetting.index !== 3)
        .filter((limitSetting: any) => limitSetting.selected === true)
        .filter((limitSetting: any) => (lowOrHigh === 'low' ? limitSetting.lowCheck === true && limitSetting.displayLow === true : limitSetting.highCheck === true && limitSetting.displayHigh === true)).length;
    }
    return groupsPlottingInfo;
  };

  getPatLimitLinesVisualization = (limitLinesVis: ILimitLinesVisualizationFuncObj, plottingType: string, lowOrHigh: string, extraLines: any, waferSelection: any, testParameterIndex: any) => {
    const shapesOrAnnotations: any[] = [];
    const { graphIndex, plotVerticalLines } = limitLinesVis;
    if (plottingType === 'shapes') {
      limitLinesVis.limitSettingsObj.forEach((patLimitLine: any, waferIndex: number) => {
        if (graphIndex >= 0 && patLimitLine.waferId === waferSelection[testParameterIndex[0]]) {
          shapesOrAnnotations.push({
            key: `patLimitLines${plotVerticalLines ? 'Vertical' : 'Horizontal'} ${graphIndex} ${waferIndex} ${lowOrHigh}`,
            type: 'line',
            xref: `x${graphIndex === 0 ? '' : graphIndex + 1}`,
            yref: plotVerticalLines ? 'paper' : `y${graphIndex === 0 ? '' : graphIndex + 1}`,
            x0: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'x0', lowOrHigh, plottingType, waferIndex, extraLines),
            y0: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'y0', lowOrHigh, plottingType, waferIndex, extraLines),
            x1: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'x1', lowOrHigh, plottingType, waferIndex, extraLines),
            y1: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'y1', lowOrHigh, plottingType, waferIndex, extraLines),
            opacity: 1,
            line: {
              color: lowOrHigh === 'low' ? patLimitLine.colorLow : patLimitLine.colorHigh,
              width: lowOrHigh === 'low' ? patLimitLine.thicknessLow : patLimitLine.thicknessHigh,
              dash: lowOrHigh === 'low' ? patLimitLine.appearanceLow : patLimitLine.appearanceHigh,
            },
          });
        }
      });
    } else if (plottingType === 'annotations') {
      limitLinesVis.limitSettingsObj.forEach((patLimitLine: any, waferIndex: number) => {
        if (graphIndex >= 0 && patLimitLine.waferId === waferSelection[testParameterIndex[0]]) {
          shapesOrAnnotations.push({
            key: `patLimitLines${plotVerticalLines ? 'Vertical' : 'Horizontal'} ${graphIndex} ${waferIndex} ${lowOrHigh}`,
            x: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'x', lowOrHigh, plottingType, waferIndex, extraLines),
            y: this.getPlottingValueForLimitLines(limitLinesVis, patLimitLine, 'y', lowOrHigh, plottingType, waferIndex, extraLines),
            xref: `x${graphIndex === 0 ? '' : graphIndex + 1}`,
            yref: plotVerticalLines ? 'paper' : `y${graphIndex === 0 ? '' : graphIndex + 1}`,
            text: lowOrHigh === 'low' ? `${patLimitLine.customNameLow}: ${GeneralUtils.roundNumberAppropriately(patLimitLine.lowVal, 3)}` : `${patLimitLine.customNameHigh}: ${GeneralUtils.roundNumberAppropriately(patLimitLine.highVal, 3)}`,
            showarrow: true,
            ax: plotVerticalLines ? -40 : -30,
            ay: plotVerticalLines ? -30 : -40,
            font: {
              size: 14,
              color: '#ffffff',
            },
            align: 'center',
            arrowhead: 3,
            arrowsize: 1,
            arrowwidth: 2,
            arrowcolor: '#636363',
            bordercolor: '#000000',
            borderwidth: 1,
            borderpad: 3,
            bgcolor: lowOrHigh === 'low' ? patLimitLine.colorLow : patLimitLine.colorHigh,
            opacity: 1,
          });
        }
      });
      // eslint-disable-next-line no-param-reassign
      extraLines.index += limitLinesVis.limitSettingsObj.length;
    }
    return shapesOrAnnotations;
  };

  generateDataObjectFromError = (errorDetails: any): ICombinedGraphDataWithReportHeaders => {
    return {
      reportHeaderSetting: {
        name: '',
        titleFontSize: 0,
        titleExpression: [],
        fieldFontSize: 0,
        displayFieldsCount: 0,
        rowCount: 0,
        columnCount: 0,
        itemsAlignment: '',
        title: '',
        titleTemplate: '',
        fields: [],
      },
      flatGraphDataDto: {
        errors: [{
          facility: errorDetails.Facility,
          workCenter: errorDetails.WorkCenter,
          device: errorDetails.Device,
          testProgram: errorDetails.TestProgram,
          lot: errorDetails.Lot,
          wafer: errorDetails.Wafer,
          message: errorDetails.Message,
          description: errorDetails.Description,
          type: errorDetails.Type,
        }],
        graphIdentity: {
          columnName: '',
          entityType: '',
          id: '',
        },
        graphs: [],
        headers: {},
        hiddenCount: 0,
        highlightedCount: 0,
        labelRows: [],
        interactionDataExists: false,
      },
    };
  };

  generateDataTab = (props: ICustomizedReportGraphProps, preferences: any, callback?: (errors: IError[]) => void) => {
    if (props.selectionStore) {
      const {
        reportSessionId,
        selectionStore,
        groupingSortingListStore,
        defaultGrouping,
        parseFilter,
        config,
        report,
        testParameterIndex,
        productType,
        selectedBinPlusDefinition,
        isSoftBin,
      } = props;
      const gridRenderingOptions: GRID_RENDERING_OPTIONS[] = [];
      if (ReportCollectionPreferences[report.actor]?.showReportForIndividualTestParameter) {
        gridRenderingOptions.push('INDIVIDUAL_PARAMETER_REPORT');
      }
      if (preferences.reportType === 'BIN_BASED') {
        gridRenderingOptions.push('SHOW_BIN_CALCULATED_COLUMNS');
      } else {
        gridRenderingOptions.push('SHOW_PARAMETRIC_CALCULATED_COLUMNS');
      }
      gridRenderingOptions.push('SHOW_NAME_COLUMNS_BY_DEFAULT');
      if (props.groupingSortingListStore.grouping.length > 0) {
        gridRenderingOptions.push('SHOW_GROUPING_COLUMNS');
      }
      if (props.groupingSortingListStore.sorting.length > 0) {
        gridRenderingOptions.push('SHOW_SORTING_COLUMNS');
      }
      return (
        <CustomizedRawDataGrid
          title=""
          viewAs="DATA_TAB"
          key="Data Tab Grid"
          gridRenderingOptions={gridRenderingOptions}
          reportSessionId={reportSessionId}
          setRawDataGridInstanceHandler={() => undefined}
          powerviewSessionId={reportSessionId}
          id={reportSessionId}
          testParameterConditions={selectionStore.testParameterConditions}
          groupingSortingListStore={groupingSortingListStore}
          defaultGrouping={defaultGrouping}
          parseFilter={parseFilter}
          selectionStore={selectionStore}
          aggredateFunction={null}
          config={config}
          testParameterIndex={testParameterIndex}
          filterData={false}
          productType={productType}
          reportType={report.actor}
          selectedBinPlusDefId={selectedBinPlusDefinition.id}
          isSoftBin={isSoftBin}
          setErrorsData={(errorsData: IError[]) => {
            if (callback) {
              callback(errorsData);
            }
          }}
        />
      );
    }
    return <></>;
  };

  generateStatisticsTab = (props: ICustomizedReportGraphProps, preferences: any, interactionsApplied?: boolean, callback?: (errors: IError[]) => void, setStateCallback?: (updatedLimitSettingsObj: any) => void) => {
    if (props.selectionStore) {
      const {
        reportSessionId,
        selectionStore,
        groupingSortingListStore,
        defaultGrouping,
        parseFilter,
        config,
        report,
        limitSettingsObj,
        testParameterIndex,
        testParameterIndexForWhichLimitsApplied,
        productType,
        selectedBinPlusDefinition,
        isSoftBin,
      } = props;

      const gridRenderingOptions: GRID_RENDERING_OPTIONS[] = [];
      if (ReportCollectionPreferences[report.actor]?.showReportForIndividualTestParameter) {
        gridRenderingOptions.push('INDIVIDUAL_PARAMETER_REPORT');
      }
      if (preferences.reportType === 'BIN_BASED') {
        gridRenderingOptions.push('SHOW_BIN_CALCULATED_COLUMNS');
      } else {
        gridRenderingOptions.push('SHOW_PARAMETRIC_CALCULATED_COLUMNS');
      }
      gridRenderingOptions.push('SHOW_NAME_COLUMNS_BY_DEFAULT');
      if (props.groupingSortingListStore.grouping.length > 0) {
        gridRenderingOptions.push('SHOW_GROUPING_COLUMNS');
      }
      if (props.groupingSortingListStore.sorting.length > 0) {
        gridRenderingOptions.push('SHOW_SORTING_COLUMNS');
      }
      if (limitSettingsObj && Object.keys(limitSettingsObj).length > 0) {
        gridRenderingOptions.push('APPLY_LIMIT_LINES');
      }
      if (interactionsApplied && interactionsApplied === true) {
        gridRenderingOptions.push('SHOW_FILTERED_STATS_COLUMNS');
      }
      gridRenderingOptions.push('SHOW_STATISTICS_COLUMNS');
      const gridRenderingOptionsPassing = _.cloneDeep(gridRenderingOptions);
      gridRenderingOptionsPassing.push('SHOW_PASSING_STATS_ONLY');
      const gridRenderingOptionsFailing = _.cloneDeep(gridRenderingOptions);
      gridRenderingOptionsFailing.push('SHOW_FAILING_STATS_ONLY');
      gridRenderingOptions.push('SHOW_ALL_STATS');

      return (
        <div>
          <CustomizedRawDataGrid
            title="Overall"
            viewAs="DATA_TAB"
            key="Overall Stats Grid"
            gridRenderingOptions={gridRenderingOptions}
            reportSessionId={reportSessionId}
            setRawDataGridInstanceHandler={() => undefined}
            powerviewSessionId={reportSessionId}
            id={reportSessionId}
            testParameterConditions={selectionStore.testParameterConditions}
            groupingSortingListStore={groupingSortingListStore}
            defaultGrouping={defaultGrouping}
            parseFilter={parseFilter}
            selectionStore={selectionStore}
            aggredateFunction={null}
            config={config}
            limitSettingsObj={limitSettingsObj}
            testParameterIndex={testParameterIndex}
            testParameterIndexForWhichLimitsApplied={testParameterIndexForWhichLimitsApplied}
            filterData={interactionsApplied || false}
            productType={productType}
            reportType={report.actor}
            selectedBinPlusDefId={selectedBinPlusDefinition.id}
            isSoftBin={isSoftBin}
            setErrorsData={(errorsData: IError[]) => {
              if (callback) {
                callback(errorsData);
              }
            }}
            setNewLimitSettings={(updatedLimitSettingsObj: any) => {
              if (setStateCallback) {
                setStateCallback(updatedLimitSettingsObj);
              }
            }}
          />
          <CustomizedRawDataGrid
            title="Passing Only"
            viewAs="DATA_TAB"
            key="Passing Stats Grid"
            gridRenderingOptions={gridRenderingOptionsPassing}
            reportSessionId={reportSessionId}
            setRawDataGridInstanceHandler={() => undefined}
            powerviewSessionId={reportSessionId}
            id={reportSessionId}
            testParameterConditions={selectionStore.testParameterConditions}
            groupingSortingListStore={groupingSortingListStore}
            defaultGrouping={defaultGrouping}
            parseFilter={parseFilter}
            selectionStore={selectionStore}
            aggredateFunction={null}
            config={config}
            limitSettingsObj={limitSettingsObj}
            testParameterIndex={testParameterIndex}
            testParameterIndexForWhichLimitsApplied={testParameterIndexForWhichLimitsApplied}
            filterData={interactionsApplied || false}
            productType={productType}
            reportType={report.actor}
            selectedBinPlusDefId={selectedBinPlusDefinition.id}
            isSoftBin={isSoftBin}
            setErrorsData={(errorsData: IError[]) => {
              if (callback) {
                callback(errorsData);
              }
            }}
            setNewLimitSettings={(updatedLimitSettingsObj: any) => {
              if (setStateCallback) {
                setStateCallback(updatedLimitSettingsObj);
              }
            }}
          />
          <CustomizedRawDataGrid
            title="Failing Only"
            viewAs="DATA_TAB"
            key="Failing Stats Grid"
            gridRenderingOptions={gridRenderingOptionsFailing}
            reportSessionId={reportSessionId}
            setRawDataGridInstanceHandler={() => undefined}
            powerviewSessionId={reportSessionId}
            id={reportSessionId}
            testParameterConditions={selectionStore.testParameterConditions}
            groupingSortingListStore={groupingSortingListStore}
            defaultGrouping={defaultGrouping}
            parseFilter={parseFilter}
            selectionStore={selectionStore}
            aggredateFunction={null}
            config={config}
            limitSettingsObj={limitSettingsObj}
            testParameterIndex={testParameterIndex}
            testParameterIndexForWhichLimitsApplied={testParameterIndexForWhichLimitsApplied}
            filterData={interactionsApplied || false}
            productType={productType}
            reportType={report.actor}
            selectedBinPlusDefId={selectedBinPlusDefinition.id}
            isSoftBin={isSoftBin}
            setErrorsData={(errorsData: IError[]) => {
              if (callback) {
                callback(errorsData);
              }
            }}
            setNewLimitSettings={(updatedLimitSettingsObj: any) => {
              if (setStateCallback) {
                setStateCallback(updatedLimitSettingsObj);
              }
            }}
          />
        </div>
      );
    }
    return <></>;
  };

  generateErrorsTab = (errors: IError[]) => {
    return (
      <ScrollView
        height="500px"
        showScrollbar="always"
        scrollByThumb
        reachBottomText=""
      >
        <Container fluid>
          <Row>
            <Col lg={12}>
              <CustomizedDataGrid
                showFilterRow
                columnAutoWidth
                wordWrapEnabled
                selectionMode="single"
                enableColumnChooser={false}
                fields={[
                  {
                    caption: 'Error Type',
                    dataField: 'type',
                    dataType: 'string',
                    showInfo: false,
                    viewMode: 'tag',
                    cellRender: (e: any) => {
                      const { type } = e.data;
                      let colorClass = '';
                      colorClass = 'background-color-warning';
                      if (type === 'SYSTEM_ERROR') {
                        colorClass = 'background-color-danger';
                      }
                      return (
                        <div
                          className={`w100 text-center rounded mr10 p2 color-light ${colorClass}`}
                        >
                          {type?.replaceAll('_', ' ')
                            .toUpperCase()}
                        </div>
                      );
                    },
                  },
                  {
                    caption: 'Facility',
                    dataField: 'facility',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Work Center',
                    dataField: 'workCenter',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Device',
                    dataField: 'device',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Test Program',
                    dataField: 'testProgram',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Lot',
                    dataField: 'lot',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Wafer',
                    dataField: 'wafer',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Test Parameter',
                    dataField: 'testParameter',
                    dataType: 'string',
                    showInfo: false,
                  },
                  {
                    caption: 'Message',
                    dataField: 'message',
                    dataType: 'string',
                    showInfo: false,
                  },
                ]}
                moreOptions={[]}
                tableData={errors}
              />
            </Col>
          </Row>
        </Container>
      </ScrollView>
    );
  };
}
