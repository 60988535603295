import Button from 'components/wrapped-component/hint-controls/Button';
import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import RawDataGrid from './raw-grid/raw-datagrid';

class ShowDataPopup extends Component<any, any> {
  private filterExpression: any;

  private advancedFilterInput:any;

  constructor(props: any) {
    super(props);
    this.saveFilterValue = this.saveFilterValue.bind(this);
    this.syncFilterValues = this.syncFilterValues.bind(this);
  }

  saveFilterValue() {
    const { preserveFilterValue } = this.props;
    if (preserveFilterValue !== undefined) {
      preserveFilterValue(this.filterExpression, this.advancedFilterInput);
    }
  }

  syncFilterValues(filterExpression:any, advancedFilterInput:any) {
    this.filterExpression = filterExpression;
    this.advancedFilterInput = advancedFilterInput;
  }

  componentDidMount() {
    const doc:any = document;
    if (doc !== null) {
      doc.getElementById('mod').parentElement.removeAttribute('tabIndex');
    }
  }

  render() {
    const { showData, dataFieldPanel, advancedFilterInput } = this.props;
    return (
      <Row className="h-100v">
        <Col>
          <Modal
            show
            onHide={showData}
            dialogClassName="modal-90w "
            size="lg"
            id="mod"
          >
            <Modal.Header closeButton className="border-none">
              <Modal.Title className="text-center w-100">
                <p className="text-center">Raw Data</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex-column pt0">
              <RawDataGrid dataFieldPanel={dataFieldPanel} syncFilterValues={this.syncFilterValues} advancedFilterInput={advancedFilterInput} />
              <div className="w100p pr15">
                <Button variant="primary float-right mt10" size="lg" onClick={this.saveFilterValue}>Apply Filter</Button>
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    );
  }
}
export default ShowDataPopup;
