/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { faChevronLeft, faChevronRight, faTimes, } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IDrilledGraphData, ISelectionCriteriaData, IExpressionsList, IGenericDetailedReportRequestObject, EntityType, GroupingSortingDTO,
} from 'interfaces';
import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import _ from 'lodash';

import '../individual-reports.scss';
import GeneralUtils, { ReportTypeConstants as ReportType } from 'GeneralUtils';
import LimitSettingsData from 'components/wrapped-component/limit-settings-modal/LimitSettingsData';
import { IReportHeaderSetting } from 'components/utility-component/report-headers/ReportHeaderBuilder';
import CustomizedReportLegend from './CustomizedReportLegend';
import CustomizedReportGraph, { ICustomizedReportModifiableOptions } from './CustomizedReportGraph';
import {
  IViewMode,
  ICustomizedReportRowBaseProps,
  AggregateFunctionNames,
  SeriesTypeNames,
  ICustomizedReportCollectionTypes,
  ParametricFailureReportOptions,
  ICustomizedLegendData,
  ICombinedGraphData,
} from './customized-report-helpers/CustomizedReportInterfaces';
import { IGraphDataHelper, ILegendDataHelper } from './customized-report-helpers/CustomizedReportHelper';
import { IActorDetails } from './CustomizedReportsCollection';
import { GraphAndLegendDataHelpersFactory } from './customized-report-helpers/CustomizedReportMapping';
import PubSubBinder, { ActionType } from '../PublishSubscribeBinder';

export const parametricFailureReportOptions: ParametricFailureReportOptions = {
  type: 'FAILURE',
  count: 3,
  trend: 'TOP',
};
export interface ICustomizedReportState {
  viewMode: IViewMode;
  showLegend: boolean;
  isLegendPinned: boolean;
  showReportRow: boolean;
  aggredateFunction: AggregateFunctionNames;
  parametricFailureReportOptions: ParametricFailureReportOptions;
  seriesType: SeriesTypeNames;
  showModal: boolean;
  updatedTabCount?: number;
  testParameterIndex?: number[];
  testParameterSelectedIndex?: number;
  testParameterIndexForWhichLimitsApplied?: number;
  limitSettingsObj?: any;
  showDies: boolean;
  showDiesShowingOption: boolean;
  showAnnotations: boolean;
  adjustedPlotlyGraphWidth: number;
  isSoftBin: boolean;
  plotterPageNumber: number;
  axisBreakThresholdFactor: number;
  reportLoaded: boolean;
  useReportModifiersDataIfSaved: boolean;
  binChangeAndDieStatus?: any[];
}
export interface ICustomizedReportProps extends ICustomizedReportRowBaseProps {
  selectionStoreDataSetCount? : number;
  report : IActorDetails;
  legend : IActorDetails;
  defaultSelectionCriteriaData : ISelectionCriteriaData[];
  defaultGrouping?: GroupingSortingDTO,
  galleryData: IDrilledGraphData[];
  type: ICustomizedReportCollectionTypes;
  binPlusDefinitions: [];
  waferGalleryFilters: IExpressionsList[];
  hideReportRow?: () => void;
  config: { [key: string]: any };
  rulePayLoad?: string;
  testParameterIndex: number[];
  limitSettingsObj?: any;
  loadingPriority: number;
  loadingWeight: number;
  patLimitLines: any;
  patParamCtrlLimitLines: any;
  waferSelection?: any;
  reportIndex: number;
  updatedHeaderId?: string;
  headerData?: IReportHeaderSetting;
  graphData?: ICombinedGraphData;
  legendData?: ICustomizedLegendData;
  reportModifiersData?: ICustomizedReportModifiableOptions;
  saveData?: (data: any, reportIndex: number, dataType: string) => void;
  showLegend: boolean,
  isApplyAllReportHeaders?: boolean;
  isClosedSomeReport?: boolean;
  waferCombinations?: any;
  isSoftBin: boolean;
}
class CustomizedReport extends React.Component<ICustomizedReportProps, ICustomizedReportState> {
  private graphHelper : IGraphDataHelper | undefined = undefined;

  private legendHelper : ILegendDataHelper | undefined = undefined;

  private pubSubBinder = PubSubBinder();

  private customizedReportGraphRef: any;

  pfrTPCountInputTimeout: NodeJS.Timeout | null = null;

  pfrTPCountInput: any = null;

  private BinChangeColor: any = {
    BIN_NUMBER_CHANGE_ONLY: '#FF9200',
    PASS_TO_FAIL: '#FF0000',
    FAIL_TO_PASS: '#00EAFF',
  }

  constructor(props:ICustomizedReportProps) {
    super(props);
    this.customizedReportGraphRef = React.createRef();
    const {
      report, legend, type, groupingSortingListStore, testParameterIndex, config, showLegend, isSoftBin
    } = this.props;
    this.graphHelper = (GraphAndLegendDataHelpersFactory(report.actor) as IGraphDataHelper);
    this.legendHelper = (GraphAndLegendDataHelpersFactory(legend.actor) as ILegendDataHelper);
    if (this.legendHelper.preferences.summary.totalItems && !this.legendHelper.preferences.summary.totalItems.find((x: any) => x.name === 'Outlier Parametric Yield')) {
      this.legendHelper.preferences.summary.totalItems = [...this.legendHelper.preferences.summary.totalItems, ...LimitSettingsData.getLegendSummaryOptionsForLimits()];
    }

    const reportPreferences = (this.graphHelper as IGraphDataHelper).getPreferences();

    this.pubSubBinder.RegisterActor(
      'GENERAL_ACTION',
      this.expandLegend,
    );
    this.state = {
      showLegend,
      isLegendPinned: true,
      showReportRow: reportPreferences.autoLoad,
      viewMode: type && type === 'SIMULATE_POLICY' ? 'DETAILED' : 'COMBINED',
      aggredateFunction: 'NONE',
      seriesType: this.getDefaultSeriesType(),
      updatedTabCount: 0,
      testParameterIndex,
      testParameterSelectedIndex: 0,
      testParameterIndexForWhichLimitsApplied: 0,
      limitSettingsObj: {},
      showDies: true,
      showDiesShowingOption: true,
      showAnnotations: true,
      parametricFailureReportOptions,
      adjustedPlotlyGraphWidth: 0,
      isSoftBin,
      plotterPageNumber: 0,
      axisBreakThresholdFactor: 25,
      reportLoaded: false,
      showModal: false,
      useReportModifiersDataIfSaved: true,
      binChangeAndDieStatus: [],
    };
    this.onChangeReportConfigValues([
      {
        key: 'parametricFailureReportOptions',
        value: parametricFailureReportOptions,
      },
      {
        key: 'softBins',
        value: [],
      },
      {
        key: 'waferIdsForOverlay',
        value: [],
      },
    ]);
  }

  setReportLoaded = () => {
    this.setState({ reportLoaded: true });
  };

  getDefaultSeriesType = () => {
    const { report } = this.props;
    let seriesType: SeriesTypeNames = 'SEPARATE';
    if (report.actor === ReportType.BIN_PARETO || report.actor === ReportType.PARAMETRIC_HISTOGRAM) {
      seriesType = 'SINGLE';
    }
    return seriesType;
  };

  adjustPlotlyGraphWidth = () => {
    const { adjustedPlotlyGraphWidth } = this.state;
    setTimeout(() => {
      try {
        if (document !== null) {
          const classes = [
            '.combined-bin-histogram-report',
            '.plotly',
            '.svg-container',
            '.main-svg',
          ];
          let currentClass = '';
          classes.forEach((c:string) => {
            currentClass = `${currentClass} ${c}`;
            const e = document.querySelector(currentClass);
            (e as any).removeAttribute('width');
            (e as any).style.width = '100% !important';
            (e as any).classList.add('w-100');
          });
          this.setState({ adjustedPlotlyGraphWidth: adjustedPlotlyGraphWidth + 1 });
        }
      } catch (ex) {
        this.setState({ adjustedPlotlyGraphWidth: adjustedPlotlyGraphWidth + 1 });
      }
    }, 0);
  };

  toggleShowLegend = () => {
    const { showLegend } = this.state;
    this.setState({ showLegend: !showLegend, isLegendPinned: true }, this.adjustPlotlyGraphWidth);
  };

  expandLegend = (action: ActionType) => {
    if (action === 'EXPAND_LEGEND_EVENT') {
      this.setState({ showLegend: true, isLegendPinned: true }, this.adjustPlotlyGraphWidth);
    } else if (action === 'COLLAPSE_LEGEND_EVENT') {
      this.setState({ showLegend: false, isLegendPinned: true }, this.adjustPlotlyGraphWidth);
    }
  };

  toggleShowReportRow = () => {
    const { showReportRow } = this.state;
    this.setState({ showReportRow: !showReportRow }, this.adjustPlotlyGraphWidth);
  };

  saveReportModifiableOptions = () => {
    const {
      seriesType, aggredateFunction, testParameterIndex, isSoftBin, limitSettingsObj, showDies, showAnnotations, plotterPageNumber, axisBreakThresholdFactor,
    } = this.state;
    const { saveData, reportIndex, config } = this.props;
    const reportModifiableOptions: ICustomizedReportModifiableOptions = {
      seriesType,
      aggredateFunction,
      testParameterIndex,
      isSoftBin,
      config,
      limitSettingsObj,
      showDies,
      showAnnotations,
      plotterPageNumber,
      axisBreakThresholdFactor,
    };
    if (saveData) {
      saveData(reportModifiableOptions, reportIndex, 'MODIFIERS');
    }
  };

  updateState = (stateData:ICustomizedReportState, errors?: any) => {
    const {
      seriesType, aggredateFunction, testParameterIndex, isSoftBin, limitSettingsObj, showDies, showAnnotations, plotterPageNumber, axisBreakThresholdFactor,
    } = this.state;
    const { config, graphData } = this.props;
    if (errors && errors.length > 0) {
      errors.forEach((error: any) => {
        graphData?.errors.push(error);
      })
    }
    const reportModifiableOptions: ICustomizedReportModifiableOptions = {
      seriesType, aggredateFunction, testParameterIndex, isSoftBin, config, limitSettingsObj, showDies, showAnnotations, plotterPageNumber, axisBreakThresholdFactor,
    };
    let updateReportModifiableOptions = false;
    Object.entries(stateData).forEach((item: any) => {
      if (Object.entries(reportModifiableOptions).find((x: any) => x[0] === item[0])) {
        updateReportModifiableOptions = true;
      }
    });
    this.setState(stateData, () => {
      if (updateReportModifiableOptions) {
        this.setState({ useReportModifiersDataIfSaved: false });
        this.saveReportModifiableOptions();
      }
    });
  };

  getReportColumnWidth = () => {
    const { showLegend, isLegendPinned } = this.state;

    if (showLegend && isLegendPinned) {
      return 8;
    }
    if (showLegend && !isLegendPinned) {
      return 12;
    }
    return 12;
  };

  onChangeReportConfigValues = (keyValuePairs: { key: string, value: any }[], componentUnmounting = false) => {
    const { report } = this.props;
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.config[keyValuePairs[i].key] = keyValuePairs[i].value;
    }
    if (!componentUnmounting && report.actor === ReportType.PARAMETRIC_FAILURE) {
      this.setState({ useReportModifiersDataIfSaved: false });
      this.saveReportModifiableOptions();
    }
  };

  componentDidMount() {
    const { reportModifiersData } = this.props;
    if (reportModifiersData) {
      const {
        seriesType, aggredateFunction, testParameterIndex, isSoftBin, limitSettingsObj, showDies, showAnnotations, plotterPageNumber, axisBreakThresholdFactor,
      } = reportModifiersData;
      if (seriesType !== this.state.seriesType || aggredateFunction !== this.state.aggredateFunction || JSON.stringify(testParameterIndex) !== JSON.stringify(this.state.testParameterIndex)
        || isSoftBin !== this.state.isSoftBin || JSON.stringify(limitSettingsObj) !== JSON.stringify(this.state.limitSettingsObj) || showDies !== this.state.showDies
        || showAnnotations !== this.state.showAnnotations || plotterPageNumber !== this.state.plotterPageNumber || axisBreakThresholdFactor !== this.state.axisBreakThresholdFactor) {
        this.setState({
          seriesType,
          aggredateFunction,
          testParameterIndex,
          isSoftBin,
          limitSettingsObj,
          showDies,
          showAnnotations,
          plotterPageNumber,
          axisBreakThresholdFactor,
        });
      }
    }
  }

  getSupportingComponents = (callback:any) => {
    const { viewMode } = this.state;
    if (callback) {
      return callback(this.props, this.state, this, this.updateState, this.getRequestObject(viewMode));
    }
    return <></>;
  };

  getRequestObject = (viewMode: IViewMode) => {
    const {
      selectedBinPlusDefinition,
      parseFilter,
      selectionStore,
      groupingSortingListStore,
      reportSessionId,
      report,
      config,
      testParameterIndex,
      selectionStoreDataSetCount,
    } = this.props;

    const {
      aggredateFunction,
      seriesType,
      isSoftBin,
    } = this.state;
    const data: IGenericDetailedReportRequestObject = {
      selectionStoreDataSetCount,
      scwData: selectionStore.selections.map((item) => ({
        entityType: item.controlType as EntityType,
        values: item.values.map((val) => val.id),
      })),
      filters: JSON.stringify(parseFilter(selectionStore)),
      binPlusDefId: selectedBinPlusDefinition.id,
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: aggredateFunction,
      seriesType,
      config,
      reportType: report.actor,
      testParameterIndex,
      hasNonSequentialXValues: report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT ? true : undefined,
      isSoftBin,
    };
    return data;
  };

  getBinChangeDataForBinWaferMapComparison = (data: any) => {
    this.setState({ binChangeAndDieStatus: data });
    this.getCountsforBinChange();
  }

  getCountsforBinChange = () => {
    const { binChangeAndDieStatus } = this.state;
    const allBinChanges: any[] = [];

    binChangeAndDieStatus!.forEach((perCombination: any) => {
      const countList: any = [];
      const isSelectedList: boolean[] = [];
      const hiddenList: boolean[] = [];
      const result: any = [];
      perCombination.dieStatus.forEach((dieStatus:any, dieIndex: number) => {
        if (dieStatus === 'BIN_NUMBER_CHANGE_ONLY' || dieStatus === 'PASS_TO_FAIL' || dieStatus === 'FAIL_TO_PASS'){
          countList.push(dieStatus);
          isSelectedList.push(perCombination.isSelected[dieIndex])
          hiddenList.push(perCombination.hidden[dieIndex])
        }
      });
      perCombination.binChanges.forEach((obj: any, index: number) => {
        const existIndex = result.findIndex((x:any) => {
          return x.hasOwnProperty(`${obj.key}->${obj.value}`);
        });
        if (existIndex === -1){
          result.push({ [`${obj.key}->${obj.value}`]: [hiddenList[index] === true ? 0 : 1, countList[index], this.BinChangeColor[countList[index]], isSelectedList[index], hiddenList[index]] })
        } else {
          result[existIndex][`${obj.key}->${obj.value}`] = [hiddenList[index] === true ? result[existIndex][`${obj.key}->${obj.value}`][0] : result[existIndex][`${obj.key}->${obj.value}`][0] + 1,
            countList[index], this.BinChangeColor[countList[index]],
            isSelectedList[index] === true ? true : result[existIndex][`${obj.key}->${obj.value}`][3], hiddenList[index] === true ? true : result[existIndex][`${obj.key}->${obj.value}`][4]]
        }
      });
      allBinChanges.push(result);
    });
    this.setState({ binChangeAndDieStatus: allBinChanges })
  };

  render() {
    const {
      showLegend,
      viewMode,
      aggredateFunction,
      seriesType,
      showReportRow,
      testParameterIndex,
      testParameterSelectedIndex,
      testParameterIndexForWhichLimitsApplied,
      limitSettingsObj,
      showDies,
      showAnnotations,
      isSoftBin,
      plotterPageNumber,
      axisBreakThresholdFactor,
      useReportModifiersDataIfSaved,
      binChangeAndDieStatus,
    } = this.state;

    const {
      groupingSortingListStore,
      parseFilter,
      legend,
      reportSessionId,
      selectedBinPlusDefinition,
      selectionStore,
      config,
      rulePayLoad,
      loadingPriority,
      selectionStoreDataSetCount,
      loadingWeight,
      patLimitLines,
      patParamCtrlLimitLines,
      productType,
      defaultGrouping,
      reportIndex,
      updatedHeaderId,
      graphData,
      headerData,
      legendData,
      reportModifiersData,
      saveData,
      waferSelection,
      isClosedSomeReport,
      waferCombinations,
    } = this.props;
    const { graphHelper } = this;
    const { reportLoaded } = this.state;

    const { report, hideReportRow, isApplyAllReportHeaders } = this.props;

    return (
      <Container fluid className="overflow-visible">
        <div className="m10 border-all background-color-light p10">
          <Row>
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb0 mr10">{GeneralUtils.toTitleCase(report.actor.replaceAll('_', ' '))}</h5>
                  {
                    productType && (
                      <div className="d-flex align-items-center ml4 background-color-secondary p6 color-light" style={{ borderRadius: 5 }}>
                        {GeneralUtils.toTitleCase(productType)}
                      </div>
                    )
                  }
                  {/* {
                      this.getSupportingComponents((graphHelper as IGraphDataHelper).getSecondaryComponent)
                    } */}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  {
                    reportLoaded && this.getSupportingComponents((graphHelper as IGraphDataHelper).getPrimaryComponent)
                  }
                  {/* ---- 👇🏼👇🏼👇🏼 Uncomment this section when implementing detailed reports 👇🏼👇🏼👇🏼 --- */}
                  {/* <Button
                      className="d-inline-block btn-config mr6 d-none"
                      variant="clear"
                      onClick={() => {
                        if (viewMode === 'COMBINED') {
                          this.setState({ viewMode: 'DETAILED' });
                        } else {
                          this.setState({ viewMode: 'COMBINED' });
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faMapSigns}
                        className="mr10"
                      />
                      {`Show ${viewMode === 'COMBINED' ? 'Detailed' : 'Combined'} Report`}
                    </Button> */}
                  {/* <Button
                      className={`d-inline-block mr6 ${!showLegend ? 'btn-config' : ''}`}
                      variant={showLegend ? 'primary' : 'clear'}
                      onClick={() => {
                        this.toggleShowLegend();
                      }}
                      disabled={!showReportRow}
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faMapSigns}
                        className="mr10"
                      />
                      Legend
                    </Button> */}
                  {
                    hideReportRow
                      && (
                        <Button
                          className="btn-config"
                          variant="clear"
                          onClick={hideReportRow}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faTimes}
                          />
                        </Button>
                      )
                  }

                </div>
              </div>
            </Col>
          </Row>
          {
            showReportRow && (
              <Row>
                {/* --------------------- GRAPH 👇🏼 --------------------- */}
                <Col lg={this.getReportColumnWidth()} className="position-relative">
                  { reportLoaded && (
                    <Button
                      style={{
                        right: '4px',
                        top: '6px',
                        height: '35px',
                        width: showLegend ? '35px' : '100px',
                        borderRadius: '0px',
                      }}
                      onClick={this.toggleShowLegend}
                      className="position-absolute cursor-pointer"
                      variant="dark"
                    >
                      {!showLegend && (
                        <>
                          <FontAwesomeIcon
                            size="lg"
                            icon={faChevronLeft}
                          />
                          <span className="m8">
                            Legend
                          </span>
                        </>
                      )}
                      {showLegend && (
                        <FontAwesomeIcon
                          size="lg"
                          icon={faChevronRight}
                        />
                      )}
                    </Button>
                  )}
                  <CustomizedReportGraph
                    reportIndex={reportIndex}
                    productType={productType}
                    selectionStoreDataSetCount={selectionStoreDataSetCount}
                    ref={this.customizedReportGraphRef}
                    dataHelper={this.graphHelper}
                    aggredateFunction={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.aggredateFunction : aggredateFunction}
                    seriesType={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.seriesType : seriesType}
                    isSoftBin={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.isSoftBin : isSoftBin}
                    reportSessionId={reportSessionId}
                    groupingSortingListStore={groupingSortingListStore}
                    defaultGrouping={defaultGrouping}
                    report={report}
                    parseFilter={parseFilter}
                    selectedBinPlusDefinition={selectedBinPlusDefinition}
                    selectionStore={selectionStore}
                    config={(reportModifiersData && useReportModifiersDataIfSaved) ? _.cloneDeep(reportModifiersData.config) : _.cloneDeep(config)}
                    viewMode={viewMode}
                    onChangeReportConfigValues={this.onChangeReportConfigValues}
                    rulePayLoad={rulePayLoad}
                    testParameterIndex={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.testParameterIndex : testParameterIndex}
                    testParameterSelectedIndex={testParameterSelectedIndex}
                    testParameterIndexForWhichLimitsApplied={testParameterIndexForWhichLimitsApplied}
                    limitSettingsObj={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.limitSettingsObj : limitSettingsObj}
                    showDies={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.showDies : showDies}
                    showAnnotations={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.showAnnotations : showAnnotations}
                    loadingPriority={loadingPriority}
                    loadingWeight={loadingWeight}
                    patLimitLines={patLimitLines}
                    patParamCtrlLimitLines={patParamCtrlLimitLines}
                    waferSelection={waferSelection}
                    setStateCallback={(updatedStateData: any) => {
                      this.updateState(updatedStateData);
                    }}
                    plotterPageNumber={plotterPageNumber}
                    axisBreakThresholdFactor={axisBreakThresholdFactor}
                    setReportLoaded={this.setReportLoaded}
                    updatedHeaderId={updatedHeaderId}
                    graphData={graphData}
                    headerData={headerData}
                    saveData={saveData}
                    isApplyAllReportHeaders={isApplyAllReportHeaders}
                    isClosedSomeReport={isClosedSomeReport}
                    getBinChangeDataForBinWaferMapComparison={report.actor === ReportType.BIN_WAFER_MAP_COMPARISON ? this.getBinChangeDataForBinWaferMapComparison : undefined}
                  />
                </Col>
                {/* --------------------- GRAPH 👆🏼 --------------------- */}
                {/* --------------------- LEGEND 👇🏼 --------------------- */}
                { showLegend && (
                  <Col lg={4} className="p0">
                    <CustomizedReportLegend
                      reportIndex={reportIndex}
                      productType={productType}
                      selectionStoreDataSetCount={selectionStoreDataSetCount}
                      pinToggleCallback={(isPinned:boolean) => {
                        this.setState({ isLegendPinned: isPinned });
                      }}
                      dataHelper={this.legendHelper}
                      aggredateFunction={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.aggredateFunction : aggredateFunction}
                      seriesType={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.seriesType : seriesType}
                      isSoftBin={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.isSoftBin : isSoftBin}
                      reportSessionId={reportSessionId}
                      groupingSortingListStore={groupingSortingListStore}
                      legend={legend}
                      parseFilter={parseFilter}
                      selectedBinPlusDefinition={selectedBinPlusDefinition}
                      selectionStore={selectionStore}
                      viewMode={viewMode}
                      config={(reportModifiersData && useReportModifiersDataIfSaved) ? _.cloneDeep(reportModifiersData.config) : _.cloneDeep(config)}
                      testParameterIndex={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.testParameterIndex : testParameterIndex}
                      limitSettingsObj={(reportModifiersData && useReportModifiersDataIfSaved) ? reportModifiersData.limitSettingsObj : limitSettingsObj}
                      legendData={legendData}
                      saveData={saveData}
                      waferCombinations={waferCombinations}
                      binChangeData={binChangeAndDieStatus}
                    />
                  </Col>
                )}

                {/* --------------------- LEGEND 👆🏼 --------------------- */}
              </Row>
            )
          }

        </div>
      </Container>
    );
  }
}

export default CustomizedReport;
