import GeneralUtils from "GeneralUtils";
import toast from "CustomToast";
import ModalPopup from "components/wrapped-component/modal-popup/modal-popup";
import axios from "./axios-limit-concurrency";

export default class HttpBase {
  private logServerCalls = (stackTrace: string) => {
    // console.log(stackTrace);
  };

  GET = (url: string): Promise<any> => {
    this.logServerCalls(`Start Time: ${new Date().toString()}`);
    const token = GeneralUtils.getCookieValue("AUTH_TOKEN");
    const database = GeneralUtils.getCookieValue("DATABASE");
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          SelectedDatabase: database,
        },
        withCredentials: true,
      })
      .then(this.successHandlerBase.bind(this))
      .catch(this.errorHandlerBase.bind(this));
  };

  DELETE = (url: string): Promise<any> => {
    const token = GeneralUtils.getCookieValue("AUTH_TOKEN");
    const database = GeneralUtils.getCookieValue("DATABASE");
    this.logServerCalls(`Start Time: ${new Date().toString()}`);
    return axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          SelectedDatabase: database,
        },
        withCredentials: true,
      })
      .then(this.successHandlerBase.bind(this))
      .catch(this.errorHandlerBase.bind(this));
  };

  POST = (url: string, data: any, options?: any): Promise<any> => {
    const token = GeneralUtils.getCookieValue("AUTH_TOKEN");
    const database = GeneralUtils.getCookieValue("DATABASE");
    this.logServerCalls(`Start Time: ${new Date().toString()}`);
    return axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          SelectedDatabase: database,
        },
        withCredentials: true,
        ...options,
      })
      .then(this.successHandlerBase.bind(this))
      .catch(this.errorHandlerBase.bind(this));
  };

  PUT = (url: string, data: any): Promise<any> => {
    const token = GeneralUtils.getCookieValue("AUTH_TOKEN");
    const database = GeneralUtils.getCookieValue("DATABASE");
    this.logServerCalls(`Start Time: ${new Date().toString()}`);
    return axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          SelectedDatabase: database,
        },
        withCredentials: true,
      })
      .then(this.successHandlerBase.bind(this))
      .catch(this.errorHandlerBase.bind(this));
  };

  private successHandlerBase = (res: any) => {
    this.logServerCalls(`End Time (Success): ${new Date().toString()}`);

    if (res.status === 200) {
      return Promise.resolve(res.data);
    }
    return Promise.reject(res);
  };

  private errorHandlerBase = async (error: any = "Error") => {
    if (error.response !== undefined) {
      // ----------------------------------------------------------------
      // ----------------------------------------------------------------
      // ----------------------------------------------------------------
      // 403:: CHECK IF THE SESSION HAS EXPIRED
      // ERR_CONTEXT_EXPIRED:: SCW Context Expired
      if (
        error.response.data.Message === "ERR_CONTEXT_EXPIRED" ||
        error.response.status === 403
      ) {
        let message = "";
        // const resolvePopover = await ModalPopup.confirm({
        //   header: "Alert",
        //   body: message,
        //   showYesButton: false,
        //   noButtonText: "Ok",
        // });
        // if (!resolvePopover) {
        //   // eslint-disable-next-line no-alert
        //   window.location.reload();
        // }

        return toast.error(error.response.data.Message);
      } else if (error.response.status === 401) {
        toast.error("Invalid Credentials");
      } else if (error.response.status === 500) {
        if (error.response.data?.Message !== "") {
          toast.error(error.response.data.Message);
        }
      } else {
        toast.error(error.response.data.Message);
      }
      //----------------------------------------------------------------
      //----------------------------------------------------------------
      //----------------------------------------------------------------

      return Promise.reject(error);
    }
    toast.error("Unknown error received. Please contact administrator.");
    return undefined;
  };
}
