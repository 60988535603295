import { addErrorClasses } from 'components/template-component/form-error-label/ErrorLabel';
import CustomizedDropdown from 'components/wrapped-component/customized-dropdown/CustomizedDropdown';
import Heading from 'components/wrapped-component/hint-controls/Heading';
import Label from 'components/wrapped-component/hint-controls/Label';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import { CheckBox, ScrollView } from 'devextreme-react';
import React from 'react';
import {
  Button, Col, Row, ToggleButton,
} from 'react-bootstrap';
import { reticleIncrementUOMs } from './wafer-control-map';

type PCMWATSiteMapTabProps = {
  pcmWatSiteMapTabData: PCMWATSiteMapTabData,
  formErrors: { [key: string] : any },
  onChangePCMWATMapTabData: (key: string, value: any, callback?: any) => void,
  onApplyReticleLayout: () => void,
  onResetReticle: () => void,
  onCropSelectedReticles: () => void,
  onPanReticle: (direction: ReticlePanDirection) => void,
  onInsert: (direction: ReticleInsertDirection) => void,
};

type PCMWATSiteMapTabState = {

};

export type PCMWATSiteMapTabData = {
  [key: string]: any,
  reticleCalculationMethod: ReticleCalculationMethod,
  maxReticleRows: number,
  maxReticleCols: number,
  panIncrement: number,
  panIncrementUOM: string,
  panMode: ReticlePanMode,
};

// eslint-disable-next-line no-unused-vars
export enum ReticleCalculationMethod { AUTO, ROWS_COLS }

// eslint-disable-next-line no-unused-vars
export enum ReticlePanMode { SELECTED, ALL }

// eslint-disable-next-line no-unused-vars
export enum ReticlePanDirection { UP, DOWN, LEFT, RIGHT }

// eslint-disable-next-line no-unused-vars
export enum ReticleInsertDirection { UP, DOWN, LEFT, RIGHT }

export default class PCMWATSiteMapTab extends React.Component<PCMWATSiteMapTabProps, PCMWATSiteMapTabState> {
  constructor(props: PCMWATSiteMapTabProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      pcmWatSiteMapTabData, formErrors, onChangePCMWATMapTabData, onApplyReticleLayout, onResetReticle, onCropSelectedReticles, onPanReticle,
      onInsert,
    } = this.props;
    return (
      <div className="custom-form mt10" style={{ fontSize: '10px' }}>
        <ScrollView
          height="85vh"
          showScrollbar="onHover"
          scrollByThumb
          reachBottomText=""
        >
          <Heading size={5}>Reticle Layout</Heading>
          <div className="mt10">
            <ToggleButton
              style={{ boxShadow: '0 0', color: 'black' }}
              variant="clear"
              type="radio"
              className="mr15"
              checked={pcmWatSiteMapTabData.reticleCalculationMethod === ReticleCalculationMethod.ROWS_COLS}
              value={pcmWatSiteMapTabData.reticleCalculationMethod}
            //   onChange={() => { this.onChangeWaferDefintionData('calculationMethod', CalculationMethod.RETICLE); }}
            >
              <span className="ml10">Calculate With Max Reticle</span>
            </ToggleButton>
            <Label
              childrenItemsInline
              labelPosition="left-middle"
              labelSize="0"
              fieldSize="100"
              errorSize="100"
              labelTitle=""
              className="mt10"
            >
              <Label
                labelTitle="Rows"
                labelPosition="top"
                labelSize="20"
                fieldSize="80"
                errorSize="100"
                error={formErrors.maxReticleRows}
                isFieldTouched
                className="mr10"
                required={pcmWatSiteMapTabData.reticleCalculationMethod === ReticleCalculationMethod.ROWS_COLS}
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.maxReticleRows,
                    true,
                  )}`}
                  type="number"
                  name="maxReticleRows"
                  value={pcmWatSiteMapTabData.maxReticleRows}
                  placeholder=""
                  onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) onChangePCMWATMapTabData('maxReticleRows', e.target.value); }}
                />
              </Label>
              <Label
                labelTitle="Cols"
                labelPosition="top"
                labelSize="20"
                fieldSize="80"
                errorSize="100"
                error={formErrors.maxReticleCols}
                isFieldTouched
                required={pcmWatSiteMapTabData.reticleCalculationMethod === ReticleCalculationMethod.ROWS_COLS}
              >
                <Textbox
                  autoComplete="off"
                  className={`${addErrorClasses(
                    formErrors.maxReticleCols,
                    true,
                  )}`}
                  type="number"
                  name="maxReticleCols"
                  value={pcmWatSiteMapTabData.maxReticleCols}
                  placeholder=""
                  onChange={(e: any) => { if (e.target.value === '' || +e.target.value >= 0) onChangePCMWATMapTabData('maxReticleCols', e.target.value); }}
                />
              </Label>
            </Label>

          </div>

          <Row className="mt10">
            <Col className="text-center">
              <Button onClick={() => { onCropSelectedReticles(); }} variant="clear" size="sm" className="w100 btn-config">
                Crop
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onResetReticle(); }} variant="clear" size="sm" className="w100 btn-config">
                Reset
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onApplyReticleLayout(); }} variant="clear" size="sm" className="w100 btn-config">
                Apply
              </Button>
            </Col>
          </Row>
          <hr className="mt10 mb10" />
          <Heading size={5}>Pan</Heading>
          <Label
            childrenItemsInline
            labelPosition="left-middle"
            labelSize="0"
            fieldSize="100"
            errorSize="100"
            labelTitle=""
            className="mt10"
          >
            <Label
              labelTitle="Increment"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5"
            >
              <Textbox
                autoComplete="off"
                type="number"
                name="increment"
                value={pcmWatSiteMapTabData.panIncrement}
                placeholder="0"
                onChange={(e: any) => { onChangePCMWATMapTabData('panIncrement', +e.target.value); }}
              />
            </Label>
            <Label
              labelTitle="UOM"
              labelPosition="top"
              labelSize="40"
              fieldSize="60"
              errorSize="100"
              isFieldTouched
              className="mb5 ml5"
            >
              <CustomizedDropdown
                variant="clear"
                full
                list={reticleIncrementUOMs}
                selectedValue={pcmWatSiteMapTabData.panIncrementUOM || reticleIncrementUOMs[0][0]}
                onChange={(value: any) => { onChangePCMWATMapTabData('panIncrementUOM', value); }}
              />
            </Label>
          </Label>
          <Row>
            <Col>

              <div className="mt15">
                <CheckBox
                  className="mr15"
                  value={pcmWatSiteMapTabData.panMode === ReticlePanMode.SELECTED}
                  onValueChange={() => { onChangePCMWATMapTabData('panMode', ReticlePanMode.SELECTED); }}
                />
                {' '}
                Selected
              </div>
              <div className="mt15">
                <CheckBox
                  className="mr15"
                  value={pcmWatSiteMapTabData.panMode === ReticlePanMode.ALL}
                  onValueChange={() => { onChangePCMWATMapTabData('panMode', ReticlePanMode.ALL); }}
                />
                {' '}
                Reticle
              </div>
            </Col>
            <Col>
              <Row>
                <Col />
                <Col>
                  <Button onClick={() => { onPanReticle(ReticlePanDirection.UP); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowup" />
                  </Button>
                </Col>
                <Col />
              </Row>
              <Row>
                <Col className="mr0 pl40">
                  <Button onClick={() => { onPanReticle(ReticlePanDirection.LEFT); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowleft" />
                  </Button>
                </Col>
                <Col className="ml0">
                  <Button onClick={() => { onPanReticle(ReticlePanDirection.RIGHT); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowright" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col />
                <Col>
                  <Button onClick={() => { onPanReticle(ReticlePanDirection.DOWN); }} variant="clear" size="sm" className="w30 btn-config">
                    <i className="dx-icon-arrowdown" />
                  </Button>
                </Col>
                <Col />
              </Row>
            </Col>
          </Row>
          <hr className="mt10 mb10" />
          <Heading size={5}>Columns/Rows</Heading>
          <Row>
            <Col className="text-center">
              <Button onClick={() => { onInsert(ReticleInsertDirection.DOWN); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Reticle Below
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onInsert(ReticleInsertDirection.UP); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Reticle Above
              </Button>
            </Col>
          </Row>
          <Row className="mt5 mb10">
            <Col className="text-center">
              <Button onClick={() => { onInsert(ReticleInsertDirection.RIGHT); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Reticle Right
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { onInsert(ReticleInsertDirection.LEFT); }} variant="clear" size="sm" className="w150 btn-config">
                Insert Reticle Left
              </Button>
            </Col>
          </Row>
          {/* <Row className="mb10">
            <Col className="text-center">
              <Button onClick={() => { }} variant="clear" size="sm" className="w150 btn-config">
                Delete Row Above
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { }} variant="clear" size="sm" className="w150 btn-config">
                Delete Column Left
              </Button>
            </Col>
          </Row>
          <Row className="mb10">
            <Col className="text-center">
              <Button onClick={() => { }} variant="clear" size="sm" className="w150 btn-config">
                Delete Row Below
              </Button>
            </Col>
            <Col className="text-center">
              <Button onClick={() => { }} variant="clear" size="sm" className="w150 btn-config">
                Delete Column Right
              </Button>
            </Col>
          </Row> */}
        </ScrollView>
      </div>
    );
  }
}
