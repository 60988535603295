/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import { UtilityFunctions } from 'components/wafer-control-map/utility';
import { DEFAULT_DIE_COLOR, WAFER_DIAMETER_EXEMPTED_FROM_RETICLE_COLLISION_CROPPING } from 'components/wafer-control-map/wafer-control-map';
import _ from 'lodash';
import { ReticleInsertDirection, ReticlePanDirection, ReticlePanMode } from 'components/wafer-control-map/pcm-wat-site-map-tab';
import toast from 'CustomToast';
import { HomeDieCoordinateSystemFormData } from 'components/wafer-control-map/home-die-coords-form';
import { GLUtility } from './WebGLUtility';
import WaferMapVariablesV2, { DieData, Dies } from './WaferMapVariablesClassV2';
import {
  ActionOnWaferData,
  ColAxisDirection,
  ColFlip,
  DieColorType,
  DieLocation,
  ExclusionType,
  FlipAxis,
  NotchPosition,
  RotateDirection,
  RowAxisDirection,
  RowFlip,
} from '../../wafer-map/web-gl-utils/Enums';
import {
  Canvas,
  DieCircleTextureDetails,
  DieLineTextureDetails,
  DieRectangleTextureDetails,
  DieTriangleTextureDetails,
  RectCoord,
  ReticleData,
  ReticleGridRectCoords,
  ReticleReference,
  StandardReticle,
  Viewport,
  XYPoint,
  ZoneData,
} from '../../wafer-map/web-gl-utils/Types';
import PublishSubscribe, { EventTypes } from '../../PublishSubscribe';
import colors from '../../../../../assets/colors.json';
import { MarkedDieTypeData } from '../types';
import { COORDINATE_SYSTEMS } from '../../wafer-map/WaferMap';

class WaferUtils {
  [key: string]: any;

  waferMapVariables: WaferMapVariablesV2;

  constructor(variablesObject: WaferMapVariablesV2) {
    this.waferMapVariables = variablesObject;
  }

  renderWafer = () => {
    const {
      scaledDieWidth, scaledDieHeight, xAnchor, yAnchor,
    } = this.waferMapVariables;
    this.prepareDataForRendering(scaledDieWidth, scaledDieHeight, xAnchor, yAnchor);
    this.initiateRendering();
  };

  getGPUCoordsGLSingle = (coords: number[], canvas: { width: number, height: number }) => {
    // coords = [x1, y1, x2, y2 ...] - values of alternating x and y points
    const gpuCoords = []; // -1 to 1
    for (let i = 0; i < coords.length - 1; i += 2) {
      gpuCoords.push(
        -1.0 + (coords[i] / canvas.width) * 2,
        -1.0 + (coords[i + 1] / canvas.height) * 2,
      );
    }
    return gpuCoords;
  };

  getFirstTickInViewPortXYPos = (anchor: number, dieLength: number, tickOffset: number, axisStart: number, axisIncrement: number, isFlipped: boolean) => {
    const anchorOffset = isFlipped ? (axisStart + 1) * dieLength : 0;
    const tickPosAtYAnchor = (anchor + anchorOffset + axisIncrement * (dieLength / 2));
    const tickDistance = dieLength * tickOffset;
    const noOfTicksInViewPortBeforeAnchorTick = Math.floor(tickPosAtYAnchor / tickDistance);
    return tickPosAtYAnchor - noOfTicksInViewPortBeforeAnchorTick * tickDistance;
  };

  getReticleCoordsDataAndRenderText = (
    reticleGridRectCoords: ReticleGridRectCoords,
    offsetX: number,
    offsetY: number,
    dieWidth: number,
    dieHeight: number,
    rowOffset: number,
    colOffset: number,
    thicknessFactor: number,
    paddingFactor: 0 | 1,
    colFlip: ColFlip,
    colAxisIncrement: number,
    colAxisStart: number,
    rowFlip: RowFlip,
    rowAxisIncrement: number,
    rowAxisStart: number,
    reticleType: 'all' | 'selected' | 'under_selection',
  ) => {
    const { showReticleText } = this.waferMapVariables;
    const reticles = Object.keys(reticleGridRectCoords);
    const reticleBorderCoords: number[] = [];
    const reticleBGCoords: number[] = [];

    for (let i = 0; i < reticles.length; i += 1) {
      // eslint-disable-next-line no-continue
      if (reticleType === 'under_selection' && !reticleGridRectCoords[reticles[i]].isUnderSelection) continue;
      // eslint-disable-next-line no-continue
      if (reticleType === 'selected' && !reticleGridRectCoords[reticles[i]].isSelected) continue;
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const xAnchorOffset = colFlip === ColFlip.Inverted ? (colAxisStart + 1) * dieWidth : 0;
      const yAnchorOffset = rowFlip === RowFlip.Inverted ? (rowAxisStart + 1) * dieHeight : 0;
      const reticleRect: RectCoord = {
        startX: offsetX + xAnchorOffset + colAxisIncrement * (currReticle.startX - colOffset) * dieWidth,
        startY: offsetY + yAnchorOffset + rowAxisIncrement * (currReticle.startY - rowOffset) * dieHeight,
        endX: offsetX + xAnchorOffset + colAxisIncrement * (currReticle.endX - colOffset + 1) * dieWidth,
        endY: offsetY + yAnchorOffset + rowAxisIncrement * (currReticle.endY - rowOffset + 1) * dieHeight,
      };

      if (reticleType === 'all' && showReticleText && this.renderReticleText(reticleGridRectCoords[reticles[i]], reticleRect, dieWidth, dieHeight)) {
        const unrotatedReticleBGCoords = GLUtility.prepareRectVec(reticleRect.startX, reticleRect.startY, reticleRect.endX, reticleRect.endY);
        reticleBGCoords.push(...unrotatedReticleBGCoords);
      }
      const currReticleBorderCoords = this.getReticleFourSidesGLCoords(dieWidth, dieHeight, thicknessFactor, paddingFactor, reticleRect);
      reticleBorderCoords.push(...currReticleBorderCoords);
    }
    return { reticleBorderCoords, reticleBGCoords };
  };

  renderReticleText = (reticle: ReticleData, reticleRect: RectCoord, dieWidth: number, dieHeight: number): boolean => {
    const {
      textCtx, reticleSize, innerViewPort, gl,
    } = this.waferMapVariables;
    if (textCtx && gl) {
      const reticleText = reticle[this.waferMapVariables.reticleTextField] !== undefined
      && reticle[this.waferMapVariables.reticleTextField] !== null
        ? `${reticle[this.waferMapVariables.reticleTextField]}` : null;
      if (reticleText !== undefined && reticleText !== null) {
        textCtx.font = `${Math.min(Math.abs(reticleSize.x * dieWidth), Math.abs(reticleSize.y * dieHeight)) / 2}px Arial`;
        textCtx.save();
        textCtx.beginPath();
        textCtx.rect(innerViewPort.x, gl.canvas.height - (innerViewPort.y + innerViewPort.height), innerViewPort.width, innerViewPort.height);
        textCtx.clip();
        const xyVal = this.getTickTextXYPos((reticleRect.startX + reticleRect.endX) / 2, (reticleRect.startY + reticleRect.endY) / 2, reticleText);
        textCtx.fillText(reticleText, xyVal.xVal, xyVal.yVal);
        textCtx.restore();
        return true;
      }
      return false;
    }
    return false;
  };

  getReticleReferencePointCoords = (
    canvas: Canvas,
    rotation: number[],
    reticleReference: ReticleReference,
    reticleRect: RectCoord,
  ) => {
    let reticleReferenceCoords: number[] = [];
    let reticleReferencePoint: { x: number, y: number } | null = null;
    let referenceX;
    let referenceY;

    switch (reticleReference) {
      case 'BOTTOM_LEFT':
        referenceX = reticleRect.startX;
        referenceY = reticleRect.endY;
        break;
      case 'BOTTOM_RIGHT':
        referenceX = reticleRect.endX;
        referenceY = reticleRect.endY;
        break;
      case 'TOP_LEFT':
        referenceX = reticleRect.startX;
        referenceY = reticleRect.startY;
        break;
      case 'TOP_RIGHT':
        referenceX = reticleRect.endX;
        referenceY = reticleRect.startY;
        break;
      default:
        referenceX = reticleRect.startX + (reticleRect.endX - reticleRect.startX) / 2;
        referenceY = reticleRect.startY + (reticleRect.endY - reticleRect.startY) / 2;
        break;
    }

    reticleReferenceCoords = [
      referenceX - 5, referenceY - 5, 0,
      referenceX + 5, referenceY + 5, 0,
      referenceX + 5, referenceY - 5, 0,
      referenceX - 5, referenceY + 5, 0,
    ];
    reticleReferencePoint = { x: referenceX, y: referenceY };
    return { reticleReferencePoint, reticleReferenceCoords };
  };

  getReticleFourSidesGLCoords = (
    dieWidth: number,
    dieHeight: number,
    thicknessFactor: number,
    paddingFactor: 0 | 1,
    reticleRect: RectCoord,
  ) => {
    const thickness = (0.033333 * Math.min(dieWidth, dieHeight) + 1.66667) * thicknessFactor; // y = mx + c, where x is min of die width and die height
    const padding = thickness * paddingFactor;
    return [
      ...GLUtility.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.startY + thickness + padding), // top
      ...GLUtility.prepareRectVec(reticleRect.startX + padding, reticleRect.endY - padding - thickness, reticleRect.endX - padding, reticleRect.endY - padding), // bottom
      ...GLUtility.prepareRectVec(reticleRect.startX + padding, reticleRect.startY + padding, reticleRect.startX + thickness + padding, reticleRect.endY - padding), // left
      ...GLUtility.prepareRectVec(reticleRect.endX - padding - thickness, reticleRect.startY + padding, reticleRect.endX - padding, reticleRect.endY - padding), // right
    ];
  };

  getReferenceReticleCoordsData = (
    reticleGridRectCoords: RectCoord,
    offsetX: number,
    offsetY: number,
    dieWidth: number,
    dieHeight: number,
    canvas: Canvas,
    rotation: number[],
    rowOffset: number,
    colOffset: number,
    thicknessFactor: number,
    paddingFactor: 0 | 1,
    reticleReference: ReticleReference,
  ) => {
    let reticleBorderCoords: number[] = [];
    let reticleReferenceCoords: number[] = [];
    let reticleReferencePoint: { x: number, y: number } | null = null;
    const currReticle: RectCoord = reticleGridRectCoords;
    const reticleRect: RectCoord = {
      startX: offsetX + (currReticle.startX - colOffset) * dieWidth,
      startY: offsetY + (currReticle.startY - rowOffset) * dieHeight,
      endX: offsetX + (currReticle.endX - colOffset) * dieWidth + dieWidth,
      endY: offsetY + (currReticle.endY - rowOffset) * dieHeight + dieHeight,
    };

    const reticleReferenceData = this.getReticleReferencePointCoords(canvas, rotation, reticleReference, reticleRect);
    reticleReferenceCoords = reticleReferenceData.reticleReferenceCoords;
    reticleReferencePoint = reticleReferenceData.reticleReferencePoint;
    reticleBorderCoords = this.getReticleFourSidesGLCoords(dieWidth, dieHeight, thicknessFactor, paddingFactor, reticleRect);

    return { reticleBorderCoords, reticleReferenceCoords, reticleReferencePoint };
  };

  getTickTextXYPos = (xOrignal: number, yOrignal: number, tickValue: string | number) => {
    const { angleInDegrees, innerViewPort, textCtx } = this.waferMapVariables;
    if (!textCtx) return { xVal: 0, yVal: 0 };
    const ma = textCtx!.measureText(tickValue.toString());
    let xyVal;
    switch (angleInDegrees) {
      case 0:
        xyVal = {
          xVal: xOrignal,
          yVal: yOrignal,
        };
        break;
      case 90:
        xyVal = {
          xVal: innerViewPort.width - yOrignal,
          yVal: xOrignal,
        };
        break;
      case 180:
        xyVal = {
          xVal: innerViewPort.width - xOrignal,
          yVal: innerViewPort.height - yOrignal,
        };
        break;
      case 270:
        xyVal = {
          xVal: yOrignal,
          yVal: innerViewPort.height - xOrignal,
        };
        break;
      default:
        xyVal = {
          xVal: 0,
          yVal: 0,
        };
        break;
    }

    return {
      xVal: innerViewPort.x + xyVal.xVal,
      yVal: textCtx!.canvas.height - ((innerViewPort.y + innerViewPort.height) - xyVal.yVal) + (ma.actualBoundingBoxAscent - ma.actualBoundingBoxDescent) / 2,
    };
  };

  renderGridLines = () => {
    const {
      xAnchor, scaledDieWidth, colAxisStart, colAxisIncrement, colFlip, innerViewPort, rotation,
      scaledDieHeight, yAnchor, rowAxisIncrement, rowAxisStart, rowFlip, gl, programNormal,
    } = this.waferMapVariables;
    if (!programNormal || !gl) return;
    const gridLinesGLCoords: number[] = [];
    for (
      let i = this.getFirstTickInViewPortXYPos(xAnchor, scaledDieWidth, 1, colAxisStart, colAxisIncrement, colFlip === ColFlip.Inverted);
      i <= innerViewPort.width + scaledDieWidth / 2;
      i += scaledDieWidth
    ) {
      const sx = i - scaledDieWidth / 2;
      const ex = sx + 1;
      const sy = 0;
      const ey = innerViewPort.height;
      const verticalGridLines = GLUtility.prepareRectVec(sx, sy, ex, ey);
      gridLinesGLCoords.push(...verticalGridLines);
    }

    for (
      let i = this.getFirstTickInViewPortXYPos(yAnchor, scaledDieHeight, 1, rowAxisStart, rowAxisIncrement, rowFlip === RowFlip.Inverted);
      i <= innerViewPort.height + scaledDieHeight / 2;
      i += scaledDieHeight
    ) {
      const sx = 0;
      const ex = innerViewPort.width;
      const sy = i - scaledDieHeight / 2;
      const ey = sy + 1;
      const horizontalGridLines = GLUtility.prepareRectVec(sx, sy, ex, ey);
      gridLinesGLCoords.push(...horizontalGridLines);
    }
    GLUtility.renderShape(
      {
        coords: gridLinesGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programNormal,
        viewport: innerViewPort,
        rotation,
        resolution: innerViewPort,
        color: [0.0, 0.0, 0.0, 0.4],
      },
    );
  };

  getMarkedPCMSiteNumbers = () => {
    const { reticleGridRectCoords } = this.waferMapVariables;

    const markedWatPCMSiteNumbers: number[] = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[reticleKey].watPCMSiteNumber !== null) {
        markedWatPCMSiteNumbers.push(reticleGridRectCoords[reticleKey].watPCMSiteNumber!);
      }
    }
    return Array.from(new Set(markedWatPCMSiteNumbers)); // unique
  };

  getFirstReticleTickInViewPortXYPos = (
    reticleSize: number,
    leastStartReticleOnOverlayingReticle: { start: number, end: number },
    offset: number, // row or col offset
    anchor: number,
    dieLength: number,
    tickPosition: 'center' | 'start',
    axisStart: number,
    axisIncrement: number,
    isFlipped: boolean,
    tickOffset: number,
  ) => {
    let tickPosAtAnchor = -1;
    let anhorReticleMidGridWithoutOffset = -1;

    const anchorOffset = isFlipped ? (axisStart + 1) * dieLength : 0;

    if (tickPosition === 'start') {
      tickPosAtAnchor = anchor + anchorOffset;
      anhorReticleMidGridWithoutOffset = leastStartReticleOnOverlayingReticle.start - offset;
    } else if (tickPosition === 'center') {
      tickPosAtAnchor = (anchor + anchorOffset + axisIncrement * (dieLength / 2));
      anhorReticleMidGridWithoutOffset = ((leastStartReticleOnOverlayingReticle.start + leastStartReticleOnOverlayingReticle.end) / 2) - offset;
    }
    const anhorReticleMidPos = tickPosAtAnchor + axisIncrement * anhorReticleMidGridWithoutOffset * dieLength;
    const reticleLength = reticleSize * dieLength;

    const tickDistance = tickOffset === 1 ? reticleLength : reticleLength * Math.ceil(tickOffset / reticleSize);
    const noOfReticleTicksInViewPortBeforeAnchorReticleTick = Math.floor(anhorReticleMidPos / tickDistance);
    return {
      anhorReticleMidPos,
      tickDistance,
      reticleLength,
      firstReticleTickInViewPort: anhorReticleMidPos - noOfReticleTicksInViewPortBeforeAnchorReticleTick * tickDistance,
    };
  };

  renderAxisLabel = (label: string, textCtx: CanvasRenderingContext2D, xCoord: number, yCoord: number) => {
    const start = this.getTickTextXYPos(xCoord, yCoord, 0);
    textCtx.save();
    // eslint-disable-next-line no-param-reassign
    textCtx.fillStyle = '#FF0000';
    // eslint-disable-next-line no-param-reassign
    textCtx.font = `900 ${textCtx.font.toString()}`;
    textCtx.fillText(label, start.xVal, start.yVal);
    textCtx.restore();
  };

  renderTickLinesAndTextAndGetDieTextCoords = () => {
    const {
      textCtx, colAxisStart, colAxisIncrement, rowAxisStart, rowAxisIncrement, scaledDieHeight, scaledDieWidth, xAnchor, yAnchor, waferData, rowAxisDirection,
      colFlip, isDieCoordinatesSystemEnabled, isReticleCoordinatesSystemEnabled, overlayReticle, innerViewPort, outerViewPort, tickLineSize, rotation, angleInDegrees,
      colOffset, programNormal, gl, rowFlip, rowOffset, referenceReticleGridRectCoords, reticleSize, reticleXAxisReference, reticleYAxisReference, colAxisDirection,
    } = this.waferMapVariables;
    if (!textCtx || !programNormal || !gl) return { dieTextXCoords: {}, dieTextYCoords: {} };
    textCtx.textAlign = 'center';
    textCtx.textBaseline = 'middle';
    const { tickOffsetX, tickOffsetY } = this.getTickOffsetsAndSetFont();

    const tickLinesGLCoords: number[] = [];
    const dieTextXCoords: { [xIndex: number]: number } = {};
    const dieTextYCoords: { [xIndex: number]: number } = {};

    let dieTickTextPercent = 0.33; // 0 to 1
    let reticleSubRowColTextPercent = 0.5; // 0 to 1
    let reticleTextPercent = 0.75; // 0 to 1
    let axisLabelsPercent = 0.66;

    if (isDieCoordinatesSystemEnabled && !isReticleCoordinatesSystemEnabled) {
      dieTickTextPercent = 0.33;
      axisLabelsPercent = 0.66;
    }

    if (!isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled) {
      reticleSubRowColTextPercent = 0.4;
      reticleTextPercent = 0.6;
      axisLabelsPercent = 0.8;
    }

    if (isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled) {
      dieTickTextPercent = 0.25;
      reticleSubRowColTextPercent = 0.5;
      reticleTextPercent = 0.65;
      axisLabelsPercent = 0.85;
    }

    for (
      let i = this.getFirstTickInViewPortXYPos(xAnchor, scaledDieWidth, tickOffsetX, colAxisStart, colAxisIncrement, colFlip === ColFlip.Inverted), u = 0;
      i <= innerViewPort.width;
      i += scaledDieWidth, u += 1) {
      const xAnchorOffset = colFlip === ColFlip.Inverted ? (colAxisStart + 1) * scaledDieWidth : 0;
      const tickGridValueWithOffset = Math.round(((xAnchor + xAnchorOffset + colAxisIncrement * (scaledDieWidth / 2)) - i) / scaledDieWidth);
      const tickValue = colOffset - colAxisIncrement * tickGridValueWithOffset;
      const xyDieValTop = this.getTickTextXYPos(i, -(((outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height)) * dieTickTextPercent), tickValue);
      const xyDieValBottom = this.getTickTextXYPos(i, innerViewPort.height - ((outerViewPort.y - innerViewPort.y) * dieTickTextPercent), tickValue);

      const reticleTickValue = referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle
        ? (reticleSize.x - ((referenceReticleGridRectCoords.startX - tickValue) % reticleSize.x)) % reticleSize.x
        : -1;

      if (angleInDegrees === 0 || angleInDegrees === 180) dieTextXCoords[tickValue] = xyDieValTop.xVal;
      else dieTextXCoords[tickValue] = xyDieValTop.yVal;

      if (u % tickOffsetX === 0 && (
        (!isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetX === 1 && tickOffsetY === 1)))
        || (isDieCoordinatesSystemEnabled && !isReticleCoordinatesSystemEnabled)
        || (isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetX === 1 && tickOffsetY === 1)))
      )) {
        if (isDieCoordinatesSystemEnabled) {
          const tickValueBasedOnTrueCoords = colAxisDirection === ColAxisDirection.LeftToRight ? tickValue : waferData.waferMaxCols - 1 + 2 * colOffset - tickValue;
          // ****************** Die tick text - Top ******************
          textCtx.fillText(tickValueBasedOnTrueCoords.toString(), xyDieValTop.xVal, xyDieValTop.yVal);
          // ****************** Die tick text - Bottom ******************
          textCtx.fillText(tickValueBasedOnTrueCoords.toString(), xyDieValBottom.xVal, xyDieValBottom.yVal);
        }
        if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
          const xyReticleValTop = this.getTickTextXYPos(i, -(((outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height)) * reticleSubRowColTextPercent), tickValue);
          const xyReticleValBottom = this.getTickTextXYPos(i, innerViewPort.height - ((outerViewPort.y - innerViewPort.y) * reticleSubRowColTextPercent), tickValue);
          textCtx.fillStyle = '#0000FF';
          // ****************** Reticle Sub Col tick text - Top  ******************
          textCtx.fillText(`${reticleTickValue}`, xyReticleValTop.xVal, xyReticleValTop.yVal);
          // ****************** Reticle Sub Col tick text - Bottom  ******************
          textCtx.fillText(`${reticleTickValue}`, xyReticleValBottom.xVal, xyReticleValBottom.yVal);
          textCtx.fillStyle = '#000000';
        }

        // ****************** Die tick marks ******************
        // ****************** Die tick marks - Top ******************
        const xValOfTick = i + (innerViewPort.x - outerViewPort.x);
        const topTickCoords = [
          xValOfTick,
          (outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height) - tickLineSize / 2,
          0,
          xValOfTick,
          (outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height) + tickLineSize / 2,
          0,
        ];
        tickLinesGLCoords.push(...topTickCoords);

        // ****************** Die tick marks - Bottom ******************
        const bottomTickCoords = [
          xValOfTick,
          outerViewPort.y + outerViewPort.height - innerViewPort.y + tickLineSize / 2,
          0,
          xValOfTick,
          outerViewPort.y + outerViewPort.height - innerViewPort.y - tickLineSize / 2,
          0,
        ];
        tickLinesGLCoords.push(...bottomTickCoords);
      }
    }

    if (isDieCoordinatesSystemEnabled) {
      this.renderAxisLabel(
        'X',
        textCtx,
        outerViewPort.x - innerViewPort.x + outerViewPort.width / 2,
        -(((outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height)) * axisLabelsPercent),
      );
    }

    for (
      let i = this.getFirstTickInViewPortXYPos(yAnchor, scaledDieHeight, tickOffsetY, rowAxisStart, rowAxisIncrement, rowFlip === RowFlip.Inverted), u = 0;
      i <= innerViewPort.height;
      i += scaledDieHeight, u += 1) {
      // ****************** Die tick text ******************
      const yAnchorOffset = rowFlip === RowFlip.Inverted ? (rowAxisStart + 1) * scaledDieHeight : 0;
      const tickGridValueWithOffset = Math.round(((yAnchor + yAnchorOffset + rowAxisIncrement * (scaledDieHeight / 2)) - i) / scaledDieHeight);
      const tickValue = rowOffset - rowAxisIncrement * tickGridValueWithOffset;
      const xyDieValLeft = this.getTickTextXYPos(-(innerViewPort.x - outerViewPort.x) * dieTickTextPercent, i, tickValue);
      const xyDieValRight = this.getTickTextXYPos(innerViewPort.width + (((outerViewPort.x + outerViewPort.width) - (innerViewPort.x + innerViewPort.width)) * dieTickTextPercent), i, tickValue);

      const reticleTickValue = referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle
        ? (reticleSize.y - ((referenceReticleGridRectCoords.startY - tickValue) % reticleSize.y)) % reticleSize.y
        : -1;

      if (angleInDegrees === 0 || angleInDegrees === 180) dieTextYCoords[tickValue] = xyDieValLeft.yVal;
      else dieTextYCoords[tickValue] = xyDieValLeft.xVal;

      if (u % tickOffsetY === 0 && (
        (!isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetY === 1 && tickOffsetX === 1)))
        || (isDieCoordinatesSystemEnabled && !isReticleCoordinatesSystemEnabled)
        || (isDieCoordinatesSystemEnabled && isReticleCoordinatesSystemEnabled && (!overlayReticle || (overlayReticle && tickOffsetY === 1 && tickOffsetX === 1)))
      )) {
        if (isDieCoordinatesSystemEnabled) {
          const tickValueBasedOnTrueCoords = rowAxisDirection === RowAxisDirection.TopToBottom ? tickValue : waferData.waferMaxRows - 1 + 2 * rowOffset - tickValue;
          // ****************** Die tick text - Left ******************
          textCtx.fillText(tickValueBasedOnTrueCoords.toString(), xyDieValLeft.xVal, xyDieValLeft.yVal);
          // ****************** Die tick text - Right ******************
          textCtx.fillText(tickValueBasedOnTrueCoords.toString(), xyDieValRight.xVal, xyDieValRight.yVal);
        }
        if (referenceReticleGridRectCoords && isReticleCoordinatesSystemEnabled && overlayReticle) {
          const xyReticleValLeft = this.getTickTextXYPos(-(innerViewPort.x - outerViewPort.x) * reticleSubRowColTextPercent, i, tickValue);
          const xyReticleValRight = this.getTickTextXYPos(innerViewPort.width + (((outerViewPort.x + outerViewPort.width) - (innerViewPort.x + innerViewPort.width)) * reticleSubRowColTextPercent), i, tickValue);
          textCtx.fillStyle = '#0000FF';
          // ****************** Reticle Sub Col tick text - Left  ******************
          textCtx.fillText(`${reticleTickValue}`, xyReticleValLeft.xVal, xyReticleValLeft.yVal);
          // ****************** Reticle Sub Col tick text - Right  ******************
          textCtx.fillText(`${reticleTickValue}`, xyReticleValRight.xVal, xyReticleValRight.yVal);
          textCtx.fillStyle = '#000000';
        }

        // ****************** Die tick marks ******************
        // ****************** Die tick marks - Left  ******************
        const yValOfTick = i + (innerViewPort.y - outerViewPort.y);
        const leftTickCoords = [
          (innerViewPort.x - outerViewPort.x) - tickLineSize / 2,
          yValOfTick,
          0,
          (innerViewPort.x - outerViewPort.x) + tickLineSize / 2,
          yValOfTick,
          0,
        ];
        tickLinesGLCoords.push(...leftTickCoords);

        // ****************** Die tick marks - Right  ******************
        const rightTickCoords = [
          (innerViewPort.x + innerViewPort.width) - outerViewPort.x - tickLineSize / 2,
          yValOfTick,
          0,
          (innerViewPort.x + innerViewPort.width) - outerViewPort.x + tickLineSize / 2,
          yValOfTick,
          0,
        ];
        tickLinesGLCoords.push(...rightTickCoords);
      }
    }

    if (isDieCoordinatesSystemEnabled) {
      this.renderAxisLabel(
        'Y',
        textCtx,
        -(innerViewPort.x - outerViewPort.x) * axisLabelsPercent,
        outerViewPort.y - innerViewPort.y + outerViewPort.height / 2,
      );
    }

    // ****************** Reticle tick marks and text - Top and Bottom ******************
    if (isReticleCoordinatesSystemEnabled && overlayReticle) {
      const reticleTickMarksData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.x,
        { start: reticleXAxisReference, end: reticleXAxisReference + reticleSize.x - 1 },
        colOffset,
        xAnchor,
        scaledDieWidth,
        tickOffsetX === 1 ? 'start' : 'center',
        colAxisStart,
        colAxisIncrement,
        colFlip === ColFlip.Inverted,
        tickOffsetX,
      );
      for (let i = reticleTickMarksData.firstReticleTickInViewPort; i <= innerViewPort.width; i += reticleTickMarksData.tickDistance) {
        // ****************** Reticle tick marks - Top ******************
        const xValOfTick = i + (innerViewPort.x - outerViewPort.x);
        const topTickCoords = [
          xValOfTick,
          (outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height) - ((tickOffsetX === 1 && tickOffsetY === 1) ? 5 * tickLineSize : tickLineSize),
          0,
          xValOfTick,
          (outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height) + ((tickOffsetX === 1 && tickOffsetY === 1) ? 2 * tickLineSize : tickLineSize),
          0,
        ];
        tickLinesGLCoords.push(...topTickCoords);

        // ****************** Reticle tick marks - Bottom ******************
        const bottomTickCoords = [
          xValOfTick,
          outerViewPort.y + outerViewPort.height - innerViewPort.y + ((tickOffsetX === 1 && tickOffsetY === 1) ? 5 * tickLineSize : tickLineSize),
          0,
          xValOfTick,
          outerViewPort.y + outerViewPort.height - innerViewPort.y - ((tickOffsetX === 1 && tickOffsetY === 1) ? 2 * tickLineSize : tickLineSize),
          0,
        ];
        tickLinesGLCoords.push(...bottomTickCoords);
      }

      const reticleTickTextData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.x,
        { start: reticleXAxisReference, end: reticleXAxisReference + reticleSize.x - 1 },
        colOffset,
        xAnchor,
        scaledDieWidth,
        'center',
        colAxisStart,
        colAxisIncrement,
        colFlip === ColFlip.Inverted,
        tickOffsetX,
      );
      textCtx.fillStyle = '#FF0000';
      for (let i = reticleTickTextData.firstReticleTickInViewPort; i <= innerViewPort.width; i += reticleTickTextData.tickDistance) {
        const tickValue = colAxisIncrement * Math.round((i - reticleTickTextData.anhorReticleMidPos) / reticleTickTextData.reticleLength);
        const xyReticleValTop = this.getTickTextXYPos(i, -(((outerViewPort.y + outerViewPort.height) - (innerViewPort.y + innerViewPort.height)) * reticleTextPercent), tickValue);
        const xyReticleValBottom = this.getTickTextXYPos(i, innerViewPort.height - ((outerViewPort.y - innerViewPort.y) * reticleTextPercent), tickValue);
        // ****************** Reticle tick text - Top ******************
        textCtx.fillText(tickValue.toString(), xyReticleValTop.xVal, xyReticleValTop.yVal);
        // ****************** Reticle tick text - Bottom ******************
        textCtx.fillText(tickValue.toString(), xyReticleValBottom.xVal, xyReticleValBottom.yVal);
      }
      textCtx.fillStyle = '#000000';
    }

    // ****************** Reticle tick marks and text - Left and Right ******************
    if (isReticleCoordinatesSystemEnabled && overlayReticle) {
      const reticleTickMarksData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.y,
        { start: reticleYAxisReference, end: reticleYAxisReference + reticleSize.y - 1 },
        rowOffset,
        yAnchor,
        scaledDieHeight,
        tickOffsetY === 1 ? 'start' : 'center',
        rowAxisStart,
        rowAxisIncrement,
        rowFlip === RowFlip.Inverted,
        tickOffsetY,
      );
      for (let i = reticleTickMarksData.firstReticleTickInViewPort; i <= innerViewPort.height; i += reticleTickMarksData.tickDistance) {
        // ****************** Reticle tick marks - Left ******************
        const yValOfTick = i + (innerViewPort.y - outerViewPort.y);
        const leftTickCoords = [
          (innerViewPort.x - outerViewPort.x) - ((tickOffsetX === 1 && tickOffsetY === 1) ? 5 * tickLineSize : tickLineSize),
          yValOfTick,
          0,
          (innerViewPort.x - outerViewPort.x) + ((tickOffsetX === 1 && tickOffsetY === 1) ? 2 * tickLineSize : tickLineSize),
          yValOfTick,
          0,
        ];
        tickLinesGLCoords.push(...leftTickCoords);

        // ****************** Reticle tick marks - Right  ******************
        const rightTickCoords = [
          (innerViewPort.x + innerViewPort.width) - outerViewPort.x - ((tickOffsetX === 1 && tickOffsetY === 1) ? 2 * tickLineSize : tickLineSize),
          yValOfTick,
          0,
          (innerViewPort.x + innerViewPort.width) - outerViewPort.x + ((tickOffsetX === 1 && tickOffsetY === 1) ? 5 * tickLineSize : tickLineSize),
          yValOfTick,
          0,
        ];
        tickLinesGLCoords.push(...rightTickCoords);
      }

      const reticleTickTextData = this.getFirstReticleTickInViewPortXYPos(
        reticleSize.y,
        { start: reticleYAxisReference, end: reticleYAxisReference + reticleSize.y - 1 },
        rowOffset,
        yAnchor,
        scaledDieHeight,
        'center',
        rowAxisStart,
        rowAxisIncrement,
        rowFlip === RowFlip.Inverted,
        tickOffsetY,
      );
      textCtx.fillStyle = '#FF0000';
      for (let i = reticleTickTextData.firstReticleTickInViewPort; i <= innerViewPort.height; i += reticleTickTextData.tickDistance) {
        const tickValue = rowAxisIncrement * Math.round((i - reticleTickTextData.anhorReticleMidPos) / reticleTickTextData.reticleLength);
        const xyReticleValLeft = this.getTickTextXYPos(-(innerViewPort.x - outerViewPort.x) * reticleTextPercent, i, tickValue);
        const xyReticleValRight = this.getTickTextXYPos(innerViewPort.width + (((outerViewPort.x + outerViewPort.width) - (innerViewPort.x + innerViewPort.width)) * reticleTextPercent), i, tickValue);
        // ****************** Reticle tick text - Left ******************
        textCtx.fillText(tickValue.toString(), xyReticleValLeft.xVal, xyReticleValLeft.yVal);
        // ****************** Reticle tick text - Right ******************
        textCtx.fillText(tickValue.toString(), xyReticleValRight.xVal, xyReticleValRight.yVal);
      }
      textCtx.fillStyle = '#000000';
    }

    GLUtility.renderShape(
      {
        coords: tickLinesGLCoords,
        gl,
        shapeType: gl.LINES,
        program: programNormal,
        resolution: outerViewPort,
        rotation,
        viewport: outerViewPort,
        color: [0.0, 0.0, 0.0, 1.0],
      },
    );
    return { dieTextXCoords, dieTextYCoords };
  };

  getRadialZoneCoords = (zones: ZoneData[], currentZoneId: string, waferDiameter: number, waferEdgeExclusion: number, maxRingRadius: number, centerX: number, centerY: number, canvas: Canvas, rotation: number[]) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'RADIAL' || (filteredZones[0].zoneType === 'RADIAL' && filteredZones[0].numberOfZones === undefined)) return [];
    const numberOfZones = filteredZones[0].numberOfZones!;
    const ringRadiusExcludingEdgeExclusion = ((waferDiameter - 2 * waferEdgeExclusion) / waferDiameter) * maxRingRadius;
    const incrementRadiusSqred = (ringRadiusExcludingEdgeExclusion ** 2) / numberOfZones;
    let currRadiusSqred = incrementRadiusSqred;
    const radialZoneRingCoords: number[] = [];
    for (let r = 0; r < numberOfZones; r += 1, currRadiusSqred += incrementRadiusSqred) {
      radialZoneRingCoords.push(...this.getRingCoords(Math.sqrt(currRadiusSqred), 1000, centerX, centerY, canvas));
    }
    return radialZoneRingCoords;
  };

  getTickOffsetsAndSetFont = () => {
    const {
      angleInDegrees, waferData, colOffset, textCtx, textFont, rowOffset, scaledDieWidth, scaledDieHeight,
    } = this.waferMapVariables;
    let offsetX = 1;
    let offsetY = 1;
    if (!textCtx) {
      return { tickOffsetX: offsetX, tickOffsetY: offsetY };
    }
    let tickTextYBuffer = 1;
    textCtx.font = `${textFont}px Arial`;
    const minXTickText: number = colOffset;
    const maxXTickText: number = waferData.waferMaxCols - 1 + colOffset;

    const maxTickTextWidth: number = Math.max(textCtx.measureText(`${maxXTickText}`).width, textCtx.measureText(`${minXTickText}`).width);
    const tickTextXBuffer: number = maxTickTextWidth * 1.5;
    if (angleInDegrees === 0 || angleInDegrees === 180) {
      tickTextYBuffer = textFont * 1.5;
    } else if (angleInDegrees === 90 || angleInDegrees === 270) {
      const minYTickText: number = rowOffset;
      const maxYTickText = waferData.waferMaxRows - 1 + rowOffset;
      const maxTickTextWidthY: number = Math.max(textCtx.measureText(`${maxYTickText}`).width, textCtx.measureText(`${minYTickText}`).width);
      tickTextYBuffer = maxTickTextWidthY * 1.5;
    }
    if (scaledDieWidth <= tickTextXBuffer) {
      offsetX += Math.floor(
        tickTextXBuffer / (scaledDieWidth / 1.5),
      );
    } else {
      offsetX = 1;
    }
    if (scaledDieHeight <= tickTextYBuffer) {
      offsetY += Math.floor(
        tickTextYBuffer / (scaledDieHeight / 1.5),
      );
    } else {
      offsetY = 1;
    }
    return { tickOffsetX: offsetX, tickOffsetY: offsetY };
  };

  renderDieText = (dieData: DieData, dieTextXCoords: { [xIndex: number]: number }, dieTextYCoords: { [xIndex: number]: number }, i: number, j: number) => {
    const {
      scaledDieWidth, scaledDieHeight, angleInDegrees, textCtx, innerViewPort, gl, dieTextField,
      shouldUseOnlyBinColor, dieTypes, dieTypeField, dieColorType,
    } = this.waferMapVariables;
    if (!textCtx || !gl) return;
    const text = this.getTextFromDieField(dieData, dieTextField) || '';
    const [r, g, b] = this.hashColorToRGB(
      this.getColor(shouldUseOnlyBinColor, dieData!, dieTypes, dieData!.binColor!, dieTypeField, dieColorType, this.getCurrZone(), { zoneColorIndex: 0, zoneColorMapping: {} }),
    );
    let ctxFontSize = scaledDieWidth * 0.2;
    if (angleInDegrees === 90 || angleInDegrees === 270) {
      ctxFontSize = scaledDieHeight * 0.2;
    }
    ctxFontSize /= (Math.floor(text?.toString().length / 5) || 1);
    textCtx.save();
    textCtx.beginPath();
    textCtx.rect(innerViewPort.x, gl.canvas.height - (innerViewPort.y + innerViewPort.height), innerViewPort.width, innerViewPort.height);
    textCtx.clip();
    textCtx.font = `${ctxFontSize}px Arial`;
    textCtx.fillStyle = 0.2126 * r * 255 + 0.7152 * g * 255 + 0.0722 * b * 255 > 125 ? '#000000' : '#FFFFFF';
    if (angleInDegrees === 0 || angleInDegrees === 180) {
      textCtx.fillText(text, dieTextXCoords[j!], dieTextYCoords[i!]);
    } else {
      textCtx.fillText(text, dieTextYCoords[i!], dieTextXCoords[j!]);
    }
    textCtx.fillStyle = '#000000';
    textCtx.restore();
  };

  getTextFromDieField = (die: DieData, field: string) => {
    const { dieTypes, zones, currentZoneId } = this.waferMapVariables;
    if (!(field in die)) return '';
    if (dieTypes && field in dieTypes) {
      if (die[field] === null) return '';
      const dieType = UtilityFunctions.getDieTypeFromId(die[field].toString()!, dieTypes[field]);
      if (dieType === null) return '';
      return dieType.id;
    // eslint-disable-next-line no-else-return
    } else if (field === 'zoneInfo') {
      const currZone = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
      if (currZone.length > 0) {
        const zoneInfo = die[field];
        if (zoneInfo && zoneInfo[currentZoneId]) {
          return zoneInfo[currentZoneId].toString();
        }
        return '';
      }
      return '';
    } else {
      return die[field];
    }
  };

  getWaferWidthHeightDiffInfo = (xAnchor: number, yAnchor: number, dieWidth: number, dieHeight: number) => {
    const {
      waferData, waferWidthToColsRatio, waferHeightToRowsRatio, waferBGOffsetXDies, waferBGOffsetYDies,
    } = this.waferMapVariables;
    const widthOfWaferData = dieWidth * waferData.waferMaxCols;
    const heightOfWaferData = dieHeight * waferData.waferMaxRows;
    const widthOfBg = widthOfWaferData * waferWidthToColsRatio;
    const heightOfBg = heightOfWaferData * waferHeightToRowsRatio;
    return {
      widthOfWaferData,
      heightOfWaferData,
      widthOfBg,
      heightOfBg,
      initialXdiff: (widthOfBg - widthOfWaferData) / 2,
      initialYdiff: (heightOfBg - heightOfWaferData) / 2,
      diff: Math.abs(widthOfBg - heightOfBg),
      centerOfBG: {
        x: xAnchor + widthOfWaferData / 2 + waferBGOffsetXDies * dieWidth,
        y: yAnchor + heightOfWaferData / 2 + waferBGOffsetYDies * dieHeight,
      },
    };
  };

  getWaferBgImageGLCoords = (dieWidth: number, dieHeight: number, xAnchorParam: number, yAnchorParam: number) => {
    const {
      waferBGOffsetXDies, waferBGOffsetYDies, waferTopOffset,
    } = this.waferMapVariables;
    const {
      widthOfWaferData, heightOfWaferData, widthOfBg, heightOfBg, initialXdiff, initialYdiff, diff,
    } = this.getWaferWidthHeightDiffInfo(xAnchorParam, yAnchorParam, dieWidth, dieHeight);
    let sx = xAnchorParam - initialXdiff + waferBGOffsetXDies * dieWidth;
    let sy = yAnchorParam - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight;
    let ex = xAnchorParam + widthOfWaferData + initialXdiff + waferBGOffsetXDies * dieWidth;
    let ey = yAnchorParam + heightOfWaferData + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight;
    if (widthOfBg < heightOfBg) {
      sx = xAnchorParam - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth;
      sy = yAnchorParam - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight;
      ex = xAnchorParam + widthOfWaferData + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth;
      ey = yAnchorParam + heightOfWaferData + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight;
    }
    return GLUtility.prepareRectVecForTexture(sx, sy, ex, ey);
  };

  getWaferBgCenterPoint = (dieWidth: number, dieHeight: number, xAnchorParam: number, yAnchorParam: number) => {
    const { waferBGOffsetXDies, waferBGOffsetYDies, waferTopOffset } = this.waferMapVariables;
    const {
      widthOfWaferData, heightOfWaferData, widthOfBg, heightOfBg, initialXdiff, initialYdiff, diff,
    } = this.getWaferWidthHeightDiffInfo(xAnchorParam, yAnchorParam, dieWidth, dieHeight);
    if (widthOfBg < heightOfBg) {
      return {
        x: (
          xAnchorParam - initialXdiff - diff / 2 + waferBGOffsetXDies * dieWidth // x1
          + xAnchorParam + widthOfWaferData + initialXdiff + diff / 2 + waferBGOffsetXDies * dieWidth // x2
        ) / 2,
        y: (
          yAnchorParam - initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight // y1
          + yAnchorParam + heightOfWaferData + initialYdiff - waferTopOffset + waferBGOffsetYDies * dieHeight // y2
        ) / 2,
      };
    }
    return {
      x: (
        xAnchorParam - initialXdiff + waferBGOffsetXDies * dieWidth // x1
        + xAnchorParam + widthOfWaferData + initialXdiff + waferBGOffsetXDies * dieWidth // x2
      ) / 2,
      y: (
        yAnchorParam - initialYdiff - diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight // y1
        + yAnchorParam + heightOfWaferData + initialYdiff + diff / 2 - waferTopOffset + waferBGOffsetYDies * dieHeight // y2
      ) / 2,
    };
  };

  clearAll = () => {
    const {
      innerViewPort, radarViewPort, outerViewPort, gl, textCtx,
    } = this.waferMapVariables;
    if (!gl || !textCtx) return;
    GLUtility.clearViewport(gl, innerViewPort);
    GLUtility.clearViewport(gl, radarViewPort);
    GLUtility.clearViewport(gl, outerViewPort);
    GLUtility.clearTextViewport(textCtx, {
      x: outerViewPort.x, y: gl.canvas.height - (outerViewPort.y + outerViewPort.height), width: outerViewPort.width, height: outerViewPort.height,
    });
  };

  changeWaferMapVariables = (data: { waferData: { [key:string] : any }, shouldReRender: boolean }) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data.waferData)) {
      if (key in this.waferMapVariables) {
        this.waferMapVariables[key] = value;
      }
    }
    if (data.shouldReRender) this.renderWafer();
  };

  getColor = (
    shouldUseOnlyBinColor: boolean,
    die: DieData,
    dieTypes: any,
    binColor: string,
    dieTypeField: string,
    dieColorType: DieColorType,
    currZone: ZoneData | null,
    zoneColorMappingInfo: { zoneColorIndex: number, zoneColorMapping: { [key: string]: number } },
  ) : string => {
    if (shouldUseOnlyBinColor) return binColor;

    if (dieColorType === DieColorType.DIE_TYPE) {
      if ((dieTypeField && !die[dieTypeField]) && (!die.dieType || die.dieType === '')) return '#000000';
      if (!dieTypeField) return '#000000';
      let dieType;
      if (dieTypeField && die[dieTypeField] && die[dieTypeField] !== '') {
        dieType = UtilityFunctions.getDieTypeFromId(die[dieTypeField], dieTypes[dieTypeField]);
      } else if (die.dieType !== null){
        dieType = die.dieType;
      }
      if (dieType === undefined || dieType === null || dieType.color === undefined || dieType.color === null) return '#000000';
      return dieType.color;
    } else if (dieColorType === DieColorType.ZONE) {
      if (currZone === null || die.zoneInfo === undefined) {
        return DEFAULT_DIE_COLOR;
      } else {
        const currZoneId = currZone.id?.toString();
        if (currZoneId! in die.zoneInfo) {
          if (die.zoneInfo![currZoneId!] && die.zoneInfo![currZoneId!].length === 0) return DEFAULT_DIE_COLOR;
          const mappingKey = JSON.stringify(die.zoneInfo![currZoneId!].sort());
          if (!(mappingKey in zoneColorMappingInfo.zoneColorMapping)) {
            // eslint-disable-next-line no-param-reassign
            zoneColorMappingInfo.zoneColorMapping[mappingKey] = zoneColorMappingInfo.zoneColorIndex;
            // eslint-disable-next-line no-param-reassign
            zoneColorMappingInfo.zoneColorIndex += 1;
          }
          return colors[zoneColorMappingInfo.zoneColorMapping[mappingKey]];
        } else {
          return DEFAULT_DIE_COLOR;
        }
      }
    }
    return binColor;
  };

  getCurrZone = () => {
    const { zones, currentZoneId } = this.waferMapVariables;
    let currZone: ZoneData | null = null;
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      currZone = filteredZones[0];
    }
    return currZone;
  };

  getRingCoords = (currRadius: number, noOfPoints: number, centerX: number, centerY: number, canvas: Canvas) => {
    const ringCoords: number[] = [];
    const radiusY = currRadius;
    const radiusX = (radiusY / canvas.width) * canvas.height;
    for (let i = 0; i < noOfPoints; i += 1) {
      const theta = 2 * Math.PI * (i / noOfPoints);
      const x = centerX + radiusX * Math.cos(theta);
      const y = centerY + radiusY * Math.sin(theta);
      ringCoords.push(x, y, 0);
    }
    return ringCoords;
  };

  getWCMCroppingRingGLCoords = (dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number) => {
    const { innerViewPort } = this.waferMapVariables;
    const ringInfo = this.getRingCenterAndRadius(dieWidth, dieHeight, xAnchor, yAnchor);
    return this.getRingCoords(ringInfo.radius, 1000, ringInfo.center.x, ringInfo.center.y, { width: innerViewPort.width, height: innerViewPort.height });
  };

  getRingCenterAndRadius = (dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number): { center: XYPoint, radius: number } => {
    const {
      panOffsetYToDieStepSizeYRatio, panOffsetXToDieStepSizeXRatio, waferBGOffsetYDies, waferBGOffsetXDies, ringDiameterToWaferDiameterRatio,
    } = this.waferMapVariables;
    const {
      widthOfWaferData, heightOfWaferData, widthOfBg, heightOfBg,
    } = this.getWaferWidthHeightDiffInfo(xAnchor, yAnchor, dieWidth, dieHeight);

    const maxRingRadiusY = Math.max(widthOfBg, heightOfBg) / 2;
    return {
      center: {
        x: xAnchor + widthOfWaferData / 2 + (dieWidth * panOffsetXToDieStepSizeXRatio) + waferBGOffsetXDies * dieWidth,
        y: yAnchor + heightOfWaferData / 2 + (dieHeight * panOffsetYToDieStepSizeYRatio) + waferBGOffsetYDies * dieHeight,
      },
      radius: (ringDiameterToWaferDiameterRatio * maxRingRadiusY),
    };
  };

  setDieLocation = (dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number, innerDieRect: RectCoord, dieData: DieData) => {
    const topLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
    const topRight = { x: innerDieRect.endX, y: innerDieRect.startY };
    const bottomLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
    const bottomRight = { x: innerDieRect.endX, y: innerDieRect.endY };
    const ringInfo = this.getRingCenterAndRadius(dieWidth, dieHeight, xAnchor, yAnchor);

    if (
      UtilityFunctions.euclideanDistance(topLeft, ringInfo.center) <= ringInfo.radius
      && UtilityFunctions.euclideanDistance(topRight, ringInfo.center) <= ringInfo.radius
      && UtilityFunctions.euclideanDistance(bottomLeft, ringInfo.center) <= ringInfo.radius
      && UtilityFunctions.euclideanDistance(bottomRight, ringInfo.center) <= ringInfo.radius
    ) {
      // eslint-disable-next-line no-param-reassign
      dieData!.location = DieLocation.INSIDE_RING;
    } else {
      if (
        UtilityFunctions.euclideanDistance(topLeft, ringInfo.center) > ringInfo.radius
      || UtilityFunctions.euclideanDistance(topRight, ringInfo.center) > ringInfo.radius
      || UtilityFunctions.euclideanDistance(bottomLeft, ringInfo.center) > ringInfo.radius
      || UtilityFunctions.euclideanDistance(bottomRight, ringInfo.center) > ringInfo.radius
      ) {
        // eslint-disable-next-line no-param-reassign
        dieData!.location = DieLocation.ON_RING;
      }
      if (
        UtilityFunctions.euclideanDistance(topLeft, ringInfo.center) > ringInfo.radius
      && UtilityFunctions.euclideanDistance(topRight, ringInfo.center) > ringInfo.radius
      && UtilityFunctions.euclideanDistance(bottomLeft, ringInfo.center) > ringInfo.radius
      && UtilityFunctions.euclideanDistance(bottomRight, ringInfo.center) > ringInfo.radius
      ) {
        // eslint-disable-next-line no-param-reassign
        dieData!.location = DieLocation.OUTSIDE_RING;
      }
    }
  };

  renderBgImages = () => {
    const {
      notchPosition, wcmWaferDiameter, showBgImage, radarWaferBgImageGLCoords, waferBgImageGLCoords,
      gl, programTexture, innerViewPort, showRadar, radarViewPort, rotation,
    } = this.waferMapVariables;
    if (!gl || !programTexture) return;
    const waferBgImage = this.getCurrentWaferBgImage(notchPosition, wcmWaferDiameter);
    if (showBgImage && waferBgImage) {
      GLUtility.renderImage({
        coords: waferBgImageGLCoords,
        gl,
        program: programTexture,
        textureImage: waferBgImage,
        resolution: innerViewPort,
        viewport: innerViewPort,
        rotation,
      });
      if (radarWaferBgImageGLCoords && showRadar) {
        GLUtility.renderImage({
          coords: radarWaferBgImageGLCoords,
          gl,
          program: programTexture,
          textureImage: waferBgImage,
          resolution: innerViewPort,
          viewport: radarViewPort,
          rotation,
        });
      }
    }
  };

  getReticleOffsetLineCoords = (
    p1: { x: number, y: number },
    p2: { x: number, y: number },
  ) => {
    const slope = (p2.y - p1.y) / (p2.x - p1.x);
    const intercept = p1.y - slope * p1.x;

    const points = [];
    const noOfPoints = 50; // number of points in between p1 and p2
    const xIncrement = (p2.x - p1.x) / (noOfPoints + 1);
    for (let i = 1; i <= noOfPoints; i += 1) {
      const x = xIncrement * i + p1.x;
      const y = slope * x + intercept;
      points.push(x, y, 0);
    }
    return points;
  };

  getVerticalZoneCoords = (zones: ZoneData[], currentZoneId: string, colOffset: number, scaledDieWidth: number, xAnchor: number) => {
    const { innerViewPort } = this.waferMapVariables;
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'VERTICAL' || (filteredZones[0].zoneType === 'VERTICAL' && filteredZones[0].numberOfZones === undefined)) return [];

    const verticalZoneCoords: number[] = [];
    for (let i = 0; filteredZones[0].verticalZoneColNumbers && i < filteredZones[0].verticalZoneColNumbers.length; i += 1) {
      const startX = (filteredZones[0].verticalZoneColNumbers[i] + 1 - colOffset) * scaledDieWidth + xAnchor;
      const coords = GLUtility.prepareRectVec(startX - 2, 0, startX + 2, innerViewPort.height);
      verticalZoneCoords.push(...coords);
    }
    return verticalZoneCoords;
  };

  getHorizontalZoneCoords = (zones: ZoneData[], currentZoneId: string, rowOffset: number, scaledDieHeight: number, YAnchor: number) => {
    const { innerViewPort } = this.waferMapVariables;
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    if (filteredZones[0].zoneType !== 'HORIZONTAL' || (filteredZones[0].zoneType === 'HORIZONTAL' && filteredZones[0].numberOfZones === undefined)) return [];

    const horizontalZoneCoords: number[] = [];
    for (let i = 0; filteredZones[0].horizontalZoneRowNumbers && i < filteredZones[0].horizontalZoneRowNumbers.length; i += 1) {
      const startY = (filteredZones[0].horizontalZoneRowNumbers[i] + 1 - rowOffset) * scaledDieHeight + YAnchor;
      const coords = GLUtility.prepareRectVec(0, startY - 2, innerViewPort.width, startY + 2);
      horizontalZoneCoords.push(...coords);
    }
    return horizontalZoneCoords;
  };

  performActionShiftInsertDies = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      dieTypes, rowsTakenByWaferBG, colsTakenByWaferBG, outerViewPort, colAxisDirection, rowAxisDirection,
    } = this.waferMapVariables;
    for (let i = 0; i < this.waferMapVariables.waferData.dies.length; i += 1) {
      for (let j = 0; this.waferMapVariables.waferData.dies[i] && j < this.waferMapVariables.waferData.dies[i].length; j += 1) {
        if (this.waferMapVariables.waferData.dies[i][j]) {
          UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
        }
      }
    }

    const oldRows = this.waferMapVariables.waferData.waferMaxRows;
    const oldCols = this.waferMapVariables.waferData.waferMaxCols;

    if (data.config.direction === 'below' || data.config.direction === 'above') {
      // transverse
      this.waferMapVariables.waferData.dies = this.waferMapVariables.waferData.dies[0].map((__: any, colIndex: any) => this.waferMapVariables.waferData.dies.map((r: any) => r[colIndex]));
    }
    const selectionData = this.getSelectionData(this.waferMapVariables.waferData.dies);
    if (!selectionData.selectionFound) {
      toast.warn('Select atleast one die or select consecutive dies');
      return { data: this.waferMapVariables.waferData, action: data.action };
    }
    for (let i = 0; i < this.waferMapVariables.waferData.dies.length; i += 1) {
      if (selectionData.selectionInfo[i].start !== null && selectionData.selectionInfo[i].end !== null) {
        // unselect dies in this row
        for (let j = 0; j < this.waferMapVariables.waferData.dies[i].length; j += 1) {
          if (this.waferMapVariables.waferData.dies[i][j]) this.waferMapVariables.waferData.dies[i][j]!.isSelected = false;
        }

        const changeCount = selectionData.selectionInfo[i].end - selectionData.selectionInfo[i].start + 1;
        const startInsertingAt = data.config.direction === 'right' || data.config.direction === 'below' ? selectionData.selectionInfo[i].start : selectionData.selectionInfo[i].end + 1;
        this.waferMapVariables.waferData.dies[i].splice(
          startInsertingAt,
          0,
          ...[...new Array(changeCount)].map(() => {
            return {
              binColor: DEFAULT_DIE_COLOR,
              isSelected: true,
              isCropped: false,
              dieType: UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType),
              isDeleted: false,
              bin: Math.floor(Math.random() * 1000),
            };
          }),
        );

        // remove extra nulls if the new dies are inserted in place of null
        if (data.config.direction === 'left' || data.config.direction === 'above') {
          for (let j = 0, index = 0; j < changeCount; j += 1) {
            if (this.waferMapVariables.waferData.dies[i][index] === null) {
              this.waferMapVariables.waferData.dies[i].splice(index, 1);
            } else {
              break;
            }
          }
        } else {
          for (let j = 0, index = this.waferMapVariables.waferData.dies[i].length - 1; j < 5; j += 1, index -= 1) {
            if (this.waferMapVariables.waferData.dies[i][index] === null) {
              this.waferMapVariables.waferData.dies[i].splice(index, 1);
            } else {
              break;
            }
          }
        }
      }
    }
    const maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(this.waferMapVariables.waferData.dies);
    for (let i = 0; i < this.waferMapVariables.waferData.dies.length; i += 1) {
      if (this.waferMapVariables.waferData.dies[i]) {
        const len = this.waferMapVariables.waferData.dies[i].length;
        const nullInsertionCount = maxRowsAndCols.maxCols - len;
        const startInsertingAt = data.config.direction === 'right' || data.config.direction === 'below' ? len : 0;
        this.waferMapVariables.waferData.dies[i].splice(
          startInsertingAt,
          0,
          ...new Array(nullInsertionCount).fill(null, 0, nullInsertionCount),
        );
      }
    }

    if (data.config.direction === 'right' || data.config.direction === 'left') {
      this.waferMapVariables.waferData.waferMaxCols = maxRowsAndCols.maxCols;
      this.waferMapVariables.waferData.waferMaxRows = maxRowsAndCols.maxRows;
      const newCols = this.waferMapVariables.waferData.waferMaxCols;
      this.waferMapVariables.waferWidthToColsRatio = colsTakenByWaferBG / newCols;
      const changeInCols = newCols - oldCols;
      if (data.config.direction === 'right') {
        this.waferMapVariables.waferBGOffsetXDies -= 0.5 * changeInCols;
        if (colAxisDirection === ColAxisDirection.RightToLeft) this.waferMapVariables.colOffset -= changeInCols;
      } else if (data.config.direction === 'left') {
        this.waferMapVariables.waferBGOffsetXDies += 0.5 * changeInCols;
        if (colAxisDirection === ColAxisDirection.LeftToRight) this.waferMapVariables.colOffset -= changeInCols;
      }
    } else if (data.config.direction === 'below' || data.config.direction === 'above') {
      this.waferMapVariables.waferData.waferMaxCols = maxRowsAndCols.maxRows;
      this.waferMapVariables.waferData.waferMaxRows = maxRowsAndCols.maxCols;
      this.waferMapVariables.waferData.dies = this.waferMapVariables.waferData.dies[0].map((__: any, colIndex: any) => this.waferMapVariables.waferData.dies.map((r: any) => r[colIndex]));
      const newRows = this.waferMapVariables.waferData.waferMaxRows;
      this.waferMapVariables.waferHeightToRowsRatio = rowsTakenByWaferBG / newRows;
      const changeInRows = newRows - oldRows;
      if (data.config.direction === 'below') {
        this.waferMapVariables.waferBGOffsetYDies -= 0.5 * changeInRows;
        if (rowAxisDirection === RowAxisDirection.BottomToTop) this.waferMapVariables.rowOffset -= changeInRows;
      } else if (data.config.direction === 'above') {
        this.waferMapVariables.waferBGOffsetYDies += 0.5 * changeInRows;
        if (rowAxisDirection === RowAxisDirection.TopToBottom) this.waferMapVariables.rowOffset -= changeInRows;
      }
    }
    this.waferMapVariables.onSetOuterViewport(outerViewPort);
    this.waferMapVariables.processData();
    this.renderWafer();
    return {
      data: this.waferMapVariables.waferData, action: data.action, colOffset: this.waferMapVariables.colOffset, rowOffset: this.waferMapVariables.rowOffset,
    };
  };

  performActionInsertRowCol = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      dieTypes, colsTakenByWaferBG, rowsTakenByWaferBG, outerViewPort, colAxisDirection, rowAxisDirection,
    } = this.waferMapVariables;
    let currIndex = -1;

    if (data.config.dimension === 'row') {
      for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
        for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
          if (this.waferMapVariables.waferData.dies[i][j]) {
            UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
            if (this.waferMapVariables.waferData.dies[i][j]!.isSelected && (currIndex === -1 || data.config.direction === 'below')) {
              currIndex = i;
            }
          }
        }
      }
    } else {
      for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
        for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
          if (this.waferMapVariables.waferData.dies[i][j]) {
            UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
            if (this.waferMapVariables.waferData.dies[i][j]!.isSelected && (currIndex === -1 || data.config.direction === 'right')) {
              currIndex = j;
            }
          }
        }
      }
    }

    if (currIndex === -1) {
      toast.warn('Select atleast one die');
      return { data: this.waferMapVariables.waferData, action: data.action };
    }

    if (data.config.dimension === 'row') {
      const newRow: any [] = [];
      for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
        newRow.push({
          binColor: DEFAULT_DIE_COLOR,
          isSelected: false,
          dieType: UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType),
          isCropped: false,
          isDeleted: false,
          bin: Math.floor(Math.random() * 1000),
        });
      }
      this.waferMapVariables.waferData.dies.splice(
        currIndex + (data.config.direction === 'below' ? 1 : 0),
        0,
        newRow,
      );

      this.waferMapVariables.waferData.waferMaxRows += 1;
      const newRows = this.waferMapVariables.waferData.waferMaxRows;
      this.waferMapVariables.waferHeightToRowsRatio = rowsTakenByWaferBG / newRows;
      if (data.config.direction === 'above') {
        this.waferMapVariables.waferBGOffsetYDies += 0.5;
        if (rowAxisDirection === RowAxisDirection.TopToBottom) this.waferMapVariables.rowOffset -= 1;
      } else if (data.config.direction === 'below') {
        this.waferMapVariables.waferBGOffsetYDies -= 0.5;
        if (rowAxisDirection === RowAxisDirection.BottomToTop) this.waferMapVariables.rowOffset -= 1;
      }
    } else if (data.config.dimension === 'col') {
      for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
        this.waferMapVariables.waferData.dies[i].splice(
          currIndex + (data.config.direction === 'right' ? 1 : 0),
          0,
          {
            binColor: DEFAULT_DIE_COLOR,
            isSelected: false,
            dieType: UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType),
            isCropped: false,
            isDeleted: false,
            bin: Math.floor(Math.random() * 1000),
          },
        );
      }
      this.waferMapVariables.waferData.waferMaxCols += 1;
      const newCols = this.waferMapVariables.waferData.waferMaxCols;
      this.waferMapVariables.waferWidthToColsRatio = colsTakenByWaferBG / newCols;
      if (data.config.direction === 'left') {
        this.waferMapVariables.waferBGOffsetXDies += 0.5;
        if (colAxisDirection === ColAxisDirection.LeftToRight) this.waferMapVariables.colOffset -= 1;
      } else if (data.config.direction === 'right') {
        this.waferMapVariables.waferBGOffsetXDies -= 0.5;
        if (colAxisDirection === ColAxisDirection.RightToLeft) this.waferMapVariables.colOffset -= 1;
      }
    }
    this.waferMapVariables.onSetOuterViewport(outerViewPort);
    this.waferMapVariables.processData();
    this.renderWafer();
    return {
      data: this.waferMapVariables.waferData, action: data.action, colOffset: this.waferMapVariables.colOffset, rowOffset: this.waferMapVariables.rowOffset,
    };
  };

  performActionShiftDeleteDies = (data: { action: ActionOnWaferData, config: any }) => {
    const { dieTypes, outerViewPort } = this.waferMapVariables;
    for (let i = 0; i < this.waferMapVariables.waferData.dies.length; i += 1) {
      for (let j = 0; j < this.waferMapVariables.waferData.dies[i].length; j += 1) {
        if (this.waferMapVariables.waferData.dies[i][j]) {
          UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
        }
      }
    }

    if (data.config.direction === 'below' || data.config.direction === 'above') {
      // transverse
      this.waferMapVariables.waferData.dies = this.waferMapVariables.waferData.dies[0].map((__: any, colIndex: any) => this.waferMapVariables.waferData.dies.map((r: any) => r[colIndex]));
    }
    const selectionData = this.getSelectionData(this.waferMapVariables.waferData.dies);
    if (!selectionData.selectionFound) {
      toast.warn('Select atleast one die or select consecutive dies');
      return { data: this.waferMapVariables.waferData, action: data.action };
    }
    for (let i = 0; i < this.waferMapVariables.waferData.dies.length; i += 1) {
      if (selectionData.selectionInfo[i].start !== null && selectionData.selectionInfo.end !== null) {
        const changeCount = selectionData.selectionInfo[i].end - selectionData.selectionInfo[i].start + 1;
        this.waferMapVariables.waferData.dies[i].splice(selectionData.selectionInfo[i].start, changeCount);
        if (data.config.direction === 'right' || data.config.direction === 'below') {
          this.waferMapVariables.waferData.dies[i].splice(0, 0, ...new Array(changeCount).fill(null, 0, changeCount));
        } else if (data.config.direction === 'left' || data.config.direction === 'above') {
          this.waferMapVariables.waferData.dies[i].splice(this.waferMapVariables.waferData.dies[i].length, 0, ...new Array(changeCount).fill(null, 0, changeCount));
        }
      }
    }
    if (data.config.direction === 'below' || data.config.direction === 'above') {
      // transverse
      this.waferMapVariables.waferData.dies = this.waferMapVariables.waferData.dies[0].map((__: any, colIndex: any) => this.waferMapVariables.waferData.dies.map((r: any) => r[colIndex]));
    }
    this.waferMapVariables.onSetOuterViewport(outerViewPort);
    this.waferMapVariables.processData();
    this.renderWafer();
    return { data: this.waferMapVariables.waferData, action: data.action };
  };

  performActionDeleteRowCol = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      dieTypes, colsTakenByWaferBG, rowsTakenByWaferBG, outerViewPort, colAxisDirection, rowAxisDirection,
    } = this.waferMapVariables;
    let currIndex = -1;

    if (data.config.dimension === 'row') {
      for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
        for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
          if (this.waferMapVariables.waferData.dies[i][j]) {
            UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
            if (this.waferMapVariables.waferData.dies[i][j]!.isSelected && (currIndex === -1 || data.config.direction === 'below')) {
              currIndex = i;
            }
          }
        }
      }
    } else {
      for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
        for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
          if (this.waferMapVariables.waferData.dies[i][j]) {
            UtilityFunctions.deleteReticleInfo(this.waferMapVariables.waferData.dies[i][j]!, dieTypes.dieType);
            if (this.waferMapVariables.waferData.dies[i][j]!.isSelected && (currIndex === -1 || data.config.direction === 'right')) {
              currIndex = j;
            }
          }
        }
      }
    }
    if (currIndex === -1) {
      toast.warn('Select atleast one die');
      return { data: this.waferMapVariables.waferData, action: data.action };
    }
    if (data.config.dimension === 'row') {
      let indexToDel = 0;
      if (data.config.direction === 'above') {
        indexToDel = currIndex - 1;
        if (indexToDel < 0) {
          toast.warn('No row above');
          return { data: this.waferMapVariables.waferData, action: data.action };
        }
        this.waferMapVariables.waferBGOffsetYDies -= 0.5;
        if (rowAxisDirection === RowAxisDirection.TopToBottom) this.waferMapVariables.rowOffset += 1;
      } else if (data.config.direction === 'below') {
        indexToDel = currIndex + 1;
        if (indexToDel >= this.waferMapVariables.waferData.waferMaxRows) {
          toast.warn('No row below');
          return { data: this.waferMapVariables.waferData, action: data.action };
        }
        this.waferMapVariables.waferBGOffsetYDies += 0.5;
        if (rowAxisDirection === RowAxisDirection.BottomToTop) this.waferMapVariables.rowOffset += 1;
      }
      this.waferMapVariables.waferData.dies.splice(indexToDel, 1);
      this.waferMapVariables.waferData.waferMaxRows -= 1;
      const newRows = this.waferMapVariables.waferData.waferMaxRows;
      this.waferMapVariables.waferHeightToRowsRatio = rowsTakenByWaferBG / newRows;
    } else if (data.config.dimension === 'col') {
      let indexToDel = 0;
      if (data.config.direction === 'left') {
        indexToDel = currIndex - 1;
        if (indexToDel < 0) {
          toast.warn('No column towards left');
          return { data: this.waferMapVariables.waferData, action: data.action };
        }
        this.waferMapVariables.waferBGOffsetXDies -= 0.5;
        if (colAxisDirection === ColAxisDirection.LeftToRight) this.waferMapVariables.colOffset += 1;
      } else if (data.config.direction === 'right') {
        indexToDel = currIndex + 1;
        if (indexToDel >= this.waferMapVariables.waferData.waferMaxCols) {
          toast.warn('No column towards right');
          return { data: this.waferMapVariables.waferData, action: data.action };
        }
        this.waferMapVariables.waferBGOffsetXDies += 0.5;
        if (colAxisDirection === ColAxisDirection.RightToLeft) this.waferMapVariables.colOffset += 1;
      }
      for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
        this.waferMapVariables.waferData.dies[i].splice(indexToDel, 1);
      }
      this.waferMapVariables.waferData.waferMaxCols -= 1;
      const newCols = this.waferMapVariables.waferData.waferMaxCols;
      this.waferMapVariables.waferWidthToColsRatio = colsTakenByWaferBG / newCols;
    }
    this.waferMapVariables.onSetOuterViewport(outerViewPort);
    this.waferMapVariables.processData();
    this.renderWafer();
    return {
      data: this.waferMapVariables.waferData, action: data.action, colOffset: this.waferMapVariables.colOffset, rowOffset: this.waferMapVariables.rowOffset,
    };
  };

  getSelectionData = (data: any[][]) => {
    const selectionInfo: any = [];
    let maxNewInsertionCount = -1;
    const rows = data.length;
    const cols = data[0] ? data[0].length : 0;
    let selectionFound = false;
    const selectionCoords: { x: number, y: number }[] = []; // keeps track of all dies x and y values (not axes coordinates) that were selected
    for (let i = 0; i < rows; i += 1) {
      selectionInfo.push({ start: null, end: null, selectionComplete: false });
      for (let j = 0; j < cols; j += 1) {
        if (data[i][j] && data[i][j].isSelected) {
          selectionCoords.push({ x: data[i][j].x, y: data[i][j].y });
          if (selectionInfo[i].selectionComplete) {
            // if a die gets selected after the selection is complete - means non-consecutive dies are selected
            selectionInfo[i].start = null;
            selectionInfo[i].end = null;
            selectionInfo[i].selectionComplete = false;
            break;
          }
          selectionInfo[i].start = selectionInfo[i].start === null ? j : selectionInfo[i].start;
          selectionInfo[i].end = j;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (selectionInfo[i].start !== null && selectionInfo[i].end !== null) {
            selectionInfo[i].selectionComplete = true;
          }
        }
      }
      // eslint-disable-next-line no-lonely-if
      if (selectionInfo[i].start !== null && selectionInfo[i].end !== null) {
        selectionInfo[i].selectionComplete = true;
      }
      if (selectionInfo[i].start !== null && selectionInfo[i].end !== null && maxNewInsertionCount < (selectionInfo[i].end - selectionInfo[i].start + 1)) {
        maxNewInsertionCount = selectionInfo[i].end - selectionInfo[i].start + 1;
      }
      if (selectionInfo[i].selectionComplete) selectionFound = true;
    }
    return {
      selectionInfo, maxNewInsertionCount, selectionFound, selectionCoords,
    };
  };

  getRotatedLineTextureCorners = (params: {
    topLeft: XYPoint, topRight: XYPoint, bottomLeft: XYPoint, bottomRight: XYPoint,
    dieInnerWidth: number, dieInnerHeight: number, innerDieRect: RectCoord, angle: number,
    isClockwise: boolean,
  }) => {
    // rotates the vertical texture lines w.r.t die center as origin
    // center shift
    const topLeft1 = { x: -1 * (params.dieInnerWidth / 2 + params.innerDieRect.startX - params.topLeft.x), y: params.dieInnerHeight / 2 + params.innerDieRect.startY - params.topLeft.y };
    const topRight1 = { x: -1 * (params.dieInnerWidth / 2 + params.innerDieRect.startX - params.topRight.x), y: params.dieInnerHeight / 2 + params.innerDieRect.startY - params.topRight.y };
    const bottomLeft1 = { x: -1 * (params.dieInnerWidth / 2 + params.innerDieRect.startX - params.bottomLeft.x), y: params.dieInnerHeight / 2 + params.innerDieRect.startY - params.bottomLeft.y };
    const bottomRight1 = { x: -1 * (params.dieInnerWidth / 2 + params.innerDieRect.startX - params.bottomRight.x), y: params.dieInnerHeight / 2 + params.innerDieRect.startY - params.bottomRight.y };
    // rotate
    const topLeft2 = UtilityFunctions.rotate(topLeft1, params.angle, params.isClockwise);
    const topRight2 = UtilityFunctions.rotate(topRight1, params.angle, params.isClockwise);
    const bottomLeft2 = UtilityFunctions.rotate(bottomLeft1, params.angle, params.isClockwise);
    const bottomRight2 = UtilityFunctions.rotate(bottomRight1, params.angle, params.isClockwise);
    // shift back and return
    return {
      topLeft: { x: params.dieInnerWidth / 2 + params.innerDieRect.startX + topLeft2.x, y: params.dieInnerHeight / 2 + params.innerDieRect.startY - topLeft2.y },
      topRight: { x: params.dieInnerWidth / 2 + params.innerDieRect.startX + topRight2.x, y: params.dieInnerHeight / 2 + params.innerDieRect.startY - topRight2.y },
      bottomLeft: { x: params.dieInnerWidth / 2 + params.innerDieRect.startX + bottomLeft2.x, y: params.dieInnerHeight / 2 + params.innerDieRect.startY - bottomLeft2.y },
      bottomRight: { x: params.dieInnerWidth / 2 + params.innerDieRect.startX + bottomRight2.x, y: params.dieInnerHeight / 2 + params.innerDieRect.startY - bottomRight2.y },
    };
  };

  getTextureLineGLAndColorCoords = (lineTextureDetails: DieLineTextureDetails, innerDieRect: RectCoord, isClockwise: boolean) => {
    const { scaledDieWidth, scaledDieHeight } = this.waferMapVariables;
    const coords: number[] = [];
    const coordsColor: number[] = [];
    const { sw, sh } = this.getStreetDimensions(false);

    const dieInnerWidth = scaledDieWidth - sw;
    const dieInnerHeight = scaledDieHeight - sh;

    const lt = lineTextureDetails.lineThickness * dieInnerWidth;
    const sp = lineTextureDetails.lineSpacing * dieInnerWidth;
    const of = lineTextureDetails.offset * dieInnerWidth;

    for (let u = 0; u <= Math.ceil(dieInnerWidth / (lt + sp)); u += 1) {
      // length of texture line is equal to diagonal
      const lineLength = Math.sqrt(dieInnerWidth * dieInnerWidth + dieInnerHeight * dieInnerHeight);
      const extraLength = (lineLength - dieInnerHeight) / 2;

      const midX = innerDieRect.startX + of + (sp + lt) * u;

      let topLeft: XYPoint = { x: midX - lt / 2, y: innerDieRect.startY - extraLength };
      let topRight: XYPoint = { x: topLeft.x + lt, y: topLeft.y };
      let bottomLeft: XYPoint = { x: topLeft.x, y: innerDieRect.endY + extraLength };
      let bottomRight: XYPoint = { x: bottomLeft.x + lt, y: bottomLeft.y };

      ({
        topLeft, topRight, bottomLeft, bottomRight,
      } = this.getRotatedLineTextureCorners({
        topLeft, topRight, bottomLeft, bottomRight, innerDieRect, dieInnerHeight, dieInnerWidth, angle: lineTextureDetails.angle, isClockwise,
      }));

      // general form of line: ax + by = c
      // after rearranging y = mx + c =>
      // a = y1 - y2, b = x2 - x1, c = y1 * x2 - x1 * y2

      // Equations of lines representing inner borders of die
      const topBorderLine = {
        a: 0,
        b: innerDieRect.endX - innerDieRect.startX,
        c: innerDieRect.startY * innerDieRect.endX - innerDieRect.startX * innerDieRect.startY,
      };
      const bottomBorderLine = {
        a: 0,
        b: innerDieRect.endX - innerDieRect.startX,
        c: innerDieRect.endY * innerDieRect.endX - innerDieRect.startX * innerDieRect.endY,
      };
      const leftBorderLine = {
        a: innerDieRect.startY - innerDieRect.endY,
        b: 0,
        c: innerDieRect.startY * innerDieRect.startX - innerDieRect.startX * innerDieRect.endY,
      };
      const rightBorderLine = {
        a: innerDieRect.startY - innerDieRect.endY,
        b: 0,
        c: innerDieRect.startY * innerDieRect.endX - innerDieRect.endX * innerDieRect.endY,
      };

      // Equations of lines representing left and right sides of current texture line
      const currLineLeftSide = {
        a: topLeft.y - bottomLeft.y,
        b: bottomLeft.x - topLeft.x,
        c: topLeft.y * bottomLeft.x - topLeft.x * bottomLeft.y,
      };
      const currLineRightSide = {
        a: topRight.y - bottomRight.y,
        b: bottomRight.x - topRight.x,
        c: topRight.y * bottomRight.x - topRight.x * bottomRight.y,
      };

      let isLeftIntersectedWithTop = false;
      let isLeftIntersectedWithBottom = false;
      let isLeftIntersectedWithLeft = false;
      let isLeftIntersectedWithRight = false;
      let resTop = UtilityFunctions.solveSimultaneous(topBorderLine.a, topBorderLine.b, topBorderLine.c, currLineLeftSide.a, currLineLeftSide.b, currLineLeftSide.c);
      if (resTop) {
        resTop = resTop as XYPoint;
        if (resTop.x >= innerDieRect.startX && resTop.x <= innerDieRect.endX) {
          topLeft = resTop;
          isLeftIntersectedWithTop = true;
        }
      }
      let resBottom = UtilityFunctions.solveSimultaneous(bottomBorderLine.a, bottomBorderLine.b, bottomBorderLine.c, currLineLeftSide.a, currLineLeftSide.b, currLineLeftSide.c);
      if (resBottom) {
        resBottom = resBottom as XYPoint;
        if (resBottom.x >= innerDieRect.startX && resBottom.x <= innerDieRect.endX) {
          bottomLeft = resBottom;
          isLeftIntersectedWithBottom = true;
        }
      }
      let resLeft = UtilityFunctions.solveSimultaneous(leftBorderLine.a, leftBorderLine.b, leftBorderLine.c, currLineLeftSide.a, currLineLeftSide.b, currLineLeftSide.c);
      if (resLeft) {
        resLeft = resLeft as XYPoint;
        if (resLeft.y >= innerDieRect.startY && resLeft.y <= innerDieRect.endY) {
          if (isClockwise) {
            bottomLeft = resLeft;
          } else {
            topLeft = resLeft;
          }
          isLeftIntersectedWithLeft = true;
        }
      }
      let resRight = UtilityFunctions.solveSimultaneous(rightBorderLine.a, rightBorderLine.b, rightBorderLine.c, currLineLeftSide.a, currLineLeftSide.b, currLineLeftSide.c);
      if (resRight) {
        resRight = resRight as XYPoint;
        if (resRight.y >= innerDieRect.startY && resRight.y <= innerDieRect.endY) {
          if (isClockwise) {
            topLeft = resRight;
          } else {
            bottomLeft = resRight;
          }
          isLeftIntersectedWithRight = true;
        }
      }

      let isRightIntersectedWithTop = false;
      let isRightIntersectedWithBottom = false;
      let isRightIntersectedWithLeft = false;
      let isRightIntersectedWithRight = false;
      let resTop1 = UtilityFunctions.solveSimultaneous(topBorderLine.a, topBorderLine.b, topBorderLine.c, currLineRightSide.a, currLineRightSide.b, currLineRightSide.c);
      if (resTop1) {
        resTop1 = resTop1 as XYPoint;
        if (resTop1.x >= innerDieRect.startX && resTop1.x <= innerDieRect.endX) {
          topRight = resTop1;
          isRightIntersectedWithTop = true;
        }
      }
      let resBottom1 = UtilityFunctions.solveSimultaneous(bottomBorderLine.a, bottomBorderLine.b, bottomBorderLine.c, currLineRightSide.a, currLineRightSide.b, currLineRightSide.c);
      if (resBottom1) {
        resBottom1 = resBottom1 as XYPoint;
        if (resBottom1.x >= innerDieRect.startX && resBottom1.x <= innerDieRect.endX) {
          bottomRight = resBottom1;
          isRightIntersectedWithBottom = true;
        }
      }
      let resLeft1 = UtilityFunctions.solveSimultaneous(leftBorderLine.a, leftBorderLine.b, leftBorderLine.c, currLineRightSide.a, currLineRightSide.b, currLineRightSide.c);
      if (resLeft1) {
        resLeft1 = resLeft1 as XYPoint;
        if (resLeft1.y >= innerDieRect.startY && resLeft1.y <= innerDieRect.endY) {
          if (isClockwise) {
            bottomRight = resLeft1;
          } else {
            topRight = resLeft1;
          }
          isRightIntersectedWithLeft = true;
        }
      }
      let resRight1 = UtilityFunctions.solveSimultaneous(rightBorderLine.a, rightBorderLine.b, rightBorderLine.c, currLineRightSide.a, currLineRightSide.b, currLineRightSide.c);
      if (resRight1) {
        resRight1 = resRight1 as XYPoint;
        if (resRight1.y >= innerDieRect.startY && resRight1.y <= innerDieRect.endY) {
          if (isClockwise) {
            topRight = resRight1;
          } else {
            bottomRight = resRight1;
          }
          isRightIntersectedWithRight = true;
        }
      }
      if (isClockwise) {
        if (!isLeftIntersectedWithTop && !isLeftIntersectedWithBottom && !isLeftIntersectedWithLeft && !isLeftIntersectedWithRight) {
          if (isRightIntersectedWithTop && isRightIntersectedWithLeft) {
            topLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
            bottomLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
          } else if (isRightIntersectedWithTop && isRightIntersectedWithBottom) {
            topLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
            bottomLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
          } else if (isRightIntersectedWithRight && isRightIntersectedWithLeft) {
            topLeft = { x: innerDieRect.endX, y: innerDieRect.startY };
            bottomLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
          }
        }
        if (!isRightIntersectedWithTop && !isRightIntersectedWithBottom && !isRightIntersectedWithLeft && !isRightIntersectedWithRight) {
          if (isLeftIntersectedWithTop && isLeftIntersectedWithBottom) {
            topRight = { x: innerDieRect.endX, y: innerDieRect.startY };
            bottomRight = { x: innerDieRect.endX, y: innerDieRect.endY };
          } else if (isLeftIntersectedWithRight && isLeftIntersectedWithBottom) {
            topRight = { x: innerDieRect.endX, y: innerDieRect.endY };
            bottomRight = { x: innerDieRect.endX, y: innerDieRect.endY };
          } else if (isLeftIntersectedWithRight && isLeftIntersectedWithLeft) {
            topRight = { x: innerDieRect.endX, y: innerDieRect.endY };
            bottomRight = { x: innerDieRect.startX, y: innerDieRect.endY };
          }
        }
      } else {
        if (!isLeftIntersectedWithTop && !isLeftIntersectedWithBottom && !isLeftIntersectedWithLeft && !isLeftIntersectedWithRight) {
          if (isRightIntersectedWithTop && isRightIntersectedWithBottom) {
            topLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
            bottomLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
          } else if (isRightIntersectedWithLeft && isRightIntersectedWithBottom) {
            topLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
            bottomLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
          } else if (isRightIntersectedWithRight && isRightIntersectedWithLeft) {
            topLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
            bottomLeft = { x: innerDieRect.endX, y: innerDieRect.endY };
          }
        }
        if (!isRightIntersectedWithTop && !isRightIntersectedWithBottom && !isRightIntersectedWithLeft && !isRightIntersectedWithRight) {
          if (isLeftIntersectedWithTop && isLeftIntersectedWithBottom) {
            topRight = { x: innerDieRect.endX, y: innerDieRect.startY };
            bottomRight = { x: innerDieRect.endX, y: innerDieRect.endY };
          } else if (isLeftIntersectedWithRight && isLeftIntersectedWithTop) {
            topRight = { x: innerDieRect.endX, y: innerDieRect.startY };
            bottomRight = { x: innerDieRect.endX, y: innerDieRect.startY };
          } else if (isLeftIntersectedWithRight && isLeftIntersectedWithLeft) {
            topRight = { x: innerDieRect.startX, y: innerDieRect.startY };
            bottomRight = { x: innerDieRect.endX, y: innerDieRect.startY };
          }
        }
      }

      if (!isLeftIntersectedWithTop && !isLeftIntersectedWithBottom && !isLeftIntersectedWithLeft && !isLeftIntersectedWithRight
        && !isRightIntersectedWithTop && !isRightIntersectedWithBottom && !isRightIntersectedWithLeft && !isRightIntersectedWithRight
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      coords.push(
        topLeft.x,
        topLeft.y,
        0,
        topRight.x,
        topRight.y,
        0,
        bottomLeft.x,
        bottomLeft.y,
        0,
        bottomLeft.x,
        bottomLeft.y,
        0,
        topRight.x,
        topRight.y,
        0,
        bottomRight.x,
        bottomRight.y,
        0,
      );

      const color = this.hashColorToRGB(lineTextureDetails.color);
      coordsColor.push(
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
      );
    }
    return { coords, coordsColor };
  };

  getTextureCircleGLAndColorCoords = (circleTextureDetails: DieCircleTextureDetails[], innerDieRect: RectCoord) => {
    const { scaledDieWidth, scaledDieHeight } = this.waferMapVariables;
    const { sw, sh } = this.getStreetDimensions(false);
    const coords: number[] = [];
    const coordsColor: number[] = [];
    const dieInnerWidth = scaledDieWidth - sw;
    const dieInnerHeight = scaledDieHeight - sh;
    for (let t = 0; t < circleTextureDetails.length; t += 1) {
      const radius = circleTextureDetails[t].radius * dieInnerWidth;
      const sx = innerDieRect.startX + circleTextureDetails[t].center.x * dieInnerWidth - radius;
      const sy = innerDieRect.startY + circleTextureDetails[t].center.y * dieInnerHeight - radius;
      const ex = sx + 2 * radius;
      const ey = sy + 2 * radius;
      const recVec = GLUtility.prepareRectVecForTexture(sx, sy, ex, ey);
      coords.push(...recVec);

      const color = this.hashColorToRGB(circleTextureDetails[t].color);
      coordsColor.push(
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
      );
    }
    return { coords, coordsColor };
  };

  getTextureTriangleGLAndColorCoords = (triangleTextureDetails: DieTriangleTextureDetails[], innerDieRect: RectCoord) => {
    const { scaledDieWidth, scaledDieHeight } = this.waferMapVariables;
    const { sw, sh } = this.getStreetDimensions(false);
    const coords: number[] = [];
    const coordsColor: number[] = [];
    const dieInnerWidth = scaledDieWidth - sw;
    const dieInnerHeight = scaledDieHeight - sh;
    for (let t = 0; t < triangleTextureDetails.length; t += 1) {
      coords.push(
        innerDieRect.startX + triangleTextureDetails[t].p1.x * dieInnerWidth,
        innerDieRect.startY + triangleTextureDetails[t].p1.y * dieInnerHeight,
        0,
        innerDieRect.startX + triangleTextureDetails[t].p2.x * dieInnerWidth,
        innerDieRect.startY + triangleTextureDetails[t].p2.y * dieInnerHeight,
        0,
        innerDieRect.startX + triangleTextureDetails[t].p3.x * dieInnerWidth,
        innerDieRect.startY + triangleTextureDetails[t].p3.y * dieInnerHeight,
        0,
      );

      const color = this.hashColorToRGB(triangleTextureDetails[t].color);
      coordsColor.push(
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
        color[0],
        color[1],
        color[2],
        1,
      );
    }
    return { coords, coordsColor };
  };

  getTextureRectangleGLAndColorCoords = (rectangleTextureDetails: DieRectangleTextureDetails[], innerDieRect: RectCoord) => {
    const { scaledDieWidth, scaledDieHeight } = this.waferMapVariables;
    const { sw, sh } = this.getStreetDimensions(false);
    const coords: number[] = [];
    const coordsColor: number[] = [];
    const dieInnerWidth = scaledDieWidth - sw;
    const dieInnerHeight = scaledDieHeight - sh;
    for (let t = 0; t < rectangleTextureDetails.length; t += 1) {
      const recVec = GLUtility.prepareRectVec(
        innerDieRect.startX + rectangleTextureDetails[t].p1.x * dieInnerWidth,
        innerDieRect.startY + rectangleTextureDetails[t].p1.y * dieInnerHeight,
        innerDieRect.startX + rectangleTextureDetails[t].p2.x * dieInnerWidth,
        innerDieRect.startY + rectangleTextureDetails[t].p2.y * dieInnerHeight,
      );
      coords.push(...recVec);

      const { color } = rectangleTextureDetails[t];
      coordsColor.push(
        color[0],
        color[1],
        color[2],
        color[3],
        color[0],
        color[1],
        color[2],
        color[3],
        color[0],
        color[1],
        color[2],
        color[3],
        color[0],
        color[1],
        color[2],
        color[3],
        color[0],
        color[1],
        color[2],
        color[3],
        color[0],
        color[1],
        color[2],
        color[3],
      );
    }
    return { coords, coordsColor };
  };

  getStreetDimensions = (initial: boolean) => {
    const {
      dieHeightToStreetHeightRatio, scaledDieHeight, scaledDieWidth, scaledDieHeightInitial, scaledDieWidthInitial, dieWidthToStreetWidthRatio,
    } = this.waferMapVariables;
    const dieWidth = initial ? scaledDieWidthInitial : scaledDieWidth;
    const dieHeight = initial ? scaledDieHeightInitial : scaledDieHeight;
    return {
      sw: dieWidthToStreetWidthRatio === -1 ? 0 : dieWidth / dieWidthToStreetWidthRatio,
      sh: dieHeightToStreetHeightRatio === -1 ? 0 : dieHeight / dieHeightToStreetHeightRatio,
    };
  };

  removeDieTypeFromDies = (dieTypeId: string) => {
    const {
      startRow, startCol, waferData, rowDirection, colDirection,
    } = this.waferMapVariables;
    for (let i = 0, p = startRow; i < waferData.dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < waferData.dies[i].length; j += 1, q += colDirection) {
        if (waferData.dies[p][q] && waferData.dies[p][q]!.dieType === dieTypeId) {
          waferData.dies[p][q]!.dieType = null;
        }
      }
    }
  };

  isAnyDieSelectedOnWafer = (dies: Dies, startRow: number, startCol: number, rowDirection: number, colDirection: number) => {
    // returns true if any uncropped and undeleted die is selected on the wafermap
    for (let i = 0, p = startRow; i < dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < dies[i].length; j += 1, q += colDirection) {
        if (dies[p][q] !== null && !dies[p][q]!.isCropped && !dies[p][q]!.isDeleted && dies[p][q]!.isSelected) {
          return true;
        }
      }
    }
    return false;
  };

  prepareDataForRendering = (
    dieWidth: number,
    dieHeight: number,
    xAnchorParam: number,
    yAnchorParam: number,
  ) => {
    const {
      startRow, startCol, rowDirection, colDirection, waferData, scaledDieHeightInitial, scaledDieWidthInitial,
      rotation, xAnchorInitial, xAnchor, yAnchorInitial, yAnchor, innerViewPort, scaledDieWidth, scaledDieHeight,
      gl, showDieText, showBgImage, shouldUseOnlyBinColor, dieTypes, dieTypeField, dieColorType, showRing, overlayReticle, reticleGridRectCoords, colFlip, rowFlip, rowOffset, colOffset,
      reticleReference, colAxisStart, rowAxisStart, colAxisIncrement, rowAxisIncrement, showReferenceReticle, referenceReticleGridRectCoords, zones, currentZoneId,
      wcmWaferDiameter, wcmWaferEdgeExclusion, pageNumber, plotterPageNumber, isDieSelectedOnAnyWafer,
    } = this.waferMapVariables;
    if (!gl) return;
    if (pageNumber !== undefined && plotterPageNumber !== undefined && pageNumber !== plotterPageNumber) return;

    const allDiesOuterGLCoords: number[] = [];
    const textureLinesGLCoords: number[] = [];
    const textureLineColors: number[] = [];
    const textureCirclesGLCoords: number[] = [];
    const textureCircleColors: number[] = [];
    const textureTrianglesGLCoords: number[] = [];
    const textureTriangleColors: number[] = [];
    const textureRectanglesGLCoords: number[] = [];
    const textureRectangleColors: number[] = [];
    const radarDiesGLCoords: number[] = [];
    const glCoordsBasedColors: number[] = [];
    let referenceReticleBorderGLCoords: number[] = [];
    const reticleReferenceGLCoords: number[] = [];
    let allReticleBorderGLCoords: number[] = [];
    let allReticleBgGLCoords: number[] = [];
    let underSelectionReticleBorderGLCoords: number[] = [];
    let selectedReticleBorderGLCoords: number[] = [];
    const innerViewportCanvas = { width: innerViewPort.width, height: innerViewPort.height };
    let wcmCroppingRingGLCoords: number[] = [];

    this.clearAll();

    if (showBgImage) {
      this.waferMapVariables.waferBgImageGLCoords = this.getWaferBgImageGLCoords(dieWidth, dieHeight, xAnchorParam, yAnchorParam);
      if (this.waferMapVariables.radarWaferBgImageGLCoords === null) {
        this.waferMapVariables.radarWaferBgImageGLCoords = this.getWaferBgImageGLCoords(scaledDieWidthInitial, scaledDieHeightInitial, xAnchorInitial, yAnchorInitial);
      }
      this.renderBgImages(); // rendered the first thing to ensure all other lines / coords / triangles are not hidden under images
    }
    if (showRing) wcmCroppingRingGLCoords = this.getWCMCroppingRingGLCoords(dieWidth, dieHeight, xAnchorParam, yAnchorParam);

    const { dieTextXCoords, dieTextYCoords } = this.renderTickLinesAndTextAndGetDieTextCoords();
    const { sw, sh } = this.getStreetDimensions(false);
    const { sw: swInitial, sh: shInitial } = this.getStreetDimensions(true);

    const currZone = this.getCurrZone();

    if (overlayReticle) {
      const allReticleCoordsData = this.getReticleCoordsDataAndRenderText(
        reticleGridRectCoords,
        xAnchorParam,
        yAnchorParam,
        dieWidth,
        dieHeight,
        rowOffset,
        colOffset,
        1,
        0,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'all',
      );
      if (referenceReticleGridRectCoords && showReferenceReticle) {
        const referenceReticleCoordsData = this.getReferenceReticleCoordsData(
          referenceReticleGridRectCoords,
          xAnchorParam,
          yAnchorParam,
          dieWidth,
          dieHeight,
          innerViewportCanvas,
          rotation,
          rowOffset,
          colOffset,
          1,
          0,
          reticleReference,
        );
        referenceReticleBorderGLCoords = referenceReticleCoordsData.reticleBorderCoords;
        reticleReferenceGLCoords.push(...referenceReticleCoordsData.reticleReferenceCoords);
        this.waferMapVariables.reticleReferencePoint = referenceReticleCoordsData.reticleReferencePoint;
      }
      const underSelectionReticleCoordsData = this.getReticleCoordsDataAndRenderText(
        reticleGridRectCoords,
        xAnchorParam,
        yAnchorParam,
        dieWidth,
        dieHeight,
        rowOffset,
        colOffset,
        1,
        0,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'under_selection',
      );
      const selectedReticleCoordsData = this.getReticleCoordsDataAndRenderText(
        reticleGridRectCoords,
        xAnchorParam,
        yAnchorParam,
        dieWidth,
        dieHeight,
        rowOffset,
        colOffset,
        1,
        0,
        colFlip,
        colAxisIncrement,
        colAxisStart,
        rowFlip,
        rowAxisIncrement,
        rowAxisStart,
        'selected',
      );
      allReticleBorderGLCoords = allReticleCoordsData.reticleBorderCoords;
      allReticleBgGLCoords = allReticleCoordsData.reticleBGCoords;
      underSelectionReticleBorderGLCoords = underSelectionReticleCoordsData.reticleBorderCoords;
      selectedReticleBorderGLCoords = selectedReticleCoordsData.reticleBorderCoords;
    }

    this.waferMapVariables.waferBgCenterPoint = this.getWaferBgCenterPoint(dieWidth, dieHeight, xAnchorParam, yAnchorParam);
    this.waferMapVariables.dieCount = 0;
    this.waferMapVariables.selectedDieCount = 0;
    let rowCount = 0;
    let isNonNullDieFound = false;
    const colInfo: any = {};
    const dieTypeCountInfo: { [key: string]: number } = {};
    for (let i = 0; dieTypes.dieType && i < dieTypes.dieType.length; i += 1) {
      dieTypeCountInfo[dieTypes.dieType[i].id] = 0;
    }
    dieTypeCountInfo.null = 0;
    const {
      widthOfBg, heightOfBg, centerOfBG,
    } = this.getWaferWidthHeightDiffInfo(xAnchorParam, yAnchorParam, dieWidth, dieHeight);

    const zoneColorMappingInfo: { zoneColorIndex: number, zoneColorMapping: { [key: string]: number } } = { zoneColorIndex: 0, zoneColorMapping: {} };

    // checks if any uncropped and undeleted die is selected on the wafermap
    // use this info to reduce the opacity of the unselected dies
    // Note: this traverses the entire dataset. Add any other logic related to data traversal here if needed in future
    const isAnyDieSelectedOnWafer = this.isAnyDieSelectedOnWafer(waferData.dies, startRow, startCol, rowDirection, colDirection);

    for (let i = 0, p = startRow; i < waferData.dies.length; i += 1, p += rowDirection) {
      isNonNullDieFound = false;
      for (let j = 0, q = startCol; j < waferData.dies[i].length; j += 1, q += colDirection) {
        const { innerDieRect } = this.getDieOuterAndInnerRect(i, j, dieWidth, dieHeight, xAnchorParam, yAnchorParam, sw, sh);

        const dieData = waferData.dies[p][q];
        if (dieData) {
          this.setDieLocation(dieWidth, dieHeight, xAnchorParam, yAnchorParam, innerDieRect, dieData);
          if (!dieData!.isCropped && !dieData!.isDeleted) {
            this.waferMapVariables.dieCount += 1;
            isNonNullDieFound = true;
            dieTypeCountInfo[dieData.dieType] += 1;
            colInfo[j] = true;
            if (showDieText) this.renderDieText(dieData!, dieTextXCoords, dieTextYCoords, i + rowOffset, j + colOffset);
            const dieInnerCoords = GLUtility.prepareRectVec(innerDieRect.startX, innerDieRect.startY, innerDieRect.endX, innerDieRect.endY);
            allDiesOuterGLCoords.push(...dieInnerCoords);

            if (this.waferMapVariables.radarDiesGLCoords === null) {
              const { innerDieRect: innerRadarDieRect } = this.getDieOuterAndInnerRect(i, j, scaledDieWidthInitial, scaledDieHeightInitial, xAnchorInitial, yAnchorInitial, swInitial, shInitial);
              const radarDieCoords = GLUtility.prepareRectVec(innerRadarDieRect.startX, innerRadarDieRect.startY, innerRadarDieRect.endX, innerRadarDieRect.endY);
              radarDiesGLCoords.push(...radarDieCoords);
            }

            const [r, g, b] = this.hashColorToRGB(this.getColor(shouldUseOnlyBinColor, dieData!, dieTypes, dieData!.binColor!, dieTypeField, dieColorType, currZone, zoneColorMappingInfo));
            if (dieData!.isUnderSelection) {
              glCoordsBasedColors.push(
                r,
                g,
                b,
                0.5,
                r,
                g,
                b,
                0.5,
                r,
                g,
                b,
                0.5,
                r,
                g,
                b,
                0.5,
                r,
                g,
                b,
                0.5,
                r,
                g,
                b,
                0.5,
              );
            } else if (dieData!.isSelected) {
              const borderColor = [0, 0, 0, 0.8];
              dieData.rectangleTextures = [
                // right border
                {
                  p1: { x: 1, y: 0 },
                  p2: { x: 0.85, y: 1 },
                  color: borderColor,
                },
                // bottom border
                {
                  p1: { x: 0, y: 1 },
                  p2: { x: 1, y: 0.85 },
                  color: borderColor,
                },
                // left border
                {
                  p1: { x: 0, y: 0 },
                  p2: { x: 0.15, y: 1 },
                  color: borderColor,
                },
                // top border
                {
                  p1: { x: 0, y: 0 },
                  p2: { x: 1, y: 0.15 },
                  color: borderColor,
                },
              ];
              this.waferMapVariables.selectedDieCount += 1;
              glCoordsBasedColors.push(
                r,
                g,
                b,
                1,
                r,
                g,
                b,
                1,
                r,
                g,
                b,
                1,
                r,
                g,
                b,
                1,
                r,
                g,
                b,
                1,
                r,
                g,
                b,
                1,
              );
            } else {
              dieData.rectangleTextures = [];
              const opacityOfUnselectedDies = isAnyDieSelectedOnWafer || isDieSelectedOnAnyWafer ? 0.8 : 1; // reduce opacity of unselected dies incase of a selection
              glCoordsBasedColors.push(
                r,
                g,
                b,
                opacityOfUnselectedDies,
                r,
                g,
                b,
                opacityOfUnselectedDies,
                r,
                g,
                b,
                opacityOfUnselectedDies,
                r,
                g,
                b,
                opacityOfUnselectedDies,
                r,
                g,
                b,
                opacityOfUnselectedDies,
                r,
                g,
                b,
                opacityOfUnselectedDies,
              );
            }
            if (dieData.lineTexture && dieData.lineTexture.clockwise) {
              const data = this.getTextureLineGLAndColorCoords(dieData.lineTexture.clockwise, innerDieRect, true);
              textureLinesGLCoords.push(...data.coords);
              textureLineColors.push(...data.coordsColor);
            }
            if (dieData.lineTexture && dieData.lineTexture.antiClockwise) {
              const data = this.getTextureLineGLAndColorCoords(dieData.lineTexture.antiClockwise, innerDieRect, false);
              textureLinesGLCoords.push(...data.coords);
              textureLineColors.push(...data.coordsColor);
            }
            if (dieData.circleTextures) {
              const data = this.getTextureCircleGLAndColorCoords(dieData.circleTextures, innerDieRect);
              textureCirclesGLCoords.push(...data.coords);
              textureCircleColors.push(...data.coordsColor);
            }
            if (dieData.triangleTextures) {
              const data = this.getTextureTriangleGLAndColorCoords(dieData.triangleTextures, innerDieRect);
              textureTrianglesGLCoords.push(...data.coords);
              textureTriangleColors.push(...data.coordsColor);
            }
            if (dieData.rectangleTextures) {
              const data = this.getTextureRectangleGLAndColorCoords(dieData.rectangleTextures, innerDieRect);
              textureRectanglesGLCoords.push(...data.coords);
              textureRectangleColors.push(...data.coordsColor);
            }
          }
        }
      }
      if (isNonNullDieFound) rowCount += 1;
    }

    if (this.waferMapVariables.radarDiesGLCoords === null) {
      this.waferMapVariables.radarDiesGLCoords = radarDiesGLCoords;
    }
    this.waferMapVariables.dieTypeCountInfo = dieTypeCountInfo;
    this.waferMapVariables.rowCount = rowCount;
    this.waferMapVariables.colCount = Object.keys(colInfo).length;
    this.waferMapVariables.allDiesOuterGLCoords = allDiesOuterGLCoords;
    this.waferMapVariables.textureLinesGLCoords = textureLinesGLCoords;
    this.waferMapVariables.textureLineColors = textureLineColors;
    this.waferMapVariables.textureCirclesGLCoords = textureCirclesGLCoords;
    this.waferMapVariables.textureCircleColors = textureCircleColors;
    this.waferMapVariables.textureTrianglesGLCoords = textureTrianglesGLCoords;
    this.waferMapVariables.textureTriangleColors = textureTriangleColors;
    this.waferMapVariables.textureRectanglesGLCoords = textureRectanglesGLCoords;
    this.waferMapVariables.textureRectangleColors = textureRectangleColors;
    this.waferMapVariables.glCoordsBasedColors = glCoordsBasedColors;
    this.waferMapVariables.wcmCroppingRingGLCoords = wcmCroppingRingGLCoords;
    this.waferMapVariables.referenceReticleBorderGLCoords = referenceReticleBorderGLCoords;
    this.waferMapVariables.reticleReferenceGLCoords = reticleReferenceGLCoords;
    this.waferMapVariables.allReticleBorderGLCoords = allReticleBorderGLCoords;
    this.waferMapVariables.allReticleBgGLCoords = allReticleBgGLCoords;
    this.waferMapVariables.underSelectionReticleBorderGLCoords = underSelectionReticleBorderGLCoords;
    this.waferMapVariables.selectedReticleBorderGLCoords = selectedReticleBorderGLCoords;
    this.waferMapVariables.radialZoneRingGLCoords = this.getRadialZoneCoords(zones, currentZoneId, wcmWaferDiameter, wcmWaferEdgeExclusion, Math.max(widthOfBg, heightOfBg) / 2, centerOfBG.x, centerOfBG.y, innerViewportCanvas, rotation);
    this.waferMapVariables.verticalZoneGLCoords = this.getVerticalZoneCoords(zones, currentZoneId, colOffset, scaledDieWidth, xAnchorParam);
    this.waferMapVariables.horizontalZoneGLCoords = this.getHorizontalZoneCoords(zones, currentZoneId, rowOffset, scaledDieHeight, yAnchorParam);
    this.waferMapVariables.groupedZoneGLCoords = this.getGroupedZoneCoords(zones, currentZoneId, rowOffset, colOffset, dieWidth, dieHeight, xAnchorParam, yAnchorParam);
    this.waferMapVariables.waferBgCenterGLCoords = [
      this.waferMapVariables.waferBgCenterPoint.x + 5, this.waferMapVariables.waferBgCenterPoint.y + 5, 0,
      this.waferMapVariables.waferBgCenterPoint.x - 5, this.waferMapVariables.waferBgCenterPoint.y - 5, 0,
      this.waferMapVariables.waferBgCenterPoint.x + 5, this.waferMapVariables.waferBgCenterPoint.y - 5, 0,
      this.waferMapVariables.waferBgCenterPoint.x - 5, this.waferMapVariables.waferBgCenterPoint.y + 5, 0,
    ];
    this.waferMapVariables.radarPointerGLCoords = this.getRadarPointerGLCoords(
      xAnchorInitial,
      yAnchorInitial,
      xAnchor,
      yAnchor,
      scaledDieWidthInitial,
      scaledDieHeightInitial,
      scaledDieWidth,
      scaledDieHeight,
      innerViewPort,
    );
    if (this.waferMapVariables.reticleReferencePoint) {
      this.waferMapVariables.reticleOffsetGLCoords = this.getReticleOffsetLineCoords(this.waferMapVariables.waferBgCenterPoint, this.waferMapVariables.reticleReferencePoint);
    }
  };

  getBorderCoordsUsingRectCoords = (rect: RectCoord) => {
    // arranges the rect coords to return coords for 4 sides borders
    return [
      rect.startX, rect.startY, 0, rect.endX, rect.startY, 0, // top side
      rect.endX, rect.startY, 0, rect.endX, rect.endY, 0, // right side
      rect.endX, rect.endY, 0, rect.startX, rect.endY, 0, // bottom side
      rect.startX, rect.endY, 0, rect.startX, rect.startY, 0, // left side
    ];
  };

  onFlipWafer = (data: { axis: FlipAxis, isXAxisFlipped: boolean, isYAxisFlipped: boolean }) => {
    const { changeVariablesForWaferFlip } = this.waferMapVariables;
    changeVariablesForWaferFlip(data.axis, data.isXAxisFlipped, data.isYAxisFlipped, false);
    this.renderWafer();
  };

  getRadarPointerGLCoords = (
    xAnchorInitial: number,
    yAnchorInitial: number,
    xAnchor: number,
    yAnchor: number,
    scaledDieWidthInitial: number,
    scaledDieHeightInitial: number,
    scaledDieWidth: number,
    scaledDieHeight: number,
    innerViewPort: Viewport,
  ) => {
    const widthRatio = scaledDieWidthInitial / scaledDieWidth;
    const heightRatio = scaledDieHeightInitial / scaledDieHeight;

    const startX = xAnchorInitial - xAnchor * widthRatio;
    const startY = yAnchorInitial - yAnchor * heightRatio;
    const endX = startX + innerViewPort.width * widthRatio;
    const endY = startY + innerViewPort.height * heightRatio;

    return this.getBorderCoordsUsingRectCoords({
      startX, startY, endX, endY,
    });
  };

  hashColorToRGB = (hash: string): number[] => {
    if (hash === null) hash = '#000000';
    return [parseInt(hash.substring(1, 3), 16) / 255.0,
      parseInt(hash.substring(3, 5), 16) / 255.0,
      parseInt(hash.substring(5, 7), 16) / 255.0];
  };

  setPreDragAnchors = () => {
    const { xAnchor, yAnchor } = this.waferMapVariables;
    this.waferMapVariables.xAnchorPreDrag = xAnchor;
    this.waferMapVariables.yAnchorPreDrag = yAnchor;
  };

  getRotatedCoordsForCanvasInteractions = (rect: RectCoord) => {
    // startX, endX => [0 - innerViewport.width]+
    // startY, endY => [0 - innerViewport.height]+
    const { angleInDegrees, innerViewPort } = this.waferMapVariables;

    const rotation = [Math.sin((angleInDegrees) * (Math.PI / 180)), Math.cos((angleInDegrees) * (Math.PI / 180))];

    const startX = rect.startX - innerViewPort.width / 2;
    const startY = rect.startY - innerViewPort.height / 2;
    const endX = rect.endX - innerViewPort.width / 2;
    const endY = rect.endY - innerViewPort.height / 2;

    const rstartX = startX * rotation[1] + startY * rotation[0];
    const rstartY = startY * rotation[1] - startX * rotation[0];
    const rendX = endX * rotation[1] + endY * rotation[0];
    const rendY = endY * rotation[1] - endX * rotation[0];

    return {
      startX: rstartX + innerViewPort.width / 2,
      startY: rstartY + innerViewPort.height / 2,
      endX: rendX + innerViewPort.width / 2,
      endY: rendY + innerViewPort.height / 2,
    };
  };

  getBoxArrayIndicesFromCanvasCoords = (rect: RectCoord): RectCoord => {
    // From
    // startX, endX => [0 - innerViewport.width]+
    // startY, endY => [0 - innerViewport.height]+

    // To
    // startX, startY, endX, endY => indices of 2d array
    const {
      xAnchor, yAnchor, scaledDieWidth, scaledDieHeight,
    } = this.waferMapVariables;

    const rotatedCoords = this.getRotatedCoordsForCanvasInteractions(rect);

    if (rotatedCoords.startX > rotatedCoords.endX) {
      [rotatedCoords.startX, rotatedCoords.endX] = [rotatedCoords.endX, rotatedCoords.startX];
    }
    if (rotatedCoords.startY > rotatedCoords.endY) {
      [rotatedCoords.startY, rotatedCoords.endY] = [rotatedCoords.endY, rotatedCoords.startY];
    }
    return {
      startX: Math.floor((rotatedCoords.startX - xAnchor) / scaledDieWidth),
      startY: Math.floor((rotatedCoords.startY - yAnchor) / scaledDieHeight),
      endX: Math.floor((rotatedCoords.endX - xAnchor) / scaledDieWidth),
      endY: Math.floor((rotatedCoords.endY - yAnchor) / scaledDieHeight),
    };
  };

  dragWaferMap = (data: { startX: number, startY: number, endX: number, endY: number, isFromPubSub?: boolean }) => {
    const { xAnchorPreDrag, yAnchorPreDrag, innerViewPort } = this.waferMapVariables;
    const rect: RectCoord = {
      startX: data.startX, startY: data.startY, endX: data.endX, endY: data.endY,
    };
    if (data.isFromPubSub) {
      rect.startX *= innerViewPort.width;
      rect.startY *= innerViewPort.height;
      rect.endX *= innerViewPort.width;
      rect.endY *= innerViewPort.height;
    }

    const rotatedCoords = this.getRotatedCoordsForCanvasInteractions({
      startX: rect.startX, startY: rect.startY, endX: rect.endX, endY: rect.endY,
    });

    this.waferMapVariables.xAnchor = xAnchorPreDrag + (rotatedCoords.endX - rotatedCoords.startX);
    this.waferMapVariables.yAnchor = yAnchorPreDrag + (rotatedCoords.endY - rotatedCoords.startY);
    this.renderWafer();
  };

  dragWaferMapFromRadar = (data: { x: number, y: number, isFromPubSub?: boolean }) => {
    const {
      xAnchorInitial, yAnchorInitial, innerViewPort, radarViewPort, scaledDieWidthInitial,
      scaledDieHeight, scaledDieHeightInitial, scaledDieWidth, isRadarEventAnchorCenter,
    } = this.waferMapVariables;

    const xy: XYPoint = { x: data.x, y: data.y };
    if (data.isFromPubSub) {
      xy.x *= innerViewPort.width;
      xy.y *= innerViewPort.height;
    }

    const widthRatio = scaledDieWidthInitial / scaledDieWidth;
    const heightRatio = scaledDieHeightInitial / scaledDieHeight;

    const radarPointerStartX = xAnchorInitial - this.waferMapVariables.xAnchor * widthRatio;
    const radarPointerStartY = yAnchorInitial - this.waferMapVariables.yAnchor * heightRatio;
    const radarPointerEndX = radarPointerStartX + innerViewPort.width * widthRatio;
    const radarPointerEndY = radarPointerStartY + innerViewPort.height * heightRatio;

    const startX = (xy.x) * (innerViewPort.width / radarViewPort.width);
    const startY = (xy.y) * (innerViewPort.height / radarViewPort.height);
    const rotatedCoords = this.getRotatedCoordsForCanvasInteractions({
      startX, startY, endX: startX, endY: startY,
    });
    this.waferMapVariables.xAnchor = (xAnchorInitial - rotatedCoords.startX) / widthRatio;
    this.waferMapVariables.yAnchor = (yAnchorInitial - rotatedCoords.startY) / heightRatio;

    if (isRadarEventAnchorCenter) {
      this.waferMapVariables.xAnchor += (Math.abs(radarPointerStartX - radarPointerEndX) / 2) / widthRatio;
      this.waferMapVariables.yAnchor += (Math.abs(radarPointerStartY - radarPointerEndY) / 2) / heightRatio;
    }

    this.renderWafer();
  };

  clearSelection = async () => {
    const {
      waferData, keyIndex, onSelectionChanged,
    } = this.waferMapVariables;
    const unSelectedData: DieData[] = [];
    for (let i = 0; i < waferData.dies.length; i += 1) {
      for (let j = 0; waferData.dies[i] && j < waferData.dies[i].length; j += 1) {
        if (waferData.dies[i][j] !== null && waferData.dies[i][j]!.isSelected) {
          waferData.dies[i][j]!.isSelected = false;
        }
      }
    }
    PublishSubscribe().publishWithOthersID(EventTypes.SELECTION_ON_WAFER, {
      selectedData: [],
      unSelectedData,
    }, keyIndex);
    if (onSelectionChanged) onSelectionChanged(keyIndex, [], unSelectedData);
    this.renderWafer();
  };

  onToggleRadar = (data: { checked: boolean }) => {
    this.waferMapVariables.showRadar = data.checked;
    this.clearAll();
    this.waferMapVariables.onSetOuterViewport(
      data.checked ? this.waferMapVariables.outerViewPortWithRadar!
        : this.waferMapVariables.outerViewPortWithoutRadar!,
    );
    this.renderWafer();
  };

  onDataSelectedOnWafer = (data: { selectedData: DieData[], unSelectedData: DieData[] }) => {
    // from pubsub only
    const { waferData } = this.waferMapVariables;
    for (let k = 0; k < data.selectedData.length; k += 1) {
      let isDieSelectionChanged = false;
      for (let i = 0; !isDieSelectionChanged && i < waferData.dies.length; i += 1) {
        for (let j = 0; !isDieSelectionChanged && waferData.dies[i] && j < waferData.dies[i].length; j += 1) {
          if (waferData.dies[i][j] && !waferData.dies[i][j]!.isSelected && data.selectedData[k].x === waferData.dies[i][j]!.x && data.selectedData[k].y === waferData.dies[i][j]!.y) {
            waferData.dies[i][j]!.isSelected = true;
            isDieSelectionChanged = true;
          }
        }
      }
    }

    for (let l = 0; l < data.unSelectedData.length; l += 1) {
      let isDieSelectionChanged = false;
      for (let i = 0; !isDieSelectionChanged && i < waferData.dies.length; i += 1) {
        for (let j = 0; !isDieSelectionChanged && waferData.dies[i] && j < waferData.dies[i].length; j += 1) {
          if (waferData.dies[i][j] && waferData.dies[i][j]!.isSelected && data.unSelectedData[l].x === waferData.dies[i][j]!.x && data.unSelectedData[l].y === waferData.dies[i][j]!.y) {
            waferData.dies[i][j]!.isSelected = false;
            isDieSelectionChanged = true;
          }
        }
      }
    }

    this.renderWafer();
  };

  onDiesMarkedOnWafer = (data: { markedDieData: DieData[], fieldName: string, currentDieType: string | null }) => {
    const { waferData } = this.waferMapVariables;
    for (let k = 0; k < data.markedDieData.length; k += 1) {
      let isDieMarked = false;
      for (let i = 0; !isDieMarked && i < waferData.dies.length; i += 1) {
        for (let j = 0; !isDieMarked && waferData.dies[i] && j < waferData.dies[i].length; j += 1) {
          if (waferData.dies[i][j] && data.markedDieData[k].x === waferData.dies[i][j]!.x && data.markedDieData[k].y === waferData.dies[i][j]!.y) {
            waferData.dies[i][j]![data.fieldName] = data.currentDieType;
            isDieMarked = true;
          }
        }
      }
    }
    this.renderWafer();
  };

  zoomWaferMapUsingBoxSelection = (data: { startX: number, startY: number, endX: number, endY: number, isFromPubSub?: boolean }) => {
    const { innerViewPort } = this.waferMapVariables;
    const rect: RectCoord = {
      startX: data.startX, startY: data.startY, endX: data.endX, endY: data.endY,
    };
    if (data.isFromPubSub) {
      rect.startX *= innerViewPort.width;
      rect.startY *= innerViewPort.height;
      rect.endX *= innerViewPort.width;
      rect.endY *= innerViewPort.height;
    }

    const rotatedCoords = this.getRotatedCoordsForCanvasInteractions({
      startX: rect.startX, startY: rect.startY, endX: rect.endX, endY: rect.endY,
    });

    const reigonWidth = Math.abs(rotatedCoords.endX - rotatedCoords.startX);
    const reigonHeight = Math.abs(rotatedCoords.endY - rotatedCoords.startY);

    const zoomFactor = reigonWidth > reigonHeight ? innerViewPort.width / reigonWidth : innerViewPort.height / reigonHeight;
    this.waferMapVariables.scaledDieWidth *= zoomFactor;
    this.waferMapVariables.scaledDieHeight *= zoomFactor;

    let centerRegionX = (rotatedCoords.startX + rotatedCoords.endX) / 2;
    let centerRegionY = (rotatedCoords.startY + rotatedCoords.endY) / 2;

    centerRegionX -= this.waferMapVariables.xAnchor;
    centerRegionY -= this.waferMapVariables.yAnchor;

    centerRegionX *= zoomFactor;
    centerRegionY *= zoomFactor;

    centerRegionX += this.waferMapVariables.xAnchor;
    centerRegionY += this.waferMapVariables.yAnchor;

    this.waferMapVariables.xAnchor -= (centerRegionX - innerViewPort.width / 2);
    this.waferMapVariables.yAnchor -= (centerRegionY - innerViewPort.height / 2);

    this.renderWafer();
  };

  zoomWaferMapUsingButtons = (data: { zoomFactor: number }) => {
    const { waferData } = this.waferMapVariables;

    const previousWaferWidth = waferData.waferMaxCols * this.waferMapVariables.scaledDieWidth;
    const previousWaferHeight = waferData.waferMaxRows * this.waferMapVariables.scaledDieHeight;

    this.waferMapVariables.scaledDieWidth *= data.zoomFactor;
    this.waferMapVariables.scaledDieHeight *= data.zoomFactor;

    const newWaferWidth = waferData.waferMaxCols * this.waferMapVariables.scaledDieWidth;
    const newWaferHeight = waferData.waferMaxRows * this.waferMapVariables.scaledDieHeight;

    const changeInWaferWidth = newWaferWidth - previousWaferWidth;
    const changeInWaferHeight = newWaferHeight - previousWaferHeight;

    this.waferMapVariables.xAnchor -= changeInWaferWidth / 2;
    this.waferMapVariables.yAnchor -= changeInWaferHeight / 2;
    this.renderWafer();
  };

  rotateWaferMap = (directionObj: { [key: string]: RotateDirection }) => {
    const { setRotationParams, angleInDegrees } = this.waferMapVariables;
    let newAngle;
    if (directionObj.direction === RotateDirection.AntiClockWise) {
      newAngle = angleInDegrees - 90;
      if (angleInDegrees === 0) {
        newAngle = 270;
      }
    } else {
      newAngle = (angleInDegrees + 90) % 360;
    }
    setRotationParams(newAngle);
    this.waferMapVariables.radarDiesGLCoords = null;
    this.waferMapVariables.radarWaferBgImageGLCoords = null;
    this.renderWafer();
  };

  resetWaferZoomPositionRotation = () => {
    const {
      xAnchorInitial, yAnchorInitial, scaledDieHeightInitial, scaledDieWidthInitial, setRotationParams,
    } = this.waferMapVariables;
    this.waferMapVariables.xAnchor = xAnchorInitial;
    this.waferMapVariables.yAnchor = yAnchorInitial;
    this.waferMapVariables.scaledDieWidth = scaledDieWidthInitial;
    this.waferMapVariables.scaledDieHeight = scaledDieHeightInitial;
    setRotationParams(0);
    this.waferMapVariables.radarDiesGLCoords = null;
    this.waferMapVariables.radarWaferBgImageGLCoords = null;
    this.renderWafer();
  };

  getCurrentWaferBgImage = (notchPosition: NotchPosition, wcmWaferDiameter: number) => {
    if (wcmWaferDiameter > 0 && UtilityFunctions.isWaferFlat(wcmWaferDiameter)) {
      if (notchPosition === NotchPosition.DOWN) {
        return this.waferMapVariables.waferBgImageBottomFlat;
      } else if (notchPosition === NotchPosition.LEFT) {
        return this.waferMapVariables.waferBgImageLeftFlat;
      } else if (notchPosition === NotchPosition.RIGHT) {
        return this.waferMapVariables.waferBgImageRightFlat;
      } else {
        return this.waferMapVariables.waferBgImageTopFlat;
      }
    } else {
      if (notchPosition === NotchPosition.DOWN) {
        return this.waferMapVariables.waferBgImageBottom;
      } else if (notchPosition === NotchPosition.LEFT) {
        return this.waferMapVariables.waferBgImageLeft;
      } else if (notchPosition === NotchPosition.RIGHT) {
        return this.waferMapVariables.waferBgImageRight;
      } else {
        return this.waferMapVariables.waferBgImageTop;
      }
    }
  };

  initiateRendering = () => {
    const {
      gl, programNormal, programColorBased, innerViewPort, allDiesOuterGLCoords, glCoordsBasedColors, showRadar, textureLinesGLCoords, textureLineColors,
      outerViewPort, textCtx, showRing, overlayReticle, programCircle, textureCircleColors, textureCirclesGLCoords, textureTriangleColors, textureTrianglesGLCoords,
      radarViewPort, radarDiesGLCoords, radarPointerGLCoords, scaledDieWidth, wcmCroppingRingGLCoords, textureRectangleColors, textureRectanglesGLCoords,
      referenceReticleBorderGLCoords, referenceReticleBorderColor, reticleReferenceGLCoords, reticleReferenceColor,
      allReticleBorderGLCoords, allReticleBorderColor, allReticleBgColor, allReticleBgGLCoords, underSelectionReticleBorderColor,
      underSelectionReticleBorderGLCoords, selectedReticleBorderColor, selectedReticleBorderGLCoords, showReticleText,
      showReferenceReticle, reticleOffsetGLCoords, reticleOffsetLineColor, markWaferCenter, waferBgCenterGLCoords,
      waferBgCenterColor, radialZoneRingGLCoords, zoneBoundaryColor, verticalZoneGLCoords, horizontalZoneGLCoords,
      groupedZoneGLCoords, rotation, pageNumber, plotterPageNumber,
    } = this.waferMapVariables;
    if (!gl || !programNormal || !programColorBased || !programCircle || !textCtx) return;
    if (pageNumber !== undefined && plotterPageNumber !== undefined && pageNumber !== plotterPageNumber) return;

    GLUtility.renderShape(
      {
        coords: allDiesOuterGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programColorBased,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        coordsBasedColors: glCoordsBasedColors,
      },
    );
    GLUtility.renderShape(
      {
        coords: textureLinesGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programColorBased,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        coordsBasedColors: textureLineColors,
      },
    );
    GLUtility.renderShape(
      {
        coords: textureTrianglesGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programColorBased,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        coordsBasedColors: textureTriangleColors,
      },
    );
    GLUtility.renderShape(
      {
        coords: textureRectanglesGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programColorBased,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        coordsBasedColors: textureRectangleColors,
      },
    );
    GLUtility.renderCircle(
      {
        coords: textureCirclesGLCoords,
        gl,
        program: programCircle,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        coordsBasedColors: textureCircleColors,
      },
    );
    if (radarDiesGLCoords && showRadar) {
      GLUtility.renderShape(
        {
          coords: radarDiesGLCoords,
          gl,
          shapeType: gl.TRIANGLES,
          program: programColorBased,
          rotation,
          resolution: innerViewPort,
          viewport: radarViewPort,
          coordsBasedColors: glCoordsBasedColors,
        },
      );
    }
    if (showRadar) {
      GLUtility.renderShape({
        coords: radarPointerGLCoords,
        shapeType: gl.LINES,
        gl,
        program: programNormal,
        rotation,
        resolution: innerViewPort,
        viewport: radarViewPort,
        color: [0.0, 0.0, 0.0, 1.0],
      });
    }
    GLUtility.renderShape(
      {
        coords: this.getBorderCoordsUsingRectCoords({
          startX: 0, startY: 0, endX: innerViewPort.width, endY: innerViewPort.height,
        }),
        gl,
        shapeType: gl.LINES,
        rotation,
        resolution: innerViewPort,
        program: programNormal,
        viewport: innerViewPort,
        color: [0.0, 0.0, 0.0, 1.0],
      },
    );

    GLUtility.renderShape(
      {
        coords: this.getBorderCoordsUsingRectCoords({
          startX: 0, startY: 0, endX: outerViewPort.width, endY: outerViewPort.height,
        }),
        gl,
        shapeType: gl.LINES,
        rotation,
        resolution: outerViewPort,
        program: programNormal,
        viewport: outerViewPort,
        color: [0.0, 0.0, 0.0, 1.0],
      },
    );
    if (showRadar) {
      GLUtility.renderShape(
        {
          coords: this.getBorderCoordsUsingRectCoords({
            startX: 0, startY: 0, endX: radarViewPort.width, endY: radarViewPort.height,
          }),
          gl,
          shapeType: gl.LINES,
          rotation,
          program: programNormal,
          resolution: radarViewPort,
          viewport: radarViewPort,
          color: [0.0, 0.0, 0.0, 1.0],
        },
      );
    }
    if (showRing) {
      GLUtility.renderShape(
        {
          coords: wcmCroppingRingGLCoords,
          gl,
          shapeType: gl.LINES,
          rotation,
          program: programNormal,
          resolution: innerViewPort,
          viewport: innerViewPort,
          color: [1.0, 0.0, 0.0, 1.0],
        },
      );
    }
    if (overlayReticle) {
      if (showReticleText) {
        GLUtility.renderShape(
          {
            coords: allReticleBgGLCoords,
            gl,
            shapeType: gl.TRIANGLES,
            program: programNormal,
            rotation,
            viewport: innerViewPort,
            resolution: innerViewPort,
            color: allReticleBgColor,
          },
        );
      }
      GLUtility.renderShape(
        {
          coords: allReticleBorderGLCoords,
          gl,
          shapeType: gl.TRIANGLES,
          program: programNormal,
          viewport: innerViewPort,
          rotation,
          resolution: innerViewPort,
          color: allReticleBorderColor,
        },
      );
      if (showReferenceReticle) {
        GLUtility.renderShape(
          {
            coords: referenceReticleBorderGLCoords,
            gl,
            shapeType: gl.TRIANGLES,
            program: programNormal,
            rotation,
            viewport: innerViewPort,
            resolution: innerViewPort,
            color: referenceReticleBorderColor,
          },
        );
      }
      GLUtility.renderShape(
        {
          coords: selectedReticleBorderGLCoords,
          gl,
          shapeType: gl.TRIANGLES,
          program: programNormal,
          rotation,
          viewport: innerViewPort,
          resolution: innerViewPort,
          color: selectedReticleBorderColor,
        },
      );
      GLUtility.renderShape(
        {
          coords: underSelectionReticleBorderGLCoords,
          gl,
          shapeType: gl.TRIANGLES,
          program: programNormal,
          rotation,
          viewport: innerViewPort,
          resolution: innerViewPort,
          color: underSelectionReticleBorderColor,
        },
      );
      if (showReferenceReticle) {
        GLUtility.renderShape(
          {
            coords: reticleReferenceGLCoords,
            gl,
            shapeType: gl.LINES,
            program: programNormal,
            viewport: innerViewPort,
            rotation,
            resolution: innerViewPort,
            color: reticleReferenceColor,
          },
        );
        GLUtility.renderShape(
          {
            coords: reticleOffsetGLCoords,
            gl,
            shapeType: gl.LINES,
            program: programNormal,
            viewport: innerViewPort,
            rotation,
            resolution: innerViewPort,
            color: reticleOffsetLineColor,
          },
        );
      }
    }
    if (markWaferCenter) {
      GLUtility.renderShape(
        {
          coords: waferBgCenterGLCoords,
          gl,
          shapeType: gl.LINES,
          program: programNormal,
          viewport: innerViewPort,
          rotation,
          resolution: innerViewPort,
          color: waferBgCenterColor,
        },
      );
    }
    if (scaledDieWidth > 5) {
      this.renderGridLines();
    }
    GLUtility.renderShape(
      {
        coords: radialZoneRingGLCoords,
        gl,
        shapeType: gl.POINTS,
        program: programNormal,
        rotation,
        viewport: innerViewPort,
        resolution: innerViewPort,
        color: zoneBoundaryColor,
      },
    );
    GLUtility.renderShape(
      {
        coords: verticalZoneGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programNormal,
        viewport: innerViewPort,
        rotation,
        resolution: innerViewPort,
        color: zoneBoundaryColor,
      },
    );
    GLUtility.renderShape(
      {
        coords: horizontalZoneGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programNormal,
        resolution: innerViewPort,
        rotation,
        viewport: innerViewPort,
        color: zoneBoundaryColor,
      },
    );
    GLUtility.renderShape(
      {
        coords: groupedZoneGLCoords,
        gl,
        shapeType: gl.TRIANGLES,
        program: programNormal,
        viewport: innerViewPort,
        rotation,
        resolution: innerViewPort,
        color: zoneBoundaryColor,
      },
    );
    // this.renderPoint(this.waferMapVariables.xAnchor, this.waferMapVariables.yAnchor, innerViewPort, innerViewPort);
  };

  getTooltipContent = (die: DieData) => {
    let content = '';
    if (die !== null) {
      const { tooltipFields } = this.waferMapVariables;
      for (let i = 0; i < tooltipFields.length; i += 1) {
        let value = this.getTextFromDieField(die, tooltipFields[i][0]);
        if (Array.isArray(value)) {
          let str = '';
          value = value.forEach((x, index) => {
            if (index !== 0) str += ', ';
            str += x === null ? 'Null' : x;
          });
          value = str;
        }
        content += `<div>
        <strong>
          ${tooltipFields[i][1]}
          :
        </strong>
        ${' '}
        ${value}
      </div>`;
      }
    }
    return content;
  };

  renderPoint = (x: number, y: number, viewport: Viewport, printViewport: Viewport) => {
    const { rotation, gl, program } = this.waferMapVariables;
    if (!gl || !program) return;
    GLUtility.renderShape(
      {
        coords: [x, y],
        gl,
        shapeType: gl.POINTS,
        program,
        resolution: viewport,
        viewport: printViewport,
        rotation,
        color: [1.0, 0.0, 0.0, 1.0],
      },
    );
  };

  getDieOuterAndInnerRect = (i: number, j: number, dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number, streetWidth: number, streetHeight: number): { outerDieRect: RectCoord, innerDieRect: RectCoord } => {
    const outerStartX = j * dieWidth + xAnchor;
    const outerStartY = i * dieHeight + yAnchor;
    const outerEndX = outerStartX + dieWidth;
    const outerEndY = outerStartY + dieHeight;

    const innerStartX = outerStartX + streetWidth / 2;
    const innerStartY = outerStartY + streetHeight / 2;
    const innerEndX = outerEndX - streetWidth / 2;
    const innerEndY = outerEndY - streetHeight / 2;

    return {
      outerDieRect: {
        startX: outerStartX, startY: outerStartY, endX: outerEndX, endY: outerEndY,
      },
      innerDieRect: {
        startX: innerStartX, startY: innerStartY, endX: innerEndX, endY: innerEndY,
      },
    };
  };

  isReticleCollidingWithKeepOut = (
    data: {
      dieWidth: number,
      dieHeight: number,
      reticleSize: XYPoint,
      dieData: DieData,
      notchPosition: NotchPosition,
      yAnchor: number,
      xAnchor: number,
      center: number,
      multiplier: number,
      distanceFromCenterToKeepOutLine: number,
    },
  ) => {
    let reticleBorder;
    const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(data.dieData, data.reticleSize);
    switch (data.notchPosition) {
      case NotchPosition.DOWN:
        reticleBorder = reticleRectCoordOfCurrDie.endY * data.dieHeight + data.yAnchor + data.dieHeight;
        break;
      case NotchPosition.UP:
        reticleBorder = reticleRectCoordOfCurrDie.startY * data.dieHeight + data.yAnchor;
        break;
      case NotchPosition.RIGHT:
        reticleBorder = reticleRectCoordOfCurrDie.endX * data.dieWidth + data.xAnchor + data.dieWidth;
        break;
      case NotchPosition.LEFT:
        reticleBorder = reticleRectCoordOfCurrDie.startX * data.dieWidth + data.xAnchor;
        break;
      default:
        reticleBorder = 0;
        break;
    }
    return data.multiplier * (data.center - reticleBorder) > data.distanceFromCenterToKeepOutLine;
  };

  isDieOutsideFlatOrNotch =
  (multiplier: number, center: number, topLeft: XYPoint, topRight: XYPoint, bottomLeft: XYPoint, bottomRight: XYPoint, axis: 'x' | 'y', distanceFromCenterToKeepOutLine: number) => {
    return (
      multiplier * (center - topLeft[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - topRight[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - bottomLeft[axis]) > distanceFromCenterToKeepOutLine
      || multiplier * (center - bottomRight[axis]) > distanceFromCenterToKeepOutLine
    );
  };

  getNumberOfRadialZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      scaledDieHeight, scaledDieWidth, reticleGridRectCoords, waferData,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, colOffset, rowOffset,
      panOffsetXToDieStepSizeXRatio, panOffsetYToDieStepSizeYRatio, waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion,
    } = this.waferMapVariables;

    const pcmSitesInRadialZone: number[][] = this.getPcmSitesInRadialZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      panOffsetXToDieStepSizeXRatio,
      panOffsetYToDieStepSizeYRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markRadialInfo: false,
    });

    let numberOfRadialZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInRadialZone[i].length === 0) {
        numberOfRadialZonesWithNoPCMSites += 1;
      }
    }

    return numberOfRadialZonesWithNoPCMSites;
  };

  performCropDueToKeepOut = (
    data: {
      i: number, j: number, dieWidth: number, dieHeight: number, xAnchor: number, yAnchor: number,
      wcmExclusionType: ExclusionType, dieWidthToStreetWidthRatio: number, dieHeightToStreetHeightRatio: number,
      waferCenterX: number, waferCenterY: number, wcmWaferDiameter: number, wcmWaferNotchKeepOut: number,
      wcmWaferFlatKeepOut: number, wcmWaferBaseFlat: number, wcmWaferScribeLine: number, widthOfBg: number,
      heightOfBg: number, notchPosition: NotchPosition, dieData: DieData, reticleSize: XYPoint, streetWidth: number, streetHeight: number,
    },
  ): void => {
    const { innerDieRect } = this.getDieOuterAndInnerRect(data.i, data.j, data.dieWidth, data.dieHeight, data.xAnchor, data.yAnchor, data.streetWidth, data.streetHeight);

    const topLeft = { x: innerDieRect.startX, y: innerDieRect.startY };
    const topRight = { x: innerDieRect.endX, y: innerDieRect.startY };
    const bottomLeft = { x: innerDieRect.startX, y: innerDieRect.endY };
    const bottomRight = { x: innerDieRect.endX, y: innerDieRect.endY };

    let multiplier = 1;
    let center = data.waferCenterY;
    let axis: 'x' | 'y' = 'y';
    const radiusInInches = (data.wcmWaferDiameter / 2);
    let distanceFromCenterInInches = radiusInInches;
    switch (data.wcmExclusionType) {
      case ExclusionType.NOTCH_KEEP_OUT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferNotchKeepOut;
        break;
      case ExclusionType.FLAT_KEEP_OUT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferFlatKeepOut;
        break;
      case ExclusionType.SCRIBE_LINE_AND_BASE_FLAT:
        distanceFromCenterInInches = radiusInInches - data.wcmWaferBaseFlat - data.wcmWaferScribeLine;
        break;
      default:
        break;
    }
    let distanceFromCenterToKeepOutLine = (distanceFromCenterInInches * data.heightOfBg) / data.wcmWaferDiameter;
    if (data.notchPosition === NotchPosition.LEFT || data.notchPosition === NotchPosition.RIGHT) {
      center = data.waferCenterX;
      axis = 'x';
      distanceFromCenterToKeepOutLine = (distanceFromCenterInInches * data.widthOfBg) / data.wcmWaferDiameter;
    }
    if (data.notchPosition === NotchPosition.DOWN || data.notchPosition === NotchPosition.RIGHT) {
      multiplier = -1;
    }
    if (this.isDieOutsideFlatOrNotch(multiplier, center, topLeft, topRight, bottomLeft, bottomRight, axis, distanceFromCenterToKeepOutLine)) {
      // eslint-disable-next-line no-param-reassign
      data.dieData.isCropped = true;
    }
    if (data.wcmWaferDiameter !== WAFER_DIAMETER_EXEMPTED_FROM_RETICLE_COLLISION_CROPPING && this.isReticleCollidingWithKeepOut({
      dieWidth: data.dieWidth,
      dieHeight: data.dieHeight,
      reticleSize: data.reticleSize,
      dieData: data.dieData,
      notchPosition: data.notchPosition,
      yAnchor: data.yAnchor,
      xAnchor: data.xAnchor,
      center,
      multiplier,
      distanceFromCenterToKeepOutLine,
    })) {
      // eslint-disable-next-line no-param-reassign
      data.dieData.isCropped = true;
    }
  };

  performActionCrop = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, showRing, ringDiameterToWaferDiameterRatio, scaledDieWidth, scaledDieHeight, xAnchor, yAnchor, reticleSize,
      waferWidthToColsRatio, waferHeightToRowsRatio, waferBGOffsetXDies, waferBGOffsetYDies, dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio, wcmWaferNotchKeepOut, wcmWaferDiameter, notchPosition, wcmExclusionType, wcmWaferFlatKeepOut, gl,
      wcmWaferBaseFlat, wcmWaferScribeLine, startRow, startCol, rowDirection, colDirection,
    } = this.waferMapVariables;

    const rows = waferData.waferMaxRows;
    const cols = waferData.waferMaxCols;
    const mWidth = scaledDieWidth * cols;
    const mHeight = scaledDieHeight * rows;
    const widthOfBg = mWidth * waferWidthToColsRatio;
    const heightOfBg = mHeight * waferHeightToRowsRatio;
    const waferCenterX = xAnchor + mWidth / 2 + waferBGOffsetXDies * scaledDieWidth;
    const waferCenterY = yAnchor + mHeight / 2 + waferBGOffsetYDies * scaledDieHeight;

    const streetWidth = dieWidthToStreetWidthRatio === -1 ? 0 : scaledDieWidth / dieWidthToStreetWidthRatio;
    const streetHeight = dieHeightToStreetHeightRatio === -1 ? 0 : scaledDieHeight / dieHeightToStreetHeightRatio;

    if (!showRing || !gl) return null;
    for (let i = 0, p = startRow; i < waferData.dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; waferData.dies[i] && j < waferData.dies[i].length; j += 1, q += colDirection) {
        const dieData = waferData.dies[p][q];

        if (dieData) {
          if (dieData.location !== DieLocation.INSIDE_RING) {
            dieData.isCropped = true;
          }

          this.performCropDueToKeepOut({
            i,
            j,
            dieWidth: scaledDieWidth,
            dieHeight: scaledDieHeight,
            xAnchor,
            yAnchor,
            wcmExclusionType,
            dieWidthToStreetWidthRatio,
            dieHeightToStreetHeightRatio,
            waferCenterX,
            waferCenterY,
            wcmWaferDiameter,
            wcmWaferNotchKeepOut,
            wcmWaferFlatKeepOut,
            wcmWaferBaseFlat,
            wcmWaferScribeLine,
            widthOfBg,
            heightOfBg,
            notchPosition,
            dieData,
            reticleSize,
            streetHeight,
            streetWidth,
          });
        }
      }
    }
    this.waferMapVariables.radarDiesGLCoords = null;
    return { data: waferData, action: data.action, ringDiameterToWaferDiameterRatio };
  };

  performActionRedraw = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, showRing, ringDiameterToWaferDiameterRatio, startRow, startCol,
      rowDirection, colDirection, gl,
    } = this.waferMapVariables;
    if (!showRing || !gl) return null;
    for (let i = 0, p = startRow; i < waferData.dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; waferData.dies[i] && j < waferData.dies[i].length; j += 1, q += colDirection) {
        const dieData = waferData.dies[i][j];
        if (dieData && dieData.location === DieLocation.INSIDE_RING) {
          dieData.isCropped = false;
        }
      }
    }
    this.waferMapVariables.radarDiesGLCoords = null;
    return { data: waferData, action: data.action, ringDiameterToWaferDiameterRatio };
  };

  performActionDefault = (data: { action: ActionOnWaferData, config: any }) => {
    const { waferData } = this.waferMapVariables;
    return { data: waferData, action: data.action };
  };

  addReticleInfoToDie = (i: number, j: number, dieData: DieData, reticleSize: { x: number, y: number }, reticleTopLeft: { x: number, y: number }, standardReticle: StandardReticle) => {
    // helper method
    const retX = (reticleSize.x - ((reticleTopLeft.x - j) % reticleSize.x)) % reticleSize.x;
    const retY = (reticleSize.y - ((reticleTopLeft.y - i) % reticleSize.y)) % reticleSize.y;
    // eslint-disable-next-line no-param-reassign
    dieData.reticleX = retX;
    // eslint-disable-next-line no-param-reassign
    dieData.reticleY = retY;
    // eslint-disable-next-line no-param-reassign
    dieData.reticleSite = standardReticle.reticle[retY][retX];
    if (standardReticle.reticle[retY][retX] === +standardReticle.WATPCMSite) {
      // eslint-disable-next-line no-param-reassign
      dieData.dieType = UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', this.waferMapVariables.dieTypes.dieType) || '';
    }
    return ((j - reticleTopLeft.x) < reticleSize.x)
    && ((j - reticleTopLeft.x) >= 0) && ((i - reticleTopLeft.y) < reticleSize.y) && ((i - reticleTopLeft.y) >= 0);
  };

  performActionApplyReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, reticleSize, standardReticle, getReticleAxisReference,
    } = this.waferMapVariables;
    if (standardReticle !== null) {
      this.waferMapVariables.reticleGridRectCoords = {}; // empty older reticle coords
      this.waferMapVariables.referenceReticleGridRectCoords = null;
      for (let i = 0; i < waferData.dies.length; i += 1) {
        for (let j = 0; waferData.dies[i] && j < waferData.dies[i].length; j += 1) {
          const dieData = waferData.dies[i][j];
          if (dieData !== null) {
            const isDieInReferenceReticle = this.addReticleInfoToDie(i, j, dieData, reticleSize, data.config.reticleTopLeft, standardReticle);
            // reticle stored as dictionary
            const reticleRectCoordOfCurrDie = UtilityFunctions.getReticleGridRectCoordOfDie(dieData, reticleSize);
            this.waferMapVariables.reticleGridRectCoords[JSON.stringify(reticleRectCoordOfCurrDie)] = {
              isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
            };
            if (isDieInReferenceReticle) {
              this.waferMapVariables.referenceReticleGridRectCoords = _.cloneDeep(reticleRectCoordOfCurrDie);
            }
          }
        }
      }
      this.waferMapVariables.reticleXAxisReference = getReticleAxisReference('x');
      this.waferMapVariables.reticleYAxisReference = getReticleAxisReference('y');
    }
    return { data: waferData, action: data.action };
  };

  performActionCropSelectedReticlesOnWafer = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, colOffset, rowOffset, waferData, dieTypes,
    } = this.waferMapVariables;
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [key, value] of Object.entries(reticleGridRectCoords)) {
      if (reticleGridRectCoords[key].isSelected) {
        delete reticleGridRectCoords[key];
        const reticleRectCoord: RectCoord = JSON.parse(key);
        for (let i = reticleRectCoord.startY - rowOffset; i <= reticleRectCoord.endY - rowOffset; i += 1) {
          for (let j = reticleRectCoord.startX - colOffset; j <= reticleRectCoord.endX - colOffset; j += 1) {
            if (waferData.dies[i] && waferData.dies[i][j]) {
              UtilityFunctions.deleteReticleInfo(waferData.dies[i][j]!, dieTypes.dieType);
            }
          }
        }
      }
    }
    return { data: waferData, action: data.action };
  };

  performActionPanReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, colOffset, rowOffset, waferData, reticleSize, standardReticle,
    } = this.waferMapVariables;
    const newReticleGridRectCoords = _.cloneDeep(reticleGridRectCoords);

    // adjust the increment depending on whether UOM is die or reticle
    let incrementX = data.config.increment;
    let incrementY = data.config.increment;
    if (data.config.UOM === 'reticle') {
      incrementY = data.config.increment * reticleSize.y;
      incrementX = data.config.increment * reticleSize.x;
    }

    const oldReticles: ReticleGridRectCoords = {};
    // delete old reticles
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      if (key in reticleGridRectCoords) {
        oldReticles[key] = _.cloneDeep(reticleGridRectCoords[key]);
        delete newReticleGridRectCoords[key];
      }
    }

    // add new reticles
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      if (data.config.direction === ReticlePanDirection.UP) {
        reticleRectCoord.startY -= incrementY;
        reticleRectCoord.endY -= incrementY;
      } else if (data.config.direction === ReticlePanDirection.DOWN) {
        reticleRectCoord.startY += incrementY;
        reticleRectCoord.endY += incrementY;
      } else if (data.config.direction === ReticlePanDirection.LEFT) {
        reticleRectCoord.startX -= incrementX;
        reticleRectCoord.endX -= incrementX;
      } else if (data.config.direction === ReticlePanDirection.RIGHT) {
        reticleRectCoord.startX += incrementX;
        reticleRectCoord.endX += incrementX;
      }

      if (key in reticleGridRectCoords) {
        if (data.config.mode === ReticlePanMode.SELECTED && JSON.stringify(reticleRectCoord) in newReticleGridRectCoords) {
          toast.warn('Reticles cannot overlap with each other.');
          return null;
        }
        newReticleGridRectCoords[JSON.stringify(reticleRectCoord)] = oldReticles[key];
      }
    }

    // check for overlap
    if (data.config.mode === ReticlePanMode.SELECTED) {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const key1 in newReticleGridRectCoords) {
        if (newReticleGridRectCoords[key1].isSelected) {
          // eslint-disable-next-line guard-for-in, no-restricted-syntax
          for (const key2 in newReticleGridRectCoords) {
            const selectedReticleRectCoord: RectCoord = JSON.parse(key1);
            const reticleRectCoord: RectCoord = JSON.parse(key2);
            if (
              selectedReticleRectCoord.endX >= reticleRectCoord.startX
              && selectedReticleRectCoord.startX <= reticleRectCoord.endX
              && selectedReticleRectCoord.endY >= reticleRectCoord.startY
              && selectedReticleRectCoord.startY <= reticleRectCoord.endY
              && !_.isEqual(selectedReticleRectCoord, reticleRectCoord)
            ) {
              toast.warn('Reticles cannot overlap with each other.');
              return null;
            }
          }
        }
      }
    }

    // delete reticle info from old dies in selected reticle because it is going to be moved
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !reticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      for (let i = reticleRectCoord.startY - rowOffset; i <= reticleRectCoord.endY - rowOffset; i += 1) {
        for (let j = reticleRectCoord.startX - colOffset; j <= reticleRectCoord.endX - colOffset; j += 1) {
          if (waferData.dies[i] && waferData.dies[i][j]) {
            UtilityFunctions.deleteReticleInfo(waferData.dies[i][j]!, this.waferMapVariables.dieTypes.dieType);
          }
        }
      }
    }

    // adding reticle info to dies where reticle is moved
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in newReticleGridRectCoords) {
      // eslint-disable-next-line no-continue
      if (data.config.mode === ReticlePanMode.SELECTED && !newReticleGridRectCoords[key].isSelected) continue;
      const reticleRectCoord: RectCoord = JSON.parse(key);
      for (let i = reticleRectCoord.startY - rowOffset, k = 0; i <= reticleRectCoord.endY - rowOffset; i += 1, k += 1) {
        for (let j = reticleRectCoord.startX - colOffset, l = 0; j <= reticleRectCoord.endX - colOffset; j += 1, l += 1) {
          if (waferData.dies[i] && waferData.dies[i][j]) {
            waferData.dies[i][j]!.reticleX = l;
            waferData.dies[i][j]!.reticleY = k;
            waferData.dies[i][j]!.reticleSite = standardReticle!.reticle[k][l];
            if (standardReticle!.reticle[k][l] === +standardReticle!.WATPCMSite) {
              waferData.dies[i][j]!.dieType = UtilityFunctions.getDieTypeIdFromName('WAT/PCM Die', this.waferMapVariables.dieTypes.dieType) || '';
            }
          }
        }
      }
    }
    this.waferMapVariables.reticleGridRectCoords = newReticleGridRectCoords;
    return { data: waferData, action: data.action };
  };

  isNewReticleSpaceOccupied = (direction: ReticleInsertDirection, currReticle: RectCoord, affectingReticle: RectCoord, reticleSize: { x: number, y: number }) => {
    // helper method
    return (
      (
        (
          (
            (direction === ReticleInsertDirection.DOWN && currReticle.startY > affectingReticle.endY && currReticle.startY <= affectingReticle.endY + reticleSize.y)
            || (direction === ReticleInsertDirection.UP && currReticle.endY < affectingReticle.startY && currReticle.endY >= affectingReticle.startY - reticleSize.y)
          )
          && currReticle.startX <= affectingReticle.endX
          && currReticle.endX >= affectingReticle.startX
        )
    || (
      (
        (direction === ReticleInsertDirection.RIGHT && currReticle.startX > affectingReticle.endX && currReticle.startX <= affectingReticle.endX + reticleSize.x)
            || (direction === ReticleInsertDirection.LEFT && currReticle.endX < affectingReticle.startX && currReticle.endX >= affectingReticle.startX - reticleSize.x)
      )
        && currReticle.startY <= affectingReticle.endY
        && currReticle.endY >= affectingReticle.startY
    )
      )
   && !_.isEqual(affectingReticle, currReticle)
    );
  };

  performActionInsertReticle = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      reticleGridRectCoords, waferData, reticleSize,
    } = this.waferMapVariables;

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const selectedReticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[selectedReticleKey].isSelected) {
        const selectedReticle: RectCoord = JSON.parse(selectedReticleKey);

        let canInsert = true;

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const reticleKey in this.waferMapVariables.reticleGridRectCoords) {
          const currReticle: RectCoord = JSON.parse(reticleKey);
          if (this.isNewReticleSpaceOccupied(data.config.direction, currReticle, selectedReticle, reticleSize)) {
            canInsert = false;
            break;
          }
        }

        if (canInsert) {
          const newReticle = _.cloneDeep(selectedReticle);
          if (data.config.direction === ReticleInsertDirection.LEFT) {
            newReticle.startX -= reticleSize.x;
            newReticle.endX -= reticleSize.x;
          }
          if (data.config.direction === ReticleInsertDirection.RIGHT) {
            newReticle.startX += reticleSize.x;
            newReticle.endX += reticleSize.x;
          }
          if (data.config.direction === ReticleInsertDirection.DOWN) {
            newReticle.startY += reticleSize.y;
            newReticle.endY += reticleSize.y;
          }
          if (data.config.direction === ReticleInsertDirection.UP) {
            newReticle.startY -= reticleSize.y;
            newReticle.endY -= reticleSize.y;
          }
          this.waferMapVariables.reticleGridRectCoords[JSON.stringify(newReticle)] = {
            isSelected: false, isUnderSelection: false, watPCMSiteNumber: null,
          };
        } else {
          toast.warn(`Can not insert reticle in ${ReticleInsertDirection[data.config.direction].toLowerCase()}
            direction of the following reticle: startX = ${selectedReticle.startX},  startY = ${selectedReticle.startY}, endX = ${selectedReticle.endX}, endY = ${selectedReticle.endY}`);
        }
      }
    }
    return { data: waferData, action: data.action };
  };

  performActionApplyFullWATMap = (data: { action: ActionOnWaferData, config: any }) => {
    const { waferData } = this.waferMapVariables;

    const arr = Object.keys(this.waferMapVariables.reticleGridRectCoords).map((x: any) => { return JSON.parse(x); });
    const sortedArr: RectCoord[] = _.orderBy(arr, ['startY', 'startX'], ['asc']);
    for (let i = 0; i < sortedArr.length; i += 1) {
      this.waferMapVariables.reticleGridRectCoords[JSON.stringify(sortedArr[i])].watPCMSiteNumber = i + 1;
    }
    return { data: waferData, action: data.action };
  };

  deleteOldZoneInfoFromDie = (dieData: DieData, selectedZoneId: string) => {
    if ('zoneNumber' in dieData) {
      // eslint-disable-next-line no-param-reassign
      delete dieData.zoneNumber;
    }
    if (dieData.zoneInfo && selectedZoneId in dieData.zoneInfo!) {
      // eslint-disable-next-line no-param-reassign
      delete dieData.zoneInfo[selectedZoneId];
    }
  };

  deleteOldZoneInfoFromDies = (startRow: number, startCol: number, dies: Dies, rowDirection: number, colDirection: number, selectedZoneId: string) => {
    for (let i = 0, p = startRow; i < dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < dies[i].length; j += 1, q += colDirection) {
        if (dies[p][q]) this.deleteOldZoneInfoFromDie(dies[p][q]!, selectedZoneId);
      }
    }
  };

  getPcmSitesInRadialZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    xAnchor: number,
    yAnchor: number,
    rows: number,
    cols: number,
    panOffsetXToDieStepSizeXRatio: number,
    panOffsetYToDieStepSizeYRatio: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    dies: Dies,
    markRadialInfo: boolean,
  }) => {
    const {
      widthOfBg, heightOfBg, centerOfBG,
    } = this.getWaferWidthHeightDiffInfo(data.xAnchor, data.yAnchor, data.scaledDieWidth, data.scaledDieHeight);

    const reticles = Object.keys(data.reticleGridRectCoords);
    const maxRingRadiusY = Math.max(widthOfBg, heightOfBg) / 2;

    const ringRadiusExcludingEdgeExclusion = ((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter) * maxRingRadiusY;
    const incrementRadiusSqred = (ringRadiusExcludingEdgeExclusion ** 2) / data.numberOfZones!;

    const pcmSitesInRadialZone: any = [];
    for (let i = 0; i < data.numberOfZones!; i += 1) {
      pcmSitesInRadialZone[i] = [];
    }

    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleRect: RectCoord = {
        startX: data.xAnchor + (currReticle.startX - data.colOffset) * data.scaledDieWidth,
        startY: data.yAnchor + (currReticle.startY - data.rowOffset) * data.scaledDieHeight,
        endX: data.xAnchor + (currReticle.endX - data.colOffset) * data.scaledDieWidth + data.scaledDieWidth,
        endY: data.yAnchor + (currReticle.endY - data.rowOffset) * data.scaledDieHeight + data.scaledDieHeight,
      };
      const reticleCenter = { x: (reticleRect.startX + reticleRect.endX) / 2, y: (reticleRect.startY + reticleRect.endY) / 2 };

      let currRadiusSqred = incrementRadiusSqred;
      for (let r = 0; r < data.numberOfZones!; r += 1, currRadiusSqred += incrementRadiusSqred) {
        const ringRadius = Math.sqrt(currRadiusSqred);
        if (UtilityFunctions.euclideanDistance(reticleCenter, centerOfBG) < ringRadius) {
          if (data.markRadialInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.dies[l] && data.dies[l][m]) {
                  // eslint-disable-next-line no-param-reassign
                  data.dies[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInRadialZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }
    return pcmSitesInRadialZone;
  };

  markZoneInfoFromZoneNumbers = (startRow: number, startCol: number, dies: Dies, rowDirection: number, colDirection: number, selectedZoneId: string, pcmSitesInZone: any) => {
    for (let i = 0, p = startRow; i < dies.length; i += 1, p += rowDirection) {
      for (let j = 0, q = startCol; j < dies[i].length; j += 1, q += colDirection) {
        if (dies[p][q] && 'zoneNumber' in dies[p][q]!) {
          if (!dies[p][q]!.zoneInfo) {
            // eslint-disable-next-line no-param-reassign
            dies[p][q]!.zoneInfo = {};
          }
          // eslint-disable-next-line no-param-reassign
          dies[p][q]!.zoneInfo![selectedZoneId] = [...pcmSitesInZone[dies[p][q]!.zoneNumber]];
        }
      }
    }
  };

  performActionApplyRadialZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, reticleGridRectCoords,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, colOffset, rowOffset, zones,
      panOffsetXToDieStepSizeXRatio, panOffsetYToDieStepSizeYRatio, waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'RADIAL' && currZone.numberOfZones === undefined) return { data: waferData, action: data.action };

    // delete old zone info from dies
    this.deleteOldZoneInfoFromDies(startRow, startCol, waferData.dies, rowDirection, colDirection, data.config.selectedZoneId);

    const pcmSitesInRadialZone = this.getPcmSitesInRadialZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      panOffsetXToDieStepSizeXRatio,
      panOffsetYToDieStepSizeYRatio,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markRadialInfo: true,
    });
    this.markZoneInfoFromZoneNumbers(startRow, startCol, waferData.dies, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInRadialZone);
    return { data: waferData, action: data.action };
  };

  getNumberOfVerticalZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      waferData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const pcmSitesInVerticalZone = this.getPcmSitesInVerticalZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markInfo: false,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: '',
      dieTypes,
      currZone: null,
    });

    if (!pcmSitesInVerticalZone) {
      toast.error('Error in defining vertical zone.');
      return null;
    }

    let numberOfVerticalZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInVerticalZone[i].length === 0) {
        numberOfVerticalZonesWithNoPCMSites += 1;
      }
    }

    return numberOfVerticalZonesWithNoPCMSites;
  };

  isDieInsideGivenRadiusFromCenter = (
    radius: number,
    dieData: DieData,
    rowOffset: number,
    colOffset: number,
    dieWidth: number,
    dieHeight: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    offsetX: number,
    offsetY: number,
    waferCenter: { x: number, y: number },
  ) => {
    const startX = (dieData.x! - colOffset) * dieWidth + offsetX;
    const startY = (dieData.y! - rowOffset) * dieHeight + offsetY;
    const endX = startX + dieWidth;
    const endY = startY + dieHeight;

    const sw = dieWidth / dieWidthToStreetWidthRatio;
    const sh = dieHeight / dieHeightToStreetHeightRatio;

    const dieBorderSX = startX + sw / 2;
    const dieBorderSY = startY + sh / 2;
    const dieBorderEX = endX - sw / 2;
    const dieBorderEY = endY - sh / 2;

    const topLeft = { x: dieBorderSX, y: dieBorderSY };
    const topRight = { x: dieBorderEX, y: dieBorderSY };
    const bottomLeft = { x: dieBorderSX, y: dieBorderEY };
    const bottomRight = { x: dieBorderEX, y: dieBorderEY };

    return (
      UtilityFunctions.euclideanDistance(topLeft, waferCenter) < radius
      && UtilityFunctions.euclideanDistance(topRight, waferCenter) < radius
      && UtilityFunctions.euclideanDistance(bottomLeft, waferCenter) < radius
      && UtilityFunctions.euclideanDistance(bottomRight, waferCenter) < radius
    );
  };

  isDieEligibleForMarkingZone = (
    radius: number,
    dieData: DieData,
    rowOffset: number,
    colOffset: number,
    dieWidth: number,
    dieHeight: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    offsetX: number,
    offsetY: number,
    waferCenter: { x: number, y: number },
    dieTypes: any,
  ) => {
    return (this.isDieInsideGivenRadiusFromCenter(
      radius,
      dieData,
      rowOffset,
      colOffset,
      dieWidth,
      dieHeight,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      offsetX,
      offsetY,
      waferCenter,
    )
       && (!dieData.dieType || (dieData.dieType !== UtilityFunctions.getDieTypeIdFromName('Edge Die', dieTypes.dieType)))
      && (!dieData.dieType || (dieData.dieType !== UtilityFunctions.getDieTypeIdFromName('Ugly/Partial Die', dieTypes.dieType)))
    );
  };

  getVerticalZoneColNumbers = (totalDies: number, numberOfZones: number, noOfDiesInsideWaferInColumn: number[], rows: number, colOffset: number) => {
    const front: number[] = [];
    const back: number[] = [];
    let verticalZoneColNumbers: number[] = [];
    let dieCountInCurrZone = 0;
    const requiredDies = totalDies / numberOfZones;
    for (let i = 0, j = 0; i < noOfDiesInsideWaferInColumn.length && j < Math.floor((numberOfZones - 1) / 2); i += 1) {
      dieCountInCurrZone += noOfDiesInsideWaferInColumn[i];
      if (dieCountInCurrZone <= (requiredDies + rows / 2) && dieCountInCurrZone > (requiredDies - rows / 2)) {
        front.push(i + colOffset);
        back.push(noOfDiesInsideWaferInColumn.length - i - 2 + colOffset);
        dieCountInCurrZone = 0;
        j += 1;
      }
    }

    if (numberOfZones % 2 === 0) {
      verticalZoneColNumbers = [...front, (front[front.length - 1] + back[back.length - 1]) / 2, ...back.reverse()];
    } else {
      verticalZoneColNumbers = [...front, ...back.reverse()];
    }

    return verticalZoneColNumbers;
  };

  getMaxColNumFromAllReticles = (reticleGridRectCoords: ReticleGridRectCoords) => {
    let max = Number.MIN_SAFE_INTEGER;
    const reticles = Object.keys(reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      if ((JSON.parse(reticles[i]) as RectCoord).endX > max) {
        max = (JSON.parse(reticles[i]) as RectCoord).endX;
      }
    }
    return max;
  };

  getPcmSitesInVerticalZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    xAnchor: number,
    yAnchor: number,
    rows: number,
    cols: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    dies: Dies,
    markInfo: boolean,
    startRow: number,
    startCol: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    rowDirection: number,
    colDirection: number,
    selectedZoneId: string,
    dieTypes: any,
    currZone: ZoneData | null,
  }) => {
    const {
      centerOfBG, widthOfBg, heightOfBg,
    } = this.getWaferWidthHeightDiffInfo(data.xAnchor, data.yAnchor, data.scaledDieWidth, data.scaledDieHeight);

    const waferRadiusExcludingEdgeExclusion = (((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);
    const rows = data.dies.length;
    const cols = data.dies[0].length;

    const noOfDiesInsideWaferInColumn = [];
    let totalDies = 0;
    for (let j = 0, q = data.startCol; j < cols; j += 1, q += data.colDirection) {
      let count = 0;
      for (let i = 0, p = data.startRow; i < rows; i += 1, p += data.rowDirection) {
        if (data.dies[p][q]) {
          if (data.markInfo) this.deleteOldZoneInfoFromDie(data.dies[p][q]!, data.selectedZoneId);
          if (
            this.isDieEligibleForMarkingZone(
              waferRadiusExcludingEdgeExclusion,
              data.dies[p][q]!,
              data.rowOffset,
              data.colOffset,
              data.scaledDieWidth,
              data.scaledDieHeight,
              data.dieWidthToStreetWidthRatio,
              data.dieHeightToStreetHeightRatio,
              data.xAnchor,
              data.yAnchor,
              centerOfBG,
              data.dieTypes,
            )
          ) {
            count += 1;
            totalDies += 1;
            // eslint-disable-next-line no-param-reassign
            if (data.markInfo) data.dies[p][q]!.shouldConsiderForVerticleZone = true;
          } else {
            // eslint-disable-next-line no-lonely-if
            // eslint-disable-next-line no-param-reassign
            if (data.markInfo) data.dies[p][q]!.shouldConsiderForVerticleZone = false;
          }
        }
      }
      noOfDiesInsideWaferInColumn.push(count);
    }

    const verticalZoneColNumbers: number[] = this.getVerticalZoneColNumbers(totalDies, data.numberOfZones, noOfDiesInsideWaferInColumn, rows, data.colOffset);
    // eslint-disable-next-line no-param-reassign
    if (data.markInfo) data.currZone!.verticalZoneColNumbers = verticalZoneColNumbers;

    const pcmSitesInVerticalZone: any = [];
    for (let i = 0; i < data.numberOfZones; i += 1) {
      pcmSitesInVerticalZone[i] = [];
    }

    const verticalZoneColNumbersIncludingLast = [...verticalZoneColNumbers, this.getMaxColNumFromAllReticles(data.reticleGridRectCoords)];
    if (data.numberOfZones !== verticalZoneColNumbersIncludingLast.length) return null;
    const reticles = Object.keys(data.reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleCenter = { x: (currReticle.startX + currReticle.endX) / 2, y: (currReticle.startY + currReticle.endY) / 2 };
      for (let r = 0; r < data.numberOfZones; r += 1) {
        if (reticleCenter.x < verticalZoneColNumbersIncludingLast[r]) {
          if (data.markInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.dies[l] && data.dies[l][m] && data.dies[l][m]!.shouldConsiderForVerticleZone) {
                  // eslint-disable-next-line no-param-reassign
                  data.dies[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInVerticalZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }

    return pcmSitesInVerticalZone;
  };

  performActionApplyVerticalZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, zones, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'VERTICAL' && currZone.numberOfZones === undefined) return { data: waferData, action: data.action };

    // also handles deletion of old zones
    const pcmSitesInVerticalZone = this.getPcmSitesInVerticalZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markInfo: true,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: data.config.selectedZoneId,
      dieTypes,
      currZone,
    });
    if (pcmSitesInVerticalZone) {
      this.markZoneInfoFromZoneNumbers(startRow, startCol, waferData.dies, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInVerticalZone);
    } else {
      toast.error('Error in defining vertical zone.');
    }
    return { data: waferData, action: data.action };
  };

  getHorizontalZoneRowNumbers = (totalDies: number, numberOfZones: number, noOfDiesInsideWaferInRow: number[], cols: number, rowOffset: number) => {
    const front: number[] = [];
    const back: number[] = [];
    let horizontalZoneRowNumbers: number[] = [];
    let dieCountInCurrZone = 0;
    const requiredDies = totalDies / numberOfZones;
    for (let i = 0, j = 0; i < noOfDiesInsideWaferInRow.length && j < Math.floor((numberOfZones - 1) / 2); i += 1) {
      dieCountInCurrZone += noOfDiesInsideWaferInRow[i];
      if (dieCountInCurrZone <= (requiredDies + cols / 2) && dieCountInCurrZone > (requiredDies - cols / 2)) {
        front.push(i + rowOffset);
        back.push(noOfDiesInsideWaferInRow.length - i - 2 + rowOffset);
        dieCountInCurrZone = 0;
        j += 1;
      }
    }

    if (numberOfZones % 2 === 0) {
      horizontalZoneRowNumbers = [...front, (front[front.length - 1] + back[back.length - 1]) / 2, ...back.reverse()];
    } else {
      horizontalZoneRowNumbers = [...front, ...back.reverse()];
    }

    return horizontalZoneRowNumbers;
  };

  getMaxRowNumFromAllReticles = (reticleGridRectCoords: ReticleGridRectCoords) => {
    let max = Number.MIN_SAFE_INTEGER;
    const reticles = Object.keys(reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      if ((JSON.parse(reticles[i]) as RectCoord).endY > max) {
        max = (JSON.parse(reticles[i]) as RectCoord).endY;
      }
    }
    return max;
  };

  getNumberOfHorizontalZonesWithNoPCMSites = (numberOfZones: number) => {
    const {
      waferData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const pcmSitesInHorizontalZone = this.getPcmSitesInHorizontalZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markInfo: false,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: '',
      dieTypes,
      currZone: null,
    });

    if (!pcmSitesInHorizontalZone) {
      toast.error('Error in defining horizontal zone.');
      return null;
    }

    let numberOfHorizontalZonesWithNoPCMSites = 0;
    for (let i = 0; i < numberOfZones; i += 1) {
      if (pcmSitesInHorizontalZone[i].length === 0) {
        numberOfHorizontalZonesWithNoPCMSites += 1;
      }
    }

    return numberOfHorizontalZonesWithNoPCMSites;
  };

  getPcmSitesInHorizontalZone = (data: {
    scaledDieWidth: number,
    scaledDieHeight: number,
    xAnchor: number,
    yAnchor: number,
    rows: number,
    cols: number,
    waferBGOffsetXDies: number,
    waferBGOffsetYDies: number,
    reticleGridRectCoords: ReticleGridRectCoords,
    waferWidthToColsRatio: number,
    waferHeightToRowsRatio: number,
    wcmWaferDiameter: number,
    wcmWaferEdgeExclusion: number,
    numberOfZones: number,
    colOffset: number,
    rowOffset: number,
    dies: Dies,
    markInfo: boolean,
    startRow: number,
    startCol: number,
    dieWidthToStreetWidthRatio: number,
    dieHeightToStreetHeightRatio: number,
    rowDirection: number,
    colDirection: number,
    selectedZoneId: string,
    dieTypes: any,
    currZone: ZoneData | null,
  }) => {
    const dieWidth = data.scaledDieWidth;
    const dieHeight = data.scaledDieHeight;
    const offsetX = data.xAnchor;
    const offsetY = data.yAnchor;
    const mWidth = dieWidth * data.dies[0].length;
    const mHeight = dieHeight * data.dies.length;
    const waferCenterX = offsetX + mWidth / 2 + data.waferBGOffsetXDies * dieWidth;
    const waferCenterY = offsetY + mHeight / 2 + data.waferBGOffsetYDies * dieHeight;
    const widthOfBg = mWidth * data.waferWidthToColsRatio;
    const heightOfBg = mHeight * data.waferHeightToRowsRatio;
    const waferCenter = { x: waferCenterX, y: waferCenterY };
    const waferRadiusExcludingEdgeExclusion = (((data.wcmWaferDiameter - 2 * data.wcmWaferEdgeExclusion) / data.wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);
    const rows = data.dies.length;
    const cols = data.dies[0].length;

    const noOfDiesInsideWaferInRow = [];
    let totalDies = 0;
    for (let i = 0, p = data.startRow; i < rows; i += 1, p += data.rowDirection) {
      let count = 0;
      for (let j = 0, q = data.startCol; j < cols; j += 1, q += data.colDirection) {
        if (data.dies[p][q]) {
          if (data.markInfo) this.deleteOldZoneInfoFromDie(data.dies[p][q]!, data.selectedZoneId);
          if (
            this.isDieEligibleForMarkingZone(
              waferRadiusExcludingEdgeExclusion,
              data.dies[p][q]!,
              data.rowOffset,
              data.colOffset,
              data.scaledDieWidth,
              data.scaledDieHeight,
              data.dieWidthToStreetWidthRatio,
              data.dieHeightToStreetHeightRatio,
              data.xAnchor,
              data.yAnchor,
              waferCenter,
              data.dieTypes,
            )
          ) {
            count += 1;
            totalDies += 1;
            // eslint-disable-next-line no-param-reassign
            if (data.markInfo) data.dies[p][q]!.shouldConsiderForHorizontalZone = true;
          } else {
            // eslint-disable-next-line no-lonely-if
            // eslint-disable-next-line no-param-reassign
            if (data.markInfo) data.dies[p][q]!.shouldConsiderForHorizontalZone = false;
          }
        }
      }
      noOfDiesInsideWaferInRow.push(count);
    }
    const horizontalZoneRowNumbers: number[] = this.getHorizontalZoneRowNumbers(totalDies, data.numberOfZones, noOfDiesInsideWaferInRow, cols, data.rowOffset);
    // eslint-disable-next-line no-param-reassign
    if (data.markInfo) data.currZone!.horizontalZoneRowNumbers = horizontalZoneRowNumbers;
    const pcmSitesInHorizontalZone: any = [];
    for (let i = 0; i < data.numberOfZones; i += 1) {
      pcmSitesInHorizontalZone[i] = [];
    }

    const horizontalZoneNumbersIncludingLast = [...horizontalZoneRowNumbers, this.getMaxRowNumFromAllReticles(data.reticleGridRectCoords)];
    if (data.numberOfZones !== horizontalZoneNumbersIncludingLast.length) return null;
    const reticles = Object.keys(data.reticleGridRectCoords);
    for (let i = 0; i < reticles.length; i += 1) {
      const currReticle: RectCoord = JSON.parse(reticles[i]);
      const reticleCenter = { x: (currReticle.startX + currReticle.endX) / 2, y: (currReticle.startY + currReticle.endY) / 2 };
      for (let r = 0; r < data.numberOfZones; r += 1) {
        if (reticleCenter.y < horizontalZoneNumbersIncludingLast[r]) {
          if (data.markInfo) {
            // if reticle is inside the ring mark the radial zone number (ring) on the die
            for (let l = currReticle.startY - data.rowOffset; l <= currReticle.endY - data.rowOffset; l += 1) {
              for (let m = currReticle.startX - data.colOffset; m <= currReticle.endX - data.colOffset; m += 1) {
                if (data.dies[l] && data.dies[l][m] && data.dies[l][m]!.shouldConsiderForHorizontalZone) {
                  // eslint-disable-next-line no-param-reassign
                  data.dies[l][m]!.zoneNumber = r;
                }
              }
            }
          }
          // if the reticle has wat pcm site number than add that pcm site number to current radial zone
          if (data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber !== null) {
            pcmSitesInHorizontalZone[r].push(data.reticleGridRectCoords[reticles[i]].watPCMSiteNumber);
          }
          break;
        }
      }
    }

    return pcmSitesInHorizontalZone;
  };

  performActionApplyHorizontalZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      waferData, startRow, startCol, rowDirection, colDirection, scaledDieHeight, scaledDieWidth, dieTypes, reticleGridRectCoords,
      xAnchor, yAnchor, waferWidthToColsRatio, waferHeightToRowsRatio, zones, dieWidthToStreetWidthRatio, dieHeightToStreetHeightRatio,
      waferBGOffsetXDies, waferBGOffsetYDies, wcmWaferDiameter, wcmWaferEdgeExclusion, colOffset, rowOffset,
    } = this.waferMapVariables;

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferData, action: data.action };
    const currZone = filteredZones[0];
    if (currZone.zoneType === 'HORIZONTAL' && currZone.numberOfZones === undefined) return { data: waferData, action: data.action };

    // also handles deletion of old zones
    const pcmSitesInHorizontalZone = this.getPcmSitesInHorizontalZone({
      scaledDieWidth,
      scaledDieHeight,
      xAnchor,
      yAnchor,
      rows: waferData.dies.length,
      cols: waferData.dies[0].length,
      waferBGOffsetXDies,
      waferBGOffsetYDies,
      reticleGridRectCoords,
      waferWidthToColsRatio,
      waferHeightToRowsRatio,
      wcmWaferDiameter,
      wcmWaferEdgeExclusion,
      numberOfZones: currZone.numberOfZones!,
      colOffset,
      rowOffset,
      dies: waferData.dies,
      markInfo: true,
      startRow,
      startCol,
      dieWidthToStreetWidthRatio,
      dieHeightToStreetHeightRatio,
      rowDirection,
      colDirection,
      selectedZoneId: data.config.selectedZoneId,
      dieTypes,
      currZone,
    });
    if (pcmSitesInHorizontalZone) {
      this.markZoneInfoFromZoneNumbers(startRow, startCol, waferData.dies, rowDirection, colDirection, data.config.selectedZoneId, pcmSitesInHorizontalZone);
    } else {
      toast.error('Error in defining horizontal zone.');
    }
    return { data: waferData, action: data.action };
  };

  getZoneInfoOfDiesWithinReticle = (reticle: RectCoord, rowOffset: number, colOffset: number, dies: Dies, selectedZoneId: string) => {
    let reticleZoneInfo: number | null = null;
    for (let l = reticle.startY - rowOffset; l <= reticle.endY - rowOffset && reticleZoneInfo === null; l += 1) {
      for (let m = reticle.startX - colOffset; m <= reticle.endX - colOffset && reticleZoneInfo === null; m += 1) {
        if (
          dies[l]
          && dies[l][m]
          && dies[l][m]!.zoneInfo
          && dies[l][m]!.zoneInfo![selectedZoneId]
          && dies[l][m]!.zoneInfo![selectedZoneId].length > 0) {
          // eslint-disable-next-line prefer-destructuring
          reticleZoneInfo = dies[l][m]!.zoneInfo![selectedZoneId][0]; // assuming one die has only one zone incase of grouped
        }
      }
    }
    return reticleZoneInfo;
  };

  getGroupedZoneCoords = (zones: ZoneData[], currentZoneId: string, rowOffset: number, colOffset: number, dieWidth: number, dieHeight: number, offsetX: number, offsetY: number) => {
    const filteredZones = zones.filter((z: ZoneData) => { return z.id === currentZoneId; });
    if (filteredZones.length === 0) return [];
    const groupedZoneGLCoords: number[] = [];
    for (let i = 0; filteredZones[0].ycoords && i < filteredZones[0].ycoords.length; i += 1) {
      const coord = { x: filteredZones[0].ycoords[i].x - colOffset, y: filteredZones[0].ycoords[i].y - rowOffset };
      const sx = coord.x * dieWidth + offsetX;
      const sy = offsetY + coord.y * dieHeight;
      const ey = offsetY + (coord.y + 1) * dieHeight;
      const coords = GLUtility.prepareRectVec(sx - 2, sy, sx + 2, ey);
      groupedZoneGLCoords.push(...coords);
    }
    for (let i = 0; filteredZones[0].xcoords && i < filteredZones[0].xcoords.length; i += 1) {
      const coord = { x: filteredZones[0].xcoords[i].x - colOffset, y: filteredZones[0].xcoords[i].y - rowOffset };
      const sx = offsetX + coord.x * dieWidth;
      const ex = offsetX + (coord.x + 1) * dieWidth;
      const sy = coord.y * dieHeight + offsetY;
      const coords = GLUtility.prepareRectVec(sx, sy - 2, ex, sy + 2);
      groupedZoneGLCoords.push(...coords);
    }
    return groupedZoneGLCoords;
  };

  getIntersectingInterval = (a: { start: number, end: number }, b: { start: number, end: number }) => {
    const instersectingInterval: { start: number | null, end: number | null } = { start: null, end: null }; // means no intersection
    if (!(b.start > a.end || a.start > b.end)) {
      instersectingInterval.start = Math.max(a.start, b.start);
      instersectingInterval.end = Math.max(a.end, b.end);
    }
    return instersectingInterval;
  };

  getZoneBoundaryCoordsForGroupedZone = (
    direction: 'right' | 'left' | 'above' | 'below',
    reticle: RectCoord,
    reticleSize: { x: number, y: number },
    reticleGridRectCoords: ReticleGridRectCoords,
    currReticleZoneInfo: number | null,
    rowOffset: number,
    colOffset: number,
    dies: Dies,
    selectedZoneId: string,
  ) => {
    const coords: { [key: string]: boolean } = {};
    const possibleReticlesOnSide: string[] = [];

    let start = reticle.startY - reticleSize.y + 1;
    let end = reticle.endY;

    if (direction === 'above' || direction === 'below') {
      start = reticle.startX - reticleSize.x + 1;
      end = reticle.endX;
    }

    for (let i = start; i <= end; i += 1) {
      let startX = reticle.endX + 1;
      let startY = i;
      if (direction === 'left') {
        startX = reticle.startX - 1 - (reticleSize.x - 1);
        startY = i;
      } else if (direction === 'below') {
        startX = i;
        startY = reticle.endY + 1;
      } else if (direction === 'above') {
        startX = i;
        startY = reticle.startY - 1 - (reticleSize.y - 1);
      }
      possibleReticlesOnSide.push(JSON.stringify({
        startX, startY, endX: startX + reticleSize.x - 1, endY: startY + reticleSize.y - 1,
      }));
    }

    let reticlesOnSideCount = 0;
    for (let i = 0; i < possibleReticlesOnSide.length; i += 1) {
      if (possibleReticlesOnSide[i] in reticleGridRectCoords) {
        reticlesOnSideCount += 1;
        const otherReticle: RectCoord = JSON.parse(possibleReticlesOnSide[i]);
        const otherReticleZoneInfo: number | null = this.getZoneInfoOfDiesWithinReticle(otherReticle, rowOffset, colOffset, dies, selectedZoneId);
        if (otherReticleZoneInfo !== currReticleZoneInfo) {
          const instersectingInterval: { start: number | null, end: number | null } = this.getIntersectingInterval(
            {
              start: direction === 'right' || direction === 'left' ? reticle.startY : reticle.startX,
              end: direction === 'right' || direction === 'left' ? reticle.endY : reticle.endX,
            },
            {
              start: direction === 'right' || direction === 'left' ? otherReticle.startY : otherReticle.startX,
              end: direction === 'right' || direction === 'left' ? otherReticle.endY : otherReticle.endX,
            },
          );
          if (instersectingInterval.start !== null && instersectingInterval.end !== null) {
            for (let j = instersectingInterval.start; j <= instersectingInterval.end; j += 1) {
              if (direction === 'right') {
                coords[JSON.stringify({ x: otherReticle.startX, y: j })] = true;
              } else if (direction === 'left') {
                coords[JSON.stringify({ x: reticle.startX, y: j })] = true;
              } else if (direction === 'below') {
                coords[JSON.stringify({ x: j, y: otherReticle.startY })] = true;
              } else if (direction === 'above') {
                coords[JSON.stringify({ x: j, y: reticle.startY })] = true;
              }
            }
          }
        }
      }
    }

    if (reticlesOnSideCount === 0) {
      start = reticle.startY;
      end = reticle.endY;
      if (direction === 'above' || direction === 'below') {
        start = reticle.startX;
        end = reticle.endX;
      }
      for (let i = start; i <= end; i += 1) {
        if (direction === 'right' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: reticle.endX + 1, y: i })] = true;
        } else if (direction === 'left' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: reticle.startX, y: i })] = true;
        } else if (direction === 'below' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: i, y: reticle.endY + 1 })] = true;
        } else if (direction === 'above' && currReticleZoneInfo !== null) {
          coords[JSON.stringify({ x: i, y: reticle.startY })] = true;
        }
      }
    }
    return coords;
  };

  performActionApplyGroupedZone = (data: { action: ActionOnWaferData, config: any }) => {
    const {
      zones, waferData, reticleGridRectCoords, colOffset, rowOffset, reticleSize, scaledDieHeight, scaledDieWidth, wcmWaferDiameter, wcmWaferEdgeExclusion,
      xAnchor, yAnchor, dieHeightToStreetHeightRatio, dieWidthToStreetWidthRatio, dieTypes,
    } = this.waferMapVariables;
    const {
      widthOfBg, heightOfBg, centerOfBG,
    } = this.getWaferWidthHeightDiffInfo(xAnchor, yAnchor, scaledDieWidth, scaledDieHeight);

    const waferRadiusExcludingEdgeExclusion = (((wcmWaferDiameter - 2 * wcmWaferEdgeExclusion) / wcmWaferDiameter)) * (Math.max(widthOfBg, heightOfBg) / 2);

    const filteredZones = zones.filter((z: ZoneData) => { return z.id === data.config.selectedZoneId; });
    if (filteredZones.length === 0) return { data: waferData, action: data.action };
    const currZone = filteredZones[0];

    const markedWatPCMSiteReticles: ReticleGridRectCoords = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      if (reticleGridRectCoords[reticleKey].watPCMSiteNumber !== null) {
        markedWatPCMSiteReticles[reticleKey] = _.cloneDeep(reticleGridRectCoords[reticleKey]);
      }
    }

    if (Object.keys(markedWatPCMSiteReticles).length === 0) {
      return { data: waferData, action: data.action };
    }

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      const reticle: RectCoord = JSON.parse(reticleKey);
      const reticleCenter = { x: (reticle.startX + reticle.endX) / 2, y: (reticle.startY + reticle.endY) / 2 };
      let minimumDistance = Number.MAX_SAFE_INTEGER;
      let closestPCMSiteNumber = null;
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const markedReticleKey in markedWatPCMSiteReticles) {
        const markedReticle: RectCoord = JSON.parse(markedReticleKey);
        const markedReticleCenter = { x: (markedReticle.startX + markedReticle.endX) / 2, y: (markedReticle.startY + markedReticle.endY) / 2 };

        const distance = UtilityFunctions.euclideanDistance(markedReticleCenter, reticleCenter);
        if (distance < minimumDistance) {
          minimumDistance = distance;
          closestPCMSiteNumber = markedWatPCMSiteReticles[markedReticleKey].watPCMSiteNumber;
        }
      }
      if (closestPCMSiteNumber !== null) {
        for (let l = reticle.startY - rowOffset; l <= reticle.endY - rowOffset; l += 1) {
          for (let m = reticle.startX - colOffset; m <= reticle.endX - colOffset; m += 1) {
            if (waferData.dies[l] && waferData.dies[l][m]
              && this.isDieEligibleForMarkingZone(
                waferRadiusExcludingEdgeExclusion,
                waferData.dies[l][m]!,
                rowOffset,
                colOffset,
                scaledDieWidth,
                scaledDieHeight,
                dieWidthToStreetWidthRatio,
                dieHeightToStreetHeightRatio,
                xAnchor,
                yAnchor,
                centerOfBG,
                dieTypes,
              )) {
              if (!waferData.dies[l][m]!.zoneInfo) {
                waferData.dies[l][m]!.zoneInfo = {};
              }
              waferData.dies[l][m]!.zoneInfo![data.config.selectedZoneId] = [closestPCMSiteNumber];
            }
          }
        }
      }
    }

    let ycoords: { [key: string]: boolean } = {};
    let xcoords: { [key: string]: boolean } = {};

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const reticleKey in reticleGridRectCoords) {
      const reticle: RectCoord = JSON.parse(reticleKey);
      const currReticleZoneInfo: number | null = this.getZoneInfoOfDiesWithinReticle(reticle, rowOffset, colOffset, waferData.dies, data.config.selectedZoneId);
      ycoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('right', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferData.dies, data.config.selectedZoneId),
        ...ycoords,
      };
      ycoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('left', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferData.dies, data.config.selectedZoneId),
        ...ycoords,
      };
      xcoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('below', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferData.dies, data.config.selectedZoneId),
        ...xcoords,
      };
      xcoords = {
        ...this.getZoneBoundaryCoordsForGroupedZone('above', reticle, reticleSize, reticleGridRectCoords, currReticleZoneInfo, rowOffset, colOffset, waferData.dies, data.config.selectedZoneId),
        ...xcoords,
      };
    }
    currZone.ycoords = Object.keys(ycoords).map((coord: string) => { return JSON.parse(coord); });
    currZone.xcoords = Object.keys(xcoords).map((coord: string) => { return JSON.parse(coord); });
    return { data: waferData, action: data.action };
  };

  performActionDeleteSelectedDies = (data: { action: ActionOnWaferData, config: any }) => {
    const { outerViewPort } = this.waferMapVariables;
    for (let i = 0; i < this.waferMapVariables.waferData.waferMaxRows; i += 1) {
      for (let j = 0; j < this.waferMapVariables.waferData.waferMaxCols; j += 1) {
        if (this.waferMapVariables.waferData.dies[i][j] && this.waferMapVariables.waferData.dies[i][j]?.isSelected) {
          this.waferMapVariables.waferData.dies[i][j] = null;
        }
      }
    }
    this.waferMapVariables.onSetOuterViewport(outerViewPort);
    this.waferMapVariables.processData();
    this.renderWafer();
    return { data: this.waferMapVariables.waferData, action: data.action };
  };

  performActionAndSendResponse = (data: { action: ActionOnWaferData, config: any }) => {
    const { keyIndex } = this.waferMapVariables;
    let publishData: any = {};
    switch (data.action) {
      case ActionOnWaferData.CROP:
        publishData = this.performActionCrop(data);
        break;
      case ActionOnWaferData.APPLY_RETICLE:
        publishData = this.performActionApplyReticle(data);
        break;
      case ActionOnWaferData.DEFAULT:
        publishData = this.performActionDefault(data);
        break;
      case ActionOnWaferData.REDRAW:
        publishData = this.performActionRedraw(data);
        break;
      case ActionOnWaferData.CROP_SELECTED_RETICLES_ON_WAFER:
        publishData = this.performActionCropSelectedReticlesOnWafer(data);
        break;
      case ActionOnWaferData.PAN_RETICLE:
        publishData = this.performActionPanReticle(data);
        break;
      case ActionOnWaferData.SHIFT_INSERT_RETICLE:
        publishData = this.performActionInsertReticle(data);
        break;
      case ActionOnWaferData.APPLY_FULL_WAT_MAP:
        publishData = this.performActionApplyFullWATMap(data);
        break;
      case ActionOnWaferData.APPLY_RADIAL_ZONE:
        publishData = this.performActionApplyRadialZone(data);
        break;
      case ActionOnWaferData.APPLY_VERTICAL_ZONE:
        publishData = this.performActionApplyVerticalZone(data);
        break;
      case ActionOnWaferData.APPLY_HORIZONTAL_ZONE:
        publishData = this.performActionApplyHorizontalZone(data);
        break;
      case ActionOnWaferData.APPLY_GROUPED_PER_SITE_ZONE:
        publishData = this.performActionApplyGroupedZone(data);
        break;
      case ActionOnWaferData.SHIFT_INSERT_DIES:
        publishData = this.performActionShiftInsertDies(data);
        break;
      case ActionOnWaferData.INSERT_ROW_COL:
        publishData = this.performActionInsertRowCol(data);
        break;
      case ActionOnWaferData.DELETE_ROW_COL:
        publishData = this.performActionDeleteRowCol(data);
        break;
      case ActionOnWaferData.DELETE_SELECTED_DIES:
        publishData = this.performActionDeleteSelectedDies(data);
        break;
      case ActionOnWaferData.SHIFT_DELETE_DIES:
        publishData = this.performActionShiftDeleteDies(data);
        break;
      default:
        publishData = this.performActionDefault(data);
        break;
    }
    if (publishData) {
      publishData.config = data.config;
      this.renderWafer();
      const ps = PublishSubscribe();
      ps.publishWithOthersID(EventTypes.WAFER_DATA_RESPONSE, publishData, keyIndex.toString());
    }
  };

  getDiesList = () => {
    const {
      waferData, reticleXAxisReference, reticleYAxisReference, reticleSize, overlayReticle, dieTypes,
    } = this.waferMapVariables;
    const maxRowsAndCols = UtilityFunctions.getMaxRowsAndCols(waferData.dies);
    waferData.waferMaxCols = maxRowsAndCols.maxCols;
    waferData.waferMaxRows = maxRowsAndCols.maxRows;

    const dies: { [key: string]: any } [] = [];
    for (let i = 0; i < waferData.waferMaxRows; i += 1) {
      for (let j = 0; j < waferData.waferMaxCols; j += 1) {
        if (waferData.dies[i][j]) {
          const dieData = waferData.dies[i][j]!;
          let reticleRow = null;
          let reticleCol = null;
          let reticleSubRow = null;
          let reticleSubCol = null;
          if (overlayReticle) {
            reticleRow = dieData.y! < reticleYAxisReference ? Math.ceil((reticleYAxisReference - dieData.y!) / reticleSize.y) : Math.floor((dieData.y! - reticleYAxisReference) / reticleSize.y);
            reticleCol = dieData.x! < reticleXAxisReference ? Math.ceil((reticleXAxisReference - dieData.x!) / reticleSize.x) : Math.floor((dieData.x! - reticleXAxisReference) / reticleSize.x);
            reticleSubRow = dieData.reticleY === undefined ? null : dieData.reticleY;
            reticleSubCol = dieData.reticleX === undefined ? null : dieData.reticleX;
          }
          dies.push({
            zoneInfo: dieData.zoneInfo === undefined ? null : dieData.zoneInfo,
            dieXCalculation: dieData.x,
            dieYCalculation: dieData.y,
            dieX: dieData.xTrue,
            dieY: dieData.yTrue,
            reticleRow,
            reticleCol,
            reticleSubRow,
            reticleSubCol,
            dieRow: i,
            dieCol: j,
            isDieNull: false,
            isDeleted: dieData.isDeleted,
            isCropped: dieData.isCropped,
            dieType: dieData.dieType ? UtilityFunctions.getDieTypeFromId(dieData.dieType, dieTypes.dieType) : null,
          });
        } else {
          dies.push({
            zoneInfo: null,
            dieXCalculation: -1,
            dieYCalculation: -1,
            dieX: -1,
            dieY: -1,
            reticleRow: null,
            reticleCol: null,
            reticleSubRow: null,
            reticleSubCol: null,
            dieRow: i,
            dieCol: j,
            isDieNull: true,
            isDeleted: false,
            isCropped: false,
            dieType: null,
          });
        }
      }
    }
    return dies;
  };

  getReticlesList = () => {
    const { reticleGridRectCoords, referenceReticleGridRectCoords } = this.waferMapVariables;
    const reticles: { [key: string]: any } [] = [];
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in reticleGridRectCoords) {
      const reticleCoords: RectCoord = JSON.parse(key);
      reticles.push({
        startX: reticleCoords.startX,
        startY: reticleCoords.startY,
        endX: reticleCoords.endX,
        endY: reticleCoords.endY,
        wATPCMSiteNumber: reticleGridRectCoords[key].watPCMSiteNumber,
        isReferenceReticle: false,
      });
    }
    if (referenceReticleGridRectCoords) {
      reticles.push({
        startX: referenceReticleGridRectCoords.startX,
        startY: referenceReticleGridRectCoords.startY,
        endX: referenceReticleGridRectCoords.endX,
        endY: referenceReticleGridRectCoords.endY,
        wATPCMSiteNumber: null,
        isReferenceReticle: true,
      });
    }
    return reticles;
  };

  toggleSelectionOnWaferFromArrayIndices = (arrayIndices: RectCoord, getSelectionInfo = false) => {
    const {
      startRow, startCol, rowDirection, colDirection, waferData,
    } = this.waferMapVariables;
    const selectedData: DieData[] = [];
    const unSelectedData: DieData[] = [];
    for (let i = arrayIndices.startY; i <= arrayIndices.endY; i += 1) {
      for (let j = arrayIndices.startX; j <= arrayIndices.endX; j += 1) {
        const rIndex = startRow + rowDirection * i;
        const cIndex = startCol + colDirection * j;
        const dieData = waferData.dies[rIndex] === undefined ? undefined : waferData.dies[rIndex][cIndex];
        if (dieData && !dieData.isDeleted && !dieData.isCropped) {
          // eslint-disable-next-line no-lonely-if
          if (!dieData.isSelected) {
            dieData.isSelected = true;
            if (getSelectionInfo) selectedData.push(_.cloneDeep(dieData));
          } else {
            dieData.isSelected = false;
            if (getSelectionInfo) unSelectedData.push(_.cloneDeep(dieData));
          }
        }
      }
    }
    return { selectedData, unSelectedData };
  }

  clearSelectionActionHelper = (selectionMatrixIndices: { r: number, c: number }[], value: boolean) => {
    const { waferData } = this.waferMapVariables;
    for (let i = 0; i < selectionMatrixIndices.length; i += 1) {
      const { r, c } = selectionMatrixIndices[i];
      if (waferData.dies[r][c] !== null) {
        waferData.dies[r][c]!.isSelected = value;
      }
    }
  }

  selectionAction = (data: { arrayIndices: RectCoord }) => {
    this.toggleSelectionOnWaferFromArrayIndices(data.arrayIndices);
    this.renderWafer();
  }

  selectionActionUndo = (data: { arrayIndices: RectCoord }) => {
    this.toggleSelectionOnWaferFromArrayIndices(data.arrayIndices);
    this.renderWafer();
  }

  clearSelectionAction = (data: { selectionMatrixIndices: { r: number, c: number }[] }) => {
    this.clearSelectionActionHelper(data.selectionMatrixIndices, false);
    this.renderWafer();
  }

  clearSelectionActionUndo = (data: { selectionMatrixIndices: { r: number, c: number }[] }) => {
    this.clearSelectionActionHelper(data.selectionMatrixIndices, true);
    this.renderWafer();
  }

  applyOffsetsToDieData = (coordinateSystems: string[], subRowOffset: number, subColOffset: number) => {
    const {
      rowFlip, rowAxisStart, rowAxisIncrement, rowAxisDirection, rowOffset,
      colFlip, colAxisStart, colAxisIncrement, colAxisDirection, colOffset,
      waferData,
    } = this.waferMapVariables;
    for (let i = 0; i < waferData.dies.length; i += 1) {
      for (let j = 0; j < waferData.dies[i].length; j += 1) {
        const dieData = waferData.dies[i][j];
        if (dieData) {
          if (coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
            dieData.y = UtilityFunctions.getDieXOrY(
              rowFlip === RowFlip.Upright,
              rowAxisStart,
              rowAxisIncrement,
              rowAxisDirection === RowAxisDirection.TopToBottom,
              i,
              rowOffset,
              waferData.waferMaxRows,
            );
            dieData.x = UtilityFunctions.getDieXOrY(
              colFlip === ColFlip.Upright,
              colAxisStart,
              colAxisIncrement,
              colAxisDirection === ColAxisDirection.LeftToRight,
              j,
              colOffset,
              waferData.waferMaxCols,
            );
            dieData.xTrue = UtilityFunctions.getDieTrueXOrY(dieData.x, colAxisDirection === ColAxisDirection.LeftToRight, waferData.waferMaxCols, colOffset);
            dieData.yTrue = UtilityFunctions.getDieTrueXOrY(dieData.y, rowAxisDirection === RowAxisDirection.TopToBottom, waferData.waferMaxRows, rowOffset);
          }
          if (coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE) && dieData.reticleX !== undefined && dieData.reticleY !== undefined) {
            dieData.reticleX += subColOffset;
            dieData.reticleY += subRowOffset;
          }
        }
      }
    }
  };

  applyOffsetsToReticleData = (coordinateSystems: string[], deltaRowOffset: number, deltaColOffset: number, reticleRow: number, reticleCol: number) => {
    const { reticleGridRectCoords, referenceReticleGridRectCoords } = this.waferMapVariables;
    const reticles = Object.keys(reticleGridRectCoords);
    const newReticleGridRectCoords: ReticleGridRectCoords = {};
    for (let i = 0; i < reticles.length; i += 1) {
      const reticleCoords: RectCoord = JSON.parse(reticles[i]);
      const newReticleCoords: RectCoord = {
        startX: reticleCoords.startX + deltaColOffset,
        startY: reticleCoords.startY + deltaRowOffset,
        endX: reticleCoords.endX + deltaColOffset,
        endY: reticleCoords.endY + deltaRowOffset,
      };
      newReticleGridRectCoords[JSON.stringify(newReticleCoords)] = _.cloneDeep(reticleGridRectCoords[reticles[i]]);
    }
    this.waferMapVariables.reticleGridRectCoords = newReticleGridRectCoords;

    if (referenceReticleGridRectCoords) {
      referenceReticleGridRectCoords.startX += deltaColOffset;
      referenceReticleGridRectCoords.startY += deltaRowOffset;
      referenceReticleGridRectCoords.endX += deltaColOffset;
      referenceReticleGridRectCoords.endY += deltaRowOffset;
    }
    // eslint-disable-next-line no-param-reassign
    this.waferMapVariables.reticleXAxisReference = this.waferMapVariables.getReticleAxisReference('x', false, reticleCol);
    // eslint-disable-next-line no-param-reassign
    this.waferMapVariables.reticleYAxisReference = this.waferMapVariables.getReticleAxisReference('y', false, reticleRow);
  };

  applyOffsetsToData = (data: HomeDieCoordinateSystemFormData, homeDie: DieData) => {
    let subRowOffset = 0;
    let subColOffset = 0;

    let reticleRowOffset = 0;
    let reticleColOffset = 0;
    const rowColAxisDirection = UtilityFunctions.getColAndRowAxisDirection(data.waferAxisDirection);
    this.waferMapVariables.colAxisDirection = rowColAxisDirection.colAxisDirection;
    this.waferMapVariables.rowAxisDirection = rowColAxisDirection.rowAxisDirection;
    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.STANDARD)) {
      if (homeDie.x === undefined || homeDie.y === undefined) return;

      const newColOffset = rowColAxisDirection.colAxisDirection === ColAxisDirection.RightToLeft
        ? data.standardX - this.waferMapVariables.waferData.waferMaxCols + 1 + homeDie.x - this.waferMapVariables.colOffset
        : data.standardX - homeDie.x + this.waferMapVariables.colOffset;
      const deltaColOffset = newColOffset - this.waferMapVariables.colOffset;

      const newRowOffset = rowColAxisDirection.rowAxisDirection === RowAxisDirection.BottomToTop
        ? data.standardY - this.waferMapVariables.waferData.waferMaxRows + 1 + homeDie.y - this.waferMapVariables.rowOffset
        : data.standardY - homeDie.y + this.waferMapVariables.rowOffset;
      const deltaRowOffset = newRowOffset - this.waferMapVariables.rowOffset;

      reticleRowOffset += deltaRowOffset;
      reticleColOffset += deltaColOffset;

      this.waferMapVariables.colOffset += deltaColOffset;
      this.waferMapVariables.rowOffset += deltaRowOffset;
    }

    if (data.coordinateSystems.includes(COORDINATE_SYSTEMS.RETICLE)) {
      if (homeDie.reticleX === undefined || homeDie.reticleY === undefined) return;
      subRowOffset = data.subRow - homeDie.reticleY;
      subColOffset = data.subCol - homeDie.reticleX;
      reticleRowOffset -= subRowOffset;
      reticleColOffset -= subColOffset;
    }

    this.applyOffsetsToDieData(data.coordinateSystems, subRowOffset, subColOffset);
    this.applyOffsetsToReticleData(data.coordinateSystems, reticleRowOffset, reticleColOffset, data.row, data.col);
  };

  markDieAction = (data: { markedDieTypeData: MarkedDieTypeData }) => {
    const {
      startRow, startCol, rowDirection, colDirection, waferData, dieTypes
    } = this.waferMapVariables;
    for (let i = 0; i < data.markedDieTypeData.markedDies.length; i += 1) {
      const rIndex = startRow + rowDirection * data.markedDieTypeData.markedDies[i].r;
      const cIndex = startCol + colDirection * data.markedDieTypeData.markedDies[i].c;
      const dieData = waferData.dies[rIndex] === undefined ? undefined : waferData.dies[rIndex][cIndex];
      if (dieData && !dieData.isCropped && !dieData.isDeleted) {
        const changeKeys = Object.keys(data.markedDieTypeData.markedDies[i].changes);
        for (let j = 0; j < changeKeys.length; j += 1) {
          dieData[changeKeys[j]] = data.markedDieTypeData.markedDies[i].changes[changeKeys[j]].newVal;
        }
      }
    }
    if (data.markedDieTypeData.diePrevData) {
      this.applyOffsetsToData(data.markedDieTypeData.newHomeDieFormData!, data.markedDieTypeData.diePrevData!);
    }
    if (data.markedDieTypeData.oldHomeDieMatrixIndices) {
      // eslint-disable-next-line max-len
      waferData.dies[data.markedDieTypeData.oldHomeDieMatrixIndices.i][data.markedDieTypeData.oldHomeDieMatrixIndices.j]!.dieType = UtilityFunctions.getDieTypeIdFromName('Unassigned', dieTypes.dieType)
    }
    this.renderWafer();
  }

  markDieActionUndo = (data: { markedDieTypeData: MarkedDieTypeData }) => {
    const {
      startRow, startCol, rowDirection, colDirection, waferData, dieTypes,
    } = this.waferMapVariables;

    if (data.markedDieTypeData.dieNewData) {
      this.applyOffsetsToData(data.markedDieTypeData.oldHomeDieFormData!, data.markedDieTypeData.dieNewData!);
    }

    for (let i = 0; i < data.markedDieTypeData.markedDies.length; i += 1) {
      const rIndex = startRow + rowDirection * data.markedDieTypeData.markedDies[i].r;
      const cIndex = startCol + colDirection * data.markedDieTypeData.markedDies[i].c;
      const dieData = waferData.dies[rIndex] === undefined ? undefined : waferData.dies[rIndex][cIndex];
      if (dieData && !dieData.isCropped && !dieData.isDeleted) {
        const changeKeys = Object.keys(data.markedDieTypeData.markedDies[i].changes);
        for (let j = 0; j < changeKeys.length; j += 1) {
          dieData[changeKeys[j]] = data.markedDieTypeData.markedDies[i].changes[changeKeys[j]].prevVal;
        }
      }
    }
    if (data.markedDieTypeData.oldHomeDieMatrixIndices) {
      waferData.dies[data.markedDieTypeData.oldHomeDieMatrixIndices.i][data.markedDieTypeData.oldHomeDieMatrixIndices.j]!.dieType = UtilityFunctions.getDieTypeIdFromName('Home Die', dieTypes.dieType)
    }
    this.renderWafer();
  }
}
export default WaferUtils;
