import React from 'react';
import { Container, Col, Row, } from 'react-bootstrap';
import { faCheck, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CodeEditor from 'components/utility-component/code-editor/CodeEditor';
import { Multiselect } from 'multiselect-react-dropdown';
import { emptyDefaultSelectionCriteria, IDefaultSelectionCriteria } from 'components/selection-criteria/SelectionCriteriaTypes';
import Button from '../../wrapped-component/hint-controls/Button';
import PageHeader from '../../template-component/page-header/PageHeader';
import CustomizedDropdown from '../../wrapped-component/customized-dropdown/CustomizedDropdown';
import AppConstants from '../../../constants.js';
import { addErrorClasses, ErrorLabel } from '../../template-component/form-error-label/ErrorLabel';
import { WaferReferences, IdefaultWidget } from '../../../interfaces';
import { httpService } from '../../../services/http.service';
import Heading from '../../wrapped-component/hint-controls/Heading';
import SelectionCriteriaModal from '../../selection-criteria/selection-criteria-modal';
import Checkbox from '../../wrapped-component/hint-controls/Checkbox';
import './create-swm-rule.scss';

interface ICreateSWMRule {
  closeRuleComponent: () => void;
  id?: string;
  policyStepSequence?:number;
  getSavedRule?: (sequence:number, ruleId:string) => void;
  sources?: string[][];
}

class CreateSWMRule extends React.Component<ICreateSWMRule, any> {
  static defaultProps: Partial<ICreateSWMRule> = {
    sources: [['Source1', 'Source1'], ['Source2', 'Source2']],
  };

  private widgetsList : IdefaultWidget[] = [
    {
      controlType: 'Facility',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'facility') || {} as IDefaultSelectionCriteria,
    },
    {
      controlType: 'Work Center',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'work_center') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Device',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'device') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Test Program',
      columnWeight: 3,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'test_program') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Test Program Revision',
      columnWeight: 3,
      isVisible: true,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'test_program_revision') || {} as IDefaultSelectionCriteria,
    },
    {
      controlType: 'Lot',
      columnWeight: 4,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'lot') || {} as IDefaultSelectionCriteria,

    },
    {
      controlType: 'Wafer',
      columnWeight: 8,
      defaultSelectionCriteria: emptyDefaultSelectionCriteria.find((x) => x.entityType === 'wafer') || {} as IDefaultSelectionCriteria,

    },
  ];

  ;

  private defaultStatement = {
    condition: '',
    conditionError: undefined,
    thenStatements: [''],
    thenStatementErrors: [undefined],
    elseStatements: [''],
    elseStatementErrors: [undefined],
    thenFieldsData: WaferReferences[0][0],
    thenParametricData: [{ key: 'NONE', value: 'NONE' }], // ParametricReferences[0][0],
    elseFieldsData: WaferReferences[0][0],
    elseParametricData: [{ key: 'NONE', value: 'NONE' }], // ParametricReferences[0][0],
    shouldExitAfterThen: false,
    shouldExitAfterElse: false,
  };

  constructor(props:ICreateSWMRule) {
    super(props);
    this.ruleCoverage = this.ruleCoverage.bind(this);
    this.renderConditionStatements = this.renderConditionStatements.bind(this);

    this.state = {
      editRule: {
        Id: '',
        name: '',
        shortDescription: '',
        description: '',
        version: 'v1.0',
      },
      ruleCoveragePercentage: undefined,
      statements: [],
      sourceProperties: this.getCodeEditorOptions(),
      thenSourceProperties: this.getCodeEditorOptions(true),
      showModal: false,
      showModal2: false,
      coverageWafer1: undefined,
      coverageWafer2: undefined,
      showCoverage: false,
    };
    this.getRule();
    // this.getCodeEditorOptions();
  }

  getCodeEditorOptions = (then = false) => {
    const { sources } = this.props;
    const dieFields = ['SoftBinNumber', 'IsPassing', 'HardBinNumber', 'Parameters', 'Facility', 'WorkCenter', 'Device', 'TestProgram'];
    const options: any[] = [];
    options.push({ regex: /^$/, values: { type: 'list', values: [...sources!.map((x) => x[0]), 'target'] } });
    sources!.forEach((x: string[]) => options.push({
      regex: new RegExp(`${x[0]}\\.$`, 'i'),
      // eslint-disable-next-line max-len
      values: { type: 'groupedList', values: [{ key: 'Fields', items: dieFields }, { key: 'Methods', items: ['Equals'] }] },
    }));
    options.push({ regex: /target\.$/i, values: { type: 'list', values: dieFields } });
    sources!.forEach((x: string[]) => options.push({
      regex: new RegExp(`${x[0]}\\.Parameters\\["$`, 'i'),
      // eslint-disable-next-line max-len,object-curly-newline
      values: { type: 'grid', values: { fields: [{ caption: 'Test #', dataField: 'number', dataType: 'string', showInfo: false }, { caption: 'Test Name', dataField: 'name', dataType: 'string', showInfo: false }, { caption: 'Ctrl High', dataField: 'ctrlHigh', dataType: 'string', showInfo: false }, { caption: 'Ctrl Low', dataField: 'ctrlLow', dataType: 'string', showInfo: false }, { caption: 'Spec High', dataField: 'specHigh', dataType: 'string', showInfo: false }, { caption: 'Spec Low', dataField: 'specLow', dataType: 'string', showInfo: false }, { caption: 'Test Unit', dataField: 'testUnit', dataType: 'string', showInfo: false }, { caption: 'Res Scale', dataField: 'resScale', dataType: 'string', showInfo: false }], datasource: [{ number: '1.2', name: 'Hot', ctrlHigh: 1.1, ctrlLow: 1.1, specHigh: 1.1, specLow: 1.1, testUnit: 'V', resScale: 3 }, { number: '2.7', name: 'Cold', ctrlHigh: 1.1, ctrlLow: 1.1, specHigh: 1.1, specLow: 1.1, testUnit: 'V', resScale: 3 }], filterValue: 'name' } },
    }));
    options.push(
      { regex: /[A-Za-z0-9]+\s$/i, values: { type: 'groupedList', values: [{ key: 'Fields', items: [...sources!.map((x) => x[0]), 'target'] }, { key: 'Operations', items: ['*', '==', '+'] }] } },
      { regex: /Math\.$/i, values: { type: 'list', values: ['Pow(value, power)', 'Sqrt(value)'] } },
      { regex: /\s$/i, values: { type: 'list', values: [...sources!.map((x) => x[0]), 'target'] } },
      { regex: /Facility\.$/i, values: { type: 'list', values: ['StartsWith("str")'] } },
    );
    // this.setState({
    //   sourceProperties: options,
    //   thenSourceProperties: [
    //     {
    //       regex: /^$/,
    //       // eslint-disable-next-line max-len
    //       values: { type: 'groupedList', values: [{ key: 'Variables', items: [...sources!.map((x) => x[0]), 'target'] }, { key: 'Methods', items: ['AddParameter("name", number, ctrlHigh, ctrlLow, specHigh, specLow, testUnit, resScale, expression)', 'CopySource1Metadata()', 'CopySource2Metadata()', 'CopySource1ParametricData()', 'CopySource2ParametricData()', 'CopyBothParametricData()'] }] },
    //     },
    //     ...options,
    //   ],
    // });
    if (then) {
      return [
        {
          regex: /^$/,
          // eslint-disable-next-line max-len
          values: { type: 'groupedList', values: [{ key: 'Variables', items: [...sources!.map((x) => x[0]), 'target'] }, { key: 'Methods', items: ['AddParameter("name", number, ctrlHigh, ctrlLow, specHigh, specLow, testUnit, resScale, expression)', 'CopySource1Metadata()', 'CopySource2Metadata()', 'CopySource1ParametricData()', 'CopySource2ParametricData()', 'CopyBothParametricData()'] }] },
        },
        ...options,
      ];
    }
    return options;
  };

  getRule = async () => {
    const { id } = this.props;
    const { editRule, statements } = this.state;
    if (id !== undefined) {
      const data = await httpService.getSWMRule(id);
      editRule.Id = data.id;
      editRule.name = data.name;
      editRule.shortDescription = data.shortDescription;
      editRule.description = data.description;
      editRule.version = data.version;
      editRule.createdBy = data.createdBy !== null ? `${data.createdBy.firstName} ${data.createdBy.lastName}` : '';
      editRule.createdOn = data.createdOn;
      editRule.updatedBy = data.updatedBy !== null ? `${data.updatedBy.firstName} ${data.createdBy.lastName}` : '';
      editRule.updatedOn = data.updatedOn;
      data.statements.sort((a: any, b: any) => a.sequence - b.sequence).forEach((x: any) => {
        const statement = JSON.parse(JSON.stringify(this.defaultStatement));
        statement.condition = x.condition;
        // //     conditionError: undefined,
        statement.thenStatements = x.thenStatements;
        statement.thenStatementErrors = x.thenStatements.map(() => undefined);
        statement.elseStatements = x.elseStatements;
        statement.elseStatementErrors = x.elseStatements.map(() => undefined);
        statement.thenFieldsData = x.thenFieldsData;
        statement.thenParametricData = x.thenParametricData;
        statement.elseFieldsData = x.elseFieldsData;
        statement.elseParametricData = x.elseParametricData;
        statement.shouldExitAfterThen = x.shouldExitAfterThen;
        statement.shouldExitAfterElse = x.shouldExitAfterElse;
        statements.push(statement);
      });
    } else {
      statements.push(JSON.parse(JSON.stringify(this.defaultStatement)));
    }
    this.setState({ editRule, statements });
  };

  ruleCoverage = () => {
    const ruleCoveragePercentage = (
      <div className="d-flex mt10">
        <h6 className="ml20 color-success">Coverage: 34%</h6>
        <span className="ml20 color-icon-color">Total Dies: 192</span>
        <span className="ml20 color-icon-color">Dies Covered: 65</span>
        <span className="ml20 color-icon-color">Dies Excluded: 127</span>
      </div>
    );
    this.setState({ ruleCoveragePercentage });
    // const {
    //   statements,
    //   coverageWafer1,
    //   coverageWafer2,
    // } = this.state;
    // httpService.swmRuleCoverage({
    //   rule: { statements },
    //   // source1Wafer: '7',
    //   // source2Wafer: '8',
    //   source1Wafer: coverageWafer1.id,
    //   source2Wafer: coverageWafer2.id,
    // }).then((response) => {
    //   if (response.response.state === 'COVERAGE') {
    //     this.setState({ ruleCoveragePercentage: 'Please wait a moment' });
    //   }
    //   this.waitForRuleCoverage(response.response.policyId);
    // });
  };

  waitForRuleCoverage = async (policyId: string, retryCount = 0) => {
    if (retryCount > 5) {
      this.setState({ ruleCoveragePercentage: 'Could not calculate coverage' });
      return;
    }
    const response = await httpService.swmRuleCoverage({
      policyId,
    });
    if (response.response.state === 'COVERAGE') {
      this.setState({ ruleCoveragePercentage: `Coverage: ${response.response.coverage.toFixed(2)}%` });
    } else if (response.response.state === 'COVERAGE_NOT_EXIST') {
      await new Promise(() => setTimeout(() => this.waitForRuleCoverage(policyId, retryCount + 1), 2000));
    }
  };

  getStatementValue = (value: string) => {
    // console.log(`Condition Value: ${value}`);
    return value;
  };

  renderConditionStatements = (conditionalStatements: any) => {
    const { sourceProperties, thenSourceProperties } = this.state;
    const { sources } = this.props;
    return conditionalStatements.map((conditionalStatement: any, index: any) => (
      <div className="border-radius-5">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className="label-left"
          htmlFor="if"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            if
          </span>
          <div className="flex-60 d-flex">
            <div className="flex-90 ml-7">
              <CodeEditor
                defaultValue={this.getStatementValue(conditionalStatement.condition)}
                error={conditionalStatement.conditionError}
                placeHolder="Rule Definition"
                matchingCriterion={sourceProperties}
                updateOnBlur={(value: string) => {
                  const { statements } = this.state;
                  statements[index].condition = value;
                  this.setState({ statements });
                }}
              />
            </div>
            <div className="flex-10">
              <Button
                variant="outline-warning"
                className="w43 flex-5 float-right"
                size="lg"
                onClick={async () => {
                  const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.condition });
                  if (!response.validSyntax) {
                    const { statements } = this.state;
                    statements[index].conditionError = response.message;
                    this.setState({ statements });
                  }
                }}
              >
                <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
              </Button>
            </div>
          </div>
        </label>
        <label
          className="label-left"
          htmlFor="then"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            then
          </span>
          <div className="flex-60">
            {
              conditionalStatement.thenStatements.map((thenStatement: string, statementIndex: any) => (
                <div className="d-flex mb5">
                  <div className="flex-90 ml-7">
                    <CodeEditor
                      rows={1}
                      error={conditionalStatement.thenStatementErrors[statementIndex]}
                      defaultValue={thenStatement}
                      placeHolder="Merge Die then statement"
                      matchingCriterion={thenSourceProperties}
                      updateOnBlur={(value: string) => {
                        const { statements } = this.state;
                        statements[index].thenStatements[statementIndex] = value;
                        this.setState({ statements });
                      }}
                    />
                  </div>
                  <div className="flex-10">
                    <Button
                      variant="outline-warning"
                      className="w43 flex-5 float-right"
                      size="lg"
                      onClick={async () => {
                        const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.thenStatements[statementIndex] });
                        if (!response.validSyntax) {
                          const { statements } = this.state;
                          statements[index].thenStatementErrors[statementIndex] = response.message;
                          this.setState({ statements });
                        }
                      }}
                    >
                      <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
                    </Button>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex-20">
            <Button
              variant="outline-primary"
              className="w43 flex-5 float-right"
              size="lg"
              onClick={
                () => {
                  const { statements } = this.state;
                  statements[index].thenStatements.push('');
                  statements[index].thenStatementErrors.push(undefined);
                  this.setState({ statements });
                }
              }
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
            </Button>
          </div>
        </label>
        <div className="flex-100 d-flex">
          <div className="custom-form-inline label-left flex-80 ml-auto pl10">
            <div className="flex-10 mt5">
              <Checkbox
                defaultChecked={conditionalStatement.shouldExitAfterThen}
                onChange={(e:any) => {
                  const { statements } = this.state;
                  statements[index].shouldExitAfterThen = e.target.checked;
                  this.setState({ statements });
                }}
              >
                Exit
              </Checkbox>
            </div>
            <span className="label flex-20 pr10">Copy Metadata</span>
            <div className="flex-20 pr20">
              <CustomizedDropdown
                full
                variant="clear"
                list={sources!}
                selectedValue={conditionalStatement.thenFieldsData}
                onChange={
                  (value) => {
                    const { statements } = this.state;
                    statements[index].thenFieldsData = value;
                    this.setState({ statements });
                  }
                }
              />
            </div>
            <span className="label flex-20 pr10">Copy Parametric Data</span>
            <div className="flex-30">
              <Multiselect
                options={sources!.map((x) => {
                  return { key: x[0], value: x[1] };
                })}
                avoidHighlightFirstOption
                showCheckbox
                closeOnSelect={false}
                // selectedValues={selectedDieTypes}
                placeholder="Copy Parametric Data"
                displayValue="value"
                // onSelect={this.updateDieType}
                // onRemove={this.updateDieType}
              />
            </div>
          </div>
        </div>
        <label
          className="label-left"
          htmlFor="else"
        >
          <span className="label flex-20 align-self-flex-start mt10">
            else
          </span>
          <div className="flex-60">
            {
              conditionalStatement.elseStatements.map((elseStatement: string, statementIndex: any) => (
                <div className="d-flex mb5">
                  <div className="flex-90 ml-7">
                    <CodeEditor
                      rows={1}
                      defaultValue={elseStatement}
                      error={conditionalStatement.elseStatementErrors[statementIndex]}
                      placeHolder="Merge Die else statement"
                      matchingCriterion={thenSourceProperties}
                      updateOnBlur={(value: string) => {
                        const { statements } = this.state;
                        statements[index].elseStatements[statementIndex] = value;
                        this.setState({ statements });
                      }}
                    />
                  </div>
                  <div className="flex-10">
                    <Button
                      variant="outline-warning"
                      className="w43 flex-5 float-right"
                      size="lg"
                      onClick={async () => {
                        const response = await httpService.validateSWMRuleCondition({ condition: conditionalStatement.elseStatements[statementIndex] });
                        if (!response.validSyntax) {
                          const { statements } = this.state;
                          statements[index].elseStatementErrors[statementIndex] = response.message;
                          this.setState({ statements });
                        }
                      }}
                    >
                      <FontAwesomeIcon className="mt-10" size="sm" icon={faCheck} />
                    </Button>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex-20">
            <Button
              variant="outline-primary"
              className="w43 flex-5 float-right"
              size="lg"
              onClick={
                () => {
                  const { statements } = this.state;
                  statements[index].elseStatements.push('');
                  statements[index].elseStatementErrors.push(undefined);
                  this.setState({ statements });
                }
              }
            >
              <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
            </Button>
          </div>
        </label>
        <div className="flex-100 d-flex">
          <div className="custom-form-inline label-left flex-80 ml-auto pl10">
            <div className="flex-10 mt5">
              <Checkbox
                defaultChecked={conditionalStatement.shouldExitAfterElse}
                onChange={(e:any) => {
                  const { statements } = this.state;
                  statements[index].shouldExitAfterElse = e.target.checked;
                  this.setState({ statements });
                }}
              >
                Exit
              </Checkbox>
            </div>
            <span className="label flex-20 pr10">Copy Metadata</span>
            <div className="flex-20 pr20">
              <CustomizedDropdown
                full
                variant="clear"
                list={sources!}
                selectedValue={conditionalStatement.elseFieldsData}
                onChange={
                  (value) => {
                    const { statements } = this.state;
                    statements[index].elseFieldsData = value;
                    this.setState({ statements });
                  }
                }
              />
            </div>
            <span className="label flex-20 pr10">Copy Parametric Data</span>
            <div className="flex-30">
              <Multiselect
                options={sources!.map((x) => {
                  return { key: x[0], value: x[1] };
                })}
                // selectedValues={selectedDieTypes}
                placeholder="Copy Parametric Data"
                displayValue="value"
                onSelect={(values: any) => {
                  // console.log(values);
                }}

                // onRemove={this.updateDieType}
              />
            </div>
          </div>
        </div>
      </div>
    ));
  };

  renderCoverage() {
    const {
      showModal,
      coverageWafer1,
      showModal2,
      coverageWafer2,
      showCoverage,
      ruleCoveragePercentage,
    } = this.state;
    return (
      <>
        <div className="d-flex">
          <Button
            variant="outline-warning"
            className="mr10"
            size="lg"
            onClick={() => this.setState({ showCoverage: true })}
          >
            Check Coverage
          </Button>
          <div>{ruleCoveragePercentage}</div>
        </div>
        {showCoverage && (
          <div className="mt10 mb10">
            <div className="custom-form-inline label-left">
              <Col lg={6}>
                <div className="custom-form-inline label-left">
                  <span className="label">Source1 Wafer</span>
                  <Button
                    variant="outline-primary"
                    className="w43 ml-auto"
                    size="lg"
                    onClick={
                      () => {
                        this.setState({ showModal: true });
                      }
                    }
                  >
                    <FontAwesomeIcon className="mt-10" size="sm" icon={faEdit} />
                  </Button>
                </div>
                <div className="custom-form label-left">
                  <span className="color-icon-color">{coverageWafer1 !== undefined ? `Wafer1 Id: ${coverageWafer1.name}` : 'No source selected'}</span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="custom-form-inline label-left">
                  <span className="label">Source2 Wafer</span>
                  <Button
                    variant="outline-primary"
                    className="w43 ml-auto"
                    size="lg"
                    onClick={
                      () => {
                        this.setState({ showModal2: true });
                      }
                    }
                  >
                    <FontAwesomeIcon className="mt-10" size="sm" icon={faEdit} />
                  </Button>
                </div>
                <div className="custom-form label-left d-block">
                  <span className="color-icon-color">{coverageWafer2 !== undefined ? `Wafer2 Id: ${coverageWafer2.name}` : 'No source selected'}</span>
                </div>
              </Col>
              <SelectionCriteriaModal
                widgetsList={this.widgetsList}
                showModal={showModal}
                onModalClose={() => this.setState({ showModal: false })}
                applyCriteria={(obj: any) => {
                  const criteria = obj.waferSelected.length > 0 ? obj.waferSelected[0] : undefined;
                  this.setState({
                    showModal: false,
                    coverageWafer1: criteria,
                  });
                }}
              />
              <SelectionCriteriaModal
                widgetsList={this.widgetsList}
                showModal={showModal2}
                onModalClose={() => this.setState({ showModal2: false })}
                applyCriteria={(obj: any) => {
                  const criteria = obj.waferSelected.length > 0 ? obj.waferSelected[0] : undefined;
                  this.setState({
                    showModal2: false,
                    coverageWafer2: criteria,
                  });
                }}
              />
            </div>
            <div className="custom-form-inline label-left mt10">
              <Button
                variant="outline-primary"
                className="float-right mr10"
                size="lg"
                onClick={this.ruleCoverage}
                // disabled={!coverageWafer1 && !coverageWafer2}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }

  renderDeltaParameters(parameters: any) {
    const { sourceProperties } = this.state;
    return (
      <div className="custom-form-inline label-left">
        <span className="label flex-20"><h6>Target Parameters</h6></span>
        <div className="flex-70">
          {
            parameters.map((parameter: any, index: any) => (
              <div className="custom-form-inline">
                <input
                  defaultValue={parameter.parameter.name}
                  autoComplete="off"
                  type="text"
                  className="flex-30"
                  placeholder="Parameter Name"
                  onBlur={
                    (event: any) => {
                      const { deltaParameters } = this.state;
                      deltaParameters[index].parameter.name = event.target.value;
                      this.setState({ deltaParameters });
                    }
                  }
                />
                <input
                  defaultValue={parameter.parameter.number}
                  autoComplete="off"
                  type="text"
                  className="flex-20"
                  placeholder="Parameter Number"
                  onBlur={
                    (event: any) => {
                      const { deltaParameters } = this.state;
                      deltaParameters[index].parameter.number = event.target.value;
                      this.setState({ deltaParameters });
                    }
                  }
                />
                <div className="flex-50">
                  <CodeEditor
                    rows={1}
                    defaultValue={parameter.expression}
                    placeHolder="Parameter Definition"
                    matchingCriterion={sourceProperties}
                    updateOnBlur={(value: string) => {
                      const { deltaParameters } = this.state;
                      deltaParameters[index].expression = value;
                      this.setState({ deltaParameters });
                    }}
                  />
                </div>
              </div>
            ))
          }
        </div>
        <div className="flex-10">
          <Button
            variant="outline-primary"
            className="w43 float-right"
            size="lg"
            onClick={
              () => {
                parameters.push({ expression: '', parameter: { name: '', number: '' } });
                this.setState({ deltaParameters: parameters });
              }
            }
          >
            <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const formValidationSchema = Yup.object({
      name: Yup.string().required('Required').max(20),
      shortDescription: Yup.string().required('Required').max(1000),
      description: Yup.string().max(1000),
      version: Yup.string().required(),
    });
    const {
      editRule,
      statements,
    } = this.state;
    const { closeRuleComponent, getSavedRule, policyStepSequence } = this.props;

    return (
      <div
        className="pl40 pr20 container-primary-border-top"
      >
        <Formik
          validateOnBlur
          enableReinitialize
          initialValues={{
            name: `${editRule.name}`,
            shortDescription: `${editRule.shortDescription}`,
            description: `${editRule.description}`,
            version: 'v1.0',
          }}
          validationSchema={formValidationSchema}
          onSubmit={async (values: any) => {
            const { id } = this.props;
            const data = {
              id,
              name: values.name,
              shortDescription: values.shortDescription,
              description: values.description,
              version: values.version,
              createdBy: { Id: AppConstants.user.Id },
              updatedBy: { Id: AppConstants.user.Id },
              statements,
            };
            if (id === undefined) {
              const saveSWMResponse = await httpService.saveSWMRule({ RuleJSON: JSON.stringify(data).toString() });
              if (saveSWMResponse && saveSWMResponse !== '') {
                if (getSavedRule !== undefined && policyStepSequence !== undefined) {
                  getSavedRule(policyStepSequence, saveSWMResponse);
                }
                closeRuleComponent();
              }
            } else {
              await httpService.updateSWMRule({ RuleJSON: JSON.stringify(data).toString() });
              closeRuleComponent();
            }
          }}
        >
          {({
            handleSubmit, handleChange, values, errors, touched,
          }) => (
            <form
              onSubmit={handleSubmit}
              onBlur={handleChange}
              className="custom-form"
            >
              <PageHeader
                title={values.name === '' ? 'Create SWM Rule' : values.name}
                breadcrumbs={`Policy \\ SWM Stages \\ ${
                  values.name === '' ? 'Create SWM Rule' : values.name
                }`}
                titleActions={(
                  <div className="pt10 d-flex align-items-end">
                    <span className="pb7 mr10" style={{ color: 'grey' }}>
                      {values.version}
                    </span>
                    <div className="dropdown-badge pb7">
                      <CustomizedDropdown
                        variant="clear"
                        disabled
                        list={[['1', 'Draft'],
                          ['2', 'Active']]}
                      />
                    </div>
                  </div>
                )}
                primaryActions={(
                  <div className="d-flex align-items-center justify-content-between pr10 ml-20 pb20">
                    {/* <div className="text-right">
                      <span className="color-dark-three mt-10">Created By</span>
                      <br />
                      <span className="color-dark-three mt-10 mr7">
                        <u>{`${AppConstants.user.firstName} ${AppConstants.user.lastName}`}</u>
                      </span>
                      <span className="color-dark-three mr7 mt-10">on</span>
                      <span className="color-dark-three mt-10">
                        <u>26th Jul 2020</u>
                      </span>
                    </div> */}
                  </div>
                )}
                secondaryActions={editRule.Id !== undefined && editRule.Id !== '' ? (
                  <span className="color-primary mt-10">
                    Rule ID:
                    {' '}
                    {editRule.Id}
                  </span>
                ) : undefined}
              />
              <div className="">

                <Container fluid className="overflow-visible">
                  <Row>
                    <Col>
                      <Container className="p0">
                        <Row>
                          <Col>
                            <Heading size={6}>General</Heading>
                            <label className="label-left" htmlFor="name">
                              <span className="label flex-20">
                                Name
                                <span className="required">*</span>
                              </span>
                              <input
                                defaultValue={values.name}
                                autoComplete="off"
                                type="text"
                                className={`flex-50 ${addErrorClasses(
                                  errors.name,
                                  touched.name,
                                )}`}
                                name="name"
                                placeholder="Name"
                              />
                              <ErrorLabel
                                error={errors.name}
                                isTouched={touched.name}
                              />
                            </label>
                            {/** ---------------------------------- */}
                            <label
                              className="label-left"
                              htmlFor="shortDescription"
                            >
                              <span className="label flex-20">
                                Short Description
                                <span className="required">*</span>
                              </span>
                              <input
                                defaultValue={editRule.shortDescription}
                                autoComplete="off"
                                type="text"
                                className={`flex-50 ${addErrorClasses(
                                  errors.shortDescription,
                                  touched.shortDescription,
                                )}`}
                                name="shortDescription"
                                placeholder="Short Description"
                              />
                              <ErrorLabel
                                error={errors.shortDescription}
                                isTouched={touched.shortDescription}
                              />
                            </label>
                            {/** ---------------------------------- */}
                            <label className="label-textarea" htmlFor="description">
                              <span className="label flex-20">Description</span>
                              <textarea
                                defaultValue={editRule.description}
                                rows={3}
                                className={`flex-50 ${addErrorClasses(
                                  errors.description,
                                  touched.description,
                                )}`}
                                name="description"
                                placeholder="Add detailed notes to this rule..."
                              />
                              <ErrorLabel
                                error={errors.description}
                                isTouched={touched.description}
                              />
                            </label>
                            {/* { this.renderDeltaParameters(deltaParameters) } */}
                            {/** ---------------------------------- */}
                            <div className="custom-form-inline label-left">
                              <div className="label flex-20"><Heading size={6}>Merge Conditions</Heading></div>
                              <div className="flex-80">
                                <Button
                                  variant="outline-primary"
                                  className="w43 float-right"
                                  size="lg"
                                  onClick={
                                    () => {
                                      statements.push(JSON.parse(JSON.stringify(this.defaultStatement)));
                                      this.setState({ statements });
                                    }
                                  }
                                >
                                  <FontAwesomeIcon className="mt-10" size="sm" icon={faPlus} />
                                </Button>
                              </div>
                            </div>
                            {/** ---------------------------------- */}
                            {
                              this.renderConditionStatements(statements)
                            }
                            <br />
                            <br />
                            {this.renderCoverage()}
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid>
                <Row className="mt30 p0 pb60">
                  <Col lg={9}>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      className="mr10"
                    >
                      Save
                    </Button>
                    <Button onClick={closeRuleComponent} variant="clear" size="lg">
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CreateSWMRule;
