import React, { ReactNode } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './customized-treeview.scss';

type checkBoxModeType = 'normal' | 'selectAll' | 'none' | undefined;
type selectionModeType = 'multiple' | 'single' | undefined;

interface ITreeViewProps {
  selectionMode?: selectionModeType;
  showCheckBoxesMode?: checkBoxModeType;
  dataForTreeViewInJson: any;
  renderTreeViewItem: (item: any) => ReactNode;
  onSelectionChanged: (e: any) => void;
  width: string | number;
  height: number;
}

const CustomizedTreeView = (props: ITreeViewProps) => {
  const {
    selectionMode,
    showCheckBoxesMode,
    dataForTreeViewInJson,
    renderTreeViewItem,
    onSelectionChanged,
    width,
    height,
  } = props;

  return (
    <TreeView
      id="treeview"
      width={width}
      height={height}
      items={dataForTreeViewInJson}
      showCheckBoxesMode={showCheckBoxesMode}
      selectionMode={selectionMode}
      onSelectionChanged={(e: any) => onSelectionChanged(e)}
      itemRender={renderTreeViewItem}
    />
  );
};

CustomizedTreeView.defaultProps = {
  showCheckBoxesMode: 'normal',
  selectionMode: 'multiple',
};

export default CustomizedTreeView;
