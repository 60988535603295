/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollView } from 'devextreme-react';
import {
  EntityType, IGenericDetailedReportRequestObject, IDrilledGraphData, IGraphCoordinates, IPatLimits, GroupingSortingDTO, IHeaderSettingsRequestObj,
} from 'interfaces';
import _ from 'lodash';
import React from 'react';
import {
  Tab, Tabs, Row, Col, OverlayTrigger, Popover, Button, Modal,
} from 'react-bootstrap';
import { IFieldValue, IReportHeaderSetting } from 'components/utility-component/report-headers/ReportHeaderBuilder';
import { httpReportHeader } from 'services/http.report-header';
import ReportHeaderLandingArea from 'components/utility-component/report-headers/ReportHeaderLandingArea';
import ReportHeaderChooser from 'components/utility-component/report-headers/ReportHeaderChooser';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import WaferPlotter from 'components/utility-component/wafer-map-widget/wafer-map-v2/WaferPlotter/WaferPlotter';
import userDataUtil from 'services/user-data-util';
import GeneralUtils, { ReportTypeConstants as ReportType, ReportTypeConstants } from 'GeneralUtils';
import Textbox from 'components/wrapped-component/hint-controls/Textbox';
import WaferUtils from 'components/utility-component/wafer-map-widget/wafer-map-v2/Utils/WaferUtils';
import toast from 'CustomToast';
import PubSubBinder, { ActionType, InteractionEventType } from '../PublishSubscribeBinder';
import { IGraphDataHelper } from './customized-report-helpers/CustomizedReportHelper';
import {
  ICombinedGraphData,
  ICustomizedReportBaseProps,
  ICustomizedReportGraphTab,
  InteractionInputDTO,
  IInteractionType,
  IViewMode,
  SeriesTypeNames,
  AggregateFunctionNames,
  ICombinedGraphDataWithReportHeaders,
} from './customized-report-helpers/CustomizedReportInterfaces';
import { ReportCollectionPreferences } from './customized-report-helpers/CustomizedReportMapping';
import { IActorDetails } from './CustomizedReportsCollection';
import DrilledBinHistogramReport from './DrilledBinHistogramReport';
import InteractionIndicators from './customized-report-utils/InteractionIndicators';
import { httpSummaryStatLines } from '../../../services/http.summary-stat-lines';
import ActionItemsSheet, { ILineDict } from './customized-report-helpers/action-items-sheet/ActionItemsSheet';
import { GraphingUtils } from './customized-report-utils/GraphingUtils';
import ContextSavingSpinner from './customized-report-utils/ContextSavingSpinner';
import { IPowerViewProgressDriver } from './PowerViewProgressBar';
import AppConstants from '../../../constants';

export type InteractionMode = 'ALL' | 'SELECTED' | 'SIMILAR';
export interface ICustomizedReportModifiableOptions {
  seriesType: SeriesTypeNames,
  aggredateFunction: AggregateFunctionNames;
  testParameterIndex?: number[],
  isSoftBin: boolean,
  config: { [key: string]: any },
  limitSettingsObj?: any,
  showDies: boolean,
  showAnnotations: boolean,
  plotterPageNumber: number,
  axisBreakThresholdFactor: number,
}
export interface ICustomizedReportGraphProps
  extends ICustomizedReportBaseProps {
  selectionStoreDataSetCount?: number,
  report: IActorDetails;
  onChangeReportConfigValues: (keyValuePairs: { key: string, value: any }[], componentUnmounting?: boolean) => void;
  rulePayLoad?: string;
  testParameterIndex?: number[],
  testParameterSelectedIndex?: number,
  testParameterIndexForWhichLimitsApplied?: number,
  limitSettingsObj?: any;
  defaultGrouping?: GroupingSortingDTO;
  showDies: boolean;
  showAnnotations: boolean;
  setStateCallback: (updatedParentState: any) => void;
  loadingPriority: number;
  loadingWeight: number;
  patLimitLines: IPatLimits[];
  patParamCtrlLimitLines: IPatLimits[];
  waferSelection?: any;
  isSoftBin: boolean;
  plotterPageNumber: number;
  axisBreakThresholdFactor: number;
  setReportLoaded: () => void;
  reportIndex: number;
  updatedHeaderId?: string;
  graphData?: ICombinedGraphData;
  headerData?: IReportHeaderSetting;
  saveData?: (data: any, reportIndex: number, dataType: string) => void;
  isApplyAllReportHeaders?: boolean;
  isClosedSomeReport?: boolean;
  getBinChangeDataForBinWaferMapComparison?: (data: any) => void;
}

export interface ICustomizedReportGraphState {
  graphData: IDrilledGraphData[] | ICombinedGraphData;
  selectedPoints: { [key: string]: any };
  reportPublisherId: string;
  nticks: number;
  loading: boolean;
  toggleShowConfirmClearInteractions: boolean;
  toggleShowReportHeaderChooser: boolean;
  reportHeader: IReportHeaderSetting | undefined;
  interactionIndicator: {
    selection: boolean;
    hiding: boolean;
  };
  summaryStatLines: any[];
  lineDict: ILineDict;
  selectedSoftbinsForOverlay: string[],
  selectedWaferIdsForOverlay: string[],
  plotterPageNumber: number,
  graphIndex: number,
  numOfGraphsPerPage: number,
  widthPerGraph: number,
}

class CustomizedReportGraph extends React.Component<ICustomizedReportGraphProps, ICustomizedReportGraphState> {
  private pubSubBinder = PubSubBinder();

  private count = 0;

  private waferPlotter: WaferPlotter | null = null;

  private waferPlotterScrollView: ScrollView | null = null;

  private overlayGraphs: { [keyIndex: string]: IGraphCoordinates } = {};

  private softBins: { [sbn: string]: any } = {};

  private wafers: { waferId: string, waferKey: string }[] = [];

  private selectionData: any;

  private currentViewWidth: number;

  constructor(props: any) {
    super(props);
    this.selectionData = undefined;
    this.resetOverlayVariables();
    this.currentViewWidth = document.getElementById('main-dashboard')!.clientWidth;
    this.state = {
      reportHeader: undefined,
      graphData: [],
      selectedPoints: {},
      reportPublisherId: '',
      nticks: 9,
      loading: true,
      toggleShowConfirmClearInteractions: false,
      toggleShowReportHeaderChooser: false,
      interactionIndicator: {
        selection: false,
        hiding: false,
      },
      summaryStatLines: [],
      lineDict: {},
      selectedSoftbinsForOverlay: [],
      selectedWaferIdsForOverlay: [],
      plotterPageNumber: props.plotterPageNumber,
      graphIndex: 0,
      numOfGraphsPerPage: AppConstants.numberOfGraphsPerRow,
      widthPerGraph: this.currentViewWidth - 60,
    };
  }

  componentDidMount() {
    const {
      viewMode,
    } = this.props;
    let { reportPublisherId } = this.state;
    const { report, reportIndex } = this.props;
    userDataUtil.registerInteractionEvent(`[GRAPH] ${report.actor}`, 'REPORTING');
    reportPublisherId = this.pubSubBinder.RegisterActor(
      report.actor,
      this.reportSubscriptionListener,
      report.actor.includes(ReportType.PARAMETRIC_FAILURE),
    );

    this.setState({ reportPublisherId }, () => {
      this.generateDetailedReport(viewMode, true);
    });
  }

  componentDidUpdate(prevProps: ICustomizedReportGraphProps, prevState: ICustomizedReportGraphState) {
    const {
      showAnnotations, showDies, limitSettingsObj, plotterPageNumber, axisBreakThresholdFactor,
      setReportLoaded, seriesType, testParameterIndex, isSoftBin, config, report, aggredateFunction, saveData, viewMode, reportIndex
    } = this.props;
    const { isClosedSomeReport } = this.props;
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (prevProps.showAnnotations !== showAnnotations || prevProps.showDies !== showDies || JSON.stringify(prevProps.limitSettingsObj) !== JSON.stringify(limitSettingsObj)
        || prevProps.plotterPageNumber !== plotterPageNumber || prevProps.axisBreakThresholdFactor !== axisBreakThresholdFactor) {
        this.saveReportModifiableOptions();
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ toggleShowConfirmClearInteractions: false });
      } else if ((prevProps.seriesType !== seriesType || JSON.stringify(prevProps.testParameterIndex) !== JSON.stringify(testParameterIndex) || prevProps.isSoftBin !== isSoftBin
                 || (report.actor === ReportType.PARAMETRIC_FAILURE && JSON.stringify(prevProps.config) !== JSON.stringify(config)) || prevProps.aggredateFunction !== aggredateFunction)
                 && !isClosedSomeReport) {
        this.saveReportModifiableOptions();
        this.generateDetailedReport(viewMode, false, true);
      }
      if (isClosedSomeReport && saveData){
        saveData(undefined, reportIndex, GeneralUtils.ReportDataTypeConstants.REPORT_CLOSING);
      }
    }
    const { loading } = this.state;
    if (prevState.loading !== loading) {
      setReportLoaded();
    }
  }

  componentWillUnmount() {
    const { reportPublisherId } = this.state;
    const { onChangeReportConfigValues } = this.props;
    this.pubSubBinder.DisposeActor(reportPublisherId);
    onChangeReportConfigValues([
      {
        key: 'softBins',
        value: [],
      },
      {
        key: 'waferIdsForOverlay',
        value: [],
      },
    ], true);
  }

  resetOverlayVariables = () => {
    this.waferPlotter = null;
    this.overlayGraphs = {};
    this.softBins = {};
    this.wafers = [];
  };

  onChangeSoftbinSelection = (value: boolean, softbinNumber: string) => {
    this.setState((prevState: ICustomizedReportGraphState) => {
      const newSelectedSoftBinsForOverlay = prevState.selectedSoftbinsForOverlay.filter((sbn: string) => {
        return sbn !== softbinNumber;
      });
      if (value) newSelectedSoftBinsForOverlay.push(softbinNumber);
      return { selectedSoftbinsForOverlay: newSelectedSoftBinsForOverlay };
    });
  };

  onSelectAllSoftbins = (value: boolean, softbinNumbers: string[]) => {
    this.setState({ selectedSoftbinsForOverlay: value ? softbinNumbers : [] });
  };

  onChangeOverlayWafersSelection = (selectedWaferIdsForOverlay: string[]) => {
    this.setState({ selectedWaferIdsForOverlay });
  };

  getHeaderSettingsRequestObj = () => {
    const { report, groupingSortingListStore } = this.props;
    const { graphData } = this.state;
    let xValuesForComputationRetrieved;
    let xValuesOnActualGraphRetrieved;
    let yValuesRetrieved;
    if (report.actor === ReportType.PARAMETRIC_TREND) {
      yValuesRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => graph.y).flat();
      xValuesForComputationRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => (graph.xValue as number[])).flat();
      xValuesOnActualGraphRetrieved = xValuesForComputationRetrieved;
    } else if (report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) {
      yValuesRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => graph.y).flat();
      xValuesForComputationRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => graph.xText).flat();
      xValuesForComputationRetrieved = xValuesForComputationRetrieved.map(Number);
      xValuesOnActualGraphRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => (graph.xValue as number[])).flat();
    } else if (report.actor === ReportType.PARAMETRIC_BOX_PLOT) {
      yValuesRetrieved = (graphData as ICombinedGraphData).graphs.map((graph) => graph.y[0]).flat();
      xValuesForComputationRetrieved = Array.from(Array(yValuesRetrieved.length).keys());
      if (groupingSortingListStore.grouping && groupingSortingListStore.grouping.length >= 1) {
        xValuesOnActualGraphRetrieved = Array.from(Array((graphData as ICombinedGraphData).graphs.length).keys());
      } else {
        xValuesOnActualGraphRetrieved = [-0.55, -0.25];
      }
    }
    const headerSettingsRequestObj: IHeaderSettingsRequestObj = {
      xValuesForComputation: xValuesForComputationRetrieved,
      xValuesOnActualGraph: xValuesOnActualGraphRetrieved,
      yValues: yValuesRetrieved,
    };
    return headerSettingsRequestObj;
  };

  getRequestObject = (viewMode: IViewMode, isReportMounted = false, getHeaderSettingsRequestObj = false) => {
    const {
      selectedBinPlusDefinition,
      parseFilter,
      selectionStore,
      groupingSortingListStore,
      reportSessionId,
      report,
      aggredateFunction,
      seriesType,
      isSoftBin,
      config,
      testParameterIndex,
      selectionStoreDataSetCount,
      productType,
    } = this.props;
    let headerSettingRequestObject: IHeaderSettingsRequestObj | null = null;
    if (getHeaderSettingsRequestObj === true) {
      headerSettingRequestObject = this.getHeaderSettingsRequestObj();
    }
    const data: IGenericDetailedReportRequestObject = {
      productType,
      selectionStoreDataSetCount,
      scwData: selectionStore.selections.map((item) => ({
        entityType: item.controlType as EntityType,
        values: item.values.map((val) => val.id),
      })),
      filters: JSON.stringify(parseFilter(selectionStore)),
      binPlusDefId: selectedBinPlusDefinition.id,
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: ReportCollectionPreferences[report.actor]?.useAggregateFunctions ? aggredateFunction : null,
      seriesType,
      config: (() => {
        const configClone = _.cloneDeep(config);
        configClone.isReportMounted = isReportMounted;
        return configClone;
      })(),
      reportType: report.actor,
      testParameterIndex,
      hasNonSequentialXValues: report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT ? true : undefined,
      headerSettingsRequestObj: headerSettingRequestObject !== null ? headerSettingRequestObject : undefined,
      isSoftBin,
    };
    return data;
  };

  checkBoxPlotDieHidingCondition = () => {
    const { setStateCallback } = this.props;
    const { graphData } = this.state;
    if ((graphData as ICombinedGraphData).graphs.length > 0 && (graphData as ICombinedGraphData).graphs[0].reportSpecificAttributes.HideDies[0] === true) {
      setStateCallback({ showDiesShowingOption: false });
    }
  };

  saveReportModifiableOptions = () => {
    const {
      saveData, reportIndex, seriesType, aggredateFunction, testParameterIndex, isSoftBin, config, limitSettingsObj, showDies, showAnnotations, plotterPageNumber, axisBreakThresholdFactor,
    } = this.props;
    const reportModifiableOptions: ICustomizedReportModifiableOptions = {
      seriesType,
      aggredateFunction,
      testParameterIndex,
      isSoftBin,
      config,
      limitSettingsObj,
      showDies,
      showAnnotations,
      plotterPageNumber,
      axisBreakThresholdFactor,
    };
    if (saveData) {
      saveData(reportModifiableOptions, reportIndex, GeneralUtils.ReportDataTypeConstants.MODIFIERS);
    }
  };

  generateDetailedReport = async (viewMode: IViewMode, isReportMounted = false, componentDidUpdate = false) => {
    const {
      dataHelper, graphData, headerData, saveData, reportIndex, updatedHeaderId, getBinChangeDataForBinWaferMapComparison, report
    } = this.props;
    this.resetOverlayVariables();
    this.setState({ loading: true });
    const requestObject = this.getRequestObject(viewMode, isReportMounted);
    requestObject.reportHeaderId = updatedHeaderId;
    if (!componentDidUpdate && graphData && report.actor !== ReportType.BIN_WAFER_MAP_COMPARISON) {
      if (headerData) {
        this.setDetailedGraphAndHeaderData(graphData, headerData, componentDidUpdate);
      } else if (!headerData && updatedHeaderId) {
        this.updateReportHeader(updatedHeaderId, graphData, true);
      }
    } else {
      (dataHelper as IGraphDataHelper).getDetailedGraphData(requestObject, (data: ICombinedGraphDataWithReportHeaders) => {
        if (saveData) {
          saveData(data.flatGraphDataDto, reportIndex, GeneralUtils.ReportDataTypeConstants.GRAPH);
          saveData(data.reportHeaderSetting, reportIndex, GeneralUtils.ReportDataTypeConstants.HEADER);
        }
        if (getBinChangeDataForBinWaferMapComparison){
          this.invokeGetBinChangeDataForBinWaferMapComparison(data);
        }
        this.setDetailedGraphAndHeaderData(data.flatGraphDataDto, data.reportHeaderSetting, componentDidUpdate);
      });
    }
  };

  setDetailedGraphAndHeaderData = async (graphData: ICombinedGraphData, headerData: IReportHeaderSetting, componentDidUpdate: boolean) => {
    const { report } = this.props;
    const { interactionIndicator, summaryStatLines, lineDict } = this.state;
    let newSummaryStatLines: IFieldValue[];
    let newLineDict: ILineDict;

    if (componentDidUpdate) {
      newSummaryStatLines = [];
      newLineDict = {};
    } else {
      newSummaryStatLines = summaryStatLines;
      newLineDict = lineDict;
    }

    if (graphData.interactionDataExists) {
      interactionIndicator.hiding = true;
      interactionIndicator.selection = true;
    }
    const selectedPoints: { [key: string]: any } = {};
    (graphData as ICombinedGraphData).graphs.forEach(
      (g, index: number) => {
        selectedPoints[index.toString()] = g.selectedIndexes.map(
          (p: any) => ({
            pointIndex: p,
          }),
        );
      },
    );

    this.setState({
      loading: false,
      graphData,
      reportHeader: headerData,
      selectedPoints,
      interactionIndicator,
      summaryStatLines: newSummaryStatLines,
      lineDict: newLineDict,
    }, () => {
      // if (firstGeneration) {
      //   const powerViewProgressDriver : IPowerViewProgressDriver = {
      //     action: 'APPEND',
      //     description: `${GeneralUtils.toTitleCase(report.actor)} Generated Successfully`,
      //     timestamp: new Date(),
      //   };
      //   this.logProgress(powerViewProgressDriver);
      // }
      if (report.actor === ReportType.PARAMETRIC_BOX_PLOT) {
        this.checkBoxPlotDieHidingCondition();
      }
    });
  };

  logProgress = (powerViewProgressDriver: IPowerViewProgressDriver) => {
    const { reportPublisherId } = this.state;
    this.pubSubBinder.BroadcastEvent(
      reportPublisherId,
      ['POWER_VIEW_PROGRESS_BAR'],
      'LOG_POWER_VIEW_PROGRESS',
      powerViewProgressDriver,
    );
  };

  setSelectionData = (e: any) => {
    if (e !== undefined) {
      this.selectionData = e;
    }
  };

  replotGraph = () => {
    const { interactionIndicator, graphData } = this.state;
    // state invokation to re-render plotly graph if interactions applied and accidental selection/deselection needs to be reverted
    if (interactionIndicator.selection && (graphData as ICombinedGraphData).highlightedCount > 0) {
      this.setState({ toggleShowConfirmClearInteractions: false });
    }
  };

  reportPublisherEvent = (mode: InteractionMode, action: ActionType, reportType: IInteractionType) => {
    const {
      viewMode,
      dataHelper,
      saveData,
      reportIndex,
      getBinChangeDataForBinWaferMapComparison,
    } = this.props;
    const {
      graphData,
      interactionIndicator,
      reportPublisherId,
    } = this.state;
    const { selectionData } = this;

    let interactionEventType: InteractionEventType = 'SELECTION';
    if (action.includes('HIDE_')) {
      interactionEventType = 'HIDE';
    } else if (action.includes('SELECT_')) {
      interactionEventType = 'SELECTION';
    } else {
      interactionEventType = 'CLEAR_INTERACTIONS';
    }

    const selectionEventInput: InteractionInputDTO = {
      eventType: interactionEventType,
      interactionMode: mode,
      requestObject: this.getRequestObject(viewMode),
      interactionDetails: [],
    };

    const { graphs, labelRows } = graphData as ICombinedGraphData;

    if (selectionData && selectionData.points.length > 0 && graphs.length > 0) {
      let similarInteractionKeys: any[] = [];
      if (graphs[0].similarInteractionDetail.length > 0) {
        similarInteractionKeys = Object.keys(graphs[0].similarInteractionDetail[0]);
      }
      let selectedInteractionKeys: any[] = [];
      if (graphs[0].selectedInteractionDetail.length > 0) {
        selectedInteractionKeys = Object.keys(graphs[0].selectedInteractionDetail[0]);
      }

      const pointIndexMultiplier = (reportType === 'INDIVIDUAL_PARAMETRIC_FAILURE_SELECTION' || reportType === 'INDIVIDUAL_PARAMETRIC_FAILURE_HIDING' ? 2 : 1);
      const curveNumberDivider = pointIndexMultiplier;
      const groupingLabelsPerGraph = labelRows.length / graphs.length;

      selectionData.points.forEach((p: any) => {
        const interactionDetail: { [key: string]: any } = {};
        let skipGraphInteractions = false;
        if (reportType.startsWith('INDIVIDUAL_PARAMETRIC_BOX_PLOT')) {
          p.pointIndexXTextAndY = p.pointIndex[1];
          p.pointIndex = p.pointIndex[0];
          if (p.fullData.type === 'scatter') {
            skipGraphInteractions = true;
          }
        }
        if (skipGraphInteractions) {
          return;
        }
        if (similarInteractionKeys.length > 0 && mode === 'SIMILAR') {
          if (reportType.includes(ReportTypeConstants.PARAMETRIC_BOX_PLOT)) {
            interactionDetail.XCoordinateCustom = graphs[p.curveNumber].xCoordinateCustom[0][p.pointIndexXTextAndY];
            interactionDetail.YCoordinateCustom = graphs[p.curveNumber].yCoordinateCustom[0][p.pointIndexXTextAndY];
          } else {
            similarInteractionKeys.forEach((similarInteractionKey) => {
              interactionDetail[similarInteractionKey] = graphs[Math.floor(p.curveNumber / curveNumberDivider)].similarInteractionDetail[p.pointIndex * pointIndexMultiplier][similarInteractionKey];
            });
          }
        }
        if (selectedInteractionKeys.length > 0 && mode === 'SELECTED') {
          if (reportType.includes('INDIVIDUAL_PARAMETRIC_BOX_PLOT')) {
            interactionDetail.XText = graphs[p.curveNumber].xText[0][p.pointIndexXTextAndY];
          } else {
            selectedInteractionKeys.forEach((selectedInteractionKey) => {
              const accessingIndex = Math.floor(p.curveNumber / curveNumberDivider);
              if (selectedInteractionKey === GeneralUtils.Constants.ALL_GROUPINGS) {
              // get interaction details for all groupings for that specific point
                for (let i = groupingLabelsPerGraph * accessingIndex; i < groupingLabelsPerGraph * accessingIndex + groupingLabelsPerGraph; i += 1){
                  const newKey = GeneralUtils.ToSnakeCase(labelRows[i].xAxisTextPrefix);
                  const newValue = labelRows[i].xAxisText;
                  interactionDetail[newKey] = newValue;
                }
              } else {
                interactionDetail[selectedInteractionKey] = graphs[accessingIndex].selectedInteractionDetail[p.pointIndex * pointIndexMultiplier][selectedInteractionKey];
              }
            });
          }
        }
        if (!selectionEventInput.interactionDetails.some((dict) => JSON.stringify(dict) === JSON.stringify(interactionDetail))) {
          if (('Bin Number' in interactionDetail) && interactionDetail['Bin Number'] === null){
            interactionDetail['Bin Number'] = -1;
          }
          selectionEventInput.interactionDetails.push(interactionDetail);
        }
      });
    }
    this.resetOverlayVariables();
    this.setState({ loading: true }, () => {
      (dataHelper as IGraphDataHelper).postCombinedEvent(selectionEventInput)
        .then((newGraphDataWithReportHeaders: ICombinedGraphDataWithReportHeaders) => {
          if (getBinChangeDataForBinWaferMapComparison){
            this.invokeGetBinChangeDataForBinWaferMapComparison(newGraphDataWithReportHeaders);
          }
          if (saveData) {
            saveData(newGraphDataWithReportHeaders.flatGraphDataDto, reportIndex, 'GRAPH');
            saveData(newGraphDataWithReportHeaders.reportHeaderSetting, reportIndex, 'HEADER');
          }
          if (interactionEventType === 'SELECTION') {
            interactionIndicator.selection = true;
          } else if (interactionEventType === 'HIDE') {
            interactionIndicator.hiding = true;
          }

          this.selectionData = undefined;
          const updatedState = {
            interactionIndicator,
            graphData: newGraphDataWithReportHeaders.flatGraphDataDto,
            reportHeader: newGraphDataWithReportHeaders.reportHeaderSetting,
            loading: false,
          };

          this.setState(updatedState, () => {
            this.forceUpdate();
            this.pubSubBinder.BroadcastEvent(reportPublisherId, 'ALL_REPORTS_AND_LEGENDS', action, { reportIndex });
          });
        });
    });
  };

  returnRequestObj = (reportHeaderId: string) => {
    const {
      viewMode,
      rulePayLoad,
    } = this.props;
    const requestObject = this.getRequestObject(viewMode);
    requestObject.reportHeaderId = reportHeaderId;
    requestObject.rulePayLoad = rulePayLoad;
    return requestObject;
  };

  updateReportHeader = (reportHeaderId: string, updatedGraphData?: ICombinedGraphData, updateGraphDataAsWell = false) => {
    const { reportIndex, saveData } = this.props;
    const { graphData } = this.state;
    let newGraphData: ICombinedGraphData;
    if (updatedGraphData && updateGraphDataAsWell) {
      newGraphData = updatedGraphData;
    } else {
      newGraphData = (graphData as ICombinedGraphData);
    }
    this.setState({ loading: true }, () => {
      this.waferPlotter = null;
      const requestObject = this.returnRequestObj(reportHeaderId);
      httpReportHeader.apply(requestObject)
        .then((reportHeader: IReportHeaderSetting) => {
          this.setState({
            graphData: newGraphData,
            reportHeader,
            loading: false,
          });
          if (saveData) {
            saveData(reportHeader, reportIndex, 'HEADER');
          }
        });
    });
  };

  reportSubscriptionListener = (action: ActionType, params?: any) => {
    const {
      viewMode,
      dataHelper,
      getBinChangeDataForBinWaferMapComparison
    } = this.props;

    if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS' || action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
      this.setState({ loading: true }, () => {
        let handler;
        if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS') {
          handler = (dataHelper as IGraphDataHelper).subscribeSelectionEvent;
        } else if (action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
          handler = (dataHelper as IGraphDataHelper).subscribeHidingEvent;
        }
        if (handler) {
          this.resetOverlayVariables();
          handler(this.getRequestObject(viewMode), (graphDataWithReportHeaders: ICombinedGraphDataWithReportHeaders) => {
            const selectedPoints: { [key: string]: any } = {};
            if (getBinChangeDataForBinWaferMapComparison){
              this.invokeGetBinChangeDataForBinWaferMapComparison(graphDataWithReportHeaders);
            }
            (graphDataWithReportHeaders as ICombinedGraphDataWithReportHeaders).flatGraphDataDto.graphs.forEach(
              (g, index: number) => {
                selectedPoints[index.toString()] = g.selectedIndexes.map(
                  (p: any) => ({
                    pointIndex: p,
                  }),
                );
              },
            );

            const { interactionIndicator } = this.state;

            if (params !== undefined && params.clearIndicators) {
              interactionIndicator.selection = false;
              interactionIndicator.hiding = false;
            } else if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS') {
              interactionIndicator.selection = true;
            } else if (action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
              interactionIndicator.hiding = true;
            }

            this.setState(
              {
                loading: false,
                graphData: (graphDataWithReportHeaders as ICombinedGraphDataWithReportHeaders).flatGraphDataDto,
                reportHeader: (graphDataWithReportHeaders as ICombinedGraphDataWithReportHeaders).reportHeaderSetting,
                selectedPoints,
                interactionIndicator,
              },
              this.forceUpdate,
            );
          });
        }
      });
    } else if (action === 'APPLY_REPORT_HEADER' && params && params.reportHeaderId) {
      this.updateReportHeader(params.reportHeaderId);
    }
  };

  plotSelectedPointsForGroup = (groupIndex: number, totalPoints: any[], yValues: any[], graphType: any, reportActor: string) => {
    const { interactionIndicator } = this.state;
    if (!interactionIndicator.selection) {
      if (graphType === 'box') {
        return yValues[0].map((_item: any, index: number) => [0, index]);
      }
      return totalPoints.map((_item: any, index: number) => index);
    }

    const { graphData } = this.state;
    if ((graphData as ICombinedGraphData).graphs[groupIndex] !== undefined) {
      if (graphType === 'box') {
        const selectedIndexes = (graphData as ICombinedGraphData).graphs[groupIndex].selectedIndexes;
        return selectedIndexes.map((_item: any) => [0, _item]);
      }
      groupIndex = reportActor === ReportType.PARAMETRIC_FAILURE ? Math.floor(groupIndex / 2) : groupIndex;
      return (graphData as ICombinedGraphData).graphs[groupIndex].selectedIndexes;
    }

    return [];
  };

  toggleConfirmClearInteractions = (flag: boolean) => {
    this.setState({ toggleShowConfirmClearInteractions: flag });
  };

  toggleShowReportHeaderChooser = () => {
    const { toggleShowReportHeaderChooser } = this.state;
    this.setState({ toggleShowReportHeaderChooser: !toggleShowReportHeaderChooser });
  };

  clearInteractions = () => {
    const {
      dataHelper,
      viewMode,
      saveData,
      reportIndex,
      getBinChangeDataForBinWaferMapComparison,
    } = this.props;
    const { reportPublisherId } = this.state;
    const selectionEventInput: InteractionInputDTO = {
      eventType: 'CLEAR_INTERACTIONS',
      interactionMode: 'ALL',
      requestObject: this.getRequestObject(viewMode),
      interactionDetails: [],
    };
    this.selectionData = undefined;
    this.resetOverlayVariables();
    this.setState({ loading: true }, () => {
      (dataHelper as IGraphDataHelper).postCombinedEvent(selectionEventInput)
        .then((newGraphDataWithReportHeaders: ICombinedGraphDataWithReportHeaders) => {
          if (getBinChangeDataForBinWaferMapComparison){
            this.invokeGetBinChangeDataForBinWaferMapComparison(newGraphDataWithReportHeaders);
          }
          if (saveData) {
            saveData(newGraphDataWithReportHeaders.flatGraphDataDto, reportIndex, 'GRAPH');
            saveData(newGraphDataWithReportHeaders.reportHeaderSetting, reportIndex, 'HEADER');
          }
          this.setState({
            loading: false,
            toggleShowConfirmClearInteractions: false,
            graphData: newGraphDataWithReportHeaders.flatGraphDataDto,
            reportHeader: newGraphDataWithReportHeaders.reportHeaderSetting,
            interactionIndicator: {
              selection: false,
              hiding: false,
            },
          }, () => {
            this.forceUpdate();
            this.pubSubBinder.BroadcastEvent(reportPublisherId, 'ALL_REPORTS_AND_LEGENDS', 'SELECT_GRAPH_AND_LEGEND_ITEMS', { clearIndicators: true }); // 'SELECT_GRAPH_AND_LEGEND_ITEMS');
          });
        });
    });
  };

  updateState = (stateData: any) => {
    this.setState(stateData);
  };

  renderInteractionIndicators = (show: boolean, title: string, icon: any, label: string, message: any) => {
    if (!show) {
      return <></>;
    }

    const popover = (
      <Popover id={title} className="w150">
        <Popover.Content>
          <Row>
            <Col>
              <span>
                <b>{label}</b>
              </span>
            </Col>
            <Col>
              <span>
                {message}
              </span>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
        <div className="ml4 p4 background-color-info color-light rounded cursor-pointer">
          <FontAwesomeIcon className="mr4" size="sm" icon={icon} />
          {title}
        </div>
      </OverlayTrigger>
    );
  };

  getSupportingComponents = (callback: any) => {
    if (callback) {
      return callback(this.props, this.state, this, this.updateState);
    }
    return <></>;
  };

  updateLimitSettings = (updatedLimitSettingsObj: any) => {
    const { limitSettingsObj, setStateCallback } = this.props;
    if (JSON.stringify(limitSettingsObj.outlier) !== JSON.stringify(updatedLimitSettingsObj.outlier)
      || JSON.stringify(limitSettingsObj.distribution) !== JSON.stringify(updatedLimitSettingsObj.distribution)) {
      const newLimitSettingsObj = _.cloneDeep(limitSettingsObj);
      newLimitSettingsObj.outlier = updatedLimitSettingsObj.outlier;
      newLimitSettingsObj.distribution = updatedLimitSettingsObj.distribution;
      setStateCallback({ limitSettingsObj: newLimitSettingsObj });
    }
  };

  getTabsComponent = (callback: any) => {
    const { viewMode, report } = this.props;
    const { graphData, interactionIndicator } = this.state;
    let interactionsApplied = false;
    if (interactionIndicator.hiding || interactionIndicator.selection) {
      interactionsApplied = true;
    }
    const tabs: any[] = [];
    if (callback && report.actor !== 'BIN_WAFER_MAP_COMPARISON') {
      tabs.push(callback(this.getRequestObject(viewMode), this.props, interactionsApplied, this.updateLimitSettings).map((tabItem: ICustomizedReportGraphTab) => (
        <Tab
          className="pt20"
          eventKey={tabItem.key}
          title={tabItem.title}
          mountOnEnter
        >
          {tabItem.component}
        </Tab>
      )));
    }
    if ((graphData as ICombinedGraphData).errors) {
      const graphingUtil = new GraphingUtils();
      tabs.push(
        <Tab
          tabClassName="w200"
          className="pt20"
          eventKey="errors"
          title={(() => {
            const errorsCount = (graphData as ICombinedGraphData).errors.filter((x) => x.type === 'DATA_ERROR' || x.type === 'SYSTEM_ERROR').length;
            const warningsCount = (graphData as ICombinedGraphData).errors.filter((x) => x.type === 'WARNING').length;

            return (
              <div className="d-flex align-items-center justify-content-between">
                <div className="flex-50 d-flex align-items-center justify-content-between pr10 mr10">
                  <div className="flex-20">
                    <FontAwesomeIcon
                      className="color-danger"
                      size="sm"
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-40 pl4">Errors</div>
                  <div className="flex-40 text-right pl4">
                    [
                    {errorsCount}
                    ]
                  </div>
                </div>
                <div className="pr20">{'\u0026'}</div>
                <div className="flex-50 d-flex align-items-center justify-content-between">
                  <div className="flex-20">
                    <FontAwesomeIcon
                      className="color-warning"
                      size="sm"
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-40 pl4">
                    Warnings
                  </div>
                  <div className="flex-40 text-right pl4">
                    [
                    {warningsCount}
                    ]
                  </div>
                </div>
              </div>
            );
          })()}
          mountOnEnter
        >
          {graphingUtil.generateErrorsTab((graphData as ICombinedGraphData).errors)}
        </Tab>,
      );
    }
    return tabs;
  };

  summaryStatsHandler = (summaryStat: string[], lineDict: ILineDict) => {
    const {
      viewMode,
      report,
    } = this.props;
    const requestObject = this.getRequestObject(viewMode, undefined, true);

    requestObject.summaryStat = summaryStat;
    requestObject.reportType = report.actor;

    httpSummaryStatLines.calculate(requestObject)
      .then((fieldValues: IFieldValue[]) => {
        if (fieldValues) {
          this.setState({
            summaryStatLines: fieldValues,
            lineDict,
          }, this.forceUpdate);
        }
      });
  };

  onPageChangeHandler = (isNext: boolean) => {
    const { setStateCallback } = this.props;
    const { graphData, numOfGraphsPerPage } = this.state;
    if (this.waferPlotterScrollView) {
      this.waferPlotterScrollView!.instance.scrollTo(0);
    }

    this.setState((prevState: ICustomizedReportGraphState) => {
      const newState = {
        plotterPageNumber: prevState.plotterPageNumber,
      };
      let newPage = newState.plotterPageNumber + 1;
      if (!isNext) {
        newPage = newState.plotterPageNumber === 0 ? newState.plotterPageNumber : newState.plotterPageNumber - 1;
      }

      // Stop moving ahead/behind if no data there
      if (
        (!this.getIsWaferMapReport() && (graphData as ICombinedGraphData).graphs.length > (newPage * numOfGraphsPerPage))
        || (this.getIsWaferMapReport() && (this.waferPlotter === null || Object.values(this.waferPlotter?.waferUtils).filter((util: WaferUtils) => {
          return util.waferMapVariables.pageNumber === newPage;
        }).length > 0))
      ) {
        newState.plotterPageNumber = newPage;
      } else {
        toast.warn(`No data on the ${isNext ? 'next' : 'previous'} page`);
      }
      setStateCallback({ plotterPageNumber: newState.plotterPageNumber });
      return newState;
    });
  };

  getIsWaferMapReport = () => {
    const { report } = this.props;
    return (report.actor === ReportType.PARAMETRIC_WAFER_MAP || report.actor === ReportType.BIN_WAFER_MAP);
  };

  isShowSummaryStatsComponent = () => {
    const { report, seriesType } = this.props;
    const { graphData } = this.state;
    if ((report.actor === ReportType.PARAMETRIC_TREND || report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT) && (graphData as ICombinedGraphData).graphs.length === 1) {
      return true;
    }
    if ((report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT || report.actor === ReportType.PARAMETRIC_TREND) && (seriesType === 'SINGLE' || (seriesType === 'SEPARATE' && (graphData as ICombinedGraphData).graphs.length === 1))) {
      return true;
    }
    return false;
  };

  invokeGetBinChangeDataForBinWaferMapComparison = (data: ICombinedGraphDataWithReportHeaders) => {
    const { getBinChangeDataForBinWaferMapComparison } = this.props;
    const perCombinationBinChange: any = [];
    for (let index = 0; index < data.flatGraphDataDto.graphs.length; index += 1){
      if (data.flatGraphDataDto.graphs[index].graphCaption.includes('Comparison')){
        const binChangeDict: any = {
          binChanges: data.flatGraphDataDto.graphs[index].comparisonGraphResponseData?.binNumberChangeList,
          dieStatus: data.flatGraphDataDto.graphs[index].comparisonGraphResponseData?.dieComparisonStatuses,
          isSelected: data.flatGraphDataDto.graphs[index].toolTips.map((toolTip: any) => toolTip.isSelected),
          hidden: data.flatGraphDataDto.graphs[index].toolTips.map((toolTip: any) => toolTip.hidden)
        }
        perCombinationBinChange.push(binChangeDict);
      }
    }
    if (getBinChangeDataForBinWaferMapComparison){
      getBinChangeDataForBinWaferMapComparison(perCombinationBinChange);
    }
  }

  render() {
    this.count += 1;
    const {
      viewMode,
      dataHelper,
      report,
      testParameterIndex,
      showDies,
      reportIndex,
      updatedHeaderId,
      headerData,
      saveData,
      isApplyAllReportHeaders,
    } = this.props;
    const {
      graphData,
      loading,
      interactionIndicator,
      toggleShowConfirmClearInteractions,
      reportHeader,
      toggleShowReportHeaderChooser,
      reportPublisherId,
      summaryStatLines,
      lineDict,
      plotterPageNumber,
    } = this.state;

    if (viewMode === 'DETAILED') {
      return (graphData as IDrilledGraphData[]).length > 0 ? (
        <Row className="pb100">
          <Col>
            <div className="m10 background-color-light p20">
              <Row>
                <Col lg={12}>
                  <ScrollView
                    width="100%"
                    useNative
                    direction="horizontal"
                    showScrollbar="onScroll"
                    scrollByThumb
                    reachBottomText=""
                    scrollByContent
                  >
                    <DrilledBinHistogramReport
                      data={(graphData as IDrilledGraphData[])}
                    />
                  </ScrollView>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      ) : <></>;
    }
    return (
      <>
        <Tabs id="customized-report-tabs">
          <Tab className="" eventKey="visualization" title="Visualization">
            {/* --------------------- REPORT HEADER | TEST PARAMETER NAME | INTERACTION INDICATORS 👇🏼 --------------------- */}
            <div className="d-flex flex-column align-items-center justify-content-center mt20">
              {this.getSupportingComponents((dataHelper as IGraphDataHelper).getSecondaryComponent)}
              {viewMode === 'COMBINED' && (graphData as ICombinedGraphData).graphs !== undefined && (
                <div className="d-flex align-items-center flex-row-reverse">
                  <InteractionIndicators
                    highlightedCount={(graphData as ICombinedGraphData).highlightedCount}
                    hiddenCount={(graphData as ICombinedGraphData).hiddenCount}
                    interactionIndicator={interactionIndicator}
                    toggleConfirmClearInteractions={this.toggleConfirmClearInteractions}
                  />
                </div>
              )}
            </div>
            {!loading && (
              <div>
                <ReportHeaderLandingArea
                  testParameterIndex={testParameterIndex}
                  reportHeaderSettings={reportHeader}
                  returnRequestObj={this.returnRequestObj}
                  additionalInformation={[]}
                  reportIndex={reportIndex}
                  updatedHeaderId={updatedHeaderId}
                  headerData={headerData}
                  saveData={saveData}
                  isApplyAllReportHeaders={isApplyAllReportHeaders}
                />
              </div>
            )}
            {/* --------------------- REPORT HEADER | TEST PARAMETER NAME | INTERACTION INDICATORS 👆🏼 --------------------- */}
            {/* --------------------- INTERACTION INDICATORS 👇🏼 --------------------- */}
            {/* {viewMode === 'COMBINED' && (graphData as ICombinedGraphData).graphs !== undefined && (
              <div className="d-flex align-items-center flex-row-reverse w-100 mb20">
                <InteractionIndicators
                  highlightedCount={(graphData as ICombinedGraphData).highlightedCount}
                  hiddenCount={(graphData as ICombinedGraphData).hiddenCount}
                  interactionIndicator={interactionIndicator}
                  toggleConfirmClearInteractions={this.toggleConfirmClearInteractions}
                />
              </div>
            )} */}
            {/* --------------------- INTERACTION INDICATORS 👆🏼 --------------------- */}
            <div className="w-100 position-relative" key="graph component">
              {/* --------------------- GRAPH COMPONENT 👇🏼 --------------------- */}
              {report.actor !== 'BIN_WAFER_MAP_COMPARISON' && (
                <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }} className="mt20">
                  <span className="mt5 mr10" style={{ fontWeight: 'bold' }}>Page Info</span>
                  <Button
                    style={{ borderRadius: '0px' }}
                    onClick={() => { this.onPageChangeHandler(false); }}
                  >
                    {'<'}
                  </Button>
                  <Textbox
                    style={{ width: '50px', height: '30px', textAlign: 'center' }}
                    readonly
                    disabled
                    name="pageNumber"
                    placeholder="Page Number"
                    type="text"
                    value={plotterPageNumber + 1}
                    onChange={() => { }}
                  />
                  <Button
                    style={{ borderRadius: '0px' }}
                    onClick={() => { this.onPageChangeHandler(true); }}
                  >
                    {'>'}
                  </Button>
                </div>
              )}
              {!loading && (dataHelper as IGraphDataHelper).generateGraphComponent(
                this.props,
                this.state,
                this,
                this.updateState,
                summaryStatLines,
                lineDict,
              )}
              {/* --------------------- GRAPH COMPONENT 👆🏼 --------------------- */}
              {/* --------------------- ACTION SHEET COMPONENT 👇🏼 --------------------- */}
              {/* FIXME!! Actor condition needs to be fixed */}
              {!loading && (graphData as ICombinedGraphData).graphs.length !== 0 && this.isShowSummaryStatsComponent() && (
                <div style={{
                  position: 'absolute',
                  top: '54px',
                  right: 0,
                }}
                >
                  <ActionItemsSheet reportActor={report.actor} diesShownOnBoxPlot={showDies} onActionHandler={this.summaryStatsHandler} />
                </div>
              )}
              {/* --------------------- ACTION SHEET COMPONENT 👆🏼 --------------------- */}
            </div>
            {loading && (
              <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '430px' }}>
                <div className="w300">
                  <ContextSavingSpinner
                    mode="REPORT"
                    title={`Generating ${GeneralUtils.toTitleCase(report.actor)}`}
                  />
                </div>
              </div>
            )}
            {!loading
              && (graphData as ICombinedGraphData).graphs.length === 0
              && (
                <p>No graph data found. Check the Errors Tab for details.</p>
              )}
          </Tab>
          {/* ---------------------  DYNAMIC TABS RENDERING 👇🏼 --------------------- */}
          {
            this.getTabsComponent((dataHelper as IGraphDataHelper).generateTabs)
          }
          {/* ---------------------  DYNAMIC TABS RENDERING 👆🏼 --------------------- */}
        </Tabs>

        <Modal
          show={toggleShowReportHeaderChooser}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title as="h5">Report Headers</Modal.Title>
            <span className="float-right">
              <Button variant="secondary" onClick={() => this.setState({ toggleShowReportHeaderChooser: false })}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faTimes}
                />
              </Button>
            </span>
          </Modal.Header>
          <Modal.Body className="background-color-body-background">
            <div className="h-100">
              <ReportHeaderChooser
                reportPublisherId={reportPublisherId}
                applyToSelected={this.updateReportHeader}
                report={report}
                applyActions={{
                  APPLY_ALL: true,
                  APPLY_SELECTED: true,
                  APPLY_SIMILAR: true,
                }}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={toggleShowConfirmClearInteractions}
          onClick={() => {
            this.toggleConfirmClearInteractions(false);
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to reset all hidden and highlighted points from all of the reports?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={() => {
                this.toggleConfirmClearInteractions(false);
              }}
            >
              No
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.clearInteractions();
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CustomizedReportGraph;
