/* eslint-disable lines-between-class-members */
// Class for Wafer Map Variables
// eslint-disable-next-line no-unused-vars
import Tooltip from 'devextreme-react/tooltip';
import {
  DieSubView, DieType, RectCoord, ReticleGridRectCoords, ReticleReference, Viewport, WaferMapTestData, ZoneData, StandardReticle,
} from '../web-gl-utils/Types';
import {
  ExclusionType, NotchPosition, SelectionMode, MarkingMode, DieColorType, RowAxisDirection, RowFlip, ColFlip, ColAxisDirection, DefaultNotchPosition,
} from '../web-gl-utils/Enums';

export const defaultToolTipFields: string [][] = [
  ['xTrue', 'x'], ['yTrue', 'y'], ['dieType', 'Die Type'], ['reticleX', 'Reticle X'],
  ['reticleY', 'Reticle Y'], ['softBin', 'Softbin'], ['zoneInfo', 'Zone Info'],
];
// - All dimensions are in pixels (px)
class WaferMapVariables {
  // used in wafer control map
  [key: string]: any;

  showRing = false;

  ringRadius = 170;

  waferHeightToRowsRatio = 1;

  waferWidthToColsRatio = 1;

  ringDiameterToWaferDiameterRatio = 1;

  panOffsetXToDieStepSizeXRatio = 0;

  panOffsetYToDieStepSizeYRatio = 0;

  waferBGOffsetXDies = 0;

  waferBGOffsetYDies = 0;

  isWaferControlMap: boolean;

  isManualServiceWafer: boolean;

  dieWidthToStreetWidthRatio = 1;

  dieHeightToStreetHeightRatio = 1;

  reticleSize: { x: number, y: number } = { x: 0, y: 0 };

  overlayReticle = false; // if this is true, only then consider using reticle info else consider as if there is no reticle applied

  reticleGridColor: number[] = [1.0, 0.0, 0.0, 1.0];

  zoomScaleFactorFromProps = 1;

  currentDieType = '';

  currentWatPCMSiteNumber: number | null = null;

  currentWatTestMethod = 'sampling';

  dieTypes: any; // DONOT Mutate this in wafermap because it has orignal data!!

  zones: ZoneData[];

  currentZoneId = '';

  freeFormZonePCMSiteNumber: number | null = null;

  isContextMenuOpen = false;

  isMouseMoving = false;

  markWaferCenter = true;

  referenceReticleBorderCoords: number[] = [];

  reticleReferenceCoords: number[] = []; // coords of the cross mark at reticle reference of the reference reticle

  reticleReferencePoint: { x: number, y: number } | null = null; // coords of only the single point reticle reference of the reference reticle

  waferCenterCoords: number[] = []; // coords of the cross mark at wafer center

  waferCenterPoint: { x: number, y: number } = { x: 0, y: 0 }; // coords of only the single point wafer center

  reticleOffsetCoords: number[] = [];

  waferCenterColor: number[] = [1.0, 0.0, 0.0, 1.0];

  referenceReticleBorderColor: number[] = [0.0, 0.0, 1.0, 1.0];

  reticleReferenceColor: number[] = [0.0, 0.0, 1.0, 1.0];

  reticleOffsetLineColor: number[] = [1.0, 0.0, 1.0, 1.0];

  notchPosition: NotchPosition = DefaultNotchPosition;

  reticleReference: ReticleReference;

  showReferenceReticle = false;

  standardReticle: StandardReticle | null = null; // same type as in reticle inside standard reticle editor type (passed by reference: don't mutate)

  selectionMode: SelectionMode = SelectionMode.DIE;

  markingMode: MarkingMode = MarkingMode.DIE_TYPE;

  reticleGridRectCoords: ReticleGridRectCoords = {}; // this includes the col and row offsets

  referenceReticleGridRectCoords: RectCoord | null = null; // stores grid rect coords of reference reticle

  reticleXAxisReference = 0;

  reticleYAxisReference = 0;

  allReticleBGCoords: number[] = []; // reticle bg coords are only for those reticles that have text on them

  allReticleBGColor: number[] = [0.0, 0.0, 1.0, 0.2];

  allReticleBorderCoords: number[] = [];

  allReticleBorderColor: number[] = [1.0, 0.0, 0.0, 1.0];

  underSelectionReticleBorderCoords: number[] = [];

  underSelectionReticleBorderColor: number[] = [0.0, 1.0, 0.0, 1.0];

  selectedReticleBorderCoords: number[] = [];

  selectedReticleBorderColor: number[] = [0.0, 0.6, 0.1, 1.0];

  reticleTextField = 'watPCMSiteNumber';

  showReticleText = true;

  wcmWaferDiameter: number; // in inches

  wcmWaferEdgeExclusion: number; // in inches

  wcmWaferNotchKeepOut: number; // in inches

  wcmWaferFlatKeepOut: number; // in inches

  wcmWaferBaseFlat: number; // in inches

  wcmWaferScribeLine: number; // in inches

  wcmExclusionType: ExclusionType;

  isDieCoordinatesSystemEnabled = true;

  isReticleCoordinatesSystemEnabled = false;

  radialZoneRingCoords: number[] = [];

  verticalZoneCoords: number[] = [];

  horizontalZoneCoords: number[] = [];

  groupedZoneCoords: number[] = [];

  zoneBoundaryColor: number[] = [1.0, 1.0, 0.0, 1.0];

  dieColorType: DieColorType = DieColorType.DIE_TYPE;

  // used in wafer control map

  keyIndex: string; // Index of Wafer Map

  tooltipEL: Tooltip | null = null;

  tooltipHoverTime = 0.5; // tooltip hover time in seconds

  tooltipFields: string[][] = defaultToolTipFields;

  binTextFieldOptions = [['softBin', 'Soft Bin'], ['x', 'x'], ['y', 'y'], ['reticleSite', 'Reticle Site'], ['zoneInfo', 'Zone Info']];

  binTextField = 'softBin'; // specify the property to be displayed inside the dies just like bin numbers

  waferMapPadding: number;

  centerOfEclipse1 = 150; // Center of Wafer X Axis

  centerOfEclipse2 = 250; // Center of Wafer Y Axis

  widthOfEclipse = 100; // Number of Rows in Wafer Map Data

  heightOfEclipse = 50; // Number of Columns in Wafer Map Data

  XAnchor = 0; // Top Left position of Wafer Map X-Axis (Changes dynamically as wafer map is dragged)

  YAnchor = 0; // Top Left position of Wafer Map Y-Axis (Changes dynamically as wafer map is dragged)

  XAnchorPreDrag = 0; // Pre X Anchor position before dragging

  YAnchorPreDrag = 0; // Pre Y Anchor position before dragging

  dieWidth: number; // Width of indiviual die

  dieHeight: number; // Height of indiviual die

  thresholdDieWidth = 0; // Width of die should not exceed more than this value

  thresholdDieHeight = 0; // Height of die should not exceed more than this value

  scaledDieWidth = 0; // New scaled width of die after scaling of wafer Map

  scaledDieHeight = 0; // New scaled height of die after scaling of wafer Map

  waferMapTestData: WaferMapTestData; // 2D Array of Randomly generated wafer map data

  gl: WebGL2RenderingContext | null = null; // WebGl context for dies and gridLines

  zoomGl: WebGL2RenderingContext | null = null; // WebGl context for zoom feature (Higlighting box)

  program: WebGLProgram | null = null; // WebGl Program for "this.gl" context

  horizontalGridLines: number[] = []; // 1D array for gridLines (WebGL Triangles)

  verticleGridLines: number[] = []; // 1D array for gridLines (WebGL Triangles)

  selectedDies: number[] = []; // 1D array for selected dies (WebGL Triangles)

  allDiesCoords: number[] = []; // 1D array for all dies (WebGl Triangles)

  filteredDies: number[] = []; // 1D array for all filteredDies by applying filters on datagrid (WebGL Triangles)

  dieColorMap: { [key: string]: number[] } = {}; // 1D array for Colored Dies according to bin Numbers (WebGl Triangles)

  dieBorderMap: { [key: string]: number[] } = {}; // 1D array of same dies (dieColorMap) with 90 percent of area (WebGl Triangles)

  dieColorMapSelection: { [key: string]: number[] } = {}; // 1D array for Colored Dies according to bin Numbers (WebGl Triangles)

  dieBorderMapSelection: { [key: string]: number[] } = {}; // 1D array of same dies (dieColorMap) with 90 percent of area (WebGl Triangles)

  dieColorMapSelected: { [key: string]: number[] } = {}; // 1D array for Colored Dies according to bin Numbers (WebGl Triangles)

  dieBorderMapSelected: { [key: string]: number[] } = {}; // 1D array of same dies (dieColorMap) with 90 percent of area (WebGl Triangles)

  selectionBoxOpacity = 0.5;

  selectedOpacity = 1.0; // Selected Dies Opacity

  // selectedDieDarknessFactor: number = 2;
  // binColorOpacity: number = 0.68;
  selectedDieDarknessFactor = 1.5;

  binColorOpacity = 1;

  radarWaferMapColor: number[] = [1.0, 0.0, 0.0, 0.5]; // Radar Wafer Map Color

  selectedDieColor: number[] = [1.0, 0.0, 0.0, 1.0]; // Selected Dies color

  gridLinesColor: number[] = [0.0, 0.0, 0.0, 0.2]; // Grid Lines Color

  filteredDieColor: number[] = [0.0, 0.0, 0.0, 1.0]; // Filtered Die Color Border

  radarPointerColor: number[] = [0.0, 0.0, 0.0, 0.5]; // Radar pointer box on radar view color

  zoomBoxColor: number[] = [0.0, 0.0, 0.0, 0.3]; // Zoom Box color

  selectionBoxColor: number[] = [1.0, 0.0, 0.0, 1.0]; // Selection box color

  dieCount = 0; // Number of dies rendered

  ctx: any = null; // ctx for binNumber canvas

  tickTextCtx: any = null; // tickText ctx for tick text rendering

  binTextFieldFlag = false; // Flag for Bin Number rendering

  dieImageFlag = false; // Flag for die Image rendering

  dieImageGl: WebGL2RenderingContext | null = null; // WebGl Context for rendering die images

  selectedRowKeys: any = []; // 1D array for selected data (DataGridComponent)

  filteredData: any = []; // 1D array for filtered data (DataGridComponent)

  dieSubView?: DieSubView; // SubDies Grid Coords

  dieSubViewMap: number[] = []; // SubDies Map (WebGl Triangles)

  tickTextFont = 9; // Tick Text font size in px

  scaledWaferWidth = 0; // Width of wafer map after scaling (px)

  scaledWaferHeight = 0; // Height of wafer map after scaling (px)

  radarPointer: any = null; // Shaded Black Pointer on radar (WebGl Triangle)

  radarPointerGl: WebGL2RenderingContext | null = null; // WebGl Context for Shaded Black Pointer

  radarPointerProgram: WebGLProgram | null = null; // WebGl Program for "this.radarPointerGl"

  tickLinesGl: WebGL2RenderingContext | null = null; // WebGl Context for Shaded Black Pointer

  tickLinesProgram: WebGLProgram | null = null; // WebGl Program for "this.radarPointerGl"

  XAnchorIntialPosition = 0; // Intial X Anchor Position saved

  YAnchorIntialPosition = 0; // Intial Y Anchor Position saved

  waferTopOffset: number;

  intialScaledDieWidth = 0; // Intial Scaled Die Width saved

  intialScaledDieHeight = 0; // Intial Scaled Die Height saved

  tickOffsetX = 0;

  tickOffsetY = 0;

  tickLineSize = 4;

  subdieRowStart = 0;

  subdieColStart = 0;

  rowDirection = 1; // 1 or -1

  startRow = 0; // 0 or 78

  colDirection = 1; // 1 or -1

  startCol = 0; // 0 or 38

  rowAxisIncrement = 1; // 1 or -1

  rowAxisStart = 0;

  colAxisIncrement = 1; // 1 or -1

  colAxisStart = 0;

  rowOffset = 0; // row axis numbering start

  colOffset = 0; // column axis numbering start

  rowAxisDirection: RowAxisDirection = RowAxisDirection.TopToBottom; // flips only the axis (visually)

  colAxisDirection: ColAxisDirection = ColAxisDirection.LeftToRight; // flips only the axis (visually)

  rowFlip: RowFlip = RowFlip.Upright;

  colFlip: ColFlip = ColFlip.Upright;

  markZones = false;

  zoom = false;

  drag = false;

  select = false;

  dieImages: (null | HTMLImageElement)[][] = [];

  waferBgImageTop: (null | HTMLImageElement) = null;

  waferBgImageBottom: (null | HTMLImageElement) = null;

  waferBgImageLeft: (null | HTMLImageElement) = null;

  waferBgImageRight: (null | HTMLImageElement) = null;

  waferBgImageTopFlat: (null | HTMLImageElement) = null;

  waferBgImageBottomFlat: (null | HTMLImageElement) = null;

  waferBgImageLeftFlat: (null | HTMLImageElement) = null;

  waferBgImageRightFlat: (null | HTMLImageElement) = null;

  isImagePromiseResolved = false;

  isBgImagePromiseResolved = false;

  dieImageTextureCoords: number[] | null = null;

  dieImageTextureBuffer: WebGLBuffer | null = null;

  dieImageProgram: WebGLProgram | null = null;

  dieImageCoords: number[][][] = [];

  waferBgImageCoords: number[] = [];

  ringCoords: number[] = [];

  zoomScaleFactor = 1;

  viewPortWidth = 600; // Width of View Port Canvas where wafer map is rendered

  viewPortHeight = 600; // Height of View Port Canvas where wafer map is rendered

  outerRatioWidth = 1.25;

  outerRatioHeight = 1.25;

  ratioWidth = 1.5;

  ratioHeight = 1.5;

  outerVPWidth: number = this.viewPortWidth / this.outerRatioWidth;

  outerVPHeight: number = this.viewPortHeight / this.outerRatioHeight;

  tickViewPort: Viewport = {
    width: this.outerVPWidth,
    height: this.outerVPHeight,
    x: 0,
    y: this.viewPortHeight - this.outerVPHeight,
  };

  viewPortXDiff = (this.viewPortWidth / this.outerRatioWidth - this.viewPortWidth / this.ratioWidth) / 2;

  viewPortYDiff = (this.viewPortHeight / this.outerRatioHeight - this.viewPortHeight / this.ratioHeight) / 2;

  viewport: Viewport = {
    width: this.viewPortWidth / this.ratioWidth,
    height: this.viewPortHeight / this.ratioHeight,
    x: this.tickViewPort.x + this.viewPortXDiff,
    y: this.tickViewPort.y + this.viewPortYDiff,
  };

  radarViewPort: Viewport = {
    width: this.viewPortWidth - this.tickViewPort.width,
    height: this.viewPortWidth - this.tickViewPort.width,
    x: this.tickViewPort.x + this.tickViewPort.width,
    y: this.viewPortHeight - (this.viewPortWidth - this.tickViewPort.width),
  };

  radarCanvasWidth: number = this.radarViewPort.width; // Width of radar canvas

  radarCanvasHeight: number = this.radarViewPort.height; // Height of radar canvas

  tickTextCanvasWidth: number = this.tickViewPort.width; // Width of tick text Canvas

  tickTextCanvasHeight: number = this.tickViewPort.height; // Height of tick text Canvas

  binCanvasWidth: number = this.viewport.width; // Width of tick text Canvas

  binCanvasHeight: number = this.viewport.height; // Height of tick text Canvas

  tickTextX: number[] = [];

  tickTextY: number[] = [];

  binNumX: number[] = [];

  binNumY: number[] = [];

  radarDiesCoords: number[] | null = null;

  selectionCoord: RectCoord | null = null;

  radarPointerCoord: RectCoord | null = null;

  angleInDegrees = 0;

  rotation: number[] = [Math.sin((360 - this.angleInDegrees) * (Math.PI / 180)), Math.cos((360 - this.angleInDegrees) * (Math.PI / 180))];

  showRadarOnly: boolean;

  showRotationNotch: boolean;

  dieTypeField: string;

  selectedDieField: string;

  setRotationParams = (rotationAngle: number) => {
    this.angleInDegrees = rotationAngle;
    this.rotation = [Math.sin((360 - this.angleInDegrees) * (Math.PI / 180)), Math.cos((360 - this.angleInDegrees) * (Math.PI / 180))];
  };

  showGridBordersAndTicks = true;

  setViewPortDimensions = () => {
    this.outerVPWidth = this.viewPortWidth / this.outerRatioWidth;
    this.outerVPHeight = this.viewPortHeight / this.outerRatioHeight;
    this.tickViewPort = {
      width: this.outerVPWidth,
      height: this.outerVPHeight,
      x: 0,
      y: this.viewPortHeight - this.outerVPHeight,
    };
    this.viewPortXDiff = (this.viewPortWidth / this.outerRatioWidth - this.viewPortWidth / this.ratioWidth) / 2;
    this.viewPortYDiff = (this.viewPortHeight / this.outerRatioHeight - this.viewPortHeight / this.ratioHeight) / 2;
    this.viewport = {
      width: this.viewPortWidth / this.ratioWidth,
      height: this.viewPortHeight / this.ratioHeight,
      x: this.tickViewPort.x + this.viewPortXDiff,
      y: this.tickViewPort.y + this.viewPortYDiff,
    };
    this.radarViewPort = {
      width: this.viewPortWidth - this.tickViewPort.width,
      height: this.viewPortWidth - this.tickViewPort.width,
      x: this.tickViewPort.x + this.tickViewPort.width,
      y: this.viewPortHeight - (this.viewPortWidth - this.tickViewPort.width),
    };
    this.radarCanvasWidth = this.radarViewPort.width; // Width of radar canvas
    this.radarCanvasHeight = this.radarViewPort.height; // Height of radar canvas

    this.tickTextCanvasWidth = this.tickViewPort.width; // Width of tick text Canvas
    this.tickTextCanvasHeight = this.tickViewPort.height; // Height of tick text Canvas
    this.binCanvasWidth = this.viewport.width; // Width of tick text Canvas
    this.binCanvasHeight = this.viewport.height; // Height of tick text Canvas
  };

  setViewPortDimensionsWithoutRadar = () => {
    this.outerVPWidth = this.viewPortWidth / this.outerRatioWidth;
    this.outerVPHeight = this.viewPortHeight / this.outerRatioHeight;
    this.tickViewPort = {
      width: this.outerVPWidth,
      height: this.outerVPHeight,
      x: (this.viewPortWidth - this.outerVPWidth) / 2,
      y: this.viewPortHeight - this.outerVPHeight,
    };
    this.viewPortXDiff = (this.viewPortWidth / this.outerRatioWidth - this.viewPortWidth / this.ratioWidth) / 2;
    this.viewPortYDiff = (this.viewPortHeight / this.outerRatioHeight - this.viewPortHeight / this.ratioHeight) / 2;
    this.viewport = {
      width: this.viewPortWidth / this.ratioWidth,
      height: this.viewPortHeight / this.ratioHeight,
      x: this.tickViewPort.x + this.viewPortXDiff,
      y: this.tickViewPort.y + this.viewPortYDiff,
    };
    this.radarViewPort = {
      width: 0,
      height: 0,
      x: this.tickViewPort.x + this.tickViewPort.width,
      y: this.viewPortHeight - (this.viewPortWidth - this.tickViewPort.width),
    };

    this.tickTextCanvasWidth = this.tickViewPort.width; // Width of tick text Canvas
    this.tickTextCanvasHeight = this.tickViewPort.height; // Height of tick text Canvas
    this.binCanvasWidth = this.viewport.width; // Width of tick text Canvas
    this.binCanvasHeight = this.viewport.height; // Height of tick text Canvas
  };

  setViewPortDimensionsRadarOnly = () => {
    this.outerVPWidth = this.viewPortWidth / this.outerRatioWidth;
    this.outerVPHeight = this.viewPortHeight / this.outerRatioHeight;
    this.tickViewPort = {
      width: this.outerVPWidth,
      height: this.outerVPHeight,
      x: 0,
      y: this.viewPortHeight - this.outerVPHeight,
    };
    this.viewPortXDiff = (this.viewPortWidth / this.outerRatioWidth - this.viewPortWidth / this.ratioWidth) / 2;
    this.viewPortYDiff = (this.viewPortHeight / this.outerRatioHeight - this.viewPortHeight / this.ratioHeight) / 2;
    this.viewport = {
      width: this.viewPortWidth / this.ratioWidth,
      height: this.viewPortHeight / this.ratioHeight,
      x: this.tickViewPort.x + this.viewPortXDiff,
      y: this.tickViewPort.y + this.viewPortYDiff,
    };
    this.radarViewPort = {
      width: this.viewPortWidth,
      height: this.viewPortHeight,
      x: 0,
      y: 0,
    };

    this.tickTextCanvasWidth = 0; // Width of tick text Canvas
    this.tickTextCanvasHeight = 0; // Height of tick text Canvas
    this.binCanvasWidth = 0; // Width of tick text Canvas
    this.binCanvasHeight = 0; // Height of tick text Canvas
  };

  constructor(
    key: string,
    data: WaferMapTestData,
    dieSize?: any,
    dieSubView?: DieSubView,
    showRadar = true,
    waferSize = 600,
    showGridBordersAndTicks = true,
    rotationAngle = 0,
    showRadarOnly = false,
    showRotationNotch = false,
    waferTopOffset = 0,
    padding = 20,
    rowOffset = 0,
    colOffset = 0,
    ringRadius = 170,
    markZones = false,
    dieWidthToStreetWidthRatio = 1,
    dieHeightToStreetHeightRatio = 1,
    ringDiameterToWaferDiameterRatio = 1,
    waferHeightToRowsRatio = 1,
    waferWidthToColsRatio = 1,
    waferBGOffsetXDies = 0,
    waferBGOffsetYDies = 0,
    isWaferControlMap = false,
    showRing = false,
    overlayReticle = false,
    binTextFieldFlag = false,
    reticleSize: { x: number, y: number } = { x: 0, y: 0 },
    binTextField = 'softBin',
    zoomScaleFactorFromProps = 1,
    currentDieType = '',
    dieTypes: DieType[] = [],
    markWaferCenter = false,
    reticleReference: ReticleReference = 'CENTER',
    showReferenceReticle = false,
    dieTypeField: string = undefined as unknown as string,
    isManualServiceWafer = false,
    selectedDieField: string = undefined as unknown as string,
    zones: ZoneData[] = [],
    wcmWaferDiameter = 0,
    wcmWaferEdgeExclusion = 0,
    wcmWaferNotchKeepOut = 0,
    wcmWaferFlatKeepOut = 0,
    wcmWaferBaseFlat = 0,
    wcmWaferScribeLine = 0,
    wcmExclusionType: ExclusionType = ExclusionType.NONE,
    tooltipFields: string[][] = defaultToolTipFields,
  ) {
    this.wcmExclusionType = wcmExclusionType;
    this.wcmWaferDiameter = wcmWaferDiameter;
    this.wcmWaferEdgeExclusion = wcmWaferEdgeExclusion;
    this.wcmWaferNotchKeepOut = wcmWaferNotchKeepOut;
    this.wcmWaferFlatKeepOut = wcmWaferFlatKeepOut;
    this.wcmWaferBaseFlat = wcmWaferBaseFlat;
    this.wcmWaferScribeLine = wcmWaferScribeLine;
    this.showReferenceReticle = showReferenceReticle;
    this.reticleReference = reticleReference;
    this.markWaferCenter = markWaferCenter;
    this.dieTypes = dieTypes;
    this.zones = zones;
    this.currentDieType = currentDieType;
    this.zoomScaleFactorFromProps = zoomScaleFactorFromProps;
    this.reticleSize = reticleSize;
    this.binTextField = binTextField;
    this.binTextFieldFlag = binTextFieldFlag;
    this.overlayReticle = overlayReticle;
    this.showRing = showRing;
    this.isWaferControlMap = isWaferControlMap;
    this.isManualServiceWafer = isManualServiceWafer;
    this.waferBGOffsetXDies = waferBGOffsetXDies;
    this.waferBGOffsetYDies = waferBGOffsetYDies;
    this.waferHeightToRowsRatio = waferHeightToRowsRatio;
    this.waferWidthToColsRatio = waferWidthToColsRatio;
    this.ringDiameterToWaferDiameterRatio = ringDiameterToWaferDiameterRatio;
    this.dieWidthToStreetWidthRatio = dieWidthToStreetWidthRatio;
    this.dieHeightToStreetHeightRatio = dieHeightToStreetHeightRatio;
    this.markZones = markZones;
    this.ringRadius = ringRadius;
    this.waferMapPadding = padding;
    this.waferTopOffset = waferTopOffset;
    this.showRotationNotch = showRotationNotch;
    this.showRadarOnly = showRadarOnly;
    this.showGridBordersAndTicks = showGridBordersAndTicks;
    this.setRotationParams(rotationAngle);
    this.viewPortHeight = waferSize;
    this.viewPortWidth = waferSize;
    this.rowOffset = rowOffset;
    this.colOffset = colOffset;
    this.dieTypeField = dieTypeField;
    this.selectedDieField = selectedDieField;
    this.tooltipFields = tooltipFields;
    if (waferSize !== 600) {
      this.setViewPortDimensions();
    }
    if (!showRadar) {
      this.setViewPortDimensionsWithoutRadar();
    }
    if (showRadarOnly) {
      this.setViewPortDimensionsRadarOnly();
    }
    this.keyIndex = key; // Index of Wafer Map
    this.waferMapTestData = data; // 2D Array of Randomly generated wafer map data
    this.dieSubView = dieSubView; // SubDies Grid Coords
    this.dieWidth = dieSize.dieWidth;
    this.dieHeight = dieSize.dieHeight;
    if (this.rowFlip === RowFlip.Inverted) {
      this.rowDirection = -1;
      this.startRow = this.waferMapTestData.length - 1;
      this.subdieRowStart = this.dieSubView!.length - 1;
      this.rowAxisDirection = this.rowAxisDirection === RowAxisDirection.BottomToTop ? RowAxisDirection.TopToBottom : RowAxisDirection.BottomToTop;
    }
    if (this.colFlip === ColFlip.Inverted) {
      this.colDirection = -1;
      this.startCol = this.waferMapTestData[0].length - 1;
      this.subdieColStart = this.dieSubView![0].length - 1;
      this.colAxisDirection = this.colAxisDirection === ColAxisDirection.RightToLeft ? ColAxisDirection.LeftToRight : ColAxisDirection.RightToLeft;
    }
    if (this.colAxisDirection === ColAxisDirection.RightToLeft) {
      this.colAxisIncrement = -1;
      this.colAxisStart = this.waferMapTestData[0].length - 1;
    }

    if (this.rowAxisDirection === RowAxisDirection.BottomToTop) {
      this.rowAxisIncrement = -1;
      this.rowAxisStart = this.waferMapTestData.length - 1;
    }
  }
}

export default WaferMapVariables;
