/* eslint-disable no-param-reassign */
import { EntityType, IGenericDetailedReportRequestObject, } from 'interfaces';
import GeneralUtils, { ReportDataTypeConstants, ReportTypeConstants as ReportType } from 'GeneralUtils';
import _ from 'lodash';
import React, { Ref } from 'react';
import { Spinner } from 'react-bootstrap';
import { ListRange, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import AppConstants from '../../../constants.js';
import PubSubBinder, { ActionType } from '../PublishSubscribeBinder';
import { IGraphDataHelper } from './customized-report-helpers/CustomizedReportHelper';
import { AggregateFunctionNames, ICombinedGraphDataWithReportHeaders, SeriesTypeNames, } from './customized-report-helpers/CustomizedReportInterfaces';
import { GraphAndLegendDataHelpersFactory, ReportCollectionPreferences } from './customized-report-helpers/CustomizedReportMapping';
import { ICustomizedReportProps } from './CustomizedReport';
import { IActorDetails, REPORT_HEADER_CONTROLS_DIV_ID, SELECTION_CRITERIA_DIV_ID } from './CustomizedReportsCollection';

export interface IScrollableReportProps {
  renderReportsList: JSX.Element[];
  isClosedSomeReport: boolean,
}

export interface IScrollableReportState {
  renderAgain: boolean;
}

const ScrollSeekPlaceholder = ({ height }: any) => (
  <div
    style={{
      height,
      padding: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      alignContent: 'center',
    }}
  >
    <div className="h180 w-100 d-flex align-items-center justify-content-center">
      <Spinner
        as="span"
        animation="border"
        role="status"
        aria-hidden="true"
      />
    </div>
  </div>
);

const ReportsFooter = () => {
  return (
    <div style={{ padding: '1rem', textAlign: 'center' }}>
      <div className="h20 w-100 d-flex align-items-center justify-content-center" />
    </div>
  );
};

const VIRTUOSO_PARENT_DIV_ID = 'virtuosoParentDiv';

class ScrollableReports extends React.Component<IScrollableReportProps, IScrollableReportState> {
  private scrollableReportsPubSubID: string;

  private renderReportsList: JSX.Element[];

  private indexOfFirstReportWithData: number;

  private indexOfLastReportWithData: number;

  private pubSubBinder = PubSubBinder();

  private virtuosoRef: Ref<VirtuosoHandle> | undefined;

  private batchSize = 10;

  private reportRequestsBlockedIndexes: number[] = [];

  private reportIndicesAlreadyBeingFetched: number[] = [];

  constructor(props: IScrollableReportProps) {
    super(props);
    const {
      renderReportsList,
    } = this.props;
    this.renderReportsList = renderReportsList;
    this.indexOfFirstReportWithData = 0;
    this.indexOfLastReportWithData = 0;
    this.state = {
      renderAgain: true,
    };
    this.virtuosoRef = React.createRef();
    this.scrollableReportsPubSubID = this.pubSubBinder.RegisterActor(
      'GENERAL_ACTION',
      this.reportSubscriptionListener,
    );
  }

  componentDidUpdate(prevProps: IScrollableReportProps) {
    const { renderReportsList, isClosedSomeReport } = this.props;
    const { renderAgain } = this.state;
    // load first batch of reports
    if (prevProps.renderReportsList !== renderReportsList) {
      if (renderReportsList.length === 0 && isClosedSomeReport){
        this.renderReportsList = [];
        this.setState({ renderAgain: !renderAgain });
      } else {
        this.addMissingPropsToRenderReportsList();
        // initially load twice the batch size, load more as user scrolls by batch size
        const indecesList = _.range(0, this.batchSize * 2 > renderReportsList.length ? renderReportsList.length : this.batchSize * 2);
        if (renderReportsList.length > 0) {
          if (!isClosedSomeReport) {
            this.reportRequestsBlockedIndexes = [0, 1, 2, 3];
            this.batchRequestLoader(indecesList)
          }
          this.setState({ renderAgain: !renderAgain })
        }
      }
    }
  }

  saveData = (data: any, reportIndex: number, dataType: string) => {
    const { renderReportsList } = this;
    renderReportsList[reportIndex].props[dataType] = data;
    if (dataType === ReportDataTypeConstants.MODIFIERS){
      renderReportsList[reportIndex].props.legendData = undefined;
    }
  };

  reportSubscriptionListener = (action: ActionType, params?: any) => {
    const { renderReportsList } = this;
    // interaction propagated via RDG
    if (params && params.reportIndex === -1){
      if (renderReportsList.length === 0){
        return;
      }
      params.reportIndex = this.reportRequestsBlockedIndexes.length > 0 ? this.reportRequestsBlockedIndexes[0] : 0;
    }
    if (action === 'EXPAND_LEGEND_EVENT') {
      renderReportsList.forEach((renderReport: JSX.Element) => {
        renderReport.props.showLegend = true;
      });
    } else if (action === 'COLLAPSE_LEGEND_EVENT') {
      renderReportsList.forEach((renderReport: JSX.Element) => {
        renderReport.props.showLegend = false;
      });
    } else if (action === 'SELECT_GRAPH_AND_LEGEND_ITEMS' || action === 'HIDE_GRAPH_AND_LEGEND_ITEMS') {
      renderReportsList.forEach((renderReport: JSX.Element) => {
        renderReport.props.graphData = undefined;
        renderReport.props.legendData = undefined;
      });
      if (params){
        const reportIndeces = this.reportIndecesToLoadAfterInteractions(params.reportIndex)
        if (reportIndeces.length !== 0) {
          this.batchRequestLoader(reportIndeces);
        // if (this.virtuosoRef !== undefined && this.virtuosoRef !== null) {
        // this.virtuosoRef.current.scrollToIndex({
        //   index: params.reportIndex,
        //   align: 'center',
        //   behavior: 'smooth',
        // });
        // }
        }
      }
    } else if (action === 'APPLY_REPORT_HEADER' && params !== undefined && params.reportHeaderId) {
      renderReportsList.forEach((renderReport: JSX.Element) => {
        renderReport.props.headerData = undefined;
        renderReport.props.updatedHeaderId = params.reportHeaderId;
        renderReport.props.isApplyAllReportHeaders = true;
      });
      const reportIndeces = this.reportIndecesToLoadAfterInteractions(params.reportIndex)
      if (reportIndeces.length !== 0) {
        this.batchRequestLoader(reportIndeces);
      }
    }
  };

  reportIndecesToLoadAfterInteractions = (reportIndex: number) => {
    const { renderReportsList, batchSize } = this;
    const firstReportWithData = reportIndex - _.toInteger(batchSize / 2) <= 0 ? 0 : reportIndex - _.toInteger(batchSize / 2);
    const lastReportWithData = reportIndex + _.toInteger(batchSize / 2) >= renderReportsList.length ? renderReportsList.length - 1 : reportIndex + _.toInteger(batchSize / 2);
    this.indexOfFirstReportWithData = firstReportWithData;
    this.indexOfLastReportWithData = lastReportWithData;
    return _.range(firstReportWithData, lastReportWithData + 1);
  }

  batchRequestLoader = (indecesList: number[]) => {
    const { renderReportsList, indexOfLastReportWithData, indexOfFirstReportWithData } = this;

    indecesList.forEach((val: number) => {
      if (renderReportsList[val] && !this.reportRequestsBlockedIndexes.includes(val) && AppConstants.ALLOW_REPORTS_RETRIEVAL_BATCHING) {
        if (renderReportsList[val].props.report.actor === 'DATA_SUMMARY') {
          return; // do not load data for data summary report, it is loaded in the report itself
        }
        const graphHelper = (GraphAndLegendDataHelpersFactory(renderReportsList[val].props.report.actor) as IGraphDataHelper);
        const reportPreferences = (graphHelper as IGraphDataHelper).getPreferences();
        const aggregateFunction = (reportPreferences.allowDefaultGrouping && renderReportsList[val].props.groupingSortingListStore.grouping.length > 0 ? 'NONE' : null);
        const requestObject = this.getRequestObject(renderReportsList[val].props, aggregateFunction);
        this.reportIndicesAlreadyBeingFetched.push(val);
        (graphHelper as IGraphDataHelper).getDetailedGraphData(requestObject, (dataObj: ICombinedGraphDataWithReportHeaders) => {
          renderReportsList[val].props.graphData = dataObj.flatGraphDataDto;
          renderReportsList[val].props.headerData = dataObj.reportHeaderSetting;
          renderReportsList[val].props.saveData = this.saveData;
          this.reportIndicesAlreadyBeingFetched = this.reportIndicesAlreadyBeingFetched.filter((x: number) => x !== val);
        });
      } else if (this.reportRequestsBlockedIndexes.includes(val) || !AppConstants.ALLOW_REPORTS_RETRIEVAL_BATCHING) {
        renderReportsList[val].props.saveData = this.saveData;
      }
    });
    this.indexOfLastReportWithData = Math.max(...indecesList) > indexOfLastReportWithData ? Math.max(...indecesList) : indexOfLastReportWithData;
    this.indexOfFirstReportWithData = Math.min(...indecesList) < indexOfFirstReportWithData ? Math.min(...indecesList) : indexOfFirstReportWithData;
  }

  getDefaultSeriesType = (report: IActorDetails) => {
    let seriesType: SeriesTypeNames = 'SEPARATE';
    if (report.actor === ReportType.BIN_PARETO || report.actor === ReportType.PARAMETRIC_HISTOGRAM) {
      seriesType = 'SINGLE';
    }
    return seriesType;
  }

  getRequestObject = (
    props: ICustomizedReportProps,
    aggregateFunction: AggregateFunctionNames,
  ) => {
    const {
      selectedBinPlusDefinition,
      parseFilter,
      selectionStore,
      groupingSortingListStore,
      reportSessionId,
      report,
      testParameterIndex,
      selectionStoreDataSetCount,
      productType,
      type,
      isSoftBin
    } = props;
    const viewMode = type && type === 'SIMULATE_POLICY' ? 'DETAILED' : 'COMBINED';
    const data: IGenericDetailedReportRequestObject = {
      productType,
      selectionStoreDataSetCount,
      scwData: selectionStore.selections.map((item) => ({
        entityType: item.controlType as EntityType,
        values: item.values.map((val) => val.id),
      })),
      filters: JSON.stringify(parseFilter(selectionStore)),
      binPlusDefId: selectedBinPlusDefinition.id,
      grouping: groupingSortingListStore.grouping,
      sorting: groupingSortingListStore.sorting,
      isFlatGraph: viewMode === 'COMBINED',
      reportSessionId,
      function: ReportCollectionPreferences[report.actor]?.useAggregateFunctions ? aggregateFunction : null,
      seriesType: this.getDefaultSeriesType(report),
      config: report.actor === ReportType.PARAMETRIC_FAILURE ? this.onChangeReportConfigValues([
        {
          key: 'parametricFailureReportOptions',
          value: {
            type: 'FAILURE',
            count: 3,
            trend: 'TOP',
          },
        },
        {
          key: 'softBins',
          value: [],
        },
        {
          key: 'waferIdsForOverlay',
          value: [],
        }]) : {},
      reportType: report.actor,
      testParameterIndex,
      hasNonSequentialXValues: report.actor === ReportType.PARAMETRIC_XY_SCATTER_PLOT ? true : undefined,
      headerSettingsRequestObj: undefined,
      isSoftBin,
    };
    return data;
  };

  onChangeReportConfigValues = (keyValuePairs: { key: string, value: any }[]) => {
    const config: { [key: string]: any } = {};
    for (let i = 0; i < keyValuePairs.length; i += 1) {
      // eslint-disable-next-line react/destructuring-assignment
      config[keyValuePairs[i].key] = keyValuePairs[i].value;
    }
    return config;
  };

  addMissingPropsToRenderReportsList = () => {
    const { renderReportsList, isClosedSomeReport } = this.props;
    const newRenderReportsList = _.cloneDeep(renderReportsList);
    let currentIndex = 0;
    for (let i = 0; i < this.renderReportsList.length && isClosedSomeReport; i += 1) {
      if (newRenderReportsList.length > currentIndex && this.renderReportsList[i].props.report.actor === newRenderReportsList[currentIndex].props.report.actor){
        newRenderReportsList[currentIndex].props.isClosedSomeReport = true;
        // eslint-disable-next-line no-loop-func
        Object.values(GeneralUtils.ReportDataTypeConstants).forEach((value: string) => {
          const propData = this.renderReportsList[i].props[value];
          if (propData) {
            newRenderReportsList[currentIndex].props[value] = propData;
          }
        });
        currentIndex += 1;
      }
    }
    this.renderReportsList = newRenderReportsList;
  };

  onScroll = (event: any) => {
    const reportHeaderControlsDiv = document.getElementById(REPORT_HEADER_CONTROLS_DIV_ID);
    const vituosoParentDiv = document.getElementById(VIRTUOSO_PARENT_DIV_ID);
    const selectionCriteriaDiv = document.getElementById(SELECTION_CRITERIA_DIV_ID);
    const height = window.innerHeight - (reportHeaderControlsDiv ? reportHeaderControlsDiv.clientHeight : 0) - (selectionCriteriaDiv ? selectionCriteriaDiv.clientHeight : 0) - 50;
    if (event.target.hasAttribute('data-test-id') && vituosoParentDiv) {
      vituosoParentDiv.style.height = `${height}px`;
    }
  };

  renderRangeChanged = (range: ListRange) => {
    const {
      indexOfLastReportWithData, indexOfFirstReportWithData, renderReportsList
    } = this;
    // on scrolling down
    const newRenderReportList = [...renderReportsList];
    if (indexOfLastReportWithData - range.endIndex < _.toInteger(this.batchSize / 2)) {
      const renderStartIndex = indexOfLastReportWithData + 1;
      const renderEndIndex = renderStartIndex + this.batchSize;
      const indecesList: number[] = [];
      newRenderReportList.slice(renderStartIndex, renderEndIndex).forEach((report: JSX.Element) => {
        if (report.props.graphData === undefined || report.props.headerData === undefined) {
          indecesList.push(report.props.reportIndex);
        }
      });
      if (indecesList.length > 0) {
        this.batchRequestLoader(indecesList.filter((x: number) => !this.reportIndicesAlreadyBeingFetched.includes(x)));
      }
    }
    // on scrolling up
    if (range.startIndex - indexOfFirstReportWithData < _.toInteger(this.batchSize / 2)) {
      const renderStartIndex = indexOfFirstReportWithData - 1 <= 0 ? 0 : indexOfFirstReportWithData - 1;
      const renderEndIndex = renderStartIndex - this.batchSize <= 0 ? 0 : renderStartIndex - this.batchSize;
      const indecesList: number[] = [];
      newRenderReportList.slice(renderEndIndex, renderStartIndex).forEach((report: JSX.Element) => {
        if (report.props.graphData === undefined || report.props.headerData === undefined) {
          indecesList.push(report.props.reportIndex);
        }
      });
      if (indecesList.length > 0) {
        this.batchRequestLoader(indecesList.filter((x: number) => !this.reportIndicesAlreadyBeingFetched.includes(x)));
      }
    }
  }

  render() {
    const { renderReportsList } = this;
    const selectionCriteriaDiv = document.getElementById(SELECTION_CRITERIA_DIV_ID);
    const height = window.innerHeight - 2 * (selectionCriteriaDiv ? selectionCriteriaDiv.clientHeight : 0) - 50;
    return (
      <div onScroll={this.onScroll} style={{ height }} id={VIRTUOSO_PARENT_DIV_ID}>
        {renderReportsList && renderReportsList.length > 0 ? (
          <Virtuoso
            totalCount={renderReportsList?.length}
            itemContent={(index: number) => renderReportsList[index]}
            ref={this.virtuosoRef}
            components={{ Footer: ReportsFooter }}
            // scrollSeekConfiguration={{
            //     enter: (velocity) => Math.abs(velocity) > 2000,
            //  åß   exit: (velocity) => Math.abs(velocity) < 500,
            // }}
            overscan={{ reverse: 1200, main: 1200 }}
            rangeChanged={AppConstants.ALLOW_REPORTS_RETRIEVAL_BATCHING ? this.renderRangeChanged : undefined}
            itemsRendered={(items: any) => {
              const indexes = items.map((item: any) => item.index);
              this.reportRequestsBlockedIndexes = indexes;
            }}
          />
        ) : <></>}
      </div>
    );
  }
}

export default ScrollableReports;
