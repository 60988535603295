/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Hint from './Hint/Hint';
// eslint-disable-next-line no-unused-vars
import { IHint } from '../../../interfaces.js';
import { ErrorLabel } from '../../template-component/form-error-label/ErrorLabel';

interface IButtonProps {
  children: React.ReactNode;
  hint?: IHint;
  onLabelEdited?: (label: string) => void;
  labelTitle:string;
  labelInput?: boolean,
  labelPosition: 'top' | 'left-top' | 'left-middle';
  labelSize: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  fieldSize: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  errorSize: '0' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  error?: string;
  isFieldTouched?:boolean;
  required?:boolean;
  childrenItemsInline?:boolean;
  className?:string;
  fontSize?: string,
  style?:any,
}

const Label = (props:IButtonProps) => {
  const {
    children, hint, labelTitle, labelPosition, labelSize, fieldSize, errorSize, error,
    isFieldTouched, required, childrenItemsInline, className, labelInput, onLabelEdited, fontSize, style,
  } = props;

  const [labelState, setLabelState] = useState({
    inEditMode: false,
  });

  const labelInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (labelInputRef.current !== null && labelState.inEditMode) {
      labelInputRef.current.focus();
    }
  }, []);

  const labelWidthClass = labelPosition === 'top' ? 'custom-form' : 'custom-form-inline';

  const labelStyleClass = labelPosition === 'top' ? 'pt6 pb4 d-block' : '';

  const fieldStyleClass = labelPosition === 'top' ? 'pb4' : '';

  let errorRightClass = ' ';

  let errorPosition: 'bottom' | 'right';

  let labelPositionClass = '';
  if (labelPosition === 'top') {
    labelPositionClass = 'top';
    errorPosition = 'bottom';
  } else if (labelPosition === 'left-middle') {
    labelPositionClass = 'left';
    errorRightClass += ' pl10 mt-4 ';
    errorPosition = 'right';
  } else {
    labelPositionClass = 'textarea';
    errorRightClass += ' pl10 mt-4 ';
    errorPosition = 'right';
  }

  const Error = (
    <ErrorLabel
      className={errorRightClass}
      size={errorSize}
      error={error}
      isTouched={isFieldTouched}
    />
  );

  let childrenItems;
  if (childrenItemsInline) {
    childrenItems = (
      <div className="w-100 flex-100 d-flex align-items-center">
        {children}
      </div>
    );
  } else {
    childrenItems = children;
  }
  return (
    <div className={`${className} ${labelWidthClass} label-${labelPositionClass} ${isFieldTouched && error !== '' ? 'mb16' : ''}`}>
      <span
        ref={(el) => {
          if (el) {
            el.style.setProperty('margin-top', '0px', 'important');
          }
        }}
        className={`label flex-${labelSize} ${labelStyleClass}`}
      >
        {!labelInput && <span style={style ? { fontSize, ...style } : { fontSize }}>{labelTitle}</span>}
        {labelInput && (
          <span>
            <input
              defaultValue={labelTitle}
              ref={labelInputRef}
              type="text"
              readOnly={!labelState.inEditMode}
              style={{ padding: '0px', maxWidth: '25%', border: 'none' }}
            />
            <i
              className="icon dx-icon-edit"
              onClick={() => {
                if (labelInputRef.current !== null) {
                  labelInputRef.current.focus();
                }
                setLabelState({ inEditMode: true });
              }}
              style={{ display: !labelState.inEditMode ? 'inline' : 'none' }}
            />
            <i
              className="icon dx-icon-check"
              onClick={() => {
                if (onLabelEdited && labelInputRef.current) onLabelEdited(labelInputRef.current.value);
                setLabelState({ inEditMode: false });
              }}
              style={{ display: labelState.inEditMode ? 'inline' : 'none' }}
            />
          </span>
        )}
        {required && <span className="required">*</span>}
        <Hint hint={hint} variant="icon" />
      </span>
      <div className={`flex-${fieldSize} ${fieldStyleClass}`}>
        {childrenItems}
        {errorPosition === 'bottom' && error !== '' && Error}
      </div>
      {errorPosition === 'right' && error !== '' && Error}
    </div>
  );
};

Label.defaultProps = {
  error: '',
  isFieldTouched: false,
  required: false,
  childrenItemsInline: false,
  className: '',
  fontSize: '12px',
};

export default Label;
