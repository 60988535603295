import CustomizedDataGrid from 'components/wrapped-component/customized-data-grid/CustomizedDataGrid';
import Button from 'components/wrapped-component/hint-controls/Button';
import { IFieldList } from 'interfaces';
import _, { forEach } from 'lodash';
import React, { Component } from 'react';
import {
  Col, OverlayTrigger, Popover, Row, Table,
} from 'react-bootstrap';
import { httpBinHistogram } from 'services/http.bin-histogram';
import { DataFilters } from '../DataFilters';
import FieldChooser from './field-chooser';
import './raw-datagrid.scss';

interface IRawDataGrid {
  filterExpression?: any,
  dataFieldPanel: DataFilters,
  syncFilterValues?: any,
  advancedFilterInput?:any,
}
class RawDataGrid extends Component<IRawDataGrid, any> {
  private rawData: any = [];

  private rawDataSplited: any = [];

  private fieldsMerged:any = [];

  private fieldsSplited:any = [];

  private fieldsUnpivoted:any = [];

  private filterExpression: any;

  private filteredCount:any;

  private parametersList:any;

  constructor(props: any) {
    super(props);
    this.setCustomGridInstanceHandler = this.setCustomGridInstanceHandler.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.cacheFilterValue = this.cacheFilterValue.bind(this);
    this.switchMergeView = this.switchMergeView.bind(this);
    this.switchSplitMode = this.switchSplitMode.bind(this);
    this.customizedHeader = this.customizedHeader.bind(this);
    this.applyColumnChanges = this.applyColumnChanges.bind(this);
    this.switchUnpivotView = this.switchUnpivotView.bind(this);
    this.getFields = this.getFields.bind(this);
    this.state = {
      customizedDataGridInstances: {},
      splitColumnMode: false,
      unpivotView: false,
      parameterFields: [{
        caption: 'Parameter Number',
        dataField: 'number',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'Parameter Name',
        dataField: 'name',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'Parameter Value',
        dataField: 'param_Value',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'Test Unit',
        dataField: 'test_unit',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'Low Limit',
        dataField: 'low_ctrl_limit',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'High Limit',
        dataField: 'high_ctrl_limit',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'Low Spec',
        dataField: 'low_spec_limit',
        dataType: 'string',
        minWidth: 100,
        selected: true,
      }, {
        caption: 'High Spec',
        dataField: 'high_spec_limit',
        dataType: 'string',
        minWidth: 100,
        selected: true,

      },
      // , {   caption: 'Res Scale',
      //   dataField: 'res_scale',
      //   dataType: 'string',
      //   minWidth: 100,
      //   selected: false,
      // }, {
      //   caption: 'HLM Scale',
      //   dataField: 'hlm_scale',
      //   dataType: 'string',
      //   minWidth: 100,
      //   selected: false,
      // },
      // {
      //   caption: 'LML Scale',
      //   dataField: 'lml_scale',
      //   dataType: 'string',
      //   minWidth: 100,
      //   selected: false,
      // }
      ],
    };
  }

  // toggleSplitMode() {
  //   const { splitColumnMode, unpivotView } = this.state;
  //   if (!unpivotView) { this.setState({ splitColumnMode: !splitColumnMode }); } else this.getRawData();
  // }

  switchSplitMode() {
    const { unpivotView, splitColumnMode } = this.state;
    if (splitColumnMode) { return; }
    if (unpivotView) {
      this.setState({ splitColumnMode: true }, () => {
        this.getRawData();
      });
    } else {
      this.setState({ splitColumnMode: true });
    }
  }

  switchMergeView() {
    const { unpivotView, splitColumnMode } = this.state;
    if (!splitColumnMode) return;
    if (unpivotView) {
      this.setState({ splitColumnMode: false }, () => {
        this.getRawData();
      });
    } else {
      this.setState({ splitColumnMode: false });
    }
  }

  switchUnpivotView() {
    const { unpivotView } = this.state;
    if (unpivotView) return;
    this.getUnpivotedData();
  }

  stringToColorCode(str:string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 3) - hash);
    }
    const c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return `#${'00000'.substring(0, 6 - c.length) + c}`;
  }

  componentDidMount() {
    const { dataFieldPanel } = this.props;
    httpBinHistogram.getRawData(dataFieldPanel)
      .then((data: any) => {
        // console.log(data, 'RAWDATA');
        this.rawData = data.rawData;
        this.parametersList = data.testParameters;
        this.setState({ dataLoaded: true });
      });
  }

  getRawData() {
    const { dataFieldPanel } = this.props;
    httpBinHistogram.getRawData(dataFieldPanel)
      .then((data: any) => {
        this.rawData = data.rawData;
        this.parametersList = data.testParameters;
        this.setState({ dataLoaded: true, unpivotView: false });
      });
  }

  getUnpivotedData() {
    const { dataFieldPanel } = this.props;
    httpBinHistogram.getRawDataUnpivoted(dataFieldPanel)
      .then((data: any) => {
        this.rawData = data.rawData;
        this.parametersList = data.testParameters;
        this.setState({ unpivotView: true, splitColumnMode: false });
      });
  }

  onValueChanged(e: any) {
    this.setState({
      gridFilterValue: e.value,
    });
  }

  setCustomGridInstanceHandler(customizedDataGrid: CustomizedDataGrid, keyIndex: string) {
    const { customizedDataGridInstances } = this.state;
    customizedDataGridInstances[keyIndex] = customizedDataGrid;
  }

  getFields() {
    const { splitColumnMode, unpivotView } = this.state;
    if (splitColumnMode && !unpivotView) {
      return this.fieldsSplited;
    }
    if (!splitColumnMode && !unpivotView) {
      return this.fieldsMerged;
    }

    return this.fieldsUnpivoted;
  }

  customizedHeader(e: any) {
    if (e.column.dataField.indexOf('÷') !== -1) {
      const paramInfo = e.column.dataField.split('÷');
      const parameterFilter:any = _.filter(this.parametersList, (testParameter) => {
        if (testParameter.tableName === paramInfo[0] && testParameter.number.toString() === paramInfo[1]) { return testParameter; }
      });
      const testParameter:any = parameterFilter[0];
      if (testParameter === undefined) { return e.column.caption; }
      const { parameterFields } = this.state;

      const nameSelected = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'name' && paramField.selected;
      });
      const numberSelected = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'number' && paramField.selected;
      });

      const testUnit = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'testUnit' && paramField.selected;
      });

      const lowLimit = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'low_ctrl_limit' && paramField.selected;
      });
      const highLimit = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'high_ctrl_limit' && paramField.selected;
      });

      const lowSpec = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'low_spec_limit' && paramField.selected;
      });
      const highSpec = _.findIndex(parameterFields, (paramField:any) => {
        return paramField.dataField === 'high_spec_limit' && paramField.selected;
      });

      const resScale = '';
      const hlmScale = '';
      const llmScale = '';
      const popover: any = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Parameters Detail</Popover.Title>
          <Popover.Content>
            <Table bordered>
              <tr style={{ color: `${this.stringToColorCode('Parameter Number')}` }}>
                <td>
                  Parameter Number :
                </td>
                <td>
                  <b>
                    {numberSelected}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('Parameter Name')}` }}>
                <td>
                  Parameter Name :
                </td>
                <td>
                  <b>
                    {testParameter.name}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('Test Unit')}` }}>
                <td>
                  Test Unit :
                </td>
                <td>
                  <b>
                    {testParameter.testUnit}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('Low Sepc')}` }}>
                <td>
                  Low Sepc :
                </td>
                <td>
                  <b>
                    {testParameter.lowSpecLimit}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('High Sepc')}` }}>
                <td>
                  High Sepc :
                </td>
                <td>
                  <b>
                    {testParameter.highSpecLimit}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('Low Limit')}` }}>
                <td>
                  Low Limit :
                </td>
                <td>
                  <b>
                    {testParameter.lowCtrlLimit}
                  </b>
                </td>
              </tr>
              <tr style={{ color: `${this.stringToColorCode('High Limit')}` }}>
                <td>
                  High Limit :
                </td>
                <td>
                  <b>
                    {testParameter.highCtrlLimit}
                  </b>
                </td>
              </tr>
            </Table>
          </Popover.Content>
        </Popover>
      );

      return (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement="bottom"
          overlay={popover}
        >
          <div>
            <span className="p2" style={{ color: `${this.stringToColorCode('Parameter Number')}` }}>
              [
              {testParameter.number}
              ]
              <br />
            </span>
            {
              nameSelected !== -1 ? (
                <span className="p2" style={{ color: `${this.stringToColorCode('Parameter Name')}` }}>
                  {testParameter.name}
                </span>
              ) : ''
            }
            {testUnit !== undefined ? (
              <span className="p2" style={{ color: `${this.stringToColorCode('Test Unit')}` }}>
                <br />
                {testParameter.testUnit}
              </span>
            ) : ''}
            {lowSpec !== -1 ? (
              <span>
                {
                  lowSpec !== -1
                    ? (
                      <span className="p2" style={{ color: `${this.stringToColorCode('Low Spec')}` }}>
                        <br />
                        {testParameter.lowSpecLimit}
                      </span>
                    ) : ''
                }
                {
                  highSpec !== -1
                    ? (
                      <span className="p2" style={{ color: `${this.stringToColorCode('High Spec')}` }}>
                        {testParameter.highCtrlLimit}
                      </span>
                    ) : ''
                }
              </span>
            ) : ''}
            {lowLimit !== -1 ? (
              <span>
                {
                  lowLimit !== -1
                    ? (
                      <span className="p2" style={{ color: `${this.stringToColorCode('Low Limit')}` }}>
                        <br />
                        {testParameter.lowCtrlLimit}
                      </span>
                    ) : ''
                }
                {
                  highLimit !== -1
                    ? (
                      <span className="p2" style={{ color: `${this.stringToColorCode('High Limit')}` }}>
                        {testParameter.highCtrlLimit}
                      </span>
                    ) : ''
                }
              </span>

            ) : ''}
          </div>
        </OverlayTrigger>
      );
    }
    return e.column.caption;
  }

  cacheFilterValue(filterExpression: any, advancedFilterInput:any) {
    this.filterExpression = filterExpression;
    const { syncFilterValues } = this.props;
    if (syncFilterValues !== undefined) {
      syncFilterValues(this.filterExpression.replaceAll('÷', '.'), advancedFilterInput);
    }
  }

  getDefaultFields() {
    const field: Array<IFieldList> = [
      {
        caption: 'Facility [F]',
        dataField: 'facility',
        dataType: 'string',
        minWidth: 100,
        fields: [{
          caption: 'Name',
          dataField: 'facility÷name',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[F].Name',
        }, {
          caption: 'Type',
          dataField: 'facility÷type',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[F].Type',
        }, {
          caption: 'Building Number',
          dataField: 'facility÷building_number',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].Building Number',
        }, {
          caption: 'Country',
          dataField: 'facility÷country',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].country',
        }, {
          caption: 'City',
          dataField: 'facility÷city',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].City',
        }, {
          caption: 'Email',
          dataField: 'facility÷email',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].Email',
        }, {
          caption: 'Phone',
          dataField: 'facility÷phone',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].Phone',
        }, {
          caption: 'Contact Person',
          dataField: 'facility÷contact_name',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].Contact Person',
        }, {
          caption: 'Service Offered',
          dataField: 'facility÷services_offered',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[F].Service Offered',
        }],
      }, {
        caption: 'Work Center [WC]',
        dataField: 'work_center',
        dataType: 'string',
        minWidth: 100,
        fields: [{
          caption: 'Name',
          dataField: 'work_center÷name',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[WC].Name',
        },
        {
          caption: 'Test Temperature',
          dataField: 'work_center÷test_temperature',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[WC].Test Temperature',
        }, {
          caption: 'Owner',
          dataField: 'work_center÷owner',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[WC].Owner',
        }, {
          caption: 'Email',
          dataField: 'work_center÷email',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[WC].Email',
        }],
      }, {
        caption: 'Test Program [TP]',
        dataField: 'test_program',
        dataType: 'string',
        minWidth: 100,
        fields: [{
          caption: 'Name',
          dataField: 'test_program÷name',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[TP].Name',
        }, {
          caption: 'Revision Number',
          dataField: 'test_program_revision÷revision_number',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[TP].Revision Number',
        }],
      }, {
        dataField: 'device',
        caption: 'Device [D]',
        dataType: 'string',
        minWidth: 100,
        fields: [{
          dataField: 'device÷name',
          caption: 'Device',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[D].Name',
        }, {
          dataField: 'device_family÷name',
          caption: 'Device Family',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[D].Device Family',
        }, {
          dataField: 'device÷gdpw_value',
          caption: 'GDPW Value',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].GDPW Value',
        }, {
          dataField: 'device÷target_yield',
          caption: 'Target Value',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].Target Value',
        }, {
          dataField: 'device÷owner',
          caption: 'Owner',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].owner',
        }, {
          dataField: 'device÷market_segment',
          caption: 'Market Segment',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].Market Segment',
        }, {
          dataField: 'device÷technology_source',
          caption: 'Technology Source',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].Technology Source',
        }, {
          dataField: 'device÷package_type',
          caption: 'Package Type',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].package type',
        }, {
          dataField: 'device÷wafer_size',
          caption: 'Wafer Size',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].Wafer Size',
        }, {
          dataField: 'device÷manufacturer_source',
          caption: 'Manufacturer Source',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[D].Manufacturer Source',
        }],
      }, {
        dataField: 'lot',
        caption: 'Lot [L]',
        dataType: 'string',
        minWidth: 100,
        fields: [{
          dataField: 'lot÷key',
          caption: 'Lot',
          dataType: 'string',
          minWidth: 100,
          selected: true,
          filterColumn: '[L].Key',
        },
        {
          caption: 'Lot Start Time',
          dataField: 'lot÷start_time',
          dataType: 'datetime',
          minWidth: 100,
          selected: true,
          filterColumn: '[L].Lot Start Time',
        },
        {
          caption: 'Lot Finish Time',
          dataField: 'lot÷end_time',
          dataType: 'datetime',
          minWidth: 100,
          selected: true,
          filterColumn: '[L].Lot Finish Time',
        },
        {
          caption: 'Test Temperature',
          dataField: 'lot÷test_temperature',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Test Temperature',
        },
        {
          caption: 'Is Excluded',
          dataField: 'lot÷is_excluded',
          dataType: 'boolean',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Is Excluded',
        },
        {
          caption: 'SPCF',
          dataField: 'lot÷spcf',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].SPCF',
        },
        {
          caption: 'SPLF',
          dataField: 'lot÷splf',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].SPLF',
        },
        {
          caption: 'SYLF',
          dataField: 'lot÷sylf',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].SYLF',
        },
        {
          caption: 'Lot Complete Status',
          dataField: 'lot÷lot_complete_status',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Lot Complete status',
        },
        {
          caption: 'Auxiliary File',
          dataField: 'lot÷auxiliary_file',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Auxiliary File',
        },
        {
          caption: 'Burn In Time',
          dataField: 'lot÷burn_in_time',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Burn In Time',
        },
        {
          caption: 'Command Mode Code',
          dataField: 'lot÷command_mode_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Command Mode Code',
        },
        {
          caption: 'Date Code',
          dataField: 'lot÷date_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Date Code',
        },
        {
          caption: 'Design Revision',
          dataField: 'lot÷design_revision',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Design Revision',
        },
        {
          caption: 'Engineering Id',
          dataField: 'lot÷engineering_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Engineering Id',
        },
        {
          caption: 'Floor Id',
          dataField: 'lot÷floor_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Floor Id',
        },
        {
          caption: 'Flow Id',
          dataField: 'lot÷flow_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Flow Id',
        },
        {
          caption: 'Mode Code',
          dataField: 'lot÷mode_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Mode Code',
        },
        {
          caption: 'Node Name',
          dataField: 'lot÷node_name',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Node Name',
        },
        {
          caption: 'Operation Frequency',
          dataField: 'lot÷operation_frequency',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Operation Frequency',
        },
        {
          caption: 'Package Type',
          dataField: 'lot÷package_type',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Package Type',
        },
        {
          caption: 'Process Id',
          dataField: 'lot÷process_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Process Id',
        },
        {
          caption: 'Protection Code',
          dataField: 'lot÷protection_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Protection Code',
        },
        {
          caption: 'Rom Code',
          dataField: 'lot÷rom_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Rom Code',
        },
        {
          caption: 'Serial Number',
          dataField: 'lot÷serial_number',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Serial Number',
        },
        {
          caption: 'Setup Id',
          dataField: 'lot÷setup_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Setup Id',
        },
        {
          caption: 'Setup Time',
          dataField: 'lot÷setup_time',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Setup Time',
        },
        {
          caption: 'Specification Name',
          dataField: 'lot÷specification_name',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Specification Name',
        },
        {
          caption: 'Specification Version',
          dataField: 'lot÷specification_version',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Specification Version',
        },
        {
          caption: 'Station Number',
          dataField: 'lot÷station_number',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Station Number',
        },
        {
          caption: 'Sub Lot Id',
          dataField: 'lot÷sub_lot_id',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Sub Lot Id',
        },
        {
          caption: 'Supervisor Name',
          dataField: 'lot÷supervisor_name',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Supervisor Name',
        },
        {
          caption: 'Test Code',
          dataField: 'lot÷test_code',
          dataType: 'string',
          minWidth: 100,
          selected: false,
          filterColumn: '[L].Test Code',
        }],
      }, {
        dataField: 'wafer',
        dataType: 'string',
        caption: 'Wafer [W]',
        minWidth: 100,
        fields: [
          {
            dataField: 'wafer÷key',
            dataType: 'string',
            caption: 'Key',
            minWidth: 100,
            selected: true,
            filterColumn: '[W].Key',
          },
          {
            caption: 'Probe Count',
            dataField: 'wafer÷probe_count',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[W].Probe Count',
          },
          {
            caption: 'Start Time',
            dataField: 'wafer÷wafer_start_time',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[W].Start Time',
          },
          {
            caption: 'Finish Time',
            dataField: 'wafer÷wafer_finish_time',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[W].Finish Time',
          },
          {
            caption: 'Is Active',
            dataField: 'wafer÷is_active',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Is Active',
          },
          {
            caption: 'Wafer Type',
            dataField: 'wafer÷wafer_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Wafer Type',
          },
          {
            caption: 'Frame Id',
            dataField: 'wafer÷frame_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Frame Id',
          },
          {
            caption: 'Die Height',
            dataField: 'wafer÷die_height',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Die Height',
          },
          {
            caption: 'Die Width',
            dataField: 'wafer÷die_width',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Die Width',
          },
          {
            caption: 'Actual Notch',
            dataField: 'wafer÷actual_notch',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Actual Notch',
          },
          {
            caption: 'Target Notch',
            dataField: 'wafer÷target_notch',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Target Notch',
          },
          {
            caption: 'Die Count',
            dataField: 'wafer÷die_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Die Count',
          }, {
            caption: 'Total Dies In File',
            dataField: 'wafer÷total_dies_in_file',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Total Dies In File',
          }, {
            caption: 'Good Count',
            dataField: 'wafer÷good_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Good Count',
          }, {
            caption: 'Tester Type',
            dataField: 'wafer÷tester_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Tester Type',
          }, {
            caption: 'Include In Yield',
            dataField: 'wafer÷include_in_yield',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Include In Yield',
          }, {
            caption: 'Yield',
            dataField: 'wafer÷yield',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Yield',
          },
          {
            caption: 'Test Temperature',
            dataField: 'wafer÷test_temperature',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Test Temperature',
          }, {
            caption: 'Measurement Unit Id',
            dataField: 'wafer÷measurement_unit_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Measurement Unit Id',
          }, {
            caption: 'Marked For Wafer Hunter',
            dataField: 'wafer÷marked_for_wafer_hunter',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Marked For Wafer Hunter',
          }, {
            caption: 'Abort Count',
            dataField: 'wafer÷abort_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Abort Count',
          },
          {
            caption: 'Cable Id',
            dataField: 'wafer÷cable_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Cable Id',
          },
          {
            caption: 'Cable Type',
            dataField: 'wafer÷cable_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Cable Type',
          },
          {
            caption: 'Card Id',
            dataField: 'wafer÷card_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Card Id',
          }, {
            caption: 'Card Type',
            dataField: 'wafer÷card_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Card Type',
          }, {
            caption: 'Center X',
            dataField: 'wafer÷center_x',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Center X',
          }, {
            caption: 'Center Y',
            dataField: 'wafer÷center_y',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Center Y',
          }, {
            caption: 'Contactor Id',
            dataField: 'wafer÷contactor_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Contactor Id',
          }, {
            caption: 'Contactor Type',
            dataField: 'wafer÷contactor_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Contactor Type',
          }, {
            caption: 'CPU Type',
            dataField: 'wafer÷cpu_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].CPU Type',
          },
          {
            caption: 'DIB ID',
            dataField: 'wafer÷dib_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].DIB ID',
          },
          {
            caption: 'DIB Type',
            dataField: 'wafer÷dib_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].DIB Type',
          }, {
            caption: 'Exececution Description',
            dataField: 'wafer÷exec_description',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Exececution Description',
          },
          {
            caption: 'Exececution Type',
            dataField: 'wafer÷exec_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Exececution Type',
          },
          {
            caption: 'Execution Version',
            dataField: 'wafer÷exec_version',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Execution Version',
          },
          {
            caption: 'Extra Id',
            dataField: 'wafer÷extra_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Extra Id',
          },
          {
            caption: 'Extra Type',
            dataField: 'wafer÷extra_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Extra Type',
          }, {
            caption: 'Fab Wafer',
            dataField: 'wafer÷fab_wafer_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Fab Wafer',
          }, {
            caption: 'Flat',
            dataField: 'wafer÷flat',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Flat',
          }, {
            caption: 'Functional Count',
            dataField: 'wafer÷functional_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Functional Count',
          },
          {
            caption: 'Handler Id',
            dataField: 'wafer÷handler_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Handler Id',
          }, {
            caption: 'Handler Type',
            dataField: 'wafer÷handler_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Handler Type',
          }, {
            caption: 'Head Number',
            dataField: 'wafer÷head_number',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Head Number',
          },
          {
            caption: 'Laser Id',
            dataField: 'wafer÷laser_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Laser Id',
          },
          {
            caption: 'Laser Type',
            dataField: 'wafer÷laser_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Laser Type',
          },
          {
            caption: 'Loadboard Id',
            dataField: 'wafer÷loadboard_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Loadboard Id',
          },
          {
            caption: 'Loadboard Type',
            dataField: 'wafer÷loadboard_type',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Loadboard Type',
          },
          {
            caption: 'Mask Id',
            dataField: 'wafer÷mask_id',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Mask Id',
          },
          {
            caption: 'Node Name',
            dataField: 'wafer÷node_name',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Node Name',
          },
          {
            caption: 'Operator Name',
            dataField: 'wafer÷operator_name',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Operator Name',
          },
          {
            caption: 'PCR Part Count',
            dataField: 'wafer÷pcr_part_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].PCR Part Count',
          },
          {
            caption: 'PCR Abort Count',
            dataField: 'wafer÷pcr_abort_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].PCR Abort Count',
          },
          {
            caption: 'PCR Functional Count',
            dataField: 'wafer÷pcr_functional_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].PCR Functional Count',
          },
          {
            caption: 'PCR Part Count',
            dataField: 'wafer÷pcr_good_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].PCR Part Count',
          },
          {
            caption: 'PCR Re Test Count',
            dataField: 'wafer÷pcr_re_test_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].PCR Re Test Count',
          },
          {
            caption: 'Positive X',
            dataField: 'wafer÷positive_x',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Positive X',
          },
          {
            caption: 'Positive Y',
            dataField: 'wafer÷positive_y',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Positive Y',
          }, {
            caption: 'Retest Code',
            dataField: 'wafer÷retest_code',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Retest Code',
          }, {
            caption: 'Retest Count',
            dataField: 'wafer÷retest_count',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Retest Count',
          },
          {
            caption: 'Site Group',
            dataField: 'wafer÷site_group',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Site Group',
          },
          {
            caption: 'Site Numbers',
            dataField: 'wafer÷site_numbers',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Site Numbers',
          },
          {
            caption: 'STDF Version',
            dataField: 'wafer÷stdf_version',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].STDF Version',
          },
          {
            caption: 'User Description',
            dataField: 'wafer÷user_description',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].User Description',
          },
          {
            caption: 'User Text',
            dataField: 'wafer÷user_text',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].User Text',
          },
          {
            caption: 'Wafer Size',
            dataField: 'wafer÷wafer_size',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Wafer Size',
          },
          {
            caption: 'ATDF Version',
            dataField: 'wafer÷atdf_version',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].ATDF Version',
          },
          {
            caption: 'Is Scaled',
            dataField: 'wafer÷is_scaled',
            dataType: 'string',
            minWidth: 100,
            selected: false,
            filterColumn: '[W].Is Scaled',
          },

        ],
      },
      {
        caption: 'Die [DI]',
        dataField: 'die',
        dataType: 'number',
        minWidth: 100,
        fields: [
          {
            caption: 'X',
            dataField: 'die÷x',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].X',
          },
          {
            caption: 'Y',
            dataField: 'die÷y',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Y',
          },
          {
            caption: 'Hard Bin Number',
            dataField: 'die÷hard_bin_number',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Hard Bin Number',
          },
          {
            caption: 'Soft Bin Number',
            dataField: 'die÷soft_bin_number',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Soft Bin Number',
          },
          {
            caption: 'Site Number',
            dataField: 'die÷site_number',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Site Number',
          },
          {
            caption: 'Die Pass Fail',
            dataField: 'die÷execution_status',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Die Pass Fail',
          },
          {
            caption: 'Probe Card Site Map Number',
            dataField: 'die÷probe_card_site_map_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Probe Card Site Map Number',
          },
          {
            caption: 'Probe Card Site Map Position',
            dataField: 'die÷probe_card_site_map_position',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Probe Card Site Map Position',
          },
          {
            caption: 'Probe Card Site Number',
            dataField: 'die÷probe_card_site_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Probe Card Site Number',
          },
          {
            caption: 'Probe Card Site Number Position',
            dataField: 'die÷probe_card_site_number_position',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Probe Card Site Number Position',
          },
          {
            caption: 'Reticle Site Map Number',
            dataField: 'die÷reticle_site_map_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Reticle Site Map Number',
          },
          {
            caption: 'Reticle Site Map Position',
            dataField: 'die÷reticle_site_map_position',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Reticle Site Map Position',
          },
          {
            caption: 'Reticle Site Number',
            dataField: 'die÷reticle_site_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Reticle Site Number',
          },
          {
            caption: 'Reticle Site Number Position',
            dataField: 'die÷reticle_site_number_position',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Reticle Site Number Position',
          },
          {
            caption: 'WAT PCM Site Number',
            dataField: 'die÷wat_pcm_site_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].WAT PCM Site Number',
          },
          {
            caption: 'Zone',
            dataField: 'die÷zone',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Zone',
          },
          {
            caption: 'Is Allignment Die',
            dataField: 'die÷is_alignment_die',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Is Allignment Die',
          },
          {
            caption: 'Is Home Die',
            dataField: 'die÷is_home_die',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Is Home Die',
          },
          {
            caption: 'Abort Code',
            dataField: 'die÷abort_code',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Abort Code',
          },
          {
            caption: 'Part Fix',
            dataField: 'die÷part_fix',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Part Fix',
          },
          {
            caption: 'Part Id',
            dataField: 'die÷part_id',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Part Id',
          },
          {
            caption: 'Part Text',
            dataField: 'die÷part_text',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Part Text',
          },
          {
            caption: 'Test Code',
            dataField: 'die÷test_code',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Test Code',
          },
          {
            caption: 'Test Count',
            dataField: 'die÷test_count',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Test Count',
          },
          {
            caption: 'Test Time',
            dataField: 'die÷test_time',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[DI].Test Time',
          }],
      },
      {
        caption: 'Bin Summary [BS]',
        dataField: 'bin_summary',
        dataType: 'number',
        minWidth: 100,
        fields: [
          {
            caption: 'Bin Number',
            dataField: 'bin_summary÷bin_number',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Bin Number',
          },
          {
            caption: 'Bin Name',
            dataField: 'bin_summary÷bin_name',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Bin Name',
          },
          {
            caption: 'Bin Record',
            dataField: 'bin_summary÷bin_record_type',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Bin Record',
          },
          {
            caption: 'Head Number',
            dataField: 'bin_summary÷head_number',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Head Number',
          },
          {
            caption: 'Die Count',
            dataField: 'bin_summary÷number_of_parts',
            dataType: 'number',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Die Count',
          },
          {
            caption: 'Bin Pass Fail',
            dataField: 'bin_summary÷execution_status',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Bin Pass Fail',
          },
          {
            caption: 'Reticle Site Number',
            dataField: 'bin_summary÷reticle_site_number',
            dataType: 'string',
            minWidth: 100,
            selected: true,
            filterColumn: '[BS].Reticle Site Number',
          }],
      },
    ];
    return field;
  }

  applyColumnChanges(parameterFields:any) {
    this.setState(parameterFields);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0; const
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  render() {
    const field:any = this.getDefaultFields();
    const {
      gridFilterValue, splitColumnMode, parameterFields, unpivotView,
    } = this.state;
    const { advancedFilterInput } = this.props;
    const nameSelected = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'name' && parameter.selected);
    });
    const numberSelected = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'number' && parameter.selected);
    });
    const testUnit = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'test_unit' && parameter.selected);
    });
    const paramValue = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'param_Value' && parameter.selected);
    });
    const lowLimit = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'low_ctrl_limit' && parameter.selected);
    });
    const highLimit = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'high_ctrl_limit' && parameter.selected);
    });
    const lowSpec = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'low_spec_limit' && parameter.selected);
    });
    const highSpec = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'high_spec_limit' && parameter.selected);
    });
    const resScale = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'res_scale' && parameter.selected === true);
    });
    const hlmScale = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'hlm_scale' && parameter.selected === true);
    });
    const llmScale = _.findIndex(parameterFields, (parameter:any) => {
      return (parameter.dataField === 'llm_scale' && parameter.selected === true);
    });
    if (!splitColumnMode && !unpivotView) {
      if (this.rawData.length > 0 && this.fieldsMerged.length === 0) {
        this.fieldsMerged = JSON.parse(JSON.stringify(field));
        forEach(this.parametersList, (testParameter:any) => {
          this.fieldsMerged.push({
            caption: testParameter.name,
            dataField: `${testParameter.tableName}÷${testParameter.number}`,
            dataType: 'number',
            minWidth: 100,
            headerCellRender: this.customizedHeader,
            selected: true,
            hideInColumnChooser: true,
            filterColumn: `[${`${testParameter.name}÷${testParameter.number}`}]`,
          });
        });
      }
    } else if (this.rawData.length > 0 && splitColumnMode && !unpivotView) {
      if (this.rawDataSplited.length === 0) {
        this.rawDataSplited = JSON.parse(JSON.stringify(this.rawData));
        this.fieldsSplited = JSON.parse(JSON.stringify(field));
        forEach(this.parametersList, (testParameter:any) => {
          const columnFields:any = {
            caption: `${testParameter.name} [${testParameter.number}]`,
            dataField: this.uuidv4(),
            dataType: 'string',
            minWidth: 100,
            selected: true,
            hideInColumnChooser: true,
            fields: [],
          };
          this.rawDataSplited.forEach((item:any) => {
            eval(`item["${testParameter.tableName}÷name÷${testParameter.number}"]="${testParameter.name}"`);
            eval(`item["${testParameter.tableName}÷number÷${testParameter.number}"]="${testParameter.number}"`);
            eval(`item["${testParameter.tableName}÷test_unit÷${testParameter.number}"]="${testParameter.testUnit}"`);
            eval(`item["${testParameter.tableName}÷low_ctrl_limit÷${testParameter.number}"]=${testParameter.lowCtrlLimit === '' ? null : testParameter.lowCtrlLimit}`);
            eval(`item["${testParameter.tableName}÷high_ctrl_limit÷${testParameter.number}"]=${testParameter.highCtrlLimit === '' ? null : testParameter.highCtrlLimit}`);
            eval(`item["${testParameter.tableName}÷low_spec_limit÷${testParameter.number}"]=${testParameter.lowSpecLimit === '' ? null : testParameter.lowSpecLimit}`);
            eval(`item["${testParameter.tableName}÷high_spec_limit÷${testParameter.number}"]=${testParameter.highSpecLimit === '' ? null : testParameter.highSpecLimit}`);
          });
          columnFields.fields.push({
            caption: 'Number',
            dataField: `${testParameter.tableName}÷number÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: numberSelected !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].Number`,
          });

          columnFields.fields.push({
            caption: 'Name',
            dataField: `${testParameter.tableName}÷name÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: nameSelected !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].Name`,
          });

          columnFields.fields.push({
            caption: 'Parameter Value',
            dataField: `${testParameter.tableName}÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: paramValue !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].Parameter Value`,
            fields: [],
          });
          columnFields.fields.push({
            caption: 'Test Unit',
            dataField: `${testParameter.tableName}÷test_unit÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: testUnit !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].test unit`,
          });
          columnFields.fields.push({
            caption: 'Low Control Limit',
            dataField: `${testParameter.tableName}÷low_ctrl_limit÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: lowLimit !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].low control limit`,
          });
          columnFields.fields.push({
            caption: 'High Control Limit',
            dataField: `${testParameter.tableName}÷high_ctrl_limit÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: highLimit !== -1,
            hideInColumnChooser: true,
            filterColumn: `[${testParameter.number}].high control limit`,
          });
          columnFields.fields.push({
            caption: 'Low Spec Limit',
            dataField: `${testParameter.tableName}÷low_spec_limit÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            selected: lowSpec !== -1,
            filterColumn: `[${testParameter.number}].low spec limit`,
          });
          columnFields.fields.push({
            caption: 'High Spec Limit',
            dataField: `${testParameter.tableName}÷high_spec_limit÷${testParameter.number}`,
            dataType: 'string',
            minWidth: 100,
            hideInColumnChooser: true,
            selected: highSpec !== 1,
            filterColumn: `[${testParameter.number}].high spec limit`,
          });
          this.fieldsSplited.push(columnFields);
        });
      }
    } else if (unpivotView) {
      // if (this.rawData.length === 0) {
      this.fieldsUnpivoted = JSON.parse(JSON.stringify(field));
      this.fieldsUnpivoted.push({
        caption: 'Parameter Name',
        dataField: 'parameterName',
        dataType: 'string',
        minWidth: 100,
        selected: nameSelected !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'Parameter Number',
        dataField: 'parameterNumber',
        dataType: 'number',
        minWidth: 100,
        selected: numberSelected !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'Parameter Value',
        dataField: 'parameterValue',
        dataType: 'number',
        minWidth: 100,
        selected: paramValue !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'Low Control Limit',
        dataField: 'parameterLowLimit',
        dataType: 'string',
        minWidth: 100,
        selected: lowLimit !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'High Control Limit',
        dataField: 'parameterHighLimit',
        dataType: 'string',
        minWidth: 100,
        selected: highLimit !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'Low Spec Limit',
        dataField: 'parameterLowSpec',
        dataType: 'string',
        minWidth: 100,
        selected: lowSpec !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      this.fieldsUnpivoted.push({
        caption: 'High Spec Limit',
        dataField: 'parameterHighSpec',
        dataType: 'string',
        minWidth: 100,
        selected: highSpec !== -1,
        hideInColumnChooser: true,
        fields: [],
      });
      // }
    }
    return (
      <Row className="h-100v w100p flex-column">

        <Col>
          {/* <div className='w100p d-flex filter-builder'>
                <h6 className='mr10 pt5'>Filter Builder:</h6>
                <FilterBuilder
                  value={gridFilterValue}
                  fields={field}
                  onValueChanged={this.onValueChanged} />
              </div> */}
          <div className="w100p flex-row raw-grid">
            <FieldChooser fields={parameterFields} applyColumnChanges={this.applyColumnChanges} />
            <Button title="Switch to Split Mode" variant={splitColumnMode && !unpivotView ? 'outline-primary splitColumn border-all pl5 pr5 pt0 pb0' : 'outline-grey splitColumn border-all pl5 pr5 pt0 pb0'} className="dx-icon-columnfield" type="button" onClick={this.switchSplitMode}>
              <i />
            </Button>
            <Button title="Switch to Merge Mode" variant={!splitColumnMode && !unpivotView ? 'outline-primary mergeColumn border-all pl5 pr5 pt0 pb0' : 'outline-grey mergeColumn border-all pl5 pr5 pt0 pb0'} className="dx-icon-mergecells" type="button" onClick={this.switchMergeView}>
              <i />
            </Button>
            <Button title="unpivot View" variant={unpivotView ? 'outline-primary unpivotColumn border-all pl5 pr5 pt0 pb0' : 'outline-grey unpivotColumn border-all pl5 pr5 pt0 pb0'} className="dx-icon-detailslayout" type="button" onClick={this.switchUnpivotView}>
              <i />
            </Button>
            {/* <Dropdown>
              <Dropdown.Toggle variant="outline-grey" size="lg" id="dropdown-basic" className="options border-all pt5 pl4 pr4 pb2">
                {!unpivotView ? <i className={splitColumnMode ? 'dx-icon-mergecells' : 'dx-icon-columnfield'} /> : <i className="dx-icon-detailslayout" />}
              </Dropdown.Toggle>

              <Dropdown.Menu className="options">
                <Dropdown.Item>
                  <p>
                    <i className="dx-icon-columnfield" />
                    <span>Split View</span>
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p>
                    <i className="dx-icon-mergecells" />
                    <span> Merge View</span>
                  </p>
                </Dropdown.Item>
                <Dropdown.Item>
                  <p>
                    <i className="dx-icon-detailslayout" />
                    <span> Unpivot View</span>
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <CustomizedDataGrid
              id={new Date().toString()}
              fields={this.getFields()}
              publisherId="rawGrid"
              tableData={splitColumnMode ? this.rawDataSplited : this.rawData}
              height={560}
              showGroupPanel={false}
              filterValue={gridFilterValue}
              showAdvancedFilters
              enableColumnChooser={false}
              customColumnChooser
              showBorders
              showColumnLines
              filteredCount={this.filteredCount}
              advanceFilterExpression={this.cacheFilterValue}
              showDynamicColumn
              rawGrid
              scrollMode="virtual"
              advancedFilterInput={advancedFilterInput}
            />
          </div>
        </Col>
        <Col>
          <div
            className="pt5"
            ref={(ref) => {
            // eslint-disable-next-line react/destructuring-assignment
              this.filteredCount = ref;
            }}
          >
            <b> [ Total Records / Filtered : </b>
            <i>
              {this.rawData.length}

            </i>
            /
            <i id="filteredRecords" />
            {'  '}

            <b> ]</b>

            <b className="pl10"> [ Total / Selected Lots : </b>
            <i>
              {this.rawData.length > 0 ? _.uniqBy(this.rawData, 'lot÷Key').length : 0}
            </i>
            /
            <i id="selectedLots">

              {'  '}
            </i>
            <b> ]</b>
            <b className="pl10 war"> [ Total / Selected Wafers : </b>
            <i>
              {this.rawData.length > 0 ? _.uniqBy(this.rawData, 'wafer÷id').length : 0}

            </i>
            /
            <i id="selectedWafers">

              {'  '}
            </i>
            <b> ]</b>
          </div>
          {
            !splitColumnMode && !unpivotView ? (
              <div className="d-flex param-legends col-11">
                <ul>
                  <li>

                    <b className="p10 " style={{ color: `${this.stringToColorCode('Parameter Number')}` }}>
                      Parameter Number
                    </b>
                  </li>
                  <li>
                    <b className="p10" style={{ color: `${this.stringToColorCode('Parameter Name')}` }}>Parameter Name</b>

                  </li>
                  <li><b className="p10 " style={{ color: `${this.stringToColorCode('Test Unit')}` }}>Test Unit</b></li>
                  <li><b className="p10 " style={{ color: `${this.stringToColorCode('Low Spec')}` }}>Low Spec</b></li>
                  <li><b className="p10 " style={{ color: `${this.stringToColorCode('High Spec')}` }}>High Spec</b></li>
                  <li><b className="p10 " style={{ color: `${this.stringToColorCode('Low Limit')}` }}>Low Limit</b></li>
                  <li><b className="p10 " style={{ color: `${this.stringToColorCode('High Limit')}` }}>High Limit</b></li>
                </ul>
              </div>
            ) : ''
          }

        </Col>
      </Row>
    );
  }
}
export default RawDataGrid;
