/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { EntityType, OperationType, IGenericDetailedReportRequestObject } from 'interfaces';
import { httpReport } from 'services/http.report';
import { ICustomizedReportGraphProps, ICustomizedReportGraphState } from '../../CustomizedReportGraph';
import { InteractionInputDTO, ICombinedGraphData, ICombinedGraphDataWithReportHeaders, } from '../CustomizedReportInterfaces';
import { IGraphDataHelper, IGraphMode, IGraphType, } from '../CustomizedReportHelper';
import { GraphingUtils } from '../../customized-report-utils/GraphingUtils';
import { REPORT_TYPE } from '../CustomizedReportMapping';
import { ICustomizedReportProps, ICustomizedReportState } from '../../CustomizedReport';
import ReportOptionsModal from '../../customized-report-utils/ReportOptionsModal';
import ReportHorizontalScrollView from '../../customized-report-utils/ReportHorizontalScrollView';

export class BinParetoDataHelper implements IGraphDataHelper {
  preferences = {
    hasMultiYAxis: true,
    reportType: 'BIN_BASED' as REPORT_TYPE,
    graphType: ('bar' as IGraphType),
    graphMode: ('markers' as IGraphMode),
    markerOpacity: 0.9,
    markerBorderOpacity: 0,
    showCustomLabelsWhenGrouped: true,
    showCustomLabelsWhenNotGrouped: true,
    showCustomTickText: true,
    autoLoad: true,
    repeatMarkerColor: false,
    showYAxisBreaks: true,
    showXAxisBreaks: false,
    allowDefaultGrouping: false,
    extractSelectedValuesFromToolTips: false,
    showAddedMarkers: false,
    showAddedLines: {
      grouping: {
        show: true,
        name: 'Cumulative Bin %',
      },
      noGrouping: {
        show: true,
        name: 'Cumulative Bin %',
      },
    },
    textOnHover: [['placeholder']],
    hoverTemplate: {
      grouping: '%{text}',
      noGrouping: '%{text}',
    },
    selectionEventDefaults: {
      xAxisLabel: 'BIN_NUMBER',
      yAxisLabel: 'DIE_COUNT',
      avoidXWhenGroupingPresent: false,
    },
    defaultGrouping: {
      columnName: 'wafer_id',
      entityType: ('WAFER' as EntityType),
      isLastGrouping: true,
      sequence: 1,
      name: 'id',
      operationType: ('GROUPING' as OperationType),
    },
    axisNames: {
      grouping: {
        xAxisName: 'Softbin',
        yAxisName: 'Die Count',
        secondaryYAxisName: 'Cumulative %',
      },
      noGrouping: {
        xAxisName: 'Softbin',
        yAxisName: 'Die Count',
        secondaryYAxisName: 'Cumulative %',
      },
    },
    showTickText: {
      grouping: {
        xAxis: true,
        yAxis: true,
      },
      noGrouping: {
        xAxis: true,
        yAxis: true,
      },
    },
    useCaptionAsGroupValueWhenGroupingPresent: false,
  };

  getPreferences = () => {
    return this.preferences;
  };

  getPrimaryComponent = (props: ICustomizedReportProps, state: ICustomizedReportState, _classMembers: any, setStateCallback: any, requestObject?: IGenericDetailedReportRequestObject) => {
    const {
      groupingSortingListStore, selectionStore, report, testParameterIndex,
    } = props;
    const testParameters = selectionStore.selections.filter(
      (x: any) => x.entityType === 'Testparameter',
    );
    const testParameterValues: any[] = [];
    testParameters.forEach((testParameter: any) => {
      testParameterValues.push(testParameter.values);
    });
    return (
      <ReportOptionsModal
        reportActor={report.actor}
        groupingSortingListStore={groupingSortingListStore}
        selectionStore={selectionStore}
        testParameterValues={testParameterValues}
        testParameterIndex={testParameterIndex}
        parentState={state}
        setParentStateCallback={(callbackObj: any) => {
          setStateCallback(callbackObj);
        }}
        applyLimitSettings={() => {}}
        requestObject={requestObject}
        hideLimitSettings
      />
    );
  };

  generateTabs = (requestObject: IGenericDetailedReportRequestObject, props: ICustomizedReportGraphProps, interactionsApplied?: boolean, updateParentState?: any) => {
    const tabs = [];
    const graphingUtil = new GraphingUtils();

    tabs.push({
      title: 'Data',
      key: 'data',
      component: graphingUtil.generateDataTab(props, this.preferences),
    });
    tabs.push({
      title: 'Statistics',
      key: 'statistics',
      component: graphingUtil.generateStatisticsTab(props, this.preferences, interactionsApplied),
    });

    return tabs;
  };

  generateGraphComponent = (props: ICustomizedReportGraphProps, state: ICustomizedReportGraphState, classMembers: any, setStateCallback: any) => {
    const { graphData } = state;
    this.updatePreferences(props, (graphData as ICombinedGraphData));
    return (
      <ReportHorizontalScrollView
        reportGraphProps={props}
        reportGraphState={state}
        classMembers={classMembers}
        setStateCallback={setStateCallback}
        preferences={this.preferences}
      />
    );
  };

  postCombinedEvent = (selectionEventInput: InteractionInputDTO) => {
    return httpReport.setInteractionData(selectionEventInput);
  };

  subscribeSelectionEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  subscribeHidingEvent = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphData: ICombinedGraphDataWithReportHeaders) => void) => {
    const data = await httpReport.getReport(requestObject);
    callback(data);
  };

  getDetailedGraphData = async (requestObject: IGenericDetailedReportRequestObject, callback: (graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => void) => {
    httpReport.getReport(requestObject).then((graphDataAndReportHeader: ICombinedGraphDataWithReportHeaders) => {
      callback(graphDataAndReportHeader);
    }).catch((err: any) => {
      const errorDetails: any = err.message;
      const graphingUtil = new GraphingUtils();
      callback(graphingUtil.generateDataObjectFromError(errorDetails));
    });
  };

  updatePreferences = (props: ICustomizedReportGraphProps, data: ICombinedGraphData) => {
    const { isSoftBin } = props;
    this.preferences.textOnHover = [];
    data.graphs.forEach((graph: any) => {
      this.preferences.textOnHover.push(graph.y);
    });
    if (!isSoftBin) {
      this.preferences.axisNames.grouping.xAxisName = 'Hardbin';
      this.preferences.axisNames.noGrouping.xAxisName = 'Hardbin';
    } else {
      this.preferences.axisNames.grouping.xAxisName = 'Softbin';
      this.preferences.axisNames.noGrouping.xAxisName = 'Softbin';
    }
  };
}
