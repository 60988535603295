import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataGrid, { Form, Editing, Popup, } from 'devextreme-react/data-grid';

import { Item } from 'devextreme-react/form';
import _ from 'lodash';
import CustomizedDataGrid from '../../../../../wrapped-component/customized-data-grid/CustomizedDataGrid';
import PATCreateRulePopupForm from './pat-create-rule-popup/PATCreateRulePopupForm';
import { PATRule } from '../../PATInterfaces';
import toast from '../../../../../../CustomToast';
import { httpService } from '../../../../../../services/http.service';

interface IPATCreateRuleGridProps {
  searchCriteria: any;
  saveNewPatRule: (e: PATRule) => void;
  patRuleSetConfig: any;
}

interface IPATCreateRuleGridState {
  currentEditRule: PATRule;
  selectedRowsData: any;
  assignPATRuleId: boolean;
}

export const RULE_TYPES = [
  ['STATIC_PAT', 'Static'],
  ['DYNAMIC_PAT', 'Dynamic'],
  // ['DYNAMIC_FT', 'Dynamic for FT'],
  // ['MPVAT', 'MVPAT'],
];

export const SITE_OPTIONS = [
  ['SIMPLE_RULE', 'Simple Rule'],
  ['RULE_PER_SITE', 'Rule Per Site'],
];

const NEW_EDIT_RULE = {
  testParameters: [],
  version: 1,
  sequenceId: 0,
  patRuleType: RULE_TYPES[0][0],
  siteOption: SITE_OPTIONS[0][0],
  seedLimit: {
    logicalOp: '&&',
    recalculateCondition: '',
    relationalOp: '<=',
    timeCriteria: 'DAY',
    timeValue: 0,
    waferLotCount: 0,
    waferLotCriteria: 'WAFER',
  },
};

class PATCreateRuleGrid extends Component<IPATCreateRuleGridProps, IPATCreateRuleGridState> {
  private dataGridRef: DataGrid | null = null;

  private popFormRef: any = null;

  private highlightTextBox : any = {
    Name: '',
    Owner: '',
    Description: '',
  };

  constructor(props: any) {
    super(props);
    this.state = {
      currentEditRule: _.cloneDeep(NEW_EDIT_RULE),
      selectedRowsData: [],
      assignPATRuleId: false,
    };
  }

  componentDidUpdate(prevProps:IPATCreateRuleGridProps) {
    if (prevProps.patRuleSetConfig.patRules.length < this.props.patRuleSetConfig.patRules.length) {
      this.assignNewPATRuleID();
    }
  }

  updateCurrentEditRule = ({ data }: any) => {
    this.setState({ currentEditRule: _.cloneDeep(data) });
  };

  onInitNewRow = () => {
    this.setState({ currentEditRule: _.cloneDeep(NEW_EDIT_RULE) });
  };

  onRowRemoved = async (row: any) => {
    await httpService.deletePATRule(row.data.id);
    toast.success('PAT Rule Successfully Deleted');
  };

  onSavedPatRule = () => {
    const { saveNewPatRule } = this.props;
    const { currentEditRule } = this.state;
    const createdRule = _.cloneDeep(currentEditRule);
    if (createdRule) {
      this.setState({ currentEditRule: _.cloneDeep(NEW_EDIT_RULE) }, () => {
        saveNewPatRule(createdRule);
      });
    }
  };

  checkRuleTestParameters = (testParameters: any) => {
    for (let index = 0; index < testParameters.length; index += 1) {
      if (testParameters[index].binPlusGroupType !== 'NA') {
        if (testParameters[index].binPlusDefinitionColor !== ''
          && testParameters[index].binPlusDefinitionId !== null
          && testParameters[index].binPlusDefinitionName !== ''
          && testParameters[index].binPlusDefinitionNumber !== ''
          && testParameters[index].binPlusTableId !== null
          && testParameters[index].highKLimit !== 0
          && testParameters[index].lowKLimit !== 0
        ) {
          return true;
        }
        toast.error('Provide Bin Information for the selected test parameter');
        return false;
      }
      if (testParameters[index].highKLimit === 0 && testParameters[index].lowKLimit === 0) {
        toast.error('K Values cannot be 0');
        return false;
      }
    }
  };

  checkRuleFields = (rule: any) => {
    if (rule.name === undefined){
      this.highlightTextBox.Name = true;
      return false;
    }
    if (rule.description === undefined || rule.description === ''){
      this.highlightTextBox.Description = true;
      return false;
    }
    if (rule.owner === undefined || rule.owner === ''){
      this.highlightTextBox.Owner = true;
      return false;
    }
    return rule.testParameters.length > 0 && this.checkRuleTestParameters(rule.testParameters);
  }

  onRowSelection = (event: any) => {
    const { selectedRowsData } = event;
    this.setState({ selectedRowsData });
  };

  onSavePopupFormHandler = () => {
    if (this.popFormRef) {
      this.setState({
        currentEditRule: this.popFormRef!.getCurrentEditRule(),
      }, () => {
        // this line will invoke the onSaved of the customized Data grid
        if (this.dataGridRef && this.checkRuleFields(this.popFormRef!.getCurrentEditRule())) this.dataGridRef.instance.saveEditData();
      });
    }
  };

  onCancelPopupFormHandler = () => {
    if (this.dataGridRef) this.dataGridRef.instance.cancelEditData();
  };

  assignNewPATRuleID = () => {
    const { assignPATRuleId } = this.state;
    this.setState({ assignPATRuleId: true });
  };

  onRowReorder = (e: any) => {
    const { patRuleSetConfig } = this.props;
    const { assignPATRuleId } = this.state;
    if (this.dataGridRef && assignPATRuleId === false) {
      const newTasks = [...patRuleSetConfig.patRules];
      const reorderedRules = e.filter((x: any) => newTasks.some((y: any) => y.id === x.id));
      for (let i = 0; i < reorderedRules.length; i += 1) {
        reorderedRules[i].rule.sequenceId = i;
        reorderedRules[i].sequenceId = i;
      }
      patRuleSetConfig.patRules = reorderedRules;
    }
  };

  render() {
    const {
      currentEditRule,
      selectedRowsData,
    } = this.state;

    const {
      patRuleSetConfig,
      searchCriteria,
    } = this.props;

    const {
      patRules,
      patRuleSetType,
    } = patRuleSetConfig;

    return (
      <Row>
        <Col lg={12} className="mt20">
          <div>
            <CustomizedDataGrid
              setRef={(ref: DataGrid) => {
                this.dataGridRef = ref;
              }}
              id="ruleCreateId"
              // selectionMode="multiple"
              selectedRowKeys={selectedRowsData}
              onRowSelection={this.onRowSelection}
              enableColumnChooser={false}
              showAdvancedFilters={false}
              showRowLines
              showColumnLines
              allowRowReorder
              height={300}
              onEditingStart={this.updateCurrentEditRule}
              onSaved={this.onSavedPatRule}
              onInitNewRow={this.onInitNewRow}
              onRowRemoved={this.onRowRemoved}
              tableData={patRules}
              onRowReorder={this.onRowReorder}
              editingComponent={(
                <Editing
                  useIcons
                  mode="popup"
                  allowUpdating
                  allowAdding
                  allowDeleting
                  newRowPosition="last"
                >
                  <Popup
                    showCloseButton
                    closeOnOutsideClick={false}
                    showTitle={false}
                    width="90%"
                    height="80%"
                    toolbarItems={[{
                      toolbar: 'bottom',
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        text: 'Save',
                        onClick: this.onSavePopupFormHandler,
                      },
                    },
                    {
                      toolbar: 'bottom',
                      location: 'after',
                      widget: 'dxButton',
                      options: {
                        text: 'Cancel',
                        onClick: this.onCancelPopupFormHandler,
                      },
                    },
                    ]}
                  />
                  <Form>
                    <Item
                      cssClass="mt20 mb20"
                      itemType="group"
                      caption="Rule Settings"
                      colCount={2}
                      colSpan={2}
                    >
                      <PATCreateRulePopupForm
                        ref={(ref: any) => {
                          this.popFormRef = ref;
                        }}
                        highlightTextBox={this.highlightTextBox}
                        searchCriteria={searchCriteria}
                        currentEditRule={currentEditRule}
                        patRuleSetType={patRuleSetType}
                        // onSaveRuleSettings={() => {
                        // }}
                      />
                    </Item>
                  </Form>
                </Editing>
              )}
              fields={[
                {
                  caption: 'Rule Name',
                  dataField: 'name',
                  dataType: 'string',
                  showInfo: false,
                },
                {
                  caption: 'Rule Description',
                  dataField: 'description',
                  dataType: 'string',
                  showInfo: false,
                },
                {
                  caption: 'PAT Rule Type',
                  dataField: 'patRuleType',
                  dataType: 'string',
                  showInfo: false,
                },
                {
                  caption: 'Rule State',
                  dataField: 'state',
                  dataType: 'string',
                  showInfo: false,
                },
                {
                  caption: 'Site Option',
                  dataField: 'siteOption',
                  dataType: 'string',
                  showInfo: false,
                },

                {
                  caption: 'Rule Version',
                  dataField: 'version',
                  dataType: 'string',
                  showInfo: false,
                },
                {
                  caption: 'Test Parameter Count',
                  dataField: 'testParameters.length',
                  dataType: 'string',
                  showInfo: false,
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default PATCreateRuleGrid;
